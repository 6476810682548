import {CechaGrupa} from '../enums/CechaGrupa';
import {CechaType} from '../enums/CechaType';
import {QuantityType} from "../enums/QuantityType";

export class AddonAttribute {
    symbol: string;
    value: string;
    hasAutoOption: boolean;
    visible = true;
    type: CechaType;
    quantityType: QuantityType;
    group: CechaGrupa;
}
