import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {FilterMetadata} from "primeng/api/filtermetadata";
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {SelectItem} from "primeng/api/selectitem";
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {map, tap} from "rxjs/operators";
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {AddonCategoryField, CatalogTab} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {AddonCategoryGroupService} from "../addon-category-group/addon-category-group.service";
import {CatalogSection} from "../addons/CatalogSection";
import {AddonCategoryFieldUsage} from "../catalog-field-usage";
import {AddonCategory} from "./addon-category";
import {AddonCategoryService} from './addon-category.service';

@Component({
    selector: 'app-addon-category',
    templateUrl: './addon-category.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [AddonCategoryService, DataServiceHelper, TranslatedSelectItemService, AddonCategoryGroupService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonCategoryComponent extends CrudCommonComponent<AddonCategory, AddonCategoryService> implements OnInit {

    @Input()
    target: CatalogSection;

    item: AddonCategory;
    addonCategoryGroupService: AddonCategoryGroupService;
    addonCategoryGroups: SelectItem[];
    validationErrors: { [field: string]: string } = {};
    editPermits: FieldLimitation[] = [];
    fieldUsage: AddonCategoryFieldUsage;
    CatalogTab = CatalogTab;
    AddonCategoryField = AddonCategoryField;

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => this.validateForm()
        },
        ADDONS: {
            id: 'ADDONS',
            validate: () => of(true)
        }
    };

    @ViewChild('dt') datatable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, AddonCategoryService, 'ADDON_CATEGORY', 'AddonCategory');
        this.item = new AddonCategory();
        this.addonCategoryGroupService = injector.get(AddonCategoryGroupService);
        this.initDefaultSortOrder();
        this.fieldUsage = new AddonCategoryFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.item = this.getNewItem();
        let filters: { [filterProperty: string]: FilterMetadata } = {};
        filters['target'] = {};
        filters['target'].value = this.target;
        this.addonCategoryGroupService.getItems(null, null, filters, null, null).subscribe({
            next: data => {
                this.addonCategoryGroups = data.data.map(group =>
                    new SelectItemImpl(group.name[this.translate.currentLang], group.id));
            },
            error: error => this.errors.handle(error)
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.ADDON_CATEGORIES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.item = new AddonCategory();
        this.getItem(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    getNewItem(): AddonCategory {
        let item = new AddonCategory();
        item.target = this.target;
        return item;
    }

    getItem(itemId: number) {
        this.itemService.getItem(itemId).subscribe({
            next: item => {
                this.item = item;
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors);

        if (!this.item.name[this.userLang]) {
            this.validationErrors[`name[${this.userLang}]`] = `error.addonCategoryDto.name[${this.userLang}].not_empty`;
        }

        if (!this.item.groupId) {
            this.validationErrors['groupId'] = 'error.addonCategoryDto.groupId.not_null';
        }

        if (!this.item.symbol) {
            this.validationErrors['symbol'] = 'error.addonCategoryDto.symbol.not_null';
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.addonCategoryDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 9999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.add();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    loadItemsLazy(event: LazyLoadEvent) {
        event.filters['target'] = {value: this.target};
        super.loadItemsLazy(event);
    }
}
