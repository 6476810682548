import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {PaginatorRowsPerPageOptions} from '../../../../common/crud-common/paginatorRowsPerPageOptions';
import {TristateCheckboxState} from '../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {ProductionOrdersPosition} from './productionOrdersPosition';

export class ProductionOrdersPositionTableData {
    positions: ProductionOrdersPosition[] = [];
    selectedPosition: ProductionOrdersPosition = undefined;
    lastLoadEvent: LazyLoadEvent;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    rowsPerPage = PaginatorRowsPerPageOptions.defaultValue;
    // checkbox column selections
    selectedItems: ProductionOrdersPosition[] = [];
    allSelectedState = TristateCheckboxState.UNCHECKED;
    showFilters = false;
    showRealDimensionsColumn = false;

    constructor() {
        this.lastLoadEvent =
            {
                first: undefined,
                rows: undefined,
                sortField: undefined,
                sortOrder: undefined,
                multiSortMeta: undefined,
                globalFilter: undefined,
                origin: undefined,
                filters: {}
            };
    }
}
