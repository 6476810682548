import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Subscription} from 'rxjs';

import 'moment/locale/da';
import 'moment/locale/de';
import 'moment/locale/en-ca';
import 'moment/locale/en-gb';
import 'moment/locale/nn';
import 'moment/locale/pl';
import 'moment/locale/sv';

export function convertAppLanguageToMomentLocale(lang: string): string {
    switch (lang) {
        case 'se':
            return 'sv';
        case 'no':
            return 'nn';
        case 'en':
            return 'en-gb';
        case 'ca':
            return 'en-ca';
        default:
            break;
    }
    return lang;
}

@Pipe({
    name: 'momentCurrentLang',
    pure: false
})
export class MomentCurrentLangPipe implements PipeTransform, OnDestroy {

    private lastValue: moment.Moment;
    private subscription: Subscription;

    constructor(private readonly translate: TranslateService,
                private readonly changeDetector: ChangeDetectorRef) {
        this.subscription = this.translate.onLangChange.subscribe(event => {
            if (this.lastValue != undefined) {
                this.lastValue.locale(convertAppLanguageToMomentLocale(event.lang));
                this.changeDetector.markForCheck();
            }
        });
    }

    transform(value: moment.MomentInput): moment.MomentInput {
        if (!value) {
            this.lastValue = undefined;
            return '';
        }
        this.lastValue = moment(value);
        return this.lastValue.locale(convertAppLanguageToMomentLocale(this.translate.currentLang));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
