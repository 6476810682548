import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {ComplaintPosition} from "./complaint-position";
import {ComplaintStatus} from './complaint-status';

@JsonDeserializable
export class Complaint implements CrudItem {

    id: number;
    complaintNumber: string;
    status: ComplaintStatus;
    deliveryDate: Date;
    deliveryDestination: string;
    expectedShippingDate: Date;
    sendToVerifyDate: Date;
    description: string;
    translatedDescription: string;
    createdDate: Date;
    offerId: number;
    offerSubsystemName: string;
    clientManagerFirstName: string;
    clientManagerLastName: string;
    subClientManagerFirstName: string;
    subClientManagerLastName: string;
    positions: ComplaintPosition[];

    static isComplaintStatusIn(complaint: Complaint, statuses: ComplaintStatus[]): boolean {
        return statuses.find(status => status === complaint.status) != null;
    }

    static isComplaintStatusNotIn(complaint: Complaint, statuses: ComplaintStatus[]): boolean {
        return statuses.find(status => status === complaint.status) == null;
    }

    static fromJSON(jsonObject: object): Complaint {
        const complaint = new Complaint();
        complaint.id = jsonObject['id'];
        complaint.complaintNumber = jsonObject['complaintNumber'];
        complaint.status = ComplaintStatus[jsonObject['status'] as string];
        if (jsonObject['deliveryDate'] != undefined) {
            complaint.deliveryDate = new Date(jsonObject['deliveryDate']);
        }
        complaint.deliveryDestination = jsonObject['deliveryDestination'];
        if (jsonObject['expectedShippingDate'] != undefined) {
            complaint.expectedShippingDate = new Date(jsonObject['expectedShippingDate']);
        }
        if (jsonObject['sendToVerifyDate'] != undefined) {
            complaint.sendToVerifyDate = new Date(jsonObject['sendToVerifyDate']);
        }
        complaint.description = jsonObject['description'];
        complaint.translatedDescription = jsonObject['translatedDescription'];
        if (jsonObject['createdDate'] != undefined) {
            complaint.createdDate = new Date(jsonObject['createdDate']);
        }
        complaint.offerId = jsonObject['offerId'];
        complaint.offerSubsystemName = jsonObject['offerSubsystemName'];
        complaint.clientManagerFirstName = jsonObject['clientManagerFirstName'];
        complaint.clientManagerLastName = jsonObject['clientManagerLastName'];
        complaint.subClientManagerFirstName = jsonObject['subClientManagerFirstName'];
        complaint.subClientManagerLastName = jsonObject['subClientManagerLastName'];
        if (jsonObject['positions'] != undefined) {
            complaint.positions = (jsonObject['positions'] as object[]).map(ComplaintPosition.fromJSON);
        }
        return complaint;
    }
}
