<p-dialog #dialog [resizable]="false" [(visible)]="visible" (onHide)="onClose.emit()"
          class="dialog-window" [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">{{ 'OFFER.TABS.SECTION.MAIN.TERRACE_HANDLE_LAYOUT' | translate }}</div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-600">
        <ng-container *ngFor="let option of options | async">
            <div [ngClass]="{'layout-option': true, 'layout-option-selected': selectedTerraceHandleLayout === option.value}"
                 (click)="selectedTerraceHandleLayout = option.value">
                <div>{{ option.label | translate }}</div>

                <img [ngClass]="{'layout-option-flip-horizontal': needsFlipping()}" [src]="getFilePath(option.value)"
                (load)="centerDialog()"/>
            </div>
        </ng-container>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-save" label="{{'GENERAL.OK' | translate }}" type="main-action"
                               [size]="40" (onClick)="submit($event)"></app-simple-button>
            <app-simple-button buttonId="dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
