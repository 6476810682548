import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Hotkey} from "angular2-hotkeys";
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {addonQuantityTypeIntegerOnly} from '../../../../../../../../window-designer/catalog-data/addon-interface';
import {PositionListAddon} from "../../../../../../../../window-designer/catalog-data/position-list-addon";
import {DrawingData} from "../../../../../../../../window-designer/drawing-data/drawing-data";
import {WindowAddon} from "../../../../../../../../window-designer/drawing-data/WindowAddon";
import {WindowAddonData} from '../../../../../../../../window-designer/drawing-data/WindowAddonData';
import {WindowDimensions} from '../../../../../../../../window-designer/entities/window-dimensions';
import {AddonAvailableIn} from "../../../../../../../../window-designer/enums/addon-available-in.enum";
import {AddonCategoryEnum} from "../../../../../../../../window-designer/enums/AddonCategoryEnum";
import {AddonFor} from "../../../../../../../../window-designer/enums/AddonFor";
import {QuantityType} from "../../../../../../../../window-designer/enums/QuantityType";
import {StaticDataHelper} from '../../../../../../../../window-designer/static-data-helper';
import {
    AddonDefaultQuantityCalculator
} from "../../../../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator";
import {Permissions} from "../../../../../../../auth/permission.service";
import {UserUiConfigService} from "../../../../../../../auth/uiconfig/userUiConfig.service";
import {CommonErrorHandler} from "../../../../../../../common/CommonErrorHandler";
import {ComponentWithUserConfigAndPaginator} from "../../../../../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../../../../../common/dataServiceHelper";
import {DatatableHelper} from "../../../../../../../common/DatatableHelper";
import {SystemType} from "../../../../../../../common/enums/SystemType";
import {ExchangeService} from '../../../../../../../common/exchange.service';
import {DataTableColumn} from '../../../../../../../common/service/data.table.column';
import {DataTableColumnBuilder} from '../../../../../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../../../../../common/service/translated-select-item.service';
import {Currencies} from '../../../../../../../currencies';
import {TristateCheckboxState} from '../../../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {Supplier} from '../../../../../../supplier/supplier';
import {Addon, AddonCategoryCount} from "../../../../../../window-system/addons/addon";
import {AddonsService} from "../../../../../../window-system/addons/addons.service";
import {Offer} from "../../../../../offer";
import {
    WindowComponentPreviewData
} from '../../../../../window-editor/window-component-preview-dialog/window-component-preview-dialog.component';
import {PositionService} from "../../../position.service";
import {BulkAddonPositionValidator} from "../bulk-addon-position.validator";

export enum WindowAddonActionType {
    EDIT = "EDIT",
    ADD = "ADD",
}

export class WindowAddonSaveData {

    public validation = false;
    public addonToSave: WindowAddon;
    public action: WindowAddonActionType;
    public positionlistAddon: PositionListAddon;

    constructor(validation: boolean, addonToSave?: WindowAddon, action?: WindowAddonActionType, positionListAddon?: PositionListAddon) {
        this.validation = validation;
        this.addonToSave = addonToSave;
        this.action = action;
        this.positionlistAddon = positionListAddon;
    }
}

@Component({
    selector: 'app-addon-position',
    templateUrl: './addon-position.component.html',
    styleUrls: ['../../../../../../shared-styles.css', './addon-position.component.css'],
    providers: [AddonsService, PositionService, DataServiceHelper, UserUiConfigService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonPositionComponent extends ComponentWithUserConfigAndPaginator implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    private TABLE_SEQUENCE_OFFSET = 2;
    private EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_TEXTAREA = "TEXTAREA";
    private EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_INPUT = "INPUT";
    private EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_LABEL = "LABEL";
    private EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_SPAN = "SPAN";

    @ViewChild('dt', {static: true}) datatable: DataTable;

    @Output() windowAddonSaved: EventEmitter<WindowAddonSaveData> = new EventEmitter<WindowAddonSaveData>();
    @Output() windowAddonRemoved: EventEmitter<number> = new EventEmitter<number>();
    @Output() tableDataLoaded: EventEmitter<any> = new EventEmitter();

    @Input() offerId: number;
    @Input() offerPositionId: number;
    @Input() bulkAddonsOnly = false;
    @Input() tabViewAddedMode = false;
    @Input() selectedCategory: AddonCategoryEnum;
    @Input() selectedCategoryId: number;
    @Input() offer: Offer;
    @Input() suppliers: Supplier[];
    @Input() windowAddonData: WindowAddonData;
    @Input() roofWindowsDimension: WindowDimensions;
    @Input() addedWindowAddons: PositionListAddon[];
    @Input() tabIndex: number;
    @Input() systemId: number;
    @Input() sidebarOnlyMode: boolean;
    @Input() readOnlyMode: boolean;
    @Input() staticData: StaticDataHelper;
    @Input() includeGeneralAddons: boolean;
    @Input() includeSubsystemAddons: boolean;
    @Input() systemType: SystemType;
    @Input() subsystemDefaultCurrency: Currencies;
    @Input() searchedName: string;
    @Output() readonly searchedNameCategoryResultsChange = new EventEmitter<AddonCategoryCount[]>();

    seqNum: number;
    lastAddedId = 0;
    editedButNotAddedPositions: number[];
    addons: PositionListAddon[] = [];
    selectedAddon: PositionListAddon;
    allSelected = TristateCheckboxState.UNCHECKED;
    filterCategory: Observable<SelectItem[]>;
    filterAddonFor: Observable<SelectItem[]>;

    availableCategories = [];
    userLang;
    lastLoadEvent: LazyLoadEvent;

    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;

    private spaceHotkey: Hotkey;

    selectedItems: PositionListAddon[] = [];

    addonPreviewData: WindowComponentPreviewData;

    readonly columns: DataTableColumn[];

    constructor(public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private addonService: AddonsService,
                public exchangeService: ExchangeService,
                public permissions: Permissions,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
        super(injector, changeDetector, 'AddonPositionComponent', false);

        this.userLang = translate.currentLang;

        this.spaceHotkey = new Hotkey('space', (): boolean => {
            if (this.bulkAddonsOnly) {
                this.changeSelectionOfItem();
            } else if (this.selectedAddon != undefined) {
                if (this.tabViewAddedMode) {
                    this.deleteWindowAddon(this.selectedAddon);
                } else {
                    this.addWindowAddon(this.selectedAddon);
                }
            }
            return false;
        });
        this.columns = this.initTable();
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    protected getColumns(): DataTableColumn[] {
        return this.columns;
    }

    private changeSelectionOfItem() {
        if (this.selectedAddon) {
            this.selectItem(this.selectedAddon);
        }
    }

    checkAllSelected() {
        if (this.selectedItems.length === 0) {
            this.allSelected = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedItems.length === this.addons.length) {
            this.allSelected = TristateCheckboxState.CHECKED;
        } else {
            this.allSelected = TristateCheckboxState.CHECKED_PARTIALLY;
        }
        this.changeDetector.markForCheck();
    }

    doOnRowSelect(event) {
        let focusedHtmlTag = event.originalEvent.target.tagName;
        if (focusedHtmlTag === this.EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_TEXTAREA ||
            focusedHtmlTag === this.EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_INPUT) {
            return;
        }

        if ((focusedHtmlTag === this.EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_LABEL ||
            focusedHtmlTag === this.EXCLUDED_FROM_DOUBLECLICK_EVENT_TAG_SPAN) &&
            event.originalEvent.target.className.indexOf('checkbox') !== -1) {
            return;
        }

        if (this.bulkAddonsOnly) {
            this.keepSelectedItemIndex(event);
            setTimeout(() => this.restoreSelectionAndResetHotkeys(), 100);
        } else if (!this.tabViewAddedMode) {
            this.addWindowAddon(this.selectedAddon);
        }
    }

    showDialogToAdd() {
    }

    ngOnInit() {
        this.editedButNotAddedPositions = [];
        this.seqNum = this.tabIndex + this.TABLE_SEQUENCE_OFFSET;
        this.changeDetector.markForCheck();
        super.ngOnInit();
        if (this.tabViewAddedMode) {
            this.loadAddons();
        }

        for (let category in AddonCategoryEnum) {
            this.availableCategories.push(category);
        }
        this.filterCategory = this.translatedSelectItemService.buildSortedDropdown(AddonCategoryEnum, 'ADDONS.CATEGORIES.', '');

        this.prepareAddonForFilterValues();
        this.hotkeysService.add(this.spaceHotkey);
    }

    private initTable(): DataTableColumn[] {
        return DataTableColumnBuilder.create()
            .add('name', 'ADDONS.FORM.NAME').makeFilterable().makeSortable()
            .add(`name.${this.userLang}`, 'ADDONS.FORM.NAME').makeFilterable().makeSortable()
            .add('category', 'ADDONS.FORM.CATEGORY').makeFilterable().makeSortable()
            .add('pcn', 'ADDONS.FORM.PCN').makeFilterable().makeSortable()
            .add('supplier.companyName', 'ADDONS.FORM.SUPPLIER').makeFilterable().makeSortable()
            .add('supplier.name', 'ADDONS.FORM.SUPPLIER').makeFilterable().makeSortable()
            .add('price', 'ADDONS.FORM.PRICE')
            .add('addonFor', 'ADDONS.FORM.ADDON_FOR').makeFilterable()
            .add('quantity', 'OFFER.POSITIONS.FORM.QUANTITY')
            .add('lockRecalculateQuantity', 'OFFER.POSITIONS.FORM.LOCK_RECALCULATE_QUANTITY')
            .add('quantityType', 'ADDONS.FORM.QUANTITY_TYPE')
            .add('comment', 'OFFER.POSITIONS.ACTIONS.TOOLTIPS.SHOW_DESCRIPTION')
            .build();
    }

    prepareAddonForFilterValues() {
        this.filterAddonFor = this.translatedSelectItemService.buildSortedDropdown(AddonFor, 'ADDONS.FOR.', '').pipe(
            map(addonFor => addonFor.filter(af => af.value !== AddonFor.BULK)));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('searchedName' in changes) {
            this.updateNameSearch();
        }
        const suppliersChange = changes['suppliers'];
        if (suppliersChange && !suppliersChange.isFirstChange()) {
            DatatableHelper.reload(this.datatable);
        }
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.spaceHotkey);
        super.ngOnDestroy();
    }

    submit() {
        return undefined;
    }

    selectAllChange() {
        this.selectedItems = [];

        if (this.allSelected === TristateCheckboxState.CHECKED) {
            for (let addon of this.addons) {
                this.selectItem(addon, false);
            }
        } else if (this.allSelected === TristateCheckboxState.UNCHECKED) {
            for (let addon of this.addons) {
                if (this.datatable.isRowExpanded(addon)) {
                    this.datatable.toggleRow(addon);
                }
            }
        }

        this.changeDetector.markForCheck();
    }

    isSelectedItem(item: PositionListAddon): boolean {
        return this.getSelectedItem(item.id) != null;
    }

    private getSelectedItem(id: number): PositionListAddon {
        return this.selectedItems.find(selectedItem => selectedItem.id === id);
    }

    selectItem(item: PositionListAddon, doAllSelectedCheck = true) {
        if (this.isSelectedItem(item)) {
            this.selectedItems.splice(this.selectedItems.indexOf(this.getSelectedItem(item.id)), 1);
            if (this.datatable.isRowExpanded(item)) {
                this.datatable.toggleRow(item);
            }
        } else {
            if (item.chooseColor && item.availableCoreColors && item.availableCoreColors.length === 1) {
                item.selectedCoreColor = item.availableCoreColors[0].value;
            }

            this.selectedItems.push(item);
            if (!this.datatable.isRowExpanded(item)) {
                this.datatable.toggleRow(item);
            }

        }
        if (doAllSelectedCheck) {
            this.checkAllSelected();
        }
    }

    loadAddonsLazy(event: LazyLoadEvent) {
        this.allSelected = TristateCheckboxState.UNCHECKED;
        this.lastLoadEvent = event;
        this.validationErrors = {};
        if (this.offer != null) {
            this.offerId = this.offer.id;
        }
        const filters = this.addDefaultFilters(event.filters);
        return forkJoin({
            addons: this.addonService.getItems(event.first, event.rows, filters, event.sortField, event.sortOrder),
            counts: !!this.searchedName ? this.addonService.countByCategories(filters) : of<AddonCategoryCount[]>([])
        }).pipe(
            finalize(() => this.hideDataLoadingIndicator()),
            takeUntil(this.componentDestroyed)
        ).subscribe({
            next: data => {
                this.totalRecords = data.addons.totalRecords;
                this.addons = this.wrapAddons(data.addons.data);

                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);

                this.selectedAddon = this.addons[0];
                DatatableHelper.focusOnRowIfNotEditingFilters(event);
                this.tableDataLoaded.emit();
                this.changeDetector.markForCheck();
                this.searchedNameCategoryResultsChange.emit(data.counts);
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                console.info('AddonPositionComponent `loadAddonsLazy` completed!');
            }
        });
    }

    private loadAddons() {
        this.validationErrors = {};
        if (this.addedWindowAddons && this.addedWindowAddons.length > 0) {
            this.addons = this.addedWindowAddons;
            this.selectedAddon = this.addons[0];
            DatatableHelper.focusOnTableRow(this.datatable);
            this.changeDetector.markForCheck();
        }
        this.hideDataLoadingIndicator();
    }

    convertEmptyColors(): void {
        this.addons.forEach(addon => {
            if ('' + addon.selectedInsideColor === 'undefined') {
                addon.selectedInsideColor = undefined;
            }

            if ('' + addon.selectedOutsideColor === 'undefined') {
                addon.selectedOutsideColor = undefined;
            }
        });
    }

    validateSelectedItems(): boolean {
        this.convertEmptyColors();
        this.validationErrors = {};
        let valid = this.validateSelected(this.selectedItems);
        this.changeDetector.markForCheck();

        return valid;
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    addWindowAddon(addon: PositionListAddon) {
        this.validationErrors = {};
        let addonArray: PositionListAddon[] = [addon];
        let valid = this.validateSelected(addonArray);
        let windowAddonSaveData;
        if (valid) {
            if (this.addedWindowAddons.find(addedAddon => addedAddon.id === addon.id)) {
                return;
            }
            let quantity = this.calculateQuantity(addon);
            let windowAddon = new WindowAddon(addon.id, addon.quantityType, addon.otherInfo, quantity);
            windowAddon.lockRecalculateQuantity = addon.lockRecalculateQuantity;
            windowAddonSaveData = new WindowAddonSaveData(true, windowAddon, WindowAddonActionType.ADD, addon);
            this.lastAddedId = addon.id;
            this.loadAddonsLazy(this.createDatatableLazyLoadMetadata());
        } else {
            windowAddonSaveData = new WindowAddonSaveData(false);
        }
        this.changeDetector.markForCheck();
        this.windowAddonSaved.emit(windowAddonSaveData);
    }

    deleteWindowAddon(addon: PositionListAddon) {
        this.addons = this.addons.filter(addedAddons => addedAddons.id !== addon.id);
        if (this.datatable.filteredValue != null) {
            this.datatable.filteredValue = this.datatable.filteredValue
                .filter(filteredAddon => filteredAddon.id !== addon.id);
        }
        this.windowAddonRemoved.emit(addon.id);
        this.selectedAddon = this.addons[0];
        DatatableHelper.focusOnTableRow(this.datatable);
        this.validateSelected(this.addons);
        this.changeDetector.markForCheck();
    }

    editWindowAddon(addon: PositionListAddon) {
        if (this.tabViewAddedMode) {
            let valid = this.validateSelected(this.addons);
            let windowAddonSaveData;
            if (valid) {
                let quantity = this.calculateQuantity(addon);
                let windowAddon = new WindowAddon(addon.id, addon.quantityType, addon.otherInfo, quantity);
                windowAddon.lockRecalculateQuantity = addon.lockRecalculateQuantity;
                windowAddonSaveData = new WindowAddonSaveData(true, windowAddon, WindowAddonActionType.EDIT);
            } else {
                windowAddonSaveData = new WindowAddonSaveData(false);
            }
            this.changeDetector.markForCheck();
            this.windowAddonSaved.emit(windowAddonSaveData);
        } else if (!this.bulkAddonsOnly) {
            this.editedButNotAddedPositions.push(addon.id);
        }
    }

    editAddonInAddMode(addon) {
        if (this.tabViewAddedMode) {
            this.editWindowAddon(addon);
        }
    }

    onQuantityChange(value, addon) {
        const addonId = addon.id + "_";
        Object.keys(this.validationErrors).filter(key => key.startsWith(addonId))
            .forEach(key => this.validationErrors[key] = undefined);
        addon.quantity = value;
        addon.lockRecalculateQuantity = true;
        this.changeDetector.markForCheck();
    }

    private calculateQuantity(addon: PositionListAddon) {
        let quantity: number;
        if (AddonPositionComponent.usesMeterAsQuantity(addon)) {
            quantity = +addon.quantity.toString().replace(",", ".") || 1;
        } else {
            quantity = +addon.quantity || 1;
        }
        if (quantity <= 0) {
            quantity = 1;
        }

        return quantity;
    }

    removeFromHotKeyService() {
        this.hotkeysService.remove(this.spaceHotkey);
    }

    addSpaceToHotKeyService() {
        this.hotkeysService.add(this.spaceHotkey);
    }

    validateSelected(selected: PositionListAddon[]): boolean {
        this.validationErrors = {};

        return new BulkAddonPositionValidator().validate(selected, this.bulkAddonsOnly, this.validationErrors);
    }

    static usesMeterAsQuantity(addon: PositionListAddon) {
        return addon.quantityType === QuantityType.METER;
    }

    clearError(controlName) {
        if (this.validationErrors) {
            this.validationErrors[controlName] = undefined;
        }
        this.changeDetector.markForCheck();
    }

    nothingSelected() {
        return this.selectedItems.length <= 0;
    }

    private wrapAddons(data: Addon[]): PositionListAddon[] {
        if (!data) {
            return [];
        }

        if (!this.bulkAddonsOnly) {
            let dataSource;
            if (this.roofWindowsDimension) {
                dataSource = AddonDefaultQuantityCalculator.prepareRoofDataSource(this.roofWindowsDimension);
            } else if (this.systemType === SystemType.GATE) {
                dataSource = AddonDefaultQuantityCalculator.prepareGateDataSource();
            } else {
                dataSource = AddonDefaultQuantityCalculator.prepareDataSource(this.windowAddonData as DrawingData, this.staticData);
            }
            return data.map(addon => {
                let alreadyVisibleAddon = this.addons == null ? null : this.addons.find(presentAddon => presentAddon.id === addon.id);
                if (alreadyVisibleAddon != null) {
                    return alreadyVisibleAddon;
                } else {
                    let defaultQuantity = AddonDefaultQuantityCalculator.calculate(addon, dataSource);

                    return new PositionListAddon(addon, this.userLang, null, defaultQuantity);
                }
            });
        }

        return data.map(addon => {
            return new PositionListAddon(addon, this.userLang);
        });
    }

    private addDefaultFilters(filters: { [field: string]: FilterMetadata }) {
        if (!filters) {
            filters = {};
        }

        filters = this.bulkAddonsOnly ? this.addBulkAddonFilters(filters) : this.addWindowAddonForCategoryFilters(filters);
        filters["active"] = {value: "true"};
        filters["forOffer"] = {value: "true"};
        filters["offerId"] = this.offerId != undefined ? {value: `${this.offerId}`} : undefined;
        filters["offerPositionId"] = this.offerPositionId != undefined ? {value: `${this.offerPositionId}`} : undefined;
        filters['includeGeneralAddons'] = {value: `${this.includeGeneralAddons}`};
        filters['includeSubsystemAddons'] = {value: `${this.includeSubsystemAddons}`};
        filters["availableIn"] = {value: [AddonAvailableIn.BOTH, AddonAvailableIn.DIALOG]};
        if (this.systemType === SystemType.WINDOW && this.systemId != undefined) {
            filters["windowSystemId"] = {value: `${this.systemId}`};
        } else {
            filters["windowSystemId"] = undefined;
        }
        if (this.systemType === SystemType.GATE && this.systemId != undefined) {
            filters["gateSystemId"] = {value: `${this.systemId}`};
        } else {
            filters["gateSystemId"] = undefined;
        }
        filters['withColors'] = {value: 'true'};
        filters['withPrices'] = {value: 'true'};
        if (this.suppliers != undefined && this.includeGeneralAddons) {
            filters['incomingSupplierId'] = {value: this.suppliers.map(s => s.id).join()};
        } else {
            filters['incomingSupplierId'] = undefined;
        }
        return filters;
    }

    private addBulkAddonFilters(filters: { [field: string]: FilterMetadata }) {
        filters["addonFor"] = {
            value: AddonFor[AddonFor.BULK]
        };

        if (this.selectedCategory) {
            filters["category"] = {
                value: AddonCategoryEnum[this.selectedCategory]
            };
        }

        return filters;
    }

    private addWindowAddonForCategoryFilters(filters: { [field: string]: FilterMetadata }) {
        let addonForFilter = this.datatable.filters["addonFor"];
        if (addonForFilter != undefined && addonForFilter.value != undefined && addonForFilter.value.length > 0) {
            filters["addonFor"] = addonForFilter;
        } else {
            filters["addonFor"] = {value: Object.keys(AddonFor).filter(af => af !== AddonFor[AddonFor.BULK]).join(',')};
        }

        if (this.selectedCategory) {
            filters["category"] = {
                value: AddonCategoryEnum[this.selectedCategory]
            };
        }

        if (this.selectedCategoryId) {
            filters["includeWithCategoryId"] = {value: this.selectedCategoryId};
        }

        let idsToExclude = this.windowAddonData.addons.map(addon => addon.addonId).concat(this.addedWindowAddons.map(addon => addon.id));
        if (this.lastAddedId) {
            idsToExclude.push(this.lastAddedId);
        }
        if (idsToExclude.length > 0) {
            filters["idsToExclude"] = {
                value: idsToExclude.join(",")
            };
        }
        filters['windowAddons'] = {value: 'true'};

        return filters;
    }

    onRowSelect(event) {
        return undefined;
    }

    private createDatatableLazyLoadMetadata(): LazyLoadEvent {
        return this.datatable.createLazyLoadMetadata();
    }

    updateFilterValue() {
        this.tableDataLoaded.emit();
        this.changeDetector.markForCheck();
    }

    ngAfterViewInit() {
        this.updateNameSearch();
        // delay a bit since looks like in designer this happens too fast
        setTimeout(() => this.showHideFilters(false), 50);
        this.hideViewLoadingIndicator();
    }

    private updateNameSearch() {
        if (!this.searchedName) {
            return;
        }
        this.datatable.filter(this.searchedName, 'name', 'contains');
        if (this.searchedName) {
            const nameColumn = this.getColumns().find(col => col.field === 'name');
            nameColumn.defaultFilterValue = { value: this.searchedName };
            this.resetTableFilterInputs(this.datatable);
        }
    }

    handleShowImage(addonId: number, header: string): void {
        this.addonPreviewData = new WindowComponentPreviewData(this.addonService.getImageForItem(addonId), header);
        this.changeDetector.markForCheck();
    }

    isDeleteButtonDisabled(addon: PositionListAddon): boolean {
        return this.readOnlyMode || !this.tabViewAddedMode || (!this.sidebarOnlyMode && !addon.removable && addon.defaultAddon);
    }

    isAllowOnlyIntegers(addon: Addon): boolean {
        return addonQuantityTypeIntegerOnly(addon.quantityType);
    }

    getPriceInSubsystemDefaultCurrency(addon: Addon): string {
        if (addon.price.value == null) {
            return '';
        }
        if (addon.subsystemId == null) {
            return this.exchangeService.getPriceInCurrency(addon.price.value, Currencies.PLN, this.subsystemDefaultCurrency);
        }

        return addon.price.value.toFixed(2);
    }
}
