<p-dialog [resizable]="false" [visible]="tabPanelsReady" (onHide)="emitCloseDialog()"
          class="dialog-window" [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.DRAWING.ADDONS.ADD' | translate }}
        </div>
    </p-header>
    <div class="bulk-addon-dialog-container">
        <div class="new-form-field-error-message" *ngIf="availableCategories.length === 0">
            {{'OFFER.DRAWING.ADDONS.NO_ADDONS_FOR_SELECTION'| translate}}
        </div>

        <p-tabMenu [model]="items" [activeItem]="activeItem" [ngClass]="{'disabledEvents': validationErrorsPresent()}"></p-tabMenu>

        <div *ngIf="availableCategories.length !== 0" style="min-height: 60vh" class="flex-row-container">
            <div *ngIf="activeItem != undefined && activeItem === selectAddonItem" class="second-level-menu compact">

                <app-search [component]="'add-window-addon-search'" (searched)="handleSearch($event)"></app-search>

                <div *ngFor="let tabPanel of tabPanelsData" class="navigation-element compact" (click)="handleChange(tabPanel)"
                     [class.active]="currentTabId == tabPanel.index">

                    <ng-container *vLet="getCategoryLabelTranslation(tabPanel) as text">
                        <div class="name" [pTooltip]="text" [tooltipDisabled]="text.length <= 17">
                            {{ text }}
                        </div>
                        <div *ngIf="searchedNameCategoryCounts != undefined">
                            {{ searchedNameCategoryCounts.get(tabPanel.name) }}
                        </div>
                    </ng-container>
                </div>
            </div>

            <div class="addons-tab-view-list-content">
                <ng-container *ngFor="let tabPanel of tabPanelsData">
                    <div [class.hidden]="currentTabId !== tabPanel.index">
                        <app-addon-position *ngIf="panelContentVisible[tabPanel.index]"
                                            (windowAddonSaved)="saveAddon($event)"
                                            (tableDataLoaded)="tableDataLoaded()" [offerId]="offerId"
                                            [offerPositionId]="offerPositionId"
                                            [tabViewAddedMode]="false" [selectedCategory]="selectedCategory"
                                            [selectedCategoryId]="selectedCategoryId"
                                            [windowAddonData]="windowAddonData"
                                            [roofWindowsDimension]="roofWindowsDimension"
                                            [systemType]="systemType"
                                            [addedWindowAddons]="addedWindowAddons" [tabIndex]="tabPanel.index"
                                            [systemId]="systemId" [sidebarOnlyMode]="sidebarOnlyMode"
                                            [staticData]="staticData" [includeGeneralAddons]="true"
                                            [includeSubsystemAddons]="true"
                                            [subsystemDefaultCurrency]="subsystemDefaultCurrency"></app-addon-position>
                    </div>
                </ng-container>
                <div [class.hidden]="currentTabId !== tabPanelsData.length">
                    <app-addon-position *ngIf="panelContentVisible[tabPanelsData.length]"
                                        (windowAddonSaved)="saveAddon($event)"
                                        (tableDataLoaded)="tableDataLoaded()" [offerId]="offerId"
                                        [offerPositionId]="offerPositionId"
                                        [tabViewAddedMode]="false"
                                        [windowAddonData]="windowAddonData"
                                        [roofWindowsDimension]="roofWindowsDimension"
                                        [systemType]="systemType"
                                        [addedWindowAddons]="addedWindowAddons" [tabIndex]="tabPanelsData.length"
                                        [systemId]="systemId" [sidebarOnlyMode]="sidebarOnlyMode"
                                        [staticData]="staticData" [includeGeneralAddons]="true"
                                        [includeSubsystemAddons]="true"
                                        [subsystemDefaultCurrency]="subsystemDefaultCurrency"
                                        [searchedName]="searchedName"
                                        (searchedNameCategoryResultsChange)="handleSearchedNameCategoryResults($event)"></app-addon-position>
                </div>
                <app-addon-position *ngIf="activeItem != undefined && activeItem === addedAddonsItem"
                                    (windowAddonSaved)="saveAddon($event)"
                                    (windowAddonRemoved)="removeAddon($event)" [offerId]="offerId"
                                    [offerPositionId]="offerPositionId"
                                    [tabViewAddedMode]="true" [selectedCategory]="selectedCategory"
                                    [selectedCategoryId]="selectedCategoryId"
                                    [windowAddonData]="windowAddonData"
                                    [roofWindowsDimension]="roofWindowsDimension"
                                    [systemType]="systemType"
                                    [addedWindowAddons]="addedWindowAddons" [tabIndex]="0"
                                    [sidebarOnlyMode]="sidebarOnlyMode" [readOnlyMode]="readOnlyMode"
                                    [staticData]="staticData" [includeGeneralAddons]="true"
                                    [includeSubsystemAddons]="true"
                                    [subsystemDefaultCurrency]="subsystemDefaultCurrency"></app-addon-position>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'OFFER.ACTIONS.DIALOGS.BUTTON_CLOSE' | translate }}" type="main-action"
                               [disabled]="validationErrorsPresent()" [size]="40" (onClick)="emitCloseDialog()"></app-simple-button>
        </div>
    </p-footer>

</p-dialog>
