import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {saveAs} from 'file-saver';
import * as moment from "moment";
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {forkJoin, from, Observable, of} from 'rxjs';
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import {ApplicationPrivilege} from '../../../auth/application-privilege';
import {Permissions} from '../../../auth/permission.service';
import {StorageKey, StorageService} from "../../../auth/storage.service";
import {UserUiConfigService} from '../../../auth/uiconfig/userUiConfig.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {OfferStatus} from "../../../common/enums/OfferStatus";
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {OnceFlag} from '../../../shared/once-flag';
import {SupportedLanguage, SupportedLanguages} from "../../../supportedLanguages";
import {Subsystem, SubsystemType} from '../../subsystem/subsystem';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {UserService} from "../../user/user.service";
import {OffersService} from '../offer-service';
import {PrintAction} from '../printAction';
import {DocumentFormat} from './document-format.enum';
import {DocumentTemplateType} from './document-template-type.enum';
import {DocumentType} from './DocumentType';
import {PrintConfig} from "./print-config";
import {PrintDialogTab} from "./print-dialog-tab";
import {PrintOptions} from './print-options';
import {PrintPricesDetailLevel} from './print-prices-detail-level.enum';
import {PrintService} from './print-service';
import {PrintSubsystemConfig} from "./print-subsystem-config";
import {PrintableSection} from './printable-section.enum';
import {SinglePrintConfig} from "./single-print-config";
import {ExchangeService} from "../../../common/exchange.service";
import {PrintHistoryEntry} from "../offer";

declare let printJS: any;

export class PrintDialogTableDataItem {
    parentTab: PrintDialogTab;
    parentDocumentTemplateTypes: DocumentTemplateType[];
    documentType: DocumentType;
    actions: PrintAction[];
    documentTemplate: DocumentTemplateType;
    labelKey: string;

    constructor(documentType: DocumentType, actions: PrintAction[], labelKey: string, parentTab: PrintDialogTab,
                documentTemplateOverride?: DocumentTemplateType) {
        this.parentTab = parentTab;
        this.documentType = documentType;
        this.actions = actions;
        this.documentTemplate = documentTemplateOverride;
        this.labelKey = labelKey;
        if (parentTab === PrintDialogTab.GLAMOUR) {
            this.parentDocumentTemplateTypes = [null];
        } else {
            this.parentDocumentTemplateTypes =
                PrintDialogComponent.DOCUMENT_TYPES_WITH_MEDIUM_TEMPLATE.includes(documentType) ?
                    [DocumentTemplateType.MEDIUM, DocumentTemplateType.FULL] : [DocumentTemplateType.FULL];
        }
    }
}

export class PrintableItem {
    itemId: number;
    numberForFilename: string;
    status?: OfferStatus;
    printHistoryEntry?: PrintHistoryEntry;

    constructor(itemId: number, numberForFilename: string) {
        this.itemId = itemId;
        this.numberForFilename = numberForFilename;
    }
}

export enum PrintStatus {
    NONE,
    PRINTED
}

@Component({
    selector: 'app-print-dialog',
    templateUrl: './print-dialog.component.html',
    styleUrls: ['./print-dialog.component.css', '../../shared-styles.css'],
    providers: [PrintService, DataServiceHelper, OffersService, SubsystemService, UserService, ExchangeService]
})
export class PrintDialogComponent implements OnInit {

    static readonly DOCUMENT_TYPES_WITH_MEDIUM_TEMPLATE = [
        DocumentType.ORDER_CONFIRMATION,
        DocumentType.RETAIL_OFFER,
        DocumentType.OFFER,
        DocumentType.DISTRIBUTION_OFFER,
        DocumentType.ORDER,
        DocumentType.ORDER_WITH_FIXED_EXCHANGE_RATE,
        DocumentType.PRODUCTION_ORDER,
        DocumentType.TECHNICAL_DRAWING
    ];
    private static readonly PRINT_BLOCK_ID = 'PrintDialogComponent print';

    @Input() printableSection: PrintableSection;
    @Input() selectedItems: PrintableItem[] = [];
    @Input() selectedPositionsIds: number[] = null;
    @Input() selectedConjunctionsIds: number[] = null;
    @Output() onCloseDialog = new EventEmitter<PrintStatus>();
    @ViewChild('dialog', {static: true}) dialog: Dialog;

    printableDocumentsPerTabAndTemplate = new Map<PrintDialogTab, Map<DocumentTemplateType, PrintDialogTableDataItem[]>>();
    printConfigsPerTab: Map<PrintDialogTab, PrintConfig> = new Map<PrintDialogTab, PrintConfig>();
    templateTypeItemsPerTab: Map<PrintDialogTab, SelectItem[]> = new Map<PrintDialogTab, SelectItem[]>();
    printSubsystemConfig: PrintSubsystemConfig;
    subsystem: Subsystem;
    priceDetailLevels: SelectItem[];
    availableTabs: PrintDialogTab[];

    selectedTemplatePerTab: Map<PrintDialogTab, DocumentTemplateType> = new Map<PrintDialogTab, DocumentTemplateType>();
    selectedTab: PrintDialogTab;
    dialogReady = false;

    documentTemplateType = DocumentTemplateType;
    printDialogTab = PrintDialogTab;
    printPricesDetailLevel = PrintPricesDetailLevel;

    readonly supportedLanguages = SupportedLanguages.languages;
    selectedLanguages: Map<string, SupportedLanguage> = new Map<string, SupportedLanguage>();
    showLanguageSelector = false;
    typeToChangeLang: DocumentType;
    templateToChangeLang: DocumentTemplateType;
    printLanguages: Map<string, string>;

    status = PrintStatus.NONE;
    printableSectionEnum = PrintableSection;

    private enableOfferExport = false;
    private enableGeneratingOfferLink = true;

    offerLinkExpiration: Date;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private exchangeService: ExchangeService,
                public permissions: Permissions,
                private userUiConfigService: UserUiConfigService,
                public printService: PrintService,
                private offerService: OffersService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private growls: GrowlMessageController,
                private blockUiController: BlockUiController,
                private subsystemService: SubsystemService,
                private userService: UserService,
                private storage: StorageService) {
        this.priceDetailLevels = [
            {label: 'OFFER.POSITIONS.DIALOGS.PRINT.DONT_HIDE_PRICES', value: PrintPricesDetailLevel.DO_NOT_HIDE},
            {label: 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_PRICES_DETAILS', value: PrintPricesDetailLevel.HIDE_DETAILS},
            {label: 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_ALL_PRICES', value: PrintPricesDetailLevel.HIDE_ALL}
        ];
    }

    ngOnInit() {
        this.printLanguages = this.userUiConfigService.getPrintLanguages();
        const configObservable = (this.printableSection === PrintableSection.OFFERS
            && !this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) ?
            this.printService.getPrintDialogConfig() : of(new PrintSubsystemConfig());
        forkJoin({
            printSubsystemConfig: configObservable,
            subsystem: this.subsystemService.getSubsystemForCurrentUser(),
            hasExport: this.printableSection === PrintableSection.ORDERS ? this.userService.isOfferExportEnabled() : of(false),
            offerLinkDurationHours: this.offerService.getOfferLinkDurationHours()
        }).subscribe({
            next: data => {
                this.printSubsystemConfig = data.printSubsystemConfig;
                this.subsystem = data.subsystem;
                this.enableOfferExport = data.hasExport;
                this.offerLinkExpiration = moment().add(data.offerLinkDurationHours, 'hours').toDate();
                this.setUp();
                this.restoreLastUsedSettings();
                this.centerDialog();
                this.dialogReady = true;
            },
            error: error => this.errors.handle(error)
        });
    }

    private preparePrintableDocuments(): PrintDialogTableDataItem[] {
        let allPrintableDocuments: PrintDialogTableDataItem[] = [];
        let addItem = (documentType: DocumentType, permittedRoles?: ApplicationPrivilege[], labelKey?: string,
                       documentTemplateOverride?: DocumentTemplateType, parentTab = PrintDialogTab.REGULAR) => {
            if (permittedRoles == undefined || this.permissions.isPermitted({roles: permittedRoles})) {
                allPrintableDocuments.push(
                    new PrintDialogTableDataItem(documentType,
                        this.getAvailableActions(documentType),
                        labelKey ||
                        `OFFER.POSITIONS.DIALOGS.PRINT.PRINTABLE_SECTION.${this.printableSection}.${documentType}`,
                        parentTab, documentTemplateOverride));
            }
        };
        switch (this.printableSection) {
            case PrintableSection.OFFERS:
                addItem(DocumentType.GLAMOUR_OFFER, ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA'],
                    'OFFER.POSITIONS.DIALOGS.PRINT.PRINTABLE_SECTION.GLAMOUR_SHORT', DocumentTemplateType.MEDIUM,
                    PrintDialogTab.GLAMOUR);
                addItem(DocumentType.GLAMOUR_OFFER, ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA'],
                    'OFFER.POSITIONS.DIALOGS.PRINT.PRINTABLE_SECTION.GLAMOUR_FULL', DocumentTemplateType.FULL,
                    PrintDialogTab.GLAMOUR);
                if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']})) {
                    if (this.subsystem == undefined || this.subsystem.type !== SubsystemType.INVESTMENT) {
                        addItem(DocumentType.DISTRIBUTION_OFFER);
                    }
                }
                addItem(DocumentType.OFFER, ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']);
                addItem(DocumentType.RETAIL_OFFER, ['ROLE_SPRZEDAWCA']);
                addItem(DocumentType.TECHNICAL_DRAWING);
                if (this.enableOfferExport) {
                    addItem(DocumentType.MACHINE_READABLE_EXPORT, ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR'],
                        'OFFER.POSITIONS.DIALOGS.PRINT.PRINTABLE_SECTION.EXPORT', DocumentTemplateType.FULL, PrintDialogTab.EXPORT);
                }
                if (this.enableGeneratingOfferLink) {
                    addItem(DocumentType.PREAUTHENTICATED_OFFER_LINK, ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN'],
                        'OFFER.POSITIONS.DIALOGS.PRINT.PRINTABLE_SECTION.PREAUTHENTICATED_OFFER_LINK',
                        DocumentTemplateType.FULL, PrintDialogTab.EXPORT);
                }
                break;
            case PrintableSection.ORDERS:
                addItem(DocumentType.ORDER, ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC']);
                addItem(DocumentType.OFFER, ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']);
                addItem(DocumentType.RETAIL_OFFER, ['ROLE_SPRZEDAWCA']);
                if (this.selectedItems.every(item => item.status && item.status === OfferStatus.PAID)) {
                    addItem(DocumentType.ORDER_CONFIRMATION, ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']);
                }
                addItem(DocumentType.TECHNICAL_DRAWING);
                if (this.enableOfferExport) {
                    addItem(DocumentType.MACHINE_READABLE_EXPORT, ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR'],
                        'OFFER.POSITIONS.DIALOGS.PRINT.PRINTABLE_SECTION.EXPORT', DocumentTemplateType.FULL, PrintDialogTab.EXPORT);
                }
                addItem(DocumentType.ORDER_WITH_FIXED_EXCHANGE_RATE, ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']);
                break;
            case PrintableSection.HISTORICAL_ORDERS:
                addItem(DocumentType.ORDER_WITH_FIXED_EXCHANGE_RATE, ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC']);
                break;
            case PrintableSection.PRODUCTION_ORDERS:
                addItem(DocumentType.PRODUCTION_ORDER);
                addItem(DocumentType.TECHNICAL_DRAWING);
                break;
            case PrintableSection.COMPLAINTS:
                addItem(DocumentType.COMPLAINT);
                break;
            case PrintableSection.DELIVERY_LISTS:
                addItem(DocumentType.DELIVERY_LIST);
                addItem(DocumentType.CHECKLIST, ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']);
                break;
            case PrintableSection.CONJUNCTIONS:
                addItem(DocumentType.CONJUNCTION);
                break;
            default:
                throw new Error("Unsupported printable section: " + this.printableSection);
        }
        return allPrintableDocuments;
    }

    private setUp(): void {
        let templateTypesPerTab = new Map<PrintDialogTab, DocumentTemplateType[]>();
        this.preparePrintableDocuments().forEach(doc => {
            if (!this.printableDocumentsPerTabAndTemplate.has(doc.parentTab)) {
                this.printableDocumentsPerTabAndTemplate.set(doc.parentTab, new Map<DocumentTemplateType, PrintDialogTableDataItem[]>());
            }
            if (!templateTypesPerTab.has(doc.parentTab)) {
                templateTypesPerTab.set(doc.parentTab, []);
            }
            doc.parentDocumentTemplateTypes.forEach(templateType => {
                if (!this.printableDocumentsPerTabAndTemplate.get(doc.parentTab).has(templateType)) {
                    this.printableDocumentsPerTabAndTemplate.get(doc.parentTab).set(templateType, []);
                }
                if (templateTypesPerTab.get(doc.parentTab).indexOf(templateType) === -1) {
                    templateTypesPerTab.get(doc.parentTab).push(templateType);
                }
                this.printableDocumentsPerTabAndTemplate.get(doc.parentTab).get(templateType).push(doc);
            });
            this.prepareLanguageOptions(doc);
        });

        templateTypesPerTab.forEach((templateTypes: DocumentTemplateType[], tab: PrintDialogTab) => {
            this.templateTypeItemsPerTab.set(tab,
                templateTypes.map(templateType => ({
                    label: `OFFER.POSITIONS.DIALOGS.PRINT.TEMPLATE.${templateType}`,
                    value: templateType
                })));
            this.selectedTemplatePerTab.set(tab, this.templateTypeItemsPerTab.get(tab)[0].value);
        });

        this.availableTabs = Array.from(templateTypesPerTab.keys());
        this.selectedTab = this.availableTabs[0];
    }

    private getAvailableActions(documentType: DocumentType) {
        switch (documentType) {
            case DocumentType.MACHINE_READABLE_EXPORT:
                return [PrintAction.DOWNLOAD_JSON, PrintAction.DOWNLOAD_XML];
            case DocumentType.PREAUTHENTICATED_OFFER_LINK:
                return [PrintAction.COPY_CONTENT_TO_CLIPBOARD];
        }
        const msFileDownloadAction = this.printableSection === PrintableSection.DELIVERY_LISTS ?
            PrintAction.DOWNLOAD_XLSX : PrintAction.DOWNLOAD_DOCX;
        const actions = [msFileDownloadAction];
        if (documentType !== DocumentType.CHECKLIST) {
            actions.push(PrintAction.DOWNLOAD_PDF);
            if (this.selectedConjunctionsIds && this.selectedConjunctionsIds.length === 1) {
                actions.push(PrintAction.PRINT);
                return actions;
            }
            if (documentType !== DocumentType.CONJUNCTION && this.selectedItems.length === 1) {
                actions.push(PrintAction.PRINT);
                return actions;
            }
        }
        return actions;
    }

    private restoreLastUsedSettings(): void {
        let configsPerTab: Map<PrintDialogTab, PrintConfig>;
        if (this.printableSection === PrintableSection.HISTORICAL_ORDERS) {
            configsPerTab = new Map<PrintDialogTab, PrintConfig>();
            let printOptions = this.selectedItems[0].printHistoryEntry.printOptions;
            let printConfig = {
                lastChosenDocumentTemplateType: printOptions.documentTemplateType,
                lastSelectedTab: true,
                configsPerTemplateType: new Map<DocumentTemplateType, SinglePrintConfig>()
            };
            printConfig.configsPerTemplateType.set(printOptions.documentTemplateType, printOptions);
            configsPerTab.set(PrintDialogTab.REGULAR, printConfig);
            this.restoreConfigs(configsPerTab);
        } else {
            try {
                configsPerTab = this.userUiConfigService.getPrintConfig(this.printableSection);
                this.restoreConfigs(configsPerTab);
            } catch (e) {
                console.warn('Wrong print configuration saved in userUiConfigService. Generating new one.');
                console.warn(e);
                configsPerTab = new Map<PrintDialogTab, PrintConfig>();
                this.availableTabs.forEach(tab => configsPerTab.set(tab, new PrintConfig(this.printableSection, tab)));
                this.restoreConfigs(configsPerTab);
                this.userUiConfigService.savePrintConfig(this.printableSection, configsPerTab);
            }
        }
        this.printConfigsPerTab = configsPerTab;
    }

    private restoreConfigs(configsPerTab: Map<PrintDialogTab, PrintConfig>): void {
        for (let tab of this.availableTabs) {
            if (!configsPerTab.has(tab)) {
                configsPerTab.set(tab, new PrintConfig(this.printableSection, tab));
            }
            let printConfigForTab = configsPerTab.get(tab);
            if (printConfigForTab.lastSelectedTab) {
                this.selectedTab = tab;
            }
            let possibleTemplates = this.templateTypeItemsPerTab.get(tab).map(item => item.value);
            if (possibleTemplates.indexOf(printConfigForTab.lastChosenDocumentTemplateType) === -1) {
                printConfigForTab.lastChosenDocumentTemplateType = possibleTemplates[0];
            }
            this.selectedTemplatePerTab.set(tab, printConfigForTab.lastChosenDocumentTemplateType);
            for (let templateTypeItem of this.templateTypeItemsPerTab.get(tab)) {
                let templateType = templateTypeItem.value;
                let configsPerTemplate = printConfigForTab.configsPerTemplateType;
                if (!configsPerTemplate.has(templateType)) {
                    configsPerTemplate.set(templateType, new SinglePrintConfig(templateType));
                }
                let config = configsPerTemplate.get(templateType);
                config.showLeaflets = config.showLeaflets && this.printSubsystemConfig.enableLeaflets;
                if (config.printPricesDetailLevel == undefined) {
                    config.printPricesDetailLevel = PrintPricesDetailLevel.DO_NOT_HIDE;
                }
                if (templateType === DocumentTemplateType.FULL) {
                    config.printQuantitiesDetails = true;
                    config.showWeight = true;
                    config.showUpselling = true;
                    config.showRot = true;
                    config.warrantyInfo = true;
                    config.showGrillsLayout = true;
                }
            }
        }
    }

    closeDialog(): void {
        this.dialogHideHelper.call(() => this.onCloseDialog.emit(this.status));
    }

    getIcon(action: PrintAction): string {
        switch (action) {
            case PrintAction.PRINT:
                return 'print';
            case PrintAction.COPY_CONTENT_TO_CLIPBOARD:
                return 'content_copy';
        }
    }

    getIconAwesome(action: PrintAction): string {
        switch (action) {
            case PrintAction.DOWNLOAD_DOCX:
                return 'fa-file-word-o';
            case PrintAction.DOWNLOAD_XLSX:
                return 'fa-file-excel-o';
            case PrintAction.DOWNLOAD_PDF:
                return 'fa-file-pdf-o';
            case PrintAction.DOWNLOAD_JSON:
                return 'fa-file-archive-o';
            case PrintAction.DOWNLOAD_XML:
                return 'fa-file-code-o';
        }
    }

    centerDialog() {
        setTimeout(() => this.dialog.center(), 1);
        this.changeDetector.markForCheck();
    }

    print(documentType: DocumentType, actionType: PrintAction, documentTemplateOverride: DocumentTemplateType) {
        this.blockUiController.block(PrintDialogComponent.PRINT_BLOCK_ID);
        let observable: Observable<any>;
        if (this.printableSection === PrintableSection.CONJUNCTIONS) {
            observable = forkJoin(this.selectedConjunctionsIds
                .map(id => this.generateDocument(this.selectedItems[0], documentType, actionType, documentTemplateOverride, id)));
        } else {
            observable = forkJoin(this.selectedItems
                .map(item => this.generateDocument(item, documentType, actionType, documentTemplateOverride)));
        }
        observable
            .pipe(finalize(() => this.blockUiController.unblock(PrintDialogComponent.PRINT_BLOCK_ID)))
            .subscribe({
                complete: () => {
                    this.status = PrintStatus.PRINTED;
                }
            });
    }

    private generateDocument(item: PrintableItem, documentType: DocumentType, printAction: PrintAction,
                             documentTemplateOverride?: DocumentTemplateType, additionalId?: number): Observable<any> {
        let observable: Observable<File>;
        let documentFormat = this.getDocumentFormat(printAction);
        const printConfig = this.getPrintConfigForSelectedTemplate();
        const documentTypeKey = documentTemplateOverride ? documentType + '_' + documentTemplateOverride : documentType;
        const printOptions = new PrintOptions(documentType, documentTemplateOverride || this.selectedTemplatePerTab.get(this.selectedTab),
            documentFormat, printConfig, this.selectedLanguages.get(documentTypeKey).code);
        this.printConfigsPerTab.forEach((configPerTab, tab) => configPerTab.lastSelectedTab = tab === this.selectedTab);
        this.userUiConfigService.savePrintConfig(this.printableSection, this.printConfigsPerTab,
            documentTypeKey, this.selectedLanguages.get(documentTypeKey).code);
        switch (documentType) {
            case DocumentType.RETAIL_OFFER:
            case DocumentType.OFFER:
            case DocumentType.GLAMOUR_OFFER:
            case DocumentType.DISTRIBUTION_OFFER:
            case DocumentType.ORDER:
            case DocumentType.ORDER_CONFIRMATION:
                observable = this.printService.printOffer(item.itemId, printOptions);
                break;
            case DocumentType.ORDER_WITH_FIXED_EXCHANGE_RATE:
                if (this.printableSection === PrintableSection.HISTORICAL_ORDERS) {
                    printOptions.forceCurrentExchangeRate = false;
                    printOptions.forceExchangeRate = this.selectedItems[0].printHistoryEntry.exchangeRate;
                } else {
                    printOptions.forceCurrentExchangeRate = true;
                }
                printOptions.documentType = DocumentType.ORDER;
                observable = this.printService.printOffer(item.itemId, printOptions);
                break;
            case DocumentType.PRODUCTION_ORDER:
                observable = this.printService.printProductionOrder(item.itemId, printOptions);
                break;
            case DocumentType.TECHNICAL_DRAWING:
                observable =
                    this.printService.printTechnicalDrawing(item.itemId, this.selectedPositionsIds, printOptions,
                        this.printableSection === PrintableSection.PRODUCTION_ORDERS);
                break;
            case DocumentType.COMPLAINT:
                observable = this.printService.printComplaint(item.itemId, this.selectedPositionsIds, printOptions);
                break;
            case DocumentType.DELIVERY_LIST:
                observable = this.printService.printDeliveryList(item.itemId, printOptions);
                break;
            case DocumentType.CHECKLIST:
                observable = this.printService.printChecklist(item.itemId, this.selectedPositionsIds, printOptions);
                break;
            case DocumentType.CONJUNCTION:
                observable = this.printService.printConjunction(additionalId, printOptions);
                break;
            case DocumentType.MACHINE_READABLE_EXPORT:
                observable = this.offerService.getExportData(item.itemId, printAction === PrintAction.DOWNLOAD_XML);
                break;
            case DocumentType.PREAUTHENTICATED_OFFER_LINK:
                observable = this.offerService.getTokenWithAccessToOffer(item.itemId, this.offerLinkExpiration).pipe(map(token => {
                    return new File([`${location.origin}/login?token=${token}`], item.numberForFilename);
                }));
                break;
            default:
                console.warn(`Unsupported document type: ${documentType}`);
                break;
        }
        return observable.pipe(
            mergeMap((file: File) => this.processPrintAction(file, printAction)),
            catchError(error => this.handlePrintError(error))
        );
    }

    getPrintConfigForSelectedTemplate(): SinglePrintConfig {
        return this.printConfigsPerTab.get(this.selectedTab).configsPerTemplateType.get(this.selectedTemplatePerTab.get(this.selectedTab));
    }

    private getDocumentFormat(printAction: PrintAction) {
        switch (printAction) {
            case PrintAction.DOWNLOAD_DOCX:
                return DocumentFormat.DOCX;
            case PrintAction.DOWNLOAD_XLSX:
                return DocumentFormat.XLSX;
            default:
                return DocumentFormat.PDF;
        }
    }

    private processPrintAction(response: File, printAction: PrintAction): Observable<any> {
        switch (printAction) {
            default:
                saveAs(response, response.name, true);
                break;
            case PrintAction.PRINT:
                return from(response.arrayBuffer())
                    .pipe(mergeMap(arrayBuffer => {
                        let blob = new Blob([new Uint8Array(arrayBuffer)], {type: "application/pdf"});
                        let fileURL = URL.createObjectURL(blob);
                        return new Observable(subscriber => {
                            printJS({
                                printable: fileURL,
                                type: 'pdf',
                                showModal: false,
                                onLoadingEnd: () => {
                                    URL.revokeObjectURL(fileURL);
                                    subscriber.complete();
                                },
                                onError: error => {
                                    URL.revokeObjectURL(fileURL);
                                    subscriber.error(error);
                                }
                            });
                        });
                    }));
            case PrintAction.IDLE:
                // noop
                break;
            case PrintAction.COPY_CONTENT_TO_CLIPBOARD:
                return from(response.text())
                    .pipe(mergeMap(text => navigator.clipboard.write([new ClipboardItem({
                            'text/plain': new Blob([text], {type: 'text/plain'}),
                            'text/html': new Blob([`<a href="${text}">${response.name}</a>`], {type: 'text/html'})
                        })])),
                        tap(() => this.growls.info('OFFER.POSITIONS.DIALOGS.PRINT.ACTIONS.GROWLS.COPY_CONTENT_TO_CLIPBOARD.SUCCESS')));
        }
        return of([]);
    }

    private handlePrintError(error): Observable<any> {
        this.errors.handle(error);
        this.growls.error('OFFER.ACTIONS.ON_ERROR.GENERATE_DOCUMENT');
        return of([]);
    }

    tabChanged(event): void {
        this.selectedTab = this.availableTabs[event.index];
        this.centerDialog();
        this.resetShowLanguageSelector();
    }

    templateChanged(template: DocumentTemplateType, tab: PrintDialogTab, config: PrintConfig): void {
        this.selectedTemplatePerTab.set(tab, template);
        config.lastChosenDocumentTemplateType = template;
        this.centerDialog();
        this.resetShowLanguageSelector();
    }

    hidePricesCheckboxClicked() {
        let selectedTemplate = this.getPrintConfigForSelectedTemplate();
        selectedTemplate.printPricesDetailLevel =
            selectedTemplate.printPricesDetailLevel === PrintPricesDetailLevel.DO_NOT_HIDE ?
                PrintPricesDetailLevel.HIDE_ALL : PrintPricesDetailLevel.DO_NOT_HIDE;
    }

    languageChange(stretched: string, documentType: DocumentType, documentTemplate: DocumentTemplateType) {
        const language: SupportedLanguage = this.supportedLanguages.filter(lang => lang.code === stretched)[0];
        const key = documentTemplate ? documentType + '_' + documentTemplate : documentType;
        this.selectedLanguages.set(key, language);
        this.resetShowLanguageSelector();
    }

    openLanguageSelector(documentType: DocumentType, template: DocumentTemplateType) {
        this.typeToChangeLang = documentType;
        this.templateToChangeLang = template;
        this.showLanguageSelector = !this.showLanguageSelector;
    }

    resetShowLanguageSelector() {
        this.showLanguageSelector = false;
        this.typeToChangeLang = null;
        this.templateToChangeLang = null;
    }

    private prepareLanguageOptions(document: PrintDialogTableDataItem) {
        const key = document.documentTemplate ? document.documentType + '_' + document.documentTemplate : document.documentType;
        let language: SupportedLanguage;
        if (this.printLanguages && this.printLanguages[key] !== undefined) {
            language = this.supportedLanguages.filter(lang => lang.code === this.printLanguages[key])[0];
        } else {
            language = this.supportedLanguages.filter(lang => lang.code === this.storage.get(StorageKey.INTERFACE_LANGUAGE))[0];
        }
        this.selectedLanguages.set(key, language);
    }

    createCheckboxId(fieldName: string, tab: PrintDialogTab): string {
        return fieldName + '_' + tab;
    }
}
