import {Observable, of} from 'rxjs';
import {AllAddons} from './all-addons';
import {AddonInterface} from './catalog-data/addon-interface';
import {CustomTranslationsInterface, MASTER_QUARTER_PLACEHOLDER} from './catalog-data/custom-translations-interface';
import {Glazing} from './catalog-data/glazing';
import {MultilanguageFieldInterface} from './catalog-data/multilanguage-field-interface';
import {WindowSystemInterface} from "./catalog-data/window-system-interface";
import {CompositionDistancesUtils} from "./composition-distances";
import {ArcGrillSegment} from './drawing-data/ArcGrillSegment';
import {AreaSpecification} from './drawing-data/AreaSpecification';
import {CutData} from "./drawing-data/CutData";
import {DrawingData, DrawingDataSpecification} from './drawing-data/drawing-data';
import {FieldWithPosition} from './drawing-data/FieldWithPosition';
import {Grill} from './drawing-data/Grill';
import {GrillSegment} from './drawing-data/GrillSegment';
import {HandleDirection} from './drawing-data/HandleDirection';
import {LineGrillSegment} from './drawing-data/LineGrillSegment';
import {SubWindowData} from './drawing-data/SubWindowData';
import {WindowAddon} from './drawing-data/WindowAddon';
import {WindowData} from './drawing-data/WindowData';
import {WindowShape} from './drawing-data/WindowShape';
import {WindowShapeType} from './drawing-data/WindowShapeType';
import {DataKeys, DrawingUtil, MinMaxXY} from './drawing-util';
import {SubwindowSide} from "./enums/subwindow-side";
import {WindowAddonCalculationMode} from './enums/WindowAddonCalculationMode';
import {Guides} from './guides';
import {GuidesDialogData} from './guides-dialog-data';
import {HandleDataFactory} from './handle-data-factory';
import {MaxWindowDimensions} from './max-window-dimensions';
import {AreaNumbersPainter} from './painters/AreaNumbersPainter';
import {HandlePainter} from "./painters/HandlePainter";
import {OpeningLines} from "./painters/OpeningLines";
import {PainterMode} from "./painters/PainterMode";
import {PainterParams} from "./painters/PainterParams";
import {ScalingPainter} from "./painters/ScalingPainter";
import {TextPainter} from './painters/TextPainter';
import {TopDownViewPainter} from "./painters/TopDownViewPainter";
import {WatermarkPainter} from "./painters/WatermarkPainter";
import {WindowPainter} from './painters/WindowPainter';
import {WindowParams} from './painters/WindowParams';
import {PendingGrillData} from './pending-grill-data';
import {ProfilesCompositionDistances, ProfilesCompositionType} from './profiles-composition-distances';
import {Shading, ShadingCalculator, Shadings} from "./ShadingCalculator";
import {StaticDataHelper} from './static-data-helper';
import {SubwindowTypes} from './subwindow-types';
import {AddonDefaultQuantityCalculator} from './utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator';
import {AreaUtils} from "./utils/AreaUtils";
import {CssBuilder} from './utils/CssBuilder';
import {ErrorNames} from './utils/ErrorNames';
import {GrillHelper} from './utils/grill-helper';
import {GrillSegmentGenerator} from './utils/GrillSegmentGenerator';
import {GrillSegmentIdGenerator} from './utils/GrillSegmentIdGenerator';
import {OperationResult} from './utils/OperationResult';
import {PolygonPoint, PolygonPointUtil} from './utils/PolygonPoint';
import {PricingUtils} from "./utils/PricingUtils";
import {RandomIdGenerator} from "./utils/RandomIdGenerator";
import {SortingUtil} from "./utils/SortingUtil";
import {VisibilitySettings} from './utils/VisibilitySettings';
import {WindowAddonMapper} from './utils/WindowAddonMapper';
import {WindowShapeUtil} from './utils/WindowShapeUtil';
import {WindowSystemDefaultsUtil} from "./utils/WindowSystemDefaultsUtil";
import {WindowTypeCodeParser} from "./utils/WindowTypeCodeParser";
import {WindowCalculator} from './window-calculator';
import {WindowDataFactory} from "./window-data-factory";
import {Tool, WindowDesignerInterface} from './window-designer-interface';
import {WindowNeighbourCheck} from './window-neighbour-check';
import {WindowAttributes} from './window-types/window-attributes';
import {WindowTypeCode} from "./window-types/window-type-code";
import {WindowSystemDefaults} from "./entities/window-system-defaults";

export abstract class AbstractWindowDesigner implements WindowDesignerInterface {

    visibilitySettings: VisibilitySettings;
    requiredFieldFilled: boolean;
    mode = Tool.SELECT;
    svg: Snap.Paper;
    data: DrawingData;
    guidesDialogData: GuidesDialogData;
    totalBoundingBox: MinMaxXY;
    totalBoundingClientRect: DOMRect;
    pendingGrillData: PendingGrillData;
    pendingOperationLineHelper: Snap.Element;
    pendingHandleDirection: HandleDirection;
    guides: Guides;
    readonly windowPainter: WindowPainter = new WindowPainter(this);
    subwindowTypes: SubwindowTypes;
    readonly staticData = new StaticDataHelper();
    allAddons: AllAddons;
    dottedOrnamentPattern: Snap.Element;
    stripedFillingPattern: Snap.Element;
    noFillingPattern: Snap.Element;
    clickedSnapElements: { type: string; elements: Snap.Element[] } = {type: undefined, elements: []};
    sidebarOnlyMode = false;
    redrawPreviewMode: boolean;
    maxWindowDimensions = new MaxWindowDimensions();
    readonly profileCompositionDistances = new ProfilesCompositionDistances();
    customTranslations: CustomTranslationsInterface;
    isTerrace: boolean;
    topMullionDimension: boolean;
    ventilationAlwaysOnFrame: boolean;

    protected constructor(protected window: any) {
    }

    protected generateStyleSheet(): string {
        let builder = new CssBuilder();

        builder = builder.setNodePrefix('svg')
            .openNode('text').addKey('text-anchor', 'middle').addKey('fill', '#030303').addKey('font-family', 'Roboto').closeNode()
            .openNode('.glass').addKey('fill', '#EFF9FC').closeNode()
            .openNode('.glass-custom').addKey('fill', '#F9B9E6').closeNode()
            .openNode('.decorative-filling').addKey('fill', '#DCDCDC').closeNode()
            .openNode('.render-area-numbers').addKey('fill', '#FFFFFF').closeNode()
            .openNode('.render-guide').addKey('fill', '#393939').closeNode()
            .openNode('.mainFrameElement').addKey('fill', '#FFFFFF').closeNode()
            .openNode('.dimensions-group').addKey('letter-spacing', '1px').closeNode();

        return builder.build();
    }

    protected getCenterPointForWindow(points: number[]) {
        let x1 = points[0];
        let x2 = points[0] !== points[2] ? points[2] : points[4];
        let y1 = points[1];
        let y2 = points[1] !== points[3] ? points[3] : points[5];
        return {x: (x1 + x2) / 2, y: (y1 + y2) / 2};
    }

    setVisibilitySettings(settings: VisibilitySettings) {
        this.visibilitySettings = settings;
    }

    // NOTE: Put here all the stuff that needs to be done after reloading the DrawingData
    setDrawingData(newDrawingData: DrawingData) {
        this.data = newDrawingData;
        GrillSegmentIdGenerator.init(this.data);
    }

    saveStepInHistory(data?: DrawingData): void {
    }

    cancelLast(): void {
    }

    protected drawWindow(selectedObjects: any[] = undefined, isTerrace = false, painterMode = PainterMode.REGULAR, shaded = false,
                         viewFromOutside = false): void {
        if (this.data && this.data.windows && this.data.specification.frameProfileId != null && !this.sidebarOnlyMode) {
            if (!this.profileCompositionDistances.validate(this.data)) {
                this.showError('OFFER.DRAWING.RESIZE.DISTANCES_NOT_SET');
                return;
            }
            this.initializePainterPatterns();
            this.recalculateTotalBoundingBox();
            let painterParams = new PainterParams(painterMode, () => this.getViewBox(painterMode, true), shaded,
                viewFromOutside, this.data.specification.opening, this.totalBoundingBox, this.topMullionDimension,
                this.ventilationAlwaysOnFrame, this.data.glazingPackageForAreaId != undefined);

            let polygonMapFull = new Map<AreaSpecification, PolygonPoint[]>();
            let polygonMap = new Map<AreaSpecification, number[]>();
            let clickableElements: Snap.Element[] = [];

            let framePoints = WindowCalculator.getOuterFramePoints(this.data.windows, this.data.cuts);
            let shadings: Shadings = ShadingCalculator.generate(this.svg, this.data.windows, this.data.cuts,
                this.profileCompositionDistances, this.totalBoundingBox, painterParams, this.createSnapFilter());
            this.paintOuterFrame(framePoints, clickableElements, painterParams, shadings);
            this.paintWingShadows(framePoints, this.data.windows, painterParams, shadings);
            this.paintWindows(polygonMap, polygonMapFull, clickableElements, painterParams, shadings);
            this.paintFloatingDetails(painterParams);
            if (painterParams.isRegularMode()) {
                this.windowPainter.paintOverlay(painterParams, clickableElements);
                this.paintPendingOperationHelper();
                this.paintCutButtons();
                this.paintWindowAddingButtons();
                this.paintSelectedTabIndicator();
                this.paintGuidancePoints(polygonMap, polygonMapFull);
                this.reselectRecreatedSnapElements(selectedObjects);
            }
            this.paintShadings(framePoints, painterParams, shadings);
            if (this.data.windows.length !== 0) {
                this.guides.drawGuides(painterParams);
            }
            TopDownViewPainter.paint(this, painterParams, isTerrace);
            this.setViewBox(painterParams);
            // this.paintDebugElements(painterParams);
        }
    }

    protected addWindows(windows: WindowData[], attributes: WindowAttributes, allowFestAtHinges: boolean, side?: string): boolean {
        const oldWindowCount = this.data.windows.length;
        let multipleCircularWindows = attributes.eliptical && oldWindowCount;
        if (multipleCircularWindows) {
            this.showError(ErrorNames.BAD_NEIGHBOUR_BUSINNSS_TYPE);
            return false;
        }
        for (let window of windows) {
            if (!WindowNeighbourCheck.canAddNewWindow(window, side, this.data, this.totalBoundingBox, allowFestAtHinges)) {
                this.data.windows.length = oldWindowCount;
                this.showError(ErrorNames.BAD_NEIGHBOUR_BUSINNSS_TYPE);
                return false;
            }
            this.data.windows.push(window);
        }
        this.recalculateTotalBoundingBox();
        if (this.totalBoundingBox.maxX - this.totalBoundingBox.minX > this.maxWindowDimensions.MAX_WIDTH ||
            this.totalBoundingBox.maxY - this.totalBoundingBox.minY > this.maxWindowDimensions.MAX_HEIGHT) {
            this.showError(ErrorNames.CONSTRUCTION_TOO_BIG_ERROR, {
                w: this.maxWindowDimensions.MAX_WIDTH.toString(),
                h: this.maxWindowDimensions.MAX_HEIGHT.toString()
            });
            this.data.windows.length = oldWindowCount;
            this.recalculateTotalBoundingBox();
            return false;
        }
        for (let window of windows) {
            try {
                HandleDataFactory.createStartingHandles(window);
                this.guides.addGuidesForWindow(window, this.data.cuts);
                this.setCommonValues();
                this.changeShapeIfNeeded(attributes);
                this.recalculateAreasSizes(window, this.data.cuts, this.totalBoundingBox, this.profileCompositionDistances,
                    this.isValidationDisabled());
            } catch (e) {
                this.showError(e.name);
                return false;
            }
        }
        this.data.windows.sort(SortingUtil.sortWindows);
        return true;
    }

    changeShapeIfNeeded(attributes: WindowAttributes) {
        if (attributes.eliptical !== WindowShape.isEllipse(this.data.shape)) {
            this.onWindowShapeChange(attributes.eliptical ? WindowShapeType.ELLIPSE : WindowShapeType.RECTANGULAR);
        }
    }

    recalculateAreasSizes(window: WindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                          profileCompositionDistances: ProfilesCompositionDistances,
                          skipValidation: boolean) {
        AreaUtils.recalculateSubwindowAreaSizes(window.subWindows, cuts, totalBoundingBox, profileCompositionDistances,
            skipValidation);
    }

    onWindowShapeChange(newWindowShapeType: WindowShapeType) {
        this.data.shape = WindowShapeUtil.generateDefaultWindowShape(newWindowShapeType, this.totalBoundingBox);
        this.data.cuts.length = 0;
        try {
            this.data.cuts.push(...WindowShapeUtil.generateCutsForWindowShape(this.data.shape, this.totalBoundingBox));
            this.guides.rebuildStructureGuides();
        } catch (e) {
            if (e.name !== ErrorNames.ARC_HEIGHT_TOO_BIG_FOR_GIVEN_WIDTH) {
                throw e;
            }
            this.showError(e.name);
        }
    }

    private paintOuterFrame(framePoints: number[], clickableElements: Snap.Element[], params: PainterParams,
                            shadings: Shadings) {
        if (framePoints.length > 0) {
            let frame = ScalingPainter.path(this.svg, framePoints, WindowParams.regular(params), params);
            if (params.isShaded()) {
                shadings.add(frame, 10, 5);
                let innerPoints = WindowCalculator.getConstructionFrameInnerPoints(framePoints,
                    this.profileCompositionDistances, this.data.windows);
                shadings.addJoints(this.svg, framePoints, innerPoints, params);
            }
            if (this.mode === Tool.CUT) {
                let clickableOuterFrame = this.svg.path(DrawingUtil.pathStringFromPolygonPoints(framePoints, true))
                    .attr({
                        fill: 'none',
                        pointerEvents: 'stroke',
                        strokeWidth: this.getOnePercentOfCanvasSize() * 5
                    });
                clickableOuterFrame.click(event => {
                    this.onFrameClickCutHandler(event);
                });
                clickableElements.push(clickableOuterFrame);
            }
        }
    }

    setCommonValues(): void {
    }

    recalculateTotalBoundingBox(): void {
        const oldBoundingBox = this.totalBoundingBox;
        this.totalBoundingBox = DrawingUtil.calculateTotalBoundingBox(this.data.windows, this);
        this.totalBoundingClientRect = this.svg.node.getBoundingClientRect();
        this.notifySizeChanged(oldBoundingBox, this.totalBoundingBox);
    }

    runWindowsArrayGarbageCollector(): OperationResult {
        return new OperationResult();
    }

    redrawWindow(omitValidation: boolean, omitPricing: boolean, callback?: () => void): Observable<void> {
        return of(undefined);
    }

    calculateSvgCoordsFromMouseEvent(event: MouseEvent): { x: number; y: number } {
        return {x: 0, y: 0};
    }

    onFrameClickCutHandler(event: MouseEvent): void {
    }

    onClickLineGrillHandler(event: MouseEvent): void {
    }

    private drawGrills(subWindow: SubWindowData, area: AreaSpecification, glazingBead: PolygonPoint[],
                       clickableElements: Snap.Element[], params: PainterParams) {
        for (let grill of area.grills) {
            this.drawWindowGrillSegments(grill, subWindow, area, glazingBead, clickableElements, params);
        }
        if (this.windowPainter.isToolActive(Tool.GRILL_TEMPLATE, area)) {
            const glazingBeadNumbers = PolygonPointUtil.toNumbersArray(glazingBead);
            let fieldPositions = GrillHelper.areaHasGrills(area) ? area.fields.map(f => f.positions, []) :
                [glazingBeadNumbers];
            fieldPositions.forEach(position => {
                let part = this.svg.polygon(position);
                part.addClass('glass-area');
                part.attr({pointerEvents: 'fill'});
                part.data(DataKeys.SUBWINDOW, subWindow);
                part.data(DataKeys.AREA, area);
                part.data(DataKeys.FILLING, area.filling);
                this.addGrillTemplateFieldMouseHandlers(part, subWindow, area, glazingBeadNumbers);
                clickableElements.push(part);
            });
        }
    }

    private drawWindowGrillSegments(grill: Grill, subWindow: SubWindowData, area: AreaSpecification,
                                    glazingBeadPoints: PolygonPoint[], clickableElements: Snap.Element[],
                                    params: PainterParams): void {
        let clipPath = this.svg.el('clipPath', {}).toDefs();
        clipPath.attr({id: clipPath.id});

        grill.parentFields.forEach(f => {
            clipPath.add(this.svg.polygon(ScalingPainter.scale(params, f.positions)));
        });
        grill.drawingSegments.forEach(segment => {
            if (GrillSegment.isLine(segment)) {
                this.drawLineGrillSegment(segment, subWindow, area, clipPath, grill, glazingBeadPoints,
                    clickableElements, params, false);
                if (params != undefined && !params.isMode(PainterMode.WEBSHOP)) {
                    this.drawLineGrillSegment(segment, subWindow, area, clipPath, grill, glazingBeadPoints,
                        clickableElements, params, true);
                }
            } else if (GrillSegment.isArc(segment)) {
                this.drawArcGrillSegment(segment, area, clipPath, grill);
            } else {
                let err = new Error("WindowDesignerComponent.drawWindowGrillSegments(): Wrong type: " + segment.type);
                err.name = ErrorNames.NOT_IMPLEMENTED;
                throw err;
            }
        });
    }

    addGrillTemplateFieldMouseHandlers(part: Snap.Element, subWindow: SubWindowData, area: AreaSpecification,
                                       glazingBead: number[]): void {
    }

    addSelectionClickHandler(elem: Snap.Element, multiSelectionClassName: string): void {
    }

    private drawLineGrillSegment(currentGrillSegment: LineGrillSegment, subwindow: SubWindowData,
                                 area: AreaSpecification, clipPath: Snap.Element,
                                 grillSegmentParent: Grill, glazingBeadPoints: PolygonPoint[],
                                 clickableElements: Snap.Element[], params: PainterParams, colored: boolean ): void {
        let attr = colored ? WindowParams.grillsColored(params) : WindowParams.grills(params);
        let polygon = GrillSegmentGenerator.getGrillSegmentPolygon(currentGrillSegment, grillSegmentParent);
        let grill = ScalingPainter.path(this.svg, polygon, attr, params);
        if (colored) {
            grill.attr({clip: clipPath}).addClass(WindowParams.GRILL_COLORED_ELEM)
                .addClass(WindowParams.GRILL_ID_ELEM + grillSegmentParent.id + "-" + grillSegmentParent.colorId);
        } else {
            grill.attr({clip: clipPath}).addClass(WindowParams.MUNTIN_ELEM);
        }
        if (params.isRegularMode()) {
            grill.data(DataKeys.SUBWINDOW, subwindow);
            grill.data(DataKeys.GLAZING_BEAD, glazingBeadPoints);
            grill.data(DataKeys.AREA, area);
            grill.data(DataKeys.GRILL, grillSegmentParent);
            grill.data(DataKeys.GRILL_SEGMENT, currentGrillSegment);
            if (this.windowPainter.isToolActive(Tool.GRILL, area) &&
                GrillHelper.canDependOn(this.pendingGrillData.grill, grillSegmentParent)) {
                let clickable = this.windowPainter.cloneElement(grill);
                clickable.click(event => this.onClickLineGrillHandler(event));
                clickableElements.push(clickable);
            }
            this.addSelectionClickHandler(grill, WindowParams.MUNTIN_ELEM);
        }
    }

    private drawArcGrillSegment(currentGrillSegment: ArcGrillSegment, area: AreaSpecification,
                                clipPath: Snap.Element, grillSegmentParent: Grill): void {
        let err = new Error("WindowDesignerComponent.drawArcGrillSegment(): Not implemented yet");
        err.name = ErrorNames.NOT_IMPLEMENTED;
        throw err;
    }

    private calculateArcGrillPartsAndClipPath(currentGrillSegment: ArcGrillSegment, parts: FieldWithPosition[],
                                              clipPath: Snap.Element, grillSegmentParent: Grill): FieldWithPosition[] {
        let err = new Error("WindowDesignerComponent.calculateArcGrillPartsAndClipPath(): Not implemented yet");
        err.name = ErrorNames.NOT_IMPLEMENTED;
        throw err;
    }

    abstract prepareTabsData();

    getSubWindowName(subWindowData: SubWindowData): string {
        return this.subwindowTypes.get(subWindowData.typeCode).names[this.getCurrentLanguage()] +
            subWindowData.subWindowNameSuffix;
    }

    getOnePercentOfCanvasSize(): number {
        return Math.max(
            this.totalBoundingBox.maxY - this.totalBoundingBox.minY,
            this.totalBoundingBox.maxX - this.totalBoundingBox.minX
        ) / 100;
    }

    private initializePainterPatterns() {
        this.dottedOrnamentPattern = this.svg.circle(1, 1, 1).attr({fill: '#000'}).pattern(0, 0, 10, 10);
        let spread = 32;
        let bg = this.svg.rect(0, 0, spread, spread).attr({fill: '#fff'});
        let stripe = this.svg.line(-1, spread + 1, spread + 1, -1).attr({stroke: '#000', strokeWidth: spread / 16});
        let stripe2 = this.svg.line(-1, 1, 1, -1).attr({stroke: '#000', strokeWidth: spread / 16});
        let stripe3 = this.svg.line(spread - 1, spread + 1, spread + 1, spread - 1)
            .attr({stroke: '#000', strokeWidth: spread / 16});
        this.stripedFillingPattern = this.svg.group(bg, stripe, stripe2, stripe3).pattern(0, 0, spread, spread);

        let noFillingPatternSize = 32;
        let noFillingPatternBackground = this.svg.rect(0, 0, noFillingPatternSize, noFillingPatternSize)
            .attr({fill: '#ffffff'});
        let noFillingPatternBox1 = this.svg.rect(noFillingPatternSize / 2, 0, noFillingPatternSize / 2,
            noFillingPatternSize / 2).attr({fill: '#F5F5F5'});
        let noFillingPatternBox2 = this.svg.rect(0, noFillingPatternSize / 2, noFillingPatternSize / 2,
            noFillingPatternSize / 2).attr({fill: '#F5F5F5'});
        this.noFillingPattern = this.svg.group(noFillingPatternBackground, noFillingPatternBox1, noFillingPatternBox2)
            .pattern(0, 0, noFillingPatternSize, noFillingPatternSize);
    }

    isGlazingOrnament(glazing: Glazing): boolean {
        return false;
    }

    abstract isDefaultGlazing(area: AreaSpecification): boolean;

    protected reselectRecreatedSnapElements(selectedObjects: any[]): void {
    }

    protected setViewBox(params: PainterParams): void {
        let box = ScalingPainter.scaleBox(params, this.getViewBox(params.mode));
        this.svg.attr({
            viewBox: [
                box.minX,
                box.minY,
                box.maxX - box.minX,
                box.maxY - box.minY
            ].join(' ')
        });
    }

    getViewBox(painterMode: PainterMode, recalculateDistanceFromWindows = false): MinMaxXY {
        let distanceFromWindows = 0;
        let border = 0;
        if (this.data.windows.length > 0) {
            if (recalculateDistanceFromWindows) {
                this.guides.recalculateDistanceFromWindows();
            }
            distanceFromWindows = this.guides.distanceFromWindows;
            if (painterMode !== PainterMode.WEBSHOP) {
                border = this.getOnePercentOfCanvasSize() * 7;
            }
        }
        let topDownViewDisplayed = painterMode === PainterMode.REGULAR && TopDownViewPainter.supported(this);
        return new MinMaxXY(
            this.totalBoundingBox.minX - distanceFromWindows - border,
            this.totalBoundingBox.maxX + border,
            this.totalBoundingBox.minY - distanceFromWindows - border,
            (topDownViewDisplayed ? 2 : 1) * (this.totalBoundingBox.maxY + border)
        );
    }

    abstract getTotalBoundingClientRect(): DOMRect;

    protected paintPendingOperationHelper(): void {
    }

    protected paintSelectedTabIndicator(): void {
    }

    protected paintWindowAddingButtons(): void {
    }

    protected paintCutButtons(): void {
    }

    protected paintGuidancePoints(polygonMap: Map<AreaSpecification, number[]>,
                                  polygonMapFull: Map<AreaSpecification, PolygonPoint[]>): void {
    }

    protected paintFloatingDetails(params: PainterParams): void {
        let masterQuarterLabel = params.isRegularMode() ?
            this.customTranslations.masterQuarter[this.getCurrentLanguage()] : MASTER_QUARTER_PLACEHOLDER;
        let openingsGroup = this.svg.g().addClass(OpeningLines.GROUP_CLASS);
        for (let window of this.data.windows) {
            for (let subWindow of window.subWindows) {
                let floatingElementsBoxes: number[][] = [];
                let outerSubwindowPoints = WindowCalculator.getOuterSubwindowPoints(subWindow, this.data.cuts);
                let totalGlazingBeadPoints = WindowCalculator.getTotalGlazingBeadsPoints(subWindow, this.data.cuts,
                    this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                if (this.visibilitySettings.areaNumbers || !params.isRegularMode()) {
                    floatingElementsBoxes = AreaNumbersPainter.paint(subWindow, this, totalGlazingBeadPoints, params);
                }

                let subwindowName = this.getSubWindowName(subWindow);
                let point = this.getCenterPointForWindow(subWindow.points);
                if (params.isRegularMode() && this.visibilitySettings.quarterNames) {
                    this.paintSubwindowName(subwindowName, point, floatingElementsBoxes, params);
                }
                if (subWindow.isLeading && !WindowCalculator.isSubWindowAccordion(subWindow) &&
                    (!params.isRegularMode() || this.visibilitySettings.leadingSubwindowIndicator)) {
                    this.paintLeadingSubwindowIndicator(masterQuarterLabel, point, floatingElementsBoxes, params);
                }
                openingsGroup.add(...this.windowPainter.paintSubwindowOpenings(window, subWindow,
                    outerSubwindowPoints, totalGlazingBeadPoints,
                    floatingElementsBoxes, params));
            }
        }
    }

    private paintSubwindowName(subwindowName: string, point: { x: number, y: number }, overlapPolygons: number[][],
                               params: PainterParams) {
        const textPainter = TextPainter.forDrawing(this, params);
        let textElement = textPainter.paintText(subwindowName, [point.x, point.y]);
        textElement.attr({pointerEvents: "none"});
        textElement.addClass('subwindow-name');
        textPainter.preventOverlapsByMovingUpwards(textElement, overlapPolygons, params);
        overlapPolygons.push(textPainter.getBBox(textElement).toPoints());
    }

    private paintLeadingSubwindowIndicator(masterQuarterLabel: string, point: { x: number, y: number },
                                           overlapPolygons: number[][], params: PainterParams) {
        const textPainter = TextPainter.forDrawing(this, params);
        let textElement = textPainter.paintText(masterQuarterLabel, [point.x, point.y]);
        textElement.attr({'pointerEvents': 'none', 'font-weight': 'bold'});
        textElement.addClass('master-quarter-label');
        textPainter.preventOverlapsByMovingUpwards(textElement, overlapPolygons, params);
        overlapPolygons.push(textPainter.getBBox(textElement).toPoints());
    }

    public mapAddonsToWindowAddons() {
        if (this.allAddons != null) {
            let getAddon = (addonList: AddonInterface[], id: number): AddonInterface =>
                addonList != undefined ? addonList.find(addon => addon.id === id) : undefined;

            let spec = this.data.specification;
            let dataSource = AddonDefaultQuantityCalculator.prepareDataSource(this.data, this.staticData);

            type WindowAddonProp = {
                [K in keyof DrawingDataSpecification]: DrawingDataSpecification[K] extends WindowAddon ? K : never;
            }[keyof DrawingDataSpecification];

            let mapGlobal = (property: WindowAddonProp, availables: AddonInterface[]) => {
                WindowAddonMapper.mapToWindowAddon(spec[property],
                    getAddon(availables, spec[property] && spec[property].addonId), WindowAddonCalculationMode.GLOBAL, dataSource);
            };

            mapGlobal('weldType', this.allAddons.availableWeldTypes);
            mapGlobal('frameEnhancement', this.allAddons.availableEnhancements);
            mapGlobal('handleType', this.allAddons.availableHandles);
            mapGlobal('underwindowProfile', this.allAddons.availableUnderwindowProfiles);
            mapGlobal('milling', this.allAddons.availableMillings);
            mapGlobal('millingNorwegian', this.allAddons.availableMillingsNorwegian);
            mapGlobal('underWindowBead', this.allAddons.availableUnderWindowBeads);
            mapGlobal('cover', this.allAddons.availableCovers);
            mapGlobal('fittingBrake', this.allAddons.availableFittingBrakes);
            mapGlobal('fittingSliding', this.allAddons.availableFittingSlidings);
            mapGlobal('fittingType', this.allAddons.availableFittingTypes);
            mapGlobal('fittingEspagnoletteType', this.allAddons.availableFittingEspagnoletteTypes);
            mapGlobal('fittingVeranda', this.allAddons.availableFittingVerandas);
            mapGlobal('fittingInsertion', this.allAddons.availableFittingInsertions);
            mapGlobal('fittingMainInsertion', this.allAddons.availableFittingMainInsertions);
            mapGlobal('fittingAdditionalInsertion', this.allAddons.availableFittingAdditionalInsertions);
            mapGlobal('fittingLock', this.allAddons.availableFittingLocks);
            mapGlobal('fittingLockTerrace', this.allAddons.availableFittingLocksTerrace);
            mapGlobal('fittingAutomaticDrive', this.allAddons.availableFittingAutomaticDrives);
            mapGlobal('terraceHandle', this.allAddons.availableTerraceHandles);

            for (let i = 0; i < this.data.windows.length; i++) {
                for (let subWindow of this.data.windows[i].subWindows) {
                    WindowAddonMapper.mapToWindowAddon(subWindow.coupler,
                        getAddon(this.allAddons.availableCouplers, subWindow.coupler.addonId),
                        WindowAddonCalculationMode.SINGLE_ELEMENT);

                    WindowAddonMapper.mapToWindowAddon(subWindow.drip,
                        getAddon(this.allAddons.availableDrips, subWindow.drip.addonId),
                        WindowAddonCalculationMode.SINGLE_ELEMENT);

                    WindowAddonMapper.mapToWindowAddon(subWindow.ventilator,
                        getAddon(this.allAddons.availableVentilators, subWindow.ventilator.addonId),
                        WindowAddonCalculationMode.SINGLE_ELEMENT);
                }
            }
        }
    }

    configAddonIconClicked(): void {
    }

    private paintWindows(polygonMap: Map<AreaSpecification, number[]>,
                         polygonMapFull: Map<AreaSpecification, PolygonPoint[]>,
                         clickableElements: Snap.Element[], params: PainterParams, shadings: Shadings) {
        this.windowPainter.paintConfigAddonsIcon(this.data.configurableAddonIds, null, params, this.totalBoundingBox);
        this.data.windows.forEach(window => {
            this.windowPainter.paint(window, this.data.cuts, clickableElements, params, shadings);
            window.subWindows.forEach(subwindow => {
                let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPointsFull(subwindow, this.data.cuts,
                    this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                subwindow.areasSpecification.forEach(area => {
                    let areaGlazingBeadFull =
                        WindowCalculator.getGlazingBeadPointsFull(subwindow, area.definingMullions,
                            totalGlazingBead, this.profileCompositionDistances);
                    let areaGlazingBead = PolygonPointUtil.toNumbersArray(areaGlazingBeadFull);
                    this.drawGrills(subwindow, area, areaGlazingBeadFull, clickableElements, params);
                    if (this.mode === Tool.GRILL) {
                        polygonMapFull.set(area, areaGlazingBeadFull);
                        polygonMap.set(area, areaGlazingBead);
                    }
                });
            });
        });
    }

    isPricingTabOpen(): boolean {
        return false;
    }

    protected abstract showError(error: string, params?: any): void;

    protected abstract getCurrentLanguage(): keyof MultilanguageFieldInterface;

    createSnapMatrix(): Snap.Matrix {
        return this.window.Snap.matrix();
    }

    createSnapFilter(): Snap.Filter {
        return this.window.Snap.filter;
    }

    abstract getEmptyBoundingBox(): MinMaxXY;

    abstract canClickGuides(): boolean;

    abstract isValidationDisabled(): boolean;

    private paintShadings(framePoints: number[], params: PainterParams, shadings: Shadings): void {
        if (params.isShaded() && framePoints.length > 0) {
            this.paintShading(framePoints, shadings.light, params);
            this.paintShading(framePoints, shadings.dark, params);
            ScalingPainter.path(this.svg, framePoints, Shadings.jointAttr(), params);
            HandlePainter.paintShaded(this, params, shadings, framePoints);
            WatermarkPainter.paint(this, params, framePoints);
        }
    }

    private paintShading(points: number[], shading: Shading, params: PainterParams): void {
        ScalingPainter.path(this.svg, points, {fill: shading.gradient, mask: shading.mask, pointerEvents: 'none'},
            params);
    }

    private paintWingShadows(framePoints: number[], windows: WindowData[], params: PainterParams,
                             shadings: Shadings): void {
        if (params.isShaded() && !params.viewFromOutside) {
                let shadowGroup = this.svg.g();
                let shadowMask = this.svg.g(ScalingPainter.path(this.svg, framePoints, WindowParams.OverlayMask.Show, params));
                windows.forEach(w => w.subWindows.forEach(sw => {
                    let wingOuterEdge = WindowCalculator.getFFWingOuterEdgePoints(sw, this.data.cuts,
                        this.totalBoundingBox, this.profileCompositionDistances, this.isValidationDisabled());
                    shadowGroup.add(ScalingPainter.path(this.svg, wingOuterEdge, {}, params));
                    shadowMask.add(ScalingPainter.path(this.svg, wingOuterEdge, WindowParams.OverlayMask.Hide, params));
                }));
                shadowGroup.attr({filter: shadings.wingShadowFilter, mask: shadowMask});
        }
    }

    protected paintDebugElements(params: PainterParams): void {
    }

    notifySizeChanged(oldBoundingBox: MinMaxXY, newBoundingBox: MinMaxXY): void {
    }

    static createDrawingDataForFWindowForGlazingPackageFromArea(packageWidth: number, packageHeight: number,
                                                                windowSystem: WindowSystemInterface,
                                                                profileCompositionDistances: ProfilesCompositionDistances,
                                                                windowSystemDefaults: WindowSystemDefaults,
                                                                sealInternalId: number, sealExternalId: number,
                                                                sourceArea: AreaSpecification | undefined): DrawingData {
        const drawingData = new DrawingData();
        drawingData.glazingPackageForAreaId = sourceArea ? sourceArea.generatedId : RandomIdGenerator.generate();
        drawingData.windowSystemId = windowSystem.id;

        let attributes = WindowTypeCodeParser.parseTypeCode(WindowTypeCode.F);
        const compositionDistances = WindowCalculator.prepareOffsets(profileCompositionDistances,
            (frame, glazingSeating) => frame - glazingSeating,
            ProfilesCompositionType.FRAME, ProfilesCompositionType.GLAZING_PACKAGE_SEATING_DEPTH);

        const newWindow = WindowDataFactory.createWindowData(attributes, windowSystem, 1, undefined, 'center', {
            height: packageHeight
                + CompositionDistancesUtils.get(compositionDistances, SubwindowSide.TOP)
                + CompositionDistancesUtils.get(compositionDistances, SubwindowSide.BOTTOM),
            width: packageWidth
                + CompositionDistancesUtils.get(compositionDistances, SubwindowSide.LEFT)
                + CompositionDistancesUtils.get(compositionDistances, SubwindowSide.RIGHT)
        });

        drawingData.windows = newWindow;

        const boundingBox = DrawingUtil.calculateTotalBoundingBox(drawingData.windows);

        newWindow.forEach(w => {
            AreaUtils.recalculateSubwindowAreaSizes(w.subWindows, drawingData.cuts, boundingBox, profileCompositionDistances, true);
        });

        WindowSystemDefaultsUtil.applyDefaults(windowSystemDefaults, drawingData, windowSystem, false);
        drawingData.specification.terraceGlazingPackageId = windowSystemDefaults.terraceGlazingPackageId;
        drawingData.specification.sealInternalId = sealInternalId;
        drawingData.specification.sealExternalId = sealExternalId;
        drawingData.addons = [];

        AreaUtils.recalculateAreasNumbers(drawingData);
        AreaUtils.setTotalPerimeter(drawingData);
        AreaUtils.setSubwindowShapes(drawingData);

        if (sourceArea != undefined) {
            newWindow.forEach(w => w.subWindows.forEach(sw => sw.areasSpecification.forEach(newArea => {
                newArea.filling = {...sourceArea.filling};
                newArea.glazingBead.id = sourceArea.glazingBead.id;
            })));
        }

        return PricingUtils.enhanceForPricing(drawingData, windowSystem, profileCompositionDistances);
    }
}
