import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {DataServiceHelper} from "../../../../../../common/dataServiceHelper";
import {AssemblyUnit} from "../../../../../window-system/assembly/assembly";
import {AssemblyService} from "../../../../../window-system/assembly/assembly.service";
import {PositionService} from "../../position.service";
import {AssemblyData} from "../add-assembly/assembly-data";
import {EditAssemblyOrTransportDialogData} from "../position-list-dialogs";
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {Currencies} from "../../../../../../currencies";

@Component({
    selector: 'app-edit-assembly',
    templateUrl: './edit-assembly.component.html',
    styleUrls: ['./edit-assembly-component.css', '../../../../../shared-styles.css'],
    providers: [AssemblyService, PositionService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditAssemblyComponent implements OnInit {

    @Input()
    dialogData: EditAssemblyOrTransportDialogData;

    @Input()
    readOnlyMode: boolean;

    @Output()
    readonly onSubmit = new EventEmitter<void>();

    @Output()
    readonly onClose = new EventEmitter<void>();

    visible = false;
    assembly: AssemblyData;
    currency: Currencies;
    validationErrors: { [field: string]: string } = {};

    constructor(private assemblyService: AssemblyService,
                private positionService: PositionService,
                public translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
    }

    ngOnInit(): void {
        this.assembly = JSON.parse(this.dialogData.editedPosition.data);
        this.currency = this.dialogData.editedPosition.pricingCurrency;
        this.visible = true;
    }

    submit(): void {
        if (this.readOnlyMode || !this.validateForm()) {
            return;
        }
        this.positionService.saveItem(this.dialogData.editedPosition).subscribe({
            complete: () => {
                this.onSubmit.emit();
                this.visible = false;
            },
            error: error => this.errors.handle(error)
        });
    }

    isNotMeter(assembly: AssemblyData): boolean {
        return assembly.assemblyUnit !== AssemblyUnit.METER;
    }

    fillQuantityFromOffer(): void {
        this.assemblyService.calculateQuantityForOffer(this.assembly.assemblyId, this.dialogData.editedPosition.offerId).subscribe({
            next: quantity => {
                this.dialogData.editedPosition.quantity = quantity;
                this.clearError('quantity');
            },
            error: error => this.errors.handle(error)
        });
    }

    private validateForm(): boolean {
        this.validationErrors = {};
        let quantity = this.dialogData.editedPosition.quantity;
        if (isNaN(parseFloat(quantity as any)) || !isFinite(quantity)) {
            this.validationErrors['quantity'] = 'error.assemblyDto.quantity.not_empty';
        } else if (quantity <= 0) {
            this.validationErrors['quantity'] = 'error.assemblyDto.quantity.below_min';
        }
        return Object.keys(this.validationErrors).every(key => this.validationErrors[key] == undefined);
    }

    clearError(field: string) {
        this.validationErrors[field] = undefined;
        this.changeDetector.markForCheck();
    }
}
