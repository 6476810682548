import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SupportedLanguages} from '../../../../supportedLanguages';
import {AddonCategory} from "../addon-category";
import {COMMON_INPUT_LENGTH_LIMITS} from "../../../../common/crud-common/common-input-length-limits";
import {SelectItem} from "primeng/api/selectitem";
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {AddonCategoryFieldUsage} from "../../catalog-field-usage";
import {AddonCategoryField} from "../../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {PrintVisibility} from "../print-visibility.enum";

@Component({
    selector: 'app-addon-category-form',
    templateUrl: './addon-category-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonCategoryFormComponent {

    supportedLanguages = SupportedLanguages.languages;
    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    @Input() item: AddonCategory;
    @Input() validationErrors: any;
    @Input() addonCategoryGroups: SelectItem[];
    @Input() editPermits: FieldLimitation[];

    fieldUsage: AddonCategoryFieldUsage;
    AddonCategoryField = AddonCategoryField;

    printVisibilities: SelectItem[];

    constructor() {
        this.fieldUsage = new AddonCategoryFieldUsage(this);
        this.printVisibilities = [
            {label: 'ADDON_CATEGORY.FORM.PRINT_VISIBILITY.ALWAYS', value: PrintVisibility.ALWAYS},
            {label: 'ADDON_CATEGORY.FORM.PRINT_VISIBILITY.OFFERS_ONLY', value: PrintVisibility.OFFERS_ONLY},
            {label: 'ADDON_CATEGORY.FORM.PRINT_VISIBILITY.PROD_ORDERS_ONLY', value: PrintVisibility.PROD_ORDERS_ONLY},
            {label: 'ADDON_CATEGORY.FORM.PRINT_VISIBILITY.NEVER', value: PrintVisibility.NEVER}
        ];
    }
}
