<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(GraspGlazingPackageField.NAME)"
                            inputId="name" label="{{ 'GRASP_GLAZING_PACKAGE.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="100"
                            [disabled]="fieldUsage.disabled(GraspGlazingPackageField.NAME)"
                            [required]="true" [field]="item?.name"></app-translation-fields>

    <div class="new-form-row">
        <app-input-text *ngIf="fieldUsage.show(GraspGlazingPackageField.SYMBOL)"
                        inputId="symbol" label="{{ 'GRASP_GLAZING_PACKAGE.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(GraspGlazingPackageField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="100"
                        [required]="true" [(ngModel)]="item.symbol">
        </app-input-text>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GraspGlazingPackageField.CATEGORY)">
        <app-select inputId="category" label="{{ 'GRASP_GLAZING_PACKAGE.FORM.CATEGORY' | translate }}"
                    [disabled]="fieldUsage.disabled(GraspGlazingPackageField.CATEGORY)"
                    [options]="selectCategories | selectItemMultilanguageFieldTranslate"
                    [(ngModel)]="item.glazingCategoryId" [required]="true"
                    [(validationMessageKey)]="validationErrors['glazingCategoryId']"></app-select>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GraspGlazingPackageField.FRAME_CATEGORY)">
        <app-select inputId="category" label="{{ 'GRASP_GLAZING_PACKAGE.FORM.FRAME_CATEGORY' | translate }}"
                    [disabled]="fieldUsage.disabled(GraspGlazingPackageField.FRAME_CATEGORY)"
                    [options]="frameCategories | selectItemMultilanguageFieldTranslate"
                    [(ngModel)]="item.frameCategoryId" [required]="true"
                    [(validationMessageKey)]="validationErrors['frameCategoryId']"></app-select>
    </div>

    <div *ngIf="fieldUsage.show(GraspGlazingPackageField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GRASP_GLAZING_PACKAGE.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GraspGlazingPackageField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(GraspGlazingPackageField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex" label="{{ 'GRASP_GLAZING_PACKAGE.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GraspGlazingPackageField.SORT_INDEX)"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [required]="true" [allowOnlyIntegers]="true" [(ngModel)]="item.sortIndex"></app-input-number>
    </div>
</div>
