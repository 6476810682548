<div class="new-form-600">
    <ng-container *ngIf="!roofSystem && !entranceSystem">
        <div class="new-form-row">
            <app-input-number inputId="uwInput" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAMOUR_UW' | translate }}"
                              [disabled]="disabled"
                              [(validationMessageKey)]="validationErrors['glamourDisplayUw']" [min]="0"
                              [(ngModel)]="windowSystem.glamourDisplayUw"></app-input-number>
        </div>

        <p-accordion>
            <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAMOUR_UW_DESCRIPTION' | translate }}" [selected]="false">
                <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                    <app-text-area inputId="{{'uwDescriptionInput_' + lang.code}}" [maxlength]="1000"
                                   label="{{ 'GLAMOUR_BOOKLET_DESCRIPTION.FIELD_LABEL' | translate: {lang: lang.code} }}"
                                   [disabled]="disabled"
                                   [(validationMessageKey)]="validationErrors['glamourDisplayUwDescription.' + lang.code]"
                                   [ngModel]="windowSystem.glamourDisplayUwDescription && windowSystem.glamourDisplayUwDescription[lang.code]"
                                   (ngModelChange)="handleGlamouDisplayUwChanged($event, lang.code)">
                    </app-text-area>
                </div>
            </p-accordionTab>
        </p-accordion>

        <p-accordion>
            <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAMOUR_PRINT_ICON' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': hasGlamourPrintIconValidationErrors()}">
                <div *ngFor="let lang of supportedLanguages" class="new-form-row">
                    <app-file-upload [inputId]="'glamourPrintIcon_' + lang.code"
                                     [disabled]="disabled"
                                     [ngModel]="glamourPrintIconFile[lang.code]?.file" [label]="lang.name | translate"
                                     (ngModelChange)="glamourPrintIconFileChanged(lang.code, $event)" [maxSize]="500000"
                                     [maxWidth]="2000" [maxHeight]="2000"
                                     addButtonLabel="WINDOW-SYSTEM-DEFINITION.FORM.SELECT_GLAMOUR_PRINT_ICON_UPLOAD"
                                     changeButtonLabel="WINDOW-SYSTEM-DEFINITION.FORM.CHANGE_GLAMOUR_PRINT_ICON_UPLOAD"
                                     deleteButtonLabel="WINDOW-SYSTEM-DEFINITION.FORM.DELETE_GLAMOUR_PRINT_ICON_UPLOAD"
                                     accept=".svg" validationFileType="image/svg+xml"
                                     [(validationMessageKey)]="validationErrors['glamourPrintIconFile.' + lang.name]"></app-file-upload>
                </div>
            </p-accordionTab>
        </p-accordion>
    </ng-container>

    <ng-container *ngIf="roofSystem">
        <app-translation-fields inputId="glamourRoofHeaderDescription" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAMOUR_ROOF_HEADER_DESCRIPTION' | translate }}"
                                validationKeyPrefix="glamourRoofHeaderDescription"
                                [disabled]="disabled"
                                [validationErrors]="validationErrors" [maxlength]="250"
                                [field]="windowSystem.glamourRoofHeaderDescription"></app-translation-fields>

        <p-accordion>
            <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAMOUR_ROOF_DESCRIPTION_1' | translate }}" [selected]="false">
                <div class="new-form-row">
                    <h3>{{ 'GLAMOUR_BOOKLET_DESCRIPTION.SEPARATOR_NOTE' | translate }}: ▼</h3>
                </div>

                <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                    <app-text-area inputId="{{'desc1_' + lang.code}}"
                                   label="{{ 'GLAMOUR_BOOKLET_DESCRIPTION.FIELD_LABEL' | translate: {lang: lang.code} }}"
                                   [disabled]="disabled"
                                   [ngModel]="windowSystem.glamourRoofDescription1 && windowSystem.glamourRoofDescription1[lang.code]"
                                   (ngModelChange)="handleGlamourDescription1Changed($event, lang.code)">
                    </app-text-area>
                </div>
            </p-accordionTab>
        </p-accordion>

        <p-accordion>
            <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAMOUR_ROOF_DESCRIPTION_2' | translate }}" [selected]="false">
                <div class="new-form-row">
                    <h3>{{ 'GLAMOUR_BOOKLET_DESCRIPTION.SEPARATOR_NOTE' | translate }}: ▼</h3>
                </div>

                <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                    <app-text-area inputId="{{'desc2_' + lang.code}}"
                                   [disabled]="disabled"
                                   label="{{ 'GLAMOUR_BOOKLET_DESCRIPTION.FIELD_LABEL' | translate: {lang: lang.code} }}"
                                   [ngModel]="windowSystem.glamourRoofDescription2 && windowSystem.glamourRoofDescription2[lang.code]"
                                   (ngModelChange)="handleGlamourDescription2Changed($event, lang.code)">
                    </app-text-area>
                </div>
            </p-accordionTab>
        </p-accordion>
    </ng-container>

    <p-accordion>
        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAMOUR_PRINT_IMAGE' | translate }}"
                        [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['glamourPrintImage']}">
            <div>
                <app-file-upload inputId="glamourPrintImage" [ngModel]="glamourPrintImageFile"
                                 [disabled]="disabled"
                                 (ngModelChange)="glamourPrintImageFileChanged($event)" [maxSize]="500000"
                                 [maxWidth]="2000" [maxHeight]="2000"
                                 addButtonLabel="WINDOW-SYSTEM-DEFINITION.FORM.SELECT_GLAMOUR_PRINT_IMAGE_UPLOAD"
                                 changeButtonLabel="WINDOW-SYSTEM-DEFINITION.FORM.CHANGE_GLAMOUR_PRINT_IMAGE_UPLOAD"
                                 deleteButtonLabel="WINDOW-SYSTEM-DEFINITION.FORM.DELETE_GLAMOUR_PRINT_IMAGE_UPLOAD"
                                 [widthToHeightRatio]="roofSystem ? 1.6 : 1.3"
                                 [(validationMessageKey)]="validationErrors['glamourPrintImage']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
