import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {ItemForCatalogLinking} from '../single-system-checkbox-crud/item-for-catalog-linking';
import {GateSystem} from "./gate-system";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing} from "../../../common/crud-common/crudItemList";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {CatalogItemName} from "../../../common/crud-common/catalog-item-name";
import {ProfitMarginExistance} from "../../offer/window-editor/drawing-tool/ProfitMarginExistance";

@Injectable()
export class GateSystemService implements CrudService<GateSystem> {

    private static readonly API_URL = 'gateSystem';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) { }

    addItem(item: GateSystem, file?: File): Observable<number> {
        return this.saveGateSystem(item, file);
    }

    editItem(itemId: number, item: GateSystem, file?: File): Observable<number> {
        return this.saveGateSystem(item, file, itemId);
    }

    getItem(itemId: number): Observable<GateSystem> {
        return this.http.get<GateSystem>(`${GateSystemService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<GateSystem>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<GateSystem>>(GateSystemService.API_URL, {params: params});
    }

    getImage(itemId: number): Observable<string> {
        return this.http.get(`${GateSystemService.API_URL}/${itemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`${GateSystemService.API_URL}/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    saveGateSystem(item: GateSystem, file: File, itemId?: number): Observable<number> {
        const url = itemId ? `${GateSystemService.API_URL}/${itemId}` : GateSystemService.API_URL;
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('gateSystemDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post(url, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(GateSystemService.API_URL, response)));
    }

    copy(id: number, item: GateSystem, file: File) {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('gateSystemDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post(`${GateSystemService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(GateSystemService.API_URL, response)));
    }

    validate(item: GateSystem): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${GateSystemService.API_URL}/validate`, item));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${GateSystemService.API_URL}/names`, {params: params});
    }

    getGatesForCatalogLinking(): Observable<ItemForCatalogLinking[]> {
        return this.http.get<ItemForCatalogLinking[]>(`${GateSystemService.API_URL}/forCatalogLinking`);
    }

    getActiveGatesWithImages(selectedGateId?: number): Observable<GateSystem[]> {
        const params = {};
        if (selectedGateId != undefined) {
            params['selectedGateId'] = `${selectedGateId}`;
        }
        return this.http.get<GateSystem[]>(`${GateSystemService.API_URL}/activeWithImages`, {params: params});
    }

    public validateMarginExistance(systemId: number, offerId: number): Observable<ProfitMarginExistance> {
        return this.http.get<ProfitMarginExistance>(`${GateSystemService.API_URL}/${systemId}/profitMarginExists/${offerId}`);
    }
}
