import {JsonDeserializable} from "../../../common/crud-common/crudItem";
import {Currencies} from "../../../currencies";
import {ProductionOrderStatisticsPoint} from "./production-order-statistics-point";

@JsonDeserializable
export class ProductionOrderStatistics {

    label: string;
    data: ProductionOrderStatisticsPoint[];
    currency: Currencies;

    static fromJSON(jsonObject: any): ProductionOrderStatistics {
        const statistics = new ProductionOrderStatistics();
        statistics.label = jsonObject.label;
        statistics.data = (jsonObject.data || []).map(ProductionOrderStatisticsPoint.fromJSON);
        statistics.currency = jsonObject.currency;
        return statistics;
    }
}
