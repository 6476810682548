import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {Dialog} from 'primeng/dialog';
import {finalize} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../../../../common/CommonErrorHandler';
import {ValidationErrors} from '../../../../../../common/validation-errors';
import {PositionService} from '../../position.service';
import {Position} from '../position';
import {SetPrintOrderDialogData} from '../position-list-dialogs';

@Component({
    selector: 'app-set-print-order-dialog',
    templateUrl: './set-print-order-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SetPrintOrderDialogComponent implements OnChanges {

    @Input()
    dialogData: SetPrintOrderDialogData;

    @Output()
    readonly onPrintOrderChangeSuccess = new EventEmitter<Position>();

    @Output()
    readonly onClose = new EventEmitter<void>();

    visible = true;
    newPrintOrder: number;
    validationErrors: ValidationErrors = {};

    minPrintOrder: number;
    maxPrintOrder: number;

    private submitInProgres = false;

    @ViewChild('dialog', {static: true})
    dialog: Dialog;

    constructor(private readonly positionService: PositionService,
                private readonly errors: CommonErrorHandler,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const dialogDataChange = changes['dialogData'];
        if (dialogDataChange != undefined) {
            if (dialogDataChange.currentValue != undefined) {
                const position = (dialogDataChange.currentValue as SetPrintOrderDialogData).position;
                this.newPrintOrder = position.printOrder;
                this.minPrintOrder = 1;
                this.maxPrintOrder = undefined;
                this.positionService.getPrintOrderRange(position.id).subscribe({
                    next: range => {
                        this.minPrintOrder = range.from;
                        this.maxPrintOrder = range.to;
                    },
                    error: error => this.errors.handle(error)
                });
            } else {
                this.newPrintOrder = undefined;
                this.minPrintOrder = undefined;
                this.maxPrintOrder = undefined;
            }
        }
    }

    handleSubmit() {
        if (this.submitInProgres) {
            return;
        }
        this.submitInProgres = true;
        this.positionService.setPrintOrder(this.dialogData.position.id, this.newPrintOrder).pipe(finalize(() => {
            this.submitInProgres = false;
            this.changeDetector.markForCheck();
        })).subscribe({
            next: () => {
                this.visible = false;
                this.onPrintOrderChangeSuccess.emit(this.dialogData.position);
            },
            error: error => {
                this.validationErrors = this.errors.handle(error);
            }
        });
    }
}
