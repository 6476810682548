import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {EntranceModelField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {EntranceModelFieldUsage} from "../../catalog-field-usage";
import {EntranceModel} from "../entrance-model";

@Component({
    selector: 'app-entrance-model-form',
    templateUrl: './entrance-model-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceModelFormComponent {

    @Input() item: EntranceModel;
    @Input() validationErrors: any;
    @Input() file: File;
    @Input() file2: File;
    @Input() glazingPackages: SelectItem[];
    @Input() editPermits: FieldLimitation[] = [];
    @Output() readonly fileChange: EventEmitter<File> = new EventEmitter<File>();
    @Output() readonly file2Change: EventEmitter<File> = new EventEmitter<File>();

    fieldUsage: EntranceModelFieldUsage;
    EntranceModelField = EntranceModelField;

    constructor() {
        this.fieldUsage = new EntranceModelFieldUsage(this);
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    onFile2Change(file2: File): void {
        this.file2Change.emit(file2);
        this.file2 = file2;
    }
}
