export type ReportDateResolution = 'day' | 'week' | 'month' | 'quarter';

export enum ReportGrouping {
    SUBSYSTEM = 'SUBSYSTEM',
    CLIENT = 'CLIENT',
    SUPPLIER = 'SUPPLIER',
    SYSTEM = 'SYSTEM',
}

export enum ReportMode {
    revenue = 'revenue',
    income = 'income',
    conversion = 'conversion',
}
