import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {WebshopBusinessType} from './webshop-business-type';

@Injectable()
export class WebshopBusinessTypeService {

    private static readonly API_URL = 'webshopBusinessTypes';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WebshopBusinessType>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<WebshopBusinessType>>(WebshopBusinessTypeService.API_URL, {params: params});
    }

    editItem(item: WebshopBusinessType): Observable<number> {
        return this.http.post<void>(WebshopBusinessTypeService.API_URL, item).pipe(map(() => item.id));
    }

    getItem(itemId: number): Observable<WebshopBusinessType> {
        return this.http.get<WebshopBusinessType>(`${WebshopBusinessTypeService.API_URL}/${itemId}`)
            .pipe(map(result => WebshopBusinessType.fromJSON(result)));
    }
}
