import {HttpErrorResponse} from "@angular/common/http";
import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from "@angular/platform-browser";
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from "primeng/api/selectitem";
import {EMPTY, forkJoin, Observable, of} from 'rxjs';
import {catchError, finalize, mergeMap, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {MultilanguageFieldInterface} from "../../../../window-designer/catalog-data/multilanguage-field-interface";
import {ConfigAddonParentInfo} from "../../../../window-designer/entities/ConfigAddonParentInfo";
import {ConfigurableAddon} from "../../../../window-designer/entities/ConfigurableAddon";
import {ConfigAddonApplication} from "../../../../window-designer/enums/ConfigAddonApplication";
import {SubwindowTypes} from "../../../../window-designer/subwindow-types";
import {SizeUnitsConverter} from "../../../../window-designer/utils/SizeUnitsConverter";
import {CurrentUserService} from "../../../auth/current-user.service";
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from "../../../common/growl-message/growl-message-controller";
import {MissingProfitMarginHandlerService} from "../../../common/missing-profit-margin-handler/missing-profit-margin-handler.service";
import {IdGeneratorService} from "../../../common/service/id-generator.service";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {OnceFlag} from "../../../shared/once-flag";
import {CatalogConfiguration} from '../../catalog-creator/catalog-configuration';
import {CatalogConfigurationService} from "../../catalog-creator/catalog-configuration.service";
import {CatalogOptionService} from "../../catalog-creator/catalog-option/catalog-option.service";
import {CatalogPropertyTarget} from "../../catalog-creator/CatalogPropertyTarget";
import {ConfigSystemDefaults} from "../../settings/config-system-defaults/config-system-defaults";
import {ConfigSystemDefaultsState} from "../../settings/system-defaults/system-default-state";
import {AddonCategoryGroup} from "../../window-system/addon-category-group/addon-category-group";
import {AddonCategoryGroupService} from "../../window-system/addon-category-group/addon-category-group.service";
import {AutoOption} from "../../window-system/addons/addon";
import {ColorService} from "../../window-system/color/color.service";
import {
    ConfigDesignerCatalogDependentOptionService
} from "../../window-system/config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option.service";
import {ConfigSystem} from "../../window-system/config-system/config-system";
import {ConfigSystemService} from "../../window-system/config-system/config-system.service";
import {
    GateDesignerCatalogDependentOptionService
} from "../../window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option.service";
import {MaterialColorImages} from "../../window-system/material/material-color-images/material-color-images";
import {MaterialService} from "../../window-system/material/material.service";
import {SubwindowTypeService} from "../../window-system/subwindow-type/subwindow-type.service";
import {PositionType} from "../AbstractPosition";
import {AccordionTabId} from "../gate-editor/gate-editor-field-states";
import {SidebarFieldChangeEvent} from "../gate-editor/gate-sidebar/gate-sidebar.component";
import {ConfigurableAddonDialogComponent} from "../offers/position/position-list/configurable-addon/configurable-addon-dialog.component";
import {ConfigurableAddonDialogEventModel} from "../offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonDialogEventModel";
import {Position, PositionFactory} from '../offers/position/position-list/position';
import {
    AddConfigurableAddonDialogData,
    DialogType,
    GlobalAddConfigAddonsDialogData
} from "../offers/position/position-list/position-list-dialogs";
import {PositionService} from '../offers/position/position.service';
import {DrawingToolControlsMode} from "../window-editor/drawing-tool-controls/drawing-tool-controls.component";
import {OfferComponentsCounter} from "../window-editor/drawing-tool/OfferComponentsCounter";
import {ProfitMarginExistance} from "../window-editor/drawing-tool/ProfitMarginExistance";
import {Pricing} from "../window-editor/sidebar/pricing/Pricing";
import {PricingComponent} from "../window-editor/sidebar/pricing/pricing.component";
import {PricingService} from "../window-editor/sidebar/pricing/pricing.service";
import {CatalogItemType} from "../window-editor/sidebar/pricing/PricingItem";
import {ResponseStatusFlags, ResponseStatusHelper} from "../window-editor/sidebar/ResponseStatusFlags";
import {SystemDefaultsService} from "../window-editor/system-defaults.service";
import {WindowComponentPreviewData} from "../window-editor/window-component-preview-dialog/window-component-preview-dialog.component";
import {
    WindowEditorOfferData,
    WindowEditorPositionData,
    WindowEditorProductionOrderData
} from '../window-editor/window-editor-offer-interfaces';
import {ConfigData} from "./config-data";
import {ConfigEditorField} from "./config-editor-field";
import {CatalogLoadResult, ConfigEditorFieldContentProvider} from "./config-editor-field-content-provider";
import {ConfigEditorFieldStates} from "./config-editor-field-states";
import {ConfigSidebarFieldImageService} from "./config-sidebar/config-sidebar-field-image.service";
import {ConfigSidebarComponent} from "./config-sidebar/config-sidebar.component";

const enum SidebarTab {
    GENERAL = 0,
    PRICING = 1,
    VALIDATION = 2
}

@Component({
    selector: 'app-config-editor',
    templateUrl: './config-editor.component.html',
    styleUrls: ['../window-editor/common/designers.css', './config-designers.css'],
    providers: [PositionService, ConfigEditorFieldContentProvider, ConfigSystemService,
        CatalogConfigurationService, AddonCategoryGroupService, GateDesignerCatalogDependentOptionService,
        MissingProfitMarginHandlerService, ConfigSidebarFieldImageService, SystemDefaultsService,
        ColorService, MaterialService, PricingService, IdGeneratorService, SubwindowTypeService,
        ConfigDesignerCatalogDependentOptionService, CatalogOptionService, CurrentUserService]
})
export class ConfigEditorComponent implements OnInit {

    private static readonly BLOCK_SOURCE_ID = 'ConfigEditor';

    @ViewChild('pricing', {static: false})
    pricingComponent: PricingComponent;

    @ViewChild(ConfigSidebarComponent)
    sidebarComponent: ConfigSidebarComponent;

    @Input()
    offer: WindowEditorOfferData;

    @Input()
    productionOrder: WindowEditorProductionOrderData;

    @Input()
    currentActionPosition: WindowEditorPositionData;

    @Input()
    displayedDialogData: AddConfigurableAddonDialogData | GlobalAddConfigAddonsDialogData;

    @Input()
    applicableTo: ConfigAddonApplication[] = [];

    @Input()
    subwindowTypes: SubwindowTypes;

    @Output()
    onClose = new EventEmitter<ConfigurableAddonDialogEventModel[]>();

    @Input()
    saveAddonOnFormSubmit = true;

    @Input()
    dialogType: DialogType;

    @Input()
    initialQuantity = 1;

    configs: ConfigSystem[] = [];
    catalogConfiguration: CatalogConfiguration;

    selectedConfigSystem: ConfigSystem;

    data: ConfigData;
    positionData: ConfigurableAddon;
    parentElementDescription: ConfigAddonParentInfo[];
    originalData: string;
    fieldUsage: ConfigEditorFieldStates;
    readOnlyMode = true;
    defaultsLevel: 'GLOBAL' | 'SUBSYSTEM_GROUP' | 'SUBSYSTEM' | 'CLIENT_GROUP' | 'CLIENT' | 'OFFER';
    defaultsLevels: SelectItem[] = [];
    defaultsOverrideLowerLevel: boolean;
    showDesignerDialog = false;
    showExitWithoutSavingConfirmationDialog = false;
    windowComponentPreviewData: WindowComponentPreviewData;

    addonCategoryGroups: AddonCategoryGroup[];
    visibleAddonCategoryGroups: AddonCategoryGroup[];
    defaults: ConfigSystemDefaultsState;

    systemPreviewImage: SafeResourceUrl;
    DrawingToolControlsMode = DrawingToolControlsMode;
    showSidebar = true;
    catalogLoadedForSystemId: number;
    validationErrors: ValidationErrors = {};

    pricingStatus: ResponseStatusFlags = new ResponseStatusFlags();
    backendValidationStatus: ResponseStatusFlags = new ResponseStatusFlags();
    sidebarActiveTabIndex = SidebarTab.GENERAL;

    systemLinksLoaded = false;
    colorMaterialLinks: MaterialColorImages[] = [];

    fieldToFocusOnError: string;
    groupToFocusOnError: string;
    saveInProgress = false;

    showPricingAndValidation = false;

    private readonly dialogHideHelper = new OnceFlag();

    readonly dialogs = {
        description: false,
        confirmReplacing: false,
        add: false,
    };

    constructor(private readonly router: Router,
                private readonly translate: TranslateService,
                private readonly positionService: PositionService,
                private readonly configSystemService: ConfigSystemService,
                private readonly materialService: MaterialService,
                private readonly configEditorFieldContentProvider: ConfigEditorFieldContentProvider,
                private readonly blockUiController: BlockUiController,
                private readonly errors: CommonErrorHandler,
                private readonly catalogConfigurationService: CatalogConfigurationService,
                private readonly systemDefaultsService: SystemDefaultsService,
                private readonly currentUserService: CurrentUserService,
                private readonly growls: GrowlMessageController,
                private readonly permissions: Permissions,
                private readonly changeDetector: ChangeDetectorRef,
                private readonly pricingService: PricingService,
                private idGeneratorService: IdGeneratorService,
                protected subwindowTypeService: SubwindowTypeService,
                private sanitizer: DomSanitizer,
                private readonly missingProfitMarginHandlerService: MissingProfitMarginHandlerService,
                private readonly addonCategoryGroupService: AddonCategoryGroupService,
                private readonly catalogOptionService: CatalogOptionService) {
        this.fieldUsage = new ConfigEditorFieldStates(this, configEditorFieldContentProvider);
        this.configEditorFieldContentProvider.filteringEnabled = true;
    }

    ngOnInit() {
        this.blockUiController.block(ConfigEditorComponent.BLOCK_SOURCE_ID);
        let newPosition = this.currentActionPosition == null;
        if (newPosition) {
            this.currentActionPosition = PositionFactory.config(this.offer.id);
            this.currentActionPosition.quantity = this.initialQuantity;
        }

        this.loadDefaultsLevels();

        forkJoin({
            catalogConfiguration: this.loadCatalogConfiguration(newPosition),
            subwindowTypes: this.subwindowTypes ? of([]) : this.subwindowTypeService.getAll()
        }).pipe(catchError(
            (error) => {
                this.blockUiController.unblock(ConfigEditorComponent.BLOCK_SOURCE_ID);
                this.errors.handle(error);
                this.router.navigate(['features/offer']);
                return EMPTY;
            }), mergeMap(data => {
            if (!this.subwindowTypes) {
                this.subwindowTypes = new SubwindowTypes(data.subwindowTypes);
            }
            this.catalogConfiguration = data.catalogConfiguration;
            let position = this.currentActionPosition;
            this.readOnlyMode = this.offer == undefined
                || !this.permissions.userCanEditOffer(this.offer.status)
                || (position.validationDisabled && !this.permissions.isKoordynator() && !this.permissions.isOpiekun())
                || (this.offer.offerLockUserLogin !== this.currentUserService.currentUserName
                    && !this.currentUserService.restrictedToOfferNumber);
            this.positionData = JSON.parse(position.data);
            this.parentElementDescription = this.positionData.parentElementDescription;
            this.data = this.positionData.configData || new ConfigData();
            this.originalData = position.data;
            const systemIds = this.displayedDialogData instanceof GlobalAddConfigAddonsDialogData ?
                [...new Set(this.displayedDialogData.selectedPositions.map(item => item.windowSystemId))]
                : this.displayedDialogData.windowSystemId ? [this.displayedDialogData.windowSystemId] : null;
            return this.configEditorFieldContentProvider.loadConfigSystems(this.applicableTo, systemIds, this.data.configSystemId);
        }), finalize(() => this.blockUiController.unblock(ConfigEditorComponent.BLOCK_SOURCE_ID))).subscribe(configs => {
            this.initConfigs(configs);
            this.showPricingAndValidation = this.saveAddonOnFormSubmit; // hide for window designer and global add
            if (newPosition) {
                this.dialogs.add = true;
            } else {
                this.configSystemSelected(this.data.configSystemId, true);
            }
            this.changeDetector.markForCheck();
        });
    }

    private loadCatalogConfiguration(loadAll: boolean): Observable<CatalogConfiguration> {
        return loadAll ? this.catalogConfigurationService.getForTarget(CatalogPropertyTarget.CONFIG_ADDONS) : of(null);
    }

    private loadProfitMargins(systemId: number): Observable<ProfitMarginExistance> {
        return this.readOnlyMode ?
            of(new ProfitMarginExistance(true)) :
            this.configSystemService.validateMarginExistance(systemId, this.currentActionPosition.offerId, this.applicableTo);
    }

    private hideUiBlock(): void {
        this.blockUiController.unblock(ConfigEditorComponent.BLOCK_SOURCE_ID);
    }

    configSystemLargeImageGetter(): (itemId: number) => Observable<string> {
        return (itemId: number) => {
            return this.configSystemService.getImageLargeAsString(itemId);
        };
    }

    materialColorLargeImageGetter(): (materialId: number, colorId: number) => Observable<string> {
        return (materialId: number, colorId: number) => {
            return this.materialService.getMaterialColorImageLargeAsString(materialId, colorId);
        };
    }

    configSystemSelected(configSystemId: number, init?: boolean): void {
        if (configSystemId == undefined) {
            this.onAddDialogClosed();
            return;
        }
        this.blockUiController.block(ConfigEditorComponent.BLOCK_SOURCE_ID);
        this.systemLinksLoaded = false;
        this.loadProfitMargins(configSystemId)
            .pipe(catchError(
                (error) => {
                    this.errors.handle(error);
                    return EMPTY;
                }),
                this.missingProfitMarginHandlerService.handleProfitMarginExistenceResult({
                ...this.offer,
                identifier: {target: 'CONFIG_SYSTEM', configSystemId: configSystemId}
            }),
                mergeMap(profitMarginValid => {
                if (profitMarginValid) {
                    if (!init) {
                        this.data = new ConfigData();
                    }
                    this.data.configSystemId = configSystemId;
                    // TODO MP pobranie nieaktualnego systemu na edycji
                    this.selectedConfigSystem = this.configs.find(config => config.id === configSystemId);
                    this.dialogs.add = false;
                    if (!this.readOnlyMode) {
                        // intentionally outside forkJoin. We don't want the designer to wait for this data.
                        // It's required only in material-color choice dialog.
                        // If user opens that dialog before this data is loaded he should have ui blocked with spinner.

                        // if readonly mode then this request is not needed because forkJoin's initColorMaterialLink
                        // does the trick for just selected.
                        this.materialService.getColorLinksForConfigSystem(configSystemId, [this.data.materialId])
                            .subscribe(links => {
                                this.colorMaterialLinks = links;
                                this.systemLinksLoaded = true;
                                this.changeDetector.markForCheck();
                            });
                    }
                    return forkJoin({
                        systemImage: this.configSystemService.getDesignerImage(configSystemId),
                        initColorMaterialLink: (this.data.materialId == null && this.data.colorId == null) ? of(null) : this.materialService.getColorLinkForMaterial(this.data.materialId, this.data.colorId),
                        sidebarAddons: this.addonCategoryGroupService.getGroupsForConfigDeep(configSystemId, _.values(this.data.sidebarAddons), this.includeAutoOptions()),
                        ...this.configEditorFieldContentProvider.getCatalogDataSource(configSystemId, this.data, this.readOnlyMode),
                        defaults: this.systemDefaultsService.getDefaultsForConfig(configSystemId, this.offer.id)
                    });
                } else {
                    return EMPTY;
                }
            }), finalize(() => this.hideUiBlock())).subscribe(
            (catalogData) => {
                if (this.data.sidebarAddons != null && !init) {
                    this.data.sidebarAddons = {};
                }
                if (catalogData.initColorMaterialLink != null && !this.systemLinksLoaded) {
                    this.colorMaterialLinks = [catalogData.initColorMaterialLink];
                    this.changeDetector.markForCheck();
                }
                this.addonCategoryGroups = catalogData.sidebarAddons;
                let categories = [];
                (this.addonCategoryGroups || []).forEach(group => {
                    group.categories.forEach(category => {
                        categories.push(category.symbol);
                        this.configEditorFieldContentProvider.setItems(category.symbol, [], [this.data.sidebarAddons[category.symbol]]);
                    });
                });

                this.visibleAddonCategoryGroups = catalogData.sidebarAddons;
                let fieldEnum = this.sanitizeDataOnExistingPosition();
                this.configEditorFieldContentProvider.initializeProvider(fieldEnum);
                this.initCatalog(this.data.configSystemId, catalogData);
                this.defaults = catalogData.defaults;
                if (!init) {
                    for (let sidebarAddonsKey in this.defaults.value.sidebarAddons) {
                        if (!categories.includes(sidebarAddonsKey)) {
                            delete this.defaults.value.sidebarAddons[sidebarAddonsKey];
                        }
                    }
                    ConfigData.applyDefaults(this.data, this.defaults, this.applicableTo.includes(ConfigAddonApplication.INDEPENDENT));
                    this.data = {...this.data};
                    if (this.colorMaterialLinks == null || this.colorMaterialLinks.length === 0) {
                        ((this.data.materialId == null || this.data.colorId == null) ? of(null) : this.materialService.getColorLinkForMaterial(this.data.materialId, this.data.colorId))
                            .subscribe(a => {
                                this.colorMaterialLinks = [a];
                                this.changeDetector.markForCheck();
                            });
                    }
                }
                this.configEditorFieldContentProvider.storeSelectedValues(this.data);
                this.configEditorFieldContentProvider.resetNewItems();
                this.setPreviewImage(catalogData.systemImage);
                this.showDesignerDialog = true;
                this.changeDetector.markForCheck();
            });
    }

    private includeAutoOptions() {
        return [DialogType.ADD_CONFIGURABLE_ADDON, DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON].includes(this.dialogType);
    }

    private initCatalog(systemId: number, data: CatalogLoadResult) {
        this.catalogLoadedForSystemId = systemId;
        const mapSelectItem = (item: { id: number, name: MultilanguageFieldInterface, active: boolean, singlePositionAddon?: boolean, additionalIcon?: string, hex?: string }) => ({
            label: item.name[this.translate.currentLang],
            value: item.id,
            available: item.active,
            singlePositionItem: item.singlePositionAddon,
            icon: item.additionalIcon,
            title: item.hex
        });
        const mapSelectItem2 = (item: { id: number, names: MultilanguageFieldInterface, active: boolean }) => ({
            label: item.names[this.translate.currentLang],
            value: item.id,
            available: item.active
        });

        (this.addonCategoryGroups || []).forEach(group => {
            group.categories.forEach(category => {
                let items = category.addons.map(mapSelectItem);
                this.configEditorFieldContentProvider.setItems(category.symbol, items, [this.data.sidebarAddons[category.symbol]]);
            });
        });

        this.configEditorFieldContentProvider.setItems(ConfigEditorField.MATERIAL, data.materials.map(mapSelectItem), [this.data.materialId]);
        this.configEditorFieldContentProvider.setItems(ConfigEditorField.COLOR, data.colors.map(mapSelectItem2), [this.data.colorId]);

        this.configEditorFieldContentProvider.storeFieldDependencies(data.fieldDependencies);
    }

    private initConfigs(configs: ConfigSystem[]): void {
        if (configs.length === 0) {
            this.onEditorClose();
            this.growls.error("ERRORS.CONFIG_ADDON_NO_SYSTEMS_AVAILABLE");
            return;
        }
        this.configs = configs;
        this.selectedConfigSystem = this.configs.find(config => config.id === this.data.configSystemId);
    }

    private setPreviewImage(data: string): void {
        this.systemPreviewImage = data == null ? null : this.sanitizer.bypassSecurityTrustUrl(data);
    }

    handleSidebarActiveIndexChange(tabIndex: SidebarTab): void {
        switch (tabIndex) {
            case SidebarTab.GENERAL:
                break;
            case SidebarTab.PRICING:
                this.sanitizeDataBeforeSave();
                this.pricingComponent.getPricing(this.prepareConfigurableAddon(), this.currentActionPosition.id, undefined,
                    this.currentActionPosition.validationDisabled);
                break;
            case SidebarTab.VALIDATION:
                // uses validation messages from pricing, load it
                this.sanitizeDataBeforeSave();
                this.pricingComponent.getPricing(this.prepareConfigurableAddon(), this.currentActionPosition.id, undefined,
                    this.currentActionPosition.validationDisabled);
                break;
        }
    }

    private sanitizeDataBeforeSave(validationErrors = {}) {
        const unavailable = this.configEditorFieldContentProvider.fieldsWithUnavailableValues || [];
        let isSelectedColorAvailable = (this.configEditorFieldContentProvider.getItems(ConfigEditorField.COLOR).find(item => item.value === this.data.colorId) || {}).available;
        if (!isSelectedColorAvailable && !unavailable.includes(ConfigEditorField.COLOR)) {
            unavailable.push(ConfigEditorField.COLOR);
        }

        this.addonCategoryGroups.forEach((group, index) => {
            if ((this.selectedConfigSystem.materialColorCategoryGroupId == null && index === 0) || group.id === this.selectedConfigSystem.materialColorCategoryGroupId) {
                if (this.data.colorId != null) {
                    let linkAvailable = this.colorMaterialLinks.some(link => link.colorId === this.data.colorId && link.materialId === this.data.materialId);
                    if (!linkAvailable) {
                        this.setupScrollToError(validationErrors, group.id, ConfigEditorField.COLOR, ConfigEditorField.COLOR);
                        this.growls.error('CONFIG_SYSTEM.MISSING_COLOR_MATERIAL_LINK');
                    }
                }
                [ConfigEditorField.MATERIAL, ConfigEditorField.COLOR]
                    .filter(field => this.fieldUsage.isNotValid(field) || unavailable.includes(field))
                    .forEach(field => this.setupScrollToError(validationErrors, group.id, field, field));
            }
            group.categories
                .forEach(category => {
                    let isFilled = this.data.sidebarAddons[category.symbol] != null;
                    let isVisible = this.fieldUsage.isVisible(category.symbol);
                    let anyOptionAvailable = category.addons.some(addon => addon.active);
                    if (anyOptionAvailable && isVisible && ((!isFilled && category.required) || unavailable.includes(category.symbol))) {
                        this.setupScrollToError(validationErrors, group.id, group.id + '_' + category.symbol, category.symbol);
                    } else if (isFilled && !isVisible) {
                        delete this.data.sidebarAddons[category.symbol];
                    }
                });
        });
    }

    private setupScrollToError(validationErrors: ValidationErrors, groupId: string | number, fieldName: string, fieldSymbol: string) {
        validationErrors[fieldName] = "ERROR";
        if (this.fieldToFocusOnError == undefined) {
            this.fieldToFocusOnError = fieldSymbol;
            this.groupToFocusOnError = this.sidebarComponent.addonCategoryGroupName + groupId;
        }
    }

    generalTabContainsErrors() {
        return ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
    }

    private loadDefaultsLevels(): void {
        if (this.permissions.isKoordynator()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.GLOBAL',
                value: 'GLOBAL'
            });
        }
        if (this.permissions.isKoordynator() || this.permissions.isOpiekun()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM_GROUP',
                value: 'SUBSYSTEM_GROUP'
            });
        }
        if (this.permissions.isKoordynator() || this.permissions.isOpiekun() || this.permissions.isOperator()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM',
                value: 'SUBSYSTEM'
            });
        }
        if (this.permissions.isOperator()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.CLIENT_GROUP',
                value: 'CLIENT_GROUP'
            });
        }
        if (this.permissions.isOperator() || this.permissions.isHandlowiec() || this.permissions.isSprzedawca()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.CLIENT',
                value: 'CLIENT'
            });
        }
        this.defaultsLevels.push({
            label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.OFFER',
            value: 'OFFER'
        });
        if (this.defaultsLevels.length > 0) {
            this.defaultsLevel = this.defaultsLevels[0].value;
        }
    }

    openChangeSystemDialog(): void {
        (this.catalogConfiguration == null ? this.loadCatalogConfiguration(true) : of(this.catalogConfiguration)).subscribe(
            catalogConfiguration => {
                this.catalogConfiguration = catalogConfiguration;
                this.dialogs.add = true;
            }
        );
    }

    sanitizeDataOnExistingPosition(): string[] {
        let validCategories = _.chain(this.addonCategoryGroups)
            .map(group => group.categories)
            .flatten()
            .map(category => category.symbol)
            .value();
        let selectedCategories = Object.keys(this.data.sidebarAddons);
        for (let category of selectedCategories) {
            if (!validCategories.includes(category)) {
                delete this.data.sidebarAddons[category];
            }
        }
        return validCategories;
    }

    getTabpanelHeaderStyle(status: ResponseStatusFlags): string {
        return ResponseStatusHelper.tabpanelHeaderStyle(status);
    }

    updateValidationPricingStatuses(pricing: Pricing, omitPricing: boolean) {
        ResponseStatusHelper.updateValidationStatuses(this.backendValidationStatus, pricing);
        let pricingTabSelected = this.sidebarActiveTabIndex === SidebarTab.PRICING;
        if (!omitPricing || pricingTabSelected) {
            ResponseStatusHelper.updateValidationStatuses(this.pricingStatus, pricing);
        }
    }

    onShowDescriptionDialog(): void {
        this.dialogs.description = true;
    }

    handleDefaultsSavedClick(): void {
        if (this.defaults[this.getFieldNameFromLevel(this.defaultsLevel)]) {
            this.dialogs.confirmReplacing = true;
            return;
        }

        this.saveDefaults();
    }

    saveDefaults(): void {
        const data = ConfigSystemDefaults.copy(this.data);
        let observable: Observable<any> = of({});

        const offerId = this.offer.id;
        if (this.defaultsOverrideLowerLevel) {
            observable = observable.pipe(
                mergeMap(() => this.systemDefaultsService.deleteSystemDefaults(
                    CatalogItemType.CONFIG_SYSTEM, this.defaultsLevel, this.selectedConfigSystem.id, offerId)),
                tap(() => {
                    const levels = ['GLOBAL', 'SUBSYSTEM_GROUP', 'SUBSYSTEM', 'CLIENT_GROUP', 'CLIENT', 'OFFER'];
                    const savedIndex = levels.indexOf(this.defaultsLevel) + 1;
                    for (let i = savedIndex; i < levels.length; ++i) {
                        this.defaults[this.getFieldNameFromLevel(levels[i])] = false;
                    }
                }));
        }
        observable.pipe(mergeMap(() => this.systemDefaultsService.save(CatalogItemType.CONFIG_SYSTEM, this.defaultsLevel, this.selectedConfigSystem.id,
            offerId, data))).subscribe({
            complete: () => {
                this.dialogs.confirmReplacing = false;
                this.defaults.value = data;
                this.defaults[this.getFieldNameFromLevel(this.defaultsLevel)] = true;
                this.growls.info('OFFER.TABS.SECTION.DEFAULTS.SAVED');
                this.changeDetector.markForCheck();
            },
            error: error => {
                let errors = this.errors.handle(error);
                for (let errorsKey in errors) {
                    this.growls.error(errors[errorsKey]);
                }
            }
        });
    }

    private getFieldNameFromLevel(level: string): keyof Omit<ConfigSystemDefaultsState, 'value'> {
        switch (level) {
            case 'GLOBAL':
                return 'globalDefaultExisting';
            case 'SUBSYSTEM_GROUP':
                return 'subsystemGroupDefaultExisting';
            case 'SUBSYSTEM':
                return 'subsystemDefaultExisting';
            case 'CLIENT_GROUP':
                return 'clientGroupDefaultExisting';
            case 'CLIENT':
                return 'clientDefaultExisting';
            case 'OFFER':
                return 'offerDefaultExisting';
        }
    }

    handleSidebarFieldChange(event: SidebarFieldChangeEvent): void {
        this.updateDependentFieldsOnChange(event);
        this.updateUsedGlobalSettingsStateOnFieldChange(event);
        if (event.field === ConfigEditorField.COLOR && this.applicableTo.includes(ConfigAddonApplication.INDEPENDENT)) {
            this.validateConfig();
        }
    }

    private updateDependentFieldsOnChange(event: SidebarFieldChangeEvent): void {
        this.configEditorFieldContentProvider.notifyFieldChanged(event.field, event.newValue);
        switch (event.field) {
            case ConfigEditorField.DIM_1:
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_1_GREATER_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_1_LESS_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.AREA_GREATER_THAN, SizeUnitsConverter.mmSidesToMeterSquared(event.newValue, this.data.wym1));
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.AREA_LESS_THAN, SizeUnitsConverter.mmSidesToMeterSquared(event.newValue, this.data.wym1));
                break;
            case ConfigEditorField.DIM_2:
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_2_GREATER_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_2_LESS_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.AREA_GREATER_THAN, SizeUnitsConverter.mmSidesToMeterSquared(this.data.wym2, event.newValue));
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.AREA_LESS_THAN, SizeUnitsConverter.mmSidesToMeterSquared(this.data.wym2, event.newValue));
                break;
            case ConfigEditorField.DIM_3:
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_3_GREATER_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_3_LESS_THAN, event.newValue);
                break;
            case ConfigEditorField.DIM_4:
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_4_GREATER_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_4_LESS_THAN, event.newValue);
                break;
            case ConfigEditorField.DIM_5:
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_5_GREATER_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_5_LESS_THAN, event.newValue);
                break;
            case ConfigEditorField.DIM_6:
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_6_GREATER_THAN, event.newValue);
                this.configEditorFieldContentProvider.notifyFieldChanged(ConfigEditorField.DIM_6_LESS_THAN, event.newValue);
                break;
        }
    }

    private updateUsedGlobalSettingsStateOnFieldChange(event: SidebarFieldChangeEvent): void {
        if (this.fieldIsSavedInDefaults(event.field)) {
            this.data.usedGlobalSettingsChanged = true;
        }
    }

    private fieldIsSavedInDefaults(field: ConfigEditorField | string): boolean {
        switch (field) {
            case ConfigEditorField.COLOR:
            case ConfigEditorField.MATERIAL:
                return true;
            default:
                // addons
                return !Object.keys(ConfigEditorField).includes(field);
        }
    }

    private needsValidationOnBlur(field: string): boolean {
        switch (field) {
            case ConfigEditorField.DIM_1:
            case ConfigEditorField.DIM_2:
            case ConfigEditorField.DIM_3:
            case ConfigEditorField.DIM_4:
            case ConfigEditorField.DIM_5:
            case ConfigEditorField.DIM_6:
                return true;
            default:
                return false;
        }
    }

    handleSidebarFieldBlur(event: SidebarFieldChangeEvent): void {
        if (this.needsValidationOnBlur(event.field)) {
            this.validateConfig();
        }
    }

    private validateConfig() {
        this.sanitizeDataBeforeSave();
        this.pricingService.evaluateConfig(this.readOnlyMode, this.prepareConfigurableAddon(), this.offer.id,
            this.currentActionPosition.id, true, this.currentActionPosition.validationDisabled).subscribe({
            next: pricingResult => {
                ResponseStatusHelper.updateValidationStatuses(this.pricingStatus, pricingResult);
                ResponseStatusHelper.updateValidationStatuses(this.backendValidationStatus, pricingResult);
                if (this.pricingStatus.errors) {
                    this.growls.error('OFFER.DRAWING.PRICING.ERROR');
                }
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    private prepareConfigurableAddon(): ConfigurableAddon {
        let configuredAddon = new ConfigurableAddon();
        configuredAddon.offerId = this.offer.id;
        configuredAddon.parentElementDescription = this.parentElementDescription;
        if (this.applicableTo.includes(ConfigAddonApplication.INDEPENDENT)) {
            configuredAddon.application = ConfigAddonApplication.INDEPENDENT; // this.displayedDialogData.application;
        } else {
            configuredAddon.application = this.selectedConfigSystem.applicableTo.filter(a => a !== ConfigAddonApplication.INDEPENDENT)[0];
        }
        configuredAddon.openings = this.displayedDialogData.openings;
        configuredAddon.windowSystemId = this.displayedDialogData.windowSystemId;

        configuredAddon.attributes = null;
        configuredAddon.configData = this.data;
        configuredAddon.definitionId = this.data.configSystemId;
        configuredAddon.glazingBeadId = this.positionData.glazingBeadId;
        return configuredAddon;
    }

    saveAndExit(): void {
        let existingLink = this.colorMaterialLinks.find(link => link.colorId === this.data.colorId && link.materialId === this.data.materialId);
        if (this.data.colorId && this.data.materialId && !existingLink) {
            this.growls.warning('CONFIG_EDITOR.SAVE_DISABLED_DATA_LOADING');
            return;
        }
        if (this.saveInProgress || this.readOnlyMode || !this.validateAll()) {
            this.focusOnErrorField();
            return;
        }
        this.saveInProgress = true;
        let configurableAddon = this.prepareConfigurableAddon();
        let position = this.prepareOfferPosition(configurableAddon);
        position.quantity = this.currentActionPosition.quantity;
        let positions: Position[] = [position];
        if (this.saveAddonOnFormSubmit) {
            this.positionService.saveItem(position).subscribe({
                next: () => {
                },
                error: (error: HttpErrorResponse): void => {
                    this.saveInProgress = false;
                    this.errors.handle(error);
                    this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
                    this.changeDetector.markForCheck();
                },
                complete: (): void => {
                    this.finishAndEmitSuccess("Saved to database.", positions, configurableAddon);
                    this.changeDetector.markForCheck();
                }
            });
        } else {
            if (position.id || position.assignedId) {
                this.finishAndEmitSuccess("Edited existing addon.", positions, configurableAddon);
            } else {
                let addonsCount;
                if (this.dialogType === DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON) {
                    let globalAddData = this.displayedDialogData as GlobalAddConfigAddonsDialogData;
                    addonsCount = OfferComponentsCounter.countApplicable(globalAddData.selectedPositions, configurableAddon.application);
                } else {
                    addonsCount = this.displayedDialogData.addonsCount;
                }
                this.idGeneratorService.getGeneratedOfflineIds(addonsCount).subscribe({
                    next: data => {
                        for (let i = 0; i < data.ids.length; i++) {
                            if (i > 0) {
                                position = JSON.parse(JSON.stringify(position));
                                positions.push(position);
                            }
                            position.assignedId = data.ids[i];
                        }
                        this.finishAndEmitSuccess("Created new addon with generated id", positions, configurableAddon);
                    },
                    error: error => {
                        this.saveInProgress = false;
                        this.errors.handle(error);
                        this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
                    }
                });
            }
        }
    }

    private finishAndEmitSuccess(message: string, positions: Position[], configurableAddon: ConfigurableAddon) {
        let addonAutoOptionGrouper = (aggr, addon) => {
            aggr[addon.id] = addon.autoOption;
            return aggr;
        };
        let categoriesWithAutoOptions: CategoryWithAutoOptions[] = _.chain(this.visibleAddonCategoryGroups)
            .map(group => group.categories)
            .flatten()
            .filter(category => category.hasAutoOption)
            .map(category => ({symbol: category.symbol, addons: category.addons.reduce(addonAutoOptionGrouper, {})}))
            .value();

        this.dialogHideHelper.call(() => {
            this.emitOnSuccess(positions, this.dialogType, configurableAddon, categoriesWithAutoOptions);
            // this.resetDialog();
            this.saveInProgress = false;
            this.blockUiController.unblock(ConfigurableAddonDialogComponent.SAVE_CONFIG_ADDON_ID);
            console.info('ConfigurableAddonDialog action `saveItem` completed! ' + message);
        });
    }

    private emitOnSuccess(positions: Position[], mode: DialogType, configurableAddon: ConfigurableAddon, categoriesWithAutoOptions: CategoryWithAutoOptions[]): void {
        this.onClose.emit(
            positions.map(position => new ConfigurableAddonDialogEventModel(mode, position, configurableAddon, this.selectedConfigSystem.name, categoriesWithAutoOptions)));
    }

    private prepareOfferPosition(configurableAddon: ConfigurableAddon): Position {
        let position;
        let dimensions = configurableAddon.configData.wym1 + "x" + configurableAddon.configData.wym2;
        let quantity = 1;
        if (this.currentActionPosition) {
            position = Object.assign({}, this.currentActionPosition);
            position.data = JSON.stringify(configurableAddon);
            position.quantity = quantity;
            position.name = this.selectedConfigSystem.name;
            position.dimensions = dimensions;
        } else {
            position = new Position(this.selectedConfigSystem.name, PositionType.CONFIG_SYSTEM,
                null, dimensions, quantity, null, null,
                null, null, null, null, null, null, null, null,
                JSON.stringify(configurableAddon), this.offer.id, null);
        }
        position.supplierId = this.selectedConfigSystem.supplier.id;
        position.configSystemId = this.selectedConfigSystem.id;
        return position;
    }

    saveItem(): void {
        this.positionService.saveItem(this.currentActionPosition as Position)
            .subscribe({
                next: newOfferPositionId => {
                    this.saveInProgress = false;
                    this.onEditorClose(newOfferPositionId);
                },
                error: error => {
                    this.errors.handle(error);
                    this.saveInProgress = false;
                }
            });
    }

    validateAll(): boolean {
        this.validationErrors = this.getValidationErrors();
        let errorsPresent = ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);

        const unavailableDetected = this.configEditorFieldContentProvider.fieldsWithUnavailableValues.length > 0;
        if (unavailableDetected) {
            this.growls.error('OFFER.TABS.ERROR.UNAVAILABLE_ITEMS_PRESENT');
        }

        return !errorsPresent && !unavailableDetected;
    }

    private getValidationErrors(): ValidationErrors {
        const validationErrors = {};
        this.sanitizeDataBeforeSave(validationErrors);

        let requiredFields = ConfigEditorFieldStates.SECTION_FIELDS.get(AccordionTabId.GENERAL);
        const unavailable = this.configEditorFieldContentProvider.fieldsWithUnavailableValues || [];
        for (const field of requiredFields) {
            if (this.fieldUsage.isNotValid(field) || unavailable.includes(field)) {
                this.setupScrollToError(validationErrors, AccordionTabId.GENERAL, field, field);
            }
        }
        return validationErrors;
    }

    private focusOnErrorField() {
        if (this.fieldToFocusOnError) {
            this.handleSidebarActiveIndexChange(SidebarTab.GENERAL);
            if (this.groupToFocusOnError != undefined) {
                this.sidebarComponent.mainAcc.tabs.forEach(at => at.selected = at.id === this.groupToFocusOnError);
            }
            const htmlElement = document.getElementById(this.fieldToFocusOnError + '_id');
            if (htmlElement) {
                setTimeout(() => {
                    document.getElementById(this.fieldToFocusOnError + '_id').scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest'
                    });
                    this.fieldToFocusOnError = undefined;
                    this.groupToFocusOnError = undefined;
                }, 0);
            }
        }
    }

    exitEditor(): void {
        if (this.dataChanged() && this.data.configSystemId != null) {
            this.showExitWithoutSavingConfirmationDialog = true;
        } else {
            this.showExitWithoutSavingConfirmationDialog = false;
            this.onEditorClose();
        }
    }

    private dataChanged(): boolean {
        return this.originalData !== JSON.stringify(this.data);
    }

    onEditorClose(positionId?: number): void {
        this.dialogHideHelper.call(() => {
            this.onClose.emit(null);
        });
    }

    onAddDialogClosed(): void {
        if (this.data.configSystemId == null) {
            this.exitEditor();
        }
        this.dialogs.add = false;
    }

    handleFileDownloadClick(optionId: number) {
        this.catalogOptionService.getCatalogOptionFileUrl(optionId).subscribe(url => window.open(url, '_blank'));
    }
}

export interface CategoryWithAutoOptions {
    symbol: string;
    addons: {[addonId: number]: AutoOption};
}
