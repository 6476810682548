import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from "primeng/datatable";
import {forkJoin, Observable} from "rxjs";
import {Permissions} from "../../../../../auth/permission.service";
import {StorageService} from '../../../../../auth/storage.service';
import {DatatableInterface} from '../../../../../common/DatatableHelper';
import {ResponseError} from '../../../../../common/error.handler';
import {ExchangeService} from "../../../../../common/exchange.service";
import {SavedFilterService} from '../../../../../common/saved-filter/saved-filter.service';
import {CrudComponent} from "../../../../../common/service/crud.component";
import {DataTableColumnBuilder, SavedShownColumns} from '../../../../../common/service/data.table.column.builder';
import {ExportComponent} from "../../../../../common/service/export.component";
import {Currencies} from "../../../../../currencies";
import {TristateCheckboxState} from "../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {ErrorResponse} from '../../../../errors/errorResponse';
import {SubsystemService} from '../../../../subsystem/subsystem.service';
import {HasSavedFilter} from '../../../has-saved-filter';
import {ShippingSimulationModel} from "../shipping-simulation.model";
import {ShippingSimulationService} from "../shipping-simulation.service";

@Component({
    selector: 'app-shipping-simulation-list',
    templateUrl: './shipping-simulation-list.component.html',
    styleUrls: ['./shipping-simulation-list.component.css', '../../../../shared-styles.css',
        '../../../../../common/offer-status-colors.css'],
    providers: [ExportComponent, SubsystemService, ExchangeService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingSimulationListComponent extends CrudComponent implements OnInit, HasSavedFilter {

    readonly TABLE_ID = 'simulationsTable';

    private ignoreStoredFilter: boolean;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    lastLazyLoadEvent: LazyLoadEvent;
    simulations: ShippingSimulationModel[] = [];
    selectedItem: ShippingSimulationModel;
    selectedItems: ShippingSimulationModel[] = [];
    allSelectedState = TristateCheckboxState.UNCHECKED;
    defaultCurrency = Currencies.EUR;
    selectedSubsystems: string[] = [];
    availableSubsystems: SelectItem[] = [];
    multipleSimulationsCost: number;

    @ViewChild('dt', {static: true})
    dataTable: DataTable;

    constructor(injector: Injector, changeDetector: ChangeDetectorRef,
                private shippingSimulationService: ShippingSimulationService,
                private router: Router,
                private route: ActivatedRoute,
                private exchangeService: ExchangeService,
                private subsystemService: SubsystemService,
                public permissions: Permissions,
                private storage: StorageService) {
        super(injector, changeDetector, 'ShippingSimulationListComponent', false);
    }

    ngOnInit() {
        super.ngOnInit();
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.createTable();
        this.resetDefaultFilterSelection();
        forkJoin({
            subsystems: this.subsystemService.getSelectionItems(),
            exchangeRates: this.exchangeService.initializeExchangeRates()
        }).subscribe(data => {
            this.availableSubsystems = data.subsystems;
            this.exchangeService.storeExchangeRates(data.exchangeRates);
            this.changeDetector.markForCheck();
        });
    }

    // needed for column default filter values
    private createTable(): void {
        let builder = DataTableColumnBuilder.create()
            .add('name', 'SHIPPING_SIMULATION.LIST.NAME', true)
            .add('createdDate', 'SHIPPING_SIMULATION.LIST.CREATED_DATE', true)
            .add('price', 'SHIPPING_SIMULATION.LIST.PRICE', true)
            .add('slots', 'SHIPPING_SIMULATION.LIST.SLOTS', true);
        if (this.isSubsystemColumnVisible()) {
            builder.add('subsystemName', 'SHIPPING_SIMULATION.LIST.SUBSYSTEM', true);
        }
        this.init(builder.build());
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalFilters = true): void {
        super.loadItemsLazy(event);
        this.filterExchangeRate(event);
        this.lastLazyLoadEvent = event;
        this.storeLazyLoadEvent(event, this.storage);
        this.onDataLazyLoad.emit(event);
        if (updateOriginalFilters) {
            this.onUpdateOriginalFilters.emit(event);
        }
        this.shippingSimulationService.getSimulations(event.first, this.chosenRowsPerPage, event.filters, event.sortField, event.sortOrder)
            .subscribe(response => {
                this.simulations = response.data;
                this.totalRecords = response.totalRecords;
                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                this.totalRecordsChange.emit(this.totalRecords);
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            });
    }

    getExportData(): Observable<object[]> {
        throw new Error('Method not implemented.');
    }

    showDialogToAdd(): void {
        throw new Error('Method not implemented.');
    }

    submit(): void {
        throw new Error('Method not implemented.');
    }

    onRowSelect(event: any): void {
        throw new Error('Method not implemented.');
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    showSimulationDetails(simulation: ShippingSimulationModel): void {
        this.router.navigate(["/features/shipping-simulation-details", {
            previousRoute: '/features/offer',
            component: 'shipping-simulation',
            simulationId: simulation.id
        }]);
    }

    rowDoubleClick(event): void {
        this.showSimulationDetails(event.data);
    }

    private filterExchangeRate(event: LazyLoadEvent): void {
        this.exchangeService.applyRateToRangeFilters(['price'], event, this.defaultCurrency, this.defaultCurrency);
    }

    formatSimulationPrice(shippingSimulation: ShippingSimulationModel): string {
        return shippingSimulation.price != null
            ? ExchangeService.formatPriceInCurrency('' + shippingSimulation.price, this.defaultCurrency)
            : '...';
    }

    isSubsystemColumnVisible(): boolean {
        return this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.getDatatable().filters = {};
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLazyLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        this.lastLazyLoadEvent.filters = filter.filters;
        this.lastLazyLoadEvent.sortOrder = filter.sortOrder;
        this.lastLazyLoadEvent.sortField = filter.sortField;
        this.applyMultiSelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedSubsystems, filter.filters['subsystemIds']);
        this.getDatatable().setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
    }

    private resetDefaultFilterSelection() {
        if (!this.ignoreStoredFilter) {
            this.lastLazyLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
            if (this.lastLazyLoadEvent != undefined) {
                this.applySavedFilter(this.lastLazyLoadEvent);
            }
        }
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.getDatatable().filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    selectAllChange(allSelectedState: TristateCheckboxState) {
        this.allSelectedState = allSelectedState;
        this.selectedItems = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.simulations);
        }
        this.changeDetector.markForCheck();
    }

    isSelectedItem(item: ShippingSimulationModel): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: ShippingSimulationModel) {
        let index = this.selectedItems.indexOf(item);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }
        this.refreshAllSelectedFlag();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedItems.length === this.simulations.length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    calculateShippingCostForSelectedSimulations(): void {
        this.shippingSimulationService.calculateShippingCostForMultipleSimulations(this.selectedItems.map(simulation => simulation.id))
            .subscribe({
                next: result => {
                    this.multipleSimulationsCost = result;
                    this.changeDetector.markForCheck();
                },
                error: (error: HttpErrorResponse) => {
                    const errorResponse = new ErrorResponse(error.error);
                    if (errorResponse.is400()) {
                        this.growlMessageController.error(errorResponse.message);
                    } else {
                        throw new ResponseError(error);
                    }
                }
            });
    }
}
