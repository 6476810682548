<app-wizard #wizard header="{{ 'NAVIGATION.ITEMS.CLIENTS.ADD-CLIENT' | translate }}" (onCancel)="exitWizard()"
            (currentStepChange)="onStepChange($event)" (onComplete)="submit()">
    <app-wizard-step label="{{ 'CLIENT-DETAILS.FORM.GENERAL' | translate }}" [id]="STEPS.FILL_DETAILS"
                     [validate]="validateClientDetailsStep">
        <div *ngIf="dataReady">
            <app-client-details-form [client]="client" [selectCurrencies]="selectCurrencies"
                                     [validationErrors]="validationErrors" [existingGroups]="existingGroups"
                                     [suppliers]="suppliers"
                                     [groupSimpleProfitMarginSource]="groupSimpleProfitMarginSource"
                                     [simpleProfitMarginSource]="simpleProfitMarginSource"
                                     (onClientGroupChanged)="reloadProfitMargins($event)"></app-client-details-form>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_SYSTEMS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div *ngIf="dataReady" class="new-form-60vw">
            <app-profit-margin *ngIf="dataReady && client.groupId" #childTable [clientGroupId]="client.groupId"
                               [clientId]="client.id" entityType="client" [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
            <div *ngIf="!client.groupId" class="new-form-600">{{'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate}}</div>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.ROOF_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_ROOF_SYSTEMS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div *ngIf="dataReady" class="new-form-60vw">
            <app-profit-margin *ngIf="dataReady && client.groupId" #childTable [clientGroupId]="client.groupId"
                               [clientId]="client.id" entityType="client" [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
            <div *ngIf="!client.groupId" class="new-form-600">{{'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate}}</div>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.ENTRANCE_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_ENTRANCE_SYSTEMS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div *ngIf="dataReady" class="new-form-60vw">
            <app-profit-margin *ngIf="dataReady && client.groupId" #childTable [clientGroupId]="client.groupId"
                               [clientId]="client.id" entityType="client" [userCanEdit]="true"
                               [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                               [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
            <div *ngIf="!client.groupId" class="new-form-600">{{'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate}}</div>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_CONFIG_ADDONS"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div *ngIf="dataReady" class="new-form-60vw">
            <app-profit-margin *ngIf="client.groupId" #childTable [clientGroupId]="client.groupId" [clientId]="client.id"
                               entityType="client" [userCanEdit]="true" [target]="possibleTargets.CONFIG"
                               [globalMarginUpdateButtonId]="confAddGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="confAddGlobalMarginInputId"></app-profit-margin>
            <div *ngIf="!client.groupId" class="new-form-600">{{'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate}}</div>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CLIENT-GROUP.FORM.GATES_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.FILL_PROFIT_MARGINS_GATES"
                     *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !simpleProfitMarginSource">
        <div *ngIf="dataReady" class="new-form-60vw">
            <app-profit-margin *ngIf="client.groupId" #childTable [clientGroupId]="client.groupId" [clientId]="client.id"
                               entityType="client" [userCanEdit]="true" [target]="possibleTargets.GATE"
                               [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                               [globalMarginUpdateInputId]="gatesGlobalMarginInputId"></app-profit-margin>
            <div *ngIf="!client.groupId" class="new-form-600">{{'CLIENT-DETAILS.FORM.SELECT_GROUP_ID' | translate}}</div>
        </div>
    </app-wizard-step>
</app-wizard>
