import {Pipe, PipeTransform} from '@angular/core';
import {Product} from "./Product";
import {ChargeType} from "./Charge";

@Pipe({
    name: 'productType'
})
export class ProductTypePipe implements PipeTransform {

    transform(product: Product): string {
        return product.dimension == null ? 'ADDON' :
            product.components.some(component => component.type === ChargeType.ENTRANCE_DOOR_PRICING) ? 'ENTRANCE_DOOR' :
            product.components.some(component => component.type === ChargeType.GATE_PRICING) ? 'GATE' : 'WINDOW';
            product.components.some(component => component.type === ChargeType.CONFIG_ADDON_CHARGE || component.type === ChargeType.BULK_CONFIG_ADDON_CHARGE || component.type === ChargeType.CONFIG_ADDITIONAL_CHARGE) ? 'CONFIG' : 'WINDOW';
    }

}
