import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class GateWall implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    active = true;
    sortIndex: number;

    static fromJSON(jsonObject): GateWall {
        const item = new GateWall();
        item.id = jsonObject.id;
        item.name = MultilanguageField.fromJSON(jsonObject.name);
        item.shortcut = MultilanguageField.fromJSON(jsonObject.shortcut);
        item.symbol = jsonObject.symbol;
        item.active = jsonObject.active;
        item.sortIndex = jsonObject.sortIndex;
        return item;
    }
}
