<div class="date-range-filter">
	<app-select inputId="kind" [options]="dateRangeKinds | async" [allowSelectingNone]="true" [ngModel]="kind"
				(ngModelChange)="handleKindChange($event)">
		<ng-template pTemplate="label"></ng-template>
	</app-select>
	<ng-container *ngIf="displayCalendars()">
		<app-calendar inputId="from" label="{{ 'GENERAL.DATE_FROM' | translate }}" [inline]="false"
					  [utc]="true" [maxDate]="to" [ngModel]="from" (ngModelChange)="handleFromChange($event)">
			<ng-template pTemplate="label" let-label="label" let-inputId="inputId">
				<div class="date-range-filter-label">
					<label [attr.for]="inputId">{{ label }}</label>
					<app-simple-button label="{{ 'GENERAL.FILTER_CLEAR' | translate }}" icon="clear" [inlineLabel]="false"
									   type="filter" [size]="40" align="right"
									   (onClick)="handleFromChange(undefined)"></app-simple-button>
				</div>
			</ng-template>
		</app-calendar>
		<app-calendar *ngIf="displayTo()" inputId="to" label="{{ 'GENERAL.DATE_TO' | translate }}" [inline]="false"
					  [utc]="true" [minDate]="from" [ngModel]="to" (ngModelChange)="handleToChange($event)">
			<ng-template pTemplate="label" let-label="label" let-inputId="inputId">
				<div class="date-range-filter-label">
					<label [attr.for]="inputId">{{ label }}</label>
					<app-simple-button label="{{ 'GENERAL.FILTER_CLEAR' | translate }}" icon="clear" [inlineLabel]="false"
									   type="filter" [size]="40" align="right"
									   (onClick)="handleToChange(undefined)"></app-simple-button>
				</div>
			</ng-template>
		</app-calendar>
	</ng-container>
</div>
