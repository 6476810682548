import {DistanceFrameInterface, DistanceFrameType} from '../../../../window-designer/catalog-data/distance-frame-interface';
import {MultilanguageField} from "../../../supportedLanguages";

export interface DistanceFramePosition {
    position: number;
    glassesInGlazingCount: number;
}

export class DistanceFrame implements DistanceFrameInterface {
    id: number;
    thickness: number;
    names: MultilanguageField;
    shortcut: MultilanguageField;
    active: boolean;
    symbol: string;
    frameGroup: string;
    type: DistanceFrameType;
    sortIndex: number;
    positions: DistanceFramePosition[];
    warmAlternativeDistanceFrameId: number;
    warmFrame: boolean;

    constructor() {
        this.id = undefined;
        this.thickness = undefined;
        this.active = true;
        this.names = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.symbol = undefined;
        this.frameGroup = undefined;
        this.type = undefined;
        this.sortIndex = 1;
        this.positions = [];
        this.warmAlternativeDistanceFrameId = undefined;
        this.warmFrame = false;
    }
}
