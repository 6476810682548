import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {WebshopAreaBarsCode} from '../../../../../window-designer/webshop-area-bars/WebshopAreaBarsCode';
import {CrudCommonComponent} from '../../../../common/crud-common/crud.component';
import {DatatableInterface} from '../../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../../shared/validator/input-validator';
import {WebshopAreaBars} from './webshop-area-bars';
import {WebshopAreaBarsService} from './webshop-area-bars.service';

@Component({
    selector: 'app-webshop-area-bars',
    templateUrl: './webshop-area-bars.component.html',
    styleUrls: ['./webshop-area-bars.component.css'],
    providers: [WebshopAreaBarsService, TranslatedSelectItemService]
})
export class WebshopAreaBarsComponent extends CrudCommonComponent<WebshopAreaBars, WebshopAreaBarsService> implements OnInit {

    webshopAreaBarsCodeFilter: Observable<SelectItem[]>;

    @ViewChild(DataTable, {static: true})
    dataTable: DataTable;

    stepValidator: WizardStepValidator;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, WebshopAreaBarsService, 'WEBSHOP_AREA_BARS', 'WebshopAreaBars');
        this.stepValidator = () => this.validateForm();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.webshopAreaBarsCodeFilter = this.translatedSelectItemService.buildUnsortedDropdown(WebshopAreaBarsCode, '', '');
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): WebshopAreaBars {
        return new WebshopAreaBars();
    }

    validateForm(): boolean {
        this.validationErrors = {};
        this.validationErrors['sortIndex'] = MultiValidator.of('error.webshopAreaBarsDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, this.totalRecords)
            .validate(this.item.sortIndex);
        return !this.validationErrorsPresent();
    }

    getIcon(webshopAreaBarsCode: WebshopAreaBarsCode): string {
        return `assets/images/area-bars-icons/area-bars-${webshopAreaBarsCode}.svg`;
    }
}
