<ng-container *ngIf="dialogs.add && configs != undefined">
    <app-add-config-dialog [displayDialog]="dialogs.add"
                           [items]="configs" [catalogConfiguration]="catalogConfiguration"
                           [largeImageGetter]="configSystemLargeImageGetter()"
                           (dialogClosed)="configSystemSelected($event)" (openStorageFile)="handleFileDownloadClick($event)"
                           [data]="data"></app-add-config-dialog>
</ng-container>


<div *ngIf="showDesignerDialog" class="dialog-window">
    <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true"
              [modal]="true" [focusOnShow]="false" [showHeader]="false">
        <div class="editor-dialog">
            <div id="designer-container">
                <app-drawing-tool-controls [offer]="offer" [productionOrder]="productionOrder" [readOnlyMode]="readOnlyMode"
                                           [mode]="DrawingToolControlsMode.CONFIG" (onExit)="exitEditor()"
                                           (onSaveAndExit)="saveAndExit()"></app-drawing-tool-controls>

                <app-exit-without-saving-confirmation-dialog [visible]="showExitWithoutSavingConfirmationDialog"
                                                             (hideDialog)="showExitWithoutSavingConfirmationDialog = false"
                                                             (exitWithoutSaving)="onEditorClose()"></app-exit-without-saving-confirmation-dialog>

                <div class="preview-container">
                    <img *ngIf="systemPreviewImage" id="systemPreviewImage" [src]="systemPreviewImage">
                </div>

                <!-- Details panel (sidebar) -->
                <div id="details-panel" *ngIf="catalogLoadedForSystemId != undefined"
                     [ngClass]="{ 'hide-sidebar': !showSidebar }">
                    <div id="details" class="config-details" [hidden]="!showSidebar">
                        <p-tabView id="details-tabs" (activeIndexChange)="handleSidebarActiveIndexChange($event)">
                            <p-tabPanel header="{{ 'OFFER.TABS.GENERAL' | translate }}" class="sidebar-entries"
                                        [headerStyleClass]="generalTabContainsErrors() ? 'errors-on-tab': ''">
                                <app-config-sidebar [position]="currentActionPosition" [parentElementDescription]="parentElementDescription"
                                                    [subwindowTypes]="subwindowTypes"
                                                  [addonCategoryGroups]="visibleAddonCategoryGroups" [fields]="fieldUsage" [data]="data"
                                                  [selectedSystem]="selectedConfigSystem" [validationErrors]="validationErrors"
                                                  [readOnlyMode]="readOnlyMode" [defaultsLevels]="defaultsLevels"
                                                  [(defaultsLevel)]="defaultsLevel"
                                                  [(defaultsOverrideLowerLevel)]="defaultsOverrideLowerLevel"
                                                  [colorMaterialLinks]="colorMaterialLinks" [systemLinksLoaded]="systemLinksLoaded"
                                                  [largeImageGetter]="materialColorLargeImageGetter()"
                                                  (onSystemChange)="openChangeSystemDialog()"
                                                  (afterFieldBlur)="handleSidebarFieldBlur($event)"
                                                  (afterFieldChange)="handleSidebarFieldChange($event)"
                                                  (onShowDescriptionDialog)="onShowDescriptionDialog()"
                                                  (onShowImage)="windowComponentPreviewData = $event"
                                                  (defaultsSavedClick)="handleDefaultsSavedClick()"></app-config-sidebar>
                            </p-tabPanel>

                            <ng-container *ngIf="showPricingAndValidation">
                                <!--Pricing-->
                                <p-tabPanel
                                            header="{{ 'OFFER.TABS.PRICING.TITLE' | translate }}"
                                            [headerStyleClass]="getTabpanelHeaderStyle(pricingStatus) + ' pricingTab'">
                                    <app-pricing #pricing [offer]="offer" [productionOrder]="productionOrder"
                                                 (refreshValidationPricingStatuses)="updateValidationPricingStatuses($event, false)"
                                                 (setErrorToPricingStatus)="pricingStatus.errors = true" [readOnlyMode]="readOnlyMode"
                                                 [isConfig]="true"></app-pricing>
                                </p-tabPanel>

                                <!--Validation-->
                                <p-tabPanel
                                            header="{{ 'OFFER.TABS.VALIDATION.TITLE' | translate }}"
                                            [headerStyleClass]="getTabpanelHeaderStyle(backendValidationStatus) + ' validationTab'">
                                    <app-sidebar-validation [pricingProducts]="pricing.pricing?.products || []"
                                                            [validationMessages]="pricing.pricing?.validationMessages || []"
                                                            [readOnlyMode]="readOnlyMode"
                                                            (refreshValidationPricingStatuses)="updateValidationPricingStatuses($event, true)"
                                                            (setErrorToValidationStatus)="backendValidationStatus.errors = true"></app-sidebar-validation>
                                </p-tabPanel>
                            </ng-container>
                        </p-tabView>
                    </div>
                    <div class="spacer" *ngIf="!showSidebar"></div>
                    <app-sidebar-hider [showSidebar]="showSidebar" [sidebarOnlyMode]="false"
                                       (onClick)="showSidebar = !showSidebar"></app-sidebar-hider>
                </div>
            </div>
        </div>
    </p-dialog>
</div>

<app-missing-profit-margin-handler></app-missing-profit-margin-handler>

<app-window-component-preview-dialog [(previewData)]="windowComponentPreviewData"></app-window-component-preview-dialog>

<app-show-description *ngIf="dialogs.description" [visible]="true"
                      [position]="currentActionPosition" [readOnlyMode]="readOnlyMode"
                      [persistOnSave]="false"
                      (onClose)="dialogs.description = false;"></app-show-description>


<p-dialog #globalConfirmDialog id="gate-system-defaults-replace-dialog" [(visible)]="dialogs.confirmReplacing"
          [resizable]="false" [modal]="true" [focusOnShow]="false"
          header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}" (onHide)="dialogs.confirmReplacing = false">

    <div class="new-form-400">
        <p>{{ 'OFFER.TABS.SECTION.DEFAULTS.CONFIRM.BODY' | translate }}</p>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="config-system-defaults-replace-confirm-button"
                               label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="saveDefaults()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="globalConfirmDialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
