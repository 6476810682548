import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Permissions} from "../../../auth/permission.service";
import {StatusTransition} from "../status-transition-dialog/StatusTransition";
import {StatusTransitionHelper} from "../status-transition-dialog/StatusTransitionHelper";
import {DeliveryListService} from "./delivery-list.service";
import {DeliveryList} from "./delivery-list";
import {DeliveryListStatus} from "./DeliveryListStatus";

@Injectable()
export class DeliveryListStatusTransitionService {

    constructor(private permissions: Permissions,
                private deliveryListService: DeliveryListService) {}

    public createPossibleTransitions(deliveryList: DeliveryList, successCallback?: (actionName: string) => void,
                                     errorCallback?: (actionName: string, error: HttpErrorResponse) => void): StatusTransition[] {
        let possibleTransitions = [];
        let addTransition = (label: string, icon: string, apiCommand: () => any, id: string) => {
            StatusTransitionHelper.genericAddTransition(possibleTransitions, label, icon, apiCommand, id, successCallback, errorCallback);
        };

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            if (deliveryList.status === DeliveryListStatus.DRAFT) {
                addTransition(
                    'DELIVERY_LIST.CHANGE_STATUS.APPROVED',
                    'check',
                    () => this.deliveryListService.setStatus(deliveryList.id, DeliveryListStatus.APPROVED),
                    'APPROVED'
                );
            }
            if (deliveryList.status === DeliveryListStatus.APPROVED) {
                addTransition(
                    'DELIVERY_LIST.CHANGE_STATUS.DRAFT',
                    'close',
                    () => this.deliveryListService.setStatus(deliveryList.id, DeliveryListStatus.DRAFT),
                    'DRAFT'
                );
            }
        }

        return possibleTransitions;
    }

    private isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }
}
