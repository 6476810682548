import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ImagesValidator} from '../../../form-inputs/inputs/file-upload/images-validator';
import {TabularAddress} from '../../subsystem/tabularAddress';
import {SellerSettings} from './seller-settings';
import {SellerSettingsService} from './seller-settings.service';

export class SellerSettingsValidator {

    constructor(public service: SellerSettingsService) {
    }

    validateDetails(sellerSettings: SellerSettings, imageFiles: File[] = []): Observable<ValidationErrors> {
        return this.service.validateDetails(sellerSettings);
    }

    validateAddress(sellerSettings: SellerSettings, availableAddresses: TabularAddress[],
                    newCorrespondenceAddress: TabularAddress,
                    newDeliveryAddress: TabularAddress): Observable<ValidationErrors> {
        const validationErrors = {};
        // selecting existing address
        if (sellerSettings.primaryCorrespondenceAddressId != undefined) {
            const correspondenceAddress = availableAddresses.find(address => address.id === sellerSettings.primaryCorrespondenceAddressId);
            if (correspondenceAddress == undefined) {
                validationErrors['correspondenceAddress'] = 'error.sellerSettingsDto.correspondenceAddress.not_null';
            }
        } else if (newCorrespondenceAddress == null) {
            validationErrors['correspondenceAddress'] = 'error.sellerSettingsDto.correspondenceAddress.not_null';
        }
        if (sellerSettings.primaryDeliveryAddressId != undefined) {
            const deliveryAddress = availableAddresses.find(address => address.id === sellerSettings.primaryDeliveryAddressId);
            if (deliveryAddress == undefined) {
                validationErrors['deliveryAddress'] = 'error.sellerSettingsDto.deliveryAddress.not_null';
            }
        }
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            return of(validationErrors);
        }
        return this.service.validateAddress(sellerSettings, availableAddresses, newCorrespondenceAddress, newDeliveryAddress);
    }
}
