export enum OfferStatus {
    WEBSHOP_DRAFT = 'WEBSHOP_DRAFT',
    COPY_DRAFT = 'COPY_DRAFT',
    NEW_OFFER = 'NEW_OFFER',
    PARTNER_NEW_OFFER = 'PARTNER_NEW_OFFER',
    TO_REVISE = 'TO_REVISE',
    TO_SEND = 'TO_SEND',
    TO_VERIFY = 'TO_VERIFY',
    TO_REVIEW = 'TO_REVIEW',
    REVIEWED = 'REVIEWED',
    CANCEL = 'CANCEL',
    CANCEL_ORDER = 'CANCEL_ORDER',
    VERIFICATION_APPROVED = 'VERIFICATION_APPROVED',
    VERIFICATION_REJECTED = 'VERIFICATION_REJECTED',
    VERIFICATION_FOR_APPROVAL = 'VERIFICATION_FOR_APPROVAL',
    DELETED_ORDER = 'DELETED_ORDER',
    VERIFIED = 'VERIFIED',
    WAITING_FOR_PAYMENT = 'WAITING_FOR_PAYMENT',
    DELETED = 'DELETED',
    PAID = 'PAID',
    CORRECTION = 'CORRECTION',
    CORRECTED = 'CORRECTED',
    PARTIALLY_DISPATCHED = 'PARTIALLY_DISPATCHED',
    DISPATCHED = 'DISPATCHED',
    SENT_TO_PARTNER = 'SENT_TO_PARTNER',
    SUSPENDED = 'SUSPENDED'
}

export const PAID_AND_AFTER_STATUSES = [OfferStatus.PAID, OfferStatus.CORRECTION, OfferStatus.CORRECTED,
    OfferStatus.PARTIALLY_DISPATCHED, OfferStatus.DISPATCHED];
