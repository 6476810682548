import {
    AfterContentInit,
    ChangeDetectorRef,
    ContentChildren,
    Directive,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {ControlValueAccessor} from '@angular/forms';
import {PrimeTemplate} from 'primeng/api';

export abstract class FormHandler {

    inputs: AbstractInputComponent[] = [];

    registerInput(input: AbstractInputComponent): void {
        input.registerOnChange(value => this.onInputChange(value, input));
        this.inputs.push(input);
    }

    unregisterInput(input: AbstractInputComponent): void {
        const index = this.inputs.indexOf(input);
        if (index !== -1) {
            this.inputs.splice(index, 1);
        }
    }

    abstract onInputChange(value: any, input: AbstractInputComponent): void;

    /**
     * Checks if this form has any remaining validation errors - does not validate
     * @returns {boolean}
     */
    hasValidationErrors(ignoreFieldId?: string): boolean {
        return this.inputs
            .filter(input => input.inputId !== ignoreFieldId)
            .map(input => input.validationMessageKey)
            .filter(validationError => validationError != undefined).length === 0;
    }

    clearValidationErrors(): void {
        for (let input of this.inputs) {
            input.validationMessageKeyChange.emit(undefined);
        }
    }
}

export type InputChangeFunction = (value: any) => void;

@Directive()
export abstract class AbstractInputComponent
    implements ControlValueAccessor, OnInit, AfterContentInit, OnDestroy, OnChanges {

    private static readonly DISABLED_CSS_CLASS = 'new-form-field-disabled';
    private static readonly FOCUSED_CSS_CLASS = 'new-form-field-focus';
    private static readonly VALIDATION_ERROR_CSS_CLASS = 'new-form-field-error';

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    placeholder: string;

    @Input()
    validationMessageKey: string;

    @Input()
    hasValidationError: boolean;

    disabled: boolean;

    @Input()
    modelOptions: {
        updateOn?: 'change' | 'blur';
    };

    @Output()
    validationMessageKeyChange = new EventEmitter<string>();

    @Output()
    onFocus = new EventEmitter<FocusEvent>();

    @Output()
    onBlur = new EventEmitter<FocusEvent>();

    @ViewChild('defaultLabel', {read: TemplateRef, static: true})
    defaultLabel: TemplateRef<any>;

    @ViewChild('defaultValidationMessage', {read: TemplateRef, static: true})
    defaultValidationMessage: TemplateRef<any>;

    @ContentChildren(PrimeTemplate)
    templates: QueryList<PrimeTemplate>;

    labelTemplate: TemplateRef<any>;

    validationMessageTemplate: TemplateRef<any>;

    private _value: any;

    private onChange: InputChangeFunction[];
    private onTouched: () => void;

    constructor(protected renderer: Renderer2,
                protected changeDetector: ChangeDetectorRef,
                protected form: FormHandler) {
        this.onChange = [];
        this.onTouched = () => {
        };
    }

    protected abstract getContainer(): ElementRef;

    protected resetTemplatesToDefault(): void {
    }

    protected registerTemplate(type: string, template: TemplateRef<any>): void {
    }

    get value() {
        return this._value;
    }

    set value(obj: any) {
        this._value = obj;
        this.validationMessageKeyChange.emit(undefined);
        this.onChange.forEach(onChange => onChange(obj));
    }

    writeValue(obj: any): void {
        this._value = obj;
        this.changeDetector.markForCheck();
    }

    registerOnChange(fn: InputChangeFunction): void {
        this.onChange.push(fn);
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (this.disabled !== isDisabled) {
            if (isDisabled) {
                this.renderer.addClass(this.getContainer().nativeElement, AbstractInputComponent.DISABLED_CSS_CLASS);
            } else {
                this.renderer.removeClass(this.getContainer().nativeElement, AbstractInputComponent.DISABLED_CSS_CLASS);
            }
            this.disabled = isDisabled;
            this.changeDetector.markForCheck();
        }
    }

    ngOnInit(): void {
        if (this.form != undefined) {
            this.form.registerInput(this);
        }
    }

    ngAfterContentInit(): void {
        this.templates.changes.subscribe(labelTemplates => {
            this.setupTemplates(labelTemplates);
        });
        this.setupTemplates(this.templates.toArray());
    }

    private setupTemplates(templates: PrimeTemplate[]) {
        this.labelTemplate = this.defaultLabel;
        this.validationMessageTemplate = this.defaultValidationMessage;
        this.resetTemplatesToDefault();
        templates.forEach(template => {
            switch (template.getType()) {
                case 'label':
                    this.labelTemplate = template.template;
                    break;
                case 'validationMessage':
                    this.validationMessageTemplate = template.template;
                    break;
                default:
                    this.registerTemplate(template.getType(), template.template);
                    break;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.form != undefined) {
            this.form.unregisterInput(this);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.setValidationMessageErrorState(changes);
        this.setHasValidationErrorState(changes);
        this.setNoLongerAvailableErrorState(changes);
    }

    setValidationMessageErrorState(changes: SimpleChanges): void {
        if ('validationMessageKey' in changes) {
            let change = changes['validationMessageKey'];
            let hasValue = change.currentValue != undefined;
            let hadValue = change.previousValue != undefined;
            if (hasValue !== hadValue) {
                if (hasValue) {
                    this.renderer.addClass(this.getContainer().nativeElement, AbstractInputComponent.VALIDATION_ERROR_CSS_CLASS);
                } else {
                    this.renderer.removeClass(this.getContainer().nativeElement, AbstractInputComponent.VALIDATION_ERROR_CSS_CLASS);
                }
            }
        }
    }

    setHasValidationErrorState(changes: SimpleChanges): void {
        if ('hasValidationError' in changes) {
            let change = changes['hasValidationError'];
            if (change.currentValue !== change.previousValue) {
                if (change.currentValue == true) {
                    this.renderer.addClass(this.getContainer().nativeElement, AbstractInputComponent.VALIDATION_ERROR_CSS_CLASS);
                } else {
                    this.renderer.removeClass(this.getContainer().nativeElement, AbstractInputComponent.VALIDATION_ERROR_CSS_CLASS);
                }
            }
        }
    }

    setNoLongerAvailableErrorState(changes: SimpleChanges): void {
        if ('noLongerAvailable' in changes) {
            let change = changes['noLongerAvailable'];
            if (change.currentValue !== change.previousValue) {
                if (change.currentValue) {
                    this.renderer.addClass(this.getContainer().nativeElement, AbstractInputComponent.VALIDATION_ERROR_CSS_CLASS);
                } else {
                    this.renderer.removeClass(this.getContainer().nativeElement, AbstractInputComponent.VALIDATION_ERROR_CSS_CLASS);
                }
            }
        }
    }

    handleFocus(event: FocusEvent): void {
        this.renderer.addClass(this.getContainer().nativeElement, AbstractInputComponent.FOCUSED_CSS_CLASS);
        this.onFocus.emit(event);
    }

    handleBlur(event: FocusEvent): void {
        this.renderer.removeClass(this.getContainer().nativeElement, AbstractInputComponent.FOCUSED_CSS_CLASS);
        this.onTouched();
        this.onBlur.emit(event);
    }
}
