import {WebshopAreaBarsCode} from '../../../../../window-designer/webshop-area-bars/WebshopAreaBarsCode';
import {JsonDeserializable} from "../../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../../supportedLanguages";

@JsonDeserializable
export class WindowSystemWebshopSettings {
    id: number;
    windowSystemId: number;
    windowSystemName: MultilanguageField;
    windowSystemType: string;
    windowMaterial: string;
    enabledInWebshop: boolean;
    ownWindowSystemName: string;
    availableBusinessTypesIds: number[];
    availableAreaBars: WebshopAreaBarsCode[];

    constructor() {
        this.id = undefined;
        this.windowSystemId = undefined;
        this.windowSystemName = new MultilanguageField();
        this.windowSystemType = undefined;
        this.windowMaterial = undefined;
        this.enabledInWebshop = undefined;
        this.ownWindowSystemName = undefined;
        this.availableBusinessTypesIds = [];
        this.availableAreaBars = [];
    }

    static fromJSON(jsonObject: any): WindowSystemWebshopSettings {
        const dto = new WindowSystemWebshopSettings();
        dto.id = jsonObject.id;
        dto.windowSystemId = jsonObject.windowSystemId;
        dto.windowSystemName = MultilanguageField.fromJSON(jsonObject.windowSystemName);
        dto.windowSystemType = jsonObject.windowSystemType;
        dto.windowMaterial = jsonObject.windowMaterial;
        dto.enabledInWebshop = jsonObject.enabledInWebshop;
        dto.ownWindowSystemName = jsonObject.ownWindowSystemName;
        dto.availableBusinessTypesIds = jsonObject.availableBusinessTypesIds || [];
        dto.availableAreaBars = jsonObject.availableAreaBars || [];
        return dto;
    }
}
