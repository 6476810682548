export class History {

    private static HISTORY_SIZE = 10;

    private performed: any[] = [];
    private recall: any[] = [];

    canRedo(): boolean {
        return this.recall.length > 0;
    }

    canUndo(): boolean {
        return this.performed.length > 0;
    }

    canCancel(): boolean {
        return this.performed.length > 0;
    }

    undo(data?): any {
        if (this.canUndo()) {
            let operation = this.performed.pop();

            if (data) {
                this.recall.push(JSON.stringify(data));
                if (this.recall.length > History.HISTORY_SIZE) {
                    this.recall.shift();
                }
            }
            return JSON.parse(operation);
        }
    }

    cancel(): any {
        if (this.canCancel()) {
            let operation = this.performed.pop();
            return JSON.parse(operation);
        }
    }

    redo(data?): any {
        if (this.canRedo()) {
            let operation = this.recall.pop();

            if (data) {
                this.performed.push(JSON.stringify(data));
                if (this.performed.length > History.HISTORY_SIZE) {
                    this.performed.shift();
                }
            }

            return JSON.parse(operation);
        }
    }

    saveOperation(operation: any): void {
        this.performed.push(JSON.stringify(operation));

        if (this.performed.length > History.HISTORY_SIZE) {
            this.performed.shift();
        }

        if (this.recall.length > 0) {
            this.recall = new Array();
        }
    }
}
