import {ChangeDetectionStrategy, Component, Input, QueryList, ViewChild, ViewChildren} from "@angular/core";
import {Editor} from 'primeng/editor';
import {Supplier} from "../supplier";
import {SupportedLanguages} from "../../../supportedLanguages";

@Component({
    selector: 'app-supplier-annotations-form',
    templateUrl: './supplier-annotations-form.component.html',
    styleUrls: ['../create-supplier/create-supplier.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierAnnotationsFormComponent {

    @Input()
    validationErrors: any;

    @Input()
    supplier: Supplier;

    supportedLanguages = SupportedLanguages.languages;

    @ViewChild("supplierHeaderComment", {static: true}) supplierHeaderComment: Editor;

    @ViewChild("supplierFooterComment", {static: true}) supplierFooterComment: Editor;

    @ViewChildren("offerComment") offerComment: QueryList<Editor>;
}
