import * as _ from 'underscore';
import {Glazing} from '../../../../window-designer/catalog-data/glazing';
import {GlazingPackageForAreaRange} from '../../../../window-designer/catalog-data/glazing-package-for-area-range';
import {GlazingPackageInterface} from '../../../../window-designer/catalog-data/glazing-package-interface';
import {CrudItem} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

export enum GlazingPackageTarget {
    WEBSHOP_GLAZING = 'WEBSHOP_GLAZING',
    UPSELLING_GLAZING = 'UPSELLING_GLAZING',
    SYSTEM_DEFINITION = 'SYSTEM_DEFINITION',
    DECORATIVE_GLAZING = 'DECORATIVE_GLAZING',
    TERRACE_SYSTEM = 'TERRACE_SYSTEM'
}

export class GlazingPackage implements CrudItem, GlazingPackageInterface {
    id: number;
    names: MultilanguageField;
    hasGlazing1: boolean;
    hasGlazing2: boolean;
    hasGlazing3: boolean;
    hasGlazing4: boolean;
    glazing1: Glazing;
    glazing2: Glazing;
    glazing3: Glazing;
    glazing4: Glazing;
    active: boolean;
    sortIndex: number;
    glazingPackageForAreaRangeList: GlazingPackageForAreaRange[];
    target: GlazingPackageTarget;
    linkedSystemIds?: number[];
    decorative: boolean;

    constructor(target: GlazingPackageTarget) {
        this.id = undefined;
        this.names = new MultilanguageField();
        this.hasGlazing1 = false;
        this.hasGlazing2 = false;
        this.hasGlazing3 = false;
        this.hasGlazing4 = false;
        this.glazing1 = new Glazing();
        this.glazing2 = new Glazing();
        this.glazing3 = new Glazing();
        this.glazing4 = new Glazing();
        this.active = true;
        this.sortIndex = undefined;
        this.glazingPackageForAreaRangeList = [];
        this.target = target;
        this.linkedSystemIds = [];
        this.decorative = false;
    }

    static copy(obj: GlazingPackage): GlazingPackage {
        let copy: GlazingPackage = new GlazingPackage(obj.target);
        this.copyGlazingPackageParams(copy, obj);
        return copy;
    }

    protected static copyGlazingPackageParams(target: GlazingPackage, source: GlazingPackage) {
        target.id = source.id;
        target.names = source.names;
        target.hasGlazing1 = source.hasGlazing1;
        target.hasGlazing2 = source.hasGlazing2;
        target.hasGlazing3 = source.hasGlazing3;
        target.hasGlazing4 = source.hasGlazing4;
        target.glazing1 = _.clone(source.glazing1);
        target.glazing2 = _.clone(source.glazing2);
        target.glazing3 = _.clone(source.glazing3);
        target.glazing4 = _.clone(source.glazing4);
        target.sortIndex = source.sortIndex;
        target.glazingPackageForAreaRangeList = [...source.glazingPackageForAreaRangeList];
        target.decorative = source.decorative;
        target.linkedSystemIds = [...source.linkedSystemIds];
    }
}
