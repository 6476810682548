import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {Assembly, AssemblyUnit} from "./assembly";
import {AssemblyService} from "./assembly.service";
import {AssemblyValidator} from "./assembly.validator";

@Component({
    selector: 'app-assembly',
    templateUrl: './assembly.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [AssemblyService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssemblyComponent extends CrudCommonComponent<Assembly, AssemblyService> implements OnInit {

    assemblyUnitsFilter: Observable<SelectItem[]>;
    availableAssemblyUnits: Observable<SelectItem[]>;

    @ViewChild(DataTable)
    dataTable: DataTable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, AssemblyService, 'ASSEMBLY', 'Assembly');
        this.item = new Assembly();
        this.initDefaultSortOrder();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.assemblyUnitsFilter = this.translatedSelectItemService.buildSortedDropdown(AssemblyUnit, 'ASSEMBLY.UNITS.', '');
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.availableAssemblyUnits = this.assemblyUnitsFilter.pipe(map(units => units.slice(1)));
    }

    getNewItem(): Assembly {
        return new Assembly();
    }

    showDialogToCopy(): void {
        if (this.selectedItem != undefined) {
            this.itemId = undefined;
            this.item = _.clone(this.selectedItem);
            this.item.id = undefined;
            this.setDisplayDialog(true);
        }
    }

    validateForm(): void {
        this.validationErrors = AssemblyValidator.validate(this.item);
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    getDialogHeaderText(): string {
        return `NAVIGATION.ITEMS.WINDOW-SYSTEM.ASSEMBLY.${this.copyMode ? 'COPY' : 'EDIT-ASSEMBLY'}`;
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
