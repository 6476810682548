import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Observable} from 'rxjs';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {UserWithActivityDataList} from './user-activity-data';
import {UserActivityMailReportSettings} from './user-activity-mail-report-settings';

@Injectable()
export class UserActivityService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getUsers(offset: number, pageSize: number, filters: {
        [filterProperty: string]: FilterMetadata
    }, sortColumn: string, sortOrder: number): Observable<UserWithActivityDataList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<UserWithActivityDataList>('usersactivity', {params: params});
    }

    getReportFile(offset: number, pageSize: number, filters: {
        [filterProperty: string]: FilterMetadata
    }, sortColumn: string, sortOrder: number): Observable<File> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get('usersactivity/downloadReport', {params: params, responseType: 'text', observe: 'response'})
            .pipe(this.dataServiceHelper.mapToFile());
    }

    getMailReportConfiguration() {
        return this.http.get<UserActivityMailReportSettings>('usersactivity/mailReportConfiguration');
    }

    updateMailReportConfiguration(settings: UserActivityMailReportSettings) {
        return this.http.post<void>('usersactivity/mailReportConfiguration', settings);
    }
}
