import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {TreeTableNode} from "primeng/api/treetablenode";
import {forkJoin, Observable, of} from "rxjs";
import {map, mergeMap} from "rxjs/operators";
import {Permissions} from "../../../../auth/permission.service";
import {UserUiConfigService} from "../../../../auth/uiconfig/userUiConfig.service";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {MultilanguageFieldSelectItem} from "../../../../common/service/select-item-multilanguage-field-translate.pipe";
import {TranslatedSelectItem} from "../../../../common/service/translated.select.item";
import {MultilanguageField} from "../../../../supportedLanguages";
import {SupplierService} from "../../../supplier/supplier.service";
import {ConfigSystemService} from "../../../window-system/config-system/config-system.service";
import {GateSystemService} from "../../../window-system/gate-system/gate-system.service";
import {WindowSystemDefinitionService} from "../../../window-system/window-system-definition/window-system-definition.service";
import {PositionType} from "../../AbstractPosition";
import {GateData} from "../../gate-editor/gate-data";
import {Offer, PriceWithTax} from "../../offer";
import {PricingService} from "../../window-editor/sidebar/pricing/pricing.service";
import {Position, PositionSortGroup} from "../position/position-list/position";
import {PositionService} from "../position/position.service";
import {DetailedPricing} from "./detailed-pricing";
import {DetailedPricingSource} from "./DetailedPricingSource";
import {CostType, PricingModification} from "./pricing-modification";
import {PricingComponentOverrides} from "./simulation-parameters-dialog/detailed-pricing-simulation-definitions";

export class SummaryValue {
    constructor(public costType: CostType, public value: PriceWithTax) {
    }
}

export interface TreeItem {
    name: MultilanguageField | string;
    modifications: PricingModification[];
    system?: MultilanguageField;
    printOrder?: number;
    quantity?: number;
    summaryValues?: SummaryValue[];
    rowLevel: RowLevel;
    has0PricesOnly?: boolean;
    hasKeyLabel?: boolean;
}

export enum RowLevel {
    PARENT = 'PARENT',
    TOGGLE_COMPONENT = 'TOGGLE_COMPONENT',
    COMPONENT = 'COMPONENT',
    MANUAL_MODIF = 'MANUAL_MODIF',
    TOTAL = 'TOTAL'
}

interface PricingComponentsOverrideDialogData {
    dialogVisible: boolean;
    suppliers: SelectItem[];
    windowSystems: MultilanguageFieldSelectItem[];
    gateSystems: MultilanguageFieldSelectItem[];
    configSystems: MultilanguageFieldSelectItem[];
    hasBulkAddons: boolean;
    overrides: PricingComponentOverrides;
}

export type DetailedPricingColumn = 'PRINT_ORDER' | 'SYSTEM' | 'POSITION_NAME' | 'VENSKA' | 'SUBSYSTEM' | 'CLIENT' | 'SELLER' | 'SUMMARY';

@Component({
    selector: 'app-detailed-pricing',
    templateUrl: './detailed-pricing.component.html',
    styleUrls: ['../../../shared-styles.css', './detailed-pricing.component.css'],
    providers: [PricingService, PositionService, SupplierService, WindowSystemDefinitionService, GateSystemService, ConfigSystemService]
})
export class DetailedPricingComponent implements OnInit {

    private static readonly BLOCK_SOURCE_ID = 'DetailedPricing';
    private static readonly VIEW_NAME = 'DetailedPricingComponent';
    private static readonly SAVED_CONFIG = {
        SHOWN_COLUMNS: 'shownColumns',
        SHOW_GROSS: 'showGross',
        SHOW_0_PRICES: 'show0Prices'
    };

    @Input() offer: Offer;

    @Output() closeDialog: EventEmitter<void> = new EventEmitter<void>();

    detailedPricingTreeData: TreeTableNode<TreeItem>[] = [];
    selected: TreeTableNode<TreeItem> | TreeTableNode<TreeItem>[];
    loaded = false;
    visible = true;
    grossValues = false;
    zeroPricesVisible = false;
    visibleAll = false;
    helpOpened = false;
    costTypes: CostType[] = [CostType.VENSKA, CostType.SUBSYSTEM, CostType.CLIENT, CostType.SELLER];
    currency = '';
    offerTotal: SummaryValue[] = [];

    selectedColumns: DetailedPricingColumn[] = [];
    allColumns: DetailedPricingColumn[] = [];
    columnOptions: TranslatedSelectItem[] = [];

    pricingComponentsOverride: PricingComponentsOverrideDialogData;

    constructor(private readonly pricingService: PricingService,
                private readonly changeDetector: ChangeDetectorRef,
                private readonly blockUiController: BlockUiController,
                private readonly userUIConfigService: UserUiConfigService,
                private readonly positionService: PositionService,
                private readonly supplierService: SupplierService,
                private readonly windowSystemService: WindowSystemDefinitionService,
                private readonly gateSystemService: GateSystemService,
                private readonly configSystemService: ConfigSystemService,
                private readonly permissions: Permissions) {
    }

    get canViewVenskaPrices(): boolean {
        return this.permissions.isKoordynator() || this.permissions.isOpiekun();
    }

    get canViewSubsystemPrices(): boolean {
        return this.permissions.isKoordynator() || this.permissions.isOpiekun();
    }

    get canViewClientPrices(): boolean {
        return !this.permissions.isSprzedawca();
    }

    ngOnInit(): void {
        const savedColumns = this.userUIConfigService.getConfigForTheView(DetailedPricingComponent.VIEW_NAME,
            DetailedPricingComponent.SAVED_CONFIG.SHOWN_COLUMNS);
        this.grossValues = this.userUIConfigService.getConfigForTheView(DetailedPricingComponent.VIEW_NAME,
            DetailedPricingComponent.SAVED_CONFIG.SHOW_GROSS);
        this.zeroPricesVisible = this.userUIConfigService.getConfigForTheView(DetailedPricingComponent.VIEW_NAME,
            DetailedPricingComponent.SAVED_CONFIG.SHOW_0_PRICES);
        if (savedColumns) {
            this.selectedColumns = savedColumns;
        } else {
            this.selectedColumns = ['PRINT_ORDER', 'SYSTEM'];
            if (this.canViewVenskaPrices) {
                this.selectedColumns.push('VENSKA');
            } else if (this.canViewSubsystemPrices) {
                this.selectedColumns.push('SUBSYSTEM');
            } else if (this.canViewClientPrices) {
                this.selectedColumns.push('CLIENT');
            } else {
                this.selectedColumns.push('SELLER');
            }
            this.selectedColumns.push('SUMMARY');
        }
        this.allColumns = ['PRINT_ORDER', 'SYSTEM'];
        if (this.canViewVenskaPrices) {
            this.allColumns.push('VENSKA');
        }
        if (this.canViewSubsystemPrices) {
            this.allColumns.push('SUBSYSTEM');
        }
        if (this.canViewClientPrices) {
            this.allColumns.push('CLIENT');
        }
        this.allColumns.push('SELLER', 'SUMMARY');
        this.allColumns.forEach(column => {
            this.columnOptions.push({label: null, labelKey: 'OFFER.DETAILED_PRICING.' + column, value: column});
        });
        this.initDetailedPricing(this.pricingService.getDetailedPricing(this.offer.id));
        this.offerTotal = [];
        if (this.canViewVenskaPrices) {
            this.offerTotal.push(new SummaryValue(CostType.VENSKA, this.offer.venskaBuyPrice));
        }
        if (this.canViewSubsystemPrices) {
            this.offerTotal.push(new SummaryValue(CostType.SUBSYSTEM, this.offer.buyPrice));
        }
        if (this.canViewClientPrices) {
            this.offerTotal.push(new SummaryValue(CostType.CLIENT, this.offer.sellPrice));
        }
        this.offerTotal.push(new SummaryValue(CostType.SELLER, this.offer.retailSellPrice));
    }

    private initDetailedPricing(source: Observable<DetailedPricing[]>): void {
        this.blockUiController.block(DetailedPricingComponent.BLOCK_SOURCE_ID);
        source.subscribe((res: DetailedPricing[]) => {
            let tree: TreeTableNode<TreeItem>[] = [];
            for (let offerPosition of res) {
                let components: TreeTableNode[] = [];
                let posModificationsByCostType = this.mapByCostType(offerPosition.positionModifications);
                for (let position of offerPosition.positions) {
                    const positionModifications = this.mapByCostType(position.modifications);
                    let twoRowsFound = false;
                    for (let items of positionModifications.values()) {
                        if (items.length > 1) {
                            twoRowsFound = true;
                        } else {
                            if (twoRowsFound) {
                                items.unshift(new PricingModification(items[0].costType));
                            }
                        }
                    }
                    const children = this.mapToTreeNode(positionModifications, position.chargeType, posModificationsByCostType);
                    let has0PricesOnly = false;
                    has0PricesOnly = children.every(child => this.has0PricesOnly(child.data.modifications));
                    components.push({
                        children: children,
                        data: {
                            name: position.positionName,
                            modifications: this.getPricingModificationArray(),
                            rowLevel: RowLevel.TOGGLE_COMPONENT,
                            has0PricesOnly: has0PricesOnly
                        },
                        expanded: this.visibleAll
                    });
                }
                const numberOfRows = this.getNumberOfRows(posModificationsByCostType);
                for (let i = 0; i < numberOfRows; i++) {
                    let modifications: PricingModification[] = this.getModificationsPerType(posModificationsByCostType, i, true,
                        posModificationsByCostType);
                    components.push({
                        data: {
                            name: 'OFFER.DETAILED_PRICING.MANUAL_MODIFICATION',
                            modifications: modifications,
                            hasKeyLabel: true,
                            rowLevel: RowLevel.MANUAL_MODIF
                        },
                        expanded: this.visibleAll
                    });
                }
                const summaryValues: SummaryValue[] = [];
                if (this.canViewVenskaPrices) {
                    summaryValues.push(new SummaryValue(CostType.VENSKA, offerPosition.venskaBuyValue));
                }
                if (this.canViewSubsystemPrices) {
                    summaryValues.push(new SummaryValue(CostType.SUBSYSTEM, offerPosition.buyValue));
                }
                if (this.canViewClientPrices) {
                    summaryValues.push(new SummaryValue(CostType.CLIENT, offerPosition.sellValue));
                }
                summaryValues.push(new SummaryValue(CostType.SELLER, offerPosition.retailSellValue));
                let positionTreeData: TreeItem = {
                    name: offerPosition.name,
                    modifications: this.getPricingModificationArray(offerPosition.venskaPrice, offerPosition.subsystemPrice,
                        offerPosition.clientPrice, offerPosition.sellerPrice),
                    system: offerPosition.system,
                    printOrder: offerPosition.printOrder,
                    quantity: offerPosition.quantity,
                    summaryValues: summaryValues,
                    rowLevel: RowLevel.PARENT
                };
                tree.push({
                    children: components,
                    data: positionTreeData,
                    expanded: this.visibleAll
                });
            }
            tree.push({
                data: {
                    name: 'OFFER.DETAILED_PRICING.OFFER.TOTAL',
                    summaryValues: this.offerTotal,
                    rowLevel: RowLevel.TOTAL,
                    modifications: this.getPricingModificationArray(),
                    hasKeyLabel: true
                },
                expanded: this.visibleAll
            });
            this.detailedPricingTreeData = tree;
            this.blockUiController.unblock(DetailedPricingComponent.BLOCK_SOURCE_ID);
            this.loaded = true;
            this.changeDetector.markForCheck();
        });
    }

    public toggleNode(node: TreeTableNode<TreeItem>): void {
        node.children.forEach(child => {
            child.expanded = node.expanded;
        });
    }

    public toggleAll(): void {
        this.detailedPricingTreeData.forEach(position => {
            position.expanded = !this.visibleAll;
            if (position.children !== undefined) {
                position.children.forEach(child => {
                    child.expanded = !this.visibleAll;
                });
            }
        });
        this.detailedPricingTreeData = [...this.detailedPricingTreeData];
        this.visibleAll = !this.visibleAll;
    }

    private getCostType(source: DetailedPricingSource): CostType {
        switch (source) {
            case DetailedPricingSource.SYSTEM_VENSKA_RABATE:
            case DetailedPricingSource.SUPPLIER_VENSKA_RABATE:
            case DetailedPricingSource.NONE_VENSKA:
                return CostType.VENSKA;
            case DetailedPricingSource.GLOBAL_OFFER_VENSKA_RABATE:
            case DetailedPricingSource.MANUAL_VENSKA_RABATE:
            case DetailedPricingSource.SUBSYSTEM_GROUP_MARGIN:
            case DetailedPricingSource.SUBSYSTEM_MARGIN:
            case DetailedPricingSource.SUPPLIER_SUBSYSTEM_GROUP_RABATE:
            case DetailedPricingSource.SUPPLIER_SUBSYSTEM_RABATE:
            case DetailedPricingSource.SYSTEM_SUBSYSTEM_RABATE:
            case DetailedPricingSource.SYSTEM_SUBSYSTEM_GROUP_RABATE:
            case DetailedPricingSource.NONE_SUBSYSTEM:
                return CostType.SUBSYSTEM;
            case DetailedPricingSource.GLOBAL_OFFER_RABATE:
            case DetailedPricingSource.MANUAL_RABATE:
            case DetailedPricingSource.CLIENT_MARGIN:
            case DetailedPricingSource.CLIENT_GROUP_MARGIN:
            case DetailedPricingSource.MANUAL_CLIENT_MARGIN:
            case DetailedPricingSource.NONE_CLIENT:
                return CostType.CLIENT;
            case DetailedPricingSource.MANUAL_RETAIL_MARGIN:
            case DetailedPricingSource.MANUAL_RETAIL_RABATE:
            case DetailedPricingSource.GLOBAL_OFFER_RETAIL_RABATE:
            case DetailedPricingSource.RETAIL_CLIENT_GROUP_MARGIN:
            case DetailedPricingSource.RETAIL_CLIENT_MARGIN:
            case DetailedPricingSource.NONE_RETAIL:
                return CostType.SELLER;
        }
    }

    private mapByCostType(modifications: PricingModification[]): Map<CostType, PricingModification[]> {
        let mappedModifications: Map<CostType, PricingModification[]> = new Map<CostType, PricingModification[]>();
        if (modifications.length > 0) {
            for (let modification of modifications) {
                const costType = this.getCostType(modification.source);
                if (mappedModifications.has(costType)) {
                    mappedModifications.set(costType, [...mappedModifications.get(costType), modification]);
                } else {
                    mappedModifications.set(costType, [modification]);
                }
            }
            if (mappedModifications.size !== Object.keys(CostType).length) {
                const sortOrder = Object.keys(CostType);
                const missing = sortOrder.filter(type =>
                    Array.from(mappedModifications.keys()).indexOf(CostType[type]) === -1) as CostType[];
                for (let item of missing) {
                    mappedModifications.set(item, [new PricingModification(item)]);
                }
                mappedModifications = new Map([...mappedModifications.entries()].sort((a, b) =>
                    sortOrder.indexOf(a[0]) - sortOrder.indexOf(b[0])));
            }
        }
        return mappedModifications;
    }

    private mapToTreeNode(modificationsByCostType: Map<CostType, PricingModification[]>, itemName: string,
                  posModifByCost: Map<CostType, PricingModification[]>): TreeTableNode<TreeItem>[] {
        let children: TreeTableNode<TreeItem>[] = [];
        const numberOfRows = this.getNumberOfRows(modificationsByCostType);
        for (let i = 0; i < numberOfRows; i++) {
            let modifications: PricingModification[] = this.getModificationsPerType(modificationsByCostType, i, false, posModifByCost);
            let treeData: TreeItem = {
                name: itemName,
                modifications: modifications,
                rowLevel: RowLevel.COMPONENT,
                has0PricesOnly: this.has0PricesOnly(modifications)
            };
            children.push({
                data: treeData,
                expanded: this.visibleAll
            });
        }
        return children;
    }

    private getNumberOfRows(mapList: Map<CostType, PricingModification[]>): number {
        return Math.max(...Array.from(mapList.values()).map(value => value.length));
    }

    private getPricingModificationArray(venskaPrice?: PriceWithTax, subsystemPrice?: PriceWithTax, clientPrice?: PriceWithTax,
                                sellerPrice?: PriceWithTax): PricingModification[] {
        const list: PricingModification[] = [];
        list.push(new PricingModification(CostType.VENSKA, venskaPrice, true));
        list.push(new PricingModification(CostType.SUBSYSTEM, subsystemPrice, true));
        list.push(new PricingModification(CostType.CLIENT, clientPrice, true));
        list.push(new PricingModification(CostType.SELLER, sellerPrice, true));
        return list;
    }

    private getModificationsPerType(modificationsMap: Map<CostType, PricingModification[]>, index: number, manualModifications: boolean,
                            posModifByCost: Map<CostType, PricingModification[]>): PricingModification[] {
        const modifications: PricingModification[] = [];
        for (let key of modificationsMap.keys()) {
            const items = modificationsMap.get(key);
            if (items[index] != undefined) {
                items[index].costType = key;
                items[index].bolded = manualModifications ? index === items.length - 1 :
                    (posModifByCost.get(key)
                        ? (posModifByCost.get(key).every(pos => pos.priceAfter === undefined) && index === items.length - 1)
                        : index === items.length - 1);
                modifications.push(items[index]);
            } else {
                modifications.push(new PricingModification(key));
            }
        }
        return modifications;
    }

    public getPriceLabel(type: CostType): string {
        const baseLabel = 'OFFER.DETAILED_PRICING.';
        switch (type) {
            case CostType.VENSKA:
                return baseLabel + 'VENSKA_BUY_PRICE';
            case CostType.SUBSYSTEM:
                return baseLabel + 'SUBSYSTEM_BUY_PRICE';
            case CostType.CLIENT:
                return baseLabel + 'SELL_PRICE';
            case CostType.SELLER:
                return baseLabel + 'RETAIL_SELL_PRICE';
            default:
                break;
        }
        return baseLabel;
    }

    public columnVisible(column: DetailedPricingColumn | number): boolean {
        return this.selectedColumns.some(col => col === column);
    }

    public showColumns(columns: DetailedPricingColumn[]): void {
        this.selectedColumns = columns;
        this.userUIConfigService.saveConfigForTheView(DetailedPricingComponent.VIEW_NAME,
            DetailedPricingComponent.SAVED_CONFIG.SHOWN_COLUMNS, columns);
    }

    public show0Prices(show0Prices: boolean): void {
        this.zeroPricesVisible = show0Prices;
        this.userUIConfigService.saveConfigForTheView(DetailedPricingComponent.VIEW_NAME,
            DetailedPricingComponent.SAVED_CONFIG.SHOW_0_PRICES, show0Prices);
    }

    public showGrossPrices(showGross: boolean): void {
        this.grossValues = showGross;
        this.userUIConfigService.saveConfigForTheView(DetailedPricingComponent.VIEW_NAME,
            DetailedPricingComponent.SAVED_CONFIG.SHOW_GROSS, showGross);
    }

    private has0PricesOnly(modifications: PricingModification[]): boolean {
        return modifications.filter((modif: PricingModification) => modif.priceAfter && modif.priceBefore)
            .every((modif: PricingModification) => modif.priceBefore.netValue === 0 && modif.priceAfter.netValue === 0);
    }

    openSimulationParametersDialog(): void {
        let dataSource: Observable<Omit<PricingComponentsOverrideDialogData, 'dialogVisible'>>;
        if (this.pricingComponentsOverride == undefined) {
            const filters = {offerId: {value: this.offer.id}, sortGroup: {value: PositionSortGroup.SYSTEM}};
            dataSource = this.positionService.getItems(undefined, undefined, filters, undefined, undefined).pipe(
                mergeMap(positionListing => forkJoin({
                    windowSystems: this.windowSystemService.getItemNames().pipe(map(windowSystemNames => {
                        const windowSystemIdsInOffer = positionListing.data
                            .filter(pos => (pos as Position).windowSystemId != undefined)
                            .map(pos => (pos as Position).windowSystemId);
                        return windowSystemNames.filter(windowSystemName => windowSystemIdsInOffer.indexOf(windowSystemName.id) >= 0)
                            .map(windowSystemName => ({labelKey: windowSystemName.name, value: windowSystemName.id}));
                    })),
                    gateSystems: this.gateSystemService.getItemNames().pipe(map(gateSystemNames => {
                        const gateSystemIdsInOffer = positionListing.data
                            .filter(pos => pos.type === PositionType.GATE_SYSTEM)
                            .map(pos => (JSON.parse(pos.data) as GateData).gateSystemId);
                        return gateSystemNames.filter(gateSystemName => gateSystemIdsInOffer.indexOf(gateSystemName.id) >= 0)
                            .map(gateSystemName => ({labelKey: gateSystemName.name, value: gateSystemName.id}));
                    })),
                    configSystems: this.configSystemService.getItemNames().pipe(map(configNames => {
                        const configAddonsIdsInOffer = positionListing.data
                            .filter(pos => pos.type === PositionType.CONFIG_SYSTEM)
                            .map(pos => (pos as Position).configSystemId);
                        return configNames.filter(configName => configAddonsIdsInOffer.indexOf(configName.id) >= 0)
                            .map(configName => ({labelKey: configName.name, value: configName.id}));
                    })),
                    suppliers: this.canViewVenskaPrices ? this.supplierService.getSupplierNames().pipe(map(supplierNames => {
                        const supplierIdsInOffer = positionListing.data.map(pos => (pos as Position).supplierId);
                        return supplierNames.filter(supplierName => supplierIdsInOffer.indexOf(supplierName.id) >= 0)
                            .map(supplierName => ({ label: supplierName.companyName, value: supplierName.id }));
                    })) : of<SelectItem[]>([]),
                    hasBulkAddons: of(positionListing.data.some(pos => pos.type === PositionType.BULK_ADDON)),
                    overrides: of(new PricingComponentOverrides())
                }))
            );
        } else {
            dataSource = of(this.pricingComponentsOverride);
        }

        dataSource.subscribe(data => {
            this.pricingComponentsOverride = Object.assign({}, data, { dialogVisible: true });
            this.changeDetector.markForCheck();
        });
    }

    simulateWithDifferentDiscountsAndMargins(): void {
        this.initDetailedPricing(this.pricingService.getDetailedPricingSimulation(this.offer.id, this.pricingComponentsOverride.overrides));
    }
}
