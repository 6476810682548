import {WindowSystemMaterialLinks} from '../single-system-checkbox-crud/window-system-material-links';
import {Seal} from "./Seal";

export class SealWithMaterials extends Seal implements WindowSystemMaterialLinks {
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;

    constructor() {
        super();
    }
}
