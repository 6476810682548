import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {forkJoin, Observable, of} from "rxjs";
import * as _ from 'underscore';
import {WindowSystemInterface} from '../../../../../../window-designer/catalog-data/window-system-interface';
import {DrawingData} from "../../../../../../window-designer/drawing-data/drawing-data";
import {ProfilesCompositionDistances} from "../../../../../../window-designer/profiles-composition-distances";
import {CommonErrorHandler} from "../../../../../common/CommonErrorHandler";
import {Grill} from '../../../../window-system/grill/grill';
import {PositionMessage} from "../../../offers/message";
import {ConfigAddonValidator} from '../../drawing-tool/ConfigAddonValidator';
import {ConfigurableAddonUtils} from "../../drawing-tool/ConfigurableAddonUtils";
import {WindowEditorProductionOrderData} from '../../window-editor-offer-interfaces';
import {ConfigurableAddonPositionModel} from '../pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {Pricing} from "../pricing/Pricing";
import {PricingService} from "../pricing/pricing.service";
import {PricingResult} from '../pricing/PricingResult';
import {PricingWithModel} from "../pricing/PricingWithModel";
import {Product} from "../pricing/Product";
import {ConfigSystem} from "../../../../window-system/config-system/config-system";

@Component({
    selector: 'app-sidebar-validation',
    templateUrl: './validation.component.html',
    styleUrls: ['./validation.component.css', '../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PricingService]
})
export class ValidationComponent {

    @Input() pricingProducts: Product[];
    @Input() validationMessages: PositionMessage[];
    @Input() readOnlyMode: boolean;
    @Input() productionOrder: WindowEditorProductionOrderData;

    @Output() refreshValidationPricingStatuses = new EventEmitter<any>();
    @Output() setErrorToValidationStatus = new EventEmitter();

    pricing: Pricing;
    validationErrors: string[] = [];

    configAddonPricingsAndModels: PricingWithModel[] = [];

    constructor(public translate: TranslateService,
                public cd: ChangeDetectorRef,
                public pricingService: PricingService,
                private errors: CommonErrorHandler) {
        translate.onLangChange.subscribe(() => this.cd.markForCheck());
    }

    validate(data: DrawingData, offerId: number, offerPositionId: number, configAddonModels: ConfigurableAddonPositionModel[],
             windowSystem: WindowSystemInterface, profileCompositionDistances: ProfilesCompositionDistances,
             configAddonDefinitions: ConfigSystem[],
             grills: Grill[], validationDisabled: boolean): void {
        this.validationErrors = [].concat(
            profileCompositionDistances.validateForErrors(data),
            ConfigAddonValidator.validateConfigAddonsForErrors(data, configAddonModels, configAddonDefinitions, grills)
        );
        if (this.validationErrors.length === 0) {
            ConfigurableAddonUtils.resizeAll(data, configAddonModels, profileCompositionDistances, false);
            ConfigurableAddonUtils.changeGlazingBeads(data, configAddonModels);
            configAddonModels = configAddonModels.filter(model => model.configurableAddon);
            this.validationMessages = null;
            this.pricingProducts = null;
            this.configAddonPricingsAndModels = [];
            this.cd.markForCheck();
            let configAddons = configAddonModels.map(model => model.configurableAddon);
            let configValidationDisabled = configAddonModels.map(model => model.position.validationDisabled);
            let windowPricingObservable: Observable<Pricing>;
            let configAddonObservable: Observable<PricingResult[]>;
            if (this.productionOrder) {
                windowPricingObservable = this.pricingService.getSaveProdOrderPricing(offerPositionId);
                configAddonObservable = of([]);
            } else {
                windowPricingObservable = this.pricingService.evaluate(this.readOnlyMode, data, profileCompositionDistances, offerId,
                    offerPositionId, windowSystem, true, false,
                    _.object(configAddonModels.map(ca => ca.position.id ? ca.position.id : ca.position.assignedId),
                        configAddonModels.map(ca => ca.configurableAddon.definitionId)), validationDisabled);
                configAddonObservable = configAddons.length
                    ? this.pricingService.evaluateAddons(offerId, configAddons, configValidationDisabled)
                    : of([]);
            }
            forkJoin({
                window: windowPricingObservable,
                configAddons: configAddonObservable
            }).subscribe({
                next: response => {
                    console.info('PricingService `evaluate` success:');
                    let windowValidation = response.window;
                    this.pricingProducts = windowValidation.products;
                    this.validationMessages = windowValidation.validationMessages;
                    this.configAddonPricingsAndModels = this.pricingService.joinPricingWithModels(response.configAddons, configAddonModels);
                    this.refreshValidationPricingStatuses.emit(windowValidation);
                    if (this.configAddonPricingsAndModels.some(e => e.pricingResult.containsError)) {
                        this.setErrorToValidationStatus.emit();
                    }
                    this.cd.markForCheck();
                },
                error: error => {
                    this.errors.handle(error);
                    this.setErrorToValidationStatus.emit();
                    this.cd.markForCheck();
                }
            });
        }

        this.cd.markForCheck();
    }
}
