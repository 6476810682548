import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from "primeng/datatable";
import {Observable} from "rxjs";
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {Permissions} from "../../../../auth/permission.service";
import {StorageService} from '../../../../auth/storage.service';
import {ButtonWithMenuElementSelectedEvent} from "../../../../common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder} from "../../../../common/button-with-menu/MenuElement";
import {MenuType} from "../../../../common/button-with-menu/MenuType";
import {DatatableInterface} from '../../../../common/DatatableHelper';
import {ExchangeService} from "../../../../common/exchange.service";
import {SavedFilterService} from '../../../../common/saved-filter/saved-filter.service';
import {CrudComponent} from "../../../../common/service/crud.component";
import {DataTableColumnBuilder, SavedShownColumns} from "../../../../common/service/data.table.column.builder";
import {ExportComponent} from "../../../../common/service/export.component";
import {TranslatedSelectItem} from "../../../../common/service/translated.select.item";
import {TranslatedSelectItemBuilder} from "../../../../common/service/translated.select.item.builder";
import {TristateCheckboxState} from "../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {AccessData} from "../../../AccessData";
import {SubsystemService} from '../../../subsystem/subsystem.service';
import {DeliveryListStatus} from '../../delivery-list/DeliveryListStatus';
import {HasSavedFilter} from '../../has-saved-filter';
import {CommentDialogMode} from "../../offers/position/position-list/comment-dialog/comment-dialog-mode";
import {PrintableItem} from '../../print-dialog/print-dialog.component';
import {PrintableSection} from '../../print-dialog/printable-section.enum';
import {StatusTransition} from "../../status-transition-dialog/StatusTransition";
import {Complaint} from "../complaint";
import {ComplaintStatus} from "../complaint-status";
import {ComplaintStatusTransitionService} from "../complaint-status-transition.service";
import {ComplaintService} from "../complaint.service";

@Component({
    selector: 'app-complaint-list',
    templateUrl: './complaint-list.component.html',
    styleUrls: ['../../../shared-styles.css', './complaint-list.component.css'],
    providers: [ExportComponent, SubsystemService, ExchangeService, ComplaintService, ComplaintStatusTransitionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComplaintListComponent extends CrudComponent implements OnInit, HasSavedFilter {

    static readonly STATUS_TRANSITION_BLOCK_UI_ID = 'STATUS_TRANSITION_BLOCK_UI';
    readonly TABLE_ID = 'complaintsTable';

    private ignoreStoredFilter: boolean;
    private showPageWarning = false;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();
    @Output() onVisibleColumnsChange = new EventEmitter<SavedShownColumns>();

    menuType = MenuType;

    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    lastLazyLoadEvent: LazyLoadEvent;
    complaints: Complaint[] = [];
    selectedItem: Complaint;
    selectedItems: Complaint[] = [];
    printableItems: PrintableItem[] = [];
    allSelectedState = TristateCheckboxState.UNCHECKED;
    currentActionComplaint: Complaint;
    displayConfirmDeleteDialog = false;
    displayCommentsDialog = false;
    commentDialogMode: CommentDialogMode = CommentDialogMode.COMPLAINT;
    possibleTransitions: StatusTransition[] = [];
    transitionsDialogVisible = false;
    availableStatuses: TranslatedSelectItem[] = [];
    selectedStatuses = [];
    availableSubsystems: SelectItem[] = [];
    selectedSubsystems: string[] = [];
    filterByOwnComplaintsEnabled = true;
    manualDialogVisible = false;
    showPrintButton = false;
    printDialogVisible = false;
    PrintableSection = PrintableSection;

    @ViewChild('dt', {static: true})
    dataTable: DataTable;

    constructor(injector: Injector, changeDetector: ChangeDetectorRef,
                private complaintService: ComplaintService,
                private router: Router,
                private route: ActivatedRoute,
                private statusTransitionService: ComplaintStatusTransitionService,
                private permissions: Permissions,
                private subsystemService: SubsystemService,
                private storage: StorageService) {
        super(injector, changeDetector, 'ComplaintListComponent', false);
    }

    ngOnInit() {
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.showPageWarning = this.route.snapshot.paramMap.has('showPageWarning');
        super.ngOnInit();
        this.initStatusFilter();
        this.initSubsystemFilter();
        this.createTable();
        this.resetDefaultFilterSelection();
        this.initLastSelectedItem();
        this.initFilterByCurrentUser();
    }

    private createTable() {
        let shownColumns = this.getShownColumns();

        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('complaintNumber', 'OFFER.COMPLAINT.LIST.COMPLAINT_NUMBER', true).makeFilterable().makeSortable()
            .add('status', 'OFFER.COMPLAINT.LIST.STATUS', true);

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('offerSubsystemName', 'OFFER.COMPLAINT.LIST.SUBSYSTEM_NAME', true).makeFilterable().makeSortable();
            builder.add('clientManagerName', 'OFFER.COMPLAINT.LIST.CLIENT_MANAGER_NAME', true).makeSortable().makeFilterable();
            builder.add('subClientManagerName', 'OFFER.COMPLAINT.LIST.SUB_CLIENT_MANAGER_NAME', false).makeSortable().makeFilterable();
        }

        builder.add('createdDate', 'OFFER.COMPLAINT.LIST.CREATED_DATE', true)
            .setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING).makeSortable().makeFilterable()
            .add('sendToVerifyDate', 'OFFER.COMPLAINT.LIST.SEND_TO_VERIFY_DATE', true).makeFilterable().makeSortable()
            .add('expectedShippingDate', 'OFFER.COMPLAINT.LIST.EXPECTED_SHIPPING_DATE', true).makeFilterable().makeSortable();

        super.init(builder.build());
        this.saveShownColumns();
    }

    saveShownColumns() {
        const shownColumns = super.saveShownColumns();
        this.onVisibleColumnsChange.emit(shownColumns);
        return shownColumns;
    }

    private initStatusFilter(): void {
        let selectItemBuilder = TranslatedSelectItemBuilder.create();
        Object.keys(ComplaintStatus)
            .forEach(status => selectItemBuilder.add('OFFER.COMPLAINT.FORM.STATUS.' + status, status));
        this.availableStatuses = selectItemBuilder.build();
    }

    private initSubsystemFilter(): void {
        this.subsystemService.getSelectionItems().subscribe(subsystemListing => {
            this.availableSubsystems = subsystemListing;
        });
    }

    private initFilterByCurrentUser(): void {
        if (this.route.snapshot.paramMap.has('forCurrentUser')) {
            this.filterByOwnComplaintsEnabled = this.route.snapshot.paramMap.get('forCurrentUser') === 'true';
        }
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalFilters = true) {
        super.loadItemsLazy(event);
        this.filterByOwnComplaints(event);
        this.lastLazyLoadEvent = event;
        this.storeLazyLoadEvent(event, this.storage);
        this.onDataLazyLoad.emit(event);
        if (updateOriginalFilters) {
            this.onUpdateOriginalFilters.emit(event);
        }

        this.complaintService.getComplaints(event.first, this.chosenRowsPerPage, event.filters, event.sortField, event.sortOrder)
            .subscribe(response => {
                this.complaints = response.data;
                this.totalRecords = response.totalRecords;
                this.totalRecordsChange.emit(this.totalRecords);
                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                this.restoreSelection(event);
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            });
    }

    private initLastSelectedItem(): void {
        if (this.route.snapshot.paramMap.has('lastSelection')) {
            this.selectedItem = new Complaint();
            this.selectedItem.id = +this.route.snapshot.paramMap.get('lastSelection');
        }
    }

    private restoreSelection(event: LazyLoadEvent): void {
        let selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.complaints, event);

        if (this.showPageWarning && this.selectedItem != null && selectedItem != null && this.selectedItem.id !== selectedItem.id) {
            this.growlMessageController.info('OFFER.COMPLAINT.LIST.SELECTION_ON_OTHER_PAGE');
        }

        this.showPageWarning = false;
        this.selectedItem = selectedItem;
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.updateVisibleColumns(visibleColumns);
        this.getDatatable().filters = {};
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLazyLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        this.lastLazyLoadEvent.filters = filter.filters;
        this.lastLazyLoadEvent.sortOrder = filter.sortOrder;
        this.lastLazyLoadEvent.sortField = filter.sortField;
        this.applyExtraHeaderFilters(filter);
        this.applyMultiSelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const currentUserFilter = filter.filters['forCurrentUser'];
        if (currentUserFilter != undefined) {
            this.filterByOwnComplaintsEnabled = '' + currentUserFilter.value === 'true';
        }
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedStatuses, filter.filters['status']);
        this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        SavedFilterService.applyMultiSelectFilters(this.selectedSubsystems, filter.filters['subsystemIds']);
        this.getDatatable().setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
    }

    resetDefaultFilterSelection() {
        let forcedStatuses = this.route.snapshot.paramMap.get('statusFilter');
        let selectedStatuses: ComplaintStatus[];
        if (forcedStatuses) {
            selectedStatuses = forcedStatuses.split(',').map(status => ComplaintStatus[status]);
        }
        if (!this.ignoreStoredFilter) {
            this.lastLazyLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
            if (this.lastLazyLoadEvent != undefined) {
                this.applySavedFilter(this.lastLazyLoadEvent);
            }
        }
        this.restoreStatusesSelection(selectedStatuses);
    }

    private restoreStatusesSelection(selectedStatuses: ComplaintStatus[]): void {
        if (selectedStatuses) {
            this.selectedStatuses = selectedStatuses;
            if (!this.columnByField['status']) {
                this.onDisplayedColumnsChange([...this.selectedColumns, 'status']);
            }
        } else {
            if (this.selectedStatuses.length === 0) {
                this.selectedStatuses = [ComplaintStatus.NEW, ComplaintStatus.TO_VERIFY, ComplaintStatus.TO_REVISE,
                    ComplaintStatus.ACCEPTED, ComplaintStatus.EXAMINATION_IN_PROGRESS, ComplaintStatus.REJECTED];
            }
        }
        if (this.selectedStatuses.length > 0) {
            const statusColumn = this.getColumns().find(column => column.field === 'status');
            if (statusColumn != undefined) {
                statusColumn.defaultFilterValue = {
                    label: '',
                    value: this.selectedStatuses.join(';')
                };
            }
            this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        }
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.getDatatable().filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    handleStatusFilterChange(statuses: DeliveryListStatus[]): void {
        this.selectedStatuses = statuses;
        this.getDatatable().filter(statuses.join(';'), 'status', 'in');
    }

    filterByOwnComplaints(event: LazyLoadEvent): void {
        if (this.filterByOwnComplaintsEnabled) {
            event.filters['forCurrentUser'] = {value: true, matchMode: undefined};
        } else {
            event.filters['forCurrentUser'] = undefined;
        }
    }

    getExportData(): Observable<object[]> {
        return this.complaintService.getComplaints(0, null, this.lastLazyLoadEvent.filters, this.lastLazyLoadEvent.sortField,
            this.lastLazyLoadEvent.sortOrder).pipe(
            map(list => list.data),
            map(list =>
                list.map(item => {
                    return {
                        id: item.id,
                        complaintNumber: item.complaintNumber,
                        status: item.status,
                        deliveryDate: item.deliveryDate,
                        deliveryDestination: item.deliveryDestination,
                        expectedShippingDate: item.expectedShippingDate,
                        sendToVerifyDate: item.sendToVerifyDate,
                        description: item.description,
                        createdDate: item.createdDate,
                        offerId: item.offerId
                    };
                })));
    }

    showDialogToAdd(): void {
        throw new Error('Method not implemented.');
    }

    submit(): void {
        throw new Error('Method not implemented.');
    }

    onRowSelect(event: any): void {
        throw new Error('Method not implemented.');
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    rowDoubleClick(event): void {
        this.redirectToDetails(event.data.id);
    }

    showDetails(id: number): void {
        this.redirectToDetails(id);
    }

    private redirectToDetails(id: number): void {
        this.router.navigate([AccessData.path.complaint(), id, AccessData.complaintPositionURLSuffix]);
    }

    buildRowActions(complaint: Complaint): MenuElement[] {
        let rowActions: MenuElement[] = [];
        let complaintSuffix: string = this.getComplaintSuffix(complaint);

        if (this.isDeleteEnabled(complaint)) {
            this.addActionButton('DELETE', rowActions, 'OFFER.ACTIONS.TOOLTIPS.DELETE', complaintSuffix);
        }

        this.addActionButton('OPEN_COMMENTS_DIALOG', rowActions, 'OFFER.ACTIONS.TOOLTIPS.COMMENTS', complaintSuffix);
        this.addChangeStatusRowAction(complaint, rowActions, complaintSuffix);

        return rowActions;
    }

    private isDeleteEnabled(complaint: Complaint): boolean {
        return (this.permissions.isPermitted({roles: ['ROLE_OPERATOR']}) &&
            (ComplaintStatus.NEW === complaint.status || ComplaintStatus.TO_REVISE === complaint.status)) ||
            (this.permissions.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) &&
                (ComplaintStatus.TO_VERIFY === complaint.status || ComplaintStatus.EXAMINATION_IN_PROGRESS === complaint.status ||
                    ComplaintStatus.ACCEPTED === complaint.status || ComplaintStatus.REJECTED === complaint.status));
    }

    private addChangeStatusRowAction(complaint: Complaint, rowActions: MenuElement[], complaintSuffix: string): void {
        let possibleTransitions = this.statusTransitionService.createPossibleTransitions(complaint,
            (): void => {
                this.showActionSuccessMessage('CHANGE_STATUS');
                this.loadItemsLazy(this.lastLazyLoadEvent);
            },
            (): void => {
                this.showActionErrorMessage('CHANGE_STATUS');
                this.loadItemsLazy(this.lastLazyLoadEvent);
            });

        if (possibleTransitions.length === 1) {
            this.addActionButton(possibleTransitions[0].action, rowActions, possibleTransitions[0].item.label, complaintSuffix);
        } else if (possibleTransitions.length > 1) {
            this.addActionButton('CHANGE_STATUS', rowActions, 'OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP', complaintSuffix);
        }
    }

    private addActionButton(action: string, rowActions: MenuElement[], translationKey: string, id: string): void {
        rowActions.push(new MenuElementBuilder().setTranslationKey(translationKey).setIdentifier(action + id).build());
    }

    private getComplaintSuffix(complaint: Complaint) {
        return '___' + complaint.id;
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent): void {
        let data = event.identifier.split('___');
        let action = data[0];
        let complaintId = +data[1];
        let found = this.complaints.find(complaint => complaint.id === complaintId);

        if (found) {
            this.actionOnClick(action, found);
        } else {
            console.error("Action called for non existing complaintId " + complaintId);
        }
    }

    actionOnClick(action: string, complaint: Complaint) {
        this.currentActionComplaint = complaint;

        switch (action) {
            case 'DELETE':
                this.displayConfirmDeleteDialog = true;
                this.changeDetector.markForCheck();
                break;
            case 'OPEN_COMMENTS_DIALOG':
                this.displayCommentsDialog = true;
                this.changeDetector.markForCheck();
                break;
            case 'CHANGE_STATUS':
                this.statusChangeAction(complaint);
                break;
            default:
                console.error("ComplaintListComponent: action '" + action + "' is unknown.");
                break;
        }
    }

    private statusChangeAction(complaint: Complaint): void {
        this.possibleTransitions = this.statusTransitionService.createPossibleTransitions(complaint,
            (): void => {
                this.blockUiController.unblock(ComplaintListComponent.STATUS_TRANSITION_BLOCK_UI_ID);
                this.showActionSuccessMessage('CHANGE_STATUS');
                this.loadItemsLazy(this.lastLazyLoadEvent);
                this.changeDetector.markForCheck();
            },
            (): void => {
                this.blockUiController.unblock(ComplaintListComponent.STATUS_TRANSITION_BLOCK_UI_ID);
                this.showActionErrorMessage('CHANGE_STATUS');
                this.loadItemsLazy(this.lastLazyLoadEvent);
                this.changeDetector.markForCheck();
            });

        if (this.possibleTransitions.length === 1) {
            this.blockUiController.block(ComplaintListComponent.STATUS_TRANSITION_BLOCK_UI_ID);
            this.possibleTransitions[0].item.command();
        } else if (this.possibleTransitions.length > 1) {
            this.transitionsDialogVisible = true;
        }
    }

    hideConfirmDeleteDialog(): void {
        this.displayConfirmDeleteDialog = false;
        this.changeDetector.markForCheck();
    }

    deleteComplaint(): void {
        this.complaintService.deleteComplaint(this.currentActionComplaint.id).subscribe({
            next: () => {
                this.hideConfirmDeleteDialog();
                this.showActionSuccessMessage('DELETE');
                this.loadItemsLazy(this.lastLazyLoadEvent);
            },
            error: error => {
                this.hideConfirmDeleteDialog();
                this.showErrorMessage(error);
                this.loadItemsLazy(this.lastLazyLoadEvent);
            }
        });
    }

    showActionSuccessMessage(actionName: string) {
        this.growlMessageController.info('OFFER.COMPLAINT.ACTIONS.ON_SUCCESS.' + actionName);
    }

    showActionErrorMessage(actionName: string) {
        this.growlMessageController.error('OFFER.COMPLAINT.ACTIONS.ON_ERROR.' + actionName);
    }

    private showErrorMessage(errorResponse: HttpErrorResponse) {
        let errors = _.values(errorResponse.error.invalidFields);
        for (let error of errors) {
            this.growlMessageController.error(error);
        }
    }

    resetCommentDialog(commentAdded: boolean): void {
        if (commentAdded) {
            this.loadItemsLazy(this.lastLazyLoadEvent);
        }

        this.displayCommentsDialog = false;
        this.changeDetector.markForCheck();
    }

    hideTransitionsDialog(): void {
        this.transitionsDialogVisible = false;
        this.possibleTransitions = [];

        this.changeDetector.markForCheck();
    }

    changeStatus(selectedTransition: StatusTransition): void {
        if (selectedTransition) {
            switch (selectedTransition.action) {
                case 'CHANGE_STATUS':
                    this.blockUiController.block(ComplaintListComponent.STATUS_TRANSITION_BLOCK_UI_ID);
                    selectedTransition.item.command();
                    this.hideTransitionsDialog();
                    break;
                default:
                    console.error("Unsupported status transition.", selectedTransition.action);
                    break;
            }
        }
    }

    isOwnOffersFilterVisible(): boolean {
        return !this.filterByOwnComplaintsEnabled && this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    enableFilterByOwnedComplaints(): void {
        this.filterByOwnComplaintsEnabled = true;
        this.loadItemsLazy(this.lastLazyLoadEvent);
    }

    isShowAllButtonVisible(): boolean {
        return this.filterByOwnComplaintsEnabled && this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    showAll(): void {
        this.filterByOwnComplaintsEnabled = false;
        this.loadItemsLazy(this.lastLazyLoadEvent);
    }

    selectAllChange() {
        this.selectedItems = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.complaints);
        }

        this.handlePrintButtonState();
        this.changeDetector.markForCheck();
    }

    private handlePrintButtonState() {
        this.showPrintButton = this.isAllowedToPrintComplaint() && this.selectedItems.length > 0;
    }

    private isAllowedToPrintComplaint(): boolean {
        return this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    isSelectedItem(item: Complaint): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: Complaint): void {
        let index = this.selectedItems.indexOf(item);

        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }

        this.handlePrintButtonState();
        this.refreshAllSelectedFlag();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedItems.length === this.complaints.length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    public openPrintDialog() {
        this.printableItems = this.selectedItems.map(item => new PrintableItem(item.id, item.complaintNumber));
        this.printDialogVisible = true;
    }
}
