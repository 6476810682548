import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {Template} from './template';

@Injectable()
export class TemplatesService implements CrudService<Template> {

    static SERVICE_PATH = 'templates';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Template>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(TemplatesService.SERVICE_PATH, {params: params}).pipe(
            map(response => Listing.fromJSON(Template, response)));
    }

    getItem(itemId: number): Observable<Template> {
        return this.http.get<object>(`${TemplatesService.SERVICE_PATH}/${itemId}`).pipe(
            map(response => Template.fromJSON(response)));
    }

    addItem(item: Template): Observable<number> {
        return this.http.post<void>(TemplatesService.SERVICE_PATH, item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(TemplatesService.SERVICE_PATH, response)));
    }

    editItem(itemId: number, item: Template): Observable<number> {
        return this.http.put<void>(`${TemplatesService.SERVICE_PATH}/${itemId}`, item).pipe(
            map(() => itemId));
    }

    removeItem(itemId: number): Observable<void> {
        return this.http.delete<void>(`${TemplatesService.SERVICE_PATH}/${itemId}`);
    }

    validate(item: Template): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('templates/validate', item));
    }
}
