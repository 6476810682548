export class CascadeValueCalculator {

    static calculateDiscountValue(value: string): number {
        if (value == undefined) {
            return 0;
        }
        const discounts = value.split(';');
        if (discounts.length > 1) {
            return discounts.reduce((aggregate, next) => +next + (aggregate * (1 - (+next / 100))), 0);
        } else {
            return +discounts[0];
        }
    }

    static calculateMarginValue(value: string): number {
        if (value == undefined) {
            return 0;
        }
        const discounts = value.split(';');
        if (discounts.length > 1) {
            return discounts.reduce((aggregate, next) => +next + (aggregate * (1 + (+next / 100))), 0);
        } else {
            return +discounts[0];
        }
    }
}
