import {CrudItem, JsonDeserializable} from '../../common/crud-common/crudItem';

@JsonDeserializable
export class SubsystemGroup implements CrudItem {
    id: number;
    name: string;
    bulkAddonProfitMargin: string;

    static fromJSON(jsonObject: any): SubsystemGroup {
        const subsystemGroup = new SubsystemGroup();
        subsystemGroup.id = jsonObject.id;
        subsystemGroup.name = jsonObject.name;
        subsystemGroup.bulkAddonProfitMargin = jsonObject.bulkAddonProfitMargin;
        return subsystemGroup;
    }
}
