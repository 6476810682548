import {SubWindowData} from "../drawing-data/SubWindowData";
import {CompositionType} from "../drawing-data/CompositionType";
import {DrawingData} from "../drawing-data/drawing-data";
import {DrawingUtil, MinMaxXY} from "../drawing-util";

export class ProfileCompositionUtils {

    public static isSubwindowAdjacentToMovableBar(subwindow: SubWindowData): boolean {
        return subwindow.profilesComposition.left === CompositionType.MOVABLE_POST ||
            subwindow.profilesComposition.right === CompositionType.MOVABLE_POST;
    }

    public static hasMovablePost(drawingData: DrawingData): boolean {
        return drawingData.windows.some(w => w.subWindows.some(s => this.isSubwindowAdjacentToMovableBar(s)));
    }

    private static isSubwindowNextToConstructionalMullion(subwindow: SubWindowData, totalBoundingBox: MinMaxXY): boolean {
        const swBox = DrawingUtil.calculatePolygonTotalBoundingBox(subwindow.points);
        return subwindow.profilesComposition.left === CompositionType.REGULAR && swBox.minX !== totalBoundingBox.minX
            || subwindow.profilesComposition.right === CompositionType.REGULAR && swBox.maxX !== totalBoundingBox.maxX
            || subwindow.profilesComposition.top === CompositionType.REGULAR && swBox.minY !== totalBoundingBox.minY
            || subwindow.profilesComposition.bottom === CompositionType.REGULAR && swBox.maxY !== totalBoundingBox.maxY;
    }

    public static constructionalMullionPresent(drawingData: DrawingData): boolean {
        return drawingData.windows.reduce((s, w) => s + w.subWindows.length, 0) > 1 &&
            drawingData.windows.some(w => w.subWindows.some(sw => this.isSubwindowNextToConstructionalMullion(sw,
                DrawingUtil.calculateTotalBoundingBox(drawingData.windows))));
    }
}
