import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MailSettings} from './mail-settings';

@Injectable()
export class MailSettingsService {

    private static readonly API_URL = 'mailSettings';

    constructor(private http: HttpClient) {
    }

    getPasswordResetMailSettings(): Observable<MailSettings> {
        return this.http.get<object>(`${MailSettingsService.API_URL}/passwordReset`)
            .pipe(map(MailSettings.fromJSON));
    }

    getOfferStatusChangeMailSettings(): Observable<MailSettings> {
        return this.http.get<object>(`${MailSettingsService.API_URL}/offerStatusChange`)
            .pipe(map(MailSettings.fromJSON));
    }

    getUserActivityReportMailSettings(): Observable<MailSettings> {
        return this.http.get<object>(`${MailSettingsService.API_URL}/userActivityReport`)
            .pipe(map(MailSettings.fromJSON));
    }

    updatePasswordResetMailSettings(data: MailSettings): Observable<void> {
        return this.http.post<void>(`${MailSettingsService.API_URL}/passwordReset`, data);
    }

    updateOfferStatusChangeMailSettings(data: MailSettings): Observable<void> {
        return this.http.post<void>(`${MailSettingsService.API_URL}/offerStatusChange`, data);
    }

    updateUserActivityReportMailSettings(data: MailSettings): Observable<void> {
        return this.http.post<void>(`${MailSettingsService.API_URL}/userActivityReport`, data);
    }
}
