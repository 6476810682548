import {Glazing} from "../../../window-designer/catalog-data/glazing";
import {ValidationErrors} from '../validation-errors';

type GlazingKey = keyof (Glazing);

export class GlassSelectionValidator {

    static readonly VALIDATED_KEYS: GlazingKey[] = ['glazingGlassQuantity', 'glass1id', 'glass2id', 'glass3id', 'glass4id', 'frame1id',
        'frame2id', 'frame3id'];

    validate(commonData: Glazing): ValidationErrors {
        let validationErrors = {};

        // set all fields to undefined to make code using Object.assign clear existing errors on revalidate
        GlassSelectionValidator.VALIDATED_KEYS.forEach(validatedKey => validationErrors[validatedKey] = undefined);

        if (commonData.glazingGlassQuantity == null) {
            validationErrors['glazingGlassQuantity'] = 'error.offerPosition.bulkChange.selection.not_empty';
        }

        for (let i = 1; i <= commonData.glazingGlassQuantity; i++) {
            if (commonData['glass' + i + 'id'] === undefined || commonData['glass' + i + 'id'] === null) {
                validationErrors['glass' + i + 'id'] = 'error.offerPosition.bulkChange.selection.not_empty';
            }
            if (i < commonData.glazingGlassQuantity && (commonData['frame' + i + 'id'] == undefined)) {
                validationErrors['frame' + i + 'id'] = 'error.offerPosition.bulkChange.selection.not_empty';
            }
        }

        return validationErrors;
    }
}
