import {QueryList} from '@angular/core';
import {Accordion} from 'primeng/accordion';

export abstract class SidebarHelper {

    public static onFocusOpenAccsTabs(event: FocusEvent, accordions: QueryList<Accordion>): void {
        let accordionTabsIds = this.getParentAccordionTabsIds((event.target as Element).parentElement);
        accordions.forEach(acc => this.openAccordionTabs(accordionTabsIds, acc));
    }

    public static onFocusOpenAccTabs(event: FocusEvent, accordion: Accordion): void {
        let accordionTabsIds = this.getParentAccordionTabsIds((event.target as Element).parentElement);
        this.openAccordionTabs(accordionTabsIds, accordion);
    }

    private static openAccordionTabs(accordionTabsIds: string[], accordion: Accordion) {
        accordion.tabs.filter(t => accordionTabsIds.indexOf(t.id) >= 0).forEach(t => t.selected = true);
    }

    private static getParentAccordionTabsIds(element: HTMLElement): string[] {
        let accordionTabsIds = [];
        while (element) {
            if (element.nodeName === "P-ACCORDIONTAB") {
                accordionTabsIds.push(element.id);
            }
            element = element.parentElement;
        }
        return accordionTabsIds;
    }

    static filterNumbersOnInputField(event: Event): void {
        let input = event.target as HTMLInputElement;
        if (!input.value || input.value === '-') {
            // do not validate empty
            event.preventDefault();
            return;
        }
        let value = parseFloat(input.value);
        if (!value) {
            input.value = input.min;
            return;
        }
        let min = parseFloat(input.min);
        if (!!min && value < min) {
            event.preventDefault();
            input.value = input.min;
            return;
        }
        let max = parseFloat(input.max);
        if (!!max && value > max) {
            event.preventDefault();
            input.value = input.max;
            return;
        }
        let step = parseFloat(input.step);
        let tmp = value / step;
        if (!!step && !Number.isInteger(tmp)) {
            input.value = '' + (Math.floor(tmp) * step);
            return;
        }
    }

    public static unwrapUndefinedNoneSelectionWorkaround(value: any) {
        if (value === "undefined") {
            return undefined;
        }
        return value;
    }
}
