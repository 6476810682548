import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {finalize, mergeMap} from 'rxjs/operators';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {CrudItem} from '../../../common/crud-common/crudItem';
import {ComponentWithUserConfigAndPaginator} from '../../../common/crud-common/paginable.component';
import {DatatableInterface} from '../../../common/DatatableHelper';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {TranslatedSelectItemBuilder} from '../../../common/service/translated.select.item.builder';
import {TristateCheckboxState} from '../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {WebshopChargeService} from '../webshop-charge/webshop-charge-service';
import {DataModificationTarget, isUpsellingMode, WebshopCharge} from '../webshop-charge/WebshopCharge';
import {UpsellingPropositionService} from "../../admin-panel/upselling-proposition/upselling-proposition-service";
import {Observable} from "rxjs";
import {Listing} from "../../../common/crud-common/crudItemList";
import {UpsellingProposition} from "../../admin-panel/upselling-proposition/upselling-proposition";

interface AvailableItem extends CrudItem {
    id: number;
    name: MultilanguageFieldInterface;
    info: MultilanguageFieldInterface;
    windowSystemName: MultilanguageFieldInterface;
    available: boolean;
}

@Component({
    selector: 'app-webshop-charge-per-subsystem',
    templateUrl: './webshop-charge-per-subsystem.component.html',
    providers: [WebshopChargeService, UpsellingPropositionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopChargePerSubsystemComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    itemList: AvailableItem[];
    selectedItem: AvailableItem;
    fromRecord = 0;
    toRecord = 0;
    totalRecords = 0;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;
    displayDialog = false;
    item: AvailableItem;
    selected: number[] = [];
    lastLazyLoadEvent: LazyLoadEvent;
    allSelectedState = TristateCheckboxState.UNCHECKED;
    dataModificationTargets = DataModificationTarget;
    @Input('target')
    targetInput: DataModificationTarget;

    @ViewChild('dt', {static: true})
    dataTable: DataTable;

    get target(): DataModificationTarget {
        if (this.targetInput) {
            return this.targetInput;
        }
        if (this.activatedRoute.snapshot.data.target) {
            return this.activatedRoute.snapshot.data.target;
        }
        return undefined;
    }

    constructor(private readonly activatedRoute: ActivatedRoute,
                private readonly webshopChargeService: WebshopChargeService,
                private readonly upsellingPropositionService: UpsellingPropositionService,
                private readonly errors: CommonErrorHandler,
                public readonly translate: TranslateService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopChargePerSubsystemComponent', false);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterActive = TranslatedSelectItemBuilder.create()
            .add('', '')
            .add('WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.AVAILABLE', true)
            .add('WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.UNAVAILABLE', false)
            .build();
        this.defaultActiveFilter = this.filterActive[1];
    }

    showDialogToAdd(): void {
    }

    hideDialog(): void {
        this.displayDialog = false;
        this.changeDetector.markForCheck();
    }

    setAvailableSingle(): Observable<void> {
        return isUpsellingMode(this.target) ?
            this.upsellingPropositionService.setAvailabilityInSubsystem([this.item.id], this.item.available) :
            this.webshopChargeService.setWebshopChargeAvailable(this.item.id, this.item.available);
    }

    setAvailableMultiple(available: boolean): Observable<void> {
        return isUpsellingMode(this.target) ?
            this.upsellingPropositionService.setAvailabilityInSubsystem(this.selected, available) :
            this.webshopChargeService.setWebshopChargesAvailable(this.selected, available);
    }

    getItems(event: LazyLoadEvent): Observable<Listing<WebshopCharge | UpsellingProposition>> {
        return isUpsellingMode(this.target) ?
            this.upsellingPropositionService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder) :
            this.webshopChargeService.getWebshopCharges(event.first, event.rows, event.filters, event.sortField, event.sortOrder);
    }

    getAvailable(): Observable<number[]> {
        return isUpsellingMode(this.target) ?
            this.upsellingPropositionService.getAvailableInSubsystem() :
            this.webshopChargeService.getAvailableWebshopCharges();
    }

    submit(): void {
        this.setAvailableSingle().subscribe({
            next: () => {
                this.growlMessageController.info('WEBSHOP_CHARGE_PER_SUBSYSTEM.UPDATED');
                this.hideDialog();
                this.dataTable.paginate(); // shortcut for reloading data
            },
            error: error => this.errors.handle(error)
        });
    }

    onRowSelect(event: any): void {
        this.displayDialog = true;
        this.item = {...event.data};
        this.selectedItem = event.data;
        this.keepSelectedItemIndex(event);
        this.changeDetector.markForCheck();
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        this.selected = [];
        this.refreshAllSelectedFlag();
        super.loadItemsLazy(event);
        this.lastLazyLoadEvent = event;
        if (!event.filters.hasOwnProperty('target')) {
            event.filters.target = {value: this.target};
        }
        this.getItems(event).pipe(
            finalize(() => this.hideDataLoadingIndicator()),
            mergeMap(webshopCharges => {
                this.itemList = webshopCharges.data.map(webshopCharge => ({
                    id: webshopCharge.id,
                    name: webshopCharge.name,
                    info: webshopCharge.info,
                    windowSystemName: webshopCharge["windowSystemName"],
                    available: false
                }));
                this.totalRecords = webshopCharges.totalRecords;
                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.itemList, event);
                return this.getAvailable();
            })
        ).subscribe({
            next: availableWebshopCharges => {
                this.itemList.forEach(item => {
                    item.available = availableWebshopCharges.includes(item.id);
                });
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    isSelected(item: AvailableItem) {
        return this.selected.includes(item.id);
    }

    handleSelection(item: AvailableItem) {
        const index = this.selected.findIndex(id => item.id === id);
        if (index === -1) {
            this.selected.push(item.id);
        } else {
            this.selected.splice(index, 1);
        }
        this.refreshAllSelectedFlag();
        this.changeDetector.markForCheck();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selected.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selected.length === this.itemList.length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    setForSelected(available: boolean) {
        this.showDataLoadingIndicator();
        this.setAvailableMultiple(available).subscribe({
            next: () => {
                this.selected = [];
                this.loadItemsLazy(this.lastLazyLoadEvent);
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    selectAllChange(): void {
        this.selected = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selected.push(...this.itemList.map(item => item.id));
        }
        this.changeDetector.markForCheck();
    }
}
