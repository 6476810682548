import {GrillInterface} from '../catalog-data/grill-interface';
import {DrawingData} from "../drawing-data/drawing-data";
import {Grill} from "../drawing-data/Grill";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";
import {DataKeys} from "../drawing-util";
import {AreaUtils} from "./AreaUtils";
import {ErrorNames} from "./ErrorNames";
import {MullionHelper} from "./MullionHelper";
import {PolygonPoint, PolygonPointUtil} from './PolygonPoint';
import {PositionsHelper} from "./positions-helper";

export class GrillUtils {

    public static changeRelativePosition(grill: Grill, element: Snap.Element, posIndex: number,
                                         newAbsolutePosition: number, vertical: boolean) {
        let relPosition = grill.positions[posIndex];
        let perpendicularLine = vertical ? [newAbsolutePosition, 0, newAbsolutePosition, 1] :
            [0, newAbsolutePosition, 1, newAbsolutePosition];
        switch (relPosition.type) {
            case PositionReferenceType.GRILL:
                let area = element.data(DataKeys.AREA);
                let grillSegment = AreaUtils.findGrillSegmentById(area, relPosition.id);
                relPosition.percent = PositionsHelper.findNewPercentForSegment(grillSegment, perpendicularLine);
                break;
            case PositionReferenceType.MULLION:
                let subWindow = element.data(DataKeys.SUBWINDOW);
                let mullionSegment = MullionHelper.findMullionSegmentById(relPosition.id, subWindow);
                relPosition.percent = PositionsHelper.findNewPercentForSegment(mullionSegment, perpendicularLine);
                break;
            case PositionReferenceType.GLAZING_BEAD:
                let glazingBead: PolygonPoint[] = element.data(DataKeys.GLAZING_BEAD);
                PositionsHelper.setNewPercentForPolygonSide(relPosition, glazingBead, perpendicularLine);
                break;
            case PositionReferenceType.INNER_FRAME:
                let innerFrame: PolygonPoint[] = element.data(DataKeys.INNER_FRAME);
                PositionsHelper.setNewPercentForPolygonSide(relPosition, innerFrame, perpendicularLine);
                break;
            default:
                let err = new Error("Position reference type not supported");
                err.name = ErrorNames.GRILL_FAILED_TO_CHANGE_POSITION;
                throw err;
        }
    }

    public static initializeGrillsAngularity(data: DrawingData, grillDtos: GrillInterface[]): void {
        data.windows.forEach(w =>
            w.subWindows.forEach(s => {
                s.mullions.forEach(m => m.canBeAngled = MullionHelper.canBeDrawnAtAnAngle(m));
                s.areasSpecification.forEach(a =>
                    a.grills.forEach(g => {
                        let dto = grillDtos.find(d => d.id === g.id);
                        g.canBeAngled = dto.angled;
                        g.constructionType = dto.type;
                    }));
            }));
    }
}
