import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {finalize} from "rxjs/operators";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {Listing} from '../../../common/crud-common/crudItemList';
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DatatableHelper} from "../../../common/DatatableHelper";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {SubsystemService} from "../../subsystem/subsystem.service";
import {ErrorReportService} from "./error-report.service";
import {ErrorCategory, ErrorReport, getErrorCategoryTranslationKey, Severity} from "./errorReport";

@Component({
    selector: 'app-error-browser',
    templateUrl: './error-browser.component.html',
    styleUrls: ['./error-browser.component.css', '../../shared-styles.css'],
    providers: [ErrorReportService, DataServiceHelper, TranslatedSelectItemService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorBrowserComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    messages: ErrorReport[] = [];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;

    lastSearchValue: string;
    availableSubsystems: SelectItem[] = [];
    selectedSubsystems: string[] = [];

    filterServerity: Observable<SelectItem[]>;
    filterCategory: Observable<SelectItem[]>;

    @ViewChild(DataTable, {static: true})
    dataTable: DataTable;

    getErrorCategoryTranslationKey = getErrorCategoryTranslationKey;

    constructor(private readonly errorReportService: ErrorReportService,
                private readonly translatedSelectItemService: TranslatedSelectItemService,
                private readonly errors: CommonErrorHandler,
                private readonly subsystemService: SubsystemService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'ErrorBrowserComponent', false);
    }

    ngOnInit() {
        this.filterServerity = this.translatedSelectItemService.buildSortedDropdown(Severity, 'ADMIN_PANEL.ERROR_BROWSER.SEVERITY_FILTER.',
            '');
        this.filterCategory = this.translatedSelectItemService.buildSortedDropdown(ErrorCategory, getErrorCategoryTranslationKey,
            '');
        this.subsystemService.getSelectionItems().subscribe(subsystems => {
            this.availableSubsystems = subsystems.sort((a, b) => a.label.localeCompare(b.label));
        });
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    doOnRowSelect(event) {
        // do nothing
    }

    onRowSelect() {
        // do nothing
    }

    showDialogToAdd() {
        // do nothing
    }

    submit() {
        // do nothing
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        let dataSource: Observable<Listing<ErrorReport>>;
        if (!!this.lastSearchValue) {
            dataSource = this.errorReportService.getMessagesSimple(event.first, event.rows, this.lastSearchValue,
                event.sortField, event.sortOrder);
        } else {
            dataSource = this.errorReportService.getMessages(event.first, event.rows, event.filters, event.sortField, event.sortOrder);
        }
        dataSource.pipe(
            finalize(() => this.hideDataLoadingIndicator())
        ).subscribe({
            next: data => {
                console.info('ErrorBrowserComponent `getMessages` success:');
                this.setMessages(data, event);
            },
            error: error => {
                console.error('ErrorBrowserComponent `getMessages` error:', error);
                this.errors.handle(error);
            },
            complete: () => {
                console.info('ErrorBrowserComponent `getMessages` completed!');
                this.changeDetector.markForCheck();
            }
        });
    }

    private setMessages(data: Listing<ErrorReport>, event: LazyLoadEvent) {
        this.messages = data.data;
        this.totalRecords = data.totalRecords;
        this.fromRecord = Math.min(event.first + 1, this.totalRecords);
        this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.dataTable.filter(subsystems.join(','), 'subsystem', 'in');
    }

    lookupRowStyleClass(rowData: ErrorReport): string {
        return rowData.severity;
    }

    dismissMessage(message: ErrorReport): void {
        this.errorReportService.deactivateMessage(message.id).subscribe({
            next: () => {
                console.info('ErrorBrowserComponent `deactivateMessage` completed!');
                DatatableHelper.reload(this.dataTable);
            },
            error: error => {
                console.error('ErrorBrowserComponent `deactivateMessage` error:', error);
                this.errors.handle(error);
            }
        });
    }

    dismissAllMessages(): void {
        this.errorReportService.deactivateAllMessages().subscribe({
            next: () => {
                console.info('ErrorBrowserComponent `deactivateAllMessages` completed!');
                DatatableHelper.reload(this.dataTable);
            },
            error: error => {
                console.error('ErrorBrowserComponent `deactivateAllMessages` error:', error);
                this.errors.handle(error);
            }
        });
    }

    addCommaIfNeeded(currentIndex: number, size: number): string {
        if (currentIndex < size - 1 && size !== 1) {
            return ", ";
        }

        return "";
    }

    handleMessagesSearch(searchedValue: string): void {
        if (searchedValue && this.lastSearchValue === searchedValue) {
            return;
        }
        this.lastSearchValue = searchedValue;
        DatatableHelper.reload(this.dataTable);
    }
}
