import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ValidationErrors} from '../../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../../common/ValidationErrorsHelper';
import {Palette} from '../palette';

@Injectable()
export class PaletteService {

    private static readonly API_URL = 'palette';

    constructor(private http: HttpClient) {
    }

    get(id: number): Observable<Palette> {
        return this.http.get<object>(`${PaletteService.API_URL}/${id}`)
            .pipe(map(response => Palette.fromJSON(response)));
    }

    update(palette: Palette): Observable<void> {
        return this.http.put<void>(`${PaletteService.API_URL}/${palette.id}`, palette);
    }

    validateGeneralData(palette: Palette): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${PaletteService.API_URL}/validateGeneralDetails`, palette));
    }

    deletePalette(paletteId: number): Observable<void> {
        return this.http.delete<void>(`${PaletteService.API_URL}/${paletteId}`);
    }
}
