import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ErrorResponse} from '../features/errors/errorResponse';
import {ResponseError} from './error.handler';
import {GrowlMessageController} from './growl-message/growl-message-controller';
import {MessageParams} from './growl-message/MessageParams';
import {ValidationErrors} from './validation-errors';
import {ValidationErrorsHelper} from './ValidationErrorsHelper';

@Injectable()
export class CommonErrorHandler {

    constructor(private growls: GrowlMessageController) {
    }

    public handle(error: HttpErrorResponse | Error, showValidationErrorsInGrowl = false): ValidationErrors {
        if (error instanceof HttpErrorResponse) {
            let errorResponse = new ErrorResponse(error.error);
            if (errorResponse.is400() || errorResponse.is422()) {
                if (errorResponse.message) {
                    this.growls.error(errorResponse.message);
                }
                if (errorResponse.errorCodesWithParams) {
                    errorResponse.errorCodesWithParams.forEach(e => this.growls.error(e.errorCode, e.params));
                }
                let validationErrors: ValidationErrors = {};
                if (ValidationErrorsHelper.validationErrorsPresent(errorResponse.invalidFields)) {
                    validationErrors = {...errorResponse.invalidFields};
                }
                if (showValidationErrorsInGrowl) {
                    Object.values(validationErrors).forEach(e => this.growls.error(e));
                }
                return validationErrors;
            } else {
                throw new ResponseError(error);
            }
        } else {
            throw error;
        }
    }

    public handleFE(error: Error, params?: MessageParams): void {
        console.warn(error, params);
        if (error.name) {
            this.growls.error(error.name, params);
        }
    }
}
