import {Observable, of} from 'rxjs';
import {ValidationErrors} from '../../common/validation-errors';
import {ValidationErrorsHelper} from '../../common/ValidationErrorsHelper';
import {ZipCodeValidator} from '../../common/zip-code-validator';
import {MultiValidator} from '../../shared/validator/input-validator';
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from './profit-margin/profitMargin';
import {Subsystem, TransportType} from './subsystem';
import {SubsystemService} from './subsystem.service';
import {TabularAddress} from './tabularAddress';

export class SubsystemValidator {

    constructor(public subsystemService: SubsystemService) {
    }

    validateDetails(subsystem: Subsystem): Observable<ValidationErrors> {
        const validationErrors = {};
        if (subsystem.subsystemGroupId == undefined) {
            validationErrors['subsystemGroupId'] = 'error.subsystemDto.subsystemGroupId.not_null';
        }
        if (!subsystem.name || !subsystem.name.trim()) {
            validationErrors['name'] = 'error.subsystemDto.name.not_empty';
        }
        if (!subsystem.symbol || !subsystem.symbol.trim()) {
            validationErrors['symbol'] = 'error.subsystemDto.symbol.not_empty';
        }
        if (!subsystem.defaultCurrency) {
            validationErrors['defaultCurrency'] = 'error.subsystemDto.defaultCurrency.not_empty';
        }
        if (subsystem.clientManager == undefined || subsystem.clientManager.id == undefined) {
            validationErrors['clientManager.login'] = 'error.subsystemDto.clientManager.login.not_empty';
        }
        if (subsystem.subClientManager == undefined || subsystem.subClientManager.id == undefined) {
            validationErrors['subClientManager'] = 'error.subsystemDto.subClientManager.not_empty';
        }
        if (subsystem.vatSell == undefined) {
            validationErrors['vatSell'] = 'error.subsystemDto.vatSell.not_empty';
        }
        if (subsystem.phoneNumber == undefined) {
            validationErrors['phoneNumber'] = 'error.subsystemDto.phoneNumber.not_empty';
        }
        if (subsystem.transportType == undefined) {
            validationErrors['transportType'] = 'error.subsystemDto.transportType.not_empty';
        } else if (subsystem.transportType === TransportType.SPLITTED) {
            if (subsystem.euTransportPercent + subsystem.nonEuTransportPercent !== 100) {
                validationErrors['euTransportPercent'] = 'error.subsystemDto.euTransportPercent.not_in_range';
            }
        }
        if (!!subsystem.bulkAddonProfitMargin
            && !isValidProfitMarginValueString(normalizeProfitMarginValueString(subsystem.bulkAddonProfitMargin))) {
            validationErrors['bulkAddonProfitMargin'] = 'PROFIT_MARGIN.ERROR.INVALID_VALUE';
        }
        validationErrors['defaultOfferDurationDays'] = MultiValidator.of('error.subsystemDto.defaultOfferDurationDays')
            .withRangeValidator(1, 31).validate(subsystem.defaultOfferDurationDays);
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            return of(validationErrors);
        }
        return this.subsystemService.validateDetails(subsystem);
    }

    validateSingleAddress(field: string, address: TabularAddress): ValidationErrors {
        const validationErrors: ValidationErrors = {};
        if (!address.address.street) {
            validationErrors[`${field}.address.street`] = `error.subsystemDto.${field}.address.street.not_null`;
        }
        if (!address.address.city) {
            validationErrors[`${field}.address.city`] = `error.subsystemDto.${field}.address.city.not_null`;
        }
        if (!address.address.country) {
            validationErrors[`${field}.address.country`] = `error.subsystemDto.${field}.address.country.not_null`;
        }
        if (!address.address.zip) {
            validationErrors[`${field}.address.zip`] = `error.subsystemDto.${field}.address.zip.not_null`;
        } else if (!new ZipCodeValidator().validate(address.address.country, address.address.zip)) {
            validationErrors[`${field}.address.zip`] = `error.subsystemDto.${field}.address.zip.pattern_not_matched`;
        }
        return validationErrors;
    }

    validateAddresses(availableAddresses: TabularAddress[]): Observable<ValidationErrors> {
        const validationErrors: ValidationErrors = {};
        for (let i = 0; i < availableAddresses.length; ++i) {
            Object.assign(validationErrors, this.validateSingleAddress(`addresses[${i}]`, availableAddresses[i]));
        }
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            return of(validationErrors);
        }
        return this.subsystemService.validateAddresses(availableAddresses);
    }

    validateAddress(subsystem: Subsystem, availableAddresses: TabularAddress[],
                    newCorrespondenceAddress: TabularAddress,
                    newDeliveryAddress: TabularAddress): Observable<ValidationErrors> {
        const validationErrors = {};
        // selecting existing address
        if (subsystem.primaryCorrespondenceAddressId != undefined) {
            const correspondenceAddress = availableAddresses.find(address => address.id === subsystem.primaryCorrespondenceAddressId);
            if (correspondenceAddress == undefined) {
                validationErrors['correspondenceAddress'] = 'error.subsystemDto.correspondenceAddress.not_null';
            }
        } else if (newCorrespondenceAddress == null) {
            validationErrors['correspondenceAddress'] = 'error.subsystemDto.correspondenceAddress.not_null';
        }
        if (subsystem.primaryDeliveryAddressId != undefined) {
            const deliveryAddress = availableAddresses.find(address => address.id === subsystem.primaryDeliveryAddressId);
            if (deliveryAddress == undefined) {
                validationErrors['deliveryAddress'] = 'error.subsystemDto.deliveryAddress.not_null';
            }
        }
        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            return of(validationErrors);
        }
        return this.subsystemService.validateAddress(subsystem, availableAddresses, newCorrespondenceAddress, newDeliveryAddress);
    }
}
