import {DocumentFormat} from './document-format.enum';
import {DocumentTemplateType} from './document-template-type.enum';
import {DocumentType} from './DocumentType';
import {SinglePrintConfig} from "./single-print-config";

export class PrintOptions extends SinglePrintConfig {
    documentType: DocumentType;
    documentTemplateType: DocumentTemplateType;
    documentFormat: DocumentFormat;
    printLanguage: string;

    constructor(documentType: DocumentType, documentTemplateType: DocumentTemplateType, documentFormat: DocumentFormat,
                config: SinglePrintConfig, printLanguage: string) {
        super();
        this.printPricesDetailLevel = config.printPricesDetailLevel;
        this.printQuantitiesDetails = config.printQuantitiesDetails;
        this.printOnlyGrossPrices = config.printOnlyGrossPrices;
        this.roundPrices = config.roundPrices;
        this.warrantyInfo = config.warrantyInfo;
        this.hideDimensions = config.hideDimensions;
        this.hideOpenings = config.hideOpenings;
        this.showLeaflets = config.showLeaflets;
        this.showWeight = config.showWeight;
        this.showUpselling = config.showUpselling;
        this.showRot = config.showRot;
        this.showGrillsLayout = config.showGrillsLayout;
        this.hideAssemblyAndTransportDisclaimer = config.hideAssemblyAndTransportDisclaimer;
        this.hideDealerDiscount = config.hideDealerDiscount;
        this.hideWindowSystemHeaders = config.hideWindowSystemHeaders;
        this.documentType = documentType;
        this.documentTemplateType = documentTemplateType;
        this.documentFormat = documentFormat;
        this.printLanguage = printLanguage;
    }
}
