import {MultilanguageField} from '../../../../../supportedLanguages';
import {PriceWithTax} from "../../../offer";
import {CatalogItemType, PricingItem} from './PricingItem';

export enum ChargeType {
    WINDOW_PRICING = 'WINDOW_PRICING',
    ROOF_WINDOW_PRICING = 'ROOF_WINDOW_PRICING',
    WINDOW_CHARGE = 'WINDOW_CHARGE',
    SUBWINDOW_CHARGE = 'SUBWINDOW_CHARGE',
    AREA_CHARGE = 'AREA_CHARGE',
    INSIDE_COLOR_CHARGE = 'INSIDE_COLOR_CHARGE',
    OUTSIDE_COLOR_CHARGE = 'OUTSIDE_COLOR_CHARGE',
    CORE_COLOR_CHARGE = 'CORE_COLOR_CHARGE',
    INSIDE_OUTSIDE_COLOR_CHARGE = 'INSIDE_OUTSIDE_COLOR_CHARGE',
    GLAZING_CHARGE = 'GLAZING_CHARGE',
    INNER_SEAL_CHARGE = 'INNER_SEAL_CHARGE',
    OUTER_SEAL_CHARGE = 'OUTER_SEAL_CHARGE',
    FILLING_CHARGE = 'FILLING_CHARGE',
    MULLION_CHARGE = 'MULLION_CHARGE',
    GLASS_CHARGE = 'GLASS_CHARGE',
    DISTANCE_FRAME_CHARGE = 'DISTANCE_FRAME_CHARGE',
    MUNTIN_CHARGE = 'MUNTIN_CHARGE',
    CONFIG_ADDON_CHARGE = 'CONFIG_ADDON_CHARGE',
    BULK_CONFIG_ADDON_CHARGE = 'BULK_CONFIG_ADDON_CHARGE',
    CONFIG_ADDITIONAL_CHARGE = 'CONFIG_ADDITIONAL_CHARGE',
    SHAPE_CHARGE = 'SHAPE_CHARGE',
    BULK_ADDON_CHARGE = 'BULK_ADDON_CHARGE',
    WINDOW_ADDON_CHARGE = 'WINDOW_ADDON_CHARGE',
    WING_ADDON_CHARGE = 'WING_ADDON_CHARGE',
    QUARTER_ADDON_CHARGE = 'QUARTER_ADDON_CHARGE',
    GLAZIERY_ADDON_CHARGE = 'GLAZIERY_ADDON_CHARGE',
    AREA_ADDON_CHARGE = 'AREA_ADDON_CHARGE',
    SIDEBAR_ADDON_CHARGE = 'SIDEBAR_ADDON_CHARGE',
    PROFILE_CHARGE = 'PROFILE_CHARGE',
    FITTING_CHARGE = 'FITTING_CHARGE',
    GLAZING_BEAD_CHARGE = 'GLAZING_BEAD_CHARGE',
    ASSEMBLY_CHARGE = 'ASSEMBLY_CHARGE',
    VENTILATOR_ADDON_CHARGE = 'VENTILATOR_ADDON_CHARGE',
    DRIP_ADDON_CHARGE = 'DRIP_ADDON_CHARGE',
    HANDLE_ADDON_CHARGE = 'HANDLE_ADDON_CHARGE',
    COUPLER_ADDON_CHARGE = 'COUPLER_ADDON_CHARGE',
    COVER_ADDON_CHARGE = 'COVER_ADDON_CHARGE',
    DECORATIVE_FILLING_CHARGE = 'DECORATIVE_FILLING_CHARGE',
    ENTRANCE_DOOR_PRICING = 'ENTRANCE_DOOR_PRICING',
    GATE_PRICING = 'GATE_PRICING'
}

export class Charge {
    type: ChargeType;
    name: MultilanguageField;
    venskaBuyPrice: PriceWithTax;
    buyPrice: PriceWithTax;
    sellPrice: PriceWithTax;
    retailSellPrice: PriceWithTax;
    components: Charge[];
    quantity: number;
    relatedItems: PricingItem[];

    static findRelatedItemId(charge: Charge, type: CatalogItemType): number {
        return charge.relatedItems
            .filter(relatedItem => relatedItem.relatedItemType === type)
            .map(relatedItem => relatedItem.relatedItemId)[0];
    }
}
