import {ApplicationPrivilege} from './auth/application-privilege';

export class NavigationLink<T = string> {
    public hotkey: string;
    public cssClass: T;
    public routerLink: string;
    public roles: { roles: ApplicationPrivilege[] };
    public name: string;
    public icon: string;
    public hidden: boolean;
    public roles2?: { roles: ApplicationPrivilege[] };

    constructor(hotkey: string,
                cssClass: T,
                routerLink: string,
                roles: { roles: ApplicationPrivilege[] },
                name: string,
                icon?: string,
                hidden?: boolean,
                roles2?: {roles: ApplicationPrivilege[]}) {
        this.hotkey = hotkey;
        this.cssClass = cssClass;
        this.routerLink = routerLink;
        this.roles = roles;
        this.name = name;
        this.icon = icon;
        this.hidden = hidden === true;
        this.roles2 = roles2;
        if (!icon) {
            this.icon = "help_outline";
        }
    }
}
