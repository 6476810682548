<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.TEMPLATES.GENERAL' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'SETTINGS.SECTION.TEMPLATES.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [paginator]="true" [rows]="chosenRowsPerPage" [totalRecords]="totalRecords"
                 [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true" selectionMode="single"
                 [(selection)]="selectedItem" (onRowDblclick)="doOnRowSelect($event)" [lazy]="true"
                 (onLazyLoad)="loadItemsLazy($event)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 sortField="{{ defaultSortColumn }}" [sortOrder]="defaultSortOrder" dataKey="id" [value]="itemList">
        <p-column field="content"
                  header="{{ 'SETTINGS.SECTION.TEMPLATES.FORM.CONTENT' | translate:{lang:translate.currentLang} }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-rowData="rowData" pTemplate="body">
                <div title="{{rowData.content[translate.currentLang]}}">
                    {{ trimContent(rowData.content[translate.currentLang])}}
                </div>
            </ng-template>
        </p-column>
        <p-column field="templateType" header="{{ 'SETTINGS.SECTION.TEMPLATES.FORM.TYPE' | translate }}"
                  [sortable]="true" [filter]="showFilters" [filterValues]="templateTypeFilter | async">
            <ng-template pTemplate="body" let-rowData="rowData">
                {{ 'SETTINGS.SECTION.TEMPLATES.FORM.TYPES.' + rowData.templateType | translate }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>
                {{ 'GENERAL.TOTAL_RECORDS' | translate: {from: fromRecord, to: toRecord, total: totalRecords} }}
            </span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'SETTINGS.SECTION.TEMPLATES.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'SETTINGS.SECTION.TEMPLATES.STEPS.DATA' | translate }}" [validate]="validateDataStep">
        <app-template-form [item]="item" [templateTypeOptions]="templateTypeOptions | async"
                           [validationErrors]="validationErrors"></app-template-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ 'SETTINGS.SECTION.TEMPLATES.EDIT' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step [validate]="validateDataStep">
        <app-template-form [item]="item" [templateTypeOptions]="templateTypeOptions | async"
                           [validationErrors]="validationErrors"></app-template-form>
    </app-wizard-step>
</app-wizard-dialog>
