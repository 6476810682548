import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {ComponentWithUserConfigAndPaginator} from "../../../../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../../../../common/dataServiceHelper";
import {DatatableHelper} from "../../../../../../common/DatatableHelper";
import {TranslatedSelectItemService} from '../../../../../../common/service/translated-select-item.service';
import {Currencies} from '../../../../../../currencies';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {MultilanguageField, SupportedLanguages} from "../../../../../../supportedLanguages";
import {SubsystemService} from "../../../../../subsystem/subsystem.service";
import {UserService} from "../../../../../user/user.service";
import {Assembly, AssemblyUnit} from "../../../../../window-system/assembly/assembly";
import {AssemblyService} from "../../../../../window-system/assembly/assembly.service";
import {PositionType} from '../../../../AbstractPosition';
import {PositionService} from "../../position.service";
import {Position, PositionSortGroup} from "../position";
import {AssemblyData} from "./assembly-data";

class AssemblyTableData {
    template: Assembly;
    position: Position;
    selected: boolean;
}

@Component({
    selector: 'app-add-assembly',
    templateUrl: './add-assembly.component.html',
    styleUrls: ['./add-assembly.component.css', '../../../../../shared-styles.css'],
    providers: [AssemblyService, PositionService, UserService, SubsystemService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddAssemblyComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy {

    @Input()
    offerId: number;

    @Output()
    onSubmit = new EventEmitter();

    @Output()
    onClose = new EventEmitter();

    @ViewChild(DataTable, {static: true})
    dataTable: DataTable;

    visible = false;
    itemList: AssemblyTableData[] = [];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    selectedItem: AssemblyTableData;
    assemblyUnits: Observable<SelectItem[]>;
    allSelected = false;
    subsystemVatRate = 0;
    subsystemCurrency: Currencies;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private assemblyService: AssemblyService,
                private positionService: PositionService,
                private userService: UserService,
                private subsystemService: SubsystemService,
                private translatedSelectItemService: TranslatedSelectItemService,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
        super(injector, changeDetector, 'AddAssemblyComponent', false);
    }

    ngOnInit(): void {
        this.assemblyUnits = this.translatedSelectItemService.buildSortedDropdown(AssemblyUnit, 'ASSEMBLY.UNITS.', '');
        this.userService.getUserProfile().pipe(mergeMap(user => {
            return this.subsystemService.getItem(user.subsystemId);
        })).subscribe({
            next: subsystem => {
                this.subsystemVatRate = subsystem.vatSell;
                this.subsystemCurrency = subsystem.defaultCurrency;
                this.setVisible(true);
            },
            error: error => this.errors.handle(error)
        });
        // no default hotkeys
    }

    ngOnDestroy(): void {
        // no default hotkeys
    }

    submit(): void {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        let positionsToSave = this.itemList.filter(data => data.selected);
        let observable = this.positionService.saveItem(positionsToSave[0].position);
        for (let i = 1; i < positionsToSave.length; ++i) {
            observable = observable.pipe(mergeMap(() => {
                positionsToSave.shift().selected = false;
                this.changeDetector.markForCheck();
                return this.positionService.saveItem(positionsToSave[0].position);
            }));
        }

        observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
            complete: () => {
                this.dialogHideHelper.call(() => this.onSubmit.emit());
                this.setVisible(false);
            },
            error: (error) => {
                this.errors.handle(error);
            }
        });
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        event.filters['active'] = {value: 'true'};
        super.loadItemsLazy(event);
        this.assemblyService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.itemList = data.data.map(assembly => this.createPositionFromAssembly(assembly));
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedItem = this.itemList[0];
                    DatatableHelper.focusOnRowIfNotEditingFilters(event);
                    this.changeDetector.markForCheck();
                },
                error: error => this.errors.handle(error)
            });
    }

    private createPositionFromAssembly(assembly: Assembly): AssemblyTableData {
        let name = new MultilanguageField();
        for (let lang of SupportedLanguages.languages) {
            name[lang.code] = assembly.name;
        }
        let position = new Position(name, PositionType.ASSEMBLY, undefined, undefined, undefined, undefined, undefined, undefined,
            undefined, undefined, undefined, undefined, undefined, null, null,
            JSON.stringify(new AssemblyData(assembly.id, assembly.assemblyUnit)), this.offerId, undefined);
        position.sortGroup = PositionSortGroup.ASSEMBLY;
        return {
            template: assembly,
            position: position,
            selected: false
        };
    }

    selectAllChange(selected: boolean): void {
        this.allSelected = selected;
        for (let data of this.itemList) {
            if (data.selected !== selected) {
                data.selected = selected;
                this.dataTable.toggleRow(data);
            }
        }
    }

    selectOne(data: AssemblyTableData, selected: boolean): void {
        data.selected = selected;
        this.dataTable.toggleRow(data);
        this.allSelected = selected && this.itemList.every(item => item.selected);
    }

    calculateGrossPrice(netPrice: number): number {
        return netPrice * (1 + this.subsystemVatRate / 100);
    }

    quantityInputChange(position: Position, quantity: number): void {
        position.quantity = quantity;
        this.changeDetector.markForCheck();
    }

    isNotMeter(assembly: Assembly): boolean {
        return assembly.assemblyUnit !== AssemblyUnit.METER;
    }

    fillQuantityFromOffer(item: AssemblyTableData): void {
        this.assemblyService.calculateQuantityForOffer(item.template.id, item.position.offerId).subscribe({
            next: quantity => {
                item.position.quantity = quantity;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    hasSelection(): boolean {
        let selectedItems = this.itemList.filter(data => data.selected);
        return selectedItems.length > 0 && selectedItems.every(data => data.position.quantity > 0);
    }

    close(): void {
        if (this.setVisible(false)) {
            this.dialogHideHelper.call(() => this.onClose.emit());
        }
    }

    private setVisible(visible: boolean): boolean {
        if (this.visible !== visible) {
            this.visible = visible;
            this.changeDetector.markForCheck();
            return true;
        }
        return false;
    }

    showDialogToAdd(): void {
        // already a dialog
    }

    onRowSelect(event: any): void {
        // no row select
    }
}
