<p-dialog header="{{ 'CONFIGURABLE_ADDONS.HEADER' | translate }}" [resizable]="false" [visible]="true"
          (onHide)="emitCloseDialog()" [focusOnShow]="false" class="dialog-window">
    <div class="config-addon-list-content">
        <p-dataTable #dt [value]="nonDeletedModels" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                     [paginator]="true" selectionMode="single" (onRowDblclick)="editAddon($event)">
            <p-column field="configurableAddon.configurableAddonDefinitionType"
                      header="{{ 'CONFIGURABLE_ADDONS.DEFINITION_TYPE' | translate }}" [sortable]="false" [filter]="false">
                <ng-container *ngIf="displayDeprecated">
                    <ng-template let-addon="rowData.configurableAddon" pTemplate="body">
                        {{ getDefinitionName(addon.configurableAddonDefinitionType) | translate }}
                    </ng-template>
                </ng-container>
                <ng-container *ngIf="!displayDeprecated">
                    <ng-template let-position="rowData.position" pTemplate="body">
                        {{ position && position.name && position.name[translate.currentLang] }}
                    </ng-template>
                </ng-container>
            </p-column>
            <p-column field="configurableAddon.application" header="{{ 'CONFIGURABLE_ADDONS.APPLICATION' | translate }}"
                      [sortable]="true" [filter]="true" [filterValues]="filterApplication | async">
                <ng-template let-model="rowData" pTemplate="body">
                    {{ ('OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.' + model.configurableAddon.application) | translate }}
                    {{ mappedReferencedInfo[(model.position.id || model.position.assignedId)] }}
                </ng-template>
            </p-column>
            <p-column field="configurableAddon.openingWidth" header="{{ 'CONFIGURABLE_ADDONS.DIMENSIONS' | translate }}"
                      [sortable]="false" [filter]="false">
                <ng-template let-addon="rowData.configurableAddon" pTemplate="body">
                    {{ addon.dimensionsOverride }}
                </ng-template>
            </p-column>
            <p-column field="actions" header="" [sortable]="false" [style]="{'width': '50px'}"
                      styleClass="action-tabview-column">
                <ng-template let-addon="rowData" pTemplate="body">
                    <app-simple-button icon="remove" type="delete" [disabled]="readOnlyMode"
                                       (onClick)="deleteAddon(addon)"></app-simple-button>
                </ng-template>
            </p-column>
        </p-dataTable>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'OFFER.ACTIONS.DIALOGS.BUTTON_CLOSE' | translate }}" type="main-action"
                               [size]="40" (onClick)="emitCloseDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
