<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(RailSystemField.NAME)"
                            inputId="name"
                            label="{{ 'RAIL_SYSTEM.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="100"
                            [disabled]="fieldUsage.disabled(RailSystemField.NAME)"
                            [required]="true" [field]="item.name"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(RailSystemField.SHORTCUT)"
                            inputId="shortcut"
                            label="{{ 'RAIL_SYSTEM.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors" [maxlength]="100"
                            [disabled]="fieldUsage.disabled(RailSystemField.SHORTCUT)"
                            [required]="false" [field]="item.shortcut"></app-translation-fields>

    <div *ngIf="fieldUsage.show(RailSystemField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'RAIL_SYSTEM.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(RailSystemField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [required]="true" [(ngModel)]="item.symbol">
        </app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(RailSystemField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'RAIL_SYSTEM.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(RailSystemField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(RailSystemField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'RAIL_SYSTEM.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(RailSystemField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RailSystemField.IMAGE_SECTION)">
        <p-accordion>
            <p-accordionTab header="{{ 'RAIL_SYSTEM.FORM.IMAGE_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(RailSystemField.IMAGE_SECTION)"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                 [addButtonLabel]="'RAIL_SYSTEM.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'RAIL_SYSTEM.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'RAIL_SYSTEM.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RailSystemField.DESIGNER_IMAGE_SECTION) || fieldUsage.show(RailSystemField.PRINT_DATA)">
        <p-accordion>
            <p-accordionTab *ngIf="fieldUsage.show(RailSystemField.DESIGNER_IMAGE_SECTION)"
                            header="{{ 'RAIL_SYSTEM.FORM.DESIGNER_IMAGE_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['designerImage']}">
                <app-file-upload inputId="designerImage" [ngModel]="designerFile"
                                 [disabled]="fieldUsage.disabled(RailSystemField.DESIGNER_IMAGE_SECTION)"
                                 (ngModelChange)="onDesignerFileChange($event)"
                                 [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                 [addButtonLabel]="'RAIL_SYSTEM.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'RAIL_SYSTEM.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'RAIL_SYSTEM.FORM.DELETE_FILE_UPLOAD'"
                                 accept=".svg" validationFileType="image/svg+xml"
                                 [(validationMessageKey)]="validationErrors['designerImage']"></app-file-upload>
            </p-accordionTab>

            <p-accordionTab *ngIf="fieldUsage.show(RailSystemField.PRINT_DATA)"
                            header="{{ 'RAIL_SYSTEM.FORM.PRINT_DATA' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['additionalIcon'] || validationErrors['additionalComment']}">
                <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                    <app-text-area inputId="{{'additionalComment_' + lang.code}}"
                                   [disabled]="fieldUsage.disabled(RailSystemField.PRINT_DATA)"
                                   label="{{ 'RAIL_SYSTEM.FORM.ADDITIONAL_COMMENT' | translate: {lang: lang.code} }}"
                                   [(validationMessageKey)]="validationErrors['additionalComment']" [maxlength]="1000"
                                   [required]="false" [(ngModel)]="item.additionalComment && item.additionalComment[lang.code]"></app-text-area>
                </div>

                <app-file-upload inputId="additionalIcon" [ngModel]="additionalIcon"
                                 (ngModelChange)="additionalIconChange.emit($event)"
                                 [disabled]="fieldUsage.disabled(RailSystemField.PRINT_DATA)"
                                 [maxSize]="10000" [maxWidth]="100" [maxHeight]="100"
                                 [addButtonLabel]="'CATALOG_OPTION.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'CATALOG_OPTION.FORM.SELECT_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'CATALOG_OPTION.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['additionalIcon']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
