import {ConfigAddonOpening} from '../entities/ConfigAddonOpening';
import {Glazing} from "../catalog-data/glazing";
import {RandomIdGenerator} from "../utils/RandomIdGenerator";
import {DefiningMullion} from "./DefiningMullion";
import {FieldWithPosition} from "./FieldWithPosition";
import {Filling} from "./Filling";
import {GlazingBead} from "./GlazingBead";
import {Grill} from "./Grill";

export class AreaSpecification {
    generatedId: string;
    ordinalNumber: number;
    width: number;
    height: number;
    pricingWitdh: number;
    pricingHeight: number;
    fillingWidth: number;
    fillingHeight: number;
    realPackageWidth: number;
    realPackageHeight: number;
    pluginWidth: number;
    pluginHeight: number;
    configurableAddonIds: number[];
    filling: Filling;
    glazing: Glazing;
    glazingBead: GlazingBead;
    grills: Grill[];
    fields: FieldWithPosition[];
    mullionObliqueField: boolean;
    rectangularShape: boolean;
    definingMullions: DefiningMullion[];
    openings: ConfigAddonOpening[] = [];
    glazingPackageId: number;
    glazingCategoryId: number;
    glazingFrameCategoryId: number;

    constructor(filling: Filling, glazing: Glazing, glazingBead: GlazingBead, definingMullions?: DefiningMullion[]) {
        this.generatedId = RandomIdGenerator.generate();
        this.filling = filling;
        this.glazing = glazing;
        this.glazingBead = glazingBead;
        this.configurableAddonIds = [];
        this.grills = [];
        this.fields = [];
        this.definingMullions = definingMullions != undefined ? [...definingMullions] : [];
        this.mullionObliqueField = false;
        this.rectangularShape = true;
    }
}
