import {MultilanguageField} from '../../../supportedLanguages';

export class UpsellingProposition {
    id: number;
    name = new MultilanguageField();
    info = new MultilanguageField();
    active = true;
    sortIndex = 1;
    image: string;
    webshopChargeIds: number[] = [];
    warmFrames: boolean;
    glazingPackageIds: number[] = [];
    configurableAddonUpsellingSettingsIds: number[] = [];
    availableToNewSubsystems: boolean;
}
