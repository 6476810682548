import {
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    Optional,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';

export const TEXT_AREA_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextAreaComponent),
    multi: true
};

@Component({
    selector: 'app-text-area',
    templateUrl: './text-area.component.html',
    styleUrls: ['./text-area.component.css'],
    providers: [TEXT_AREA_VALUE_ACCESSOR]
})
export class TextAreaComponent extends AbstractInputComponent implements AfterContentInit {

    @Input()
    maxlength: number;

    @Input()
    icon: string;

    @Input()
    required: boolean;

    @Output()
    onIconClicked = new EventEmitter<void>();

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    @ViewChild('defaultIcon', {read: TemplateRef, static: true})
    defaultIcon: TemplateRef<any>;

    iconTemplate: TemplateRef<any>;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }

    protected resetTemplatesToDefault(): void {
        this.iconTemplate = this.defaultIcon;
    }

    protected registerTemplate(type: string, template: TemplateRef<any>): void {
        if (type === 'icon') {
            this.iconTemplate = template;
        }
    }

    iconClicked(event: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.onIconClicked.emit();
        }
    }
}
