import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {Subscription} from 'rxjs';
import {Permissions} from '../../auth/permission.service';
import {SidenavController} from '../../sidenav-controller';

@Component({
    selector: 'app-navigation-bar',
    templateUrl: './navigation-bar.component.html',
    styleUrls: ['./navigation-bar.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class NavigationBarComponent implements OnInit, OnDestroy {

    @Input()
    defaultTemplate: TemplateRef<any>;

    template: TemplateRef<any>;
    visible = true;

    private readonly subscriptions: Subscription[] = [];

    constructor(private sidenavController: SidenavController,
                public permissions: Permissions,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscriptions.push(this.sidenavController.visibilityChange.subscribe(visible => {
            this.visible = visible;
            this.changeDetector.detectChanges();
        }));
        this.subscriptions.push(this.sidenavController.contentChange.subscribe(template => {
            this.template = template != undefined ? template : this.defaultTemplate;
            this.changeDetector.detectChanges();
        }));
        this.template = this.defaultTemplate;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
