import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WindowDesignerCatalogDependentOptionsSet} from './window-designer-catalog-dependent-options-set';

@Injectable()
export class WindowDesignerCatalogDependentOptionsSetService implements CrudService<WindowDesignerCatalogDependentOptionsSet> {

    private static readonly API_URL = 'windowDesignerCatalogDependentOptionsSet';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WindowDesignerCatalogDependentOptionsSet>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);

        return this.http.get<Listing<object>>(WindowDesignerCatalogDependentOptionsSetService.API_URL, {params: params})
            .pipe(mapListingToJson(WindowDesignerCatalogDependentOptionsSet));
    }

    getItem(itemId: number): Observable<WindowDesignerCatalogDependentOptionsSet> {
        return this.http.get<object>(`${WindowDesignerCatalogDependentOptionsSetService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(WindowDesignerCatalogDependentOptionsSet));
    }

    addItem(item: WindowDesignerCatalogDependentOptionsSet): Observable<number> {
        return this.http.post<void>(WindowDesignerCatalogDependentOptionsSetService.API_URL, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(WindowDesignerCatalogDependentOptionsSetService.API_URL));
    }

    editItem(itemId: number, item: WindowDesignerCatalogDependentOptionsSet): Observable<number> {
        return this.http.put<void>(`${WindowDesignerCatalogDependentOptionsSetService.API_URL}/${itemId}`, item)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }

    deleteItem(itemId: number): Observable<void> {
        return this.http.delete(`${WindowDesignerCatalogDependentOptionsSetService.API_URL}/${itemId}`)
            .pipe(map(() => undefined));
    }

    validateGeneralData(item: WindowDesignerCatalogDependentOptionsSet): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowDesignerCatalogDependentOptionsSetService.API_URL}/validateGeneralData`, item));
    }

    validateDependentOptions(item: WindowDesignerCatalogDependentOptionsSet): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowDesignerCatalogDependentOptionsSetService.API_URL}/validateDependentOptions`, item));
    }
}
