<div class="new-form-60vw">
    <app-translation-fields [field]="item.name"
                            [maxlength]="100"
                            [required]="true" [validationErrors]="validationErrors" inputId="name"
                            label="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.NAME' | translate }}" validationKeyPrefix="name"></app-translation-fields>

    <app-translation-fields [field]="item.shortcut"
                            [maxlength]="100"
                            [required]="false" [validationErrors]="validationErrors" inputId="shortcut"
                            label="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.SHORTCUT' | translate }}" validationKeyPrefix="shortcut"></app-translation-fields>

    <div class="new-form-row">
        <app-input-text [(ngModel)]="item.symbol" [(validationMessageKey)]="validationErrors['symbol']"
                        [maxlength]="50" [required]="true"
                        inputId="symbol" label="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.SYMBOL' | translate }}">
        </app-input-text>
    </div>

    <div class="new-form-row">
        <app-select [(ngModel)]="item.descriptorLevel" [(validationMessageKey)]="validationErrors['descriptorLevel']"
                    [disabled]="item.id != null" [options]="levels | async"
                    [required]="true" inputId="descriptorLevel"
                    label="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.DESCRIPTOR_LEVEL.LABEL' | translate }}"></app-select>
    </div>

    <div *ngIf="item.id != null" class="new-form-row">
        <app-input-number [(ngModel)]="item.sortIndex"
                          [(validationMessageKey)]="validationErrors['sortIndex']"
                          [allowOnlyIntegers]="true"
                          [max]="9999" [min]="1" [required]="true" [step]="1"
                          inputId="sortIndex" label="{{ 'CONFIG_TYPE_DESCRIPTOR.FORM.SORT_INDEX' | translate }}"></app-input-number>
    </div>
</div>
