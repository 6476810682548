import {ExpresionOperands} from '../../../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityExpresionCalculator';
import {DecimalValidator, IntegerValidator, Validator} from "../../../../../../shared/validator/input-validator";
import {QuantityType} from '../../../../../../../window-designer/enums/QuantityType';
import {PositionListAddon} from "../../../../../../../window-designer/catalog-data/position-list-addon";

export class BulkAddonPositionValidator {

    private CORE_COLOR_NOT_EMPTY_KEY: string = 'error.bulkAddon.coreColor.not_empty';
    private CORE_COLOR_MUST_BE_COVERED_KEY: string = 'error.bulkAddon.coreColor.must_be_covered';
    private CORE_COLOR_CANNOT_BE_COVERED_KEY: string = 'error.bulkAddon.coreColor.cannot_be_covered';
    private QUANTITY_NOT_IN_RANGE_KEY: string = 'error.bulkAddon.quantity.not_in_range';

    private CORE_COLOR_FIELD: string = 'coreColor';
    private INSIDE_COVER_COLOR_FIELD: string = 'insideCoverColor';
    private OUTSIDE_COVER_COLOR_FIELD: string = 'outsideCoverColor';
    private QUANTITY_FIELD: string = 'quantity';

    public validate(selected: PositionListAddon[], bulkAddonsOnly: boolean, validationErrors: any): boolean {
        let valid: boolean = true;

        for (let elem of selected) {
            if (!this.validateColors(elem, bulkAddonsOnly, validationErrors)) {
                valid = false;
            }

            if (!Object.values(ExpresionOperands).includes(ExpresionOperands[elem.quantity as unknown as string])) {
                let quantityValidator: Validator = undefined;
                if (elem.quantityType === QuantityType.METER || elem.quantityType === QuantityType.SQUARE_METER) {
                    quantityValidator = new DecimalValidator();
                } else {
                    quantityValidator = new IntegerValidator();
                }

                if (elem.quantity == null || !quantityValidator.isValid(elem.quantity.toString()) ||
                    elem.quantity > 99999 || elem.quantity <= 0) {
                    valid = false;
                    validationErrors[elem.id + "_" + this.QUANTITY_FIELD] = this.QUANTITY_NOT_IN_RANGE_KEY;
                }

                if (elem.quantity == null && (!elem.defaultQuantity || !quantityValidator.isValid(elem.defaultQuantity.toString()) ||
                    elem.defaultQuantity > 99999 || elem.defaultQuantity < 0)) {
                    valid = false;
                    validationErrors[elem.id + "_" + this.QUANTITY_FIELD] = this.QUANTITY_NOT_IN_RANGE_KEY;
                }
            }
        }
        return valid;
    }

    private validateColors(element: PositionListAddon, bulkAddonsOnly: boolean, validationErrors: any): boolean {
        let valid: boolean = true;

        if (element.chooseColor && bulkAddonsOnly) {
            if (element.availableCoreColors != null && element.availableCoreColors.length > 0 && !element.selectedCoreColor) {
                validationErrors[element.id + "_" + this.CORE_COLOR_FIELD] = this.CORE_COLOR_NOT_EMPTY_KEY;
                valid = false;
            }

            if (element.selectedCoreColor && element.selectedCoreColor.mustBeCovered) {
                if (!element.selectedInsideColor) {
                    validationErrors[element.id + "_" + this.INSIDE_COVER_COLOR_FIELD] = this.CORE_COLOR_MUST_BE_COVERED_KEY;
                    valid = false;
                }
                if (!element.selectedOutsideColor) {
                    validationErrors[element.id + "_" + this.OUTSIDE_COVER_COLOR_FIELD] = this.CORE_COLOR_MUST_BE_COVERED_KEY;
                    valid = false;
                }
            }

            if (element.selectedCoreColor && element.selectedCoreColor.cannotBeCovered) {
                if (element.selectedInsideColor) {
                    validationErrors[element.id + "_" + this.INSIDE_COVER_COLOR_FIELD] = this.CORE_COLOR_CANNOT_BE_COVERED_KEY;
                    valid = false;
                }
                if (element.selectedOutsideColor) {
                    validationErrors[element.id + "_" + this.OUTSIDE_COVER_COLOR_FIELD] = this.CORE_COLOR_CANNOT_BE_COVERED_KEY;
                    valid = false;
                }
            }
        }

        return valid;
    }
}
