import {GateImagePaintParams} from './gate-image-paint-params';
import {GatePanelStampingType} from './gate-panel-stamping-type';
import {GatePrintAttributes} from './gate-print-constants';
import {MinMaxXY} from "../window-designer/drawing-util";

export class GatePanelPrintPainter {

    constructor(private readonly svg: Snap.Paper) {
    }

    private static isPanelDark(panelColor: string): boolean {
        const hex = panelColor.replace('#', '');
        const c_r = parseInt(hex.substring(0, 2), 16);
        const c_g = parseInt(hex.substring(2, 2 + 2), 16);
        const c_b = parseInt(hex.substring(4, 4 + 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        return brightness < 100;
    }

    paint(params: GateImagePaintParams, panelsBox: MinMaxXY): void {
        const panels = this.svg.g();
        panels.addClass('gate-panels');

        const stampingEffect = this.svg.filter(`<feGaussianBlur id="innerShadow" stdDeviation="4" result="blur" />`);

        const panelTexture = this.svg.filter(`<feTurbulence type="fractalNoise" baseFrequency='0.02 0.1' result='noise' numOctaves="3" />`
            + `<feDiffuseLighting in='noise' lighting-color='${params.panelColor}' surfaceScale='2'>`
            + `  <feDistantLight azimuth='30' elevation='65' />`
            + `</feDiffuseLighting>`
            + `<feMerge>`
            + `  <feMergeNode/>`
            + `  <feMergeNode in="SourceGraphic"></feMergeNode>`
            + `</feMerge>`);
        panelTexture.attr({
            x: '0%',
            y: '0%',
            width: '100%',
            height: '100%',
            filterUnits: 'objectBoundingBox'
        });
        let remainingHeight = params.height;
        while (params.panelHeight <= remainingHeight) {
            panels.add(this.paintSegment(panelsBox.minY + remainingHeight, params.width, params.panelHeight, panelsBox,
                params.panelStamping, params.panelColor, stampingEffect, panelTexture));
            remainingHeight -= params.panelHeight;
        }
        if (remainingHeight > 0) {
            const partialSegment = this.paintSegment(panelsBox.minY + remainingHeight, params.width, params.panelHeight, panelsBox,
                params.panelStamping, params.panelColor, stampingEffect, panelTexture);
            panels.add(partialSegment);
        }
        // clip top panel to correct height
        const clip = this.svg.rect(panelsBox.minX - 1, panelsBox.minY - 1, params.width + 2, params.height + 2);
        clip.attr({
            strokeWidth: 2
        });
        panels.attr({
            clipPath: clip
        });
    }

    private paintSegment(bottomY: number, width: number, height: number, panelsBox: MinMaxXY, appearance: GatePanelStampingType,
                         color: string, stampingEffect: Snap.Element, texture: Snap.Element): Snap.Element {
        const singlePanelGroup = this.svg.g();
        singlePanelGroup.addClass('gate-panel');
        const panel = this.svg.rect(panelsBox.minX, bottomY - height, width, height);
        const darkPanel: boolean = GatePanelPrintPainter.isPanelDark(color);
        panel.attr(GatePrintAttributes.panel(color, darkPanel));
        singlePanelGroup.add(panel);
        switch (appearance) {
            case GatePanelStampingType.ALUTECH_L:
            case GatePanelStampingType.BRAMTECH_EXTRA:
                // plain panel, nothing to draw
                break;
            case GatePanelStampingType.ALUTECH_M:
            case GatePanelStampingType.BRAMTECH_ONELINE: {
                const midLine = this.svg.rect(panelsBox.minX, bottomY - height / 2, width, 1);
                midLine.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                singlePanelGroup.add(midLine);
                break;
            }
            case GatePanelStampingType.ALUTECH_S:
            case GatePanelStampingType.BRAMTECH_MULTILINE:
            case GatePanelStampingType.BRAMTECH_MULTI_INDUSTRIAL:
            case GatePanelStampingType.BRAMTECH_MICRO_INDUSTRIAL: {
                let linesCount = (() => {
                    switch (appearance) {
                        case GatePanelStampingType.ALUTECH_S:
                            return Math.round(height / 125) - 1;
                        case GatePanelStampingType.BRAMTECH_MULTILINE:
                        case GatePanelStampingType.BRAMTECH_MULTI_INDUSTRIAL:
                            return 5;
                        case GatePanelStampingType.BRAMTECH_MICRO_INDUSTRIAL:
                            return 11;
                        default:
                            return 0;
                    }
                })();
                const lineSpacing = height / (linesCount + 1);
                while (linesCount > 0) {
                    const line = this.svg.rect(panelsBox.minX, bottomY - linesCount * lineSpacing, width, 1);
                    line.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(line);
                    --linesCount;
                }
                break;
            }
            case GatePanelStampingType.ALUTECH_RECTS: {
                const distanceFromSideEdge = 250;
                const distanceFromTopAndBottom = 75;
                const innerRectOffset = 75;
                const rectWidth = 500;
                const rectHeight = height - distanceFromTopAndBottom * 2;
                const usableWidth = width - distanceFromSideEdge * 2;
                const minSpaceBetween = 125;
                const numRects = Math.floor((usableWidth - rectWidth) / (rectWidth + minSpaceBetween)) + 1;
                const spacingBetween = (usableWidth - numRects * rectWidth) / (numRects - 1);
                let innerRectHeight = rectHeight - innerRectOffset * 2;
                if (spacingBetween === Infinity || Number.isNaN(spacingBetween) || innerRectHeight < 1) {
                    break;
                }
                const baselineX = panelsBox.minX + distanceFromSideEdge;
                for (let i = 0; i < numRects; ++i) {
                    const outerRect = this.svg.rect(baselineX + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - rectHeight,
                        rectWidth, rectHeight);
                    outerRect.attr(GatePrintAttributes.panel(color, darkPanel));
                    outerRect.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(outerRect);

                    const innerRect = this.svg.rect(baselineX + innerRectOffset + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - rectHeight + innerRectOffset,
                        rectWidth - innerRectOffset * 2, innerRectHeight);
                    innerRect.attr(GatePrintAttributes.panel(color, darkPanel));
                    innerRect.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(innerRect);
                }
                break;
            }
            case GatePanelStampingType.BRAMTECH_QUADRO: {
                const distanceFromSideEdge = 150;
                const distanceFromTopAndBottom = 50;
                const innerRectOffset = 25;
                const rectWidth = 500;
                const rectHeight = height - distanceFromTopAndBottom * 2;
                const usableWidth = width - distanceFromSideEdge * 2;
                const minSpaceBetween = 50;
                const numRects = Math.floor((usableWidth - rectWidth) / (rectWidth + minSpaceBetween)) + 1;
                const spacingBetween = (usableWidth - numRects * rectWidth) / (numRects - 1);
                let midRectHeightHeight = rectHeight - innerRectOffset * 2;
                if (spacingBetween === Infinity || Number.isNaN(spacingBetween) || midRectHeightHeight < 1) {
                    break;
                }
                const baselineX = panelsBox.minX + distanceFromSideEdge;
                for (let i = 0; i < numRects; ++i) {
                    const outerRect = this.svg.rect(baselineX + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - rectHeight,
                        rectWidth, rectHeight);
                    outerRect.attr(GatePrintAttributes.panel(color, darkPanel));
                    outerRect.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(outerRect);

                    const midRect = this.svg.rect(baselineX + innerRectOffset + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - rectHeight + innerRectOffset,
                        rectWidth - innerRectOffset * 2, midRectHeightHeight);
                    midRect.attr(GatePrintAttributes.panel(color, darkPanel));
                    midRect.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(midRect);

                    const innerRect = this.svg.rect(baselineX + (innerRectOffset * 2) + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - rectHeight + (innerRectOffset * 2),
                        rectWidth - innerRectOffset * 4, rectHeight - innerRectOffset * 4);
                    innerRect.attr(GatePrintAttributes.panel(color, darkPanel));
                    innerRect.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(innerRect);

                    const topLeftLine = this.svg.line(baselineX + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - rectHeight,
                        baselineX + (innerRectOffset * 2) + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - rectHeight + (innerRectOffset * 2));
                    topLeftLine.attr(GatePrintAttributes.panel(color, darkPanel));
                    topLeftLine.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(topLeftLine);

                    const topRightLine = this.svg.line(baselineX + i * (rectWidth + spacingBetween) + rectWidth,
                        bottomY - distanceFromTopAndBottom - rectHeight,
                        baselineX - (innerRectOffset * 2) + i * (rectWidth + spacingBetween) + rectWidth,
                        bottomY - distanceFromTopAndBottom - rectHeight + (innerRectOffset * 2));
                    topRightLine.attr(GatePrintAttributes.panel(color, darkPanel));
                    topRightLine.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(topRightLine);

                    const bottomLeftLine = this.svg.line(baselineX + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom,
                        baselineX + (innerRectOffset * 2) + i * (rectWidth + spacingBetween),
                        bottomY - distanceFromTopAndBottom - (innerRectOffset * 2));
                    bottomLeftLine.attr(GatePrintAttributes.panel(color, darkPanel));
                    bottomLeftLine.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(bottomLeftLine);

                    const bottomRightLine = this.svg.line(baselineX + i * (rectWidth + spacingBetween) + rectWidth,
                        bottomY - distanceFromTopAndBottom,
                        baselineX - (innerRectOffset * 2) + i * (rectWidth + spacingBetween) + rectWidth,
                        bottomY - distanceFromTopAndBottom - (innerRectOffset * 2));
                    bottomRightLine.attr(GatePrintAttributes.panel(color, darkPanel));
                    bottomRightLine.attr(GatePrintAttributes.panelStampingLine(stampingEffect, darkPanel));
                    singlePanelGroup.add(bottomRightLine);
                }
                break;
            }
            default:
                panel.attr(GatePrintAttributes.panelTexture(texture));
                break;
        }
        return singlePanelGroup;
    }
}
