export class CountsRequest {
    offerCountsByStatus: { [status: string]: boolean };
    complaintCountsByStatus: { [status: string]: boolean };
    paymentPackageCountsByStatus: { [status: string]: boolean };

    constructor() {
        this.offerCountsByStatus = {};
        this.complaintCountsByStatus = {};
        this.paymentPackageCountsByStatus = {};
    }
}
