import {AreaSpecification} from "../../../../window-designer/drawing-data/AreaSpecification";

export class GlassChangeEvent {
    glassPosition: number;
    newValue: number;
    area: AreaSpecification;
    subwindowId: string;

    constructor(    glassPosition: number,
    newGlassId: number,
    area: AreaSpecification,
    subwindowId?: string) {
        this.glassPosition = glassPosition;
        this.newValue = newGlassId;
        this.area = area;
        this.subwindowId = subwindowId;
    }
}