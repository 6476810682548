import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper, FileState} from "../../../common/dataServiceHelper";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing} from "../../../common/crud-common/crudItemList";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {ListOfIds} from "../../ListOfIds";
import {CatalogItemName} from "../../../common/crud-common/catalog-item-name";
import {GateWall} from "./gate-wall";
import {GatePanelType} from "../gate-panel-type/gate-panel-type";

@Injectable()
export class GateWallService implements CrudService<GateWall> {

    private static readonly API_URL = 'gateWall';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    addItem(item: GateWall, lowResolutionPreviewImage?: FileState, highResolutionPreviewImage?: FileState): Observable<number> {
        return this.saveGateWall(item);
    }

    editItem(itemId: number, item: GateWall, lowResolutionPreviewImage?: FileState,
             highResolutionPreviewImage?: FileState): Observable<number> {
        return this.saveGateWall(item);
    }

    getItem(itemId: number): Observable<GateWall> {
        return this.http.get<GateWall>(`${GateWallService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<GateWall>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<GateWall>>(GateWallService.API_URL, {params: params});
    }

    saveGateWall(item: GateWall, lowResolutionPreviewImage?: FileState,
                 highResolutionPreviewImage?: FileState): Observable<number> {
        let formData = this.prepareFormData(item, lowResolutionPreviewImage, highResolutionPreviewImage);
        return this.http.post(GateWallService.API_URL, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(GateWallService.API_URL, response)));
    }

    copy(id: number, item: GateWall, lowResolutionPreviewImage?: FileState, highResolutionPreviewImage?: FileState) {
        let formData = this.prepareFormData(item, lowResolutionPreviewImage, highResolutionPreviewImage);
        return this.http.post(`${GateWallService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(GateWallService.API_URL, response)));
    }

    validate(item: GateWall): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${GateWallService.API_URL}/validate`, item));
    }

    editLinks(gateWallId: number, systemIds: ListOfIds): Observable<number> {
        return this.http.put<void>(`${GateWallService.API_URL}/link/${gateWallId}`, systemIds).pipe(map(() => 0));
    }

    getLinkedModels(gateWallId: number): Observable<number[]> {
        return this.http.get<number[]>(`${GateWallService.API_URL}/linked/${gateWallId}`);
    }

    getGateWallsForGateSystem(gateSystemId: number, selectedIds: number[] = null): Observable<GateWall[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        return this.http.get<object[]>(`${GateWallService.API_URL}/forGateSystem/${gateSystemId}`, {params: params})
            .pipe(map(result => result.map(GateWall.fromJSON)));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${GateWallService.API_URL}/names`, {params: params});
    }

    private prepareFormData(item: GateWall, lowResolutionPreviewImage?: FileState, highResolutionPreviewImage?: FileState): FormData {
        const formData = new FormData();
        formData.append('gateWallDto', new Blob([JSON.stringify(item)], {type: 'application/json'}));
        if (this.dataServiceHelper.isFileSaveNeeded(lowResolutionPreviewImage)) {
            formData.append('lowResolutionPreviewImage', lowResolutionPreviewImage.file);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(highResolutionPreviewImage)) {
            formData.append('highResolutionPreviewImage', highResolutionPreviewImage.file);
        }
        return formData;
    }

    getLowResolutionPreviewImage(gateWallId: number): Observable<string> {
        return this.http.get(`${GateWallService.API_URL}/${gateWallId}/lowResolutionPreviewImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getLowResolutionPreviewImageAsFile(gateWallId: number): Observable<File> {
        return this.http.get(`${GateWallService.API_URL}/${gateWallId}/lowResolutionPreviewImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getHighResolutionPreviewImage(gateWallId: number): Observable<string> {
        return this.http.get(`${GateWallService.API_URL}/${gateWallId}/highResolutionPreviewImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getHighResolutionPreviewImageAsFile(gateWallId: number): Observable<File> {
        return this.http.get(`${GateWallService.API_URL}/${gateWallId}/highResolutionPreviewImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }
}
