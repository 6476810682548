import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {ComplaintService} from "../../../../complaint/complaint.service";
import {RemovePositionsResult} from '../../position.service';
import {PositionSortGroup} from '../position';

@Component({
    selector: 'app-delete-complaint-position-dialog',
    templateUrl: './delete-complaint-position-dialog.component.html',
    styleUrls: ['../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteComplaintPositionDialogComponent {

    @Input()
    itemsToRemove: number[];

    @Output()
    onSuccess: EventEmitter<RemovePositionsResult> = new EventEmitter();

    @Output()
    onError: EventEmitter<any> = new EventEmitter();

    @Output()
    onCancel: EventEmitter<any> = new EventEmitter();

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private complaintService: ComplaintService) {
    }

    remove() {
        this.complaintService.deleteComplaintPositions(this.itemsToRemove).subscribe({
            next: (affectedSuppliers) => {
                this.dialogHideHelper.call(() => this.onSuccess.emit({
                    affectedSuppliers: affectedSuppliers,
                    affectedSortGroups: new Set<PositionSortGroup>()
                }));
            },
            error: error => {
                this.dialogHideHelper.call(() => this.onError.emit(error));
            }
        });
    }

    cancel() {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }
}
