import {Component, EventEmitter, Input, Output} from '@angular/core';
import {COMMON_INPUT_LENGTH_LIMITS} from "../../../../common/crud-common/common-input-length-limits";
import {ValidationErrors} from '../../../../common/validation-errors';
import {UpsellingProposition} from "../upselling-proposition";

@Component({
    selector: 'app-upselling-proposition-form',
    templateUrl: './upselling-proposition-form.component.html'
})
export class UpsellingPropositionFormComponent {

    @Input() item: UpsellingProposition;
    @Input() validationErrors: ValidationErrors;
    @Input() file: File;
    @Output() readonly fileChange = new EventEmitter<File>();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
