<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.SUBSYSTEM_INFO' | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-600">
            <div class="new-form-row">
                <h3>{{ "SETTINGS.SECTION.SUBSYSTEM_INFO" | translate }}</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="name" [ngModel]="item.name" label="{{ 'SUBSYSTEM-DETAILS.FORM.NAME' | translate }}"
                                [disabled]="true" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="symbol" [ngModel]="item.symbol" label="{{ 'SUBSYSTEM-DETAILS.FORM.SYMBOL' | translate }}"
                                [disabled]="true" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="phoneNumber" [(ngModel)]="item.phoneNumber"
                                label="{{ 'SUBSYSTEM-DETAILS.FORM.PHONE_NUMBER' | translate }}"
                                [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})" [maxlength]="50"
                                [(validationMessageKey)]="validationErrors['phoneNumber']" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="email" [(ngModel)]="item.email" [required]="true"
                                label="{{ 'SUBSYSTEM-DETAILS.FORM.EMAIL' | translate }}"
                                [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})" [maxlength]="255"
                                [(validationMessageKey)]="validationErrors['email']"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-select inputId="defaultCurrency" [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})"
                            label="{{ 'SUBSYSTEM-DETAILS.FORM.DEFAULT_CURRENCY' | translate }}"
                            [options]="currencyOptions | async" [ngModel]="item.defaultCurrency"
                            (ngModelChange)="onDefaultCurrencyChange($event)" [required]="true"></app-select>
            </div>

            <div class="new-form-row" *ngIf="item.defaultCurrency !== 'PLN'">
                <app-input-number inputId="currentExchangeRate" disabled="true"
                                  label="{{ 'OFFER.CREATE_OFFER.FORM.CURRENT_EXCHANGE_RATE' | translate }}"
                                  [(ngModel)]="currentExchangeRateForDefaultCurrency"></app-input-number>
            </div>
            <div class="new-form-row" *ngIf="item.defaultCurrency !== 'PLN'">
                <app-input-number inputId="subsystemManualExchangeRate" [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})"
                                  label="{{ 'OFFER.CREATE_OFFER.FORM.SUBSYSTEM_MANUAL_EXCHANGE_RATE' | translate }}"
                                  [(validationMessageKey)]="validationErrors['manualExchangeRate']"
                                  [(ngModel)]="item.manualExchangeRate"></app-input-number>
            </div>
            <div class="new-form-row" *ngIf="item.defaultCurrency !== 'PLN'">
                <app-input-number inputId="exchangeRateSafeChangeThreshold" [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})"
                                  label="{{ 'OFFER.CREATE_OFFER.FORM.EXCHANGE_RATE_SAFE_CHANGE_THRESHOLD' | translate }}"
                                  [(validationMessageKey)]="validationErrors['exchangeRateSafeChangeThreshold']"
                                  [(ngModel)]="item.exchangeRateSafeChangeThreshold"></app-input-number>
            </div>

            <div *ngIf="item.webShopEnabled" class="new-form-row">
                <app-input-text inputId="account" [(ngModel)]="item.account"
                                label="{{ 'SUBSYSTEM-DETAILS.FORM.ACCOUNT' | translate }}"
                                [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})" [maxlength]="20"
                                [(validationMessageKey)]="validationErrors['account']"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="clientManager" [ngModel]="getFullManagerName()" label="{{ 'SUBSYSTEM-DETAILS.FORM.CLIENT_MANAGER_LOGIN' | translate }}"
                                [disabled]="true" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-number inputId="vatSell" [(ngModel)]="item.vatSell" [min]="0"
                                  label="{{ 'SUBSYSTEM-DETAILS.FORM.VAT_SELL' | translate }}"
                                  [(validationMessageKey)]="validationErrors['vatSell']"
                                  [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})" [maxlength]="3"
                                  [required]="true"></app-input-number>
            </div>

            <div *ngIf="displayEnableUpsellingCheckbox" class="new-form-row">
                <app-checkbox inputId="enableUpselling"
                              label="{{ 'SUBSYSTEM-DETAILS.FORM.ENABLE_UPSELLING' | translate }}"
                              [(ngModel)]="item.enableUpselling"></app-checkbox>
            </div>

            <div class="new-form-row">
                <app-checkbox inputId="enableWindowUpselling"
                              label="{{ 'SUBSYSTEM-DETAILS.FORM.ENABLE_WINDOW_UPSELLING' | translate }}"
                              [(ngModel)]="item.enableWindowUpselling"
                              [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})"></app-checkbox>
            </div>

            <div class="new-form-row">
                <app-checkbox inputId="enableRot"
                              label="{{ 'SUBSYSTEM-DETAILS.FORM.ENABLE_ROT' | translate }}"
                              [(ngModel)]="item.enableRot"
                              [disabled]="!isPermitted({roles:['ROLE_OPERATOR']})"></app-checkbox>
            </div>


        </div>
        <div class="new-form-600">

            <div class="new-form-row blue-text-button" *ngIf="isPermitted({roles:['ROLE_OPERATOR']})">
                <app-simple-button buttonId="subsystem-address-select-modify"
                                   label="{{ 'SUBSYSTEM-DETAILS.FORM.MODIFY_ADDRESS' | translate }}"
                                   (onClick)="showAddressSelectionDialog('modify')"></app-simple-button>
            </div>

            <div class="new-form-row">
                <h3>{{ "SUBSYSTEM-DETAILS.FORM.CORRESPONDENCE_ADDRESS" | translate }}</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="correspondenceAddress.street"
                                [ngModel]="correspondenceAddress?.address.street"
                                label="{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}" [required]="true"
                                [disabled]="true" [maxlength]="100"
                                [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="correspondenceAddress.city" [ngModel]="correspondenceAddress?.address.city"
                                label="{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}" [required]="true"
                                [disabled]="true" [maxlength]="50"
                                [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-select inputId="correspondenceAddress.country" [ngModel]="correspondenceAddress?.address.country"
                            label="{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}" [disabled]="true"
                            [options]="countries | async" [required]="true"
                            [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"></app-select>
            </div>

            <div class="new-form-row">
                <app-input-zip inputId="correspondenceAddress.zip" [ngModel]="correspondenceAddress?.address.zip"
                               label="{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}" [required]="true"
                               [disabled]="true" [country]="correspondenceAddress?.address.country"
                               [(validationMessageKey)]="validationErrors['correspondenceAddress']"></app-input-zip>
            </div>

            <div class="new-form-row blue-text-button" *ngIf="isPermitted({roles:['ROLE_OPERATOR']})">
                <app-simple-button buttonId="subsystem-address-select-correspondence"
                                   label="{{ 'SUBSYSTEM-DETAILS.FORM.SELECT_ADDRESS' | translate }}"
                                   (onClick)="showAddressSelectionDialog('correspondence')"></app-simple-button>
            </div>

            <div class="new-form-row">
                <h3>{{ "SUBSYSTEM-DETAILS.FORM.DELIVERY_ADDRESS" | translate }}</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="deliveryStreet" [ngModel]="deliveryAddress?.address.street"
                                label="{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}" [disabled]="true"
                                [maxlength]="100"
                                [hasValidationError]="validationErrors['deliveryAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="deliveryCity" [ngModel]="deliveryAddress?.address.city"
                                label="{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}" [disabled]="true" [maxlength]="50"
                                [hasValidationError]="validationErrors['deliveryAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-select inputId="deliveryCountry" [ngModel]="deliveryAddress?.address.country"
                            label="{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}" [disabled]="true"
                            [options]="countries | async" [allowSelectingNone]="true"
                            [hasValidationError]="validationErrors['deliveryAddress'] != undefined"></app-select>
            </div>

            <div class="new-form-row">
                <app-input-zip inputId="deliveryZip" [ngModel]="deliveryAddress?.address.zip"
                               label="{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}" [disabled]="true"
                               [country]="deliveryAddress?.address.country"
                               [(validationMessageKey)]="validationErrors['deliveryAddress']"></app-input-zip>
            </div>

            <div class="new-form-row blue-text-button" *ngIf="isPermitted({roles:['ROLE_OPERATOR']})">
                <app-simple-button buttonId="subsystem-address-select-delivery"
                                   label="{{ 'SUBSYSTEM-DETAILS.FORM.SELECT_ADDRESS' | translate }}"
                                   (onClick)="showAddressSelectionDialog('delivery')"></app-simple-button>
            </div>
        </div>
    </div>

    <div class="settings-footer">
        <div class="settings-footer-right" *ngIf="isPermitted({roles:['ROLE_OPERATOR']})">
            <app-simple-button buttonId="saveSubsystemButton" label="{{ 'GENERAL.SAVE' | translate }}" icon="check"
                               type="main-action" [size]="40" (onClick)="saveChanges()"></app-simple-button>
        </div>
    </div>
</div>
<app-subsystem-address-selection-dialog *ngIf="addressSelectionDialogType != 'none'" [addresses]="addresses" (addressesChange)="handleAddressesChange($event)"
                                        [visible]="true"
                                        (visibleChange)="handleAddressSelectionVisibilityChange($event)"
                                        [selectedAddress]="addressSelectionDialogType == 'correspondence' ? correspondenceAddress : deliveryAddress"
                                        (selectedAddressChange)="handleAddressSelected($event)" [canEdit]="addressSelectionDialogType === 'modify'"
                                        [selectable]="addressSelectionDialogType != 'modify'"></app-subsystem-address-selection-dialog>
