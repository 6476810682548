import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {Permissions} from "../../../auth/permission.service";
import {Country} from '../../../common/enums/country';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ClientGroup} from '../../client-group/ClientGroup';
import {Client} from "../client";
import {SimpleProfitMarginSource} from '../simple-profit-margin-source/simple-profit-margin-source';

@Component({
    selector: 'app-client-details-form',
    templateUrl: './client-details-form.component.html',
    providers: [TranslatedSelectItemService]
})
export class ClientDetailsFormComponent implements OnInit {

    @Input() client: Client;
    @Input() validationErrors: any;
    @Input() existingGroups: ClientGroup[];
    @Input() selectCurrencies: SelectItem[];
    @Input() selectUsers: SelectItem[];
    @Input() suppliers: SupplierInterface[];
    @Input() groupSimpleProfitMarginSource?: SimpleProfitMarginSource;
    @Input() simpleProfitMarginSource?: SimpleProfitMarginSource;

    @Output() readonly onClientGroupChanged = new EventEmitter<number>();

    countries: Observable<SelectItem[]>;

    constructor(private permissions: Permissions,
                private translatedSelectItemService: TranslatedSelectItemService) {
    }

    ngOnInit(): void {
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
    }

    clientGroupChanged(event: ClientGroup) {
        this.client.groupId = event != undefined ? event.id : undefined;
        this.onClientGroupChanged.emit(this.client.groupId);
    }

    isNotOperatorOrSeller(): boolean {
        return !this.permissions.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']});
    }

    isNotOperator(): boolean {
        return !this.permissions.isPermitted({roles: ['ROLE_OPERATOR']});
    }

    get selectedClientGroup(): ClientGroup {
        if (this.existingGroups == undefined || this.client == undefined) {
            return undefined;
        }
        return this.existingGroups.find(clientGroup => this.client.groupId === clientGroup.id);
    }

    clientGroupOptionFormatter = (clientGroup: ClientGroup): SelectItem => {
        return {label: clientGroup.name, value: clientGroup};
    }

    clientGroupKey = (clientGroup: ClientGroup | number): number => {
        if (clientGroup == undefined) {
            return undefined;
        }
        if (typeof clientGroup === 'number') {
            return clientGroup;
        }
        return clientGroup.id;
    }
}
