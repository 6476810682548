<div *ngIf="active">
    <p-dataTable #dt [seqNum]="seqNum" [value]="itemList" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions" [paginator]="true" [lazy]="true"
                 [totalRecords]="totalRecords" selectionMode="single" (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true" [selection]="selectedItem"
                 (selectionChange)="selectedItemChange.emit($event)" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="name" header="{{ 'PRICESHEET.FORM.NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>
        <p-column field="type" header="{{ 'PRICESHEET.FORM.TYPE' | translate }}" [sortable]="false" [filter]="false">
            <ng-template let-item="rowData" pTemplate="body">
                {{('PRICESHEET.TYPES.' + item.type) | translate}}
            </ng-template>
        </p-column>
        <p-column field="validFrom" header="{{ 'PRICESHEET.FORM.VALID_FROM' | translate }}" [filter]="showFilters"
                  [sortable]="true" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.validFrom | momentCurrentLang | amDateFormat: 'L LT' }}
            </ng-template>
        </p-column>
        <p-column field="lastModifiedDate" header="{{ 'PRICESHEET.LAST_MODIFICATION_DATE' | translate }}" [filter]="showFilters"
                  [sortable]="true" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.lastModifiedDate | momentCurrentLang | amDateFormat: 'L LT' }}
            </ng-template>
        </p-column>
        <p-column field="lastNotificationDate" header="{{ 'PRICESHEET.LAST_NOTIFICATION_DATE' | translate }}" [filter]="showFilters"
                  [sortable]="true" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.lastNotificationDate | momentCurrentLang | amDateFormat: 'L LT' }}
            </ng-template>
        </p-column>
        <p-column field="active" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter" header="{{ 'PRICESHEET.FORM.STATUS' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{(item.active ? 'PRICESHEET.FORM.ACTIVE' : 'PRICESHEET.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
