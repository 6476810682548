import {Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, from, Observable} from 'rxjs';
import {mergeAll, reduce} from 'rxjs/operators';
import {ValidationErrors} from '../../../../common/validation-errors';
import {WindowDependentOptionField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {WindowEditorFieldContentProvider} from '../../../offer/window-editor/window-editor-field-content-provider';
import {WindowDependentOptionFieldUsage} from "../../catalog-field-usage";
import {WindowDependentOptionHelperInterface} from "../../catalog-field-usage-helper-interfaces";
import {ItemForCatalogLinking} from "../../single-system-checkbox-crud/item-for-catalog-linking";
import {WindowDesignerCatalogDependentOptionsSet} from '../window-designer-catalog-dependent-options-set';
import {WindowDesignerCatalogDependentOptionsSetComponentConfiguration} from "../window-designer-catalog-dependent-options-set.component";

@Component({
    selector: 'app-window-designer-catalog-dependent-option-form',
    templateUrl: './window-designer-catalog-dependent-option-form.component.html'
})
export class WindowDesignerCatalogDependentOptionFormComponent implements WindowDependentOptionHelperInterface {

    @Input()
    configuration: WindowDesignerCatalogDependentOptionsSetComponentConfiguration;

    @Input()
    item: WindowDesignerCatalogDependentOptionsSet;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    windowSystems: Observable<ItemForCatalogLinking[]>;

    @Input()
    editPermits: FieldLimitation[] = [];

    fieldUsage: WindowDependentOptionFieldUsage;
    WindowDependentOptionField = WindowDependentOptionField;

    constructor(private readonly translate: TranslateService,
                private readonly windowEditorFieldContentProvider: WindowEditorFieldContentProvider) {
        this.fieldUsage = new WindowDependentOptionFieldUsage(this);
    }

    handleWindowSystemChange(windowSystemIds: number[]): void {
        this.item.windowSystemId = windowSystemIds;
        this.validationErrors['windowSystemId'] = undefined;
        if (windowSystemIds.length === 0) {
            return;
        }
        from(windowSystemIds.map(id => forkJoin(
            this.configuration.loadCatalogData(this.windowEditorFieldContentProvider, id)
        ))).pipe(
            mergeAll(1),
            reduce((acc, value) => {
                return this.configuration.mergeCatalogData(this.windowEditorFieldContentProvider, acc, value);
            })
        ).subscribe(catalogData => {
            this.configuration.storeCatalogData(this.windowEditorFieldContentProvider, catalogData);
        });
    }
}
