import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewChild,} from '@angular/core';
import {forkJoin, of} from "rxjs";
import {mergeMap} from "rxjs/operators";
import {BlockUiController} from "../../../block-ui/block-ui-controller";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ConfigEditorFieldContentProvider} from "../../offer/config-editor/config-editor-field-content-provider";
import {ConfigEditorFieldStates} from "../../offer/config-editor/config-editor-field-states";
import {ConfigSidebarFieldImageService} from "../../offer/config-editor/config-sidebar/config-sidebar-field-image.service";
import {ConfigSidebarComponent} from "../../offer/config-editor/config-sidebar/config-sidebar.component";
import {SidebarFieldChangeEvent} from "../../offer/gate-editor/gate-sidebar/gate-sidebar.component";
import {CatalogItemType} from "../../offer/window-editor/sidebar/pricing/PricingItem";
import {SystemDefaultsService} from "../../offer/window-editor/system-defaults.service";
import {SubsystemGroupService} from "../../subsystem-group/subsystem-group.service";
import {SubsystemService} from "../../subsystem/subsystem.service";
import {AddonCategoryGroup} from "../../window-system/addon-category-group/addon-category-group";
import {AddonCategoryGroupService} from "../../window-system/addon-category-group/addon-category-group.service";
import {AddonsService} from "../../window-system/addons/addons.service";
import {ColorService} from "../../window-system/color/color.service";
import {
    ConfigDesignerCatalogDependentOptionService
} from "../../window-system/config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option.service";
import {ConfigSystem} from "../../window-system/config-system/config-system";
import {ConfigSystemService} from "../../window-system/config-system/config-system.service";
import {MaterialColorImages} from "../../window-system/material/material-color-images/material-color-images";
import {MaterialService} from "../../window-system/material/material.service";
import {ConfigSystemDefaults} from "../config-system-defaults/config-system-defaults";
import {ConfigSystemDefaultsState} from "../system-defaults/system-default-state";
import {SystemDefaultsComponent} from "../system-defaults/system-defaults.component";

@Component({
    selector: 'app-new-configurable-addon-defaults',
    templateUrl: '../system-defaults/config-system-defaults.component.html',
    styleUrls: ['../system-defaults/system-defaults.component.css'],
    providers: [SystemDefaultsService, ConfigEditorFieldContentProvider, ConfigSidebarFieldImageService, ConfigSystemService,
        ColorService, MaterialService, AddonCategoryGroupService, ConfigDesignerCatalogDependentOptionService,
        AddonsService, SubsystemGroupService, SubsystemService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewConfigurableAddonDefaultsComponent extends SystemDefaultsComponent<ConfigSystem, ConfigSystemDefaultsState> implements OnInit {

    private static readonly CONFIG_DEFAULTS_LOAD = 'CONFIG_DEFAULTS_LOAD';

    fields: ConfigEditorFieldStates;

    systemLinksLoaded = false;
    colorMaterialLinks: MaterialColorImages[] = [];
    addonCategoryGroups: AddonCategoryGroup[];

    readonly editorMock: {selectedConfigSystem: ConfigSystem} = {selectedConfigSystem: null};

    @ViewChild('sidebar', {static: false})
    sidebar: ConfigSidebarComponent;

    constructor(injector: Injector,
                private systemService: ConfigSystemService,
                private readonly editorFieldContentProvider: ConfigEditorFieldContentProvider,
                private readonly blockUiController: BlockUiController,
                private readonly materialService: MaterialService) {
        super(injector);
        this.fields = new ConfigEditorFieldStates(undefined, editorFieldContentProvider, this.editorMock);
        this.editorFieldContentProvider.filteringEnabled = true;
        this.itemType = CatalogItemType.CONFIG_SYSTEM;
    }

    ngOnInit() {
        this.state = Object.assign(new ConfigSystemDefaultsState(), {
            value: new ConfigSystemDefaults(),
            offerDefaultExisting: false,
            clientDefaultExisting: false,
            clientGroupDefaultExisting: false,
            subsystemDefaultExisting: false,
            globalDefaultExisting: false
        });
        this.systemService.getItems(undefined, undefined, {active: {value: 'true'}}, 'sortIndex', 1)
            .subscribe(data => {
                this.systems = data.data;
                this.handleSystemChange(this.systems[0]);
            });
        super.ngOnInit();
    }

    handleSidebarFieldChange(event: SidebarFieldChangeEvent): void {
        this.editorFieldContentProvider.notifyFieldChanged(event.field, event.newValue);
    }

    handleSystemChange(system: ConfigSystem): void {
        this.selectedSystem = system;
        this.editorMock.selectedConfigSystem = system;
        if (system != undefined) {
            this.blockUiController.block(NewConfigurableAddonDefaultsComponent.CONFIG_DEFAULTS_LOAD);
            this.systemDefaultsService.getDefaultsForConfig(system.id, undefined).pipe(
                mergeMap(defaults => {
                    this.state = defaults;
                    this.editorFieldContentProvider.storeSelectedValues(defaults.value);
                    return forkJoin({
                        dummy: of(`fix: TS2339: Property 'colors' does not exist on type 'never'.`),
                        ...this.editorFieldContentProvider.getCatalogDataSource(system.id, this.state.value, false),
                        colorMaterialLinks: this.materialService.getColorLinksForConfigSystem(system.id, null)
                    });
                })
            ).subscribe(catalogData => {
                this.editorFieldContentProvider.initCatalog(catalogData);
                this.colorMaterialLinks = catalogData.colorMaterialLinks;
                this.systemLinksLoaded = true;
                this.addonCategoryGroups = catalogData.addonCategoryGroups;
                setTimeout(() => this.sidebar.setMaterialColorThumbnail(), 0);
                this.blockUiController.unblock(NewConfigurableAddonDefaultsComponent.CONFIG_DEFAULTS_LOAD);
                this.sidebar.changeDetector.markForCheck();
            });
        }
    }
}
