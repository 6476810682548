<div class="dialog-window">
    <p-dialog #dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [(visible)]="visible"
              (onHide)="closed.emit()" [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title">
                {{ headerLabelKey | translate }}
            </div>
        </p-header>
        <div class="new-form-400 scrolled-dialog">
            <div class="new-form-row">
                <app-select inputId="windowSystemSelect" label="{{ 'OFFER.TABS.SYSTEM' | translate }}"
                            [options]="availableWindowSystems | selectItemMultilanguageFieldTranslate"
                            [(validationMessageKey)]="validationErrors['windowSystem']"
                            [ngModel]="selectedWindowSystem" (ngModelChange)="onSystemChange($event)"
                            [disabled]="readOnlyMode"></app-select>
            </div>
            <div class="new-form-row">
                <app-input-number inputId="glazingPackageQuantity"
                                  label="{{ 'OFFER.TABS.SECTION.MAIN.QUANTITY' | translate }}"
                                  [(validationMessageKey)]="validationErrors['glazingPackageQuantity']"
                                  [min]="1" [max]="1000000" [(ngModel)]="glazingPackageQuantity"
                                  [disabled]="readOnlyMode"></app-input-number>
            </div>
            <div class="new-form-row">
                <app-input-number inputId="glazingPackageWidthInput"
                                  label="{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.WIDTH' | translate }}"
                                  [(validationMessageKey)]="validationErrors['glazingPackageWidth']"
                                  [min]="maxDimensions.MIN_WIDTH" [max]="maxDimensions.MAX_WIDTH"
                                  [(ngModel)]="glazingPackageWidth" [disabled]="readOnlyMode"></app-input-number>
            </div>
            <div class="new-form-row">
                <app-input-number inputId="glazingPackageHeightInput"
                                  label="{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.HEIGHT' | translate }}"
                                  [(validationMessageKey)]="validationErrors['glazingPackageHeight']"
                                  [min]="maxDimensions.MIN_HEIGHT" [max]="maxDimensions.MAX_HEIGHT"
                                  [(ngModel)]="glazingPackageHeight" [disabled]="readOnlyMode"></app-input-number>
            </div>
            <div *ngIf="!loading" class="new-form-row">
                <app-glass-selection *ngIf="!usesPredefinedGlazingPackages" [glasses]="glasses" [frames]="frames"
                                     [commonData]="glazing" [glazingGlassNumber]="glazingGlassNumber"
                                     [(validationErrors)]="validationErrors" [readOnlyMode]="readOnlyMode"
                                     [checkAvailability]="true"
                                     (glassQuantityEmitter)="handleGlassQuantityChange($event)"></app-glass-selection>
                <app-predefined-glazing-selection *ngIf="usesPredefinedGlazingPackages"
                                                  [readOnlyMode]="readOnlyMode"
                                                  [validationErrors]="validationErrors"
                                                  [quantityOptions]="predefinedGlazingGlassQuantities"
                                                  [selectedQuantity]="glazing.glazingGlassQuantity"
                                                  (quantityChanged)="handlePredefinedGlazingPackageQuantityChange($event)"
                                                  [categoryOptions]="predefinedGlazingPackageCategories | selectItemMultilanguageFieldTranslate"
                                                  [glazingCategoryId]="glazingPackageCategoryId"
                                                  (categoryChanged)="handlePredefinedGlazingPackageCategoryChange($event)"
                                                  [frameCategoryOptions]="predefinedGlazingPackageFrameCategories | selectItemMultilanguageFieldTranslate"
                                                  [glazingFrameCategoryId]="glazingPackageFrameCatagoryId"
                                                  (frameCategoryChanged)="handlePredefinedGlazingPackageFrameCategoryChange($event)"
                                                  [glazingPackageOptions]="predefinedGlazingPackages | selectItemMultilanguageFieldTranslate"
                                                  [glazingPackageId]="glazingPackageId"
                                                  (glazingPackageChanged)="handlePredefinedGlazingPackageChange($event)"></app-predefined-glazing-selection>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirm" [label]="'GENERAL.OK' | translate"
                                   type="main-action" [size]="40" [disabled]="saveInProgress || readOnlyMode"
                                   (onClick)="submit()"></app-simple-button>
                <app-simple-button buttonId="cancel" [label]="'GENERAL.CANCEL' | translate"
                                   type="cancel" [size]="40" [disabled]="saveInProgress"
                                   (onClick)="dialog.close($event)"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
