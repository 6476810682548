import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {forkJoin, Observable, of} from "rxjs";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {finalize, map, tap} from "rxjs/operators";
import {MultilanguageField} from "../../../supportedLanguages";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {ConfigSliderElement} from "./config-slider-element";
import {ConfigSliderElementService} from "./config-slider-element.service";
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {ConfigSystemService} from "../config-system/config-system.service";
import {SelectItem} from "primeng/api/selectitem";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-config-slider-element',
    templateUrl: './config-slider-element.component.html',
    providers: [ConfigSliderElementService, TranslatedSelectItemService, ConfigSystemService]
})
export class ConfigSliderElementComponent extends CrudCommonComponent<ConfigSliderElement, ConfigSliderElementService>
    implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
    };

    validateDataStep: WizardStepValidator;
    configSystems: SelectItem[];

    @ViewChild('dt')
    dataTable: DatatableInterface;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private translateService: TranslateService,
        private configSystemService: ConfigSystemService,
    ) {
        super(injector, changeDetector, true, ConfigSliderElementService, 'CONFIG_TYPE_DESCRIPTOR', 'ConfigTypeDescriptor');
        this.validateDataStep = () => this.validateForm();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.configSystemService.getItemNames()
            .pipe(map(systems => systems.map(item => new SelectItemImpl(item.name[this.translateService.currentLang], item.id))))
            .subscribe(items => this.configSystems = items);
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): ConfigSliderElement {
        return new ConfigSliderElement();
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.prepareDataForItem(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    prepareDataForItem(itemId: number) {
        forkJoin({
            item: itemId != undefined ? this.itemService.getItem(itemId) : of(new ConfigSliderElement()),
            image: (itemId != undefined ? this.itemService.getImageAsFile(itemId) : of<File>(undefined)),
        }).subscribe({
            next: data => {
                this.newItem = itemId == undefined;
                this.item = data.item;
                this.file = data.image;
                if (this.newItem) {
                    this.item.label = new MultilanguageField();
                    this.item.configSystemName = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');

        this.validationErrors['sortIndex'] = MultiValidator.of('error.configSliderElementDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        this.validationErrors['configSystemId'] = MultiValidator.of('error.configSliderElementDto.configSystemId')
            .withNotNullValidator()
            .validate(this.item.configSystemId);

        this.validationErrors['imageLarge'] = MultiValidator.of('error.configSliderElementDto.imageLarge')
            .withNotNullValidator()
            .validate(this.file);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm().subscribe(validateOk => {
            if (validateOk) {
                if (this.isSaveInProgress()) {
                    return;
                }
                this.setSaveInProgress(true);

                let observable: Observable<number>;
                if (this.copyMode) {
                    observable = this.itemService.copyElement(this.selectedItem.id, this.item, this.file);
                } else {
                    observable = this.itemService.saveElement(this.item, this.file);
                }
                observable.pipe(
                    finalize(() => this.setSaveInProgress(false))).subscribe({
                    complete: () => {
                        this.showSuccessMessage();
                        this.hideDetails();
                    },
                    error: err => {
                        this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                        this.changeDetector.markForCheck();
                    }
                });
            }
        });
    }

    showDialogToAdd() {
        this.validationErrors = {};
        this.prepareDataForItem(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.prepareDataForItem(this.selectedItem.id);
        }
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newItem = false;
        this.reloadDatatable();
    }
}
