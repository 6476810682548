import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {PriceWithTax} from '../offer';
import {TemporaryDealerDiscount} from './TemporaryDealerDiscount';

@JsonDeserializable
export class ProductionOrder implements CrudItem {
    id: number;
    offerNumbers: string[];
    offerCurrency: string;
    offerExchangeRate: number;
    orderNumber: string;
    creationDate: Date = new Date();
    supplierCompanyName: string;
    supplierName: MultilanguageField;
    supplierId: number;
    subsystemName: number;
    clientManagerFirstName: string;
    clientManagerLastName: string;
    subClientManagerFirstName: string;
    subClientManagerLastName: string;
    venskaBuyValue: PriceWithTax = new PriceWithTax(0, 0);
    buyValue: PriceWithTax = new PriceWithTax(0, 0);
    netVenskaIncome: number;
    temporaryDiscount: TemporaryDealerDiscount = new TemporaryDealerDiscount();
    hasTemporaryWindowSystemDealerDiscount: boolean;
    headerComment: string;
    footerComment: string;
    deliveryDescription: string;
    correctionId: number;
    orderCanceled: boolean;
    paymentPackageName: string;
    paletteName: string;
    printed: boolean;

    static fromJSON(jsonObject: any): ProductionOrder {
        const productionOrder = new ProductionOrder();
        productionOrder.id = jsonObject.id;
        productionOrder.offerNumbers = [...jsonObject.offerNumbers];
        productionOrder.offerCurrency = jsonObject.offerCurrency;
        productionOrder.offerExchangeRate = jsonObject.offerExchangeRate;
        productionOrder.orderNumber = jsonObject.orderNumber;
        if (jsonObject.creationDate != undefined) {
            productionOrder.creationDate = new Date(jsonObject.creationDate);
        }
        productionOrder.supplierCompanyName = jsonObject.supplierCompanyName;
        if (jsonObject.supplierName != undefined) {
            productionOrder.supplierName = MultilanguageField.fromJSON(jsonObject.supplierName);
        }
        productionOrder.supplierId = jsonObject.supplierId;
        productionOrder.subsystemName = jsonObject.subsystemName;
        productionOrder.clientManagerFirstName = jsonObject.clientManagerFirstName;
        productionOrder.clientManagerLastName = jsonObject.clientManagerLastName;
        productionOrder.subClientManagerFirstName = jsonObject.subClientManagerFirstName;
        productionOrder.subClientManagerLastName = jsonObject.subClientManagerLastName;
        if (jsonObject.venskaBuyValue != undefined) {
            productionOrder.venskaBuyValue = PriceWithTax.fromJSON(jsonObject.venskaBuyValue);
        }
        if (jsonObject.buyValue != undefined) {
            productionOrder.buyValue = PriceWithTax.fromJSON(jsonObject.buyValue);
        }
        productionOrder.netVenskaIncome = jsonObject.netVenskaIncome;
        if (jsonObject.temporaryDiscount != undefined) {
            productionOrder.temporaryDiscount = TemporaryDealerDiscount.fromJSON(jsonObject.temporaryDiscount);
        }
        productionOrder.hasTemporaryWindowSystemDealerDiscount = jsonObject.hasTemporaryWindowSystemDealerDiscount;
        productionOrder.headerComment = jsonObject.headerComment;
        productionOrder.footerComment = jsonObject.footerComment;
        productionOrder.deliveryDescription = jsonObject.deliveryDescription;
        productionOrder.correctionId = jsonObject.correctionId;
        productionOrder.orderCanceled = jsonObject.orderCanceled;
        productionOrder.paymentPackageName = jsonObject.paymentPackageName;
        productionOrder.paletteName = jsonObject.paletteName;
        productionOrder.printed = jsonObject.printed;
        return productionOrder;
    }
}
