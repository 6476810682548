<hr *ngIf="pricingsPerConfigAddon.length">
<div *ngIf="pricingsPerConfigAddon.length" id="config-addon-pricing-container">
    <div class="product-type-grid">
        <div class="primary-text">{{"OFFER.TABS.PRICING.PRODUCT.TYPE.CONFIG_ADDON" | translate}}
        </div>
        <div *ngIf="!validationMode" class="primary-text">
            <span *ngIf="configAddonSumPricing != null">{{formatPriceInOfferCurrency(configAddonSumPricing)}}</span>
            <span *ngIf="configAddonSumPricing == null">- / - PLN</span>
        </div>
        <div></div>
    </div>
    <div>
        <div *ngFor="let pricingResult of pricingsPerConfigAddon">
            <div class="config-charge-grid">
                <div *ngIf="pricingResult.addon.configurableAddonDefinitionType != null">
                    {{ getAddonName(pricingResult.addon.configurableAddonDefinitionType) | translate }} -
                    {{ ('OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.' + pricingResult.addon.application) | translate }}
                    {{ pricingResult.addon.parentInfo || "" }}
                </div>
                <div *ngIf="pricingResult.addon.configurableAddonDefinitionType == null">
                    {{ getAddonName2(pricingResult) | multilanguageTranslate }} -
                    {{ ('OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.' + pricingResult.addon.application) | translate }}
                    {{ pricingResult.addon.parentInfo || "" }}
                </div>
                <div *ngIf="!validationMode">
                    <span *ngIf="!pricingResult.containsError">{{formatPriceInOfferCurrency(pricingResult.pricing)}}</span>
                    <span *ngIf="pricingResult.containsError">- / - PLN</span>
                </div>
            </div>
            <div class="pricing-message-padding new-form-field-error-message" *ngIf="!validationMode && pricingResult.containsError">
                {{ ("error.pricing.pricingFailed") | translate }}
            </div>
            <div *ngIf="validationMode">
                <div class="pricing-message-padding new-form-field-error-message" *ngFor="let message of getUniqueMessagesForPricingResult(pricingResult)"
                [ngClass]="getClassName(message)">
                    <span [innerHTML]="message.messageCode | translate"></span>
                </div>
                <div *ngFor="let message of pricingResult.infoMessages">
                    <div class="pricing-message-padding">
                        <app-pricing-message [message]="message" [ignoreFieldName]="true"></app-pricing-message>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
