import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {WindowSystemModel} from './WindowSystemModel';

@Injectable()
export class WindowSystemModelService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    save(windowSystemId: number, data: WindowSystemDefaults): Observable<void> {
        return this.http.post<void>('windowSystemModels', {
            windowSystemId: windowSystemId,
            value: data
        });
    }

    edit(itemId: number, item: WindowSystemModel): Observable<void> {
        return this.http.put<void>(`windowSystemModels/${itemId}`, item);
    }

    getDefaultsFor(windowSystemId: number): Observable<WindowSystemModel> {
        let params = {
            windowSystemId: '' + windowSystemId
        };
        return this.http.get<WindowSystemModel>('windowSystemModels/getDefaultsFor', {params: params});
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WindowSystemModel>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<WindowSystemModel>>('windowSystemModels', {params: params});
    }
}
