<app-wizard #wizard header="{{ getHeader() | translate }}" (onCancel)="exitWizard()" (onComplete)="save()">
    <app-wizard-step label="{{ 'DELIVERY_LIST.FORM.STEPS.DETAILS' | translate }}" [id]="STEPS.DETAILS.id"
                     [validate]="STEPS.DETAILS.validator">
        <app-delivery-list-form [deliveryList]="deliveryList"
                                [validationErrors]="validationErrors"></app-delivery-list-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="originalDeliveryListId == null" label="{{ 'DELIVERY_LIST.FORM.STEPS.SUBSYSTEM' | translate }}"
                     [id]="STEPS.SUBSYSTEM.id" [validate]="STEPS.SUBSYSTEM.validator">
        <app-delivery-list-subsystem-selection-form [deliveryList]="deliveryList" [subsystems]="subsystems"
                                                    [validationErrors]="validationErrors"
                                                    (onDblClick)="wizard.nextStep()"></app-delivery-list-subsystem-selection-form>
    </app-wizard-step>
</app-wizard>
