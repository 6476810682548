<div class="new-form-600">
    <div class="new-form-row">
        <app-checkbox inputId="hasGlazing1" label="{{ 'GLAZING_PACKAGES.FORM.GLAZING1' | translate }}"
                      [disabled]="readOnly || ((target === glazingPackageTargets.UPSELLING_GLAZING || target === glazingPackageTargets.TERRACE_SYSTEM) && (item.hasGlazing2 || item.hasGlazing3 || item.hasGlazing4))"
                      [ngModel]="item.hasGlazing1"
                      (ngModelChange)="handleHasGlazingChange(1, $event)"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="hasGlazing2" label="{{ 'GLAZING_PACKAGES.FORM.GLAZING2' | translate }}"
                      [disabled]="readOnly || ((target === glazingPackageTargets.UPSELLING_GLAZING || target === glazingPackageTargets.TERRACE_SYSTEM) && (item.hasGlazing1 || item.hasGlazing3 || item.hasGlazing4))"
                      [ngModel]="item.hasGlazing2"
                      (ngModelChange)="handleHasGlazingChange(2, $event)"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="hasGlazing3" label="{{ 'GLAZING_PACKAGES.FORM.GLAZING3' | translate }}"
                      [disabled]="readOnly || ((target === glazingPackageTargets.UPSELLING_GLAZING || target === glazingPackageTargets.TERRACE_SYSTEM) && (item.hasGlazing1 || item.hasGlazing2 || item.hasGlazing4))"
                      [ngModel]="item.hasGlazing3"
                      (ngModelChange)="handleHasGlazingChange(3, $event)"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="hasGlazing4" label="{{ 'GLAZING_PACKAGES.FORM.GLAZING4' | translate }}"
                      [disabled]="readOnly || ((target === glazingPackageTargets.UPSELLING_GLAZING || target === glazingPackageTargets.TERRACE_SYSTEM) && (item.hasGlazing1 || item.hasGlazing2 || item.hasGlazing3))"
                      [ngModel]="item.hasGlazing4"
                      (ngModelChange)="handleHasGlazingChange(4, $event)"></app-checkbox>
    </div>

    <p-accordion [multiple]="true">
        <ng-template #glazingValidationErrors let-index="glazingIndex" let-validationErrors="validationErrors">
            <div *ngIf="validationErrors.length > 0" class="new-form-row">
                <div class="new-form-field">
                    <div></div>
                    <div>
                        <div *ngFor="let validationError of validationErrors" class="new-form-field-error-message">
                            {{ validationError | translate }}
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <p-accordionTab header="{{ 'GLAZING_PACKAGES.FORM.GLAZING1' | translate }}"
                        [(selected)]="accordionTabSelected[0]" [disabled]="!item.hasGlazing1"
                        [ngClass]="{'accordion-header-with-errors': validationErrors.glazingHasErrors(1)}">

            <ng-container *ngIf="target === glazingPackageTargets.WEBSHOP_GLAZING || target === glazingPackageTargets.SYSTEM_DEFINITION
                                    || target === glazingPackageTargets.DECORATIVE_GLAZING">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_STANDARD' | translate }}</h3>
                </div>
                <app-glass-selection *ngIf="item.hasGlazing1" [glasses]="glasses" [frames]="frames"
                                     [commonData]="item.glazing1" [glazingGlassNumber]="[1]"
                                     [validateInstantly]="validationErrors.glazingHasErrors(1)"
                                     [(validationErrors)]="validationErrors.glazing1"
                                     [glazingWidths]="glazingWidths"
                                     [readOnlyMode]="readOnly" [catalogAdd]="addMode"></app-glass-selection>
                <ng-container
                        *ngTemplateOutlet="glazingValidationErrors; context: { glazingIndex: 1, validationErrors: validationErrors.getErrorsNotLinkedToField(1) }"></ng-container>
            </ng-container>

            <ng-container *ngIf="supportPackagesForAreaRanges && availableGlazingBeads != undefined">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_PER_AREA_RANGE' | translate }}</h3>
                </div>
                <div class="new-form-row">
                    <app-glazing-packages-for-area-range-form
                            [readOnlyMode]="readOnly"
                            [packagesForAreas]="item.glazingPackageForAreaRangeList"
                            [validationErrors]="validationErrors"
                            [glasses]="glasses"
                            [frames]="frames"
                            [glazingWidths]="glazingWidths"
                            [availableGlazingBeads]="availableGlazingBeads"
                            [glassCount]="1"
                            [addMode]="addMode">
                    </app-glazing-packages-for-area-range-form>
                </div>
            </ng-container>
        </p-accordionTab>
        <p-accordionTab header="{{ 'GLAZING_PACKAGES.FORM.GLAZING2' | translate }}"
                        [(selected)]="accordionTabSelected[1]" [disabled]="!item.hasGlazing2"
                        [ngClass]="{'accordion-header-with-errors': validationErrors.glazingHasErrors(2)}">
            <ng-container *ngIf="target === glazingPackageTargets.WEBSHOP_GLAZING || target === glazingPackageTargets.SYSTEM_DEFINITION
                                    || target === glazingPackageTargets.DECORATIVE_GLAZING">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_STANDARD' | translate }}</h3>
                </div>
                <app-glass-selection *ngIf="item.hasGlazing2" [glasses]="glasses" [frames]="frames"
                                     [commonData]="item.glazing2" [glazingGlassNumber]="[2]"
                                     [validateInstantly]="validationErrors.glazingHasErrors(2)"
                                     [(validationErrors)]="validationErrors.glazing2"
                                     [glazingWidths]="glazingWidths"
                                     [readOnlyMode]="readOnly" [catalogAdd]="addMode"></app-glass-selection>
                <ng-container
                        *ngTemplateOutlet="glazingValidationErrors; context: { glazingIndex: 2, validationErrors: validationErrors.getErrorsNotLinkedToField(2) }"></ng-container>
            </ng-container>

            <ng-container *ngIf="supportPackagesForAreaRanges && availableGlazingBeads != undefined">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_PER_AREA_RANGE' | translate }}</h3>
                </div>
                <div class="new-form-row">
                    <app-glazing-packages-for-area-range-form
                            [readOnlyMode]="readOnly"
                            [packagesForAreas]="item.glazingPackageForAreaRangeList"
                            [validationErrors]="validationErrors"
                            [glasses]="glasses"
                            [frames]="frames"
                            [glazingWidths]="glazingWidths"
                            [availableGlazingBeads]="availableGlazingBeads"
                            [glassCount]="2"
                            [addMode]="addMode">
                    </app-glazing-packages-for-area-range-form>
                </div>
            </ng-container>
        </p-accordionTab>
        <p-accordionTab header="{{ 'GLAZING_PACKAGES.FORM.GLAZING3' | translate }}"
                        [(selected)]="accordionTabSelected[2]" [disabled]="!item.hasGlazing3"
                        [ngClass]="{'accordion-header-with-errors': validationErrors.glazingHasErrors(3)}">
            <ng-container *ngIf="target === glazingPackageTargets.WEBSHOP_GLAZING || target === glazingPackageTargets.SYSTEM_DEFINITION
                                    || target === glazingPackageTargets.DECORATIVE_GLAZING">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_STANDARD' | translate }}</h3>
                </div>
                <app-glass-selection *ngIf="item.hasGlazing3" [glasses]="glasses" [frames]="frames"
                                     [commonData]="item.glazing3" [glazingGlassNumber]="[3]"
                                     [validateInstantly]="validationErrors.glazingHasErrors(3)"
                                     [(validationErrors)]="validationErrors.glazing3"
                                     [glazingWidths]="glazingWidths"
                                     [readOnlyMode]="readOnly" [catalogAdd]="addMode"></app-glass-selection>
                <ng-container
                        *ngTemplateOutlet="glazingValidationErrors; context: { glazingIndex: 3, validationErrors: validationErrors.getErrorsNotLinkedToField(3) }"></ng-container>
            </ng-container>

            <ng-container *ngIf="supportPackagesForAreaRanges && availableGlazingBeads != undefined">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_PER_AREA_RANGE' | translate }}</h3>
                </div>
                <div class="new-form-row">
                    <app-glazing-packages-for-area-range-form
                            [readOnlyMode]="readOnly"
                            [packagesForAreas]="item.glazingPackageForAreaRangeList"
                            [validationErrors]="validationErrors"
                            [glasses]="glasses"
                            [frames]="frames"
                            [glazingWidths]="glazingWidths"
                            [availableGlazingBeads]="availableGlazingBeads"
                            [glassCount]="3"
                            [addMode]="addMode">
                    </app-glazing-packages-for-area-range-form>
                </div>
            </ng-container>
        </p-accordionTab>
        <p-accordionTab header="{{ 'GLAZING_PACKAGES.FORM.GLAZING4' | translate }}"
                        [(selected)]="accordionTabSelected[3]" [disabled]="!item.hasGlazing4"
                        [ngClass]="{'accordion-header-with-errors': validationErrors.glazingHasErrors(4)}">
            <ng-container *ngIf="target === glazingPackageTargets.WEBSHOP_GLAZING || target === glazingPackageTargets.SYSTEM_DEFINITION
                                    || target === glazingPackageTargets.DECORATIVE_GLAZING">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_STANDARD' | translate }}</h3>
                </div>
                <app-glass-selection *ngIf="item.hasGlazing4" [glasses]="glasses" [frames]="frames"
                                     [commonData]="item.glazing4" [glazingGlassNumber]="[4]"
                                     [validateInstantly]="validationErrors.glazingHasErrors(4)"
                                     [(validationErrors)]="validationErrors.glazing4"
                                     [glazingWidths]="glazingWidths"
                                     [readOnlyMode]="readOnly" [catalogAdd]="addMode"></app-glass-selection>
                <ng-container
                        *ngTemplateOutlet="glazingValidationErrors; context: { glazingIndex: 4, validationErrors: validationErrors.getErrorsNotLinkedToField(4) }"></ng-container>
            </ng-container>

            <ng-container *ngIf="supportPackagesForAreaRanges && availableGlazingBeads != undefined">
                <div class="new-form-row">
                    <h3>{{ 'GLAZING_PACKAGES.FORM.GLAZING_PER_AREA_RANGE' | translate }}</h3>
                </div>
                <div class="new-form-row">
                    <app-glazing-packages-for-area-range-form
                            [readOnlyMode]="readOnly"
                            [packagesForAreas]="item.glazingPackageForAreaRangeList"
                            [validationErrors]="validationErrors"
                            [glasses]="glasses"
                            [frames]="frames"
                            [glazingWidths]="glazingWidths"
                            [availableGlazingBeads]="availableGlazingBeads"
                            [glassCount]="4"
                            [addMode]="addMode">
                    </app-glazing-packages-for-area-range-form>
                </div>
            </ng-container>
        </p-accordionTab>
    </p-accordion>
</div>
