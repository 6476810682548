import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {
    AutoAlignOption,
    OpeningOption,
    WindowSystemType,
    WindowSystemTypeData
} from '../../../../../window-designer/catalog-data/window-system-interface';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {MaterialType} from '../../../../common/enums/MaterialType';
import {MaterialTypeProvider} from '../../../../common/MaterialTypeProvider';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../common/validation-errors';
import {RoofSystemField, WindowSystemBasicField, WindowSystemField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {PricetableSelectionMode} from "../../../price-lists/price-table/pricetable-selection-mode";
import {Supplier} from '../../../supplier/supplier';
import {WindowSystemFieldUsage} from "../../catalog-field-usage";
import {CatalogItemTag} from '../../catalog-item-tags/catalog-item-tag';
import {RoofSystemFunction, WindowSystemDefinition} from '../window-system-definition';

@Component({
    selector: 'app-window-system-definition-general-form',
    templateUrl: './window-system-definition-general-form.component.html',
    styleUrls: ['./window-system-definition-general-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionGeneralFormComponent implements OnInit {

    @Input()
    roofSystem: boolean;

    @Input()
    entranceSystem: boolean;

    @Input()
    windowSystem: WindowSystemDefinition;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    suppliers: SelectItem[];

    @Input()
    windowEditorImage: File;

    @Input()
    printoutImage: File;

    @Input()
    roofGlazingPackages: SelectItem[];

    @Input()
    tags: SelectItem[];

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly windowEditorImageChange = new EventEmitter<File>();

    @Output()
    readonly printoutImageChange = new EventEmitter<File>();

    materialTypes: Observable<SelectItem[]>;

    windowSystemTypes: Observable<SelectItem[]>;
    selectedWindowSystemType: WindowSystemTypeData;
    availableOpenings: Observable<SelectItem[]>;
    autoAlignOptions: Observable<SelectItem[]>;
    pricetableSelectionModeOptions: Observable<SelectItem[]>;

    roofSystemFunctions: Observable<SelectItem[]>;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    selectedTags: CatalogItemTag[];

    fieldUsage: WindowSystemFieldUsage;
    WindowSystemBasicField = WindowSystemBasicField;
    WindowSystemField = WindowSystemField;
    RoofSystemField = RoofSystemField;

    constructor(public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private changeDetector: ChangeDetectorRef) {
        this.fieldUsage = new WindowSystemFieldUsage(this);
    }

    ngOnInit(): void {
        this.materialTypes = this.translatedSelectItemService.buildUnsortedDropdown(this.roofSystem
            ? MaterialTypeProvider.getRoofSystemsMaterials() : MaterialType, 'MATERIAL.', undefined);
        this.windowSystemTypes = this.translatedSelectItemService.buildSortedDropdown(
            WindowSystemType.getWindowAndTerraceSystemsTypes(),
            (windowSystemType: WindowSystemTypeData) => 'WINDOW-SYSTEM-DEFINITION.SYSTEM_TYPE.' + windowSystemType.type,
            undefined);
        this.availableOpenings = this.translatedSelectItemService.buildSortedDropdown([OpeningOption.INSIDE, OpeningOption.OUTSIDE],
            'OFFER.OPENING.', undefined);
        this.roofSystemFunctions = this.translatedSelectItemService.buildUnsortedDropdown(RoofSystemFunction,
            'WINDOW-SYSTEM-DEFINITION.ROOF_SYSTEM_FUNCTION.', undefined);
        this.autoAlignOptions = this.translatedSelectItemService.buildSortedDropdown(AutoAlignOption,
            'WINDOW-SYSTEM-DEFINITION.FORM.AUTO_ALIGN.', undefined);
        this.pricetableSelectionModeOptions = this.translatedSelectItemService.buildSortedDropdown([PricetableSelectionMode.WINDOW,
            PricetableSelectionMode.WINDOW_COLOR_DEPENDENT], 'WINDOW-SYSTEM-DEFINITION.FORM.PRICETABLE_SELECTION_MODE.', undefined);
        this.selectedWindowSystemType = WindowSystemType.getByName(this.windowSystem.systemType);
        this.selectedTags = this.windowSystem.tagIds
            .map(tagId => this.tags.find(tag => tag.value.id === tagId))
            .filter(tag => tag != undefined)
            .map(tag => tag.value);
    }

    onSelectedOpeningsChange(): void {
        this.validationErrors['possibleOpenings'] = undefined;
    }

    getSupplierKey = (supplier: Supplier) => supplier ? supplier.id : undefined;

    getWindowSystemTypeKey = (windowSystemType: WindowSystemTypeData) => windowSystemType ? windowSystemType.type : undefined;

    handleSystemTypeChange(windowSystemType: WindowSystemTypeData): void {
        this.selectedWindowSystemType = windowSystemType;
        if (windowSystemType != undefined) {
            this.windowSystem.doors = windowSystemType.doors;
            this.windowSystem.hsSystem = windowSystemType.additionalProperties;
            this.windowSystem.systemType = windowSystemType.type;
            if (windowSystemType.predefinedGlazing) {
                this.windowSystem.glazingPackageId = undefined;
            }
        } else {
            this.windowSystem.doors = undefined;
            this.windowSystem.hsSystem = undefined;
            this.windowSystem.systemType = undefined;
        }
        this.windowSystem.wingsImpositionWidth = undefined;
        this.windowSystem.wingsContactWidth = undefined;
        this.validationErrors = Object.assign({}, this.validationErrors, {wingsImpositionWidth: undefined, wingsContactWidth: undefined});
        this.changeDetector.markForCheck();
    }

    windowEditorImageChanged(file: File): void {
        this.windowEditorImageChange.emit(file);
        this.windowEditorImage = file;
    }

    printoutImageChanged(file: File): void {
        this.printoutImageChange.emit(file);
        this.printoutImage = file;
    }

    handleTagsChange(tags: CatalogItemTag[]): void {
        this.selectedTags = tags;
        this.windowSystem.tagIds = tags.map(tag => tag.id);
    }
}
