<!--Tools-->
<div id="toolbar" *ngIf="!sidebarOnlyMode">
    <ng-container *ngIf="mode === DrawingToolControlsMode.WINDOW || mode === DrawingToolControlsMode.CONJUNCTION">
        <div class="tool" id="tool-undo" [class.disabled]="!canUndo()"
             (click)="undoLast()" pTooltip="{{'OFFER.MENU.UNDO' | translate}}">
            <span class="material-icons">undo</span>
        </div>
        <div class="tool" id="tool-redo" [class.disabled]="!canRedo()"
             (click)="redoLast()" pTooltip="{{'OFFER.MENU.REDO' | translate}}">
            <span class="material-icons">redo</span>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === DrawingToolControlsMode.CONJUNCTION">
        <div class="tool" id="tool-change-coupler" [class.disabled]="!canChangeConnector()"
             (click)="onChangeCoupler.emit()" pTooltip="{{'OFFER.MENU.CHANGE_CONNECTOR' | translate}}">
            <span class="material-icons">view_day</span>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === DrawingToolControlsMode.WINDOW">
        <button-with-menu *ngIf="isShapeEnabledForWindowSystem()" [menuType]="menuType.ICON_LINE"
                          [menuElements]="windowShapeMenuElements"
                          [disabled]="!canChangeWindowShape()" (onMenuOpen)="cancelMode()"
                          (menuElementSelected)="windowShapeMenuSelected($event)">
            <div class="tool" id="tool-shape-selection" [class.disabled]="!canChangeWindowShape()"
                 pTooltip="{{'OFFER.MENU.SHAPE' | translate}}">
                <span class="material-icons">looks</span>
            </div>
        </button-with-menu>
        <div *ngIf="isCutEnabledForWindowSystem()" class="tool" id="tool-cut"
             [ngClass]="{disabled: !canUseCutTool(), 'tool-active': isToolActive('CUT')}"
             (click)="enableCutMode()" pTooltip="{{'OFFER.MENU.CUT' | translate}}">
            <span class="material-icons">content_cut</span>
        </div>
        <div *ngIf="isTrimEnabledForWindowSystem()" class="tool" id="tool-trim" [class.disabled]="!canUseTrimTool()"
             (click)="trimToolUsed()" pTooltip="{{'OFFER.MENU.TRIM' | translate}}">
            <span class="material-icons">signal_cellular_off</span>
        </div>
        <div *ngIf="isAddMullionEnabledForWindowSystem()" class="tool" id="tool-mullion"
             [ngClass]="{disabled: !canUseMullionTool(), 'tool-active': showGrillTool === GrillType.MULLION}"
             (click)="initGrillMode(GrillType.MULLION)" pTooltip="{{'OFFER.DRAWING.MULLION.ADD' | translate}}">
            <div class="tool-mullion"></div>
        </div>
        <div *ngIf="isMirrorEnabledForWindowSystem()" class="tool" id="tool-mirror"
             [class.disabled]="!canUseMirrorTool()"
             (click)="mirrorMode()" pTooltip="{{'OFFER.MENU.MIRROR' | translate}}">
            <span class="material-icons">compare_arrows</span>
        </div>
        <button-with-menu *ngIf="isAlignmentEnabledForWindowSystem()" [menuType]="menuType.ICON_LINE"
                          [menuElements]="aligmentToolMenuElements"
                          [disabled]="!canUseAlignmentTool()" (onMenuOpen)="cancelMode()"
                          (menuElementSelected)="aligmentToolMenuSelected($event)">
            <div class="tool" id="tool-alignment-selection" [class.disabled]="!canUseAlignmentTool()"
                 pTooltip="{{'OFFER.MENU.ALIGNMENT' | translate}}"></div>
        </button-with-menu>
        <div *ngIf="isAddLineGrillEnabledForWindowSystem()" class="tool" id="tool-grill"
             [ngClass]="{disabled: !canUseGrillTool(GrillType.LINE_GRILL), 'tool-active':  isToolActive('GRILL')}"
             (click)="initGrillMode(GrillType.LINE_GRILL)"
             pTooltip="{{'OFFER.DRAWING.GRILL.LINE_GRILL' | translate}}">
            <div class="tool-grill"></div>
        </div>
        <button-with-menu *ngIf="isAddGridGrillEnabledForWindowSystem()" [menuType]="menuType.ICON_LINE"
                          [menuElements]="grillGridMenuElements"
                          [disabled]="!canUseGrillTool()" (onMenuOpen)="cancelMode()"
                          (menuElementSelected)="grillGridMenuSelected($event)">
            <div class="tool" id="tool-grill-selection"
                 [ngClass]="{disabled: !canUseGrillTool(), 'tool-active': isToolActive('GRILL_TEMPLATE')}"
                 pTooltip="{{'OFFER.DRAWING.GRILL.GRID' | translate}}">
                <div class="tool-grill-selection"></div>
            </div>
        </button-with-menu>
        <div *ngIf="isExtendHorizontalGrillsEnabledForWindowSystem()" class="tool" id="tool-extend-horizontal-grills"
             [ngClass]="{disabled: !canUseExtendHorizontalGrillsTool(), 'tool-active':  isToolActive('EXTEND_HORIZONTAL_GRILLS')}"
             (click)="extendHorizontalGrills()"
             pTooltip="{{'OFFER.MENU.EXTEND_HORIZONTAL_GRILLS' | translate}}">
            <div class="tool-extend-horizontal-grills"></div>
        </div>
        <button-with-menu *ngIf="isAddHandleEnabledForWindowSystem()" [menuType]="menuType.ICON_LINE"
                          [menuElements]="handleDirectionMenuElements"
                          [disabled]="!canUseHandleTool()" (onMenuOpen)="cancelMode()"
                          (menuElementSelected)="handleDirectionMenuSelected($event)">
            <div class="tool" id="tool-handle-selection"
                 [ngClass]="{disabled: !canUseHandleTool(), 'tool-active': isToolActive('HANDLE')}"
                 pTooltip="{{'OFFER.MENU.HANDLE' | translate}}">
                <div class="tool-handle-selection"></div>
            </div>
        </button-with-menu>
        <div *ngIf="isAddConfigAddonEnabledForWindowSystem()" class="tool" id="tool-config-addon"
             [class.disabled]="!canAddConfigAddon()"
             (click)="addConfigAddon()" pTooltip="{{'OFFER.MENU.ADD_CONFIG_ADDON' | translate}}">
            <span class="material-icons">playlist_add</span>
        </div>
        <div *ngIf="isAddStandaloneGlazingPackageEnabledForWindowSystem()" class="tool"
             id="tool-standalone-glazing-package"
             [class.disabled]="!canAddStandaloneGlazingPackage()"
             (click)="addStandaloneGlazingPackage()" pTooltip="{{'OFFER.MENU.ADD_STADNALONE_GLAZING_PACKAGE' | translate}}">
            <span class="material-icons">open_in_new</span>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === DrawingToolControlsMode.WINDOW || mode === DrawingToolControlsMode.CONJUNCTION">
        <div class="tool" id="tool-delete"
             [class.disabled]="!canUseDeleteTool()"
             (click)="deleteSelectedItem()"
             pTooltip="{{(canUseDeleteTool() ? 'OFFER.MENU.REMOVE_SELECTED' : 'OFFER.MENU.CAN_NOT_REMOVE_SELECTED') | translate}}">
            <span class="material-icons">delete_forever</span>
        </div>
    </ng-container>
    <ng-container *ngIf="mode === DrawingToolControlsMode.WINDOW">
        <div class="separator"></div>
        <div class="tool" id="tool-cancel"
             [class.disabled]="!canCancelTool()"
             (click)="cancelMode()" pTooltip="{{'OFFER.MENU.CANCEL' | translate}}">
            <span class="material-icons">check_circle_outline</span>
        </div>
    </ng-container>
    <div class="spacer"></div>
    <ng-container *ngIf="mode === DrawingToolControlsMode.WINDOW">
        <div class="tool" id="tool-settings"
             [class.disabled]="!atLeastOneWindowAddedAndNotAddingAnotherOneNow()"
             (click)="openSettings()" pTooltip="{{'OFFER.MENU.SETTINGS.TITLE'|translate}}">
            <span class="material-icons">settings</span>
        </div>
    </ng-container>
    <div class="tool" id="tool-exit"
         (click)="exit()" pTooltip="{{'OFFER.MENU.EXIT_WITHOUT_SAVING' | translate}}">
        <span class="material-icons">exit_to_app</span>
    </div>
    <div class="tool" id="tool-save-exit" [class.disabled]="!canSaveAndExit()"
         (click)="saveAndExit()" pTooltip="{{'OFFER.MENU.SAVE_AND_EXIT' | translate}}">
        <span class="material-icons">save</span>
    </div>
    <div id="cursor-coords" *ngIf="showPointerPosition">
        X: <span id="pointer-position-x">0</span><br/>
        Y: <span id="pointer-position-y">0</span>
    </div>
</div>

<div *ngIf="!sidebarOnlyMode && !toolOptionsVisible" id="designer-header-container" class="tool-options-container">

    <!--Designer header -->
    <div id="designer-header" class="tool-options">
        <div id="designer-header-label" class="tool-options-label">
            {{ getDesignerHeader() }}
        </div>
    </div>
</div>

<div id="tool-options-container" [class.sidebarOnlyMode]="sidebarOnlyMode" class="tool-options-container">

    <!--Tool options-->
    <div id="tool-options" class="tool-options"
         *ngIf="toolOptionsVisible && !sidebarOnlyMode">

        <div id="tool-options-label" class="tool-options-label">
            {{ toolOptionsLabel | translate }}
            <ng-container *ngIf="grillGridSelected">
                <div *ngIf="designer?.pendingGrillData.getGenericGrillGrid() as genericGrid" class="float-right">
                    <div class="new-form-row">
                        <app-checkbox inputId="active" label="{{ 'OFFER.DRAWING.GRILL.WINGLESS_MODE' | translate }}"
                                      [(ngModel)]="genericGrid.winglessMode"></app-checkbox>
                    </div>
                </div>
            </ng-container>
        </div>

        <!--Choose line grill-->
        <div *ngIf="showGrillTool === GrillType.LINE_GRILL && designer?.pendingGrillData" class="new-form-row pad-left10px">
            <app-select inputId="grillId"
                        label="{{ 'OFFER.DRAWING.GRILL.SELECT_TYPE' | translate }}"
                        [(validationMessageKey)]="designer?.pendingGrillValidationErrors['grill']"
                        [options]="grillSelectItems | async"
                        [(ngModel)]="designer?.pendingGrillData.grill.id"
                        (ngModelChange)="grillParamsChange(GrillType.LINE_GRILL, ['id'])"
                        [required]="true"></app-select>
            <div>
                <app-select inputId="lineGrillColorId"
                            label="{{ 'OFFER.DRAWING.GRILL.SELECT_COLOR' | translate }}"
                            [(validationMessageKey)]="designer?.pendingGrillValidationErrors['color']"
                            [options]="getGrillColorSelectItems()"
                            [ngModel]="designer?.pendingGrillData.grill.colorId"
                            (ngModelChange)="handleGrillColorSelected($event)"
                            [translateLabels]="true"
                            [required]="true" [optionFormatter]="colorWithGroupOptionFormatter"
                            [optionKey]="colorOptionKey"></app-select>
                <div class="grillSelectColorInfo" *ngIf="isGrillExternalColorWithMatchingColors()">
                    {{ 'OFFER.DRAWING.GRILL.SELECT_COLOR_INFO' | translate }}
                </div>
            </div>
        </div>

        <!--Grill grid parameters-->
        <div *ngIf="showGrillTool === GrillType.GRID_STANDARD && designer?.pendingGrillData" class="pad-left10px">
            <div class="new-form-row">
                <app-select inputId="grillId"
                            label="{{ 'OFFER.DRAWING.GRILL.SELECT_TYPE' | translate }}"
                            [(validationMessageKey)]="designer?.pendingGrillValidationErrors['grill']"
                            [options]="grillSelectItems | async"
                            [(ngModel)]="designer?.pendingGrillData.grill.id"
                            (ngModelChange)="grillParamsChange(GrillType.GRID_STANDARD, ['id'])"
                            [required]="true"></app-select>
                <div>
                    <app-select inputId="grillGridColorId"
                                label="{{ 'OFFER.DRAWING.GRILL.SELECT_COLOR' | translate }}"
                                [(validationMessageKey)]="designer?.pendingGrillValidationErrors['color']"
                                [options]="getGrillColorSelectItems()"
                                [ngModel]="designer?.pendingGrillData.grill.colorId"
                                (ngModelChange)="handleGrillColorSelected($event)"
                                [translateLabels]="true"
                                [required]="true" [optionFormatter]="colorWithGroupOptionFormatter"
                                [optionKey]="colorOptionKey"></app-select>
                    <div class="grillSelectColorInfo" *ngIf="isGrillExternalColorWithMatchingColors()">
                        {{ 'OFFER.DRAWING.GRILL.SELECT_COLOR_INFO' | translate }}
                    </div>
                </div>
                <div class="new-form-row">
                    <app-input-number inputId="grill-grid-columns"
                                      label="{{ 'OFFER.DRAWING.GRILL.GRID_COLUMNS' | translate }}"
                                      [(validationMessageKey)]="designer?.pendingGrillValidationErrors['gridColumns']"
                                      [min]="1" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                                      [(ngModel)]="designer?.pendingGrillData.getGrillGrid().columns"
                                      (ngModelChange)="grillParamsChange(GrillType.GRID_STANDARD, ['columns'])"
                                      [required]="true"></app-input-number>
                    <app-input-number inputId="grill-grid-rows"
                                      label="{{ 'OFFER.DRAWING.GRILL.GRID_ROWS' | translate }}"
                                      [(validationMessageKey)]="designer?.pendingGrillValidationErrors['gridRows']"
                                      [min]="1" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                                      [(ngModel)]="designer?.pendingGrillData.getGrillGrid().rows"
                                      (ngModelChange)="grillParamsChange(GrillType.GRID_STANDARD, ['rows'])"
                                      [required]="true"></app-input-number>
                </div>
            </div>
            <div class="new-form-row tool-options-footer">
                <div class="new-form-field-error-message" *ngIf="designer?.pendingGrillValidationErrors['gridSize']">
                    {{ designer?.pendingGrillValidationErrors['gridSize'] | translate }}
                </div>
            </div>
        </div>

        <!--Choose cross grill-->
        <div *ngIf="showGrillTool === GrillType.GRID_CROSS_SIMPLE && designer?.pendingGrillData" class="new-form-row pad-left10px">
            <app-select inputId="grillId"
                        label="{{ 'OFFER.DRAWING.GRILL.SELECT_TYPE' | translate }}"
                        [(validationMessageKey)]="designer?.pendingGrillValidationErrors['grill']"
                        [options]="grillSelectItems | async"
                        [(ngModel)]="designer?.pendingGrillData.grill.id"
                        (ngModelChange)="grillParamsChange(GrillType.GRID_CROSS_SIMPLE, ['id'])"
                        [required]="true"></app-select>
            <div>
                <app-select inputId="crossGrillSimpleColorId"
                            label="{{ 'OFFER.DRAWING.GRILL.SELECT_COLOR' | translate }}"
                            [(validationMessageKey)]="designer?.pendingGrillValidationErrors['color']"
                            [options]="getGrillColorSelectItems()"
                            [translateLabels]="true"
                            [ngModel]="designer?.pendingGrillData.grill.colorId"
                            (ngModelChange)="handleGrillColorSelected($event)"
                            [required]="true" [optionFormatter]="colorWithGroupOptionFormatter"
                            [optionKey]="colorOptionKey"></app-select>
                <div class="grillSelectColorInfo" *ngIf="isGrillExternalColorWithMatchingColors()">
                    {{ 'OFFER.DRAWING.GRILL.SELECT_COLOR_INFO' | translate }}
                </div>
            </div>
        </div>

        <!--Choose cross angled grill-->
        <div *ngIf="showGrillTool === GrillType.GRID_CROSS_ANGLED && designer?.pendingGrillData" class="new-form-row pad-left10px">
            <app-select inputId="grillId"
                        label="{{ 'OFFER.DRAWING.GRILL.SELECT_TYPE' | translate }}"
                        [(validationMessageKey)]="designer?.pendingGrillValidationErrors['grill']"
                        [options]="angledGrillSelectItems | async"
                        [(ngModel)]="designer?.pendingGrillData.grill.id"
                        (ngModelChange)="grillParamsChange(GrillType.GRID_CROSS_ANGLED, ['id'])"
                        [required]="true"></app-select>
            <div>
                <app-select inputId="crossGrillAngledColorId"
                            label="{{ 'OFFER.DRAWING.GRILL.SELECT_COLOR' | translate }}"
                            [(validationMessageKey)]="designer?.pendingGrillValidationErrors['color']"
                            [options]="getGrillColorSelectItems()"
                            [translateLabels]="true"
                            [ngModel]="designer?.pendingGrillData.grill.colorId"
                            (ngModelChange)="handleGrillColorSelected($event)"
                            [required]="true" [optionFormatter]="colorWithGroupOptionFormatter"
                            [optionKey]="colorOptionKey"></app-select>
                <div class="grillSelectColorInfo" *ngIf="isGrillExternalColorWithMatchingColors()">
                    {{ 'OFFER.DRAWING.GRILL.SELECT_COLOR_INFO' | translate }}
                </div>
            </div>
        </div>

        <!--Diamond grill parameters-->
        <div *ngIf="showGrillTool === GrillType.GRID_RHOMBUS && designer?.pendingGrillData" class="pad-left10px">
            <div class="new-form-row">
                <app-select inputId="grillId"
                            label="{{ 'OFFER.DRAWING.GRILL.SELECT_TYPE' | translate }}"
                            [(validationMessageKey)]="designer?.pendingGrillValidationErrors['grill']"
                            [options]="angledGrillSelectItems | async"
                            [(ngModel)]="designer?.pendingGrillData.grill.id"
                            (ngModelChange)="grillParamsChange(GrillType.GRID_RHOMBUS, ['id'])"
                            [required]="true"></app-select>
                <app-select inputId="diamondGrillColorId"
                            label="{{ 'OFFER.DRAWING.GRILL.SELECT_COLOR' | translate }}"
                            [(validationMessageKey)]="designer?.pendingGrillValidationErrors['color']"
                            [options]="getGrillColorSelectItems()"
                            [translateLabels]="true"
                            [ngModel]="designer?.pendingGrillData.grill.colorId"
                            (ngModelChange)="handleGrillColorSelected($event)"
                            [required]="true" [optionFormatter]="colorWithGroupOptionFormatter"
                            [optionKey]="colorOptionKey"></app-select>
                <div class="new-form-row">
                    <app-input-number inputId="gridRhombusWidth"
                                      label="{{ 'OFFER.DRAWING.GRILL.RHOMBUS_WIDTH' | translate }}"
                                      [(validationMessageKey)]="designer?.pendingGrillValidationErrors['rhombusWidth']"
                                      [min]="1" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                                      [ngModel]="designer?.pendingGrillData.getGrillGridWithRhombusOnIntersections().rhombusWidth"
                                      (ngModelChange)="onRhombusGrillSizeChange($event)"
                                      [required]="true"></app-input-number>
                    <app-input-number inputId="gridRhombusHeight"
                                      label="{{ 'OFFER.DRAWING.GRILL.RHOMBUS_HEIGHT' | translate }}"
                                      [(validationMessageKey)]="designer?.pendingGrillValidationErrors['rhombusHeight']"
                                      [ngModel]="designer?.pendingGrillData.getGrillGridWithRhombusOnIntersections().rhombusWidth"
                                      [disabled]="true"></app-input-number>
                </div>
                <div class="new-form-row">
                    <app-input-number inputId="grid3columns"
                                      label="{{ 'OFFER.DRAWING.GRILL.GRID_COLUMNS' | translate }}"
                                      [(validationMessageKey)]="designer?.pendingGrillValidationErrors['gridColumns']"
                                      [min]="1" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                                      [(ngModel)]="designer?.pendingGrillData.getGrillGridWithRhombusOnIntersections().columns"
                                      (ngModelChange)="grillParamsChange(GrillType.GRID_RHOMBUS, ['columns'])"
                                      [required]="true"></app-input-number>
                    <app-input-number inputId="grid3rows"
                                      label="{{ 'OFFER.DRAWING.GRILL.GRID_ROWS' | translate }}"
                                      [(validationMessageKey)]="designer?.pendingGrillValidationErrors['gridRows']"
                                      [min]="1" [max]="INPUT_NUMBER_DEFAULT_MAX_VALUE"
                                      [(ngModel)]="designer?.pendingGrillData.getGrillGridWithRhombusOnIntersections().rows"
                                      (ngModelChange)="grillParamsChange(GrillType.GRID_RHOMBUS, ['rows'])"
                                      [required]="true"></app-input-number>
                </div>
            </div>
            <div class="grillSelectColorInfo" *ngIf="isGrillExternalColorWithMatchingColors()">
                {{ 'OFFER.DRAWING.GRILL.SELECT_COLOR_INFO' | translate }}
            </div>
        </div>

        <!--Choose mullion-->
        <div *ngIf="showGrillTool === GrillType.MULLION" class="new-form-row pad-left10px">
            <app-select label="{{ 'OFFER.DRAWING.MULLION.SELECT_TYPE' | translate }}"
                        [(validationMessageKey)]="designer?.pendingGrillValidationErrors['mullion']"
                        [options]="systemMullionSelectItems | async"
                        [(ngModel)]="designer?.pendingGrillData.grill.id"
                        (ngModelChange)="grillParamsChange(GrillType.MULLION, ['id'])"
                        [required]="true"></app-select>
            <div></div>
        </div>
    </div>
</div>
<app-ral-color-selection-dialog *ngIf="selectingRalColors != undefined" [header]="selectingRalColorHeader | translate"
                                [colors]="selectingRalColors" (onColorSelect)="handleGrillRalColorSelected($event)"
                                (onCancel)="handleGrillRalColorSelectionCancel()"></app-ral-color-selection-dialog>
