import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {saveAs} from 'file-saver';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {Table} from 'primeng/table';
import {EMPTY, from, Observable} from 'rxjs';
import {catchError, finalize, mergeAll, mergeMap} from 'rxjs/operators';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ComponentWithUserConfigAndPaginator, KeepSelectedItemEventParams} from '../../../common/crud-common/paginable.component';
import {DatatableHelper, DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TristateCheckboxState} from "../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {SupportedLanguages} from '../../../supportedLanguages';
import {TranslationImportStatus, TranslationsService} from '../translations.service';
import {TranslationTarget} from './translation-target.enum';
import {UiTranslation} from './ui-translation';

@Component({
    selector: 'app-ui-translations',
    templateUrl: './ui-translations.component.html',
    styleUrls: ['./ui-translations.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiTranslationsComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    readonly supportedLanguages = SupportedLanguages.languages;

    translations: UiTranslation[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    selectedItem: UiTranslation;
    availableTargets: Observable<SelectItem[]>;
    selectedTargets: TranslationTarget[];

    editedItem: UiTranslation;
    importedFile: File;
    showImportChangesDialog = false;
    importChanges: TranslationImportStatus[];
    allSelectedImportRowsState = TristateCheckboxState.UNCHECKED;
    selectedImportRows: TranslationImportStatus[] = [];

    private readonly translationsService: TranslationsService;
    private readonly errors: CommonErrorHandler;
    private readonly translatedSelectItemService: TranslatedSelectItemService;
    readonly permissions: Permissions;

    @ViewChild('table', {static: true})
    table: Table;

    @ViewChild('importDialog', {static: true})
    importDialog: Dialog;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'UiTranslationsComponent', false);
        this.translationsService = injector.get(TranslationsService);
        this.errors = injector.get(CommonErrorHandler);
        this.translatedSelectItemService = injector.get(TranslatedSelectItemService);
        this.permissions = injector.get(Permissions);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.availableTargets = this.translatedSelectItemService.buildUnsortedDropdown(TranslationTarget, 'TRANSLATION_TARGET.', undefined);

        this.handleTargetFilterChange([]);
    }

    showDialogToAdd(): void {
    }

    submit(): void {
        this.translationsService.saveUiTranslations(this.editedItem).subscribe({
            next: () => {
                this.selectedItem.text = this.editedItem.text;
                this.selectedItem.lastModifiedManually = new Date();
                this.editedItem = undefined;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            }
        });
    }

    onRowSelect(event: { data: UiTranslation } & KeepSelectedItemEventParams): void {
        this.editedItem = UiTranslation.fromJSON(event.data);
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        super.loadItemsLazy(event);
        this.translationsService.getUiTranslations(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => {
                this.blockUiController.unblock('TranslationsComponentData');
                this.hideDataLoadingIndicator();
            }))
            .subscribe({
                next: data => {
                    this.translations = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.translations, event, item => item.key);
                },
                error: error => {
                    this.errors.handle(error);
                    this.changeDetector.markForCheck();
                },
                complete: () => {
                    this.changeDetector.markForCheck();
                }
            });
    }

    getTableAdapter(table: Table): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(table);
    }

    getDatatable(): DatatableInterface {
        return this.getTableAdapter(this.table);
    }

    rowTrackById(index: number, item: UiTranslation): string {
        return item.key;
    }

    handleTargetFilterChange(targets: TranslationTarget[]): void {
        if (targets.length === 0) {
            targets = Object.keys(TranslationTarget).map(target => TranslationTarget[target]);
        }
        this.selectedTargets = targets;
        this.table.filter(targets.join(';'), 'target', 'in');
    }

    handleTextFilterChange(value: string, code: keyof MultilanguageFieldInterface): void {
        let textFilter = this.table.filters['text'];
        if (textFilter == undefined && value == undefined) {
            // filter doesnt exist and new filter is not set
            return;
        }
        let textFilterValue = textFilter != undefined ? (Array.isArray(textFilter) ? textFilter[0].value : textFilter.value) : {};
        textFilterValue[code] = value;
        if (Object.values(textFilterValue).filter(v => !!v).length === 0) {
            textFilterValue = undefined;
        }
        this.table.filter(textFilterValue, 'text', 'contains');
    }

    handleDownloadAllClick(): void {
        this.translationsService.downloadAllUiTranslations().subscribe({
            next: translations => {
                // explicit BOM for excel
                const BOM = "\uFEFF";
                saveAs(new Blob([BOM, translations]), 'translations.csv', {autoBom: false});
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    handleImportFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        const files = input.files;
        this.importedFile = files[0];
        input.value = null;
        if (this.importedFile != undefined) {
            from(this.importedFile.text()).pipe(
                mergeMap(csv => this.translationsService.checkImportedTranslations(csv))
            ).subscribe(result => {
                this.importChanges = result;
                this.allSelectedImportRowsState = TristateCheckboxState.CHECKED;
                this.selectAllImportRowsChange();
                this.showImportChangesDialog = true;
                this.changeDetector.markForCheck();
            });
        }
    }

    isLanguageChangedOnImport(translation: TranslationImportStatus, language: string): boolean {
        return translation.changedLanguages.includes(language);
    }

    selectAllImportRowsChange() {
        this.selectedImportRows = [];
        if (this.allSelectedImportRowsState === TristateCheckboxState.CHECKED) {
            this.selectedImportRows = this.importChanges.filter(change => change.keyValid);
        }
        this.changeDetector.markForCheck();
    }

    isSelectedImportRow(item: TranslationImportStatus): boolean {
        return this.selectedImportRows.indexOf(item) > -1;
    }

    selectImportRow(item: TranslationImportStatus) {
        let index = this.selectedImportRows.indexOf(item);
        if (index > -1) {
            this.selectedImportRows.splice(index, 1);
        } else {
            this.selectedImportRows.push(item);
        }
        if (this.selectedImportRows.length === 0) {
            this.allSelectedImportRowsState = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedImportRows.length === this.importChanges.filter(change => change.keyValid).length) {
            this.allSelectedImportRowsState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedImportRowsState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    handleImportFileConfirmed(event: Event): void {
        this.importedFile = undefined;
        if (this.selectedImportRows.length === 0) {
            this.importDialog.close(event);
            return;
        }
        from(this.selectedImportRows.map(importRow => {
            return this.translationsService.saveUiTranslations({key: importRow.key, target: importRow.target, text: importRow.newTexts})
                .pipe(catchError(error => {
                    this.errors.handle(error);
                    return EMPTY;
                }));
        })).pipe(
            mergeAll(10),
            finalize(() => {
                this.changeDetector.markForCheck();
            })
        ).subscribe({
            complete: () => {
                this.importDialog.close(event);
                DatatableHelper.reload(this.table);
                this.growlMessageController.info('TRANSLATIONS.UI_TRANSLATIONS.IMPORT_SUCCESS');
            }
        });
    }

    handleAutoTranslateUi(): void {
        this.blockUiController.block('autoTranslateUi');
        this.translationsService.autoTranslateUi().pipe(finalize(() => {
            this.blockUiController.unblock('autoTranslateUi');
            DatatableHelper.reload(this.table);
            this.hideDataLoadingIndicator();
        })).subscribe({
            next: () => this.growlMessageController.info("TRANSLATIONS.UI_TRANSLATIONS.AUTO_TRANSLATE_SUCCESS"),
            error: error => this.errors.handle(error)
        });
    }
}
