import {PrintPricesDetailLevel} from './print-prices-detail-level.enum';
import {DocumentTemplateType} from "./document-template-type.enum";

export class SinglePrintConfig {
    printPricesDetailLevel: PrintPricesDetailLevel;
    printQuantitiesDetails: boolean;
    printOnlyGrossPrices: boolean;
    roundPrices: boolean;
    warrantyInfo: boolean;
    hideDimensions: boolean;
    hideOpenings: boolean;
    showLeaflets: boolean;
    showWeight: boolean;
    showUpselling: boolean;
    showRot: boolean;
    showGrillsLayout: boolean;
    hideAssemblyAndTransportDisclaimer: boolean;
    forceCurrentExchangeRate: boolean;
    forceExchangeRate: number;
    hideDealerDiscount: boolean;
    hideWindowSystemHeaders: boolean;

    constructor(documentTemplateType?: DocumentTemplateType) {
        if (documentTemplateType !== undefined) {
            this.printPricesDetailLevel = PrintPricesDetailLevel.DO_NOT_HIDE;
            this.warrantyInfo = true;
            this.hideDimensions = false;
            this.hideOpenings = false;
            this.showWeight = true;
            this.showUpselling = true;
            this.showGrillsLayout = false;
            this.hideAssemblyAndTransportDisclaimer = false;
            this.hideDealerDiscount = false;
            this.hideWindowSystemHeaders = false;
            switch (documentTemplateType) {
                case DocumentTemplateType.MEDIUM:
                    this.printQuantitiesDetails = true;
                    this.printOnlyGrossPrices = false;
                    this.roundPrices = false;
                    this.showRot = true;
                    break;
                case DocumentTemplateType.FULL:
                    break;
                case null:
                    // Glamour print is null template for now
                    this.showLeaflets = true;
                    break;
                default:
                    throw new Error('Unsupported DocumentTemplateType: ' + DocumentTemplateType[documentTemplateType]);
            }
        }
    }
}
