import {WindowDesignerInterface} from '../window-designer-interface';
import {PainterParams} from "./PainterParams";
import {WindowParams} from "./WindowParams";
import {ScalingPainter} from "./ScalingPainter";
import {SubwindowSortHelper} from "../utils/subwindow-sort-helper";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {TerraceHandleLayout} from "../drawing-data/TerraceHandleLayout";
import {CompositionType} from "../drawing-data/CompositionType";
import * as _ from "underscore";
import {DrawingUtil} from "../drawing-util";
import {GlazingHelper} from "../glazing-helper";
import {WindowCalculator} from "../window-calculator";
import {WindowData} from "../drawing-data/WindowData";
import {SubwindowDependencies} from "../utils/SubwindowDependencies";

enum ConnectorsDirection {
    UPWARD = 'UPWARD',
    DOWNWARD = 'DOWNWARD'
}

enum HalfViewSide {
    LEFT = 'LEFT',
    RIGHT = 'RIGHT'
}

class TopDownHalfView {
    hasHandle: boolean;
    connectorsDirection: ConnectorsDirection;
    xPos: number;
    side: HalfViewSide;
    parent: TopDownSubwindowView;

    constructor(xPos: number, side: HalfViewSide, parent: TopDownSubwindowView) {
        this.hasHandle = true;
        this.connectorsDirection = ConnectorsDirection.UPWARD;
        this.xPos = xPos;
        this.side = side;
        this.parent = parent;
    }
}

class TopDownSubwindowView {
    subwindow: SubWindowData;
    leftHalf: TopDownHalfView;
    rightHalf: TopDownHalfView;
    halves: TopDownHalfView[];
    railNumber: number;

    constructor(subwindow: SubWindowData) {
        this.subwindow = subwindow;
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(subwindow.points);
        this.leftHalf = new TopDownHalfView(box.minX, HalfViewSide.LEFT, this);
        this.rightHalf = new TopDownHalfView(box.maxX, HalfViewSide.RIGHT, this);
        this.halves = [this.leftHalf, this.rightHalf];
    }
}

export class TopDownViewPainter {

    static readonly EDGE_SIZE = 3;
    static readonly PROFILE_SIZE = 44;
    static readonly PROFILES_SPACING = 3;
    static readonly SPACING_SIZE = 20;
    static readonly HALF_GLASS_SIZE = 30;
    static readonly GLASS_PROFILE_OVERLAP = 4;

    public static paint(designer: WindowDesignerInterface, params: PainterParams, isTerrace: boolean): void {
        if (!params.isRegularMode() || !isTerrace || !this.supported(designer)) {
            return;
        }
        let window = designer.data.windows[0];
        let dependencies = new SubwindowDependencies(window);
        let subwindowViews = this.mapSubwindowViews(window, dependencies);
        let nonScaledWidth = this.calculateNonScaledWidth(subwindowViews, this.countDependencies(dependencies));
        let scale = (designer.totalBoundingBox.maxX - designer.totalBoundingBox.minX) / nonScaledWidth;
        params = JSON.parse(JSON.stringify(params));
        params.scale = scale;

        for (let i = 0; i + 1 < subwindowViews.length; i++) {
            let leftNeighbour = subwindowViews[i];
            let rightNeighbour = subwindowViews[i + 1];

            if (dependencies.get(leftNeighbour.subwindow).includes(rightNeighbour.subwindow)) {
                rightNeighbour.leftHalf.connectorsDirection = ConnectorsDirection.DOWNWARD;
            }
            if (dependencies.get(rightNeighbour.subwindow).includes(leftNeighbour.subwindow)) {
                leftNeighbour.rightHalf.connectorsDirection = ConnectorsDirection.DOWNWARD;
            }
            if (leftNeighbour.subwindow.profilesComposition.right === CompositionType.SLIDING_CONTACT) {
                leftNeighbour.rightHalf.xPos -= (this.PROFILE_SIZE / 2) * params.scale;
            }
            if (rightNeighbour.subwindow.profilesComposition.left === CompositionType.SLIDING_CONTACT) {
                rightNeighbour.leftHalf.xPos += (this.PROFILE_SIZE / 2) * params.scale;
            }
            if (i === 0) {
                leftNeighbour.leftHalf.xPos += (this.EDGE_SIZE + this.PROFILE_SIZE / 2) * params.scale;
            }
            if (i + 2 === subwindowViews.length) {
                rightNeighbour.rightHalf.xPos -= (this.EDGE_SIZE + this.PROFILE_SIZE / 2) * params.scale;
            }
        }

        let handleLayout = designer.data.specification.terraceHandleLayout || TerraceHandleLayout.EVERYWHERE;
        switch (handleLayout) {
            case TerraceHandleLayout.EVERYWHERE:
                // default - already done
                break;
            case TerraceHandleLayout.EVERYWHERE_ONLY_INSIDE:
                _.flatten(subwindowViews.map(swv => swv.halves))
                    .filter(half => half.connectorsDirection === ConnectorsDirection.DOWNWARD)
                    .forEach(half => half.hasHandle = false);
                break;
            case TerraceHandleLayout.EDGES_ONLY_INSIDE:
                _.flatten(subwindowViews.map(swv => swv.halves))
                    .forEach(half => half.hasHandle = false);
                subwindowViews[0].leftHalf.hasHandle = true;
                subwindowViews[subwindowViews.length - 1].rightHalf.hasHandle = true;
                break;
            case TerraceHandleLayout.CENTER:
                _.flatten(subwindowViews.map(swv => swv.halves))
                    .forEach(half => half.hasHandle = false);
                let maxRailNumber = Math.max(...subwindowViews.map(swv => swv.railNumber));
                subwindowViews.filter(swv => swv.railNumber === maxRailNumber).forEach(swv => {
                    if (swv.subwindow.profilesComposition.left === CompositionType.SLIDING_CONTACT) {
                        swv.leftHalf.hasHandle = true;
                    }
                    if (swv.subwindow.profilesComposition.right === CompositionType.SLIDING_CONTACT) {
                        swv.rightHalf.hasHandle = true;
                    }
                });
                break;
            case TerraceHandleLayout.NOWHERE:
                _.flatten(subwindowViews.map(swv => swv.halves))
                    .forEach(half => half.hasHandle = false);
                break;
            default:
                throw new Error('Unsupported TerraceHandleLayout: ' + handleLayout);
        }

        let g = designer.svg.group();
        for (let i = 0; i < subwindowViews.length; i++) {
            let view = subwindowViews[i];

            if (i === 0) {
                let frame = this.paintFrame(designer, params, view.leftHalf);
                frame.transform(`t${designer.totalBoundingBox.minX},0,s-1,1`);
                g.add(frame);
            } else if (i + 1 === subwindowViews.length) {
                let frame = this.paintFrame(designer, params, view.rightHalf);
                frame.transform(`t${designer.totalBoundingBox.maxX},0`);
                g.add(frame);
            }
            g.add(...view.halves.map(h => this.paintProfile(h, designer, params)));
            g.add(...this.paintGlass(view, designer, params));
        }
        g.transform(`t0,${this.getTotalYTransform(designer, params.scale)}`);
    }

    public static supported(designer: WindowDesignerInterface): boolean {
        return designer.data.windows !== undefined && designer.data.windows.length === 1 &&
            designer.data.windows.every(w => w.subWindows.every(sw => WindowCalculator.isSubWindowCopalSliding(sw)));
    }

    private static mapSubwindowViews(window: WindowData, dependencies: SubwindowDependencies): TopDownSubwindowView[] {
        let subwindowViews = window.subWindows.map(sw => new TopDownSubwindowView(sw));
        subwindowViews.forEach(swv => swv.railNumber = this.getRailNumber(swv.subwindow, dependencies));
        return subwindowViews;
    }

    private static countDependencies(dependencies: SubwindowDependencies): number {
        let dependenciesCount = 0;
        dependencies.dependencies.forEach((d) => {
            dependenciesCount += d.length;
        });
        return dependenciesCount;
    }

    private static getTotalYTransform(designer: WindowDesignerInterface, scale: number): number {
        return designer.totalBoundingBox.maxY + TopDownViewPainter.PROFILE_SIZE * scale;
    }

    private static getRailNumber(sw: SubWindowData, dependencies: SubwindowDependencies): number {
        return Math.max(...dependencies.get(sw).map(d => this.getRailNumber(d, dependencies) + 1), 1);
    }

    private static p3101withHandle(designer: WindowDesignerInterface, params: PainterParams): Snap.Element {
        let g = designer.svg.g();
        let paths = [
            [0, 0, 23.9744, 0],
            [-20.5237, 0, -19.0512, 0],
            [-10.5256, -18.25, -10.5256, -1.5],
            [21.5244, -21.55, 22.5744, -21.55],
            [22.5744, -21.55, 22.5744, -20.25],
            [17.2744, -20.25, 17.2744, -21.55],
            [17.2744, -21.55, 18.3242, -21.55],
            [23.9744, -22.75, 21.5244, -22.75],
            [16.2744, -22.75, 16.2744, -20],
            [23.9744, 0, 23.9744, -22.75],
            [7.3742, -20, -20.5237, -20],
            [9.1613, -20, 16.2744, -20],
            [0.4756, -24.0013, 9.1613, -24.0013],
            [0.4025, -23.0067, 3.937, -22.5065],
            [23.9744, -20.75, 23.9744, -20.7581],
            [-19.7711, -15.8124, -19.7711, -18],
            [-18.3256, -18.25, -18.3256, -14.2519],
            [-19.7711, -14.2519, -19.7711, -18],
            [-19.7711, -18, -19.7711, -18.25],
            [-19.7711, -18.25, -20.5237, -18.25],
            [-18.0707, -20, -17.8457, -20],
            [-20.5237, -1.75, -19.7711, -1.75],
            [-19.7711, -1.95, -19.7711, -2],
            [-19.7711, -1.75, -19.7711, -5.7498],
            [-18.3256, -5.7498, -18.3256, -1.5],
            [-9.0256, -18.25, 22.4744, -18.25],
            [-18.3256, -18.25, -10.5256, -18.25],
            [22.5744, -20.25, 17.2744, -20.25],
            [-9.0256, -18.25, -9.0256, -1.5],
            [-20.5237, -18.25, -20.5237, -20],
            [-20.5237, -1.75, -20.5237, 0],
            [4.011, -22.5016, 7.3742, -22.5016],
            [5.7244, -22.5934, 5.7244, -22.5016], // TODO czy ten powinien zostac
            [18.3242, -22.75, 16.2744, -22.75],
            [18.3242, -22.75, 18.3242, -21.55],
            [21.5244, -22.75, 21.5244, -21.55],
            [-9.0256, -1.5, 22.4744, -1.5],
            [-18.3256, -1.5, -10.5256, -1.5],
            [22.4744, -1.5, 22.4744, -18.25],
            [-18.3256, -5.7498, -19.7711, -5.7498],
            [-18.3256, -14.2519, -19.7711, -14.2519],
            [7.3742, -22.5016, 7.3742, -20],
            [9.1613, -20, 9.1613, -24.0013],
            [-4.4256, 24.2, -14.6256, 24.2],
            [-2.9711, 21, -16.0801, 21],
            [-2.9562, 0.5, -16.095, 0.5],
        ];

        let arcs = [
            [0.4025, -23.0067, 0.5, 0.5, 0, 0.4756, -24.0013],
            [4.011, -22.5016, 0.5, 0.5, 0, 3.937, -22.5065],
            [-19.0512, 0, 12, 12, 0, -19.2684, 15.0003],
            [-19.4255, 21.8001, 5.5, 5.5, 0, -19.2685, 15.0003],
            [-14.6256, 24.2, 6, 6, 0, -19.4255, 21.8001],
            [0.3744, 21.8, 6, 6, 0, -4.4256, 24.2],
            [0.2173, 15.0003, 5.5, 5.5, 0, 0.3744, 21.8],
            [0.2173, 15.0003, 12, 12, 0, 0, 0],
            [-1.108, 16.833, 14.2, 14.2, 0, -2.9562, 0.5],
            [-1.108, 16.833, 2.5, 2.5, 0, -2.9711, 21],
            [-16.0801, 21, 2.5, 2.5, 0, -17.9432, 16.833],
            [-16.095, 0.5, 14.2, 14.2, 0, -17.9432, 16.833]
        ];

        g.add(...this.paintPaths(paths, params, designer.svg));
        g.add(...this.paintArcs(arcs, params, designer.svg));
        return g;
    }

    private static paintPaths(paths: number[][], params: PainterParams, svg: Snap.Paper): Snap.Element[] {
        return paths.map(p => ScalingPainter.path(svg, p, WindowParams.topdownView, params));
    }

    private static paintArcs(arcs: number[][], params: PainterParams, svg: Snap.Paper): Snap.Element[] {
        arcs = arcs.map(a => ScalingPainter.scale(params, a));
        let arcsPaths = arcs.map(a => `M${a[0]},${a[1]} A${a[2]},${a[3]} ${a[4]} 0,1 ${a[5]},${a[6]}`);
        return arcsPaths.map(p => svg.path(p).attr(WindowParams.topdownView));
    }

    private static p3101(designer: WindowDesignerInterface, params: PainterParams): Snap.Element {
        let g = designer.svg.g();
        let paths = [
            [-20.0509, -6.4993, -12.2509, -6.4993],
            [-12.2509, -6.4993, -12.2509, 10.5007],
            [-12.2509, 10.5007, -20.0509, 10.5007],
            [-10.7509, 10.5007, 21.0491, 10.5007],
            [22.2491, 12.0007, 22.2491, -10.6993],
            [5.7996, -6.4993, 21.0491, -6.4993],
            [-10.7509, -6.4993, 5.7996, -6.4993],
            [15.5491, -8.1993, 20.8491, -8.1993],
            [6.827, -7.9993, 14.5491, -7.9993],
            [-21.2509, -3.8117, -21.2509, -5.9993],
            [-21.2509, -3.8117, -21.2509, -5.7993],
            [-20.0509, -6.4993, -20.0509, -2.2493],
            [-21.2509, -2.2493, -21.2509, -6.2493],
            [-21.2509, -3.8117, -21.2509, -5.8493],
            [-21.2509, 10.2507, -21.2509, 6.2507],
            [-21.2509, -6.2493, -22.2491, -6.2493],
            [-19.796, -7.9993, -19.571, -7.9993],
            [-22.2491, -7.9993, 5.4123, -7.9993],
            [22.2491, 12.0007, -22.2491, 12.0007],
            [-19.571, 12.0007, -19.796, 12.0007],
            [-22.2491, 10.2507, -21.2509, 10.2507],
            [-20.0509, 6.2507, -20.0509, 10.5007],
            [20.8491, -9.4993, 20.8491, -8.1993],
            [15.5491, -8.1993, 15.5491, -9.4993],
            [15.5491, -9.4993, 15.9991, -9.4993],
            [16.5991, -10.6993, 14.5491, -10.6993],
            [22.2491, -10.6993, 19.7991, -10.6993],
            [14.5491, -10.6993, 14.5491, -7.9993],
            [22.2491, -8.6993, 22.2491, -8.7074],
            [-1.2498, -12.0007, 3.9991, -12.0007],
            [-1.3228, -11.006, 5.0767, -10.1004],
            [21.0491, 10.5007, 21.0491, -6.4993],
            [-10.7509, -6.4993, -10.7509, 10.5007],
            [-22.2491, 10.2507, -22.2491, 12.0007],
            [-22.2491, -7.9993, -22.2491, -6.2493],
            [-20.0509, -2.2493, -21.2509, -2.2493],
            [-20.0509, 6.2507, -21.2509, 6.2507],
            [16.5991, -9.4993, 16.5991, -10.6993],
            [19.7991, -9.4993, 19.7991, -10.6993],
            [16.5991, -9.4993, 15.9991, -9.4993],
            [20.8491, -9.4993, 19.7991, -9.4993],
            [6.827, -7.9993, 6.827, -12.0007],
            [6.827, -12.0007, 3.9991, -12.0007],
            [5.4123, -7.9993, 5.4123, -10.1004],
            [5.4123, -10.1004, 5.0767, -10.1004],
        ];

        let arcs = [
            [-1.3228, -11.006, 0.5, 0.5, 0, -1.2498, -12.0007],
            [2.2857, -10.5009, 0.5, 0.5, 0, 2.2117, -10.5059],
            [2.2857, -10.5009, 0.5, 0.5, 0, 2.2117, -10.5059],
        ];

        g.add(...this.paintPaths(paths, params, designer.svg));
        g.add(...this.paintArcs(arcs, params, designer.svg));
        return g;
    }

    private static getBaseFramePoints(view: TopDownHalfView): number[] {
        return view.side === HalfViewSide.LEFT ?
            [0, -17, 23, -17, 23, 13, 0, 13, 0, 11.3, 21.3, 11.3, 21.3, -15.3, 0, -15.3] :
            [-23, -17, 0, -17, 0, 13, -23, 13, -23, 11.3, -1.7, 11.3, -1.7, -15.3, -23, -15.3];
    }

    private static calculateNonScaledWidth(subwindowViews: TopDownSubwindowView[], dependenciesCount: number): number {
        return (2 * this.EDGE_SIZE)
            + subwindowViews.length * (2 * (this.PROFILE_SIZE + this.HALF_GLASS_SIZE) + this.SPACING_SIZE)
            - dependenciesCount * this.PROFILE_SIZE;
    }

    private static getRailShift(view: TopDownSubwindowView): number {
        return view.railNumber * (this.PROFILE_SIZE / 2 + this.PROFILES_SPACING);
    }

    private static paintFrame(designer: WindowDesignerInterface, params: PainterParams,
                              view: TopDownHalfView): Snap.Element {
        let framePoints = DrawingUtil.shiftArrayInYAxis(this.getBaseFramePoints(view), this.getRailShift(view.parent));
        return ScalingPainter.path(designer.svg, framePoints, WindowParams.topdownView, params);
    }

    private static paintGlass(view: TopDownSubwindowView, designer: WindowDesignerInterface, params: PainterParams): Snap.Element[] {
        let elements = [];
        let glazing = view.subwindow.areasSpecification[0].glazing; // TODO - what should we do with multiple areas?
        let totalWidth = GlazingHelper.getGlazingTotalWidth(glazing, designer.staticData.glasses,
            designer.staticData.frames);
        let yPos = this.getRailShift(view);
        let halfWidth = totalWidth / 2;
        let leftGlassStart = (view.leftHalf.xPos / params.scale) + this.PROFILE_SIZE / 2;
        let rightGlassStart = (view.rightHalf.xPos / params.scale) - this.PROFILE_SIZE / 2 - this.HALF_GLASS_SIZE;

        let yTop = yPos - halfWidth;
        for (let i = 1; i <= glazing.glazingGlassQuantity; i++) {
            let yBottom = yTop + GlazingHelper.getNthGlassThickness(glazing, i, designer.staticData.glasses);

            let leftGlassPath = [
                leftGlassStart - this.GLASS_PROFILE_OVERLAP, yTop,
                leftGlassStart + this.HALF_GLASS_SIZE, yTop,
                leftGlassStart + this.HALF_GLASS_SIZE, yBottom,
                leftGlassStart - this.GLASS_PROFILE_OVERLAP, yBottom
            ];
            let rightGlassPath = [
                rightGlassStart, yTop,
                rightGlassStart + this.HALF_GLASS_SIZE + this.GLASS_PROFILE_OVERLAP, yTop,
                rightGlassStart + this.HALF_GLASS_SIZE + this.GLASS_PROFILE_OVERLAP, yBottom,
                rightGlassStart, yBottom
            ];
            elements.push(ScalingPainter.path(designer.svg, leftGlassPath, WindowParams.topdownView, params));
            elements.push(ScalingPainter.path(designer.svg, rightGlassPath, WindowParams.topdownView, params));

            if (i < glazing.glazingGlassQuantity) {
                yTop = yBottom + GlazingHelper.getNthFrameThickness(glazing, i, designer.staticData.frames);
            }
        }

        return elements;
    }

    private static paintProfile(halfView: TopDownHalfView, designer: WindowDesignerInterface, params: PainterParams): Snap.Element {
        let yPos = params.scale * this.getRailShift(halfView.parent);
        let profile = halfView.hasHandle ?
            this.p3101withHandle(designer, params) :
            this.p3101(designer, params);
        let xCorrection = params.scale * (halfView.hasHandle ? -2 : 0);
        let yCorrection = params.scale * (halfView.hasHandle ? 10 : -2);
        let xScaleFactor = halfView.side === HalfViewSide.LEFT ? -1 : 1;
        let yScaleFactor = halfView.connectorsDirection === ConnectorsDirection.DOWNWARD ? -1 : 1;
        profile.transform(`t${halfView.xPos + xCorrection},${yPos + (halfView.connectorsDirection === ConnectorsDirection.DOWNWARD ? -1 : 1) * yCorrection},s${xScaleFactor},${yScaleFactor}`);
        return profile;
    }
}
