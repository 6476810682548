import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable} from "rxjs";
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {Country} from '../../../common/enums/country';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {VenskaSettingsService} from "./venska-settings.service";
import {VenskaData} from "./VenskaData";

@Component({
    selector: 'app-venska-settings',
    templateUrl: './venska-settings.component.html',
    styleUrls: ['../../shared-styles.css', '../settings.component.css', "./venska-settings.component.css"],
    providers: [VenskaSettingsService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class VenskaSettingsComponent implements OnInit {

    venskaData: VenskaData = new VenskaData();
    validationErrors = {};
    countries: Observable<SelectItem[]>;

    imageFile: File;
    logoGlamourFile: File;

    constructor(public permissions: Permissions,
                public changeDetector: ChangeDetectorRef,
                public venskaDataService: VenskaSettingsService,
                public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private growlMessageController: GrowlMessageController,
                private errors: CommonErrorHandler) {
    }

    ngOnInit() {
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
        this.getVenskaData();
    }

    private getVenskaData() {
        forkJoin({
            data: this.venskaDataService.getVenskaData(),
            logo: this.venskaDataService.getLogo(),
            logoGlamour: this.venskaDataService.getLogoGlamour()
        }).subscribe({
            next: data => {
                this.venskaData = data.data;
                this.imageFile = data.logo;
                this.logoGlamourFile = data.logoGlamour;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                console.debug('getVenskaData` completed!');
            }
        });
    }

    public saveChanges() {
        if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            this.venskaDataService.updateVenskaData(this.venskaData, this.imageFile, this.logoGlamourFile).subscribe({
                next: () => {
                    this.getVenskaData();
                    this.showSuccessMessage();
                },
                error: errorMessage => {
                    this.validationErrors = this.errors.handle(errorMessage);
                    this.changeDetector.markForCheck();
                },
                complete: () => {
                    console.debug('getUserProfile` completed!');
                }
            });
        }
    }

    handleImageFileChange(file: File): void {
        this.imageFile = file || new File([], null);
    }

    handleLogoGlamourFileChange(file: File): void {
        this.logoGlamourFile = file || new File([], null);
    }

    private showSuccessMessage() {
        this.growlMessageController.info('VENSKA_DATA.DATA_UPDATED');
    }
}
