import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {Permissions} from '../../../../auth/permission.service';
import {CommonErrorHandler} from '../../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../../common/ValidationErrorsHelper';
import {MultiValidator} from "../../../../shared/validator/input-validator";
import {SupportedLanguages} from '../../../../supportedLanguages';
import {Role} from '../../../admin-panel/role/role';
import {RoleService} from '../../../admin-panel/role/role.service';
import {News} from '../news';
import {NewsService} from '../news-service';
import {NewsValidator} from "../NewsValidator";

@Component({
    selector: 'app-news-editor',
    templateUrl: './news-editor.component.html',
    styleUrls: ['../../settings.component.css', './news-editor.component.css'],
    providers: [NewsService, RoleService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsEditorComponent implements OnInit, OnChanges {

    readonly supportedLanguages = SupportedLanguages.languages;

    @Input()
    news: News;

    @Input()
    futureNews: News[];

    @Input()
    currentNews: News;

    @Input()
    saveAsNew: boolean;

    @Input()
    saveCopy: boolean;

    @Output()
    saveAsNewChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output()
    readonly newsFinished = new EventEmitter<void>();

    @Output()
    readonly newsSaved = new EventEmitter<News>();

    @Output()
    copySaved = new EventEmitter<boolean>();

    roles: Role[];
    showPreview = false;
    initializeEditor = false;
    canEdit: boolean;
    validationErrors = {};
    currentLang: string;
    file: File;
    copyId: number = null;

    constructor(private translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private newsService: NewsService,
                private roleService: RoleService,
                private permissions: Permissions,
                private growlMessageController: GrowlMessageController,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
        this.currentLang = this.translate.currentLang;
    }

    ngOnInit(): void {
        this.canEdit = this.permissions.canEditNews();
        this.roleService.getItems(undefined, undefined, {
            primary: {value: 'true'},
            includeSystemRoles: {value: 'false'}
        }, undefined, undefined).subscribe({
            next: response => {
                this.roles = response.data;
                this.initializeEditor = true;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const newsChange = changes['news'];
        if (newsChange != undefined) {
            this.validationErrors = {};
            this.file = undefined;
            if (newsChange.currentValue != undefined && newsChange.currentValue.id != undefined) {
                this.newsService.getImage(newsChange.currentValue.id).subscribe(image => {
                    this.file = image;
                    this.changeDetector.markForCheck();
                });
            }
        }
        const saveCopyChange = changes['saveCopy'];
        if (saveCopyChange && saveCopyChange.currentValue) {
            this.copyId = this.news.id;
            this.saveNews(false);
        }
    }

    saveNews(publish: boolean): void {
        this.validateForm(publish);
        if (this.validationErrorsPresent(this.validationErrors)) {
            this.changeDetector.markForCheck();
        } else {
            const toSave = this.prepareNewsToSave(publish);
            this.newsService.saveNews(toSave, !this.saveAsNew && publish, this.file, this.copyId).subscribe({
                next: newId => {
                    this.news.id = newId;
                    this.news.validFrom = toSave.validFrom;
                    this.news.validTo = toSave.validTo;
                    this.saveAsNewChange.emit(false);
                    this.growlMessageController.info('NEWS.SAVED');
                    this.newsSaved.emit(this.news);
                    this.copyId = null;
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error, true);
                    this.parseContentError();
                    this.changeDetector.markForCheck();
                    if (this.saveCopy) {
                        this.copySaved.emit(false);
                    }
                }
            });
        }
    }

    private parseContentError() {
        if (this.validationErrors['content'] != null) {
            let msg = this.validationErrors['content'];
            let pattern = /error\.newsDto\.content\.(\S{2})\.(.*)/;
            let groups = msg.match(pattern);
            if (groups != null) {
                let lang = groups[1];
                this.validationErrors[`content[${lang}]`] = 'error.newsDto.content.' + groups[2];
            }
        }
    }

    isVisibleForUsersWithRoleOtherThanKoordynator(): boolean {
        return this.news != undefined &&
            this.news.validFrom != undefined;
    }

    openPreview(lang: string) {
        this.currentLang = lang;
        this.showPreview = true;
    }

    closePreview() {
        this.currentLang = this.translate.currentLang;
        this.showPreview = false;
    }

    getCurrentNewsTitle(): string {
        if (this.news && this.currentLang) {
            return this.news.title[this.currentLang];
        }
    }

    getCurrentNewsContent(): string {
        if (this.news && this.currentLang) {
            return this.news.content[this.currentLang];
        }
    }

    validateForm(toPublish: boolean): void {
        this.validationErrors = {};

        if (this.news.validFrom == undefined) {
            this.validationErrors['validFrom'] = 'error.newsDto.validFrom.not_null';
        }
        if (this.news.validTo == undefined) {
            this.validationErrors['validTo'] = 'error.newsDto.validTo.not_null';
        }
        if (moment(this.news.validFrom.setSeconds(0)).isSame(this.news.validTo.setSeconds(0))) {
            this.validationErrors['validFrom'] = 'error.newsDto.valid_dates_overlap';
            this.validationErrors['validTo'] = 'error.newsDto.valid_dates_overlap';
        }
        if (moment(this.news.validTo).isBefore(this.news.validFrom)) {
            this.validationErrors['validTo'] = 'error.newsDto.validTo.beforeValidFrom';
        }
        if (!(!toPublish && !this.news.published)) {
            this.validateCollidingDates();
        }

        const validateLocalizedFields = (lang: string): void => {
            this.validationErrors[`title[${lang}]`] = NewsValidator.validateTitle(this.news.title[lang]);
            this.validationErrors[`content[${lang}]`] = MultiValidator.of('error.newsDto.content')
                .withNotNullValidator()
                .withNotBlankValidator()
                .validate(this.news.content[lang]);
        };

        if (toPublish) {
            this.supportedLanguages.forEach(lang => validateLocalizedFields(lang.code));
        } else {
            validateLocalizedFields(this.currentLang);
        }
    }

    private validateCollidingDates() {
        if (this.currentNews && this.currentNews.id !== this.news.id) {
            if (this.validateDate(this.currentNews, this.news.validTo)) {
                this.validationErrors['validTo'] = 'error.newsDto.validTo.collidesWithFutureNews';
            }
            if (this.validateDate(this.currentNews, this.news.validFrom)) {
                this.validationErrors['validFrom'] = 'error.newsDto.validTo.collidesWithFutureNews';
            }
        }
        if (this.futureNews) {
            if (this.futureNews.some(news =>
                this.validateDate(news, this.news.validTo))) {
                this.validationErrors['validTo'] = 'error.newsDto.validTo.collidesWithFutureNews';
            }
            if (this.futureNews.some(news =>
                this.validateDate(news, this.news.validFrom))) {
                this.validationErrors['validFrom'] = 'error.newsDto.validTo.collidesWithFutureNews';
            }
        }
    }

    private validateDate(news: News, dateToCheck: Date) {
        return moment(dateToCheck).isSameOrAfter(news.validFrom)
            && moment(dateToCheck).isSameOrBefore(news.validTo)
            && news.id !== this.news.id;
    }

    validationErrorsPresent(validationErrors?: { [field: string]: string }): boolean {
        if (!validationErrors) {
            validationErrors = this.validationErrors;
        }
        return Object.keys(validationErrors).filter(key => validationErrors[key] != undefined).length > 0;
    }

    editContent(content: string, lang: string): void {
        this.news.content[lang] = content;
        ValidationErrorsHelper.clearErrors(this.validationErrors, `content[${lang}]`);
        this.changeDetector.markForCheck();
    }

    isRoleSelected(role: Role): boolean {
        return this.news.visibleFor.includes(role.id);
    }

    handleRoleSelectionChange(role: Role, selected: boolean): void {
        const index = this.news.visibleFor.indexOf(role.id);
        if (selected) {
            if (index >= 0) {
                return;
            }
            this.news.visibleFor = [...this.news.visibleFor, role.id];
        } else {
            if (index < 0) {
                return;
            }
            this.news.visibleFor.splice(index, 1);
            this.news.visibleFor = [...this.news.visibleFor];
        }
    }

    private prepareNewsToSave(publish: boolean): News {
        const toSave = News.fromJSON(this.news);
        if (this.saveAsNew || toSave.id === undefined) {
            toSave.id = undefined;
        }
        toSave.published = publish;
        return toSave;
    }

    finishNews() {
        this.newsService.finishNews(this.news.id).subscribe(() => {
            this.growlMessageController.info('NEWS.FINISHED');
            this.newsFinished.emit();
        });
    }

    handleImageFileChange(file: File): void {
        this.file = file || new File([], null);
    }
}
