import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin} from 'rxjs';
import {finalize, map, mergeMap} from 'rxjs/operators';
import * as _ from 'underscore';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CechaGrupa} from '../../../../window-designer/enums/CechaGrupa';
import {ConfigAddonApplication} from '../../../../window-designer/enums/ConfigAddonApplication';
import {
    ConfigurableAddonDefinitionType,
    configurableAddonDefinitionTypeToTranslationKey
} from '../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {DataServiceHelper, FileState} from '../../../common/dataServiceHelper';
import {MotlawaIntegrationInfo} from "../../../common/MotlawaIntegrationInfo";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {TranslatedSelectItemImpl} from '../../../common/service/translated.select.item.impl';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {MultilanguageField, SupportedLanguages} from '../../../supportedLanguages';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {ConfigAddonField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {MessageSeverity} from '../../offer/offers/message';
import {ConfigurableAddonsService} from '../../offer/offers/position/config-addons.service';
import {ConfigurableAddonComponent} from '../../offer/offers/position/position-list/configurable-addon/configurableAddon.component';
import {Cecha} from '../../offer/offers/position/position-list/ConfigurableAddonModel/Cecha';
import {ConfigurableAddonDefinition} from '../../offer/offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonDefinition';
import {
    ConfigurableAddonDefinitionRoDto
} from '../../offer/offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonDefinitionRoDto';
import {
    ConfigurableAddonDefinitionSettings
} from '../../offer/offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonDefinitionSettings';
import {DefinitionParser} from '../../offer/offers/position/position-list/ConfigurableAddonModel/DefinitionParser';
import {SupplierService} from '../../supplier/supplier.service';
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {ConfigAddonFieldUsage} from "../catalog-field-usage";

@Component({
    selector: 'app-config-addons',
    templateUrl: './config-addons.component.html',
    styleUrls: ['../../shared-styles.css', './config-addons.component.css', '../../settings/settings.component.css'],
    providers: [ConfigurableAddonsService, DataServiceHelper, SupplierService, TranslatedSelectItemService,
        WindowSystemDefinitionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigAddonsComponent extends SingleSystemCheckboxCrudComponent<ConfigurableAddonDefinitionRoDto, ConfigurableAddonsService>
    implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT];

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };
    readonly route: ActivatedRoute;

    selectedConfigAddonType;

    groupedConfigAddons: any = {};
    addonsOfType: ConfigurableAddonDefinitionRoDto[];
    selectedConfigAddonDef: ConfigurableAddonDefinitionType;
    configAddon: ConfigurableAddonDefinitionRoDto = null;
    definition: ConfigurableAddonDefinition;
    suppliers: SelectItem[];
    availableApplicableTo: TranslatedSelectItem[] = [];
    supplier: number;
    translations = {};
    readonly SupportedLanguages = SupportedLanguages;
    description: MultilanguageField;

    @ViewChild('configurableAddonComponent') configurableAddonComponent: ConfigurableAddonComponent;
    @ViewChild(DataTable, {static: true}) dataTable: DataTable;

    cechyByGroup: Map<CechaGrupa, Cecha[]>;
    parsed;
    groups: { [group: string]: Cecha[] };
    offerItem: { cechy: { [cecha: string]: boolean }, messageLevels: { [severity: string]: boolean } };
    orderItem: { cechy: { [cecha: string]: boolean }, messageLevels: { [severity: string]: boolean } };

    cechyHiddenOnOffer: string[];
    cechyHiddenOnOrder: string[];
    messagesHiddenOnOffer: MessageSeverity[];
    messagesHiddenOnOrder: MessageSeverity[];
    printableMessageSeverityLevels: MessageSeverity[] = [MessageSeverity.WARNING, MessageSeverity.INFO, MessageSeverity.CODE];

    descriptionImage: File;
    descriptionImageLarge: File;

    horizontalPrintImageFile: FileState;
    verticalPrintImageFile: FileState;
    glamourDescription = new MultilanguageField();
    motlawaIntegrationInfo = new MotlawaIntegrationInfo();

    readOnlyMode: boolean;

    editPermits: FieldLimitation[] = [];
    fieldUsage: ConfigAddonFieldUsage;
    ConfigAddonField = ConfigAddonField;

    constructor(injector: Injector,
                private supplierService: SupplierService,
                private editCatalogPermitsService: EditCatalogPermitsService,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, ConfigurableAddonsService, 'OFFER.POSITIONS.ADDON_CONFIG', 'ConfigAddons');
        this.userLang = this.translate.currentLang;
        this.route = injector.get(ActivatedRoute);
        this.fieldUsage = new ConfigAddonFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.showDataLoadingIndicator();

        this.initAvailableApplicableToItems();

        let event = {
            first: 0,
            rows: 200,
            sortField: 'type',
            sortOrder: 1,
            filters: {}
        };

        let componentName = this.entityName + 'ListComponent';
        forkJoin({
            configAddons: this.itemService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder),
            suppliers: this.supplierService.getSupplierNames()
        })
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info(componentName + ' `getPage` success:');
                    this.itemList = data.configAddons.data;
                    this.totalRecords = data.configAddons.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.itemList, event);

                    this.groupedConfigAddons = _.groupBy(this.itemList, 'addonType');

                    this.suppliers = data.suppliers.map(s => {
                        return {label: s.companyName, value: s.id};
                    });

                    let addonTypesTranslationNames = Object.keys(this.groupedConfigAddons)
                        .map(key => 'OFFER.POSITIONS.ADDON_CONFIG.TYPES.' + key);
                    let typesTranslationNames = this.itemList
                        .map(item => configurableAddonDefinitionTypeToTranslationKey(item.type));
                    this.translate.get([...addonTypesTranslationNames, ...typesTranslationNames]).subscribe(translations => {
                        this.translations = translations;
                    });
                },
                error: error => {
                    console.error(componentName + ' `getPage` error:', error);
                    this.setErrors(error);
                    this.hideDataLoadingIndicator();
                },
                complete: () => {
                    console.info(componentName + ' `getPage` completed!');
                    this.changeDetector.markForCheck();
                    this.hideDataLoadingIndicator();
                }
            });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.CONFIG_ADDONS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'configurableaddons';
    }

    sort(event) {
        const tmp = this.itemList.sort((a: ConfigurableAddonDefinitionRoDto, b: ConfigurableAddonDefinitionRoDto): number => {
            if (event.field) {
                switch (event.field) {
                    case 'type':
                        return (this.translations[configurableAddonDefinitionTypeToTranslationKey(a[event.field])])
                        > (this.translations[configurableAddonDefinitionTypeToTranslationKey(b[event.field])])
                            ? event.order : -event.order;
                    case 'addonType':
                        return (this.translations['OFFER.POSITIONS.ADDON_CONFIG.TYPES.' + a[event.field]])
                        > (this.translations['OFFER.POSITIONS.ADDON_CONFIG.TYPES.' + b[event.field]])
                            ? event.order : -event.order;
                }
            }
        });
        // we're forcing table update
        this.itemList = [];
        this.itemList.push(...tmp);
    }

    doOnRowSelect(event) {
        super.doOnRowSelect(event);
        this.setDisplayDialog(false);

        this.selectedConfigAddonType = event.data.addonType;
        this.selectedConfigAddonDef = event.data.type;

        this.addonsOfType = this.groupedConfigAddons[this.selectedConfigAddonType];
        this.definition = null;
        this.readOnlyMode = false;
        this.resetFile();
        this.resetDescriptionFile();
        this.resetDescriptionFileLarge();
        this.resetGlamourHorizontalPrintImageFile();
        this.resetGlamourVerticalPrintImageFile();
        this.configAddon = this.addonsOfType.find(addon => addon.type === this.selectedConfigAddonDef);
        forkJoin({
            definition: this.itemService.getDefinition(this.configAddon.id),
            image: this.itemService.getImageForItemAsFile(this.configAddon.id),
            descriptionImage: this.itemService.getDescriptionImageForItemAsFile(this.configAddon.id),
            descriptionImageLarge: this.itemService.getDescriptionImageLargeForItemAsFile(this.configAddon.id),
            horizontalPrintImage: this.itemService.getGlamourHorizontalPrintImageAsFile(this.configAddon.id),
            verticalPrintImage: this.itemService.getGlamourVerticalPrintImageAsFile(this.configAddon.id),
            glamourDescription: this.itemService.getGlamourDescription(this.configAddon.id),
            motlawaIntegrationInfo: this.itemService.getMotlawaInfo(this.configAddon.id),
            linkedWindowSystems: this.getLinkedWindowSystems(this.configAddon.id)
        }).subscribe(
            data => {
                this.definition = data.definition;
                this.file = data[1];
                this.descriptionImage = data.descriptionImage;
                this.descriptionImageLarge = data.descriptionImageLarge;
                this.selectedWindowSystems = data.linkedWindowSystems;
                this.horizontalPrintImageFile = {file: data.horizontalPrintImage, needSave: false};
                this.verticalPrintImageFile = {file: data.verticalPrintImage, needSave: false};
                this.glamourDescription = data.glamourDescription;
                this.motlawaIntegrationInfo = data.motlawaIntegrationInfo;
                this.rebuildForm();
                this.setDisplayDialog(true);
                this.readOnlyMode = this.definition.type != null;
                this.changeDetector.markForCheck();
            }
        );

    }

    rebuildForm(): void {
        this.resetForm();
        this.cechyHiddenOnOffer = this.definition.hiddenOnOffer;
        this.cechyHiddenOnOrder = this.definition.hiddenOnOrder;
        this.messagesHiddenOnOffer = this.definition.messagesHiddenOnOffer;
        this.messagesHiddenOnOrder = this.definition.messagesHiddenOnOrder;
        this.printableMessageSeverityLevels.forEach(messageSeverity => this.setMessageLevelInItem(messageSeverity));
        this.description = this.definition.description;

        this.parsed = DefinitionParser.parseDefinition(this.definition);
        this.parsed.cechy.forEach((cecha) => {
            this.setCechaSettingsInItem(cecha);
            this.buildGroupsWithCechy(cecha);
        });

        this.suppliers.forEach(supplier => {
            if (supplier.value === this.definition.supplier.id) {
                this.supplier = supplier.value;
            }
        });

        Object.keys(this.groups)
            .forEach(groupName => {
                const cechyInGroup = this.groups[groupName];
                this.cechyByGroup.set(CechaGrupa[groupName], cechyInGroup);
            });
    }

    setCechaSettingsInItem(cecha: Cecha): void {
        this.offerItem.cechy[cecha.symbol] = !_.contains(this.cechyHiddenOnOffer, cecha.symbol);
        this.orderItem.cechy[cecha.symbol] = !_.contains(this.cechyHiddenOnOrder, cecha.symbol);
    }

    setMessageLevelInItem(messageSeverity: MessageSeverity): void {
        this.offerItem.messageLevels[messageSeverity] = !_.contains(this.messagesHiddenOnOffer, messageSeverity);
        this.orderItem.messageLevels[messageSeverity] = !_.contains(this.messagesHiddenOnOrder, messageSeverity);
    }

    handleMessageLevelChange(messageLevels: { [severity: string]: boolean }, severity: string, value: boolean): void {
        messageLevels[severity] = value;
    }

    private resetForm(): void {
        this.validationErrors = {};
        this.orderItem = {cechy: {}, messageLevels: {}};
        this.offerItem = {cechy: {}, messageLevels: {}};
        this.groups = {};
        this.cechyByGroup = new Map<CechaGrupa, Cecha[]>();
    }

    private buildGroupsWithCechy(cecha: Cecha): void {
        if (cecha.grupa === CechaGrupa.DIMENSIONS || cecha.grupa === CechaGrupa.BULK_DIMENSIONS) {
            return;
        }
        const group: string = CechaGrupa[cecha.grupa];
        if (this.groups.hasOwnProperty(group)) {
            this.groups[group].push(cecha);
        } else {
            this.groups[group] = [cecha];
        }
    }

    submit() {
        this.validationErrors['sortIndex'] = MultiValidator.of('error.ConfigurableAddonDefinitionSettingsDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(this.fromRecord, this.toRecord)
            .validate(this.configAddon.sortIndex);

        let validationErrorsPresent = Object.keys(this.validationErrors).some(key => this.validationErrors[key] !== undefined);
        if (!validationErrorsPresent) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let settings = this.getUpdatedSettings();
            this.itemService.updateDefinitionSettings(settings, this.file, this.descriptionImageLarge, this.motlawaIntegrationInfo)
                .pipe(mergeMap(updatedDefinition => {
                    return this.itemService.editLinks(this.getApiUrl(), updatedDefinition.id, this.prepareSystemIdsForRequest())
                        .pipe(map(() => updatedDefinition));
                }), mergeMap(updatedDefinition => {
                    return this.itemService.updateAddonGlamourPrintInfo(updatedDefinition.id, this.horizontalPrintImageFile,
                        this.verticalPrintImageFile, this.glamourDescription).pipe(map(() => updatedDefinition));
                }))
                .subscribe({
                    next: updatedDefinition => {
                        this.dialogHideHelper.call(() => {
                            const index = this.addonsOfType.findIndex(addon => addon.id === updatedDefinition.id);
                            this.addonsOfType[index] = Object.assign({}, updatedDefinition, {
                                pcv: this.addonsOfType[index].pcv,
                                aluminium: this.addonsOfType[index].aluminium,
                                wood: this.addonsOfType[index].wood,
                                aluminiumWoodMix: this.addonsOfType[index].aluminiumWoodMix
                            });
                            this.definition = updatedDefinition;
                            this.setSaveInProgress(false);
                            this.showSuccessMessage();
                            this.setDisplayDialog(false);
                            this.ngOnInit();
                        });
                    },
                    error: () => {
                        this.setSaveInProgress(false);
                        this.showErrorMessage();
                    }
                });
        }
    }

    cancel() {
        this.dialogHideHelper.call(() => {
            super.cancel();
            this.ngOnInit();
        });
    }

    private getUpdatedSettings() {
        let settings = new ConfigurableAddonDefinitionSettings();
        settings.definitionId = this.definition.id;
        settings.sortIndex = this.configAddon.sortIndex;
        settings.supplierId = this.supplier;
        settings.active = this.definition.active;
        settings.hiddenOnOffer = this.getListOfUncheckedCechaSymbols(this.offerItem.cechy);
        settings.hiddenOnOrder = this.getListOfUncheckedCechaSymbols(this.orderItem.cechy);
        settings.messagesHiddenOnOffer = this.getListOfUncheckedCechaSymbols(this.offerItem.messageLevels);
        settings.messagesHiddenOnOrder = this.getListOfUncheckedCechaSymbols(this.orderItem.messageLevels);
        settings.applicableTo = this.definition.applicableTo;
        settings.description = this.description;
        settings.canBeAddedToAreaWithOuterGrill = this.definition.canBeAddedToAreaWithOuterGrill;
        settings.canBeAddedToAreaWithoutFilling = this.definition.canBeAddedToAreaWithoutFilling;
        settings.canBeAddedToAreaWithOtherFilling = this.definition.canBeAddedToAreaWithOtherFilling;
        settings.canBeAddedToAreaWithDecorativeFilling = this.definition.canBeAddedToAreaWithDecorativeFilling;
        return settings;
    }

    private getListOfUncheckedCechaSymbols(item: { [key: string]: boolean }): string[] {
        return Object.keys(item).filter(symbol => !item[symbol]) || [];
    }

    showSuccessMessage() {
        this.growlMessageController.info('OFFER.POSITIONS.ADDON_CONFIG.DEFINITION_UPDATED');
    }

    showErrorMessage() {
        this.growlMessageController.error('OFFER.POSITIONS.ADDON_CONFIG.DEFINITION_UPDATE_FAILED');
    }

    groupsKeys(): CechaGrupa[] {
        return Object.keys(this.groups).map(k => CechaGrupa[k]);
    }

    private initAvailableApplicableToItems(): void {
        this.availableApplicableTo = Object.keys(ConfigAddonApplication).filter(element => element !== ConfigAddonApplication.WINDOW)
            .map(element => new TranslatedSelectItemImpl('OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.' + element, element));
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    getNewItem(): ConfigurableAddonDefinitionRoDto {
        return new ConfigurableAddonDefinitionRoDto();
    }

    resetDescriptionFile(): void {
        this.descriptionImage = null;
    }

    resetDescriptionFileLarge(): void {
        this.descriptionImageLarge = null;
    }

    resetGlamourHorizontalPrintImageFile(): void {
        this.horizontalPrintImageFile = {file: undefined, needSave: false};
    }

    resetGlamourVerticalPrintImageFile(): void {
        this.verticalPrintImageFile = {file: undefined, needSave: false};
    }

    onDescriptionImageLargeFileChange(newFile: File) {
        this.descriptionImageLarge = newFile;
        if (!newFile) {
            this.descriptionImageLarge = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleGlamourHorizontalPrintImageFileChange(newFile: File): void {
        this.horizontalPrintImageFile.file = newFile;
        this.horizontalPrintImageFile.needSave = true;
        if (!newFile) {
            this.horizontalPrintImageFile.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleGlamourVerticalPrintImageFileChange(newFile: File): void {
        this.verticalPrintImageFile.file = newFile;
        this.verticalPrintImageFile.needSave = true;
        if (!newFile) {
            this.verticalPrintImageFile.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleGlamourDescriptionChange(data: {text: string, lang: keyof MultilanguageFieldInterface}): void {
        this.glamourDescription[data.lang] = data.text;
        this.changeDetector.markForCheck();
    }
}
