<ng-container *ngIf="history && history.length > 0; else emptyHistory">
    <p-treeTable [value]="historyTree">
        <ng-template pTemplate="header">
            <tr>
                <th>{{ 'OFFER_HISTORY.COLUMN.API' | translate }}</th>
                <th>{{ 'OFFER_HISTORY.COLUMN.DATE' | translate }}</th>
                <th>{{ 'OFFER_HISTORY.COLUMN.USER' | translate }}</th>
                <th colspan="2">{{ 'OFFER_HISTORY.COLUMN.URL' | translate }}</th>
            </tr>
            <tr>
                <th>{{ 'OFFER_HISTORY.COLUMN.CHANGE_TYPE' | translate }}</th>
                <th>{{ 'OFFER_HISTORY.COLUMN.NAME' | translate }}</th>
                <th>{{ 'OFFER_HISTORY.COLUMN.DIMENSIONS' | translate }}</th>
                <th>{{ 'OFFER_HISTORY.COLUMN.SYSTEM' | translate }}</th>
                <th>{{ 'OFFER_HISTORY.COLUMN.ID' | translate }}</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-data="rowData">
            <tr class="p-treetable-row">
                <ng-container *ngIf="data.request">
                    <td>
                        <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                        {{ ('OFFER_HISTORY.FRIENDLY_API_NAME.' + data.request.apiClass + '.' + data.request.apiMethod) | translate }}
                    </td>
                    <td>{{ data.request.requestTimestamp | momentCurrentLang | amDateFormat: 'L LT' }}</td>
                    <td>{{ data.request.userName + (data.request.impersonatorUserName ? ' (' + data.request.impersonatorUserName + ')' : '') }}</td>
                    <td colSpan="2">{{ data.request.requestUri }}</td>
                </ng-container>
                <ng-container *ngIf="data.entry">
                    <td>{{ ('OFFER_HISTORY.CHANGE_TYPE.' + data.entry.changeType) | translate }}</td>
                    <td>{{ data.entry.offerPositionName | multilanguageTranslate }}</td>
                    <td>{{ data.entry.dimensions }}</td>
                    <td>{{ data.entry.systemName | multilanguageTranslate }}</td>
                    <td>{{ data.entry.offerPositionId }}</td>
                </ng-container>
            </tr>
        </ng-template>
    </p-treeTable>
</ng-container>

<ng-template #emptyHistory>
    <div [ngStyle]="{'padding.px': 20}">{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}</div>
</ng-template>

