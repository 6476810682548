<ng-template #labelTemplate>
    <span class="flex-row">
        <label [attr.for]="fieldId" *ngIf="cecha.nameCode != undefined">
            {{ cecha.nameCode | translate }}{{cecha.obowiazkowa && !(cecha.disabled || readOnlyMode) ? '*' : ''}}<ng-container *ngIf="cecha.jednostkaMiary"> [{{'ADDONS.QUANTITY_TYPE.ABBREVIATION.' + cecha.jednostkaMiary | translate}}]</ng-container>
        </label>
        <label [attr.for]="fieldId" *ngIf="cecha.nameCode == undefined"></label>
        <app-simple-button icon="image" *ngIf="displayImageIcon" type="filter" [size]="28" (onClick)="imageIconClick()"></app-simple-button>
    </span>
</ng-template>
<ng-template #validationMessageTemplate>
    <div *ngIf="errors[errorKey] != undefined" class="new-form-field-error-message" [innerHTML]="errors[errorKey] | translate"></div>
    <ng-container *ngIf="pricingErrors != undefined">
        <div *ngFor="let msg of pricingErrors" class="new-form-field-error-message" [innerHTML]="msg | translate"></div>
    </ng-container>
</ng-template>

<ng-container [ngSwitch]="cecha.type">
    <app-select *ngSwitchCase="CechaType.OPTIONS" [inputId]="fieldId" [validationMessageKey]="getAnyValidationMessage()"
                [disabled]="cecha.disabled || readOnlyMode" [options]="cecha.opcje" [optionFormatter]="formatSelectOption"
                [translateLabels]="true" [ngModel]="fieldValue" (ngModelChange)="onValueChange($event)">
        <ng-template pTemplate="label">
            <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
        </ng-template>
        <ng-template pTemplate="validationMessage">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-template>
    </app-select>
    <app-input-number *ngSwitchCase="CechaType.NUMERIC_VALUE" [inputId]="fieldId"
                      [validationMessageKey]="getAnyValidationMessage()" [disabled]="cecha.disabled || readOnlyMode"
                      [min]="cecha.minValue || 0" [max]="cecha.maxValue || 999999" [step]="cecha.step"
                      [ngModel]="fieldValue" (ngModelChange)="onValueChange($event)">
        <ng-template pTemplate="label">
            <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
        </ng-template>
        <ng-template pTemplate="validationMessage">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-template>
    </app-input-number>
    <app-text-area *ngSwitchCase="CechaType.TEXTAREA" [inputId]="fieldId" [ngModel]="fieldValue"
                   [validationMessageKey]="getAnyValidationMessage()" [disabled]="cecha.disabled || readOnlyMode"
                   (ngModelChange)="onValueChange($event)">
        <ng-template pTemplate="label">
            <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
        </ng-template>
        <ng-template pTemplate="validationMessage">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-template>
    </app-text-area>
</ng-container>
