import {Component, OnDestroy, OnInit} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {Subscription} from "rxjs";
import {filter} from 'rxjs/operators';
import {environment} from "../environments/environment";
import {AuthService} from "./auth/auth.service";
import {CurrentUserService} from "./auth/current-user.service";
import {Permissions} from "./auth/permission.service";
import {StorageKey, StorageService} from "./auth/storage.service";
import {ButtonWithMenuElementSelectedEvent} from "./common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder, MenuElementType} from "./common/button-with-menu/MenuElement";
import {MenuType} from "./common/button-with-menu/MenuType";
import {AccessData} from "./features/AccessData";
import {ChangeLanguageService} from "./features/login/change-language.service";
import {IsAnotherUserLoggedService} from "./features/login/isAnotherUserLogged";
import {IsLoggedService} from "./features/login/islogged.service";
import {ReportsService} from "./features/reports/reports.service";
import {NavigationLink} from "./NavigationLink";
import {SidenavController} from './sidenav-controller';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css', './second-level-menu.css', './common/button-with-menu/button-with-menu.component.css'],
    providers: [AuthService, ChangeLanguageService, ReportsService]
})
export class AppComponent implements OnInit, OnDestroy {
    isAnotherLogged: boolean;
    isLoggedIn: boolean;
    currentUser: string;
    mainUser: string;
    showSwitchUserDialog: boolean;
    showSidenav = true;
    homeSidenav = true;
    sideNavItems: NavigationLink[] = [];
    hotkeyOnlyItems: NavigationLink[] = [];

    menuType = MenuType;
    settingsMenuElements: MenuElement[] = [];
    userMenuElements: MenuElement[] = [];
    private subscriptions: Subscription[] = [];
    private hotkeys: Hotkey[] = [];

    constructor(private translate: TranslateService,
                private router: Router,
                private storage: StorageService,
                private permissions: Permissions,
                private auth: AuthService,
                private changeLanguage: ChangeLanguageService,
                private isLoggedService: IsLoggedService,
                private isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private currentUserService: CurrentUserService,
                private hotkeysService: HotkeysService,
                private sidenavController: SidenavController) {

        if (!!this.storage.get(StorageKey.JWT)) {
            this.changeLang(this.storage.get(StorageKey.INTERFACE_LANGUAGE));
        } else {
            this.translate.setDefaultLang('en');
            this.translate.use('en');
        }
    }

    ngOnInit(): void {
        if (this.isLoggedIn) {
            this.changeLang(this.storage.get(StorageKey.INTERFACE_LANGUAGE));
        }
        this.subscriptions.push(this.isLoggedService.item$.subscribe(
            received => {
                this.isLoggedIn = received;
            }));
        this.subscriptions.push(this.isAnotherUserLoggedService.item$.subscribe(
            received => {
                if (received !== this.isAnotherLogged) {
                    this.isAnotherLogged = received;
                    this.prepareMenus();
                    this.prepareSideNavItemsAndHotKeys();
                }
                this.currentUser = this.currentUserService.currentUserName;
                this.mainUser = this.currentUserService.mainUserName;
            }));
        this.subscriptions.push(this.sidenavController.visibilityChange.subscribe(visible => {
            this.showSidenav = visible;
            this.prepareSideNavItemsAndHotKeys();
        }));
        this.subscriptions.push(this.sidenavController.contentChange.subscribe(customContent => {
            this.homeSidenav = customContent == undefined;
            this.prepareSideNavItemsAndHotKeys();
        }));
        this.subscriptions.push(this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe(() => this.prepareSideNavItemsAndHotKeys()));
        this.showSwitchUserDialog = false;
        this.prepareSideNavItemsAndHotKeys();
        this.prepareMenus();
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.hotkeys);
        this.subscriptions.forEach(subsciption => subsciption.unsubscribe());
    }

    protected changeLang(langName): void {
        if (this.isLoggedIn) {
            this.changeLanguage.changeLng(langName);
            this.storage.set(StorageKey.INTERFACE_LANGUAGE, langName);
        }
        this.translate.use(langName);
    }

    logout() {
        this.auth.logout();
    }

    private prepareMenus(): void {
        this.userMenuElements = [];
        this.userMenuElements.push(new MenuElementBuilder()
            .setType(MenuElementType.LANGUAGE_SELECTOR)
            .build());

        if (this.isAnotherLogged) {
            this.userMenuElements.push(new MenuElementBuilder()
                .setType(MenuElementType.REGULAR)
                .setIdentifier("backToOriginalUser")
                .setTranslationKey('IMPERSONATE.BACK_TO_ORIGINAL_USER')
                .translateButtonText()
                .build());
        }

        this.userMenuElements.push(new MenuElementBuilder()
            .setType(MenuElementType.NAVIGATION_LINK)
            .setIdentifier("userInfoSettingsButton")
            .setNavigationLink(this.hotkeyOnlyItems.find(elem => elem.cssClass === "settingsButton"))
            .translateButtonText()
            .build());

        this.userMenuElements.push(new MenuElementBuilder()
            .setType(MenuElementType.REGULAR)
            .setIdentifier("logout")
            .setTranslationKey('LOGOUT.TITLE')
            .build());

        this.settingsMenuElements = [];
        this.settingsMenuElements.push(new MenuElementBuilder()
            .setType(MenuElementType.NAVIGATION_LINK)
            .setIdentifier("translationsButton")
            .setNavigationLink(this.hotkeyOnlyItems.find(elem => elem.cssClass === "translationsButton"))
            .translateButtonText()
            .build());
        this.settingsMenuElements.push(new MenuElementBuilder()
            .setType(MenuElementType.NAVIGATION_LINK)
            .setIdentifier("adminPanelButton")
            .setNavigationLink(this.hotkeyOnlyItems.find(elem => elem.cssClass === "adminPanelButton"))
            .translateButtonText()
            .build());
    }

    menuElementSelected(event: ButtonWithMenuElementSelectedEvent): void {
        let id = event.identifier;
        if (id === "backToOriginalUser") {
            this.backToOriginalUser();
        }

        if (id === "logout") {
            this.logout();
        }
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    private backToOriginalUser() {
        this.auth.switchUserBack();
    }

    isMaintenanceModeOn() {
        return environment.maintenanceMode;
    }

    private prepareSideNavItemsAndHotKeys() {
        this.sideNavItems = [];
        this.sideNavItems.push(new NavigationLink('ctrl+alt+o', "offersButton", AccessData.path.offer(), AccessData.roles.offer, 'NAVIGATION.ITEMS.OFFER', "assignment"));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+l', "catalogButton", AccessData.path.windowSystem(), AccessData.roles.windowSystem, 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW-SYSTEM', "local_offer"));

        this.sideNavItems.push(new NavigationLink('ctrl+alt+k', "catalogCreatorButton", AccessData.path.catalogCreator(), AccessData.roles.catalogCreator, 'NAVIGATION.ITEMS.CATALOG_CREATOR.LIST', "local_offer"));

        this.sideNavItems.push(new NavigationLink('ctrl+alt+s', "suppliersButton", AccessData.path.supplier(), AccessData.roles.supplier, 'NAVIGATION.ITEMS.SUPPLIERS.LIST', "airport_shuttle"));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+c', "clientsButton", AccessData.path.client(), AccessData.roles.client, 'NAVIGATION.ITEMS.CLIENTS.LIST', "person_outline", undefined, {roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA', 'ROLE_WEBSHOP']}));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+g', "clientGroupsButton", AccessData.path.clientGroups(), AccessData.roles.clientGroups, 'NAVIGATION.ITEMS.CLIENT-GROUP-LIST', "group", undefined, {roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA', 'ROLE_WEBSHOP']}));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+u', "usersButton", AccessData.path.user(), AccessData.roles.user, 'NAVIGATION.ITEMS.USERS.LIST', "person_pin"));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+r', "userGroupsButton", AccessData.path.userGroups(), AccessData.roles.userGroups, 'NAVIGATION.ITEMS.USER-GROUP-LIST', "supervisor_account"));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+p', "pricelistButton", AccessData.path.priceLists(), AccessData.roles.priceLists, 'NAVIGATION.ITEMS.PRICE-LISTS.MENU', "attach_money", undefined, {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+b', "subsystemsButton", AccessData.path.subsystem(), AccessData.roles.subsystem, 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST', "business"));
        this.sideNavItems.push(new NavigationLink('ctrl+alt+q', "subsystemGroupsButton", AccessData.path.subsystemGroup(), AccessData.roles.subsystemGroup, 'NAVIGATION.ITEMS.SUBSYSTEM_GROUP.LIST', "business"));

        this.hotkeyOnlyItems = [];
        this.hotkeyOnlyItems.push(new NavigationLink('ctrl+alt+h', "homeButton", AccessData.path.features(), AccessData.roles.features, 'NAVIGATION.ITEMS.HOME'));
        this.hotkeyOnlyItems.push(new NavigationLink('ctrl+alt+e', "settingsButton", AccessData.path.settings(), AccessData.roles.settings, 'NAVIGATION.ITEMS.SETTINGS'));
        this.hotkeyOnlyItems.push(new NavigationLink('ctrl+alt+a', "adminPanelButton", AccessData.path.adminPanel(), AccessData.roles.adminPanel, 'NAVIGATION.ITEMS.ADMIN_PANEL'));
        this.hotkeyOnlyItems.push(new NavigationLink('ctrl+alt+t', "translationsButton", AccessData.path.translations(), AccessData.roles.translations, 'NAVIGATION.ITEMS.TRANSLATIONS'));

        let addHotkey = (link: NavigationLink): void => {
            if (!this.menuElementActive(link.routerLink) && this.isPermitted(link.roles) && (link.roles2 == undefined || this.isPermitted(link.roles2))) {
                let hotkey = new Hotkey(link.hotkey, () => {
                    this.router.navigate([link.routerLink]);
                    return false;
                }, undefined, link.name);
                this.hotkeysService.add(hotkey);
                this.hotkeys.push(hotkey);
            }
        };

        this.hotkeysService.remove(this.hotkeys);
        this.hotkeys = [];
        if (this.showSidenav && this.homeSidenav) {
            this.hotkeyOnlyItems.forEach(item => addHotkey(item));
            this.sideNavItems.forEach(item => addHotkey(item));
        }
    }

    menuElementActive(routerLink: string): boolean {
        return this.router.isActive(routerLink, {
            paths: 'subset',
            queryParams: 'subset',
            fragment: 'ignored',
            matrixParams: 'ignored'
        });
    }

    calculateStyleWidth() {
        return {
            width: this.isLoggedIn && this.showSidenav ? 'calc(100% - var(--first-level-menu-width))' : '100%'
        };
    }

    isDisplaySideMenuLabels(): boolean {
        return this.storage.get(StorageKey.DISPLAY_SIDE_MENU_LABELS) == 'true';
    }
}
