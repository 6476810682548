import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class SellerSettings {
    id: number;
    userId: number;
    name: string;
    phoneNumber: string;
    primaryCorrespondenceAddressId: number;
    primaryDeliveryAddressId: number;

    constructor() {
        this.id = undefined;
        this.userId = undefined;
        this.name = undefined;
        this.phoneNumber = undefined;
        this.primaryCorrespondenceAddressId = undefined;
        this.primaryDeliveryAddressId = undefined;
    }

    static fromJSON(jsonObject: any): SellerSettings {
        const settings = new SellerSettings();
        settings.id = jsonObject.id;
        settings.userId = jsonObject.userId;
        settings.name = jsonObject.name;
        settings.phoneNumber = jsonObject.phoneNumber;
        settings.primaryCorrespondenceAddressId = jsonObject.primaryCorrespondenceAddressId;
        settings.primaryDeliveryAddressId = jsonObject.primaryDeliveryAddressId;
        return settings;
    }
}
