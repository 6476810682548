import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {MultilanguageField} from '../../../supportedLanguages';
import {WebshopCharge, DataModificationTarget} from './WebshopCharge';
import {UpsellingChargeData} from "../../../../window-designer/entities/upselling-charge-data";

@Injectable()
export class WebshopChargeService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    public saveWebshopCharge(id: number, name: MultilanguageField, windowSystemId: number,
                             data: UpsellingChargeData | WindowSystemDefaults, target: DataModificationTarget): Observable<void> {
        return this.http.post<void>('webshopCharges', {
            id: id,
            name: name,
            windowSystemId: windowSystemId,
            value: data,
            target: target
        });
    }

    public editWebshopCharge(webshopChargeId: number, item: WebshopCharge, file: File, fileBig: File): Observable<void> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('webshopChargeDto', new Blob([body], {type: 'application/json'}));
        if (file) {
            formData.append('file', file);
        }
        if (fileBig) {
            formData.append('fileBig', fileBig);
        }
        return this.http.post<void>(`webshopCharges/edit/${webshopChargeId}`, formData);
    }

    public getWebshopCharge(webshopChargeId: number): Observable<WebshopCharge> {
        return this.http.get<WebshopCharge>(`webshopCharges/${webshopChargeId}`);
    }

    getWebshopCharges(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                      sortColumn: string, sortOrder: number): Observable<Listing<WebshopCharge>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<WebshopCharge>>('webshopCharges', {params: params});
    }

    setWebshopChargesAvailable(ids: number[], available: boolean): Observable<void> {
        const headers = this.dataServiceHelper.prepareHeaders({ids, available});
        return this.http.post<void>(`webshopCharges/setAvailableInSubsystemBulk`, {}, {headers});
    }

    getImage(webshopChargeId: number): Observable<File> {
        return this.http.get(`webshopCharges/${webshopChargeId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getImageBig(webshopChargeId: number): Observable<File> {
        return this.http.get(`webshopCharges/${webshopChargeId}/imageBig`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    setWebshopChargeAvailable(webshopChargeId: number, available: boolean): Observable<void> {
        return this.http.post<void>(`webshopCharges/${webshopChargeId}/setAvailableInSubsystem`, available, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    getAvailableWebshopCharges(): Observable<number[]> {
        return this.http.get<number[]>('webshopCharges/available');
    }

    getAllActiveWindowUpsellings(): Observable<Listing<WebshopCharge>> {
        let filters = {
            target: {value: DataModificationTarget.WINDOW_UPSELLING},
            active: {value: 'true'}
        };
        let params = this.dataServiceHelper.prepareSearchParams(undefined, undefined, filters, 'name', 1);
        return this.http.get<Listing<WebshopCharge>>('webshopCharges', {params: params});
    }
}
