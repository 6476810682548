import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {ValidationErrors} from '../../../../common/validation-errors';
import {EntranceGlazingPackageField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {EntranceGlazingPackageFieldUsage} from "../../catalog-field-usage";
import {EntranceGlazingPackage} from "../entrance-glazing-package";

@Component({
    selector: 'app-entrance-glazing-package-form',
    templateUrl: './entrance-glazing-package-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceGlazingPackageFormComponent {

    @Input()
    item: EntranceGlazingPackage;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    file: File;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly fileChange = new EventEmitter<File>();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: EntranceGlazingPackageFieldUsage;
    EntranceGlazingPackageField = EntranceGlazingPackageField;

    constructor() {
        this.fieldUsage = new EntranceGlazingPackageFieldUsage(this)
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
