import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import * as _ from 'underscore';
import {WindowSystemDefinition} from "../../../window-system/window-system-definition/window-system-definition";
import {MaterialType} from "../../../../common/enums/MaterialType";
import {RoofWindowData} from "../../window-editor/roof-window-editor/RoofWindowData";
import {AddProductDialogDataProviderComponent} from "./add-product-dialog-data-provider.component";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-add-roof-window-dialog',
    templateUrl: './add-product-dialog-data-provider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRoofWindowDialogComponent extends AddProductDialogDataProviderComponent<WindowSystemDefinition> implements OnInit {

    @Input() data: RoofWindowData;

    tableHeader = 'FORM.ADD_SYSTEM.SYSTEM';
    dropdownSelectLabel = 'WINDOW-SYSTEM-DEFINITION.FORM.ROOF_SYSTEM_FUNCTION';

    constructor(translate: TranslateService,
                domSanitizer: DomSanitizer,
                changeDetector: ChangeDetectorRef) {
        super(translate, domSanitizer, changeDetector);
    }

    ngOnInit() {
        this.initTabs();
        let selected;
        if (this.data && this.data.systemId) {
            selected = this.items.find(system => system.id === this.data.systemId);
        } else {
            selected = _.chain(this.items)
                .sortBy(item => item.sortIndex)
                .first()
                .value();
        }
        this.handleTabChange(selected.material);
        this.handleDropdownChange(selected.roofSystemFunction);
        this.handleValueChange(selected);
        this.changeDetector.markForCheck();
    }

    private initTabs(): void {
        this.initMaterialTabs();
    }

    getWindowSystems() {
        return (this.items || []);
    }

    private getEntityTabIndex(item: WindowSystemDefinition): string {
        return item.material;
    }

    handleTabChange(material: MaterialType): void {
        this.activeTabItem = this.tabItems.find(tab => tab.tabindex === material);
        let filteredItems = _.chain(this.items || [])
            .filter(item => this.getEntityTabIndex(item) === this.activeTabItem.tabindex)
            .sortBy(item => item.sortIndex)
            .value();
        this.initDropdown(filteredItems);
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleDropdownChange(selectedItem.roofSystemFunction);
    }

    public initDropdown(items: WindowSystemDefinition[]): void {
        this.dropdownSelectOptions = _.chain(items)
            .map(item => item.roofSystemFunction)
            .uniq()
            .map(f => this.prepareDropdownOption(f))
            .sortBy(item => item.label)
            .value();
        this.showDropdown = this.dropdownSelectOptions.length > 1;
    }

    private prepareDropdownOption(systemFunction: string): SelectItem {
        return {
            label: this.translate.instant('WINDOW-SYSTEM-DEFINITION.ROOF_SYSTEM_FUNCTION.' + systemFunction),
            value: systemFunction
        };
    }

    handleDropdownChange(dropdownValue: string): void {
        this.selectedDropdownSelectOption = dropdownValue;
        let filteredItems = _.chain(this.items || [])
            .filter(item => this.getEntityTabIndex(item) === this.activeTabItem.tabindex)
            .filter(item => item.roofSystemFunction === this.selectedDropdownSelectOption)
            .sortBy(item => item.sortIndex)
            .value();
        this.tableOptions = filteredItems;
        for (let item of filteredItems) {
            this.tableOptionNames[item.id] = item.names[this.translate.currentLang];
        }
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleValueChange(selectedItem);
    }

    handleValueChange(item: WindowSystemDefinition): void {
        this.selectedTableOption = item;
        this.selectedValueImage = null;
        if (this.selectedTableOption != undefined) {
            this.largeImageId = this.selectedTableOption.id;
            this.selectedValueDescription = this.prepareDescription(item.description);
            if (item.image) {
                this.selectedValueImage = this.domSanitizer.bypassSecurityTrustUrl(item.image);
            }
        }
    }
}
