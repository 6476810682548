<div class="new-form-60vw">

    <!-- NAME -->
    <app-translation-fields *ngIf="fieldUsage.show(GlassField.NAME)"
                            inputId="name" label="{{ 'GLASS.FORM.NAME' | translate }}" validationKeyPrefix="name"
                            [disabled]="fieldUsage.disabled(GlassField.NAME)"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.name"></app-translation-fields>
    <!-- SHORTCUT -->
    <app-translation-fields *ngIf="fieldUsage.show(GlassField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'GLASS.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(GlassField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>

    <!-- SYMBOL -->
    <div *ngIf="fieldUsage.show(GlassField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol"
                        label="{{ 'GLASS.FORM.SYMBOL' | translate }}"
                        [maxlength]="30"
                        [disabled]="fieldUsage.disabled(GlassField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']"
                        [(ngModel)]="item.symbol" [required]="true">
        </app-input-text>
    </div>

    <!-- THICKNESS -->
    <div class="new-form-row" *ngIf="fieldUsage.show(GlassField.THICKNESS)">
        <app-input-number inputId="thickness" label="{{ 'GLASS.FORM.THICKNESS' | translate }}"
                          [disabled]="fieldUsage.disabled(GlassField.THICKNESS)"
                          [(validationMessageKey)]="validationErrors['thickness']" [min]="1" [max]="1000"
                          [required]="true" [(ngModel)]="item.thickness"></app-input-number>
    </div>

    <!-- IS LAMINATED -->
    <div class="new-form-row" *ngIf="fieldUsage.show(GlassField.LAMINATED)">
        <app-checkbox inputId="laminated" label="{{ 'GLASS.FORM.LAMINATED' | translate }}"
                      [disabled]="fieldUsage.disabled(GlassField.LAMINATED)"
                      [(ngModel)]="item.laminated"></app-checkbox>
    </div>

    <!-- unit weight -->
    <div class="new-form-row" *ngIf="fieldUsage.show(GlassField.UNIT_WEIGHT)">
        <app-input-number inputId="unitWeight" label="{{ 'GLASS.FORM.UNIT_WEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(GlassField.UNIT_WEIGHT)"
                          [(validationMessageKey)]="validationErrors['unitWeight']" [min]="0" [max]="9999"
                          [required]="true" [(ngModel)]="item.unitWeight"></app-input-number>
    </div>

    <!-- CONSTRUCT THICKNESS -->
    <div class="new-form-row" *ngIf="fieldUsage.show(GlassField.CONSTRUCT_THICKNESS)">
        <app-input-number inputId="constructionThickness" label="{{ 'GLASS.FORM.CONSTRUCT_THICKNESS' | translate }}"
                          [disabled]="fieldUsage.disabled(GlassField.CONSTRUCT_THICKNESS)"
                          [(validationMessageKey)]="validationErrors['constructionThickness']" [min]="0.1" [max]="1000" [step]="0.1"
                          [required]="true" [(ngModel)]="item.constructionThickness"></app-input-number>
    </div>

    <!-- ESG -->
    <div class="new-form-row" *ngIf="fieldUsage.show(GlassField.ESG)">
        <app-checkbox inputId="esg" label="{{ 'GLASS.FORM.ESG' | translate }}"
                      [disabled]="fieldUsage.disabled(GlassField.ESG)"
                      [(ngModel)]="item.esg"></app-checkbox>
    </div>

    <!-- TMP -->
    <div class="new-form-row" *ngIf="fieldUsage.show(GlassField.TMP)">
        <app-checkbox inputId="tmp" label="{{ 'GLASS.FORM.TMP' | translate }}"
                      [disabled]="fieldUsage.disabled(GlassField.TMP)"
                      [(ngModel)]="item.tmp"></app-checkbox>
    </div>

    <!-- ORNAMENT -->
    <div class="new-form-row" *ngIf="fieldUsage.show(GlassField.ORNAMENT)">
        <app-checkbox inputId="ornament" label="{{ 'GLASS.FORM.ORNAMENT' | translate }}"
                      [disabled]="fieldUsage.disabled(GlassField.ORNAMENT)"
                      [(ngModel)]="item.ornament"></app-checkbox>
    </div>

    <!-- ACTIVE -->
    <div *ngIf="fieldUsage.show(GlassField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GLASS.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GlassField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <!-- SORT INDEX -->
    <div *ngIf="fieldUsage.show(GlassField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'GLASS.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GlassField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <p-accordion>
        <!-- IMAGE UPLOAD -->
        <p-accordionTab *ngIf="fieldUsage.show(GlassField.PREVIEW_IMAGE)"
                        header="{{ 'GLASS.FORM.IMAGE' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
            <app-file-upload inputId="image" [ngModel]="file"
                             [disabled]="fieldUsage.disabled(GlassField.PREVIEW_IMAGE)"
                             [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                             [addButtonLabel]="'GLASS.FORM.SELECT_FILE_UPLOAD'"
                             [changeButtonLabel]="'GLASS.FORM.CHANGE_FILE_UPLOAD'"
                             [deleteButtonLabel]="'GLASS.FORM.DELETE_FILE_UPLOAD'"
                             (ngModelChange)="fileChanged($event)"
                             [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
        </p-accordionTab>
        <!-- IMAGE UPLOAD -->
        <p-accordionTab *ngIf="fieldUsage.show(GlassField.GLAMOUR_PRINT_ICON)"
                        header="{{ 'GLASS.FORM.GLAMOUR_PRINT_ICON' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['glamourPrintIconFile']}">
            <app-file-upload inputId="image" [ngModel]="glamourPrintIconFile"
                             [disabled]="fieldUsage.disabled(GlassField.GLAMOUR_PRINT_ICON)"
                             [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                             [addButtonLabel]="'GLASS.FORM.SELECT_GLAMOUR_PRINT_ICON_UPLOAD'"
                             [changeButtonLabel]="'GLASS.FORM.CHANGE_GLAMOUR_PRINT_ICON_UPLOAD'"
                             [deleteButtonLabel]="'GLASS.FORM.DELETE_GLAMOUR_PRINT_ICON_UPLOAD'"
                             (ngModelChange)="glamourPrintIconFileChanged($event)"
                             [(validationMessageKey)]="validationErrors['glamourPrintIconFile']"></app-file-upload>
        </p-accordionTab>
    </p-accordion>
</div>
