import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Assembly} from '../assembly';

@Component({
    selector: 'app-assembly-form',
    templateUrl: './assembly-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssemblyFormComponent {

    @Input()
    item: Assembly;

    @Input()
    assemblyUnits: SelectItem[];

    @Input()
    validationErrors: { [field: string]: string };

    @Input()
    copyMode: boolean;
}
