import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {CurrentUserService} from '../../auth/current-user.service';
import {mapItemArrayToJson, mapItemToJson} from '../../common/crud-common/crudItem';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {ProductionOrderSalesTargetStatistics} from '../home/dashboard-report/production-order-sales-target-statistics';
import {ProductionOrderStatistics} from '../home/dashboard-report/production-order-statistics';
import {ReportDateResolution, ReportGrouping, ReportMode} from './report-definitions';
import {ProductionOrderConversions} from '../home/dashboard-report/production-order-conversions';

@Injectable()
export class ReportsService {

    private getProductionOrderReportDataCache = new Map<string, { day: number, dataSource: Observable<ProductionOrderStatistics[]> }>();
    private getProductionOrderConversionReportDataCache = new Map<string, { day: number, dataSource: Observable<ProductionOrderConversions[]> }>();
    private getSalesTargetDataCache = new Map<string, { day: number, dataSource: Observable<ProductionOrderSalesTargetStatistics> }>();

    constructor(private readonly http: HttpClient,
                private readonly dataServiceHelper: DataServiceHelper,
                private readonly currentUserService: CurrentUserService) {
    }

    getProductionOrderReportData(mode: ReportMode, dateResolution: ReportDateResolution, filters: Partial<Record<'dateRange'
                                     | 'subsystemId' | 'clientId' | 'supplierId', FilterMetadata>>, resultGrouping: ReportGrouping[],
                                 previousYearHistoricData = false): Observable<ProductionOrderStatistics[]> {
        const params = this.dataServiceHelper.prepareSearchParams(undefined, undefined, filters, undefined, undefined);
        params['dateResolution'] = dateResolution;
        params['resultGrouping'] = resultGrouping;
        params['previousYearHistoricData'] = `${previousYearHistoricData}`;
        const cacheKey = new HttpParams({fromObject: {...params, mode: mode}}).toString()
            + '_' + this.currentUserService.currentUserId;
        let cached = this.getProductionOrderReportDataCache.get(cacheKey);
        const currentDay = moment().utc().dayOfYear();
        if (cached == undefined || cached.day !== currentDay) {
            cached = {
                day: currentDay,
                dataSource: this.http.get<object[]>(`productionorders/report/${mode}`, {params: params})
                    .pipe(shareReplay(1), mapItemArrayToJson(ProductionOrderStatistics))
            };
            this.getProductionOrderReportDataCache.set(cacheKey, cached);
        }
        return cached.dataSource;
    }

    getProductionOrderConversionData(dateResolution: ReportDateResolution, filters: Partial<Record<'dateRange'
                                         | 'subsystemId' | 'clientId', FilterMetadata>>,
                                     resultGrouping: ReportGrouping[]): Observable<ProductionOrderConversions[]> {
        const params = this.dataServiceHelper.prepareSearchParams(undefined, undefined, filters, undefined, undefined);
        params['dateResolution'] = dateResolution;
        params['resultGrouping'] = resultGrouping;

        const cacheKey = new HttpParams({fromObject: {...params, mode: 'conversion'}}).toString()
                         + '_' + this.currentUserService.currentUserId;
        let cached = this.getProductionOrderConversionReportDataCache.get(cacheKey);
        const currentDay = moment().utc().dayOfYear();
        if (cached == undefined || cached.day !== currentDay) {
            cached = {
                day: currentDay,
                dataSource: this.http.get<object[]>('productionorders/report/conversion', {params: params})
                                .pipe(shareReplay(1), mapItemArrayToJson(ProductionOrderConversions))
            };
            this.getProductionOrderConversionReportDataCache.set(cacheKey, cached);
        }
        return cached.dataSource;
    }

    getSalesTargetData(): Observable<ProductionOrderSalesTargetStatistics> {
        const cacheKey = `${this.currentUserService.currentUserId}`;
        let cached = this.getSalesTargetDataCache.get(cacheKey);
        const currentDay = moment().utc().dayOfYear();
        if (cached == undefined || cached.day !== currentDay) {
            cached = {
                day: currentDay,
                dataSource: this.http.get<object[]>('productionorders/report/salesTarget')
                    .pipe(shareReplay(1), mapItemToJson(ProductionOrderSalesTargetStatistics))
            };
            this.getSalesTargetDataCache.set(cacheKey, cached);
        }
        return cached.dataSource;
    }

    clearSalesTargetCache(): void {
        const cacheKey = `${this.currentUserService.currentUserId}`;
        this.getSalesTargetDataCache.delete(cacheKey);
    }
}
