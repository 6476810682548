import {HttpErrorResponse} from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Hotkey} from "angular2-hotkeys";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {forkJoin, Observable, of, Subject} from "rxjs";
import {finalize, map, mergeMap} from 'rxjs/operators';
import {CurrentUserService} from '../../../auth/current-user.service';
import {Permissions} from "../../../auth/permission.service";
import {StorageService} from "../../../auth/storage.service";
import {UserUiConfigService} from "../../../auth/uiconfig/userUiConfig.service";
import {ButtonWithMenuElementSelectedEvent} from "../../../common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder} from "../../../common/button-with-menu/MenuElement";
import {MenuType} from "../../../common/button-with-menu/MenuType";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DatatableHelper, TableToDatatableInterfaceAdapter} from "../../../common/DatatableHelper";
import {OfferStatus} from "../../../common/enums/OfferStatus";
import {ResponseError} from "../../../common/error.handler";
import {ExchangeService} from "../../../common/exchange.service";
import {OfferStatusProvider} from "../../../common/offerStatusProvider";
import {SavedFilterService} from "../../../common/saved-filter/saved-filter.service";
import {CrudComponent} from "../../../common/service/crud.component";
import {DataTableColumnBuilder, SavedShownColumns} from "../../../common/service/data.table.column.builder";
import {ExportComponent} from "../../../common/service/export.component";
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {TranslatedSelectItemBuilder} from "../../../common/service/translated.select.item.builder";
import {Currencies} from "../../../currencies";
import {TristateCheckboxState} from "../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {ErrorResponse} from "../../errors/errorResponse";
import {ListOfIds} from '../../ListOfIds';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {HasSavedFilter} from '../has-saved-filter';
import {Offer, OrderDeliveryListNames, OrderProductionOrderNumbers, PrintHistoryEntry} from "../offer";
import {prepareOfferOrderForCsvExport} from '../offer-csv-helpers';
import {OffersService} from "../offer-service";
import {CreateOfferMode} from "../offers/create-offer/create-offer-mode";
import {CommentDialogMode} from "../offers/position/position-list/comment-dialog/comment-dialog-mode";
import {DialogType} from "../offers/position/position-list/position-list-dialogs";
import {PaymentPackageService} from "../payment-package/payment-package.service";
import {PrintableItem} from '../print-dialog/print-dialog.component';
import {PrintableSection} from '../print-dialog/printable-section.enum';
import {
    ShippingSimulationFloatButton
} from "../shipping/shipping-simulation/shipping-simulation-float-button/shipping-simulation-float-button";
import {
    ShippingSimulationSidebarComponent
} from "../shipping/shipping-simulation/shipping-simulation-sidebar/shipping-simulation-sidebar.component";
import {ShippingSimulationService} from "../shipping/shipping-simulation/shipping-simulation.service";
import {StatusTransitionDialogService} from "../status-transition-dialog/status-transition-dialog.service";
import {StatusTransition} from "../status-transition-dialog/StatusTransition";
import {StatusTransitionHelper} from "../status-transition-dialog/StatusTransitionHelper";

enum DialogIds {
    NONE = -1,
    DELETE = 1,
    COMMENTS = 6,
    CONFIRM_CORRECTION = 7,
    CANCEL_ORDER = 8,
    MESSAGES = 9,
    HISTORY = 10,
    PRINT_HISTORY = 11,
    CONFIRM_PARTIAL_SIMULATION = 12,
}

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['../../shared-styles.css', '../../../common/offer-status-colors.css'],
    providers: [OffersService, SubsystemService, ExportComponent, StorageService, UserUiConfigService, ExchangeService,
        StatusTransitionDialogService, PaymentPackageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrdersComponent extends CrudComponent implements OnInit, OnDestroy, AfterViewInit, HasSavedFilter {

    static readonly FILTER_MINE = "MINE";
    static readonly FILTER_LATEST = "LATEST";

    readonly DialogIds = DialogIds;

    customFiltersColumnsOptions: TranslatedSelectItem[] = [
        {labelKey: "GENERAL.CUSTOM_FILTERS.MINE", label: undefined, value: OrdersComponent.FILTER_MINE},
        {labelKey: "GENERAL.CUSTOM_FILTERS.LATEST", label: undefined, value: OrdersComponent.FILTER_LATEST}
    ];
    customFiltersColumnsActive: string[] = [];

    private readonly ACQUIRE_OFFERLOCK_ID = 'acquireOfferLock';
    readonly ORDERS_TABLE_ID = 'ordersTable';
    private readonly CREATE_CORRECTION_ID = 'createCorrection';

    private ignoreStoredFilter: boolean;
    private showPageWarning = false;

    menuType = MenuType;

    currentActionOffer: Offer;
    currentUserLogin: string;
    openedDialogNumber = DialogIds.NONE;
    dialogType = DialogType;
    lastLoadEvent: LazyLoadEvent;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    offers: Offer[];
    selectedItems: Offer[] = [];
    printableItems: PrintableItem[] = [];
    selectedItem: Offer;
    userLang: string;
    availableSubsystems: SelectItem[] = [];
    availableStatuses: TranslatedSelectItem[] = [];
    availableCurrencies: MenuElement[] = [];
    selectedSubsystems: string[] = [];
    selectedStatuses: OfferStatus[] = [];
    selectedCurrency;
    defaultCurrency: Currencies;
    shippingCalculationInProgress: boolean;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();
    @Output() onVisibleColumnsChange = new EventEmitter<SavedShownColumns>();

    showPrintButton = false;
    printDialogVisible = false;
    printHistoryDialogVisible = false;
    PrintableSection = PrintableSection;

    allSelectedState = TristateCheckboxState.UNCHECKED;

    transitionsDialogVisible = false;
    possibleTransitions: StatusTransition[] = [];

    @ViewChild('dt', {static: true}) datatable: Table;
    @ViewChild('shippingSidebar', {static: true}) shippingSimulationSidebar: ShippingSimulationSidebarComponent;
    @ViewChild('shippingFloatButton', {static: true}) shippingFloatButton: ShippingSimulationFloatButton;

    private spaceHotkey: Hotkey;

    commentDialogMode: CommentDialogMode = CommentDialogMode.OFFER;

    private componentInitialized = new Subject<void>();

    filterByPaymentPackage: string;
    filterByDeliveryListName: string;
    filterByOfferNumber: string;

    incomingClientId: number;

    constructor(public permissions: Permissions,
                private currentUserService: CurrentUserService,
                public router: Router,
                private route: ActivatedRoute,
                public subsystemService: SubsystemService,
                public offerService: OffersService,
                public exchangeService: ExchangeService,
                public storage: StorageService,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private statusTransitionDialogService: StatusTransitionDialogService,
                private shippingSimulationService: ShippingSimulationService,
                private errors: CommonErrorHandler) {

        super(injector, changeDetector, 'OrdersComponent', false);
        this.userLang = this.translate.currentLang;

        this.availableCurrencies = Object.keys(Currencies).map(currency => new MenuElementBuilder()
            .setTitle(currency).setIdentifier(currency).build());

        this.spaceHotkey = new Hotkey('space', (): boolean => {
            this.changeSelectionOfItem();
            return false;
        });
    }

    getDatatable(): TableToDatatableInterfaceAdapter {
        return TableToDatatableInterfaceAdapter.create(this.datatable);
    }

    ngOnInit(): void {
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.showPageWarning = this.route.snapshot.paramMap.has('showPageWarning');
        this.filterByPaymentPackage = this.route.snapshot.queryParamMap.get('paymentPackageName');
        this.filterByDeliveryListName = this.route.snapshot.queryParamMap.get('deliveryListName');
        this.filterByOfferNumber = this.route.snapshot.queryParamMap.get('offerNumber');
        this.currentUserLogin = this.currentUserService.currentUserName;
        this.availableStatuses = TranslatedSelectItemBuilder.create()
            .add('OFFER.STATUS.VERIFIED', OfferStatus.VERIFIED)
            .add('OFFER.STATUS.WAITING_FOR_PAYMENT', OfferStatus.WAITING_FOR_PAYMENT)
            .add('OFFER.STATUS.SUSPENDED', OfferStatus.SUSPENDED)
            .add('OFFER.STATUS.PAID', OfferStatus.PAID)
            .add('OFFER.STATUS.PARTIALLY_DISPATCHED', OfferStatus.PARTIALLY_DISPATCHED)
            .add('OFFER.STATUS.DISPATCHED', OfferStatus.DISPATCHED)
            .add('OFFER.STATUS.DELETED_ORDER', OfferStatus.DELETED_ORDER)
            .add('OFFER.STATUS.CORRECTION', OfferStatus.CORRECTION)
            .add('OFFER.STATUS.CORRECTED', OfferStatus.CORRECTED)
            .add('OFFER.STATUS.CANCEL_ORDER', OfferStatus.CANCEL_ORDER)
            .build();

        this.createTable();
        this.initIncomingClientId();
        this.resetDefaultFilterSelection();
        this.initLastSelectedItem();
        this.initFilterByCurrentUser();
        this.initFilterRequireLatestOfferVersion();
        if (this.shippingSimulationService.checkCalculationFinishedInterval) {
            this.shippingCalculationInProgress = true;
            this.shippingSimulationService.checkCalculationFinishedInterval.pipe(
                finalize(() => {
                    this.shippingCalculationInProgress = false;
                    this.changeDetector.markForCheck();
                }))
                .subscribe();
        }
        this.shippingFloatButton.refresh();

        forkJoin({
            defaultCurrency: this.subsystemService.getDefaultCurrency(),
            subsystemSelectItems: this.subsystemService.getSelectionItems(),
            exchangeRates: this.exchangeService.initializeExchangeRates()
        }).subscribe({
            next: data => {
                this.defaultCurrency = data.defaultCurrency;
                this.selectedCurrency = this.defaultCurrency;
                this.availableSubsystems = data.subsystemSelectItems;
                this.exchangeService.storeExchangeRates(data.exchangeRates);
                this.langTranslateSubscription.push(this.translate.onLangChange.subscribe(event => {
                    this.userLang = event.lang;
                    this.loadItemsLazy(this.lastLoadEvent);
                }));
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                super.ngOnInit();
                this.hotkeysService.add(this.spaceHotkey);
                this.componentInitialized.complete();
                this.changeDetector.markForCheck();
            }
        });
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.spaceHotkey);
        super.ngOnDestroy();
    }

    private changeSelectionOfItem(): void {
        if (this.selectedItem) {
            this.selectItem(this.selectedItem);
        }
    }

    private initIncomingClientId(): void {
        if (this.route.snapshot.queryParamMap.has('clientId')) {
            this.incomingClientId = +this.route.snapshot.queryParamMap.get('clientId');
        }
    }

    onRowSelect(event): void {
        if (this.selectedItem) {
            this.router.navigate(['/features/offer', this.selectedItem.id, 'position']);
        }
    }

    submit(): void {
        // get select row and submit
        console.log("OrderComponent get select row and submit");
    }

    selectAllChange(): void {
        this.selectedItems = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.offers);
        }
        this.handlePrintButtonState();

        this.changeDetector.markForCheck();
    }

    private handlePrintButtonState() {
        const anyOfferInDeletedStatus = this.selectedItems.some(offer => offer.status === OfferStatus.DELETED);
        this.showPrintButton = this.selectedItems.length > 0 && this.selectedItems.length <= 20 && !anyOfferInDeletedStatus;
    }

    isSelectedItem(item: Offer): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: Offer): void {
        let index = this.selectedItems.indexOf(item);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }

        this.handlePrintButtonState();

        this.refreshAllSelectedFlag();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedItems.length === this.offers.length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    resetDefaultFilterSelection(): void {
        if (!this.ignoreStoredFilter) {
            this.lastLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
            if (this.lastLoadEvent != undefined) {
                this.applySavedFilter(this.lastLoadEvent);
            }
        } else {
            if (this.filterByPaymentPackage || this.filterByDeliveryListName || this.filterByOfferNumber) {
                this.filterByCurrentUser = false;
                this.requireLatestOfferVersion = false;
                this.lastLoadEvent = {};
                let filter = {
                    filters: {
                        roPaymentPackageName: {value: this.filterByPaymentPackage},
                        deliveryListName: {value: this.filterByDeliveryListName},
                        offerNumber: {value: this.filterByOfferNumber}
                    }
                };
                this.applySavedFilter(filter);
            }
        }
        this.resetSelectedStatuses();
    }

    private resetSelectedStatuses(): void {
        let forcedStatuses = this.route.snapshot.paramMap.get('statusFilter');
        if (forcedStatuses) {
            this.selectedStatuses = forcedStatuses.split(',').map(status => OfferStatus[status]);
            if (!this.columnByField['status']) {
                this.onDisplayedColumnsChange([...this.selectedColumns, 'status']);
            }
        } else if (this.selectedStatuses.length === 0) {
            this.selectedStatuses = OfferStatusProvider.getOfferStatusFromOrder();
        }
        if (this.selectedStatuses.length > 0) {
            const statusColumn = this.getColumns().find(column => column.field === 'status');
            if (statusColumn != undefined) {
                statusColumn.defaultFilterValue = {
                    label: '',
                    value: this.selectedStatuses.join(';')
                };
            }
            this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        }
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.datatable.filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    handleStatusFilterChange(statuses: OfferStatus[]): void {
        this.selectedStatuses = statuses;
        this.datatable.filter(statuses.join(';'), 'status', 'in');
    }

    private filterExchangeRate(event: LazyLoadEvent): void {
        this.exchangeService.applyRateToRangeFilters(['sellNetVal', 'sellGrossVal', 'buyVenskaNetVal', 'buyVenskaGrossVal',
            'buyNetVal', 'buyGrossVal', 'netIncome', 'grossIncome', 'netVenskaIncome', 'grossVenskaIncome',
            'sourceOfferSellNetVal', 'sourceOfferSellGrossVal'], event, this.selectedCurrency);
    }

    getPriceInDefaultCurrency(priceInPln: number, priceInOfferCurrency: number, exchangeRate: number,
                              subsystemManualExchangeRate?: number): string {
        if (priceInOfferCurrency != undefined) {
            return priceInOfferCurrency.toFixed(2);
        }
        return ExchangeService.getPriceInDefaultCurrency(priceInPln, exchangeRate, subsystemManualExchangeRate);
    }

    getPriceInSelectedCurrency(priceInPln: number, priceInOfferCurrency: number,
                               offer: Offer, applySubsystemManualExchangeRate = false): string {
        if (this.selectedCurrency === offer.currency) {
            if (priceInOfferCurrency != undefined) {
                return priceInOfferCurrency.toFixed(2);
            }
            if (applySubsystemManualExchangeRate && offer.subsystemManualExchangeRate) {
                return ExchangeService.getPriceInDefaultCurrency(priceInPln, offer.exchangeRate, offer.subsystemManualExchangeRate);
            }
            return ExchangeService.getPriceInDefaultCurrency(priceInPln, offer.exchangeRate);
        }
        if (priceInPln == undefined) {
            const plnPriceToOfferCurrencyRatio = (applySubsystemManualExchangeRate && offer.subsystemManualExchangeRate)
                ? offer.subsystemManualExchangeRate
                : offer.exchangeRate;
            return this.exchangeService.getPriceInCurrency(priceInOfferCurrency, offer.currency, this.selectedCurrency,
                plnPriceToOfferCurrencyRatio);
        }
        return this.exchangeService.getPLNPriceInCurrency(priceInPln, this.selectedCurrency);
    }

    private createTable(): void {
        let shownColumns = this.getShownColumns();
        if (this.filterByPaymentPackage != undefined && shownColumns != undefined && shownColumns.roPaymentPackageName == undefined) {
            shownColumns.roPaymentPackageName = {};
        }
        if (this.filterByDeliveryListName != undefined && shownColumns != undefined && shownColumns.deliveryListName == undefined) {
            shownColumns.deliveryListName = {};
        }
        if (this.filterByOfferNumber != undefined && shownColumns != undefined && shownColumns.offerNumber == undefined) {
            shownColumns.offerNumber = {};
        }
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns);

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']})) {
            builder.add('productionOrderNumber', 'OFFER.FORM.PRODUCTION_ORDER_NUMBER', true).makeFilterable().disableSorting()
                .add('deliveryListName', 'OFFER.FORM.DELIVERY_LISTS_NAMES', false).makeFilterable().disableSorting()
                .add('paletteName', 'OFFER.FORM.PALETTE_NAMES', false).makeFilterable().disableSorting();
        }
        builder.add('roPaymentPackageName', 'OFFER.FORM.RELATED_PAYMENT_PACKAGE_NAME', true).makeFilterable().disableSorting()
               .add('offerNumber', 'OFFER.FORM.OFFER_NUMBER', true).makeFilterable().makeSortable()
               .add('alternateOfferNumber', 'OFFER.FORM.ALT_OFFER_NUMBER', this.isPermitted({roles: ['ROLE_OPERATOR']})).makeFilterable()
               .makeSortable()
               .add('createdDate', 'OFFER.FORM.OFFER_CREATION_DATE', false).makeFilterable().makeSortable()
               .add('orderCreationDate', 'OFFER.FORM.ORDER_CREATION_DATE', false).makeFilterable().makeSortable()
               .setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING)
               .add('productionOrderCreationDate', 'OFFER.FORM.PRODUCTION_ORDER_CREATION_DATE', false).makeFilterable().makeSortable()
               .add('lastStatusChange', 'OFFER.FORM.LAST_STATUS_CHANGE', true).makeFilterable().makeSortable()
               .add('status', 'OFFER.FORM.STATUS', true).setFilterValues(this.availableStatuses)
               .add('lastModifiedDate', 'OFFER.FORM.LAST_MODIFIED_DATE', true).makeFilterable().makeSortable()
               .add('validFrom', 'OFFER.FORM.VALID_FROM', this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})).makeFilterable()
               .makeSortable()
               .add('validTo', 'OFFER.FORM.VALID_TO', this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})).makeFilterable()
               .makeSortable();

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('subsystemName', 'OFFER.FORM.SUBSYSTEM', true).makeFilterable().makeSortable();
        }

        builder.add('clientManagerName', 'OFFER.FORM.CLIENT_MANAGER', this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}))
            .makeFilterable().makeSortable()
            .add('subClientManagerName', 'OFFER.FORM.SUB_CLIENT_MANAGER', this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}))
            .makeFilterable().makeSortable()
            .add('clientGroupName', 'OFFER.FORM.CLIENT_GROUP', this.isPermitted({roles: ['ROLE_OPERATOR']})).makeFilterable().makeSortable()
            .add('merchantGroupName', 'OFFER.FORM.MERCHANT_GROUP', this.isPermitted({roles: ['ROLE_OPERATOR']})).makeFilterable()
            .add('clientName', 'OFFER.FORM.CLIENT_NAME', true).makeFilterable().makeSortable()
            .add('clientEmail', 'OFFER.FORM.CLIENT_EMAIL', true).makeFilterable().makeSortable()
            .add('clientIdentifier', 'OFFER.FORM.CLIENT_IDENTIFIER', false).makeFilterable().makeSortable()
            .add('vatSell', 'OFFER.FORM.VAT_SELL', false).makeFilterable().makeSortable()
            .add('sellNetVal', 'OFFER.FORM.SELL_NET_VAL', true).makeFilterable().makeSortable()
            .add('sellGrossVal', 'OFFER.FORM.SELL_GROSS_VAL', false).makeFilterable().makeSortable();

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC']})) {
            builder
                .add('buyNetVal', 'OFFER.FORM.BUY_NET_VAL', true).makeFilterable().makeSortable()
                .add('vatBuy', 'OFFER.FORM.VAT_BUY', false).makeFilterable().makeSortable()
                .add('buyGrossVal', 'OFFER.FORM.BUY_GROSS_VAL', false).makeFilterable().makeSortable();
        }

        builder
            .add('sellerClientGroupName', 'OFFER.FORM.SELLER_CLIENT_GROUP', true).makeFilterable().makeSortable()
            .add('sellerClientName', 'OFFER.FORM.SELLER_CLIENT_NAME', true).makeFilterable().makeSortable()
            .add('vatRetailSell', 'OFFER.FORM.VAT_RETAIL_SELL', false).makeFilterable().makeSortable()
            .add('retailSellNetVal', 'OFFER.FORM.RETAIL_SELL_NET_VAL', false)
            .add('retailSellGrossVal', 'OFFER.FORM.RETAIL_SELL_GROSS_VAL', false).makeFilterable().makeSortable();

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('buyVenskaNetVal', 'OFFER.FORM.BUY_VENSKA_NET_VAL', true).makeFilterable().makeSortable()
                .add('buyVenskaGrossVal', 'OFFER.FORM.BUY_VENSKA_GROSS_VAL', false).makeFilterable().makeSortable();
        }

        builder.add('ownAddonsNetCost', 'OFFER.FORM.OWN_ADDONS_NET_COST', true).makeFilterable().makeSortable()
            .add('ownAddonsGrossCost', 'OFFER.FORM.OWN_ADDONS_GROSS_COST', false).makeFilterable().makeSortable()
            .add('assemblyNetCost', 'OFFER.FORM.ASSEMBLY_NET_COST', true).makeFilterable().makeSortable()
            .add('assemblyGrossCost', 'OFFER.FORM.ASSEMBLY_GROSS_COST', false).makeFilterable().makeSortable()
            .add('transportNetCost', 'OFFER.FORM.TRANSPORT_NET_COST', true).makeFilterable().makeSortable()
            .add('transportGrossCost', 'OFFER.FORM.TRANSPORT_GROSS_COST', false).makeFilterable().makeSortable();

        if (this.isPermitted({roles: ['ROLE_OPERATOR']})) {
            builder.add('netIncome', 'OFFER.FORM.NET_INCOME', true).makeFilterable().makeSortable()
                .add('grossIncome', 'OFFER.FORM.GROSS_INCOME', false).makeFilterable().makeSortable();
        }
        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('netVenskaIncome', 'OFFER.FORM.NET_INCOME', true).makeFilterable().makeSortable()
                .add('grossVenskaIncome', 'OFFER.FORM.GROSS_INCOME', false).makeFilterable().makeSortable();
        }

        builder.add('merchantName', 'OFFER.FORM.MERCHANT_NAME', this.isPermitted({roles: ['ROLE_OPERATOR']})).makeFilterable()
            .makeSortable();

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_READ_WEBSHOP_DETAILS']})) {
            builder.add('sourceOfferNumber', 'OFFER.FORM.SOURCE_OFFER_NUMBER', true)
                .add('sourceOfferSellVat', 'OFFER.FORM.SOURCE_OFFER_VAT', false).makeFilterable().makeSortable()
                .add('sourceOfferSellNetVal', 'OFFER.FORM.SOURCE_OFFER_NET_VAL', true)
                .add('sourceOfferSellGrossVal', 'OFFER.FORM.SOURCE_OFFER_GROSS_VAL', false).makeFilterable().makeSortable();
        }
        super.init(builder.build());
        this.saveShownColumns();
    }

    rowTrackById(index: number, item: Offer) {
        return item.id;
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalTableFilters = true): void {
        this.componentInitialized.subscribe({
            complete: () => {
                this.loadItemsLazySubscription(event, updateOriginalTableFilters);
            }
        });
    }

    loadItemsLazySubscription(event: LazyLoadEvent, updateOriginalTableFilters: boolean): void {
        super.loadItemsLazy(event);
        event.filters['isOrder'] = {value: 'true'};
        this.filterCurrentUser(event);
        this.filterRequireLatestOfferVersion(event);
        this.storeLazyLoadEvent(event, this.storage);
        this.filterClientId(event);
        this.filterExchangeRate(event);
        if (event.origin == undefined) {
            event.origin = this.getDatatable();
        }
        this.lastLoadEvent = event;
        this.onDataLazyLoad.emit(this.lastLoadEvent);
        if (updateOriginalTableFilters) {
            this.onUpdateOriginalFilters.emit(this.lastLoadEvent);
        }
        this.allSelectedState = TristateCheckboxState.UNCHECKED;
        this.selectedItems = [];
        this.handlePrintButtonState();
        this.offerService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder).pipe(
            mergeMap(data => {
                const offerIds = data.data.map(offer => offer.id);
                const canSeeProductionOrderNumber =
                    this.columnOptions.findIndex(col => col.value === 'productionOrderNumber') >= 0;
                const canSeeDeliveryListName = this.columnOptions.findIndex(col => col.value === 'deliveryListName') >= 0 && !this.isPermitted({roles: ['ROLE_SPRZEDAWCA']});
                return forkJoin({
                    data: of(data),
                    productionOrderNumbers: canSeeProductionOrderNumber ? this.offerService.getProductionOrderNumbersForOrderList(offerIds) : of<OrderProductionOrderNumbers[]>([]),
                    deliveryLists: canSeeDeliveryListName ? this.offerService.getDeliveryListsForOrderList(new ListOfIds(offerIds)) : of<OrderDeliveryListNames[]>([])
                });
            }),
            finalize(() => this.hideDataLoadingIndicator())
        ).subscribe({
            next: data => {
                console.info('OrdersComponent `getPage` success:');
                this.offers = data.data.data;
                this.totalRecords = data.data.totalRecords;
                this.totalRecordsChange.emit(this.totalRecords);
                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                const selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.offers, event);
                if (this.showPageWarning && this.selectedItem != undefined && selectedItem != undefined
                    && this.selectedItem.id !== selectedItem.id) {
                    this.growlMessageController.info('OFFER.LIST.SELECTION_ON_OTHER_PAGE_ORDER');
                }
                this.showPageWarning = false;
                this.selectedItem = selectedItem;

                if (!this.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
                    this.offers.forEach(offer => {
                        let relatedProductionOrders = data.productionOrderNumbers.find(orderNumbers => orderNumbers.offerId === offer.id);
                        offer.productionOrders = relatedProductionOrders ? relatedProductionOrders.productionOrderNumbers : [];

                        let relatedDeliveryLists = data.deliveryLists.find(deliveryListName => deliveryListName.offerId === offer.id);
                        offer.palettes = relatedDeliveryLists ? relatedDeliveryLists.paletteNames : [];
                        offer.deliveryLists = relatedDeliveryLists ? relatedDeliveryLists.deliveryListNames : [];
                    });
                }
            },
            error: error => {
                console.error('OrdersComponent `getPage` error:', error);
                this.setErrors(error);
            },
            complete: () => {
                console.info('OrdersComponent `getPage` completed!');
                DatatableHelper.focusOnRowIfNotEditingFilters(event);
                this.changeDetector.markForCheck();
            }
        });
    }

    filterClientId(event: LazyLoadEvent): void {
        if (this.incomingClientId != undefined) {
            event.filters['clientId'] = {value: this.incomingClientId.toString(), matchMode: undefined};
        }
    }

    private initLastSelectedItem(): void {
        if (this.route.snapshot.paramMap.has('lastSelection')) {
            this.selectedItem = new Offer();
            this.selectedItem.id = +this.route.snapshot.paramMap.get('lastSelection');
        }
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    getExportData(): Observable<object[]> {
        const loadEvent = this.lastLoadEvent;
        return this.offerService.getItems(0, null, loadEvent.filters, loadEvent.sortField, loadEvent.sortOrder).pipe(
            mergeMap(orderData => {
                return forkJoin({
                    orders: of(orderData.data),
                    translations: this.translate.get(Object.keys(OfferStatus).map(status => 'OFFER.STATUS.' + status)),
                    productionOrderNumbers: this.offerService.getProductionOrderNumbersForOrderList(orderData.data.map(order => order.id))
                });
            }),
            map(data => {
                const orderMap = new Map<number, string[]>();
                for (let prodOrder of data.productionOrderNumbers) {
                    orderMap.set(prodOrder.offerId, prodOrder.productionOrderNumbers);
                }
                return data.orders.map(order => prepareOfferOrderForCsvExport(order,
                    (item2) => data.translations['OFFER.STATUS.' + item2.status],
                    orderMap.get(order.id) || []));
            })
        );
    }

    changeSemicolonsToLineBreaks(groups: string): string {
        return groups.replace(/;/g, "\n");
    }

    containsSemicolon(groups: string): boolean {
        return groups.indexOf(";") > 0;
    }

    actionOnClick(action: string, offer: Offer): void {
        console.info("OrdersComponent: clicked action '" + action + "' for offer: " + offer.id);
        this.currentActionOffer = offer;
        switch (action) {
            case 'DELETE':
                this.setOpenedDialogNumber(DialogIds.DELETE);
                break;
            case 'COPY':
                this.copyOffer(offer);
                break;
            case 'SHOW_DETAILS':
                this.tryToAcquireOfferLockAndNavigateToPositionList(offer.id, this.currentActionOffer.offerLockUserLogin);
                break;
            case 'COMMENTS':
                this.setOpenedDialogNumber(DialogIds.COMMENTS);
                break;
            case 'SHOW_PRODUCTION_ORDERS':
                if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})) {
                    this.router.navigate(['/features/offer', {component: 'production-order', ignoreStoredFilter: true}],
                        {queryParams: {offerNumber: "\"" + this.currentActionOffer.offerNumber + "\""}});
                }
                break;
            case 'GO_TO_CORRECTION':
                this.navigateToCorrection();
                break;
            case 'CHANGE_STATUS':
                this.possibleTransitions = this.statusTransitionDialogService.createPossibleTransitions(offer,
                    (actionName): void => {
                        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                        this.onActionSuccess(actionName);
                    },
                    (actionName, error): void => {
                        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                        this.onActionError(actionName, error);
                    });

                if (this.possibleTransitions.length === 1) {
                    this.possibleTransitions[0].item.command();
                } else if (this.possibleTransitions.length > 1) {
                    this.transitionsDialogVisible = true;
                }
                break;
            case 'CONFIRM_CORRECTION':
                this.setOpenedDialogNumber(DialogIds.CONFIRM_CORRECTION);
                break;
            case 'CREATE_CORRECTION':
                this.blockUiController.block(this.CREATE_CORRECTION_ID);
                this.offerService.createCorrection(offer.id).subscribe({
                    next: correctionId => {
                        this.selectedItem = new Offer();
                        this.selectedItem.id = correctionId;
                        this.onActionSuccess('CREATE_CORRECTION');
                        this.blockUiController.unblock(this.CREATE_CORRECTION_ID);
                    },
                    error: error => {
                        this.blockUiController.unblock(this.CREATE_CORRECTION_ID);
                        this.errors.handle(error);
                    }
                });
                break;
            case 'REMOVE_LOCK':
                this.offerService.unblockOffer(offer.id).subscribe({
                    complete: () => {
                        this.showActionSuccessMessage(action);
                        this.currentActionOffer.offerLockUserLogin = null;
                        this.changeDetector.markForCheck();
                    }
                });
                break;
            case 'CANCEL_ORDER':
                this.setOpenedDialogNumber(DialogIds.CANCEL_ORDER);
                break;
            case 'SHOW_MESSAGES':
                this.setOpenedDialogNumber(DialogIds.MESSAGES);
                break;
            case 'SHOW_HISTORY':
                this.setOpenedDialogNumber(DialogIds.HISTORY);
                break;
            case 'SHOW_PRINT_HISTORY':
                this.setOpenedDialogNumber(DialogIds.PRINT_HISTORY);
                break;
            default:
                console.error("OrdersComponent: action '" + action + "' is unknown.");
                break;
        }
    }

    navigateToCorrection(): void {
        if (this.currentActionOffer.offerLockUserLogin == null) {
            this.blockUiController.block(this.ACQUIRE_OFFERLOCK_ID);
            this.offerService.blockOffer(this.currentActionOffer.relatedOfferId)
                .pipe(finalize(() => this.blockUiController.unblock(this.ACQUIRE_OFFERLOCK_ID)))
                .subscribe({
                    next: hasLock => this.router.navigate([`/features/offer/${this.currentActionOffer.relatedOfferId}/position`, {hasLock: hasLock}]),
                    error: error => this.setErrors(error)
                });
        } else {
            this.router.navigate([`/features/offer/${this.currentActionOffer.relatedOfferId}/position`]);
        }
    }

    deleteOrder(): void {
        console.info("OrdersComponent: deleting order: " + this.currentActionOffer.id);
        this.offerService.deleteTheOffer(this.currentActionOffer.id).subscribe({
            error: error => {
                console.error('OrdersComponent delete error:', error);
                this.setErrors(error);
            },
            complete: () => {
                this.showActionSuccessMessage('DELETE');
                this.loadItemsLazy(this.lastLoadEvent);
                console.info('OrdersComponent delete completed!');
            }
        });
    }

    closeTheDialog(): void {
        this.setOpenedDialogNumber(DialogIds.NONE);
    }

    private showActionSuccessMessage(actionName: string): void {
        let message = 'OFFER.ACTIONS.ON_SUCCESS.' + actionName;
        this.growlMessageController.info(message);
    }

    copyOffer(offer: Offer) {
        this.router.navigate(['/features/create-offer', {
            offerId: offer.id,
            mode: CreateOfferMode.COPY
        }]);
        return false;
    }

    showDialogToAdd(): void {
    }

    public showAction(action: string, offer: Offer): boolean {
        switch (action) {
            case 'DELETE':
                return this.isOpiekunOrKoordynator() &&
                    !([OfferStatus.PAID, OfferStatus.CORRECTED, OfferStatus.PARTIALLY_DISPATCHED, OfferStatus.DISPATCHED, OfferStatus.CANCEL_ORDER].includes(offer.status));
            case 'SHOW_PRODUCTION_ORDERS':
                return this.isOpiekunOrKoordynator() &&
                    ([OfferStatus.PAID, OfferStatus.CORRECTED, OfferStatus.PARTIALLY_DISPATCHED, OfferStatus.DISPATCHED, OfferStatus.CANCEL_ORDER].includes(offer.status));
            case 'GO_TO_CORRECTION':
                return offer.relatedOfferId != null &&
                    ((offer.relationType === 'CORRECTION_DRAFT' && this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) || offer.relationType === 'CORRECTION');
            case 'COPY':
                return offer.status !== OfferStatus.DELETED;
            case 'COMMENTS':
            case 'CHANGE_STATUS':
            case 'CONFIRM_CORRECTION':
                return true;
            case 'CREATE_CORRECTION':
                return this.isOpiekunOrKoordynator() && offer.status === OfferStatus.PAID && offer.relationType == undefined;
            case 'LOCKED_BY_INFO':
                return offer.offerLockUserLogin != null;
            case 'REMOVE_LOCK':
                return offer.offerLockUserLogin != null && this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPERATOR']});
            case 'SHOW_MESSAGES':
                return offer.status !== OfferStatus.DELETED && offer.highestMessageSeverity != null || offer.pricingOutdated;
            case 'CANCEL_ORDER':
                return this.isOpiekunOrKoordynator() && offer.status === OfferStatus.PAID && offer.relationType == undefined;
            case 'SHOW_HISTORY':
                return true;
            case 'SHOW_PRINT_HISTORY':
                return true;
            default:
                return false;
        }
    }

    private addActionButton(action: string, offer: any, rowActions: MenuElement[], id: string, translation?: string): void {
        if (this.showAction(action, offer)) {
            let translationKey = translation || 'OFFER.ACTIONS.TOOLTIPS.' + action;
            let builder = new MenuElementBuilder().setTranslationKey(translationKey).setIdentifier(action + id)
                .setDisabled(!this.actionEnabled(action, offer));
            rowActions.push(builder.build());
        }
    }

    private getOfferSuffix(offer: Offer) {
        return '___' + offer.id;
    }

    getRowActionsBuilder(offer: Offer): () => MenuElement[] {
        return () => this.buildRowActions(offer);
    }

    protected buildRowActions(offer: Offer): MenuElement[] {
        let rowActions: MenuElement[] = [];
        let offerSuffix: string = this.getOfferSuffix(offer);

        this.addActionButton('DELETE', offer, rowActions, offerSuffix);
        this.addActionButton('SHOW_MESSAGES', offer, rowActions, offerSuffix);
        this.addActionButton('COPY', offer, rowActions, offerSuffix);
        this.addActionButton('COMMENTS', offer, rowActions, offerSuffix);
        this.addActionButton('SHOW_PRODUCTION_ORDERS', offer, rowActions, offerSuffix);
        this.addActionButton('GO_TO_CORRECTION', offer, rowActions, offerSuffix);
        this.addActionButton('CREATE_CORRECTION', offer, rowActions, offerSuffix);
        this.addChangeStatusRowAction(offer, rowActions, offerSuffix);
        this.addLockedByInfo(offer, rowActions);
        this.addActionButton('REMOVE_LOCK', offer, rowActions, offerSuffix);
        this.addActionButton('CANCEL_ORDER', offer, rowActions, offerSuffix);
        this.addActionButton('SHOW_HISTORY', offer, rowActions, offerSuffix);
        this.addActionButton('SHOW_PRINT_HISTORY', offer, rowActions, offerSuffix);

        return rowActions;
    }

    private addChangeStatusRowAction(offer: Offer, rowActions: MenuElement[], offerSuffix: string): void {
        let possibleTransitions = this.statusTransitionDialogService.createPossibleTransitions(offer);
        if (possibleTransitions.length === 1) {
            this.addActionButton(possibleTransitions[0].action, offer, rowActions, offerSuffix, possibleTransitions[0].item.label);
        } else if (possibleTransitions.length > 1) {
            this.addActionButton('CHANGE_STATUS', offer, rowActions, offerSuffix, 'OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP');
        }
    }

    private addLockedByInfo(offer: Offer, rowActions: MenuElement[]): void {
        if (this.showAction('LOCKED_BY_INFO', offer)) {
            let params = {userLogin: offer.offerLockUserLogin};
            let title = this.translate.instant('OFFER.ACTIONS.TOOLTIPS.LOCKED_BY_INFO', params);
            let builder = new MenuElementBuilder().setTitle(title).setIdentifier('LOCKED_BY_INFO_' + offer.id)
                .setDisabled(true);
            rowActions.push(builder.build());
        }
    }

    protected actionEnabled(action: string, offer: any): boolean {
        switch (action) {
            case 'DELETE':
            case 'CHANGE_STATUS':
            case 'COMMENTS':
                return offer.offerLockUserLogin == null || offer.offerLockUserLogin === this.currentUserLogin;
            default:
                return true;
        }
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent) {
        let data = event.identifier.split('___');
        let action = data[0];

        let offerId = +data[1];
        let found = this.offers.find(offer => offer.id === offerId);

        if (found) {
            this.actionOnClick(action, found);
        } else {
            console.error("Action called for non existing positionId " + offerId);
        }
    }

    onActionSuccess(actionName: string): void {
        this.showActionSuccessMessage(actionName);
        this.loadItemsLazy(this.lastLoadEvent);
        console.info('OffersComponent action `' + actionName + '` completed!');
    }

    onActionError(actionName: string, error): void {
        console.error('OffersComponent action `' + actionName + '` error:', error);
        this.setErrors(error);
    }

    private setOpenedDialogNumber(dialogNumber: DialogIds): void {
        if (this.openedDialogNumber !== dialogNumber) {
            this.openedDialogNumber = dialogNumber;
            this.changeDetector.markForCheck();
        }
    }

    private setErrors(errors: HttpErrorResponse | Error): void {
        if (errors instanceof HttpErrorResponse) {
            let message = new ErrorResponse(errors.error).message;
            if (message) {
                this.growlMessageController.error(message);
                this.changeDetector.markForCheck();
            } else {
                throw new ResponseError(errors);
            }
        } else {
            throw errors;
        }
    }

    handleCurrencyMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.selectedCurrency = event.identifier;
    }

    private isOpiekunOrKoordynator(): boolean {
        return this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    resetCommentDialog(commentAddedOrEdited: boolean): void {
        if (commentAddedOrEdited) {
            this.loadItemsLazy(this.lastLoadEvent);
        }

        this.closeTheDialog();
    }

    onSaveCommentSuccess(): void {
        this.showActionSuccessMessage('ADD_COMMENT');
    }

    hideTransitionsDialog(): void {
        this.transitionsDialogVisible = false;
        this.possibleTransitions = [];

        this.changeDetector.markForCheck();
    }

    changeStatus(selectedTransition: StatusTransition): void {
        if (selectedTransition) {
            switch (selectedTransition.action) {
                case 'CHANGE_STATUS':
                    this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                    selectedTransition.item.command();
                    this.hideTransitionsDialog();
                    break;
                default:
                    console.error("Unsupported status transition.", selectedTransition.action);
                    break;
            }
        }
    }

    public get filterByCurrentUser(): boolean {
        return this.customFiltersColumnsActive.some(x => x === OrdersComponent.FILTER_MINE);
    }

    public set filterByCurrentUser(value: boolean) {
        let index = this.customFiltersColumnsActive.indexOf(OrdersComponent.FILTER_MINE, 0);
        if (!value && index >= 0) {
            this.customFiltersColumnsActive.splice(index, 1);
        } else if (value && index < 0) {
            this.customFiltersColumnsActive.push(OrdersComponent.FILTER_MINE);
        }
    }

    public get requireLatestOfferVersion(): boolean {
        return this.customFiltersColumnsActive.some(x => x === OrdersComponent.FILTER_LATEST);
    }

    public set requireLatestOfferVersion(value: boolean) {
        let index = this.customFiltersColumnsActive.indexOf(OrdersComponent.FILTER_LATEST, 0);
        if (!value && index >= 0) {
            this.customFiltersColumnsActive.splice(index, 1);
        } else if (value && index < 0) {
            this.customFiltersColumnsActive.push(OrdersComponent.FILTER_LATEST);
        }
    }

    onCustomFilterChange() {
        if (this.lastLoadEvent) {
            this.lastLoadEvent.filters['clientId'] = undefined;
            this.incomingClientId = undefined;
        }
        this.loadItemsLazy(this.lastLoadEvent);
    }

    private initFilterByCurrentUser(): void {
        if (this.filterByCurrentUser == undefined) {
            this.filterByCurrentUser = true;
        }
        if (this.route.snapshot.paramMap.has('forCurrentUser')) {
            this.filterByCurrentUser = this.route.snapshot.paramMap.get('forCurrentUser') === 'true';
        }
    }

    private initFilterRequireLatestOfferVersion(): void {
        if (this.requireLatestOfferVersion == undefined) {
            this.requireLatestOfferVersion = false;
        }
    }

    filterCurrentUser(event: LazyLoadEvent): void {
        let forCurrentUser;
        if (this.incomingClientId != undefined) {
            this.customFiltersColumnsActive = [];
            forCurrentUser = false;
        } else {
            forCurrentUser = this.filterByCurrentUser;
        }
        event.filters['forCurrentUser'] = {value: forCurrentUser, matchMode: undefined};
    }

    filterRequireLatestOfferVersion(event: LazyLoadEvent): void {
        event.filters['requireLatestOfferVersion'] = {value: this.requireLatestOfferVersion, matchMode: undefined};
    }

    addSelectedToShipping(stopIfSimulationImpossibleForAnyPosition: boolean): void {
        this.shippingSimulationService.addOffersToDraft(this.selectedItems.map(item => item.id), stopIfSimulationImpossibleForAnyPosition)
            .subscribe({
                next: result => {
                    if (stopIfSimulationImpossibleForAnyPosition && !result.allPositionsCanBeSimulated) {
                        this.setOpenedDialogNumber(DialogIds.CONFIRM_PARTIAL_SIMULATION);
                        return;
                    }
                    this.shippingFloatButton.refresh();

                    if (this.selectedItems.length === result.offersWithWindowPositions) {
                        this.growlMessageController.info('SHIPPING_SIMULATION.MESSAGE.SUCCESS.OFFERS_ADDED');
                    } else if (this.selectedItems.length === 1 && result.offersWithWindowPositions === 0) {
                        this.growlMessageController.info('SHIPPING_SIMULATION.MESSAGE.SUCCESS.OFFER_WITHOUT_SYSTEM_POSITIONS');
                    } else {
                        this.growlMessageController.info('SHIPPING_SIMULATION.MESSAGE.SUCCESS.OFFERS_PARTIALLY_ADDED');
                    }
                },
                error: (error: HttpErrorResponse) => {
                    this.growlMessageController.error(new ErrorResponse(error.error).message);
                }
            });
    }

    showShippingSidebar(): void {
        this.shippingSimulationSidebar.show();
    }

    shippingSidebarHidden(): void {
        this.shippingFloatButton.refresh();
    }

    shippingCalculationStarted(): void {
        this.shippingCalculationInProgress = true;
    }

    shippingCalculationFinished(): void {
        this.shippingCalculationInProgress = false;
    }

    saveShownColumns() {
        const shownColumns = super.saveShownColumns();
        this.onVisibleColumnsChange.emit(shownColumns);
        return shownColumns;
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.updateVisibleColumns(visibleColumns);
        this.getDatatable().resetFilters();
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        // delete legacy filter
        delete filter.filters.subsystemName;

        this.lastLoadEvent.filters = filter.filters;
        delete this.lastLoadEvent.filters.paymentPackageName;
        this.lastLoadEvent.sortOrder = filter.sortOrder;
        this.lastLoadEvent.sortField = filter.sortField;
        this.applyExtraHeaderFilters(filter);
        this.applyMultiSelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const currentUserFilter = filter.filters['forCurrentUser'];
        if (currentUserFilter != undefined) {
            this.filterByCurrentUser = '' + currentUserFilter.value === 'true';
        }
        const requireLatestOfferVersion = filter.filters['requireLatestOfferVersion'];
        if (requireLatestOfferVersion != undefined) {
            this.requireLatestOfferVersion = '' + requireLatestOfferVersion.value === 'true';
        }
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedStatuses, filter.filters['status']);
        this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        SavedFilterService.applyMultiSelectFilters(this.selectedSubsystems, filter.filters['subsystemIds']);
        this.getDatatable().setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
    }

    hasCorrection(order: Offer): boolean {
        return order.relationType === "CORRECTION";
    }

    private navigateToPositionList(offerId: number, hasLock: boolean): void {
        this.router.navigate(['/features/offer', offerId, 'position', {hasLock: hasLock}]);
    }

    redirectToPositionList(offer: Offer): void {
        this.tryToAcquireOfferLockAndNavigateToPositionList(offer.id, offer.offerLockUserLogin);
    }

    private tryToAcquireOfferLockAndNavigateToPositionList(offerId: number, offerLockUserLogin: string) {
        if (offerLockUserLogin == null) {
            this.blockUiController.block(this.ACQUIRE_OFFERLOCK_ID);
            this.offerService.blockOffer(offerId).subscribe(
                hasLock => {
                    this.blockUiController.unblock(this.ACQUIRE_OFFERLOCK_ID);
                    this.navigateToPositionList(offerId, hasLock);
                });
        } else {
            this.navigateToPositionList(offerId, offerLockUserLogin === this.currentUserLogin);
        }
    }

    public openPrintDialog() {
        this.printableItems = this.selectedItems.map(item => {
            let printableItem = new PrintableItem(item.id, item.offerNumber);
            printableItem.status = item.status;
            return printableItem;
        });
        this.printDialogVisible = true;
    }

    cancelOrder() {
        console.info("OrdersComponent: canceling order: " + this.currentActionOffer.id);
        this.offerService.cancelOrder(this.currentActionOffer.id).subscribe({
            next: () => {
                this.loadItemsLazy(this.lastLoadEvent);
                this.showActionSuccessMessage("CANCEL_ORDER");
            },
            error: error => {
                this.setErrors(error);
                console.error("OrdersComponent: error during canceling order: " + this.currentActionOffer.id);
            }
        });
    }

    showUnreadCommentIcon(offer: Offer): boolean {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return offer.hasCommentUnreadByVenska;
        }
        return offer.hasCommentUnreadBySubsystem;
    }

    showCommentExistIcon(offer: Offer): boolean {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return !offer.hasCommentUnreadByVenska && offer.hasComment;
        }
        return !offer.hasCommentUnreadBySubsystem && offer.hasComment;
    }

    onPrintHistoryClick(printHistoryEntry: PrintHistoryEntry): void {
        this.openedDialogNumber = null;
        let order = this.offers.find(offer => offer.id === printHistoryEntry.offerId);
        let printableItem = new PrintableItem(order.id, order.offerNumber);
        printableItem.printHistoryEntry = printHistoryEntry;
        this.printableItems = [printableItem];
        this.printHistoryDialogVisible = true;
    }
}
