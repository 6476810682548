import {ArcCutData} from "../drawing-data/ArcCutData";
import {ArchEllipticalWindowShape} from "../drawing-data/ArchEllipticalWindowShape";
import {ArchSegmentalWindowShape} from "../drawing-data/ArchSegmentalWindowShape";
import {ArchThreeCenteredWindowShape} from "../drawing-data/ArchThreeCenteredWindowShape";
import {EllipseWindowShape} from "../drawing-data/EllipseWindowShape";
import {RectWindowShape} from "../drawing-data/RectWindowShape";
import {WindowShape} from "../drawing-data/WindowShape";
import {WindowShapeType} from "../drawing-data/WindowShapeType";
import {MinMaxXY} from "../drawing-util";
import {ErrorNames} from "./ErrorNames";
import {FloatOps} from "./float-ops";

export abstract class WindowShapeUtil {

    private static readonly ARC_TO_TOTAL_SIZE_RATIO = 0.30;

    public static generateDefaultWindowShape(shapeType: WindowShapeType, box: MinMaxXY): WindowShape {
        let width = box.maxX - box.minX;
        let height = box.maxY - box.minY;
        let rx = Math.floor(width * WindowShapeUtil.ARC_TO_TOTAL_SIZE_RATIO);
        let ry = Math.floor(height * WindowShapeUtil.ARC_TO_TOTAL_SIZE_RATIO);

        switch (shapeType) {
            case WindowShapeType.RECTANGULAR:
                return new RectWindowShape();
            case WindowShapeType.ELLIPSE:
                return new EllipseWindowShape();
            case WindowShapeType.ARCH_ELLIPTICAL:
                return new ArchEllipticalWindowShape(ry);
            case WindowShapeType.ARCH_SEGMENTAL:
                let arcHeight = Math.min(ry, Math.floor(width / 2));
                return new ArchSegmentalWindowShape(arcHeight);
            case WindowShapeType.ARCH_THREE_CENTERED:
                return new ArchThreeCenteredWindowShape(rx, rx, ry);
            default:
                let err = new Error("Not implemented for window shape type: [" + shapeType + "]");
                err.name = ErrorNames.UNKNOWN_SHAPE_TYPE;
                throw err;
        }
    }

    public static generateCutsForWindowShape(shape: WindowShape, box: MinMaxXY): ArcCutData[] {
        if (WindowShape.isRectangular(shape)) {
            return [];
        } else if (WindowShape.isEllipse(shape)) {
            return WindowShapeUtil.generateCutsForEllipse(box);
        }
        this.validateArcHeightAndWidth(box, shape);
        if (WindowShape.isArchElliptical(shape)) {
            return WindowShapeUtil.generateCutsForArchElliptical(box, shape);
        } else if (WindowShape.isArchSegmental(shape)) {
            return WindowShapeUtil.generateCutsForArchSegmental(box, shape);
        } else if (WindowShape.isArchThreeCentered(shape)) {
            return WindowShapeUtil.generateCutsForArchThreeCentered(box, shape);
        }
        let err = new Error("Not implemented for window shape: [" + shape + "]");
        err.name = ErrorNames.UNKNOWN_SHAPE_TYPE;
        throw err;
    }

    private static validateArcHeightAndWidth(box: MinMaxXY,
                                             shape: ArchEllipticalWindowShape | ArchSegmentalWindowShape | ArchThreeCenteredWindowShape) {
        let width = box.maxX - box.minX;
        let arcHeight = WindowShape.isArchElliptical(shape) || WindowShape.isArchSegmental(shape) ? shape.arcHeight : shape.ry;
        if (FloatOps.lt(width / 2, arcHeight)) {
            let err = new Error("Arc height for cannot be greater than the half of window width");
            err.name = ErrorNames.ARC_HEIGHT_TOO_BIG_FOR_GIVEN_WIDTH;
            throw err;
        }
    }

    private static generateCutsForEllipse(box: MinMaxXY): ArcCutData[] {
        let width = box.maxX - box.minX;
        let height = box.maxY - box.minY;
        let rx = width / 2;
        let ry = height / 2;
        let cx = box.minX + rx;
        let cy = box.minY + ry;
        return [
            new ArcCutData(cx, cy, rx, ry, -Math.PI, 0),
            new ArcCutData(cx, cy, rx, ry, 0, Math.PI)];
    }

    private static generateCutsForArchElliptical(box: MinMaxXY, shape: ArchEllipticalWindowShape) {
        if (FloatOps.le(shape.arcHeight, 0)) {
            return [];
        }
        let width = box.maxX - box.minX;
        let rx = width / 2;
        let ry = shape.arcHeight;
        let cx = box.minX + rx;
        let cy = box.minY + ry;
        return [new ArcCutData(cx, cy, rx, ry, -Math.PI, 0)];
    }

    private static generateCutsForArchSegmental(box: MinMaxXY, shape: ArchSegmentalWindowShape) {
        if (FloatOps.le(shape.arcHeight, 0)) {
            return [];
        }
        let width = box.maxX - box.minX;
        let r = shape.arcHeight / 2 + width * width / (8 * shape.arcHeight);
        let cx = box.minX + width / 2;
        let cy = box.minY + r;
        let fi = Math.acos(width / 2 / r);
        return [new ArcCutData(cx, cy, r, r, -Math.PI + fi, -fi)];
    }

    private static generateCutsForArchThreeCentered(box: MinMaxXY, shape: ArchThreeCenteredWindowShape) {
        if (FloatOps.le(shape.ry, 0)) {
            return [];
        }
        let cy = box.minY + shape.ry;
        let result = [];
        if (FloatOps.gt(shape.rx1, 0)) {
            let cx1 = box.minX + shape.rx1;
            result.push(new ArcCutData(cx1, cy, shape.rx1, shape.ry, -Math.PI, -Math.PI / 2));
        }
        if (FloatOps.gt(shape.rx2, 0)) {
            let cx2 = box.maxX - shape.rx2;
            result.push(new ArcCutData(cx2, cy, shape.rx2, shape.ry, -Math.PI / 2, 0));
        }
        return result;
    }
}
