<div class="flex-row-container">
    <div *ngIf="showMenu" class="second-level-menu">
        <ng-container *ngFor="let group of sideNavItemsByGroup; let last = last">
            <div class="title">
                <div class="text">{{ ('NAVIGATION.ITEMS.WINDOW-SYSTEM.' + group.groupName) | translate }}</div>
            </div>
            <div *ngFor="let item of group.items" class="navigation-element" id="{{item.cssClass}}"
                 [class.active]="isActiveSubComponent(item.cssClass)"
                 (click)="navigateToSubComponent(item.cssClass)">
                <div class="name">{{ item.name | translate }}</div>
            </div>

            <div *ngIf="!last" class="menu-separator">&nbsp;</div>
        </ng-container>
    </div>

    <div class="content" [ngClass]="{'second-level-menu-hidden': !showMenu}">
        <app-window-system-definition *ngIf="isActiveSubComponent('windowSystems')"></app-window-system-definition>
        <app-color *ngIf="isActiveSubComponent('colors')"
                   [colorTarget]="colorTarget.General"></app-color>
        <app-color *ngIf="isActiveSubComponent('configColors')"
                   [colorTarget]="colorTarget.Config"></app-color>
        <app-glass *ngIf="isActiveSubComponent('glasses')"></app-glass>
        <app-distance-frame *ngIf="isActiveSubComponent('frames')"></app-distance-frame>
        <app-seal *ngIf="isActiveSubComponent('seals')"></app-seal>
        <app-subwindow-type *ngIf="isActiveSubComponent('subwindowTypes')"></app-subwindow-type>
        <app-business-type *ngIf="isActiveSubComponent('businessTypes')"></app-business-type>
        <app-grill *ngIf="isActiveSubComponent('grills')"></app-grill>
        <app-roof-glazing-package *ngIf="isActiveSubComponent('glazingPackages')"></app-roof-glazing-package>
        <app-other-filling *ngIf="isActiveSubComponent('otherFillings')"></app-other-filling>
        <app-decorative-filling *ngIf="isActiveSubComponent('decorativeFillings')"></app-decorative-filling>
        <app-addons *ngIf="isActiveSubComponent('addons')"
                    [addonCategoryTarget]="addonCategoryEnumGroups.Window"></app-addons>
        <app-addons *ngIf="isActiveSubComponent('gateAddons')"
                    [addonCategoryTarget]="addonCategoryEnumGroups.Gate"></app-addons>
        <app-addons *ngIf="isActiveSubComponent('configSidebarAddons')"
                    [addonCategoryTarget]="addonCategoryEnumGroups.Config"></app-addons>
        <app-addon-category *ngIf="isActiveSubComponent('addonCategoryGate')" [target]="catalogSection.GATE"></app-addon-category>
        <app-addon-category-group *ngIf="isActiveSubComponent('addonCategoryGroupGate')" [target]="catalogSection.GATE"></app-addon-category-group>
        <app-addon-category *ngIf="isActiveSubComponent('addonCategoryConfig')" [target]="catalogSection.CONFIG"></app-addon-category>
        <app-addon-category-group *ngIf="isActiveSubComponent('addonCategoryGroupConfig')" [target]="catalogSection.CONFIG"></app-addon-category-group>
        <app-profile *ngIf="isActiveSubComponent('profiles')"></app-profile>
        <app-material *ngIf="isActiveSubComponent('materials')"></app-material>
        <app-config-addons *ngIf="isActiveSubComponent('configAddons')"></app-config-addons>
        <app-glazing-bead *ngIf="isActiveSubComponent('glazingBeads')"></app-glazing-bead>
        <app-assembly *ngIf="isActiveSubComponent('assemblies')"></app-assembly>
        <app-transport *ngIf="isActiveSubComponent('transports')"></app-transport>
        <app-rack *ngIf="isActiveSubComponent('racks')"></app-rack>
        <app-window-dimensions *ngIf="isActiveSubComponent('roofWindowsDimensions')"
                               [type]="windowDimensionsTypes.ROOF"></app-window-dimensions>
        <app-window-dimensions *ngIf="isActiveSubComponent('entranceDimensions')"
                               [type]="windowDimensionsTypes.ENTRANCE"></app-window-dimensions>
        <app-window-system-definition *ngIf="isActiveSubComponent('roofSystems')"
                                      [roofSystem]="true"></app-window-system-definition>
        <app-webshop-glazing-package *ngIf="isActiveSubComponent('webshopGlazingPackages')"
                                     [target]="glazingPackageTargets.WEBSHOP_GLAZING"></app-webshop-glazing-package>
        <app-glazing-package *ngIf="isActiveSubComponent('systemGlazingPackages')"
                                     [target]="glazingPackageTargets.SYSTEM_DEFINITION"></app-glazing-package>
        <app-glazing-package *ngIf="isActiveSubComponent('systemDecorativeGlazingPackages')"
                                     [target]="glazingPackageTargets.DECORATIVE_GLAZING"></app-glazing-package>
        <app-webshop-glazing-package *ngIf="isActiveSubComponent('terraceGlazingPackages')"
                                     [target]="glazingPackageTargets.TERRACE_SYSTEM"></app-webshop-glazing-package>
        <app-window-system-model *ngIf="isActiveSubComponent('models')"></app-window-system-model>
        <app-webshop-business-type *ngIf="isActiveSubComponent('webshopBusinessTypes')"></app-webshop-business-type>
        <app-window-system-webshop *ngIf="isActiveSubComponent('windowSystemsWebshop')"></app-window-system-webshop>
        <app-webshop-glazing-package-per-subsystem
                *ngIf="isActiveSubComponent('webshopGlazingPackagesPerSubsystem')"></app-webshop-glazing-package-per-subsystem>
        <app-webshop-charge *ngIf="isActiveSubComponent('webshopCharges')" [target]="dataModificationTargets.WEBSHOP_CHARGE"></app-webshop-charge>
        <app-webshop-recommended-configuration
                *ngIf="isActiveSubComponent('webshopRecommendedConfiguration')"></app-webshop-recommended-configuration>
        <app-webshop-sale *ngIf="isActiveSubComponent('webshopSale')"></app-webshop-sale>
        <app-catalog-item-tags *ngIf="isActiveSubComponent('catalogItemTags')"></app-catalog-item-tags>
        <app-subsystem-notes-categories *ngIf="isActiveSubComponent('subsystemNotesCategories')"></app-subsystem-notes-categories>
        <app-webshop-charge-per-subsystem *ngIf="isActiveSubComponent('webshopChargePerSubsystem')"
                                          [target]="dataModificationTargets.WEBSHOP_CHARGE"></app-webshop-charge-per-subsystem>
        <app-webshop-area-bars *ngIf="isActiveSubComponent('webshopAreaBars')"></app-webshop-area-bars>
        <app-window-designer-catalog-dependent-options-set
                *ngIf="isActiveSubComponent('windowDesignerCatalogDependentOption')"
                [configuration]="dependentOptionConfigurations.WINDOW_SYSTEM"></app-window-designer-catalog-dependent-options-set>
        <app-window-system-definition *ngIf="isActiveSubComponent('entranceSystems')"
                                      [entranceSystem]="true"></app-window-system-definition>
        <app-entrance-glazing-package *ngIf="isActiveSubComponent('entranceGlazingPackages')"></app-entrance-glazing-package>
        <app-entrance-model *ngIf="isActiveSubComponent('entranceModels')"></app-entrance-model>
        <app-grasp-glazing-categories *ngIf="isActiveSubComponent('graspGlazingCategories')"></app-grasp-glazing-categories>
        <app-grasp-distance-frame-category *ngIf="isActiveSubComponent('graspDistanceFrameCategory')"></app-grasp-distance-frame-category>
        <app-grasp-glazing-package *ngIf="isActiveSubComponent('graspGlazingPackages')"></app-grasp-glazing-package>
        <app-discontinued-catalog-item-info
                *ngIf="isActiveSubComponent('discontinuedCatalogItemInfo')"></app-discontinued-catalog-item-info>
        <app-gate-system *ngIf="isActiveSubComponent('gateSystem')"></app-gate-system>
        <app-rail-system *ngIf="isActiveSubComponent('railSystem')"></app-rail-system>
        <app-gate-panel-type *ngIf="isActiveSubComponent('gatePanelType')"></app-gate-panel-type>
        <app-gate-wall *ngIf="isActiveSubComponent('gateWall')"></app-gate-wall>
        <app-gate-designer-catalog-dependent-options-set
                *ngIf="isActiveSubComponent('gateDesignerCatalogDependentOption')"></app-gate-designer-catalog-dependent-options-set>
        <app-config-designer-catalog-dependent-options-set
                *ngIf="isActiveSubComponent('configDesignerCatalogDependentOption')"></app-config-designer-catalog-dependent-options-set>
        <app-config-type-descriptor *ngIf="isActiveSubComponent('configTypeDescriptor')"></app-config-type-descriptor>
        <app-config-system *ngIf="isActiveSubComponent('configSystem')"></app-config-system>
        <app-config-slider-element *ngIf="isActiveSubComponent('configSliderElement')"></app-config-slider-element>
        <app-window-designer-catalog-dependent-options-set
                *ngIf="isActiveSubComponent('entranceDesignerCatalogDependentOption')"
                [configuration]="dependentOptionConfigurations.ENTRANCE_DOOR"></app-window-designer-catalog-dependent-options-set>
    </div>
</div>
