import {AddonFor} from "../../enums/AddonFor";

export class AddonQuantityDataSource {

    quantities: { [key: string]: number } = {};
    windowArea: number;
    windowHeight: number;
    windowPerimeter: number;
    windowWidth: number;

    constructor(windowArea = undefined,
                windowHeight = undefined,
                windowPerimeter = undefined,
                windowWidth = undefined) {
        this.quantities = {};
        this.quantities[AddonFor.BULK] = 1;
        this.quantities[AddonFor.WING_FUNCTIONS_GROUP] = 1;
        this.windowArea = windowArea;
        this.windowHeight = windowHeight;
        this.windowPerimeter = windowPerimeter;
        this.windowWidth = windowWidth;
    }
}

export class AddonQuantityDataSourceFactory {
    public static readonly forChecks = new AddonQuantityDataSource(1, 1, 1, 1);
}
