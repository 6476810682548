import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogOption} from './catalog-option';
import {CatalogOptionService} from './catalog-option.service';
import {CatalogPropertyTarget} from "../CatalogPropertyTarget";
import {SelectItem} from "primeng/api/selectitem";

@Component({
    selector: 'app-catalog-option',
    templateUrl: './catalog-option.component.html',
    styleUrls: ['./catalog-option.component.css', '../../shared-styles.css'],
    providers: [CatalogOptionService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogOptionComponent extends CrudCommonComponent<CatalogOption, CatalogOptionService> implements OnInit {

    item: CatalogOption;
    validateDataStep: WizardStepValidator;
    validationErrors: { [field: string]: string } = {};
    filterTarget: Observable<SelectItem[]>;

    canUploadToAmazon = false;
    amazonFiles: SelectItem[] = [];
    uploadedAmazonFile: File;

    readonly STEPS = {
        DATA: 'DATA'
    };

    @ViewChild('dt') datatable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, CatalogOptionService, 'CATALOG_OPTION', 'CatalogOption');
        this.item = new CatalogOption();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.item = this.getNewItem();
        this.filterTarget = this.translatedSelectItemService.buildSortedDropdown(Object.values(CatalogPropertyTarget),
            'NAVIGATION.ITEMS.CATALOG_CREATOR.VIEWS.', '');
        forkJoin({
            canUploadToAmazon: this.itemService.canUploadToAmazonS3(),
            amazonFiles: this.itemService.getAmazonS3Files()
        }).subscribe({
            next: data => {
                this.amazonFiles = data.amazonFiles.map(file => ({label: file, value: file}));
                this.canUploadToAmazon = data.canUploadToAmazon;
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.item = new CatalogOption();
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.item = new CatalogOption();
            this.getItemWithImage(this.selectedItem.id);
        }
    }

    getNewItem(): CatalogOption {
        return new CatalogOption();
    }

    getItemWithImage(optionId: number) {
        forkJoin({
            item: this.itemService.getItem(optionId),
            image: this.itemService.getImage(optionId)
        }).subscribe({
            next: data => {
                this.item = data.item;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.file = data.image;
                if (!this.copyMode) {
                    // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                    this.focusOnElementWithId(this.getFirstInputId());
                } else {
                    this.item.id = undefined;
                }
                if (!this.canUploadToAmazon) {
                    if (this.amazonFiles.findIndex(af => af.value === this.item.amazonS3FileName) < 0) {
                        this.item.amazonS3FileName = undefined;
                    }
                }
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getItemWithImage` completed!');
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'icon');

        if (this.item.symbol == null || this.item.symbol === '') {
            this.validationErrors['symbol'] = 'error.catalogOptionDto.symbol.not_empty';
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.catalogOptionDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 9999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validationErrors = {};
        if (this.validateForm()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file, this.uploadedAmazonFile);
            } else {
                observable = this.itemService.saveCatalogOption(this.item, this.file, this.uploadedAmazonFile);
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }

        this.edit();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    handlePhotoDownloadClick() {
        console.log('can someone click me, please?')
        this.itemService.getCatalogOptionFileUrl(this.item.id).subscribe(url => window.open(url, '_blank'));
    }
}
