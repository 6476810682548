import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {Message} from 'primeng/api/message';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, of} from 'rxjs';
import {finalize, mergeMap} from 'rxjs/operators';
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {ValidationService} from '../../../common/service/validation.service';
import {ZipCodeValidator} from '../../../common/zip-code-validator';
import {Currencies} from '../../../currencies';
import {WizardDialogComponent} from '../../../form-inputs/wizard/wizard-dialog.component';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {ClientGroupService} from '../../client-group/client-group.service';
import {ClientGroup} from '../../client-group/ClientGroup';
import {HasSaveTable} from '../../subsystem/base-profit-margin.component';
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from '../../subsystem/profit-margin/profitMargin';
import {SupplierService} from '../../supplier/supplier.service';
import {UserService} from '../../user/user.service';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {AddClientSubmitEvent, AddClientSubmitEventType} from '../add-client-submit-event';
import {Client} from '../client';
import {ClientService} from '../client.service';
import {SimpleProfitMarginSource, validateSimpleProfitMargins} from '../simple-profit-margin-source/simple-profit-margin-source';
import {ProfitMarginTarget} from "../../subsystem/profit-margin/profit-margin-target";

@Component({
    selector: 'app-edit-client',
    templateUrl: './edit-client.component.html',
    providers: [ValidationService, UserService, SupplierService, ClientGroupService]
})
export class EditClientComponent implements OnInit {

    private static readonly SUBMIT_BLOCK_ID = 'EditClientComponent submit';

    readonly WindowSystemTypeGroup = ProductTypeGroup;

    readonly STEPS = {
        GENERAL: 'general',
        SYSTEM_MARGINS: 'profit-margins-system',
        ROOF_SYSTEM_MARGINS: 'profit-margins-roof-system',
        ENTRANCE_SYSTEM_MARGINS: 'profit-margins-entrance-system',
        CONFIG_ADDON_MARGINS: 'profit-margins-config-addon',
        GATES_MARGINS: 'profit-margins-gates'
    };

    readonly profitGlobalMarginButtonId = 'profitGlobalMarginButtonId';
    readonly profitGlobalMarginInputId = 'profitGlobalMarginInputId';
    readonly roofProfitGlobalMarginButtonId = 'roofProfitGlobalMarginButtonId';
    readonly roofProfitGlobalMarginInputId = 'roofProfitGlobalMarginInputId';
    readonly entranceProfitGlobalMarginButtonId = 'entranceProfitGlobalMarginButtonId';
    readonly entranceProfitGlobalMarginInputId = 'entranceProfitGlobalMarginInputId';
    readonly confAddGlobalMarginButtonId = 'confAddGlobalMarginButtonId';
    readonly confAddGlobalMarginInputId = 'confAddGlobalMarginInputId';
    readonly gatesGlobalMarginButtonId = 'gatesGlobalMarginButtonId';
    readonly gatesGlobalMarginInputId = 'gatesGlobalMarginInputId';

    readonly route: ActivatedRoute;

    possibleTargets = ProfitMarginTarget;

    @Input() hideProfitMargins: boolean;
    @Input() client: Client;
    @Input() existingGroups: ClientGroup[] = [];
    validationErrors = {};
    suppliers: SupplierInterface[];
    groupSimpleProfitMarginSource?: SimpleProfitMarginSource;
    simpleProfitMarginSource?: SimpleProfitMarginSource;

    generalStepValidator: WizardStepValidator;

    initialDataLoaded = false;
    saveInProgress: boolean;
    selectCurrencies: SelectItem[] = [];
    selectUsers: SelectItem[] = [];
    private enterHotkey: Hotkey;
    msgs: Message[] = [];

    @ViewChildren('childTable')
    childTables: QueryList<HasSaveTable>;

    @ViewChild('wizard', {static: true}) wizard: WizardDialogComponent;
    @Output() onSubmit: EventEmitter<AddClientSubmitEvent> = new EventEmitter<AddClientSubmitEvent>();

    constructor(private translate: TranslateService,
                private validationService: ValidationService,
                private clientService: ClientService,
                private errors: CommonErrorHandler,
                private permissions: Permissions,
                private hotkeysService: HotkeysService,
                private changeDetector: ChangeDetectorRef,
                private userService: UserService,
                public blockUiController: BlockUiController,
                private supplierService: SupplierService,
                private clientGroupService: ClientGroupService,
                injector: Injector) {
        this.route = injector.get(ActivatedRoute);
    }

    ngOnInit() {
        for (let item in Currencies) {
            this.selectCurrencies.push({label: item, value: item});
        }
        this.enterHotkey = new Hotkey('enter', (event: any): boolean => {
            if (event.target && event.target.parentElement && event.target.parentElement.className && event.target.parentElement.className.includes('p-datatable-cell-editor')) {
                return false;
            }
            this.submit();
            return false;
        }, ['INPUT']);
        this.hotkeysService.add(this.enterHotkey);
        this.generalStepValidator = () => {
            const validationErrors = this.validateForm();
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return !this.validationErrorsPresent();
        };

        if (this.client.name != null) {
            this.initSelectUsers();
        }
        forkJoin({
            suppliers: this.supplierService.getSuppliersForSimpleProfitMargins(),
            simpleProfitMarginSource: this.client.id !== undefined ?
                this.clientService.getSimpleProfitMarginSource(this.client.id) : of(new SimpleProfitMarginSource()),
            groupSimpleProfitMarginSource: this.client.groupId !== undefined ?
                this.clientGroupService.getSimpleProfitMarginSource(this.client.groupId) : of(new SimpleProfitMarginSource()),
        }).subscribe(data => {
            this.suppliers = data.suppliers;
            this.simpleProfitMarginSource = data.simpleProfitMarginSource;
            this.setGroupSimpleProfitMarginSource(data.groupSimpleProfitMarginSource);
            this.initialDataLoaded = true;
            this.changeDetector.markForCheck();
            setTimeout(() => this.wizard.centerDialog());
        });
    }

    private initSelectUsers(): void {
        this.userService.getUsersBySubsystem().subscribe(response => {
            let users = response.data.filter(user => user.active && (user.roleName === 'HANDLOWIEC' || user.roleName === 'OPERATOR'));
            const clientOwnerIsHiddenUser = users.filter(user => user.isHidden).find(user => this.client.owner === user.login);
            if (!clientOwnerIsHiddenUser) {
                users = users.filter(user => !user.isHidden);
            }
            users.forEach(user => this.selectUsers.push({label: user.firstName + ' ' + user.lastName, value: user.login}));
            if (this.selectUsers.length > 0) {
                let selectItem = this.selectUsers.find(selectUser => this.client.owner === selectUser.value);
                this.client.owner = selectItem && selectItem.value;
                this.changeDetector.markForCheck();
            }
        });
    }

    prepareSuccessMessage() {
        this.translate.get(['CLIENT-DETAILS.CLIENT_UPDATED', 'GENERAL.INFO']).subscribe((res: any) => {
            this.pushMessage({
                severity: 'info',
                summary: res['GENERAL.INFO'],
                detail: res['CLIENT-DETAILS.CLIENT_UPDATED']
            });
        });
    }

    private pushMessage(message: Message): void {
        this.msgs.push(message);
        this.changeDetector.markForCheck();
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    submit(): void {
        if (this.saveInProgress) {
            return;
        }

        this.setSaveInProgress(true);
        if (!!this.client.bulkAddonProfitMargin) {
            this.client.bulkAddonProfitMargin = normalizeProfitMarginValueString(this.client.bulkAddonProfitMargin);
        }
        this.blockUiController.block(EditClientComponent.SUBMIT_BLOCK_ID);
        this.clientService.saveClient(this.client).pipe(
            finalize(() => {
                this.setSaveInProgress(false);
                this.blockUiController.unblock(EditClientComponent.SUBMIT_BLOCK_ID);
            }),
            mergeMap(clientId => forkJoin([...this.childTables.map(table => table.saveTable(clientId)),
                this.clientService.saveSimpleProfitMarginSource(clientId, this.simpleProfitMarginSource)]
            )))
            .subscribe({
                next: () => {
                    this.prepareSuccessMessage();
                    this.notifyParent('success');
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                }
            });
    }

    public notifyParent(notificationType: AddClientSubmitEventType) {
        let result = new AddClientSubmitEvent();
        result.msgs = this.msgs;
        result.type = notificationType;
        this.hotkeysService.remove(this.enterHotkey);
        this.onSubmit.emit(result);
    }

    validateForm() {
        const validationErrors = {};
        this.validationService.validateNotEmpty(this.client, ['name'], validationErrors, 'clientDto');
        if (this.client.email && !/^.+@.+$/.test(this.client.email)) {
            validationErrors['email'] = 'error.clientDto.email.not_well_formatted';
        }
        if (!this.client.groupId) {
            validationErrors['groupId'] = 'error.clientDto.groupId.not_null';
        }
        if (this.client.address.zip != undefined &&
            !new ZipCodeValidator().validate(this.client.address.country, this.client.address.zip)) {
            validationErrors['address.zip'] = 'error.clientDto.address.zip.pattern_not_matched';
        }
        if (this.simpleProfitMarginSource != undefined) {
            Object.assign(validationErrors, validateSimpleProfitMargins(this.simpleProfitMarginSource, this.suppliers, false));
        }
        if (!!this.client.bulkAddonProfitMargin
            && !isValidProfitMarginValueString(normalizeProfitMarginValueString(this.client.bulkAddonProfitMargin))) {
            validationErrors['bulkAddonProfitMargin'] = 'PROFIT_MARGIN.ERROR.INVALID_VALUE';
        }
        return validationErrors;
    }

    private validationErrorsPresent() {
        return Object.keys(this.validationErrors).filter(key => this.validationErrors[key] != undefined).length > 0;
    }

    reloadProfitMargins(clientGroupId: number): void {
        if (clientGroupId) {
            this.clientGroupService.getSimpleProfitMarginSource(clientGroupId).subscribe(groupSimpleProfitMarginSource => {
                this.setGroupSimpleProfitMarginSource(groupSimpleProfitMarginSource);
                this.changeDetector.markForCheck();
            });
        }
    }

    getDialogHeader(): string {
        if (this.client.id != undefined) {
            return 'NAVIGATION.ITEMS.CLIENTS.EDIT-CLIENT';
        }
        return 'NAVIGATION.ITEMS.CLIENTS.ADD-CLIENT';
    }

    getDialogStyle(): string {
        if (!this.hideProfitMargins) {
            return 'width-100percent-no-padding height-60vw-no-padding';
        }
        return '';
    }

    setSaveInProgress(saveInProgress: boolean): void {
        if (this.saveInProgress !== saveInProgress) {
            this.saveInProgress = saveInProgress;
            this.changeDetector.markForCheck();
        }
    }

    private setGroupSimpleProfitMarginSource(groupSimpleProfitMarginSource: SimpleProfitMarginSource): void {
        this.groupSimpleProfitMarginSource = groupSimpleProfitMarginSource;
        if (groupSimpleProfitMarginSource != undefined) {
            if (this.simpleProfitMarginSource == undefined) {
                this.simpleProfitMarginSource = new SimpleProfitMarginSource();
            }
        } else {
            this.simpleProfitMarginSource = undefined;
            Object.keys(this.validationErrors)
                .filter(key => key.startsWith('simpleProfitMarginSource'))
                .forEach(key => this.validationErrors[key] = undefined);
        }
    }
}
