import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {GateControl} from "../../../../gate-painter/enums/gate-control.enum";
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {GateDependentOptionField, GateDependentOptionTab} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {GateEditorField} from '../../offer/gate-editor/gate-editor-field';
import {GateEditorFieldContentProvider} from '../../offer/gate-editor/gate-editor-field-content-provider';
import {AddonCategoryGroupService} from '../addon-category-group/addon-category-group.service';
import {AddonCategoryService} from '../addon-category/addon-category.service';
import {GateDependentOptionFieldUsage} from "../catalog-field-usage";
import {ColorService} from '../color/color.service';
import {GatePanelTypeService} from '../gate-panel-type/gate-panel-type.service';
import {GateSystem} from '../gate-system/gate-system';
import {GateSystemService} from '../gate-system/gate-system.service';
import {GateWallService} from "../gate-wall/gate-wall.service";
import {RailSystemService} from '../rail-system/rail-system.service';
import {GateDesignerCatalogDependentOption} from './data-form/gate-designer-catalog-dependent-option';
import {
    GateDesignerCatalogDependentOptionFormDependencyComponent
} from './data-form/gate-designer-catalog-dependent-option-form-dependency.component';
import {GateDesignerCatalogDependentOptionService} from './data-form/gate-designer-catalog-dependent-option.service';
import {GateDesignerCatalogDependentOptionsSet} from './gate-designer-catalog-dependent-options-set';
import {GateDesignerCatalogDependentOptionsSetService} from './gate-designer-catalog-dependent-options-set.service';
import {CatalogSection} from "../addons/CatalogSection";

@Component({
    selector: 'app-gate-designer-catalog-dependent-options-set',
    templateUrl: './gate-designer-catalog-dependent-options-set.component.html',
    providers: [GateDesignerCatalogDependentOptionsSetService, GateDesignerCatalogDependentOptionService, TranslatedSelectItemService,
        GateEditorFieldContentProvider, GateSystemService, RailSystemService, ColorService, GatePanelTypeService, AddonCategoryGroupService,
        AddonCategoryService, GateWallService]
})
export class GateDesignerCatalogDependentOptionsSetComponent
    extends CrudCommonComponent<GateDesignerCatalogDependentOptionsSet, GateDesignerCatalogDependentOptionsSetService>
    implements OnInit {

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState: boolean;

    private readonly gateDesignerCatalogDependentOptionService: GateDesignerCatalogDependentOptionService;
    private readonly gateSystemService: GateSystemService;
    private readonly gateEditorFieldContentProvider: GateEditorFieldContentProvider;
    private readonly addonCategoryService: AddonCategoryService;
    readonly allGateEditorRequiredField: Subject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
    readonly allGateEditorDependentField: Subject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
    inputGateEditorRequiredField: Observable<SelectItem[]>;
    inputGateEditorDependentField: Observable<SelectItem[]>;
    requiredInputSelectedMap: Map<string, string[]>;
    dependentInputSelectedMap: Map<string, string[]>;

    gateSystems: Observable<GateSystem[]>;
    optionsSet: GateDesignerCatalogDependentOption[] = [];

    @ViewChild('table')
    table: Table;

    readonly STEPS = {
        DATA: 'DATA',
        REQUIRED: 'REQUIRED',
        DEPENDENT: 'DEPENDENT'
    };

    readonly VALIDATORS = {
        DATA: () => this.validateData(),
        REQUIRED: () => this.validateRequired(),
        DEPENDENT: () => this.validateDependent()
    };

    editPermits: FieldLimitation[] = [];
    fieldUsage: GateDependentOptionFieldUsage;
    GateDependentOptionField = GateDependentOptionField;
    GateDependentOptionTab = GateDependentOptionTab;
    nextStepsFilled: boolean;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, false, GateDesignerCatalogDependentOptionsSetService, 'GATE_DESIGNER_CATALOG_DEPENDENT_OPTION',
            'GateDesignerCatalogDependentOption');
        this.gateDesignerCatalogDependentOptionService = injector.get(GateDesignerCatalogDependentOptionService);
        this.gateSystemService = injector.get(GateSystemService);
        this.gateEditorFieldContentProvider = injector.get(GateEditorFieldContentProvider);
        this.addonCategoryService = injector.get(AddonCategoryService);
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
        this.inputGateEditorRequiredField = this.allGateEditorRequiredField.pipe(
            map(selectItems => selectItems.filter(selectItem => selectItem.label != undefined && selectItem.label.length > 0))
        );
        this.inputGateEditorDependentField = this.allGateEditorDependentField.pipe(
            map(selectItems => selectItems.filter(selectItem => selectItem.label != undefined && selectItem.label.length > 0))
        );
        this.fieldUsage = new GateDependentOptionFieldUsage(this);
    }

    ngOnInit(): void {
        super.ngOnInit();

        const requiredFieldKeys = this.getAllowedGateEditorRequiredFields().map(field => 'GATE_DESIGNER_FIELD.' + field);
        const dependentFieldKeys = this.getAllowedGateEditorDependentFields().map(field => 'GATE_DESIGNER_FIELD.' + field);

        let filters = {};
        filters['target'] = {};
        filters['target'].value = CatalogSection.GATE;
        forkJoin({
            addonCategories: this.addonCategoryService.getItems(undefined, undefined, filters, 'sortIndex', 1),
            translations: this.translate.get([].concat(requiredFieldKeys, dependentFieldKeys))
        }).subscribe(data => {
            const requiredSelectItems = this.getAllowedGateEditorRequiredFields().map(field => ({
                label: data.translations['GATE_DESIGNER_FIELD.' + field],
                value: field
            }));
            const dependentSelectItems = this.getAllowedGateEditorDependentFields().map(field => ({
                label: data.translations['GATE_DESIGNER_FIELD.' + field],
                value: field
            }));
            const categorySelectItems = data.addonCategories.data.map(category => ({
                label: category.name[this.translate.currentLang],
                value: category.symbol
            }));
            const sortFunction = (a: SelectItem, b: SelectItem) => {
                if (a.label == undefined) {
                    return b.label != undefined ? 1 : -1;
                }
                if (b.label == undefined) {
                    return -1;
                }
                return a.label.localeCompare(b.label);
            };

            this.gateEditorFieldContentProvider.initializeProvider(categorySelectItems.map(selectItem => selectItem.value));
            this.allGateEditorRequiredField.next([].concat(requiredSelectItems, categorySelectItems).sort(sortFunction));
            this.allGateEditorDependentField.next([].concat(dependentSelectItems, categorySelectItems).sort(sortFunction));
        });

        this.gateSystems = this.gateSystemService.getItems(0, 0, {active: {value: 'true'}}, null, null).pipe(
            map(listing => listing.data),
            shareReplay(1));

        this.translatedSelectItemService.buildUnsortedDropdown([GateControl.MANUAL, GateControl.DRIVE], 'GATE_CONTROL.', undefined)
            .subscribe(selectItems => this.gateEditorFieldContentProvider.setItems(GateEditorField.CONTROL, selectItems));
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GATE_DEPENDENT_OPTIONS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }

    getNewItem(): GateDesignerCatalogDependentOptionsSet {
        this.requiredInputSelectedMap = new Map<string, string[]>();
        this.dependentInputSelectedMap = new Map<string, string[]>();
        this.optionsSet = [];
        return new GateDesignerCatalogDependentOptionsSet();
    }

    loadEditedItem(event: { data: GateDesignerCatalogDependentOptionsSet }): Observable<GateDesignerCatalogDependentOptionsSet> {
        return this.itemService.getItem(event.data.id).pipe(
            mergeMap(item => forkJoin({
                initGateSystems: this.gateSystems,
                ...this.gateEditorFieldContentProvider.getCatalogDataSource(item.gateSystemId, undefined)
            }).pipe(map(catalogData => {
                this.gateEditorFieldContentProvider.initCatalog(catalogData);
                return item;
            })))
        );
    }

    doOnRowSelect(event) {
        this.requiredInputSelectedMap = new Map<string, string[]>();
        this.dependentInputSelectedMap = new Map<string, string[]>();
        this.gateDesignerCatalogDependentOptionService.getItemsFromSet(event.data.id).subscribe(
            options => this.optionsSet = options
        );
        super.doOnRowSelect(event);
    }

    prepareItemForRequest() {
        let options = [];
        for (const requiredMapKey of this.requiredInputSelectedMap.keys()) {
            let {inputId: requiredInputId} = GateDesignerCatalogDependentOptionFormDependencyComponent.decodeMapKey(requiredMapKey);
            let requiredValues = this.requiredInputSelectedMap.get(requiredMapKey);

            for (const requiredValue of requiredValues) {
                for (const dependentMapKey of this.dependentInputSelectedMap.keys()) {
                    let {
                        inputId: dependentInputId,
                        whenMatched
                    } = GateDesignerCatalogDependentOptionFormDependencyComponent.decodeMapKey(dependentMapKey);
                    let dependentValues = this.dependentInputSelectedMap.get(dependentMapKey);
                    for (const dependentValue of dependentValues) {
                        let option = new GateDesignerCatalogDependentOption();
                        option.requiredInputId = requiredInputId;
                        option.requiredInputValue = requiredValue;
                        option.dependentInputId = dependentInputId;
                        option.dependentInputValue = dependentValue;
                        option.whenMatched = whenMatched;
                        options.push(option);
                    }
                }
            }
        }

        this.item.options = options;
        return this.item;
    }

    deleteItem(item: GateDesignerCatalogDependentOptionsSet): void {
        this.itemService.deleteItem(item.id).subscribe(
            () => {
                this.reloadDatatable();
                this.growlMessageController.info(`${this.translationKey}.${this.translationKey}_DELETED`);
            }
        );
    }

    validateData(): Observable<boolean> {
        return this.itemService.validateGeneralData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }

    validateRequired(): Observable<boolean> {
        const validationErrors = {};

        if (this.requiredInputSelectedMap == null || this.requiredInputSelectedMap.size === 0) {
            validationErrors[`required`] = `error.gateDesignerCatalogDependentOptionsSetDto.required.not_empty`;
        } else if (Array.from(this.requiredInputSelectedMap.values()).some(val => val == null || val.length === 0)) {
            validationErrors[`required`] = `error.gateDesignerCatalogDependentOptionsSetDto.required.value.not_empty`;
        }

        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return of(true);
    }

    validateDependent(): Observable<boolean> {
        const validationErrors = {};

        if (this.dependentInputSelectedMap == null || this.dependentInputSelectedMap.size === 0) {
            validationErrors[`dependent`] = `error.gateDesignerCatalogDependentOptionsSetDto.dependent.not_empty`;
        } else if (Array.from(this.dependentInputSelectedMap.values()).some(val => val == null || val.length === 0)) {
            validationErrors[`dependent`] = `error.gateDesignerCatalogDependentOptionsSetDto.dependent.value.not_empty`;
        }

        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }

        this.prepareItemForRequest();
        return this.itemService.validateDependentOptions(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
    }

    private getAllowedGateEditorRequiredFields(): GateEditorField[] {
        return [
            GateEditorField.RAIL_SYSTEM,
            GateEditorField.CORE_COLOR,
            GateEditorField.EXTERNAL_COLOR,
            GateEditorField.INTERNAL_COLOR,
            GateEditorField.GATE_PANEL_TYPE,
            GateEditorField.CONTROL,
            GateEditorField.WIDTH_GREATER_THAN,
            GateEditorField.WIDTH_LESS_THAN,
            GateEditorField.HEIGHT_GREATER_THAN,
            GateEditorField.HEIGHT_LESS_THAN,
            GateEditorField.AREA_GREATER_THAN,
            GateEditorField.AREA_LESS_THAN,
            GateEditorField.LINTEL_HEIGHT_GREATER_THAN,
            GateEditorField.LINTEL_HEIGHT_LESS_THAN
        ];
    }

    private getAllowedGateEditorDependentFields(): GateEditorField[] {
        return [
            GateEditorField.RAIL_SYSTEM,
            GateEditorField.GATE_WALL,
            GateEditorField.CORE_COLOR,
            GateEditorField.EXTERNAL_COLOR,
            GateEditorField.INTERNAL_COLOR,
            GateEditorField.GATE_PANEL_TYPE,
            GateEditorField.CONTROL
        ];
    }
}
