import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {ArchivedOfferVersionsDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-offer-archived-versions-dialog',
    templateUrl: './offer-archived-versions-dialog.component.html',
    styleUrls: ['./offer-archived-versions-dialog.component.css', '../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferArchivedVersionsDialogComponent {

    @Input() dialogData: ArchivedOfferVersionsDialogData;
    @Input() canRestore: boolean;
    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() printArchivedVersion: EventEmitter<number> = new EventEmitter<number>();
    @Output() restoreArchivedVersion: EventEmitter<number> = new EventEmitter<number>();

    private readonly dialogHideHelper = new OnceFlag();

    close() {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    restore(id: number) {
        if (this.canRestore) {
            this.restoreArchivedVersion.emit(id);
        }
    }

    print(id: number) {
        this.printArchivedVersion.emit(id);
    }
}
