import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {Dialog} from 'primeng/dialog';
import {OnceFlag} from '../../shared/once-flag';

@Component({
    selector: 'app-news-dialog',
    templateUrl: './news-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsDialogComponent {

    @Input()
    newsTitle: string;

    @Input()
    newsContent: string;

    @Input()
    dialogId: string;

    @Output()
    onConfirmed = new EventEmitter<void>();

    @Output()
    onRemindNewsLater = new EventEmitter<void>();

    @ViewChild('dialog', {static: true}) dialog: Dialog;

    visible = true;

    private readonly dialogHideHelper = new OnceFlag();

    remindLater() {
        this.dialogHideHelper.call(() => this.onRemindNewsLater.emit());
    }

    confirm() {
        this.dialogHideHelper.call(() => this.onConfirmed.emit());
    }

    centerDialog() {
        setTimeout(() => this.dialog.center(), 1);
    }
}
