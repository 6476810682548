import {AddonCategoryEnum} from "../../../../../../window-designer/enums/AddonCategoryEnum";
import {CatalogItemType} from "../../../window-editor/sidebar/pricing/PricingItem";

export class DealerDiscountValue {
    value: string;
    cascade: boolean;
}

export class DealerDiscounts {
    value = new DealerDiscountValue();
    subsystemSellValue = new DealerDiscountValue();
    subsystemGroupSellValue = new DealerDiscountValue();
}

export class ProfitMargins {
    subsystemProfitMargin: number;
    subsystemGroupProfitMargin: number;

    clientProfitMargin: number;
    clientGroupProfitMargin: number;

    sellerClientProfitMargin: number;
    sellerClientGroupProfitMargin: number;
}

export class PricingComponentOverrides {
    readonly windowSystemDealerDiscounts = new Map<number, DealerDiscounts>();
    readonly gateSystemDealerDiscounts = new Map<number, DealerDiscounts>();
    readonly configSystemDealerDiscounts = new Map<number, DealerDiscounts>();
    readonly supplierDealerDiscounts = new Map<number, DealerDiscounts>();
    readonly windowSystemProfitMargins = new Map<number, ProfitMargins>();
    readonly gateSystemProfitMargins = new Map<number, ProfitMargins>();
    readonly configSystemProfitMargins = new Map<string /*configSystemId_bulk*/, ProfitMargins>();
    readonly windowAddonProfitMargins = new Map<AddonCategoryEnum, ProfitMargins>();
    bulkAddonProfitMargins: ProfitMargins;
    readonly catalogChargeSubsystemProfitMargins = new Map<CatalogItemType, number>();
}
