import {ConfigAddonOpening} from "../../../../../../window-designer/entities/ConfigAddonOpening";
import {ConfigurableAddon} from '../../../../../../window-designer/entities/ConfigurableAddon';
import {ConfigAddonApplication} from "../../../../../../window-designer/enums/ConfigAddonApplication";
import {Currencies} from '../../../../../currencies';
import {NumberInputConverter} from "../../../../../shared/converter/input-converter";
import {MultilanguageField} from "../../../../../supportedLanguages";
import {ListOfIds} from "../../../../ListOfIds";
import {AbstractPosition} from '../../../AbstractPosition';
import {ComplaintPosition} from "../../../complaint/complaint-position";
import {OfferVersion} from "../../../OfferVersion";
import {GlobalConfigAddonAddResult} from './bulk-change-confirmation/globalConfigAddonAddResult';
import {BulkChange} from "./BulkWindowSystemChange";
import {ConfigurableAddonDefinition} from './ConfigurableAddonModel/ConfigurableAddonDefinition';
import {ConjunctionDialogDto} from "./conjunctions/conjunction-dialog-dto";
import {FinalizeBulkChange} from './FinalizeBulkChange';
import {Position} from "./position";
import {RotData} from "./rot-data-dialog/RotData";
import {SubsystemCurrencyData} from "../../../../subsystem/subsystem";

export enum DialogType {
    EDIT,
    PRICE_CHANGE,
    ADD_BULK_ADDON,
    EDIT_BULK_ADDON,
    ADD_CONFIGURABLE_ADDON, // Add in window editor
    EDIT_CONFIGURABLE_ADDON,
    ADD_OTHER_SYSTEM_ADDON,
        GLOBAL_EDIT_OTHER_SYSTEM_ADDON,
    GLOBAL_ADD_CONFIGURABLE_ADDON, // global add
        GLOBAL_EDIT_CONFIGURABLE_ADDON,
    GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION,
    GLOBAL_CONFIG_ADDONS_EDIT_CONFIRMATION,
    POSITION_MESSAGES,
    OFFER_MESSAGES,
    COMMENTS,
    REMOVE_POSITION,
    SHOW_SUMMARY,
    CHANGE_ANNOTATIONS,
    RABATE_MARKUP,
    SHOW_DESCRIPTION,
    BULK_WINDOW_SYSTEM_CHANGE,
    BULK_CHANGE_CONFIRMATION,
    BULK_GLASS_CHANGE,
    BULK_COLOR_CHANGE,
    BULK_GENERAL_CHANGE,
    BULK_FITTINGS_CHANGE,
    BULK_PREDEF_GLAZING_PACKAGE_CHANGE,
    UPDATE_EXCHANGE_RATE,
    UPDATE_PRICINGS,
    ADD_ASSEMBLY,
    EDIT_ASSEMBLY,
    ADD_OWN_BULK_ADDON,
    ADD_TRANSPORT,
    EDIT_TRANSPORT,
    UPDATE_VAT,
    EDIT_COMPLAINT_POSITION,
    REMOVE_COMPLAINT_POSITION,
    COMPLAINT_MOVIE_INSTRUCTIONS,
    COMPLAINT_PHOTO_INSTRUCTIONS,
    CREATE_COMPLAINT_CONFIRMATION,
    COMPARE_POSITIONS,
    OFFER_ARCHIVED_VERSIONS,
    CORRECTION_ORDER_GENERATION,
    CHANGE_SYSTEM_DEALER_DISCOUNT,
    CHANGE_DEALER_DISCOUNT,
    UPDATE_VALIDITY_DATES,
    SET_PRINT_ORDER,
    ROT_DATA,
    OFFER_CHARGE,
    CONJUNCTIONS,
    DISABLE_VALIDATION,
    NEW_CONFIG_ADDON, // new independent config
    EDIT_CONFIG_ADDON, // edit on position list and in window editor
    ADD_STANDALONE_GLAZING_PACKAGE,
    GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE,
    GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE_INFO,
    CONFIRM_PARTIAL_SIMULATION,
}

export class PriceChangeDialogData {
    type: "PRICE_CHANGE";
    @NumberInputConverter('price')
    price_text: string = undefined;
    price: number;
    net = true;
    buy = false;
    canChangeBuyPrice = false;
    currency = Currencies.PLN;
    exchangeRate = 1;

    constructor(public position: Position, canChangeBuyPrice: boolean, currency: Currencies, exchangeRate: number) {
        this.type = "PRICE_CHANGE";
        this.canChangeBuyPrice = canChangeBuyPrice;
        this.currency = currency;
        this.exchangeRate = exchangeRate;
    }
}

export class CommentsDialogData {
    type: "COMMENTS";

    constructor(public supplierId: number) {
    }
}

export class AddConfigurableAddonDialogData {
    type: "ADD_CONFIGURABLE_ADDON";
    application: ConfigAddonApplication;
    windowSystemId: number;
    openings: ConfigAddonOpening[];
    addonsCount: number;

    constructor(configAddonApplicationType: ConfigAddonApplication, windowSystemId = null, addonsCount = 1, openings = []) {
        this.type = "ADD_CONFIGURABLE_ADDON";
        this.application = configAddonApplicationType;
        this.windowSystemId = windowSystemId;
        this.addonsCount = addonsCount;
        this.openings = openings;
    }
}

export class EditBulkAddonDialogData {
    type: "EDIT_BULK_ADDON";
    offerPosition: AbstractPosition;

    constructor(offerPosition: AbstractPosition) {
        this.type = "EDIT_BULK_ADDON";
        this.offerPosition = offerPosition;
    }
}

export class OpenOfferChargeDataDialogData {
    type: "OFFER_CHARGE";
    offerPosition: AbstractPosition;

    constructor(offerPosition: AbstractPosition) {
        this.type = "OFFER_CHARGE";
        this.offerPosition = offerPosition;
    }
}

export class GlobalAddConfigAddonsDialogData extends AddConfigurableAddonDialogData {
    selectedPositions: Position[];

    constructor(selectedPositions: Position[], application?: ConfigAddonApplication) {
        super(application);
        this.selectedPositions = selectedPositions;
    }
}

export class BulkChangeDialogData {
    public type: string;
    modifiedPositions: Position[];

    protected constructor(dialogType: string,
                public offerId: number,
                public allOfferPositions: Position[],
                public selectedOfferPositions: Position[]) {
        this.type = dialogType;
        this.modifiedPositions = [];
    }
}

export class EditComplaintPositionDialogData {
    type: 'EDIT_COMPLAINT_POSITION';
    movieFile: File;
    photoFile: File;

    constructor(public complaintPosition: ComplaintPosition) {
    }
}

export interface BulkChangeDialogDataConstructor {
    new(offerId: number, allOfferPositions: Position[], selectedOfferPositions: Position[]): BulkChangeDialogData;
}

export class BulkWindowSystemChangeDialogData extends BulkChangeDialogData {
    constructor(offerId: number,
                allOfferPositions: Position[],
                selectedOfferPositions: Position[]) {
        super("BULK_WINDOW_SYSTEM_CHANGE", offerId, allOfferPositions, selectedOfferPositions);
    }
}

export class BulkColorChangeDialogData extends BulkChangeDialogData {
    constructor(offerId: number,
                allOfferPositions: Position[],
                selectedOfferPositions: Position[]) {
        super("BULK_COLOR_CHANGE", offerId, allOfferPositions, selectedOfferPositions);
    }
}

export class BulkGeneralChangeDialogData extends BulkChangeDialogData {
    constructor(offerId: number,
                allOfferPositions: Position[],
                selectedOfferPositions: Position[]) {
        super("BULK_GENERAL_CHANGE", offerId, allOfferPositions, selectedOfferPositions);
    }
}

export class BulkFittingsChangeDialogData extends BulkChangeDialogData {
    constructor(offerId: number,
                allOfferPositions: Position[],
                selectedOfferPositions: Position[]) {
        super("BULK_FITTINGS_CHANGE", offerId, allOfferPositions, selectedOfferPositions);
    }
}

export class BulkGlassChangeDialogData extends BulkChangeDialogData {
    constructor(offerId: number,
                allOfferPositions: Position[],
                selectedOfferPositions: Position[]) {
        super("BULK_GLASS_CHANGE", offerId, allOfferPositions, selectedOfferPositions);
    }
}

export class BulkChangeConfirmationDialogData {
    type: "BULK_CHANGE_CONFIRMATION";
    accepted = false;
    result: FinalizeBulkChange;

    constructor(public changeResult: BulkChange,
                public modifiedPositions: Position[],
                public changeToConfirm: DialogType) {
        this.type = "BULK_CHANGE_CONFIRMATION";
    }
}

export class GlobalConfigAddonsAddConfirmationDialogData {
    type: "GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION";

    constructor(public modifiedPositions: Position[],
                public configsToAdd: GlobalConfigAddonAddResult[],
                public addon: ConfigurableAddon,
                public configurableAddonName: MultilanguageField,
                public isUpselling = false) {
        this.type = "GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION";
    }
}

export class RabateMarkupDialogData {
    type: "RABATE_MARKUP";
    positionList: Position[];
    offerId: number;
    rabate: number;
    markup: number;
    isMarkup: boolean;

    constructor(offerId: number, positionList: Position[], isMarkup = false, rabate?: number) {
        this.positionList = positionList;
        this.offerId = offerId;
        this.rabate = rabate;
        this.isMarkup = isMarkup;
    }
}

export class RotDataDialogData {
    type: "ROT_DATA";
    offerId: number;
    canEdit: boolean;
    rotData: RotData;

    constructor(offerId: number, canEdit: boolean, rotData: RotData) {
        this.offerId = offerId;
        this.canEdit = canEdit;
        this.rotData = rotData;
    }
}

export class UpdateExchangeRateDialogData {
    type: "UPDATE_EXCHANGE_RATE";

    constructor(public offerId: number, public offerCurrency: Currencies, public exchangeRatePercentDiff?: number,
            public exchangeRateWarningStatus?: 'HIGH' | 'LOW', public subsystemCurrencyData?: SubsystemCurrencyData) {
        this.type = "UPDATE_EXCHANGE_RATE";
        this.offerCurrency = offerCurrency;
        this.exchangeRatePercentDiff = exchangeRatePercentDiff;
        this.exchangeRateWarningStatus = exchangeRateWarningStatus;
        this.subsystemCurrencyData = subsystemCurrencyData;
    }
}

export class UpdatePricingsDialogData {
    type: 'UPDATE_PRICINGS';
    selectedPositionsIds: ListOfIds;
    offerId: number;
    forceAll: boolean;

    constructor(positionList: ListOfIds, offerId: number, forceAll: boolean) {
        this.type = 'UPDATE_PRICINGS';
        this.selectedPositionsIds = positionList;
        this.offerId = offerId;
        this.forceAll = forceAll;
    }
}

export class EditAssemblyOrTransportDialogData {
    constructor(public editedPosition: Position, public type: 'EDIT_ASSEMBLY' | 'EDIT_TRANSPORT') {
    }
}

export class UpdateVatDialogData {
    type: 'UPDATE_VAT';
    vatSell: number;
    offerId: number;

    constructor(offerId: number) {
        this.type = 'UPDATE_VAT';
        this.offerId = offerId;
    }
}

export class ArchivedOfferVersionsDialogData {
    type: 'OFFER_ARCHIVED_VERSIONS';
    versions: OfferVersion[] = [];

    constructor(versions: OfferVersion[]) {
        this.type = 'OFFER_ARCHIVED_VERSIONS';
        this.versions = versions;
    }
}

export class UpdateValidityDatesDialogData {
    offerId: number;
    type: 'UPDATE_VALIDITY_DATES';

    constructor(offerId: number) {
        this.offerId = offerId;
        this.type = 'UPDATE_VALIDITY_DATES';
    }
}

export class SetPrintOrderDialogData {
    type: 'SET_PRINT_ORDER';

    constructor(public position: Position) {
        this.type = 'SET_PRINT_ORDER';
    }
}

export class ConjunctionsDialogData {
    type: 'CONJUNCTIONS';
    conjunctions: ConjunctionDialogDto[] = [];

    constructor(conjunctions: ConjunctionDialogDto[]) {
        this.type = 'CONJUNCTIONS';
        this.conjunctions = conjunctions;
    }
}

export class DisableValidationDialogData {
    type: 'DISABLE_VALIDATION';

    constructor(public offerPosition: Position) {
        this.type = 'DISABLE_VALIDATION';
    }
}

export class AddStandaloneGlazingPackagesGloballyDialogData {
    readonly type: 'GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE';

    constructor(public positions: Position[]) {
        this.type = 'GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE';
    }
}

export class BulkPredefGlazingPackageChangeDialogData extends BulkChangeDialogData {
    constructor(offerId: number,
                allOfferPositions: Position[],
                selectedOfferPositions: Position[]) {
        super("BULK_PREDEF_GLAZING_PACKAGE_CHANGE", offerId, allOfferPositions, selectedOfferPositions);
    }
}

export type DialogData = PriceChangeDialogData
    | CommentsDialogData | AddConfigurableAddonDialogData | EditBulkAddonDialogData | GlobalAddConfigAddonsDialogData
    | BulkWindowSystemChangeDialogData | BulkColorChangeDialogData | BulkGlassChangeDialogData | BulkChangeConfirmationDialogData
    | BulkChangeDialogData | BulkGeneralChangeDialogData | RabateMarkupDialogData | UpdateExchangeRateDialogData
    | UpdatePricingsDialogData | EditAssemblyOrTransportDialogData | UpdateVatDialogData | GlobalConfigAddonsAddConfirmationDialogData
    | EditComplaintPositionDialogData | ArchivedOfferVersionsDialogData
    | UpdateValidityDatesDialogData | SetPrintOrderDialogData | RotDataDialogData | OpenOfferChargeDataDialogData | ConjunctionsDialogData
    | DisableValidationDialogData | AddStandaloneGlazingPackagesGloballyDialogData;

// helper class to do type casting in html templates
export class DialogDataSelector {

    getData(data: DialogData, type: 'PRICE_CHANGE'): PriceChangeDialogData;
    getData(data: DialogData, type: 'COMMENTS'): CommentsDialogData;
    getData(data: DialogData, type: 'ADD_CONFIGURABLE_ADDON'): AddConfigurableAddonDialogData;
    getData(data: DialogData, type: 'EDIT_BULK_ADDON'): EditBulkAddonDialogData;
    getData(data: DialogData, type: 'OFFER_CHARGE'): OpenOfferChargeDataDialogData;
    getData(data: DialogData, type: 'EDIT_COMPLAINT_POSITION'): EditComplaintPositionDialogData;
    getData(data: DialogData, type: 'BULK_WINDOW_SYSTEM_CHANGE'): BulkWindowSystemChangeDialogData;
    getData(data: DialogData, type: 'BULK_COLOR_CHANGE'): BulkColorChangeDialogData;
    getData(data: DialogData, type: 'BULK_GENERAL_CHANGE'): BulkGeneralChangeDialogData;
    getData(data: DialogData, type: 'BULK_FITTINGS_CHANGE'): BulkFittingsChangeDialogData;
    getData(data: DialogData, type: 'BULK_GLASS_CHANGE'): BulkGlassChangeDialogData;
    getData(data: DialogData, type: 'BULK_CHANGE_CONFIRMATION'): BulkChangeConfirmationDialogData;
    getData(data: DialogData, type: 'GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION'): GlobalConfigAddonsAddConfirmationDialogData;
    getData(data: DialogData, type: 'RABATE_MARKUP'): RabateMarkupDialogData;
    getData(data: DialogData, type: 'ROT_DATA'): RotDataDialogData;
    getData(data: DialogData, type: 'UPDATE_EXCHANGE_RATE'): UpdateExchangeRateDialogData;
    getData(data: DialogData, type: 'UPDATE_PRICINGS'): UpdatePricingsDialogData;
    getData(data: DialogData, type: 'EDIT_ASSEMBLY_OR_TRANSPORT'): EditAssemblyOrTransportDialogData;
    getData(data: DialogData, type: 'UPDATE_VAT'): UpdateVatDialogData;
    getData(data: DialogData, type: 'OFFER_ARCHIVED_VERSIONS'): ArchivedOfferVersionsDialogData;
    getData(data: DialogData, type: 'UPDATE_VALIDITY_DATES'): UpdateValidityDatesDialogData;
    getData(data: DialogData, type: 'SET_PRINT_ORDER'): SetPrintOrderDialogData;
    getData(data: DialogData, type: 'CONJUNCTIONS'): ConjunctionsDialogData;
    getData(data: DialogData, type: 'DISABLE_VALIDATION'): DisableValidationDialogData;
    getData(data: DialogData, type: 'GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE'): AddStandaloneGlazingPackagesGloballyDialogData;
    getData(data: DialogData, type: 'BULK_PREDEF_GLAZING_PACKAGE_CHANGE'): BulkPredefGlazingPackageChangeDialogData;
    getData(data: DialogData, type: string): any {
        return data;
    }
}
