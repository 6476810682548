import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {GrowlMessageController} from "./growl-message/growl-message-controller";

@Injectable()
export class OpenImageInNewBrowserTabController {

    constructor(
        private domSanitizer: DomSanitizer,
        private growls: GrowlMessageController,
    ) {}

    public openHexInNewBrowserTab(label: string, hex: string) {
        let w = window.open('', '_blank');
        if (w != null) {
            let div = w.document.createElement('div');
            div.style['background-color'] = hex;
            div.style['width'] = '800px';
            div.style['height'] = '800px';
            w.document.write(div.outerHTML);
            w.document.title = label;
        }
    }

    public openInNewBrowserTab(label: string, largeImageGetter: () => Observable<string>, backupImage?: string, backupImageSrc?: SafeUrl) {
        largeImageGetter()
            .subscribe(data => {
                    let w = window.open('', '_blank');
                    if (w != null) {
                        let src: any;
                        if (!data || data === '') {
                            if (backupImage) {
                                data = backupImage;
                            } else if (backupImageSrc) {
                                src = backupImageSrc;
                            } else {
                                return;
                            }
                        }
                        if (data) {
                            src = this.domSanitizer.bypassSecurityTrustUrl(data);
                        }
                        let image = new Image();
                        image.src = src.changingThisBreaksApplicationSecurity;
                        w.document.write(image.outerHTML);
                        w.document.title = label;
                    } else {
                        this.growls.error('GENERAL.ERROR');
                    }
                }
            );
    }
}
