import {DropDownExtraOptions} from '../../shared/drop-down-extra-options';
import {WindowSystemInterface} from '../catalog-data/window-system-interface';
import {AreaSpecification} from '../drawing-data/AreaSpecification';
import {DrawingData, DrawingDataSpecification} from '../drawing-data/drawing-data';
import {FillingType} from '../drawing-data/FillingType';
import {SubWindowData} from '../drawing-data/SubWindowData';
import {WindowAddon} from '../drawing-data/WindowAddon';
import {WindowData} from '../drawing-data/WindowData';
import {WindowSystemDefaults} from '../entities/window-system-defaults';
import {WindowCalculator} from '../window-calculator';
import {WindowCommonData} from '../window-common-data';
import {DrawingDataFixedWindowContent, DrawingDataSpecificationUtil} from './DrawingDataSpecificationUtil';
import {UpsellingChargeData} from "../entities/upselling-charge-data";
import {VentilationUtils} from "./VentilationUtils";

export class WindowSystemDefaultsUtil {

    readonly DO_NOT_CHANGE_VALUE = 0;

    public static applyDefaults(defaults: WindowSystemDefaults, data: DrawingData, windowSystem: WindowSystemInterface,
                                sidebarOnlyMode = false): void {
        data.view = defaults.view;
        data.addons = defaults.addons || [];

        WindowSystemDefaultsUtil.applySpecificationsDefaults(defaults, data.specification, data.windows, windowSystem, sidebarOnlyMode);
        data.windows.forEach(window => WindowSystemDefaultsUtil.applyWindowDefaults(defaults, window));
    }

    public static applySpecificationsDefaults(defaults: WindowSystemDefaults, specification: DrawingDataSpecification,
                                              windows: WindowData[], windowSystem: WindowSystemInterface, sidebarOnlyMode = false): void {
        // general tab ids
        specification.colorIdCore = defaults.colorIdCore;
        specification.colorIdExternal = defaults.colorIdExternal;
        specification.colorIdInternal = defaults.colorIdInternal;
        specification.frameProfileId = defaults.frameProfileId;
        specification.sealExternalId = defaults.sealColorExternalId;
        specification.sealInternalId = defaults.sealColorInternalId;
        specification.constructionalMullionId = defaults.constructionalMullionId;
        specification.movablePostId = defaults.movablePostId;

        this.initWindowAddonFields(specification);

        // general tab addons turned to ids
        if (sidebarOnlyMode || DrawingDataSpecificationUtil.clearUnnecessaryFields(specification, windows, {windowSystem: windowSystem})
            === DrawingDataFixedWindowContent.OPENABLE_WINDOWS_INCLUDED) {
            specification.opening = defaults.opening;
            specification.cover.addonId = defaults.coverId;
            specification.fittingAdditionalInsertion.addonId = defaults.fittingAdditionalInsertionId;
            specification.fittingBrake.addonId = defaults.fittingBrakeId;
            specification.fittingEspagnoletteType.addonId = defaults.fittingEspagnoletteTypeId;
            specification.fittingLock.addonId = defaults.fittingLockId;
            specification.fittingLockTerrace.addonId = defaults.fittingLockTerraceId;
            specification.fittingLockTerraceLocation = defaults.fittingLockTerraceLocation;
            specification.fittingInsertion.addonId = defaults.fittingInsertionId;
            specification.fittingMainInsertion.addonId = defaults.fittingMainInsertionId;
            specification.fittingSliding.addonId = defaults.fittingSlidingId;
            specification.fittingType.addonId = defaults.fittingTypeId;
            specification.fittingAutomaticDrive.addonId = defaults.fittingAutomaticDriveId;
            specification.fittingVeranda.addonId = defaults.fittingVerandaId;
            specification.handleType.addonId = defaults.handleTypeId;
            specification.doorstepId = defaults.doorstepId;
            specification.terraceHandle.addonId = defaults.terraceHandleId;
            specification.terraceHandleLayout = defaults.terraceHandleLayout;
            specification.channelSectionId = defaults.channelSectionId;
        }

        if (!WindowCalculator.hasSubwindowsAllowingHandles(windows) && !sidebarOnlyMode) {
            specification.handleType.addonId = undefined;
        }

        specification.frameEnhancement.addonId = defaults.frameEnhancementId;
        specification.milling.addonId = defaults.millingId;
        specification.millingNorwegian.addonId = defaults.millingNorwegianId;
        specification.underWindowBead.addonId = defaults.underWindowBeadId;
        specification.underwindowProfile.addonId = defaults.underwindowProfileId;
        specification.weldType.addonId = defaults.weldTypeId;

        specification.windowFunction = defaults.windowFunction;
    }

    public static applyWindowDefaults(modificationData: WindowSystemDefaults, window: WindowData, upsellingMode = false): void {
        window.subWindows.forEach(subwindow => WindowSystemDefaultsUtil.applySubwindowDefaults(modificationData, subwindow, upsellingMode));
    }

    public static applySubwindowDefaults(modificationData: WindowSystemDefaults, subwindow: SubWindowData,
        upsellingMode: boolean): void {
        if (!upsellingMode) {
            subwindow.areasSpecification.forEach(area => WindowSystemDefaultsUtil.applyAreaDefaults(modificationData, area));
        }
    }

    public static applyAreaDefaults(defaults: WindowSystemDefaults, area: AreaSpecification): void {
        area.glazingBead.id = defaults.glazingBeadId;
        area.filling.type = defaults.fillingType;
        area.filling.externalColorId = undefined;
        area.filling.internalColorId = undefined;
        area.filling.decorativeFillingId = undefined;
        area.filling.fillingId = undefined;
        area.filling.width = undefined;
        area.glazing.glazingGlassQuantity = undefined;
        area.glazing.glass1id = undefined;
        area.glazing.glass2id = undefined;
        area.glazing.glass3id = undefined;
        area.glazing.glass4id = undefined;
        area.glazing.frame1id = undefined;
        area.glazing.frame2id = undefined;
        area.glazing.frame3id = undefined;
        area.glazingPackageId = undefined;
        area.glazingCategoryId = undefined;
        area.glazingFrameCategoryId = undefined;
        switch (defaults.fillingType) {
            case FillingType.GLASS:
                this.applyGlazingDefaults(defaults, area);
                break;
            case FillingType.FILLING:
                area.filling.fillingId = defaults.fillingId;
                area.filling.externalColorId = defaults.externalColorId;
                area.filling.internalColorId = defaults.internalColorId;
                break;
            case FillingType.DECORATIVE_FILLING:
                area.filling.decorativeFillingId = defaults.decorativeFillingId;
                area.filling.externalColorId = defaults.externalColorId;
                area.filling.internalColorId = defaults.internalColorId;
                this.applyGlazingDefaults(defaults, area);
                break;
            case FillingType.NO_FILLING:
                area.filling.width = defaults.fillingWidth;
                break;
        }
    }

    public static applyGlazingDefaults(defaults: WindowSystemDefaults, area: AreaSpecification): void {
        if (defaults.glazingGlassQn != null) {
            area.glazing.glazingGlassQuantity = defaults.glazingGlassQn;
            area.glazing.glass1id = defaults.glass1id;
            area.glazing.glass2id = defaults.glass2id;
            area.glazing.glass3id = defaults.glass3id;
            area.glazing.glass4id = defaults.glass4id;
            area.glazing.frame1id = defaults.frame1id;
            area.glazing.frame2id = defaults.frame2id;
            area.glazing.frame3id = defaults.frame3id;
            area.glazingPackageId = defaults.glazingPackageId;
            area.glazingCategoryId = defaults.glazingCategoryId;
            area.glazingFrameCategoryId = defaults.glazingFrameCategoryId;
        }
    }

    public static applyWebshopChargeDefaults(data: DrawingData, defineColors = false): void {
        this.initWindowAddonFields(data.specification);
        data.specification.frameProfileId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.sealExternalId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.sealInternalId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.opening = DropDownExtraOptions.DO_NOT_CHANGE;
        data.specification.cover.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingAdditionalInsertion.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingBrake.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingEspagnoletteType.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingLock.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingLockTerrace.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingLockTerraceLocation = DropDownExtraOptions.DO_NOT_CHANGE;
        data.specification.fittingInsertion.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingMainInsertion.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingSliding.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingType.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingAutomaticDrive.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.fittingVeranda.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.handleType.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.terraceHandle.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.terraceHandleLayout = DropDownExtraOptions.DO_NOT_CHANGE;
        data.specification.doorstepId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.frameEnhancement.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.milling.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.millingNorwegian.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.underWindowBead.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.underwindowProfile.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.weldType.addonId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.windowFunction = DropDownExtraOptions.DO_NOT_CHANGE;
        data.specification.channelSectionId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.constructionalMullionId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        data.specification.movablePostId = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        if (defineColors) {
            data.specification.colorIdCore = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
            data.specification.colorIdExternal = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
            data.specification.colorIdInternal = DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE;
        }
    }

    public static prepareWindowSystemDefaults(data: DrawingData, commonData: WindowCommonData): WindowSystemDefaults {
        let defaults = new WindowSystemDefaults();
        // general tab ids
        defaults.colorIdCore = data.specification.colorIdCore;
        defaults.colorIdExternal = data.specification.colorIdExternal;
        defaults.colorIdInternal = data.specification.colorIdInternal;
        defaults.doorstepId = data.specification.doorstepId;
        defaults.frameProfileId = data.specification.frameProfileId;
        defaults.sealColorExternalId = data.specification.sealExternalId;
        defaults.sealColorInternalId = data.specification.sealInternalId;

        // general tab addons turned to ids
        defaults.coverId = data.specification.cover.addonId;
        defaults.frameEnhancementId = data.specification.frameEnhancement.addonId;
        defaults.fittingBrakeId = data.specification.fittingBrake.addonId;
        defaults.fittingSlidingId = data.specification.fittingSliding.addonId;
        defaults.fittingTypeId = data.specification.fittingType.addonId;
        defaults.fittingEspagnoletteTypeId = data.specification.fittingEspagnoletteType.addonId;
        defaults.fittingVerandaId = data.specification.fittingVeranda.addonId;
        defaults.fittingInsertionId = data.specification.fittingInsertion.addonId;
        defaults.fittingMainInsertionId = data.specification.fittingMainInsertion.addonId;
        defaults.fittingAdditionalInsertionId = data.specification.fittingAdditionalInsertion.addonId;
        defaults.fittingLockId = data.specification.fittingLock.addonId;
        defaults.fittingLockTerraceId = data.specification.fittingLockTerrace.addonId;
        defaults.fittingLockTerraceLocation = data.specification.fittingLockTerraceLocation;
        defaults.fittingAutomaticDriveId = data.specification.fittingAutomaticDrive.addonId;
        defaults.handleTypeId = data.specification.handleType.addonId;
        defaults.millingId = data.specification.milling.addonId;
        defaults.millingNorwegianId = data.specification.millingNorwegian.addonId;
        defaults.underWindowBeadId = data.specification.underWindowBead.addonId;
        defaults.underwindowProfileId = data.specification.underwindowProfile.addonId;
        defaults.weldTypeId = data.specification.weldType.addonId;
        defaults.terraceHandleId = data.specification.terraceHandle.addonId;
        defaults.terraceHandleLayout = data.specification.terraceHandleLayout;
        defaults.channelSectionId = data.specification.channelSectionId;
        defaults.constructionalMullionId = data.specification.constructionalMullionId;
        defaults.movablePostId = data.specification.movablePostId;

        defaults.windowFunction = data.specification.windowFunction;
        defaults.opening = data.specification.opening;

        // areas
        defaults.glazingBeadId = commonData.glazingBeadId;
        defaults.fillingType = commonData.fillingType;
        defaults.coreColorId = commonData.coreColorId;
        defaults.externalColorId = commonData.externalColorId;
        defaults.internalColorId = commonData.internalColorId;
        defaults.decorativeFillingId = commonData.decorativeFillingId;
        defaults.fillingId = commonData.fillingId;
        defaults.fillingWidth = commonData.fillingWidth;
        defaults.glazingGlassQn = commonData.glazingGlassQuantity;
        defaults.glass1id = commonData.glass1id;
        defaults.glass2id = commonData.glass2id;
        defaults.glass3id = commonData.glass3id;
        defaults.glass4id = commonData.glass4id;
        defaults.frame1id = commonData.frame1id;
        defaults.frame2id = commonData.frame2id;
        defaults.frame3id = commonData.frame3id;

        defaults.view = data.view;

        defaults.addons = data.addons.map(addon => {
            let windowAddon = new WindowAddon(addon.addonId, addon.quantityType);
            windowAddon.defaultAddon = true;

            return windowAddon;
        });

        defaults.terraceGlazingPackageId = data.specification.terraceGlazingPackageId;
        return defaults;
    }

    public static prepareWindowSystemDefaultsPredefinedGlazingFields(commonData: WindowCommonData,
        defaults: WindowSystemDefaults): void {
        defaults.glazingPackageId = commonData.glazingPackageId;
        defaults.glazingCategoryId = commonData.glazingCategoryId;
        defaults.glazingFrameCategoryId = commonData.glazingFrameCategoryId;
    }

    public static prepareUpsellingVentilation(upsellingDummySubwindow: SubWindowData, chargeData: UpsellingChargeData) {
        chargeData.ventilator = upsellingDummySubwindow.ventilator;
        chargeData.drip = upsellingDummySubwindow.drip;
        chargeData.coupler = upsellingDummySubwindow.coupler;
    }

    public static applyUpsellingChargeToSubwindow(subwindow: SubWindowData, chargeData: UpsellingChargeData) {
        if (chargeData !== undefined) {
            if (chargeData.ventilator) {
                subwindow.ventilator = chargeData.ventilator;
            }
            if (chargeData.drip) {
                subwindow.drip = chargeData.drip;
            }
            if (chargeData.coupler && VentilationUtils.canHaveCoupler(subwindow)) {
                subwindow.coupler = chargeData.coupler;
            }
        }
    }

    public static applyDefaultsToCommonData(defaults: WindowSystemDefaults, commonData: WindowCommonData, modelMode: boolean): void {
        commonData.glazingBeadId = defaults.glazingBeadId;
        if (modelMode && defaults.fillingType == undefined) {
            commonData.fillingType = FillingType.GLASS;
        } else {
            commonData.fillingType = defaults.fillingType;
        }
        commonData.coreColorId = defaults.coreColorId;
        commonData.externalColorId = defaults.externalColorId;
        commonData.internalColorId = defaults.internalColorId;
        commonData.decorativeFillingId = defaults.decorativeFillingId;
        commonData.fillingId = defaults.fillingId;
        commonData.fillingWidth = defaults.fillingWidth;
        commonData.glazingGlassQuantity = defaults.glazingGlassQn;
        if (!modelMode) {
            commonData.glass1id = defaults.glass1id;
            commonData.glass2id = defaults.glass2id;
            commonData.glass3id = defaults.glass3id;
            commonData.glass4id = defaults.glass4id;
            commonData.frame1id = defaults.frame1id;
            commonData.frame2id = defaults.frame2id;
            commonData.frame3id = defaults.frame3id;
        }
    }

    public static clearGlazingDefaults(commonData: WindowCommonData): void {
        commonData.glazingGlassQuantity = undefined;
        commonData.glass1id = undefined;
        commonData.glass2id = undefined;
        commonData.glass3id = undefined;
        commonData.glass4id = undefined;
        commonData.frame1id = undefined;
        commonData.frame2id = undefined;
        commonData.frame3id = undefined;
    }

    private static initWindowAddonFields(specification: DrawingDataSpecification) {
        type WindowAddonProp = {
            [K in keyof DrawingDataSpecification]: DrawingDataSpecification[K] extends WindowAddon ? K : never;
        }[keyof DrawingDataSpecification];
        const setIfMissing = (prop: WindowAddonProp) => {
            if (specification[prop] == undefined) {
                specification[prop] = new WindowAddon();
            }
        };
        setIfMissing('cover');
        setIfMissing('fittingBrake');
        setIfMissing('fittingSliding');
        setIfMissing('fittingType');
        setIfMissing('fittingEspagnoletteType');
        setIfMissing('fittingVeranda');
        setIfMissing('fittingInsertion');
        setIfMissing('fittingMainInsertion');
        setIfMissing('fittingAdditionalInsertion');
        setIfMissing('fittingLock');
        setIfMissing('fittingLockTerrace');
        setIfMissing('fittingAutomaticDrive');
        setIfMissing('frameEnhancement');
        setIfMissing('handleType');
        setIfMissing('milling');
        setIfMissing('millingNorwegian');
        setIfMissing('underWindowBead');
        setIfMissing('underwindowProfile');
        setIfMissing('weldType');
        setIfMissing('terraceHandle');
    }
}
