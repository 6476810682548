import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from "rxjs";
import {map, tap} from 'rxjs/operators';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {RoofGlazingPackageField} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {RoofGlazingPackageFieldUsage} from "../catalog-field-usage";
import {RoofGlazingPackage} from "./roof-glazing-package";
import {RoofGlazingPackageService} from "./roof-glazing-package.service";

@Component({
    selector: 'app-roof-glazing-package',
    templateUrl: './roof-glazing-package.component.html',
    styleUrls: ['./roof-glazing-package.component.css', '../../shared-styles.css'],
    providers: [RoofGlazingPackageService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoofGlazingPackageComponent extends CrudCommonComponent<RoofGlazingPackage, RoofGlazingPackageService> implements OnInit {

    readonly STEPS = {
        DATA: 'DATA'
    };

    item: RoofGlazingPackage;
    @ViewChild('dt') datatable;

    editPermits: FieldLimitation[] = [];
    fieldUsage: RoofGlazingPackageFieldUsage;
    RoofGlazingPackageField = RoofGlazingPackageField;

    validateDataStep: WizardStepValidator;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, RoofGlazingPackageService, 'ROOF_GLAZING_PACKAGE', 'RoofGlazingPackage');
        this.item = new RoofGlazingPackage();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new RoofGlazingPackageFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.ROOF_GLAZING_PACKAGES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    showDialogToAdd() {
        super.showDialogToAdd();
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.getItemWithImage(this.selectedItem.id);
        }
    }

    onRowSelect(event) {
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    onLanguageChange(): void {
        super.onLanguageChange();
    }

    getItemWithImage(glazingPackageId: number) {
        forkJoin({
            item: this.itemService.getItem(glazingPackageId),
            file: this.itemService.getImage(glazingPackageId)
        }).subscribe({
            next: data => {
                this.item = data[0];
                this.file = data[1];
                if (this.copyMode) {
                    this.item.id = undefined;
                }
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                console.debug('getRoofGlazingPackage completed!');
                this.setDisplayDialog(true);
            }
        });
    }

    submit() {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file);
            } else {
                observable = this.itemService.saveItem(this.item, this.file);
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');
        if (!this.item.name[this.userLang]) {
            this.validationErrors[`name[${this.userLang}]`] = `error.roofGlazingPackageDto.name[${this.userLang}].not_empty`;
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.roofGlazingPackageDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999).validate(this.item.sortIndex);

        if (!this.item.glassesQuantity) {
            this.validationErrors['glassesQuantity'] = 'error.roofGlazingPackageDto.glassesQuantity.not_null';
        } else if (!this.glassesQuantityValid(this.item.glassesQuantity)) {
            this.validationErrors['glassesQuantity'] = 'error.roofGlazingPackageDto.glassesQuantity.below_min';
        }

        if (!this.item.symbol) {
            this.validationErrors['symbol'] = 'error.roofGlazingPackageDto.symbol.not_empty';
        }

        this.validationErrors['ug'] = MultiValidator.of('error.roofGlazingPackageDto.ug')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(0, 99).validate(this.item.ug);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));

    }

    glassesQuantityValid(width) {
        let format = /^\d$/g;
        return format.test(width);
    }

    getNewItem(): RoofGlazingPackage {
        return new RoofGlazingPackage();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
