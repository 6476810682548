import {MultilanguageFieldInterface} from '../../../../../window-designer/catalog-data/multilanguage-field-interface';
import {JsonDeserializable} from '../../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../../supportedLanguages';
import {GateEditorField} from '../../../offer/gate-editor/gate-editor-field';
import {
    PositionDesignerCatalogDependentOption,
    WindowDesignerCatalogDependentOptionAction
} from '../../window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option';

@JsonDeserializable
export class GateDesignerCatalogDependentOption implements PositionDesignerCatalogDependentOption<GateEditorField | string> {
    id: number;
    gateSystemId: number;
    gateSystemName: MultilanguageFieldInterface;
    requiredInputId: GateEditorField | string;
    requiredInputValue: string;
    requiredInputValueName?: MultilanguageFieldInterface;
    dependentInputId: GateEditorField | string;
    dependentInputValue: string;
    dependentInputValueName?: MultilanguageFieldInterface;
    whenMatched: WindowDesignerCatalogDependentOptionAction;

    static fromJSON(jsonObject: object): GateDesignerCatalogDependentOption {
        const option = new GateDesignerCatalogDependentOption();
        option.id = jsonObject['id'];
        option.gateSystemId = jsonObject['gateSystemId'];
        if (jsonObject['gateSystemName'] != undefined) {
            option.gateSystemName = MultilanguageField.fromJSON(jsonObject['gateSystemName']);
        }
        option.requiredInputId = jsonObject['requiredInputId'];
        option.requiredInputValue = jsonObject['requiredInputValue'];
        if (jsonObject['requiredInputValueName'] != undefined) {
            option.requiredInputValueName = MultilanguageField.fromJSON(jsonObject['requiredInputValueName']);
        }
        option.dependentInputId = jsonObject['dependentInputId'];
        option.dependentInputValue = jsonObject['dependentInputValue'];
        if (jsonObject['dependentInputValueName'] != undefined) {
            option.dependentInputValueName = MultilanguageField.fromJSON(jsonObject['dependentInputValueName']);
        }
        if (jsonObject['whenMatched'] != undefined) {
            option.whenMatched = WindowDesignerCatalogDependentOptionAction[jsonObject['whenMatched'] as string];
        }
        return option;
    }
}
