import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from "primeng/dialog";
import {EMPTY, forkJoin, Observable} from 'rxjs';
import {map, tap} from "rxjs/operators";
import * as _ from 'underscore';
import {Permissions} from "../../../../../../auth/permission.service";
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {DataServiceHelper} from "../../../../../../common/dataServiceHelper";
import {ResponseError} from "../../../../../../common/error.handler";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {TemplateType} from "../../../../../settings/templates-settings/template";
import {TemplatesService} from "../../../../../settings/templates-settings/templates.service";
import {ComplaintPosition} from "../../../../complaint/complaint-position";
import {Comment, CommentType, PositionComment} from "../../../comment";
import {CommentService} from "../../../comment-service";
import {CommentDialogMode} from "./comment-dialog-mode";

@Component({
    selector: 'app-comment-dialog',
    templateUrl: './comment-dialog.component.html',
    styleUrls: ['../../../../../shared-styles.css', './comment-dialog.component.css'],
    providers: [CommentService, TemplatesService, DataServiceHelper]
})
export class CommentDialogComponent implements AfterViewInit {

    public static readonly READ_FLAG_CHANGE_BLOCK_ID = 'readFlagChange';

    @Input() offerId: number;
    @Input() offerPositionId: number;
    @Input() complaintId: number;
    @Input() complaintPosition: ComplaintPosition;
    @Input() mode: CommentDialogMode;

    @Output() onCloseDialog = new EventEmitter<boolean>();
    @Output() onSaveCommentSuccess = new EventEmitter<void>();

    @ViewChild(Dialog) dialog: Dialog;

    comments: Comment[] = [];
    positionComments: PositionComment[] = [];

    newComment: Comment = new Comment();
    validationErrors = {};

    private commentAdded = false;
    CommentDialogMode = CommentDialogMode;
    filteredComments: Comment[] = [];
    otherInfoTemplates: SelectItem[] = [];
    selectedTemplate: string;
    componentInitialized = false;
    isCurrentUserVenskaUser = false;
    positionsCommentsNumber: number;

    openTabCommentId;
    openTabPositionCommentId;
    commentReadFlagChanged = false;
    CommentType = CommentType;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private commentService: CommentService,
                private translate: TranslateService,
                private permissions: Permissions,
                private templateService: TemplatesService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController) {
        this.isCurrentUserVenskaUser = this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    ngAfterViewInit(): void {
        this.initComment();
        forkJoin({
            templates: this.loadTemplates(),
            comments: this.loadComments()
        }).subscribe({
            next: loadStatus => {
                this.componentInitialized = loadStatus.templates && loadStatus.comments;
                this.filterComments();
                this.positionsCommentsNumber = this.positionComments.map(positionComment => positionComment.comments.length)
                    .reduce((sum, current) => sum + current, 0);
                this.changeDetector.markForCheck();
            },
            error: (error) => this.errors.handle(error)
        });
    }

    closeDialog(): void {
        this.dialogHideHelper.call(() => this.onCloseDialog.emit(this.commentAdded || this.commentReadFlagChanged));
    }

    addComment(): void {
        if (this.newComment && this.newComment.comment) {
            this.commentService.addComment(this.newComment, this.offerId).subscribe({
                next: (savedComment) => {
                    this.handleCommentSaved(savedComment);
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
        } else {
            this.validationErrors['comment'] = 'error.commentDto.comment.not_null';
        }
    }

    private handleCommentSaved(savedComment: Comment): void {
        this.emitSuccessEvent();
        this.comments.unshift(savedComment);
        this.filteredComments.unshift(savedComment);
        this.newComment = new Comment();
        this.initComment();
        this.dialog.center();
        this.commentAdded = true;
    }

    private emitSuccessEvent(): void {
        this.onSaveCommentSuccess.emit();
    }

    private initComment(): void {
        switch (this.mode) {
            case CommentDialogMode.OFFER:
                this.newComment.offerId = this.offerId;
                break;
            case CommentDialogMode.OFFER_POSITION:
                this.newComment.offerPositionId = this.offerPositionId;
                break;
            case CommentDialogMode.COMPLAINT:
                this.newComment.complaintId = this.complaintId;
                break;
            case CommentDialogMode.COMPLAINT_POSITION:
                this.newComment.complaintPositionId = this.complaintPosition ? this.complaintPosition.id : null;
                break;
        }
    }

    private filterComments(): void {
        this.comments.forEach(comment => {
            let filtered = _.clone(comment);

            if (filtered.commentType === CommentType.SYSTEM) {
                let parts = filtered.comment.split(' ');
                this.translate.get(parts).subscribe(labels => {
                    filtered.comment = parts.map(part => labels[part]).join(' ').replace(/<br\/> /, "<br/>");
                    this.changeDetector.markForCheck();
                });
            }

            this.filteredComments.push(filtered);
        });
    }

    addTemplate(): void {
        if (this.selectedTemplate != null) {
            if (this.newComment.comment) {
                this.newComment.comment = (this.newComment.comment + " " + this.selectedTemplate).substring(0, 500);
            } else {
                this.newComment.comment = this.selectedTemplate;
            }
        }
    }

    private loadTemplates(): Observable<boolean> {
        let filters = {};

        filters["templateType"] = {
            value: TemplateType[TemplateType.COMMENT]
        };

        return this.templateService.getItems(0, 1000, filters, null, null).pipe(tap({
            next: data => {
                let templates = [];
                for (let template of data.data) {
                    templates.push({
                        label: this.trimContent(template.content[this.translate.currentLang]),
                        value: template.content[this.translate.currentLang]
                    });
                    if (templates.length > 0) {
                        this.selectedTemplate = templates[0].value;
                    }
                }
                this.otherInfoTemplates = templates;
            },
            error: error => {
                throw new ResponseError(error);
            }
        }), map(() => true));
    }

    private loadComments(): Observable<boolean> {
        switch (this.mode) {
            case CommentDialogMode.OFFER:
                return forkJoin({
                    offerComments: this.commentService.getCommentsForOffer(this.offerId),
                    positionComments: this.commentService.getCommentsForAllOfferPositions(this.offerId)
                }).pipe(
                    tap(data => {
                        this.comments = data.offerComments;
                        this.positionComments = data.positionComments;
                    }),
                    map(() => true));
            case CommentDialogMode.OFFER_POSITION:
                return this.commentService.getCommentsForOfferPosition(this.offerPositionId).pipe(
                    tap(comments => this.comments = comments), map(() => true));
            case CommentDialogMode.COMPLAINT_POSITION:
                return this.commentService.getCommentsForComplaintPosition(this.complaintPosition.id).pipe(
                    tap(comments => this.comments = comments), map(() => true));
            case CommentDialogMode.COMPLAINT:
                return this.commentService.getCommentsForComplaint(this.complaintId).pipe(
                    tap(comments => this.comments = comments), map(() => true));
        }
        return EMPTY;
    }

    trimContent(data: string): string {
        if (data && data.length > 50) {
            return data.substring(0, 48) + "...";
        }
        return data;
    }

    getCommentChangeReadFlagLabel(comment: Comment) {
        if (this.isCurrentUserVenskaUser && comment.unreadByVenska || !this.isCurrentUserVenskaUser && comment.unreadBySubsystem) {
            return this.translate.instant('GENERAL.MARK_AS_READ');
        }
        return this.translate.instant('GENERAL.MARK_AS_UNREAD');
    }

    hasUnreadComment(comments: Comment[]): boolean {
        if (this.mode !== CommentDialogMode.COMPLAINT) {
            if (this.isCurrentUserVenskaUser && comments.some(comment => comment.unreadByVenska) ||
                !this.isCurrentUserVenskaUser && comments.some(comment => comment.unreadBySubsystem)) {
                return true;
            }
        }
        return false;
    }

    hasUnreadPositionComment(positionComments: PositionComment[]): boolean {
        return positionComments.some(positionComment => this.hasUnreadComment(positionComment.comments));
    }

    changeReadFlag(comment: Comment) {
        this.blockUiController.block(CommentDialogComponent.READ_FLAG_CHANGE_BLOCK_ID);
        this.commentService.changeReadFlag(comment.id).subscribe({
            next: newCommentData => {
                if (newCommentData.offerId != undefined || this.mode === CommentDialogMode.OFFER_POSITION) {
                    let index = this.comments.findIndex(com => com.id === newCommentData.id);
                    this.comments[index] = newCommentData;

                    let filteredCommentIndex = this.filteredComments.findIndex(com => com.id === newCommentData.id);
                    this.filteredComments[filteredCommentIndex] = newCommentData;

                    this.openTabCommentId = newCommentData.id;
                } else if (newCommentData.offerPositionId != undefined) {
                    for (let i = 0; i < this.positionComments.length; ++i) {
                        const positionComment = this.positionComments[i];
                        const commentIndex = positionComment.comments.findIndex(com => com.id === newCommentData.id);
                        if (commentIndex >= 0) {
                            positionComment.comments[commentIndex] = newCommentData;
                            break;
                        }
                    }

                    this.openTabPositionCommentId = newCommentData.id;
                }

                this.commentReadFlagChanged = true;
                this.changeDetector.markForCheck();

                this.blockUiController.unblock(CommentDialogComponent.READ_FLAG_CHANGE_BLOCK_ID);
            },
            error: error => {
                this.errors.handle(error);
                this.blockUiController.unblock(CommentDialogComponent.READ_FLAG_CHANGE_BLOCK_ID);
            }
        });
    }
}
