import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {MailBoxSslMode} from './mail-box-configuration';
import {SubsystemWebshopMailConfiguration} from './subsystem-webshop-mail-configuration';
import {SubsystemWebshopMailConfigurationService} from './subsystem-webshop-mail-configuration.service';

@Component({
    selector: 'app-subsystem-webshop-mail-configuration',
    templateUrl: './subsystem-webshop-mail-configuration.component.html',
    styleUrls: ['../settings.component.css', './subsystem-webshop-mail-configuration.component.css'],
    providers: [SubsystemWebshopMailConfigurationService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemWebshopMailConfigurationComponent implements OnInit {

    private readonly SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION_SAVING_ID = "SubsystemWebshopMailConfigurationSavingId";
    private readonly SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION_LOADING_ID = "SubsystemWebshopMailConfigurationLoadingId";

    sslModes: Observable<SelectItem[]>;
    mailConfig: SubsystemWebshopMailConfiguration;
    validationErrors = {};

    constructor(public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private subsystemWebshopMailService: SubsystemWebshopMailConfigurationService,
                private growlMessageController: GrowlMessageController,
                private errors: CommonErrorHandler,
                protected changeDetector: ChangeDetectorRef,
                public permissions: Permissions,
                private blockUiController: BlockUiController) {
        this.mailConfig = new SubsystemWebshopMailConfiguration();
    }

    ngOnInit() {
        this.sslModes = this.translatedSelectItemService.buildUnsortedDropdown(
            [MailBoxSslMode.SSL, MailBoxSslMode.STARTTLS, MailBoxSslMode.AZURE], '', undefined);
        this.blockUiController.block(this.SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION_LOADING_ID);
        this.subsystemWebshopMailService.getItem().pipe(
            finalize(() => this.blockUiController.unblock(this.SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION_LOADING_ID))
        ).subscribe({
            next: data => {
                this.mailConfig = data;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    save() {
        if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            this.blockUiController.block(this.SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION_SAVING_ID);
            this.subsystemWebshopMailService.saveItem(this.mailConfig).pipe(
                finalize(() => this.blockUiController.unblock(this.SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION_SAVING_ID))
            ).subscribe({
                next: () => this.showSuccessMessage(),
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    get isAzure() {
        return this.mailConfig.mailBoxConfiguration.sslMode === MailBoxSslMode.AZURE;
    }

    private showSuccessMessage(): void {
        this.growlMessageController.info("SUBSYSTEM_WEBSHOP_MAIL_CONFIGURATION.SAVING_SUCCESS_MESSAGE");
    }

    editEditorValue(content: string): void {
        this.mailConfig.mailBody = content;
        this.resetErrors();
    }

    private resetErrors() {
        this.validationErrors = {};
        this.changeDetector.markForCheck();
    }
}
