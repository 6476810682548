import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector} from '@angular/core';
import {ExportComponent} from '../../../../common/service/export.component';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {SubsystemService} from '../../../subsystem/subsystem.service';
import {AbstractUserActivityComponent} from '../abstract-user-activity.component';
import {UserActivityService} from '../user-activity.service';

@Component({
    selector: 'app-user-login-times',
    templateUrl: '../abstract-user-activity.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [UserActivityService, SubsystemService, TranslatedSelectItemService, ExportComponent],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserLoginTimesComponent extends AbstractUserActivityComponent {

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super('lastLoginTime', 'ADMIN_PANEL.LAST_LOGIN_TIME_COLUMN', 'ADMIN_PANEL.LAST_LOGIN_TIMES', true, 'UserLoginTimesComponent',
            injector, changeDetector);
    }
}
