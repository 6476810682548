<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRILLS.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRILL.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRILL.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords"
                 selectionMode="single" (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="muntinTable">
        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(GrillField.NAME)"
                          header="{{'GRILL.FORM.NAME'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="name"></p-column>
                <p-column [rowspan]="2" [sortable]="true" field="sortIndex" header="{{'GRILL.FORM.SORT_INDEX' | translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(GrillField.SHORTCUT)"
                          header="{{'GRILL.FORM.SHORTCUT'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="shortcut"></p-column>
                <p-column *ngIf="fieldUsage.show(GrillField.SYMBOL)"
                          header="{{'GRILL.FORM.SYMBOL'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="symbol"></p-column>
                <p-column *ngIf="fieldUsage.show(GrillField.TYPE)"
                          header="{{'GRILL.FORM.TYPE.HEADER'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="type" [filterValues]="filterType | async"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)"
                          header="{{'GRILL.FORM.SYSTEMS'|translate}}" [colspan]="4"></p-column>
                <p-column *ngIf="fieldUsage.show(GrillField.ACTIVE)"
                          header="{{'GRILL.FORM.STATUS'|translate}}" [rowspan]="2" field="active" [sortable]="false"
                          [filter]="showFilters"
                          [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.PCV'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.WOOD'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM_WOOD_MIX'|translate}}"></p-column>
            </p-row>
        </p-headerColumnGroup>
        <p-column *ngIf="fieldUsage.show(GrillField.NAME)" field="name">
            <ng-template let-item="rowData" pTemplate="body">{{item.name[userLang]}}</ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(GrillField.SORT_INDEX)" field="sortIndex"></p-column>
        <p-column *ngIf="fieldUsage.show(GrillField.SHORTCUT)" field="shortcut">
            <ng-template let-item="rowData" pTemplate="body">{{item.shortcut[userLang]}}</ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(GrillField.SYMBOL)" field="symbol">
            <ng-template let-item="rowData" pTemplate="body">{{item.symbol}}</ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(GrillField.TYPE)" field="type">
            <ng-template let-item="rowData" pTemplate="body">{{'GRILL.FORM.TYPE.'+item.type | translate}}</ng-template>
        </p-column>

        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="pcv">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.pcv)" (ngModelChange)="materialChange(item, 'PCV')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminium">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminium)" (ngModelChange)="materialChange(item, 'ALUMINIUM')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="wood">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.wood)" (ngModelChange)="materialChange(item, 'WOOD')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminiumWoodMix">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminiumWoodMix)" (ngModelChange)="materialChange(item, 'ALUMINIUM_WOOD_MIX')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <p-column *ngIf="fieldUsage.show(GrillField.ACTIVE)" [filter]="showFilters" [sortable]="false" field="active" header="{{ 'GRILL.FORM.STATUS' | translate }}"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                {{(item.active ? 'GRILL.FORM.ACTIVE' : 'GRILL.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRILL.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'GRILL.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-grill-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [availableColors]="availableColors"
                        [types]="availableTypes | async" [file]="file" (fileChange)="onFileChange($event)"
                        (selectedColorsChange)="clearError('colorIds')"></app-grill-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GRILL.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRILL.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GRILL.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'GRILL.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-grill-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [availableColors]="availableColors"
                        [types]="availableTypes | async" [file]="file" (fileChange)="onFileChange($event)"
                        (selectedColorsChange)="clearError('colorIds')"></app-grill-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GRILL.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)" [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
