import {DrawingData} from "./drawing-data/drawing-data";
import {DrawingUtil} from "./drawing-util";
import {WindowCalculator} from "./window-calculator";

export abstract class PolygonGeometryCalculator {

    // not used, but could be usefull in the future - method calculates area for any polygon
    public static calculatePolygonAreaForWindow(data: DrawingData): number {
        let framePoints = WindowCalculator.getOuterFramePoints(data.windows, data.cuts);
        let splitedArray = this.splitArray(framePoints);
        return this.polygonArea(splitedArray[0], splitedArray[1], framePoints.length / 2);
    }

    public static calculatePolygonPerimeterForWindow(data: DrawingData): number {
        let framePoints = WindowCalculator.getOuterFramePoints(data.windows, data.cuts);
        let splitedArray = this.splitArray(framePoints);
        return this.polygonPerimeter(splitedArray[0], splitedArray[1], framePoints.length / 2);
    }

    public static calculatePolygonWidthForWindow(data: DrawingData): number {
        let framePoints = WindowCalculator.getOuterFramePoints(data.windows, data.cuts);
        let minMaxXY = DrawingUtil.calculatePolygonTotalBoundingBox(framePoints);
        return minMaxXY.maxX - minMaxXY.minX;
    }

    public static calculatePolygonHeightForWindow(data: DrawingData): number {
        let framePoints = WindowCalculator.getOuterFramePoints(data.windows, data.cuts);
        let minMaxXY = DrawingUtil.calculatePolygonTotalBoundingBox(framePoints);
        return minMaxXY.maxY - minMaxXY.minY;
    }

    private static polygonArea(X: number[], Y: number[], numPoints: number): number {
        let area = 0;
        let j = numPoints - 1;

        for (let i = 0; i < numPoints; i++) {
            area = area + (X[j] + X[i]) * (Y[j] - Y[i]);
            j = i;
        }
        return Math.abs(area / 2);
    }

    private static polygonPerimeter(X: number[], Y: number[], numPoints: number): number {
        let perimeter = 0;

        let j = numPoints - 1;
        for (let i = 0; i < numPoints; i++) {
            let distance = Math.sqrt(Math.pow((X[j] - X[i]), 2) + Math.pow((Y[j] - Y[i]), 2));
            perimeter += distance;
            j = i;
        }

        return perimeter;
    }

    private static splitArray(arrayToSplit: number[]): number[][] {
        let oddOnes: number[] = [];
        let evenOnes: number[] = [];
        for (let i = 0; i < arrayToSplit.length; i++) {
            (i % 2 === 0 ? evenOnes : oddOnes).push(arrayToSplit[i]);
        }
        return [evenOnes, oddOnes];
    }
}
