<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'GRASP_GLAZING_PACKAGE.TITLE.LIST' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'GRASP_GLAZING_PACKAGE.TITLE.COPY' | translate }}" icon="content_copy"
                                   type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'GRASP_GLAZING_PACKAGE.TITLE.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" id="categoriesTable" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column *ngIf="fieldUsage.show(GraspGlazingPackageField.NAME)"
                  field="name" sortField="{{ 'name.' + translate.currentLang }}" filterField="name"
                  header="{{ 'GRASP_GLAZING_PACKAGE.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column header="{{ 'GRASP_GLAZING_PACKAGE.FORM.SORT_INDEX' | translate }}" [sortable]="true" field="sortIndex"></p-column>
        <p-column *ngIf="fieldUsage.show(GraspGlazingPackageField.SYMBOL)"
                  field="symbol" sortField="symbol" filterField="symbol"
                  header="{{ 'GRASP_GLAZING_PACKAGE.FORM.SYMBOL' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.symbol }}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(GraspGlazingPackageField.CATEGORY)"
                  field="category" sortField="category" filterField="category"
                  header="{{ 'GRASP_GLAZING_PACKAGE.FORM.CATEGORY' | translate }}" [sortable]="false" [filter]="showFilters"
                  [filterValues]="filterCategories | selectItemMultilanguageFieldTranslate">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.glazingCategoryId | glazingPackageCategoryName:allCategories | multilanguageTranslate }}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(GraspGlazingPackageField.FRAME_CATEGORY)"
                  field="frameCategoryId" filterField="frameCategoryId"
                  header="{{ 'GRASP_GLAZING_PACKAGE.FORM.FRAME_CATEGORY' | translate }}" [sortable]="false" [filter]="showFilters"
                  [filterValues]="filterFrameCategories | selectItemMultilanguageFieldTranslate">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.frameCategoryId | glazingPackageCategoryName:allFrameCategories | multilanguageTranslate }}
            </ng-template>
        </p-column>
        <p-column field="glassQuantity" filterField="glassQuantity" sortField="glassQuantity"
                  header="{{ 'GRASP_GLAZING_PACKAGE.FORM.GLASS_QUANTITY' | translate }}" [sortable]="true" [filter]="showFilters"
                  [filterValues]="availableQuantities">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.glazing.glazingGlassQuantity }}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(GraspGlazingPackageField.ACTIVE)" [filter]="showFilters" field="active" header="{{ 'GRASP_GLAZING_PACKAGE.FORM.STATUS' | translate }}"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'GRASP_GLAZING_PACKAGE.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'GRASP_GLAZING_PACKAGE.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'GRASP_GLAZING_PACKAGE.TITLE.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'GRASP_GLAZING_PACKAGE.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-grasp-glazing-package-form [editPermits]="editPermits" [item]="item"
                                        [selectCategories]="availableCategories"
                                        [frameCategories]="availableFrameCategories"
                                        [validationErrors]="validationErrors"></app-grasp-glazing-package-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GRASP_GLAZING_PACKAGE.STEPS.GLAZING' | translate }}" [id]="STEPS.GLAZING" [validate]="validateGlazingStep"
                     *ngIf="fieldUsage.show(CatalogTab.GLAZING)">
        <app-grasp-glazing-form [frames]="frames" [glasses]="glasses" [addMode]="true"
                                [glazing]="item.glazing" [validationErrors]="validationErrors"
                                [selectedGlazingBead]="item.glazingBeadId" (onGlazingBeadChange)="handleGlazingBeadChange($event)"
                                [availableGlazingBeads]="availableGlazingBeads"></app-grasp-glazing-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GRASP_GLAZING_PACKAGE.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <app-grasp-glazing-package-systems-form [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)" [item]="item"
                                                [(linkedSystemIds)]="selectedWindowSystems"
                                                [windowSystems]="windowSystems"></app-grasp-glazing-package-systems-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ (copyMode ? 'GRASP_GLAZING_PACKAGE.TITLE.COPY' : 'GRASP_GLAZING_PACKAGE.TITLE.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'GRASP_GLAZING_PACKAGE.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-grasp-glazing-package-form [editPermits]="editPermits" [item]="item"
                                        [selectCategories]="availableCategories"
                                        [frameCategories]="availableFrameCategories"
                                        [validationErrors]="validationErrors"></app-grasp-glazing-package-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GRASP_GLAZING_PACKAGE.STEPS.GLAZING' | translate }}" [id]="STEPS.GLAZING" [validate]="validateGlazingStep"
                     *ngIf="fieldUsage.show(CatalogTab.GLAZING)">
        <app-grasp-glazing-form [frames]="frames" [glasses]="glasses" [glazing]="item.glazing"
                                [selectedGlazingBead]="item.glazingBeadId" (onGlazingBeadChange)="handleGlazingBeadChange($event)"
                                [validationErrors]="validationErrors"
                                [availableGlazingBeads]="availableGlazingBeads"></app-grasp-glazing-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'GRASP_GLAZING_PACKAGE.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <app-grasp-glazing-package-systems-form [(linkedSystemIds)]="selectedWindowSystems" [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                                [item]="item"
                                                [windowSystems]="windowSystems"></app-grasp-glazing-package-systems-form>
    </app-wizard-step>
</app-wizard-dialog>
