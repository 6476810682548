import {HttpClient} from "@angular/common/http";
import {Injectable} from '@angular/core';
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CrudService} from "../../../common/crud-common/crud.service";
import {Listing} from "../../../common/crud-common/crudItemList";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {GraspGlazingCategory} from "./grasp-glazing-category";

@Injectable()
export class GraspGlazingCategoryService implements CrudService<GraspGlazingCategory> {

    private static readonly API_URL = 'graspGlazingCategory';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) { }

    addItem(item: GraspGlazingCategory): Observable<number> {
        return this.saveCategory(item);
    }

    editItem(itemId: number, item: GraspGlazingCategory): Observable<number> {
        return this.saveCategory(item, itemId);
    }

    getItem(itemId: number): Observable<GraspGlazingCategory> {
        return this.http.get<GraspGlazingCategory>(`${GraspGlazingCategoryService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<GraspGlazingCategory>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<GraspGlazingCategory>>(GraspGlazingCategoryService.API_URL, {params: params});
    }

    validate(item: GraspGlazingCategory): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${GraspGlazingCategoryService.API_URL}/validate`, item));
    }

    copy(id: number, item: GraspGlazingCategory) {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('graspGlazingCategoryDto', new Blob([body], {type: 'application/json'}));

        return this.http.post(`${GraspGlazingCategoryService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('graspGlazingCategory', response)));
    }

    saveCategory(item: GraspGlazingCategory, itemId?: number): Observable<number> {
        const url = itemId ? `${GraspGlazingCategoryService.API_URL}/${itemId}` : GraspGlazingCategoryService.API_URL;
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('graspGlazingCategoryDto', new Blob([body], {type: 'application/json'}));

        return this.http.post(url, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('graspGlazingCategory', response)));
    }

    getActiveItems() {
        return this.http.get<Listing<object>>(`${GraspGlazingCategoryService.API_URL}/active`)
            .pipe(map(response => Listing.fromJSON(GraspGlazingCategory, response)));
    }
}
