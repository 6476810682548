import {ChangeDetectorRef, Component, ElementRef, forwardRef, Inject, Input, Optional, Renderer2, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';

export const INPUT_NUMBER_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputNumberComponent),
    multi: true
};

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    providers: [INPUT_NUMBER_VALUE_ACCESSOR]
})
export class InputNumberComponent extends AbstractInputComponent {

    @Input()
    min: number;

    @Input()
    max: number;

    @Input()
    step: number;

    @Input()
    shouldRoundToStep: boolean;

    @Input()
    required: boolean;

    @Input()
    allowOnlyIntegers = false;

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }

    keydown(event: KeyboardEvent): boolean {
        if (this.allowOnlyIntegers) {
            switch (event.key) {
                case 'Backspace':
                case 'Tab':
                case 'Enter':
                case 'Delete':
                case 'End':
                case 'Home':
                case 'ArrowLeft':
                case 'ArrowUp':
                case 'ArrowRight':
                case 'ArrowDown':
                case '0':
                case '1':
                case '2':
                case '3':
                case '4':
                case '5':
                case '6':
                case '7':
                case '8':
                case '9':
                    return true;
                default:
                    break;
            }
            const copyPasteModifierDown = event.ctrlKey || event.metaKey;
            return copyPasteModifierDown && (event.key === 'x' || event.key === 'c' || event.key === 'v');
        }
        return true;
    }

    roundToStep() {
        const remainder = this.value % this.step;
        if (remainder !== 0) {
            this.value = (remainder < (this.step / 2) ? this.value - remainder : this.value + (this.step - remainder));
        }
    }

    handleNumberInputBlur(event: FocusEvent) {
        if (this.required) {
            if (this.value > this.max) {
                this.value = this.max;
            }
            if (this.value < this.min) {
                this.value = this.min;
            }
            if (this.shouldRoundToStep) {
                this.roundToStep();
            }
        }
        this.handleBlur(event);
    }
}
