import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {SelectItemImpl} from '../../../../../../../common/service/select.item.impl';
import {ValidationErrors} from '../../../../../../../common/validation-errors';
import {Cecha} from "../../ConfigurableAddonModel/Cecha";
import {CechaType} from "../../../../../../../../window-designer/enums/CechaType";
import {Opcja} from '../../ConfigurableAddonModel/Opcja';

@Component({
    selector: 'app-configurable-addon-field',
    templateUrl: './configurableAddon-field.component.html',
    styleUrls: ['../../../../../../shared-styles.css', './configurableAddon-field.component.css']
})
export class ConfigurableAddonFieldComponent implements OnInit, OnChanges {
    @Input() cecha: Cecha;
    @Input() userLang: string;
    @Input() isMasterCecha = false;
    @Input() displayImageIcon = false;
    @Input() errors: ValidationErrors;
    @Input() pricingErrors: string[];
    @Input() errorKey: any;
    @Input() initialValue: any;
    @Input() readOnlyMode: boolean;
    @Output() onMasterCechaChange = new EventEmitter<any>();
    @Output() valueChange = new EventEmitter<any>();
    @Output() onImageIconClick = new EventEmitter<any>();
    fieldValue: any;
    fieldId: string;
    CechaType = CechaType; // used in html template

    ngOnInit() {
        this.fieldId = this.cecha.symbol;

        if (this.cecha.defaultValue && (this.initialValue == undefined || this.initialValue == '')) {
            this.fieldValue = this.cecha.defaultValue;
            this.onValueChange(this.cecha.defaultValue);
        } else {
            this.fieldValue = this.initialValue;
            if (this.cecha.type === CechaType.OPTIONS) {
                this.cecha.opcje = this.cecha.opcje.filter(opcja => !opcja.hidden || opcja.symbol === this.fieldValue);
            }
        }
        if (this.fieldValue === 0) {
            this.fieldValue = "";
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.cecha.symbol === 'quantity') {
            if ('initialValue' in changes) {
                this.fieldValue = changes['initialValue'].currentValue;
            }
        }
    }

    onValueChange(value) {
        this.fieldValue = value != undefined ? value : "";
        this.valueChange.emit(this.fieldValue);
        if (this.isMasterCecha) {
            this.onMasterCechaChange.emit();
        }
        this.resetError();
    }

    resetError() {
        if (this.errors != undefined) {
            this.errors[this.errorKey] = undefined;
        }
        this.pricingErrors = [];
    }

    // gets any available validation message - this only controls if validation message block is displayed
    getAnyValidationMessage(): string {
        if (this.errors != undefined && this.errors[this.errorKey] != undefined) {
            return this.errors[this.errorKey];
        }
        if (this.pricingErrors != undefined && this.pricingErrors.length > 0) {
            return this.pricingErrors[0];
        }
        return undefined;
    }

    formatSelectOption = (opcja: Opcja) =>
        new SelectItemImpl(opcja.nameCode != undefined ? opcja.nameCode : opcja.nazwa[this.userLang], opcja.symbol)

    imageIconClick() {
        this.onImageIconClick.emit();
    }
}
