export enum AddonFor {
    BULK = "BULK",
    WINDOW = "WINDOW",
    WING = "WING",
    WING_ACTIVE = "WING_ACTIVE",
    WING_PASSIVE = "WING_PASSIVE",
    WING_FUNCTIONS = "WING_FUNCTIONS",
    WING_FUNCTIONS_GROUP = "WING_FUNCTIONS_GROUP",
    DOORSTEP_WIDTH = "DOORSTEP_WIDTH",
    GLAZING_NON_ESG = "GLAZING_NON_ESG",
    QUARTER = "QUARTER",
    AREA = "AREA", // number of areas
    AREA_SIZE = "AREA_SIZE", // total size of areas (m2)
    TERRACE_LOCK = "TERRACE_LOCK",
    TERRACE_HANDLE = 'TERRACE_HANDLE',
    GATE = 'GATE',
    CONFIG_ADDON = 'CONFIG_ADDON'
}
