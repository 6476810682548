import {Guide} from "./Guide";

export class GuideData {
    main: Guide[];
    structure: Guide[];

    constructor() {
        this.main = [];
        this.structure = [];
    }
}
