import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ListOfIds} from '../../ListOfIds';
import {DeliveryList} from './delivery-list';
import {DeliveryListStatus} from './DeliveryListStatus';
import {Palette} from './palette';
import {OrderDeliveryData} from './palette-wizard/palette-production-orders/order-delivery-data';

@Injectable()
export class DeliveryListService {

    private static readonly API_URL = 'deliveryList';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [field: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<DeliveryList>> {
        const searchParams = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(`${DeliveryListService.API_URL}`, {params: searchParams})
            .pipe(mapListingToJson(DeliveryList));
    }

    getItem(id: number, selectedSorting = 'name_asc'): Observable<DeliveryList> {
        let params: { [param: string]: string | string[] } = {};
        params['sortPalettesBy'] = selectedSorting.split('_')[0];
        params['palettesAscending'] = selectedSorting.split('_')[1] === 'asc' ? 'true' : 'false';
        return this.http.get<object>(`${DeliveryListService.API_URL}/${id}`, {params})
            .pipe(mapItemToJson(DeliveryList));
    }

    save(deliveryList: DeliveryList): Observable<number> {
        if (deliveryList.id) {
            return this.http.put<void>(`${DeliveryListService.API_URL}/${deliveryList.id}`, deliveryList)
                .pipe(map(() => deliveryList.id));
        }
        return this.http.post<void>(`${DeliveryListService.API_URL}`, deliveryList, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(DeliveryListService.API_URL, response)));
    }

    setStatus(id: number, status: DeliveryListStatus): Observable<void> {
        return this.http.post<void>(`${DeliveryListService.API_URL}/setStatus/${id}/${status}`, {});
    }

    addPalette(deliveryListId: number, palette: Palette): Observable<void> {
        return this.http.post<void>(`${DeliveryListService.API_URL}/addPalette/${deliveryListId}`, palette);
    }

    detach(deliveryListId: number, ids: ListOfIds): Observable<void> {
        return this.http.post<void>(`${DeliveryListService.API_URL}/detach/${deliveryListId}`, ids);
    }

    getDeliveryData(deliveryListId: number): Observable<Listing<OrderDeliveryData>> {
        return this.http.get<Listing<OrderDeliveryData>>(`${DeliveryListService.API_URL}/deliveryData/${deliveryListId}`)
            .pipe(mapListingToJson(OrderDeliveryData));
    }

    validateGeneralData(item: DeliveryList): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${DeliveryListService.API_URL}/validateGeneralDetails`, item));
    }

    validateSubsystemData(item: DeliveryList): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post(`${DeliveryListService.API_URL}/validateSubsystemData`, item));
    }

    delete(deliveryListId: number): Observable<void> {
        return this.http.delete<void>(`${DeliveryListService.API_URL}/${deliveryListId}`);
    }
}
