import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {SkipInterceptorsInterceptor} from '../common/http/skip-interceptors.interceptor';
import {IsAnotherUserLoggedService} from '../features/login/isAnotherUserLogged';
import {JwtDto} from "./jwt-dto";
import {JwtInterceptor} from './jwt.interceptor';
import {LogoutHelperService} from './logout-helper.service';
import {StorageKey, StorageService} from './storage.service';

@Injectable()
export class AuthService {

    private static readonly USERS_URL = '/features/user';
    private readonly version: string;

    constructor(private router: Router,
                private storageService: StorageService,
                private http: HttpClient,
                private isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private logoutHelper: LogoutHelperService) {
        this.version = environment.version;
    }

    login(credentials): Observable<JwtDto> {
        credentials.frontendVersion = this.version;
        return this.http.post<JwtDto>('auth/login', credentials, {
            headers: {
                [SkipInterceptorsInterceptor.HEADER]: JwtInterceptor.NAME,
                [JwtInterceptor.SKIP_ERROR_HANDLING_HEADER]: '1'
            }
        });
    }

    logout(navigateToLogin = true): void {
        this.logoutHelper.logout(navigateToLogin);
    }

    switchUser(username: string): void {
        this.switchUserApi(username).pipe(
            map(
                tokenObject => {
                    // Save current user data
                    this.storageService.set(StorageKey.MAIN_JWT, this.storageService.get(StorageKey.JWT));
                    this.storageService.set(StorageKey.MAIN_USER_AUTH_DATA, this.storageService.get(StorageKey.CURRENT_USER_AUTH_DATA));

                    // Write new user data
                    this.storageService.set(StorageKey.JWT, tokenObject.token);
                    this.storageService.set(StorageKey.CURRENT_USER_AUTH_DATA, JSON.stringify({...tokenObject, token: undefined}));
                    this.storageService.set(StorageKey.CURRENT_USER_INTERFACE_LANGUAGE, tokenObject.userInterfaceLanguage);
                    this.storageService.set(StorageKey.DISPLAY_SIDE_MENU_LABELS, `${tokenObject.displaySideMenuLabels}`);
                }))
            .subscribe({
                next: () => {
                    this.isAnotherUserLoggedService.changeState(true);
                    this.router.navigate(['/features', {isSelf: false}]);
                },
                error: error => {
                    console.debug('Error while switching user: ' + error);
                }
            });
    }

    switchUserBack(): void {
        if (this.storageService.get(StorageKey.JWT) == undefined || this.storageService.get(StorageKey.MAIN_JWT) == undefined) {
            this.logoutHelper.logout();
            return;
        }
        this.storageService.set(StorageKey.JWT, this.storageService.get(StorageKey.MAIN_JWT));
        this.storageService.set(StorageKey.CURRENT_USER_AUTH_DATA, this.storageService.get(StorageKey.MAIN_USER_AUTH_DATA));
        this.storageService.remove(StorageKey.MAIN_JWT);
        this.storageService.remove(StorageKey.MAIN_USER_AUTH_DATA);
        this.storageService.remove(StorageKey.CURRENT_USER_INTERFACE_LANGUAGE);
        this.isAnotherUserLoggedService.changeState(false);
        this.router.navigate(['/features', {isSelf: true}, 'user'],
            this.router.url === AuthService.USERS_URL ? {queryParams: {reload: true}} : undefined);
    }

    private switchUserApi(username: string): Observable<JwtDto> {
        return this.http.get<JwtDto>('auth/switch', {params: {username: username}});
    }

    getUserData(token: string): Observable<JwtDto> {
        return this.http.post<JwtDto>('auth/userData', undefined, {
            headers: {
                [SkipInterceptorsInterceptor.HEADER]: JwtInterceptor.NAME,
                Authorization: token
            }
        });
    }

    sendPasswordResetMail(login: string): Observable<void> {
        return this.http.post<void>('auth/sendPasswordResetMail', {
            username: login,
            origin: location.origin
        }, {
            headers: {
                [SkipInterceptorsInterceptor.HEADER]: JwtInterceptor.NAME,
                [JwtInterceptor.SKIP_ERROR_HANDLING_HEADER]: '1'
            }
        });
    }
}
