<p-dialog [resizable]="false" [visible]="true" [modal]="true" (onHide)="cancel()" [focusOnShow]="false">

    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.CREATE_COMPLAINT_CONFIRM.TITLE' | translate }}
        </div>
    </p-header>

    <div class="new-form-600">
        <div>
            <p>{{'OFFER.POSITIONS.DIALOGS.CREATE_COMPLAINT_CONFIRM.MESSAGE' | translate}}</p>
            <div *ngFor="let item of itemsToDisplay" class="related-complaint">
                <span (click)="goToComplaint(item.id)">
                    <span [ngClass]="'status-color ' + item.status">
                        {{ 'OFFER.COMPLAINT.FORM.STATUS.' + item.status | translate }}
                    </span>
                    <strong>{{item.complaintNumber}}</strong>
                    <span>({{ item.createdDate | momentCurrentLang | amDateFormat: 'L LT' }})</span>
                </span>
            </div>
        </div>

        <div>
            <p>{{'OFFER.POSITIONS.DIALOGS.CREATE_COMPLAINT_CONFIRM.CONFIRM' | translate}}</p>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="goToCreator()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="cancel()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
