import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'cellSelected',
    pure: true
})
export class CellSelectedPipe implements PipeTransform {

    transform(selectedCells: { x: number, y: number }[] | number[], row: number, column: number): boolean {
        if (row !== null && column === null) {
            return selectedCells.some(val => val === row);
        } else if (row === null && column !== null) {
            return selectedCells.some(val => val === column);
        }
        return selectedCells.some(cell => cell.x === row && cell.y === column);
    }
}
