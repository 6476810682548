import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../common/crud-common/crud.service';
import {Listing} from '../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {Pricelist, PricelistStatus, PricelistTarget} from './pricelist';

@Injectable()
export class PriceListService implements CrudService<Pricelist> {

    private static readonly API_URL_LIST = 'pricelists';
    private static readonly API_URL_SINGLE = 'pricelist';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Pricelist>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(PriceListService.API_URL_LIST, {params: params}).pipe(
            map(response => Listing.fromJSON(Pricelist, response)));
    }

    getItem(itemId: number): Observable<Pricelist> {
        return this.http.get<object>(`${PriceListService.API_URL_SINGLE}/${itemId}`).pipe(
            map(responseData => Pricelist.fromJSON(responseData)));
    }

    addItem(item: Pricelist): Observable<number> {
        return this.http.post<void>(PriceListService.API_URL_SINGLE, item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(PriceListService.API_URL_SINGLE, response)));
    }

    editItem(itemId: number, item: Pricelist): Observable<number> {
        return this.http.put<void>(`${PriceListService.API_URL_SINGLE}/${itemId}`, item).pipe(map(() => itemId));
    }

    getAllPricelists(target: PricelistTarget): Observable<Listing<Pricelist>> {
        let filters = {
            status: {value: [PricelistStatus.ACTIVE]},
            target: {value: target}
        };
        let params = this.dataServiceHelper.prepareSearchParams(undefined, undefined, filters, 'validFrom', 0);
        return this.http.get<Listing<object>>(PriceListService.API_URL_LIST, {params: params}).pipe(
            map(response => Listing.fromJSON(Pricelist, response)));
    }

    getCurrentPricelist(target: PricelistTarget): Observable<Pricelist> {
        return this.http.get<object>(`${PriceListService.API_URL_SINGLE}/currentPricelist/${target}`).pipe(
            map(response => {
                return response != undefined ? Pricelist.fromJSON(response) : undefined;
            }));
    }
}
