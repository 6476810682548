import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Permissions} from '../../../../auth/permission.service';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {DistanceFrameField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {DistanceFrameFieldUsage} from "../../catalog-field-usage";
import {DistanceFrame} from '../distanceFrame';

@Component({
    selector: 'app-distance-frame-form',
    templateUrl: './distance-frame-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistanceFrameFormComponent {

    @Input()
    item: DistanceFrame;

    @Input()
    validationErrors: any;

    @Input()
    types: SelectItem[];

    @Input()
    warmFramesByThickness: Map<number, DistanceFrame[]> = new Map<number, DistanceFrame[]>();

    @Input()
    editPermits: FieldLimitation[] = [];

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: DistanceFrameFieldUsage;
    DistanceFrameField = DistanceFrameField;

    constructor(public permissions: Permissions,
                private translate: TranslateService) {
        this.fieldUsage = new DistanceFrameFieldUsage(this);
    }

    handleThicknessChange(newThickness: number | undefined): void {
        this.item.thickness = newThickness;
        if (!this.warmFramesByThickness.has(newThickness)) {
            this.item.warmAlternativeDistanceFrameId = undefined;
        }
    }

    formatDistanceFrameSelectItem = (distanceFrame: DistanceFrame): SelectItem => {
        return {
            label: distanceFrame.names[this.translate.currentLang],
            value: distanceFrame.id
        };
    }
}
