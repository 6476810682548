import {JsonDeserializable} from '../../../../../common/crud-common/crudItem';
import {PositionMessage} from "../../message";

@JsonDeserializable
export class BulkChange {
    bulkChangeId: number;
    validationResults: { [offerPositionId: number]: string[] };
    messagesByPosition: { [offerPositionId: number]: PositionMessage[] };

    static fromJSON(jsonObject: any): BulkChange {
        const bulkChange = new BulkChange();
        bulkChange.bulkChangeId = jsonObject.bulkChangeId;
        bulkChange.validationResults = jsonObject.validationResults;
        bulkChange.messagesByPosition = jsonObject.messagesByPosition;
        return bulkChange;
    }

    hasValidationErrors(offerPositionId: number): boolean {
        return (this.validationResults[offerPositionId] != undefined
            && this.validationResults[offerPositionId].length !== 0);
    }
}
