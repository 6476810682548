<div *ngIf="showProperties" class="p-datatable-tablewrapper" [ngClass]="{'multiple-positions-table': windowProperties.length > 1}">
    <table class="windowParametersTable" width="100%" height="100%">
        <ng-container *ngIf="windowProperties.length > 1">
            <tr class="properties-header">
                <th>{{'OFFER.POSITIONS.FORM.NAME' | translate}}</th>
                <ng-container *ngIf="isWindowPosition; else gateSystemName">
                    <ng-container *ngFor="let window of windowProperties">
                        <th>{{window.positionName && window.positionName[translate.currentLang]}}</th>
                    </ng-container>
                </ng-container>
                <ng-template #gateSystemName>
                    <ng-container *ngFor="let gate of gateProperties">
                        <th>{{gate.gateSystem && gate.gateSystem[translate.currentLang]}}</th>
                    </ng-container>
                </ng-template>
            </tr>
        </ng-container>

        <ng-container>
            <tr class="properties-header windowParametersTable">
                <th>{{ 'OFFER.TABS.GENERAL' | translate}}</th>
                <ng-container *ngFor="let window of windowProperties">
                    <th></th>
                </ng-container>
            </tr>

            <ng-container *ngFor="let obj of getDataRows() | async">
                <tr [ngClass]="{'property-entry': !obj.subtitle,'properties-header': obj.subtitle}">
                    <td class="property-label">
                        {{ obj.header | translate }}
                    </td>
                    <ng-container *ngFor="let value of obj.values">
                        <td *ngIf="value.innerHTML" class="property-value description-padding" [innerHTML]="value.value"></td>
                        <td *ngIf="!value.innerHTML" class="property-value" [ngClass]="{'variousValues': obj.variousValues}">
                            <ng-container *ngIf="value.toTranslate">{{ ('' + value.value) | translate }}</ng-container>
                            <ng-container *ngIf="!value.toTranslate">{{value.value}}</ng-container>
                        </td>
                    </ng-container>
                </tr>
            </ng-container>
        </ng-container>
    </table>
    <div class="window-properties-spacing-for-upselling-btn"></div>
</div>
