<p-dialog id="exit-confirmation-dialog" [header]="header" [resizable]="false" [modal]="true" [closable]="false"
          [visible]="true" [focusOnShow]="false">
    <div class="scrolled-dialog scrolled-dialog-60vw ral-colors-container">
        <div *ngFor="let color of colors"
             [ngClass]="{'ral-color-block': true, 'ral-color-selected': isColorSelected(color)}">
            <div class="ral-color-button" [ngStyle]="getColorStyle(color)" (click)="selectColor(color)">
                {{ color.names[translate.currentLang] }}
            </div>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="onCancel.emit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               [disabled]="selectedColor == undefined"
                               (onClick)="onColorSelect.emit(selectedColor)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
