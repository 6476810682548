<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(EntranceGlazingPackageField.NAME)"
                            inputId="name" label="{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.NAME' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(EntranceGlazingPackageField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.name"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(EntranceGlazingPackageField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(EntranceGlazingPackageField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <div *ngIf="fieldUsage.show(EntranceGlazingPackageField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(EntranceGlazingPackageField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="100"
                        [(ngModel)]="item.symbol" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(EntranceGlazingPackageField.GLASSES_QUANTITY)">
        <app-input-number inputId="glassesQuantity"
                          label="{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.GLASSES_QUANTITY' | translate }}"
                          [disabled]="fieldUsage.disabled(EntranceGlazingPackageField.GLASSES_QUANTITY)"
                          [(validationMessageKey)]="validationErrors['glassesQuantity']" [min]="1" [max]="9"
                          [required]="true" [(ngModel)]="item.glassesQuantity"></app-input-number>
    </div>
    <div *ngIf="fieldUsage.show(EntranceGlazingPackageField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(EntranceGlazingPackageField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']" [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(EntranceGlazingPackageField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex" label="{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(EntranceGlazingPackageField.SORT_INDEX)"
                          [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1"
                          [max]="99999999999" [step]="1" [(ngModel)]="item.sortIndex"
                          [required]="true"></app-input-number>
    </div>
    <p-accordion *ngIf="fieldUsage.show(EntranceGlazingPackageField.IMAGE_SECTION)">
        <p-accordionTab header="{{ 'ENTRANCE_GLAZING_PACKAGE.FORM.IMAGE_SECTION' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="file" (ngModelChange)="onFileChange($event)"
                                 [disabled]="fieldUsage.disabled(EntranceGlazingPackageField.IMAGE_SECTION)"
                                 [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                 [addButtonLabel]="'ENTRANCE_GLAZING_PACKAGE.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'ENTRANCE_GLAZING_PACKAGE.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'ENTRANCE_GLAZING_PACKAGE.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
