export class CssBuilder {
    private style: string;
    private nodePrefix: string;

    constructor() {
        this.style = "";
        this.nodePrefix = "";
    }

    public setNodePrefix(value: string): CssBuilder {
        this.nodePrefix = value;
        return this;
    }

    public clearNodePrefix(): CssBuilder {
        this.nodePrefix = "";
        return this;
    }

    public openNode(name: string): CssNode {
        let nodeNameWithPrefix = this.nodePrefix == "" ? name : this.nodePrefix + " " + name;
        nodeNameWithPrefix = this.style == "" ? nodeNameWithPrefix : " " + nodeNameWithPrefix;
        return new CssNode(this, nodeNameWithPrefix);
    }

    public build(): string {
        return this.style;
    }

    public addRawString(string: string): CssBuilder {
        this.style += string;
        return this;
    }
}

export class CssNode {
    private builder: CssBuilder;
    private openedNode: string;
    private attrs: string[];
    private buildedNode: string;

    constructor(builder: CssBuilder, openedNode: string) {
        this.builder = builder;
        this.openedNode = openedNode;
        this.attrs = [];
    }

    public addKey(key: string, value: string): CssNode {
        this.attrs.push(key + ": " + value + ";");
        return this;
    }

    public closeNode(): CssBuilder {
        this.buildedNode = this.openedNode + " {";
        let first = true;
        for (let attr of this.attrs) {
            this.buildedNode += first ? attr : " " + attr;
            first = false;
        }
        this.buildedNode += "}";

        return this.builder.addRawString(this.buildedNode);
    }
}
