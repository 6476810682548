<div class="new-form-60vw">
    <div class="new-form-row" *ngIf="permissions.isKoordynator()">
        <p-pickList [source]="availableSubsystems" [target]="selectedSubsystems"
                    sourceHeader="{{ 'DASHBOARD.REPORTS.SETTINGS.SUBSYSTEMS.AVAILABLE' | translate }}"
                    targetHeader="{{ 'DASHBOARD.REPORTS.SETTINGS.SUBSYSTEMS.SELECTED' | translate }}"
                    [responsive]="true" filterBy="name,defaultCurrency,clientManager.login"
                    [sourceStyle]="{'height': '40vh'}" [targetStyle]="{'height': '40vh'}"
                    [showSourceControls]="false" [showTargetControls]="false"
                    (onMoveToSource)="handleMoveToSource($event.items)"
                    (onMoveAllToSource)="handleMoveToSource($event.items)"
                    (onMoveToTarget)="handleMoveToTarget($event.items)"
                    (onMoveAllToTarget)="handleMoveToTarget($event.items)">
            <ng-template let-subsystem pTemplate="item">
                <div class="subsystem-picklist-row">
                    <div class="subsystem-picklist-column-name">{{ subsystem.name }}</div>
                    <div class="subsystem-picklist-column-currency">{{ subsystem.defaultCurrency }}</div>
                    <div class="subsystem-picklist-column-manager">{{ subsystem.clientManager.login }}</div>
                </div>
            </ng-template>
        </p-pickList>
    </div>
</div>
