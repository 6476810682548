<ng-template #defaultLabel>
    <label *ngIf="label" [attr.for]="inputId" class="new-form-checkbox-label">{{ label }}</label>
</ng-template>
<ng-template #defaultValidationMessage>
    <div class="new-form-field-error-message">
        {{ validationMessageKey | translate }}
    </div>
</ng-template>
<div #container class="new-form-field">
    <div class="new-form-checkbox-large-form-padding"></div>
    <div class="new-form-field-input-container">
        <div class="new-form-checkbox-container">
            <label class="input-checkbox">
                <input type="checkbox" [attr.id]="inputId" [disabled]="disabled" [(ngModel)]="value"
                       (focus)="handleFocus($event)" (blur)="handleBlur($event)" (click)="handleClick($event)">
                <span class="checkbox"></span>
            </label>
            <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
        </div>
        <ng-container *ngIf="validationMessageKey != undefined">
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
