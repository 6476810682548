import {HttpErrorResponse} from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Hotkey} from "angular2-hotkeys";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from "primeng/datatable";
import {Observable, of} from "rxjs";
import {map, mergeMap, tap} from "rxjs/operators";
import * as _ from 'underscore';
import {Permissions} from "../../../auth/permission.service";
import {ButtonWithMenuElementSelectedEvent} from '../../../common/button-with-menu/button-with-menu-event';
import {MenuElement, MenuElementBuilder} from '../../../common/button-with-menu/MenuElement';
import {MenuType} from '../../../common/button-with-menu/MenuType';
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {ExchangeService} from "../../../common/exchange.service";
import {GrowlMessageController} from "../../../common/growl-message/growl-message-controller";
import {CrudComponent} from "../../../common/service/crud.component";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {ExportComponent} from "../../../common/service/export.component";
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {TristateCheckboxState} from '../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {SidenavController} from "../../../sidenav-controller";
import {AccessData} from "../../AccessData";
import {ListOfIds} from "../../ListOfIds";
import {PrintableItem} from '../print-dialog/print-dialog.component';
import {PrintableSection} from '../print-dialog/printable-section.enum';
import {ProductionOrderService} from '../production-orders/production-order-service';
import {ProductionOrder} from '../production-orders/ProductionOrder';
import {StatusTransition} from "../status-transition-dialog/StatusTransition";
import {StatusTransitionHelper} from "../status-transition-dialog/StatusTransitionHelper";
import {DeliveryList} from "./delivery-list";
import {DeliveryListStatusTransitionService} from "./delivery-list-status-transition.service";
import {DeliveryListService} from "./delivery-list.service";
import {Palette} from './palette';
import {PaletteAdditionalElement} from './palette-additional-element';
import {PaletteAdditionalElementService} from './palette-additional-element.service';
import {PaletteAdditionalElementsTableData} from './palette-additional-elements-table.component/palette-additional-elements-table-data';
import {
    PaletteAdditionalElementsTableComponent
} from './palette-additional-elements-table.component/palette-additional-elements-table.component';
import {PaletteTableData} from "./palette-list-table/palette-table-data";
import {PaletteTableComponent} from './palette-list-table/palette-table.component';
import {PaletteWizardMode} from "./palette-wizard/palette-wizard-mode";
import {PaletteService} from "./palette-wizard/palette.service";

@Component({
    selector: 'app-delivery-list',
    templateUrl: './delivery-list.component.html',
    styleUrls: ['../../shared-styles.css', './delivery-list.component.css'],
    providers: [DeliveryListService, DeliveryListStatusTransitionService, PaletteService, ExportComponent,
        ProductionOrderService, PaletteAdditionalElementService, ExchangeService /*for print dialog*/],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryListComponent extends CrudComponent implements OnInit, OnDestroy {

    public static readonly SAVE_PALETTE_ADDITIONAL_ELEMENT_BLOCK_ID = 'savePaletteAdditionalElement';
    private static readonly SORTING_SETTINGS_KEY: string = "sorting";

    @ViewChild('toolbar', {read: TemplateRef, static: true})
    toolbar: TemplateRef<any>;

    @ViewChildren('paletteTable')
    paletteTables: QueryList<PaletteTableComponent>;

    @ViewChildren('paletteAdditionalElementsTable')
    paletteAdditionalElementTables: QueryList<PaletteAdditionalElementsTableComponent>;

    private activatedRoute: ActivatedRoute;
    private deliveryListService: DeliveryListService;
    private productionOrderService: ProductionOrderService;
    private deliveryListStatusTransitionService: DeliveryListStatusTransitionService;
    private errors: CommonErrorHandler;
    private fullscreenControllers: SidenavController;
    private growls: GrowlMessageController;
    private paletteService: PaletteService;
    private paletteAdditionalElementService: PaletteAdditionalElementService;
    private permissions: Permissions;
    private router: Router;

    addPaletteHotkey;
    deleteSelectedHotkey;
    editDeliveryListHotkey;

    canEdit = false;
    deliveryList: DeliveryList;
    id: number;
    PaletteWizardMode = PaletteWizardMode;
    possibleTransitions: StatusTransition[] = [];
    statusTransitionInfoDialogVisible = false;
    tableDataById: { [id: number]: PaletteTableData };
    paletteAdditionalElementsTableDataById: { [id: number]: PaletteAdditionalElementsTableData };

    printDialogVisible = false;
    PrintableSection = PrintableSection;
    printableItems: PrintableItem[] = [];
    selectedProdOrdersIds: number[] = [];

    showDialogToAddPaletteAdditionalElement = false;
    paletteAdditionalElementPaletteId: number;
    paletteAdditionalElementDescription: string;
    validationErrors: { [field: string]: string } = {};

    selectedSorting: MenuElement;
    availableSorting: MenuElement[] = [];
    menuType = MenuType;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'DeliveryListComponent', false);
        this.activatedRoute = injector.get(ActivatedRoute);
        this.deliveryListService = injector.get(DeliveryListService);
        this.productionOrderService = injector.get(ProductionOrderService);
        this.deliveryListStatusTransitionService = injector.get(DeliveryListStatusTransitionService);
        this.errors = injector.get(CommonErrorHandler);
        this.fullscreenControllers = injector.get(SidenavController);
        this.growls = injector.get(GrowlMessageController);
        this.paletteService = injector.get(PaletteService);
        this.paletteAdditionalElementService = injector.get(PaletteAdditionalElementService);
        this.permissions = injector.get(Permissions);
        this.router = injector.get(Router);

        this.initHotkeys();
        this.initSortingMenu();
    }

    private initSortingMenu() {
        const translationKey = "DELIVERY_LIST.PALETTE_LIST.SORT_BY";
        this.availableSorting = [];
        this.availableSorting.push(new MenuElementBuilder().setTranslationKey(translationKey + ".NAME_DESC").setIdentifier("name_desc").build());
        this.availableSorting.push(new MenuElementBuilder().setTranslationKey(translationKey + ".NAME_ASC").setIdentifier("name_asc").build());
        this.availableSorting.push(new MenuElementBuilder().setTranslationKey(translationKey + ".CREATION_DATE_DESC").setIdentifier("creationDate_desc").build());
        this.availableSorting.push(new MenuElementBuilder().setTranslationKey(translationKey + ".CREATION_DATE_ASC").setIdentifier("creationDate_asc").build());
    }

    ngOnInit() {
        this.fullscreenControllers.replaceContent(this.toolbar);

        let userUiConfigForThisView = this.getUserUiConfigForThisView(DeliveryListComponent.SORTING_SETTINGS_KEY);
        this.selectedSorting = this.availableSorting.find(item => item.identifier === userUiConfigForThisView) || this.availableSorting[0];

        this.activatedRoute.params.pipe(mergeMap(params => {
            this.id = +params['id'];
            return this.deliveryListService.getItem(this.id, this.selectedSorting.identifier);
        })).subscribe({
            next: deliveryList => {
                this.deliveryList = deliveryList;
                this.createTable();
                this.initShownColumns();
                this.initTableData();
                this.fillPossibleTransitions();
                this.hideDataLoadingIndicator();
                this.canEdit = this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
                this.registerHotkeys();
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.navigateBack();
                this.errors.handle(error);
            }
        });
    }

    ngOnDestroy() {
        this.removeHotkeys();
        this.fullscreenControllers.replaceContent(undefined);
        super.ngOnDestroy();
    }

    private initHotkeys(): void {
        this.addPaletteHotkey = new Hotkey('alt+a', () => {
            this.addPalette();
            return false;
        }, undefined, 'DELIVERY_LIST.ADD_PALETTE');
        this.deleteSelectedHotkey = new Hotkey('del', (): boolean => {
            this.deleteSelected();
            return false;
        }, undefined, 'DELIVERY_LIST.DELETE_SELECTED');
        this.editDeliveryListHotkey = new Hotkey('alt+e', (): boolean => {
            this.editDeliveryList();
            return false;
        }, undefined, 'DELIVERY_LIST.EDIT_DELIVERY_LIST');
    }

    private registerHotkeys(): void {
        this.removeHotkeys();
        if (this.canEdit) {
            this.hotkeysService.add(this.addPaletteHotkey);
            this.hotkeysService.add(this.deleteSelectedHotkey);
            this.hotkeysService.add(this.editDeliveryListHotkey);
        }
    }

    private removeHotkeys(): void {
        this.hotkeysService.remove(this.addPaletteHotkey);
        this.hotkeysService.remove(this.deleteSelectedHotkey);
        this.hotkeysService.remove(this.editDeliveryListHotkey);
    }

    reloadDeliveryList(): void {
        this.deliveryListService.getItem(this.id, this.selectedSorting.identifier).subscribe({
            next: data => {
                this.deliveryList = data;
                this.initTableData();
                this.fillPossibleTransitions();
                this.registerHotkeys();
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    getDatatable(): DataTable {
        return undefined;
    }

    getExportData(): Observable<object[]> {
        return undefined;
    }

    onRowSelect(event): void {}

    showDialogToAdd(): void {}

    submit() {}

    private createTable() {
        let shownColumns = this.getShownColumns();
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('offerNumbers', 'DELIVERY_LIST.PALETTE_LIST.OFFER_NUMBER', true).makeFilterable()
            .add('orderNumber', 'DELIVERY_LIST.PALETTE_LIST.PRODUCTION_ORDER_NUMBER', true).makeFilterable().makeSortable()
            .add('creationDate', 'DELIVERY_LIST.PALETTE_LIST.CREATION_DATE', true).makeFilterable().makeSortable();
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('supplierCompanyName', 'DELIVERY_LIST.PALETTE_LIST.SUPPLIER', true).makeFilterable().makeSortable();
        } else {
            builder.add('supplierName', 'DELIVERY_LIST.PALETTE_LIST.SUPPLIER', true).makeFilterable().makeSortable();
        }
        builder.add('clientManagerName', 'DELIVERY_LIST.PALETTE_LIST.MANAGER_NAME', true).makeFilterable().makeSortable();
        super.init(builder.build());
    }

    initTableData(): void {
        this.tableDataById = {};
        this.paletteAdditionalElementsTableDataById = {};

        for (let palette of this.deliveryList.palettes) {
            this.tableDataById[palette.id] = new PaletteTableData([]);
            this.paletteAdditionalElementsTableDataById[palette.id] = new PaletteAdditionalElementsTableData([]);
        }
    }

    addPalette(): void {
        this.router.navigate([AccessData.path.paletteWizard(), {deliveryListId: this.deliveryList.id}]);
    }

    editDeliveryList(): void {
        this.router.navigate([AccessData.path.deliveryListWizard(), {id: this.deliveryList.id}]);
    }

    navigateBack(): void {
        this.router.navigate([AccessData.path.offer(), {component: 'delivery-lists'}]);
    }

    navigateToProductionOrder(id: string): void {
        this.router.navigate([AccessData.path.productionOrderPosition(id)]);
    }

    fillPossibleTransitions(): void {
        this.possibleTransitions = this.deliveryListStatusTransitionService.createPossibleTransitions(this.deliveryList,
            (): void => { this.onChangeStatus(); },
            (actionName, error): void => { this.onChangeStatus(error); });
    }

    private onChangeStatus(error?: HttpErrorResponse): void {
        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
        if (error != null) {
            this.errors.handle(error);
        } else {
            this.growls.info('DELIVERY_LIST.CHANGE_STATUS_SUCCESS');
            this.reloadDeliveryList();
        }
    }

    statusTransitionButtonClick(): void {
        if (this.possibleTransitions.length === 1) {
            this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
            this.possibleTransitions[0].item.command();
        }
    }

    preventEvent(event): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    editPalette(event, id: number, mode: PaletteWizardMode): void {
        this.preventEvent(event);
        this.router.navigate(
            [AccessData.path.paletteWizard(), {id: id, deliveryListId: this.deliveryList.id, mode: mode}]);
    }

    canDeletePalette(palette: Palette): boolean {
        return palette.productionOrderIds.length === 0 && palette.paletteAdditionalElementsIds.length === 0;
    }

    deletePalette(palette: Palette): void {
        this.paletteService.deletePalette(palette.id).subscribe(() => {
            this.deliveryList.palettes = this.deliveryList.palettes.filter(p => p !== palette);
            this.changeDetector.markForCheck();
        });
    }

    showTransitionInfo(event: MouseEvent): void {
        this.preventEvent(event);
        this.statusTransitionInfoDialogVisible = true;
        this.changeDetector.markForCheck();
    }

    hideStatusTransitionDialog(): void {
        this.statusTransitionInfoDialogVisible = false;
        this.changeDetector.markForCheck();
    }

    getStatusTransitionInfoLabel(): string {
        return 'DELIVERY_LIST.STATUS_DESCRIPTION.' + this.possibleTransitions[0].item.id;
    }

    isDeleteButtonEnabled() {
        return this.canEdit && (this.getSelectedProductionOrdersIds().length > 0 || this.getSelectedPaletteAdditionalElementsIds().length > 0);
    }

    deleteSelected() {
        if (this.getSelectedProductionOrdersIds().length > 0) {
            this.deliveryListService.detach(this.id, new ListOfIds(this.getSelectedProductionOrdersIds())).subscribe({
                next: () => {
                    this.reloadDeliveryList();
                },
                error: error => {
                    this.errors.handle(error);
                }
            });
        }
        if (this.getSelectedPaletteAdditionalElementsIds().length > 0) {
            this.paletteAdditionalElementService.delete(new ListOfIds(this.getSelectedPaletteAdditionalElementsIds())).subscribe({
                next: () => {
                    this.reloadDeliveryList();
                },
                error: error => {
                    this.errors.handle(error);
                }
            });
        }
    }

    getSelectedProductionOrdersIds(): number[] {
        return _.flatten(this.deliveryList.palettes.map(p => this.tableDataById[p.id].selectedItems.map(i => i.id)));
    }

    getSelectedPaletteAdditionalElementsIds(): number[] {
        return _.flatten(this.deliveryList.palettes.map(p => this.paletteAdditionalElementsTableDataById[p.id].selectedItems.map(i => i.id)));
    }

    filterEnabledForPalette(paletteId) {
        return this.filterEnabledFor(this.tableDataById[paletteId]);
    }

    private filterEnabledFor(tableData: PaletteTableData) {
        if (tableData) {
            return tableData.showFilters;
        }
        return false;
    }

    showTableFilters(event, paletteId: number) {
        this.showFiltersFor(event, this.tableDataById[paletteId]);
        this.reloadTableForPalette(paletteId);
        this.changeDetector.markForCheck();
    }

    private showFiltersFor(event, tableData: PaletteTableData) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        tableData.showFilters = !tableData.showFilters;
    }

    reloadTableForPalette(paletteId: number): void {
        let tableComponent = this.getTableForPalette(paletteId);
        if (tableComponent != undefined) {
            this.loadItemsForPalette(tableComponent.getDataTable().createLazyLoadMetadata(), paletteId, true);
        }
    }

    getTableForPalette(paletteId: number): PaletteTableComponent {
        let tables = this.paletteTables.toArray();
        let paletteListTable = tables.find(table => table.getPalette().id === paletteId);
        if (paletteListTable != undefined) {
            return paletteListTable;
        }
        return undefined;
    }

    loadItemsForPalette(event: LazyLoadEvent, paletteId: number, clearRowCache: boolean): void {
        if (event == undefined) {
            event = this.getTableForPalette(paletteId).getDataTable().createLazyLoadMetadata();
        }
        event.filters['paletteId'] = {value: paletteId.toString()};
        this.loadTableItems(event,
            () => this.tableDataById[paletteId],
            () => this.getTableForPalette(paletteId),
            clearRowCache);
    }

    private loadTableItems(event: LazyLoadEvent,
                           getTableData: () => PaletteTableData,
                           getTableComponent: () => PaletteTableComponent,
                           clearRowCache: boolean) {
        getTableData().lastLoadEvent = event;
        getTableData().allSelectedState = TristateCheckboxState.UNCHECKED;
        getTableData().selectedItems = [];

        return this.productionOrderService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .subscribe({
                next: data => {
                    let productionOrders = data.data as ProductionOrder[];
                    this.initTableItems(event, getTableData, productionOrders, data);
                },
                error: error => {
                    this.errors.handle(error);
                    getTableComponent().refreshTable(false);
                },
                complete: () => {
                    console.info('PaletteTableComponent `getPage` completed!');
                    getTableComponent().refreshTable(clearRowCache);
                }
            });
    }

    private initTableItems(event: LazyLoadEvent,
                           getTableData: () => PaletteTableData,
                           productionOrders: ProductionOrder[],
                           data: any): void {

        let tableData = getTableData();
        tableData.productionOrders = productionOrders;

        tableData.totalRecords = data.totalRecords;
        tableData.fromRecord = Math.min(event.first + 1, tableData.totalRecords);
        tableData.toRecord = Math.min(event.first + event.rows, tableData.totalRecords);
    }

    filterEnabledForPaletteAdditionalElements(paletteId) {
        return this.filterEnabledForPaletteAdditionalElementsTable(this.paletteAdditionalElementsTableDataById[paletteId]);
    }

    private filterEnabledForPaletteAdditionalElementsTable(tableData: PaletteAdditionalElementsTableData) {
        if (tableData) {
            return tableData.showFilters;
        }
        return false;
    }

    showAdditionalElementsTableFilters(event, paletteId: number) {
        this.showAdditionalElementsFiltersFor(event, this.paletteAdditionalElementsTableDataById[paletteId]);
        this.reloadAdditionalElementsTableForPalette(paletteId);
        this.changeDetector.markForCheck();
    }

    private showAdditionalElementsFiltersFor(event, tableData: PaletteAdditionalElementsTableData) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
        tableData.showFilters = !tableData.showFilters;
    }

    reloadAdditionalElementsTableForPalette(paletteId: number): void {
        let tableComponent = this.getAdditionalElementsTableForPalette(paletteId);
        if (tableComponent != undefined) {
            this.loadAdditionalElementsForPalette(tableComponent.getDataTable().createLazyLoadMetadata(), paletteId, true);
        }
    }

    getAdditionalElementsTableForPalette(paletteId: number): PaletteAdditionalElementsTableComponent {
        let tables = this.paletteAdditionalElementTables.toArray();
        let paletteAdditionalElementsListTable = tables.find(table => table.getPalette().id === paletteId);
        if (paletteAdditionalElementsListTable != undefined) {
            return paletteAdditionalElementsListTable;
        }
        return undefined;
    }

    loadAdditionalElementsForPalette(event: LazyLoadEvent, paletteId: number, clearRowCache: boolean): void {
        if (event == undefined) {
            event = this.getAdditionalElementsTableForPalette(paletteId).getDataTable().createLazyLoadMetadata();
        }
        event.filters['paletteId'] = {value: paletteId.toString()};

        this.loadPaletteAdditionalElementsTableItems(event,
            () => this.paletteAdditionalElementsTableDataById[paletteId],
            () => this.getAdditionalElementsTableForPalette(paletteId),
            clearRowCache);
    }

    private loadPaletteAdditionalElementsTableItems(event: LazyLoadEvent,
                           getTableData: () => PaletteAdditionalElementsTableData,
                           getTableComponent: () => PaletteAdditionalElementsTableComponent,
                           clearRowCache: boolean) {
        getTableData().lastLoadEvent = event;
        getTableData().allSelectedState = TristateCheckboxState.UNCHECKED;
        getTableData().selectedItems = [];

        return this.paletteAdditionalElementService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .subscribe({
                next: data => {
                    let paletteAdditionalElements = data.data as PaletteAdditionalElement[];
                    this.initPaletteAdditionalElementsTableItems(event, getTableData, paletteAdditionalElements, data);
                },
                error: error => {
                    this.errors.handle(error);
                    getTableComponent().refreshTable(false);
                },
                complete: () => {
                    console.info('PaletteAdditionalElementsTableComponent `getPage` completed!');
                    getTableComponent().refreshTable(clearRowCache);
                }
            });
    }

    private initPaletteAdditionalElementsTableItems(event: LazyLoadEvent,
                           getTableData: () => PaletteAdditionalElementsTableData,
                           paletteAdditionalElements: PaletteAdditionalElement[],
                           data: any): void {

        let tableData = getTableData();
        tableData.paletteAdditionalElements = paletteAdditionalElements;

        tableData.totalRecords = data.totalRecords;
        tableData.fromRecord = Math.min(event.first + 1, tableData.totalRecords);
        tableData.toRecord = Math.min(event.first + event.rows, tableData.totalRecords);
    }

    public openPrintDialog() {
        this.printableItems = [new PrintableItem(this.deliveryList.id, this.deliveryList.name)];
        this.selectedProdOrdersIds = this.getSelectedProductionOrdersIds();
        this.printDialogVisible = true;
        this.changeDetector.markForCheck();
    }

    addPaletteAdditionalElement(event, paletteId: number) {
        this.preventEvent(event);
        this.showDialogToAddPaletteAdditionalElement = true;
        this.paletteAdditionalElementPaletteId = paletteId;
    }

    closeDialog(): void {
        this.showDialogToAddPaletteAdditionalElement = false;
        this.paletteAdditionalElementPaletteId = undefined;
        this.paletteAdditionalElementDescription = undefined;
        this.validationErrors = {};
    }

    savePaletteAdditionalElement(): void {
        let paletteAdditionalElement = new PaletteAdditionalElement(this.paletteAdditionalElementDescription, this.paletteAdditionalElementPaletteId);
        this.validateForm(paletteAdditionalElement);

        if (!this.validationErrorsPresent()) {
            this.blockUiController.block(DeliveryListComponent.SAVE_PALETTE_ADDITIONAL_ELEMENT_BLOCK_ID);

            this.paletteAdditionalElementService.save(paletteAdditionalElement).subscribe({
                next: () => {
                    this.blockUiController.unblock(DeliveryListComponent.SAVE_PALETTE_ADDITIONAL_ELEMENT_BLOCK_ID);
                    if (this.paletteAdditionalElementsTableDataById[paletteAdditionalElement.paletteId].paletteAdditionalElements.length > 1) {
                        this.reloadAdditionalElementsTableForPalette(paletteAdditionalElement.paletteId);
                    } else {
                        this.reloadDeliveryList();
                    }
                    this.closeDialog();
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.closeDialog();
                    this.blockUiController.unblock(DeliveryListComponent.SAVE_PALETTE_ADDITIONAL_ELEMENT_BLOCK_ID);
                }
            });
        }
    }

    validateForm(paletteAdditionalElement: PaletteAdditionalElement): Observable<boolean> {
        this.validationErrors = {};
        if (this.paletteAdditionalElementDescription == undefined || this.paletteAdditionalElementDescription.trim() === "") {
            this.validationErrors['description'] = 'error.paletteAdditionalElement.description.not_empty';
        }
        if (ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            return of(false);
        }

        return this.paletteAdditionalElementService.validate(paletteAdditionalElement).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    showPaletteAdditionalElementsTable(palette: Palette): boolean {
        return palette.paletteAdditionalElementsIds.length > 0 ||
            this.paletteAdditionalElementsTableDataById[palette.id].showFilters;
    }

    handleSortMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.selectedSorting = this.availableSorting.find(item => item.identifier === event.identifier);
        this.saveUserUiConfigForThisView(DeliveryListComponent.SORTING_SETTINGS_KEY, event.identifier);
        this.reloadDeliveryList();
    }
}
