import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {WebshopCustomPage} from './webshop-custom-page';

@Injectable()
export class WebshopCustomPageService implements CrudService<WebshopCustomPage> {

    private static readonly API_URL = 'webshopCustomPage';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WebshopCustomPage>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(WebshopCustomPageService.API_URL, {params: params})
            .pipe(mapListingToJson(WebshopCustomPage));
    }

    getItem(itemId: number): Observable<WebshopCustomPage> {
        return this.http.get<object>(`${WebshopCustomPageService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(WebshopCustomPage));
    }

    addItem(item: WebshopCustomPage): Observable<number> {
        return this.http.post<void>(WebshopCustomPageService.API_URL, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(WebshopCustomPageService.API_URL));
    }

    editItem(itemId: number, item: WebshopCustomPage): Observable<number> {
        return this.http.put<void>(`${WebshopCustomPageService.API_URL}/${itemId}`, item)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }
}
