<div class="flex-row-container">
    <div *ngIf="showMenu" class="second-level-menu">
        <div class="title">
            <div class="text">{{ 'NAVIGATION.ITEMS.PRICE-LISTS.MENU' | translate }}</div>
        </div>

        <div *ngFor="let item of sideNavItems" class="navigation-element"
             [class.active]="isActiveSubComponent(item.cssClass)" (click)="navigateToSubComponent(item.cssClass)">
            <div class="name">{{item.name | translate}}</div>
        </div>
    </div>
    <div class="content" [ngClass]="{'second-level-menu-hidden': !showMenu}">
        <app-price-lists-definition *ngIf="isActiveSubComponent('pricelists_windows')" [target]="targets.WINDOWS"></app-price-lists-definition>
        <app-price-lists-definition *ngIf="isActiveSubComponent('pricelists_configs')" [target]="targets.CONFIGS"></app-price-lists-definition>
        <app-pricesheets *ngIf="isActiveSubComponent('pricesheets')"></app-pricesheets>
        <app-price-table *ngIf="isActiveSubComponent('pricetables_windows')"></app-price-table>
        <app-price-table-configs *ngIf="isActiveSubComponent('pricetables_configs')"></app-price-table-configs>
        <app-shipping-pricings *ngIf="isActiveSubComponent('shipping-pricetables')"></app-shipping-pricings>
        <app-dealer-discount-per-system
                *ngIf="isActiveSubComponent('dealer-discounts-per-system')"></app-dealer-discount-per-system>
        <app-dealer-discount-per-supplier
                *ngIf="isActiveSubComponent('dealer-discounts-per-supplier')"></app-dealer-discount-per-supplier>
        <app-dealer-discount-per-configurable-addon
                *ngIf="isActiveSubComponent('dealer-discounts-per-configurable-addon')"></app-dealer-discount-per-configurable-addon>
        <app-rot-settings *ngIf="isActiveSubComponent('rotSettings')"></app-rot-settings>
    </div>
</div>
