import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DecorativeFillingType} from "../../../../../window-designer/catalog-data/decorative-filling-type";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {DecorativeFillingField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {DecorativeFillingFieldUsage} from "../../catalog-field-usage";
import {DecorativeFilling} from "../decorativeFilling";

@Component({
    selector: 'app-decorative-filling-form',
    templateUrl: './decorative-filling-form.component.html',
    styleUrls: ['./decorative-filling-form.component.css', '../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DecorativeFillingFormComponent {

    public readonly INPUT_NUMBER_DEFAULT_MAX_VALUE = 999999;
    public readonly INPUT_NUMBER_DEFAULT_MIN_VALUE = 0;

    @Input()
    item: DecorativeFilling;

    @Input()
    availableInsideColors: SelectItem[];

    @Input()
    availableOutsideColors: SelectItem[];

    @Input()
    availableCoreColors: SelectItem[];

    @Input()
    validationErrors: any;

    @Input()
    file: File;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly fileChange = new EventEmitter<File>();

    availableTypes: string[] = [];

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: DecorativeFillingFieldUsage;
    DecorativeFillingField = DecorativeFillingField;

    constructor() {
        for (let type in DecorativeFillingType) {
            this.availableTypes.push(type);
        }
        this.fieldUsage = new DecorativeFillingFieldUsage(this)
    }

    getTypeIconUrl(typeName: string): string {
        return "assets/images/window-designer/decorative-filling-types/" + typeName + ".png";
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
