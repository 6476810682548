import {EventEmitter, Injectable} from '@angular/core';
import {Observable, SubscriptionLike, timer} from 'rxjs';
import {takeWhile} from 'rxjs/operators';
import {environment} from '../../environments/environment';

@Injectable()
export class BlockUiController {

    public visibilityChange: Observable<boolean>;
    private visibilityState: EventEmitter<boolean>;

    private blockSources: Set<string>;
    private lastState = false;
    private debugLogger: SubscriptionLike;

    constructor() {
        this.visibilityState = new EventEmitter<boolean>(true);
        this.visibilityChange = this.visibilityState.asObservable();
        this.blockSources = new Set<string>();
    }

    public block(source: string): void {
        this.blockSources.add(source);
        this.emitState();
    }

    public unblock(source: string): void {
        this.blockSources.delete(source);
        this.emitState();
    }

    public unblockAll(): void {
        this.blockSources = new Set<string>();
        this.emitState();
    }

    public isBlocked(): boolean {
        return this.blockSources.size > 0;
    }

    private emitState(): void {
        if (this.lastState !== this.isBlocked()) {
            this.lastState = this.isBlocked();
            this.visibilityState.next(this.isBlocked());
            if (!environment.production) {
                if (this.isBlocked()) {
                    this.debugLogger = timer(5000, 2000).pipe(
                        takeWhile(counter => counter < 5 && this.isBlocked()))
                        .subscribe(() => {
                            console.log('BlockUI - Possible missing unblock call, remaining blocks', this.blockSources);
                        });
                } else if (this.debugLogger != undefined) {
                    this.debugLogger.unsubscribe();
                }
            }
        }
    }
}

let blockUiControllerInstance: BlockUiController = undefined;

export function blockUiControllerFactory(): BlockUiController {
    if (blockUiControllerInstance == undefined) {
        blockUiControllerInstance = new BlockUiController();
    }
    return blockUiControllerInstance;
}
