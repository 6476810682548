import {Routes} from "@angular/router";
import {AuthGuard} from "../auth/auth.guard";
import {ApplicationInfoComponent} from "./application-info.component";

export const applicationInfoRoutes: Routes = [
    {
        path: 'application-info',
        component: ApplicationInfoComponent,
        canActivate: [AuthGuard]
    }
];
