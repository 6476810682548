import {PositionReferenceType} from "./PositionReferenceType";

export class RelativePosition {
    type: PositionReferenceType;
    id: number;
    percent: number;

    constructor(type: PositionReferenceType,
                id: number,
                percent: number) {
        this.type = type;
        this.id = id;
        this.percent = percent;
    }
}
