import {KeyValue} from '@angular/common';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, TrackByFunction} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {Observable} from 'rxjs';
import {AddonCategoryEnum} from '../../../../../../window-designer/enums/AddonCategoryEnum';
import {Permissions} from '../../../../../auth/permission.service';
import {MultilanguageFieldSelectItem} from '../../../../../common/service/select-item-multilanguage-field-translate.pipe';
import {TranslatedSelectItemService} from '../../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../../../common/ValidationErrorsHelper';
import {CatalogChargeProfitMarginType} from '../../../../subsystem/catalog-charge-profit-margin/catalog-charge-profit-margin';
import {CatalogItemType} from '../../../window-editor/sidebar/pricing/PricingItem';
import {DealerDiscounts, PricingComponentOverrides, ProfitMargins} from './detailed-pricing-simulation-definitions';

@Component({
    selector: 'app-detailed-pricing-simulation-parameters-dialog',
    templateUrl: './detailed-pricing-simulation-parameters-dialog.component.html',
    styleUrls: ['./detailed-pricing-simulation-parameters-dialog.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DetailedPricingSimulationParametersDialogComponent implements OnInit {

    @Input()
    isSellerOffer: boolean;

    @Input()
    supplierNames: SelectItem[];

    @Input()
    windowSystems: MultilanguageFieldSelectItem[];

    @Input()
    gateSystems: MultilanguageFieldSelectItem[];

    @Input()
    configSystems: MultilanguageFieldSelectItem[];

    @Input()
    hasBulkAddons: boolean;

    @Input()
    overrides: PricingComponentOverrides;

    @Output()
    readonly onHide = new EventEmitter<void>();

    validationErrors: ValidationErrors = {};

    visible = true;
    windowSystem: number;
    gateSystem: number;
    configSystem: number;
    configurableAddonBulk = true;
    supplier: number;
    addonCategory: AddonCategoryEnum;
    catalogItemType: CatalogItemType;

    readonly addonCategories: Observable<SelectItem[]>;
    readonly catalogItemTypes: Observable<SelectItem[]>;

    constructor(private readonly permissions: Permissions,
                translatedSelectItemService: TranslatedSelectItemService) {
        this.addonCategories = translatedSelectItemService.buildSortedDropdown(AddonCategoryEnum, 'ADDONS.CATEGORIES.', undefined);
        this.catalogItemTypes = translatedSelectItemService.buildSortedDropdown(Object.keys(CatalogItemType)
            .filter(key => CatalogChargeProfitMarginType[key] != undefined), 'CATALOG_CHARGE_PROFIT_MARGIN.TYPES.', undefined);
    }

    ngOnInit(): void {
        if (!this.canViewSubsystemPrices) {
            this.configurableAddonBulk = false;
        }
    }

    get canViewVenskaPrices(): boolean {
        return this.permissions.isKoordynator() || this.permissions.isOpiekun();
    }

    get canViewSubsystemPrices(): boolean {
        return this.permissions.isKoordynator() || this.permissions.isOpiekun();
    }

    get canViewClientPrices(): boolean {
        return !this.permissions.isSprzedawca();
    }

    get visibleProfitMarginColumns(): number {
        let columns = 0;
        if (this.isSellerOffer) {
            columns += 2; // retail
        }
        if (this.canViewSubsystemPrices) {
            columns += 2; // subsystem grouo, subsystem
        }
        if (this.canViewClientPrices) {
            columns += 2; // client group, client
        }
        return columns;
    }

    hasDealerDiscountOverrides(): boolean {
        return this.overrides.supplierDealerDiscounts.size > 0
            || this.overrides.windowSystemDealerDiscounts.size > 0
            || this.overrides.gateSystemDealerDiscounts.size > 0
            || this.overrides.configSystemDealerDiscounts.size > 0;
    }

    hasProfitMarginOverrides(): boolean {
        return this.overrides.windowSystemProfitMargins.size > 0
            || this.overrides.gateSystemProfitMargins.size > 0
            || this.overrides.configSystemProfitMargins.size > 0
            || this.overrides.windowAddonProfitMargins.size > 0
            || this.overrides.bulkAddonProfitMargins != undefined
            || this.overrides.catalogChargeSubsystemProfitMargins.size > 0;
    }

    addWindowSystemDealerDiscount(windowSystemId: number): void {
        this.overrides.windowSystemDealerDiscounts.set(windowSystemId, new DealerDiscounts());
    }

    deleteWindowSystemDealerDiscount = (windowSystemId: number): void => {
        this.overrides.windowSystemDealerDiscounts.delete(windowSystemId);
    }

    addWindowSystemProfitMargin(windowSystemId: number): void {
        this.overrides.windowSystemProfitMargins.set(windowSystemId, new ProfitMargins());
    }

    deleteWindowSystemProfitMargin = (windowSystemId: number): void => {
        this.overrides.windowSystemProfitMargins.delete(windowSystemId);
    }

    addGateSystemDealerDiscount(gateSystemId: number): void {
        this.overrides.gateSystemDealerDiscounts.set(gateSystemId, new DealerDiscounts());
    }

    deleteGateSystemDealerDiscount = (gateSystemId: number): void => {
        this.overrides.gateSystemDealerDiscounts.delete(gateSystemId);
    }

    addGateSystemProfitMargin(gateSystemId: number): void {
        this.overrides.gateSystemProfitMargins.set(gateSystemId, new ProfitMargins());
    }

    deleteGateSystemProfitMargin = (gateSystemId: number): void => {
        this.overrides.gateSystemProfitMargins.delete(gateSystemId);
    }

    addConfigSystemDealerDiscount(configSystemId: number): void {
        this.overrides.configSystemDealerDiscounts.set(configSystemId, new DealerDiscounts());
    }

    deleteConfigSystemDealerDiscount = (configSystemId: number): void => {
        this.overrides.configSystemDealerDiscounts.delete(configSystemId);
    }

    hasConfigSystemProfitMargin(configSystemId: number, bulk: boolean): boolean {
        return this.overrides.configSystemProfitMargins.has(`${configSystemId}_${bulk}`);
    }

    addConfigSystemProfitMargin(configSystemId: number, bulk: boolean): void {
        this.overrides.configSystemProfitMargins.set(`${configSystemId}_${bulk}`, new ProfitMargins());
    }

    deleteConfigSystemProfitMargin = (key: string): void => {
        this.overrides.configSystemProfitMargins.delete(key);
    }

    addSupplierDealerDiscount(supplier: number): void {
        this.overrides.supplierDealerDiscounts.set(supplier, new DealerDiscounts());
    }

    deleteSupplierDealerDiscount = (supplier: number): void => {
        this.overrides.supplierDealerDiscounts.delete(supplier);
    }

    addWindowAddonProfitMargin(addonCategory: AddonCategoryEnum): void {
        this.overrides.windowAddonProfitMargins.set(addonCategory, new ProfitMargins());
    }

    deleteWindowAddonProfitMargin = (addonCategory: AddonCategoryEnum): void => {
        this.overrides.windowAddonProfitMargins.delete(addonCategory);
    }

    setBulkAddonProfitMargin(): void {
        this.overrides.bulkAddonProfitMargins = new ProfitMargins();
    }

    deleteBulkAddonProfitMargin = (): void => {
        this.overrides.bulkAddonProfitMargins = undefined;
    }

    setCatalogChargeProfitMargin(catalogItemType: string, value: number): void {
        this.overrides.catalogChargeSubsystemProfitMargins.set(CatalogItemType[catalogItemType], value);
    }

    deleteCatalogChargeProfitMargin(catalogItemType: string): void {
        this.overrides.catalogChargeSubsystemProfitMargins.delete(CatalogItemType[catalogItemType]);
    }

    trackCatalogCharge: TrackByFunction<KeyValue<string, number>> = (index: number, item: KeyValue<string, number>) => {
        return item.key;
    }

    findLabelByValue(items: SelectItem[], value: any): string {
        const selectItem = items.find(item => item.value === value);
        return selectItem != undefined ? selectItem.label : `${value}`;
    }

    getConfigSystemLabel(key: string, bulkLabel: string): string {
        const keyParts = key.split('_');
        const configSystemId = +keyParts[0];
        const bulk = keyParts[1] === 'true';
        const labelParts = [this.findLabelByValue(this.configSystems, configSystemId)];
        if (bulk) {
            labelParts.push(`(${bulkLabel})`);
        }
        return labelParts.join(' ');
    }

    handleSubmit(dialog: Dialog, event: Event): void {
        this.validateForm();
        if (ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            return;
        }
        dialog.close(event);
    }

    resetForm(): void {
        this.overrides.windowSystemDealerDiscounts.clear();
        this.overrides.gateSystemDealerDiscounts.clear();
        this.overrides.configSystemDealerDiscounts.clear();
        this.overrides.supplierDealerDiscounts.clear();
        this.overrides.windowSystemProfitMargins.clear();
        this.overrides.gateSystemProfitMargins.clear();
        this.overrides.configSystemProfitMargins.clear();
        this.overrides.windowAddonProfitMargins.clear();
        this.overrides.bulkAddonProfitMargins = undefined;
        this.overrides.catalogChargeSubsystemProfitMargins.clear();
    }

    validateForm(): void {
        this.validationErrors = {};
        const dealerDiscountValuePattern = /^(?: *?-?\d+[,.]?\d? *; *)*(?: *?-?\d+[,.]?\d? *)?$/;
        const isValidDealerDiscountValue = (value: string) => value == undefined || value === '' || dealerDiscountValuePattern.test(value);
        const validateDealerDiscounts = (value: DealerDiscounts, namePrefix: string, id: number) => {
            if (!isValidDealerDiscountValue(value.value.value)) {
                this.validationErrors[namePrefix + 'DealerDiscount_' + id] = 'error.dealerDiscountDto.value.pattern_not_matched';
            }
            if (!isValidDealerDiscountValue(value.subsystemSellValue.value)) {
                this.validationErrors[namePrefix + 'SubsystemSellDealerDiscount_' + id] = 'error.dealerDiscountDto.value.pattern_not_matched';
            }
            if (!isValidDealerDiscountValue(value.subsystemGroupSellValue.value)) {
                this.validationErrors[namePrefix + 'SubsystemGroupSellDealerDiscount_' + id] = 'error.dealerDiscountDto.value.pattern_not_matched';
            }
        };
        this.overrides.windowSystemDealerDiscounts.forEach((value, key) => {
            validateDealerDiscounts(value, 'windowSystem', key);
        });
        this.overrides.gateSystemDealerDiscounts.forEach((value, key) => {
            validateDealerDiscounts(value, 'gateSystem', key);
        });
        this.overrides.configSystemDealerDiscounts.forEach((value, key) => {
            validateDealerDiscounts(value, 'configSystem', key);
        });
        this.overrides.supplierDealerDiscounts.forEach((value, key) => {
            validateDealerDiscounts(value, 'supplier', key);
        });
    }
}
