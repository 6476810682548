import {Injectable} from "@angular/core";
import {Permissions} from "../../../../auth/permission.service";
import {ValidationErrors} from '../../../../common/validation-errors';
import {ComplaintPosition} from "../complaint-position";

@Injectable()
export class ComplaintPositionFormValidator {

    constructor(private permissions: Permissions) {
    }

    validate(complaintPosition: ComplaintPosition): ValidationErrors {
        const validationErrors = {};
        if (!complaintPosition.quantity || complaintPosition.quantity < 0) {
            validationErrors['quantity-' + complaintPosition.offerPositionId] = 'error.complaintPositionDto.quantity.not_in_range';
        }

        if (complaintPosition.complaintTypes.length === 0) {
            validationErrors['complaintType-' + complaintPosition.offerPositionId] = 'error.complaintPositionDto.complaintType.not_null';
        }

        this.validateTranslatedDescription(complaintPosition, validationErrors);
        return validationErrors;
    }

    private isTranslatedDescriptionRequired(): boolean {
        return this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR']});
    }

    private validateTranslatedDescription(complaintPosition: ComplaintPosition, validationErrors: any): void {
        if (this.isTranslatedDescriptionRequired() && !complaintPosition.translatedDescription) {
            validationErrors['translatedDescription-' + complaintPosition.offerPositionId] =
                'error.complaintPositionDto.translatedDescription.not_null';
        }
    }
}
