import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ScopeValidator} from '../../../shared/validator/input-validator';
import {Transport} from './transport';

@Injectable()
export class TransportService implements CrudService<Transport> {

    private static readonly API_URL = 'transport';

    rangeFilterValidator: ScopeValidator;
    private readonly filterValidator: { [filterProperty: string]: (value: any) => boolean };

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        this.rangeFilterValidator = new ScopeValidator();
        this.filterValidator = {
            value: value => this.rangeFilterValidator.isValid(value)
        };
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Transport>> {
        this.transformRangeFilterValues(filters);
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder, this.filterValidator);
        return this.http.get<Listing<object>>(TransportService.API_URL, {params: params}).pipe(
            map(response => Listing.fromJSON(Transport, response)));
    }

    getItem(transportId: number): Observable<Transport> {
        return this.http.get<Transport>(`${TransportService.API_URL}/${transportId}`);
    }

    addItem(transport: Transport): Observable<number> {
        return this.http.post<any>(TransportService.API_URL, transport, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(TransportService.API_URL, response)));
    }

    editItem(transportId: number, transport: Transport): Observable<number> {
        return this.http.put(`${TransportService.API_URL}/${transportId}`, transport).pipe(
            map(() => transportId));
    }

    calculateQuantityForOffer(transportId: number, offerId: number): Observable<number> {
        return this.http.get(`${TransportService.API_URL}/${transportId}/calculateQuantityForOffer/${offerId}`, {responseType: 'text'})
            .pipe(map(response => +response));
    }

    private transformRangeFilterValues(filters: { [filterProperty: string]: FilterMetadata }): void {
        const valueFilter = filters['value'];
        if (valueFilter != undefined && this.rangeFilterValidator.isValid(valueFilter.value)) {
            const {from, to} = this.rangeFilterValidator.getMatchedLimits(valueFilter.value);
            valueFilter.value = `(${from.toFixed(2)})-(${to.toFixed(2)})`;
        }
    }
}
