import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {Country} from '../../../common/enums/country';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SubsystemWebshopInfo, WebshopView} from './subsystem-webshop-info';
import {SubsystemWebshopInfoService} from './subsystem-webshop-info.service';

export type FileType = 'footer' | 'icon' | 'logo' | 'logoMobile';

@Component({
    selector: 'app-subsystem-webshop-info',
    templateUrl: './subsystem-webshop-info.component.html',
    styleUrls: ['../settings.component.css', './subsystem-webshop-info.component.css'],
    providers: [SubsystemWebshopInfoService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemWebshopInfoComponent implements OnInit {

    private readonly SUBSYSTEM_WEBSHOP_INFO_SAVING_ID = "SubsystemWebshopInfoSavingId";
    private readonly SUBSYSTEM_WEBSHOP_INFO_LOADING_ID = "SubsystemWebshopInfoLoadingId";

    readonly LOGO_SIZE_MULTIPLIER = 5;

    subsystemWebshopInfo: SubsystemWebshopInfo;
    validationErrors = {};
    countries: Observable<SelectItem[]>;
    callpageVisibleViewsOptions: Observable<SelectItem[]>;

    files: { footer?: File, icon?: File, logo?: File, logoMobile?: File } = {};

    constructor(public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private subsystemWebshopInfoService: SubsystemWebshopInfoService,
                private growlMessageController: GrowlMessageController,
                private errors: CommonErrorHandler,
                protected changeDetector: ChangeDetectorRef,
                public permissions: Permissions,
                private blockUiController: BlockUiController) {
        this.subsystemWebshopInfo = new SubsystemWebshopInfo();
    }

    ngOnInit() {
        this.blockUiController.block(this.SUBSYSTEM_WEBSHOP_INFO_LOADING_ID);
        this.subsystemWebshopInfoService.getItem().pipe(
            finalize(() => this.blockUiController.unblock(this.SUBSYSTEM_WEBSHOP_INFO_LOADING_ID))
        ).subscribe({
            next: data => {
                this.subsystemWebshopInfo = data;
                this.files.footer = this.subsystemWebshopInfo.footer;
                this.files.icon = this.subsystemWebshopInfo.icon;
                this.files.logo = this.subsystemWebshopInfo.logo;
                this.files.logoMobile = this.subsystemWebshopInfo.logoMobile;
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
        this.callpageVisibleViewsOptions = this.translatedSelectItemService.buildUnsortedDropdown(WebshopView, 'WEBSHOP_VIEW.', undefined);
    }

    save() {
        if (this.subsystemWebshopInfo.theme.primaryColor != undefined && this.subsystemWebshopInfo.theme.primaryColor.trim() === "") {
            this.subsystemWebshopInfo.theme.primaryColor = undefined;
        }
        if (this.subsystemWebshopInfo.theme.secondaryColor != undefined && this.subsystemWebshopInfo.theme.secondaryColor.trim() === "") {
            this.subsystemWebshopInfo.theme.secondaryColor = undefined;
        }
        this.validateForm();
        if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            this.blockUiController.block(this.SUBSYSTEM_WEBSHOP_INFO_SAVING_ID);
            this.subsystemWebshopInfoService.saveItem(this.subsystemWebshopInfo, this.files.footer, this.files.icon,
                this.files.logo, this.files.logoMobile).pipe(
                    finalize(() => this.blockUiController.unblock(this.SUBSYSTEM_WEBSHOP_INFO_SAVING_ID))
            ).subscribe({
                next: () => this.showSuccessMessage(),
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    private showSuccessMessage(): void {
        this.growlMessageController.info("SUBSYSTEM_WEBSHOP_INFO_SETTINGS.SAVING_SUCCESS_MESSAGE");
    }

    validateForm(): void {
        if (this.subsystemWebshopInfo.theme.primaryColor == undefined ||
            this.subsystemWebshopInfo.theme.primaryColor.trim() === "" ||
            !/^#[0-9A-Fa-f]{6}$/.test(this.subsystemWebshopInfo.theme.primaryColor)) {
            this.validationErrors['theme.primaryColor'] = 'error.subsystemWebshopInfoDto.theme.primaryColor.pattern_not_matched';
        }
        if (this.subsystemWebshopInfo.theme.secondaryColor == undefined ||
            this.subsystemWebshopInfo.theme.secondaryColor.trim() === "" ||
            !/^#[0-9A-Fa-f]{6}$/.test(this.subsystemWebshopInfo.theme.secondaryColor)) {
            this.validationErrors['theme.secondaryColor'] = 'error.subsystemWebshopInfoDto.theme.secondaryColor.pattern_not_matched';
        }
        this.changeDetector.markForCheck();
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    onFileChange(newFile: File | undefined, fileType: FileType) {
        this.files[fileType] = newFile;
        if (!newFile) {
            this.files[fileType] = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    private resetErrors() {
        this.validationErrors = {};
        this.changeDetector.markForCheck();
    }

    isCallpageVisibleViewSelected(viewOption: WebshopView): boolean {
        return this.subsystemWebshopInfo.callpageVisibleViews.includes(viewOption);
    }

    handleCallpageVisibleViewSelectionChanged(value: WebshopView, selected: boolean): void {
        const index = this.subsystemWebshopInfo.callpageVisibleViews.indexOf(value);
        if (selected) {
            if (index < 0) {
                this.subsystemWebshopInfo.callpageVisibleViews = [...this.subsystemWebshopInfo.callpageVisibleViews, value];
            }
        } else if (index >= 0) {
            this.subsystemWebshopInfo.callpageVisibleViews.splice(index, 1);
            this.subsystemWebshopInfo.callpageVisibleViews = [...this.subsystemWebshopInfo.callpageVisibleViews];
        }
        this.changeDetector.markForCheck();
    }
}
