import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {SupportedLanguage} from '../../../../supportedLanguages';
import {DocumentType} from '../../../offer/print-dialog/DocumentType';
import {SupplierService} from '../../../supplier/supplier.service';
import {DocumentTemplate} from '../document-template';

@Component({
    selector: 'app-document-template-form',
    templateUrl: './document-template-form.component.html',
    providers: [SupplierService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTemplateFormComponent implements OnInit {

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;
    readonly documentType = DocumentType;
    suppliersOptions: SelectItem[];

    @Input() item: DocumentTemplate;
    @Input() documentTypeOptions: SelectItem[];
    @Input() documentTemplateTypeOptions: SelectItem[];
    @Input() languageOptions: SelectItem[];
    @Input() validationErrors: any;
    @Input() file: File;

    @Output()
    fileChange = new EventEmitter<File>();

    constructor(public supplierService: SupplierService) {
    }

    ngOnInit(): void {
        this.onDocumentTypeChange(this.item.documentType);
    }

    contains(template: DocumentTemplate, language: SupportedLanguage): boolean {
        return template.languages.includes(language.code);
    }

    changeFile(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    onDocumentTypeChange(documentType: DocumentType): void {
        this.item.documentType = documentType;
        if (documentType === this.documentType.PRODUCTION_ORDER) {
            this.supplierService.getSupplierNames()
                .subscribe(data => {
                    this.suppliersOptions = data.map(supplier => ({label: supplier.companyName, value: supplier.id}));
                });
        } else {
            this.suppliersOptions = [];
            this.item.suppliersIds = [];
        }
    }
}
