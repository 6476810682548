<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ASSEMBLY.LIST' | translate }}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR','ROLE_OPERATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ASSEMBLY.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ASSEMBLY.ADD-ASSEMBLY' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [paginator]="true" [rows]="chosenRowsPerPage" [totalRecords]="totalRecords"
                 [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true" selectionMode="single"
                 [(selection)]="selectedItem" (onRowDblclick)="doOnRowSelect($event)" [lazy]="true"
                 (onLazyLoad)="loadItemsLazy($event)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 sortField="{{ defaultSortColumn }}" [sortOrder]="defaultSortOrder"
                 dataKey="id" [value]="itemList">
        <p-column field="name" header="{{ 'ASSEMBLY.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains"></p-column>
        <p-column field="sortIndex" header="{{ 'ASSEMBLY.FORM.SORT_INDEX' | translate }}" [sortable]="true"></p-column>
        <p-column field="assemblyUnit" header="{{ 'ASSEMBLY.FORM.UNIT' | translate }}" [sortable]="true" [filter]="showFilters"
                  [filterValues]="assemblyUnitsFilter | async">
            <ng-template pTemplate="body" let-assembly="rowData">
                {{ 'ASSEMBLY.UNITS.' + assembly.assemblyUnit | translate }}
            </ng-template>
        </p-column>
        <p-column field="value" header="{{ 'ASSEMBLY.FORM.VALUE' | translate }}" [sortable]="true" [filter]="showFilters">
            <ng-template pTemplate="body" let-assembly="rowData">
                {{ assembly.value | price: assembly.currency }}
            </ng-template>
        </p-column>
        <p-column field="active" header="{{ 'ASSEMBLY.FORM.STATUS' | translate }}" [filter]="showFilters"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template pTemplate="body" let-assembly="rowData">
                {{ (assembly.active ? 'ASSEMBLY.FORM.ACTIVE' : 'ASSEMBLY.FORM.INACTIVE') | translate }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>
                {{ 'GENERAL.TOTAL_RECORDS' | translate: {from: fromRecord, to: toRecord, total: totalRecords} }}
            </span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ASSEMBLY.ADD-ASSEMBLY' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'ASSEMBLY.STEPS.DATA' | translate }}">
        <app-assembly-form [item]="item" [assemblyUnits]="availableAssemblyUnits | async"
                           [validationErrors]="validationErrors" [copyMode]="copyMode"></app-assembly-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ getDialogHeaderText() | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step>
        <app-assembly-form [item]="item" [assemblyUnits]="availableAssemblyUnits | async"
                           [validationErrors]="validationErrors" [copyMode]="copyMode"></app-assembly-form>
    </app-wizard-step>
</app-wizard-dialog>
