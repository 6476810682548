import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {finalize} from 'rxjs/operators';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {ScheduledJob} from './scheduled-job';
import {ScheduledJobService} from './scheduled-job.service';

@Component({
    selector: 'app-scheduled-job',
    templateUrl: './scheduled-job.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [ScheduledJobService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledJobComponent implements OnInit {

    public jobs: ScheduledJob[] = [];

    constructor(private scheduledJobService: ScheduledJobService,
                private changeDetector: ChangeDetectorRef,
                private translateService: TranslateService,
                private blockUiController: BlockUiController) {
    }

    public ngOnInit(): void {
        this.loadJobs();
    }

    private loadJobs(): void {
        this.blockUiController.block('ScheduledJobData');
        this.scheduledJobService.getJobs().pipe(
            finalize(() => this.blockUiController.unblock('ScheduledJobData')))
            .subscribe(data => {
                this.jobs = data;
                this.changeDetector.markForCheck();
            });
    }

    public isJobExpired(job: ScheduledJob): boolean {
        return (job.endDate == null && moment(job.startDate).add(10, 'minute').isBefore(new Date())) ||
            (job.endDate != null && moment(job.startDate).add(10, 'minute').isBefore(job.endDate));
    }
}
