import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ListOfIds} from '../../features/ListOfIds';

@Injectable()
export class IdGeneratorService {

    private readonly SERVICE_PATH = 'idgenerator';

    constructor(private http: HttpClient) {
    }

    public getGeneratedOfflineIds(idsCount: number): Observable<ListOfIds> {
        return this.http.get<ListOfIds>(`${this.SERVICE_PATH}/offlineid/${idsCount}`);
    }
}
