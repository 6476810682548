import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import * as _ from "underscore";
import {MessageSeverity, PositionMessage} from "../../offer/offers/message";

@Component({
    selector: 'app-sheet-table',
    templateUrl: './sheet-table.component.html',
    styleUrls: ['./sheet-table.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SheetTableComponent implements AfterViewInit {

    readonly COLUMN_INDEX_KEY = 'data';

    @Input() data: any[] = [];
    @Input() columns: any[] = [];
    @Input() isNew: boolean;
    @Input() rowHeaderLabel: string;
    @Input() columnHeaderLabel: string;
    @Input() firstColumnHeaderLabel: boolean;
    @Input() includeDescriptionRow: boolean;
    @Input() readOnly: boolean;
    @Input() validator: (value: any, ...args: any) => boolean;
    @Input() setErrorCode: (row: number, col: number) => string;
    @Input() cellStyleClasses: { col: number, row: number, classes: string[] }[];
    @Output() errorDetected: EventEmitter<PositionMessage> = new EventEmitter<PositionMessage>();

    readonlyCell = true;

    error: PositionMessage;

    selectedCells: {x: number, y: number}[] = [];
    selectedHeaderRows: number[] = [];
    selectedHeaderColumns: number[] = [];
    firstSelectedCell: {x: number, y: number};
    entireRowSelected: boolean;
    entireColumnSelected: boolean;

    startRowIndex: number;
    endRowIndex: number;
    startColIndex: number;
    endColIndex: number;

    invalidCells: {x: number, y: number}[] = [];

    constructor(private readonly translateService: TranslateService,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    private static compareCells(cell1, cell2) {
        if (cell1.x <= cell2.x && cell1.y <= cell2.y) {
            return -1;
        } else {
            return 1;
        }
    }

    get rowLabel(): string {
        return this.rowHeaderLabel ? this.rowHeaderLabel + ' ' : '';
    }

    get columnLabel(): string {
        return this.columnHeaderLabel ? this.columnHeaderLabel + ' ' : '';
    }

    ngAfterViewInit() {
        if (this.isNew) {
            this.addRow();
        }
    }

    onPaste(event: ClipboardEvent) {
        const pasted = event.clipboardData.getData('text');
        if (!pasted) {
            return;
        }
        const row_data = pasted.split('\n').filter(v => v !== '');
        let column_data = row_data[0].split('\t');
        if (this.entireRowSelected && row_data.length > 1 || this.entireColumnSelected && column_data.length > 1) {
            this.setError('error.shippingPricetableDto.forbiddenOperation');
            return;
        }
        const firstSelected = this.selectedCells[0];
        this.addNecessaryCells(row_data.length, column_data.length, firstSelected);
        for (let i = firstSelected.x; i < firstSelected.x + row_data.length; i++) {
            for (let j = firstSelected.y; j < firstSelected.y + column_data.length; j++) {
                if (!this.selectedCells.some(cell => cell.x === i && cell.y === j)) {
                    this.selectedCells.push({x: i, y: j});
                }
            }
        }
        this.selectHeaders();
        let counter = 0;
        for (let i = 0; i < row_data.length; i++) {
            column_data = row_data[i].split('\t');
            for (let j = 0; j < column_data.length; j++) {
                let cell = this.selectedCells[counter];
                let cell_data = column_data[j].replace(',', '.');
                let stringValue = cell_data.toString();
                let newValue = !stringValue.includes(":") ? Math.round((+cell_data + Number.EPSILON) * 100) / 100 : cell_data;
                this.data[cell.x][cell.y] = newValue;
                this.validateCell(cell.x, cell.y, newValue);
                counter++;
            }
        }
        this.changeDetector.markForCheck();
    }

    onCopy(event: ClipboardEvent) {
        let toCopy = '';
        for (let i = 0; i < this.selectedHeaderRows.length; i++) {
            for (let j = 0; j < this.selectedHeaderColumns.length; j++) {
                const suffix = j === this.selectedHeaderColumns.length - 1 ? '\n' : '\t';
                toCopy = toCopy + this.data[this.selectedHeaderRows[i]][this.selectedHeaderColumns[j]] + suffix;
            }
        }
        event.clipboardData.setData('text/plain', toCopy);
        event.preventDefault();
    }

    detectSelectAll(event: Event) {
        event.preventDefault();
        this.startSelection(0, 0);
        this.continueSelection(this.data.length - 1, this.columns.length - 1);
        this.finishSelection();
    }

    startSelection(row: number, column: number) {
        this.clearSelection();
        if (row === null) {
            this.entireColumnSelected = true;
            this.startSelection(0, column);
        } else if (column === null) {
            this.entireRowSelected = true;
            this.startSelection(row, 0);
        } else {
            this.selectedCells.push({x: row, y: column});
            this.firstSelectedCell = {x: row, y: column};
            this.changeDetector.markForCheck();
        }
    }

    continueSelection(row: number, column: number) {
        if (this.firstSelectedCell != undefined) {
            this.changeDetector.markForCheck();
            if (row === null) {
                this.continueSelection(this.data.length - 1, column);
            } else if (column === null) {
                this.continueSelection(row, this.columns.length - 1);
            } else {
                const newSelection = [];
                if (this.firstSelectedCell.x === row && this.firstSelectedCell.y === column) {
                    this.selectHeaders();
                    return;
                }
                for (let x = this.firstSelectedCell.x; this.firstSelectedCell.x < row ? x <= row : x >= row;
                     this.firstSelectedCell.x < row ? x++ : x--) {
                    for (let y = this.firstSelectedCell.y; this.firstSelectedCell.y < column ? y <= column : y >= column;
                         this.firstSelectedCell.y < column ? y++ : y--) {
                        if (!newSelection.some(cell => cell.x === x && cell.y === y)) {
                            newSelection.push({x: x, y: y});
                        }
                    }
                }
                this.selectedCells = newSelection;
                if (this.selectedCells.length > 1) {
                    this.selectedCells.sort((cell1, cell2) => SheetTableComponent.compareCells(cell1, cell2));
                }
            }
        }
    }

    finishSelection() {
        this.firstSelectedCell = undefined;
        this.selectHeaders();
        if (this.selectedCells.length > 0) {
            this.startRowIndex = this.selectedCells[0].x;
            this.endRowIndex = this.selectedCells[this.selectedCells.length - 1].x;
            this.startColIndex = this.selectedCells[0].y;
            this.endColIndex = this.selectedCells[this.selectedCells.length - 1].y;
        }
        this.changeDetector.markForCheck();
    }

    selectHeaders() {
        const newHeaderRows = [];
        const newHeaderColumns = [];
        this.selectedCells.forEach(cell => {
            if (!newHeaderRows.some(val => val === cell.x)) {
                newHeaderRows.push(cell.x);
            }
            if (!newHeaderColumns.some(val => val === cell.y)) {
                newHeaderColumns.push(cell.y);
            }
        });
        this.selectedHeaderRows = newHeaderRows;
        this.selectedHeaderColumns = newHeaderColumns;
    }

    clearSelection() {
        this.selectedCells = [];
        this.selectedHeaderColumns = [];
        this.selectedHeaderRows = [];
        this.entireColumnSelected = false;
        this.entireRowSelected = false;
        this.startRowIndex = undefined;
        this.endRowIndex = undefined;
        this.startColIndex = undefined;
        this.endColIndex = undefined;
    }

    addNecessaryCells(rowsToPaste: number, colsToPaste: number, pasteCoords: { x: number, y: number }) {
        const currentTotalRows = this.data.length;
        const rowsToAdd = rowsToPaste + pasteCoords.x - currentTotalRows;
        if (rowsToAdd > 0) {
            this.addRow(rowsToAdd);
        }

        const currentTotalCols = Object.keys(this.columns).length;
        const colsToAdd = colsToPaste + pasteCoords.y - currentTotalCols;
        if (colsToAdd > 0) {
            _.range(0, colsToAdd).forEach(() => this.addColumn());
        }
    }

    addRow(numbOfRowsToAdd = 1) {
        _.range(numbOfRowsToAdd).forEach(() => this.data.push({[this.COLUMN_INDEX_KEY]: ''}));
        this.changeDetector.markForCheck();
    }

    addColumn() {
        this.columns.push(this.columns[this.columns.length - 1] + 1);
        this.changeDetector.markForCheck();
    }

    deleteValue() {
        for (let i = 0; i < this.selectedCells.length; i++) {
            let cell = this.selectedCells[i];
            this.data[cell.x][cell.y] = null;
        }
        this.changeDetector.markForCheck();
    }

    removeColumn() {
        const startColIndex = this.selectedCells[0].y;
        const endColIndex = this.selectedCells[this.selectedCells.length - 1].y;
        let startColIndexToRemove = Math.min(startColIndex, endColIndex);
        let endColIndexToRemove = Math.max(startColIndex, endColIndex);
        let columnsToRemove = endColIndexToRemove - startColIndexToRemove + 1;
        this.data.forEach(row => {
            // first move all cells
            for (let i = startColIndexToRemove; i < this.columns.length - columnsToRemove; i++) {
                row[i] = row[i + columnsToRemove];
            }
            // then delete trailing columns
            for (let i = this.columns.length - columnsToRemove; i < this.columns.length; i++) {
                delete row[i];
            }
        });

        this.columns.splice(this.columns.length - columnsToRemove);
        this.clearSelection();
        this.changeDetector.markForCheck();
    }

    removeLastColumn() {
        const removedColumn = this.columns.length - 1;
        this.data.forEach(row => delete row[removedColumn]);
        this.columns.pop();
        this.clearSelection();
        this.changeDetector.markForCheck();
    }

    removeRows() {
        if (this.startRowIndex <= this.endRowIndex) {
            this.data.splice(this.startRowIndex, this.endRowIndex - this.startRowIndex + 1);
        } else {
            this.data.splice(this.endRowIndex, this.startRowIndex - this.endRowIndex + 1);
        }
        this.clearSelection();
        this.changeDetector.markForCheck();
    }

    canDeleteSelectedColumns() {
        return this.startColIndex >= 0;
    }

    canDeleteLastColumn() {
        return this.columns.length > 1;
    }

    canDeleteSelectedRows() {
        return this.startRowIndex >= 0;
    }

    validateCell(row: number, column: number, value: any): boolean {
        const isValid = this.validator(value.toString().replace(',', '.'), column, row);

        if (isValid) {
            const index = this.invalidCells.findIndex(cell => cell.x === row && cell.y === column);
            if (index !== -1) {
                this.invalidCells.splice(index, 1);
            }
        } else {
            if (!this.invalidCells.some(cell => cell.x === row && cell.y === column)) {
                this.invalidCells.push({x: row, y: column});
            }
        }
        if (this.invalidCells.length > 0) {
            this.setError();
        } else {
            this.clearErrors();
        }
        return isValid;
    }

    setError(message?: string) {
        const errorCode = message ? message : this.setErrorCode(this.invalidCells[0].x, this.invalidCells[0].y);
        this.error = new PositionMessage();
        this.error.messageCode = errorCode;
        this.error.severity = MessageSeverity.ERROR;
        if (!message) {
            let rowLabel = this.rowLabel + this.invalidCells[0].x;
            let colLabel = this.columnLabel + this.invalidCells[0].y;
            this.error.messageCodeParams = {row: rowLabel, col: colLabel};
        }
        this.errorDetected.emit(this.error);
    }

    clearErrors() {
        this.error = undefined;
        this.errorDetected.emit(undefined);
    }

    getStyleClassesForCell(row: number, col: number): string {
        const classes = ['sheet-cell', 'content-cell'];
        if ((row === 0 && this.includeDescriptionRow) || col === 0) {
            classes.push('header-cell');
        }
        if (this.selectedCells.some(cell => cell.x === row && cell.y === col)) {
            classes.push('selected-cell');
        }
        const customClasses = (this.cellStyleClasses || []).find(style => style.col === col && style.row === row);
        if (customClasses != undefined) {
            classes.push(...customClasses.classes);
        }
        return classes.join(' ');
    }
}
