<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(ColorField.NAME)"
                            inputId="name" label="{{ 'COLOR.FORM.NAME' | translate }}" validationKeyPrefix="names"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.names"
                            [disabled]="fieldUsage.disabled(ColorField.NAME)"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(ColorField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'COLOR.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"
                            [disabled]="fieldUsage.disabled(ColorField.SHORTCUT)"></app-translation-fields>
    <div *ngIf="fieldUsage.show(ColorField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'COLOR.FORM.SYMBOL' | translate }}"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [(ngModel)]="item.symbol" [required]="true"
                        [disabled]="fieldUsage.disabled(ColorField.SYMBOL)">
        </app-input-text>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.TYPE)">
        <app-select inputId="type" label="{{ 'COLOR.FORM.TYPE' | translate }}"
                    [options]="selectTypes | async" [ngModel]="item.type" [required]="true"
                    [(validationMessageKey)]="validationErrors['type']"
                    [disabled]="fieldUsage.disabled(ColorField.TYPE)"
                    (ngModelChange)="onTypeChange($event)"></app-select>
    </div>
    <div *ngIf="fieldUsage.show(ColorField.RAL_HEX)" class="new-form-row">
        <app-input-text inputId="ralHex" label="{{ 'COLOR.FORM.RAL_HEX' | translate }}"
                        [(validationMessageKey)]="validationErrors['ralHex']" [maxlength]="7" [required]="true"
                        [disabled]="fieldUsage.disabled(ColorField.RAL_HEX)"
                        [(ngModel)]="item.ralHex" (ngModelChange)=changeWebshopHex()></app-input-text>
    </div>
    <div *ngIf="fieldUsage.show(ColorField.GROUP)" class="new-form-row">
        <app-input-text inputId="group" label="{{ 'COLOR.FORM.GROUP' | translate }}"
                        [(validationMessageKey)]="validationErrors['group']"
                        [disabled]="fieldUsage.disabled(ColorField.GROUP)"
                        [(ngModel)]="item.group">
        </app-input-text>
    </div>
    <ng-container *ngIf="colorTarget.target === 'General'">
        <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.CORE)">
            <app-checkbox inputId="core" label="{{ 'COLOR.FORM.CORE' | translate }}"
                          [disabled]="fieldUsage.disabled(ColorField.CORE)"
                          [(ngModel)]="item.core" (ngModelChange)="coreChanged()"></app-checkbox>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.INSIDE)">
            <app-checkbox inputId="inside" label="{{ 'COLOR.FORM.INSIDE' | translate }}"
                          [disabled]="fieldUsage.disabled(ColorField.INSIDE)"
                          [(ngModel)]="item.inside"></app-checkbox>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.OUTSIDE)">
            <app-checkbox inputId="outside" label="{{ 'COLOR.FORM.OUTSIDE' | translate }}"
                          [disabled]="fieldUsage.disabled(ColorField.OUTSIDE)"
                          [(ngModel)]="item.outside"></app-checkbox>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.GRILL)">
            <app-checkbox inputId="grill" label="{{ 'COLOR.FORM.GRILL' | translate }}"
                          [disabled]="fieldUsage.disabled(ColorField.GRILL)"
                          [(ngModel)]="item.grill"></app-checkbox>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.MUST_BE_COVERED)">
            <app-checkbox inputId="mustBeCovered" label="{{ 'COLOR.FORM.MUST_BE_COVERED' | translate }}"
                          [(ngModel)]="item.mustBeCovered" [disabled]="fieldUsage.disabled(ColorField.MUST_BE_COVERED)"
                          (ngModelChange)="mustBeCoveredChanged()"></app-checkbox>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.CANNOT_BE_COVERED)">
            <app-checkbox inputId="cannotBeCovered" label="{{ 'COLOR.FORM.CANNOT_BE_COVERED' | translate }}"
                          [(ngModel)]="item.cannotBeCovered" [disabled]="fieldUsage.disabled(ColorField.CANNOT_BE_COVERED)"
                          (ngModelChange)="cannotBeCoveredChanged()"></app-checkbox>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.GRILL_MATCHING_COLOR)">
            <app-select inputId="matchingColorId" label="{{ 'COLOR.FORM.GRILL_MATCHING_COLOR' | translate }}"
                        [options]="availableColors" [(ngModel)]="item.matchingColorId" [disabled]="fieldUsage.disabled(ColorField.GRILL_MATCHING_COLOR)"
                        [required]="false" [allowSelectingNone]="true"></app-select>
        </div>
    </ng-container>

    <div *ngIf="fieldUsage.show(ColorField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'COLOR.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(ColorField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(ColorField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'COLOR.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(ColorField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <ng-container *ngIf="fieldUsage.show(ColorField.WEBSHOP)">
        <div class="new-form-row">
            <app-checkbox inputId="webshop" label="{{ 'COLOR.FORM.WEBSHOP' | translate }}"
                          [disabled]="fieldUsage.disabled(ColorField.WEBSHOP)"
                          [(ngModel)]="item.webshop"></app-checkbox>
        </div>

        <p-accordion>
            <div *ngIf="fieldUsage.show(ColorField.WEBSHOP_COLOR_OPTION)" class="new-form-row">
                <app-radio-button inputId="webshopColorOptions" [options]="webshopColorOptions" [required]="true"
                                  [disabled]="fieldUsage.disabled(ColorField.WEBSHOP_COLOR_OPTION)"
                                  [(ngModel)]="item.selectedWebshopColorOption"
                                  (ngModelChange)="onSelectedWebshopColorOptionChange()"
                                  [translateLabels]="true"
                                  [(validationMessageKey)]="validationErrors['selectedWebshopColorOption']">
                </app-radio-button>
            </div>

            <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.WEBSHOP_HEX)">
                <app-input-text inputId="ralHex" label="{{ 'COLOR.FORM.WEBSHOP_HEX' | translate }}"
                                [disabled]="fieldUsage.disabled(ColorField.WEBSHOP_HEX)"
                                [(validationMessageKey)]="validationErrors['webshopHex']" [maxlength]="7" [required]="true"
                                [(ngModel)]="item.webshopHex" pTooltip="{{(isPaletteCustom() ? 'COLOR.FORM.WEBSHOP_HEX_TOOLTIP' : '') | translate }}"></app-input-text>
            </div>

            <p-accordionTab *ngIf="fieldUsage.show(ColorField.WEBSHOP_TEXTURE)"  header="{{ 'COLOR.FORM.WEBSHOP_TEXTURE' | translate }}"
                            [selected]="true" [ngClass]="{'accordion-header-with-errors': validationErrors['webshopTexture']}">
                <app-file-upload inputId="webshopTexture" [ngModel]="webshopTexture"
                                 (ngModelChange)="onWebshopTextureChange($event)"
                                 [disabled]="fieldUsage.disabled(ColorField.WEBSHOP_TEXTURE)"
                                 [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                 [addButtonLabel]="'COLOR.FORM.SELECT_TEXTURE_UPLOAD'"
                                 [changeButtonLabel]="'COLOR.FORM.CHANGE_TEXTURE_UPLOAD'"
                                 [deleteButtonLabel]="'COLOR.FORM.DELETE_TEXTURE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['webshopTexture']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </ng-container>

    <div *ngIf="fieldUsage.show(ColorField.GATE_HEX)" class="new-form-row">
        <app-input-text inputId="gateHex" label="{{ 'COLOR.FORM.WEBSHOP_HEX' | translate }}"
                        [disabled]="fieldUsage.disabled(ColorField.GATE_HEX)"
                        [(validationMessageKey)]="validationErrors['webshopHex']" [maxlength]="7" [required]="true"
                        [ngModel]="item.webshopHex" (ngModelChange)="handleGateHexChange($event)" ></app-input-text>
    </div>

    <!-- image -->
    <div class="new-form-row" *ngIf="fieldUsage.show(ColorField.IMAGE_SECTION)">
        <p-accordion>

            <p-accordionTab header="{{ 'COLOR.FORM.IMAGE_SECTION' | translate }}"
                            [selected]="false" [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(ColorField.IMAGE_SECTION)"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                 [addButtonLabel]="'COLOR.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'COLOR.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'COLOR.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>

</div>
