import {GrillSegment} from "../drawing-data/GrillSegment";
import {Mullion} from "../drawing-data/Mullion";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {ErrorNames} from "./ErrorNames";
import {GrillHelper} from "./grill-helper";
import {RelativePosition} from "../drawing-data/RelativePosition";
import {ProfileInterface, ProfileType} from "../catalog-data/profile-interface";

export class MullionHelper {

    public static isDeletable(mullion: Mullion, subwindow: SubWindowData): boolean {
        return MullionHelper.getDependees(mullion, subwindow).length === 0 &&
            subwindow.areasSpecification.filter(
                a => a.definingMullions.map(dm => dm.id).indexOf(GrillHelper.getExpectedLineSegment(mullion).id) >= 0)
                .every(a => !GrillHelper.areaHasGrills(a));
    }

    public static findMullionById(id: number, subwindow: SubWindowData): Mullion {
        for (let mullion of subwindow.mullions) {
            if (mullion.drawingSegments[0].id === id) {
                return mullion;
            }
        }
        let err = new Error("Segment with id " + id + " not found");
        err.name = ErrorNames.GENERAL_ERROR;
        throw err;
    }

    public static findMullionSegmentById(id: number, subwindow: SubWindowData): GrillSegment {
        for (let mullion of subwindow.mullions) {
            if (mullion.drawingSegments[0].id === id) {
                return mullion.drawingSegments[0];
            }
        }
        let err = new Error("Segment with id " + id + " not found");
        err.name = ErrorNames.GENERAL_ERROR;
        throw err;
    }

    public static isVeneer(mullion: Mullion): boolean {
        return mullion.positions.length === 1 && this.isVeneerPosition(mullion.positions[0]);
    }

    public static isVeneerPosition(position: RelativePosition): boolean {
        switch (position.type) {
            case PositionReferenceType.VENEER:
            case PositionReferenceType.WEBSHOP_VENEER_HORIZONTAL:
            case PositionReferenceType.WEBSHOP_VENEER_VERTICAL:
                return true;
            default:
                return false;
        }
    }

    public static getParentDependencies(mullion: Mullion, subwindow: SubWindowData): Mullion[] {
        return mullion.positions.filter(p => p.type === PositionReferenceType.MULLION)
            .map(p => MullionHelper.findMullionById(p.id, subwindow));
    }

    public static getDependees(mullion: Mullion, subwindow: SubWindowData): Mullion[] {
        return subwindow.mullions.filter(m => m.positions.some(
            p => p.type === PositionReferenceType.MULLION && mullion.drawingSegments.map(s => s.id).indexOf(p.id) >= 0));
    }

    public static isDependantOn(mullion: Mullion, toCheck: Mullion): boolean {
        return mullion.positions.some(
            p => p.type === PositionReferenceType.MULLION && p.id === GrillHelper.getExpectedLineSegment(toCheck).id);
    }

    public static areCoDependent(mullion: Mullion, other: Mullion): boolean {
        return this.isDependantOn(mullion, other) || this.isDependantOn(other, mullion);
    }

    public static canBeDrawnAtAnAngle(mullion: Mullion): boolean {
        return mullion.isConstructional;
    }

    public static canBeAngled(mullion: ProfileInterface): boolean {
        return mullion.type === ProfileType.CONSTRUCTIONAL_MULLION;
    }
}
