<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.EDIT_PERMISSIONS.GENERAL' | translate }}</div>

        <div class="new-form-600">
            <app-select inputId="roleSelect"
                        label="{{'SETTINGS.SECTION.EDIT_PERMISSIONS.ROLE' | translate}}"
                        [options]="roles"
                        [ngModel]="selectedRole"
                        (ngModelChange)="selectRole($event)"></app-select>
        </div>
    </div>

    <div class="settings-form-wrapper">
        <p-accordion>
            <p-accordionTab *ngFor=" let item of catalogItems" [header]="('NAVIGATION.ITEMS.WINDOW-SYSTEM.' + item + '.LIST') | translate"
                            [ngClass]="{'accordion-header-with-errors': unsavedAccordion(item)}">
                <ng-container *ngTemplateOutlet="form; context: { item: item }"></ng-container>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>

<ng-template #form let-item="item">
    <div class="new-form-60vw">
        <div class="form-row">
            <app-checkbox class="label"
                          [inputId]="'copyFrom_' + item" label="{{ 'SETTINGS.SECTION.EDIT_PERMISSIONS.COPY_FROM' | translate }}"
                          [(ngModel)]="copyConfig.get(item).selected"></app-checkbox>
            <div class="selectable select-with-button" *ngIf="copyConfig.get(item).selected">
                <app-select [inputId]="'selectCopyFrom_' + item"
                            [options]="catalogItems"
                            [optionFormatter]="catalogItemsFormatter"
                            [translateLabels]="true"
                            [(ngModel)]="copyConfig.get(item).copyFrom"></app-select>
                <app-simple-button buttonId="copyFields"
                                   [label]="'GENERAL.COPY' | translate"
                                   type="main-action" [size]="40"
                                   (onClick)="copyPermits(item)"></app-simple-button>
            </div>
        </div>
        <div class="form-row" *ngFor="let field of fieldsByItem.get(item)">
            <span class="label">{{ ('LIMITATIONS.FIELD.' + field.fieldName + '.LABEL') | translate }}</span>
            <app-radio-button class="selectable"
                              [(ngModel)]="field.limitation"
                              (ngModelChange)="changeLimitation(item, field.fieldName)"
                              [options]="editOptions"
                              [translateLabels]="true"></app-radio-button>
        </div>
        <div class="form-row">
            <app-simple-button class="selectable"
                               buttonId="confirm"
                               [label]="'GENERAL.SAVE' | translate"
                               type="main-action" [size]="40"
                               (onClick)="savePermits(item)"></app-simple-button>
        </div>
    </div>
</ng-template>

<app-exit-without-saving-confirmation-dialog (exitWithoutSaving)="leaveWithoutSaving()"
                                             (hideDialog)="unsavedChangesDialogVisible = false; showUnsavedAccordion = true; exitConfirmation.next(false)"
                                             [visible]="unsavedChangesDialogVisible"></app-exit-without-saving-confirmation-dialog>
