import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../../common/crud-common/crud.service';
import {Listing, mapListingToJson} from '../../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {ConfigDesignerCatalogDependentOption} from './config-designer-catalog-dependent-option';

@Injectable()
export class ConfigDesignerCatalogDependentOptionService implements CrudService<ConfigDesignerCatalogDependentOption> {

    private static readonly API_URL = 'configDesignerCatalogDependentOption';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<ConfigDesignerCatalogDependentOption>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);

        return this.http.get<Listing<object>>(ConfigDesignerCatalogDependentOptionService.API_URL, {params: params})
            .pipe(mapListingToJson(ConfigDesignerCatalogDependentOption));
    }

    getItemsFromSet(optionsSetId: number): Observable<ConfigDesignerCatalogDependentOption[]> {
        return this.http.get<ConfigDesignerCatalogDependentOption[]>(`${ConfigDesignerCatalogDependentOptionService.API_URL}/allFromSet/${optionsSetId}`);
    }

    getItem(itemId: number): Observable<ConfigDesignerCatalogDependentOption> {
        return undefined;
    }

    addItem(item: ConfigDesignerCatalogDependentOption): Observable<number> {
        return undefined;
    }

    editItem(itemId: number, item: ConfigDesignerCatalogDependentOption): Observable<number> {
        return undefined;
    }
}
