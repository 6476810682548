<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(RoofGlazingPackageField.NAME)"
                            inputId="name" label="{{ 'ROOF_GLAZING_PACKAGE.FORM.NAME' | translate }}" validationKeyPrefix="name"
                            [disabled]="fieldUsage.disabled(RoofGlazingPackageField.NAME)"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.name"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(RoofGlazingPackageField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'ROOF_GLAZING_PACKAGE.FORM.SHORTCUT' | translate }}"
                            [disabled]="fieldUsage.disabled(RoofGlazingPackageField.SHORTCUT)"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(RoofGlazingPackageField.GLASSES)"
                            inputId="glasses" label="{{ 'ROOF_GLAZING_PACKAGE.FORM.GLASSES' | translate }}"
                            validationKeyPrefix="glasses" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(RoofGlazingPackageField.GLASSES)"
                            [maxlength]="255"
                            [field]="item.glasses"></app-translation-fields>
    <div *ngIf="fieldUsage.show(RoofGlazingPackageField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'ROOF_GLAZING_PACKAGE.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(RoofGlazingPackageField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [(ngModel)]="item.symbol" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(RoofGlazingPackageField.GLASSES_QUANTITY)">
        <app-input-number inputId="glassesQuantity" label="{{ 'ROOF_GLAZING_PACKAGE.FORM.GLASSES_QUANTITY' | translate }}"
                          [disabled]="fieldUsage.disabled(RoofGlazingPackageField.GLASSES_QUANTITY)"
                          [(validationMessageKey)]="validationErrors['glassesQuantity']" [min]="1" [max]="9"
                          [required]="true" [(ngModel)]="item.glassesQuantity"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(RoofGlazingPackageField.UG)">
        <app-input-number inputId="ug" label="{{ 'ROOF_GLAZING_PACKAGE.FORM.UG' | translate }}"
                          [disabled]="fieldUsage.disabled(RoofGlazingPackageField.UG)"
                          [(validationMessageKey)]="validationErrors['ug']" [min]="0" [max]="99"
                          [required]="true" [(ngModel)]="item.ug"></app-input-number>
    </div>
    <div *ngIf="fieldUsage.show(RoofGlazingPackageField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active"
                      label="{{ 'ROOF_GLAZING_PACKAGE.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(RoofGlazingPackageField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(RoofGlazingPackageField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'ROOF_GLAZING_PACKAGE.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(RoofGlazingPackageField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <p-accordion *ngIf="fieldUsage.show(RoofGlazingPackageField.IMAGE_SECTION)">
        <p-accordionTab header="{{ 'ROOF_GLAZING_PACKAGE.FORM.IMAGE_SECTION' | translate }}"
                        [selected]="false" [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(RoofGlazingPackageField.IMAGE_SECTION)"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                 [addButtonLabel]="'ROOF_GLAZING_PACKAGE.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'ROOF_GLAZING_PACKAGE.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'ROOF_GLAZING_PACKAGE.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
