<div [attr.id]="buttonId" [pTooltip]="formattedTooltip" class="toolbar-tile" *ngIf="!menuElements" [ngClass]="{'disabled': disabled}"
     (click)="!disabled && handleClick($event)">
    <span *ngIf="icon" class="material-icons">{{ icon }}</span>
    <div class="name">{{ label }}</div>
</div>


<button-with-menu [buttonId]="buttonId" *ngIf="menuElements"
                  [menuType]="menuType.SIDEBAR_ICON_BUTTON"
                  [menuElements]="menuElements"
                  [disabled]="disabled"
                  (menuElementSelected)="handleMenuElementSelected($event)">
    <div [pTooltip]="formattedTooltip" class="toolbar-tile" [ngClass]="{'disabled': disabled}">
        <span *ngIf="icon" class="material-icons">{{ icon }}</span>
        <div class="name">{{ label }}</div>
    </div>
</button-with-menu>