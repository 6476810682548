import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {CatalogItemTag} from './catalog-item-tag';

@Injectable()
export class CatalogItemTagsService implements CrudService<CatalogItemTag> {

    private static readonly API_URL = 'catalogItemTag';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<CatalogItemTag>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(CatalogItemTagsService.API_URL, {params: params})
            .pipe(map(response => Listing.fromJSON(CatalogItemTag, response)));
    }

    getItem(catalogItemTagId: number): Observable<CatalogItemTag> {
        return this.http.get<object>(`${CatalogItemTagsService.API_URL}/${catalogItemTagId}`)
            .pipe(map(response => CatalogItemTag.fromJSON(response)));
    }

    getImage(catalogItemTagId: number): Observable<File> {
        return this.http.get(`${CatalogItemTagsService.API_URL}/${catalogItemTagId}/icon`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    addItem(catalogItemTag: CatalogItemTag, iconFile: File): Observable<number> {
        const formData = this.prepareFormData(catalogItemTag, iconFile);
        return this.http.post<void>(CatalogItemTagsService.API_URL, formData, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId(CatalogItemTagsService.API_URL, response)));
    }

    editItem(catalogItemTagId: number, catalogItemTag: CatalogItemTag, iconFile: File): Observable<number> {
        const formData = this.prepareFormData(catalogItemTag, iconFile);
        return this.http.put<void>(`${CatalogItemTagsService.API_URL}/${catalogItemTagId}`, formData)
            .pipe(map(() => catalogItemTagId));
    }

    private prepareFormData(catalogItemTag: CatalogItemTag, iconFile: File): FormData {
        const formData = new FormData();
        formData.append('catalogItemTagDto', new Blob([JSON.stringify(catalogItemTag)], {type: 'application/json'}));
        if (iconFile != undefined) {
            formData.append('iconFile', iconFile);
        }
        return formData;
    }

    getAllActive(): Observable<CatalogItemTag[]> {
        return this.getItems(0, undefined, {active: {value: 'true'}}, 'id', 1).pipe(map(result => result.data));
    }
}
