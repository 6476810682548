import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTableColumn} from "./data.table.column";

export type ColumnFilterMatchMode = 'startsWith' | 'contains' | 'endsWith' | 'equals' | 'notEquals' | 'in' | 'dateRange';
export type SavedShownColumns = { [field: string]: { sortOrder?: number } };

export class DataTableColumnBuilder {

    public static readonly ORDER_ASCENDING = 1;
    public static readonly ORDER_DESCENDING: number = -1;
    public static readonly ORDER_NONE = 0;

    private readonly columns: DataTableColumn[];
    private column: DataTableColumn;
    private readonly shownColumns: SavedShownColumns;

    private constructor(shownColumns: SavedShownColumns) {
        this.columns = [];
        this.shownColumns = shownColumns;
    }

    public static create(): DataTableColumnBuilder {
        return new DataTableColumnBuilder(undefined);
    }

    public static createWithShownColumnsArray(shownColumns: SavedShownColumns): DataTableColumnBuilder {
        return new DataTableColumnBuilder(shownColumns);
    }

    public static applyLazyLoadEventData(event: LazyLoadEvent, columns: DataTableColumn[]): void {
        if (columns != null && event != null) {
            for (let column of columns) {
                column.defaultFilterValue = undefined;
                column.sortOrder = 0;

                if (event.filters != null && event.filters[column.field] != null) {
                    column.defaultFilterValue = {
                        label: '',
                        value: event.filters[column.field].value
                    };
                }

                if (column.field === event.sortField) {
                    column.sortOrder = event.sortOrder;
                } else {
                    column.sortOrder = undefined;
                }
            }
        }
    }

    public addColumn(column: DataTableColumn): DataTableColumnBuilder {
        this.column = column;
        this.columns.push(column);
        return this;
    }

    public add(field: string, header: string, defaultVisible?: boolean): DataTableColumnBuilder {
        let shownColumn = this.shownColumns != null && this.shownColumns[field] != null ? this.shownColumns[field] : null;

        let columnVisible = this.shownColumns != null ? shownColumn != null : defaultVisible;
        let sortOrder = shownColumn != null ? shownColumn.sortOrder : DataTableColumnBuilder.ORDER_NONE;
        let index = this.columns.length;

        this.addColumn(new DataTableColumn(field, header, index, columnVisible, defaultVisible, sortOrder));

        return this;
    }

    public makeFilterable(): DataTableColumnBuilder {
        this.column.filterable = true;
        return this;
    }

    public setFilterMatchMode(matchMode: ColumnFilterMatchMode): DataTableColumnBuilder {
        this.column.filterMatchMode = matchMode;
        return this;
    }

    public makeSortable(): DataTableColumnBuilder {
        this.column.sortable = true;
        return this;
    }

    public disableSorting(): DataTableColumnBuilder {
        this.column.sortable = false;
        return this;
    }

    public setFilterValues(value: SelectItem[], sortable = false): DataTableColumnBuilder {
        this.column.filterValues = value;
        this.column.sortable = sortable;
        return this;
    }

    public setDefaultFilterValue(value: SelectItem): DataTableColumnBuilder {
        this.column.defaultFilterValue = value;
        return this;
    }

    public build(): DataTableColumn[] {
        return this.columns;
    }

    public setDefaultSortOrder(value: number): DataTableColumnBuilder {
        if (!this.isUserSortOrderDefined()) {
            this.column.sortOrder = value;
        }

        return this;
    }

    private isUserSortOrderDefined(): boolean {
        let found = false;

        if (this.shownColumns != null) {
            for (let column in this.shownColumns) {
                if (this.shownColumns[column].sortOrder) {
                    found = true;
                    break;
                }
            }
        }

        return found;
    }

    public applyLazyLoadEventData(event: LazyLoadEvent): DataTableColumnBuilder {
        DataTableColumnBuilder.applyLazyLoadEventData(event, this.columns);
        return this;
    }
}
