<div class="gauge-chart-parent">
    <div class="gauge-ring">
        <div class="arrow-container" [ngStyle]="{'transform': 'rotate(' + arrowRotation + 'deg'}"></div>
    </div>
    <div class="gauge-labels">
        <div class="gauge-summary">
            <p>{{ ('REPORTS.CONVERSION.ORDERS_COUNT') | translate }}:<b [style]="{color: greenColor}">{{ ordersCount | number: '1.0-0g' }}</b></p>
            <p>{{ ('REPORTS.CONVERSION.OFFERS_COUNT') | translate }}:<b>{{ offersCount | number: '1.0-0g' }}</b></p>
        </div>
    </div>
</div>
