import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {ApplicationPrivilege} from '../../auth/application-privilege';
import {Permissions} from '../../auth/permission.service';
import {SecondLevelMenu} from '../../common/second-level-menu/SecondLevelMenu';
import {NavigationLink} from '../../NavigationLink';
import {SidenavController} from '../../sidenav-controller';
import {PricelistTarget} from "./pricelist";

type PriceListsMenuOption = 'pricelists_windows' | 'pricelists_configs' | 'pricesheets' | 'pricetables_windows' | 'pricetables_configs' | 'shipping-pricetables' | 'dealer-discounts-per-system'
    | 'dealer-discounts-per-supplier' | 'dealer-discounts-per-configurable-addon' | 'rotSettings';

@Component({
    selector: 'app-price-lists',
    templateUrl: './price-lists.component.html',
    styleUrls: ['../../second-level-menu.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceListsComponent implements OnInit, OnDestroy, SecondLevelMenu<PriceListsMenuOption> {

    showMenu = true;
    showMenuSubscription: Subscription;
    sideNavItems: NavigationLink<PriceListsMenuOption>[] = [];

    activeSubComponent: PriceListsMenuOption;
    targets = PricelistTarget;

    constructor(private permissions: Permissions,
                private sidenavController: SidenavController,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.prepareSideNavItems();
        this.activatedRoute.paramMap.subscribe(paramMap => {
            this.switchToSubComponent((paramMap.get('view') as PriceListsMenuOption) || this.sideNavItems[0].cssClass);
        });
        this.showMenuSubscription = this.sidenavController.visibilityChange.subscribe(visible => {
            this.showMenu = visible;
            this.changeDetector.markForCheck();
        });
    }

    ngOnDestroy(): void {
        if (this.showMenuSubscription != undefined) {
            this.showMenuSubscription.unsubscribe();
        }
    }

    private prepareSideNavItems(): void {
        this.sideNavItems = [];

        const pricePermissions: {roles: ApplicationPrivilege[]} = {
            roles: ['ROLE_READ_PRICELIST', 'ROLE_CREATE_PRICELIST', 'ROLE_EDIT_PRICELIST', 'ROLE_DELETE_PRICELIST']
        };

        const dealerDiscountPermissions: {roles: ApplicationPrivilege[]} = {
            roles: ['ROLE_READ_DEALER_DISCOUNT', 'ROLE_EDIT_DEALER_DISCOUNT']
        };

        this.sideNavItems.push(new NavigationLink('', 'pricelists_windows', '', pricePermissions, 'PRICE_LISTS_DEFINITION.TITLE'));
        this.sideNavItems.push(new NavigationLink('', 'pricelists_configs', '', pricePermissions, 'PRICE_LISTS_CONFIGS_DEFINITION.TITLE'));
        this.sideNavItems.push(new NavigationLink('', 'pricesheets', '', pricePermissions, 'NAVIGATION.ITEMS.PRICESHEETS'));
        this.sideNavItems.push(new NavigationLink('', 'pricetables_windows', '', pricePermissions, 'NAVIGATION.ITEMS.PRICE-LISTS.PRICE_TABLES.MENU'));
        this.sideNavItems.push(new NavigationLink('', 'pricetables_configs', '', pricePermissions, 'NAVIGATION.ITEMS.PRICE-LISTS.PRICE_TABLES_CONFIGS.MENU'));
        this.sideNavItems.push(new NavigationLink('', 'shipping-pricetables', '', pricePermissions, 'NAVIGATION.ITEMS.PRICE-LISTS.SHIPPING_PRICE_TABLES.MENU'));
        this.sideNavItems.push(new NavigationLink('', 'dealer-discounts-per-system', '', dealerDiscountPermissions, 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.SYSTEMS.MENU'));
        this.sideNavItems.push(new NavigationLink('', 'dealer-discounts-per-supplier', '', dealerDiscountPermissions, 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.SUPPLIERS.MENU'));
        this.sideNavItems.push(new NavigationLink('', 'dealer-discounts-per-configurable-addon', '', dealerDiscountPermissions, 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.CONFIGURABLE_ADDONS.MENU'));
        this.sideNavItems.push(new NavigationLink('', 'rotSettings', '', { roles: ['ROLE_EDIT_ROT_SETTINGS'] }, 'NAVIGATION.ITEMS.ROT_SETTINGS'));

        this.sideNavItems = this.sideNavItems.filter((item) => {
            return this.permissions.isPermitted(item.roles);
        });
    }

    switchToSubComponent(item: PriceListsMenuOption): void {
        this.activeSubComponent = item;
    }

    navigateToSubComponent(element: PriceListsMenuOption): void {
        this.router.navigate(['.', {view: element}], {relativeTo: this.activatedRoute});
    }

    isActiveSubComponent(cssClass: PriceListsMenuOption): boolean {
        return this.activeSubComponent === cssClass;
    }
}
