<div class="dialog-window">
    <p-dialog [visible]="true" [resizable]="false" [modal]="true" (onHide)="cancel()" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title">{{ "USER-DETAILS.EDIT-USER-TITLE" | translate }}
            </div>
        </p-header>
        <div class="scrolled-dialog">
            <app-user-details-form [validationErrors]="validationErrors" [roles]="availableRoles"
                                   [subsystems]="existingSubsystems" [user]="user" [additionalRoles]="secondaryRoles"
                                   [file]="file" (fileChange)="handleFileChange($event)"></app-user-details-form>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button label="{{'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                                   (onClick)="submit()"></app-simple-button>
                <app-simple-button label="{{'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="cancel()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
