import {ConfigurableAddonPositionModel} from '../../../../window-editor/sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {GlobalAddConfigAddonsDialogData} from "../position-list-dialogs";
import {Position} from "../position";
import {ConfigurableAddonDialogEventModel} from "./ConfigurableAddonDialogEventModel";
import {MultilanguageField} from "../../../../../../supportedLanguages";

export class GlobalAddConfigAddonsData {
    windowPositions: Position[];
    addonModels: ConfigurableAddonPositionModel[];
    configurableAddonName: MultilanguageField;

    constructor(globalAddData?: GlobalAddConfigAddonsDialogData, eventModels?: ConfigurableAddonDialogEventModel[]) {
        this.windowPositions = [];
        this.addonModels = [];
        if (eventModels) {
            this.configurableAddonName = eventModels[0].getConfigurableAddonName();
            this.addonModels = eventModels.map(
                event => new ConfigurableAddonPositionModel(event.getPosition(), event.getConfigurableAddon()));
        }
        if (globalAddData) {
            this.windowPositions = globalAddData.selectedPositions;
        }
    }
}
