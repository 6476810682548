import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {Privilege} from '../../../auth/privilege';
import {PrivilegeService} from '../../../auth/privilege.service';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {Role} from './role';
import {RoleService} from './role.service';

@Component({
    selector: 'app-role',
    templateUrl: './role.component.html',
    providers: [RoleService, PrivilegeService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RoleComponent extends CrudCommonComponent<Role, RoleService> implements OnInit {

    @ViewChild('dt')
    table: Table;

    privileges: Privilege[];

    private readonly privilegeService: PrivilegeService;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, RoleService, 'ROLE', 'Role');
        this.privilegeService = injector.get(PrivilegeService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.privilegeService.getGrantablePrivileges().subscribe(
            privileges => this.privileges = privileges
        );
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    getNewItem(): Role {
        return new Role();
    }
}
