import * as _ from 'underscore';
import {AreaSpecification} from "../../../../../window-designer/drawing-data/AreaSpecification";
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {SubWindowData} from "../../../../../window-designer/drawing-data/SubWindowData";
import {DataKeys, DrawingUtil, MinMaxXY} from "../../../../../window-designer/drawing-util";
import {ConfigAddonOpening} from "../../../../../window-designer/entities/ConfigAddonOpening";
import {ConfigurableAddon} from "../../../../../window-designer/entities/ConfigurableAddon";
import {ConfigAddonApplication} from "../../../../../window-designer/enums/ConfigAddonApplication";
import {ConfigAddonOpeningType} from "../../../../../window-designer/enums/ConfigAddonOpeningType";
import {ProfilesCompositionDistances} from '../../../../../window-designer/profiles-composition-distances';
import {SubwindowPolygons} from "../../../../../window-designer/subwindow-polygons";
import {ConfigurableAddonOpeningsUtils} from '../../../../../window-designer/utils/ConfigurableAddonOpeningsUtils';
import {CutsUtil} from "../../../../../window-designer/utils/cutUtils";
import {WindowCalculator} from "../../../../../window-designer/window-calculator";
import {OpeningDirection} from '../../../../../window-designer/window-types/opening-direction';
import {OpeningDirectionUtils} from '../../../../../window-designer/window-types/opening-direction-utils';
import {SubWindowTypeCode} from "../../../../../window-designer/window-types/subwindow-type-code";
import {ConfigurableAddonDialogEventModel} from '../../offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonDialogEventModel';
import {Opcja} from "../../offers/position/position-list/ConfigurableAddonModel/Opcja";
import {ConfigurableAddonPositionModel} from '../sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {CategoryWithAutoOptions} from "../../config-editor/config-editor.component";
import {AutoOption} from "../../../window-system/addons/addon";

export class ConfigurableAddonUtils {

    static saveAllModelsToPositions(configurableAddons: ConfigurableAddonPositionModel[]) {
        configurableAddons.forEach(model => {
            let id = model.position.id || model.position.assignedId;
            ConfigurableAddonUtils.setAddonModel(id, configurableAddons);
        });
    }

    static resizeAllNew(data: DrawingData, configurableAddons: ConfigurableAddonPositionModel[],
                        profileCompositionDistances: ProfilesCompositionDistances) {
        let relevantAssignedIds = configurableAddons.map(model => model.position.assignedId);
        this.resizeAll(data, configurableAddons, profileCompositionDistances, false, undefined, relevantAssignedIds);
    }

    static resizeAll(data: DrawingData, configurableAddons: ConfigurableAddonPositionModel[],
                     profileCompositionDistances: ProfilesCompositionDistances,
                     skipValidation: boolean,
                     totalBoundingBox?: MinMaxXY, relevantAssignedIds = undefined): void {
        let nonDeletedModels = configurableAddons.filter(model => model.configurableAddon);
        if (!totalBoundingBox) {
            totalBoundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);
        }
        let subwindowsPolygonsMap = ConfigurableAddonOpeningsUtils.prepareSubwindowsPolygonsMap(data, totalBoundingBox, profileCompositionDistances, skipValidation);
        this.resizeWindowAddons(data, totalBoundingBox, subwindowsPolygonsMap, nonDeletedModels, relevantAssignedIds);
        this.resizeSubwindowsAddons(data, subwindowsPolygonsMap, nonDeletedModels, relevantAssignedIds);
        this.resizeAreasAddons(data, totalBoundingBox, profileCompositionDistances, skipValidation, nonDeletedModels, relevantAssignedIds);
    }

    static getAddonModel(id: number,
                         configurableAddons: ConfigurableAddonPositionModel[]): ConfigurableAddonPositionModel {
        let addon = configurableAddons.find(
            model => model.position.id === id || model.position.assignedId === id);
        if (!addon) {
            throw new Error("Configurable addon with id " + id + " not found.");
        }
        return addon;
    }

    static getAddon(id: number, configurableAddons: ConfigurableAddonPositionModel[]): ConfigurableAddon {
        return this.getAddonModel(id, configurableAddons).configurableAddon;
    }

    static addNewAddonToSelectedElement(models: ConfigurableAddonDialogEventModel[],
                                        clickedSnapElements: { type: string; elements: Snap.Element[] },
                                        globalAddonIds: number[]) {
        const categoriesWithAutoOptions = models[0].getCategoriesWithAutoOptions() || [];
        for (let i in models) {
            let generatedAddonId = models[i].getPosition().assignedId;
            let addon = models[i].getConfigurableAddon();
            let clickedElement: Snap.Element = clickedSnapElements.elements[i];
            switch (addon.application) {
                case ConfigAddonApplication.WINDOW:
                    globalAddonIds.push(generatedAddonId);
                    break;
                case ConfigAddonApplication.SUBWINDOW:
                    let subWindow: SubWindowData = clickedElement.data(DataKeys.SUBWINDOW);
                    subWindow.configurableAddonIds.push(generatedAddonId);
                    break;
                case ConfigAddonApplication.AREA:
                    let area: AreaSpecification = clickedElement.data(DataKeys.AREA);
                    area.configurableAddonIds.push(generatedAddonId);
                    break;
                default:
                    console.warn("Unsupported configurable addon application.");
                    break;
            }
            let typeCode = addon.application === ConfigAddonApplication.WINDOW ? null :
                clickedElement.data(DataKeys.SUBWINDOW).typeCode;
            this.setAutoValues(categoriesWithAutoOptions, addon, typeCode);
        }
    }

    static setAutoValues(categoriesWithAutoOptions: CategoryWithAutoOptions[], addon: ConfigurableAddon, subWindowTypeCode?: SubWindowTypeCode) {
        if (categoriesWithAutoOptions.length === 0) {
            return;
        }
        switch (addon.application) {
            case ConfigAddonApplication.WINDOW: // not used but left for future
                this.setStrona(categoriesWithAutoOptions, addon, AutoOption.RIGHT);
                break;
            case ConfigAddonApplication.SUBWINDOW:
            case ConfigAddonApplication.AREA:
                if (subWindowTypeCode == undefined) {
                    console.warn("Missing subwindow type code.");
                    break;
                }
                this.setStronaAuto(categoriesWithAutoOptions, addon, subWindowTypeCode);
                break;
            default:
                console.warn("Unsupported configurable addon application.");
                break;
        }
    }

    public static setStronaAuto(categoriesWithAutoOptions: CategoryWithAutoOptions[], addon: ConfigurableAddon, subWindowTypeCode: SubWindowTypeCode, forceSet = false) {
        categoriesWithAutoOptions.forEach(category => {
            let currentValueId = addon.configData.sidebarAddons[category.symbol];
            if (currentValueId == null) {
                return;
            }
            let currentAutoOption = category.addons[currentValueId];
            if (currentAutoOption !== AutoOption.AUTO && !forceSet) {
                return;
            }
            const opening = OpeningDirectionUtils.getSubwindowOpening(subWindowTypeCode);
            const sideValue = opening === OpeningDirection.L ? AutoOption.LEFT : AutoOption.RIGHT;
            this.setStrona([category], addon, sideValue);
        });
    }

    public static setStrona(categoriesWithAutoOptions: CategoryWithAutoOptions[], addon: ConfigurableAddon, forceValue: AutoOption) {
        categoriesWithAutoOptions.forEach(category => {
            let forceValueId = Object.keys(category.addons).find(key => category.addons[key] === forceValue);
            addon.configData.sidebarAddons[category.symbol] = +forceValueId;
        });
    }

    public static changeGlazingBeads(data: DrawingData, configurableAddons: ConfigurableAddonPositionModel[],
                                     relevantAssignedIds: number[] = undefined): void {
        let subwindowBeadIds = [];
        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            let subwindowBeadId = ConfigurableAddonOpeningsUtils.getCommonElementOrUndefined(
                subwindow.areasSpecification.map(area => area.glazingBead.id));
            subwindowBeadIds.push(subwindowBeadId);
            this.setGlazingBeads(subwindowBeadId, subwindow, configurableAddons, relevantAssignedIds);
            subwindow.areasSpecification.forEach(area => {
                this.setGlazingBeads(area.glazingBead.id, area, configurableAddons, relevantAssignedIds);
            });
        }));
        let globalBeadId = ConfigurableAddonOpeningsUtils.getCommonElementOrUndefined(subwindowBeadIds);
        this.setGlazingBeads(globalBeadId, data, configurableAddons, relevantAssignedIds);
    }

    private static setGlazingBeads(glazingBeadId: number, parent: DrawingData | SubWindowData | AreaSpecification,
                                   configurableAddons: ConfigurableAddonPositionModel[],
                                   relevantAssignedIds: number[]): void {
        if (this.hasConfigAddons(parent)) {
            this.getRelevantIds(parent, relevantAssignedIds).forEach(id => {
                let addon: ConfigurableAddon = this.getAddon(id, configurableAddons);
                addon.glazingBeadId = glazingBeadId;
            });
        }
    }

    private static getRelevantIds(parent: DrawingData | SubWindowData | AreaSpecification,
                                  relevantAssignedIds: number[]) {
        let allIds = parent.configurableAddonIds;
        return (relevantAssignedIds == undefined) ? allIds : (allIds.filter((n) => relevantAssignedIds.includes(n)));
    }

    private static resizeWindowAddons(data: DrawingData, totalBoundingBox: MinMaxXY,
                                      subwindowsPolygonsMap: SubwindowPolygons[],
                                      configurableAddons: ConfigurableAddonPositionModel[],
                                      relevantAssignedIds: number[]) {
        if (this.hasConfigAddons(data)) {
            let framePoints = WindowCalculator.getOuterFramePoints(data.windows, data.cuts);
            let framePointsOpening = ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME, framePoints);
            let frameOpeningPoints: number[];
            let wingPoints: number[];
            let pluginPoints: number[];
            let glassPoints: number[];
            let allSubwindows = _.chain(data.windows).map(window => window.subWindows).flatten().value();
            let wingedParent = allSubwindows.some(subwindow => !WindowCalculator.isSubWindowF(subwindow));
            if (framePointsOpening.rectangular) {
                let framePointsBox = DrawingUtil.calculatePolygonTotalBoundingBox(framePoints);

                let topSubwindows = allSubwindows.filter(
                    subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsXPoints(subwindow), framePointsBox.minX));
                let bottomSubwindows = allSubwindows.filter(
                    subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsXPoints(subwindow), framePointsBox.maxX));
                let leftSubwindows = allSubwindows.filter(
                    subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsYPoints(subwindow), framePointsBox.minY));
                let rightSubwindows = allSubwindows.filter(
                    subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsYPoints(subwindow), framePointsBox.maxY));

                wingedParent = [...topSubwindows, ...bottomSubwindows, ...leftSubwindows, ...rightSubwindows].some(
                    subwindow => !WindowCalculator.isSubWindowF(subwindow));

                frameOpeningPoints =
                    ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows,
                        leftSubwindows, rightSubwindows, subwindowsPolygonsMap, ConfigAddonOpeningType.FRAME_OPENING);
                wingPoints =
                    ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows,
                        leftSubwindows, rightSubwindows, subwindowsPolygonsMap, ConfigAddonOpeningType.WING);
                pluginPoints =
                    ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows,
                        leftSubwindows, rightSubwindows, subwindowsPolygonsMap, ConfigAddonOpeningType.PLUGIN);
                glassPoints =
                    ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows,
                        leftSubwindows, rightSubwindows, subwindowsPolygonsMap, ConfigAddonOpeningType.GLASS);
            }
            let openings = [
                framePointsOpening,
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME_OPENING, frameOpeningPoints),
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.WING, wingPoints),
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.PLUGIN, pluginPoints),
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.GLASS, glassPoints)
            ];
            this.getRelevantIds(data, relevantAssignedIds).forEach(id => {
                let addon: ConfigurableAddon = this.getAddon(id, configurableAddons);
                addon.dimensionsOverride = this.prepareDimensionsString((totalBoundingBox.maxX - totalBoundingBox.minX),
                    (totalBoundingBox.maxY - totalBoundingBox.minY));
                addon.wingedParent = wingedParent;
                this.setOpenings(addon, openings);
            });
        }
    }

    private static resizeSubwindowsAddons(data: DrawingData, subwindowsPolygonsMap: SubwindowPolygons[],
                                          configurableAddons: ConfigurableAddonPositionModel[],
                                          relevantAssignedIds: number[]) {
        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            if (this.hasConfigAddons(subwindow)) {
                let subwindowPolygons = subwindowsPolygonsMap[subwindow.generatedId];
                let openings = [
                    ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME, subwindowPolygons.framePoints),
                    ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME_OPENING, subwindowPolygons.frameOpeningPoints),
                    ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.WING, subwindowPolygons.wingPoints),
                    ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.PLUGIN, subwindowPolygons.pluginPoints),
                    ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.GLASS, subwindowPolygons.glassPoints)
                ];
                let wingedParent = !WindowCalculator.isSubWindowF(subwindow);
                this.getRelevantIds(subwindow, relevantAssignedIds).forEach(id => {
                    let addon: ConfigurableAddon = this.getAddon(id, configurableAddons);
                    addon.parentInfo = "(" + SubWindowTypeCode[subwindow.typeCode] + ")";
                    addon.dimensionsOverride = this.prepareDimensionsString((subwindow.points[2] - subwindow.points[0]),
                        (subwindow.points[5] - subwindow.points[1]));
                    addon.wingedParent = wingedParent;
                    this.setOpenings(addon, openings);
                });
            }
        }));
    }

    private static resizeAreasAddons(data: DrawingData, totalBoundingBox: MinMaxXY,
                                     profileCompositionDistances: ProfilesCompositionDistances,
                                     skipValidation: boolean,
                                     configurableAddons: ConfigurableAddonPositionModel[],
                                     relevantAssignedIds: number[]) {
        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            let cuts = data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subwindow.points, cut));
            let totalInnerFramePoints = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow, cuts,
                totalBoundingBox, profileCompositionDistances, skipValidation);
            let totalGlazingBeadPoints = WindowCalculator.getTotalGlazingBeadsPoints(subwindow, cuts,
                totalBoundingBox, profileCompositionDistances, skipValidation);
            let wingedParent = !WindowCalculator.isSubWindowF(subwindow);
            subwindow.areasSpecification.forEach(area => {
                if (this.hasConfigAddons(area)) {
                    let pluginPoints = WindowCalculator.getFrameInnerEdgePoints(subwindow, area.definingMullions,
                        totalInnerFramePoints);
                    let glassPoints = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions,
                        totalGlazingBeadPoints, profileCompositionDistances);
                    let openings = [
                        ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.PLUGIN, pluginPoints),
                        ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.GLASS, glassPoints)
                    ];
                    let relevantIds = this.getRelevantIds(area, relevantAssignedIds);
                    relevantIds.forEach(id => {
                        let addon: ConfigurableAddon = this.getAddon(id, configurableAddons);
                        addon.parentInfo = area.ordinalNumber + " (" + SubWindowTypeCode[subwindow.typeCode] + ")";
                        addon.dimensionsOverride = this.prepareDimensionsString(area.pricingWitdh, area.pricingHeight);
                        addon.wingedParent = wingedParent;
                        this.setOpenings(addon, openings);
                        this.setAddonModel(id, configurableAddons);
                        this.perpetuateEditedAddonModel(id, configurableAddons);
                    });
                }
            });
        }));
    }

    public static deleteAddon(addonModel: ConfigurableAddonPositionModel, data: DrawingData,
                              configurableAddons: ConfigurableAddonPositionModel[]): void {
        let deleteId = (idToDelete: number, idsArray: number[]) => {
            let index = idsArray.indexOf(idToDelete);
            if (index > -1) {
                idsArray.splice(index, 1);
            }
            return index > -1;
        };
        let id = addonModel.position.id || addonModel.position.assignedId;
        let addon = addonModel.configurableAddon;
        switch (addon.application) {
            case ConfigAddonApplication.WINDOW:
                deleteId(id, data.configurableAddonIds);
                break;
            case ConfigAddonApplication.SUBWINDOW:
                data.windows.some(
                    window => window.subWindows.some(subwindow => deleteId(id, subwindow.configurableAddonIds)));
                break;
            case ConfigAddonApplication.AREA:
                data.windows.some(window => window.subWindows.some(
                    subwindow => subwindow.areasSpecification.some(area => deleteId(id, area.configurableAddonIds))));
                break;
        }

        if (addonModel.position.id) {
            let index = configurableAddons.indexOf(addonModel);
            configurableAddons[index].configurableAddon = undefined;
        } else {
            let index = configurableAddons.indexOf(addonModel);
            configurableAddons.splice(index, 1);
        }
    }

    public static perpetuateEditedAddonModel(id: number, configurableAddons: ConfigurableAddonPositionModel[]) {
        let addonModel = this.getAddonModel(id, configurableAddons);
        addonModel.configurableAddon = JSON.parse(addonModel.position.data);
    }

    public static setAddonModel(id: number, configurableAddons: ConfigurableAddonPositionModel[]) {
        let addonModel = this.getAddonModel(id, configurableAddons);
        addonModel.position.data = JSON.stringify(addonModel.configurableAddon);
    }

    static addonsPresent(data: DrawingData): boolean {
        let present = (parent: any) => (parent.configurableAddonIds && parent.configurableAddonIds.length);
        return (present(data) || data.windows.some(window => window.subWindows.some(
            subwindow => present(subwindow) || subwindow.areasSpecification.some(area => present(area)))));
    }

    public static deleteAllAddons(data: DrawingData, configurableAddons: ConfigurableAddonPositionModel[]): void {
        let stillPresent = configurableAddons.filter(model => model.configurableAddon);
        stillPresent.forEach(model => this.deleteAddon(model, data, configurableAddons));
    }

    public static removeAllAddonIds(data: DrawingData): void {
        data.configurableAddonIds = [];
        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            subwindow.configurableAddonIds = [];
            subwindow.areasSpecification.forEach(area => {
                area.configurableAddonIds = [];
            });
        }));
    }

    private static prepareDimensionsString(width: number, height: number): string {
        return width + "x" + height;
    }

    private static hasConfigAddons(parent: DrawingData | SubWindowData | AreaSpecification): boolean {
        return parent.configurableAddonIds != undefined && parent.configurableAddonIds.length > 0;
    }

    private static setOpenings(addon: ConfigurableAddon, openings: ConfigAddonOpening[]) {
        addon.openings = openings.filter(el => el != undefined);
    }
}
