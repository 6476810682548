import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {ValidationErrors} from '../../../../common/validation-errors';
import {SystemGlazingPackageField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GlazingPackageFieldUsage} from "../../catalog-field-usage";
import {CatalogItemTag} from '../../catalog-item-tags/catalog-item-tag';
import {GlazingPackage, GlazingPackageTarget} from '../glazing-package';
import {WebshopGlazingPackage} from '../webshop-glazing-package/webshop-glazing-package';

@Component({
    selector: 'app-glazing-package-general-data-form',
    templateUrl: './glazing-package-general-data-form.component.html',
    styleUrls: ['./glazing-package-general-data-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlazingPackageGeneralDataFormComponent implements OnInit {

    @Input()
    item: GlazingPackage;

    @Input()
    validationErrors: ValidationErrors = {};

    @Input()
    file: File;

    @Input()
    tags: SelectItem[];

    @Input()
    target: GlazingPackageTarget;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly fileChange = new EventEmitter<File>();

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    glazingPackageTargets = GlazingPackageTarget;
    selectedTags: CatalogItemTag[];

    fieldUsage: GlazingPackageFieldUsage;
    SystemGlazingPackageField = SystemGlazingPackageField;
    WebshopGlazingPackage = WebshopGlazingPackage;

    constructor(public translate: TranslateService) {
        this.fieldUsage = new GlazingPackageFieldUsage(this);
    }

    ngOnInit(): void {
        if (this.isWebshopGlazingPackage(this.item)) {
            this.selectedTags = (this.item.tagIds || [])
                .map(tagId => this.tags.find(tag => tag.value.id === tagId))
                .filter(tag => tag != undefined)
                .map(tag => tag.value);
        } else {
            this.selectedTags = [];
        }
    }

    handleTagsChange(tags: CatalogItemTag[]): void {
        if (this.isWebshopGlazingPackage(this.item)) {
            this.selectedTags = tags;
            this.item.tagIds = tags.map(tag => tag.id);
        }
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    isWebshopGlazingPackage(glazingPackage: GlazingPackage): glazingPackage is WebshopGlazingPackage {
        return glazingPackage.target === GlazingPackageTarget.WEBSHOP_GLAZING;
    }
}
