import {SelectItem} from 'primeng/api/selectitem';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {
    PositionDesignerCatalogDependentOption,
    WindowDesignerCatalogDependentOptionAction
} from '../../window-system/window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option';

class DependentFieldId {
    static pack<FieldEnum>(dependentFieldId: FieldEnum, dependentFieldValue: string): string {
        return `${dependentFieldId}___${dependentFieldValue}`;
    }
}

class ValueRequirements {
    requiredValues: string[] = [];
    disallowedValues: string[] = [];
}

interface ExtendedSelectItem extends SelectItem {
    isNew?: boolean;
}

export class PositionEditorFieldContentProvider {

    protected readonly changeNotifiers = new Map<string, Subject<void>>();
    protected readonly items = new Map<string, BehaviorSubject<ExtendedSelectItem[]>>();
    protected readonly _filteringEnabled = new BehaviorSubject<boolean>(false);
    protected readonly itemsObservables = new Map<string, Observable<ExtendedSelectItem[]>>();
    protected readonly lastFilteredItems = new Map<string, ExtendedSelectItem[]>();
    protected readonly hasNewItemsAfterRefiltering = new Map<string, boolean>();

    protected readonly selectedValues = new Map<string, string>();

    protected readonly fieldsThatDependOnField = new Map<string, Set<string>>();
    protected readonly fieldValueRequirements = new Map<string, Map<string, ValueRequirements>>();

    public readonly fieldsWithUnavailableValues: string[] = [];

    public readonly afterFilter = new Subject<void>();

    protected constructor(codes: string[]) {
        this.initializeProvider(codes);
    }

    public initializeProvider(fieldEnum: string[]) {
        for (let fieldE of fieldEnum) {
            this.initializeProviderForField(fieldE);
        }
    }

    public initializeProviderForField(fieldEnum: string) {
        if (this.changeNotifiers.has(fieldEnum)) {
            return;
        }
        const changeNotifier = new BehaviorSubject<void>(undefined);
        this.changeNotifiers.set(fieldEnum, changeNotifier);
        const items = new BehaviorSubject<SelectItem[]>([]);
        this.items.set(fieldEnum, items);
        this.lastFilteredItems.set(fieldEnum, []);
        this.hasNewItemsAfterRefiltering.set(fieldEnum, false);
        this.itemsObservables.set(fieldEnum, combineLatest([items, changeNotifier, this._filteringEnabled]).pipe(
            map(agg => this.filterItems(agg[0], fieldEnum, agg[2]))
        ));
    }

    getItemsStream(field: string): Observable<SelectItem[]> {
        return this.itemsObservables.get(field);
    }

    resetNewItems() {
        this.hasNewItemsAfterRefiltering.clear();
    }

    getItems(field: string): SelectItem[] {
        return this.lastFilteredItems.get(field);
    }

    getUnfilteredItems(field: string): SelectItem[] {
        return this.items.get(field).getValue();
    }

    private filterItems(items: ExtendedSelectItem[], field: string, filter: boolean): ExtendedSelectItem[] {
        const oldItems = this.lastFilteredItems.get(field);
        const filteredItems = filter ? items.filter(selectItem => {
            let value = selectItem.value;
            if (typeof value !== 'string') {
                value = '' + value;
            }
            const requiredValuesByField = this.fieldValueRequirements.get(DependentFieldId.pack(field, value));
            if (requiredValuesByField == undefined) {
                return true; // current value does not depend on anything
            }
            const hideRulesState = {
                hasHideRules: false,
                matched: true
            };
            for (let requiredValues of requiredValuesByField) {
                const selectedValueInRequiredField = this.selectedValues.get(requiredValues[0]);
                const requiredValueChecker = (requiredValue: string) =>
                    this.requiredValueIsCorrect(selectedValueInRequiredField, requiredValues[0], requiredValue);

                if (requiredValues[1].requiredValues.length > 0 && !requiredValues[1].requiredValues.some(requiredValueChecker)) {
                    return false;
                }
                if (requiredValues[1].disallowedValues.length > 0) {
                    hideRulesState.hasHideRules = true;
                    hideRulesState.matched = requiredValues[1].disallowedValues.some(requiredValueChecker);
                    if (hideRulesState.hasHideRules && hideRulesState.matched) {
                        return false; // hide option
                    }
                }
            }

            return true;
        }) : [...items];

        const valueDifference = (left: ExtendedSelectItem[] = [], right: ExtendedSelectItem[] = []): ExtendedSelectItem[] => {
            const rightValues = right.map(selectItem => selectItem.value);
            return left.filter(leftValue => rightValues.indexOf(leftValue.value) < 0);
        };

        const newItems = valueDifference(filteredItems, oldItems);
        const deletedItems = valueDifference(oldItems, filteredItems);
        const hasNewItems = newItems.length > 0;
        const hasDeletedItems = deletedItems.length > 0;

        if (!hasNewItems && !hasDeletedItems) {
            return oldItems; // return same array for change detection reasons
        }

        for (let newItem of newItems) {
            newItem.isNew = true;
        }
        for (let deletedItem of deletedItems) {
            deletedItem.isNew = false;
        }

        this.lastFilteredItems.set(field, filteredItems);
        if (hasNewItems) {
            this.hasNewItemsAfterRefiltering.set(field, true);
        } else if (hasDeletedItems) {
            this.hasNewItemsAfterRefiltering.set(field, filteredItems.some(filteredItem => filteredItem.isNew));
        }
        this.afterFilter.next();
        return filteredItems;
    }

    private refilterItems(field: string) {
        if (this.items.get(field)) {
            this.filterItems(this.items.get(field).getValue(), field, this._filteringEnabled.getValue());
        }
    }

    protected requiredValueIsCorrect(currentValue: string, requiredField: string, requiredValue: string): boolean {
        return currentValue === requiredValue || (requiredValue == undefined && currentValue == undefined);
    }

    set filteringEnabled(value: boolean) {
        this._filteringEnabled.next(value);
    }

    notifyFieldChanged(field: string, value: any): void {
        if (value != undefined && typeof value !== 'string') {
            value = '' + value;
        }
        if (this.selectedValues.get(field) === value) {
            return;
        }

        if (value != undefined) {
            this.selectedValues.set(field, value);
        } else {
            this.selectedValues.delete(field);
        }

        // Send new option lists to all fields
        const dependentFields = this.fieldsThatDependOnField.get(field);
        if (dependentFields != undefined) {
            for (let dependentField of dependentFields) {
                this.triggerChangeNotifiers(dependentField);
            }
        }
    }

    triggerChangeNotifiers(field: string): void {
        const subject = this.changeNotifiers.get(field);
        if (!subject.observed) {
            // if there are no subscribers because the field is currently hidden, we need to manually refilter options
            this.refilterItems(field);
        }
        subject.next();
    }

    hasNewItems(field: string): boolean {
        return this.hasNewItemsAfterRefiltering.get(field);
    }

    clearHasNewItemsMarker(field: string): void {
        for (let selectItem of this.lastFilteredItems.get(field)) {
            selectItem.isNew = false;
        }
        this.hasNewItemsAfterRefiltering.set(field, false);
    }

    setItems(field: string, items: ExtendedSelectItem[], selectedItemIds: number[] = undefined): void {
        if (this.items.get(field) == undefined) {
            this.initializeProviderForField(field);
        }
        this.filterItems(items, field, this._filteringEnabled.getValue());
        let extendedSelectItems = this.lastFilteredItems.get(field);
        if (extendedSelectItems != null) {
            for (let selectItem of extendedSelectItems) {
                selectItem.isNew = false;
            }
            this.hasNewItemsAfterRefiltering.set(field, false);
            this.items.get(field).next(items);
            if (selectedItemIds) {
                if (items.filter(item => !item.available && selectedItemIds.some(id => id === item.value)).length > 0) {
                    this.fieldsWithUnavailableValues.push(field);
                }
            }
        }
    }

    storeFieldDependencies(dependencies: PositionDesignerCatalogDependentOption<string>[]): void {
        this.fieldsThatDependOnField.clear();
        this.fieldValueRequirements.clear();

        const allDependentFields = new Set<string>();

        const addDependentField = (requiredInputId: string, dependentInputId: string) => {
            let dependentFields = this.fieldsThatDependOnField.get(requiredInputId);
            if (dependentFields == undefined) {
                dependentFields = new Set<string>();
                this.fieldsThatDependOnField.set(requiredInputId, dependentFields);
            }
            dependentFields.add(dependentInputId);
            allDependentFields.add(dependentInputId);
        };

        for (let dependency of dependencies) {
            addDependentField(dependency.requiredInputId, dependency.dependentInputId);

            let requiredFieldValuesByField = this.fieldValueRequirements.get(
                DependentFieldId.pack(dependency.dependentInputId, dependency.dependentInputValue));
            if (requiredFieldValuesByField == undefined) {
                requiredFieldValuesByField = new Map<string, ValueRequirements>();
                this.fieldValueRequirements.set(DependentFieldId.pack(dependency.dependentInputId, dependency.dependentInputValue),
                    requiredFieldValuesByField);
            }
            let values = requiredFieldValuesByField.get(dependency.requiredInputId);
            if (values == undefined) {
                values = new ValueRequirements();
                requiredFieldValuesByField.set(dependency.requiredInputId, values);
            }
            switch (dependency.whenMatched) {
                case WindowDesignerCatalogDependentOptionAction.SHOW:
                    values.requiredValues.push(dependency.requiredInputValue);
                    break;
                case WindowDesignerCatalogDependentOptionAction.HIDE:
                    values.disallowedValues.push(dependency.requiredInputValue);
                    break;
                default:
                    break;
            }
        }

        // refilter all inputs to ensure option lists arent changed during angular change detection
        for (let dependentField of allDependentFields) {
            this.refilterItems(dependentField);
        }
    }

    fieldsWithUnavailableValuesPresent(visibleFields: string[]): string[] {
        const fieldVisibleButUnavailableSelected: string[] = [];
        for (let unavailableFields of this.fieldsWithUnavailableValues) {
            if (visibleFields.some(field => field === unavailableFields)) {
                fieldVisibleButUnavailableSelected.push(unavailableFields);
            }
        }
        return fieldVisibleButUnavailableSelected;
    }
}
