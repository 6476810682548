<div class="background" [ngStyle]="{'background-image': 'url(' + backgroundImageUrl + ')'}">
    <div class="new-form-600 thank-you-message-content">
        <div class="new-form-row">
            <div id="logo"></div>
        </div>
        <div class="new-form-row">
            <h3>{{ 'EXTERNAL_USER_OFFER_EDIT.CONTENT.HEADER' | translate }}</h3>
        </div>
        <div class="new-form-row">
            <div>{{ 'EXTERNAL_USER_OFFER_EDIT.CONTENT.BODY' | translate }}</div>
        </div>
    </div>
</div>
