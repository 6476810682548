import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Privilege} from './privilege';

@Injectable()
export class PrivilegeService {

    private static readonly API_URL = 'privilege';

    constructor(private http: HttpClient) {
    }

    getGrantablePrivileges(): Observable<Privilege[]> {
        return this.http.get<Privilege[]>(`${PrivilegeService.API_URL}/grantable`);
    }
}
