<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'CATALOG_ITEM_TAG.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'CATALOG_ITEM_TAG.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" id="tagsTable" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="tagText" sortField="{{ 'tagText.' + translate.currentLang }}" filterField="tagText"
                  header="{{ 'CATALOG_ITEM_TAG.FORM.TAG_TEXT' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-catalogItemTag="rowData">
                {{ catalogItemTag.tagText[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column header="{{ 'CATALOG_ITEM_TAG.FORM.SORT_INDEX' | translate }}" [sortable]="true" field="sortIndex"></p-column>
        <p-column field="color"  header="{{ 'CATALOG_ITEM_TAG.FORM.COLOR' | translate }}">
            <ng-template pTemplate="body" let-catalogItemTag="rowData">
                <div *ngIf="catalogItemTag.color" class="catalog-item-tag-preview" [ngStyle]="{background:  catalogItemTag.color}"></div>
            </ng-template>
        </p-column>
        <p-column field="active" [filter]="showFilters" header="{{ 'CATALOG_ITEM_TAG.FORM.STATUS' | translate }}"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'CATALOG_ITEM_TAG.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'CATALOG_ITEM_TAG.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'CATALOG_ITEM_TAG.FORM.ADD_TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'CATALOG_ITEM_TAG.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-catalog-item-tags-form [item]="item" [iconFile]="file" (iconFileChange)="onFileChange($event)"
                                    [validationErrors]="validationErrors"></app-catalog-item-tags-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ 'CATALOG_ITEM_TAG.FORM.EDIT_TITLE' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" contentStyleClass="scrolled-dialog-400">
    <app-wizard-step label="{{ 'CATALOG_ITEM_TAG.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-catalog-item-tags-form [item]="item" [iconFile]="file" (iconFileChange)="onFileChange($event)"
                                    [validationErrors]="validationErrors"></app-catalog-item-tags-form>
    </app-wizard-step>
</app-wizard-dialog>
