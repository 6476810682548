import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Router} from "@angular/router";
import * as moment from 'moment';
import {BlockUiController} from "../../../block-ui/block-ui-controller";
import {News} from "../../settings/news/news";
import {NewsService} from "../../settings/news/news-service";

@Component({
    selector: 'app-news-preview',
    templateUrl: './news-preview.component.html',
    styleUrls: ['./news-preview.component.css']
})
export class NewsPreviewComponent implements OnInit {

    private static readonly BLOCK_SOURCE_ID = 'NewsPreview';

    @Output() selectedNews: EventEmitter<number> = new EventEmitter<number>();

    previewNews: News[];

    constructor(private readonly newsService: NewsService,
                private readonly router: Router,
                private readonly blockUiController: BlockUiController) {
    }

    ngOnInit(): void {
        this.blockUiController.block(NewsPreviewComponent.BLOCK_SOURCE_ID);
        this.newsService.getPreviewItems(5).subscribe(news => {
            this.previewNews = news.data;
            this.blockUiController.unblock(NewsPreviewComponent.BLOCK_SOURCE_ID);
        });
    }

    goToFullNews() {
        this.router.navigate(['/features/settings/news']);
    }

    openNews(newsId: number) {
        this.selectedNews.emit(newsId);
    }

    isCurrentNews(news: News): boolean {
        return news.published && moment().isBetween(news.validFrom, news.validTo);
    }
}
