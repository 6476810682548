import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {forkJoin, Observable, of} from 'rxjs';
import {map, take} from "rxjs/operators";
import * as _ from 'underscore';
import {WindowSystemType} from "../../../../../../window-designer/catalog-data/window-system-interface";
import {FillingType} from "../../../../../../window-designer/drawing-data/FillingType";
import {Permissions} from "../../../../../auth/permission.service";
import {CommonErrorHandler} from "../../../../../common/CommonErrorHandler";
import {DataServiceHelper} from "../../../../../common/dataServiceHelper";
import {ConvertMillimetersToInchesPipe} from "../../../../../common/service/convert-millimeters-to-inches.pipe";
import {MultilanguageField} from "../../../../../supportedLanguages";
import {CatalogDescriptionEntry} from "../../../../catalog-creator/CatalogDescriptionEntry";
import {AbstractPosition, isWindowPositionType, PositionTarget, PositionType} from '../../../AbstractPosition';
import {ProductionOrdersPositionService} from '../../../production-orders/production-orders-position/production-orders-position.service';
import {PositionService} from "../position.service";
import {AddonProperties} from "./addon-properties";
import {AssemblyProperties} from "./assembly-properties";
import {ConfigProperties} from "./config-properties";
import {GateProperties} from './gate-properties';
import {PreviewWindowAddon} from './PreviewWindowAddon';
import {TransportProperties} from "./transport-properties";
import {WindowProperties} from "./window-properties";

interface PropertyInfo {
    label?: string;
    propName: string;
    multilang?: boolean;
    valuePrefix?: string;
    labels?: string[];
}

interface DataRow {
    header: string;
    values: { value: string | number, toTranslate?: boolean, innerHTML?: boolean }[];
    variousValues?: boolean;
    subtitle?: boolean;
}

@Component({
    selector: 'app-window-properties',
    templateUrl: './window-properties.component.html',
    styleUrls: ['./window-properties.component.css'],
    providers: [PositionService, ProductionOrdersPositionService, DataServiceHelper, ConvertMillimetersToInchesPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowPropertiesComponent {

    @Input()
    properties: WindowProperties[] | GateProperties[] | AddonProperties[] | AssemblyProperties[] | TransportProperties[] | ConfigProperties[];

    @Input()
    positionType: PositionType;

    @Input()
    showProperties: boolean;

    get windowProperties() {
        return this.properties as WindowProperties[];
    }

    get gateProperties() {
        return this.properties as GateProperties[];
    }

    get addonProperties() {
        return this.properties as AddonProperties[];
    }

    private previewedPosition: AbstractPosition;

    constructor(public permission: Permissions,
                public positionService: PositionService,
                public productionOrdersPositionService: ProductionOrdersPositionService,
                public translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private convertMillimetersToInches: ConvertMillimetersToInchesPipe) {
    }

    updatePositionWindowProperties(selectedPosition: AbstractPosition, target: PositionTarget) {
        if (this.previewedPosition === selectedPosition
            && (selectedPosition || {}).type !== PositionType.CONFIG_SYSTEM
            && (selectedPosition || {}).type !== PositionType.STANDALONE_GLAZING_PACKAGE) {
            return;
        }
        this.previewedPosition = selectedPosition;
        this.setShowProperties(false);
        this.properties = [new WindowProperties()];
        if (selectedPosition && selectedPosition.id) {
            this.positionType = selectedPosition.type;
            if (isWindowPositionType(this.positionType)) {
                this.getWindowProperties(selectedPosition, target);
            } else if (this.positionType === PositionType.GATE_SYSTEM) {
                this.getGateProperties(selectedPosition, target);
            } else if (this.positionType === PositionType.BULK_ADDON) {
                this.getAddonProperties(selectedPosition, target);
            } else if (this.positionType === PositionType.ASSEMBLY) {
                this.getAssemblyProperties(selectedPosition, target);
            } else if (this.positionType === PositionType.TRANSPORT) {
                this.getTransportProperties(selectedPosition, target);
            } else if (selectedPosition.type === PositionType.CONFIG_SYSTEM) {
                this.getConfigProperties(selectedPosition, target);
            }
        }
    }

    public getWindowProperties(position: AbstractPosition, target: PositionTarget) {
        let service: Observable<WindowProperties[]>;
        if (target === PositionTarget.PRODUCTION_ORDER) {
            service = this.productionOrdersPositionService.getWindowProperties(position.id)
                .pipe(map(props => [props]));
        } else if (target === PositionTarget.OFFER) {
            service = this.positionService.getPropertiesForMultipleWindows([position.id]);
        }
        service.subscribe({
            next: data => {
                console.info('Position `getWindowProperties` success');
                this.properties = data;
                this.setShowProperties(true);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    public getGateProperties(position: AbstractPosition, target: PositionTarget) {
        let service: Observable<GateProperties[]>;
        if (target === PositionTarget.PRODUCTION_ORDER) {
            service = this.productionOrdersPositionService.getGateProperties(position.id)
                .pipe(map(props => [props]));
        } else if (target === PositionTarget.OFFER) {
            service = this.positionService.getPropertiesForMultipleGates([position.id]);
        }
        service.subscribe({
            next: data => {
                console.info('Position `getGateProperties` success');
                this.properties = data;
                this.setShowProperties(true);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    public getAddonProperties(position: AbstractPosition, target: PositionTarget) {
        let service: Observable<AddonProperties[]>;
        if (target === PositionTarget.PRODUCTION_ORDER) {
            service = this.productionOrdersPositionService.getAddonProperties(position.id)
                .pipe(map(props => [props]));
        } else if (target === PositionTarget.OFFER) {
            service = this.positionService.getPropertiesForMultipleAddons([position.id]);
        }
        service.subscribe({
            next: data => {
                console.info('Position `getAddonProperties` success');
                this.properties = data;
                this.setShowProperties(true);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    public getAssemblyProperties(position: AbstractPosition, target: PositionTarget) {
        let service: Observable<AssemblyProperties[]>;
        if (target === PositionTarget.PRODUCTION_ORDER) {
            service = this.productionOrdersPositionService.getAssemblyProperties(position.id)
                .pipe(map(props => [props]));
        } else if (target === PositionTarget.OFFER) {
            service = this.positionService.getPropertiesForMultipleAssemblies([position.id]);
        }
        service.subscribe({
            next: data => {
                console.info('Position `getAssemblyProperties` success');
                this.properties = data;
                this.setShowProperties(true);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    public getTransportProperties(position: AbstractPosition, target: PositionTarget) {
        let service: Observable<TransportProperties[]>;
        if (target === PositionTarget.PRODUCTION_ORDER) {
            service = this.productionOrdersPositionService.getTransportProperties(position.id)
                .pipe(map(props => [props]));
        } else if (target === PositionTarget.OFFER) {
            service = this.positionService.getPropertiesForMultipleTransports([position.id]);
        }
        service.subscribe({
            next: data => {
                console.info('Position `getAddonProperties` success');
                this.properties = data;
                this.setShowProperties(true);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    get isWindowPosition(): boolean {
        return isWindowPositionType(this.positionType);
    }

    public getConfigProperties(position: AbstractPosition, target: PositionTarget) {
        let service: Observable<ConfigProperties[]>;
        if (target === PositionTarget.PRODUCTION_ORDER) {
            service = this.productionOrdersPositionService.getConfigProperties(position.id)
                .pipe(map(props => [props]));
        } else if (target === PositionTarget.OFFER) {
            service = this.positionService.getPropertiesForMultipleConfigs([position.id]);
        }
        service.subscribe({
            next: data => {
                console.info('Position `getConfigProperties` success');
                this.properties = data;
                this.setShowProperties(true);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    getFormattedUw(uw: number): string {
        return uw.toFixed(2) + ' W/m2K';
    }

    public isColorLabelDisplayed(): boolean {
        return this.windowProperties.some(w => {
            return w.coreColorNames != undefined || w.externalColorNames != undefined || w.internalColorNames != undefined;
        }) || this.gateProperties.some(w => {
           return w.coreColor != undefined ||
            w.externalColor != undefined ||
            w.internalColor != undefined;
        }) || this.addonProperties.some(a => {
            return a.coreColorNames != undefined || a.outsideColorNames != undefined || a.insideColorNames != undefined;
        });
    }

    public isAddonLabelDisplayed(): boolean {
        return this.windowProperties.some(w => w.previewWindowAddons != undefined && w.previewWindowAddons.length > 0);
    }

    public showUw(): boolean {
        return !this.windowProperties.every(w => w['gateSystem'] != undefined || w['configSystem'] != undefined);
    }

    private setShowProperties(show: boolean): void {
        if (this.showProperties !== show) {
            this.showProperties = show;
            this.changeDetector.markForCheck();
        }
    }

    areFillingPropertiesPresent(): boolean {
        return this.windowProperties.some(w => {
                return !!w.fillingType ||
                        !!w.glazingBeadNames ||
                        !!w.fillingNames ||
                        !!w.glassQuantity ||
                        !!w.fillingWidth ||
                        !!w.decorativeFillingNames ||
                        !!w.fillingExternalColorNames ||
                        !!w.fillingExternalColorNames;
            });
    }

    getMaxGlasses() {
        return _.chain(this.windowProperties).map(w => w.glassQuantity || 0).max().value();
    }

    getGlassesNumberArray() {
        return _.range(this.getMaxGlasses());
    }

    areGrillsPresent(): boolean {
        return this.windowProperties.some(w => !!w.grillNames);
    }

    areMullionsPresent(): boolean {
        return this.windowProperties.some(w => !!w.mullionNames);
    }

    areFittingsPresent(): boolean {
        return this.windowProperties.some(w => {
            return !!w.fittingSlidingNames
                || !!w.fittingInsertionNames
                || !!w.fittingLockNames
                || !!w.fittingLockTerraceNames
                || !!w.fittingBrakeNames
                || !!w.fittingTypeNames
                || !!w.fittingAutomaticDriveNames
                || !!w.fittingEspagnoletteTypeNames
                || !!w.fittingVerandaNames
                || !!w.fittingMainInsertionNames
                || !!w.fittingAdditionalInsertionNames
                || !!w.entranceDoorFitting;
        });
    }

    areGateSidebarAddonsPresent(): boolean {
        return this.gateProperties.some(w => !!w.sidebarAddons);
    }

    gateGroups(): string[] {
        return _.chain(this.gateProperties)
            .map(w => w.sidebarAddons)
            .map(propGroups => propGroups.map(elem => elem.group))
            .flatten()
            .map(multi => multi[this.translate.currentLang])
            .uniq()
            .values()
            .value();
    }

    gateCategories(group: string): string[] {
        return _.chain(this.gateProperties)
            .map(w => w.sidebarAddons)
            .flatten()
            .filter(propGroup => propGroup.group[this.translate.currentLang] === group)
            .map(propGroup => propGroup.categories.map(elem => elem.category))
            .flatten()
            .map(multi => multi[this.translate.currentLang])
            .uniq()
            .values()
            .value();
    }

    checkGlassSettingsConsistency(): boolean {
        if (this.windowProperties.some(w => w.glassQuantity != null && w.glassQuantity > 0 && w.glassNames != null)) {
            let glassChecker: boolean =  this.windowProperties.some(w => w.glassNames.length === w.glassQuantity);
            return glassChecker
                && (!this.windowProperties.some(w => w.frameNames != null)
                    || this.windowProperties.some(w => w.frameNames.length === w.glassQuantity - 1));
        }

        return false;
    }

    get showDimensions(): boolean {
        return this.windowProperties.some(wp => !!wp.dimensions);
    }

    generalRows() {
        let allWindowsAreTerrace = this.windowProperties.every(wp => wp.windowSystemType === WindowSystemType.TERRACE.type);
        let rows: PropertyInfo[] = [
            {label: 'OFFER.TABS.SECTION.MAIN.QUANTITY', propName: 'quantity'},
            {label: 'OFFER.TABS.SYSTEM', propName: 'windowSystemNames', multilang: true},
            {label: 'OFFER.TABS.SYSTEM', propName: 'configSystemNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MODEL.MODEL', propName: 'entranceModelNames', multilang: true},
            {label: 'ADDONS.CATEGORIES.WELD_TYPE', propName: 'weldType', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.PROFILE', propName: 'profileNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.ENHANCEMENT', propName: 'frameEnhancementNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MILLING', propName: 'millingNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.MILLING_NORWEGIAN', propName: 'millingNorwegianNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.UNDER_WINDOW_BEAD', propName: 'underWindowBeadNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.VIEW', propName: 'view', multilang: true, valuePrefix: 'OFFER.WINDOW_VIEW.'},
            {label: 'OFFER.TABS.SECTION.MAIN.FUNCTION', propName: 'windowFunction', multilang: true, valuePrefix: 'OFFER.FUNCTION.'},
            {label: 'WINDOW-SYSTEM-DEFINITION.FORM.ROOF_SYSTEM_FUNCTION', propName: 'roofWindowFunction', multilang: true, valuePrefix: 'WINDOW-SYSTEM-DEFINITION.ROOF_SYSTEM_FUNCTION.'},
            {label: 'OFFER.TABS.SECTION.MAIN.COVERS', propName: 'doorCoverNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.HANDLES', propName: 'handleTypeNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.TERRACE_HANDLES', propName: 'terraceHandleNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.TERRACE_HANDLE_LAYOUT', propName: 'terraceHandleLayout', multilang: true, valuePrefix: 'TERRACE_HANDLE_LAYOUT.'},
            {label: allWindowsAreTerrace ? 'OFFER.TABS.SECTION.MAIN.DOORSTEPS_TERRACE' : 'OFFER.TABS.SECTION.MAIN.DOORSTEPS', propName: 'doorStepNames', multilang: true},
            {label: 'PROFILE.FORM.TYPE.MOVABLE_POST', propName: 'movablePostNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.UNDERWINDOW_PROFILE', propName: 'underwindowProfileNames', multilang: true},
            {label: 'OFFER.OPENING.SECTION_TITLE', propName: 'doorOpening', multilang: true, valuePrefix: 'OFFER.OPENING.'},
            {label: 'OFFER.TABS.SECTION.MAIN.SEAL_EXTERNAL_COLOR', propName: 'sealExternalColorNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.SEAL_INTERNAL_COLOR', propName: 'sealInternalColorNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.VENTILATOR', propName: 'ventilatorNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.DRIP', propName: 'dripNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.COUPLER', propName: 'couplerNames', multilang: true},
            // gates
            {label: 'OFFER.TABS.SECTION.MAIN.GATE_SYSTEM', propName: 'gateSystem', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.RAIL_SYSTEM', propName: 'railSystem', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.GATE_PANEL_TYPE', propName: 'gatePanelType', multilang: true},
            {label: 'OFFER.TABS.SECTION.MAIN.CONTROL', propName: 'control', multilang: true, valuePrefix: 'GATE_CONTROL.'},
            // addons
            {label: 'OFFER.POSITIONS.FORM.UNIT', propName: 'quantityType', multilang: true, valuePrefix: 'ADDONS.QUANTITY_TYPE.ABBREVIATION.'},
            // assembly
            {label: 'OFFER.POSITIONS.FORM.UNIT', propName: 'assemblyUnit', multilang: true, valuePrefix: 'ASSEMBLY.UNITS.'},
        ];

        let configRows: PropertyInfo[];
        let dimensions = [];
        if (this.windowProperties[0]['configSystem'] != null) {
            dimensions = _.chain(this.windowProperties)
                .map(prop => (prop as unknown as ConfigProperties).configDimensions || [])
                .flatten()
                .map((dim, i) => ({label: dim.dimLabelKey, propName: `configDimensions[${i}].dimValue`, multilang: false}))
                .flatten()
                .uniq()
                .value();
        }
        configRows = [...dimensions,
            {label: 'OFFER.POSITIONS.ADDON_CONFIG.FORM.APPLICABLE', propName: 'application', multilang: true, valuePrefix: 'OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.'},
            {label: 'OFFER.TABS.SECTION.MAIN.CONFIG_SYSTEM', propName: 'configSystem', multilang: true},
        ];
        rows = [...rows, ...configRows];

        return rows.filter(row => this.windowProperties.some(w => !!this.accessByString(w, row.propName)));
    }

    colorAndMaterialRows(): PropertyInfo[] {
        if (this.windowProperties[0]['configSystem'] == null) {
            return [];
        }
        let getKey = (id: number, field: 'MATERIAL' | 'COLOR') => `CONFIG_LABEL.${id}.NAME.${field}`;
        let materialKeys = [];
        let materialWithoutCustomTranslation = false;
        let colorKeys = [];
        let colorWithoutCustomTranslation = false;
        _.chain(this.windowProperties)
            .map(prop => (prop as unknown as ConfigProperties))
            .map(prop => prop.configSystemId)
            .uniq()
            .map(id => {
                let materialKey = getKey(id, 'MATERIAL');
                let colorKey = getKey(id, 'COLOR');
                let translations = this.translate.instant([materialKey, colorKey]);
                if (translations[materialKey] !== materialKey) {
                    materialKeys.push(materialKey);
                } else {
                    materialWithoutCustomTranslation = true;
                }
                if (translations[colorKey] !== colorKey) {
                    colorKeys.push(colorKey);
                } else {
                    colorWithoutCustomTranslation = true;
                }
            })
            .value();
        if (materialWithoutCustomTranslation) {
            materialKeys.unshift('OFFER.TABS.SECTION.MAIN.MATERIAL');
        }
        if (colorWithoutCustomTranslation) {
            colorKeys.unshift('OFFER.TABS.SECTION.MAIN.COLOR');
        }
        let rows = [
            {labels: materialKeys, propName: 'material', multilang: true},
            {labels: colorKeys, propName: 'color', multilang: true}
        ];
        return rows.filter(row => this.windowProperties.some(w => !!this.accessByString(w, row.propName)));
    }

    accessByString(object: object, propertyPath: string): any {
        propertyPath = propertyPath.replace(/\[(\w+)]/g, '.$1');  // convert indexes to properties
        propertyPath = propertyPath.replace(/^\./, '');           // strip a leading dot
        let a = propertyPath.split('.');
        for (let i = 0, n = a.length; i < n; ++i) {
            if (object == null) {
                return null;
            }
            let k = a[i];
            if (k in object) {
                object = object[k];
            } else {
                return;
            }
        }
        return object;
    }

    colorRows() {
        const rows: PropertyInfo[] = [
            {label: 'OFFER.TABS.SECTION.COLOR.CORE_COLOR', propName: 'coreColorNames'},
            {label: 'OFFER.TABS.SECTION.COLOR.EXTERNAL_COLOR', propName: 'externalColorNames'},
            {label: 'OFFER.TABS.SECTION.COLOR.INTERNAL_COLOR', propName: 'internalColorNames'},
            // gates
            {label: 'OFFER.TABS.SECTION.COLOR.GATE_CORE_COLOR', propName: 'coreColor'},
            {label: 'OFFER.TABS.SECTION.COLOR.EXTERNAL_COLOR', propName: 'externalColor'},
            {label: 'OFFER.TABS.SECTION.COLOR.INTERNAL_COLOR', propName: 'internalColor'},
            // addons
            {label: 'OFFER.TABS.SECTION.COLOR.EXTERNAL_COLOR', propName: 'outsideColorNames'},
            {label: 'OFFER.TABS.SECTION.COLOR.INTERNAL_COLOR', propName: 'insideColorNames'},
        ];
        return rows.filter(row => this.windowProperties.some(w => !!w[row.propName]));
    }

    fillingRows() {
        const rows: PropertyInfo[] = [
            {label: 'OFFER.TABS.SECTION.FILLING.FILLING_TYPE', propName: 'fillingType', multilang: true, valuePrefix: 'OFFER.FILLING_TYPE.'},
            {label: 'OFFER.TABS.SECTION.FILLING.EXTERNAL_COLOR', propName: 'fillingExternalColorNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.FILLING.INTERNAL_COLOR', propName: 'fillingInternalColorNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.FILLING.FILLING_NAME', propName: 'decorativeFillingNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.FILLING.GLAZING_BEAD', propName: 'glazingBeadNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.FILLING.GLAZING_GLASS_QN', propName: 'glassQuantity'},
            {label: 'WINDOW-SYSTEM-DEFINITION.FORM.ROOF_SYSTEM_GLAZING_PACKAGE', propName: 'glazingPackageNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.FILLING.GLAZING_CATEGORY', propName: 'glazingCategoryNames', multilang: true},
            {label: 'OFFER.TABS.SECTION.FILLING.GLAZING_FRAME_CATEGORY', propName: 'glazingFrameCategoryNames', multilang: true},
        ];

        if (this.isAnyFillingType(FillingType.NO_FILLING)) {
            rows.push({label: 'OFFER.TABS.SECTION.FILLING.WIDTH', propName: 'fillingWidth'});
        }
        if (this.isAnyFillingType(FillingType.FILLING)) {
            rows.push({label: 'OFFER.TABS.SECTION.FILLING.FILLING_NAME', propName: 'fillingNames', multilang: true});
        }
        if (this.isAnyFillingType(FillingType.DECORATIVE_FILLING)) {
            rows.push({label: 'OFFER.TABS.SECTION.FILLING.FILLING_NAME', propName: 'decorativeFillingNames', multilang: true});
        }
        return rows.filter(row => this.windowProperties.some(w => !!w[row.propName]));
    }

    private isAnyFillingType(fillingType: FillingType) {
        return this.windowProperties.some(w => w.fillingType === FillingType[fillingType]);
    }

    addonRows() {
        const lang = this.translate.currentLang;
        const uniqAddons: PreviewWindowAddon[] = _.chain(this.windowProperties)
            .map(w => w.previewWindowAddons || [])
            .flatten(true)
            .filter(a => a != null)
            .uniq(false, addon => addon.id)
            .value() as any;
        return uniqAddons.map(addon => {
            let name = addon.names[lang];
            let quantityType = addon.quantityType;
            let quantities = _.chain(this.windowProperties).map(w => w.previewWindowAddons)
                .map(array => {
                    if (array == null) {
                        return 0;
                    }
                    let found = array.find(add => add.id === addon.id);
                    if (found == null) {
                        return 0;
                    }
                    return found.quantity;
                }).value();
            return {name, quantityType, quantities};
        });
    }

    fittingRows() {
        const rows: PropertyInfo[] = [
            {label: 'OFFER.TABS.SECTION.FITTINGS.ENTRANCE_DOOR_FITTING', propName: 'entranceDoorFitting'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_BRAKE', propName: 'fittingBrakeNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_SLIDING', propName: 'fittingSlidingNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_TYPE', propName: 'fittingTypeNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_ESPAGNOLETTE_TYPE', propName: 'fittingEspagnoletteTypeNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_VERANDA', propName: 'fittingVerandaNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_INSERTION', propName: 'fittingInsertionNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_MAIN_INSERTION', propName: 'fittingMainInsertionNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_ADDITIONAL_INSERTION', propName: 'fittingAdditionalInsertionNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK', propName: 'fittingLockNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK_TERRACE', propName: 'fittingLockTerraceNames'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_LOCK_TERRACE_LOCATION', propName: 'fittingTerraceLockLocation', multilang: true, valuePrefix: 'TERRACE_LOCK_LOCATION.'},
            {label: 'OFFER.TABS.SECTION.FITTINGS.FITTING_AUTOMATIC_DRIVE', propName: 'fittingAutomaticDriveNames'},
        ];

        return rows.filter(row => this.windowProperties.some(w => !!w[row.propName]));
    }

    isDescriptionPresent(): boolean {
        return this.windowProperties.some(w => !!w.description);
    }

    getAllDescriptionCategories(): CatalogDescriptionEntry[] {
        return _.chain(this.windowProperties)
            .map(wp => wp.description)
            .filter(d => d != null)
            .flatten()
            .uniq((item) => item.propertySortIndex)
            .value();
    }

    getDescriptionLabelForCategory(windowProperties: WindowProperties, propertySortIndex: number): string {
        if (windowProperties.description != null) {
            let descriptionEntry = windowProperties.description.find(d => d.propertySortIndex === propertySortIndex);
            if (descriptionEntry != null) {
                return descriptionEntry.optionName[this.translate.currentLang];
            }
        }
        return '';
    }

    getOrEmpty(name: MultilanguageField): string {
        return name != null ? (name[this.translate.currentLang]) : '';
    }

    getPropertyValue(gate: GateProperties, group: string, category: string) {
        if (!('sidebarAddons' in gate)) {
            return '';
        }
        let sidebarAddons = gate.sidebarAddons;
        if (sidebarAddons == null) {
            return '';
        }
        let foundPropGroup = sidebarAddons.find(propGroup => propGroup.group[this.translate.currentLang] === group);
        if (foundPropGroup == null) {
            return '';
        }
        let foundPropCategory = foundPropGroup.categories.find(propCategory => propCategory.category[this.translate.currentLang] === category);
        if (foundPropCategory == null) {
            return '';
        } else {
            return this.getOrEmpty(foundPropCategory.addon);
        }
    }

    getDataRows(): Observable<DataRow[]> {
        return forkJoin({
            dimensions: this.mapDimensions()
        }).pipe(map(data => {
            let array: DataRow[] = [];
            this.mapUw(array);
            array.push(...data.dimensions);
            this.mapGeneralRows(array);
            this.mapColors(array);
            this.mapFillings(array);
            this.mapGrills(array);
            this.mapMullions(array);
            this.mapFittings(array);
            this.mapGateSidebarAddons(array);
            this.mapCatalogDescriptions(array);
            this.mapAddons(array);
            return array;
        }));
    }

    private mapUw(array: DataRow[]) {
        if (this.isWindowPosition) {
            let values = this.windowProperties
                .map(window => window.uw)
                .map(uw => uw == null ? {value: 'OFFER.POSITIONS.FORM.UW_UNAVAILABLE', toTranslate: true} : {value: this.getFormattedUw(uw)});
            array.push(this.mapDataRow('OFFER.POSITIONS.FORM.UW', values));
        }
    }

    private mapDimensions(): Observable<DataRow[]> {
        if (this.showDimensions) {
            let values = this.windowProperties
                .map(window => window.dimensions)
                .map(dim => this.convertMillimetersToInches.transform(dim).pipe(take(1)));
            if (values.length !== 0) {
                return forkJoin(values).pipe(map(convertedDims => {
                    const mappedValues = convertedDims.map(dim => ({value: dim}));
                    return [this.mapDataRow('OFFER.POSITIONS.FORM.DIMENSIONS', mappedValues)];
                }));
            }
        }
        return of([]);
    }

    private mapGeneralRows(array: DataRow[]) {
        this.generalRows().forEach(row => {
            let values = this.windowProperties
                .map(this.mapPropertiesRich(row));
            array.push(this.mapDataRow(row.label, values));
        });
    }

    private mapColors(array: DataRow[]) {
        if (this.isColorLabelDisplayed()) {
            this.mapSubtitle(array, 'OFFER.TABS.SECTION.COLOR.SECTION_TITLE');

            this.colorRows().forEach(row => {
                let values = this.windowProperties
                    .map(window => ({value: this.getOrEmpty(window[row.propName]), toTranslate: true}));
                array.push(this.mapDataRow(row.label, values));
            });
        }
    }

    private mapFillings(array: DataRow[]) {
        if (this.areFillingPropertiesPresent()) {
            this.mapSubtitle(array, 'OFFER.TABS.SECTION.FILLING.SECTION_TITLE');

            this.fillingRows().forEach(row => {
                let values = this.windowProperties
                    .map(this.mapPropertiesRich(row));
                array.push(this.mapDataRow(row.label, values));
            });

            if (this.checkGlassSettingsConsistency()) {
                for (let glassNumber of this.getGlassesNumberArray()) {
                    let str = '';
                    if (this.windowProperties.length === 1) {
                        if (glassNumber === 0) {
                            str = 'OFFER.TABS.SECTION.FILLING.GLASS_INTERNAL';
                        } else if (glassNumber === 1 && this.getMaxGlasses() === 3) {
                            str = 'OFFER.TABS.SECTION.FILLING.GLASS_MIDDLE';
                        } else if (glassNumber > 0 && glassNumber < this.getMaxGlasses() - 1 && this.getMaxGlasses() !== 3) {
                            str = this.translate.instant('OFFER.TABS.SECTION.FILLING.GLASS_MIDDLE_NUMBERED', {glassNumber:glassNumber});
                        } else if (glassNumber > 0 && glassNumber === this.getMaxGlasses() - 1) {
                            str = 'OFFER.TABS.SECTION.FILLING.GLASS_EXTERNAL';
                        }
                    } else if (this.windowProperties.length > 1) {
                        if (glassNumber === 0) {
                            str = 'OFFER.TABS.SECTION.FILLING.GLASS_FIRST_INTERNAL';
                        } else {
                            str = this.translate.instant('OFFER.TABS.SECTION.FILLING.GLASS_NUMBERED', {glassNumber:glassNumber + 1});
                        }
                    }
                    let values = this.windowProperties.map(window => window.glassNames ? this.getOrEmpty(window.glassNames[glassNumber]) : '')
                        .map(str2 => ({value: str2}));
                    array.push(this.mapDataRow(str, values));

                    let str3 = '';
                    if (glassNumber < this.getMaxGlasses() - 1) {
                        if (this.getMaxGlasses() === 2) {
                            str3 = 'OFFER.TABS.SECTION.FILLING.FRAME';
                        } else {
                            str3 = this.translate.instant('OFFER.TABS.SECTION.FILLING.FRAME_NUMBERED', {at: glassNumber + 1, by: glassNumber + 2});
                        }
                    }
                    let values3 = this.windowProperties.map(window => window.frameNames ? this.getOrEmpty(window.frameNames[glassNumber]) : '')
                        .map(str2 => ({value: str2}));
                    array.push(this.mapDataRow(str3, values3));
                }
            }
        }
    }

    private mapGrills(array: DataRow[]) {
        if (this.areGrillsPresent()) {
            this.mapSubtitle(array, 'OFFER.TABS.SECTION.GRILL.SECTION_TITLE');

            let values = this.windowProperties
                .map(window => ({value: this.getOrEmpty(window.grillNames), toTranslate: true}));
            array.push(this.mapDataRow('OFFER.TABS.SECTION.GRILL.GRILL_NAME', values));
        }
    }

    private mapMullions(array: DataRow[]) {
        if (this.areMullionsPresent()) {
            this.mapSubtitle(array, 'OFFER.TABS.SECTION.MULLION.SECTION_TITLE');

            let values = this.windowProperties
                .map(window => ({value: this.getOrEmpty(window.mullionNames), toTranslate: true}));
            array.push(this.mapDataRow('OFFER.TABS.SECTION.MULLION.MULLION', values));
        }
    }

    private mapFittings(array: DataRow[]) {
        if (this.areFittingsPresent()) {
            this.mapSubtitle(array, 'OFFER.TABS.SECTION.FITTINGS.SECTION_TITLE');

            this.fittingRows().forEach(row => {
                let values = this.windowProperties
                    .map(properties => {
                        if (row.multilang && row.valuePrefix && properties[row.propName] != null) {
                            return {value: (row.valuePrefix + properties[row.propName]), toTranslate: true};
                        } else {
                            return {value: this.getOrEmpty(properties[row.propName])};
                        }
                    });
                array.push(this.mapDataRow(row.label, values));
            });
        }
    }

    private mapGateSidebarAddons(array: DataRow[]) {
        if (this.areGateSidebarAddonsPresent()) {
            this.gateGroups().forEach(group => {
                this.mapSubtitle(array, group, true);

                this.gateCategories(group).forEach(row => {
                    let values = this.gateProperties
                        .map(gate => ({value: this.getPropertyValue(gate, group, row)}));
                    array.push(this.mapDataRow(row, values));
                });
            });
        }
    }

    private mapCatalogDescriptions(array: DataRow[]) {
        if (this.isDescriptionPresent()) {
            this.mapSubtitle(array, 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.DESCRIPTION');
            this.getAllDescriptionCategories().forEach(category => {
                let values = this.windowProperties
                    .map(window => ({value: this.getDescriptionLabelForCategory(window, category.propertySortIndex), innerHTML: true}));
                array.push(this.mapDataRow(category.propertyName[this.translate.currentLang], values));
            });
        }
    }

    private mapAddons(array: DataRow[]) {
        if (this.isAddonLabelDisplayed()) {
            this.mapSubtitle(array, 'OFFER.TABS.SECTION.ADDONS.SECTION_TITLE');
            this.addonRows().forEach(windowAddon => {
                let values = windowAddon.quantities
                    .map(quantity => ({
                        value: quantity === 0 ? '' : quantity + this.translate.instant('ADDONS.QUANTITY_TYPE.ABBREVIATION.' + windowAddon.quantityType),
                    }));
                array.push(this.mapDataRow(windowAddon.name, values));
            });
        }
    }

    private mapDataRow(header: string, values: ({ value: string; toTranslate?: boolean })[]): DataRow {
        return {
            header: header,
            values: values,
            variousValues: !this.elementsEqual(values.map(valueObj => valueObj.value))
        };
    }

    elementsEqual(arr: any[]): boolean {
        if (arr == null || arr.length === 0) {
            return false;
        }
        return !_.without(arr, arr[0]).length;
    }

    private mapSubtitle(array: DataRow[], header: string, gates?: boolean): void {
        let values;
        if (gates) {
            values = this.gateProperties.map(() => ({value: ''}));
        } else {
            values = this.windowProperties.map(() => ({value: ''}));
        }
        array.push({header, values, subtitle: true});
    }

    private mapPropertiesRich(row: PropertyInfo) {
        return properties => {
            const propValue = this.accessByString(properties, row.propName);
            if (row.multilang && row.valuePrefix && propValue != null) {
                return {value: (row.valuePrefix + propValue), toTranslate: true};
            } else if (row.multilang && !row.valuePrefix) {
                return {value: this.getOrEmpty(propValue)};
            } else if (!row.multilang) {
                return {value: propValue};
            } else {
                return {value: ''};
            }
        };
    }
}
