import * as _ from 'underscore';
import {GlassInterface} from '../catalog-data/glass-interface';
import {Glazing} from '../catalog-data/glazing';
import {GrillInterface} from '../catalog-data/grill-interface';
import {AreaSpecification} from "../drawing-data/AreaSpecification";
import {CutData} from "../drawing-data/CutData";
import {DefiningMullion} from "../drawing-data/DefiningMullion";
import {DrawingData} from "../drawing-data/drawing-data";
import {FieldWithPosition} from "../drawing-data/FieldWithPosition";
import {Filling} from "../drawing-data/Filling";
import {FillingType} from "../drawing-data/FillingType";
import {GlazingBead} from "../drawing-data/GlazingBead";
import {Grill} from "../drawing-data/Grill";
import {GrillSegment} from "../drawing-data/GrillSegment";
import {Mullion} from "../drawing-data/Mullion";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {SubWindowShape} from "../drawing-data/SubWindowShape";
import {WindowData} from "../drawing-data/WindowData";
import {WindowShape} from "../drawing-data/WindowShape";
import {DrawingUtil, MinMaxXY} from "../drawing-util";
import {GrillTypes} from "../enums/GrillTypes";
import {GlazingHelper} from '../glazing-helper';
import {PendingGrillData} from '../pending-grill-data';
import {ProfilesCompositionDistances} from '../profiles-composition-distances';
import {WindowCalculator} from "../window-calculator";
import {WindowDataFactory} from "../window-data-factory";
import {AreaMergeResult} from "./AreaMergeResult";
import {AreaMergeStatus} from "./AreaMergeStatus";
import {CutsUtil} from "./cutUtils";
import {ErrorNames} from "./ErrorNames";
import {GrillHelper} from "./grill-helper";
import {OperationResult} from "./OperationResult";
import {PolygonPointUtil} from "./PolygonPoint";
import {SortingUtil} from "./SortingUtil";

export class AreaUtils {

    public static recalculateAreasNumbers(data: DrawingData) {
        let i = 0;
        data.windows.forEach(
            w => w.subWindows.forEach(sw => sw.areasSpecification.forEach(as => as.ordinalNumber = ++i)));
    }

    public static setSubwindowShapes(data: DrawingData) {
        data.windows.forEach(w => w.subWindows.forEach(sw => {
            let relevantCuts = data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(sw.points, cut));
            if (relevantCuts.length === 0) {
                sw.shape = SubWindowShape.RECTANGULAR;
            } else if (relevantCuts.some(cut => CutData.isArc(cut))) {
                if (!WindowShape.isEllipse(data.shape)) {
                    sw.shape = SubWindowShape.ARC;
                } else if (data.windows && data.windows[0].subWindows.length > 1) {
                    sw.shape = SubWindowShape.ARC;
                } else {
                    sw.shape = SubWindowShape.ELLIPSE;
                }
            } else {
                // only count non-horizontal/non-vertical cuts (as those don't change a window's shape)
                relevantCuts = relevantCuts.filter(
                    c => !CutData.isLine(c) || !(c.points[0] === c.points[2] || c.points[1] === c.points[3]));
                sw.shape = relevantCuts.length === 0 ? SubWindowShape.RECTANGULAR : SubWindowShape.NOT_RECTANGULAR;
            }
            sw.relevantCutIds = relevantCuts.map(c => c.generatedId);
        }));
    }

    static setGlazingsESG(data: DrawingData, glasses: GlassInterface[]) {
        data.windows.forEach(w => w.subWindows.forEach(sw => sw.areasSpecification.forEach(area => {
            area.glazing.isESG = AreaUtils.isAreaESG(area, glasses);
        })));
    }

    private static isAreaESG(area: AreaSpecification, glasses: GlassInterface[]): boolean {
        if (area.filling.type !== FillingType.GLASS) {
            return false;
        }
        for (let i = 1; i < area.glazing.glazingGlassQuantity + 1; i++) {
            let nthGlass = GlazingHelper.getNthGlass(area.glazing, i);
            if (!nthGlass) {
                continue;
            }
            const glass = glasses.find(g => g.id === nthGlass);
            if (glass && glass.esg) {
                return true;
            }
        }
        return false;
    }

    public static canHaveGrills(area: AreaSpecification) {
        return area.filling.type === FillingType.GLASS;
    }

    public static canPendingGrillBeAdded(area: AreaSpecification, pendingGrillData?: PendingGrillData,
                                         catalogGrillsForWindowSystem?: GrillInterface[]) {
        if (!pendingGrillData || !catalogGrillsForWindowSystem) {
            return false;
        }
        return this.canHaveGrills(area) &&
            this.canGrillsBeCombined(area, pendingGrillData, catalogGrillsForWindowSystem);
    }

    private static canGrillsBeCombined(area: AreaSpecification, pending: PendingGrillData,
                                       dtos: GrillInterface[]): boolean {
        let grillIds = _.uniq([pending.grill.id, ...area.grills.map(grill => grill.id)]);
        let grills = grillIds.map(id => this.findGrillDto(id, dtos));
        return grills.filter(grill => grill.type === GrillTypes.INNER)
            .filter(grill => grill.canNotBeCombinedWithOtherGrills).length < 2;
    }

    private static findGrillDto(id: number, dtos: GrillInterface[]): GrillInterface {
        return dtos.find(dto => dto.id === id);
    }

    public static getAreasContainingGrills(windows: WindowData[]) {
        let allAreasWithGrills: AreaSpecification[] = [];
        for (let windowData of windows) {
            for (let subwindow of windowData.subWindows) {
                allAreasWithGrills.push(
                    ...subwindow.areasSpecification.filter(area => GrillHelper.areaHasGrills(area)));
            }
        }
        return allAreasWithGrills;
    }

    public static getOuterFrameId(area: AreaSpecification): number {
        if (area.filling) {
            if (area.filling.type === FillingType.GLASS) {
                switch (area.glazing.glazingGlassQuantity) {
                    case 4:
                        return area.glazing.frame3id;
                    case 3:
                        return area.glazing.frame2id;
                    case 2:
                        return area.glazing.frame1id;
                }
            }
        }
        return undefined;
    }

    static setAreaFields(area: AreaSpecification, parts: FieldWithPosition[]) {
        area.fields = [];
        if (AreaUtils.canHaveGrills(area)) {
            parts.filter(p => p.positions.length).forEach(
                p =>
                    area.fields.push(new FieldWithPosition(p.positions, _.uniq(p.grillSegmentIds))));
        }
    }

    static removeUnusedDefiningMullionReferences(subWindow: SubWindowData, totalGlazingBead: number[],
                                                 profileCompositionDistances: ProfilesCompositionDistances) {
        subWindow.areasSpecification.forEach(area => {
            let originalAreaPoints = WindowCalculator.getGlazingBeadPoints(subWindow, area.definingMullions,
                totalGlazingBead, profileCompositionDistances);
            area.definingMullions = area.definingMullions.filter(dm => {
                let mullionsExcludingOne = area.definingMullions.filter(value => value !== dm);
                let excludedAreaPoints = WindowCalculator.getGlazingBeadPoints(subWindow, mullionsExcludingOne,
                    totalGlazingBead, profileCompositionDistances);
                return !_.isEqual(originalAreaPoints, excludedAreaPoints);
            });
        });
    }

    static isEqualFilling(filling1: Filling, filling2: Filling): boolean {
        if (filling1.type !== filling2.type) {
            return false;
        }
        switch (filling1.type) {
            case FillingType.GLASS:
                break;
            case FillingType.DECORATIVE_FILLING:
            case FillingType.FILLING:
                if (filling1.type === FillingType.DECORATIVE_FILLING) {
                    if (filling1.decorativeFillingId !== filling2.decorativeFillingId) {
                        return false;
                    }
                } else {
                    if (filling1.fillingId !== filling2.fillingId) {
                        return false;
                    }
                }
                if (filling1.externalColorId !== filling2.externalColorId) {
                    return false;
                }
                if (filling1.internalColorId !== filling2.internalColorId) {
                    return false;
                }
                break;
            case FillingType.NO_FILLING:
                if (filling1.width !== filling2.width) {
                    return false;
                }
                break;
            default:
                break;
        }
        return true;
    }

    static isEqualGlazing(glazing1: Glazing, glazing2: Glazing): boolean {
        if (glazing1.glazingGlassQuantity !== glazing2.glazingGlassQuantity) {
            return false;
        }
        for (let i = 1; i <= glazing1.glazingGlassQuantity; ++i) {
            if (GlazingHelper.getNthGlass(glazing1, i) !== GlazingHelper.getNthGlass(glazing2, i)) {
                return false;
            }
        }
        for (let i = 1; i < glazing1.glazingGlassQuantity; ++i) {
            if (GlazingHelper.getNthFrame(glazing1, i) !== GlazingHelper.getNthFrame(glazing2, i)) {
                return false;
            }
        }
        return true;
    }

    static isEqualFillingAndGlazing(area1: AreaSpecification, area2: AreaSpecification): boolean {
        if (!AreaUtils.isEqualFilling(area1.filling, area2.filling)) {
            return false;
        }
        if (area1.filling.type === FillingType.GLASS && !AreaUtils.isEqualGlazing(area1.glazing, area2.glazing)) {
            return false;
        }
        return true;
    }

    static resetFillingAndGlazing(area: AreaSpecification): void {
        area.filling = new Filling();
        area.glazing = new Glazing();
    }

    public static removeConfigurableAddonIds(areas: AreaSpecification[]): number[] {
        let removedIds: number[] = [];
        areas.forEach(area => {
            removedIds.push(...area.configurableAddonIds);
            area.configurableAddonIds = [];
        });
        return _.uniq(removedIds);
    }

    static findGrillBySegmentId(area: AreaSpecification, grillSegmentId: number): Grill {
        return GrillHelper.findGrillBySegmentId(area.grills, grillSegmentId);
    }

    static findGrillSegmentById(area: AreaSpecification, grillSegmentId: number): GrillSegment {
        return GrillHelper.findGrillSegmentById(area.grills, grillSegmentId);
    }

    static setTotalPerimeter(data: DrawingData): void {
        let framePoints = WindowCalculator.getOuterFramePoints(data.windows, data.cuts);
        let sum = 0;
        for (let i = 0; i < framePoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(framePoints, i);
            let y1 = DrawingUtil.getPoint(framePoints, i + 1);
            let x2 = DrawingUtil.getPoint(framePoints, i + 2);
            let y2 = DrawingUtil.getPoint(framePoints, i + 3);
            sum += DrawingUtil.distance([x1, y1], [x2, y2]);
        }
        data.totalPerimeter = sum;
    }

    public static setNewAreaSizes(subwindow: SubWindowData, totalGlazingBead: number[],
                                  totalInnerFramePoints: number[], totalRealPackagePoints: number[],
                                  profileCompositionDistances: ProfilesCompositionDistances) {
        subwindow.ventilationPluginWidth = DrawingUtil.segmentsLength(DrawingUtil.getTopPolygonEdge(totalInnerFramePoints)) || 0;
        let totalGlazingBox = DrawingUtil.calculatePolygonTotalBoundingBox(totalGlazingBead);
        let subwindowBox = DrawingUtil.calculatePolygonTotalBoundingBox(subwindow.points);
        let glazingBeadPointsMap: Map<AreaSpecification, number[]> = new Map();
        let hasNonRectangularArea = false;
        for (let area of subwindow.areasSpecification) {
            let areaPoints = WindowCalculator.getAreaSizePoint(subwindow, area.definingMullions, totalGlazingBead,
                false);
            let areaBox = AreaUtils.calculateAreaNewPoints(areaPoints, totalGlazingBox, subwindowBox);
            area.width = Math.max(areaBox.maxX - areaBox.minX, 0);
            area.height = Math.max(areaBox.maxY - areaBox.minY, 0);

            areaPoints = WindowCalculator.getAreaSizePoint(subwindow, area.definingMullions, totalGlazingBead, true);
            areaBox = AreaUtils.calculateAreaNewPoints(areaPoints, totalGlazingBox, subwindowBox);
            area.pricingWitdh = Math.max(areaBox.maxX - areaBox.minX, 0);
            area.pricingHeight = Math.max(areaBox.maxY - areaBox.minY, 0);

            let realPackagePoints = WindowCalculator.getRealGlazingPackagePoints(subwindow, area.definingMullions,
                totalRealPackagePoints, profileCompositionDistances);
            let realPackageBox = DrawingUtil.calculatePolygonTotalBoundingBox(realPackagePoints);
            area.realPackageWidth = Math.max(realPackageBox.maxX - realPackageBox.minX, 0);
            area.realPackageHeight = Math.max(realPackageBox.maxY - realPackageBox.minY, 0);

            let pluginPoints = WindowCalculator.getFrameInnerEdgePoints(subwindow, area.definingMullions,
                totalInnerFramePoints);
            let pluginBox = DrawingUtil.calculatePolygonTotalBoundingBox(pluginPoints);
            area.pluginWidth = Math.max(pluginBox.maxX - pluginBox.minX, 0);
            area.pluginHeight = Math.max(pluginBox.maxY - pluginBox.minY, 0);

            let glazingBeadPoints = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions,
                totalGlazingBead, profileCompositionDistances);
            area.rectangularShape = DrawingUtil.isPolygonRectangular(glazingBeadPoints);

            if (area.rectangularShape) {
                let gbBox = DrawingUtil.calculateMinMaxFromPolygon(glazingBeadPoints, false);
                area.fillingWidth = gbBox.maxX - gbBox.minX;
                area.fillingHeight = gbBox.maxY - gbBox.minY;
            } else {
                hasNonRectangularArea = true;
            }

            glazingBeadPointsMap.set(area, glazingBeadPoints);
        }
        if (hasNonRectangularArea) {
            for (let area of subwindow.areasSpecification) {
                area.fillingWidth = undefined;
                area.fillingHeight = undefined;
            }
        }
        subwindow.areasSpecification.sort(
            (a, b) => SortingUtil.topToBottomLeftToRight(glazingBeadPointsMap.get(a), glazingBeadPointsMap.get(b)));
    }

    private static calculateAreaNewPoints(areaPoints: number[], totalGlazingBox: MinMaxXY,
                                          subwindowBox: MinMaxXY): MinMaxXY {
        for (let i = 0; i < areaPoints.length; i += 2) {
            areaPoints[i] = (areaPoints[i] === totalGlazingBox.minX) ? subwindowBox.minX : areaPoints[i];
            areaPoints[i] = (areaPoints[i] === totalGlazingBox.maxX) ? subwindowBox.maxX : areaPoints[i];
            areaPoints[i + 1] = (areaPoints[i + 1] === totalGlazingBox.minY) ? subwindowBox.minY : areaPoints[i + 1];
            areaPoints[i + 1] = (areaPoints[i + 1] === totalGlazingBox.maxY) ? subwindowBox.maxY : areaPoints[i + 1];
        }
        return DrawingUtil.calculatePolygonTotalBoundingBox(areaPoints);
    }

    public static recalculateAreaSizes(windows: WindowData[], cuts: CutData[], totalBoundingBox: MinMaxXY,
                                       profileCompositionDistances: ProfilesCompositionDistances,
                                       skipValidation: boolean) {
        windows.forEach(w =>
            AreaUtils.recalculateSubwindowAreaSizes(w.subWindows, cuts, totalBoundingBox, profileCompositionDistances, skipValidation)
        );
    }

    public static recalculateSubwindowAreaSizes(subwindows: SubWindowData[], cuts: CutData[], totalBoundingBox: MinMaxXY,
                                                profileCompositionDistances: ProfilesCompositionDistances,
                                                skipValidation: boolean) {
        subwindows.forEach(sw => {
            if (sw.wing != undefined) {
                this.recalculateWingSize(sw, cuts, totalBoundingBox, profileCompositionDistances, skipValidation);
            }
            let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPoints(sw, cuts, totalBoundingBox,
                profileCompositionDistances, skipValidation);
            let totalInnerFramePoints = WindowCalculator.getTotalFrameInnerEdgePoints(sw, cuts, totalBoundingBox,
                profileCompositionDistances, skipValidation);
            let totalRealPackagePoints = WindowCalculator.getTotalRealGlazingPackagePoints(sw, cuts,
                totalBoundingBox, profileCompositionDistances);
            AreaUtils.setNewAreaSizes(sw, totalGlazingBead, totalInnerFramePoints, totalRealPackagePoints, profileCompositionDistances);
        });
    }

    public static recalculateWingSize(sw: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                                      profileCompositionDistances: ProfilesCompositionDistances, skipValidation: boolean) {
        let wingOuterEdge = WindowCalculator.getFFWingOuterEdgePoints(sw, cuts, totalBoundingBox, profileCompositionDistances,
            skipValidation);
        let wingBox = DrawingUtil.calculatePolygonTotalBoundingBox(wingOuterEdge);
        sw.wing.width = Math.max(wingBox.maxX - wingBox.minX, 0);
        sw.wing.height = Math.max(wingBox.maxY - wingBox.minY, 0);
    }

    static checkIfAreasRedefinitionIsRequired(subwindow: SubWindowData, totalGlazingBead: number[],
                                              profileCompositionDistances: ProfilesCompositionDistances): boolean {
        return subwindow.areasSpecification.some(area => {
            let glazingBead = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions, totalGlazingBead,
                profileCompositionDistances);
            return subwindow.mullions.some(mullion => {
                let segment = mullion.drawingSegments[0];
                if (GrillSegment.isLine(segment)) {
                    return (DrawingUtil.polygonLineIntersectionCount(glazingBead, segment.points) === 2 &&
                        !area.definingMullions.some(def => {
                            return segment.id === def.id;
                        }));
                } else {
                    let err = new Error("Not supported segment type: " + segment.type);
                    err.name = ErrorNames.SELECTED_ELEMENT_UKNOWN_TYPE;
                    throw err;
                }
            });
        });
    }

    public static createFreshAreas(subwindow: SubWindowData, totalGlazingBead: number[], totalInnerFrame: number[],
                                   totalRealPackagePoints: number[],
                                   profileCompositionDistances: ProfilesCompositionDistances): OperationResult {
        let operationResult = new OperationResult(
            _.flatten(subwindow.areasSpecification.map(area => area.configurableAddonIds)), true);
        subwindow.areasSpecification = [WindowDataFactory.createArea()];
        subwindow.mullions.forEach(m => {
            operationResult.merge(AreaUtils.splitIntersectedAreas(subwindow, m, totalGlazingBead, profileCompositionDistances));
        });
        AreaUtils.setNewAreaSizes(subwindow, totalGlazingBead, totalInnerFrame, totalRealPackagePoints, profileCompositionDistances);
        return operationResult;
    }

    public static splitIntersectedAreas(subwindow: SubWindowData, mullion: Mullion,
                                        totalGlazingBead: number[],
                                        profileCompositionDistances: ProfilesCompositionDistances): OperationResult {
        let segment = GrillHelper.getExpectedLineSegment(mullion);
        let operationResult = new OperationResult([], true);
        let line = DrawingUtil.extrapolateSegment(segment.points);
        let clonedAreas = [];
        let removedAreas = [];
        const deepClone = (obj: any) => JSON.parse(JSON.stringify(obj));
        for (let area of subwindow.areasSpecification) {
            let glazingBead = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions, totalGlazingBead,
                profileCompositionDistances);
            let intersectionsCount = DrawingUtil.polygonLineIntersectionCount(glazingBead, line, false);
            if (intersectionsCount === 2) {
                let newArea1 = new AreaSpecification(deepClone(area.filling), deepClone(area.glazing), new GlazingBead(area.glazingBead.id),
                    deepClone(area.definingMullions));
                newArea1.glazingPackageId = area.glazingPackageId;
                newArea1.glazingCategoryId = area.glazingCategoryId;
                newArea1.glazingFrameCategoryId = area.glazingFrameCategoryId;
                let newArea2 = new AreaSpecification(deepClone(area.filling), deepClone(area.glazing), new GlazingBead(area.glazingBead.id),
                    deepClone(area.definingMullions));
                newArea2.glazingPackageId = area.glazingPackageId;
                newArea2.glazingCategoryId = area.glazingCategoryId;
                newArea2.glazingFrameCategoryId = area.glazingFrameCategoryId;
                newArea1.definingMullions.push(new DefiningMullion(segment.id, 'top'));
                newArea2.definingMullions.push(new DefiningMullion(segment.id, 'bottom'));
                clonedAreas.push(newArea1, newArea2);
                removedAreas.push(area);
                operationResult.addIds(AreaUtils.removeConfigurableAddonIds([area]));
            }
        }
        let areasSpecifications = [...subwindow.areasSpecification];
        for (let area of clonedAreas) {
            areasSpecifications.push(area);
        }
        for (let area of removedAreas) {
            areasSpecifications = areasSpecifications.filter(a => a.generatedId !== area.generatedId);
        }
        subwindow.areasSpecification = [...areasSpecifications];
        return operationResult;
    }

    private static findMergeableAreas(subwindow: SubWindowData, totalGlazingBead: number[],
                                      areasDefinedByMullion: AreaSpecification[],
                                      removedSegmentId: number): AreaMergeResult {
        let isAreaMergable: AreaMergeResult = {};
        for (let area of areasDefinedByMullion) {
            isAreaMergable[area.ordinalNumber] = {};
        }
        for (let i = 0; i < areasDefinedByMullion.length; ++i) {
            let remainingDefiningMullionsArea1 = areasDefinedByMullion[i].definingMullions.filter(
                dm => dm.id !== removedSegmentId);
            let removedDefiningMullion1 = areasDefinedByMullion[i].definingMullions.find(
                dm => dm.id === removedSegmentId);
            let area1points = WindowCalculator.getAreaSizePointFull(subwindow,
                areasDefinedByMullion[i].definingMullions,
                PolygonPointUtil.toPolygonPoints(totalGlazingBead), true);
            let mergeCandidates = [];
            for (let j = i + 1; j < areasDefinedByMullion.length; ++j) {
                let remainingDefiningMullionsArea2 = areasDefinedByMullion[j].definingMullions.filter(
                    dm => dm.id !== removedSegmentId);
                let removedDefiningMullion2 = areasDefinedByMullion[j].definingMullions.find(
                    dm => dm.id === removedSegmentId);
                let sharedMullions = [];
                for (let k = 0; k < remainingDefiningMullionsArea1.length; ++k) {
                    let sharedMullionIndex = remainingDefiningMullionsArea2.findIndex(
                        dm => dm.id === remainingDefiningMullionsArea1[k].id);
                    if (sharedMullionIndex !== -1) {
                        sharedMullions.push({
                            area1side: remainingDefiningMullionsArea1[k].side,
                            area2side: remainingDefiningMullionsArea2[sharedMullionIndex].side
                        });
                    }
                }
                if (sharedMullions.every(sm => sm.area1side === sm.area2side) &&
                    removedDefiningMullion1.side !== removedDefiningMullion2.side) {
                    let area2points = WindowCalculator.getAreaSizePointFull(subwindow,
                        areasDefinedByMullion[j].definingMullions,
                        PolygonPointUtil.toPolygonPoints(totalGlazingBead), true);
                    let sharedPointCount = 0;
                    for (let area1point of area1points) {
                        let area2index = area2points.findIndex(pp => pp.x === area1point.x && pp.y === area1point.y);
                        if (area2index !== -1) {
                            ++sharedPointCount;
                        }
                    }
                    if (sharedPointCount >= 2) {
                        mergeCandidates.push(areasDefinedByMullion[j].ordinalNumber);
                    }
                }
            }
            if (mergeCandidates.length === 1) {
                console.debug(`Area ${areasDefinedByMullion[i].ordinalNumber} can be merged with ${mergeCandidates}`);
                isAreaMergable[areasDefinedByMullion[i].ordinalNumber] = {
                    result: AreaMergeStatus.MERGE_OWNER,
                    mergeWith: mergeCandidates[0]
                };
                isAreaMergable[mergeCandidates[0]] = {result: AreaMergeStatus.MERGED};
            } else if (mergeCandidates.length > 1) {
                console.error(`Area ${areasDefinedByMullion[i].ordinalNumber} has more than one possible area to merge with ` +
                    `${mergeCandidates}, falling back to recreating everything with no filling`);
                isAreaMergable[areasDefinedByMullion[i].ordinalNumber] = {result: AreaMergeStatus.CANNOT_MERGE};
            } else if (isAreaMergable[areasDefinedByMullion[i].ordinalNumber].result !== AreaMergeStatus.MERGED) {
                console.error(`Area ${areasDefinedByMullion[i].ordinalNumber} cannot be merged with anything, `
                    + `falling back to recreating everything with no filling`);
                isAreaMergable[areasDefinedByMullion[i].ordinalNumber] = {result: AreaMergeStatus.CANNOT_MERGE};
            }
        }
        return isAreaMergable;
    }

    private static doAreaMerge(subwindow: SubWindowData, totalGlazingBead: number[], totalInnerFrame: number[],
                               totalRealPackagePoints: number[],
                               profileCompositionDistances: ProfilesCompositionDistances,
                               isAreaMergable: AreaMergeResult,
                               removedSegmentId: number): OperationResult {
        let operationResult = new OperationResult([], true);
        for (let areaOrdinal in isAreaMergable) {
            let merge = isAreaMergable[areaOrdinal];
            if (merge.result === AreaMergeStatus.MERGE_OWNER) {
                let area1 = subwindow.areasSpecification.find(area => area.ordinalNumber === +areaOrdinal);
                let area2index = subwindow.areasSpecification.findIndex(area => area.ordinalNumber === merge.mergeWith);
                let area2 = subwindow.areasSpecification[area2index];
                operationResult.addIds(AreaUtils.removeConfigurableAddonIds([area1, area2]));
                area1.definingMullions = _.uniq([...area1.definingMullions, ...area2.definingMullions], dm => dm.id)
                    .filter(df => df.id !== removedSegmentId);
                subwindow.areasSpecification.splice(area2index, 1);
                if (!AreaUtils.isEqualFillingAndGlazing(area1, area2)) {
                    AreaUtils.resetFillingAndGlazing(area1);
                }
            }
        }
        AreaUtils.setNewAreaSizes(subwindow, totalGlazingBead, totalInnerFrame, totalRealPackagePoints, profileCompositionDistances);
        return operationResult;
    }

    public static mergeAreasBackTogether(mullion: Mullion, subwindow: SubWindowData, totalGlazingBead: number[],
                                         totalInnerFrame: number[], totalRealPackagePoints: number[],
                                         profileCompositionDistances: ProfilesCompositionDistances): OperationResult {
        let areasDefinedByMullion = [];
        let removedSegmentId = mullion.drawingSegments[0].id;
        for (let area of subwindow.areasSpecification) {
            if (area.definingMullions.some(dm => dm.id === mullion.drawingSegments[0].id)) {
                areasDefinedByMullion.push(area);
            }
        }
        console.debug(`Removing mullion segment: ${removedSegmentId}`);
        console.debug(`Areas defined by this mullion ${areasDefinedByMullion.map(a => a.ordinalNumber)}`);
        let operationResult = new OperationResult();
        let isAreaMergable = AreaUtils.findMergeableAreas(subwindow, totalGlazingBead, areasDefinedByMullion,
            removedSegmentId);
        subwindow.mullions.splice(subwindow.mullions.indexOf(mullion), 1);
        if (Object.keys(isAreaMergable)
            .every((areaOrdinal) => isAreaMergable[+areaOrdinal].result !== AreaMergeStatus.CANNOT_MERGE)) {
            operationResult.merge(
                AreaUtils.doAreaMerge(subwindow, totalGlazingBead, totalInnerFrame, totalRealPackagePoints,
                    profileCompositionDistances, isAreaMergable, removedSegmentId));
        } else {
            operationResult.merge(
                AreaUtils.createFreshAreas(subwindow, totalGlazingBead, totalInnerFrame, totalRealPackagePoints,
                    profileCompositionDistances));
        }
        return operationResult;
    }

    public static checkIfAnyAreasAreOverlapping(subwindow: SubWindowData, totalGlazingBead: number[],
                                                profileCompositionDistances: ProfilesCompositionDistances): boolean {
        return AreaUtils.checkIfAreasAreOverlapping(subwindow.areasSpecification, subwindow, totalGlazingBead, profileCompositionDistances);
    }

    public static checkIfAreasAreOverlapping(areas: AreaSpecification[], subwindow: SubWindowData, totalGlazingBead: number[],
                                             profileCompositionDistances: ProfilesCompositionDistances): boolean {
        let pointsMap = new Map<AreaSpecification, number[]>();
        for (let area of areas) {
            let points = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions, totalGlazingBead,
                profileCompositionDistances);
            if (points.length === 0) {
                return true;
            }
            pointsMap.set(area, points);
        }
        for (let i in areas) {
            let myPoints = pointsMap.get(subwindow.areasSpecification[i]);
            let j = +i + 1;
            while (subwindow.areasSpecification[j]) {
                let otherPoints = pointsMap.get(subwindow.areasSpecification[j]);
                if (DrawingUtil.doPolygonsOverlap(myPoints, otherPoints)) {
                    return true;
                }
                j++;
            }
        }
        return false;
    }
}
