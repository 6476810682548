<div class="dialog-window">
<p-dialog #addonDialog
          header="{{ (dialogType === DialogType.ADD_OTHER_SYSTEM_ADDON ? 'OFFER.POSITIONS.ADDON_CONFIG.NAME_OTHER_SYSTEM' : 'OFFER.POSITIONS.ADDON_CONFIG.NAME') | translate }}"
          [resizable]="false" [modal]="true" (onHide)="onDialogClose()" [visible]="true" [focusOnShow]="false">

    <div class="scrolled-dialog new-form-60vw">
        <ng-container *ngIf="dialogType === DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON">
            <div class="new-form-row">
                <app-select inputId="application" [disabled]="readOnlyMode"
                            label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.APPLICABLE' | translate }}"
                            [options]="configAddonApplications | async" [ngModel]="displayedDialogData.application"
                            (ngModelChange)="displayedDialogData.application = $event; initConfigurableAddon()"></app-select>
            </div>
        </ng-container>

        <ng-container *ngIf="displayedDialogData.application != null">
            <div class="new-form-row">
                <!-- CONFIGURABLE ADDON type select -->
                <app-select
                        *ngIf="dialogType === DialogType.EDIT_CONFIGURABLE_ADDON || dialogType === DialogType.ADD_CONFIGURABLE_ADDON || dialogType === DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON"
                        inputId="type" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.TYPE' | translate }}"
                        [options]="configAddonTypeItems | async" [ngModel]="selectedConfigAddonType"
                        [disabled]="formBuilding || readOnlyMode || dialogType === DialogType.EDIT_CONFIGURABLE_ADDON"
                        (ngModelChange)="updateAvailableDefinitions($event)"></app-select>
                <!-- OTHER SYSTEM type select -->
                <app-select *ngIf="dialogType === DialogType.ADD_OTHER_SYSTEM_ADDON" inputId="type"
                            label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.TYPE_OTHER_SYSTEM' | translate }}"
                            [options]="configOtherSystemAddonTypeItems | async" [ngModel]="selectedConfigAddonType"
                            [disabled]="formBuilding || readOnlyMode"
                            (ngModelChange)="updateAvailableDefinitions($event)"></app-select>
            </div>
            <div class="new-form-row">
                <!-- TYPE of addon -->
                <app-select inputId="selectedConfigAddon"
                            label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.DEFINITION' | translate }}"
                            [disabled]="selectedConfigAddonType == undefined || formBuilding || readOnlyMode || dialogType === DialogType.EDIT_CONFIGURABLE_ADDON"
                            [ngModel]="configAddon" [options]="addonsOfTypeSelectItems | async" [optionKey]="definitionKey"
                            (ngModelChange)="buildConfigAddonForm($event)"></app-select>
            </div>
            <div *ngIf="parentElementDescription" class="new-form-row">
                <div class="new-form-field">
                    <label>{{ 'OFFER.POSITIONS.ADDON_CONFIG.PARENT_APPLICATIONS.LABEL' | translate }}</label>
                    <div class="new-form-field-input-container parent-applications">
                        <ng-container *ngFor="let description of parentElementDescription">
                            <div>
                                {{ ('OFFER.POSITIONS.ADDON_CONFIG.PARENT_APPLICATIONS.' + description.application) | translate }}
                            </div>
                            <div>
                                <ng-container *ngIf="showParentInfoIndex(description.application)">
                                    {{ description.index }}
                                </ng-container>
                                {{getDescription(description)}}
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <app-configurable-addon #configurableAddonComponent *ngIf="newConfigAddonDefPricingOk"
                                    [application]="displayedDialogData.application" (rebuildFormComplete)="onRebuildFormComplete()"
                                    [errors]="configAddonErrors" [definition]="newConfigAddonDef"
                                    [image]="newConfigAddonDescriptionImage" [readOnlyMode]="readOnlyMode"
                                    [pricingErrors]="configAddonError?.pricingFailedFields"
                                    [initialValues]="configAddonInitialValues"
                                    [defaultAttributeValues]="initialAttributeValues"
                                    [showDimensionSection]="showDimensionSection"></app-configurable-addon>

            <!-- Quantity and unit price accordion -->
            <ng-container *ngIf="showPriceSection && newConfigAddonDefPricingOk">
                <p-accordion [multiple]="true" styleClass="no-accordion-content-padding">
                    <p-accordionTab header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.GROUP.SUMMARY' | translate}}" [selected]="true"
                                    [ngClass] = "{'accordion-header-with-errors': configAddonErrors && configAddonErrors['quantity']}">

                        <div *ngIf="showQuantitySection" class="new-form-row">
                            <app-configurable-addon-field id="quantity" [cecha]="quantityCecha" [errors]="configAddonErrors" errorKey="quantity"
                                                          [initialValue]="quantity" [userLang]="userLang" [readOnlyMode]="readOnlyMode"
                                                          (valueChange)="changeQuantity($event)"></app-configurable-addon-field>
                        </div>
                        <div class="new-form-row">
                            <!-- PRICE -->
                            <div class="new-form-field new-form-field-disabled">
                                <label for="currentAddonPrice">{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.PRICE' | translate }}</label>
                                <div class="recalculate-price-row">
                                    <input *ngIf="offer.currency === selectedCurrency" id="currentAddonPrice" [ngModel]="configAddonCalculatedPrice.price" disabled>
                                    <textarea *ngIf="offer.currency !== selectedCurrency" [disabled]="true">{{configAddonCalculatedPrice.price}}</textarea>
                                    <app-simple-button buttonId="recalculatePrice" [disabled]="readOnlyMode"
                                                       label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.RECALCULATE' | translate }}"
                                                       icon="cached" type="action" [size]="40"
                                                       (onClick)="recalculateConfigAddonPrice()"></app-simple-button>
                                </div>
                            </div>
                        </div>
                    </p-accordionTab>
                </p-accordion>
            </ng-container>

            <!-- MESSAGES -->
            <div>
                <div tabindex="1" id="configAddonError" #error *ngIf="configAddonError" class="new-form-field pricing-message">
                    <div class="new-form-field-error-message">
                        {{configAddonError.message | translate}}
                    </div>
                </div>
                <div tabindex="1" #messages class="pricing-message" *ngFor="let configAddonMessage of configAddonMessages">
                    <app-pricing-message [message]="configAddonMessage" [ignoreFieldName]="true"></app-pricing-message>
                </div>
            </div>
        </ng-container>
    </div>

    <!-- BUTTONS -->
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="saveButton"
                               label="{{ 'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40" [disabled]="!newConfigAddonDefPricingOk || formBuilding || readOnlyMode"
                               (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button buttonId="cancelButton" label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="onDialogClose()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
</div>

<p-dialog id="show-validation-messages-dialog" [(visible)]="showValidationMessagesDialog"
          [closable]="false" [resizable]="false" [modal]="true"
          [focusOnShow]="false" header="{{'OFFER.MENU.EXIT_WITH_MESSAGES' | translate}}">

    <div class="new-form-600">
        <div *ngFor="let configAddonMessage of configAddonMessages">
            <app-pricing-message [message]="configAddonMessage" [ignoreFieldName]="true"></app-pricing-message>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="confirmValidationMessages()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="closeValidationMessagesDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<app-missing-profit-margin-handler></app-missing-profit-margin-handler>
