import {FileValidator} from '../../../form-inputs/inputs/file-upload/file-validator';
import {Pricesheet} from './pricesheet';

export class PricesheetValidator {

    static validate(pricesheet: Pricesheet, file: File): { [field: string]: string } {
        const errors = {};
        if (!pricesheet.name) {
            errors['name'] = 'error.pricingSheetDto.name.not_empty';
        } else if (pricesheet.name.length > 100) {
            errors['name'] = 'error.pricingSheetDto.name.not_in_range';
        }
        if (!pricesheet.type) {
            errors['type'] = 'error.pricingSheetDto.type.not_exists';
        }
        if (file == undefined) {
            errors['file'] = 'error.pricingSheetDto.file.not_exists';
        } else {
            const fileError = FileValidator.validationErrors(file, undefined, 7000000);
            if (!!fileError) {
                errors['file'] = fileError;
            }
        }
        return errors;
    }
}
