<div class="list-content">
    <div class="table-header">
        <div class="title">
            {{ 'NAVIGATION.ITEMS.OFFERS.SEARCH' | translate }}
        </div>
    </div>

    <div class="datatable-container">
        <p-table #dt [value]="offers" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="limitedRowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="true" [lazyLoadOnInit]="false" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="handleLazyLoad($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th>
                        {{ 'OFFER.FORM.ACTIONS' | translate }}
                    </th>
                    <th *ngIf="columnByField['offerNumber']" pSortableColumn="offerNumber"
                        [ngClass]="columnHeaderClasses('offerNumber')">
                        <span>{{ 'OFFER.FORM.OFFER_NUMBER' | translate }}</span>
                        <p-sortIcon field="offerNumber"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['alternateOfferNumber']" pSortableColumn="alternateOfferNumber"
                        [ngClass]="columnHeaderClasses('alternateOfferNumber')">
                        <span>{{ 'OFFER.FORM.ALT_OFFER_NUMBER' | translate }}</span>
                        <p-sortIcon field="alternateOfferNumber"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['productionOrderNumber']"
                        [ngClass]="columnHeaderClasses('productionOrderNumber')">
                        <span>{{ 'OFFER.FORM.PRODUCTION_ORDER_NUMBER' | translate }}</span>
                    </th>
                    <th *ngIf="columnByField['deliveryListName']" [ngClass]="columnHeaderClasses('deliveryListName')">
                        <span>{{ 'OFFER.FORM.DELIVERY_LISTS_NAMES' | translate }}</span>
                    </th>
                    <th *ngIf="columnByField['paletteName']" [ngClass]="columnHeaderClasses('paletteName')">
                        <span>{{ 'OFFER.FORM.PALETTE_NAMES' | translate }}</span>
                    </th>
                    <th *ngIf="columnByField['paymentPackageName']" pSortableColumn="paymentPackage.name"
                        [ngClass]="columnHeaderClasses('paymentPackageName')">
                        <span>{{ 'OFFER.FORM.RELATED_PAYMENT_PACKAGE_NAME' | translate }}</span>
                        <p-sortIcon field="paymentPackage.name"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['createdDate']" pSortableColumn="createdDate"
                        [ngClass]="columnHeaderClasses('createdDate')">
                        <span>{{ 'OFFER.FORM.CREATED_DATE' | translate }}</span>
                        <p-sortIcon field="createdDate"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['lastStatusChange']" pSortableColumn="lastStatusChange"
                        [ngClass]="columnHeaderClasses('lastStatusChange')">
                        <span>{{ 'OFFER.FORM.LAST_STATUS_CHANGE' | translate }}</span>
                        <p-sortIcon field="lastStatusChange"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['status']" pSortableColumn="status"
                        [ngClass]="columnHeaderClasses('status')">
                        <span>{{ 'OFFER.FORM.STATUS' | translate }}</span>
                        <p-sortIcon field="status"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['lastModifiedDate']" pSortableColumn="lastModifiedDate"
                        [ngClass]="columnHeaderClasses('lastModifiedDate')">
                        <span>{{ 'OFFER.FORM.LAST_MODIFIED_DATE' | translate }}</span>
                        <p-sortIcon field="lastModifiedDate"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['validFrom']" pSortableColumn="validFrom"
                        [ngClass]="columnHeaderClasses('validFrom')">
                        <span>{{ 'OFFER.FORM.VALID_FROM' | translate }}</span>
                        <p-sortIcon field="validFrom"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['validTo']" pSortableColumn="validTo"
                        [ngClass]="columnHeaderClasses('validTo')">
                        <span>{{ 'OFFER.FORM.VALID_TO' | translate }}</span>
                        <p-sortIcon field="validTo"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['subsystemName']" pSortableColumn="subsystem.name"
                        [ngClass]="columnHeaderClasses('subsystemName')">
                        <span>{{ 'OFFER.FORM.SUBSYSTEM' | translate }}</span>
                        <p-sortIcon field="subsystem.name"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['clientManagerName']" pSortableColumn="clientManagerName"
                        [ngClass]="columnHeaderClasses('clientManagerName')">
                        <span>{{ 'OFFER.FORM.CLIENT_MANAGER' | translate }}</span>
                        <p-sortIcon field="clientManagerName"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['clientGroupName']" pSortableColumn="client.group.name"
                        [ngClass]="columnHeaderClasses('clientGroupName')">
                        <span>{{ 'OFFER.FORM.CLIENT_GROUP' | translate }}</span>
                        <p-sortIcon field="client.group.name"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['sellerClientGroupName']" pSortableColumn="sellerClient.group.name"
                        [ngClass]="columnHeaderClasses('sellerClientGroupName')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_GROUP' | translate }}</span>
                        <p-sortIcon field="sellerClient.group.name"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['merchantGroupName']" [ngClass]="columnHeaderClasses('merchantGroupName')">
                        <span>{{ 'OFFER.FORM.MERCHANT_GROUP' | translate }}</span>
                    </th>
                    <th *ngIf="columnByField['clientName']" pSortableColumn="client.name"
                        [ngClass]="columnHeaderClasses('clientName')">
                        <span>{{ 'OFFER.FORM.CLIENT_NAME' | translate }}</span>
                        <p-sortIcon field="client.name"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['clientEmail']" pSortableColumn="client.email"
                        [ngClass]="columnHeaderClasses('clientEmail')">
                        <span>{{ 'OFFER.FORM.CLIENT_EMAIL' | translate }}</span>
                        <p-sortIcon field="client.email"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['clientIdentifier']" pSortableColumn="client.identifier"
                        [ngClass]="columnHeaderClasses('clientIdentifier')">
                        <span>{{ 'OFFER.FORM.CLIENT_IDENTIFIER' | translate }}</span>
                        <p-sortIcon field="client.identifier"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['sellerClientName']" pSortableColumn="sellerClient.name"
                        [ngClass]="columnHeaderClasses('sellerClientName')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_NAME' | translate }}</span>
                        <p-sortIcon field="sellerClient.name"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['sellerClientEmail']" pSortableColumn="sellerClient.email"
                        [ngClass]="columnHeaderClasses('sellerClientEmail')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_EMAIL' | translate }}</span>
                        <p-sortIcon field="sellerClient.email"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['sellerClientIdentifier']" pSortableColumn="sellerClient.identifier"
                        [ngClass]="columnHeaderClasses('sellerClientIdentifier')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_IDENTIFIER' | translate }}</span>
                        <p-sortIcon field="sellerClient.identifier"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['vatRetailSell']" pSortableColumn="retailSellPrice.vatPercent"
                        [ngClass]="columnHeaderClasses('vatRetailSell')">
                        <span>{{ 'OFFER.FORM.VAT_RETAIL_SELL' | translate }}</span>
                        <p-sortIcon field="retailSellPrice.vatPercent"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['retailSellNetVal']" pSortableColumn="retailSellPrice.netValue"
                        [ngClass]="columnHeaderClasses('retailSellNetVal')">
                        <span>{{ 'OFFER.FORM.RETAIL_SELL_NET_VAL' | translate }}</span>
                        <p-sortIcon field="retailSellPrice.netValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['retailSellGrossVal']" pSortableColumn="retailSellPrice.grossValue"
                        [ngClass]="columnHeaderClasses('retailSellGrossVal')">
                        <span>{{ 'OFFER.FORM.RETAIL_SELL_GROSS_VAL' | translate }}</span>
                        <p-sortIcon field="retailSellPrice.grossValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['vatBuy']" pSortableColumn="buyPrice.vatPercent"
                        [ngClass]="columnHeaderClasses('vatBuy')">
                        <span>{{ 'OFFER.FORM.VAT_BUY' | translate }}</span>
                        <p-sortIcon field="buyPrice.vatPercent"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['vatSell']" pSortableColumn="sellPrice.vatPercent"
                        [ngClass]="columnHeaderClasses('vatSell')">
                        <ng-container *ngIf="permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']}); else vatSellLabel">
                            <span>{{ 'OFFER.FORM.VAT_SELL_FOR_SELLER' | translate }}</span>
                        </ng-container>
                        <ng-template #vatSellLabel><span>{{ 'OFFER.FORM.VAT_SELL' | translate }}</span></ng-template>
                        <p-sortIcon field="sellPrice.vatPercent"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['sellNetVal']" pSortableColumn="sellPrice.netValue"
                        [ngClass]="columnHeaderClasses('sellNetVal')">
                        <ng-container *ngIf="permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']}); else sellNetLabel">
                            <span>{{ 'OFFER.FORM.SELL_NET_VAL_FOR_SELLER' | translate }}</span>
                        </ng-container>
                        <ng-template #sellNetLabel><span>{{ 'OFFER.FORM.SELL_NET_VAL' | translate }}</span></ng-template>
                        <p-sortIcon field="sellPrice.netValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['sellGrossVal']" pSortableColumn="sellPrice.grossValue"
                        [ngClass]="columnHeaderClasses('sellGrossVal')">
                        <ng-container *ngIf="permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']}); else sellGrossLabel">
                            <span>{{ 'OFFER.FORM.SELL_GROSS_VAL_FOR_SELLER' | translate }}</span>
                        </ng-container>
                        <ng-template #sellGrossLabel><span>{{ 'OFFER.FORM.SELL_GROSS_VAL' | translate }}</span></ng-template>
                        <p-sortIcon field="sellPrice.grossValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['buyNetVal']" pSortableColumn="buyPrice.netValue"
                        [ngClass]="columnHeaderClasses('buyNetVal')">
                        <span>{{ 'OFFER.FORM.BUY_NET_VAL' | translate }}</span>
                        <p-sortIcon field="buyPrice.netValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['buyGrossVal']" pSortableColumn="buyPrice.grossValue"
                        [ngClass]="columnHeaderClasses('buyGrossVal')">
                        <span>{{ 'OFFER.FORM.BUY_GROSS_VAL' | translate }}</span>
                        <p-sortIcon field="buyPrice.grossValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['buyVenskaNetVal']" pSortableColumn="venskaBuyPrice.netValue"
                        [ngClass]="columnHeaderClasses('buyVenskaNetVal')">
                        <span>{{ 'OFFER.FORM.BUY_VENSKA_NET_VAL' | translate }}</span>
                        <p-sortIcon field="venskaBuyPrice.netValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['buyVenskaGrossVal']" pSortableColumn="venskaBuyPrice.grossValue"
                        [ngClass]="columnHeaderClasses('buyVenskaGrossVal')">
                        <span>{{ 'OFFER.FORM.BUY_VENSKA_GROSS_VAL' | translate }}</span>
                        <p-sortIcon field="venskaBuyPrice.grossValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['ownAddonsNetCost']" pSortableColumn="ownAddonsCost.netValue"
                        [ngClass]="columnHeaderClasses('ownAddonsNetCost')">
                        <span>{{ 'OFFER.FORM.OWN_ADDONS_NET_COST' | translate }}</span>
                        <p-sortIcon field="ownAddonsCost.netValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['ownAddonsGrossCost']" pSortableColumn="ownAddonsCost.grossValue"
                        [ngClass]="columnHeaderClasses('ownAddonsGrossCost')">
                        <span>{{ 'OFFER.FORM.OWN_ADDONS_GROSS_COST' | translate }}</span>
                        <p-sortIcon field="ownAddonsCost.grossValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['assemblyNetCost']" pSortableColumn="assemblyCost.netValue"
                        [ngClass]="columnHeaderClasses('assemblyNetCost')">
                        <span>{{ 'OFFER.FORM.ASSEMBLY_NET_COST' | translate }}</span>
                        <p-sortIcon field="assemblyCost.netValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['assemblyGrossCost']" pSortableColumn="assemblyCost.grossValue"
                        [ngClass]="columnHeaderClasses('assemblyGrossCost')">
                        <span>{{ 'OFFER.FORM.ASSEMBLY_GROSS_COST' | translate }}</span>
                        <p-sortIcon field="assemblyCost.grossValue"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['netRetailIncome']" pSortableColumn="netRetailIncome"
                        [ngClass]="columnHeaderClasses('netRetailIncome')">
                        <span>{{ 'OFFER.FORM.NET_RETAIL_INCOME' | translate }}</span>
                        <p-sortIcon field="netRetailIncome"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['grossRetailIncome']" pSortableColumn="grossRetailIncome"
                        [ngClass]="columnHeaderClasses('grossRetailIncome')">
                        <span>{{ 'OFFER.FORM.GROSS_RETAIL_INCOME' | translate }}</span>
                        <p-sortIcon field="grossRetailIncome"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['netIncome']" pSortableColumn="netIncome"
                        [ngClass]="columnHeaderClasses('netIncome')">
                        <span>{{ 'OFFER.FORM.NET_INCOME' | translate }}</span>
                        <p-sortIcon field="netIncome"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['grossIncome']" pSortableColumn="grossIncome"
                        [ngClass]="columnHeaderClasses('grossIncome')">
                        <span>{{ 'OFFER.FORM.GROSS_INCOME' | translate }}</span>
                        <p-sortIcon field="grossIncome"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['netVenskaIncome']" pSortableColumn="netVenskaIncome"
                        [ngClass]="columnHeaderClasses('netVenskaIncome')">
                        <span>{{ 'OFFER.FORM.NET_INCOME' | translate }}</span>
                        <p-sortIcon field="netVenskaIncome"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['grossVenskaIncome']" pSortableColumn="grossVenskaIncome"
                        [ngClass]="columnHeaderClasses('grossVenskaIncome')">
                        <span>{{ 'OFFER.FORM.GROSS_INCOME' | translate }}</span>
                        <p-sortIcon field="grossVenskaIncome"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['merchantName']" pSortableColumn="merchantName"
                        [ngClass]="columnHeaderClasses('merchantName')">
                        <span>{{ 'OFFER.FORM.MERCHANT_NAME' | translate }}</span>
                        <p-sortIcon field="merchantName"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['deliveryAddressStreet']" pSortableColumn="deliveryAddress.address.street"
                        [ngClass]="columnHeaderClasses('deliveryAddressStreet')">
                        {{ 'OFFER.FORM.ADDRESS.STREET' | translate }}
                        <p-sortIcon field="deliveryAddress.address.street"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['deliveryAddressCity']" pSortableColumn="deliveryAddress.address.city"
                        [ngClass]="columnHeaderClasses('deliveryAddressCity')">
                        {{ 'OFFER.FORM.ADDRESS.CITY' | translate }}
                        <p-sortIcon field="deliveryAddress.address.city"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['deliveryAddressZip']" pSortableColumn="deliveryAddress.address.zip"
                        [ngClass]="columnHeaderClasses('deliveryAddressZip')">
                        {{ 'OFFER.FORM.ADDRESS.ZIP' | translate }}
                        <p-sortIcon field="deliveryAddress.address.zip"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['deliveryAddressCountry']"
                        pSortableColumn="deliveryAddress.address.country"
                        [ngClass]="columnHeaderClasses('deliveryAddressCountry')">
                        {{ 'OFFER.FORM.ADDRESS.COUNTRY' | translate }}
                        <p-sortIcon field="deliveryAddress.address.country"></p-sortIcon>
                    </th>
                    <th *ngIf="columnByField['sourceOfferNumber']" pSortableColumn="sourceOfferNumber"
                        [ngClass]="columnHeaderClasses('sourceOfferNumber')">
                        <span>{{ 'OFFER.FORM.SOURCE_OFFER_NUMBER' | translate }}</span>
                        <p-sortIcon field="sourceOfferNumber"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-offer let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" (dblclick)="redirectToPositionList(offer)" [pSelectableRow]="offer"
                    [pSelectableRowIndex]="rowIndex" [app-table-keyboard-navigation]="offer"
                    (app-table-keyboard-navigation-enter)="redirectToPositionList(offer)" class="datatable-data">
                    <td>
                        <div class="table-row-action-buttons">
                            <app-simple-button [buttonId]="'open_' + offer.id" icon="search" type="main-action"
                                               [size]="36"
                                               label="{{'OFFER.ACTIONS.TOOLTIPS.SHOW_OFFER_DETAILS' | translate}}"
                                               [inlineLabel]="false"
                                               (onClick)="redirectToPositionList(offer)"></app-simple-button>
                            <app-simple-button [buttonId]="'showInMainView_' + offer.id" icon="list" type="action"
                                               [size]="36"
                                               label="{{ (isOrder(offer) ? 'OFFER.ACTIONS.TOOLTIPS.SHOW_IN_ORDER_VIEW' : 'OFFER.ACTIONS.TOOLTIPS.SHOW_IN_OFFER_VIEW') | translate }}"
                                               [inlineLabel]="false"
                                               (onClick)="showInMainView(offer)"></app-simple-button>
                        </div>
                    </td>
                    <td *ngIf="columnByField['offerNumber']">
                        <app-offer-number [offerNumber]="offer.offerNumber" [isOffer]="true"></app-offer-number>
                    </td>
                    <td *ngIf="columnByField['alternateOfferNumber']">
                        {{ offer.alternateOfferNumber }}
                    </td>
                    <td *ngIf="columnByField['productionOrderNumber']">
                        <span *ngFor="let productionOrderNumber of offer.productionOrders">
                            <app-offer-number [offerNumber]="productionOrderNumber"
                                              [isProductionOrder]="true"></app-offer-number>
                        </span>
                    </td>
                    <td *ngIf="columnByField['deliveryListName']">
                        <div *ngFor="let deliveryListName of offer.deliveryLists">
                            {{ deliveryListName }}
                        </div>
                    </td>
                    <td *ngIf="columnByField['paletteName']">
                        <div *ngFor="let paletteName of offer.palettes">
                            {{ paletteName }}
                        </div>
                    </td>
                    <td *ngIf="columnByField['paymentPackageName']">
                        {{ offer.paymentPackageName }}
                    </td>
                    <td *ngIf="columnByField['createdDate']">
                        {{ offer.createdDate | momentCurrentLang | amDateFormat: 'L' }}
                    </td>
                    <td *ngIf="columnByField['lastStatusChange']">
                        {{ offer.lastStatusChange | momentCurrentLang | amDateFormat: 'L LT' }}
                    </td>
                    <td *ngIf="columnByField['status']">
                        <div class="status-color {{ offer.status }}">
                            {{ ('OFFER.STATUS.' + offer.status) | translate }}
                        </div>
                    </td>
                    <td *ngIf="columnByField['lastModifiedDate']">
                        {{ offer.lastModifiedDate | momentCurrentLang | amDateFormat: 'L LT' }}
                    </td>
                    <td *ngIf="columnByField['validFrom']">
                        {{ offer.validFrom | momentCurrentLang | amDateFormat: 'L' }}
                    </td>
                    <td *ngIf="columnByField['validTo']">
                        {{ offer.validTo | momentCurrentLang | amDateFormat: 'L' }}
                    </td>
                    <td *ngIf="columnByField['subsystemName']">
                        {{ offer.subsystemName }}
                    </td>
                    <td *ngIf="columnByField['clientManagerName']">
                        {{ offer.clientManagerFirstName + ' ' + offer.clientManagerLastName }}
                    </td>
                    <td *ngIf="columnByField['clientGroupName']">
                        {{ offer.clientGroupName }}
                    </td>
                    <td *ngIf="columnByField['sellerClientGroupName']">
                        {{ offer.sellerClientGroupName }}
                    </td>
                    <td *ngIf="columnByField['merchantGroupName']">
                        <div *ngIf="containsSemicolon(offer.merchantGroupName)"
                             pTooltip="{{ changeSemicolonsToLineBreaks(offer.merchantGroupName) }}"
                             tooltipPosition="left">
                            ...
                        </div>
                        <div *ngIf="!containsSemicolon(offer.merchantGroupName)">
                            {{ offer.merchantGroupName }}
                        </div>
                    </td>
                    <td *ngIf="columnByField['clientName']">
                        {{ offer.clientName }}
                    </td>
                    <td *ngIf="columnByField['clientEmail']">
                        {{ offer.clientEmail }}
                    </td>
                    <td *ngIf="columnByField['clientIdentifier']">
                        {{ offer.clientIdentifier }}
                    </td>
                    <td *ngIf="columnByField['sellerClientName']">
                        {{ offer.sellerClientName }}
                    </td>
                    <td *ngIf="columnByField['sellerClientEmail']">
                        {{ offer.sellerClientEmail }}
                    </td>
                    <td *ngIf="columnByField['sellerClientIdentifier']">
                        {{ offer.sellerClientIdentifier }}
                    </td>
                    <td *ngIf="columnByField['vatRetailSell']">
                        {{ offer.retailSellPrice.vatPercent }}
                    </td>
                    <td *ngIf="columnByField['retailSellNetVal']">
                        <span> {{ offer.retailSellPrice && offer.retailSellPrice.netValue ? (getPriceInSelectedCurrency(offer.retailSellPrice.netValue, offer.retailSellPriceInOfferCurrency.netValue, offer, true) | price: selectedCurrency) : ''}} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ offer.retailSellPrice && offer.retailSellPrice.netValue ? (getPriceInDefaultCurrency(offer.retailSellPrice.netValue, offer.retailSellPriceInOfferCurrency.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency) : '' }} </span>
                    </td>
                    <td *ngIf="columnByField['retailSellGrossVal']">
                        <span> {{ offer.retailSellPrice && offer.retailSellPrice.grossValue ? (getPriceInSelectedCurrency(offer.retailSellPrice.grossValue, offer.retailSellPriceInOfferCurrency.grossValue, offer, true) | price: selectedCurrency) : '' }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ offer.retailSellPrice && offer.retailSellPrice.grossValue ? (getPriceInDefaultCurrency(offer.retailSellPrice.grossValue, offer.retailSellPriceInOfferCurrency.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency) : ''}} </span>
                    </td>
                    <td *ngIf="columnByField['vatBuy']">
                        {{ offer.buyPrice.vatPercent }}
                    </td>
                    <td *ngIf="columnByField['vatSell']">
                        {{ offer.sellPrice.vatPercent }}
                    </td>
                    <td *ngIf="columnByField['sellNetVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.sellPrice.netValue, offer.sellPriceInOfferCurrency.netValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.sellPrice.netValue, offer.sellPriceInOfferCurrency.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['sellGrossVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.sellPrice.grossValue, offer.sellPriceInOfferCurrency.grossValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.sellPrice.grossValue, offer.sellPriceInOfferCurrency.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['buyNetVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.buyPrice.netValue, offer.buyPriceInOfferCurrency.netValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.buyPrice.netValue, offer.buyPriceInOfferCurrency.netValue, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['buyGrossVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.buyPrice.grossValue, offer.buyPriceInOfferCurrency.grossValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.buyPrice.grossValue, offer.buyPriceInOfferCurrency.grossValue, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['buyVenskaNetVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.venskaBuyPrice.netValue, offer.venskaBuyPriceInOfferCurrency.netValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.venskaBuyPrice.netValue, offer.venskaBuyPriceInOfferCurrency.netValue, offer.exchangeRate) | price: offer.currency}} </span>
                    </td>
                    <td *ngIf="columnByField['buyVenskaGrossVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.venskaBuyPrice.grossValue, offer.venskaBuyPriceInOfferCurrency.grossValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.venskaBuyPrice.grossValue, offer.venskaBuyPriceInOfferCurrency.grossValue, offer.exchangeRate) | price: offer.currency}} </span>
                    </td>
                    <td *ngIf="columnByField['ownAddonsNetCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.ownAddonsCost.netValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.ownAddonsCost.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['ownAddonsGrossCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.ownAddonsCost.grossValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.ownAddonsCost.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['assemblyNetCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.assemblyCost.netValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.assemblyCost.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['assemblyGrossCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.assemblyCost.grossValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.assemblyCost.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['netRetailIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.netRetailIncome, offer.netRetailIncomeInOfferCurrency, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.netRetailIncome, offer.netRetailIncomeInOfferCurrency, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['grossRetailIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.grossRetailIncome, offer.grossIncomeInOfferCurrency, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.grossRetailIncome, offer.grossIncomeInOfferCurrency, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['netIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.netIncomeInOfferCurrency, offer.netIncome, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.netIncome, offer.netIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['grossIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.grossIncome, offer.grossIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.grossIncome, offer.grossIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['netVenskaIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.netVenskaIncome, offer.netVenskaIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.netVenskaIncome, offer.netVenskaIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['grossVenskaIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.grossVenskaIncome, offer.grossVenskaIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price"
                              *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.grossVenskaIncome, offer.grossVenskaIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['merchantName']">
                        {{ offer.merchantFirstName + ' ' + offer.merchantLastName }}
                    </td>
                    <td *ngIf="columnByField['deliveryAddressStreet']">
                        {{ offer.deliveryAddressStreet }}
                    </td>
                    <td *ngIf="columnByField['deliveryAddressCity']">
                        {{ offer.deliveryAddressCity }}
                    </td>
                    <td *ngIf="columnByField['deliveryAddressZip']">
                        {{ offer.deliveryAddressZip }}
                    </td>

                    <td *ngIf="columnByField['deliveryAddressCountry']">
                        <ng-container *ngIf="offer.deliveryAddressCountry != null">
                            {{ ('COUNTRIES.' + offer.deliveryAddressCountry) | translate }}
                        </ng-container>
                    </td>
                    <td *ngIf="columnByField['sourceOfferNumber']">
                        <app-offer-number [offerNumber]="offer.sourceWebshopOfferNumber" [isOffer]="true"></app-offer-number>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="p-datatable-emptymessage-row">
                    <td [attr.colspan]="columns.length" class="p-datatable-emptymessage">
                        <span>{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>
