import {Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {ShippingItemFloorAffiliation} from "../shipping-simulation/ShippingItemFloorAffiliation";
import {ShippingItem} from '../ShippingItem';

@Component({
    selector: 'app-shipping-item-drawer',
    templateUrl: './shipping-item-drawer.component.html',
    styleUrls: ['../shipping-drawer.css']
})
export class ShippingItemDrawerComponent {

    @Input()
    item: ShippingItem;

    @Input()
    scale: number;

    @Input()
    floorAffiliation: ShippingItemFloorAffiliation;

    ShippingItemFloorAffiliation = ShippingItemFloorAffiliation;

    constructor(private translate: TranslateService) {
    }

    getPosition(attribute: string): number {
        return Math.round(this.item.position[attribute] * this.scale);
    }

    getSize(attribute: string): number {
        return Math.round(this.item.size[attribute] * this.scale);
    }

    showItem(): boolean {
        return this.floorAffiliation !== ShippingItemFloorAffiliation.NONE;
    }

    getTooltip(): string {
        let i = this.item;
        return `${this.translate.instant('OFFER.FORM.OFFER_NUMBER')}: ${i.offerNumber}` +
            `\n${this.translate.instant('OFFER.POSITIONS.FORM.PRINT_ORDER')}: ${i.printOrder}` +
            `\n${this.translate.instant('OFFER.POSITIONS.FORM.NAME')}: ${i.positionName[this.translate.currentLang]}` +
            `\n${this.translate.instant('GENERAL.POSITION')}: ${i.position.x}x${i.position.y}x${i.position.z}` +
            `\n${this.translate.instant('OFFER.POSITIONS.ADDON_CONFIG.GROUP.DIMENSIONS')}: ${i.size.x}x${i.size.y}x${i.size.z}` +
            (i.flipped ? `\n${this.translate.instant('SHIPPING_SIMULATION.DETAILS.ITEM_FLIPPED')}` : '') +
            `\nID: ${i.offerPositionId}`;
    }
}
