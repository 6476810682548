import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValidationErrors} from '../../../../common/validation-errors';
import {SupportedLanguages} from '../../../../supportedLanguages';
import {PromotionInformation} from '../promotion-information';

@Component({
    selector: 'app-promotion-information-form',
    templateUrl: './promotion-information-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionInformationFormComponent {

    @Input()
    promotionInformation: PromotionInformation;

    @Input()
    validationErrors: ValidationErrors;

    supportedLanguages = SupportedLanguages.languages;

    getTextErrorKey(key: string): string {
        if (key == undefined) {
            return undefined;
        }
        return key.replace(/text\[[a-z]{2}]/, 'text');
    }
}
