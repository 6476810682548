import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {WindowSystemTypeData, WindowSystemTypeEnum} from "../../../../window-designer/catalog-data/window-system-interface";
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {DataServiceHelper, FileState} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ProfitMarginExistance} from '../../offer/window-editor/drawing-tool/ProfitMarginExistance';
import {WindowEditorWindowSystemInterface} from '../../offer/window-editor/window-editor-window-system-interface';
import {BusinessTypeList} from '../business-type/BusinessTypeList';
import {EntranceModel} from '../entrance-model/entrance-model';
import {ItemForCatalogLinking} from '../single-system-checkbox-crud/item-for-catalog-linking';
import {ProductTypeGroup} from "./product-type-group";
import {
    WindowSystemDefinition,
    WindowSystemDefinitionList,
    WindowSystemName,
    WindowSystemsAddOnsList,
    WindowSystemsColorsDefinitionList,
    WindowSystemsDecorativeFillingsDefinitionList,
    WindowSystemsFrameProfilesList,
    WindowSystemsGlazingBeadsList,
    WindowSystemsOtherFillingsDefinitionList,
    WindowSystemsSealsDefinitionList
} from './window-system-definition';
import {WindowSystemWebShopInfo} from './window-system-web-shop-info';

@Injectable()
export class WindowSystemDefinitionService {

    static readonly API_URL = 'system';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getSystems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
               sortColumn: string, sortOrder: number): Observable<WindowSystemDefinitionList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<WindowSystemDefinitionList>('systems', {params: params});
    }

    getRoofSystems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                   sortColumn: string, sortOrder: number): Observable<WindowSystemDefinitionList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<WindowSystemDefinitionList>('systems/roofSystems', {params: params});
    }

    getSystem(windowSystemDefinitionId: number): Observable<WindowSystemDefinition> {
        return this.http.get<WindowSystemDefinition>(`${WindowSystemDefinitionService.API_URL}/${windowSystemDefinitionId}`);
    }

    getGlamourPrintIconAsFile(windowSystemId: number, language: keyof MultilanguageFieldInterface): Observable<File> {
        return this.http.get(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/glamourPrintIcon/${language}`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    saveGlamourPrintIconAsFile(windowSystemId: number, language: keyof MultilanguageFieldInterface, file: File): Observable<void> {
        const formData = new FormData();
        formData.append('glamourPrintIcon', file);
        return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/glamourPrintIcon/${language}`, formData);
    }

    saveSystem(windowSystemDefinition: WindowSystemDefinition, windowSystemWebShopInfo: WindowSystemWebShopInfo,
               webshopImage: File, largeWebshopImage: File, largeWebshopImageMobile: File, glamourPrintImage: FileState,
               windowEditorImage: File): Observable<number> {
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([JSON.stringify(windowSystemDefinition)], {
            type: 'application/json'
        }));
        formData.append('windowSystemWebShopInfoDto', new Blob([JSON.stringify(windowSystemWebShopInfo)], {
            type: 'application/json'
        }));
        if (webshopImage != undefined) {
            formData.append('webshopImage', webshopImage);
        }
        if (largeWebshopImage != undefined) {
            formData.append('largeWebshopImage', largeWebshopImage);
        }
        if (largeWebshopImageMobile != undefined) {
            formData.append('largeWebshopImageMobile', largeWebshopImageMobile);
        }
        if (windowEditorImage != undefined) {
            formData.append('windowEditorImage', windowEditorImage);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(glamourPrintImage)) {
            formData.append('glamourPrintImage', glamourPrintImage.file);
        }

        if (windowSystemDefinition.id) {
            return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/${windowSystemDefinition.id}`, formData).pipe(
                map(() => windowSystemDefinition.id));
        } else {
            return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}`, formData, {observe: 'response'}).pipe(
                map(response => this.dataServiceHelper.getNewItemId(WindowSystemDefinitionService.API_URL, response)));
        }
    }

    getSystemsSeals(windowSystemId: number, selectedSealIds: number[] = null, readOnly = false): Observable<WindowSystemsSealsDefinitionList> {
        const params = selectedSealIds ? {selectedSealIds: selectedSealIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<WindowSystemsSealsDefinitionList>(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/seals`,
            {params: params});
    }

    getSystemsColors(windowSystemId: number, selectedColorIds: number[] = null, readOnly = false): Observable<WindowSystemsColorsDefinitionList> {
        const params = selectedColorIds ? {selectedColorIds: selectedColorIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<WindowSystemsColorsDefinitionList>(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/colors`,
            {params: params});
    }

    getSystemsOtherFillings(windowSystemId: number, selectedFillingIds: number[] = null, readOnly = false)
        : Observable<WindowSystemsOtherFillingsDefinitionList> {
        const params = selectedFillingIds ? {selectedFillingIds: selectedFillingIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<WindowSystemsOtherFillingsDefinitionList>(
            `${WindowSystemDefinitionService.API_URL}/${windowSystemId}/fillings`, {params: params});
    }

    getSystemsDecorativeFillings(windowSystemId: number, selectedFillingIds: number[] = null, readOnly = false)
        : Observable<WindowSystemsDecorativeFillingsDefinitionList> {
        const params = selectedFillingIds ? {selectedFillingIds: selectedFillingIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<WindowSystemsDecorativeFillingsDefinitionList>(
            `${WindowSystemDefinitionService.API_URL}/${windowSystemId}/decorative-fillings`, {params: params});
    }

    getBusinessTypes(windowSystemId: number): Observable<BusinessTypeList> {
        return this.http.get<BusinessTypeList>(
            `${WindowSystemDefinitionService.API_URL}/${windowSystemId}/business-types`);
    }

    getSystemsProfiles(windowSystemId: number, selectedProfileIds: number[] = null, readOnly = false): Observable<WindowSystemsFrameProfilesList> {
        const params = selectedProfileIds ? {selectedProfileIds: selectedProfileIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<WindowSystemsFrameProfilesList>(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/profiles`,
            {params: params});
    }

    getSystemsAddOns(windowSystemId: number, selectedAddonIds: number[] = null, readOnly = false): Observable<WindowSystemsAddOnsList> {
        const params = selectedAddonIds ? {selectedAddonIds: selectedAddonIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<WindowSystemsAddOnsList>(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/addons`,
            {params: params});
    }

    getEntranceModels(windowSystemId: number): Observable<EntranceModel[]> {
        return this.http.get<EntranceModel[]>(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/entranceModels`);
    }

    getSystemsGlazingBeads(windowSystemId: number, selectedBeadIds: number[] = null, readOnly = false): Observable<WindowSystemsGlazingBeadsList> {
        const params = selectedBeadIds ? {selectedBeadIds: selectedBeadIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<WindowSystemsGlazingBeadsList>(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/glazing-beads`,
            {params: params});
    }

    copy(id: number, item: WindowSystemDefinition, windowSystemWebShopInfo: WindowSystemWebShopInfo,
         webshopImage: File, largeWebshopImage: File, largeWebshopImageMobile: File, glamourPrintImage: FileState,
         windowEditorImage: File): Observable<number> {
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([JSON.stringify(item)], {
            type: 'application/json'
        }));
        formData.append('windowSystemWebShopInfoDto', new Blob([JSON.stringify(windowSystemWebShopInfo)], {
            type: 'application/json'
        }));
        if (webshopImage != undefined) {
            formData.append('webshopImage', webshopImage);
        }
        if (largeWebshopImage != undefined) {
            formData.append('largeWebshopImage', largeWebshopImage);
        }
        if (largeWebshopImageMobile != undefined) {
            formData.append('largeWebshopImageMobile', largeWebshopImageMobile);
        }
        if (windowEditorImage != undefined) {
            formData.append('windowEditorImage', windowEditorImage);
        }
        if (glamourPrintImage != undefined) {
            formData.append('glamourPrintImage', glamourPrintImage.file);
        }

        return this.http.post(`${WindowSystemDefinitionService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(WindowSystemDefinitionService.API_URL, response)));
    }

    public validateMarginExistance(systemId: number, offerId: number): Observable<ProfitMarginExistance> {
        return this.http.get<ProfitMarginExistance>(`${WindowSystemDefinitionService.API_URL}/${systemId}/profitMarginExists/${offerId}`);
    }

    validateGeneralData(windowSystemDefinition: WindowSystemDefinition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/validateGeneralData`, windowSystemDefinition));
    }

    validateAnglesData(windowSystemDefinition: WindowSystemDefinition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/validateAnglesData`, windowSystemDefinition));
    }

    validateAssemblyData(windowSystemDefinition: WindowSystemDefinition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/validateAssemblyData`, windowSystemDefinition));
    }

    validateShapesData(windowSystemDefinition: WindowSystemDefinition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/validateShapesData`, windowSystemDefinition));
    }

    validateGlamourPrintData(windowSystemDefinition: WindowSystemDefinition): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/validateGlamourPrintData`, windowSystemDefinition));
    }

    saveRoofSystem(windowSystemDefinition: WindowSystemDefinition, windowEditorImage: File, printoutImage: File,
                   glamourPrintImage: FileState): Observable<number> {
        let windowSystemDefinitionBody = JSON.stringify(windowSystemDefinition);
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([windowSystemDefinitionBody], {
            type: 'application/json'
        }));

        if (windowEditorImage != undefined) {
            formData.append('windowEditorImage', windowEditorImage);
        }
        if (printoutImage != undefined) {
            formData.append('printoutImage', printoutImage);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(glamourPrintImage)) {
            formData.append('glamourPrintImage', glamourPrintImage.file);
        }

        if (windowSystemDefinition.id) {
            return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/roofSystem/${windowSystemDefinition.id}`, formData).pipe(
                map(() => windowSystemDefinition.id));
        } else {
            return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/roofSystem`, formData, {observe: 'response'}).pipe(
                map(response => this.dataServiceHelper.getNewItemId(WindowSystemDefinitionService.API_URL + '/roofSystem', response)));
        }
    }

    getRoofSystem(windowSystemId: number): Observable<WindowSystemDefinition> {
        return this.http.get<WindowSystemDefinition>(`${WindowSystemDefinitionService.API_URL}/roofSystem/${windowSystemId}`);
    }

    copyRoofSystem(id: number, item: WindowSystemDefinition, windowEditorImage: File, printoutImage: File,
                   glamourPrintImage: FileState): Observable<number> {
        let windowSystemDefinitionBody = JSON.stringify(item);
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([windowSystemDefinitionBody], {
            type: 'application/json'
        }));

        if (windowEditorImage != undefined) {
            formData.append('windowEditorImage', windowEditorImage);
        }
        if (printoutImage != undefined) {
            formData.append('printoutImage', printoutImage);
        }
        if (glamourPrintImage != undefined) {
            formData.append('glamourPrintImage', glamourPrintImage.file);
        }

        return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/roofSystem/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(`${WindowSystemDefinitionService.API_URL}/roofSystem`, response)));
    }

    validateRoofSystem(item: WindowSystemDefinition, windowEditorImage: File, printoutImage: File,
                       glamourPrintImage: FileState): Observable<ValidationErrors> {
        let windowSystemDefinitionBody = JSON.stringify(item);
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([windowSystemDefinitionBody], {
            type: 'application/json'
        }));

        if (windowEditorImage != undefined) {
            formData.append('windowEditorImage', windowEditorImage);
        }
        if (printoutImage != undefined) {
            formData.append('printoutImage', printoutImage);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(glamourPrintImage)) {
            formData.append('glamourPrintImage', glamourPrintImage.file);
        }

        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/validateRoofSystem`, formData));
    }

    getWindowEditorImage(systemId: number): Observable<string> {
        return this.http.get(`${WindowSystemDefinitionService.API_URL}/${systemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getWindowEditorImageAsFile(systemId: number): Observable<File> {
        return this.http.get(`${WindowSystemDefinitionService.API_URL}/${systemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getPrintoutImage(systemId: number): Observable<string> {
        return this.http.get(`${WindowSystemDefinitionService.API_URL}/${systemId}/printoutImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getPrintoutImageAsFile(systemId: number): Observable<File> {
        return this.http.get(`${WindowSystemDefinitionService.API_URL}/${systemId}/printoutImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getGlamourPrintImageAsFile(windowSystemId: number): Observable<File> {
        return this.http.get(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/glamourPrintImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getAvailableProducts(): Observable<ProductTypeGroup[]> {
        return this.http.get<string[]>('systems/availableProducts')
            .pipe(map(response => response.map(typeName => ProductTypeGroup[typeName])));
    }

    allNotRoofSystemNames(): Observable<WindowSystemName[]> {
        return this.http.get<WindowSystemName[]>('systems/allNotRoofSystemNames');
    }

    getActiveSystemsMatchingType(windowSystemId: number): Observable<WindowSystemDefinitionList> {
        return this.http.get<WindowSystemDefinitionList>(`systems/activeSystemsByType/${windowSystemId}`);
    }

    getSystemsForCatalogLinking(): Observable<ItemForCatalogLinking[]> {
        return this.http.get<ItemForCatalogLinking[]>('systems/forCatalogLinking');
    }

    getSystemsForWindowEditor(enabledInWebshop: boolean, onlyWithBusinessType: boolean,
                              systemType: WindowSystemTypeData[]): Observable<WindowEditorWindowSystemInterface[]> {
        let params = new HttpParams();
        if (enabledInWebshop != undefined) {
            params = params.set('enabledInWebshop', `${enabledInWebshop}`);
        }
        if (onlyWithBusinessType != undefined) {
            params = params.set('onlyWithBusinessType', `${onlyWithBusinessType}`);
        }
        if (systemType != undefined) {
            params = params.set('systemTypes', `${systemType.map(t => t.type)}`);
        }
        return this.http.get<WindowEditorWindowSystemInterface[]>('systems/forWindowEditor', {params: params});
    }

    getSystemsForVeneer(windowSystemId: number, systemTypes: WindowSystemTypeData[]): Observable<WindowEditorWindowSystemInterface[]> {
        let params = new HttpParams();
        params = params.set('systemTypes', systemTypes != undefined ? `${systemTypes.map(t => t.type)}` : `${[]}`);
        return this.http.get<WindowEditorWindowSystemInterface[]>(
            `${WindowSystemDefinitionService.API_URL}/${windowSystemId}/veneerSystems`, {params: params});
    }

    getEntranceSystems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                       sortColumn: string, sortOrder: number): Observable<WindowSystemDefinitionList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<WindowSystemDefinitionList>('systems/entranceSystems', {params: params});
    }

    getEntranceSystem(entranceSystemId: number): Observable<WindowSystemDefinition> {
        return this.http.get<WindowSystemDefinition>(`${WindowSystemDefinitionService.API_URL}/entranceSystem/${entranceSystemId}`);
    }

    validateEntranceSystem(item: WindowSystemDefinition): Observable<ValidationErrors> {
        let windowSystemDefinitionBody = JSON.stringify(item);
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([windowSystemDefinitionBody], {
            type: 'application/json'
        }));

        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/validateEntranceSystem`, formData));
    }

    saveEntranceSystem(windowSystemDefinition: WindowSystemDefinition): Observable<number> {
        let windowSystemDefinitionBody = JSON.stringify(windowSystemDefinition);
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([windowSystemDefinitionBody], {
            type: 'application/json'
        }));

        if (windowSystemDefinition.id) {
            return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/entranceSystem/${windowSystemDefinition.id}`, formData).pipe(
                map(() => windowSystemDefinition.id));
        } else {
            return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/entranceSystem`, formData, {observe: 'response'}).pipe(
                map(response => this.dataServiceHelper.getNewItemId(WindowSystemDefinitionService.API_URL + '/entranceSystem', response)));
        }
    }

    copyEntranceSystem(id: number, item: WindowSystemDefinition): Observable<number> {
        let windowSystemDefinitionBody = JSON.stringify(item);
        let formData = new FormData();
        formData.append('windowSystemDto', new Blob([windowSystemDefinitionBody], {
            type: 'application/json'
        }));

        return this.http.post<void>(`${WindowSystemDefinitionService.API_URL}/entranceSystem/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId(`${WindowSystemDefinitionService.API_URL}/entranceSystem`, response)));
    }

    getItemNames(active = true, systemTypes?: WindowSystemTypeEnum[]): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        if (systemTypes != undefined) {
            params['systemTypes'] = systemTypes.join(',');
        }
        return this.http.get<CatalogItemName[]>('systems/names', {params: params});
    }

    getDownloadPriceTableFromExternalApiUrl(windowSystemId: number): Observable<string> {
        return this.http.get(`${WindowSystemDefinitionService.API_URL}/${windowSystemId}/supplierExternalPriceTableDownloadApi`,
            {responseType: 'text'});
    }

    getWindowSystemToUseForStandaloneGlazingPackage(windowSystemId: number): Observable<number> {
        return this.http.get<number>(`${WindowSystemDefinitionService.API_URL}/getWindowSystemToUseForStandaloneGlazingPackage`,
            {params: {windowSystemId: `${windowSystemId}`}});
    }

    checkConfigAddonAvailability(selectedSystemIds: number[]): Observable<boolean> {
        const params = selectedSystemIds ? {systemIds: selectedSystemIds.toString()} : {};
        return this.http.get<boolean>(`${WindowSystemDefinitionService.API_URL}/configAddonsAvailability`, {params: params});
    }
}
