<p-dialog [resizable]="false" [modal]="true" (onHide)="close()" [visible]="visible" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.OFFER_CHARGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <app-input-text inputId="name" label="{{ 'OFFER.POSITIONS.DIALOGS.OFFER_CHARGE.NAME' | translate }}"
                            [disabled]="true"
                            [ngModel]="dialogData.offerPosition.name[translate.currentLang]"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="netValue" label="{{ 'OFFER.POSITIONS.DIALOGS.OFFER_CHARGE.NET_VALUE' | translate }}"
                            [disabled]="true"
                            [ngModel]="sellValue.netValue | price:currency"></app-input-text>

        </div>
        <div class="new-form-row">
            <app-input-text inputId="grossValue" label="{{ 'OFFER.POSITIONS.DIALOGS.OFFER_CHARGE.GROSS_VALUE' | translate }}"
                            [disabled]="true"
                            [ngModel]="sellValue.grossValue | price:currency"></app-input-text>

        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
