import {WindowShape} from "./WindowShape";
import {WindowShapeType} from "./WindowShapeType";

export class ArchEllipticalWindowShape extends WindowShape {
    arcHeight: number;

    constructor(arcHeight: number) {
        super(WindowShapeType.ARCH_ELLIPTICAL);
        this.arcHeight = arcHeight;
    }
}
