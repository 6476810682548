<p-dialog #dialog [resizable]="false" [modal]="true" [styleClass]="getDialogStyleClass()" (onHide)="close()"
          [visible]="visible" (visibleChange)="handleVisibleChange($event)" [focusOnShow]="false">
    <p-header>
        <div class="wizard-dialog-header p-dialog-title">
            {{ header }}
            <ng-container *ngTemplateOutlet="afterHeaderTemplate"></ng-container>
        </div>
        <ng-container *ngIf="steps.length > 1">
            <span *ngFor="let step of steps; let i = index" [id]="buildTabId(step)"
                  [ngClass]="{'wizard-dialog-tab': true, 'wizard-dialog-tab-active': step.active, 'wizard-dialog-tab-invalid': !stepsValid[i]}"
                  (click)="setStep(i)" (mousedown)="preventDragging($event)">{{ step.label }}</span>
        </ng-container>
    </p-header>
    <div [ngClass]="contentStyleClass" class="wizard-dialog-tab-content">
        <ng-content></ng-content>
    </div>
    <p-footer>
        <ng-template #defaultFooter>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="wizard-dialog-save" label="{{'GENERAL.SAVE' | translate }}"
                                   type="main-action" [size]="40" (onClick)="submit()"></app-simple-button>
                <app-simple-button buttonId="wizard-dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}"
                                   type="cancel" [size]="40" (onClick)="close()"></app-simple-button>
            </div>
        </ng-template>
        <ng-container *ngTemplateOutlet="footerTemplate"></ng-container>
    </p-footer>
</p-dialog>
