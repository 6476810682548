<!-- OVERWRITE DEFAULTS DIALOG -->
<p-dialog id="window-system-defaults-overwrite-dialog" [(visible)]="visible" [resizable]="false"
          [modal]="true" header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}" (onShow)="handleShow()"
          (onHide)="hide()" [focusOnShow]="false">

    <div class="new-form-400 scrolled-dialog">
        <div class="new-form-row">
            <p>{{ title | translate }}</p>
        </div>
        <div class="new-form-row" *ngFor="let selection of selections">
            <app-checkbox [label]="selection.name" inputId="{{'subsystem_checkbox_' + selection.id}}"
                          [(ngModel)]="selection.selected"></app-checkbox>
        </div>
        <div class="new-form-row">
            <p>{{ body | translate }}</p>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="window-system-defaults-replace-confirm-button"
                               label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               [disabled]="!canConfirm()" (onClick)="confirm()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="hide()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
