import * as _ from 'underscore';
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {AreaSpecification} from "../../../../../window-designer/drawing-data/AreaSpecification";
import {GrillSegment} from "../../../../../window-designer/drawing-data/GrillSegment";
import {LineGrillSegment} from "../../../../../window-designer/drawing-data/LineGrillSegment";
import {GrillHelper} from "../../../../../window-designer/utils/grill-helper";
import {WindowCalculator} from "../../../../../window-designer/window-calculator";
import {ProfilesCompositionDistances} from "../../../../../window-designer/profiles-composition-distances";
import {DrawingUtil, MinMaxXY} from "../../../../../window-designer/drawing-util";
import {PositionsHelper} from "../../../../../window-designer/utils/positions-helper";
import {LineGrill} from "../../../../../window-designer/drawing-data/LineGrill";
import {Grill} from "../../../../../window-designer/drawing-data/Grill";

export class ExtendGrillsTool {

    public static use(data: DrawingData, totalBoundingBox: MinMaxXY,
                      profileCompositionDistances: ProfilesCompositionDistances) {

        let horizontalGrillSegmentsPerArea = new Map<AreaSpecification, LineGrillSegment[]>();
        let horizontalGrillSegmentPositionsPerArea = new Map<AreaSpecification, number[]>();
        let parentGrillsPerPosition = new Map<number, Grill>();
        let glazingBeadsPerArea = new Map<AreaSpecification, number[]>();
        let glazingBeadBoundsPerArea = new Map<AreaSpecification, MinMaxXY>();
        let allAreas = [];
        let yPositions = [];
        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            subwindow.areasSpecification.forEach(area => {
                let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPoints(subwindow, data.cuts,
                    totalBoundingBox, profileCompositionDistances, false);
                let glazingBead = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions,
                    totalGlazingBead, profileCompositionDistances);
                glazingBeadsPerArea.set(area, glazingBead);
                glazingBeadBoundsPerArea.set(area, DrawingUtil.calculateMinMaxFromPolygon(glazingBead, false));
                let horizontalGrillSegments = _.flatten(area.grills.map(g => g.drawingSegments.filter(GrillSegment.isLine)))
                    .filter(s => GrillHelper.isSegmentHorizontal(s));
                horizontalGrillSegmentsPerArea.set(area, horizontalGrillSegments);
                let horizontalGrillSegmentPositions = horizontalGrillSegments.map(s => s.points[1]);
                horizontalGrillSegmentPositionsPerArea.set(area, horizontalGrillSegmentPositions);
                horizontalGrillSegments.forEach(s => {
                    // not specified which grill to copy when multiple present on same position
                    parentGrillsPerPosition.set(s.points[1], GrillHelper.findGrillBySegmentId(area.grills, s.id));
                });
                yPositions.push(...horizontalGrillSegmentPositions);
                allAreas.push(area);
            });
        }));

        for (let yPos of _.unique(yPositions)) {
            allAreas.filter(area => ExtendGrillsTool.yPosBetweenBounds(yPos, glazingBeadBoundsPerArea.get(area)) &&
                !_.includes(horizontalGrillSegmentPositionsPerArea.get(area), yPos)).forEach(area => {
                    let yLine = [0, yPos, 1, yPos];
                    let intersections = DrawingUtil.polygonLineIntersections(glazingBeadsPerArea.get(area), yLine, true);
                    if (intersections.length === 2 && intersections.every(i => i.intersects)) {
                        let grillBeingExtended = parentGrillsPerPosition.get(yPos);
                        let grillToAdd = new LineGrill();
                        grillToAdd.id = grillBeingExtended.id;
                        grillToAdd.colorId = grillBeingExtended.colorId;
                        grillToAdd.width = grillBeingExtended.width;
                        grillToAdd.canBeAngled = grillBeingExtended.canBeAngled;
                        grillToAdd.positions.push(...intersections.map(
                            i => PositionsHelper.prepareGlazingBeadReference(glazingBeadsPerArea.get(area),
                                [i.x, i.y])));
                        area.grills.push(grillToAdd);
                    } else {
                        throw new Error('ExtendGrillsTool - Wrong intersection count.');
                    }
            });
        }

        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, data.cuts,
                totalBoundingBox, profileCompositionDistances, true);
            PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads, profileCompositionDistances);
        }));
    }

    private static yPosBetweenBounds(yPos: number, bounds: MinMaxXY): boolean {
        return bounds.minY < yPos && yPos < bounds.maxY;
    }
}
