<div class="new-form-600">

    <div class="create-subsystem create-subsystem-section-header">
        <span>{{ 'SUBSYSTEM-DETAILS.FORM.CORRESPONDENCE_ADDRESS' | translate }}</span>
    </div>

    <div class="new-form-row">
        <app-input-text inputId="correspondenceAddress.street" label="{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}"
                        [maxlength]="100" [disabled]="true" [required]="true"
                        [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"
                        [ngModel]="correspondenceAddress?.address.street"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="correspondenceAddress.city" label="{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}"
                        [maxlength]="50" [disabled]="true" [required]="true"
                        [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"
                        [ngModel]="correspondenceAddress?.address.city"></app-input-text>
    </div>

    <div class="new-form-row">
        <app-select inputId="correspondenceAddress.country" label="{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}"
                    [disabled]="true" [required]="true" [options]="countries | async"
                    [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"
                    [ngModel]="correspondenceAddress?.address.country"></app-select>
    </div>

    <div class="new-form-row">
        <app-input-zip #correspondenceAddressZip inputId="correspondenceAddress.zip"
                       label="{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}" [disabled]="true" [required]="true"
                       [validationMessageKey]="validationErrors['correspondenceAddress']"
                       [country]="correspondenceAddress?.address.country"
                       [ngModel]="correspondenceAddress?.address.zip"></app-input-zip>
    </div>

    <div class="new-form-row blue-text-button" *ngIf="!disabledFields">
        <app-simple-button buttonId="subsystem-address-select-correspondence"
                           label="{{ 'SUBSYSTEM-DETAILS.FORM.SELECT_ADDRESS' | translate }}"
                           (onClick)="addressSelectionDialogType = 'correspondence'"></app-simple-button>
    </div>

    <div class="create-subsystem create-subsystem-header-spacer"></div>

    <div class="create-subsystem create-subsystem-section-header">
        <span>{{ 'SUBSYSTEM-DETAILS.FORM.DELIVERY_ADDRESS' | translate }}</span>
    </div>

    <div class="new-form-row blue-text-button">
        <app-simple-button buttonId="copyCorrespondenceAddressButton"
                           label="{{ 'SUBSYSTEM-DETAILS.FORM.COPY_CORRESPONDENCE_ADDRESS' | translate }}" type="action"
                           [size]="32" (onClick)="copyCorrespondenceAddressToDelivery()"></app-simple-button>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="deliveryAddress.street" label="{{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}"
                        [maxlength]="100" [disabled]="true"
                        [hasValidationError]="validationErrors['deliveryAddress'] != undefined"
                        [ngModel]="deliveryAddress?.address.street"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="deliveryAddress.city" label="{{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}"
                        [maxlength]="50" [disabled]="true"
                        [hasValidationError]="validationErrors['deliveryAddress'] != undefined"
                        [ngModel]="deliveryAddress?.address.city"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="deliveryAddress.country" label="{{ 'SUBSYSTEM-DETAILS.FORM.COUNTRY' | translate }}"
                    [disabled]="true" [options]="countries | async" [allowSelectingNone]="true"
                    [hasValidationError]="validationErrors['deliveryAddress'] != undefined"
                    [ngModel]="deliveryAddress?.address.country"></app-select>
    </div>
    <div class="new-form-row">
        <app-input-zip inputId="deliveryAddress.zip" label="{{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}"
                       [disabled]="true" [country]="deliveryAddress?.address.country"
                       [hasValidationError]="validationErrors['deliveryAddress'] != undefined"
                       [ngModel]="deliveryAddress?.address.zip"></app-input-zip>
    </div>
    <div class="new-form-row blue-text-button" *ngIf="!disabledFields">
        <app-simple-button buttonId="subsystem-address-select-delivery"
                           label="{{ 'SUBSYSTEM-DETAILS.FORM.SELECT_ADDRESS' | translate }}"
                           (onClick)="addressSelectionDialogType = 'delivery'"></app-simple-button>
    </div>
</div>
<app-subsystem-address-selection-dialog *ngIf="addressSelectionDialogType != 'none'" [addresses]="addresses" [visible]="true"
                                        (visibleChange)="handleAddressSelectionVisibilityChange($event)"
                                        [selectedAddress]="addressSelectionDialogType == 'correspondence' ? correspondenceAddress : deliveryAddress"
                                        (selectedAddressChange)="handleAddressSelected($event)" [canEdit]="false"
                                        [selectable]="true"></app-subsystem-address-selection-dialog>
