import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Color} from '../../../../window-system/color/color';

@Component({
    selector: 'app-ral-color-selection-dialog',
    templateUrl: './ral-color-selection-dialog.component.html',
    styleUrls: ['./ral-color-selection-dialog.component.css']
})
export class RalColorSelectionDialogComponent {

    @Input()
    header: string;

    @Input()
    colors: Color[];

    @Output()
    onColorSelect = new EventEmitter<Color>();

    @Output()
    onCancel = new EventEmitter<void>();

    selectedColor: Color;

    constructor(public translate: TranslateService) {
    }

    isColorSelected(color: Color): boolean {
        return color === this.selectedColor;
    }

    selectColor(color: Color): void {
        this.selectedColor = color;
    }

    getColorStyle(color: Color): any {
        return {
            'background-color': color.ralHex,
            'color': this.getBestContrastLabelColor(color.ralHex)
        };
    }

    private getBestContrastLabelColor(color: string): string {
        const r = parseInt(color.substring(1, 3), 16);
        const g = parseInt(color.substring(3, 5), 16);
        const b = parseInt(color.substring(5, 7), 16);
        const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
        return (yiq >= 128) ? 'black' : 'white';
    }

    formatter = (color: Color): SelectItem => {
        return {
            label: color.names[this.translate.currentLang],
            value: color
        };
    }
}
