import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ValidationErrors} from '../../../../common/validation-errors';
import {CatalogItemTag} from '../catalog-item-tag';

@Component({
    selector: 'app-catalog-item-tags-form',
    templateUrl: './catalog-item-tags-form.component.html'
})
export class CatalogItemTagsFormComponent {

    @Input()
    item: CatalogItemTag;

    @Input()
    iconFile: File;

    @Output()
    readonly iconFileChange = new EventEmitter<File>();

    @Input()
    validationErrors: ValidationErrors;

    handleIconFileChange(file: File): void {
        this.iconFileChange.emit(file);
    }
}
