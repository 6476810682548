import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {GateWallField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GateWallFieldUsage} from "../../catalog-field-usage";
import {GateWall} from "../gate-wall";

@Component({
    selector: 'app-gate-wall-form',
    templateUrl: './gate-wall-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWallFormComponent {
    @Input() item: GateWall;
    @Input() validationErrors: any;

    @Input()
    lowResolutionPreviewImage: File;

    @Output()
    readonly lowResolutionPreviewImageChange = new EventEmitter<File>();

    @Input()
    highResolutionPreviewImage: File;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Output()
    readonly highResolutionPreviewImageChange = new EventEmitter<File>();

    fieldUsage: GateWallFieldUsage;
    GateWallField = GateWallField;

    constructor() {
        this.fieldUsage = new GateWallFieldUsage(this);
    }
}
