import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {Complaint} from '../../../../complaint/complaint';

@Component({
    selector: 'app-create-complaint-confirmation-dialog',
    templateUrl: './create-complaint-confirmation-dialog.component.html',
    styleUrls: ['../../../../../shared-styles.css', '../../../../complaint/complaint-list/complaint-list.component.css',
        './create-complaint-confirmation-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateComplaintConfirmationDialogComponent {

    @Input() itemsToDisplay: Complaint[];
    @Output() onConfirm: EventEmitter<any> = new EventEmitter();
    @Output() navigateToComplaint: EventEmitter<number> = new EventEmitter<number>();
    @Output() onCancel: EventEmitter<any> = new EventEmitter();

    private readonly dialogHideHelper = new OnceFlag();

    goToCreator() {
        this.dialogHideHelper.call(() => this.onConfirm.emit());
    }

    goToComplaint(complaintId) {
        this.dialogHideHelper.call(() => this.navigateToComplaint.emit(complaintId));
    }

    cancel() {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }
}
