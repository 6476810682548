<div id="designer-container">
    <app-drawing-tool-controls #drawingToolsControl
                               [offer]="offer"
                               [readOnlyMode]="false"
                               [mode]="DrawingToolControlsMode.CONJUNCTION"
                               [history]="history"
                               [canSaveExit]="canSaveExit"
                               [canDelete]="canUseDeleteTool"
                               [canChangeCoupler]="canUseChangeCouplerTool"
                               (onChangeCoupler)="openChangeCouplerDialog()"
                               (onDelete)="deleteSelected()"
                               (onCancelMode)="cancelMode()"
                               (onRedoLast)="redoLast()"
                               (onUndoLast)="undoLast()"
                               (onExit)="exit()"
                               (onSaveAndExit)="save()">
    </app-drawing-tool-controls>

    <app-exit-without-saving-confirmation-dialog [visible]="showExitWithoutSavingConfirmationDialog"
                                                 (hideDialog)="showExitWithoutSavingConfirmationDialog = false"
                                                 (exitWithoutSaving)="exitWithoutSaving()"></app-exit-without-saving-confirmation-dialog>

    <div class="preview-container">
        <div class="painter">
            <div id="svg-container" app-designer-length-unit-hider>
                <svg id="drawing-svg" (window:resize)="OnWindowResize($event)" class="svg" width="100%"
                     height="100%"></svg>
            </div>
            <span class="view-label">{{ 'CONJUNCTION.VIEW_FROM_INSIDE_LABEL' | translate }}</span>
        </div>
    </div>

    <div *ngIf="data" id="details-panel" [ngClass]="{'hide-sidebar': !showSidebar }">
        <div id="details" [hidden]="!showSidebar" [ngStyle]="{'min-width': 'max-content'}">

            <div class="details-panel-contents">
                <div class="new-form-field">
                    <label>{{ 'OFFER.POSITIONS.CONJUNCTION.SUPPLIER' | translate }}</label>
                    {{ supplierName }}
                </div>

                <div class="new-form-field">
                    <label>{{ 'OFFER.POSITIONS.CONJUNCTION.POSITIONS' | translate }}</label>
                    {{ includedPositionNumbers }}
                </div>

                <div class="new-form-row">
                    <app-select inputId="connector"
                                label="{{ 'OFFER.POSITIONS.CONJUNCTION.CONNECTOR' | translate }}"
                                [disabled]="canUseChangeCouplerTool"
                                [options]="availableConnectors" [optionFormatter]="connectorOptionFormatter"
                                [ngModel]="connectorToAddId" [allowSelectingNone]="true"
                                placeholder="{{ 'OFFER.POSITIONS.CONJUNCTION.CUSTOM_CONNECTOR' | translate }}"
                                noneOptionPosition="last" (ngModelChange)="onConnectorChange($event)"></app-select>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="connectorWidth"
                                      label="{{ 'OFFER.POSITIONS.CONJUNCTION.CONNECTOR_WIDTH' | translate }}"
                                      [disabled]="connectorToAddId != null || canUseChangeCouplerTool" [ngModel]="connectorToAddWidth"
                                      (ngModelChange)="onConnectorWidthChange($event)"
                                      [min]="1" [allowOnlyIntegers]="true"></app-input-number>
                </div>
                <div class="new-form-row">
                    <p-accordion>
                        <p-accordionTab header="{{ 'OFFER.POSITIONS.CONJUNCTION.USED_CONNECTORS' | translate }}"
                                        [selected]="false">
                            <p *ngFor="let coupler of includedCouplers" class="new-form-field">
                                <b>{{ coupler.label }}</b> - {{ coupler.width }}mm - {{ coupler.name }}
                            </p>
                        </p-accordionTab>
                    </p-accordion>
                </div>

                <div class="new-form-row">
                    <p-accordion>
                        <p-accordionTab
                                *ngFor="let supplier of availableSuppliers; let first = first; trackBy: trackById;"
                                header="{{ supplier.name | multilanguageTranslate }}" [selected]="first">
                            <div *ngFor="let position of availablePositionsBySupplier.get(supplier.id)">
                                <div class="new-form-row"
                                     [ngClass]="{'selected-position': position === positionToBeAdded}"
                                     (click)="onPositionClick(position)">
                                    <div class="new-form-field">
                                        <label>{{position.position.printOrder}}
                                            : {{position.dimensions | convertMillimetersToInches | async }}
                                            {{ (position.position.name) | multilanguageTranslate }} </label>
                                        <div class="available-position-thumbnail"><img [src]="position.image"></div>
                                    </div>
                                </div>
                            </div>
                        </p-accordionTab>
                    </p-accordion>
                </div>
            </div>

        </div>
        <div class="spacer" *ngIf="!showSidebar"></div>
        <app-sidebar-hider [showSidebar]="showSidebar"
                           (onClick)="toggleSidebar()"></app-sidebar-hider>
    </div>

    <p-dialog *ngIf="showChangeCouplerDialog" [visible]="true" [resizable]="false"
              [modal]="true" (onHide)="showChangeCouplerDialog = false" [focusOnShow]="false">

        <p-header>
            <div class="p-dialog-title">
                {{'OFFER.POSITIONS.CONJUNCTION.CONNECTOR_DIALOG_HEADER' | translate}}
            </div>
        </p-header>

        <div class="scrolled-dialog-600">
            <div class="new-form-400">
                <div class="new-form-row">
                    <app-select inputId="changeConnector"
                                label="{{ 'OFFER.POSITIONS.CONJUNCTION.CONNECTOR' | translate }}"
                                [options]="availableConnectors" [optionFormatter]="connectorOptionFormatter"
                                [ngModel]="selectedConnectorChange.id" [allowSelectingNone]="true"
                                (ngModelChange)="onConnectorChange($event, true)"
                                placeholder="{{ 'OFFER.POSITIONS.CONJUNCTION.CUSTOM_CONNECTOR' | translate }}"
                                noneOptionPosition="last"></app-select>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="changeConnectorWidth"
                                      label="{{ 'OFFER.POSITIONS.CONJUNCTION.CONNECTOR_WIDTH' | translate }}"
                                      [disabled]="selectedConnectorChange.id != null" [ngModel]="selectedConnectorChange.width"
                                      (ngModelChange)="onConnectorWidthChange($event, true)"
                                      [min]="1" [allowOnlyIntegers]="true"></app-input-number>
                </div>
            </div>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action"
                                   [size]="40" (onClick)="changeSelectedConnector()"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="showChangeCouplerDialog = false"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>

