import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {ConjunctionsDialogData} from "../position-list-dialogs";
import {AccessData} from "../../../../../AccessData";
import {ActivatedRoute, Router} from "@angular/router";
import {ConjunctionService} from "./conjunction-service";
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {TristateCheckboxState} from "../../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {ConjunctionDialogDto} from "./conjunction-dialog-dto";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-conjunctions',
    templateUrl: './conjunctions.component.html',
    styleUrls: ['./conjunctions.component.css', '../../../../../shared-styles.css'],
    providers: [ConjunctionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConjunctionsComponent implements OnInit {

    @Input()
    dialogData: ConjunctionsDialogData;

    @Output()
    onClose = new EventEmitter();

    @Output()
    onPrint = new EventEmitter<number[]>();

    validationErrors = {};
    toBeDeletedId: number;

    private conjunctionService: ConjunctionService;
    private errors: CommonErrorHandler;
    readonly route: ActivatedRoute;
    private router: Router;
    private translate: TranslateService;

    private readonly dialogHideHelper = new OnceFlag();

    offerId: number;

    allSelectedState = TristateCheckboxState.UNCHECKED;
    selected: number[] = [];
    userLang: string;

    constructor(injector: Injector,
                private changeDetector: ChangeDetectorRef) {
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.conjunctionService = injector.get(ConjunctionService);
        this.errors = injector.get(CommonErrorHandler);
        this.translate = injector.get(TranslateService);
        this.userLang = this.translate.currentLang;
    }

    ngOnInit(): void {
        this.offerId = this.route.snapshot.params['offerId'];
    }

    public close(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    public createNew(): void {
        this.router.navigate(['new', AccessData.conjunctionDesignerURLSuffix], {relativeTo: this.route});
    }

    public edit(id: number): void {
        this.router.navigate([id, AccessData.conjunctionDesignerURLSuffix], {relativeTo: this.route});
    }

    public print(): void {
        this.onPrint.emit(this.selected);
    }

    public initDelete(id: number): void {
        this.toBeDeletedId = id;
    }

    public delete(id: number): void {
        this.conjunctionService.delete(this.offerId, id).subscribe({
            next: () => {
                this.dialogData.conjunctions = this.dialogData.conjunctions.filter(c => c.id !== id);
                this.toBeDeletedId = undefined;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    isSelected(item: ConjunctionDialogDto) {
        return this.selected.includes(item.id);
    }

    handleSelection(item: ConjunctionDialogDto) {
        const index = this.selected.findIndex(id => item.id === id);
        if (index === -1) {
            this.selected.push(item.id);
        } else {
            this.selected.splice(index, 1);
        }
        this.refreshAllSelectedFlag();
        this.changeDetector.markForCheck();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selected.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selected.length === this.dialogData.conjunctions.filter(c => !c.containsSignificantChanges).length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    selectAllValidChange(): void {
        this.selected = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selected.push(...this.dialogData.conjunctions.filter(c => !c.containsSignificantChanges).map(item => item.id));
        }
        this.changeDetector.markForCheck();
    }
}
