import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {ConfigDesignerCatalogDependentOption} from './data-form/config-designer-catalog-dependent-option';

@JsonDeserializable
export class ConfigDesignerCatalogDependentOptionsSet implements CrudItem {
    id: number;
    configSystemId: number;
    configSystemName: MultilanguageFieldInterface;
    sortIndex = 1;
    name: string;
    active = true;
    options: ConfigDesignerCatalogDependentOption[];

    static fromJSON(jsonObject: object): ConfigDesignerCatalogDependentOptionsSet {
        const optionsSet = new ConfigDesignerCatalogDependentOptionsSet();
        optionsSet.id = jsonObject['id'];
        optionsSet.configSystemId = jsonObject['configSystemId'];
        if (jsonObject['configSystemName'] != undefined) {
            optionsSet.configSystemName = MultilanguageField.fromJSON(jsonObject['configSystemName']);
        }
        optionsSet.sortIndex = jsonObject['sortIndex'];
        optionsSet.name = jsonObject['name'];
        optionsSet.active = jsonObject['active'];
        optionsSet.options = [];
        return optionsSet;
    }
}
