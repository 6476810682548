<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.OFFER_SUPPLIER_COUNTER.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                               icon="import_export" type="action"
                               (onClick)="exportCSV('ADMIN_PANEL.OFFER_SUPPLIER_COUNTER.TITLE')"></app-simple-button>
        </div>
    </div>

    <p-dataTable [value]="offerCounts"
                 [paginator]="false"
                 [lazy]="true"
                 selectionMode="single"
                 [responsive]="true"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate">

        <!-- SUPPLIER -->
        <p-column field="groupName"
                  header="{{ 'ADMIN_PANEL.OFFER_SUPPLIER_COUNTER.FORM.SUPPLIER' | translate }}"
                  [sortable]="true"
                  [filter]="false">

            <ng-template let-offerCount="rowData" pTemplate="body">
                {{ offerCount.groupName }}
            </ng-template>
        </p-column>

        <!-- YEARS -->
        <p-column *ngFor="let year of years"
                  [field]="year"
                  [header]="year"
                  [sortable]="true"
                  [filter]="false">

            <ng-template let-offerCount="rowData" pTemplate="body">
                {{ offerCount.countByYear[year] ? offerCount.countByYear[year] : 0 }}
            </ng-template>
        </p-column>
    </p-dataTable>
</div>
