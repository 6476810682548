import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {CatalogElement} from '../../../admin-panel/edit-catalog-permits/catalog-element.enum';
import {EditCatalogPermitsService} from '../../../admin-panel/edit-catalog-permits/edit-catalog-permits.service';
import {GlazingPackageFieldUsage} from '../../catalog-field-usage';
import {CatalogItemTagsService} from '../../catalog-item-tags/catalog-item-tags.service';
import {DistanceFrameService} from '../../distance-frame/distance-frame.service';
import {GlassService} from '../../glass/glass.service';
import {GraspGlazingPackageService} from '../../grasp-glazing-package/grasp-glazing-package.service';
import {WindowSystemDefinitionService} from '../../window-system-definition/window-system-definition.service';
import {AbstractGlazingPackageComponent} from '../abstract-glazing-package.component';
import {WebshopGlazingPackage} from './webshop-glazing-package';
import {WebshopGlazingPackageService} from './webshop-glazing-package.service';

@Component({
    selector: 'app-webshop-glazing-package',
    templateUrl: '../glazing-package.component.html',
    providers: [WebshopGlazingPackageService, DataServiceHelper, TranslatedSelectItemService, GlassService, DistanceFrameService,
        CatalogItemTagsService, WindowSystemDefinitionService, GraspGlazingPackageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopGlazingPackageComponent extends AbstractGlazingPackageComponent<WebshopGlazingPackage, WebshopGlazingPackageService>
    implements OnInit {

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService,
                private readonly graspGlazingPackageService: GraspGlazingPackageService) {
        super(injector, changeDetector, WebshopGlazingPackageService, 'WEBSHOP_GLAZING_PACKAGE', 'WebshopGlazingPackage');
        this.fieldUsage = new GlazingPackageFieldUsage(this);
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterHasGlazing = this.translatedSelectItemService.buildUnsortedDropdown(['YES', 'NO'], 'GENERAL.', '');
        this.initDefaultSortOrder();
    }

    ngOnInit() {
        super.ngOnInit();
        forkJoin({
            editPermits: this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.TERRACE_GLAZING_PACKAGES),
            graspGlazingPackages: this.graspGlazingPackageService.getItems(undefined, undefined, {active: {value: 'true'}}, 'sortIndex', 0)
        }).subscribe(data => {
            this.editPermits = data.editPermits.fieldsLimitations;
            this.graspGlazingPackages = data.graspGlazingPackages.data;
        });
    }

    getNewItem(): WebshopGlazingPackage {
        return new WebshopGlazingPackage(this.target);
    }

    submit() {
        if (!this.validationErrorsPresent()) {
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file);
            } else {
                observable = this.itemService.saveItem(this.item, this.file);
            }
            observable.pipe(
                mergeMap(glazingId => {
                    return forkJoin({
                        links: this.itemService.setGlazingWindowSystemLinks(glazingId, this.item.linkedSystemIds),
                        glazingId: of(glazingId)
                    }).pipe(
                        mergeMap(data => of(data.glazingId))
                    );
                })
            ).subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    getGlazingPackage(glazingId: number) {
        forkJoin({
            item: this.itemService.getItem(glazingId),
            file: this.target ? of(undefined) : this.itemService.getImage(glazingId)
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.file = data.file;
                if (!this.copyMode) {
                    // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                    this.focusOnElementWithId(this.getFirstInputId());
                } else {
                    this.item.id = undefined;
                }
                if (this.item.graspGlazingPackageId1 != undefined) {
                    this.item.hasGlazing1 = false;
                }
                if (this.item.graspGlazingPackageId2 != undefined) {
                    this.item.hasGlazing2 = false;
                }
                if (this.item.graspGlazingPackageId3 != undefined) {
                    this.item.hasGlazing3 = false;
                }
                if (this.item.graspGlazingPackageId4 != undefined) {
                    this.item.hasGlazing4 = false;
                }
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getItemWithImage` completed!');
            }
        });
    }
}
