import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {PaginatorRowsPerPageOptions} from "../../../../common/crud-common/paginatorRowsPerPageOptions";
import {TristateCheckboxState} from "../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {PaletteAdditionalElement} from '../palette-additional-element';

export class PaletteAdditionalElementsTableData {
    paletteAdditionalElements: PaletteAdditionalElement[] = [];
    selected: PaletteAdditionalElement = undefined;
    lastLoadEvent: LazyLoadEvent;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    rowsPerPage = PaginatorRowsPerPageOptions.defaultValue;
    selectedItems: PaletteAdditionalElement[] = [];
    allSelectedState = TristateCheckboxState.UNCHECKED;
    showFilters = false;

    constructor(paletteAdditionalElements: PaletteAdditionalElement[]) {
        this.paletteAdditionalElements = paletteAdditionalElements;
    }
}
