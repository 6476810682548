<div class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_BUSINESS_TYPE.LIST' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="webshopBusinessTypes" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedWebshopBusinessType"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="type" header="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.WINDOW_TYPE_CODE' | translate }}" [sortable]="false"
                  [filter]="showFilters" filterMatchMode="equals" [filterValues]="filterTypes | async">
            <ng-template pTemplate="body" let-item="rowData">
                {{ nameParser(item.type)}}
            </ng-template>
        </p-column>
        <p-column field="names" header="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.names[userLang] }}
            </ng-template>
        </p-column>
        <p-column field="sortIndex" header="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.SORT_INDEX' | translate }}" [sortable]="true"></p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>


<app-wizard-dialog *ngIf="displayDialog"
                   header="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.EDIT_TITLE' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()"
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step [id]="STEPS.DATA" [validate]="VALIDATORS.DATA">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-input-text inputId="type" label="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.WINDOW_TYPE_CODE' | translate }}"
                                disabled="true" [ngModel]="nameParser(selectedWebshopBusinessType.type)">
                </app-input-text>
            </div>

            <div class="new-form-row">
                     <app-input-text inputId="windowSystemName" disabled="true"
                                     label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.NAME' | translate }}"
                                     [ngModel]="selectedWebshopBusinessType.names[userLang]"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-number inputId="sortIndex"
                                  label="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.SORT_INDEX' | translate }}"
                                  [allowOnlyIntegers]="true"
                                  [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [step]="1"
                                  [(ngModel)]="selectedWebshopBusinessType.sortIndex" [required]="true"></app-input-number>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="{{'ownBusinessTypeNameWindow'}}" [maxlength]="200"
                                label="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.OWN_WINDOW_SYSTEM_NAME.WINDOW' | translate }}"
                                [(ngModel)]="selectedWebshopBusinessType.ownBusinessTypeNameWindow"
                ></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="{{'ownBusinessTypeNameDoor'}}" [maxlength]="200"
                                label="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.OWN_WINDOW_SYSTEM_NAME.DOOR' | translate }}"
                                [(ngModel)]="selectedWebshopBusinessType.ownBusinessTypeNameDoor"
                ></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="{{'ownBusinessTypeNameTerrace'}}" [maxlength]="200"
                                label="{{ 'WEBSHOP_BUSINESS_TYPE.FORM.OWN_WINDOW_SYSTEM_NAME.TERRACE' | translate }}"
                                [(ngModel)]="selectedWebshopBusinessType.ownBusinessTypeNameTerrace"
                ></app-input-text>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
