import {HttpErrorResponse} from '@angular/common/http';
import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {finalize} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {UserService} from '../../user/user.service';

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./../login.component.css'],
    providers: [UserService]
})
export class ResetPasswordFormComponent implements OnInit, OnDestroy {

    @Input()
    resetPasswordToken: string;

    @Output()
    readonly returnClick = new EventEmitter<void>();

    @Output()
    readonly passwordSuccessfullyChanged = new EventEmitter<string>();

    @Output()
    readonly errorMessageChange = new EventEmitter<string>();

    password: string;
    confirmPassword: string;

    passwordChangeInProgress = false;

    private readonly enterHotkey: Hotkey;

    constructor(private readonly userService: UserService,
                private readonly errors: CommonErrorHandler,
                private readonly hotkeysService: HotkeysService) {
        this.enterHotkey = new Hotkey('enter', (): boolean => {
            this.handleChangePasswordClick();
            return false;
        }, ['INPUT']);
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.enterHotkey);
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.enterHotkey);
    }

    handleChangePasswordClick() {
        if (this.passwordChangeInProgress) {
            return;
        }
        if (!this.password || !this.confirmPassword) {
            this.errorMessageChange.emit('error.passwordDto.password.not_empty');
            return;
        }
        if (this.password !== this.confirmPassword) {
            this.errorMessageChange.emit('USER-DETAILS.FORM.PASSWORD_DONT_MATCH');
            return;
        }
        this.passwordChangeInProgress = true;
        this.userService.resetPassword(this.resetPasswordToken, this.password).pipe(
            finalize(() => this.passwordChangeInProgress = false)
        ).subscribe({
            next: () => {
                this.passwordSuccessfullyChanged.emit(this.password);
            },
            error: error => {
                if (error instanceof HttpErrorResponse && error.headers.get('AuthError') === 'AuthenticationTokenExpiredException') {
                    this.errorMessageChange.emit('LOGIN.FORM.PASSWORD_RESET_LINK_EXPIRED');
                    return;
                }
                const validationErrors = this.errors.handle(error);
                this.errorMessageChange.emit(validationErrors['password']);
            }
        });
    }
}
