import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class SkipInterceptorsInterceptor implements HttpInterceptor {

    static readonly HEADER = 'X-Skip-Interceptor';

    static isSkipped(request: HttpRequest<unknown>, interceptorName: string): boolean {
        const headers = request.headers.getAll(SkipInterceptorsInterceptor.HEADER);
        return headers != undefined && headers.indexOf(interceptorName) >= 0;
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request.clone({
            headers: request.headers.delete(SkipInterceptorsInterceptor.HEADER)
        }));
    }
}
