export class VisibilitySettings {
    coordinatesValues: boolean;
    dimensionValues: boolean;
    totalDimensionLines: boolean;
    frameDimensionLines: boolean;
    mullionPositions: boolean;
    muntinPositions: boolean;
    toolDimensionLines: boolean;
    pointerPosition: boolean;
    angleValues: boolean;
    configAddonIcons: boolean;
    quarterNames: boolean;
    areaNumbers: boolean;
    leadingSubwindowIndicator: boolean;

    constructor() {
        this.coordinatesValues = true;
        this.dimensionValues = true;
        this.totalDimensionLines = true;
        this.frameDimensionLines = true;
        this.mullionPositions = true;
        this.muntinPositions = true;
        this.toolDimensionLines = true;
        this.pointerPosition = true;
        this.angleValues = true;
        this.configAddonIcons = true;
        this.quarterNames = true;
        this.areaNumbers = true;
        this.leadingSubwindowIndicator = true;
    }
}
