<div class="new-form-very-wide">

    <p-pickList id="chargesList" [source]="availableCharges" [target]="selectedCharges"
                sourceHeader="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.CHARGES.AVAILABLE' | translate }}"
                targetHeader="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.CHARGES.SELECTED' | translate }}" [responsive]="true"
                filterBy="filter"
                [sourceStyle]="{'height': '100%'}" [targetStyle]="{'height': '100%'}"
                [showSourceControls]="false" [showTargetControls]="false"
                (onMoveToSource)="handleMoveToSource($event.items)"
                (onMoveAllToSource)="handleMoveToSource($event.items)"
                (onMoveToTarget)="handleMoveToTarget($event.items)"
                (onMoveAllToTarget)="handleMoveToTarget($event.items)">
        <ng-template let-charge pTemplate="item">
            <div [ngClass]="{'warn-in-source': charge.warnInSource, 'warn-in-target': charge.warnInTarget}">
                <div class="new-form-row">{{ charge.name }}</div>
                <div class="new-form-row"><span class="picklist-column-system">{{ charge.windowSystemName }}</span>
                </div>
            </div>
        </ng-template>
    </p-pickList>

    <div *ngIf="validationErrors['webshopChargeIds'] != undefined" class="new-form-row">
        <div class="new-form-field new-form-field-error new-form-field-error-message">
            {{ validationErrors['webshopChargeIds'] | translate }}
        </div>
    </div>

</div>
