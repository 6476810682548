import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import * as _ from 'underscore';
import {
    ConfigurableAddonDefinitionType,
    configurableAddonDefinitionTypeToTranslationKey
} from '../../../../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {Permissions} from "../../../../../../auth/permission.service";
import {ExchangeService} from "../../../../../../common/exchange.service";
import {Currencies} from '../../../../../../currencies';
import {PricingMessageComponent} from "../../../../../errors/pricing-message/pricing-message.component";
import {PriceWithTax} from '../../../../offer';
import {MessageSeverity, PositionMessage} from "../../../../offers/message";
import {WindowEditorOfferData} from '../../../window-editor-offer-interfaces';
import {Pricing} from '../Pricing';
import {PricingWithModel} from '../PricingWithModel';
import {ConfigAddonPricingResult} from './ConfigAddonPricingResult';

@Component({
    selector: 'app-config-addon-pricing',
    templateUrl: './config-addon-pricing.component.html',
    styleUrls: ['../pricing.component.css', '../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigAddonPricingComponent implements OnChanges, OnDestroy {

    @Input()
    offer: WindowEditorOfferData;

    @Input()
    configAddonPricingsAndModels: PricingWithModel[] = [];

    @Input()
    validationMode = true;

    pricingsPerConfigAddon: ConfigAddonPricingResult[];
    configAddonSumPricing: Pricing;

    private readonly onLangChangeSubscription: Subscription;

    constructor(public translate: TranslateService,
                public permissions: Permissions,
                private changeDetector: ChangeDetectorRef) {
        this.onLangChangeSubscription = this.translate.onLangChange.subscribe(() => this.changeDetector.markForCheck());
    }

    ngOnChanges(): void {
        if (this.validationMode) {
            this.pricingsPerConfigAddon = this.prepareConfigAddonsValidationMap(this.configAddonPricingsAndModels);
        } else {
            this.pricingsPerConfigAddon = this.prepareConfigAddonsPricingMap(this.configAddonPricingsAndModels);
            this.configAddonSumPricing = this.prepareConfigAddonSum(this.configAddonPricingsAndModels);
        }
    }

    ngOnDestroy(): void {
        this.onLangChangeSubscription.unsubscribe();
    }

    private prepareConfigAddonsPricingMap(configAddonPricingsAndModels: PricingWithModel[]) {
        return configAddonPricingsAndModels.map(
            e => new ConfigAddonPricingResult(e.configurableAddonPositionModel.configurableAddon, e.pricingResult));
    }

    private prepareConfigAddonsValidationMap(configAddonPricingsAndModels: PricingWithModel[]) {
        return this.prepareConfigAddonsPricingMap(configAddonPricingsAndModels).filter(this.containsMessages);
    }

    private containsMessages(pricingResult: ConfigAddonPricingResult) {
        const {errorMessages, infoMessages, warningMessages} = pricingResult;
        return errorMessages.errorCodes.length || errorMessages.failedFields.length || infoMessages.length ||
            warningMessages.length;
    }

    getAddonName(configurableAddonDefinitionType: ConfigurableAddonDefinitionType) {
        return configurableAddonDefinitionTypeToTranslationKey(configurableAddonDefinitionType);
    }

    getAddonName2(configurableAddonDefinitionType: any) {
        return configurableAddonDefinitionType.pricing && configurableAddonDefinitionType.pricing.products &&
        configurableAddonDefinitionType.pricing.products[0].positionName;
    }

    private prepareConfigAddonSum(configAddonPricingsAndModels: PricingWithModel[]): Pricing {
        if (configAddonPricingsAndModels.some(e => e.pricingResult.containsError)) {
            return null;
        }
        let newPricing = new Pricing();
        if (configAddonPricingsAndModels.length > 0) {
            let venskaBuyPrice = configAddonPricingsAndModels[0].pricingResult.pricing.venskaBuyPrice;
            let vat = venskaBuyPrice != null ? venskaBuyPrice.vatPercent : 0;
            newPricing.venskaBuyPrice = configAddonPricingsAndModels.map(e => e.pricingResult.pricing.venskaBuyPrice)
                .reduce((sum, current) => current != undefined && sum != undefined ? PriceWithTax.add(sum, current) : undefined,
                    new PriceWithTax(0, vat));
        }
        if (this.permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
            newPricing.retailSellPrice =
                new PriceWithTax(+configAddonPricingsAndModels.map(e => e.pricingResult.pricing.retailSellPrice.netValue)
                    .reduce(((sum, current) => sum + current), 0).toFixed(2), 0);
        } else {
            newPricing.buyPrice =
                new PriceWithTax(+configAddonPricingsAndModels.map(e => e.pricingResult.pricing.buyPrice.netValue)
                    .reduce(((sum, current) => sum + current), 0).toFixed(2), 0);
        }
        newPricing.sellPrice =
            new PriceWithTax(+configAddonPricingsAndModels.map(e => e.pricingResult.pricing.sellPrice.netValue)
                .reduce(((sum, current) => sum + current), 0).toFixed(2), 0);
        return newPricing;
    }

    formatPriceInOfferCurrency(prices: Pricing): string {
        if (prices.venskaBuyPrice != undefined) {
            // venska always PLN
            return ExchangeService.formatPriceInCurrency(prices.venskaBuyPrice.netValue.toFixed(2)
                + ' / ' + prices.buyPrice.netValue.toFixed(2)
                + ' / ' + prices.sellPrice.netValue.toFixed(2),
                'PLN');
        }
        if (prices.buyPrice == undefined) {
            // SPRZEDAWCA
            return ExchangeService.formatPriceInCurrency(this.getValueInOfferCurrency(prices.sellPrice.netValue) + ' / '
                + this.getValueInOfferCurrency(prices.retailSellPrice.netValue), this.offer.currency);
        }
        return ExchangeService.formatPriceInCurrency(this.getValueInOfferCurrency(prices.buyPrice.netValue) + ' / '
            + this.getValueInOfferCurrency(prices.sellPrice.netValue), this.offer.currency);
    }

    private getValueInOfferCurrency(value: number): string {
        if (this.offer.currency !== Currencies[Currencies.PLN]) {
            return (value / this.offer.exchangeRate).toFixed(2);
        }
        return value.toFixed(2);
    }

    getUniqueMessagesForPricingResult(pricingResult: ConfigAddonPricingResult): PositionMessage[] {
        const errorMessages: string[] = [];
        if (pricingResult.errorMessages.errorCodes) {
            errorMessages.push(...pricingResult.errorMessages.errorCodes);
        }
        if (pricingResult.errorMessages.failedFields) {
            for (let failedField of pricingResult.errorMessages.failedFields) {
                errorMessages.push(...failedField.message);
            }
        }
        const warningMessages: string[] = [];
        if (pricingResult.warningMessages) {
            warningMessages.push(...pricingResult.warningMessages
                .map(message => message.messageCode));
        }
        return [
            ..._.uniq(errorMessages).map(code => this.toMessage(code, MessageSeverity.ERROR)),
            ..._.uniq(warningMessages).map(code => this.toMessage(code, MessageSeverity.WARNING))
        ];
    }

    private toMessage(errorCode: string, severity: MessageSeverity): PositionMessage {
        let message = new PositionMessage();
        message.messageCode = errorCode;
        message.severity = severity;
        return message;
    }

    getClassName(message: PositionMessage): string {
        return PricingMessageComponent.getClassName(message.severity);
    }
}
