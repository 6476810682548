import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {ApplicationPrivilege} from './application-privilege';
import {Permissions} from "./permission.service";

@Injectable()
export class PermissionsGuard  {

    constructor(private permissions: Permissions) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let componentData = route.data;
        if (componentData.hasOwnProperty('roles') && !this.permissions.isPermitted(componentData as { roles: ApplicationPrivilege[] })) {
            this.permissions.redirectToNoPermissionsErrorPage(false);
            return false;
        }
        return true;
    }
}
