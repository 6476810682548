import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {SupportedLanguages} from '../../../supportedLanguages';
import {Template, TemplateType} from './template';
import {TemplatesService} from './templates.service';

@Component({
    selector: 'app-templates-settings',
    templateUrl: './templates-settings.component.html',
    providers: [TemplatesService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplatesSettingsComponent extends CrudCommonComponent<Template, TemplatesService> implements OnInit {

    templateTypeFilter: Observable<SelectItem[]>;
    templateTypeOptions: Observable<SelectItem[]>;
    @ViewChild(DataTable) dataTable: DataTable;
    validateDataStep: WizardStepValidator;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, TemplatesService, 'TEMPLATE', 'Template');
        this.item = new Template();
        this.validateDataStep = () => this.validateForm();
    }

    ngOnInit(): void {
        this.templateTypeFilter = this.translatedSelectItemService.buildSortedDropdown(TemplateType,
            "SETTINGS.SECTION.TEMPLATES.FORM.TYPES.", '');
        this.templateTypeOptions = this.templateTypeFilter.pipe(map(types => types.slice(1)));
    }

    getNewItem(): Template {
        return new Template();
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    onRowSelect(event): void {
        this.validationErrors = {};
        this.item = new Template();
        this.itemService.getItem(event.data.id).subscribe({
            next: data => {
                this.item = data;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.setDisplayDialog(true);
            },
            error: error => {
                console.error(error);
                if (error.status === 500) {
                    this.setErrors(error);
                } else {
                    this.growlMessageController.error(error);
                }
            },
            complete: () => {
                console.debug('getTemplate` completed!');
            }
        });
        this.keepSelectedItemIndex(event);
    }

    cancel(): void {
        this.newItem = false;
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedItem);
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        if (this.newItem) {
            this.itemService.addItem(this.item).subscribe(this.genericCleanupAndReloadSuccessObserver());
        } else {
            this.itemService.editItem(this.item.id, this.item).subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    showSuccessMessage(): void {
        this.growlMessageController.info('SETTINGS.SECTION.TEMPLATES.TEMPLATE_SAVED');
    }

    validateForm(): Observable<boolean> {
        let errors = {};
        for (let lang of SupportedLanguages.languages) {
            if (!this.item.content[lang.code]) {
                errors['content_' + lang.code] = 'error.templateDto.content.not_empty';
            }
        }
        if (!this.item.templateType) {
            errors['templateType'] = 'error.templateDto.templateType.not_empty';
        }
        if (this.validationErrorsPresent(errors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, errors);
            this.changeDetector.markForCheck();
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    trimContent(data: string) {
        if (data && data.length > 100) {
            return data.substring(0, 98) + "...";
        }
        return data;
    }
}
