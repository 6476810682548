import {WebshopGlazingPackage} from '../../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package';

export class UpsellingPickListGlazing {

    id: number;
    name: string;
    windowSystemIds: number[];
    sortIndex: number;
    warnInSource: boolean;
    warnInTarget: boolean;

    constructor(glazingPackage: WebshopGlazingPackage, lang: string) {
        this.id = glazingPackage.id;
        this.name = glazingPackage.names[lang];
        this.windowSystemIds = glazingPackage.linkedSystemIds;
        this.sortIndex = glazingPackage.sortIndex;
    }
}
