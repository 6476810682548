import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, forkJoin, of} from 'rxjs';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {AddonAttribute} from '../../../../../../../window-designer/entities/AddonAttribute';
import {ConfigurableAddon} from '../../../../../../../window-designer/entities/ConfigurableAddon';
import {CechaGrupa} from '../../../../../../../window-designer/enums/CechaGrupa';
import {CechaType} from '../../../../../../../window-designer/enums/CechaType';
import {ConfigAddonApplication} from '../../../../../../../window-designer/enums/ConfigAddonApplication';
import {configurableAddonDefinitionTypeToTranslationKey} from '../../../../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../../../../common/validation-errors';
import {PricingFailedField} from '../../../../../errors/errorResponse';
import {Color} from '../../../../../window-system/color/color';
import {ColorService} from '../../../../../window-system/color/color.service';
import {Material} from '../../../../../window-system/material/material';
import {MaterialService} from '../../../../../window-system/material/material.service';
import {WindowComponentPreviewData} from '../../../../window-editor/window-component-preview-dialog/window-component-preview-dialog.component';
import {ConfigurableAddonsService} from '../../config-addons.service';
import {Cecha} from '../ConfigurableAddonModel/Cecha';
import {ConfigurableAddonDefinition} from '../ConfigurableAddonModel/ConfigurableAddonDefinition';
import {DefinitionParser} from '../ConfigurableAddonModel/DefinitionParser';
import {Opcja} from '../ConfigurableAddonModel/Opcja';
import {ParsedDefinition} from '../ConfigurableAddonModel/ParsedDefinition';
import {ConfigAddonDefaultAttributeValue} from './config-addon-default-attribute-value';
import {OpenImageInNewBrowserTabController} from "../../../../../../common/open-image-in-new-browser-tab-controller.service";
import {QuantityType} from "../../../../../../../window-designer/enums/QuantityType";

@Component({
    selector: 'app-configurable-addon',
    templateUrl: './configurableAddon.component.html',
    styleUrls: ['../../../../../shared-styles.css', './configurableAddon.component.css'],
    providers: [MaterialService, ColorService, DataServiceHelper, ConfigurableAddonsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurableAddonComponent implements OnChanges {

    public static readonly MATERIAL_CECHA_SYMBOL = 'addon_material';
    public static readonly MATERIAL_COLOR_CECHA_SYMBOL = 'addon_material_color';
    private static readonly LOAD_DATA_ID = 'ConfigurableAddonComponent loadData';

    @Input() definition: ConfigurableAddonDefinition;
    @Input() image: string;
    @Input() initialValues: ConfigurableAddon;
    @Input() defaultAttributeValues: ConfigAddonDefaultAttributeValue[];
    @Input() errors: ValidationErrors;
    @Input() pricingErrors: PricingFailedField[] = [];
    @Input() showDimensionSection = true;
    @Input() application: ConfigAddonApplication;
    @Input() readOnlyMode: boolean;

    @Output() rebuildFormComplete = new EventEmitter<any>();

    src: SafeResourceUrl;
    parsed: ParsedDefinition;
    userLang: string;
    groups: CechaGrupa[];
    Object = Object;
    item: ConfigurableAddon;
    cechyDependentOnMasterCecha: Map<Cecha, Cecha[]>;
    independentCechy: Cecha[] = [];
    colorPreviewData: WindowComponentPreviewData;

    materials: Material[] = [];
    colors: Color[] = [];

    cechyToShow = [];
    showBulkDimensions = true;

    public componentReady = new BehaviorSubject<boolean>(false);

    constructor(private translate: TranslateService,
                private materialService: MaterialService,
                private colorService: ColorService,
                private changeDetector: ChangeDetectorRef,
                private sanitizer: DomSanitizer,
                private configurableAddonService: ConfigurableAddonsService,
                private errorHandler: CommonErrorHandler,
                public openImageInNewBrowserTabController: OpenImageInNewBrowserTabController,
                private blockUiController: BlockUiController) {

        this.userLang = translate.currentLang;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.readOnlyMode && 'initialValues' in changes && changes['initialValues'].currentValue != undefined && changes['initialValues'].previousValue == undefined) {
            this.blockUiController.block(ConfigurableAddonComponent.LOAD_DATA_ID);
            let materialAttr = this.initialValues.attributes
                .find(attr => attr.symbol === ConfigurableAddonComponent.MATERIAL_CECHA_SYMBOL);
            let materialColorAttr = this.initialValues.attributes
                .find(attr => attr.symbol === ConfigurableAddonComponent.MATERIAL_COLOR_CECHA_SYMBOL);
            let materialObservable = materialAttr == null ? of({data: []}) : this.materialService.getItems(0, null, {symbol: {value: materialAttr.value}}, null, null);
            let materialColorObservable = materialColorAttr == null ? of({data: []}) : this.colorService.getItems(0, null, {symbol: {value: materialColorAttr.value}}, null, null);
            forkJoin({
                materials: materialObservable,
                colors: materialColorObservable
            }).pipe(finalize(() => this.blockUiController.unblock(ConfigurableAddonComponent.LOAD_DATA_ID))
            ).subscribe({
                next: data => {
                    this.materials = data.materials.data;
                    this.colors = data.colors.data;
                },
                error: error => {
                    this.errorHandler.handle(error);
                },
                complete: () => {
                    this.componentReady.next(true);
                    console.info('configurableAddonComponent set materials and colors. DONE');
                }
            });
        }
    }

    rebuildForm(globalEditMode = false): void {
        const isNew = this.initialValues == undefined;
        let addAutoOptions = !globalEditMode
            && (this.application == undefined || this.application !== ConfigAddonApplication.INDEPENDENT)
            && isNew;
        this.parsed = DefinitionParser.parseDefinition(this.definition, addAutoOptions, globalEditMode);
        this.resetForm();
        this.groups = _.uniq(this.parsed.cechy.map(cecha => cecha.grupa));

        this.parsed.cechy.forEach(cecha => {
            const translationKey = 'ConfigAddonDefinitions.' + this.definition.type + '.' + cecha.symbol + '.';
            cecha.nameCode = translationKey + 'fieldName';
            if (cecha.opcje != undefined) {
                const doNotChangeOption = cecha.opcje.find(opcja => opcja.symbol === Opcja.DO_NOT_CHANGE_OPTION_SYMBOL);
                if (doNotChangeOption != undefined) {
                    doNotChangeOption.nameCode = 'OFFER.POSITIONS.DROP_DOWN_OPTIONS.DO_NOT_CHANGE';
                }

                if (cecha.symbol !== ConfigurableAddonComponent.MATERIAL_CECHA_SYMBOL
                    && cecha.symbol !== ConfigurableAddonComponent.MATERIAL_COLOR_CECHA_SYMBOL) {
                    cecha.opcje.forEach(opcja => {
                        if (opcja.symbol === Opcja.DO_NOT_CHANGE_OPTION_SYMBOL) {
                            return;
                        }
                        opcja.nameCode = translationKey + 'options.' + opcja.symbol;
                    });
                }
            }

            if (cecha.symbol === ConfigurableAddonComponent.MATERIAL_CECHA_SYMBOL) {
                const materialOrder = this.materials.reduce((ordered, material, materialIndex) => {
                    ordered[material.symbol] = materialIndex;
                    return ordered;
                }, {});
                materialOrder[Opcja.DO_NOT_CHANGE_OPTION_SYMBOL] = -1;
                cecha.opcje = cecha.opcje.filter(opcja => materialOrder[opcja.symbol] != undefined)
                    .sort((a, b) => materialOrder[a.symbol] - materialOrder[b.symbol]);

                cecha.opcje.forEach(opcja => {
                    if (opcja.symbol === Opcja.DO_NOT_CHANGE_OPTION_SYMBOL) {
                        return;
                    }
                    opcja.nazwa = this.materials.find(m => m.symbol === opcja.symbol)!.name;
                });
            } else if (cecha.symbol === ConfigurableAddonComponent.MATERIAL_COLOR_CECHA_SYMBOL) {
                const colorOrder = this.colors.reduce((ordered, color) => {
                    ordered[color.symbol] = color.sortIndex;
                    return ordered;
                }, {});
                colorOrder[Opcja.DO_NOT_CHANGE_OPTION_SYMBOL] = -1;
                cecha.opcje = cecha.opcje.filter(opcja => colorOrder[opcja.symbol] != undefined)
                    .sort((a, b) => colorOrder[a.symbol] - colorOrder[b.symbol]);

                cecha.opcje!.forEach(opcja => {
                    if (opcja.symbol === Opcja.DO_NOT_CHANGE_OPTION_SYMBOL) {
                        return;
                    }
                    opcja.nazwa = this.colors.find(c => c.symbol === opcja.symbol)!.names;
                });
            }

            if (this.initialValues) {
                this.setItemFromInitialValues();
            } else {
                this.setNewItem(cecha);
            }
        });
        this.independentCechy = this.getIndependentCechy();
        this.independentCechy.forEach(cecha => this.setCechyDependentOnCurrentCecha(cecha));

        if (this.showDimensionSection && this.image) {
            this.src = this.sanitizer.bypassSecurityTrustUrl(this.image);
        }

        this.collectCechyToShow();
        setTimeout(() => {
            this.rebuildFormComplete.emit();
            this.changeDetector.markForCheck();
        }, 50);
    }

    private collectCechyToShow() {
        this.cechyToShow = this.parsed.cechy
            .filter(cecha => this.isToShow(cecha))
            .map(cecha => cecha.hasOwnProperty('dependsOn') ? this.getEvaluated(cecha) : cecha);
    }

    getEvaluated(cecha: Cecha): Cecha {
        const masterCecha = this.independentCechy.filter(independentCecha =>
            independentCecha.symbol === cecha.visibilityDependsOn || independentCecha.symbol === cecha.dependsOn);
        if (masterCecha.length === 0) {
            return undefined;
        }
        const dependant = this.cechyDependentOnMasterCecha.get(masterCecha[0]);
        return dependant && dependant.find(dependantCecha => cecha.symbol === dependantCecha.symbol);
    }

    isToShow(cecha: Cecha): boolean {
        return !cecha.hidden && (this.isCechaDependent(cecha) ? !!this.getEvaluated(cecha) : this.isMasterCechaToShow(cecha));
    }

    isMasterCechaToShow(cecha: Cecha): boolean {
        if (cecha.isVisibleByApplication == undefined) {
            return true;
        }
        let visible = cecha.isVisibleByApplication(this.item.application);
        let itemAttr = this.item.attributes.find(attr => attr.symbol === cecha.symbol);
        if (itemAttr != null) {
            itemAttr.visible = visible;
        }
        return visible;
    }

    changeItemValue(event, cecha: Cecha) {
        let attr = this.item.attributes.find(a => a.symbol === cecha.symbol);
        if (attr == null) {
            attr = this.setCechaInItem(cecha);
        }
        attr.value = event;
        this.changeDetector.markForCheck();
    }

    private resetForm(): void {
        this.item = new ConfigurableAddon();
        this.item.application = this.application;
        this.groups = [];
        this.cechyDependentOnMasterCecha = new Map<Cecha, Cecha[]>();
        this.independentCechy = [];
    }

    private setItemFromInitialValues(): void {
        this.item = this.initialValues;
        this.item.configurableAddonDefinitionType = this.definition.type;

        if (this.showDimensionSection) {
            let dimFilled = this.item.attributes
                .filter(attr => attr.group === CechaGrupa.DIMENSIONS)
                .some(attr => attr.value != null && attr.value !== "");
            this.showBulkDimensions = !dimFilled;
        }
    }

    showGroup(group: CechaGrupa): boolean {
        let isDimensionGroup = this.isGroup(group, 'DIMENSIONS') || this.isGroup(group, 'BULK_DIMENSIONS');
        let hide = (isDimensionGroup && !this.showDimensionSection) ||
            (this.isGroup(group, 'DIMENSIONS') && this.showBulkDimensions) ||
            (this.isGroup(group, 'BULK_DIMENSIONS') && !this.showBulkDimensions);
        return !hide;
    }

    private setNewItem(cecha: Cecha): void {
        this.item.definitionId = this.definition.id;
        this.item.configurableAddonDefinitionType = this.definition.type;
        let defaultValue = undefined;
        if (this.defaultAttributeValues != undefined) {
            defaultValue = this.defaultAttributeValues.find(defaultAttributeValue => defaultAttributeValue.symbol === cecha.symbol);
            if (defaultValue && defaultValue.value === Opcja.AUTO_OPTION_SYMBOL) {
                defaultValue = undefined;
            }
        }
        if (defaultValue == undefined && cecha.defaultValue != undefined) {
            defaultValue = {value: cecha.defaultValue};
        }

        if (defaultValue != undefined) {
            this.setCechaInItem(cecha, defaultValue.value);
        } else if (cecha.type === CechaType.OPTIONS) {
            let defaultOpcjaId = cecha.opcje.findIndex(opcja => opcja.domyslna);
            defaultOpcjaId = defaultOpcjaId < 0 ? 0 : defaultOpcjaId;
            const defaultOpcja = cecha.opcje[defaultOpcjaId];
            this.setCechaInItem(cecha, defaultOpcja.symbol);
        } else {
            this.setCechaInItem(cecha);
        }
    }

    setCechaInItem(cecha: Cecha, defaultOption?: string): AddonAttribute {
        let attr: AddonAttribute = new AddonAttribute();
        attr.symbol = cecha.symbol;
        attr.type = cecha.type;
        attr.quantityType = QuantityType[cecha.jednostkaMiary];
        attr.value = defaultOption ? defaultOption : '';
        attr.group = cecha.grupa;
        attr.visible = true;
        attr.hasAutoOption = cecha.hasAutoOption;
        this.item.attributes.push(attr);
        return attr;
    }

    getIndependentCechy(): Cecha[] {
        return this.parsed.cechy.filter(cecha => !this.isCechaDependent(cecha));
    }

    isCechaDependent(cecha: Cecha): boolean {
        return cecha.hasOwnProperty('visibilityDependsOn') || cecha.hasOwnProperty('dependsOn');
    }

    setCechyDependentOnCurrentCecha(masterCecha: Cecha): void {
        const dependentCechy: Cecha[] = this.parsed.cechy.filter(cecha => {
            return (cecha.hasOwnProperty('visibilityDependsOn') && cecha.visibilityDependsOn === masterCecha.symbol)
                ||
                (cecha.hasOwnProperty('dependsOn') && cecha.dependsOn === masterCecha.symbol);
        });
        let toShow: Cecha[] = this.getDependentCechyToDisplay(dependentCechy, masterCecha);
        toShow = this.getDependentCechyWithLimitedOptions(toShow, masterCecha);
        this.cechyDependentOnMasterCecha.set(masterCecha, toShow);
        this.collectCechyToShow();
        this.changeDetector.markForCheck();
    }

    private getDependentCechyToDisplay(dependentCechy: Cecha[], masterCecha: Cecha): Cecha[] {
        return dependentCechy.filter(cecha => {
            let addonAttribute = this.item.attributes.find(attr => attr.symbol === masterCecha.symbol);
            if (addonAttribute == null) {
                return false;
            }
            let visible = true;
            if (cecha.isVisibleByMasterValue != undefined) {
                visible = cecha.isVisibleByMasterValue(addonAttribute.value);
            }
            if (cecha.hasOwnProperty('dependentValueNotIn')) {
                visible = !_.contains([Opcja.DO_NOT_CHANGE_OPTION_SYMBOL, ...cecha.dependentValueNotIn.split(',')
                    .map(str => str.trim())], addonAttribute.value);
            } else if (cecha.hasOwnProperty('dependentValueIn')) {
                visible = _.contains(cecha.dependentValueIn.split(',').map(str => str.trim()), addonAttribute.value);
            }
            let itemAttr = this.item.attributes.find(attr => attr.symbol === cecha.symbol);
            if (itemAttr != null) {
                itemAttr.visible = visible;
            }
            return visible;
        });
    }

    private getDependentCechyWithLimitedOptions(cechy: Cecha[], masterCecha: Cecha): Cecha[] {
        return cechy.map(cecha => {
            let cechaWithLimitedOptions: Cecha = Object.assign({}, cecha);
            if (cechaWithLimitedOptions.hasOwnProperty('dependsOn') && cechaWithLimitedOptions.type === CechaType.OPTIONS) {
                const masterVal = this.item.attributes.find(attr => attr.symbol === masterCecha.symbol).value;
                cechaWithLimitedOptions.opcje = cechaWithLimitedOptions.opcje.filter(opcja => {
                    if (opcja.tylkoDla) {
                        const allowedMasterValues = opcja.tylkoDla
                            .split(',')
                            .map(v => v.trim())
                            .map(v => {
                                const prefixEnd = v.lastIndexOf('.');
                                return prefixEnd >= 0 ? v.substring(prefixEnd + 1) : v;
                            });
                        return allowedMasterValues.indexOf(masterVal) >= 0;
                    }
                    if (opcja.isSelectedByMasterValue != undefined && masterVal && masterVal !== '') {
                        return opcja.isSelectedByMasterValue(masterVal);
                    }
                    return true;
                });

                if (!this.initialValues || !this.cechaWithLimitedOptionsAllowsCurrentValue(cechaWithLimitedOptions)) {
                    this.setDefaultOptionAsValue(cechaWithLimitedOptions);
                }
            }
            return cechaWithLimitedOptions;
        });
    }

    private cechaWithLimitedOptionsAllowsCurrentValue(cechaWithOptions: Cecha): boolean {
        const currentValueSymbol = this.item.attributes.find(attr => attr.symbol === cechaWithOptions.symbol).value;
        return cechaWithOptions.opcje.some(opcja => opcja.symbol === currentValueSymbol);
    }

    private setDefaultOptionAsValue(cechaWithOptions: Cecha): void {
        if (cechaWithOptions.opcje.length === 0) {
            console.error('there are no options found for attribute', cechaWithOptions);
            return;
        }
        let defaultValueIndex = -1;
        if (this.defaultAttributeValues != undefined) {
            const defaultValue = this.defaultAttributeValues
                .find(defaultAttributeValue => defaultAttributeValue.symbol === cechaWithOptions.symbol);
            if (defaultValue != undefined) {
                defaultValueIndex = cechaWithOptions.opcje.findIndex(opcja => opcja.symbol === defaultValue.value);
            }
        }
        if (defaultValueIndex === -1) {
            defaultValueIndex = cechaWithOptions.opcje.findIndex(opcja => opcja.domyslna);
        }
        defaultValueIndex = defaultValueIndex < 0 ? 0 : defaultValueIndex;
        let attr = this.item.attributes.find(a => a.symbol === cechaWithOptions.symbol);
        attr.value = cechaWithOptions.opcje[defaultValueIndex].symbol;
    }

    getPricingErrorsForField(fieldName: string): string[] {
        if (this.pricingErrors) {
            for (let field of this.pricingErrors) {
                if (field.failedField === fieldName) {
                    return field.message;
                }
            }
        }
        return null;
    }

    getAttribute(cecha: Cecha) {
        let attribute = this.item.attributes.find(attr => attr.symbol === cecha.symbol);
        return attribute && attribute.value;
    }

    isGroup(group: CechaGrupa, groupStr: string) {
        return group === CechaGrupa[groupStr];
    }

    sectionContainsErrors(group: CechaGrupa) {
        if (!this.errors && !this.pricingErrors) {
            return false;
        }
        for (let cecha of this.cechyToShow) {
            if (cecha.grupa === group) {
                if (this.errors && this.errors[cecha.symbol]) {
                    return true;
                }
                if (this.pricingErrors) {
                    for (let field of this.pricingErrors) {
                        if (field.failedField === cecha.symbol) {
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    handleFieldImageClick(cecha: Cecha): void {
        if (cecha.symbol === ConfigurableAddonComponent.MATERIAL_COLOR_CECHA_SYMBOL) {
            return this.handleShowColor(cecha);
        }
    }

    handleShowColor(cecha: Cecha): void {
        let symbol = this.item.attributes.find(attr => attr.symbol === cecha.symbol).value;
        let header = cecha.opcje.find(opcja => opcja.symbol === symbol).nazwa[this.userLang];
        this.colorPreviewData = new WindowComponentPreviewData(this.colorService.getImageForItemBySymbol(symbol), header);
        this.changeDetector.markForCheck();
    }

    displayImageIcon(cecha: Cecha): boolean {
        return cecha.symbol === ConfigurableAddonComponent.MATERIAL_COLOR_CECHA_SYMBOL;
    }

    openLargeImage() {
        let label = this.translate.instant(
            configurableAddonDefinitionTypeToTranslationKey(this.item.configurableAddonDefinitionType));
        this.openImageInNewBrowserTabController
            .openInNewBrowserTab(label, () => this.configurableAddonService.getDescriptionImageLargeForItem(this.definition.id));
    }
}
