import {JsonDeserializable} from '../../../common/crud-common/crudItem';

export enum MailBoxSslMode {
    NONE = 'NONE',
    SSL = 'SSL',
    STARTTLS = 'STARTTLS',
    AZURE = 'AZURE'
}

@JsonDeserializable
export class MailBoxConfiguration {
    host: string;
    port: number;
    username: string;
    password: string;
    sslMode: MailBoxSslMode;

    static fromJSON(jsonObject: any): MailBoxConfiguration {
        const mailBoxConfig = new MailBoxConfiguration();
        mailBoxConfig.host = jsonObject.host;
        mailBoxConfig.port = jsonObject.port;
        mailBoxConfig.username = jsonObject.username;
        mailBoxConfig.password = jsonObject.password;
        mailBoxConfig.sslMode = MailBoxSslMode[jsonObject.sslMode as string];
        return mailBoxConfig;
    }
}
