import {ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ApplicationResource, KnownApplicationResources} from './application-resource';
import {ApplicationResourceService} from './application-resource.service';

@Component({
    selector: 'app-application-resource',
    templateUrl: './application-resource.component.html',
    providers: [ApplicationResourceService, TranslatedSelectItemService]
})
export class ApplicationResourceComponent extends CrudCommonComponent<ApplicationResource, ApplicationResourceService> {

    readonly MAX_ROWS_WITHOUT_PAGINATION = 2000;
    readonly resources = Object.keys(KnownApplicationResources)
        .filter(key => typeof KnownApplicationResources[key] === 'number')
        .map(key => ({id: KnownApplicationResources[key], name: key}));
    readonly totalRecords = this.resources.length;

    @ViewChild('dt', {static: true})
    table: Table;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, ApplicationResourceService, 'APPLICATION_RESOURCE', 'ApplicationResource');
        this.hideDataLoadingIndicator();
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    getNewItem(): ApplicationResource {
        return undefined;
    }

    loadEditedItem(event: { data: ApplicationResource }): Observable<ApplicationResource> {
        return this.itemService.getItemContent(event.data.id).pipe(
            tap(file => this.file = file),
            map(() => {
                const applicationResource = new ApplicationResource();
                applicationResource.id = event.data.id;
                return applicationResource;
            }));
    }

    getEditDialogHeader(): string {
        return 'APPLICATION_RESOURCE.TYPE.' + KnownApplicationResources[this.item.id];
    }

    getResourceAspectRatio(): number | undefined {
        return KnownApplicationResources[KnownApplicationResources[this.item.id]] == KnownApplicationResources.GLAMOUR_TITLE_PAGE_DEFAULT_BACKGROUND ? 1 : undefined;
    }
}
