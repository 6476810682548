export class SizeUnitsConverter {

    private static MILLIMETER_TO_METER_DIVISOR = 1000;
    private static CENTIMETERS_TO_MILLIMETERS_MULTIPLIER = 10;

    public static mmToMeter(value: number): number {
        return value / SizeUnitsConverter.MILLIMETER_TO_METER_DIVISOR;
    }

    public static mmSidesToMeterSquared(width: number, height: number): number {
        return this.mmToMeter(width) * this.mmToMeter(height);
    }

    public static cmToMillimeter(value: number): number {
        return value * SizeUnitsConverter.CENTIMETERS_TO_MILLIMETERS_MULTIPLIER;
    }
}
