import {PainterParams} from "./painters/PainterParams";
import {WindowData} from "./drawing-data/WindowData";
import {CutData} from "./drawing-data/CutData";
import {ProfilesCompositionDistances} from "./profiles-composition-distances";
import {DrawingUtil, MinMaxXY} from "./drawing-util";
import {ScalingPainter} from "./painters/ScalingPainter";
import {WindowCalculator} from "./window-calculator";
import {SubWindowShape} from "./drawing-data/SubWindowShape";
import {DrawingData} from "./drawing-data/drawing-data";

export class ShadingCalculator {

    public static readonly WINGS_GROOVES_POINT = .85;
    public static readonly WINGS_SHADOW_RADIUS = 10;
    public static readonly HANDLES_SHADOW_RADIUS = 8;
    public static readonly SHADOW_OPACITY = .33;
    public static readonly HANDLES_SHADOW_OPACITY = .66;

    public static rgba(alpha: number, r: number, g = r, b = r): string {
        return `rgba(${r}%, ${g}%, ${b}%, ${alpha}%)`;
    }

    private static absoluteGradientString(polygon: number[], params: PainterParams, ...steps: string[]): string {
        let box = DrawingUtil.calculateMinMaxFromPolygon(ScalingPainter.scale(params, polygon), false);
        let topRight = `${box.maxX},${box.minY}`;
        let bottomLeft = `${box.minX},${box.maxY}`;
        let positionsString = `L(${topRight},${bottomLeft})`;
        return `${positionsString}${steps.join('-')}`;
    }

    // #77D5FF -> #23A5DF
    public static areaGlassesGradientString(polygon: number[], params: PainterParams): string {
        return this.absoluteGradientString(polygon, params, this.rgba(20, 46.7, 83.5, 100), this.rgba(50, 13.7, 71, 87.5));
    }

    // #cccccc -> #a6a6a6
    public static areaDecorationsGradientString(polygon: number[], params: PainterParams): string {
        return this.absoluteGradientString(polygon, params, this.rgba(66, 80, 80, 80), this.rgba(66, 65, 65, 65));
    }

    public static generate(svg: Snap.Paper, windows: WindowData[], cuts: CutData[],
                           profileCompositionDistances: ProfilesCompositionDistances,
                           totalBoundingBox: MinMaxXY, params: PainterParams, snapFilter: Snap.Filter): Shadings {
        if (!params.isShaded()) {
            return null;
        }
        let outerWingPoints = WindowCalculator.constructionWingPointsOffsetByFactor(windows, cuts, totalBoundingBox,
            profileCompositionDistances, 0);

        let shadings = new Shadings();
        shadings.light = this.shading(svg, this.absoluteGradientString(outerWingPoints, params, this.rgba(100, 100), this.rgba(0, 100)));
        shadings.dark = this.shading(svg, this.absoluteGradientString(outerWingPoints, params, this.rgba(0, 0), this.rgba(100, 0)));
        shadings.wingShadowFilter = svg.filter(this.wingsShadowFilter(params, snapFilter));
        shadings.wingBlurFilter = svg.filter(this.wingsBlurFilter(params, snapFilter));
        shadings.handlesShadowFilter = svg.filter(this.handlesShadowFilter(params, snapFilter));
        return shadings;
    }

    public static shading(svg: Snap.Paper, gradientString: string): Shading {
        return new Shading(
            svg.gradient(gradientString),
            svg.g()
        );
    }

    public static splitBentParts(outerPolygon: number[], innerPolygon: number[]): ShadingBentParts {
        let outerBox = DrawingUtil.calculatePolygonTotalBoundingBox(outerPolygon);
        let innerBox = DrawingUtil.calculatePolygonTotalBoundingBox(innerPolygon);
        let parts = new ShadingBentParts();
        parts.top = [
            outerBox.minX, outerBox.minY,
            outerBox.maxX, outerBox.minY,
            innerBox.maxX, innerBox.minY,
            innerBox.minX, innerBox.minY
        ];
        parts.right = [
            outerBox.maxX, outerBox.minY,
            outerBox.maxX, outerBox.maxY,
            innerBox.maxX, innerBox.maxY,
            innerBox.maxX, innerBox.minY,
        ];
        parts.left = [
            outerBox.minX, outerBox.minY,
            innerBox.minX, innerBox.minY,
            innerBox.minX, innerBox.maxY,
            outerBox.minX, outerBox.maxY
        ];
        parts.bottom = [
            innerBox.minX, innerBox.maxY,
            innerBox.maxX, innerBox.maxY,
            outerBox.maxX, outerBox.maxY,
            outerBox.minX, outerBox.maxY
        ];
        return parts;
    }

    private static wingsShadowFilter(params: PainterParams, snapFilter: Snap.Filter): string {
        return this.shadowFilter(params, ShadingCalculator.WINGS_SHADOW_RADIUS, ShadingCalculator.SHADOW_OPACITY, snapFilter);
    }

    private static wingsBlurFilter(params: PainterParams, snapFilter: Snap.Filter): string {
        return this.blurFilter(params, ShadingCalculator.WINGS_SHADOW_RADIUS, snapFilter);
    }

    private static handlesShadowFilter(params: PainterParams, snapFilter: Snap.Filter): string {
        return this.shadowFilter(params, ShadingCalculator.HANDLES_SHADOW_RADIUS, ShadingCalculator.HANDLES_SHADOW_OPACITY, snapFilter);
    }

    private static shadowFilter(params: PainterParams, radius: number, opacity: number, snapFilter: Snap.Filter): string {
        return snapFilter.shadow(0, 0, radius * params.scale, '#000000', opacity);
    }

    private static blurFilter(params: PainterParams, radius: number, snapFilter: Snap.Filter): string {
        return snapFilter.blur(radius * params.scale, 0);
    }

    public static isShadingSupported(data: DrawingData): boolean {
        return !data.windows.some(window => window.subWindows.some(subwindow => subwindow.shape !== SubWindowShape.RECTANGULAR));
    }
}

export class Shadings {
    light: Shading;
    dark: Shading;
    wingShadowFilter: Snap.Element;
    wingBlurFilter: Snap.Element;
    handlesShadowFilter: Snap.Element;

    private static maskAttr(alpha) {
        return {
            strokeWidth: '0',
            fill: ShadingCalculator.rgba(100, alpha),
            fillOpacity: '1'
        };
    }

    public static jointAttr(opacity = 0.2) {
        return {
            fill: 'none',
            pointerEvents: 'none',
            stroke: '#000',
            strokeWidth: '2',
            strokeOpacity: '' + opacity
        };
    }

    public add(element: Snap.Element, lightAlpha: number, darkAlpha: number): void {
        let lightClone = element.clone().attr(Shadings.maskAttr(lightAlpha));
        let darkClone = element.clone().attr(Shadings.maskAttr(darkAlpha));
        this.light.mask.add(lightClone);
        this.dark.mask.add(darkClone);
    }

    public addJoints(svg: Snap.Paper, outerPolygon: number[], innerPolygon: number[], params: PainterParams,
                     opacity?: number): Snap.Element[] {
        let elements = [];
        for (let i = 0; i < outerPolygon.length; i += 2) {
            let line = [outerPolygon[i], outerPolygon[i + 1], innerPolygon[i], innerPolygon[i + 1]];
            elements.push(ScalingPainter.line(svg, line, Shadings.jointAttr(opacity), params));
        }
        return elements;
    }

    public addWingGrooves(svg: Snap.Paper, polygon: number[], params: PainterParams): Snap.Element {
        return this.addGrooves(svg, polygon, params);
    }

    public addGrooves(svg: Snap.Paper, polygon: number[], params: PainterParams, opacity?: number): Snap.Element {
        return ScalingPainter.path(svg, polygon, Shadings.jointAttr(opacity), params);
    }
}

export class Shading {
    gradient: Snap.Element;
    mask: Snap.Element;

    constructor(gradient: Snap.Element, mask: Snap.Element) {
        this.gradient = gradient;
        this.mask = mask;
    }
}

export class ShadingBentParts {
    top: number[];
    right: number[];
    left: number[];
    bottom: number[];
}
