import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {WindowUpsellingData} from './WindowUpsellingData';

@Injectable()
export class WindowUpsellingDataService {

    private static readonly API_URL = 'windowUpsellingData';

    constructor(private http: HttpClient) {
    }

    getData(offerId: number): Observable<WindowUpsellingData> {
        return this.http.get<WindowUpsellingData>(`${WindowUpsellingDataService.API_URL}/${offerId}`);
    }

    saveData(data: WindowUpsellingData): Observable<void> {
        return this.http.post<void>(`${WindowUpsellingDataService.API_URL}/${data.offerId}`, data);
    }
}
