import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapItemToJson} from '../../common/crud-common/crudItem';
import {Listing} from '../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {Client} from './client';
import {SimpleProfitMarginSource} from './simple-profit-margin-source/simple-profit-margin-source';

@Injectable()
export class ClientService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getClients(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
               sortColumn: string, sortOrder: number): Observable<Listing<Client>> {

        if (sortColumn) {
            if (this.isAddressColumn(sortColumn)) {
                sortColumn = 'address.' + sortColumn;
            }
            if (sortColumn === 'groupName') {
                sortColumn = 'group.name';
            }
        }
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>('clients', {params: params}).pipe(
            map(response => Listing.fromJSON(Client, response)));
    }

    private isAddressColumn(sortColumn: string): boolean {
        return ['street', 'city', 'zip', 'country'].indexOf(sortColumn) >= 0;
    }

    saveClient(client: Client): Observable<number> {
        if (client.id) {
            return this.http.put<void>(`client/${client.id}`, client)
                .pipe(this.dataServiceHelper.mapToExistingItemId(client.id));
        } else {
            return this.http.post<void>('client', client, {observe: 'response'})
                .pipe(this.dataServiceHelper.mapToNewItemId('client'));
        }
    }

    deactivateClient(clientId: number): Observable<void> {
        return this.http.put<void>(`client/${clientId}/deactivate`, undefined);
    }

    getClient(clientId: number): Observable<Client> {
        return this.http.get<object>(`client/${clientId}`)
            .pipe(map(response => Client.fromJSON(response)));
    }

    validate(client: Client): Observable<boolean> {
        return this.http.post<void>('client/validate', client, {observe: 'response'})
            .pipe(map(response => response.ok));
    }

    getNotWebshopClientsForSubsystem(subsystemId: number): Observable<Client[]> {
        return this.http.get<Client[]>('clients/getBySubsystem/' + subsystemId);
    }

    getClientsSimple(searchedValue: string, filters: { [filterProperty: string]: FilterMetadata }): Observable<Listing<Client>> {
        filters['searchedValue'] = {
            value: searchedValue
        };
        let params = this.dataServiceHelper.prepareSearchParams(null, null, filters, null, null);
        return this.http.get<Listing<object>>('clients/globalSearch', {params: params}).pipe(
            map(response => Listing.fromJSON(Client, response)));
    }

    getSimpleProfitMarginSource(clientId: number): Observable<SimpleProfitMarginSource> {
        return this.http.get<object>(`client/${clientId}/simpleProfitMargins`)
            .pipe(mapItemToJson(SimpleProfitMarginSource));
    }

    saveSimpleProfitMarginSource(clientGroupId: number, profitMargins: SimpleProfitMarginSource): Observable<void> {
        return this.http.post<void>(`client/${clientGroupId}/simpleProfitMargins`, profitMargins);
    }
}
