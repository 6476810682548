<app-wizard #wizard
            header="{{ 'SUPPLIER-DETAILS.ADD-SUPPLIER-TITLE' | translate }}"
            (onCancel)="exitWizard()"
            (onComplete)="saveSupplier()">

    <app-wizard-step label="{{ 'SUPPLIER-DETAILS.STEP-DETAILS-TITLE' | translate }}"
                     [id]="STEPS.DETAILS"
                     [validate]="validateSupplierDetailsStep">

        <app-supplier-details-form [validationErrors]="validationErrors"
                                   [supplier]="supplier">
        </app-supplier-details-form>
    </app-wizard-step>

    <!-- ADDRESS STEP -->
    <app-wizard-step label="{{ 'SUPPLIER-DETAILS.STEP-ADDRESS-TITLE' | translate }}"
                     [id]="STEPS.ADDRESS"
                     [validate]="validateAddressStep">

        <app-supplier-address-form [supplier]="supplier"
                                   [validationErrors]="validationErrors">
        </app-supplier-address-form>
    </app-wizard-step>

    <!-- ANNOTATIONS STEP -->
    <app-wizard-step *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                    label="{{ 'SUPPLIER-DETAILS.STEP-ANNOTATIONS-TITLE' | translate }}"
                     [id]="STEPS.ANNOTATIONS"
                     [validate]="validateAnnotationsStep">

        <app-supplier-annotations-form [validationErrors]="validationErrors"
                                       [supplier]="supplier">
        </app-supplier-annotations-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                     label="{{ 'MOTLAWA_INTEGRATION_FORM.WIZARD-STEP-TITLE' | translate }}" [id]="STEPS.MOTLAWA_INTEGRATION">
        <app-supplier-motlawa-integration-form [motlawaIntegrationInfo]="motlawaIntegrationInfo"
                                               [validationErrors]="validationErrors"></app-supplier-motlawa-integration-form>
    </app-wizard-step>
</app-wizard>
