<div class="new-form-60vw">
    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': bulkTabHasErrors()}" [selected]="false"
                            header="{{ 'CONFIG_SYSTEM.FORM.BULK_DIMENSIONS' | translate }}">
                <app-config-system-dimensions-settings [dimensionLabelKeysMap]="dimensionLabelKeysMap" [dimensionsType]="'bulk'"
                                                       [item]="item"
                                                       [validationErrors]="validationErrors"></app-config-system-dimensions-settings>
            </p-accordionTab>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': windowTabHasErrors()}" [selected]="false"
                            header="{{ 'CONFIG_SYSTEM.FORM.FOR_WINDOW_DIMENSIONS' | translate }}">
                <app-config-system-dimensions-settings [dimensionLabelKeysMap]="dimensionLabelKeysMap" [dimensionsType]="'window'"
                                                       [item]="item"
                                                       [validationErrors]="validationErrors"></app-config-system-dimensions-settings>
            </p-accordionTab>

            <p-accordionTab [ngClass]="{'accordion-header-with-errors': validationErrors['amazonS3File']}" [selected]="false"
                            header="{{ 'CONFIG_SYSTEM.FORM.SIZING_INSTRUCTIONS' | translate }}">
                <div class="new-form-row">
                    <app-input-text [(ngModel)]="item.sizingVideoLink"
                                    [(validationMessageKey)]="validationErrors['sizingVideoLink']"
                                    [maxlength]="50" inputId="video-link"
                                    label="{{ 'CONFIG_SYSTEM.FORM.SIZING_LINK' | translate }}"
                    ></app-input-text>
                </div>
                <div *ngIf="canUploadToAmazon" class="new-form-row">
                    <app-file-upload (ngModelChange)="handleUploadedFileChange($event)"
                                     [(validationMessageKey)]="validationErrors['amazonS3File']"
                                     [image]="false" [maxHeight]="100000" [maxSize]="10485760"
                                     [maxWidth]="100000"
                                     [ngModel]="uploadedFile" accept="*.*"
                                     addButtonLabel="{{ 'CONFIG_SYSTEM.FORM.SELECT_SIZING_FILE_UPLOAD' | translate }}"
                                     changeButtonLabel="{{ 'CONFIG_SYSTEM.FORM.SELECT_SIZING_FILE_UPLOAD' | translate }}"
                                     deleteButtonLabel="{{ 'CONFIG_SYSTEM.FORM.DELETE_SIZING_FILE_UPLOAD' | translate }}"
                                     inputId="amazon-storage-file-upload-input"
                                     label="{{ 'CONFIG_SYSTEM.FORM.UPLOAD_SIZING_FILE' | translate }}"></app-file-upload>
                </div>
                <div class="new-form-row">
                    <div>
                        <h3>{{ 'CONFIG_SYSTEM.FORM.CHOOSE_FILE_ON_AMAZON' | translate }}</h3>
                        <p-listbox (ngModelChange)="handleUnusedFileSelectionChange($event)" [ngModel]="selectedFile"
                                   [options]="amazonS3Files"></p-listbox>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
