<div style="display: flex;">
    <app-sheet-table
        [data]="data"
        [isNew]="isNew"
        [columns]="columns"
        [rowHeaderLabel]="'PRICE_TABLES.FORM.HEIGHT' | translate"
        [columnHeaderLabel]="'PRICE_TABLES.FORM.WIDTH' | translate"
        [includeDescriptionRow]="true"
        [readOnly]="!permissions.canEditPricelist()"
        [validator]="validateValue"
        [setErrorCode]="setErrorCode"
        [cellStyleClasses]="cellStyleClasses"
        (errorDetected)="error = $event"></app-sheet-table>
</div>
