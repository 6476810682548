import {Component, Injector, NgZone, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {EMPTY, forkJoin, Observable, of} from "rxjs";
import * as _ from 'underscore';
import {StorageService} from "../../../../auth/storage.service";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {UnitConverter} from "../../../../common/unit-converter";
import {SidenavController} from "../../../../sidenav-controller";
import {OffersService} from "../../offer-service";
import {WindowEditorOfferData} from '../window-editor-offer-interfaces';
import {
    ConjunctionCoupler, ConjunctionData, ConjunctionDependee, ConjunctionPositionChild, ConjunctionPositionData, SideEnum
} from "./conjunction-data";
import {ConjunctionDto} from "./conjunction-dto";
import {ConjunctionService} from "../../offers/position/position-list/conjunctions/conjunction-service";
import {catchError, finalize} from "rxjs/operators";
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {MultilanguageFieldInterface} from "../../../../../window-designer/catalog-data/multilanguage-field-interface";
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {DrawingUtil, MinMaxXY, Point} from "../../../../../window-designer/drawing-util";
import {MaterialType} from "../../../../common/enums/MaterialType";
import {PositionService} from "../../offers/position/position.service";
import {Supplier} from "../../../supplier/supplier";
import {SelectItemImpl} from "../../../../common/service/select.item.impl";
import {TextPainter} from "../../../../../window-designer/painters/TextPainter";
import {Guides} from "../../../../../window-designer/guides";
import {WindowCalculator} from "../../../../../window-designer/window-calculator";
import {PolygonPoint} from "../../../../../window-designer/utils/PolygonPoint";
import {ConjunctionLabelGenerator} from "./ConjunctionLabelGenerator";
import {MultilanguageField} from "../../../../supportedLanguages";
import {CatalogItemName} from "../../../../common/crud-common/catalog-item-name";
import {ErrorNames} from "../../../../../window-designer/utils/ErrorNames";
import {DrawingToolControlsComponent, DrawingToolControlsMode} from "../drawing-tool-controls/drawing-tool-controls.component";
import {History} from "../history/history";

export class ConjunctionCatalogData {
    constructor(public positions: OfferPositionConjunctionData[], public connectors: ConjunctionConnector[]) {
    }
}

export interface OfferPositionConjunctionData {
    id: number;
    name: MultilanguageFieldInterface;
    quantity: number;
    data: string;
    conjunction: string;
    printOrder: number;

    supplierId: number;
    supplierName: MultilanguageField;
    material: MaterialType;
    systemThickness: number;
}

export interface ConjunctionConnector {
    id: number;
    name: MultilanguageFieldInterface;
    width: number;
    depthRanges: string;
    material: MaterialType;

    parsedRanges: {from: number, to: number}[];
}

class ParsedConjunctionPosition {
    position: OfferPositionConjunctionData;
    data: DrawingData;
    image: SafeUrl;
    dimensions: string;
    width: number;
    height: number;
    snapElement: Snap.Element;
    cornerSurfaces: Map<SideEnum, Map<SideEnum, number>>;
}

class ConjunctionPainterHelperFields {
    origin: Point;
    shiftedCenterPoint: Point;
    shiftedFramePoints: number[];
}

export enum ConjunctionElementType {
    POSITION = 'POSITION',
    COUPLER = 'COUPLER',
}

@Component({
    selector: 'app-conjunction-editor',
    templateUrl: './conjunction-editor.component.html',
    styleUrls: ['../common/designers.css', './conjunction-editor.component.css', '../common/designer-length-unit-hider.directive.css'],
    providers: [
        CommonErrorHandler, OffersService, PositionService, ConjunctionService
    ]
})
export class ConjunctionEditorComponent implements OnInit, OnDestroy {

    private static readonly BLOCK_SOURCE_ID = 'ConjunctionEditor';
    private static readonly LP_SEPARATOR = ', ';
    private static readonly SNAP_ELEM_SELECTED_CSS_CLASS = "selected-snap-item";

    @ViewChild('drawingToolsControl', {static: true}) drawingToolsControl: DrawingToolControlsComponent;

    DrawingToolControlsMode = DrawingToolControlsMode;
    ConjunctionElementType = ConjunctionElementType;

    private blockUiController: BlockUiController;
    private errors: CommonErrorHandler;
    private offerService: OffersService;
    private positionService: PositionService;
    private route: ActivatedRoute;
    private router: Router;
    private sanitizer: DomSanitizer;
    private sidenavController: SidenavController;
    private storage: StorageService;
    private hotkeyService: HotkeysService;
    private conjunctionService: ConjunctionService;

    translate: TranslateService;

    showExitWithoutSavingConfirmationDialog: boolean;
    showSidebar = true;
    private enterHotkey: Hotkey;
    private escapeHotkey: Hotkey;
    private undoHotkey: Hotkey;
    private redoHotkey: Hotkey;
    private deleteHotkey: Hotkey;
    private mouseCoords: Point;
    svg: Snap.Paper;

    offer: WindowEditorOfferData;
    suppliers: Supplier[];

    catalogData: ConjunctionCatalogData;
    conjunctionDto: ConjunctionDto;
    data: ConjunctionData;
    originalData: string;
    mappedPositions: Map<number, ParsedConjunctionPosition> = new Map();
    painterHelperMap: Map<string, ConjunctionPainterHelperFields> = new Map();
    availablePositionsBySupplier: Map<number, ParsedConjunctionPosition[]>;
    availableSuppliers: CatalogItemName[];
    availableConnectors: ConjunctionConnector[] = [];
    totalBoundingBox: MinMaxXY;
    positionToBeAdded: ParsedConjunctionPosition;
    preparedButtons: (() => void)[];
    postponedPainters: ((fontSize: number) => void)[];
    positionToBeAddedOrigins: { origin: Point, viewBoxCorner: Point }[] = [];
    connectorToAddId: number;
    connectorToAddWidth: number;
    selectedConnector: ConjunctionCoupler;
    selectedConnectorChange: ConjunctionCoupler;

    supplierName: string;
    includedPositionNumbers: string;
    includedCouplers: { label: string, width: number, name: string }[];
    connectorOptionFormatter: (addon: ConjunctionConnector) => SelectItemImpl;
    sideClockwiseOrder: Map<SideEnum, SideEnum>;
    customCouplerName: string;
    deletedPositionLabel: string;
    history: History;
    selectedElements: Snap.Element[] = [];
    showChangeCouplerDialog: boolean;
    canUseDeleteTool: boolean;
    canUseChangeCouplerTool: boolean;
    canSaveExit: boolean;

    constructor(injector: Injector, public zone: NgZone) {
        this.blockUiController = injector.get(BlockUiController);
        this.errors = injector.get(CommonErrorHandler);
        this.offerService = injector.get(OffersService);
        this.positionService = injector.get(PositionService);
        this.conjunctionService = injector.get(ConjunctionService);
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.sanitizer = injector.get(DomSanitizer);
        this.sidenavController = injector.get(SidenavController);
        this.storage = injector.get(StorageService);
        this.translate = injector.get(TranslateService);
        this.hotkeyService = injector.get(HotkeysService);
        this.history = new History();
        this.connectorOptionFormatter = (addon: ConjunctionConnector) => new SelectItemImpl(addon.name[this.translate.currentLang],
            addon.id);
        this.initHotkeys();
    }

    initHotkeys(): void {
        this.enterHotkey = new Hotkey('enter', () => {
            this.save();
            return false;
        }, ['INPUT']);
        this.escapeHotkey = new Hotkey('esc', () => {
            this.cancelMode(true);
            return false;
        }, ['INPUT']);
        this.undoHotkey = new Hotkey('ctrl+z', () => {
            this.undoLast();
            return false;
        }, undefined, 'OFFER.MENU.UNDO');
        this.redoHotkey = new Hotkey('ctrl+y', () => {
            this.redoLast();
            return false;
        }, undefined, 'OFFER.MENU.REDO');
        this.deleteHotkey = new Hotkey('del', () => {
            this.deleteSelected();
            return false;
        }, undefined, 'OFFER.MENU.REMOVE_SELECTED');

        this.hotkeyService.add(this.enterHotkey);
        this.hotkeyService.add(this.escapeHotkey);
        this.hotkeyService.add(this.undoHotkey);
        this.hotkeyService.add(this.redoHotkey);
        this.hotkeyService.add(this.deleteHotkey);
    }

    ngOnInit(): void {
        this.svg = Snap('#drawing-svg');
        this.sidenavController.hide();
        this.blockUiController.block(ConjunctionEditorComponent.BLOCK_SOURCE_ID);
        let offerId = this.route.snapshot.params['offerId'];
        if (offerId == undefined) {
            this.navigateToPositionList();
        }
        let conjunctionId = this.route.snapshot.params['conjunctionId'];
        let newPosition = conjunctionId === 'new';
        let conjunctionObservable: Observable<ConjunctionDto>;
        conjunctionObservable = newPosition ? of(new ConjunctionDto()) : this.conjunctionService.get(conjunctionId);

        forkJoin({
            offer: this.offerService.getOfferForWindowEditor(offerId),
            conjunction: conjunctionObservable,
            suppliers: this.positionService.getSuppliersForOffer(offerId),
            catalogData: this.conjunctionService.getCatalogData(offerId),
            translations: this.translate.get(['OFFER.POSITIONS.CONJUNCTION.CUSTOM_CONNECTOR',
                'OFFER.POSITIONS.CONJUNCTION.DELETED_POSITION'])
        }).pipe(catchError((error) => {
            this.hideUiBlock();
            this.errors.handle(error);
            this.router.navigate(['features/offer']);
            return EMPTY;
        }), finalize(() => this.hideUiBlock())).subscribe({
            next: data => {
                this.offer = data.offer;
                this.conjunctionDto = data.conjunction;
                this.catalogData = data.catalogData;
                this.suppliers = data.suppliers;
                this.data = JSON.parse(data.conjunction.data);
                this.originalData = data.conjunction.data;
                this.customCouplerName = data.translations['OFFER.POSITIONS.CONJUNCTION.CUSTOM_CONNECTOR'];
                this.deletedPositionLabel = data.translations['OFFER.POSITIONS.CONJUNCTION.DELETED_POSITION'];
                this.initSideEnum();
                this.parseConnectors();
                this.parsePositions();
                this.filterAvailableConnectors();
                this.rebindData();
                this.initMouse();
                this.initCoupler();
                this.initRoot(this.data.root ? this.data.root.id : undefined);
                this.refreshIncludedElements();
                this.paint();
            },
            error: (error) => this.errors.handle(error)
        });
    }

    initSideEnum(): void {
        this.sideClockwiseOrder = new Map<SideEnum, SideEnum>();
        this.sideClockwiseOrder.set(SideEnum.TOP, SideEnum.RIGHT);
        this.sideClockwiseOrder.set(SideEnum.RIGHT, SideEnum.BOTTOM);
        this.sideClockwiseOrder.set(SideEnum.BOTTOM, SideEnum.LEFT);
        this.sideClockwiseOrder.set(SideEnum.LEFT, SideEnum.TOP);
    }

    reverseSide(side: SideEnum): SideEnum {
        return this.sideClockwiseOrder.get(this.sideClockwiseOrder.get(side));
    }

    rebindData(): void {
        let positions = this.getAllIncludedPositions();
        let countUsages = [];
        let invalidPositions = [];
        let invalidCouplers = false;
        let deletedPosition = false;
        positions.filter(p => p.id != undefined).forEach(p => {
            let mapped = this.mappedPositions.get(p.id);
            if (mapped == undefined) {
                deletedPosition = true;
                this.deletePosition(p);
            } else if (!this.validatePosition(mapped.position, countUsages)) {
                invalidPositions.push(mapped.position.printOrder);
                this.deletePosition(p);
            } else {
                p.width = mapped.width;
                p.height = mapped.height;
            }
            countUsages[p.id] = countUsages[p.id] ? (countUsages[p.id] + 1) : 1;
        });
        this.getAllIncludedCouplers().filter(c => c.id != undefined).forEach(c => {
            let connector = this.catalogData.connectors.find(a => a.id === c.id);
            if (connector == undefined || !this.connectorFitsSystem(connector)) {
                invalidCouplers = true;
                c.id = undefined;
            }
        });
        if (invalidPositions.length > 0) {
            let error = this.error('Some positions have been removed due to significant changes', ErrorNames.CONJUNCTION_POSITIONS_EDITED);
            this.errors.handleFE(error, {positions: this.formatPositionPrintOrders(invalidPositions)});
        }
        if (deletedPosition) {
            let error = this.error('Positions deleted by the user are no logner available', ErrorNames.CONJUNCTION_POSITION_DELETED);
            this.errors.handleFE(error);
        }
        if (invalidCouplers) {
            let error = this.error('Some connector are no logner available', ErrorNames.CONJUNCTION_CONNECTOR_INACTIVE);
            this.errors.handleFE(error);
        }
    }

    connectorFitsSystem(connector: ConjunctionConnector): boolean {
        return this.connectorFitsSystemMaterial(connector) && this.systemFitsConnectorRanges(connector);
    }

    systemFitsConnectorRanges(connector: ConjunctionConnector): boolean {
        return this.conjunctionDto.systemThickness == undefined || connector.parsedRanges.some(
            r => r.from <= this.conjunctionDto.systemThickness && this.conjunctionDto.systemThickness <= r.to);
    }

    connectorFitsSystemMaterial(connector: ConjunctionConnector): boolean {
        return this.conjunctionDto.material == undefined || connector.material === this.conjunctionDto.material;
    }

    initCoupler(): void {
        if (this.catalogData.connectors.length > 0) {
            this.connectorToAddId = this.catalogData.connectors[0].id;
            this.connectorToAddWidth = this.catalogData.connectors[0].width;
        } else {
            this.connectorToAddWidth = 1;
        }
    }

    initRoot(rootId: number): void {
        let root = this.mappedPositions.get(rootId);
        if (root != undefined) {
            this.conjunctionDto.supplierId = root.position.supplierId;
            this.supplierName = this.suppliers.find(s => s.id === this.conjunctionDto.supplierId).name[this.translate.currentLang];
            this.conjunctionDto.material = root.position.material;
            this.conjunctionDto.systemThickness = root.position.systemThickness;
            this.filterAvailableConnectors();
            this.filterAvailablePositionsAndSuppliers();
        } else {
            this.conjunctionDto.supplierId = undefined;
            this.supplierName = undefined;
            this.conjunctionDto.material = undefined;
            this.conjunctionDto.systemThickness = undefined;
            let availablePositions = this.catalogData.positions.map(p => this.mappedPositions.get(p.id))
                                         .filter(p => this.initialPositionValidation(p.position));
            this.filterSuppliers(availablePositions);
        }
    }

    filterAvailablePositionsAndSuppliers(): void {
        let countUsages = _.countBy(this.getAllIncludedPositions().map(p => p.id));
        let availablePositions = this.catalogData.positions.map(p => this.mappedPositions.get(p.id))
                                      .filter(p => this.validatePosition(p.position, countUsages));
        this.filterSuppliers(availablePositions);
    }

    initialPositionValidation(position: OfferPositionConjunctionData): boolean {
        return position.systemThickness != undefined;
    }

    filterAvailableConnectors(): void {
        let availableConnectors = this.catalogData.connectors.filter(c => this.connectorFitsSystem(c));
        if (this.connectorToAddId != undefined && !availableConnectors.some(c => c.id === this.connectorToAddId)) {
            this.onConnectorChange(availableConnectors.length === 0 ? undefined : availableConnectors[0].id);
        }
        this.availableConnectors = availableConnectors;
    }

    validatePosition(position: OfferPositionConjunctionData, countUsages): boolean {
        return this.initialPositionValidation(position)
               && position.supplierId === this.conjunctionDto.supplierId
               && position.material === this.conjunctionDto.material
               && position.systemThickness === this.conjunctionDto.systemThickness
               && (countUsages[position.id] == undefined || position.quantity > countUsages[position.id]);
    }

    filterSuppliers(availablePositions: ParsedConjunctionPosition[]): void {
        let suppliers = availablePositions.map(pos => ({id: pos.position.supplierId, name: pos.position.supplierName}))
            .reduce((obj, item) => Object.assign(obj, {[item.id]: item.name}), {});
        this.availablePositionsBySupplier = new Map<number, ParsedConjunctionPosition[]>();
        availablePositions.forEach(pos => {
            let positions = this.availablePositionsBySupplier.get(pos.position.supplierId) || [];
            positions.push(pos);
            this.availablePositionsBySupplier.set(pos.position.supplierId, positions);
        });
        this.availableSuppliers = _.chain(Object.entries(suppliers)).map(entry => ({id: +entry[0], name: entry[1]})).sortBy("id").value();
    }

    trackById = (index: number, item: CatalogItemName) => {
        return item.id;
    }

    refreshIncludedElements(): void {
        let includedPosistion = this.getAllIncludedPositions();
        this.includedPositionNumbers = this.formatPositionPrintOrders(
            includedPosistion.map(p => this.mappedPositions.get(p.id)).filter(p => p != undefined).map(p => p.position.printOrder));
        let couplers = this.getAllIncludedCouplers();
        ConjunctionLabelGenerator.generateCouplerLabels(couplers);
        this.includedCouplers = _.uniq(couplers.map(c => {
            let name = c.id == undefined ? this.customCouplerName :
                this.catalogData.connectors.find(a => a.id === c.id).name[this.translate.currentLang];
            return {label: c.label, width: c.width, name: name};
        }), c => c.label);
    }

    formatPositionPrintOrders(printOrders: number[]): string {
        return _.uniq(printOrders).sort((a, b) => a - b).join(ConjunctionEditorComponent.LP_SEPARATOR);
    }

    initMouse(): void {
        this.svg.mousemove(event => {
            this.mouseCoords = this.calculateSvgCoordsFromMouseEvent(event);
            this.mouseCoords.x = Math.abs(this.totalBoundingBox.minX) + this.mouseCoords.x | 0; // tslint:disable-line:no-bitwise
            this.mouseCoords.y = Math.abs(this.totalBoundingBox.minY) + this.mouseCoords.y | 0; // tslint:disable-line:no-bitwise
        });
    }

    parsePositions(): void {
        this.catalogData.positions.forEach(p => {
            let parsed = new ParsedConjunctionPosition();
            parsed.position = p;
            parsed.data = JSON.parse(p.data);
            parsed.image = this.createSvgObjectURL(p.conjunction);
            let frame = WindowCalculator.getOuterFramePointsFull(parsed.data);
            let bBox = DrawingUtil.calculateTotalBoundingBox(parsed.data.windows);
            parsed.width = bBox.maxX - bBox.minX;
            parsed.height = bBox.maxY - bBox.minY;
            parsed.dimensions = `${parsed.width}x${parsed.height}`;
            parsed.snapElement = Snap(Snap.parse(p.conjunction).node);
            parsed.cornerSurfaces = this.getCornerSurfaces(frame, bBox);
            this.mappedPositions.set(p.id, parsed);
        });
    }

    parseConnectors(): void {
        this.catalogData.connectors.forEach(c => {
            c.parsedRanges = c.depthRanges.split(';').map(r => r.split('-').map(limit => +limit.trim())).map(r => ({from: r[0], to: r[1]}));
        });
    }

    OnWindowResize(event: any): void {
        this.paint();
    }

    paint(): void {
        if (this.data == undefined) {
            return;
        }
        this.preparedButtons = [];
        this.postponedPainters = [];
        this.positionToBeAddedOrigins = [];
        this.totalBoundingBox = new MinMaxXY(0, 0, 0, 0);
        this.svg.clear();
        if (this.data.root == undefined) {
            this.prepareButton();
        } else {
            this.paintNode(this.data.root);
        }

        this.preparedButtons.forEach(b => b());
        let box = this.totalBoundingBox;
        let fontSize = Math.max((box.maxX - box.minX), (box.maxY - box.minY)) / 32;
        this.postponedPainters.forEach(p => p(fontSize));
        this.paintGuides(fontSize, box);
        let bordersMultiplier = 0.1;
        this.totalBoundingBox.minX -= (box.maxX - box.minX) * bordersMultiplier;
        this.totalBoundingBox.maxX += (box.maxX - box.minX) * bordersMultiplier;
        this.totalBoundingBox.minY -= (box.maxY - box.minY) * bordersMultiplier;
        this.totalBoundingBox.maxY += (box.maxY - box.minY) * bordersMultiplier;
        if (this.positionToBeAdded) {
            this.totalBoundingBox.minX -= this.connectorToAddWidth;
            this.totalBoundingBox.maxX += this.connectorToAddWidth;
            this.totalBoundingBox.minY -= this.connectorToAddWidth;
            this.totalBoundingBox.maxY += this.connectorToAddWidth;
        }

        this.svg.attr({
            viewBox: [box.minX, box.minY, box.maxX - box.minX, box.maxY - box.minY].join(' ')
        });
    }

    paintGuides(fontSize: number, box: MinMaxXY): void {
        if (!(this.data && this.data.root)) {
            return;
        }
        let guidesDistance = fontSize;
        let guideAttr = {
            stroke: '#393939', strokeWidth: fontSize / 16 + 'px', fill: 'none'
        };
        Guides.drawSingleHorizontalGuide(this.svg, box.minX, box.maxX, box.minY - guidesDistance, guidesDistance / 2)
              .attr(guideAttr);
        Guides.drawSingleVerticalGuide(this.svg, box.minX - guidesDistance, box.minY, box.maxY, guidesDistance / 2)
              .attr(guideAttr);
        this.paintTextInAllUnits(box.maxX - box.minX, [(box.minX + box.maxX) / 2, box.minY - guidesDistance], fontSize, false);
        this.paintTextInAllUnits(box.maxY - box.minY, [box.minX - guidesDistance, (box.minY + box.maxY) / 2], fontSize, false, true);
        this.totalBoundingBox.minX -= guidesDistance * 3;
        this.totalBoundingBox.minY -= guidesDistance * 3;
    }

    addNode(newNode: ConjunctionPositionData, origin: Point, parent?: ConjunctionPositionData, side?: SideEnum, corner?: SideEnum): void {
        this.history.saveOperation(this.data);
        if (parent) {
            if (parent.dependees.some(s => s.side === side && s.children.some(c => c.corner === corner))) {
                throw this.error('AddNode - child already exists on chosen corner.');
            }
            let dependee = parent.dependees.find(s => s.side === side);
            if (dependee == undefined) {
                dependee = new ConjunctionDependee(side, this.newCoupler());
                parent.dependees.push(dependee);
            }
            dependee.children.push(new ConjunctionPositionChild(corner, newNode));
            this.filterAvailablePositionsAndSuppliers();
        } else {
            this.data.root = newNode;
            this.initRoot(this.positionToBeAdded.position.id);
        }
        this.positionToBeAdded = undefined;
        this.refreshToolButtons();
        this.refreshIncludedElements();
        this.paint();
        this.drawingToolsControl.markForCheck();
    }

    newCoupler(): ConjunctionCoupler {
        return new ConjunctionCoupler(this.connectorToAddId, this.connectorToAddWidth);
    }

    getCornerSurfaces(frame: PolygonPoint[], bbox: MinMaxXY): Map<SideEnum, Map<SideEnum, number>> {
        let results: Map<SideEnum, Map<SideEnum, number>> = new Map<SideEnum, Map<SideEnum, number>>();
        for (let side of Object.values(SideEnum)) {
            results.set(side, new Map<SideEnum, number>());
            let cornerIndex;
            for (let corner of this.getPossibleCorners(side)) {
                let cornerX = (side === SideEnum.LEFT || corner === SideEnum.LEFT) ? bbox.minX : bbox.maxX;
                let cornerY = (side === SideEnum.TOP || corner === SideEnum.TOP) ? bbox.minY : bbox.maxY;
                cornerIndex = frame.findIndex(c => c.x === cornerX && c.y === cornerY);
                let sideAxis = (side === SideEnum.LEFT || side === SideEnum.RIGHT) ? 'x' : 'y';
                let sideExtremum = ((side === SideEnum.LEFT || side === SideEnum.TOP) ? 'min' : 'max') + sideAxis.toUpperCase();
                let neighbouringPoint;
                if (cornerIndex !== -1) {
                    neighbouringPoint = DrawingUtil.getPoint(frame, cornerIndex + (this.sideClockwiseOrder.get(side) === corner ? -1 : 1));
                    if (neighbouringPoint[sideAxis] === bbox[sideExtremum]) {
                        results.get(side).set(corner, DrawingUtil.distance([neighbouringPoint.x, neighbouringPoint.y], [cornerX, cornerY]));
                    }
                }
            }
        }
        return results;
    }

    canPositionFitIn(parent: ConjunctionPositionData, origin: Point, side: SideEnum, corner: SideEnum): boolean {
        let newBox = this.getBox(origin, this.positionToBeAdded);
        let newCenter = DrawingUtil.getPolygonCentroid(newBox);
        let availableAddedSurface = this.positionToBeAdded.cornerSurfaces.get(this.reverseSide(side)).get(corner);
        let availableParentSurface = parent.id == undefined ?
            ((side === SideEnum.TOP || side === SideEnum.BOTTOM) ? parent.width : parent.height) :
            this.mappedPositions.get(parent.id).cornerSurfaces.get(side).get(corner);
        return availableAddedSurface != undefined && availableParentSurface != undefined
               && this.getAllIncludedPositions().every(p => {
                let helperFields = this.painterHelperMap.get(p.generatedId);
                return !DrawingUtil.isPointInPolygon([helperFields.shiftedCenterPoint.x, helperFields.shiftedCenterPoint.y], newBox)
                   && !DrawingUtil.isPointInPolygon(newCenter, helperFields.shiftedFramePoints) && !DrawingUtil.doPolygonsOverlap(
                    helperFields.shiftedFramePoints, newBox);
        });
    }

    buttonAlreadyPresent(origin: Point, viewBoxCorner: Point): boolean {
        return this.positionToBeAddedOrigins.findIndex(
            o => o.origin.x === origin.x && o.origin.y === origin.y
                 && o.viewBoxCorner.x === viewBoxCorner.x && o.viewBoxCorner.y === viewBoxCorner.y) !== -1;
    }

    prepareButton(origin: Point = new Point(0, 0), parent?: ConjunctionPositionData, side?: SideEnum, corner?: SideEnum): void {
        if (this.positionToBeAdded) {
            this.preparedButtons.push(() => {
                let newNode = JSON.parse(JSON.stringify(this.positionToBeAdded));
                let viewBox = this.mappedPositions.get(newNode.position.id).snapElement.attr('viewBox');
                let viewBoxCorner = new Point(viewBox['x'] + (side === SideEnum.LEFT || corner === SideEnum.RIGHT ? viewBox['width'] : 0),
                    viewBox['y'] + (side === SideEnum.TOP || corner === SideEnum.BOTTOM ? viewBox['height'] : 0));
                if (this.buttonAlreadyPresent(origin, viewBoxCorner) || (parent != null && !this.canPositionFitIn(parent, origin, side,
                    corner))) {
                    return;
                }
                let newEl = this.mappedPositions.get(newNode.position.id).snapElement.clone();
                newEl.attr({width: newNode.width, height: newNode.height, x: origin.x, y: origin.y});
                newEl.addClass('green-tinted');
                newEl.click(
                    event => this.addNode(new ConjunctionPositionData(newNode.position.id, newNode.width, newNode.height), origin, parent,
                        side, corner));
                this.positionToBeAddedOrigins.push({origin: origin, viewBoxCorner: viewBoxCorner});
                this.svg.add(newEl);
                if (parent == null) {
                    this.totalBoundingBox = new MinMaxXY(0, newNode.width, 0, newNode.height);
                } else {
                    let r = side === SideEnum.LEFT || side === SideEnum.RIGHT ? (parent.height / 2) : (parent.width / 2);
                    let clip = this.svg.circle(viewBoxCorner.x, viewBoxCorner.y, r);
                    newEl.attr({clip: clip});
                }
            });
        }
    }

    getBox(origin: Point, nodePos: ParsedConjunctionPosition): number[] {
        return [
            origin.x,
            origin.y,
            origin.x + nodePos.width,
            origin.y,
            origin.x + nodePos.width,
            origin.y + nodePos.height,
            origin.x,
            origin.y + nodePos.height,
        ];
    }

    paintNode(node: ConjunctionPositionData, origin: Point = new Point(0, 0)): void {
        if (node == undefined) {
            return;
        }

        if (node.id != undefined) {
            let parsed = this.mappedPositions.get(node.id);
            let el = parsed.snapElement.clone();
            el.attr({width: parsed.width, height: parsed.height, x: origin.x, y: origin.y});

            let framePoints = WindowCalculator.getOuterFramePoints(parsed.data.windows, parsed.data.cuts);
            let viewBox = parsed.snapElement.attr('viewBox');
            framePoints = DrawingUtil.shiftArrayInXAxis(framePoints, (origin.x - viewBox['x']));
            framePoints = DrawingUtil.shiftArrayInYAxis(framePoints, origin.y - viewBox['y']);
            let center = DrawingUtil.getPolygonCentroid(framePoints);
            this.painterHelperMap.set(node.generatedId, {
                origin: origin, shiftedCenterPoint: new Point(center[0], center[1]), shiftedFramePoints: framePoints
            });
            el.data(ConjunctionElementType.POSITION, node);
            this.addSelectionClickHandler(el);
            this.svg.add(el);
            this.postponedPainters.push((fontSize) => {
                this.paintText('' + parsed.position.printOrder, center, fontSize);
            });
        } else {
            this.postponedPainters.push((fontSize) => {
                let el = this.svg.rect(origin.x, origin.y, node.width, node.height)
                             .attr({
                                 fill: 'none',
                                 strokeDasharray: `${fontSize},${fontSize}`,
                                 stroke: '#393939',
                                 strokeWidth: fontSize / 32 + 'px'
                             });
                this.svg.add(
                    this.paintText(this.deletedPositionLabel, [origin.x + (node.width / 2), origin.y + ((node.height - fontSize) / 2)],
                        fontSize));
                const mmText = this.paintText(`${node.width}x${node.height}`,
                    [origin.x + (node.width / 2), origin.y + ((node.height + fontSize) / 2)], fontSize)
                    .addClass(Guides.MILLIMETERS_UNIT_CLASS);
                this.svg.add(mmText);
                const makeInchText = (value: number): string => {
                    const inches = UnitConverter.millimitersToInches(value);
                    let inchesText = `${inches.full}`;
                    if (inches.sixteenths !== 0) {
                        inchesText += ` ${inches.sixteenths}/16`;
                    }
                    return inchesText;
                };
                const inchText = this.paintText(`${makeInchText(node.width)}x${makeInchText(node.height)}`,
                    [origin.x + (node.width / 2), origin.y + ((node.height + fontSize) / 2)], fontSize)
                    .addClass(Guides.INCHES_UNIT_CLASS);
                this.svg.add(inchText);
                this.svg.add(el);
            });
            this.painterHelperMap.set(node.generatedId, this.generateHelperForEmptySpace(origin, node));
        }

        this.totalBoundingBox.minX = Math.min(this.totalBoundingBox.minX, origin.x);
        this.totalBoundingBox.maxX = Math.max(this.totalBoundingBox.maxX, origin.x + node.width);
        this.totalBoundingBox.minY = Math.min(this.totalBoundingBox.minY, origin.y);
        this.totalBoundingBox.maxY = Math.max(this.totalBoundingBox.maxY, origin.y + node.height);

        if (this.positionToBeAdded) {
            for (let side of Object.values(SideEnum)) {
                let dependee = node.dependees.find(d => d.side === side);
                dependee = dependee ? JSON.parse(JSON.stringify(dependee)) : new ConjunctionDependee(side, this.newCoupler());
                for (let corner of this.getPossibleCorners(side)) {
                    if (dependee.children.findIndex(c => c.corner === corner) === -1) {
                        dependee.children.push(new ConjunctionPositionChild(corner));
                    }
                }
                dependee.children = dependee.children.filter(c => c.data == null);
                this.paintDependees(dependee, origin, node);
            }
        }
        node.dependees.forEach(d => this.paintDependees(d, origin, node));
    }

    addSelectionClickHandler(element: Snap.Element) {
        element.click(event => {
            if (this.positionToBeAdded == undefined) {
                event.stopPropagation();
                this.selectElement(element, event.shiftKey);
                return;
            }
        });
    }

    resetSelectedElements(): void {
        this.selectedElements.forEach(e => this.removeClassFromElementAndItsChildren(e));
        this.selectedElements = [];
    }

    selectElement(elem: Snap.Element, addToAlreadySelected: boolean) {
        if (!addToAlreadySelected) {
            this.resetSelectedElements();
        }
        this.selectedElements.push(elem);
        this.addClassToElementAndItsChildren(elem);
        this.refreshToolButtons();
    }

    private addClassToElementAndItsChildren(elem: Snap.Element) {
        elem.addClass(ConjunctionEditorComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        for (let child of elem.children()) {
            child.addClass(ConjunctionEditorComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        }
    }

    private removeClassFromElementAndItsChildren(elem: Snap.Element) {
        elem.removeClass(ConjunctionEditorComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        for (let child of elem.children()) {
            child.removeClass(ConjunctionEditorComponent.SNAP_ELEM_SELECTED_CSS_CLASS);
        }
    }

    paintText(text: string, position: number[], fontSize: number, centered = true, vertical = false): Snap.Element {
        return TextPainter.simpleText(this.svg, text, position, fontSize, centered, vertical).attr({pointerEvents: 'none'});
    }

    paintTextInAllUnits(value: number, position: number[], fontSize: number, centered = true, vertical = false): Snap.Element[] {
        const texts: Snap.Element[] = [];

        const mmText = TextPainter.simpleText(this.svg, `${value}`, position, fontSize, centered, vertical).attr({pointerEvents: 'none'});
        mmText.addClass(Guides.MILLIMETERS_UNIT_CLASS);
        texts.push(mmText);

        const inches = UnitConverter.millimitersToInches(value);
        let inchesText = `${inches.full}`;
        if (inches.sixteenths !== 0) {
            inchesText += ` ${inches.sixteenths}/16`;
        }
        const inText = TextPainter.simpleText(this.svg, inchesText, position, fontSize, centered, vertical).attr({pointerEvents: 'none'});
        inText.addClass(Guides.INCHES_UNIT_CLASS);
        texts.push(inText);

        return texts;
    }

    generateHelperForEmptySpace(origin: Point, node: ConjunctionPositionData): ConjunctionPainterHelperFields {
        return {
            origin: origin,
            shiftedCenterPoint: new Point(origin.x + (node.width / 2), origin.y + (node.height / 2)),
            shiftedFramePoints: [
                origin.x, origin.y,
                origin.x + node.width, origin.y,
                origin.x + node.width, origin.y + node.height,
                origin.x, origin.y + node.height
            ]
        };
    }

    getPossibleCorners(side: SideEnum): SideEnum[] {
        switch (side) {
            case SideEnum.TOP:
            case SideEnum.BOTTOM:
                return [SideEnum.LEFT, SideEnum.RIGHT];
            case SideEnum.LEFT:
            case SideEnum.RIGHT:
                return [SideEnum.TOP, SideEnum.BOTTOM];
            default:
                throw new Error(`Unsupported side ${side}`);
        }
    }

    paintDependees(dependee: ConjunctionDependee, origin: Point, parent: ConjunctionPositionData): void {
        let targetOrigin = new Point(origin.x, origin.y);
        switch (dependee.side) {
            case SideEnum.TOP:
                targetOrigin.y -= dependee.coupler.width;
                dependee.children.forEach(c => {
                    let childOrigin = new Point(targetOrigin.x, targetOrigin.y);
                    childOrigin.y -= c.data ? c.data.height : this.positionToBeAdded.height;
                    switch (c.corner) {
                        case SideEnum.LEFT:
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        case SideEnum.RIGHT:
                            childOrigin.x += parent.width - (c.data ? c.data.width : this.positionToBeAdded.width);
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        default:
                            throw new Error(`Wrong corner ${c.corner} for side ${dependee.side}`);
                    }
                });
                break;
            case SideEnum.BOTTOM:
                targetOrigin.y += parent.height + dependee.coupler.width;
                dependee.children.forEach(c => {
                    let childOrigin = new Point(targetOrigin.x, targetOrigin.y);
                    switch (c.corner) {
                        case SideEnum.LEFT:
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        case SideEnum.RIGHT:
                            childOrigin.x += parent.width - (c.data ? c.data.width : this.positionToBeAdded.width);
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        default:
                            throw new Error(`Wrong corner ${c.corner} for side ${dependee.side}`);
                    }
                });
                break;
            case SideEnum.LEFT:
                targetOrigin.x -= dependee.coupler.width;
                dependee.children.forEach(c => {
                    let childOrigin = new Point(targetOrigin.x, targetOrigin.y);
                    childOrigin.x -= c.data ? c.data.width : this.positionToBeAdded.width;
                    switch (c.corner) {
                        case SideEnum.TOP:
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        case SideEnum.BOTTOM:
                            childOrigin.y += parent.height - (c.data ? c.data.height : this.positionToBeAdded.height);
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        default:
                            throw new Error(`Wrong corner ${c.corner} for side ${dependee.side}`);
                    }
                });
                break;
            case SideEnum.RIGHT:
                targetOrigin.x += dependee.coupler.width + parent.width;
                dependee.children.forEach(c => {
                    let childOrigin = new Point(targetOrigin.x, targetOrigin.y);
                    switch (c.corner) {
                        case SideEnum.TOP:
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        case SideEnum.BOTTOM:
                            childOrigin.y += parent.height - (c.data ? c.data.height : this.positionToBeAdded.height);
                            this.prepareButton(childOrigin, parent, dependee.side, c.corner);
                            this.paintNode(c.data, childOrigin);
                            break;
                        default:
                            throw new Error(`Wrong corner ${c.corner} for side ${dependee.side}`);
                    }
                });
                break;
        }
        this.paintCoupler(dependee, origin, parent);
    }

    private paintCoupler(dependee: ConjunctionDependee, parentOrigin: Point, parent: ConjunctionPositionData) {
        let coupler = dependee.coupler;
        if (coupler && coupler.label) {
            let origin: Point;
            let size: Point;
            switch (dependee.side) {
                case SideEnum.TOP:
                    origin = new Point(parentOrigin.x, parentOrigin.y - coupler.width);
                    size = new Point(parent.width, coupler.width);
                    break;
                case SideEnum.RIGHT:
                    origin = new Point(parentOrigin.x + parent.width, parentOrigin.y);
                    size = new Point(coupler.width, parent.height);
                    break;
                case SideEnum.BOTTOM:
                    origin = new Point(parentOrigin.x, parentOrigin.y + parent.height);
                    size = new Point(parent.width, coupler.width);
                    break;
                case SideEnum.LEFT:
                    origin = new Point(parentOrigin.x - coupler.width, parentOrigin.y);
                    size = new Point(coupler.width, parent.height);
                    break;
            }
            this.postponedPainters.push((fontSize: number) => {
                let g = this.svg.g(this.svg.rect(origin.x, origin.y, size.x, size.y).attr({fill: '#ffffff'}));
                g.add(this.svg.circle(origin.x + (size.x / 2), origin.y + (size.y / 2), fontSize).attr({fillOpacity: '0'}));
                g.data(ConjunctionElementType.COUPLER, dependee.coupler);
                this.addSelectionClickHandler(g);
                this.paintText(coupler.label, [origin.x + (size.x / 2), origin.y + (size.y / 2)], fontSize);
            });
        }
    }

    error(msg: string, name?: string): Error {
        let error = new Error(`ConjunctionEditorComponent - ${msg}`);
        if (name) {
            error.name = name;
        }
        return error;
    }

    getAllIncludedPositions(): ConjunctionPositionData[] {
        return this.data.root == undefined ? [] : this.getWithChildren(this.data.root);
    }

    getAllIncludedCouplers(): ConjunctionCoupler[] {
        return _.flatten(this.getAllIncludedPositions().map(p => p.dependees)).map(d => d.coupler).filter(c => c != undefined);
    }

    private getWithChildren(parent: ConjunctionPositionData): ConjunctionPositionData[] {
        let children = [];
        parent.dependees.forEach(side => side.children.forEach(c => children.push(...this.getWithChildren(c.data))));
        return [parent, ...children];
    }

    calculateSvgCoordsFromMouseEvent(event: MouseEvent): Point {
        let svg = <SVGSVGElement><any>this.svg.node;
        let point = svg.createSVGPoint();
        point.x = event.clientX;
        point.y = event.clientY;
        point = point.matrixTransform(svg.getScreenCTM().inverse());
        return new Point(point.x, point.y);
    }

    createSvgObjectURL(svg: string): SafeUrl {
        let blob = new Blob([svg], {type: 'image/svg+xml'});
        return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(blob));
    }

    ngOnDestroy(): void {
        this.sidenavController.show();
        this.hotkeyService.remove(this.enterHotkey);
        this.hotkeyService.remove(this.escapeHotkey);
        this.hotkeyService.remove(this.undoHotkey);
        this.hotkeyService.remove(this.redoHotkey);
        this.hotkeyService.remove(this.deleteHotkey);
    }

    exit(): void {
        if (this.dataChanged()) {
            this.showExitWithoutSavingConfirmationDialog = true;
        } else {
            this.showExitWithoutSavingConfirmationDialog = false;
            this.navigateToPositionList();
        }
    }

    private dataChanged(): boolean {
        return this.originalData !== JSON.stringify(this.data);
    }

    exitWithoutSaving(): void {
        this.navigateToPositionList();
    }

    navigateToPositionList(): void {
        const matrixParams = {..._.omit(this.route.parent.snapshot.params, ['offerId'])};
        this.router.navigate(['../..', matrixParams], {relativeTo: this.route});
    }

    save() {
        if (!this.validate()) {
            return;
        }
        this.blockUiController.block(ConjunctionEditorComponent.BLOCK_SOURCE_ID);
        this.conjunctionDto.data = JSON.stringify(this.data);
        this.conjunctionDto.svg = this.getSvg();
        this.conjunctionService.save(this.conjunctionDto, this.offer.id)
            .pipe(finalize(() => this.hideUiBlock()))
            .subscribe({
                next: () => {
                    this.navigateToPositionList();
                }, error: error => {
                    this.errors.handle(error, true);
                }
            });
    }

    private getSvg(): string {
        return new XMLSerializer().serializeToString(document.getElementById('drawing-svg'));
    }

    validate(): boolean {
        let positions = this.getAllIncludedPositions();
        return this.data.root != null && !this.positionToBeAdded && positions.length > 1 && positions.every(p => p.id != undefined);
    }

    elementsSelected(type: ConjunctionElementType): boolean {
        return this.selectedElements.some(e => e.data(type) != undefined);
    }

    canChangeCoupler(): () => boolean {
        return () => this.selectedElements.some(e => e.data(ConjunctionElementType.COUPLER) != undefined);
    }

    openChangeCouplerDialog(): void {
        let selectedConnectors = this.selectedElements.map(e => e.data(ConjunctionElementType.COUPLER)).filter(e => e != undefined);
        if (selectedConnectors.length === 1) {
            this.selectedConnector = selectedConnectors[0];
            this.selectedConnectorChange = JSON.parse(JSON.stringify(selectedConnectors[0]));
            this.showChangeCouplerDialog = true;
        }
    }

    deleteSelected(): void {
        let selected: ConjunctionPositionData[] = this.selectedElements.map(e => e.data(ConjunctionElementType.POSITION))
                                                      .filter(p => p != undefined);
        if (selected.length > 0) {
            this.history.saveOperation(this.data);
            while (selected.length > 0) {
                selected.sort(s => (s === this.data.root || s.dependees.length === 0) ? -1 : 1);
                this.deletePosition(selected.shift());
            }
            this.selectedElements = [];
            this.recheckEverything();
            this.paint();
        }
    }

    deletePosition(positionData: ConjunctionPositionData): void {
        let childrenCount = positionData.dependees.reduce((total, dep) => total + dep.children.length, 0);
        if (childrenCount === 0) {
            if (positionData === this.data.root) {
                this.data.root = undefined;
            } else {
                let parent = this.findParent(positionData);
                let dependee = parent.dependees.find(d => d.children.some(c => c.data.generatedId === positionData.generatedId));
                dependee.children.splice(dependee.children.findIndex(c => c.data.generatedId === positionData.generatedId), 1);
                if (dependee.children.length === 0) {
                    parent.dependees.splice(parent.dependees.findIndex(d => d === dependee), 1);
                }
            }
        } else if (childrenCount === 1 && positionData === this.data.root) {
            this.data.root = positionData.dependees.shift().children.shift().data;
        } else {
            positionData.id = undefined;
        }
    }

    findParent(positionData: ConjunctionPositionData): ConjunctionPositionData {
        return this.getAllIncludedPositions()
                   .find(p => p.dependees.some(d => d.children.some(c => c.data.generatedId === positionData.generatedId)));
    }

    private hideUiBlock(): void {
        this.blockUiController.unblock(ConjunctionEditorComponent.BLOCK_SOURCE_ID);
    }

    toggleSidebar(): void {
        this.paint();
        this.showSidebar = !this.showSidebar;
    }

    public onPositionClick(position: ParsedConjunctionPosition): void {
        this.resetSelectedElements();
        this.positionToBeAdded = this.positionToBeAdded === position ? null : position;
        this.paint();
        this.refreshToolButtons();
    }

    onConnectorChange(connectorId: number, inDialog = false): void {
        if (inDialog) {
            this.selectedConnectorChange.id = connectorId;
        } else {
            this.connectorToAddId = connectorId;
        }
        this.onConnectorWidthChange(connectorId ? this.catalogData.connectors.find(c => c.id === connectorId).width : 1, inDialog);
    }

    onConnectorWidthChange(width: number, inDialog = false): void {
        if (inDialog) {
            this.selectedConnectorChange.width = width;
        } else {
            this.connectorToAddWidth = width;
            if (this.positionToBeAdded) {
                this.paint();
            }
        }
    }

    changeSelectedConnector(): void {
        this.history.saveOperation(this.data);
        this.selectedConnector.id = this.selectedConnectorChange.id;
        this.selectedConnector.width = this.selectedConnectorChange.width;
        this.showChangeCouplerDialog = false;
        this.refreshIncludedElements();
        this.paint();
    }

    redoLast(): void {
        this.data = this.history.redo(this.data);
        this.recheckEverything();
        this.paint();
    }

    undoLast(): void {
        this.data = this.history.undo(this.data);
        this.recheckEverything();
        this.paint();
    }

    cancelMode(resetSelectedElements = false): void {
        if (resetSelectedElements) {
            this.resetSelectedElements();
        }
        this.positionToBeAdded = undefined;
        this.paint();
    }

    recheckEverything(): void {
        this.refreshToolButtons();
        this.filterAvailableConnectors();
        this.filterAvailablePositionsAndSuppliers();
        this.refreshIncludedElements();
        this.drawingToolsControl.markForCheck();
    }

    refreshToolButtons(): void {
        this.canUseDeleteTool = this.elementsSelected(ConjunctionElementType.POSITION) && !this.positionToBeAdded;;
        this.canUseChangeCouplerTool = this.elementsSelected(ConjunctionElementType.COUPLER);
        this.canSaveExit = this.validate();
    }
}
