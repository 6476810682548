import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {SupportedLanguages} from '../../../../supportedLanguages';
import {Template} from '../template';

@Component({
    selector: 'app-template-form',
    templateUrl: './template-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateFormComponent {

    supportedLanguages = SupportedLanguages.languages;

    @Input() item: Template;
    @Input() templateTypeOptions: SelectItem[];
    @Input() validationErrors: any;
}
