import {JsonDeserializable} from './crud-common/crudItem';

@JsonDeserializable
export class Contact {
    name: string;
    phone: string;
    email: string;
    fax: string;

    static fromJSON(jsonObject: any): Contact {
        const contact = new Contact();
        contact.name = jsonObject.name;
        contact.phone = jsonObject.phone;
        contact.email = jsonObject.email;
        contact.fax = jsonObject.fax;
        return contact;
    }
}
