<div class="new-form-600">
    <div class="new-form-row">
        <app-input-text inputId="name" label="{{ 'PRICE_LISTS_DEFINITION.FORM.NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['name']" [maxlength]="100" [required]="true"
                        [disabled]="!canEdit" [(ngModel)]="pricelist.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="validFrom" label="{{ 'PRICE_LISTS_DEFINITION.FORM.VALID_FROM' | translate }}"
                      [(validationMessageKey)]="validationErrors['validFrom']" [inline]="true"
                      [minDate]="minValidFromDate" [showTime]="true" [(ngModel)]="pricelist.validFrom"
                      [disabled]="!canEdit || !canEditDate"></app-calendar>
    </div>
    <div *ngIf="!isPricetableGenerationFailed(pricelist)" class="new-form-row">
        <app-checkbox inputId="status" label="{{ 'PRICE_LISTS_DEFINITION.FORM.ACTIVE' | translate }}"
                      [disabled]="!canEdit" [ngModel]="getActiveCheckboxState(pricelist)"
                      (ngModelChange)="handleActiveCheckboxChange(pricelist, $event)"></app-checkbox>
    </div>
    <div *ngIf="isPricetableGenerationFailed(pricelist)" class="new-form-field-error-message">
        {{ 'PRICE_LISTS_DEFINITION.FORM.GENERATION_FAILED_MESSAGE' | translate }}
    </div>
</div>
