export class MotlawaIntegrationData {
    csvData: string;
    comparedCsvData: string;
    csvDelimiter: string;
    priceTotal: string;
    successfulSendingDate: Date;
    packagePermanentInMotlawa: boolean;
    packageUuid: string;

    static fromJSON(jsonObject: any): MotlawaIntegrationData {
        const dto = new MotlawaIntegrationData();
        dto.csvData = jsonObject.csvData;
        dto.comparedCsvData = jsonObject.comparedCsvData;
        dto.csvDelimiter = jsonObject.csvDelimiter;
        dto.priceTotal = jsonObject.priceTotal;
        dto.successfulSendingDate = jsonObject.successfulSendingDate ? new Date(jsonObject.successfulSendingDate) : undefined;
        dto.packagePermanentInMotlawa = jsonObject.packagePermanentInMotlawa;
        dto.packageUuid = jsonObject.packageUuid;
        return dto;
    }
}
