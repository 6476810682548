<p-dialog [resizable]="false" [visible]="true" [modal]="true" (onHide)="cancel()" [focusOnShow]="false">

    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.REMOVE_POSITION.TITLE' | translate }}
        </div>
    </p-header>

    <div class="new-form-600">
        <div *ngIf="areParentsSelected" class="warning-message">
            <p>{{'OFFER.POSITIONS.DIALOGS.REMOVE_POSITION.CONFIG_ADDONS_WARNING' | translate}}</p>
        </div>
        <div>
            <p>{{'OFFER.POSITIONS.DIALOGS.REMOVE_POSITION.CONFIRM' | translate}}</p>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button *ngIf="areParentsSelected" buttonId="removeWithChildrenButton"
                               label="{{ 'OFFER.POSITIONS.DIALOGS.REMOVE_POSITION.CONFIRM_WITHOUT_ADDONS' | translate }}"
                               type="main-action" [size]="40" [disabled]="deleteInProgress"
                               (onClick)="removeConfirmed()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               buttonId="removePositionWithAddonsButton"
                               [disabled]="deleteInProgress"
                               (onClick)="removeWithChildrenConfirmed()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               [disabled]="deleteInProgress"
                               (onClick)="cancel()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
