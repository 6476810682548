import {TranslateService} from '@ngx-translate/core';

export enum DropDownExtraOptionsLabels {
    OPTION_STANDARD = 'GENERAL.OPTION_STANDARD',
    DO_NOT_CHANGE = 'OFFER.POSITIONS.DROP_DOWN_OPTIONS.DO_NOT_CHANGE',
    CLEAN_OLD_VALUE = 'OFFER.POSITIONS.DROP_DOWN_OPTIONS.CLEAN_OLD_VALUE'
}

export class DropDownExtraOptionsProvider {

    constructor(private translate: TranslateService) {
    }

    getDoNotChangeLabel(): string {
        return this.getLabel(DropDownExtraOptionsLabels.DO_NOT_CHANGE);
    }

    getLabel(labelKey: DropDownExtraOptionsLabels): string {
        return this.translate.instant(labelKey);
    }
}
