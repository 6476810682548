<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.RACKS.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.RACK.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.RACK.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="rackTable">

        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(RackField.NAME)"
                          field="name" header="{{ 'RACK.FORM.NAME' | translate }}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.WEIGHT)"
                          field="weight" header="{{ 'RACK.FORM.WEIGHT' | translate}}" [rowspan]="2" [sortable]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.NET_EURO_PRICE)"
                          field="netEuroPrice" header="{{ 'RACK.FORM.NET_EURO_PRICE' | translate}}" [rowspan]="2" [sortable]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.SLOTS)"
                          field="slots" header="{{ 'RACK.FORM.SLOTS' | translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains"></p-column>
                <p-column header="{{'RACK.FORM.TOTAL_DIMENSIONS'|translate}}" [colspan]="3"></p-column>
                <p-column header="{{'RACK.FORM.USABLE_DIMENSIONS'|translate}}" [colspan]="3"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" [rowspan]="2" field="active" header="{{ 'RACK.FORM.STATUS' | translate}}"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(RackField.TOTAL_WIDTH)"
                          field="totalWidth" header="{{ 'RACK.FORM.WIDTH' | translate}}" [sortable]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.TOTAL_DEPTH)"
                          field="totalDepth" header="{{ 'RACK.FORM.DEPTH' | translate}}" [sortable]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.TOTAL_HEIGHT)"
                          field="totalHeight" header="{{ 'RACK.FORM.HEIGHT' | translate}}" [sortable]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.USABLE_WIDTH)"
                          field="usableWidth" header="{{ 'RACK.FORM.WIDTH' | translate}}" [sortable]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.USABLE_DEPTH)"
                          field="usableDepth" header="{{ 'RACK.FORM.DEPTH' | translate}}" [sortable]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(RackField.USABLE_HEIGHT)"
                          field="usableHeight" header="{{ 'RACK.FORM.HEIGHT' | translate}}" [sortable]="true"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <p-column *ngIf="fieldUsage.show(RackField.NAME)" field="name">
            <ng-template let-rack="rowData" pTemplate="body">{{rack.name[userLang]}}</ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(RackField.WEIGHT)" field="weight"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.NET_EURO_PRICE)" field="netEuroPrice"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.SLOTS)" field="slots"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.TOTAL_WIDTH)" field="totalWidth"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.TOTAL_DEPTH)" field="totalDepth"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.TOTAL_HEIGHT)" field="totalHeight"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.USABLE_WIDTH)" field="usableWidth"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.USABLE_DEPTH)" field="usableDepth"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.USABLE_HEIGHT)" field="usableHeight"></p-column>
        <p-column *ngIf="fieldUsage.show(RackField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" [rowspan]="2" field="active" header="{{ 'RACK.FORM.STATUS' | translate}}"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-rack="rowData" pTemplate="body">
                {{(rack.active ? 'RACK.FORM.ACTIVE' : 'RACK.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.RACK.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">

    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-rack-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-rack-form>
    </app-wizard-step>

</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog && isPermitted({roles:['ROLE_KOORDYNATOR']})"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.RACK.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.RACK.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-rack-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-rack-form>
    </app-wizard-step>

</app-wizard-dialog>
