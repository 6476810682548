import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {HotkeysService} from "angular2-hotkeys";
import {MenuItem} from 'primeng/api/menuitem';
import {forkJoin, of, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {WindowAddonData} from '../../../../../../../window-designer/drawing-data/WindowAddonData';
import {WindowDimensions} from '../../../../../../../window-designer/entities/window-dimensions';
import {AddonCategoryEnum} from "../../../../../../../window-designer/enums/AddonCategoryEnum";
import {StaticDataHelper} from '../../../../../../../window-designer/static-data-helper';
import {StorageKey, StorageService} from '../../../../../../auth/storage.service';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {ExchangeService} from '../../../../../../common/exchange.service';
import {TabbedComponent} from "../../../../../../common/TabbedComponent";
import {Currencies} from '../../../../../../currencies';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {SubsystemService} from '../../../../../subsystem/subsystem.service';
import {AddonCategoryCount} from '../../../../../window-system/addons/addon';
import {AddonsService} from "../../../../../window-system/addons/addons.service";
import {
    AddonPositionComponent,
    WindowAddonSaveData
} from "../../../../offers/position/position-list/add-bulk-addon-position/addon-position/addon-position.component";
import {SystemType} from "../../../../../../common/enums/SystemType";
import {AddonCategory} from "../../../../../window-system/addon-category/addon-category";

@Component({
    selector: 'app-addons-tab-view',
    templateUrl: './addons-tab-view.component.html',
    styleUrls: ['./addons-tab-view.component.css', '../../../../../../second-level-menu.css'],
    providers: [AddonsService, ExchangeService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddonsTabViewComponent extends TabbedComponent implements OnInit, OnDestroy {

    private static readonly HEADER_KEYS = [
        'OFFER.DRAWING.ADDONS.NAVIGATION.SELECTION_TAB',
        'OFFER.DRAWING.ADDONS.NAVIGATION.ADDED_TAB'
    ];

    availableCategories: string[];
    availableCustomCategories: AddonCategory[] = [];

    @ViewChildren(AddonPositionComponent) addonTabs: QueryList<AddonPositionComponent>;

    tabPanelsData: { index: number, name: string }[];
    tabPanelsReady = false;
    panelContentVisible: boolean[];
    selectedCategory: AddonCategoryEnum;
    selectedCategoryId: number;
    currentTabId = 0;
    validationErrors = {};
    subsystemDefaultCurrency: Currencies;

    @Output() onSubmit = new EventEmitter<any>();
    @Output() onRemove = new EventEmitter<any>();
    @Output() onClose = new EventEmitter();
    @Input() systemId: number;
    @Input() offerId: number;
    @Input() offerPositionId: number;
    @Input() windowAddonData: WindowAddonData;
    @Input() roofWindowsDimension: WindowDimensions;
    @Input() addedWindowAddons: any;
    @Input() sidebarOnlyMode: boolean;
    @Input() readOnlyMode: boolean;
    @Input() staticData: StaticDataHelper;
    @Input() systemType: SystemType = SystemType.WINDOW;

    selectAddonItem: MenuItem;
    addedAddonsItem: MenuItem;

    private componentDestroyed$: Subject<boolean> = new Subject<boolean>();

    private readonly dialogHideHelper = new OnceFlag();

    searchedName: string;
    searchedNameCategoryCounts: Map<string, number>;

    constructor(private addonsService: AddonsService,
                private translate: TranslateService,
                private storage: StorageService,
                private exchangeService: ExchangeService,
                private subsystemService: SubsystemService,
                hotkeysService: HotkeysService,
                changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
        super(hotkeysService, changeDetector);
    }

    ngOnInit() {
        this.panelContentVisible = [];
        this.tabPanelsData = [];
        this.availableCategories = [];
        forkJoin({
            translations: this.translate.get(AddonsTabViewComponent.HEADER_KEYS),
            addonCategories: this.systemType === SystemType.WINDOW ? this.addonsService.getCategories(this.systemId, this.systemType)
                : of<string[]>([]),
            addonCustomCategories: this.systemType === SystemType.GATE ? this.addonsService.getCustomCategories(this.systemId)
                : of<AddonCategory[]>([]),
            defaultCurrency: this.subsystemService.getDefaultCurrency(),
            exchangeRates: this.exchangeService.initializeExchangeRates()
        }).pipe(takeUntil(this.componentDestroyed$))
            .subscribe({
                next: data => {
                    this.initializeMenuItemsCallback(data.translations);
                    console.info('`loadAvailableCategories` success:', data.addonCategories);
                    if (data.addonCategories.length > 0) {
                        this.availableCategories = data.addonCategories;
                    } else {
                        this.availableCustomCategories = data.addonCustomCategories;
                        this.availableCustomCategories.forEach(category => {
                            this.availableCategories.push(category.name[this.translate.currentLang]);
                        });
                    }
                    this.createTabPanels();
                    this.subsystemDefaultCurrency = data.defaultCurrency;
                    this.exchangeService.storeExchangeRates(data.exchangeRates);
                },
                error: error => {
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('`loadAvailableCategories` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    handleChange(event: { index: number, name: string }) {
        if (this.addonTabs.some(tab => tab.validationErrorsPresent())) {
            return;
        }
        this.addonTabs.find(addonTab => addonTab.tabIndex === this.currentTabId).removeFromHotKeyService();
        this.panelContentVisible[event.index] = true;
        const selectedItem = event.name;
        this.selectedCategory = AddonCategoryEnum[selectedItem];
        if (this.availableCustomCategories.length > 0) {
            this.selectedCategoryId = this.availableCustomCategories.find(category =>
                category.name[this.translate.currentLang] === selectedItem).id;
        }
        this.validationErrors = {};

        const addonPositionComponent = this.addonTabs.find(tab => tab.tabIndex === event.index);
        if (addonPositionComponent != undefined) {
            addonPositionComponent.addSpaceToHotKeyService();
            this.changeDetector.detectChanges();
        }

        this.storage.set(StorageKey.LAST_WINDOW_EDITOR_ADDON_CATEGORY, AddonCategoryEnum[this.selectedCategory]);
        this.currentTabId = event.index;
        this.searchedNameCategoryCounts = undefined;
        this.changeDetector.markForCheck();
    }

    saveAddon(windowAddonSaveData: WindowAddonSaveData) {
        if (windowAddonSaveData.validation) {
            this.onSubmit.emit(windowAddonSaveData);
            this.updateAddonTabLabel();
        } else {
            if (this.addonTabs.some(tab => tab.validationErrorsPresent())) {
                this.validationErrors = this.addonTabs.find(tab => tab.validationErrorsPresent()).validationErrors;
            } else {
                this.validationErrors = this.addonTabs.find(addonTab => addonTab.tabIndex === this.currentTabId).validationErrors;
            }
            this.changeDetector.markForCheck();
        }
    }

    validationErrorsPresent() {
        return this.addonTabs && this.addonTabs.some(tab => tab.validationErrorsPresent());
    }

    removeAddon(addonId: any) {
        this.onRemove.emit(addonId);
        this.updateAddonTabLabel();
    }

    private updateAddonTabLabel() {
        this.addedAddonsItem.label =
            this.addedAddonsItem.label.replace(new RegExp('\\(\\d+\\)'), '(' + this.windowAddonData.addons.length + ')');
    }

    tableDataLoaded() {
        this.tabPanelsReady = true;
        this.changeDetector.markForCheck();
    }

    emitCloseDialog() {
        if (this.tabPanelsReady) {
            if (this.addonTabs && this.addonTabs.some(tab => tab.validationErrorsPresent())) {
                return;
            }
            this.dialogHideHelper.call(() => this.onClose.emit());
        }
    }

    private createTabPanels() {
        if (this.readOnlyMode || this.availableCategories.length === 0) {
            this.tabPanelsReady = true;
        } else {
            let lastCategoryIndex = this.availableCategories.indexOf(this.storage.get(StorageKey.LAST_WINDOW_EDITOR_ADDON_CATEGORY));
            if (lastCategoryIndex < 0) {
                lastCategoryIndex = 0;
            }
            this.availableCategories.forEach((category, tabIndex) => {
                if (this.tabPanelsData.findIndex(tab => tab.name === category) === -1) {
                    this.tabPanelsData.push({index: tabIndex, name: category});
                    this.panelContentVisible.push(tabIndex === lastCategoryIndex);
                }
            });
            this.panelContentVisible.push(false); // search
            this.selectedCategory = AddonCategoryEnum[this.availableCategories[lastCategoryIndex]];
            if (this.availableCustomCategories.length > 0) {
                this.selectedCategoryId = this.availableCustomCategories.find(category =>
                    category.name[this.translate.currentLang] === this.availableCategories[lastCategoryIndex]).id;
            }
            this.currentTabId = lastCategoryIndex;
        }
        this.changeDetector.markForCheck();
    }

    private initializeMenuItemsCallback(headersTranslations: { [key: string]: string }) {
        this.selectAddonItem = {
            id: "selectAddonItem",
            label: headersTranslations['OFFER.DRAWING.ADDONS.NAVIGATION.SELECTION_TAB'],
            disabled: this.readOnlyMode,
            command: () => {
                this.validationErrors = {};
                if (this.panelContentVisible.length === 0) {
                    this.createTabPanels();
                }
                this.activeTabIndex = 0;
                this.activeItem = this.selectAddonItem;
            }
        };
        this.addedAddonsItem = {
            id: "addedAddonsItem",
            label: headersTranslations['OFFER.DRAWING.ADDONS.NAVIGATION.ADDED_TAB'] + " (" + this.windowAddonData.addons.length + ")",
            command: () => {
                this.panelContentVisible = [];
                this.tabPanelsData = [];
                this.validationErrors = {};
                this.activeTabIndex = 1;
                this.activeItem = this.addedAddonsItem;
            }
        };
        this.items = [this.selectAddonItem, this.addedAddonsItem];
        this.selectTabByIndex(this.readOnlyMode ? 1 : 0);
    }

    getCategoryLabelTranslation(tabPanel) {
        return this.systemType === SystemType.WINDOW ? this.translate.instant('ADDONS.CATEGORIES.' + tabPanel.name) : tabPanel.name;
    }

    handleSearch(name: string): void {
        if (!!name) {
            this.searchedName = name;
            this.currentTabId = this.tabPanelsData.length;
            this.panelContentVisible[this.tabPanelsData.length] = true;
        }
    }

    handleSearchedNameCategoryResults(searchedNameCategoryResults: AddonCategoryCount[]): void {
        this.searchedNameCategoryCounts = new Map<AddonCategoryEnum, number>();
        for (let searchedNameCategoryResult of searchedNameCategoryResults) {
            if (searchedNameCategoryResult.addonCategoryId != undefined) {
                const addonCategory = this.availableCustomCategories.find(cc => cc.id === searchedNameCategoryResult.addonCategoryId);
                if (addonCategory != undefined) {
                    this.searchedNameCategoryCounts.set(addonCategory.name[this.translate.currentLang], searchedNameCategoryResult.count);
                }
            } else {
                this.searchedNameCategoryCounts.set(searchedNameCategoryResult.category as string, searchedNameCategoryResult.count);
            }
        }
    }
}
