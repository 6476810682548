import {Injectable} from '@angular/core';
import {Subject} from "rxjs";
import {GrowlMessage} from "./growl-message";
import {MessageParams} from "./MessageParams";

@Injectable()
export class GrowlMessageController {

    newGrowl: Subject<GrowlMessage> = new Subject<GrowlMessage>();

    private showGrowl(severity: string, summary: string, detail: string, params?: MessageParams, sticky?: boolean): void {
        this.show(new GrowlMessage(severity, summary, detail, params, sticky));
    }

    public show(message: GrowlMessage): void {
        this.newGrowl.next(message);
    }

    public info(detail: string, params?: MessageParams, sticky?: boolean): void {
        this.showGrowl('info', "GENERAL.INFO", detail, params, sticky);
    }

    public warning(detail: string, params?: MessageParams, sticky?: boolean): void {
        this.showGrowl('warn', "GENERAL.WARN", detail, params, sticky);
    }

    public error(detail: string, params?: MessageParams, sticky?: boolean): void {
        this.showGrowl('error', "GENERAL.ERROR", detail, params, sticky);
    }

    public blocker(detail: string, params?: MessageParams): void {
        this.showGrowl('error', "GENERAL.BLOCKER", detail, params, true);
    }

    public clearStickies(): void {
        this.showGrowl('clear_stickies', '', '');
    }
}
