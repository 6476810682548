import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConfigAddonApplication} from '../../../../../window-designer/enums/ConfigAddonApplication';
import {CatalogItemName} from '../../../../common/crud-common/catalog-item-name';
import {mapItemToJson} from '../../../../common/crud-common/crudItem';
import {Listing} from '../../../../common/crud-common/crudItemList';
import {DataServiceHelper, FileState} from '../../../../common/dataServiceHelper';
import {MotlawaIntegrationInfo} from "../../../../common/MotlawaIntegrationInfo";
import {MultilanguageField} from '../../../../supportedLanguages';
import {SingleSystemCheckboxCrudService} from '../../../window-system/single-system-checkbox-crud/single-system-checkbox-crud.service';
import {ProfitMarginExistance} from '../../window-editor/drawing-tool/ProfitMarginExistance';
import {ConfigurableAddonDefinition} from './position-list/ConfigurableAddonModel/ConfigurableAddonDefinition';
import {ConfigurableAddonDefinitionRoDto} from './position-list/ConfigurableAddonModel/ConfigurableAddonDefinitionRoDto';
import {ConfigurableAddonDefinitionSettings} from './position-list/ConfigurableAddonModel/ConfigurableAddonDefinitionSettings';
import {ConfigurableAddonType} from './position-list/ConfigurableAddonModel/ConfigurableAddonType';

@Injectable()
export class ConfigurableAddonsService extends SingleSystemCheckboxCrudService<ConfigurableAddonDefinitionRoDto> {

    static readonly API_URL = 'configurableaddons';

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getDefinitions(active?: boolean, windowSystemId?: number): Observable<Listing<ConfigurableAddonDefinitionRoDto>> {
        let params = {};
        if (active != undefined) {
            params['active'] = '' + active;
        }
        if (windowSystemId != undefined) {
            params['windowSystemId'] = '' + windowSystemId;
        }
        return this.http.get<Listing<object>>(`${ConfigurableAddonsService.API_URL}/list`, {params: params}).pipe(
            map(response => Listing.fromJSON(ConfigurableAddonDefinitionRoDto, response)));
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<ConfigurableAddonDefinitionRoDto>> {
        return this.getDefinitions();
    }

    getDefinition(definitionId: number): Observable<ConfigurableAddonDefinition> {
        return this.http.get<object>(`${ConfigurableAddonsService.API_URL}/${definitionId}`)
            .pipe(mapItemToJson(ConfigurableAddonDefinition));
    }

    getItem(itemId: number): Observable<ConfigurableAddonDefinitionRoDto> {
        return this.http.get<ConfigurableAddonDefinitionRoDto>(`${ConfigurableAddonsService.API_URL}/${itemId}`);
    }

    updateDefinitionSettings(settings: ConfigurableAddonDefinitionSettings, file: File,
                             descriptionImageLarge: File, motlawaInfo: MotlawaIntegrationInfo): Observable<ConfigurableAddonDefinition> {
        let body = JSON.stringify(settings);
        let formData = new FormData();
        formData.append('ConfigurableAddonDefinitionSettingsDto', new Blob([body], {
            type: 'application/json'
        }));
        if (motlawaInfo != undefined) {
            let motlawaIntegrationInfo = JSON.stringify(motlawaInfo);
            formData.append('MotlawaIntegrationInfo', new Blob([motlawaIntegrationInfo], {
                type: 'application/json'
            }));
        }
        if (file != undefined) {
            formData.append('file', file);
        }
        if (descriptionImageLarge != undefined) {
            formData.append('descriptionImageLarge', descriptionImageLarge);
        }

        return this.http.post<object>(ConfigurableAddonsService.API_URL, formData)
            .pipe(mapItemToJson(ConfigurableAddonDefinition));
    }

    getImageForItem(configAddonDefId: number): Observable<string> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageForItemAsFile(configAddonDefId: number): Observable<File> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getDescriptionImageForItem(configAddonDefId: number): Observable<string> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/descriptionImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getDescriptionImageForItemAsFile(configAddonDefId: number): Observable<File> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/descriptionImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getDescriptionImageLargeForItem(configAddonDefId: number): Observable<string> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/descriptionImageLarge`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getDescriptionImageLargeForItemAsFile(configAddonDefId: number): Observable<File> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/descriptionImageLarge`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    addItem(item: ConfigurableAddonDefinitionRoDto): Observable<number> {
        // not supported
        return undefined;
    }

    editItem(itemId: number, item: ConfigurableAddonDefinitionRoDto): Observable<number> {
        // not supported
        return undefined;
    }

    getGlamourDescription(configAddonDefId: number): Observable<MultilanguageField> {
        return this.http.get<MultilanguageField>(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/glamourDescription`);
    }

    getMotlawaInfo(configAddonDefId: number): Observable<MotlawaIntegrationInfo> {
        return this.http.get<MotlawaIntegrationInfo>(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/motlawaInfo`);
    }

    getGlamourHorizontalPrintImageAsFile(configAddonDefId: number): Observable<File> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/glamourHorizontalPrintImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getGlamourVerticalPrintImageAsFile(configAddonDefId: number): Observable<File> {
        return this.http.get(`${ConfigurableAddonsService.API_URL}/${configAddonDefId}/glamourVerticalPrintImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    updateAddonGlamourPrintInfo(configurableDefinitionId: number, horizontalPrintImage: FileState,
                                verticalPrintImage: FileState, glamourDescription: MultilanguageField): Observable<void> {
        const formData = new FormData();
        if (this.dataServiceHelper.isFileSaveNeeded(horizontalPrintImage)) {
            formData.append('horizontalPrintImage', horizontalPrintImage.file);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(verticalPrintImage)) {
            formData.append('verticalPrintImage', verticalPrintImage.file);
        }
        let body = JSON.stringify(glamourDescription);
        formData.append('glamourDescription', new Blob([body], {type: 'application/json'}));
        return this.http.put<void>(`${ConfigurableAddonsService.API_URL}/${configurableDefinitionId}/glamourPrintInfo`, formData);
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${ConfigurableAddonsService.API_URL}/names`, {params: params});
    }
}
