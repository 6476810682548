<ng-template #defaultLabel>
    <div class="new-form-field-label-container">
        <div>
            <label [attr.for]="inputId">{{ label }}<ng-container *ngIf="required && !disabled">*</ng-container></label>
        </div>

        <div *ngIf="validationMessageKey != undefined" class="new-form-field-label-error-spacer"></div>
    </div>
</ng-template>
<ng-template #defaultValidationMessage>
    <div class="new-form-field-error-message">
        {{ validationMessageKey | translate }}
    </div>
</ng-template>
<div #container class="new-form-field">
    <ng-container *ngTemplateOutlet="labelTemplate; context: { label: label, inputId: inputId }"></ng-container>
    <div class="new-form-field-input-container" *vLet="displayInches | async as showInches">
        <input *ngIf="!showInches" type="number" [attr.id]="inputId" [disabled]="disabled"
               [attr.placeholder]="placeholder" [attr.min]="min" [attr.max]="max" [attr.step]="step || 'any'"
               [ngModel]="value" (ngModelChange)="handleValueChange($event)" [ngModelOptions]="modelOptions"
               (focus)="handleFocus($event)" (blur)="handleBlur($event)">
        <div *ngIf="showInches" class="inches-container">
            <input class="full-inches" type="number" [attr.id]="inputId" [disabled]="disabled"
                   [attr.placeholder]="placeholder" [attr.min]="inchesMin" [attr.max]="inchesMax" [attr.step]="1"
                   [ngModel]="inches?.full" (ngModelChange)="handleInchesChange($event)"
                   [ngModelOptions]="modelOptions" (focus)="handleFocus($event)" (blur)="handleBlur($event)">
            <input class="sixteenths" type="number" [attr.id]="inputId + '_sixteenths'" [disabled]="disabled"
                   [attr.placeholder]="placeholder" [attr.min]="0" [attr.max]="15" [attr.step]="1"
                   [ngModel]="inches?.sixteenths" (ngModelChange)="handleInchFractionChange($event)"
                   [ngModelOptions]="modelOptions" (focus)="handleFocus($event)" (blur)="handleBlur($event)">
            <label class="sixteenths-label">16th</label>
        </div>
        <ng-container *ngIf="validationMessageKey != undefined || hasValidationError">
            <span class="new-form-field-error-icon material-icons">error</span>
            <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
        </ng-container>
    </div>
</div>
