<p-dialog #dialog header="{{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_SYSTEM_DEALER_DISCOUNT' | translate }}"
          [resizable]="false" [modal]="true" [visible]="visible" [focusOnShow]="false"
          (visibleChange)="handleVisibleChange($event)">
    <p-dataTable [seqNum]="1" [paginator]="true" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [responsive]="true" [(selection)]="selectedDealerDiscountValue" [editable]="true" [immutable]="true"
                 (onFilter)="handleFilter($event)" dataKey="windowSystemId" [totalRecords]="totalRecords"
                 [value]="dealerDiscountValues">
        <p-column field="windowSystemName.{{ translate.currentLang }}"
                  header="{{ 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate }}" [filter]="true"
                  filterMatchMode="contains" [style]="{'min-width.px': 200}"></p-column>
        <p-column header="{{ 'DEALER_DISCOUNTS.FORM.VALUE' | translate }}" [editable]="true"
                  [style]="{'width.px': 400}">
            <ng-template let-value="rowData" pTemplate="body">
                <span class="editable-cell-value-span">{{ value.discountValue }}</span>
                <span class="material-icons editable-cell-value-span-icon"></span>
                <span *ngIf="value.error" class="material-icons discount-validation-error-icon">error</span>
            </ng-template>
            <ng-template pTemplate="editor" let-value="rowData">
                <div class="new-form-row" style="width: 384px">
                    <app-input-text [inputId]="value.category" [(validationMessageKey)]="value.error"
                                    [(ngModel)]="value.discountValue">
                        <ng-template pTemplate="label"></ng-template>
                    </app-input-text>
                </div>
                <div class="new-form-row">
                    <app-checkbox
                            label="{{ 'OFFER.PRODUCTION_ORDERS.CHANGE_SYSTEM_DEALER_DISCOUNT.CASCADE' | translate }}"
                            [(ngModel)]="value.cascade"></app-checkbox>
                </div>
            </ng-template>
        </p-column>
    </p-dataTable>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="saveSystemDealerDiscountButton" label="{{ 'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40" (onClick)="submit(dialog, $event)"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="dialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
