import {RandomIdGenerator} from "../utils/RandomIdGenerator";

export class Guide {
    generatedId: string;
    subwindowGeneratedId: string;
    position: number;
    size: number;
    locked: boolean;

    constructor(position: number) {
        this.generatedId = RandomIdGenerator.generate();
        this.subwindowGeneratedId = undefined;
        this.position = position;
        this.size = null;
        this.locked = false;
    }
}
