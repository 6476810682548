import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatGlazingWidths'
})
export class FormatGlazingWidthsPipe implements PipeTransform {

  transform(currentGlazingWidth: number, glazingWidths: string): string {
      let widths = (Math.round(currentGlazingWidth * 10) / 10);
      if (Number.isNaN(widths)) {
          return "0";
      }
      let widthsString = widths.toString();
      if (glazingWidths != undefined) {
          widthsString += ' [ ' + glazingWidths.replace(/\./g, ',').split(';').join(' | ') + ' ]';
      }
      return widthsString;
  }

}
