import {PriceWithTax} from '../../../offer';
import {MessageSeverity, PositionMessage} from '../../../offers/message';
import {Product} from './Product';

export class Pricing {
    venskaBuyPrice: PriceWithTax;
    buyPrice: PriceWithTax;
    sellPrice: PriceWithTax;
    retailSellPrice: PriceWithTax;
    products: Product[] = [];
    validationMessages: PositionMessage[] = [];
    pricingExceptionMessages: PositionMessage[] = [];

    public static containsBlockers(pricing: Pricing) {
        return Pricing.containsForValidation(pricing, MessageSeverity.BLOCKER)
            || Pricing.containsForPricing(pricing, MessageSeverity.BLOCKER);
    }

    public static containsErrors(pricing: Pricing, isPricingCurrentlySelectedTab?: boolean) {
        return Pricing.containsForValidation(pricing, MessageSeverity.ERROR)
            || Pricing.containsForPricing(pricing, MessageSeverity.ERROR)
            || (isPricingCurrentlySelectedTab && pricing.sellPrice == null);
    }

    public static containsWarnings(pricing: Pricing) {
        return Pricing.containsForValidation(pricing, MessageSeverity.WARNING)
            || Pricing.containsForPricing(pricing, MessageSeverity.WARNING);
    }

    public static containsInfo(pricing: Pricing) {
        return Pricing.containsForValidation(pricing, MessageSeverity.INFO)
            || Pricing.containsForPricing(pricing, MessageSeverity.INFO);
    }

    public static containsForValidation(pricing: Pricing, type: MessageSeverity) {
        return Pricing.containsCheckProductMessages(pricing.products, type, true)
            || Pricing.containsCheckMessageArray(type, pricing.validationMessages);
    }

    public static containsForPricing(pricing: Pricing, type: MessageSeverity) {
        return Pricing.containsCheckProductMessages(pricing.products, type, false)
            || Pricing.containsCheckMessageArray(type, pricing.pricingExceptionMessages);
    }

    private static containsCheckMessageArray(type: MessageSeverity, messageArray: PositionMessage[]) {
        for (let message of messageArray) {
            if (message.severity === type) {
                return true;
            }
        }
        return false;
    }

    private static containsCheckProductMessages(products: Product[], type: string, isValidationMessage: boolean) {
        for (let product of products) {
            for (let message of product.messages) {
                if (message.severity === type && message.validationMessage === isValidationMessage) {
                    return true;
                }
            }
        }

        return false;
    }

    public static getAllMessages(pricing: Pricing): PositionMessage[] {
        let result: PositionMessage[] = [];
        if (pricing.validationMessages) {
            result.push(...pricing.validationMessages);
        }
        for (let product of pricing.products) {
            for (let message of product.messages) {
                result.push(message);
            }
        }
        return result;
    }

    public static collectProductMessagesofType(pricing: Pricing, severity: MessageSeverity): PositionMessage[] {
        return pricing.products.reduce((messages, product) => {
            return messages.concat(product.messages.filter(message => message.severity === severity));
        }, []);
    }
}
