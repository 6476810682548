<p-dialog #dialog [resizable]="false" [(visible)]="showDialog" (onHide)="emitDialogResult()"
          class="dialog-window" [modal]="true" [baseZIndex]="1" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title dialog-header">
                {{ 'MISSING_PROFIT_MARGIN_HANDLER.TITLE' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-600 dialog-content">
        <div class="new-form-600">
            <!-- SUBSYSTEM -->
            <ng-container *ngIf="canEdit.subsystem">
                <h3 class="new-form-row">
                    {{ 'MISSING_PROFIT_MARGIN_HANDLER.INFO.SUBSYSTEM' | translate:{
                    group: subsystem.subsystemGroupName,
                    subsystem: subsystem.name
                } }}
                </h3>
                <h3 class="new-form-row">
                    <app-input-text inputId="subsystemValueInput" label="{{ 'MISSING_PROFIT_MARGIN_HANDLER.BODY.SUBSYSTEM' | translate: {
                    target: targetKey | translate,
                    targetName: profitMarginTargetName | multilanguageTranslate
                } }}" [(validationMessageKey)]="validationErrors['profitMarginValue']"
                                    [(ngModel)]="profitMarginValue"></app-input-text>
                </h3>
            </ng-container>

            <!-- CLIENT -->
            <ng-container *ngIf="canEdit.client">
                <h3 class="new-form-row">
                    {{ 'MISSING_PROFIT_MARGIN_HANDLER.INFO.CLIENT' | translate:{
                    group: client.groupName,
                    client: client.name
                } }}
                </h3>
                <h3 class="new-form-row">
                    {{ 'MISSING_PROFIT_MARGIN_HANDLER.BODY.CLIENT' | translate: {
                    target: targetKey | translate,
                    targetName: (clientGroupSimpleProfitMargins ? profitMarginTargetSupplierName : profitMarginTargetName) | multilanguageTranslate
                } }}
                </h3>
                <div class="new-form-row">
                    <app-input-text inputId="clientValueInput" label="{{ 'MISSING_PROFIT_MARGIN_HANDLER.INPUT_LABEL' | translate }}" [(validationMessageKey)]="validationErrors['profitMarginValue']"
                                    [(ngModel)]="profitMarginValue"></app-input-text>
                </div>
            </ng-container>

            <!-- SELLER CLIENT -->
            <ng-container *ngIf="canEdit.sellerClient">
                <h3 class="new-form-row">
                    {{ 'MISSING_PROFIT_MARGIN_HANDLER.INFO.CLIENT' | translate:{
                    group: sellerClient.groupName,
                    client: sellerClient.name
                } }}
                </h3>
                <h3 class="new-form-row">
                    {{ 'MISSING_PROFIT_MARGIN_HANDLER.BODY.CLIENT' | translate: {
                    target: targetKey | translate,
                    targetName: (clientGroupSimpleProfitMargins ? profitMarginTargetSupplierName : profitMarginTargetName) | multilanguageTranslate
                } }}
                </h3>
                <div class="new-form-row">
                    <app-input-text inputId="sellerClientValueInput" label="{{ 'MISSING_PROFIT_MARGIN_HANDLER.INPUT_LABEL' | translate: {
                    target: targetKey | translate,
                    targetName: (sellerClientGroupSimpleProfitMargins ? profitMarginTargetSupplierName : profitMarginTargetName) | multilanguageTranslate
                } }}" [(validationMessageKey)]="validationErrors['profitMarginValue']"
                                    [(ngModel)]="profitMarginValue"></app-input-text>
                </div>
            </ng-container>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-save" [label]="'GENERAL.SAVE' | translate" type="main-action"
                               [disabled]="profitMarginValue == undefined || profitMarginValue == ''"
                               [size]="40" (onClick)="handleProfitMarginSaveClick($event)"></app-simple-button>
            <app-simple-button buttonId="dialog-cancel" [label]="'GENERAL.CANCEL' | translate" type="cancel" [size]="40"
                               (onClick)="handleProfitMarginCancelClick($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
