<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                               icon="import_export" type="action"
                               (onClick)="exportCSV()"></app-simple-button>
        </div>
    </div>

    <p-treeTable [value]="offerCountsTreeData" selectionMode="single" [(selection)]="selected"
                 (onNodeSelect)="nodeSelected()">
        <ng-template pTemplate="header">
            <tr>
                <th ttSortableColumn="groupName">{{ 'ADMIN_PANEL.OFFER_SUBSYSTEM_COUNTER.FORM.SUBSYSTEM' | translate }}
                    <p-treeTableSortIcon field="groupName"></p-treeTableSortIcon></th>
                <th *ngFor="let year of years" [ttSortableColumn]="'countByYear.' + year">{{ year }}<p-treeTableSortIcon [field]="'countByYear.' + year"></p-treeTableSortIcon></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-offerCount="rowData">
            <tr class="p-treetable-row">
                <td>
                    <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                    {{ offerCount.groupName }}
                </td>
                <td *ngFor="let year of years">
                    {{ offerCount.countByYear[year] ? offerCount.countByYear[year] : 0 }}
                </td>
            </tr>
        </ng-template>
    </p-treeTable>
</div>
