import {HttpErrorResponse} from '@angular/common/http';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {DomSanitizer} from "@angular/platform-browser";
import {ActivatedRoute, ParamMap, Params, Router} from "@angular/router";
import {Hotkey} from "angular2-hotkeys";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Dialog} from 'primeng/dialog';
import {forkJoin, Observable, of, Subscription} from "rxjs";
import {catchError, finalize, mergeMap} from 'rxjs/operators';
import * as _ from 'underscore';
import {CachingWindowDesignerDataService} from '../../../../../../window-designer/caching-window-designer-data-service';
import {getColorFormattedNameWithGroup} from '../../../../../../window-designer/catalog-data/color-interface';
import {DistanceFrameInterface} from '../../../../../../window-designer/catalog-data/distance-frame-interface';
import {GlassInterface} from '../../../../../../window-designer/catalog-data/glass-interface';
import {GrillInterface} from '../../../../../../window-designer/catalog-data/grill-interface';
import {PositionListAddon} from "../../../../../../window-designer/catalog-data/position-list-addon";
import {ProfileInterface} from '../../../../../../window-designer/catalog-data/profile-interface';
import {WindowSystemInterface, WindowSystemType} from '../../../../../../window-designer/catalog-data/window-system-interface';
import {DrawingData} from "../../../../../../window-designer/drawing-data/drawing-data";
import {ConfigurableAddon} from "../../../../../../window-designer/entities/ConfigurableAddon";
import {ConfigAddonApplication} from "../../../../../../window-designer/enums/ConfigAddonApplication";
import {DrawingDataValidator} from "../../../../../../window-designer/utils/DrawingDataValidator";
import {VisibilitySettings} from "../../../../../../window-designer/utils/VisibilitySettings";
import {WindowCalculator} from '../../../../../../window-designer/window-calculator';
import {WindowDesignerDataServiceInterface} from '../../../../../../window-designer/window-designer-data.service-interface';
import {ApplicationFeatures} from '../../../../../application-info/application-features';
import {AuthService} from '../../../../../auth/auth.service';
import {CurrentUserService} from '../../../../../auth/current-user.service';
import {Permissions} from "../../../../../auth/permission.service";
import {StorageKey, StorageService} from "../../../../../auth/storage.service";
import {ButtonWithMenuElementSelectedEvent} from "../../../../../common/button-with-menu/button-with-menu-event";
import {ButtonWithMenuComponent} from "../../../../../common/button-with-menu/button-with-menu.component";
import {MenuElement, MenuElementBuilder} from "../../../../../common/button-with-menu/MenuElement";
import {MenuType} from "../../../../../common/button-with-menu/MenuType";
import {CommonErrorHandler} from "../../../../../common/CommonErrorHandler";
import {DataServiceHelper} from "../../../../../common/dataServiceHelper";
import {AvailableProducts} from "../../../../../common/enums/AvailableProductType";
import {OfferStatus, PAID_AND_AFTER_STATUSES} from "../../../../../common/enums/OfferStatus";
import {ExchangeService} from "../../../../../common/exchange.service";
import {MissingProfitMarginHandlerService} from "../../../../../common/missing-profit-margin-handler/missing-profit-margin-handler.service";
import {OfferStatusProvider} from "../../../../../common/offerStatusProvider";
import {CrudComponent} from "../../../../../common/service/crud.component";
import {DataTableColumnBuilder} from "../../../../../common/service/data.table.column.builder";
import {ExportComponent} from "../../../../../common/service/export.component";
import {ValidationErrorsHelper} from "../../../../../common/ValidationErrorsHelper";
import {Currencies} from "../../../../../currencies";
import {TristateCheckboxState} from "../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {WizardStepValidator} from "../../../../../form-inputs/wizard/wizard-step.component";
import {SidenavController} from '../../../../../sidenav-controller';
import {AccessData} from "../../../../AccessData";
import {MarkProductsService} from "../../../../admin-panel/mark-products/mark-products.service";
import {MarkedProduct} from "../../../../admin-panel/mark-products/marked-product";
import {ErrorResponse} from '../../../../errors/errorResponse';
import {ListOfIds} from "../../../../ListOfIds";
import {SubsystemCurrencyData} from "../../../../subsystem/subsystem";
import {SubsystemService} from '../../../../subsystem/subsystem.service';
import {Supplier} from "../../../../supplier/supplier";
import {SupplierService} from '../../../../supplier/supplier.service';
import {UserService} from "../../../../user/user.service";
import {AddonsService} from "../../../../window-system/addons/addons.service";
import {Color} from "../../../../window-system/color/color";
import {DistanceFrameService} from "../../../../window-system/distance-frame/distance-frame.service";
import {GlassService} from "../../../../window-system/glass/glass.service";
import {GrillService} from "../../../../window-system/grill/grill.service";
import {ProductTypeGroup} from '../../../../window-system/window-system-definition/product-type-group';
import {ProductsVisibility} from "../../../../window-system/window-system-definition/products-visibility";
import {WindowSystemDefinitionService} from "../../../../window-system/window-system-definition/window-system-definition.service";
import {
    AbstractPosition,
    AbstractPositionList,
    isFullScreenDesignerWindowPositionType,
    isWindowPositionType,
    PositionType
} from '../../../AbstractPosition';
import {Complaint} from "../../../complaint/complaint";
import {ComplaintPosition} from "../../../complaint/complaint-position";
import {ComplaintStatus} from "../../../complaint/complaint-status";
import {ComplaintStatusTransitionService} from '../../../complaint/complaint-status-transition.service';
import {ComplaintService} from "../../../complaint/complaint.service";
import {CreateComplaintComponent} from "../../../complaint/create-complaint/create-complaint.component";
import {ComplaintPositionFormValidator} from "../../../complaint/form/complaint-position-form.validator";
import {Offer, OfferExhangeRates} from "../../../offer";
import {OffersService} from "../../../offer-service";
import {OfferVersion, OfferVersionStatus} from "../../../OfferVersion";
import {PrintableItem} from '../../../print-dialog/print-dialog.component';
import {PrintableSection} from '../../../print-dialog/printable-section.enum';
import {ProductionOrderService} from '../../../production-orders/production-order-service';
import {
    ProductionOrdersPositionTableData
} from '../../../production-orders/production-orders-position-list/production-orders-position-list-table-data';
import {ProductionOrdersPosition} from '../../../production-orders/production-orders-position-list/productionOrdersPosition';
import {ProductionOrdersPositionService} from '../../../production-orders/production-orders-position/production-orders-position.service';
import {ProductionOrder} from '../../../production-orders/ProductionOrder';
import {
    ShippingSimulationFloatButton
} from "../../../shipping/shipping-simulation/shipping-simulation-float-button/shipping-simulation-float-button";
import {
    ShippingSimulationSidebarComponent
} from "../../../shipping/shipping-simulation/shipping-simulation-sidebar/shipping-simulation-sidebar.component";
import {ShippingSimulationService} from "../../../shipping/shipping-simulation/shipping-simulation.service";
import {StatusTransitionDialogService} from '../../../status-transition-dialog/status-transition-dialog.service';
import {StatusTransition} from "../../../status-transition-dialog/StatusTransition";
import {StatusTransitionHelper} from "../../../status-transition-dialog/StatusTransitionHelper";
import {PricingService} from "../../../window-editor/sidebar/pricing/pricing.service";
import {WindowDesignerDataService} from '../../../window-editor/window-designer/window-designer-data.service';
import {WindowDesignerComponent} from "../../../window-editor/window-designer/window-designer.component";
import {CreateOfferMode} from "../../create-offer/create-offer-mode";
import {PositionService, RemovePositionsResult} from "../position.service";
import {AddonProperties} from "../window-properties/addon-properties";
import {AssemblyProperties} from "../window-properties/assembly-properties";
import {ConfigProperties} from "../window-properties/config-properties";
import {GateProperties} from '../window-properties/gate-properties';
import {TransportProperties} from "../window-properties/transport-properties";
import {WindowProperties} from '../window-properties/window-properties';
import {BulkAddonData} from "./add-bulk-addon-position/bulk-addon-data";
import {SubmitStatus} from "./bulk-change-confirmation/global-config-addons-add-confirmation.component";
import {BulkChangeFrontendWarning} from "./BulkChangeFrontendWarning";
import {CommentDialogMode} from "./comment-dialog/comment-dialog-mode";
import {ConfigurableAddonDefinitionRoDto} from "./ConfigurableAddonModel/ConfigurableAddonDefinitionRoDto";
import {ConfigurableAddonDialogEventModel} from "./ConfigurableAddonModel/ConfigurableAddonDialogEventModel";
import {GlobalAddConfigAddonsData} from "./ConfigurableAddonModel/GlobalAddConfigAddonsData";
import {ConjunctionDialogDto} from "./conjunctions/conjunction-dialog-dto";
import {ConjunctionService} from "./conjunctions/conjunction-service";
import {OfferPositionModel} from './OfferPositionModel';
import {Position, PositionSortGroup} from "./position";
import {
    AddConfigurableAddonDialogData,
    AddStandaloneGlazingPackagesGloballyDialogData,
    ArchivedOfferVersionsDialogData,
    BulkChangeConfirmationDialogData,
    BulkChangeDialogData,
    BulkChangeDialogDataConstructor,
    BulkColorChangeDialogData,
    BulkFittingsChangeDialogData,
    BulkGeneralChangeDialogData,
    BulkGlassChangeDialogData,
    BulkPredefGlazingPackageChangeDialogData,
    BulkWindowSystemChangeDialogData,
    CommentsDialogData,
    ConjunctionsDialogData,
    DialogData,
    DialogDataSelector,
    DialogType,
    DisableValidationDialogData,
    EditAssemblyOrTransportDialogData,
    EditBulkAddonDialogData,
    EditComplaintPositionDialogData,
    GlobalAddConfigAddonsDialogData,
    GlobalConfigAddonsAddConfirmationDialogData,
    OpenOfferChargeDataDialogData,
    PriceChangeDialogData,
    RabateMarkupDialogData,
    RotDataDialogData,
    SetPrintOrderDialogData,
    UpdateExchangeRateDialogData,
    UpdatePricingsDialogData,
    UpdateValidityDatesDialogData,
    UpdateVatDialogData
} from "./position-list-dialogs";
import {PositionTableData} from "./position-list-table/position-list-table-data";
import {PositionListTableComponent} from "./position-list-table/position-list-table.component";
import {RabateComponent} from "./rabate/rabate.component";
import {RotDataService} from "./rot-data-dialog/rot-data.service";
import {RotData} from "./rot-data-dialog/RotData";
import {OtherInfoDialogCloseEvent} from './show-description/show-description.component';
import {UpsellingPropositionResult} from "./UpsellingPropositionResult";
import {UpsellingPropositionResults} from "./UpsellingPropositionResults";
import {UpsellingSidebarComponent} from './windowUpselling/upselling-sidebar/upselling-sidebar.component';

@Component({
    selector: 'app-position-list',
    templateUrl: './position-list.component.html',
    styleUrls: ['../../../../shared-styles.css', './position-list.component.css'],
    providers: [OffersService, ExchangeService, SubsystemService, ExportComponent, PositionService, DataServiceHelper,
        PricingService, AddonsService, GlassService, DistanceFrameService, WindowSystemDefinitionService,
        GrillService, ComplaintService, ComplaintPositionFormValidator, StatusTransitionDialogService, ComplaintStatusTransitionService,
        UserService, AuthService, ProductionOrderService, ProductionOrdersPositionService, SupplierService,
        WindowDesignerDataService, RotDataService, MarkProductsService, ConjunctionService, MissingProfitMarginHandlerService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionListComponent extends CrudComponent implements OnInit, OnDestroy, AfterViewInit {

    private static readonly BULK_CHANGE_BLOCK_ID = 'PositionListBulkChange';
    private static readonly UPSELLING_BLOCK = 'APPLY UPSELLING';
    private static readonly RESTORE_ARCHIVED_OFFER_BLOCK_ID = 'RestoreArchivedOffer';
    private static readonly ACQUIRE_OFFERLOCK_ID = 'acquireOfferLock';
    private static readonly COPY_POSITION_BLOCK_ID = 'copyPosition';
    public static readonly BACK_TO_FILTERED = 'backToFiltered';

    commentDialogMode: CommentDialogMode;

    @Input() isComplaint = false;
    @Input() isProductionOrder = false;
    @Input() complaint: Complaint;
    selectedComplaintPosition: ComplaintPosition;
    relatedComplaints: Complaint[] = [];

    suppliers: Supplier[] = [];
    currentActionPosition: Position;
    subscription: Subscription;
    id: number;
    productionOrderId: number;
    offer: Offer;
    availableCurrencies: MenuElement[] = [];
    selectedCurrency: Currencies;
    defaultCurrency: Currencies;
    isRotEnabled: boolean;
    tableDataBySupplier: { [supplierId: number]: PositionTableData };
    ownAddonsData: PositionTableData;
    assembliesData: PositionTableData;
    transportsData: PositionTableData;

    itemsToRemove: Position[];
    complaintPositionsToRemove: number[];
    userLang: string;

    positionsPropertiesForComparison: WindowProperties[] | GateProperties[] | ConfigProperties[] | AddonProperties[] | AssemblyProperties[] | TransportProperties[];
    positionTypeForComparison: PositionType;
    positionsForComparison: Position[];
    rotData: RotData;
    markedProducts: MarkedProduct[];

    @Output() selectionChange: EventEmitter<{ data?: AbstractPosition }> = new EventEmitter();
    @Output() reloadComplaint: EventEmitter<any> = new EventEmitter();

    displayedDialog: DialogType = undefined;
    displayedDialogData: DialogData;
    readonly dialogDataSelector = new DialogDataSelector();
    rabate;
    disableGlobalChangePircingButton = true;
    disableGlobalChangeButton = true;
    globalChangeItems: MenuElement[] = [];
    globalChangePricingItems: MenuElement[] = [];
    annotationItems: MenuElement[] = [];
    restoreItems: MenuElement[] = [];

    addon: PositionListAddon;
    configAddon: ConfigurableAddonDefinitionRoDto = null;
    availableInsideColors: Color[];
    availableOutsideColors: Color[];
    imagePreview;
    canEdit: boolean;
    canEditProdOrder: boolean;
    isOrder: boolean;
    ready = false;
    selectedComplaintPositions: Position[] = [];
    selectedComplaintPositionsIds: number[] = [];
    exchangeRatePercentDifference: number;

    @ViewChild('toolbar', {read: TemplateRef, static: true})
    toolbar: TemplateRef<any>;

    @ViewChild('addonDialog') addonDialog: Dialog;
    @ViewChild('rabateDialog') rabateDialog: RabateComponent;

    editHotkey: Hotkey;
    deleteHotkey: Hotkey;
    shippingHotkey: Hotkey;
    private profitMarginHotkey: Hotkey;
    private rebateHotkey: Hotkey;
    annotationsHotkey: Hotkey;
    private windowDesignerHotkey: Hotkey;
    private roofWindowDesignerHotkey: Hotkey;
    private editConfigAddonHotkey: Hotkey;
    showSummaryHotkey: Hotkey;
    private globalGlassChangeHotkey: Hotkey;
    private globalSystemChangeHotkey: Hotkey;
    private globalColorChangeHotkey: Hotkey;
    private globalGeneralChangeHotkey: Hotkey;
    private globalExchangeRateUpdateHotkey: Hotkey;
    globalPricingsUpdateHotkey: Hotkey;
    conjunctionHotkey: Hotkey;
    shippingCalculationInProgress: boolean;

    // Parameters needed for redrawing previews process
    settings: VisibilitySettings = new VisibilitySettings();
    loadDrawer = false;
    areParentsSelected = false;
    lastSelectedRow: AbstractPosition;

    @ViewChildren('drawer') drawer: QueryList<WindowDesignerComponent>;
    @ViewChildren('positionTable') positionTables: QueryList<PositionListTableComponent>;
    @ViewChild('ownAddonsPositionTable') ownAddonsPositionTable: PositionListTableComponent;
    @ViewChild('assembliesPositionTable') assembliesPositionTable: PositionListTableComponent;
    @ViewChild('transportsPositionTable') transportsPositionTable: PositionListTableComponent;
    @ViewChild('addPositionHintButton') addPositionHintButton: ButtonWithMenuComponent;
    @ViewChild('addPositionHintRegionDiv') addPositionHintRegionDiv: ElementRef<HTMLDivElement>;
    @ViewChild('productionOrderPositionTable') productionOrderPositionTable: DataTable;

    menuType = MenuType;
    exchangeRateWarningStatus: 'HIGH' | 'LOW' | undefined;
    subsystemCurrencyData: SubsystemCurrencyData;
    addPositionMenuElements: MenuElement[] = [];

    showDeleteButton = false;
    showShippingButton = false;
    private blurEventListenerAdded = false;

    validateComplaintPositionDataStep: WizardStepValidator;

    lastEditedWindowPositionId: number;
    archivedIdToRestore: number;
    enteredFromSearchView: string;

    possibleTransitions: StatusTransition[] = [];

    upsellingResults: UpsellingPropositionResults[] = [];

    transitionsDialogVisible = false;
    statusTransitionInfoDialogVisible = false;

    printDialogVisible = false;
    printableItems: PrintableItem[] = [];

    private offerTransitionInProgress = false;
    hasOfferLock = false;
    tryToAcquireLock = false;

    appFeatures: ApplicationFeatures;
    positionDisabled: boolean;
    positionToEdit: AbstractPosition;

    readonly route: ActivatedRoute;
    private router: Router;
    private storage: StorageService;
    public exchangeService: ExchangeService;
    private offerService: OffersService;
    private rotDataService: RotDataService;
    private markProductsService: MarkProductsService;
    private conjunctionService: ConjunctionService;
    productionOrder: ProductionOrder;
    selectedPositionsIds: number[] = [];
    selectedConjunctionsIds: number[] = [];
    tableData: ProductionOrdersPositionTableData = new ProductionOrdersPositionTableData();
    private productionOrdersPositionService: ProductionOrdersPositionService;
    private productionOrderService: ProductionOrderService;
    private supplierService: SupplierService;
    private windowDesignerDataService: WindowDesignerDataServiceInterface;

    @ViewChild('shippingSidebar', {static: true}) shippingSimulationSidebar: ShippingSimulationSidebarComponent;
    @ViewChild('shippingFloatButton', {static: true}) shippingFloatButton: ShippingSimulationFloatButton;

    @ViewChild('upsellingSidebar', {static: true}) upsellingSidebar: UpsellingSidebarComponent;

    hasDifferencesFromLastArchivedVersion = false;

    supplierTrackBy: (index: number, supplier: Supplier) => number;

    constructor(private fullscreenControllers: SidenavController,
                public permissions: Permissions,
                private currentUserService: CurrentUserService,
                public positionService: PositionService,
                public addonService: AddonsService,
                private sanitizer: DomSanitizer,
                private pricingService: PricingService,
                private offerStatusTransitionDialogService: StatusTransitionDialogService,
                private complaintStatusTransitionService: ComplaintStatusTransitionService,
                private glassService: GlassService,
                private frameService: DistanceFrameService,
                private windowSystemDefinitionService: WindowSystemDefinitionService,
                private grillService: GrillService,
                private auth: AuthService,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private shippingSimulationService: ShippingSimulationService,
                private complaintService: ComplaintService,
                private complaintPositionFormValidator: ComplaintPositionFormValidator,
                private errors: CommonErrorHandler,
                private subsystemService: SubsystemService) {
        super(injector, changeDetector, 'PositionListComponent', true);
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.storage = injector.get(StorageService);
        this.exchangeService = injector.get(ExchangeService);
        this.offerService = injector.get(OffersService);
        this.rotDataService = injector.get(RotDataService);
        this.markProductsService = injector.get(MarkProductsService);
        this.conjunctionService = injector.get(ConjunctionService);
        this.productionOrdersPositionService = injector.get(ProductionOrdersPositionService);
        this.productionOrderService = injector.get(ProductionOrderService);
        this.supplierService = injector.get(SupplierService);
        this.windowDesignerDataService = injector.get(WindowDesignerDataService);
        this.userLang = this.translate.currentLang;
        this.initHotkeys();
        this.supplierTrackBy = (index: number, supplier: Supplier) => {
            return supplier.id;
        };
    }

    openWindowDesigner(positionId: string, group: ProductTypeGroup) {
        switch (group) {
            case ProductTypeGroup.DEFAULT:
            case ProductTypeGroup.TERRACE:
                this.router.navigate([positionId, AccessData.windowDesignerURLSuffix], {
                    relativeTo: this.route,
                    queryParams: {windowSystemTypeGroup: group}
                });
                break;
            case ProductTypeGroup.ROOF:
            case ProductTypeGroup.ENTRANCE:
                this.router.navigate([positionId, AccessData.roofWindowDesignerURLSuffix], {
                    relativeTo: this.route,
                    queryParams: {windowSystemTypeGroup: group}
                });
                break;
        }
    }

    private fillAnnotationMenu() {
        this.annotationItems = [];
        if (this.canEdit) {
            this.annotationItems.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ANNOTATION_ENTRY').setIdentifier('ANNOTATION_ENTRY').build());
            this.annotationItems.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ANNOTATION_END').setIdentifier('ANNOTATION_END').build());
            this.annotationItems.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.VENSKA_ANNOTATION').setIdentifier('VENSKA_ANNOTATION').build());
        }
    }

    private fillRestoreVersionMenu() {
        this.restoreItems = [];
        if (!this.isComplaint && this.offer.lastArchivedVersionId) {
            if (this.canEdit) {
                this.restoreItems.push(new MenuElementBuilder().setTranslationKey('OFFER.POSITIONS.RESTORE_LAST_ARCHIVED_VERSION')
                    .setIdentifier('RESTORE_LAST_ARCHIVED_VERSION').build());
            }
            this.restoreItems.push(new MenuElementBuilder().setTranslationKey('OFFER.POSITIONS.SHOW_ARCHIVED_VERSIONS')
                .setIdentifier('SHOW_ARCHIVED_VERSIONS').build());
        }
    }

    showDialogToAdd(): void {
        this.openWindowDesigner('new', ProductTypeGroup.DEFAULT);
    }

    private getMarkLabel(productType: AvailableProducts) {
        const marked = this.markedProducts.find(product => product.productType === productType);
        return marked ? marked.markLabels[this.userLang] : '';
    }

    getDatatable(): DataTable {
        return undefined;
    }

    ngOnInit() {
        this.appFeatures = this.route.snapshot.data['features'] as ApplicationFeatures;
        this.fullscreenControllers.replaceContent(this.toolbar);
        this.commentDialogMode = this.isComplaint ? CommentDialogMode.COMPLAINT_POSITION : CommentDialogMode.OFFER_POSITION;
        this.availableCurrencies = Object.keys(Currencies).map(currency => new MenuElementBuilder()
            .setTitle(currency).setIdentifier(currency).build());

        if (this.isProductionOrder) {
            this.route.params.pipe(mergeMap((params: Params) => {
                this.productionOrderId = +params.productionOrderId;
                return forkJoin({
                    defaultCurrency: this.subsystemService.getDefaultCurrency(),
                    productionOrder: this.productionOrderService.getItem(this.productionOrderId),
                    exchangeRates: this.exchangeService.initializeExchangeRates()
                });
            }), mergeMap(
                data => {
                    this.defaultCurrency = data.defaultCurrency;
                    this.productionOrder = data.productionOrder;
                    this.exchangeService.storeExchangeRates(data.exchangeRates);
                    this.selectedCurrency = this.defaultCurrency;
                    this.createProductionOrderTable();
                    this.loadProdOrderTableItems(this.tableData.lastLoadEvent);

                    return forkJoin({
                        supplier: this.supplierService.getSupplier(this.productionOrder.supplierId),
                        offer: this.productionOrderService.getLastRealtedOffer(this.productionOrder.id)
                    });
                }
            )).subscribe(data => {
                this.suppliers = [data.supplier];
                this.offer = data.offer;
                this.initSelectedCurrency();
                this.setCanEditField();
                this.setCanEditProdOrder();
                this.blockUiController.unblock("PositionListComponentData");
                this.ready = true;
                this.changeDetector.markForCheck();
                console.info('ProductionOrdersPositionListComponent `onInit` completed!');
            });
            return;
        }

        if (this.shippingSimulationService.checkCalculationFinishedInterval) {
            this.shippingCalculationInProgress = true;
            this.shippingSimulationService.checkCalculationFinishedInterval.pipe(
                finalize(() => {
                    this.shippingCalculationInProgress = false;
                    this.changeDetector.markForCheck();
                }))
                .subscribe();
        }

        this.route.paramMap.pipe(mergeMap((params: ParamMap) => {
            if (this.isComplaint) {
                this.id = this.complaint.offerId;
            } else {
                this.id = +params.get('offerId');
            }
            if (params.has('lastSelection')) {
                this.lastEditedWindowPositionId = +params.get('lastSelection');
            } else {
                this.lastEditedWindowPositionId = undefined;
            }
            if (params.has('hasLock')) {
                // this is to allow properly exiting this view before offer is fully loaded (for example on slow network)
                this.hasOfferLock = params.get('hasLock') === 'true';
            }
            if (params.has('tryToAcquireLock')) {
                this.tryToAcquireLock = params.get('tryToAcquireLock') === 'true';
            }
            if (params.has('fromSearch')) {
                this.enteredFromSearchView = params.get('fromSearch');
            }
            return forkJoin({
                offer: this.offerService.getItem(this.id),
                isRotEnabled: this.offerService.isRotEnabled(this.id),
                suppliers: this.getSuppliers(),
                sortGroups: this.positionService.getPositionSortGroups(this.id),
                exchangeRates: this.exchangeService.initializeExchangeRates(),
                defaultCurrency: this.subsystemService.getDefaultCurrency(),
                currentVersionStatus: this.offerService.getCurrentVersionStatus(this.id),
                systemsVisibility: this.windowSystemDefinitionService.getAvailableProducts(),
                rotData: this.rotDataService.getRotData(this.id),
                markedProducts: this.markProductsService.getItems()
            });
        })).subscribe({
            next: data => {
                this.isRotEnabled = data.isRotEnabled;
                this.offer = data.offer;
                this.fillPossibleTransitions();
                this.suppliers = data.suppliers;
                this.suppliers.sort((s1: Supplier, s2: Supplier) => s1.id - s2.id);
                this.defaultCurrency = data.defaultCurrency;
                this.initSelectedCurrency();
                this.createTable();
                this.initShownColumns();
                this.tableDataBySupplier = {};
                for (let supplier of this.suppliers) {
                    this.tableDataBySupplier[supplier.id] = new PositionTableData(PositionSortGroup.SYSTEM);
                }
                if (this.suppliers.length > 0) {
                    this.tableDataBySupplier[this.suppliers[0].id].canFocusFirstRow = true;
                }
                if (data.sortGroups.OWN_ADDON && !this.isComplaint) {
                    this.ownAddonsData = new PositionTableData(PositionSortGroup.OWN_ADDON);
                    if (this.suppliers.length === 0) {
                        this.ownAddonsData.canFocusFirstRow = true;
                    }
                } else {
                    this.ownAddonsData = undefined;
                }
                if (data.sortGroups.ASSEMBLY && !this.isComplaint) {
                    this.assembliesData = new PositionTableData(PositionSortGroup.ASSEMBLY);
                    if (this.suppliers.length === 0 && this.ownAddonsData == undefined) {
                        this.assembliesData.canFocusFirstRow = true;
                    }
                } else {
                    this.assembliesData = undefined;
                }
                if (data.sortGroups.OWN_TRANSPORT && !this.isComplaint) {
                    this.transportsData = new PositionTableData(PositionSortGroup.ASSEMBLY);
                    if (this.suppliers.length === 0 && this.ownAddonsData == undefined && this.assembliesData == undefined) {
                        this.transportsData.canFocusFirstRow = true;
                    }
                } else {
                    this.transportsData = undefined;
                }
                this.isOrder = OfferStatusProvider.isOrderStatus(this.offer.status);
                this.setCanEditField();
                this.setShowWindowUpsellingButton();

                this.exchangeService.storeExchangeRates(data.exchangeRates);
                this.hasDifferencesFromLastArchivedVersion = data.currentVersionStatus.hasDifferencesFromLastArchivedVersion;
                this.setGlobalChangeItems();
                this.registerHotkeys();
                this.langTranslateSubscription.push(this.translate.onLangChange.subscribe(event => {
                    this.userLang = event.lang;
                    this.reloadAllTables();
                }));
                this.markedProducts = data.markedProducts;
                this.fillAddPositionMenu(ProductsVisibility.prepare(data.systemsVisibility, this.appFeatures.enabledProducts));
                this.fillRestoreVersionMenu();
                this.fillAnnotationMenu();
                this.ready = true;
                this.changeDetector.markForCheck();
                this.rotData = data.rotData;
                this.subsystemService.getSubsystemCurrencyData(this.offer.subsystemId).subscribe(
                    currencyData => {
                        this.subsystemCurrencyData = currencyData;
                        this.handleExchangeRateDifference();
                    }
                );
                console.info('PositionListComponent `onInit` completed!');
            },
            error: () => {
                this.handleOfferLoadingError();
            }
        });
        if (this.currentUserService.restrictedToOfferNumber == undefined) {
            this.shippingFloatButton.refresh();
        }
    }

    private handleExchangeRateDifference() {
        if (PAID_AND_AFTER_STATUSES.includes(this.offer.status)) {
           return;
        }
        let offerExchangeRate = this.offer.subsystemManualExchangeRate ? this.offer.subsystemManualExchangeRate : this.offer.exchangeRate;
        let currentExchangeRate = this.exchangeService.getCurrentRatio(this.offer.currency);
        let diff = (currentExchangeRate - offerExchangeRate) * 100 / currentExchangeRate;
        this.exchangeRatePercentDifference = Math.abs(Math.round( diff * 10 + Number.EPSILON ) / 10);
        if (this.subsystemCurrencyData && diff > this.subsystemCurrencyData.exchangeRateSafeChangeThreshold) {
            this.exchangeRateWarningStatus = 'HIGH';
        } else if (this.subsystemCurrencyData && diff < (this.subsystemCurrencyData.exchangeRateSafeChangeThreshold * -1)) {
            this.exchangeRateWarningStatus = 'LOW';
        } else {
            this.exchangeRateWarningStatus = null;
            this.exchangeRatePercentDifference = null;
        }
        this.changeDetector.markForCheck();
    }

    private setShowWindowUpsellingButton() {
        if (this.canEdit) {
            this.positionService.getAvailableUpsellings(this.offer.id)
                .pipe(finalize(() => this.blockUiController.unblock(PositionListComponent.UPSELLING_BLOCK)))
                .subscribe({
                    next: results => {
                        this.upsellingResults = results;
                        this.changeDetector.markForCheck();
                    },
                    error: error => this.errors.handle(error)
                });
        }
    }

    private initSelectedCurrency(): void {
        let currencyToSelect = this.defaultCurrency;
        let idStorageKey = this.isProductionOrder ? StorageKey.LAST_VISITED_PROD_ORDER_ID : StorageKey.LAST_VISITED_OFFER_ID;
        let id = this.isProductionOrder ? this.productionOrder.id : this.id;
        let lastVisitedOfferId = this.storage.get(idStorageKey);
        let currencyStorageKey = this.isProductionOrder
            ? StorageKey.LAST_SELECTED_PROD_ORDER_CURRENCY
            : StorageKey.LAST_SELECTED_OFFER_CURRENCY;
        if (lastVisitedOfferId != null && +lastVisitedOfferId === id) {
            let lastSelectedCurrency = this.storage.get(currencyStorageKey);
            if (lastSelectedCurrency != null) {
                currencyToSelect = Currencies[lastSelectedCurrency as string];
            }
        } else {
            this.storage.remove(currencyStorageKey);
        }
        this.selectedCurrency = currencyToSelect;
        this.storage.set(idStorageKey, '' + id);
    }

    private getSuppliers(): Observable<Supplier[]> {
        return this.isComplaint ?
            this.positionService.getSuppliersForComplaint(this.complaint.id) :
            this.positionService.getSuppliersForOffer(this.id);
    }

    reloadOffer(): void {
        forkJoin({
            offer: this.offerService.getItem(this.id),
            currentVersionStatus: this.isComplaint
                ? of<OfferVersionStatus>({ archiveVersionNumber: undefined, hasDifferencesFromLastArchivedVersion: false })
                : this.offerService.getCurrentVersionStatus(this.id)
        }).subscribe({
            next: data => {
                this.offer = data.offer;
                this.fillPossibleTransitions();
                this.isOrder = OfferStatusProvider.isOrderStatus(this.offer.status);
                this.setCanEditField();
                this.setGlobalChangeItems();
                this.registerHotkeys();
                this.fillRestoreVersionMenu();
                this.fillAnnotationMenu();
                this.changeDetector.markForCheck();
                if (data.currentVersionStatus.archiveVersionNumber != undefined) {
                    this.offer.archiveVersionNumber = data.currentVersionStatus.archiveVersionNumber;
                }
                this.hasDifferencesFromLastArchivedVersion = data.currentVersionStatus.hasDifferencesFromLastArchivedVersion;
            },
            error: () => {
                this.handleOfferLoadingError();
            }
        });
    }

    handleCurrencyMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        this.selectedCurrency = Currencies[event.identifier as string];
        let currencyStorageKey = this.isProductionOrder
            ? StorageKey.LAST_SELECTED_PROD_ORDER_CURRENCY
            : StorageKey.LAST_SELECTED_OFFER_CURRENCY;
        this.storage.set(currencyStorageKey, event.identifier);
    }

    private setCanEditField(): void {
        this.hasOfferLock = (this.offer && this.offer.offerLockUserLogin === this.currentUserService.currentUserName)
            || !!this.currentUserService.restrictedToOfferNumber;
        this.canEdit = this.hasOfferLock && !this.isComplaint && !this.isProductionOrder
            && this.permissions.userCanEditOffer(this.offer.status) && !this.offer.vatBlocksEdit;
        if (this.offer.offerLockUserLogin == undefined && this.tryToAcquireLock) {
            this.offerService.blockOffer(this.offer.id).subscribe(hasLock => {
                if (hasLock) {
                    this.offer.offerLockUserLogin = this.currentUserService.currentUserName;
                    this.setCanEditField();
                }
            });
        }
    }

    private setCanEditProdOrder(): void {
        this.canEditProdOrder = this.isProductionOrder && this.productionOrder != undefined
            && this.productionOrder.correctionId == undefined && !this.productionOrder.orderCanceled;
    }

    private registerHotkeys(): void {
        this.removeHotkeys();
        if (!this.isOrder) {
            if (this.hasOfferLock && !this.isComplaint &&
                this.permissions.userCanEditOffer(this.offer.status)) {
                if (!this.offer.vatBlocksEdit) {
                    this.hotkeysService.add(this.editHotkey);
                    this.hotkeysService.add(this.deleteHotkey);
                    this.hotkeysService.add(this.profitMarginHotkey);
                    this.hotkeysService.add(this.rebateHotkey);
                    this.hotkeysService.add(this.annotationsHotkey);
                    this.hotkeysService.add(this.windowDesignerHotkey);
                    this.hotkeysService.add(this.roofWindowDesignerHotkey);
                    this.hotkeysService.add(this.editConfigAddonHotkey);
                    this.hotkeysService.add(this.globalColorChangeHotkey);
                    this.hotkeysService.add(this.globalSystemChangeHotkey);
                    this.hotkeysService.add(this.globalGlassChangeHotkey);
                    this.hotkeysService.add(this.globalGeneralChangeHotkey);
                    this.hotkeysService.add(this.globalExchangeRateUpdateHotkey);
                    this.hotkeysService.add(this.conjunctionHotkey);
                } else if (this.isProductionOrder) {
                    this.hotkeysService.add(this.annotationsHotkey);
                }
                this.hotkeysService.add(this.globalPricingsUpdateHotkey);
            }
            this.hotkeysService.add(this.shippingHotkey);
        }
        this.hotkeysService.add(this.showSummaryHotkey);
    }

    submit() {
        if (this.displayedDialog) {
            this.submitDialog();
            return;
        }
    }

    ngAfterViewInit() {
        this.drawer.changes.subscribe((drawer: QueryList<WindowDesignerComponent>) => {
            if (this.loadDrawer) {
                let dialogData = this.displayedDialogData as BulkChangeConfirmationDialogData ;
                let positionsToRedraw = dialogData.modifiedPositions
                    .filter(pos => pos.type === PositionType.SYSTEM)
                    .filter(modified => {
                        return !dialogData.changeResult.hasValidationErrors(modified.id);
                    });
                const catalogDataCache = new CachingWindowDesignerDataService(this.windowDesignerDataService);
                let observable = drawer.first.saveSvgForRedrawing(dialogData.result.bulkChangeId, positionsToRedraw[0].id,
                    positionsToRedraw[0].data, catalogDataCache);
                for (let i = 1; i < positionsToRedraw.length; ++i) {
                    observable = observable.pipe(mergeMap(() => {
                        return drawer.first.saveSvgForRedrawing(dialogData.result.bulkChangeId, positionsToRedraw[i].id,
                            positionsToRedraw[i].data, catalogDataCache);
                    }));
                }

                let disableDrawingTool = () => {
                    this.resetDialog();
                    this.loadDrawer = false;
                    this.blockUiController.unblock(PositionListComponent.BULK_CHANGE_BLOCK_ID);
                    this.changeDetector.markForCheck();
                };

                let updateSelectedPositionPictureAndDetails = () => {
                    if (this.lastSelectedRow !== undefined && this.lastSelectedRow.type === PositionType.SYSTEM) {
                        let modifiedSelectedPosition = dialogData.modifiedPositions.find(
                            modified => modified.id === this.lastSelectedRow.id);
                        if (modifiedSelectedPosition !== undefined) {
                            this.selectionChange.emit({data: modifiedSelectedPosition});
                        }
                    }
                };

                observable.pipe(mergeMap(() => {
                    return this.positionService.finalizeBulkChange(dialogData.result.bulkChangeId,
                        dialogData.result.excludedPositionIds, this.offer.id);
                })).subscribe({
                    error: error => {
                        this.errors.handleFE(error);
                        disableDrawingTool();
                    },
                    complete: () => {
                        this.refreshAfterAction(DialogType[dialogData.changeToConfirm], false, false, undefined);
                        updateSelectedPositionPictureAndDetails();
                        disableDrawingTool();
                    }
                });
            }
        });
        this.hideViewLoadingIndicator();
        this.hideDataLoadingIndicator();
    }

    ngOnDestroy() {
        this.removeHotkeys();
        this.fullscreenControllers.replaceContent(undefined);
        super.ngOnDestroy();
    }

    private removeHotkeys(): void {
        this.hotkeysService.remove(this.showSummaryHotkey);
        this.hotkeysService.remove(this.editConfigAddonHotkey);
        this.hotkeysService.remove(this.windowDesignerHotkey);
        this.hotkeysService.remove(this.roofWindowDesignerHotkey);
        this.hotkeysService.remove(this.annotationsHotkey);
        this.hotkeysService.remove(this.rebateHotkey);
        this.hotkeysService.remove(this.profitMarginHotkey);
        this.hotkeysService.remove(this.deleteHotkey);
        this.hotkeysService.remove(this.editHotkey);
        this.hotkeysService.remove(this.globalColorChangeHotkey);
        this.hotkeysService.remove(this.globalSystemChangeHotkey);
        this.hotkeysService.remove(this.globalGlassChangeHotkey);
        this.hotkeysService.remove(this.globalGeneralChangeHotkey);
        this.hotkeysService.remove(this.globalExchangeRateUpdateHotkey);
        this.hotkeysService.remove(this.globalPricingsUpdateHotkey);
        this.hotkeysService.remove(this.shippingHotkey);
        this.hotkeysService.remove(this.conjunctionHotkey);
    }

    private setGlobalChangeItems(selected: Position[] = []): void {
        let base = 'OFFER.POSITIONS.DIALOGS.';
        const anySelected = selected.length > 0;

        const predefCallback = (pos: Position) => {
            const windowSystemType = WindowSystemType.getByName(pos.windowSystemType);
            return windowSystemType != undefined && WindowSystemType.isPredefinedGlazing(windowSystemType);
        };

        const onlyTerraceSelected = anySelected && selected.every(pos => pos.windowSystemType === WindowSystemType.TERRACE.type);
        const anyPredefinedGlazingSelected = anySelected && selected.some(predefCallback);
        const onlyPredefGlazingSelected = anySelected && selected.every(predefCallback);
        const onlyNonTerraceWindowsSystemsSelected = anySelected && selected.every(pos => pos.type === PositionType.SYSTEM)
            && !selected.some(pos => pos.windowSystemType === WindowSystemType.TERRACE.type);
        const hasNonFixedSubwindows = anySelected && selected.filter(pos => pos.type === PositionType.SYSTEM).some(pos => {
            return WindowCalculator.hasNonFixedSubwindows(new OfferPositionModel(pos).mappedData as DrawingData);
        });
        const sameWindowSystemType = anySelected && selected.every(pos => pos.windowSystemType === selected[0].windowSystemType);
        const sameMaterial = anySelected && selected.every(pos => pos.type === PositionType.SYSTEM && pos.windowSystemMaterial === selected[0].windowSystemMaterial);
        const hasValidationDisabled = anySelected && selected.some(pos => pos.validationDisabled)
            && !this.permissions.isKoordynator() && !this.permissions.isOpiekun();
        const actionsWhenOnlyWindowSystemsSelected = [
            this.createDisablableGlobalChangeItem('SYSTEM', base + 'BULK_WINDOW_SYSTEM_CHANGE.',
                !((onlyNonTerraceWindowsSystemsSelected || onlyTerraceSelected) && sameWindowSystemType) || !sameMaterial),
            this.createDisablableGlobalChangeItem('GENERAL', base + 'BULK_GENERAL_CHANGE.',
                !(onlyNonTerraceWindowsSystemsSelected || onlyTerraceSelected) || !sameMaterial),
            this.createDisablableGlobalChangeItem('COLORS', base + 'BULK_COLOR_CHANGE.',
                !(onlyNonTerraceWindowsSystemsSelected || onlyTerraceSelected) || !sameMaterial),
            this.createDisablableGlobalChangeItem('GLASS', base + 'BULK_GLASS_CHANGE.',
                !((onlyNonTerraceWindowsSystemsSelected && !anyPredefinedGlazingSelected) || onlyTerraceSelected) || !sameMaterial),
            this.createDisablableGlobalChangeItem('PREDEF_GLAZING_PACKAGE', base + 'BULK_PREDEF_GLAZING_PACKAGE_CHANGE.',
                !onlyPredefGlazingSelected || !sameMaterial),
            this.createDisablableGlobalChangeItem('FITTINGS', base + 'BULK_FITTINGS_CHANGE.',
                !(onlyNonTerraceWindowsSystemsSelected || onlyTerraceSelected) || !hasNonFixedSubwindows || !sameMaterial),
            this.createDisablableGlobalChangeItem('CONFIG_ADDONS', base + 'BULK_ADD_CONFIG_ADDONS.',
                    !onlyNonTerraceWindowsSystemsSelected || !sameMaterial),
            this.createDisablableGlobalChangeItem('STANDALONE_GLAZING_PACKAGES', base + 'BULK_ADD_STANDALONE_GLAZING_PACKAGES.',
                !onlyNonTerraceWindowsSystemsSelected || !sameMaterial),
        ];

        let actionsWhenPricingIsUpToDate = [];
        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})) {
            const profitMarginsAllowed = !this.offer.pricingOutdated && anySelected;
            actionsWhenPricingIsUpToDate = [
                this.createDisablableGlobalChangeItem('MARGIN', base + 'BULK_MARGIN_CHANGE.', !profitMarginsAllowed),
                this.createDisablableGlobalChangeItem('RABATE', base + 'BULK_RABATE_CHANGE.', !profitMarginsAllowed)
            ];
        }

        let actionsAvailableToNonVenska = [];
        if (this.isPermittedToUpdateExchangeRate()) {
            actionsAvailableToNonVenska = [
                this.createDisablableGlobalChangeItem('UPDATE_EXCHANGE_RATE', base + 'UPDATE_EXCHANGE_RATE.'),
            ];
        }

        const actionsAlwaysAvailableWhenAnythingSelected = [
            this.createDisablableGlobalChangeItem('UPDATE_PRICINGS', base + 'UPDATE_PRICINGS.', !anySelected)
        ];

        const actionsAlwaysAvailable = [
            this.createDisablableGlobalChangeItem('UPDATE_VAT', base + 'UPDATE_VAT.')
        ];

        this.globalChangeItems = [
            ...actionsWhenOnlyWindowSystemsSelected,
        ];

        if (anySelected && onlyNonTerraceWindowsSystemsSelected && sameMaterial && !selected.some(item => item.type === PositionType.CONFIG_SYSTEM || item.type === PositionType.CONFIGURABLE_ADDON)) {
            this.windowSystemDefinitionService.checkConfigAddonAvailability(selected.map(item => item.windowSystemId)).subscribe(configAddonsAvailable => {
                const addAddonsIndex = this.globalChangeItems.findIndex(item => item.identifier === 'CONFIG_ADDONS');
                if (!this.globalChangeItems[addAddonsIndex].disabled) {
                    this.globalChangeItems[addAddonsIndex].disabled = !configAddonsAvailable;
                }
            });
        }

        this.disableGlobalChangePircingButton = hasValidationDisabled;
        this.disableGlobalChangeButton = this.globalChangeItems.every(item => item.disabled) || hasValidationDisabled;

        this.globalChangePricingItems = [
            ...actionsWhenPricingIsUpToDate,
            ...actionsAvailableToNonVenska,
            ...actionsAlwaysAvailable,
            ...actionsAlwaysAvailableWhenAnythingSelected
        ];
    }

    public isPermittedToUpdateExchangeRate(): boolean {
        return this.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']});
    }

    private createDisablableGlobalChangeItem(identifier: string, translationKeyBase: string, disabled = false): MenuElement {
        const tooltipMessage = translationKeyBase + 'TOOLTIP.' + (disabled ? 'DISABLED' : 'ENABLED');
        return new MenuElementBuilder().setIdentifier(identifier)
            .setTranslationKey(translationKeyBase + 'HEADER')
            .setTooltip(tooltipMessage)
            .setDisabled(disabled).build();
    }

    globalChangeMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        if (this.blockActionIfOldConfigsPresent()) {
            return;
        }
        switch (event.identifier) {
            case 'SYSTEM':
                this.globalChangeClick(BulkWindowSystemChangeDialogData);
                break;
            case 'GENERAL':
                this.globalChangeClick(BulkGeneralChangeDialogData);
                break;
            case 'COLORS':
                this.globalChangeClick(BulkColorChangeDialogData);
                break;
            case 'GLASS':
                this.globalChangeClick(BulkGlassChangeDialogData);
                break;
            case 'FITTINGS':
                this.globalChangeClick(BulkFittingsChangeDialogData);
                break;
            case 'CONFIG_ADDONS':
                this.openGlobalAddConfigAddonDialog();
                break;
            case 'STANDALONE_GLAZING_PACKAGES':
                this.setDisplayedDialog(DialogType.GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE_INFO);
                break;
            case 'PREDEF_GLAZING_PACKAGE':
                this.globalChangeClick(BulkPredefGlazingPackageChangeDialogData);
                break;
            case 'MARGIN':
                this.openProfitMargins();
                break;
            case 'RABATE':
                this.openRabateDialog();
                break;
            case 'UPDATE_EXCHANGE_RATE':
                this.openUpdateExchangeRateDialog();
                break;
            case 'UPDATE_PRICINGS':
                this.openUpdatePricingsDialog();
                break;
            case 'UPDATE_VAT':
                this.openUpdateVatDialog();
                break;
        }
    }

    private blockActionIfOldConfigsPresent(): boolean {
        let allPositions = this.getAllAndSelectedPositions().positions || [];
        if (allPositions.some(pos => pos.configurableAddonDefinitionType != null)) {
            this.growlMessageController.error('ACTION_DISABLED.REMOVE_OLD_CONFIGS');
            return true;
        }
        return false;
    }

    globalChangeClick(bulkChangeDialogData: BulkChangeDialogDataConstructor) {
        let allData = this.getAllAndSelectedPositions();
        this.displayedDialogData = new bulkChangeDialogData(this.id, allData.positions, allData.selectedItems);
        this.setDisplayedDialog(DialogType[this.displayedDialogData.type]);
    }

    private getAllAndSelectedPositions(): { positions: Position[], selectedItems: Position[] } {
        let allData = {positions: [], selectedItems: []};
        return _.values<PositionTableData>(this.tableDataBySupplier)
            .filter(tableData => tableData != undefined)
            .concat(this.ownAddonsData != undefined ? [this.ownAddonsData] : [])
            .concat(this.assembliesData != undefined ? [this.assembliesData] : [])
            .concat(this.transportsData != undefined ? [this.transportsData] : [])
            .reduce((previousValue, tableData) => {
                previousValue.positions.push(...tableData.positions);
                previousValue.selectedItems.push(...tableData.selectedItems);
                return previousValue;
            }, allData);
    }

    getAllNotOwnPositions(): Position[] {
        return _.values<PositionTableData>(this.tableDataBySupplier)
            .filter(tableData => tableData != undefined)
            .reduce((positions, tableData) => {
                positions.push(...tableData.positions);
                return positions;
            }, []);
    }

    private openGlobalAddConfigAddonDialog() {
        let allData = this.getAllAndSelectedPositions();
        this.displayedDialogData =
            new GlobalAddConfigAddonsDialogData(allData.selectedItems.filter(p => p.type === PositionType.SYSTEM)
                .map(item => JSON.parse(JSON.stringify(item))));
        this.setDisplayedDialog(DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON);
    }

    openGlobalAddStandaloneGlazingPackagesDialog(): void {
        let selected = this.getAllAndSelectedPositions().selectedItems;
        this.displayedDialogData = new AddStandaloneGlazingPackagesGloballyDialogData(selected);
        this.setDisplayedDialog(DialogType.GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE);
    }

    openUpdateExchangeRateDialog(): void {
        if (this.isPermittedToUpdateExchangeRate()) {
            this.displayedDialogData = new UpdateExchangeRateDialogData(this.offer.id, this.offer.currency,
                this.exchangeRatePercentDifference, this.exchangeRateWarningStatus, this.subsystemCurrencyData);
            this.setDisplayedDialog(DialogType.UPDATE_EXCHANGE_RATE);
        }
    }

    openUpdatePricingsDialog(forceAll = false): void {
        let selectedItems = [];
        for (let supplierId in this.tableDataBySupplier) {
            let tableData = this.tableDataBySupplier[supplierId];
            if (tableData == undefined) {
                continue;
            }
            for (let item of tableData.selectedItems) {
                selectedItems.push(item.id);
            }
        }
        if (this.assembliesData != null) {
            for (let item of this.assembliesData.selectedItems) {
                selectedItems.push(item.id);
            }
        }
        if (this.ownAddonsData != null) {
            for (let item of this.ownAddonsData.selectedItems) {
                selectedItems.push(item.id);
            }
        }
        if (this.transportsData != null) {
            for (let item of this.transportsData.selectedItems) {
                selectedItems.push(item.id);
            }
        }
        let listOfIds = new ListOfIds();
        listOfIds.ids = selectedItems;
        this.displayedDialogData = new UpdatePricingsDialogData(listOfIds, this.offer.id, forceAll);
        this.setDisplayedDialog(DialogType.UPDATE_PRICINGS);
    }

    showAnnotations() {
        this.setDisplayedDialog(DialogType.CHANGE_ANNOTATIONS);
    }

    private openUpdateVatDialog(): void {
        this.displayedDialogData = new UpdateVatDialogData(this.offer.id);
        this.setDisplayedDialog(DialogType.UPDATE_VAT);
    }

    openConjunctionsDialog(): void {
        this.conjunctionService.getDialogData(this.id).subscribe({
            next: (data: ConjunctionDialogDto[]) => {
                this.displayedDialogData = new ConjunctionsDialogData(data);
                this.setDisplayedDialog(DialogType.CONJUNCTIONS);
            },
            error: (error: HttpErrorResponse) => {
                let errorResponse = new ErrorResponse(error.error);
                if (errorResponse.is404()) {
                    this.navigateBack(false);
                } else {
                    this.errors.handle(error);
                }
            }
        });
    }

    editOffer() {
        if (this.isComplaint) {
            this.editComplaint();
        } else {
            let clientIdToUse = this.permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']})
                ? this.offer.sellerClientId
                : this.offer.clientId;
            this.router.navigate(['.', AccessData.pathSuffix.settings, {
                mode: CreateOfferMode.EDIT,
                userId: this.offer.merchantId,
                clientId: clientIdToUse
            }], {
                relativeTo: this.route
            });
        }

        return false;
    }

    navigateToSubsystemSettings() {
        this.router.navigate(['/features/settings/subsystem']);
    }

    clickedRowChange(event) {
        if (this.lastSelectedRow === event) {
            return;
        }
        this.lastSelectedRow = event;
        this.selectionChange.emit({data: event});
    }

    selectedRowCountChange() {
        let selectedItemsCount = this.countSelectedElement();
        this.showDeleteButton = selectedItemsCount > 0;
        this.updateShippingButtonVisibility();

        const {selectedItems} = this.getAllAndSelectedPositions();
        if (this.isComplaint) {
            this.selectedComplaintPositions = selectedItems;
            this.selectedComplaintPositionsIds = this.selectedComplaintPositions.map(pos => pos.id);
        } else {
            this.setGlobalChangeItems(selectedItems);
        }
    }

    redirectToOffersListComponent() {
        if (!!this.enteredFromSearchView) {
            this.router.navigate(['/features/offer', {
                component: 'offer-search'
            }], {
                queryParams: {
                    searchedValue: this.enteredFromSearchView
                }
            });
        } else {
            this.router.navigate(['/features/offer', {
                component: this.isOrder ? 'order' : 'offer',
                showPageWarning: true,
                lastSelection: this.id
            }]);
        }
        return false;
    }

    redirectToComplaintListComponent() {
        this.router.navigate(['/features/offer', {
            component: 'complaint',
            showPageWarning: true,
            lastSelection: this.complaint.id
        }]);
        return false;
    }

    redirectToOfferPositionList(offerId: number): void {
        this.router.navigate(['/features/offer', offerId, 'position']);
    }

    navigateBack(unblock = true) {
        if (this.isComplaint) {
            return this.redirectToComplaintListComponent();
        } else if (this.isProductionOrder) {
            return this.redirectToProductionOrdersListComponent();
        }
        const exitOfferPositionList = () => {
            if (this.currentUserService.restrictedToOfferNumber == undefined) {
                this.redirectToOffersListComponent();
            } else {
                this.auth.logout(false);
                this.router.navigate(['.', AccessData.pathSuffix.externalOfferEditDone], {
                    relativeTo: this.route
                });
            }
        };
        if (unblock && this.hasOfferLock) {
            this.blockUiController.block('PositionComponentExit');
            this.offerService.unblockOffer(this.id)
                .pipe(finalize(() => this.blockUiController.unblock('PositionComponentExit')))
                .subscribe({
                    error: (error) => this.errors.handle(error),
                    complete: () => {
                        exitOfferPositionList();
                    }
                });
        } else {
            exitOfferPositionList();
        }
    }

    redirectToProductionOrdersListComponent() {
        let backToFilteredValue = this.route.snapshot.queryParams[PositionListComponent.BACK_TO_FILTERED];
        if (backToFilteredValue) {
            this.storage.set(StorageKey.PROD_ORDER, backToFilteredValue);
        }

        this.router.navigate(['/features/offer', {component: 'production-order'}]);
    }

    archiveOffer() {
        if (this.isComplaint) {
            return;
        } else if (this.isOrder && !OfferStatusProvider.isCorrectionStatus(this.offer.status)) {
            return;
        } else {
            this.blockUiController.block('PositionComponentExit');
            this.offerService.archiveOffer(this.id).pipe(
                finalize(() => this.blockUiController.unblock('PositionComponentExit')),
                mergeMap(() => this.offerService.getCurrentVersionStatus(this.offer.id))
            ).subscribe({
                next: currentVersionStatus => {
                    this.offer.archiveVersionNumber = currentVersionStatus.archiveVersionNumber;
                    this.hasDifferencesFromLastArchivedVersion = currentVersionStatus.hasDifferencesFromLastArchivedVersion;
                    this.changeDetector.markForCheck();
                },
                error: error => {
                    this.reloadAllTables();
                    this.errors.handle(error);
                }
            });
        }
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    getExportData(): Observable<object[]> {
        return undefined;
    }

    private createTable() {
        let shownColumns = this.getShownColumns();
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('windowSystemName', 'OFFER.POSITIONS.FORM.SYSTEM', true).makeFilterable()
            .add('name', 'OFFER.POSITIONS.FORM.NAME', true).makeFilterable()
            .add('parentPrintOrder', 'OFFER.POSITIONS.FORM.PARENT_POSITION', true).makeSortable()
            .add('dimensions', 'OFFER.POSITIONS.FORM.DIMENSIONS', true).makeFilterable()
            .add('quantity', 'OFFER.POSITIONS.FORM.QUANTITY', true).makeFilterable().makeSortable()
            .add('unit', 'OFFER.POSITIONS.FORM.UNIT', true);
        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})) {
            builder
                .add('profitMargin', 'OFFER.POSITIONS.FORM.MARKUP', true).makeFilterable().makeSortable()
                .add('rabate', 'OFFER.POSITIONS.FORM.RABATE', true).makeFilterable().makeSortable();
        }

        if (this.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
            builder
                .add('sellPrice.netValue', 'OFFER.POSITIONS.FORM.PRICE_SELL_NET_FOR_SELLER', true).makeFilterable().makeSortable()
                .add('sellPrice.vatPercent', 'OFFER.POSITIONS.FORM.VAT_SELL_FOR_SELLER', true).makeFilterable().makeSortable()
                .add('sellPrice.grossValue', 'OFFER.POSITIONS.FORM.PRICE_SELL_GROSS_FOR_SELLER', false).makeFilterable().makeSortable()
                .add('sellValue.netValue', 'OFFER.POSITIONS.FORM.VALUE_SELL_NET_FOR_SELLER', true).makeFilterable().makeSortable()
                .add('sellValue.grossValue', 'OFFER.POSITIONS.FORM.VALUE_SELL_GROSS_FOR_SELLER', false).makeFilterable().makeSortable();
        } else {
            builder.add('sellPrice.netValue', 'OFFER.POSITIONS.FORM.PRICE_SELL_NET', true).makeFilterable().makeSortable();
            if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
                builder.add('buyPrice.vatPercent', 'OFFER.POSITIONS.FORM.VAT_BUY', true).makeFilterable().makeSortable();
            } else {
                builder.add('sellPrice.vatPercent', 'OFFER.POSITIONS.FORM.VAT_SELL', true).makeFilterable().makeSortable();
            }
            builder
                .add('sellPrice.grossValue', 'OFFER.POSITIONS.FORM.PRICE_SELL_GROSS', false).makeFilterable().makeSortable()
                .add('sellValue.netValue', 'OFFER.POSITIONS.FORM.VALUE_SELL_NET', true).makeFilterable().makeSortable()
                .add('sellValue.grossValue', 'OFFER.POSITIONS.FORM.VALUE_SELL_GROSS', false).makeFilterable().makeSortable();
        }

        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC']})) {
            builder
                .add('buyPrice.netValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_NET', true).makeFilterable().makeSortable()
                .add('buyPrice.grossValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_GROSS', false).makeFilterable().makeSortable()
                .add('buyValue.netValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_NET', true).makeFilterable().makeSortable()
                .add('buyValue.grossValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_GROSS', false).makeFilterable().makeSortable();
        }
        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR']})) {
            builder.add('netIncome', 'OFFER.POSITIONS.FORM.NET_INCOME', true).makeFilterable().makeSortable();
            builder.add('grossIncome', 'OFFER.POSITIONS.FORM.GROSS_INCOME', false).makeFilterable().makeSortable();
        }
        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})) {
            builder.add('retailSellPrice.netValue', 'OFFER.FORM.RETAIL_SELL_NET_PRICE', true).makeFilterable().makeSortable();
            builder.add('retailSellPrice.vatPercent', 'OFFER.POSITIONS.FORM.VAT_RETAIL_SELL', true).makeFilterable().makeSortable();
            builder.add('retailSellPrice.grossValue', 'OFFER.FORM.RETAIL_SELL_GROSS_PRICE', false).makeFilterable().makeSortable();
            builder.add('retailSellValue.netValue', 'OFFER.FORM.RETAIL_SELL_NET_VAL', true).makeFilterable().makeSortable();
            builder.add('retailSellValue.grossValue', 'OFFER.FORM.RETAIL_SELL_GROSS_VAL', false).makeFilterable().makeSortable();
            builder.add('netRetailIncome', 'OFFER.FORM.NET_RETAIL_INCOME', true).makeFilterable().makeSortable();
            builder.add('grossRetailIncome', 'OFFER.FORM.GROSS_RETAIL_INCOME', false).makeFilterable().makeSortable();
        }
        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('venskaBuyPrice.netValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_VENSKA_NET', true).makeFilterable().makeSortable();
            builder.add('venskaBuyPrice.grossValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_VENSKA_GROSS', false).makeFilterable().makeSortable();
            builder.add('venskaBuyValue.netValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_NET', true).makeFilterable().makeSortable();
            builder.add('venskaBuyValue.grossValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_GROSS', false).makeFilterable().makeSortable();
            builder.add('netVenskaIncome', 'OFFER.POSITIONS.FORM.NET_INCOME', true).makeFilterable().makeSortable();
            builder.add('grossVenskaIncome', 'OFFER.POSITIONS.FORM.GROSS_INCOME', false).makeFilterable().makeSortable();
            builder.add('dealerDiscount', 'OFFER.POSITIONS.FORM.DEALER_DISCOUNT', false).makeFilterable();
            builder.add('tempDealerDiscount', 'OFFER.POSITIONS.FORM.TEMP_DEALER_DISCOUNT', false).makeFilterable();
            builder.add('basePrice', 'OFFER.POSITIONS.FORM.BASE_PRICE', false).makeFilterable();
            builder.add('realDimensions', 'OFFER.POSITIONS.FORM.REAL_DIMENSIONS', false);
        }
        super.init(builder.build());
    }

    private createProductionOrderTable() {
        let shownColumns = this.getShownColumns();
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('name', 'OFFER.POSITIONS.FORM.NAME', true).makeFilterable()
            .add('dimensions', 'OFFER.POSITIONS.FORM.DIMENSIONS', true).makeFilterable()
            .add('realDimensions', 'OFFER.POSITIONS.FORM.REAL_DIMENSIONS', true)
            .add('quantity', 'OFFER.POSITIONS.FORM.QUANTITY', true).makeFilterable().makeSortable()
            .add('unit', 'OFFER.POSITIONS.FORM.UNIT', true)
            .add('rabate', 'OFFER.POSITIONS.FORM.RABATE', true).makeFilterable().makeSortable()
            .add('venskaBuyPrice.vatPercent', 'OFFER.POSITIONS.FORM.VENSKA_BUY_VAT_PERCENT', true).makeFilterable().makeSortable()
            .add('buyPrice.vatPercent', 'OFFER.POSITIONS.FORM.BUY_VAT_PERCENT', true).makeFilterable().makeSortable()
            .add('buyPrice.netValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_NET', true).makeFilterable().makeSortable()
            .add('buyPrice.grossValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_GROSS', false).makeFilterable().makeSortable()
            .add('buyValue.netValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_NET', true).makeFilterable().makeSortable()
            .add('buyValue.grossValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_GROSS', false).makeFilterable().makeSortable()
            .add('venskaBuyPrice.netValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_VENSKA_NET', true).makeFilterable().makeSortable()
            .add('venskaBuyPrice.grossValue', 'OFFER.POSITIONS.FORM.PRICE_BUY_VENSKA_GROSS', false).makeFilterable().makeSortable()
            .add('venskaBuyValue.netValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_NET', true).makeFilterable().makeSortable()
            .add('venskaBuyValue.grossValue', 'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_GROSS', false).makeFilterable().makeSortable()
            .add('netVenskaIncome', 'OFFER.POSITIONS.FORM.NET_INCOME', true).makeFilterable().makeSortable()
            .add('grossVenskaIncome', 'OFFER.POSITIONS.FORM.GROSS_INCOME', false).makeFilterable().makeSortable()
            .add('dealerDiscount', 'OFFER.POSITIONS.FORM.DEALER_DISCOUNT', false)
            .add('tempDealerDiscount', 'OFFER.POSITIONS.FORM.TEMP_DEALER_DISCOUNT', false)
            .add('tempSystemDealerDiscount', 'OFFER.PRODUCTION_ORDERS.TEMP_SYSTEM_DEALER_DISCOUNT', false)
            .add('basePrice', 'OFFER.POSITIONS.FORM.BASE_PRICE', false);
        super.init(builder.build());
    }

    onDisplayedColumnsRemoved(removedColumns: string[]): void {
        if (!this.isProductionOrder) {
            const clearFiltersFromPositionListTable = (positionTable: PositionListTableComponent) => {
                const dataTable = positionTable.getDataTable();
                for (let removedColumn of removedColumns) {
                    if (dataTable.filters[removedColumn] != undefined) {
                        dataTable.filter(undefined, removedColumn, undefined);
                    }
                }
            };
            this.positionTables.forEach(clearFiltersFromPositionListTable);
            if (this.ownAddonsPositionTable != undefined) {
                clearFiltersFromPositionListTable(this.ownAddonsPositionTable);
            }
            if (this.assembliesPositionTable != undefined) {
                clearFiltersFromPositionListTable(this.assembliesPositionTable);
            }
        } else if (this.productionOrderPositionTable != undefined) {
            for (let removedColumn of removedColumns) {
                if (this.productionOrderPositionTable.filters[removedColumn] != undefined) {
                    this.productionOrderPositionTable.filter(undefined, removedColumn, undefined);
                }
            }
        }
    }

    private canEditPosition(position: Position) {
        if (this.isComplaint) {
            return true;
        } else if (this.canEdit) {
            if (position.sortGroup !== PositionSortGroup.SYSTEM) {
                return this.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']});
            }
            return true;
        }
        return false;
    }

    editActionOnClick(position: AbstractPosition): void {
        console.info("PositionListComponent: clicked action 'EDIT' for position: " + position.id);

        if (this.blockUiController.isBlocked()) {
            console.info("PositionListComponent: can't perform action 'EDIT', position or table operation is in progress");

            return;
        }
        this.currentActionPosition = position as Position;
        this.selectionChange.emit({data: position});
        this.openPositionEditForm(position);
    }

    actionOnClick(action: string, position: Position) {
        console.info("PositionListComponent: clicked action '" + action + "' for position: " + position.id);

        if (this.blockUiController.isBlocked()) {
            console.info("PositionListComponent: can't perform action '" + action + "', " +
                "position or table operation is in progress'");

            return;
        }

        this.currentActionPosition = position;
        this.selectionChange.emit({data: position});

        switch (action) {
            case 'EDIT':
                this.openPositionEditForm(position);
                break;
            case 'PRICE_CHANGE':
                if (this.canEditPosition(position)) {
                    this.openPriceChangeDialog();
                }
                break;
            case 'DELETE':
                this.itemsToRemove = [position];
                this.areParentsSelected = this.selectedPositionsHaveConfigAddons([position.id]);
                if (this.isComplaint) {
                    this.showRemoveSelectedComplaintsDialog([position]);
                } else {
                    this.setDisplayedDialog(DialogType.REMOVE_POSITION);
                }
                break;
            case 'POSITION_MESSAGES':
                this.setDisplayedDialog(DialogType.POSITION_MESSAGES);
                break;
            case 'COPY':
                this.blockUiController.block(PositionListComponent.COPY_POSITION_BLOCK_ID);
                this.positionService.copyItem(position.id).subscribe({
                    next: (affectedSupplierIds: number[]): void => {
                        if (position.sortGroup !== PositionSortGroup.SYSTEM) {
                            this.refreshAfterAction(action, false, false, position);
                        } else {
                            for (let supplierId of affectedSupplierIds) {
                                this.reloadTableForSupplier(supplierId);
                            }
                        }
                    },
                    error: error => {
                        this.errors.handle(error);
                        this.setSaveInProgress(false);
                        this.changeDetector.markForCheck();
                        this.blockUiController.unblock(PositionListComponent.COPY_POSITION_BLOCK_ID);
                    },
                    complete: (): void => {
                        console.info('OffersComponent action `COPY` completed!');
                        this.resetDialog();
                        this.setSaveInProgress(false);
                        this.changeDetector.markForCheck();
                        this.blockUiController.unblock(PositionListComponent.COPY_POSITION_BLOCK_ID);
                    }
                });
                break;
            case 'COMMENTS':
                this.displayedDialogData = new CommentsDialogData(position.supplierId);
                this.setDisplayedDialog(DialogType.COMMENTS);
                break;
            case 'SHOW_DESCRIPTION':
                this.setDisplayedDialog(DialogType.SHOW_DESCRIPTION);
                break;
            case 'MOVE_UP':
                this.positionService.moveUp(position.id).subscribe({
                    complete: () => {
                        const table = this.getTableForPosition(position);
                        const tableData = table.tableData;
                        if (position.printOrder === tableData.fromRecord) {
                            // moved up first position on page, reload data
                            this.handlePrintOrderChange(position);
                        } else {
                            // simple element swap
                            let positionIndex = tableData.positions.indexOf(position);
                            let otherPosition = tableData.positions[positionIndex - 1];
                            --position.printOrder;
                            ++otherPosition.printOrder;
                            tableData.positions = [
                                ...tableData.positions.slice(0, positionIndex - 1),
                                position,
                                otherPosition,
                                ...tableData.positions.slice(positionIndex + 1)
                            ];
                            table.refreshTable(false);
                            this.updateConfigAddonsParentPrintOrder(position, otherPosition);
                        }
                    },
                    error: (error) => {
                        const validationErrors = this.errors.handle(error);
                        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
                            this.growlMessageController.error(validationErrors['printOrder']);
                        }
                    }
                });
                break;
            case 'MOVE_DOWN':
                this.positionService.moveDown(position.id).subscribe({
                    complete: () => {
                        const table = this.getTableForPosition(position);
                        const tableData = table.tableData;
                        if (position.printOrder === tableData.toRecord) {
                            // moved down last position on page, reload data
                            this.handlePrintOrderChange(position);
                        } else {
                            // simple element swap
                            let positionIndex = tableData.positions.indexOf(position);
                            let otherPosition = tableData.positions[positionIndex + 1];
                            ++position.printOrder;
                            --otherPosition.printOrder;
                            tableData.positions = [
                                ...tableData.positions.slice(0, positionIndex),
                                otherPosition,
                                position,
                                ...tableData.positions.slice(positionIndex + 2)
                            ];
                            table.refreshTable(false);
                            this.updateConfigAddonsParentPrintOrder(position, otherPosition);
                        }
                    },
                    error: (error) => {
                        const validationErrors = this.errors.handle(error);
                        if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
                            this.growlMessageController.error(validationErrors['printOrder']);
                        }
                    }
                });
                break;
            case 'SET_PRINT_ORDER':
                this.displayedDialogData = new SetPrintOrderDialogData(position);
                this.setDisplayedDialog(DialogType.SET_PRINT_ORDER);
                break;
            case 'DISABLE_VALIDATION':
                this.displayedDialogData = new DisableValidationDialogData(position);
                this.setDisplayedDialog(DialogType.DISABLE_VALIDATION);
                break;
            default:
                console.error("PositionListComponent: clicked action '" + action + "' is not supported!");
                break;
        }
    }

    private updateConfigAddonsParentPrintOrder(movedPosition: Position, otherPosition: Position) {
        const allDisplayedPositions: Position[] = _.flatten(Object.values(this.tableDataBySupplier)
            .map((tableData: PositionTableData) => tableData.positions));
        allDisplayedPositions
            .filter(pos => pos.parentOfferPositionId === movedPosition.id)
            .forEach(pos => pos.parentPrintOrder = movedPosition.printOrder);
        allDisplayedPositions
            .filter(pos => pos.parentOfferPositionId === otherPosition.id)
            .forEach(pos => pos.parentPrintOrder = otherPosition.printOrder);
        this.positionTables.forEach(table => table.refreshTable(false));
    }

    private getSuppliersWithChildPositions(parentPosition: Position) {
        return Object.entries(this.tableDataBySupplier)
            .filter(e => e[1].positions.some(childPosition => childPosition.parentOfferPositionId === parentPosition.id))
            .map(e => +e[0]);
    }

    addPositionMenuElementSelected(event: ButtonWithMenuElementSelectedEvent) {
        switch (event.identifier) {
            case 'addSystem': {
                this.openWindowDesigner('new', ProductTypeGroup.DEFAULT);
                break;
            }
            case 'addEntranceSystem': {
                this.openWindowDesigner('new', ProductTypeGroup.ENTRANCE);
                break;
            }
            case 'addTerraceSystem': {
                this.openWindowDesigner('new', ProductTypeGroup.TERRACE);
                break;
            }
            case 'addRoofSystem': {
                this.openWindowDesigner('new', ProductTypeGroup.ROOF);
                break;
            }
            case 'addBulkAddon': {
                this.showBulkAddonAddDialog(false);
                break;
            }
            case 'addOwnBulkAddon': {
                this.showBulkAddonAddDialog(true);
                break;
            }
            case 'addAssembly': {
                this.showAssemblyAddDialog();
                break;
            }
            case 'addTransport': {
                this.showTransportAddDialog();
                break;
            }
            case 'addConfigurableAddon': {
                this.addConfigurableAddonNew();
                break;
            }
            case 'addOtherSystemAddon': {
                this.openGateDesigner('new');
                break;
            }
            case 'addStandaloneGlazingPackage':
                this.positionToEdit = undefined;
                this.setDisplayedDialog(DialogType.ADD_STANDALONE_GLAZING_PACKAGE);
                break;
        }
    }

    private openEditComplaintPositionDialog(): void {
        this.validationErrors = {};
        this.displayedDialogData = new EditComplaintPositionDialogData(Object.assign({}, this.getSelectedComplaintPosition()));

        this.displayedDialog = DialogType.EDIT_COMPLAINT_POSITION;
    }

    getSelectedComplaintPosition(): ComplaintPosition {
        if (this.currentActionPosition == null || this.complaint == null) {
            this.selectedComplaintPosition = null;
        } else {
            this.selectedComplaintPosition = this.complaint.positions.find(
                complaintPosition => complaintPosition.offerPositionId === this.currentActionPosition.id);
        }

        return this.selectedComplaintPosition;
    }

    private openPriceChangeDialog() {
        this.displayedDialogData = new PriceChangeDialogData(this.currentActionPosition,
            this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}), Currencies[this.offer.currency], this.offer.exchangeRate);
        this.setDisplayedDialog(DialogType.PRICE_CHANGE);
    }

    public submitDialog(event?: SubmitStatus | BulkChangeFrontendWarning[]) {
        switch (this.displayedDialog) {
            case DialogType.BULK_WINDOW_SYSTEM_CHANGE:
            case DialogType.BULK_GLASS_CHANGE:
            case DialogType.BULK_FITTINGS_CHANGE:
                this.submitBulkChangeDialog();
                break;
            case DialogType.BULK_GENERAL_CHANGE:
            case DialogType.BULK_COLOR_CHANGE:
            case DialogType.BULK_PREDEF_GLAZING_PACKAGE_CHANGE:
                this.submitBulkChangeDialog(event as BulkChangeFrontendWarning[]);
                break;
            case DialogType.BULK_CHANGE_CONFIRMATION:
                this.submitBulkChangeConfirmationDialog();
                break;
            case DialogType.GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION:
                this.submitGlobalConfigAddonsAddConfirmationDialog(event as SubmitStatus);
                break;
            case DialogType.EDIT_COMPLAINT_POSITION:
                this.submitEditComplaintPosition();
                break;
            case DialogType.DISABLE_VALIDATION: {
                const offerPosition = this.dialogDataSelector.getData(this.displayedDialogData, 'DISABLE_VALIDATION').offerPosition;
                this.positionService.disableValidation(offerPosition.id).pipe(
                    finalize(() => this.resetDialog())
                ).subscribe({
                    next: () => {
                        offerPosition.validationDisabled = true;
                        this.showActionSuccessMessage('DISABLE_VALIDATION');
                        this.setShowWindowUpsellingButton();
                        this.reloadAllTables();
                    },
                    error: () => {
                        this.showActionErrorMessage('DISABLE_VALIDATION');
                    }
                });
                break;
            }
            case DialogType.CONFIRM_PARTIAL_SIMULATION:
                this.addToShippingSimulation(true);
                this.resetDialog();
                break;
            default:
                break;
        }
    }

    private submitEditComplaintPosition(): void {
        let dialogData = this.dialogDataSelector.getData(this.displayedDialogData, 'EDIT_COMPLAINT_POSITION');
        let complaintId = dialogData.complaintPosition.complaintId;
        let offerPositionId = dialogData.complaintPosition.offerPositionId;

        this.complaintService.saveComplaintPosition(dialogData.complaintPosition).pipe(
            mergeMap(() => forkJoin({
                movie: dialogData.movieFile != undefined
                    ? this.complaintService.saveComplaintPositionMovie(complaintId, offerPositionId, dialogData.movieFile) : of(undefined),
                photo: dialogData.photoFile != undefined
                    ? this.complaintService.saveComplaintPositionPhoto(complaintId, offerPositionId, dialogData.photoFile) : of(undefined)
            }))
        ).subscribe({
            next: () => {
                this.genericDialogSuccess('EDIT_COMPLAINT_POSITION', true, false, false, this.currentActionPosition);
            },
            error: (error) => {
                this.genericDialogError('EDIT_COMPLAINT_POSITION', error, this.currentActionPosition);
            }
        });
    }

    submitAddAssemblyDialog() {
        this.showActionSuccessMessage('ADD_ASSEMBLY');
        if (this.assembliesData == undefined) {
            this.reloadAllTables();
        } else {
            this.reloadAssembliesTable();
        }
        this.resetDialog();
    }

    submitAddTransportDialog() {
        this.showActionSuccessMessage('ADD_TRANSPORT');
        if (this.transportsData == undefined) {
            this.reloadAllTables();
        } else {
            this.reloadTransportsTable();
        }
        this.resetDialog();
    }

    priceChangeSuccessful(actionMessage): void {
        this.genericDialogSuccess(actionMessage, true, false, false, this.currentActionPosition);
    }

    saveRotData(rotData: RotData): void {
        this.displayedDialogData = this.getRotDataDialogData(false);
        let observable = this.rotData == null ? this.rotDataService.createRotData(rotData)
            : this.rotDataService.updateRotData(rotData, this.id);
        observable.pipe(
            finalize(() => this.displayedDialogData = this.getRotDataDialogData(this.canEdit))
        ).subscribe({
            next: () => {
                this.rotData = rotData;
                this.genericDialogSuccess('ROT_DATA.SAVED', true, false, false, this.currentActionPosition);
            },
            error: error => {
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            }
        });
    }

    rabateDialogSubmitted(event: { success: boolean, rabate: number }): void {
        if (event) {
            if (event.success) {
                const data = this.dialogDataSelector.getData(this.displayedDialogData, 'RABATE_MARKUP');
                if (!data.positionList) {
                    this.offer.rabate = event.rabate;
                }
                this.showActionSuccessMessage(data.isMarkup ? 'PROFIT_MARGINS' : 'RABATE');
            }
        }
        this.resetDialog();
        this.reloadAllTables();
    }

    private submitBulkChangeDialog(event: BulkChangeFrontendWarning[] = []): void {
        let dialogData = this.displayedDialogData as BulkChangeDialogData;
        this.blockUiController.block(PositionListComponent.BULK_CHANGE_BLOCK_ID);
        this.positionService.beginBulkChange(dialogData.modifiedPositions, dialogData.type)
            .subscribe({
                next: changeData => {
                    this.displayedDialogData = new BulkChangeConfirmationDialogData(changeData, dialogData.modifiedPositions,
                        this.displayedDialog);
                    this.addKnownWarnings(this.displayedDialogData, event);
                    switch (this.displayedDialog) {
                        case DialogType.BULK_GENERAL_CHANGE:
                        case DialogType.BULK_GLASS_CHANGE:
                        case DialogType.BULK_WINDOW_SYSTEM_CHANGE:
                            this.performFrontValidation(this.displayedDialogData);
                            break;
                        default:
                            this.setDisplayedDialog(DialogType.BULK_CHANGE_CONFIRMATION);
                            this.blockUiController.unblock(PositionListComponent.BULK_CHANGE_BLOCK_ID);
                            break;
                    }
                },
                error: (error) => {
                    this.blockUiController.unblock(PositionListComponent.BULK_CHANGE_BLOCK_ID);
                    this.errors.handle(error);
                }
            });
    }

    private addKnownWarnings(displayedDialogData: BulkChangeConfirmationDialogData,
                             warnings: BulkChangeFrontendWarning[]) {
        for (let warning of warnings) {
            displayedDialogData.changeResult.messagesByPosition[warning.positionId].push(warning.message);
        }
    }

    private submitBulkChangeConfirmationDialog(): void {
        let dialogData = this.displayedDialogData as BulkChangeConfirmationDialogData;
        let positionsToRedraw = dialogData.modifiedPositions.filter(
            modified => {
                return !dialogData.changeResult.hasValidationErrors(modified.id);
            });
        if (dialogData.accepted && positionsToRedraw.length > 0) {
            this.loadDrawer = true;
            this.changeDetector.markForCheck();
            this.blockUiController.block(PositionListComponent.BULK_CHANGE_BLOCK_ID);
        } else {
            this.resetDialog();
        }
    }

    private tryAddingConfigAddonsGlobally(globalAddConfigAddonsData: GlobalAddConfigAddonsData, isUpselling: boolean) {
        let addon = globalAddConfigAddonsData.addonModels[0].configurableAddon;

        let windowPositionsIds = new ListOfIds();
        windowPositionsIds.ids = globalAddConfigAddonsData.windowPositions.map(pos => pos.id);

        this.pricingService.tryAddingAddonsGlobally(this.id, addon, windowPositionsIds).subscribe({
            next: resultList => {
                const orderedWindowPositions = globalAddConfigAddonsData.windowPositions.sort((p1, p2) => p1.printOrder - p2.printOrder);
                this.displayedDialogData = new GlobalConfigAddonsAddConfirmationDialogData(orderedWindowPositions, resultList.data, addon, globalAddConfigAddonsData.configurableAddonName, isUpselling);
                this.setDisplayedDialog(DialogType.GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    private submitGlobalConfigAddonsAddConfirmationDialog(submitStatus: SubmitStatus): void {
        if (submitStatus === SubmitStatus.OK) {
            this.refreshAfterAction(DialogType[DialogType.GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION], false,
                false, undefined);
            this.reloadAllTables();
        }
        this.resetDialog();
    }

    submitEditAssemblyDialog(): void {
        let dialogData = this.dialogDataSelector.getData(this.displayedDialogData, 'EDIT_ASSEMBLY_OR_TRANSPORT');
        this.refreshAfterAction('EDIT_ASSEMBLY', false, false, dialogData.editedPosition);
    }

    submitEditTransportDialog(): void {
        let dialogData = this.dialogDataSelector.getData(this.displayedDialogData, 'EDIT_ASSEMBLY_OR_TRANSPORT');
        this.refreshAfterAction('EDIT_TRANSPORT', false, false, dialogData.editedPosition);
    }

    public resetDialog() {
        this.setDisplayedDialog(undefined);
        this.displayedDialogData = undefined;
        this.configAddon = undefined;
        this.currentActionPosition = undefined;
        this.rabate = undefined;
        this.validationErrors = {};
        this.addon = undefined;
        this.availableInsideColors = [];
        this.availableOutsideColors = [];
        this.imagePreview = undefined;
        this.areParentsSelected = false;
        this.positionDisabled = false;
        this.restoreSelectionAndResetHotkeys();
        this.registerHotkeys();
    }

    printConjunctions(conjunctionIds: number[]) {
        this.resetDialog();
        this.selectedConjunctionsIds = conjunctionIds;
        this.showPrintDialog();
    }

    private genericDialogSuccess(actionName: string, closeDialog: boolean, finishedWithWarning: boolean, finishedWithError,
                                 position: Position) {
        this.refreshAfterAction(actionName, finishedWithWarning, finishedWithError, position);
        console.info('OffersComponent action `' + actionName + '` completed!');
        if (closeDialog) {
            this.resetDialog();
        }
        this.setSaveInProgress(false);
    }

    genericDialogError(actionName: string, error: HttpErrorResponse | Error, position: Position) {
        this.errors.handle(error);
        this.resetDialog();
        this.refreshAfterAction(actionName, false, true, position);
        this.setSaveInProgress(false);
        this.changeDetector.markForCheck();
    }

    public refreshAfterAction(actionName: string, finishedWithWarning: boolean, finishedWithError: boolean,
                              position: Position, concernBulkAddons = false) {
        if (finishedWithWarning) {
            if (!concernBulkAddons) {
                this.showActionWarningMessage(actionName);
            }
        } else if (finishedWithError) {
            this.showActionErrorMessage(actionName);
        } else {
            this.showActionSuccessMessage(actionName);
        }
        if (DialogType[actionName] === DialogType.EDIT_CONFIG_ADDON) {
            this.selectionChange.emit({data: this.lastSelectedRow});
        }
        if (position == undefined
            || (position.sortGroup === PositionSortGroup.SYSTEM && this.tableDataBySupplier[position.supplierId] == undefined)
            || (position.sortGroup === PositionSortGroup.OWN_ADDON && this.ownAddonsData == undefined)
            || (position.sortGroup === PositionSortGroup.ASSEMBLY && this.assembliesData == undefined)
            || (position.sortGroup === PositionSortGroup.OWN_TRANSPORT && this.transportsData == undefined)) {
            this.reloadAllTables();
        } else if (position.sortGroup === PositionSortGroup.SYSTEM) {
            this.reloadAllTables();
        } else if (position.sortGroup === PositionSortGroup.OWN_ADDON) {
            this.reloadOwnAddonsTable();
        } else if (position.sortGroup === PositionSortGroup.ASSEMBLY) {
            this.reloadAssembliesTable();
        } else if (position.sortGroup === PositionSortGroup.OWN_TRANSPORT) {
            this.reloadTransportsTable();
        }
    }

    protected showActionSuccessMessage(actionName: string): void {
        let message = 'OFFER.POSITIONS.ACTIONS.ON_SUCCESS.' + actionName;
        this.growlMessageController.info(message);
    }

    private showActionWarningMessage(actionName: string): void {
        let message = 'OFFER.POSITIONS.ACTIONS.ON_WARN.' + actionName;
        this.growlMessageController.warning(message);
    }

    private showActionErrorMessage(actionName: string): void {
        let message = 'OFFER.POSITIONS.ACTIONS.ON_ERROR.' + actionName;
        this.growlMessageController.error(message);
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        console.error('tried to load table data without supplier!');
        console.trace(); // tslint:disable-line: no-console
    }

    private eventHasFilters(event: LazyLoadEvent): boolean {
        return Object.keys(event.filters)
            .filter(key => key !== 'offerId' && key !== 'supplierId' && key !== 'sortGroup' && key !== 'complaintId')
            .map(key => event.filters[key])
            .filter(filter => filter)
            .findIndex(filter => filter.value != undefined && filter.value !== '') !== -1;
    }

    loadItemsForSupplier(event: LazyLoadEvent, supplierId: number, clearRowCache: boolean): void {
        if (event == undefined) {
            event = this.getTableForSupplier(supplierId).getDataTable().createLazyLoadMetadata();
        }
        event.filters['supplierId'] = {value: supplierId.toString()};
        event.filters['sortGroup'] = {value: PositionSortGroup[PositionSortGroup.SYSTEM]};
        this.loadTableItems(event,
            () => this.tableDataBySupplier[supplierId],
            () => this.getTableForSupplier(supplierId),
            () => {
                if (!this.eventHasFilters(event)) {
                    this.tableDataBySupplier[supplierId] = undefined;
                    this.suppliers.splice(this.suppliers.findIndex(s => s.id === supplierId), 1);
                }
            },
            clearRowCache,
            Currencies.PLN);
    }

    loadOwnAddons(event: LazyLoadEvent, clearRowCache: boolean): void {
        if (event == undefined) {
            event = this.ownAddonsPositionTable.getDataTable().createLazyLoadMetadata();
        }
        event.filters['sortGroup'] = {value: PositionSortGroup[PositionSortGroup.OWN_ADDON]};
        this.loadTableItems(event,
            () => this.ownAddonsData,
            () => this.ownAddonsPositionTable,
            () => {
                if (!this.eventHasFilters(event)) {
                    this.ownAddonsData = undefined;
                }
            },
            clearRowCache,
            this.offer.currency);
    }

    loadAssemblies(event: LazyLoadEvent, clearRowCache: boolean): void {
        if (event == undefined) {
            event = this.assembliesPositionTable.getDataTable().createLazyLoadMetadata();
        }
        event.filters['sortGroup'] = {value: PositionSortGroup[PositionSortGroup.ASSEMBLY]};
        this.loadTableItems(event,
            () => this.assembliesData,
            () => this.assembliesPositionTable,
            () => {
                if (!this.eventHasFilters(event)) {
                    this.assembliesData = undefined;
                }
            },
            clearRowCache,
            this.offer.currency);
    }

    loadTransports(event: LazyLoadEvent, clearRowCache: boolean): void {
        if (event == undefined) {
            event = this.assembliesPositionTable.getDataTable().createLazyLoadMetadata();
        }
        event.filters['sortGroup'] = {value: PositionSortGroup[PositionSortGroup.OWN_TRANSPORT]};
        this.loadTableItems(event,
            () => this.transportsData,
            () => this.transportsPositionTable,
            () => {
                if (!this.eventHasFilters(event)) {
                    this.transportsData = undefined;
                }
            },
            clearRowCache,
            this.offer.currency);
    }

    private filterExchangeRate(event: LazyLoadEvent, positionsCurrency: Currencies): void {
        let fields = ['buyPrice.netValue', 'buyPrice.grossValue', 'buyValue.netValue',
            'buyValue.grossValue', 'venskaBuyPrice.netValue', 'venskaBuyPrice.grossValue', 'venskaBuyValue.netValue',
            'venskaBuyValue.grossValue', 'netVenskaIncome', 'grossVenskaIncome'];
        if (!this.isProductionOrder) {
            fields.push('sellPrice.netValue', 'sellPrice.grossValue', 'sellValue.netValue', 'sellValue.grossValue',
                'netIncome', 'grossIncome');
        }
        this.exchangeService.applyRateToRangeFilters(fields, event, this.selectedCurrency, positionsCurrency);
    }

    public loadProdOrderTableItems(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.filterExchangeRate(event, Currencies.PLN);
        this.tableData.lastLoadEvent = event;
        this.tableData.allSelectedState = TristateCheckboxState.UNCHECKED;
        this.tableData.selectedItems = [];
        if (event && this.productionOrder) {
            event.filters['productionOrderId'] = {value: this.productionOrder.id.toString()};
            this.blockUiController.block("ProductionOrdersPositionListComponentData");
            return this.productionOrdersPositionService.getItems(event.first, event.rows, event.filters, event.sortField,
                event.sortOrder).pipe(
                finalize(() => {
                    this.blockUiController.unblock("ProductionOrdersPositionListComponentData");
                    this.blockUiController.unblock("PositionListComponentData");
                }))
                .subscribe({
                    next: data => {
                        console.info('ProductionOrdersPositionListComponent `getPage` success:');
                        let tableData = this.tableData;
                        tableData.positions = data.data as ProductionOrdersPosition[];
                        tableData.totalRecords = data.totalRecords;
                        tableData.fromRecord = Math.min(event.first + 1, tableData.totalRecords);
                        tableData.toRecord = Math.min(event.first + event.rows, tableData.totalRecords);
                        let selectedElement;
                        if (tableData.selectedPosition) {
                            selectedElement = tableData.positions.find(p => p.id === tableData.selectedPosition.id);
                        }
                        if (!selectedElement) {
                            selectedElement = tableData.positions[0];
                        }
                        tableData.selectedPosition = selectedElement;

                        this.clickedRowChange(tableData.selectedPosition);
                    },
                    error: error => {
                        console.error('ProductionOrdersPositionListComponent `getPage` error:', error);
                        this.changeDetector.markForCheck();
                        this.errors.handle(error);
                    },
                    complete: () => {
                        console.info('ProductionOrdersPositionListComponent `getPage` completed!');
                        this.changeDetector.markForCheck();
                    }
                });
        }
    }

    private loadTableItems(event: LazyLoadEvent,
                           getTableData: () => PositionTableData,
                           getTableComponent: () => PositionListTableComponent,
                           actionOnEmptyResult: () => void,
                           clearRowCache: boolean,
                           positionsCurrency: Currencies) {

        this.filterExchangeRate(event, positionsCurrency);
        getTableData().lastLoadEvent = event;
        getTableData().allSelectedState = TristateCheckboxState.UNCHECKED;
        getTableData().selectedItems = [];
        if (this.offer) {
            event.filters['offerId'] = {value: this.offer.id.toString()};
            if (this.isComplaint) {
                event.filters['complaintId'] = {value: this.complaint.id.toString()};
            }
            let blockUiName = 'PositionListComponent' + event.filters['sortGroup'].value;
            if (event.filters['supplierId'] != undefined) {
                blockUiName += event.filters['supplierId'].value;
            }
            this.blockUiController.block(blockUiName);
            return forkJoin({
                listing: this.positionService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder),
                currentVersionStatus: this.offerService.getCurrentVersionStatus(this.id)
            }).subscribe({
                next: data => {
                    console.info('PositionListComponent `getPage` success:');
                    let offerPositions = data.listing.data as Position[];

                    if (this.isComplaint && offerPositions.length > 0) {
                        this.complaintService.getComplaintPositionsByOfferPositionIds(this.complaint.id,
                            offerPositions.map(position => position.id)).subscribe({
                            next: complaintPositions => {
                                this.addComplaintPositions(complaintPositions);
                                this.applyComplaintPositionData(offerPositions, complaintPositions);
                                this.initTableItems(event, getTableData, actionOnEmptyResult, offerPositions, data.listing);
                            },
                            error: error => {
                                this.errors.handle(error);
                                getTableComponent().refreshTable(false);
                            },
                            complete: () => {
                                this.handleLoadTableItemsComplete(getTableComponent, clearRowCache);
                            }
                        });
                    } else {
                        getTableData().windowsCount = data.listing.windowPositionsCount;
                        this.initTableItems(event, getTableData, actionOnEmptyResult, offerPositions, data.listing);
                    }

                    this.offer.archiveVersionNumber = data.currentVersionStatus.archiveVersionNumber;
                    this.hasDifferencesFromLastArchivedVersion = data.currentVersionStatus.hasDifferencesFromLastArchivedVersion;
                },
                error: error => {
                    this.errors.handle(error);
                    getTableComponent().refreshTable(false);
                },
                complete: () => {
                    this.blockUiController.unblock(blockUiName);
                    if (!this.isComplaint) {
                        this.handleLoadTableItemsComplete(getTableComponent, clearRowCache);
                    }
                }
            });
        }
    }

    private addComplaintPositions(complaintPositions: ComplaintPosition[]) {
        complaintPositions.forEach(complaintPosition => this.handleComplaintPositionAdding(complaintPosition));
    }

    private handleComplaintPositionAdding(newComplaintPosition: ComplaintPosition) {
        let existingPositionIndex = this.complaint.positions
            .findIndex(complaintPosition => complaintPosition.id === newComplaintPosition.id);
        if (existingPositionIndex != undefined) {
            this.complaint.positions = [
                ...this.complaint.positions.slice(0, existingPositionIndex),
                newComplaintPosition,
                ...this.complaint.positions.slice(existingPositionIndex + 1)
            ];
        } else {
            this.complaint.positions = this.complaint.positions.concat(newComplaintPosition);
        }
    }

    handleLoadTableItemsComplete(getTableComponent: () => PositionListTableComponent, clearRowCache: boolean) {
        console.info('PositionListComponent `getPage` completed!');
        this.selectedRowCountChange();
        getTableComponent().refreshTable(clearRowCache);
    }

    private applyComplaintPositionData(offerPositions: Position[], complaintPositions: ComplaintPosition[]): void {
        offerPositions.forEach(offerPosition => {
            let complaintPosition = complaintPositions.find(cp => cp.offerPositionId === offerPosition.id);
            offerPosition.quantity = complaintPosition.quantity;
        });
    }

    private initTableItems(event: LazyLoadEvent,
                           getTableData: () => PositionTableData,
                           actionOnEmptyResult: () => void,
                           offerPositions: Position[],
                           data: AbstractPositionList): void {

        let tableData = getTableData();
        tableData.positions = offerPositions;
        if (data.totalRecords > 0) {
            tableData.showRealDimensionsColumn = tableData.positions.some(p => [PositionType.CONFIGURABLE_ADDON, PositionType.CONFIG_SYSTEM].includes(p.type));
        }
        tableData.totalRecords = data.totalRecords;
        tableData.fromRecord = Math.min(event.first + 1, tableData.totalRecords);
        tableData.toRecord = Math.min(event.first + event.rows, tableData.totalRecords);

        this.restoreSelectedPosition(tableData);

        if (tableData.canFocusFirstRow) {
            this.clickedRowChange(tableData.selectedPosition);
        }
        tableData.canFocusFirstRow = true;
        if (data.totalRecords === 0) {
            actionOnEmptyResult();
        }
    }

    private restoreSelectedPosition(tableData: PositionTableData) {
        let selectedElement;

        if (this.lastEditedWindowPositionId != null) {
            selectedElement = tableData.positions.find(p => p.id === this.lastEditedWindowPositionId);

            tableData.systemSelectionRestored =
                selectedElement != null && this.lastEditedWindowPositionId === selectedElement.id &&
                isFullScreenDesignerWindowPositionType(selectedElement.type);

            if (tableData.systemSelectionRestored) {
                this.lastEditedWindowPositionId = null;
            }

            let triedRestoreForAllSuppliers = !this.suppliers.some(supplier =>
                !this.tableDataBySupplier[supplier.id] || this.tableDataBySupplier[supplier.id].systemSelectionRestored == null);

            if (this.lastEditedWindowPositionId != null && triedRestoreForAllSuppliers) {
                this.growlMessageController.info('OFFER.POSITIONS.SELECTION_ON_OTHER_PAGE');
            }
        } else if (tableData.selectedPosition) {
            selectedElement = tableData.positions.find(p => p.id === tableData.selectedPosition.id);
        }

        if (!selectedElement) {
            selectedElement = tableData.positions[0];
        }

        tableData.selectedPosition = selectedElement;
    }

    reloadAllTables(): void {
        let windowUpsellingBtnObservable = this.canEdit
            ? this.positionService.getAvailableUpsellings(this.offer.id)
            : of<UpsellingPropositionResults[]>([]);
        forkJoin({
            suppliers: this.getSuppliers(),
            sortGroups: this.positionService.getPositionSortGroups(this.id),
            currentVersionStatus: this.offerService.getCurrentVersionStatus(this.id),
            upsellingResults: windowUpsellingBtnObservable.pipe(catchError(() => of<UpsellingPropositionResults[]>([])))
        }).subscribe({
            next: data => {
                if (this.canEdit) {
                    this.upsellingResults = data.upsellingResults;
                }

                let newSuppliers = data.suppliers.sort((s1: Supplier, s2: Supplier) => s1.id - s2.id);
                let oldI = 0;
                let newI = 0;
                let hasChanges = false;
                // sorted set symmetric difference
                while (oldI < this.suppliers.length) {
                    if (newI >= newSuppliers.length) {
                        for (; oldI < this.suppliers.length; ++oldI) {
                            // removed
                            this.tableDataBySupplier[this.suppliers[oldI].id] = undefined;
                        }
                        hasChanges = true;
                        break;
                    }

                    if (this.suppliers[oldI].id < newSuppliers[newI].id) {
                        // id not found in newSupliers, remove
                        this.tableDataBySupplier[this.suppliers[oldI].id] = undefined;
                        ++oldI;
                        hasChanges = true;
                    } else {
                        if (newSuppliers[newI].id < this.suppliers[oldI].id) {
                            // id not found in old suppliers, add
                            this.tableDataBySupplier[newSuppliers[newI].id] = new PositionTableData(PositionSortGroup.SYSTEM);
                            hasChanges = true;
                        } else {
                            // id found in both old and new suppliers, reload table
                            const existingSupplierId = this.suppliers[oldI].id;
                            this.tableDataBySupplier[existingSupplierId].canFocusFirstRow = false;
                            this.loadItemsForSupplier(this.tableDataBySupplier[existingSupplierId].lastLoadEvent, existingSupplierId, true);
                            ++oldI;
                        }
                        ++newI;
                    }
                }

                for (; newI < newSuppliers.length; ++newI) {
                    // added
                    this.tableDataBySupplier[newSuppliers[newI].id] = new PositionTableData(PositionSortGroup.SYSTEM);
                    hasChanges = true;
                }

                if (hasChanges) {
                    this.suppliers = newSuppliers;
                    if (this.suppliers.length > 0) {
                        this.tableDataBySupplier[this.suppliers[0].id].canFocusFirstRow = true;
                        for (let i = 1; i < this.suppliers.length; ++i) {
                            this.tableDataBySupplier[this.suppliers[i].id].canFocusFirstRow = false;
                        }
                    }
                }
                if (data.sortGroups.OWN_ADDON && !this.isComplaint) {
                    if (this.ownAddonsData == undefined) {
                        this.ownAddonsData = new PositionTableData(PositionSortGroup.OWN_ADDON);
                        if (this.suppliers.length === 0) {
                            this.ownAddonsData.canFocusFirstRow = true;
                        }
                    } else {
                        this.ownAddonsData.canFocusFirstRow = false;
                        this.reloadOwnAddonsTable();
                    }
                } else {
                    this.ownAddonsData = undefined;
                }
                if (data.sortGroups.ASSEMBLY && !this.isComplaint) {
                    if (this.assembliesData == undefined) {
                        this.assembliesData = new PositionTableData(PositionSortGroup.ASSEMBLY);
                        if (this.suppliers.length === 0 && this.ownAddonsData == undefined) {
                            this.assembliesData.canFocusFirstRow = true;
                        }
                    } else {
                        this.assembliesData.canFocusFirstRow = false;
                        this.reloadAssembliesTable();
                    }
                } else {
                    this.assembliesData = undefined;
                }
                if (data.sortGroups.OWN_TRANSPORT && !this.isComplaint) {
                    if (this.transportsData == undefined) {
                        this.transportsData = new PositionTableData(PositionSortGroup.OWN_TRANSPORT);
                        if (this.suppliers.length === 0 && this.ownAddonsData == undefined && this.assembliesData == undefined) {
                            this.transportsData.canFocusFirstRow = true;
                        }
                    } else {
                        this.transportsData.canFocusFirstRow = false;
                        this.reloadTransportsTable();
                    }
                } else {
                    this.transportsData = undefined;
                }
                this.offer.archiveVersionNumber = data.currentVersionStatus.archiveVersionNumber;
                this.hasDifferencesFromLastArchivedVersion = data.currentVersionStatus.hasDifferencesFromLastArchivedVersion;
                if (this.lastSelectedRow && this.lastSelectedRow.type === PositionType.STANDALONE_GLAZING_PACKAGE) {
                    this.selectionChange.emit({data: this.lastSelectedRow});
                }
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        });
    }

    reloadTableForSupplier(supplierId: number): void {
        let tableComponent = this.getTableForSupplier(supplierId);
        if (tableComponent != undefined) {
            this.loadItemsForSupplier(tableComponent.getDataTable().createLazyLoadMetadata(), supplierId, true);
            this.setShowWindowUpsellingButton();
        }
    }

    getTableForSupplier(supplierId: number): PositionListTableComponent {
        let tables = this.positionTables.toArray();
        let positionListTable = tables.find(table => table.getSupplier().id === supplierId);
        if (positionListTable != undefined) {
            return positionListTable;
        }
        return undefined;
    }

    getTableForPosition(position: Position): PositionListTableComponent {
        switch (position.sortGroup) {
            case PositionSortGroup.SYSTEM: {
                let tables = this.positionTables.toArray();
                let positionListTable = tables.find(table => table.getSupplier().id === position.supplierId);
                if (positionListTable != undefined) {
                    return positionListTable;
                }
                break;
            }
            case PositionSortGroup.OWN_ADDON:
                return this.ownAddonsPositionTable;
            case PositionSortGroup.ASSEMBLY:
                return this.assembliesPositionTable;
            case PositionSortGroup.OWN_TRANSPORT:
                return this.transportsPositionTable;
            default:
                break;
        }
        return undefined;
    }

    reloadOwnAddonsTable(): void {
        this.loadOwnAddons(this.ownAddonsData.lastLoadEvent, true);
    }

    reloadAssembliesTable(): void {
        this.loadAssemblies(this.assembliesData.lastLoadEvent, true);
    }

    reloadTransportsTable(): void {
        this.loadTransports(this.transportsData.lastLoadEvent, true);
    }

    private initHotkeys(): void {
        this.editHotkey = new Hotkey('alt+e', (): boolean => {
            this.editOffer();
            return false;
        }, undefined, 'OFFER.POSITIONS.EDIT_OFFER');
        this.deleteHotkey = new Hotkey('del', (): boolean => {
            this.removeSelectedPositions();
            return false;
        }, undefined, 'OFFER.POSITIONS.REMOVE_POSITION');
        this.shippingHotkey = new Hotkey('alt+t', () => {
            this.addToShippingSimulation(false);
            return false;
        }, undefined, 'OFFER.POSITIONS.SHIPPING');
        this.profitMarginHotkey = new Hotkey('alt+f', (): boolean => {
            this.openProfitMargins();
            return false;
        }, undefined, 'OFFER.POSITIONS.DIALOGS.RABATE_MARGINS.MARGIN');
        this.rebateHotkey = new Hotkey('alt+r', (): boolean => {
            this.openRabateDialog();
            return false;
        }, undefined, 'OFFER.POSITIONS.DIALOGS.RABATE_MARGINS.RABATE');
        this.annotationsHotkey = new Hotkey('alt+b', (): boolean => {
            this.showAnnotations();
            return false;
        }, undefined, 'OFFER.POSITIONS.ANNOTATIONS');
        this.windowDesignerHotkey = new Hotkey('alt+n', (): boolean => {
            this.openWindowDesigner('new', ProductTypeGroup.DEFAULT);
            return false;
        }, undefined, 'OFFER.POSITIONS.ADD_SYSTEM');
        this.roofWindowDesignerHotkey = new Hotkey('alt+o', (): boolean => {
            this.openWindowDesigner('new', ProductTypeGroup.ROOF);
            return false;
        }, undefined, 'OFFER.POSITIONS.ADD_ROOF_SYSTEM');
        this.editConfigAddonHotkey = new Hotkey('ctrl+alt+d', (): boolean => {
            this.editConfigurableAddon(this.currentActionPosition);
            return false;
        }, undefined, 'OFFER.POSITIONS.ADD_CONFIGURABLE_ADDON');
        this.showSummaryHotkey = new Hotkey('alt+s', (): boolean => {
            this.showSummary();
            return false;
        }, undefined, 'OFFER.POSITIONS.SUMMARY');
        this.globalColorChangeHotkey = new Hotkey('alt+c', (): boolean => {
            this.globalChangeClick(BulkColorChangeDialogData);
            return false;
        }, undefined, 'OFFER.POSITIONS.GLOBAL_CHANGE_HOTKEYS.COLOR');
        this.globalGlassChangeHotkey = new Hotkey('alt+g', (): boolean => {
            this.globalChangeClick(BulkGlassChangeDialogData);
            return false;
        }, undefined, 'OFFER.POSITIONS.GLOBAL_CHANGE_HOTKEYS.GLASS');
        this.globalSystemChangeHotkey = new Hotkey('alt+y', (): boolean => {
            this.globalChangeClick(BulkWindowSystemChangeDialogData);
            return false;
        }, undefined, 'OFFER.POSITIONS.GLOBAL_CHANGE_HOTKEYS.SYSTEM');
        this.globalGeneralChangeHotkey = new Hotkey('alt+l', (): boolean => {
            this.globalChangeClick(BulkGeneralChangeDialogData);
            return false;
        }, undefined, 'OFFER.POSITIONS.GLOBAL_CHANGE_HOTKEYS.GENERAL');
        this.globalExchangeRateUpdateHotkey = new Hotkey('ctrl+alt+n', () => {
            this.openUpdateExchangeRateDialog();
            return false;
        }, undefined, 'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.HEADER');
        this.globalPricingsUpdateHotkey = new Hotkey('alt+m', () => {
            this.openUpdatePricingsDialog();
            return false;
        }, undefined, 'OFFER.POSITIONS.DIALOGS.UPDATE_PRICINGS.HEADER');
        this.conjunctionHotkey = new Hotkey('alt+k', () => {
            this.openConjunctionsDialog();
            return false;
        }, undefined, 'OFFER.POSITIONS.CONJUNCTION.HEADER');

        this.validateComplaintPositionDataStep = () => this.validateComplaintPositionData();
    }

    openGateDesigner(positionId: string): void {
        this.router.navigate([positionId, AccessData.gateDesignerURLSuffix], {
            relativeTo: this.route,
        });
    }

    showBulkAddonAddDialog(ownAddons: boolean): void {
        this.setDisplayedDialog(ownAddons ? DialogType.ADD_OWN_BULK_ADDON : DialogType.ADD_BULK_ADDON);
    }

    showAssemblyAddDialog() {
        this.setDisplayedDialog(DialogType.ADD_ASSEMBLY);
    }

    showTransportAddDialog(): void {
        this.setDisplayedDialog(DialogType.ADD_TRANSPORT);
    }

    isDialogVisible(dialogType: string) {
        return this.displayedDialog === DialogType[dialogType];
    }

    getApplicableTo() {
        if (this.displayedDialog === DialogType.NEW_CONFIG_ADDON) {
            return [ConfigAddonApplication.INDEPENDENT];
        }
        if (this.displayedDialog === DialogType.EDIT_CONFIG_ADDON) {
            let configData: ConfigurableAddon = JSON.parse(this.currentActionPosition.data);
            return [configData.application];
        }
        if (this.displayedDialog === DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON) {
            return [ConfigAddonApplication.AREA, ConfigAddonApplication.SUBWINDOW];
        }
    }

    selectedPositionsHaveConfigAddons(selectedIds: number[]): boolean {
        const positions: Position[] = _.flatten(_.values(this.tableDataBySupplier)
            .filter(tableData => tableData != undefined)
            .map((tableData: PositionTableData) => tableData.positions));
        return _.some(positions, position => _.contains(selectedIds, position.parentOfferPositionId));
    }

    editConfigurableAddon(position: AbstractPosition) {
        if (position) {
            let configuredAddon: ConfigurableAddon = JSON.parse(position.data);
            this.displayedDialogData = new AddConfigurableAddonDialogData(configuredAddon.application, configuredAddon.windowSystemId,
                1, configuredAddon.openings);
            this.positionDisabled = position.configurableAddonDefinitionType != null;
            this.setDisplayedDialog(DialogType.EDIT_CONFIGURABLE_ADDON);
        } else {
            this.addConfigurableAddon();
        }
    }

    addConfigurableAddon(isOtherSystem?: boolean) {
        this.currentActionPosition = undefined;
        this.displayedDialogData = new AddConfigurableAddonDialogData(ConfigAddonApplication.INDEPENDENT);
        this.setDisplayedDialog(isOtherSystem ? DialogType.ADD_OTHER_SYSTEM_ADDON : DialogType.ADD_CONFIGURABLE_ADDON);
        this.changeDetector.markForCheck();
    }

    addConfigurableAddonNew(): void {
        this.currentActionPosition = undefined;
        this.displayedDialogData = new AddConfigurableAddonDialogData(ConfigAddonApplication.INDEPENDENT);
        this.setDisplayedDialog(DialogType.NEW_CONFIG_ADDON);
        this.changeDetector.markForCheck();
    }

    editConfigurableAddonNew(position: AbstractPosition): void {
        if (position) {
            let configuredAddon: ConfigurableAddon = JSON.parse(position.data);
            this.displayedDialogData = new AddConfigurableAddonDialogData(configuredAddon.application, configuredAddon.windowSystemId,
                1, configuredAddon.openings);
            this.setDisplayedDialog(DialogType.EDIT_CONFIG_ADDON);
        } else {
            this.addConfigurableAddonNew();
        }
        this.changeDetector.markForCheck();
    }

    editBulkAddon(position: AbstractPosition) {
        if (position) {
            const addonFromData: BulkAddonData = JSON.parse(position.data);
            const isOffer = !this.isComplaint && !this.isProductionOrder;
            forkJoin({
                addon: this.addonService.getItem(addonFromData.addonId, true, this.offer.id, isOffer ? position.id : undefined),
                image: this.addonService.getImageForItem(addonFromData.addonId)
            }).subscribe({
                next: data => {
                    this.displayedDialogData = new EditBulkAddonDialogData(position);
                    this.addon = new PositionListAddon(data.addon, this.userLang);
                    this.addon.defaultQuantity = position.quantity;

                    this.setupColors(this.addon, addonFromData);
                    if (data.image) {
                        this.imagePreview = this.sanitizer.bypassSecurityTrustUrl(data.image);
                    } else {
                        this.imagePreview = undefined;
                    }
                    this.setDisplayedDialog(DialogType.EDIT_BULK_ADDON);
                },
                error: error => {
                    this.errors.handle(error);
                }
            });
        }
    }

    editAssembly(position: Position): void {
        this.displayedDialogData = new EditAssemblyOrTransportDialogData(position, 'EDIT_ASSEMBLY');
        this.setDisplayedDialog(DialogType.EDIT_ASSEMBLY);
    }

    editTransport(position: Position): void {
        this.displayedDialogData = new EditAssemblyOrTransportDialogData(position, 'EDIT_TRANSPORT');
        this.setDisplayedDialog(DialogType.EDIT_TRANSPORT);
    }

    openOfferCharge(position: AbstractPosition): void {
        this.displayedDialogData = new OpenOfferChargeDataDialogData(position);
        this.setDisplayedDialog(DialogType.OFFER_CHARGE);
    }

    public setupColors(originalAddon: PositionListAddon, configuredAddon: BulkAddonData) {
        // first fill select items with all available colors
        // here original addon keeps info about all available colors for this addon
        let availableCoreColors: SelectItem[] = [];
        let availableInsideColors: SelectItem[] = [];
        let availableOutsideColors: SelectItem[] = [];

        this.addon.availableCoreColors.forEach(availableColor => {
            // if some color was selected, use it for proper match in model
            if (configuredAddon.selectedCoreColor && configuredAddon.selectedCoreColor.id === availableColor.value.id) {
                availableCoreColors.push({
                    label: getColorFormattedNameWithGroup(configuredAddon.selectedCoreColor, this.userLang),
                    value: configuredAddon.selectedCoreColor
                });
            } else {
                availableCoreColors.push({
                    label: getColorFormattedNameWithGroup(availableColor.value, this.userLang),
                    value: availableColor.value
                });
            }
        });

        this.addon.availableInsideColors.forEach(ac => {
            // if some color was selected, use it for proper match in model
            if (configuredAddon.selectedInsideColor && configuredAddon.selectedInsideColor.id === ac.value.id) {
                availableInsideColors.push({
                    label: getColorFormattedNameWithGroup(configuredAddon.selectedInsideColor, this.userLang),
                    value: configuredAddon.selectedInsideColor
                });
            } else {
                availableInsideColors.push({
                    label: getColorFormattedNameWithGroup(ac.value, this.userLang),
                    value: ac.value
                });
            }
        });
        this.addon.availableOutsideColors.forEach(ac => {
            // if some color was selected, use it for proper match in model
            if (configuredAddon.selectedOutsideColor && configuredAddon.selectedOutsideColor.id === ac.value.id) {
                availableOutsideColors.push({
                    label: getColorFormattedNameWithGroup(configuredAddon.selectedOutsideColor, this.userLang),
                    value: configuredAddon.selectedOutsideColor
                });
            } else {
                availableOutsideColors.push({
                    label: getColorFormattedNameWithGroup(ac.value, this.userLang),
                    value: ac.value
                });
            }

        });
        // now since originalAddon will be displayed in the form, set his colors to be one selected in configured addon
        // to get proper match in ngModel
        originalAddon.selectedCoreColor = configuredAddon.selectedCoreColor;
        originalAddon.selectedInsideColor = configuredAddon.selectedInsideColor;
        originalAddon.selectedOutsideColor = configuredAddon.selectedOutsideColor;
        originalAddon.availableInsideColors = availableInsideColors;
        originalAddon.availableOutsideColors = availableOutsideColors;
        originalAddon.availableCoreColors = availableCoreColors;
    }

    doOnRowSelect(event) {
        this.keepSelectedItemIndex(event);
        if (!this.isComplaint || this.isEditComplaintEnabled()) {
            super.doOnRowSelect(event);
        } else {
            setTimeout(() => this.restoreSelectionAndResetHotkeys(), 100);
        }
    }

    onRowSelect(event) {
        if (event.data) {
            event.originalEvent.stopImmediatePropagation();
            this.actionOnClick('EDIT', event.data);
        }
    }

    removeSelectedPositions() {
        let selectedItemsCount = this.countSelectedElement();
        if (selectedItemsCount > 0) {
            if (this.isComplaint) {
                this.showRemoveSelectedComplaintsDialog(this.getSelectedItems());
            } else {
                this.itemsToRemove = this.getSelectedItems();
                this.setDisplayedDialog(DialogType.REMOVE_POSITION);
            }
        } else {
            this.growlMessageController.warning('OFFER.POSITIONS.ACTIONS.ON_ERROR.NO_ITEMS_SELECTED');
        }
    }

    private showRemoveSelectedComplaintsDialog(positionsToRemove: Position[]): void {
        let selectedComplaintPositionIds = [];

        positionsToRemove.forEach(selectedPosition => {
            selectedComplaintPositionIds.push(this.complaint.positions.find(
                complaintPosition => complaintPosition.offerPositionId === selectedPosition.id).id);
        });

        this.complaintPositionsToRemove = selectedComplaintPositionIds;
        this.setDisplayedDialog(DialogType.REMOVE_COMPLAINT_POSITION);
    }

    getSelectedItems(): Position[] {
        return _.flatten(
            _.values(this.tableDataBySupplier)
                .filter(tableData => tableData != undefined)
                .concat(this.ownAddonsData != undefined ? [this.ownAddonsData] : [])
                .concat(this.assembliesData != undefined ? [this.assembliesData] : [])
                .concat(this.transportsData != undefined ? [this.transportsData] : [])
                .map((tableData: PositionTableData) => tableData.selectedItems)
        );
    }

    private countSelectedElement() {
        let selectedItemsIds = this.getSelectedItems().map(item => item.id);
        this.areParentsSelected = this.selectedPositionsHaveConfigAddons(selectedItemsIds);
        return selectedItemsIds.length;
    }

    openProfitMargins() {
        this.openRabateDialog(false, true);
    }

    openRabateDialog(forWholeOffer = false, isMarkup = false) {
        if (this.blockActionIfOldConfigsPresent()) {
            return;
        }
        if (this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})) {
            if (forWholeOffer) {
                this.displayedDialogData = new RabateMarkupDialogData(this.offer.id, null, false, this.offer.rabate);
                this.setDisplayedDialog(DialogType.RABATE_MARKUP);
            } else {
                let allSelections;
                if (this.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})) {
                    allSelections = this.getSelectedItems();
                } else {
                    allSelections = [];
                    for (let supplierId in this.tableDataBySupplier) {
                        allSelections.push(...this.tableDataBySupplier[supplierId].selectedItems);
                    }
                }
                if (allSelections.length > 0) {
                    this.displayedDialogData = new RabateMarkupDialogData(this.offer.id, allSelections, isMarkup);
                    this.setDisplayedDialog(DialogType.RABATE_MARKUP);
                } else {
                    this.growlMessageController.warning('error.pricing.profitMarginType.missing_selection');
                }
            }
        }
    }

    showSummary() {
        this.setDisplayedDialog(DialogType.SHOW_SUMMARY);
    }

    public printButtonVisible(): boolean {
        if (this.isComplaint) {
            return this.selectedComplaintPositions.length > 0 && this.isAllowedToPrintComplaint();
        }
        return true;
    }

    private isAllowedToPrintComplaint(): boolean {
        return this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
    }

    getPrintableSection(): PrintableSection {
        if (this.isComplaint) {
            return PrintableSection.COMPLAINTS;
        } else if (this.isProductionOrder) {
            return PrintableSection.PRODUCTION_ORDERS;
        } else if (this.selectedConjunctionsIds.length > 0) {
            return PrintableSection.CONJUNCTIONS;
        } else if (this.isOrder) {
            return PrintableSection.ORDERS;
        }
        return PrintableSection.OFFERS;
    }

    showPrintDialog(archivedOfferId?: number) {
        if (this.isProductionOrder) {
            this.printableItems = [new PrintableItem(this.productionOrder.id, this.productionOrder.orderNumber)];
            this.selectedPositionsIds = this.tableData.selectedItems.filter(pos => pos.type === PositionType.SYSTEM).map(pos => pos.id);
        } else {
            let numberForFilename = this.isComplaint ? this.complaint.complaintNumber : this.offer.offerNumber;
            let id = archivedOfferId ? archivedOfferId : (this.isComplaint ? this.complaint : this.offer).id;
            this.printableItems = [new PrintableItem(id, numberForFilename)];
            if (!this.isComplaint) {
                this.printableItems[0].status = this.offer.status;
            }
            this.selectedPositionsIds = this.selectedComplaintPositionsIds;
        }
        this.printDialogVisible = true;
        this.changeDetector.markForCheck();
    }

    onPrintDialogClose() {
        this.printableItems = [];
        this.selectedConjunctionsIds = [];
        this.printDialogVisible = false;
    }

    private fillAddPositionMenu(productsVisibility: ProductsVisibility) {
        this.addPositionMenuElements = [];
        if (productsVisibility[ProductTypeGroup.DEFAULT]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_SYSTEM').setIdentifier('addSystem')
                .setProductMark(this.getMarkLabel(AvailableProducts.SYSTEM)).build());
        }
        if (productsVisibility[ProductTypeGroup.ENTRANCE]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_ENTRANCE_SYSTEM').setIdentifier('addEntranceSystem')
                .setProductMark(this.getMarkLabel(AvailableProducts.ENTRANCE_SYSTEM)).build());
        }
        if (productsVisibility[ProductTypeGroup.TERRACE]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_TERRACE_SYSTEM').setIdentifier('addTerraceSystem')
                .setProductMark(this.getMarkLabel(AvailableProducts.TERRACE_SYSTEM)).build());
        }
        if (productsVisibility[ProductTypeGroup.ROOF]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_ROOF_SYSTEM').setIdentifier('addRoofSystem')
                .setProductMark(this.getMarkLabel(AvailableProducts.ROOF_SYSTEM)).build());
        }
        if (productsVisibility[ProductTypeGroup.BULK_ADDON]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_ADDON_BULK').setIdentifier('addBulkAddon')
                .setProductMark(this.getMarkLabel(AvailableProducts.ADDON_BULK)).build());
        }
        if (productsVisibility[ProductTypeGroup.CONFIG_ADDON]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_CONFIGURABLE_ADDON').setIdentifier('addConfigurableAddon')
                .setProductMark(this.getMarkLabel(AvailableProducts.CONFIGURABLE_ADDON)).build());
        }
        if (productsVisibility[ProductTypeGroup.GATE]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_GATE_SYSTEM').setIdentifier('addOtherSystemAddon')
                .setProductMark(this.getMarkLabel(AvailableProducts.GATE_SYSTEM)).build());
        }
        if (productsVisibility[ProductTypeGroup.DEFAULT]) {
            this.addPositionMenuElements.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ADD_STANDALONE_GLAZING_PACKAGE').setIdentifier('addStandaloneGlazingPackage')
                .setProductMark('').build());
        }
        if (this.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})) {
            if (productsVisibility[ProductTypeGroup.SUBSYSTEM_BULK_ADDON]) {
                this.addPositionMenuElements.push(new MenuElementBuilder()
                    .setTranslationKey('OFFER.POSITIONS.ADD_OWN_ADDON_BULK').setIdentifier('addOwnBulkAddon')
                    .setProductMark(this.getMarkLabel(AvailableProducts.OWN_ADDON_BULK)).build());
            }
            if (productsVisibility[ProductTypeGroup.ASSEMBLY]) {
                this.addPositionMenuElements.push(new MenuElementBuilder()
                    .setTranslationKey('OFFER.POSITIONS.ADD_ASSEMBLY').setIdentifier('addAssembly')
                    .setProductMark(this.getMarkLabel(AvailableProducts.ASSEMBLY)).build());
            }
            if (productsVisibility[ProductTypeGroup.TRANSPORT]) {
                this.addPositionMenuElements.push(new MenuElementBuilder()
                    .setTranslationKey('OFFER.POSITIONS.ADD_TRANSPORT').setIdentifier('addTransport')
                    .setProductMark(this.getMarkLabel(AvailableProducts.TRANSPORT)).build());
            }
        }
    }

    addBulkAddonsSaved(): void {
        this.resetDialog();
        this.refreshAfterAction('ADD_BULK_ADDON', false, false, undefined, true);
    }

    showDescriptionClosed(event: OtherInfoDialogCloseEvent): void {
        if (event.success) {
            this.showActionSuccessMessage('SHOW_DESCRIPTION');
            this.selectionChange.emit({data: event.position});
            this.offerService.getCurrentVersionStatus(this.id).subscribe(currentVersionStatus => {
                this.offer.archiveVersionNumber = currentVersionStatus.archiveVersionNumber;
                this.hasDifferencesFromLastArchivedVersion = currentVersionStatus.hasDifferencesFromLastArchivedVersion;
                this.changeDetector.markForCheck();
            });
        }
        this.resetDialog();
    }

    private setDisplayedDialog(dialogType: DialogType): void {
        if (this.displayedDialog !== dialogType) {
            this.displayedDialog = dialogType;
            this.changeDetector.markForCheck();
        }
    }

    public saveShownColumns() {
        const savedShownColumns = super.saveShownColumns();
        if (this.positionTables) {
            this.positionTables.forEach(tbl => tbl.refreshTable(false));
        }
        if (this.ownAddonsPositionTable) {
            this.ownAddonsPositionTable.refreshTable(false);
        }
        return savedShownColumns;
    }

    private performFrontValidation(displayedDialogData: BulkChangeConfirmationDialogData) {
        const systemPositions = displayedDialogData.modifiedPositions.filter(pos => pos.type === PositionType.SYSTEM);
        let positionsBySystem = new Map<number, Position[]>(
            _.chain(systemPositions)
            .groupBy((item) => (JSON.parse(item.data) as DrawingData).windowSystemId)
            .pairs()
            .value()
            .map(p => [+p[0], p[1]])
        );
        forkJoin({
            glasses: this.glassService.getItems(0, null, null, "id", 1),
            frames: this.frameService.getAllActiveDistanceFrames(),
            dataPerSystem: forkJoin(Array.from(positionsBySystem.keys()).map(windowSystemId => {
                return forkJoin({
                    system: this.windowSystemDefinitionService.getSystem(windowSystemId),
                    profiles: this.windowSystemDefinitionService.getSystemsProfiles(windowSystemId),
                    grills: this.grillService.getGrillsForWindowSystem(windowSystemId)
                });
            }))
        }).pipe(
            finalize(() => this.blockUiController.unblock(PositionListComponent.BULK_CHANGE_BLOCK_ID))
        ).subscribe({
            next: data => {
                type DataPerSystem = typeof data.dataPerSystem[0];
                const dataPerSystem = new Map<number, DataPerSystem>(data.dataPerSystem.map(dps => [dps.system.id, dps]));

                positionsBySystem.forEach((positions, windowSystemId) => {
                    positions.forEach(p => {
                        this.validateSinglePosition(displayedDialogData, p, data.glasses.data,
                            dataPerSystem.get(windowSystemId).profiles.data,
                            dataPerSystem.get(windowSystemId).system,
                            dataPerSystem.get(windowSystemId).grills,
                            data.frames.data);
                    });
                });
                this.setDisplayedDialog(DialogType.BULK_CHANGE_CONFIRMATION);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    private validateSinglePosition(displayedDialogData: BulkChangeConfirmationDialogData, position: Position,
                                         glasses: GlassInterface[], profiles: ProfileInterface[], system: WindowSystemInterface,
                                         grills: GrillInterface[], frames: DistanceFrameInterface[]) {
        let data = JSON.parse(position.data) as DrawingData;
        let errorsArray = displayedDialogData.changeResult.validationResults[position.id];
        DrawingDataValidator.validatePosition(data, glasses, profiles, system, grills, frames, errorsArray);
    }

    onUpdatePricingSuccess(msgKey: string, success: boolean) {
        if (success) {
            this.showActionSuccessMessage(msgKey);
        }
        this.resetDialog();
        this.offerService.getItem(this.id).subscribe({
            next: data => {
                this.offer = data;
                this.setCanEditField();
                this.setGlobalChangeItems();
                this.registerHotkeys();
                this.reloadAllTables();
            },
            error: () => {
                this.handleOfferLoadingError();
            }
        });
        if (success) {
            this.openUpdateValidityDatesDialog();
        }
    }

    onUpdateValidityDatesSuccess(successMsgKey: string) {
        this.showActionSuccessMessage(successMsgKey);
        this.resetDialog();
    }

    onConfigurableAddonDialogSuccess(events: ConfigurableAddonDialogEventModel[]): void {
        if (events == null) {
            this.resetDialog();
            return;
        }
        let event = events[0];
        switch (event.getActionName()) {
            case DialogType.NEW_CONFIG_ADDON:
            case DialogType.EDIT_CONFIG_ADDON:
            case DialogType.ADD_CONFIGURABLE_ADDON:
            case DialogType.EDIT_CONFIGURABLE_ADDON:
            case DialogType.ADD_OTHER_SYSTEM_ADDON:
                this.refreshAfterAction(DialogType[event.getActionName()], false, false, event.getPosition());
                break;
            case DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON:
                if (events.length > 0) {
                    let globalConfigAddData = new GlobalAddConfigAddonsData(this.displayedDialogData as GlobalAddConfigAddonsDialogData,
                        events);
                    this.tryAddingConfigAddonsGlobally(globalConfigAddData, false);
                }
                break;
            default:
                throw new Error("PositionList.onConfigurableAddonDialogSuccess - Unsupported action: " + DialogType[event.getActionName()]);
        }
        this.resetDialog();
    }

    submitEditBulkAddon(): void {
        this.genericDialogSuccess('EDIT_BULK_ADDON', true, false, false, this.currentActionPosition);
    }

    profitMarginSubmited() {
        this.genericDialogSuccess('PROFIT_MARGINS', true, false, false, this.currentActionPosition);
    }

    handleChangeAnnotationsSave() {
        this.showActionSuccessMessage('CHANGE_ANNOTATIONS');
        this.resetDialog();
        this.offerService.getItem(this.offer.id).subscribe({
            next: data => {
                this.offer = data;
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    private openPositionEditForm(position: AbstractPosition) {
        if (this.isComplaint) {
            this.openEditComplaintPositionDialog();
            return;
        }
        switch (position.type) {
            case PositionType.SYSTEM:
                this.openWindowDesigner(position.id.toString(), ProductTypeGroup.DEFAULT);
                break;
            case PositionType.ROOF_SYSTEM:
                this.openWindowDesigner(position.id.toString(), ProductTypeGroup.ROOF);
                break;
            case PositionType.ENTRANCE_DOOR_SYSTEM:
                this.openWindowDesigner(position.id.toString(), ProductTypeGroup.ENTRANCE);
                break;
            case PositionType.GATE_SYSTEM:
                this.openGateDesigner(position.id.toString());
                break;
            case PositionType.CONFIGURABLE_ADDON:
                this.editConfigurableAddon(position);
                break;
            case PositionType.CONFIG_SYSTEM:
                this.editConfigurableAddonNew(position);
                break;
            case PositionType.BULK_ADDON:
                this.editBulkAddon(position);
                break;
            case PositionType.ASSEMBLY:
                this.editAssembly(position as Position); // safe cast, assembly cannot exist on production orders
                break;
            case PositionType.TRANSPORT:
                this.editTransport(position as Position); // safe cast, transport cannot exist on production orders
                break;
            case PositionType.OFFER_CHARGE:
                this.openOfferCharge(position);
                break;
            case PositionType.STANDALONE_GLAZING_PACKAGE:
                if (this.permissions.isKoordynator() || this.permissions.isOpiekun()) {
                    this.openWindowDesigner(position.id.toString(), ProductTypeGroup.DEFAULT);
                } else {
                    this.positionToEdit = position;
                    this.setDisplayedDialog(DialogType.ADD_STANDALONE_GLAZING_PACKAGE);
                }
                break;
        }
    }

    restoreMenuElementSelected(event: ButtonWithMenuElementSelectedEvent | MenuElement) {
        switch (event.identifier) {
            case 'RESTORE_LAST_ARCHIVED_VERSION': {
                this.askToRestoreArchivedOfferVersion(this.offer.lastArchivedVersionId);
                break;
            }
            case 'SHOW_ARCHIVED_VERSIONS': {
                this.showOfferArchivedVersions();
                break;
            }
        }
    }

    showTableFilters(event: MouseEvent, supplierId: number) {
        this.showFiltersFor(event, this.tableDataBySupplier[supplierId]);
        this.reloadTableForSupplier(supplierId);
        this.changeDetector.markForCheck();
    }

    showOwnAddonsFilters(event: MouseEvent) {
        this.showFiltersFor(event, this.ownAddonsData);
        this.reloadOwnAddonsTable();
        this.changeDetector.markForCheck();
    }

    showAssembliesFilters(event: MouseEvent) {
        this.showFiltersFor(event, this.assembliesData);
        this.reloadAssembliesTable();
        this.changeDetector.markForCheck();
    }

    showRotDialog(event: MouseEvent) {
        this.eventPreventDefault(event);
        this.displayedDialogData = this.getRotDataDialogData(this.canEdit);
        this.setDisplayedDialog(DialogType.ROT_DATA);
        this.changeDetector.markForCheck();
    }

    private getRotDataDialogData(canEdit: boolean) {
        return new RotDataDialogData(this.offer.id, canEdit, this.rotData);
    }

    private eventPreventDefault(event: Event) {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }
    }

    showTransportsFilters(event) {
        this.showFiltersFor(event, this.transportsData);
        this.reloadTransportsTable();
        this.changeDetector.markForCheck();
    }

    private showFiltersFor(event, tableData: PositionTableData) {
        this.eventPreventDefault(event);
        tableData.showFilters = !tableData.showFilters;
    }

    filterEnabledForSupplier(supplierId) {
        return this.filterEnabledFor(this.tableDataBySupplier[supplierId]);
    }

    filterEnabledForOwnAddons() {
        return this.filterEnabledFor(this.ownAddonsData);
    }

    filterEnabledForAssemblies() {
        return this.filterEnabledFor(this.assembliesData);
    }

    filterEnabledForTransports() {
        return this.filterEnabledFor(this.transportsData);
    }

    private filterEnabledFor(tableData: PositionTableData) {
        if (tableData) {
            return tableData.showFilters;
        }
        return false;
    }

    resetCommentDialog(commentAddedOrEdited: boolean): void {
        if (commentAddedOrEdited) {
            this.reloadTableForSupplier(this.dialogDataSelector.getData(this.displayedDialogData, 'COMMENTS').supplierId);
        }

        this.resetDialog();
    }

    onSaveCommentSuccess(): void {
        this.showActionSuccessMessage('ADD_COMMENT');
    }

    showConfigAddonDimensions(): boolean {
        let dialogData = this.dialogDataSelector.getData(this.displayedDialogData, 'ADD_CONFIGURABLE_ADDON');
        return this.displayedDialog !== DialogType.GLOBAL_ADD_CONFIGURABLE_ADDON
            && this.currentActionPosition == undefined
            || (dialogData && dialogData.application === ConfigAddonApplication.INDEPENDENT);
    }

    handleDeletePositionSuccess(affectedTables: RemovePositionsResult): void {

        this.resetDialog();
        this.showDeleteButton = false;
        this.showShippingButton = false;
        this.selectionChange.emit({});
        if (affectedTables != undefined) {
            for (let supplierId of affectedTables.affectedSuppliers) {
                this.reloadTableForSupplier(supplierId);
            }
            if (affectedTables.affectedSortGroups.has(PositionSortGroup.OWN_ADDON)) {
                this.reloadOwnAddonsTable();
            }
            if (affectedTables.affectedSortGroups.has(PositionSortGroup.ASSEMBLY)) {
                this.reloadAssembliesTable();
            }
            if (affectedTables.affectedSortGroups.has(PositionSortGroup.OWN_TRANSPORT)) {
                this.reloadTransportsTable();
            }
            this.reloadOffer();
        }
        this.showActionSuccessMessage("REMOVE_POSITION");
        this.itemsToRemove = [];
    }

    handleDeletePositionError(error: HttpErrorResponse | Error): void {
        this.showDeleteButton = false;
        this.itemsToRemove = [];
        this.resetDialog();
        this.errors.handle(error);
    }

    private getSelectedPositionsValidForShipping(): Position[] {
        return this.getSelectedItems().filter(pos => pos.type === PositionType.SYSTEM)
            .filter(pos => this.suppliers.find(supplier => supplier.id === pos.supplierId).simulateShipping);
    }

    private updateShippingButtonVisibility(): void {
        this.showShippingButton = !this.isComplaint && !this.isProductionOrder && this.getSelectedPositionsValidForShipping().length > 0
            && this.currentUserService.restrictedToOfferNumber == undefined;
    }

    addToShippingSimulation(ignoreWarningDialog: boolean): void {
        const shippingPositions = this.getSelectedPositionsValidForShipping();
        if (!ignoreWarningDialog && shippingPositions.length !== this.getSelectedItems().length) {
            this.setDisplayedDialog(DialogType.CONFIRM_PARTIAL_SIMULATION);
            return;
        }
        this.shippingSimulationService.addOfferPositionsToDraft(shippingPositions).subscribe({
            next: () => {
                this.showActionSuccessMessage("ADD_TO_SIMULATION");
                this.shippingFloatButton.refresh();
            },
            error: error => this.errors.handle(error)
        });
    }

    comparePositions(): void {
        let positions = this.getSelectedItems().filter(pos => isWindowPositionType(pos.type));
        let positionType: PositionType;
        let gatePositions = this.getSelectedItems().filter(pos => pos.type === PositionType.GATE_SYSTEM);
        let configPositions = this.getSelectedItems().filter(pos => pos.type === PositionType.CONFIG_SYSTEM);
        let addonPositions = this.getSelectedItems().filter(pos => pos.type === PositionType.BULK_ADDON);
        let assemblyPositions = this.getSelectedItems().filter(pos => pos.type === PositionType.ASSEMBLY);
        let transportPositions = this.getSelectedItems().filter(pos => pos.type === PositionType.TRANSPORT);
        let properties: Observable<WindowProperties[] | GateProperties[] | ConfigProperties[] | AddonProperties[] | AssemblyProperties[] | TransportProperties[]>;
        if (positions.length > 0) {
            properties = this.positionService.getPropertiesForMultipleWindows(positions.map(pos => pos.id));
            positionType = PositionType.SYSTEM;
        } else if (gatePositions.length > 0) {
            properties = this.positionService.getPropertiesForMultipleGates(gatePositions.map(pos => pos.id));
            positionType = PositionType.GATE_SYSTEM;
        } else if (configPositions.length > 0) {
            properties = this.positionService.getPropertiesForMultipleConfigs(configPositions.map(pos => pos.id));
            positionType = PositionType.CONFIG_SYSTEM;
        } else if (addonPositions.length > 0) {
            properties = this.positionService.getPropertiesForMultipleAddons(addonPositions.map(pos => pos.id));
            positionType = PositionType.BULK_ADDON;
        } else if (assemblyPositions.length > 0) {
            properties = this.positionService.getPropertiesForMultipleAssemblies(addonPositions.map(pos => pos.id));
            positionType = PositionType.ASSEMBLY;
        } else if (transportPositions.length > 0) {
            properties = this.positionService.getPropertiesForMultipleTransports(addonPositions.map(pos => pos.id));
            positionType = PositionType.TRANSPORT;
        }
        properties.subscribe({
            next: (positionsProperties) => {
                this.positionsForComparison = positions;
                this.positionsPropertiesForComparison = positionsProperties;
                this.positionTypeForComparison = positionType;
                this.setDisplayedDialog(DialogType.COMPARE_POSITIONS);
            },
            error: error => this.errors.handle(error)
        });
    }

    showOfferArchivedVersions(): void {
        this.offerService.getArchivedOfferVersions(this.id).subscribe({
            next: (data: OfferVersion[]) => {
                this.displayedDialogData = new ArchivedOfferVersionsDialogData(data);
                this.setDisplayedDialog(DialogType.OFFER_ARCHIVED_VERSIONS);
            },
            error: (error: HttpErrorResponse) => {
                let errorResponse = new ErrorResponse(error.error);
                if (errorResponse.is404()) {
                    this.navigateBack(false);
                } else {
                    this.errors.handle(error);
                }
            }
        });
    }

    public askToRestoreArchivedOfferVersion(archivedOfferId: number): void {
        if (this.canEdit) {
            this.archivedIdToRestore = archivedOfferId;
            this.changeDetector.markForCheck();
        }
    }

    public hideConfirmRestoreDialog(): void {
        this.archivedIdToRestore = undefined;
        this.changeDetector.markForCheck();
    }

    public restoreArchivedOfferVersion(): void {
        if (this.archivedIdToRestore != undefined) {
            this.blockUiController.block(PositionListComponent.RESTORE_ARCHIVED_OFFER_BLOCK_ID);
            this.offerService.restoreArchivedOfferVersion(this.id, this.archivedIdToRestore).pipe(
                finalize(() => this.blockUiController.unblock(PositionListComponent.RESTORE_ARCHIVED_OFFER_BLOCK_ID)))
                .subscribe({
                    next: offerId => {
                        this.selectionChange.emit({});
                        this.redirectToOfferPositionList(offerId);
                        this.upsellingSidebar.refresh(offerId);
                    },
                    error: error => {
                        this.errors.handle(error);
                    },
                    complete: () => {
                        this.archivedIdToRestore = undefined;
                        this.resetDialog();
                    }
                });
        }
    }

    createComplaint(): void {
        const offerPositionsForComplaint = this.getValidOfferPositions();
        this.complaintService.getComplaintsContainingPositions(offerPositionsForComplaint.map(p => p.id)).subscribe({
            next: data => {
                if (data.length > 0) {
                    this.relatedComplaints = data;
                    this.setDisplayedDialog(DialogType.CREATE_COMPLAINT_CONFIRMATION);
                } else {
                    this.proceedToComplaintCreator();
                }
            },
            error: (error) => {
                this.errors.handle(error);
            }
        });
    }

    private getValidOfferPositions() {
        return [...this.filterValidOfferPositions(this.getSelectedItems())
            .map(({id, name, quantity, printOrder}) => {
                return {id, name, quantity, printOrder};
            })];
    }

    proceedToComplaintCreator(): void {
        const complaintPositions = this.getValidOfferPositions();
        this.storage.set(CreateComplaintComponent.COMPLAINT_OFFER_POSITIONS_KEY, JSON.stringify(complaintPositions));
        this.resetDialog();
        this.router.navigate(['/features/create-complaint', {offerId: this.offer.id}]);
    }

    navigateToComplaint(complaintId: number): void {
        this.resetDialog();
        this.router.navigate([`/features/complaint/${complaintId}/complaintPosition`]);
    }

    editComplaint(): void {
        this.router.navigate(['/features/create-complaint', {complaintId: this.complaint.id}]);
    }

    hasCorrection(): boolean {
        return this.offer != undefined && this.offer.relatedOfferId != undefined &&
            ((this.offer.relationType === 'CORRECTION_DRAFT' && this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}))
                || this.offer.relationType === 'CORRECTION');
    }

    navigateToCorrection(): void {
        if (this.offer.offerLockUserLogin == null) {
            this.blockUiController.block(PositionListComponent.ACQUIRE_OFFERLOCK_ID);
            this.offerService.blockOffer(this.offer.relatedOfferId).subscribe({
                next: hasLock => {
                    this.blockUiController.unblock(PositionListComponent.ACQUIRE_OFFERLOCK_ID);
                    this.router.navigate([`/features/offer/${this.offer.relatedOfferId}/position`, {hasLock: hasLock}]);
                },
                error: error => this.errors.handle(error)
            });
        } else {
            this.router.navigate([`/features/offer/${this.offer.relatedOfferId}/position`]);
        }
    }

    navigateToProdOrderCorrection(): void {
        this.router.navigate([`/features/${this.productionOrder.correctionId}/productionOrderPosition`]);
    }

    createComplaintButtonVisible(): boolean {
        return !this.isComplaint && !this.isProductionOrder && this.offer && this.hasOfferStatusSuitableForComplaint()
            && this.isPermitted({roles: ['ROLE_OPERATOR']})
            && this.offer.relationType !== 'CORRECTION' && this.filterValidOfferPositions(this.getSelectedItems()).length > 0;
    }

    private hasOfferStatusSuitableForComplaint() {
        return [OfferStatus.PAID, OfferStatus.CORRECTED, OfferStatus.PARTIALLY_DISPATCHED, OfferStatus.DISPATCHED]
            .includes(this.offer.status);
    }

    private filterValidOfferPositions(positions: Position[]): Position[] {
        return positions == null ? [] : positions.filter(position => position.sortGroup === PositionSortGroup.SYSTEM);
    }

    comparePositionsButtonVisible(): boolean {
        const onlyWindows = this.getSelectedItems().every(pos => isWindowPositionType(pos.type));
        const onlyGates = this.getSelectedItems().every(pos => pos.type === PositionType.GATE_SYSTEM);
        const onlyConfigs = this.getSelectedItems().every(pos => pos.type === PositionType.CONFIG_SYSTEM);
        return !this.isComplaint && !this.isProductionOrder && (onlyWindows || onlyGates || onlyConfigs) && this.getSelectedItems().length > 1;
    }

    isEditOfferEnabled(): boolean {
        return (!this.offerTransitionInProgress && this.isEditComplaintEnabled() && !this.isProductionOrder)
            || (this.canEdit || this.permissions.isKoordynator());
    }

    isEditComplaintEnabled(): boolean {
        return this.isComplaint &&
            ((this.permissions.isPermitted({roles: ['ROLE_OPERATOR']}) &&
                Complaint.isComplaintStatusIn(this.complaint, [ComplaintStatus.NEW, ComplaintStatus.TO_REVISE])) ||
                (this.permissions.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}) &&
                    Complaint.isComplaintStatusNotIn(this.complaint, [ComplaintStatus.NEW, ComplaintStatus.TO_REVISE,
                        ComplaintStatus.DELETED])));
    }

    isShowSummaryEnabled(): boolean {
        return !this.isComplaint && (this.isProductionOrder || (this.suppliers && this.suppliers.length > 0));
    }

    isUpdatePricingEnabled(): boolean {
        return !this.isComplaint && !this.isProductionOrder && this.offer && this.offer.vatBlocksEdit &&
            this.hasOfferLock && this.permissions.userCanEditOffer(this.offer.status);
    }

    isDeletePositionButtonEnabled(): boolean {
        return this.showDeleteButton && (this.isEditComplaintEnabled() || this.canEdit || this.offer.status === OfferStatus.COPY_DRAFT);
    }

    isPositionsEditEnabled(): boolean {
        return this.isEditComplaintEnabled() || this.canEdit;
    }

    getDialogComplaintPosition(): ComplaintPosition {
        if (this.displayedDialogData instanceof EditComplaintPositionDialogData) {
            return this.displayedDialogData.complaintPosition;
        }
        return undefined;
    }

    validateComplaintPositionData(): Observable<boolean> {
        let dialogData = this.dialogDataSelector.getData(this.displayedDialogData, 'EDIT_COMPLAINT_POSITION');

        if (dialogData.complaintPosition != null) {
            this.validationErrors = this.complaintPositionFormValidator.validate(dialogData.complaintPosition);
        } else {
            this.validationErrors = {};
        }

        return of(!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors));
    }

    showMovieInstructionDialog(): void {
        this.setDisplayedDialog(DialogType.COMPLAINT_MOVIE_INSTRUCTIONS);
    }

    handleMovieFileChange(file: File): void {
        if (this.displayedDialogData instanceof EditComplaintPositionDialogData) {
            this.displayedDialogData.movieFile = file;
        }
        return undefined;
    }

    showPhotoInstructionDialog(): void {
        this.setDisplayedDialog(DialogType.COMPLAINT_PHOTO_INSTRUCTIONS);
    }

    handlePhotoFileChange(file: File): void {
        if (this.displayedDialogData instanceof EditComplaintPositionDialogData) {
            this.displayedDialogData.photoFile = file;
        }
    }

    closeEditComplaintPositionDialog(): void {
        if (this.displayedDialog === DialogType.EDIT_COMPLAINT_POSITION) {
            this.resetDialog();
        }
    }

    closeComplaintPositionInstructionDialog(): void {
        this.setDisplayedDialog(DialogType.EDIT_COMPLAINT_POSITION);
    }

    public getHeaderNumber() {
        if (this.isComplaint && this.complaint != undefined) {
            return this.complaint.complaintNumber;
        } else if (this.offer != undefined) {
            return this.offer.offerNumber;
        }
    }

    fillPossibleTransitions() {
        if (this.isComplaint) {
            this.possibleTransitions = this.complaintStatusTransitionService.createPossibleTransitions(this.complaint,
                actionName => this.onChangeStatusSuccess(actionName),
                (actionName, error) => this.onChangeStatusError(error));
        } else {
            this.possibleTransitions = this.offerStatusTransitionDialogService.createPossibleTransitions(this.offer,
                actionName => this.onChangeStatusSuccess(actionName),
                (actionName, error) => this.onChangeStatusError(error));
        }
    }

    onChangeStatusSuccess(actionName: string): void {
        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
        setTimeout(() => {
            this.offerTransitionInProgress = false;
        }, 500);

        this.showOfferActionSuccessMessage(actionName);

        if (this.isComplaint) {
            this.reloadComplaint.emit();
        } else {
            if ((this.offer.status === OfferStatus.TO_REVIEW || this.offer.status === OfferStatus.VERIFICATION_FOR_APPROVAL)
                && this.permissions.isPermitted({roles: ['ROLE_OPIEKUN']})) {
                console.info('OffersComponent action `' + actionName + '` completed but user is no longer allowed to view offer!');
                this.router.navigate(['features/offer']);

                return;
            } else {
                this.reloadAllTables();
            }

            console.info('OffersComponent action `' + actionName + '` completed!');
            this.reloadOffer();
        }
    }

    onChangeStatusError(error: any): void {
        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
        this.offerTransitionInProgress = false;
        this.errors.handle(error);
        this.reloadAllTables();
    }

    private showOfferActionSuccessMessage(actionName: string) {
        let message = this.isComplaint ? 'OFFER.COMPLAINT.ACTIONS.ON_SUCCESS.CHANGE_STATUS' : 'OFFER.ACTIONS.ON_SUCCESS.' + actionName;
        this.growlMessageController.info(message);
    }

    showTransitionsDialog() {
        if (!this.offerTransitionInProgress) {
            this.transitionsDialogVisible = true;
            this.changeDetector.markForCheck();
        }
    }

    hideTransitionsDialog() {
        this.transitionsDialogVisible = false;
        this.changeDetector.markForCheck();
    }

    changeStatus(selectedTransition: StatusTransition) {
        if (selectedTransition) {
            this.applyTransitionChange(selectedTransition);
            this.hideTransitionsDialog();
        }
    }

    applyTransitionChange(selectedTransition: StatusTransition, event?: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        if (!this.offerTransitionInProgress) {
            switch (selectedTransition.action) {
                case 'CHANGE_STATUS':
                    this.offerTransitionInProgress = true;
                    this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                    selectedTransition.item.command();
                    break;
                case 'CONFIRM_CORRECTION':
                    this.displayedDialog = DialogType.CORRECTION_ORDER_GENERATION;
                    break;
                default:
                    console.error("Unsupported status transition.", selectedTransition.action);
                    break;
            }
        }
    }

    statusTransitionButtonClick(event?: MouseEvent): void {
        if (this.possibleTransitions.length === 1) {
            this.applyTransitionChange(this.possibleTransitions[0], event);
        } else if (this.possibleTransitions.length > 1) {
            this.showTransitionsDialog();
        }
        this.setCanEditField();
    }

    showTransitionInfo(event: MouseEvent): void {
        if (event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        }

        this.statusTransitionInfoDialogVisible = true;
        this.changeDetector.markForCheck();
    }

    hideStatusTransitionDialog(): void {
        this.statusTransitionInfoDialogVisible = false;
        this.changeDetector.markForCheck();
    }

    getStatusTransitionInfoLabel(): string {
        let baseKey = (this.isComplaint ? 'OFFER.COMPLAINT.ACTIONS.DIALOG' : 'OFFER.ACTIONS.DIALOGS') + '.STATUS_TRANSITION_INFO.CONTENT.';
        return this.translate.instant(baseKey + this.possibleTransitions[0].item.id);
    }

    showShippingSidebar(): void {
        this.shippingSimulationSidebar.show();
    }

    showUpsellingSidebar(): void {
        this.upsellingSidebar.show();
    }

    shippingSidebarHidden(): void {
        this.shippingFloatButton.refresh();
    }

    shippingCalculationStarted(): void {
        this.shippingCalculationInProgress = true;
    }

    shippingCalculationFinished(): void {
        this.shippingCalculationInProgress = false;
    }

    private handleOfferLoadingError(): void {
        this.navigateBack(false);
    }

    onAddPositionHintBlur(): void {
        this.addPositionHintButton.closeMenu();
    }

    onAddPositionHintClick(event: MouseEvent): void {
        if (!this.blurEventListenerAdded) {
            this.blurEventListenerAdded = true;
            this.addAddPositionHintBlurEventListener();
        }
        this.addPositionHintButton.onClick();
    }

    addAddPositionHintBlurEventListener(): void {
        if (this.addPositionHintRegionDiv != null) {
            this.addPositionHintRegionDiv.nativeElement.addEventListener("blur", () => {
                this.onAddPositionHintBlur();
            }, true);
        }
    }

    showAddPositionHint(): boolean {
        return this.canEdit && !this.isComplaint && this.suppliers.length === 0 && this.assembliesData == null &&
            this.ownAddonsData == null && this.transportsData == undefined;
    }

    isSelectedItem(item: ProductionOrdersPosition): boolean {
        return this.tableData.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: ProductionOrdersPosition): void {
        let index = this.tableData.selectedItems.indexOf(item);
        if (index > -1) {
            this.tableData.selectedItems.splice(index, 1);
        } else {
            this.tableData.selectedItems.push(item);
        }
        if (this.tableData.selectedItems.length === this.tableData.positions.filter(pos => pos.type !== PositionType.OFFER_CHARGE).length) {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED;
        } else if (this.tableData.selectedItems.length === 0) {
            this.tableData.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    selectAllChange(): void {
        this.tableData.selectedItems = [];

        if (this.tableData.allSelectedState === TristateCheckboxState.CHECKED) {
            this.tableData.selectedItems.push(...this.tableData.positions.filter(pos => pos.type !== PositionType.OFFER_CHARGE));
        }
    }

    handleRowKeyDown(event: any): void {
        if (event.originalEvent.code === 'Space') {
            event.originalEvent.preventDefault();
            this.selectItem(event.data);
        }
    }

    getQuantityTypeForPosition(position: ProductionOrdersPosition): string {
        if (isWindowPositionType(position.type) ||
            position.type === PositionType.CONFIGURABLE_ADDON || position.type === PositionType.GATE_SYSTEM) {
            return 'ADDONS.QUANTITY_TYPE.ABBREVIATION.PIECE';
        }
        if (position.type === PositionType.BULK_ADDON) {
            let addonFromData: BulkAddonData = JSON.parse(position.data);
            return 'ADDONS.QUANTITY_TYPE.ABBREVIATION.' + addonFromData.quantityType;
        }
        console.error("Cannot find proper position type quantity. Position type is " + position.type);
        return "";
    }

    formatOfferPriceFunction = (price: number) => this.formatOfferPrice(price, true);

    formatOfferPrice(price: number, applySubsystemManualExchangeRate = false): string {
        if (price == null) {
            return '';
        }
        if (this.selectedCurrency === this.offer.currency) {
            return this.formatPriceInOfferCurrencyWithManualChange(price, applySubsystemManualExchangeRate);
        }
        return ExchangeService.formatPriceInCurrency(
            this.exchangeService.getPLNPriceInCurrency(price, this.selectedCurrency), this.selectedCurrency);
    }

    formatPriceInOfferCurrencyWithManualChange(price: number, applySubsystemManualExchangeRate = false): string {
        if (price == null) {
            return '';
        }
        if (applySubsystemManualExchangeRate && this.offer.subsystemManualExchangeRate) {
            return ExchangeService.formatPriceInCurrency(
                ExchangeService.getPriceInDefaultCurrency(price, this.offer.subsystemManualExchangeRate), this.offer.currency);
        }
        return ExchangeService.formatPriceInCurrency(
            ExchangeService.getPriceInDefaultCurrency(price, this.offer.exchangeRate), this.offer.currency);
    }

    formatPriceInOfferCurrency(price: number): string {
        return ExchangeService.formatPriceInCurrency(
            ExchangeService.getPriceInDefaultCurrency(price, this.productionOrder.offerExchangeRate), this.productionOrder.offerCurrency);
    }

    formatPriceInSelectedCurrency(price: number): string {
        if (price == null) {
            return '';
        }
        if (this.selectedCurrency === this.productionOrder.offerCurrency) {
            return this.formatPriceInOfferCurrency(price);
        }
        return ExchangeService.formatPriceInCurrency(
            this.exchangeService.getPLNPriceInCurrency(price, this.selectedCurrency), this.selectedCurrency);
    }

    positionHasTemporaryDealerDiscount(item: ProductionOrdersPosition): boolean {
        return item.tempDealerDiscount != undefined || item.tempSystemDealerDiscount != undefined;
    }

    handleChangeDiscountError(error) {
        this.resetDialog();
        this.errors.handle(error);
    }

    handleChangeDiscountSave() {
        this.resetDialog();
        this.growlMessageController.info('OFFER.PRODUCTION_ORDERS.CHANGE_DEALER_DISCOUNT.SUCCESS');
        this.loadProdOrderTableItems(this.tableData.lastLoadEvent);
    }

    setShowChangeDealerDiscountDialog(visible: boolean) {
        if (visible) {
            this.setDisplayedDialog(DialogType.CHANGE_DEALER_DISCOUNT);
        } else {
            this.resetDialog();
        }
        this.changeDetector.markForCheck();
    }

    setShowChangeSystemDealerDiscountDialog() {
        this.setDisplayedDialog(DialogType.CHANGE_SYSTEM_DEALER_DISCOUNT);
        this.changeDetector.markForCheck();
    }

    handleChangeSystemDealerDiscountDialogClose(saved: boolean) {
        this.resetDialog();
        if (saved) {
            this.loadProdOrderTableItems(this.tableData.lastLoadEvent);
            this.growlMessageController.info('OFFER.PRODUCTION_ORDERS.CHANGE_SYSTEM_DEALER_DISCOUNT.SUCCESS');
        }
    }

    onUpdateExchangeRateSuccess(successMsgKey: string, newExchangeRates: OfferExhangeRates): void {
        this.offer.exchangeRate = newExchangeRates.exchangeRate;
        this.offer.subsystemManualExchangeRate = newExchangeRates.subsystemManualExchangeRate;
        this.showActionSuccessMessage(successMsgKey);
        this.reloadAllTables();
        this.handleExchangeRateDifference();
        this.resetDialog();
    }

    getSupplierPositionTableHeader(supplier: Supplier): string {
        let supplierSubsystemName = supplier.name[this.userLang];
        let nameLabel = this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']}) ? `${supplier.companyName} (${supplierSubsystemName})` : supplierSubsystemName;
        let positionTable = this.tableDataBySupplier[supplier.id];
        return positionTable.hasWindowPositions() ?  `${nameLabel} - ${positionTable.getWindowPositionsCount()}` : nameLabel;
    }

    applyUpselling(proposition: UpsellingPropositionResult) {
        this.blockUiController.block(PositionListComponent.UPSELLING_BLOCK);
        this.positionService.applyUpselling(this.id, proposition.id, proposition.systemIds)
            .pipe(
                finalize(() => this.blockUiController.unblock(PositionListComponent.UPSELLING_BLOCK)),
                mergeMap(() => {
                    // could reload only affected suppliers but
                    // to update messages marks on all positions we need to reload all
                    this.reloadAllTables();
                    return this.offerService.getItem(this.id);
                }))
            .subscribe({
                next: offer => {
                    this.offer = offer;
                    this.fillRestoreVersionMenu();
                    this.changeDetector.markForCheck();
                },
                error: error => {
                    this.errors.handle(error);
                }
            });
    }

    private openUpdateValidityDatesDialog(): void {
        this.displayedDialogData = new UpdateValidityDatesDialogData(this.offer.id);
        this.setDisplayedDialog(DialogType.UPDATE_VALIDITY_DATES);
    }

    handlePrintOrderChange(position: Position): void {
        switch (position.sortGroup) {
            case PositionSortGroup.SYSTEM:
                this.reloadTableForSupplier(position.supplierId);
                this.getSuppliersWithChildPositions(position).forEach(supplierId => this.reloadTableForSupplier(supplierId));
                break;
            case PositionSortGroup.OWN_ADDON:
                this.reloadOwnAddonsTable();
                break;
            case PositionSortGroup.ASSEMBLY:
                this.reloadAssembliesTable();
                break;
            case PositionSortGroup.OWN_TRANSPORT:
                this.reloadTransportsTable();
                break;
        }
    }

    isPositionAlwaysReadOnly(position: Position): boolean {
        return position.type === PositionType.OFFER_CHARGE;
    }
}
