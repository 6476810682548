<button [attr.id]="buttonId" type="button" [disabled]="disabled" (blur)="closeMenu()" (click)="onClick()"
        [ngClass]="getCssClassesForTab" [pTooltip]="buttonLabel" [tooltipDisabled]="buttonInlineLabel">
    <span class="simple-button-flex-container"
          *ngIf="leftButtonIcon || buttonLabel || rightButtonIcon">
        <span *ngIf="leftButtonIcon" class="simple-button-icon material-icons">{{ leftButtonIcon }}</span>
        <ng-container *ngIf="buttonInlineLabel && buttonLabel">
            <span *ngIf="!leftButtonIcon" class="simple-button-extra-text-padding"></span>
            <span class="simple-button-label">{{ buttonLabel }}</span>
            <span *ngIf="!rightButtonIcon" class="simple-button-extra-text-padding"></span>
        </ng-container>
        <span *ngIf="rightButtonIcon" class="simple-button-icon material-icons">{{ rightButtonIcon }}</span>
        <span class="simple-button-padding"></span>
    </span>
    <ng-content></ng-content>
</button>

<button-popup-menu *ngIf="menuElementsLazy != undefined" [menuId]="buttonId+'_popupmenu'"
             [menuElements]="menuElementsLazy"
             [menuType]="menuType"
             [menuVisible]="menuVisible"
             [alignmentPopupPosition]="alignmentPopupPosition"
             (menuElementSelected)="positionSelected($event)">
</button-popup-menu>
