import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {EmptyError, Observable} from 'rxjs';
import {defaultIfEmpty, finalize, tap} from 'rxjs/operators';
import {ApplicationFeatures} from '../application-info/application-features';
import {ApplicationInfoService} from '../application-info/application-info.service';
import {UserUiConfigService} from '../auth/uiconfig/userUiConfig.service';

/**
 * Blocks loading a component before user ui config is loaded from the server
 */
@Injectable()
export class UserUiConfigResolver {

    constructor(private uiConfigService: UserUiConfigService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        return new Observable(subscriber => {
            const unsubscribeWorkaround = {completedNaturally: false};
            this.uiConfigService.remoteFetchUserUiConfig()
                .pipe(
                    finalize(() => {
                        if (!unsubscribeWorkaround.completedNaturally) {
                            subscriber.error(new EmptyError());
                        }
                    }),
                    // must be last for finalize() to work on custom unsubscribe logic in authAwareHttp
                    tap(response => {
                        this.uiConfigService.storeUserUiConfig(response);
                    }))
                .subscribe({
                    next: data => subscriber.next(data),
                    error: error => {
                        unsubscribeWorkaround.completedNaturally = true;
                        subscriber.error(error);
                    },
                    complete: () => {
                        unsubscribeWorkaround.completedNaturally = true;
                        subscriber.complete();
                    }
                });
        });
    }
}

@Injectable()
export class ApplicationFeaturesResolver {

    constructor(private applicationInfoService: ApplicationInfoService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ApplicationFeatures> {
        return this.applicationInfoService.getFeatures()
            .pipe(defaultIfEmpty({
                enabledProducts: [],
                enableProductionOrderStatistics: false,
                enableSalesTargets: false
            }));
    }
}
