import {AfterViewInit, ChangeDetectorRef, Directive, Injector, OnDestroy, OnInit} from "@angular/core";
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {Observable, of, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {StorageService} from "../../auth/storage.service";
import {UserUiConfigService} from "../../auth/uiconfig/userUiConfig.service";
import {BlockUiController} from '../../block-ui/block-ui-controller';
import {DatatableHelper, DatatableInterface} from "../DatatableHelper";
import {FocusOnElement} from "../FocusOnElement";
import {GrowlMessageController} from '../growl-message/growl-message-controller';
import {DataTableColumn} from "../service/data.table.column";
import {DataTableColumnBuilder, SavedShownColumns} from "../service/data.table.column.builder";
import {ValidationErrors} from '../validation-errors';
import {CrudItem} from "./crudItem";
import {PaginatorRowsPerPageOptions} from "./paginatorRowsPerPageOptions";

export interface KeepSelectedItemEventParams {
    originalEvent?: {
        origin: DatatableInterface;
        returnTabIndex: number;
    };
}

@Directive()
export abstract class ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy, AfterViewInit {

    public static readonly ROWS_PER_PAGE_PROPERTY_KEY = "rowsPerPage";
    public static readonly SHOWN_COLUMNS_PROPERTY_KEY: string = "shownColumns";

    public readonly INPUT_NUMBER_DEFAULT_MAX_VALUE = 999999;
    public readonly INPUT_NUMBER_DEFAULT_MIN_VALUE = 0;
    readonly rowsPerPageOptions: number[] = PaginatorRowsPerPageOptions.values;
    readonly limitedRowsPerPageOptions: number[] = PaginatorRowsPerPageOptions.limitedValues;
    chosenRowsPerPage: number;
    chosenPageNumber = 0;

    selectedItemTabIndex: number;
    selectedItemDatatable: DatatableInterface;

    protected enterHotkey: Hotkey;
    newElementHotkey: Hotkey;
    copyHotkey: Hotkey;
    saveInProgress = false;

    validationErrors: { [field: string]: string } = {};
    copyMode = false;

    showFilters = false;

    private userUiConfigService: UserUiConfigService;
    protected hotkeysService: HotkeysService;
    protected blockUiController: BlockUiController;
    protected growlMessageController: GrowlMessageController;

    defaultSortColumn: string = undefined;
    defaultSortOrder: number;

    private timeout: number;

    protected readonly componentDestroyed = new Subject<boolean>();

    protected constructor(injector: Injector,
                          protected changeDetector: ChangeDetectorRef,
                          protected viewName: string,
                          private isCopySupported: boolean) {
        this.userUiConfigService = injector.get(UserUiConfigService);
        this.hotkeysService = injector.get(HotkeysService);
        this.blockUiController = injector.get(BlockUiController);
        this.growlMessageController = injector.get(GrowlMessageController);

        this.chosenRowsPerPage = this.getRowsPerPageValue();
        this.enterHotkey = new Hotkey('enter', (event: any): boolean => {
            if (event.target && event.target.className && event.target.className.includes("p-datatable-cell-editor")) {
                return false;
            }

            this.submit();
            return false;
        }, ['INPUT']);
        this.newElementHotkey = new Hotkey('alt+n', () => {
            this.doShowDialogToAdd();
            return false;
        }, undefined, 'GENERAL.HOTKEYS.NEW');
        this.copyHotkey = new Hotkey('alt+c', () => {
            this.doShowDialogToCopy();
            return false;
        }, undefined, 'GENERAL.HOTKEYS.COPY');
        this.blockUiController.block(this.viewName + 'View');
        this.blockUiController.block(this.viewName + 'Data');
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.newElementHotkey);
        if (this.isCopySupported) {
            this.hotkeysService.add(this.copyHotkey);
        }

        this.initShownColumns();
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.newElementHotkey);
        this.hotkeysService.remove(this.copyHotkey);
        if (this.timeout != undefined) {
            clearTimeout(this.timeout);
        }
        this.componentDestroyed.next(true);
        this.componentDestroyed.complete();
    }

    ngAfterViewInit() {
        this.showHideFilters(false);
        this.hideViewLoadingIndicator();
    }

    abstract showDialogToAdd(): void;

    abstract submit(): void;

    abstract onRowSelect(event: { data: any } & KeepSelectedItemEventParams): void;

    protected getColumns(): DataTableColumn[] {
        return null;
    }

    doShowDialogToAdd(): void {
        this.hotkeysService.remove(this.copyHotkey);
        this.setSaveInProgress(false);
        this.validationErrors = {};
        let focused = document.querySelector(':focus');
        if (focused instanceof HTMLElement) {
            focused.blur();
        }
        this.showDialogToAdd();
        this.focusOnElementWithId(this.getFirstInputId());
    }

    private addEnterHotkeyDelayed() {
        this.timeout = window.setTimeout(() => {
            this.timeout = undefined;
            this.hotkeysService.add(this.enterHotkey);
        }, 100);
    }

    doShowDialogToCopy(): void {
        this.setSaveInProgress(false);
        if (this.isCopySupported) {
            this.hotkeysService.remove(this.newElementHotkey);
            this.copyMode = true;
            this.validationErrors = {};
            let focused = document.querySelector(':focus');
            if (focused instanceof HTMLElement) {
                focused.blur();
            }
            this.showDialogToCopy();
            this.focusOnElementWithId(this.getFirstInputId());
            this.addEnterHotkeyDelayed();
        }
    }

    showDialogToCopy(): void {
        console.log("COPY DIALOG NOT SUPPORTED");
        this.hotkeysService.add(this.newElementHotkey);
    }

    doOnRowSelect(event: { data: any } & KeepSelectedItemEventParams): void {
        this.validationErrors = {};
        this.onRowSelect(event);
        this.focusOnElementWithId(this.getFirstInputId());
        this.addEnterHotkeyDelayed();
    }

    protected saveUserUiConfigForThisView(key: string, value: any) {
        this.userUiConfigService.saveConfigForTheView(this.viewName, key, value);
    }

    protected getUserUiConfigForThisView(key: string): any {
        return this.userUiConfigService.getConfigForTheView(this.viewName, key);
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        if (event) {
            this.handlePaginatorState(event.rows);
            this.showDataLoadingIndicator();
            this.updateShownColumnsSortOrder(event);
        }
    }

    protected storeLazyLoadEvent(event: LazyLoadEvent, storage: StorageService): void {
        let eventData = {
            first: event.first,
            rows: event.rows,
            sortField: event.sortField,
            sortOrder: event.sortOrder,
            filters: event.filters
        };

        storage.set(this.viewName + "_filters", JSON.stringify(eventData));
    }

    protected retrieveStoredLazyLoadEvent(storage: StorageService): LazyLoadEvent {
        let stored: string = storage.get(this.viewName + "_filters");

        if (stored) {
            return JSON.parse(stored);
        }

        return null;
    }

    applyLazyLoadEventData(event: LazyLoadEvent): void {
        DataTableColumnBuilder.applyLazyLoadEventData(event, this.getColumns());
        Object.keys(event.filters).filter(field => event.filters[field] != undefined).forEach(field => {
            this.getDatatable().setFilterValue(event.filters[field].value, field, 'contains');
        });
        this.getDatatable().setSingleSortProperties(event.sortField, event.sortOrder);
        this.defaultSortColumn = event.sortField;
        this.defaultSortOrder = event.sortOrder;
    }

    resetTableFilterInputs(table: DatatableInterface) {
        let thead = (table.el.nativeElement as Element).querySelector('thead');
        if (thead == undefined) {
            return;
        }
        this.getColumns().forEach(column => {
            let filterValue = column.defaultFilterValue == null ? '' : column.defaultFilterValue.value;
            let input = thead.querySelector('input[name="' + column.field + '"]');
            // handle custom template filters
            if (input instanceof HTMLInputElement) {
                input.value = filterValue;
            }
        });
    }

    protected showDataLoadingIndicator() {
        this.blockUiController.block(this.viewName + 'Data');
    }

    protected hideDataLoadingIndicator() {
        this.blockUiController.unblock(this.viewName + 'Data');
    }

    protected hideViewLoadingIndicator() {
        this.blockUiController.unblock(this.viewName + 'View');
    }

    private handlePaginatorState(eventRows: number): void {
        if (this.chosenRowsPerPage !== eventRows) {
            this.chosenRowsPerPage = eventRows;
            this.saveRowsPerPageValue();
        }
    }

    private saveRowsPerPageValue(): void {
        this.userUiConfigService.saveConfigForTheView(this.viewName,
            ComponentWithUserConfigAndPaginator.ROWS_PER_PAGE_PROPERTY_KEY, this.chosenRowsPerPage);
    }

    protected getRowsPerPageValue(): number {
        let value = this.userUiConfigService.getConfigForTheView(this.viewName,
            ComponentWithUserConfigAndPaginator.ROWS_PER_PAGE_PROPERTY_KEY);
        return value ? value : PaginatorRowsPerPageOptions.defaultValue;
    }

    keepSelectedItemIndex(event: KeepSelectedItemEventParams): void {
        if (event.originalEvent) {
            this.selectedItemTabIndex = event.originalEvent.returnTabIndex;
            this.selectedItemDatatable = event.originalEvent.origin;
        }
    }

    restoreSelectionAndResetHotkeys(): void {
        this.hotkeysService.remove(this.enterHotkey);
        if (this.selectedItemDatatable && this.selectedItemTabIndex) {
            DatatableHelper.focusOnRowWithIndex(this.selectedItemDatatable, this.selectedItemTabIndex);
        }
    }

    restoreSelectionAndResetHotkeysAfterCopyCanceled(datatable: DataTable, tableElement: any): void {
        this.hotkeysService.remove(this.enterHotkey);
        if (tableElement) {
            DatatableHelper.focusOnRowWithElement(datatable, tableElement);
        }
    }

    restoreSelectionAndResetHotkeysAfterCancel(tableElement: any): void {
        this.hotkeysService.remove(this.enterHotkey);
        if (this.isCopySupported) {
            this.hotkeysService.add(this.copyHotkey);
        }
        this.hotkeysService.add(this.newElementHotkey);
        let datatable = this.getDatatable();
        if (tableElement && datatable) {
            DatatableHelper.focusOnRowWithElement(datatable, tableElement);
        }
    }

    abstract getDatatable(): DatatableInterface;

    protected reloadDatatable(): void {
        const datatable = this.getDatatable();
        if (datatable != undefined) {
            DatatableHelper.reload(datatable);
        }
    }

    protected focusOnElementWithId(firstInputId: string): void {
        FocusOnElement.tryToFocus(firstInputId, 0, 3, 100);
    }

    protected getFirstInputId(): string {
        return "name";
    }

    validationErrorsPresent(validationErrors?: { [field: string]: string }): boolean {
        if (!validationErrors) {
            validationErrors = this.validationErrors;
        }
        return Object.keys(validationErrors).filter(key => validationErrors[key] != undefined).length > 0;
    }

    processValidationResults(frontendValidationErrors: ValidationErrors,
                             backendValidationErrors?: Observable<ValidationErrors>): Observable<boolean> {
        if (this.validationErrorsPresent(frontendValidationErrors)) {
            return of(false)
                .pipe(tap(() => {
                    this.validationErrors = Object.assign({}, this.validationErrors, frontendValidationErrors);
                    this.changeDetector.markForCheck();
                }));
        }
        if (backendValidationErrors != undefined) {
            return backendValidationErrors.pipe(
                tap(errors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, errors);
                    this.changeDetector.markForCheck();
                }),
                map(errors => !this.validationErrorsPresent(errors)));
        }
        return of(true);
    }

    clearError(controlName): void {
        if (this.validationErrors[controlName] != undefined) {
            this.validationErrors[controlName] = undefined;
            this.changeDetector.markForCheck();
        }
    }

    public setSaveInProgress(saveInProgress: boolean): void {
        this.saveInProgress = saveInProgress;
    }

    public isSaveInProgress(): boolean {
        return this.saveInProgress;
    }

    mapStateToTriStateCheckboxState(state: string) {
        return DatatableHelper.mapStateToTriStateCheckboxState(state);
    }

    showHideFilters(show?: boolean) {
        this.showFilters = show === undefined ? !this.showFilters : show;
        let columns: DataTableColumn[] = this.getColumns();

        if (columns) {
            columns.forEach(column => {
                if (column.filterable) {
                    column.filter = this.showFilters;
                }
            });
        }
    }

    restoreSelectionAfterLoad<T>(selectedItem: T, itemList: T[], event: LazyLoadEvent, keyExtractor: (item: T) => any): T;
    restoreSelectionAfterLoad<T extends CrudItem>(selectedItem: T, itemList: T[], event: LazyLoadEvent): T;
    restoreSelectionAfterLoad<T>(selectedItem: T, itemList: T[], event: LazyLoadEvent, keyExtractor?: (item: any) => any): T {
        if (keyExtractor == undefined) {
            keyExtractor = item => item.id;
        }
        let selectedItemIndex = -1;
        const selectedItemKey = selectedItem != undefined ? keyExtractor(selectedItem) : undefined;
        if (selectedItemKey != undefined) {
            selectedItemIndex = itemList.findIndex(item => keyExtractor(item) === selectedItemKey);
        }
        if (selectedItemIndex === -1) {
            selectedItemIndex = 0;
        }
        DatatableHelper.focusOnRowIfNotEditingFilters(event, selectedItemIndex);
        return itemList[selectedItemIndex];
    }

    protected isDefaultSortOrderChanged(event: LazyLoadEvent): boolean {
        return event != null && event.sortField
            && (event.sortField !== this.defaultSortColumn || event.sortOrder !== this.defaultSortOrder);
    }

    public updateShownColumnsSortOrder(event: LazyLoadEvent): void {
        if (this.isDefaultSortOrderChanged(event)) {
            let newColumns = undefined;

            if (event.sortField != null) {
                newColumns = {};

                newColumns[event.sortField] = {
                    sortOrder: event.sortOrder
                };
            }

            this.saveUserUiConfigForThisView(ComponentWithUserConfigAndPaginator.SHOWN_COLUMNS_PROPERTY_KEY, newColumns);
        }
    }

    protected initShownColumns(): void {
        let shownColumns = this.getShownColumns();

        if (shownColumns != null) {
            for (let column in shownColumns) {
                if (shownColumns[column] != null && shownColumns[column].sortOrder) {
                    this.defaultSortOrder = shownColumns[column].sortOrder;
                    this.defaultSortColumn = column;
                }
            }
        }
    }

    public getShownColumns(): SavedShownColumns {
        return this.getUserUiConfigForThisView(ComponentWithUserConfigAndPaginator.SHOWN_COLUMNS_PROPERTY_KEY);
    }

    columnHeaderClasses(field: string): string {
        const table = this.getDatatable();
        if (table == undefined) {
            return '';
        }
        const filter = table.filters[field];
        if (filter == undefined) {
            return '';
        }
        const hasFilterValue = (filterMeta: FilterMetadata): boolean =>  filterMeta.value != undefined && filterMeta.value !== '';
        let activeFilter = Array.isArray(filter) ? filter.some(hasFilterValue) : hasFilterValue(filter);

        let classes = '';
        if (activeFilter) {
            classes = 'p-column-title-underlined';
        } else {
            return classes;
        }

        if (this.showFilters) {
            classes += ' p-column-header-highlighted';
        }
        return classes;
    }

    handleInputFilterClick(event: MouseEvent) {
        event.stopPropagation();
    }

    getInputEventValue(event: Event): string {
        if (event.target instanceof HTMLInputElement
            || event.target instanceof HTMLSelectElement
            || event.target instanceof HTMLTextAreaElement) {
            return event.target.value;
        }
        return undefined;
    }
}
