import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JwtInterceptor} from "../../auth/jwt.interceptor";
import {mapItemToJson} from '../../common/crud-common/crudItem';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {ValidationErrors} from '../../common/validation-errors';
import {ValidationErrorsHelper} from '../../common/ValidationErrorsHelper';
import {SimpleProfitMarginSource} from '../client/simple-profit-margin-source/simple-profit-margin-source';
import {ClientGroup} from './ClientGroup';
import {ClientGroupList} from './ClientGroupList';

@Injectable()
export class ClientGroupService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getClientGroups(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                    sortColumn: string, sortOrder: number): Observable<ClientGroupList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<ClientGroupList>('clientgroups', {params: params});
    }

    getItem(clientGroupId: number, ignorePermissionErrors = false): Observable<ClientGroup> {
        const headers = {};
        if (ignorePermissionErrors) {
            headers[JwtInterceptor.SKIP_ERROR_HANDLING_HEADER] = '1';
        }
        return this.http.get<ClientGroup>(`clientgroup/${clientGroupId}`, {headers: headers});
    }

    add(clientGroup: ClientGroup): Observable<number> {
        return this.http.post<void>('clientgroup', clientGroup, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId('clientgroup', response)));
    }

    edit(clientGroupId: number, clientGroup: ClientGroup): Observable<number> {
        return this.http.put<void>(`clientgroup/${clientGroupId}`, clientGroup)
            .pipe(map(() => clientGroupId));
    }

    delete(clientGroup: ClientGroup): Observable<void> {
        return this.http.delete<void>(`clientgroup/${clientGroup.id}`);
    }

    validateDetails(clientGroup: ClientGroup): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('clientgroup/validateGeneralDetails', clientGroup));
    }

    getSimpleProfitMarginSource(clientGroupId: number): Observable<SimpleProfitMarginSource> {
        return this.http.get<object>(`clientgroup/${clientGroupId}/simpleProfitMargins`)
            .pipe(mapItemToJson(SimpleProfitMarginSource));
    }

    saveSimpleProfitMarginSource(clientGroupId: number, profitMargins: SimpleProfitMarginSource): Observable<void> {
        return this.http.post<void>(`clientgroup/${clientGroupId}/simpleProfitMargins`, profitMargins);
    }

    editBulkAddonProfitMargin(clientGroupId: number, profitMarginValue: string): Observable<void> {
        return this.http.put<void>(`clientgroup/${clientGroupId}/bulkAddonProfitMargin`, profitMarginValue, {
            headers: {'Content-Type': 'text/plain'},
        });
    }
}
