export enum WebshopAreaBarsCode {
    NONE = 'NONE',
    M1x0 = 'M1x0',
    M1x1 = 'M1x1',
    M0x1 = 'M0x1',
    G1x0 = 'G1x0',
    G2x1 = 'G2x1',
    G1x1 = 'G1x1',
    G1x2 = 'G1x2',
    G1x3 = 'G1x3',
    G1x4 = 'G1x4',
    G0x1 = 'G0x1',
    G0x2 = 'G0x2',
    G0x3 = 'G0x3',
    G0x4 = 'G0x4',
    G2x2 = 'G2x2',
    L0xR__G1x0_T = 'L0xR__G1x0_T',
    L0xR__G2x0_T = 'L0xR__G2x0_T',
    L0xR__G1x0_T__C_T = 'L0xR__G1x0_T__C_T',
    L0xR__G1x1_T = 'L0xR__G1x1_T',
    M1x0__L0xR__G1x0_T = 'M1x0__L0xR__G1x0_T',
    M1x0__L0xR__G1x1_T = 'M1x0__L0xR__G1x1_T',
    M1x0__G0x1 = 'M1x0__G0x1',
    M1x0__G1x1 = 'M1x0__G1x1',
    M1x0__G0x2 = 'M1x0__G0x2',
    M1x0__G1x2 = 'M1x0__G1x2',
    M0xR__G1x0_T = 'M0xR__G1x0_T',
    M0xR__G2x0_T = 'M0xR__G2x0_T',
    M0xR__G1x1_T = 'M0xR__G1x1_T',
    M0xR__G2x1_T = 'M0xR__G2x1_T',
    M0xR__G1x0_T__C_T = 'M0xR__G1x0_T__C_T',
    M1xR_T__G1x1 = 'M1xR_T__G1x1',
    M1xR__G1x0_T = 'M1xR__G1x0_T',
    M1xR__G1x1_T = 'M1xR__G1x1_T'
}
