import {Directive, ElementRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {GateControl} from "../../../../../gate-painter/enums/gate-control.enum";
import {GateImagePaintParams} from '../../../../../gate-painter/gate-image-paint-params';
import {GatePainterService} from './gate-painter.service';

@Directive({
    selector: '[app-gate-painter]'
})
export class GatePainterDirective implements OnInit, OnChanges {

    readonly CALCULATE_ME_ELEMENT = '[id^=formula]';

    @Input()
    imageTemplate: string;

    private svgTemplate: Snap.Fragment;

    private readonly formulaReplacer = new Map<string, (params: GateImagePaintParams) => number>([
        ["N", (p) => p.lintelHeight],
        ["W", (p) => p.width],
        ["H", (p) => p.height],
        ["M", (p) => p.control === GateControl.MANUAL ? 1 : 0],
        ["D", (p) => p.control === GateControl.DRIVE ? 1 : 0],
    ]);
    private readonly formulaOperators = ['+', '-', '*', '/', '(', ')', '.', 'd'];
    private readonly formulaSanitizer =
        new RegExp(`[^${[...this.formulaOperators].join('\\')}${[...this.formulaReplacer.keys()].join('')}]`, 'g');

    constructor(private readonly gatePainterService: GatePainterService,
                private readonly hostElement: ElementRef<Element>) {
    }

    ngOnInit() {
        this.gatePainterService.paintEvents.subscribe(params => {
            this.paint(params);
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        const imageChange = changes['imageTemplate'];
        if (imageChange != undefined) {
            this.svgTemplate = !!imageChange.currentValue ? Snap.parse(imageChange.currentValue) : undefined;
        }
    }

    private paint(params: GateImagePaintParams): void {
        const range = document.createRange();
        range.selectNodeContents(this.hostElement.nativeElement);
        range.deleteContents();

        if (this.svgTemplate != undefined) {
            if (!params.width || !params.height || !params.lintelHeight || !params.control) {
                return;
            }
            this.hostElement.nativeElement.appendChild(this.svgTemplate.node.cloneNode(true));

            this.hostElement.nativeElement.querySelectorAll(this.CALCULATE_ME_ELEMENT).forEach(el => {
                const formula = el.id.split('_')[1];
                if (this.requiredParamsFilled(params)) {
                    const sanitizedFormula = formula.replace(this.formulaSanitizer, '');
                    const formulaFunction = Function([...this.prepareFormulaVariables(params), `return ${sanitizedFormula};`].join('; '));
                    el.textContent = formulaFunction();
                } else {
                    el.textContent = formula;
                }
            });
        }
    }

    private requiredParamsFilled(params: GateImagePaintParams): boolean {
        return params.height != undefined && params.lintelHeight != undefined && params.width != undefined && params.control != undefined;
    }

    private prepareFormulaVariables(params: GateImagePaintParams): string[] {
        const variables = [];
        this.formulaReplacer.forEach((v, k) => variables.push(`let ${k} = ${v(params)}`));
        return variables;
    }
}
