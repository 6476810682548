<div class="new-form-very-wide">
    <div class="sort-buttons-container">
        <span id="sort-available-orders-button-container">
            <button-with-menu buttonId="sortAvailableOrdersButton"
                              buttonLabel="{{ 'PAYMENT_PACKAGE.FORM.SORT_BY.AVAILABLE_ORDERS_LABEL' | translate }}"
                              leftButtonIcon="keyboard_arrow_down" buttonType="action" [buttonSize]="32"
                              [menuElements]="availableSorting" [menuType]="menuType.BOTTOM_LEFT"
                              (menuElementSelected)="handleSortSourceMenuElementSelected($event)"></button-with-menu>
        </span>
        <span id="sort-selected-orders-button-container">
            <button-with-menu buttonId="sortSelectedOrdersButton"
                              buttonLabel="{{ 'PAYMENT_PACKAGE.FORM.SORT_BY.SELECTED_ORDERS_LABEL' | translate }}"
                              leftButtonIcon="keyboard_arrow_down" buttonType="action" [buttonSize]="32"
                              [menuElements]="availableSorting" [menuType]="menuType.BOTTOM_LEFT"
                              (menuElementSelected)="handleSortTargetMenuElementSelected($event)"></button-with-menu>
        </span>
    </div>

    <p-pickList id="paymentPackagePickList" [source]="availableOrders" [target]="selectedOrders"
                sourceHeader="{{ 'PAYMENT_PACKAGE.FORM.AVAILABLE_ORDERS' | translate }}"
                targetHeader="{{ 'PAYMENT_PACKAGE.FORM.ADDED_ORDERS' | translate }}" [responsive]="true"
                filterBy="offerNumber,alternateOfferNumber" [sourceStyle]="{'height': '80%'}"
                [targetStyle]="{'height': '80%'}" [showSourceControls]="false" [showTargetControls]="true"
                (onMoveToSource)="handleMoveToSource($event.items)"
                (onMoveAllToSource)="handleMoveToSource($event.items)"
                (onMoveToTarget)="handleMoveToTarget($event.items)"
                (onMoveAllToTarget)="handleMoveToTarget($event.items)"
                (onTargetReorder)="handleTargetReorder()">
        <ng-template let-order pTemplate="item">
            <div class="orders-picklist-row">
                <span class="orders-picklist-column-offer-number">{{ order.offerNumber }}</span>
                <span class="orders-picklist-column-alt-offer-number">{{ order.alternateOfferNumber }}</span>
                <span class="orders-picklist-column-created">{{ order.createdDate | momentCurrentLang | amDateFormat: 'L' }}</span>
            </div>
        </ng-template>
    </p-pickList>

    <div *ngIf="validationErrors['orders'] != undefined" class="new-form-row">
        <div class="new-form-field new-form-field-error new-form-field-error-message">
            {{ validationErrors['orders'] | translate }}
        </div>
    </div>
</div>
