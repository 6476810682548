import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Optional,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {PrimeNGConfig} from "primeng/api";
import {LocaleSettings} from 'primeng/calendar';
import {Subscription} from 'rxjs';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';
import {CalendarLocaleProvider} from './calendar-locale-provider';

export const CALENDAR_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CalendarComponent),
    multi: true
};

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    providers: [CALENDAR_VALUE_ACCESSOR]
})
export class CalendarComponent extends AbstractInputComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    inline = false;

    @Input()
    utc: boolean;

    @Input()
    minDate: Date;

    @Input()
    maxDate: Date;

    @Input()
    required: boolean;

    @Input()
    showTime = false;

    @Output()
    onSelect = new EventEmitter<Date>();

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    dateLocal: Date;
    minDateLocal: Date;
    maxDateLocal: Date;

    private langChangeSubscription: Subscription;
    calendarLocale: LocaleSettings;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                private translate: TranslateService,
                private calendarLocaleProvider: CalendarLocaleProvider,
                private primengConfig: PrimeNGConfig,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
        this.calendarLocale = this.calendarLocaleProvider.getPrimengCalendarSettings(this.translate.currentLang);
        this.primengConfig.setTranslation(this.calendarLocale);
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.langChangeSubscription = this.translate.onLangChange.subscribe(event => {
            this.calendarLocale = this.calendarLocaleProvider.getPrimengCalendarSettings(event.lang);
            this.primengConfig.setTranslation(this.calendarLocale);
            // create new date to format input field value
            if (this.value != undefined) {
                this.value = new Date(this.value.getTime());
            }
            this.changeDetector.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        const minDateChange = changes['minDate'];
        if (minDateChange != undefined) {
            this.minDateLocal = this.utc ? this.convertDateToLocal(minDateChange.currentValue) : minDateChange.currentValue;
        }
        const maxDateChange = changes['maxDate'];
        if (maxDateChange != undefined) {
            this.maxDateLocal = this.utc ? this.convertDateToLocal(maxDateChange.currentValue) : maxDateChange.currentValue;
        }
    }

    ngOnDestroy(): void {
        if (this.langChangeSubscription != undefined) {
            this.langChangeSubscription.unsubscribe();
        }
        super.ngOnDestroy();
    }

    handleChange(localDate: Date): void {
        this.dateLocal = localDate;
        if (this.utc) {
            this.value = this.convertDateToUtc(localDate);
        } else {
            this.value = localDate;
        }
    }

    writeValue(obj: any): void {
        if (obj instanceof Date) {
            this.dateLocal = this.convertDateToLocal(obj);
            super.writeValue(obj);
        } else if (typeof obj === 'string') {
            this.dateLocal = this.convertDateToLocal(new Date(obj));
            super.writeValue(new Date(obj));
        } else {
            this.dateLocal = obj;
            super.writeValue(obj);
        }
    }

    handleOnSelect(date: Date): void {
        this.onSelect.emit(date);
    }

    private convertDateToUtc(localDate: Date): Date {
        if (!this.utc) {
            return localDate;
        }
        if (localDate != undefined) {
            // turn selected year/month/day/hour/minute to date as if we are in UTC zone
            return new Date(localDate.getTime() - localDate.getTimezoneOffset() * 1000 * 60);
        }
        return undefined;
    }

    private convertDateToLocal(utcDate: Date): Date {
        if (!this.utc) {
            return utcDate;
        }
        if (utcDate != undefined) {
            return new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 1000 * 60);
        }
        return undefined;
    }

    handleFocus(event: Event) {
        super.handleFocus(event as FocusEvent);
    }

    handleBlur(event: Event) {
        super.handleBlur(event as FocusEvent);
    }
}
