<p-dataTable #dataTable [value]="tableData.paletteAdditionalElements" [rows]="tableData.rowsPerPage"
             [rowsPerPageOptions]="limitedRowsPerPageOptions" [paginator]="true" selectionMode="single"
             [(selection)]="tableData.selected" [totalRecords]="tableData.totalRecords" [responsive]="true" [lazy]="true"
             (onLazyLoad)="emitLazyLoad($event)" dataKey="id" [rowTrackBy]="rowTrackBy" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate">
    <p-column *ngIf="canEdit" [style]="{'vertical-align':'middle', 'width' : '100px'}">
        <ng-template pTemplate="header">
            <app-tristate-checkbox [ngModel]="tableData.allSelectedState"
                                   (ngModelChange)="selectAllChange($event)"></app-tristate-checkbox>
        </ng-template>
        <ng-template let-position="rowData" pTemplate="body">
            <span class="flex-row">
                <app-checkbox [ngModel]="isSelectedItem(position)"
                              (ngModelChange)="selectItem(position)"></app-checkbox>
            </span>
        </ng-template>
    </p-column>

    <p-column field="description" [style]="{'white-space':'normal'}"
              [header]="'DELIVERY_LIST.PALETTE_ADDITIONAL_ELEMENT_LIST.DESCRIPTION' | translate"
              [sortable]="true" [filter]="isFilterable()">
        <ng-template let-paletteAdditionalElement="rowData" pTemplate="body">
            <div>{{ paletteAdditionalElement.description }}</div>
        </ng-template>
    </p-column>
    <ng-template pTemplate="paginatorLeft">
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: tableData.fromRecord, to: tableData.toRecord, total:tableData.totalRecords} }}</span>
    </ng-template>
</p-dataTable>
