<p-accordion [multiple]="false" #swAcc class="window-designer-tab">
    <!--Main-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.MAIN.SECTION_TITLE' | translate }}" [selected]="true"
                    [id]="SidebarIdGenerator.tabName(null, null)"
                    [ngClass]="getSectionErrorClass(SidebarSection.MAIN)">
        <!--Business type-->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.BUSINESS_TYPE)">
            <div class="input-with-action-container">
                <div class="new-form-field">
                    <label>{{ 'OFFER.TABS.SECTION.MAIN.BUSINESS_TYPE' | translate }}</label>
                    {{ subwindowName }}
                </div>
            </div>
            <div class="new-form-field flex-row">
                <span class="spacer"></span>
                <app-simple-button buttonId="businessTypeChange" [disabled]="readOnlyMode"
                                   label="{{ 'OFFER.TABS.SECTION.MAIN.BUSINESS_TYPE_CHANGE' | translate }}"
                                   [inlineLabel]="false" icon="swap_horiz" type="filter" align="right"
                                   (onClick)="businessTypeChangeClicked()"></app-simple-button>
            </div>
        </div>

        <!--Dimensions-->
        <div class="new-form-field" *ngIf="fieldUsage.show(Field.DIMENSIONS)">
            <label>{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.LABEL_BOTH' | translate }}</label>
            {{ (getSubWindowDimensions(windowData)[0] + "x" + getSubWindowDimensions(windowData)[1]) | convertMillimetersToInches | async }}
        </div>

        <!--Shape type-->
        <div class="new-form-field" *ngIf="fieldUsage.show(Field.SHAPE_TYPE)">
            <label>{{ 'OFFER.TABS.SECTION.MAIN.SHAPE.SECTION_TITLE' | translate }}</label>
            <div *ngIf="fieldUsage.show(Field.SHAPE_TYPE_NOT_RECTANGULAR)">
                {{ 'OFFER.TABS.SECTION.MAIN.SHAPE.NOT_RECTANGULAR' | translate }}
            </div>
            <div *ngIf="fieldUsage.show(Field.SHAPE_TYPE_ARC)">
                {{ 'OFFER.TABS.SECTION.MAIN.SHAPE.ARC' | translate }}
            </div>
        </div>

        <!-- Ventilator -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.VENTILATOR)" id="{{SidebarIdGenerator.ventilationId(windowData)}}">
            <ng-container *vLet="filteredVentilators | async as items">
                <app-select-or-checkbox label="{{ 'OFFER.TABS.SECTION.MAIN.VENTILATOR' | translate }}" [disabled]="readOnlyMode"
                            [options]="items" (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnSubwindowAddonChange($event, Field.VENTILATOR)"
                            [value]="windowData.ventilator.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.VENTILATOR, undefined)"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!-- Drip - okapnik -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.DRIP)">
            <ng-container *vLet="filteredDrips | async as items">
                <app-select-or-checkbox label="{{ 'OFFER.TABS.SECTION.MAIN.DRIP' | translate }}" [disabled]="readOnlyMode"
                            [options]="items" (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnSubwindowAddonChange($event, Field.DRIP)"
                            [value]="windowData.drip.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.DRIP, undefined)"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>

        <!-- Coupler - mufa -->
        <div class="new-form-row" *ngIf="fieldUsage.show(Field.COUPLER)">
            <ng-container *vLet="filteredCouplers | async as items">
                <app-select-or-checkbox label="{{ 'OFFER.TABS.SECTION.MAIN.COUPLER' | translate }}" [disabled]="readOnlyMode"
                            [options]="items" (onFocus)="onSelectFocus($event)"
                            (valueChange)="emitOnSubwindowAddonChange($event, Field.COUPLER)"
                            [value]="windowData.coupler.addonId" [required]="false"
                            [hasValidationError]="fieldUsage.required(Field.COUPLER, undefined)"
                            [allowSelectingNone]="true" placeholder="{{ 'GENERAL.OPTION_NONE' | translate }}">
                    <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                 (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                 [disabled]="disabled"></app-sidebar-field-label>
                    </ng-template>
                </app-select-or-checkbox>
            </ng-container>
        </div>
    </p-accordionTab>

    <!--Mullions-->
    <p-accordionTab #tab
            header="{{ 'OFFER.TABS.SECTION.MULLION.SECTION_TITLE' | translate }}"
            (click)="swAcc.activeIndex = tab.findTabIndex()"
            *ngIf="fieldUsage.showSection(SidebarSection.MULLIONS, null)"
            [id]="SidebarIdGenerator.tabName(null, SidebarIdGenerator.tabNames.mullions)"
            [ngClass]="getSectionErrorClass(SidebarSection.MULLIONS)">
        <div *ngFor="let mullion of windowData.mullions; trackBy: mullionsTrackBy; let mullionIndex = index">
            <div class="new-form-field new-form-field-nohover" [id]="SidebarIdGenerator.mullionId(windowData, mullionIndex)">

                <div class="new-form-row">
                    <app-select label="{{ (mullion.isConstructional ? ('OFFER.TABS.SECTION.MULLION.CONSTRUCTIONAL_MULLION' | translate) :
                                    ('OFFER.TABS.SECTION.MULLION.MULLION' | translate)) + ' (' + (mullionIndex + 1) + ')'}}"
                                [options]="filterUsableMullions(mullion, windowData)" (onFocus)="onSelectFocus($event)"
                                (ngModelChange)="emitOnMullionChange(mullion, $event)"
                                [ngModel]="mullion.id" [required]="false" [disabled]="readOnlyMode"
                                [hasValidationError]="fieldUsage.required(Field.MULLION, null, mullion) ||
                                        (mullion.id | isNotAvailable:(filterUsableMullions(mullion, windowData)
                                            | mapToSelectItems:selectItemFormatters.profileOptionFormatter))"
                                [noLongerAvailable]="mullion.id | isNotAvailable:(filterUsableMullions(mullion, windowData)
                                    | mapToSelectItems:selectItemFormatters.profileOptionFormatter)"
                                [checkAvailability]="true"
                                [optionFormatter]="selectItemFormatters.profileOptionFormatter">
                        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                     [disabled]="mullion.id == undefined"
                                                     (onShowImage)="handleShowImage(imageService.getProfileImage(mullion.id), label)"></app-sidebar-field-label>
                        </ng-template>
                    </app-select>
                </div>

                <app-veneer (veneerEvent)="veneerChangeEvent($event)" [systemId]="mullion.veneer.systemId"
                            [supplierId]="getWindowSystem()?.supplier.id" [mullion]="mullion" [subwindow]="windowData"
                            (onFocus)="onSelectFocus($event)" [windowSystems]="windowSystemsForVeneer"
                            [profileCompositionDistances]="profileCompositionDistances"
                            [readOnlyMode]="readOnlyMode"></app-veneer>
            </div>
        </div>
    </p-accordionTab>

    <p-accordionTab #tab
            *ngFor="let area of windowData.areasSpecification; trackBy: areasTrackBy; let areaNumber = index"
            header="{{ 'OFFER.TABS.SECTION.AREA' | translate:{areaNumber: area.ordinalNumber} }}"
            [id]="SidebarIdGenerator.tabName(areaNumber, null)"
            (click)="swAcc.activeIndex = tab.findTabIndex()"
            [ngClass]="getSectionErrorClass(SidebarSection.AREA, area)">
        <p-accordion [multiple]="false">
            <!--Filling-->
            <p-accordionTab header="{{ 'OFFER.TABS.SECTION.FILLING.SECTION_TITLE' | translate }}"
                            [id]="SidebarIdGenerator.tabName(areaNumber, SidebarIdGenerator.tabNames.filling)"
                            [ngClass]="getSectionErrorClass(SidebarSection.FILLING, area)" [selected]="true">

                <!--Filling type-->
                <div class="new-form-row">
                    <app-select *ngIf="fieldUsage.show(Field.FILLING_TYPE_W_MUNTINS, area)" [disabled]="readOnlyMode"
                                label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_TYPE' | translate }}"
                                [options]="fillingTypesGlassOnly" (onFocus)="onSelectFocus($event)"
                                (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.FILLING_TYPE_W_MUNTINS, area, areaNumber)"
                                [hasValidationError]="fieldUsage.required(Field.FILLING_TYPE_W_MUNTINS, area)"
                                [ngModel]="area.filling.type" [required]="false" [translateLabels]="true"
                                [optionFormatter]="selectItemFormatters.fillingTypeOptionFormatter"></app-select>

                    <app-select *ngIf="fieldUsage.show(Field.FILLING_TYPE_WO_MUNTINS, area)" [disabled]="readOnlyMode"
                                label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_TYPE' | translate }}"
                                [options]="fillingTypes" (onFocus)="onSelectFocus($event)"
                                (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.FILLING_TYPE_WO_MUNTINS, area, areaNumber)"
                                [hasValidationError]="fieldUsage.required(Field.FILLING_TYPE_WO_MUNTINS, area)"
                                [ngModel]="area.filling.type" [required]="false" [translateLabels]="true"
                                [optionFormatter]="selectItemFormatters.fillingTypeOptionFormatter"></app-select>

                    <app-select *ngIf="fieldUsage.show(Field.FILLING_TYPE_WO_DECORATIVE_FILLINGS, area)" [disabled]="readOnlyMode"
                                label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_TYPE' | translate }}"
                                [options]="fillingTypesWithoutDecorativeFilling" (onFocus)="onSelectFocus($event)"
                                (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.FILLING_TYPE_WO_DECORATIVE_FILLINGS, area, areaNumber)"
                                [hasValidationError]="fieldUsage.required(Field.FILLING_TYPE_WO_DECORATIVE_FILLINGS, area)"
                                [ngModel]="area.filling.type" [required]="false" [translateLabels]="true"
                                [optionFormatter]="selectItemFormatters.fillingTypeOptionFormatter"></app-select>
                </div>

                <!--Filling width-->
                <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_WIDTH, area)">
                    <app-select inputId="fillingWidthSelect" [disabled]="readOnlyMode"
                                label="{{ 'OFFER.TABS.SECTION.FILLING.WIDTH' | translate }}"
                                [options]="systemGlazingWidthItems" (onFocus)="onSelectFocus($event)"
                                (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.FILLING_WIDTH, area, areaNumber)"
                                [hasValidationError]="fieldUsage.required(Field.FILLING_WIDTH, area)"
                                [ngModel]="area.filling.width" [required]="false"></app-select>
                </div>

                <!--Filling name-->
                <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_NAME, area)">
                    <!--Filling name - external-->
                    <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FILLING_NAME_EXTERNAL) | async as items">
                        <app-select *ngIf="fieldUsage.show(Field.FILLING_NAME_EXTERNAL, area)" [disabled]="readOnlyMode"
                                    label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_NAME' | translate }}"
                                    [inputId]="Field.FILLING_NAME_EXTERNAL + '_id'"
                                    [options]="items"
                                    (onFocus)="onSelectFocus($event)"
                                    (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.FILLING_NAME_EXTERNAL, area, areaNumber)"
                                    [hasValidationError]="fieldUsage.required(Field.FILLING_NAME_EXTERNAL, area) ||
                                        (area.filling.fillingId | isNotAvailable:items)"
                                    [noLongerAvailable]="area.filling.fillingId | isNotAvailable:items" [checkAvailability]="true"
                                    [ngModel]="area.filling.fillingId" [required]="false"></app-select>
                    </ng-container>

                    <!--Filling name - internal-->
                    <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.FILLING_NAME_INTERNAL) | async as items">
                        <app-select *ngIf="fieldUsage.show(Field.FILLING_NAME_INTERNAL, area)" [disabled]="readOnlyMode"
                                    [inputId]="Field.FILLING_NAME_INTERNAL + '_id'"
                                    label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_NAME' | translate }}"
                                    [options]="items"
                                    (onFocus)="onSelectFocus($event)"
                                    (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.FILLING_NAME_INTERNAL, area, areaNumber)"
                                    [hasValidationError]="fieldUsage.required(Field.FILLING_NAME_INTERNAL, area) ||
                                        (area.filling.fillingId | isNotAvailable:items)"
                                    [noLongerAvailable]="area.filling.fillingId | isNotAvailable:items" [checkAvailability]="true"
                                    [ngModel]="area.filling.fillingId" [required]="false"></app-select>
                    </ng-container>

                    <!--Filling - decorative filling-->
                    <ng-container *ngIf="fieldUsage.show(Field.DECORATIVE_FILLING, area)">
                        <ng-container *vLet="windowEditorFieldContentProvider.getItemsStream(Field.DECORATIVE_FILLING) | async as items">
                            <div class="input-with-action-container">
                                <app-select [disabled]="readOnlyMode"
                                            label="{{ 'OFFER.TABS.SECTION.FILLING.FILLING_NAME' | translate }}"
                                            [options]="items"
                                            (onFocus)="onSelectFocus($event)"
                                            (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.DECORATIVE_FILLING, area, areaNumber)"
                                            [hasValidationError]="fieldUsage.required(Field.DECORATIVE_FILLING, area) ||
                                                (area.filling.decorativeFillingId | isNotAvailable:items)"
                                            [noLongerAvailable]="area.filling.decorativeFillingId | isNotAvailable:items" [checkAvailability]="true"
                                            [ngModel]="area.filling.decorativeFillingId" [required]="false">
                                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                                        <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                                 [disabled]="area.filling.decorativeFillingId == undefined"
                                                                 (onShowImage)="handleShowImage(imageService.getDecorativeFillingImage(area.filling.decorativeFillingId), label)"></app-sidebar-field-label>
                                    </ng-template>
                                </app-select>
                            </div>
                        </ng-container>
                        <div *ngIf="fieldUsage.show(Field.DECORATIVE_FILLING_FLIP, area)" class="new-form-field flex-row input-action-button-container">
                            <app-simple-button buttonId="decorativeFillingFlip" [disabled]="readOnlyMode"
                                               label="{{ 'OFFER.TABS.SECTION.FILLING.FLIP' | translate }}"
                                               [inlineLabel]="false" icon="compare_arrows" type="filter" [size]="40" align="right"
                                               (onClick)="decorativeFillingFlip(area.filling)"></app-simple-button>
                        </div>
                    </ng-container>
                </div>

                <!--Fillings external color-->
                <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_EXTERNAL_COLOR, area)">
                    <div class="input-with-action-container">
                        <app-select label="{{ 'OFFER.TABS.SECTION.FILLING.EXTERNAL_COLOR' | translate }}" [disabled]="readOnlyMode"
                                    [options]="getColorsForFilling(area, FillingColorType.EXTERNAL)" (onFocus)="onSelectFocus($event)"
                                    (ngModelChange)="handleColorSelected($event, Field.FILLING_EXTERNAL_COLOR, area, areaNumber)"
                                    [hasValidationError]="fieldUsage.required(Field.FILLING_EXTERNAL_COLOR, area)"
                                    [ngModel]="area.filling.externalColorId" [required]="false" [allowSelectingNone]="true"
                                    [optionFormatter]="selectItemFormatters.colorWithGroupOptionFormatter"
                                    [optionKey]="selectItemFormatters.colorOptionKey">
                            <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                                <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                         [disabled]="area.filling.externalColorId == undefined"
                                                         (onShowImage)="handleShowImage(imageService.getColorImage(area.filling.externalColorId), label)"></app-sidebar-field-label>
                            </ng-template>
                        </app-select>
                    </div>

                    <div *ngIf="fieldUsage.show(Field.FILLING_INTERNAL_COLOR, area)" class="new-form-field flex-row input-action-button-container">
                        <app-simple-button buttonId="applyFillingExternalToInternalColorButton" [disabled]="readOnlyMode"
                                           label="{{ 'OFFER.TABS.SECTION.MAIN.APPPLY_EXTERNAL_TO_INTERNAL_COLOR' | translate }}"
                                           [inlineLabel]="false" icon="arrow_downward" type="filter" [size]="40" align="right"
                                           (onClick)="applyFillingExternalToInternalColor(area.filling.externalColorId, area, areaNumber)"></app-simple-button>
                    </div>
                </div>

                <!--Fillings internal color-->
                <div class="new-form-row" *ngIf="fieldUsage.show(Field.FILLING_INTERNAL_COLOR, area)">
                    <app-select label="{{ 'OFFER.TABS.SECTION.FILLING.INTERNAL_COLOR' | translate }}" [disabled]="readOnlyMode"
                                [options]="getColorsForFilling(area, FillingColorType.INTERNAL)" (onFocus)="onSelectFocus($event)"
                                (ngModelChange)="handleColorSelected($event, Field.FILLING_INTERNAL_COLOR, area, areaNumber)"
                                [hasValidationError]="fieldUsage.required(Field.FILLING_INTERNAL_COLOR, area)"
                                [ngModel]="area.filling.internalColorId" [required]="false" [allowSelectingNone]="true"
                                [optionFormatter]="selectItemFormatters.colorWithGroupOptionFormatter"
                                [optionKey]="selectItemFormatters.colorOptionKey">
                        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                     [disabled]="area.filling.internalColorId == undefined"
                                                     (onShowImage)="handleShowImage(imageService.getColorImage(area.filling.internalColorId), label)"></app-sidebar-field-label>
                        </ng-template>
                    </app-select>
                </div>

                <!--Glass selector-->
                <div *ngIf="fieldUsage.show(Field.GLASS_SELECTOR, area)">
                    <app-glass-selection [id]="Field.GLASS_SELECTOR + '_id'"
                                         [commonData]="area.glazing" [frames]="frames" [readOnlyMode]="readOnlyMode"
                                         [glasses]="glasses" [decorative]="isDecorativeFillingType(area.filling.type)"
                                         [glazingGlassNumber]="isDecorativeFillingType(area.filling.type) ? availableGlassCountsForDecor : availableGlassCounts"
                                         [validateInstantly]="validateAll" [(validationErrors)]="glazingValidationErrors"
                                         [glazingWidths]="getGlazingWidths(area)"
                                         [showImagePreviewButtons]="true" [checkAvailability]="true"
                                         (frameChangeEmitter)="emitFrameChanged(area)"
                                         (focus)="onSelectFocus($event)"
                                         (glassQuantityEmitter)="onGlassQnChange(area, $event)"
                                         (glassChangeEmitter)="onGlassChange(area, $event)"
                                         (beforeGeneralGlassUpdate)="emitOnGeneralSubWindowValueChange($event, Field.GLASS_SELECTOR, area, areaNumber)"
                                         (onShowGlassImage)="handleShowImage(imageService.getGlassImage($event.id), $event.header)">
                    </app-glass-selection>
                </div>

                <!--Glazing bead-->
                <div class="new-form-row" id="{{ 'glazing-bead-field-container-' + area.ordinalNumber }}"  *ngIf="fieldUsage.show(Field.GLAZING_BEAD)">
                    <app-select inputId="glazingBeadSelect" label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_BEAD' | translate }}"
                                [options]="filteredGlazingBeads[area.generatedId] || []" (onFocus)="onSelectFocus($event)"
                                (ngModelChange)="emitOnGeneralSubWindowValueChange($event, Field.GLAZING_BEAD, area, areaNumber)"
                                [ngModel]="area.glazingBead.id" [required]="false" [disabled]="readOnlyMode"
                                [hasValidationError]="fieldUsage.required(Field.GLAZING_BEAD, area) ||
                                    (area.glazingBead.id | isNotAvailable:(filteredGlazingBeads[area.generatedId]
                                        | mapToSelectItems:selectItemFormatters.glazingBeadOptionFormatter))"
                                [noLongerAvailable]="area.glazingBead.id | isNotAvailable:(filteredGlazingBeads[area.generatedId]
                                    | mapToSelectItems:selectItemFormatters.glazingBeadOptionFormatter)"
                                [checkAvailability]="true"
                                [optionFormatter]="selectItemFormatters.glazingBeadOptionFormatter">
                        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                     [disabled]="area.glazingBead.id == undefined"
                                                     (onShowImage)="handleShowImage(imageService.getGlazingBeadImage(area.glazingBead.id), label)"></app-sidebar-field-label>
                        </ng-template>
                    </app-select>
                </div>

                <app-predefined-glazing-selection
                    *ngIf="fieldUsage.show(Field.PREDEFINED_GLAZING_PACKAGE, area)"
                    [readOnlyMode]="readOnlyMode"
                    (selectFocus)="onSelectFocus($event)"
                    [quantityOptions]="windowEditorFieldContentProvider.getItemsStream(Field.GLAZING_PACKAGE_QUANTITY) | async"
                    [selectedQuantity]="area.glazing.glazingGlassQuantity"
                    [quantityNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE_QUANTITY)"
                    [quantityHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE_QUANTITY, area, null)"
                    (quantityChanged)="emitOnGeneralSubWindowValueChange($event, Field.GLAZING_PACKAGE_QUANTITY, area, areaNumber)"
                    (quantityClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE_QUANTITY)"
                    [categoryOptions]="windowEditorFieldContentProvider.getItemsStream(windowEditorFieldContentProvider.getGlazingPackageCategoryFieldId(area.glazing.glazingGlassQuantity)) | async"
                    [glazingCategoryId]="area.glazingCategoryId"
                    [categoryNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE_CATEGORY)"
                    [categoryHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE_CATEGORY, area, null)"
                    (categoryChanged)="emitOnGeneralSubWindowValueChange($event, Field.GLAZING_PACKAGE_CATEGORY, area, areaNumber)"
                    (categoryClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE_CATEGORY)"
                    [frameCategoryOptions]="windowEditorFieldContentProvider.getItemsStream(windowEditorFieldContentProvider.getGlazingPackageFrameCategoryFieldId(area.glazing.glazingGlassQuantity)) | async"
                    [glazingFrameCategoryId]="area.glazingFrameCategoryId"
                    [frameCategoryNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE_FRAME_CATEGORY)"
                    [frameCategoryHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE_FRAME_CATEGORY, area, null)"
                    (frameCategoryChanged)="emitOnGeneralSubWindowValueChange($event, Field.GLAZING_PACKAGE_FRAME_CATEGORY, area, areaNumber)"
                    (frameCategoryClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE_FRAME_CATEGORY)"
                    [glazingPackageOptions]="windowEditorFieldContentProvider.getItemsStream(windowEditorFieldContentProvider.getGlazingPackagesFieldId(area.glazing.glazingGlassQuantity, area.glazingCategoryId, area.glazingFrameCategoryId)) | async"
                    [glazingPackageId]="area.glazingPackageId"
                    [glazingPackageNewOptions]="windowEditorFieldContentProvider.hasNewItems(Field.GLAZING_PACKAGE)"
                    [glazingPackageHasValidationErrors]="fieldUsage.required(Field.GLAZING_PACKAGE, area, null)"
                    (glazingPackageChanged)="emitOnGeneralSubWindowValueChange($event, Field.GLAZING_PACKAGE, area, areaNumber)"
                    (glazingPackageClicked)="windowEditorFieldContentProvider.clearHasNewItemsMarker(Field.GLAZING_PACKAGE)">
                </app-predefined-glazing-selection>
            </p-accordionTab>

            <!--Grills-->
            <p-accordionTab
                    header="{{ 'OFFER.TABS.SECTION.GRILL.SECTION_TITLE' | translate }}"
                    *ngIf="fieldUsage.showSection(SidebarSection.MUNTINS, area)"
                    [id]="SidebarIdGenerator.tabName(areaNumber, SidebarIdGenerator.tabNames.muntins)"
                    [ngClass]="getSectionErrorClass(SidebarSection.MUNTINS, area)">

                <div *ngFor="let grill of area.grills; let currentGrillNumber = index">
                    <div class="new-form-field new-form-field-nohover"
                         id="{{ SidebarIdGenerator.muntinId(tabIndex, areaNumber, currentGrillNumber)}}">

                        <div class="new-form-row">
                            <app-select label="{{ getGrillType(grill)[1] ? (('OFFER.TABS.SECTION.GRILL.TYPE.'
                                            + getGrillType(grill)[0] | translate) + ' ' + getGrillType(grill)[1]
                                            + ' (' + getMuntinTypeBasedNumber(areaNumber, currentGrillNumber) + ')') :
                                            ('OFFER.TABS.SECTION.GRILL.TYPE.' + getGrillType(grill)[0] | translate)
                                            + ' (' + getMuntinTypeBasedNumber(areaNumber, currentGrillNumber) + ')' }}"
                                        [options]="getAllowedGrills(grill, area)" (onFocus)="onSelectFocus($event)"
                                        [translateLabels]="true" [checkAvailability]="true"
                                        (ngModelChange)="emitOnGrillChange(grill, $event)"
                                        [hasValidationError]="fieldUsage.required(Field.GRILL, area, grill) ||
                                            (grill.id | isNotAvailable:(getAllowedGrills(grill, area) | mapToSelectItems:selectItemFormatters.grillOptionFormatter):true)"
                                        [noLongerAvailable]="grill.id | isNotAvailable:(getAllowedGrills(grill, area) | mapToSelectItems:selectItemFormatters.grillOptionFormatter):true"
                                        [ngModel]="grill.id" [required]="false" [disabled]="readOnlyMode"
                                        [optionFormatter]="selectItemFormatters.grillOptionFormatter">
                                <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                                    <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                             [disabled]="grill.id == undefined"
                                                             (onShowImage)="handleShowImage(imageService.getGrillImage(grill.id), label)"></app-sidebar-field-label>
                                </ng-template>
                            </app-select>
                        </div>

                        <div class="new-form-row">
                            <app-select label="{{ ('OFFER.TABS.SECTION.GRILL.GRILL_COLOR' | translate) + ' - '
                                            + (getGrillType(grill)[1] ? (('OFFER.TABS.SECTION.GRILL.TYPE.'
                                            + getGrillType(grill)[0] | translate) + ' ' + getGrillType(grill)[1]
                                            + ' (' + getMuntinTypeBasedNumber(areaNumber, currentGrillNumber) + ')') :
                                            ('OFFER.TABS.SECTION.GRILL.TYPE.' + getGrillType(grill)[0] | translate)
                                            + ' (' + getMuntinTypeBasedNumber(areaNumber, currentGrillNumber) + ')') }}"
                                        [options]="getGrillColors(grill)" (onFocus)="onSelectFocus($event)"
                                        [translateLabels]="true"
                                        (ngModelChange)="handleGrillColorSelected(grill, $event)"
                                        [hasValidationError]="fieldUsage.required(Field.GRILL_COLOR, area, grill)"
                                        [ngModel]="grill.colorId" [required]="false" [disabled]="readOnlyMode"
                                        [optionFormatter]="selectItemFormatters.colorWithGroupOptionFormatter"
                                        [optionKey]="selectItemFormatters.colorOptionKey">
                                <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                                    <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                             [disabled]="grill.colorId == undefined"
                                                             (onShowImage)="handleShowImage(imageService.getColorImage(grill.colorId), label)"></app-sidebar-field-label>
                                </ng-template>
                            </app-select>
                        </div>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </p-accordionTab>
</p-accordion>
<app-ral-color-selection-dialog *ngIf="selectingRalColors != undefined" [header]="selectingRalColorHeader | translate"
                                [colors]="selectingRalColors" (onColorSelect)="handleGrillRalColorSelected($event)"
                                (onCancel)="handleGrillRalColorSelectionCancel()"></app-ral-color-selection-dialog>
