import {HttpClient} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AddonInterface} from '../../../../window-designer/catalog-data/addon-interface';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper, FileState} from "../../../common/dataServiceHelper";
import {SystemType} from "../../../common/enums/SystemType";
import {MotlawaIntegrationInfo} from '../../../common/MotlawaIntegrationInfo';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ListOfIds} from '../../ListOfIds';
import {ProfitMarginExistance} from "../../offer/window-editor/drawing-tool/ProfitMarginExistance";
import {AddonCategory} from "../addon-category/addon-category";
import {Addon, AddonCategoryCount} from "./addon";

@Injectable()
export class AddonsService {

    constructor(public http: HttpClient, public dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Addon>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<Addon>>('addons', {params: params});
    }

    getItem(itemId: number, forOffer: boolean, offerId?: number, offerPositionId?: number,
            includeInvalidColors?: boolean): Observable<Addon> {
        let params: any = {
            forOffer: `${forOffer}`
        };
        if (offerId != null) {
            params.offerId = `${offerId}`;
        }
        if (offerPositionId != null) {
            params.offerPositionId = `${offerPositionId}`;
        }
        if (includeInvalidColors != undefined) {
            params.includeInvalidColors = `${includeInvalidColors}`;
        }
        return this.http.get<Addon>(`addons/${itemId}`, {
            params: params
        });
    }

    saveItem(addon: Addon, file: File, tile: FileState, additionalIcon: File, motlawaInfo: MotlawaIntegrationInfo): Observable<number> {
        let body = JSON.stringify(addon);
        let formData = new FormData();
        formData.append("addonDto", new Blob([body], {
            type: "application/json"
        }));

        if (file != undefined) {
            formData.append("file", file);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(tile)) {
            formData.append('tile', tile.file);
        }
        if (additionalIcon != undefined) {
            formData.append("additionalIcon", additionalIcon);
        }
        if (motlawaInfo != undefined) {
            formData.append('MotlawaIntegrationInfo', new Blob([JSON.stringify(motlawaInfo)], {type: 'application/json'}));
        }
        return this.http.post('addons', formData, {observe: 'response'}).pipe(
            map(response => addon.id || this.dataServiceHelper.getNewItemId('addons', response)));
    }

    getImageForItem(addonId: number): Observable<string> {
        return this.http.get(`addons/${addonId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageForItemAsFile(addonId: number): Observable<File> {
        return this.http.get(`addons/${addonId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(
            this.dataServiceHelper.mapToFile()
        );
    }

    getTileForItemAsFile(addonId: number): Observable<File> {
        return this.http.get(`addons/${addonId}/tile`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(
            this.dataServiceHelper.mapToFile()
        );
    }

    getAdditionalIconAsFile(addonId: number): Observable<File> {
        return this.http.get(`addons/${addonId}/additionalIcon`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(
            this.dataServiceHelper.mapToFile()
        );
    }

    copyItem(id: number, addon: Addon, file: File, tile: FileState, additionalIcon: File, motlawaInfo: MotlawaIntegrationInfo): Observable<number> {
        let body = JSON.stringify(addon);
        let formData = new FormData();
        formData.append("addonDto", new Blob([body], {
            type: "application/json"
        }));

        if (file != undefined) {
            formData.append("file", file);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(tile)) {
            formData.append('tile', tile.file);
        }
        if (additionalIcon != undefined) {
            formData.append("additionalIcon", additionalIcon);
        }
        if (motlawaInfo != undefined) {
            formData.append('MotlawaIntegrationInfo', new Blob([JSON.stringify(motlawaInfo)], {type: 'application/json'}));
        }
        return this.http.post(`addons/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId('addons', response)));
    }

    getCategories(systemId: number, systemType: SystemType): Observable<string[]> {
        return this.http.get<string[]>(`addons/categories/${systemId}`, {
            params: {
                systemType: `${systemType}`
            }
        });
    }

    getCustomCategories(systemId: number): Observable<AddonCategory[]> {
        return this.http.get<AddonCategory[]>(`addons/custom-categories/${systemId}`);
    }

    getBulkCategories(supplierId: number[], includeGeneralAddons: boolean, includeSubsystemAddons: boolean): Observable<string[]> {
        const params  = {
            includeGeneralAddons: `${includeGeneralAddons}`,
            includeSubsystemAddons: `${includeSubsystemAddons}`
        };
        if (supplierId.length > 0) {
            params['supplierId'] = supplierId.join();
        }
        return this.http.get<string[]>('addons/bulkcategories', {params: params});
    }

    getItemsByIds(addonIds: number[], withColors: boolean): Observable<AddonInterface[]> {
        const params = {
            ids: addonIds.join(','),
            withColors: `${withColors}`
        };
        return this.http.get<AddonInterface[]>('addons/added', {params: params});
    }

    validateMarginExistance(addonId: number, offerId: number): Observable<ProfitMarginExistance> {
        return this.http.get<ProfitMarginExistance>(`addons/${addonId}/profitMarginExists/${offerId}`);
    }

    validate(addon: Addon): Observable<{ [field: string]: string }> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('addons/validate', addon));
    }

    getLinkedSystems(addonId: number): Observable<number[]> {
        return this.http.get<number[]>(`addons/linked/${addonId}`);
    }

    editLinks(addonId: number, systemIds: ListOfIds): Observable<number> {
        return this.http.put<void>(`addons/link/${addonId}`, systemIds).pipe(map(() => 0));
    }

    getMotlawaInfo(addonId: number): Observable<MotlawaIntegrationInfo> {
        return this.http.get<MotlawaIntegrationInfo>(`addons/${addonId}/motlawaInfo`);
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('addons/names', {params: params});
    }

    editGateSystemLinks(addonId: number, gateSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`addons/linkGates/${addonId}`, gateSystemIds);
    }

    editConfigSystemLinks(addonId: number, configSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`addons/linkConfigs/${addonId}`, configSystemIds);
    }

    getLinkedGateSystems(addonId: number): Observable<number[]> {
        return this.http.get<number[]>(`addons/linkedGates/${addonId}`);
    }

    countByCategories(filters: { [filterProperty: string]: FilterMetadata }): Observable<AddonCategoryCount[]> {
        let params = this.dataServiceHelper.prepareSearchParams(undefined, undefined, filters, undefined, undefined);
        return this.http.get<AddonCategoryCount[]>('addons/countByCategories', {params: params});
    }

    getLinkedConfigSystems(addonId: number): Observable<number[]> {
        return this.http.get<number[]>(`addons/linkedConfigs/${addonId}`);
    }
}
