import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import * as _ from 'underscore';

@Component({
    selector: 'app-hotkeys-help',
    templateUrl: './hotkeys-help.component.html',
    styleUrls: ['./hotkeys-help.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [trigger('fadeIn', [
        state('false', style({
            'opacity': 0,
            'visibility': 'hidden',
            'z-index': -1024
        })),
        state('true', style({
            'opacity': 1,
            'visibility': 'visible',
            'z-index': 10002
        })),
        transition('false => true', animate('0.15s linear'))
    ])]
})
export class HotkeysHelpComponent implements OnInit, OnDestroy {

    helpVisible = false;
    hotkeys: Hotkey[][];

    private openHotkey: Hotkey;
    private closeHotkey: Hotkey;

    constructor(private hotkeysService: HotkeysService, private changeDetector: ChangeDetectorRef) {
        this.openHotkey = new Hotkey('?', () => {
            if (!this.helpVisible) {
                this.openHelp();
            } else {
                this.closeHelp();
            }
            return false;
        });
        this.closeHotkey = new Hotkey('esc', () => {
            this.closeHelp();
            return false;
        });
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.openHotkey);
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.openHotkey);
        this.hotkeysService.remove(this.closeHotkey);
    }

    private openHelp(): void {
        this.hotkeys = [];
        const hotkeysWithDescription = this.hotkeysService.hotkeys.filter(hotkey => hotkey.description);
        const hotkeysPartitioned = _.partition(hotkeysWithDescription, (item, index, array) => index < array.length / 2);
        for (let i = 0; i < hotkeysWithDescription.length / 2; ++i) {
            this.hotkeys.push([hotkeysPartitioned[0][i], hotkeysPartitioned[1][i]]);
        }
        this.hotkeysService.pause();
        this.hotkeysService.add(this.openHotkey);
        this.hotkeysService.add(this.closeHotkey);
        this.helpVisible = true;
        this.changeDetector.markForCheck();
    }

    private closeHelp(): void {
        this.helpVisible = false;
        this.hotkeysService.remove(this.openHotkey);
        this.hotkeysService.remove(this.closeHotkey);
        this.hotkeysService.unpause();
        this.changeDetector.markForCheck();
    }

    getHotkeyDescription(hotkey: Hotkey): string {
        if (hotkey.description == undefined) {
            return undefined;
        }
        if (typeof hotkey.description === 'string') {
            return hotkey.description;
        }
        return hotkey.description();
    }
}
