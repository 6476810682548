<div class="new-form-row" *ngIf="glazingGlassNumber.length > 1 || modelMode || sidebarOnlyMode">
    <app-select inputId="glazingGlassQuantitySelect" [disabled]="readOnlyMode"
                label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_GLASS_QN' | translate }}"
                [(validationMessageKey)]="validationErrors['glazingGlassQuantity']" (onFocus)="focus.emit($event)"
                [options]="glazingGlassNumber" [optionFormatter]="glazingGlassNumberOptionFormatter"
                [ngModel]="commonData.glazingGlassQuantity" [allowSelectingNone]="sidebarOnlyMode"
                (ngModelChange)="changeGlassQuantity($event); executeInstantValidation()"></app-select>
</div>
<div class="new-form-row">
    <div class="new-form-field">
        <div class="new-form-field-label-container">
            <div>
                <label>{{ 'OFFER.TABS.SECTION.FILLING.WIDTH' | translate }}</label>
            </div>
        </div>
        <div class="new-form-field-input-container">
            {{ currentGlazingWidth | formatGlazingWidths:glazingWidths }}
        </div>
    </div>
</div>
<ng-container *ngFor="let glassNumber of commonData.glazingGlassQuantity | range">
    <!--Glass type-->
    <div class="new-form-row">
        <app-select [inputId]="'glass' + (glassNumber + 1) + 'Select'" [disabled]="readOnlyMode || modelMode || sidebarOnlyMode"
                    label="{{ FieldType.GLASS | glassSelectionFieldLabel:commonData.glazingGlassQuantity:glassNumber | translate: { glassNumber: glassNumber } }}"
                    [(validationMessageKey)]="validationErrors['glass' + (glassNumber + 1) + 'id']" [allowSelectingNone]="sidebarOnlyMode"
                    (onFocus)="focus.emit($event)" [options]="glassesByNumber[glassNumber]"
                    [ngModel]="commonData['glass' + (glassNumber + 1) + 'id']" [checkAvailability]="checkAvailability"
                    [noLongerAvailable]="checkAvailability ? (commonData['glass' + (glassNumber + 1) + 'id'] | isNotAvailable:glassesByNumber[glassNumber]:true) : false"
                    (ngModelChange)="onGlassChange(glassNumber + 1, $event); executeInstantValidation()">
            <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                <app-sidebar-field-label *ngIf="showImagePreviewButtons" [inputId]="inputId" [label]="label"
                                         [disabled]="commonData['glass' + (glassNumber + 1) + 'id'] == undefined"
                                         (onShowImage)="handleShowImage(commonData['glass' + (glassNumber + 1) + 'id'], label)"></app-sidebar-field-label>
                <div *ngIf="!showImagePreviewButtons" class="new-form-field-label-container">
                    <div><label [attr.for]="inputId">{{ label }}</label></div>
                    <div *ngIf="validationErrors['glass' + (glassNumber + 1) + 'id'] != undefined"
                         class="new-form-field-label-error-spacer"></div>
                </div>
            </ng-template>
        </app-select>
    </div>

    <!--Frame type-->
    <div class="new-form-row" *ngIf="glassNumber + 1 !== commonData.glazingGlassQuantity">
        <app-select [inputId]="'frame' + (glassNumber + 1) + 'Select'" [disabled]="readOnlyMode || modelMode || sidebarOnlyMode"
                    label="{{ FieldType.FRAME | glassSelectionFieldLabel:commonData.glazingGlassQuantity | translate:{at: glassNumber + 1, by: glassNumber + 2} }}"
                    [allowSelectingNone]="sidebarOnlyMode"
                    [(validationMessageKey)]="validationErrors['frame' + (glassNumber + 1) + 'id']"
                    (onFocus)="focus.emit($event)" [options]="framesByNumber[glassNumber]"
                    [ngModel]="commonData['frame' + (glassNumber + 1) + 'id']" [checkAvailability]="checkAvailability"
                    [noLongerAvailable]="checkAvailability ? (commonData['frame' + (glassNumber + 1) + 'id'] | isNotAvailable:framesByNumber[glassNumber]:true) : false"
                    (ngModelChange)="onFrameChange(glassNumber + 1, $event); executeInstantValidation()"></app-select>
    </div>
</ng-container>
