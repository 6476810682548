import {Address} from '../../common/address';
import {JsonDeserializable} from '../../common/crud-common/crudItem';
import {Country} from '../../common/enums/country';

@JsonDeserializable
export class TabularAddress {
    id: number;
    tempId?: number;
    address: Address;
    removable: boolean;

    constructor(id?: number, street?: string, city?: string, zip?: string, country?: Country) {
        this.id = id;
        this.address = new Address();
        this.address.street = street;
        this.address.city = city;
        this.address.zip = zip;
        this.address.country = country;
        this.removable = true;
    }

    static fromJSON(jsonObject: any): TabularAddress {
        const tabularAddress = new TabularAddress();
        tabularAddress.id = jsonObject.id;
        tabularAddress.address = Address.fromJSON(jsonObject.address);
        tabularAddress.removable = jsonObject.removable;
        return tabularAddress;
    }
}
