import {ErrorResponse, PricingFailedField} from '../../../../../errors/errorResponse';
import {ConfigAddonApplication} from '../../../../../../../window-designer/enums/ConfigAddonApplication';
import {MessageSeverity} from "../../../message";

export class GlobalConfigAddonAddResult {
    errorCodes: string[];
    messages: PricingFailedField[];
    windowPositionId: number;
    application: ConfigAddonApplication;
    generatedId: string;

    static isValid(result: GlobalConfigAddonAddResult) {
        let hasErrors = result.errorCodes != null && result.errorCodes.length > 0;
        let hasErrorMessages = result.messages != null && Object.keys(result.messages).length > 0
            && Object.keys(result.messages).some(key => key === MessageSeverity.ERROR || key === MessageSeverity.BLOCKER);
        return !hasErrors && !hasErrorMessages;
    }
}

export class GlobalConfigAddonAddResultList {
    data: GlobalConfigAddonAddResult[];
    totalRecords: number;
}
