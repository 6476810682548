import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    DoCheck,
    EventEmitter,
    Input,
    KeyValueChangeRecord,
    KeyValueDiffer,
    KeyValueDiffers,
    OnChanges,
    Output,
    SimpleChanges
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import * as _ from 'underscore';
import {DropDownExtraOptions} from "../../../shared/drop-down-extra-options";
import {Glazing} from "../../../window-designer/catalog-data/glazing";
import {WindowCommonData} from "../../../window-designer/window-common-data";
import {WindowDesignerComponent} from "../../features/offer/window-editor/window-designer/window-designer.component";
import {DistanceFrame} from "../../features/window-system/distance-frame/distanceFrame";
import {GlassChangeEvent} from "../../features/window-system/glass/glassChangeEvent";
import {GlassWithPosition} from "../../features/window-system/glass/glassWithPositions";
import {SelectItemExtended} from "../../form-inputs/inputs/select/select.component";
import {SelectItemImpl} from '../service/select.item.impl';
import {ValidationErrors} from '../validation-errors';
import {GlassSelectionFieldType} from "./glass-selection-field-label.pipe";
import {GlassSelectionValidator} from "./GlassSelectionValidator";

class GlassPreviewData {
    constructor(public id: number, public header: string) {
    }
}

@Component({
    selector: 'app-glass-selection',
    templateUrl: './glass-selection.component.html',
    styleUrls: ['./glass-selection.component.css', '../../features/shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlassSelectionComponent implements OnChanges, DoCheck {

    @Input()
    glasses: GlassWithPosition[];

    @Input()
    frames: DistanceFrame[];

    @Input()
    commonData: Glazing = new Glazing();

    @Input()
    glazingGlassNumber = [1, 2, 3, 4];

    @Input()
    validateInstantly = false;

    @Input()
    validationErrors: { [field: string]: string } = {};

    @Input()
    glazingWidths: string;

    @Input()
    readOnlyMode: boolean;

    @Input()
    modelMode = false;

    @Input()
    sidebarOnlyMode = false;

    @Input()
    showImagePreviewButtons = false;

    @Input()
    decorative = false;

    @Input()
    catalogAdd = false;

    @Input()
    checkAvailability = false;

    @Input()
    boldGlasses: GlassWithPosition[];

    @Input()
    boldFrames: DistanceFrame[];

    @Output()
    glassChangeEmitter = new EventEmitter<GlassChangeEvent>();

    @Output()
    glassQuantityEmitter = new EventEmitter<number>();

    @Output()
    focus = new EventEmitter<FocusEvent>();

    @Output()
    beforeGeneralGlassUpdate = new EventEmitter();

    @Output()
    frameChangeEmitter = new EventEmitter<GlassChangeEvent>();

    @Output()
    validationErrorsChange = new EventEmitter<ValidationErrors>();

    @Output()
    onShowGlassImage = new EventEmitter<GlassPreviewData>();

    glazingGlassNumberOptionFormatter: (quantity: number) => SelectItemExtended;
    frameOptionFormatter: (frame: DistanceFrame) => SelectItemExtended;

    glassesByNumber: SelectItemExtended[][] = [];
    framesByNumber: SelectItemExtended[][] = [];

    currentGlazingWidth: number;
    FieldType = GlassSelectionFieldType;

    private readonly commonDataDiffer: KeyValueDiffer<string, any>;

    constructor(public translate: TranslateService,
                public changeDetector: ChangeDetectorRef,
                differs: KeyValueDiffers) {
        this.commonData = new WindowCommonData();
        this.commonDataDiffer = differs.find(this.commonData).create();
        this.glazingGlassNumberOptionFormatter = (quantity: number) => new SelectItemImpl('' + quantity, quantity);
        this.frameOptionFormatter = (frame: DistanceFrame) => {
            return {
                label: frame.names[this.translate.currentLang],
                value: frame.id,
                available: frame.active,
                bold: this.boldFrames != undefined && this.boldFrames.findIndex(bf => bf.id === frame.id) >= 0
            };
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('glasses' in changes) {
            if (changes['glasses'].currentValue != undefined) {
                for (let i = 0; i < this.commonData.glazingGlassQuantity; ++i) {
                    this.glassesByNumber[i] = this.getGlassesForPosition(changes['glasses'].currentValue, i + 1);
                }
            }
        }
        if ('frames' in changes) {
            if (changes['frames'].currentValue != undefined) {
                for (let i = 0; i < this.commonData.glazingGlassQuantity - 1; ++i) {
                    this.framesByNumber[i] = this.getFramesForPosition(changes['frames'].currentValue, i + 1);
                }
            }
        }
        if ('glazingGlassNumber' in changes) {
            if (changes['glazingGlassNumber'].currentValue != undefined) {
                let glazingGlassNumber = changes['glazingGlassNumber'].currentValue;
                if (this.commonData != undefined && glazingGlassNumber.length === 1 && !this.modelMode) {
                    this.commonData.glazingGlassQuantity = glazingGlassNumber[0];
                }
            }
        }
        if ('validateInstantly' in changes) {
            if (changes['validateInstantly'].currentValue) {
                this.validateForm();
            }
        }
        if ('commonData' in changes) {
            if (this.validateInstantly) {
                this.validateForm();
            }
        }
        if ('glazingWidths' in changes) {
            this.rebuildFramesByPosition();
        }
    }

    ngDoCheck(): void {
        if (this.commonDataDiffer != undefined) {
            let changes = this.commonDataDiffer.diff(this.commonData);
            if (changes) {
                const changedKeys: string[] = [];
                const changeCallback = (r: KeyValueChangeRecord<string, any>) => {
                    changedKeys.push(r.key);
                    if (r.key === 'glazingGlassQuantity') {
                        this.rebuildGlassesByPosition();
                        this.rebuildFramesByPosition();
                    }
                };
                changes.forEachRemovedItem(changeCallback);
                changes.forEachAddedItem(changeCallback);
                changes.forEachChangedItem(changeCallback);
                if (_.intersection(changedKeys, GlassSelectionValidator.VALIDATED_KEYS).length > 0) {
                    this.currentGlazingWidth = this.calculateGlazingWidth();
                    if (this.validateInstantly) {
                        this.validateForm();
                    }
                }
                this.changeDetector.markForCheck();
            }
        }
    }

    private rebuildGlassesByPosition(): void {
        for (let i = 0; i < this.commonData.glazingGlassQuantity; ++i) {
            this.glassesByNumber[i] = this.getGlassesForPosition(this.glasses, i + 1);
        }
        this.changeDetector.markForCheck();
    }

    private getGlassesForPosition(glasses: GlassWithPosition[], position: number): SelectItemExtended[] {
        let matchingGlasses: SelectItemExtended[] = [];
        let selectedGlassCount = this.commonData.glazingGlassQuantity;

        let canBeAtPosition = (glass, of) => {
            return glass['position' + position + 'of' + of];
        };

        if (glasses != undefined) {
            matchingGlasses = glasses
                .filter(g => g.allPositions || canBeAtPosition(g, selectedGlassCount))
                .map(g => {
                    return {
                        label: g.name[this.translate.currentLang],
                        value: g.id,
                        available: g.active,
                        bold: this.boldGlasses != undefined && this.boldGlasses.findIndex(bg => bg.id === g.id) >= 0
                    };
                });

            if (this.boldGlasses != undefined) {
                const firstBold = matchingGlasses.findIndex(si => si.bold);
                if (firstBold >= 0) {
                    matchingGlasses.unshift({
                        label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_ALL'),
                        value: DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE,
                        bold: true,
                        center: true,
                        disabled: true
                    });
                }
                const firstNonBold = matchingGlasses.findIndex(si => !si.bold);
                if (firstNonBold >= 0) {
                    matchingGlasses.splice(firstNonBold, 0, {
                        label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_SOME'),
                        value: DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE,
                        bold: true,
                        center: true,
                        disabled: true
                    });
                }
            }
        }

        return matchingGlasses;
    }

    private rebuildFramesByPosition(except?: number): void {
        for (let i = 0; i < this.commonData.glazingGlassQuantity - 1; ++i) {
            if (i + 1 === except) {
                continue;
            }
            this.framesByNumber[i] = this.getFramesForPosition(this.frames, i + 1);
        }
        this.changeDetector.markForCheck();
    }

    private getFramesForPosition(frames: DistanceFrame[], position: number): SelectItemExtended[] {
        let filteredFrames = frames;
        if (this.glazingWidths) {
            const selectedGlassCount = this.commonData.glazingGlassQuantity;
            filteredFrames = frames.filter(frame => {
                return frame.positions.findIndex(p => p.position === position && p.glassesInGlazingCount === selectedGlassCount) !== -1;
            });
        }
        const mappedFrames = filteredFrames.map(this.frameOptionFormatter);
        if (this.boldFrames != undefined) {
            const firstBold = mappedFrames.findIndex(si => si.bold);
            if (firstBold >= 0) {
                mappedFrames.unshift({
                    label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_ALL'),
                    value: DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE,
                    bold: true,
                    center: true,
                    disabled: true
                });
            }
            const firstNonBold = mappedFrames.findIndex(si => !si.bold);
            if (firstNonBold >= 0) {
                mappedFrames.splice(firstNonBold, 0, {
                    label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_SOME'),
                    value: DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE,
                    bold: true,
                    center: true,
                    disabled: true
                });
            }
        }

        return mappedFrames;
    }

    private calculateGlazingWidth(): number {
        let width = 0;
        for (let i = 1; i <= this.commonData.glazingGlassQuantity; ++i) {
            const glassId = this.commonData[`glass${i}id`];
            const glass = this.glasses.find(g => g.id === glassId);
            if (glass != undefined) {
                width += glass.thickness;
            }
        }
        for (let i = 1; i < this.commonData.glazingGlassQuantity; ++i) {
            const frameId = this.commonData[`frame${i}id`];
            const frame = this.frames.find(g => g.id === frameId);
            if (frame != undefined) {
                width += frame.thickness;
            }
        }
        return width;
    }

    changeGlassQuantity(newGlassQuantity: number) {
        this.clearFields();
        this.beforeGeneralGlassUpdate.emit();
        this.glassQuantityEmitter.emit(newGlassQuantity);
        this.rebuildGlassesByPosition();
        this.rebuildFramesByPosition();
        this.changeDetector.markForCheck();
    }

    clearFields(): void {
        for (let i = 1; i <= WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX; i++) {
            this.commonData['glass' + i + 'id'] = undefined;
            if (i !== WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX) {
                this.commonData['frame' + i + 'id'] = undefined;
            }
        }
        this.clearValidationErrors();
    }

    validateForm(): void {
        this.validationErrorsChange.emit(new GlassSelectionValidator().validate(this.commonData));
    }

    emitFrameChange(frameNumber: number, newFrameId: number) {
        this.frameChangeEmitter.emit(new GlassChangeEvent(frameNumber, newFrameId, null));
    }

    emitGlassChanged(newGlassId: number, glassNumber: number): void {
        this.glassChangeEmitter.emit(new GlassChangeEvent(glassNumber, newGlassId, null));
    }

    onGlassChange(glassNumber: number, newGlassId: number): void {
        this.beforeGeneralGlassUpdate.emit();
        this.commonData['glass' + glassNumber + 'id'] = newGlassId;
        this.validationErrors['glass' + glassNumber + 'id'] = undefined;
        this.currentGlazingWidth = this.calculateGlazingWidth();
        this.rebuildFramesByPosition();
        this.emitGlassChanged(newGlassId, glassNumber);
    }

    onFrameChange(frameNumber: number, newFrameId: number): void {
        this.beforeGeneralGlassUpdate.emit();
        this.commonData['frame' + frameNumber + 'id'] = newFrameId;
        this.validationErrors['frame' + frameNumber + 'id'] = undefined;
        this.currentGlazingWidth = this.calculateGlazingWidth();
        this.rebuildFramesByPosition(frameNumber);
        this.emitFrameChange(frameNumber, newFrameId);
    }

    public clearValidationErrors(): void {
        Object.keys(this.validationErrors).forEach(field => this.validationErrors[field] = undefined);
    }

    executeInstantValidation(): void {
        if (!this.validateInstantly) {
            return;
        }
        this.validateForm();
    }

    handleShowImage(glassId: number, header: string): void {
        this.onShowGlassImage.emit(new GlassPreviewData(glassId, header));
    }
}
