<div class="new-form-600">

    <div class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'CATALOG_OPTION.FORM.SYMBOL' | translate }}"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="100"
                        [required]="true" [(ngModel)]="item.symbol">
        </app-input-text>
    </div>

    <div class="new-form-row">
        <app-select inputId="target" label="{{ 'CATALOG_OPTION.FORM.TARGET' | translate }}"
                    [(validationMessageKey)]="validationErrors['target']"
                    [options]="availableTargets | async" [required]="false" [(ngModel)]="item.target"></app-select>
    </div>

    <div class="new-form-row" *ngFor="let lang of supportedLanguages">
        <app-text-area inputId="{{'value_' + lang.code}}"
                       label="{{ 'CATALOG_OPTION.FORM.VALUE' | translate: {lang: lang.code} }}"
                       [(validationMessageKey)]="validationErrors['value']" [maxlength]="2000"
                       [required]="false" [(ngModel)]="item.value[lang.code]"></app-text-area>
    </div>

    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'CATALOG_OPTION.FORM.ACTIVE' | translate }}"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="item.id != null" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'CATALOG_OPTION.FORM.SORT_INDEX' | translate }}"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab header="{{ 'CATALOG_OPTION.FORM.IMAGE_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['icon']}">
                <app-file-upload inputId="icon" [ngModel]="file"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="10000" [maxWidth]="100" [maxHeight]="100"
                                 [addButtonLabel]="'CATALOG_OPTION.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'CATALOG_OPTION.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'CATALOG_OPTION.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['icon']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>

    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': validationErrors['amazonS3File']}" [selected]="false"
                            header="{{ 'CATALOG_OPTION.FORM.STORAGE_FILES' | translate }}">

                <div *ngIf="canUploadToAmazon" class="new-form-row">
                    <app-file-upload (ngModelChange)="handleUploadedFileChange($event)"
                                     [(validationMessageKey)]="validationErrors['amazonS3File']"
                                     [maxHeight]="100000" [maxSize]="10485760" [maxWidth]="100000"
                                     [ngModel]="uploadedFile"
                                     [image]="false" accept="*.*"
                                     addButtonLabel="{{ 'CATALOG_OPTION.FORM.SELECT_AMAZON_FILE_UPLOAD' | translate }}"
                                     changeButtonLabel="{{ 'CATALOG_OPTION.FORM.SELECT_AMAZON_FILE_UPLOAD' | translate }}"
                                     deleteButtonLabel="{{ 'CATALOG_OPTION.FORM.DELETE_AMAZON_FILE_UPLOAD' | translate }}"
                                     inputId="amazon-storage-file-upload-input"
                                     label="{{ 'CATALOG_OPTION.FORM.UPLOAD_AMAZON_FILE' | translate }}">
                    </app-file-upload>
                </div>
                <div class="new-form-row">
                    <div>
                        <h3>{{ 'CATALOG_OPTION.FORM.CHOOSE_FILE_ON_AMAZON' | translate }}</h3>
                        <p-listbox (ngModelChange)="handleUnusedFileSelectionChange($event)" [ngModel]="selectedFile"
                                   [options]="amazonS3Files"></p-listbox>
                    </div>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
