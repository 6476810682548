import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from "../message-service";
import {DialogType} from "../position/position-list/position-list-dialogs";
import {OfferPositionMessagesContainer, PositionMessage} from "../message";
import {OnceFlag} from "../../../../shared/once-flag";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {PricingMessageComponent} from "../../../errors/pricing-message/pricing-message.component";
import {MenuItem} from "primeng/api/menuitem";

@Component({
    selector: 'app-messages-dialog',
    templateUrl: './messages-dialog.component.html',
    styleUrls: ['./messages-dialog.component.css', '../../../shared-styles.css'],
    providers: [MessageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MessagesDialogComponent implements OnInit {

    @Input()
    displayedDialog: DialogType;

    @Input()
    entityId: number;

    @Output()
    readonly onClose = new EventEmitter<void>();

    dialogType = DialogType;
    dataInitialized = false;
    userLang: string;
    messagesTabMenuItems: MenuItem[] = [];
    activePositionMessagesItem: MenuItem;
    activePositionMessageItemIndex = '0';
    messages: PositionMessage[];
    positionMessages: OfferPositionMessagesContainer[];

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private messageService: MessageService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
        this.userLang = this.translate.currentLang;
    }

    ngOnInit(): void {
        this.loadMessages();
    }

    lookupRowStyleClass(rowData: PositionMessage): string {
        return rowData.severity;
    }

    private loadMessages(): void {
        if (this.displayedDialog === DialogType.OFFER_MESSAGES) {
            this.messageService.getMessagesForOffer(this.entityId).subscribe({
                next: data => {
                    this.messages = data.offerMessages;
                    this.positionMessages = data.positionMessages;
                    this.getPositionMessagesItems(data.positionMessages);
                    this.onDataLoaded();
                },
                error: error => this.errors.handle(error)
            });
        } else if (this.displayedDialog === DialogType.POSITION_MESSAGES) {
            this.messageService.getMessagesForPosition(this.entityId).subscribe({
                next: data => {
                    this.messages = data;
                    this.onDataLoaded();
                },
                error: error => this.errors.handle(error)
            });
        }
    }

    private onDataLoaded() {
        this.translateErrorCodeParams();
        this.dataInitialized = true;
        this.changeDetector.markForCheck();
    }

    private getPositionMessagesItems(positionMessages: OfferPositionMessagesContainer[]) {
        this.messagesTabMenuItems = [];
        for (let i = 0; i < positionMessages.length; i++) {
            this.messagesTabMenuItems.push({
                label: positionMessages[i].name[this.userLang],
                icon: '',
                command: () => {
                    this.activePositionMessageItemIndex = '' + i;
                }
            });
        }
        this.activePositionMessagesItem = this.messagesTabMenuItems[0];
    }

    private translateErrorCodeParams() {
        this.messages.forEach(message => {
            this.translateParams(message);
        });
        if (this.displayedDialog === DialogType.OFFER_MESSAGES) {
            this.positionMessages.forEach(container => {
                container.messages.forEach(message => {
                    this.translateParams(message);
                });
            });
        }
    }

    private translateParams(message: PositionMessage) {
        if (message && message.messageCodeParams) {
            let values = Object.keys(message.messageCodeParams).map(key => message.messageCodeParams[key]);

            if (values.length > 0) {
                let translatedValues = this.translate.instant(values);

                Object.keys(translatedValues).forEach(translatedKey => {
                    let originalKey = this.getKeyByValue(message.messageCodeParams, translatedKey);
                    message.messageCodeParams[originalKey] = translatedValues[translatedKey];
                });
            }
        }
    }

    private getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    close(): void {
        this.activePositionMessageItemIndex = '0';
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    getFieldName(message: PositionMessage): string {
        return PricingMessageComponent.getFieldNameTranslationsCode(message.fieldName);
    }
}
