import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    Optional,
    Renderer2,
    SimpleChange,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputComponent, FormHandler} from '../../form-inputs/inputs/abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../../form-inputs/inputs/form-handler-token';
import {Country} from '../enums/country';
import {ZipCodeValidator} from '../zip-code-validator';

export const INPUT_ZIP_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputZipComponent),
    multi: true
};

@Component({
    selector: 'app-input-zip',
    templateUrl: './input-zip.component.html',
    styleUrls: ['./input-zip.component.css'],
    providers: [INPUT_ZIP_VALUE_ACCESSOR]
})
export class InputZipComponent extends AbstractInputComponent implements OnChanges {

    @Input()
    country: Country;

    @Input()
    required: boolean;

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    mask: string;
    maxlength: number;

    private validator = new ZipCodeValidator();
    private skipNextValueChange = false; // workaround value being initialized before country

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        if ('country' in changes) {
            const country: SimpleChange = changes['country'];
            const format = this.validator.getFormat(country.currentValue);
            if (format != undefined) {
                this.mask = format.inputMask;
                this.maxlength = format.maxlength;
            } else {
                this.initDefault();
            }

            if (country.currentValue != undefined) {
                this.skipNextValueChange = true;
            }
        }
    }

    handleValueChange(value: string): void {
        if (!this.skipNextValueChange && !this.disabled) {
            this.value = value;
        }
        this.skipNextValueChange = false;
    }

    private initDefault(): void {
        this.mask = '';
        this.maxlength = 10;
    }

    handleFocus(event: Event) {
        super.handleFocus(event as FocusEvent);
    }

    handleBlur(event: Event) {
        super.handleBlur(event as FocusEvent);
    }
}
