import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {forkJoin} from 'rxjs';
import {finalize, map, mergeMap, tap} from 'rxjs/operators';
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {CurrentUserService} from '../../../auth/current-user.service';
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardDialogComponent} from '../../../form-inputs/wizard/wizard-dialog.component';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {SimpleProfitMarginSource, validateSimpleProfitMargins} from '../../client/simple-profit-margin-source/simple-profit-margin-source';
import {HasSaveTable} from '../../subsystem/base-profit-margin.component';
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from '../../subsystem/profit-margin/profitMargin';
import {SupplierService} from '../../supplier/supplier.service';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {ClientGroupService} from '../client-group.service';
import {ClientGroup} from '../ClientGroup';
import {ProfitMarginTarget} from "../../subsystem/profit-margin/profit-margin-target";

@Component({
    selector: 'app-edit-client-group',
    templateUrl: './edit-client-group.component.html',
    providers: [ClientGroupService, DataServiceHelper, SupplierService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditClientGroupComponent implements OnInit, OnDestroy {

    private static readonly SUBMIT_BLOCK_ID = 'EditClientGroupComponent submit';

    readonly WindowSystemTypeGroup = ProductTypeGroup;

    readonly STEPS = {
        GENERAL: 'general',
        SYSTEM_MARGINS: 'profit-margins-system',
        ROOF_SYSTEM_MARGINS: 'profit-margins-roof-system',
        ENTRANCE_SYSTEM_MARGINS: 'profit-margins-entrance-system',
        CONFIG_ADDON_MARGINS: 'profit-margins-config-addon',
        GATES_MARGINS: 'profit-margins-gates'
    };

    readonly profitGlobalMarginButtonId = 'profitGlobalMarginButtonId';
    readonly profitGlobalMarginInputId = 'profitGlobalMarginInputId';
    readonly roofProfitGlobalMarginButtonId = 'roofProfitGlobalMarginButtonId';
    readonly roofProfitGlobalMarginInputId = 'roofProfitGlobalMarginInputId';
    readonly entranceProfitGlobalMarginButtonId = 'entranceProfitGlobalMarginButtonId';
    readonly entranceProfitGlobalMarginInputId = 'entranceProfitGlobalMarginInputId';
    readonly clientGroupConfAddGlobalMarginButtonId = 'clientGroupConfAddGlobalMarginButtonId';
    readonly clientGroupConfAddGlobalMarginInputId = 'clientGroupConfAddGlobalMarginInputId';
    readonly gatesGlobalMarginButtonId = 'gatesGlobalMarginButtonId';
    readonly gatesGlobalMarginInputId = 'gatesGlobalMarginInputId';

    readonly route: ActivatedRoute;
    possibleTargets = ProfitMarginTarget;

    @ViewChildren('childTable')
    childTables: QueryList<HasSaveTable>;

    @ViewChild('wizard', {static: true}) wizard: WizardDialogComponent;

    @Input() clientGroup: ClientGroup;

    protected enterHotkey: Hotkey;
    validationErrors = {};
    displayYesNoDialog = false;
    suppliers: SupplierInterface[];
    simpleProfitMarginSource?: SimpleProfitMarginSource;
    showClearingSimpleProfitMarginsWarning = false;

    @Output() onSave = new EventEmitter();
    @Output() onCancel = new EventEmitter();
    @Output() onDelete = new EventEmitter();

    generalStepValidator: WizardStepValidator;
    initialDataLoaded = false;
    saveInProgress = false;
    canDeleteGroup: boolean;

    constructor(public translate: TranslateService,
                public clientGroupService: ClientGroupService,
                public permissions: Permissions,
                public hotkeysService: HotkeysService,
                public changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                public blockUiController: BlockUiController,
                private supplierService: SupplierService,
                private currentUserService: CurrentUserService,
                injector: Injector) {
        this.route = injector.get(ActivatedRoute);
        this.enterHotkey = new Hotkey('enter', (event): boolean => {
            if (event.target && (event.target as HTMLInputElement).parentElement
                && (event.target as HTMLInputElement).parentElement.className.includes('p-datatable-cell-editor')) {
                return false;
            }
            this.submit();
            return false;
        }, ['INPUT']);
        this.generalStepValidator = () => {
            const validationErrors = {};
            if (!this.clientGroup.name) {
                validationErrors['name'] = 'error.clientGroupDto.name.not_empty';
            }
            if (!!this.clientGroup.bulkAddonProfitMargin
                && !isValidProfitMarginValueString(normalizeProfitMarginValueString(this.clientGroup.bulkAddonProfitMargin))) {
                validationErrors['bulkAddonProfitMargin'] = 'PROFIT_MARGIN.ERROR.INVALID_VALUE';
            }
            validationErrors['defaultOfferDurationDays'] = MultiValidator.of('error.clientGroupDto.defaultOfferDurationDays')
                .withRangeValidator(1, 31).validate(this.clientGroup.defaultOfferDurationDays);
            if (this.simpleProfitMarginSource != undefined) {
                Object.assign(validationErrors, validateSimpleProfitMargins(this.simpleProfitMarginSource, this.suppliers));
            }
            if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
                this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                return false;
            }
            return this.clientGroupService.validateDetails(this.clientGroup).pipe(
                tap(backendValidationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
        };
    }

    ngOnInit() {
        this.canDeleteGroup = this.permissions.canDeleteClientgroup() && (!this.permissions.isHandlowiec() || this.isGroupOwner());
        this.hotkeysService.add(this.enterHotkey);
        forkJoin({
            suppliers: this.supplierService.getSuppliersForSimpleProfitMargins(),
            simpleProfitMarginSource: this.clientGroupService.getSimpleProfitMarginSource(this.clientGroup.id)
        }).subscribe(data => {
            this.suppliers = data.suppliers;
            this.simpleProfitMarginSource = data.simpleProfitMarginSource;
            this.initialDataLoaded = true;
            this.changeDetector.markForCheck();
            setTimeout(() => this.wizard.centerDialog());
        });
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.enterHotkey);
    }

    submit() {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.blockUiController.block(EditClientGroupComponent.SUBMIT_BLOCK_ID);
        if (!!this.clientGroup.bulkAddonProfitMargin) {
            this.clientGroup.bulkAddonProfitMargin = normalizeProfitMarginValueString(this.clientGroup.bulkAddonProfitMargin);
        }
        this.clientGroupService.edit(this.clientGroup.id, this.clientGroup)
            .pipe(mergeMap(() => forkJoin([...this.childTables.map(table => table.saveTable(this.clientGroup.id)),
                this.clientGroupService.saveSimpleProfitMarginSource(this.clientGroup.id, this.simpleProfitMarginSource)
            ])), finalize(() => this.blockUiController.unblock(EditClientGroupComponent.SUBMIT_BLOCK_ID)))
            .subscribe({
                next: () => {
                    this.saveInProgress = false;
                    this.onSave.emit();
                },
                error: error => {
                    this.saveInProgress = false;
                    this.validationErrors = this.errors.handle(error);
                }
            });
    }

    cancel() {
        this.onCancel.emit();
    }

    showYesNoDialog(show: boolean) {
        if (this.displayYesNoDialog !== show) {
            this.displayYesNoDialog = show;
            this.changeDetector.markForCheck();
        }
    }

    button_delete() {
        this.showYesNoDialog(true);
    }

    delete(): void {
        this.clientGroupService.delete(this.clientGroup)
            .subscribe({
                next: () => {
                    this.showYesNoDialog(false);
                    this.onDelete.emit(true);
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
    }

    getDialogHeader(): string {
        if (this.canEdit()) {
            return 'CLIENT-GROUP.FORM.EDIT-TITLE';
        }
        return 'CLIENT-GROUP.FORM.VIEW-TITLE';
    }

    getDialogStyle(): string {
        if (this.canEdit()) {
            return 'width-100percent-no-padding height-60vw-no-padding';
        }
        return '';
    }

    canEdit(): boolean {
        return this.initialDataLoaded && (this.clientGroup.id != undefined
            ? (this.permissions.canEditClientgroup() && this.permissions.isHandlowiec() ? this.isGroupOwner() : true)
            : this.permissions.canCreateClientgroup());
    }

    handleEnableSimpleProfitMarginSourceChange(enable: boolean): void {
        if (enable) {
            this.simpleProfitMarginSource = new SimpleProfitMarginSource();
        } else {
            this.simpleProfitMarginSource = undefined;
            Object.keys(this.validationErrors)
                .filter(key => key.startsWith('simpleProfitMarginSource'))
                .forEach(key => this.validationErrors[key] = undefined);
            this.changeDetector.markForCheck();
        }
    }

    handleEnableSimpleProfitMarginSourceBeforeChange(event: MouseEvent): void {
        event.preventDefault();
        this.showClearingSimpleProfitMarginsWarning = true;
    }

    handleClearingSimpleProfitMarginsWarningConfirm(): void {
        this.handleEnableSimpleProfitMarginSourceChange(this.simpleProfitMarginSource == undefined);
        this.showClearingSimpleProfitMarginsWarning = false;
    }

    private isGroupOwner() {
        return this.currentUserService.currentUserId === this.clientGroup.ownerId;
    }
}
