import * as moment from "moment";

export enum DateRangeKind {
    INPUT_SINGLE = 'INPUT_SINGLE',
    INPUT_RANGE = 'INPUT_RANGE',
    CURRENT_WEEK = 'CURRENT_WEEK',
    CURRENT_MONTH = 'CURRENT_MONTH',
    CURRENT_YEAR = 'CURRENT_YEAR',
    PAST_7_DAYS = 'PAST_7_DAYS',
    PAST_30_DAYS = 'PAST_30_DAYS',
    PAST_YEAR = 'PAST_YEAR',
    SINCE_TODAY = 'SINCE_TODAY'
}

// Excluding dashboard reports-only:
export const DateRangeKindBasic = [
    DateRangeKind.INPUT_SINGLE,
    DateRangeKind.INPUT_RANGE,
    DateRangeKind.CURRENT_WEEK,
    DateRangeKind.CURRENT_MONTH,
    DateRangeKind.CURRENT_YEAR,
    DateRangeKind.SINCE_TODAY,
];

export class DateRangeFilter {

    static readonly DATE_FORMAT = 'YYYY-MM-DD';

    constructor(public readonly from: string,
                public readonly to: string,
                public readonly kind: DateRangeKind) {
    }

    static getForDates(from: Date, to: Date, kind: DateRangeKind): DateRangeFilter {
        let fromString;
        let toString;
        if (from != null) {
            fromString = moment(from).format(DateRangeFilter.DATE_FORMAT);
        }
        if (to != null) {
            toString = moment(to).format(DateRangeFilter.DATE_FORMAT);
        }
        return new DateRangeFilter(fromString, toString, kind);
    }
}
