import {JsonDeserializable} from "../../../common/crud-common/crudItem";
import {CatalogElement} from "./catalog-element.enum";
import {FieldLimitation} from "./field-limitation";

@JsonDeserializable
export class EditCatalogPermits {
    id: number;
    roleId: number;
    catalogElement: CatalogElement;
    fieldsLimitations: FieldLimitation[];

    static fromJSON(jsonObject: any): EditCatalogPermits {
        const permits = new EditCatalogPermits();
        permits.id = jsonObject['id'];
        permits.roleId = jsonObject['roleId'];
        permits.catalogElement = jsonObject['catalogElement'];
        permits.fieldsLimitations = jsonObject['fieldsLimitations'];
        return permits;
    }
}
