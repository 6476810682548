import {MultilanguageField} from "../../../supportedLanguages";

export enum MessageSeverity {
    BLOCKER = 'BLOCKER',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
    INFO = 'INFO',
    CODE = 'CODE'
}

export class PositionMessage {
    messageCode: string;
    messageCodeParams: { [key: string]: string; };
    severity: MessageSeverity;
    fieldName: string;
    validationMessage: boolean;
    createdByRule: string;

    static equals(that: PositionMessage, other: PositionMessage): boolean {
        if (that.severity !== other.severity) {
            return false;
        }
        if (that.fieldName !== other.fieldName) {
            return false;
        }
        if (that.messageCodeParams) {
            for (let paramName of Object.keys(that.messageCodeParams)) {
                if (!other.messageCodeParams || !other.messageCodeParams.hasOwnProperty(paramName)) {
                    return false;
                }
                if (that.messageCodeParams[paramName] !== other.messageCodeParams[paramName]) {
                    return false;
                }
            }
        }
        return that.messageCode === other.messageCode;
    }
}

export class OfferMessagesContainer {
    offerMessages: PositionMessage[];
    positionMessages: OfferPositionMessagesContainer[];
}

export class OfferPositionMessagesContainer {
    name: MultilanguageField;
    messages: PositionMessage[];
}
