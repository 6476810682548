import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import * as moment from "moment";
import {OfferStatus} from "../../../../common/enums/OfferStatus";
import {ValidationErrors} from '../../../../common/validation-errors';
import {Subsystem, TransportType} from '../../../subsystem/subsystem';
import {SubsystemService} from '../../../subsystem/subsystem.service';
import {PaymentPackage} from '../payment-package';

@Component({
    selector: 'app-payment-package-form',
    templateUrl: './payment-package-form.component.html',
    providers: [SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentPackageFormComponent implements OnInit {

    @Input()
    paymentPackage: PaymentPackage;

    @Input()
    validationErrors: ValidationErrors = {};

    selectedSubsystem: Subsystem;
    euTransportValue: number;
    nonEuTransportValue: number;
    readonly today: Date = moment().startOf('day').toDate();

    constructor(private subsystemService: SubsystemService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.loadSubsystem();
    }

    loadSubsystem(): void {
        if (this.paymentPackage.subsystemId != undefined) {
            this.subsystemService.getItem(this.paymentPackage.subsystemId).subscribe(subsystem => {
                this.selectedSubsystem = subsystem;
                if (this.isSplitTransport(subsystem)) {
                    this.calculateSplitTransportValues(subsystem);
                }
                this.changeDetector.markForCheck();
            });
        }
    }

    get isPaid(): boolean {
        return this.paymentPackage.status === OfferStatus.PAID;
    }

    get showSplitTransportValues(): boolean {
        return this.selectedSubsystem && this.isSplitTransport(this.selectedSubsystem);
    }

    handleTransportValueChange(transportValue: number): void {
        this.paymentPackage.transportValue = transportValue;
        if (this.showSplitTransportValues) {
            this.calculateSplitTransportValues(this.selectedSubsystem);
        }
    }

    private isSplitTransport(subsystem: Subsystem): boolean {
        return subsystem.transportType === TransportType.SPLITTED;
    }

    private calculateSplitTransportValues(subsystem: Subsystem): void {
        const transportValue = this.paymentPackage.transportValue || 0;
        this.euTransportValue = subsystem.euTransportPercent * transportValue / 100;
        this.nonEuTransportValue = subsystem.nonEuTransportPercent * transportValue / 100;
    }
}
