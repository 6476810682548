import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {PositionType} from '../../../../AbstractPosition';
import {AddonProperties} from '../../window-properties/addon-properties';
import {AssemblyProperties} from '../../window-properties/assembly-properties';
import {GateProperties} from '../../window-properties/gate-properties';
import {TransportProperties} from '../../window-properties/transport-properties';
import {WindowProperties} from '../../window-properties/window-properties';
import {ConfigProperties} from "../../window-properties/config-properties";

@Component({
    selector: 'app-compare-positions-dialog',
    templateUrl: './compare-positions-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComparePositionsComponent {

    @Input() properties: WindowProperties[] | GateProperties[] | ConfigProperties[] | AddonProperties[] | AssemblyProperties[] | TransportProperties[];
    @Input() positionType: PositionType;
    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    close() {
        this.onClose.emit();
    }
}
