import {ChangeDetectorRef, Directive, DoCheck, ElementRef, Input} from '@angular/core';
import * as moment from 'moment';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {DateRangeFilter, DateRangeKind, DateRangeKindBasic} from '../date-range-filter';
import {TranslatedSelectItemService} from '../service/translated-select-item.service';

@Directive()
export abstract class AbstractDateRangeFilterComponent implements DoCheck {

    from: Date;

    to: Date;

    kind = DateRangeKind.INPUT_RANGE;

    @Input()
    dateRangeKinds: Observable<SelectItem[]>;

    private lastFilterValue: DateRangeFilter;

    protected constructor(private translatedSelectItemService: TranslatedSelectItemService,
                          private changeDetector: ChangeDetectorRef) {
        this.dateRangeKinds = this.translatedSelectItemService.buildUnsortedDropdown(DateRangeKindBasic, 'GENERAL.DATE_RANGE_KIND.',
            undefined);
    }

    ngDoCheck(): void {
        const filter = this.filter;
        const filterValue = filter != undefined ?
            (Array.isArray(filter) ? filter[0].value : filter.value)
            : undefined;
        if (filterValue !== this.lastFilterValue) {
            if (filterValue != undefined) {
                this.from = filterValue.from != undefined ? moment.utc(filterValue.from, DateRangeFilter.DATE_FORMAT).toDate() : undefined;
                this.to = filterValue.to != undefined ? moment.utc(filterValue.to, DateRangeFilter.DATE_FORMAT).toDate() : undefined;
                this.kind = filterValue.kind != undefined ? DateRangeKind[filterValue.kind as string] : undefined;
            } else {
                this.from = undefined;
                this.to = undefined;
                this.kind = DateRangeKind.INPUT_RANGE;
            }
            this.lastFilterValue = filterValue;
            this.changeDetector.markForCheck();
        }
    }

    abstract get hostElement(): ElementRef;

    abstract get filter(): FilterMetadata | FilterMetadata[];

    abstract doFilter(filter: DateRangeFilter): void;

    displayCalendars(): boolean {
        return this.kind === DateRangeKind.INPUT_SINGLE || this.kind === DateRangeKind.INPUT_RANGE;
    }

    displayTo(): boolean {
        return this.kind === DateRangeKind.INPUT_RANGE;
    }

    handleKindChange(kind: DateRangeKind) {
        this.kind = kind;
        this.updateFilter();
    }

    handleFromChange(from: Date) {
        this.from = from;
        this.updateFilter();
    }

    handleToChange(to: Date) {
        this.to = to;
        this.updateFilter();
    }

    private updateFilter(): void {
        const filterValue = this.createFilterValue();
        if (this.lastFilterValue !== filterValue) {
            this.doFilter(filterValue);
            this.changeDetector.markForCheck();
        }
        this.lastFilterValue = filterValue;
    }

    private createFilterValue(): DateRangeFilter {
        if (this.kind == undefined) {
            return undefined;
        }
        if (this.kind === DateRangeKind.INPUT_SINGLE && this.from == undefined) {
            return undefined;
        }
        if (this.kind === DateRangeKind.INPUT_RANGE && this.from == undefined && this.to == undefined) {
            return undefined;
        }
        return new DateRangeFilter(
            this.from != undefined ? moment(this.from).format(DateRangeFilter.DATE_FORMAT) : undefined,
            this.to != undefined ? moment(this.to).format(DateRangeFilter.DATE_FORMAT) : undefined,
            this.kind);
    }

    bypassColumnClick(event: MouseEvent): boolean {
        if (this.hostElement == undefined) {
            return false;
        }
        // dispatch a copy of the event originating from datatable instead of column to avoid triggering sorting in column
        // calendar needs it to close when clicked outside
        const eventCopy = new MouseEvent(event.type, {
            bubbles: event.bubbles,
            cancelable: event.cancelable,
            composed: event.composed,
            view: event.view,
            detail: event.detail,
            screenX: event.screenX,
            screenY: event.screenY,
            clientX: event.clientX,
            clientY: event.clientY,
            movementX: event.movementX,
            movementY: event.movementY,
            ctrlKey: event.ctrlKey,
            altKey: event.altKey,
            shiftKey: event.shiftKey,
            metaKey: event.metaKey,
            button: event.button,
            buttons: event.buttons,
            relatedTarget: event.relatedTarget
        });
        this.hostElement.nativeElement.dispatchEvent(eventCopy);
        event.stopPropagation();
        return true;
    }
}
