import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Accordion} from 'primeng/accordion';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DropDownExtraOptions} from '../../../../../../shared/drop-down-extra-options';
import {ColorInterface} from '../../../../../../window-designer/catalog-data/color-interface';
import {DrawingData} from "../../../../../../window-designer/drawing-data/drawing-data";
import {FillingType} from "../../../../../../window-designer/drawing-data/FillingType";
import {WindowAddon} from '../../../../../../window-designer/drawing-data/WindowAddon';
import {MinMaxXY} from '../../../../../../window-designer/drawing-util';
import {MaxWindowDimensions} from "../../../../../../window-designer/max-window-dimensions";
import {ProfilesCompositionDistances} from "../../../../../../window-designer/profiles-composition-distances";
import {GrillHelper} from "../../../../../../window-designer/utils/grill-helper";
import {WindowCommonData} from "../../../../../../window-designer/window-common-data";
import {ColorType} from '../../../../../ColorType';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../../common/crud-common/common-input-length-limits';
import {FocusOnElement} from "../../../../../common/FocusOnElement";
import {GlassSelectionComponent} from '../../../../../common/glass-selection/glass-selection.component';
import {TranslatedSelectItemService} from '../../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {
    SelectInvalidValueCorrectedEvent,
    SelectInvalidValueCorrectionReason
} from '../../../../../form-inputs/inputs/select/select.component';
import {MultilanguageField} from '../../../../../supportedLanguages';
import {Color} from "../../../../window-system/color/color";
import {DistanceFrame} from "../../../../window-system/distance-frame/distanceFrame";
import {GlassChangeEvent} from "../../../../window-system/glass/glassChangeEvent";
import {GlassWithPosition} from "../../../../window-system/glass/glassWithPositions";
import {GlazingBead} from "../../../../window-system/glazing-bead/glazing-bead";
import {GlazingPackage} from '../../../../window-system/glazing-package/glazing-package';
import {WebshopGlazingPackage} from '../../../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package';
import {
    DataModificationTarget, isDataModificationMode, isUpsellingMode, WebshopCharge
} from '../../../../window-system/webshop-charge/WebshopCharge';
import {WindowSystemDefinition} from '../../../../window-system/window-system-definition/window-system-definition';
import {DecorativeFillingWithColors} from '../../DecorativeFillingWithColors';
import {GeneralTabFieldUsage, SidebarSection} from "../../fieldUsage";
import {GeneralTabHaveErrors} from "../../GeneralTabHaveErrors";
import {OtherFillingWithColors} from "../../OtherFillingWithColors";
import {SidebarHelper} from "../../sidebar-helper";
import {WindowComponentPreviewData} from '../../window-component-preview-dialog/window-component-preview-dialog.component';
import {WindowEditorField} from '../../window-editor-field';
import {WindowEditorFieldContentProvider} from '../../window-editor-field-content-provider';
import {WindowEditorPositionData} from '../../window-editor-offer-interfaces';
import {WindowEditorWindowSystemInterface} from '../../window-editor-window-system-interface';
import {FillingColorType} from "../FillingColorType";
import {SelectItemFormatters} from "../select-item-formatters";
import {SidebarFieldImageService} from '../sidebar-field-image.service';
import {GraspGlazingPackage} from "../../../../window-system/grasp-glazing-package/grasp-glazing-package";

export enum SizeProp {
    TOTAL_WIDTH = 'totalWidth',
    TOTAL_HEIGHT = 'totalHeight'
}

@Component({
    selector: 'app-sidebar-general',
    templateUrl: './general-tab.component.html',
    styleUrls: ['./general-tab.component.css', '../sidebar.component.css', '../../window-editor.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneralTabComponent implements AfterViewInit, OnChanges {

    public static readonly TOTAL_WIDTH_INPUT_ID = WindowEditorField.WIDTH + '_id';
    public static readonly TOTAL_HEIGHT_INPUT_ID = WindowEditorField.HEIGHT + '_id';
    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    @ViewChild('mainAcc', {static: true}) mainAcc: Accordion;
    @ViewChild('glassSelection') glassSelection: GlassSelectionComponent;

    @Input() offerPosition: WindowEditorPositionData;
    @Input() drawingData: DrawingData;
    @Input() filteredWindowSystems: WindowEditorWindowSystemInterface[];
    @Input() selectedWindowSystem: WindowSystemDefinition;
    @Input() sidebarOnlyMode: boolean;
    @Input() modelMode: boolean;
    @Input() webshopChargeMode: DataModificationTarget | undefined;
    @Input() readOnlyMode: boolean;
    @Input() totalBoundingBox: MinMaxXY;
    @Input() profileCompositionDistances: ProfilesCompositionDistances;
    totalWidth: number;
    totalHeight: number;
    @Input() commonData: WindowCommonData;
    @Input() chosenCoreColor: Color;
    @Input() colors: Color[];
    @Input() intersectingGrillColors: Color[];
    @Input() intersectingGrillRalColors: Color[];
    @Input() intersectingGrillNcsColors: Color[];
    @Input() systemRalExternalColors: Color[];
    @Input() systemRalInternalColors: Color[];
    @Input() systemRalBothSidesColors: Color[];
    @Input() systemNcsExternalColors: Color[];
    @Input() systemNcsInternalColors: Color[];
    @Input() systemNcsBothSidesColors: Color[];
    @Input() filteredGlazingBeads: GlazingBead[];
    @Input() fillingTypes: FillingType[];
    @Input() fillingTypesGlassOnly: FillingType[];
    @Input() fillingTypesWithoutDecorativeFilling: FillingType[];
    @Input() glazingGlassNumber: number[];
    @Input() allFillings: OtherFillingWithColors[];
    @Input() systemDecorativeFillings: DecorativeFillingWithColors[];
    @Input() frames: DistanceFrame[];
    @Input() availableGlassCounts: number[];
    @Input() glasses: GlassWithPosition[];
    @Input() windowSystemDefaultsLevel: string;
    @Input() windowSystemDefaultsLevels: SelectItem[];
    @Input() windowSystemDefaultsOverrideLowerLevel: boolean;
    @Input() windowSystemDefaultsValidation: { [field: string]: string };
    @Input() offerContainsGrills: boolean;
    @Input() grillColors: { [grillId: number]: Color[] } = {};
    @Input() systemGlazingWidthItems: SelectItem[] = [];
    @Input() selectedWebshopGlazingPackageId: number;
    @Input() editModelMode: boolean;
    @Input() availableWebshopGlazingPackages: WebshopGlazingPackage[];
    @Input() availableTerraceGlazingPackage: GlazingPackage[];
    @Input() webshopCharge: WebshopCharge;
    @Input() availableGlassCountsForDecor: number[];
    @Input() allGlazingPackages: GraspGlazingPackage[];

    @Output() windowSystemDefaultsLevelChange = new EventEmitter<string>();
    @Output() windowSystemDefaultsOverrideLowerLevelChange = new EventEmitter<boolean>();
    @Output() onWindowSystemChange = new EventEmitter<{ oldWindowSystemId: number, newWindowSystemId: number }>();
    @Output() onGlobalFillingTypeChange = new EventEmitter<FillingType>();
    @Output() onSetAllFillingWidth = new EventEmitter<number>();
    @Output() onSetAllGlazingGlassQuantity = new EventEmitter<number>();
    @Output() onSetWebshopGlazingPackage = new EventEmitter<number>();
    @Output() onSetTerraceGlazingPackage = new EventEmitter<number>();
    @Output() onSetGraspGlazingPackage = new EventEmitter<number>();
    @Output() onSetGraspGlazingCategory = new EventEmitter<number>();
    @Output() onSetGraspGlazingFrameCategory = new EventEmitter<number>();
    @Output() onSetGraspGlazingQuantity = new EventEmitter<number>();
    @Output() onSetAllFillingId = new EventEmitter<number>();
    @Output() onSetAllDecorativeFillingId = new EventEmitter();
    @Output() onSetAllFillingCoreColor = new EventEmitter<number>();
    @Output() onSetAllFillingExternalColor = new EventEmitter<number>();
    @Output() onSetAllFillingInternalColor = new EventEmitter<number>();
    @Output() onSetAllGlassType = new EventEmitter<GlassChangeEvent>();
    @Output() onSetAllFrames = new EventEmitter<GlassChangeEvent>();
    @Output() onGlobalGrillChange = new EventEmitter<{ oldId: number, newId: number }>();
    @Output() onGlobalGrillColorChange = new EventEmitter<{ oldId: number, newId: number }>();
    @Output() onGlobalMullionChange = new EventEmitter<number>();
    @Output() onGlobalGlazingBeadChange = new EventEmitter<number>();
    @Output() onSetDefaultValuesButtonClick = new EventEmitter();
    @Output() onCancelModelSavingButtonClick = new EventEmitter();
    @Output() onCancelWebshopChargeSavingButtonClick = new EventEmitter();
    @Output() onChangeTotalSize = new EventEmitter();
    @Output() onChangeThresholdProfile = new EventEmitter<number>();
    @Output() onChangeFrameProfile = new EventEmitter<number>();
    @Output() onChangeChannelSectionProfile = new EventEmitter<number>();
    @Output() onChangeConstructionalMullionProfile = new EventEmitter<number>();
    @Output() onChangeMovablePostProfile = new EventEmitter<number>();
    @Output() showAddonsTabViewEvent = new EventEmitter<boolean>();
    @Output() showConfigAddonsListEvent = new EventEmitter();
    @Output() onQuantityChange = new EventEmitter();
    @Output() onShowDescriptionDialog = new EventEmitter();
    @Output() onCoreColorChange = new EventEmitter<Color>();
    @Output() onSetWebshopChargeNames = new EventEmitter<MultilanguageField>();

    @Output() onGlobalFillingChange = new EventEmitter<boolean>();
    @Output() onBeforeGeneralTabValueChange = new EventEmitter();
    @Output() onGeneralTabValueChange = new EventEmitter<boolean>();

    @Output() errorsOnMainChange = new EventEmitter<boolean>();
    @Output() errorsOnColorChange = new EventEmitter<boolean>();
    @Output() errorsOnFillingChange = new EventEmitter<boolean>();
    @Output() errorsOnFittingsChange = new EventEmitter<boolean>();
    @Output() errorsOnMullionsChange = new EventEmitter<boolean>();
    @Output() errorsOnMuntinsChange = new EventEmitter<boolean>();

    @Output() generalTabInitialized = new EventEmitter<any>();

    @Output() onShowImage = new EventEmitter<WindowComponentPreviewData>();

    @Input() isSavingHistory: boolean;
    @Output() readonly isSavingHistoryChange = new EventEmitter<boolean>();

    private isAutoCorrectingSelectValue = false;

    sizeProp = SizeProp;
    Field = WindowEditorField;
    SidebarSection = SidebarSection;
    fieldUsage: GeneralTabFieldUsage;

    validateAll = false;

    maxWindowDimensions = new MaxWindowDimensions();

    FillingColorType = FillingColorType;

    FillingType = FillingType;
    availableGlazingPackages: Observable<SelectItem[]>;

    selectItemFormatters: SelectItemFormatters;

    componentInitialized = false;

    previousPositionQuantity: number;

    readonly externalColors: Observable<SelectItem[]>;
    readonly internalColors: Observable<SelectItem[]>;
    readonly terraceColors: Observable<SelectItem[]>;
    selectingRalColorHeader: string;
    selectingRalColorField: WindowEditorField;
    selectingRalColors: Color[];
    private colorBeforeRalSelection: Color;

    glazingBeadsSelectItems: SelectItem[];

    displayTerraceHandleLocationDialog = false;

    webshopChargeValidationErrors: ValidationErrors = {};

    visibleFields: WindowEditorField[] = [];
    isWebshopCharge: boolean;

    constructor(public translate: TranslateService,
                public cd: ChangeDetectorRef,
                public imageService: SidebarFieldImageService,
                public translatedSelectItemService: TranslatedSelectItemService,
                public windowEditorFieldContentProvider: WindowEditorFieldContentProvider
    ) {
        this.fieldUsage = new GeneralTabFieldUsage(this);
        this.selectItemFormatters = new SelectItemFormatters(translate);
        const filterColorSelectItems = (colorSelectItem: SelectItem, selectedColorId: number) => {
            const color = this.colors.find(c => c.id === colorSelectItem.value);
            return color != undefined
                && ((color.type !== ColorType.RAL_PALETTE_CUSTOM && color.type !== ColorType.NCS_PALETTE_CUSTOM)
                    || color.id === selectedColorId
                    || `${color.id}`.startsWith('PLACEHOLDER'));
        };
        this.externalColors = this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.EXTERNAL_COLOR)
            .pipe(map(colorSelectItems => colorSelectItems
                .filter(colorSelectItem => filterColorSelectItems(colorSelectItem, this.drawingData.specification.colorIdExternal))));
        this.internalColors = this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.INTERNAL_COLOR)
            .pipe(map(colorSelectItems => colorSelectItems
                .filter(colorSelectItem => filterColorSelectItems(colorSelectItem, this.drawingData.specification.colorIdInternal))));
        this.terraceColors = this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.TERRACE_COLOR)
            .pipe(map(colorSelectItems => colorSelectItems
                .filter(colorSelectItem => filterColorSelectItems(colorSelectItem, this.drawingData.specification.colorIdExternal))));
        this.availableGlazingPackages = this.windowEditorFieldContentProvider.getItemsStream(this.Field.GLAZING_PACKAGE);
    }

    totalSizeChanged(sizeParam: SizeProp, saveAfterChangeSize = false) {
        let newDimension = {};
        newDimension[sizeParam] = this[sizeParam];
        newDimension['autosave'] = saveAfterChangeSize;
        this.onChangeTotalSize.emit(newDimension);
    }

    ngAfterViewInit() {
        if (this.sidebarOnlyMode) {
            FocusOnElement.tryToFocus(WindowEditorField.QUANTITY + '_id', 0, 3, 100);
        } else {
            FocusOnElement.tryToFocus(GeneralTabComponent.TOTAL_WIDTH_INPUT_ID, 0, 3, 100);
        }

        let generalTabHaveErrors = new GeneralTabHaveErrors();

        generalTabHaveErrors.main = this.fieldUsage.sectionHasErrors(SidebarSection.MAIN);
        generalTabHaveErrors.color = this.fieldUsage.sectionHasErrors(SidebarSection.COLOR);
        generalTabHaveErrors.fittings = this.fieldUsage.sectionHasErrors(SidebarSection.FITTINGS);
        generalTabHaveErrors.filling = this.fieldUsage.sectionHasErrors(SidebarSection.FILLING);
        generalTabHaveErrors.mullions = this.fieldUsage.sectionHasErrors(SidebarSection.MULLIONS);
        generalTabHaveErrors.muntins = this.fieldUsage.sectionHasErrors(SidebarSection.MUNTINS);

        this.generalTabInitialized.emit(generalTabHaveErrors);
        this.componentInitialized = true;
        this.previousPositionQuantity = this.offerPosition.quantity;
        this.cd.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('totalBoundingBox' in changes) {
            const oldValue: MinMaxXY = changes['totalBoundingBox'].previousValue;
            const newValue: MinMaxXY = changes['totalBoundingBox'].currentValue;
            // pola totalWidth/totalHeight mogą być zmieniane również przez UI,
            // dlatego nadpisujemy ich wartość tylko jeżeli zmieniła się w totalBoundingBox
            if (!oldValue || (oldValue.minX !== newValue.minX || oldValue.maxX !== newValue.maxX)) {
                this.totalWidth = newValue.maxX - newValue.minX;
            }
            if (!oldValue || (oldValue.minY !== newValue.minY || oldValue.maxY !== newValue.maxY)) {
                this.totalHeight = newValue.maxY - newValue.minY;
            }
        }
        if ('colors' in changes) {
            // externalColors, internalColors and terraceColors subscriptions fire earlier than ngOnChanges for entire color list
            // so it filters using old data (invalid on window system change)
            // we need to trigger refilter again manually
            this.windowEditorFieldContentProvider.triggerChangeNotifiers(WindowEditorField.EXTERNAL_COLOR);
            this.windowEditorFieldContentProvider.triggerChangeNotifiers(WindowEditorField.INTERNAL_COLOR);
            this.windowEditorFieldContentProvider.triggerChangeNotifiers(WindowEditorField.TERRACE_COLOR);
        }
        if ('filteredGlazingBeads' in changes) {
            this.glazingBeadsSelectItems = this.filteredGlazingBeads.map(glazingBead =>
                this.selectItemFormatters.formatGlazingBeadOption(glazingBead));
        }
        if ('webshopCharge' in changes) {
            this.isWebshopCharge = this.webshopCharge !== undefined;
        }
    }

    emitOnWindowSystemDefaultsLevelChange(windowSystemDefaultsLevel: string) {
        this.windowSystemDefaultsLevel = windowSystemDefaultsLevel;
        this.windowSystemDefaultsLevelChange.emit(windowSystemDefaultsLevel);
    }

    emitWindowSystemDefaultsOverrideLowerLevelChange(windowSystemDefaultsOverrideLowerLevel: boolean): void {
        this.windowSystemDefaultsOverrideLowerLevel = windowSystemDefaultsOverrideLowerLevel;
        this.windowSystemDefaultsOverrideLowerLevelChange.emit(windowSystemDefaultsOverrideLowerLevel);
    }

    private emitOnWindowSystemChange(event: { oldWindowSystemId: number, newWindowSystemId: number }): void {
        this.onWindowSystemChange.emit(event);
    }

    private emitOnGlobalFillingTypeChange(event: FillingType) {
        this.emitOnFillingChange();
        this.onGlobalFillingTypeChange.emit(event);
    }

    private emitOnSetAllFillingWidth(width: number | undefined): void {
        this.emitOnFillingChange();
        this.onSetAllFillingWidth.emit(width);
    }

    emitOnSetAllGlazingGlassQuantity(event) {
        this.onSetAllGlazingGlassQuantity.emit(event);
        this.emitOnFillingChange();
    }

    emitOnSetWebshopGlazingPackage(event) {
        this.onSetWebshopGlazingPackage.emit(event);
    }

    private emitOnSetAllFillingId(fillingId: number | undefined) {
        this.emitOnFillingChange();
        this.onSetAllFillingId.emit(fillingId);
        this.cd.markForCheck();
    }

    private emitOnSetAllDecorativeFillingId(decorativeFillingId: number | undefined) {
        this.emitOnFillingChange();
        this.onSetAllDecorativeFillingId.emit(decorativeFillingId);
        this.cd.markForCheck();
    }

    private emitOnSetAllFillingCoreColor(value: number): void {
        this.emitOnFillingChange();
        this.onSetAllFillingCoreColor.emit(value);
    }

    private emitOnSetAllFillingExternalColor(value: number): void {
        this.emitOnFillingChange();
        this.onSetAllFillingExternalColor.emit(value);
    }

    private emitOnSetAllFillingInternalColor(value: number): void {
        this.emitOnFillingChange();
        this.onSetAllFillingInternalColor.emit(value);
    }

    emitOnSetAllGlassType(event: GlassChangeEvent) {
        if (event.newValue !== undefined) {
            this.removeFieldFromUnavailable(WindowEditorField.GLASS);
        }
        this.onSetAllGlassType.emit(event);
        this.emitOnFillingChange();
    }

    emitOnSetAllFrames(event: GlassChangeEvent) {
        if (event.newValue !== undefined) {
            this.removeFieldFromUnavailable(WindowEditorField.DISTANCE_FRAME);
        }
        this.onSetAllFrames.emit(event);
        this.emitOnFillingChange();
    }

    emitOnGlobalGrillChange(newId: number | undefined, colorChange: boolean, force: boolean) {
        if (newId != undefined || force) {
            this.onBeforeGeneralTabValueChange.emit(newId);
            let key = colorChange ? 'grillColorId' : 'grillId';
            let emitter = colorChange ? this.onGlobalGrillColorChange : this.onGlobalGrillChange;
            emitter.emit({oldId: this.commonData[key], newId: newId});
            this.onGeneralTabValueChange.emit(false); // already redrawn in onGlobalGrillChange
            this.requiredFieldUpdated(SidebarSection.MUNTINS);
            this.removeFieldFromUnavailable(WindowEditorField.GRILL);
        }
    }

    private emitOnGlobalMullionChange(newMullionId) {
        let oldMullionId = this.commonData['mullionId'];
        this.commonData['mullionId'] = newMullionId;
        this.onGlobalMullionChange.emit(oldMullionId);
    }

    private emitOnGlobalGlazingBeadChange(newGlazingBeadId) {
        this.onGlobalGlazingBeadChange.emit(newGlazingBeadId);
    }

    emitOnSetDefaultValuesButtonClick() {
        this.webshopChargeValidationErrors = {};
        if (this.isDataModificationMode()) {
            this.onSetWebshopChargeNames.emit(this.webshopCharge.name);
            this.validateWebshopChargeName(this.webshopCharge.name);
        }
        if (this.modelMode) {
            if (this.commonData.fillingType !== FillingType.GLASS) {
                this.webshopChargeValidationErrors['value.fillingType'] = 'error.windowSystemModelDto.value.fillingType.not_in_range';
            }
        }
        this.onSetDefaultValuesButtonClick.emit();
    }

    private validateWebshopChargeName(name: MultilanguageField): void {
        if (!name[this.translate.currentLang] || name[this.translate.currentLang].trim() === '') {
            this.webshopChargeValidationErrors[`name[${this.translate.currentLang}]`] =
                `error.webshopChargeDto.name[${this.translate.currentLang}].not_empty`;
        }
    }

    emitOnCancelModel() {
        this.onCancelModelSavingButtonClick.emit();
    }

    emitOnCancelWebshopCharge() {
        this.onCancelWebshopChargeSavingButtonClick.emit();
    }

    emitOnChangeThresholdProfile(event) {
        this.onChangeThresholdProfile.emit(event);
    }

    emitOnChangeChannelSectionProfile(event) {
        this.onChangeChannelSectionProfile.emit(event);
    }

    emitOnChangeConstructionalMullionProfile(event) {
        this.onChangeConstructionalMullionProfile.emit(event);
    }

    emitOnChangeMovablePostProfile(event) {
        this.onChangeMovablePostProfile.emit(event);
    }

    emitOnChangeFrameProfile(event) {
        this.removeFieldFromUnavailable(WindowEditorField.PROFILE);
        this.onChangeFrameProfile.emit(event);
        this.requiredFieldUpdated(SidebarSection.MAIN);
    }

    emitOnFillingChange() {
        this.requiredFieldUpdated(SidebarSection.FILLING);
        this.onGlobalFillingChange.emit();
    }

    /**
     * To prevent entering negative values
     */
    filterNumbersOnInputField(event: Event): void {
        SidebarHelper.filterNumbersOnInputField(event);
    }

    updateOfferPositionQuantity(): void {
        if (!this.offerPosition.quantity) {
            this.offerPosition.quantity = 1;
        }

        if (this.previousPositionQuantity !== this.offerPosition.quantity) {
            this.onQuantityChange.emit();
        }

        this.previousPositionQuantity = this.offerPosition.quantity;
    }

    getUnselectedColorValue(): 0 | undefined {
        return this.webshopChargeMode == null ? undefined : 0;
    }

    private coreColorChange(): void {
        let unselectedValue = this.getUnselectedColorValue();
        this.drawingData.specification.colorIdCore = this.chosenCoreColor != undefined ? this.chosenCoreColor.id : unselectedValue;
        this.drawingData.specification.colorIdExternal = unselectedValue;
        this.drawingData.specification.colorIdInternal = unselectedValue;
        this.requiredFieldUpdated(SidebarSection.COLOR);
        this.onCoreColorChange.emit(this.chosenCoreColor);
    }

    getColorsForFilling(colorType: FillingColorType): ColorInterface[] {
        if (this.commonData.fillingType === FillingType.FILLING) {
            let filling = this.allFillings.find(f => f.id === this.commonData.fillingId);
            if (filling != undefined) {
                switch (colorType) {
                    case FillingColorType.INTERNAL:
                        return filling.internalColors;
                    case FillingColorType.EXTERNAL:
                        return filling.externalColors;
                    case FillingColorType.CORE:
                        return [filling.coreColor];
                }
            }
        } else if (this.commonData.fillingType === FillingType.DECORATIVE_FILLING) {
            let filling = this.systemDecorativeFillings.find(f => f.id === this.commonData.decorativeFillingId);
            if (filling != undefined) {
                switch (colorType) {
                    case FillingColorType.INTERNAL:
                        return filling.internalColors;
                    case FillingColorType.EXTERNAL:
                        return filling.externalColors;
                    case FillingColorType.CORE:
                        return [filling.coreColor];
                }
            }
        }
        return [];
    }

    private containsAngledMuntins(): boolean {
        return this.drawingData.windows.some(w => w.subWindows.some(s => s.areasSpecification.some(a => a.grills.some(grill => {
            return GrillHelper.isDrawnAtAngle(grill);
        }))));
    }

    private containsAngledMullions(): boolean {
        return this.drawingData.windows.some(
            w => w.subWindows.some(s => s.mullions.some(m => GrillHelper.isDrawnAtAngle(m))));
    }

    get filteredGrills(): Observable<SelectItem[]> {
        return this.containsAngledMuntins()
            ? this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.GRILL_ANGLED)
            : this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.GRILL);
    }

    get filteredMullions(): Observable<SelectItem[]> {
        return this.containsAngledMullions()
            ? this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.MULLION_ANGLED)
            : this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.MULLION);
    }

    coreColorRequireCover(): boolean {
        if (this.drawingData.specification.colorIdCore == undefined) {
            return false;
        } else {
            if (this.chosenCoreColor === undefined) {
                if (this.colors === undefined) {
                    return false;
                } else {
                    this.chosenCoreColor = this.getSystemCoreColorById(this.drawingData.specification.colorIdCore);
                    if (this.chosenCoreColor === undefined) {
                        return false;
                    }
                }
            }

            return this.chosenCoreColor.mustBeCovered && !this.chosenCoreColor.cannotBeCovered;
        }
    }

    private externalColorChange(): void {
        if (isNaN(this.drawingData.specification.colorIdExternal)) {
            this.drawingData.specification.colorIdExternal = this.getUnselectedColorValue();
        }

        this.requiredFieldUpdated(SidebarSection.COLOR);
        this.emitOnGlobalGrillChange(this.drawingData.specification.colorIdExternal, true, true);
        this.fillingExternalColorChange(this.drawingData.specification.colorIdExternal);
    }

    private bothSidesColorChange(): void {
        if (isNaN(this.drawingData.specification.colorIdExternal)) {
            this.drawingData.specification.colorIdExternal = this.getUnselectedColorValue();
            this.drawingData.specification.colorIdInternal = this.getUnselectedColorValue();
        }

        this.requiredFieldUpdated(this.selectingRalColorField === WindowEditorField.TERRACE_COLOR
            ? SidebarSection.MAIN
            : SidebarSection.COLOR);
        this.emitOnGlobalGrillChange(this.drawingData.specification.colorIdExternal, true, true);
        this.fillingExternalColorChange(this.drawingData.specification.colorIdExternal);
    }

    private internalColorChange(): void {
        if (isNaN(this.drawingData.specification.colorIdInternal)) {
            this.drawingData.specification.colorIdInternal = this.getUnselectedColorValue();
        }
        this.requiredFieldUpdated(SidebarSection.COLOR);
        this.fillingInternalColorChange(this.drawingData.specification.colorIdInternal);
    }

    isAddonsTabViewButtonDisabled(): boolean {
        return isUpsellingMode(this.webshopChargeMode) || !this.profileCompositionDistances.validate(this.drawingData);
    }

    showAddonsTabView() {
        if (!this.isAddonsTabViewButtonDisabled()) {
            this.showAddonsTabViewEvent.emit();
        }
    }

    showConfigAddonsList() {
        this.showConfigAddonsListEvent.emit();
    }

    sectionContainsErrors(section: SidebarSection): boolean {
        let value: boolean = this.fieldUsage.sectionHasErrors(section);

        switch (section) {
            case SidebarSection.MAIN:
                this.errorsOnMainChange.emit(value);
                return value;
            case SidebarSection.FILLING:
                this.errorsOnFillingChange.emit(value);
                return value;
            case SidebarSection.COLOR:
                this.errorsOnColorChange.emit(value);
                return value;
            case SidebarSection.FITTINGS:
                this.errorsOnFittingsChange.emit(value);
                return value;
            case SidebarSection.MULLIONS:
                this.errorsOnMullionsChange.emit(value);
                return value;
            case SidebarSection.MUNTINS:
                this.errorsOnMuntinsChange.emit(value);
                return value;
            default:
                return false;
        }
    }

    getSectionErrorClass(section: SidebarSection): { [cssClass: string]: boolean } {
        return {
            'accordion-header-with-errors': this.fieldUsage.sectionHasErrors(section)
        };
    }

    onSelectFocus(event: FocusEvent): void {
        SidebarHelper.onFocusOpenAccTabs(event, this.mainAcc);
        if (event.target instanceof HTMLInputElement) {
            event.preventDefault();
            (<HTMLInputElement>event.target).select();
        }

    }

    private requiredFieldUpdated(section: SidebarSection): void {
        this.sectionContainsErrors(section);
    }

    setValidateAll(value: boolean): void {
        this.validateAll = value;
    }

    markForCheck(): void {
        this.cd.markForCheck();
    }

    emitOnGeneralTabValueChange(value, field: WindowEditorField): void {
        if (!((field === WindowEditorField.GLAZING_BEAD || field === WindowEditorField.MULLION) && value === undefined)) {
            this.removeFieldFromUnavailable(field);
        }
        value = SidebarHelper.unwrapUndefinedNoneSelectionWorkaround(value);
        // commonData fields might get a undefined value when they have different values in each subwindow
        // in that case we don't want to fire change events again creating double history entries and setting undefined in all subwindows
        if (value != undefined || !this.isAutoCorrectingSelectValue) {
            this.onBeforeGeneralTabValueChange.emit(value);
            this.updateFieldValue(value, field);
            if (WindowEditorField.GLASS_SELECTOR !== field) {
                this.onGeneralTabValueChange.emit(this.shouldRedrawOnGeneralTabValueChange(field));
            }
        }
    }

    shouldRedrawOnGeneralTabValueChange(field: WindowEditorField): boolean {
        return field === WindowEditorField.OPENING || field === WindowEditorField.TERRACE_HANDLE_LAYOUT;
    }

    private updateFieldValue(value: any, field: WindowEditorField) {
        switch (field) {
            case WindowEditorField.WELD_TYPE:
                this.drawingData.specification.weldType.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.VIEW:
                this.drawingData.view = value;
                break;
            case WindowEditorField.FRAME_ENHANCEMENT:
                this.drawingData.specification.frameEnhancement.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.WINDOW_FUNCTION:
                this.drawingData.specification.windowFunction = value;
                break;
            case WindowEditorField.COVERS:
                this.drawingData.specification.cover.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.MILLINGS:
                this.drawingData.specification.milling.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.MILLINGS_NORWEGIAN:
                this.drawingData.specification.millingNorwegian.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.UNDER_WINDOW_BEAD:
                this.drawingData.specification.underWindowBead.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.HANDLES:
                this.drawingData.specification.handleType.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.TERRACE_HANDLE:
                this.drawingData.specification.terraceHandle.addonId = value;
                if (value == undefined) {
                    this.drawingData.specification.terraceHandleLayout = undefined;
                }
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.TERRACE_HANDLE_LAYOUT:
                this.drawingData.specification.terraceHandleLayout = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.UNDERWINDOW_PROFILE:
                this.drawingData.specification.underwindowProfile.addonId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.OPENING:
                this.drawingData.specification.opening = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.SEALS_EXTERNAL:
                this.drawingData.specification.sealExternalId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.SEALS_INTERNAL:
                this.drawingData.specification.sealInternalId = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.CORE_COLOR:
                this.chosenCoreColor = this.getSystemCoreColorById(value);
                this.coreColorChange();
                break;
            case WindowEditorField.EXTERNAL_COLOR:
                this.drawingData.specification.colorIdExternal = value;
                this.externalColorChange();
                break;
            case WindowEditorField.INTERNAL_COLOR:
                this.drawingData.specification.colorIdInternal = value;
                this.internalColorChange();
                break;
            case WindowEditorField.TERRACE_COLOR:
                this.drawingData.specification.colorIdInternal = value;
                this.drawingData.specification.colorIdExternal = value;
                this.bothSidesColorChange();
                break;
            case WindowEditorField.FILLING_WIDTH:
                this.emitOnSetAllFillingWidth(value);
                break;
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
                this.fillingNameChange(value);
                break;
            case WindowEditorField.DECORATIVE_FILLING:
                this.decorativeFillingNameChange(value);
                break;
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
                this.fillingExternalColorChange(value);
                break;
            case WindowEditorField.FILLING_INTERNAL_COLOR:
                this.fillingInternalColorChange(value);
                break;
            case WindowEditorField.GLAZING_BEAD:
                this.emitOnGlobalGlazingBeadChange(value);
                break;
            case WindowEditorField.FITTING_BRAKE:
                this.drawingData.specification.fittingBrake.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_SLIDING:
                this.drawingData.specification.fittingSliding.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_TYPE:
                this.drawingData.specification.fittingType.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_ESPAGNOLETTE_TYPE:
                this.drawingData.specification.fittingEspagnoletteType.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_VERANDA:
                this.drawingData.specification.fittingVeranda.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_INSERTION:
                this.drawingData.specification.fittingInsertion.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_MAIN_INSERTION:
                this.drawingData.specification.fittingMainInsertion.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_ADDITIONAL_INSERTION:
                this.drawingData.specification.fittingAdditionalInsertion.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_LOCK:
                this.drawingData.specification.fittingLock.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FITTING_LOCK_TERRACE:
                this.drawingData.specification.fittingLockTerrace.addonId = value;
                if (value == undefined) {
                    this.drawingData.specification.fittingLockTerraceLocation = undefined;
                }
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.FITTING_LOCK_TERRACE_LOCATION:
                this.drawingData.specification.fittingLockTerraceLocation = value;
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.FITTING_AUTOMATIC_DRIVE:
                if (this.drawingData.specification.fittingAutomaticDrive == undefined) {
                    this.drawingData.specification.fittingAutomaticDrive = new WindowAddon();
                }
                this.drawingData.specification.fittingAutomaticDrive.addonId = value;
                this.requiredFieldUpdated(SidebarSection.FITTINGS);
                break;
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS:
                this.emitOnGlobalFillingTypeChange(value);
                break;
            case WindowEditorField.WINDOW_SYSTEM: {
                const event = {oldWindowSystemId: this.drawingData.windowSystemId, newWindowSystemId: value};
                this.drawingData.windowSystemId = value;
                this.emitOnWindowSystemChange(event);
                break;
            }
            case WindowEditorField.MULLION:
                this.emitOnGlobalMullionChange(value);
                break;
            case WindowEditorField.TERRACE_GLAZING_PACKAGE:
                this.onSetTerraceGlazingPackage.emit(value);
                this.requiredFieldUpdated(SidebarSection.MAIN);
                break;
            case WindowEditorField.GLAZING_PACKAGE:
                if (value != undefined) {
                    this.onSetGraspGlazingPackage.emit(value);
                    this.requiredFieldUpdated(SidebarSection.MAIN);
                }
                break;
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
                if (value != undefined) {
                    this.onSetGraspGlazingCategory.emit(value);
                    this.requiredFieldUpdated(SidebarSection.MAIN);
                }
                break;
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
                if (value != undefined) {
                    this.onSetGraspGlazingFrameCategory.emit(value);
                    this.requiredFieldUpdated(SidebarSection.MAIN);
                }
                break;
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
                if (value != undefined) {
                    this.onSetGraspGlazingQuantity.emit(value);
                    this.requiredFieldUpdated(SidebarSection.MAIN);
                }
                break;
        }
    }

    handleBeginSelectInvalidValueCorrection(event: SelectInvalidValueCorrectedEvent): void {
        if (event.reason === SelectInvalidValueCorrectionReason.OPTIONS_CHANGED) {
            this.isSavingHistoryChange.emit(false);
        }
        this.isAutoCorrectingSelectValue = true;
    }

    handleEndSelectInvalidValueCorrection(): void {
        if (!this.isSavingHistory) {
            this.isSavingHistoryChange.emit(true);
        }
        this.isAutoCorrectingSelectValue = false;
    }

    private getSystemCoreColorById(id: number): Color {
        return this.colors.find(color => color.id === id && color.core);
    }

    private refreshChosenCoreColor(): void {
        this.chosenCoreColor = this.getSystemCoreColorById(this.drawingData.specification.colorIdCore);
    }

    public getWindowSystem(): WindowSystemDefinition {
        return this.selectedWindowSystem;
    }

    /**
     * Few fields are referenced between model and view components as whole objects (not primitives like ids) so
     * after some actions (like undo / redo) there is a need to refresh references to selected objects.
     */
    refreshSelectedFields(): void {
        this.refreshChosenCoreColor();
    }

    private fillingNameChange(value: number | undefined): void {
        this.emitOnSetAllFillingId(value);
        this.updateFillingColors();

        let filling = this.allFillings.find(f => f.id === value);

        if (filling && filling.coreColor) {
            this.commonData.coreColorId = filling.coreColor.id;
            this.emitOnSetAllFillingCoreColor(filling.coreColor.id);
        } else {
            this.commonData.coreColorId = undefined;
            this.emitOnSetAllFillingCoreColor(null);
        }
    }

    private decorativeFillingNameChange(value: number | undefined): void {
        this.commonData.decorativeFillingId = value;
        this.emitOnSetAllDecorativeFillingId(value);
        this.updateFillingColors();

        let filling = this.systemDecorativeFillings.find(f => f.id === value);

        if (filling && filling.coreColor) {
            this.commonData.coreColorId = filling.coreColor.id;
            this.emitOnSetAllFillingCoreColor(filling.coreColor.id);
        } else {
            this.commonData.coreColorId = undefined;
            this.emitOnSetAllFillingCoreColor(null);
        }
    }

    private updateFillingColors() {
        if (this.fieldUsage.show(WindowEditorField.FILLING_EXTERNAL_COLOR)) {
            this.fillingExternalColorChange(this.drawingData.specification.colorIdExternal);
        } else {
            this.fillingExternalColorChange(undefined);
        }

        if (this.fieldUsage.show(WindowEditorField.FILLING_INTERNAL_COLOR)) {
            this.fillingInternalColorChange(this.drawingData.specification.colorIdExternal);
        } else {
            this.fillingInternalColorChange(undefined);
        }
    }

    private fillingExternalColorChange(value): void {
        if (value == undefined || this.getColorsForFilling(FillingColorType.EXTERNAL).find(color => color.id === value)) {
            this.commonData.externalColorId = value;
        } else {
            this.commonData.externalColorId = undefined;
        }

        this.emitOnSetAllFillingExternalColor(value);
    }

    private fillingInternalColorChange(value): void {
        if (value == undefined || this.getColorsForFilling(FillingColorType.INTERNAL).find(color => color.id === value)) {
            this.commonData.internalColorId = value;
        } else {
            this.commonData.internalColorId = undefined;
        }

        this.emitOnSetAllFillingInternalColor(value);
    }

    showDescriptionDialog(): void {
        this.onShowDescriptionDialog.emit();
    }

    applyExternalToInternalColor(): void {
        if (this.drawingData.specification.colorIdExternal != null) {
            let internalColor = this.colors.find(color => color.id === this.drawingData.specification.colorIdExternal);
            if (internalColor != undefined) {
                this.handleColorSelected(internalColor.id, WindowEditorField.INTERNAL_COLOR);
                if (internalColor.type === ColorType.OTHER) {
                    this.drawingData.specification.colorOtherInfoInternal = this.drawingData.specification.colorOtherInfoExternal;
                }
            }
        }
    }

    applyFillingExternalToInternalColor(): void {
        if (this.commonData.externalColorId != null) {
            const internalColors = this.getColorsForFilling(FillingColorType.INTERNAL);
            if (internalColors.find(color => color.id === this.commonData.externalColorId) != undefined) {
                this.emitOnGeneralTabValueChange(this.commonData.externalColorId, WindowEditorField.FILLING_INTERNAL_COLOR);
            }
        }
    }

    handleShowImage(imageSource: Observable<string>, header: string): void {
        this.onShowImage.emit(new WindowComponentPreviewData(imageSource, header));
    }

    getGlazingWidths(): string {
        let windowSystem = this.getWindowSystem();
        if (this.commonData.fillingType === FillingType.DECORATIVE_FILLING) {
            return this.commonData.decorativeFillingId == null ? ''
                : this.systemDecorativeFillings.find(filling => filling.id === this.commonData.decorativeFillingId).glazingWidths;
        }
        return windowSystem != null ? windowSystem.glazingWidths : '';
    }

    handleColorSelected(colorId: number | DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE, field: WindowEditorField) {
        this.removeFieldFromUnavailable(field);
        if (colorId === DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE) {
            switch (field) {
                case WindowEditorField.EXTERNAL_COLOR:
                    this.drawingData.specification.colorIdExternal = colorId;
                    break;
                case WindowEditorField.INTERNAL_COLOR:
                    this.drawingData.specification.colorIdInternal = colorId;
                    break;
                case WindowEditorField.CORE_COLOR:
                    this.drawingData.specification.colorIdCore = colorId;
                    break;
                case WindowEditorField.TERRACE_COLOR:
                    this.drawingData.specification.colorIdExternal = colorId;
                    this.drawingData.specification.colorIdInternal = colorId;
                    break;
            }
            return;
        }
        const color = this.colors.find(c => c.id === colorId);
        if (color != undefined && typeof color.id !== 'number'
            && (color.type === ColorType.RAL_PALETTE_CUSTOM || color.type === ColorType.NCS_PALETTE_CUSTOM)) {
            const dependentOptionFilter = (selectableColor: Color) => this.windowEditorFieldContentProvider.getItems(field)
                .find(option => option.value === selectableColor.id);
            switch (field) {
                case WindowEditorField.TERRACE_COLOR:
                    this.colorBeforeRalSelection = this.colors
                        .find(c => c.id === this.drawingData.specification.colorIdExternal);
                    this.selectingRalColors = (color.type === ColorType.RAL_PALETTE_CUSTOM
                        ? this.systemRalBothSidesColors : this.systemNcsBothSidesColors)
                        .filter(dependentOptionFilter);
                    break;
                case WindowEditorField.EXTERNAL_COLOR:
                    this.colorBeforeRalSelection = this.colors
                        .find(c => c.id === this.drawingData.specification.colorIdExternal);
                    this.selectingRalColors = (color.type === ColorType.RAL_PALETTE_CUSTOM
                        ? this.systemRalExternalColors
                        : this.systemNcsExternalColors)
                        .filter(dependentOptionFilter);
                    this.handleInternalColorChangeWhenExternalColorChanged(color, field);
                    break;
                case WindowEditorField.INTERNAL_COLOR:
                    this.colorBeforeRalSelection = this.colors
                        .find(c => c.id === this.drawingData.specification.colorIdInternal);
                    this.selectingRalColors = (color.type === ColorType.RAL_PALETTE_CUSTOM
                        ? this.systemRalInternalColors
                        : this.systemNcsInternalColors)
                        .filter(dependentOptionFilter);
                    break;
                case WindowEditorField.GRILL_COLOR:
                    this.colorBeforeRalSelection = this.intersectingGrillColors
                        .find(c => c.id === this.commonData.grillColorId);
                    this.selectingRalColors = (color.type === ColorType.RAL_PALETTE_CUSTOM
                        ? this.intersectingGrillRalColors
                        : this.intersectingGrillNcsColors)
                        .filter(dependentOptionFilter);
                    break;
            }
            this.selectingRalColorHeader = 'OFFER.TABS.SECTION.COLOR.' + color.type;
            this.selectingRalColorField = field;
            if (field !== WindowEditorField.GRILL_COLOR) {
                this.emitOnGeneralTabValueChange(undefined, field);
            } else {
                // hack: put 'PLACEHOLDER_xxx' string in commonData.grillColorId field to allow
                // reverting it back to undefined when cancelling ral/ncs dialog
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        this.commonData.grillColorId = <any>'PLACEHOLDER_RAL';
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        this.commonData.grillColorId = <any>'PLACEHOLDER_NCS';
                        break;
                    default:
                        break;
                }
            }
        } else {
            // remove old RAL color
            const colorSwap = this.prepareRalColorSwap(field);
            if (colorSwap.oldColor != undefined
                && (colorSwap.oldColor.type === ColorType.RAL_PALETTE_CUSTOM || colorSwap.oldColor.type === ColorType.NCS_PALETTE_CUSTOM)) {
                this.handleInternalColorChangeWhenExternalColorChanged(color, field);
            }
            if (field !== WindowEditorField.GRILL_COLOR) {
                this.emitOnGeneralTabValueChange(color != undefined ? color.id : undefined, field);
                this.handleInternalColorChangeWhenExternalColorChanged(color, field);
            } else {
                this.emitOnGlobalGrillChange(color != undefined ? color.id : undefined, true, false);
            }
            if (this.selectingRalColorField === field && field !== WindowEditorField.TERRACE_COLOR) {
                this.selectingRalColorHeader = undefined;
                this.selectingRalColorField = undefined;
                this.selectingRalColors = undefined;
                this.colorBeforeRalSelection = undefined;
            }
        }
    }

    handleInternalColorChangeWhenExternalColorChanged(color: Color, field: WindowEditorField): void {
        if (color != null && field === WindowEditorField.TERRACE_COLOR) {
            this.handleColorSelected(color.id, WindowEditorField.INTERNAL_COLOR);
        }
    }

    handleRalColorSelected(color: Color): void {
        if (this.selectingRalColorField !== WindowEditorField.GRILL_COLOR) {
            this.emitOnGeneralTabValueChange(color != undefined ? color.id : undefined, this.selectingRalColorField);
        } else {
            this.emitOnGlobalGrillChange(color != undefined ? color.id : undefined, true, true);
        }
        this.selectingRalColorHeader = undefined;
        this.selectingRalColorField = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    handleRalColorSelectionCancel(): void {
        if (this.selectingRalColorField !== WindowEditorField.GRILL_COLOR) {
            this.emitOnGeneralTabValueChange(this.colorBeforeRalSelection != undefined ? this.colorBeforeRalSelection.id : undefined,
                this.selectingRalColorField);
        } else {
            this.emitOnGlobalGrillChange(this.colorBeforeRalSelection != undefined ? this.colorBeforeRalSelection.id : undefined,
                true, false);
        }
        this.selectingRalColorHeader = undefined;
        this.selectingRalColorField = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    private prepareRalColorSwap(field: WindowEditorField): { oldColor: Color } {
        let oldColor: Color;
        switch (field) {
            case WindowEditorField.TERRACE_COLOR:
            case WindowEditorField.EXTERNAL_COLOR:
                oldColor = this.colors.find(color => color.id === this.drawingData.specification.colorIdExternal);
                break;
            case WindowEditorField.INTERNAL_COLOR:
                oldColor = this.colors.find(color => color.id === this.drawingData.specification.colorIdInternal);
                break;
            default:
                return {
                    oldColor: undefined
                };
        }
        return {
            oldColor: oldColor
        };
    }

    showGlobalSettingsTab(): boolean {
        return !this.readOnlyMode && !this.modelMode && !this.isDataModificationMode();
    }

    standardDoorstepAvailable(): boolean {
        return this.getWindowSystem().standardDoorstepAvailable;
    }

    isDataModificationMode(): boolean {
        return isDataModificationMode(this.webshopChargeMode);
    }

    handleTerraceHandleLayoutSelectClick(event: MouseEvent): void {
        event.preventDefault();
        (event.target as HTMLSelectElement).blur();
        this.windowEditorFieldContentProvider.clearHasNewItemsMarker(WindowEditorField.TERRACE_HANDLE_LAYOUT);
        this.displayTerraceHandleLocationDialog = true;
    }

    isDecorativeFillingType(fillingType: FillingType): boolean {
        return fillingType === FillingType.DECORATIVE_FILLING;
    }

    private removeFieldFromUnavailable(field: WindowEditorField) {
        const unavailableFields = this.windowEditorFieldContentProvider.fieldsWithUnavailableValues;
        const index = unavailableFields.indexOf(field, 0);
        if (index > -1) {
            unavailableFields.splice(index, 1);
        }
    }
}
