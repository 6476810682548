import {WebshopAreaBarsCode} from '../../../../window-designer/webshop-area-bars/WebshopAreaBarsCode';
import {WindowTypeCode} from '../../../../window-designer/window-types/window-type-code';
import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MaterialType} from '../../../common/enums/MaterialType';
import {MultilanguageField} from '../../../supportedLanguages';

export enum WebshopWindowSystemCategory {
    WINDOW = 'WINDOW',
    DOOR = 'DOOR',
    TERRACE = 'TERRACE'
}

export interface WebshopProductConfiguration {
    productType?: WebshopWindowSystemCategory;
    language?: keyof MultilanguageField;
    windowSystemMaterial?: MaterialType;
    windowTypeCode?: WindowTypeCode;
    windowWidth?: number;
    windowHeight?: number;
    windowSystemModelId?: number;
    quantity?: number;
    externalColorId?: number;
    internalColorId?: number;
    glazingGlassQuantity?: number;
    glazingPackageId?: number;
    useWarmDistanceFrames?: boolean;
    areaBarCode?: WebshopAreaBarsCode;
    additionalChargesIds?: number[];
    recommendedConfigurationId?: number;
    fillingMullionPosition?: number;
}

@JsonDeserializable
export class WebshopRecommendedConfiguration {
    id: number;
    windowSystemModelId: number;
    windowSystemName = new MultilanguageField();
    businessTypeId: number;
    businessTypeName = new MultilanguageField();
    validFrom: Date;
    validTo: Date;
    indefinite: boolean;
    description: string;
    active: boolean;
    showOnMainPage: boolean;
    sortIndex: number;
    productConfiguration: WebshopProductConfiguration = {};
    configurationInvalid: boolean;

    static fromJSON(jsonObject: any): WebshopRecommendedConfiguration {
        const webshopRecommendedConfiguration = new WebshopRecommendedConfiguration();
        webshopRecommendedConfiguration.id = jsonObject.id;
        webshopRecommendedConfiguration.windowSystemModelId = jsonObject.windowSystemModelId;
        if (jsonObject.windowSystemName != undefined) {
            webshopRecommendedConfiguration.windowSystemName = MultilanguageField.fromJSON(jsonObject.windowSystemName);
        }
        webshopRecommendedConfiguration.businessTypeId = jsonObject.businessTypeId;
        if (jsonObject.businessTypeName != undefined) {
            webshopRecommendedConfiguration.businessTypeName = MultilanguageField.fromJSON(jsonObject.businessTypeName);
        }
        if (jsonObject.validFrom != undefined) {
            webshopRecommendedConfiguration.validFrom = new Date(jsonObject.validFrom);
        }
        if (jsonObject.validTo != undefined) {
            webshopRecommendedConfiguration.validTo = new Date(jsonObject.validTo);
        }
        webshopRecommendedConfiguration.description = jsonObject.description;
        webshopRecommendedConfiguration.active = jsonObject.active;
        webshopRecommendedConfiguration.showOnMainPage = jsonObject.showOnMainPage;
        webshopRecommendedConfiguration.sortIndex = jsonObject.sortIndex;
        webshopRecommendedConfiguration.productConfiguration = jsonObject.productConfiguration;
        webshopRecommendedConfiguration.configurationInvalid = jsonObject.configurationInvalid;
        webshopRecommendedConfiguration.indefinite = jsonObject.indefinite;
        return webshopRecommendedConfiguration;
    }
}
