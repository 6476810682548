import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {AddonCategory} from "../addon-category";
import {Addon, AutoOption} from "../../addons/addon";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {AddonsService} from "../../addons/addons.service";
import {TranslateService} from "@ngx-translate/core";
import {AddonCategoryEnum} from "../../../../../window-designer/enums/AddonCategoryEnum";
import {CatalogSection} from "../../addons/CatalogSection";

@Component({
    selector: 'app-addon-addons-category-form',
    templateUrl: './addon-category-addons-form.component.html',
    styleUrls: ['addon-category-picklist.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AddonsService]
})
export class AddonCategoryAddonsFormComponent implements OnInit {

    @Input() item: AddonCategory;
    @Input() validationErrors: any;
    @Input() target: CatalogSection;
    @Input() disabled: boolean;
    allAvailableAddons: Addon[];
    availableAddons: Addon[];
    selectedAddons: Addon[];

    constructor(public translate: TranslateService,
                private addonService: AddonsService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.allAvailableAddons = [];
        const filters: { [field: string]: FilterMetadata } = {};
        if (this.target === CatalogSection.GATE) {
            filters["category"] = {value: [AddonCategoryEnum.GATE_SIDEBAR, AddonCategoryEnum.GATE_NOT_SIDEBAR]};
        } else if (this.target === CatalogSection.CONFIG) {
            filters["category"] = {value: [AddonCategoryEnum.CONFIG_ADDON_SIDEBAR]};
        }
        filters["forOffer"] = {value: "true"};
        filters['includeGeneralAddons'] = {value: 'true'};
        filters['includeSubsystemAddons'] = {value: 'false'};
        filters['includeUncategorized'] = {value: 'true'};
        filters['includeWithCategoryId'] = {value: this.item.id};
        this.addonService.getItems(undefined, undefined, filters, undefined, undefined)
            .subscribe(
                addons => {
                    this.allAvailableAddons = addons.data;

                    this.selectedAddons = [];
                    this.availableAddons = this.allAvailableAddons.filter(addon => (addon.autoOption !== AutoOption.NONE) === this.item.hasAutoOption);
                    for (let selectedAddon of this.item.addons) {
                        let selectedIndex = this.availableAddons.findIndex(addon => addon.id === selectedAddon.id);
                        if (selectedIndex !== -1) {
                            this.selectedAddons.push(...this.availableAddons.splice(selectedIndex, 1));
                        }
                    }
                    this.changeDetector.markForCheck();
                }
            );
    }

    handleMoveToSource(addons: Addon[]): void {
        for (let movedOption of addons) {
            let selectedIndex = this.item.addons.findIndex(addon => addon.id === movedOption.id);
            if (selectedIndex !== -1) {
                this.item.addons.splice(selectedIndex, 1);
            }
        }
    }

    handleMoveToTarget(moved: Addon[]): void {
        for (let movedOption of moved) {
            this.item.addons.push(movedOption);
        }
    }

    onAutoOptionChange(hasAutoOption: boolean): void {
        this.item.hasAutoOption = hasAutoOption;
        this.selectedAddons = [];
        this.availableAddons = this.allAvailableAddons.filter(addon => (addon.autoOption !== AutoOption.NONE) === this.item.hasAutoOption);
    }
}
