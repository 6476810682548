import {ChangeDetectionStrategy, Component, ViewChild} from "@angular/core";
import {ActivatedRoute} from '@angular/router';
import {ContainerWithPreviewComponent} from '../../../../common/container-with-preview/container-with-preview.component';
import {AbstractPosition, PositionTarget} from '../../AbstractPosition';
import {OffersService} from "../../offer-service";
import {PaymentPackageService} from "../../payment-package/payment-package.service";
import {PositionListComponent} from "./position-list/position-list.component";

@Component({
    selector: 'app-position',
    templateUrl: './position.component.html',
    providers: [OffersService, PaymentPackageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionComponent {

    @ViewChild("positionList") positionList: PositionListComponent;
    @ViewChild("container") container: ContainerWithPreviewComponent;
    private target: PositionTarget;
    isProductionOrder = false;

    constructor(private route: ActivatedRoute) {
        this.route.data.subscribe(data => {
            this.target = data.target;
            this.isProductionOrder = PositionTarget.PRODUCTION_ORDER === this.target;
        });
    }

    positionChange(event: { data?: AbstractPosition }) {
        this.container.handlePositionChange(event.data, this.target);
    }

    pictureClicked(position: AbstractPosition) {
        this.positionList.editActionOnClick(position);
    }
}
