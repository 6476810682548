import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class PriceTableItem {
    width: number;
    height: number;
    value: number;

    constructor(width, height, value) {
        this.width = Number(width);
        this.height = Number(height);
        this.value = Number(value);
    }

    static fromJSON(jsonObject: any): PriceTableItem {
        return new PriceTableItem(jsonObject['width'], jsonObject['height'], jsonObject['value']);
    }

    isValid(): boolean {
        return this.isValidHeader(this.width) && this.isValidHeader(this.height) && this.isValidValue(this.value);
    }

    private isValidValue(n: any): boolean {
        return this.isNumeric(n) && Number(n) > 0;
    }

    private isValidHeader(n: any): boolean {
        return this.isInteger(n) && Number(n) > 0 && Number(n) < 9999999;
    }

    private isNumeric(n: any): boolean {
        return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
    }

    private isInteger(n: any): boolean {
        return Number.isInteger(Number(n));
    }
}
