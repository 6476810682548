export enum PricetableSelectionMode {
    WINDOW = 'WINDOW',
    WINDOW_COLOR_DEPENDENT = 'WINDOW_COLOR_DEPENDENT',
    CONFIG = 'CONFIG',
}

export enum WindowColorDependentAdditionalParam {
    DEFAULT = 0,
    EXTERNAL_ONLY = 1,
    INTERNAL_OR_BOTH = 2
}

export function getPricetableSelectionModeParamEnum(mode: PricetableSelectionMode) {
    switch (mode) {
        case PricetableSelectionMode.WINDOW:
            break;
        case PricetableSelectionMode.WINDOW_COLOR_DEPENDENT:
            return WindowColorDependentAdditionalParam;
        case PricetableSelectionMode.CONFIG:
            break;
        default:
            break;
    }
    return undefined;
}
