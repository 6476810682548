import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, map, mergeMap, tap} from 'rxjs/operators';
import {DistanceFrameType} from '../../../../window-designer/catalog-data/distance-frame-interface';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from "../../../common/service/translated.select.item";
import {TranslatedSelectItemBuilder} from "../../../common/service/translated.select.item.builder";
import {TristateCheckboxState} from '../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, DistanceFrameField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ErrorResponse} from '../../errors/errorResponse';
import {DistanceFrameFieldUsage} from "../catalog-field-usage";
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {DistanceFrameService} from './distance-frame.service';
import {DistanceFrame} from './distanceFrame';

@Component({
    selector: 'app-distance-frame',
    templateUrl: './distance-frame.component.html',
    styleUrls: ['./distance-frame.component.css', '../../shared-styles.css'],
    providers: [DistanceFrameService, WindowSystemDefinitionService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DistanceFrameComponent extends SingleSystemCheckboxCrudComponent<DistanceFrame, DistanceFrameService>
    implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };

    item: DistanceFrame;

    filterType: Observable<SelectItem[]>;
    availableTypes: Observable<SelectItem[]>;
    filterWarmFrame: TranslatedSelectItem[];
    windowSystemsByDefaultFrameId = new Map<number, number[]>();
    allActiveDistanceFrames: DistanceFrame[];
    distanceFramesForWarmAlternatives: Map<number, DistanceFrame[]>;

    @ViewChild('dt') datatable;

    validateDataStep: WizardStepValidator;

    editPermits: FieldLimitation[] = [];
    fieldUsage: DistanceFrameFieldUsage;
    CatalogTab = CatalogTab;
    DistanceFrameField = DistanceFrameField;
    warmFramesByThickness: Map<number, DistanceFrame[]>;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, DistanceFrameService, 'DISTANCE-FRAME', 'DistanceFrame');
        this.item = new DistanceFrame();
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new DistanceFrameFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterType = this.translatedSelectItemService.buildSortedDropdown(DistanceFrameType, 'DISTANCE-FRAME.FORM.TYPES.', '');
        this.filterWarmFrame = TranslatedSelectItemBuilder.create()
            .add('', '')
            .add('DISTANCE-FRAME.WARM_FRAME', true)
            .add('DISTANCE-FRAME.COLD_FRAME', false)
            .build();
        this.availableTypes = this.filterType.pipe(map(types => types.slice(1)));
        forkJoin({
            allActiveDistanceFrames: this.itemService.getAllActiveDistanceFrames(),
            usedInWindowSystems: this.itemService.getDistanceFrameIdsUsedInWindowSystemDefaultGlazingPackage(),
            editPermits: this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.DISTANCE_FRAMES)
        }).subscribe(data => {
            this.allActiveDistanceFrames = data.allActiveDistanceFrames.data;
            this.fillWindowSystemsByDefaultFrameId(data.usedInWindowSystems);
            this.editPermits = data.editPermits.fieldsLimitations;
        });
        // this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.DISTANCE_FRAMES).subscribe(permits => {
        //     this.editPermits = permits.fieldsLimitations;
        // });
    }

    protected getApiUrl(): string {
        return 'distanceframe';
    }

    private fillWindowSystemsByDefaultFrameId(usedInWindowSystems: { id: number, windowSystemIds: number[] }[]): void {
        this.windowSystemsByDefaultFrameId.clear();
        for (let usedInWindowSystem of usedInWindowSystems) {
            this.windowSystemsByDefaultFrameId.set(usedInWindowSystem.id, usedInWindowSystem.windowSystemIds);
        }
    }

    showDialogToCopy(): void {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.item = new DistanceFrame();
            this.getDistanceFrame(this.selectedItem.id);
        }
    }

    public getNewItem(): DistanceFrame {
        return new DistanceFrame();
    }

    hideFrameDetails(): void {
        this.reloadDatatable();
        this.setDisplayDialog(false);
        this.copyMode = false;
    }

    submit(): void {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        let observable: Observable<number>;
        if (this.copyMode) {
            observable = this.itemService.copyFrame(this.selectedItem.id, this.item).pipe(mergeMap(this.editLinksAfterSave()));
        } else {
            observable = this.itemService.saveFrame(this.item).pipe(mergeMap(this.editLinksAfterSave()));
        }
        observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
            next: frameId => {
                if (this.newItem || this.copyMode) {
                    this.selectedItem = this.getNewItem();
                    this.selectedItem.id = frameId;
                    this.allActiveDistanceFrames.push(Object.assign(this.getNewItem(), this.item));
                } else {
                    const distanceFrameForWarmAlternatives = this.allActiveDistanceFrames.find(frame => frame.id === frameId);
                    if (distanceFrameForWarmAlternatives != undefined) {
                        Object.assign(distanceFrameForWarmAlternatives, this.item);
                    } else {
                        this.allActiveDistanceFrames.push(Object.assign(this.getNewItem(), this.item));
                    }
                }
                this.hideFrameDetails();
                this.showSuccessMessage();
            },
            error: (errorMessage: HttpErrorResponse) => {
                let errorResponse = new ErrorResponse(errorMessage.error);
                if (errorResponse.is400()) {
                    this.validationErrors = Object.assign({}, errorResponse.invalidFields);
                    this.changeDetector.markForCheck();
                } else {
                    this.setErrors(errorMessage);
                }
            }
        });
    }

    validateForm(): Observable<boolean> {
        const validationErrors = {};
        if (!this.item.names[this.userLang]) {
            validationErrors[`names[${this.userLang}]`] = `error.distanceFrameDto.names[${this.userLang}].not_empty`;
        }
        if (!this.item.symbol) {
            validationErrors['symbol'] = 'error.distanceFrameDto.symbol.not_empty';
        }
        if (this.item.thickness == undefined) {
            validationErrors['thickness'] = 'error.distanceFrameDto.thickness.not_empty';
        } else if (this.item.thickness <= 0 || this.item.thickness >= 1000) {
            validationErrors['thickness'] = 'error.distanceFrameDto.thickness.not_in_range';
        }
        if (!this.item.frameGroup) {
            validationErrors['frameGroup'] = 'error.distanceFrameDto.frameGroup.not_empty';
        }
        if (!this.item.type) {
            validationErrors['type'] = 'error.distanceFrameDto.type.not_empty';
        }
        if (this.item.warmFrame && this.item.warmAlternativeDistanceFrameId != undefined) {
            validationErrors['warmAlternativeDistanceFrameId'] = 'error.distanceFrameDto.warmAlternativeDistanceFrameId.not_unique';
        }

        validationErrors['sortIndex'] = MultiValidator.of('error.distanceFrameDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    onRowSelect(event): void {
        this.validationErrors = {};
        this.item = new DistanceFrame();
        this.getDistanceFrame(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    cancel(): void {
        this.newItem = false;
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedItem);
    }

    doShowDialogToAdd() {
        this.distanceFramesForWarmAlternatives = new Map<number, DistanceFrame[]>();
        this.allActiveDistanceFrames.filter(frame => frame.warmFrame).forEach(value => {
            const frames = this.distanceFramesForWarmAlternatives.get(value.thickness) || [];
            frames.push(value);
            this.distanceFramesForWarmAlternatives.set(value.thickness, frames);
        });
        super.doShowDialogToAdd();
    }

    getDistanceFrame(frameId: number): void {
        forkJoin({
            frame: this.itemService.getItem(frameId),
            linkedSystems: this.getLinkedWindowSystems(frameId)
        }).subscribe({
            next: data => {
                this.item = data.frame;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.selectedWindowSystems = data.linkedSystems;
                this.distanceFramesForWarmAlternatives = new Map<number, DistanceFrame[]>();
                this.allActiveDistanceFrames.filter(frame => frame.warmFrame).forEach(value => {
                    if (value.id !== frameId) {
                        const frames = this.distanceFramesForWarmAlternatives.get(value.thickness) || [];
                        frames.push(value);
                        this.distanceFramesForWarmAlternatives.set(value.thickness, frames);
                    }
                });
                this.setDisplayDialog(true);
            },
            error: error => {
                console.error(error);
                if (error.status === 500) {
                    this.setErrors(error);
                } else {
                    this.growlMessageController.error(error);
                }
            },
            complete: () => {
                console.debug('getDistanceFrame` completed!');
            }
        });
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    isFramePositionSelected(distanceFrame: DistanceFrame, position: number, glassesInGlazingCount: number): boolean {
        return distanceFrame.positions.findIndex(p => p.position === position && p.glassesInGlazingCount === glassesInGlazingCount) !== -1;
    }

    handleFramePositionSelectionChange(distanceFrame: DistanceFrame, position: number, glassesInGlazingCount: number): void {
        const index = distanceFrame.positions.findIndex(p => p.position === position && p.glassesInGlazingCount === glassesInGlazingCount);
        if (index === -1) {
            distanceFrame.positions.push({position: position, glassesInGlazingCount: glassesInGlazingCount});
        } else {
            distanceFrame.positions.splice(index, 1);
        }
        this.itemService.setDistanceFramePositions(distanceFrame.id, distanceFrame.positions).subscribe();
        this.changeDetector.markForCheck();
    }

    getFrameAllPositionsSelectionState(distanceFrame: DistanceFrame): TristateCheckboxState {
        switch (distanceFrame.positions.length) {
            case 1 /*2 glassesInGlazing*/ + /*3 glassesInGlazing*/ 2 + /*4 glassesInGlazing*/ 3:
                return TristateCheckboxState.CHECKED;
            case 0:
                return TristateCheckboxState.UNCHECKED;
            default:
                break;
        }
        return TristateCheckboxState.CHECKED_PARTIALLY;
    }

    handleFrameAllPositionsSelectionChange(distanceFrame: DistanceFrame): void {
        if (this.getFrameAllPositionsSelectionState(distanceFrame) !== TristateCheckboxState.CHECKED) {
            distanceFrame.positions = [
                {position: 1, glassesInGlazingCount: 2},
                {position: 1, glassesInGlazingCount: 3},
                {position: 2, glassesInGlazingCount: 3},
                {position: 1, glassesInGlazingCount: 4},
                {position: 2, glassesInGlazingCount: 4},
                {position: 3, glassesInGlazingCount: 4}
            ];
        } else {
            distanceFrame.positions = [];
        }
        this.itemService.setDistanceFramePositions(distanceFrame.id, distanceFrame.positions).subscribe();
        this.changeDetector.markForCheck();
    }

    suppressDoubleClick(event: MouseEvent): void {
        event.stopPropagation();
    }
}
