import {WindowPaintAttributes, WindowParams} from "./WindowParams";
import {DrawingUtil, MinMaxXY} from "../drawing-util";
import {PainterParams} from "./PainterParams";

export class ScalingPainter {

    public static scale(params: PainterParams, positions: number[]): number[] {
        if (params.viewFromOutside) {
            positions = DrawingUtil.shiftArrayInXAxis(DrawingUtil.flipXCoordinates(positions), params.totalWidth);
        }
        if (params.scale !== 1) {
            positions = positions.map(p => p * params.scale);
        }
        return positions;
    }

    public static scaleBox(params: PainterParams, box: MinMaxXY): MinMaxXY {
        if (params.scale !== 1) {
            box = new MinMaxXY(
                box.minX * params.scale,
                box.maxX * params.scale,
                box.minY * params.scale,
                box.maxY * params.scale
            );
        }
        return box;
    }

    public static path(svg: Snap.Paper, polygon: number[], attr: WindowPaintAttributes, params: PainterParams,
                       shouldClose = true): Snap.Element {
        let path = DrawingUtil.pathStringFromPolygonPoints(ScalingPainter.scale(params, polygon), shouldClose);
        return svg.path(path).attr(attr);
    }

    public static line(svg: Snap.Paper, line: number[], attr: WindowPaintAttributes,
                       params: PainterParams): Snap.Element {
        line = ScalingPainter.scale(params, line);
        return svg.line(line[0], line[1], line[2], line[3]).attr(attr);
    }

    public static circle(svg: Snap.Paper, circle: number[], attr: WindowPaintAttributes,
                         params: PainterParams): Snap.Element {
        circle = ScalingPainter.scale(params, circle);
        return svg.circle(circle[0], circle[1], circle[2]).attr(attr);
    }

    public static ellipseWithClipPath(svg: Snap.Paper, ellipse: number[], clip: number[], attr: WindowPaintAttributes,
                                      params: PainterParams): Snap.Element {
        clip = ScalingPainter.scale(params, clip);
        ellipse = ScalingPainter.scale(params, ellipse);
        let clipPath = svg.path(DrawingUtil.pathStringFromPolygonPoints(clip, true));
        return svg.ellipse(ellipse[0], ellipse[1], ellipse[2], ellipse[3]).attr({clip: clipPath}).attr(attr);
    }

    public static prepareHollowMask(svg: Snap.Paper, outerPolygon: number[], innerPolygon: number[], params: PainterParams): Snap.Element {
        let shownOuterFrame = ScalingPainter.path(svg, outerPolygon, WindowParams.OverlayMask.Show, params);
        let hollowCenter = ScalingPainter.path(svg, innerPolygon, WindowParams.OverlayMask.Hide, params);
        let mask = svg.group(shownOuterFrame, hollowCenter);
        return mask;
    }
}
