import {diff} from 'deep-diff';
import {DrawingData} from '../../../../../window-designer/drawing-data/drawing-data';

export interface DrawingDataFieldChange {
    path: string;
    newValue: any;
}

export class DrawingDataDiffer {

    private previousDrawingData = (() => {
        const drawingData = new DrawingData();
        drawingData.view = undefined;
        return drawingData;
    })();

    diff(newDrawingData: DrawingData): DrawingDataFieldChange[] {

        const drawingDataDiff = diff(this.previousDrawingData, newDrawingData, {
            normalize: (currentPath, key, lhs, rhs) => {
                if (lhs && rhs) {
                    return undefined; // no alternate comparison logic
                }
                // ignore undefined vs "missing key"
                return [lhs != undefined ? lhs : null, rhs != undefined ? rhs : null];
            }
        });

        const result: DrawingDataFieldChange[] = [];

        if (drawingDataDiff != undefined) {
            for (let change of drawingDataDiff) {
                result.push({path: change.path != undefined ? change.path.join('.') : undefined, newValue: change.rhs});
            }
        }

        this.previousDrawingData = DrawingData.copy(newDrawingData);

        return result;
    }
}
