import {WindowSystemDefaults} from './entities/window-system-defaults';
import {Glazing} from "./catalog-data/glazing";
import {WindowSystemInterface} from './catalog-data/window-system-interface';
import {AreaSpecification} from "./drawing-data/AreaSpecification";
import {Filling} from "./drawing-data/Filling";
import {GlazingBead} from "./drawing-data/GlazingBead";
import {ProfilesComposition} from "./drawing-data/ProfilesComposition";
import {SubWindowData} from "./drawing-data/SubWindowData";
import {WindowData} from "./drawing-data/WindowData";
import {Wing} from "./drawing-data/Wing";
import {ErrorNames} from "./utils/ErrorNames";
import {WindowCommonData} from "./window-common-data";
import {SubWindowTypeCode} from "./window-types/subwindow-type-code";
import {WindowAttributes} from "./window-types/window-attributes";
import {MinMaxXY, Point} from "./drawing-util";
import {SubwindowAttributes} from "./window-types/subwindow-attributes";

export abstract class WindowDataFactory {

    private static DEFAULT_WINDOW_WIDTH = 1000;
    private static DEFAULT_WINDOW_HEIGHT = 1000;
    private static DEFAULT_ELLIPTICAL_WINDOW_HEIGHT_INCREASER = 200;
    private static DEFAULT_ELLIPTICAL_WINDOW_WIDTH_INCREASER = 200;

    public static createWindowData(attributes: WindowAttributes, windowSystem: WindowSystemInterface, windowCount: number,
        totalBoundingBox: MinMaxXY, side: 'top' | 'right' | 'bottom' | 'left' | 'center', forcedSize?: { width: number, height: number },
        defaultsData?: WindowSystemDefaults): WindowData[] {
        if (!attributes) {
            let err = new Error("WindowDataFactory.createWindowData: Not implemented for type: " + attributes.typeCode);
            err.name = ErrorNames.NOT_IMPLEMENTED;
            throw err;
        }

        const windows: WindowData[] = [];
        let windowSizes = this.getWindowSizes(attributes, windowSystem, windowCount, totalBoundingBox, side, forcedSize);
        windowSizes.forEach(windowSize => {
            let newWindow = new WindowData();
            windows.push(newWindow);
            newWindow.typeCode = attributes.typeCode;
            newWindow.subWindows = [];
            let subwindowSizes = this.getSubwindowSizes(attributes, windowSize);
            for (let i = 0; i < attributes.subwindows.length; i++) {
                let newSubWindow = this.initSubwindow(subwindowSizes[i], attributes.subwindows[i], defaultsData);
                newSubWindow.profilesComposition = attributes.profilesComposition ? attributes.profilesComposition[i] :
                    new ProfilesComposition();
                newWindow.subWindows.push(newSubWindow);
            }
        });
        return windows;
    }

    private static getWindowSizes(attributes: WindowAttributes, windowSystem: WindowSystemInterface, windowCount: number,
        totalBoundingBox: MinMaxXY, side: 'top' | 'right' | 'bottom' | 'left' | 'center',
        forcedSize?: { width: number, height: number }): MinMaxXY[] {

        let totalTargetWidth;
        let totalTargetHeight;
        let defaultWindowWidth = WindowDataFactory.getDefaultWidth(windowSystem, attributes.eliptical);
        let defaultWindowHeight = WindowDataFactory.getDefaultHeight(windowSystem, attributes.eliptical);
        let verticalWindowCount = 1;
        let horizontalWindowCount = 1;

        switch (side) {
            case "center":
                totalTargetWidth = defaultWindowWidth * (attributes.eliptical ? 1 :
                    (attributes.vertical ? 1 : attributes.subwindows.length));
                totalTargetHeight = defaultWindowHeight * (attributes.eliptical ? 1 :
                    (attributes.vertical ? attributes.subwindows.length : 1));
                break;
            case "top":
            case "bottom":
                totalTargetWidth = totalBoundingBox.maxX - totalBoundingBox.minX;
                totalTargetHeight = defaultWindowHeight * (attributes.vertical ? attributes.subwindows.length : 1);
                horizontalWindowCount = windowCount;
                break;
            case "left":
            case "right":
                totalTargetWidth = defaultWindowWidth * (attributes.vertical ? 1 : attributes.subwindows.length);
                totalTargetHeight = totalBoundingBox.maxY - totalBoundingBox.minY;
                verticalWindowCount = windowCount;
                break;
            default:
                throw new Error(`WindowDataFactory.createWindowData - Unsupported side ${side}`);
        }

        if (forcedSize) {
            totalTargetWidth = forcedSize.width;
            totalTargetHeight = forcedSize.height;
        }

        let targetOrigin: Point;

        switch (side) {
            case "center":
                targetOrigin = new Point(0, 0);
                break;
            case "top":
                targetOrigin = new Point(totalBoundingBox.minX, totalBoundingBox.minY - totalTargetHeight);
                break;
            case "bottom":
                targetOrigin = new Point(totalBoundingBox.minX, totalBoundingBox.maxY);
                break;
            case "left":
                targetOrigin = new Point(totalBoundingBox.minX - totalTargetWidth, totalBoundingBox.minY);
                break;
            case "right":
                targetOrigin = new Point(totalBoundingBox.maxX, totalBoundingBox.minY);
                break;
            default:
                throw new Error(`WindowDataFactory.createWindowData - Unsupported side ${side}`);
        }

        let results: MinMaxXY[] = [];
        let xPositions = this.divideIntoEqualParts(targetOrigin.x, targetOrigin.x + totalTargetWidth, horizontalWindowCount);
        let yPositions = this.divideIntoEqualParts(targetOrigin.y, targetOrigin.y + totalTargetHeight, verticalWindowCount);

        for (let x = 1; x < xPositions.length; x++) {
            for (let y = 1; y < yPositions.length; y++) {
                results.push(new MinMaxXY(xPositions[x - 1], xPositions[x], yPositions[y - 1], yPositions[y]));
            }
        }

        return results;
    }

    private static getSubwindowSizes(attributes: WindowAttributes, windowBounds: MinMaxXY): MinMaxXY[] {
        let results: MinMaxXY[] = [];
        let xPositions = this.divideIntoEqualParts(windowBounds.minX, windowBounds.maxX,
            attributes.vertical ? 1 : attributes.subwindows.length);
        let yPositions = this.divideIntoEqualParts(windowBounds.minY, windowBounds.maxY,
            attributes.vertical ? attributes.subwindows.length : 1);

        for (let x = 1; x < xPositions.length; x++) {
            for (let y = 1; y < yPositions.length; y++) {
                results.push(new MinMaxXY(xPositions[x - 1], xPositions[x], yPositions[y - 1], yPositions[y]));
            }
        }

        return results;
    }

    // divide range of integers into as equal parts as possible
    // eg. [0, 1000] divided into 3 parts would yield [0, 334, 667, 1000]
    private static divideIntoEqualParts(min: number, max: number, partsCount: number) {
        let totalNumber = max - min;
        let quotient = Math.floor(totalNumber / partsCount);
        let remainder = totalNumber % partsCount;
        let results = [min];
        let previous = min;
        for (let i = 0; i < partsCount; i++) {
            let current = previous + quotient;
            if (remainder > 0) {
                current++;
                remainder--;
            }
            results.push(current);
            previous = current;
        }
        return results;
    }

    private static initSubwindow(size: MinMaxXY, subwindowAttributes: SubwindowAttributes,
        defaultsData?: WindowSystemDefaults): SubWindowData {
        let newSubWindow = new SubWindowData();
        newSubWindow.typeCode = subwindowAttributes.type;
        newSubWindow.isLeading = subwindowAttributes.isLeading;
        newSubWindow.configurableAddonIds = [];
        newSubWindow.handle = undefined;
        newSubWindow.mullions = [];

        if (newSubWindow.typeCode !== SubWindowTypeCode.F) {
            newSubWindow.wing = new Wing();
        }

        newSubWindow.points = [
            size.minX, size.minY, size.maxX, size.minY, size.maxX, size.maxY, size.minX, size.maxY
        ];

        newSubWindow.cut = newSubWindow.points;
        newSubWindow.neighboringFramesLengths = [];

        let newArea = WindowDataFactory.createArea(defaultsData);
        newArea.width = size.maxX - size.minX;
        newArea.height = size.maxY - size.minY;
        newSubWindow.areasSpecification = [newArea];
        newSubWindow.ventilator.addonId = undefined;
        newSubWindow.drip.addonId = undefined;
        newSubWindow.coupler.addonId = undefined;

        return newSubWindow;
    }

    public static createArea(defaultsData?: WindowSystemDefaults): AreaSpecification {
        let filling = new Filling();
        let glazing = new Glazing();
        let glazingBead = new GlazingBead();
        if (defaultsData != null) {
            filling.type = defaultsData.fillingType;
            filling.fillingId = defaultsData.fillingId;
            filling.decorativeFillingId = defaultsData.decorativeFillingId;
            filling.type = defaultsData.fillingType;
            filling.coreColorId = defaultsData.coreColorId;
            filling.externalColorId = defaultsData.externalColorId;
            filling.internalColorId = defaultsData.internalColorId;

            glazing.glazingGlassQuantity = defaultsData.glazingGlassQn;
            glazing.glass1id = defaultsData.glass1id;
            glazing.glass2id = defaultsData.glass2id;
            glazing.glass3id = defaultsData.glass3id;
            glazing.glass4id = defaultsData.glass4id;
            glazing.frame1id = defaultsData.frame1id;
            glazing.frame2id = defaultsData.frame2id;
            glazing.frame3id = defaultsData.frame3id;

            glazingBead.id = defaultsData.glazingBeadId;
        }
        const area = new AreaSpecification(filling, glazing, glazingBead);
        if (defaultsData != null) {
            area.glazingCategoryId = defaultsData.glazingCategoryId;
            area.glazingFrameCategoryId = defaultsData.glazingFrameCategoryId;
            area.glazingPackageId = defaultsData.glazingPackageId;
        }
        return area;
    }

    public static getDefaultWidth(windowSystem: WindowSystemInterface, elliptical?: boolean) {
        let width = windowSystem != undefined ? windowSystem.defaultWidth : WindowDataFactory.DEFAULT_WINDOW_WIDTH;
        if (elliptical) {
            width += WindowDataFactory.DEFAULT_ELLIPTICAL_WINDOW_WIDTH_INCREASER;
        }
        return width;
    }

    public static getDefaultHeight(windowSystem: WindowSystemInterface, elliptical?: boolean) {
        let height = windowSystem != undefined ? windowSystem.defaultHeight : WindowDataFactory.DEFAULT_WINDOW_HEIGHT;
        if (elliptical) {
            height += WindowDataFactory.DEFAULT_ELLIPTICAL_WINDOW_HEIGHT_INCREASER;
        }
        return height;
    }

    public static fillWindowWithCommonData(window: WindowData, commonData: WindowCommonData): void {
        window.subWindows.forEach(subwindow => subwindow.areasSpecification.forEach(area => {
            area.filling.type = commonData.fillingType;
            area.filling.width = commonData.fillingWidth;
            area.filling.fillingId = commonData.fillingId;
            area.filling.decorativeFillingId = commonData.decorativeFillingId;
            area.filling.externalColorId = commonData.externalColorId;
            area.filling.internalColorId = commonData.internalColorId;
            area.filling.coreColorId = commonData.coreColorId;
            area.glazing.glazingGlassQuantity = commonData.glazingGlassQuantity;
            area.glazing.glass1id = commonData.glass1id;
            area.glazing.glass2id = commonData.glass2id;
            area.glazing.glass3id = commonData.glass3id;
            area.glazing.glass4id = commonData.glass4id;
            area.glazing.frame1id = commonData.frame1id;
            area.glazing.frame2id = commonData.frame2id;
            area.glazing.frame3id = commonData.frame3id;
            area.glazingBead.id = commonData.glazingBeadId;
            area.glazingCategoryId = commonData.glazingCategoryId;
            area.glazingFrameCategoryId = commonData.glazingFrameCategoryId;
            area.glazingPackageId = commonData.glazingPackageId;
        }));
    }
}
