<div class="new-form-600">
    <div class="new-form-row">
        <app-select inputId="catalogItemType"
                    label="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.CATALOG_ITEM_TYPE' | translate }}"
                    [(validationMessageKey)]="validationErrors['catalogItemType']" [options]="catalogItemTypes"
                    [required]="true" [ngModel]="discontinuedCatalogItemInfo.catalogItemType"
                    (ngModelChange)="handleCatalogItemTypeChange($event)" [disabled]="!editable"></app-select>

    </div>
    <div *ngIf="isNew" class="new-form-row">
        <div class="new-form-field">
            <div class="new-form-field-label-container">
                <div>
                    <label for="catalogItemId">{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.CATALOG_ITEM' | translate }}*</label>
                </div>
                <div *ngIf="validationErrors['catalogItemId'] != undefined"
                     class="new-form-field-label-error-spacer"></div>
            </div>
            <div class="new-form-field-input-container">
                <p-multiSelect [options]="catalogItemsOptions"
                               (onChange)="handleSelectedCatalogItemsChange($event.value)" defaultLabel="..."
                               appendTo="body" [displaySelectedLabel]="true"
                               [(ngModel)]="selectedCatalogItems"></p-multiSelect>
                <ng-container *ngIf="validationErrors['catalogItemId'] != undefined">
                    <span class="new-form-field-error-icon new-form-field-error-icon-select material-icons">error</span>
                    <div class="new-form-field-error-message">
                        {{ validationErrors['catalogItemId'] | translate }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div *ngIf="!isNew" class="new-form-row">
        <app-select inputId="catalogItem" label="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.CATALOG_ITEM' | translate }}"
                    [(validationMessageKey)]="validationErrors['catalogItemId']" [options]="catalogItemsOptions"
                    [required]="true" [(ngModel)]="discontinuedCatalogItemInfo.catalogItemId" [disabled]="!editable"></app-select>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="unavailableFrom"
                      label="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.UNAVAILABLE_FROM' | translate }}"
                      [(validationMessageKey)]="validationErrors['unavailableFrom']" [inline]="true" [utc]="true"
                      [minDate]="minDate" [required]="true" [disabled]="!editable"
                      [ngModel]="discontinuedCatalogItemInfo.unavailableFrom"
                      (ngModelChange)="unavailableFromChange($event)"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="nameModificationDate"
                      label="{{ 'DISCONTINUED_CATALOG_ITEM_INFO.FORM.NAME_MODIFICATION_DATE' | translate }}"
                      [(validationMessageKey)]="validationErrors['nameModificationDate']" [inline]="true" [utc]="true"
                      [minDate]="minDate" [maxDate]="discontinuedCatalogItemInfo.unavailableFrom" [required]="true"
                      [(ngModel)]="discontinuedCatalogItemInfo.nameModificationDate" [disabled]="!editable"></app-calendar>
    </div>
</div>
