export enum ConfigurableAddonDefinitionType {
    GATE_GARAGE = "GATE_GARAGE",
    GATE_INDUSTRIAL = "GATE_INDUSTRIAL",

    PLEAT_AB_22 = "PLEAT_AB_22",

    SHUTTER_HORIZONTAL_ALU = "SHUTTER_HORIZONTAL_ALU",
    SHUTTER_HORIZONTAL_ALU_ISOTRA = "SHUTTER_HORIZONTAL_ALU_ISOTRA",
    SHUTTER_HORIZONTAL_ALU_NORDICA = "SHUTTER_HORIZONTAL_ALU_NORDICA",

    SHUTTER_VERTICAL_STANDARD = "SHUTTER_VERTICAL_STANDARD",
    SHUTTER_VERTICAL_EXCLUSIVE = "SHUTTER_VERTICAL_EXCLUSIVE",

    SHUTTER_PANEL_TRACK = "SHUTTER_PANEL_TRACK",

    BLIND_FREE_HANGING = "BLIND_FREE_HANGING",

    BLIND_FREE_HANGING_DAY_NIGHT_TRIOLA_2 = "BLIND_FREE_HANGING_DAY_NIGHT_TRIOLA_2",
    BLIND_FREE_HANGING_DAY_NIGHT_PRESTO = "BLIND_FREE_HANGING_DAY_NIGHT_PRESTO",

    BLIND_CASSETTE_PASSIONATA = "BLIND_CASSETTE_PASSIONATA",
    BLIND_CASSETTE_DAY_NIGHT_PASSIONATA = "BLIND_CASSETTE_DAY_NIGHT_PASSIONATA",
    BLIND_CASSETTE_PASSIONATA_PLUS = "BLIND_CASSETTE_PASSIONATA_PLUS",

    BLIND_CASSETTE_DAY_NIGHT_PASSIONATA_PLUS = "BLIND_CASSETTE_DAY_NIGHT_PASSIONATA_PLUS",
    BLIND_CASSETTE_SUITA = "BLIND_CASSETTE_SUITA",
    BLIND_CASSETTE_DAY_NIGHT_SUITA = "BLIND_CASSETTE_DAY_NIGHT_SUITA",

    MOSQUITO_NET_FLANGED = "MOSQUITO_NET_FLANGED",
    MOSQUITO_NET_ROLLER_VERTICA_VERTICAL = "MOSQUITO_NET_ROLLER_VERTICA_VERTICAL",
    MOSQUITO_NET_ROLLER_VERTICA_HORIZONTAL = "MOSQUITO_NET_ROLLER_VERTICA_HORIZONTAL",
    MOSQUITO_NET_ROLLER_VERTICA_HORIZONTAL_DOUBLE = "MOSQUITO_NET_ROLLER_VERTICA_HORIZONTAL_DOUBLE",
    MOSQUITO_NET_DOOR = "MOSQUITO_NET_DOOR",
}

export function configurableAddonDefinitionTypeToTranslationKey(value: ConfigurableAddonDefinitionType): string {
    return 'ConfigAddonDefinitions.' + ConfigurableAddonDefinitionType[value] + '.configAddonName';
}
