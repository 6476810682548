import {Offer} from './offer';

export interface OfferTableColumns {
    status?: string;
    buyVenskaGrossVal?: number;
    buyVenskaNetVal?: number;
    buyGrossVal?: number;
    buyNetVal?: number;
    sellGrossVal?: number;
    sellNetVal?: number;
    ownAddonsGrossCost?: number;
    ownAddonsNetCost?: number;
    assemblyGrossCost?: number;
    assemblyNetCost?: number;
    transportGrossCost?: number;
    transportNetCost?: number;
    vatBuy?: number;
    vatSell?: number;
    merchantName?: string;
    clientManagerName?: string;
    productionOrderNumber?: string;
}

export function prepareOfferOrderForCsvExport(item: Offer,
                                              statusMapper: (item: any, attribute: string) => string,
                                              productionOrders: string[]): Offer & OfferTableColumns {
    const item2: Offer & OfferTableColumns = {...<any>item};
    item2.status = <any>statusMapper(item, "status");
    item2.buyVenskaGrossVal = item.venskaBuyPrice.grossValue;
    item2.buyVenskaNetVal = item.venskaBuyPrice.netValue;
    item2.buyGrossVal = item.buyPrice ? item.buyPrice.grossValue : null;
    item2.buyNetVal = item.buyPrice ? item.buyPrice.netValue : null;
    item2.sellGrossVal = item.sellPrice.grossValue;
    item2.sellNetVal = item.sellPrice.netValue;
    item2.ownAddonsGrossCost = item.ownAddonsCost.grossValue;
    item2.ownAddonsNetCost = item.ownAddonsCost.netValue;
    item2.assemblyGrossCost = item.assemblyCost.grossValue;
    item2.assemblyNetCost = item.assemblyCost.netValue;
    item2.transportGrossCost = item.transportCost.grossValue;
    item2.transportNetCost = item.transportCost.netValue;
    item2.vatBuy = item.buyPrice ? item.buyPrice.vatPercent : null;
    item2.vatSell = item.sellPrice.vatPercent;
    item2.merchantName = item.merchantFirstName + ' ' + item.merchantLastName;
    item2.clientManagerName = item.clientManagerFirstName + ' ' + item.clientManagerLastName;
    item2.productionOrderNumber = productionOrders && productionOrders.join();
    item2.clientName = item.sellerClientId ? item.sellerClientName : item.clientName;
    item2.clientEmail = item.sellerClientId ? item.sellerClientEmail : item.clientEmail;
    item2.clientIdentifier = item.sellerClientId ? item.sellerClientIdentifier : item.clientIdentifier;

    return item2;
}
