<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.SELLER_SETTINGS' | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-600">
            <div class="new-form-row">
                <!-- align both columns -->
                <h3>&nbsp;</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="name" [(ngModel)]="item.name" label="{{ 'SELLER_SETTINGS.FORM.NAME' | translate }}"
                                [(validationMessageKey)]="validationErrors['name']" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="phoneNumber" [(ngModel)]="item.phoneNumber"
                                label="{{ 'SELLER_SETTINGS.FORM.PHONE_NUMBER' | translate }}"
                                [maxlength]="50"
                                [(validationMessageKey)]="validationErrors['phoneNumber']" [required]="true"></app-input-text>
            </div>
        </div>
        <div class="new-form-600">

            <div class="new-form-row blue-text-button">
                <app-simple-button buttonId="subsystem-address-select-modify"
                                   label="{{ 'SELLER_SETTINGS.FORM.MODIFY_ADDRESS' | translate }}"
                                   (onClick)="showAddressSelectionDialog('modify')"></app-simple-button>
            </div>

            <div class="new-form-row">
                <h3>{{ "SELLER_SETTINGS.FORM.CORRESPONDENCE_ADDRESS" | translate }}</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="correspondenceAddress.street"
                                [ngModel]="correspondenceAddress?.address.street"
                                label="{{ 'SELLER_SETTINGS.FORM.STREET' | translate }}" [required]="true"
                                [disabled]="true" [maxlength]="100"
                                [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="correspondenceAddress.city" [ngModel]="correspondenceAddress?.address.city"
                                label="{{ 'SELLER_SETTINGS.FORM.CITY' | translate }}" [required]="true"
                                [disabled]="true" [maxlength]="50"
                                [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-select inputId="correspondenceAddress.country" [ngModel]="correspondenceAddress?.address.country"
                            label="{{ 'SELLER_SETTINGS.FORM.COUNTRY' | translate }}" [disabled]="true"
                            [options]="countries | async" [required]="true"
                            [hasValidationError]="validationErrors['correspondenceAddress'] != undefined"></app-select>
            </div>

            <div class="new-form-row">
                <app-input-zip inputId="correspondenceAddress.zip" [ngModel]="correspondenceAddress?.address.zip"
                               label="{{ 'SELLER_SETTINGS.FORM.ZIP' | translate }}" [required]="true"
                               [disabled]="true" [country]="correspondenceAddress?.address.country"
                               [(validationMessageKey)]="validationErrors['correspondenceAddress']"></app-input-zip>
            </div>

            <div class="new-form-row blue-text-button">
                <app-simple-button buttonId="seller-settings-address-select-correspondence"
                                   label="{{ 'SELLER_SETTINGS.FORM.SELECT_ADDRESS' | translate }}"
                                   (onClick)="showAddressSelectionDialog('correspondence')"></app-simple-button>
            </div>

            <div class="new-form-row">
                <h3>{{ "SELLER_SETTINGS.FORM.DELIVERY_ADDRESS" | translate }}</h3>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="deliveryStreet" [ngModel]="deliveryAddress?.address.street"
                                label="{{ 'SELLER_SETTINGS.FORM.STREET' | translate }}" [disabled]="true"
                                [maxlength]="100"
                                [hasValidationError]="validationErrors['deliveryAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="deliveryCity" [ngModel]="deliveryAddress?.address.city"
                                label="{{ 'SELLER_SETTINGS.FORM.CITY' | translate }}" [disabled]="true" [maxlength]="50"
                                [hasValidationError]="validationErrors['deliveryAddress'] != undefined"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-select inputId="deliveryCountry" [ngModel]="deliveryAddress?.address.country"
                            label="{{ 'SELLER_SETTINGS.FORM.COUNTRY' | translate }}" [disabled]="true"
                            [options]="countries | async" [allowSelectingNone]="true"
                            [hasValidationError]="validationErrors['deliveryAddress'] != undefined"></app-select>
            </div>

            <div class="new-form-row">
                <app-input-zip inputId="deliveryZip" [ngModel]="deliveryAddress?.address.zip"
                               label="{{ 'SELLER_SETTINGS.FORM.ZIP' | translate }}" [disabled]="true"
                               [country]="deliveryAddress?.address.country"
                               [(validationMessageKey)]="validationErrors['deliveryAddress']"></app-input-zip>
            </div>

            <div class="new-form-row blue-text-button">
                <app-simple-button buttonId="seller-settings-address-select-delivery"
                                   label="{{ 'SELLER_SETTINGS.FORM.SELECT_ADDRESS' | translate }}"
                                   (onClick)="showAddressSelectionDialog('delivery')"></app-simple-button>
            </div>
        </div>
    </div>

    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" icon="check" type="main-action"
                               [size]="40" (onClick)="saveChanges()"></app-simple-button>
        </div>
    </div>

</div>

<app-subsystem-address-selection-dialog *ngIf="addressSelectionDialogType != 'none'" [addresses]="addresses" (addressesChange)="handleAddressesChange($event)"
                                        [visible]="true"
                                        (visibleChange)="handleAddressSelectionVisibilityChange($event)"
                                        [selectedAddress]="addressSelectionDialogType == 'correspondence' ? correspondenceAddress : deliveryAddress"
                                        (selectedAddressChange)="handleAddressSelected($event)" [canEdit]="addressSelectionDialogType === 'modify'"
                                        [selectable]="addressSelectionDialogType != 'modify'"></app-subsystem-address-selection-dialog>
