import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {DeliveryListStatus} from './DeliveryListStatus';
import {Palette} from './palette';

@JsonDeserializable
export class DeliveryList {

    id: number;
    name: string;
    creationDate: Date;
    dispatchDate: Date;
    subsystemId: number;
    subsystemName: string;
    subsystemClientManagerId: number;
    subsystemClientManagerFirstName: string;
    subsystemClientManagerLastName: string;
    subsystemSubClientManagerId: number;
    subsystemSubClientManagerFirstName: string;
    subsystemSubClientManagerLastName: string;
    ownerId: number;
    ownerFirstName: string;
    ownerLastName: string;
    status: DeliveryListStatus;
    palettes: Palette[] = [];

    static fromJSON(jsonObject: any): DeliveryList {
        const deliveryList = new DeliveryList();
        deliveryList.id = jsonObject.id;
        deliveryList.name = jsonObject.name;
        deliveryList.creationDate = new Date(jsonObject.creationDate);
        deliveryList.dispatchDate = new Date(jsonObject.dispatchDate);
        deliveryList.subsystemId = jsonObject.subsystemId;
        deliveryList.subsystemName = jsonObject.subsystemName;
        deliveryList.subsystemClientManagerId = jsonObject.subsystemClientManagerId;
        deliveryList.subsystemClientManagerFirstName = jsonObject.subsystemClientManagerFirstName;
        deliveryList.subsystemClientManagerLastName = jsonObject.subsystemClientManagerLastName;
        deliveryList.subsystemSubClientManagerId = jsonObject.subsystemSubClientManagerId;
        deliveryList.subsystemSubClientManagerFirstName = jsonObject.subsystemSubClientManagerFirstName;
        deliveryList.subsystemSubClientManagerLastName = jsonObject.subsystemSubClientManagerLastName;
        deliveryList.ownerId = jsonObject.ownerId;
        deliveryList.ownerFirstName = jsonObject.ownerFirstName;
        deliveryList.ownerLastName = jsonObject.ownerLastName;
        deliveryList.status = jsonObject.status;
        deliveryList.palettes = (jsonObject.palettes || []).map(Palette.fromJSON);
        return deliveryList;
    }
}
