import {animate, state, style, transition, trigger} from "@angular/animations";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {finalize} from "rxjs/operators";
import {Permissions} from '../../../../../auth/permission.service';
import {UserUiConfigService} from '../../../../../auth/uiconfig/userUiConfig.service';
import {CommonErrorHandler} from "../../../../../common/CommonErrorHandler";
import {DataServiceHelper} from "../../../../../common/dataServiceHelper";
import {ExchangeService} from "../../../../../common/exchange.service";
import {Currencies} from "../../../../../currencies";
import {AddressService} from '../../../../subsystem/address.service';
import {TabularAddress} from '../../../../subsystem/tabularAddress';
import {ShippingSimulationDetailsComponent} from '../shipping-simulation-details/shipping-simulation-details.component';
import {ShippingSimulationOfferPositionModel} from "../shipping-simulation-offer-position.model";
import {ShippingSimulationOfferModel} from "../shipping-simulation-offer.model";
import {ShippingSimulationService} from "../shipping-simulation.service";

@Component({
    selector: 'app-shipping-simulation-sidebar',
    templateUrl: './shipping-simulation-sidebar.component.html',
    styleUrls: [ './shipping-simulation-sidebar.component.css' ],
    animations: [
        trigger('sidebarVisibilityState', [
            state('visible', style({
                width: '512px'
            })),
            state('hidden', style({
                width: '0'
            })),
            transition('visible => hidden', animate('300ms ease-out')),
            transition('hidden => visible', animate('300ms ease-in'))
        ])
    ],
    providers: [DataServiceHelper, AddressService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingSimulationSidebarComponent implements OnInit {

    static readonly VISIBLE_STATE: string = 'visible';
    static readonly HIDDEN_STATE: string = 'hidden';

    offers: ShippingSimulationOfferModel[] = [];
    positionCount = 0;
    visibleState: string = ShippingSimulationSidebarComponent.HIDDEN_STATE;
    visible = false;
    defaultCurrency: string = Currencies.EUR.toString();
    shippingCalculationInProgress: boolean;

    deliveryAddressSelectionDialogVisible: boolean;
    addresses: TabularAddress[];
    customDeliveryAddress: TabularAddress;
    useBigTrucks = false;
    truckSizeError: string;

    @Input()
    currentRoute: string;

    @Input()
    currentRouteParams: any;

    @Output() onHide: EventEmitter<void> = new EventEmitter<void>();
    @Output() onCalculationStart: EventEmitter<void> = new EventEmitter<void>();
    @Output() onCalculationFinish: EventEmitter<void> = new EventEmitter<void>();

    constructor(public shippingSimulationService: ShippingSimulationService,
                private changeDetector: ChangeDetectorRef,
                public translateService: TranslateService,
                private router: Router,
                private errors: CommonErrorHandler,
                private addressService: AddressService,
                public permissions: Permissions,
                private userUiConfigService: UserUiConfigService) {
    }

    ngOnInit(): void {
        this.offers = this.shippingSimulationService.getOffers();
        if (this.shippingSimulationService.checkCalculationFinishedInterval) {
            this.shippingCalculationInProgress = true;
            this.shippingSimulationService.checkCalculationFinishedInterval.pipe(
                finalize(() => {
                    this.refresh(true);
                }))
                .subscribe();
        }
        this.useBigTrucks = this.userUiConfigService.getConfigForTheView('ShippingSimulationDetailsComponent',
            ShippingSimulationDetailsComponent.USE_BIG_TRUCKS_OPTION) || false;
    }

    hide(): void {
        this.visibleState = ShippingSimulationSidebarComponent.HIDDEN_STATE;
        this.visible = false;
        this.deliveryAddressSelectionDialogVisible = false;
        this.changeDetector.markForCheck();
        this.onHide.emit();
    }

    show(): void {
        this.visibleState = ShippingSimulationSidebarComponent.VISIBLE_STATE;
        this.refresh();
    }

    private refresh(resetCalculationInProgress?: boolean): void {
        this.shippingSimulationService.getDraftForCurrentUser().subscribe(() => {
            this.addressService.getAddress(this.shippingSimulationService.shippingSimulation.deliveryAddressId).subscribe(
                data => {
                    this.customDeliveryAddress = data;
                    this.changeDetector.markForCheck();
                }
            );
            this.shippingSimulationService.getAllSimulationPositions(this.shippingSimulationService.shippingSimulation.id).subscribe({
                next: () => {
                    this.offers = this.shippingSimulationService.getOffers();
                    this.positionCount = this.shippingSimulationService.getPositionCount();
                    if (resetCalculationInProgress) {
                        this.shippingCalculationInProgress = false;
                    }
                    this.changeDetector.markForCheck();
                }, error: (error) => this.errors.handle(error)
            });
        });
    }

    removeOffer(offer: ShippingSimulationOfferModel): void {
        if (!this.shippingCalculationInProgress) {
            this.shippingSimulationService.removeOfferFromDraft(offer.id).subscribe({
                next: () => this.refresh(),
                error: (error) => this.errors.handle(error)
            });
        }
    }

    removePosition(position: ShippingSimulationOfferPositionModel): void {
        if (!this.shippingCalculationInProgress) {
            this.shippingSimulationService.removeSimulationPositionsFromDraft([position.shippingPositionId]).subscribe({
                next: () => this.refresh(),
                error: (error) => this.errors.handle(error)
            });
        }
    }

    removeAll(): void {
        if (!this.shippingCalculationInProgress) {
            this.shippingSimulationService.removeAllPositionsFromSimulation(this.shippingSimulationService.shippingSimulation.id).subscribe({
                next: () => this.refresh(),
                error: (error) => this.errors.handle(error)
            });
        }
    }

    showDeliveryAddressSelectionDialog() {
        if (this.addresses == undefined) {
            this.addressService.getUsableAddresses(this.shippingSimulationService.shippingSimulation.subsystemId).subscribe(addresses => {
                this.addresses = addresses;
                if (this.shippingSimulationService.shippingSimulation.deliveryAddressId != undefined) {
                    this.customDeliveryAddress = this.addresses
                        .find(address => address.id === this.shippingSimulationService.shippingSimulation.deliveryAddressId);
                }
                this.deliveryAddressSelectionDialogVisible = true;
                this.changeDetector.markForCheck();
            });
            return;
        }

        this.deliveryAddressSelectionDialogVisible = true;
        this.changeDetector.markForCheck();
    }

    handleDeliveryAddressChanged(address: TabularAddress): void {
        this.shippingSimulationService.setDeliveryAddress(address).subscribe({
            next: () => {
                this.customDeliveryAddress = address;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    switchPositionDisplay(offer: ShippingSimulationOfferModel): void {
        this.shippingSimulationService.switchPositionDisplay(offer.id);
        this.changeDetector.markForCheck();
    }

    recalculateTransport(experimental?: boolean): void {
        if (this.positionCount && !this.shippingCalculationInProgress) {
            this.onCalculationStart.emit();

            this.shippingCalculationInProgress = true;
            this.shippingSimulationService.calculateTransport(this.useBigTrucks, experimental).subscribe({
                next: () => {
                    this.refresh();

                    this.shippingSimulationService.createCheckCalculationFinishedInterval(this.shippingSimulationService.shippingSimulation.id)
                        .subscribe({
                            complete: () => {
                                this.shippingCalculationInProgress = false;
                                if (this.showCalculationErrorOnSidebar(this.shippingSimulationService.shippingSimulation.calculationFailedCode)) {
                                    this.truckSizeError = this.shippingSimulationService.shippingSimulation.calculationFailedCode;
                                }
                                this.onCalculationFinish.emit();
                                this.changeDetector.markForCheck();
                            },
                            error: error => {
                                this.shippingCalculationInProgress = false;
                                this.errors.handle(error);
                            }
                        });
                },
                error: error => {
                    this.shippingCalculationInProgress = false;
                    this.onCalculationFinish.emit();
                    this.errors.handle(error);
                }
            });
        }
    }

    sidebarAnimationFinished(): void {
        if (this.visibleState === ShippingSimulationSidebarComponent.VISIBLE_STATE) {
            this.visible = true;
            this.changeDetector.markForCheck();
        }
    }

    navigateToSimulation(): void {
        if (this.positionCount && !this.shippingCalculationInProgress) {
            this.router.navigate(["/features/shipping-simulation-details",
                Object.assign({previousRoute: this.currentRoute}, this.currentRouteParams || {})]);
        }
    }

    formatSimulationPrice(): string {
        return this.shippingSimulationService.shippingSimulation.calculationResult
            ? ExchangeService.formatPriceInCurrency('' + this.shippingSimulationService.shippingSimulation.price, this.defaultCurrency)
            : '...';
    }

    handleUseBigTrucksChange(useBigTrucks: boolean): void {
        this.useBigTrucks = useBigTrucks;
        this.userUiConfigService.saveConfigForTheView('ShippingSimulationDetailsComponent',
            ShippingSimulationDetailsComponent.USE_BIG_TRUCKS_OPTION, useBigTrucks);
    }

    private showCalculationErrorOnSidebar(errorCode: string): boolean {
        return errorCode == undefined || errorCode === 'error.shipping.noTruckLargeEnoughException';
    }
}
