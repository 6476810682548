import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {Permissions} from '../../../../../../auth/permission.service';
import {CommonErrorHandler} from '../../../../../../common/CommonErrorHandler';
import {ExchangeService} from '../../../../../../common/exchange.service';
import {Currencies} from '../../../../../../currencies';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {Offer} from '../../../../offer';
import {OffersService} from '../../../../offer-service';

@Component({
    selector: 'app-offer-summary-dialog',
    templateUrl: './summary-dialog.component.html',
    styleUrls: ['./summary-dialog.component.css', '../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryDialogComponent implements AfterViewInit {

    @Input()
    offer: Offer;

    @Input()
    selectedCurrency: Currencies;

    @Output()
    onClose: EventEmitter<any> = new EventEmitter();

    summary: { label: string, value: number }[];

    dataInitialized = false;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private exchangeService: ExchangeService,
                private permissions: Permissions,
                private offerService: OffersService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler) {
    }

    ngAfterViewInit(): void {
        this.loadOfferSummary();
    }

    private loadOfferSummary() {
        this.offerService.getItem(this.offer.id).subscribe({
            next: offer => {
                this.summary = [];
                if (!this.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
                    this.summary.push({
                        label: 'OFFER.POSITIONS.FORM.PRICE_BUY_NET',
                        value: offer.buyPrice.netValue
                    }, {
                        label: 'OFFER.POSITIONS.FORM.PRICE_BUY_GROSS',
                        value: offer.buyPrice.grossValue
                    });
                }

                this.summary.push({
                    label: 'OFFER.POSITIONS.FORM.PRICE_SELL_NET',
                    value: offer.sellPrice.netValue
                }, {
                    label: 'OFFER.POSITIONS.FORM.PRICE_SELL_GROSS',
                    value: offer.sellPrice.grossValue
                });

                if (this.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})
                    && offer.retailSellPrice != undefined && offer.retailSellPrice.netValue != undefined) {
                    this.summary.push({
                        label: 'OFFER.POSITIONS.FORM.RETAIL_VALUE_SELL_NET',
                        value: offer.retailSellPrice.netValue
                    }, {
                        label: 'OFFER.POSITIONS.FORM.RETAIL_VALUE_SELL_GROSS',
                        value: offer.retailSellPrice.grossValue
                    });
                }

                if (this.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})) {
                    this.summary.push({
                        label: 'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_NET',
                        value: offer.venskaBuyPrice.netValue
                    }, {
                        label: 'OFFER.POSITIONS.FORM.VALUE_BUY_VENSKA_GROSS',
                        value: offer.venskaBuyPrice.grossValue
                    }, {
                        label: 'OFFER.POSITIONS.FORM.NET_INCOME',
                        value: offer.netVenskaIncome
                    });
                } else {
                    this.summary.push({
                        label: 'OFFER.POSITIONS.FORM.NET_INCOME',
                        value: offer.netIncome
                    });
                    if (this.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && offer.netRetailIncome != undefined) {
                        this.summary.push({
                            label: 'OFFER.POSITIONS.FORM.RETAIL_NET_INCOME',
                            value: offer.netRetailIncome
                        });
                    }
                }

                this.dataInitialized = true;
                this.changeDetector.markForCheck();
            },
            error: (error) => this.errors.handle(error)
        });
    }

    formatPriceInSelectedCurrency(price: number): string {
        if (price == null) {
            return '';
        }
        if (this.selectedCurrency === this.offer.currency) {
            return this.formatPriceInOfferCurrency(price);
        }
        return ExchangeService.formatPriceInCurrency(this.getPriceInCurrency(price, this.selectedCurrency), this.selectedCurrency);
    }

    formatPriceInOfferCurrency(price: number): string {
        let exchangeRate = this.offer.subsystemManualExchangeRate ? this.offer.subsystemManualExchangeRate : this.offer.exchangeRate;
        return ExchangeService.formatPriceInCurrency(
            ExchangeService.getPriceInDefaultCurrency(price, exchangeRate), this.offer.currency);
    }

    private getPriceInCurrency(price: number, currency: Currencies): string {
        return this.exchangeService.getPLNPriceInCurrency(price, currency);
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    close(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }
}
