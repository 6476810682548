import * as _ from 'underscore';
import {AreaSpecification} from "../drawing-data/AreaSpecification";
import {CrossGrillAngled} from "../drawing-data/CrossGrillAngled";
import {CrossGrillSimple} from "../drawing-data/CrossGrillSimple";
import {DrawingData} from "../drawing-data/drawing-data";
import {FieldWithPosition} from "../drawing-data/FieldWithPosition";
import {Grill} from "../drawing-data/Grill";
import {GrillGrid} from "../drawing-data/GrillGrid";
import {GrillGridWithRhombusOnIntersections} from "../drawing-data/GrillGridWithRhombusOnIntersections";
import {GrillSegment} from "../drawing-data/GrillSegment";
import {GrillType} from "../drawing-data/GrillType";
import {LineGrill} from "../drawing-data/LineGrill";
import {LineGrillSegment} from "../drawing-data/LineGrillSegment";
import {Mullion} from "../drawing-data/Mullion";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";
import {PricingGrillSegment} from '../drawing-data/PricingGrillSegment';
import {SubWindowData} from "../drawing-data/SubWindowData";
import {ErrorNames} from "./ErrorNames";
import {FloatOps} from "./float-ops";
import {RandomIdGenerator} from "./RandomIdGenerator";
import {GrillTypes} from "../enums/GrillTypes";
import {DrawingUtil} from "../drawing-util";

export class GrillHelper {

    private static isDependantOn(toCheck: Grill, grill: Grill): boolean {
        if (toCheck.type === GrillType.LINE_GRILL) {
            return toCheck.positions.some(
                p => p.type === PositionReferenceType.GRILL && grill.drawingSegments.find(s => s.id === p.id) !==
                    undefined);
        }
        let field: FieldWithPosition = GrillHelper.expectSingleParentField(toCheck);
        return field.grillSegmentIds.some(id => GrillHelper.findGrillBySegmentId([grill], id) !== undefined);
    }

    static canDependOn(toCheck: Grill, other: Grill) {
        return toCheck.constructionType  === GrillTypes.INNER || toCheck.constructionType === other.constructionType;
    }

    public static isDeletable(grill: Grill, area: AreaSpecification): boolean {
        return !area.grills.some(g => GrillHelper.isDependantOn(g, grill));
    }

    public static getExpectedLineSegment(grill: Grill) {
        return this.expectLineSegment(grill.drawingSegments[0]);
    }

    public static expectLineSegment(segment: GrillSegment) {
        if (GrillSegment.isLine(segment)) {
            return segment;
        } else {
            let err = new Error("Unsupported segment type: " + segment.type);
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
    }

    public static expectPricingSegment(segment: GrillSegment): PricingGrillSegment {
        if (GrillSegment.isPricing(segment)) {
            return segment;
        } else {
            let err = new Error("Unsupported segment type: " + segment.type);
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
    }

    public static isSegmentPerpendicular(segment: LineGrillSegment, toVertical: boolean): boolean {
        return FloatOps.eq(segment.points[toVertical ? 0 : 1], segment.points[toVertical ? 2 : 3]);
    }

    public static isPerpendicular(grill: Grill, toVertical: boolean): boolean {
        let segment = GrillHelper.getExpectedLineSegment(grill);
        return GrillHelper.isSegmentPerpendicular(segment, toVertical);
    }

    public static isVertical(grill: Grill): boolean {
        return GrillHelper.isPerpendicular(grill, true);
    }

    public static isHorizontal(grill: Grill): boolean {
        return GrillHelper.isPerpendicular(grill, false);
    }

    public static isSegmentVertical(segment: LineGrillSegment): boolean {
        return GrillHelper.isSegmentPerpendicular(segment, true);
    }

    public static isSegmentHorizontal(segment: LineGrillSegment): boolean {
        return GrillHelper.isSegmentPerpendicular(segment, false);
    }

    public static initSegmentsIfNeeded(grill: Grill) {
        grill.drawingSegments = grill.drawingSegments || [];
        grill.pricingSegments = grill.pricingSegments || [];
    }

    public static areaHasGrills(area: AreaSpecification): boolean {
        return area.grills && area.grills.length > 0;
    }

    public static subwindowContainsGrills(subwindow: SubWindowData): boolean {
        return subwindow.areasSpecification.some(area => GrillHelper.areaHasGrills(area));
    }

    public static containsHorizontalSegments(grill: Grill): boolean {
        return grill.drawingSegments.map(s => GrillHelper.expectLineSegment(s)).some(s => GrillHelper.isSegmentHorizontal(s));
    }

    public static constructionContainsHorizontalGrills(data: DrawingData): boolean {
        return data.windows.some(w => w.subWindows.some(
            sw => sw.areasSpecification.some(area => area.grills.some(grill => GrillHelper.containsHorizontalSegments(grill)))));
    }

    public static expectSingleParentField(g: Grill): FieldWithPosition {
        if (g.parentFields == undefined || g.parentFields.length > 1) {
            let err = new Error("Expecting single clip path for grill grid.");
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
        return g.parentFields[0];
    }

    private static shiftGrillSegmentIds(newIds: { [id: number]: number }, data: DrawingData): void {
        let getNewId = (oldId: number) => {
            return newIds[oldId];
        };
        let assignNewId = <T extends {id: number}>(objects: T[]) => {
            objects.forEach(obj => obj.id = getNewId(obj.id));
        };
        let assignNewIdToGrills = (grills: Grill[]) => {
            grills.forEach(g => {
                assignNewId(g.positions.filter(p => p.type === PositionReferenceType.MULLION || p.type === PositionReferenceType.GRILL));
                assignNewId(g.drawingSegments);
            });
        };
        data.windows.forEach(w => {
            w.subWindows.forEach(s => {
                assignNewIdToGrills(s.mullions);
                s.areasSpecification.forEach(a => {
                    assignNewId(a.definingMullions);
                    assignNewIdToGrills(a.grills);
                });
            });
        });
    }

    public static normalizeSegmentGeneratedIds(data: DrawingData) {
        let newIds: { [id: number]: number } = {};
        let grillsAndMullions: Grill[] = _.flatten(
            data.windows.map(w => w.subWindows.map(s => [s.mullions, s.areasSpecification.map(a => a.grills)])));
        let segments: GrillSegment[] = _.flatten(grillsAndMullions.map(g => g.drawingSegments));
        segments.forEach((s, i) => newIds[s.id] = -i - 1);
        GrillHelper.shiftGrillSegmentIds(newIds, data);
    }

    public static findGrillBySegmentId(grills: Grill[], grillSegmentId: number): Grill {
        for (let grill of grills) {
            for (let grillSegment of grill.drawingSegments) {
                if (grillSegment.id === grillSegmentId) {
                    return grill;
                }
            }
            for (let grillSegment of grill.pricingSegments) {
                if (grillSegment.id === grillSegmentId) {
                    return grill;
                }
            }
        }
        console.warn("Cannot find grill by segment id:", grillSegmentId);
        return undefined;
    }

    public static findGrillSegmentById(grills: Grill[], grillSegmentId: number): GrillSegment {
        let grill = GrillHelper.findGrillBySegmentId(grills, grillSegmentId);
        if (grill != undefined) {
            return grill.drawingSegments.find(grillSegment => grillSegment.id === grillSegmentId) ||
                grill.pricingSegments.find(grillSegment => grillSegment.id === grillSegmentId);
        }
        return undefined;
    }

    public static checkAngularity(grill: Grill, addNewGrillMode = false): void {
        if (!grill.canBeAngled && GrillHelper.isDrawnAtAngle(grill)) {
            let err = new Error("Resizing would result in bending perpendicular only grills");
            if (grill.type === GrillType.MULLION) {
                err.name = addNewGrillMode ? ErrorNames.MULLION_ILLEGAL_POSITION : ErrorNames.MULLION_CANNOT_BEND_GRILL_ON_RESIZE;
            } else {
                err.name = addNewGrillMode ? ErrorNames.GRILL_ILLEGAL_POSITION : ErrorNames.GRILL_CANNOT_BEND_GRILL_ON_RESIZE;
            }
            throw err;
        }
    }

    public static isDrawnAtAngle(grill: Grill): boolean {
        return grill.pricingSegments.some(s => s.angled);
    }

    public static newGrill(mode: GrillType): Grill {
        switch (mode) {
            case GrillType.MULLION:
                return new Mullion();
            case GrillType.LINE_GRILL:
                return new LineGrill();
            case GrillType.GRID_CROSS_ANGLED:
                return new CrossGrillAngled();
            case GrillType.GRID_CROSS_SIMPLE:
                return new CrossGrillSimple();
            case GrillType.GRID_RHOMBUS:
                return new GrillGridWithRhombusOnIntersections();
            case GrillType.GRID_STANDARD:
                return new GrillGrid();
            default:
                let err = new Error("Unsupported grill type");
                err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
                throw err;
        }
    }

    public static cloneGrill(grill: Grill): Grill {
        let clone = JSON.parse(JSON.stringify(grill));
        clone.generatedId = RandomIdGenerator.generate();
        return clone;
    }

    public static setSizeForRhombusGrill(grill: Grill, size?: number): void {
        if (grill.type !== GrillType.GRID_RHOMBUS) {
            let err = new Error("GrillHelper.setSizeForRhombusGrill: Unsupported grill type");
            err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
            throw err;
        }
        let rhombus = grill as GrillGridWithRhombusOnIntersections;
        rhombus.rhombusWidth = size || rhombus.rhombusWidth ;
        rhombus.rhombusHeight = size || rhombus.rhombusWidth;
    }

    public static isGrid(grill: Grill): boolean {
        switch (grill.type) {
            case GrillType.LINE_GRILL:
            case GrillType.MULLION:
                return false;
            case GrillType.GRID_STANDARD:
            case GrillType.GRID_CROSS_SIMPLE:
            case GrillType.GRID_CROSS_ANGLED:
            case GrillType.GRID_RHOMBUS:
                return true;
            default:
                let err = new Error("Unsupported grill type");
                err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
                throw err;
        }
    }

    public static trimGrillSegmentsToPolygon(grill: Grill, polygon: number[]): void {
        grill.drawingSegments = grill.drawingSegments.map(s => {
            let segment = GrillHelper.expectLineSegment(s);
            let intersections = DrawingUtil.polygonLineIntersections(polygon, segment.points);
            switch (intersections.length) {
                case 0:
                    return segment;
                case 1:
                    if (DrawingUtil.isPointInPolygon([segment.points[0], segment.points[1]], polygon)) {
                        return new LineGrillSegment([segment.points[0], segment.points[1], intersections[0].x, intersections[0].y]);
                    } else {
                        return new LineGrillSegment([segment.points[2], segment.points[3], intersections[0].x, intersections[0].y]);
                    }
                case 2:
                    return new LineGrillSegment([intersections[0].x, intersections[0].y, intersections[1].x, intersections[1].y]);
                default:
                    throw new Error('GrillSegmentGenerator: Wrong intersection count');
            }
        });
    }
}
