import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, tap} from 'rxjs/operators';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy {

    searchedValue: string;
    private searchedValueChange = new Subject<string>();
    private subscription: Subscription;
    private changedSinceLastFocus = false;

    @Input() component: string;
    @Output()
    readonly searched = new EventEmitter<string>();

    constructor(private route: ActivatedRoute) {
    }

    ngOnInit(): void {
        if (this.route.snapshot.paramMap.get('component') === this.component) {
            this.searchedValue = this.route.snapshot.queryParamMap.get('searchedValue');
        }
        this.subscription = this.searchedValueChange
            .pipe(
                tap(searchedValue => this.searchedValue = searchedValue),
                debounceTime(1000),
                distinctUntilChanged())
            .subscribe(search => this.searched.emit(search));
    }

    ngOnDestroy(): void {
        if (this.subscription != undefined) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
    }

    handleSearchedValueChange(searchedValue: string): void {
        this.changedSinceLastFocus = true;
        this.searchedValueChange.next(searchedValue);
    }

    handleFocus(): void {
        this.changedSinceLastFocus = false;
        if (this.route.snapshot.paramMap.get('component') !== this.component) {
            this.searched.emit(this.searchedValue);
        }
    }

    handleBlur(): void {
        if (!this.changedSinceLastFocus) {
            this.searched.emit(this.searchedValue);
        } else {
            this.searchedValueChange.next(this.searchedValue);
        }
    }
}
