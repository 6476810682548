<div class="list-content settings settings-single-column">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_ADDONS.LIST' | translate }}</div>
    </div>

    <p-dataTable [paginator]="false" [rows]="chosenRowsPerPage" [totalRecords]="totalRecords"
                 [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true" selectionMode="single"
                 [(selection)]="selectedItem" (onRowDblclick)="doOnRowSelect($event)" [lazy]="false"
                 sortField="sortIndex" [sortOrder]="1"
                 emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 dataKey="id" [value]="itemList">
        <p-column *ngIf="fieldUsage.show(ConfigAddonField.TYPE)" field="addonType" header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.TYPE' | translate }}"
                  sortable="custom" [filter]="showFilters" filterMatchMode="contains"
                  (sortFunction)="sort($event)">
            <ng-template let-item="rowData" pTemplate="body">
                {{("OFFER.POSITIONS.ADDON_CONFIG.TYPES." + item.addonType) | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(ConfigAddonField.DEFINITION)" field="type" header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.DEFINITION' | translate }}"
                  sortable="custom"
                  (sortFunction)="sort($event)"
                  [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                {{("ConfigAddonDefinitions." + item.type + ".configAddonName") | translate}}
            </ng-template>
        </p-column>
        <p-column [sortable]="true" field="sortIndex" header="{{ 'ASSEMBLY.FORM.SORT_INDEX' | translate }}"></p-column>
    </p-dataTable>
</div>

<app-wizard-dialog *ngIf="displayDialog" #wizardDialog
                   header="{{ 'ConfigAddonDefinitions.' + selectedConfigAddonDef + '.configAddonName' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'ADDONS.STEPS.DATA' | translate }}" [id]="STEPS.DATA">
        <div class="scrolled-dialog new-form-60vw">
            <div *ngIf="fieldUsage.show(ConfigAddonField.SORT_INDEX)" class="new-form-row">
                <app-input-number inputId="sortIndex"
                                  label="{{ 'ASSEMBLY.FORM.SORT_INDEX' | translate }}"
                                  [allowOnlyIntegers]="true"
                                  [(validationMessageKey)]="validationErrors['sortIndex']" [min]="fromRecord"
                                  [max]="toRecord"
                                  [step]="1"
                                  [(ngModel)]="configAddon.sortIndex"
                                  [disabled]="fieldUsage.disabled(ConfigAddonField.SORT_INDEX)" [required]="true"></app-input-number>
            </div>


            <div class="new-form-row" *ngIf="fieldUsage.show(ConfigAddonField.SUPPLIER)">
                <app-select inputId="supplier"
                            label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.SUPPLIER' | translate }}"
                            [options]="suppliers" [disabled]="fieldUsage.disabled(ConfigAddonField.SUPPLIER)"
                            [(ngModel)]="supplier"></app-select>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_OUTER_GRILL)">
                <app-checkbox inputId="canBeAddedToAreaWithOuterGrill" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITH_OUTER_GRILL' | translate }}"
                              [disabled]="fieldUsage.disabled(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_OUTER_GRILL)"
                              [(ngModel)]="definition.canBeAddedToAreaWithOuterGrill"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITHOUT_FILLING)">
                <app-checkbox inputId="canBeAddedToAreaWithoutFilling" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITHOUT_FILLING' | translate }}"
                              [disabled]="fieldUsage.disabled(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITHOUT_FILLING)"
                              [(ngModel)]="definition.canBeAddedToAreaWithoutFilling"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_OTHER_FILLING)">
                <app-checkbox inputId="canBeAddedToAreaWithOtherFilling" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITH_OTHER_FILLING' | translate }}"
                              [disabled]="fieldUsage.disabled(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_OTHER_FILLING)"
                              [(ngModel)]="definition.canBeAddedToAreaWithOtherFilling"></app-checkbox>
            </div>
            <div class="new-form-row" *ngIf="fieldUsage.show(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_DECORATIVE_FILLING)">
                <app-checkbox inputId="canBeAddedToAreaWithDecorativeFilling" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.CAN_BE_ADDED_TO_AREA_WITH_DECORATIVE_FILLING' | translate }}"
                              [disabled]="fieldUsage.disabled(ConfigAddonField.CAN_BE_ADDED_TO_AREA_WITH_DECORATIVE_FILLING)"
                              [(ngModel)]="definition.canBeAddedToAreaWithDecorativeFilling"></app-checkbox>
            </div>
            <div *ngIf="fieldUsage.show(ConfigAddonField.ACTIVE)" class="new-form-row">
                <app-checkbox inputId="active" label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.ACTIVE' | translate }}"
                              [disabled]="fieldUsage.disabled(ConfigAddonField.ACTIVE)"
                              [(ngModel)]="definition.active"></app-checkbox>
            </div>

            <div *ngIf="definition">
                <p-accordion [multiple]="true">
                    <p-accordionTab *ngIf="fieldUsage.show(ConfigAddonField.APPLICABLE)"
                                    header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.APPLICABLE' | translate }}"
                                    [selected]="false">
                        <p-listbox [options]="availableApplicableTo | selectItemTranslate"
                                   [(ngModel)]="definition.applicableTo"
                                   [disabled]="fieldUsage.disabled(ConfigAddonField.APPLICABLE)"
                                   [multiple]="true"></p-listbox>
                    </p-accordionTab>
                    <p-accordionTab *ngFor="let group of groupsKeys()"
                                    header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.GROUP.'+ group | translate}}">
                        <div class="cechy-visibility-grid">
                            <div class="offer-order-selection-header"></div>
                            <div class="offer-order-selection-header">
                                {{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.ON_OFFER' | translate}}
                            </div>
                            <div class="offer-order-selection-header">
                                {{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.ON_ORDER' | translate}}
                            </div>
                            <ng-container *ngFor="let cecha of cechyByGroup.get(group)">
                                <div class="offer-order-selection-row new-form-field">
                                    <label [htmlFor]="cecha.symbol"> {{ ('ConfigAddonDefinitions.' + definition.type
                                        +
                                        '.' + cecha.symbol + '.fieldName') | translate
                                        }} {{'(' + cecha.symbol + ')'}} </label>
                                </div>
                                <div class="offer-order-selection-row">
                                    <app-checkbox inputId="{{'offerItem_'+cecha.symbol}}"
                                                  [(ngModel)]="offerItem.cechy[cecha.symbol]"
                                                  [disabled]="definition.isOtherSystem"></app-checkbox>
                                </div>
                                <div class="offer-order-selection-row">
                                    <app-checkbox inputId="{{'orderItem'+cecha.symbol}}"
                                                  [(ngModel)]="orderItem.cechy[cecha.symbol]"
                                                  [disabled]="definition.isOtherSystem"></app-checkbox>
                                </div>
                            </ng-container>
                        </div>
                    </p-accordionTab>

                    <p-accordionTab *ngIf="fieldUsage.show(ConfigAddonField.MESSAGES)"
                                    header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.MESSAGES' | translate}}">
                        <div class="cechy-visibility-grid">
                            <div class="offer-order-selection-header"></div>
                            <div class="offer-order-selection-header">
                                {{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.ON_OFFER' | translate}}
                            </div>
                            <div class="offer-order-selection-header">
                                {{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.ON_ORDER' | translate}}
                            </div>
                            <ng-container *ngFor="let messageSeverity of printableMessageSeverityLevels">
                                <div class="offer-order-selection-row new-form-field">
                                    <label [htmlFor]="messageSeverity">
                                        {{ ('TRANSLATIONS_FOR_PRINT_OUTS.MESSAGES.' + messageSeverity) | translate}}
                                    </label>
                                </div>
                                <div class="offer-order-selection-row">
                                    <app-checkbox inputId="{{'offerItem_'+ messageSeverity}}"
                                                  [ngModel]="offerItem.messageLevels[messageSeverity]"
                                                  (ngModelChange)="handleMessageLevelChange(offerItem.messageLevels, messageSeverity, $event)"
                                                  [disabled]="fieldUsage.disabled(ConfigAddonField.MESSAGES)"></app-checkbox>
                                </div>
                                <div class="offer-order-selection-row">
                                    <app-checkbox inputId="{{'orderItem'+ messageSeverity}}"
                                                  [ngModel]="orderItem.messageLevels[messageSeverity]"
                                                  (ngModelChange)="handleMessageLevelChange(orderItem.messageLevels, messageSeverity, $event)"
                                                  [disabled]="fieldUsage.disabled(ConfigAddonField.MESSAGES)"></app-checkbox>
                                </div>
                            </ng-container>
                        </div>
                    </p-accordionTab>

                    <p-accordionTab *ngIf="fieldUsage.show(ConfigAddonField.PREVIEW_IMAGE)"
                                    header="{{ 'ADDONS.FORM.PREVIEW_IMAGE' | translate }}" [selected]="false"
                                    [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                        <div class="new-form-row">
                            <app-file-upload inputId="image" [ngModel]="file"
                                             (ngModelChange)="onFileChange($event)"
                                             [disabled]="fieldUsage.disabled(ConfigAddonField.PREVIEW_IMAGE)"
                                             [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                             [addButtonLabel]="'OFFER.POSITIONS.ADDON_CONFIG.FORM.SELECT_FILE_UPLOAD'"
                                             [changeButtonLabel]="'OFFER.POSITIONS.ADDON_CONFIG.FORM.SELECT_FILE_UPLOAD'"
                                             [deleteButtonLabel]="'OFFER.POSITIONS.ADDON_CONFIG.FORM.DELETE_FILE_UPLOAD'"
                                             [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
                        </div>
                    </p-accordionTab>

                    <p-accordionTab *ngIf="fieldUsage.show(ConfigAddonField.DESCRIPTION_IMAGE)"
                                    header="{{ 'ADDONS.FORM.DESCRIPTION_IMAGE' | translate }}" [selected]="false"
                                    [ngClass]="{'accordion-header-with-errors': validationErrors['descriptionImage']}">
                        <div class="new-form-row">
                            <app-file-upload inputId="descriptionImage" [ngModel]="descriptionImage"
                                             [disabled]="true"
                                             [maxSize]="150000" [maxWidth]="300" [maxHeight]="300"
                                             [addButtonLabel]="'ADDONS.FORM.SELECT_DESCRIPTION_FILE_UPLOAD'"
                                             [changeButtonLabel]="'ADDONS.FORM.SELECT_DESCRIPTION_FILE_UPLOAD'"
                                             [deleteButtonLabel]="'ADDONS.FORM.DELETE_DESCRIPTION_FILE_UPLOAD'"
                                             [(validationMessageKey)]="validationErrors['descriptionImage']"></app-file-upload>
                        </div>
                    </p-accordionTab>

                    <p-accordionTab *ngIf="fieldUsage.show(ConfigAddonField.DESCRIPTION_IMAGE_LARGE)"
                                    header="{{ 'ADDONS.FORM.DESCRIPTION_IMAGE_LARGE' | translate }}" [selected]="false"
                                    [ngClass]="{'accordion-header-with-errors': validationErrors['descriptionImageLarge']}">
                        <div class="new-form-row">
                            <app-file-upload inputId="descriptionImageLarge" [ngModel]="descriptionImageLarge"
                                             (ngModelChange)="onDescriptionImageLargeFileChange($event)"
                                             [disabled]="fieldUsage.disabled(ConfigAddonField.DESCRIPTION_IMAGE_LARGE)"
                                             [maxSize]="2200000" [maxWidth]="2000" [maxHeight]="2000"
                                             [addButtonLabel]="'ADDONS.FORM.SELECT_DESCRIPTION_FILE_UPLOAD'"
                                             [changeButtonLabel]="'ADDONS.FORM.SELECT_DESCRIPTION_FILE_UPLOAD'"
                                             [deleteButtonLabel]="'ADDONS.FORM.DELETE_DESCRIPTION_FILE_UPLOAD'"
                                             [(validationMessageKey)]="validationErrors['descriptionImageLarge']"></app-file-upload>
                        </div>
                    </p-accordionTab>

                    <p-accordionTab *ngIf="fieldUsage.show(ConfigAddonField.DESCRIPTION)"
                                    header="{{ 'ADDONS.FORM.DESCRIPTION' | translate }}" [selected]="false">
                        <div *ngFor="let language of SupportedLanguages.languages" class="new-form-row">
                            <app-text-area  [inputId]="'description_' + language.code"
                                            label="{{ language.name | translate }}"
                                            [maxlength]="500"
                                            [disabled]="fieldUsage.disabled(ConfigAddonField.DESCRIPTION)"
                                            [(ngModel)]="description[language.code]"></app-text-area>
                        </div>
                    </p-accordionTab>
                    <p-accordionTab header="{{ 'MOTLAWA_INTEGRATION_FORM.CONFIGS_ACCORDION_LABEL' | translate }}" [selected]="false"
                                    *ngIf="fieldUsage.show(ConfigAddonField.MOTLAWA_INTEGRATION)"
                                    [ngClass]="{'accordion-header-with-errors': validationErrors['motlawaInfo']}">
                        <app-motlawa-integration-input [disabled]="fieldUsage.disabled(ConfigAddonField.MOTLAWA_INTEGRATION)"
                                                       [value]="motlawaIntegrationInfo"
                                                       [validationErrorsPrefix]="'motlawaIntegrationInfo'"
                                                       [validationErrors]="validationErrors"></app-motlawa-integration-input>
                    </p-accordionTab>
                </p-accordion>
            </div>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'ADDONS.FORM.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [linkableItems]="windowSystems" [(ngModel)]="selectedWindowSystems"
                                [disabled]="readOnlyMode"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step label="{{ 'CONFIGURABLE_ADDONS.STEPS.GLAMOUR_PRINT_INFO' | translate }}" [id]="STEPS.SYSTEMS">
        <div class="new-form-60vw">
            <app-config-addon-glamour-print-info-form [validationErrors]="validationErrors"
                                                      [disabled]="readOnlyMode"
                                                      [horizontalPrintImageFile]="horizontalPrintImageFile.file"
                                                      (horizontalPrintImageFileChange)="handleGlamourHorizontalPrintImageFileChange($event)"
                                                      [verticalPrintImageFile]="verticalPrintImageFile.file"
                                                      (verticalPrintImageFileChange)="handleGlamourVerticalPrintImageFileChange($event)"
                                                      [glamourDescription]="glamourDescription"
                                                      (glamourDescriptionChange)="handleGlamourDescriptionChange($event)">
            </app-config-addon-glamour-print-info-form>
        </div>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="wizard-dialog-save" label="{{'GENERAL.SAVE' | translate }}" icon="check"
                               type="main-action" [size]="40" [disabled]="definition == undefined || saveInProgress || readOnlyMode"
                               (onClick)="wizardDialog.submit()"></app-simple-button>
            <app-simple-button buttonId="wizard-dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="wizardDialog.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
