import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {SubwindowTypeField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {SubwindowTypeFieldUsage} from "../../catalog-field-usage";
import {SubwindowType} from "../SubwindowType";

@Component({
    selector: 'app-subwindow-type-form',
    templateUrl: './subwindow-type-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubwindowTypeFormComponent {

    @Input() item: SubwindowType;
    @Input() validationErrors: any;
    @Input() editPermits: FieldLimitation[] = [];

    fieldUsage: SubwindowTypeFieldUsage;
    SubwindowTypeField = SubwindowTypeField;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    constructor(public translateService: TranslateService) {
        this.fieldUsage = new SubwindowTypeFieldUsage(this);
    }
}
