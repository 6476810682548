import {TranslatedSelectItem} from "./translated.select.item";

export class TranslatedSelectItemImpl implements TranslatedSelectItem {
    labelKey: string;
    label: string;
    value: any;

    constructor(labelKey: string, value: any) {
        this.labelKey = labelKey;
        this.label = labelKey; // placeholder until resolved by pipe
        this.value = value;
    }
}
