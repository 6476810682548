import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemArrayToJson, mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {GraspDistanceFrameCategory} from './grasp-distance-frame-category';

@Injectable()
export class GraspDistanceFrameCategoryService implements CrudService<GraspDistanceFrameCategory> {

    private static readonly API_URL = 'graspDistanceFrameCategory';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: {
        [p: string]: FilterMetadata
    }, sortColumn: string, sortOrder: number): Observable<Listing<GraspDistanceFrameCategory>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(GraspDistanceFrameCategoryService.API_URL, {params: params})
            .pipe(mapListingToJson(GraspDistanceFrameCategory));
    }

    getItem(itemId: number): Observable<GraspDistanceFrameCategory> {
        return this.http.get<object>(`${GraspDistanceFrameCategoryService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(GraspDistanceFrameCategory));
    }

    addItem(item: GraspDistanceFrameCategory): Observable<number> {
        return this.http.post<void>(GraspDistanceFrameCategoryService.API_URL, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(GraspDistanceFrameCategoryService.API_URL));
    }

    editItem(itemId: number, item: GraspDistanceFrameCategory): Observable<number> {
        return this.http.put<void>(`${GraspDistanceFrameCategoryService.API_URL}/${itemId}`, item)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }

    getActiveItems(): Observable<GraspDistanceFrameCategory[]> {
        return this.http.get<object[]>(`${GraspDistanceFrameCategoryService.API_URL}/active`)
            .pipe(mapItemArrayToJson(GraspDistanceFrameCategory));
    }
}
