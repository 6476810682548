<div class="new-form-60vw">
    <div class="new-form-row">
        <app-select inputId="groupId" label="{{ 'CLIENT-DETAILS.FORM.CLIENT-GROUP' | translate }}"
                    [(validationMessageKey)]="validationErrors['groupId']" [options]="existingGroups"
                    [optionFormatter]="clientGroupOptionFormatter" [optionKey]="clientGroupKey"
                    [ngModel]="client.groupId" (ngModelChange)="clientGroupChanged($event)"
                    [required]="true"></app-select>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="clientName" label="{{ 'CLIENT-DETAILS.FORM.NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['name']" [maxlength]="100" [required]="true"
                        [(ngModel)]="client.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="clientStreet" label="{{ 'CLIENT-DETAILS.FORM.STREET' | translate }}"
                        [(validationMessageKey)]="validationErrors['address.street']" [maxlength]="100"
                        [(ngModel)]="client.address.street"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="clientCity" label="{{ 'CLIENT-DETAILS.FORM.CITY' | translate }}"
                        [(validationMessageKey)]="validationErrors['address.city']" [maxlength]="50"
                        [(ngModel)]="client.address.city"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="clientCountry" label="{{ 'CLIENT-DETAILS.FORM.COUNTRY' | translate }}"
                    [(validationMessageKey)]="validationErrors['address.country']" [options]="countries | async"
                    [allowSelectingNone]="true" [(ngModel)]="client.address.country"></app-select>
    </div>
    <div class="new-form-row">
        <app-input-zip inputId="clientZip" label="{{ 'CLIENT-DETAILS.FORM.ZIP' | translate }}"
                       [(validationMessageKey)]="validationErrors['address.zip']" [country]="client.address.country"
                       [disabled]="client.address.country == undefined"
                       [(ngModel)]="client.address.zip"></app-input-zip>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="clientIdentifier" label="{{ 'CLIENT-DETAILS.FORM.IDENTIFIER' | translate }}"
                        [(validationMessageKey)]="validationErrors['identifier']" [maxlength]="30"
                        [(ngModel)]="client.identifier"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="clientPhone" label="{{ 'CLIENT-DETAILS.FORM.PHONE' | translate }}"
                        [(validationMessageKey)]="validationErrors['phone']" [maxlength]="50"
                        [(ngModel)]="client.phone"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="clientEmail" label="{{ 'CLIENT-DETAILS.FORM.E-MAIL' | translate }}"
                        [(validationMessageKey)]="validationErrors['email']" [maxlength]="255"
                        [(ngModel)]="client.email"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="client.id != null && selectUsers.length > 0">
        <app-select label="{{ 'CLIENT-DETAILS.FORM.OWNER' | translate }}"
                    [(validationMessageKey)]="validationErrors['owner']" [options]="selectUsers"
                    [disabled]="isNotOperator()" [required]="true" [(ngModel)]="client.owner"></app-select>
    </div>
    <div class="new-form-row">
        <app-text-area inputId="description" label="{{ 'CLIENT-DETAILS.FORM.DESCRIPTION' | translate }}"
                       [(validationMessageKey)]="validationErrors['description']" [maxlength]="512"
                       [(ngModel)]="client.description"></app-text-area>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'CLIENT-DETAILS.FORM.ACTIVE' | translate }}"
                      [(validationMessageKey)]="validationErrors['active']" [(ngModel)]="client.active"></app-checkbox>
    </div>
    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab header="{{ 'PROFIT_MARGIN.FORM.SIMPLE_HEADER' | translate }}">
                <app-simple-profit-margin-source *ngIf="simpleProfitMarginSource"
                                                 [groupProfitMargins]="groupSimpleProfitMarginSource"
                                                 [profitMargins]="simpleProfitMarginSource" [suppliers]="suppliers"
                                                 [validationErrors]="validationErrors" validationPrefix="simpleProfitMarginSource."
                                                 (onSetAllClicked)="client.bulkAddonProfitMargin = $event"></app-simple-profit-margin-source>
                <div class="new-form-row new-form-row-narrow-inputs">
                    <app-input-text inputId="bulkAddonProfitMargin"
                                    label="{{ 'CLIENT-DETAILS.FORM.BULK_ADDON_PROFIT_MARGIN' | translate }}"
                                    [(validationMessageKey)]="validationErrors['bulkAddonProfitMargin']" [maxlength]="250"
                                    [disabled]="isNotOperatorOrSeller()"
                                    [(ngModel)]="client.bulkAddonProfitMargin"></app-input-text>
                    <app-input-text inputId="bulkGroupAddonProfitMargin"
                                    label="{{ 'PROFIT_MARGIN.FORM.GROUP_PROFIT_MARGIN' | translate }}" [disabled]="true"
                                    [ngModel]="selectedClientGroup?.bulkAddonProfitMargin"></app-input-text>
                </div>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
