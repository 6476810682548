import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {WindowSystemDefinitionDrawingToolsEnabler} from '../window-system-definition-drawing-tools-enabler';

@Component({
    selector: 'app-window-system-definition-drawing-tools-form',
    templateUrl: './window-system-definition-drawing-tools-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowSystemDefinitionDrawingToolsFormComponent {

    @Input()
    windowSystemDrawingToolsEnabler: WindowSystemDefinitionDrawingToolsEnabler;

    @Input()
    disabled: boolean;
}
