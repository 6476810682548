import {DrawingUtil} from "../drawing-util";
import {ScalingPainter} from "./ScalingPainter";
import {PainterParams} from "./PainterParams";
import {PainterMode} from "./PainterMode";
import {OpeningOption} from "../catalog-data/window-system-interface";
import {WindowPaintAttributes} from "./WindowParams";

export class OpeningLines {

    public static GROUP_CLASS = 'openings-group';

    private static openingLineAttrs(params: PainterParams, forceDottedLines = false): WindowPaintAttributes {
        let dottedLines = !params.isShaded() && (forceDottedLines || params.openingOption === OpeningOption.INSIDE);
        return {
            fill: 'none',
            stroke: `${params.isShaded() ? '#3b454d' : '#111111'}`,
            strokeWidth: `${((params.isMode(PainterMode.THUMBNAIL) || params.isShaded() ? 1 : 4) / (dottedLines ? 1 : 2) * params.floatingElementsScale)}px`,
            strokeDasharray: dottedLines ? `1px ${params.isMode(PainterMode.THUMBNAIL) ? 5 : 15 * params.floatingElementsScale}px` : 'none',
            strokeLinecap: 'round',
            pointerEvents: 'none'
        };
    }

    private static hsLineAttrs(params: PainterParams): WindowPaintAttributes {
        return {
            stroke: `${params.isShaded() ? '#3b454d' : '#111111'}`,
            strokeWidth: `${params.isMode(PainterMode.THUMBNAIL) ? 1.6 : (6.4 * params.floatingElementsScale)}px`,
            strokeLinecap: 'round',
            pointerEvents: 'none'
        };
    }

    private static pdLineAttrs(params: PainterParams): WindowPaintAttributes {
        return {
            stroke: `${params.isShaded() ? '#3b454d' : '#111111'}`,
            strokeWidth: `${params.isMode(PainterMode.THUMBNAIL) ? 1 : (4 * params.floatingElementsScale)}px`,
            strokeLinecap: 'round',
            pointerEvents: 'none'
        };
    }

    static drawUOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams): Snap.Element[] {
        let downR;
        let downL;
        let top;
        let points = DrawingUtil.getRectangleAroundPolygon(innerPoints);
        let minMaxXY = DrawingUtil.calculateMinMaxFromPolygon(innerPoints);
        let vertLine = [(minMaxXY.minX + minMaxXY.maxX) / 2, minMaxXY.minY, (minMaxXY.minX + minMaxXY.maxX) / 2, minMaxXY.maxY];

        let topPointCandidate = undefined;
        let leftPointCandidate = undefined;
        let rightPointCandidate = undefined;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let intersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, vertLine[0], vertLine[1], vertLine[2], vertLine[3]);

            if (intersection.intersects) {
                if (intersection.onLine1 && intersection.onLine2) {
                    if (topPointCandidate == undefined) {
                        topPointCandidate = [intersection.x, intersection.y];
                    } else {
                        if (topPointCandidate[1] >= intersection.y) {
                            topPointCandidate = [intersection.x, intersection.y];
                        }
                    }
                }
            }
        }
        top = topPointCandidate;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let leftIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, top[0], top[1], points.bottomL[0], points.bottomL[1]);
            let rightIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, top[0], top[1], points.bottomR[0], points.bottomR[1]);
            if (leftIntersection.intersects && leftIntersection.onLine1 && leftIntersection.onLine2 &&
                (!leftPointCandidate || leftPointCandidate[1] < leftIntersection.y)) {
                leftPointCandidate = [leftIntersection.x, leftIntersection.y];
            }
            if (rightIntersection.intersects && rightIntersection.onLine1 && rightIntersection.onLine2 &&
                (!rightPointCandidate || rightPointCandidate[1] < rightIntersection.y)) {
                rightPointCandidate = [rightIntersection.x, rightIntersection.y];
            }
        }
        downR = rightPointCandidate;
        downL = leftPointCandidate;
        return [
            ScalingPainter.line(svg, [downL[0], downL[1], top[0], top[1]], this.openingLineAttrs(params), params),
            ScalingPainter.line(svg, [downR[0], downR[1], top[0], top[1]], this.openingLineAttrs(params), params)
        ];
    }

    static drawAOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams): Snap.Element[] {
        let downR;
        let downL;
        let top;
        let points = DrawingUtil.getRectangleAroundPolygon(innerPoints);
        let minMaxXY = DrawingUtil.calculateMinMaxFromPolygon(innerPoints);
        let vertLine = [(minMaxXY.minX + minMaxXY.maxX) / 2, minMaxXY.minY, (minMaxXY.minX + minMaxXY.maxX) / 2, minMaxXY.maxY];

        let bottomPointCandidate = undefined;
        let leftPointCandidate = undefined;
        let rightPointCandidate = undefined;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let intersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, vertLine[0], vertLine[1], vertLine[2], vertLine[3]);

            if (intersection.intersects) {
                if (intersection.onLine1 && intersection.onLine2) {
                    if (bottomPointCandidate == undefined) {
                        bottomPointCandidate = [intersection.x, intersection.y];
                    } else {
                        if (bottomPointCandidate[1] <= intersection.y) {
                            bottomPointCandidate = [intersection.x, intersection.y];
                        }
                    }
                }
            }
        }
        top = bottomPointCandidate;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let leftIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, top[0], top[1], points.topL[0], points.topL[1]);
            let rightIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, top[0], top[1], points.topR[0], points.topR[1]);
            if (leftIntersection.intersects && leftIntersection.onLine1 && leftIntersection.onLine2 &&
                (!leftPointCandidate || leftPointCandidate[1] > leftIntersection.y)) {
                leftPointCandidate = [leftIntersection.x, leftIntersection.y];
            }
            if (rightIntersection.intersects && rightIntersection.onLine1 && rightIntersection.onLine2 &&
                (!rightPointCandidate || rightPointCandidate[1] > rightIntersection.y)) {
                rightPointCandidate = [rightIntersection.x, rightIntersection.y];
            }
        }
        downR = rightPointCandidate;
        downL = leftPointCandidate;
        return [
            ScalingPainter.line(svg, [downL[0], downL[1], top[0], top[1]], this.openingLineAttrs(params), params),
            ScalingPainter.line(svg, [downR[0], downR[1], top[0], top[1]], this.openingLineAttrs(params), params)
        ];
    }

    static drawR_LOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams, sideHung: boolean): Snap.Element[] {
        let topL;
        let lowL;
        let right;
        let points = DrawingUtil.getRectangleAroundPolygon(innerPoints);
        let minMaxXY = DrawingUtil.calculateMinMaxFromPolygon(innerPoints);
        let horizonalLine = [minMaxXY.minX, (minMaxXY.minY + minMaxXY.maxY) / 2, minMaxXY.maxX, (minMaxXY.minY + minMaxXY.maxY) / 2];
        let topPointCandidate = undefined;
        let bottomPointCandidate = undefined;
        let rightPointCandidate = undefined;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let intersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, horizonalLine[0], horizonalLine[1],
                horizonalLine[2], horizonalLine[3]);

            if (intersection.intersects) {
                if (intersection.onLine1 && intersection.onLine2) {
                    if (rightPointCandidate == undefined) {
                        rightPointCandidate = [intersection.x, intersection.y];
                    } else {
                        if (rightPointCandidate[0] <= intersection.x) {
                            rightPointCandidate = [intersection.x, intersection.y];
                        }
                    }
                }
            }
        }
        right = rightPointCandidate;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let topIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, right[0], right[1], points.topL[0], points.topL[1]);
            let bottomIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, right[0], right[1], points.bottomL[0], points.bottomL[1]);
            if (topIntersection.intersects) {
                if (topIntersection.onLine1 && topIntersection.onLine2) {
                    if (topPointCandidate == undefined) {
                        topPointCandidate = [topIntersection.x, topIntersection.y];
                    } else {
                        if (topPointCandidate[1] >= topIntersection.y) {
                            topPointCandidate = [topIntersection.x, topIntersection.y];
                        }
                    }
                }
            }
            if (bottomIntersection.intersects) {
                if (bottomIntersection.onLine1 && bottomIntersection.onLine2) {
                    if (bottomPointCandidate == undefined) {
                        bottomPointCandidate = [bottomIntersection.x, bottomIntersection.y];
                    } else {
                        if (bottomPointCandidate[1] <= bottomIntersection.y) {
                            bottomPointCandidate = [bottomIntersection.x, bottomIntersection.y];
                        }
                    }
                }
            }
        }
        topL = topPointCandidate;
        lowL = bottomPointCandidate;
        if (sideHung) {
            const sideHungDelta = (topL[1] - lowL[1]) / 10;
            topL[1] -= sideHungDelta;
            lowL[1] += sideHungDelta;
        }
        return [
            ScalingPainter.line(svg, [topL[0], topL[1], right[0], right[1]], this.openingLineAttrs(params), params),
            ScalingPainter.line(svg, [lowL[0], lowL[1], right[0], right[1]], this.openingLineAttrs(params), params)
        ];
    }

    static drawR_ROpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams, sideHung: boolean): Snap.Element[] {
        let topR;
        let lowR;
        let left;
        let points = DrawingUtil.getRectangleAroundPolygon(innerPoints);
        let minMaxXY = DrawingUtil.calculateMinMaxFromPolygon(innerPoints);
        let horizontalLine = [minMaxXY.minX, (minMaxXY.minY + minMaxXY.maxY) / 2, minMaxXY.maxX, (minMaxXY.minY + minMaxXY.maxY) / 2];
        let topPointCandidate = undefined;
        let bottomPointCandidate = undefined;
        let leftPointCandidate = undefined;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let intersection = DrawingUtil.lineIntersection(
                x1, y1, x2, y2, horizontalLine[0], horizontalLine[1], horizontalLine[2], horizontalLine[3]);

            if (intersection.intersects) {
                if (intersection.onLine1 && intersection.onLine2) {
                    if (leftPointCandidate == undefined) {
                        leftPointCandidate = [intersection.x, intersection.y];
                    } else {
                        if (leftPointCandidate[0] >= intersection.x) {
                            leftPointCandidate = [intersection.x, intersection.y];
                        }
                    }
                }
            }
        }
        left = leftPointCandidate;

        for (let i = 0; i < innerPoints.length; i += 2) {
            let x1 = DrawingUtil.getPoint(innerPoints, i - 2);
            let y1 = DrawingUtil.getPoint(innerPoints, i - 1);
            let x2 = DrawingUtil.getPoint(innerPoints, i);
            let y2 = DrawingUtil.getPoint(innerPoints, i + 1);

            let topIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, left[0], left[1], points.topR[0], points.topR[1]);
            let bottomIntersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, left[0], left[1], points.bottomR[0], points.bottomR[1]);
            if (topIntersection.intersects) {
                if (topIntersection.onLine1 && topIntersection.onLine2) {
                    if (topPointCandidate == undefined) {
                        topPointCandidate = [topIntersection.x, topIntersection.y];
                    } else {
                        if (topPointCandidate[1] >= topIntersection.y) {
                            topPointCandidate = [topIntersection.x, topIntersection.y];
                        }
                    }
                }
            }
            if (bottomIntersection.intersects) {
                if (bottomIntersection.onLine1 && bottomIntersection.onLine2) {
                    if (bottomPointCandidate == undefined) {
                        bottomPointCandidate = [bottomIntersection.x, bottomIntersection.y];
                    } else {
                        if (bottomPointCandidate[1] <= bottomIntersection.y) {
                            bottomPointCandidate = [bottomIntersection.x, bottomIntersection.y];
                        }
                    }
                }
            }
        }
        topR = topPointCandidate;
        lowR = bottomPointCandidate;
        if (sideHung) {
            const sideHungDelta = (topR[1] - lowR[1]) / 10;
            topR[1] -= sideHungDelta;
            lowR[1] += sideHungDelta;
        }
        return [
            ScalingPainter.line(svg, [topR[0], topR[1], left[0], left[1]], this.openingLineAttrs(params), params),
            ScalingPainter.line(svg, [lowR[0], lowR[1], left[0], left[1]], this.openingLineAttrs(params), params)
        ];
    }

    static shiftPositions(positions: number[], shift: number, vertical = false) {
        for (let i = 0; i < positions.length; i++) {
            if (i % 2 === (vertical ? 1 : 0)) {
                positions[i] += shift;
            }
        }
    }

    static drawArrowOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams, floatingElementsBoxes: number[][],
                                 rightSide: boolean, hs = false, isTerrace = false): Snap.Element[] {
        let center = DrawingUtil.getPolygonCentroid(innerPoints);
        let bbox = DrawingUtil.calculatePolygonTotalBoundingBox(innerPoints);
        let arrowScale = isTerrace ? .195 : .15;
        let arrowHeadScale = isTerrace ? .13 : 0.1;
        let h = (bbox.maxY - bbox.minY) * arrowScale;
        let w = (bbox.maxX - bbox.minX) * arrowScale;
        this.shiftPositions(center, ((rightSide ? -w : w) / 2));
        let arrow = (h + w) * arrowHeadScale;
        let lineEnd = center[0] + (rightSide ? w : -w);
        let arrowHead = [lineEnd, center[1]];
        let lines = [];
        if (hs) {
            lines.push([center[0], center[1], center[0], center[1] + h]);
        }
        lines.push([center[0], center[1], ...arrowHead]);
        lines.push([...arrowHead, lineEnd + (rightSide ? -arrow : arrow), center[1] + arrow]);
        lines.push([...arrowHead, lineEnd + (rightSide ? -arrow : arrow), center[1] - arrow]);
        lines = OpeningLines.preventOverlapsByMovingDownwards(lines, floatingElementsBoxes, params);
        lines = lines.map(line => ScalingPainter.scale(params, line));
        return lines.map(line => {
            let params1 = this.hsLineAttrs(params);
            params1.strokeWidth = `${params.isMode(PainterMode.THUMBNAIL) ? 1.6 : 6.4}px`;
            return svg.line(line[0], line[1], line[2], line[3]).attr(params1);
        });
    }

    static drawOGOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams): Snap.Element[] {
        return this.drawOOpeningLines(innerPoints, svg, params, 'top');
    }

    static drawOLOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams): Snap.Element[] {
        return this.drawOOpeningLines(innerPoints, svg, params, 'left');
    }

    static drawOPOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams): Snap.Element[] {
        return this.drawOOpeningLines(innerPoints, svg, params, 'right');
    }

    static drawOOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams, side: string): Snap.Element[] {
        let minMaxXY = DrawingUtil.calculateMinMaxFromPolygon(innerPoints);
        let width = (minMaxXY.maxX - minMaxXY.minX);
        let height = (minMaxXY.minY - minMaxXY.maxY);
        let contactX = ((minMaxXY.minX + minMaxXY.maxX) / 2) + ((side === 'top') ? 0 : (((side === 'right') ? width : -width) / 4));
        let contactY = ((minMaxXY.minY + minMaxXY.maxY) / 2) + ((side === 'top') ? (-height / 4) : 0);

        let horizonalLine = [0, contactY, 1, contactY];
        let verticalLine = [contactX, 0, contactX, 1];

        let points = [];
        let horizontal = DrawingUtil.polygonLineIntersections(innerPoints, horizonalLine, true);
        horizontal.sort((a, b) => (a.x - b.x));
        let vertical = DrawingUtil.polygonLineIntersections(innerPoints, verticalLine, true);
        vertical.sort((a, b) => (a.y - b.y));
        points.push(horizontal[0].x, horizontal[0].y, vertical[0].x, vertical[0].y, horizontal[1].x, horizontal[1].y,
            vertical[1].x, vertical[1].y);

        return [ScalingPainter.path(svg, points, this.openingLineAttrs(params), params)];
    }

    static drawWLOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams): Snap.Element[] {
        return this.drawWOpeningLines(innerPoints, svg, params, false, true);
    }

    static drawWPOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams): Snap.Element[] {
        return this.drawWOpeningLines(innerPoints, svg, params, true, true);
    }

    static drawWOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams, right: boolean,
                             forceDottedLines = false): Snap.Element[] {
        let corners = DrawingUtil.getRectangleAroundPolygon(innerPoints);
        let top = right ? corners.topR : corners.topL;
        let bot = right ? corners.bottomR : corners.bottomL;
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(innerPoints);
        let r = box.maxX - box.minX;
        let h = box.maxY - box.minY;
        let ellipseInfo = [top[0], ((top[1] + bot[1]) / 2), r, h / 2];

        return [ScalingPainter.ellipseWithClipPath(svg, ellipseInfo, innerPoints, this.openingLineAttrs(params, forceDottedLines), params)];
    }

    static drawPDOpeningLines(innerPoints: number[], svg: Snap.Paper, params: PainterParams,
                              floatingElementsBoxes: number[][]): Snap.Element[] {
        let center = DrawingUtil.getPolygonCentroid(innerPoints);
        let bbox = DrawingUtil.calculatePolygonTotalBoundingBox(innerPoints);
        let h = (bbox.maxY - bbox.minY) * 0.15;
        let w = (bbox.maxX - bbox.minX) * 0.15;
        this.shiftPositions(center, -h / 2, true);
        let arrow = (h + w) * 0.1;
        let lines = [
            [center[0], center[1], center[0], center[1] + h],
            [center[0], center[1], center[0] + arrow, center[1] + arrow],
            [center[0], center[1], center[0] - arrow, center[1] + arrow]
        ];
        lines = OpeningLines.preventOverlapsByMovingDownwards(lines, floatingElementsBoxes, params);
        lines = lines.map(line => ScalingPainter.scale(params, line));
        return lines.map(line => svg.line(line[0], line[1], line[2], line[3]).attr(this.pdLineAttrs(params)));
    }

    static drawHL_HPOpeningLines(outerPoints: number[], innerPoints: number[], svg: Snap.Paper, params: PainterParams,
                                 rightSide: boolean, drawArrowHead: boolean, shorterLineEnd: boolean): Snap.Element[] {
        let center = DrawingUtil.getPolygonCentroid(outerPoints);
        let bboxOuter = DrawingUtil.calculatePolygonTotalBoundingBox(outerPoints);
        let bboxInner = DrawingUtil.calculatePolygonTotalBoundingBox(innerPoints);
        let arrowHeadScale = 0.015;
        let h = bboxInner.maxY - bboxInner.minY;
        let w = bboxOuter.maxX - bboxOuter.minX;
        let arrow = (h + w) * arrowHeadScale;
        let shiftPositionAdjustment = 0;
        if (shorterLineEnd) {
            if (rightSide) {
                shiftPositionAdjustment = bboxOuter.minX - bboxInner.minX - arrow * 2;
            } else {
                shiftPositionAdjustment = bboxInner.maxX - bboxOuter.maxX - arrow * 2;
            }
            w += shiftPositionAdjustment;
        }
        this.shiftPositions(center, ((w + shiftPositionAdjustment) / 2) * (rightSide ? -1 : 1));
        if (drawArrowHead) {
            if (rightSide) {
                w += bboxInner.maxX - bboxOuter.maxX - arrow * 2;
            } else {
                w += bboxOuter.minX - bboxInner.minX - arrow * 2;
            }
        }
        let lineEnd = center[0] + (rightSide ? w : -w);
        let arrowHead = [lineEnd, center[1]];
        let lines = [];
        lines.push([center[0], center[1], ...arrowHead]);
        if (drawArrowHead) {
            lines.push([...arrowHead, lineEnd + (rightSide ? -arrow : arrow), center[1] + arrow]);
            lines.push([...arrowHead, lineEnd + (rightSide ? -arrow : arrow), center[1] - arrow]);
        }
        lines = lines.map(line => ScalingPainter.scale(params, line));
        return lines.map(line => {
            let params1 = this.hsLineAttrs(params);
            return svg.line(line[0], line[1], line[2], line[3]).attr(params1);
        });
    }

    private static preventOverlapsByMovingDownwards(lines: number[][], floatingElementsBoxes: number[][],
                                                    params: PainterParams): number[][] {
        let transformationStep = 10 * params.scale;
        while (floatingElementsBoxes.some(
            overlapPolygon =>
                lines.every(line => DrawingUtil.isWholePolygonInsidePolygon(line, overlapPolygon)) ||
                lines.some(line => DrawingUtil.polygonLineIntersectionCount(overlapPolygon, line, false) > 0))) {
            lines = lines.map(line => DrawingUtil.shiftArrayInYAxis(line, transformationStep));
        }
        return lines;
    }
}
