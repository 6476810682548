import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class ConfigSliderElement implements CrudItem {
    id: number;
    symbol: string;
    label: MultilanguageField;
    configSystemId: number;
    configSystemName: MultilanguageField;
    sortIndex: number;
    image?: string;
    active = true;

    static fromJSON(jsonObject): ConfigSliderElement {
        const item = new ConfigSliderElement();
        item.id = jsonObject.id;
        item.symbol = jsonObject.symbol;
        item.label = MultilanguageField.fromJSON(jsonObject.label);
        item.configSystemId = jsonObject.configSystemId;
        item.configSystemName = jsonObject.configSystemName;
        item.sortIndex = jsonObject.sortIndex;
        item.image = jsonObject.image;
        item.active = jsonObject.active;
        return item;
    }
}
