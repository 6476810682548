import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, of} from 'rxjs';
import {ColorTarget, ColorType} from "../../../../ColorType";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ColorField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {ColorFieldUsage} from "../../catalog-field-usage";
import {ColorHelperInterface} from "../../catalog-field-usage-helper-interfaces";
import {Color} from "../color";
import {WebshopColorOptions} from '../webshop-color-options';

@Component({
    selector: 'app-color-form',
    templateUrl: './color-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColorFormComponent implements OnInit, ColorHelperInterface {

    @Input() colorTarget: ColorTarget;
    @Input() item: Color;
    @Input() validationErrors: any;
    @Input() file: File;
    @Input() webshopTexture: File;
    @Input() availableColors: SelectItem[];
    @Input() editPermits: FieldLimitation[] = [];

    @Output() colorTypeChange = new EventEmitter<ColorType>();
    @Output() fileChange = new EventEmitter<File>();
    @Output() webshopTextureChange = new EventEmitter<File>();
    @Output() resetWebshopTexture = new EventEmitter<File>();

    selectTypes: Observable<SelectItem[]>;
    webshopColorOptions: SelectItem[];

    fieldUsage: ColorFieldUsage;
    ColorField = ColorField;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    constructor(public translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService) {
        this.webshopColorOptions = [
            {label: 'COLOR.FORM.WEBSHOP_COLOR_OPTIONS.HEX', value: WebshopColorOptions.HEX},
            {label: 'COLOR.FORM.WEBSHOP_COLOR_OPTIONS.TEXTURE', value: WebshopColorOptions.TEXTURE}
        ];
        this.fieldUsage = new ColorFieldUsage(this);
    }

    ngOnInit(): void {
        this.initSelectTypes();
    }

    initSelectTypes(): void {
        if (this.item) {
            this.selectTypes = this.translatedSelectItemService.buildSortedDropdown(this.colorTarget.colorTypes, 'COLOR_TYPES.', undefined);
        } else {
            this.selectTypes = of([]);
        }
    }

    onTypeChange(type: string) {
        this.item.type = type;
        this.item.ralHex = undefined;
        this.setCheckboxes();
        if (type === ColorType.ADDON_MATERIAL) {
            this.item.webshop = false;
            this.item.selectedWebshopColorOption = null;
            this.item.webshopHex = null;
            this.item.ralHex = null;
            this.file = null;
        }

        this.colorTypeChange.emit(ColorType[type]);
    }

    private setCheckboxes() {
        if (this.shouldForceCoreSelection()) {
            this.item.core = true;
            this.item.grill = false;
            this.item.inside = false;
            this.item.outside = false;
        }
        if (this.shouldForceCoreNotSelected()) {
            this.item.core = false;
            this.item.mustBeCovered = false;
            this.item.cannotBeCovered = false;
        }
        if (this.item.type === ColorType.TERRACE) {
            this.item.inside = true;
            this.item.outside = true;
        }
    }

    public shouldForceCoreSelection() {
        return this.item.type === ColorType.PCV;
    }

    public shouldForceCoreNotSelected() {
        return this.item.type === ColorType.VENEER
            || this.item.type === ColorType.RAL_PALETTE_CUSTOM || this.item.type === ColorType.NCS_PALETTE_CUSTOM
            || this.item.type === ColorType.OTHER || this.item.type === ColorType.TERRACE;
    }

    public shouldDisableCoreCheckbox() {
        return this.shouldForceCoreSelection() || this.shouldForceCoreNotSelected();
    }

    coreChanged() {
        if (!this.item.core) {
            this.item.mustBeCovered = false;
            this.item.cannotBeCovered = false;
        }
    }

    cannotBeCoveredChanged() {
        if (this.item.cannotBeCovered) {
            this.item.mustBeCovered = false;
        }
    }

    mustBeCoveredChanged() {
        if (this.item.mustBeCovered) {
            this.item.cannotBeCovered = false;
        }
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    onWebshopTextureChange(webshopTexture: File): void {
        this.webshopTextureChange.emit(webshopTexture);
        this.webshopTexture = webshopTexture;
    }

    isPaletteCustom(): boolean {
        return this.item.type === ColorType.RAL_PALETTE_CUSTOM || this.item.type === ColorType.NCS_PALETTE_CUSTOM;
    }

    onSelectedWebshopColorOptionChange(): void {
        if (this.item.selectedWebshopColorOption === WebshopColorOptions.HEX) {
            if (this.item.ralHex) {
                this.item.webshopHex = this.item.ralHex;
            }
            this.resetWebshopTexture.emit();
        } else {
            this.item.webshopHex = undefined;
        }
    }

    changeWebshopHex(): void {
        if (this.item.selectedWebshopColorOption === WebshopColorOptions.HEX) {
            this.item.webshopHex = this.item.ralHex;
        }
    }

    isWebshopHexSelected(): boolean {
        return this.item.selectedWebshopColorOption === WebshopColorOptions.HEX;
    }

    isTextureSelected(): boolean {
        return this.item.selectedWebshopColorOption === WebshopColorOptions.TEXTURE;
    }

    handleGateHexChange(gateHex: string) {
        this.item.webshopHex = gateHex;
        if (this.item.type === ColorType.RAL_PALETTE_CUSTOM) {
            this.item.ralHex = gateHex;
        }
    }

    hasWindowSystemLinks(): boolean {
        return false;
    }

    hasGateSystemLinks(): boolean {
        return false;
    }

    hasConfigSystemLinks(): boolean {
        return false;
    }
}
