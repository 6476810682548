import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class ApplicationStatusService {

    constructor(private http: HttpClient) {
    }

    isMaintenanceActive(): Observable<boolean> {
        return this.http.get<boolean>('applicationStatus/maintenanceStatus');
    }

    setMaintenanceStatus(maintenanceStatus: boolean): Observable<void> {
        return this.http.post<void>('applicationStatus/maintenanceStatus', maintenanceStatus, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    isRedrawWindowsInProgress(): Observable<boolean> {
        return this.http.get<boolean>('applicationStatus/redrawWindowsStatus');
    }

    getMotlawaDbConnectionId(): Observable<string> {
        return this.http.get('applicationStatus/motlawaDbConnectionId', {responseType: 'text'});
    }

    setMotlawaDbConnectionId(motlawaDbConnectionId: string): Observable<void> {
        return this.http.post<void>('applicationStatus/motlawaDbConnectionId', motlawaDbConnectionId, {
            headers: {
                'Content-Type': 'text/plain'
            }
        });
    }
}
