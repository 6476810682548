<div class="list-content" *ngIf="!newAddon">
    <div class="table-header">
        <div class="title">{{'ADDONS.TITLE.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR','ROLE_OPERATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'ADDONS.TITLE.COPY' | translate }}" icon="content_copy"
                                   type="action" [disabled]="!selectedAddon" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button *ngIf="incomingSupplierId != undefined"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}" icon="filter_list" type="action"
                                   (onClick)="removeFiltersNotSetOnTableColumns()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'ADDONS.TITLE.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="addons" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedAddon"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="addonsTable">
        <p-column field="name" header="{{ 'ADDONS.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  *ngIf="fieldUsage.show(AddonField.NAME)" filterMatchMode="contains">
            <ng-template let-addon="rowData" pTemplate="body">
                {{addon.name[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})" field="sortIndex"
                  header="{{ 'ADDONS.FORM.SORT_INDEX' | translate }}" [sortable]="true"></p-column>
        <p-column field="shortcut" header="{{ 'ADDONS.FORM.SHORTCUT' | translate }}" [sortable]="true" [filter]="showFilters"
                  *ngIf="fieldUsage.show(AddonField.SHORTCUT)" filterMatchMode="contains">
            <ng-template let-addon="rowData" pTemplate="body">
                {{addon.shortcut[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(AddonField.SYMBOL)" field="symbol"
                  header="{{ 'ADDONS.FORM.SYMBOL' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
        </p-column>
        <p-column *ngIf="fieldUsage.show(AddonField.CATEGORY)" field="category"
                  header="{{ 'ADDONS.FORM.CATEGORY' | translate }}" [sortable]="false" [filter]="showFilters"
                  [filterValues]="filterCategory | async">
            <ng-template let-addon="rowData" pTemplate="body">
                {{ ('ADDONS.CATEGORIES.'+addon.category) | translate}}
            </ng-template>
        </p-column>
        <p-column field="pcn" header="{{ 'ADDONS.FORM.PCN' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains" *ngIf="fieldUsage.show(AddonField.PCN)">
        </p-column>
        <p-column field="addonFor" header="{{ 'ADDONS.FORM.ADDON_FOR' | translate }}" [sortable]="false" [filter]="showFilters"
                  [filterValues]="filterAddonFor | async" *ngIf="fieldUsage.show(AddonField.ADDON_FOR)">
            <ng-template let-addon="rowData" pTemplate="body">
                {{ ('ADDONS.FOR.'+addon.addonFor) | translate}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(AddonField.SUPPLIER)"
                  field="supplier.companyName" header="{{ 'ADDONS.FORM.SUPPLIER' | translate }}" [sortable]="true"
                  [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})" let-item="rowData" pTemplate="body">
                {{item.supplier.companyName}}  <span *ngIf="item.supplier.name != undefined" class="secondary-text">{{' (' + item.supplier.name[userLang] + ')'}}</span>
            </ng-template>
            <ng-template *ngIf="isPermitted({roles:['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})" let-item="rowData" pTemplate="body">
                {{item.supplier.name[userLang]}}
            </ng-template>
        </p-column>
        <p-column field="price" header="{{ 'ADDONS.FORM.PRICE' | translate }}" [sortable]="false" [filter]="false"
                  *ngIf="fieldUsage.show(AddonField.PRICE)">
            <ng-template let-addon="rowData" pTemplate="body">
                {{ addon.price.type == 'AMOUNT' || addon.price.type == 'RIGID_PRICE' ? (addon.price.value | price: addon.price.currency) :
                (addon.price.type == 'PERCENT' ? addon.price.value.toFixed(2) + '%' : addon.price.priceGroupDesc)}}
            </ng-template>
        </p-column>
        <p-column field="defaultQuantity" header="{{ 'ADDONS.FORM.DEFAULT_QUANTITY' | translate }}" [sortable]="false"
                  [filter]="false" *ngIf="fieldUsage.show(AddonField.DEFAULT_QUANTITY)">
            <ng-template let-addon="rowData" pTemplate="body">
                <ng-container *ngIf="addon.quantityType === null">
                    -
                </ng-container><ng-container *ngIf="addon.quantityType !== null">
                    {{addon.defaultQuantity + ' ' + (('ADDONS.QUANTITY_TYPE.ABBREVIATION.' + addon.quantityType) | translate)}}
            </ng-container>
            </ng-template>
        </p-column>
        <p-column field="active" header="{{ 'ADDONS.FORM.STATUS' | translate }}" [sortable]="false" [filter]="showFilters"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter"
                  *ngIf="fieldUsage.show(AddonField.ACTIVE)">
            <ng-template let-addon="rowData" pTemplate="body">
                {{(addon.active ? 'ADDONS.FORM.ACTIVE' : 'ADDONS.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newAddon" header="{{ 'ADDONS.TITLE.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">

    <app-wizard-step label="{{ 'ADDONS.STEPS.DATA' | translate }}" [validate]="validateGeneralDataStep">
        <app-addon-form [addon]="addon" [motlawaInfo]="motlawaInfo" [validationErrors]="validationErrors"
                        [addonCategoryTarget]="addonCategoryTarget.target" [isNew]="true"
                        [categories]="availableCategories | async" [addonFor]="availableAddonFor | async"
                        [suppliers]="availableSuppliers" [coreColors]="availableCoreColors"
                        [insideColors]="availableInsideColors" [outsideColors]="availableOutsideColors"
                        [quantityTypes]="availableQuantityTypes | async" [windowSystems]="windowSystems" [file]="file"
                        [currency]="subsystemCurrency" (fileChange)="onFileChange($event)"
                        [tile]="tile.file" (tileChange)="onTileChange($event)"
                        [editPermits]="editPermits"
                        [additionalIcon]="additionalIcon" (additionalIconChange)="onAdditionalIconChange($event)"></app-addon-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon) && isWindowAddon()" label="{{ 'ADDONS.FORM.SYSTEMS' | translate }}">
        <app-addon-systems-form [addon]="addon"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [(linkedSystemIds)]="selectedWindowSystems"
                                [windowSystems]="windowSystems"
                                [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT,
                                    WindowSystemTypeGroup.TERRACE]"></app-addon-systems-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon) && isWindowAddon()" label="{{ 'ADDONS.FORM.ROOF_SYSTEMS' | translate }}">
        <app-addon-systems-form [addon]="addon"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [(linkedSystemIds)]="selectedWindowSystems"
                                [windowSystems]="windowSystems"
                                [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-addon-systems-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon) && isWindowAddon()" label="{{ 'ADDONS.FORM.ENTRANCE_SYSTEMS' | translate }}">
        <app-addon-systems-form [addon]="addon"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [(linkedSystemIds)]="selectedWindowSystems"
                                [windowSystems]="windowSystems"
                                [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-addon-systems-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon) && isGateAddon()"
                     label="{{ 'COLOR.STEPS.GATE_SYSTEMS' | translate }}" id="GATE_SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="gateSystems" [linkableEntity]="LinkableEntity.GATE_SYSTEMS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.GATE-SYSTEMS"
                                [(ngModel)]="selectedGateSystems"></app-link-selection>
        </div>
    </app-wizard-step>

    <app-wizard-step *ngIf="isConfigAddon()"
                     label="{{ 'COLOR.STEPS.CONFIG_ADDONS' | translate }}" [id]="'CONFIG_ADDONS'">
        <div class="new-form-60vw">
            <app-link-selection [linkableItems]="configAddonSystems" [linkableEntity]="LinkableEntity.CONFIG_ADDONS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.CONFIG-SYSTEMS"
                                [(ngModel)]="selectedConfigAddonSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newAddon && displayDialog" header="{{ (copyMode ? 'ADDONS.TITLE.COPY' : 'ADDONS.TITLE.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'ADDONS.STEPS.DATA' | translate }}" [validate]="validateGeneralDataStep">
        <app-addon-form *ngIf="displayDialog" [addon]="addon" [motlawaInfo]="motlawaInfo" [isNew]="false"
                        [validationErrors]="validationErrors" [categories]="availableCategories | async"
                        [addonCategoryTarget]="addonCategoryTarget.target"
                        [addonFor]="availableAddonFor | async" [suppliers]="availableSuppliers"
                        [coreColors]="availableCoreColors" [insideColors]="availableInsideColors"
                        [outsideColors]="availableOutsideColors" [quantityTypes]="availableQuantityTypes | async"
                        [windowSystems]="windowSystems" [file]="file" [currency]="subsystemCurrency"
                        [editPermits]="editPermits"
                        [tile]="tile.file" (tileChange)="onTileChange($event)"
                        [additionalIcon]="additionalIcon" (additionalIconChange)="onAdditionalIconChange($event)"
                        (fileChange)="onFileChange($event)"></app-addon-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon) && isWindowAddon()" label="{{ 'ADDONS.FORM.SYSTEMS' | translate }}">
        <app-addon-systems-form [addon]="addon"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [(linkedSystemIds)]="selectedWindowSystems"
                                [windowSystems]="windowSystems"
                                [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT,
                                    WindowSystemTypeGroup.TERRACE]"></app-addon-systems-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon) && isWindowAddon()" label="{{ 'ADDONS.FORM.ROOF_SYSTEMS' | translate }}">
        <app-addon-systems-form [addon]="addon"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [(linkedSystemIds)]="selectedWindowSystems"
                                [windowSystems]="windowSystems"
                                [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-addon-systems-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon) && isWindowAddon()" label="{{ 'ADDONS.FORM.ENTRANCE_SYSTEMS' | translate }}">
        <app-addon-systems-form [addon]="addon"
                                [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [(linkedSystemIds)]="selectedWindowSystems"
                                [windowSystems]="windowSystems"
                                [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-addon-systems-form>
    </app-wizard-step>

    <app-wizard-step *ngIf="isGateAddon() && fieldUsage.show(CatalogTab.LINKED_SYSTEMS, addon)"
                     label="{{ 'COLOR.STEPS.GATE_SYSTEMS' | translate }}" [id]="'GATE_SYSTEMS'">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="gateSystems" [linkableEntity]="LinkableEntity.GATE_SYSTEMS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.GATE-SYSTEMS"
                                [(ngModel)]="selectedGateSystems"></app-link-selection>
        </div>
    </app-wizard-step>

    <app-wizard-step *ngIf="isConfigAddon()"
                     label="{{ 'COLOR.STEPS.CONFIG_ADDONS' | translate }}" [id]="'CONFIG_ADDONS'">
        <div class="new-form-60vw">
            <app-link-selection [linkableItems]="configAddonSystems" [linkableEntity]="LinkableEntity.CONFIG_ADDONS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.CONFIG-SYSTEMS"
                                [(ngModel)]="selectedConfigAddonSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
