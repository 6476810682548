import {
    SelectTileLink,
    SelectTileLinkable
} from "../../../offer/config-editor/config-sidebar/tile-select-dialog/tile-select-dialog.component";

export class MaterialColorImages implements SelectTileLinkable {
    materialId: number;
    colorId: number;
    image: string;
    hex: string;

    static fromJSON(jsonObject: any): MaterialColorImages {
        return Object.assign(new MaterialColorImages(), jsonObject);
    }

    toSelectTileLink(): SelectTileLink {
        let selectTileLink = new SelectTileLink();
        selectTileLink.selectValue = this.materialId;
        selectTileLink.tileValue = this.colorId;
        selectTileLink.image = this.image;
        selectTileLink.hex = this.hex;
        return selectTileLink;
    }
}
