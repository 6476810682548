<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.PRICE-LISTS.PRICE_TABLES.MENU' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <select class="price-list-select" [ngModel]="selectedPricelist"
                        id="selectedPricelist" (ngModelChange)="pricelistChanged($event)">
                    <option *ngFor="let pricelist of availablePricelists" [ngValue]="pricelist">{{pricelist.name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="type2windowSystems" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [(selection)]="selectedRow" (onRowDblclick)="doOnRowSelect($event)"
                 [lazy]="true" [totalRecords]="totalRecords" (onLazyLoad)="loadPricetablesLazy($event)"
                 [responsive]="true" selectionMode="single"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column field="windowSystemName" header="{{ 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-t2ws="rowData" pTemplate="body">
                {{t2ws.windowSystemName | multilanguageTranslate}}
            </ng-template>
        </p-column>
        <p-column field="typeName" header="{{ 'PRICE_TABLES.FORM.TYPE' | translate }}" [sortable]="false"
                  [filter]="showFilters"
                  filterMatchMode="contains" [filterValues]="availableTypes">
            <ng-template let-t2ws="rowData" pTemplate="body">
                {{typeNameParser(t2ws.typeName)}}
            </ng-template>
        </p-column>
        <p-column field="priceTableId" header="{{ 'PRICE_TABLES.FORM.TABLE' | translate }}">
            <ng-template let-type2windowSystem="rowData" pTemplate="body">
                <app-simple-button *ngIf="type2windowSystem.priceTableId && type2windowSystem.priceTableItemsPresent"
                                   icon="check" type="confirm" [size]="36"
                                   (onClick)="editPricetable(type2windowSystem)"></app-simple-button>
                <app-simple-button *ngIf="!type2windowSystem.priceTableId || !type2windowSystem.priceTableItemsPresent"
                                   icon="create" type="delete" [size]="36"
                                   (onClick)="editPricetable(type2windowSystem)"></app-simple-button>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
    <app-window-pricingtables *ngIf="displayDialog" [priceTables]="priceTables"
                              [selectedPricelistIsFuture]="selectedPricelistIsFuture"
                              [canDownloadExternalPriceTable]="!!downloadFromExternalApiUrl"
                              [tableInfo]="selectedTableInfo" (addChildPricetable)="addChildPricetable($event)"
                              (close)="closeDialog()"
                              (submit)="submitPricingTable($event.priceTables)"
                              [target]="targets.WINDOWS"
                              (downloadExternalPriceTable)="downloadExternalPriceTable($event.code, $event.future)"></app-window-pricingtables>
</div>
