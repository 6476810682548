import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {TranslateModule} from '@ngx-translate/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CalendarModule} from 'primeng/calendar';
import {DialogModule} from 'primeng/dialog';
import {TooltipModule} from 'primeng/tooltip';
import {FieldLabelWithButtonComponent} from './field-label-with-button/field-label-with-button.component';
import {CalendarComponent} from './inputs/calendar/calendar.component';
import {CheckboxComponent} from './inputs/checkbox/checkbox.component';
import {FileUploadComponent} from './inputs/file-upload/file-upload.component';
import {InputNumberComponent} from './inputs/input-number/input-number.component';
import {InputPasswordComponent} from './inputs/input-password/input-password.component';
import {InputTextComponent} from './inputs/input-text/input-text.component';
import {InputTooltipDirective} from './inputs/input-tooltip/input-tooltip.directive';
import {RadioButtonComponent} from './inputs/radio-button/radio-button.component';
import {SelectComponent} from './inputs/select/select.component';
import {TextAreaComponent} from './inputs/text-area/text-area.component';
import {TristateCheckboxComponent} from './inputs/tristate-checkbox/tristate-checkbox.component';
import {SimpleButtonComponent} from './simple-button/simple-button.component';
import {WizardDialogComponent} from './wizard/wizard-dialog.component';
import {WizardStepComponent} from './wizard/wizard-step.component';
import {WizardComponent} from './wizard/wizard.component';

@NgModule({
    imports: [
        BrowserAnimationsModule,
        ImageCropperModule,
        CommonModule,
        FormsModule,
        TranslateModule.forRoot(),
        // primeng
        CalendarModule,
        DialogModule,
        TooltipModule
    ],
    exports: [
        CalendarComponent,
        CheckboxComponent,
        FileUploadComponent,
        InputNumberComponent,
        InputPasswordComponent,
        InputTextComponent,
        RadioButtonComponent,
        SelectComponent,
        SimpleButtonComponent,
        TextAreaComponent,
        TristateCheckboxComponent,
        WizardComponent,
        WizardDialogComponent,
        WizardStepComponent,
        FieldLabelWithButtonComponent,
        InputTooltipDirective
    ],
    declarations: [
        CalendarComponent,
        CheckboxComponent,
        FileUploadComponent,
        InputNumberComponent,
        InputPasswordComponent,
        InputTextComponent,
        RadioButtonComponent,
        SelectComponent,
        SimpleButtonComponent,
        TextAreaComponent,
        TristateCheckboxComponent,
        WizardComponent,
        WizardDialogComponent,
        WizardStepComponent,
        FieldLabelWithButtonComponent,
        InputTooltipDirective
    ]
})
export class FormInputsModule {
}
