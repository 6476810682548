import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {AddonCategoryGroup} from "./addon-category-group";
import {CatalogSection} from "../addons/CatalogSection";

@Injectable()
export class AddonCategoryGroupService implements CrudService<AddonCategoryGroup> {

    static readonly API_URL = 'addonCategoryGroup';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<AddonCategoryGroup>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<AddonCategoryGroup>>(AddonCategoryGroupService.API_URL, {params: params});
    }

    getItem(itemId: number): Observable<AddonCategoryGroup> {
        return this.http.get<AddonCategoryGroup>(`${AddonCategoryGroupService.API_URL}/${itemId}`);
    }

    addItem(item: AddonCategoryGroup): Observable<number> {
        return this.save(item);
    }

    editItem(itemId: number, item: AddonCategoryGroup): Observable<number> {
        return this.save(item);
    }

    delete(item: AddonCategoryGroup): Observable<void> {
        return this.http.delete<void>(`${AddonCategoryGroupService.API_URL}/${item.id}`);
    }

    save(item: AddonCategoryGroup): Observable<number> {
        return this.http.post(AddonCategoryGroupService.API_URL, item, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(AddonCategoryGroupService.API_URL, response)));
    }

    validate(option: AddonCategoryGroup): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${AddonCategoryGroupService.API_URL}/validate`, option));
    }

    getGroupsForGateDeep(gateSystemId: number, selectedIds: number[] = null): Observable<AddonCategoryGroup[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        return this.http.get<AddonCategoryGroup[]>(`${AddonCategoryGroupService.API_URL}/groupsForGateSystem/${gateSystemId}`,
            {params: params});
    }

    getGroupsForConfigDeep(configSystemId: number, selectedIds: number[] = null, includeAutoOptions = false): Observable<AddonCategoryGroup[]> {
        const params = {
            includeAutoOptions: `${includeAutoOptions}`,
            ...(selectedIds ? {selectedIds: selectedIds.toString()} : {})
        };
        return this.http.get<AddonCategoryGroup[]>(`${AddonCategoryGroupService.API_URL}/groupsForConfigSystem/${configSystemId}`,
            {params: params});
    }

    getGroupsForConfigSystemDefinition(): Observable<AddonCategoryGroup[]> {
        let filters = {};
        filters['target'] = {
            value: CatalogSection.CONFIG
        };
        filters['withCategories'] = {
            value: 'true'
        };
        return this.getItems(null, null, filters, 'sortIndex', 1)
            .pipe(map(data => data.data));
    }
}
