import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from 'rxjs';
import {StorageKey} from '../../auth/storage.service';

@Injectable()
export class IsAnotherUserLoggedService {

    private itemSource: BehaviorSubject<boolean>;
    public item$: Observable<boolean>;

    constructor() {
        this.itemSource = new BehaviorSubject(window.localStorage.getItem(StorageKey.MAIN_JWT.toString()) != undefined);
        this.item$ = this.itemSource.asObservable();
    }

    changeState(isAnotherUserLoggedIn: boolean): void {
        this.itemSource.next(isAnotherUserLoggedIn);
    }

    get isImpersonating(): boolean {
        return this.itemSource.getValue();
    }
}

let isAnotherUserLoggedService: IsAnotherUserLoggedService = undefined;
export function isAnotherUserLoggedServiceSingletonFactory() {
    if (isAnotherUserLoggedService == undefined) {
        isAnotherUserLoggedService = new IsAnotherUserLoggedService();
    }
    return isAnotherUserLoggedService;
}
