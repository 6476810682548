import {NavigationLink} from '../../NavigationLink';

export enum MenuElementType {
    REGULAR, NAVIGATION_LINK, LANGUAGE_SELECTOR, ICON_LINE
}

export class MenuElement {
    title: string;
    translationKey: string;
    identifier: string;
    type: MenuElementType;
    navigationLink: NavigationLink;
    translateElementTitle = false;
    disabled = false;
    tooltip: string;
    productMark: string;
}

export class MenuElementBuilder {
    menuElement: MenuElement;

    constructor() {
        this.menuElement = new MenuElement();
        this.menuElement.type = MenuElementType.REGULAR;
    }

    public setTitle(title: string): MenuElementBuilder {
        if (this.menuElement.translationKey) {
            this.menuElement.translationKey = undefined;
            console.warn("Already provided translation key, do not assign title at the same time!");
        }

        this.menuElement.title = title;

        return this;
    }

    public setTranslationKey(translationKey: string): MenuElementBuilder {
        if (this.menuElement.title) {
            this.menuElement.title = undefined;
            console.warn("Already provided title, do not assign translation key at the same time!");
        }

        this.menuElement.translationKey = translationKey;

        return this;
    }

    public setIdentifier(identifier: string): MenuElementBuilder {
        this.menuElement.identifier = identifier;

        return this;
    }

    public setType(type: MenuElementType): MenuElementBuilder {
        this.menuElement.type = type;

        return this;
    }

    public setNavigationLink(link: NavigationLink): MenuElementBuilder {
        this.menuElement.navigationLink = link;

        return this;
    }

    public setDisabled(disabled = true): MenuElementBuilder {
        this.menuElement.disabled = disabled;

        return this;
    }

    public setTooltip(code: string): MenuElementBuilder {
        this.menuElement.tooltip = code;

        return this;
    }

    public translateButtonText(): MenuElementBuilder {
        this.menuElement.translateElementTitle = true;

        return this;
    }

    public setProductMark(mark: string): MenuElementBuilder {
        this.menuElement.productMark = mark;
        return this;
    }

    public build(): MenuElement {
        return this.menuElement;
    }
}
