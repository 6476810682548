<div class="app-field-label">
    <label [attr.for]="inputId" (click)="labelClick($event)">
        {{ label }}

        <div class="new-form-field-label-action-container">
            <app-simple-button label="{{ buttonLabel }}" [inlineLabel]="false" [icon]="icon" type="filter"
                               [size]="28" [disabled]="disabled" (onClick)="buttonClick($event)"></app-simple-button>
        </div>
    </label>
</div>
