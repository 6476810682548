import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";
import {SupplierInterface} from "../../../../window-designer/catalog-data/supplier-interface";

@JsonDeserializable
export class GateSystem implements CrudItem {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    pcn: string;
    supplier: SupplierInterface;
    active = true;
    sortIndex: number;
    image: string;
    description: { [propertyId: number]: number; } = {};
    dimensionStep = 1;
    unitWeight: number;

    static fromJSON(jsonObject: any): GateSystem {
        const gate = new GateSystem();
        gate.id = jsonObject.id;
        gate.name = MultilanguageField.fromJSON(jsonObject.name);
        gate.shortcut = MultilanguageField.fromJSON(jsonObject.shortcut);
        gate.symbol = jsonObject.symbol;
        gate.pcn = jsonObject.pcn;
        gate.supplier = jsonObject.supplier;
        gate.active = jsonObject.active;
        gate.sortIndex = jsonObject.sortIndex;
        gate.image = jsonObject.image;
        gate.description = {};
        gate.dimensionStep = jsonObject.dimensionStep;
        gate.unitWeight = jsonObject.unitWeight;
        return gate;
    }
}
