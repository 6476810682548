import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {WindowData} from "../../../../../window-designer/drawing-data/WindowData";
import {ConfigAddonApplication} from "../../../../../window-designer/enums/ConfigAddonApplication";
import {Position} from "../../offers/position/position-list/position";

export class OfferComponentsCounter {

    public static singleDataSubwindowsCount(data: DrawingData): number {
        return data.windows.map(window => window.subWindows.length).reduce(((prev, curr) => prev + curr), 0);
    }

    private static singleDataAreasCount(data: DrawingData): number {
        return data.windows.map(window => this.singleWindowAreasCount(window)).reduce(((prev, curr) => prev + curr), 0);
    }

    private static singleWindowAreasCount(window: WindowData) {
        return window.subWindows.map(subwindow => subwindow.areasSpecification.length)
            .reduce(((prev, curr) => prev + curr), 0);
    }

    static countApplicable(positions: Position[], application: ConfigAddonApplication): number {
        let count;
        switch (application) {
            case ConfigAddonApplication.WINDOW:
                count = positions.length;
                break;
            case ConfigAddonApplication.SUBWINDOW:
                count = positions.map(position => this.singleDataSubwindowsCount(JSON.parse(position.data)))
                    .reduce(((prev, curr) => prev + curr), 0);
                break;
            case ConfigAddonApplication.AREA:
                count = positions.map(position => this.singleDataAreasCount(JSON.parse(position.data)))
                    .reduce(((prev, curr) => prev + curr), 0);
                break;
            default:
                throw new Error("Unsupported application: " + application);
        }
        return count;
    }
}
