import {ConjunctionCoupler} from "./conjunction-data";

export abstract class ConjunctionLabelGenerator {

    private static couplerLabelCharCode: number;
    private static readonly initialCouplerLabelCharCode = 'A'.charCodeAt(0) - 1;
    private static readonly maxCouplerLabelCharCode = 'Z'.charCodeAt(0);
    private static readonly replacementCouplerLabelCharCode = '?'.charCodeAt(0);

    public static generateCouplerLabels(couplers: ConjunctionCoupler[]): void {
        this.couplerLabelCharCode = this.initialCouplerLabelCharCode;
        let labels: Map<number, string> = new Map<number, string>();
        couplers.map(c => {
            let key: number = c.id || -c.width;
            let label = labels.get(key);
            if (label == undefined) {
                label = this.getNextCouplerLabel();
                labels.set(key, label);
            }
            c.label = label;
        });
    }

    private static getNextCouplerLabel(): string {
        this.couplerLabelCharCode++;
        return String.fromCharCode(
            this.couplerLabelCharCode > this.maxCouplerLabelCharCode ? this.replacementCouplerLabelCharCode : this.couplerLabelCharCode);
    }
}
