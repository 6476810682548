import {ProductionOrderConversionPoint} from "./production-order-conversion-point";
import {JsonDeserializable} from "../../../common/crud-common/crudItem";

@JsonDeserializable
export class ProductionOrderConversions {

    label: string;
    data: ProductionOrderConversionPoint[];

    static fromJSON(jsonObject: any): ProductionOrderConversions {
        const statistics = new ProductionOrderConversions();
        statistics.label = jsonObject.label;
        statistics.data = (jsonObject.data || []).map(ProductionOrderConversionPoint.fromJSON);
        return statistics;
    }
}
