import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SkipInterceptorsInterceptor} from '../common/http/skip-interceptors.interceptor';
import {UrlInterceptor} from '../common/http/url.interceptor';
import {ApplicationFeatures} from './application-features';
import {ApplicationInfo} from './application-info';

@Injectable()
export class ApplicationInfoService {

    constructor(public http: HttpClient) {
    }

    getBackendInfo(): Observable<ApplicationInfo> {
        return this.http.get<ApplicationInfo>('applicationInfo/getInfo');
    }

    getFrontendInfo() {
        return this.http.get<object>(`${window.location.origin}/git.json`, {
            headers: {
                [SkipInterceptorsInterceptor.HEADER]: UrlInterceptor.NAME
            }
        });
    }

    getFeatures(): Observable<ApplicationFeatures> {
        return this.http.get<ApplicationFeatures>('applicationInfo/features');
    }
}
