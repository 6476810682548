<ng-template #unavailableTemplate let-id="id" let-items="items" let-canBeEmpty="canBeEmpty">
    <div *ngIf="id | isNotAvailable: items: canBeEmpty" class="new-form-field-error-message">
        {{ 'ERROR.DESIGNER.NO_LONGER_AVAILABLE' | translate }}
    </div>
</ng-template>

<p-accordion #mainAcc [multiple]="false" class="gate-designer-tab" id="general-tab">
    <!--Main-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.MAIN.SECTION_TITLE' | translate }}"
                    [(selected)]="accordionTabState.main" id="mainSectionAccordion" name="mainSectionAccordion"
                    [ngClass]="{'accordion-header-with-errors': fields.isAnyErrorPresentInGeneralAccordionTab(validationErrors)}">

        <!-- Gate system -->
        <div class="new-form-row" *ngIf="fields.isVisible(Fields.GATE_SYSTEM)">
            <div class="input-with-action-container">
                <div class="new-form-field">
                    <label>{{ 'OFFER.TABS.SECTION.MAIN.GATE_SYSTEM' | translate }}</label>
                    {{ selectedGateSystem?.name | multilanguageTranslate }}
                </div>
            </div>
            <div class="new-form-field flex-row">
                <span class="spacer"></span>
                <app-simple-button buttonId="gateSystemChange" [disabled]="readOnlyMode"
                                   label="{{ 'OFFER.TABS.SECTION.MAIN.GATE_SYSTEM_CHANGE' | translate }}"
                                   [inlineLabel]="false" icon="swap_horiz" type="filter" align="right"
                                   tooltipPosition="left" (onClick)="onGateSystemChange.emit()"></app-simple-button>
            </div>
        </div>

        <!--Quantity-->
        <div class="new-form-row" *ngIf="fields.isVisible(Fields.QUANTITY)">
            <app-input-number [inputId]="Fields.QUANTITY +'_id'"
                              label="{{ 'OFFER.TABS.SECTION.MAIN.QUANTITY' | translate }}"
                              [(validationMessageKey)]="validationErrors[Fields.QUANTITY]" [disabled]="readOnlyMode"
                              (onFocus)="accordionTabState.main = true" [min]="1" [max]="1000000"
                              [allowOnlyIntegers]="true" [(ngModel)]="position.quantity"
                              [modelOptions]="{updateOn: 'blur'}">
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-number>
        </div>

        <div class="new-form-row" *ngIf="fields.isVisible(Fields.WIDTH) || fields.isVisible(Fields.HEIGHT)">
            <app-input-number *ngIf="fields.isVisible(Fields.WIDTH)" [inputId]="Fields.WIDTH +'_id'"
                              label="{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.WIDTH' | translate }}"
                              [(validationMessageKey)]="validationErrors[Fields.WIDTH]" [disabled]="readOnlyMode"
                              (onFocus)="accordionTabState.main = true"
                              [shouldRoundToStep]="true" (onBlur)="handleFieldBlur(Fields.WIDTH, 'width')"
                              [min]="1000" [max]="20000" [step]="selectedGateSystem?.dimensionStep"
                              [allowOnlyIntegers]="true" [ngModel]="data.width"
                              (ngModelChange)="handleFieldValueChange(Fields.WIDTH, 'width', $event)">
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-number>
            <app-input-number *ngIf="fields.isVisible(Fields.HEIGHT)" [inputId]="Fields.HEIGHT +'_id'"
                              label="{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.HEIGHT' | translate }}"
                              [(validationMessageKey)]="validationErrors[Fields.HEIGHT]" [disabled]="readOnlyMode"
                              (onFocus)="accordionTabState.main = true"
                              [shouldRoundToStep]="true" (onBlur)="handleFieldBlur(Fields.HEIGHT, 'height')"
                              [min]="1000" [max]="20000" [step]="selectedGateSystem?.dimensionStep"
                              [allowOnlyIntegers]="true" [ngModel]="data.height"
                              (ngModelChange)="handleFieldValueChange(Fields.HEIGHT, 'height', $event)">

                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-number>
        </div>

        <!-- Rail system -->
        <div class="new-form-row" *ngIf="fields.isVisible(Fields.RAIL_SYSTEM)">
            <ng-container *vLet="gateEditorFieldContentProvider.getItemsStream(Fields.RAIL_SYSTEM) | async as items">
                <app-select
                    [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.RAIL_SYSTEM)}"
                    [inputId]="Fields.RAIL_SYSTEM + '_id'"
                    label="{{ 'OFFER.TABS.SECTION.MAIN.RAIL_SYSTEM' | translate }}"
                    [(validationMessageKey)]="validationErrors[Fields.RAIL_SYSTEM]" [disabled]="readOnlyMode"
                    (onFocus)="accordionTabState.main = true" [options]="items" [checkAvailability]="true"
                    [noLongerAvailable]="data.railSystemId | isNotAvailable:items:true"
                    (onClick)="gateEditorFieldContentProvider.clearHasNewItemsMarker(Fields.RAIL_SYSTEM)"
                    [ngModel]="data.railSystemId"
                    (ngModelChange)="handleFieldValueChange(Fields.RAIL_SYSTEM, 'railSystemId', $event)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label" [disabled]="!data.railSystemId"
                                                 (onShowImage)="handleShowImage(imageService.getRailSystemImage(data.railSystemId), label)"></app-sidebar-field-label>
                    </ng-template>
                    <ng-template pTemplate="validationMessage">
                        <ng-container
                            *ngTemplateOutlet="unavailableTemplate; context: { id: data.railSystemId, items: items, canBeEmpty: true }"></ng-container>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <div class="new-form-row" *ngIf="fields.isVisible(Fields.LINTEL_HEIGHT)">
            <app-input-number [inputId]="Fields.LINTEL_HEIGHT +'_id'"
                              label="{{ 'OFFER.TABS.SECTION.MAIN.DIMENSIONS.LINTEL_HEIGHT' | translate }}"
                              [(validationMessageKey)]="validationErrors[Fields.LINTEL_HEIGHT]"
                              [disabled]="readOnlyMode" (onFocus)="accordionTabState.main = true"
                              [min]="50" [max]="10000" [step]="1" (onBlur)="handleFieldBlur(Fields.LINTEL_HEIGHT, 'lintelHeight')"
                              [allowOnlyIntegers]="true" [ngModel]="data.lintelHeight"
                              (ngModelChange)="handleFieldValueChange(Fields.LINTEL_HEIGHT, 'lintelHeight', $event)">
                <ng-template let-inputId="inputId" let-label="label" pTemplate="label">
                    <app-sidebar-field-label (onShowImage)="handleShowImage(lintelImageUrl, label)" [inputId]="inputId"
                                             [label]="label"></app-sidebar-field-label>
                </ng-template>
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-number>
        </div>

        <ng-container *ngIf="fields.isVisible(Fields.GATE_WALL)">
            <ng-container *vLet="gateEditorFieldContentProvider.getItemsStream(Fields.GATE_WALL) | async as items">
                <div [class]="Fields.GATE_WALL + '_1_id'" class="new-form-row">
                    <app-select
                        [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.GATE_WALL)}"
                        [inputId]="Fields.GATE_WALL + '_1_id'"
                        label="{{ 'OFFER.TABS.SECTION.MAIN.GATE_WALL_1' | translate }}"
                        [(validationMessageKey)]="validationErrors[Fields.GATE_WALL + '_1']" [disabled]="readOnlyMode"
                        (onFocus)="accordionTabState.main = true" [options]="items" [checkAvailability]="true"
                        [noLongerAvailable]="data.wall1 | isNotAvailable:items:true"
                        (onClick)="handleGateWall1SelectClick($event)"
                        [ngModel]="data.wall1" [fakeSelect]="true"
                        (ngModelChange)="handleFieldValueChange(Fields.GATE_WALL, 'wall1', $event)">
                        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label" [disabled]="!data.wall1"
                                                     (onShowImage)="handleShowImage(imageService.getGateWallImage(data.wall1), label)"></app-sidebar-field-label>
                        </ng-template>
                        <ng-template pTemplate="validationMessage">
                            <ng-container
                                *ngTemplateOutlet="unavailableTemplate; context: { id: data.wall1, items: items, canBeEmpty: true }"></ng-container>
                        </ng-template>
                    </app-select>
                </div>

                <div [class]="Fields.GATE_WALL + '_2_id'" class="new-form-row">
                    <app-select
                        [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.GATE_WALL)}"
                        [inputId]="Fields.GATE_WALL + '_2_id'"
                        label="{{ 'OFFER.TABS.SECTION.MAIN.GATE_WALL_2' | translate }}"
                        [(validationMessageKey)]="validationErrors[Fields.GATE_WALL + '_2']" [disabled]="readOnlyMode"
                        (onFocus)="accordionTabState.main = true" [options]="items" [checkAvailability]="true"
                        [noLongerAvailable]="data.wall2 | isNotAvailable:items:true"
                        (onClick)="handleGateWall2SelectClick($event)"
                        [ngModel]="data.wall2" [fakeSelect]="true"
                        (ngModelChange)="handleFieldValueChange(Fields.GATE_WALL, 'wall2', $event)">
                        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label" [disabled]="!data.wall2"
                                                     (onShowImage)="handleShowImage(imageService.getGateWallImage(data.wall2), label)"></app-sidebar-field-label>
                        </ng-template>
                        <ng-template pTemplate="validationMessage">
                            <ng-container
                                *ngTemplateOutlet="unavailableTemplate; context: { id: data.wall2, items: items, canBeEmpty: true }"></ng-container>
                        </ng-template>
                    </app-select>
                </div>
            </ng-container>
        </ng-container>

        <!-- Panel type -->
        <div *ngIf="fields.isVisible(Fields.GATE_PANEL_TYPE)" [class]="Fields.GATE_PANEL_TYPE + '_id'" class="new-form-row">
            <ng-container
                *vLet="gateEditorFieldContentProvider.getItemsStream(Fields.GATE_PANEL_TYPE) | async as items">
                <app-select
                    [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.GATE_PANEL_TYPE)}"
                    [inputId]="Fields.GATE_PANEL_TYPE + '_id'" [fakeSelect]="true"
                    label="{{ 'OFFER.TABS.SECTION.MAIN.GATE_PANEL_TYPE' | translate }}"
                    [(validationMessageKey)]="validationErrors[Fields.GATE_PANEL_TYPE]" [disabled]="readOnlyMode"
                    (onFocus)="accordionTabState.main = true" [options]="items" [checkAvailability]="true"
                    (onClick)="handleGatePanelTypeSelectClick($event)" [ngModel]="data.gatePanelTypeId"
                    [noLongerAvailable]="data.gatePanelTypeId | isNotAvailable:items:true"
                    (ngModelChange)="handleFieldValueChange(Fields.GATE_PANEL_TYPE, 'gatePanelTypeId', $event)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label" [disabled]="!data.gatePanelTypeId"
                                                 (onShowImage)="handleShowImage(imageService.getGatePanelTypeImage(data.gatePanelTypeId), label)"></app-sidebar-field-label>
                    </ng-template>
                    <ng-template pTemplate="validationMessage">
                        <ng-container
                            *ngTemplateOutlet="unavailableTemplate; context: { id: data.gatePanelTypeId, items: items, canBeEmpty: true }"></ng-container>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <!-- Core color -->
        <div class="new-form-row" *ngIf="fields.isVisible(Fields.CORE_COLOR)">
            <ng-container *vLet="gateEditorFieldContentProvider.getItemsStream(Fields.CORE_COLOR) | async as items">
                <app-select
                        [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.CORE_COLOR)}"
                        [inputId]="Fields.CORE_COLOR + '_id'"
                        label="{{ 'OFFER.TABS.SECTION.COLOR.GATE_CORE_COLOR' | translate }}"
                        [(validationMessageKey)]="validationErrors[Fields.CORE_COLOR]" [disabled]="readOnlyMode"
                        (onFocus)="accordionTabState.main = true" [options]="items" [checkAvailability]="true"
                        (onClick)="gateEditorFieldContentProvider.clearHasNewItemsMarker(Fields.CORE_COLOR)"
                        [ngModel]="data.coreColorId"
                        [noLongerAvailable]="data.coreColorId | isNotAvailable:items:true"
                        (ngModelChange)="handleFieldValueChange(Fields.CORE_COLOR, 'coreColorId', $event)">
                    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                        <app-sidebar-field-label [inputId]="inputId" [label]="label" [disabled]="!data.coreColorId"
                                                 (onShowImage)="handleShowImage(imageService.getColorImage(data.coreColorId), label)"></app-sidebar-field-label>
                    </ng-template>
                    <ng-template pTemplate="validationMessage">
                        <ng-container
                                *ngTemplateOutlet="unavailableTemplate; context: { id: data.coreColorId, items: items, canBeEmpty: true }"></ng-container>
                    </ng-template>
                </app-select>
            </ng-container>
        </div>

        <ng-container *ngIf="!fields.isVisible(Fields.CORE_COLOR)">
            <ng-container *ngTemplateOutlet="coveringColors; context: {
            labelExternal: 'OFFER.TABS.SECTION.COLOR.EXTERNAL_GATE_COLOR',
            labelInternal: 'OFFER.TABS.SECTION.COLOR.INTERNAL_GATE_COLOR',
            placeholder: '',
            allowSelectingNone: false}"></ng-container>
        </ng-container>

        <!-- Control -->
        <div class="new-form-row" *ngIf="fields.isVisible(Fields.CONTROL)">
            <ng-container *vLet="gateEditorFieldContentProvider.getItemsStream(Fields.CONTROL) | async as items">
                <app-select [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.CONTROL)}"
                            [inputId]="Fields.CONTROL + '_id'"
                            label="{{ 'OFFER.TABS.SECTION.MAIN.CONTROL' | translate }}"
                            [(validationMessageKey)]="validationErrors[Fields.CONTROL]" [disabled]="readOnlyMode"
                            (onFocus)="accordionTabState.main = true" [options]="items" [checkAvailability]="false"
                            (onClick)="gateEditorFieldContentProvider.clearHasNewItemsMarker(Fields.CONTROL)"
                            [ngModel]="data.control"
                            (ngModelChange)="handleFieldValueChange(Fields.CONTROL, 'control', $event)">
                    <ng-template pTemplate="validationMessage"></ng-template>
                </app-select>
            </ng-container>
        </div>
    </p-accordionTab>

    <!--Covering colors-->
    <p-accordionTab *ngIf="fields.isVisible(Fields.COVERING_COLORS)"
                    [ngClass]="{'accordion-header-with-errors': fields.isAnyErrorPresentInTab(validationErrors)}"
                    header="{{ 'OFFER.TABS.SECTION.COVERING_COLOR.SECTION_TITLE' | translate }}" id="coveringColorsAccordion"
                    [(selected)]="accordionTabState.covering" name='coveringColorsAccordion' >
        <ng-container *ngTemplateOutlet="coveringColors; context: {
            labelExternal: 'OFFER.TABS.SECTION.COLOR.EXTERNAL_GATE_COVERING_COLOR',
            labelInternal: 'OFFER.TABS.SECTION.COLOR.INTERNAL_GATE_COVERING_COLOR',
            placeholder: 'GENERAL.OPTION_GATE_PANEL_COLOR',
            allowSelectingNone: true}"></ng-container>
    </p-accordionTab>

    <p-accordionTab *ngFor="let group of addonCategoryGroups; trackBy: trackByGroup" header="{{ group.name[translate.currentLang] }}"
                    [(selected)]="accordionTabState[group.id]" [id]="addonCategoryGroupName + group.id"
                    [ngClass]="{'accordion-header-with-errors': fields.isAnyErrorPresentInTab(validationErrors, group.id)}">

        <ng-container *ngFor="let category of group.categories">
            <ng-container *ngIf="fields.isVisible(category.symbol)">
                <ng-container *vLet="gateEditorFieldContentProvider.getItemsStream(category.symbol) | async as items">
                    <app-select-or-checkbox
                            [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(category.symbol)}"
                            [inputId]="category.symbol + '_id'" label="{{ category.name[translate.currentLang] }}"
                            [(validationMessageKey)]="validationErrors[group.id + '_' + category.symbol]"
                            [disabled]="readOnlyMode" (onFocus)="accordionTabState[group.id] = true" [options]="items"
                            [checkAvailability]="true" [allowSelectingNone]="!category.required"
                            (onClick)="gateEditorFieldContentProvider.clearHasNewItemsMarker(category.symbol)"
                            [value]="data.sidebarAddons[category.symbol]"
                            [noLongerAvailable]="data.sidebarAddons[category.symbol] | isNotAvailable:items:true"
                            (valueChange)="handleSidebarFieldValueChange(category.symbol, category.symbol, $event)">
                        <ng-template #labelTemplate let-disabled="disabled" let-inputId="inputId" let-itemId="itemId" let-label="label">
                            <app-sidebar-field-label [inputId]="inputId" [label]="label"
                                                     [tooltipLabel]="category.info[translate.currentLang]"
                                                     (onShowImage)="handleShowImage(imageService.getAddonImage(itemId), label)"
                                                     [disabled]="disabled"></app-sidebar-field-label>
                        </ng-template>
                        <ng-template #validationMessageTemplate>
                            <ng-container
                                    *ngTemplateOutlet="unavailableTemplate; context: { id: data.sidebarAddons[category.symbol], items: items, canBeEmpty: true }"></ng-container>
                        </ng-template>
                    </app-select-or-checkbox>
                </ng-container>
            </ng-container>
        </ng-container>
    </p-accordionTab>

    <!--Addons-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.ADDONS.SECTION_TITLE' | translate }}"
                    *ngIf="fields.isVisible(Fields.ADDONS)"
                    id="addonsAccordion" name='addonsAccordion' class="p-accordion-button"
                    (click)="showAddonsDialog()">
    </p-accordionTab>

    <!--Description-->
    <p-accordionTab header="{{ 'OFFER.TABS.SECTION.DESCRIPTION.SECTION_TITLE' | translate }}"
                    *ngIf="fields.isVisible(Fields.DESCRIPTION)" [(selected)]="accordionTabState.description"
                    id="descriptionAccordion" name='descriptionAccordion' class="p-accordion-button"
                    (click)="showDescriptionDialog()"></p-accordionTab>

    <!--Global settings-->
    <p-accordionTab *ngIf="!readOnlyMode" header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }}"
                    [(selected)]="accordionTabState.defaults" id="globalSettingsAccordion"
                    name='globalSettingsAccordion'>

        <div class="new-form-row">
            <app-select inputId="globalSettingsSelect" [(validationMessageKey)]="validationErrors['level']"
                        [options]="defaultsLevels" [required]="false"
                        (onFocus)="accordionTabState.defaults = true" [ngModel]="defaultsLevel"
                        [translateLabels]="true" (ngModelChange)="defaultsLevelChange.emit($event)">
                <ng-template pTemplate="label"></ng-template>
            </app-select>
        </div>

        <div class="new-form-row" *ngIf="!fields.sidebarOnlyMode">
            <app-checkbox inputId="globalSettingsOverrideLowerLevels"
                          label="{{ 'OFFER.TABS.SECTION.DEFAULTS.OVERRIDE' | translate }}"
                          [ngModel]="defaultsOverrideLowerLevel"
                          (ngModelChange)="defaultsOverrideLowerLevelChange.emit($event)"></app-checkbox>
        </div>

        <div class="new-form-row">
            <div class="new-form-field">
                <app-simple-button id="globalSettingsSaveButtonContainer" buttonId="globalSettingsSaveButton"
                                   label="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}" type="main-action"
                                   [size]="40" fixedWidth="100%" (onClick)="defaultsSavedClick.emit($event)"
                                   (onFocus)="accordionTabState.defaults = true"></app-simple-button>
            </div>
        </div>

        <div *ngIf="data.usedGlobalSettingsLevel && !data.usedGlobalSettingsChanged" class="new-form-row">
            <div class="new-form-field">
                {{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }}
                - {{ 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.' + data.usedGlobalSettingsLevel | translate }}
            </div>
        </div>

        <div *ngIf="data.usedGlobalSettingsLevel && data.usedGlobalSettingsChanged" class="new-form-row">
            <div class="new-form-field">
                {{ 'OFFER.TABS.SECTION.DEFAULTS.SECTION_TITLE' | translate }}
                - {{ 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.' + data.usedGlobalSettingsLevel | translate }}
                - {{ 'OFFER.TABS.SECTION.DEFAULTS.MODIFIED' | translate }}
            </div>
        </div>
    </p-accordionTab>
</p-accordion>

<app-ral-color-selection-dialog *ngIf="selectingRalColors != undefined" [header]="selectingRalColorHeader | translate"
                                [colors]="selectingRalColors" (onColorSelect)="handleRalColorSelected($event)"
                                (onCancel)="handleRalColorSelectionCancel()"></app-ral-color-selection-dialog>

<app-gate-panel-type-selection-dialog *ngIf="displayGatePanelTypeDialog" [gatePanelTypeId]="data.gatePanelTypeId"
                                      (gatePanelTypeIdChange)="handleGatePanelTypeSelected($event)"
                                      (onClose)="displayGatePanelTypeDialog = false" [checkAvailability]="true"></app-gate-panel-type-selection-dialog>

<app-gate-wall-selection-dialog *ngIf="displayGateWall1Dialog" [gateWallId]="data.wall1" [wallIndex]="1"
                                (gateWallIdChange)="handleGateWall1Selected($event)"
                                (onClose)="displayGateWall1Dialog = false" [checkAvailability]="true"></app-gate-wall-selection-dialog>

<app-gate-wall-selection-dialog *ngIf="displayGateWall2Dialog" [gateWallId]="data.wall2" [wallIndex]="2"
                                (gateWallIdChange)="handleGateWall2Selected($event)"
                                (onClose)="displayGateWall2Dialog = false" [checkAvailability]="true"></app-gate-wall-selection-dialog>

<ng-template #coveringColors let-allowSelectingNone="allowSelectingNone" let-labelExternal="labelExternal" let-labelInternal="labelInternal"
                             let-placeholder="placeholder">
    <!-- External color -->
    <div *ngIf="fields.isVisible(Fields.EXTERNAL_COLOR)" class="new-form-row">
        <ng-container *vLet="externalColors | async as items">
            <app-select
                (ngModelChange)="handleColorChange(Fields.EXTERNAL_COLOR, 'externalColorId', $event)"
                (onClick)="gateEditorFieldContentProvider.clearHasNewItemsMarker(Fields.EXTERNAL_COLOR)"
                (onFocus)="accordionTabState.covering = true"
                [(validationMessageKey)]="validationErrors[Fields.EXTERNAL_COLOR]"
                [allowSelectingNone]="allowSelectingNone"
                [checkAvailability]="true" [disabled]="readOnlyMode" [inputId]="Fields.EXTERNAL_COLOR + '_id'"
                [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.EXTERNAL_COLOR)}"
                [ngModel]="data.externalColorId"
                [noLongerAvailable]="data.externalColorId | isNotAvailable:items:true"
                [options]="items"
                label="{{ labelExternal | translate }}"
                placeholder="{{ placeholder | translate }}">
                <ng-template let-inputId="inputId" let-label="label" pTemplate="label">
                    <app-sidebar-field-label (onShowImage)="handleShowImage(imageService.getColorImage(data.externalColorId), label)" [disabled]="!data.externalColorId" [inputId]="inputId"
                                             [label]="label"></app-sidebar-field-label>
                </ng-template>
                <ng-template pTemplate="validationMessage">
                    <ng-container
                        *ngTemplateOutlet="unavailableTemplate; context: { id: data.externalColorId, items: items, canBeEmpty: true }"></ng-container>
                </ng-template>
            </app-select>
        </ng-container>
    </div>

    <!-- Internal color -->
    <div *ngIf="fields.isVisible(Fields.INTERNAL_COLOR)" class="new-form-row">
        <ng-container *vLet="internalColors | async as items">
            <app-select
                (ngModelChange)="handleColorChange(Fields.INTERNAL_COLOR, 'internalColorId', $event)"
                (onClick)="gateEditorFieldContentProvider.clearHasNewItemsMarker(Fields.INTERNAL_COLOR)"
                (onFocus)="accordionTabState.covering = true"
                [(validationMessageKey)]="validationErrors[Fields.INTERNAL_COLOR]"
                [allowSelectingNone]="allowSelectingNone"
                [checkAvailability]="true" [disabled]="readOnlyMode" [inputId]="Fields.INTERNAL_COLOR + '_id'"
                [ngClass]="{'has-new-options': gateEditorFieldContentProvider.hasNewItems(Fields.INTERNAL_COLOR)}"
                [ngModel]="data.internalColorId"
                [noLongerAvailable]="data.internalColorId | isNotAvailable:items:true"
                [options]="items"
                label="{{ labelInternal | translate }}"
                placeholder="{{ placeholder | translate }}">
                <ng-template let-inputId="inputId" let-label="label" pTemplate="label">
                    <app-sidebar-field-label (onShowImage)="handleShowImage(imageService.getColorImage(data.internalColorId), label)" [disabled]="!data.internalColorId" [inputId]="inputId"
                                             [label]="label"></app-sidebar-field-label>
                </ng-template>
                <ng-template pTemplate="validationMessage">
                    <ng-container
                        *ngTemplateOutlet="unavailableTemplate; context: { id: data.internalColorId, items: items, canBeEmpty: true }"></ng-container>
                </ng-template>
            </app-select>
        </ng-container>
    </div>
</ng-template>
