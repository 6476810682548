import {AreaSpecification} from '../../../../../window-designer/drawing-data/AreaSpecification';
import {DrawingData} from '../../../../../window-designer/drawing-data/drawing-data';
import {FillingType} from '../../../../../window-designer/drawing-data/FillingType';
import {Grill as DrawingDataGrill} from '../../../../../window-designer/drawing-data/Grill';
import {SubWindowData} from '../../../../../window-designer/drawing-data/SubWindowData';
import {GrillTypes} from '../../../../../window-designer/enums/GrillTypes';
import {Grill} from '../../../window-system/grill/grill';
import {ConfigurableAddonPositionModel} from '../sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {ConfigSystem} from "../../../window-system/config-system/config-system";
import {SubWindowTypeCode} from "../../../../../window-designer/window-types/subwindow-type-code";

export class ConfigAddonValidator {

    public static validateConfigAddons(data: DrawingData, configAddonModels: ConfigurableAddonPositionModel[],
                                       configAddonDefinitions: ConfigSystem[],
                                       grills: Grill[]): boolean {
        return this.validateConfigAddonsForErrors(data, configAddonModels, configAddonDefinitions, grills).length === 0;
    }

    public static validateConfigAddonsForErrors(data: DrawingData, configAddonModels: ConfigurableAddonPositionModel[],
                                                configAddonDefinitions: ConfigSystem[],
                                                grills: Grill[]): string[] {
        let validationErrors: string[] = [];

        let subwindows = data.windows.map(window => window.subWindows)
            .reduce((allSubwindows: SubWindowData[], currentSubwindows: SubWindowData[]) => allSubwindows.concat(currentSubwindows));
        subwindows.forEach(subwindow => this.validateConfigAddonsInSubwindow(subwindow, configAddonModels, configAddonDefinitions, validationErrors));
        subwindows.map(subwindow => subwindow.areasSpecification)
            .reduce((allAreas: AreaSpecification[], currentAreas: AreaSpecification[]) => allAreas.concat(currentAreas))
            .forEach(area => this.validateConfigAddonsInArea(area, configAddonModels, configAddonDefinitions, grills, validationErrors));

        return validationErrors;
    }

    private static validateConfigAddonsInSubwindow(subwindow: SubWindowData, configAddonModels: ConfigurableAddonPositionModel[],
                                              configAddonDefinitions: ConfigSystem[], validationErrors: string[]) {
        subwindow.configurableAddonIds
            .map(configPositionId => this.getDefinition(configAddonModels, configPositionId, configAddonDefinitions))
            .forEach(system => {
                if (system == null) {
                    validationErrors.push('ERRORS.CONFIG_ADDON_NOT_AVAILABLE_IN_SYSTEM');
                    console.warn('Added configurable addon is not available for current window system');
                } else if (system.canBeAddedToOpenableOnly && this.subwindowIsNonOpenable(subwindow)) {
                    validationErrors.push('ERRORS.CONFIG_ADDON_NOT_AVAILABLE_IN_NON_OPENABLE_WINDOW');
                    console.warn('Added configurable addon is not available for non openable window');
                }
        });
    }

    private static validateConfigAddonsInArea(area: AreaSpecification, configAddonModels: ConfigurableAddonPositionModel[],
                                              configAddonDefinitions: ConfigSystem[],
                                              grills: Grill[], validationErrors: string[]) {
        area.configurableAddonIds
            .map(configPositionId => this.getDefinition(configAddonModels, configPositionId, configAddonDefinitions))
            .forEach(system => {
                if (system == null) {
                    validationErrors.push('ERRORS.CONFIG_ADDON_NOT_AVAILABLE_IN_SYSTEM');
                    console.warn('Added configurable addon is not available for current window system');
                } else {
                    this.validateConfigAddon(system, grills, validationErrors, area.filling.type, area.grills);
                }
        });
    }

    private static validateConfigAddon(configAddonDefinition: ConfigSystem,
                                       grills: Grill[], validationErrors: string[], fillingType: FillingType,
                                       areaGrills: DrawingDataGrill[]) {
        if (!configAddonDefinition.canBeAddedToAreaWithOuterGrill && this.areaWithConfigAddonContainsOuterGrill(grills, areaGrills)) {
            validationErrors.push('ERRORS.CONFIG_ADDON_NOT_AVAILABLE_IN_AREA_WITH_OUTER_GRILL');
            console.warn('Added configurable addon is not available for area with outer grill');
        }
        if (!configAddonDefinition.canBeAddedToAreaWithoutFilling && fillingType === FillingType.NO_FILLING) {
            validationErrors.push('ERRORS.CONFIG_ADDON_NOT_AVAILABLE_IN_AREA_WITHOUT_FILLING');
            console.warn('Added configurable addon is not available for area without filling');
        }
        if (!configAddonDefinition.canBeAddedToAreaWithOtherFilling && fillingType === FillingType.FILLING) {
            validationErrors.push('ERRORS.CONFIG_ADDON_NOT_AVAILABLE_IN_AREA_WITH_FILLING');
            console.warn('Added configurable addon is not available for area with filling');
        }
        if (!configAddonDefinition.canBeAddedToAreaWithDecorativeFilling && fillingType === FillingType.DECORATIVE_FILLING) {
            validationErrors.push('ERRORS.CONFIG_ADDON_NOT_AVAILABLE_IN_AREA_WITH_DECORATIVE_FILLING');
            console.warn('Added configurable addon is not available for area with decorative filling');
        }
    }

    private static subwindowIsNonOpenable({typeCode}: SubWindowData) {
        return typeCode === SubWindowTypeCode.F || typeCode === SubWindowTypeCode.FF;
    }

    private static getDefinition(configAddonModels: ConfigurableAddonPositionModel[], configPositionId: number, configSystems: ConfigSystem[]) {
        let configAddonModel = configAddonModels
            .find(model => model.position.id === configPositionId || model.position.assignedId === configPositionId);
        if (configAddonModel == undefined) {
            return undefined;
        }
        return configSystems.find(definition => definition.id === configAddonModel.configurableAddon.definitionId);
    }

    private static areaWithConfigAddonContainsOuterGrill(grills: Grill[], areaGrills: DrawingDataGrill[]): boolean {
        return areaGrills
            .map(areaGrill => grills.find(g => g.id === areaGrill.id))
            .filter(grill => grill != null)
            .some(grill => grill.type === GrillTypes.OUTER);
    }
}
