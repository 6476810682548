<div class="wrapper first-field-wrapper login-wrapper">
    <input id="login" name="login" type="text" placeholder="{{ 'LOGIN.FORM.LOGIN' | translate }}" [(ngModel)]="login" />
</div>
<div class="submit-wrapper">
    <div class="button return" [ngClass]="{ disabled: sendingMailInProgress }" tabindex="0">
        <div (click)="returnClick.emit()">
            {{ 'LOGIN.FORM.RETURN_TO_LOGIN' | translate }}
        </div>
    </div>
    <div class="button submit" [ngClass]="{ disabled: sendingMailInProgress }" tabindex="1">
        <div (click)="handleSendResetPasswordMailClick()">
            {{ 'LOGIN.FORM.SEND_PASSWORD_RESET_MAIL' | translate }}
        </div>
    </div>
</div>
