<div class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.USERS.LIST' | translate}}
        </div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <!-- CLEAR HIDDEN FILTERS -->
                <app-simple-button *ngIf="filterOwnUsersEnabled" label="{{ 'USER-DETAILS.REMOVE_FILTERS' | translate }}"
                                   icon="filter_list" type="action" (onClick)="clearFilterOwnUsers()"></app-simple-button>

                <!-- SHOW OWN USERS -->
                <app-simple-button *ngIf="isShowOwnUsersFilterEnabled() && !filterOwnUsersEnabled"
                                   label="{{ 'USER-DETAILS.SHOW_OWN_USERS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="enableFilterByOwnUsers()"></app-simple-button>

                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.USERS.LIST')"></app-simple-button>
                <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}" [displaySelectedLabel]="false"
                               [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"
                               id="usersShownColumns"></p-multiSelect>
                <app-simple-button *ngIf="permissions.canCreateUser()" buttonId="addUser"
                                   label="{{ 'NAVIGATION.ITEMS.USERS.ADD-USER' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-table #dt [value]="users" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
             (onPage)="chosenPageNumber = $event.first" [lazy]="true" [lazyLoadOnInit]="false" [filterDelay]="400"
             [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" dataKey="id"
             [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [(selection)]="selectedUser" [attr.id]="USERS_TABLE_ID">
        <ng-template pTemplate="header">
            <tr>
                <th *ngIf="permissions.isKoordynator() || permissions.isOpiekun()">
                    <span>{{ 'USER-DETAILS.FORM.ACTIONS_HEADER' | translate }}</span>
                </th>
                <th *ngIf="columnByField['id'] != undefined" pSortableColumn="id" [ngClass]="columnHeaderClasses('id')" [ngStyle]="{ width: '5%' }">
                    <span>{{ 'USER-DETAILS.FORM.ID' | translate }}</span>
                    <p-sortIcon field="id"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="id"
                           (input)="dt.filter(getInputEventValue($event), 'id', 'equals')">
                </th>
                <th *ngIf="columnByField['login'] != undefined" pSortableColumn="login" [ngClass]="columnHeaderClasses('login')">
                    <span>{{ 'USER-DETAILS.FORM.LOGIN' | translate }}</span>
                    <p-sortIcon field="login"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="login"
                           (input)="dt.filter(getInputEventValue($event), 'login', 'contains')">
                </th>
                <th *ngIf="columnByField['firstName'] != undefined" pSortableColumn="firstName" [ngClass]="columnHeaderClasses('firstName')">
                    <span>{{ 'USER-DETAILS.FORM.FIRST_NAME' | translate }}</span>
                    <p-sortIcon field="firstName"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="firstName"
                           (input)="dt.filter(getInputEventValue($event), 'firstName', 'contains')">
                </th>
                <th *ngIf="columnByField['lastName'] != undefined" pSortableColumn="lastName" [ngClass]="columnHeaderClasses('lastName')">
                    <span>{{ 'USER-DETAILS.FORM.LAST_NAME' | translate }}</span>
                    <p-sortIcon field="lastName"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="lastName"
                           (input)="dt.filter(getInputEventValue($event), 'lastName', 'contains')">
                </th>
                <th *ngIf="columnByField['email'] != undefined" pSortableColumn="email" [ngClass]="columnHeaderClasses('email')">
                    <span>{{ 'USER-DETAILS.FORM.EMAIL' | translate }}</span>
                    <p-sortIcon field="email"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="email"
                           (input)="dt.filter(getInputEventValue($event), 'email', 'contains')">
                </th>
                <th *ngIf="columnByField['interfaceLanguage'] != undefined" [ngClass]="columnHeaderClasses('interfaceLanguage')">
                    <span>{{ 'USER-DETAILS.FORM.INTERFACE_LANG' | translate }}</span>
                </th>
                <th *ngIf="columnByField['otherInfo'] != undefined" [ngClass]="columnHeaderClasses('otherInfo')">
                    <span>{{ 'USER-DETAILS.FORM.OTHER_INFO' | translate }}</span>
                </th>
                <th *ngIf="columnByField['roleName'] != undefined" [ngClass]="columnHeaderClasses('roleName')">
                    <span>{{ 'USER-DETAILS.FORM.ROLE' | translate }}</span>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <app-select inputId="roleFilter" [options]="roleSelectItems | async"
                                    [ngModel]="selectedRole"
                                    (ngModelChange)="handleRoleFilterChange($event)">
                            <ng-template pTemplate="label"></ng-template>
                        </app-select>
                    </div>
                </th>
                <th *ngIf="columnByField['additionalRoles'] != undefined" [ngClass]="columnHeaderClasses('additionalRoles')">
                    <span>{{ 'USER-DETAILS.FORM.ADDITIONAL_ROLES' | translate }}</span>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <p-multiSelect [options]="additionalRoleSelectItems | async"
                                       defaultLabel="{{ 'USER-DETAILS.FORM.ADDITIONAL_ROLES' | translate }}" appendTo="body"
                                       [displaySelectedLabel]="false" [ngModel]="selectedAdditionalRoles"
                                       (ngModelChange)="handleAdditionalRolesFilterChange($event)"></p-multiSelect>
                    </div>
                </th>
                <th *ngIf="columnByField['accountExpirationDate'] != undefined" pSortableColumn="accountExpirationDate" [ngClass]="columnHeaderClasses('accountExpirationDate')">
                    <span>{{ 'USER-DETAILS.FORM.ACCOUNT_EXPIRATION' | translate }}</span>
                    <p-sortIcon field="accountExpirationDate"></p-sortIcon>
                    <div app-table-date-range-filter="accountExpirationDate"
                         [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                </th>
                <th *ngIf="columnByField['subsystemIds'] != undefined" [ngClass]="columnHeaderClasses('subsystemIds')">
                    <span>{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}</span>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <p-multiSelect [options]="subsystemsSelect"
                                       defaultLabel="{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}" appendTo="body"
                                       [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                       (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                    </div>
                </th>
                <th *ngIf="columnByField['active'] != undefined" [ngClass]="columnHeaderClasses('active')">
                    <span>{{ 'USER-DETAILS.FORM.STATUS' | translate }}</span>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <app-select inputId="activeFilter" [options]="filterActive | selectItemTranslate"
                                    [ngModel]="filterActiveState"
                                    (ngModelChange)="handleFilterActiveChange($event)">
                            <ng-template pTemplate="label"></ng-template>
                        </app-select>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
            <tr [attr.tabindex]="rowIndex" [pSelectableRow]="user" [pSelectableRowIndex]="rowIndex"
                (dblclick)="doOnRowSelect({ data: user })" [app-table-keyboard-navigation]="user"
                (app-table-keyboard-navigation-enter)="doOnRowSelect({ data: user })">
                <td *ngIf="permissions.isKoordynator() || permissions.isOpiekun()">
                    <div class="table-row-action-buttons min-width80">
                        <app-simple-button *ngIf="isImpersonationAllowed(user)"
                                           label="{{ 'USER-DETAILS.FORM.IMPERSONATE_BUTTON' | translate }}"
                                           [inlineLabel]="false" icon="fingerprint" type="main-action"
                                           (onClick)="impersonate(user.login)"></app-simple-button>
                    </div>
                </td>
                <td *ngIf="columnByField['id'] != undefined">{{ user.id }}</td>
                <td *ngIf="columnByField['login'] != undefined">{{ user.login }}</td>
                <td *ngIf="columnByField['firstName'] != undefined">{{ user.firstName }}</td>
                <td *ngIf="columnByField['lastName'] != undefined">{{ user.lastName }}</td>
                <td *ngIf="columnByField['email'] != undefined">{{ user.email }}</td>
                <td *ngIf="columnByField['interfaceLanguage'] != undefined">{{ user.interfaceLanguage | translate }}</td>
                <td *ngIf="columnByField['otherInfo'] != undefined">{{ user.otherInfo }}</td>
                <td *ngIf="columnByField['roleName'] != undefined">{{ getRoleTranslationKey(user.roleName) | translate }}</td>
                <td *ngIf="columnByField['additionalRoles'] != undefined">
                    <div *ngFor="let roleName of getAdditionalRoleNames(user) | async">
                        {{ roleName }}
                    </div>
                </td>
                <td *ngIf="columnByField['accountExpirationDate'] != undefined">
                    {{ getEffectiveExpirationDate(user) | momentCurrentLang | amDateFormat: 'L' }}
                </td>
                <td *ngIf="columnByField['subsystemIds'] != undefined">{{ getSubsystemsName(user.subsystemId) }}</td>
                <td *ngIf="columnByField['active'] != undefined">
                    {{ (user.active ? 'USER-DETAILS.ACTIVE' : 'USER-DETAILS.INACTIVE') | translate }}
                </td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>
<app-edit-user *ngIf="displayDialog && editedUser" [(file)]="file" [user]="editedUser" [existingSubsystems]="subsystemsSelect"
               (onNoSuitableUsers)="showNoSuitableSupplierError()"
               (onShowSubstituteUsersDialog)="showSelectNewUserDialog($event)"
               [existingRoles]="roleSelectItems | async" (onSave)="handleUserUpdated()" (onCancel)="handleCancel()"></app-edit-user>

<!-- ADD SUBSTITUTE MERCHANT DIALOG -->
<app-substitute-user-dialog  *ngIf="displaySubstitueUserDialog"
                                [user]="editedUser"
                                [substituteUsers]="substituteUsers"
                                (onHide)="handleCancel()"
                                (onSuccess)="onUpdateUserSuccess()"
                                (onSaveUserError)="showFailedToSaveUserMsg()"
                                (onMoveClientsError)="showFailedToMoveClientsMsg()">
</app-substitute-user-dialog>
