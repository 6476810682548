import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {RailSystem} from "./rail-system";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing} from "../../../common/crud-common/crudItemList";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {ListOfIds} from "../../ListOfIds";
import {CatalogItemName} from "../../../common/crud-common/catalog-item-name";

@Injectable()
export class RailSystemService implements CrudService<RailSystem> {

    private static readonly API_URL = 'railSystem';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    addItem(item: RailSystem, file?: File, designerFile?: File, additionalIcon?: File): Observable<number> {
        return this.saveRailSystem(item, file, designerFile, additionalIcon);
    }

    editItem(itemId: number, item: RailSystem, file?: File, designerFile?: File, additionalIcon?: File): Observable<number> {
        return this.saveRailSystem(item, file, designerFile, additionalIcon, itemId);
    }

    getItem(itemId: number): Observable<RailSystem> {
        return this.http.get<RailSystem>(`${RailSystemService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<RailSystem>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<RailSystem>>(RailSystemService.API_URL, {params: params});
    }

    getImage(itemId: number): Observable<File> {
        return this.http.get(`${RailSystemService.API_URL}/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getImageAsBase64(itemId: number): Observable<string> {
        return this.http.get(`${RailSystemService.API_URL}/${itemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getDesignerImage(itemId: number): Observable<File> {
        return this.http.get(`${RailSystemService.API_URL}/${itemId}/designerImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getAdditionalIcon(itemId: number): Observable<File> {
        return this.http.get(`${RailSystemService.API_URL}/${itemId}/additionalIcon`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getDesignerImageAsBase64(itemId: number): Observable<string> {
        return this.http.get(`${RailSystemService.API_URL}/${itemId}/designerImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    saveRailSystem(item: RailSystem, file: File, designerFile: File, additionalIcon: File, itemId?: number): Observable<number> {
        const url = itemId ? `${RailSystemService.API_URL}/${itemId}` : RailSystemService.API_URL;
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('railSystemDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        if (designerFile) {
            formData.append('designerFile', designerFile);
        }
        if (additionalIcon) {
            formData.append('additionalIcon', additionalIcon);
        }
        return this.http.post(url, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(RailSystemService.API_URL, response)));
    }

    copy(id: number, item: RailSystem, file: File, designerFile: File, additionalIcon: File) {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('railSystemDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }
        if (designerFile) {
            formData.append('designerFile', designerFile);
        }
        if (additionalIcon) {
            formData.append('additionalIcon', additionalIcon);
        }
        return this.http.post(`${RailSystemService.API_URL}/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(RailSystemService.API_URL, response)));
    }

    validate(item: RailSystem): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${RailSystemService.API_URL}/validate`, item));
    }

    editLinks(railSystemId: number, systemIds: ListOfIds): Observable<number> {
        return this.http.put<void>(`${RailSystemService.API_URL}/link/${railSystemId}`, systemIds).pipe(map(() => 0));
    }

    getLinkedModels(railSystemId: number): Observable<number[]> {
        return this.http.get<number[]>(`${RailSystemService.API_URL}/linked/${railSystemId}`);
    }

    getRailSystemsForGateSystem(gateSystemId: number, selectedIds: number[] = null): Observable<RailSystem[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        return this.http.get<object[]>(`${RailSystemService.API_URL}/forGateSystem/${gateSystemId}`, {params: params})
            .pipe(map(result => result.map(RailSystem.fromJSON)));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${RailSystemService.API_URL}/names`, {params: params});
    }
}
