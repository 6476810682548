import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {GateDesignerCatalogDependentOptionsSet} from './gate-designer-catalog-dependent-options-set';

@Injectable()
export class GateDesignerCatalogDependentOptionsSetService implements CrudService<GateDesignerCatalogDependentOptionsSet> {

    private static readonly API_URL = 'gateDesignerCatalogDependentOptionsSet';

    constructor(private readonly http: HttpClient, private readonly dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<GateDesignerCatalogDependentOptionsSet>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);

        return this.http.get<Listing<object>>(GateDesignerCatalogDependentOptionsSetService.API_URL, {params: params})
            .pipe(mapListingToJson(GateDesignerCatalogDependentOptionsSet));
    }

    getItem(itemId: number): Observable<GateDesignerCatalogDependentOptionsSet> {
        return this.http.get<object>(`${GateDesignerCatalogDependentOptionsSetService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(GateDesignerCatalogDependentOptionsSet));
    }

    addItem(item: GateDesignerCatalogDependentOptionsSet): Observable<number> {
        return this.http.post<void>(GateDesignerCatalogDependentOptionsSetService.API_URL, item, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(GateDesignerCatalogDependentOptionsSetService.API_URL));
    }

    editItem(itemId: number, item: GateDesignerCatalogDependentOptionsSet): Observable<number> {
        return this.http.put<void>(`${GateDesignerCatalogDependentOptionsSetService.API_URL}/${itemId}`, item)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }

    deleteItem(itemId: number): Observable<void> {
        return this.http.delete(`${GateDesignerCatalogDependentOptionsSetService.API_URL}/${itemId}`)
            .pipe(map(() => undefined));
    }

    validateGeneralData(item: GateDesignerCatalogDependentOptionsSet): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${GateDesignerCatalogDependentOptionsSetService.API_URL}/validateGeneralData`, item));
    }

    validateDependentOptions(item: GateDesignerCatalogDependentOptionsSet): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${GateDesignerCatalogDependentOptionsSetService.API_URL}/validateDependentOptions`, item));
    }
}
