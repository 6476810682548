import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ListingItem} from '../../../common/crud-common/crudItem';
import {Listing} from '../../../common/crud-common/crudItemList';
import {AbstractSellDealerDiscount} from './abstract-sell-dealer-discount';

export abstract class AbstractSellDealerDiscountService<T extends AbstractSellDealerDiscount> {

    protected constructor(private http: HttpClient,
                          private itemType: ListingItem<T>) {
    }

    abstract getApiBaseUrl(): string;

    abstract addSearchParams(params: { [param: string]: string | string[] }, id: number, groupId: number): void;

    getSellDealerDiscounts(id: number, groupId?: number): Observable<Listing<T>> {
        const params: { [param: string]: string | string[] } = {};
        this.addSearchParams(params, id, groupId);
        return this.http.get<Listing<object>>(`${this.getApiBaseUrl()}`, {params: params})
            .pipe(map(response => Listing.fromJSON(this.itemType, response)));
    }

    saveSellDealerDiscounts(id: number, sellDealerDiscounts: T[]): Observable<void> {
        return this.http.post<void>(`${this.getApiBaseUrl()}/${id}`, sellDealerDiscounts);
    }
}
