import * as _ from "underscore";
import {WindowSystemInterface} from '../catalog-data/window-system-interface';
import {CutData} from "../drawing-data/CutData";
import {DrawingData} from "../drawing-data/drawing-data";
import {Grill} from "../drawing-data/Grill";
import {GrillSegmentDto} from "../drawing-data/GrillSegmentDto";
import {Mullion} from "../drawing-data/Mullion";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {WindowShape} from "../drawing-data/WindowShape";
import {DrawingUtil, IntersectionResult, MinMaxXY} from "../drawing-util";
import {ConfigAddonOpeningType} from '../enums/ConfigAddonOpeningType';
import {SubwindowSide} from "../enums/subwindow-side";
import {ProfilesCompositionDistances, ProfilesCompositionType} from '../profiles-composition-distances';
import {SubwindowPolygons} from '../subwindow-polygons';
import {WindowCalculator} from "../window-calculator";
import {WindowNeighbourCheck} from "../window-neighbour-check";
import {AngleConverter} from "./angle-converter";
import {ConfigurableAddonOpeningsUtils} from './ConfigurableAddonOpeningsUtils';
import {CutsUtil} from "./cutUtils";
import {DrawingDataSpecificationUtil} from "./DrawingDataSpecificationUtil";
import {FloatOps} from './float-ops';
import {GrillHelper} from "./grill-helper";
import {MullionHelper} from "./MullionHelper";
import {MullionUtils} from "./MullionUtils";
import {PolygonPoint, PolygonPointUtil} from "./PolygonPoint";
import {ProfileCompositionUtils} from "./profile-composition-utils";
import {RandomIdGenerator} from "./RandomIdGenerator";

export class PricingUtils {

    /**
     * We need some more data for pricing than for 'just' drawing the window.
     *
     * Here we add:
     * - cuts data to subwindow
     * - real mullion lengths
     * - frame lenghts
     *
     * And remove front-end only helper data:
     * - guides
     * - functionsQuantity
     */
    public static enhanceForPricing(data: DrawingData, windowSystem: WindowSystemInterface,
                                    profiles: ProfilesCompositionDistances, clearGrillSegments = true): DrawingData {
        let copiedData: DrawingData = JSON.parse(JSON.stringify(data));
        let cuts: CutData[] = copiedData.cuts;
        for (let window of copiedData.windows) {
            if (window.generatedId == null) {
                window.generatedId = RandomIdGenerator.generate();
            }
            for (let subwindow of window.subWindows) {
                subwindow.cut = CutsUtil.applyCuts(subwindow.points, cuts, 0, true);
                subwindow.isActive = WindowCalculator.isSubWindowActive(subwindow);
                delete subwindow.functionsQuantity;
            }
        }

        DrawingDataSpecificationUtil.clearUnnecessaryFields(copiedData.specification, copiedData.windows, {windowSystem: windowSystem});

        this.fillOpeningsData(copiedData, profiles);

        PricingUtils.recalculateMullionLengths(copiedData);
        PricingUtils.recalculateNeighboringFramesLengths(copiedData);
        PricingUtils.recalculateNonstandardCutAngless(copiedData, profiles);
        if (clearGrillSegments) {
            PricingUtils.mapGrillAndMullionSegmentsToDtos(copiedData);
        }

        this.clearUnusedProfiles(data, copiedData);
        delete copiedData.guides;
        return copiedData;
    }

    private static clearUnusedProfiles(data: DrawingData, copiedData: DrawingData) {
        let constructionalMullionPresent = ProfileCompositionUtils.constructionalMullionPresent(data);
        if (!constructionalMullionPresent) {
            copiedData.specification.constructionalMullionId = null;
        }
        let movablePostPresent = ProfileCompositionUtils.hasMovablePost(data);
        if (!movablePostPresent) {
            copiedData.specification.movablePostId = null;
        }
    }

    public static fillOpeningsData(data: DrawingData, profiles: ProfilesCompositionDistances) {
        let totalBoundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);
        let subwindowsPolygonsMap = ConfigurableAddonOpeningsUtils.prepareSubwindowsPolygonsMap(data, totalBoundingBox, profiles, false);
        this.insertWindowOpenings(data, subwindowsPolygonsMap);
        this.insertSubwindowsOpenings(data, subwindowsPolygonsMap);
        this.insertAreasOpenings(data, totalBoundingBox, profiles);
    }

    private static insertWindowOpenings(data: DrawingData, subwindowsPolygonsMap: SubwindowPolygons[]) {
        let framePoints = WindowCalculator.getOuterFramePoints(data.windows, data.cuts);
        let framePointsOpening = ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME, framePoints);
        let frameOpeningPoints: number[];
        let wingPoints: number[];
        let pluginPoints: number[];
        let glassPoints: number[];
        let allSubwindows = _.chain(data.windows).map(window => window.subWindows).flatten().value();
        let wingedParent = allSubwindows.some(subwindow => !WindowCalculator.isSubWindowF(subwindow));
        if (framePointsOpening.rectangular) {
            let framePointsBox = DrawingUtil.calculatePolygonTotalBoundingBox(framePoints);

            let topSubwindows = allSubwindows.filter(
                subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsXPoints(subwindow), framePointsBox.minX));
            let bottomSubwindows = allSubwindows.filter(
                subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsXPoints(subwindow), framePointsBox.maxX));
            let leftSubwindows = allSubwindows.filter(
                subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsYPoints(subwindow), framePointsBox.minY));
            let rightSubwindows = allSubwindows.filter(
                subwindow => _.contains(ConfigurableAddonOpeningsUtils.getSubwindowsYPoints(subwindow), framePointsBox.maxY));

            wingedParent = [...topSubwindows, ...bottomSubwindows, ...leftSubwindows, ...rightSubwindows].some(
                subwindow => !WindowCalculator.isSubWindowF(subwindow));

            frameOpeningPoints =
                ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows, leftSubwindows, rightSubwindows,
                    subwindowsPolygonsMap, ConfigAddonOpeningType.FRAME_OPENING);
            wingPoints =
                ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows, leftSubwindows, rightSubwindows,
                    subwindowsPolygonsMap, ConfigAddonOpeningType.WING);
            pluginPoints =
                ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows, leftSubwindows, rightSubwindows,
                    subwindowsPolygonsMap, ConfigAddonOpeningType.PLUGIN);
            glassPoints =
                ConfigurableAddonOpeningsUtils.joinSubwindowPolygonPoints(topSubwindows, bottomSubwindows, leftSubwindows, rightSubwindows,
                    subwindowsPolygonsMap, ConfigAddonOpeningType.GLASS);
        }
        let openings = [
            framePointsOpening,
            ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME_OPENING, frameOpeningPoints),
            ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.WING, wingPoints),
            ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.PLUGIN, pluginPoints),
            ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.GLASS, glassPoints)
        ];

        data.wingedParent = wingedParent;
        data.openings = openings.filter(el => el != undefined);
    }

    private static insertSubwindowsOpenings(data: DrawingData, subwindowsPolygonsMap: SubwindowPolygons[]) {
        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            let subwindowPolygons = subwindowsPolygonsMap[subwindow.generatedId];
            let openings = [
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME, subwindowPolygons.framePoints),
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.FRAME_OPENING, subwindowPolygons.frameOpeningPoints),
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.WING, subwindowPolygons.wingPoints),
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.PLUGIN, subwindowPolygons.pluginPoints),
                ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.GLASS, subwindowPolygons.glassPoints)
            ];
            subwindow.openings = openings.filter(el => el != undefined);
        }));
    }

    private static insertAreasOpenings(data: DrawingData, totalBoundingBox: MinMaxXY,
                                     profileCompositionDistances: ProfilesCompositionDistances) {
        data.windows.forEach(window => window.subWindows.forEach(subwindow => {
            let cuts = data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subwindow.points, cut));
            let totalInnerFramePoints = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow, cuts,
                totalBoundingBox, profileCompositionDistances, false);
            let totalGlazingBeadPoints = WindowCalculator.getTotalGlazingBeadsPoints(subwindow, cuts,
                totalBoundingBox, profileCompositionDistances, false);
            subwindow.areasSpecification.forEach(area => {
                let pluginPoints = WindowCalculator.getFrameInnerEdgePoints(subwindow, area.definingMullions,
                    totalInnerFramePoints);
                let glassPoints = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions,
                    totalGlazingBeadPoints, profileCompositionDistances);
                let openings = [
                    ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.PLUGIN, pluginPoints),
                    ConfigurableAddonOpeningsUtils.mapOpening(ConfigAddonOpeningType.GLASS, glassPoints)
                ];
                area.openings = openings.filter(el => el != undefined);
            });
        }));
    }

    public static recalculateMullionLengths(data: DrawingData): void {
        data.windows.forEach(window => {
            window.subWindows.forEach(subwindow => {
                let frame = WindowCalculator.getOuterSubwindowPoints(subwindow, data.cuts);
                subwindow.mullions.forEach(mullion => {
                    let segment = GrillHelper.getExpectedLineSegment(mullion);
                    let mullionLine = segment.points;
                    let intersections: IntersectionResult[];
                    let segmentPointA = [mullionLine[0], mullionLine[1]];
                    let segmentPointB = [mullionLine[2], mullionLine[3]];
                    let pointA = segmentPointA;
                    let pointB = segmentPointB;
                    if (MullionHelper.isVeneer(mullion) ||
                        PricingUtils.onlyHasReferencesOfType(mullion, PositionReferenceType.INNER_FRAME)) {
                        intersections = DrawingUtil.polygonLineIntersections(frame, mullionLine, true);
                        pointA = PricingUtils.pointFromIntersection(intersections[0]);
                        pointB = PricingUtils.pointFromIntersection(intersections[1]);
                    } else {
                        let positionA = mullion.positions[0];
                        let positionB = mullion.positions[1];
                        intersections = DrawingUtil.polygonLineIntersections(frame, mullionLine, true);
                        if (positionA.type === PositionReferenceType.INNER_FRAME) {
                            pointA = DrawingUtil.getIntersectionCloserToOriginalPoint(pointA, intersections);
                        }
                        if (positionB.type === PositionReferenceType.INNER_FRAME) {
                            pointB = DrawingUtil.getIntersectionCloserToOriginalPoint(pointB, intersections);
                        }
                    }
                    mullion.length = FloatOps.round(DrawingUtil.distance(pointA, pointB));
                });
            });
        });
    }

    private static pointFromIntersection(intersection: IntersectionResult): number[] {
        if (intersection == undefined || !intersection.intersects) {
            throw new Error("Mullion line doesn't intersect subwindow polygon. Should be impossible.");
        }
        return [intersection.x, intersection.y];
    }

    private static onlyHasReferencesOfType(mullion: Mullion, type: PositionReferenceType): boolean {
        return mullion.positions.every(pos => pos.type === type);
    }

    private static convertToNeighboringFrame(...points: PolygonPoint[]): any {
        return {
            path: PolygonPointUtil.toNumbersArray(points),
            length: Math.ceil(DrawingUtil.getPathLength(points))
        };
    }

    public static recalculateNeighboringFramesLengths(data: DrawingData): void {
        if (WindowShape.isEllipse(data.shape)) {
            data.windows.forEach(window => {
                window.subWindows.forEach(subwindow => {
                    subwindow.neighboringFramesLengths = [data.totalPerimeter];
                });
            });
        } else {
            let outerFramePoints = WindowCalculator.getOuterFramePointsFull(data);
            let roundedCorners = WindowShape.isArchElliptical(data.shape) || WindowShape.isArchThreeCentered(data.shape);
            if (WindowShape.isArchThreeCentered(data.shape)) {
                let topMostPosition = Math.min(...outerFramePoints.map(p => p.y));
                outerFramePoints.filter(p => p.y === topMostPosition).forEach(p => p.isArc = true);
            }
            let segments = [];
            let arcAccumulator = [];
            let firstNonArcIndex = outerFramePoints.findIndex(p => !p.isArc);
            for (let i = 0; i < outerFramePoints.length; i++) {
                let a = DrawingUtil.getPoint(outerFramePoints, firstNonArcIndex + i);
                let b = DrawingUtil.getPoint(outerFramePoints, firstNonArcIndex + i + 1);
                if ((a.isArc || roundedCorners) && b.isArc) {
                    arcAccumulator.push(a);
                } else if (!a.isArc && (!b.isArc || !roundedCorners)) {
                    segments.push(PricingUtils.convertToNeighboringFrame(a, b));
                } else {
                    if (roundedCorners) {
                        segments.push(PricingUtils.convertToNeighboringFrame(...arcAccumulator, a, b));
                    } else {
                        segments.push(PricingUtils.convertToNeighboringFrame(...arcAccumulator, a));
                        segments.push(PricingUtils.convertToNeighboringFrame(a, b));
                    }
                    arcAccumulator = [];
                }
            }

            data.windows.forEach(window => {
                window.subWindows.forEach(subwindow => {
                    subwindow.neighboringFramesLengths = _.uniq(
                        segments.filter(s => this.isFrameSegmentPartOfSubwindow(s.path, subwindow.cut))
                            .map(s => s.length));
                });
            });
        }
    }

    private static isFrameSegmentPartOfSubwindow(path: number[], subwindow: number[]): boolean {
        let verticesOnPath = 0;
        for (let i = 0; i < path.length - 2; i += 2) {
            let pathSegment = [
                DrawingUtil.getPoint(path, i),
                DrawingUtil.getPoint(path, i + 1),
                DrawingUtil.getPoint(path, i + 2),
                DrawingUtil.getPoint(path, i + 3)
            ];
            for (let j = 0; j < subwindow.length; j += 2) {
                let subwindowVertex = [
                    DrawingUtil.getPoint(subwindow, j),
                    DrawingUtil.getPoint(subwindow, j + 1)
                ];
                if (FloatOps.eq(0, DrawingUtil.distanceFromLine(pathSegment, subwindowVertex))) {
                    verticesOnPath++;
                }
                if (verticesOnPath > 1) {
                    return true;
                }
            }
        }
        return false;
    }

    private static recalculateNonstandardCutAngless(data: DrawingData, profiles: ProfilesCompositionDistances): void {
        const setDebugValue = <T>(obj: T, debugKey: string, debugValue: any): void => {
            if (!!data['__ABAKUS_DEBUG__']) {
                obj[debugKey] = debugValue;
            }
        };

        // we assume that a 90.1 degree angle was intended by the user to be 90
        const convertAngleToDeg = (r: number) => FloatOps.round(AngleConverter.toDeg(r));

        // due to all the rounding (and sometimes lack of rounding) we dont always properly identify colinear points
        // so we have to filter them out based on rounded angle being 180 degrees
        const isNonStandardAngle = (a: number) => FloatOps.ne(a, 90) && FloatOps.ne(a, 180);

        const totalBoundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);

        const outerFramePoints = WindowCalculator.getOuterFramePointsFull(data, true);
        let {angles: frameAngles, points} = DrawingUtil.getPolygonAngles(outerFramePoints);
        frameAngles = frameAngles.map(convertAngleToDeg);

        data.nonstandardFrameCutAnglesCount = frameAngles.filter(isNonStandardAngle).length * 2;
        data.nonstandardWingCutAnglesCount = 0;
        data.nonstandardMullionCutAnglesCount = 0;

        const processedFakeMullions = new Map<string /*subwindow generated id*/, Set<SubwindowSide>>();
        const fakeMullions: number[][] = [];

        for (let w of data.windows) {
            for (let sw of w.subWindows) {
                // wing cuts
                if (!WindowCalculator.isSubWindowF(sw)) {
                    const outerSubwindowWingPoints = WindowCalculator.getFFWingOuterEdgePointsFull(sw,
                        data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(sw.points, cut)), totalBoundingBox, profiles, true);

                    let {angles: subWindowAngles, points: subWindowPoints} = DrawingUtil.getPolygonAngles(outerSubwindowWingPoints);
                    subWindowAngles = subWindowAngles.map(convertAngleToDeg);

                    data.nonstandardWingCutAnglesCount += subWindowAngles.filter(isNonStandardAngle).length * 2;

                    setDebugValue(sw, '__ABAKUS_NONSTANDARD_WING_POINTS__',
                        subWindowPoints.filter((p, i) => isNonStandardAngle(subWindowAngles[i])));
                }

                // fake mullions (frame between subwindows)
                const otherSide = (side: SubwindowSide): SubwindowSide => {
                    switch (side) {
                        case SubwindowSide.LEFT: return SubwindowSide.RIGHT;
                        case SubwindowSide.RIGHT: return SubwindowSide.LEFT;
                        case SubwindowSide.TOP: return SubwindowSide.BOTTOM;
                        case SubwindowSide.BOTTOM: return SubwindowSide.TOP;
                    }
                };

                const processNeighbour = (subWindow: SubWindowData, neighbour: SubWindowData, side: SubwindowSide): void => {
                    const swProcessedSides = processedFakeMullions.get(subWindow.generatedId);
                    let neighbourProcessedSides = processedFakeMullions.get(neighbour.generatedId);
                    if (neighbourProcessedSides == undefined) {
                        neighbourProcessedSides = new Set<SubwindowSide>();
                        processedFakeMullions.set(neighbour.generatedId, neighbourProcessedSides);
                    }

                    if (swProcessedSides.has(side) && neighbourProcessedSides.has(otherSide(side))) {
                        return;
                    }

                    const sharedPoints: number[] = [];
                    for (let i = 0; i + 1 < neighbour.points.length; i += 2) {
                        const point = [neighbour.points[i], neighbour.points[i + 1]];
                        if (DrawingUtil.isPointInPolygon(point, subWindow.points)) {
                            sharedPoints.push(point[0], point[1]);
                        }
                    }

                    if (sharedPoints.length !== 4) {
                        return;
                    }

                    swProcessedSides.add(side);
                    neighbourProcessedSides.add(otherSide(side));

                    const mullionHalfWidth = profiles.get(ProfilesCompositionType.MULLION) / 2;
                    let fakeMullionPoints: number[];
                    switch (side) {
                        case SubwindowSide.LEFT:
                            fakeMullionPoints = [
                                sharedPoints[0] - mullionHalfWidth, sharedPoints[1],
                                sharedPoints[0] + mullionHalfWidth, sharedPoints[1],
                                sharedPoints[2] + mullionHalfWidth, sharedPoints[3],
                                sharedPoints[2] - mullionHalfWidth, sharedPoints[3],
                            ];
                            break;
                        case SubwindowSide.RIGHT:
                            fakeMullionPoints = [
                                sharedPoints[0] - mullionHalfWidth, sharedPoints[1],
                                sharedPoints[0] + mullionHalfWidth, sharedPoints[1],
                                sharedPoints[2] + mullionHalfWidth, sharedPoints[3],
                                sharedPoints[2] - mullionHalfWidth, sharedPoints[3],
                            ];
                            break;
                        case SubwindowSide.TOP:
                            fakeMullionPoints = [
                                sharedPoints[0], sharedPoints[1] - mullionHalfWidth,
                                sharedPoints[2], sharedPoints[3] - mullionHalfWidth,
                                sharedPoints[2], sharedPoints[3] + mullionHalfWidth,
                                sharedPoints[0], sharedPoints[1] + mullionHalfWidth,
                            ];
                            break;
                        case SubwindowSide.BOTTOM:
                            fakeMullionPoints = [
                                sharedPoints[0], sharedPoints[1] - mullionHalfWidth,
                                sharedPoints[2], sharedPoints[3] - mullionHalfWidth,
                                sharedPoints[2], sharedPoints[3] + mullionHalfWidth,
                                sharedPoints[0], sharedPoints[1] + mullionHalfWidth,
                            ];
                            break;
                    }
                    let relevantCuts = data.cuts.filter(cut => subWindow.relevantCutIds.includes(cut.generatedId)
                        || neighbour.relevantCutIds.includes(cut.generatedId));
                    const cutFakeMullion = CutsUtil.applyCutsFull(fakeMullionPoints, relevantCuts, 0, true);
                    fakeMullions.push(PolygonPointUtil.toNumbersArray(cutFakeMullion));

                    const mullionAngles = DrawingUtil.getPolygonAngles(cutFakeMullion)
                        .angles.map(convertAngleToDeg);

                    data.nonstandardMullionCutAnglesCount += Math.floor(mullionAngles.filter(isNonStandardAngle).length / 2);
                };

                const processNeighbours = (subWindow: SubWindowData, neighbours: SubWindowData[], side: SubwindowSide): void => {
                    neighbours.forEach(n => processNeighbour(subWindow, n, side));
                };

                if (!processedFakeMullions.has(sw.generatedId)) {
                    processedFakeMullions.set(sw.generatedId, new Set());
                }

                const {bottom, left, right, top} = WindowNeighbourCheck.getNeighbours(sw, data);
                processNeighbours(sw, bottom, SubwindowSide.BOTTOM);
                processNeighbours(sw, left, SubwindowSide.LEFT);
                processNeighbours(sw, right, SubwindowSide.RIGHT);
                processNeighbours(sw, top, SubwindowSide.TOP);

                // real mullions
                let totalInnerFrame = WindowCalculator.getTotalFrameInnerEdgePoints(sw,
                    data.cuts.filter(cut => sw.relevantCutIds.includes(cut.generatedId)), totalBoundingBox, profiles, true);

                const swMullions = [];

                for (let mullion of sw.mullions) {
                    const mullionPoints = MullionUtils.getMullionPoints(mullion, totalInnerFrame, sw, profiles, true);
                    swMullions.push(mullionPoints);

                    let mullionAngles = DrawingUtil.getPolygonAngles(PolygonPointUtil.toPolygonPoints(mullionPoints))
                        .angles.map(convertAngleToDeg);

                    data.nonstandardMullionCutAnglesCount += Math.floor(mullionAngles.filter(isNonStandardAngle).length / 2);

                    setDebugValue(sw, '__ABAKUS_MULLIONS__', swMullions);
                }
            }
        }

        setDebugValue(data, '__ABAKUS_NONSTANDARD_ANGLE_FRAME_POINTS__', points.filter((p, i) => isNonStandardAngle(frameAngles[i])));
        setDebugValue(data, '__ABAKUS_MULLIONS__', fakeMullions);
    }

    public static mapGrillAndMullionSegmentsToDtos(data: DrawingData): void {
        for (let window of data.windows) {
            for (let subwindow of window.subWindows) {
                for (let mullion of subwindow.mullions) {
                    mullion.segments = [];
                    this.deleteRedundantSegmentsData(mullion);
                }
                for (let area of subwindow.areasSpecification) {
                    for (let grill of area.grills) {
                        grill.segments = grill.pricingSegments.map(
                            s => new GrillSegmentDto(s.id, s.angled, DrawingUtil.segmentsLength(s.points)));
                        this.deleteRedundantSegmentsData(grill);
                    }
                }
            }
        }
    }

    private static deleteRedundantSegmentsData(grill: Grill): void {
        delete grill.drawingSegments;
        delete grill.pricingSegments;
        delete grill.parentFields;
        delete grill.canBeAngled;
        delete grill.constructionType;
    }
}
