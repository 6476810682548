import {Observable} from 'rxjs';
import {Glazing} from './catalog-data/glazing';
import {AreaSpecification} from './drawing-data/AreaSpecification';
import {DrawingData} from './drawing-data/drawing-data';
import {HandleDirection} from './drawing-data/HandleDirection';
import {MinMaxXY} from './drawing-util';
import {GuidesDialogData} from './guides-dialog-data';
import {PendingGrillData} from './pending-grill-data';
import {ProfilesCompositionDistances} from './profiles-composition-distances';
import {OperationResult} from './utils/OperationResult';
import {VisibilitySettings} from './utils/VisibilitySettings';
import {CustomTranslationsInterface} from "./catalog-data/custom-translations-interface";
import {StaticDataHelper} from "./static-data-helper";
import {PainterMode} from "./painters/PainterMode";

export enum Tool {
    SELECT,
    NEW_WINDOW,
    NEW_SUBWINDOW,
    CUT,
    GRILL,
    GRILL_TEMPLATE,
    EXTEND_HORIZONTAL_GRILLS,
    HANDLE,
    MULLION,
    CHANGE_BUSINESS_TYPE
}

export interface WindowDesignerInterface {
    mode: Tool;
    data: DrawingData;
    svg: Snap.Paper;
    totalBoundingBox: MinMaxXY;
    totalBoundingClientRect: DOMRect;
    dottedOrnamentPattern: Snap.Element;
    stripedFillingPattern: Snap.Element;
    noFillingPattern: Snap.Element;
    clickedSnapElements: { type: string, elements: Snap.Element[] };
    pendingGrillData: PendingGrillData;
    pendingOperationLineHelper: Snap.Element;
    pendingHandleDirection: HandleDirection;
    visibilitySettings: VisibilitySettings;
    redrawPreviewMode: boolean;
    requiredFieldFilled: boolean;
    isTerrace: boolean;
    guidesDialogData: GuidesDialogData;
    profileCompositionDistances: ProfilesCompositionDistances;
    customTranslations: CustomTranslationsInterface;
    staticData: StaticDataHelper;

    addSelectionClickHandler(elem: Snap.Element, multiSelectionClassName: string): void;

    onClickLineGrillHandler(event: MouseEvent): void;

    isGlazingOrnament(glazing: Glazing): boolean;

    isDefaultGlazing(area: AreaSpecification): boolean;

    calculateSvgCoordsFromMouseEvent(event: MouseEvent): { x: number, y: number };

    saveStepInHistory(data?: DrawingData): void;

    cancelLast(): void;

    redrawWindow(omitValidation: boolean, omitPricing: boolean, callback?: () => void): Observable<void>;

    getOnePercentOfCanvasSize(): number;

    configAddonIconClicked(): void;

    getViewBox(painterMode: PainterMode, recalculateDistanceFromWindows?: boolean): MinMaxXY;

    getTotalBoundingClientRect(): DOMRect;

    recalculateTotalBoundingBox(): void;

    runWindowsArrayGarbageCollector(): OperationResult;

    prepareTabsData(): void;

    isPricingTabOpen(): boolean;

    createSnapMatrix(): Snap.Matrix;

    createSnapFilter(): Snap.Filter;

    getEmptyBoundingBox(): MinMaxXY;

    canClickGuides(): boolean;

    isValidationDisabled(): boolean;

    notifySizeChanged(oldBoundingBox: MinMaxXY, newBoundingBox: MinMaxXY): void;
}
