import {Message} from 'primeng/api/message';
import {MessageParams} from './MessageParams';

export class GrowlMessage implements Message {

    severity?: string;
    summary?: string;
    detail?: string;
    params?: MessageParams;
    sticky?: boolean;

    constructor(severity: string, summary: string, detail: string, params: MessageParams, sticky?: boolean) {
        this.severity = severity;
        this.summary = summary;
        this.detail = detail;
        this.params = params;
        this.sticky = sticky;
    }
}
