import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConjunctionDto} from "../../../../window-editor/conjunction-editor/conjunction-dto";
import {ConjunctionDialogDto} from "./conjunction-dialog-dto";
import {ConjunctionCatalogData} from "../../../../window-editor/conjunction-editor/conjunction-editor.component";

@Injectable()
export class ConjunctionService {

    private static readonly SERVICE_PATH = 'conjunctions';

    constructor(private http: HttpClient) {
    }

    getDialogData(offerId: number): Observable<ConjunctionDialogDto[]> {
        return this.http.get<ConjunctionDialogDto[]>(`${ConjunctionService.SERVICE_PATH}/${offerId}/dialogData`);
    }

    getCatalogData(offerId: number): Observable<ConjunctionCatalogData> {
        return this.http.get<ConjunctionCatalogData>(`${ConjunctionService.SERVICE_PATH}/${offerId}/catalogData`);
    }

    get(conjunctionId: number): Observable<ConjunctionDto> {
        return this.http.get<ConjunctionDto>(`${ConjunctionService.SERVICE_PATH}/${conjunctionId}`);
    }

    save(conjunction: ConjunctionDto, offerId: number): Observable<void> {
        return this.http.post<void>(`${ConjunctionService.SERVICE_PATH}/${offerId}`, conjunction);
    }

    delete(offerId: number, conjunctionId: number): Observable<void> {
        return this.http.delete<void>(`${ConjunctionService.SERVICE_PATH}/${offerId}/${conjunctionId}`);
    }
}
