import {CechaGrupa} from '../../../../../../../window-designer/enums/CechaGrupa';
import {CechaType} from '../../../../../../../window-designer/enums/CechaType';
import {Cecha} from "./Cecha";
import {ConfigurableAddonDefinition} from "./ConfigurableAddonDefinition";
import {Opcja} from "./Opcja";
import {ParsedDefinition} from "./ParsedDefinition";

export class DefinitionParser {
    static parseDefinition(configAddon: ConfigurableAddonDefinition, addAutoOptions = false, globalEditMode = false): ParsedDefinition {
        let additionalProperties: Cecha[] = [];
        if (configAddon.propertyMaterial) {
            additionalProperties.push(JSON.parse(configAddon.propertyMaterial));
        }
        if (configAddon.propertyColor) {
            additionalProperties.push(JSON.parse(configAddon.propertyColor));
        }
        let parsed: ParsedDefinition = JSON.parse(configAddon.definition);
        if (addAutoOptions) {
            parsed.cechy.filter(cecha => cecha.hasAutoOption).forEach(DefinitionParser.addAutoOption);
        }
        parsed.cechy = parsed.cechy.concat(additionalProperties);
        parsed.cechy.forEach(cecha => {
            Object.keys(cecha).forEach(key => {
                if (cecha[key] === null) {
                    delete cecha[key];
                }
            });
            this.initializeCechaFunctions(cecha);
        });

        if (globalEditMode) {
            this.modifyCechaForGlobalEditMode(parsed);
        }
        return parsed;
    }

    private static initializeCechaFunctions(cecha: Cecha): void {
        if (cecha.hasOwnProperty('visibleIf')) {
            if (cecha.hasOwnProperty('visibilityDependsOn') || cecha.hasOwnProperty('dependsOn')) {
                cecha.isVisibleByMasterValue = Function(`"use strict"; return (function(masterValue) { return ${cecha.visibleIf}; });`)();
            } else {
                cecha.isVisibleByApplication = Function(`"use strict"; return (function(application) { return ${cecha.visibleIf}; });`)();
            }
        }
        if (cecha.type === CechaType.OPTIONS) {
            for (let opcja of cecha.opcje) {
                if (opcja.selectedIf) {
                    opcja.isSelectedByMasterValue =
                        Function(`"use strict"; return (function(masterValue) { return ${opcja.selectedIf}; });`)();
                }
            }
        }
    }

    private static modifyCechaForGlobalEditMode(parsed: ParsedDefinition) {
        parsed.cechy = parsed.cechy
            .filter(cecha => cecha.grupa !== CechaGrupa.DIMENSIONS)
            .filter(cecha => !cecha.disabled)
            .filter(cecha => cecha.type !== CechaType.TEXTAREA);
        parsed.cechy.forEach(cecha => {
            if (!cecha.dependsOn && !cecha.visibilityDependsOn) {
                cecha.obowiazkowa = false;
                if (cecha.type === CechaType.OPTIONS) {
                    DefinitionParser.addDoNotChangeOption(cecha);
                }
            }
            if (cecha.dependsOn && !cecha.visibilityDependsOn) {
                cecha.visibilityDependsOn = cecha.dependsOn;
                cecha.dependentValueNotIn = Opcja.DO_NOT_CHANGE_OPTION_SYMBOL;
            }
            if (cecha.type === CechaType.NUMERIC_VALUE) {
                cecha.defaultValue = null;
            }
        });
    }

    static addAutoOption(cecha: Cecha) {
        let autoOption = new Opcja();
        autoOption.symbol = Opcja.AUTO_OPTION_SYMBOL;
        autoOption.domyslna = true;
        cecha.opcje.push(autoOption);
    }

    static addDoNotChangeOption(cecha: Cecha) {
        let doNotChangeOption = new Opcja();
        doNotChangeOption.symbol = Opcja.DO_NOT_CHANGE_OPTION_SYMBOL;
        doNotChangeOption.domyslna = true;
        cecha.opcje.unshift(doNotChangeOption);
    }
}
