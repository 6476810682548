import {MultilanguageFieldInterface} from "../../../window-designer/catalog-data/multilanguage-field-interface";
import {CrudItem, JsonDeserializable} from '../../common/crud-common/crudItem';
import {ContactMethod} from '../../common/enums/contact-method.enum';
import {Country} from '../../common/enums/country';
import {OfferStatus} from '../../common/enums/OfferStatus';
import {Currencies} from '../../currencies';
import {MultilanguageField} from "../../supportedLanguages";
import {PositionType} from "./AbstractPosition";
import {MessageSeverity} from "./offers/message";
import {WindowEditorOfferData} from './window-editor/window-editor-offer-interfaces';
import {PrintOptions} from "./print-dialog/print-options";
import {MapToJsonTransformer} from "../../../shared/map-to-json-transformer";

export class Offer implements CrudItem, WindowEditorOfferData {

    id: number = undefined;
    offerNumber: string;
    alternateOfferNumber: string;
    validFrom: Date = new Date();
    validTo: Date = new Date();
    createdDate: Date;
    otherInfo: string;
    lastStatusChange: Date = new Date();
    subsystemName: string;
    subsystemId: number;
    clientManagerFirstName: string;
    clientManagerLastName: string;
    subClientManagerFirstName: string;
    subClientManagerLastName: string;
    clientGroupName: string;
    merchantGroupName: string;
    merchantFirstName: string;
    merchantLastName: string;
    clientId: number;
    clientName: string;
    clientIdentifier: string;
    clientEmail: string;
    venskaBuyPrice: PriceWithTax = new PriceWithTax(0, 0);
    buyPrice: PriceWithTax = new PriceWithTax(0, 0);
    sellPrice: PriceWithTax = new PriceWithTax(0, 0);
    retailSellPrice: PriceWithTax;
    venskaBuyPriceInOfferCurrency: PriceWithTax = new PriceWithTax(0, 0);
    buyPriceInOfferCurrency: PriceWithTax = new PriceWithTax(0, 0);
    sellPriceInOfferCurrency: PriceWithTax = new PriceWithTax(0, 0);
    retailSellPriceInOfferCurrency: PriceWithTax;
    status: OfferStatus;
    subsystemOwnPositionPresent: boolean;
    ownAddonsCost: PriceWithTax = new PriceWithTax(0, 0);
    assemblyCost: PriceWithTax = new PriceWithTax(0, 0);
    transportCost: PriceWithTax = new PriceWithTax(0, 0);
    netVenskaIncome: number;
    grossVenskaIncome: number;
    netIncome: number;
    grossIncome: number;
    netRetailIncome: number;
    grossRetailIncome: number;
    netVenskaIncomeInOfferCurrency: number;
    grossVenskaIncomeInOfferCurrency: number;
    netIncomeInOfferCurrency: number;
    grossIncomeInOfferCurrency: number;
    netRetailIncomeInOfferCurrency: number;
    grossRetailIncomeInOfferCurrency: number;
    clientManagerId: number;
    merchantId: number;
    active = true;
    currency: Currencies;
    exchangeRate: number;
    exchangeRateDate: Date = new Date();
    orderCreationDate: Date = undefined;
    productionOrderCreationDate: Date = undefined;
    productionOrders: string[] = [];
    highestMessageSeverity: MessageSeverity;
    rabate: number;
    pricingOutdated: boolean;
    vatBlocksEdit: boolean;
    relationType: string;
    relatedOfferId: number;
    archiveVersionNumber: string;
    lastArchivedVersionId: number;
    offerLockUserLogin: string;
    paymentPackageName: string;
    roPaymentPackageName: string;
    deliveryLists: string[] = [];
    palettes: string[] = [];
    hasComment: boolean;
    hasCommentUnreadByVenska: boolean;
    hasCommentUnreadBySubsystem: boolean;
    validationDisabled: boolean;
    subsystemManualExchangeRate: number;
    lastModifiedDate: Date = undefined;
    deliveryAddressId: number;
    sellerClientId: number;
    sellerClientName: string;
    sellerClientIdentifier: string;
    sellerClientEmail: string;
    sellerClientGroupName: string;
    deliveryAddressStreet: string;
    deliveryAddressCity: string;
    deliveryAddressZip: string;
    deliveryAddressCountry: Country;
    dataSource: string;
    webshopPreferredContactMethod: ContactMethod[] = [];
    sourceWebshopOfferNumber: string;
    sourceWebshopOfferSellPrice: PriceWithTax;
    hasPositionWithDisabledValidation: boolean;
}

@JsonDeserializable
export class PriceWithTax {
    vatPercent: number;
    netValue: number;
    grossValue: number;

    constructor(netValue: number, vatPercent: number) {
        this.netValue = netValue;
        this.vatPercent = vatPercent;
        this.grossValue = this.netValue * ((100 + this.vatPercent) / 100);
    }

    static add(price: PriceWithTax, other: PriceWithTax): PriceWithTax {
        if (price.vatPercent !== other.vatPercent) {
            console.error("trying to add prices with different VAT");
        }
        const netValue = price.netValue + other.netValue;
        return new PriceWithTax(netValue, price.vatPercent);
    }

    static fromJSON(jsonObject: any): PriceWithTax {
        const priceWithTax = new PriceWithTax(jsonObject.netValue, jsonObject.vatPercent);
        priceWithTax.grossValue = jsonObject.grossValue; // use exact value from backend
        return priceWithTax;
    }
}

export class OrderProductionOrderNumbers {
    offerId: number;
    productionOrderNumbers: string[];
}

export class OrderDeliveryListNames {
    offerId: number;
    paletteNames: string[];
    deliveryListNames: string[];
}

export interface OfferExhangeRates {
    id: number;
    exchangeRate: number;
    subsystemManualExchangeRate: number;
}

export enum OfferHistoryChangeType {
    CREATED = 'CREATED',
    UPDATED = 'UPDATED',
    DELETED = 'DELETED'
}

@JsonDeserializable
export class OfferHistoryEntry {
    requestId: string;
    requestTimestamp: Date;
    requestUri: string;
    apiClass: string;
    apiMethod: string;
    userName: string;
    impersonatorUserName: string;
    offerNumber: string;
    changeType: OfferHistoryChangeType;
    offerPositionId: number;
    offerPositionType: PositionType;
    offerPositionName: MultilanguageFieldInterface;
    dimensions: string;
    systemName: MultilanguageFieldInterface;

    static fromJSON(jsonObject: any): OfferHistoryEntry {
        const entry = new OfferHistoryEntry();
        entry.requestId = jsonObject['requestId'];
        entry.requestTimestamp = jsonObject['requestTimestamp'] != undefined ? new Date(jsonObject['requestTimestamp']) : undefined;
        entry.requestUri = jsonObject['requestUri'];
        entry.apiClass = jsonObject['apiClass'];
        entry.apiMethod = jsonObject['apiMethod'];
        entry.userName = jsonObject['userName'];
        entry.impersonatorUserName = jsonObject['impersonatorUserName'];
        entry.offerNumber = jsonObject['offerNumber'];
        entry.changeType = OfferHistoryChangeType[jsonObject['changeType']];
        entry.offerPositionId = jsonObject['offerPositionId'];
        entry.offerPositionType = PositionType[jsonObject['offerPositionType']];
        entry.offerPositionName = jsonObject['offerPositionName'] != undefined
            ? MultilanguageField.fromJSON(jsonObject['offerPositionName'])
            : undefined;
        entry.dimensions = jsonObject['dimensions'];
        entry.systemName = jsonObject['systemName'] != undefined
            ? MultilanguageField.fromJSON(jsonObject['systemName'])
            : undefined;
        return entry;
    }
}

@JsonDeserializable
export class PrintHistoryEntry {
    id: number;
    printTimestamp: Date;
    exchangeRate: number;
    offerId: number;
    userName: string;
    impersonatorUserName: string;
    currency: Currencies;
    printOptions: PrintOptions;

    static fromJSON(jsonObject: any): PrintHistoryEntry {
        const entry = new PrintHistoryEntry();
        entry.id = jsonObject['id'];
        entry.printTimestamp = jsonObject['printTimestamp'] != undefined ? new Date(jsonObject['printTimestamp']) : undefined;
        entry.exchangeRate = jsonObject['exchangeRate'];
        entry.offerId = jsonObject['offerId'];
        entry.userName = jsonObject['userName'];
        entry.impersonatorUserName = jsonObject['impersonatorUserName'];
        entry.currency = Currencies[jsonObject['currency']];
        entry.printOptions = JSON.parse(jsonObject['printOptions'], MapToJsonTransformer.reviver);
        return entry;
    }
}
