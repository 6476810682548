import {
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild
} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {SelectInvalidValueCorrectedEvent} from "../../../form-inputs/inputs/select/select.component";

@Component({
    selector: 'app-select-or-checkbox',
    templateUrl: './select-or-checkbox.component.html'
})
export class SelectOrCheckboxComponent implements OnChanges {

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    placeholder: string;

    @Input()
    validationMessageKey: string;

    @Input()
    hasValidationError: boolean;

    @Input()
    disabled: boolean;

    @Input()
    modelOptions: {
        updateOn?: 'change' | 'blur';
    };

    @Output()
    validationMessageKeyChange = new EventEmitter<string>();

    @Output()
    onFocus = new EventEmitter<FocusEvent>();

    @Output()
    onBlur = new EventEmitter<FocusEvent>();

    @Input()
    options: any[];

    @Input()
    value: any;

    @Input()
    required: boolean;

    @Input()
    noLongerAvailable = false;

    @Input()
    allowSelectingNone = false;

    @Input()
    noneOptionPosition: 'first' | 'last' = 'first';

    @Input()
    checkAvailability = false;

    @Input()
    translateLabels: boolean;

    @Input()
    optionFormatter: (option: any) => SelectItem;

    @Input()
    optionKey: (option: any) => any;

    @Input()
    fakeSelect = false;

    @Output()
    readonly onClick = new EventEmitter<MouseEvent>();

    @Output()
    readonly beginInvalidValueCorrection = new EventEmitter<SelectInvalidValueCorrectedEvent>();

    @Output()
    readonly endInvalidValueCorrection = new EventEmitter<void>();

    @Output()
    readonly valueChange = new EventEmitter<any>();

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    @ContentChild('labelTemplate', {read: TemplateRef, static: true}) public labelTemplate;

    @ContentChild('validationMessageTemplate', {read: TemplateRef, static: true}) public validationMessageTemplate;

    isCheckbox = false;

    constructor() {
        this.options = [];
        this.optionFormatter = option => option;
        this.optionKey = value => value;
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('options' in changes) {
            this.isCheckbox = this.options.length === 1 && this.options[0].singlePositionItem;
        }
    }

    handleCheckboxChange(selected): void {
        let value = selected ? this.options[0].value : undefined;
        this.valueChange.emit(value);
    }
}
