<div class="list-content">
    <p-dataTable #dt [value]="deliveryData" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [seqNum]="1" [lazy]="true" selectionMode="single"
                 [responsive]="true" (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)"
                 emptyMessage="{{'PALETTE_PRODUCTION_ORDERS_TABLE.NO_DATA_DETAILS' | translate}}" id="paletteProductionOrdersTable"
                 class="fixed-table" sortField="offerNumber">

        <p-column [style]="{'vertical-align':'middle'}">
            <ng-template pTemplate="header">
                <app-tristate-checkbox [ngModel]="allSelectedState" (ngModelChange)="selectAllChange($event)"></app-tristate-checkbox>
            </ng-template>
            <ng-template let-data="rowData" pTemplate="body">
                    <span class="flex-row">
                        <app-checkbox [ngModel]="isSelectedItem(data)" (ngModelChange)="selectItem(data)"></app-checkbox>
                    </span>
            </ng-template>
        </p-column>
        <p-column field="orderNumber" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.ORDER_NUMBER' | translate }}" [sortable]="true" [filter]="true"
                  filterMatchMode="contains"></p-column>
        <p-column field="companyName" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.COMPANY_NAME' | translate }}" [sortable]="true" [filter]="true"
                  filterMatchMode="contains"></p-column>
        <p-column field="offerNumber" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.OFFER_NUMBER' | translate }}" [sortable]="true" [filter]="true"
                  filterMatchMode="contains"></p-column>
        <p-column field="alternateOfferNumber" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.ALTERNATE_OFFER_NUMBER' | translate }}" [sortable]="true" [filter]="true"
                  filterMatchMode="contains"></p-column>
        <p-column field="lastStatusChange" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.LAST_STATUS_CHANGE' | translate }}" [filter]="true"
                  [sortable]="true" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="false"></div>
            </ng-template>
            <ng-template let-data="rowData" pTemplate="body">
                {{ data.lastStatusChange | momentCurrentLang | amDateFormat: 'L' }}
            </ng-template>
        </p-column>
        <p-column field="paymentPackageName" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.PAYMENT_PACKAGE_NAME' | translate }}" [sortable]="true" [filter]="true"
                  filterMatchMode="contains"></p-column>
        <p-column field="clientManagerName" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.CLIENT_MANAGER_NAME' | translate }}" [sortable]="true" [filter]="true">
            <ng-template let-data="rowData" pTemplate="body">
                <div>{{ data.clientManagerFirstName + ' ' + data.clientManagerLastName}}</div>
            </ng-template>
        </p-column>
        <p-column field="clientManagerName" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.MERCHANT_NAME' | translate }}" [sortable]="true" [filter]="true">
            <ng-template let-data="rowData" pTemplate="body">
                <div>{{ data.merchantFirstName + ' ' + data.merchantLastName}}</div>
            </ng-template>
        </p-column>
        <p-column field="clientName" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.CLIENT_NAME' | translate }}" [sortable]="true" [filter]="true"
                  filterMatchMode="contains"></p-column>
        <p-column field="clientIdentifier" header="{{ 'PALETTE_PRODUCTION_ORDERS_TABLE.CLIENT_IDENTIFIER' | translate }}" [sortable]="true" [filter]="true"
                  filterMatchMode="contains"></p-column>
    </p-dataTable>
</div>
