import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";
import {SupplierInterface} from "../../../../window-designer/catalog-data/supplier-interface";
import {ConfigTypeDescriptor} from "../config-type-descriptor/config-type-descriptor";
import {MessageSeverity} from "../../offer/offers/message";
import {ConfigAddonApplication} from "../../../../window-designer/enums/ConfigAddonApplication";
import {WindowSystemMaterialLinks} from "../single-system-checkbox-crud/window-system-material-links";

@JsonDeserializable
export class ConfigSystem implements CrudItem, WindowSystemMaterialLinks {
    id: number;
    category: ConfigTypeDescriptor;
    function: ConfigTypeDescriptor;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    sortIndex: number;
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;
    symbol: string;
    supplier: SupplierInterface;
    dimensionStep = 1;
    unitWeight: string;
    hasDimension1: boolean;
    hasDimension2: boolean;
    hasDimension3: boolean;
    hasDimension4: boolean;
    hasDimension5: boolean;
    hasDimension6: boolean;
    hasWidth1: boolean;
    hasWidth2: boolean;
    hasWidth3: boolean;
    hasHeight1: boolean;
    hasHeight2: boolean;
    hasHeight3: boolean;
    active = true;
    messagesHiddenOnOffer: MessageSeverity[];
    messagesHiddenOnOrder: MessageSeverity[];
    applicableTo: ConfigAddonApplication[];
    description: { [propertyId: number]: number; } = {};
    image: string;
    designerImage: string;
    imageLarge: string;
    materialColorCategoryGroupId: number;
    materialColorPrevCategoryId: number;

    canBeAddedToAreaWithOuterGrill: boolean;
    canBeAddedToAreaWithoutFilling: boolean;
    canBeAddedToAreaWithOtherFilling: boolean;
    canBeAddedToAreaWithDecorativeFilling: boolean;
    canBeAddedToOpenableOnly: boolean;

    hideColorSelect: boolean;

    sizingVideoLink: string;
    amazonS3FileName: string;

    static fromJSON(jsonObject: any): ConfigSystem {
        const config = new ConfigSystem();
        config.id = jsonObject.id;
        config.category = ConfigTypeDescriptor.fromJSON(jsonObject.category);
        config.function = ConfigTypeDescriptor.fromJSON(jsonObject.function);
        config.name = MultilanguageField.fromJSON(jsonObject.name);
        config.shortcut = MultilanguageField.fromJSON(jsonObject.shortcut);
        config.sortIndex = jsonObject.sortIndex;
        config.symbol = jsonObject.symbol;
        config.supplier = jsonObject.supplier;
        config.dimensionStep = jsonObject.dimensionStep;
        config.unitWeight = jsonObject.unitWeight;
        config.hasDimension1 = jsonObject.hasDimension1;
        config.hasDimension2 = jsonObject.hasDimension2;
        config.hasDimension3 = jsonObject.hasDimension3;
        config.hasDimension4 = jsonObject.hasDimension4;
        config.hasDimension5 = jsonObject.hasDimension5;
        config.hasDimension6 = jsonObject.hasDimension6;
        config.hasWidth1 = jsonObject.hasWidth1;
        config.hasWidth2 = jsonObject.hasWidth2;
        config.hasWidth3 = jsonObject.hasWidth3;
        config.hasHeight1 = jsonObject.hasHeight1;
        config.hasHeight2 = jsonObject.hasHeight2;
        config.hasHeight3 = jsonObject.hasHeight3;
        config.active = jsonObject.active;
        config.messagesHiddenOnOffer = jsonObject.messagesHiddenOnOffer;
        config.messagesHiddenOnOrder = jsonObject.messagesHiddenOnOrder;
        config.applicableTo = jsonObject.applicableTo;
        config.description = jsonObject.description;
        config.image = jsonObject.image;
        config.designerImage = jsonObject.designerImage;
        config.imageLarge = jsonObject.imageLarge;
        config.materialColorCategoryGroupId = jsonObject.materialColorCategoryGroupId;
        config.materialColorPrevCategoryId = jsonObject.materialColorPrevCategoryId;

        config.sizingVideoLink = jsonObject.sizingVideoLink;
        config.amazonS3FileName = jsonObject.amazonS3FileName;

        config.canBeAddedToAreaWithOuterGrill = jsonObject.canBeAddedToAreaWithOuterGrill;
        config.canBeAddedToAreaWithoutFilling = jsonObject.canBeAddedToAreaWithoutFilling;
        config.canBeAddedToAreaWithOtherFilling = jsonObject.canBeAddedToAreaWithOtherFilling;
        config.canBeAddedToAreaWithDecorativeFilling = jsonObject.canBeAddedToAreaWithDecorativeFilling;
        config.canBeAddedToOpenableOnly = jsonObject.canBeAddedToOpenableOnly;

        config.hideColorSelect = jsonObject.hideColorSelect;
        return config;
    }
}
