import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {ConfigSliderElementService} from "../../../window-system/config-slider-element/config-slider-element.service";
import {ConfigSliderElement} from "../../../window-system/config-slider-element/config-slider-element";
import {TranslateService} from "@ngx-translate/core";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'app-add-product-slider',
    templateUrl: './add-product-slider.component.html',
    styleUrls: ['./add-product-slider.component.css'],
    providers: [ConfigSliderElementService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddProductSliderComponent {

    @Input() visible: boolean;
    @Input() elements: ConfigSliderElement[] = [];
    @Input() header: string;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();

    constructor(private configSliderElementService: ConfigSliderElementService,
                public translate: TranslateService,
                private blockUiController: BlockUiController) {
    }
}
