import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    Optional,
    Provider,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AbstractInputComponent, FormHandler} from '../../form-inputs/inputs/abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../../form-inputs/inputs/form-handler-token';
import {DisplayUnitService, SupportedDisplayUnitSystem} from '../service/display-unit.service';
import {InchToMmCompositeInputValue, UnitConverter} from '../unit-converter';

const INPUT_LENGTH_VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputLengthComponent),
    multi: true
};

@Component({
    selector: 'app-input-length',
    templateUrl: './input-length.component.html',
    styleUrls: ['./input-length.component.css'],
    providers: [INPUT_LENGTH_VALUE_ACCESSOR]
})
export class InputLengthComponent extends AbstractInputComponent implements OnChanges {

    @Input()
    min: number;

    @Input()
    max: number;

    @Input()
    step: number;

    @Input()
    shouldRoundToStep: boolean;

    @Input()
    required: boolean;

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    inches: InchToMmCompositeInputValue;
    inchesMin: number;
    inchesMax: number;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler,
                private readonly displayUnitService: DisplayUnitService) {
        super(renderer, changeDetector, form);
    }

    ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);
        const minChange = changes['min'];
        if (minChange != undefined) {
            if (minChange.currentValue != undefined) {
                this.inchesMin = UnitConverter.millimitersToInches(minChange.currentValue).full;
            } else {
                this.inchesMin = undefined;
            }
        }
        const maxChange = changes['max'];
        if (maxChange != undefined) {
            if (maxChange.currentValue != undefined) {
                this.inchesMax = UnitConverter.millimitersToInches(maxChange.currentValue).full;
            } else {
                this.inchesMax = undefined;
            }
        }
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }

    writeValue(obj: any) {
        this.inches = obj != undefined ? UnitConverter.millimitersToInches(obj) : undefined;
        super.writeValue(obj);
    }

    handleValueChange(value: number): void {
        this.inches = UnitConverter.millimitersToInches(value);
        this.value = value;
    }

    handleInchesChange(value: number): void {
        this.inches.full = value;
        this.value = UnitConverter.inchesToMillimeters(this.inches);
    }

    handleInchFractionChange(value: number): void {
        this.inches.sixteenths = value;
        this.value = UnitConverter.inchesToMillimeters(this.inches);
    }

    roundToStep() {
        const remainder = this.value % this.step;
        if (remainder !== 0) {
            this.handleValueChange(remainder < (this.step / 2) ? this.value - remainder : this.value + (this.step - remainder));
        }
    }

    handleBlur(event: FocusEvent) {
        if (this.value > this.max) {
            this.handleValueChange(this.max);
        }
        if (this.value < this.min) {
            this.handleValueChange(this.min);
        }
        if (this.shouldRoundToStep) {
            this.roundToStep();
        }
        super.handleBlur(event);
    }

    get displayInches(): Observable<boolean> {
        return this.displayUnitService.system.pipe(map(system => system === SupportedDisplayUnitSystem.IMPERIAL));
    }
}
