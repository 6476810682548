import {JsonDeserializable} from "../../../../../../common/crud-common/crudItem";

@JsonDeserializable
export class RotData {

    offerId: number;
    rotPercentValue: number;
    grossLimit: number;

    constructor(offerId: number, rotPercentValue: number, grossLimit: number) {
        this.offerId = offerId;
        this.rotPercentValue = rotPercentValue;
        this.grossLimit = grossLimit;
    }

    static fromJSON(jsonObject: any): RotData {
        return jsonObject == null ? null : new RotData(jsonObject.offerId, jsonObject.rotPercentValue, jsonObject.grossLimit);
    }

    clone(): RotData {
        return new RotData(this.offerId, this.rotPercentValue, this.grossLimit);
    }
}
