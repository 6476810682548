<div *ngFor="let message of validationMessages">
    <div class="pricing-message-padding">
        <app-pricing-message [message]="message"></app-pricing-message>
    </div>
</div>
<div *ngFor="let validationError of validationErrors" class="new-form-field-error-message">
    {{ validationError | translate }}
</div>
<div *ngFor="let product of pricingProducts">
    <div class="pricing-message-padding" *ngIf="product.messages.length > 0">
        <div *ngIf="(product.positionName | json) == '{}'" class="primary-text">{{"OFFER.TABS.PRICING.PRODUCT.TYPE.GENERAL"  | translate}}
        </div>
        <div *ngIf="(product.positionName | json) != '{}'" class="primary-text">{{"OFFER.TABS.PRICING.PRODUCT.TYPE." + (product | productType)  | translate}}
            {{product.positionName[translate.currentLang]}} {{product.dimension}}
        </div>
    </div>

    <div *ngFor="let message of product.messages">
        <div *ngIf="message.severity !== 'CODE'" class="pricing-message-padding">
            <app-pricing-message [message]="message"></app-pricing-message>
        </div>
    </div>
</div>

<app-config-addon-pricing [configAddonPricingsAndModels]="configAddonPricingsAndModels" [validationMode]="true"></app-config-addon-pricing>
