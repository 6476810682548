export enum CatalogElement {
    ADDONS = 'ADDONS',
    ADDON_CATEGORY_GROUPS = 'ADDON_CATEGORY_GROUPS',
    ADDON_CATEGORIES = 'ADDON_CATEGORIES',
    CONFIG_ADDONS = 'CONFIG_ADDONS',
    COLORS = 'COLORS',
    GLAZING_BEADS = 'GLAZING_BEADS',
    MATERIALS = 'MATERIALS',
    CONFIG_SYSTEMS = 'CONFIG_SYSTEMS',
    CONFIG_DEPENDENT_OPTIONS = 'CONFIG_DEPENDENT_OPTIONS',
    WINDOW_DEPENDENT_OPTIONS = 'WINDOW_DEPENDENT_OPTIONS',
    SYSTEM_GLAZING_PACKAGES = 'SYSTEM_GLAZING_PACKAGES',
    SYSTEM_DECORATIVE_GLAZING_PACKAGES = 'SYSTEM_DECORATIVE_GLAZING_PACKAGES',
    TERRACE_GLAZING_PACKAGES = 'TERRACE_GLAZING_PACKAGES',
    PROFILES = 'PROFILES',
    DISTANCE_FRAMES = 'DISTANCE_FRAMES',
    WINDOW_SYSTEMS = 'WINDOW_SYSTEMS',
    GRILLS = 'GRILLS',
    GLASSES = 'GLASSES',
    BUSINESS_TYPES = 'BUSINESS_TYPES',
    SUBWINDOW_TYPES = 'SUBWINDOW_TYPES',
    SEALS = 'SEALS',
    OTHER_FILLINGS = 'OTHER_FILLINGS',
    DECORATIVE_FILLINGS = 'DECORATIVE_FILLINGS',
    ROOF_GLAZING_PACKAGES = 'ROOF_GLAZING_PACKAGES',
    ROOF_SYSTEMS = 'ROOF_SYSTEMS',
    ROOF_DIMENSIONS = 'ROOF_DIMENSIONS',
    ENTRANCE_MODELS = 'ENTRANCE_MODELS',
    GRASP_GLAZING_CATEGORIES = 'GRASP_GLAZING_CATEGORIES',
    GRASP_DISTANCE_FRAME_CATEGORIES = 'GRASP_DISTANCE_FRAME_CATEGORIES',
    GRASP_GLAZING_PACKAGES = 'GRASP_GLAZING_PACKAGES',
    ENTRANCE_GLAZING_PACKAGES = 'ENTRANCE_GLAZING_PACKAGES',
    ENTRANCE_SYSTEMS = 'ENTRANCE_SYSTEMS',
    ENTRANCE_DIMENSIONS = 'ENTRANCE_DIMENSIONS',
    GATE_SYSTEMS = 'GATE_SYSTEMS',
    GATE_WALLS = 'GATE_WALLS',
    GATE_DEPENDENT_OPTIONS = 'GATE_DEPENDENT_OPTIONS',
    GATE_PANEL_TYPES = 'GATE_PANEL_TYPES',
    RAIL_SYSTEMS = 'RAIL_SYSTEMS',
    RACKS = 'RACKS'
}
