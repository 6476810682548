<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'GRASP_GLAZING_CATEGORIES.TITLE.LIST' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy" label="{{ 'GRASP_GLAZING_CATEGORIES.TITLE.COPY' | translate }}" icon="content_copy"
                                   type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'GRASP_GLAZING_CATEGORIES.TITLE.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" id="categoriesTable" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column *ngIf="fieldUsage.show(GraspGlazingCategoryField.NAME)"
                  field="name" sortField="{{ 'name.' + translate.currentLang }}" filterField="name"
                  header="{{ 'GRASP_GLAZING_CATEGORIES.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column header="{{ 'GRASP_GLAZING_CATEGORIES.FORM.SORT_INDEX' | translate }}" [sortable]="true" field="sortIndex"></p-column>
        <p-column *ngIf="fieldUsage.show(GraspGlazingCategoryField.ACTIVE)"
                  field="active" [filter]="showFilters" header="{{ 'GRASP_GLAZING_CATEGORIES.FORM.STATUS' | translate }}"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'GRASP_GLAZING_CATEGORIES.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'GRASP_GLAZING_CATEGORIES.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'GRASP_GLAZING_CATEGORIES.TITLE.ADD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'GRASP_GLAZING_CATEGORIES.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-grasp-glazing-categories-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-grasp-glazing-categories-form>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog" header="{{ (copyMode ? 'GRASP_GLAZING_CATEGORIES.TITLE.COPY' : 'GRASP_GLAZING_CATEGORIES.TITLE.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" contentStyleClass="scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'GRASP_GLAZING_CATEGORIES.STEPS.DATA' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-grasp-glazing-categories-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-grasp-glazing-categories-form>
    </app-wizard-step>
</app-wizard-dialog>
