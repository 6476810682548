import {Guide} from "./drawing-data/Guide";
import {GuideData} from "./drawing-data/GuideData";
import {GuideHelper} from "./drawing-data/GuideHelper";
import {MaxWindowDimensions} from './max-window-dimensions';
import {ErrorNames} from "./utils/ErrorNames";
import {RandomIdGenerator} from "./utils/RandomIdGenerator";

export class GuidesDataHelper {
    public static addStructureGuides(gData: GuideData, positions: number[], subwindowGeneratedId?: string) {
        for (let p of positions) {
            GuidesDataHelper.addGuide(gData.structure, p, subwindowGeneratedId);
        }
        this.recalculateMainGuide(gData);
    }

    public static removeEmptyGuides(gData: GuideData) {
        let filterFunc = (guide: Guide, index: number, arr: Guide[]) => guide.size !== 0 || index === arr.length - 1;
        gData.main = gData.main.filter(filterFunc);
        gData.structure = gData.structure.filter(filterFunc);
    }

    public static recalculatePositions(gData: GuideData) {
        this.recalculatePositionsPrivate(gData.main);
        this.recalculatePositionsPrivate(gData.structure);
        this.recalculateMainGuide(gData);
    }

    public static checkTotalSize(gData: GuideData, isChangeVertical: boolean): void {
        const totalSize = gData.structure.reduce((sum, guide, index, arr) => sum + (index !== arr.length - 1 ? guide.size : 0), 0);
        if (totalSize <= 0) {
            let err = new Error("GuidesHelper.checkTotalSize: Niedozwolona operacja - próba ustawienia zerowego głównego rozmiaru");
            err.name = ErrorNames.RESIZE_FAILED;
            throw err;
        }
        const maxDimensions = new MaxWindowDimensions();
        if (gData.main[0].size > (isChangeVertical ? maxDimensions.MAX_HEIGHT : maxDimensions.MAX_WIDTH)) {
            let err: Error & {
                params?: {
                    [key: string]: string
                }
            } = new Error("GuidesHelper.checkTotalSize: Niedozwolona operacja - próba zbyt dużego głównego rozmiaru");
            err.name = ErrorNames.CONSTRUCTION_TOO_BIG_ERROR;
            err.params = {
                h: '' + maxDimensions.MAX_HEIGHT,
                w: '' + maxDimensions.MAX_WIDTH
            };
            throw err;
        }
    }

    private static recalculateMainGuide(gData: GuideData) {
        let begin = gData.structure[0].position;
        let end = GuideHelper.end(gData.structure[gData.structure.length - 1]);
        let size = end - begin;
        if (gData.main.length === 0) {
            var newMainGuide = this.addGuide(gData.main, begin);
            newMainGuide.locked = true;
            this.addGuide(gData.main, end);
        } else {
            let guide = gData.main[0];
            guide.generatedId = guide.generatedId || RandomIdGenerator.generate();
            guide.position = begin;
            guide.size = size;
            this.recalculatePositionsPrivate(gData.main);
        }
    }

    private static addGuide(guides: Guide[], position: number, subwindowGeneratedId?: string): Guide {
        let found = guides.find(g => g.position === position);
        if (found) {
            found.subwindowGeneratedId = subwindowGeneratedId;
            return;
        }
        let newGuide = new Guide(position);
        newGuide.subwindowGeneratedId = subwindowGeneratedId;
        guides.push(newGuide);
        guides.sort((a, b) => a.position - b.position);
        let index =  guides.indexOf(newGuide);
        if (index < guides.length - 1) {
            newGuide.size = guides[index + 1].position - position;
        }
        if (index > 0) {
            guides[index - 1].size = position - guides[index - 1].position;
        }
        return newGuide;
    }

    private static recalculatePositionsPrivate(guides: Guide[]) {
        for (let i = 1; i < guides.length; ++i) {
            guides[i].position = GuideHelper.end(guides[i - 1]);
        }
    }
}
