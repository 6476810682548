<app-global-change-confirmation (onSubmit)="submitDialog($event)"
                                [visible]="this.dialogData.modifiedPositions.length > 0"
                                (visibleChange)="submitDialog(false)"
                                [allPositionsContainErrors]="allPositionsContainErrors"
                                [somePositionsContainErrors]="somePositionsContainErrors">
    <div class="position" *ngFor="let position of this.dialogData.modifiedPositions">
        <div class="name-status">
            <div class="name">
                {{ position.printOrder + ' - '}}
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.POSITION' | translate }} {{
                position.name[translate.currentLang] }}
            </div>
            <div *ngIf="positionContainErrors(position); else confirmationValid" class="status failure">
                {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.NOT_VALID' | translate }}
            </div>
            <ng-template #confirmationValid>
                <div class="status success">
                    {{ 'OFFER.POSITIONS.DIALOGS.BULK_CHANGE_CONFIRMATION.VALID' | translate }}
                </div>
            </ng-template>

            </div>
            <ng-container *ngIf="positionContainErrors(position)">
                <div class="new-form-field-error-message" *ngFor="let error of getPositionErrors(position.id)"
                     [innerHTML]="error | translate"></div>
            </ng-container>
            <ng-container *ngFor="let msg of getPositionMessages(position.id)">
                <app-pricing-message [ignoreFieldName]="true" [message]="msg"></app-pricing-message>
            </ng-container>
        </div>
</app-global-change-confirmation>
