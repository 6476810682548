import {ChangeDetectionStrategy, Component, Injector, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, of} from 'rxjs';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {DropDownExtraOptions} from '../../../../../../../shared/drop-down-extra-options';
import {DropDownExtraOptionsLabels} from '../../../../../../../shared/DropDownExtraOptionsProvider';
import {DrawingData} from '../../../../../../../window-designer/drawing-data/drawing-data';
import {FittingTerraceLockLocation} from '../../../../../../../window-designer/drawing-data/FittingTerraceLockLocation';
import {AddonCategoryEnum} from '../../../../../../../window-designer/enums/AddonCategoryEnum';
import {AddonFor} from '../../../../../../../window-designer/enums/AddonFor';
import {AddonDefaultQuantityCalculator} from "../../../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator";
import {Addon} from '../../../../../window-system/addons/addon';
import {AddonsService} from '../../../../../window-system/addons/addons.service';
import {DecorativeFillingsService} from '../../../../../window-system/decorative-filling/decorative-filling.service';
import {DecorativeFilling} from '../../../../../window-system/decorative-filling/decorativeFilling';
import {Glass} from '../../../../../window-system/glass/glass';
import {GlassService} from '../../../../../window-system/glass/glass.service';
import {CommonBulkChangeComponent} from '../common-bulk-change.component';
import {Position} from '../position';
import {BulkFittingsChangeDialogData} from '../position-list-dialogs';

@Component({
    selector: 'app-bulk-fitting-change',
    templateUrl: './bulk-fitting-change.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AddonsService, GlassService, DecorativeFillingsService]
})
export class BulkFittingChangeComponent extends CommonBulkChangeComponent implements OnInit {

    @Input()
    dialogData: BulkFittingsChangeDialogData;

    anyFittingAvailable = false;

    availableFittingBrakes: SelectItem[];
    availableFittingSlidings: SelectItem[];
    availableFittingTypes: SelectItem[];
    availableFittingEspagnoletteTypes: SelectItem[];
    availableFittingVerandas: SelectItem[];
    availableFittingInsertions: SelectItem[];
    availableFittingMainInsertions: SelectItem[];
    availableFittingAdditionalInsertions: SelectItem[];
    availableFittingLocks: SelectItem[];
    availableFittingLocksTerrace: SelectItem[];
    availableFittingLocksTerraceLocations: SelectItem[];
    availableFittingAutomaticDrives: SelectItem[];

    fittingBrake: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingSliding: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingType: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingEspagnoletteType: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingVeranda: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingInsertion: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingMainInsertion: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingAdditionalInsertion: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingLock: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingLockTerrace: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingLockTerraceLocation: FittingTerraceLockLocation | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;
    fittingAutomaticDrive: Addon | DropDownExtraOptions = DropDownExtraOptions.DO_NOT_CHANGE;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit(): void {
        this.initStaticData(false);
        const addonsFillters = {
            active: {value: 'true'},
            forOffer: {value: 'true'},
            includeGeneralAddons: {value: 'true'},
            includeSubsystemAddons: {value: 'true'},
            withColors: {value: 'false'},
            withPrices: {value: 'false'},
            windowSystemId: {value: Array.from(this.windowSystemIds)}
        };
        forkJoin({
            addons: this.addonsService.getItems(0, undefined, {
                ...addonsFillters,
                requireAllSystens: {value: 'false'}
            }, 'id', 1),
            boldAddons: this.addonsService.getItems(0, undefined, {
                ...addonsFillters,
                requireAllSystens: {value: 'true'}
            }, 'id', 1),
            glasses: this.glassIds.size > 0 ? this.glassService.getGlasses(Array.from(this.glassIds)) : of<Glass[]>([]),
            decorativeFillings: this.decorativeFillingIds.size ? this.decorativeFillingService.getDecorativeFillings(Array.from(this.decorativeFillingIds)) : of<DecorativeFilling[]>([])
        }).pipe(
            finalize(() => this.blockUiController.unblock(CommonBulkChangeComponent.LOAD_DATA_ID))
        ).subscribe({
            next: catalogData => {
                let data = _.groupBy(catalogData.addons.data.filter(addon => addon.addonFor !== AddonFor[AddonFor.BULK]),
                    addon => addon.category);
                let boldData = _.groupBy(catalogData.boldAddons.data.filter(addon => addon.addonFor !== AddonFor[AddonFor.BULK]),
                    addon => addon.category);
                const buildAddonSelectItems = (category: AddonCategoryEnum, extra?: DropDownExtraOptionsLabels) => {
                    return this.buildAddonSelectItems(data[category], extra, boldData[category]);
                };
                this.availableFittingBrakes = buildAddonSelectItems(AddonCategoryEnum.FITTING_BRAKE);
                this.availableFittingSlidings = buildAddonSelectItems(AddonCategoryEnum.FITTING_SLIDING,
                    DropDownExtraOptionsLabels.OPTION_STANDARD);
                this.availableFittingTypes = buildAddonSelectItems(AddonCategoryEnum.FITTING_TYPE,
                    DropDownExtraOptionsLabels.OPTION_STANDARD);
                this.availableFittingEspagnoletteTypes = buildAddonSelectItems(AddonCategoryEnum.FITTING_ESPAGNOLETTE_TYPE);
                this.availableFittingVerandas = buildAddonSelectItems(AddonCategoryEnum.FITTING_VERANDA);
                this.availableFittingInsertions = buildAddonSelectItems(AddonCategoryEnum.FITTING_INSERTION);
                this.availableFittingMainInsertions = buildAddonSelectItems(AddonCategoryEnum.FITTING_MAIN_INSERTION);
                this.availableFittingAdditionalInsertions = buildAddonSelectItems(AddonCategoryEnum.FITTING_ADDITIONAL_INSERTION);
                this.availableFittingLocks = buildAddonSelectItems(AddonCategoryEnum.FITTING_LOCK);
                this.availableFittingLocksTerrace = buildAddonSelectItems(AddonCategoryEnum.FITTING_LOCK_TERRACE);
                this.availableFittingLocksTerraceLocations = this.buildEnumSelectItems('TERRACE_LOCK_LOCATION.', FittingTerraceLockLocation);
                this.availableFittingAutomaticDrives = buildAddonSelectItems(AddonCategoryEnum.FITTING_AUTOMATIC_DRIVE,
                    DropDownExtraOptionsLabels.OPTION_STANDARD);
                this.anyFittingAvailable = this.anyFittingsAvailable();
                this.staticData.glasses = catalogData.glasses;
                this.staticData.decorativeFillings = catalogData.decorativeFillings;
                this.changeDetector.markForCheck();
            },
            error: (error) => {
                this.errors.handle(error);
            },
            complete: () => {
                this.dataReady = true;
                this.changeDetector.markForCheck();
            }
        });
    }

    private anyFittingsAvailable(): boolean {
        return this.availableFittingBrakes.length > 0 ||
        this.availableFittingSlidings.length > 0 ||
        this.availableFittingTypes.length > 0 ||
        this.availableFittingEspagnoletteTypes.length > 0 ||
        this.availableFittingVerandas.length > 0 ||
        this.availableFittingInsertions.length > 0 ||
        this.availableFittingMainInsertions.length > 0 ||
        this.availableFittingAdditionalInsertions.length > 0 ||
        this.availableFittingLocks.length > 0 ||
        this.availableFittingLocksTerrace.length > 0 ||
        this.availableFittingAutomaticDrives.length > 0;
    }

    protected getSelectedPositions(): Position[] {
        return this.dialogData.selectedOfferPositions;
    }

    submitDialog() {
        for (let updatedPosition of this.positionsToUpdate) {
            const drawingData = updatedPosition.mappedData as DrawingData;
            const specification = drawingData.specification;
            const dataSource = AddonDefaultQuantityCalculator.prepareDataSource(drawingData, this.staticData);
            specification.fittingBrake = this.selectAddonValue(specification.fittingBrake, this.fittingBrake, dataSource);
            specification.fittingSliding = this.selectAddonValue(specification.fittingSliding, this.fittingSliding, dataSource);
            specification.fittingType = this.selectAddonValue(specification.fittingType, this.fittingType, dataSource);
            specification.fittingEspagnoletteType = this.selectAddonValue(specification.fittingEspagnoletteType,
                this.fittingEspagnoletteType, dataSource);
            specification.fittingVeranda = this.selectAddonValue(specification.fittingVeranda, this.fittingVeranda, dataSource);
            specification.fittingInsertion = this.selectAddonValue(specification.fittingInsertion, this.fittingInsertion, dataSource);
            specification.fittingMainInsertion = this.selectAddonValue(specification.fittingMainInsertion, this.fittingMainInsertion,
                dataSource);
            specification.fittingAdditionalInsertion = this.selectAddonValue(specification.fittingAdditionalInsertion,
                this.fittingAdditionalInsertion, dataSource);
            specification.fittingLock = this.selectAddonValue(specification.fittingLock, this.fittingLock, dataSource);
            specification.fittingLockTerrace = this.selectAddonValue(specification.fittingLockTerrace, this.fittingLockTerrace, dataSource);
            specification.fittingLockTerraceLocation = this.submitValueToMappedData(specification.fittingLockTerraceLocation, this.fittingLockTerraceLocation);
            specification.fittingAutomaticDrive = this.selectAddonValue(specification.fittingAutomaticDrive, this.fittingAutomaticDrive,
                dataSource);
            if (specification.fittingLockTerrace == undefined || specification.fittingLockTerrace.addonId == undefined) {
                specification.fittingLockTerraceLocation = undefined;
            }

            updatedPosition.offerPosition.data = JSON.stringify(drawingData);
        }

        this.dialogData.modifiedPositions = this.positionsToUpdate.map(updatedPosition => updatedPosition.offerPosition);
        this.dialogHideHelper.call(() => this.onSubmit.emit());
    }
}
