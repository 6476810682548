import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Optional,
    Renderer2,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';

export const TRISTATE_CHECKBOX_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TristateCheckboxComponent),
    multi: true
};

export enum TristateCheckboxState {
    CHECKED,
    UNCHECKED,
    CHECKED_PARTIALLY
}

@Component({
    selector: 'app-tristate-checkbox',
    templateUrl: './tristate-checkbox.component.html',
    styleUrls: ['./tristate-checkbox.component.css'],
    providers: [TRISTATE_CHECKBOX_VALUE_ACCESSOR],
    encapsulation: ViewEncapsulation.None
})
export class TristateCheckboxComponent extends AbstractInputComponent {

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    state = TristateCheckboxState;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    getContainer(): ElementRef {
        return this.containerElement;
    }

    handleValueChange(newState: TristateCheckboxState): void {
        if (!this.disabled) {
            this.value = newState;
        }
    }
}
