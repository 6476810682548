import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {

    transform(value: string): string {
        if (value != undefined) {
            return value.replace(/<.*?>/g, '').replace(/&(\w+);/g, (match, dec) => {
                return String.fromCharCode(dec);
            });
        }
    }

}
