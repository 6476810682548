<p-dialog #dialog [(visible)]="visible" [modal]="true" (onHide)="onHide.emit($event)">
    <div class="scrolled-dialog scrolled-dialog-60vw">
        <div class="controls-container">
            <div *ngIf="windowSystems.length > 0" class="system-controls-container">
                <app-select class="system-select" inputId="windowSystemSelect"
                            label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_SYSTEMS.LIST' | translate }}"
                            [options]="windowSystems | selectItemMultilanguageFieldTranslate"
                            [(ngModel)]="windowSystem"></app-select>
                <app-simple-button *ngIf="canViewVenskaPrices"
                                   buttonId="addWindowSystemDealerDiscount"
                                   label="{{ 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.ADD' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="windowSystem == undefined || overrides.windowSystemDealerDiscounts.has(windowSystem)"
                                   (onClick)="addWindowSystemDealerDiscount(windowSystem)"></app-simple-button>
                <app-simple-button buttonId="addWindowSystemProfitMargin"
                                   label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.ADD_PROFIT_MARGIN' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="windowSystem == undefined || overrides.windowSystemProfitMargins.has(windowSystem)"
                                   (onClick)="addWindowSystemProfitMargin(windowSystem)"></app-simple-button>
            </div>
            <div *ngIf="gateSystems.length > 0" class="system-controls-container">
                <app-select class="system-select" inputId="gateSystemSelect"
                            label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GATE_SYSTEMS.LIST' | translate }}"
                            [options]="gateSystems | selectItemMultilanguageFieldTranslate"
                            [(ngModel)]="gateSystem"></app-select>
                <app-simple-button *ngIf="canViewVenskaPrices"
                                   buttonId="addGateSystemDealerDiscount"
                                   label="{{ 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.ADD' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="gateSystem == undefined || overrides.gateSystemDealerDiscounts.has(gateSystem)"
                                   (onClick)="addGateSystemDealerDiscount(gateSystem)"></app-simple-button>
                <app-simple-button buttonId="addGateSystemProfitMargin"
                                   label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.ADD_PROFIT_MARGIN' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="gateSystem == undefined || overrides.gateSystemProfitMargins.has(gateSystem)"
                                   (onClick)="addGateSystemProfitMargin(gateSystem)"></app-simple-button>
            </div>
            <div *ngIf="configSystems.length > 0" class="system-controls-container">
                <app-select class="system-select" inputId="configSystemSelect"
                            label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.CONFIG_ADDONS.LIST' | translate }}"
                            [options]="configSystems | selectItemMultilanguageFieldTranslate"
                            [(ngModel)]="configSystem"></app-select>
                <app-simple-button *ngIf="canViewVenskaPrices"
                                   buttonId="addConfigurableAddonDefDealerDiscount"
                                   label="{{ 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.ADD' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="configSystem == undefined || overrides.configSystemDealerDiscounts.has(configSystem)"
                                   (onClick)="addConfigSystemDealerDiscount(configSystem)"></app-simple-button>
                <app-simple-button buttonId="addConfigurableAddonDefProfitMargin"
                                   label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.ADD_PROFIT_MARGIN' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="configSystem == undefined || hasConfigSystemProfitMargin(configSystem, configurableAddonBulk)"
                                   (onClick)="addConfigSystemProfitMargin(configSystem, configurableAddonBulk)"></app-simple-button>
                <app-checkbox *ngIf="canViewSubsystemPrices"
                              inputId="addConfigurableAddonDefProfitMarginBulk"
                              label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.BULK_CONFIG' | translate }}"
                              [(ngModel)]="configurableAddonBulk"></app-checkbox>
            </div>
            <div *ngIf="supplierNames.length > 0" class="system-controls-container">
                <app-select class="system-select" inputId="supplierSelect"
                            label="{{ 'NAVIGATION.ITEMS.SUPPLIERS.LIST' | translate }}"
                            [options]="supplierNames" [(ngModel)]="supplier"></app-select>
                <app-simple-button buttonId="addSupplierDealerDiscount"
                                   label="{{ 'NAVIGATION.ITEMS.PRICE-LISTS.DEALER_DISCOUNTS.ADD' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="supplier == undefined || overrides.supplierDealerDiscounts.has(supplier)"
                                   (onClick)="addSupplierDealerDiscount(supplier)"></app-simple-button>
            </div>
            <div *ngIf="windowSystems.length > 0 && canViewSubsystemPrices" class="system-controls-container">
                <app-select class="system-select" inputId="addonCategorySelect"
                            label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.WINDOW_ADDON_CATEGORY' | translate }}"
                            [options]="addonCategories | async" [(ngModel)]="addonCategory"></app-select>
                <app-simple-button buttonId="addWindowAddonProfitMargin"
                                   label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.ADD_PROFIT_MARGIN' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="addonCategory == undefined || overrides.windowAddonProfitMargins.has(addonCategory)"
                                   (onClick)="addWindowAddonProfitMargin(addonCategory)"></app-simple-button>
            </div>
            <div *ngIf="windowSystems.length > 0 && canViewSubsystemPrices" class="system-controls-container">
                <app-select class="system-select" inputId="catalogChargeSelect"
                            label="{{ 'PROFIT_MARGIN.CATEGORIES.CATALOG_CHARGE' | translate }}"
                            [options]="catalogItemTypes | async" [(ngModel)]="catalogItemType"></app-select>
                <app-simple-button buttonId="addWindowAddonProfitMargin"
                                   label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.ADD_PROFIT_MARGIN' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="catalogItemType == undefined || overrides.catalogChargeSubsystemProfitMargins.has(catalogItemType)"
                                   (onClick)="setCatalogChargeProfitMargin(catalogItemType, 0)"></app-simple-button>
            </div>
            <div *ngIf="hasBulkAddons" class="system-controls-container">
                <app-input-text class="system-select" inputId="bulkAddonLabelInput"
                                [label]="'SUBSYSTEM-DETAILS.FORM.BULK_ADDON_PROFIT_MARGIN' | translate"
                                [ngModel]="''" [disabled]="true"></app-input-text>
                <app-simple-button buttonId="addBulkAddonProfitMargin"
                                   label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.ADD_PROFIT_MARGIN' | translate }}"
                                   type="filter" [size]="32"
                                   [disabled]="overrides.bulkAddonProfitMargins != undefined"
                                   (onClick)="setBulkAddonProfitMargin()"></app-simple-button>
            </div>
        </div>

        <ng-template #dealerDiscountsRow let-id="id" let-dealerDiscounts="dealerDiscounts" let-label="label"
                     let-namePrefix="namePrefix" let-handleDelete="handleDelete">
            <div class="system-name">{{ label }}</div>
            <app-input-text inputId="{{ namePrefix + 'DealerDiscount_' + id }}"
                            [(validationMessageKey)]="validationErrors[namePrefix + 'DealerDiscount_' + id]"
                            [(ngModel)]="dealerDiscounts.value.value">
                <ng-template pTemplate="label"></ng-template>
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-text>
            <app-checkbox inputId="{{ namePrefix + 'DealerDiscountCascade_' + id }}"
                          [(ngModel)]="dealerDiscounts.value.cascade"></app-checkbox>
            <app-input-text inputId="{{ namePrefix + 'SubsystemGroupSellDealerDiscount_' + id }}"
                            [(validationMessageKey)]="validationErrors[namePrefix + 'SubsystemGroupSellDealerDiscount_' + id]"
                            [(ngModel)]="dealerDiscounts.subsystemGroupSellValue.value">
                <ng-template pTemplate="label"></ng-template>
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-text>
            <app-checkbox inputId="{{ namePrefix + 'SubsystemGroupSellDealerDiscountCascade_' + id }}"
                          [(ngModel)]="dealerDiscounts.subsystemGroupSellValue.cascade"></app-checkbox>
            <app-input-text inputId="{{ namePrefix + 'SubsystemSellDealerDiscount_' + id }}"
                            [(validationMessageKey)]="validationErrors[namePrefix + 'SubsystemSellDealerDiscount_' + id]"
                            [(ngModel)]="dealerDiscounts.subsystemSellValue.value">
                <ng-template pTemplate="label"></ng-template>
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-text>
            <app-checkbox inputId="{{ namePrefix + 'SubsystemSellDealerDiscountCascade_' + id }}"
                          [(ngModel)]="dealerDiscounts.subsystemSellValue.cascade"></app-checkbox>
            <div class="delete-button-wrapper">
                <app-simple-button buttonId="{{ namePrefix + 'DeleteDealerDiscount_' + id }}"
                                   label="{{ 'GENERAL.DELETE' | translate }}" [inlineLabel]="false" icon="delete"
                                   type="delete" [size]="32" (onClick)="handleDelete(id)"></app-simple-button>
            </div>
        </ng-template>

        <div *ngIf="hasDealerDiscountOverrides()" class="new-form-60vw values-table-dealer-discounts">
            <div></div>
            <div class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.SYSTEM_VENSKA_RABATE' | translate }}</div>
            <div class="header-label">{{ 'DEALER_DISCOUNTS.FORM.CASCADE' | translate }}</div>
            <div class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.SYSTEM_SUBSYSTEM_RABATE' | translate }}</div>
            <div class="header-label">{{ 'DEALER_DISCOUNTS.FORM.CASCADE' | translate }}</div>
            <div class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.SYSTEM_SUBSYSTEM_GROUP_RABATE' | translate }}</div>
            <div class="header-label">{{ 'DEALER_DISCOUNTS.FORM.CASCADE' | translate }}</div>
            <div></div> <!-- DELETE button -->
            <ng-container *ngFor="let kv of overrides.windowSystemDealerDiscounts | keyvalue">
                <ng-container *ngTemplateOutlet="dealerDiscountsRow; context: {
                    id: kv.key,
                    dealerDiscounts: kv.value,
                    label: findLabelByValue(windowSystems, kv.key),
                    namePrefix: 'windowSystem',
                    handleDelete: deleteWindowSystemDealerDiscount }"></ng-container>
            </ng-container>
            <ng-container *ngFor="let kv of overrides.gateSystemDealerDiscounts | keyvalue">
                <ng-container *ngTemplateOutlet="dealerDiscountsRow; context: {
                    id: kv.key,
                    dealerDiscounts: kv.value,
                    label: findLabelByValue(gateSystems, kv.key),
                    namePrefix: 'gateSystem',
                    handleDelete: deleteGateSystemDealerDiscount }"></ng-container>
            </ng-container>
            <ng-container *ngFor="let kv of overrides.configSystemDealerDiscounts | keyvalue">
                <ng-container *ngTemplateOutlet="dealerDiscountsRow; context: {
                    id: kv.key,
                    dealerDiscounts: kv.value,
                    label: findLabelByValue(configSystems, kv.key),
                    namePrefix: 'configSystem',
                    handleDelete: deleteConfigSystemDealerDiscount }"></ng-container>
            </ng-container>
            <ng-container *ngFor="let kv of overrides.supplierDealerDiscounts | keyvalue">
                <ng-container *ngTemplateOutlet="dealerDiscountsRow; context: {
                    id: kv.key,
                    dealerDiscounts: kv.value,
                    label: findLabelByValue(supplierNames, kv.key),
                    namePrefix: 'supplier',
                    handleDelete: deleteSupplierDealerDiscount }"></ng-container>
            </ng-container>
        </div>

        <ng-template #profitMarginsRow let-id="id" let-profitMargins="profitMargins"
                     let-label="label" let-namePrefix="namePrefix"
                     let-hideClient="hideClient" let-handleDelete="handleDelete">
            <div class="system-name">{{ label }}</div>
            <app-input-number *ngIf="canViewSubsystemPrices"
                              inputId="{{ namePrefix + 'SubsystemGroupProfitMargin_' + id }}"
                              [(validationMessageKey)]="validationErrors[namePrefix + 'SubsystemGroupProfitMargin_' + id]"
                              [(ngModel)]="profitMargins.subsystemGroupProfitMargin">
                <ng-template pTemplate="label"></ng-template>
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-number>
            <app-input-number *ngIf="canViewSubsystemPrices"
                              inputId="{{ namePrefix + 'SubsystemProfitMargin_' + id }}"
                              [(validationMessageKey)]="validationErrors[namePrefix + 'SubsystemGroupProfitMargin_' + id]"
                              [(ngModel)]="profitMargins.subsystemProfitMargin">
                <ng-template pTemplate="label"></ng-template>
                <ng-template pTemplate="validationMessage"></ng-template>
            </app-input-number>
            <ng-container *ngIf="!hideClient">
                <app-input-number *ngIf="canViewClientPrices"
                                  inputId="{{ namePrefix + 'ClientGroupProfitMargin_' + id }}"
                                  [(validationMessageKey)]="validationErrors[namePrefix + 'ClientGroupProfitMargin_' + id]"
                                  [(ngModel)]="profitMargins.clientGroupProfitMargin">
                    <ng-template pTemplate="label"></ng-template>
                    <ng-template pTemplate="validationMessage"></ng-template>
                </app-input-number>
                <app-input-number *ngIf="canViewClientPrices"
                                  inputId="{{ namePrefix + 'ClientProfitMargin_' + id }}"
                                  [(validationMessageKey)]="validationErrors[namePrefix + 'ClientProfitMargin_' + id]"
                                  [(ngModel)]="profitMargins.clientProfitMargin">
                    <ng-template pTemplate="label"></ng-template>
                    <ng-template pTemplate="validationMessage"></ng-template>
                </app-input-number>
                <app-input-text *ngIf="isSellerOffer" inputId="{{ namePrefix + 'SellerClientGroupProfitMargin_' + id }}"
                                [(validationMessageKey)]="validationErrors[namePrefix + 'SellerClientGroupProfitMargin_' + id]"
                                [(ngModel)]="profitMargins.sellerClientGroupProfitMargin">
                    <ng-template pTemplate="label"></ng-template>
                    <ng-template pTemplate="validationMessage"></ng-template>
                </app-input-text>
                <app-input-number *ngIf="isSellerOffer" inputId="{{ namePrefix + 'SellerClientProfitMargin_' + id }}"
                                  [(validationMessageKey)]="validationErrors[namePrefix + 'SellerClientProfitMargin_' + id]"
                                  [(ngModel)]="profitMargins.sellerClientProfitMargin">
                    <ng-template pTemplate="label"></ng-template>
                    <ng-template pTemplate="validationMessage"></ng-template>
                </app-input-number>
            </ng-container>
            <ng-container *ngIf="hideClient">
                <div *ngIf="canViewClientPrices"></div>
                <div *ngIf="canViewClientPrices"></div>
                <div *ngIf="isSellerOffer"></div>
                <div *ngIf="isSellerOffer"></div>
            </ng-container>
            <div class="delete-button-wrapper">
                <app-simple-button buttonId="{{ namePrefix + 'DeleteProfitMargin_' + id }}"
                                   label="{{ 'GENERAL.DELETE' | translate }}" [inlineLabel]="false" icon="delete"
                                   type="delete" [size]="32" (onClick)="handleDelete(id)"></app-simple-button>
            </div>
        </ng-template>

        <div *ngIf="hasProfitMarginOverrides()"
             [ngClass]="['new-form-60vw', 'values-table-profit-margins-' + visibleProfitMarginColumns]">
            <div></div> <!-- SYSTEM -->
            <div *ngIf="canViewSubsystemPrices" class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.SUBSYSTEM_GROUP_MARGIN' | translate }}</div>
            <div *ngIf="canViewSubsystemPrices" class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.SUBSYSTEM_MARGIN' | translate }}</div>
            <div *ngIf="canViewClientPrices" class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.CLIENT_GROUP_MARGIN' | translate }}</div>
            <div *ngIf="canViewClientPrices" class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.CLIENT_MARGIN' | translate }}</div>
            <div *ngIf="isSellerOffer" class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.RETAIL_CLIENT_GROUP_MARGIN' | translate }}</div>
            <div *ngIf="isSellerOffer" class="header-label">{{ 'OFFER.DETAILED_PRICING.SOURCE.RETAIL_CLIENT_MARGIN' | translate }}</div>
            <div></div> <!-- DELETE button -->
            <ng-container *ngFor="let kv of overrides.windowSystemProfitMargins | keyvalue">
                <ng-container *ngTemplateOutlet="profitMarginsRow; context: {
                    id: kv.key,
                    profitMargins: kv.value,
                    label: findLabelByValue(windowSystems, kv.key),
                    namePrefix: 'windowSystem',
                    handleDelete: deleteWindowSystemProfitMargin }"></ng-container>
            </ng-container>
            <ng-container *ngFor="let kv of overrides.gateSystemProfitMargins | keyvalue">
                <ng-container *ngTemplateOutlet="profitMarginsRow; context: {
                    id: kv.key,
                    profitMargins: kv.value,
                    label: findLabelByValue(gateSystems, kv.key),
                    namePrefix: 'gateSystem',
                    handleDelete: deleteGateSystemProfitMargin }"></ng-container>
            </ng-container>
            <ng-container *ngFor="let kv of overrides.configSystemProfitMargins | keyvalue">
                <ng-container *ngTemplateOutlet="profitMarginsRow; context: {
                    id: kv.key,
                    profitMargins: kv.value,
                    label: getConfigSystemLabel(kv.key, 'OFFER.DETAILED_PRICING_SIMULATION.BULK_CONFIG' | translate),
                    namePrefix: 'configSystem',
                    handleDelete: deleteConfigSystemProfitMargin }"></ng-container>
            </ng-container>
            <ng-container *ngFor="let kv of overrides.windowAddonProfitMargins | keyvalue">
                <ng-container *ngTemplateOutlet="profitMarginsRow; context: {
                    id: kv.key,
                    profitMargins: kv.value,
                    label: findLabelByValue(addonCategories | async, kv.key),
                    namePrefix: 'addonCategory',
                    handleDelete: deleteWindowAddonProfitMargin,
                    hideClient: true }"></ng-container>
            </ng-container>
            <ng-container *ngIf="overrides.bulkAddonProfitMargins != undefined">
                <ng-container *ngTemplateOutlet="profitMarginsRow; context: {
                    id: 'bulkAddon',
                    profitMargins: overrides.bulkAddonProfitMargins,
                    label: 'SUBSYSTEM-DETAILS.FORM.BULK_ADDON_PROFIT_MARGIN' | translate,
                    namePrefix: 'bulkAddon',
                    handleDelete: deleteBulkAddonProfitMargin }"></ng-container>
            </ng-container>
            <ng-container *ngFor="let kv of overrides.catalogChargeSubsystemProfitMargins | keyvalue; trackBy: trackCatalogCharge">
                <div class="system-name">{{ ('CATALOG_CHARGE_PROFIT_MARGIN.TYPES.' + kv.key) | translate }}</div>
                <div></div>
                <app-input-number inputId="{{ 'catalogChargeSubsystemProfitMargin_' + kv.key }}"
                                  [(validationMessageKey)]="validationErrors['catalogChargeSubsystemProfitMargin_' + kv.key]"
                                  [ngModel]="kv.value" (ngModelChange)="setCatalogChargeProfitMargin(kv.key, $event)">
                    <ng-template pTemplate="label"></ng-template>
                    <ng-template pTemplate="validationMessage"></ng-template>
                </app-input-number>
                <div></div>
                <div></div>
                <div *ngIf="isSellerOffer"></div>
                <div *ngIf="isSellerOffer"></div>
                <div class="delete-button-wrapper">
                    <app-simple-button buttonId="{{ 'catalogChargeSubsystemProfitMargin_Delete_' + kv.key }}"
                                       label="{{ 'GENERAL.DELETE' | translate }}" [inlineLabel]="false" icon="delete"
                                       type="delete" [size]="32"
                                       (onClick)="deleteCatalogChargeProfitMargin(kv.key)"></app-simple-button>
                </div>
            </ng-container>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="handleSubmit(dialog, $event)"></app-simple-button>
            <app-simple-button label="{{ 'OFFER.DETAILED_PRICING_SIMULATION.RESET_FORM' | translate }}" type="delete"
                               [size]="40" (onClick)="resetForm()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
