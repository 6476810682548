import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {TranslatedSelectItemService} from '../../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WebshopRecommendedConfiguration, WebshopWindowSystemCategory} from '../../webshop-recommended-configuration';

@Component({
    selector: 'app-webshop-recommended-configuration-general-form',
    templateUrl: './webshop-recommended-configuration-general-form.component.html',
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopRecommendedConfigurationGeneralFormComponent implements OnInit {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    categories: Observable<SelectItem[]>;

    constructor(private readonly translatedSelectItemService: TranslatedSelectItemService) {
    }

    ngOnInit(): void {
        this.categories = this.translatedSelectItemService.buildUnsortedDropdown(WebshopWindowSystemCategory, 'WEBSHOP_WINDOW_SYSTEM_CATEGORY.', undefined);
    }

    setIndefinite(event: boolean) {
        if (event) {
            this.item.validTo = null;
        }
    }
}
