<div [ngClass]="{'new-form-60vw': permissions.isPermitted({roles:['ROLE_KOORDYNATOR']}),
                 'new-form-600': !permissions.isPermitted({roles:['ROLE_KOORDYNATOR']})}">
    <ng-container *ngIf="permissions.isPermitted({roles:['ROLE_KOORDYNATOR']})">
        <ng-container>
            <app-translation-fields *ngIf="fieldUsage.show(AddonField.NAME)" [disabled]="fieldUsage.disabled(AddonField.NAME)" inputId="name" label="{{ 'ADDONS.FORM.NAME' | translate }}"
                                    [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                                    validationKeyPrefix="name"
                                    [required]="true" [field]="addon.name"></app-translation-fields>
        </ng-container>
        <app-translation-fields *ngIf="fieldUsage.show(AddonField.SHORTCUT)" [disabled]="fieldUsage.disabled(AddonField.SHORTCUT)" inputId="shortcut"
                                validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                                [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                                label="{{ 'ADDONS.FORM.SHORTCUT' | translate }}"
                                [field]="addon.shortcut"></app-translation-fields>
    </ng-container>
    <ng-container *ngIf="!permissions.isPermitted({roles:['ROLE_KOORDYNATOR']})">
        <ng-container>
            <div *ngIf="fieldUsage.show(AddonField.NAME)" class="new-form-row">
                <app-input-text inputId="name" label="{{ 'ADDONS.FORM.NAME' | translate }}"
                                [validationMessageKey]="formatValidationMessage(validationErrors['name' + '[' + translate.currentLang + ']'])"
                                (validationMessageKeyChange)="handleValidationMessageChange($event, 'name' + '[' + translate.currentLang + ']')"
                                [disabled]="fieldUsage.disabled(AddonField.NAME)"
                                [maxlength]="100" [(ngModel)]="addon.name[translate.currentLang]"
                                [required]="true"></app-input-text>
            </div>
        </ng-container>
        <div *ngIf="fieldUsage.show(AddonField.SHORTCUT)" class="new-form-row">
            <app-input-text inputId="shortcut" label="{{ 'ADDONS.FORM.SHORTCUT' | translate }}"
                            [disabled]="fieldUsage.disabled(AddonField.SHORTCUT)"
                            [validationMessageKey]="formatValidationMessage(validationErrors['shortcut' + '[' + translate.currentLang + ']'])"
                            (validationMessageKeyChange)="handleValidationMessageChange($event, 'shortcut' + '[' + translate.currentLang + ']')"
                            [maxlength]="30" [(ngModel)]="addon.shortcut[translate.currentLang]"></app-input-text>
        </div>
    </ng-container>
    <div *ngIf="fieldUsage.show(AddonField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" [maxlength]="50"
                        label="{{ 'ADDONS.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(AddonField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']"
                        [(ngModel)]="addon.symbol"></app-input-text>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.CATEGORY)">
        <app-select inputId="category" label="{{ 'ADDONS.FORM.CATEGORY' | translate }}" [required]="true"
                    [disabled]="fieldUsage.disabled(AddonField.CATEGORY)"
                    [(validationMessageKey)]="validationErrors['category']" [options]="categories"
                    [ngModel]="addon.category" (ngModelChange)="onCategoryChange($event)"></app-select>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.PCN)">
        <div class="new-form-field" >
            <label for="pcn">{{ 'ADDONS.FORM.PCN' | translate }}</label>
            <div class="new-form-field-input-container">
                <p-inputMask [ngModel]="addon.pcn" id="pcn" inputId="pcn" ngDefaultControl
                             [disabled]="fieldUsage.disabled(AddonField.PCN)"
                             (ngModelChange)="addon.pcn = $event; validationErrors['pcn'] = undefined"
                             mask="PCN 99 99 99 99" placeholder="PCN 99 99 99 99"></p-inputMask>
                <span *ngIf="validationErrors['pcn'] != undefined" class="new-form-field-error-icon material-icons">error</span>
                <div *ngIf="validationErrors['pcn'] != undefined" class="new-form-field-error-message">
                    {{ validationErrors['pcn'] | translate }}
                </div>
            </div>
        </div>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.SUPPLIER)">
        <app-select inputId="supplier" label="{{ 'ADDONS.FORM.SUPPLIER' | translate }}"
                    [disabled]="fieldUsage.disabled(AddonField.SUPPLIER)"
                    [(validationMessageKey)]="validationErrors['supplier']" [options]="suppliers"
                    [ngModel]="addon.supplier" (ngModelChange)="onSupplierChange($event)"
                    [required]="true"></app-select>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.QUANTITY_TYPE)">
        <app-select inputId="quantityType" label="{{ 'ADDONS.FORM.QUANTITY_TYPE' | translate }}"
                    [disabled]="fieldUsage.disabled(AddonField.QUANTITY_TYPE)"
                    [allowSelectingNone]="addon.addonFor === 'CONFIG_ADDON'"
                    [(validationMessageKey)]="validationErrors['quantityType']" [options]="quantityTypes"
                    [(ngModel)]="addon.quantityType" [required]="false"></app-select>
    </div>

    <ng-container *ngIf="isWindowAddon()">
        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.ADDON_FOR)">
            <app-select inputId="addonFor" label="{{ 'ADDONS.FORM.ADDON_FOR' | translate }}" [required]="true"
                        [disabled]="fieldUsage.disabled(AddonField.ADDON_FOR)"
                        [(validationMessageKey)]="validationErrors['addonFor']" [options]="addonFor"
                        [ngModel]="addon.addonFor" (ngModelChange)="onAddonForChange($event)"></app-select>
        </div>


    </ng-container>
    <ng-container *ngIf="isWindowAddon()">
        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.DEFAULT_QUANTITY)">
            <app-input-number *ngIf="!isAddonForWindow()" inputId="defaultQuantity"
                              label="{{ 'ADDONS.FORM.DEFAULT_QUANTITY' | translate }}"
                              [disabled]="fieldUsage.disabled(AddonField.DEFAULT_QUANTITY)"
                              [(validationMessageKey)]="validationErrors['defaultQuantity']" [min]="1" [max]="1000000"
                              [required]="true"
                              [(ngModel)]="addon.defaultQuantity"></app-input-number>
            <app-input-text #windowAddonDefaultQuantityInput *ngIf="isAddonForWindow()" inputId="defaultQuantity"
                            label="{{ 'ADDONS.FORM.DEFAULT_QUANTITY' | translate }}"
                            [disabled]="fieldUsage.disabled(AddonField.DEFAULT_QUANTITY)"
                            [(validationMessageKey)]="validationErrors['defaultQuantity']" [required]="true"
                            [(ngModel)]="addon.defaultQuantity"
                            appInputTooltip="{{ 'ADDONS.FORM.DEFAULT_QUANTITY_TOOLTIP' | translate }}"
                            [appInputTooltipComponentHost]="windowAddonDefaultQuantityInput"></app-input-text>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.UNIT_WEIGHT)">
            <app-input-number inputId="unitWeight" label="{{ 'ADDONS.FORM.UNIT_WEIGHT' | translate }}"
                              [disabled]="fieldUsage.disabled(AddonField.UNIT_WEIGHT)"
                              [(validationMessageKey)]="validationErrors['unitWeight']" [required]="true"
                              [(ngModel)]="addon.unitWeight" [min]="0" [max]="9999"></app-input-number>
        </div>

        <!-- IS REMOVABLE -->
        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.REMOVABLE)">
            <app-checkbox inputId="removable" label="{{ 'ADDONS.FORM.REMOVABLE' | translate }}"
                          [disabled]="fieldUsage.disabled(AddonField.REMOVABLE)"
                          [(ngModel)]="addon.removable" (ngModelChange)="removableChange()"></app-checkbox>
        </div>

        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.QUANTITY_CHANGE)">
            <app-checkbox inputId="changeQuantity" label="{{ 'ADDONS.FORM.QUANTITY_CHANGE' | translate }}"
                          [disabled]="fieldUsage.disabled(AddonField.QUANTITY_CHANGE)"
                          [(ngModel)]="addon.changeQuantity" ></app-checkbox>
        </div>
    </ng-container>

    <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.AVAILABLE_IN)">
        <app-radio-button inputId="availableIn" label="{{ 'ADDONS.FORM.AVAILABLE_IN_LABEL' | translate }}"
                          [(validationMessageKey)]="validationErrors['availableIn']" [options]="filteredAvailableInOptions"
                          [disabled]="fieldUsage.disabled(AddonField.AVAILABLE_IN)"
                          [required]="true" [(ngModel)]="addon.availableIn"
                          [translateLabels]="true" ></app-radio-button>
    </div>
    <ng-container *ngIf="canHavePrice()">
        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.PRICE_TYPE)">
            <app-radio-button inputId="priceType" label="{{ 'ADDONS.FORM.PRICE' | translate }}"
                              [disabled]="fieldUsage.disabled(AddonField.PRICE_TYPE)"
                              [(validationMessageKey)]="validationErrors['price.type']" [options]="priceTypes"
                              [required]="true" [ngModel]="addon.price.type"
                              [translateLabels]="true"
                              (ngModelChange)="onPriceTypeChange($event)"></app-radio-button>
        </div>
        <div class="new-form-row addon-price-group" *ngIf="fieldUsage.show(AddonField.PRICE)">
            <app-input-number *ngIf="addon.price.type === 'AMOUNT'" inputId="amount"
                              label="{{ (currency ? 'ADDONS.FORM.PRICE_TYPE.AMOUNT_CURRENCY' : 'ADDONS.FORM.PRICE_TYPE.AMOUNT') | translate: {currency: currency} }}"
                              [disabled]="fieldUsage.disabled(AddonField.PRICE)"
                              [(validationMessageKey)]="validationErrors['price.value']" [min]="0"
                              [(ngModel)]="addon.price.value" [required]="true"></app-input-number>
            <app-input-number *ngIf="addon.price.type === 'PERCENT' && addon.addonFor !== 'BULK'" inputId="percent"
                              label="{{ 'ADDONS.FORM.PRICE_TYPE.PERCENT' | translate }}"
                              [disabled]="fieldUsage.disabled(AddonField.PRICE)"
                              [(validationMessageKey)]="validationErrors['price.value']" [min]="0.1"
                              [step]="0.1" [(ngModel)]="addon.price.value" [required]="true"></app-input-number>
            <app-text-area #priceGroupInput *ngIf="addon.price.type === 'PRICE_GROUP'" inputId="priceGroup"
                           label="{{ 'ADDONS.FORM.PRICE_TYPE.PRICE_GROUP' | translate }}"
                           [disabled]="fieldUsage.disabled(AddonField.PRICE)"
                           [(validationMessageKey)]="validationErrors['price.priceGroupDesc']"
                           [(ngModel)]="addon.price.priceGroupDesc" [required]="true"
                           appInputTooltip="{{ getPriceGroupHelpTooltip() | translate }}"
                           [appInputTooltipComponentHost]="priceGroupInput"></app-text-area>
            <app-input-number *ngIf="addon.price.type === 'RIGID_PRICE'" inputId="venskaBuyNetPrice"
                              label="{{ 'ADDONS.FORM.RIGID_PRICE_VENSKA_BUY_NET_PRICE' | translate }}"
                              [disabled]="fieldUsage.disabled(AddonField.PRICE)"
                              [(validationMessageKey)]="validationErrors['price.value']" [min]="0"
                              [(ngModel)]="addon.price.value" [required]="true"></app-input-number>

        </div>
        <div *ngIf="fieldUsage.show(AddonField.VENSKA_SELL_NET_PRICE)" class="new-form-row">
            <app-input-number inputId="venskaSellNetPrice"
                              label="{{ 'ADDONS.FORM.RIGID_PRICE_VENSKA_SELL_NET_PRICE' | translate }}"
                              [disabled]="fieldUsage.disabled(AddonField.PRICE)"
                              [(validationMessageKey)]="validationErrors['price.venskaSellNetPrice']" [min]="0"
                              [(ngModel)]="addon.price.venskaSellNetPrice" [required]="true"></app-input-number>
        </div>
    </ng-container>
    <div class="new-form-row"
         *ngIf="fieldUsage.show(AddonField.COLOR_CHANGE, addon)">
        <app-checkbox inputId="chooseColor" label="{{ 'ADDONS.FORM.COLOR_CHANGE' | translate }}"
                      [disabled]="fieldUsage.disabled(AddonField.COLOR_CHANGE)"
                      [(ngModel)]="addon.chooseColor" (ngModelChange)="clearError('colors')"></app-checkbox>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.SINGLE_POSITION_ADDON)">
        <app-checkbox inputId="singlePositionAddon" label="{{ 'ADDONS.FORM.SINGLE_POSITION_ADDON' | translate }}"
                      [disabled]="fieldUsage.disabled(AddonField.SINGLE_POSITION_ADDON)"
                      [(ngModel)]="addon.singlePositionAddon"></app-checkbox>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.SHOW_GATE_DISCLAIMER)">
        <app-checkbox inputId="showGateDisclaimer" label="{{ 'ADDONS.FORM.SHOW_GATE_DISCLAIMER' | translate }}"
                      [disabled]="fieldUsage.disabled(AddonField.SHOW_GATE_DISCLAIMER)"
                      [(ngModel)]="addon.showGateDisclaimer"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(AddonField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'ADDONS.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(AddonField.ACTIVE)"
                      [(ngModel)]="addon.active"></app-checkbox>
    </div>
    <div *ngIf="fieldUsage.show(AddonField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          [disabled]="fieldUsage.disabled(AddonField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          label="{{ 'ADDONS.FORM.SORT_INDEX' | translate }}"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="addon.sortIndex" [required]="true"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(AddonField.PROFIT_MARGIN_WINDOW_SYSTEM, addon)" class="new-form-row">
        <app-select inputId="profitMarginWindowSystemId"
                    label="{{ 'ADDONS.FORM.PROFIT_MARGIN_WINDOW_SYSTEM' | translate }}"
                    [(validationMessageKey)]="validationErrors['profitMarginWindowSystemId']"
                    [options]="filteredWindowSystems" [(ngModel)]="addon.profitMarginWindowSystemId"
                    [disabled]="fieldUsage.disabled(AddonField.PROFIT_MARGIN_WINDOW_SYSTEM)"></app-select>
    </div>

    <ng-container>
        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.CUSTOM_VALUE)">
            <app-input-number inputId="customNumericValue"
                              [disabled]="fieldUsage.disabled(AddonField.CUSTOM_VALUE)"
                              [allowOnlyIntegers]="true"
                              label="{{ ('ADDONS.FORM.CUSTOM_NUMERIC_VALUE.' + addon.category) | translate }}"
                              [(validationMessageKey)]="validationErrors['customNumericValue']" [min]="0" [max]="999" [step]="1"
                              [(ngModel)]="addon.customNumericValue"></app-input-number>
        </div>
        <div class="new-form-row" *ngIf="fieldUsage.show(AddonField.CUSTOM_STRING_VALUE)">
            <app-input-text inputId="customStringValue"
                            [disabled]="fieldUsage.disabled(AddonField.CUSTOM_STRING_VALUE)"
                            label="{{ ('ADDONS.FORM.CUSTOM_STRING_VALUE.' + addon.category) | translate }}"
                              [(validationMessageKey)]="validationErrors['customStringValue']" [maxlength]="100"
                              [(ngModel)]="addon.customStringValue"></app-input-text>
        </div>
    </ng-container>

    <p-accordion>
        <!--Colors-->
        <app-colors-selection *ngIf="fieldUsage.show(AddonField.COLORS_SELECTION)"
                              [coreColorsHeader]="'ADDONS.FORM.CORE_COLORS'"
                              [coreColorsDisabled]="fieldUsage.disabled(AddonField.COLORS_SELECTION)"
                              [coreColorsModel]="addon.coreColors"
                              (onCoreColorChange)="addon.coreColors = $event;clearError('colors')"
                              [coreColorsOptions]="coreColors"
                              [coreColorValidationMessage]="validationErrors['colors']"
                              [insideColorsHeader]="'ADDONS.FORM.INSIDE_COLORS'"
                              [insideColorsDisabled]="fieldUsage.disabled(AddonField.COLORS_SELECTION)"
                              [insideColorsModel]="addon.insideColors"
                              (onInsideColorChange)="addon.insideColors = $event"
                              [insideColorsOptions]="insideColors"
                              [outsideColorsHeader]="'ADDONS.FORM.OUTSIDE_COLORS'"
                              [outsideColorsDisabled]="fieldUsage.disabled(AddonField.COLORS_SELECTION)"
                              [outsideColorsModel]="addon.outsideColors"
                              (onOutsideColorChange)="addon.outsideColors = $event"
                              [outsideColorsOptions]="outsideColors"></app-colors-selection>
        <!--Image preview-->
        <p-accordionTab header="{{ 'ADDONS.FORM.PREVIEW_IMAGE' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['image']}" *ngIf="fieldUsage.show(AddonField.PREVIEW_IMAGE)">
            <div class="new-form-row">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(AddonField.PREVIEW_IMAGE)"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="150000" [maxWidth]="800" [maxHeight]="800"
                                 [addButtonLabel]="'ADDONS.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'ADDONS.FORM.SELECT_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'ADDONS.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </div>
        </p-accordionTab>
        <p-accordionTab header="{{ 'MOTLAWA_INTEGRATION_FORM.ADDON_ACCORDION_LABEL' | translate }}" [selected]="false"
                        *ngIf="fieldUsage.show(AddonField.MOTLAWA_INTEGRATION)"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['motlawaInfo.symbol'] || validationErrors['motlawaInfo.description']}">
            <app-motlawa-integration-input [value]="motlawaInfo"
                                           [disabled]="fieldUsage.disabled(AddonField.MOTLAWA_INTEGRATION)"
                                           [validationErrorsPrefix]="'motlawaInfo'"
                                           [validationErrors]="validationErrors"></app-motlawa-integration-input>
        </p-accordionTab>

        <p-accordionTab *ngIf="fieldUsage.show(AddonField.PRINT_DATA)" header="{{ 'ADDONS.FORM.PRINT_DATA' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['additionalIcon'] || validationErrors['additionalComment']}">
            <div class="new-form-row" *ngFor="let lang of supportedLanguages">
                <app-text-area inputId="{{'additionalComment_' + lang.code}}"
                               [disabled]="fieldUsage.disabled(AddonField.PRINT_DATA)"
                               label="{{ 'ADDONS.FORM.ADDITIONAL_COMMENT' | translate: {lang: lang.code} }}"
                               [(validationMessageKey)]="validationErrors['additionalComment']" [maxlength]="1000"
                               [required]="false" [(ngModel)]="addon.additionalComment && addon.additionalComment[lang.code]"></app-text-area>
            </div>

            <app-file-upload inputId="additionalIcon" [ngModel]="additionalIcon"
                             [disabled]="fieldUsage.disabled(AddonField.PRINT_DATA)"
                             (ngModelChange)="additionalIconChange.emit($event)"
                             [maxSize]="10000" [maxWidth]="100" [maxHeight]="100"
                             [addButtonLabel]="'CATALOG_OPTION.FORM.SELECT_FILE_UPLOAD'"
                             [changeButtonLabel]="'CATALOG_OPTION.FORM.SELECT_FILE_UPLOAD'"
                             [deleteButtonLabel]="'CATALOG_OPTION.FORM.DELETE_FILE_UPLOAD'"
                             [(validationMessageKey)]="validationErrors['additionalIcon']"></app-file-upload>
        </p-accordionTab>
        <p-accordionTab header="{{ 'ADDONS.FORM.AUTO_OPTION' | translate }}" [selected]="false"
                        *ngIf="fieldUsage.show(AddonField.AUTO_OPTION)">
            <div class="new-form-row">
                <app-radio-button inputId="autoOption"
                                  label="{{ 'ADDONS.FORM.AUTO_OPTION' | translate }}"
                                  [options]="autoOptions | async" [required]="true"
                                  [(ngModel)]="addon.autoOption"></app-radio-button>
            </div>
        </p-accordionTab>


        <p-accordionTab header="{{ 'ADDONS.FORM.TILE_DATA' | translate }}" [selected]="false"
                        *ngIf="fieldUsage.show(AddonField.TILE_DATA)">
            <div class="new-form-row">
                <app-file-upload inputId="tileData" [ngModel]="tile"
                                 (ngModelChange)="onTileChange($event)"
                                 [widthToHeightRatio]="1"
                                 [maxSize]="100000" [maxWidth]="200" [maxHeight]="200"
                                 [addButtonLabel]="'ADDONS.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'ADDONS.FORM.SELECT_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'ADDONS.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['tile']"></app-file-upload>
            </div>

            <div class="new-form-row" style="position: relative">
                <app-input-text inputId="hex" label="{{ 'MATERIAL_COLOR_LINKS.HEX' | translate }}"
                                [(validationMessageKey)]="validationErrors['hex']" [maxlength]="7" [required]="false"
                                [(ngModel)]="addon.hex"></app-input-text>
                <span class="hex-preview" [ngStyle]="{'backgroundColor': addon.hex}"></span>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
