import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ValidationErrors} from '../../../common/validation-errors';
import {TabularAddress} from '../tabularAddress';

@Component({
    selector: 'app-subsystem-address-list',
    templateUrl: './subsystem-address-list.component.html',
    styleUrls: ['./subsystem-address-list.component.css']
})
export class SubsystemAddressListComponent {

    @Input()
    addresses: TabularAddress[];

    @Input()
    usedAddresses: TabularAddress[];

    @Output()
    readonly editAddress = new EventEmitter<TabularAddress>();

    @Output()
    readonly deleteAddress = new EventEmitter<TabularAddress>();

    @Input()
    selectedAddress: TabularAddress;

    @Output()
    readonly selectedAddressChange = new EventEmitter<TabularAddress>();

    @Input()
    canEdit = false;

    @Input()
    selectable = false;

    @Input()
    validationErrors: ValidationErrors;

    handleEditClicked(address: TabularAddress): void {
        this.editAddress.emit(address);
    }

    handleDeleteClicked(address: TabularAddress): void {
        this.deleteAddress.emit(address);
    }

    handleSelectClicked(address: TabularAddress): void {
        this.selectedAddressChange.emit(address);
    }

    hasValidationErrors(index: number): boolean {
        return this.validationErrors != undefined && Object.keys(this.validationErrors).some(key => key.startsWith(`addresses[${index}]`));
    }
}
