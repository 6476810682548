export enum Country {
    NORWAY = 'NORWAY',
    SWEDEN = 'SWEDEN',
    POLAND = 'POLAND',
    FINLAND = 'FINLAND',
    DENMARK = 'DENMARK',
    ICELAND = 'ICELAND',
    SCOTLAND = 'SCOTLAND',
    GERMANY = 'GERMANY',
    HUNGARY = 'HUNGARY',
    CANADA = 'CANADA',
    IRELAND = 'IRELAND',
    NORTHERN_IRELAND = 'NORTHERN_IRELAND',
    GREAT_BRITAIN = 'GREAT_BRITAIN'
}
