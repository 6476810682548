import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {SupplierInterface} from '../../../../../window-designer/catalog-data/supplier-interface';
import {DataServiceHelper} from "../../../../common/dataServiceHelper";
import {SelectItemImpl} from '../../../../common/service/select.item.impl';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {SupplierService} from "../../../supplier/supplier.service";
import {DealerDiscountComponent, DealerDiscountType} from "../dealer-discount.component";
import {DealerDiscountPerSupplier} from "./dealer-discount-per-supplier";
import {DealerDiscountPerSupplierService} from "./dealer-discount-per-supplier.service";

@Component({
    selector: 'app-dealer-discount-per-supplier',
    templateUrl: '../dealer-discount.component.html',
    styleUrls: ['../../../shared-styles.css'],
    providers: [DealerDiscountPerSupplierService, DataServiceHelper, SupplierService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DealerDiscountPerSupplierComponent extends DealerDiscountComponent<DealerDiscountPerSupplier, DealerDiscountPerSupplierService>
    implements OnInit {

    private suppliers: SupplierInterface[] = [];
    availableSuppliers: SelectItem[] = [];

    item: DealerDiscountPerSupplier = new DealerDiscountPerSupplier();

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                public supplierService: SupplierService) {
        super(injector, changeDetector, DealerDiscountPerSupplierService, 'DealerDiscountPerSupplier', DealerDiscountType.PER_SUPPLIER);
    }

    ngOnInit() {
        super.ngOnInit();
        this.loadActiveSuppliers();
    }

    getFirstInputId() {
        return "supplierId";
    }

    getNewItem(): DealerDiscountPerSupplier {
        return new DealerDiscountPerSupplier();
    }

    onLanguageChange(): void {
        super.onLanguageChange();
        this.rebuildSuppliers();
    }

    prepareItemForRequest(): DealerDiscountPerSupplier {
        let requestItem = this.getNewItem();
        requestItem.id = this.item.id;
        requestItem.supplierId = this.item.supplierId;
        requestItem.discountValue = this.item.discountValue || '0';
        requestItem.active = this.item.active;
        requestItem.cascade = this.item.cascade;
        requestItem.validFrom = this.item.validFrom;
        return requestItem;
    }

    private loadActiveSuppliers() {
        return this.supplierService.getSupplierNames().subscribe({
            next: data => {
                this.suppliers = data;
                this.rebuildSuppliers();
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                console.info('DealerDiscountComponent `getWindowSystems` completed!');
            }
        });
    }

    private rebuildSuppliers() {
        this.availableSuppliers = this.suppliers.map(supplier => new SelectItemImpl(supplier.companyName, supplier.id));
        if (this.item.supplierId == undefined && this.availableSuppliers.length > 0) {
            this.item.supplierId = this.availableSuppliers[0].value;
        }
    }

    getTargetOptions(): SelectItem[] {
        return this.availableSuppliers;
    }
}
