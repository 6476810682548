import {DoorSide, OpeningOption} from '../../../../../window-designer/catalog-data/window-system-interface';
import {WindowAddon} from '../../../../../window-designer/drawing-data/WindowAddon';
import {WindowAddonData} from '../../../../../window-designer/drawing-data/WindowAddonData';
import {RandomIdGenerator} from '../../../../../window-designer/utils/RandomIdGenerator';

export class EntranceDoorData implements WindowAddonData {
    generatedId: string = RandomIdGenerator.generate();
    systemId: number;
    glazingPackageId: number;
    entranceModelId: number;
    dimensionsId: number;
    width: number;
    height: number;
    externalColorId: number;
    internalColorId: number;
    opening: OpeningOption;
    frameProfileId: number;
    entranceLock: WindowAddon;
    side: DoorSide;
    addons: WindowAddon[] = [];

    constructor() {
        this.generatedId = RandomIdGenerator.generate();
        this.entranceLock = new WindowAddon();
        this.addons = [];
    }
}
