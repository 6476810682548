<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.SUBSYSTEM_GROUP.LIST' | translate }}
        </div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})" buttonId="addSubsystem"
                                   label="{{ 'NAVIGATION.ITEMS.SUBSYSTEM_GROUP.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [seqNum]="1" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate" id="subsystemsTable">
		<!-- ACTIONS -->
		<p-column header="{{ 'SUBSYSTEM_GROUP.FORM.ACTIONS' | translate }}" [sortable]="false" [style]="{'width':'152px'}">
			<ng-template let-subsystemGroup="rowData" pTemplate="body">
				<div class="table-row-action-buttons">
					<app-simple-button buttonId="show" icon="dynamic_feed" type="main-action" [size]="36"
									   (onClick)="goToSubsystems(subsystemGroup.id)"
									   pTooltip="{{ 'SUBSYSTEM_GROUP.FORM.SHOW_SUBSYSTEMS' | translate }}"></app-simple-button>
				</div>
			</ng-template>
		</p-column>
        <p-column field="name" header="{{ 'SUBSYSTEM_GROUP.FORM.NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate: {from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem && displayDialog" header="{{ 'SUBSYSTEM_GROUP.ADD-TITLE' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.DETAILS_STEP' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-subsystem-group-form [subsystemGroup]="item" [validationErrors]="validationErrors"
                                  [canEdit]="canEdit"></app-subsystem-group-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.PROFIT_MARGINS' | translate }}" [id]="STEPS.SYSTEM_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.PROFIT_MARGINS_ROOF' | translate }}" [id]="STEPS.ROOF_SYSTEM_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.PROFIT_MARGINS_ENTRANCE' | translate }}" [id]="STEPS.ENTRANCE_SYSTEM_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.ADDON_PROFIT_MARGINS' | translate }}" [id]="STEPS.ADDON_MARGINS">
        <app-subsystem-group-addon-profit-margin #childTable entityType="subsystemGroup" [subsystemGroupId]="item.id"
                                                 class="app-subsystem-addon-profit-margin-bulk-addons"
                                                 [userCanEdit]="canEdit"
                                                 [globalMarginUpdateButtonId]="addonGlobalMarginButtonId"
                                                 [globalMarginUpdateInputId]="addonGlobalMarginInputId"></app-subsystem-group-addon-profit-margin>
    </app-wizard-step>

    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id"
                           entityType="subsystemGroup" [userCanEdit]="canEdit"
                           class="app-subsystem-addon-profit-margin-config-addons"
                           [globalMarginUpdateButtonId]="confAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="confAddGlobalMarginInputId"
                           [target]="possibleTargets.CONFIG"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.BULK_CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.BULK_CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id"
                           entityType="subsystemGroup" [userCanEdit]="canEdit"
                           class="app-subsystem-addon-profit-margin-config-addons"
                           [globalMarginUpdateButtonId]="bulkConfAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="bulkConfAddGlobalMarginInputId"
                           [target]="possibleTargets.CONFIG_BULK"></app-profit-margin>
    </app-wizard-step>

    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.CATALOG_CHARGE_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CHARGE_MARGINS">
        <app-catalog-charge-profit-margin #childTable entityType="subsystemGroup" [subsystemGroupId]="item.id"
                                          [globalMarginUpdateButtonId]="catalogChargeGlobalMarginButtonId"
                                          [globalMarginUpdateInputId]="catalogChargeGlobalMarginInputId"
                                          [canEdit]="canEdit"></app-catalog-charge-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.GATES_PROFIT_MARGINS' | translate }}" [id]="STEPS.GATES_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="gatesGlobalMarginInputId" [target]="possibleTargets.GATE"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.WINDOW_SYSTEM_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.SYSTEM_SELL_DEALER_DISCOUNTS">
        <app-subsystem-group-window-system-sell-dealer-discount #childTable [id]="item.id" [canEdit]="canEdit"
                                                                [globalMarginUpdateInputId]="systemGlobalDiscountInputId"
                                                                [globalMarginUpdateButtonId]="systemGlobalDiscountButtonId"
                                                                [validationErrors]="validationErrors"></app-subsystem-group-window-system-sell-dealer-discount>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.CONFIG_ADDON_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_SELL_DEALER_DISCOUNTS">
        <app-subsystem-group-configurable-addon-sell-dealer-discount #childTable [id]="item.id" [canEdit]="canEdit"
                                                                     [globalMarginUpdateInputId]="configAddonGlobalDiscountInputId"
                                                                     [globalMarginUpdateButtonId]="configAddonGlobalDiscountButtonId"
                                                                     [validationErrors]="validationErrors"></app-subsystem-group-configurable-addon-sell-dealer-discount>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.SUPPLIER_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.SUPPLIER_SELL_DEALER_DISCOUNTS">
        <app-subsystem-group-supplier-sell-dealer-discount #childTable [id]="item.id" [canEdit]="canEdit"
                                                           [globalMarginUpdateInputId]="supplierGlobalDiscountInputId"
                                                           [globalMarginUpdateButtonId]="supplierGlobalDiscountButtonId"
                                                           [validationErrors]="validationErrors"></app-subsystem-group-supplier-sell-dealer-discount>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog" #wizard header="{{ getDialogHeader() | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="width-60vw-no-padding"
                   contentStyleClass="width-100percent-no-padding height-60vw-no-padding">
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.DETAILS_STEP' | translate }}" [id]="STEPS.DATA"
                     [validate]="validateDataStep">
        <app-subsystem-group-form [subsystemGroup]="item" [validationErrors]="validationErrors"
                                  [canEdit]="canEdit"></app-subsystem-group-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.PROFIT_MARGINS' | translate }}" [id]="STEPS.SYSTEM_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.PROFIT_MARGINS_ROOF' | translate }}" [id]="STEPS.ROOF_SYSTEM_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.PROFIT_MARGINS_ENTRANCE' | translate }}"
                     [id]="STEPS.ENTRANCE_SYSTEM_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.ADDON_PROFIT_MARGINS' | translate }}" [id]="STEPS.ADDON_MARGINS">
        <app-subsystem-group-addon-profit-margin #childTable entityType="subsystemGroup" [subsystemGroupId]="item.id"
                                                 class="app-subsystem-addon-profit-margin-bulk-addons"
                                                 [userCanEdit]="canEdit"
                                                 [globalMarginUpdateButtonId]="addonGlobalMarginButtonId"
                                                 [globalMarginUpdateInputId]="addonGlobalMarginInputId"></app-subsystem-group-addon-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id"
                           entityType="subsystemGroup" [userCanEdit]="canEdit"
                           class="app-subsystem-addon-profit-margin-config-addons"
                           [globalMarginUpdateButtonId]="confAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="confAddGlobalMarginInputId"
                           [target]="possibleTargets.CONFIG"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.BULK_CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.BULK_CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id"
                           entityType="subsystemGroup" [userCanEdit]="canEdit"
                           class="app-subsystem-addon-profit-margin-config-addons"
                           [globalMarginUpdateButtonId]="bulkConfAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="bulkConfAddGlobalMarginInputId"
                           [target]="possibleTargets.CONFIG_BULK"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.CATALOG_CHARGE_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CHARGE_MARGINS">
        <app-catalog-charge-profit-margin #childTable entityType="subsystemGroup" [subsystemGroupId]="item.id"
                                          [globalMarginUpdateButtonId]="catalogChargeGlobalMarginButtonId"
                                          [globalMarginUpdateInputId]="catalogChargeGlobalMarginInputId"
                                          [canEdit]="canEdit"></app-catalog-charge-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.GATES_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="item.id" entityType="subsystemGroup" [userCanEdit]="canEdit"
                           [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="gatesGlobalMarginInputId" [target]="possibleTargets.GATE"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.WINDOW_SYSTEM_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.SYSTEM_SELL_DEALER_DISCOUNTS">
        <app-subsystem-group-window-system-sell-dealer-discount #childTable [id]="item.id" [canEdit]="canEdit"
                                                                [globalMarginUpdateInputId]="systemGlobalDiscountInputId"
                                                                [globalMarginUpdateButtonId]="systemGlobalDiscountButtonId"
                                                                [validationErrors]="validationErrors"></app-subsystem-group-window-system-sell-dealer-discount>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.CONFIG_ADDON_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_SELL_DEALER_DISCOUNTS">
        <app-subsystem-group-configurable-addon-sell-dealer-discount #childTable [id]="item.id" [canEdit]="canEdit"
                                                                     [globalMarginUpdateInputId]="configAddonGlobalDiscountInputId"
                                                                     [globalMarginUpdateButtonId]="configAddonGlobalDiscountButtonId"
                                                                     [validationErrors]="validationErrors"></app-subsystem-group-configurable-addon-sell-dealer-discount>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM_GROUP.FORM.SUPPLIER_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.SUPPLIER_SELL_DEALER_DISCOUNTS">
        <app-subsystem-group-supplier-sell-dealer-discount #childTable [id]="item.id" [canEdit]="canEdit"
                                                           [globalMarginUpdateInputId]="supplierGlobalDiscountInputId"
                                                           [globalMarginUpdateButtonId]="supplierGlobalDiscountButtonId"
                                                           [validationErrors]="validationErrors"></app-subsystem-group-supplier-sell-dealer-discount>
    </app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="saveButton" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action"
                               [size]="40" [disabled]="!canEdit" (onClick)="wizard.submit()"></app-simple-button>
            <app-simple-button *ngIf="isPermitted({roles:['ROLE_DELETE_SUBSYSTEM']})"
                               buttonId="deleteButton" label="{{ 'GENERAL.DELETE' | translate}}" [inlineLabel]="false"
                               icon="delete" type="delete" [size]="40"
                               (onClick)="showDeleteConfirmation()"></app-simple-button>
            <app-simple-button buttonId="cancelButton" label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="wizard.close()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
<app-confirm-action-dialog *ngIf="deleteDialogOpened" header="{{ 'GENERAL.DIALOG.DELETING' | translate }}"
                           question="{{ 'GENERAL.DELETE_CONFIRMATION' | translate }}" (onConfirmed)="processDelete()"
                           (onCancel)="cancelDelete()"></app-confirm-action-dialog>
