import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataServiceHelper} from '../dataServiceHelper';
import {DateRangeFilter, DateRangeKind} from '../date-range-filter';

@Injectable()
export class RedrawWindowsService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    isMaintenanceActive(): Observable<boolean> {
        return this.http.get<boolean>('applicationStatus/maintenanceStatus');
    }

    execute(withSvg: boolean, offerCreatedFromDate?: Date, offerCreatedToDate?: Date): Observable<void> {
        let filters: any = {
            withSvg: {value: '' + withSvg},
        };
        if (offerCreatedFromDate != null || offerCreatedToDate != null) {
            filters.offersCreateDateRange = {
                matchMode: "dateRange",
                value: DateRangeFilter.getForDates(offerCreatedFromDate, offerCreatedToDate, DateRangeKind.INPUT_RANGE)};
        }
        let params = this.dataServiceHelper.prepareSearchParams(null, null, filters, null, null, null);
        return this.http.get<void>('redrawWindows', {params: params});
    }
}
