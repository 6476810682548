<div class="list-content" *ngIf="!newItem">
    <div class="table-header">
        <div class="title">{{'COLOR.TITLE' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.COLORS.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.COLORS.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="colorsTable">
        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(ColorField.NAME)"
                          header="{{'COLOR.FORM.NAME'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="name"></p-column>
                <p-column [rowspan]="2" [sortable]="true" field="sortIndex" header="{{'COLOR.FORM.SORT_INDEX' | translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(ColorField.SHORTCUT)"
                          [filter]="showFilters" [rowspan]="2" [sortable]="true" header="{{'COLOR.FORM.SHORTCUT'|translate}}"
                          filterMatchMode="contains" field="shortcut"></p-column>
                <p-column *ngIf="fieldUsage.show(ColorField.SYMBOL)"
                          header="{{'COLOR.FORM.SYMBOL'|translate}}" [rowspan]="2" field="symbol" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains"></p-column>
                <p-column *ngIf="fieldUsage.show(ColorField.TYPE)"
                          header="{{'COLOR.FORM.TYPE'|translate}}" [rowspan]="2" field="type" [sortable]="false"
                          [filter]="showFilters"
                          filterMatchMode="contains"
                          [filterValues]="filterTypes | async"></p-column>
                <p-column *ngIf="fieldUsage.show(ColorField.GROUP)"
                          header="{{'COLOR.FORM.GROUP'|translate}}" [rowspan]="2" field="group" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.COLOR_USAGE)"
                          header="{{'COLOR.FORM.USAGE'|translate}}" [colspan]="4"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)"
                          header="{{'COLOR.FORM.SYSTEMS'|translate}}" [colspan]="5"></p-column>
                <p-column *ngIf="fieldUsage.show(ColorField.ACTIVE)"
                          header="{{'COLOR.FORM.STATUS'|translate}}" [rowspan]="2" field="active" [sortable]="false"
                          [filter]="showFilters"
                          [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.COLOR_USAGE)" header="{{'COLOR.FORM.CORE'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.COLOR_USAGE)" header="{{'COLOR.FORM.INSIDE'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.COLOR_USAGE)" header="{{'COLOR.FORM.OUTSIDE'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.COLOR_USAGE)" header="{{'COLOR.FORM.GRILL'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.PCV'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.WOOD'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM_WOOD_MIX'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'GATES'|translate}}"></p-column>
            </p-row>
        </p-headerColumnGroup>
        <p-column *ngIf="fieldUsage.show(ColorField.NAME)"
                  field="name" header="{{ 'COLOR.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template let-color="rowData" pTemplate="body">
                {{color.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column [sortable]="true" field="sortIndex" header="{{ 'COLOR.FORM.SORT_INDEX' | translate }}"></p-column>
        <p-column *ngIf="fieldUsage.show(ColorField.SHORTCUT)"
                  field="name" header="{{ 'COLOR.FORM.SHORTCUT' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template let-color="rowData" pTemplate="body">
                {{color.shortcut[userLang]}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(ColorField.SYMBOL)"
                  field="symbol" header="{{ 'COLOR.FORM.SYMBOL' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>

        <p-column *ngIf="fieldUsage.show(ColorField.TYPE)" field="type" header="{{ 'COLOR.FORM.TYPE' | translate }}"
                  [filter]="showFilters" [sortable]="false" filterMatchMode="contains"
                  [filterValues]="filterTypes | async">
            <ng-template let-color="rowData" pTemplate="body">
                {{'COLOR_TYPES.' + color.type | translate}}
            </ng-template>
        </p-column>


        <p-column *ngIf="fieldUsage.show(ColorField.GROUP)" field="group" header="{{ 'COLOR.FORM.GROUP' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains"></p-column>
        <ng-container *ngIf="fieldUsage.show(CatalogTab.COLOR_USAGE)">
           <p-column *ngIf="fieldUsage.show(ColorField.CORE)" field="core"
                      header="{{ 'COLOR.FORM.CORE' | translate }}">
                <ng-template let-color="rowData" pTemplate="body">
                    <app-checkbox *ngIf="color.type !== addonMaterialColorType"
                                  [ngModel]="color.core" [disabled]="true"></app-checkbox>
                </ng-template>
            </p-column>
            <p-column *ngIf="fieldUsage.show(ColorField.INSIDE)" field="inside" header="{{ 'COLOR.FORM.INSIDE' | translate }}">
                <ng-template let-color="rowData" pTemplate="body">
                    <app-checkbox *ngIf="color.type !== addonMaterialColorType"
                                  [ngModel]="color.inside" [disabled]="true"></app-checkbox>
                </ng-template>
            </p-column>
            <p-column *ngIf="fieldUsage.show(ColorField.OUTSIDE)" field="outside" header="{{ 'COLOR.FORM.OUTSIDE' | translate }}">
                <ng-template let-color="rowData" pTemplate="body">
                    <app-checkbox *ngIf="color.type !== addonMaterialColorType"
                                  [ngModel]="color.outside" [disabled]="true"></app-checkbox>
                </ng-template>
            </p-column>
            <p-column *ngIf="fieldUsage.show(ColorField.GRILL)" field="grill" header="{{ 'COLOR.FORM.GRILL' | translate }}">
                <ng-template let-color="rowData" pTemplate="body">
                    <app-checkbox *ngIf="color.type !== addonMaterialColorType"
                                  [ngModel]="color.grill" [disabled]="true"></app-checkbox>
                </ng-template>
            </p-column>
        </ng-container>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="pcv" header="{{ 'PCV' | translate }}">
            <ng-template let-color="rowData" pTemplate="body">
                <app-tristate-checkbox *ngIf="color.type !== addonMaterialColorType"
                                       [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(color.pcv)"
                                       (ngModelChange)="materialChange(color, 'PCV')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminium" header="{{ 'ALUMINIUM' | translate }}">
            <ng-template let-color="rowData" pTemplate="body">
                <app-tristate-checkbox *ngIf="color.type !== addonMaterialColorType"
                                       [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(color.aluminium)"
                                       (ngModelChange)="materialChange(color, 'ALUMINIUM')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="wood" header="{{ 'WOOD' | translate }}">
            <ng-template let-color="rowData" pTemplate="body">
                <app-tristate-checkbox *ngIf="color.type !== addonMaterialColorType"
                                       [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(color.wood)"
                                       (ngModelChange)="materialChange(color, 'WOOD')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminiumWoodMix"
                  header="{{ 'ALUMINIUM_WOOD_MIX' | translate }}">
            <ng-template let-color="rowData" pTemplate="body">
                <app-tristate-checkbox *ngIf="color.type !== addonMaterialColorType"
                                       [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(color.aluminiumWoodMix)"
                                       (ngModelChange)="materialChange(color, 'ALUMINIUM_WOOD_MIX')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="gates" header="{{ 'GATES' | translate }}">
            <ng-template let-color="rowData" pTemplate="body">
                <app-tristate-checkbox *ngIf="color.type !== addonMaterialColorType"
                                       [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(color.gates)"
                                       (ngModelChange)="materialChange(color, 'GATES')"></app-tristate-checkbox>
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(ColorField.ACTIVE)"
                field="active" header="{{ 'COLOR.FORM.STATUS' | translate }}" [sortable]="false" [filter]="showFilters"
                [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-color="rowData" pTemplate="body">
                {{(color.active ? 'COLOR.FORM.ACTIVE' : 'COLOR.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="newItem && displayDialog" header="{{ 'COLOR.FORM.ADD-TITLE' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()" >
    <app-wizard-step label="{{ 'COLOR.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-color-form [item]="item" [colorTarget]="colorTarget"
                        [validationErrors]="validationErrors"
                        (colorTypeChange)="handleTypeChange($event)"
                        [file]="file"
                        (fileChange)="onFileChange($event)"
                        [availableColors]="availableColors"
                        [webshopTexture]="webshopTexture"
                        [editPermits]="editPermits"
                        (webshopTextureChange)="onWebshopTextureChange($event)"
                        (resetWebshopTexture)="resetWebshopTexture()"></app-color-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isGeneralColor()"
                     label="{{ 'COLOR.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems" [(ngModel)]="selectedWindowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isGeneralColor()"
                     label="{{ 'COLOR.STEPS.ENTRANCE_SYSTEMS' | translate }}" [id]="STEPS.ENTRANCE_SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems" [(ngModel)]="selectedWindowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="entranceSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isGeneralColor()"
                     label="{{ 'COLOR.STEPS.GATE_SYSTEMS' | translate }}" [id]="STEPS.GATE_SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="gateSystems" [linkableEntity]="LinkableEntity.GATE_SYSTEMS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.GATE-SYSTEMS"
                                [(ngModel)]="selectedGateSystems"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isConfigColor()"
                     label="{{ 'COLOR.STEPS.CONFIG_SYSTEMS' | translate }}" [id]="STEPS.CONFIG_SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="configSystems" [linkableEntity]="LinkableEntity.CONFIG_ADDONS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.CONFIG-SYSTEMS"
                                [(ngModel)]="selectedConfigSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog && isPermitted({roles:['ROLE_KOORDYNATOR']})"
                   header="{{ (copyMode ? 'COLOR.FORM.COPY-TITLE' : 'COLOR.FORM.EDIT-TITLE') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'COLOR.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-color-form [item]="item" [colorTarget]="colorTarget"
                        [validationErrors]="validationErrors"
                        [file]="file"
                        (fileChange)="onFileChange($event)"
                        [availableColors]="availableColors"
                        [webshopTexture]="webshopTexture"
                        (webshopTextureChange)="onWebshopTextureChange($event)"
                        (resetWebshopTexture)="resetWebshopTexture()"></app-color-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isGeneralColor()"
                     label="{{ 'COLOR.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems" [(ngModel)]="selectedWindowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isGeneralColor()"
                     label="{{ 'COLOR.STEPS.ENTRANCE_SYSTEMS' | translate }}" [id]="STEPS.ENTRANCE_SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems" [(ngModel)]="selectedWindowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="entranceSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isGeneralColor()"
                     label="{{ 'COLOR.STEPS.GATE_SYSTEMS' | translate }}" [id]="STEPS.GATE_SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="gateSystems" [linkableEntity]="LinkableEntity.GATE_SYSTEMS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.GATE-SYSTEMS"
                                [(ngModel)]="selectedGateSystems"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS) && isConfigColor()"
                     label="{{ 'COLOR.STEPS.CONFIG_SYSTEMS' | translate }}" [id]="STEPS.CONFIG_SYSTEMS">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="configSystems" [linkableEntity]="LinkableEntity.CONFIG_ADDONS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.CONFIG-SYSTEMS"
                                [(ngModel)]="selectedConfigSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
