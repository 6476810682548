import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Hotkey} from "angular2-hotkeys";
import {AccordionTab} from 'primeng/accordion';
import {SelectItem} from 'primeng/api/selectitem';
import {TabPanel, TabView} from 'primeng/tabview';
import {EMPTY, forkJoin, iif, Observable, of} from "rxjs";
import {catchError, finalize, map, mergeMap, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {DropDownExtraOptions} from '../../../../shared/drop-down-extra-options';
import {AbstractWindowDesigner} from "../../../../window-designer/abstract-window-designer";
import {AllAddons} from '../../../../window-designer/all-addons';
import {AddonInterface} from '../../../../window-designer/catalog-data/addon-interface';
import {Glazing} from "../../../../window-designer/catalog-data/glazing";
import {ProfileType} from '../../../../window-designer/catalog-data/profile-interface';
import {WindowSystemType, WindowSystemTypeData} from "../../../../window-designer/catalog-data/window-system-interface";
import {AreaSpecification} from "../../../../window-designer/drawing-data/AreaSpecification";
import {DrawingData, DrawingDataSpecification} from "../../../../window-designer/drawing-data/drawing-data";
import {Filling} from "../../../../window-designer/drawing-data/Filling";
import {FillingType} from "../../../../window-designer/drawing-data/FillingType";
import {FittingTerraceLockLocation} from '../../../../window-designer/drawing-data/FittingTerraceLockLocation';
import {Grill} from "../../../../window-designer/drawing-data/Grill";
import {GrillType} from "../../../../window-designer/drawing-data/GrillType";
import {HandleDirection} from "../../../../window-designer/drawing-data/HandleDirection";
import {Mullion} from "../../../../window-designer/drawing-data/Mullion";
import {SubWindowData} from "../../../../window-designer/drawing-data/SubWindowData";
import {TerraceHandleLayout} from '../../../../window-designer/drawing-data/TerraceHandleLayout';
import {WindowAddon} from "../../../../window-designer/drawing-data/WindowAddon";
import {WindowShapeType} from "../../../../window-designer/drawing-data/WindowShapeType";
import {WindowView} from '../../../../window-designer/drawing-data/WindowView';
import {DataKeys, Point} from "../../../../window-designer/drawing-util";
import {UpsellingChargeData} from "../../../../window-designer/entities/upselling-charge-data";
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';
import {ConfigAddonApplication} from '../../../../window-designer/enums/ConfigAddonApplication';
import {GlazingHelper} from "../../../../window-designer/glazing-helper";
import {WindowParams} from "../../../../window-designer/painters/WindowParams";
import {ProfilesCompositionDistances, ProfilesCompositionType} from "../../../../window-designer/profiles-composition-distances";
import {SubwindowTypes} from "../../../../window-designer/subwindow-types";
import {AlignmentTool} from '../../../../window-designer/utils/AlignmentTool';
import {AreaUtils} from "../../../../window-designer/utils/AreaUtils";
import {CutsUtil} from '../../../../window-designer/utils/cutUtils';
import {ErrorNames} from "../../../../window-designer/utils/ErrorNames";
import {GlassUpsellingValidationUtil} from '../../../../window-designer/utils/glass-upselling-validation-util';
import {GrillPositionValidator} from '../../../../window-designer/utils/grill-position-validator';
import {GrillUtils} from "../../../../window-designer/utils/GrillUtils";
import {MullionHelper} from "../../../../window-designer/utils/MullionHelper";
import {MullionUtils} from "../../../../window-designer/utils/MullionUtils";
import {OperationResult} from "../../../../window-designer/utils/OperationResult";
import {PolygonPointUtil} from "../../../../window-designer/utils/PolygonPoint";
import {PositionsHelper} from "../../../../window-designer/utils/positions-helper";
import {SidebarIdGenerator} from "../../../../window-designer/utils/SidebarIdGenerator";
import {UpsellingUtils} from "../../../../window-designer/utils/UpsellingUtils";
import {VentilationUtils} from "../../../../window-designer/utils/VentilationUtils";
import {VisibilitySettings} from "../../../../window-designer/utils/VisibilitySettings";
import {WindowAddonMapper} from "../../../../window-designer/utils/WindowAddonMapper";
import {WindowSystemDefaultsUtil} from "../../../../window-designer/utils/WindowSystemDefaultsUtil";
import {WindowCalculator} from "../../../../window-designer/window-calculator";
import {Tool} from '../../../../window-designer/window-designer-interface';
import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {CurrentUserService} from '../../../auth/current-user.service';
import {Permissions} from '../../../auth/permission.service';
import {ColorType} from '../../../ColorType';
import {ButtonWithMenuElementSelectedEvent} from "../../../common/button-with-menu/button-with-menu-event";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {MaterialType} from '../../../common/enums/MaterialType';
import {GrowlMessage} from "../../../common/growl-message/growl-message";
import {GrowlMessageController} from "../../../common/growl-message/growl-message-controller";
import {MessageParams} from '../../../common/growl-message/MessageParams';
import {MissingProfitMarginHandlerService} from '../../../common/missing-profit-margin-handler/missing-profit-margin-handler.service';
import {SidenavController} from '../../../sidenav-controller';
import {MultilanguageField, SupportedLanguages} from "../../../supportedLanguages";
import {WindowSystemDefaultsState} from "../../settings/system-defaults/system-default-state";
import {SubsystemGroupService} from '../../subsystem-group/subsystem-group.service';
import {SubsystemService} from "../../subsystem/subsystem.service";
import {SubsystemSelectionItem} from "../../subsystem/SubsystemSelectionItem";
import {AddonsService} from '../../window-system/addons/addons.service';
import {BusinessTypeService} from "../../window-system/business-type/business-type.service";
import {Color} from "../../window-system/color/color";
import {ColorService} from '../../window-system/color/color.service';
import {ConfigSystem} from "../../window-system/config-system/config-system";
import {DecorativeFillingsService} from "../../window-system/decorative-filling/decorative-filling.service";
import {DecorativeFilling} from "../../window-system/decorative-filling/decorativeFilling";
import {DistanceFrame} from "../../window-system/distance-frame/distanceFrame";
import {EntranceGlazingPackageService} from "../../window-system/entrance-glazing-package/entrance-glazing-package.service";
import {GlassChangeEvent} from "../../window-system/glass/glassChangeEvent";
import {GlassWithPosition} from "../../window-system/glass/glassWithPositions";
import {GlazingBead} from "../../window-system/glazing-bead/glazing-bead";
import {GlazingBeadService} from '../../window-system/glazing-bead/glazing-bead.service';
import {GlazingPackage} from '../../window-system/glazing-package/glazing-package';
import {WebshopGlazingPackage} from '../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package';
import {GraspDistanceFrameCategory} from "../../window-system/grasp-distance-frame-category/grasp-distance-frame-category";
import {GraspGlazingCategory} from "../../window-system/grasp-glazing-categories/grasp-glazing-category";
import {GraspGlazingPackage} from "../../window-system/grasp-glazing-package/grasp-glazing-package";
import {Grill as GrillDto} from "../../window-system/grill/grill";
import {OtherFillingService} from "../../window-system/other-filling/other-filling.service";
import {OtherFilling} from "../../window-system/other-filling/otherFilling";
import {Profile} from "../../window-system/profile/profile";
import {ProfileService} from '../../window-system/profile/profile.service';
import {Seal} from "../../window-system/seal/Seal";
import {SubwindowTypeService} from "../../window-system/subwindow-type/subwindow-type.service";
import {SubwindowType} from "../../window-system/subwindow-type/SubwindowType";
import {WebshopChargeService} from '../../window-system/webshop-charge/webshop-charge-service';
import {
    DataModificationTarget,
    isDataModificationMode,
    isUpsellingMode,
    WebshopCharge
} from '../../window-system/webshop-charge/WebshopCharge';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {WindowSystemDefinition} from "../../window-system/window-system-definition/window-system-definition";
import {WindowSystemDefinitionService} from "../../window-system/window-system-definition/window-system-definition.service";
import {WindowSystemModelService} from '../../window-system/window-system-model/window-system-model-service';
import {WindowSystemModel} from '../../window-system/window-system-model/WindowSystemModel';
import {PositionType} from "../AbstractPosition";
import {OffersService} from "../offer-service";
import {
    WindowAddonActionType,
    WindowAddonSaveData
} from "../offers/position/position-list/add-bulk-addon-position/addon-position/addon-position.component";
import {ConfigurableAddonDialogEventModel} from "../offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonDialogEventModel";
import {PositionFactory} from "../offers/position/position-list/position";
import {AddConfigurableAddonDialogData, DialogType} from "../offers/position/position-list/position-list-dialogs";
import {PositionService} from "../offers/position/position.service";
import {ProductionOrderService} from '../production-orders/production-order-service';
import {ProductionOrdersPositionService} from '../production-orders/production-orders-position/production-orders-position.service';
import {VeneerComponent} from '../veneer/veneer.component';
import {VeneerEvent} from "../veneer/VeneerEvent";
import {CustomTranslations} from "./custom-translations";
import {CustomTranslationsService} from "./custom-translations-service";
import {DecorativeFillingWithColors} from './DecorativeFillingWithColors';
import {
    DrawingToolControlsComponent,
    DrawingToolControlsMode,
    GrillParamsSelectedEvent
} from "./drawing-tool-controls/drawing-tool-controls.component";
import {ConfigurableAddonUtils} from "./drawing-tool/ConfigurableAddonUtils";
import {GlazingBeadMatchingMode, GlazingBeadUtils} from "./drawing-tool/GlazingBeadUtils";
import {ProfitMarginExistance} from './drawing-tool/ProfitMarginExistance';
import {WindowTabData} from "./drawing-tool/WindowTabData";
import {SidebarSection} from "./fieldUsage";
import {GeneralTabHaveErrors} from "./GeneralTabHaveErrors";
import {OtherFillingWithColors} from "./OtherFillingWithColors";
import {FillingColorType} from "./sidebar/FillingColorType";
import {GeneralTabComponent, SizeProp} from "./sidebar/general-tab/general-tab.component";
import {ConfigurableAddonPositionModel} from "./sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel";
import {GlazingPackagePositionModel} from "./sidebar/pricing/glazing-package-position-model";
import {PricingComponent} from "./sidebar/pricing/pricing.component";
import {CatalogItemType} from "./sidebar/pricing/PricingItem";
import {ResponseStatusFlags, ResponseStatusHelper} from "./sidebar/ResponseStatusFlags";
import {SidebarFieldImageService} from './sidebar/sidebar-field-image.service';
import {SubwindowComponent, SubwindowPropertyChangeEvent} from "./sidebar/subwindow/subwindow.component";
import {ValidationComponent} from "./sidebar/validation/validation.component";
import {SystemDefaultsService} from "./system-defaults.service";
import {WindowComponentPreviewData} from './window-component-preview-dialog/window-component-preview-dialog.component';
import {DrawingDataFieldChange} from './window-designer/drawing-data-differ.service';
import {CatalogData, WindowDesignerComponent, WindowDesignerComponentInitData} from "./window-designer/window-designer.component";
import {WindowEditorField} from './window-editor-field';
import {
    CatalogLoadResult,
    WINDOW_EDITOR_FIELD_CONTENT_PROVIDER_SERVICES,
    WindowEditorFieldContentProvider
} from './window-editor-field-content-provider';
import {WindowEditorOfferData, WindowEditorPositionData, WindowEditorProductionOrderData} from './window-editor-offer-interfaces';
import {WindowEditorWindowSystemInterface} from './window-editor-window-system-interface';

declare let jQuery: any;

@Component({
    selector: 'app-window-editor',
    templateUrl: './window-editor.component.html',
    styleUrls: ['common/designers.css', './window-editor.component.css'],
    providers: [...WINDOW_EDITOR_FIELD_CONTENT_PROVIDER_SERVICES,
        DataServiceHelper,
        DecorativeFillingsService,
        OtherFillingService,
        SubwindowTypeService,
        OffersService,
        PositionService,
        ProfileService,
        EntranceGlazingPackageService,
        SidebarFieldImageService,
        AddonsService,
        ColorService,
        GlazingBeadService,
        SubsystemGroupService,
        SubsystemService,
        CustomTranslationsService,
        ProductionOrdersPositionService,
        ProductionOrdersPositionService,
        ProductionOrderService,
        WebshopChargeService,
        WindowEditorFieldContentProvider,
        MissingProfitMarginHandlerService,
    ]
})
export class WindowEditorComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy, AfterViewInit, OnDestroy {

    windowSystemTypeGroup: ProductTypeGroup;

    private readonly GRILL_SELECTIONS_UI_KEY = 'grillSelections';

    private readonly route: ActivatedRoute;
    private readonly router: Router;
    private readonly permissions: Permissions;
    private readonly offerService: OffersService;
    private readonly positionService: PositionService;
    private readonly windowSystemDefinitionService: WindowSystemDefinitionService;
    private readonly businessTypeService: BusinessTypeService;
    private readonly subwindowTypeService: SubwindowTypeService;
    private readonly windowSystemDefaultsService: SystemDefaultsService;
    private readonly subsystemGroupService: SubsystemGroupService;
    private readonly subsystemService: SubsystemService;
    private readonly customTranslationsService: CustomTranslationsService;
    private readonly productionOrderPositionService: ProductionOrdersPositionService;
    private readonly productionOrderService: ProductionOrderService;
    private readonly sidenavController: SidenavController;
    private readonly currentUserService: CurrentUserService;
    private readonly growls: GrowlMessageController;
    private readonly errors: CommonErrorHandler;
    private readonly windowSystemModelService: WindowSystemModelService;
    private readonly webshopChargeService: WebshopChargeService;
    private readonly missingProfitMarginHandlerService: MissingProfitMarginHandlerService;

    private readonly windowEditorFieldContentProvider: WindowEditorFieldContentProvider;

    dialogType: DialogType;
    FillingColorType = FillingColorType;
    WindowEditorField = WindowEditorField;
    DrawingToolControlsMode = DrawingToolControlsMode;

    @ViewChild('designer', {static: true}) designer: WindowDesignerComponent;
    @ViewChild('drawingToolsControl', {static: true}) drawingToolsControl: DrawingToolControlsComponent;
    showSidebar: boolean;
    showExitWithoutSavingConfirmationDialog: boolean;
    showUpdateConfigurableAddonsQuantityDialog = false;
    showSettingsDialog: boolean;
    showDescriptionDialog = false;
    settings: VisibilitySettings;
    defaultSettings: VisibilitySettings = new VisibilitySettings();
    settingsList: string[] = Object.keys(this.defaultSettings);

    savedSettings: any;

    get isTerrace() {
        return this.windowSystemTypeGroup === ProductTypeGroup.TERRACE;
    }

    @ViewChild('pricing') pricingComponent: PricingComponent;
    @ViewChild('validation') validationComponent: ValidationComponent;
    @ViewChild('mainTab') mainTab: TabPanel;
    @ViewChild('tabView') tabView: TabView;
    @ViewChild(GeneralTabComponent) generalTabComponent: GeneralTabComponent;
    @ViewChildren(SubwindowComponent) subWindowComponents: QueryList<SubwindowComponent>;

    // Tool options
    currentMode: any;
    // Sidebar fields
    allActiveColors: Color[] = [];
    intersectingGrillColors: Color[] = [];
    intersectingGrillRalColors: Color[] = [];
    intersectingGrillNcsColors: Color[] = [];
    systemGrillColors: Color[] = [];
    systemRalExternalColors: Color[] = [];
    systemRalInternalColors: Color[] = [];
    systemRalBothSidesColors: Color[] = [];
    systemNcsExternalColors: Color[] = [];
    systemNcsInternalColors: Color[] = [];
    systemNcsBothSidesColors: Color[] = [];
    grillColors: { [grillId: number]: Color[] } = {};
    grillRalColors: { [grillId: number]: Color[] } = {};
    grillNcsColors: { [grillId: number]: Color[] } = {};
    frames: DistanceFrame[] = [];
    glasses: GlassWithPosition[] = [];
    availableGlassCounts: number[] = [];
    availableGlassCountsForDecor: number[] = [];
    filteredGlassCounts: number[] = [];
    systemExternalSeals: Seal[] = [];
    systemInternalSeals: Seal[] = [];
    systemDecorativeFillings: DecorativeFillingWithColors[] = [];
    systemOtherFillings: OtherFillingWithColors[] = [];
    allActiveProfiles: Profile[] = [];
    systemFrameProfiles: Profile[] = [];
    allConfigAddonDefinitions: ConfigSystem[] = [];
    windowSystemsForVeneer: WindowEditorWindowSystemInterface[] = [];
    filteredWindowSystems: WindowEditorWindowSystemInterface[] = [];
    sidebarInitialized: boolean;
    allTabsFieldsFilled: boolean[] = [];
    availableDoorsteps: Profile[] = [];
    availableChannelSections: Profile[] = [];
    availableConstructionalMullions: Profile[] = [];
    availableMovablePosts: Profile[] = [];
    systemMullions: Profile[] = [];
    grills: GrillDto[] = [];
    angledGrills: GrillDto[] = [];
    availableSubsystemGroups: SubsystemSelectionItem[] = [];
    availableSubsystems: SubsystemSelectionItem[] = [];
    windowSystemDefaults: WindowSystemDefaultsState;
    windowSystemDefaultsLevel: string;
    windowSystemDefaultsLevels: SelectItem[] = [];
    windowSystemDefaultsOverrideLowerLevel: boolean;
    windowSystemDefaultsValidation: { [field: string]: string } = {};
    systemGlazingWidthItems: SelectItem[] = [];
    filteredGlazingBeadsPerSubwindow: { [subwindowId: string]: { [areaId: string]: GlazingBead[] } } = {};
    filteredGlazingBeadsGeneralTab: GlazingBead[] = [];
    availableWebshopGlazingPackages: WebshopGlazingPackage[] = [];
    availableTerraceGlazingPackage: GlazingPackage[] = [];
    allAddons: AllAddons = new AllAddons();
    availableGlazingBeads: GlazingBead[] = [];
    chosenCoreColor: Color;
    availableGlazingPackageQuantities: number[] = [];
    availableGlazingPackages: GraspGlazingPackage[] = [];

    @Input() sidebarOnlyMode = false;
    @Input() modelMode = false;
    @Input() dataModificationMode: DataModificationTarget | undefined;
    @Input() editedWindowSystemId: number;
    @Input() webshopCharge: WebshopCharge;
    @Output() onModelSave = new EventEmitter();
    @Output() onWebshopChargeSave = new EventEmitter();
    @Output() onModelCancel = new EventEmitter();
    @Output() onWebshopChargeCancel = new EventEmitter();
    displayConfirmOverwritingModelDialog = false;

    readOnlyMode = true;
    displayConfirmReplacingWindowSystemDefaultsDialog = false;
    displayConfirmOverwritingSubsystemGroupsDefaultsDialog = false;
    displayConfirmOverwritingSystemDefaultsDialog = false;
    private readonly undoHotkey: Hotkey;
    private readonly leftHotkey: Hotkey;
    private readonly rightHotkey: Hotkey;
    private readonly redoHotkey: Hotkey;
    private readonly deleteHotkey: Hotkey;
    private readonly escHotkey: Hotkey;
    private readonly addonWindowHotkey: Hotkey;

    requiredFieldFilled = false;

    dataLoadingStatus = {
        windowSystemsByBusinessTypes: false,
        catalog: false,
        businessTypesLoadInProgress: false
    };

    isAddonsTabViewVisible = false;
    isConfigAddonsListVisible = false;
    isConfigSystemsListVisible = false;

    SidebarSection = SidebarSection;

    generalTabHaveErrors: GeneralTabHaveErrors = new GeneralTabHaveErrors();

    private highlightValidationErrors = false;
    configurableAddonDialogData: AddConfigurableAddonDialogData;
    configurableAddonPosition: WindowEditorPositionData;
    offer: WindowEditorOfferData;
    productionOrder: WindowEditorProductionOrderData;

    windowComponentPreviewData: WindowComponentPreviewData;

    selectedWebshopGlazingPackageId: number;
    newOffer = false;

    constructor(injector: Injector, changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WindowEditorComponent', false);
        this.route = injector.get(ActivatedRoute);
        this.router = injector.get(Router);
        this.permissions = injector.get(Permissions);
        this.offerService = injector.get(OffersService);
        this.positionService = injector.get(PositionService);
        this.windowSystemDefinitionService = injector.get(WindowSystemDefinitionService);
        this.businessTypeService = injector.get(BusinessTypeService);
        this.subwindowTypeService = injector.get(SubwindowTypeService);
        this.windowSystemDefaultsService = injector.get(SystemDefaultsService);
        this.subsystemGroupService = injector.get(SubsystemGroupService);
        this.subsystemService = injector.get(SubsystemService);
        this.customTranslationsService = injector.get(CustomTranslationsService);
        this.sidenavController = injector.get(SidenavController);
        this.currentUserService = injector.get(CurrentUserService);
        this.growls = injector.get(GrowlMessageController);
        this.errors = injector.get(CommonErrorHandler);
        this.productionOrderPositionService = injector.get(ProductionOrdersPositionService);
        this.productionOrderService = injector.get(ProductionOrderService);
        this.windowSystemModelService = injector.get(WindowSystemModelService);
        this.webshopChargeService = injector.get(WebshopChargeService);
        this.missingProfitMarginHandlerService = injector.get(MissingProfitMarginHandlerService);
        this.windowEditorFieldContentProvider = injector.get(WindowEditorFieldContentProvider);
        this.windowEditorFieldContentProvider.filteringEnabled = true;

        if (this.route.snapshot.data.sidebarOnlyMode != undefined) {
            this.sidebarOnlyMode = this.route.snapshot.data.sidebarOnlyMode;
        }

        this.showSidebar = true;
        this.showExitWithoutSavingConfirmationDialog = false;
        this.initSavedSettings();
        this.hideSpecificToolOptions();

        this.showSettingsDialog = false;
        this.settings =
            (this.getUserUiConfigForThisView("visibilitySettings") as VisibilitySettings) || this.defaultSettings;

        this.rightHotkey = new Hotkey('ctrl+right', () => {
            if (this.tabView) {
                let selectedId = this.tabView.tabs.findIndex(tab => tab.selected);
                if (selectedId !== -1) {
                    this.tabView.tabs[selectedId].selected = false;
                } else {
                    selectedId = 0;
                }
                ++selectedId;
                if (selectedId >= this.tabView.tabs.length) {
                    selectedId = 0;
                }
                this.tabView.tabs[selectedId].selected = true;
                this.tabChanged({index: selectedId});
            }
            return false;
        }, ['INPUT'], 'GENERAL.HOTKEYS.TAB_RIGHT');
        this.leftHotkey = new Hotkey('ctrl+left', () => {
            if (this.tabView) {
                let selectedId = this.tabView.tabs.findIndex(tab => tab.selected);
                if (selectedId !== -1) {
                    this.tabView.tabs[selectedId].selected = false;
                } else {
                    selectedId = 0;
                }
                --selectedId;
                if (selectedId < 0) {
                    selectedId = this.tabView.tabs.length - 1;
                }
                this.tabView.tabs[selectedId].selected = true;
                this.tabChanged({index: selectedId});
            }
            return false;
        }, ['INPUT'], 'GENERAL.HOTKEYS.TAB_LEFT');
        this.undoHotkey = new Hotkey('ctrl+z', () => {
            this.undoLast();
            return false;
        }, undefined, 'OFFER.MENU.UNDO');

        this.redoHotkey = new Hotkey('ctrl+y', () => {
            this.redoLast();
            return false;
        }, undefined, 'OFFER.MENU.REDO');
        this.deleteHotkey = new Hotkey('del', () => {
            this.deleteSelectedItem();
            return false;
        }, undefined, 'OFFER.MENU.REMOVE_SELECTED');
        this.escHotkey = new Hotkey('esc', () => {
            if (this.designer.mode !== Tool.SELECT) {
                this.drawingToolsControl.cancelMode();
            } else {
                this.resetSelectedElements();
            }
            return false;
        });
        this.addonWindowHotkey = new Hotkey('alt+a', () => {
            this.showAddonsTabView();
            return false;
        }, undefined, 'OFFER.TABS.SECTION.ADDONS.SECTION_TITLE');
    }

    static getAttrIdByField(field: string, area: AreaSpecification): number {
        switch (field) {
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
                return area.glazingCategoryId;
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
                return area.glazingFrameCategoryId;
            case WindowEditorField.GLAZING_PACKAGE:
                return area.glazingPackageId;
            case WindowEditorField.GLAZING_BEAD:
                return area.glazingBead.id;
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
                return area.filling.fillingId;
            case WindowEditorField.DECORATIVE_FILLING:
                return area.filling.decorativeFillingId;
        }
    }

    private initSavedSettings(): void {
        this.savedSettings = this.getUserUiConfigForThisView(this.GRILL_SELECTIONS_UI_KEY) || {};
    }

    showDialogToAdd() {
    }

    onRowSelect() {
    }

    getDatatable() {
        return undefined;
    }

    aligmentToolMenuSelected(event: ButtonWithMenuElementSelectedEvent) {
        switch (event.identifier) {
            case 'alignment-frame':
                this.designer.alignmentTool(event.originalEvent, false, false);
                break;
            case 'alignment-frame-vertical':
                this.designer.alignmentTool(event.originalEvent, false, true);
                break;
            case 'alignment-glass':
                this.designer.alignmentTool(event.originalEvent, true, false);
                break;
            case 'alignment-glass-vertical':
                this.designer.alignmentTool(event.originalEvent, true, true);
                break;
        }
    }

    trimToolUsed() {
        this.cancelMode();
        this.designer.trimTool();
    }

    submit() {
        if (!this.isSystemChangeInProgress()) {
            // return in each case to be sure we dont hit other options
            // (ie saveAndExit when changing size, or Tool.SELECT after calling cancelMode)
            if (this.designer.isAddWindowDialogDisplayed()) {
                console.error("enter should be captured by add window dialog not here");
                return;
            }
            if (this.designer.guidesDialogData && this.designer.guidesDialogData.displayDialog) {
                this.designer.displayChangeSizeEvent();
                return;
            }
            if (this.designer.mode === Tool.GRILL || this.designer.mode === Tool.GRILL_TEMPLATE) {
                if (!this.drawingToolsControl.isGrillGridInputActive()) {
                    this.cancelMode();
                }
                return;
            }
            if (this.designer.mode === Tool.SELECT) {
                this.saveAndExit();
                return;
            }
        }
    }

    ngOnInit(): void {
        if (!this.sidebarOnlyMode) {
            this.sidenavController.hide();
        }
        this.dataLoadingStatus.catalog = true;
        this.blockUiController.block('WindowEditorInit');
        this.loadWindowSystemDefaultsLevels();
        this.hideDataLoadingIndicator();

        let offerId = this.route.snapshot.params['offerId'];
        let offerPositionId = this.route.snapshot.params['positionId'];
        let prodOrder = offerId == null;
        if (offerId == null) {
            offerId = this.route.snapshot.params['productionOrderId'];
            offerPositionId = this.route.snapshot.params['positionId'];
        }
        if (offerPositionId === 'new') {
            this.newOffer = true;
        }
        this.windowSystemTypeGroup = this.route.snapshot.queryParams['windowSystemTypeGroup'] || false;
        let offerObservable: Observable<WindowEditorOfferData | WindowEditorProductionOrderData | undefined> = this.sidebarOnlyMode
            ? of(undefined)
            : (prodOrder
                ? this.productionOrderService.getProductionOrderForWindowEditor(offerId)
                : this.offerService.getOfferForWindowEditor(offerId));
        let offerPositionObservable: Observable<WindowEditorPositionData>;
        let childOfferPositionObservable: Observable<WindowEditorPositionData[]>;
        const setDefaults = this.newOffer || this.sidebarOnlyMode || this.modelMode;
        if (setDefaults) {
            offerPositionObservable = of(PositionFactory.window(offerId));
            childOfferPositionObservable = of([]);
        } else {
            offerPositionObservable = prodOrder
                ? this.productionOrderPositionService.getProductionOrderPositionForWindowEditor(offerPositionId)
                : this.positionService.getOfferPositionForWindowEditor(offerPositionId);
            childOfferPositionObservable = prodOrder ? of([]) : this.positionService.getChildOfferPositions(offerPositionId);
        }
        const modelDefaultsObservable = this.modelMode && this.editedWindowSystemId != undefined
            ? this.windowSystemModelService.getDefaultsFor(this.editedWindowSystemId)
            : of<WindowSystemModel>(undefined);

        forkJoin({
            offer: offerObservable,
            position: offerPositionObservable,
            childOfferPositions: childOfferPositionObservable,
            modelDefaults: modelDefaultsObservable
        }).pipe(catchError(
            (error) => {
                this.blockUiController.unblock('WindowEditorInit');
                this.errors.handle(error);
                this.goToOffersList();
                return EMPTY;
            }), mergeMap(data => {
            if (prodOrder && !this.sidebarOnlyMode) {
                this.productionOrder = data.offer as WindowEditorProductionOrderData;
                this.readOnlyMode = true;
            } else {
                this.offer = data.offer as WindowEditorOfferData;
                if (!this.sidebarOnlyMode) {
                    this.readOnlyMode = (this.offer.offerLockUserLogin !== this.currentUserService.currentUserName
                        && !this.currentUserService.restrictedToOfferNumber)
                        || !this.permissions.userCanEditOffer(this.offer.status)
                        || (data.position.validationDisabled && !this.permissions.isKoordynator() && !this.permissions.isOpiekun())
                        || data.childOfferPositions.some(childPos => childPos.type === PositionType.CONFIGURABLE_ADDON);
                } else {
                    this.readOnlyMode = false;
                }
            }
            let drawingData: DrawingData = JSON.parse(data.position.data);
            if (drawingData.specification.terraceGlazingPackageId != null) {
                this.windowSystemTypeGroup = ProductTypeGroup.TERRACE;
            }
            const windowSystemDefaultsForModel = new WindowSystemDefaultsState();
            if (this.modelMode) {
                drawingData.windowSystemId = this.editedWindowSystemId;
                windowSystemDefaultsForModel.value = this.editedWindowSystemId == undefined ? undefined : data.modelDefaults.value;
            }
            if (this.isDataModificationMode()) {
                drawingData.windowSystemId = this.webshopCharge == undefined ? undefined : this.webshopCharge.windowSystemId;
            }
            return forkJoin({
                position: of(data.position),
                drawingData: of(drawingData),
                childOfferPositions: of(data.childOfferPositions),
                windowSystemsForVeneer: drawingData.windowSystemId
                    ? this.loadWindowSystemsForVeneer(drawingData.windowSystemId)
                    : of<WindowEditorWindowSystemInterface[]>([]),
                windowSystems: this.loadAvailableWindowSystems(drawingData),
                subwindowTypes: this.loadAllSubwindowTypes(),
                customTranslations: this.loadCustomTranslations(),
                subsystems: this.loadAllSubsystems(),
                subsystemGroups: this.loadAllSubsystemGroups(),
                ...this.windowEditorFieldContentProvider.getCatalogForWindowSystemDataSources(drawingData.windowSystemId,
                    prodOrder ? undefined : offerId, this.modelMode,
                    this.modelMode ? windowSystemDefaultsForModel : drawingData,
                    this.readOnlyMode, setDefaults),
                windowSystem: drawingData.windowSystemId
                    ? this.windowSystemDefinitionService.getSystem(drawingData.windowSystemId)
                    : of<WindowSystemDefinition>(undefined)
            });
        })).subscribe(
            catalogData => {
                let position = catalogData.position;
                this.windowSystemsForVeneer = catalogData.windowSystemsForVeneer;
                this.filteredWindowSystems = catalogData.windowSystems;
                this.addEditedWindowSystemIfItIsCurrentlyUnavailable(catalogData.drawingData.windowSystemId, catalogData.windowSystem);
                this.availableSubsystems = catalogData.subsystems;
                this.availableSubsystemGroups = catalogData.subsystemGroups;
                this.initCatalog(catalogData, catalogData.drawingData);

                // rebind field selections
                if (position.id != undefined) {
                    this.rebindFieldSelections(catalogData.drawingData, catalogData.windowSystem,
                        this.designer.profileCompositionDistances);
                    this.recreateMullionsAndGrills(catalogData.drawingData);
                    this.sidebarInitialized = true;
                    this.validateRequiredFields();
                    this.registerHotkeys();
                } else if (this.isDataModificationMode()) {
                    if (isUpsellingMode(this.dataModificationMode)) {
                        WindowSystemDefaultsUtil.applyUpsellingChargeToSubwindow(this.designer.upsellingSubwindowDummy,
                            this.windowSystemDefaults.value as UpsellingChargeData);
                    }
                    catalogData.drawingData.windowSystemId =
                        this.webshopCharge.windowSystemId ? this.webshopCharge.windowSystemId :
                            this.filteredWindowSystems.length > 0 ? this.filteredWindowSystems[0].id : null;
                } else if (this.sidebarOnlyMode) {
                    catalogData.drawingData.windowSystemId =
                        this.modelMode && this.editedWindowSystemId ? this.editedWindowSystemId :
                            this.filteredWindowSystems.length > 0 ? this.filteredWindowSystems[0].id : null;
                }
                this.initWindowViews(catalogData.drawingData);
                this.designer.catalogLoaded.next(
                    new WindowDesignerComponentInitData(this.offer, position, catalogData.drawingData, catalogData.childOfferPositions,
                        new SubwindowTypes(catalogData.subwindowTypes), catalogData.customTranslations, catalogData.windowSystem,
                        this.windowSystemDefaults, undefined, this.grills, this.angledGrills, this.systemMullions, this.glasses,
                        this.frames, this.systemDecorativeFillings, this.allActiveColors, this.filteredWindowSystems, this.allAddons,
                        this.allConfigAddonDefinitions));
                this.designer.catalogLoaded.complete();
                this.dataLoadingStatus.catalog = false;
            });
    }

    ngAfterViewInit(): void {
        this.hideViewLoadingIndicator();
    }

    ngOnDestroy(): void {
        this.deregisterHotkeys();
        this.sidenavController.show();
        super.ngOnDestroy();
    }

    private loadWindowSystemDefaultsLevels(): void {
        if (!this.sidebarOnlyMode) {
            this.windowSystemDefaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.OFFER',
                value: 'OFFER'
            });
            if (this.permissions.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})) {
                this.windowSystemDefaultsLevels.push({
                    label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.CLIENT',
                    value: 'CLIENT'
                });
            }
            if (this.permissions.isPermitted({roles: ['ROLE_OPERATOR']})) {
                this.windowSystemDefaultsLevels.push({
                    label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.CLIENT_GROUP',
                    value: 'CLIENT_GROUP'
                });
            }
        }
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPERATOR']})) {
            this.windowSystemDefaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM',
                value: 'SUBSYSTEM'
            });
        }
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            this.windowSystemDefaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM_GROUP',
                value: 'SUBSYSTEM_GROUP'
            });
        }
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
            this.windowSystemDefaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.GLOBAL',
                value: 'GLOBAL'
            });
        }
        if (this.windowSystemDefaultsLevels.length > 0) {
            this.windowSystemDefaultsLevel = this.windowSystemDefaultsLevels[0].value;
        }
    }

    loadWindowSystemsForVeneer(supplierId: number): Observable<WindowEditorWindowSystemInterface[]> {
        if (this.sidebarOnlyMode) {
            return of<WindowEditorWindowSystemInterface[]>([]);
        }
        return this.windowSystemDefinitionService.getSystemsForVeneer(supplierId, this.getSystemTypesFilter());
    }

    private loadAvailableWindowSystems(drawingData: DrawingData): Observable<WindowEditorWindowSystemInterface[]> {
        let systemTypes = this.getSystemTypesFilter();
        if (drawingData.windows.length === 0) {
            let enabledInWebshop = undefined;
            let onlyWithBusinessType = undefined;
            if (this.modelMode) {
                enabledInWebshop = true;
            } else {
                onlyWithBusinessType = true;
            }
            return this.windowSystemDefinitionService.getSystemsForWindowEditor(enabledInWebshop, onlyWithBusinessType, systemTypes);
        }
        let existingTypes = drawingData.windows.map(w => WindowTypeCode[w.typeCode]);
        const windowSystem = this.designer.getWindowSystem();
        systemTypes = systemTypes.filter(t => t.doors === windowSystem.doors);
        return this.businessTypeService.getWindowSystemsByBusinessTypes(_.uniq(existingTypes), systemTypes);
    }

    private getSystemTypesFilter(): WindowSystemTypeData[] {
        if (this.sidebarOnlyMode) {
            return undefined;
        }
        return WindowSystemType.getByGroup(this.windowSystemTypeGroup);
    }

    private loadAllSubwindowTypes(): Observable<SubwindowType[]> {
        return this.subwindowTypeService.getItems(0, null, null, "id", 1).pipe(map(listingDto => listingDto.data));
    }

    private loadCustomTranslations(): Observable<CustomTranslations> {
        if (!this.sidebarOnlyMode) {
            return this.customTranslationsService.get();
        }
        return of<CustomTranslations>(new CustomTranslations());
    }

    private loadAllSubsystemGroups(): Observable<SubsystemSelectionItem[]> {
        if (this.sidebarOnlyMode) {
            return this.subsystemGroupService.getItems(undefined, undefined, undefined, undefined, undefined).pipe(
                map(listingDto => listingDto.data.map(sg => ({id: sg.id, name: sg.name, selected: false}))));
        }
        return of<SubsystemSelectionItem[]>([]);
    }

    private loadAllSubsystems(): Observable<SubsystemSelectionItem[]> {
        if (this.sidebarOnlyMode) {
            return this.subsystemService.getNames().pipe(map(listingDto => listingDto.data));
        }
        return of<SubsystemSelectionItem[]>([]);
    }

    private initCatalog(catalogData: CatalogLoadResult, data: DrawingData | WindowSystemDefaultsState,
                        setDefaults = false): void {
        this.initSystemColors(catalogData.colors, data, setDefaults);
        this.initSystemSeals(catalogData.seals, data, setDefaults);
        this.initSystemProfiles(catalogData.profiles, data, setDefaults);
        this.initSystemAddOns(catalogData.addons, data, setDefaults);
        this.initSystemDecorativeFillings(catalogData.decorativeFillings, catalogData.colors, data, setDefaults);
        this.initSystemOtherFillings(catalogData.fillings, catalogData.colors, data, setDefaults);
        this.initSystemGrills(catalogData.grills, data, setDefaults);
        this.initSystemDefaults(catalogData.windowSystemDefaults);
        this.initSystemGlazingBeads(catalogData.glazingBeads, data, setDefaults);
        this.initGlasses(catalogData.glasses, data, setDefaults);
        this.initDistanceFrames(catalogData.distanceFrames, data, setDefaults);
        this.initConfigAddonDefinitions(catalogData.configurableAddonDefinitions);
        this.initSystemModel(catalogData.windowSystemModel);
        this.initWebshopGlazingPackages(catalogData.webshopGlazingPackage);
        const spec = setDefaults ? this.windowEditorFieldContentProvider.getWindowSystemDefaultsData(data)
            : this.windowEditorFieldContentProvider.getDrawingDataSpec(data);
        this.initTerraceGlazingPackages(catalogData.terraceGlazingPackage, spec ? spec.terraceGlazingPackageId : undefined);
        this.windowEditorFieldContentProvider.storeWindowFunctions(this.isDataModificationMode());
        this.windowEditorFieldContentProvider.storeOpeningOptions(catalogData.windowSystem && catalogData.windowSystem.possibleOpenings,
            this.isDataModificationMode());
        this.initTerraceHandleLayouts();
        this.initTerraceLockLocations();
        this.initGlazingPackages(catalogData.graspGlazingPackages, catalogData.graspGlazingCategories,
            catalogData.graspDistanceFrameCategories, catalogData.glasses, catalogData.distanceFrames, catalogData.glazingBeads,
            data, setDefaults);
        this.windowEditorFieldContentProvider.storeFieldDependencies(catalogData.fieldDependencies);
    }

    private initDistanceFrames(frames: DistanceFrame[], data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): void {
        this.frames = this.windowEditorFieldContentProvider.storeDistanceFrames(frames, data, setDefaults);
    }

    private initGlasses(glasses: GlassWithPosition[], data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): void {
        this.glasses = this.windowEditorFieldContentProvider.storeGlasses(glasses, data, setDefaults);
    }

    private initSystemColors(colors: Color[], data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): void {
        const groupedColors = this.windowEditorFieldContentProvider.groupAndStoreColors(colors, this.isDataModificationMode(),
            this.modelMode, data, setDefaults);
        this.allActiveColors = groupedColors.allActiveColors;
        this.allActiveColors.push(this.getEmptyRalColor());
        this.allActiveColors.push(this.getEmptyNcsColor());

        this.systemGrillColors = groupedColors.grillColors;
        this.systemRalExternalColors = groupedColors.ralExternalColors;
        this.systemRalInternalColors = groupedColors.ralInternalColors;
        this.systemRalBothSidesColors = groupedColors.ralBothSidesColors;
        this.systemNcsExternalColors = groupedColors.ncsExternalColors;
        this.systemNcsInternalColors = groupedColors.ncsInternalColors;
        this.systemNcsBothSidesColors = groupedColors.ncsBothSidesColors;
        if (groupedColors.ralExternalColors.length > 0) {
            const systemExternalColors = this.windowEditorFieldContentProvider.getUnfilteredItems(WindowEditorField.EXTERNAL_COLOR);
            this.windowEditorFieldContentProvider.setItems(WindowEditorField.EXTERNAL_COLOR,
                [...systemExternalColors, this.getEmptyRalColorSelectItem()]);
        }
        if (groupedColors.ralInternalColors.length > 0) {
            const systemInternalColors = this.windowEditorFieldContentProvider.getUnfilteredItems(WindowEditorField.INTERNAL_COLOR);
            this.windowEditorFieldContentProvider.setItems(WindowEditorField.INTERNAL_COLOR,
                [...systemInternalColors, this.getEmptyRalColorSelectItem()]);
        }
        if (groupedColors.ralBothSidesColors.length > 0) {
            const systemBothSideColors = this.windowEditorFieldContentProvider.getUnfilteredItems(WindowEditorField.TERRACE_COLOR);
            this.windowEditorFieldContentProvider.setItems(WindowEditorField.TERRACE_COLOR,
                [...systemBothSideColors, this.getEmptyRalColorSelectItem()]);
        }
        if (groupedColors.ncsExternalColors.length > 0) {
            const systemExternalColors = this.windowEditorFieldContentProvider.getUnfilteredItems(WindowEditorField.EXTERNAL_COLOR);
            this.windowEditorFieldContentProvider.setItems(WindowEditorField.EXTERNAL_COLOR,
                [...systemExternalColors, this.getEmptyNcsColorSelectItem()]);
        }
        if (groupedColors.ncsInternalColors.length > 0) {
            const systemInternalColors = this.windowEditorFieldContentProvider.getUnfilteredItems(WindowEditorField.INTERNAL_COLOR);
            this.windowEditorFieldContentProvider.setItems(WindowEditorField.INTERNAL_COLOR,
                [...systemInternalColors, this.getEmptyNcsColorSelectItem()]);
        }
        if (groupedColors.ncsBothSidesColors.length > 0) {
            const systemBothSideColors = this.windowEditorFieldContentProvider.getUnfilteredItems(WindowEditorField.TERRACE_COLOR);
            this.windowEditorFieldContentProvider.setItems(WindowEditorField.TERRACE_COLOR,
                [...systemBothSideColors, this.getEmptyNcsColorSelectItem()]);
        }
    }

    private initSystemSeals(seals: Seal[], drawingData: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): void {
        const groupedSeals = this.windowEditorFieldContentProvider.groupAndStoreSeals(seals, this.isDataModificationMode(),
            drawingData, setDefaults);
        this.systemExternalSeals = groupedSeals.externalSeals;
        this.systemInternalSeals = groupedSeals.internalSeals;
    }

    private initSystemProfiles(profiles: Profile[], drawingData: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): void {
        const groupedProfiles = this.windowEditorFieldContentProvider.groupAndStoreProfiles(profiles,
            this.isDataModificationMode(), drawingData, setDefaults);
        this.allActiveProfiles = groupedProfiles.profiles;
        this.systemFrameProfiles = groupedProfiles.frameProfiles;
        this.systemMullions = groupedProfiles.mullions;
        this.availableDoorsteps = groupedProfiles.doorsteps;
        this.availableChannelSections = groupedProfiles.channelSections;
        this.availableConstructionalMullions = groupedProfiles.constructionalMullions;
        this.availableMovablePosts = groupedProfiles.movablePosts;
    }

    private initConfigAddonDefinitions(definitions: ConfigSystem[] = []): void {
        this.allConfigAddonDefinitions = definitions.sort((a, b) => a.sortIndex - b.sortIndex);
    }

    private initWindowViews(drawingData: DrawingData): void {
        const windowSystem = this.findValueInList(this.filteredWindowSystems, drawingData.windowSystemId);
        if (windowSystem != undefined && windowSystem.material === MaterialType[MaterialType.PCV]) {
            this.windowEditorFieldContentProvider.storeWindowViews([WindowView.INSIDE], this.isDataModificationMode());
            drawingData.view = WindowView[WindowView.INSIDE];
        } else {
            this.windowEditorFieldContentProvider.storeWindowViews([WindowView.INSIDE, WindowView.OUTSIDE], this.isDataModificationMode());
        }
    }

    private initSystemAddOns(addOns: AllAddons, drawingData: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): void {
        this.allAddons = this.windowEditorFieldContentProvider.groupAndStoreAddons(addOns, this.isDataModificationMode(),
            drawingData, setDefaults);
    }

    private initSystemDecorativeFillings(decorativeFillings: DecorativeFilling[], colors: Color[],
                                         data: DrawingData | WindowSystemDefaultsState, setDefaults = false): void {
        this.windowEditorFieldContentProvider.storeDecorativeFillings(decorativeFillings, data, setDefaults);
        this.systemDecorativeFillings = decorativeFillings.map(filling => {
            return {
                id: filling.id,
                names: filling.names,
                thickness: filling.thickness,
                type: filling.type,
                minimalWidth: filling.minimalWidth,
                minimalHeight: filling.minimalHeight,
                externalColors: colors.filter(
                    c => c.active && (filling.outsideColors || []).map(color => color.id).indexOf(c.id) !== -1 &&
                        c.outside),
                internalColors: colors.filter(
                    c => c.active && (filling.insideColors || []).map(color => color.id).indexOf(c.id) !== -1 &&
                        c.inside),
                coreColor: filling.coreColor ? colors.find(c => c.active && c.core && c.id === filling.coreColor.id) : undefined,
                glazingSurface: filling.glazingSurface,
                glazingWidths: filling.glazingWidths
            };
        });
    }

    private initSystemOtherFillings(otherFillings: OtherFilling[], colors: Color[], data: DrawingData | WindowSystemDefaultsState,
                                    setDefaults = false): void {
        this.windowEditorFieldContentProvider.groupAndStoreOtherFillings(otherFillings, data, setDefaults);
        this.systemOtherFillings = otherFillings.map(filling => {
            return {
                id: filling.id,
                name: filling.name,
                forIndoorUse: filling.forIndoorUse,
                forOutdoorUse: filling.forOutdoorUse,
                thickness: filling.thickness,
                externalColors: colors.filter(
                    c => c.active && (filling.outsideColors || []).map(color => color.id).indexOf(c.id) !== -1 &&
                        c.outside),
                internalColors: colors.filter(
                    c => c.active && (filling.insideColors || []).map(color => color.id).indexOf(c.id) !== -1 &&
                        c.inside),
                coreColor: filling.coreColor ? colors.find(c => c.active && c.core && c.id === filling.coreColor.id) : undefined,
            };
        });
    }

    private initSystemGrills(grills: GrillDto[], data: DrawingData | WindowSystemDefaultsState,
                             setDefaults = false): void {
        const groupedGrills = this.windowEditorFieldContentProvider.groupAndStoreGrills(grills, data, setDefaults);
        this.grills = groupedGrills.grills;
        this.angledGrills = groupedGrills.angledGrills;

        this.initGrillColors();
    }

    private findColors(colors: any[], ids: number[]): Color[] {
        let values = [];
        colors.forEach(color => {
            if (ids.find(id => id === color.id) != null) {
                values.push(color);
            }
        });
        return values;
    }

    private initGrillColors(): void {
        const allGrillColors = this.allActiveColors.filter(c => c.grill);
        this.grills.forEach(grill => {
            let grillColors: Color[] = this.findColors(allGrillColors, grill.colorIds);
            this.grillColors[grill.id] = grillColors.filter(color => color.type !== ColorType.RAL_PALETTE_CUSTOM
                && color.type !== ColorType.NCS_PALETTE_CUSTOM);
            this.grillRalColors[grill.id] = grillColors.filter(color => color.type === ColorType.RAL_PALETTE_CUSTOM);
            this.grillNcsColors[grill.id] = grillColors.filter(color => color.type === ColorType.NCS_PALETTE_CUSTOM);
            if (this.grillRalColors[grill.id].length > 0) {
                this.grillColors[grill.id].push(this.getEmptyRalColor());
            }
            if (this.grillNcsColors[grill.id].length > 0) {
                this.grillColors[grill.id].push(this.getEmptyNcsColor());
            }
        });

        this.refilterIntersectingGrillColors();
    }

    private initSystemDefaults(windowSystemDefaults: WindowSystemDefaultsState): void {
        if (this.isDataModificationMode()) {
            let defaults = new WindowSystemDefaultsState();
            defaults.value = this.webshopCharge.value;
            this.windowSystemDefaults = defaults;
        } else {
            this.windowSystemDefaults = windowSystemDefaults;
        }
    }

    private initSystemModel(windowSystemModel: WindowSystemModel): void {
        if (windowSystemModel != undefined && windowSystemModel.value != undefined) {
            let defaults = new WindowSystemDefaultsState();
            defaults.value = windowSystemModel.value;
            if (this.editedWindowSystemId == undefined) {
                defaults.subsystemDefaultExisting = true;
            }

            this.windowSystemDefaults = defaults;
        }
    }

    private initWebshopGlazingPackages(glazingPackages: WebshopGlazingPackage[]): void {
        this.availableWebshopGlazingPackages = glazingPackages;
        if (this.selectedWebshopGlazingPackageId == undefined && glazingPackages.length > 0) {
            this.selectedWebshopGlazingPackageId = glazingPackages[0].id;
        }
    }

    private initTerraceGlazingPackages(glazingPackages: GlazingPackage[], selectedId: number): void {
        this.windowEditorFieldContentProvider.storeTerraceGlazingPackages(glazingPackages, selectedId);
        this.availableTerraceGlazingPackage = glazingPackages;
    }

    private initTerraceHandleLayouts(): void {
        this.windowEditorFieldContentProvider.storeTerraceHandleLayouts(Object.keys(TerraceHandleLayout)
            .map(k => TerraceHandleLayout[k]), this.isDataModificationMode());
    }

    private initTerraceLockLocations(): void {
        this.windowEditorFieldContentProvider.storeTerraceLockLocations(Object.keys(FittingTerraceLockLocation)
            .map(k => FittingTerraceLockLocation[k]), this.isDataModificationMode());
    }

    private initSystemGlazingBeads(glazingBeads: GlazingBead[], data: DrawingData | WindowSystemDefaultsState,
                                   setDefaults = false): void {
        this.availableGlazingBeads = this.windowEditorFieldContentProvider.storeGlazingBeads(glazingBeads, data, setDefaults);
    }

    private initGlazingPackages(glazingPackages: GraspGlazingPackage[], categories: GraspGlazingCategory[],
                                graspDistanceFrameCategories: GraspDistanceFrameCategory[], glasses: GlassWithPosition[],
                                distanceFrames: DistanceFrame[], glazingBeads: GlazingBead[],
                                data: DrawingData | WindowSystemDefaultsState, setDefaults: boolean): void {
        const glazingPackageHasAllComponents = (glazingPackage: GraspGlazingPackage): boolean => {
            for (let i = 1; i <= glazingPackage.glazing.glazingGlassQuantity; ++i) {
                const glass = this.findValueInList(glasses, glazingPackage.glazing['glass' + i + 'id']);
                if (glass == undefined || !glass.active) {
                    return false;
                }
            }
            for (let i = 1; i < glazingPackage.glazing.glazingGlassQuantity; ++i) {
                const distanceFrame = this.findValueInList(distanceFrames, glazingPackage.glazing['frame' + i + 'id']);
                if (distanceFrame == undefined || !distanceFrame.active) {
                    return false;
                }
            }
            const glazingBead = this.findValueInList(glazingBeads, glazingPackage.glazingBeadId);
            if (glazingBead == undefined || !glazingBead.active) {
                return false;
            }
            return true;
        };
        for (let glazingPackage of glazingPackages) {
            if (!glazingPackageHasAllComponents(glazingPackage)) {
                glazingPackage.active = false;
            }
        }
        this.availableGlazingPackages = glazingPackages;
        this.availableGlazingPackageQuantities = _.uniq(this.availableGlazingPackages.map(p => p.glazing.glazingGlassQuantity)).sort();
        this.windowEditorFieldContentProvider.storeGlazingPackagesQuantity(this.availableGlazingPackageQuantities);
        this.availableGlazingPackageQuantities.forEach(q => {
            const categoryIds = _.uniq(this.availableGlazingPackages.filter(p => p.glazing.glazingGlassQuantity === q)
                .map(p => p.glazingCategoryId));
            const frameCategoryIds = _.uniq(this.availableGlazingPackages.filter(p => p.glazing.glazingGlassQuantity === q)
                .map(p => p.frameCategoryId));
            const availableGlazingCategories = this.windowEditorFieldContentProvider.storeGlazingCategories(q,
                categories.filter(c => categoryIds.includes(c.id)));
            const availableFrameCategories = this.windowEditorFieldContentProvider.storeGlazingFrameCategories(q,
                graspDistanceFrameCategories.filter(c => frameCategoryIds.includes(c.id)));
            for (const c of availableGlazingCategories) {
                this.windowEditorFieldContentProvider.storeGlazingPackages(q, c.id, undefined, []);
                for (const fc of availableFrameCategories) {
                    const packages = glazingPackages.filter(ggp => {
                        return ggp.glazing.glazingGlassQuantity === q && ggp.glazingCategoryId === c.id && ggp.frameCategoryId === fc.id;
                    });
                    this.windowEditorFieldContentProvider.storeGlazingPackages(q, c.id, fc.id, packages);
                }
            }
            for (const fc of availableFrameCategories) {
                this.windowEditorFieldContentProvider.storeGlazingPackages(q, undefined, fc.id, []);
            }
            this.windowEditorFieldContentProvider.storeGlazingPackages(q, undefined, undefined, []);
        });
        this.windowEditorFieldContentProvider.storeGlazingPackages(undefined, undefined, undefined, []);

        // fields for error detection only
        this.windowEditorFieldContentProvider.storeAllGlazingCategories(categories, data, setDefaults);
        this.windowEditorFieldContentProvider.storeAllGlazingFrameCategories(graspDistanceFrameCategories, data, setDefaults);
        this.windowEditorFieldContentProvider.storeAllGlazingPackages(glazingPackages, data, setDefaults);
    }

    private findValueInList<T extends { id: number }>(list: T[], id: number): T {
        return list.find(v => v.id === id);
    }

    private findIdInList<T extends { id: number }>(list: T[], id: number): number {
        if (this.isDataModificationMode()) {
            const id2 = id as number | DropDownExtraOptions;
            if (id2 === DropDownExtraOptions.DO_NOT_CHANGE || id2 === DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE) {
                return id;
            }
            if (id2 === DropDownExtraOptions.CLEAN_OLD_VALUE) {
                return undefined;
            }
        }
        return (this.findValueInList(list, id) || {id: undefined}).id;
    }

    private rebindFieldSelections(drawingData: DrawingData, windowSystem: WindowSystemDefinition,
                                  profileCompositionDistances: ProfilesCompositionDistances, windowUpsellingMode = false) {
        // validate and unset if invalid sidebar fields selections here
        if (windowSystem != undefined && windowSystem.material === MaterialType[MaterialType.PCV]) {
            drawingData.view = WindowView[WindowView.INSIDE];
        }

        let spec = drawingData.specification;

        drawingData.windowSystemId = windowSystem != undefined ? windowSystem.id : undefined;
        spec.sealExternalId = this.findIdInList(this.systemExternalSeals, spec.sealExternalId);
        spec.sealInternalId = this.findIdInList(this.systemInternalSeals, spec.sealInternalId);
        let coreColor = this.findValueInList(this.allActiveColors, spec.colorIdCore);
        if (coreColor == undefined || !coreColor.core) {
            spec.colorIdCore = undefined;
        }
        let externalColor = this.findValueInList(this.allActiveColors, spec.colorIdExternal);
        if (externalColor == undefined || !externalColor.outside) {
            spec.colorIdExternal = windowUpsellingMode ? DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE : undefined;
        }
        let internalColor = this.findValueInList(this.allActiveColors, spec.colorIdInternal);
        if (internalColor == undefined || !internalColor.inside) {
            spec.colorIdInternal = windowUpsellingMode ? DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE : undefined;
        }
        spec.frameProfileId = this.findIdInList(this.systemFrameProfiles, spec.frameProfileId);
        spec.doorstepId = this.findIdInList(this.availableDoorsteps, spec.doorstepId);
        spec.channelSectionId = this.findIdInList(this.availableChannelSections, spec.channelSectionId);
        spec.constructionalMullionId = this.findIdInList(this.availableConstructionalMullions, spec.constructionalMullionId);
        spec.movablePostId = this.findIdInList(this.availableMovablePosts, spec.movablePostId);

        type WindowAddonProp = {
            [K in keyof DrawingDataSpecification]: DrawingDataSpecification[K] extends WindowAddon ? K : never;
        }[keyof DrawingDataSpecification];
        let rebindAddon = (property: WindowAddonProp, availables: AddonInterface[]) => {
            if (this.findIdInList(availables, spec[property] && spec[property].addonId) == null) {
                spec[property] = new WindowAddon();
            }
        };
        rebindAddon('weldType', this.allAddons.availableWeldTypes);
        rebindAddon('handleType', this.allAddons.availableHandles);
        rebindAddon('frameEnhancement', this.allAddons.availableEnhancements);
        rebindAddon('underwindowProfile', this.allAddons.availableUnderwindowProfiles);
        rebindAddon('milling', this.allAddons.availableMillings);
        rebindAddon('millingNorwegian', this.allAddons.availableMillingsNorwegian);
        rebindAddon('underWindowBead', this.allAddons.availableUnderWindowBeads);
        rebindAddon('cover', this.allAddons.availableCovers);
        rebindAddon('fittingBrake', this.allAddons.availableFittingBrakes);
        rebindAddon('fittingSliding', this.allAddons.availableFittingSlidings);
        rebindAddon('fittingType', this.allAddons.availableFittingTypes);
        rebindAddon('fittingEspagnoletteType', this.allAddons.availableFittingEspagnoletteTypes);
        rebindAddon('fittingVeranda', this.allAddons.availableFittingVerandas);
        rebindAddon('fittingInsertion', this.allAddons.availableFittingInsertions);
        rebindAddon('fittingMainInsertion', this.allAddons.availableFittingMainInsertions);
        rebindAddon('fittingAdditionalInsertion', this.allAddons.availableFittingAdditionalInsertions);
        rebindAddon('fittingLock', this.allAddons.availableFittingLocks);
        rebindAddon('terraceHandle', this.allAddons.availableTerraceHandles);

        const possibleOpenings = windowSystem != undefined ? windowSystem.possibleOpenings : [];
        if (possibleOpenings.find(opening => opening === spec.opening) == undefined) {
            if (possibleOpenings.length === 1) {
                spec.opening = possibleOpenings[0];
            } else if (!(this.isDataModificationMode() && spec.opening === DropDownExtraOptions.DO_NOT_CHANGE)) {
                spec.opening = undefined;
            }
        }
        for (let window of drawingData.windows) {
            for (let subWindow of window.subWindows) {
                for (let area of subWindow.areasSpecification) {
                    let fillingId = area.filling.fillingId;
                    let decorativeFillingId = area.filling.decorativeFillingId;
                    area.glazingBead.id = this.findIdInList(this.availableGlazingBeads, area.glazingBead.id);
                    area.filling.fillingId = undefined;
                    area.filling.decorativeFillingId = undefined;
                    if (area.filling.type === FillingType.FILLING) {
                        let filling = this.findValueInList(this.systemOtherFillings, fillingId);
                        if (filling != undefined) {
                            area.filling.fillingId = filling.id;
                            area.filling.externalColorId =
                                this.findIdInList(filling.externalColors, area.filling.externalColorId);
                            area.filling.internalColorId =
                                this.findIdInList(filling.internalColors, area.filling.internalColorId);
                        }
                    } else if (area.filling.type === FillingType.DECORATIVE_FILLING) {
                        let filling = this.findValueInList(this.systemDecorativeFillings, decorativeFillingId);
                        if (filling != undefined) {
                            area.filling.decorativeFillingId = filling.id;
                            area.filling.externalColorId =
                                this.findIdInList(filling.externalColors, area.filling.externalColorId);
                            area.filling.internalColorId =
                                this.findIdInList(filling.internalColors, area.filling.internalColorId);
                        }
                    }
                    for (let glassNumber = 1; glassNumber <= area.glazing.glazingGlassQuantity; ++glassNumber) {
                        area.glazing['glass' + glassNumber + 'id'] =
                            this.findIdInList(this.glasses, area.glazing['glass' + glassNumber + 'id']);
                    }
                    for (let glassNumber = area.glazing.glazingGlassQuantity + 1; glassNumber <= 4; ++glassNumber) {
                        area.glazing['glass' + glassNumber + 'id'] = undefined;
                    }
                    for (let frameNumber = 1; frameNumber < area.glazing.glazingGlassQuantity; ++frameNumber) {
                        area.glazing['frame' + frameNumber + 'id'] =
                            this.findIdInList(this.frames, area.glazing['frame' + frameNumber + 'id']);
                    }
                    for (let frameNumber = area.glazing.glazingGlassQuantity; frameNumber <= 3; ++frameNumber) {
                        area.glazing['frame' + frameNumber + 'id'] = undefined;
                    }
                    for (let grill of area.grills) {
                        grill.id = this.findIdInList(this.grills, grill.id);
                        if (grill.id != undefined) {
                            let grillColor = this.findValueInList(this.grillColors[grill.id], grill.colorId);
                            if (grillColor == undefined) {
                                grillColor = this.findValueInList(this.grillRalColors[grill.id], grill.colorId);
                                if (grillColor == undefined) {
                                    grillColor = this.findValueInList(this.grillNcsColors[grill.id], grill.colorId);
                                }
                                if (grillColor == undefined) {
                                    grill.colorId = undefined;
                                } else {
                                    this.grillColors[grill.id].push(grillColor);
                                }
                            }
                        } else {
                            grill.colorId = undefined;
                        }
                    }
                }
                for (let mullion of subWindow.mullions) {
                    mullion.id = this.findIdInList(this.systemMullions, mullion.id);
                }
                WindowAddonMapper.edit(subWindow.ventilator,
                    this.findIdInList(this.allAddons.availableVentilators, subWindow.ventilator &&
                        subWindow.ventilator.addonId));
                WindowAddonMapper.edit(subWindow.drip,
                    this.findIdInList(this.allAddons.availableDrips, subWindow.drip && subWindow.drip.addonId));
                WindowAddonMapper.edit(subWindow.coupler,
                    this.findIdInList(this.allAddons.availableCouplers, subWindow.coupler &&
                        subWindow.coupler.addonId));
            }
        }

        this.chosenCoreColor = this.findValueInList(this.allActiveColors, spec.colorIdCore);
        if (this.chosenCoreColor != undefined && !this.chosenCoreColor.core) {
            this.chosenCoreColor = undefined;
        }
        if (windowSystem != undefined) {
            profileCompositionDistances.prepareSystem(windowSystem);
            profileCompositionDistances.prepareProfileDistances(this.allActiveProfiles, spec);
        }
        this.resetAllGlazingBeadIfNeeded();
    }

    onSetAllFillingColor(colorId: number, colorType: FillingColorType) {
        switch (colorType) {
            case FillingColorType.CORE: {
                this.designer.setAllFillingColor(null, this.designer.commonData.coreColorId, colorType);
                break;
            }

            case FillingColorType.EXTERNAL: {
                if (this.designer.commonData.externalColorId != null) {
                    this.designer.setAllFillingColor(null, this.designer.commonData.externalColorId, colorType);
                } else {
                    this.setFillingColorInAllAreas(colorId, colorType);
                }

                break;
            }

            case FillingColorType.INTERNAL: {
                if (this.designer.commonData.internalColorId != null) {
                    this.designer.setAllFillingColor(null, this.designer.commonData.internalColorId, colorType);
                } else {
                    this.setFillingColorInAllAreas(colorId, colorType);
                }

                break;
            }
        }
    }

    private setFillingColorInAllAreas(colorId: number, colorType: FillingColorType) {
        for (let i = 0; i < this.designer.data.windows.length; i++) {
            for (let subWindow of this.designer.data.windows[i].subWindows) {
                for (let area of subWindow.areasSpecification) {
                    if (area.filling != null) {
                        this.setAreaFillingColorIfPresent(colorId, colorType, area.filling);
                    }
                }
            }
        }
    }

    private setAreaFillingColorIfPresent(colorId: number, colorType: FillingColorType, filling: Filling): void {
        if (FillingType.DECORATIVE_FILLING === filling.type) {
            this.setAreaDecorativeFillingColorIfPresent(colorId, colorType, filling);
        } else if (FillingType.FILLING === filling.type) {
            this.setAreaOtherFillingColorIfPresent(colorId, colorType, filling);
        }
    }

    private setAreaDecorativeFillingColorIfPresent(colorId: number, colorType: FillingColorType,
                                                   filling: Filling): void {
        let foundFilling: DecorativeFillingWithColors =
            this.systemDecorativeFillings.find(
                decorativeFilling => filling.decorativeFillingId === decorativeFilling.id);
        if (foundFilling != undefined) {
            switch (colorType) {
                case FillingColorType.EXTERNAL: {
                    if (colorId == undefined ||
                        foundFilling.externalColors.find(color => colorId === color.id) != undefined) {
                        filling.externalColorId = colorId;
                    }
                    break;
                }
                case FillingColorType.INTERNAL: {
                    if (colorId == undefined ||
                        foundFilling.internalColors.find(color => colorId === color.id) != undefined) {
                        filling.internalColorId = colorId;
                    }
                    break;
                }
            }
        }
    }

    private setAreaOtherFillingColorIfPresent(colorId: number, colorType: FillingColorType, filling: Filling): void {
        let foundFilling: OtherFillingWithColors =
            this.systemOtherFillings.find(otherFilling => filling.fillingId === otherFilling.id);
        if (foundFilling != undefined) {
            switch (colorType) {
                case FillingColorType.EXTERNAL: {
                    if (colorId == undefined ||
                        foundFilling.externalColors.find(color => colorId === color.id) != undefined) {
                        filling.externalColorId = colorId;
                    }
                    break;
                }
                case FillingColorType.INTERNAL: {
                    if (colorId == undefined ||
                        foundFilling.internalColors.find(color => colorId === color.id) != undefined) {
                        filling.internalColorId = colorId;
                    }
                    break;
                }
            }
        }
    }

    registerHotkeys() {
        this.hotkeysService.add(this.leftHotkey);
        this.hotkeysService.add(this.rightHotkey);
        this.hotkeysService.add(this.undoHotkey);
        this.hotkeysService.add(this.redoHotkey);
        this.hotkeysService.add(this.deleteHotkey);
        this.hotkeysService.add(this.escHotkey);
        this.hotkeysService.add(this.addonWindowHotkey);
    }

    deregisterHotkeys() {
        this.hotkeysService.remove(this.undoHotkey);
        this.hotkeysService.remove(this.redoHotkey);
        this.hotkeysService.remove(this.deleteHotkey);
        this.hotkeysService.remove(this.leftHotkey);
        this.hotkeysService.remove(this.rightHotkey);
        this.hotkeysService.remove(this.escHotkey);
        this.hotkeysService.remove(this.addonWindowHotkey);
    }

    private goToOffersList() {
        this.router.navigate(['features/offer']);
    }

    tabChanged(event): void {
        this.designer.selectedTabIndicator.display = false;
        this.tabView.tabs.forEach(tab => tab.selected = false);
        this.tabView.tabs[event.index].selected = true;
        let previousTabIndex = this.designer.currentlySelectedTab;
        this.designer.selectedTabChanged(event.index);
        this.designer.mapAddonsToWindowAddons();
        let visibleSubwindowsTabsCount = this.isTerrace ? 0 : this.designer.windowTabsData.length;
        if (event.index === 0) {
            // first (GENERAL) tab
            this.designer.setCommonValues();
            this.generalTabComponent.cd.markForCheck();
        } else if ((event.index === (visibleSubwindowsTabsCount + 1))) {
            // (PRICING) tab
            if (!this.validateRequiredFields(true)) {
                this.tabView.activeIndex = previousTabIndex;
                this.designer.selectedTabChanged(previousTabIndex);
                this.designer.isPricingCurrentTab = true;
            } else {
                this.pricingComponent.refresh(this.designer.data, this.designer.offerPosition.id,
                    this.designer.configurableAddonPositions, this.designer.profileCompositionDistances,
                    this.designer.isValidationDisabled(), this.allConfigAddonDefinitions, this.grills,
                    this.designer.offerPosition.validationDisabled);
            }
        } else if ((event.index === (visibleSubwindowsTabsCount + 2))) {
            // (Validation) tab
            if (!this.validateRequiredFields(true)) {
                this.tabView.activeIndex = previousTabIndex;
                this.designer.selectedTabChanged(previousTabIndex);
            } else {
                this.redrawWindow();
                this.validationComponent.validate(this.designer.data, this.designer.offerPosition.offerId,
                    this.designer.offerPosition.id, this.designer.configurableAddonPositions, this.designer.getWindowSystem(),
                    this.designer.profileCompositionDistances, this.allConfigAddonDefinitions, this.grills,
                    this.designer.offerPosition.validationDisabled);
            }
        } else {
            this.designer.moveSelectedTabIndicator(event.index);
        }
        this.redrawWindow();
    }

    shouldBeSelected(tabIndex): boolean {
        return this.designer.wasTabSelected(tabIndex);
    }

    protected saveValues(restoreKey: string, keys: string[]): void {
        if (!this.savedSettings[restoreKey]) {
            this.savedSettings[restoreKey] = {};
        }

        for (let key of keys) {
            if (this.designer.pendingGrillData.grill[key]) {
                this.savedSettings[restoreKey][key] = this.designer.pendingGrillData.grill[key];
            }
        }

        this.saveUserUiConfigForThisView(this.GRILL_SELECTIONS_UI_KEY, this.savedSettings);
    }

    private restoreValues(restoreKey: GrillType, ignoreSavedSettings = false): void {
        if (!ignoreSavedSettings && this.savedSettings[restoreKey]) {
            let obj = this.savedSettings[restoreKey];
            for (let property in obj) {
                if (obj.hasOwnProperty(property)) {
                    this.designer.pendingGrillData.grill[property] = obj[property];
                }
            }
            this.designer.pendingGrillData.grill.id = this.findIdInList(this.grills, this.designer.pendingGrillData.grill.id);
        }
        if (this.designer.pendingGrillData.grill.id == null) {
            switch (restoreKey) {
                case GrillType.MULLION:
                    this.designer.pendingGrillData.grill.id = this.systemMullions.length > 0 ? this.systemMullions[0].id : undefined;
                    break;
                case GrillType.LINE_GRILL:
                case GrillType.GRID_CROSS_SIMPLE:
                case GrillType.GRID_STANDARD:
                    this.designer.pendingGrillData.grill.id = this.grills.length > 0 ? this.grills[0].id : undefined;
                    break;
                case GrillType.GRID_CROSS_ANGLED:
                case GrillType.GRID_RHOMBUS:
                    this.designer.pendingGrillData.grill.id = this.angledGrills.length > 0 ? this.angledGrills[0].id : undefined;
                    break;
            }
            if (!ignoreSavedSettings && this.designer.pendingGrillData.grill.id != null) {
                this.saveValues(GrillType[restoreKey], ['id']);
            }
        }
        if (restoreKey !== GrillType.MULLION) {
            this.initPendingGrillColor();
        }
    }

    deleteSelectedItem() {
        this.designer.deleteSelectedItems();
    }

    addConfigAddon(application: ConfigAddonApplication) {
        this.configurableAddonPosition = undefined;
        let windowSystemId = this.designer.data.windowSystemId;
        this.configurableAddonDialogData = new AddConfigurableAddonDialogData(application, windowSystemId,
            application === ConfigAddonApplication.WINDOW ? 1 : this.designer.clickedSnapElements.elements.length);
        this.dialogType = DialogType.ADD_CONFIGURABLE_ADDON;
    }

    editConfigAddon(addonModel: ConfigurableAddonPositionModel) {
        this.configurableAddonPosition = addonModel.position;
        let addon = addonModel.configurableAddon;
        let windowSystemId = this.designer.data.windowSystemId;
        this.configurableAddonDialogData =
            new AddConfigurableAddonDialogData(addon.application, windowSystemId, 1, addon.openings);
        this.dialogType = !addonModel.deprecated ? DialogType.EDIT_CONFIG_ADDON : DialogType.EDIT_CONFIGURABLE_ADDON;
    }

    isViewingDeprecatedConfigAddon(): boolean {
        return this.dialogType === DialogType.EDIT_CONFIGURABLE_ADDON;
    }

    removeConfigAddon() {
        this.designer.saveStepInHistory();
        this.generalTabComponent.cd.markForCheck();
        this.redrawWindow();
    }

    addStandaloneGlazingPackage(): void {
        if (this.windowSystemDefaults == undefined || this.windowSystemDefaults.value == undefined) {
            return;
        }
        const areas = this.designer.clickedSnapElements.elements.filter(element => {
            const area: AreaSpecification = element.data(DataKeys.AREA);
            if (!area.rectangularShape) {
                // has cuts, ignore
                return false;
            }
            if (area.filling.type !== FillingType.GLASS) {
                return false;
            }
            if (this.designer.glazingPackagePositions.find(gpp => gpp.window.glazingPackageForAreaId === area.generatedId)) {
                // already has a glazing package, ignore
                return false;
            }
            return true;
        }).map(element => element.data(DataKeys.AREA) as AreaSpecification);

        if (areas.length === 0) {
            return;
        }

        const blockId = 'WindowEditorComponent.addStandaloneGlazingPackage';
        this.blockUiController.block(blockId);

        const windowSystem = this.designer.getWindowSystem();
        this.windowSystemDefinitionService.getWindowSystemToUseForStandaloneGlazingPackage(windowSystem.id).pipe(
            mergeMap(windowSystemId => forkJoin({
                windowSystem: windowSystemId !== windowSystem.id
                    ? this.windowSystemDefinitionService.getSystem(windowSystemId)
                    : of(windowSystem),
                defaults: this.windowSystemDefaultsService.getDefaultsForWindow(windowSystemId, this.offer.id, false, true),
                profiles: this.windowSystemDefinitionService.getSystemsProfiles(windowSystemId)
            }))
        ).subscribe({
            next: data => {
                const profileCompositionDistances = new ProfilesCompositionDistances();
                profileCompositionDistances.prepareSystem(data.windowSystem);
                profileCompositionDistances.prepareProfileDistances(data.profiles.data, data.defaults.value);
                for (const area of areas) {
                    const drawingData = AbstractWindowDesigner.createDrawingDataForFWindowForGlazingPackageFromArea(
                        area.realPackageWidth, area.realPackageHeight, data.windowSystem,
                        profileCompositionDistances, data.defaults.value,
                        this.designer.data.specification.sealInternalId, this.designer.data.specification.sealExternalId, area);

                    const position = PositionFactory.standaloneGlazingPackage(this.offer.id);
                    position.data = JSON.stringify(drawingData);
                    this.designer.glazingPackagePositions.push(new GlazingPackagePositionModel(position, drawingData));
                    this.designer.unsavedChanges = true;
                }
                this.blockUiController.unblock(blockId);
                this.growls.info('OFFER.POSITIONS.STANDALONE_GLAZING_PACKAGE_ADDED');
            }, error: () => {
                this.blockUiController.unblock(blockId);
            }
        });
    }

    cancelMode(): void {
        if (this.designer.mode === Tool.SELECT) {
            return;
        }
        this.currentMode = undefined;
        this.resetSelectedElements();
        this.cancelAddingGrill();
        this.designer.cancelMode();
        this.resetDesignerTopPadding();
    }

    private resetDesignerTopPadding() {
        document.getElementById('window-designer-container').style.paddingTop = '';
    }

    exit(): void {
        if (this.designer.unsavedChanges) {
            this.showExitWithoutSavingConfirmationDialog = true;
        } else {
            this.showExitWithoutSavingConfirmationDialog = false;
            this.designer.exit();
        }
    }

    exitWithoutSaving(): void {
        this.designer.exit();
    }

    openSettings(): void {
        this.showSettingsDialog = true;
    }

    saveSettings(setting: string): void {
        this.settings[setting] = !this.settings[setting];
        this.designer.setVisibilitySettings(this.settings);
        this.redrawWindow();
        this.saveUserUiConfigForThisView("visibilitySettings", this.settings);
    }

    saveAndExit(): void {
        let continueWithSave = true;
        if (document.activeElement) {
            if (document.activeElement.id === GeneralTabComponent.TOTAL_HEIGHT_INPUT_ID) {
                continueWithSave = false;
                this.generalTabComponent.totalSizeChanged(SizeProp.TOTAL_HEIGHT, true);
            } else if (document.activeElement.id === GeneralTabComponent.TOTAL_WIDTH_INPUT_ID) {
                continueWithSave = false;
                this.generalTabComponent.totalSizeChanged(SizeProp.TOTAL_WIDTH, true);
            } else if (document.activeElement.id.startsWith(VeneerComponent.VENEER_HEIGHT_INPUT_ID)) {
                continueWithSave = false;
                const veneerComponent = this.subWindowComponents.reduce((a: VeneerComponent[], b) => a.concat(b.allVeneers.toArray()), [])
                    .find(veneer => document.activeElement.id.endsWith(veneer.mullion.generatedId));
                if (veneerComponent != undefined) {
                    const veneerEvent = veneerComponent.generateVeneerEvent();
                    if (veneerEvent != undefined) {
                        this.veneerChangeEvent(veneerEvent)
                            .subscribe(() => this.doSaveAndExit());
                    }
                }
            }
        }
        if (continueWithSave) {
            this.doSaveAndExit();
        }
    }

    public updateMousePosition(position: Point): void {
        jQuery('#pointer-position-x').text('' + position.x);
        jQuery('#pointer-position-y').text('' + position.y);
    }

    public onSubwindowFrameChange(event: GlassChangeEvent) {
        this.filterGlazingBeads(event.area, event.subwindowId);
        this.checkGlazingItemsAvailability(this.frames.filter(frame => !frame.active), GlazingItemAttr.FRAME);
    }

    public doSaveAndExit() {
        if (!this.generalTabComponent) {
            return;
        }
        if (this.validateRequiredFields(true)) {
            this.designer.saveAndExit();
        }
    }

    public validateRequiredFields(validationPricingOrSaveClicked = false): boolean {
        if (this.generalTabComponent == undefined || this.designer == undefined || !this.sidebarInitialized) {
            setTimeout(() => {
                this.requiredFieldFilled = false;
                this.changeDetector.markForCheck();
            });
            // no need to go further in this case..
            return false;
        }
        let unavailableItemsSelected: string[] = [];
        if (validationPricingOrSaveClicked) {
            this.highlightValidationErrors = true;
            this.generalTabComponent.setValidateAll(true);
            let visibleFields = this.generalTabComponent.visibleFields;
            this.subWindowComponents.forEach(component => {
                component.setValidateAll(true);
                visibleFields = _.union(visibleFields, component.visibleFields);
            });
            this.recheckRequiredFieldsOnMainTab();
            this.requiredFieldsOnSubWindowsFilled();
            this.generalTabComponent.markForCheck();
            this.subWindowComponents.forEach(component => component.markForCheck());
            unavailableItemsSelected = this.windowEditorFieldContentProvider.fieldsWithUnavailableValuesPresent(visibleFields);
        }
        let stringErrors: string[] = [];
        stringErrors.push(...this.generalTabComponent.fieldUsage.getAllErrors());
        let fieldToFocus = this.generalTabComponent.fieldUsage.fieldToFocusOnError;

        let growlErrors: GrowlMessage[] = _.flatten(this.subWindowComponents.map(swc => swc.fieldUsage.getAllErrors(
            this.windowEditorFieldContentProvider.fieldsWithUnavailableValues)));
        _.flatten(this.subWindowComponents.filter(swc => !swc.validVeneers())).forEach(swc => {
            growlErrors.push(new GrowlMessage('error', 'GENERAL.ERROR', 'OFFER.TABS.ERROR.NOT_SET_DETAILED.VENEER', {}));
        });
        if (this.highlightValidationErrors) {
            if (!this.designer.offerPosition.validationDisabled) {
                if (growlErrors.length === 0) {
                    stringErrors.push(...this.getFillingsWidthsValidationErrors());
                }
                if (!VentilationUtils.validateAll(this.designer.data.windows, this.designer.data.cuts,
                    this.designer.totalBoundingBox, this.designer.profileCompositionDistances)) {
                    stringErrors.push('OFFER.TABS.ERROR.VENTILATION_NOT_IN_RANGE');
                }
                if (!this.glassesPositionsAreCorrect()) {
                    stringErrors.push('OFFER.TABS.ERROR.WRONG_GLASS_POSITION');
                }
            }
            if (validationPricingOrSaveClicked) {
                this.focusOnErrorField(fieldToFocus, unavailableItemsSelected, stringErrors);
                stringErrors.forEach(e => this.growls.error(e));
                growlErrors.forEach(e => this.growls.show(e));
            }
        }
        const result = stringErrors.length === 0 && growlErrors.length === 0;
        setTimeout(() => {
            this.requiredFieldFilled = result;
            this.changeDetector.markForCheck();
        });
        return result;
    }

    private focusOnErrorField(fieldToFocus: string, unavailableItemsSelected: string[],
                              stringErrors: string[]) {
        let tabIndex = 0;
        let swcWithUnavailable: SubwindowComponent;
        let areaNumber: number = null;
        if (unavailableItemsSelected.length > 0) {
            const field = unavailableItemsSelected[0];
            stringErrors.push('OFFER.TABS.ERROR.UNAVAILABLE_ITEMS_PRESENT');
            if (SidebarIdGenerator.fieldExistsInSubwindow(field)) {
                let areaWithUnavailable: string;
                if (field === WindowEditorField.GLASS || field === WindowEditorField.DISTANCE_FRAME) {
                    const unavailableItems = field === WindowEditorField.GLASS ? this.glasses.filter(glass => !glass.active)
                        : this.frames.filter(frame => !frame.active);
                    const attr = field === WindowEditorField.GLASS ? GlazingItemAttr.GLASS : GlazingItemAttr.FRAME;
                    areaWithUnavailable = this.checkGlazingItemsAvailability(unavailableItems, attr);
                } else {
                    areaWithUnavailable = this.checkItemsAvailability(field);
                }
                if (areaWithUnavailable) {
                    fieldToFocus = field;
                    this.subWindowComponents.forEach(swc => {
                        swc.windowData.areasSpecification.forEach(area => {
                            if (area.generatedId === areaWithUnavailable) {
                                tabIndex = swc.tabIndex + 1;
                                swcWithUnavailable = swc;
                            }
                        });
                    });
                    areaNumber = swcWithUnavailable.windowData.areasSpecification.findIndex(area =>
                        area.generatedId === areaWithUnavailable);
                }
            }
            fieldToFocus = unavailableItemsSelected[0];
        }
        if (fieldToFocus) {
            if (fieldToFocus === WindowEditorField.DISTANCE_FRAME || fieldToFocus === WindowEditorField.GLASS) {
                fieldToFocus = WindowEditorField.GLASS_SELECTOR;
            }
            this.tabChanged({index: tabIndex});
            if (tabIndex === 0) {
                const elementType = SidebarIdGenerator.getTabForInputElement(fieldToFocus);
                this.selectAccordionOnGeneralTab(elementType);
            } else {
                this.selectAccordionOnSubWindowTab(fieldToFocus, areaNumber, swcWithUnavailable);
            }
            setTimeout(() => {
                let htmlElement = document.getElementById(fieldToFocus + '_id');
                if (htmlElement != undefined) {
                    htmlElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest'
                    });
                }
                fieldToFocus = undefined;
                this.clearFieldToFocus();
            }, 0);
        }
    }

    private clearFieldToFocus() {
        if (this.generalTabComponent.fieldUsage.fieldToFocusOnError != undefined) {
            this.generalTabComponent.fieldUsage.fieldToFocusOnError = undefined;
        } else {
            for (let swc of this.subWindowComponents.toArray()) {
                if (swc.fieldUsage.fieldToFocusOnError != undefined) {
                    swc.fieldUsage.fieldToFocusOnError = undefined;
                } else {
                    break;
                }
            }
        }
    }

    onSubwindowFillingChange(areaNumber: number, subwindow: SubWindowData): void {
        if (areaNumber != undefined) {
            this.filterGlazingBeads(subwindow.areasSpecification[areaNumber], subwindow.generatedId);
            this.checkItemsAvailability(WindowEditorField.GLAZING_BEAD);
        }
        this.requiredFieldsOnSubWindowsFilled();
    }

    requiredFieldsOnSubWindowsFilled(): boolean {
        let newAllTabsFieldsFilled = new Array(this.designer.windowTabsData.length).fill(false);

        for (let i = 0; i < this.designer.windowTabsData.length; i++) {
            let swComponent = this.subWindowComponents.find(
                swc => swc.windowData.generatedId === this.designer.windowTabsData[i].windowData.generatedId);
            let allVeneersValid = _.flatten(this.subWindowComponents.filter(swc => !swc.validVeneers())).length === 0;
            newAllTabsFieldsFilled[i] =
                swComponent == undefined || (swComponent.fieldUsage.getAllErrors(
                    this.windowEditorFieldContentProvider.fieldsWithUnavailableValues).length === 0 && allVeneersValid);
        }
        this.allTabsFieldsFilled = newAllTabsFieldsFilled;

        return _.compact(this.allTabsFieldsFilled).length === this.designer.windowTabsData.length;
    }

    private glassesPositionsAreCorrect(): boolean {
        let glassMap = {};
        for (let glass of this.glasses) {
            glassMap[glass.id] = glass;
        }

        let glassCanBeAtPosition = (glass, position, glassesInGlazingCount) => {
            return glass['position' + position + 'of' + glassesInGlazingCount];
        };

        let frameCanBeAtPosition = (frame, position, glassesInGlazingCount) => {
            return frame.positions.findIndex(p => p.position === position && p.glassesInGlazingCount === glassesInGlazingCount) !== -1;
        };

        for (let currentWindowIndex = 0; currentWindowIndex < this.designer.data.windows.length; currentWindowIndex++) {
            let currentWindow = this.designer.data.windows[currentWindowIndex];
            for (let currentSubWindowIndex = 0; currentSubWindowIndex < currentWindow.subWindows.length; currentSubWindowIndex++) {
                let subWindow = currentWindow.subWindows[currentSubWindowIndex];
                for (let currentAreaIndex = 0; currentAreaIndex < subWindow.areasSpecification.length; currentAreaIndex++) {
                    let area = subWindow.areasSpecification[currentAreaIndex];
                    if (area.filling.type === FillingType.GLASS) {
                        const glassMax = area.glazing.glazingGlassQuantity + 1;
                        for (let currentGlazingIndex = 1; currentGlazingIndex < glassMax; currentGlazingIndex++) {
                            let currentGlass = glassMap[GlazingHelper.getNthGlass(area.glazing, currentGlazingIndex)];

                            if (currentGlass !== undefined) {
                                if (!glassCanBeAtPosition(currentGlass, currentGlazingIndex,
                                    (area.glazing.glazingGlassQuantity))) {
                                    console.info("Glass '" + currentGlass.name[this.designer.translate.currentLang] +
                                        "' cannot be in position " + currentGlazingIndex + 'of' +
                                        (area.glazing.glazingGlassQuantity) + ". Position: (window: " +
                                        (currentWindowIndex + 1) + ", sub-window: " + (currentSubWindowIndex + 1) +
                                        ", area: " + (currentAreaIndex + 1) + ").");
                                    return false;
                                }
                            }

                            if (currentGlazingIndex < area.glazing.glazingGlassQuantity) {
                                let frameId = GlazingHelper.getNthFrame(area.glazing, currentAreaIndex);
                                let currentFrame = this.frames.find(frame => frame.id === frameId);
                                if (currentFrame != undefined) {
                                    if (!frameCanBeAtPosition(currentFrame, currentGlazingIndex, area.glazing.glazingGlassQuantity)) {
                                        console.info("Frame '" + currentFrame.names[this.designer.translate.currentLang] +
                                            "' cannot be in position " + currentGlazingIndex + 'of' +
                                            (area.glazing.glazingGlassQuantity) + ". Position: (window: " +
                                            (currentWindowIndex + 1) + ", sub-window: " + (currentSubWindowIndex + 1) +
                                            ", area: " + (currentAreaIndex + 1) + ").");
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return true;
    }

    private getFillingsWidthsValidationErrors(): string[] {
        let errors: string[] = [];
        let areas: AreaSpecification[] = _.flatten(
            this.designer.data.windows.map(w => w.subWindows.map(sw => sw.areasSpecification)));
        let allowedGlazingWidthsForWindowSystem = GlazingHelper.parseGlazingWidths(this.designer.getWindowSystem().glazingWidths);
        for (let area of areas) {
            let allowedGlazingWidths;
            let glazingWidth;
            let glazingErrorSuffix = 'pakiet_out_of_range';
            let allowedFillingWidths;
            let fillingWidth;
            if (this.isTerrace && area.filling.type == null) {
                errors.push(`error.validation.glazing_not_null`);
            } else {
                switch (area.filling.type) {
                    case FillingType.DECORATIVE_FILLING:
                        let decorativeFilling = this.systemDecorativeFillings.find(f => f.id === area.filling.decorativeFillingId);
                        if (decorativeFilling) {
                            allowedGlazingWidths = GlazingHelper.parseGlazingWidths(decorativeFilling.glazingWidths);
                            glazingWidth = GlazingHelper.getGlazingTotalWidth(area.glazing, this.glasses, this.frames);
                            allowedFillingWidths = allowedGlazingWidthsForWindowSystem;
                            fillingWidth = decorativeFilling.thickness;
                        }
                        break;
                    case FillingType.FILLING:
                        let filling = this.systemOtherFillings.find(f => f.id === area.filling.fillingId);
                        if (filling) {
                            allowedFillingWidths = allowedGlazingWidthsForWindowSystem;
                            fillingWidth = filling.thickness;
                        }
                        break;
                    case FillingType.GLASS:
                        allowedGlazingWidths = allowedGlazingWidthsForWindowSystem;
                        glazingWidth = GlazingHelper.getGlazingTotalWidth(area.glazing, this.glasses, this.frames);
                        break;
                    case FillingType.NO_FILLING:
                        allowedGlazingWidths = allowedGlazingWidthsForWindowSystem;
                        glazingWidth = area.filling.width;
                        glazingErrorSuffix = 'brak_wypelnienia_out_of_range';
                        break;
                    default:
                        throw new Error('validateFillingsWidths - Unsupported filling type: ' + area.filling.type);
                }
            }

            if (glazingWidth && GlazingHelper.isGlazingWithOutOfRange(allowedGlazingWidths, glazingWidth)) {
                errors.push(`error.validation.${glazingErrorSuffix}`);
            }
            if (fillingWidth && GlazingHelper.isGlazingWithOutOfRange(allowedFillingWidths, fillingWidth)) {
                errors.push('error.validation.filling_out_of_range');
            }
        }
        return _.uniq(errors);
    }

    protected cancelAddingGrill(): void {
        this.hideSpecificToolOptions();
        this.designer.cancelAddingGrill();
    }

    enableCutMode(): void {
        this.cancelMode();
        this.resetSelectedElements();
        this.designer.enableCutMode();
    }

    enableHandleMode(direction: HandleDirection): void {
        this.cancelMode();
        this.resetSelectedElements();
        this.designer.enableHandleMode(direction);
    }

    undoLast(): void {
        this.removeSelectionFromDivs();
        let currentWindowSystemId = this.designer.data.windowSystemId;
        this.designer.undoLast(() => {
            this.requiredFieldsOnSubWindowsFilled();
            this.recheckRequiredFieldsOnMainTab();
            let restoredWindowSystemId = this.designer.data.windowSystemId;
            if (currentWindowSystemId !== restoredWindowSystemId) {
                this.onWindowSystemChange();
            } else {
                this.generalTabComponent.refreshSelectedFields();
                this.validateRequiredFields();
            }
        });
    }

    redoLast(): void {
        this.removeSelectionFromDivs();
        let currentWindowSystemId = this.designer.data.windowSystemId;
        this.designer.redoLast(() => {
            this.requiredFieldsOnSubWindowsFilled();
            this.recheckRequiredFieldsOnMainTab();
            let restoredWindowSystemId = this.designer.data.windowSystemId;
            if (currentWindowSystemId !== restoredWindowSystemId) {
                this.onWindowSystemChange();
            }
            this.generalTabComponent.refreshSelectedFields();
            this.validateRequiredFields();
        });
    }

    resetSelectedElements(): void {
        this.removeSelectionFromDivs();
        this.designer.resetSelectedElements();
        this.redrawWindow(); // needed to remove tool guides
    }

    finalizeAddingGrill(): void {
        if (this.designer.finalizeAddingGrill()) {
            // Refresh values in grill section of the global sidebar tab
            this.refilterIntersectingGrillColors();
            this.designer.setCommonValues();
        }
    }

    private hideSpecificToolOptions() {
        this.showSettingsDialog = false;
    }

    onDrawingDataChanged(changes: DrawingDataFieldChange[]) {
        if (this.generalTabComponent) {
            this.validateRequiredFields();
            this.generalTabComponent.cd.markForCheck();
        }
        if (this.subWindowComponents) {
            this.subWindowComponents.forEach(swc => swc.cd.markForCheck());
        }
        for (let change of changes) {
            this.processDrawingDataFieldChange(change);
        }
    }

    private processDrawingDataFieldChange(change: DrawingDataFieldChange): void {
        const field = ((): WindowEditorField | undefined => {
            if (change.path.includes('typeCode') || change.path === 'windows') {
                this.designer.emitCommonBusinessType(this.designer.data);
                return undefined;
            }
            switch (change.path) {
                    // return WindowEditorField.QUANTITY;
                case 'windowSystemId':
                    return WindowEditorField.WINDOW_SYSTEM;
                    // return WindowEditorField.WIDTH;
                    // return WindowEditorField.HEIGHT;
                case 'specification.frameProfileId':
                    return WindowEditorField.PROFILE;
                case 'specification.weldType.addonId':
                    return WindowEditorField.WELD_TYPE;
                case 'specification.frameEnhancement.addonId':
                    return WindowEditorField.FRAME_ENHANCEMENT;
                case 'view':
                    return WindowEditorField.VIEW;
                case 'specification.windowFunction':
                    return WindowEditorField.WINDOW_FUNCTION;
                case 'specification.cover.addonId':
                    return WindowEditorField.COVERS;
                case 'specification.milling.addonId':
                    return WindowEditorField.MILLINGS;
                case 'specification.millingNorwegian.addonId':
                    return WindowEditorField.MILLINGS_NORWEGIAN;
                case 'specification.underWindowBead.addonId':
                    return WindowEditorField.UNDER_WINDOW_BEAD;
                case 'specification.handleType.addonId':
                    return WindowEditorField.HANDLES;
                case 'specification.underwindowProfile.addonId':
                    return WindowEditorField.UNDERWINDOW_PROFILE;
                case 'specification.doorstepId':
                    return WindowEditorField.DOORSTEP;
                case 'specification.opening':
                    return WindowEditorField.OPENING;
                case 'specification.sealInternalId':
                    return WindowEditorField.SEALS_INTERNAL;
                case 'specification.sealExternalId':
                    return WindowEditorField.SEALS_EXTERNAL;
                case 'specification.colorIdCore':
                    return WindowEditorField.CORE_COLOR;
                case 'specification.colorIdExternal':
                    return this.isTerrace ? WindowEditorField.TERRACE_COLOR : WindowEditorField.EXTERNAL_COLOR;
                case 'specification.colorIdInternal':
                    return WindowEditorField.INTERNAL_COLOR;
                    // return WindowEditorField.FILLING_WIDTH;
                    // return WindowEditorField.FILLING_NAME;
                    // return WindowEditorField.FILLING_NAME_EXTERNAL;
                    // return WindowEditorField.FILLING_NAME_INTERNAL;
                    // return WindowEditorField.DECORATIVE_FILLING;
                    // return WindowEditorField.FILLING_INTERNAL_COLOR;
                    // return WindowEditorField.FILLING_EXTERNAL_COLOR;
                    // return WindowEditorField.GLAZING_BEAD;
                case 'specification.fittingBrake.addonId':
                    return WindowEditorField.FITTING_BRAKE;
                case 'specification.fittingSliding.addonId':
                    return WindowEditorField.FITTING_SLIDING;
                case 'specification.fittingType.addonId':
                    return WindowEditorField.FITTING_TYPE;
                case 'specification.fittingEspagnoletteType.addonId':
                    return WindowEditorField.FITTING_ESPAGNOLETTE_TYPE;
                case 'specification.fittingVeranda.addonId':
                    return WindowEditorField.FITTING_VERANDA;
                case 'specification.fittingInsertion.addonId':
                    return WindowEditorField.FITTING_INSERTION;
                case 'specification.fittingMainInsertion.addonId':
                    return WindowEditorField.FITTING_MAIN_INSERTION;
                case 'specification.fittingAdditionalInsertion.addonId':
                    return WindowEditorField.FITTING_ADDITIONAL_INSERTION;
                case 'specification.fittingLock.addonId':
                    return WindowEditorField.FITTING_LOCK;
                case 'specification.fittingLockTerrace.addonId':
                    return WindowEditorField.FITTING_LOCK_TERRACE;
                case 'specification.fittingLockTerrace':
                    return WindowEditorField.FITTING_LOCK_TERRACE_LOCATION;
                    // return WindowEditorField.SHAPE_TYPE;
                    // return WindowEditorField.SHAPE_TYPE_NOT_RECTANGULAR;
                    // return WindowEditorField.SHAPE_TYPE_ARC;
                    // return WindowEditorField.FILLING_TYPE;
                    // return WindowEditorField.FILLING_TYPE_W_MUNTINS;
                    // return WindowEditorField.FILLING_TYPE_WO_MUNTINS;
                    // return WindowEditorField.GLASS_SELECTOR;
                    // return WindowEditorField.GLASS;
                    // return WindowEditorField.DISTANCE_FRAME;
                    // return WindowEditorField.GRILL;
                    // return WindowEditorField.GRILL_ANGLED;
                    // return WindowEditorField.GRILL_COLOR;
                    // return WindowEditorField.MULLION;
                    // return WindowEditorField.MULLION_ANGLED;
                    // return WindowEditorField.VENTILATOR;
                    // return WindowEditorField.DRIP;
                    // return WindowEditorField.COUPLER;
                case 'businessType':
                    return WindowEditorField.BUSINESS_TYPE;
                    // return WindowEditorField.DIMENSIONS;
                    // return WindowEditorField.WEBSHOP_MULLIONS_POSITION;
                    // return WindowEditorField.EXTERNAL_COLOR_OTHER_INFO;
                    // return WindowEditorField.INTERNAL_COLOR_OTHER_INFO;
                    // return WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS;
                case 'specification.fittingAutomaticDrive.addonId':
                    return WindowEditorField.FITTING_AUTOMATIC_DRIVE;
                    // return WindowEditorField.FLASHING;
                    // return WindowEditorField.FLASHING_AKP;
                    // return WindowEditorField.DECORATIVE_FILLING_FLIP;
                    // return WindowEditorField.WEBSHOP_GLAZING_PACKAGE;
                    // return WindowEditorField.WEBSHOP_CHARGE_NAME;
                case "specification.terraceGlazingPackageId":
                    return WindowEditorField.TERRACE_GLAZING_PACKAGE;
                case 'specification.terraceHandle.addonId':
                    return WindowEditorField.TERRACE_HANDLE;
                case 'specification.terraceHandleLayout':
                    return WindowEditorField.TERRACE_HANDLE_LAYOUT;
                default:
                    return undefined;
            }
        })();
        if (field != undefined) {
            this.windowEditorFieldContentProvider.notifyFieldChanged(field, change.newValue);
        }
    }

    windowShapeMenuSelected(shape: string) {
        let identifier = WindowShapeType[shape];
        this.resetSelectedElements();
        this.designer.changeWindowShape(identifier);
    }

    initGrillMode(mode: GrillType): void {
        this.currentMode = mode;
        this.designer.initPendingGrill(mode);
        this.restoreValues(mode);
        this.designer.setPendingGrillOrMullionData();
        this.designer.isErrorsPresent();
        this.redrawWindow();
    }

    // Sidebar
    afterDesignerInitialized(loadCatalog: boolean) {
        if (loadCatalog) {
            this.initSystemDefaults(new WindowSystemDefaultsState());
        }
        this.dataLoadingStatus.catalog = true;
        this.blockUiController.block('WindowEditorAfterInit');
        let offerId = undefined;
        if (!this.sidebarOnlyMode) {
            offerId = this.designer.offerPosition.offerId;
        }
        this.initWindowSystemGlazingWidths();
        if (this.designer.data.windowSystemId && (this.sidebarOnlyMode || this.newOffer)) {
            this.windowSystemDefaultsService.getDefaultsForWindow(this.designer.data.windowSystemId, offerId, this.modelMode).subscribe(
                defaults => {
                    this.windowSystemDefaults = defaults;
                    this.catalogInitialization(loadCatalog, offerId);
                }
            );
        } else {
            this.catalogInitialization(loadCatalog, offerId);
        }
    }

    private addEditedWindowSystemIfItIsCurrentlyUnavailable(editedWindowSystemId: number,
                                                            editedWindowSystem: WindowSystemDefinition): void {
        if (!this.filteredWindowSystems && editedWindowSystemId) {
            this.filteredWindowSystems = [editedWindowSystem];
        } else if (this.filteredWindowSystems && editedWindowSystemId
            && !this.filteredWindowSystems.find(system => system.id === editedWindowSystemId)) {
            this.filteredWindowSystems.push(editedWindowSystem);
        }
    }

    changeHotkeyRegistration(displayDialogVisible: boolean): void {
        if (displayDialogVisible) {
            this.deregisterHotkeys();
        } else {
            this.registerHotkeys();
        }
    }

    windowElementSelected(event: { data: { type: string, elements: Snap.Element[] } }): void {
        this.drawingToolsControl.markForCheck();
        this.removeSelectionFromDivs();
        if (!this.sidebarOnlyMode && this.tabView && this.mainTab) {
            let selectedTabIndex = 0;
            let clickedElements: { type: string, elements: Snap.Element[] } = event.data;
            if (clickedElements.elements && clickedElements.elements.length > 0) {
                this.tabView.tabs.forEach(t => t.selected = false);
                switch (clickedElements.type) {
                    case WindowParams.FRAME_ELEM:
                        let windows = this.designer.data.windows;
                        if (windows.length === 1 && windows[0].subWindows.length === 1) {
                            this.selectAccordionOnGeneralTab(clickedElements.type);
                            selectedTabIndex = 0;
                            break;
                        }
                        selectedTabIndex =
                            this.selectAccordionForElementsType(clickedElements.elements, clickedElements.type);
                        break;
                    case WindowParams.GLASS_ELEM:
                    case WindowParams.MUNTIN_ELEM:
                    case WindowParams.MULLION_ELEM:
                    case WindowParams.VENTILATION_ELEM:
                    case WindowParams.GLAZING_BEAD_ELEM:
                        selectedTabIndex =
                            this.selectAccordionForElementsType(clickedElements.elements, clickedElements.type);
                        break;
                    case WindowParams.WING_ELEM:
                    case WindowParams.HANDLE_ELEM:
                        this.selectAccordionOnGeneralTab(clickedElements.type);
                        selectedTabIndex = 0;
                        break;
                    default:
                        let err = new Error("Unsupported element type selected: " + clickedElements.type);
                        err.name = ErrorNames.SELECTED_ELEMENT_UKNOWN_TYPE;
                        throw err;
                }
            }
            if (this.generalTabComponent) {
                this.generalTabComponent.cd.markForCheck();
            }
            this.tabChanged({index: this.isTerrace ? 0 : selectedTabIndex});
        }
    }

    private tabNameMatch(tab: AccordionTab, areaIndex: number, tabName: string): boolean {
        let tabInfo = JSON.parse(tab.id);
        return tabInfo.area === areaIndex && tabInfo.subname === tabName;
    }

    private selectAccordionTabsForClickedObject(sw: SubWindowData, areaIndex: number, tabName: string) {
        let swc = this.subWindowComponents.find(c => c.windowData.generatedId === sw.generatedId);
        let acc = swc.allAccordions.find(a => a.tabs.some(t => this.tabNameMatch(t, areaIndex, tabName)));
        if (acc == undefined) {
            return;
        }
        let tab = acc.tabs.find(t => this.tabNameMatch(t, areaIndex, tabName));
        acc.activeIndex = acc.tabs.indexOf(tab);
        if (areaIndex != null && tabName != null) {
            // select parent tab aswell
            this.selectAccordionTabsForClickedObject(sw, areaIndex, null);
        } else {
            swc.markForCheck();
        }
    }

    private loadSystemsFilteredByExistingBusinessTypesAfterAddingWindow(): void {
        let existingTypes = this.designer.data.windows.map(w => WindowTypeCode[w.typeCode]);
        if (existingTypes.length > 0) {
            this.dataLoadingStatus.windowSystemsByBusinessTypes = true;
            this.blockUiController.block('WindowEditorSystemsByBusinessTypes');
            const windowSystem = this.designer.getWindowSystem();
            this.businessTypeService.getWindowSystemsByBusinessTypes(_.uniq(existingTypes),
                this.getSystemTypesFilter().filter(t => t.doors === windowSystem.doors)).pipe(
                finalize(() => this.blockUiController.unblock('WindowEditorSystemsByBusinessTypes')))
                .subscribe({
                    next: data => {
                        console.info('`GetWindowSystemsByBusinessTypes` success:');
                        this.filterAvailableWindowSystems(data);
                        this.designer.windowSystems = this.filteredWindowSystems;
                    },
                    error: error => {
                        this.errors.handle(error);
                    },
                    complete: () => {

                        console.info('`GetWindowSystemsByBusinessTypes` completed!');
                        this.dataLoadingStatus.windowSystemsByBusinessTypes = false;
                    }
                });
        }
    }

    private getEmptyRalColor(): Color {
        let ralColor = new Color();
        ralColor.id = ('PLACEHOLDER_RAL' as any);
        ralColor.names = new MultilanguageField();
        for (let supportedLanguage of SupportedLanguages.languages) {
            ralColor.names[supportedLanguage.code] = "OFFER.TABS.SECTION.COLOR.RAL_PALETTE_CUSTOM";
        }
        ralColor.core = true;
        ralColor.inside = true;
        ralColor.outside = true;
        ralColor.type = ColorType.RAL_PALETTE_CUSTOM;
        return ralColor;
    }

    private getEmptyRalColorSelectItem(): SelectItem {
        return {
            label: 'OFFER.TABS.SECTION.COLOR.RAL_PALETTE_CUSTOM',
            value: 'PLACEHOLDER_RAL',
            available: true
        };
    }

    private getEmptyNcsColor(): Color {
        let ralColor = new Color();
        ralColor.id = ('PLACEHOLDER_NCS' as any);
        ralColor.names = new MultilanguageField();
        for (let supportedLanguage of SupportedLanguages.languages) {
            ralColor.names[supportedLanguage.code] = "OFFER.TABS.SECTION.COLOR.NCS_PALETTE_CUSTOM";
        }
        ralColor.core = true;
        ralColor.inside = true;
        ralColor.outside = true;
        ralColor.type = ColorType.NCS_PALETTE_CUSTOM;
        return ralColor;
    }

    private getEmptyNcsColorSelectItem(): SelectItem {
        return {
            label: 'OFFER.TABS.SECTION.COLOR.NCS_PALETTE_CUSTOM',
            value: 'PLACEHOLDER_NCS',
            available: true
        };
    }

    handleWindowSystemSelectionChange(event: {oldWindowSystemId: number, newWindowSystemId: number}): void {
        if (event.newWindowSystemId == undefined) {
            return;
        }
        iif(() => !this.sidebarOnlyMode && !this.readOnlyMode,
            this.windowSystemDefinitionService.validateMarginExistance(event.newWindowSystemId, this.designer.offerPosition.offerId),
            of(new ProfitMarginExistance(true)))
            .pipe(this.missingProfitMarginHandlerService.handleProfitMarginExistenceResult({
                ...this.offer,
                identifier: {target: 'WINDOW_SYSTEM', windowSystemId: event.newWindowSystemId}
            }))
            .subscribe(profitMarginValid => {
                if (profitMarginValid) {
                    this.onWindowSystemChange();
                } else {
                    // do not undo here - would reset all selections to defaults
                    this.designer.data.windowSystemId = event.oldWindowSystemId;
                    this.generalTabComponent.markForCheck();
                }
            });
    }

    onWindowSystemChange(): void {
        this.designer.deleteAllConfigAddons();
        this.designer.deleteAllWindowAddons();
        this.designer.deleteAllGrills();
        this.dataLoadingStatus.catalog = true;
        this.blockUiController.block('WindowEditorWindowSystemChange');
        this.initWindowSystemGlazingWidths();
        let windowSystem = this.designer.getWindowSystem();
        const windowSystemType = WindowSystemType.getByName(windowSystem.systemType);
        this.windowSystemTypeGroup = windowSystemType ? windowSystemType.group : undefined;
        this.windowSystemDefaultsService.getDefaultsForWindow(this.designer.data.windowSystemId, this.designer.offerPosition.offerId,
            this.modelMode).subscribe(defaults => {
            this.initSystemDefaults(defaults);
            forkJoin({
                windowSystemsForVeneer: this.loadWindowSystemsForVeneer(this.designer.data.windowSystemId),
                ...this.windowEditorFieldContentProvider.getCatalogForWindowSystemDataSources(this.designer.data.windowSystemId,
                    this.designer.offerPosition.offerId, this.modelMode, this.windowSystemDefaults, this.readOnlyMode, true),
                windowSystem: this.windowSystemDefinitionService.getSystem(this.designer.data.windowSystemId)
            }).pipe(
                finalize(() => this.blockUiController.unblock('WindowEditorWindowSystemChange')))
                .subscribe(
                    catalogData => {
                        this.windowSystemsForVeneer = catalogData.windowSystemsForVeneer;
                        this.initCatalog(catalogData, this.windowSystemDefaults, true);
                        this.designer.setCatalogData(
                            new CatalogData(catalogData.windowSystem, this.windowSystemDefaults, this.grills, this.angledGrills,
                                this.systemMullions, this.glasses, this.frames, this.systemDecorativeFillings, this.allActiveColors,
                                this.filteredWindowSystems, this.allAddons, this.designer.getWindowSystem().windowSystemDrawingToolsEnabler,
                                this.allConfigAddonDefinitions));
                        this.availableGlassCounts = GlazingHelper.getAvailableGlassCount(this.designer.getWindowSystem(), false);
                        this.availableGlassCountsForDecor = GlazingHelper.getAvailableGlassCount(this.designer.getWindowSystem(), true);
                        this.filteredGlassCounts = this.availableGlassCounts;
                        this.clearAllSelectedValues();
                        this.initWindowViews(this.designer.data);
                        this.setDefaultWindowSystemValues();
                        this.rebindFieldSelections(this.designer.data, this.designer.windowSystem,
                            this.designer.profileCompositionDistances, isUpsellingMode(this.dataModificationMode));
                        this.dataLoadingStatus.catalog = false;
                        this.designer.redrawWindow(false, false, () => {
                            this.requiredFieldsOnSubWindowsFilled();
                            this.recheckRequiredFieldsOnMainTab();
                        });
                        if (this.modelMode) {
                            if (catalogData.windowSystemModel == null || catalogData.windowSystemModel.value == null) {
                                this.growls.info("OFFER.DRAWING.MODEL.NOT_EXISTS");
                            } else {
                                this.growls.info("OFFER.DRAWING.MODEL.EDITED");
                            }
                        } else if (this.isDataModificationMode()) {
                            this.growls.info("OFFER.DRAWING.WINDOW_SYSTEM.WEBSHOP_CHARGE_EDITED");
                        } else {
                            this.growls.info("OFFER.DRAWING.WINDOW_SYSTEM.EDITED");
                        }
                    });
        });
    }

    private initWindowSystemGlazingWidths(): void {
        let windowSystem = this.designer.getWindowSystem();
        if (windowSystem && windowSystem.glazingWidths) {
            let widthRanges = windowSystem.glazingWidths.split(';');

            let widths: number[] = [];

            widthRanges.forEach(widthRange => {
                let range: string[] = widthRange.split("-");

                if (range.length === 1 && range[0].trim()) {
                    let width = Math.ceil(Number.parseFloat(range[0].trim()));

                    if (widths.find(value => value === width) == null) {
                        widths.push(width);
                    }
                } else if (range.length > 1) {
                    let rangeFrom = Math.ceil(Number.parseFloat(range[0].trim()));
                    let rangeTo = Math.floor(Number.parseFloat(range[1].trim()));

                    for (let width = rangeFrom; width <= rangeTo; width++) {
                        if (widths.find(value => value === width) == null) {
                            widths.push(width);
                        }
                    }
                }
            });

            this.systemGlazingWidthItems = widths.sort((a, b) => a - b).slice(0, 1000).map(width => {
                return {
                    label: '' + width,
                    value: width,
                    available: true
                };
            });
        }
    }

    onGlobalFillingTypeChange(value: FillingType, propagateUndefined: boolean, resetGlazingBead = true, validateFields = true): void {
        if (value == undefined && !propagateUndefined) {
            this.designer.commonData.fillingType = undefined;
            return;
        }
        let oldData = DrawingData.copy(this.designer.data);
        try {
            this.designer.setAllFillingType(null, value);
            this.onFillingTypeChange(value, null, resetGlazingBead, validateFields);
            this.validateGrillAndMullionPositions();
            if (!this.sidebarOnlyMode) {
                this.designer.setCommonValues();
            } else {
                this.designer.commonData.fillingType = value;
            }
        } catch (e) {
            this.errors.handleFE(e);
            this.designer.setDrawingData(oldData);
        }
        if (!this.dataLoadingStatus.catalog) {
            this.redrawWindow();
        }
    }

    onSetAllFillingWidth(width: number | undefined): void {
        this.designer.commonData.fillingWidth = width;
        if (width != undefined) {
            this.designer.setAllFillingWidth(null, this.designer.commonData.fillingWidth);
            this.resetAllGlazingBeadIfNeeded();
        }
    }

    onSubwindowFillingWidthChange(): void {
        this.resetAllGlazingBeadIfNeeded();
    }

    onSetAllDecorativeFillingId(decorativeFillingId: number | undefined) {
        this.designer.commonData.decorativeFillingId = decorativeFillingId;
        if (decorativeFillingId != undefined) {
            this.designer.setAllDecorativeFillingId(null, this.designer.commonData.decorativeFillingId);
            this.resetAllGlazingBeadIfNeeded();
        }
    }

    onSetAllFillingId(fillingId: number | undefined) {
        this.designer.commonData.fillingId = fillingId;
        if (fillingId != undefined) {
            this.designer.setAllFillingId(null, fillingId);
            this.resetAllGlazingBeadIfNeeded();
        }
    }

    hideConfirmReplacingWindowSystemDefaultsDialog(): void {
        this.displayConfirmReplacingWindowSystemDefaultsDialog = false;
        this.displayConfirmOverwritingSubsystemGroupsDefaultsDialog = false;
        this.displayConfirmOverwritingSystemDefaultsDialog = false;
        this.displayConfirmOverwritingModelDialog = false;
    }

    onSetDefaultValuesButtonClick(force: boolean): void {
        if (this.isDataModificationMode() && this.generalTabComponent.webshopChargeValidationErrors
            && this.generalTabComponent.webshopChargeValidationErrors[`name[${this.generalTabComponent.translate.currentLang}]`]) {
            this.growls.error('error.webshopChargeDto.name.not_empty');
            return;
        }
        if (!this.modelMode || this.modelMode && this.validateRequiredFields(true)) {
            let getFieldNameFromLevel = level => {
                switch (level) {
                    case 'GLOBAL':
                        return 'globalDefaultExisting';
                    case 'SUBSYSTEM_GROUP':
                        return 'subsystemGroupDefaultExisting';
                    case 'SUBSYSTEM':
                        return 'subsystemDefaultExisting';
                    case 'CLIENT_GROUP':
                        return 'clientGroupDefaultExisting';
                    case 'CLIENT':
                        return 'clientDefaultExisting';
                    case 'OFFER':
                        return 'offerDefaultExisting';
                }
            };
            let levelExistingFieldName = getFieldNameFromLevel(this.windowSystemDefaultsLevel);
            let subsystemGroupsOverride = this.sidebarOnlyMode && this.windowSystemDefaultsLevel === 'SUBSYSTEM_GROUP';
            let subsystemsOverride = this.sidebarOnlyMode && this.windowSystemDefaultsLevel === 'SUBSYSTEM';

            if (!force) {
                if (this.modelMode) {
                    if (this.windowSystemDefaults.subsystemDefaultExisting) {
                        this.displayConfirmOverwritingModelDialog = true;
                        return;
                    }
                } else if (!this.isDataModificationMode()) {
                    this.windowSystemDefaultsValidation = {};
                    if (this.windowSystemDefaultsLevel == undefined) {
                        this.windowSystemDefaultsValidation['level'] = 'OFFER.TABS.SECTION.DEFAULTS.VALIDATION.LEVEL';
                        return;
                    }
                    if (subsystemGroupsOverride) {
                        this.displayConfirmOverwritingSubsystemGroupsDefaultsDialog = true;
                        return;
                    }
                    if (subsystemsOverride) {
                        this.displayConfirmOverwritingSystemDefaultsDialog = true;
                        return;
                    }
                    if (this.windowSystemDefaults[levelExistingFieldName]) {
                        this.displayConfirmReplacingWindowSystemDefaultsDialog = true;
                        return;
                    }
                }
            }

            let defaults = WindowSystemDefaultsUtil.prepareWindowSystemDefaults(this.designer.data, this.designer.commonData);
            if (this.modelMode) {
                defaults.webshopGlazingPackageId = this.selectedWebshopGlazingPackageId;
            }
            const windowSystem = this.designer.getWindowSystem();
            if (windowSystem != undefined
                && WindowSystemType.getByName(windowSystem.systemType).predefinedGlazing) {
                WindowSystemDefaultsUtil.prepareWindowSystemDefaultsPredefinedGlazingFields(this.designer.commonData, defaults);
            }

            let observable: Observable<any> = of({});

            if (subsystemGroupsOverride && !this.modelMode && !this.isDataModificationMode()) {
                let subsystemGroupIds = this.availableSubsystemGroups.filter(s => s.selected).map(s => s.id);
                observable = observable.pipe(mergeMap(() => this.windowSystemDefaultsService.overwriteForSubsystemGroups(
                    CatalogItemType.WINDOW_SYSTEM, this.designer.data.windowSystemId, subsystemGroupIds, defaults)));
            } else if (subsystemsOverride && !this.modelMode && !this.isDataModificationMode()) {
                let subsystemIds = this.availableSubsystems.filter(s => s.selected).map(s => s.id);
                observable = observable.pipe(mergeMap(() => this.windowSystemDefaultsService.overwriteForSubsystems(
                    CatalogItemType.WINDOW_SYSTEM, this.designer.data.windowSystemId, subsystemIds, defaults)));
            } else {
                if (this.windowSystemDefaultsOverrideLowerLevel && !this.modelMode && !this.isDataModificationMode()) {
                    observable = observable.pipe(mergeMap(
                            () => this.windowSystemDefaultsService.deleteSystemDefaults(CatalogItemType.WINDOW_SYSTEM,
                                this.windowSystemDefaultsLevel, this.designer.data.windowSystemId, this.designer.offerPosition.offerId)),
                        tap(() => {
                            const levels = ['GLOBAL', 'SUBSYSTEM_GROUP', 'SUBSYSTEM', 'CLIENT_GROUP', 'CLIENT', 'OFFER'];
                            const savedIndex = levels.indexOf(this.windowSystemDefaultsLevel) + 1;
                            for (let i = savedIndex; i < levels.length; ++i) {
                                this.windowSystemDefaults[getFieldNameFromLevel(levels[i])] = false;
                            }
                        }));
                }
                if (this.modelMode) {
                    observable = observable.pipe(mergeMap(() => this.windowSystemModelService.save(this.designer.data.windowSystemId,
                        defaults)));
                } else if (this.isDataModificationMode()) {
                    if (isUpsellingMode(this.dataModificationMode)) {
                        WindowSystemDefaultsUtil.prepareUpsellingVentilation(this.designer.upsellingSubwindowDummy,
                            defaults as UpsellingChargeData);
                    }
                    observable = observable.pipe(mergeMap(() => this.webshopChargeService.saveWebshopCharge(this.webshopCharge.id,
                        this.webshopCharge.name, this.designer.data.windowSystemId, defaults, this.dataModificationMode)));
                } else {
                    observable = observable.pipe(mergeMap(
                        () => this.windowSystemDefaultsService.save(CatalogItemType.WINDOW_SYSTEM, this.windowSystemDefaultsLevel,
                            this.designer.data.windowSystemId, this.designer.offerPosition.offerId, defaults)));
                }
            }
            observable.subscribe({
                complete: () => {
                    this.hideConfirmReplacingWindowSystemDefaultsDialog();
                    this.windowSystemDefaults[levelExistingFieldName] = true;
                    if (this.modelMode) {
                        this.onModelSave.emit();
                        this.growls.info('WINDOW_SYSTEM_MODEL.SAVED');
                    } else if (this.isDataModificationMode()) {
                        this.onWebshopChargeSave.emit();
                        this.growls.info('WEBSHOP_CHARGE.SAVED');
                    } else {
                        this.growls.info('OFFER.TABS.SECTION.DEFAULTS.SAVED');
                    }
                },
                error: error => {
                    let errors = this.errors.handle(error);
                    for (let errorsKey in errors) {
                        this.growls.error(errors[errorsKey]);
                    }
                }
            });
        }
    }

    onCancelModel() {
        this.onModelCancel.emit();
    }

    onCancelWebshopCharge() {
        this.onWebshopChargeCancel.emit();
    }

    onFillingTypeChange(value: FillingType, event: SubwindowPropertyChangeEvent, resetGlazingBead = true,
                        validateFields = true): void {
        let tabNumber = event ? event.tabNumber : null;
        let areaNumber = event ? event.areaNumber : null;

        if (tabNumber === null) {
            if (areaNumber === null) {
                for (let i = 0; i < this.designer.windowTabsData.length; i++) {
                    this.clearSubWindowFillingData(i);
                }
            } else {
                for (let i = 0; i < this.designer.windowTabsData.length; i++) {
                    this.clearAreaFillingData(i, areaNumber);
                }
            }
            this.filteredGlazingBeadsGeneralTab = [];
        } else {
            if (areaNumber === null) {
                this.clearSubWindowFillingData(tabNumber);
            } else {
                this.clearAreaFillingData(tabNumber, areaNumber);
            }
        }
        if (validateFields) {
            this.requiredFieldsOnSubWindowsFilled();
            this.validateRequiredFields();
        }
        if (!this.sidebarOnlyMode) {
            this.designer.debouncedRedrawWindow(false, !this.designer.isPricingTabOpen());
            if (value === FillingType.DECORATIVE_FILLING && this.availableGlassCountsForDecor.length === 1) {
                this.setDefaultDecorativeGlazing();
            }
            // Grill tool buttons status rely on filling type in commonData hence this method run
            this.designer.setCommonValues();
        }
        if (this.generalTabComponent) {
            this.generalTabComponent.sectionContainsErrors(SidebarSection.FILLING);
        }
    }

    onSetAllGlazingGlassQuantity(newGlassNumber: number): void {
        this.designer.commonData.glazingGlassQuantity = newGlassNumber;
        if (newGlassNumber == undefined) {
            return;
        }
        if (this.modelMode) {
            if (!newGlassNumber) {
                WindowSystemDefaultsUtil.clearGlazingDefaults(this.designer.commonData);
            } else {
                let selectedGlazingPackage = this.availableWebshopGlazingPackages
                    .find(glazing => glazing.id === this.selectedWebshopGlazingPackageId);
                if (selectedGlazingPackage) {
                    this.setCommonDataGlazingData(selectedGlazingPackage);
                }
            }
        } else {
            let copyGlazing = true;
            let previousCommonData = JSON.stringify(this.designer.commonData);
            if (newGlassNumber > 0 && this.designer.commonData.fillingType === FillingType.DECORATIVE_FILLING) {
                this.designer.setDefaultDecorativeGlazingData(null, newGlassNumber);
                copyGlazing = false;
            } else {
                this.designer.setAllGlazingGlassQuantity(null, this.designer.commonData.glazingGlassQuantity);
            }

            try {
                let windowSystem = this.designer.getWindowSystem();
                if (windowSystem.glazingPackage != null && !this.sidebarOnlyMode) {
                    GlazingHelper.applyGlazingBasedOnArea(this.designer.data, windowSystem.glazingPackage,
                        this.designer.commonData.glazingGlassQuantity, copyGlazing);
                    this.clearESGGlassesIfNeeded();
                    this.designer.setCommonValues();
                } else if (GlazingHelper.setDefaultCommonDataGlazing(windowSystem, this.designer.commonData)) {
                    this.designer.setAllGlassType(null, 1, this.designer.commonData.glass1id);
                    this.designer.setAllGlassType(null, 2, this.designer.commonData.glass2id);
                    this.designer.setAllGlassType(null, 3, this.designer.commonData.glass3id);
                    this.designer.setAllGlassType(null, 4, this.designer.commonData.glass4id);
                    this.designer.setAllFrames(null, 1, this.designer.commonData.frame1id);
                    this.designer.setAllFrames(null, 2, this.designer.commonData.frame2id);
                    this.designer.setAllFrames(null, 3, this.designer.commonData.frame3id);
                    if (!this.sidebarOnlyMode) {
                        this.clearESGGlassesIfNeeded();
                        this.designer.setCommonValues();
                    }
                } else if (!this.sidebarOnlyMode) {
                    this.designer.setCommonValues();
                }
            } catch (e) {
                this.errors.handleFE(e);
                this.designer.cancelLast();
                setTimeout(() => this.designer.commonData.copyFrom(JSON.parse(previousCommonData)), 0);
            }

            this.resetAllGlazingBeadIfNeeded();
            this.redrawWindow();
        }
    }

    private setCommonDataGlazingData(selectedGlazingPackage: WebshopGlazingPackage): void {
        let glazing = this.getGlazing(this.designer.commonData.glazingGlassQuantity, selectedGlazingPackage);
        if (glazing) {
            if (this.glasses.find(glass => glass.id === glazing.glass1id)) {
                this.designer.commonData.glass1id = glazing.glass1id;
            } else {
                this.designer.commonData.glass1id = undefined;
            }

            if (this.glasses.find(glass => glass.id === glazing.glass2id)) {
                this.designer.commonData.glass2id = glazing.glass2id;
            } else {
                this.designer.commonData.glass2id = undefined;
            }

            if (this.glasses.find(glass => glass.id === glazing.glass3id)) {
                this.designer.commonData.glass3id = glazing.glass3id;
            } else {
                this.designer.commonData.glass3id = undefined;
            }

            if (this.glasses.find(glass => glass.id === glazing.glass4id)) {
                this.designer.commonData.glass4id = glazing.glass4id;
            } else {
                this.designer.commonData.glass4id = undefined;
            }

            if (this.frames.find(glass => glass.id === glazing.frame1id)) {
                this.designer.commonData.frame1id = glazing.frame1id;
            } else {
                this.designer.commonData.frame1id = undefined;
            }

            if (this.frames.find(glass => glass.id === glazing.frame2id)) {
                this.designer.commonData.frame2id = glazing.frame2id;
            } else {
                this.designer.commonData.frame2id = undefined;
            }

            if (this.frames.find(glass => glass.id === glazing.frame3id)) {
                this.designer.commonData.frame3id = glazing.frame3id;
            } else {
                this.designer.commonData.frame3id = undefined;
            }
        }

        this.resetAllGlazingBeadIfNeeded();
    }

    private getGlazing(glassCount: number, glazingPackage: WebshopGlazingPackage): Glazing {
        const windowSystemType = WindowSystemType.getByName(this.designer.getWindowSystem().systemType);
        if (windowSystemType == undefined) {
            return undefined;
        }
        if (!windowSystemType.predefinedGlazing) {
            if (glassCount === 1) {
                return glazingPackage.glazing1;
            }
            if (glassCount === 2) {
                return glazingPackage.glazing2;
            }
            if (glassCount === 3) {
                return glazingPackage.glazing3;
            }
            if (glassCount === 4) {
                return glazingPackage.glazing4;
            }
        } else {
            const ggp = this.availableGlazingPackages.find(gp => gp.id === glazingPackage['graspGlazingPackageId' + glassCount]);
            if (ggp != undefined) {
                return ggp.glazing;
            }
        }
        return undefined;
    }

    onSetWebshopGlazingPackage(newWebshopGlazingPackageId: number): void {
        this.selectedWebshopGlazingPackageId = newWebshopGlazingPackageId;
        WindowSystemDefaultsUtil.clearGlazingDefaults(this.designer.commonData);
        this.resetAllGlazingBeadIfNeeded();
        this.filterGlassCounts();
        this.setPredefinedGlazingPackage();
    }

    onSetTerraceGlazingPackage(newGlazingPackageId: number): void {
        this.designer.data.specification.terraceGlazingPackageId = newGlazingPackageId;
        WindowSystemDefaultsUtil.clearGlazingDefaults(this.designer.commonData);
        this.resetAllGlazingBeadIfNeeded();

        let glazingPackage = this.availableTerraceGlazingPackage.find(gp => gp.id === newGlazingPackageId);
        if (glazingPackage == null) {
            return;
        }
        let previousCommonData = JSON.stringify(this.designer.commonData);

        try {
            this.designer.setAllFillingType(null, FillingType.GLASS);

            GlassUpsellingValidationUtil.applyGlazingBasedOnArea(glazingPackage.glazingPackageForAreaRangeList,
                this.designer.staticData, this.designer.data,
                this.availableGlazingBeads.map(gb => ({
                    id: gb.id,
                    active: gb.active,
                    acceptableFillingWidth: GlazingBead.parseAcceptableFillingWidth(gb)
                })),
                this.designer.getWindowSystem(), false);

            if (!this.sidebarOnlyMode) {
                this.designer.debouncedRedrawWindow(false, this.designer.isPricingTabOpen(),
                    () => this.validateRequiredFields());
            }

            if (!this.sidebarOnlyMode) {
                this.clearESGGlassesIfNeeded();
                this.designer.setCommonValues();
            }
        } catch (e) {
            this.errors.handleFE(e);
            this.designer.cancelLast();
            setTimeout(() => this.designer.commonData.copyFrom(JSON.parse(previousCommonData)), 0);
        }

        this.onSidebarValueChange(true);
    }

    private filterGlassCounts(): void {
        let glassCounts: number[] = [];
        let selectedGlazingPackage = this.availableWebshopGlazingPackages
            .find(glazing => glazing.id === this.selectedWebshopGlazingPackageId);
        if (selectedGlazingPackage) {
            if (selectedGlazingPackage.hasGlazing1) {
                glassCounts.push(1);
            }
            if (selectedGlazingPackage.hasGlazing2) {
                glassCounts.push(2);
            }
            if (selectedGlazingPackage.hasGlazing3) {
                glassCounts.push(3);
            }
            if (selectedGlazingPackage.hasGlazing4) {
                glassCounts.push(4);
            }
        }

        this.filteredGlassCounts = _.intersection(glassCounts, this.availableGlassCounts).sort((a, b) => a - b);
    }

    private setPredefinedGlazingPackage(): void {
        let selectedGlazingPackage = this.availableWebshopGlazingPackages
            .find(glazing => glazing.id === this.selectedWebshopGlazingPackageId);

        const predefinedGlassCounts: number[] = [];
        if (selectedGlazingPackage) {
            let ggp = this.availableGlazingPackages.find(gp => gp.id === selectedGlazingPackage.graspGlazingPackageId1);
            if (ggp) {
                predefinedGlassCounts.push(ggp.glazing.glazingGlassQuantity);
            }
            ggp = this.availableGlazingPackages.find(gp => gp.id === selectedGlazingPackage.graspGlazingPackageId2);
            if (ggp) {
                predefinedGlassCounts.push(ggp.glazing.glazingGlassQuantity);
            }
            ggp = this.availableGlazingPackages.find(gp => gp.id === selectedGlazingPackage.graspGlazingPackageId3);
            if (ggp) {
                predefinedGlassCounts.push(ggp.glazing.glazingGlassQuantity);
            }
            ggp = this.availableGlazingPackages.find(gp => gp.id === selectedGlazingPackage.graspGlazingPackageId4);
            if (ggp) {
                predefinedGlassCounts.push(ggp.glazing.glazingGlassQuantity);
            }
        }
        this.availableGlazingPackageQuantities = _.uniq(predefinedGlassCounts).sort();
    }

    private clearESGGlassesIfNeeded(): void {
        try {
            this.validateGrillAndMullionPositions();
        } catch (preClearError) {
            let clearGlassIdIfESG = (glassNumber: number) => {
                let glass = this.glasses.find(
                    g => g.id === this.designer.commonData['glass' + glassNumber + 'id']);
                if (glass && glass.esg) {
                    this.designer.setAllGlassType(null, glassNumber, null);
                }
            };
            let oldData = DrawingData.copy(this.designer.data);
            for (let i = 1; i < this.designer.commonData.glazingGlassQuantity + 1; i++) {
                clearGlassIdIfESG(i);
            }
            try {
                this.validateGrillAndMullionPositions();
                this.growls.error(ErrorNames.ESG_GLASS_NEEDS_CHANGING);
            } catch (postClearError) {
                // swallow postClearError, something is wrong despite the ESG glass change
                this.designer.setDrawingData(oldData);
                throw preClearError;
            }
        }
    }

    onSingleGlassQuantityChange(event: GlassChangeEvent) {
        this.designer.saveStepInHistory(this.designer.data);
        let previousCommonData = JSON.stringify(this.designer.commonData);
        try {
            let area = event.area;

            // Reset selected glasses
            for (let i = 1; i < WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX + 1; i++) {
                area.glazing['glass' + i + 'id'] = undefined;
            }

            // Reset selected frames
            for (let i = 1; i < WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX; i++) {
                area.glazing['frame' + i + 'id'] = undefined;
            }

            let windowSystem = this.designer.getWindowSystem();
            GlassUpsellingValidationUtil.applyGlazingToArea(area, event.newValue,
                windowSystem.glazingPackage.glazingPackageForAreaRangeList, windowSystem);
            this.clearESGGlassesIfNeeded();
            this.filterGlazingBeads(area, event.subwindowId);
            this.redrawWindow();
        } catch (e) {
            this.errors.handleFE(e);
            this.designer.cancelLast();
            setTimeout(() => {
                this.designer.commonData.copyFrom(JSON.parse(previousCommonData));
                this.redrawWindow();
            }, 0);
        }
    }

    filterGlazingBeads(area: AreaSpecification, subwindowId: string): void {
        let beads = GlazingBeadUtils.filterGlazingBeadsMatchingWidth(
            this.availableGlazingBeads,
            area,
            GlazingBeadMatchingMode.AREA,
            this.glasses,
            this.frames,
            this.systemDecorativeFillings,
            this.systemOtherFillings
        );
        if (!this.filteredGlazingBeadsPerSubwindow[subwindowId]) {
            this.filteredGlazingBeadsPerSubwindow[subwindowId] = {};
        }
        this.filteredGlazingBeadsPerSubwindow[subwindowId][area.generatedId] = beads;
        this.filterGeneralTabGlazingBeads();
        if (GlazingBeadUtils.resetIfNeeded(area, beads)) {
            if (this.designer) {
                this.designer.setCommonValues();
            }
        }
    }

    filterGeneralTabGlazingBeads(): void {
        let beadsPerArea: GlazingBead[][] = [];
        Object.values(this.filteredGlazingBeadsPerSubwindow).forEach(glazingBeadsPerAreaInSubwindow => {
            Object.values(glazingBeadsPerAreaInSubwindow).forEach(glazingBeadsPerArea => {
                beadsPerArea.push(glazingBeadsPerArea);
            });
        });
        this.filteredGlazingBeadsGeneralTab = _.intersection(...beadsPerArea);
    }

    onSingleGlassChange(event: GlassChangeEvent) {
        try {
            event.area.glazing['glass' + event.glassPosition + 'id'] = event.newValue;
            this.validateGrillAndMullionPositions();
            this.filterGlazingBeads(event.area, event.subwindowId);
            this.redrawWindow();
            this.checkGlazingItemsAvailability(this.glasses.filter(glass => !glass.active), GlazingItemAttr.GLASS);
        } catch (e) {
            this.errors.handleFE(e);
            setTimeout(() => this.designer.undoLast());
        }
    }

    resetAllGlazingBeadIfNeeded() {
        if (!this.sidebarOnlyMode) {
            this.filteredGlazingBeadsPerSubwindow = {};
            this.designer.data.windows.forEach(window => {
                window.subWindows.forEach(subwindow => {
                    subwindow.areasSpecification.forEach(area => {
                        this.filterGlazingBeads(area, subwindow.generatedId);
                    });
                });
            });
            this.filterGeneralTabGlazingBeads();
            this.designer.setCommonValues();
        } else {
            this.filteredGlazingBeadsGeneralTab = GlazingBeadUtils.filterGlazingBeadsMatchingWidth(
                this.availableGlazingBeads,
                this.designer.commonData,
                GlazingBeadMatchingMode.COMMON,
                this.glasses,
                this.frames,
                this.systemDecorativeFillings,
                this.systemOtherFillings
            );
            GlazingBeadUtils.resetIfNeededSidebarMode(this.designer.commonData, this.filteredGlazingBeadsGeneralTab);
        }
    }

    onSetAllGlassType(event: GlassChangeEvent): void {
        try {
            this.designer.commonData['glass' + event.glassPosition + 'id'] = event.newValue;
            if (event.newValue != undefined) {
                this.designer.setAllGlassType(null, event.glassPosition, event.newValue);
                this.resetAllGlazingBeadIfNeeded();
                this.validateGrillAndMullionPositions();
                this.redrawWindow();
            }
        } catch (e) {
            this.errors.handleFE(e);
            setTimeout(() => {
                this.designer.cancelLast();
                this.generalTabComponent.markForCheck();
            }, 0);
        }
    }

    onSetAllFrameType(event: GlassChangeEvent): void {
        this.designer.commonData['frame' + event.glassPosition + 'id'] = event.newValue;
        if (event.newValue != undefined) {
            this.designer.setAllFrames(null, event.glassPosition, event.newValue);
            this.resetAllGlazingBeadIfNeeded();
        }
    }

    private clearAreaFillingData(tabNumber: number, areaNumber: number): void {
        let subWindow = this.designer.windowTabsData[tabNumber].windowData;
        let areaSpecification = subWindow.areasSpecification[areaNumber];
        areaSpecification.glazingBead.id = undefined;
        areaSpecification.filling.fillingId = undefined;
        areaSpecification.filling.decorativeFillingId = undefined;
        areaSpecification.filling.externalColorId = undefined;
        areaSpecification.filling.internalColorId = undefined;
        areaSpecification.filling.coreColorId = undefined;
        areaSpecification.glazing.glazingGlassQuantity = undefined;
        areaSpecification.glazing.glass1id = undefined;
        areaSpecification.glazing.glass2id = undefined;
        areaSpecification.glazing.glass3id = undefined;
        areaSpecification.glazing.glass4id = undefined;
        areaSpecification.glazing.frame1id = undefined;
        areaSpecification.glazing.frame2id = undefined;
        areaSpecification.glazing.frame3id = undefined;
        areaSpecification.filling.width = undefined;

        if (this.filteredGlazingBeadsPerSubwindow[subWindow.generatedId] != undefined) {
            this.filteredGlazingBeadsPerSubwindow[subWindow.generatedId][areaSpecification.generatedId] = [];
        }
    }

    private clearSubWindowFillingData(tabNumber: number): void {
        let subWindow = this.designer.windowTabsData[tabNumber].windowData;
        for (let i = 0; i < subWindow.areasSpecification.length; i++) {
            this.clearAreaFillingData(tabNumber, i);
        }
        this.filteredGlazingBeadsPerSubwindow[subWindow.generatedId] = {};
    }

    private clearAllSelectedValues(): void {
        this.designer.commonData.clear();

        this.onGlobalFillingTypeChange(undefined, true);

        this.designer.commonData.glazingGlassQuantity = undefined;
        this.designer.setAllGlazingGlassQuantity(null, undefined);

        this.designer.data.specification.frameProfileId = undefined;

        this.designer.data.specification.colorIdCore = undefined;
        this.designer.data.specification.colorIdExternal = undefined;
        this.designer.data.specification.colorIdInternal = undefined;
        this.designer.data.specification.sealExternalId = undefined;
        this.designer.data.specification.sealInternalId = undefined;
        this.designer.data.specification.handleType.addonId = undefined;
        this.designer.data.specification.underwindowProfile.addonId = undefined;
        this.designer.data.specification.windowFunction = undefined;
        this.designer.data.specification.opening = undefined;
        this.designer.data.specification.doorstepId = undefined;
        this.designer.data.specification.cover.addonId = undefined;
        this.designer.data.specification.fittingBrake.addonId = undefined;
        this.designer.data.specification.fittingSliding.addonId = undefined;
        this.designer.data.specification.fittingType.addonId = undefined;
        this.designer.data.specification.fittingEspagnoletteType.addonId = undefined;
        this.designer.data.specification.fittingVeranda.addonId = undefined;
        this.designer.data.specification.fittingInsertion.addonId = undefined;
        this.designer.data.specification.fittingMainInsertion.addonId = undefined;
        this.designer.data.specification.fittingAdditionalInsertion.addonId = undefined;
        this.designer.data.specification.fittingLock.addonId = undefined;
        this.designer.data.specification.fittingLockTerrace.addonId = undefined;
        this.designer.data.specification.fittingLockTerraceLocation = undefined;
        this.designer.data.specification.opening = undefined;
        this.designer.data.view = undefined;
        this.designer.data.specification.terraceHandle.addonId = undefined;
        this.designer.data.specification.terraceHandleLayout = undefined;
        this.designer.data.specification.channelSectionId = undefined;
        this.designer.data.specification.constructionalMullionId = undefined;
        this.designer.data.specification.movablePostId = undefined;

        for (let window of this.designer.data.windows) {
            for (let subWindow of window.subWindows) {
                subWindow.ventilator.addonId = undefined;
                subWindow.drip.addonId = undefined;
                subWindow.coupler.addonId = undefined;
            }
        }
    }

    private setDefaultWindowSystemValues(): void {
        let oldData = DrawingData.copy(this.designer.data);
        this.designer.data.usedGlobalSettingsLevel = undefined;
        this.designer.data.usedGlobalSettingsChanged = false;
        let defaultSystemGlazing: Glazing = undefined;
        let windowSystem = this.designer.getWindowSystem();
        if (this.filteredGlassCounts.length === 1 && windowSystem != null) {
            let prop = 'glazing' + this.filteredGlassCounts[0];
            defaultSystemGlazing = windowSystem.glazingPackage && windowSystem.glazingPackage[prop];
        }
        this.designer.data.usedGlobalSettingsLevel = WindowSystemDefaultsState.getLevel(this.windowSystemDefaults);
        if (this.isDataModificationMode() && (this.windowSystemDefaults == null || this.windowSystemDefaults.value == null)) {
            WindowSystemDefaultsUtil.applyWebshopChargeDefaults(this.designer.data, isUpsellingMode(this.dataModificationMode));
        } else {
            let defaults = (this.windowSystemDefaults == null || this.windowSystemDefaults.value == null) ?
                new WindowSystemDefaults() : this.windowSystemDefaults.value;
            if (defaults.fillingType == null && defaultSystemGlazing != null) {
                defaults.fillingType = FillingType.GLASS;
                defaults.glazingGlassQn = defaultSystemGlazing.glazingGlassQuantity;
                defaults.glass1id = defaultSystemGlazing.glass1id;
                defaults.glass2id = defaultSystemGlazing.glass2id;
                defaults.glass3id = defaultSystemGlazing.glass3id;
                defaults.glass4id = defaultSystemGlazing.glass4id;
                defaults.frame1id = defaultSystemGlazing.frame1id;
                defaults.frame2id = defaultSystemGlazing.frame2id;
                defaults.frame3id = defaultSystemGlazing.frame3id;
            }
            WindowSystemDefaultsUtil.applyDefaults(defaults, this.designer.data, windowSystem, this.sidebarOnlyMode);

            this.designer.loadWindowAddons();
            if (!this.sidebarOnlyMode) {
                try {
                    this.designer.setCommonValues();
                    this.designer.data.specification.terraceGlazingPackageId = defaults.terraceGlazingPackageId;
                    this.validateGrillAndMullionPositions();
                    this.requiredFieldsOnSubWindowsFilled();
                    this.validateRequiredFields();
                    this.resetAllGlazingBeadIfNeeded();
                } catch (e) {
                    this.errors.handleFE(e);
                    this.designer.setDrawingData(oldData);
                }
                this.designer.debouncedRedrawWindow(false, !this.designer.isPricingCurrentTab);
                // Grill tool buttons status rely on filling type in commonData hence this method run
            } else if (this.sidebarOnlyMode && !this.isDataModificationMode()) {
                this.selectedWebshopGlazingPackageId = defaults.webshopGlazingPackageId;
                this.designer.data.specification.terraceGlazingPackageId = defaults.terraceGlazingPackageId;
                this.designer.commonData.glazingGlassQuantity = defaults.glazingGlassQn;
                this.designer.commonData.glazingCategoryId = defaults.glazingCategoryId;
                this.designer.commonData.glazingFrameCategoryId = defaults.glazingFrameCategoryId;
                this.designer.commonData.glazingPackageId = defaults.glazingPackageId;
                if (this.modelMode) {
                    this.filterGlassCounts();
                    this.setPredefinedGlazingPackage();
                    WindowSystemDefaultsUtil.applyDefaultsToCommonData(defaults, this.designer.commonData, this.modelMode);
                    if (!WindowSystemType.getByName(windowSystem.systemType).predefinedGlazing) {
                        if (this.filteredGlassCounts.find(glassCount => glassCount === this.designer.commonData.glazingGlassQuantity)) {
                            this.onSetAllGlazingGlassQuantity(this.designer.commonData.glazingGlassQuantity);
                        } else {
                            this.onSetAllGlazingGlassQuantity(undefined);
                        }
                    } else {
                        if (this.availableGlazingPackageQuantities.find(gc => gc === this.designer.commonData.glazingGlassQuantity)) {
                            this.onGlobalSetGraspGlazingQuantity(this.designer.commonData.glazingGlassQuantity);
                        } else {
                            this.onGlobalSetGraspGlazingQuantity(undefined);
                        }
                    }
                } else {
                    WindowSystemDefaultsUtil.applyDefaultsToCommonData(defaults, this.designer.commonData, this.modelMode);
                }
            }
        }
    }

    onGrillChange(event: { grill: Grill, newGrill: Grill }, subwindow: SubWindowData): void {
        this.designer.saveStepInHistory(this.designer.data);
        let grillDto = this.grills.find(g => g.id === event.newGrill.id);
        let grill = event.grill;
        grill.id = grillDto.id;
        grill.colorId = event.newGrill.colorId;
        grill.width = grillDto.width;
        grill.canBeAngled = grillDto.angled;
        grill.constructionType = grillDto.type;
        try {
            let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, this.designer.data.cuts,
                this.designer.totalBoundingBox, this.designer.profileCompositionDistances, this.designer.isValidationDisabled());
            PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads, this.designer.profileCompositionDistances);
            GrillPositionValidator.validateGrills(subwindow, totalGlazingBeads.regular, this.designer.profileCompositionDistances);
            this.refilterIntersectingGrillColors();
            this.designer.setCommonValues();
        } catch (e) {
            this.errors.handleFE(e);
            this.designer.cancelLast();
        }
        this.redrawWindow();
    }

    onMullionChange(event: { mullion: Mullion, newId: number }, subwindow: SubWindowData): void {
        this.designer.saveStepInHistory(this.designer.data);
        let mullion = event.mullion;
        mullion.id = event.newId;
        let mullionData = this.systemMullions.find(m => m.id === mullion.id);
        let oldWidth = mullion.width;
        if (mullion.id != undefined) {
            mullion.width = MullionUtils.getInternalWidth(mullionData, this.designer.profileCompositionDistances);
            mullion.isConstructional = mullionData.type === ProfileType.CONSTRUCTIONAL_MULLION;
            mullion.canBeAngled = MullionHelper.canBeDrawnAtAnAngle(mullion);
        }
        try {
            MullionUtils.onMullionWidthChange(mullion, oldWidth);
            let relevantCuts = this.designer.data.cuts.filter(cut => CutsUtil.cutDataIntersectsPolygon(subwindow.points, cut));
            if (MullionHelper.isVeneer(mullion)) {
                this.processNewCreatedChanges(
                    MullionUtils.updateAbsoluteMullionPositions(subwindow, relevantCuts,
                        this.designer.totalBoundingBox,
                        this.designer.profileCompositionDistances, this.designer.data.shape));
            }
            let totalInnerEdgePoints = WindowCalculator.getTotalFrameInnerEdgePointsFull(subwindow,
                relevantCuts, this.designer.totalBoundingBox, this.designer.profileCompositionDistances,
                this.designer.isValidationDisabled());
            let totalInnerFrame = PolygonPointUtil.toNumbersArray(totalInnerEdgePoints);
            let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(subwindow, relevantCuts,
                this.designer.totalBoundingBox, this.designer.profileCompositionDistances,
                this.designer.isValidationDisabled());
            let totalRealPackagePoints = WindowCalculator.getTotalRealGlazingPackagePoints(subwindow, relevantCuts,
                this.designer.totalBoundingBox, this.designer.profileCompositionDistances);
            MullionUtils.validateMullionPositions(subwindow, totalInnerEdgePoints, totalGlazingBeads.regular,
                this.designer.profileCompositionDistances, this.designer.data.shape);
            PositionsHelper.updateAbsolutePositions(subwindow, totalGlazingBeads,
                this.designer.profileCompositionDistances);
            AreaUtils.setNewAreaSizes(subwindow, totalGlazingBeads.regular, totalInnerFrame, totalRealPackagePoints,
                this.designer.profileCompositionDistances);
            this.redrawWindow();
        } catch (e) {
            this.errors.handleFE(e);
            setTimeout(() => {
                this.designer.cancelLast();
                this.redrawWindow();
            }, 1);
        }
    }

    onChangeTotalSize(event): void {
        let totalBoundingBox = this.designer.totalBoundingBox;
        let oldWidth = totalBoundingBox.maxX - totalBoundingBox.minX;
        let oldHeight = totalBoundingBox.maxY - totalBoundingBox.minY;
        const observables = [of(new OperationResult())];
        if (event.totalHeight !== undefined) {
            observables.push(this.designer.guides.changeSizeIgnoringLocks(this.designer.getWindowSystem(), true,
                Math.max(event.totalHeight, 1)));
        }
        if (event.totalWidth !== undefined) {
            observables.push(this.designer.guides.changeSizeIgnoringLocks(this.designer.getWindowSystem(), false,
                Math.max(event.totalWidth, 1)));
        }

        forkJoin(observables).subscribe({
            next: (operationResults: OperationResult[]) => {
                let result = new OperationResult();
                operationResults.forEach(op => result.merge(op));
                this.designer.pricingStatus.errors = false;
                this.designer.pricingStatus.warnings = false;
                this.processNewCreatedChanges(result);
                if (event.autosave) {
                    this.doSaveAndExit();
                }
            },
            error: (error: Error & { params?: MessageParams }) => {
                this.onDrawingDataChanged([]);
                this.generalTabComponent.totalWidth = oldWidth;
                this.generalTabComponent.totalHeight = oldHeight;
                this.errors.handleFE(error, error.params);
                this.redrawWindow();
            }
        });
    }

    onChangeConstructionalProfile(event, field: WindowEditorField): void {
        let specField: string;
        let compositionType: ProfilesCompositionType;
        let availableProfiles: Profile[];
        switch (field) {
            case WindowEditorField.DOORSTEP:
                specField = 'doorstepId';
                compositionType = ProfilesCompositionType.THRESHOLD;
                availableProfiles = this.availableDoorsteps;
                break;
            case WindowEditorField.CHANNEL_SECTION:
                specField = 'channelSectionId';
                compositionType = ProfilesCompositionType.CHANNEL_SECTION;
                availableProfiles = this.availableChannelSections;
                break;
            case WindowEditorField.PROFILE:
                specField = 'frameProfileId';
                compositionType = ProfilesCompositionType.FRAME;
                availableProfiles = this.systemFrameProfiles;
                break;
            case WindowEditorField.CONSTRUCTIONAL_MULLION:
                specField = 'constructionalMullionId';
                compositionType = ProfilesCompositionType.MULLION;
                availableProfiles = this.availableConstructionalMullions;
                break;
            case WindowEditorField.MOVABLE_POST:
                specField = 'movablePostId';
                compositionType = ProfilesCompositionType.MOVABLE_POST;
                availableProfiles = this.availableMovablePosts;
                break;
            default:
                throw new Error('onChangeConstructionalProfile - Unsupported field ' + field);
        }
        if (this.sidebarOnlyMode) {
            this.designer.data.specification[specField] = event;
        } else {
            this.designer.saveStepInHistory(this.designer.data);
            let oldCompositions = this.designer.profileCompositionDistances.actual.get(compositionType);
            try {
                let oldMaps = PositionsHelper.getFramesAndGlazingBeads(this.designer.data, this.designer.profileCompositionDistances,
                    this.designer.isValidationDisabled());
                let oldData = DrawingData.copy(this.designer.data);
                let oldProfileCompositionDistances = JSON.parse(JSON.stringify(this.designer.profileCompositionDistances));
                this.designer.data.specification[specField] = event;
                this.designer.profileCompositionDistances.set(compositionType,
                    event ? this.findProfileById(event, availableProfiles).compositionDistances : undefined);
                let operationResult = AlignmentTool.autoAlign(this.designer.getWindowSystem(),
                    this.designer.profileCompositionDistances, this.designer.data, oldData, oldProfileCompositionDistances);
                this.designer.guides.rebuildStructureGuides();
                operationResult.merge(MullionUtils.repositionMullionsAndGrills(this.designer.data,
                    this.designer.profileCompositionDistances, oldMaps.frames, oldMaps.glazingBeads,
                    this.designer.isValidationDisabled()));
                this.processNewCreatedChanges(operationResult);
            } catch (e) {
                this.errors.handleFE(e);
                this.designer.cancelLast();
                this.designer.profileCompositionDistances.set(compositionType, oldCompositions);
            }
            this.redrawWindow(false);
        }
    }

    private findProfileById(id: number, array: Profile[], required = true): Profile {
        let profile = array.find(p => p.id === id);
        if (profile) {
            return profile;
        } else if (required) {
            let err = new Error("Profile with id: " + id + " not found.");
            err.name = ErrorNames.SELECTED_ELEMENT_DOES_NOT_EXIST;
            throw err;
        } else {
            return null;
        }
    }

    onGlobalGrillChange(event: { oldId: number, newId: number }): void {
        this.cancelMode();
        this.designer.commonData.grillId = event.newId;
        if (event.newId == undefined) {
            return;
        }
        this.designer.saveStepInHistory(this.designer.data);
        let grillDto = this.grills.find(g => g.id === this.designer.commonData.grillId);
        try {
            this.designer.data.windows.forEach(w =>
                w.subWindows.forEach(sw => {
                        sw.areasSpecification.forEach(area =>
                            area.grills.forEach(grill => {
                                grill.id = this.designer.commonData.grillId;
                                grill.width = grillDto.width;
                                grill.canBeAngled = grillDto.angled;
                                grill.constructionType = grillDto.type;
                            }));
                        let totalGlazingBeads = WindowCalculator.getTotalGlazingBeads(sw, this.designer.data.cuts,
                            this.designer.totalBoundingBox, this.designer.profileCompositionDistances,
                            this.designer.isValidationDisabled());
                        PositionsHelper.updateAbsolutePositions(sw, totalGlazingBeads, this.designer.profileCompositionDistances);
                        GrillPositionValidator.validateGrills(sw, totalGlazingBeads.regular, this.designer.profileCompositionDistances);
                    }
                ));
            this.refilterIntersectingGrillColors();
        } catch (e) {
            this.errors.handleFE(e);
            this.designer.cancelLast();
            setTimeout(() => this.designer.commonData.grillId = event.oldId, 0);
        }
        this.redrawWindow();
    }

    onGlobalGrillColorChange(event: { oldId: number, newId: number }): void {
        this.cancelMode();
        this.designer.commonData.grillColorId = event.newId;
        if (event.newId == undefined) {
            return;
        }
        this.designer.saveStepInHistory(this.designer.data);
        this.designer.data.windows.forEach(w =>
            w.subWindows.forEach(sw => {
                    sw.areasSpecification.forEach(area =>
                        area.grills.forEach(grill => {
                            if (this.checkAndFillGrillColorsFromGlobalSelection(grill.id, this.designer.commonData.grillColorId)) {
                                grill.colorId = this.designer.commonData.grillColorId;
                            }
                        })
                    );
                }
            )
        );
        this.refilterIntersectingGrillColors();
    }

    onGlobalMullionChange(oldMullionId: number): void {
        if (this.designer.commonData.mullionId == undefined) {
            return;
        }
        this.cancelMode();
        let width = MullionUtils.getInternalWidth(
            this.systemMullions.find(m => m.id === this.designer.commonData.mullionId),
            this.designer.profileCompositionDistances);
        this.designer.data.windows.forEach(w => w.subWindows.forEach(sw => sw.mullions.forEach(mullion => {
            let oldWidth = mullion.width;
            mullion.id = this.designer.commonData.mullionId;
            mullion.width = width;
            mullion.canBeAngled = MullionHelper.canBeDrawnAtAnAngle(mullion);
            MullionUtils.onMullionWidthChange(mullion, oldWidth);
        })));
        try {
            let operationResult = MullionUtils.updateAllMullionPositions(this.designer.data,
                this.designer.profileCompositionDistances, this.designer.totalBoundingBox);
            this.processNewCreatedChanges(operationResult);
            this.redrawWindow(false);
        } catch (e) {
            this.errors.handleFE(e);
            setTimeout(() => {
                this.designer.cancelLast();
                this.designer.commonData.mullionId = oldMullionId;
                this.redrawWindow(false);
            }, 1);
        }
    }

    onGlobalGlazingBeadChange(glazingBeadId: number): void {
        if (glazingBeadId != undefined) {
            this.cancelMode();
            this.designer.saveStepInHistory(this.designer.data);
        }
        this.designer.commonData.glazingBeadId = glazingBeadId;
        if (glazingBeadId != undefined) {
            this.designer.data.windows.forEach(w =>
                w.subWindows.forEach(sw => {
                        sw.areasSpecification.forEach(area =>
                            area.glazingBead.id = glazingBeadId
                        );
                    }
                )
            );
            this.requiredFieldsOnSubWindowsFilled();
        }
    }

    onSubWindowVentilatorChange(ventilatorId: number, subwindow: SubWindowData): void {
        this.cancelMode();
        this.designer.saveStepInHistory(this.designer.data);
        subwindow.ventilator.addonId = ventilatorId;
        this.designer.setCommonValues();
        this.redrawWindow();
    }

    onSubWindowDripChange(dripId: number, subwindow: SubWindowData): void {
        this.cancelMode();
        this.designer.saveStepInHistory(this.designer.data);
        subwindow.drip.addonId = dripId;
        this.designer.setCommonValues();
        this.redrawWindow();
    }

    onSubWindowCouplerChange(couplerId: number, subwindow: SubWindowData): void {
        this.cancelMode();
        this.designer.saveStepInHistory(this.designer.data);
        subwindow.coupler.addonId = couplerId;
        this.designer.setCommonValues();
        this.redrawWindow();
    }

    onSetWebshopChargeName(name: MultilanguageField) {
        this.webshopCharge.name = name;
    }

    onNewWindowAdd(): void {
        if (this.designer.data.windows.length === 0) {
            this.sidebarInitialized = false;
            this.blockUiController.block('WindowEditorNewWindow');
            this.validateRequiredFields();
            this.loadAvailableWindowSystems(this.designer.data).pipe(
                finalize(() => this.blockUiController.unblock('WindowEditorNewWindow')))
                .subscribe(systems => {
                    this.filteredWindowSystems = systems;
                    this.designer.windowSystems = this.filteredWindowSystems;
                });
            return;
        }

        this.loadSystemsFilteredByExistingBusinessTypesAfterAddingWindow();
        this.requiredFieldsOnSubWindowsFilled();
        if (this.generalTabComponent) {
            this.recheckRequiredFieldsOnMainTab();
            this.validateRequiredFields();
        }
    }

    private markDropdown(windowNum: number, areaNum: number, grillNum: number): void {
        let id = SidebarIdGenerator.muntinId(windowNum, areaNum, grillNum);
        this.removeSelectionFromDivs();
        document.getElementById(id).classList.add('selected');
    }

    private removeSelectionFromDivs(): void {
        let previouslySelected = document.getElementsByClassName('new-form-field selected');
        for (let i = 0; i < previouslySelected.length; i++) {
            previouslySelected.item(i).classList.remove('selected');
        }
    }

    private getSubwindowIndex(subwindow: SubWindowData): number {
        let subwindowNumber = 0;
        for (let window of this.designer.data.windows) {
            for (let sw of window.subWindows) {
                if (sw.generatedId === subwindow.generatedId) {
                    return subwindowNumber;
                }
                subwindowNumber++;
            }
        }
        let err = new Error("WindowEditorComponent.getSubwindowIndex(): Index not found!");
        err.name = ErrorNames.SELECTED_ELEMENT_DOES_NOT_EXIST;
        throw err;
    }

    private getIndexById(array: (AreaSpecification | Grill)[], object: AreaSpecification | Grill): number {
        let index = array.findIndex(element => element.generatedId === object.generatedId);
        if (index < 0) {
            let err = new Error("WindowEditorComponent.getIndexById(): Index not found!");
            err.name = ErrorNames.SELECTED_ELEMENT_DOES_NOT_EXIST;
            throw err;
        }
        return index;
    }

    private selectAccordionOnGeneralTab(type: WindowParams): void {
        this.mainTab.selected = true;
        let tabName = SidebarIdGenerator.getGeneralTabNameForSelectedElementsType(type, this.isTerrace);
        this.generalTabComponent.mainAcc.tabs.forEach(at => at.selected = at.id === tabName);
    }

    private selectAccordionOnSubWindowTab(field: string, areaNumber: number, swc: SubwindowComponent): void {
        switch (field) {
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS:
            case WindowEditorField.FILLING_WIDTH:
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
            case WindowEditorField.DECORATIVE_FILLING:
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
            case WindowEditorField.FILLING_INTERNAL_COLOR:
            case WindowEditorField.GLASS_SELECTOR:
            case WindowEditorField.GLASS:
            case WindowEditorField.DISTANCE_FRAME:
            case WindowEditorField.GLAZING_BEAD:
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
            case WindowEditorField.GLAZING_PACKAGE:
                this.selectAccordionTabsForClickedObject(swc.windowData, areaNumber, SidebarIdGenerator.tabNames.filling);
                break;
            case WindowEditorField.GRILL:
                this.selectAccordionTabsForClickedObject(swc.windowData, areaNumber, SidebarIdGenerator.tabNames.muntins);
                break;
            case WindowEditorField.MULLION:
                this.selectAccordionTabsForClickedObject(swc.windowData, null, SidebarIdGenerator.tabNames.mullions);
                break;
            default:
                this.selectAccordionTabsForClickedObject(swc.windowData, null, null);
                break;
        }
    }

    private selectAccordionForElementsType(elements: Snap.Element[], type: WindowParams): number {
        if (elements.length > 1 || this.isTerrace) {
            this.selectAccordionOnGeneralTab(type);
            return 0;
        } else {
            let elem = elements[0] as any;
            let subwindow: SubWindowData = elem.data(DataKeys.SUBWINDOW);
            let subwindowIndex = this.getSubwindowIndex(subwindow);
            switch (type) {
                case WindowParams.MUNTIN_ELEM:
                    if (this.isTerrace) {
                        this.throwUnsupportedTypeError();
                    }
                    let area = elem.data(DataKeys.AREA);
                    let grill = elem.data(DataKeys.GRILL);
                    let areaIndex = this.getIndexById(subwindow.areasSpecification, area);
                    let grillIndex = this.getIndexById(area.grills, grill);
                    this.selectAccordionTabsForClickedObject(subwindow, areaIndex, SidebarIdGenerator.tabNames.muntins);
                    this.markDropdown(subwindowIndex, areaIndex, grillIndex);
                    break;
                case WindowParams.MULLION_ELEM:
                    let mullion = elem.data(DataKeys.MULLION);
                    let mullionIndex = this.getIndexById(subwindow.mullions, mullion);
                    this.selectAccordionTabsForClickedObject(subwindow, null, SidebarIdGenerator.tabNames.mullions);
                    this.removeSelectionFromDivs();
                    let id = SidebarIdGenerator.mullionId(subwindow, mullionIndex);
                    document.getElementById(id).classList.add('selected');
                    break;
                case WindowParams.GLASS_ELEM:
                case WindowParams.GLAZING_BEAD_ELEM:
                    let fillingArea = elem.data(DataKeys.AREA);
                    let fillingAreaIndex = this.getIndexById(subwindow.areasSpecification, fillingArea);
                    this.selectAccordionTabsForClickedObject(subwindow, fillingAreaIndex,
                        SidebarIdGenerator.tabNames.filling);

                    this.removeSelectionFromDivs();
                    if (type === WindowParams.GLAZING_BEAD_ELEM) {
                        jQuery("#glazing-bead-field-container-" + fillingArea.ordinalNumber).find(".new-form-field").addClass("selected");
                    }

                    break;
                case WindowParams.FRAME_ELEM:
                    this.selectAccordionTabsForClickedObject(subwindow, null, null);
                    break;
                case WindowParams.VENTILATION_ELEM:
                    this.selectAccordionTabsForClickedObject(subwindow, null, null);
                    this.removeSelectionFromDivs();
                    document.getElementById(SidebarIdGenerator.ventilationId(subwindow)).classList.add('selected');
                    break;
                default:
                    this.throwUnsupportedTypeError();
                    break;
            }
            return subwindowIndex + 1;
        }
    }

    private throwUnsupportedTypeError(): void {
        let err = new Error("Unsupported type in selectAccordionForElementsType");
        err.name = ErrorNames.SELECTED_ELEMENT_UKNOWN_TYPE;
        throw err;
    }

    protected isSystemChangeInProgress(): boolean {
        return this.sidebarInitialized && this.isWindowSystemDataLoadInProgress();
    }

    private isWindowSystemDataLoadInProgress(): boolean {
        return this.dataLoadingStatus.catalog || this.dataLoadingStatus.businessTypesLoadInProgress ||
            this.dataLoadingStatus.windowSystemsByBusinessTypes;
    }

    onBusinessTypesLoadInProgressChange(loadInProgress: boolean): void {
        if (loadInProgress) {
            this.blockUiController.block('WindowEditorBusinessTypes');
        } else {
            this.blockUiController.unblock('WindowEditorBusinessTypes');
        }
        this.dataLoadingStatus.businessTypesLoadInProgress = loadInProgress;
    }

    openBusinessTypeChangeDialog(subwindow: SubWindowData): void {
        this.designer.openBusinessTypeChangeDialog(subwindow.generatedId);
    }

    veneerChangeEvent(event: VeneerEvent) {
        return this.designer.veneerChangeEvent(event);
    }

    showAddonsTabView(): void {
        this.deregisterHotkeys();
        this.isAddonsTabViewVisible = true;
    }

    closeAddonsTabView(): void {
        this.isAddonsTabViewVisible = false;
        this.registerHotkeys();
        this.redrawWindow();
    }

    showConfigAddonsList(): void {
        this.deregisterHotkeys();
        let anyOldConfigs = (this.designer.configurableAddonPositions || []).some(pos => pos.deprecated);
        if (anyOldConfigs) {
            this.isConfigAddonsListVisible = true;
        } else {
            this.isConfigSystemsListVisible = true;
        }
    }

    closeConfigAddonsList(): void {
        this.isConfigAddonsListVisible = false;
        this.isConfigSystemsListVisible = false;
        this.registerHotkeys();
    }

    windowAddonsSave(windowAddonSaveData: WindowAddonSaveData): void {
        if (windowAddonSaveData.action === WindowAddonActionType.ADD) {
            this.designer.addWindowAddonToData(windowAddonSaveData.addonToSave, windowAddonSaveData.positionlistAddon);
            this.growls.info('OFFER.DRAWING.ADDONS.ADDED');
        } else {
            if (this.designer.editWindowAddonInData(windowAddonSaveData.addonToSave)) {
                this.growls.info('OFFER.DRAWING.ADDONS.EDITED');
            }
        }
        this.designer.unsavedChanges = true;
        this.designer.updateUsedGlobalSettingsFlagToChanged();
    }

    windowAddonsRemove(addonId): void {
        this.designer.removeWindowAddonToData(addonId);
        this.growls.info('OFFER.DRAWING.ADDONS.REMOVED');
        this.designer.unsavedChanges = true;
        this.designer.updateUsedGlobalSettingsFlagToChanged();
    }

    private validateGrillAndMullionPositions(): void {
        AreaUtils.setGlazingsESG(this.designer.data, this.glasses);
        this.designer.data.windows.forEach(w => w.subWindows.forEach(sw => {
            let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPoints(sw, this.designer.data.cuts,
                this.designer.totalBoundingBox, this.designer.profileCompositionDistances,
                this.designer.isValidationDisabled());
            MullionUtils.validateSpacingBetweenMullions(sw, totalGlazingBead, this.designer.profileCompositionDistances);
            GrillPositionValidator.validateGrills(sw, totalGlazingBead, this.designer.profileCompositionDistances);
        }));
    }

    handleError(haveErrors: boolean, section: SidebarSection): void {
        switch (section) {
            case SidebarSection.MAIN:
                this.generalTabHaveErrors.main = haveErrors;
                break;
            case SidebarSection.FILLING:
                this.generalTabHaveErrors.filling = haveErrors;
                break;
            case SidebarSection.COLOR:
                this.generalTabHaveErrors.color = haveErrors;
                break;
            case SidebarSection.FITTINGS:
                this.generalTabHaveErrors.fittings = haveErrors;
                break;
            case SidebarSection.MULLIONS:
                this.generalTabHaveErrors.mullions = haveErrors;
                break;
            case SidebarSection.MUNTINS:
                this.generalTabHaveErrors.muntins = haveErrors;
                break;
            default:
                return;
        }
    }

    getSubwindowErrorStyle(subwindowIndex: number): string {
        return this.highlightValidationErrors && this.allTabsFieldsFilled.length !== 0 && !this.allTabsFieldsFilled[subwindowIndex]
            ? 'errors-on-tab'
            : '';
    }

    onGeneralTabInitialized(generalTabHaveErrors: any): void {
        setTimeout(() => {
            this.generalTabHaveErrors = generalTabHaveErrors;
            this.recheckRequiredFieldsOnMainTab();
            this.validateRequiredFields();
        }, 1);
    }

    recheckRequiredFieldsOnMainTab(): void {
        this.generalTabComponent.sectionContainsErrors(SidebarSection.FILLING);
        this.generalTabComponent.sectionContainsErrors(SidebarSection.FITTINGS);
        this.generalTabComponent.sectionContainsErrors(SidebarSection.COLOR);
        this.generalTabComponent.sectionContainsErrors(SidebarSection.MULLIONS);
        this.generalTabComponent.sectionContainsErrors(SidebarSection.MUNTINS);
        this.generalTabComponent.sectionContainsErrors(SidebarSection.ADDONS);
        this.generalTabComponent.sectionContainsErrors(SidebarSection.MAIN);
    }

    generalTabContainsErrors(): boolean {
        return this.highlightValidationErrors &&
            (this.generalTabHaveErrors.filling || this.generalTabHaveErrors.main || this.generalTabHaveErrors.color
                || this.generalTabHaveErrors.fittings || this.generalTabHaveErrors.muntins
                || (this.allTabsFieldsFilled.length !== 0
                    && _.compact(this.allTabsFieldsFilled).length !== this.designer.windowTabsData.length))
            || this.isDataModificationMode()
            && this.generalTabComponent && this.generalTabComponent.webshopChargeValidationErrors[`name[${this.generalTabComponent.translate.currentLang}]`] != undefined;
    }

    private refilterIntersectingGrillColors() {
        let grillIds = [];
        let grillColorIds = [];
        for (let w of this.designer.data.windows) {
            for (let sw of w.subWindows) {
                for (let a of sw.areasSpecification) {
                    for (let g of a.grills) {
                        grillIds.push(g.id);
                        grillColorIds.push(g.colorId);
                    }
                }
            }
        }
        let intersectingGrillColors = [...this.allActiveColors];
        let intersectingGrillRalColors = [...this.allActiveColors];
        let intersectingGrillNcsColors = [...this.allActiveColors];
        _.uniq(grillIds).forEach(id => {
            intersectingGrillColors = intersectingGrillColors.filter(gc => _.contains(this.grillColors[id], gc));
            intersectingGrillRalColors = intersectingGrillRalColors.filter(gc => _.contains(this.grillRalColors[id], gc));
            intersectingGrillNcsColors = intersectingGrillNcsColors.filter(gc => _.contains(this.grillNcsColors[id], gc));
        });

        this.intersectingGrillColors = intersectingGrillColors;
        this.intersectingGrillRalColors = intersectingGrillRalColors;
        this.intersectingGrillNcsColors = intersectingGrillNcsColors;

        if (this.intersectingGrillRalColors.length > 0) {
            this.intersectingGrillColors.push(this.getEmptyRalColor());
        }
        if (this.intersectingGrillNcsColors.length > 0) {
            this.intersectingGrillColors.push(this.getEmptyNcsColor());
        }
        _.uniq(grillColorIds).forEach(usedGrillColorId => {
            const ralColor = this.intersectingGrillRalColors.find(ral => ral.id === usedGrillColorId);
            if (ralColor != undefined && this.intersectingGrillColors.find(gc => gc.id === usedGrillColorId) == undefined) {
                this.intersectingGrillColors.push(ralColor);
            }
        });
        _.uniq(grillColorIds).forEach(usedGrillColorId => {
            const ncsColor = this.intersectingGrillNcsColors.find(ncs => ncs.id === usedGrillColorId);
            if (ncsColor != undefined && this.intersectingGrillColors.find(gc => gc.id === usedGrillColorId) == undefined) {
                this.intersectingGrillColors.push(ncsColor);
            }
        });
    }

    public onSidebarValueChange(redraw = false): void {
        this.designer.updateUsedGlobalSettingsFlagToChanged();
        this.validateRequiredFields();
        this.drawingToolsControl.markForCheck();
        if (redraw) {
            this.designer.redrawWindow(true, true);
        }
    }

    public onBeforeSidebarValueChange(): void {
        this.drawingToolsControl.cancelMode();
        this.drawingToolsControl.markForCheck();
        this.designer.saveStepInHistory();
    }

    public onSubWindowValueChange(): void {
        this.validateRequiredFields();
        this.drawingToolsControl.markForCheck();
    }

    public onBeforeSubWindowValueChange(): void {
        this.drawingToolsControl.cancelMode();
        this.drawingToolsControl.markForCheck();
        this.designer.saveStepInHistory();
    }

    onConfigurableAddonDialogClose(): void {
        this.configurableAddonDialogData = undefined;
    }

    onConfigurableAddonDialogSuccess(events: ConfigurableAddonDialogEventModel[]): void {
        if (events == null) {
            this.onConfigurableAddonDialogClose();
            return;
        }
        this.designer.saveStepInHistory();
        this.configurableAddonDialogData = undefined;
        let firstEvent = events[0];
        if (firstEvent.getActionName() === DialogType.EDIT_CONFIG_ADDON) {
            let id = firstEvent.getPosition().id || firstEvent.getPosition().assignedId;
            let editedModel = this.designer.configurableAddonPositions.find(
                pos => pos.position.assignedId === id || pos.position.id === id);
            editedModel.position = firstEvent.getPosition();
            editedModel.configurableAddon = firstEvent.getConfigurableAddon();
            ConfigurableAddonUtils.perpetuateEditedAddonModel(id, this.designer.configurableAddonPositions);
        } else {
            for (let event of events) {
                let configurableAddonPosition = new ConfigurableAddonPositionModel(event.getPosition(),
                    event.getConfigurableAddon());
                this.designer.configurableAddonPositions.push(configurableAddonPosition);
                this.designer.addCategoriesWithAutoOptions(event.getCategoriesWithAutoOptions());
            }
            ConfigurableAddonUtils.addNewAddonToSelectedElement(events,
                this.designer.clickedSnapElements, this.designer.data.configurableAddonIds);
            this.generalTabComponent.cd.markForCheck();
            this.redrawWindow();
        }
    }

    setErrorToPricingStatus() {
        this.designer.pricingStatus.errors = true;
    }

    setErrorToValidationStatus() {
        this.designer.validationStatus.errors = true;
    }

    private initPendingGrillColor(preservePrevious = false): void {
        let grill = this.designer.pendingGrillData.grill;
        if (grill) {
            let grillColors: Color[] = this.grillColors[grill.id];
            if (grillColors && grillColors.length > 0) {
                if (preservePrevious && this.findIdInList(grillColors, grill.colorId) !== undefined) {
                    return;
                }
                let externalWindowColorId = this.designer.data.specification.colorIdExternal;
                let externalWindowColor = this.findValueInList(this.allActiveColors, externalWindowColorId);
                let matchingColorId = externalWindowColor != undefined ? externalWindowColor.matchingColorId : undefined;
                matchingColorId = this.findIdInList(grillColors, matchingColorId); // make sure it's present
                let commonColorId = this.findIdInList(grillColors, externalWindowColorId);
                grill.colorId = commonColorId || matchingColorId || grillColors[0].id;
            } else {
                grill.colorId = undefined;
            }
        }
    }

    hideSettingsDialog(): void {
        this.showSettingsDialog = false;
    }

    private checkAndFillGrillColorsFromGlobalSelection(grillId: number, colorId: number): boolean {
        if (this.findValueInList(this.grillColors[grillId], colorId) != undefined) {
            return true;
        }
        let extraColor = this.findValueInList(this.grillRalColors[grillId], colorId);
        if (extraColor != undefined) {
            this.grillColors[grillId] = [...this.grillColors[grillId], extraColor];
            return true;
        }
        extraColor = this.findValueInList(this.grillNcsColors[grillId], colorId);
        if (extraColor != undefined) {
            this.grillColors[grillId] = [...this.grillColors[grillId], extraColor];
            return true;
        }
        return false;
    }

    handleGrillParamsChange(event: GrillParamsSelectedEvent) {
        if (_.contains(event.keys, 'id')) {
            this.initPendingGrillColor(true);
            this.designer.setPendingGrillOrMullionData();
            // redraw to change clickable green overlay for constructional/decorative mullion  and grills interactions
            this.redrawWindow();
            if (this.designer.pendingOperationLineHelper != null) {
                this.designer.pendingOperationLineHelper.attr({strokeWidth: this.designer.pendingGrillData.grill.width});
            }
        }
        this.designer.isErrorsPresent();
        this.saveValues(event.restoreKey, event.keys);
    }

    onSidebarQuantityChange(): void {
        if (this.designer.configurableAddonPositions.length > 0) {
            this.showUpdateConfigurableAddonsQuantityDialog = true;
        }
    }

    updateConfigurableAddonsPositionQuantity(): void {
        this.designer.configurableAddonPositions.forEach(addonPosition =>
            addonPosition.position.quantity = this.designer.offerPosition.quantity);

        this.hideUpdateConfigurableAddonsQuantityDialog();
    }

    hideUpdateConfigurableAddonsQuantityDialog(): void {
        this.showUpdateConfigurableAddonsQuantityDialog = false;
    }

    descriptionDialogClosed(): void {
        this.showDescriptionDialog = false;
    }

    onShowDescriptionDialog(): void {
        this.showDescriptionDialog = true;
    }

    handleOnCoreColorChange(color: Color): void {
        this.chosenCoreColor = color;
    }

    private processNewCreatedChanges(operationResult: OperationResult): void {
        if (operationResult.areasChanged) {
            this.resetAllGlazingBeadIfNeeded();
        }
        operationResult.areasChanged = false;
        this.designer.markRemovedConfigurableAddons(operationResult.deletedConfigurableAddonIds);
        operationResult.validationGrowls.forEach(message => this.growls.warning(message));
    }

    private filterAvailableWindowSystems(systems: WindowEditorWindowSystemInterface[]): void {
        let skipCombineCheck = this.designer.data.windows.length <= 1;
        let currentSystem = this.designer.getWindowSystem();
        this.filteredWindowSystems = systems.filter(s =>
            (this.sidebarOnlyMode ||
                (s.doors === currentSystem.doors && (skipCombineCheck || s.canCombineBusinessTypes))
            )
        );
    }

    private recreateMullionsAndGrills(data: DrawingData) {
        try {
            GrillUtils.initializeGrillsAngularity(data, this.grills);
            MullionUtils.repositionMullionsAndGrills(data, this.designer.profileCompositionDistances, null, null, true);
        } catch (e) {
            this.errors.handleFE(e);
        }
    }

    redrawWindow(omitPricingAndValidation = true): void {
        omitPricingAndValidation = omitPricingAndValidation || !this.highlightValidationErrors;
        this.designer.redrawWindow(omitPricingAndValidation, omitPricingAndValidation);
    }

    windowTabTrackBy = (index: number, windowTab: WindowTabData) => {
        return windowTab.windowData.generatedId;
    }

    getTabpanelHeaderStyle(status: ResponseStatusFlags): string {
        return ResponseStatusHelper.tabpanelHeaderStyle(status);
    }

    onSubwindowTypeChange(generatedId: string): void {
        let swComponent = this.subWindowComponents.find(swc => swc.windowData.generatedId === generatedId);
        if (swComponent != null) {
            swComponent.onTypeChange();
            this.loadSystemsFilteredByExistingBusinessTypesAfterAddingWindow();
        }
    }

    onMirrorToolUsed() {
        this.designer.mirrorTool(() => this.loadSystemsFilteredByExistingBusinessTypesAfterAddingWindow());
    }

    isDataModificationMode(): boolean {
        return isDataModificationMode(this.dataModificationMode);
    }

    updateStoredWindowSize(event: { oldWidth: number, oldHeight: number, newWidth: number, newHeight: number }): void {
        if (event.oldWidth !== event.newWidth) {
            this.windowEditorFieldContentProvider.notifyFieldChanged(WindowEditorField.WIDTH_GREATER_THAN, event.newWidth);
            this.windowEditorFieldContentProvider.notifyFieldChanged(WindowEditorField.WIDTH_LESS_THAN, event.newWidth);
        }
        if (event.oldHeight !== event.newHeight) {
            this.windowEditorFieldContentProvider.notifyFieldChanged(WindowEditorField.HEIGHT_GREATER_THAN, event.newHeight);
            this.windowEditorFieldContentProvider.notifyFieldChanged(WindowEditorField.HEIGHT_LESS_THAN, event.newHeight);
        }
    }

    setDefaultDecorativeGlazing() {
        this.designer.commonData.glazingGlassQuantity = this.availableGlassCountsForDecor[0];
        this.designer.setDefaultDecorativeGlazingData(null, this.availableGlassCountsForDecor[0]);
        let windowSystem = this.designer.getWindowSystem();
        if (windowSystem.glazingPackage != null && !this.sidebarOnlyMode) {
            GlazingHelper.applyGlazingBasedOnArea(this.designer.data, windowSystem.glazingPackage,
                this.designer.commonData.glazingGlassQuantity, false);
            this.clearESGGlassesIfNeeded();
            this.designer.setCommonValues();
        }
    }

    onGlobalSetGlazingPackage(glazingPackageId: number) {
        this.designer.commonData.glazingPackageId = glazingPackageId;
        if (glazingPackageId) {
            let glazingPackage = this.availableGlazingPackages.find(gp => gp.id === glazingPackageId);
            Glazing.copyFields(glazingPackage.glazing, this.designer.commonData);
            this.designer.commonData.glazingBeadId = glazingPackage.glazingBeadId;
            this.designer.commonData.glazingCategoryId = glazingPackage.glazingCategoryId;
            this.designer.commonData.glazingFrameCategoryId = glazingPackage.frameCategoryId;
            this.designer.data.windows.forEach(window => {
                window.subWindows.forEach(sw => {
                    sw.areasSpecification.forEach(area => {
                        area.glazing = glazingPackage.glazing;
                        area.glazingBead.id = glazingPackage.glazingBeadId;
                        area.glazingPackageId = glazingPackageId;
                        area.glazingCategoryId = glazingPackage.glazingCategoryId;
                        area.glazingFrameCategoryId = glazingPackage.frameCategoryId;
                    });
                });
            });
        }
    }

    onGlobalSetGlazingCategory(categoryId: number) {
        this.designer.commonData.glazingCategoryId = categoryId;
        if (categoryId) {
            this.designer.data.windows.forEach(window => {
                window.subWindows.forEach(sw => {
                    sw.areasSpecification.forEach(area => {
                        area.glazingCategoryId = categoryId;
                    });
                });
            });
        }
    }

    onGlobalSetGlazingFrameCategory(categoryId: number) {
        this.designer.commonData.glazingFrameCategoryId = categoryId;
        if (categoryId) {
            this.designer.data.windows.forEach(window => {
                window.subWindows.forEach(sw => {
                    sw.areasSpecification.forEach(area => {
                        area.glazingFrameCategoryId = categoryId;
                    });
                });
            });
        }
    }

    onGlobalSetGraspGlazingQuantity(quantity: number) {
        this.designer.commonData.glazingGlassQuantity = quantity;
        this.designer.commonData.glazingCategoryId = undefined;
        this.designer.commonData.glazingFrameCategoryId = undefined;
        this.designer.commonData.glazingPackageId = undefined;
        if (quantity) {
            this.designer.data.windows.forEach(window => {
                window.subWindows.forEach(sw => {
                    sw.areasSpecification.forEach(area => {
                        area.glazing.glazingGlassQuantity = quantity;
                        area.glazingCategoryId = undefined;
                        area.glazingFrameCategoryId = undefined;
                        area.glazingPackageId = undefined;
                    });
                });
            });
        }
        if (this.modelMode) {
            if (quantity) {
                const selectedGlazingPackage = this.availableWebshopGlazingPackages
                    .find(glazing => glazing.id === this.selectedWebshopGlazingPackageId);
                if (selectedGlazingPackage) {
                    this.setCommonDataGlazingData(selectedGlazingPackage);
                    const graspGlazingPackageId = selectedGlazingPackage['graspGlazingPackageId' + quantity];
                    const graspGlazingPackage = this.availableGlazingPackages.find(gp => gp.id === graspGlazingPackageId);
                    if (graspGlazingPackage) {
                        this.designer.commonData.glazingCategoryId = graspGlazingPackage.glazingCategoryId;
                        this.designer.commonData.glazingFrameCategoryId = graspGlazingPackage.frameCategoryId;
                        this.designer.commonData.glazingPackageId = graspGlazingPackage.id;
                        this.resetAllGlazingBeadIfNeeded();
                    }
                }
            }
        }
    }

    subwindowGlazingQuantityChange(event: GlassChangeEvent): void {
        this.cancelMode();
        this.designer.saveStepInHistory(this.designer.data);
        event.area.glazing.glazingGlassQuantity = event.newValue;
        event.area.glazingCategoryId = undefined;
        event.area.glazingFrameCategoryId = undefined;
        event.area.glazingPackageId = undefined;
        this.designer.setCommonValues();
        this.redrawWindow();
        this.changeDetector.markForCheck();
    }

    subwindowGlazingCategoryChange(event: GlassChangeEvent): void {
        this.cancelMode();
        this.designer.saveStepInHistory(this.designer.data);
        event.area.glazingCategoryId = event.newValue;
        event.area.glazingPackageId = undefined;
        this.designer.setCommonValues();
        this.redrawWindow();
        this.changeDetector.markForCheck();
    }

    subwindowGlazingFrameCategoryChange(event: GlassChangeEvent): void {
        this.cancelMode();
        this.designer.saveStepInHistory(this.designer.data);
        event.area.glazingFrameCategoryId = event.newValue;
        event.area.glazingPackageId = undefined;
        this.designer.setCommonValues();
        this.redrawWindow();
        this.changeDetector.markForCheck();
    }

    subwindowGlazingPackageChange(event: GlassChangeEvent): void {
        this.cancelMode();
        this.designer.saveStepInHistory(this.designer.data);
        event.area.glazingPackageId = event.newValue;
        this.designer.setCommonValues();
        this.redrawWindow();
        this.changeDetector.markForCheck();
    }

    private catalogInitialization(loadCatalog: boolean, offerId: number) {
        const setDefaults = this.sidebarOnlyMode || this.newOffer;
        forkJoin({
            windowSystemsForVeneer: loadCatalog
                ? this.loadWindowSystemsForVeneer(this.designer.data.windowSystemId)
                : of<WindowEditorWindowSystemInterface[]>([]),
            windowSystems: this.loadAvailableWindowSystems(this.designer.data),
            ...this.windowEditorFieldContentProvider.getCatalogForWindowSystemDataSources(
                loadCatalog ? this.designer.data.windowSystemId : undefined, offerId, this.modelMode,
                setDefaults ? this.windowSystemDefaults : this.designer.data, this.readOnlyMode, setDefaults),
            windowSystem: this.designer.data.windowSystemId
                && (!this.designer.windowSystem || this.designer.windowSystem.id !== this.designer.data.windowSystemId)
                    ? this.windowSystemDefinitionService.getSystem(this.designer.data.windowSystemId)
                    : of(this.designer.windowSystem)
        }).pipe(
            finalize(() => {
                this.drawingToolsControl.markForCheck();
                this.blockUiController.unblock('WindowEditorAfterInit');
                this.blockUiController.unblock('WindowEditorInit');
            }))
            .subscribe(
                catalogData => {
                    this.filterAvailableWindowSystems(catalogData.windowSystems);
                    this.addEditedWindowSystemIfItIsCurrentlyUnavailable(this.designer.data.windowSystemId, catalogData.windowSystem);
                    if (loadCatalog) {
                        this.windowSystemsForVeneer = catalogData.windowSystemsForVeneer;
                        this.initCatalog(catalogData, setDefaults ? this.windowSystemDefaults : this.designer.data, setDefaults);
                        this.designer.setCatalogData(
                            new CatalogData(catalogData.windowSystem, this.windowSystemDefaults, this.grills, this.angledGrills,
                                this.systemMullions, this.glasses, this.frames, this.systemDecorativeFillings, this.allActiveColors,
                                this.filteredWindowSystems, this.allAddons, this.designer.getWindowSystem().windowSystemDrawingToolsEnabler,
                                this.allConfigAddonDefinitions));
                    }
                    this.availableGlassCounts = GlazingHelper.getAvailableGlassCount(this.designer.getWindowSystem(), false);
                    this.availableGlassCountsForDecor = GlazingHelper.getAvailableGlassCount(this.designer.getWindowSystem(), true);
                    this.filteredGlassCounts = this.availableGlassCounts;
                    this.initWindowViews(this.designer.data);
                    if (loadCatalog && catalogData.windowSystems.length > 0) {
                        this.setDefaultWindowSystemValues();
                    }
                    this.rebindFieldSelections(this.designer.data, this.designer.windowSystem, this.designer.profileCompositionDistances,
                        isUpsellingMode(this.dataModificationMode));
                    if (!this.sidebarOnlyMode) {
                        if (loadCatalog) {
                            AlignmentTool.autoAlign(this.designer.getWindowSystem(),
                                this.designer.profileCompositionDistances, this.designer.data);
                            this.designer.guides.rebuildStructureGuides();
                        }
                        AreaUtils.recalculateAreaSizes(this.designer.data.windows, this.designer.data.cuts,
                            this.designer.totalBoundingBox, this.designer.profileCompositionDistances,
                            this.designer.isValidationDisabled());
                    }
                    this.refilterIntersectingGrillColors();
                    this.dataLoadingStatus.catalog = false;
                    this.sidebarInitialized = true;
                });
    }

    removeFieldFromUnavailable(field: string) {
        const unavailableFields = this.windowEditorFieldContentProvider.fieldsWithUnavailableValues;
        const index = unavailableFields.indexOf(field, 0);
        if (index > -1) {
            unavailableFields.splice(index, 1);
        }
    }

    checkGlazingItemsAvailability(unavailableItems: GlassWithPosition[] | DistanceFrame[], attr: GlazingItemAttr): string {
        const windows = this.designer.data.windows;
        let detectedUnavailable = false;
        let areaWithUnavailable: string;
        if (unavailableItems.length > 0 && (windows.length > 1 || windows[0].subWindows.length > 1)) {
            windows.forEach(window => {
                window.subWindows.forEach(sw => {
                    sw.areasSpecification.forEach(area => {
                        for (let i = 1; i <= 4; i++) {
                            if (unavailableItems.some(item => item.id === area.glazing[attr + i + 'id'])) {
                                detectedUnavailable = true;
                                if (!areaWithUnavailable) {
                                    areaWithUnavailable = area.generatedId;
                                }
                                break;
                            }
                        }
                    });
                });
            });
            if (!detectedUnavailable) {
                this.removeFieldFromUnavailable(attr === GlazingItemAttr.GLASS ? WindowEditorField.GLASS
                    : WindowEditorField.DISTANCE_FRAME);
            }
        } else {
            this.removeFieldFromUnavailable(attr === GlazingItemAttr.GLASS ? WindowEditorField.GLASS
                : WindowEditorField.DISTANCE_FRAME);
        }
        return areaWithUnavailable;
    }

    checkItemsAvailability(field: string): string {
        const windows = this.designer.data.windows;
        let areaWithUnavailable: string;
        if (windows.length > 0) {
            let detectedUnavailable = false;
            const unavailableItems: SelectItem[] = this.windowEditorFieldContentProvider.getItems(field).filter(item => !item.available);
            windows.forEach(window => {
                window.subWindows.forEach(sw => {
                    if (field === WindowEditorField.MULLION) {
                        sw.mullions.forEach(mullion => {
                            if (unavailableItems.some(item => item.value === mullion.id)) {
                                detectedUnavailable = true;
                                if (!areaWithUnavailable) {
                                    areaWithUnavailable = sw.areasSpecification[0].generatedId;
                                }
                            }
                        });
                    } else {
                        sw.areasSpecification.forEach(area => {
                            if (field === WindowEditorField.GRILL) {
                                area.grills.forEach(grill => {
                                    if (unavailableItems.some(item => item.value === grill.id)) {
                                        detectedUnavailable = true;
                                        if (!areaWithUnavailable) {
                                            areaWithUnavailable = area.generatedId;
                                        }
                                    }
                                });
                            } else {
                                if (unavailableItems.some(item => item.value === WindowEditorComponent.getAttrIdByField(field, area))) {
                                    detectedUnavailable = true;
                                    if (!areaWithUnavailable) {
                                        areaWithUnavailable = area.generatedId;
                                    }
                                }
                            }
                        });
                    }
                });
            });
            if (!detectedUnavailable) {
                this.removeFieldFromUnavailable(field);
            }
        } else {
            this.removeFieldFromUnavailable(field);
        }
        return areaWithUnavailable;
    }

    getSubWindowName(subwindow: SubWindowData): string {
        return UpsellingUtils.UPSELLING_CHARGE_DUMMY_ID === subwindow.generatedId ?
            this.designer.translate.instant('OFFER.TABS.CHARGE_SUBWINDOW_TAB_LABEL') : this.designer.getSubWindowName(subwindow);
    }
}

enum GlazingItemAttr {
    GLASS = 'glass',
    FRAME = 'frame'
}
