import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from "primeng/table";
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from "rxjs/operators";
import {CatalogItemNameAndActive} from "../../../common/crud-common/catalog-item-name-and-active";
import {COMMON_INPUT_LENGTH_LIMITS} from "../../../common/crud-common/common-input-length-limits";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DatatableInterface, TableToDatatableInterfaceAdapter} from "../../../common/DatatableHelper";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {MultiValidator} from "../../../shared/validator/input-validator";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, MaterialField} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {MaterialFieldUsage} from "../catalog-field-usage";
import {ColorService} from "../color/color.service";
import {ConfigSystemService} from "../config-system/config-system.service";
import {LinkableEntities} from "../link-selection/link-selection.component";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {Material} from "./material";
import {MaterialService} from "./material.service";

@Component({
    selector: 'app-material',
    templateUrl: './material.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [MaterialService, DataServiceHelper, TranslatedSelectItemService, ColorService, ConfigSystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialComponent extends CrudCommonComponent<Material, MaterialService> implements OnInit, AfterViewInit {

    readonly LinkableEntity = LinkableEntities;

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => this.validateDataStep()
        },
        SYSTEMS: {
            id: 'SYSTEMS'
        }
    };

    @ViewChild('table')
    table: Table;

    private readonly configSystemService: ConfigSystemService;
    private readonly colorService: ColorService;

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState = true;

    colors: CatalogItemNameAndActive[];
    configSystems: ItemForCatalogLinking[];
    selectedConfigSystems: number[] = [];

    editPermits: FieldLimitation[] = [];
    fieldUsage: MaterialFieldUsage;
    CatalogTab = CatalogTab;
    MaterialField = MaterialField;

    showColorLinks = false;
    selectedMaterial: Material;

    // inputSelectedMap: Map<number, number>;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, MaterialService, 'ADDON_MATERIAL', 'Material');
        this.configSystemService = injector.get(ConfigSystemService);
        this.colorService = injector.get(ColorService);
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
        this.fieldUsage = new MaterialFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        forkJoin({
            configSystems: this.configSystemService.getConfigAddonsForCatalogLinking(),
            editPermits: this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.MATERIALS),
            colors: this.itemService.getColors()
        }).subscribe(data => {
            this.configSystems = data.configSystems;
            this.editPermits = data.editPermits.fieldsLimitations;
            this.colors = data.colors;
            this.changeDetector.markForCheck();
        });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.table != undefined) {
            this.table.filter(true, 'active', 'equals');
        }
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    getNewItem(): Material {
        this.selectedConfigSystems = [];
        return new Material();
    }

    loadEditedItem(event: { data: Material }): Observable<Material> {
        return forkJoin({
            item: this.itemService.getItem(event.data.id),
            file: this.itemService.getImageAsFile(event.data.id),
            linkedConfigSystems: this.itemService.getLinkedSystems(event.data.id)
        }).pipe(tap(data => {
            if (this.copyMode) {
                data.item.id = undefined;
            }
            this.selectedConfigSystems = data.linkedConfigSystems;
            this.file = data.file;
        }), map(data => data.item));
    }

    showDialogToCopy() {
        this.loadEditedItem({data: this.selectedItem}).subscribe({
            next: item => {
                this.item = item;
                this.setDisplayDialog(true);
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    submit() {
        this.validateFormImpl().subscribe(validationOk => {
            if (!validationOk) {
                return;
            }
            let requestItem = this.prepareItemForRequest();

            if (this.isSaveInProgress()) {
                return;
            } else {
                this.setSaveInProgress(true);
            }

            const saveObservable: Observable<number> = (() => {
                if (this.copyMode) {
                    return this.itemService.copyItem(this.selectedItem.id, requestItem, this.file);
                }
                if (this.itemId == undefined) {
                    return this.itemService.addItem(requestItem, this.file);
                }
                return this.itemService.editItem(this.itemId, requestItem, this.file);
            })();

            saveObservable
                .pipe(mergeMap(itemId => this.itemService.editLinks(itemId, this.selectedConfigSystems).pipe(map(() => itemId))))
                .subscribe(this.genericCleanupAndReloadSuccessObserver());
        });
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }

    private validateDataStep(): Observable<boolean> {
        const validationErrors = {};

        validationErrors[`name[${this.translate.currentLang}]`] = MultiValidator.of(`error.materialDto.name[${this.translate.currentLang}]`)
            .withNotNullValidator()
            .withNotBlankValidator()
            .withSizeValidator(0, COMMON_INPUT_LENGTH_LIMITS.NAME_MAX_LENGTH)
            .validate(this.item.name[this.translate.currentLang]);
        validationErrors[`shortcut[${this.translate.currentLang}]`] = MultiValidator.of(`error.materialDto.shortcut[${this.translate.currentLang}]`)
            .withSizeValidator(0, COMMON_INPUT_LENGTH_LIMITS.SHORTCUT_MAX_LENGTH)
            .validate(this.item.shortcut[this.translate.currentLang]);
        validationErrors[`symbol`] = MultiValidator.of(`error.materialDto.symbol`)
            .withNotNullValidator()
            .withNotBlankValidator()
            .withSizeValidator(0, 50)
            .validate(this.item.symbol);
        validationErrors[`active`] = MultiValidator.of(`error.materialDto.active`)
            .withNotNullValidator()
            .validate(this.item.active);
        if (this.item.id != null) {
            validationErrors['sortIndex'] = MultiValidator.of('error.materialDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999999999)
                .validate(this.item.sortIndex);
        }

        return (this.validationErrorsPresent(validationErrors) ? of(validationErrors) : this.itemService.validate(this.item))
            .pipe(
                tap(backendValidationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors))
            );
    }

    editColorLinks(item: Material) {
        this.showColorLinks = true;
        this.selectedMaterial = item;
    }
}
