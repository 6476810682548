<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'GLAZING_BEAD.TITLE' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLAZING_BEAD.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLAZING_BEAD.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [(selection)]="selectedItem" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="glazingBeadTable">

        <p-headerColumnGroup>
            <p-row>
                <p-column *ngIf="fieldUsage.show(GlazingBeadField.NAME)"
                          header="{{'GLAZING_BEAD.FORM.NAME'|translate}}" [rowspan]="2" [sortable]="true" [filter]="showFilters"
                          filterMatchMode="contains" field="name"></p-column>
                <p-column [rowspan]="2" [sortable]="true" field="sortIndex" header="{{'GLAZING_BEAD.FORM.SORT_INDEX'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(GlazingBeadField.SHORTCUT)"
                          header="{{'GLAZING_BEAD.FORM.SHORTCUT'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" field="shortcut"></p-column>
                <p-column *ngIf="fieldUsage.show(GlazingBeadField.SYMBOL)"
                          header="{{'GLAZING_BEAD.FORM.SYMBOL'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" field="symbol"></p-column>
                <p-column *ngIf="fieldUsage.show(GlazingBeadField.THICKNESS)"
                          header="{{'GLAZING_BEAD.FORM.THICKNESS'|translate}}" [rowspan]="2" field="thickness"
                          [sortable]="true" [filter]="showFilters" filterMatchMode="contains" filterType="number"
                          [filterNumericMinValue]="1" [filterNumericMaxValue]="99999"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)"
                          header="{{'GLAZING_BEAD.FORM.SYSTEMS'|translate}}" [colspan]="4"></p-column>
                <p-column *ngIf="fieldUsage.show(GlazingBeadField.ACTIVE)"
                          header="{{'COLOR.FORM.STATUS'|translate}}" [rowspan]="2" field="active" [sortable]="false"
                          [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
            <p-row>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.PCV'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.WOOD'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" header="{{'MATERIAL.ALUMINIUM_WOOD_MIX'|translate}}"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <!--Name-->
        <p-column *ngIf="fieldUsage.show(GlazingBeadField.NAME)"
                  field="name" [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.names[userLang]}}</span>
            </ng-template>
        </p-column>

        <!--SortIndex-->
        <p-column [sortable]="true" field="sortIndex"></p-column>

        <!--shortcut-->
        <p-column *ngIf="fieldUsage.show(GlazingBeadField.SHORTCUT)"
                  field="shortcut" [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.shortcut[userLang]}}</span>
            </ng-template>
        </p-column>

        <!--Symbol-->
        <p-column *ngIf="fieldUsage.show(GlazingBeadField.SYMBOL)"
                  field="symbol" [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.symbol}}</span>
            </ng-template>
        </p-column>

        <!--Thickness-->
        <p-column *ngIf="fieldUsage.show(GlazingBeadField.THICKNESS)"
                  field="thickness" [sortable]="true" [filter]="showFilters" filterMatchMode="contains" filterType="number"
                  [filterNumericMinValue]="1">
            {{item.thickness}}
        </p-column>

        <!--System - PCV-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="pcv">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.pcv)" (ngModelChange)="materialChange(item, 'PCV')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Aluminium-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminium">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminium)" (ngModelChange)="materialChange(item, 'ALUMINIUM')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Wood-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="wood">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.wood)" (ngModelChange)="materialChange(item, 'WOOD')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--System - Aluminium and wood-->
        <p-column *ngIf="fieldUsage.show(CatalogTab.SYSTEM_GROUP)" field="aluminiumWoodMix">
            <ng-template let-item="rowData" pTemplate="body">
                <app-tristate-checkbox [disabled]="fieldUsage.disabled(CatalogTab.SYSTEM_GROUP)"
                                       [ngModel]="mapStateToTriStateCheckboxState(item.aluminiumWoodMix)" (ngModelChange)="materialChange(item, 'ALUMINIUM_WOOD_MIX')"></app-tristate-checkbox>
            </ng-template>
        </p-column>

        <!--Status-->
        <p-column *ngIf="fieldUsage.show(GlazingBeadField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" field="active"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'GLAZING_BEAD.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'GLAZING_BEAD.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'GLAZING_BEAD.ADD_GLAZING_BEAD' | translate }}" (onCancel)="cancel()"
            (onComplete)="submit()">
    <app-wizard-step label="{{ 'GLAZING_BEAD.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-glazing-bead-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file"
                               (fileChange)="onFileChange($event)"></app-glazing-bead-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" [id]="STEPS.SYSTEMS" label="{{ 'GLAZING_BEAD.STEPS.SYSTEMS' | translate }}">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.GLAZING_BEAD.COPY' : 'GLAZING_BEAD.EDIT_GLAZING_BEAD') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass="" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'GLAZING_BEAD.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-glazing-bead-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" [file]="file"
                               (fileChange)="onFileChange($event)"></app-glazing-bead-form>
    </app-wizard-step>
    <app-wizard-step *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)" [id]="STEPS.SYSTEMS" label="{{ 'GLAZING_BEAD.STEPS.SYSTEMS' | translate }}">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
