import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonWithMenuElementSelectedEvent} from "./button-with-menu-event";
import {MenuElement} from "./MenuElement";
import {MenuType} from "./MenuType";

declare const jQuery: any;

@Component({
    selector: 'button-with-menu',
    templateUrl: './button-with-menu.component.html',
    styleUrls: ['./button-with-menu.component.css', '../../form-inputs/simple-button/simple-button.component.css']
})
export class ButtonWithMenuComponent {

    private MENU_ELEMENT_HEIGHT_PX = 50;
    private MENU_BOTTOM_MARGIN_PX = 42;

    menuVisible = false;
    alignmentPopupPosition: number[] = [0, 0];

    // button attributes
    @Input() buttonId: string = "buttonWithMenu" + this.randomString();
    @Input() buttonLabel: string;
    @Input() buttonInlineLabel = true;
    @Input() leftButtonIcon: string;
    @Input() rightButtonIcon: string;
    @Input() buttonType: string;
    @Input() buttonSize: number;
    @Input() disabled: boolean;

    @Input() menuElements: MenuElement[] = [];
    @Input() menuElementBuilder: () => MenuElement[];
    @Input() menuType: MenuType;

    @Output() menuElementSelected = new EventEmitter<ButtonWithMenuElementSelectedEvent>();
    @Output() onMenuOpen = new EventEmitter();

    menuElementsLazy: MenuElement[];
    lastOpenedTowardsTop = false;

    public toggleMenu(): void {
        if (!this.menuVisible) {
            this.onMenuOpen.emit();
            if (this.menuElementBuilder != undefined) {
                this.menuElementsLazy = this.menuElementBuilder();
            } else {
                this.menuElementsLazy = this.menuElements;
            }
            this.alignmentPopupPosition = this.getPopupPosition(this.buttonId);
        }
        this.menuVisible = !this.menuVisible;
    }

    public closeMenu(): void {
        if (this.menuVisible) {
            this.toggleMenu();
        }
    }

    public onClick(): void {
        this.toggleMenu();
    }

    private getPopupPosition(id: string): number[] {
        let alignmentPopupPosition = jQuery("#" + id);

        if (this.alignmentPopupPosition.length === 0) {
            return [0, 0];
        }

        this.switchMenuOrientationIfOutsideScreen(alignmentPopupPosition);

        let left = this.getLeftAlignmentPosition(alignmentPopupPosition);
        let top = this.getTopAlignmentPosition(alignmentPopupPosition);

        return [left, top];
    }

    private getTopAlignmentPosition(alignmentPopupPosition: any) {
        if (this.menuType === MenuType.SIDEBAR_ICON_BUTTON) {
            let popupMenuHeight = this.MENU_ELEMENT_HEIGHT_PX * this.menuElementsLazy.length;
            let windowInnerHeight = jQuery("document").context.defaultView.innerHeight;

            let wouldOverflowTop = popupMenuHeight + this.MENU_BOTTOM_MARGIN_PX > alignmentPopupPosition.offset().top;
            let wouldOverflowBottom = windowInnerHeight - alignmentPopupPosition.offset().top - popupMenuHeight  < 0;

            if (wouldOverflowTop && wouldOverflowBottom) {
                this.lastOpenedTowardsTop = !this.lastOpenedTowardsTop;
            }
            if ((wouldOverflowTop && !wouldOverflowBottom) || this.lastOpenedTowardsTop) {
                return alignmentPopupPosition.offset().top + popupMenuHeight - alignmentPopupPosition.height() / 4;
            }
            if (!wouldOverflowTop && wouldOverflowBottom) {
                return windowInnerHeight - 8 /*margin*/;
            }
            return alignmentPopupPosition.offset().top + popupMenuHeight - 8 /*margin*/;
        } else {
            return alignmentPopupPosition.offset().top;
        }
    }

    private getLeftAlignmentPosition(alignmentPopupPosition: any) {
        let left;

        switch (this.menuType) {
            case MenuType.TOP_LEFT:
            case MenuType.BOTTOM_LEFT:
                left = alignmentPopupPosition.offset().left;
                break;
            case MenuType.ICON_LINE:
                left = alignmentPopupPosition.offset().left + alignmentPopupPosition.width();
                break;
            case MenuType.SIDEBAR_ICON_BUTTON:
            case MenuType.TOP_RIGHT:
            case MenuType.BOTTOM_RIGHT:
            default:
                left = alignmentPopupPosition.offset().left;
                break;
        }

        return left;
    }

    private switchMenuOrientationIfOutsideScreen(alignmentPopupPosition: any): void {
        if (this.menuType === MenuType.BOTTOM_LEFT || this.menuType === MenuType.BOTTOM_RIGHT) {
            let popupMenuHeight = this.MENU_ELEMENT_HEIGHT_PX * this.menuElementsLazy.length;
            let windowInnerHeight = jQuery("document").context.defaultView.innerHeight;

            if (windowInnerHeight - alignmentPopupPosition.offset().top - popupMenuHeight - this.MENU_BOTTOM_MARGIN_PX < 0) {
                this.menuType = this.menuType === MenuType.BOTTOM_LEFT ? MenuType.TOP_LEFT : MenuType.TOP_RIGHT;
            }
        } else if (this.menuType === MenuType.TOP_LEFT || this.menuType === MenuType.TOP_RIGHT) {
            let popupMenuHeight = this.MENU_ELEMENT_HEIGHT_PX * this.menuElementsLazy.length;

            if (popupMenuHeight + this.MENU_BOTTOM_MARGIN_PX > alignmentPopupPosition.offset().top) {
                this.menuType = this.menuType === MenuType.TOP_LEFT ? MenuType.BOTTOM_LEFT : MenuType.BOTTOM_RIGHT;
            }
        }
    }

    private randomString(): string {
        return Math.floor(Math.random() * 1000000).toString();
    }

    positionSelected(event: ButtonWithMenuElementSelectedEvent): void {
        this.menuElementSelected.emit(event);
    }

    get getCssClassesForTab(): any {
        const ngClasses = {
            'active': !this.menuVisible,
            'button-container': (this.menuType === MenuType.SIDEBAR_ICON_BUTTON || this.menuType === MenuType.ICON_LINE)
        };
        ngClasses['simple-button'] = true;
        if (this.buttonType != undefined) {
            ngClasses[`simple-button-${this.buttonType}`] = true;
        }
        if (this.buttonSize != undefined) {
            ngClasses[`simple-button-${this.buttonSize}`] = true;
        }
        return ngClasses;
    }
}
