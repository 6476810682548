export enum DistanceFrameType {
    STEEL = <any>"STEEL",
    SWISSPACER = <any>"SWISSPACER",
    ALUMINIUM = <any>"ALUMINIUM"
}

export interface DistanceFrameInterface {
    id: number;
    thickness: number;
    frameGroup: string;
    type: DistanceFrameType;
}
