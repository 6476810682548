import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {PickList} from 'primeng/picklist';
import {finalize} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {ValidationErrors} from '../../../common/validation-errors';
import {Subsystem} from '../../subsystem/subsystem';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {SubsystemSalesTargetChangeHistoryEntry} from "./subsystem-sales-target-change-history-entry";

@Component({
    selector: 'app-subsystem-sales-target-settings',
    templateUrl: './subsystem-sales-target-settings.component.html',
    styleUrls: ['./subsystem-sales-target-settings.component.css', '../settings.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemSalesTargetSettingsComponent implements OnInit {

    @ViewChild('pickList')
    pickList: PickList;

    currentYearSalesTarget: number;
    availableSubsystems: Subsystem[] = [];
    selectedSubsystems: Subsystem[] = [];
    validationErrors: ValidationErrors = {};

    changeHistory: SubsystemSalesTargetChangeHistoryEntry[];

    constructor(public readonly permissions: Permissions,
                private readonly subsystemService: SubsystemService,
                private readonly growls: GrowlMessageController,
                private readonly blockUi: BlockUiController,
                private readonly errors: CommonErrorHandler,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    get canChooseSubsystems(): boolean {
        return this.permissions.isKoordynator() || this.permissions.isOpiekun();
    }

    get hasSelectedSubsystem(): boolean {
        return !this.canChooseSubsystems || this.selectedSubsystems.length > 0;
    }

    ngOnInit(): void {
        if (this.canChooseSubsystems) {
            this.subsystemService.getItems(null, null, {active: {value: true}}, 'id', 1).subscribe({
                next: selectItems => {
                    this.availableSubsystems = selectItems.data;
                    this.changeDetector.markForCheck();
                }
            });
        } else {
            this.subsystemService.getSubsystemForCurrentUser().subscribe({
                next: subsystem => {
                    this.availableSubsystems = [subsystem];
                    this.currentYearSalesTarget = subsystem.currentYearSalesTarget;
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    submit(): void {
        this.blockUi.block('SubsystemSalesTargetSettingsSave');
        this.subsystemService.saveSalesTargets(this.getSelectedSubsystems(), this.currentYearSalesTarget).pipe(
            finalize(() => this.blockUi.unblock('SubsystemSalesTargetSettingsSave'))
        ).subscribe({
            complete: () => {
                this.growls.info('SUBSYSTEM-DETAILS.CURRENT_YEAR_SALES_TARGET_SAVED', {year: `${new Date().getFullYear()}`});
            },
            error: err => {
                this.errors.handle(err);
            }
        });
    }

    showHistory(subsystem: Subsystem): void {
        this.subsystemService.getSalesTargetHistory(subsystem.id).subscribe({
            next: history => {
                console.log(history);
                this.changeHistory = history;
                this.changeDetector.markForCheck();
            }
        });
    }

    private getSelectedSubsystems(): number[] {
        if (!this.canChooseSubsystems) {
            return this.availableSubsystems.map(si => si.id);
        }
        return this.selectedSubsystems.map(si => si.id);
    }

    protected readonly history = history;
}
