<div class="dialog-window">
    <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true" (onHide)="hide(false)"
              [modal]="true" [focusOnShow]="true" (keydown.escape)="hide(false)">
        <p-header>
            <div class="p-dialog-title confirm-action-header">
                {{ header | translate }}
            </div>
        </p-header>
        <div class="new-form-60vw preserveWhitespace">
            <div class="confirm-action-question" [innerHTML]="text | translate"></div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirm"
                                   [label]="confirmButtonLabel ? confirmButtonLabel : ('GENERAL.OK' | translate)"
                                   type="main-action" [size]="40" (onClick)="hide(true)"></app-simple-button>
                <app-simple-button *ngIf="hasCancelButton" buttonId="cancel" [label]="'GENERAL.CANCEL' | translate" type="cancel" [size]="40"
                                   (onClick)="hide(false)"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
