import {Component, OnInit, ChangeDetectionStrategy, Injector, ChangeDetectorRef, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from "../../../common/validation-errors";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {SupportedLanguages} from "../../../supportedLanguages";
import {CatalogElement} from '../../admin-panel/edit-catalog-permits/catalog-element.enum';
import {GraspDistanceFrameCategoryField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from '../../admin-panel/edit-catalog-permits/edit-catalog-permits.service';
import {FieldLimitation} from '../../admin-panel/edit-catalog-permits/field-limitation';
import {GraspDistanceFrameCategoryFieldUsage} from '../catalog-field-usage';
import {GraspDistanceFrameCategory} from './grasp-distance-frame-category';
import {GraspDistanceFrameCategoryService} from './grasp-distance-frame-category.service';

@Component({
    selector: 'app-grasp-distance-frame-category',
    templateUrl: './grasp-distance-frame-category.component.html',
    providers: [GraspDistanceFrameCategoryService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GraspDistanceFrameCategoryComponent extends CrudCommonComponent<GraspDistanceFrameCategory, GraspDistanceFrameCategoryService>
    implements OnInit {

    @ViewChild('table')
    table: Table;

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState: boolean;

    readonly GraspDistanceFrameCategoryField = GraspDistanceFrameCategoryField;
    readonly fieldUsage: GraspDistanceFrameCategoryFieldUsage;
    private readonly editCatalogPermitsService: EditCatalogPermitsService;
    editPermits: FieldLimitation[] = [];

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => {
                const stepValidationErrors = this.validateGeneralData();
                this.validationErrors = Object.assign({}, this.validationErrors, stepValidationErrors);
                return !this.validationErrorsPresent(stepValidationErrors);
            }
        }
    };

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, GraspDistanceFrameCategoryService,
            'GRASP_DISTANCE_FRAME_CATEGORY', 'GraspDistanceFrameCategory');
        this.editCatalogPermitsService = injector.get(EditCatalogPermitsService);
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
        this.fieldUsage = new GraspDistanceFrameCategoryFieldUsage(this);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GRASP_GLAZING_CATEGORIES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    getNewItem(): GraspDistanceFrameCategory {
        return new GraspDistanceFrameCategory();
    }

    loadEditedItem(event: { data: GraspDistanceFrameCategory }): Observable<GraspDistanceFrameCategory> {
        return this.itemService.getItem(event.data.id);
    }

    showDialogToCopy() {
        this.itemService.getItem(this.selectedItem.id).subscribe(copied => {
            this.item = copied;
            this.item.id = undefined;
            this.setDisplayDialog(true);
            this.focusOnElementWithId(this.getFirstInputId());
            this.changeDetector.markForCheck();
        });
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }

    protected validateForm(): void {
        this.validationErrors = Object.assign({}, this.validateGeneralData());
    }

    private validateGeneralData(): ValidationErrors {
        const validationErrors: ValidationErrors = {
            [`name[${this.translate.currentLang}]`]: MultiValidator.of(`error.graspDistanceFrameCategoryDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 50)
                .validate(this.item.name[this.translate.currentLang]),
            sortIndex: this.item.id != undefined ? MultiValidator.of('error.graspDistanceFrameCategoryDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .validate(this.item.sortIndex) : undefined,
            active: MultiValidator.of('error.graspDistanceFrameCategoryDto.active')
                .withNotNullValidator()
                .validate(this.item.active)
        };
        for (let language of SupportedLanguages.languages) {
            if (language.code === this.translate.currentLang) {
                continue;
            }
            validationErrors[`name[${language.code}`] = MultiValidator.of(`error.graspDistanceFrameCategoryDto.name[${language.code}]`)
                .withSizeValidator(0, 50)
                .validate(this.item.name[language.code]);
        }
        return validationErrors;
    }
}
