import {ProductTypeGroup} from "./product-type-group";

export class ProductsVisibility {
    [group: string]: boolean;

    public static prepare(available: ProductTypeGroup[],
                                     enabled: ProductTypeGroup[]): ProductsVisibility {
        const result = {};
        for (let group of Object.values(ProductTypeGroup)) {
            result[group] = available.includes(group) && enabled.includes(group);
        }
        return result;
    }
}
