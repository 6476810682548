import {DrawingUtil, MinMaxXY, Point} from "../drawing-util";
import {WindowCalculator} from "../window-calculator";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {WindowData} from "../drawing-data/WindowData";
import {CutData} from "../drawing-data/CutData";
import {ProfilesCompositionDistances} from "../profiles-composition-distances";
import * as _ from "underscore";
import {PainterParams} from "../painters/PainterParams";
import {UpsellingChargeData} from "../entities/upselling-charge-data";
import {DrawingData} from "../drawing-data/drawing-data";

export class VentilationUtils {

    private static readonly HEIGHT = 27;
    private static readonly WIDTH = 302;

    public static validate(subwindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                           profileCompositionDistances: ProfilesCompositionDistances): boolean {
        let totalInnerFramePoints = WindowCalculator.getTotalFrameInnerEdgePoints(subwindow, cuts, totalBoundingBox,
            profileCompositionDistances, true);
        let ventilationPlugin = DrawingUtil.getTopPolygonEdge(totalInnerFramePoints);
        let ventilationPluginWidth = DrawingUtil.segmentsLength(ventilationPlugin) || 0;
        return ventilationPluginWidth >= VentilationUtils.WIDTH;
    }

    public static validateAll(windows: WindowData[], cuts: CutData[], totalBoundingBox: MinMaxXY,
                              profileCompositionDistances: ProfilesCompositionDistances): boolean {
        return _.flatten(windows.map(window => window.subWindows))
            .filter(sw => VentilationUtils.isVentilationPresent(sw))
            .every(sw => VentilationUtils.validate(sw, cuts, totalBoundingBox, profileCompositionDistances));
    }

    public static isVentilationPresent(entity: SubWindowData | UpsellingChargeData): boolean {
        return [entity.ventilator, entity.drip, entity.coupler].filter(e => e != null).some(e => e.addonId != null);
    }

    public static getPath(subwindow: SubWindowData, cuts: CutData[], totalBoundingBox: MinMaxXY,
                          profileCompositionDistances: ProfilesCompositionDistances, params: PainterParams): number[] {
        let innermostFrameCenterPoint = WindowCalculator.getInnermostFrameCenterPoint(subwindow, cuts, totalBoundingBox,
            profileCompositionDistances, true, params);
        if (innermostFrameCenterPoint == null) {
            return [];
        }
        let topEdge = DrawingUtil.getTopPolygonEdge(innermostFrameCenterPoint);
        let centerPoint = new Point((topEdge[0] + topEdge[2]) / 2, topEdge[1]);
        return [
            centerPoint.x - (VentilationUtils.WIDTH / 2), centerPoint.y - (VentilationUtils.HEIGHT / 2),
            centerPoint.x + (VentilationUtils.WIDTH / 2), centerPoint.y - (VentilationUtils.HEIGHT / 2),
            centerPoint.x + (VentilationUtils.WIDTH / 2), centerPoint.y + (VentilationUtils.HEIGHT / 2),
            centerPoint.x - (VentilationUtils.WIDTH / 2), centerPoint.y + (VentilationUtils.HEIGHT / 2)
        ];
    }

    public static canHaveCoupler(subwindow: SubWindowData): boolean {
        return WindowCalculator.isSubWindowF(subwindow);
    }

    public static findFirstVentableSubwindow(data: DrawingData, cuts: CutData[], totalBoundingBox: MinMaxXY,
        profileCompositionDistances: ProfilesCompositionDistances): SubWindowData {
        let subwindows = _.flatten(data.windows.map(w => w.subWindows));
        // Prioritize picking winged subwindow if possible
        let validSubwindow = this.getTopmostLeftmostSubwindow(subwindows.filter(sw => !WindowCalculator.isSubWindowF(sw)), cuts,
            totalBoundingBox, profileCompositionDistances);
        if (validSubwindow == undefined) {
            validSubwindow = this.getTopmostLeftmostSubwindow(subwindows.filter(sw => WindowCalculator.isSubWindowF(sw)), cuts,
                totalBoundingBox, profileCompositionDistances);
        }
        return validSubwindow;
    }

    private static getTopmostLeftmostSubwindow(subwindows: SubWindowData[], cuts: CutData[], totalBoundingBox: MinMaxXY,
        profileCompositionDistances: ProfilesCompositionDistances): SubWindowData {
        let totalMinY = Infinity;
        let totalMinX = Infinity;
        let validTopmostSubwindows = [];
        subwindows.forEach(sw => {
            let topY = Math.min(...DrawingUtil.getYCoordinates(sw.cut));
            if (topY <= totalMinY && VentilationUtils.validate(sw, cuts, totalBoundingBox, profileCompositionDistances)) {
                if (topY === totalMinY) {
                    validTopmostSubwindows.push(sw);
                } else {
                    totalMinY = topY;
                    validTopmostSubwindows = [sw];
                }
            }
        });
        let validSubwindow;
        validTopmostSubwindows.forEach(sw => {
            let leftX = Math.min(...DrawingUtil.getXCoordinates(sw.cut));
            if (leftX < totalMinX) {
                totalMinX = leftX;
                validSubwindow = sw;
            }
        });
        return validSubwindow;
    }
}
