<div class="new-form-very-wide">

    <p-pickList id="glazingsList" [source]="availableGlazings" [target]="selectedGlazings"
                sourceHeader="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.CHARGES.AVAILABLE' | translate }}"
                targetHeader="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.CHARGES.SELECTED' | translate }}" [responsive]="true"
                filterBy="name"
                [sourceStyle]="{'height': '100%'}" [targetStyle]="{'height': '100%'}"
                [showSourceControls]="false" [showTargetControls]="false"
                (onMoveToSource)="handleMoveToSource($event.items)"
                (onMoveAllToSource)="handleMoveToSource($event.items)"
                (onMoveToTarget)="handleMoveToTarget($event.items)"
                (onMoveAllToTarget)="handleMoveToTarget($event.items)">
        <ng-template let-glazing pTemplate="item">
            <div [ngClass]="{'warn-in-source': glazing.warnInSource, 'warn-in-target': glazing.warnInTarget}">
                <div class="new-form-row">{{ glazing.name }}</div>
            </div>
        </ng-template>
    </p-pickList>

    <div *ngIf="validationErrors['webshopGlazingIds'] != undefined" class="new-form-row">
        <div class="new-form-field new-form-field-error new-form-field-error-message">
            {{ validationErrors['webshopGlazingIds'] | translate }}
            <br>
            {{ overlappingSystemNames }}
        </div>
    </div>

</div>
