import {ArchEllipticalWindowShape} from "./ArchEllipticalWindowShape";
import {ArchSegmentalWindowShape} from "./ArchSegmentalWindowShape";
import {ArchThreeCenteredWindowShape} from "./ArchThreeCenteredWindowShape";
import {EllipseWindowShape} from "./EllipseWindowShape";
import {RectWindowShape} from "./RectWindowShape";
import {WindowShapeType} from "./WindowShapeType";

export abstract class WindowShape {
    type: WindowShapeType;

    constructor(type: WindowShapeType) {
        this.type = type;
    }

    static isRectangular(shape: WindowShape): shape is RectWindowShape {
        return shape.type === WindowShapeType.RECTANGULAR;
    }

    static isEllipse(shape: WindowShape): shape is EllipseWindowShape {
        return shape.type === WindowShapeType.ELLIPSE;
    }

    static isArchElliptical(shape: WindowShape): shape is ArchEllipticalWindowShape {
        return shape.type === WindowShapeType.ARCH_ELLIPTICAL;
    }

    static isArchSegmental(shape: WindowShape): shape is ArchSegmentalWindowShape {
        return shape.type === WindowShapeType.ARCH_SEGMENTAL;
    }

    static isArchThreeCentered(shape: WindowShape): shape is ArchThreeCenteredWindowShape {
        return shape.type === WindowShapeType.ARCH_THREE_CENTERED;
    }
}
