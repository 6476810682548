export enum GateEditorField {
    QUANTITY = 'QUANTITY',
    GATE_SYSTEM = 'GATE_SYSTEM',
    WIDTH = 'WIDTH',
    HEIGHT = 'HEIGHT',
    LINTEL_HEIGHT = 'LINTEL_HEIGHT',
    RAIL_SYSTEM = 'RAIL_SYSTEM',
    GATE_WALL = 'GATE_WALL',
    CORE_COLOR = 'CORE_COLOR',
    EXTERNAL_COLOR = 'EXTERNAL_COLOR',
    INTERNAL_COLOR = 'INTERNAL_COLOR',
    COVERING_COLORS = 'COVERING_COLORS',
    GATE_PANEL_TYPE = 'GATE_PANEL_TYPE',
    CONTROL = 'CONTROL',
    DESCRIPTION = 'DESCRIPTION',
    ADDONS = 'ADDONS',

    WIDTH_GREATER_THAN = 'WIDTH_GREATER_THAN',
    WIDTH_LESS_THAN = 'WIDTH_LESS_THAN',
    HEIGHT_GREATER_THAN = 'HEIGHT_GREATER_THAN',
    HEIGHT_LESS_THAN = 'HEIGHT_LESS_THAN',
    AREA_GREATER_THAN = 'AREA_GREATER_THAN',
    AREA_LESS_THAN = 'AREA_LESS_THAN',
    LINTEL_HEIGHT_GREATER_THAN = 'LINTEL_HEIGHT_GREATER_THAN',
    LINTEL_HEIGHT_LESS_THAN = 'LINTEL_HEIGHT_LESS_THAN',
}
