import {MultilanguageField} from '../../../../supportedLanguages';
import {GlazingPackage, GlazingPackageTarget} from '../glazing-package';

export class WebshopGlazingPackage extends GlazingPackage {
    description: MultilanguageField;
    tagIds: number[];
    graspGlazingPackageId1: number;
    graspGlazingPackageId2: number;
    graspGlazingPackageId3: number;
    graspGlazingPackageId4: number;

    constructor(target: GlazingPackageTarget) {
        super(target);
        this.description = new MultilanguageField();
        this.tagIds = [];
        this.linkedSystemIds = [];
    }

    static copy(obj: WebshopGlazingPackage): WebshopGlazingPackage {
        let copy: WebshopGlazingPackage = new WebshopGlazingPackage(obj.target);
        super.copyGlazingPackageParams(copy, obj);
        copy.description = obj.description;
        copy.tagIds = [...obj.tagIds];
        copy.graspGlazingPackageId1 = obj.graspGlazingPackageId1;
        copy.graspGlazingPackageId2 = obj.graspGlazingPackageId2;
        copy.graspGlazingPackageId3 = obj.graspGlazingPackageId3;
        copy.graspGlazingPackageId4 = obj.graspGlazingPackageId4;
        return copy;
    }
}
