<div [attr.id]="dialogId" class="dialog-window">
    <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true" (onHide)="cancel()"
              [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title confirm-action-header">
                {{ 'LOGIN.TERMS_OF_USE_DIALOG.HEADER' | translate }}
            </div>
        </p-header>
        <div class="new-form-60vw">
            <div class="confirm-action-question ql-editor"
                 [innerHTML]="sanitizedContent"></div>

            <div class="confirm-action-question">
                <app-checkbox *ngIf="termsOfUse.consentCheckbox1" inputId="consent1" [label]="termsOfUse.consentCheckbox1"
                              [(validationMessageKey)]="validationErrors['consent1']"
                              [(ngModel)]="consent1"></app-checkbox>
                <app-checkbox *ngIf="termsOfUse.consentCheckbox2" inputId="consent2" [label]="termsOfUse.consentCheckbox2"
                              [(validationMessageKey)]="validationErrors['consent2']"
                              [(ngModel)]="consent2"></app-checkbox>
                <app-checkbox *ngIf="termsOfUse.consentCheckbox3" inputId="consent3" [label]="termsOfUse.consentCheckbox3"
                              [(validationMessageKey)]="validationErrors['consent3']"
                              [(ngModel)]="consent3"></app-checkbox>
                <app-checkbox *ngIf="termsOfUse.consentCheckbox4" inputId="consent4" [label]="termsOfUse.consentCheckbox4"
                              [(validationMessageKey)]="validationErrors['consent4']"
                              [(ngModel)]="consent4"></app-checkbox>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirm" [label]="'GENERAL.OK' | translate" type="main-action" [size]="40"
                                   (onClick)="confirm()"></app-simple-button>
                <app-simple-button buttonId="cancel" [label]="'GENERAL.CANCEL' | translate" type="cancel" [size]="40"
                                   (onClick)="cancel()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
