import {DrawingUtil} from "../drawing-util";
import {WindowCalculator} from '../window-calculator';
import {WindowDesignerInterface} from '../window-designer-interface';
import {PainterParams} from "./PainterParams";

export class WatermarkPainter {

    public static paint(designer: WindowDesignerInterface, params: PainterParams, outerFramePoints: number[]): void {
        if (!params.isShaded()) {
            return;
        }
        const window = designer.data.windows[designer.data.windows.length - 1];
        const subWindow = window.subWindows[window.subWindows.length - 1];
        const totalFrameInnerEdgePoints = WindowCalculator.getTotalFrameInnerEdgePoints(subWindow, designer.data.cuts,
            designer.totalBoundingBox, designer.profileCompositionDistances, false);
        const totalFrameOuterEdgePoints = WindowCalculator.isSubWindowF(subWindow)
            ? outerFramePoints
            : WindowCalculator.getFFWingOuterEdgePoints(subWindow, designer.data.cuts, designer.totalBoundingBox,
                designer.profileCompositionDistances, false);
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(totalFrameOuterEdgePoints);
        let g = designer.svg.g();
        g.add(designer.svg.path("m 106.4,137.7 15.9,53.5 16.9,-53.5 h 14.5 l -23.6,71.4 H 114.3 L 91.9,137.7 Z"));
        g.add(designer.svg.path("m 158.2,152.5 c 0,-12.4 8.8,-15 15.5,-14.8 0,0 25.1,0.7 31.4,1.6 v 11.3 h -28.2 c -5.1,0 -5.1,2.8 -5.1,5.5 v 11.1 l 28.8,1 v 11.2 l -28.8,0.9 v 11.1 c 0,5.2 3.2,5.1 6,5.1 H 205 v 11.2 c -5.5,0.8 -30.4,1.7 -30.4,1.7 -6.4,0.3 -16.5,-1.2 -16.5,-14.3 v -42.6 z"));
        g.add(designer.svg.path("m 214.7,137.7 h 13.9 l 28.3,46.1 v -46.1 h 13.7 v 71.4 h -13.7 l -28.4,-45.6 v 45.6 h -13.8 z"));
        g.add(designer.svg.path("m 280.8,155.6 c 0,-14.1 8.4,-18.3 19.3,-18.4 10.3,-0.2 22,1.2 27.6,2.8 v 10.7 c -6.2,-0.6 -20.3,-1 -23.9,-1 -6,-0.1 -9.4,0.3 -9.4,6.9 v 3.5 c 0,5.7 2.2,6.9 7.7,6.9 h 11.3 c 14,0 16.9,11 16.9,18.9 v 4.8 c 0,16.2 -10.1,19.2 -19.8,19.2 -12.2,0 -20.2,-0.7 -27.6,-2.9 v -10.7 c 3.9,0.4 15.8,1.1 24.9,1.1 4.1,0 8.8,-0.7 8.8,-6.2 v -3.8 c 0,-4.1 -1.2,-6.7 -6.4,-6.7 h -11 c -17.2,0 -18.4,-12.7 -18.4,-18.9 z"));
        g.add(designer.svg.path("m 340.4,137.7 h 13.1 v 29.9 h 5.7 l 18.1,-29.9 h 15.9 l -21.9,35.8 21.9,35.6 h -15.9 l -18.1,-29.5 h -5.6 v 29.5 h -13.2 z"));
        g.add(designer.svg.path("m 419.7,137.7 h 15.5 l 23.2,71.4 h -14.5 l -5.1,-15.8 h -23.4 l -5.1,15.8 h -14.7 z m 15.9,43.5 -8,-25.5 -8.6,25.5 z"));
        g.attr({fill: '#3b454d', fillOpacity: '.1'});
        const textSize = { width: 366.5000305175781, height: 72.71908569335938 };
        const heightForText = box.maxY - totalFrameInnerEdgePoints[5] - 10;
        const textScale = Math.min(heightForText / textSize.height, 1);
        const rightSideFrameWidth = box.maxX - totalFrameInnerEdgePoints[2];
        g.transform(`t${-91.9},${-137.7}S${textScale},${textScale},0,0t${(box.maxX - rightSideFrameWidth) / textScale - textSize.width},${(box.maxY - 5) / textScale - textSize.height}S${params.scale},${params.scale},0,0`);
    }
}
