import {ChangeDetectorRef, Directive, OnDestroy, OnInit} from "@angular/core";
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {MenuItem} from 'primeng/api/menuitem';

@Directive()
export abstract class TabbedComponent implements OnInit, OnDestroy {

    items: MenuItem[] = [];
    activeItem: MenuItem;

    protected activeTabIndex: number = 0;
    protected leftHotkey;
    protected rightHotkey;

    constructor(protected hotkeysService: HotkeysService,
                public changeDetector: ChangeDetectorRef) {
        this.leftHotkey = new Hotkey('ctrl+left', (): boolean => {
            if (this.activeTabIndex === 0) {
                this.selectTabByIndex(this.items.length - 1);
            } else {
                this.selectTabByIndex(this.activeTabIndex - 1);
            }
            return false;
        }, ['INPUT'], 'GENERAL.HOTKEYS.TAB_LEFT');
        this.rightHotkey = new Hotkey('ctrl+right', (): boolean => {
            if (this.activeTabIndex === this.items.length - 1) {
                this.selectTabByIndex(0);
            } else {
                this.selectTabByIndex(this.activeTabIndex + 1);
            }
            return false;
        }, ['INPUT'], 'GENERAL.HOTKEYS.TAB_RIGHT');
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.leftHotkey);
        this.hotkeysService.add(this.rightHotkey);
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.leftHotkey);
        this.hotkeysService.remove(this.rightHotkey);
    }

    protected selectTabByIndex(tab: number): void {
        if (this.activeTabIndex < this.items.length) {
            this.activeTabIndex = tab;
            this.activeItem = this.items[this.activeTabIndex];
            if (this.activeItem && this.activeItem.command){
                this.activeItem.command();
            }
            this.changeDetector.markForCheck();
        }
    }
}
