<div class="new-form-600">
    <p-accordion>
        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.DESCRIPTION' | translate }}" [selected]="false">
            <div *ngFor="let language of SupportedLanguages.languages" class="new-form-row">
                <app-text-area [inputId]="'description_' + language.code" label="{{ language.name | translate }}"
                               [disabled]="disabled"
                               [maxlength]="500"
                               [(ngModel)]="windowSystemWebShopInfo.description[language.code]"></app-text-area>
            </div>
        </p-accordionTab>

        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.FULL_DESCRIPTION' | translate }}"
                        [selected]="false">
            <div *ngFor="let language of SupportedLanguages.languages" class="new-form-row">
                <div class="new-form-field">
                    <h3>{{ language.name | translate }}</h3>
                    <p-editor styleClass="editor"
                              [disabled]="disabled"
                              [(ngModel)]="windowSystemWebShopInfo.longDescription[language.code]"></p-editor>
                </div>
            </div>
        </p-accordionTab>

        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.PREVIEW_IMAGE' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['webshopImage']}">
            <div class="new-form-row">
                <app-file-upload inputId="webshopImage" [ngModel]="webshopImageFile"
                                 [disabled]="disabled"
                                 (ngModelChange)="handleWebshopImageFileChange($event)" [maxSize]="150000"
                                 [maxWidth]="500" [maxHeight]="500"
                                 [addButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['webshopImage']"></app-file-upload>
            </div>
        </p-accordionTab>

        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.PHOTO' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['largeWebshopImage']}">
            <div class="new-form-row">
                <app-file-upload inputId="largeWebshopImage" [ngModel]="webshopLargeImageFile"
                                 [disabled]="disabled"
                                 (ngModelChange)="handleWebshopLargeImageFileChange($event)" [maxSize]="10000000"
                                 [maxWidth]="3840" [maxHeight]="2160"
                                 [addButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.SELECT_PHOTO_UPLOAD'"
                                 [changeButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.CHANGE_PHOTO_UPLOAD'"
                                 [deleteButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.DELETE_PHOTO_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['largeWebshopImage']"></app-file-upload>
            </div>
        </p-accordionTab>

        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.PHOTO_MOBILE' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['largeWebshopImageMobile']}">
            <div class="new-form-row">
                <app-file-upload inputId="largeWebshopImageMobile" [ngModel]="webshopLargeImageMobileFile"
                                 [disabled]="disabled"
                                 (ngModelChange)="handleWebshopLargeImageMobileFileChange($event)" [maxSize]="10000000"
                                 [maxWidth]="3840" [maxHeight]="2160"
                                 [addButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.SELECT_PHOTO_UPLOAD'"
                                 [changeButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.CHANGE_PHOTO_UPLOAD'"
                                 [deleteButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.DELETE_PHOTO_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['largeWebshopImageMobile']"></app-file-upload>
            </div>
        </p-accordionTab>
        <p-accordionTab header="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.SLIDER_IMAGE' | translate }}" [selected]="false"
                        [ngClass]="{'accordion-header-with-errors': validationErrors['sliderWebshopImage']}">
            <div *ngFor="let kv of webshopSliderImageFiles | keyvalue; trackBy: trackSliderImage" class="new-form-row">
                <app-file-upload inputId="webshopSliderImage" [ngModel]="kv.value.file"
                                 [disabled]="disabled"
                                 (ngModelChange)="handleWebshopSliderImageFileChange(kv.key, $event)"
                                 [maxSize]="10000000" [maxWidth]="3840" [maxHeight]="2160"
                                 [addButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.SELECT_SLIDER_IMAGE_UPLOAD'"
                                 [changeButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.CHANGE_SLIDER_IMAGE_UPLOAD'"
                                 [deleteButtonLabel]="'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.DELETE_SLIDER_IMAGE_UPLOAD'"></app-file-upload>
            </div>
            <div class="new-form-row">
                <div class="new-form-field">
                    <div class="new-form-field-input-container"></div>
                    <div class="new-form-field-input-container">
                        <app-simple-button buttonId="add-slider-image-button"
                                           label="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.ADD_SLIDER_IMAGE' | translate }}"
                                           type="action" [size]="40"
                                           (onClick)="handleWebshopAddSliderImageClick()"></app-simple-button>
                    </div>
                </div>
            </div>
        </p-accordionTab>
    </p-accordion>
    <div class="new-form-row">
        <app-input-number inputId="priceRating"
                          label="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.PRICE_RATING' | translate }}"
                          [disabled]="disabled"
                          [(validationMessageKey)]="validationErrors['priceRating']" [min]="1" [max]="5" [step]="1"
                          [required]="true" [allowOnlyIntegers]="true"
                          [(ngModel)]="windowSystemWebShopInfo.priceRating"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="energySavingRating"
                          [disabled]="disabled"
                          label="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.ENERGY_SAVING_RATING' | translate }}"
                          [(validationMessageKey)]="validationErrors['energySavingRating']" [min]="1" [max]="5"
                          [step]="1" [required]="true" [allowOnlyIntegers]="true"
                          [(ngModel)]="windowSystemWebShopInfo.energySavingRating"></app-input-number>

    </div>
</div>
