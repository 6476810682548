<p-dialog #dialog [resizable]="false" [modal]="true" (onHide)="onClose.emit()" [(visible)]="visible" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div class="new-form-row">
            <app-input-text inputId="name" label="{{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.NAME' | translate }}"
                            [disabled]="true"
                            [ngModel]="dialogData.editedPosition.name[translate.currentLang]"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="unit" label="{{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.UNIT' | translate }}"
                            [disabled]="true"
                            [ngModel]="('ASSEMBLY.UNITS.' + assembly.assemblyUnit) | translate"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="netPrice" label="{{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.NET_PRICE' | translate }}"
                            [disabled]="true"
                            [ngModel]="dialogData.editedPosition.sellPrice.netValue | price:currency"></app-input-text>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="grossPrice" label="{{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.GROSS_PRICE' | translate }}"
                            [disabled]="true"
                            [ngModel]="dialogData.editedPosition.sellPrice.grossValue | price:currency"></app-input-text>
        </div>
        <div class="new-form-row">
            <div class="new-form-field" [ngClass]="{'new-form-field-error': validationErrors['quantity'] != undefined}">
                <label for="quantity">{{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.QUANTITY' | translate }}</label>
                <div class="new-form-field-input-container">
                    <div class="flex-row-container">
                        <input type="number" class="assembly-quantity" id="quantity" min="1" step="1"
                               [ngModel]="dialogData.editedPosition.quantity" [disabled]="readOnlyMode"
                               (ngModelChange)="dialogData.editedPosition.quantity = $event; clearError('quantity')">
                        <app-simple-button *ngIf="isNotMeter(assembly)" id="quantityFromOfferButton"
                                           label="{{ 'ASSEMBLY.FORM.FILL_QUANTITY_FROM_OFFER' | translate }}"
                                           [inlineLabel]="false" icon="refresh" type="action" [size]="40"
                                           [disabled]="readOnlyMode" (onClick)="fillQuantityFromOffer()"></app-simple-button>
                    </div>
                    <ng-container *ngIf="validationErrors['quantity'] != undefined">
                        <span class="assembly-quantity new-form-field-error-icon material-icons">error</span>
                        <div class="new-form-field-error-message">{{ validationErrors['quantity'] | translate }}</div>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="new-form-row">
            <app-text-area inputId="comment"
                           label="{{ 'OFFER.POSITIONS.ACTIONS.TOOLTIPS.SHOW_DESCRIPTION' | translate }}"
                           [maxlength]="500"
                           [(ngModel)]="dialogData.editedPosition.otherInfo"></app-text-area>
        </div>
        <div class="new-form-row">
            <app-input-text inputId="netValue" label="{{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.NET_VALUE' | translate }}"
                            [disabled]="true"
                            [ngModel]="dialogData.editedPosition.sellValue.netValue | price:currency"></app-input-text>

        </div>
        <div class="new-form-row">
            <app-input-text inputId="grossValue" label="{{ 'OFFER.POSITIONS.DIALOGS.EDIT_ASSEMBLY.GROSS_VALUE' | translate }}"
                            [disabled]="true"
                            [ngModel]="dialogData.editedPosition.sellValue.grossValue | price:currency"></app-input-text>

        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="assemblySave" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action"
                               [disabled]="readOnlyMode" [size]="40" (onClick)="submit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="dialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
