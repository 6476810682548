import {
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import * as _ from 'underscore';
import {MaterialType} from '../../../../common/enums/MaterialType';
import {WindowSystemDefinition} from '../../../window-system/window-system-definition/window-system-definition';
import {EntranceDoorData} from "../../window-editor/roof-window-editor/entrance-door-data";
import {AddProductDialogDataProviderComponent} from "./add-product-dialog-data-provider.component";
import {CatalogItemExtended} from "../../../window-system/entrance-model/entrance-model-basic";

@Component({
    selector: 'app-add-entrance-window-dialog',
    templateUrl: './add-product-dialog-data-provider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddEntranceWindowDialogComponent extends AddProductDialogDataProviderComponent<CatalogItemExtended> implements OnInit {

    @Input() data: EntranceDoorData;
    @Input() windowSystems: WindowSystemDefinition[];

    tableHeader = 'OFFER.TABS.SECTION.MODEL.MODEL';
    dropdownSelectLabel = 'PRICE_TABLES.FORM.SYSTEM_NAME';
    showDropdown = true;

    constructor(translate: TranslateService,
                domSanitizer: DomSanitizer,
                changeDetector: ChangeDetectorRef) {
        super(translate, domSanitizer, changeDetector);
    }

    prepareDataToSubmit() {
       return {systemId: this.selectedDropdownSelectOption, modelId: this.selectedTableOption.id};
    }

    ngOnInit() {
        this.initTabs();
        let selectedSystem;
        let selectedModel;
        if (this.data && this.data.entranceModelId) {
            selectedModel = this.items.find(model => model.id === this.data.entranceModelId);
            selectedSystem = this.windowSystems.find(system => system.id === this.data.systemId);
        } else {
            selectedModel = _.chain(this.items)
                .sortBy(item => item.sortIndex)
                .first()
                .value();
            selectedSystem = this.windowSystems.find(system => system.id === selectedModel.links[0]);
        }
        this.handleTabChange(selectedSystem.material);
        this.handleDropdownChange(selectedSystem.id);
        this.handleValueChange(selectedModel);
        this.changeDetector.markForCheck();
    }

    private initTabs(): void {
        this.initMaterialTabs();
    }

    getWindowSystems() {
        return (this.windowSystems || []);
    }

    private getEntityTabIndex(item: WindowSystemDefinition): string {
        return item.material;
    }

    handleTabChange(material: MaterialType): void {
        this.activeTabItem = this.tabItems.find(tab => tab.tabindex === material);
        let filteredItems = _.chain(this.windowSystems || [])
            .filter(item => this.getEntityTabIndex(item) === this.activeTabItem.tabindex)
            .sortBy(item => item.sortIndex)
            .value();
        this.initDropdown(filteredItems);
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleDropdownChange(selectedItem.id);
    }

    public initDropdown(items: WindowSystemDefinition[]): void {
        this.dropdownSelectOptions = _.chain(items)
            .sortBy(item => item.sortIndex)
            .map(item => this.prepareDropdownOption(item))
            .value();
        this.showDropdown = this.dropdownSelectOptions.length > 1;
    }

    private prepareDropdownOption(item: WindowSystemDefinition): SelectItem {
        return {
            label: item.names[this.translate.currentLang],
            value: item.id
        };
    }

    handleDropdownChange(dropdownValue: number): void {
        this.selectedDropdownSelectOption = dropdownValue;
        let filteredItems = _.chain(this.items || [])
            .filter(model => model.links.includes(this.selectedDropdownSelectOption))
            .sortBy(item => item.sortIndex)
            .value();
        this.tableOptions = filteredItems;
        for (let item of filteredItems) {
            this.tableOptionNames[item.id] = item.name[this.translate.currentLang];
            if (item.image != undefined) {
                this.tableOptionIcons[item.id] = this.domSanitizer.bypassSecurityTrustUrl(item.image);
            }
        }
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleValueChange(selectedItem);
    }

    handleValueChange(item: CatalogItemExtended): void {
        this.selectedTableOption = item;
        if (this.selectedTableOption != undefined) {
            this.selectedValueDescription = this.prepareDescription(this.windowSystems.find(system => system.id === this.selectedDropdownSelectOption).description);
        }
    }
}
