import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class SubsystemUserActivityReport {

    userId: number;
    userLogin: string;
    lastUserLoginDate: Date;
    lastOfferCreationDate: Date;
    lastOfferModificationDate: Date;
    lastOrderCreationDate: Date;

    static fromJSON(jsonObject: object): SubsystemUserActivityReport {
        const report = new SubsystemUserActivityReport();
        report.userId = jsonObject['userId'];
        report.userLogin = jsonObject['userLogin'];
        if (jsonObject['lastUserLoginDate'] != undefined) {
            report.lastUserLoginDate = new Date(jsonObject['lastUserLoginDate']);
        }
        if (jsonObject['lastOfferCreationDate'] != undefined) {
            report.lastOfferCreationDate = new Date(jsonObject['lastOfferCreationDate']);
        }
        if (jsonObject['lastOfferModificationDate'] != undefined) {
            report.lastOfferModificationDate = new Date(jsonObject['lastOfferModificationDate']);
        }
        if (jsonObject['lastOrderCreationDate'] != undefined) {
            report.lastOrderCreationDate = new Date(jsonObject['lastOrderCreationDate']);
        }
        return report;
    }
}
