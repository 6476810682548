export enum AvailableProducts {
    SYSTEM = 'SYSTEM',
    ENTRANCE_SYSTEM = 'ENTRANCE_SYSTEM',
    TERRACE_SYSTEM = 'TERRACE_SYSTEM',
    ROOF_SYSTEM = 'ROOF_SYSTEM',
    ADDON_BULK = 'ADDON_BULK',
    CONFIGURABLE_ADDON = 'CONFIGURABLE_ADDON',
    GATE_SYSTEM = 'GATE_SYSTEM',
    OWN_ADDON_BULK = 'OWN_ADDON_BULK',
    ASSEMBLY = 'ASSEMBLY',
    TRANSPORT = 'TRANSPORT'
}
