<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ 'CONFIG_SYSTEM.TITLE.LIST' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button (onClick)="showHideFilters()"
                                   buttonId="toggle-filter-button"
                                   icon="filter_list" label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}" type="filter"></app-simple-button>
                <app-simple-button (onClick)="doShowDialogToCopy()" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   buttonId="copy" icon="content_copy" label="{{ 'CONFIG_SYSTEM.TITLE.COPY' | translate }}"
                                   type="action"></app-simple-button>
                <app-simple-button (onClick)="doShowDialogToAdd()" [hotkey]="newElementHotkey" [size]="40"
                                   align="right" buttonId="add" icon="add" label="{{ 'CONFIG_SYSTEM.TITLE.ADD' | translate }}"
                                   type="main-action"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt (onLazyLoad)="loadItemsLazy($event)" (onRowDblclick)="doOnRowSelect($event)" [(selection)]="selectedItem" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate"
                 [lazy]="true" [paginator]="true" [responsive]="true" [rowsPerPageOptions]="rowsPerPageOptions"
                 [rows]="chosenRowsPerPage" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [totalRecords]="totalRecords" [value]="itemList" id="categoriesTable"
                 selectionMode="single">
        <p-column [sortable]="true" field="sortIndex" header="{{ 'CONFIG_SYSTEM.FORM.SORT_INDEX' | translate }}"></p-column>
        <p-column [filterValues]="filterCategories" [filter]="showFilters" [sortable]="true"
                  field="category" filterField="category" header="{{ 'CONFIG_SYSTEM.FORM.CATEGORY' | translate }}"
                  sortField="category">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.category?.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column [filterValues]="filterFunctions" [filter]="showFilters" [sortable]="true"
                  field="function" filterField="function" header="{{ 'CONFIG_SYSTEM.FORM.FUNCTION' | translate }}"
                  sortField="function">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.function?.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column [filter]="showFilters" [sortable]="true" field="name"
                  filterField="name" filterMatchMode="contains" header="{{ 'CONFIG_SYSTEM.FORM.NAME' | translate }}"
                  sortField="{{ 'name.' + translate.currentLang }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column [filter]="showFilters" [sortable]="true" field="shortcut"
                  filterField="shortcut" filterMatchMode="contains" header="{{ 'CONFIG_SYSTEM.FORM.SHORTCUT' | translate }}"
                  sortField="{{ 'shortcut.' + translate.currentLang }}">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.shortcut[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column [filter]="showFilters" [sortable]="true" field="symbol"
                  filterField="symbol" filterMatchMode="contains" header="{{ 'CONFIG_SYSTEM.FORM.SYMBOL' | translate }}"
                  sortField="symbol">
            <ng-template let-item="rowData" pTemplate="body">
                {{ item.symbol }}
            </ng-template>
        </p-column>
        <p-column [filter]="showFilters" [sortable]="true"
                  field="supplier.companyName"
                  filterMatchMode="contains" header="{{ 'CONFIG_SYSTEM.FORM.SUPPLIER' | translate }}"></p-column>
        <p-column [defaultFilterValue]="defaultActiveFilter" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters"
                  field="active" header="{{ 'CONFIG_SYSTEM.FORM.STATUS' | translate }}">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'CONFIG_SYSTEM.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'CONFIG_SYSTEM.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-wizard (onCancel)="cancel()" (onComplete)="submit()" *ngIf="newItem"
            header="{{ 'CONFIG_SYSTEM.TITLE.ADD' | translate }}">
    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep"
                     label="{{ 'CONFIG_SYSTEM.STEPS.DATA' | translate }}">
        <app-config-system-form (designerImageChange)="onDesignerImageChange($event)" (imageLargeChange)="onImageLargeChange($event)" [categories]="availableCategories"
                                [designerImage]="designerImage" [file]="file" [functions]="availableFunctions"
                                [imageLarge]="imageLarge" [item]="item" [addonCategoryGroups]="addonCategoryGroups"
                                [messageLevelsOffer]="messageLevelsOffer" [messageLevelsOrder]="messageLevelsOrder"
                                [printableMessageSeverityLevels]="printableMessageSeverityLevels" [suppliers]="existingSuppliers"
                                [validationErrors]="validationErrors" [labelKeysMap]="labelKeysMap"></app-config-system-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.DIMENSIONS" [validate]="validateDimensionsStep" label="{{ 'CONFIG_SYSTEM.STEPS.DIMENSIONS' | translate}}">
        <app-config-system-dimensions-form [dimensionLabelKeysMap]="labelKeysMap"
                                           [item]="item"
                                           (uploadedFileChange)="uploadedFile = $event"
                                           [amazonS3Files]="amazonFiles"
                                           [canUploadToAmazon]="canUploadToAmazon"
                                           [validationErrors]="validationErrors"></app-config-system-dimensions-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.DESCRIPTION" label="{{ 'CONFIG_SYSTEM.STEPS.DESCRIPTION' | translate }}">
        <app-catalog-configuration-wizard-step
            [catalogConfiguration]="catalogConfigurations"
            [value]="item.description"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.SYSTEMS" label="{{ 'CONFIG_SYSTEM.STEPS.SYSTEMS' | translate }}">
        <div class="new-form-60vw">
            <app-link-selection [(ngModel)]="selectedWindowSystems" [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.GLAMOUR_PRINT_INFO" label="{{ 'CONFIG_SYSTEM.STEPS.GLAMOUR_PRINT_INFO' | translate }}">
        <div class="new-form-60vw">
            <app-config-addon-glamour-print-info-form (glamourDescriptionChange)="handleGlamourDescriptionChange($event)"
                                                      (horizontalPrintImageFileChange)="handleGlamourHorizontalPrintImageFileChange($event)"
                                                      (verticalPrintImageFileChange)="handleGlamourVerticalPrintImageFileChange($event)"
                                                      [glamourDescription]="glamourDescription"
                                                      [horizontalPrintImageFile]="horizontalPrintImageFile.file"
                                                      [validationErrors]="validationErrors"
                                                      [verticalPrintImageFile]="verticalPrintImageFile.file">
            </app-config-addon-glamour-print-info-form>
        </div>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.MOTLAWA" label="{{ 'CONFIG_SYSTEM.STEPS.MOTLAWA_INTEGRATION' | translate }}">
        <div class="new-form-60vw">
            <app-motlawa-integration-input [value]="motlawaInfo"
                                           [validationErrorsPrefix]="'motlawaInfo'"
                                           [validationErrors]="validationErrors"></app-motlawa-integration-input>
        </div>
    </app-wizard-step>
</app-wizard>

<app-wizard-dialog (onCancel)="cancel()" (onComplete)="submit()"
                   *ngIf="!newItem && displayDialog" contentStyleClass="scrolled-dialog-60vw" header="{{  (copyMode ? 'CONFIG_SYSTEM.TITLE.COPY' : 'CONFIG_SYSTEM.TITLE.EDIT') | translate }}">
    <app-wizard-step [id]="STEPS.DATA" [validate]="validateDataStep"
                     label="{{ 'CONFIG_SYSTEM.STEPS.DATA' | translate }}">
        <app-config-system-form (designerImageChange)="onDesignerImageChange($event)" (imageLargeChange)="onImageLargeChange($event)" [categories]="availableCategories"
                                [designerImage]="designerImage" [file]="file" [functions]="availableFunctions"
                                [imageLarge]="imageLarge" [item]="item" [addonCategoryGroups]="addonCategoryGroups"
                                [messageLevelsOffer]="messageLevelsOffer" [messageLevelsOrder]="messageLevelsOrder"
                                [printableMessageSeverityLevels]="printableMessageSeverityLevels" [suppliers]="existingSuppliers"
                                [validationErrors]="validationErrors" [labelKeysMap]="labelKeysMap"></app-config-system-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.DIMENSIONS" [validate]="validateDimensionsStep" label="{{ 'CONFIG_SYSTEM.STEPS.DIMENSIONS' | translate}}">
        <app-config-system-dimensions-form [dimensionLabelKeysMap]="labelKeysMap"
                                           [item]="item"
                                           (uploadedFileChange)="uploadedFile = $event"
                                           [amazonS3Files]="amazonFiles"
                                           [canUploadToAmazon]="canUploadToAmazon"
                                           [validationErrors]="validationErrors"
        ></app-config-system-dimensions-form>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.DESCRIPTION" label="{{ 'CONFIG_SYSTEM.STEPS.DESCRIPTION' | translate }}">
        <app-catalog-configuration-wizard-step
            [catalogConfiguration]="catalogConfigurations"
            [value]="item.description"></app-catalog-configuration-wizard-step>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.SYSTEMS" label="{{ 'CONFIG_SYSTEM.STEPS.SYSTEMS' | translate }}">
        <div class="new-form-60vw">
            <app-link-selection [(ngModel)]="selectedWindowSystems" [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.GLAMOUR_PRINT_INFO" label="{{ 'CONFIG_SYSTEM.STEPS.GLAMOUR_PRINT_INFO' | translate }}">
        <div class="new-form-60vw">
            <app-config-addon-glamour-print-info-form (glamourDescriptionChange)="handleGlamourDescriptionChange($event)"
                                                      (horizontalPrintImageFileChange)="handleGlamourHorizontalPrintImageFileChange($event)"
                                                      (verticalPrintImageFileChange)="handleGlamourVerticalPrintImageFileChange($event)"
                                                      [glamourDescription]="glamourDescription"
                                                      [horizontalPrintImageFile]="horizontalPrintImageFile.file"
                                                      [validationErrors]="validationErrors"
                                                      [verticalPrintImageFile]="verticalPrintImageFile.file">
            </app-config-addon-glamour-print-info-form>
        </div>
    </app-wizard-step>
    <app-wizard-step [id]="STEPS.MOTLAWA" label="{{ 'CONFIG_SYSTEM.STEPS.MOTLAWA_INTEGRATION' | translate }}">
        <div class="new-form-60vw">
            <app-motlawa-integration-input [value]="motlawaInfo"
                                           [validationErrorsPrefix]="'motlawaInfo'"
                                           [validationErrors]="validationErrors"></app-motlawa-integration-input>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
