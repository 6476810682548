export class ProfitMarginApi {
    static SUBSYSTEM_GROUP = "profitmarginsubsystemgroup";
    static SUBSYSTEM_GROUP_ALL = "profitmarginssubsystemgroup";
    static SUBSYSTEM = "profitmarginsubsystem";
    static SUBSYSTEM_ALL = "profitmarginssubsystem";
    static CLIENT_GROUP = "profitmarginclientgroup";
    static CLIENT_GROUP_ALL = "profitmarginsclientgroup";
    static CLIENT = "profitmarginclient";
    static CLIENT_ALL = "profitmarginsclient";
}
