import {Injectable} from "@angular/core";
import * as _ from 'underscore';
import {DrawingData} from "../../../../../window-designer/drawing-data/drawing-data";
import {WindowCommonData} from "../../../../../window-designer/window-common-data";
import {ConfigurableAddonPositionModel} from '../sidebar/pricing/config-addon-pricing/ConfigurableAddonPositionModel';
import {WindowDesignerComponent} from "../window-designer/window-designer.component";
import {History} from "./history";

@Injectable()
export class HistoryService {

    private historyDrawingData: History = new History();
    private historyCommonData: History = new History();
    private historyConfigAddons: History = new History();

    saveStep(drawingData: DrawingData, commonData: WindowCommonData, configurableAddonPositions: ConfigurableAddonPositionModel[]): void {
        this.historyDrawingData.saveOperation(drawingData);
        this.historyCommonData.saveOperation(commonData);
        this.historyConfigAddons.saveOperation(configurableAddonPositions);
    }

    clearHistory(): void {
        this.historyDrawingData = new History();
        this.historyCommonData = new History();
        this.historyConfigAddons = new History();
    }

    canUndo(): boolean {
        return this.historyDrawingData.canUndo() || this.historyCommonData.canUndo() || this.historyConfigAddons.canUndo();
    }

    canRedo(): boolean {
        return this.historyDrawingData.canRedo() || this.historyCommonData.canRedo() || this.historyConfigAddons.canRedo();
    }

    canCancel(): boolean {
        return this.historyDrawingData.canCancel() || this.historyCommonData.canCancel() || this.historyConfigAddons.canCancel();
    }

    redoLast(windowDesignerComponent: WindowDesignerComponent): void {
        windowDesignerComponent.setDrawingData(this.historyDrawingData.redo(windowDesignerComponent.data));
        windowDesignerComponent.commonData.copyFrom(this.historyCommonData.redo(windowDesignerComponent.commonData));
        windowDesignerComponent.setConfigAddons(this.historyConfigAddons.redo(windowDesignerComponent.configurableAddonPositions));
    }

    cancelLast(windowDesignerComponent: WindowDesignerComponent): void {
        windowDesignerComponent.setDrawingData(this.historyDrawingData.cancel());
        windowDesignerComponent.commonData.copyFrom(this.historyCommonData.cancel());
        windowDesignerComponent.setConfigAddons(this.historyConfigAddons.cancel());
    }

    undoLast(windowDesignerComponent: WindowDesignerComponent): void {
        const addonIdsBeforeUndo = windowDesignerComponent.data.addons.map(addon => addon.addonId);
        windowDesignerComponent.setDrawingData(this.historyDrawingData.undo(windowDesignerComponent.data));
        this.removeUndoneAddonsFromStaticData(addonIdsBeforeUndo, windowDesignerComponent);
        windowDesignerComponent.commonData.copyFrom(this.historyCommonData.undo(windowDesignerComponent.commonData));
        windowDesignerComponent.setConfigAddons(this.historyConfigAddons.undo(windowDesignerComponent.configurableAddonPositions));
    }

    private removeUndoneAddonsFromStaticData(addonIdsBeforeUndo: number[], windowDesignerComponent: WindowDesignerComponent) {
        if (addonIdsBeforeUndo.length !== windowDesignerComponent.data.addons.length) {
            const removedAddonIds = _.difference(addonIdsBeforeUndo, windowDesignerComponent.data.addons.map(addon => addon.addonId));
            windowDesignerComponent.addedWindowAddons = windowDesignerComponent.addedWindowAddons
                .filter(addon => !removedAddonIds.includes(addon.id));
        }
    }
}
