<div class="home-container">
    <div class="home-main-section">
        <div class="home-header">
            <div class="logo-container">
                <div class="home-logo"></div>
            </div>
            <div *ngIf="promotionInformation" class="promotion-container">
                <app-card-fold [corner]="promoFoldCorner" [foldColor]="promoFoldColor"></app-card-fold>
                <div class="promotion-title-line">
                    {{ promotionInformation.text | multilanguageTranslate }}
                </div>
                <div class="counter-container">
                    <app-countdown (isShortRemaining)="handleShortTimeRemaining($event)"
                                   [endTime]="promotionInformation.validTo"
                                   [showExpiresSoonTime]="promotionInformation.showExpiresSoonDate"
                    ></app-countdown>
                </div>
            </div>
            <div class="header-right-side">
                <div class="header-right-side-item header-socials">
                    <a class="header-socials-item"
                       href="https://www.facebook.com/venskadrutex?ref=tn_tnmn" target="_blank">
                        <i class="fa font-awesome fas fa-facebook-f"></i>
                    </a>
                    <a class="header-socials-item"
                       href="https://www.youtube.com/channel/UCzag1pLc6E4ePeK3vU7Nssw" target="_blank">
                        <i class="fa font-awesome fas fa-youtube-play"></i>
                    </a>
                    <a class="header-socials-item"
                       href="https://www.instagram.com/venska_fonster/" target="_blank">
                        <i class="fa font-awesome fas fa-instagram"></i>
                    </a>
                </div>
                <div class="header-right-side-item header-date">{{ currentWeek }}</div>
                <div class="header-right-side-item header-languages">
                    <button-with-menu [menuElements]="langMenuElements"
                                      [menuType]="menuType.BOTTOM_RIGHT"
                                      rightButtonIcon="keyboard_arrow_down">
                        <div class="navigation-tile" id="changeLanguage">
                            <div class="language">
                                <div [id]="translate.currentLang" class="flag"></div>
                            </div>
                        </div>
                    </button-with-menu>
                </div>
            </div>
        </div>
        <ng-container *ngIf="canSeeReports; else noReportsPlaceholder">
            <div class="home-separator"></div>
            <div class="home-charts" [ngStyle]="{ 'background-image': !showReports ? 'url(' + backgroundImageUrl + ')' : undefined }">
                <app-dashboard-report [ngStyle]="{ visibility: !showReports ? 'hidden' : undefined }"
                                      (displayDialog)="quickMenuInfoButtonClicked = $event"></app-dashboard-report>
            </div>
            <div class="home-separator">
                <app-simple-button label="{{ (showReports ? 'DASHBOARD.REPORTS.HIDE_LABEL' : 'DASHBOARD.REPORTS.SHOW_LABEL') | translate }}"
                                   [inlineLabel]="false" [icon]="showReports ? 'first_page' : 'last_page'"
                                   [size]="40" (onClick)="toggleReports()"></app-simple-button>
            </div>
        </ng-container>
        <ng-template #noReportsPlaceholder>
            <div class="home-separator home-charts" [ngStyle]="{ 'background-image': 'url(' + backgroundImageUrl + ')' }"></div>
            <div class="home-separator"></div>
        </ng-template>


        <div class="home-news">
            <app-news-preview (selectedNews)="openSelectedNews($event)"></app-news-preview>
        </div>
    </div>

    <div class="quick-menu">
        <p-multiSelect defaultLabel="{{ 'DASHBOARD.QUICK_MENU.CHOOSE_ITEMS' | translate }}" id="detailedPricingShownColumns"
                       [displaySelectedLabel]="false" [options]="quickMenuSelectItems | selectItemTranslate"
                       [ngModel]="savedQuickMenuItems" (ngModelChange)="showQuickMenu($event)"
        ></p-multiSelect>
        <div *ngFor="let buttonGroup of quickMenuButtons" class="quick-menu-button-group">
            <ng-container *ngFor="let button of buttonGroup">
                <div *ngIf="button.display()" [class.quick-menu-button-container-no-counter]="button.count == undefined"
                     class="quick-menu-button-container">
                    <div [class.quick-menu-button-info-no-counter]="button.count == undefined" class="quick-menu-button-info">
                                        <span (click)="quickMenuInfoButtonClicked = button"
                                              [class.inverted]="button.count == undefined" class="tooltip-info-icon"></span>
                    </div>
                    <button (click)="button.action()" [attr.id]="button.id" class="quick-menu-button" type="button">
                        <div class="quick-menu-button-label"><span>{{ button.label | translate }}</span></div>
                        <div *ngIf="button.count == undefined" class="quick-menu-button-spacer"><span></span></div>
                        <div *ngIf="button.count != undefined" [id]="button.id + '_counter'"
                            class="quick-menu-button-spacer quick-menu-button-count"><span *ngIf="!isNan(button.count())">{{ button.count()}}</span></div>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>


<app-news-dialog (onConfirmed)="confirmNews()" (onRemindNewsLater)="remindNewsLater()" *ngIf="showNews" [newsContent]="getNewsContent()"
                 [newsTitle]="getNewsTitle()" dialogId="newsDialog"></app-news-dialog>

<app-info-dialog (onHide)="quickMenuInfoButtonClicked = undefined"
                 *ngIf="quickMenuInfoButtonClicked != undefined"
                 header="{{ quickMenuInfoButtonClicked.label | translate }}"
                 text="{{ quickMenuInfoButtonClicked.info | translate }}"></app-info-dialog>
