<p-dialog [header]="header" [resizable]="false" [modal]="true" [(visible)]="visible"
          [focusOnShow]="true" appendTo="body" [style]="{ 'width.px': 1200 }">
    <div class="slider-container">
        <app-slider [infinite]="true" [maxSlidesToShow]="1" [slidesToScroll]="1"
                    [initialSlide]="0" [slideWidthForResponsiveMode]="150" [showArrows]="true" [variableWidth]="true">
            <div appSliderSlide *ngFor="let element of elements" class="slide-container">
                <img class="slide-image" [src]="element.image | appSafeValue: 'url'">
                <div class="label-container">{{element.label[translate.currentLang]}}</div>
            </div>
        </app-slider>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.EXIT' | translate }}" type="cancel" [size]="40"
                               (onClick)="close.emit()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
