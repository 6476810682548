import {GrillType} from "./GrillType";
import {GenericGrillGrid} from "./GenericGrillGrid";

export class GrillGridWithRhombusOnIntersections extends GenericGrillGrid {
    type: GrillType.GRID_RHOMBUS;
    rows = 2;
    columns = 2;
    rhombusWidth = 250;
    rhombusHeight = 250;
    splittedFields: number[][];

    constructor() {
        super(GrillType.GRID_RHOMBUS);
    }
}
