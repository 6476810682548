import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from "rxjs";
import {finalize, map} from 'rxjs/operators';
import {Permissions} from "../../auth/permission.service";
import {StorageService} from "../../auth/storage.service";
import {ButtonWithMenuElementSelectedEvent} from "../../common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder} from "../../common/button-with-menu/MenuElement";
import {MenuType} from "../../common/button-with-menu/MenuType";
import {CommonErrorHandler} from '../../common/CommonErrorHandler';
import {CrudCommonComponent} from "../../common/crud-common/crud.component";
import {Listing} from "../../common/crud-common/crudItemList";
import {DataServiceHelper} from "../../common/dataServiceHelper";
import {Country} from '../../common/enums/country';
import {SavedFilterService} from "../../common/saved-filter/saved-filter.service";
import {CrudComponent} from "../../common/service/crud.component";
import {DataTableColumnBuilder} from "../../common/service/data.table.column.builder";
import {ExportComponent} from "../../common/service/export.component";
import {TranslatedSelectItemService} from '../../common/service/translated-select-item.service';
import {TranslatedSelectItem} from "../../common/service/translated.select.item";
import {ClientGroupService} from '../client-group/client-group.service';
import {ClientGroup} from '../client-group/ClientGroup';
import {CreateOfferMode} from "../offer/offers/create-offer/create-offer-mode";
import {AddClientSubmitEvent} from "./add-client-submit-event";
import {Client} from "./client";
import {ClientService} from "./client.service";

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.css', '../shared-styles.css'],
    providers: [ClientGroupService, ClientService, DataServiceHelper, ExportComponent, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ClientComponent extends CrudComponent implements OnInit {

    private ignoreStoredFilter: boolean;

    menuType = MenuType;

    clients: Client[];
    fullClientsList: Listing<Client>;
    protected currentActionClient: Client;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    filterCountry: Observable<SelectItem[]>;
    filterActive: TranslatedSelectItem[];
    defaultActiveFilter: TranslatedSelectItem;

    lastLoadEvent: LazyLoadEvent;
    filterMine = true;
    filterClientGroup = false;
    selectedRow: Client;
    editedClient: Client;
    existingGroups: ClientGroup[] = [];
    existingGroupSelectItems: SelectItem[] = [];
    selectedGroups = [];

    lastSearchValue: string;

    @ViewChild('dt', {static: true}) datatable;

    constructor(public permissions: Permissions,
                private router: Router,
                private route: ActivatedRoute,
                private clientGroupService: ClientGroupService,
                private clientService: ClientService,
                injector: Injector,
                changeDetector: ChangeDetectorRef,
                private storageService: StorageService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private commonErrorHandler: CommonErrorHandler) {

        super(injector, changeDetector, 'ClientComponent', false);
        this.langTranslateSubscription.push(this.translate.onLangChange.subscribe(() => {
            this.loadItemsLazy(this.lastLoadEvent);
        }));
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.filterActive = CrudCommonComponent.buildActiveDropdown("GENERAL.STATUS_SINGULAR");
        this.defaultActiveFilter = this.filterActive[1];
        this.filterCountry = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', '');
        this.createTable();
        this.initClientGroups();
        this.initIncomingClientGroupFilter();
        this.resetDefaultFilterSelection();
    }

    private createTable() {
        let shownColumns = this.getShownColumns();
        super.init(DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('groupId', 'CLIENT-DETAILS.FORM.CLIENT-GROUP', true).setFilterValues(this.existingGroupSelectItems)
            .add('name', 'CLIENT-DETAILS.FORM.NAME', true)
            .add('address.street', 'CLIENT-DETAILS.FORM.STREET', true)
            .add('address.city', 'CLIENT-DETAILS.FORM.CITY', true)
            .add('address.zip', 'CLIENT-DETAILS.FORM.ZIP', true)
            .add('address.country', 'CLIENT-DETAILS.FORM.COUNTRY', true)
            .add('identifier', 'CLIENT-DETAILS.FORM.IDENTIFIER', true)
            .add('phone', 'CLIENT-DETAILS.FORM.PHONE', true)
            .add('email', 'CLIENT-DETAILS.FORM.E-MAIL', true)
            .add('owner', 'CLIENT-DETAILS.FORM.OWNER', true)
            .add('createdDate', 'CLIENT-DETAILS.FORM.CREATED_DATE', true)
            .add('description', 'CLIENT-DETAILS.FORM.DESCRIPTION', true)
            .add('active', 'CLIENT-DETAILS.FORM.STATUS', true).setFilterValues(this.filterActive)
            .setDefaultFilterValue(this.defaultActiveFilter)
            .build());
    }

    actionOnClick(action: string, client: Client) {
        console.info("ClientComponent: clicked action '" + action + "' for client: " + client.id);
        this.currentActionClient = client;
        switch (action) {
            case 'ADD_OFFER':
                this.addOffer(client);
                break;
            case 'GO_OFFER':
                this.goToOffer(client);
                break;
            case 'GO_ORDER':
                this.goToOrder(client);
                break;
            case 'DEACTIVATE_USER':
                this.deactivateClient(client);
                break;
            default:
                console.error("ClientComponent: action '" + client + "' is unknown.");
                break;
        }
    }

    addOffer(client: Client) {
        this.router.navigate(['/features/create-offer', {
            clientId: client.id,
            mode: CreateOfferMode.NEW
        }]);
        return false;
    }

    goToOffer(client: Client) {
        this.router.navigate(['/features/offer', {component: 'offer'}], {queryParams: {clientId: client.id}});
    }

    goToOrder(client: Client) {
        this.router.navigate(['/features/offer', {component: 'order'}], {queryParams: {clientId: client.id}});
    }

    deactivateClient(client: Client) {
        this.clientService.deactivateClient(client.id).subscribe({
            next: () => {
                this.showSuccessDeactivationMessage();
                this.loadItemsLazy(this.lastLoadEvent);
            },
            error: errorMessage => {
                this.validationErrors = this.commonErrorHandler.handle(errorMessage);
                this.changeDetector.markForCheck();
            }
        });
    }

    getFirstInputId() {
        return "groupName";
    }

    private applySavedFilter(filter: LazyLoadEvent): void {
        this.lastLoadEvent.filters = filter.filters;
        this.lastLoadEvent.sortOrder = filter.sortOrder;
        this.lastLoadEvent.sortField = filter.sortField;
        this.applyExtraHeaderFilters(filter);
        this.applyMultiSelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    private applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const currentUserFilter = filter.filters['mine'];
        if (currentUserFilter != undefined) {
            this.filterMine = ('' + currentUserFilter.value) === 'true';
        }
    }

    private applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedGroups, filter.filters['groupId']);
        this.getDatatable().setFilterValue(this.selectedGroups.join(';'), 'groupId', 'in');
    }

    private resetDefaultFilterSelection() {
        if (!this.ignoreStoredFilter) {
            this.lastLoadEvent = this.retrieveStoredLazyLoadEvent(this.storageService);
            if (this.lastLoadEvent != undefined) {
                this.applySavedFilter(this.lastLoadEvent);
            }
        }
    }

    private applyMineFilter(event: LazyLoadEvent) {
        event.filters['mine'] = {value: this.filterMine};
    }

    private applyGroupFilter(event: LazyLoadEvent): void {
        let selectedGroupIds = this.selectedGroups.join(';');
        if (selectedGroupIds.length > 0) {
            event.filters['groupId'] = {value: selectedGroupIds, matchMode: undefined};
        } else {
            event.filters['groupId'] = undefined;
        }
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.applyMineFilter(event);
        this.applyGroupFilter(event);
        this.lastLoadEvent = event;
        this.storeLazyLoadEvent(event, this.storageService);
        return this.clientService.getClients(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('ClientComponent `getPage` success:');
                    this.setClients(data, event, false);
                    this.fullClientsList = data;
                },
                error: error => {
                    console.error('ClientComponent `getPage` error:', error);
                    this.commonErrorHandler.handle(error);
                },
                complete: () => {
                    console.info('ClientComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    private setClients(data: Listing<Client>, loadEvent: LazyLoadEvent, afterSearch: boolean) {
        this.clients = data.data;
        this.totalRecords = data.totalRecords;
        this.fromRecord = Math.min(loadEvent.first + 1, this.totalRecords);
        this.toRecord = Math.min(loadEvent.first + loadEvent.rows, this.totalRecords);
        this.selectedRow = afterSearch ? this.clients[0] : this.restoreSelectionAfterLoad(this.selectedRow, this.clients, loadEvent);
        this.changeDetector.markForCheck();
    }

    showSuccessDeactivationMessage() {
        this.growlMessageController.info('CLIENT-DETAILS.CLIENT_DEACTIVATED');
    }

    // override to not register enter hotkey
    doOnRowSelect(event) {
        this.onRowSelect(event);
        this.keepSelectedItemIndex(event);
    }

    onRowSelect(event) {
        this.prepareDataForClient(event.data.id);
    }

    private initClientGroups(): void {
        this.clientGroupService.getClientGroups(undefined, undefined, undefined, undefined, undefined).subscribe({
            next: clientGroupListing => {
                this.existingGroups = clientGroupListing.data;
                for (let group of clientGroupListing.data) {
                    this.existingGroupSelectItems.push({label: group.name, value: `${group.id}`});
                }

                this.changeDetector.markForCheck();
            },
            error: error => {
                this.commonErrorHandler.handle(error);
            }
        });
    }

    private initIncomingClientGroupFilter(): void {
        if (this.route.snapshot.queryParamMap.has('clientGroupId')) {
            this.selectedGroups = [+this.route.snapshot.queryParamMap.get('clientGroupId')];
            this.filterMine = false;
            this.filterClientGroup = true;
        }
    }

    prepareDataForClient(clientId): void {
        this.clientService.getClient(clientId).subscribe({
            next: data => {
                this.editedClient = data;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.commonErrorHandler.handle(error);
            },
            complete: () => {
                console.debug('prepareDataForClient` completed!');
            }
        });
    }

    public editClientComponentClose(event: AddClientSubmitEvent) {
        for (let eventMessage of event.msgs) {
            this.growlMessageController.show(eventMessage);
        }
        this.editedClient = undefined;
        if (event.type === 'success') {
            this.loadItemsLazy(this.lastLoadEvent);
        } else {
            this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedRow);
        }
        this.changeDetector.markForCheck();
    }

    submit() {
    }

    getExportData(): Observable<object[]> {
        return this.clientService.getClients(0, null, this.lastLoadEvent.filters, this.lastLoadEvent.sortField,
            this.lastLoadEvent.sortOrder).pipe(map(list => list.data), map(clientData => {
            clientData.forEach(client => {
                (client as any).active = this.getTranslatedLabelForAttrValue(client, "active");
            });
            return clientData;
        }));
    }

    redirectToCreateClientComponent() {
        this.router.navigate(['/features/create-client']);
        return false;
    }

    showDialogToAdd(): void {
        this.redirectToCreateClientComponent();
    }

    buildRowActions(client: Client): MenuElement[] {
        let rowActions: MenuElement[] = [];
        let offerSuffix: string = this.getClientSuffix(client);

        if (client.active) {
            rowActions.push(new MenuElementBuilder().setTranslationKey(
                'CLIENT-DETAILS.ACTIONS.TOOLTIPS.ADD').setIdentifier('ADD_OFFER' + offerSuffix).build());
        }

        rowActions.push(new MenuElementBuilder().setTranslationKey('CLIENT-DETAILS.ACTIONS.TOOLTIPS.GO_TO_OFFERS')
            .setIdentifier('GO_OFFER' + offerSuffix).build());
        rowActions.push(new MenuElementBuilder().setTranslationKey('CLIENT-DETAILS.ACTIONS.TOOLTIPS.GO_TO_ORDERS')
            .setIdentifier('GO_ORDER' + offerSuffix).build());

        if (client.active) {
            rowActions.push(new MenuElementBuilder().setTranslationKey('CLIENT-DETAILS.ACTIONS.TOOLTIPS.DELETE')
                .setIdentifier('DEACTIVATE_USER' + offerSuffix).build());
        }

        return rowActions;
    }

    private getClientSuffix(client: Client): string {
        return '___' + client.id;
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent): void {
        let data = event.identifier.split('___');
        let action = data[0];
        let clientId = +data[1];
        let foundClient = this.clients.find(client => client.id === clientId);

        if (foundClient) {
            this.actionOnClick(action, foundClient);
        } else {
            console.error("Action called on non existing clientId " + clientId);
        }
    }

    toggleFilterMine(): void {
        this.filterMine = !this.filterMine;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    showAll(): void {
        if (this.filterMine) {
            this.toggleFilterMine();
        } else if (this.filterClientGroup) {
            this.filterClientGroup = false;
            this.selectedGroups.splice(0, this.selectedGroups.length);
            this.loadItemsLazy(this.lastLoadEvent);
        }
    }

    handleClientSearch(searchedValue: string): void {
        if (searchedValue && this.lastSearchValue !== searchedValue) {
            super.loadItemsLazy(this.lastLoadEvent);
            this.clientService.getClientsSimple(searchedValue, this.lastLoadEvent.filters)
                .pipe(finalize(() => this.hideDataLoadingIndicator()))
                .subscribe((clients: Listing<Client>) => {
                this.setClients(clients, this.lastLoadEvent, true);
            });
            this.lastSearchValue = searchedValue;
        } else if (this.lastSearchValue && !searchedValue) {
            super.loadItemsLazy(this.lastLoadEvent);
            this.setClients(this.fullClientsList, this.lastLoadEvent, true);
            this.lastSearchValue = undefined;
            this.hideDataLoadingIndicator();
        }
    }

}
