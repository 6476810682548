import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from "rxjs";
import {finalize, map, mergeMap, tap} from 'rxjs/operators';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from "../../../shared/validator/input-validator";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, GlassField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ErrorResponse} from "../../errors/errorResponse";
import {GlassFieldUsage} from "../catalog-field-usage";
import {ItemForCatalogLinking} from '../single-system-checkbox-crud/item-for-catalog-linking';
import {WindowSystemDefinitionService} from "../window-system-definition/window-system-definition.service";
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {Glass} from "./glass";
import {GlassService} from "./glass.service";
import {GlassInGlazingType} from "./glassInGlazingType";
import {GlassWithPosition} from "./glassWithPositions";

@Component({
    selector: 'app-glass',
    templateUrl: './glass.component.html',
    styleUrls: ['./glass.component.css', '../../shared-styles.css'],
    providers: [GlassService, WindowSystemDefinitionService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlassComponent extends CrudCommonComponent<Glass, GlassService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];

    filterEsg: Observable<SelectItem[]>;
    filterTmp: Observable<SelectItem[]>;
    filterThickness: Observable<SelectItem[]>;

    windowSystems: ItemForCatalogLinking[];
    windowSystemsByDefaultGlassId = new Map<number, number[]>();

    validateGeneralDataStep: WizardStepValidator;

    @ViewChild('dt') datatable;

    glamourPrintIconFile: File;

    editPermits: FieldLimitation[] = [];
    fieldUsage: GlassFieldUsage;
    CatalogTab = CatalogTab;
    GlassField = GlassField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private windowSystemDefinitionService: WindowSystemDefinitionService,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, GlassService, 'GLASS', 'Glass');
        this.item = new Glass();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.initStepValidators();
        this.initDefaultSortOrder();
        this.fieldUsage = new GlassFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterEsg = this.translatedSelectItemService.buildUnsortedDropdown(['YES', 'NO'], 'GENERAL.', '');
        this.filterTmp = this.translatedSelectItemService.buildUnsortedDropdown(['YES', 'NO'], 'GENERAL.', '');
        forkJoin({
            thicknesses: this.itemService.getThicknesses(),
            windowSystems: this.windowSystemDefinitionService.getSystemsForCatalogLinking(),
            usedInWindowSystems: this.itemService.getGlassIdsUsedInWindowSystemDefaultGlazingPackage()
        }).subscribe(data => {
            this.filterThickness = this.translatedSelectItemService.buildUnsortedDropdown(data.thicknesses, '', '');
            this.windowSystems = data.windowSystems;
            this.fillWindowSystemsByGlassId(data.usedInWindowSystems);
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GLASSES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    private fillWindowSystemsByGlassId(usedInWindowSystems: { id: number, windowSystemIds: number[] }[]): void {
        this.windowSystemsByDefaultGlassId.clear();
        for (let usedInWindowSystem of usedInWindowSystems) {
            this.windowSystemsByDefaultGlassId.set(usedInWindowSystem.id, usedInWindowSystem.windowSystemIds);
        }
    }

    hideGlassDetails(): void {
        this.reloadDatatable();
        this.setDisplayDialog(false);
        this.copyMode = false;
    }

    reloadThicknessFilter(): void {
        this.itemService.getThicknesses().subscribe({
            next: response => {
                this.filterThickness = this.translatedSelectItemService.buildUnsortedDropdown(response, '', '');
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    protected afterSuccessLoad(): void {
        this.itemList.forEach(glass => {
            let glassWithPos = <GlassWithPosition>glass;
            GlassComponent.selectAllPositionsCheckbox(glassWithPos);
        });
    }

    private static selectAllPositionsCheckbox(glass: GlassWithPosition): void {
        glass.allPositions = glass.position1of1
            && glass.position1of2 && glass.position2of2
            && glass.position1of3 && glass.position2of3 && glass.position3of3
            && glass.position1of4 && glass.position2of4 && glass.position3of4 && glass.position4of4;
    }

    submit(): void {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<any>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file, this.glamourPrintIconFile).pipe(
                    mergeMap(glassId => {
                        return this.itemService.setGlassWindowSystemLinks(glassId, this.item.linkedSystemIds);
                    }));
            } else {
                observable = this.itemService.saveGlass(this.item, this.file, this.glamourPrintIconFile).pipe(
                    mergeMap(glassId => {
                        return this.itemService.setGlassWindowSystemLinks(glassId, this.item.linkedSystemIds);
                    }));
            }

            observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
                next: () => {
                    this.hideGlassDetails();
                    this.showSuccessMessage();
                    this.reloadThicknessFilter();
                },
                error: (errorMessage: HttpErrorResponse) => {
                    let errorResponse = new ErrorResponse(errorMessage.error);
                    if (errorResponse.is400()) {
                        this.validationErrors = {};
                        for (let property in errorResponse.invalidFields) {
                            this.validationErrors[property] = errorResponse.invalidFields[property];
                        }
                        this.changeDetector.markForCheck();
                    } else {
                        this.setErrors(errorMessage);
                    }
                }
            });
        }
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');

        if (!this.item.name[this.userLang]) {
            this.validationErrors[`name[${this.userLang}]`] = `error.glassDto.name[${this.userLang}].not_empty`;
        }
        if (!this.item.symbol) {
            this.validationErrors['symbol'] = 'error.glassDto.symbol.not_empty';
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.glassDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999).validate(this.item.sortIndex);

        this.validationErrors['thickness'] = MultiValidator.of('error.glassDto.thickness')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(0, 1000, false, true)
            .validate(this.item.thickness);

        this.validationErrors['constructionThickness'] = MultiValidator.of('error.glassDto.thickness')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(0, 1000, false, true)
            .validate(this.item.constructionThickness);

        if (ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }

        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.getGlass(this.selectedItem.id);
        }
    }

    onRowSelect(event) {
        super.onRowSelect(event);
        this.setDisplayDialog(false);
        this.getGlass(event.data.id);
    }

    getGlass(glassId: number) {
        forkJoin({
            glass: this.itemService.getItem(glassId),
            image: this.itemService.getImageAsFile(glassId),
            glamourPrintIcon: this.itemService.getGlamourPrintIconAsFile(glassId)
        }).subscribe({
            next: data => {
                this.item = data.glass;
                this.file = data.image;
                this.glamourPrintIconFile = data.glamourPrintIcon;
                this.afterDialogOpen();
                if (this.copyMode) {
                    this.item.id = undefined;
                }
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getGlassWithImage` completed!');
            }
        });
    }

    showLinkSuccessMessage() {
        this.growlMessageController.info('GLASS.GLASS_IN_GLAZING_TYPE_UPDATED');
    }

    editGlassLinkEvent(glass: GlassWithPosition, position: number, glassCount: number, addLink: boolean) {
        let request = GlassComponent.prepareGlassInGlazingType(glass, position, glassCount);
        if (addLink) {
            this.itemService.addLink(request).subscribe({
                next: () => {
                    this.showLinkSuccessMessage();
                    glass['position' + position + 'of' + glassCount + ''] = true;
                    GlassComponent.selectAllPositionsCheckbox(glass);
                    this.changeDetector.markForCheck();
                    console.log("Created new GlassInGlazingType");
                },
                error: error => {
                    console.error(error);
                    if (error.status === 500) {
                        this.setErrors(error);
                    } else {
                        this.growlMessageController.error(error);
                    }
                }
            });
        } else {
            this.itemService.removeLink(glass.id, position, glassCount).subscribe({
                complete: () => {
                    this.showLinkSuccessMessage();
                    glass['position' + position + 'of' + glassCount + ''] = false;
                    glass.allPositions = false;
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    checkAll(all: boolean, glassId: number) {
        this.itemService.toggleLinks(glassId, all).subscribe({
            next: () => {
                this.showLinkSuccessMessage();
                let glass = <GlassWithPosition>this.itemList.find(i => i.id === glassId);
                glass.allPositions = all;
                for (let glassCount = 1; glassCount <= 4; ++glassCount) {
                    for (let position = 1; position <= glassCount; ++position) {
                        glass['position' + position + 'of' + glassCount + ''] = all;
                    }
                }
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.genericErrorHandler(error);
            }
        });
    }

    static prepareGlassInGlazingType(glass: Glass, position: number, glassCount: number) {
        let request = new GlassInGlazingType();
        request.glassId = glass.id;
        request.position = position;
        request.glassCount = glassCount;
        request.active = true;
        return request;
    }

    getNewItem(): GlassWithPosition {
        return new GlassWithPosition();
    }

    private initStepValidators(): void {
        this.validateGeneralDataStep = () => this.validateForm();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    onGlamourPrintIconFileChange(newFile: File): void {
        this.glamourPrintIconFile = newFile;
        if (!newFile) {
            this.glamourPrintIconFile = new File([], null);
        }
        this.changeDetector.markForCheck();
    }
}
