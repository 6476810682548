import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {Seal} from './Seal';
import {SealList} from './SealList';
import {SealWithMaterials} from './SealWithMaterials';

@Injectable()
export class SealService extends SingleSystemCheckboxCrudService<Seal> {

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getAllActiveSeals(windowSystemIds: number[], requireAllWindowSystems: boolean): Observable<SealList> {
        return this.http.get<SealList>('seals', {
            params: {
                sortBy: 'sortIndex',
                ascending: 'false',
                active: 'true',
                windowSystemId: windowSystemIds.join(),
                requireAllSystems: `${requireAllWindowSystems}`
            }
        });
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<SealList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<SealList>('seals', {params: params});
    }

    addItem(item: Seal): Observable<number> {
        return this.http.post('seals', item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId('seals', response)));
    }

    editItem(sealId: number, item: Seal): Observable<number> {
        return this.http.put(`seals/${sealId}`, item).pipe(map(() => sealId));
    }

    getItem(itemId: number): Observable<Seal> {
        return this.http.get<Seal>(`seals/${itemId}`);
    }

    editSealPositionAvailability(itemId: number, position: string, value: boolean): Observable<void> {
        let headers = {};
        headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8';
        return this.http.put<void>(`seals/${itemId}/position/${position}`, `value=${value}`, {headers: headers});
    }

    copy(id: number, item: SealWithMaterials): Observable<number> {
        return this.http.post(`seals/${id}/copy`, item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId('seals', response)));
    }

    validate(seal: Seal): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('seals/validate', seal));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('seals/names', {params: params});
    }
}
