import {ConfigurableAddonPositionModel} from './config-addon-pricing/ConfigurableAddonPositionModel';
import {PricingResult} from "./PricingResult";

export class PricingWithModel {
    pricingResult: PricingResult;
    configurableAddonPositionModel: ConfigurableAddonPositionModel;

    constructor(pricingResult: PricingResult,
                configurableAddonPositionModel: ConfigurableAddonPositionModel) {
        this.pricingResult = pricingResult;
        this.configurableAddonPositionModel = configurableAddonPositionModel;
    }
}
