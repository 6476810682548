import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {Permissions} from "../../../../../../auth/permission.service";
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {DecimalValidator} from "../../../../../../shared/validator/input-validator";
import {PositionService} from "../../position.service";
import {RabateMarkupDialogData} from "../position-list-dialogs";
import {BlockUiController} from "../../../../../../block-ui/block-ui-controller";
import {finalize} from "rxjs/operators";

@Component({
    selector: 'app-rabate',
    templateUrl: './rabate.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RabateComponent {

    private static readonly RABATE_DIALOG_BLOCK_ID = 'Rabate/margin dialog block';

    @Input() dialogData: RabateMarkupDialogData;
    @Output() onSubmit = new EventEmitter<{ success: boolean, rabate: number }>();
    @Output() onClose = new EventEmitter();

    validationErrors = {};

    private readonly dialogHideHelper = new OnceFlag();

    constructor(public permissions: Permissions,
                public positionService: PositionService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController) {
    }

    getHeader(): string {
        return `OFFER.POSITIONS.DIALOGS.RABATE_MARGINS.${this.dialogData.positionList ?
            (this.dialogData.isMarkup ? 'MARGIN' : 'RABATE') : 'GLOBAL_RABATE'}`;
    }

    fieldName(): string {
        return this.dialogData.isMarkup ? 'margin' : 'rabate';
    }

    validate(): boolean {
        let value = this.dialogData.isMarkup ? this.dialogData.markup : this.dialogData.rabate;
        if (this.isValidNumber(value)) {
            let replaced = this.replaceCommaWithDot(value);
            if (replaced < 0) {
                return this.setValidationError('below_min');
            }
            if (!this.dialogData.isMarkup && replaced > 100) {
                return this.setValidationError('over_max');
            }
        } else {
            return this.setValidationError('below_min');
        }
        return true;
    }

    private setValidationError(error: string): boolean {
        let fieldName = this.dialogData.isMarkup ? 'margin' : 'rabate';
        this.validationErrors[fieldName] = `error.offerPositionChangeRabateMarginDto.${fieldName}.${error}`;
        return false;
    }

    private isValidNumber(value: number) {
        return value != null && new DecimalValidator().isValid(value.toString());
    }

    private replaceCommaWithDot(value: number): number {
        return +value.toString().replace(",", ".");
    }

    submitDialog(): void {
        let valid = this.validate();
        if (valid) {
            this.blockUiController.block(RabateComponent.RABATE_DIALOG_BLOCK_ID);
            let value = this.dialogData.isMarkup ? this.dialogData.markup : this.dialogData.rabate;
            let replaced = this.replaceCommaWithDot(value);
            if (this.dialogData.positionList) {
                let positionList = this.dialogData.positionList.map(position => position.id);
                let observable = this.dialogData.isMarkup ?
                    this.positionService.recalculatePriceWithMargin(this.dialogData.offerId, positionList, replaced) :
                    this.positionService.recalculatePriceWithRabate(this.dialogData.offerId, positionList, replaced);
                observable.pipe(
                    finalize(() => this.blockUiController.unblock(RabateComponent.RABATE_DIALOG_BLOCK_ID))
                ).subscribe({
                    next: () => {
                        this.emitSuccess();
                    },
                    error: error => {
                        this.validationErrors = this.errors.handle(error);
                        this.changeDetector.markForCheck();
                    },
                    complete: () => {
                        console.info('RabateComponent `recalculatePriceWithRabate` completed!');
                    }
                });
            } else {
                this.positionService.recalculatePriceWithRabateForOffer(this.dialogData.offerId, replaced).pipe(
                    finalize(() => this.blockUiController.unblock(RabateComponent.RABATE_DIALOG_BLOCK_ID))
                ).subscribe({
                    next: () => {
                        this.emitSuccess();
                    },
                    error: error => {
                        this.validationErrors = this.errors.handle(error);
                        this.changeDetector.markForCheck();
                    }
                });
            }
        }
    }

    resetManualMargin() {
        let positionList = this.dialogData.positionList.map(position => position.id);
        this.positionService.recalculatePriceWithMargin(this.dialogData.offerId, positionList, undefined).subscribe({
            next: () => {
                this.emitSuccess();
            },
            error: error => {
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            },
            complete: () => {
                console.info('RabateComponent `recalculatePriceWithRabate` completed!');
            }
        });
    }

    private emitSuccess() {
        this.dialogHideHelper.call(() => this.onSubmit.emit({success: true, rabate: this.dialogData.rabate}));
    }

    close() {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }
}
