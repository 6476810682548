import {Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from "primeng/api/selectitem";
import {Glazing} from "../../../../../window-designer/catalog-data/glazing";
import {SelectItemImpl} from "../../../../common/service/select.item.impl";
import {DistanceFrame} from "../../distance-frame/distanceFrame";
import {GlassWithPosition} from "../../glass/glassWithPositions";
import {GlazingBead} from "../../glazing-bead/glazing-bead";

@Component({
    selector: 'app-grasp-glazing-form',
    templateUrl: './grasp-glazing-form.component.html'
})
export class GraspGlazingFormComponent {

    @Input()
    glasses: GlassWithPosition[];

    @Input()
    frames: DistanceFrame[];

    @Input()
    availableGlazingBeads: GlazingBead[];

    @Input()
    glazing: Glazing;

    @Input()
    validationErrors;

    @Input()
    addMode = false;

    @Input()
    selectedGlazingBead: number;

    @Input()
    disabled: boolean;

    @Output()
    onGlazingBeadChange = new EventEmitter<number>();

    readonly glazingFormGlassQuantities = []; // disable glass quantity switching feature of app-glass-selection component, handled here

    glazingBeadOptionFormatter: (glazingBead: GlazingBead) => SelectItem;

    constructor(public translate: TranslateService) {
        this.glazingBeadOptionFormatter = (glazingBead: GlazingBead) => new SelectItemImpl(glazingBead.names[this.translate.currentLang], glazingBead.id);
    }

    handleGlazingGlassQuantityChange(glazingGlassQuantity: number): void {
        this.glazing.glazingGlassQuantity = glazingGlassQuantity;
        if (glazingGlassQuantity < 4) {
            this.glazing.glass4id = undefined;
            this.glazing.frame3id = undefined;
        }
        if (glazingGlassQuantity < 3) {
            this.glazing.glass3id = undefined;
            this.glazing.frame2id = undefined;
        }
        if (glazingGlassQuantity < 2) {
            this.glazing.glass2id = undefined;
            this.glazing.frame1id = undefined;
        }
    }

    handleGlazingBeadChange(glazingBeadId: number) {
        this.onGlazingBeadChange.emit(glazingBeadId);
    }
}
