import * as _ from 'underscore';
import {AreaSpecification} from "../drawing-data/AreaSpecification";
import {DrawingData} from "../drawing-data/drawing-data";
import {FieldWithPosition} from "../drawing-data/FieldWithPosition";
import {Grill} from '../drawing-data/Grill';
import {GrillGridWithRhombusOnIntersections} from "../drawing-data/GrillGridWithRhombusOnIntersections";
import {GrillSegment} from "../drawing-data/GrillSegment";
import {GrillType} from "../drawing-data/GrillType";
import {LineCutData} from "../drawing-data/LineCutData";
import {LineGrill} from "../drawing-data/LineGrill";
import {LineGrillSegment} from "../drawing-data/LineGrillSegment";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";
import {PricingGrillSegment} from '../drawing-data/PricingGrillSegment';
import {RelativePosition} from "../drawing-data/RelativePosition";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {DataKeys, DrawingUtil, IntersectionResult, MinMaxXY, Point} from "../drawing-util";
import {WindowParams} from "../painters/WindowParams";
import {ProfilesCompositionDistances} from '../profiles-composition-distances';
import {WindowCalculator} from "../window-calculator";
import {AreaUtils} from "./AreaUtils";
import {CutsUtil} from "./cutUtils";
import {ErrorNames} from "./ErrorNames";
import {FloatOps} from "./float-ops";
import {GrillHelper} from "./grill-helper";
import {GrillPositionValidator} from './grill-position-validator';
import {GrillSegmentGenerator} from "./GrillSegmentGenerator";
import {MullionHelper} from "./MullionHelper";
import {PolygonPoint, PolygonPointUtil} from "./PolygonPoint";
import {GenericGrillGrid} from "../drawing-data/GenericGrillGrid";
import {CutData} from "../drawing-data/CutData";
import {TotalGlazingBeads} from "./total-glazing-beads";

export abstract class PositionsHelper {

    public static updateAbsolutePositions(subWindow: SubWindowData, totalGlazingBeads: TotalGlazingBeads,
                                          profileCompositionDistances: ProfilesCompositionDistances,
                                          addNewGrillMode = false, skipValidation = false): void {
        for (let area of subWindow.areasSpecification) {
            let glazingBeadPointsFull = WindowCalculator.getGlazingBeadPointsFull(subWindow, area.definingMullions,
                totalGlazingBeads.full, profileCompositionDistances);
            let glazingBeadPoints = PolygonPointUtil.toNumbersArray(glazingBeadPointsFull);
            let segmentsByIds: Map<number, GrillSegment> = new Map();
            let fields: FieldWithPosition[] = [new FieldWithPosition(glazingBeadPoints)];
            for (let grill of area.grills) {
                PositionsHelper.updateAbsoluteGrillPosition(grill, area, totalGlazingBeads, glazingBeadPoints, segmentsByIds, fields);
            }
            GrillSegmentGenerator.createPricingSegments(area.grills, glazingBeadPointsFull, addNewGrillMode, skipValidation);
            PositionsHelper.usePricingSegmentsInFields(area.grills, fields);
            AreaUtils.setAreaFields(area, fields);
            if (!addNewGrillMode && area.grills.length === 0) {
                area.fields = [];
            }
        }
        if (!skipValidation) {
            GrillPositionValidator.validateGrills(subWindow, totalGlazingBeads.regular, profileCompositionDistances);
        }
    }

    public static updateAbsoluteGrillPosition(grill: Grill, area: AreaSpecification, totalGlazingBeads: TotalGlazingBeads,
                                              glazingBeadPoints: number[], segmentsByIds: Map<number, GrillSegment>,
                                              fields: FieldWithPosition[]): void {
        GrillHelper.initSegmentsIfNeeded(grill);
        grill.positionsPoints = this.relativeToAbsolute(grill.positions, glazingBeadPoints, segmentsByIds);
        grill.parentFields = DrawingUtil.findFieldsContainingLine(fields, grill.positionsPoints);
        switch (grill.type) {
            case GrillType.LINE_GRILL:
                GrillSegmentGenerator.updateLineGrillSegments(grill as LineGrill);
                break;
            case GrillType.GRID_CROSS_ANGLED:
            case GrillType.GRID_CROSS_SIMPLE:
            case GrillType.GRID_RHOMBUS:
            case GrillType.GRID_STANDARD:
                GrillSegmentGenerator.updateGridGrillSegments(grill as GenericGrillGrid, totalGlazingBeads);
                break;
            default:
                let err = new Error(
                    "PositionsHelper.updateAbsolutePositions(): Not implemented for grill type: " + grill.type);
                err.name = ErrorNames.UNKNOWN_GRILL_TYPE;
                throw err;
        }
        grill.drawingSegments.forEach(s => segmentsByIds.set(s.id, s));
        this.recalculateFields(fields, area.grills, grill);
    }

    public static usePricingSegmentsInFields(grills: Grill[], fields: FieldWithPosition[]): void {
        for (let field of fields) {
            let relevantGrills = _.unique(field.grillSegmentIds.map(id => GrillHelper.findGrillBySegmentId(grills, id)));
            let pricingSegments = [].concat.apply([], relevantGrills.map(g => g.pricingSegments)) as PricingGrillSegment[];
            let pricingSegmentIds = pricingSegments.map(s => s.id);
            field.grillSegmentIds = this.getGrillSegmentsFormingField(pricingSegmentIds, grills, field.positions);
        }
    }

    private static calculateGrillGridWithRhombusParts(grill: GrillGridWithRhombusOnIntersections, fields: FieldWithPosition[],
                                                      grills: Grill[]): void {
        let field = GrillHelper.expectSingleParentField(grill);
        let fieldIndex = fields.indexOf(field);
        let anyPointInField = CutsUtil.getAnyPointInPolygon(field.positions);
        let gridFields = grill.splittedFields.map(f => CutsUtil.trimPolygonWithPolygon(f, field.positions, anyPointInField))
            .filter(f => f);
        let newFields = gridFields.map(gf =>
            new FieldWithPosition(gf,
                [...this.getGrillSegmentsFormingField(field.grillSegmentIds, grills, gf), ...grill.drawingSegments.map(s => s.id)]));
        fields.splice(fieldIndex, 1, ...newFields);
    }

    private static calculateLineGrillParts(currentGrillSegment: LineGrillSegment, fields: FieldWithPosition[],
                                           grill: Grill, grills: Grill[]): FieldWithPosition[] {
        let newParts: FieldWithPosition[] = [];
        // extrapolate the grill line a bit to the outside to make sure it intersects properly
        let intersectionCheckPoints = DrawingUtil.extrapolateSegment(currentGrillSegment.points);
        for (let j = 0; j < fields.length; ++j) {
            let field = fields[j];
            if (DrawingUtil.polygonLineIntersectionCount(field.positions, intersectionCheckPoints) > 1) {
                let topPositions = CutsUtil.applyCut(field.positions, new LineCutData(currentGrillSegment.points, 'top'),
                    grill.width / 2);
                let topSegmentIds = [...this.getGrillSegmentsFormingField(field.grillSegmentIds, grills, topPositions),
                    currentGrillSegment.id];
                let bottomPositions = CutsUtil.applyCut(field.positions, new LineCutData(currentGrillSegment.points, 'bottom'),
                    grill.width / 2);
                let bottomSegmentIds = [...this.getGrillSegmentsFormingField(field.grillSegmentIds, grills,
                    bottomPositions), currentGrillSegment.id];
                newParts.push(new FieldWithPosition(topPositions, topSegmentIds));
                newParts.push(new FieldWithPosition(bottomPositions, bottomSegmentIds));
            } else {
                newParts.push(field);
            }
        }
        return newParts;
    }

    private static getGrillSegmentsFormingField(potentialGrillSegmentIds: number[], grills: Grill[],
                                                fieldPositions: number[]): number[] {
        return potentialGrillSegmentIds.filter(grillSegmentId => {
            let grill = GrillHelper.findGrillBySegmentId(grills, grillSegmentId);
            let grillSegment = GrillHelper.expectLineSegment(GrillHelper.findGrillSegmentById(grills, grillSegmentId));
            let segmentSides = [
                DrawingUtil.getParallelLine(grillSegment.points, grill.width / 2),
                DrawingUtil.getParallelLine(grillSegment.points, -grill.width / 2)
            ];
            let tolerance = Math.SQRT1_2;
            // at least one side of the segment needs to be close enough to the field
            for (let index = 0; index < fieldPositions.length; index += 2) {
                let fieldLine = [
                    DrawingUtil.getPoint(fieldPositions, index),
                    DrawingUtil.getPoint(fieldPositions, index + 1),
                    DrawingUtil.getPoint(fieldPositions, index + 2),
                    DrawingUtil.getPoint(fieldPositions, index + 3)
                ];
                if (segmentSides.some(side => {
                    let distance = DrawingUtil.distanceBetweenLineSegments(side, fieldLine);
                    return !FloatOps.gt(distance, tolerance);
                })) {
                    return true;
                }
            }
            return false;
        });
    }

    private static recalculateFields(fields: FieldWithPosition[], grills: Grill[], grill: Grill): void {
        if (grill.type === GrillType.GRID_RHOMBUS) {
            this.calculateGrillGridWithRhombusParts((grill as GrillGridWithRhombusOnIntersections), fields, grills);
        } else {
            for (let segment of grill.drawingSegments) {
                let newParts: FieldWithPosition[] = [];
                if (GrillSegment.isLine(segment)) {
                    newParts = this.calculateLineGrillParts(segment, fields, grill, grills);
                } else {
                    let err = new Error(
                        "PositionsHelper.recalculateFieldsAndClipPath():" +
                        " Not implemented for grill segment type: " +
                        segment.type);
                    err.name = ErrorNames.NOT_IMPLEMENTED;
                    throw err;
                }
                fields.length = 0;
                fields.push(...newParts);
            }
        }
    }

    static getVeneerIntersection(index: number, relPosition: RelativePosition,
                                 intersections: IntersectionResult[]): number[] {
        let shift = () => (index + relPosition.id) % 2;
        return [intersections[shift()].x, intersections[shift()].y];
    }

    static veneerToAbsolute(relPosition: RelativePosition, polygonPoints: number[], subwindowPoints: number[]): number[] {
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(subwindowPoints);
        let line;
        switch (relPosition.type) {
            case PositionReferenceType.VENEER:
                line = [0, box.minY + relPosition.percent, 1, box.minY + relPosition.percent];
                break;
            case PositionReferenceType.WEBSHOP_VENEER_HORIZONTAL:
                line = [0, box.maxY - relPosition.percent, 1, box.maxY - relPosition.percent];
                break;
            case PositionReferenceType.WEBSHOP_VENEER_VERTICAL:
                line = [box.minX + relPosition.percent, 0, box.minX + relPosition.percent, 1];
                break;
            default:
                throw new Error("Unsupported veneer position type: " + PositionReferenceType[relPosition.type]);
        }
        let intersections = DrawingUtil.polygonLineIntersections(polygonPoints, line, true);
        if (intersections.length !== 2) {
            let err = new Error("Invalid intersections count: " + intersections.length);
            err.name = ErrorNames.MULLION_ILLEGAL_POSITION;
            throw err;
        }
        return [...PositionsHelper.getVeneerIntersection(0, relPosition, intersections),
            ...PositionsHelper.getVeneerIntersection(1, relPosition, intersections)].map(p => FloatOps.round(p));
    }

    public static relativeToAbsolute(relPositions: RelativePosition[], polygonPoints: number[],
                                     map: Map<number, GrillSegment>, subwindowPoints?: number[], forceY?: boolean): number[] {
        if (forceY) {
            let start = this.singleRelToAbs(relPositions[0], polygonPoints, map);
            let end = this.singleRelToAbsForceY(relPositions[1], polygonPoints, map, start[1]);
            return [...start, ...end];
        }
        if (relPositions.length === 1 && MullionHelper.isVeneerPosition(relPositions[0])) {
            return PositionsHelper.veneerToAbsolute(relPositions[0], polygonPoints, subwindowPoints);
        }
        if (relPositions.length !== 2) {
            let err = new Error("PositionsHelper: Oops... Grill powinien mieć dwie pozycje");
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
        return this.singleRelToAbs(relPositions[0], polygonPoints, map)
            .concat(this.singleRelToAbs(relPositions[1], polygonPoints, map));
    }

    public static singleRelToAbs(relPosition: RelativePosition, outerPolygon: number[],
                                 map: Map<number, GrillSegment>, subWindow?: SubWindowData): number[] {
        switch (relPosition.type) {
            case PositionReferenceType.GRILL:
                return this.findRelToAbsForGrill(map.get(relPosition.id), relPosition.percent);
            case PositionReferenceType.INNER_FRAME:
            case PositionReferenceType.GLAZING_BEAD:
                return this.findRelToAbsForPolygon(relPosition.id, relPosition.percent, outerPolygon);
            case PositionReferenceType.MULLION: {
                let segment;
                if (subWindow) {
                    segment = MullionHelper.findMullionSegmentById(relPosition.id, subWindow);
                } else {
                    segment = map.get(relPosition.id);
                }
                return this.findRelToAbsForGrill(segment, relPosition.percent);
            }
            default: {
                let err = new Error("PositionsHelper: Oops... Typ " + relPosition.type + " nie jest obslugiwany");
                err.name = ErrorNames.GENERAL_ERROR;
                throw err;
            }
        }
    }

    public static singleRelToAbsForceY(relPosition: RelativePosition, outerPolygon: number[],
                                       map: Map<number, GrillSegment>, forceY: number): number[] {
        switch (relPosition.type) {
            case PositionReferenceType.INNER_FRAME:
            case PositionReferenceType.GLAZING_BEAD:
                return this.findRelToAbsForPolygonForceY(relPosition.id, outerPolygon, forceY);
            default: {
                let err = new Error("PositionsHelper: Oops... Typ " + relPosition.type + " nie jest obslugiwany");
                err.name = ErrorNames.GENERAL_ERROR;
                throw err;
            }
        }
    }

    public static findRelToAbsForGrill(grillSegment: GrillSegment, percent: number): number[] {
        if (GrillSegment.isLine(grillSegment)) {
            return this.lineSegmentPercentToAbs(grillSegment.points, percent);
        } else if (GrillSegment.isArc(grillSegment)) {
            // todo
        } else {
            let err = new Error(
                "PositionsHelper: Oops... GrillSegmentType " + grillSegment.type + " nie jest obslugiwany");
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
    }

    private static findRelToAbsForPolygon(sideNumber: number, percent: number, polygon: number[]): number[] {
        let lineSegment = PositionsHelper.findLineOnPolygon(sideNumber, polygon);
        return this.lineSegmentPercentToAbs(lineSegment, percent);
    }

    private static findRelToAbsForPolygonForceY(sideNumber: number, polygon: number[], forceY: number): number[] {
        let lineSegment = PositionsHelper.findLineOnPolygon(sideNumber, polygon);
        let forcePercent = (forceY - lineSegment[1]) / (lineSegment[3] - lineSegment[1]);
        let x = lineSegment[0] + (lineSegment[2] - lineSegment[0]) * forcePercent;
        return [FloatOps.round(x), forceY];
    }

    private static lineSegmentPercentToAbs(points: number[], percent: number): number[] {
        let x = points[0] + (points[2] - points[0]) * percent;
        let y = points[1] + (points[3] - points[1]) * percent;
        return [FloatOps.round(x), FloatOps.round(y)];
    }

    public static absoluteToRelative(target: Snap.Element, elementType: string, point: number[]) {
        switch (elementType) {
            case WindowParams.GLAZING_BEAD_ELEM:
                let glazingBeadPoints = PolygonPointUtil.toNumbersArray(target.data(DataKeys.GLAZING_BEAD) as PolygonPoint[]);
                return PositionsHelper.prepareGlazingBeadReference(glazingBeadPoints, point);
            case WindowParams.MUNTIN_ELEM:
                let grillSegment = target.data(DataKeys.GRILL_SEGMENT);
                return PositionsHelper.prepareRelativePositionForSegment(grillSegment, PositionReferenceType.GRILL, point);
            case WindowParams.MULLION_ELEM:
                let mullionSegment = target.data(DataKeys.MULLION).drawingSegments[0];
                return PositionsHelper.prepareRelativePositionForSegment(mullionSegment, PositionReferenceType.MULLION,
                    point);
            case WindowParams.INNER_FRAME_ELEM:
                let innerFramePoints = PolygonPointUtil.toNumbersArray(target.data(DataKeys.INNER_FRAME) as PolygonPoint[]);
                return PositionsHelper.prepareInnerFrameReference(innerFramePoints, point);
            default:
                console.error("PositionsHelper.absoluteToRelative(): Not implemented for element type: " + elementType);
                break;
        }
    }

    public static lineAbsToPercent(linePoints: number[], point: number[]): number {
        let deltaX = linePoints[2] - linePoints[0];
        let deltaY = linePoints[3] - linePoints[1];
        if (deltaX === 0 && deltaY === 0) {
            console.error("Line has no length: " + linePoints);
            return 0;
        }
        if (Math.abs(deltaX) > Math.abs(deltaY)) {
            return (point[0] - linePoints[0]) / deltaX;
        } else {
            return (point[1] - linePoints[1]) / deltaY;
        }
    }

    public static prepareGlazingBeadReference(glazingBeadPoints: number[], point: number[]): RelativePosition {
        return PositionsHelper.preparePolygonReference(glazingBeadPoints, point, PositionReferenceType.GLAZING_BEAD);
    }

    public static prepareInnerFrameReference(innerFrame: number[], point: number[]): RelativePosition {
        return PositionsHelper.preparePolygonReference(innerFrame, point, PositionReferenceType.INNER_FRAME);
    }

    public static preparePolygonReference(polygonPoints: number[], point: number[],
                                          reference: PositionReferenceType): RelativePosition {
        let sideNumber;
        let percent;
        let minDistance = Infinity;
        for (let i = 0; i < polygonPoints.length; i += 2) {
            let pointsAB = [polygonPoints[i],
                polygonPoints[i + 1],
                polygonPoints[(i + 2) % polygonPoints.length],
                polygonPoints[(i + 3) % polygonPoints.length]];
            let distance = DrawingUtil.distanceFromLine(pointsAB, point);
            if (distance < minDistance) {
                minDistance = distance;
                sideNumber = i / 2;
                percent = PositionsHelper.lineAbsToPercent(pointsAB, point);
            }
        }
        if (minDistance > 1) {
            console.warn("PositionsHelper.preparePolygonReference(): Distance is quite big: " + minDistance);
        }
        return new RelativePosition(reference, sideNumber, percent);
    }

    public static findLineOnPolygonFull(sideNumber: number, polygon: PolygonPoint[]): PolygonPoint[] {
        let lineEnds = polygon.slice(sideNumber, sideNumber + 2);
        if (lineEnds.length === 1) {
            lineEnds = lineEnds.concat(polygon[0]);
        }
        if (lineEnds.length !== 2) {
            let err = new Error(
                "PositionsHelper.findLineOnGlazingBead(): line not found! " + sideNumber + " # " + polygon);
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
        return lineEnds;
    }

    public static findLineOnPolygon(sideNumber: number, polygon: number[]): number[] {
        let lineSegment = polygon.slice(sideNumber * 2, (sideNumber * 2) + 4);
        if (lineSegment.length === 2) {
            lineSegment = lineSegment.concat(polygon.slice(0, 2));
        }
        if (lineSegment.length !== 4) {
            let err = new Error(
                "PositionsHelper.findLineOnGlazingBead(): line not found! " + sideNumber + " # " + polygon);
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
        return lineSegment;
    }

    private static prepareRelativePositionForSegment(segment: GrillSegment, reference: PositionReferenceType,
                                                     point: number[]) {
        if (GrillSegment.isLine(segment)) {
            let percent = PositionsHelper.lineAbsToPercent(segment.points, point);
            return new RelativePosition(reference, segment.id, percent);
        }
        console.error("PositionsHelper.absoluteToRelative(): Grill segment type not supported");
    }

    public static findNewPercentForSegment(segment: GrillSegment, perpendicularLine: number[]): number {
        if (GrillSegment.isLine(segment)) {
            let intersection = DrawingUtil.lineIntersection(segment.points, perpendicularLine);
            if (intersection.onLine1) {
                return PositionsHelper.lineAbsToPercent(segment.points, [intersection.x, intersection.y]);
            } else {
                let err = new Error("New position would go outside the referenced grill bounds");
                err.name = ErrorNames.GRILL_FAILED_TO_CHANGE_POSITION;
                throw err;
            }
        } else {
            // todo arc segment
            let err = new Error("Not supported grill segment type");
            err.name = ErrorNames.NOT_IMPLEMENTED;
            throw err;
        }
    }

    public static setNewPercentForPolygonSide(relPosition: RelativePosition, polygon: PolygonPoint[], perpendicularLine: number[]) {
        let lineFull = PositionsHelper.findLineOnPolygonFull(relPosition.id, polygon);
        let isArc = lineFull.every(polygonPoint => polygonPoint.isArc);
        // we don't want to move (or treat as an error) the grill attached to the bead's corner (VIN-770)
        if (!isArc && (FloatOps.eq(relPosition.percent, 0) || FloatOps.eq(relPosition.percent, 1))) {
            return;
        }
        let line = PolygonPointUtil.toNumbersArray(lineFull);
        let intersection;
        if (isArc) {
            let arcPolygon = DrawingUtil.getArcPoints(polygon, relPosition.id);
            let intersections = DrawingUtil.polygonLineIntersections(PolygonPointUtil.toNumbersArray(arcPolygon), perpendicularLine, true,
                true);
            if (intersections.length !== 1) {
                let err = new Error("Invalid intersection count on arc");
                err.name = ErrorNames.GRILL_FAILED_TO_CHANGE_POSITION;
                throw err;
            }
            intersection = intersections[0];
        } else {
            intersection = DrawingUtil.lineIntersection(line, perpendicularLine);
        }
        if (intersection.onLine1) {
            relPosition.percent = PositionsHelper.lineAbsToPercent(line, [intersection.x, intersection.y]);
        } else {
            let err = new Error("New position would go outside the referenced bead bounds");
            err.name = ErrorNames.GRILL_FAILED_TO_CHANGE_POSITION;
            throw err;
        }
    }

    public static getFramesAndGlazingBeads(data: DrawingData, profileCompositionDistances: ProfilesCompositionDistances,
                                           skipValidation: boolean) {
        let frames = new Map<AreaSpecification, number[]>();
        let glazingBeads = new Map<AreaSpecification, number[]>();
        let boundingBox = DrawingUtil.calculateTotalBoundingBox(data.windows);
        for (let window of data.windows) {
            for (let subWindow of window.subWindows) {
                let totalGlazingBead = WindowCalculator.getTotalGlazingBeadsPoints(subWindow, data.cuts, boundingBox,
                    profileCompositionDistances, skipValidation);
                for (let area of subWindow.areasSpecification) {
                    let glazingPoints = WindowCalculator.getGlazingBeadPoints(subWindow, area.definingMullions,
                        totalGlazingBead, profileCompositionDistances);
                    let framePoints = CutsUtil.applyCuts(subWindow.points, data.cuts, 0);
                    frames.set(area, framePoints);
                    glazingBeads.set(area, glazingPoints);
                }
            }
        }
        return {frames: frames, glazingBeads: glazingBeads};
    }

    public static checkForGlazingBeadChanges(newSubWindow: number[], newGlazingBead: number[], oldSubWindow: number[],
                                             oldGlazingBead: number[]) {
        if (newGlazingBead.length !== oldGlazingBead.length) {
            let err = new Error(
                "Number of glazing bead vertices changed: " + oldGlazingBead.length + " -> " + newGlazingBead.length);
            err.name = ErrorNames.GRILL_FAILED_TO_CHANGE_POSITION;
            throw err;
        }
        if (newSubWindow.length !== oldSubWindow.length) {
            let err = new Error(
                "Number of outer frame vertices changed: " + oldSubWindow.length + " -> " + newSubWindow.length);
            err.name = ErrorNames.GRILL_FAILED_TO_CHANGE_POSITION;
            throw err;
        }
    }

    public static addRelativePosition(grill: Grill, target: Snap.Element, point: number[], isMullion: boolean) {
        let type = WindowParams.getSnapElemType(target);
        let position = PositionsHelper.absoluteToRelative(target, type, point);
        if (grill.positions.length > 0 &&
            (grill.positions[0].type === position.type && grill.positions[0].id === position.id)) {
            let err = new Error("Grills and mullions can't start and end on the same object");
            err.name = isMullion ? ErrorNames.MULLION_ILLEGAL_POSITION : ErrorNames.GRILL_ILLEGAL_POSITION;
            throw err;
        }
        grill.positions.push(position);
    }

    private static findClosestNeighboursOnBothSides(point: Point, glazingBead: number[], grillSegments: LineGrillSegment[],
                                             vertical = false): { segment: LineGrillSegment, intersection: IntersectionResult }[] {
        let coordinate = vertical ? 'y' : 'x';
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(glazingBead);
        let line = vertical ? [point.x, box.minY, point.x, box.maxY] : [box.minX, point.y, box.maxX, point.y];
        let glazingBeadIntersections = DrawingUtil.polygonLineIntersections(glazingBead, line);
        let possibleNeighbours = [
            ...glazingBeadIntersections.map(i => ({segment: null, intersection: i})),
            ...grillSegments.map(gs => ({segment: gs, intersection: DrawingUtil.lineIntersection(gs.points, line)}))
        ].filter(pn => pn.intersection.onLine1)
            .sort((a, b) => a.intersection[coordinate] - b.intersection[coordinate]);
        return [
            possibleNeighbours.filter(pn => pn.intersection[coordinate] < point[coordinate]).pop(),
            possibleNeighbours.filter(pn => pn.intersection[coordinate] > point[coordinate]).shift()
        ];
    }

    public static findGrillGridReferencePoints(grill: Grill, glazingBead: number[], area: AreaSpecification, clickedPoint: Point,
                                               onlyLookOnXAxis = false): void {
        grill.positions = [];
        let grillSegments = _.flatten(area.grills.map(g => g.drawingSegments.filter(GrillSegment.isLine)));
        let neighbours;
        if (onlyLookOnXAxis) {
            neighbours = PositionsHelper.findClosestNeighboursOnBothSides(clickedPoint, glazingBead, grillSegments);
        } else {
            let xyNeighbours = [
                PositionsHelper.findClosestNeighboursOnBothSides(clickedPoint, glazingBead, grillSegments),
                PositionsHelper.findClosestNeighboursOnBothSides(clickedPoint, glazingBead, grillSegments, true)
            ];
            neighbours = xyNeighbours.reduce(
                (a, b) => a.filter(n => n.segment).length > b.filter(n => n.segment).length ? a : b);
        }
        for (let neighbour of neighbours) {
            let position: RelativePosition;
            if (neighbour.segment) {
                let percent = PositionsHelper.lineAbsToPercent(neighbour.segment.points,
                    [neighbour.intersection.x, neighbour.intersection.y]);
                position = new RelativePosition(PositionReferenceType.GRILL, neighbour.segment.id, percent);
            } else {
                position = PositionsHelper.prepareGlazingBeadReference(glazingBead,
                    [neighbour.intersection.x, neighbour.intersection.y]);
            }
            grill.positions.push(position);
        }
    }

    public static winglessModeProcessing(grill: Grill, area: AreaSpecification, subwindow: SubWindowData, cuts: CutData[],
                                         totalBoundingBox: MinMaxXY,
                                         profileCompositionDistances: ProfilesCompositionDistances): void {
        if (!GrillHelper.isGrid(grill)) {
            return;
        }
        let grillGrid = grill as GenericGrillGrid;
        if (subwindow.mullions.length > 0 || GrillHelper.areaHasGrills(area) || WindowCalculator.isSubWindowF(subwindow)) {
            grillGrid.winglessMode = false;
        }
    }
}
