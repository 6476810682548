<div class="new-form-row date-range-filter date-range-filter-kind" (click)="bypassColumnClick($event)">
    <app-select inputId="kind" [options]="dateRangeKinds | async" [allowSelectingNone]="true" [ngModel]="kind"
                (ngModelChange)="handleKindChange($event)">
        <ng-template pTemplate="label"></ng-template>
    </app-select>
</div>
<div class="new-form-row date-range-filter date-range-filter-date-ranges-container" *ngIf="displayCalendars()" (click)="bypassColumnClick($event)">
    <app-calendar [ngClass]="{'date-range-filter-single': !displayTo()}" inputId="from"
                  label="{{ 'GENERAL.DATE_FROM' | translate }}" [inline]="false" [utc]="true" [maxDate]="to"
                  [ngModel]="from" (ngModelChange)="handleFromChange($event)">
        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
            <div *ngIf="displayTo()" class="date-range-filter-label">
                <label [attr.for]="inputId">{{ label }}</label>
                <app-simple-button label="{{ 'GENERAL.FILTER_CLEAR' | translate }}" icon="clear" [inlineLabel]="false"
                                   type="filter" [size]="24" align="right"
                                   (onClick)="handleFromChange(undefined)"></app-simple-button>
            </div>
        </ng-template>
    </app-calendar>
    <app-simple-button *ngIf="!displayTo()" label="{{ 'GENERAL.FILTER_CLEAR' | translate }}" icon="clear"
                       [inlineLabel]="false" type="filter" [size]="32"
                       (onClick)="handleFromChange(undefined)"></app-simple-button>
    <app-calendar *ngIf="displayTo()" inputId="to" label="{{ 'GENERAL.DATE_TO' | translate }}" [inline]="false"
                  [utc]="true" [minDate]="from" [ngModel]="to" (ngModelChange)="handleToChange($event)">
        <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
            <div class="date-range-filter-label">
                <label [attr.for]="inputId">{{ label }}</label>
                <app-simple-button label="{{ 'GENERAL.FILTER_CLEAR' | translate }}" icon="clear" [inlineLabel]="false"
                                   type="filter" [size]="24" align="right"
                                   (onClick)="handleToChange(undefined)"></app-simple-button>
            </div>
        </ng-template>
    </app-calendar>
</div>
