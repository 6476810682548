<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(SystemGlazingPackageField.NAME)"
                            inputId="name" label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.NAME' | translate }}"
                            validationKeyPrefix="names" [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [disabled]="fieldUsage.disabled(SystemGlazingPackageField.NAME)"
                            [required]="true" [field]="item.names"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(SystemGlazingPackageField.WEBSHOP_DESCRIPTION, item)" inputId="description" label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.DESCRIPTION' | translate }}"
                            validationKeyPrefix="description" [validationErrors]="validationErrors" [maxlength]="500"
                            [disabled]="fieldUsage.disabled(SystemGlazingPackageField.WEBSHOP_DESCRIPTION)"
                            [field]="(item | as:WebshopGlazingPackage).description"></app-translation-fields>

    <div *ngIf="fieldUsage.show(SystemGlazingPackageField.TAGS)" class="new-form-row">
        <div class="new-form-field">
            <div class="new-form-field-label-container">
                <div>
                    <label for="tags">{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.TAGS' | translate }}</label>
                </div>
            </div>
            <div class="new-form-field-input-container webshop-glazing-package-tag-container">
                <p-multiSelect inputId="tags" [options]="tags" appendTo="body" [ngModel]="selectedTags"
                               [disabled]="fieldUsage.disabled(SystemGlazingPackageField.TAGS)"
                               (ngModelChange)="handleTagsChange($event)">
                    <ng-template pTemplate="item" let-tag>
                        <label>{{ tag.value.tagText[translate.currentLang] }}</label>
                    </ng-template>
                    <ng-template pTemplate="selectedItems" let-tags>
                        <div *ngFor="let tag of tags; let last = last" class="selected-tag-item">
                            <span>{{ tag.tagText[translate.currentLang] + (last ? '' : ',') }}</span>
                        </div>
                    </ng-template>
                </p-multiSelect>
            </div>
        </div>
    </div>

    <div *ngIf="fieldUsage.show(SystemGlazingPackageField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(SystemGlazingPackageField.ACTIVE)"
                      [(validationMessageKey)]="validationErrors['active']"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(SystemGlazingPackageField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex" label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(SystemGlazingPackageField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [required]="true" [(ngModel)]="item.sortIndex"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(SystemGlazingPackageField.IMAGE_SECTION, item)" class="new-form-row">
        <p-accordion>
            <p-accordionTab header="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.IMAGE_SECTION' | translate }}" [selected]="false">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(SystemGlazingPackageField.IMAGE_SECTION)"
                                 [accept]="'.svg'" [validationFileType]="'image/svg+xml'"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="150000" [maxWidth]="500" [maxHeight]="500"
                                 [addButtonLabel]="'WEBSHOP_GLAZING_PACKAGE.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'WEBSHOP_GLAZING_PACKAGE.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'WEBSHOP_GLAZING_PACKAGE.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
