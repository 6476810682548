export class MapToJsonTransformer {
    public static replacer(key, value) {
        const originalObject = this[key];
        if (originalObject instanceof Map) {
            return {
                dataType: 'Map',
                value: Array.from(originalObject.entries()),
            };
        } else {
            return value;
        }
    }

    public static reviver(key, value) {
        if (typeof value === 'object' && value !== null) {
            if (value.dataType === 'Map') {
                return new Map(value.value);
            }
        }
        return value;
    }
}
