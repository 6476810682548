<p-dialog *ngIf="dataReady" [modal]="true" (onHide)="resetDialog()" [visible]="true" [resizable]="false" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_PREDEF_GLAZING_PACKAGE_CHANGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog new-form-600">
        <app-predefined-glazing-selection
            [validationErrors]="validationErrors"
            [quantityOptions]="quantities"
            (quantityChanged)="setSelectedQuantity($event)"
            [categoryOptions]="categories"
            (categoryChanged)="setSelectedCategory($event)"
            [frameCategoryOptions]="frameCategories"
            (frameCategoryChanged)="setSelectedFrameCategory($event)"
            [glazingPackageOptions]="glazingPackages"
            (glazingPackageChanged)="setSelectedGlazingPackage($event)"
        ></app-predefined-glazing-selection>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
