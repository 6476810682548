<div class="new-form-600">
    <app-translation-fields inputId="name" label="{{ 'CATALOG_PROPERTY.FORM.NAME' | translate }}"
                            validationKeyPrefix="name"
                            [validationErrors]="validationErrors" [maxlength]="100"
                            [field]="item.name"></app-translation-fields>

    <ng-container *ngIf="showVisibilityCheckboxes()">
        <div class="new-form-row">
            <app-checkbox inputId="visibleInApplication"
                          label="{{ 'CATALOG_PROPERTY.FORM.VISIBLE_IN_APPLICATION' | translate }}"
                          [(ngModel)]="item.visibleInApplication"></app-checkbox>
        </div>

        <div class="new-form-row">
            <app-checkbox inputId="visibleOnPrint"
                          label="{{ 'CATALOG_PROPERTY.FORM.VISIBLE_ON_PRINT' | translate }}"
                          [(ngModel)]="item.visibleOnPrint"></app-checkbox>
        </div>
    </ng-container>

    <ng-container *ngIf="showBusinessTypeRelatedCheckboxes">
        <div class="new-form-row">
            <app-checkbox inputId="availableInNonFixedWindowsCheckbox"
                          label="{{ 'CATALOG_PROPERTY.FORM.AVAILABLE_IN_NON_FIXED_WINDOWS' | translate }}"
                          [(ngModel)]="item.availableInNonFixedWindows"></app-checkbox>
        </div>
        <div class="new-form-row">
            <app-checkbox inputId="availableInFixedWindowsCheckbox"
                          label="{{ 'CATALOG_PROPERTY.FORM.AVAILABLE_IN_FIXED_WINDOWS' | translate }}"
                          [(ngModel)]="item.availableInFixedWindows"></app-checkbox>
        </div>
    </ng-container>

    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'CATALOG_PROPERTY.FORM.ACTIVE' | translate }}"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="item.id != null" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'CATALOG_PROPERTY.FORM.SORT_INDEX' | translate }}"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <p-accordion *ngIf="hasApplication()" [multiple]="true">
        <p-accordionTab [selected]="false"
                        header="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.APPLICABLE' | translate }}">
            <p-listbox [(ngModel)]="item.applicableTo"
                       [multiple]="true"
                       [options]="availableApplicableTo | selectItemTranslate"></p-listbox>
        </p-accordionTab>
    </p-accordion>
</div>
<div class="new-form-very-wide">

    <p-pickList id="catalogOptionPickList" [source]="availableOptions" [target]="selectedOptions"
                sourceHeader="{{ 'CATALOG_PROPERTY.FORM.AVAILABLE_OPTIONS' | translate }}"
                targetHeader="{{ 'CATALOG_PROPERTY.FORM.ADDED_OPTIONS' | translate }}" [responsive]="true"
                filterBy="symbol" styleClass="catalog-property-options-list"
                [sourceStyle]="{'height': '400px'}" [targetStyle]="{'height': '400px'}"
                [showSourceControls]="false" [showTargetControls]="true"
                (onMoveToSource)="handleMoveToSource($event.items)"
                (onMoveAllToSource)="handleMoveToSource($event.items)"
                (onMoveToTarget)="handleMoveToTarget($event.items)"
                (onMoveAllToTarget)="handleMoveToTarget($event.items)"
                (onTargetReorder)="handleTargetReorder()">
        <ng-template let-option pTemplate="item">
            <span class="property-picklist-column"
                  [ngClass]="{'inactive-option': !option.active}">{{ option.symbol }}</span>
        </ng-template>
    </p-pickList>

    <div *ngIf="validationErrors['options'] != undefined" class="new-form-row">
        <div class="new-form-field new-form-field-error new-form-field-error-message">
            {{ validationErrors['options'] | translate }}
        </div>
    </div>

</div>
