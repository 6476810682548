<div id="designer-container" [class.sidebarOnlyMode]="sidebarOnlyMode">
    <app-drawing-tool-controls #drawingToolsControl
                               [mode]="DrawingToolControlsMode.WINDOW"
                               [grillColors]="grillColors"
                               [grillRalColors]="grillRalColors"
                               [grillNcsColors]="grillNcsColors"
                               [allActiveColors]="allActiveColors"
                               [designer]="designer"
                               [showPointerPosition]="settings.pointerPosition"
                               [sidebarOnlyMode]="sidebarOnlyMode"
                               [readOnlyMode]="readOnlyMode"
                               [requiredFieldFilled]="requiredFieldFilled"
                               [offer]="offer"
                               [productionOrder]="productionOrder"
                               (onDelete)="deleteSelectedItem()"
                               (onAddConfigAddon)="addConfigAddon($event)"
                               (onAddStandaloneGlazingPackage)="addStandaloneGlazingPackage()"
                               (onAligmentToolUsed)="aligmentToolMenuSelected($event)"
                               (onMirrorToolUsed)="onMirrorToolUsed()"
                               (onExtendHorizontalGrillsUsed)="designer.extendHorizontalGrills()"
                               (onCancelMode)="cancelMode()"
                               (onEnableCutMode)="enableCutMode()"
                               (onTrimToolUsed)="trimToolUsed()"
                               (onEnableHandleMode)="enableHandleMode($event)"
                               (onExit)="exit()"
                               (onGrillParamsChange)="handleGrillParamsChange($event)"
                               (onInitGrillMode)="initGrillMode($event)"
                               (onOpenSettings)="openSettings()"
                               (onRedoLast)="redoLast()"
                               (onResetSelectedElements)="resetSelectedElements()"
                               (onSaveAndExit)="doSaveAndExit()"
                               (onShapeChange)="windowShapeMenuSelected($event)"
                               (onUndoLast)="undoLast()"
    >
    </app-drawing-tool-controls>

    <div id="window-designer-container" class="preview-container">
        <!--Window designer component-->
        <window-designer id="designer" #designer
                         [sidebarOnlyMode]="sidebarOnlyMode"
                         [isTerrace]="isTerrace"
                         [readOnlyMode]="readOnlyMode"
                         [dataModificationMode]="dataModificationMode"
                         [requiredFieldFilled]="requiredFieldFilled"
                         [visibilitySettings]="settings"
                         (onGrillAdded)="finalizeAddingGrill()"
                         (drawingDataChanged)="onDrawingDataChanged($event)"
                         (designerInitialized)="afterDesignerInitialized($event)"
                         (windowElementSelected)="windowElementSelected($event)"
                         (enterPressed)="submit()"
                         (onChangeDisplayAddWindowDialog)="changeHotkeyRegistration($event)"
                         (refilterGlazingBeads)="resetAllGlazingBeadIfNeeded()"
                         (newWindowAdded)="onNewWindowAdd()"
                         (cuttingFinished)="requiredFieldsOnSubWindowsFilled(); recheckRequiredFieldsOnMainTab()"
                         (revalidateRequiredFields)="validateRequiredFields()"
                         (mouseCoordsChange)="updateMousePosition($event)"
                         (openConfigAddonList)="showConfigAddonsList()"
                         (businessTypesLoadInProgressChange)="onBusinessTypesLoadInProgressChange($event)"
                         (onSubwindowTypeChange)="onSubwindowTypeChange($event)"
                         (onTotalSizeChange)="updateStoredWindowSize($event)">
        </window-designer>
    </div>
    <!--Settings dialog-->
    <div class="dialog-window">
        <p-dialog *ngIf="showSettingsDialog" id="settings-dialog" [resizable]="false" [visible]="true" [modal]="true"
                  [focusOnShow]="false" (onHide)="hideSettingsDialog()" header="{{'OFFER.MENU.SETTINGS.TITLE' | translate}}">
            <div class="scrolled-dialog">
                <div class="new-form-400">
                <span *ngFor="let setting of settingsList; let fieldIndex = index">
                    <div class="new-form-row">
                        <app-checkbox
                                label="{{ 'OFFER.MENU.SETTINGS.OPTIONS.' + setting.toUpperCase() | translate }}"
                                inputId="{{ 'settingsItem_' + fieldIndex }}"
                                [ngModel]="settings[setting]"
                                (change)="saveSettings(setting)"></app-checkbox>
                    </div>
                </span>
                </div>
            </div>
            <p-footer>
                <div class="p-dialog-buttonpane">
                    <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                                       (onClick)="hideSettingsDialog()"></app-simple-button>
                </div>
            </p-footer>
        </p-dialog>
    </div>
    <!-- ADD / EDIT CONFIGURABLE ADDON DIALOG -->
    <app-config-editor *ngIf="configurableAddonDialogData != undefined && !isViewingDeprecatedConfigAddon()"
                       [applicableTo]="[configurableAddonDialogData.application]"
                       [subwindowTypes]="designer.subwindowTypes"
                       [offer]="offer" [productionOrder]="productionOrder"
                       [currentActionPosition]="configurableAddonPosition"
                       [displayedDialogData]="configurableAddonDialogData"
                       [saveAddonOnFormSubmit]="false"
                       [dialogType]="dialogType" [initialQuantity]="designer.offerPosition?.quantity"
                       (onClose)="onConfigurableAddonDialogSuccess($event)"
    ></app-config-editor>

    <!-- REPLACE DEFAULTS DIALOG -->
    <p-dialog id="window-system-defaults-replace-dialog" [(visible)]="displayConfirmReplacingWindowSystemDefaultsDialog"
              [resizable]="false" [modal]="true" [focusOnShow]="false"
              header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}"
              (onHide)="hideConfirmReplacingWindowSystemDefaultsDialog()">

        <div class="new-form-400">
            <p>{{ 'OFFER.TABS.SECTION.DEFAULTS.CONFIRM.BODY' | translate }}</p>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="window-system-defaults-replace-confirm-button"
                                   label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                                   (onClick)="onSetDefaultValuesButtonClick(true)"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                                   (onClick)="hideConfirmReplacingWindowSystemDefaultsDialog()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>

    <!-- REPLACE MODEL DIALOG -->
    <p-dialog id="model-replace-dialog" [(visible)]="displayConfirmOverwritingModelDialog"
              [resizable]="false" [modal]="true" [focusOnShow]="false"
              header="{{ 'WINDOW_SYSTEM_MODEL.REPLACE_MODEL_DIALOG.HEADER' | translate }}"
              (onHide)="hideConfirmReplacingWindowSystemDefaultsDialog()">

        <div class="new-form-400 preserveWhitespace">
            <p>{{ 'WINDOW_SYSTEM_MODEL.REPLACE_MODEL_DIALOG.BODY' | translate }}</p>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="window-system-defaults-replace-confirm-button"
                                   label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                                   (onClick)="onSetDefaultValuesButtonClick(true)"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                                   (onClick)="hideConfirmReplacingWindowSystemDefaultsDialog()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>

    <!-- OVERWRITE DEFAULTS DIALOG -->
    <app-confirm-overwriting-window-system-defaults-dialog [visible]="displayConfirmOverwritingSubsystemGroupsDefaultsDialog"
                                                           [selections]="availableSubsystemGroups"
                                                           [title]="'OFFER.TABS.SECTION.DEFAULTS.SUBSYSTEM_GROUPS'"
                                                           body="OFFER.TABS.SECTION.DEFAULTS.OVERWRITE.BODY_SUBSYSTEM_GROUPS"
                                                           (onConfirm)="onSetDefaultValuesButtonClick(true)"
                                                           (onHide)="hideConfirmReplacingWindowSystemDefaultsDialog()"></app-confirm-overwriting-window-system-defaults-dialog>

    <app-confirm-overwriting-window-system-defaults-dialog [visible]="displayConfirmOverwritingSystemDefaultsDialog"
                                                           [selections]="availableSubsystems"
                                                           [title]="'OFFER.TABS.SECTION.DEFAULTS.SUBSYSTEMS'"
                                                           body="OFFER.TABS.SECTION.DEFAULTS.OVERWRITE.BODY_SUBSYSTEM"
                                                           (onConfirm)="onSetDefaultValuesButtonClick(true)"
                                                           (onHide)="hideConfirmReplacingWindowSystemDefaultsDialog()"></app-confirm-overwriting-window-system-defaults-dialog>

    <!--Exit confirmation dialog-->
    <app-exit-without-saving-confirmation-dialog [visible]="showExitWithoutSavingConfirmationDialog"
                                                 (hideDialog)="showExitWithoutSavingConfirmationDialog = false"
                                                 (exitWithoutSaving)="exitWithoutSaving()"></app-exit-without-saving-confirmation-dialog>

    <!--Exit with messages confirmation dialog-->
    <p-dialog id="exit-with-warnings-confirmation-dialog" [(visible)]="designer.showExitWithMessagesDialog"
              [closable]="false" [resizable]="false" [modal]="true"
              [focusOnShow]="false" header="{{'OFFER.MENU.EXIT_WITH_MESSAGES' | translate}}">

        <div class="new-form-600">
            <app-sidebar-validation [pricingProducts]="designer.recentPricingProducts"
                                    [validationMessages]="designer.recentValidationMessages">
            </app-sidebar-validation>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                                   (onClick)="designer.confirmExitWithMessages()"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                                   (onClick)="designer.closeExitWithMessagesDialog()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>

    <app-addons-tab-view *ngIf="isAddonsTabViewVisible" [systemId]="designer.data.windowSystemId"
                         [offerId]="designer?.offerPosition?.offerId || offer?.id"
                         [offerPositionId]="designer?.offerPosition?.id"
                         [windowAddonData]="designer.data"
                         (onSubmit)="windowAddonsSave($event)" (onClose)="closeAddonsTabView()"
                         (onRemove)="windowAddonsRemove($event)"
                         [addedWindowAddons]="designer.addedWindowAddons"
                         [sidebarOnlyMode]="sidebarOnlyMode"
                         [readOnlyMode]="readOnlyMode" [staticData]="designer.staticData">
    </app-addons-tab-view>

    <!-- DEPRECATED - for ordered positions lookup only-->
    <app-configurable-addon-dialog *ngIf="configurableAddonDialogData != undefined && isViewingDeprecatedConfigAddon()"
                                   [dialogType]="dialogType"
                                   [offer]="offer"
                                   [currentActionPosition]="configurableAddonPosition"
                                   [showPriceSection]="false"
                                   [showDimensionSection]="false"
                                   [saveAddonOnFormSubmit]="false"
                                   [showQuantitySection]="false"
                                   [displayedDialogData]="configurableAddonDialogData"
                                   [subwindowTypes]="designer.subwindowTypes"
                                   [readOnlyMode]="true"
                                   (onClose)="onConfigurableAddonDialogClose()">
    </app-configurable-addon-dialog>

    <app-config-addons-list *ngIf="isConfigAddonsListVisible || isConfigSystemsListVisible" [configAddonModels]="designer.configurableAddonPositions"
                            [displayDeprecated]="isConfigAddonsListVisible"
                            [drawingData]="designer.data" [windowTabsData]="designer.windowTabsData"
                            [profileCompositionDistances]="designer.profileCompositionDistances" [readOnlyMode]="readOnlyMode"
                            (onClose)="closeConfigAddonsList()" (onEdit)="editConfigAddon($event)"
                            (beforeRemove)="removeConfigAddon()" (afterRemove)="redrawWindow()">
    </app-config-addons-list>

    <app-confirm-action-dialog *ngIf="showUpdateConfigurableAddonsQuantityDialog"
                               (onCancel)="hideUpdateConfigurableAddonsQuantityDialog()"
                               (onConfirmed)="updateConfigurableAddonsPositionQuantity()"
                               [question]="'OFFER.ACTIONS.DIALOGS.CHANGE_POSITION_QUANTITY.MESSAGE' | translate"
                               [header]="'OFFER.ACTIONS.DIALOGS.CHANGE_POSITION_QUANTITY.HEADER' | translate"
                               confirmButtonLabel="{{ 'GENERAL.YES' | translate }}"
                               discardButtonLabel="{{ 'GENERAL.NO' | translate }}"></app-confirm-action-dialog>

    <app-show-description *ngIf="showDescriptionDialog" [visible]="true"
                          [position]="designer.offerPosition" [readOnlyMode]="readOnlyMode"
                          [persistOnSave]="false"
                          (onClose)="descriptionDialogClosed()"></app-show-description>

    <app-window-component-preview-dialog
            [(previewData)]="windowComponentPreviewData"></app-window-component-preview-dialog>

    <app-missing-profit-margin-handler></app-missing-profit-margin-handler>

    <!--Details panel (sidebar)-->
    <div id="details-panel" *ngIf="sidebarInitialized" [ngClass]="{'hide-sidebar': !showSidebar }">
        <div id="details" [hidden]="!showSidebar">
            <p-tabView id="details-tabs" (onChange)="tabChanged($event)" #tabView [activeIndex]="designer.currentlySelectedTab">
                <!--All-->
                <p-tabPanel header="{{ 'OFFER.TABS.GENERAL' | translate}}" class="sidebar-entries" #mainTab
                            [headerStyleClass]="generalTabContainsErrors() ? 'errors-on-tab': ''" [selected]="shouldBeSelected(-1)">
                    <app-sidebar-general [offerPosition]="designer.offerPosition"
                                         [drawingData]="designer.data"
                                         [filteredWindowSystems]="filteredWindowSystems"
                                         [selectedWindowSystem]="designer.windowSystem"
                                         [sidebarOnlyMode]="sidebarOnlyMode"
                                         [modelMode]="modelMode"
                                         [webshopChargeMode]="dataModificationMode"
                                         [readOnlyMode]="readOnlyMode"
                                         [totalBoundingBox]="designer.totalBoundingBox"
                                         [profileCompositionDistances]="designer.profileCompositionDistances"
                                         [commonData]="designer.commonData"
                                         [chosenCoreColor]="chosenCoreColor"
                                         [colors]="allActiveColors"
                                         [systemRalExternalColors]="systemRalExternalColors"
                                         [systemRalInternalColors]="systemRalInternalColors"
                                         [systemRalBothSidesColors]="systemRalBothSidesColors"
                                         [systemNcsExternalColors]="systemNcsExternalColors"
                                         [systemNcsInternalColors]="systemNcsInternalColors"
                                         [systemNcsBothSidesColors]="systemNcsBothSidesColors"
                                         [intersectingGrillColors]="intersectingGrillColors"
                                         [intersectingGrillRalColors]="intersectingGrillRalColors"
                                         [intersectingGrillNcsColors]="intersectingGrillNcsColors"
                                         [filteredGlazingBeads]="filteredGlazingBeadsGeneralTab"
                                         [offerContainsGrills]="designer.offerContainsGrills"
                                         [fillingTypes]="designer.FillingType"
                                         [fillingTypesGlassOnly]="designer.FillingTypeGlassOnly"
                                         [fillingTypesWithoutDecorativeFilling]="designer.FillingTypeWithoutDecorativeFilling"
                                         [glazingGlassNumber]="designer.glazingGlassNumber"
                                         [allFillings]="systemOtherFillings"
                                         [systemDecorativeFillings]="systemDecorativeFillings"
                                         [frames]="frames"
                                         [glasses]="glasses"
                                         [availableGlassCounts]="filteredGlassCounts"
                                         [availableGlassCountsForDecor]="availableGlassCountsForDecor"
                                         [(windowSystemDefaultsLevel)]="windowSystemDefaultsLevel"
                                         [windowSystemDefaultsLevels]="windowSystemDefaultsLevels"
                                         [(windowSystemDefaultsOverrideLowerLevel)]="windowSystemDefaultsOverrideLowerLevel"
                                         [windowSystemDefaultsValidation]="windowSystemDefaultsValidation"
                                         [grillColors]="grillColors"
                                         [systemGlazingWidthItems]="systemGlazingWidthItems"
                                         [editModelMode]="editedWindowSystemId != undefined"
                                         [selectedWebshopGlazingPackageId]="selectedWebshopGlazingPackageId"
                                         [availableWebshopGlazingPackages]="availableWebshopGlazingPackages"
                                         [availableTerraceGlazingPackage]="availableTerraceGlazingPackage"
                                         [webshopCharge]="webshopCharge"
                                         [allGlazingPackages]="availableGlazingPackages"
                                         (onWindowSystemChange)="handleWindowSystemSelectionChange($event)"
                                         (onGlobalFillingTypeChange)="onGlobalFillingTypeChange($event, false)"
                                         (onSetAllFillingWidth)="onSetAllFillingWidth($event)"
                                         (onSetAllGlazingGlassQuantity)="onSetAllGlazingGlassQuantity($event)"
                                         (onSetWebshopGlazingPackage)="onSetWebshopGlazingPackage($event)"
                                         (onSetTerraceGlazingPackage)="onSetTerraceGlazingPackage($event)"
                                         (onSetGraspGlazingPackage)="onGlobalSetGlazingPackage($event)"
                                         (onSetGraspGlazingCategory)="onGlobalSetGlazingCategory($event)"
                                         (onSetGraspGlazingFrameCategory)="onGlobalSetGlazingFrameCategory($event)"
                                         (onSetGraspGlazingQuantity)="onGlobalSetGraspGlazingQuantity($event)"
                                         (onSetAllFillingId)="onSetAllFillingId($event)"
                                         (onSetAllDecorativeFillingId)="onSetAllDecorativeFillingId($event)"
                                         (onSetAllFillingCoreColor)="onSetAllFillingColor($event, FillingColorType.CORE)"
                                         (onSetAllFillingInternalColor)="onSetAllFillingColor($event, FillingColorType.INTERNAL)"
                                         (onSetAllFillingExternalColor)="onSetAllFillingColor($event, FillingColorType.EXTERNAL)"
                                         (onSetAllGlassType)="onSetAllGlassType($event)"
                                         (onSetAllFrames)="onSetAllFrameType($event)"
                                         (onGlobalGrillChange)="onGlobalGrillChange($event)"
                                         (onGlobalGrillColorChange)="onGlobalGrillColorChange($event)"
                                         (onGlobalMullionChange)="onGlobalMullionChange($event)"
                                         (onChangeTotalSize)="onChangeTotalSize($event)"
                                         (onChangeThresholdProfile)="onChangeConstructionalProfile($event, WindowEditorField.DOORSTEP)"
                                         (onChangeChannelSectionProfile)="onChangeConstructionalProfile($event, WindowEditorField.CHANNEL_SECTION)"
                                         (onChangeConstructionalMullionProfile)="onChangeConstructionalProfile($event, WindowEditorField.CONSTRUCTIONAL_MULLION)"
                                         (onChangeMovablePostProfile)="onChangeConstructionalProfile($event, WindowEditorField.MOVABLE_POST)"
                                         (onChangeFrameProfile)="onChangeConstructionalProfile($event, WindowEditorField.PROFILE)"
                                         (onSetDefaultValuesButtonClick)="onSetDefaultValuesButtonClick(false)"
                                         (onCancelModelSavingButtonClick)="onCancelModel()"
                                         (onCancelWebshopChargeSavingButtonClick)="onCancelWebshopCharge()"
                                         (showAddonsTabViewEvent)="showAddonsTabView()"
                                         (showConfigAddonsListEvent)="showConfigAddonsList()"
                                         (onGlobalFillingChange)="requiredFieldsOnSubWindowsFilled()"
                                         (onGlobalGlazingBeadChange)="onGlobalGlazingBeadChange($event)"
                                         (onSetWebshopChargeNames)="onSetWebshopChargeName($event)"
                                         (errorsOnMainChange)="handleError($event, SidebarSection.MAIN)"
                                         (errorsOnFillingChange)="handleError($event, SidebarSection.FILLING)"
                                         (errorsOnColorChange)="handleError($event, SidebarSection.COLOR)"
                                         (errorsOnFittingsChange)="handleError($event, SidebarSection.FITTINGS)"
                                         (errorsOnMullionsChange)="handleError($event, SidebarSection.MULLIONS)"
                                         (errorsOnMuntinsChange)="handleError($event, SidebarSection.MUNTINS)"
                                         (generalTabInitialized)="onGeneralTabInitialized($event)"
                                         (onBeforeGeneralTabValueChange)="onBeforeSidebarValueChange()"
                                         (onGeneralTabValueChange)="onSidebarValueChange($event)"
                                         (onQuantityChange)="onSidebarQuantityChange()"
                                         (onShowDescriptionDialog)="onShowDescriptionDialog()"
                                         (onCoreColorChange)="handleOnCoreColorChange($event)"
                                         (onShowImage)="windowComponentPreviewData = $event"
                                         [(isSavingHistory)]="designer.isSavingHistory">
                    </app-sidebar-general>
                </p-tabPanel>

                <!--Each subWindow settings-->
                <ng-container *ngIf="!isTerrace">
                    <p-tabPanel *ngFor="let windowTab of designer.windowTabsData; trackBy: windowTabTrackBy; let i = index;" class="sidebar-entries"
                                [header]="getSubWindowName(windowTab.windowData)" [selected]="shouldBeSelected(i)"
                                [headerStyleClass]="getSubwindowErrorStyle(i)">
                        <!--Each area in subWindow-->
                        <app-sidebar-subwindow [windowData]="windowTab.windowData" [tabIndex]="i"
                                               [subwindowName]="getSubWindowName(windowTab.windowData)"
                                               [drawingData]="designer.data"
                                               [readOnlyMode]="readOnlyMode"
                                               [sidebarOnlyMode]="sidebarOnlyMode"
                                               [profileCompositionDistances]="designer.profileCompositionDistances"
                                               [filteredGlazingBeads]="filteredGlazingBeadsPerSubwindow[windowTab.windowData.generatedId] || {}"
                                               [fillingTypes]="designer.FillingType"
                                               [fillingTypesGlassOnly]="designer.FillingTypeGlassOnly"
                                               [fillingTypesWithoutDecorativeFilling]="designer.FillingTypeWithoutDecorativeFilling"
                                               [allFillings]="systemOtherFillings"
                                               [systemDecorativeFillings]="systemDecorativeFillings"
                                               [grillColors]="grillColors"
                                               [grillRalColors]="grillRalColors"
                                               [grillNcsColors]="grillNcsColors"
                                               [glazingGlassQuantities]="designer.glazingGlassNumber"
                                               [frames]="frames"
                                               [grills]="grills"
                                               [angledGrills]="angledGrills"
                                               [mullions]="systemMullions"
                                               [glasses]="glasses"
                                               [availableGlassCounts]="filteredGlassCounts"
                                               [availableGlassCountsForDecor]="availableGlassCountsForDecor"
                                               [windowSystemsForVeneer]="windowSystemsForVeneer"
                                               [windowSystem]="designer.windowSystem"
                                               [availableVentilators]="allAddons.availableVentilators"
                                               [availableDrips]="allAddons.availableDrips"
                                               [availableCouplers]="allAddons.availableCouplers"
                                               [systemGlazingWidthItems]="systemGlazingWidthItems"
                                               [allGlazingPackages]="availableGlazingPackages"
                                               (openBusinessTypeChangeDialog)="openBusinessTypeChangeDialog(windowTab.windowData)"
                                               (veneerEvent)="veneerChangeEvent($event)"
                                               (onFrameChange)="onSubwindowFrameChange($event)"
                                               (onGlassQuantityChange)="onSingleGlassQuantityChange($event)"
                                               (onSingleGlassChange)="onSingleGlassChange($event)"
                                               (onFillingTypeChange)="onFillingTypeChange($event.fillingType, $event.where)"
                                               (onGrillChange)="onGrillChange($event, windowTab.windowData)"
                                               (onMullionChange)="onMullionChange($event, windowTab.windowData)"
                                               (onVentilatorChange)="onSubWindowVentilatorChange($event, windowTab.windowData)"
                                               (onDripChange)="onSubWindowDripChange($event, windowTab.windowData)"
                                               (onCouplerChange)="onSubWindowCouplerChange($event, windowTab.windowData)"
                                               (onGlobalFillingChange)="onSubwindowFillingChange($event, windowTab.windowData)"
                                               (onFillingWidthChange)="onSubwindowFillingWidthChange()"
                                               (redrawDesigner)="redrawWindow()"
                                               (onBeforeGeneralSubWindowValueChange)="onBeforeSubWindowValueChange()"
                                               (onGeneralSubWindowValueChange)="onSubWindowValueChange()"
                                               (onShowImage)="windowComponentPreviewData = $event"
                                               (onGlazingQuantityChange)="subwindowGlazingQuantityChange($event)"
                                               (onGlazingCategoryChange)="subwindowGlazingCategoryChange($event)"
                                               (onGlazingFrameCategoryChange)="subwindowGlazingFrameCategoryChange($event)"
                                               (onGlazingPackageChange)="subwindowGlazingPackageChange($event)"
                                               (fieldChanged)="checkItemsAvailability($event)">
                        </app-sidebar-subwindow>
                    </p-tabPanel>
                </ng-container>

                <!--Pricing-->
                <p-tabPanel *ngIf="!sidebarOnlyMode && designer.windowTabsData.length > 0"
                            header="{{'OFFER.TABS.PRICING.TITLE' | translate}}"
                            [headerStyleClass]="getTabpanelHeaderStyle(designer.pricingStatus) + ' pricingTab'">
                    <app-pricing #pricing
                                 [pricing]="designer.pricing" [offer]="offer" [productionOrder]="productionOrder"
                                 (refreshValidationPricingStatuses)="designer.updateValidationPricingStatuses($event, false)"
                                 (setErrorToPricingStatus)="setErrorToPricingStatus()" [drawingData]="designer.data"
                                 [windowSystem]="designer.getWindowSystem()"
                                 [colors]="allActiveColors" [internalSeals]="systemInternalSeals"
                                 [externalSeals]="systemExternalSeals" [fillings]="systemOtherFillings"
                                 [profiles]="allActiveProfiles" [glasses]="glasses" [distanceFrames]="frames"
                                 [grills]="grills" [addons]="allAddons.ungrouped"
                                 [glazingBeads]="availableGlazingBeads" [readOnlyMode]="readOnlyMode"
                                 [windowSystemTypeGroup]="windowSystemTypeGroup"></app-pricing>
                </p-tabPanel>

                <!--Validation-->
                <p-tabPanel *ngIf="!sidebarOnlyMode && designer.windowTabsData.length > 0"
                            header="{{'OFFER.TABS.VALIDATION.TITLE' | translate}}"
                            [headerStyleClass]="getTabpanelHeaderStyle(designer.validationStatus)">
                    <app-sidebar-validation #validation  [productionOrder]="productionOrder"
                                            [pricingProducts]="designer.recentPricingProducts" [readOnlyMode]="readOnlyMode"
                                            [validationMessages]="designer.recentValidationMessages"
                                            (refreshValidationPricingStatuses)="designer.updateValidationPricingStatuses($event, true)"
                                            (setErrorToValidationStatus)="setErrorToValidationStatus()">
                    </app-sidebar-validation>
                </p-tabPanel>
            </p-tabView>
        </div>
        <div class="spacer" *ngIf="!showSidebar"></div>
        <app-sidebar-hider [showSidebar]="showSidebar"
                           [sidebarOnlyMode]="sidebarOnlyMode"
                           (onClick)="showSidebar = !showSidebar"></app-sidebar-hider>
    </div>
</div>
