<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.SCHEDULED_JOB.TITLE' | translate }}</div>
    </div>
    <p-dataTable [value]="jobs" [paginator]="false" [lazy]="true" selectionMode="single" [responsive]="true"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate">

        <!-- JOB TYPE -->
        <p-column field="jobType" header="{{ 'ADMIN_PANEL.SCHEDULED_JOB.FORM.JOB_TYPE' | translate }}"
                  [sortable]="false" [filter]="false">

            <ng-template let-job="rowData" pTemplate="body">
                {{('ADMIN_PANEL.SCHEDULED_JOB.TYPE.' + job.jobType) | translate}}
            </ng-template>
        </p-column>

        <!-- JOB EXPIRED WARNING -->
        <p-column [style]="{'vertical-align':'middle'}">
            <ng-template let-job="rowData" pTemplate="body">
                    <span class="flex-row">
                        <i *ngIf="isJobExpired(job)" class="material-icons"
                           title="{{'ADMIN_PANEL.SCHEDULED_JOB.FORM.JOB_EXPIRED' | translate}}">report_problem</i>
                    </span>
            </ng-template>
        </p-column>

        <!-- START DATE -->
        <p-column field="startDate" header="{{ 'ADMIN_PANEL.SCHEDULED_JOB.FORM.START_DATE' | translate }}"
                  [sortable]="false" [filter]="false">
            <ng-template let-job="rowData" pTemplate="body">
                {{ job.startDate | momentCurrentLang | amDateFormat: 'L LTS' }}
            </ng-template>
        </p-column>

        <!-- LAST SUCCESS DATE -->
        <p-column field="lastSuccessDate" header="{{ 'ADMIN_PANEL.SCHEDULED_JOB.FORM.LAST_SUCCESS_DATE' | translate }}"
                  [sortable]="false" [filter]="false">
            <ng-template let-job="rowData" pTemplate="body">
                {{ job.lastSuccessDate | momentCurrentLang | amDateFormat: 'L LTS' }}
            </ng-template>
        </p-column>

        <!-- LAST FAILURE DATE -->
        <p-column field="lastFailureDate" header="{{ 'ADMIN_PANEL.SCHEDULED_JOB.FORM.LAST_FAILURE_DATE' | translate }}"
                  [sortable]="false" [filter]="false">
            <ng-template let-job="rowData" pTemplate="body">
                {{ job.lastFailureDate | momentCurrentLang | amDateFormat: 'L LTS' }}
            </ng-template>
        </p-column>
    </p-dataTable>
</div>
