import {HttpErrorResponse} from "@angular/common/http";
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import * as _ from "underscore";
import {MenuType} from "../../../common/button-with-menu/MenuType";
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {KeepSelectedItemEventParams} from '../../../common/crud-common/paginable.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {SupportedLanguages} from "../../../supportedLanguages";
import {News} from './news';
import {NewsColourClassPipe} from "./news-colour-class.pipe";
import {NewsService} from './news-service';
import {NewsValidator} from "./NewsValidator";
import {NewsViewMode} from "./newsViewMode";

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.css'],
    providers: [NewsService, TranslatedSelectItemService, NewsColourClassPipe],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewsComponent extends CrudCommonComponent<News, NewsService> implements OnInit, OnDestroy {

    readonly supportedLanguages = SupportedLanguages.languages;
    readonly MAX_ROWS_WITHOUT_PAGINATION = 2000;
    menuType = MenuType;
    displayConfirmDeleteDialog = false;
    saveAsNew = false;
    futureNews: News[];
    currentNews: News;
    newsToCopy: News;

    @ViewChild('dt', {static: true})
    table: Table;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private newsColourClass: NewsColourClassPipe) {
        super(injector, changeDetector, false, NewsService, 'NEWS', 'NewsComponent');
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loadItemsLazy({
            first: 0,
            rows: this.chosenRowsPerPage,
            filters: {},
            sortField: 'validFrom',
            sortOrder: 0
        });
    }

    onRowSelect(event: { data: any } & KeepSelectedItemEventParams): void {
        this.keepSelectedItemIndex(event);
        this.itemService.getItem(this.selectedItem.id, NewsViewMode.FULL).subscribe(news => {
            if (this.saveAsNew) {
                this.copyMode = true;
                this.newsToCopy = News.fromJSON(news);
            }
            this.setEditedItem(news);
        });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    protected afterSuccessLoad() {
        super.afterSuccessLoad();
        if (this.itemList.length === 0) {
            this.setEditedItem(this.getNewItem());
            return;
        }
        if (this.newsToCopy !== undefined) {
            this.selectedItem = this.newsToCopy;
            this.newsToCopy = undefined;
        }
        this.itemService.getItem(this.selectedItem.id, NewsViewMode.FULL).subscribe(selectedNews => {
            this.setEditedItem(selectedNews);
        });
        this.futureNews = this.itemList.filter(news => this.newsColourClass.transform(news) === 'published-planned');
        this.currentNews = this.itemList.filter(news => this.newsColourClass.transform(news) === 'published-active')[0];
    }

    getNewItem(): News {
        return new News();
    }

    submit() {
    }

    handleNewsUpdate(savedNews: News) {
        if (this.copyMode) {
            this.newsToCopy = savedNews;
        }
        this.cleanUpAndReload();
    }

    handleNewsCopied(success: boolean) {
        if (!success) {
            this.saveAsNew = false;
            this.copyMode = false;
            this.setEditedItem(this.newsToCopy);
        }
    }

    private setEditedItem(selectedNews: News) {
        this.item = selectedNews;
        if (this.saveAsNew) {
            this.item.published = false;
            const copyLabel = this.translate.instant('NEWS.COPY_LABEL');
            for (let lang of this.supportedLanguages) {
                let newTitle = copyLabel + this.item.title[lang.code];
                if (NewsValidator.validateTitle(newTitle) == undefined) {
                    this.item.title[lang.code] = newTitle;
                }
            }
        }
        this.dialogHideHelper.reset();
        this.changeDetector.markForCheck();
    }

    actionOnClick(action: string, news: News) {
        console.debug("NewsComponent: clicked action '" + action + "' for news: " + news.id);
        switch (action) {
            case 'COPY_NEWS':
                this.copyNews();
                break;
            case 'DELETE_NEWS':
                this.displayConfirmDeleteDialog = true;
                this.changeDetector.markForCheck();
                break;
            default:
                console.error("NewsComponent: action '" + news + "' is unknown.");
                break;
        }
    }

    private copyNews() {
        this.saveAsNew = true;
    }

    deleteNews(news: News) {
        this.itemService.deleteNews(news.id).subscribe({
            next: () => {
                this.hideConfirmDeleteDialog();
                this.showActionSuccessMessage('DELETE');
                this.reloadDatatable();
            },
            error: error => {
                this.hideConfirmDeleteDialog();
                this.showErrorMessage(error);
                this.reloadDatatable();
            }
        });
    }

    showActionSuccessMessage(actionName: string) {
        this.growlMessageController.info('NEWS.ACTIONS.ON_SUCCESS.' + actionName);
    }

    private showErrorMessage(errorResponse: HttpErrorResponse) {
        let errors = _.values(errorResponse.error.invalidFields);
        for (let error of errors) {
            this.growlMessageController.error(error);
        }
    }

    hideConfirmDeleteDialog(): void {
        this.displayConfirmDeleteDialog = false;
        this.changeDetector.markForCheck();
    }
}
