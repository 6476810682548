import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {ValidationErrors} from "../validation-errors";
import {SelectItem} from "primeng/api/selectitem";
import {Listbox} from "primeng/listbox";

export class ListboxSelectionItem {
    id: number;
    labels: string[];

    constructor(id: number, labels: string[]) {
        this.id = id;
        this.labels = labels;
    }
}

@Component({
    selector: 'app-listbox-selection',
    templateUrl: './listbox-selection.component.html',
    styleUrls: ['./listbox-selection.component.css']
})
export class ListboxSelectionComponent implements OnChanges {

    @Input() validationErrors: ValidationErrors;
    @Input() item: ListboxSelectionItem;
    @Input() options: SelectItem[];
    @Input() disabled: boolean;
    @Input() columnsCount = 1;

    @Output() onDoubleClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() onItemChange = new EventEmitter<ListboxSelectionItem>();

    @ViewChild('listFilterRef')
    listFilter: ElementRef;

    @ViewChild('listRef')
    list: Listbox;

    array = Array;
    math = Math;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['options'] && this.list) {
            this.list.options = changes['options'].currentValue;
            this.list.activateFilter();
        }
    }

    handleDoubleClick() {
        this.onDoubleClick.emit();
    }
}
