import {Pipe, PipeTransform} from '@angular/core';
import {SelectDialogTile} from "../../config-editor/config-sidebar/tile-select-dialog/select-dialog-tile";

@Pipe({
    name: 'isTileNotAvailable'
})
export class IsTileNotAvailablePipe implements PipeTransform {

    transform(selectedId: number, items: SelectDialogTile[], canBeEmpty = false): boolean {
        if (items && items.length > 0) {
            if (selectedId) {
                const filteredItems = items.filter(item => item.tileValue === selectedId);
                return filteredItems.length > 0 ? !filteredItems[0].available : !canBeEmpty;
            } else {
                return !canBeEmpty;
            }
        }
        return false;
    }
}
