<div class="new-form-60vw upselling-settings-dialog-height">
    <div class="new-form-row">
        <app-input-text inputId="name" [maxlength]="50"
                        label="{{'OFFER.POSITIONS.ADDON_CONFIG.FORM.NAME' | translate}}"
                        [(ngModel)]="upsellingSettings.name"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="selectedConfigAddon"
                    label="{{ 'OFFER.POSITIONS.ADDON_CONFIG.FORM.DEFINITION' | translate }}"
                    [(validationMessageKey)]="validationErrors['configurableAddonDefinitionId']"
                    [options]="configSystems" [optionFormatter]="configSystemFormatter"
                    [ngModel]="upsellingSettings.configSystemId"
                    (ngModelChange)="handleConfigurableAddonDefinitionChange($event)"></app-select>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="enableUpselling"
                      label="{{ 'ADMIN_PANEL.UPSELLING_SETTINGS.FORM.ENABLE_UPSELLING' | translate }}"
                      [(ngModel)]="upsellingSettings.active"></app-checkbox>
    </div>
    <div class="new-form-row">
        <h3>{{ "ADMIN_PANEL.UPSELLING_SETTINGS.DEFAULTS_INFO" | translate }}</h3>
    </div>
    <div *ngIf="validationErrors['configAddonDefaluts'] != undefined" class="new-form-row">
        <div class="new-form-field-error-message">{{ validationErrors['configAddonDefaluts'] | translate }}</div>
    </div>
</div>
