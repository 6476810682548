import {ConfigurableAddon} from '../../../../../../../window-designer/entities/ConfigurableAddon';
import {Position} from "../position";
import {DialogType} from "../position-list-dialogs";
import {MultilanguageField} from "../../../../../../supportedLanguages";
import {CategoryWithAutoOptions} from "../../../../config-editor/config-editor.component";

export class ConfigurableAddonDialogEventModel {

    private readonly actionName: DialogType;
    private readonly position: Position;
    private readonly configurableAddon: ConfigurableAddon;
    private readonly configurableAddonName: MultilanguageField;
    private readonly categoriesWithAutoOptions: CategoryWithAutoOptions[];

    constructor(actionName: DialogType, position: Position, configurableAddon: ConfigurableAddon,
                configurableAddonName: MultilanguageField, categoriesWithAutoOptions?: CategoryWithAutoOptions[]) {
        this.actionName = actionName;
        this.position = position;
        this.configurableAddon = JSON.parse(JSON.stringify(configurableAddon));
        this.configurableAddonName = configurableAddonName;
        this.categoriesWithAutoOptions = categoriesWithAutoOptions;
    }

    public getActionName(): DialogType {
        return this.actionName;
    }

    public getPosition(): Position {
        return this.position;
    }

    public getConfigurableAddon(): ConfigurableAddon {
        return this.configurableAddon;
    }

    public getConfigurableAddonName(): MultilanguageField {
        return this.configurableAddonName;
    }

    public getCategoriesWithAutoOptions(): CategoryWithAutoOptions[] {
        return this.categoriesWithAutoOptions;
    }
}
