import {MultilanguageField} from '../../../supportedLanguages';
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';

export class WindowSystemModel {
    id: number;
    sortIndex: number;
    active: boolean;
    windowSystemId: number;
    windowSystemName: MultilanguageField;
    value: WindowSystemDefaults;

    constructor() {
        this.windowSystemName = new MultilanguageField();
        this.sortIndex = 1;
    }
}
