import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    Optional,
    QueryList,
    Renderer2,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/api/selectitem';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';

export const RADIO_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RadioButtonComponent),
    multi: true
};

@Component({
    selector: 'app-radio-button',
    templateUrl: './radio-button.component.html',
    styleUrls: ['./radio-button.component.css'],
    providers: [RADIO_VALUE_ACCESSOR]
})
export class RadioButtonComponent extends AbstractInputComponent implements OnChanges {

    @Input()
    options: SelectItem[];

    @Input()
    required: boolean;

    @Input()
    translateLabels: boolean;

    @Input()
    horizontal = true;

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    @ViewChildren('input')
    inputElements: QueryList<ElementRef>;

    @ViewChild('defaultOptionLabel', {read: TemplateRef, static: true})
    defaultOptionLabel: TemplateRef<any>;

    optionLabelTemplate: TemplateRef<any>;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }

    protected resetTemplatesToDefault(): void {
        this.optionLabelTemplate = this.defaultOptionLabel;
    }

    protected registerTemplate(type: string, template: TemplateRef<any>): void {
        if (type === 'option-label') {
            this.optionLabelTemplate = template;
        }
    }

    writeValue(obj: any): void {
        let selectedIndex = this.options.findIndex(option => option.value === obj);
        this.updateElement(selectedIndex);
        super.writeValue(obj);
    }

    handleChange(index: number): void {
        if (this.disabled) {
            return;
        }
        this.updateElement(index);
        this.value = this.options[index].value;
    }

    private updateElement(selectedIndex: number): void {
        if (this.inputElements != undefined) {
            this.inputElements.forEach((inputElement, index) => {
                this.renderer.setProperty(inputElement.nativeElement, 'checked', index === selectedIndex);
            });
        }
    }
}
