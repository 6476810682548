<app-add-product-dialog [displayDialog]="displayDialog"
                        [tableHeader]="tableHeader"

                        [tabItems]="tabItems"
                        [activeTabItem]="activeTabItem"

                        [showDropdown]="showDropdown"
                        [dropdownSelectLabel]="dropdownSelectLabel"
                        [dropdownSelectOptions]="dropdownSelectOptions"
                        [selectedDropdownSelectOption]="selectedDropdownSelectOption"
                        (onDropdownSelectionChanged)="handleDropdownChange($event)"

                        [tableTrackBy]="trackBy"
                        [tableOptions]="tableOptions"
                        [tableOptionNames]="tableOptionNames"
                        [tableOptionIcons]="tableOptionIcons"
                        [tableSelection]="selectedTableOption"
                        [tableOptionDescriptions]="tableOptionDescriptions"
                        (onTableSelectionChanged)="handleValueChange($event)"

                        [selectedValueDescription]="selectedValueDescription"
                        [selectedValueImage]="selectedValueImage"
                        [largeImageGetter]="largeImageGetter"
                        [largeImageId]="largeImageId"
                        [hasSlider]="hasSlider"

                        [submitButtonDisabled]="items?.length === 0"
                        [submitButtonLabel]="submitButtonLabel"
                        (dialogClosed)="handleDialogClosed($event)"
                        (openStorageFile)="openStorageFile.emit($event)">
</app-add-product-dialog>
