import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {forkJoin, Observable, of} from "rxjs";
import {map, mergeMap, tap} from 'rxjs/operators';
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {KeepSelectedItemEventParams} from '../../../common/crud-common/paginable.component';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {MultilanguageField} from "../../../supportedLanguages";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, EntranceGlazingPackageField} from "../../admin-panel/edit-catalog-permits/catalog-field.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ListOfIds} from "../../ListOfIds";
import {EntranceGlazingPackageFieldUsage} from "../catalog-field-usage";
import {EntranceModelService} from "../entrance-model/entrance-model.service";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {EntranceGlazingPackage} from "./entrance-glazing-package";
import {EntranceGlazingPackageService} from "./entrance-glazing-package.service";

@Component({
    selector: 'app-entrance-glazing-package',
    templateUrl: './entrance-glazing-package.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [EntranceGlazingPackageService, DataServiceHelper, TranslatedSelectItemService, EntranceModelService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceGlazingPackageComponent extends CrudCommonComponent<EntranceGlazingPackage, EntranceGlazingPackageService>
    implements OnInit, AfterViewInit {

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState = true;

    readonly STEPS = {
        DATA: 'DATA',
        MODELS: 'MODELS'
    };

    @ViewChild('table')
    table: Table;

    validateDataStep: WizardStepValidator;

    public models: ItemForCatalogLinking[];
    public selectedModels: number[];

    editPermits: FieldLimitation[] = [];
    fieldUsage: EntranceGlazingPackageFieldUsage;
    CatalogTab = CatalogTab;
    EntranceGlazingPackageField = EntranceGlazingPackageField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private entranceModelService: EntranceModelService,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, EntranceGlazingPackageService, 'ENTRANCE_GLAZING_PACKAGE', 'EntranceGlazingPackage');
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
        this.fieldUsage = new EntranceGlazingPackageFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.entranceModelService.getModelsForCatalogLinking().subscribe(models => {
            this.models = models;
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.ENTRANCE_GLAZING_PACKAGES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
        if (this.table != undefined) {
            this.table.filter(true, 'active', 'equals');
        }
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    showDialogToAdd() {
        this.validationErrors = {};
        this.prepareDataForItem(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.prepareDataForItem(this.selectedItem.id);
        }
    }

    onRowSelect(event: { data: EntranceGlazingPackage } & KeepSelectedItemEventParams): void {
        this.validationErrors = {};
        this.prepareDataForItem(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    loadEditedItem(event: { data: EntranceGlazingPackage }): Observable<EntranceGlazingPackage> {
        return forkJoin({
            item: this.itemService.getItem(event.data.id),
            image: this.itemService.getImageAsFile(event.data.id)
        }).pipe(
            tap(data => {
                if (this.copyMode) {
                    data.item.id = undefined;
                }
                this.file = data.image;
            }),
            map(data => data.item)
        );
    }

    submit() {
        this.validateForm().subscribe(validateOk => {
            if (!validateOk) {
                return;
            }
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file);
            } else {
                observable = this.itemService.saveItem(this.item, this.file);
            }

            observable = observable.pipe(mergeMap(modelId => {
                const ids = new ListOfIds();
                ids.ids = this.selectedModels;
                return this.itemService.editLinks(modelId, ids);
            }));

            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');

        this.validationErrors[`name[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.entranceGlazingPackageDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]);

        this.validationErrors[`shortcut[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.entranceGlazingPackageDto.shortcut[${this.translate.currentLang}]`)
                .withSizeValidator(0, 100)
                .validate(this.item.shortcut[this.translate.currentLang]);

        this.validationErrors['symbol'] = MultiValidator.of('error.entranceGlazingPackageDto.symbol')
            .withNotNullValidator()
            .withNotBlankValidator()
            .withSizeValidator(0, 100)
            .validate(this.item.symbol);

        this.validationErrors['glassesQuantity'] = MultiValidator.of('error.entranceGlazingPackageDto.glassesQuantity')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 9)
            .validate(this.item.glassesQuantity);

        this.validationErrors['sortIndex'] = MultiValidator.of('error.entranceGlazingPackageDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999).validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));

    }

    getNewItem(): EntranceGlazingPackage {
        return new EntranceGlazingPackage();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }

    prepareDataForItem(glazingPackageId: number) {
        forkJoin({
            glazingPackage: (glazingPackageId !== null ? this.itemService.getItem(glazingPackageId) : of(new EntranceGlazingPackage())),
            linkedModels: (glazingPackageId !== null ? this.itemService.getLinkedModels(glazingPackageId) : of<number[]>([]))
        }).subscribe({
            next: data => {
                this.newItem = glazingPackageId === null;
                this.item = data.glazingPackage;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    this.item.shortcut = new MultilanguageField();
                    this.item.sortIndex = 1;
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.selectedModels = data.linkedModels;
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }
}
