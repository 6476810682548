import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {SelectItem} from "primeng/api/selectitem";
import {Table} from "primeng/table";
import {Observable, of} from "rxjs";
import {finalize, map} from "rxjs/operators";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {TableToDatatableInterfaceAdapter} from "../../../common/DatatableHelper";
import {CrudComponent} from "../../../common/service/crud.component";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {CSVColumn, CSVDataSource, ExportComponent} from "../../../common/service/export.component";
import {Offer} from "../../offer/offer";
import {SubsystemNotesService} from "../../subsystem/subsystem-notes/subsystem-notes.service";
import {SubsystemService} from "../../subsystem/subsystem.service";
import {SubsystemNotesCounterDataRow} from "./subsystem-notes-counter-data-row";

@Component({
    selector: 'app-subsystem-notes-counter',
    templateUrl: './subsystem-notes-counter.component.html',
    styleUrls: ['../../shared-styles.css', './subsystem-notes-counter.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ExportComponent, SubsystemService, SubsystemNotesService]
})
export class SubsystemNotesCounterComponent extends CrudComponent implements OnInit, CSVDataSource {

    private static readonly FIRST_YEAR_OF_NOTES = 2021;

    @ViewChild('dt', {static: true}) datatable: Table;

    years: string[] = ["last90Days"];
    showFilters = false;
    availableSubsystems: SelectItem[] = [];
    selectedSubsystems: string[] = [];
    items: SubsystemNotesCounterDataRow[];
    selectedItem: SubsystemNotesCounterDataRow;
    lastLoadEvent: LazyLoadEvent;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private subsystemService: SubsystemService,
                private subsystemNotesService: SubsystemNotesService,
                private errors: CommonErrorHandler) {
        super(injector, changeDetector, 'SubsystemNotesCounterComponent', false);
    }

    ngOnInit(): void {
        this.prepareYears();
        this.subsystemService.getSelectionItems().subscribe(subsystems => {
            this.availableSubsystems = subsystems.sort((a, b) => a.label.localeCompare(b.label));
        });
        this.createTable();
    }

    private prepareYears() {
        let year = new Date().getFullYear();
        while (year >= SubsystemNotesCounterComponent.FIRST_YEAR_OF_NOTES) {
            this.years.push("" + year--);
        }
    }

    private createTable() {
        let shownColumns = this.getShownColumns();
        let builder = DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('subsystemName', 'subsystemName', true)
            .add('login', 'login', true);
        this.years.forEach(year => builder.add(year, year, true).makeSortable());
        super.init(builder.build());
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.datatable.filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    rowTrackById(index: number, item: Offer) {
        return item.id;
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalTableFilters = true): void {
        super.loadItemsLazy(event);
        this.lastLoadEvent = event;
        this.subsystemNotesService.getNotesCounter(event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.items = data;
                },
                error: error => {
                    console.error('OffersComponent `getPage` error:', error);
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('OffersComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    getDatatable(): TableToDatatableInterfaceAdapter {
        return TableToDatatableInterfaceAdapter.create(this.datatable);
    }

    onRowSelect(event) {
    }

    showDialogToAdd() {
    }

    submit() {
    }

    // export to csv methods
    getExportData(): Observable<object[]> {
        let exportData = [];
        for (let item of this.items) {
            let obj = {};
            this.years.forEach(year => obj[year] = item.notesCountByYear[year]);
            exportData.push({
                subsystemName: item.subsystemName,
                login: item.login,
                ...obj
            });
        }
        return of(exportData);
    }

    getExportColumns(): Observable<CSVColumn[]> {
        return this.translate.get([
            'OFFER.FORM.SUBSYSTEM', 'USER-DETAILS.FORM.LOGIN', ...this.years.map(y => `${y}`)
        ]).pipe(map(translations => [
            {
                label: translations['OFFER.FORM.SUBSYSTEM'],
                field: 'subsystemName'
            }, {
                label: translations['USER-DETAILS.FORM.LOGIN'],
                field: 'login'
            },
            ...this.years.map(year => ({
                label: translations[`${year}`],
                field: `${year}`
            }))
        ]));
    }
}
