import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractSellDealerDiscountService} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.service';
import {SubsystemGroupSupplierSellDealerDiscount} from './subsystem-group-supplier-sell-dealer-discount';

@Injectable()
export class SubsystemGroupSupplierSellDealerDiscountService
    extends AbstractSellDealerDiscountService<SubsystemGroupSupplierSellDealerDiscount> {

    constructor(http: HttpClient) {
        super(http, SubsystemGroupSupplierSellDealerDiscount);
    }

    getApiBaseUrl(): string {
        return 'sellDealerDiscount/subsystemGroup/supplier';
    }

    addSearchParams(params: { [param: string]: string | string[] }, id: number, groupId: number): void {
        if (id != undefined) {
            params['subsystemGroupId'] = '' + id;
        }
    }
}
