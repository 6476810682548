<div class="profit-margin-container" [ngClass]="{'editable': userCanEdit, 'not-editable': !userCanEdit}">
    <!--Toolbar-->
    <div *ngIf="userCanEdit" class="table-header">
        <div class="table-header-buttons">
            <div class="flex-row">
                <div class="profit-margin-global-input-container">
                    <app-input-text [inputId]="globalMarginUpdateInputId"
                                    [(validationMessageKey)]="validationErrors['globalMarginUpdateInput']"
                                    [(ngModel)]="globalProfitMargin"
                                    (keydown.enter)="applyGlobalProfitMargin($event)">
                        <ng-template pTemplate="label"></ng-template>
                    </app-input-text>
                </div>
                <app-simple-button [buttonId]="globalMarginUpdateButtonId"
                                   label="{{ 'PROFIT_MARGIN.FORM.SET_ALL_VALUES' | translate }}" icon="beenhere"
                                   type="action" [size]="40" (onClick)="applyGlobalProfitMargin($event)"
                                   (keydown.enter)="applyGlobalProfitMargin($event)"></app-simple-button>
                <app-checkbox *ngIf="entityType === 'subsystemGroup' && subsystemGroupId != undefined"
                              inputId="overrideMargins"
                              label="{{ 'PROFIT_MARGIN.FORM.OVERRIDE_MARGIN_IN_SUBSYSTEMS' | translate }}"
                              [(ngModel)]="pushToGroupMembers"></app-checkbox>
                <app-checkbox *ngIf="entityType === 'clientGroup' && clientGroupId != undefined"
                              inputId="overrideMargins"
                              label="{{ 'PROFIT_MARGIN.FORM.OVERRIDE_MARGIN_IN_CLIENTS' | translate }}"
                              [(ngModel)]="pushToGroupMembers"></app-checkbox>
            </div>
        </div>
    </div>

    <!--Table-->
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [editable]="true" [responsive]="true" [(selection)]="selectedItem"
                 (onEditComplete)="editProfitMargin($event)" *ngIf="showTable" (onEditInit)="beforeEdit($event)"
                 (onEditExit)="editProfitMargin()"
                 (onPage)="setPages($event)" (onFilter)="updateTotalPageCount()">
        <p-column field="systemName.{{userLang}}" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.NAME' | translate }}"
                  [sortable]="true" [editable]="false" [filter]="true" filterMatchMode="contains"></p-column>
        <p-column *ngIf="possibleTargets.SYSTEM == target" field="windowSystemMaterial" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MATERIAL' | translate }}"
                  [sortable]="false" [filter]="true" [filterValues]="filterMaterial | async" filterMatchMode="endsWith"
                  [editable]="false">
            <ng-template let-item="rowData" pTemplate="body">
                {{ 'MATERIAL.' + item.windowSystemMaterial | translate }}
            </ng-template>
        </p-column>
        <p-column field="supplierName.{{userLang}}" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SUPPLIER' | translate }}"
                  [sortable]="true" [filter]="true" [editable]="false">
            <ng-template pTemplate="filter">
                <div class="app-filter-multi-select">
                    <p-multiSelect [options]="filterSupplier"
                                   defaultLabel="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SUPPLIER' | translate }}"
                                   appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedSuppliers"
                                   (ngModelChange)="handleSupplierFilterChange($event)"></p-multiSelect>
                </div>
            </ng-template>
        </p-column>
        <p-column field="groupValue" header="{{ 'PROFIT_MARGIN.FORM.GROUP_PROFIT_MARGIN' | translate }}"
                  [editable]="false" *ngIf="entityType === 'subsystem' || entityType === 'client'" [style]="{'width':'15%'}">
            <ng-template pTemplate="body" let-item="rowData">
                <span [ngStyle]="{ 'text-decoration': item.valueString != undefined ? 'line-through' : 'none' }">{{ item.groupValue }}</span>
            </ng-template>
        </p-column>
        <p-column field="valueString" header="{{ 'PROFIT_MARGIN.FORM.PROFIT_MARGIN' | translate }}" [editable]="userCanEdit"
                  [style]="{'width':'15%'}">
            <ng-template let-item="rowData" pTemplate="body">
                <div><span class="editable-cell-value-span">{{ item.valueString }}</span>
                <span *ngIf="userCanEdit" class="material-icons editable-cell-value-span-icon"></span></div>
                <div *ngIf="item.valueString != item.value && item.value != undefined">({{item.value}})</div>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>

    <p *ngIf="!showTable">{{ 'PROFIT_MARGIN.FORM.DATA_LOADING' | translate }}</p>
</div>
