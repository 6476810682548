import {AddonAttribute} from "../../../../../../window-designer/entities/AddonAttribute";
import {CechaGrupa} from "../../../../../../window-designer/enums/CechaGrupa";
import {CechaType} from "../../../../../../window-designer/enums/CechaType";
import {ValidationErrors} from '../../../../../common/validation-errors';
import {MultiValidator} from "../../../../../shared/validator/input-validator";
import {Cecha} from './ConfigurableAddonModel/Cecha';
import {ConfigurableAddonDefinition} from "./ConfigurableAddonModel/ConfigurableAddonDefinition";
import {DefinitionParser} from "./ConfigurableAddonModel/DefinitionParser";
import {Opcja} from './ConfigurableAddonModel/Opcja';

export class ConfigurableAddonFormHandler {

    validateAddon(attributes: AddonAttribute[], definition: ConfigurableAddonDefinition, ignoreDimensionsGroup: boolean,
                  globalEditMode = false): ValidationErrors {
        let configAddonValidationErrors = {};
        if (attributes) {
            const parsed = DefinitionParser.parseDefinition(definition);

            let useBulkDimensions = true;
            if (!ignoreDimensionsGroup) {
                useBulkDimensions = !attributes.filter(attr => attr.group === CechaGrupa.DIMENSIONS).some(attr => attr.value != null && attr.value !== "" && attr.value !== "");
            }

            attributes.filter(attr => attr.visible).forEach(attr => {
                const cecha = parsed.cechy.find(c => c.symbol === attr.symbol);
                if ((cecha.grupa === CechaGrupa.DIMENSIONS || cecha.grupa === CechaGrupa.BULK_DIMENSIONS) && ignoreDimensionsGroup) {
                    return;
                }
                if (cecha.grupa === CechaGrupa.DIMENSIONS && useBulkDimensions) {
                    return;
                }
                if (cecha.grupa === CechaGrupa.BULK_DIMENSIONS && !useBulkDimensions) {
                    return;
                }
                if (cecha.type === CechaType.OPTIONS && cecha.obowiazkowa) {
                    if (globalEditMode && attr.value === Opcja.DO_NOT_CHANGE_OPTION_SYMBOL) {
                        return;
                    }
                    if (!attr.value || attr.value === '') {
                        configAddonValidationErrors[attr.symbol] = 'error.configAddon.attribute.not_null';
                    } else {
                        const valueIsAvailable = cecha.opcje.some(opcja => opcja.symbol === attr.value)
                            || (cecha.hasAutoOption && attr.value === Opcja.AUTO_OPTION_SYMBOL);
                        if (!valueIsAvailable) {
                            configAddonValidationErrors[attr.symbol] = 'error.configAddon.attribute.not_null';
                        }
                    }
                } else if ((cecha.type === CechaType.NUMERIC_VALUE) && cecha.obowiazkowa && (attr.value == undefined || attr.value === '')) {
                    configAddonValidationErrors[attr.symbol] = 'error.configAddon.attribute.not_null';
                } else if ((cecha.type === CechaType.NUMERIC_VALUE) && attr.value != undefined) {
                    let error = this.validate(cecha, attr);
                    if (error != undefined) {
                        configAddonValidationErrors[attr.symbol] = error;
                    }
                }
            });
        }
        return configAddonValidationErrors;
    }

    private validate(cecha: Cecha, attr: AddonAttribute) {
        if (!cecha.obowiazkowa && (attr.value === '' || !attr.value)) {
            return;
        }
        return this.createValidatorFor(cecha).validate(attr.value);
    }

    private createValidatorFor(cecha: Cecha) {
        let validator = MultiValidator.of('error.configAddon.attribute');
        if (cecha.obowiazkowa) {
            validator.withNotNullValidator();
        }
        if (cecha.type === CechaType.NUMERIC_VALUE) {
            if (!cecha.step || Number.isInteger(cecha.step)) {
                validator.withIntegerValidator();
            }
            validator.withRangeValidator(cecha.minValue ? cecha.minValue : 0, cecha.maxValue ? cecha.maxValue : 99999);
        }
        return validator;
    }
}
