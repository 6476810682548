import {DrawingUtil} from '../drawing-util';
import {WindowCalculator} from '../window-calculator';
import {WindowDesignerInterface} from '../window-designer-interface';
import {TextPainter} from './TextPainter';
import {PainterParams} from "./PainterParams";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {ScalingPainter} from "./ScalingPainter";
import {PainterMode} from "./PainterMode";

export class AreaNumbersPainter {
    public static paint(subwindow: SubWindowData, offerComponent: WindowDesignerInterface, totalGlazingBeadPoints: number[],
                        params: PainterParams): number[][] {
        if (params.isMode(PainterMode.WEBSHOP)) {
            return [];
        }
        const textPainter = TextPainter.forDrawing(offerComponent, params);
        let paintedBoxes: number[][] = [];
        for (let area of subwindow.areasSpecification) {
            let glazingBeadPoints = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions,
                totalGlazingBeadPoints, offerComponent.profileCompositionDistances);
            paintedBoxes.push(
                AreaNumbersPainter.paintSingle(glazingBeadPoints, area.ordinalNumber, offerComponent,
                    textPainter, params));
        }
        return paintedBoxes;
    }

    private static paintSingle(glazingBeadPoints: number[], areaNumber: number, offerComponent: WindowDesignerInterface,
                               textPainter: TextPainter, params: PainterParams): number[] {
        let centerPoint = DrawingUtil.getPolygonCentroid(glazingBeadPoints);
        let textString = params.shaded ? `[${areaNumber.toString()}]` : areaNumber.toString();
        let textElement = textPainter.paintText(textString, centerPoint);
        let bBox = textPainter.getBBox(textElement, () => {
            return `glazingBeadPoints: [${glazingBeadPoints.join()}]; areaNumber: ${areaNumber}; params: ${params}; centerPoint: [${centerPoint.join()}]; fontSize: ${textPainter.getFontSize(false)}; totalBoundingBox: ${JSON.stringify(offerComponent.totalBoundingBox)}; totalBoundingClientRect: ${JSON.stringify(offerComponent.totalBoundingClientRect)}`;
        });
        let boxPoints;
        let rect;
        if (params.isShaded()) {
            let halfRectSize = 32 / params.scale;
            textElement.addClass('render-area-numbers');
            textPainter.centerVertically(textElement);
            let rectPoints = [
                centerPoint[0] - halfRectSize, centerPoint[1] - halfRectSize,
                centerPoint[0] + halfRectSize, centerPoint[1] - halfRectSize,
                centerPoint[0] + halfRectSize, centerPoint[1] + halfRectSize,
                centerPoint[0] - halfRectSize, centerPoint[1] + halfRectSize
            ];
            rect = ScalingPainter.path(offerComponent.svg, rectPoints, {fill: '#000000', fillOpacity: '0.3', strokeWidth: '0'}, params);
            boxPoints = rectPoints;
        } else {
            rect = offerComponent.svg.rect(bBox.x, bBox.y, bBox.w, bBox.h);
            rect.attr({fill: "#ffffff", stroke: "#ffffff", strokeWidth: 2});
            boxPoints = bBox.toPoints();
        }
        textElement.attr({strokeWidth: 0}).insertAfter(rect);
        let group = offerComponent.svg.group(rect, textElement);
        group.attr({pointerEvents: "none"});
        group.addClass('area-number-label');
        return boxPoints;
    }
}
