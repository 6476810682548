import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, of} from "rxjs";
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {WindowSystemType} from "../../../../../../../window-designer/catalog-data/window-system-interface";
import {DrawingData} from "../../../../../../../window-designer/drawing-data/drawing-data";
import {GrillHelper} from "../../../../../../../window-designer/utils/grill-helper";
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {ValidationErrorsHelper} from "../../../../../../common/ValidationErrorsHelper";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {MultilanguageField} from "../../../../../../supportedLanguages";
import {Color} from "../../../../../window-system/color/color";
import {ColorService} from "../../../../../window-system/color/color.service";
import {Grill} from "../../../../../window-system/grill/grill";
import {GrillService} from "../../../../../window-system/grill/grill.service";
import {PositionType} from '../../../../AbstractPosition';
import {BulkChangeFrontendWarning} from "../BulkChangeFrontendWarning";
import {Position} from "../position";
import {BulkColorChangeDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-bulk-color-change',
    templateUrl: './bulk-color-change.component.html',
    styleUrls: ['./bulk-color-change.component.css', '../../../../../shared-styles.css'],
    providers: [ColorService, GrillService]
})
export class BulkColorChangeComponent implements OnInit {

    private static readonly LOAD_DATA_ID = 'BulkColorChangeComponent loadData';

    @Input()
    dialogData: BulkColorChangeDialogData;

    @Output()
    onSubmit = new EventEmitter<BulkChangeFrontendWarning[]>();

    @Output()
    onClose = new EventEmitter();

    coreColors: Color[] = [];
    externalColors: Color[] = [];
    internalColors: Color[] = [];
    allColors: Color[] = [];
    boldColors: Color[] = [];
    formatColorOption: (color: Color) => SelectItem;

    chosenCoreColor: Color;
    chosenExternalColor: Color;
    chosenInternalColor: Color;
    chosenTerraceColor: Color;

    grillsBySystem: { [systemId: number]: Grill[] } = {};
    updatedDatas: { offerPosition: Position, mappedData: DrawingData }[] = [];

    dataReady = false;

    validationErrors: { [field: string]: string } = {};
    isTerrace: boolean;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(public translate: TranslateService,
                private colorService: ColorService,
                private grillService: GrillService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController) {
        this.formatColorOption = (color: Color) => {
            return {
                label: (!color.group ? '' : color.group + ' - ') + color.names[this.translate.currentLang],
                value: color,
                bold: this.boldColors.findIndex(bc => bc.id === color.id) >= 0,
                disabled: !color.active,
                center: color.id === undefined
            };
        };
    }

    ngOnInit() {
        this.isTerrace = this.dialogData.selectedOfferPositions[0].windowSystemType === WindowSystemType.TERRACE.type;
        let copyOfferPosition = (offerPosition: Position) => {
            let position = new Position(offerPosition.name, PositionType.SYSTEM, null, null, offerPosition.quantity,
                null, null, null, null, null, null, null, null,
                null, null, null,  offerPosition.offerId, null);
            position.id = offerPosition.id;
            position.printOrder = offerPosition.printOrder;
            return {offerPosition: position, mappedData: JSON.parse(offerPosition.data)};
        };
        this.updatedDatas =
            this.dialogData.selectedOfferPositions.filter(p => p.type === PositionType.SYSTEM).map(copyOfferPosition);

        let windowSystemWithGrillsIds = this.getWindowSystemWithGrillsIds();
        this.loadData(this.getAllWindowSystemIds(), windowSystemWithGrillsIds);
    }

    private getAllWindowSystemIds(): Set<number> {
        return new Set<number>(this.updatedDatas.map(ud => ud.mappedData.windowSystemId));
    }

    private getWindowSystemWithGrillsIds(): number[] {
        return this.updatedDatas.filter(ud => this.hasGrills(ud.mappedData)).map(ud => ud.mappedData.windowSystemId)
            .filter((v, i, a) => a.indexOf(v) === i);
    }

    private hasGrills(data: DrawingData): boolean {
        return data.windows.some(w => w.subWindows.some(sw => sw.areasSpecification.some(a => GrillHelper.areaHasGrills(a))));
    }

    private loadData(allWindowSystemIds: Set<number>, selectedWindowSystemIds: number[]): void {
        this.blockUiController.block(BulkColorChangeComponent.LOAD_DATA_ID);
        const grillSources: Observable<{ [windowSystemId: string]: Grill[] }> =
            selectedWindowSystemIds.length > 0
                ? forkJoin(_.object(selectedWindowSystemIds,
                    selectedWindowSystemIds.map(id => this.grillService.getGrillsForWindowSystem(id))))
                : of({});
        forkJoin({
            anySystemColors: this.colorService.getAllActiveColors(Array.from(allWindowSystemIds), false),
            allSystemColors: this.colorService.getAllActiveColors(Array.from(allWindowSystemIds), true),
            grillsBySystem: grillSources
        }).pipe(
            finalize(() => this.blockUiController.unblock(BulkColorChangeComponent.LOAD_DATA_ID))
        ).subscribe({
            next: data => {
                console.info('`LoadData` success:');
                this.initColors(data.anySystemColors, data.allSystemColors);
                for (let index of selectedWindowSystemIds) {
                    this.grillsBySystem[index] = this.indexByIds(data.grillsBySystem[index]);
                }
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                console.info('`LoadData` completed!');
                this.dataReady = true;
                this.changeDetector.markForCheck();
            }
        });
    }

    private indexByIds(data: Grill[]): Grill[] {
        let grillsByIndex = [];
        data.forEach(grill => {
            grillsByIndex[grill.id] = grill;
        });
        return grillsByIndex;
    }

    private initColors(colors: Color[], boldColors: Color[]): void {
        colors.sort((c1, c2) => {
            const c1bold = boldColors.findIndex(c => c.id === c1.id) >= 0 ? 0 : 1;
            const c2bold = boldColors.findIndex(c => c.id === c2.id) >= 0 ? 0 : 1;
            if (c1bold !== c2bold) {
                return c1bold - c2bold;
            }
            return c1.sortIndex - c2.sortIndex;
        });
        let firstNonBold = colors.findIndex(c => c.active && boldColors.findIndex(bc => bc.id === c.id) < 0);
        if (colors.length > 0 && boldColors.findIndex(bc => bc.id === colors[0].id) >= 0) {
            const commonHeaderItem = new Color();
            commonHeaderItem.names = new MultilanguageField(this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_ALL'));
            commonHeaderItem.core = colors.slice(0, firstNonBold).findIndex(c => c.core) >= 0;
            commonHeaderItem.outside = colors.slice(0, firstNonBold).findIndex(c => c.outside) >= 0;
            commonHeaderItem.inside = colors.slice(0, firstNonBold).findIndex(c => c.inside) >= 0;
            commonHeaderItem.active = false;
            colors.unshift(commonHeaderItem);
            if (firstNonBold >= 0) {
                ++firstNonBold;
            }
        }
        if (firstNonBold >= 0) {
            const someHeaderItem = new Color();
            someHeaderItem.names = new MultilanguageField(this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_SOME'));
            someHeaderItem.core = colors.slice(firstNonBold).findIndex(c => c.core) >= 0;
            someHeaderItem.outside = colors.slice(firstNonBold).findIndex(c => c.outside) >= 0;
            someHeaderItem.inside = colors.slice(firstNonBold).findIndex(c => c.inside) >= 0;
            someHeaderItem.active = false;
            colors.splice(firstNonBold, 0, someHeaderItem);
        }
        let core = [];
        let external = [];
        let internal = [];
        for (let color of colors) {
            if (color.core) {
                core.push(color);
            }
            if (color.outside) {
                external.push(color);
            }
            if (color.inside) {
                internal.push(color);
            }
        }
        this.coreColors = core;
        this.externalColors = external;
        this.internalColors = internal;
        this.allColors = colors;
        this.boldColors = boldColors;
    }

    private coreColorRequireCover(): boolean {
        if (this.chosenCoreColor == undefined) {
            return false;
        }
        return this.chosenCoreColor.mustBeCovered && !this.chosenCoreColor.cannotBeCovered;
    }

    onCoreColorChange(chosenCoreColor: Color): void {
        this.clearValidationErrors();
        this.chosenCoreColor = chosenCoreColor;
        this.chosenExternalColor = undefined;
        this.chosenInternalColor = undefined;
    }

    private updateOffers(): {success: boolean, warnings: BulkChangeFrontendWarning[]} {
        if (!this.validateForm()) {
            return {success: false, warnings: []};
        }

        let warnings = [];
        for (let updateData of this.updatedDatas) {
            let systemGrills = this.grillsBySystem[updateData.mappedData.windowSystemId];

            if (!this.isTerrace) {
                updateData.mappedData.specification.colorIdCore = this.chosenCoreColor.id;
                if (this.chosenInternalColor != undefined) {
                    updateData.mappedData.specification.colorIdInternal = this.chosenInternalColor.id;
                } else {
                    updateData.mappedData.specification.colorIdInternal = undefined;
                }
                if (this.chosenExternalColor != undefined) {
                    updateData.mappedData.specification.colorIdExternal = this.chosenExternalColor.id;
                } else {
                    updateData.mappedData.specification.colorIdExternal = undefined;
                }

                if (this.chosenExternalColor) {
                    let colorChanged = false;
                    updateData.mappedData.windows.forEach(
                        w => w.subWindows.forEach(
                            sw => sw.areasSpecification.forEach(
                                a => a.grills.forEach(
                                    g => {
                                        if (_.contains(systemGrills[g.id].colorIds, this.chosenExternalColor.id)) {
                                            g.colorId = this.chosenExternalColor.id;
                                            colorChanged = true;
                                        }
                                    })
                            )));
                    if (colorChanged) {
                        warnings.push(new BulkChangeFrontendWarning(updateData.offerPosition.id,
                            'OFFER.POSITIONS.DIALOGS.BULK_COLOR_CHANGE.GRILL_COLORS_CHANGED'));
                    }
                }
            } else {
                updateData.mappedData.specification.colorIdExternal = this.chosenTerraceColor.id;
                updateData.mappedData.specification.colorIdInternal = this.chosenTerraceColor.id;
            }
            updateData.offerPosition.data = JSON.stringify(updateData.mappedData);
        }

        this.dialogData.modifiedPositions = this.updatedDatas.map(updateData => updateData.offerPosition);
        return {success: true, warnings: warnings};
    }

    submitDialog(): void {
        let result = this.updateOffers();
        if (result.success) {
            this.dialogHideHelper.call(() => this.onSubmit.emit(result.warnings));
        }
    }

    resetDialog() {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    protected validateForm(): boolean {
        this.clearValidationErrors();

        if (!this.isTerrace) {
            if (this.chosenCoreColor == undefined) {
                this.validationErrors['coreColor'] = 'error.offerPosition.bulkChange.color.core_color_not_empty';
            }

            if (this.chosenCoreColor && this.coreColorRequireCover()) {
                if (this.chosenExternalColor == undefined) {
                    this.validationErrors['externalColor'] =
                        'error.offerPosition.bulkChange.color.core_color_requires_cover';
                }
                if (this.chosenInternalColor == undefined) {
                    this.validationErrors['internalColor'] =
                        'error.offerPosition.bulkChange.color.core_color_requires_cover';
                }
            }
        } else {
            if (this.chosenTerraceColor == undefined) {
                this.validationErrors['terraceColor'] = 'error.offerPosition.bulkChange.color.terrace_color_not_empty';
            }
        }

        return !ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
    }

    private clearValidationErrors(): void {
        this.validationErrors = {};
    }
}
