import {Component, Input} from '@angular/core';
import {ValidationErrors} from "../validation-errors";
import {CompositionDistances} from "../../../window-designer/composition-distances";
import {ProfileType} from "../../../window-designer/catalog-data/profile-interface";

@Component({
    selector: 'app-composition-distances-form',
    templateUrl: './composition-distances-form.component.html'
})
export class CompositionDistancesFormComponent {

    @Input()
    compositionDistances: CompositionDistances;
    @Input()
    validationErrors: ValidationErrors;
    @Input()
    profileType: ProfileType;
    @Input()
    label: string;
    @Input()
    fieldName: string;
    @Input()
    disabled: boolean;

    profileTypes = ProfileType;

    isAnyErrorPresent(validationErrors: ValidationErrors): boolean {
        return !!validationErrors[this.fieldName + '.defaultWidth']
            || !!validationErrors[this.fieldName + '.topWidth']
            || !!validationErrors[this.fieldName + '.bottomWidth']
            || !!validationErrors[this.fieldName + '.hingedSideWidth']
            || !!validationErrors[this.fieldName + '.hingelessSideWidth'];
    }
}
