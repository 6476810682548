import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, mergeMap, tap} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ComponentWithUserConfigAndPaginator, KeepSelectedItemEventParams} from '../../../common/crud-common/paginable.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {SupportedLanguages} from '../../../supportedLanguages';
import {WebshopHelp, WebshopHelpType} from './webshop-help';
import {WebshopHelpService} from './webshop-help.service';

@Component({
    selector: 'app-webshop-help',
    templateUrl: './webshop-help.component.html',
    providers: [WebshopHelpService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopHelpComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    items: WebshopHelp[];
    help: WebshopHelp;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    selectedItem: WebshopHelp;

    displayDialog = false;
    uploadedFile: File;
    selectedUnusedFile: string;

    supportedLanguages: SelectItem[];
    canUploadToAmazon = false;
    unusedAmazonS3Files: SelectItem[] = [];

    private readonly webshopHelpService: WebshopHelpService;
    private readonly growls: GrowlMessageController;
    private readonly errors: CommonErrorHandler;

    @ViewChild('table', {static: true})
    private table: Table;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'WebshopHelpComponent', false);
        this.webshopHelpService = injector.get(WebshopHelpService);
        this.growls = injector.get(GrowlMessageController);
        this.errors = injector.get(CommonErrorHandler);
    }

    ngOnInit() {
        super.ngOnInit();
        this.supportedLanguages = SupportedLanguages.languages.map(lang => ({label: lang.name, value: lang.code}));
    }

    showDialogToAdd(): void {
        const help = new WebshopHelp();
        help.type = WebshopHelpType.WEBSHOP;
        forkJoin({
            help: of(help),
            canUpload: this.webshopHelpService.canUploadToAmazonS3(),
            unusedFiles: this.webshopHelpService.getUnusedAmazonS3Files()
        }).subscribe(data => {
            this.setupDialog(data);
        });
    }

    submit(): void {
        this.validateForm().pipe(
            mergeMap(validationErrors => {
                if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
                    this.validationErrors = validationErrors;
                    return of(undefined);
                }

                return this.webshopHelpService.saveHelp(this.help, undefined,
                    this.uploadedFile != undefined ? this.uploadedFile : this.selectedUnusedFile).pipe(
                    tap(() => {
                        this.growls.info(this.help.id == undefined
                            ? 'SETTINGS.SECTION.WEBSHOP_HELP.DATA_CREATED'
                            : 'SETTINGS.SECTION.WEBSHOP_HELP.DATA_UPDATED');
                        this.displayDialog = false;
                    })
                );
            })
        ).subscribe({
            error: error => {
                this.validationErrors = this.errors.handle(error);
                this.changeDetector.markForCheck();
            },
            complete: () => {
                this.loadItemsLazy(this.table.createLazyLoadMetadata());
                this.changeDetector.markForCheck();
            }
        });
    }

    onRowSelect(event: { data: WebshopHelp } & KeepSelectedItemEventParams): void {
        forkJoin({
            help: this.webshopHelpService.getHelp(event.data.id),
            canUpload: this.webshopHelpService.canUploadToAmazonS3(),
            unusedFiles: this.webshopHelpService.getUnusedAmazonS3Files()
        }).subscribe(data => {
            this.setupDialog(data);
        });
    }

    private setupDialog(data: { help: WebshopHelp, canUpload: boolean, unusedFiles: string[] }): void {
        this.help = data.help;
        this.uploadedFile = undefined;
        this.selectedUnusedFile = undefined;
        this.canUploadToAmazon = data.canUpload;
        this.unusedAmazonS3Files = data.unusedFiles.map(unusedFile => ({label: unusedFile, value: unusedFile}));
        this.displayDialog = true;
        this.changeDetector.markForCheck();
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        super.loadItemsLazy(event);
        this.webshopHelpService.getAll(WebshopHelpType.WEBSHOP).pipe(
            finalize(() => {
                this.blockUiController.unblock('TranslationsComponentData');
                this.hideDataLoadingIndicator();
            })
        ).subscribe({
            next: data => {
                this.items = data;
                this.totalRecords = data.length;
                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                this.selectedItem = this.restoreSelectionAfterLoad(this.selectedItem, this.items, event);
            },
            error: error => {
                this.errors.handle(error);
                this.changeDetector.markForCheck();
            },
            complete: () => {
                this.changeDetector.markForCheck();
            }
        });
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    rowTrackById(index: number, item: WebshopHelp): string {
        return `${item.id}`;
    }

    handleUploadedFileChange(file: File): void {
        this.uploadedFile = file || new File([], null);
        this.selectedUnusedFile = undefined;
        this.help.amazonS3FileName = this.uploadedFile.name;
        this.changeDetector.markForCheck();
    }

    handleUnusedFileSelectionChange(file: string): void {
        this.uploadedFile = undefined;
        this.selectedUnusedFile = file;
        this.help.amazonS3FileName = this.selectedUnusedFile;
        this.changeDetector.markForCheck();
    }

    private validateForm(): Observable<ValidationErrors> {
        return of({});
    }
}
