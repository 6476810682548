import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {mergeMap} from 'rxjs/operators';
import {ContainerWithPreviewComponent} from '../../../../common/container-with-preview/container-with-preview.component';
import {AbstractPosition, PositionTarget} from '../../AbstractPosition';
import {PositionListComponent} from "../../offers/position/position-list/position-list.component";
import {PaymentPackageService} from '../../payment-package/payment-package.service';
import {Complaint} from "../complaint";
import {ComplaintService} from "../complaint.service";

@Component({
    selector: 'app-complaint-position',
    templateUrl: './complaint-position.component.html',
    providers: [ComplaintService, PaymentPackageService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComplaintPositionComponent implements OnInit {

    @ViewChild("positionList") positionList: PositionListComponent;
    @ViewChild("container") container: ContainerWithPreviewComponent;

    complaint: Complaint;

    constructor(private translate: TranslateService,
                private route: ActivatedRoute,
                private changeDetector: ChangeDetectorRef,
                private complaintService: ComplaintService) {
    }

    ngOnInit() {
        this.route.params.pipe(mergeMap(params => {
            return this.complaintService.getComplaint(+params['complaintId']);
        })).subscribe(complaint => {
            this.complaint = complaint;
            this.changeDetector.markForCheck();
        });
    }

    positionChange(event: { data?: AbstractPosition }) {
        this.container.handlePositionChange(event.data, PositionTarget.OFFER);
    }

    reloadComplaint(): void {
        this.complaintService.getComplaint(this.complaint.id).subscribe(complaint => {
            this.complaint = complaint;
            this.positionList.reloadOffer();
            this.changeDetector.markForCheck();
        });
    }
}
