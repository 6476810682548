import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from "@angular/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {interval, Observable} from 'rxjs';
import {finalize, mergeMap, takeUntil, takeWhile} from "rxjs/operators";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {PriceListService} from "../price-list.service";
import {Pricelist, PricelistStatus, PricelistTarget} from "../pricelist";

@Component({
    selector: 'app-price-lists-definition',
    templateUrl: './price-lists-definition.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [PriceListService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceListsDefinitionComponent extends CrudCommonComponent<Pricelist, PriceListService> implements OnInit {

    @Input()
    target: PricelistTarget;

    public static readonly MILLIS_IN_5_MINUTES = 300000;
    filterPricelistStatus: Observable<SelectItem[]>;
    selectedStatuses: PricelistStatus[] = [];

    filterCurrent: Observable<SelectItem[]>;

    @ViewChild('dt')
    datatable: DataTable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, PriceListService, 'PRICE_LISTS_DEFINITION', 'PriceListsDefinition');
        this.item = new Pricelist();
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterPricelistStatus = this.translatedSelectItemService.buildUnsortedDropdown(PricelistStatus,
            'PRICE_LISTS_DEFINITION.FORM.', undefined);
        this.filterCurrent = this.translatedSelectItemService.buildUnsortedDropdown([true, false],
            value => value ? 'GENERAL.YES' : 'GENERAL.NO', '');
    }

    loadItemsLazy(event: LazyLoadEvent) {
        event.filters['target'] = {value: this.target};
        super.loadItemsLazy(event);
    }

    getNewItem(): Pricelist {
        return new Pricelist();
    }

    prepareItemForRequest(): Pricelist {
        let requestItem: Pricelist = this.getNewItem();
        requestItem.id = this.item.id;
        requestItem.name = this.item.name;
        requestItem.status = this.item.status;
        requestItem.validFrom = this.item.validFrom;
        requestItem.target = this.target;
        return requestItem;
    }

    doOnRowSelect(event): void {
        if (event.data && event.data.status === PricelistStatus.GENERATING) {
            return;
        }
        super.doOnRowSelect(event);
    }

    handleSelectedStatusesChange(statuses: PricelistStatus[]): void {
        this.selectedStatuses = statuses;
        this.datatable.filter(this.selectedStatuses, 'status', 'contains');
    }

    add() {
        this.validateFormImpl().subscribe(validationOk => {
            if (!validationOk) {
                return;
            }
            if (this.isSaveInProgress()) {
                return;
            } else {
                this.setSaveInProgress(true);
            }

            this.itemService.addItem(this.prepareItemForRequest()).pipe(
                finalize(() => this.setSaveInProgress(false)))
                .subscribe({
                    next: pricelistId => {
                        if (this.newItem || this.copyMode) {
                            this.selectedItem = new Pricelist();
                            this.selectedItem.id = pricelistId;
                        }
                        // poll status
                        interval(5000).pipe(
                            takeUntil(this.componentDestroyed),
                            mergeMap(() => this.itemService.getItem(pricelistId)),
                            takeWhile(pricelist => pricelist.status === PricelistStatus.GENERATING)
                        ).subscribe({
                            complete: () => {
                                this.growlMessageController.info('PRICE_LISTS_DEFINITION.PRICE_LISTS_DEFINITION_GENERATED');
                                if (!this.displayDialog && !this.newItem) {
                                    this.reloadDatatable();
                                }
                            }
                        });
                    },
                    error: error => {
                        this.genericErrorHandler(error);
                    },
                    complete: () => {
                        this.cleanUpAndReload();
                        this.showSuccessMessage();
                    }
                });
        });
    }

    validateForm() {
        this.validationErrors = {};
        if (!this.item.name) {
            this.validationErrors['name'] = 'error.pricelistDto.name.not_empty';
        }
        if (this.item.validFrom.valueOf() < Date.now() + PriceListsDefinitionComponent.MILLIS_IN_5_MINUTES) {
            this.validationErrors['validFrom'] = 'error.pricelistDto.validFrom.not_in_range';
        }
        return super.validateForm();
    }
}
