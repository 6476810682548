import {Injectable, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

class TranslatedSelectItems {

    private readonly output: BehaviorSubject<SelectItem[]>;
    private readonly items: SelectItem[];
    private readonly firstItem: SelectItem;
    private readonly sorted: boolean;

    constructor(items: SelectItem[], firstItem: SelectItem, sorted: boolean) {
        this.output = new BehaviorSubject<SelectItem[]>([]);
        this.items = items;
        this.firstItem = firstItem;
        this.sorted = sorted;
    }

    handleLangChange(translations: { [key: string]: string }): void {
        let newItems: SelectItem[] = this.items.map(original => {
            return {
                label: translations[original.label],
                value: original.value,
                available: true
            };
        });
        if (this.sorted) {
            newItems.sort((a, b) => a.label.localeCompare(b.label));
        }
        if (this.firstItem != undefined) {
            newItems.unshift({label: translations[this.firstItem.label], value: this.firstItem.value});
        }
        this.output.next(newItems);
    }

    getObservable(): Observable<SelectItem[]> {
        return this.output.asObservable();
    }
}

// Do not provide on global level - avoids having to manage subscriptions manually in each component
@Injectable()
export class TranslatedSelectItemService implements OnDestroy {

    private subscriptions: Subscription[] = [];

    constructor(private translate: TranslateService) {
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    buildSortedDropdown<T>(list: T[], translationKey: ((value: T) => string) | string, firstItemKey: string): Observable<SelectItem[]>;
    buildSortedDropdown(list: object, translationKey: ((key: string) => string) | string, firstItemKey: string): Observable<SelectItem[]>;
    buildSortedDropdown(list: any, translationKey: ((v: any) => string) | string, firstItemKey: string): Observable<SelectItem[]> {
        return this.buildDropdown(true, list, translationKey, firstItemKey, '');
    }

    buildUnsortedDropdown<T>(list: T[], translationKey: ((value: T) => string) | string, firstItemKey: string): Observable<SelectItem[]>;
    buildUnsortedDropdown(list: object, translationKey: ((key: string) => string) | string, firstItemKey: string): Observable<SelectItem[]>;
    buildUnsortedDropdown(list: any, translationKey: ((v: any) => string) | string, firstItemKey: string): Observable<SelectItem[]> {
        return this.buildDropdown(false, list, translationKey, firstItemKey, '');
    }

    private buildDropdown(sorted: boolean, list: any, translationKey: ((v: any) => string) | string,
                          firstItemKey: string, firstItemValue: any): Observable<SelectItem[]> {
        let convertedValues = Array.isArray(list) ? list : Object.keys(list);

        let buildKey = (value): string => typeof translationKey === 'string' ? translationKey + value : translationKey(value);
        let buildItem = (value): SelectItem => ({label: buildKey(value), value: value, available: true});
        let translatedSelectItems = new TranslatedSelectItems(convertedValues.map(buildItem),
            firstItemKey != undefined ? {label: firstItemKey, value: firstItemValue, available: true} : undefined, sorted);

        let keys: string[] = convertedValues.map(buildKey);
        if (firstItemKey) {
            keys.unshift(firstItemKey);
        }

        if (keys.length > 0) {
            // do one instant translation to better integrate with select component
            // (make options available immediately, even if untranslated)
            translatedSelectItems.handleLangChange(this.translate.instant(keys));

            this.translate.get(keys).subscribe(translations => translatedSelectItems.handleLangChange(translations));
            this.subscriptions.push(this.translate.onLangChange.subscribe(event =>
                translatedSelectItems.handleLangChange(this.translate.getParsedResult(event.translations, keys))));
        }

        return translatedSelectItems.getObservable();
    }
}
