import {Component, Input} from '@angular/core';
import {MultilanguageFieldSelectItem} from "../../../../common/service/select-item-multilanguage-field-translate.pipe";
import {GraspGlazingPackageField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GraspGlazingPackageFieldUsage} from "../../catalog-field-usage";
import {GraspGlazingPackage} from "../grasp-glazing-package";

@Component({
  selector: 'app-grasp-glazing-package-form',
  templateUrl: './grasp-glazing-package-form.component.html'
})
export class GraspGlazingPackageFormComponent {

    @Input()
    item: GraspGlazingPackage;

    @Input()
    selectCategories: MultilanguageFieldSelectItem[];

    @Input()
    frameCategories: MultilanguageFieldSelectItem[];

    @Input()
    validationErrors;

    @Input()
    editPermits: FieldLimitation[] = [];

    fieldUsage: GraspGlazingPackageFieldUsage;
    GraspGlazingPackageField = GraspGlazingPackageField;

    constructor() {
        this.fieldUsage = new GraspGlazingPackageFieldUsage(this);
    }

}
