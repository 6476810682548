import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {finalize} from 'rxjs/operators';
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {PositionService} from "../../position.service";
import {UpdateValidityDatesDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-update-validity-dates',
    templateUrl: './update-validity-dates.component.html',
    providers: [PositionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateValidityDatesComponent {

    private static readonly BLOCK_UI_NAME = 'UpdateValidityDates';

    @Input()
    dialogData: UpdateValidityDatesDialogData;

    @Output()
    onClose = new EventEmitter();

    @Output()
    onSuccess = new EventEmitter();

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private positionService: PositionService,
                private translate: TranslateService,
                private blockUiController: BlockUiController,
                private errors: CommonErrorHandler) {
    }

    public submit(): void {
        this.blockUiController.block(UpdateValidityDatesComponent.BLOCK_UI_NAME);
        this.positionService.updateOffersValidityDate(this.dialogData.offerId)
            .pipe(finalize(() => this.blockUiController.unblock(UpdateValidityDatesComponent.BLOCK_UI_NAME))).subscribe({
            complete: () => {
                this.emitOnSuccess();
                this.close();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    public close(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    private emitOnSuccess(): void {
        this.dialogHideHelper.call(() => this.onSuccess.emit());
    }
}
