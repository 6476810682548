import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TrackByFunction,
    ViewChild
} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {DataTable} from 'primeng/datatable';
import {Permissions} from "../../../../auth/permission.service";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {PaginatorRowsPerPageOptions} from "../../../../common/crud-common/paginatorRowsPerPageOptions";
import {DataTableColumn} from "../../../../common/service/data.table.column";
import {ValidationErrorsHelper} from "../../../../common/ValidationErrorsHelper";
import {TristateCheckboxState} from "../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {ProductionOrderService} from "../../production-orders/production-order-service";
import {ProductionOrder} from "../../production-orders/ProductionOrder";
import {Palette} from '../palette';
import {PaletteTableData} from "./palette-table-data";

@Component({
    selector: 'app-palette-table',
    templateUrl: './palette-table.component.html',
    styleUrls: ['../../../shared-styles.css', './palette-table.component.css'],
    providers: [ProductionOrderService, Permissions],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaletteTableComponent implements OnChanges {

    public static readonly EDIT_DESCRIPTION_BLOCK_ID = 'deliveryDescriptionEdit';

    @Input()
    tableData: PaletteTableData;
    @Input()
    palette: Palette;
    @Input()
    columns: DataTableColumn[];
    @Input()
    canEdit: boolean;

    @Output()
    onLazyLoad = new EventEmitter<any>();
    @Output()
    emitGoToOrder: EventEmitter<string> = new EventEmitter<string>();

    refreshCounter = 0;
    limitedRowsPerPageOptions = PaginatorRowsPerPageOptions.limitedValues;

    @ViewChild('dataTable', {static: true})
    dataTable: DataTable;

    columnByField: { [field: string]: DataTableColumn } = {};

    private readonly blockUiController: BlockUiController;
    private readonly changeDetector: ChangeDetectorRef;
    private readonly errors: CommonErrorHandler;
    private readonly productionOrderService: ProductionOrderService;
    readonly translate: TranslateService;

    showDescriptionDialog = false;
    editedProductionOrder: ProductionOrder;
    editedDescription: string;
    validationErrors: { [field: string]: string } = {};

    constructor(injector: Injector) {
        this.blockUiController = injector.get(BlockUiController);
        this.changeDetector = injector.get(ChangeDetectorRef);
        this.errors = injector.get(CommonErrorHandler);
        this.productionOrderService = injector.get(ProductionOrderService);
        this.translate = injector.get(TranslateService);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('columns' in changes) {
            const change = changes['columns'];
            if (change.currentValue != undefined) {
                this.columnByField = {};
                for (let column of change.currentValue) {
                    this.columnByField[column.field] = column;
                }
            }
        }
    }

    goToOrder(id: number): void {
        this.emitGoToOrder.emit(id.toString());
    }

    selectAllChange(state: TristateCheckboxState): void {
        this.tableData.allSelectedState = state;
        this.tableData.selectedItems = [];
        if (state === TristateCheckboxState.CHECKED) {
            this.tableData.selectedItems.push(...this.tableData.productionOrders);
        }
    }

    isSelectedItem(item: ProductionOrder): boolean {
        return this.tableData.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: ProductionOrder): void {
        let index = this.tableData.selectedItems.indexOf(item);
        if (index > -1) {
            this.tableData.selectedItems.splice(index, 1);
        } else {
            this.tableData.selectedItems.push(item);
        }
        if (this.tableData.selectedItems.length === this.tableData.productionOrders.length) {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED;
        } else if (this.tableData.selectedItems.length === 0) {
            this.tableData.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    showOrderDescriptionDialog(productionOrder: ProductionOrder) {
        this.validationErrors = {};
        this.editedProductionOrder = productionOrder;
        this.editedDescription = productionOrder.deliveryDescription;
        this.showDescriptionDialog = true;
    }

    closeDialog(): void {
        this.showDescriptionDialog = false;
        this.editedDescription = null;
        this.editedProductionOrder = null;
        this.changeDetector.markForCheck();
    }

    editDescription(): void {
        let previousValue = this.editedProductionOrder.deliveryDescription;
        this.editedProductionOrder.deliveryDescription = this.editedDescription;
        if (this.validateForm()) {
            this.blockUiController.block(PaletteTableComponent.EDIT_DESCRIPTION_BLOCK_ID);
            this.productionOrderService.updateDeliveryDescription(this.editedProductionOrder).subscribe({
                next: () => {
                    this.blockUiController.unblock(PaletteTableComponent.EDIT_DESCRIPTION_BLOCK_ID);
                    this.closeDialog();
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.editedProductionOrder.deliveryDescription = previousValue;
                    this.blockUiController.unblock(PaletteTableComponent.EDIT_DESCRIPTION_BLOCK_ID);
                }
            });
        }
    }

    validateForm(): boolean {
        this.validationErrors = {};
        if (this.editedProductionOrder.deliveryDescription.length > 1024) {
            this.validationErrors['deliveryDescription'] = 'error.productionOrderDto.deliveryDescription.not_in_range';
        }
        if (ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            return false;
        }
        return true;
    }

    rowTrackBy: TrackByFunction<ProductionOrder> = (index: number, productionOrder: ProductionOrder) => {
        return `${productionOrder.id}_${this.refreshCounter}`;
    }

    isFilterable(defaultFilterable: boolean) {
        return defaultFilterable && this.tableData.showFilters;
    }

    getDataTable(): DataTable {
        return this.dataTable;
    }

    getPalette(): Palette {
        return this.palette;
    }

    emitLazyLoad(event: any): void {
        if (this.tableData.rowsPerPage !== event.rows) {
            this.tableData.rowsPerPage = event.rows;
        }
        this.onLazyLoad.emit(event);
    }

    refreshTable(clearRowCache: boolean): void {
        if (clearRowCache) {
            this.refreshCounter += 1;
        }
        this.changeDetector.markForCheck();
    }
}
