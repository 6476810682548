import {ChangeDetectorRef, Directive, Injector, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, of} from 'rxjs';
import {map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {CrudItem} from "../../../common/crud-common/crudItem";
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {SidebarFieldChangeEvent} from '../../offer/gate-editor/gate-sidebar/gate-sidebar.component';
import {CatalogItemType} from "../../offer/window-editor/sidebar/pricing/PricingItem";
import {SystemDefaultsService} from "../../offer/window-editor/system-defaults.service";
import {SubsystemGroupService} from '../../subsystem-group/subsystem-group.service';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {SubsystemSelectionItem} from '../../subsystem/SubsystemSelectionItem';
import {SystemDefaultsState} from "./system-default-state";

@Directive()
export abstract class SystemDefaultsComponent<Item extends CrudItem, State extends SystemDefaultsState<any>> implements OnInit {

    systems: Item[];
    selectedSystem: Item;
    state: State;

    defaultsLevel: 'GLOBAL' | 'SUBSYSTEM_GROUP' | 'SUBSYSTEM' | 'CLIENT_GROUP' | 'CLIENT' | 'OFFER';
    defaultsLevels: SelectItem[] = [];
    defaultsOverrideLowerLevel: boolean;

    readonly dialogs = {
        confirmReplacing: false,
        confirmSubsystemGroup: false,
        confirmSubsystem: false
    };

    protected readonly subsystemGroupsSource: Observable<SubsystemSelectionItem[]>;
    protected readonly subsystemsSource: Observable<SubsystemSelectionItem[]>;

    subsystemGroups: SubsystemSelectionItem[] = [];
    subsystems: SubsystemSelectionItem[] = [];

    itemType: CatalogItemType;
    CatalogItemType = CatalogItemType;

    systemDefaultsService: SystemDefaultsService;
    subsystemGroupService: SubsystemGroupService;
    subsystemService: SubsystemService;
    translatedSelectItemService: TranslatedSelectItemService;
    translate: TranslateService;
    permissions: Permissions;
    growls: GrowlMessageController;
    changeDetector: ChangeDetectorRef;
    errors: CommonErrorHandler;

    protected constructor(injector: Injector) {
        this.systemDefaultsService = injector.get(SystemDefaultsService);
        this.subsystemGroupService = injector.get(SubsystemGroupService);
        this.subsystemService = injector.get(SubsystemService);
        this.translatedSelectItemService = injector.get(TranslatedSelectItemService);
        this.translate = injector.get(TranslateService);
        this.permissions = injector.get(Permissions);
        this.growls = injector.get(GrowlMessageController);
        this.changeDetector = injector.get(ChangeDetectorRef);
        this.errors = injector.get(CommonErrorHandler);

        this.subsystemGroupsSource = this.subsystemGroupService.getItems(undefined, undefined, undefined, undefined, undefined).pipe(
            map(listingDto => listingDto.data.map(sg => ({id: sg.id, name: sg.name, selected: false})))).pipe(shareReplay());
        this.subsystemsSource = this.subsystemService.getNames().pipe(map(listingDto => listingDto.data)).pipe(shareReplay());
    }

    ngOnInit() {
        this.loadDefaultsLevels();
    }

    protected loadDefaultsLevels(): void {
        this.defaultsLevels.push({
            label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM',
            value: 'SUBSYSTEM'
        });
        this.defaultsLevels.push({
            label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.SUBSYSTEM_GROUP',
            value: 'SUBSYSTEM_GROUP'
        });
        if (this.permissions.isKoordynator()) {
            this.defaultsLevels.push({
                label: 'OFFER.TABS.SECTION.DEFAULTS.LEVEL.GLOBAL',
                value: 'GLOBAL'
            });
        }

        if (this.defaultsLevels.length > 0) {
            this.defaultsLevel = this.defaultsLevels[0].value;
        }
    }

    systemOptionFormatter = (system: Item) => ({label: system['name'][this.translate.currentLang], value: system});

    systemKey = (system: CrudItem) => system != undefined ? system.id : undefined;

    abstract handleSystemChange(system: Item): void;

    abstract handleSidebarFieldChange(event: SidebarFieldChangeEvent): void;

    handleDefaultsSavedClick(): void {
        switch (this.defaultsLevel) {
            case 'SUBSYSTEM_GROUP':
                this.subsystemGroupsSource.subscribe(subsystemGroups => {
                    this.subsystemGroups = subsystemGroups;
                    this.dialogs.confirmSubsystemGroup = true;
                    this.changeDetector.markForCheck();
                });
                return;
            case 'SUBSYSTEM':
                this.subsystemsSource.subscribe(subsystems => {
                    this.subsystems = subsystems;
                    this.dialogs.confirmSubsystem = true;
                    this.changeDetector.markForCheck();
                });
                return;
        }

        if (this.state[this.getFieldNameFromLevel(this.defaultsLevel)]) {
            this.dialogs.confirmReplacing = true;
            this.changeDetector.markForCheck();
            return;
        }

        this.saveDefaults();
    }

    saveDefaults(): void {
        let observable: Observable<any> = of({});
        let itemType = this.itemType;
        if (this.defaultsLevel === 'SUBSYSTEM_GROUP') {
            let subsystemGroupIds = this.subsystemGroups.filter(s => s.selected).map(s => s.id);
            observable = observable.pipe(mergeMap(() => this.systemDefaultsService.overwriteForSubsystemGroups(
                itemType, this.selectedSystem.id, subsystemGroupIds, this.state.value)));
        } else if (this.defaultsLevel === 'SUBSYSTEM') {
            let subsystemIds = this.subsystems.filter(s => s.selected).map(s => s.id);
            observable = observable.pipe(mergeMap(() => this.systemDefaultsService.overwriteForSubsystems(
                itemType, this.selectedSystem.id, subsystemIds, this.state.value)));
        } else {
            if (this.defaultsOverrideLowerLevel) {
                observable = observable.pipe(
                    mergeMap(() => this.systemDefaultsService.deleteSystemDefaults(
                        itemType, this.defaultsLevel, this.selectedSystem.id, undefined)),
                    tap(() => {
                        const levels = ['GLOBAL', 'SUBSYSTEM_GROUP', 'SUBSYSTEM', 'CLIENT_GROUP', 'CLIENT', 'OFFER'];
                        const savedIndex = levels.indexOf(this.defaultsLevel) + 1;
                        for (let i = savedIndex; i < levels.length; ++i) {
                            this.state[this.getFieldNameFromLevel(levels[i])] = false;
                        }
                    }));
            }
            observable = observable.pipe(mergeMap(() => this.systemDefaultsService.save(itemType, this.defaultsLevel,
                this.selectedSystem.id, undefined, this.state.value)));
        }
        observable.subscribe({
            complete: () => {
                this.dialogs.confirmReplacing = false;
                this.dialogs.confirmSubsystemGroup = false;
                this.dialogs.confirmSubsystem = false;
                this.state[this.getFieldNameFromLevel(this.defaultsLevel)] = true;
                this.growls.info('OFFER.TABS.SECTION.DEFAULTS.SAVED');
                this.changeDetector.markForCheck();
            },
            error: error => {
                let errors = this.errors.handle(error);
                for (let errorsKey in errors) {
                    this.growls.error(errors[errorsKey]);
                }
            }
        });
    }

    protected getFieldNameFromLevel(level: string): keyof SystemDefaultsState<any> {
        switch (level) {
            case 'GLOBAL':
                return 'globalDefaultExisting';
            case 'SUBSYSTEM_GROUP':
                return 'subsystemGroupDefaultExisting';
            case 'SUBSYSTEM':
                return 'subsystemDefaultExisting';
            case 'CLIENT_GROUP':
                return 'clientGroupDefaultExisting';
            case 'CLIENT':
                return 'clientDefaultExisting';
            case 'OFFER':
                return 'offerDefaultExisting';
        }
    }
}
