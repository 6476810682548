import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';

export enum WebshopCustomPageType {
    TEXT = 'TEXT',
    HTML = 'HTML',
    EXTERNAL = 'EXTERNAL'
}

export enum WebshopCustomPageLinkLocation {
    NONE = 'NONE',
    HEADER = 'HEADER'
}

@JsonDeserializable
export class WebshopCustomPage implements CrudItem {
    id: number;
    name: string;
    path: string;
    type: WebshopCustomPageType;
    src?: string;
    content?: string;
    linkLocation: WebshopCustomPageLinkLocation;

    static fromJSON(jsonObject: any): WebshopCustomPage {
        const webshopCustomPage = new WebshopCustomPage();
        webshopCustomPage.id = jsonObject.id;
        webshopCustomPage.name = jsonObject.name;
        webshopCustomPage.path = jsonObject.path;
        webshopCustomPage.type = WebshopCustomPageType[jsonObject.type as string];
        webshopCustomPage.src = jsonObject.src;
        webshopCustomPage.content = jsonObject.content;
        webshopCustomPage.linkLocation = WebshopCustomPageLinkLocation[jsonObject.linkLocation as string];
        return webshopCustomPage;
    }
}
