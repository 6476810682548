<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(AddonCategoryField.NAME)"
                            inputId="name" label="{{ 'ADDON_CATEGORY.FORM.NAME' | translate }}" validationKeyPrefix="name"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.name"
                            [disabled]="fieldUsage.disabled(AddonCategoryField.NAME)"></app-translation-fields>

    <div *ngIf="fieldUsage.show(AddonCategoryField.SORT_INDEX)" class="new-form-row">
        <app-input-number [disabled]="fieldUsage.disabled(AddonCategoryField.SORT_INDEX)"
                          label="{{ 'ADDON_CATEGORY.FORM.SORT_INDEX' | translate }}"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"
                          inputId="sortIndex"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(AddonCategoryField.GROUP)" class="new-form-row">
        <app-select inputId="groupId" label="{{ 'ADDON_CATEGORY.FORM.GROUP' | translate }}"
                    [(validationMessageKey)]="validationErrors['groupId']" [options]="addonCategoryGroups"
                    [(ngModel)]="item.groupId" [required]="true"
                    [disabled]="fieldUsage.disabled(AddonCategoryField.GROUP)"></app-select>
    </div>

    <div *ngIf="fieldUsage.show(AddonCategoryField.SYMBOL)" class="new-form-row">
        <app-input-text [(ngModel)]="item.symbol" [disabled]="fieldUsage.disabled(AddonCategoryField.SYMBOL)"
                        label="{{ 'SUBSYSTEM-DETAILS.FORM.SYMBOL' | translate }}"
                        [(validationMessageKey)]="validationErrors['symbol']"
                        [required]="true"
                        inputId="symbol"></app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(AddonCategoryField.REQUIRED)" class="new-form-row">
        <app-checkbox inputId="required"
                      label="{{ 'ADDON_CATEGORY.FORM.REQUIRED' | translate }}"
                      [(ngModel)]="item.required"
                      [disabled]="fieldUsage.disabled(AddonCategoryField.REQUIRED)"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(AddonCategoryField.TILE_DIALOG)" class="new-form-row">
        <app-checkbox class="tileDialog"
                      [inputId]="'tileDialog' + item" label="{{ 'ADDON_CATEGORY.FORM.TILE_DIALOG' | translate }}"
                      [(ngModel)]="item.tileDialog"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(AddonCategoryField.HIDE_VALUE_ON_PRINT)" class="new-form-row">
        <app-checkbox [(ngModel)]="item.hideValueOnPrint"
                      [disabled]="fieldUsage.disabled(AddonCategoryField.HIDE_VALUE_ON_PRINT)"
                      inputId="hideValueOnPrint"
                      label="{{ 'ADDON_CATEGORY.FORM.HIDE_VALUE_ON_PRINT' | translate }}"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(AddonCategoryField.PRINT_VISIBILITY)" class="new-form-row">
        <app-radio-button inputId="printVisibility"
                          label="{{ 'ADDON_CATEGORY.FORM.PRINT_VISIBILITY.LABEL' | translate }}"
                          [(validationMessageKey)]="validationErrors['printVisibility']" [options]="printVisibilities"
                          [required]="true" [translateLabels]="true" [horizontal]="false"
                          [(ngModel)]="item.printVisibility"
                          [disabled]="fieldUsage.disabled(AddonCategoryField.PRINT_VISIBILITY)"></app-radio-button>
    </div>

    <p-accordion>
        <p-accordionTab *ngIf="fieldUsage.show(AddonCategoryField.INFO)"
                        [selected]="false" header="{{ 'ADDON_CATEGORY.FORM.INFO' | translate }}">
            <div *ngFor="let language of supportedLanguages" class="new-form-row">
                <app-text-area  [(ngModel)]="item.info && item.info[language.code]"
                                [disabled]="fieldUsage.disabled(AddonCategoryField.INFO)"
                                [inputId]="'info_' + language.code"
                                [maxlength]="500"
                                label="{{ language.name | translate }}"></app-text-area>
            </div>
        </p-accordionTab>
    </p-accordion>
</div>
