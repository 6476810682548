import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {ValidationErrors} from '../../../common/validation-errors';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from '../../subsystem/profit-margin/profitMargin';
import {SimpleProfitMarginSource} from './simple-profit-margin-source';

@Component({
    selector: 'app-simple-profit-margin-source',
    templateUrl: './simple-profit-margin-source.component.html',
    styleUrls: ['./simple-profit-margin-source.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleProfitMarginSourceComponent {

    @Input()
    groupProfitMargins: SimpleProfitMarginSource;

    @Input()
    profitMargins: SimpleProfitMarginSource;

    @Input()
    suppliers: SupplierInterface[];

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    validationPrefix: string;

    @Input()
    disabled: boolean;

    @Output()
    readonly onSetAllClicked = new EventEmitter<string>();

    setAllValue: string;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    setAllValues(): void {
        this.setAllValue = normalizeProfitMarginValueString(this.setAllValue);
        const validator = MultiValidator.of('error.simpleProfitMarginSourceDto.supplierProfitMargins')
            .withSizeValidator(0, 250)
            .withCustomValidator(value => {
                if (typeof value !== 'string') {
                    return undefined;
                }
                return isValidProfitMarginValueString(value) ? undefined : 'pattern_not_matched';
            });

        this.validationErrors['setAllInput'] = validator.validate(this.setAllValue);

        if (this.validationErrors['setAllInput'] == undefined) {
            for (let supplier of this.suppliers) {
                this.profitMargins.supplierProfitMargins[supplier.id] = this.setAllValue;
                this.validationErrors[`${this.validationPrefix}supplierProfitMargins[${supplier.id}]`] = undefined;
            }
        }
        this.onSetAllClicked.emit(this.setAllValue);
        this.changeDetector.markForCheck();
    }
}
