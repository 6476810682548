import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {mergeMap, share} from 'rxjs/operators';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {MaterialType} from '../../../common/enums/MaterialType';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {AbstractProfitMarginComponent, HasSaveTable} from '../base-profit-margin.component';
import {ProfitMarginTarget} from './profit-margin-target';
import {ProfitMarginService} from './profit-margin.service';
import {ProfitMargin} from './profitMargin';
import {ProfitMarginApi} from './profitMarginApi';

@Component({
    selector: 'app-profit-margin',
    templateUrl: './profit-margin.component.html',
    styleUrls: ['../../../common/addon-profit-margin/base-addon-profit-margin.component.css', '../../shared-styles.css'],
    providers: [ProfitMarginService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfitMarginComponent extends AbstractProfitMarginComponent<ProfitMargin, ProfitMarginService>
    implements OnInit, OnChanges, HasSaveTable {

    @Input() subsystemGroupId: number;
    @Input() subsystemId: number;
    @Input() clientGroupId: number;
    @Input() clientId: number;
    @Input() userCanEdit: boolean;
    @Input() globalMarginUpdateButtonId: string;
    @Input() globalMarginUpdateInputId: string;
    @Input() windowSystemTypeGroups: ProductTypeGroup[];
    @Input() target: ProfitMarginTarget = ProfitMarginTarget.SYSTEM;
    @ViewChild('dt') dt: DataTable;
    filterMaterial: Observable<SelectItem[]>;
    filterSupplier: SelectItem[];
    selectedSuppliers: string[] = [];
    showTable: boolean;
    possibleTargets = ProfitMarginTarget;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, ProfitMarginService, 'PRICE_MARGINS', 'PriceMargins');
        this.showTable = false;
    }

    getDatatable(): DataTable {
        return this.dt;
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterMaterial = this.translatedSelectItemService.buildSortedDropdown(MaterialType, 'MATERIAL.', '');
        if (!this.entityType) {
            throw new Error('Component is not properly initialized. Neither subsystem nor client group id is provided.');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const entityTypeChange = changes['entityType'];
        if (entityTypeChange != undefined) {
            switch (entityTypeChange.currentValue) {
                case 'subsystemGroup':
                    this.itemService.setApi(ProfitMarginApi.SUBSYSTEM_GROUP_ALL, ProfitMarginApi.SUBSYSTEM_GROUP);
                    break;
                case 'subsystem':
                    this.itemService.setApi(ProfitMarginApi.SUBSYSTEM_ALL, ProfitMarginApi.SUBSYSTEM);
                    break;
                case 'clientGroup':
                    this.itemService.setApi(ProfitMarginApi.CLIENT_GROUP_ALL, ProfitMarginApi.CLIENT_GROUP);
                    break;
                case 'client':
                    this.itemService.setApi(ProfitMarginApi.CLIENT_ALL, ProfitMarginApi.CLIENT);
                    break;
            }
        }
        if ('subsystemGroupId' in changes || 'subsystemId' in changes || 'clientGroupId' in changes || 'clientId' in changes) {
            this.loadItems(true);
        }
    }

    protected loadItems(keepProfitMargins = false) {
        let filters = {};
        if (this.subsystemGroupId != undefined) {
            filters['subsystemGroupId'] = {value: this.subsystemGroupId.toString()};
        }
        if (this.subsystemId != undefined) {
            filters['subsystemId'] = {value: this.subsystemId.toString()};
        }
        if (this.clientGroupId != undefined) {
            filters['clientGroupId'] = {value: this.clientGroupId.toString()};
        }
        if (this.clientId != undefined) {
            filters['clientId'] = {value: this.clientId.toString()};
        }
        if (this.windowSystemTypeGroups != undefined) {
            filters['windowSystemTypeGroup'] = {value: this.windowSystemTypeGroups};
        }
        if (this.target != undefined) {
            filters['target'] = {value: this.target};
        }
        this.itemService.getAllItems(filters).subscribe({
            next: data => {
                const usedSupplierNames = new Set<string>();
                data.data.forEach(record => {
                    usedSupplierNames.add(record.supplierName[this.userLang]);
                    if (this.itemList != undefined && keepProfitMargins) {
                        let old = this.itemList.find(o => o.id == record.id);
                        record.value = old.value;
                        record.valueString = old.valueString;
                    }
                });
                this.filterSupplier = [];
                for (let supplierName of usedSupplierNames) {
                    this.filterSupplier.push({
                        label: supplierName,
                        value: supplierName
                    });
                }
                this.itemList = data.data;
                this.totalRecords = data.totalRecords;
                this.showTable = true;
                this.changeDetector.markForCheck();
            },
            error: error => {
                console.error('ProfitMargin' + ' `getAllItems` error:', error);
                this.setErrors(error);
            },
            complete: () => {
                console.info('ProfitMargin' + ' `getAllItems` completed!');
                this.hideDataLoadingIndicator();
            }
        });
    }

    public getNewItem() {
        return new ProfitMargin();
    }

    loadEdited(event: { data: ProfitMargin }) {
        this.editedItem = this.getNewItem();
        let rowData = event ? event.data : this.editedRowDataRef;
        this.editedItem.id = rowData.id;
        this.editedItem.systemId = rowData.systemId;
        switch (this.entityType) {
            case 'subsystemGroup':
                this.editedItem.subsystemGroupId = this.subsystemGroupId;
                break;
            case 'subsystem':
                this.editedItem.subsystemId = this.subsystemId;
                break;
            case 'clientGroup':
                this.editedItem.clientGroupId = this.clientGroupId;
                break;
            case 'client':
                this.editedItem.clientId = this.clientId;
                break;
        }
        rowData.value = undefined;
        if (rowData.valueString != undefined) {
            this.editedItem.valueString = rowData.valueString;
        }
    }

    public saveTable(id?: number): Observable<void> {
        if (id != undefined) {
            switch (this.entityType) {
                case 'subsystemGroup':
                    this.subsystemGroupId = id;
                    break;
                case 'subsystem':
                    this.subsystemId = id;
                    break;
                case 'clientGroup':
                    this.clientGroupId = id;
                    break;
                case 'client':
                    this.clientId = id;
                    break;
            }
        }
        this.composeEditedList();
        const observable = this.itemService.save(this.editedList, this.target).pipe(
            mergeMap(() => {
                if ((this.subsystemGroupId || this.clientGroupId) && this.pushToGroupMembers) {
                    return this.itemService.overrideGroupMemberMargins(this.editedList, this.target);
                }
                return of<void>(undefined);
            }),
            share());
        observable.subscribe({
                error: error => {
                    console.error('ProfitMargin `saveTable` error:', error);
                    this.setErrors(error);
                },
                complete: () => {
                    this.unselectRow();
                    console.info('ProfitMargin `saveTable` completed!');
                }
            });
        return observable;
    }

    handleSupplierFilterChange(suppliers: string[]): void {
        this.selectedSuppliers = suppliers;
        this.getDatatable().filter(suppliers, 'supplierName.' + this.userLang, 'in');
    }
}
