<div>
    <div class="new-form-row">
        <div class="new-form-field">
            <div class="new-form-field-label-container">
                <div>
                    <label [attr.for]="'complaintType-' + position.offerPositionId">{{ 'OFFER.COMPLAINT.FORM.COMPLAINT_TYPE_LABEL' | translate }}*</label>
                </div>
                <div *ngIf="validationErrors['complaintType-' + position.offerPositionId] != undefined"
                     class="new-form-field-label-error-spacer"></div>
            </div>
            <div class="new-form-field-input-container">
                <p-multiSelect inputId="{{ 'complaintType-' + position.offerPositionId }}"
                               [options]="complaintTypeItems | async" defaultLabel="..." [maxSelectedLabels]="5"
                               appendTo="body" [displaySelectedLabel]="true" [ngModel]="position.complaintTypes"
                               (ngModelChange)="handleComplaintTypeChange($event)"></p-multiSelect>
                <ng-container *ngIf="validationErrors['complaintType-' + position.offerPositionId] != undefined">
                    <span class="new-form-field-error-icon new-form-field-error-icon-select material-icons">error</span>
                    <div class="new-form-field-error-message">
                        {{ validationErrors['complaintType-' + position.offerPositionId] | translate }}
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <div class="new-form-row">
        <app-input-number inputId="{{ 'quantity-' + position.offerPositionId }}"
                          label="{{ 'OFFER.COMPLAINT.FORM.QUANTITY' | translate }}"
                          [(validationMessageKey)]="validationErrors['quantity-' + position.offerPositionId]"
                          [min]="1" [max]="9999999"
                          [(ngModel)]="position.quantity"
                          [required]="true"></app-input-number>
    </div>

    <div class="new-form-row complaint-details-other-info">
        <app-text-area inputId="{{ 'positionDescription-' + position.offerPositionId }}"
                       label="{{ 'OFFER.COMPLAINT.FORM.DESCRIPTION' | translate }}" [disabled]="isDescriptionDisabled()"
                       [(validationMessageKey)]="validationErrors['positionDescription-' + position.offerPositionId]" [maxlength]="1024"
                       [(ngModel)]="position.description"></app-text-area>
    </div>

    <div *ngIf="isTranslatedDescriptionVisible()" class="new-form-row complaint-details-other-info">
        <app-text-area inputId="{{ 'translatedDescription-'  + position.offerPositionId }}" label="{{ 'OFFER.COMPLAINT.FORM.TRANSLATED_DESCRIPTION' | translate }}"
                       [(validationMessageKey)]="validationErrors['translatedDescription-' + position.offerPositionId]" [maxlength]="1024"
                       [(ngModel)]="position.translatedDescription" [required]="isTranslatedDescriptionRequired()"></app-text-area>
    </div>

    <div class="new-form-row">
        <app-input-text inputId="{{ 'movieUrl-' + position.offerPositionId }}"
                        label="{{ 'OFFER.COMPLAINT.FORM.MOVIE_URL' | translate }}"
                        [(validationMessageKey)]="validationErrors['movieUrl-' + position.offerPositionId]" [maxlength]="255"
                        [(ngModel)]="position.movieUrl">

            <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                <app-field-label-with-button [label]="label"  buttonLabel="{{ 'OFFER.COMPLAINT.FORM.SHOW_GUIDE' | translate }}"
                                             icon="info" (onButtonClick)="showMovieInstructionDialog()"></app-field-label-with-button>
            </ng-template>
        </app-input-text>
    </div>

    <div class="new-form-row">
        <app-file-upload #movieInput inputId="{{ 'movieFile-' + position.offerPositionId }}"
                         label="{{ 'OFFER.COMPLAINT.FORM.MOVIE_FILE' | translate }}"
                         [(validationMessageKey)]="validationErrors['movieFile-' + position.offerPositionId]"
                         addButtonLabel="OFFER.COMPLAINT.FORM.SELECT_MOVIE_UPLOAD"
                         changeButtonLabel="OFFER.COMPLAINT.FORM.SELECT_MOVIE_UPLOAD"
                         deleteButtonLabel="OFFER.COMPLAINT.FORM.DELETE_FILE_UPLOAD"
                         [image]="false" accept="*.*" [ngModel]="movieFile"
                         (ngModelChange)="handleMovieFileChanged($event)">
            <ng-template pTemplate="download">
                <div *ngIf="movieFile == undefined && position.movieFile" class="new-form-row">
                    <a href="#"
                       (click)="handleMovieDownloadClick()">{{ 'OFFER.COMPLAINT.FORM.PREVIEW_FILE' | translate }}</a>
                </div>
                <div *ngIf="movieFile != undefined" class="new-form-row">
                    <a href="#"
                       (click)="movieInput.downloadFile()">{{ 'OFFER.COMPLAINT.FORM.PREVIEW_FILE' | translate }}</a>
                </div>
            </ng-template>
        </app-file-upload>
    </div>

    <div class="new-form-row">
        <app-input-text inputId="{{ 'photoUrl-' + position.offerPositionId }}"
                        label="{{ 'OFFER.COMPLAINT.FORM.PHOTO_URL' | translate }}"
                        [(validationMessageKey)]="validationErrors['photoUrl-' + position.offerPositionId]" [maxlength]="255"
                        [(ngModel)]="position.photoUrl">

            <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
                <app-field-label-with-button label="{{ label }}" buttonLabel="{{ 'OFFER.COMPLAINT.FORM.SHOW_GUIDE' | translate }}"
                                             icon="info" (onButtonClick)="showPhotoInstructionDialog()"></app-field-label-with-button>
            </ng-template>
        </app-input-text>
    </div>

    <div class="new-form-row">
        <app-file-upload #photoInput inputId="{{ 'photoFile-' + position.offerPositionId }}"
                         label="{{ 'OFFER.COMPLAINT.FORM.PHOTO_FILE' | translate }}"
                         [(validationMessageKey)]="validationErrors['photoFile-' + position.offerPositionId]"
                         addButtonLabel="OFFER.COMPLAINT.FORM.SELECT_PHOTO_UPLOAD"
                         changeButtonLabel="OFFER.COMPLAINT.FORM.SELECT_PHOTO_UPLOAD"
                         deleteButtonLabel="OFFER.COMPLAINT.FORM.DELETE_FILE_UPLOAD"
                         [image]="false" accept="*.*" [ngModel]="photoFile"
                         (ngModelChange)="handlePhotoFileChanged($event)">
            <ng-template pTemplate="download">
                <div *ngIf="photoFile == undefined && position.photoFile" class="new-form-row">
                    <a href="#"
                       (click)="handlePhotoDownloadClick()">{{ 'OFFER.COMPLAINT.FORM.PREVIEW_FILE' | translate }}</a>
                </div>
                <div *ngIf="photoFile != undefined" class="new-form-row">
                    <a href="#"
                       (click)="photoInput.downloadFile()">{{ 'OFFER.COMPLAINT.FORM.PREVIEW_FILE' | translate }}</a>
                </div>
            </ng-template>
        </app-file-upload>
    </div>
</div>