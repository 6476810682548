import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import {DataTable} from 'primeng/datatable';
import {ConfigAddonParentInfo} from '../../../../../../../window-designer/entities/ConfigAddonParentInfo';
import {Permissions} from "../../../../../../auth/permission.service";
import {UserUiConfigService} from "../../../../../../auth/uiconfig/userUiConfig.service";
import {ButtonWithMenuElementSelectedEvent} from "../../../../../../common/button-with-menu/button-with-menu-event";
import {MenuElement, MenuElementBuilder} from "../../../../../../common/button-with-menu/MenuElement";
import {MenuType} from "../../../../../../common/button-with-menu/MenuType";
import {PaginatorRowsPerPageOptions} from "../../../../../../common/crud-common/paginatorRowsPerPageOptions";
import {OfferStatus} from '../../../../../../common/enums/OfferStatus';
import {ExchangeService} from "../../../../../../common/exchange.service";
import {OfferStatusProvider} from "../../../../../../common/offerStatusProvider";
import {DataTableColumn} from "../../../../../../common/service/data.table.column";
import {Currencies} from '../../../../../../currencies';
import {TristateCheckboxState} from "../../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {Supplier} from "../../../../../supplier/supplier";
import {isWindowPositionType, PositionType} from '../../../../AbstractPosition';
import {Offer} from "../../../../offer";
import {AssemblyData} from "../add-assembly/assembly-data";
import {BulkAddonData} from "../add-bulk-addon-position/bulk-addon-data";
import {TransportData} from '../add-transport/transport-data';
import {Position, PositionSortGroup} from "../position";
import {PositionTableData} from "./position-list-table-data";

class ActionEvent {
    action: string;
    position: Position;
}

@Component({
    selector: 'app-position-list-table',
    templateUrl: './position-list-table.component.html',
    styleUrls: ['../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PositionListTableComponent implements OnInit, OnChanges {

    static readonly ROWS_PER_PAGE_PROPERTY_KEY = "rowsPerPage";
    private static readonly VIEW_NAME = 'PositionListTableComponent';

    @Input()
    tableData: PositionTableData;
    @Input()
    supplier: Supplier;
    @Input()
    seqNum: number;
    @Input()
    columns: DataTableColumn[];
    @Input()
    selectedCurrency: Currencies;
    @Input()
    userLang: string;
    @Input()
    showFilters = false;
    @Input()
    offer: Offer;
    @Input()
    canEdit: boolean;
    @Input()
    isComplaint: boolean;
    @Output()
    onRowDblclick = new EventEmitter<any>();
    @Output()
    selectionChange = new EventEmitter<any>();
    @Output()
    selectedRowCountChange = new EventEmitter<any>();
    @Output()
    onLazyLoad = new EventEmitter<any>();
    @Output()
    onActionClicked = new EventEmitter<ActionEvent>();

    limitedRowsPerPageOptions = PaginatorRowsPerPageOptions.limitedValues;

    @ViewChild('dataTable', {static: true})
    dataTable: DataTable;

    menuType = MenuType;
    positionType = PositionType;
    refreshCounter = 0;

    columnByField: { [field: string]: DataTableColumn } = {};

    constructor(private exchangeService: ExchangeService,
                private permissions: Permissions,
                private userUiConfigService: UserUiConfigService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.tableData.rowsPerPage = this.getRowsPerPageValue();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('columns' in changes) {
            const change = changes['columns'];
            if (change.currentValue != undefined) {
                this.columnByField = {};
                for (let column of change.currentValue) {
                    this.columnByField[column.field] = column;
                }
            }
        }
        if ('tableData' in changes) {
            const change = changes['tableData'];
            if (!change.isFirstChange()) {
                this.dataTable.onLazyLoad.emit(this.dataTable.createLazyLoadMetadata());
            }
        }
    }

    private getRowsPerPageValue(): number {
        let value = this.userUiConfigService.getConfigForTheView(PositionListTableComponent.VIEW_NAME, this.getPropertyKeyForSaveConfig());
        return value ? value : PaginatorRowsPerPageOptions.defaultValue;
    }

    getSupplier(): Supplier {
        return this.supplier;
    }

    getDataTable(): DataTable {
        return this.dataTable;
    }

    refreshTable(clearRowCache: boolean): void {
        if (clearRowCache) {
            this.refreshCounter += 1;
        }
        this.changeDetector.markForCheck();
    }

    emitRowDblclick(event: any): void {
        this.onRowDblclick.emit(event);
    }

    handleRowKeyDown(event: any): void {
        if (event.originalEvent.code === 'Space') {
            event.originalEvent.preventDefault();
            this.selectItem(event.data);
        }
    }

    emitSelectionChange(event: any): void {
        this.selectionChange.emit(event);
    }

    emitLazyLoad(event: any): void {
        if (this.tableData.rowsPerPage !== event.rows) {
            this.tableData.rowsPerPage = event.rows;
            this.saveRowsPerPageValue();
        }
        this.onLazyLoad.emit(event);
    }

    private saveRowsPerPageValue() {
        this.userUiConfigService.saveConfigForTheView(PositionListTableComponent.VIEW_NAME,
            this.getPropertyKeyForSaveConfig(), this.tableData.rowsPerPage);
    }

    private getPropertyKeyForSaveConfig() {
        let propertyKey = PositionListTableComponent.ROWS_PER_PAGE_PROPERTY_KEY + "_";
        switch (this.tableData.sortGroup) {
            case PositionSortGroup.SYSTEM:
                propertyKey += this.getSupplier().id;
                break;
            case PositionSortGroup.OWN_ADDON:
                propertyKey += "ownAddon";
                break;
            case PositionSortGroup.ASSEMBLY:
                propertyKey += 'assembly';
                break;
        }
        return propertyKey;
    }

    actionClicked(action: string, position: Position): void {
        this.tableData.selectedPosition = position;
        this.onActionClicked.emit({action: action, position: position});
    }

    selectAllChange(state: TristateCheckboxState): void {
        this.tableData.allSelectedState = state;
        this.tableData.selectedItems = [];
        if (state === TristateCheckboxState.CHECKED) {
            this.tableData.selectedItems.push(...this.tableData.positions.filter(pos => pos.type !== PositionType.OFFER_CHARGE));
        }
        this.selectedRowCountChange.emit();
    }

    isSelectedItem(item: Position): boolean {
        return this.tableData.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: Position): void {
        let index = this.tableData.selectedItems.indexOf(item);
        if (index > -1) {
            this.tableData.selectedItems.splice(index, 1);
        } else {
            this.tableData.selectedItems.push(item);
        }
        if (this.tableData.selectedItems.length === this.tableData.positions.filter(pos => pos.type !== PositionType.OFFER_CHARGE).length) {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED;
        } else if (this.tableData.selectedItems.length === 0) {
            this.tableData.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else {
            this.tableData.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
        this.selectedRowCountChange.emit();
    }

    getQuantityTypeForPosition(position: Position): string {
        if (isWindowPositionType(position.type) ||
            position.type === PositionType.CONFIG_SYSTEM ||
            position.type === PositionType.CONFIGURABLE_ADDON ||
            position.type === PositionType.OFFER_CHARGE ||
            position.type === PositionType.GATE_SYSTEM
        ) {
            return 'ADDONS.QUANTITY_TYPE.ABBREVIATION.PIECE';
        }
        if (position.type === PositionType.BULK_ADDON) {
            let addonFromData: BulkAddonData = JSON.parse(position.data);
            return 'ADDONS.QUANTITY_TYPE.ABBREVIATION.' + addonFromData.quantityType;
        }
        if (position.type === PositionType.ASSEMBLY) {
            let assemblyData: AssemblyData = JSON.parse(position.data);
            return 'ASSEMBLY.UNITS.' + assemblyData.assemblyUnit;
        }
        if (position.type === PositionType.TRANSPORT) {
            let transportData: TransportData = JSON.parse(position.data);
            return 'ASSEMBLY.UNITS.' + transportData.assemblyUnit;
        }
        console.error("Cannot find proper position type quantity. Position type is " + position.type);
        return "";
    }

    formatPriceInSelectedCurrency(price: number, pricingCurrency: Currencies, applySubsystemManualExchangeRate = false): string {
        if (price == null) {
            return '';
        }
        if (this.selectedCurrency === this.offer.currency) {
            return this.formatPriceInOfferCurrency(price, pricingCurrency, applySubsystemManualExchangeRate);
        }
        if (pricingCurrency !== this.offer.currency) {
            return ExchangeService.formatPriceInCurrency(
                this.exchangeService.getPriceInCurrency(price, pricingCurrency, this.selectedCurrency), this.selectedCurrency);
        }
        // price is in offer currency, convert to pln using offer exchange rate first
        const plnPriceToOfferCurrencyRatio = (applySubsystemManualExchangeRate && this.offer.subsystemManualExchangeRate)
            ? this.offer.subsystemManualExchangeRate
            : this.offer.exchangeRate;
        return ExchangeService.formatPriceInCurrency(
            this.exchangeService.getPriceInCurrency(price, pricingCurrency, this.selectedCurrency, plnPriceToOfferCurrencyRatio),
            this.selectedCurrency);
    }

    formatPriceInOfferCurrency(price: number, pricingCurrency: Currencies, applySubsystemManualExchangeRate = false): string {
        if (price == null) {
            return '';
        }
        if (pricingCurrency === this.offer.currency) {
            return ExchangeService.formatPriceInCurrency(price.toFixed(2), pricingCurrency);
        }
        const plnPriceToOfferCurrencyRatio = (applySubsystemManualExchangeRate && this.offer.subsystemManualExchangeRate)
            ? this.offer.subsystemManualExchangeRate
            : this.offer.exchangeRate;
        return ExchangeService.formatPriceInCurrency(
            this.exchangeService.getPriceInCurrency(price, pricingCurrency, this.offer.currency, undefined, plnPriceToOfferCurrencyRatio),
            this.offer.currency);
    }

    private canMovePositionUp(position: Position): boolean {
        return (OfferStatusProvider.isOfferStatus(this.offer.status) || this.offer.status === OfferStatus.CORRECTION)
            && position.printOrder !== 1;
    }

    private canMovePositionDown(position: Position): boolean {
        return (OfferStatusProvider.isOfferStatus(this.offer.status) || this.offer.status === OfferStatus.CORRECTION)
            && position.printOrder !== this.tableData.totalRecords;
    }

    private canEditPosition(position: Position) {
        if (this.canEdit) {
            if (position.sortGroup !== PositionSortGroup.SYSTEM) {
                return this.permissions.isPermitted({roles: ['ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']});
            }
            return true;
        }
        return false;
    }

    private canChangePrice(position: Position): boolean {
        return position.type !== PositionType.TRANSPORT;
    }

    private canEditWithValidationDisabled(position: Position): boolean {
        if (!this.canEditPosition(position)) {
            return false;
        }
        if (position.validationDisabled && !this.permissions.isKoordynator() && !this.permissions.isOpiekun()) {
            return false;
        }
        return true;
    }

    buildRowActionBuilder(position: Position): () => MenuElement[] {
        return () => this.buildRowActions(position);
    }

    buildRowActions(position: Position): MenuElement[] {
        if (this.isComplaint) {
            return this.canEdit ? this.buildRowActionsForComplaint(position) : [];
        }

        let positionSuffix = this.getPositionSuffix(position);
        let rowActions = [
            new MenuElementBuilder().setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.COMMENTS')
                .setIdentifier('COMMENTS' + positionSuffix).build()
        ];
        if (position.highestMessageSeverity != null || position.pricingOutdated) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.MESSAGES').setIdentifier('POSITION_MESSAGES' + positionSuffix)
                .setDisabled(this.isPositionAlwaysReadOnly(position)).build());
        }

        if (!this.canEdit) {
            return rowActions;
        }
        if (this.canChangePrice(position) && this.canEditWithValidationDisabled(position)) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.PRICE_CHANGE').setIdentifier('PRICE_CHANGE' + positionSuffix)
                .setDisabled(this.isPositionAlwaysReadOnly(position)).build());
        }
        if (this.canEditPosition(position)) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.DELETE').setIdentifier('DELETE' + positionSuffix)
                .setDisabled(position.type === PositionType.OFFER_CHARGE).build());
        }
        if (this.canEditWithValidationDisabled(position)) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.COPY').setIdentifier('COPY' + positionSuffix)
                .setDisabled(this.isPositionAlwaysReadOnly(position)).build());
        }
        if (this.canEditWithValidationDisabled(position)) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.SHOW_DESCRIPTION').setIdentifier('SHOW_DESCRIPTION' + positionSuffix)
                .setDisabled(this.isPositionAlwaysReadOnly(position))
                .build());
        }
        const canMovePositionUp = this.canMovePositionUp(position);
        const canMovePositionDown = this.canMovePositionDown(position);
        if (canMovePositionUp) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.MOVE_UP').setIdentifier('MOVE_UP' + positionSuffix)
                .setDisabled(this.isPositionAlwaysReadOnly(position)).build());
        }
        if (canMovePositionDown) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.MOVE_DOWN').setIdentifier('MOVE_DOWN' + positionSuffix)
                .setDisabled(this.isPositionAlwaysReadOnly(position)).build());
        }
        if (canMovePositionUp || canMovePositionDown) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.SET_PRINT_ORDER')
                .setIdentifier('SET_PRINT_ORDER' + positionSuffix)
                .setDisabled(this.isPositionAlwaysReadOnly(position))
                .build());
        }
        if (this.permissions.canDisableOfferValidation() && !position.validationDisabled) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.DISABLE_VALIDATION')
                .setIdentifier('DISABLE_VALIDATION' + positionSuffix)
                .build());
        }

        return rowActions;
    }

    buildRowActionsForComplaint(position: Position): MenuElement[] {
        let rowActions = [];
        let positionSuffix = this.getPositionSuffix(position);

        if (this.canEditPosition(position)) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.DELETE').setIdentifier('DELETE' + positionSuffix).build());
        }

        rowActions.push(new MenuElementBuilder()
            .setTranslationKey('OFFER.POSITIONS.ACTIONS.TOOLTIPS.COMMENTS').setIdentifier('COMMENTS' + positionSuffix).build());

        return rowActions;
    }

    private getPositionSuffix(position: Position) {
        return '___' + position.id;
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent) {
        let data = event.identifier.split('___');
        let action = data[0];
        let positionId = +data[1];
        let found = this.tableData.positions.find(pos => pos.id === positionId);
        if (found) {
            this.actionClicked(action, found);
        } else {
            console.error("Action called for non existing positionId" + positionId);
        }
    }

    isFilterable(defaultFilterable: boolean) {
        return defaultFilterable && this.tableData.showFilters;
    }

    anyPositionHasParent() {
        return this.tableData.positions.findIndex(position => position.parentOfferPositionId != undefined) !== -1;
    }

    rowTrackBy = (index: number, position: Position) => {
        return `${position.id}_${position.printOrder}_${this.refreshCounter}`;
    };

    showUnreadCommentIcon(offerPosition: Position): boolean {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return offerPosition.hasCommentUnreadByVenska;
        }
        return offerPosition.hasCommentUnreadBySubsystem;
    }

    showCommentExistsIcon(offerPosition: Position): boolean {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            return !offerPosition.hasCommentUnreadByVenska && offerPosition.hasComment;
        }
        return !offerPosition.hasCommentUnreadBySubsystem && offerPosition.hasComment;
    }

    getDimensionsForConfigAddonFromDeletedWindow(offerPosition: Position): string {
        let parentElementDescription: ConfigAddonParentInfo[] = JSON.parse(offerPosition.data).parentElementDescription;
        if (parentElementDescription == null || parentElementDescription.length === 0) {
            return offerPosition.dimensions;
        }
        let element = parentElementDescription[parentElementDescription.length - 1];
        let application = this.translate.instant('OFFER.POSITIONS.ADDON_CONFIG.PARENT_APPLICATIONS.' + element.application);
        let description = `${element.name[this.userLang] || ''} ${element.dimensions}`;
        return `${application} ${description}`;
    }

    isPositionAlwaysReadOnly(position: Position): boolean {
        return position.type === PositionType.OFFER_CHARGE || position.configurableAddonDefinitionType != null;
    }
}
