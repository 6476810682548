<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(ProfileField.NAME)"
                            inputId="name" label="{{ 'PROFILE.FORM.NAME' | translate }}" validationKeyPrefix="names"
                            [disabled]="fieldUsage.disabled(ProfileField.NAME)"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.names"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(ProfileField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'PROFILE.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(ProfileField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>

    <div *ngIf="fieldUsage.show(ProfileField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'PROFILE.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(ProfileField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50" [required]="true"
                        [(ngModel)]="item.symbol"></app-input-text>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(ProfileField.TYPE)">
        <app-select inputId="type" label="{{ 'PROFILE.FORM.TYPE.HEADER' | translate }}"
                    [disabled]="fieldUsage.disabled(ProfileField.TYPE)"
                    [(validationMessageKey)]="validationErrors['type']" [options]="types" [required]="true"
                    [(ngModel)]="item.type"></app-select>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(ProfileField.UNIT_WEIGHT)">
        <app-input-number inputId="unitWeight" label="{{ 'PROFILE.FORM.UNIT_WEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(ProfileField.UNIT_WEIGHT)"
                          [(validationMessageKey)]="validationErrors['unitWeight']" [min]="0" [max]="9999"
                          [required]="true" [(ngModel)]="item.unitWeight"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(ProfileField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'PROFILE.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(ProfileField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(ProfileField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'PROFILE.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(ProfileField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(ProfileField.WIDTH_EXTERNAL)">
        <app-composition-distances-form [compositionDistances]="item.compositionDistances"
                                        [disabled]="fieldUsage.disabled(ProfileField.WIDTH_EXTERNAL)"
                                        [fieldName]="'compositionDistances'"
                                        [label]="'PROFILE.FORM.WIDTH_EXTERNAL'"
                                        [validationErrors]="validationErrors"
                                        [profileType]="item.type"></app-composition-distances-form>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(ProfileField.IMAGE_SECTION)">
        <p-accordion>
            <p-accordionTab header="{{ 'PROFILE.FORM.IMAGE_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(ProfileField.IMAGE_SECTION)"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="50000" [maxWidth]="400" [maxHeight]="400"
                                 [addButtonLabel]="'PROFILE.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'PROFILE.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'PROFILE.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
