import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {LazyLoadEvent} from "primeng/api/lazyloadevent";
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogPropertyTarget} from "../CatalogPropertyTarget";
import {CatalogProperty} from './catalog-property';
import {CatalogPropertyService} from './catalog-property.service';

@Component({
    selector: 'app-catalog-property',
    templateUrl: './catalog-property.component.html',
    styleUrls: ['./catalog-property.component.css', '../../shared-styles.css'],
    providers: [CatalogPropertyService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogPropertyComponent extends CrudCommonComponent<CatalogProperty, CatalogPropertyService> implements OnInit {

    @Input()
    target: CatalogPropertyTarget;

    item: CatalogProperty;
    validateDataStep: WizardStepValidator;
    validationErrors: { [field: string]: string } = {};

    readonly STEPS = {
        DATA: 'DATA'
    };

    @ViewChild('dt') datatable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, true, CatalogPropertyService, 'CATALOG_PROPERTY', 'CatalogProperty');
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.item = this.getNewItem();
    }

    loadItemsLazy(event: LazyLoadEvent): void {
        event.filters['target'] = {value: this.target};
        super.loadItemsLazy(event);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.item = new CatalogProperty(this.target);
        this.getProperty(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.item = new CatalogProperty(this.target);
            this.getProperty(this.selectedItem.id);
        }
    }

    getNewItem(): CatalogProperty {
        return new CatalogProperty(this.target);
    }

    getProperty(propertyId: number) {
        this.itemService.getItem(propertyId).subscribe({
            next: data => {
                this.item = data;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                if (!this.copyMode) {
                    // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                    this.focusOnElementWithId(this.getFirstInputId());
                } else {
                    this.item.id = undefined;
                }
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getItem` completed!');
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'icon');

        this.validationErrors['sortIndex'] = MultiValidator.of('error.catalogPropertyDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 9999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validationErrors = {};
        if (this.validateForm()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item);
            } else {
                observable = this.itemService.saveCatalogProperty(this.item);
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = "sortIndex";
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
