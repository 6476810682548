<app-wizard #wizard header="{{ (isEditMode ? 'OFFER.COMPLAINT.CREATE.EDIT_HEADER' : 'OFFER.COMPLAINT.CREATE.CREATE_HEADER') | translate }}" (onCancel)="exitWizard()"
            (currentStepChange)="onStepChange()" (onComplete)="save()">

    <!-- FILL DETAILS STEP: -->
    <app-wizard-step label="{{ 'OFFER.COMPLAINT.CREATE.DETAILS' | translate }}" [id]="STEPS.DETAILS"
                     [validate]="validateDetailsStep" [displayHeader]="false">

        <div class="create-complaint create-complaint-second-header">
            <span>{{ 'OFFER.COMPLAINT.CREATE.DETAILS' | translate }}</span>
        </div>

        <div *ngIf="complaint != null" class="create-complaint new-form-600 complaint-details-form">
            <div class="new-form-row">
                <app-calendar inputId="deliveryDate" label="{{ 'OFFER.COMPLAINT.FORM.DELIVERY_DATE' | translate }}"
                              [(validationMessageKey)]="validationErrors['deliveryDate']" [required]="true"
                              [ngModel]="complaint.deliveryDate" [utc]="true"
                              (ngModelChange)="handleDeliveryDateChange($event)"></app-calendar>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="deliveryDestination"
                                label="{{ 'OFFER.COMPLAINT.FORM.DELIVERY_DESTINATION' | translate }}"
                                [(validationMessageKey)]="validationErrors['deliveryDestination']" [maxlength]="255"
                                [(ngModel)]="complaint.deliveryDestination" [required]="true"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-calendar inputId="sendToVerifyDate" label="{{ 'OFFER.COMPLAINT.FORM.SEND_TO_VERIFY_DATE' | translate }}"
                              [(validationMessageKey)]="validationErrors['sendToVerifyDate']" disabled="true" [utc]="true"
                              [(ngModel)]="complaint.sendToVerifyDate"></app-calendar>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="subsystemName" label="{{ 'OFFER.COMPLAINT.FORM.SUBSYSTEM' | translate }}"
                            [(validationMessageKey)]="validationErrors['subsystemId']" disabled="true"
                            [(ngModel)]="offer.subsystemName"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="offerNumber"
                                label="{{ 'OFFER.COMPLAINT.FORM.OFFER_NUMBER' | translate }}" disabled="true"
                                [(validationMessageKey)]="validationErrors['offerNumber']" [maxlength]="255"
                                [(ngModel)]="offer.offerNumber"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="alternateOfferNumber"
                                label="{{ 'OFFER.COMPLAINT.FORM.ALTERNATE_OFFER_NUMBER' | translate }}" disabled="true"
                                [(validationMessageKey)]="validationErrors['alternateOfferNumber']" [maxlength]="255"
                                [(ngModel)]="offer.alternateOfferNumber"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-calendar inputId="expectedShippingDate" label="{{ 'OFFER.COMPLAINT.FORM.EXPECTED_SHIPPING_DATE' | translate }}"
                              [minDate]="complaint.deliveryDate" [utc]="true"
                              [(validationMessageKey)]="validationErrors['expectedShippingDate']" [disabled]="isExpectedShippingDateDisabled()"
                              [(ngModel)]="complaint.expectedShippingDate"></app-calendar>
            </div>

            <div class="new-form-row complaint-details-other-info">
                <app-text-area inputId="description" label="{{ 'OFFER.COMPLAINT.FORM.DESCRIPTION' | translate }}"
                               [(validationMessageKey)]="validationErrors['description']" [maxlength]="1024" [disabled]="isDescriptionDisabled()"
                               [(ngModel)]="complaint.description" [required]="true"></app-text-area>
            </div>

            <div *ngIf="isTranslatedDescriptionVisible()" class="new-form-row complaint-details-other-info">
                <app-text-area inputId="translatedDescription" label="{{ 'OFFER.COMPLAINT.FORM.TRANSLATED_DESCRIPTION' | translate }}"
                               [(validationMessageKey)]="validationErrors['translatedDescription']" [maxlength]="1024"
                               [(ngModel)]="complaint.translatedDescription" [required]="true"></app-text-area>
            </div>
        </div>
    </app-wizard-step>

    <!-- POSITIONS STEP -->
    <app-wizard-step *ngIf="!isEditMode" label="{{ 'OFFER.COMPLAINT.CREATE.POSITIONS' | translate }}" [id]="STEPS.POSITIONS"
                     [validate]="validatePositionsStep" [displayHeader]="false">

        <div *ngIf="complaint != null" class="create-complaint new-form-600 complaint-details-form">
            <p-accordion>
                <ng-container *ngFor="let position of complaint.positions; let i = index">
                    <p-accordionTab header="{{ createPositionAccordionHeader(position) }}"
                                    [selected]="i==0">

                        <app-complaint-position-form [validationErrors]="validationErrors" [position]="position"
                                                     (onShowMovieInstruction)="showMovieInstructionDialog()"
                                                     (onShowPhotoInstruction)="showPhotoInstructionDialog()"
                                                     (onMovieFileChange)="handleMovieFileChange(position.offerPositionId, $event)"
                                                     (onPhotoFileChange)="handlePhotoFileChange(position.offerPositionId, $event)"></app-complaint-position-form>
                    </p-accordionTab>

                    <div *ngIf="hasPositionErrors(position.offerPositionId)" class="new-form-field-label-error-spacer"></div>
                    <div *ngIf="hasPositionErrors(position.offerPositionId)" class="new-form-field-error-message">
                        {{ 'OFFER.COMPLAINT.FORM.POSITION_HAS_ERRORS' | translate }}
                    </div>
                </ng-container>
            </p-accordion>
        </div>

        <app-info-dialog *ngIf="displayMovieInstructionDialog"
                         header="{{ 'OFFER.COMPLAINT.FORM.MOVIE_GUIDE.HEADER' | translate }}"
                         text="{{ 'OFFER.COMPLAINT.FORM.MOVIE_GUIDE.TEXT' | translate }}"
                         (onHide)="hideMovieInstructionDialog()"></app-info-dialog>

        <app-info-dialog *ngIf="displayPhotoInstructionDialog"
                         header="{{ 'OFFER.COMPLAINT.FORM.PHOTO_GUIDE.HEADER' | translate }}"
                         text="{{ 'OFFER.COMPLAINT.FORM.PHOTO_GUIDE.TEXT' | translate }}"
                         (onHide)="hidePhotoInstructionDialog()"></app-info-dialog>
    </app-wizard-step>

    <app-wizard-step *ngIf="!isEditMode" label="{{ 'OFFER.COMPLAINT.CREATE.STATUS' | translate }}" [id]="STEPS.STATUS"
                     [validate]="validateStatusStep">
        <div *ngIf="complaint != null" class="create-complaint complaint-details-form create-complaint-statuses-form">
            <div class="create-complaint-statuses">
                <div *ngFor="let transition of possibleStatuses" class="change-status-button-container">
                    <div [ngClass]="{ 'selected':selectedTransition === transition }" id="{{ 'transitionTo_' + transition.item.id }}"
                         class="change-status-button" (click)="handleStatusClicked(transition)">
                        <div class="change-status-button-label">
                            <span class="material-icons">{{transition.item.icon}}</span>
                            <span class="material-icons change-status-action-icon" (click)="showTransitionInfoDialog(transition, $event)">info</span>
                            <span>{{transition.item.label  | translate}}</span>
                        </div>
                    </div>
                 </div>
                <div *ngIf="hasStatusError()" class="new-form-field-label-error-spacer"></div>
                <div *ngIf="hasStatusError()" class="new-form-field-error-message">
                    {{validationErrors['status'] | translate}}
                </div>
            </div>
        </div>
    </app-wizard-step>
</app-wizard>

<app-info-dialog *ngIf="statusTransitionInfoDialogVisible"
                 header="{{ 'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS_TRANSITION_INFO.NEW_COMPLAINT_HEADER' | translate }}"
                 text="{{ getStatusTransitionInfoLabel() }}"
                 (onHide)="hideStatusTransitionDialog()"></app-info-dialog>
