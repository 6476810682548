import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ListOfIds} from '../../ListOfIds';
import {Complaint} from './complaint';
import {ComplaintPosition} from './complaint-position';
import {ComplaintStatus} from './complaint-status';

@Injectable()
export class ComplaintService {

    private readonly API_PATH = 'complaint';

    constructor(private http: HttpClient) {
    }

    save(complaint: Complaint): Observable<Complaint> {
        return this.http.post<object>(`${this.API_PATH}/create`, complaint)
            .pipe(mapItemToJson(Complaint));
    }

    saveComplaintPosition(complaintPosition: ComplaintPosition): Observable<void> {
        return this.http.post<void>(`${this.API_PATH}/saveComplaintPosition`, complaintPosition);
    }

    saveComplaintPositionMovie(complaintId: number, offerPositionId: number, file: File): Observable<HttpResponse<void>> {
        const formData = new FormData();
        formData.append('movie', file);
        return this.http.post<void>(`${this.API_PATH}/saveComplaintPositionMovie/${complaintId}/byOfferPositionId/${offerPositionId}`,
            formData, {observe: 'response'});
    }

    saveComplaintPositionPhoto(complaintId: number, offerPositionId: number, file: File): Observable<HttpResponse<void>> {
        const formData = new FormData();
        formData.append('photo', file);
        return this.http.post<void>(`${this.API_PATH}/saveComplaintPositionPhoto/${complaintId}/byOfferPositionId/${offerPositionId}`,
            formData, {observe: 'response'});
    }

    getComplaintPositionMovieUrl(complaintPositionId: number): Observable<string> {
        return this.http.get(`${this.API_PATH}/complaintPosition/${complaintPositionId}/movieUrl`, {responseType: 'text'});
    }

    getComplaintPositionPhotoUrl(complaintPositionId: number): Observable<string> {
        return this.http.get(`${this.API_PATH}/complaintPosition/${complaintPositionId}/photoUrl`, {responseType: 'text'});
    }

    getComplaint(id: number): Observable<Complaint> {
        return this.http.get<object>(`${this.API_PATH}/get/${id}`)
            .pipe(mapItemToJson(Complaint));
    }

    getComplaints(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
                  sortColumn: string, sortOrder: number): Observable<Listing<Complaint>> {
        let searchParams = new DataServiceHelper().prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>('complaint/getComplaints', {params: searchParams})
            .pipe(mapListingToJson(Complaint));
    }

    getComplaintPositionsByOfferPositionIds(complaintId: number, offerPositionIds: number[]): Observable<ComplaintPosition[]> {
        let listOfIds = new ListOfIds();
        listOfIds.ids = offerPositionIds;
        return this.http.post<object[]>(`${this.API_PATH}/getComplaintPositionsByOfferPositionIds/${complaintId}`, listOfIds)
            .pipe(map(result => result.map(ComplaintPosition.fromJSON)));
    }

    deleteComplaintPositions(complaintPositionIds: number[]): Observable<number[]> {
        let listOfIds = new ListOfIds();
        listOfIds.ids = complaintPositionIds;
        return this.http.post(`${this.API_PATH}/deleteComplaintPositions`, listOfIds, {observe: 'response'}).pipe(
            map(response => {
                return response.headers.get('AffectedSuppliers').split(',').map(id => +id);
            }));
    }

    deleteComplaint(complaintId: number): Observable<void> {
        return this.http.delete<void>(`${this.API_PATH}/deleteComplaint/${complaintId}`);
    }

    setStatus(complaintId: number, status: ComplaintStatus): Observable<void> {
        return this.http.post<void>(`${this.API_PATH}/setStatus/${complaintId}/${status.toString()}`, {});
    }

    getComplaintsContainingPositions(offerPositionIds: number[]): Observable<Complaint[]> {
        let listOfIds = new ListOfIds();
        listOfIds.ids = offerPositionIds;
        return this.http.post<object[]>(`${this.API_PATH}/getComplaintsContainingPositions`, listOfIds)
            .pipe(map(result => result.map(Complaint.fromJSON)));
    }
}
