<div class="error-message-container">
    <h1>{{ 'GENERAL-ERROR.ERROR' | translate }}</h1>
    <h2>{{ 'error.noPermissions' | translate }}</h2>
    <img src="{{getErrorImageUrl()}}">
    <app-simple-button *ngIf="canShowGoBackButton()" label="{{ 'NAVIGATION.ITEMS.BACK' | translate }}" icon="arrow_back"
                       type="action" (onClick)="goBack()"></app-simple-button>
    <app-simple-button label="{{ 'NAVIGATION.ITEMS.HOME' | translate }}" icon="menu" type="action"
                       (onClick)="redirectToMain()"></app-simple-button>
    <app-simple-button *ngIf="showLoginButton" label="{{ 'LOGIN.FORM.SUBMIT_BUTTON' | translate}}" icon="perm_identity"
                       type="action" (onClick)="goToLogin()"></app-simple-button>
</div>
