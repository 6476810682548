import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CatalogItemName} from '../../../../common/crud-common/catalog-item-name';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {AbstractGlazingPackageService} from '../abstract-glazing-package.service';
import {GlazingPackageTarget} from '../glazing-package';
import {WebshopGlazingPackage} from './webshop-glazing-package';

@Injectable()
export class WebshopGlazingPackageService extends AbstractGlazingPackageService<WebshopGlazingPackage> {

    static readonly API_URL = 'webshopGlazingPackages';

    constructor(http: HttpClient, dataServiceHelper: DataServiceHelper) {
        super(http, dataServiceHelper);
    }

    getApiUrl() {
        return WebshopGlazingPackageService.API_URL;
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        params['targets'] = `${GlazingPackageTarget[GlazingPackageTarget.WEBSHOP_GLAZING]}`;
        return this.http.get<CatalogItemName[]>(`${AbstractGlazingPackageService.COMMON_API_URL}/names`, {params: params});
    }
}
