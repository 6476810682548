export enum ConfigEditorField {
    QUANTITY = 'QUANTITY',
    SYSTEM = 'SYSTEM',
    DIM_1 = 'DIM_1',
    DIM_2 = 'DIM_2',
    DIM_3 = 'DIM_3',
    DIM_4 = 'DIM_4',
    DIM_5 = 'DIM_5',
    DIM_6 = 'DIM_6',
    MATERIAL = 'MATERIAL',
    COLOR = 'COLOR',
    DESCRIPTION = 'DESCRIPTION',

    DIM_1_GREATER_THAN = 'DIM_1_GREATER_THAN',
    DIM_1_LESS_THAN = 'DIM_1_LESS_THAN',
    DIM_2_GREATER_THAN = 'DIM_2_GREATER_THAN',
    DIM_2_LESS_THAN = 'DIM_2_LESS_THAN',
    DIM_3_GREATER_THAN = 'DIM_3_GREATER_THAN',
    DIM_3_LESS_THAN = 'DIM_3_LESS_THAN',
    DIM_4_GREATER_THAN = 'DIM_4_GREATER_THAN',
    DIM_4_LESS_THAN = 'DIM_4_LESS_THAN',
    DIM_5_GREATER_THAN = 'DIM_5_GREATER_THAN',
    DIM_5_LESS_THAN = 'DIM_5_LESS_THAN',
    DIM_6_GREATER_THAN = 'DIM_6_GREATER_THAN',
    DIM_6_LESS_THAN = 'DIM_6_LESS_THAN',
    AREA_GREATER_THAN = 'AREA_GREATER_THAN',
    AREA_LESS_THAN = 'AREA_LESS_THAN',
}
