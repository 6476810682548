import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {AbstractSellDealerDiscount} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount';
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class SubsystemGroupConfigurableAddonSellDealerDiscount extends AbstractSellDealerDiscount {
    systemId: number;
    systemName: MultilanguageField;

    static fromJSON(jsonObject: any): SubsystemGroupConfigurableAddonSellDealerDiscount {
        let newDiscount = new SubsystemGroupConfigurableAddonSellDealerDiscount();
        AbstractSellDealerDiscount.fromJSON(newDiscount, jsonObject);
        newDiscount.systemId = jsonObject['systemId'];
        newDiscount.systemName = jsonObject['systemName'];
        return newDiscount;
    }
}
