import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {GateControl} from "../../../../gate-painter/enums/gate-control.enum";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {GateEditorField} from '../../offer/gate-editor/gate-editor-field';
import {GateEditorFieldContentProvider} from '../../offer/gate-editor/gate-editor-field-content-provider';
import {GateEditorFieldStates} from '../../offer/gate-editor/gate-editor-field-states';
import {GateSidebarFieldImageService} from '../../offer/gate-editor/gate-sidebar/gate-sidebar-field-image.service';
import {SidebarFieldChangeEvent} from '../../offer/gate-editor/gate-sidebar/gate-sidebar.component';
import {CatalogItemType} from "../../offer/window-editor/sidebar/pricing/PricingItem";
import {SystemDefaultsService} from "../../offer/window-editor/system-defaults.service";
import {SubsystemGroupService} from '../../subsystem-group/subsystem-group.service';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {AddonCategoryGroup} from '../../window-system/addon-category-group/addon-category-group';
import {AddonCategoryGroupService} from '../../window-system/addon-category-group/addon-category-group.service';
import {AddonsService} from '../../window-system/addons/addons.service';
import {Color} from '../../window-system/color/color';
import {ColorService} from '../../window-system/color/color.service';
import {
    GateDesignerCatalogDependentOptionService
} from '../../window-system/gate-designer-catalog-dependent-option/data-form/gate-designer-catalog-dependent-option.service';
import {GatePanelTypeService} from '../../window-system/gate-panel-type/gate-panel-type.service';
import {GateSystem} from '../../window-system/gate-system/gate-system';
import {GateSystemService} from '../../window-system/gate-system/gate-system.service';
import {GateWallService} from "../../window-system/gate-wall/gate-wall.service";
import {RailSystemService} from '../../window-system/rail-system/rail-system.service';
import {GateSystemDefaultsState} from "../system-defaults/system-default-state";
import {SystemDefaultsComponent} from "../system-defaults/system-defaults.component";
import {GateSystemDefaults} from './gate-system-defaults';

@Component({
    selector: 'app-gate-system-defaults',
    templateUrl: '../system-defaults/gate-system-defaults.component.html',
    styleUrls: ['../system-defaults/system-defaults.component.css'],
    providers: [SystemDefaultsService, GateEditorFieldContentProvider, GateSidebarFieldImageService, GateSystemService,
        RailSystemService, ColorService, GatePanelTypeService, AddonCategoryGroupService, GateDesignerCatalogDependentOptionService,
        AddonsService, SubsystemGroupService, SubsystemService, TranslatedSelectItemService, GateWallService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateSystemDefaultsComponent extends SystemDefaultsComponent<GateSystem, GateSystemDefaultsState> implements OnInit {

    fields: GateEditorFieldStates;

    colors: Color[];
    addonCategoryGroups: AddonCategoryGroup[];

    constructor(injector: Injector,
                private systemService: GateSystemService,
                private readonly editorFieldContentProvider: GateEditorFieldContentProvider) {
        super(injector);
        this.fields = new GateEditorFieldStates(undefined, editorFieldContentProvider);
        this.editorFieldContentProvider.filteringEnabled = true;
        this.itemType = CatalogItemType.GATE_SYSTEM;
    }

    ngOnInit() {
        this.translatedSelectItemService.buildUnsortedDropdown(GateControl, 'GATE_CONTROL.', undefined)
            .subscribe(selectItems => this.editorFieldContentProvider.setItems(GateEditorField.CONTROL, selectItems));
        this.state = Object.assign(new GateSystemDefaultsState(), {
            value: new GateSystemDefaults(),
            offerDefaultExisting: false,
            clientDefaultExisting: false,
            clientGroupDefaultExisting: false,
            subsystemDefaultExisting: false,
            globalDefaultExisting: false
        });
        this.systemService.getItems(undefined, undefined, {active: {value: 'true'}}, 'sortIndex', 1)
            .subscribe(data => {
                this.systems = data.data;
                this.handleSystemChange(this.systems[0]);
            });
        super.ngOnInit();
    }

    handleSidebarFieldChange(event: SidebarFieldChangeEvent): void {
        this.editorFieldContentProvider.notifyFieldChanged(event.field, event.newValue);
    }

    handleSystemChange(system: GateSystem): void {
        this.selectedSystem = system;
        if (system != undefined) {
            this.systemDefaultsService.getDefaultsForGate(system.id, undefined).pipe(
                mergeMap(defaults => {
                    this.state = defaults;
                    this.editorFieldContentProvider.storeSelectedValues(defaults.value);
                    return forkJoin({
                        dummy: of(`fix: TS2339: Property 'colors' does not exist on type 'never'.`),
                        ...this.editorFieldContentProvider.getCatalogDataSource(system.id, this.state.value)
                    });
                })
            ).subscribe(catalogData => {
                this.editorFieldContentProvider.initCatalog(catalogData);
                this.colors = catalogData.colors;
                this.addonCategoryGroups = catalogData.addonCategoryGroups;
            });
        }
    }
}
