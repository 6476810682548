import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../../common/crud-common/crud.service';
import {Listing} from '../../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {DealerDiscountPerSupplier} from './dealer-discount-per-supplier';

@Injectable()
export class DealerDiscountPerSupplierService implements CrudService<DealerDiscountPerSupplier> {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<Listing<DealerDiscountPerSupplier>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>('dealerdiscountspersupplier', {params: params}).pipe(
            map(response => Listing.fromJSON(DealerDiscountPerSupplier, response)));
    }

    getItem(itemId: number): Observable<DealerDiscountPerSupplier> {
        return undefined;
    }

    addItem(item: DealerDiscountPerSupplier): Observable<number> {
        return this.http.post('dealerdiscountspersupplier', item, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId('dealerdiscountspersupplier', response)));
    }

    editItem(itemId: number, item: DealerDiscountPerSupplier): Observable<number> {
        return this.http.put(`dealerdiscountspersupplier/${itemId}`, item).pipe(
            map(() => itemId));
    }
}
