import {Injectable} from '@angular/core';
import {MotlawaIntegrationInfo} from "../../../common/MotlawaIntegrationInfo";
import {ConfigSystem} from "./config-system";
import {Observable, throwError} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing, mapListingToJson} from "../../../common/crud-common/crudItemList";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper, FileState} from "../../../common/dataServiceHelper";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {ProfitMarginExistance} from "../../offer/window-editor/drawing-tool/ProfitMarginExistance";
import {MultilanguageField} from "../../../supportedLanguages";
import {SingleSystemCheckboxCrudService} from "../single-system-checkbox-crud/single-system-checkbox-crud.service";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {ConfigAddonApplication} from "../../../../window-designer/enums/ConfigAddonApplication";
import {CatalogItemName} from "../../../common/crud-common/catalog-item-name";

@Injectable()
export class ConfigSystemService extends SingleSystemCheckboxCrudService<ConfigSystem> {

    private static readonly API_URL = 'configSystem';

    constructor(protected http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    addItem(item: ConfigSystem, dimensionLabels?: Map<string, MultilanguageField>, image?: File, designerImage?: File,
            imageLarge?: File): Observable<number> {
        // not supported
        const err = new Error('ConfigSystemService.addItem is unsupported');
        return throwError(() => err);
    }

    editItem(itemId: number, item: ConfigSystem, dimensionLabels?: Map<string, MultilanguageField>,
             designerImage?: File, imageLarge?: File): Observable<number> {
        // not supported
        const err = new Error('ConfigSystemService.editItem is unsupported');
        return throwError(() => err);
    }

    getItem(itemId: number): Observable<ConfigSystem> {
        return this.http.get<ConfigSystem>(`${ConfigSystemService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<ConfigSystem>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<ConfigSystem>>(ConfigSystemService.API_URL, {params: params})
            .pipe(mapListingToJson(ConfigSystem));
    }

    getSystemsConfigs(windowSystemId: number, selectedIds: number[] = null, readOnly = false): Observable<ConfigSystem[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        params['readOnly'] = `${readOnly}`;
        return this.http.get<ConfigSystem[]>(`${ConfigSystemService.API_URL}/forWindowSystem/${windowSystemId}`, {params: params});
    }

    getImage(itemId: number): Observable<string> {
        return this.http.get(`${ConfigSystemService.API_URL}/${itemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`${ConfigSystemService.API_URL}/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getDesignerImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`${ConfigSystemService.API_URL}/${itemId}/designerImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getDesignerImage(itemId: number): Observable<string> {
        return this.http.get(`${ConfigSystemService.API_URL}/${itemId}/designerImage`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageLargeAsFile(itemId: number): Observable<File> {
        return this.http.get(`${ConfigSystemService.API_URL}/${itemId}/imageLarge`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getImageLargeAsString(itemId: number): Observable<string> {
        return this.http.get(`${ConfigSystemService.API_URL}/${itemId}/imageLarge`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    saveConfigSystem(item: ConfigSystem, designerImage: File, imageLarge: File,
                     dimensionLabels: Map<string, MultilanguageField>, motlawaInfo: MotlawaIntegrationInfo,
                     amazonS3File: File | string | undefined): Observable<number> {
        let formData = new FormData();
        formData.append('configSystemDto', new Blob([JSON.stringify(item)], {type: 'application/json'}));

        if (designerImage) {
            formData.append('designerImage', designerImage);
        }
        if (imageLarge) {
            formData.append('imageLarge', imageLarge);
        }
        if (dimensionLabels) {
            const convMap = {};
            dimensionLabels.forEach((val: MultilanguageField, key: string) => {
                convMap[key] = val;
            });
            formData.append('dimensionLabels', new Blob([JSON.stringify(convMap)], {type: 'application/json'}));
        }
        if (motlawaInfo != undefined) {
            formData.append('MotlawaIntegrationInfo', new Blob([JSON.stringify(motlawaInfo)], {type: 'application/json'}));
        }
        if (amazonS3File != undefined) {
            formData.append('amazonS3File', amazonS3File);
        }
        return this.http.post<void>(ConfigSystemService.API_URL, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToItemId(ConfigSystemService.API_URL, item.id));
    }

    copy(id: number, item: ConfigSystem, designerImage: File, imageLarge: File,
         dimensionLabels: Map<string, MultilanguageField>, motlawaInfo: MotlawaIntegrationInfo,
         amazonS3File: File | string | undefined) {
        let formData = new FormData();
        formData.append('configSystemDto', new Blob([JSON.stringify(item)], {type: 'application/json'}));

        if (designerImage) {
            formData.append('designerImage', designerImage);
        }
        if (imageLarge) {
            formData.append('imageLarge', imageLarge);
        }
        if (dimensionLabels) {
            const convMap = {};
            dimensionLabels.forEach((val: MultilanguageField, key: string) => {
                convMap[key] = val;
            });
            formData.append('dimensionLabels', new Blob([JSON.stringify(convMap)], {type: 'application/json'}));
        }
        if (motlawaInfo != undefined) {
            formData.append('MotlawaIntegrationInfo', new Blob([JSON.stringify(motlawaInfo)], {type: 'application/json'}));
        }
        if (amazonS3File != undefined) {
            formData.append('amazonS3File', amazonS3File);
        }
        return this.http.post<void>(`${ConfigSystemService.API_URL}/${id}/copy`, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(ConfigSystemService.API_URL));
    }

    validate(item: ConfigSystem): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${ConfigSystemService.API_URL}/validate`, item));
    }

    public validateMarginExistance(systemId: number, offerId: number, application: ConfigAddonApplication[]): Observable<ProfitMarginExistance> {
        let params = {
            forBulkConfigAddons: `${application.includes(ConfigAddonApplication.INDEPENDENT)}`
        };
        return this.http.get<ProfitMarginExistance>(`${ConfigSystemService.API_URL}/${systemId}/profitMarginExists/${offerId}`, {params: params});
    }

    getGlamourDescription(configSystemId: number): Observable<MultilanguageField> {
        return this.http.get<MultilanguageField>(`${ConfigSystemService.API_URL}/${configSystemId}/glamourDescription`);
    }

    getGlamourHorizontalPrintImageAsFile(configSystemId: number): Observable<File> {
        return this.http.get(`${ConfigSystemService.API_URL}/${configSystemId}/glamourHorizontalPrintImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getGlamourVerticalPrintImageAsFile(configSystemId: number): Observable<File> {
        return this.http.get(`${ConfigSystemService.API_URL}/${configSystemId}/glamourVerticalPrintImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    updateAddonGlamourPrintInfo(configSystemId: number, horizontalPrintImage: FileState,
                                verticalPrintImage: FileState, glamourDescription: MultilanguageField): Observable<void> {
        const formData = new FormData();
        if (this.dataServiceHelper.isFileSaveNeeded(horizontalPrintImage)) {
            formData.append('horizontalPrintImage', horizontalPrintImage.file);
        }
        if (this.dataServiceHelper.isFileSaveNeeded(verticalPrintImage)) {
            formData.append('verticalPrintImage', verticalPrintImage.file);
        }
        let body = JSON.stringify(glamourDescription);
        formData.append('glamourDescription', new Blob([body], {type: 'application/json'}));
        return this.http.put<void>(`${ConfigSystemService.API_URL}/${configSystemId}/glamourPrintInfo`, formData);
    }

    getLabelTranslations(configSystemId: number): Observable<Map<string, MultilanguageField>> {
        return this.http.get<{ [key: string]: MultilanguageField }>(`${ConfigSystemService.API_URL}/${configSystemId}/translations`)
            .pipe(map(res => {
                const sth = new Map<string, MultilanguageField>();
                Object.keys(res).forEach(key => sth.set(key, res[key]));
                return sth;
            }));
    }

    getMotlawaInfo(configSystemId: number): Observable<MotlawaIntegrationInfo> {
        return this.http.get<MotlawaIntegrationInfo>(`${ConfigSystemService.API_URL}/${configSystemId}/motlawaInfo`);
    }

    getConfigAddonsForCatalogLinking(): Observable<ItemForCatalogLinking[]> {
        return this.http.get<ItemForCatalogLinking[]>(`${ConfigSystemService.API_URL}/forCatalogLinking`);
    }

    canUploadToAmazonS3(): Observable<boolean> {
        return this.http.get<boolean>(`${ConfigSystemService.API_URL}/canUploadToAmazonS3`);
    }

    getAmazonS3Files(): Observable<string[]> {
        return this.http.get<string[]>(`${ConfigSystemService.API_URL}/amazonS3Files`);
    }

    getConfigSystemFileUrl(systemId: number): Observable<string> {
        return this.http.get(`${ConfigSystemService.API_URL}/${systemId}/fileUrl`, {responseType: 'text'});
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${ConfigSystemService.API_URL}/names`, {params: params});
    }
}
