import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseAddonProfitMarginService} from '../../../common/addon-profit-margin/base-addon-profit-margin.service';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {SubsystemGroupAddonProfitMargin} from './subsystem-group-addon-profit-margin';

@Injectable()
export class SubsystemGroupAddonProfitMarginService extends BaseAddonProfitMarginService<SubsystemGroupAddonProfitMargin> {

    private static readonly API_URL = 'subsystemGroupAddonsProfitMargins';

    constructor(http: HttpClient, dataServiceHelper: DataServiceHelper) {
        super(http, dataServiceHelper, SubsystemGroupAddonProfitMarginService.API_URL);
    }

    overrideGroupMemberMargins(entityId: number, profitMargins: SubsystemGroupAddonProfitMargin[]): Observable<void> {
        return this.http.post<void>(`${SubsystemGroupAddonProfitMarginService.API_URL}/${entityId}/overrideGroupMembers`, profitMargins);
    }
}
