<div class="list-content">
    <div class="table-header">
        <div class="title">{{ (target === dataModificationTargets.WEBSHOP_CHARGE ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WEBSHOP_CHARGES.LIST' : 'SETTINGS.SECTION.WINDOW_UPSELLING_SETTINGS_PER_PODSYSTEM.LIST') | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button [disabled]="selected.length === 0" buttonId="activateButton"
                                   label="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.ACTIVATE_SELECTED' | translate }}"
                                   icon="check" type="confirm" (onClick)="setForSelected(true)"></app-simple-button>
                <app-simple-button [disabled]="selected.length === 0" buttonId="deactivateButton"
                                   label="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.DEACTIVATE_SELECTED' | translate }}"
                                   icon="cancel" type="delete" (onClick)="setForSelected(false)"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="webshopChargePerSubsystem">
        <p-column field="select" [sortable]="false" [filter]="false" [style]="{'width': '44px'}">
            <ng-template pTemplate="header">
                <app-tristate-checkbox [(ngModel)]="allSelectedState" (ngModelChange)="selectAllChange()"></app-tristate-checkbox>
            </ng-template>

            <ng-template let-item="rowData" pTemplate="body">
                <span>
                    <app-checkbox [inputId]="'checkbox_' + item.name[translate.currentLang]"
                                  [ngModel]="isSelected(item)"
                                  (ngModelChange)="handleSelection(item)"></app-checkbox>
                </span>
            </ng-template>
        </p-column>
        <p-column field="name" header="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{ item.name[translate.currentLang] }}
            </ng-template>
        </p-column>
        <p-column *ngIf="target === dataModificationTargets.WEBSHOP_CHARGE"
                  field="windowSystemName"
                  header="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.WINDOW_SYSTEM_NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{item.windowSystemName[translate.currentLang]}}
            </ng-template>
        </p-column>
        <p-column field="available" header="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.STATUS' | translate }}"
                  [sortable]="false" [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                {{ (item.available ? 'WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.AVAILABLE' : 'WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.UNAVAILABLE') | translate }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard-dialog *ngIf="displayDialog" header="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.EDIT' | translate }}"
                   (onCancel)="hideDialog()" (onComplete)="submit()">
    <app-wizard-step id="data" label="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.DATA' | translate }}">
        <div class="new-form-400">
            <div class="new-form-row">
                <app-input-text inputId="nameInput" label="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.NAME' | translate }}"
                                [disabled]="true" [ngModel]="item.name[translate.currentLang]"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-text-area inputId="{{'info'}}"
                               label="{{ 'WEBSHOP_CHARGE.FORM.INFO_LOCALIZED' | translate }}"
                               [disabled]="true"
                               [ngModel]="item.info[translate.currentLang]"></app-text-area>
            </div>

            <div>
                <app-checkbox inputId="availableCheckbox"
                              label="{{ 'WEBSHOP_CHARGE_PER_SUBSYSTEM.FORM.AVAILABLE' | translate }}"
                              [(ngModel)]="item.available"></app-checkbox>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
