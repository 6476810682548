import {Handle} from "../drawing-data/Handle";
import {SubWindowData} from "../drawing-data/SubWindowData";
import {DataKeys, MinMaxXY} from "../drawing-util";
import {HandleHelper, HandlePolygons} from "../handle-helper";
import {WindowDesignerInterface} from '../window-designer-interface';
import {HandleParams} from "./HandleParams";
import {WindowParams} from "./WindowParams";
import {ScalingPainter} from "./ScalingPainter";
import {PainterParams} from "./PainterParams";
import {WindowCalculator} from "../window-calculator";
import {CutData} from "../drawing-data/CutData";
import {Shadings} from "../ShadingCalculator";
import * as _ from "underscore";

export class HandlePainter {

    public static paint(offerComponent: WindowDesignerInterface, subWindow: SubWindowData, cuts: CutData[],
                        totalBoundingBox: MinMaxXY, params: PainterParams): Snap.Element[] {
        let elements = [];
        if (HandleHelper.isPresent(subWindow.handle) && !params.isShaded()) {
            let handleGroup = offerComponent.svg.g();
            let framePoints = WindowCalculator.getWingCenterPoints(subWindow, cuts, totalBoundingBox,
                offerComponent.profileCompositionDistances, offerComponent.isValidationDisabled());
            let centerPoint = WindowCalculator.getSubWindowCenterPoint(subWindow, cuts, totalBoundingBox,
                offerComponent.profileCompositionDistances, offerComponent.isValidationDisabled());
            let point = HandleHelper.relativeToAbsolute(subWindow.handle, framePoints, centerPoint);
            let points = HandleHelper.getPoints(subWindow.handle, point);
            let circleInfo = [point.x, point.y, HandleParams.Width / 5];
            handleGroup.add(ScalingPainter.path(offerComponent.svg, points, WindowParams.smallerOnThumbnail(params), params));
            handleGroup.add(ScalingPainter.circle(offerComponent.svg, circleInfo, WindowParams.smallerOnThumbnail(params), params));
            handleGroup.attr(HandleParams.Attributes);
            this.addData(handleGroup, subWindow.handle, subWindow);
            offerComponent.addSelectionClickHandler(handleGroup, WindowParams.HANDLE_ELEM);
            elements.push(handleGroup);
        }
        return elements;
    }

    public static paintShaded(designer: WindowDesignerInterface, params: PainterParams, shadings: Shadings,
                              outerFramePoints: number[]): void {
        if (!params.isShaded() || params.viewFromOutside) {
            return;
        }
        let subwindowsWithHandles = _.flatten(designer.data.windows.map(w => w.subWindows)).filter(sw => HandleHelper.isPresent(sw.handle));
        if (subwindowsWithHandles.length > 0) {
            let foundationShadowGroup = designer.svg.g();
            let foundationShadowMask = designer.svg.g(
                ScalingPainter.path(designer.svg, outerFramePoints, WindowParams.OverlayMask.Show, params));
            let polygonsMap = new Map<SubWindowData, HandlePolygons>();
            subwindowsWithHandles.forEach(sw => {
                let framePoints = WindowCalculator.getWingCenterPoints(sw, designer.data.cuts,
                    designer.totalBoundingBox,
                    designer.profileCompositionDistances, designer.isValidationDisabled());
                let centerPoint = WindowCalculator.getSubWindowCenterPoint(sw, designer.data.cuts,
                    designer.totalBoundingBox,
                    designer.profileCompositionDistances, designer.isValidationDisabled());
                let point = HandleHelper.relativeToAbsolute(sw.handle, framePoints, centerPoint);
                polygonsMap.set(sw, HandleHelper.getShadedPoints(sw.handle, point));
            });
            subwindowsWithHandles.forEach(sw => {
                ScalingPainter.path(designer.svg, polygonsMap.get(sw).foundation, WindowParams.handle(params), params);
                foundationShadowGroup.add(
                    ScalingPainter.path(designer.svg, polygonsMap.get(sw).foundation, {}, params));
                foundationShadowMask.add(
                    ScalingPainter.path(designer.svg, polygonsMap.get(sw).foundation, WindowParams.OverlayMask.Hide,
                        params));
            });
            foundationShadowGroup.attr({filter: shadings.handlesShadowFilter, mask: foundationShadowMask});
            let handleShadowGroup = designer.svg.g();
            let handleShadowMask = designer.svg.g(
                ScalingPainter.path(designer.svg, outerFramePoints, WindowParams.OverlayMask.Show, params));
            subwindowsWithHandles.forEach(sw => {
                ScalingPainter.path(designer.svg, polygonsMap.get(sw).handle, WindowParams.handle(params), params);
                handleShadowGroup.add(ScalingPainter.path(designer.svg, polygonsMap.get(sw).handle, {}, params));
                handleShadowMask.add(
                    ScalingPainter.path(designer.svg, polygonsMap.get(sw).handle, WindowParams.OverlayMask.Hide,
                        params));
            });
            handleShadowGroup.attr({filter: shadings.handlesShadowFilter, mask: handleShadowMask});
        }
    }

    private static addData(element: Snap.Element, handle: Handle, subWindow: SubWindowData): void {
        element.addClass(WindowParams.HANDLE_ELEM);
        element.data(DataKeys.HANDLE, handle);
        element.data(DataKeys.SUBWINDOW, subWindow);
    }
}
