import {CrudItem} from "../../../common/crud-common/crudItem";
import {getTomorrowDateUTC} from '../tomorrow-date-helper';

export abstract class DealerDiscount implements CrudItem {
    id: number;
    active: boolean;
    cascade: boolean;
    discountValue: string;
    calculatedDiscountValue: number;
    validFrom: Date;
    isCurrent: boolean;

    constructor() {
        this.id = undefined;
        this.active = true;
        this.cascade = false;
        this.calculatedDiscountValue = undefined;
        this.validFrom = getTomorrowDateUTC();
        this.isCurrent = undefined;
    }
}
