import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Subscription} from 'rxjs';
import {MultilanguageFieldInterface} from '../../../window-designer/catalog-data/multilanguage-field-interface';

export interface MultilanguageFieldSelectItem extends SelectItem {
    labelKey: MultilanguageFieldInterface;
}

@Pipe({
    name: 'selectItemMultilanguageFieldTranslate',
    pure: false
})
export class SelectItemMultilanguageFieldTranslatePipe implements PipeTransform, OnDestroy {

    private items: MultilanguageFieldSelectItem[];
    private subscription: Subscription;

    constructor(private readonly translate: TranslateService,
                private readonly changeDetectorRef: ChangeDetectorRef) {
        this.subscription = translate.onLangChange.subscribe(event => this.updateLanguage(event.lang));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscription = undefined;
    }

    transform(value: MultilanguageFieldSelectItem[]): SelectItem[] {
        if (value == undefined || value.length === 0) {
            return value;
        }
        this.items = value;
        this.updateLanguage(this.translate.currentLang);
        return this.items;
    }

    private updateLanguage(lang: string) {
        if (this.items == undefined) {
            return;
        }
        for (const item of this.items) {
            if (item.labelKey != undefined) {
                item.label = item.labelKey[lang];
            }
        }
        this.changeDetectorRef.markForCheck();
    }
}
