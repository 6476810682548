<div class="new-form-600">
    <app-translation-fields *ngIf="fieldUsage.show(GlazingBeadField.NAME)"
                            inputId="name" label="{{ 'GLAZING_BEAD.FORM.NAME' | translate }}"
                            validationKeyPrefix="names" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(GlazingBeadField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.names"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(GlazingBeadField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'GLAZING_BEAD.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(GlazingBeadField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>

    <div *ngIf="fieldUsage.show(GlazingBeadField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'GLAZING_BEAD.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(GlazingBeadField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50" [required]="true"
                        [(ngModel)]="item.symbol"></app-input-text>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GlazingBeadField.THICKNESS)">
        <app-input-number inputId="thickness" label="{{ 'GLAZING_BEAD.FORM.THICKNESS' | translate }}"
                          [disabled]="fieldUsage.disabled(GlazingBeadField.THICKNESS)"
                          [(validationMessageKey)]="validationErrors['thickness']" [min]="1" [max]="99999"
                          [maxlength]="5" [required]="true" [(ngModel)]="item.thickness"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GlazingBeadField.ACCEPTABLE_FILLING_WIDTH)">
        <app-input-text inputId="acceptableFillingWidth" label="{{ 'GLAZING_BEAD.FORM.ACCEPTABLE_FILLING_WIDTH' | translate }}"
                        [disabled]="fieldUsage.disabled(GlazingBeadField.ACCEPTABLE_FILLING_WIDTH)"
                        [(validationMessageKey)]="validationErrors['acceptableFillingWidth']" [required]="true"
                        [(ngModel)]="item.acceptableFillingWidth"></app-input-text>
    </div>

    <div *ngIf="fieldUsage.show(GlazingBeadField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GLAZING_BEAD.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GlazingBeadField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="item.id != null && fieldUsage.show(GlazingBeadField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'GLAZING_BEAD.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GlazingBeadField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GlazingBeadField.IMAGE_SECTION)">
        <p-accordion>
            <p-accordionTab header="{{ 'GLAZING_BEAD.FORM.IMAGE_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 [disabled]="fieldUsage.disabled(GlazingBeadField.IMAGE_SECTION)"
                                 (ngModelChange)="onFileChange($event)"
                                 [maxSize]="50000" [maxWidth]="400" [maxHeight]="400"
                                 [addButtonLabel]="'GLAZING_BEAD.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GLAZING_BEAD.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GLAZING_BEAD.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
