import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from "rxjs/operators";
import {WindowSystemDefaults} from '../../../../window-designer/entities/window-system-defaults';
import {ConfigSystemDefaults} from "../../settings/config-system-defaults/config-system-defaults";
import {GateSystemDefaults} from "../../settings/gate-system-defaults/gate-system-defaults";
import {
    ConfigSystemDefaultsState,
    GateSystemDefaultsState,
    WindowSystemDefaultsState
} from "../../settings/system-defaults/system-default-state";
import {CatalogItemType} from "./sidebar/pricing/PricingItem";

type DefaultClasses = WindowSystemDefaults | GateSystemDefaults | ConfigSystemDefaults;

@Injectable()
export class SystemDefaultsService {

    private static readonly API_URL = 'systemDefaults';

    constructor(private http: HttpClient) {
    }

    public save(catalogItemType: CatalogItemType, level: string, systemId: number, offerId: number, data: DefaultClasses): Observable<void> {
        return this.http.put<void>(`${SystemDefaultsService.API_URL}/${catalogItemType}`, {
            level: level,
            systemId: systemId,
            offerId: offerId,
            value: data
        });
    }

    public deleteSystemDefaults(catalogItemType: CatalogItemType, level: string, systemId: number, offerId: number): Observable<void> {
        let params = {level: level};
        if (offerId != undefined) {
            params['offerId'] = '' + offerId;
        }
        return this.http.delete<void>(`${SystemDefaultsService.API_URL}/${catalogItemType}/deleteSystemDefaults/${systemId}`, {params: params});
    }

    public getDefaultsForWindow(systemId: number, offerId: number, forModel = false, forStandaloneGlazingPackage = false): Observable<WindowSystemDefaultsState> {
        let params = {systemId: '' + systemId, forModel: '' + forModel, forStandaloneGlazingPackage: '' + forStandaloneGlazingPackage};
        if (offerId != undefined) {
            params['offerId'] = '' + offerId;
        }
        return this.http.get<WindowSystemDefaultsState>(`${SystemDefaultsService.API_URL}/${CatalogItemType.WINDOW_SYSTEM}/getForOffer`, {params: params});
    }

    public getDefaultsForConfig(systemId: number, offerId: number): Observable<ConfigSystemDefaultsState> {
        let params = {systemId: '' + systemId};
        if (offerId != undefined) {
            params['offerId'] = '' + offerId;
        }
        return this.http.get<ConfigSystemDefaultsState>(`${SystemDefaultsService.API_URL}/${CatalogItemType.CONFIG_SYSTEM}/getForOffer`, {params: params})
            .pipe(tap(state => {
                if (state.value == undefined) {
                    state.value = new ConfigSystemDefaults();
                }
        }));
    }

    public getDefaultsForGate(systemId: number, offerId: number): Observable<GateSystemDefaultsState> {
        let params = {systemId: '' + systemId};
        if (offerId != undefined) {
            params['offerId'] = '' + offerId;
        }
        return this.http.get<GateSystemDefaultsState>(`${SystemDefaultsService.API_URL}/${CatalogItemType.GATE_SYSTEM}/getForOffer`, {params: params})
            .pipe(tap(state => {
                if (state.value == undefined) {
                    state.value = new GateSystemDefaults();
                }
            }));
    }

    public overwriteForSubsystemGroups(catalogItemType: CatalogItemType, systemId: number, subsystemGroupIds: number[],
                                       value: DefaultClasses): Observable<void> {
        return this.http.put<void>(`${SystemDefaultsService.API_URL}/${catalogItemType}/overwriteForSubsystemGroups`, {
            systemId: systemId,
            ids: subsystemGroupIds,
            value: value
        });
    }

    public overwriteForSubsystems(catalogItemType: CatalogItemType, systemId: number, subsystemIds: number[], value: DefaultClasses): Observable<void> {
        return this.http.put<void>(`${SystemDefaultsService.API_URL}/${catalogItemType}/overwriteForSubsystems`, {
            systemId: systemId,
            ids: subsystemIds,
            value: value
        });
    }
}
