import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import {Table} from 'primeng/table';
import {Observable} from 'rxjs';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {PromotionCode} from './promotion-code';
import {PromotionCodeService} from './promotion-code.service';

@Component({
    selector: 'app-promotion-code',
    templateUrl: './promotion-code.component.html',
    providers: [PromotionCodeService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PromotionCodeComponent extends CrudCommonComponent<PromotionCode, PromotionCodeService> {

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => this.validateGeneralData()
        }
    };

    @ViewChild('table')
    table: Table;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, PromotionCodeService, 'PROMOTION_CODE', 'PromotionCode');
    }

    getNewItem(): PromotionCode {
        return new PromotionCode();
    }

    loadEditedItem(event: { data: PromotionCode }): Observable<PromotionCode> {
        return this.itemService.getItem(event.data.id);
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    protected validateForm(): void | boolean | Observable<boolean> {
        return this.validateGeneralData();
    }

    validateGeneralData(): boolean {
        const validationErrors = {
            validFrom: MultiValidator.of('error.promotionCodeDto.validFrom')
                .withNotNullValidator()
                .validate(this.item.validFrom),
            validTo: MultiValidator.of('error.promotionCodeDto.validTo')
                .withNotNullValidator()
                .validate(this.item.validTo),
            code: MultiValidator.of('error.promotionCodeDto.code')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.code),
            value: MultiValidator.of('error.promotionCodeDto.value')
                .withNotNullValidator()
                .withRangeValidator(0, 100, false)
                .validate(this.item.value)
        };

        this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
        return !ValidationErrorsHelper.validationErrorsPresent(validationErrors);
    }
}
