<div class="list-content">
    <div class="table-header">
        <div class="title">
            {{ 'NAVIGATION.ITEMS.OFFERS.PRODUCTION_ORDERS' | translate }}
        </div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <app-simple-button id="clearFilters" *ngIf="filterByCurrentUser"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="removeFiltersNotSetOnTableColumns()"></app-simple-button>

                <app-simple-button *ngIf="!filterByCurrentUser"
                                   label="{{ 'OFFER.PRODUCTION_ORDERS.LIST.SHOW_OWN' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="enableFilterByCurrentUser()"></app-simple-button>

                <app-simple-button *ngIf="filterByPaymentPackage"
                                   label="{{ 'OFFER.PRODUCTION_ORDERS.LIST.REMOVE_PAYMENT_PACKAGE_FILTER' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="disableFilterByPaymentPackageId()"></app-simple-button>

                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.OFFERS.PRODUCTION_ORDERS')"></app-simple-button>

                <p-multiSelect id="chooseColumnsMenu" defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}"
                               [displaySelectedLabel]="false" [options]="columnOptions | selectItemTranslate"
                               [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>

                <app-simple-button *ngIf="showPrintButton" icon="print" type="action" buttonId="printButton"
                                   label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HEADER' | translate }}"
                                   (onClick)="openPrintDialog()"></app-simple-button>
            </div>
        </div>
    </div>

    <div class="datatable-container">
        <p-table #dt [value]="productionOrders" [rows]="chosenRowsPerPage" [attr.id]="PRODUCTION_ORDERS_TABLE_ID"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions" [paginator]="false" [first]="chosenPageNumber"
                 [metaKeySelection]="true" (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [responsive]="true" [sortField]="defaultSortColumn"
                 [sortOrder]="defaultSortOrder" [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th style="vertical-align: middle">
                        <app-tristate-checkbox [(ngModel)]="allSelectedState"
                                               (ngModelChange)="selectAllChange()"></app-tristate-checkbox>
                    </th>
                    <th>
                        {{ 'OFFER.FORM.ACTIONS' | translate }}
                    </th>
                    <th *ngIf="columnByField['offerNumbers']"
                        [ngClass]="columnHeaderClasses('offerNumbers')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.OFFER_NUMBER' | translate }}</span>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['offerNumbers'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)" name="offerNumbers"
                               (input)="dt.filter(getInputEventValue($event), 'offerNumbers', 'contains')">
                    </th>
                    <th *ngIf="columnByField['orderNumber']" pSortableColumn="orderNumber"
                        [ngClass]="columnHeaderClasses('orderNumber')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.PRODUCTION_ORDER_NUMBER' | translate }}</span>
                        <p-sortIcon field="orderNumber"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['orderNumber'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)" name="orderNumber"
                               (input)="dt.filter(getInputEventValue($event), 'orderNumber', 'contains')">
                    </th>
                    <th *ngIf="columnByField['creationDate']" pSortableColumn="creationDate"
                        [ngClass]="columnHeaderClasses('creationDate')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.CREATION_DATE' | translate }}</span>
                        <p-sortIcon field="creationDate"></p-sortIcon>
                        <div app-table-date-range-filter="creationDate"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                    <th *ngIf="columnByField['paymentPackageName']" pSortableColumn="paymentPackageName"
                        [ngClass]="columnHeaderClasses('paymentPackageName')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.PAYMENT_PACKAGE_NAME' | translate }}</span>
                        <p-sortIcon field="paymentPackageName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['paymentPackageName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)" name="paymentPackageName"
                               (input)="dt.filter(getInputEventValue($event), 'paymentPackageName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['paletteName']" pSortableColumn="paletteName"
                        [ngClass]="columnHeaderClasses('paletteName')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.PALETTE_NAME' | translate }}</span>
                        <p-sortIcon field="paymentPackageName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['paletteName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)" name="paletteName"
                               (input)="dt.filter(getInputEventValue($event), 'paletteName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['supplierCompanyName']" pSortableColumn="supplierCompanyName"
                        [ngClass]="columnHeaderClasses('supplierCompanyName')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.SUPPLIER_NAME' | translate }}</span>
                        <p-sortIcon field="supplierCompanyName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['supplierCompanyName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'supplierCompanyName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['subsystemName']" pSortableColumn="subsystemName"
                        [ngClass]="columnHeaderClasses('subsystemIds')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.SUBSYSTEM' | translate }}</span>
                        <p-sortIcon field="subsystemName"></p-sortIcon>
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                             (click)="handleInputFilterClick($event)">
                            <p-multiSelect [options]="availableSubsystems"
                                           defaultLabel="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}"
                                           appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                           (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                        </div>
                    </th>
                    <th *ngIf="columnByField['clientManagerName']" pSortableColumn="clientManagerName"
                        [ngClass]="columnHeaderClasses('clientManagerName')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.CLIENT_MANAGER' | translate }}</span>
                        <p-sortIcon field="clientManagerName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['clientManagerName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'clientManagerName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['subClientManagerName']" [ngClass]="columnHeaderClasses('subClientManagerName')"
                        pSortableColumn="subClientManagerName">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.SUB_CLIENT_MANAGER' | translate }}</span>
                        <p-sortIcon field="subClientManagerName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['subClientManagerName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'subClientManagerName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['venskaBuyValue.netValue']" pSortableColumn="venskaBuyValue.netValue"
                        [ngClass]="columnHeaderClasses('venskaBuyValue.netValue')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.VENSKA_BUY_NET' | translate }}</span>
                        <p-sortIcon field="venskaBuyValue.netValue"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['venskaBuyValue.netValue'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'venskaBuyValue.netValue', 'contains')">
                    </th>
                    <th *ngIf="columnByField['venskaBuyValue.grossValue']" pSortableColumn="venskaBuyValue.grossValue"
                        [ngClass]="columnHeaderClasses('venskaBuyValue.grossValue')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.VENSKA_BUY_GROSS' | translate }}</span>
                        <p-sortIcon field="venskaBuyValue.grossValue"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['venskaBuyValue.grossValue'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'venskaBuyValue.grossValue', 'contains')">
                    </th>
                    <th *ngIf="columnByField['buyValue.netValue']" pSortableColumn="buyValue.netValue"
                        [ngClass]="columnHeaderClasses('buyValue.netValue')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.BUY_NET' | translate }}</span>
                        <p-sortIcon field="buyValue.netValue"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['buyValue.netValue'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'buyValue.netValue', 'contains')">
                    </th>
                    <th *ngIf="columnByField['buyValue.grossValue']" pSortableColumn="buyValue.grossValue"
                        [ngClass]="columnHeaderClasses('buyValue.grossValue')">
                        <span>{{ 'OFFER.PRODUCTION_ORDERS.BUY_GROSS' | translate }}</span>
                        <p-sortIcon field="buyValue.grossValue"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['buyValue.grossValue'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'buyValue.grossValue', 'contains')">
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-productionOrder let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" (dblclick)="redirectToPositionList(productionOrder)"
                    [pSelectableRow]="productionOrder" [pSelectableRowIndex]="rowIndex"
                    [app-table-keyboard-navigation]="productionOrder" class="datatable-data"
                    (app-table-keyboard-navigation-enter)="redirectToPositionList(productionOrder)">
                    <td style="vertical-align: middle">
                        <span class="flex-row">
                            <span class="flex-row">
                                <app-checkbox [ngModel]="isSelectedItem(productionOrder)"
                                              (ngModelChange)="selectItem(productionOrder)"></app-checkbox>
                                <i *ngIf="postionHasTemporaryDealerDiscount(productionOrder)" class="material-icons"
                                   title="{{ 'OFFER.PRODUCTION_ORDERS.LIST.HAS_TEMP_DEALER_DISCOUNT' | translate }}">report_problem</i>
                                <i *ngIf="productionOrder.correctionId != undefined" class="material-icons"
                                   title="{{ 'OFFER.PRODUCTION_ORDERS.LIST.HAS_CORRECTION' | translate }}">error</i>
                                <i *ngIf="!productionOrder.printed && filterByPaymentPackage"
                                   class="material-icons warning-severity-color"
                                   title="{{ 'OFFER.PRODUCTION_ORDERS.LIST.NOT_PRINTED' | translate }}">report_problem</i>
                            </span>
                        </span>
                    </td>
                    <td>
                        <div class="table-row-action-buttons">
                            <button-with-menu [buttonId]="'more_' + productionOrder.id" rightButtonIcon="more_horiz"
                                              buttonType="filter" [buttonSize]="36" [menuType]="menuType.BOTTOM_LEFT"
                                              buttonLabel="{{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.TOOLTIPS.SHOW_MORE_ACTIONS' | translate }}"
                                              [buttonInlineLabel]="false"
                                              [menuElements]="buildRowActions(productionOrder)"
                                              (menuElementSelected)="handleRowAction($event)"></button-with-menu>
                        </div>
                    </td>
                    <td *ngIf="columnByField['offerNumbers']">
                        <span *ngFor="let offerNumber of productionOrder.offerNumbers">
                            <app-offer-number [offerNumber]="offerNumber" [isOffer]="true"></app-offer-number>
                        </span>
                    </td>
                    <td *ngIf="columnByField['orderNumber']">
                        <app-offer-number [offerNumber]="productionOrder.orderNumber"
                                          [isProductionOrder]="true"></app-offer-number>
                    </td>
                    <td *ngIf="columnByField['creationDate']">
                        {{ productionOrder.creationDate | momentCurrentLang | amDateFormat: 'L' }}
                    </td>
                    <td *ngIf="columnByField['paymentPackageName']">
                        {{ productionOrder.paymentPackageName }}
                    </td>
                    <td *ngIf="columnByField['paletteName']">
                        {{ productionOrder.paletteName }}
                    </td>
                    <td *ngIf="columnByField['supplierCompanyName']">
                        {{ productionOrder.supplierCompanyName }}
                    </td>
                    <td *ngIf="columnByField['subsystemName']">
                        {{ productionOrder.subsystemName }}
                    </td>
                    <td *ngIf="columnByField['clientManagerName']">
                        {{ productionOrder.clientManagerFirstName + ' ' + productionOrder.clientManagerLastName }}
                    </td>
                    <td *ngIf="columnByField['subClientManagerName']">
                        <span *ngIf="productionOrder.subClientManagerFirstName && productionOrder.subClientManagerLastName">
                            {{ productionOrder.subClientManagerFirstName + ' ' + productionOrder.subClientManagerLastName }}
                        </span>
                    </td>
                    <td *ngIf="columnByField['venskaBuyValue.netValue']">
                        {{ getPriceInSelectedCurrency(productionOrder.venskaBuyValue.netValue) + " " + selectedCurrency }}
                    </td>
                    <td *ngIf="columnByField['venskaBuyValue.grossValue']">
                        {{ getPriceInSelectedCurrency(productionOrder.venskaBuyValue.grossValue) + " " + selectedCurrency }}
                    </td>
                    <td *ngIf="columnByField['buyValue.netValue']">
                        {{ getPriceInSelectedCurrency(productionOrder.buyValue.netValue) + " " + selectedCurrency }}
                    </td>
                    <td *ngIf="columnByField['buyValue.grossValue']">
                        {{ getPriceInSelectedCurrency(productionOrder.buyValue.grossValue) + " " + selectedCurrency }}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="p-datatable-emptymessage-row">
                    <td [attr.colspan]="columns.length" class="p-datatable-emptymessage">
                        <span >{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
    <div class="dialog-window">
        <app-change-dealer-discount *ngIf="showChangeDealerDiscountDialog"
                                    [temporaryDiscount]="currentActionTemporaryDealerDiscount"
                                    (onSave)="handleChangeDiscountSave()" (onCancel)="handleChangeDiscountCancel()"
                                    (onError)="handleChangeDiscountError($event)"></app-change-dealer-discount>
    </div>
    <div class="dialog-window">
        <app-change-annotations *ngIf="showChangeAnnotationDialog" [productionOrder]="currentActionOrder"
                                (onSave)="handleChangeAnnotationsSave()"
                                (onCancel)="handleChangeAnnotationsCancel()"></app-change-annotations>
    </div>
    <div class="dialog-window" *ngIf="showChangeSystemDealerDiscountDialog">
        <app-change-system-dealer-discount [productionOrderId]="currentActionOrder.id"
                                           (onClose)="handleChangeSystemDealerDiscountDialogClose($event)"></app-change-system-dealer-discount>
    </div>

    <app-print-dialog *ngIf="printDialogVisible" [printableSection]="PrintableSection.PRODUCTION_ORDERS" [selectedItems]="printableItems"
                      (onCloseDialog)="handlePrintDialogClose($event)"></app-print-dialog>

</div>
