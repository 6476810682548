<p-dialog #dialog [(visible)]="visible" [resizable]="false" [modal]="true" (onHide)="onClose.emit()"
          [focusOnShow]="false" [closeOnEscape]="true">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.SET_PRINT_ORDER.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-400">
        <div class="new-form-row">
            <app-input-number inputId="printOrder" label="{{ 'OFFER.POSITIONS.FORM.PRINT_ORDER' | translate }}"
                              [(validationMessageKey)]="validationErrors['printOrder']" [min]="minPrintOrder"
                              [max]="maxPrintOrder" [step]="1" [required]="true" [allowOnlyIntegers]="true"
                              [(ngModel)]="newPrintOrder"></app-input-number>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="submitPriceChangeButton" label="{{ 'GENERAL.SAVE' | translate }}"
                               type="main-action" [size]="40" (onClick)="handleSubmit()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="dialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
