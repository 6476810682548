import {HttpErrorResponse} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ErrorResponse} from '../features/errors/errorResponse';
import {ValidationErrors} from './validation-errors';

export class ValidationErrorsHelper {

    public static clearAllErrorsExcluding(validationErrors: ValidationErrors, ...excludeKeys: string[]) {
        let keysToClear: string[] = Object.keys(validationErrors).filter(key => {
            return validationErrors[key] != undefined && excludeKeys.indexOf(key) < 0;
        });

        for (let key of keysToClear) {
            validationErrors[key] = undefined;
        }
    }

    public static validationErrorsPresent(validationErrors: ValidationErrors): boolean {
        return Object.keys(validationErrors).some(key => validationErrors[key] != undefined);
    }

    public static clearErrors(validationErrors: ValidationErrors, ...errorKeys: string[]): void {
        for (let key of errorKeys) {
            validationErrors[key] = undefined;
        }
    }

    public static clearAllErrors(validationErrors: ValidationErrors) {
        ValidationErrorsHelper.clearErrors(validationErrors, ...Object.keys(validationErrors));
    }

    public static mapBackendValidationErrors(request: Observable<any>): Observable<ValidationErrors> {
        return request.pipe(
            map(() => ({})),
            catchError((errorResponse: HttpErrorResponse) => {
                const error = new ErrorResponse(errorResponse.error);
                if (error.is400()) {
                    return of(error.invalidFields);
                }
                return throwError(() => errorResponse);
            }));
    }
}
