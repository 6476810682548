<app-wizard-dialog #wizard header="{{ getDialogHeader() | translate }}" (onCancel)="cancel()" (onComplete)="submit()"
                   dialogStyleClass="width-60vw-no-padding"
                   contentStyleClass="width-100percent-no-padding height-60vw-no-padding">
    <app-wizard-step label="{{ 'SUPPLIER-DETAILS.STEP-DETAILS-TITLE' | translate }}" [id]="STEPS.GENERAL"
                     [validate]="generalStepValidator">
        <app-subsystem-details-form [validationErrors]="validationErrors" [subsystem]="subsystem"
                                    [availableManagers]="availableManagers" [subsystemGroups]="subsystemGroups"
                                    [disabledFields]="disabledFields"></app-subsystem-details-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.ADDRESS_LIST_STEP' | translate }}" [id]="STEPS.ADDRESS_LIST"
                     [validate]="addressListStepValidator">
        <app-subsystem-address-list-form [(addresses)]="addresses" [canEdit]="true"
                                         [usedAddresses]="[selectedCorrespondenceAddress, selectedDeliveryAddress]"
                                         [validationErrors]="validationErrors"></app-subsystem-address-list-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUPPLIER-DETAILS.STEP-ADDRESS-TITLE' | translate }}" [id]="STEPS.ADDRESS"
                     [validate]="addressStepValidator">
        <app-subsystem-address-form [validationErrors]="validationErrors" [subsystem]="subsystem"
                                    [addresses]="addresses" [(correspondenceAddress)]="selectedCorrespondenceAddress"
                                    [(deliveryAddress)]="selectedDeliveryAddress"
                                    [disabledFields]="disabledFields"></app-subsystem-address-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.SUPPLIERS' | translate }}" [id]="STEPS.SUPPLIERS">
        <app-subsystem-inactive-suppliers-form [subsystem]="subsystem"
                                               [disabled]="disabledFields"></app-subsystem-inactive-suppliers-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.PROFIT_MARGINS' | translate }}" [id]="STEPS.SYSTEM_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                           entityType="subsystem" [userCanEdit]="!disabledFields"
                           [globalMarginUpdateButtonId]="profitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="profitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.DEFAULT, WindowSystemTypeGroup.TERRACE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.PROFIT_MARGINS_ROOF' | translate }}" [id]="STEPS.ROOF_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                           entityType="subsystem" [userCanEdit]="!disabledFields"
                           [globalMarginUpdateButtonId]="roofProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="roofProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ROOF]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.PROFIT_MARGINS_ENTRANCE' | translate }}"
                     [id]="STEPS.ENTRANCE_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                           entityType="subsystem" [userCanEdit]="!disabledFields"
                           [globalMarginUpdateButtonId]="entranceProfitGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="entranceProfitGlobalMarginInputId"
                           [windowSystemTypeGroups]="[WindowSystemTypeGroup.ENTRANCE]"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.ADDON_PROFIT_MARGINS' | translate }}" [id]="STEPS.ADDON_MARGINS">
        <app-subsystem-addon-profit-margin #childTable entityType="subsystem"
                                           [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                                           class="app-subsystem-addon-profit-margin-bulk-addons"
                                           [userCanEdit]="!disabledFields"
                                           [globalMarginUpdateButtonId]="addonGlobalMarginButtonId"
                                           [globalMarginUpdateInputId]="addonGlobalMarginInputId"></app-subsystem-addon-profit-margin>
    </app-wizard-step>

    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                           entityType="subsystem" [userCanEdit]="!disabledFields"
                           [globalMarginUpdateButtonId]="confAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="confAddGlobalMarginInputId"
                           [target]="possibleTargets.CONFIG"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.BULK_CONFIG_ADDON_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.BULK_CONFIG_ADDON_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                           entityType="subsystem" [userCanEdit]="!disabledFields"
                           [globalMarginUpdateButtonId]="bulkConfAddGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="bulkConfAddGlobalMarginInputId"
                           [target]="possibleTargets.CONFIG_BULK"></app-profit-margin>
    </app-wizard-step>

    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.CATALOG_CHARGE_PROFIT_MARGINS' | translate }}"
                     [id]="STEPS.CHARGE_MARGINS">
        <app-catalog-charge-profit-margin #childTable entityType="subsystem"
                                          [subsystemGroupId]="subsystem.subsystemGroupId" [subsystemId]="subsystem.id"
                                          [globalMarginUpdateButtonId]="catalogChargeGlobalMarginButtonId"
                                          [globalMarginUpdateInputId]="catalogChargeGlobalMarginInputId"
                                          [canEdit]="!disabledFields"></app-catalog-charge-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.GATES_PROFIT_MARGINS' | translate }}" [id]="STEPS.GATES_MARGINS">
        <app-profit-margin #childTable [subsystemGroupId]="subsystem.subsystemGroupId"
                           [subsystemId]="subsystem.id" entityType="subsystem" [userCanEdit]="!disabledFields"
                           [globalMarginUpdateButtonId]="gatesGlobalMarginButtonId"
                           [globalMarginUpdateInputId]="gatesGlobalMarginInputId" [target]="possibleTargets.GATE"></app-profit-margin>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.WINDOW_SYSTEM_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.SYSTEM_SELL_DEALER_DISCOUNTS">
        <app-subsystem-window-system-sell-dealer-discount #childTable [id]="subsystem.id"
                                                          [groupId]="subsystem.subsystemGroupId"
                                                          [canEdit]="!disabledFields"
                                                          [globalMarginUpdateInputId]="systemGlobalDiscountInputId"
                                                          [globalMarginUpdateButtonId]="systemGlobalDiscountButtonId"
                                                          [validationErrors]="validationErrors"></app-subsystem-window-system-sell-dealer-discount>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.CONFIG_ADDON_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.CONFIG_ADDON_SELL_DEALER_DISCOUNTS">
        <app-subsystem-configurable-addon-sell-dealer-discount #childTable [id]="subsystem.id"
                                                               [groupId]="subsystem.subsystemGroupId"
                                                               [canEdit]="!disabledFields"
                                                               [globalMarginUpdateInputId]="configAddonGlobalDiscountInputId"
                                                               [globalMarginUpdateButtonId]="configAddonGlobalDiscountButtonId"
                                                               [validationErrors]="validationErrors"></app-subsystem-configurable-addon-sell-dealer-discount>
    </app-wizard-step>
    <app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.SUPPLIER_SELL_DEALER_DISCOUNTS' | translate }}"
                     [id]="STEPS.SUPPLIER_SELL_DEALER_DISCOUNTS">
        <app-subsystem-supplier-sell-dealer-discount #childTable [id]="subsystem.id"
                                                     [groupId]="subsystem.subsystemGroupId" [canEdit]="!disabledFields"
                                                     [globalMarginUpdateInputId]="supplierGlobalDiscountInputId"
                                                     [globalMarginUpdateButtonId]="supplierGlobalDiscountButtonId"
                                                     [validationErrors]="validationErrors"></app-subsystem-supplier-sell-dealer-discount>
    </app-wizard-step>
	<app-wizard-step label="{{ 'SUBSYSTEM-DETAILS.FORM.USER_ACTIVITY_REPORT' | translate }}" [id]="STEPS.USER_ACTIVITY_REPORT">
		<app-subsystem-user-activity-report [subsystemId]="subsystem.id"></app-subsystem-user-activity-report>
	</app-wizard-step>
    <ng-template pTemplate="footer">
        <div class="p-dialog-buttonpane">
            <app-simple-button *ngIf="!disabledFields || permissions.isOpiekun()" buttonId="saveSubsystemButton"
                               label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="wizard.submit()"></app-simple-button>
            <app-simple-button buttonId="cancelButton" label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="cancel()"></app-simple-button>
        </div>
    </ng-template>
</app-wizard-dialog>
