<div class="list-content">
    <div class="table-header">
        <div class="title">
            {{ 'NAVIGATION.ITEMS.SHIPPING_SIMULATION_LIST' | translate }}
        </div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">

                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button *ngIf="permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                                   buttonId="calculate-multiple-simulations"
                                   icon="local_shipping" type="action" [disabled]="selectedItems.length === 0"
                                   (onClick)="calculateShippingCostForSelectedSimulations()"></app-simple-button>
            </div>
        </div>
    </div>

    <div class="datatable-container">
        <p-dataTable #dt [value]="simulations" [rows]="chosenRowsPerPage" [id]="TABLE_ID"
                     [rowsPerPageOptions]="limitedRowsPerPageOptions"
                     [paginator]="false" [seqNum]="1" [(first)]="chosenPageNumber"
                     [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                     (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                     [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                     [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate"
                     [(selection)]="selectedItem" (onRowDblclick)="rowDoubleClick($event)">

            <p-column [style]="{'vertical-align':'middle'}">
                <ng-template pTemplate="header">
                    <app-tristate-checkbox [ngModel]="allSelectedState" (ngModelChange)="selectAllChange($event)"></app-tristate-checkbox>
                </ng-template>
                <ng-template let-simulation="rowData" pTemplate="body">
                    <span class="flex-row">
                        <app-checkbox [ngModel]="isSelectedItem(simulation)" (ngModelChange)="selectItem(simulation)"></app-checkbox>
                    </span>
                </ng-template>
            </p-column>

            <!-- ACTIONS -->
            <p-column header="{{ 'SHIPPING_SIMULATION.LIST.ACTIONS' | translate }}" [sortable]="false">
                <ng-template let-simulation="rowData" pTemplate="body">
                    <div class="table-row-action-buttons">
                        <app-simple-button buttonId="search" icon="search" type="main-action" [size]="36"
                                           label="{{'SHIPPING_SIMULATION.ACTIONS.TOOLTIPS.SHOW_SHIPPING_SIMULATION_DETAILS' | translate}}" [inlineLabel]="false"
                                           (onClick)="showSimulationDetails(simulation)"></app-simple-button>
                    </div>
                </ng-template>
            </p-column>

            <!-- SHIPPING SIMULATION NAME -->
            <p-column field="name" sortField="name" [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                      [defaultFilterValue]="columnByField['name'].defaultFilterValue"
                      header="{{ 'SHIPPING_SIMULATION.LIST.NAME' | translate }}"></p-column>

            <!--CREATED DATE -->
            <p-column field="createdDate" [sortable]="true" styleClass="datatable-date-range-column"
                      [filter]="showFilters" filterMatchMode="contains"
                      [defaultFilterValue]="columnByField['createdDate'].defaultFilterValue"
                      header="{{ 'SHIPPING_SIMULATION.LIST.CREATED_DATE' | translate }}">

                <ng-template pTemplate="filter">
                    <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
                </ng-template>

                <ng-template let-item="rowData" pTemplate="body">
                    {{ item.createdDate | momentCurrentLang | amDateFormat: 'L' }}
                </ng-template>
            </p-column>

            <!-- PRICE -->
            <p-column field="price" [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                      [defaultFilterValue]="columnByField['price'].defaultFilterValue"
                      header="{{ 'SHIPPING_SIMULATION.LIST.PRICE' | translate }}">

                <ng-template let-item="rowData" pTemplate="body">
                    <span> {{ formatSimulationPrice(item) }} </span>
                </ng-template>
            </p-column>

            <!-- SLOTS -->
            <p-column field="slots" [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                      [defaultFilterValue]="columnByField['slots'].defaultFilterValue"
                      header="{{ 'SHIPPING_SIMULATION.LIST.SLOTS' | translate }}"></p-column>

            <!-- SUBSYSTEM -->
            <p-column *ngIf="isSubsystemColumnVisible()" field="subsystemName" [sortable]="true" [filter]="showFilters"
                      filterMatchMode="contains"
                      [defaultFilterValue]="columnByField['subsystemName'].defaultFilterValue"
                      header="{{ 'SHIPPING_SIMULATION.LIST.SUBSYSTEM' | translate }}">

                <ng-template pTemplate="filter">
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                        <p-multiSelect [options]="availableSubsystems"
                                       defaultLabel="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}"
                                       appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                       (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                    </div>
                </ng-template>
            </p-column>
        </p-dataTable>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>
<div class="dialog-window" *ngIf="multipleSimulationsCost != undefined">
    <p-dialog header="{{ 'SHIPPING_SIMULATION.SIDEBAR.ESTIMATED_PRICE' | translate }}"
              [visible]="multipleSimulationsCost != undefined" (onHide)="multipleSimulationsCost = undefined"
              [modal]="true" [resizable]="false" [focusOnShow]="false">
        <div class="new-form-400">
            <div class="new-form-row shipping-simulation-multiple-price">{{ multipleSimulationsCost }} EUR</div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button icon="check" type="main-action" [size]="40" label="{{ 'GENERAL.OK' | translate }}"
                                   (onClick)="multipleSimulationsCost = undefined"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
