import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {WebshopAreaBars} from './webshop-area-bars';

@Injectable()
export class WebshopAreaBarsService implements CrudService<WebshopAreaBars> {

    private static readonly API_URL = 'webshopAreaBars';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WebshopAreaBars>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(WebshopAreaBarsService.API_URL, {params: params})
            .pipe(mapListingToJson(WebshopAreaBars));
    }

    getItem(webshopAreaBarsId: number): Observable<WebshopAreaBars> {
        return this.http.get(`${WebshopAreaBarsService.API_URL}/${webshopAreaBarsId}`)
            .pipe(mapItemToJson(WebshopAreaBars));
    }

    addItem(webshopAreaBars: WebshopAreaBars): Observable<number> {
        throw new Error('New WebshopAreaBars cannot be created');
    }

    editItem(webshopAreaBarsId: number, webshopAreaBars: WebshopAreaBars): Observable<number> {
        return this.http.put<void>(WebshopAreaBarsService.API_URL, webshopAreaBars, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToExistingItemId(webshopAreaBarsId));
    }
}
