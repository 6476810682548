import {RandomIdGenerator} from "../utils/RandomIdGenerator";
import {HandleDirection} from "./HandleDirection";
import {HandleState} from "./HandleState";

export class Handle {

    generatedId: string;
    positionAngle: number;
    direction: HandleDirection;
    state: HandleState;

    constructor(positionAngle: number, direction: HandleDirection, state: HandleState) {
        if (positionAngle < 0 || positionAngle >= 360) {
            console.error("Nie można stworzyć klamki - " + positionAngle + " wykracza poza zakres kąta [0-359].");
            return;
        }
        this.generatedId = RandomIdGenerator.generate();
        this.positionAngle = positionAngle;
        this.direction = direction;
        this.state = state;
    }
}
