import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Dialog} from 'primeng/dialog';
import {forkJoin, of} from 'rxjs';
import {finalize} from 'rxjs/operators';
import * as _ from 'underscore';
import {DropDownExtraOptions} from "../../../../../../../shared/drop-down-extra-options";
import {AddonInterface} from '../../../../../../../window-designer/catalog-data/addon-interface';
import {Glazing} from '../../../../../../../window-designer/catalog-data/glazing';
import {WindowSystemType} from "../../../../../../../window-designer/catalog-data/window-system-interface";
import {DrawingData} from "../../../../../../../window-designer/drawing-data/drawing-data";
import {FillingType} from "../../../../../../../window-designer/drawing-data/FillingType";
import {StaticDataHelper} from '../../../../../../../window-designer/static-data-helper';
import {SubwindowTypes} from '../../../../../../../window-designer/subwindow-types';
import {
    AddonDefaultQuantityCalculator
} from '../../../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator';
import {GlassUpsellingValidationUtil} from '../../../../../../../window-designer/utils/glass-upselling-validation-util';
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {GlassSelectionValidator} from '../../../../../../common/glass-selection/GlassSelectionValidator';
import {SelectItemImpl} from '../../../../../../common/service/select.item.impl';
import {ValidationErrors} from '../../../../../../common/validation-errors';
import {SelectItemExtended} from "../../../../../../form-inputs/inputs/select/select.component";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {AddonsService} from '../../../../../window-system/addons/addons.service';
import {DistanceFrameService} from "../../../../../window-system/distance-frame/distance-frame.service";
import {DistanceFrameListing} from "../../../../../window-system/distance-frame/distanceFrame-list";
import {GlassService} from "../../../../../window-system/glass/glass.service";
import {GlassChangeEvent} from "../../../../../window-system/glass/glassChangeEvent";
import {GlassWithPosition} from "../../../../../window-system/glass/glassWithPositions";
import {GlazingBead} from "../../../../../window-system/glazing-bead/glazing-bead";
import {GlazingBeadService} from "../../../../../window-system/glazing-bead/glazing-bead.service";
import {GlazingPackage} from "../../../../../window-system/glazing-package/glazing-package";
import {SubwindowTypeService} from '../../../../../window-system/subwindow-type/subwindow-type.service';
import {PositionType} from '../../../../AbstractPosition';
import {GlazingBeadMatchingMode, GlazingBeadUtils} from "../../../../window-editor/drawing-tool/GlazingBeadUtils";
import {WindowDesignerComponent} from "../../../../window-editor/window-designer/window-designer.component";
import {Position} from "../position";
import {BulkGlassChangeDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-bulk-glass-change',
    templateUrl: './bulk-glass-change.component.html',
    styleUrls: ['../../../../../shared-styles.css'],
    providers: [GlassService, DistanceFrameService, GlazingBeadService, SubwindowTypeService]
})
export class BulkGlassChangeComponent implements OnInit {

    private static readonly SUBMIT_BULK_GLASS_CHANGE_DIALOG_ID = 'submitBulkGlassChangeDialog';
    private static readonly LOAD_DATA_ID = 'BulkGlassChangeComponent loadData';

    @Input()
    dialogData: BulkGlassChangeDialogData;

    @Output()
    onSubmit = new EventEmitter();

    @Output()
    onClose = new EventEmitter();

    glazing = new Glazing();
    glasses: GlassWithPosition[] = [];
    frames: DistanceFrameListing[] = [];
    boldGlasses: GlassWithPosition[] = [];
    boldFrames: DistanceFrameListing[] = [];
    availableGlazingBeads: GlazingBead[] = [];
    boldGlazingBeads: GlazingBead[] = [];
    filteredGlazingBeadList: SelectItemExtended[] = [];

    validationErrors: ValidationErrors = {};

    selectedGlazingBead: number;
    dataReady = false;

    @ViewChild(Dialog)
    dialog: Dialog;

    isTerrace: boolean;
    terraceGlazings: GlazingPackage[];
    selectedTerraceGlazing: GlazingPackage;
    formatGlazingOption: (glazing: GlazingPackage) => SelectItemExtended;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private glassService: GlassService,
                private frameService: DistanceFrameService,
                private glazingBeadService: GlazingBeadService,
                protected subwindowTypeService: SubwindowTypeService,
                protected addonsService: AddonsService,
                public translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController) {
    }

    ngOnInit() {
        this.isTerrace = this.dialogData.selectedOfferPositions[0].windowSystemType === WindowSystemType.TERRACE.type;
        if (this.isTerrace) {
            this.formatGlazingOption = (glazing: GlazingPackage) =>
                new SelectItemImpl(glazing.names[this.translate.currentLang], glazing);
        }
        this.loadData();
    }

    private loadData(): void {
        this.blockUiController.block(BulkGlassChangeComponent.LOAD_DATA_ID);
        let windowSystems = this.filteredOfferPositions().map(position => JSON.parse(position.data).windowSystemId);
        const filters = {
            active: {value: 'true'},
            windowSystemId: {value: windowSystems.join()},
            requireAllSystems: {value: 'false'}
        };
        const boldFilters = {
            active: {value: 'true'},
            windowSystemId: {value: windowSystems.join()},
            requireAllSystems: {value: 'true'}
        };
        forkJoin({
            glasses: this.glassService.getItems(0, null, filters, "sortIndex", 1),
            boldGlasses: this.glassService.getItems(0, null, boldFilters, "sortIndex", 1),
            frames: this.frameService.getItems(0, null, filters, "sortIndex", 1),
            boldFrames: this.frameService.getItems(0, null, boldFilters, "sortIndex", 1),
            glazingBeads: this.glazingBeadService.getItems(0, null, filters, "sortIndex", 1),
            boldGlazingBeads: this.glazingBeadService.getItems(0, null, boldFilters, "sortIndex", 1)
        }).pipe(
            finalize(() => this.blockUiController.unblock(BulkGlassChangeComponent.LOAD_DATA_ID))
        ).subscribe({
            next: data => {
                this.glasses = data.glasses.data;
                this.frames = data.frames.data;
                this.boldGlasses = data.boldGlasses.data;
                this.boldFrames = data.boldFrames.data;
                this.availableGlazingBeads = data.glazingBeads.data;
                this.boldGlazingBeads = data.boldGlazingBeads.data;
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.dataReady = true;
                this.changeDetector.markForCheck();
            }
        });
        if (this.isTerrace) {
            this.glassService.getActiveTerraceGlazings().pipe(
                finalize(() => this.blockUiController.unblock(BulkGlassChangeComponent.LOAD_DATA_ID))
            ).subscribe({
                next: (glazings: GlazingPackage[]) => {
                    this.terraceGlazings = glazings;
                },
                error: error => {
                    this.errors.handle(error);
                },
                complete: () => {
                    this.dataReady = true;
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    private filteredOfferPositions(): Position[] {
        return this.dialogData.selectedOfferPositions.filter(p => p.type === PositionType.SYSTEM);
    }

    private filteredConfigs(windowPositions: Position[]): Position[] {
        return this.dialogData.allOfferPositions
            .filter(pos => pos.type === PositionType.CONFIG_SYSTEM)
            .filter(pos => _.any(windowPositions, winPos => winPos.id === pos.parentOfferPositionId));
    }

    submitDialog(): void {
        let updatedDatas: { offerPosition: Position, mappedData: DrawingData }[];
        let copyOfferPosition = (offerPosition: Position) => {
            let position = new Position(offerPosition.name, offerPosition.type, null, null, offerPosition.quantity,
                null, null, null, null, null, null,
                null, null, null, null, null, offerPosition.offerId, null);
            position.id = offerPosition.id;
            position.parentOfferPositionId = offerPosition.parentOfferPositionId;
            position.printOrder = offerPosition.printOrder;
            return {offerPosition: position, mappedData: JSON.parse(offerPosition.data)};
        };

        updatedDatas = this.filteredOfferPositions().map(copyOfferPosition);
        let configs = this.filteredConfigs(this.filteredOfferPositions()).map(copyOfferPosition);

        for (let updateData of updatedDatas) {
            if (!this.isTerrace) {
                for (let window of updateData.mappedData.windows) {
                    for (let subWindow of window.subWindows) {
                        for (let area of subWindow.areasSpecification) {
                            this.glazing.glazingGlassQuantity === 0 ?
                                area.filling.type = FillingType.NO_FILLING :
                                area.filling.type = FillingType.GLASS;
                            for (let i = 1; i <= WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX; i++) {
                                area.glazing['glass' + i + 'id'] = this.glazing['glass' + i + 'id'];
                                if (i < WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX) {
                                    area.glazing['frame' + i + 'id'] = this.glazing['frame' + i + 'id'];
                                }
                            }
                            area.glazing.glazingGlassQuantity = this.glazing.glazingGlassQuantity;
                            area.glazingBead.id = this.selectedGlazingBead;
                        }
                    }
                }
            } else {
                let staticData = new StaticDataHelper();
                staticData.glasses = this.glasses;
                staticData.frames = this.frames;
                GlassUpsellingValidationUtil.applyGlazingBasedOnArea(this.selectedTerraceGlazing.glazingPackageForAreaRangeList,
                    staticData, updateData.mappedData,
                    this.availableGlazingBeads.map(gb => ({
                        id: gb.id,
                        active: gb.active,
                        acceptableFillingWidth: GlazingBead.parseAcceptableFillingWidth(gb)
                    })));
                updateData.mappedData.specification.terraceGlazingPackageId = this.selectedTerraceGlazing.id;
                updateData.offerPosition.data = JSON.stringify(updateData.mappedData);
            }

            updateData.offerPosition.data = JSON.stringify(updateData.mappedData);
        }

        if (!this.isTerrace) {
            configs.forEach(config => {
                config.mappedData.glazingBeadId = this.selectedGlazingBead;
                config.offerPosition.data = JSON.stringify(config.mappedData);
            });
        }

        if (!this.validateFinalForm()) {
            return;
        }

        if (!this.isTerrace) {
            let addonIds = _.unique(_.flatten(updatedDatas.map(ud => ud.mappedData.addons.map(addon => addon.addonId))));
            this.blockUiController.block(BulkGlassChangeComponent.SUBMIT_BULK_GLASS_CHANGE_DIALOG_ID);
            forkJoin({
                subwindowTypes: this.subwindowTypeService.getAll(),
                addonDtos: addonIds.length > 0 ? this.addonsService.getItemsByIds(addonIds, false) : of<AddonInterface[]>([])
            }).pipe(
                finalize(() => this.blockUiController.unblock(BulkGlassChangeComponent.SUBMIT_BULK_GLASS_CHANGE_DIALOG_ID))).subscribe({
                next: data => {
                    if (data) {
                        let subwindowTypes = new SubwindowTypes(data.subwindowTypes);
                        let staticData = new StaticDataHelper();
                        staticData.glasses = this.glasses;
                        updatedDatas.forEach(
                            ud => {
                                AddonDefaultQuantityCalculator.recalculateAllOnGlobalChange(ud.mappedData, subwindowTypes, data.addonDtos, staticData);
                                ud.offerPosition.data = JSON.stringify(ud.mappedData);
                            });

                        this.dialogData.modifiedPositions = [...updatedDatas, ...configs].map(updateData => updateData.offerPosition);
                        this.dialogHideHelper.call(() => this.onSubmit.emit());
                    }
                },
                error: (error) => {
                    this.errors.handle(error);
                    this.onClose.emit();
                    return of(false);
                }
            });
        } else {
            this.dialogData.modifiedPositions = [...updatedDatas, ...configs].map(data => data.offerPosition);
            this.dialogHideHelper.call(() => this.onSubmit.emit());
        }
    }

    resetDialog(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    private validateFinalForm(): boolean {
        if (!this.isTerrace) {
            this.validationErrors = Object.assign({}, new GlassSelectionValidator().validate(this.glazing));
            if (this.selectedGlazingBead == undefined) {
                this.validationErrors['selectedGlazingBeads'] = 'error.offerPosition.bulkChange.selection.not_empty';
            }
        } else {
            if (this.selectedTerraceGlazing == undefined) {
                this.validationErrors['selectedTerraceGlazing'] = 'error.offerPosition.bulkChange.selection.not_empty';
            }
        }

        return Object.keys(this.validationErrors).filter(field => this.validationErrors[field] != undefined).length === 0;
    }

    onGlassQnChange(newValue: number): void {
        setTimeout(() => this.dialog.center(), 1);
        this.glazing.glazingGlassQuantity = newValue;
        this.onGlassObjectChange();
    }

    onGlassChange(event: GlassChangeEvent): void {
        this.glazing['glass' + event.glassPosition + 'id'] = event.newValue;
        this.onGlassObjectChange();
    }

    onFrameChange(): void {
        this.onGlassObjectChange();
    }

    private onGlassObjectChange(): void {
        let filteredGlazingBeadList = GlazingBeadUtils.filterGlazingBeadsMatchingWidth(this.availableGlazingBeads,
            this.glazing, GlazingBeadMatchingMode.BULK_GLASS_CHANGE, this.glasses, this.frames);

        if (filteredGlazingBeadList.length > 0) {
            this.selectedGlazingBead = filteredGlazingBeadList[0].id;
            this.validationErrors['selectedGlazingBeads'] = undefined;
        } else {
            this.selectedGlazingBead = undefined;
        }
        this.filteredGlazingBeadList = filteredGlazingBeadList.map(glazingBead => {
            return {
                label: glazingBead.names[this.translate.currentLang],
                value: glazingBead.id,
                bold: this.boldGlazingBeads.findIndex(bgb => bgb.id === glazingBead.id) >= 0
            };
        });
        const firstBold = this.filteredGlazingBeadList.findIndex(si => si.bold);
        if (firstBold >= 0) {
            this.filteredGlazingBeadList.unshift({
                label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_ALL'),
                value: DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE,
                bold: true,
                center: true,
                disabled: true
            });
        }
        const firstNonBold = this.filteredGlazingBeadList.findIndex(si => !si.bold);
        if (firstNonBold >= 0) {
            this.filteredGlazingBeadList.splice(firstNonBold, 0, {
                label: this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_SOME'),
                value: DropDownExtraOptions.DO_NOT_CHANGE_ID_VALUE,
                bold: true,
                center: true,
                disabled: true
            });
        }
    }
}
