import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class TemporaryDealerDiscount {
    productionOrderId: number;
    discountValue: string;
    cascade: boolean;

    static fromJSON(jsonObject: any): TemporaryDealerDiscount {
        const temporaryDealerDiscount = new TemporaryDealerDiscount();
        temporaryDealerDiscount.productionOrderId = jsonObject.productionOrderId;
        temporaryDealerDiscount.discountValue = jsonObject.discountValue;
        temporaryDealerDiscount.cascade = jsonObject.cascade;
        return temporaryDealerDiscount;
    }
}
