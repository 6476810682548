<div class="dialog-window">
    <p-dialog [visible]="true" [resizable]="false" (onHide)="hide()" [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title">{{ "USER-DETAILS.CHANGE-USER-TITLE" | translate }}</div>
        </p-header>
        <div class="new-form-400">
            <div class="new-form">
                <app-select inputId="subSupplier"
                            label="{{ 'USER-DETAILS.FORM.NEW-USER-FOR-CLIENTS' | translate }}"
                            [(validationMessageKey)]="validationErrors['selectedSubstituteUser']"
                            [options]="substituteUsers" [(ngModel)]="selectedSubstituteUser"></app-select>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                                   [disabled]="isNotPermitted({roles:['ROLE_EDIT_SUPPLIER', 'ROLE_OPERATOR']})"
                                   (onClick)="moveClientsToUser()"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="hide()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
