import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IsAnotherUserLoggedService} from "../../features/login/isAnotherUserLogged";
import {SupportedLanguages} from "../../supportedLanguages";
import {StorageKey, StorageService} from "../../auth/storage.service";
import {ChangeLanguageService} from "../../features/login/change-language.service";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
    selector: 'app-languages-list',
    templateUrl: './languages-list.component.html',
    styleUrls: ['./languages-list.component.css', '../../features/shared-styles.css']
})
export class LanguagesListComponent implements OnInit, OnDestroy {

    readonly supportedLanguages = SupportedLanguages.languages;

    @Input()
    stretched = false;

    @Input() set height(value: number) {
        if (value) {
            this._height = value + 'px';
        }
    }
    _height = '80px';

    @Input()
    showActive = true;

    @Input()
    selectable = false;

    @Output()
    languageSelected: EventEmitter<string> = new EventEmitter<string>();

    public activeLanguage: string;
    private subscription: Subscription;

    constructor(private readonly isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private readonly storage: StorageService,
                private readonly changeLanguage: ChangeLanguageService,
                private readonly translate: TranslateService) {
    }

    ngOnInit() {
        if (this.showActive) {
            this.subscription = this.changeLanguage.languagedChanged.subscribe((newLanguage) => {
                this.setActiveLanguage(newLanguage);
            });
            this.supportedLanguages.forEach(language => {
                this.setActiveLanguage(language.code);
            });
        }
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public changeLang(langName: string): void {
        if (this.selectable) {
            this.languageSelected.emit(langName);
        } else {
            this.changeLanguage.changeLng(langName);
            if (this.isAnotherUserLoggedService.isImpersonating) {
                this.storage.set(StorageKey.CURRENT_USER_INTERFACE_LANGUAGE, langName);
            } else {
                this.storage.set(StorageKey.INTERFACE_LANGUAGE, langName);
                this.translate.use(langName);
            }
            this.activeLanguage = langName;
        }
    }

    public isLanguageActive(languageCode: string) {
        let isAnotherLogged = this.isAnotherUserLoggedService.isImpersonating;
        let active = isAnotherLogged ? this.storage.get(StorageKey.CURRENT_USER_INTERFACE_LANGUAGE) :
            this.storage.get(StorageKey.INTERFACE_LANGUAGE);
        return active === languageCode;
    }

    private setActiveLanguage(newLanguage: string) {
        if (this.isLanguageActive(newLanguage)) {
            this.activeLanguage = newLanguage;
        }
    }
}
