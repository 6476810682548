import {JsonDeserializable} from '../../../common/crud-common/crudItem';

export enum WebshopHelpType {
    GLAZING = 'GLAZING',
    WINDOW_SIZING = 'WINDOW_SIZING',
    WEBSHOP = 'WEBSHOP'
}

@JsonDeserializable
export class WebshopHelp {

    id: number;
    type: WebshopHelpType;
    language: string;
    description: string;
    amazonS3FileName: string;

    static fromJSON(jsonObject: any): WebshopHelp {
        const webshopGlazingHelp = new WebshopHelp();
        webshopGlazingHelp.id = jsonObject.id;
        if (jsonObject.type != undefined) {
            webshopGlazingHelp.type = WebshopHelpType[jsonObject.type as string];
        }
        webshopGlazingHelp.language = jsonObject.language;
        webshopGlazingHelp.description = jsonObject.description;
        webshopGlazingHelp.amazonS3FileName = jsonObject.amazonS3FileName;
        return webshopGlazingHelp;
    }
}
