import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {ExchangeService} from "../../../../../../common/exchange.service";
import {TranslatedSelectItemService} from '../../../../../../common/service/translated-select-item.service';
import {Currencies} from '../../../../../../currencies';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {MultiValidator} from '../../../../../../shared/validator/input-validator';
import {PositionService} from "../../position.service";
import {PriceChangeDialogData} from '../position-list-dialogs';

export class PriceChangeFailedEvent {
    actionMessage: string;
    error: HttpErrorResponse;
}

@Component({
    selector: 'app-price-change',
    templateUrl: './price-change.component.html',
    styleUrls: ['./price-change.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceChangeComponent implements OnInit {

    @Input()
    displayedDialogData: PriceChangeDialogData;

    @Output()
    onSubmit = new EventEmitter<string>();

    @Output()
    onError = new EventEmitter<PriceChangeFailedEvent>();

    @Output()
    onCancel = new EventEmitter();

    buySellOptions: Observable<SelectItem[]>;
    netGrossOptions: Observable<SelectItem[]>;
    validationErrors = {};
    isInPln = true;
    priceInCurrency: number | string = '';
    exchangeRateInfo: string;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(public positionService: PositionService,
                private translatedSelectItemService: TranslatedSelectItemService) {
        this.netGrossOptions = this.translatedSelectItemService.buildUnsortedDropdown([true, false], (isNet) => {
            return isNet ? 'OFFER.POSITIONS.DIALOGS.PRICE_CHANGE.NET' : 'OFFER.POSITIONS.DIALOGS.PRICE_CHANGE.GROSS';
        }, undefined);
    }

    ngOnInit() {
        if (this.displayedDialogData.canChangeBuyPrice) {
            this.buySellOptions = this.translatedSelectItemService.buildUnsortedDropdown([true, false], (isBuy) => {
                return isBuy ? 'OFFER.POSITIONS.DIALOGS.PRICE_CHANGE.BUY' : 'OFFER.POSITIONS.DIALOGS.PRICE_CHANGE.SELL';
            }, undefined);
        }
        this.isInPln = this.displayedDialogData.currency === Currencies.PLN;
        this.exchangeRateInfo = `(${ExchangeService.formatPriceInCurrency('1', this.displayedDialogData.currency)} = ${this.displayedDialogData.exchangeRate} PLN)`;
    }

    submitDialog() {
        if (this.validateForm()) {
            let data = this.displayedDialogData;
            let actionMessage = 'PRICE_CHANGE';
            if (data.net) {
                if (data.buy) {
                    actionMessage = 'PRICE_CHANGE_NET_BUY';
                } else {
                    actionMessage = 'PRICE_CHANGE_NET_SELL';
                }
            } else {
                if (data.buy) {
                    actionMessage = 'PRICE_CHANGE_GROSS_BUY';
                } else {
                    actionMessage = 'PRICE_CHANGE_GROSS_SELL';
                }
            }
            this.positionService.changePrice(data.position.id, data.price, data.net, data.buy).subscribe({
                next: () => {
                    this.dialogHideHelper.call(() => this.onSubmit.emit(actionMessage));
                },
                error: (error): void => {
                    this.dialogHideHelper.call(() => this.onError.emit({actionMessage: actionMessage, error: error}));
                }
            });
        }
    }

    resetDialog() {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }

    validateForm() {
        let validationError = this.isValueValid();
        if (validationError != undefined) {
            this.validationErrors = {price: validationError};
            return false;
        }
        return true;
    }

    private isValueValid(): string {
        return MultiValidator.of('error.offerPositionPriceChangeDto.price')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(0, null, true, false)
            .validate(this.displayedDialogData.price_text);
    }

    onPriceChange(priceText: string): void {
        this.displayedDialogData.price_text = priceText;
        if (!this.isInPln) {
            if (this.isValueValid() == null) {
                const value = Number(priceText.toString().replace(",", "."));
                this.priceInCurrency = this.formatPriceInOfferCurrency(value);
            } else {
                this.priceInCurrency = '';
            }
        }
    }

    private formatPriceInOfferCurrency(price: number): number {
        return +(price / this.displayedDialogData.exchangeRate).toFixed(2);
    }
}
