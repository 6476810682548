import {SubWindowData} from "../drawing-data/SubWindowData";
import {SubWindowTypeCode} from "../window-types/subwindow-type-code";

export class UpsellingUtils {

    public static readonly UPSELLING_CHARGE_DUMMY_ID = 'upselling-charge-dummy';
    public static readonly VENTILATION_ERROR = 'Upselling failed - no valid room for upselled ventilation';

    public static upsellingDummySubwindow(): SubWindowData {
        let sw = new SubWindowData();
        sw.generatedId = UpsellingUtils.UPSELLING_CHARGE_DUMMY_ID;
        sw.areasSpecification = [];
        sw.mullions = [];
        sw.typeCode = SubWindowTypeCode.F;
        sw.points = [0, 0, 0, 0];
        return sw;
    }

    public static noValidSubwindowForVentilationError(): Error {
        let error = new Error(UpsellingUtils.VENTILATION_ERROR);
        error.name = UpsellingUtils.VENTILATION_ERROR;
        return error;
    }
}
