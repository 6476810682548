export class SubwindowTypeLimitations {

    arcable: boolean;
    maxCutCount: number;
    minSideLength: number;

    constructor() {
        this.arcable = false;
        this.maxCutCount = 0;
    }
}
