import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {forkJoin, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Currencies, CURRENCIES_WITH_PREFIX_FORMAT} from '../currencies';
import {ScopeValidator} from '../shared/validator/input-validator';

class ExchangeRate {

    effectiveDate: Date;
    to: Currencies;
    rate: number;

    public static fromJSON(jsonObject: any): ExchangeRate {
        let exchangeRate = new ExchangeRate();
        exchangeRate.effectiveDate = new Date(jsonObject.effectiveDate);
        exchangeRate.to = jsonObject.to;
        exchangeRate.rate = jsonObject.rate;
        return exchangeRate;
    }
}

@Injectable()
export class ExchangeService {

    currencyToPlnRatio: Map<Currencies, number> = new Map<Currencies, number>();
    rangeFilterValidator: ScopeValidator;

    constructor(private http: HttpClient) {
        this.rangeFilterValidator = new ScopeValidator();
    }

    initializeExchangeRates(): Observable<ExchangeRate[]> {
        return forkJoin(Object.keys(Currencies).map(k => {
            let currency = Currencies[k];
            if (currency === 'PLN') {
                return of({effectiveDate: new Date(), to: Currencies.PLN, rate: 1});
            }
            return this.getCurrentExchangeRates(currency);
        }));
    }

    storeExchangeRates(rates: ExchangeRate[]): void {
        for (let exchangeRate of rates) {
            this.currencyToPlnRatio.set(exchangeRate.to, exchangeRate.rate);
        }
    }

    private getCurrentExchangeRates(code: Currencies): Observable<ExchangeRate> {
        return this.http.get<object>(`exchangeRates/${code}`).pipe(map(response => ExchangeRate.fromJSON(response)));
    }

    getPLNPriceInCurrency(price: number, currency: Currencies): string {
        return this.getPriceInCurrency(price, Currencies.PLN, currency);
    }

    getCurrentRatio(currency: Currencies): number {
        return this.currencyToPlnRatio.get(currency);
    }

    getPriceInCurrency(price: number, fromCurrency: Currencies, toCurrency: Currencies,
                       fromCurrencyRateOverride?: number, toCurrencyRateOverride?: number): string {
        if (price == null) {
            return '';
        }
        return this.calculatePriceInCurrency(price, fromCurrency, toCurrency,
            fromCurrencyRateOverride, toCurrencyRateOverride).toFixed(2);
    }

    private calculatePriceInCurrency(price: number, fromCurrency: Currencies, toCurrency: Currencies,
                                     fromCurrencyRateOverride?: number, toCurrencyRateOverride?: number): number {
        if (fromCurrency === toCurrency) {
            return price;
        }
        const fromRatio = fromCurrencyRateOverride != undefined ? fromCurrencyRateOverride : this.getCurrentRatio(fromCurrency);
        const toRatio = toCurrencyRateOverride != undefined ? toCurrencyRateOverride : this.getCurrentRatio(toCurrency);
        let exchangeRatio = fromRatio / toRatio;
        return price * exchangeRatio;
    }

    public applyRateToRangeFilters(fields: string[], event: LazyLoadEvent, fromCurrency: Currencies, toCurrency = Currencies.PLN): void {
        // deep copy filters
        let filtersCopy: { [s: string]: FilterMetadata; } = {};
        for (let field in event.filters) {
            let filter = event.filters[field];
            if (filter != undefined) {
                filtersCopy[field] = {value: filter.value, matchMode: filter.matchMode};
            }
        }
        for (let field of fields) {
            let filter = filtersCopy[field];
            if (filter != undefined) {
                if (this.rangeFilterValidator.isValid(filter.value)) {
                    const {from, to} = this.rangeFilterValidator.getMatchedLimits(filter.value);
                    if (fromCurrency === toCurrency) {
                        filter.value = `(${from.toFixed(2)})-(${to.toFixed(2)})`;
                    } else {
                        const fromInCurrency = this.calculatePriceInCurrency(from - 0.004999, fromCurrency, toCurrency);
                        const toInCurrency = this.calculatePriceInCurrency(to + 0.004999, fromCurrency, toCurrency);
                        filter.value = `(${fromInCurrency.toFixed(2)})-(${toInCurrency.toFixed(2)})`;
                    }
                } else {
                    filtersCopy[field] = undefined;
                }
            }
        }
        event.filters = filtersCopy;
    }

    public static getPriceInDefaultCurrency(price: number, exchangeRate: number, subsystemManualExchangeRate?: number): string {
        if (price == null) {
            return '';
        }
        let inCurrency;
        if (subsystemManualExchangeRate) {
            inCurrency = price / subsystemManualExchangeRate;
        } else {
            inCurrency = price / exchangeRate;
        }
        return inCurrency.toFixed(2);
    }

    public static formatPriceInCurrency(price: string, currencyAbbrv: string): string {
        if (price == null || price === '') {
            return '';
        }
        if (CURRENCIES_WITH_PREFIX_FORMAT.includes(currencyAbbrv as Currencies)) {
            return `${currencyAbbrv} ${price}`;
        } else {
            return `${price} ${currencyAbbrv}`;
        }
    }
}
