import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {Observable} from 'rxjs';
import {TerraceHandleLayout} from '../../../../../../../window-designer/drawing-data/TerraceHandleLayout';
import {WindowData} from '../../../../../../../window-designer/drawing-data/WindowData';
import {OpeningDirection} from '../../../../../../../window-designer/window-types/opening-direction';
import {OpeningDirectionUtils} from '../../../../../../../window-designer/window-types/opening-direction-utils';
import {WindowEditorField} from '../../../window-editor-field';
import {WindowEditorFieldContentProvider} from '../../../window-editor-field-content-provider';

@Component({
    selector: 'app-terrace-handle-layout-selection-dialog',
    templateUrl: './terrace-handle-layout-selection-dialog.component.html',
    styleUrls: ['./terrace-handle-layout-selection-dialog.component.css']
})
export class TerraceHandleLayoutSelectionDialogComponent implements OnInit {

    private static readonly FILE_PATH = 'assets/images/terrace-handle-layouts';

    @Input()
    terraceWindow: WindowData;

    @Input()
    terraceHandleLayout: string;

    @Output()
    readonly terraceHandleLayoutChange = new EventEmitter<string>();

    @Output()
    readonly onClose = new EventEmitter<void>();

    @ViewChild('dialog', {static: true})
    dialog: Dialog;

    visible = true;

    selectedTerraceHandleLayout: string;

    constructor(public readonly windowEditorFieldContentProvider: WindowEditorFieldContentProvider) {
    }

    ngOnInit(): void {
        this.selectedTerraceHandleLayout = this.terraceHandleLayout;
    }

    get options(): Observable<SelectItem[]> {
        return this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.TERRACE_HANDLE_LAYOUT);
    }

    submit(event: MouseEvent): void {
        this.terraceHandleLayoutChange.emit(this.selectedTerraceHandleLayout);
        this.close(event);
    }

    close(event: MouseEvent): void {
        this.dialog.close(event);
    }

    getFilePath(layout: TerraceHandleLayout): string {
        if (this.terraceWindow === undefined) {
            return undefined;
        }
        let typeCode = this.terraceWindow.typeCode.toString();
        if (this.needsFlipping()) {
            typeCode = typeCode.split('CPP').join('CPL');
        }
        return `${TerraceHandleLayoutSelectionDialogComponent.FILE_PATH}/${typeCode}-${layout}.svg`;
    }

    needsFlipping(): boolean {
        if (this.terraceWindow === undefined) {
            return undefined;
        }
        return this.terraceWindow.subWindows.map(sw => sw.typeCode)
            .every(code => OpeningDirectionUtils.getSubwindowOpening(code) === OpeningDirection.P);
    }

    centerDialog() {
        setTimeout(() => this.dialog.center(), 1);
    }
}
