import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from './app/app.module';

import {environment} from './environments/environment';
import {configureQuill} from './quill.config';

if (environment.production) {
    enableProdMode();
}

configureQuill();

platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: true });
