import {Pipe, PipeTransform} from '@angular/core';
import {SubsystemNoteCategory} from "../../../window-system/subsystem-notes-categories/subsystem-note-category";

@Pipe({
  name: 'subsystemNoteCategoryName'
})
export class SubsystemNoteCategoryNamePipe implements PipeTransform {

    transform(categoryId: number, categoriesList: SubsystemNoteCategory[], language: string): string {
        if (categoriesList.length === 0) {
            return null;
        }
        return categoriesList.filter(category => category.id === categoryId)[0].name[language];
    }

}
