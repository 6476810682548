import {AddonInterface} from '../../../../window-designer/catalog-data/addon-interface';
import {ColorInterface} from '../../../../window-designer/catalog-data/color-interface';
import {Price} from "../../../../window-designer/catalog-data/Price";
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {AddonCategoryEnum} from '../../../../window-designer/enums/AddonCategoryEnum';
import {MultilanguageField} from '../../../supportedLanguages';
import {AddonAvailableIn} from "../../../../window-designer/enums/addon-available-in.enum";
import {QuantityType} from "../../../../window-designer/enums/QuantityType";

export class Addon implements AddonInterface {
    id: number;
    name = new MultilanguageField();
    shortcut = new MultilanguageField();
    symbol: string;
    category: string;
    addonFor: string;
    supplier: SupplierInterface;
    subsystemId: number;
    price = new Price();
    defaultQuantity: any;
    quantityType: QuantityType;
    changeQuantity = true;
    chooseColor: boolean;
    insideColors: ColorInterface[] = [];
    outsideColors: ColorInterface[] = [];
    coreColors: ColorInterface[] = [];
    pcn: string;
    active = true;
    sortIndex: number;
    removable = true;
    profitMarginWindowSystemId: number;
    customNumericValue: number;
    customStringValue: string;
    availableIn: AddonAvailableIn;
    additionalComment: MultilanguageField;
    additionalIcon: string;
    singlePositionAddon: boolean;
    showGateDisclaimer: boolean;
    unitWeight: number;
    autoOption: AutoOption = AutoOption.NONE;
    tile: string;
    hex: string;
}

export interface AddonCategoryCount {
    category: AddonCategoryEnum;
    addonCategoryId: number;
    count: number;
}

export enum AutoOption {
    NONE = 'NONE', AUTO = 'AUTO', RIGHT = 'RIGHT', LEFT = 'LEFT'
}
