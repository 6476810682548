import {Address} from "../../common/address";
import {JsonDeserializable} from '../../common/crud-common/crudItem';

@JsonDeserializable
export class Client {
    id: number;
    groupId: number;
    groupName: string;
    name: string;
    address: Address;
    identifier: string;
    phone: string;
    email: string;
    description: string;
    owner: string;
    active: boolean;
    bulkAddonProfitMargin: string;
    createdDate: Date;

    constructor() {
        this.groupName = undefined;
        this.name = undefined;
        this.address = new Address();
        this.identifier = undefined;
        this.phone = undefined;
        this.email = undefined;
        this.description = undefined;
        this.owner = undefined;
        this.active = true;
        this.bulkAddonProfitMargin = undefined;
        this.createdDate = undefined;
    }

    public static fromJSON(jsonObject: any): Client {
        let client = new Client();
        client.id = jsonObject.id;
        client.groupId = jsonObject.groupId;
        client.groupName = jsonObject.groupName;
        client.name = jsonObject.name;
        if (jsonObject.address != undefined) {
            client.address = Address.fromJSON(jsonObject.address);
        } else {
            client.address = new Address();
        }
        client.identifier = jsonObject.identifier;
        client.phone = jsonObject.phone;
        client.email = jsonObject.email;
        client.description = jsonObject.description;
        client.owner = jsonObject.owner;
        client.active = jsonObject.active;
        client.bulkAddonProfitMargin = jsonObject.bulkAddonProfitMargin;
        client.createdDate = jsonObject.createdDate;
        return client;
    }
}
