export class ErrorNames {
    static readonly GENERAL_ERROR = 'ERRORS.GENERAL_ERROR';
    static readonly NOT_IMPLEMENTED = 'ERRORS.NOT_IMPLEMENTED';
    static readonly UNKNOWN_GRILL_TYPE = 'ERRORS.UNKNOWN_GRILL_TYPE';
    static readonly UNKNOWN_SHAPE_TYPE = 'ERRORS.UNKNOWN_SHAPE_TYPE';
    static readonly ARC_HEIGHT_TOO_BIG_FOR_GIVEN_WIDTH = 'ERRORS.ARC_HEIGHT_TOO_BIG_FOR_GIVEN_WIDTH';
    static readonly ALIGMENT_TOOL_ALREADY_ALIGNED = 'ERRORS.ALIGMENT_TOOL_ALREADY_ALIGNED';
    static readonly ALIGMENT_TOOL_TOO_FEW_SELECTED_ELEMENTS = 'ERRORS.ALIGMENT_TOOL_TOO_FEW_SELECTED_ELEMENTS';
    static readonly ALIGMENT_TOOL_SELECTED_ELEMENTS_NOT_IN_LINE = 'ERRORS.ALIGMENT_TOOL_SELECTED_ELEMENTS_NOT_IN_LINE';
    static readonly ALIGMENT_TOOL_INCLINED_MULLION_PRESENT = 'ERRORS.ALIGMENT_TOOL_INCLINED_MULLION_PRESENT';
    static readonly ALIGMENT_TOOL_NONFRAME_MULLION_PRESENT = 'ERRORS.ALIGMENT_TOOL_NONFRAME_MULLION_PRESENT';
    static readonly SELECTED_ELEMENT_UKNOWN_TYPE = 'ERRORS.SELECTED_ELEMENT_UKNOWN_TYPE';
    static readonly SELECTED_ELEMENT_DOES_NOT_EXIST = 'ERRORS.SELECTED_ELEMENT_DOES_NOT_EXIST';
    static readonly INTERSECTIONS_CALCULATION_FAILED = 'ERRORS.INTERSECTIONS_CALCULATION_FAILED';
    static readonly ILLEGAL_OPERATION_INVOKED = 'ERRORS.ILLEGAL_OPERATION_INVOKED';
    static readonly GRILL_ILLEGAL_POSITION = 'ERRORS.GRILL_ILLEGAL_POSITION';
    static readonly MULLION_ILLEGAL_POSITION = 'ERRORS.MULLION_ILLEGAL_POSITION';
    static readonly GRILL_FAILED_TO_CHANGE_POSITION = 'ERRORS.GRILL_FAILED_TO_CHANGE_POSITION';
    static readonly GRILL_CANNOT_BEND_GRILL_ON_RESIZE = 'ERRORS.GRILL_CANNOT_BEND_GRILL_ON_RESIZE';
    static readonly MULLION_CANNOT_BEND_GRILL_ON_RESIZE = 'ERRORS.MULLION_CANNOT_BEND_GRILL_ON_RESIZE';
    static readonly RESIZE_FAILED = 'ERRORS.RESIZE_FAILED';
    static readonly RESIZE_FAILED_ALL_DIMENSIONS_LOCKED = 'ERRORS.RESIZE_FAILED_ALL_DIMENSIONS_LOCKED';
    static readonly RESIZE_FAILED_CUTS_LAND_ON_SINGLE_LINE = 'ERRORS.RESIZE_FAILED_CUTS_LAND_ON_SINGLE_LINE';
    static readonly RESIZE_FAILED_CUTS_ANGLE_CROSSES_180 = 'ERRORS.RESIZE_FAILED_CUTS_ANGLE_CROSSES_180';
    static readonly CANNOT_ADD_MULLION_ON_CORNER = 'ERRORS.CANNOT_ADD_MULLION_ON_CORNER';
    static readonly MULLIONS_CROSSING_NOT_ALLOWED = 'ERRORS.MULLIONS_CROSSING_NOT_ALLOWED';
    static readonly FRAMES_TO_CLOSE = 'ERRORS.FRAMES_TO_CLOSE';
    static readonly MULLION_TO_CLOSE_TO_ANOTHER_MULLION = 'ERRORS.MULLION_TO_CLOSE_TO_ANOTHER_MULLION';
    static readonly MULLION_CAN_BE_HORIZONTAL_ONLY = 'ERRORS.MULLION_CAN_BE_HORIZONTAL_ONLY';
    static readonly MULLION_TO_CLOSE_TO_FRAME = 'ERRORS.MULLION_TO_CLOSE_TO_FRAME';
    static readonly GRILL_TO_CLOSE_TO_FRAME = 'ERRORS.GRILL_TO_CLOSE_TO_FRAME';
    static readonly GRILL_TO_CLOSE_TO_ANOTHER_GRILL = 'ERRORS.GRILL_TO_CLOSE_TO_ANOTHER_GRILL';
    static readonly AREA_WITHOUT_GLASS_CONTAINS_GRILLS = 'ERRORS.AREA_WITHOUT_GLASS_CONTAINS_GRILLS';
    static readonly GRILL_ALONGSIDE_FRAME = 'ERRORS.GRILL_ALONGSIDE_FRAME';
    static readonly ESG_GLASS_NEEDS_CHANGING = 'ERRORS.ESG_GLASS_NEEDS_CHANGING';
    static readonly MIRROR_TOOL_FAILED = 'ERRORS.MIRROR_TOOL_FAILED';
    static readonly TRIM_WOULD_REMOVE_SUBWINDOW = 'ERRORS.TRIM_WOULD_REMOVE_SUBWINDOW';
    static readonly NO_MIRROR_TYPE_FOR_SUBWINDOW = 'ERRORS.NO_MIRROR_TYPE_FOR_SUBWINDOW';
    static readonly NO_MIRROR_TYPE_FOR_BUSINNSS_TYPE = 'ERRORS.NO_MIRROR_TYPE_FOR_BUSINESS_TYPE';
    static readonly BAD_NEIGHBOUR_BUSINNSS_TYPE = 'ERRORS.BAD_NEIGHBOUR_BUSINNSS_TYPE';
    static readonly BUSINESS_TYPE_NAME_MISSING = 'ERRORS.BUSINESS_TYPE_NAME_MISSING';
    static readonly CONSTRUCTION_TOO_BIG_ERROR = 'ERRORS.CONSTRUCTION_TOO_BIG_ERROR';
    static readonly DEALER_MARKUP_NOT_FOUND = 'error.pricing.dealerMarkupPercentageNotFound';
    static readonly SUBSYSTEM_MARKUP_NOT_FOUND = 'error.pricing.subsystemMarkupPercentageNotFound';
    static readonly CLIENT_MARKUP_NOT_FOUND = 'error.pricing.clientMarkupPercentageNotFound';
    static readonly SELLER_CLIENT_MARKUP_NOT_FOUND = 'error.pricing.sellerClientMarkupPercentageNotFound';
    static readonly MARKUP_NOT_FOUND = 'error.pricing.venskaPriceNotSet';
    static readonly CLIENT_MARKUP_NOT_FOUND_FOR_SELLER = 'error.pricing.clientPriceNotSet'; // displayed to sellers when client margin isnt set
    static readonly ADDON_DEALER_MARKUP_NOT_FOUND = 'error.pricing.dealerAddonMarkupPercentageNotFound';
    static readonly ADDON_SUBSYSTEM_MARKUP_NOT_FOUND = 'error.pricing.subsystemAddonMarkupPercentageNotFound';
    static readonly ADDON_CLIENT_MARKUP_NOT_FOUND = 'error.pricing.clientAddonMarkupPercentageNotFound';
    static readonly ADDON_MARKUP_NOT_FOUND = 'error.pricing.venskaPriceNotSetContactAdmin';
    static readonly CLIENT_MARKUP_IS_0 = 'error.pricing.clientProfitMarginIsZeroWarning';
    static readonly ADDON_CLIENT_MARKUP_IS_0 = 'error.pricing.clientAddonProfitMarginIsZeroWarning';
    static readonly BUSINESSTYPE_MISSING_VALUE = 'error.windowPainter.newWindowBusinessTypeMissingValue';
    static readonly CONFIG_ADDONS_AUTOMATICALLY_REMOVED = 'error.windowPainter.configAddonsRemoved';
    static readonly VENTILATION_ADDONS_AUTOMATICALLY_REMOVED = 'error.windowPainter.ventilationAddonsRemoved';
    static readonly OUTER_GRILL_CANNOT_DEPEND_ON_INNER_GRILLS = 'error.validation.outer_grill_cannot_depend_on_inner_grills';
    static readonly FRAMES_CHANGED_TO_FIT_GRILL = 'error.validation.glazing_frames_changed_to_fit_grill';
    static readonly CONJUNCTION_POSITIONS_EDITED = 'error.conjunction.positions_edited';
    static readonly CONJUNCTION_POSITION_DELETED = 'error.conjunction.position_deleted';
    static readonly CONJUNCTION_CONNECTOR_INACTIVE = 'error.conjunction.connector_inactive';
}
