import {WindowTypeCode} from "../../../../window-designer/window-types/window-type-code";
import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

@JsonDeserializable
export class WebshopBusinessType {
    id: number;
    businessTypeId: number;
    names: MultilanguageField;
    type: WindowTypeCode;
    sortIndex: number;
    ownBusinessTypeNameWindow: string;
    ownBusinessTypeNameDoor: string;
    ownBusinessTypeNameTerrace: string;

    constructor() {
        this.names = {};
    }

    static fromJSON(jsonObject: any): WebshopBusinessType {
        const dto = new WebshopBusinessType();
        dto.id = jsonObject.id;
        dto.businessTypeId = jsonObject.businessTypeId;
        dto.names = MultilanguageField.fromJSON(jsonObject.names);
        dto.type = jsonObject.type;
        dto.sortIndex = jsonObject.sortIndex;
        dto.ownBusinessTypeNameWindow = jsonObject.ownBusinessTypeNameWindow;
        dto.ownBusinessTypeNameDoor = jsonObject.ownBusinessTypeNameDoor;
        dto.ownBusinessTypeNameTerrace = jsonObject.ownBusinessTypeNameTerrace;
        return dto;
    }

}
