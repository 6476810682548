<div class="new-form-600">
    <button hidden></button> <!-- todo: remove with fix VIN-3882 -->
    <div class="new-form-row">
        <app-input-text inputId="username" [maxlength]="50" autocomplete="off"
                        label="{{ 'USER-DETAILS.FORM.LOGIN' | translate }}"
                        [disabled]="editedUserIsMyself() || !canEditUser() || user.roleName === 'WEBSHOP'"
                        [(validationMessageKey)]="validationErrors['login']"
                        [(ngModel)]="user.login" [required]="true"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-password inputId="password" [maxlength]="100" autocomplete="new-password"
                            label="{{ 'USER-DETAILS.FORM.PASSWORD' | translate }}"
                            [(validationMessageKey)]="validationErrors['password']"
                            [required]="!user.id"
                            [disabled]="!canEditUser()"
                            [(ngModel)]="user.password"></app-input-password>
    </div>
    <div class="new-form-row">
        <app-input-password inputId="repeatedPassword" [maxlength]="100" autocomplete="new-password"
                        label="{{ 'USER-DETAILS.FORM.REPEAT_PASSWORD' | translate }}" [required]="!user.id"
                        [disabled]="!canEditUser()"
                        [(validationMessageKey)]="validationErrors['repeatedPassword']"
                        [(ngModel)]="user.repeatedPassword"></app-input-password>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="firstName" [maxlength]="50" [required]="true"
                        label="{{ 'USER-DETAILS.FORM.FIRST_NAME' | translate }}"
                        [disabled]="!canEditUser()"
                        [(validationMessageKey)]="validationErrors['firstName']"
                        [(ngModel)]="user.firstName"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="lastName" [maxlength]="50" [required]="true"
                        label="{{ 'USER-DETAILS.FORM.LAST_NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['lastName']"
                        [disabled]="!canEditUser()"
                        [(ngModel)]="user.lastName"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-text inputId="email" [maxlength]="255" [required]="true"
                        label="{{ 'USER-DETAILS.FORM.EMAIL' | translate }}"
                        [(validationMessageKey)]="validationErrors['email']"
                        [disabled]="!canEditUser()"
                        [(ngModel)]="user.email"></app-input-text>
    </div>

    <div class="new-form-row">
        <app-input-text inputId="phone" label="{{ 'USER-DETAILS.FORM.PHONE' | translate }}"
                        [(validationMessageKey)]="validationErrors['phone']" [maxlength]="50"
                        [(ngModel)]="user.phone"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-select inputId="interfaceLanguage" label="{{ 'USER-DETAILS.FORM.INTERFACE_LANG' | translate }}"
                    [disabled]="!canEditUser()" [required]="true"
                    [(validationMessageKey)]="validationErrors['interfaceLanguage']"
                    [options]="supportedLanguages | async" [optionFormatter]="languageOptionFormatter"
                    [(ngModel)]="user.interfaceLanguage"></app-select>
    </div>
    <div class="new-form-row">
        <app-text-area inputId="otherInfo" label="{{ 'CLIENT-DETAILS.FORM.DESCRIPTION' | translate }}"
                       [(validationMessageKey)]="validationErrors['otherInfo']" [maxlength]="512"
                       [disabled]="!canEditUser()"
                       [(ngModel)]="user.otherInfo"></app-text-area>
    </div>
    <div class="new-form-row">
        <app-select inputId="role" label="{{ 'USER-DETAILS.FORM.ROLE' | translate }}"
                    [disabled]="isChangeRoleDisabled()" [required]="true"
                    [(validationMessageKey)]="validationErrors['roleName']" [options]="roles"
                    [(ngModel)]="user.roleName" [translateLabels]="true"
                    (ngModelChange)="roleChange()"></app-select>
    </div>
    <div class="new-form-row" *ngIf="permissions.canEditUserRoles()">
        <div class="new-form-field">
            <div class="new-form-field-label-container">
                <div>
                    <label for="additionalRoles">{{ 'USER-DETAILS.FORM.ADDITIONAL_ROLES' | translate }}</label>
                </div>
            </div>
            <div class="new-form-field-input-container webshop-glazing-package-tag-container">
                <p-multiSelect inputId="additionalRoles" [options]="additionalRoles" defaultLabel="..." appendTo="body"
                               [displaySelectedLabel]="true" [ngModel]="selectedRoles"
                               (ngModelChange)="handleRolesChange($event)"></p-multiSelect>
            </div>
        </div>
    </div>
    <div class="new-form-row" *ngIf="isVenskaRole">
        <app-input-text inputId="motlawaLogin" label="{{ 'USER-DETAILS.FORM.MOTLAWA_LOGIN' | translate }}"
                        [(validationMessageKey)]="validationErrors['motlawaLogin']" [maxlength]="38"
                        [(ngModel)]="user.motlawaLogin"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="!isVenskaRole">
        <app-select inputId="subsystem" label="{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}"
                    [disabled]="!!user.id"
                    [allowSelectingNone]="true"
                    [(validationMessageKey)]="validationErrors['subsystemId']" [options]="subsystems"
                    [ngModel]="'' + user.subsystemId"
                    (ngModelChange)="subsystemChange($event)"></app-select>
    </div>
    <div *ngIf="isNew && clients.length > 0 && user.roleName === 'SPRZEDAWCA'" class="new-form-row">
        <p-accordion>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': validationErrors['clientId']}"
                            [selected]="false" header="{{ 'USER-DETAILS.FORM.CLIENT' | translate }}">
                <app-listbox-selection (onItemChange)="handleClientSelection($event)" [item]="selectedClient"
                                       [columnsCount]="2"
                                       [disabled]="!!user.id" [options]="clients"
                                       [validationErrors]="validationErrors"></app-listbox-selection>
            </p-accordionTab>
        </p-accordion>
    </div>
    <div class="new-form-row" *ngIf="!isNew && user.roleName === 'SPRZEDAWCA'">
        <app-input-text inputId="clientId" label="{{ 'USER-DETAILS.FORM.CLIENT' | translate }}"
                        [(validationMessageKey)]="validationErrors['clientId']" [disabled]="true"
                        [ngModel]="clients[0]?.label"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'USER-DETAILS.ACTIVE' | translate }}" [(ngModel)]="user.active"
                      [disabled]="!isPermitted({roles:['ROLE_CREATE_USER', 'ROLE_EDIT_USER', 'ROLE_OPERATOR']}) || editedUserIsMyself()"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']}) && user.roleName === 'OPERATOR'">
        <app-checkbox inputId="webshopPartner" label="{{ 'USER-DETAILS.FORM.WEBSHOP_PARTNER' | translate }}" [(ngModel)]="user.webshopPartner"></app-checkbox>
    </div>
    <div class="new-form-row" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
        <app-checkbox inputId="showProdOrderStatsForUser"
                      label="{{ 'USER-DETAILS.FORM.SHOW_PROD_ORDER_STATS_FOR_USER' | translate }}"
                      [(ngModel)]="user.showProdOrderStatsForUser"></app-checkbox>
    </div>

    <div class="new-form-row">
        <div class="new-form-field" [ngClass]="{'new-form-field-error': validationErrors['accountExpirationDate'] != undefined,
             'new-form-field-disabled': !canEditUserExpirationDate()}">
        <label for="accountExpirationDate">{{ 'USER-DETAILS.FORM.ACCOUNT_EXPIRATION' | translate }}</label>
            <div class="new-form-field-input-container">
                <div class="flex-row-container account-expiration-calendar">
                    <p-calendar inputId="accountExpirationDate"
                                [disabled]="!canEditUserExpirationDate()"
                                [inline]="false" appendTo="body"
                                [readonlyInput]="true" [minDate]="minAccountExpirationDate"
                                [dateFormat]="calendarLocale.dateFormat" [ngModel]="user.accountExpirationDate"
                                (ngModelChange)="handleAccountExpirationDateChange($event)"></p-calendar>
                    <app-simple-button *ngIf="canEditUserExpirationDate()"
                                       label="{{ 'GENERAL.DELETE' | translate }}" [inlineLabel]="false" icon="clear"
                                       type="action" [size]="40"
                                       (onClick)="user.accountExpirationDate = undefined"></app-simple-button>
                </div>
                <ng-container *ngIf="validationErrors['accountExpirationDate'] != undefined">
                    <span class="assembly-quantity new-form-field-error-icon material-icons">error</span>
                    <div class="new-form-field-error-message">{{ validationErrors['accountExpirationDate'] | translate }}</div>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="new-form-row">
        <app-text-area *ngIf="isExistingWebshopUser() && isPermitted({roles:['ROLE_KOORDYNATOR']})" inputId="apiToken"
                       label="{{ 'USER-DETAILS.FORM.API_TOKEN' | translate }}" [disabled]="true" icon="refresh"
                       (onIconClicked)="generateWebshopToken()" [ngModel]="webshopApiToken"></app-text-area>
    </div>
    <div class="new-form-row">
        <app-file-upload inputId="image" [ngModel]="file" (ngModelChange)="fileChanged($event)"
                         label="{{ 'USER-DETAILS.FORM.SIGNATURE' | translate }}" [required]="true" [maxSize]="150000"
                         [maxWidth]="300" [maxHeight]="100" [addButtonLabel]="'USER-DETAILS.FORM.SELECT_FILE_UPLOAD'"
                         [changeButtonLabel]="'USER-DETAILS.FORM.CHANGE_FILE_UPLOAD'" [disabled]="!canEditUser()"
                         [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
    </div>
</div>
