import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChildren
} from "@angular/core";
import {forkJoin, Observable, of, Subject} from "rxjs";
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {PositionListAddon} from "../../../../../../../window-designer/catalog-data/position-list-addon";
import {AddonCategoryEnum} from "../../../../../../../window-designer/enums/AddonCategoryEnum";
import {ErrorNames} from "../../../../../../../window-designer/utils/ErrorNames";
import {Permissions} from "../../../../../../auth/permission.service";
import {BlockUiController} from "../../../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {GrowlMessageController} from "../../../../../../common/growl-message/growl-message-controller";
import {
    MissingProfitMarginHandlerService
} from "../../../../../../common/missing-profit-margin-handler/missing-profit-margin-handler.service";
import {Currencies} from '../../../../../../currencies';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {SubsystemService} from '../../../../../subsystem/subsystem.service';
import {Supplier} from '../../../../../supplier/supplier';
import {AddonCategoryCount} from '../../../../../window-system/addons/addon';
import {AddonsService} from "../../../../../window-system/addons/addons.service";
import {PositionType} from "../../../../AbstractPosition";
import {Offer} from "../../../../offer";
import {ProfitMarginExistance} from "../../../../window-editor/drawing-tool/ProfitMarginExistance";
import {PositionService} from "../../position.service";
import {Position, PositionSortGroup} from "../position";
import {AddonPositionComponent} from "./addon-position/addon-position.component";
import {BulkAddonData} from "./bulk-addon-data";

@Component({
    selector: 'add-bulk-addon-position',
    templateUrl: './add-bulk-addon-position.component.html',
    styleUrls: ['./add-bulk-addon-position.component.css', '../../../../../../second-level-menu.css'],
    providers: [MissingProfitMarginHandlerService, SubsystemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddBulkAddonPositionComponent implements OnInit, OnDestroy {

    @Input() offer: Offer;
    @Input() suppliers: Supplier[];
    @Input() seqNum: number;
    @Input() ownAddons: boolean;
    @Input() allPositions: Position[];
    @Output() onSave: EventEmitter<void> = new EventEmitter<void>();
    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();

    @ViewChildren(AddonPositionComponent) addonTabs: QueryList<AddonPositionComponent>;

    visible = false;
    validationErrors = {};

    availableCategories: string[];

    tabPanelsData: { index: number, name: AddonCategoryEnum }[];
    panelContentVisible: boolean[];
    currentTabId = 0;
    saveInProgress = false;
    private componentDestroyed$: Subject<boolean> = new Subject<boolean>();
    private readonly dialogHideHelper = new OnceFlag();
    subsystemDefaultCurrency: Currencies;
    searchedName: string;
    searchedNameCategoryCounts: Map<AddonCategoryEnum, number>;
    showAllSuppliers = true;

    constructor(private addonsService: AddonsService,
                private positionService: PositionService,
                private permissions: Permissions,
                private changeDetector: ChangeDetectorRef,
                private blockUiController: BlockUiController,
                private growls: GrowlMessageController,
                private errors: CommonErrorHandler,
                private missingProfitMarginHandlerService: MissingProfitMarginHandlerService,
                private subsystemService: SubsystemService) {
    }

    ngOnInit() {
        this.panelContentVisible = [];
        this.tabPanelsData = [];
        this.availableCategories = [];
        if (this.suppliers != undefined && this.suppliers.length > 0
            && this.allPositions.some(position => position.type !== PositionType.BULK_ADDON)
            && !this.ownAddons) {
            this.showAllSuppliers = false;
        }
        forkJoin({
            bulkCategories: this.loadBulkCategories(),
            defaultCurrency: this.subsystemService.getDefaultCurrency()
        }).pipe(
            takeUntil(this.componentDestroyed$)
        ).subscribe({
            next: data => {
                console.info('`loadAvailableCategories` success:', data.bulkCategories);
                this.tableDataLoaded();
                this.subsystemDefaultCurrency = data.defaultCurrency;
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                console.info('`loadAvailableCategories` completed!');
            }
        });
    }

    private loadBulkCategories(): Observable<string[]> {
        const supplierIdFilter = !this.ownAddons && !this.showAllSuppliers ? this.suppliers.map(s => s.id) : [];
        return this.addonsService.getBulkCategories(supplierIdFilter, !this.ownAddons, this.ownAddons).pipe(
            tap(bulkCategories => {
                this.availableCategories = bulkCategories;
                this.createTabPanels();
            })
        );
    }

    private createTabPanels() {
        const oldTabPanels = this.tabPanelsData;
        const oldActiveTabCategory = this.currentTabId < oldTabPanels.length ? oldTabPanels[this.currentTabId].name : undefined;
        const oldActiveTabWasSearch = oldTabPanels.length > 0 && this.currentTabId === oldTabPanels.length;
        this.tabPanelsData = [];
        this.panelContentVisible = [];
        if (this.availableCategories.length !== 0) {
            this.availableCategories.forEach((category, tabIndex) => {
                this.tabPanelsData.push({index: tabIndex, name: AddonCategoryEnum[category]});
                this.panelContentVisible.push(false);
            });
            if (!oldActiveTabWasSearch) {
                let newActiveTabIndex = 0;
                if (oldActiveTabCategory != undefined) {
                    const newTab = this.tabPanelsData.find(tab => tab.name === oldActiveTabCategory);
                    if (newTab != undefined) {
                        newActiveTabIndex = newTab.index;
                    }
                }
                this.currentTabId = newActiveTabIndex;
                this.panelContentVisible[newActiveTabIndex] = true;
            }
        }
        this.panelContentVisible.push(oldActiveTabWasSearch); // search tab
        if (oldActiveTabWasSearch) {
            this.currentTabId = this.tabPanelsData.length;
        }
    }

    ngOnDestroy() {
        this.componentDestroyed$.next(true);
        this.componentDestroyed$.complete();
    }

    emitCloseDialog() {
        if (this.visible) {
            this.dialogHideHelper.call(() => this.onClose.emit());
            this.visible = false;
            this.changeDetector.markForCheck();
        }
    }

    addSelected() {
        let selectedAddons = [];
        let errorsPresent = false;

        this.addonTabs.forEach(tab => {
            if (tab.selectedItems && tab.selectedItems.length > 0) {
                if (tab.validateSelectedItems()) {
                    selectedAddons.push(...tab.selectedItems);
                } else {
                    errorsPresent = true;
                }
            }
        });

        if (!errorsPresent) {
            this.saveSelectedAddons(selectedAddons);
        }
    }

    private saveSelectedAddons(addons: PositionListAddon[]) {
        if (this.saveInProgress) {
            return;
        }

        this.setSaveInProgress(true);
        let observables = addons.map(selected => this.addonsService.validateMarginExistance(selected.id, this.offer.id));

        forkJoin(observables).subscribe({
            next: (data: ProfitMarginExistance[]) => {
                let profitMarginExistence = this.validateMargins(addons, data);
                if (profitMarginExistence.dealerMarkup && profitMarginExistence.sellerClientMarkup && profitMarginExistence.clientMarkup && profitMarginExistence.subsystemMarkup) {
                    if (profitMarginExistence.clientMarkupIsZeroWarning) {
                        this.growls.warning(ErrorNames.ADDON_CLIENT_MARKUP_IS_0);
                    }
                    let positionsToSave = addons.map(addon => {
                        let quantity = this.calculateQuantity(addon);
                        let configuredAddon = new BulkAddonData(addon.id, addon.category, addon.pcn,
                            addon.supplier.id, addon.price && addon.price.value, addon.selectedInsideColor, addon.selectedOutsideColor,
                            addon.selectedCoreColor, addon.quantityType);
                        let position = new Position(undefined, PositionType.BULK_ADDON, null, null, quantity,
                            null, null, null, null, null, null, null,
                            null, null, null, JSON.stringify(configuredAddon), this.offer.id, addon.otherInfo);
                        position.sortGroup = addon.subsystemId != undefined ? PositionSortGroup.OWN_ADDON : PositionSortGroup.SYSTEM;
                        return position;
                    });
                    let observable = this.positionService.saveItem(positionsToSave[0]);
                    for (let i = 1; i < positionsToSave.length; ++i) {
                        observable = observable.pipe(mergeMap(() => {
                            positionsToSave.shift();
                            return this.positionService.saveItem(positionsToSave[0]);
                        }));
                    }

                    observable.subscribe({
                        error: (error) => {
                            this.validationErrors = this.errors.handle(error);
                            this.setSaveInProgress(false);
                        },
                        complete: () => {
                            this.positionsSaved();
                        }
                    });
                } else {
                    of(profitMarginExistence).pipe(
                        this.missingProfitMarginHandlerService.handleProfitMarginExistenceResult({
                            ...this.offer,
                            identifier: {target: 'ADDON', category: 'ADDON'}
                        })
                    ).subscribe();
                    this.changeDetector.markForCheck();
                    this.setSaveInProgress(false);
                }
            },
            error: error => {
                this.setSaveInProgress(false);
                this.errors.handle(error);
            }
        });
    }

    private calculateQuantity(addon: PositionListAddon) {
        let quantity: number;
        if (AddonPositionComponent.usesMeterAsQuantity(addon)) {
            quantity = +addon.quantity.toString().replace(",", ".") || 1;
        } else {
            quantity = +addon.quantity || 1;
        }
        if (quantity <= 0) {
            quantity = 1;
        }

        return quantity;
    }

    private validateMargins(addons: PositionListAddon[], data: ProfitMarginExistance[]): ProfitMarginExistance {
        const missingMarkups = new ProfitMarginExistance(true);
        for (let i = 0; i < addons.length; ++i) {
            let testResult = data[i];
            if (!testResult.dealerMarkup) {
                missingMarkups.dealerMarkup = false;
            }
            if (!testResult.subsystemMarkup) {
                missingMarkups.subsystemMarkup = false;
            }
            if (!testResult.clientMarkup) {
                missingMarkups.clientMarkup = false;
            }
            if (testResult.clientMarkupIsZeroWarning) {
                missingMarkups.clientMarkupIsZeroWarning = true;
            }
            if (this.permissions.isSprzedawca() && !testResult.sellerClientMarkup) {
                missingMarkups.sellerClientMarkup = false;
            }
        }
        return missingMarkups;
    }

    private setSaveInProgress(value) {
        this.saveInProgress = value;
        if (value) {
            this.blockUiController.block('AddBulkAddonPositionComponentSave');
        } else {
            this.blockUiController.unblock('AddBulkAddonPositionComponentSave');
        }
        this.changeDetector.markForCheck();
    }

    positionsSaved() {
        this.setSaveInProgress(false);
        this.dialogHideHelper.call(() => this.onSave.emit());
    }

    nothingSelected() {
        if (this.addonTabs) {
            return !this.addonTabs.some(tab => !tab.nothingSelected());
        }
        return true;
    }

    tableDataLoaded() {
        this.visible = true;
        this.changeDetector.markForCheck();
    }

    handleChange(event: { index: number, name: AddonCategoryEnum }) {
        let activeTab = this.getActiveTab();
        if (activeTab) {
            activeTab.removeFromHotKeyService();
            this.panelContentVisible[event.index] = true;
            this.validationErrors = {};

            const addonPositionComponent = this.addonTabs.find(addonTab => addonTab.tabIndex === event.index);
            if (addonPositionComponent != undefined) {
                addonPositionComponent.addSpaceToHotKeyService();
            }

            this.currentTabId = event.index;
            this.changeDetector.markForCheck();
        }
        this.searchedNameCategoryCounts = undefined;
    }

    private getActiveTab(): AddonPositionComponent {
        return this.addonTabs.find(addonTab => addonTab.tabIndex === this.currentTabId);
    }

    getActiveSuffix(index: number) {
        let addonTab = this.getAddonTabByTabIndex(index);

        if (addonTab && addonTab.selectedItems && addonTab.selectedItems.length > 0) {
            return " (" + addonTab.selectedItems.length + ")";
        }

        return "";
    }

    private getAddonTabByTabIndex(tabIndex: number) {
        let addonTab;

        if (this.addonTabs) {
            addonTab = this.addonTabs.find(tab => tab.tabIndex === tabIndex);
        } else {
            addonTab = null;
        }

        return addonTab;
    }

    handleSearch(name: string): void {
        if (!!name) {
            this.searchedName = name;
            this.currentTabId = this.tabPanelsData.length;
            this.panelContentVisible[this.tabPanelsData.length] = true;
        }
    }

    handleSearchedNameCategoryResults(searchedNameCategoryResults: AddonCategoryCount[]): void {
        this.searchedNameCategoryCounts = new Map<AddonCategoryEnum, number>();
        for (let searchedNameCategoryResult of searchedNameCategoryResults) {
            this.searchedNameCategoryCounts.set(searchedNameCategoryResult.category, searchedNameCategoryResult.count);
        }
    }

    handleShowAllSuppliersChange(showAllSuppliers: boolean) {
        this.showAllSuppliers = showAllSuppliers;
        this.loadBulkCategories().subscribe();
    }
}
