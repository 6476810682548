import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SupportedLanguages} from "../../../../supportedLanguages";
import {ConfigTypeDescriptor} from "../config-type-descriptor";
import {SelectItem} from "primeng/api/selectitem";
import {ConfigDescriptorLevel} from "../../../../common/enums/ConfigDescriptorLevel.enum";
import {Observable, of} from "rxjs";
import {TranslatedSelectItemService} from "../../../../common/service/translated-select-item.service";

@Component({
    selector: 'app-config-type-descriptor-form',
    templateUrl: './config-type-descriptor-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigTypeDescriptorFormComponent implements OnInit {

    supportedLanguages = SupportedLanguages.languages;

    @Input() item: ConfigTypeDescriptor;
    @Input() validationErrors: any;

    levels: Observable<SelectItem[]>;

    constructor(private translatedSelectItemService: TranslatedSelectItemService) {
    }

    ngOnInit(): void {
        this.initLevels();
    }

    initLevels() {
        if (this.item) {
            this.levels = this.translatedSelectItemService.buildSortedDropdown(ConfigDescriptorLevel, 'CONFIG_TYPE_DESCRIPTOR.FORM.DESCRIPTOR_LEVEL.', undefined);
        } else {
            this.levels = of([]);
        }
    }

}
