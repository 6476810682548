import {DecorativeFillingType} from "../catalog-data/decorative-filling-type";

export class DecorativeFillingUtils {

    private static readonly FLIPPABLE_TYPES = [
        DecorativeFillingType.T1,
        DecorativeFillingType.T2,
        DecorativeFillingType.T3,
        DecorativeFillingType.T6,
        DecorativeFillingType.T9,
        DecorativeFillingType.T10,
        DecorativeFillingType.T11
    ];

    public static typeCanBeFlipped(type: DecorativeFillingType): boolean {
        return DecorativeFillingUtils.FLIPPABLE_TYPES.indexOf(type) > -1;
    }
}
