import {CutData} from "./CutData";
import {CutDataType} from "./CutDataType";

export class LineCutData extends CutData {
    points: number[];
    side: string;

    constructor(points: number[], side: string) {
        super(CutDataType.LINE);
        this.points = points;
        this.side = side;
    }
}
