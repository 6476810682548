import {HttpErrorResponse} from '@angular/common/http';
import {StatusTransition} from './StatusTransition';

export class StatusTransitionHelper {

    public static readonly TRANSITION_CHANGE_BLOCK_ID = 'statusTransitionChange';

    public static genericAddTransition(transitionsArray: StatusTransition[],
                                 label: string, icon: string,
                                 apiCommand: (additionalParam?: any) => any,
                                 id: string,
                                 successCallback?: (actionName: string) => void,
                                 errorCallback?: (actionName: string, error: HttpErrorResponse) => void,
                                 additionalParamRequest?: any) {
        const transitionItem: StatusTransition = {
            item: {
                label: label,
                icon: icon,
                command: () => {
                    if (apiCommand != null) {
                        StatusTransitionHelper.subscribeChangeStatusAction(apiCommand(transitionItem.additionalParam), id, successCallback,
                            errorCallback);
                    }
                },
                id: id
            },
            action: apiCommand != null ? 'CHANGE_STATUS' : id,
            additionalParamRequest: additionalParamRequest
        };
        transitionsArray.push(transitionItem);
    }

    private static subscribeChangeStatusAction(action, actionName: string, successCallback: (actionName: string) => void,
                                        errorCallback: (actionName: string, error: HttpErrorResponse) => void): void {
        action.subscribe(
            () => {
                if (successCallback) {
                    successCallback(actionName);
                }
            },
            error => {
                if (errorCallback) {
                    errorCallback(actionName, error);
                }
            }
        );
    }
}
