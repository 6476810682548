<div class="new-form-60vw">
    <div class="new-form-row">
        <app-input-number inputId="glazingQuantity"
                          label="{{ 'GRASP_GLAZING_CATEGORIES.FORM.GLAZING.QUANTITY' | translate }}"
                          [disabled]="disabled"
                          [(validationMessageKey)]="validationErrors['glassQuantity']" [min]="1" [max]="4" [step]="1"
                          [required]="true" [allowOnlyIntegers]="true" [ngModel]="glazing.glazingGlassQuantity"
                          (ngModelChange)="handleGlazingGlassQuantityChange($event)"></app-input-number>
    </div>

    <div class="new-form-row">
        <app-glass-selection *ngIf="glazing.glazingGlassQuantity" [glasses]="glasses" [frames]="frames"
                             [readOnlyMode]="disabled"
                             [commonData]="glazing" [glazingGlassNumber]="glazingFormGlassQuantities"
                             [(validationErrors)]="validationErrors" [catalogAdd]="addMode"></app-glass-selection>
    </div>

    <div class="new-form-row">
        <app-select inputId="glazingBead" label="{{ 'OFFER.TABS.SECTION.FILLING.GLAZING_BEAD' | translate }}"
                    [disabled]="disabled"
                    [(validationMessageKey)]="validationErrors['glazingBeadId']"
                    [options]="availableGlazingBeads" [optionFormatter]="glazingBeadOptionFormatter"
                    [ngModel]="selectedGlazingBead" (ngModelChange)="handleGlazingBeadChange($event)"></app-select>
    </div>
</div>
