<div [attr.id]="dialogId" class="dialog-window">
    <p-dialog #dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [(visible)]="visible"
              (onHide)="remindLater()" [modal]="true" [focusOnShow]="false" styleClass="dialog-visible-close"
              (onShow)="centerDialog()">
        <p-header>
            <div class="p-dialog-title">
                {{newsTitle}}
            </div>
        </p-header>
        <div class="new-form-60vw scrolled-dialog-90vh">
            <div class="confirm-action-question ql-editor" [innerHTML]="newsContent | appSafeValue: 'html'"></div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirm" [label]="'GENERAL.OK' | translate" type="main-action" [size]="40"
                                   (onClick)="confirm()"></app-simple-button>
                <app-simple-button buttonId="remindLater" [label]="'NEWS.REMIND_LATER' | translate" type="cancel"
                                   [size]="40" (onClick)="remindLater()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
