export class GeneralTabHaveErrors {
    main: boolean;
    color: boolean;
    filling: boolean;
    fittings: boolean;
    mullions: boolean;
    muntins: boolean;

    constructor() {
        this.main = false;
        this.color = false;
        this.filling = false;
        this.fittings = false;
        this.mullions = false;
        this.muntins = false;
    }
}
