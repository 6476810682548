import {ProfilesComposition} from "../drawing-data/ProfilesComposition";
import {SubwindowAttributes} from "./subwindow-attributes";
import {WindowTypeCode} from "./window-type-code";

export class WindowAttributes {
    subwindows: SubwindowAttributes[] = [];
    profilesComposition: ProfilesComposition[] = [];
    vertical = false;
    eliptical = false;
    movablePost = false;
    typeCode: WindowTypeCode;

    constructor(subwindowsNumber: number) {
        for (let i = 0; i < subwindowsNumber; i++) {
            this.subwindows.push(new SubwindowAttributes());
            this.profilesComposition.push(new ProfilesComposition());
        }
    }
}
