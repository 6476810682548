import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import * as moment from "moment";
import {convertAppLanguageToMomentLocale} from "../../../../../../common/service/moment-current-lang.pipe";
import {Comment, CommentType} from "../../../comment";
import {CommentDialogMode} from "./comment-dialog-mode";

@Pipe({
    name: 'commentTabHeader'
})
export class CommentTabHeaderPipe implements PipeTransform {

    constructor(private translate: TranslateService) {
    }

    transform(comment: Comment, mode: CommentDialogMode, isCurrentUserVenskaUser: boolean): string {
        if (mode !== CommentDialogMode.OFFER || comment.commentType === CommentType.USER) {
            return this.formatDateTime(comment.creationDate)
                + ' - '
                + comment.author
                + this.getUnreadCommentMessage(comment, mode, isCurrentUserVenskaUser);
        }

        if (comment.commentType === CommentType.SYSTEM) {
            return this.formatDateTime(comment.creationDate) + ' - '
                + (comment.author ? comment.author : this.translate.instant('OFFER.ACTIONS.DIALOGS.COMMENT.SYSTEM_AUTHOR'));
        }

        return '';
    }

    formatDateTime(date: Date): string {
        return moment(date).locale(convertAppLanguageToMomentLocale(this.translate.currentLang)).format('L LT');
    }

    getUnreadCommentMessage(comment: Comment, mode: CommentDialogMode, isCurrentUserVenskaUser: boolean): string {
        if (mode !== CommentDialogMode.COMPLAINT) {
            if (isCurrentUserVenskaUser && comment.unreadByVenska ||
                !isCurrentUserVenskaUser && comment.unreadBySubsystem) {
                return ' - !';
            }
        }
        return '';
    }
}
