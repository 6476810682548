import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {AssemblyUnit} from '../assembly/assembly';

@JsonDeserializable
export class Transport implements CrudItem {
    id: number;
    name: string;
    assemblyUnit: AssemblyUnit;
    value: number;
    currency: string;
    active = true;
    sortIndex = 1;

    static fromJSON(jsonObject: object): Transport {
        // no special handling needed
        return Object.assign({}, jsonObject as Transport);
    }
}
