import {CrudItem} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogPropertyTarget} from "../CatalogPropertyTarget";

export class CatalogOption implements CrudItem {
    id: number;
    value: MultilanguageField;
    active: boolean;
    sortIndex: number;
    symbol: string;
    icon: string;
    target: CatalogPropertyTarget;
    amazonS3FileName: string;

    constructor() {
        this.id = undefined;
        this.value = new MultilanguageField();
        this.active = true;
        this.sortIndex = 1;
    }
}
