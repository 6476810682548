import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {saveAs} from 'file-saver';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, of, Subscription} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {Country} from '../../../common/enums/country';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {Currencies} from '../../../currencies';
import {ImagesValidator} from '../../../form-inputs/inputs/file-upload/images-validator';
import {SupportedLanguages} from '../../../supportedLanguages';
import {IsLoggedService} from '../../login/islogged.service';
import {Subsystem} from '../../subsystem/subsystem';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {TabularAddress} from '../../subsystem/tabularAddress';
import {User} from '../../user/user';
import {UserService} from '../../user/user.service';
import {DocumentTemplateService} from '../document-templates/document-template.service';
import {SellerSettings} from '../seller-settings/seller-settings';
import {SellerSettingsService} from '../seller-settings/seller-settings.service';
import {SubsystemWebshopInfo} from '../subsystem-webshop-info/subsystem-webshop-info';
import {SubsystemWebshopInfoService} from '../subsystem-webshop-info/subsystem-webshop-info.service';
import {PrintoutDataServiceService, PrintoutFileType} from './printout-data.service';

@Component({
    selector: 'app-printout-data',
    templateUrl: './printout-data.component.html',
    styleUrls: ['../../shared-styles.css', '../settings.component.css'],
    providers: [SubsystemService, DataServiceHelper, SellerSettingsService, UserService, TranslatedSelectItemService,
        DocumentTemplateService, SubsystemWebshopInfoService, PrintoutDataServiceService]
})
export class PrintoutDataComponent implements OnInit, OnDestroy {

    supportedLanguagesOptions: Observable<SelectItem[]>;
    countries: Observable<SelectItem[]>;
    currencyOptions: Observable<SelectItem[]>;

    subsystem: Subsystem;
    subsystemWebshopInfo: SubsystemWebshopInfo;
    addresses: TabularAddress[];
    correspondenceAddress: TabularAddress;
    user: User;
    seller: SellerSettings;
    signature: File;
    isSeller = false;
    private saveInProgress = false;

    validationErrors = {};
    private loggedInSubscription: Subscription;
    template: File;
    confirmationTemplate: File;
    glamourAnnotations: File;
    logoGlamourFile: File;
    glamourTitlePageImage: File;
    printoutFileType = PrintoutFileType;

    translationKeys = {
        seller: {
            general: 'SETTINGS.SECTION.SELLER_SETTINGS',
            address: 'SELLER_SETTINGS.FORM.CORRESPONDENCE_ADDRESS'
        },
        subsystem: {
            general: 'SETTINGS.SECTION.SUBSYSTEM_INFO',
            address: 'SUBSYSTEM-DETAILS.FORM.CORRESPONDENCE_ADDRESS'
        },
        user: 'SETTINGS.SECTION.USER_SETTINGS',
        subsystemWebshopInfo: {
            general: 'SETTINGS.SECTION.SUBSYSTEM_WEBSHOP_INFO',
            address: 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.ADDRESS'
        }
    };

    constructor(private permissions: Permissions,
                private subsystemService: SubsystemService,
                private sellerSettingsService: SellerSettingsService,
                private printoutDataServiceService: PrintoutDataServiceService,
                private userService: UserService,
                private subsystemWebshopInfoService: SubsystemWebshopInfoService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private growlMessageController: GrowlMessageController,
                private isLoggedService: IsLoggedService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private documentTemplateService: DocumentTemplateService) {
        this.subsystem = new Subsystem();
        this.subsystemWebshopInfo = new SubsystemWebshopInfo();
        this.user = new User();
        this.seller = new SellerSettings();
        this.currencyOptions = this.translatedSelectItemService.buildUnsortedDropdown(Currencies, '', undefined);
    }

    ngOnInit() {
        this.loggedInSubscription = this.isLoggedService.item$.subscribe(
            () => {
                this.getCurrentUser();
            });
        this.supportedLanguagesOptions = this.translatedSelectItemService
            .buildUnsortedDropdown(SupportedLanguages.languages, lang => lang.name, undefined);
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
    }

    ngOnDestroy() {
        this.loggedInSubscription.unsubscribe();
    }

    getCurrentUser() {
        this.userService.getUserProfile().subscribe({
            next: data => {
                this.user = data;
                this.isSeller = this.isPermitted({roles: ['ROLE_SPRZEDAWCA']});
                this.getItemWithImage();
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                console.debug('CurrentProfile` completed!');
            }
        });
    }

    getItemWithImage() {
        forkJoin({
            subsystem: this.subsystemService.getItem(this.user.subsystemId),
            seller: this.isSeller ? this.sellerSettingsService.getItem(this.user.id) : of(undefined),
            addresses: this.isSeller
                ? this.sellerSettingsService.getAddresses(this.user.id)
                : this.subsystemService.getAddresses(this.user.subsystemId),
            signature: this.userService.getUserProfileSignature(),
            files: this.printoutDataServiceService.getFiles(this.isSeller, this.isSeller ? this.user.id : this.user.subsystemId)
        }).subscribe({
            next: data => {
                this.signature = data.signature;
                this.subsystem = data.subsystem;
                if (this.subsystem.webShopEnabled && !this.isSeller) {
                    this.subsystemWebshopInfoService.getItem().subscribe({
                        next: subsystemWebshopInfo => {
                            this.subsystemWebshopInfo = subsystemWebshopInfo;
                        },
                        error: error => {
                            this.errors.handle(error);
                        }
                    });
                }
                this.addresses = data.addresses;
                this.seller = data.seller;
                let addressId = this.isSeller
                    ? this.seller.primaryCorrespondenceAddressId
                    : this.subsystem.primaryCorrespondenceAddressId;
                this.correspondenceAddress = this.addresses.find(address => address.id === addressId);

                this.logoGlamourFile = data.files[PrintoutFileType.LOGO_GLAMOUR];
                this.template = data.files[PrintoutFileType.TEMPLATE];
                this.confirmationTemplate = data.files[PrintoutFileType.CONFIRMATION_TEMPLATE];
                this.glamourTitlePageImage = data.files[PrintoutFileType.GLAMOUR_TITLE_PAGE];
                this.glamourAnnotations = data.files[PrintoutFileType.GLAMOUR_ANNOTATIONS];
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    downloadDefaultTemplate(): void {
        this.documentTemplateService.getDefaultTemplate().subscribe({
            next: value => saveAs(value, 'defaultTemplate.docx', true),
            error: err => {
                this.validationErrors = this.errors.handle(err);
                this.changeDetector.markForCheck();
            }
        });
    }

    downloadDefaultConfirmationTemplate(): void {
        this.documentTemplateService.getDefaultConfirmationTemplate().subscribe({
            next: value => saveAs(value, 'defaultConfirmationTemplate.docx', true),
            error: err => {
                this.validationErrors = this.errors.handle(err);
                this.changeDetector.markForCheck();
            }
        });
    }

    downloadDefaultGlamourAnnotations(): void {
        this.documentTemplateService.getDefaultGlamourAnnotations().subscribe({
            next: value => saveAs(value, 'annotationsTemplate.docx', true),
            error: err => {
                this.validationErrors = this.errors.handle(err);
                this.changeDetector.markForCheck();
            }
        });
    }

    changeFile(file: File, fileType: PrintoutFileType): void {
        let newFile = file || new File([], null);
        switch (fileType) {
            case PrintoutFileType.GLAMOUR_ANNOTATIONS:
                this.glamourAnnotations = newFile;
                break;
            case PrintoutFileType.TEMPLATE:
                this.template = newFile;
                break;
            case PrintoutFileType.CONFIRMATION_TEMPLATE:
                this.confirmationTemplate = newFile;
                break;
            case PrintoutFileType.LOGO_GLAMOUR:
                this.logoGlamourFile = newFile;
                break;
            case PrintoutFileType.GLAMOUR_TITLE_PAGE:
                this.glamourTitlePageImage = newFile;
                break;
        }
    }

    saveChanges() {
        this.validateDetails().subscribe(
            validationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
                    if (this.isSaveInProgress()) {
                        return;
                    }
                    const saveObservable = this.isSeller
                        ? this.sellerSettingsService.saveFiles(this.user.id, this.logoGlamourFile, this.glamourTitlePageImage,
                            this.template, this.confirmationTemplate, this.glamourAnnotations)
                        : this.subsystemService.saveFiles(this.user.subsystemId, this.logoGlamourFile, this.glamourTitlePageImage,
                            this.template, this.confirmationTemplate, this.glamourAnnotations);
                    saveObservable.subscribe({
                        next: () => {
                            this.showSuccessMessage();
                            this.changeDetector.markForCheck();
                            this.setSaveInProgress(false);
                        },
                        error: err => {
                            this.validationErrors = this.errors.handle(err);
                            this.changeDetector.markForCheck();
                            this.setSaveInProgress(false);
                        }
                    });
                }
            }
        );
    }

    public setSaveInProgress(saveInProgress: boolean): void {
        this.saveInProgress = saveInProgress;
    }

    public isSaveInProgress(): boolean {
        return this.saveInProgress;
    }

    showSuccessMessage() {
        this.growlMessageController.info('PRINTOUT_DATA.UPDATED');
    }

    validateDetails(): Observable<ValidationErrors> {
        const validationErrors = {};
        return forkJoin({
            logoGlamourFile: ImagesValidator.validationErrors(this.logoGlamourFile, 1000000, 3000, 3000),
            glamourTitlePageImage: ImagesValidator.validationErrors(this.glamourTitlePageImage, 1000000, 3000, 3000)
        }).pipe(
            mergeMap(result => {
                validationErrors['logoGlamourFile'] = result.logoGlamourFile;
                validationErrors['glamourTitlePageImage'] = result.glamourTitlePageImage;
                return of(validationErrors);
            })
        );
    }
}
