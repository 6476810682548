import {GrillSegmentIdGenerator} from "../utils/GrillSegmentIdGenerator";
import {ArcGrillSegment} from "./ArcGrillSegment";
import {GrillSegmentType} from "./GrillSegmentType";
import {LineGrillSegment} from "./LineGrillSegment";
import {PricingGrillSegment} from "./PricingGrillSegment";

export abstract class GrillSegment {

    type: GrillSegmentType;
    id: number;

    constructor(type: GrillSegmentType) {
        this.type = type;
        this.id = GrillSegmentIdGenerator.getNextId(type === GrillSegmentType.PRICING);
    }

    static isPricing(grillSegment: GrillSegment): grillSegment is PricingGrillSegment {
        return grillSegment.type === GrillSegmentType.PRICING;
    }

    static isLine(grillSegment: GrillSegment): grillSegment is LineGrillSegment {
        return grillSegment.type === GrillSegmentType.LINE || grillSegment.type === GrillSegmentType.PRICING;
    }

    static isArc(grillSegment: GrillSegment): grillSegment is ArcGrillSegment {
        return grillSegment.type === GrillSegmentType.ARC;
    }
}
