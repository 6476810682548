import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ValidationErrors} from '../../../common/validation-errors';
import {MultilanguageField, SupportedLanguage, SupportedLanguages} from '../../../supportedLanguages';

@Component({
    selector: 'app-translation-fields',
    templateUrl: './translation-fields.component.html',
    styleUrls: ['./translation-fields.component.css'],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('showTranslationFields', [
            state('false', style({
                height: '0'
            })),
            state('true', style({
                height: '*'
            })),
            transition('true <=> false', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class TranslationFieldsComponent implements OnChanges {

    @Input()
    inputId: string;

    @Input()
    label: string;

    @Input()
    validationKeyPrefix: string;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    maxlength: number;

    @Input()
    required: boolean;

    @Input()
    disabled: boolean;

    @Input()
    field: MultilanguageField;

    showTranslationFields = false;
    animating = false;
    transformedValidationErrors: ValidationErrors;

    constructor(public translate: TranslateService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('validationErrors' in changes) {
            const newValue = changes['validationErrors'].currentValue;
            if (newValue != undefined) {
                this.transformedValidationErrors = {};
                for (let language of SupportedLanguages.languages) {
                    const error = newValue[`${this.validationKeyPrefix}[${language.code}]`];
                    this.transformedValidationErrors[language.code] = error != undefined ?
                        error.replace(`${this.validationKeyPrefix}[${language.code}]`, this.validationKeyPrefix) :
                        undefined;
                }
            }
        }
    }

    getExtraLanguages(): SupportedLanguage[] {
        return SupportedLanguages.languages.filter(language => language.code !== this.translate.currentLang);
    }

    toggleTranslationFields(): boolean {
        if (!this.animating) {
            this.showTranslationFields = !this.showTranslationFields;
        }
        this.animating = true;
        return false;
    }

    handleValueChange(value: string, lang: string): void {
        if (this.field != undefined) {
            this.field[lang] = value;
        }
    }

    handleValidationMessageChange(value: string, lang: string): void {
        this.transformedValidationErrors[lang] = value;
        this.validationErrors[`${this.validationKeyPrefix}[${lang}]`] = value != undefined ?
            value.replace(this.validationKeyPrefix, `${this.validationKeyPrefix}[${lang}]`) :
            undefined;
        this.changeDetector.markForCheck();
    }

    hasExtraLanguagesValidationErrors(): boolean {
        return this.getExtraLanguages().some(lang => this.transformedValidationErrors[lang.code] != undefined);
    }
}
