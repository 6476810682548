import * as _ from 'underscore';
import {GlassUpsellingValidationUtil} from './utils/glass-upselling-validation-util';
import {DistanceFrameInterface} from './catalog-data/distance-frame-interface';
import {GlassInterface} from './catalog-data/glass-interface';
import {Glazing} from "./catalog-data/glazing";
import {GlazingPackageInterface} from './catalog-data/glazing-package-interface';
import {WindowSystemInterface} from './catalog-data/window-system-interface';
import {DrawingData} from './drawing-data/drawing-data';
import {FillingType} from './drawing-data/FillingType';
import {GlazingBead} from './drawing-data/GlazingBead';
import {WindowCommonData} from "./window-common-data";

export class GlazingHelper {

    public static getAvailableGlassCount(windowSystem: WindowSystemInterface, decorative: boolean): number[] {
        const field = decorative ? 'decorativeGlazingPackage' : 'glazingPackage';
        let glassCount = [];
        if (windowSystem == null || windowSystem[field] == null) {
            return glassCount;
        }
        if (windowSystem[field].hasGlazing1) {
            glassCount.push(1);
        }
        if (windowSystem[field].hasGlazing2) {
            glassCount.push(2);
        }
        if (windowSystem[field].hasGlazing3) {
            glassCount.push(3);
        }
        if (windowSystem[field].hasGlazing4) {
            glassCount.push(4);
        }
        return glassCount;
    }

    public static getNthGlass(area: Glazing, n: number): number {
        switch (n) {
            case 1:
                return area.glass1id;
            case 2:
                return area.glass2id;
            case 3:
                return area.glass3id;
            case 4:
                return area.glass4id;
            default:
                break;
        }
        return undefined;
    }

    public static getNthFrame(area: Glazing, n: number): number {
        switch (n) {
            case 1:
                return area.frame1id;
            case 2:
                return area.frame2id;
            case 3:
                return area.frame3id;
            default:
                break;
        }
        return undefined;
    }

    public static getNthGlassThickness(area: Glazing, n: number, glasses: GlassInterface[]): number {
        let glassId = this.getNthGlass(area, n);
        return (glasses.find(g => g.id === glassId) || {thickness: undefined}).thickness;
    }

    public static getNthFrameThickness(area: Glazing, n: number, frames: DistanceFrameInterface[]): number {
        let frameId = this.getNthFrame(area, n);
        return (frames.find(g => g.id === frameId) || {thickness: undefined}).thickness;
    }

    public static getGlazingTotalWidth(glazing: Glazing, glasses: GlassInterface[], frames: DistanceFrameInterface[]): number {
        let width = 0;
        let glassIds = [];
        for (let i = 1; i < glazing.glazingGlassQuantity + 1; i++) {
            let nthGlass = this.getNthGlass(glazing, i);
            if (nthGlass) {
                glassIds.push(nthGlass);
            }
        }

        let frameIds = [];
        for (let i = 1; i < glazing.glazingGlassQuantity; i++) {
            let nthFrame = this.getNthFrame(glazing, i);
            if (nthFrame) {
                frameIds.push(nthFrame);
            }
        }

        let glassOccurrences = _.countBy(glassIds, _.identity);
        if (glasses) {
            for (let glass of glasses) {
                if (glassIds.indexOf(glass.id) !== -1) {
                    width += glass.thickness * glassOccurrences[glass.id];
                }
            }
        }

        let frameOccurrences = _.countBy(frameIds, _.identity);
        if (frames) {
            for (let frame of frames) {
                if (frameIds.indexOf(frame.id) !== -1) {
                    width += frame.thickness * frameOccurrences[frame.id];
                }
            }
        }
        return width;
    }

    // Return array of 2 element arrays with min and max number values
    public static parseGlazingWidths(widths: string): { min: number, max: number }[] {
        let rx = /^((?:[0-9]+)|(?:[0-9]+[,.][0-9]+)) ?- ?((?:[0-9]+)|(?:[0-9]+[,.][0-9]+))$/;
        let rxMatched;
        let mainSizesList = [];
        if (widths != null) {
            for (let sizes of widths.split(";")) {
                rxMatched = rx.exec(sizes.trim());
                if (rxMatched && rxMatched.length === 3) {
                    mainSizesList.push({min: parseFloat(rxMatched[1]), max: parseFloat(rxMatched[2])});
                }
            }
        }

        return mainSizesList;
    }

    public static isGlazingWithOutOfRange(allowedWidths: { min: number, max: number }[], glazingWidth: number): boolean {
        return allowedWidths.find(currentWidths => glazingWidth >= currentWidths.min && glazingWidth <= currentWidths.max) == undefined;
    }

    public static applyGlazingBasedOnArea(data: DrawingData, glazingPackage: GlazingPackageInterface,
                                          glazingGlassQuantity: number, copyGlazing: boolean) {
        let baseGlazingPackage: Glazing = glazingPackage[`glazing${glazingGlassQuantity}`];
        data.windows.forEach(window => {
            window.subWindows.forEach(subwindow => {
                subwindow.areasSpecification.forEach(areaSpec => {
                    if (areaSpec.filling.type !== FillingType.GLASS) {
                        return;
                    }
                    let glazingPackageForArea = GlassUpsellingValidationUtil.findGlazingForArea(areaSpec, glazingGlassQuantity, glazingPackage.glazingPackageForAreaRangeList);
                    if (copyGlazing) {
                        areaSpec.glazing = _.clone(glazingPackageForArea == null ? baseGlazingPackage : glazingPackageForArea.glazing);
                    }
                    if (glazingPackageForArea != undefined) {
                        areaSpec.glazingBead = new GlazingBead(glazingPackageForArea.glazingBeadId);
                    }
                });
            });
        });
    }

    public static setDefaultCommonDataGlazing(windowSystem: WindowSystemInterface, commonData: WindowCommonData): boolean {
        if (windowSystem.glazingPackage && windowSystem.glazingPackage['hasGlazing' + commonData.glazingGlassQuantity]) {
            let defaultGlazing = windowSystem.glazingPackage['glazing' + commonData.glazingGlassQuantity];
            commonData.glazingGlassQuantity = defaultGlazing.glazingGlassQuantity;
            commonData.glass1id = defaultGlazing.glass1id;
            commonData.glass2id = defaultGlazing.glass2id;
            commonData.glass3id = defaultGlazing.glass3id;
            commonData.glass4id = defaultGlazing.glass4id;
            commonData.frame1id = defaultGlazing.frame1id;
            commonData.frame2id = defaultGlazing.frame2id;
            commonData.frame3id = defaultGlazing.frame3id;
            return true;
        }
        return false;
    }

    public static setDefaultGlazing(windowSystem: WindowSystemInterface, glazing: Glazing, newGlassQunatity: number): void {
        if (windowSystem.glazingPackage && windowSystem.glazingPackage['hasGlazing' + newGlassQunatity]) {
            let defaultGlazing = windowSystem.glazingPackage['glazing' + newGlassQunatity];
            glazing.glazingGlassQuantity = defaultGlazing.glazingGlassQuantity;
            glazing.glass1id = defaultGlazing.glass1id;
            glazing.glass2id = defaultGlazing.glass2id;
            glazing.glass3id = defaultGlazing.glass3id;
            glazing.glass4id = defaultGlazing.glass4id;
            glazing.frame1id = defaultGlazing.frame1id;
            glazing.frame2id = defaultGlazing.frame2id;
            glazing.frame3id = defaultGlazing.frame3id;
        }
    }

    public static hasDefaultGlasses(windowSystem: WindowSystemInterface, glazing: Glazing): boolean {
        if (windowSystem.glazingPackage && windowSystem.glazingPackage['hasGlazing' + glazing.glazingGlassQuantity]) {
            let defaultGlazing = windowSystem.glazingPackage['glazing' + glazing.glazingGlassQuantity];
            if (glazing.glazingGlassQuantity !== defaultGlazing.glazingGlassQuantity) {
                return false;
            }
            if (glazing.glazingGlassQuantity >= 1 && glazing.glass1id !== defaultGlazing.glass1id) {
                return false;
            }
            if (glazing.glazingGlassQuantity >= 2 && glazing.glass2id !== defaultGlazing.glass2id) {
                return false;
            }
            if (glazing.glazingGlassQuantity >= 3 && glazing.glass3id !== defaultGlazing.glass3id) {
                return false;
            }
            if (glazing.glazingGlassQuantity >= 4 && glazing.glass4id !== defaultGlazing.glass4id) {
                return false;
            }
            return true;
        }
        return false;
    }
}
