import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Permissions} from '../../../../auth/permission.service';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {GlazingBeadField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GlazingBeadFieldUsage} from "../../catalog-field-usage";
import {GlazingBead} from '../glazing-bead';

@Component({
    selector: 'app-glazing-bead-form',
    templateUrl: './glazing-bead-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlazingBeadFormComponent {

    @Input() item: GlazingBead;
    @Input() validationErrors: any;
    @Input() file: File;
    @Input() editPermits: FieldLimitation[] = [];
    @Output() readonly fileChange = new EventEmitter<File>();

    fieldUsage: GlazingBeadFieldUsage;
    GlazingBeadField = GlazingBeadField;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    constructor(public permissions: Permissions) {
        this.fieldUsage = new GlazingBeadFieldUsage(this);
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
