import {ChangeDetectorRef, Component, OnDestroy, OnInit} from "@angular/core";
import {LangChangeEvent, TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {Permissions} from "../../../auth/permission.service";
import {StorageKey, StorageService} from "../../../auth/storage.service";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {ResponseError} from "../../../common/error.handler";
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationService} from "../../../common/service/validation.service";
import {SupportedLanguage, SupportedLanguages} from "../../../supportedLanguages";
import {ChangeLanguageService} from "../../login/change-language.service";
import {IsAnotherUserLoggedService} from "../../login/isAnotherUserLogged";
import {User} from "../../user/user";
import {UserService} from "../../user/user.service";
import {HolidayConfirmation} from "./HolidayConfirmation";

@Component({
    selector: 'app-user-settings',
    templateUrl: './user-settings.component.html',
    styleUrls: ['./user-settings.component.css', '../settings.component.css'],
    providers: [UserService, DataServiceHelper, TranslatedSelectItemService, ValidationService]
})
export class UserSettingsComponent implements OnInit, OnDestroy {

    user: User;
    validationErrors = {};
    file: File;
    protected langTranslateSubscription: Subscription;

    supportedLanguages: Observable<SelectItem[]>;
    holidayDialogVisible = false;

    constructor(public permissions: Permissions,
                private userService: UserService,
                private translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private validationService: ValidationService,
                private changeDetector: ChangeDetectorRef,
                private storage: StorageService,
                private isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private changeLanguageService: ChangeLanguageService,
                private growlMessageController: GrowlMessageController,
                private errors: CommonErrorHandler) {
        this.user = new User();
        this.langTranslateSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.user.interfaceLanguage = event.lang;
            this.changeDetector.markForCheck();
        });
    }

    ngOnInit() {
        this.getCurrentUser();
        this.supportedLanguages = this.translatedSelectItemService
            .buildUnsortedDropdown(SupportedLanguages.languages, lang => lang.name, undefined);
    }

    getInterfaceLanguage() {
        if (this.isAnotherUserLoggedService.isImpersonating) {
            return this.storage.get(StorageKey.CURRENT_USER_INTERFACE_LANGUAGE);
        }
        return this.storage.get(StorageKey.INTERFACE_LANGUAGE);
    }

    changeUserInterfaceLanguage(event): void {
        this.user.interfaceLanguage = event;
    }

    ngOnDestroy(): void {
        this.langTranslateSubscription.unsubscribe();
    }

    private getCurrentUser() {
        forkJoin({
            profile: this.userService.getUserProfile(),
            signature: this.userService.getUserProfileSignature()
        }).subscribe({
            next: data => {
                this.user = data.profile;
                this.user.repeatedPassword = this.user.password;
                this.file = data.signature;
            },
            error: error => {
                throw new ResponseError(error);
            },
            complete: () => {
                console.debug('getUserProfile` completed!');
            }
        });
    }

    private validateForm() {
        this.validationService.validateNotEmpty(this.user, ["login", "firstName", "lastName", "email"],
            this.validationErrors, "userDto");

        if (!this.user.login) {
            this.validationErrors['login'] = 'error.userDto.login.not_empty';
        }
        if (!this.user.firstName) {
            this.validationErrors['firstName'] = 'error.userDto.firstName.not_empty';
        }
        if (!this.user.lastName) {
            this.validationErrors['lastName'] = 'error.userDto.lastName.not_empty';
        }
        if (!this.user.email) {
            this.validationErrors['email'] = 'error.userDto.email.not_empty';
        }
        if (this.user.email && this.user.email.indexOf('@') === -1) {
            this.validationErrors['email'] = 'error.userDto.email.not_well_formatted';
        }
        if (!this.user.interfaceLanguage) {
            this.validationErrors['interfaceLanguage'] = 'error.userDto.interfaceLanguage.not_empty';
        }
        if (!this.user.roleName) {
            this.validationErrors['roleName'] = 'error.userDto.roleName.null';
        }
        if (this.user.password !== this.user.repeatedPassword) {
            this.validationErrors['repeatedPassword'] = 'USER-DETAILS.FORM.PASSWORD_DONT_MATCH';
        }
    }

    private validationErrorsPresent(): boolean {
        if (Object.keys(this.validationErrors).filter(field => this.validationErrors[field] != undefined).length !== 0) {
            return true;
        }
        return this.user.password !== this.user.repeatedPassword;
    }

    submit(): void {
        this.validateForm();
        if (!this.validationErrorsPresent()) {
            this.userService.saveUser(this.user, this.file).subscribe({
                complete: () => {
                    this.changeInterfaceLanguage(this.user.interfaceLanguage);
                    this.showSuccessMessage();
                    this.storage.set(StorageKey.DISPLAY_SIDE_MENU_LABELS, '' + this.user.displaySideMenuLabels);
                },
                error: err => {
                    this.validationErrors = this.errors.handle(err);
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    private showSuccessMessage() {
        this.growlMessageController.info('USER-DETAILS.USER_UPDATED');
        this.getCurrentUser();
    }

    private changeInterfaceLanguage(langName): void {
        if (this.isAnotherUserLoggedService.isImpersonating) {
            this.storage.set(StorageKey.CURRENT_USER_INTERFACE_LANGUAGE, langName);
        } else {
            this.changeLanguageService.changeLng(langName);
            this.storage.set(StorageKey.INTERFACE_LANGUAGE, langName);
            this.translate.use(langName);
        }
    }

    languageOptionFormatter = (lang: SelectItem): SelectItem => ({label: lang.label, value: (lang.value as SupportedLanguage).code});

    handleGoOnHoliday() {
        if (this.user.onHoliday) {
            this.growlMessageController.info('USER-DETAILS.HOLIDAY.WELCOME_BACK');
            this.goOnHoliday();
        } else {
            this.holidayDialogVisible = true;
        }
    }

    goOnHoliday() {
        this.userService.goOnHoliday().subscribe((confirmation: HolidayConfirmation) => {
            if (!confirmation.isBack) {
                if (confirmation.allowed) {
                    this.growlMessageController.info('USER-DETAILS.HOLIDAY.ENJOY_HOLIDAY');
                    this.user.onHoliday = confirmation.allowed;
                } else {
                    const problemSource = confirmation.problematicSubsystems ?
                        confirmation.problematicSubsystems : confirmation.problematicUsers;
                    this.growlMessageController.warning(this.translate.instant(confirmation.message)
                        + problemSource.join(", "), {}, true);
                }
            } else {
                this.user.onHoliday = false;
            }
            this.closeHolidayDialog();
        });
    }

    closeHolidayDialog() {
        this.holidayDialogVisible = false;
    }
}
