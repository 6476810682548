import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {AvailableProducts} from "../../../common/enums/AvailableProductType";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class MarkedProduct {
    productType: AvailableProducts;
    markLabels: MultilanguageField;

    constructor(productType: AvailableProducts, markLabels: MultilanguageField) {
        this.productType = productType;
        this.markLabels = markLabels;
    }

    static fromJSON(jsonObject: any): MarkedProduct {
        return new MarkedProduct(jsonObject['productType'],
            jsonObject['markLabels'] != undefined ? MultilanguageField.fromJSON(jsonObject['markLabels']) : new MultilanguageField());
    }
}
