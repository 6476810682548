import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input, OnChanges,
    OnInit,
    Output, SimpleChanges, ViewChild
} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {BehaviorSubject, Observable} from 'rxjs';
import {ValidationErrors} from '../../../../common/validation-errors';
import {AddonCategoryGroup} from "../../../window-system/addon-category-group/addon-category-group";
import {WindowComponentPreviewData} from '../../window-editor/window-component-preview-dialog/window-component-preview-dialog.component';
import {WindowEditorPositionData} from '../../window-editor/window-editor-offer-interfaces';
import {ConfigData} from "../config-data";
import {ConfigSystemDefaults} from "../../../settings/config-system-defaults/config-system-defaults";
import {ConfigEditorField} from "../config-editor-field";
import {ConfigEditorFieldStates} from "../config-editor-field-states";
import {ConfigSystem} from "../../../window-system/config-system/config-system";
import {ConfigEditorFieldContentProvider} from "../config-editor-field-content-provider";
import {ConfigSidebarFieldImageService} from "./config-sidebar-field-image.service";
import {MaterialColorImages} from "../../../window-system/material/material-color-images/material-color-images";
import {OpenImageInNewBrowserTabController} from "../../../../common/open-image-in-new-browser-tab-controller.service";
import {Accordion} from "primeng/accordion";
import {ConfigAddonParentInfo} from "../../../../../window-designer/entities/ConfigAddonParentInfo";
import {ConfigAddonApplication} from "../../../../../window-designer/enums/ConfigAddonApplication";
import {SubwindowTypes} from "../../../../../window-designer/subwindow-types";
import {SelectTileLink, TileSelectDialogComponent} from "./tile-select-dialog/tile-select-dialog.component";
import {AddonCategory} from "../../../window-system/addon-category/addon-category";
import {AddonsService} from "../../../window-system/addons/addons.service";
import {ConfigSystemService} from "../../../window-system/config-system/config-system.service";

export interface SidebarFieldChangeEvent {
    field: string;
    oldValue: any;
    newValue: any;
}

export type ConfigDataInput = ConfigSystemDefaults & Partial<Pick<ConfigData, 'configSystemId' |
    'wym1' | 'wym2' | 'wym3' | 'wym4' | 'wym5' | 'wym6' | 'materialId' | 'colorId' |
    'usedGlobalSettingsChanged' | 'usedGlobalSettingsLevel'>>;

@Component({
    selector: 'app-config-sidebar',
    templateUrl: './config-sidebar.component.html',
    styleUrls: ['./config-sidebar.component.css', '../../window-editor/sidebar/sidebar.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigSidebarComponent implements OnInit, OnChanges {

    readonly Fields = ConfigEditorField;

    @ViewChild('mainAcc', {static: true}) mainAcc: Accordion;

    @Input()
    position: WindowEditorPositionData;

    @Input()
    parentElementDescription: ConfigAddonParentInfo[];

    @Input()
    subwindowTypes: SubwindowTypes;

    @Input()
    data: ConfigDataInput;

    @Input()
    fields: ConfigEditorFieldStates;

    @Input()
    selectedSystem: ConfigSystem;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    readOnlyMode: boolean;

    @Input()
    addonCategoryGroups: AddonCategoryGroup[];

    @Input()
    defaultsLevels: SelectItem[];

    @Input()
    defaultsLevel: 'GLOBAL' | 'SUBSYSTEM_GROUP' | 'SUBSYSTEM' | 'CLIENT_GROUP' | 'CLIENT' | 'OFFER';

    @Input()
    defaultsOverrideLowerLevel: boolean;

    @Input()
    systemLinksLoaded: boolean;

    @Input()
    colorMaterialLinks: MaterialColorImages[];

    @Input()
    largeImageGetter: (materialId: number, colorId: number) => Observable<string>;

    @Output()
    readonly onSystemChange = new EventEmitter<void>();

    @Output()
    readonly beforeFieldChange = new EventEmitter<SidebarFieldChangeEvent>();

    @Output()
    readonly afterFieldChange = new EventEmitter<SidebarFieldChangeEvent>();

    @Output()
    readonly afterFieldBlur = new EventEmitter<SidebarFieldChangeEvent>();

    @Output()
    readonly onShowImage = new EventEmitter<WindowComponentPreviewData>();

    @Output()
    readonly onShowDescriptionDialog = new EventEmitter();

    @Output()
    readonly defaultsLevelChange = new EventEmitter<'GLOBAL' | 'SUBSYSTEM_GROUP' | 'SUBSYSTEM' | 'CLIENT_GROUP' | 'CLIENT' | 'OFFER'>();

    @Output()
    readonly defaultsOverrideLowerLevelChange = new EventEmitter<boolean>();

    @Output()
    readonly defaultsSavedClick = new EventEmitter<MouseEvent>();

    readonly materials: BehaviorSubject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
    readonly colors: BehaviorSubject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);
    readonly addonCategoryGroupName = 'ADDON_CATEGORY_GROUP_';

    hex: string;
    materialColorThumbnail: string;
    showMaterialColorDialog: boolean;
    tileSelectLinks: SelectTileLink[];

    showTileDialog: boolean;
    tileDialogItems: SelectItem[] = [];
    tileValue: number;
    links: SelectTileLink[] = [];
    category: AddonCategory;

    accordionTabState = {
        main: true,
        description: false,
        defaults: false
    };

    constructor(public readonly editorFieldContentProvider: ConfigEditorFieldContentProvider,
                public readonly imageService: ConfigSidebarFieldImageService,
                private openImageInNewBrowserTabController: OpenImageInNewBrowserTabController,
                public changeDetector: ChangeDetectorRef,
                public readonly translate: TranslateService,
                public readonly addonService: AddonsService,
                private configSystemService: ConfigSystemService) {
        this.editorFieldContentProvider.getItemsStream(this.Fields.MATERIAL).subscribe(this.materials);
        this.editorFieldContentProvider.getItemsStream(this.Fields.COLOR).subscribe(this.colors);
    }

    ngOnInit() {
        this.accordionTabState = {
            main: true,
            description: false,
            defaults: false
        };
        (this.addonCategoryGroups || []).forEach(group => this.accordionTabState[group.id] = true);
        this.setMaterialColorThumbnail();
    }

    ngOnChanges(changes: SimpleChanges) {
        if ('data' in changes || 'colorMaterialLinks' in changes) {
            setTimeout(() => this.setMaterialColorThumbnail(), 0);
        }
    }

    handleFieldValueChange<K extends keyof ConfigDataInput>(field: string, dataField: K, value: ConfigDataInput[K]): void {
        const event: SidebarFieldChangeEvent = {field: field, oldValue: this.data[dataField], newValue: value};
        this.beforeFieldChange.emit(event);
        this.data[dataField] = value;
        this.afterFieldChange.emit(event);
        this.removeFieldFromUnavailable(field);
        if (field === ConfigEditorField.COLOR || field === ConfigEditorField.MATERIAL) {
            this.setMaterialColorThumbnail();
        }
    }

    handleFieldBlur<K extends keyof ConfigDataInput>(field: string, dataField: K): void {
        const event: SidebarFieldChangeEvent = {field: field, oldValue: this.data[dataField], newValue: this.data[dataField]};
        this.afterFieldBlur.emit(event);
    }

    handleSidebarFieldValueChange(field: string, dataField: string, value: number): void {
        const event: SidebarFieldChangeEvent = {field: field, oldValue: this.data.sidebarAddons[dataField], newValue: value};
        this.beforeFieldChange.emit(event);
        this.data.sidebarAddons[dataField] = value;
        this.afterFieldChange.emit(event);
        this.removeFieldFromUnavailable(field);
        if (this.category && this.category.tileDialog) {
            this.closeTileDialog();
        }
    }

    handleShowImage(imageSource: Observable<string>, header: string): void {
        this.onShowImage.emit(new WindowComponentPreviewData(imageSource, header));
    }

    private removeFieldFromUnavailable(field: string): void {
        const unavailableFields = this.editorFieldContentProvider.fieldsWithUnavailableValues;
        const index = unavailableFields.indexOf(field, 0);
        if (index > -1) {
            unavailableFields.splice(index, 1);
        }
    }

    showDescriptionDialog(): void {
        this.onShowDescriptionDialog.emit();
    }

    setMaterialColorThumbnail(): void {
        let selectedLink = this.colorMaterialLinks.find(link => {
            return link.colorId === this.data.colorId && link.materialId === this.data.materialId;
        });
        this.materialColorThumbnail = selectedLink ? selectedLink.image : null;
        this.hex = selectedLink ? selectedLink.hex : null;
        this.changeDetector.markForCheck();
    }

    onMaterialColorSave(materialAndColor: {selectValue: number, tileValue: number}) {
        let materialId = materialAndColor.selectValue;
        let colorId = materialAndColor.tileValue;
        this.showMaterialColorDialog = false;
        this.handleFieldValueChange(this.Fields.MATERIAL, 'materialId', materialId);
        this.handleFieldValueChange(this.Fields.COLOR, 'colorId', colorId);
        this.validationErrors[this.Fields.MATERIAL] = null;
        this.validationErrors[this.Fields.COLOR] = null;
    }

    openMaterialAndColorDialog(fakeSelect = true) {
        if (!fakeSelect) {
            return;
        }
        this.editorFieldContentProvider.clearHasNewItemsMarker(this.Fields.MATERIAL);
        this.editorFieldContentProvider.clearHasNewItemsMarker(this.Fields.COLOR);
        this.showMaterialColorDialog = true;
    }

    materialChange(materialId: number, fakeSelect = true) {
        if (fakeSelect) {
            return;
        }
        let color = (this.colorMaterialLinks.find(link => link.materialId === materialId) || {}).colorId;
        this.handleFieldValueChange(this.Fields.MATERIAL, 'materialId', materialId);
        this.handleFieldValueChange(this.Fields.COLOR, 'colorId', color);
        this.validationErrors[this.Fields.MATERIAL] = null;
        this.validationErrors[this.Fields.COLOR] = null;
    }

    handleOpenFullImage() {
        this.openFullImage(this.data.materialId, this.data.colorId);
    }

    handleHexFullScreen() {
        let label = this.getFullScreenImageLabel(this.data.materialId, this.data.colorId);
        this.openImageInNewBrowserTabController.openHexInNewBrowserTab(label, this.hex);
    }

    openFullImage(materialId: number, colorId: number, hex?: string) {
        let label = this.getFullScreenImageLabel(materialId, colorId);
        if (hex == null) {
            let smallImage = (this.colorMaterialLinks
                .filter(link => link.materialId === materialId)
                .find(link => link.colorId === colorId) || {}).image;
            this.openImageInNewBrowserTabController
                .openInNewBrowserTab(label, () => this.largeImageGetter(materialId, colorId), smallImage);
        } else {
            this.openImageInNewBrowserTabController
                .openHexInNewBrowserTab(label, hex);
        }
    }

    private getFullScreenImageLabel(materialId: number, colorId: number) {
        let materialName = (this.materials.getValue().find(material => material.value === materialId) || {}).label;
        let colorName = (this.colors.getValue().find(color => color.value === colorId) || {}).label;
        return `${materialName} - ${colorName}`;
    }

    getDataField(i: number): keyof ConfigDataInput {
        switch (i) {
            case 1:
                return 'wym1';
            case 2:
                return 'wym2';
            case 3:
                return 'wym3';
            case 4:
                return 'wym4';
            case 5:
                return 'wym5';
            case 6:
                return 'wym6';
        }
    }

    showParentInfoIndex(application: ConfigAddonApplication): boolean {
        return application === ConfigAddonApplication.INDEPENDENT || application === ConfigAddonApplication.AREA;
    }

    getDescription(description: ConfigAddonParentInfo) {
        let lang = this.translate.currentLang;
        let name = description.name[lang] || '';
        if (description.application === ConfigAddonApplication.SUBWINDOW) {
            name = this.subwindowTypes.getFromMultilangName(description.name).names[lang];
        }
        return (name || "") + " " + description.dimensions;
    }

    openTileDialog(category: AddonCategory, items: SelectItem[]) {
        if (!category.tileDialog) {
            return;
        }
        this.tileValue = this.data.sidebarAddons[category.symbol];
        this.tileDialogItems = items;
        this.links = this.tileDialogItems.map(item => {
            let link = new SelectTileLink();
            link.selectValue = TileSelectDialogComponent.DUMMY_SELECT_VALUE;
            link.tileValue = item.value;
            link.image = item.icon;
            link.hex = item.title;
            return link;
        });
        this.category = category;
        this.showTileDialog = true;
    }

    closeTileDialog() {
        this.showTileDialog = false;
        this.tileValue = null;
        this.tileDialogItems = null;
        this.links = [];
        this.category = null;
    }

    openAddonFullImage(addonId: number, hex?: string) {
        let categoryName = this.category.name[this.translate.currentLang];
        let addonName = (this.tileDialogItems.find(item => item.value === addonId) || {}).label;
        let label = `${categoryName} - ${addonName}`;
        if (hex == null) {
            let smallImage = (this.links.find(link => link.tileValue === addonId) || {}).image;
            this.openImageInNewBrowserTabController
                .openInNewBrowserTab(label, () => this.addonService.getImageForItem(addonId), smallImage);
        } else {
            this.openImageInNewBrowserTabController
                .openHexInNewBrowserTab(label, hex);
        }
    }

    getMaterialLabel() {
        let customKey = 'CONFIG_LABEL.' + this.selectedSystem.id + '.NAME.MATERIAL';
        let instant = this.translate.instant(customKey);
        return instant === customKey ? 'OFFER.TABS.SECTION.MAIN.MATERIAL' : customKey;
    }

    getColorLabel() {
        let customKey = 'CONFIG_LABEL.' + this.selectedSystem.id + '.NAME.COLOR';
        let instant = this.translate.instant(customKey);
        return instant === customKey ? 'OFFER.TABS.SECTION.MAIN.COLOR' : customKey;
    }

    handlePhotoDownloadClick() {
        this.configSystemService.getConfigSystemFileUrl(this.selectedSystem.id).subscribe(url => window.open(url, '_blank'));
    }

    getTooltipLabel(dimensionNumber: number) {
        const key = 'CONFIG_DIMENSION.' + this.selectedSystem.id + '.TOOLTIP.DIM_' + dimensionNumber;
        const label = this.translate.instant(key);
        return label === key ? null : label;
    }

    noOptionsAvailable(items: SelectItem[]): boolean {
        return items.every(item => !item.available);
    }
}
