import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import * as _ from "underscore";
import {WindowSystemInterface} from '../../../../../../window-designer/catalog-data/window-system-interface';
import {DrawingData} from '../../../../../../window-designer/drawing-data/drawing-data';
import {ConfigurableAddon} from '../../../../../../window-designer/entities/ConfigurableAddon';
import {PricingUtils} from '../../../../../../window-designer/utils/PricingUtils';
import {ListOfIds} from '../../../../ListOfIds';
import {GateData} from '../../../gate-editor/gate-data';
import {
    PricingComponentOverrides
} from "../../../offers/detailed-pricing/simulation-parameters-dialog/detailed-pricing-simulation-definitions";
import {GlobalConfigAddonAddResultList} from '../../../offers/position/position-list/bulk-change-confirmation/globalConfigAddonAddResult';
import {EntranceDoorData} from '../../roof-window-editor/entrance-door-data';
import {RoofWindowData} from '../../roof-window-editor/RoofWindowData';
import {ConfigurableAddonPositionModel} from './config-addon-pricing/ConfigurableAddonPositionModel';
import {Pricing} from './Pricing';
import {PricingResult} from './PricingResult';
import {PricingWithModel} from './PricingWithModel';
import {ProfilesCompositionDistances} from "../../../../../../window-designer/profiles-composition-distances";
import {DetailedPricing} from "../../../offers/detailed-pricing/detailed-pricing";

@Injectable()
export class PricingService {

    constructor(private http: HttpClient) {
    }

    public evaluate(readOnlyMode: boolean, data: DrawingData, profileCompositionDistances: ProfilesCompositionDistances, offerId: number,
        offerPositionId: number, windowSystem: WindowSystemInterface, validationOnly = false, skipEnhancing = false,
        configAddons: { [id: number]: number } = undefined, validationDisabled = false): Observable<Pricing> {
        if (readOnlyMode) {
            return this.getSavedPricing(offerPositionId);
        }
        let preparedData = skipEnhancing ? data : PricingUtils.enhanceForPricing(data, windowSystem, profileCompositionDistances);
        let params = this.prepareParams(offerId, offerPositionId, validationOnly, validationDisabled);
        const formData = new FormData();
        formData.append('drawingDataDto', new Blob([JSON.stringify(preparedData)], {type: 'application/json'}));
        if (configAddons != undefined) {
            formData.append('configSystemsIds', new Blob([JSON.stringify(configAddons)], {type: 'application/json'}));
        }
        return this.http.post<Pricing>('pricing/window', formData, {params: params});
    }

    public evaluateRoofWindow(readOnlyMode: boolean, data: RoofWindowData, offerId: number, offerPositionId: number,
                              validationOnly = false, validationDisabled = false): Observable<Pricing> {
        if (readOnlyMode) {
            return this.getSavedPricing(offerPositionId);
        }
        let params = this.prepareParams(offerId, offerPositionId, validationOnly, validationDisabled);
        return this.http.post<Pricing>('pricing/roofWindow', data, {params: params});
    }

    public evaluateEntranceDoor(readOnlyMode: boolean, data: EntranceDoorData, offerId: number, offerPositionId: number,
                              validationOnly = false, validationDisabled = false): Observable<Pricing> {
        if (readOnlyMode) {
            return this.getSavedPricing(offerPositionId);
        }
        let params = this.prepareParams(offerId, offerPositionId, validationOnly, validationDisabled);
        return this.http.post<Pricing>('pricing/entranceDoor', data, {params: params});
    }

    public evaluateGate(readOnlyMode: boolean, data: GateData, offerId: number, offerPositionId: number,
                        validationOnly = false, validationDisabled = false): Observable<Pricing> {
        if (readOnlyMode) {
            return this.getSavedPricing(offerPositionId);
        }
        let params = this.prepareParams(offerId, offerPositionId, validationOnly, validationDisabled);
        return this.http.post<Pricing>('pricing/gate', data, {params: params});
    }

    public evaluateConfig(readOnlyMode: boolean, data: ConfigurableAddon, offerId: number, offerPositionId: number,
                        validationOnly = false, validationDisabled = false): Observable<Pricing> {
        if (readOnlyMode) {
            return this.getSavedPricing(offerPositionId);
        }
        let params = this.prepareParams(offerId, offerPositionId, validationOnly, validationDisabled);
        return this.http.post<Pricing>('pricing/config', data, {params: params});
    }

    private prepareParams(offerId: number, offerPositionId: number, validationOnly: boolean,
                          validationDisabled: boolean): { [param: string]: string | string[] } {
        let params = {};
        params['validationOnly'] = validationOnly ? 'true' : 'false';
        params['offerId'] = '' + offerId;
        if (offerPositionId != undefined) {
            params['offerPositionId'] = '' + offerPositionId;
        }
        params['validationDisabled'] = `${validationDisabled}`;
        return params;
    }

    public evaluateAddons(offerId: number, configuredAddons: ConfigurableAddon[],
                          validationDisabled: boolean[]): Observable<PricingResult[]> {
        const formData = new FormData();
        formData.append('configuredAddons', new Blob([JSON.stringify(configuredAddons)], {type: 'application/json'}));
        formData.append('validationDisabled', new Blob([JSON.stringify(validationDisabled)], {type: 'application/json'}));
        return this.http.post<PricingResult[]>(`pricing/configAddons/${offerId}`, formData);
    }

    public tryAddingAddonsGlobally(offerId: number, addon: ConfigurableAddon, windowsIds: ListOfIds): Observable<GlobalConfigAddonAddResultList> {
        let formData = new FormData();
        formData.append('configuredAddon', new Blob([JSON.stringify(addon)], {
            type: 'application/json'
        }));
        formData.append('windowsIds', new Blob([JSON.stringify(windowsIds)], {
            type: 'application/json'
        }));
        return this.http.post<GlobalConfigAddonAddResultList>(`pricing/configAddonsAddedGlobally/${offerId}`, formData);
    }

    public joinPricingWithModels(pricingResultList: PricingResult[], models: ConfigurableAddonPositionModel[]): PricingWithModel[] {
        if (!pricingResultList || !models) {
            return [];
        }
        if (pricingResultList.length !== models.length) {
            throw new Error('Pricings and models sizes to not match');
        }
        return pricingResultList.map((value, index) => new PricingWithModel(value, models[index]));
    }

    public getSavedPricing(positionId: number): Observable<Pricing> {
        return this.http.get<Pricing>(`pricing/${positionId}`);
    }

    public getSaveProdOrderPricing(positionId: number): Observable<Pricing> {
        return this.http.get<Pricing>(`pricing/productionOrder/${positionId}`);
    }

    public getDetailedPricing(offerId: number): Observable<DetailedPricing[]> {
        return this.http.get<DetailedPricing[]>(`pricing/${offerId}/detailedPricing`);
    }

    public getDetailedPricingSimulation(offerId: number, overrides: PricingComponentOverrides): Observable<DetailedPricing[]> {
        const payload = _.object(Object.entries(overrides)
            .map(entry => {
                if (entry[1] instanceof Map) {
                    return [entry[0], _.object(Array.from(entry[1].entries()))];
                }
                return entry;
            }));
        return this.http.post<DetailedPricing[]>(`pricing/${offerId}/detailedPricingSimulation`, payload);
    }
}
