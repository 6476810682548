import {ConfigSystemDefaults} from "../../settings/config-system-defaults/config-system-defaults";
import {ConfigSystemDefaultsState} from "../../settings/system-defaults/system-default-state";

export class ConfigData extends ConfigSystemDefaults {
    wym1: number; wym2: number; wym3: number; wym4: number; wym5: number; wym6: number;
    configSystemId: number;
    usedGlobalSettingsLevel: string;
    usedGlobalSettingsChanged: boolean;

    constructor() {
        super();
    }

    static applyDefaults(data: ConfigData, defaults: ConfigSystemDefaultsState, applyDimensions: boolean): void {
        const level = ConfigSystemDefaultsState.getLevel(defaults);
        if (applyDimensions) {
            data.wym1 = defaults.value.wym1;
            data.wym2 = defaults.value.wym2;
            data.wym3 = defaults.value.wym3;
            data.wym4 = defaults.value.wym4;
            data.wym5 = defaults.value.wym5;
            data.wym6 = defaults.value.wym6;
        }
        data.materialId = defaults.value.materialId;
        data.colorId = defaults.value.colorId;
        data.sidebarAddons = defaults.value && defaults.value.sidebarAddons || {};
        data.usedGlobalSettingsLevel = level;
    }
}
