import {Pipe, PipeTransform} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";

@Pipe({
    name: 'isNotAvailable'
})
export class IsNotAvailablePipe implements PipeTransform {

    transform(selectedId: number, items: SelectItem[], canBeEmpty = false): boolean {
        if (items && items.length > 0) {
            if (selectedId) {
                const filteredItems = items.filter(item => item.value === selectedId);
                return filteredItems.length > 0 ? !filteredItems[0].available : !canBeEmpty;
            } else {
                return !canBeEmpty;
            }
        }
        return false;
    }
}
