<div class="list-content">
    <app-shipping-simulation-sidebar #shippingSidebar currentRoute="/features/offer"
                                     [currentRouteParams]="{component: 'offer'}" (onHide)="shippingSidebarHidden()"
                                     (onCalculationStart)="shippingCalculationStarted()"
                                     (onCalculationFinish)="shippingCalculationFinished()"></app-shipping-simulation-sidebar>

    <app-shipping-simulation-float-button #shippingFloatButton
                                          [calculationInProgress]="shippingCalculationInProgress"
                                          (onClick)="showShippingSidebar()"></app-shipping-simulation-float-button>

    <div class="table-header">
        <div class="title">
            {{ 'NAVIGATION.ITEMS.OFFER' | translate }}
        </div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>

                <!-- CLEAR HIDDEN FILTERS -->
                <app-simple-button id="clearFilters" *ngIf="incomingClientId != undefined || filterByCurrentUser"
                                   label="{{ 'GENERAL.CLEAR_FILTERS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="removeFiltersNotSetOnTableColumns()"></app-simple-button>

                <!-- SHOW USER OFFERS -->
                <app-simple-button *ngIf="incomingClientId == undefined && !filterByCurrentUser"
                                   label="{{ 'OFFER.ACTIONS.TOOLTIPS.SHOW_OWN_OFFERS' | translate }}"
                                   icon="filter_list" type="action"
                                   (onClick)="enableFilterByCurrentUser()"></app-simple-button>

                <!--CSV export-->
                <app-simple-button buttonId="exportCSV" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="exportCSV('NAVIGATION.ITEMS.OFFERS.OFFERS')"></app-simple-button>

                <!--Visible columns-->
                <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}" id="offersShownColumns"
                               [displaySelectedLabel]="false" [options]="columnOptions | selectItemTranslate"
                               [ngModel]="selectedColumns"
                               (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>

                <!-- Currency select -->
                <button-with-menu buttonId="currencySelectButton" [buttonLabel]="selectedCurrency"
                                  rightButtonIcon="keyboard_arrow_down" buttonType="action" [buttonSize]="32"
                                  [menuType]="menuType.BOTTOM_RIGHT" [menuElements]="availableCurrencies"
                                  (menuElementSelected)="handleCurrencyMenuElementSelected($event)"></button-with-menu>

                <app-simple-button *ngIf="showPrintButton" icon="print" type="action" buttonId="printButton"
                                   label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HEADER' | translate }}"
                                   (onClick)="openPrintDialog()"></app-simple-button>

                <!--Add offers to shipping -->
                <app-simple-button *ngIf="selectedItems.length > 0" icon="local_shipping" type="action"
                                   (onClick)="addSelectedToShipping(true)"></app-simple-button>

                <!-- ADD OFFER -->
                <app-simple-button *ngIf="isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})"
                                   buttonId="addOffer" label="{{ 'NAVIGATION.ITEMS.OFFERS.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="redirectToCreateOfferComponent()"></app-simple-button>
            </div>
        </div>
    </div>

    <div class="datatable-container">
        <p-table #dt [value]="offers" [rows]="chosenRowsPerPage" [attr.id]="OFFERS_TABLE_ID"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions" [paginator]="false" [first]="chosenPageNumber"
                 [metaKeySelection]="true" (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" (onLazyLoad)="loadItemsLazy($event)"
                 [rowTrackBy]="rowTrackById" dataKey="id" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th style="vertical-align: middle">
                        <app-tristate-checkbox [(ngModel)]="allSelectedState"
                                               (ngModelChange)="selectAllChange()"></app-tristate-checkbox>
                    </th>
                    <th>
                        {{ 'OFFER.FORM.ACTIONS' | translate }}
                    </th>
                    <th *ngIf="columnByField['offerNumber']" pSortableColumn="offerNumber"
                        [ngClass]="columnHeaderClasses('offerNumber')">
                        <span>{{ 'OFFER.FORM.OFFER_NUMBER' | translate }}</span>
                        <p-sortIcon field="offerNumber"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['offerNumber'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)" name="offerNumber"
                               (input)="dt.filter(getInputEventValue($event), 'offerNumber', 'contains')">
                    </th>
                    <th *ngIf="columnByField['alternateOfferNumber']" pSortableColumn="alternateOfferNumber"
                        [ngClass]="columnHeaderClasses('alternateOfferNumber')">
                        <span>{{ 'OFFER.FORM.ALT_OFFER_NUMBER' | translate }}</span>
                        <p-sortIcon field="alternateOfferNumber"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['alternateOfferNumber'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)" name="alternateOfferNumber"
                               (input)="dt.filter(getInputEventValue($event), 'alternateOfferNumber', 'contains')">
                    </th>
                    <th *ngIf="columnByField['createdDate']" pSortableColumn="createdDate"
                        [ngClass]="columnHeaderClasses('createdDate')">
                        <span>{{ 'OFFER.FORM.CREATED_DATE' | translate }}</span>
                        <p-sortIcon field="createdDate"></p-sortIcon>
                        <div app-table-date-range-filter="createdDate"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                    <th *ngIf="columnByField['lastStatusChange']" pSortableColumn="lastStatusChange"
                        [ngClass]="columnHeaderClasses('lastStatusChange')">
                        <span>{{ 'OFFER.FORM.LAST_STATUS_CHANGE' | translate }}</span>
                        <p-sortIcon field="lastStatusChange"></p-sortIcon>
                        <div app-table-date-range-filter="lastStatusChange"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                    <th *ngIf="columnByField['status']" pSortableColumn="status"
                        [ngClass]="columnHeaderClasses('status')">
                        <span>{{ 'OFFER.FORM.STATUS' | translate }}</span>
                        <p-sortIcon field="status"></p-sortIcon>
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                             (click)="handleInputFilterClick($event)">
                            <p-multiSelect [options]="availableStatuses | selectItemTranslate"
                                           defaultLabel="{{ 'OFFER.FORM.STATUS' | translate }}" appendTo="body"
                                           [displaySelectedLabel]="false" [ngModel]="selectedStatuses"
                                           (ngModelChange)="handleStatusFilterChange($event)"></p-multiSelect>
                        </div>
                    </th>
                    <th *ngIf="columnByField['lastModifiedDate']" pSortableColumn="lastModifiedDate"
                        [ngClass]="columnHeaderClasses('lastModifiedDate')">
                        <span>{{ 'OFFER.FORM.LAST_MODIFIED_DATE' | translate }}</span>
                        <p-sortIcon field="lastModifiedDate"></p-sortIcon>
                        <div app-table-date-range-filter="lastModifiedDate"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                    <th *ngIf="columnByField['validFrom']" pSortableColumn="validFrom"
                        [ngClass]="columnHeaderClasses('validFrom')">
                        <span>{{ 'OFFER.FORM.VALID_FROM' | translate }}</span>
                        <p-sortIcon field="validFrom"></p-sortIcon>
                        <div app-table-date-range-filter="validFrom"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                    <th *ngIf="columnByField['validTo']" pSortableColumn="validTo"
                        [ngClass]="columnHeaderClasses('validTo')">
                        <span>{{ 'OFFER.FORM.VALID_TO' | translate }}</span>
                        <p-sortIcon field="validTo"></p-sortIcon>
                        <div app-table-date-range-filter="validTo"
                             [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                    </th>
                    <th *ngIf="columnByField['subsystemName']" pSortableColumn="subsystemName"
                        [ngClass]="columnHeaderClasses('subsystemIds')">
                        <span>{{ 'OFFER.FORM.SUBSYSTEM' | translate }}</span>
                        <p-sortIcon field="subsystemName"></p-sortIcon>
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                             (click)="handleInputFilterClick($event)">
                            <p-multiSelect [options]="availableSubsystems"
                                           defaultLabel="{{ 'NAVIGATION.ITEMS.SUBSYSTEMS.LIST' | translate }}"
                                           appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                           (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                        </div>
                    </th>
                    <th *ngIf="columnByField['clientManagerName']" pSortableColumn="clientManagerName"
                        [ngClass]="columnHeaderClasses('clientManagerName')">
                        <span>{{ 'OFFER.FORM.CLIENT_MANAGER' | translate }}</span>
                        <p-sortIcon field="clientManagerName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['clientManagerName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'clientManagerName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['subClientManagerName']" [ngClass]="columnHeaderClasses('subClientManagerName')"
                        pSortableColumn="subClientManagerName">
                        <span>{{ 'OFFER.FORM.SUB_CLIENT_MANAGER' | translate }}</span>
                        <p-sortIcon field="subClientManagerName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['subClientManagerName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'subClientManagerName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['clientGroupName']" pSortableColumn="clientGroupName"
                        [ngClass]="columnHeaderClasses('clientGroupName')">
                        <span>{{ 'OFFER.FORM.CLIENT_GROUP' | translate }}</span>
                        <p-sortIcon field="clientGroupName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['clientGroupName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'clientGroupName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sellerClientGroupName']" pSortableColumn="clientGroupName"
                        [ngClass]="columnHeaderClasses('sellerClientGroupName')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_GROUP' | translate }}</span>
                        <p-sortIcon field="clientGroupName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sellerClientGroupName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sellerClientGroupName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['merchantGroupName']"
                        [ngClass]="columnHeaderClasses('merchantGroupName')">
                        <span>{{ 'OFFER.FORM.MERCHANT_GROUP' | translate }}</span>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['merchantGroupName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'merchantGroupName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['clientName']" pSortableColumn="clientName"
                        [ngClass]="columnHeaderClasses('clientName')">
                        <span>{{ 'OFFER.FORM.CLIENT_NAME' | translate }}</span>
                        <p-sortIcon field="clientName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['clientName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'clientName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['clientEmail']" pSortableColumn="clientEmail"
                        [ngClass]="columnHeaderClasses('clientEmail')">
                        <span>{{ 'OFFER.FORM.CLIENT_EMAIL' | translate }}</span>
                        <p-sortIcon field="clientEmail"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['clientEmail'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'clientEmail', 'contains')">
                    </th>
                    <th *ngIf="columnByField['clientIdentifier']" pSortableColumn="clientIdentifier"
                        [ngClass]="columnHeaderClasses('clientIdentifier')">
                        <span>{{ 'OFFER.FORM.CLIENT_IDENTIFIER' | translate }}</span>
                        <p-sortIcon field="clientIdentifier"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['clientIdentifier'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'clientIdentifier', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sellerClientName']" pSortableColumn="sellerClientName"
                        [ngClass]="columnHeaderClasses('sellerClientName')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_NAME' | translate }}</span>
                        <p-sortIcon field="sellerClientName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sellerClientName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sellerClientName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sellerClientEmail']" pSortableColumn="sellerClientEmail"
                        [ngClass]="columnHeaderClasses('sellerClientEmail')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_EMAIL' | translate }}</span>
                        <p-sortIcon field="clientEmail"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sellerClientEmail'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sellerClientEmail', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sellerClientIdentifier']" pSortableColumn="clientIdentifier"
                        [ngClass]="columnHeaderClasses('sellerClientIdentifier')">
                        <span>{{ 'OFFER.FORM.SELLER_CLIENT_IDENTIFIER' | translate }}</span>
                        <p-sortIcon field="clientIdentifier"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sellerClientIdentifier'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sellerClientIdentifier', 'contains')">
                    </th>
                    <th *ngIf="columnByField['windowsCount']" pSortableColumn="windowsCount"
                        [ngClass]="columnHeaderClasses('windowsCount')">
                        <span>{{ 'OFFER.FORM.WINDOWS_COUNT' | translate }}</span>
                        <p-sortIcon field="windowsCount"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['windowsCount'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'windowsCount', 'contains')">
                    </th>
                    <th *ngIf="columnByField['vatRetailSell']" pSortableColumn="vatRetailSell"
                        [ngClass]="columnHeaderClasses('vatRetailSell')">
                        <span>{{ 'OFFER.FORM.VAT_RETAIL_SELL' | translate }}</span>
                        <p-sortIcon field="vatRetailSell"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['vatRetailSell'].defaultFilterValue?.value" type="number"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'vatRetailSell', 'contains')">
                    </th>
                    <th *ngIf="columnByField['retailSellNetVal']" pSortableColumn="retailSellNetVal"
                        [ngClass]="columnHeaderClasses('retailSellNetVal')">
                        <span>{{ 'OFFER.FORM.RETAIL_SELL_NET_VAL' | translate }}</span>
                        <p-sortIcon field="retailSellNetVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['retailSellNetVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'retailSellNetVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['retailSellGrossVal']" pSortableColumn="retailSellGrossVal"
                        [ngClass]="columnHeaderClasses('retailSellGrossVal')">
                        <span>{{ 'OFFER.FORM.RETAIL_SELL_GROSS_VAL' | translate }}</span>
                        <p-sortIcon field="retailSellGrossVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['retailSellGrossVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'retailSellGrossVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['vatBuy']" pSortableColumn="vatBuy"
                        [ngClass]="columnHeaderClasses('vatBuy')">
                        <span>{{ 'OFFER.FORM.VAT_BUY' | translate }}</span>
                        <p-sortIcon field="vatBuy"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['vatBuy'].defaultFilterValue?.value" type="number"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'vatBuy', 'contains')">
                    </th>
                    <th *ngIf="columnByField['vatSell']" pSortableColumn="vatSell"
                        [ngClass]="columnHeaderClasses('vatSell')">
                        <ng-container *ngIf="permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']}); else vatSellLabel">
                            <span>{{ 'OFFER.FORM.VAT_SELL_FOR_SELLER' | translate }}</span>
                        </ng-container>
                        <ng-template #vatSellLabel><span>{{ 'OFFER.FORM.VAT_SELL' | translate }}</span></ng-template>
                        <p-sortIcon field="vatSell"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['vatSell'].defaultFilterValue?.value" type="number"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'vatSell', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sellNetVal']" pSortableColumn="sellNetVal"
                        [ngClass]="columnHeaderClasses('sellNetVal')">
                        <ng-container *ngIf="permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']}); else sellNetLabel">
                            <span>{{ 'OFFER.FORM.SELL_NET_VAL_FOR_SELLER' | translate }}</span>
                        </ng-container>
                        <ng-template #sellNetLabel><span>{{ 'OFFER.FORM.SELL_NET_VAL' | translate }}</span></ng-template>
                        <p-sortIcon field="sellNetVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sellNetVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sellNetVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sellGrossVal']" pSortableColumn="sellGrossVal"
                        [ngClass]="columnHeaderClasses('sellGrossVal')">
                        <ng-container *ngIf="permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']}); else sellGrossLabel">
                            <span>{{ 'OFFER.FORM.SELL_GROSS_VAL_FOR_SELLER' | translate }}</span>
                        </ng-container>
                        <ng-template #sellGrossLabel><span>{{ 'OFFER.FORM.SELL_GROSS_VAL' | translate }}</span></ng-template>
                        <p-sortIcon field="sellGrossVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sellGrossVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sellGrossVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['buyNetVal']" pSortableColumn="buyNetVal"
                        [ngClass]="columnHeaderClasses('buyNetVal')">
                        <span>{{ 'OFFER.FORM.BUY_NET_VAL' | translate }}</span>
                        <p-sortIcon field="buyNetVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['buyNetVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'buyNetVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['buyGrossVal']" pSortableColumn="buyGrossVal"
                        [ngClass]="columnHeaderClasses('buyGrossVal')">
                        <span>{{ 'OFFER.FORM.BUY_GROSS_VAL' | translate }}</span>
                        <p-sortIcon field="buyGrossVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['buyGrossVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'buyGrossVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['buyVenskaNetVal']" pSortableColumn="buyVenskaNetVal"
                        [ngClass]="columnHeaderClasses('buyVenskaNetVal')">
                        <span>{{ 'OFFER.FORM.BUY_VENSKA_NET_VAL' | translate }}</span>
                        <p-sortIcon field="buyVenskaNetVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['buyVenskaNetVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'buyVenskaNetVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['buyVenskaGrossVal']" pSortableColumn="buyVenskaGrossVal"
                        [ngClass]="columnHeaderClasses('buyVenskaGrossVal')">
                        <span>{{ 'OFFER.FORM.BUY_VENSKA_GROSS_VAL' | translate }}</span>
                        <p-sortIcon field="buyVenskaGrossVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['buyVenskaGrossVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'buyVenskaGrossVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['ownAddonsNetCost']" pSortableColumn="ownAddonsNetCost"
                        [ngClass]="columnHeaderClasses('ownAddonsNetCost')">
                        <span>{{ 'OFFER.FORM.OWN_ADDONS_NET_COST' | translate }}</span>
                        <p-sortIcon field="ownAddonsNetCost"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['ownAddonsNetCost'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'ownAddonsNetCost', 'contains')">
                    </th>
                    <th *ngIf="columnByField['ownAddonsGrossCost']" pSortableColumn="ownAddonsGrossCost"
                        [ngClass]="columnHeaderClasses('ownAddonsGrossCost')">
                        <span>{{ 'OFFER.FORM.OWN_ADDONS_GROSS_COST' | translate }}</span>
                        <p-sortIcon field="ownAddonsGrossCost"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['ownAddonsGrossCost'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'ownAddonsGrossCost', 'contains')">
                    </th>
                    <th *ngIf="columnByField['assemblyNetCost']" pSortableColumn="assemblyNetCost"
                        [ngClass]="columnHeaderClasses('assemblyNetCost')">
                        <span>{{ 'OFFER.FORM.ASSEMBLY_NET_COST' | translate }}</span>
                        <p-sortIcon field="assemblyNetCost"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['assemblyNetCost'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'assemblyNetCost', 'contains')">
                    </th>
                    <th *ngIf="columnByField['assemblyGrossCost']" pSortableColumn="assemblyGrossCost"
                        [ngClass]="columnHeaderClasses('assemblyGrossCost')">
                        <span>{{ 'OFFER.FORM.ASSEMBLY_GROSS_COST' | translate }}</span>
                        <p-sortIcon field="assemblyGrossCost"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['assemblyGrossCost'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'assemblyGrossCost', 'contains')">
                    </th>
                    <th *ngIf="columnByField['transportNetCost']" pSortableColumn="transportNetCost"
                        [ngClass]="columnHeaderClasses('transportNetCost')">
                        <span>{{ 'OFFER.FORM.TRANSPORT_NET_COST' | translate }}</span>
                        <p-sortIcon field="transportNetCost"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['transportNetCost'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'transportNetCost', 'contains')">
                    </th>
                    <th *ngIf="columnByField['transportGrossCost']" pSortableColumn="transportGrossCost"
                        [ngClass]="columnHeaderClasses('transportGrossCost')">
                        <span>{{ 'OFFER.FORM.TRANSPORT_GROSS_COST' | translate }}</span>
                        <p-sortIcon field="transportGrossCost"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['transportGrossCost'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'transportGrossCost', 'contains')">
                    </th>
                    <th *ngIf="columnByField['netRetailIncome']" pSortableColumn="netRetailIncome"
                        [ngClass]="columnHeaderClasses('netRetailIncome')">
                        <span>{{ 'OFFER.FORM.NET_RETAIL_INCOME' | translate }}</span>
                        <p-sortIcon field="netRetailIncome"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['netRetailIncome'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'netRetailIncome', 'contains')">
                    </th>
                    <th *ngIf="columnByField['grossRetailIncome']" pSortableColumn="grossRetailIncome"
                        [ngClass]="columnHeaderClasses('grossRetailIncome')">
                        <span>{{ 'OFFER.FORM.GROSS_RETAIL_INCOME' | translate }}</span>
                        <p-sortIcon field="grossRetailIncome"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['grossRetailIncome'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'grossRetailIncome', 'contains')">
                    </th>
                    <th *ngIf="columnByField['netIncome']" pSortableColumn="netIncome"
                        [ngClass]="columnHeaderClasses('netIncome')">
                        <span>{{ 'OFFER.FORM.NET_INCOME' | translate }}</span>
                        <p-sortIcon field="netIncome"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['netIncome'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'netIncome', 'contains')">
                    </th>
                    <th *ngIf="columnByField['grossIncome']" pSortableColumn="grossIncome"
                        [ngClass]="columnHeaderClasses('grossIncome')">
                        <span>{{ 'OFFER.FORM.GROSS_INCOME' | translate }}</span>
                        <p-sortIcon field="grossIncome"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['grossIncome'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'grossIncome', 'contains')">
                    </th>
                    <th *ngIf="columnByField['netVenskaIncome']" pSortableColumn="netVenskaIncome"
                        [ngClass]="columnHeaderClasses('netVenskaIncome')">
                        <span>{{ 'OFFER.FORM.NET_INCOME' | translate }}</span>
                        <p-sortIcon field="netVenskaIncome"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['netVenskaIncome'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'netVenskaIncome', 'contains')">
                    </th>
                    <th *ngIf="columnByField['grossVenskaIncome']" pSortableColumn="grossVenskaIncome"
                        [ngClass]="columnHeaderClasses('grossVenskaIncome')">
                        <span>{{ 'OFFER.FORM.GROSS_INCOME' | translate }}</span>
                        <p-sortIcon field="grossVenskaIncome"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['grossVenskaIncome'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'grossVenskaIncome', 'contains')">
                    </th>
                    <th *ngIf="columnByField['merchantName']" pSortableColumn="merchantName"
                        [ngClass]="columnHeaderClasses('merchantName')">
                        <span>{{ 'OFFER.FORM.MERCHANT_NAME' | translate }}</span>
                        <p-sortIcon field="merchantName"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['merchantName'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'merchantName', 'contains')">
                    </th>
                    <th *ngIf="columnByField['deliveryAddressStreet']" pSortableColumn="deliveryAddressStreet"
                        [ngClass]="columnHeaderClasses('deliveryAddressStreet')">
                        {{ 'OFFER.FORM.ADDRESS.STREET' | translate }}
                        <p-sortIcon field="deliveryAddressStreet"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['deliveryAddressStreet'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'deliveryAddressStreet', 'contains')">
                    </th>
                    <th *ngIf="columnByField['deliveryAddressCity']" pSortableColumn="deliveryAddressCity"
                        [ngClass]="columnHeaderClasses('deliveryAddressCity')">
                        {{ 'OFFER.FORM.ADDRESS.CITY' | translate }}
                        <p-sortIcon field="deliveryAddressCity"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['deliveryAddressCity'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'deliveryAddressCity', 'contains')">
                    </th>
                    <th *ngIf="columnByField['deliveryAddressZip']" pSortableColumn="deliveryAddressZip"
                        [ngClass]="columnHeaderClasses('deliveryAddressZip')">
                        {{ 'OFFER.FORM.ADDRESS.ZIP' | translate }}
                        <p-sortIcon field="deliveryAddressZip"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['deliveryAddressZip'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'deliveryAddressZip', 'contains')">
                    </th>
                    <th *ngIf="columnByField['deliveryAddressCountry']" pSortableColumn="deliveryAddressCountry"
                        [ngClass]="columnHeaderClasses('deliveryAddressCountry')">
                        {{ 'OFFER.FORM.ADDRESS.COUNTRY' | translate }}
                        <p-sortIcon field="deliveryAddressCountry"></p-sortIcon>
                        <div [class.p-column-filter-invisible]="!showFilters"
                             (click)="handleInputFilterClick($event)" class="app-filter-select">
                            <app-select inputId="deliveryAddressCountrySelect" [options]="filterCountry | async"
                                        [allowSelectingNone]="false" [ngModel]="countryUsedFilter"
                                        (ngModelChange)="handleCountryFilterChange($event)">
                                <ng-template pTemplate="label"></ng-template>
                            </app-select>
                        </div>
                    </th>
                    <th *ngIf="columnByField['sourceOfferNumber']" pSortableColumn="sourceOfferNumber"
                        [ngClass]="columnHeaderClasses('sourceOfferNumber')">
                        <span>{{ 'OFFER.FORM.SOURCE_OFFER_NUMBER' | translate }}</span>
                        <p-sortIcon field="sourceOfferNumber"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sourceOfferNumber'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)" name="sourceOfferNumber"
                               (input)="dt.filter(getInputEventValue($event), 'sourceOfferNumber', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sourceOfferSellVat']" pSortableColumn="sourceOfferSellVat"
                        [ngClass]="columnHeaderClasses('sourceOfferSellVat')">
                        <span>{{ 'OFFER.FORM.SOURCE_OFFER_VAT' | translate }}</span>
                        <p-sortIcon field="sourceOfferSellNetVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sourceOfferSellVat'].defaultFilterValue?.value" type="number"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sourceOfferSellVat', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sourceOfferSellNetVal']" pSortableColumn="sourceOfferSellNetVal"
                        [ngClass]="columnHeaderClasses('sourceOfferSellNetVal')">
                        <span>{{ 'OFFER.FORM.SOURCE_OFFER_NET_VAL' | translate }}</span>
                        <p-sortIcon field="sourceOfferSellNetVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sourceOfferSellNetVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sourceOfferSellNetVal', 'contains')">
                    </th>
                    <th *ngIf="columnByField['sourceOfferSellGrossVal']" pSortableColumn="sourceOfferSellGrossVal"
                        [ngClass]="columnHeaderClasses('sourceOfferSellGrossVal')">
                        <span>{{ 'OFFER.FORM.SOURCE_OFFER_GROSS_VAL' | translate }}</span>
                        <p-sortIcon field="sourceOfferSellGrossVal"></p-sortIcon>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               [attr.value]="columnByField['sourceOfferSellGrossVal'].defaultFilterValue?.value"
                               (click)="handleInputFilterClick($event)"
                               (input)="dt.filter(getInputEventValue($event), 'sourceOfferSellGrossVal', 'contains')">
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-offer let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" (dblclick)="redirectToPositionList(offer)"
                    [pSelectableRow]="offer" [pSelectableRowIndex]="rowIndex" [app-table-keyboard-navigation]="offer"
                    (app-table-keyboard-navigation-enter)="redirectToPositionList(offer)" class="datatable-data">
                    <td style="vertical-align: middle">
                        <span class="flex-row">
                            <app-checkbox [ngModel]="isSelectedItem(offer)" (ngModelChange)="selectItem(offer)"></app-checkbox>
                            <i *ngIf="offer.pricingOutdated" (click)="actionOnClick('SHOW_MESSAGES', offer)"
                               class="fa font-awesome fas fa-exclamation-circle pricing_outdated-severity-color comment-icon-alignment"
                               title="{{ 'GENERAL.OFFER_PRICING_OUTDATED' | translate }}"></i>
                            <i *ngIf="!offer.pricingOutdated && offer.highestMessageSeverity != null"
                               (click)="actionOnClick('SHOW_MESSAGES', offer)"
                               class="fa font-awesome fas fa-exclamation-circle comment-icon-alignment"
                               [ngClass]="offer.highestMessageSeverity.toLowerCase() + '-severity-color'"
                               title="{{ ('GENERAL.MESSAGE.' + offer.highestMessageSeverity) | translate }}"></i>

                            <i *ngIf="showUnreadCommentIcon(offer)" class="fa font-awesome fas fa-comment error-severity-color comment-icon-alignment" title="{{ 'GENERAL.OFFER_UNREAD_COMMENT_EXIST' | translate }}"></i>
                            <i *ngIf="showCommentExistsIcon(offer)" class="fa font-awesome fas fa-comment comment-icon-alignment" title="{{ 'GENERAL.OFFER_COMMENT_EXIST' | translate }}"></i>
                            <i *ngIf="offer.hasPositionWithDisabledValidation" class="fa font-awesome fas fa-chain-broken comment-icon-alignment" title="{{ 'OFFER.VALIDATION_DISABLED' | translate }}"></i>
                        </span>
                    </td>
                    <td>
                        <div class="table-row-action-buttons">
                            <app-simple-button [buttonId]="'open_' + offer.id" icon="search" type="main-action" [size]="36"
                                               label="{{'OFFER.ACTIONS.TOOLTIPS.SHOW_OFFER_DETAILS' | translate}}" [inlineLabel]="false"
                                               (onClick)="actionOnClick('SHOW_DETAILS', offer)"></app-simple-button>
                            <button-with-menu [buttonId]="'more_' + offer.id" rightButtonIcon="more_horiz"
                                              buttonType="filter" [buttonSize]="36" [menuType]="menuType.BOTTOM_LEFT"
                                              buttonLabel="{{'OFFER.ACTIONS.TOOLTIPS.SHOW_MORE_ACTIONS' | translate}}" [buttonInlineLabel]="false"
                                              [menuElementBuilder]="getRowActionsBuilder(offer)"
                                              (menuElementSelected)="handleRowAction($event)"></button-with-menu>
                        </div>
                    </td>
                    <td *ngIf="columnByField['offerNumber']">
                        <app-offer-number [offerNumber]="offer.offerNumber" [isOffer]="true"></app-offer-number>
                    </td>
                    <td *ngIf="columnByField['alternateOfferNumber']">
                        {{ offer.alternateOfferNumber }}
                    </td>
                    <td *ngIf="columnByField['createdDate']">
                        {{ offer.createdDate | momentCurrentLang | amDateFormat: 'L' }}
                    </td>
                    <td *ngIf="columnByField['lastStatusChange']">
                        {{ offer.lastStatusChange | momentCurrentLang | amDateFormat: 'L LT' }}
                    </td>
                    <td *ngIf="columnByField['status']">
                        <div class="status-color {{ offer.status }}">
                            {{ ('OFFER.STATUS.' + offer.status) | translate }}
                        </div>
                    </td>
                    <td *ngIf="columnByField['lastModifiedDate']">
                        {{ offer.lastModifiedDate | momentCurrentLang | amDateFormat: 'L LT' }}
                    </td>
                    <td *ngIf="columnByField['validFrom']">
                        {{ offer.validFrom | momentCurrentLang | amDateFormat: 'L' }}
                    </td>
                    <td *ngIf="columnByField['validTo']">
                        {{ offer.validTo | momentCurrentLang | amDateFormat: 'L' }}
                    </td>
                    <td *ngIf="columnByField['subsystemName']">
                        {{ offer.subsystemName }}
                    </td>
                    <td *ngIf="columnByField['clientManagerName']">
                        {{ offer.clientManagerFirstName + ' ' + offer.clientManagerLastName }}
                    </td>
                    <td *ngIf="columnByField['subClientManagerName']">
                        <span *ngIf="offer.subClientManagerFirstName && offer.subClientManagerLastName">
                            {{ offer.subClientManagerFirstName + ' ' + offer.subClientManagerLastName }}
                        </span>
                    </td>
                    <td *ngIf="columnByField['clientGroupName']">
                        {{ offer.clientGroupName }}
                    </td>
                    <td *ngIf="columnByField['sellerClientGroupName']">
                        {{ offer.sellerClientGroupName }}
                    </td>
                    <td *ngIf="columnByField['merchantGroupName']">
                        <div *ngIf="containsSemicolon(offer.merchantGroupName)"
                             pTooltip="{{ changeSemicolonsToLineBreaks(offer.merchantGroupName) }}"
                             tooltipPosition="left">
                            ...
                        </div>
                        <div *ngIf="!containsSemicolon(offer.merchantGroupName)">
                            {{ offer.merchantGroupName }}
                        </div>
                    </td>
                    <td *ngIf="columnByField['clientName']">
                        {{ offer.clientName }}
                    </td>
                    <td *ngIf="columnByField['clientEmail']">
                        {{ offer.clientEmail }}
                    </td>
                    <td *ngIf="columnByField['clientIdentifier']">
                        {{ offer.clientIdentifier }}
                    </td>
                    <td *ngIf="columnByField['sellerClientName']">
                        {{ offer.sellerClientName }}
                    </td>
                    <td *ngIf="columnByField['sellerClientEmail']">
                        {{ offer.sellerClientEmail }}
                    </td>
                    <td *ngIf="columnByField['sellerClientIdentifier']">
                        {{ offer.sellerClientIdentifier }}
                    </td>
                    <td *ngIf="columnByField['windowsCount']">
                        {{ offer.windowsCount }}
                    </td>
                    <td *ngIf="columnByField['vatRetailSell']">
                        {{ offer.retailSellPrice.vatPercent }}
                    </td>
                    <td *ngIf="columnByField['retailSellNetVal']">
                        <span> {{ offer.retailSellPrice && offer.retailSellPrice.netValue ? (getPriceInSelectedCurrency(offer.retailSellPrice.netValue, offer.retailSellPriceInOfferCurrency.netValue, offer, true) | price: selectedCurrency) : ''}} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ offer.retailSellPrice && offer.retailSellPrice.netValue ? (getPriceInDefaultCurrency(offer.retailSellPrice.netValue, offer.retailSellPriceInOfferCurrency.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) + ' ' + offer.currency) : '' }} </span>
                    </td>
                    <td *ngIf="columnByField['retailSellGrossVal']">
                        <span> {{ offer.retailSellPrice && offer.retailSellPrice.grossValue ? (getPriceInSelectedCurrency(offer.retailSellPrice.grossValue, offer.retailSellPriceInOfferCurrency.grossValue, offer, true) + ' ' + selectedCurrency) : '' }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ offer.retailSellPrice && offer.retailSellPrice.grossValue ? (getPriceInDefaultCurrency(offer.retailSellPrice.grossValue, offer.retailSellPriceInOfferCurrency.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) + ' ' + offer.currency) : ''}} </span>
                    </td>
                    <td *ngIf="columnByField['vatBuy']">
                        {{ offer.buyPrice.vatPercent }}
                    </td>
                    <td *ngIf="columnByField['vatSell']">
                        {{ offer.sellPrice.vatPercent }}
                    </td>
                    <td *ngIf="columnByField['sellNetVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.sellPrice.netValue, offer.sellPriceInOfferCurrency.netValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.sellPrice.netValue, offer.sellPriceInOfferCurrency.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['sellGrossVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.sellPrice.grossValue, offer.sellPriceInOfferCurrency.grossValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.sellPrice.grossValue, offer.sellPriceInOfferCurrency.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['buyNetVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.buyPrice.netValue, offer.buyPriceInOfferCurrency.netValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.buyPrice.netValue, offer.buyPriceInOfferCurrency.netValue, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['buyGrossVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.buyPrice.grossValue, offer.buyPriceInOfferCurrency.grossValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.buyPrice.grossValue, offer.buyPriceInOfferCurrency.grossValue, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['buyVenskaNetVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.venskaBuyPrice.netValue, offer.venskaBuyPriceInOfferCurrency.netValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.venskaBuyPrice.netValue, offer.venskaBuyPriceInOfferCurrency.netValue, offer.exchangeRate) | price: offer.currency}} </span>
                    </td>
                    <td *ngIf="columnByField['buyVenskaGrossVal']">
                        <span> {{ getPriceInSelectedCurrency(offer.venskaBuyPrice.grossValue, offer.venskaBuyPriceInOfferCurrency.grossValue, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.venskaBuyPrice.grossValue, offer.venskaBuyPriceInOfferCurrency.grossValue, offer.exchangeRate) | price: offer.currency}} </span>
                    </td>
                    <td *ngIf="columnByField['ownAddonsNetCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.ownAddonsCost.netValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.ownAddonsCost.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['ownAddonsGrossCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.ownAddonsCost.grossValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.ownAddonsCost.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['assemblyNetCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.assemblyCost.netValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.assemblyCost.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['assemblyGrossCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.assemblyCost.grossValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.assemblyCost.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['transportNetCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.transportCost.netValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.transportCost.netValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['transportGrossCost']">
                        <span> {{ getPriceInSelectedCurrency(undefined, offer.transportCost.grossValue, offer, true) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(undefined, offer.transportCost.grossValue, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['netRetailIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.netRetailIncome, offer.netRetailIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.netRetailIncome, offer.netRetailIncomeInOfferCurrency, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['grossRetailIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.grossRetailIncome, offer.grossRetailIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.grossRetailIncome, offer.grossRetailIncomeInOfferCurrency, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['netIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.netIncome, offer.netIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.netIncome, offer.netIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['grossIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.grossIncome, offer.grossIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.grossIncome, offer.grossIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['netVenskaIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.netVenskaIncome, offer.netVenskaIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.netVenskaIncome,offer.netVenskaIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['grossVenskaIncome']">
                        <span> {{ getPriceInSelectedCurrency(offer.grossVenskaIncome, offer.grossVenskaIncomeInOfferCurrency, offer) | price: selectedCurrency }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ getPriceInDefaultCurrency(offer.grossVenskaIncome, offer.grossVenskaIncomeInOfferCurrency, offer.exchangeRate) | price: offer.currency }} </span>
                    </td>
                    <td *ngIf="columnByField['merchantName']">
                        {{ offer.merchantFirstName + ' ' + offer.merchantLastName }}
                    </td>
                    <td *ngIf="columnByField['deliveryAddressStreet']">
                        {{ offer.deliveryAddressStreet }}
                    </td>
                    <td *ngIf="columnByField['deliveryAddressCity']">
                        {{ offer.deliveryAddressCity }}
                    </td>
                    <td *ngIf="columnByField['deliveryAddressZip']">
                        {{ offer.deliveryAddressZip }}
                    </td>

                    <td *ngIf="columnByField['deliveryAddressCountry']">
                        <ng-container *ngIf="offer.deliveryAddressCountry != null">
                            {{ ('COUNTRIES.' + offer.deliveryAddressCountry) | translate }}
                        </ng-container>
                    </td>
                    <td *ngIf="columnByField['sourceOfferNumber']">
                        {{ offer.sourceWebshopOfferNumber }}
                    </td>
                    <td *ngIf="columnByField['sourceOfferSellVat']">
                        {{ offer.sourceWebshopOfferSellPrice.vatPercent }}
                    </td>
                    <td *ngIf="columnByField['sourceOfferSellNetVal']">
                        <span> {{ offer.sourceWebshopOfferSellPrice && offer.sourceWebshopOfferSellPrice.netValue ? (getPriceInSelectedCurrency(offer.sourceWebshopOfferSellPrice.netValue, undefined, offer, true) | price: selectedCurrency) : ''}} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ offer.sourceWebshopOfferSellPrice && offer.sourceWebshopOfferSellPrice.netValue ? (getPriceInDefaultCurrency(offer.sourceWebshopOfferSellPrice.netValue, undefined, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency) : '' }} </span>
                    </td>
                    <td *ngIf="columnByField['sourceOfferSellGrossVal']">
                        <span> {{ offer.sourceWebshopOfferSellPrice && offer.sourceWebshopOfferSellPrice.grossValue ? (getPriceInSelectedCurrency(offer.sourceWebshopOfferSellPrice.grossValue, undefined, offer, true) | price: selectedCurrency) : '' }} </span>
                        <span class="secondary-price" *ngIf="selectedCurrency != offer.currency"> {{ offer.sourceWebshopOfferSellPrice && offer.sourceWebshopOfferSellPrice.grossValue ? (getPriceInDefaultCurrency(offer.sourceWebshopOfferSellPrice.grossValue, undefined, offer.exchangeRate, offer.subsystemManualExchangeRate) | price: offer.currency) : ''}} </span>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr class="p-datatable-emptymessage-row">
                    <td [attr.colspan]="columns.length" class="p-datatable-emptymessage">
                        <span >{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}</span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="dt.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>

<app-offer-status-transition-dialog *ngIf="transitionsDialogVisible"
                                    [possibleTransitions]="possibleTransitions"
                                    infoLabelPrefix="OFFER.ACTIONS.DIALOGS.STATUS_TRANSITION_INFO.CONTENT."
                                    (onHide)="hideTransitionsDialog()"
                                    (onChangeStatus)="changeStatus($event)">
</app-offer-status-transition-dialog>

<app-print-dialog *ngIf="printDialogVisible" [printableSection]="PrintableSection.OFFERS" [selectedItems]="printableItems"
                  (onCloseDialog)="printDialogVisible = false"></app-print-dialog>

<!-- CONFIRM DELETE OFFER DIALOG -->
<app-confirm-action-dialog *ngIf="openedDialogNumber === OfferDialogId.DELETE_CONFIRM"
                           dialogId="deleteOfferDialog"
                           (onCancel)="closeTheDialog()"
                           (onConfirmed)="performAction('DELETE'); closeTheDialog()"
                           [question]="'GENERAL.DELETE_CONFIRMATION' | translate"
                           [header]="'GENERAL.DIALOG.DELETING' | translate"
                           [confirmButtonLabel]="'GENERAL.YES' | translate"
                           [discardButtonLabel]="'GENERAL.NO' | translate"></app-confirm-action-dialog>

<app-confirm-action-dialog *ngIf="openedDialogNumber === OfferDialogId.HIDE_DELETED_OFFER"
                           dialogId="hideDeletedOfferDialog"
                           (onCancel)="closeTheDialog()"
                           (onConfirmed)="performAction('HIDE_DELETED_OFFER'); closeTheDialog()"
                           [question]="'OFFER.ACTIONS.DIALOGS.HIDE_DELETED_OFFER.QUESTION' | translate"
                           [header]="'OFFER.ACTIONS.DIALOGS.HIDE_DELETED_OFFER.HEADER' | translate"
                           [confirmButtonLabel]="'GENERAL.YES' | translate"
                           [discardButtonLabel]="'GENERAL.NO' | translate"></app-confirm-action-dialog>

<app-messages-dialog *ngIf="openedDialogNumber === OfferDialogId.MESSAGES"
                           [displayedDialog]="dialogType.OFFER_MESSAGES"
                           [entityId]="currentActionOffer.id"
                           (onClose)="closeTheDialog()"></app-messages-dialog>

<div class="dialog-window" *ngIf="openedDialogNumber === OfferDialogId.COMMENTS">
    <app-comment-dialog [offerId]="currentActionOffer.id"
                        [mode]="commentDialogMode"
                        (onCloseDialog)="resetCommentDialog($event)"
                        (onSaveCommentSuccess)="onSaveCommentSuccess()"></app-comment-dialog>
</div>

<app-bulk-status-change-dialog *ngIf="openedDialogNumber === OfferDialogId.BULK_STATUS_CHANGE"
                               [selectedItems]="selectedItems"
                               (onHide)="handleBulkStatusChangeDialogClose($event)"></app-bulk-status-change-dialog>

<p-dialog *ngIf="openedDialogNumber === OfferDialogId.HISTORY" [visible]="true" [modal]="true">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.CREATE_OFFER.STEP_HISTORY' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-90vw">
        <app-offer-history [offerId]="currentActionOffer.id"></app-offer-history>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="closeTheDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<app-confirm-action-dialog *ngIf="openedDialogNumber === OfferDialogId.CONFIRM_PARTIAL_SIMULATION"
                           [header]="'OFFER.ACTIONS.DIALOGS.SIMULATION_IMPOSSBLE_FOR_SOME_POSITIONS.HEADER' | translate"
                           [question]="'OFFER.ACTIONS.DIALOGS.SIMULATION_IMPOSSBLE_FOR_SOME_POSITIONS.QUESTION' | translate"
                           [confirmButtonLabel]="'GENERAL.YES' | translate"
                           [discardButtonLabel]="'GENERAL.NO' | translate"
                           (onConfirmed)="addSelectedToShipping(false); closeTheDialog()"
                           (onCancel)="closeTheDialog()"></app-confirm-action-dialog>

<app-detailed-pricing *ngIf="openedDialogNumber === OfferDialogId.DETAILED_PRICING"
                      [offer]="currentActionOffer" (closeDialog)="closeTheDialog()"></app-detailed-pricing>
