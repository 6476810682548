import * as _ from 'underscore';
import {FieldWithPosition} from "./drawing-data/FieldWithPosition";
import {WindowData} from "./drawing-data/WindowData";
import {ErrorNames} from "./utils/ErrorNames";
import {FloatOps} from "./utils/float-ops";
import {PolygonPoint, PolygonPointUtil} from "./utils/PolygonPoint";
import {WindowDesignerInterface} from './window-designer-interface';

export class MinMaxXY {
    minX: number;
    maxX: number;
    minY: number;
    maxY: number;

    constructor(minX: number, maxX: number, minY: number, maxY: number) {
        this.minX = minX;
        this.maxX = maxX;
        this.minY = minY;
        this.maxY = maxY;
    }
}

export const DataKeys = {
    WINDOW: 'window',
    SUBWINDOW: 'subwindow',
    INNER_FRAME: 'inner-frame',
    GLAZING_BEAD: 'g-bead',
    WING: 'wing',
    AREA: 'area',
    FILLING: 'filling',
    GRILL: 'grill',
    GRILL_SEGMENT: 'grill-segment',
    HANDLE: 'handle',
    MULLION: 'mullion'
};

export class Rectangle {
    topL: number[];
    topR: number[];
    bottomR: number[];
    bottomL: number[];
}

export class IntersectionResult {
    intersects: boolean;
    x: number;
    y: number;
    onLine1: boolean;
    onLine2: boolean;

    hasEqualCoordinates(other: IntersectionResult): boolean {
        return FloatOps.eq(this.x, other.x) && FloatOps.eq(this.y, other.y);
    }
}

export class DrawingUtil {

    // Snap path intersection behaves badly for vertical lines
    static lineIntersection(x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number): IntersectionResult;
    static lineIntersection(line1: number[], line2: number[]): IntersectionResult;
    static lineIntersection(arg1: number | number[], arg2: number | number[], arg3?: number, arg4?: number,
                            arg5?: number, arg6?: number, arg7?: number, arg8?: number): IntersectionResult {
        let x1: number, y1: number, x2: number, y2: number, x3: number, y3: number, x4: number, y4: number;
        if (Array.isArray(arg1) && Array.isArray(arg2)) {
            let line1 = <number[]>arg1;
            let line2 = <number[]>arg2;
            x1 = line1[0];
            y1 = line1[1];
            x2 = line1[2];
            y2 = line1[3];
            x3 = line2[0];
            y3 = line2[1];
            x4 = line2[2];
            y4 = line2[3];
        } else if (typeof arg1 === "number" && typeof arg2 === "number") {
            x1 = <number>arg1;
            y1 = <number>arg2;
            x2 = arg3;
            y2 = arg4;
            x3 = arg5;
            y3 = arg6;
            x4 = arg7;
            y4 = arg8;
        }

        let denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
        let result = new IntersectionResult();
        if (FloatOps.eq(denom, 0)) {
            result.intersects = false;
            result.x = undefined;
            result.y = undefined;
            result.onLine1 = false;
            result.onLine2 = false;
            return result;
        }
        let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom;
        let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denom;

        result.intersects = true;
        result.x = x1 + ua * (x2 - x1);
        result.y = y1 + ua * (y2 - y1);
        result.onLine1 = FloatOps.ge(ua, 0) && FloatOps.le(ua, 1);
        result.onLine2 = FloatOps.ge(ub, 0) && FloatOps.le(ub, 1);
        return result;
    }

    /**
     * Returns coordinates of the intersection of the polygon and the vertical/horizontal line.
     * @param polygon
     * @param refPoint x or y coordinate to intersect polygon with
     * @param vertical true => refPoint is x, output is y; false => refPoint is y, output is x
     * @returns {Array} of 1 or 2 numbers - min and (optional) max coordinate of intersection - NOT ROUNDED!
     */
    static getMinAndMax(polygon: number[], refPoint: number, vertical: boolean): number[] {
        let abscissaIndex = vertical ? 0 : 1;
        let ordinateIndex = vertical ? 1 : 0;

        let result = [];
        for (let j = 0; j < polygon.length; j += 2) {
            let abscissa1 = polygon[j + abscissaIndex];
            let ordinate1 = polygon[j + ordinateIndex];
            let abscissa2 = polygon[(j + abscissaIndex + 2) % polygon.length];
            let ordinate2 = polygon[(j + ordinateIndex + 2) % polygon.length];
            if (abscissa1 === refPoint) {
                result.push(ordinate1);
            } else if (Math.min(abscissa1, abscissa2) < refPoint && Math.max(abscissa1, abscissa2) > refPoint) {
                result.push((ordinate2 - ordinate1) * (refPoint - abscissa1) / (abscissa2 - abscissa1) + ordinate1);
            }
        }
        if (result.length === 0 && polygon.length > 0) {
            // if there is no intersection at all, take "the nearest" coordinates
            let targetAbscissa = polygon.filter((value, idx) => idx % 2 === abscissaIndex).sort((a, b) => (a - b) * (a - refPoint))[0];
            for (let j = 0; j < polygon.length; j += 2) {
                let abscissa = polygon[j + abscissaIndex];
                let ordinate = polygon[j + ordinateIndex];
                if (abscissa === targetAbscissa) {
                    result.push(ordinate);
                }
            }
        }
        result.sort((a, b) => a - b);
        return result;
    }

    /**
     * @param onInfiniteLine if true - counts intersection with the line, if false or undefined - counts intersection with the line segment
     */
    static polygonLineIntersectionCount(polygonPoints: number[], line: number[], onInfiniteLine?: boolean): number {
        return this.polygonLineIntersections(polygonPoints, line, onInfiniteLine).length;
    }

    /**
     * @param onInfiniteLine if true - finds intersection with the line, if false or undefined - finds intersection with the line segment
     */
    static polygonLineIntersections(polygonPoints: number[], line: number[], onInfiniteLine?: boolean,
                                    isPolygonalChain = false): IntersectionResult[] {
        let intersections: IntersectionResult[] = [];
        for (let j = 0; j < polygonPoints.length - (isPolygonalChain ? 2 : 0); j += 2) {
            let x1 = polygonPoints[j];
            let y1 = polygonPoints[j + 1];
            let x2 = polygonPoints[(j + 2) % polygonPoints.length];
            let y2 = polygonPoints[(j + 3) % polygonPoints.length];
            let intersection = DrawingUtil.lineIntersection(x1, y1, x2, y2, line[0], line[1], line[2], line[3]);
            if (intersection.onLine1
                && (intersection.onLine2 || onInfiniteLine)
                && !intersections.some(i => i.hasEqualCoordinates(intersection))) {
                intersections.push(intersection);
            }
        }
        return intersections;
    }

    public static getPolygonsOverlapPoints(polygonA: number[], polygonB: number[], stopAfterFirst = false): number[] {
        let points: PolygonPoint[] = [];
        for (let j = 0; j < polygonA.length; j += 2) {
            let x1 = polygonA[j];
            let y1 = polygonA[j + 1];
            let x2 = polygonA[(j + 2) % polygonA.length];
            let y2 = polygonA[(j + 3) % polygonA.length];
            let intersections = DrawingUtil.polygonLineIntersections(polygonB, [x1, y1, x2, y2]);
            if (stopAfterFirst && intersections.length > 0) {
                return [intersections[0].x, intersections[0].y];
            }
            intersections.forEach(i => points.push(new PolygonPoint(i.x, i.y, false)));
        }
        let addContainedPoints = (outsidePolygon: number[], insidePolygon: number[]) => {
            for (let j = 0; j < insidePolygon.length; j += 2) {
                if (DrawingUtil.isPointInPolygon([insidePolygon[j], insidePolygon[j + 1]], outsidePolygon)) {
                    points.push(new PolygonPoint(insidePolygon[j], insidePolygon[j + 1], false));
                }
            }
        };
        const threshold = 1;
        addContainedPoints(polygonA, polygonB);
        addContainedPoints(polygonB, polygonA);
        let onlyUnique = (value: PolygonPoint, index: number, self: PolygonPoint[]) =>
            self.findIndex(p => Math.abs(p.x - value.x) < threshold && Math.abs(p.y - value.y) < threshold) === index;
        return ConvexHull.performGrahamScan(PolygonPointUtil.toNumbersArray(points.filter(onlyUnique)));
    }

    public static isWholePolygonInsidePolygon(insidePolygon: number[], outsidePolygon: number[]): boolean {
        for (let i = 0; i < insidePolygon.length; i += 2) {
            let x = insidePolygon[i];
            let y = insidePolygon[i + 1];
            if (!DrawingUtil.isPointInPolygon([x, y], outsidePolygon)) {
                return false;
            }
        }
        return true;
    }

    public static doPolygonsOverlap(polygonA: number[], polygonB: number[]) {
        return DrawingUtil.getPolygonsOverlapPoints(polygonA, polygonB, true).length > 0;
    }

    // extrapolates given segment by given number of millimeters in each direction
    static extrapolateSegment(segment: number[], numberOfMillimeters = 1): number[] {
        let dx = segment[2] - segment[0];
        let dy = segment[3] - segment[1];
        let len = Math.sqrt(dx * dx + dy * dy);

        dx *= numberOfMillimeters / len;
        dy *= numberOfMillimeters / len;

        return [
            segment[0] - dx,
            segment[1] - dy,
            segment[2] + dx,
            segment[3] + dy
        ];
    }

    // extrapolates given segment by a factor in each direction
    // factor=1 does nothing
    // factor=1.5 creates extrapolated segment with length 2x
    static extrapolateSegmentByFactor(segment: number[], factor: number): number[] {
        if (factor < 1) {
            let err = new Error(`extrapolateSegmentByFactor factor=${factor} is below 1`);
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }

        let dx = segment[2] - segment[0];
        let dy = segment[3] - segment[1];

        dx *= factor - 1;
        dy *= factor - 1;

        return [
            segment[0] - dx,
            segment[1] - dy,
            segment[2] + dx,
            segment[3] + dy
        ];
    }

    static lineSegmentParameter(x1: number, y1: number, x2: number, y2: number, px: number, py: number): number {
        let xdiff = x2 - x1, ydiff = y2 - y1;
        return ((px - x1) * xdiff + (py - y1) * ydiff) / (xdiff * xdiff + ydiff * ydiff);
    }

    static getParallelLine(points: number[], distance: number): number[] {
        let x1 = points[0], y1 = points[1], x2 = points[2], y2 = points[3];
        let cutAngle = DrawingUtil.atan2normalized(y2 - y1, x2 - x1);
        x1 += -Math.sin(cutAngle) * distance;
        y1 += Math.cos(cutAngle) * distance;
        x2 += -Math.sin(cutAngle) * distance;
        y2 += Math.cos(cutAngle) * distance;
        return [x1, y1, x2, y2];
    }

    static normalizeAngle(angle: number): number {
        return angle >= 0 ? angle : (angle + Math.PI * 2);
    }

    static atan2normalized(y: number, x: number): number {
        return DrawingUtil.normalizeAngle(Math.atan2(y, x));
    }

    static getPoint<T>(arr: T[], i: number): T {
        return arr[(i + arr.length) % arr.length];
    }

    static getPolygonAngles(polygonPoints: PolygonPoint[], ignoreArcCuts = true): { angles: number[], points: PolygonPoint[] } {
        const angles: number[] = [];
        const points: PolygonPoint[] = [];
        for (let i = 0; i < polygonPoints.length; i++) {
            let point1 = DrawingUtil.getPoint(polygonPoints, i);
            let midPoint = DrawingUtil.getPoint(polygonPoints, i + 1);
            let point2 = DrawingUtil.getPoint(polygonPoints, i + 2);

            if (ignoreArcCuts && [point1, midPoint, point2].every(p => p.isArc)) {
                continue;
            }

            let angle1 = DrawingUtil.atan2normalized(midPoint.y - point1.y, midPoint.x - point1.x);
            let angle2 = DrawingUtil.atan2normalized(point2.y - midPoint.y, point2.x - midPoint.x);

            let angle = Math.abs(Math.PI - DrawingUtil.normalizeAngle(angle2 - angle1));
            angles.push(angle);
            points.push(midPoint);
        }
        return {angles, points};
    }

    static getRectangleAroundPolygon(polygonPoints: number[]): Rectangle {
        let minMaxXY = DrawingUtil.calculateMinMaxFromPolygon(polygonPoints);

        let bottomR = [minMaxXY.maxX, minMaxXY.minY];
        let bottomL = [minMaxXY.minX, minMaxXY.minY];
        let topR = [minMaxXY.maxX, minMaxXY.maxY];
        let topL = [minMaxXY.minX, minMaxXY.maxY];
        let result: Rectangle = new Rectangle();
        result.bottomL = bottomL;
        result.bottomR = bottomR;
        result.topL = topL;
        result.topR = topR;
        return result;
    }

    static getPolygonFromBBox(box: MinMaxXY): number[] {
        return [
            box.minX, box.minY,
            box.maxX, box.minY,
            box.maxX, box.maxY,
            box.minX, box.maxY
        ];
    }

    static isPointAPolygonVertex(point: number[], polygonPoints: number[], useFloatOps = false): boolean {
        for (let i = 0; i < polygonPoints.length - 1; i += 2) {
            if (useFloatOps) {
                if (FloatOps.eq(point[0], polygonPoints[i]) && FloatOps.eq(point[1], polygonPoints[i + 1])) {
                    return true;
                }
            } else {
                if (point[0] === polygonPoints[i] && point[1] === polygonPoints[i + 1]) {
                    return true;
                }
            }
        }
        return false;
    }

    static calculateMinMaxFromPolygon(polygon: number[], flipY = true): MinMaxXY {
        let allPoints: [number, number][] = [];
        for (let i = 0; i < polygon.length; i += 2) {
            if (polygon[i] != undefined && polygon[i + 1] != undefined) {
                allPoints.push([polygon[i], polygon[i + 1]]);
            }
        }
        let allXs: number[] = [];
        let allYs: number[] = [];
        allPoints.forEach(point => {
            allXs.push(point[0]);
            allYs.push(point[1]);
        });

        let maxW = Math.max(...allXs);
        let minW = Math.min(...allXs);
        // in svg Y axis is turned down, so for us max is min...
        let maxH = flipY ? Math.min(...allYs) : Math.max(...allYs);
        let minH = flipY ? Math.max(...allYs) : Math.min(...allYs);

        return new MinMaxXY(minW, maxW, minH, maxH);
    }

    static pathStringFromPolygonPoints(points: number[], isClosed: boolean): string {
        let pathString = 'M' + points[0] + ',' + points[1] + 'L';
        for (let i = 2; i < points.length - 1; ++i) {
            pathString += points[i] + ' ';
        }
        pathString += points[points.length - 1];
        if (isClosed) {
            pathString += 'z';
        }
        return pathString;
    }

    static findFieldsContainingLine(fields: FieldWithPosition[], line: number[]): FieldWithPosition[] {
        let absolutePoints = DrawingUtil.extrapolateSegment(line);
        let matchingFields = fields.filter(f => this.polygonLineIntersectionCount(f.positions, absolutePoints) === 2);
        if (matchingFields.length === 0) {
            let err = new Error("DrawingUtil.findFieldsContainingLine(): Cannot find appropriate field: " + absolutePoints);
            err.name = ErrorNames.GENERAL_ERROR;
            throw err;
        }
        return matchingFields;
    }

    static calculateTotalBoundingBox(windows: WindowData[], windowDesigner?: WindowDesignerInterface): MinMaxXY {
        if (windows.length === 0) {
            return windowDesigner != undefined ? windowDesigner.getEmptyBoundingBox() : new MinMaxXY(0, 0, 0, 0);
        }

        let totalBoundingBox = {
            minX: Infinity,
            maxX: -Infinity,
            minY: Infinity,
            maxY: -Infinity
        };

        for (let window of windows) {
            for (let subWindow of window.subWindows) {
                subWindow.points.forEach((val, idx) => {
                    if ((idx % 2) === 0) { // X
                        totalBoundingBox.minX = Math.min(totalBoundingBox.minX, val);
                        totalBoundingBox.maxX = Math.max(totalBoundingBox.maxX, val);
                    } else {
                        totalBoundingBox.minY = Math.min(totalBoundingBox.minY, val);
                        totalBoundingBox.maxY = Math.max(totalBoundingBox.maxY, val);
                    }
                });
            }
        }
        return totalBoundingBox;
    }

    static calculatePolygonTotalBoundingBox(polygon: number[]): MinMaxXY {
        let totalBoundingBox = {
            minX: Infinity,
            maxX: -Infinity,
            minY: Infinity,
            maxY: -Infinity
        };

        polygon.forEach((val, idx) => {
            if ((idx % 2) === 0) { // X
                totalBoundingBox.minX = Math.min(totalBoundingBox.minX, val);
                totalBoundingBox.maxX = Math.max(totalBoundingBox.maxX, val);
            } else {
                totalBoundingBox.minY = Math.min(totalBoundingBox.minY, val);
                totalBoundingBox.maxY = Math.max(totalBoundingBox.maxY, val);
            }
        });
        return totalBoundingBox;
    }

    static calculateMultiplePolygonsTotalBoundingBox(polygons: number[][]): MinMaxXY {
        let totalBoundingBox = {
            minX: Infinity,
            maxX: -Infinity,
            minY: Infinity,
            maxY: -Infinity
        };

        polygons.forEach(polygon => {
            polygon.forEach((val, idx) => {
                if ((idx % 2) === 0) {
                    totalBoundingBox.minX = Math.min(totalBoundingBox.minX, val);
                    totalBoundingBox.maxX = Math.max(totalBoundingBox.maxX, val);
                } else {
                    totalBoundingBox.minY = Math.min(totalBoundingBox.minY, val);
                    totalBoundingBox.maxY = Math.max(totalBoundingBox.maxY, val);
                }
            });
        });
        return totalBoundingBox;
    }

    static isPointInPolygon(point: number[], polygon: number[]) {

        let p: Point = new Point(point[0], point[1]);
        let totalBoundingBox = {
            minX: Infinity,
            maxX: -Infinity,
            minY: Infinity,
            maxY: -Infinity
        };

        for (let i = 0; i < polygon.length; ++i) {
            let val = polygon[i];
            if ((i % 2) === 0) { // X
                totalBoundingBox.minX = Math.min(totalBoundingBox.minX, val);
                totalBoundingBox.maxX = Math.max(totalBoundingBox.maxX, val);
            } else {
                totalBoundingBox.minY = Math.min(totalBoundingBox.minY, val);
                totalBoundingBox.maxY = Math.max(totalBoundingBox.maxY, val);
            }
        }

        if (p.x < totalBoundingBox.minX || p.x > totalBoundingBox.maxX || p.y < totalBoundingBox.minY || p.y > totalBoundingBox.maxY) {
            return false;
        }
        let intersectionCount = (leftSide: boolean) => {
            let rayLine = [leftSide ? totalBoundingBox.minX - 10 : totalBoundingBox.maxX + 10, p.y, +p.x.toFixed(0), +p.y];
            let extendedPoint = DrawingUtil.extrapolateSegment(rayLine);
            rayLine[0] = +extendedPoint[0].toFixed(0);
            rayLine[1] = +extendedPoint[1].toFixed(0);
            return DrawingUtil.polygonLineIntersectionCount(polygon, rayLine);
        };

        let intersectionCountVertical = (downward: boolean) => {
            let rayLine = [p.x, downward ? totalBoundingBox.maxY + 10 : totalBoundingBox.minY - 10, +p.x, +p.y.toFixed(0)];
            let extendedPoint = DrawingUtil.extrapolateSegment(rayLine);
            rayLine[0] = +extendedPoint[0].toFixed(0);
            rayLine[1] = +extendedPoint[1].toFixed(0);
            return DrawingUtil.polygonLineIntersectionCount(polygon, rayLine);
        };

        return ((intersectionCount(true) % 2 !== 0) || (intersectionCount(false) % 2 !== 0))
            && ((intersectionCountVertical(true) % 2 !== 0) || (intersectionCountVertical(false) % 2 !== 0));
    }

    public static arePointsOnSameSideOfALine(points: number[][], line: number[]): boolean {
        if (points.length < 2) {
            return true;
        }
        let commonSide = DrawingUtil.isPointAboveLine(points[0], line);
        return points.filter((v, i) => i > 0).every(point => commonSide === DrawingUtil.isPointAboveLine(point, line));
    }

    private static crossProduct(origin: number[], p1: number[], p2: number[]): number {
        let v1x = p1[0] - origin[0];
        let v1y = p1[1] - origin[1];
        let v2x = p2[0] - origin[0];
        let v2y = p2[1] - origin[1];
        return v1x * v2y - v1y * v2x;
    }

    public static distance(p1: number[], p2: number[]): number {
        let x = p2[0] - p1[0];
        let y = p2[1] - p1[1];
        return Math.sqrt(x * x + y * y);
    }

    public static segmentsLength(points: number[]): number {
        return points == undefined ? undefined : DrawingUtil.distance([points[0], points[1]], [points[2], points[3]]);
    }

    public static getPathLength(points: PolygonPoint[]): number {
        let sum = 0;
        for (let i = 0; i < points.length - 1; i++) {
            let point1 = DrawingUtil.getPoint(points, i);
            let point2 = DrawingUtil.getPoint(points, i + 1);
            sum += DrawingUtil.distance([point1.x, point1.y], [point2.x, point2.y]);
        }
        return sum;
    }

    public static distanceFromLine(linePoints: number[], point: number[]): number {
        let origin = linePoints.slice(0, 2);
        let p1 = linePoints.slice(2, 4);
        return Math.abs(this.crossProduct(origin, p1, point) / this.distance(origin, p1));
    }

    public static distanceBetweenLineSegments(segmentA: number[], segmentB: number[]): number {
        let intersection = this.lineIntersection(segmentA, segmentB);
        if (intersection.onLine1 && intersection.onLine2) {
            return 0;
        }
        let distances = [
            this.distanceFromLineSegment(segmentA, segmentB.slice(0, 2)),
            this.distanceFromLineSegment(segmentA, segmentB.slice(2, 4)),
            this.distanceFromLineSegment(segmentB, segmentA.slice(0, 2)),
            this.distanceFromLineSegment(segmentB, segmentA.slice(2, 4))
        ];
        return Math.min.apply(Math, distances);
    }

    public static distanceFromLineSegment(segmentPoints: number[], point: number[]): number {
        let A = point[0] - segmentPoints[0];
        let B = point[1] - segmentPoints[1];
        let C = segmentPoints[2] - segmentPoints[0];
        let D = segmentPoints[3] - segmentPoints[1];

        let dot = A * C + B * D;
        let len_sq = C * C + D * D;
        let param = -1;
        if (len_sq !== 0) { // in case of 0 length line
            param = dot / len_sq;
        }

        let xx: number;
        let yy: number;
        if (param < 0) {
            xx = segmentPoints[0];
            yy = segmentPoints[1];
        } else if (param > 1) {
            xx = segmentPoints[2];
            yy = segmentPoints[3];
        } else {
            xx = segmentPoints[0] + param * C;
            yy = segmentPoints[1] + param * D;
        }

        let dx = point[0] - xx;
        let dy = point[1] - yy;
        return Math.sqrt(dx * dx + dy * dy);
    }

    static isLineVertical(line: number[]) {
        return line[0] === line[2];
    }

    static isLineHorizontal(line: number[]) {
        return line[1] === line[3];
    }

    public static getPolygonCentroid(polygon: number[]): number[] {
        let centroid = [0, 0];
        let signedArea = 0.0;
        let x0 = 0.0; // Current vertex X
        let y0 = 0.0; // Current vertex Y
        let x1 = 0.0; // Next vertex X
        let y1 = 0.0; // Next vertex Y
        let a = 0.0;  // Partial signed area
        for (let i = 0; i < polygon.length; i += 2) {
            x0 = polygon[i];
            y0 = polygon[i + 1];
            x1 = polygon[(i + 2) % polygon.length];
            y1 = polygon[(i + 3) % polygon.length];
            a = x0 * y1 - x1 * y0;
            signedArea += a;
            centroid[0] += (x0 + x1) * a;
            centroid[1] += (y0 + y1) * a;
        }
        signedArea *= 0.5;
        centroid[0] /= (6.0 * signedArea);
        centroid[1] /= (6.0 * signedArea);
        return centroid;
    }

    public static isPointAboveLine(point: number[], line: number[]): boolean {
        return (line[2] - line[0]) * (point[1] - line[1]) - (line[3] - line[1]) * (point[0] - line[0]) < 0;
    }

    public static isPolygonRectangular(polygon: number[]): boolean {
        for (let i = 0; i < polygon.length; i += 2) {
            let x1 = DrawingUtil.getPoint(polygon, i - 2),
                y1 = DrawingUtil.getPoint(polygon, i - 1),
                x2 = DrawingUtil.getPoint(polygon, i),
                y2 = DrawingUtil.getPoint(polygon, i + 1);
            if (x1 !== x2 && y1 !== y2) {
                return false;
            }
        }
        return true;
    }

    public static getIntersectionCloserToOriginalPoint(point: number[],
                                                       intersections: IntersectionResult[]): number[] {
        return intersections.map(i => {
            return {point: [i.x, i.y], distance: DrawingUtil.distance(point, [i.x, i.y])};
        }).sort((a, b) => a.distance < b.distance ? -1 : 1)[0].point;
    }

    public static getXCoordinates(array: number[]): number[] {
        return DrawingUtil.getCoordinates(array, 0);
    }

    public static getYCoordinates(array: number[]): number[] {
        return DrawingUtil.getCoordinates(array, 1);
    }

    public static getCoordinates(array: number[], index: number): number[] {
        return array.filter((val, idx) => (idx % 2) === index);
    }

    public static getAngleBetweenTwoLines(lineA: number[], lineB: number[]): number {
        let intersection = this.lineIntersection(lineA, lineB);
        if (!intersection.intersects) {
            return 0;
        }
        let point1 = new Point(lineA[0], lineA[1]);
        let midPoint = new Point(intersection.x, intersection.y);
        let point2 = new Point(lineB[0], lineB[1]);

        let angle1 = DrawingUtil.atan2normalized(midPoint.y - point1.y, midPoint.x - point1.x);
        let angle2 = DrawingUtil.atan2normalized(point2.y - midPoint.y, point2.x - midPoint.x);

        return Math.abs(Math.PI - DrawingUtil.normalizeAngle(angle2 - angle1));
    }

    public static getAllPolygonEdges(polygon: number[]): number[][] {
        return polygon.map((v, i, a) => [DrawingUtil.getPoint(a, i),
            DrawingUtil.getPoint(a, i + 1),
            DrawingUtil.getPoint(a, i + 2),
            DrawingUtil.getPoint(a, i + 3)]).filter((v, i) => i % 2 === 0);
    }

    private static inRange(value: number, a: number, b: number): boolean {
        return Math.min(a, b) <= value && value <= Math.max(a, b);
    }

    private static getEdgeByPosition(position: number[], polygon: PolygonPoint[]): PolygonPoint[] {
        for (let i = 0; i < polygon.length; i++) {
            let vertexA = DrawingUtil.getPoint(polygon, i);
            let vertexB = DrawingUtil.getPoint(polygon, i + 1);
            if (DrawingUtil.inRange(position[0], vertexA.x, vertexB.x) &&
                DrawingUtil.inRange(position[1], vertexA.y, vertexB.y)) {
                return [vertexA, vertexB];
            }
        }
        return undefined;
    }

    // arcPointIndex - in case we add shapes with more than one arc
    public static getArcPoints(polygon: PolygonPoint[], arcPointIndex: number) {
        if (!polygon[arcPointIndex].isArc) {
            let err = new Error("DrawingUtil.getArcPoints - Invalid arc index: " + arcPointIndex);
            err.name = ErrorNames.ILLEGAL_OPERATION_INVOKED;
            throw err;
        }

        let arcPoints = [];
        let index = arcPointIndex - 1;
        let nextPoint = DrawingUtil.getPoint(polygon, index);
        while (nextPoint.isArc) {
            arcPoints.push(nextPoint);
            index--;
            nextPoint = DrawingUtil.getPoint(polygon, index);
        }
        arcPoints.reverse();
        index = arcPointIndex;
        nextPoint = DrawingUtil.getPoint(polygon, index);
        while (nextPoint.isArc) {
            arcPoints.push(nextPoint);
            index++;
            nextPoint = DrawingUtil.getPoint(polygon, index);
        }
        return arcPoints;
    }

    public static isPointOnArc(point: number[], polygon: PolygonPoint[]): boolean {
        let edge = DrawingUtil.getEdgeByPosition(point, polygon);
        return edge !== undefined && edge.every(e => e.isArc);
    }

    public static getTopPolygonEdge(polygon: number[]): number[] {
        let box = DrawingUtil.calculatePolygonTotalBoundingBox(polygon);
        let edges = DrawingUtil.getAllPolygonEdges(polygon)
            .filter(e => DrawingUtil.isLineHorizontal(e))
            .filter(e => e[1] === box.minY);
        return edges.length === 1 ? edges[0] : null;
    }

    public static flipXCoordinates(array: number[]): number[] {
        return array.map((val, idx) => ((idx % 2 === 0) ? -val : val));
    }

    public static shrinkBoxBy(box: MinMaxXY, distance: number) {
        return new MinMaxXY(
            box.minX + distance,
            box.maxX - distance,
            box.minY + distance,
            box.maxY - distance
        );
    }

    public static shiftArrayInXAxis(array: number[], shiftTransformation: number): number[] {
        return DrawingUtil.shiftArrayInOneAxis(array, shiftTransformation, false);
    }

    public static shiftArrayInYAxis(array: number[], shiftTransformation: number): number[] {
        return DrawingUtil.shiftArrayInOneAxis(array, shiftTransformation, true);
    }

    private static shiftArrayInOneAxis(array: number[], shiftTransformation: number, vertical: boolean): number[] {
        return array.map((val, idx) => val + ((idx % 2 === (vertical ? 1 : 0)) ? shiftTransformation : 0));
    }
}

export class Point {
    x: number;
    y: number;

    constructor(x: number, y: number) {
        this.x = x;
        this.y = y;
    }
}

export class ConvexHull {
    // returns number < 0 if path p0p1p2 is a left-turn
    // returns number = 0 if path p0p1p2 is a straight line
    // returns number > 0 if path p0p1p2 is a right-turn
    private static calcThreePointsTurn(p0: PolygonPoint, p1: PolygonPoint, p2: PolygonPoint): number {
        let v1x = p1.x - p0.x;
        let v1y = p1.y - p0.y;
        let v2x = p2.x - p0.x;
        let v2y = p2.y - p0.y;

        return v2x * v1y - v2y * v1x;
    }

    // refPoint is a point with smallest y, then smallest x
    private static sortPointsAnticlockwiseRelativeToPoint(refPoint: PolygonPoint, points: PolygonPoint[]): PolygonPoint[] {
        return points.sort((a, b) => {
            if (a.x === refPoint.x && a.y === refPoint.y) {
                return -1; // ensure refPoint is first
            }
            if (b.x === refPoint.x && b.y === refPoint.y) {
                return 1; // ensure refPoint is first
            }

            let turn = ConvexHull.calcThreePointsTurn(refPoint, a, b);

            if (turn !== 0) {
                return turn;
            } else if (a.y === b.y) {
                return b.x - a.x; // if a.y == b.y then the right-most one should be first
            } else {
                return b.y - a.y; // if points lay down on the line going through refPoint, the furthest one should be first;
            }
        });
    }

    // removes collinear points relative to starting point (points[0])
    // leaves in the points being furthest away from the starting point
    // input has to be sorted first by sortPointsAnticlockwiseRelativeToPoint
    private static removeCollinear(points: PolygonPoint[]): PolygonPoint[] {
        let zipArg1 = _.clone(points);
        let refPoint = zipArg1.shift();
        let zipArg2 = _.clone(zipArg1);
        let second = zipArg2.shift();

        zipArg1.pop();

        let zipped: PolygonPoint[][] = _.zip(zipArg1, zipArg2);
        let filtered = zipped.filter((v) => this.calcThreePointsTurn(refPoint, v[0], v[1]) !== 0);
        let noncollinearPoints = filtered.map((v) => v[1]);

        noncollinearPoints.unshift(second);
        noncollinearPoints.unshift(refPoint);

        return noncollinearPoints;
    }

    // starting point is a point with smallest y, then smallest x
    private static findStartingPoint(points: PolygonPoint[]): PolygonPoint {
        let starting_point = points[0];

        points.forEach(p => {
            if (p.y < starting_point.y ||
                (p.y === starting_point.y && p.x < starting_point.x)) {
                starting_point = p;
            }
        });

        return starting_point;
    }

    static performGrahamScan(points: number[]): number[] {
        return PolygonPointUtil.toNumbersArray(this.performGrahamScanFull(PolygonPointUtil.toPolygonPoints(points)));
    }

    static performGrahamScanFull(points: PolygonPoint[]): PolygonPoint[] {
        let unique_points = _.uniq(points, false, (p) => p.x + "_" + p.y);

        if (unique_points.length < 3) {
            return [];
        }

        let starting_point = this.findStartingPoint(unique_points);
        let sorted = this.sortPointsAnticlockwiseRelativeToPoint(starting_point, unique_points);
        let noncollinear = this.removeCollinear(sorted);

        if (noncollinear.length < 3) {
            return [];
        }

        let convex: PolygonPoint[] = [];

        convex.push(noncollinear.shift());
        convex.push(noncollinear.shift());
        convex.push(noncollinear.shift());

        noncollinear.forEach(p => {
            while (this.calcThreePointsTurn(convex[convex.length - 2], convex[convex.length - 1], p) >= 0) {
                convex.pop();
            }
            convex.push(p);
        });

        return _.flatten(convex);
    }
}
