import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {OtherFillingField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {OtherFillingFieldUsage} from "../../catalog-field-usage";
import {OtherFilling} from "../otherFilling";

@Component({
    selector: 'app-other-filling-form',
    templateUrl: './other-filling-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OtherFillingFormComponent {

    public readonly INPUT_NUMBER_DEFAULT_MAX_VALUE = 999999;

    @Input() item: OtherFilling;

    @Input()
    validationErrors: any;

    @Input()
    availableInsideColors: SelectItem[];
    @Input()
    availableOutsideColors: SelectItem[];
    @Input()
    availableCoreColors: SelectItem[];
    @Input()
    editPermits: FieldLimitation[] = [];

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    fieldUsage: OtherFillingFieldUsage;
    OtherFillingField = OtherFillingField;

    constructor() {
        this.fieldUsage = new OtherFillingFieldUsage(this);
    }

    onInsideColorChange(event) {
        this.item.insideColors = event;
    }

    onOutsideColorChange(event) {
        this.item.outsideColors = event;
    }

}
