<p-dataTable #dataTable [value]="tableData.productionOrders" [rows]="tableData.rowsPerPage"
             [rowsPerPageOptions]="limitedRowsPerPageOptions" [paginator]="true" selectionMode="single"
             [(selection)]="tableData.selected" [totalRecords]="tableData.totalRecords" [responsive]="true" [lazy]="true"
             (onLazyLoad)="emitLazyLoad($event)" dataKey="id" [rowTrackBy]="rowTrackBy" [emptyMessage]="'GENERAL.NO_RECORDS_FOUND' | translate">
    <p-column *ngIf="canEdit" [style]="{'vertical-align':'middle'}">
        <ng-template pTemplate="header">
            <app-tristate-checkbox [ngModel]="tableData.allSelectedState"
                                   (ngModelChange)="selectAllChange($event)"></app-tristate-checkbox>
        </ng-template>
        <ng-template let-position="rowData" pTemplate="body">
            <span class="flex-row">
                <app-checkbox [ngModel]="isSelectedItem(position)"
                              (ngModelChange)="selectItem(position)"></app-checkbox>
            </span>
        </ng-template>
    </p-column>

    <!-- ACTIONS -->
    <p-column *ngIf="canEdit" header="{{ 'DELIVERY_LIST.PALETTE_LIST.ACTIONS.HEADER' | translate }}" [sortable]="false">
        <ng-template let-productionOrder="rowData" pTemplate="body">
            <div class="table-row-action-buttons">
                <app-simple-button [buttonId]="'editDescription_' + productionOrder.id" icon="note_add" type="main-action" [size]="36"
                                   label="{{'DELIVERY_LIST.PALETTE_LIST.ACTIONS.TOOLTIPS.EDIT_DELIVERY_DESCRIPTION' | translate}}" [inlineLabel]="false"
                                   (onClick)="showOrderDescriptionDialog(productionOrder)"></app-simple-button>
                <app-simple-button [buttonId]="'goToOrder' + productionOrder.id" icon="layers" type="filter" [size]="36"
                                   label="{{'DELIVERY_LIST.PALETTE_LIST.ACTIONS.TOOLTIPS.SHOW_PRODUCTION_ORDER' | translate}}" [inlineLabel]="false"
                                   (onClick)="goToOrder(productionOrder.id)"></app-simple-button>
            </div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['offerNumbers']" field="offerNumbers"
              [header]="columnByField['offerNumbers'].header | translate"
              [sortable]="false" [filter]="isFilterable(columnByField['offerNumbers'].filterable)">
        <ng-template let-productionOrder="rowData" pTemplate="body">
            <span *ngFor="let offerNumber of productionOrder.offerNumbers">
             <app-offer-number [offerNumber]="offerNumber" [isOffer]="true"></app-offer-number>
            </span>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['orderNumber']" field="orderNumber"
              [header]="columnByField['orderNumber'].header | translate"
              [sortable]="columnByField['orderNumber'].sortable" [filter]="isFilterable(columnByField['orderNumber'].filterable)">
        <ng-template let-productionOrder="rowData" pTemplate="body">
            <div>{{ productionOrder.orderNumber }}</div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['creationDate']" field="creationDate"
              [header]="columnByField['creationDate'].header | translate"
              [sortable]="columnByField['creationDate'].sortable" [filter]="isFilterable(columnByField['creationDate'].filterable)"
              styleClass="datatable-date-range-column">
        <ng-template pTemplate="filter">
            <div app-date-range-filter [class.p-column-filter-invisible]="!isFilterable(columnByField['creationDate'].filterable)"></div>
        </ng-template>
        <ng-template let-productionOrder="rowData" pTemplate="body">
            <div>{{ productionOrder.creationDate | momentCurrentLang | amDateFormat: 'L' }}</div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['supplierCompanyName']" field="supplierCompanyName"
              [header]="columnByField['supplierCompanyName'].header | translate"
              [sortable]="columnByField['supplierCompanyName'].sortable" [filter]="isFilterable(columnByField['supplierCompanyName'].filterable)">
        <ng-template let-productionOrder="rowData" pTemplate="body">
            <div>{{ productionOrder.supplierCompanyName }}</div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['supplierName']" field="supplierName"
              [header]="columnByField['supplierName'].header | translate"
              [sortable]="columnByField['supplierName'].sortable" [filter]="isFilterable(columnByField['supplierName'].filterable)">
        <ng-template let-productionOrder="rowData" pTemplate="body">
            <div>{{ productionOrder.supplierName[translate.currentLang] }}</div>
        </ng-template>
    </p-column>

    <p-column *ngIf="columnByField['clientManagerName']" field="clientManagerName"
              [header]="columnByField['clientManagerName'].header | translate"
              [sortable]="columnByField['clientManagerName'].sortable" [filter]="isFilterable(columnByField['clientManagerName'].filterable)">
        <ng-template let-productionOrder="rowData" pTemplate="body">
            <div>{{ productionOrder.clientManagerFirstName + ' ' + productionOrder.clientManagerLastName}}</div>
        </ng-template>
    </p-column>

    <ng-template pTemplate="paginatorLeft">
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: tableData.fromRecord, to: tableData.toRecord, total: tableData.totalRecords} }}</span>
    </ng-template>
</p-dataTable>

<p-dialog *ngIf="showDescriptionDialog" [resizable]="false" [visible]="true" (onHide)="closeDialog()" [modal]="true"
          [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'DELIVERY_LIST.PALETTE_LIST.DESCRIPTION_DIALOG_HEADER' | translate }}
        </div>
    </p-header>
    <div class="scrolled-dialog scrolled-dialog-600 new-form-600">
        <div class="new-form-row description-textarea">
            <app-text-area inputId="description"
                           [(validationMessageKey)]="validationErrors['deliveryDescription']" [maxlength]="1024"
                           [(ngModel)]="editedDescription"></app-text-area>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="addCommentButton" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action"
                               [size]="40" (onClick)="editDescription()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="closeDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
