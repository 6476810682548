<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.APPLICATION_RESOURCE.TITLE' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container"></div>
        </div>
    </div>
    <div class="datatable-container">
        <p-table #dt [value]="resources" [rows]="MAX_ROWS_WITHOUT_PAGINATION" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="false" [filterDelay]="400"
                 [totalRecords]="totalRecords" selectionMode="single" dataKey="name" [responsive]="true"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder" [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th [ngClass]="columnHeaderClasses('name')">
                        <span>{{ 'APPLICATION_RESOURCE.FORM.NAME' | translate }}</span>
                        <p-sortIcon field="name"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-resource let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex" [pSelectableRow]="resource" [pSelectableRowIndex]="rowIndex"
                    (dblclick)="doOnRowSelect({ data: resource })" [app-table-keyboard-navigation]="resource"
                    (app-table-keyboard-navigation-enter)="doOnRowSelect({ data: resource })">
                    <td>
                        {{ ('APPLICATION_RESOURCE.TYPE.' + resource.name) | translate }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<app-wizard-dialog *ngIf="displayDialog" [header]="getEditDialogHeader() | translate" (onCancel)="cancel()"
                   (onComplete)="submit()">
    <app-wizard-step label="{{ 'APPLICATION_RESOURCE.STEPS.GENERAL' | translate }}">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-file-upload inputId="resource-content" label="{{ 'APPLICATION_RESOURCE.FORM.FILE' | translate }}"
                                 [(validationMessageKey)]="validationErrors['file']" [maxSize]="15000000"
                                 [maxWidth]="10000" [maxHeight]="10000"
                                 [image]="true" [widthToHeightRatio]="getResourceAspectRatio()"
                                 addButtonLabel="APPLICATION_RESOURCE.FORM.SELECT_FILE_UPLOAD"
                                 changeButtonLabel="APPLICATION_RESOURCE.FORM.CHANGE_FILE_UPLOAD" [ngModel]="file"
                                 (ngModelChange)="onFileChange($event)"></app-file-upload>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
