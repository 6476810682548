import {ChangeDetectorRef, Directive, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {isObservable, Observable, of} from 'rxjs';
import {CascadeValueCalculator} from '../../../common/cascade-value-calculator';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {PaginatorRowsPerPageOptions} from '../../../common/crud-common/paginatorRowsPerPageOptions';
import {DataTableColumn} from '../../../common/service/data.table.column';
import {ValidationErrors} from '../../../common/validation-errors';
import {HasSaveTable} from '../../subsystem/base-profit-margin.component';
import {AbstractSellDealerDiscount} from './abstract-sell-dealer-discount';
import {AbstractSellDealerDiscountService} from './abstract-sell-dealer-discount.service';

@Directive()
export abstract class AbstractSellDealerDiscountComponent<Item extends AbstractSellDealerDiscount, Service extends AbstractSellDealerDiscountService<Item>>
    implements OnInit, OnChanges, HasSaveTable {

    @Input()
    id: number;

    @Input()
    groupId: number;

    @Input()
    canEdit: boolean;

    @Input()
    globalMarginUpdateInputId: string;

    @Input()
    globalMarginUpdateButtonId: string;

    @Input()
    validationErrors: ValidationErrors;

    globalProfitMargin: string;

    extraColumns: DataTableColumn[] = [];

    itemList: Item[] = [];
    selectedItem: Item;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    readonly rowsPerPageOptions: number[] = PaginatorRowsPerPageOptions.values;
    chosenRowsPerPage = PaginatorRowsPerPageOptions.defaultValue;

    @ViewChild(DataTable, {static: true})
    dataTable: DataTable;

    editedRow: any;

    protected constructor(protected sellDealerDiscountService: Service,
                          private errorHandler: CommonErrorHandler,
                          public changeDetector: ChangeDetectorRef) {
    }

    abstract getExtraColumns(): DataTableColumn[];

    formatExtraColumn(column: DataTableColumn, item: Item): Observable<any> {
        let value: any = item;
        const path = column.field.split('.');
        while (path.length && (value = value[path.shift()])) {
        }
        return of(value);
    }

    abstract isGroupValueVisible(): boolean;

    ngOnInit(): void {
        this.extraColumns = this.getExtraColumns();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('id' in changes || 'groupId' in changes) {
            const idChange = changes['id'];
            const groupIdChange = changes['groupId'];
            this.loadDiscounts(idChange != undefined ? idChange.currentValue : this.id,
                groupIdChange != undefined ? groupIdChange.currentValue : this.groupId);
        }
    }

    isAsyncFilterValueList(list: SelectItem[] | Observable<SelectItem[]>): list is Observable<SelectItem[]> {
        return isObservable(list);
    }

    private loadDiscounts(id: number, groupId: number): void {
        this.sellDealerDiscountService.getSellDealerDiscounts(id, groupId).subscribe({
            next: discounts => {
                this.itemList = discounts.data;
                this.totalRecords = discounts.totalRecords;
                this.fromRecord = Math.min(1, this.totalRecords);
                this.toRecord = Math.min(this.chosenRowsPerPage, this.totalRecords);
                this.changeDetector.markForCheck();
            },
            error: error => this.errorHandler.handle(error)
        });
    }

    saveTable(id: number): Observable<void> {
        const itemsToSave = this.itemList.filter(item => this.isSaveNeeded(item));
        if (itemsToSave.length === 0) {
            return of(undefined);
        }
        return this.sellDealerDiscountService.saveSellDealerDiscounts(id != undefined ? id : this.id, itemsToSave);
    }

    applyGlobalProfitMargin(event: Event): void {
        event.stopPropagation();
        if (this.isEmpty(this.globalProfitMargin)) {
            this.globalProfitMargin = "";
        }
        if (this.validateValue(this.globalProfitMargin)) {
            const cascade = this.globalProfitMargin && this.globalProfitMargin.indexOf(';') >= 0;
            for (let discount of this.itemList) {
                discount.value = this.globalProfitMargin;
                discount.calculatedDiscountValue = CascadeValueCalculator.calculateDiscountValue(this.globalProfitMargin);
                discount.cascade = cascade;
            }
            this.globalProfitMargin = undefined;
        } else {
            this.validationErrors[this.globalMarginUpdateInputId] = 'error.abstractSellDealerDiscountDto.value.pattern_not_matched';
        }
        this.changeDetector.markForCheck();
    }

    private validateValue(value: string): boolean {
        return /^(?: *?-?\d+[,.]?\d? *; *)*(?: *?-?\d+[,.]?\d? *)?$/.test(value) || this.isEmpty(value);
    }

    private isEmpty(value: string): boolean {
        return value == null || value.trim() === "";
    }

    private isSaveNeeded(sellDealerDiscount: AbstractSellDealerDiscount): boolean {
        return sellDealerDiscount.id != undefined || (sellDealerDiscount.value != undefined && sellDealerDiscount.value.length > 0);
    }

    handlePageChange(event: { first: number, rows: number }): void {
        this.fromRecord = Math.min(event.first + 1, this.totalRecords);
        this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
        this.changeDetector.markForCheck();
    }

    initEdit(event): void {
        if (this.editedRow != undefined && this.editedRow !== event.data) {
            this.commitEdit({data: this.editedRow});
        }
        this.editedRow = event.data;
        event.data.editValue = event.data.value;
    }

    commitEdit(event): void {
        this.editedRow = undefined;
        if (this.validateValue(event.data.editValue)) {
            event.data.value = event.data.editValue;
            if (event.data.value != undefined && event.data.value.length > 0) {
                event.data.calculatedDiscountValue = CascadeValueCalculator.calculateDiscountValue(event.data.value);
                event.data.cascade = event.data.value.indexOf(';') >= 0;
            } else {
                event.data.calculatedDiscountValue = undefined;
                event.data.cascade = false;
            }
        }
    }
}
