import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class SubsystemWebshopTheme {
    primaryColor: string;
    secondaryColor: string;

    static fromJSON(jsonObject: any): SubsystemWebshopTheme {
        return Object.assign<SubsystemWebshopTheme, SubsystemWebshopTheme>(new SubsystemWebshopTheme(), jsonObject);
    }
}

export enum WebshopView {
    MAIN = 'MAIN',
    RECOMMENDED_CONFIGURATIONS = 'RECOMMENDED_CONFIGURATIONS',
    PRODUCT_CONFIGURATOR = 'PRODUCT_CONFIGURATOR',
    ADD_TO_CART = 'ADD_TO_CART',
    CART = 'CART',
    CHECKOUT = 'CHECKOUT'
}

@JsonDeserializable
export class WebshopSmartsuppConfig {
    key: string;
    offsetX: number;
    offsetY: number;

    static fromJSON(jsonObject: any): WebshopSmartsuppConfig {
        return Object.assign<WebshopSmartsuppConfig, WebshopSmartsuppConfig>(new WebshopSmartsuppConfig(), jsonObject);
    }
}

@JsonDeserializable
export class SubsystemWebshopInfo {
    theme: SubsystemWebshopTheme;
    footer: File;
    phoneNumber: string;
    phoneNumber2: string;
    webshopCompanyName: string;
    companyWebsiteUrl: string;
    email: string;
    street: string;
    city: string;
    country: string;
    zip: string;
    cardTitle: string;
    callpageId: string;
    callpageVisibleViews: WebshopView[];
    smartsuppConfig: WebshopSmartsuppConfig;
    icon: File;
    logo: File;
    logoMobile: File;
    gtmUserId: string;

    constructor() {
        this.theme = new SubsystemWebshopTheme();
        this.footer = undefined;
        this.phoneNumber = undefined;
        this.phoneNumber2 = undefined;
        this.webshopCompanyName = undefined;
        this.companyWebsiteUrl = undefined;
        this.email = undefined;
        this.street = undefined;
        this.city = undefined;
        this.country = undefined;
        this.zip = undefined;
        this.cardTitle = undefined;
        this.callpageId = undefined;
        this.callpageVisibleViews = [];
        this.smartsuppConfig = new WebshopSmartsuppConfig();
        this.icon = undefined;
        this.logo = undefined;
        this.logoMobile = undefined;
        this.gtmUserId = undefined;
    }

    static fromJSON(jsonObject: any): SubsystemWebshopInfo {
        const subsystemWebshopInfo = new SubsystemWebshopInfo();
        if (jsonObject.theme != undefined) {
            subsystemWebshopInfo.theme = SubsystemWebshopTheme.fromJSON(jsonObject.theme);
        }
        subsystemWebshopInfo.phoneNumber = jsonObject.phoneNumber;
        subsystemWebshopInfo.phoneNumber2 = jsonObject.phoneNumber2;
        subsystemWebshopInfo.webshopCompanyName = jsonObject.webshopCompanyName;
        subsystemWebshopInfo.companyWebsiteUrl = jsonObject.companyWebsiteUrl;
        subsystemWebshopInfo.email = jsonObject.email;
        subsystemWebshopInfo.street = jsonObject.street;
        subsystemWebshopInfo.city = jsonObject.city;
        subsystemWebshopInfo.country = jsonObject.country;
        subsystemWebshopInfo.zip = jsonObject.zip;
        subsystemWebshopInfo.cardTitle = jsonObject.cardTitle;
        subsystemWebshopInfo.callpageId = jsonObject.callpageId;
        if (jsonObject.callpageVisibleViews != undefined) {
            subsystemWebshopInfo.callpageVisibleViews = [...jsonObject.callpageVisibleViews];
        }
        if (jsonObject.smartsuppConfig != undefined) {
            subsystemWebshopInfo.smartsuppConfig = WebshopSmartsuppConfig.fromJSON(jsonObject.smartsuppConfig);
        }
        subsystemWebshopInfo.gtmUserId = jsonObject.gtmUserId;
        return subsystemWebshopInfo;
    }
}
