<div>
    <!-- block UI -->
    <div *ngIf="visibleState == 'visible'" class="shipping-simulation-block-ui" (click)="hide()"></div>

    <!-- sidebar contents -->
    <div class="shipping-simulation-sidebar" [@sidebarVisibilityState]="visibleState" (@sidebarVisibilityState.done)="sidebarAnimationFinished()">
        <div *ngIf="visible" class="shipping-simulation-sidebar-panel">
            <div class="shipping-simulation-sidebar-header-and-offers-container">

                <!-- header -->
                <div class="shipping-simulation-sidebar-header">
                    <div class="shipping-simulation-sidebar-header-title">
                        {{ 'SHIPPING_SIMULATION.SIDEBAR.HEADER' | translate }} {{ positionCount ? '(' + positionCount + ')' : ''}}
                    </div>
                    <div class="shipping-simulation-sidebar-header-secondary-action">
                        <app-simple-button buttonId="subsystem-address-select-delivery"
                                           label="{{ 'SHIPPING_SIMULATION.ACTIONS.TOOLTIPS.CHANGE_ADDRESS' | translate }}"
                                           [inlineLabel]="false" icon="bookmarks"
                                           [disabled]="shippingCalculationInProgress || offers.length === 0"
                                           (onClick)="showDeliveryAddressSelectionDialog()"></app-simple-button>
                        <app-simple-button [disabled]="shippingCalculationInProgress"
                                           [label]="'SHIPPING_SIMULATION.SIDEBAR.CLEAR' | translate"
                                           type="cancel" [size]="24" (onClick)="removeAll()"></app-simple-button>
                    </div>
                </div>

                <!-- offers -->
                <div class="shipping-simulation-sidebar-panel-offers-container">
                    <div *ngFor="let offer of offers" class="shipping-simulation-sidebar-panel-offer">

                        <!-- offer header -->
                        <div class="shipping-simulation-sidebar-panel-offer-header">
                            <div *ngIf="!offer.isOrder" class="shipping-simulation-sidebar-panel-offer-header-title">
                                {{ 'SHIPPING_SIMULATION.SIDEBAR.OFFER' | translate }} {{ offer.offerNumber }}
                            </div>
                            <div *ngIf="offer.isOrder" class="shipping-simulation-sidebar-panel-offer-header-title">
                                {{ 'SHIPPING_SIMULATION.SIDEBAR.ORDER' | translate }} {{ offer.offerNumber }}
                            </div>
                            <div class="shipping-simulation-sidebar-panel-offer-header-action">
                                <app-simple-button icon="delete"
                                                   [disabled]="shippingCalculationInProgress"
                                                   [label]="'SHIPPING_SIMULATION.SIDEBAR.DELETE' | translate"
                                                   type="cancel" [size]="24" (onClick)="removeOffer(offer)"></app-simple-button>
                            </div>
                        </div>

                        <div class="shipping-simulation-sidebar-panel-offer-separator"></div>

                        <!-- offer positions -->
                        <div class="shipping-simulation-sidebar-panel-position-panel">
                            <div *ngFor="let position of offer.getPositionDisplayList()" class="shipping-simulation-sidebar-panel-position">
                                <div class="shipping-simulation-sidebar-panel-position-name">{{ position.name[translateService.currentLang] }}</div>
                                <div class="shipping-simulation-sidebar-panel-position-quantity">{{ position.quantity }} {{ 'ADDONS.QUANTITY_TYPE.ABBREVIATION.PIECE' | translate }}</div>
                                <div class="shipping-simulation-sidebar-panel-position-action">
                                    <app-simple-button icon="delete"
                                                   [disabled]="shippingCalculationInProgress"
                                                   [label]="'SHIPPING_SIMULATION.SIDEBAR.DELETE' | translate"
                                                   type="cancel" [size]="24" (onClick)="removePosition(position)"></app-simple-button>
                                </div>

                            </div>
                        </div>

                        <!-- offer footer -->
                        <div *ngIf="offer.getNumberOfHiddenPositions() > 0" class="shipping-simulation-sidebar-panel-offer-footer">
                            <div *ngIf="offer.displayShortList" class="shipping-simulation-sidebar-panel-offer-footer-action" (click)="switchPositionDisplay(offer)">
                                <span>{{ offer.getNumberOfHiddenPositions() }} {{ 'SHIPPING_SIMULATION.SIDEBAR.MORE' | translate }}</span>
                                <span class="shipping-simulation-sidebar-panel-offer-label-icon material-icons">expand_more</span>
                            </div>

                            <div *ngIf="!offer.displayShortList" class="shipping-simulation-sidebar-panel-offer-footer-action" (click)="switchPositionDisplay(offer)">
                                <span>{{ 'SHIPPING_SIMULATION.SIDEBAR.LESS' | translate }}</span>
                                <span class="shipping-simulation-sidebar-panel-offer-label-icon material-icons">expand_less</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- sidebar footer -->
            <div class="shipping-simulation-sidebar-footer">
                <div class="shipping-simulation-sidebar-panel-offer-separator"></div>

                <div>
                    {{ 'SHIPPING_SIMULATION.SIDEBAR.CUSTOMS_NOTE' | translate }}
                </div>

                <div class="shipping-simulation-sidebar-summary">
                    <div class="shipping-simulation-sidebar-summary-price">
                        <div>{{ customDeliveryAddress.address.street }}</div>
                        <div>{{ customDeliveryAddress.address.zip }} {{ customDeliveryAddress.address.city }} {{ 'COUNTRIES.' + customDeliveryAddress.address.country | translate }}</div>
                    </div>
                    <div class="shipping-simulation-sidebar-summary-quantity blue-text-button">
                        <app-simple-button buttonId="subsystem-address-select-delivery"
                                           label="{{ 'OFFER.CREATE_OFFER.FORM.CHANGE_DELIVERY_ADDRESS' | translate }}"
                                           (onClick)="showDeliveryAddressSelectionDialog()"></app-simple-button>
                    </div>
                </div>

                <div *ngIf="permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})">
                    <app-checkbox inputId="useBigTrucks"
                                  label="{{ 'SHIPPING_SIMULATION.SIDEBAR.USE_BIG_TRUCKS' | translate }}"
                                  [ngModel]="useBigTrucks"
                                  (ngModelChange)="handleUseBigTrucksChange($event)"></app-checkbox>
                </div>

                <div *ngIf="truckSizeError" class="shipping-simulation-estimated-transport-cost-note">
                    {{ truckSizeError | translate }}
                </div>

                <div class="shipping-simulation-estimated-transport-cost-note">
                    {{ 'SHIPPING_SIMULATION.SIDEBAR.ESTIMATED_TRANSPORT_COST_NOTE' | translate }}
                </div>

                <div *ngIf="!shippingCalculationInProgress" class="shipping-simulation-sidebar-summary">
                    <div class="shipping-simulation-sidebar-summary-price">
                        <div class="shipping-simulation-sidebar-summary-price-amount">
                            {{ formatSimulationPrice() }}
                        </div>
                        <div class="shipping-simulation-sidebar-summary-price-label">{{ 'SHIPPING_SIMULATION.SIDEBAR.ESTIMATED_PRICE' | translate }}</div>
                    </div>

                    <div class="shipping-simulation-sidebar-summary-quantity">
                        <div class="shipping-simulation-sidebar-summary-quantity-amount">
                            {{ shippingSimulationService.shippingSimulation.calculationResult ? shippingSimulationService.shippingSimulation.slots : '...' }}
                        </div>
                        <div class="shipping-simulation-sidebar-summary-quantity-label">{{ 'SHIPPING_SIMULATION.SIDEBAR.ESTIMATED_QUANTITY' | translate }}</div>
                    </div>
                </div>

                <div *ngIf="shippingCalculationInProgress" class="shipping-simulation-sidebar-summary-status">
                    {{ 'SHIPPING_SIMULATION.SIDEBAR.RECALCULATE_IN_PROGRESS' | translate }}
                </div>

                <!-- navigate to simulation button -->
                <div class="shipping-simulation-sidebar-summary-main-action">
                    <app-simple-button [disabled]="!positionCount || shippingCalculationInProgress"
                                       label="{{ 'SHIPPING_SIMULATION.SIDEBAR.CALCULATE' | translate }}"
                                       [fixedWidth]="'210px'" [size]="40" align="center"
                                       type="main-action" (onClick)="recalculateTransport()"
                                       pTooltip="{{ 'SHIPPING_SIMULATION.SIDEBAR.RECALCULATE' | translate}}"></app-simple-button>

                    <app-simple-button [disabled]="!positionCount || shippingCalculationInProgress"
                                       label="{{ 'SHIPPING_SIMULATION.SIDEBAR.NAVIGATE_TO_SIMULATION' | translate }}"
                                       type="action" [fixedWidth]="'210px'" [size]="40" align="right"
                                       buttonId="navigateToSimulation" (click)="navigateToSimulation()"></app-simple-button>
                </div>
            </div>
        </div>
    </div>

    <app-subsystem-address-selection-dialog *ngIf="deliveryAddressSelectionDialogVisible"
                                            [addresses]="addresses"
                                            [(visible)]="deliveryAddressSelectionDialogVisible"
                                            [selectedAddress]="customDeliveryAddress"
                                            (selectedAddressChange)="handleDeliveryAddressChanged($event)"
                                            [canEdit]="false"
                                            [selectable]="true"></app-subsystem-address-selection-dialog>
</div>
