import {SelectItem} from 'primeng/api/selectitem';

export class SelectItemImpl<T = any> implements SelectItem<T> {
    label: string;
    value: any;
    available?: boolean;

    constructor(label: string, value: T, available = true) {
        this.label = label;
        this.value = value;
        this.available = available;
    }
}
