import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, map, mergeMap, tap} from 'rxjs/operators';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ImagesValidator} from '../../../form-inputs/inputs/file-upload/images-validator';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {WebshopHelp, WebshopHelpType} from '../webshop-help/webshop-help';
import {WebshopHelpService} from '../webshop-help/webshop-help.service';

@Component({
    selector: 'app-webshop-glazing-help',
    templateUrl: './webshop-glazing-help.component.html',
    styleUrls: ['./webshop-glazing-help.component.css', '../settings.component.css'],
    providers: [WebshopHelpService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopGlazingHelpComponent implements OnInit {

    allHelp: WebshopHelp[] = [];

    help: WebshopHelp = new WebshopHelp();
    imageFile?: File;
    displayDialog = false;
    validationErrors: ValidationErrors = {};
    saveInProgress = false;

    constructor(private readonly webshopHelpService: WebshopHelpService,
                private readonly growls: GrowlMessageController,
                private readonly errors: CommonErrorHandler,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.webshopHelpService.getAll(WebshopHelpType.GLAZING).subscribe({
            next: data => {
                this.allHelp = data;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    loadHelp(id: number): void {
        forkJoin({
            help: this.webshopHelpService.getHelp(id),
            image: this.webshopHelpService.getHelpImage(id)
        }).subscribe({
            next: responses => {
                this.help = responses.help;
                this.imageFile = responses.image;
                this.validationErrors = {};
                this.displayDialog = true;
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    save(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        this.validateForm().pipe(
            finalize(() => this.saveInProgress = false),
            mergeMap(validationErrors => {
                if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
                    this.validationErrors = validationErrors;
                    return of(undefined);
                }
                return this.webshopHelpService.saveHelp(this.help, this.imageFile, undefined).pipe(tap(() => {
                    this.growls.info('SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.DATA_UPDATED');
                    this.displayDialog = false;
                    this.changeDetector.markForCheck();
                }));
            }))
            .subscribe({
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
    }

    handleImageFileChange(file: File): void {
        this.imageFile = file || new File([], null);
    }

    handleDescriptionChange(description: string): void {
        delete this.validationErrors['description'];
        this.help.description = description;
    }

    private validateForm(): Observable<ValidationErrors> {
        const validationErrors: ValidationErrors = {
            language: MultiValidator.of('error.webshopHelpDto.language')
                .withNotNullValidator()
                .withSizeValidator(2, 2)
                .validate(this.help.language)
        };
        if (this.help.description == undefined || !this.help.description.length) {
            validationErrors['description'] = 'error.webshopHelpDto.description.not_null';
        } else if (this.help.description.length > 100000) {
            validationErrors['description'] = 'error.webshopHelpDto.description.not_in_range';
        }
        if (this.imageFile == undefined || this.imageFile.size <= 0) {
            validationErrors['image'] = 'error.webshopHelpDto.image.not_null';
        }
        return ImagesValidator.validationErrors(this.imageFile, 10485760, 3000, 3000).pipe(map(imageValidationError => {
            if (validationErrors['image'] == undefined) {
                validationErrors['image'] = imageValidationError;
            }
            return validationErrors;
        }));
    }
}
