import {SupplierInterface} from '../../../../../../../window-designer/catalog-data/supplier-interface';
import {ConfigAddonApplication} from '../../../../../../../window-designer/enums/ConfigAddonApplication';
import {ConfigurableAddonDefinitionType} from '../../../../../../../window-designer/enums/ConfigurableAddonDefinitionType';
import {JsonDeserializable} from '../../../../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../../../../supportedLanguages';
import {MessageSeverity} from '../../../message';
import {ConfigurableAddonType} from './ConfigurableAddonType';

@JsonDeserializable
export class ConfigurableAddonDefinition {
    id: number;
    type: ConfigurableAddonDefinitionType;
    addonType: ConfigurableAddonType;
    name: MultilanguageField;
    definition: string;
    propertyMaterial: string;
    propertyColor: string;
    supplier: SupplierInterface;
    hiddenOnOffer: string[];
    hiddenOnOrder: string[];
    messagesHiddenOnOffer: MessageSeverity[];
    messagesHiddenOnOrder: MessageSeverity[];
    active: boolean;
    isOtherSystem: boolean;
    disabledApplicableTo: boolean;
    applicableTo: ConfigAddonApplication[];
    sortIndex: number;
    description: MultilanguageField;
    canBeAddedToAreaWithOuterGrill: boolean;
    canBeAddedToAreaWithoutFilling: boolean;
    canBeAddedToAreaWithOtherFilling: boolean;
    canBeAddedToAreaWithDecorativeFilling: boolean;

    constructor() {
        this.name = new MultilanguageField();
        this.hiddenOnOffer = [];
        this.hiddenOnOrder = [];
        this.messagesHiddenOnOffer = [];
        this.messagesHiddenOnOrder = [];
        this.applicableTo = [];
        this.description = new MultilanguageField();
    }

    static fromJSON(jsonObject: any): ConfigurableAddonDefinition {
        const definition = new ConfigurableAddonDefinition();
        definition.id = jsonObject['id'];
        definition.type = ConfigurableAddonDefinitionType[jsonObject['type'] as string];
        definition.addonType = ConfigurableAddonType[jsonObject['addonType'] as string];
        if (jsonObject['name'] != undefined) {
            definition.name = MultilanguageField.fromJSON(jsonObject['name']);
        }
        definition.definition = jsonObject['definition'];
        definition.propertyMaterial = jsonObject['propertyMaterial'];
        definition.propertyColor = jsonObject['propertyColor'];
        if (jsonObject['supplier'] != undefined) {
            definition.supplier = {...jsonObject['supplier']};
        }
        if (jsonObject['hiddenOnOffer'] != undefined) {
            definition.hiddenOnOffer = [...jsonObject['hiddenOnOffer']];
        }
        if (jsonObject['hiddenOnOrder'] != undefined) {
            definition.hiddenOnOrder = [...jsonObject['hiddenOnOrder']];
        }
        if (jsonObject['messagesHiddenOnOffer'] != undefined) {
            definition.messagesHiddenOnOffer = [...jsonObject['messagesHiddenOnOffer']];
        }
        if (jsonObject['messagesHiddenOnOrder'] != undefined) {
            definition.messagesHiddenOnOrder = [...jsonObject['messagesHiddenOnOrder']];
        }
        definition.active = jsonObject['active'];
        definition.isOtherSystem = jsonObject['isOtherSystem'];
        definition.disabledApplicableTo = jsonObject['disabledApplicableTo'];
        if (jsonObject['applicableTo'] != undefined) {
            definition.applicableTo = [...jsonObject['applicableTo']];
        }
        definition.sortIndex = jsonObject['sortIndex'];
        if (jsonObject['description'] != undefined) {
            definition.description = MultilanguageField.fromJSON(jsonObject['description']);
        }
        definition.canBeAddedToAreaWithOuterGrill = jsonObject['canBeAddedToAreaWithOuterGrill'];
        definition.canBeAddedToAreaWithoutFilling = jsonObject['canBeAddedToAreaWithoutFilling'];
        definition.canBeAddedToAreaWithOtherFilling = jsonObject['canBeAddedToAreaWithOtherFilling'];
        definition.canBeAddedToAreaWithDecorativeFilling = jsonObject['canBeAddedToAreaWithDecorativeFilling'];
        return definition;
    }
}
