import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogItemType} from '../../offer/window-editor/sidebar/pricing/PricingItem';

@JsonDeserializable
export class DiscontinuedCatalogItemInfo implements CrudItem {
    id: number;
    catalogItemType: CatalogItemType;
    catalogItemId: number;
    unavailableFrom: Date;
    nameModificationDate: Date;
    catalogItemName = new MultilanguageField();

    static fromJSON(jsonObject: any): DiscontinuedCatalogItemInfo {
        const catalogItemExpiration = new DiscontinuedCatalogItemInfo();
        catalogItemExpiration.id = jsonObject['id'];
        catalogItemExpiration.catalogItemType = jsonObject['catalogItemType'];
        catalogItemExpiration.catalogItemId = jsonObject['catalogItemId'];
        if (jsonObject['unavailableFrom'] != undefined) {
            catalogItemExpiration.unavailableFrom = new Date(jsonObject['unavailableFrom']);
        }
        if (jsonObject['nameModificationDate'] != undefined) {
            catalogItemExpiration.nameModificationDate = new Date(jsonObject['nameModificationDate']);
        }
        if (jsonObject['catalogItemName'] != undefined) {
            catalogItemExpiration.catalogItemName = MultilanguageField.fromJSON(jsonObject['catalogItemName']);
        }
        return catalogItemExpiration;
    }
}
