import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DataTable} from 'primeng/datatable';
import {forkJoin} from 'rxjs';
import {finalize, map, mergeMap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../common/crud-common/crud.component';
import {TranslatedSelectItemService} from '../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../common/validation-errors';
import {WizardStepValidator} from '../../form-inputs/wizard/wizard-step.component';
import {ErrorResponse} from '../errors/errorResponse';
import {HasSaveTable} from '../subsystem/base-profit-margin.component';
import {ProfitMarginTarget} from "../subsystem/profit-margin/profit-margin-target";
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from '../subsystem/profit-margin/profitMargin';
import {ProductTypeGroup} from '../window-system/window-system-definition/product-type-group';
import {SubsystemGroup} from './subsystem-group';
import {SubsystemGroupService} from './subsystem-group.service';

@Component({
    selector: 'app-subsystem-group',
    templateUrl: './subsystem-group.component.html',
    styleUrls: ['./subsystem-group.component.css'],
    providers: [SubsystemGroupService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemGroupComponent extends CrudCommonComponent<SubsystemGroup, SubsystemGroupService> implements OnInit {

    private static readonly SUBMIT_BLOCK_ID = 'SubsystemGroupComponent submit';

    readonly WindowSystemTypeGroup = ProductTypeGroup;

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEM_MARGINS: 'profit-margins-system',
        ROOF_SYSTEM_MARGINS: 'profit-margins-roof-system',
        ENTRANCE_SYSTEM_MARGINS: 'profit-margins-entrance-system',
        ADDON_MARGINS: 'profit-margins-addon',
        CONFIG_ADDON_MARGINS: 'profit-margins-config-addon',
        BULK_CONFIG_ADDON_MARGINS: 'profit-margins-bulk-config-addon',
        CHARGE_MARGINS: 'profit-margins-catalog-charge',
        GATES_MARGINS: 'profit-margins-gates',
        SYSTEM_SELL_DEALER_DISCOUNTS: 'sell-dealer-discounts-system',
        CONFIG_ADDON_SELL_DEALER_DISCOUNTS: 'sell-dealer-discounts-config-addon',
        SUPPLIER_SELL_DEALER_DISCOUNTS: 'sell-dealer-discounts-supplier'
    };

    readonly profitGlobalMarginButtonId = 'profitGlobalMarginButtonId';
    readonly profitGlobalMarginInputId = 'profitGlobalMarginInputId';
    readonly roofProfitGlobalMarginButtonId = 'roofProfitGlobalMarginButtonId';
    readonly roofProfitGlobalMarginInputId = 'roofProfitGlobalMarginInputId';
    readonly entranceProfitGlobalMarginButtonId = 'entranceProfitGlobalMarginButtonId';
    readonly entranceProfitGlobalMarginInputId = 'entranceProfitGlobalMarginInputId';
    readonly confAddGlobalMarginButtonId = 'confAddGlobalMarginButtonId';
    readonly confAddGlobalMarginInputId = 'confAddGlobalMarginInputId';
    readonly bulkConfAddGlobalMarginButtonId = 'bulkConfAddGlobalMarginButtonId';
    readonly bulkConfAddGlobalMarginInputId = 'bulkConfAddGlobalMarginInputId';
    readonly addonGlobalMarginButtonId = 'addonGlobalMarginButtonId';
    readonly addonGlobalMarginInputId = 'addonGlobalMarginInputId';
    readonly catalogChargeGlobalMarginButtonId = 'catalogChargeGlobalMarginButtonId';
    readonly catalogChargeGlobalMarginInputId = 'catalogChargeGlobalMarginInputId';
    readonly gatesGlobalMarginButtonId = 'gatesGlobalMarginButtonId';
    readonly gatesGlobalMarginInputId = 'gatesGlobalMarginInputId';
    readonly systemGlobalDiscountButtonId = 'systemGlobalDiscountButtonId';
    readonly systemGlobalDiscountInputId = 'systemGlobalDiscountInputId';
    readonly configAddonGlobalDiscountButtonId = 'configAddonGlobalDiscountButtonId';
    readonly configAddonGlobalDiscountInputId = 'configAddonGlobalDiscountInputId';
    readonly supplierGlobalDiscountButtonId = 'supplierGlobalDiscountButtonId';
    readonly supplierGlobalDiscountInputId = 'supplierGlobalDiscountInputId';

    readonly route: ActivatedRoute;
    possibleTargets = ProfitMarginTarget;

    validateDataStep: WizardStepValidator;

    canEdit: boolean;
    deleteDialogOpened = false;
    deleteInProgress = false;

    @ViewChild(DataTable)
    dataTable: DataTable;

    @ViewChildren('childTable')
    childTables: QueryList<HasSaveTable>;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, SubsystemGroupService, 'SUBSYSTEM_GROUP', 'SubsystemGroup');
        this.validateDataStep = () => this.validateForm();
        this.route = injector.get(ActivatedRoute);
    }

    ngOnInit() {
        this.canEdit = this.permissions.isPermitted({roles: ['ROLE_EDIT_SUBSYSTEM']});
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    getNewItem(): SubsystemGroup {
        return new SubsystemGroup();
    }

    protected validateForm(): boolean {
        const validationErrors: ValidationErrors = {};
        if (!this.item.name) {
            validationErrors['name'] = 'error.subsystemGroupDto.name.not_empty';
        }
        if (!!this.item.bulkAddonProfitMargin
            && !isValidProfitMarginValueString(normalizeProfitMarginValueString(this.item.bulkAddonProfitMargin))) {
            validationErrors['bulkAddonProfitMargin'] = 'PROFIT_MARGIN.ERROR.INVALID_VALUE';
        }
        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            this.changeDetector.markForCheck();
            return false;
        }
        return true;
    }

    submit() {
        if (!this.isSaveInProgress()) {
            this.setSaveInProgress(true);

            if (!!this.item.bulkAddonProfitMargin) {
                this.item.bulkAddonProfitMargin = normalizeProfitMarginValueString(this.item.bulkAddonProfitMargin);
            }

            const saveObservable = this.itemId != undefined ?
                this.itemService.editItem(this.itemId, this.item) :
                this.itemService.addItem(this.item);
            this.blockUiController.block(SubsystemGroupComponent.SUBMIT_BLOCK_ID);
            saveObservable.pipe(mergeMap(subsystemGroupId => {
                return forkJoin(this.childTables.map(table => table.saveTable(subsystemGroupId))).pipe(
                    map(() => subsystemGroupId),
                    finalize(() => this.blockUiController.unblock(SubsystemGroupComponent.SUBMIT_BLOCK_ID))
                );
            })).subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    getDialogHeader(): string {
        if (this.canEdit) {
            return 'SUBSYSTEM_GROUP.EDIT-TITLE';
        }
        return 'SUBSYSTEM_GROUP.VIEW-TITLE';
    }

    showDeleteConfirmation(): void {
        this.deleteDialogOpened = true;
        this.changeDetector.markForCheck();
    }

    processDelete(): void {
        if (!this.deleteInProgress) {
            this.deleteInProgress = true;
            this.itemService.removeItem(this.itemId).pipe(
                finalize(() => {
                    this.deleteDialogOpened = false;
                    this.deleteInProgress = false;
                    this.cleanUpAndReload();
                })
            ).subscribe({
                next: () => {
                    this.setDisplayDialog(false);
                    this.changeDetector.markForCheck();
                },
                error: (error: HttpErrorResponse) => {
                    const errorResponse = new ErrorResponse(error.error);
                    this.growlMessageController.error(errorResponse.message);
                }
            });
        }
    }

    cancelDelete(): void {
        this.deleteDialogOpened = false;
        this.changeDetector.markForCheck();
    }

    goToSubsystems(subsystemGroupId: number): void {
        this.router.navigate(['/features/subsystem', { ignoreStoredFilter: true }], {queryParams: { subsystemGroupId }})
    }
}
