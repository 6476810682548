import {GlazingPackageTarget} from '../glazing-package/glazing-package';
import {WebshopGlazingPackage} from '../glazing-package/webshop-glazing-package/webshop-glazing-package';

export class WebshopGlazingPackagePerSubsystem  {
    id: number;
    webshopGlazingPackage: WebshopGlazingPackage;
    enabledInWebshop: boolean;

    constructor() {
        this.id = undefined;
        this.webshopGlazingPackage = new WebshopGlazingPackage(GlazingPackageTarget.WEBSHOP_GLAZING);
        this.enabledInWebshop = false;
    }
}
