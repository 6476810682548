<div class="table-header">
    <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_SYSTEM_WEB_SHOP.WINDOW-SYSTEM.LIST' | translate}}</div>
    <div class="table-header-buttons">
        <div class="table-header-buttons-container">
            <app-simple-button buttonId="toggle-filter-button"
                               label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                               icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
        </div>
    </div>
</div>
<p-dataTable #dt [value]="windowSystemWebshopSettings" [rows]="chosenRowsPerPage"
             [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
             [responsive]="true" (onLazyLoad)="loadItemsLazy($event)"
             (onRowDblclick)="doOnRowSelect($event)" [(selection)]="selectedRecord"
             [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">

    <p-column field="name" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.NAME' | translate }}" [sortable]="true"
              [filter]="showFilters" filterMatchMode="contains">
        <ng-template let-subsystemWebshopSetting="rowData" pTemplate="body">
            {{subsystemWebshopSetting.windowSystemName[userLang]}}
        </ng-template>
    </p-column>
    <p-column field="ownWindowSystemName"
              header="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.GENERAL.OWN_WINDOW_SYSTEM_NAME' | translate }}"
              [sortable]="true" [filter]="showFilters" filterMatchMode="contains"></p-column>
    <p-column field="systemType" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYSTEM_TYPE' | translate }}"
              [sortable]="false" [filter]="showFilters" [filterValues]="filterType | async">
        <ng-template let-subsystemWebshopSetting="rowData" pTemplate="body">
            {{ subsystemWebshopSetting.windowSystemType ?
            (('WINDOW-SYSTEM-DEFINITION.SYSTEM_TYPE.'+subsystemWebshopSetting.windowSystemType) |
            translate) : subsystemWebshopSetting.windowSystemType }}
        </ng-template>
    </p-column>
    <p-column field="material" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MATERIAL' | translate }}"
              [sortable]="false" [filter]="showFilters" [filterValues]="filterMaterial | async">
        <ng-template let-subsystemWebshopSetting="rowData" pTemplate="body">
            {{ 'MATERIAL.' + subsystemWebshopSetting.windowMaterial | translate }}
        </ng-template>
    </p-column>
    <p-column field="active" header="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.STATUS' | translate }}" [sortable]="false"
              [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
              [defaultFilterValue]="defaultActiveFilter">
        <ng-template let-subsystemWebshopSetting="rowData" pTemplate="body">
            {{(subsystemWebshopSetting.enabledInWebshop ? 'WINDOW-SYSTEM-DEFINITION.FORM.ACTIVE' :
            'WINDOW-SYSTEM-DEFINITION.FORM.INACTIVE') | translate}}
        </ng-template>
    </p-column>

    <ng-template pTemplate="paginatorLeft">
        <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
    </ng-template>
</p-dataTable>

<app-wizard-dialog *ngIf="displayDialog"
                   header="{{'WINDOW-SYSTEM-DEFINITION.EDIT-WINDOW-SYSTEM-DEFINITION' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.GENERAL.TAB_NAME' | translate }}" [id]="STEPS.DATA"
                     [validate]="VALIDATORS.DATA">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-input-text inputId="windowSystemName" disabled="true"
                                label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.NAME' | translate }}"
                                [ngModel]="item.windowSystemName[userLang]"></app-input-text>
            </div>
            <div class="new-form-row">
                <app-select inputId="windowMaterial" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MATERIAL' | translate }}"
                            [options]="filterMaterial | async"
                            disabled="true" [ngModel]="item.windowMaterial"></app-select>
            </div>
            <div class="new-form-row">
                <app-select inputId="windowSystemType"
                            label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYSTEM_TYPE' | translate }}"
                            disabled="true" [options]="filterType | async"
                            [ngModel]="item.windowSystemType"></app-select>
            </div>
            <div class="new-form-row">
                <app-text-area  inputId="windowSystemDescription" disabled="true"
                                label="{{ 'WINDOW-SYSTEM-DEFINITION.WEB-SHOP.DESCRIPTION' | translate }}"
                                [ngModel]="windowSystemWebShopInfo.description[userLang]"></app-text-area>
            </div>
            <div class="new-form-row">
                <app-input-text inputId="ownWindowSystemName" [maxlength]="200"
                                label="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.GENERAL.OWN_WINDOW_SYSTEM_NAME' | translate }}"
                                [(ngModel)]="item.ownWindowSystemName"></app-input-text>
            </div>
            <div class="new-form-row">
                <app-checkbox inputId="enabledInWebshop"
                              label="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.GENERAL.ENABLED_IN_WEBSHOP' | translate }}"
                              [(ngModel)]="item.enabledInWebshop"></app-checkbox>
            </div>
        </div>
    </app-wizard-step>

    <app-wizard-step label="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.IMAGE.TAB_NAME' | translate }}" [id]="STEPS.IMAGE"
                     [validate]="VALIDATORS.IMAGE">
        <div class="new-form-field-input-container">
            <div class="image-row">
                <img *ngIf="imagePreview" class="image" id="imagePreview" [src]="imagePreview" />
                <div *ngIf="!imagePreview">{{'WINDOW_SYSTEM_WEBSHOP_SETTINGS.IMAGE.NO_IMAGE' | translate}}</div>
            </div>
        </div>
    </app-wizard-step>

    <app-wizard-step label="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.BUSINESS_TYPES.TAB_NAME' | translate }}"
                     [id]="STEPS.BUSINESS_TYPES"
                     [validate]="VALIDATORS.BUSINESS_TYPES">
        <div class="business-types-container">
            <div *ngIf="validationErrors['businessTypes'] != undefined" class="new-form-field-error-message">
                {{ validationErrors['businessTypes'] | translate }}
            </div>

            <div class="new-form-row">
                <app-simple-button *ngIf="availableBusinessTypes.length > 0" buttonId="business-types-toggle-all-button"
                                   label="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.BUSINESS_TYPES.ALL' | translate }}" type="action"
                                   (onClick)="toggleAllBusinessTypes()"></app-simple-button>
                <div *ngIf="availableBusinessTypes.length === 0">{{'WINDOW_SYSTEM_WEBSHOP_SETTINGS.BUSINESS_TYPES.NO_AVAILABLE_TYPES' | translate}}</div>
            </div>
            <div *ngFor="let businessType of availableBusinessTypes">
                <app-checkbox [inputId]="'businessType_' + businessType.id"
                              label="{{businessType.names[translate.currentLang]}}"
                              [ngModel]="isBusinessTypeSelected(businessType.id)"
                              (ngModelChange)="onBusinessTypeSelectionChange(businessType.id)"
                ></app-checkbox>
            </div>
        </div>
    </app-wizard-step>

    <app-wizard-step label="{{ 'WINDOW_SYSTEM_WEBSHOP_SETTINGS.AREA_BARS.TAB_NAME' | translate }}"
                     [id]="STEPS.AREA_BAR_CODES" [validate]="VALIDATORS.AREA_BAR_CODES">
        <app-window-system-webshop-area-bars-form
                [windowSystemWebshopSettings]="item"></app-window-system-webshop-area-bars-form>
    </app-wizard-step>

</app-wizard-dialog>
