import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {ValidationErrors} from "../../../../common/validation-errors";
import {AccessData} from "../../../AccessData";
import {DeliveryListService} from "../delivery-list.service";
import {Palette} from "../palette";
import {OrderDeliveryData} from "./palette-production-orders/order-delivery-data";
import {PaletteProductionOrdersComponent} from "./palette-production-orders/palette-production-orders.component";
import {PaletteWizardMode} from "./palette-wizard-mode";
import {PaletteService} from "./palette.service";

@Component({
    selector: 'app-palette-wizard',
    templateUrl: './palette-wizard.component.html',
    providers: [DeliveryListService, PaletteService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaletteWizardComponent {

    private static readonly LOADING_BLOCK_UI_NAME = 'PaletteWizardLoadBlock';
    private static readonly SAVING_BLOCK_UI_NAME = 'PaletteWizardSaveBlock';

    readonly STEPS = {
        DETAILS: {
            id: 'DATA',
            validator: () => this.validateForm()
        },
        PRODUCTION_ORDERS: {
            id: 'PRODUCTION_ORDERS',
            validator: () => this.validateProductionOrders()
        }
    };

    @ViewChild('orderSelection')
    orderSelectionStep: PaletteProductionOrdersComponent;

    deliveryListId: number;
    originalPaletteId: number;

    palette: Palette = new Palette();
    deliveryData: OrderDeliveryData[];
    mode: PaletteWizardMode;
    PaletteWizardMode = PaletteWizardMode;

    private activatedRoute: ActivatedRoute;
    private blockUiController: BlockUiController;
    private changeDetector: ChangeDetectorRef;
    private deliveryListService: DeliveryListService;
    private errors: CommonErrorHandler;
    private paletteService: PaletteService;
    private router: Router;
    public translate: TranslateService;

    validationErrors: ValidationErrors = {};

    constructor(injector: Injector) {
        this.activatedRoute = injector.get(ActivatedRoute);
        this.blockUiController = injector.get(BlockUiController);
        this.changeDetector = injector.get(ChangeDetectorRef);
        this.deliveryListService = injector.get(DeliveryListService);
        this.errors = injector.get(CommonErrorHandler);
        this.paletteService = injector.get(PaletteService);
        this.router = injector.get(Router);
        this.translate = injector.get(TranslateService);

        this.init();
    }

    private init(): void {
        this.mode = this.getRouteParamIfPresent('mode');
        this.deliveryListId = this.getRouteParamIfPresent('deliveryListId');
        this.originalPaletteId = this.getRouteParamIfPresent('id');
        this.blockUiController.block(PaletteWizardComponent.LOADING_BLOCK_UI_NAME);
        forkJoin({
            deliveryData: this.deliveryListService.getDeliveryData(this.deliveryListId),
            palette: this.originalPaletteId != undefined ? this.paletteService.get(this.originalPaletteId) : of<Palette>(undefined)
        })
            .pipe(finalize(() => this.blockUiController.unblock(PaletteWizardComponent.LOADING_BLOCK_UI_NAME)))
            .subscribe({
                next: data => {
                    this.deliveryData = data.deliveryData.data;
                    if (this.originalPaletteId != null) {
                        this.palette = data.palette;
                    }
                    this.changeDetector.markForCheck();
                },
                error: error => {
                    this.errors.handle(error);
                }
            });
    }

    private getRouteParamIfPresent(paramName: string): number {
        return this.activatedRoute.snapshot.paramMap.has(paramName) ?
            +this.activatedRoute.snapshot.paramMap.get(paramName) : undefined;
    }

    exitWizard(): void {
        this.router.navigate([AccessData.path.deliveryList(this.deliveryListId.toString())]);
    }

    public save(): void {
        this.blockUiController.block(PaletteWizardComponent.SAVING_BLOCK_UI_NAME);
        this.addSelectedInfo();
        let observable = this.originalPaletteId == null ?
            this.deliveryListService.addPalette(this.deliveryListId, this.palette) :
            this.paletteService.update(this.palette);
        observable.pipe(
            finalize(() => this.blockUiController.unblock(PaletteWizardComponent.SAVING_BLOCK_UI_NAME)))
            .subscribe({
                next: () => {
                    this.exitWizard();
                },
                error: error => {
                    this.validationErrors = this.errors.handle(error);
                    this.changeDetector.markForCheck();
                }
            });
    }

    validateForm(): Observable<boolean> {
        const validationErrors = {};
        if (this.palette.name == null) {
            validationErrors['name'] = 'error.paletteDto.name.not_null';
        } else if (this.palette.name.length === 0 || this.palette.name.length > 100) {
            validationErrors['name'] = 'error.paletteDto.name.not_in_range';
        }
        if (this.palette.quantity == null) {
            validationErrors['quantity'] = 'error.paletteDto.quantity.not_null';
        } else if (this.palette.quantity < 1) {
            validationErrors['quantity'] = 'error.paletteDto.quantity.below_min';
        } else if (this.palette.quantity > 100) {
            validationErrors['quantity'] = 'error.paletteDto.quantity.over.max';
        }
        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.paletteService.validateGeneralData(this.palette).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    validateProductionOrders(): Observable<boolean> {
        return of(true);
    }

    validationErrorsPresent(validationErrors?: { [field: string]: string }): boolean {
        return Object.keys(validationErrors).filter(key => validationErrors[key] != undefined).length > 0;
    }

    getHeader(): string {
        return 'PALETTE.FORM.' + (this.originalPaletteId == null ? 'NEW_PALETTE' : 'EDIT_PALETTE');
    }

    private addSelectedInfo(): void {
        this.palette.productionOrderIds = this.orderSelectionStep == null ? null :
            this.orderSelectionStep.getSelectedProductionOrderIds();
    }
}
