import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, RackField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {RackFieldUsage} from "../catalog-field-usage";
import {Rack} from './rack';
import {RackService} from './rack.service';

@Component({
    selector: 'app-rack',
    templateUrl: './rack.component.html',
    styleUrls: ['./rack.component.css', '../../shared-styles.css'],
    providers: [RackService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RackComponent extends CrudCommonComponent<Rack, RackService> implements OnInit {

    item: Rack;
    validateDataStep: WizardStepValidator;

    readonly STEPS = {
        DATA: 'DATA'
    };

    @ViewChild('dt') datatable;

    editPermits: FieldLimitation[] = [];
    fieldUsage: RackFieldUsage;
    CatalogField = CatalogTab;
    RackField = RackField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, RackService, 'RACK', 'Rack');
        this.item = new Rack();
        this.validateDataStep = () => this.validateForm();
        this.fieldUsage = new RackFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.initTableFilters();
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.RACKS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    initTableFilters(): void {
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    submit() {
        this.edit();
    }

    getNewItem(): Rack {
        return new Rack();
    }

    prepareItemForRequest(): Rack {
        return this.cloneRack(this.item);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.item = this.cloneRack(this.selectedItem);
            this.item.id = undefined;
            this.setDisplayDialog(true);
        }
    }

    cloneRack(rack: Rack): Rack {
        let newRack = <Rack>_.clone(rack);
        newRack.name = <MultilanguageField>_.clone(rack.name);
        return newRack;
    }

    validateForm(): Observable<boolean> {
        const validationErrors = {};
        if (this.item.name[this.userLang] == null) {
            validationErrors[`name[${this.userLang}]`] = `error.rackDto.name[${this.userLang}].not_empty`;
        }

        validationErrors['weight'] = MultiValidator.of('error.rackDto.weight')
            .withNotNullValidator()
            .withRangeValidator(0, 10000, false)
            .withCustomValidator(weight => this.exceedsAllowedPrecision(weight, 3) ? 'not_allowed' : undefined)
            .validate(this.item.weight);

        validationErrors['netEuroPrice'] = MultiValidator.of('error.rackDto.netEuroPrice')
            .withNotNullValidator()
            .withRangeValidator(0, 10000, false)
            .withCustomValidator(netEuroPrice => this.exceedsAllowedPrecision(netEuroPrice, 2) ? 'not_allowed' : undefined)
            .validate(this.item.netEuroPrice);

        validationErrors['slots'] = MultiValidator.of('error.rackDto.slots')
            .withNotNullValidator()
            .withRangeValidator(1, 100)
            .validate(this.item.slots);

        validationErrors['totalWidth'] = MultiValidator.of('error.rackDto.totalWidth')
            .withNotNullValidator()
            .withRangeValidator(1, 10000)
            .validate(this.item.totalWidth);

        validationErrors['totalDepth'] = MultiValidator.of('error.rackDto.totalDepth')
            .withNotNullValidator()
            .withRangeValidator(1, 10000)
            .validate(this.item.totalDepth);

        validationErrors['totalHeight'] = MultiValidator.of('error.rackDto.totalHeight')
            .withNotNullValidator()
            .withRangeValidator(1, 10000)
            .validate(this.item.totalHeight);

        validationErrors['usableWidth'] = MultiValidator.of('error.rackDto.usableWidth')
            .withNotNullValidator()
            .withRangeValidator(1, 10000)
            .validate(this.item.usableWidth);

        validationErrors['usableDepth'] = MultiValidator.of('error.rackDto.usableDepth')
            .withNotNullValidator()
            .withRangeValidator(1, 10000)
            .validate(this.item.usableDepth);

        validationErrors['usableHeight'] = MultiValidator.of('error.rackDto.usableHeight')
            .withNotNullValidator()
            .withRangeValidator(1, 10000)
            .validate(this.item.usableHeight);

        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.itemService.validateGeneralData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !super.validationErrorsPresent(backendValidationErrors)));
    }

    private exceedsAllowedPrecision(value: number, allowedPrecision: number): boolean {
        let valueString = "" + value;
        return valueString.includes(".") && valueString.split(".")[1].length > allowedPrecision;
    }
}
