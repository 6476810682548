import {QuantityType} from "../../enums/QuantityType";
import {AddonQuantityDataSource, AddonQuantityDataSourceFactory} from "./AddonQuantityDataSource";
import {SizeUnitsConverter} from "../SizeUnitsConverter";

export class AddonDefaultQuantityExpresionCalculator {

    static isValid(expression: string): boolean {
        return this.validateExpressionOperands(expression) &&
            !Number.isNaN((Number(this.calculate(expression, AddonQuantityDataSourceFactory.forChecks))));
    }

    static validateExpressionOperands(expression: string): boolean {
        let foundOperants: string[] = expression.split(new RegExp('[^a-zA-Z]+'));
        foundOperants = foundOperants.filter(element => element !== '');

        let properOperants: string[] = this.makeArrayFromEnum();

        let differenceResult: string[] = foundOperants.filter(function (n) {
            return properOperants.indexOf(n) === -1;
        });

        return differenceResult.length <= 0;
    }

    static calculate(expression: string, dataSource: AddonQuantityDataSource, quantityType?: QuantityType): number {
        let tmpDataSource: AddonQuantityDataSource = JSON.parse(JSON.stringify(dataSource));

        if (quantityType === QuantityType.METER || quantityType === QuantityType.SQUARE_METER) {
            tmpDataSource.windowHeight = SizeUnitsConverter.mmToMeter(tmpDataSource.windowHeight);
            tmpDataSource.windowWidth = SizeUnitsConverter.mmToMeter(tmpDataSource.windowWidth);
            tmpDataSource.windowArea = tmpDataSource.windowHeight * tmpDataSource.windowWidth;
            tmpDataSource.windowPerimeter = (tmpDataSource.windowHeight + tmpDataSource.windowWidth) * 2;
        }

        expression = expression.split(ExpresionOperands.HEIGHT.toString()).join('' + tmpDataSource.windowHeight);
        expression = expression.split(ExpresionOperands.WIDTH.toString()).join('' + tmpDataSource.windowWidth);
        expression = expression.split(ExpresionOperands.PERIMETER.toString()).join('' + tmpDataSource.windowPerimeter);
        expression = expression.split(ExpresionOperands.AREA.toString()).join('' + tmpDataSource.windowArea);

        let regex = new RegExp('^[0-9.,+\\-*/\\s()]*$');
        if (regex.test(expression)) {
            return eval(expression);
        }
        return Number.NaN;
    }

    private static makeArrayFromEnum() {
        let allValues = [];
        allValues.push(ExpresionOperands.HEIGHT);
        allValues.push(ExpresionOperands.WIDTH);
        allValues.push(ExpresionOperands.PERIMETER);
        allValues.push(ExpresionOperands.AREA);
        return allValues;
    }
}

export enum ExpresionOperands {
    HEIGHT = 'WYS',
    WIDTH = 'SZER',
    PERIMETER = 'OBW',
    AREA = 'POLE'
}
