import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {mapItemToJson} from '../../../common/crud-common/crudItem';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ConfigSystemUpsellingSettings} from './config-system-upselling-settings';

@Injectable()
export class ConfigSystemUpsellingSettingsService implements CrudService<ConfigSystemUpsellingSettings> {

    private static readonly API_URL = 'configSystemUpsellingSettings';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<ConfigSystemUpsellingSettings>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(ConfigSystemUpsellingSettingsService.API_URL, {params: params})
            .pipe(mapListingToJson(ConfigSystemUpsellingSettings));
    }

    getItem(upsellingSettingsId: number): Observable<ConfigSystemUpsellingSettings> {
        return this.http.get<object>(`${ConfigSystemUpsellingSettingsService.API_URL}/${upsellingSettingsId}`)
            .pipe(mapItemToJson(ConfigSystemUpsellingSettings));
    }

    addItem(upsellingSettings: ConfigSystemUpsellingSettings): Observable<number> {
        return this.http.post<void>(ConfigSystemUpsellingSettingsService.API_URL, upsellingSettings, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(ConfigSystemUpsellingSettingsService.API_URL));
    }

    editItem(upsellingSettingsId: number, upsellingSettings: ConfigSystemUpsellingSettings): Observable<number> {
        return this.http.put<void>(ConfigSystemUpsellingSettingsService.API_URL, upsellingSettings)
            .pipe(this.dataServiceHelper.mapToExistingItemId(upsellingSettingsId));
    }

    getLinkedSystems(upsellingSettingsId: number): Observable<number[]>;
    getLinkedSystems(upsellingSettingsIds: number[]): Observable<Map<number, number[]>>;
    getLinkedSystems(arg: number | number[]): Observable<number[] | Map<number, number[]>> {
        const params = {
            ids: Array.isArray(arg)
                ? arg.map(n => `${n}`)
                : [`${arg}`]
        };
        return this.http.get<{ [upsellingSettingsId: number]: number[] }>(`${ConfigSystemUpsellingSettingsService.API_URL}/linked`,
            {params: params}).pipe(
            map(result => {
                if (Array.isArray(arg)) {
                    return new Map<number, number[]>(Object.entries(result).map(entry => [+entry[0], entry[1]]));
                }
                return result[arg];
            }));
    }

    editLinks(upsellingSettingsId: number, systemIds: number[]): Observable<void> {
        return this.http.put<void>(`${ConfigSystemUpsellingSettingsService.API_URL}/link/${upsellingSettingsId}`, systemIds);
    }

    validateData(settings: ConfigSystemUpsellingSettings): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${ConfigSystemUpsellingSettingsService.API_URL}/validateData`,
            settings));
    }

    getAllActiveUpsellingSettingsWithWindowSystems(): Observable<{
        upsellingSettings: ConfigSystemUpsellingSettings[],
        linkedSystems: Map<number, number[]>
    }> {
        return this.getItems(undefined, undefined, {active: {value: 'true'}}, 'sortIndex', 1).pipe(
            mergeMap(listing => forkJoin({
                upsellingSettings: of(listing.data),
                linkedSystems: this.getLinkedSystems(listing.data.map(settings => settings.id))
            }))
        );
    }
}
