import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {Currencies} from "../../../currencies";

@JsonDeserializable
export class RotSettings {

    rotPercentValue: number;
    defaultGrossLimit: number;
    absoluteGrossLimit: number;
    rotCalculationCurrency: Currencies;

    static fromJSON(jsonObject: any): RotSettings {
        const rotSettings = new RotSettings();
        rotSettings.rotPercentValue = jsonObject.rotPercentValue;
        rotSettings.defaultGrossLimit = jsonObject.defaultGrossLimit;
        rotSettings.absoluteGrossLimit = jsonObject.absoluteGrossLimit;
        rotSettings.rotCalculationCurrency = jsonObject.rotCalculationCurrency;
        return rotSettings;
    }
}
