<div class="new-form-600">

    <app-translation-fields *ngIf="fieldUsage.show(RackField.NAME)"
                            inputId="name" label="{{ 'RACK.FORM.NAME' | translate }}" validationKeyPrefix="name"
                            [disabled]="fieldUsage.disabled(RackField.NAME)"
                            [validationErrors]="validationErrors" [maxlength]="lengthLimit.NAME_MAX_LENGTH"
                            [required]="true" [field]="item.name"></app-translation-fields>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.WEIGHT)">
        <app-input-number inputId="weight"
                          label="{{ 'RACK.FORM.WEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(RackField.WEIGHT)"
                          [(validationMessageKey)]="validationErrors['weight']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.weight"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.NET_EURO_PRICE)">
        <app-input-number inputId="netEuroPrice"
                          label="{{ 'RACK.FORM.NET_EURO_PRICE' | translate }}"
                          [disabled]="fieldUsage.disabled(RackField.NET_EURO_PRICE)"
                          [(validationMessageKey)]="validationErrors['netEuroPrice']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.netEuroPrice" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.SLOTS)">
        <app-input-number inputId="slots"
                          label="{{ 'RACK.FORM.SLOTS' | translate }}" [allowOnlyIntegers]="true"
                          [disabled]="fieldUsage.disabled(RackField.SLOTS)"
                          [(validationMessageKey)]="validationErrors['slots']" [min]="0" [max]="100" [step]="1"
                          [(ngModel)]="item.slots" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.USABLE_WIDTH)">
        <app-input-number inputId="usableWidth"
                          label="{{ 'RACK.FORM.USABLE_WIDTH' | translate }}" [allowOnlyIntegers]="true"
                          [disabled]="fieldUsage.disabled(RackField.USABLE_WIDTH)"
                          [(validationMessageKey)]="validationErrors['usableWidth']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.usableWidth" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.USABLE_DEPTH)">
        <app-input-number inputId="usableDepth"
                          label="{{ 'RACK.FORM.USABLE_DEPTH' | translate }}" [allowOnlyIntegers]="true"
                          [disabled]="fieldUsage.disabled(RackField.USABLE_DEPTH)"
                          [(validationMessageKey)]="validationErrors['usableDepth']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.usableDepth" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.USABLE_HEIGHT)">
        <app-input-number inputId="usableHeight"
                          label="{{ 'RACK.FORM.USABLE_HEIGHT' | translate }}" [allowOnlyIntegers]="true"
                          [disabled]="fieldUsage.disabled(RackField.USABLE_HEIGHT)"
                          [(validationMessageKey)]="validationErrors['usableHeight']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.usableHeight" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.TOTAL_WIDTH)">
        <app-input-number inputId="totalWidth"
                          label="{{ 'RACK.FORM.TOTAL_WIDTH' | translate }}" [allowOnlyIntegers]="true"
                          [disabled]="fieldUsage.disabled(RackField.TOTAL_WIDTH)"
                          [(validationMessageKey)]="validationErrors['totalWidth']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.totalWidth"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.TOTAL_DEPTH)">
        <app-input-number inputId="totalDepth"
                          label="{{ 'RACK.FORM.TOTAL_DEPTH' | translate }}" [allowOnlyIntegers]="true"
                          [disabled]="fieldUsage.disabled(RackField.TOTAL_DEPTH)"
                          [(validationMessageKey)]="validationErrors['totalDepth']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.totalDepth"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.TOTAL_HEIGHT)">
        <app-input-number inputId="totalHeight"
                          label="{{ 'RACK.FORM.TOTAL_HEIGHT' | translate }}" [allowOnlyIntegers]="true"
                          [disabled]="fieldUsage.disabled(RackField.TOTAL_HEIGHT)"
                          [(validationMessageKey)]="validationErrors['totalHeight']" [min]="0" [max]="10000" [step]="1"
                          [(ngModel)]="item.totalHeight"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(RackField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'RACK.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(RackField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(RackField.ADDITIONALLY_CALCULATED)">
        <app-checkbox inputId="additionallyCalculated" label="{{ 'RACK.FORM.ADDITIONALLY_CALCULATED' | translate }}"
                      [disabled]="fieldUsage.disabled(RackField.ADDITIONALLY_CALCULATED)"
                      [(ngModel)]="item.additionallyCalculated"></app-checkbox>
    </div>
</div>
