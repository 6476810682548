import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {TranslatedSelectItemService} from '../../../../../../common/service/translated-select-item.service';
import {MultiValidator} from '../../../../../../shared/validator/input-validator';
import {PositionService} from "../../position.service";
import {RotDataDialogData} from '../position-list-dialogs';
import {RotData} from "./RotData";
import {RotSettingsService} from "../../../../../price-lists/rot-settings/rot-settings.service";
import {RotSettings} from "../../../../../price-lists/rot-settings/RotSettings";
import {ValidationErrors} from "../../../../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../../../../common/ValidationErrorsHelper";

@Component({
    selector: 'app-rot-data-dialog',
    templateUrl: './rot-data-dialog.component.html',
    styleUrls: ['./rot-data-dialog.component.css'],
    providers: [TranslatedSelectItemService, RotSettingsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RotDataDialogComponent implements OnInit {

    @Input()
    displayedDialogData: RotDataDialogData;

    @Input()
    validationErrors: ValidationErrors;

    @Output()
    onSubmit = new EventEmitter<RotData>();

    @Output()
    onCancel = new EventEmitter();

    rotData: RotData;
    rotSettings = new RotSettings();

    constructor(public positionService: PositionService,
                public rotSettingService: RotSettingsService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.rotData = new RotData(this.displayedDialogData.offerId, 0, 0);
        this.rotSettingService.getRotSettings().subscribe(
            rotSettings => {
                this.rotSettings = rotSettings;
                if (this.displayedDialogData.rotData == null) {
                    this.rotData = new RotData(this.displayedDialogData.offerId,
                        this.rotSettings.rotPercentValue, this.rotSettings.defaultGrossLimit);
                } else {
                    this.rotData = this.displayedDialogData.rotData.clone();
                }
                this.changeDetector.markForCheck();
        });
    }

    loadCurrentPercentValue() {
        this.rotData.rotPercentValue = this.rotSettings.rotPercentValue;
    }

    submitRot() {
        if (this.validateForm()) {
            this.onSubmit.emit(this.rotData);
        }
    }

    submitCancelRot() {
        if (this.displayedDialogData.rotData == null) {
            this.resetDialog();
        } else {
            this.onSubmit.emit(null);
        }
    }

    resetDialog() {
        this.onCancel.emit();
    }

    validateForm(): boolean {
        ValidationErrorsHelper.clearAllErrors(this.validationErrors);
        const validationErrors = {
            grossLimit: MultiValidator.of('error.rotDataDto.grossLimit')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withRangeValidator(0, this.rotSettings.absoluteGrossLimit, false, true)
                .validate(this.rotData.grossLimit),
        };

        this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
        return !ValidationErrorsHelper.validationErrorsPresent(validationErrors);
    }
}
