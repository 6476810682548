import {Directive, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../common/validation-errors';
import {ConfigEditorField} from '../../../offer/config-editor/config-editor-field';
import {ConfigEditorFieldContentProvider} from '../../../offer/config-editor/config-editor-field-content-provider';
import {
    WindowDesignerCatalogDependentOptionAction
} from '../../window-designer-catalog-dependent-option/data-form/window-designer-catalog-dependent-option';
import {ConfigDesignerCatalogDependentOptionsSet} from '../config-designer-catalog-dependent-options-set';
import {ConfigDesignerCatalogDependentOption} from './config-designer-catalog-dependent-option';

enum ConfigEditorFieldInputType {
    NUMBER = 'NUMBER',
    FLOAT_NUMBER = 'FLOAT_NUMBER',
    SELECT = 'SELECT'
}

@Directive()
export abstract class ConfigDesignerCatalogDependentOptionFormDependencyComponent implements OnInit {

    @Input()
    inputSelectedMap: Map<string, string[]>;

    @Input()
    item: ConfigDesignerCatalogDependentOptionsSet;

    @Input()
    options: ConfigDesignerCatalogDependentOption[];

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    fieldType: Observable<SelectItem[]>;

    @Input()
    dependentFieldType: Observable<SelectItem[]>;

    ConfigEditorFieldInputType = ConfigEditorFieldInputType;
    inputOptionsMap: Map<ConfigEditorField | string, Observable<SelectItem[]>>;
    selectedNewField: ConfigEditorField | string;
    selectedWhenMatched: WindowDesignerCatalogDependentOptionAction;

    readonly whenMatchedValues: Observable<SelectItem[]>;

    protected constructor(public readonly translate: TranslateService,
                          protected readonly translatedSelectItemService: TranslatedSelectItemService,
                          protected readonly configEditorFieldContentProvider: ConfigEditorFieldContentProvider,
                          public readonly isRequiredFieldsForm: boolean) {
        this.whenMatchedValues = this.translatedSelectItemService.buildSortedDropdown(WindowDesignerCatalogDependentOptionAction,
            'WINDOW_DESIGNER_CATALOG_DEPENDENT_OPTION_ACTION.', undefined);
    }

    ngOnInit(): void {
        this.inputOptionsMap = new Map<ConfigEditorField | string, Observable<SelectItem[]>>();
        this.options.forEach(option => {
            let {inputId, inputValue, whenMatched} = this.getInput(option);
            if (this.inputSelectedMap.has(this.getSelectedMapKey(inputId, whenMatched))) {
                let values = this.inputSelectedMap.get(this.getSelectedMapKey(inputId, whenMatched));
                if (!values.includes(inputValue)) {
                    values.push(inputValue);
                }
            } else {
                this.inputSelectedMap.set(this.getSelectedMapKey(inputId, whenMatched), [inputValue]);
                this.inputOptionsMap.set(inputId, this.configEditorFieldContentProvider.getItemsStream(inputId)
                    .pipe(map(items => items.map<SelectItem>(item => ({
                        label: item.label,
                        value: `${item.value}`
                    })))) || of<SelectItem[]>([]));
            }
        });
    }

    formatMultiselectValues(options: SelectItem[], selected: string) {
        let find = (options || []).find(option => `${option.value}` === `${selected}`);
        return find && find.label;
    }

    abstract getInput(option: ConfigDesignerCatalogDependentOption): {
        inputId: ConfigEditorField | string,
        inputValue: string,
        whenMatched: WindowDesignerCatalogDependentOptionAction | null
    };

    setMultiValue(inputId: ConfigEditorField | string, whenMatched: WindowDesignerCatalogDependentOptionAction, values: string[]) {
        this.inputSelectedMap.set(this.getSelectedMapKey(inputId, whenMatched), values);
        this.clearErrors();
    }

    remove(inputId: ConfigEditorField | string, whenMatched: WindowDesignerCatalogDependentOptionAction) {
        this.inputSelectedMap.delete(this.getSelectedMapKey(inputId, whenMatched));
        this.inputOptionsMap.delete(inputId);
        this.clearErrors();
    }

    add() {
        let inputId = this.selectedNewField;
        let whenMatched = this.selectedWhenMatched;
        this.inputSelectedMap.set(this.getSelectedMapKey(inputId, whenMatched), []);
        this.inputOptionsMap.set(inputId, this.configEditorFieldContentProvider.getItemsStream(inputId) || of<SelectItem[]>([]));
        this.inputOptionsMap.get(inputId).subscribe(a => console.log(JSON.stringify(a)))
        this.selectedNewField = null;
        this.clearErrors();
    }

    clearErrors() {
        if (this.isRequiredFieldsForm) {
            this.validationErrors['required'] = undefined;
        } else {
            this.validationErrors['dependent'] = undefined;
            this.validationErrors['options'] = undefined;
        }
    }

    handleFieldInputIdChange(field: ConfigEditorField | string): void {
        this.selectedNewField = field;
    }

    handleFieldInputWhenMatchedChange(whenMatched: WindowDesignerCatalogDependentOptionAction): void {
        this.selectedWhenMatched = whenMatched;
    }

    getValueInputType(field: ConfigEditorField | string): ConfigEditorFieldInputType {
        switch (field) {

            case ConfigEditorField.DIM_1_GREATER_THAN:
            case ConfigEditorField.DIM_1_LESS_THAN:
            case ConfigEditorField.DIM_2_GREATER_THAN:
            case ConfigEditorField.DIM_2_LESS_THAN:
            case ConfigEditorField.DIM_3_GREATER_THAN:
            case ConfigEditorField.DIM_3_LESS_THAN:
            case ConfigEditorField.DIM_4_GREATER_THAN:
            case ConfigEditorField.DIM_4_LESS_THAN:
            case ConfigEditorField.DIM_5_GREATER_THAN:
            case ConfigEditorField.DIM_5_LESS_THAN:
            case ConfigEditorField.DIM_6_GREATER_THAN:
            case ConfigEditorField.DIM_6_LESS_THAN:
                return ConfigEditorFieldInputType.NUMBER;
            case ConfigEditorField.AREA_GREATER_THAN:
            case ConfigEditorField.AREA_LESS_THAN:
                return ConfigEditorFieldInputType.FLOAT_NUMBER;
            default:
                break;
        }
        return ConfigEditorFieldInputType.SELECT;
    }

    getInputIdFromKey(key: string): ConfigEditorField | string {
        let [inputId] = key.split(';');
        return inputId;
    }

    getWhenMatchedFromKey(key: string): WindowDesignerCatalogDependentOptionAction {
        let [, whenMatched] = key.split(';');
        return whenMatched && WindowDesignerCatalogDependentOptionAction[whenMatched];
    }

    static decodeMapKey(key: string) {
        let [inputId, whenMatched] = key.split(';');
        return {
            inputId: inputId,
            whenMatched: whenMatched && WindowDesignerCatalogDependentOptionAction[whenMatched]
        };
    }

    getSelectedMapKey(inputId: ConfigEditorField | string, whenMatched: WindowDesignerCatalogDependentOptionAction): string {
        return `${inputId};${whenMatched}`;
    }
}
