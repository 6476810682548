import {SelectItem} from 'primeng/api/selectitem';

export class DataTableColumn {
    field: string;
    header: string;
    index: number;
    defaultVisible: boolean;
    sortable: boolean;
    filter: boolean;
    filterable: boolean;
    filterMatchMode: string;
    filterValues: SelectItem[];
    defaultFilterValue: SelectItem;
    sortOrder: number;

    constructor(field: string, header: string, index: number, defaultVisible: boolean, sortable = true,
                sortOrder = 0) {
        this.field = field;
        this.header = header;
        this.index = index;
        this.defaultVisible = defaultVisible;
        this.sortable = sortable;
        this.filter = false;
        this.filterable = sortable;
        this.sortOrder = sortOrder;
    }
}
