import {HttpClient} from '@angular/common/http';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../../../common/crud-common/crud.service';
import {CrudItem} from '../../../common/crud-common/crudItem';
import {Listing} from '../../../common/crud-common/crudItemList';
import {ListOfIds} from '../../ListOfIds';
import {WindowSystemMaterialLinks} from './window-system-material-links';

export abstract class SingleSystemCheckboxCrudService<Item extends CrudItem> implements CrudService<Item> {

    protected constructor(protected http: HttpClient) {
    }

    abstract getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                      sortColumn: string, sortOrder: number): Observable<Listing<Item & WindowSystemMaterialLinks>>;

    abstract getItem(itemId: number): Observable<Item>;

    abstract addItem(item: Item): Observable<number>;

    abstract editItem(itemId: number, item: Item): Observable<number>;

    getLinkedSystems(apiUrlWithEntityId: string): Observable<number[]> {
        return this.http.get<number[]>(apiUrlWithEntityId);
    }

    changeSystemsAssociation(apiUrl: string, id: number, material: string, link: string): Observable<string> {
        let url = `${apiUrl}/${id}/`;
        if (link === 'CHECKED') {
            url += 'unlink';
        } else {
            url += 'link';
        }
        if (material === 'GATES') {
            url += 'Gates';
        } else {
            url += `/${material}`;
        }
        return this.http.post(url, undefined,  {responseType: 'text'});
    }

    editLinks(apiUrl: string, newItemId: number, systemIds: ListOfIds): Observable<void> {
        return this.http.put<void>(`${apiUrl}/link/${newItemId}`, systemIds);
    }
}
