export class Glazing {
    glazingGlassQuantity: number;
    glass1id: number;
    glass2id: number;
    glass3id: number;
    glass4id: number;
    frame1id: number;
    frame2id: number;
    frame3id: number;
    isESG: boolean;

    static copyFields(from: Glazing, to: Glazing): void {
        to.glazingGlassQuantity = from.glazingGlassQuantity;
        to.glass1id = from.glass1id;
        to.glass2id = from.glass2id;
        to.glass3id = from.glass3id;
        to.glass4id = from.glass4id;
        to.frame1id = from.frame1id;
        to.frame2id = from.frame2id;
        to.frame3id = from.frame3id;
        to.isESG = from.isESG;
    }
}
