import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {Observable, of} from "rxjs";
import {MultilanguageField} from "../../../../../supportedLanguages";

@Pipe({
    name: 'detailedPricingPositionName'
})
export class DetailedPricingPositionNamePipe implements PipeTransform {

    constructor(private readonly translate: TranslateService) {
    }

    transform(name: MultilanguageField | string, keyLabel: boolean): Observable<string> {
        if (name == undefined) {
            return undefined;
        }
        return typeof name === 'string' ?
            keyLabel ? this.translate.get(name) : this.translate.get('OFFER.DETAILED_PRICING.CHARGE_TYPE.' + name) :
            of(name[this.translate.currentLang]);
    }

}
