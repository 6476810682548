<div class="new-form-60vw">
    <!-- COMPANY_NAME -->
    <div class="new-form-row">
        <app-input-text inputId="companyName"
                        label="{{ 'SUPPLIER-DETAILS.FORM.COMPANY-NAME' | translate }}"
                        [maxlength]="100"
                        [disabled]="!permissions.canCreateSupplier() && !permissions.canEditSupplier()"
                        [(validationMessageKey)]="validationErrors['companyName']"
                        [(ngModel)]="supplier.companyName" [required]="true"></app-input-text>
    </div>

    <!-- NAME -->
    <app-translation-fields *ngIf="permissions.isKoordynator() || permissions.isOpiekun()"
                            inputId="nameForSubsystems"
                            label="{{ 'SUPPLIER-DETAILS.FORM.NAME_FOR_SUBSYSTEM' | translate }}"
                            validationKeyPrefix="name" [validationErrors]="validationErrors"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="supplier.name"></app-translation-fields>

    <!-- DESCRIPTION -->
    <div class="new-form-row">
        <app-text-area inputId="description"
                       label="{{ 'SUPPLIER-DETAILS.FORM.DESCRIPTION' | translate }}"
                       [maxlength]="512"
                       [disabled]="!permissions.canCreateSupplier() && !permissions.canEditSupplier()"
                       [(validationMessageKey)]="validationErrors['description']"
                       [(ngModel)]="supplier.description"></app-text-area>
    </div>

    <!-- SYMBOL -->
    <div *ngIf="permissions.isKoordynator() || permissions.isOpiekun()" class="new-form-row">
        <app-input-text inputId="symbol"
                        label="{{ 'SUPPLIER-DETAILS.FORM.SYMBOL' | translate }}"
                        [maxlength]="100"
                        [disabled]="!permissions.canCreateSupplier() && !permissions.canEditSupplier()"
                        [(validationMessageKey)]="validationErrors['symbol']"
                        [(ngModel)]="supplier.symbol"></app-input-text>
    </div>

    <!-- ACTIVE -->
    <div class="new-form-row">
        <app-checkbox inputId="active"
                      label="{{ 'SUPPLIER-DETAILS.FORM.ACTIVE' | translate }}"
                      [disabled]="!permissions.canCreateSupplier() && !permissions.canEditSupplier()"
                      [(validationMessageKey)]="validationErrors['active']"
                      [(ngModel)]="supplier.active"></app-checkbox>
    </div>

    <!-- WINDOW SUPPLIER -->
    <div *ngIf="permissions.isKoordynator()" class="new-form-row">
        <app-checkbox inputId="windowSupplier"
                      label="{{ 'SUPPLIER-DETAILS.FORM.WINDOW_SUPPLIER' | translate }}"
                      [(validationMessageKey)]="validationErrors['windowSupplier']"
                      [ngModel]="supplier.windowSupplier" (ngModelChange)="handleWindowSupplierChange($event)"></app-checkbox>
    </div>

    <div *ngIf="permissions.isKoordynator() && supplier.windowSupplier" class="new-form-row">
        <app-checkbox inputId="simulateShipping"
                      label="{{ 'SUPPLIER-DETAILS.FORM.SIMULATE_SHIPPING' | translate }}"
                      [(validationMessageKey)]="validationErrors['simulateShipping']"
                      [(ngModel)]="supplier.simulateShipping"></app-checkbox>
    </div>

    <div *ngIf="permissions.isKoordynator()" class="new-form-row">
        <app-checkbox inputId="printRabate"
                      label="{{ 'SUPPLIER-DETAILS.FORM.PRINT_DEALER_RABATE' | translate }}"
                      [(validationMessageKey)]="validationErrors['printRabate']"
                      [(ngModel)]="supplier.printRabate"></app-checkbox>
    </div>

    <div *ngIf="permissions.isKoordynator()" class="new-form-row">
        <app-checkbox inputId="topMullionDimensions"
                      label="{{ 'SUPPLIER-DETAILS.FORM.TOP_MULLION_DIMENSIONS' | translate }}"
                      [(validationMessageKey)]="validationErrors['topMullionDimensions']"
                      [(ngModel)]="supplier.topMullionDimensions"></app-checkbox>
    </div>

    <div *ngIf="permissions.isKoordynator()" class="new-form-row">
        <app-checkbox inputId="alternativeProductionOrders"
                      label="{{ 'SUPPLIER-DETAILS.FORM.ALTERNATIVE_PRODUCTION_ORDERS' | translate }}"
                      [(validationMessageKey)]="validationErrors['alternativeProductionOrders']"
                      [(ngModel)]="supplier.alternativeProductionOrders"></app-checkbox>
    </div>

    <div *ngIf="permissions.isKoordynator()" class="new-form-row">
        <app-checkbox inputId="allowSimpleProfitMargins"
                      label="{{ 'SUPPLIER-DETAILS.FORM.ALLOW_SIMPLE_PROFIT_MARGINS' | translate }}"
                      [(validationMessageKey)]="validationErrors['allowSimpleProfitMargins']"
                      [(ngModel)]="supplier.allowSimpleProfitMargins"></app-checkbox>
    </div>

    <div *ngIf="permissions.isKoordynator()" class="new-form-row">
        <app-checkbox [(ngModel)]="supplier.applyDealerDiscountToBulkAddons"
                      [(validationMessageKey)]="validationErrors['applyDealerDiscountToBulkAddons']"
                      inputId="applyDealerDiscountToBulkAddons"
                      label="{{ 'SUPPLIER-DETAILS.FORM.APPLY_DEALER_DISCOUNT_TO_BULK_ADDONS' | translate }}"></app-checkbox>
    </div>
</div>
