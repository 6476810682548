import {DocumentTemplateType} from "./document-template-type.enum";
import {SinglePrintConfig} from "./single-print-config";
import {PrintDialogTab} from "./print-dialog-tab";
import {PrintableSection} from "./printable-section.enum";

export class PrintConfig {
    lastChosenDocumentTemplateType: DocumentTemplateType;
    lastSelectedTab: boolean;
    configsPerTemplateType: Map<DocumentTemplateType, SinglePrintConfig>;

    constructor(printableSection: PrintableSection, tab: PrintDialogTab) {
        this.configsPerTemplateType = new Map<DocumentTemplateType, SinglePrintConfig>();
        switch (printableSection) {
            case PrintableSection.OFFERS:
            case PrintableSection.ORDERS:
            case PrintableSection.PRODUCTION_ORDERS:
                switch (tab) {
                    case PrintDialogTab.REGULAR:
                        this.lastChosenDocumentTemplateType = DocumentTemplateType.MEDIUM;
                        this.configsPerTemplateType.set(DocumentTemplateType.MEDIUM, new SinglePrintConfig(DocumentTemplateType.MEDIUM));
                        this.configsPerTemplateType.set(DocumentTemplateType.FULL, new SinglePrintConfig(DocumentTemplateType.FULL));
                        break;
                    case PrintDialogTab.GLAMOUR:
                    case PrintDialogTab.EXPORT:
                        this.lastChosenDocumentTemplateType = null;
                        this.configsPerTemplateType.set(null, new SinglePrintConfig(null));
                        break;
                    default:
                        throw new Error('Unsupported PrintDialogTab ' + PrintDialogTab[tab]);
                }
                break;
            case PrintableSection.COMPLAINTS:
            case PrintableSection.DELIVERY_LISTS:
            case PrintableSection.CONJUNCTIONS:
                this.configsPerTemplateType.set(DocumentTemplateType.FULL, new SinglePrintConfig(DocumentTemplateType.FULL));
                break;
            default:
                throw new Error('Unsupported PrintableSection ' + PrintableSection[printableSection]);
        }
    }
}
