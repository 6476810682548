import {ChangeDetectorRef, Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {BlockUiController} from '../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {TranslationsService} from '../../translations/translations.service';
import {MailSettings} from '../mail-settings/mail-settings';
import {AbstractMailSettingsComponent} from '../mail-settings/mail-settings.component';
import {MailSettingsService} from '../mail-settings/mail-settings.service';

@Component({
    selector: 'app-venska-password-reset-mail-settings',
    templateUrl: '../mail-settings/mail-settings.component.html',
    styleUrls: ['../settings.component.css'],
    providers: [MailSettingsService, TranslationsService, TranslatedSelectItemService]
})
export class VenskaPasswordResetMailSettingsComponent extends AbstractMailSettingsComponent {

    constructor(private readonly mailSettingsService: MailSettingsService,
                translationsService: TranslationsService,
                translateService: TranslateService,
                translatedSelectItemService: TranslatedSelectItemService,
                blockUiController: BlockUiController,
                growlMessageController: GrowlMessageController,
                errors: CommonErrorHandler,
                changeDetector: ChangeDetectorRef) {
        super(translationsService, translateService, translatedSelectItemService, blockUiController,
            growlMessageController, errors, 'SETTINGS.SECTION.VENSKA_DATA_PASSWORD_RESET_MAIL_SETTINGS.HEADER',
            'LOGIN.PASSWORD_RESET.MAIL_SUBJECT', 'LOGIN.PASSWORD_RESET.MAIL_BODY', true, changeDetector);
        this.componentType = 'passwordReset';
    }

    protected loadMailSettings(): Observable<MailSettings> {
        return this.mailSettingsService.getPasswordResetMailSettings();
    }

    protected saveMailSettings(mailSettings: MailSettings): Observable<void> {
        return this.mailSettingsService.updatePasswordResetMailSettings(mailSettings);
    }
}
