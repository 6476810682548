import {CrudItem} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

export class ProfitMargin implements CrudItem {
    id: number;
    systemName: MultilanguageField;
    windowSystemMaterial: string;
    systemId: number;
    supplierName: MultilanguageField;
    subsystemGroupId: number;
    subsystemId: number;
    clientGroupId: number;
    clientId: number;
    value: number;
    valueString: string;
    groupValue: number;

    constructor() {
        this.id = undefined;
        this.systemName = new MultilanguageField();
        this.windowSystemMaterial = '';
        this.systemId = 0;
        this.supplierName = new MultilanguageField();
        this.subsystemGroupId = 0;
        this.subsystemId = 0;
        this.clientGroupId = 0;
        this.clientId = 0;
        this.value = undefined;
        this.groupValue = undefined;
    }
}

export enum ProfitMarginCategory {
    WINDOW_SYSTEM = 'WINDOW_SYSTEM',
    ADDON = 'ADDON',
    CONFIGURABLE_ADDON = 'CONFIGURABLE_ADDON',
    CATALOG_CHARGE = 'CATALOG_CHARGE'
}

export function normalizeProfitMarginValueString(input: string): string {
    if (input == undefined) {
        return input;
    }

    function replaceCommasWithDots(val: string): string {
        return val.replace(/,/g, '.');
    }

    function removeTrailingDots(val: string): string {
        return val.endsWith('.') ? val.replace(/.+$/, '') : val;
    }

    function removeLeadingZeros(val: string): string {
        return (val.length > 1 && val[1] !== '.') ? val.replace(/^0+/, '') : val;
    }

    function prependMissingZero(val: string): string {
        return (val.length > 1 && val.startsWith('.')) ? '0' + val : val;
    }

    const result = input.split(";")
        .filter(val => val !== '')
        .map(val => val.trim())
        .map(replaceCommasWithDots)
        .map(removeTrailingDots)
        .map(removeLeadingZeros)
        .map(prependMissingZero)
        .join(';');
    if (result.length === 0) {
        return undefined;
    }
    return result;
}

export function isValidProfitMarginValueString(input: string): boolean {
    if (input == undefined) {
        return true;
    }

    function isNotNegativeValue(val: number): boolean {
        return !Number.isNaN(val) && val >= 0;
    }

    return input.split(";")
        .map(val => val.trim())
        .filter(val => val !== '')
        .map(val => Number(val))
        .every(isNotNegativeValue);
}
