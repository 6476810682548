<button #buttonRef [attr.id]="buttonId" type="button" class="simple-button" (click)="handleClick($event)"
        (focus)="handleFocus($event)" [disabled]="disabled">
    <span class="simple-button-flex-container" [pTooltip]="formattedTooltip" [tooltipPosition]="tooltipPosition">
        <span *ngIf="icon" class="simple-button-icon material-icons" (click)="handleIconClick($event)">{{ icon }}</span>
        <span *ngIf="iconAwesome" class="simple-button-icon fa font-awesome {{iconAwesome}}" (click)="handleIconClick($event)"></span>
        <ng-container *ngIf="inlineLabel && label">
            <span *ngIf="!icon" class="simple-button-extra-text-padding"></span>
            <span class="simple-button-label">{{ label }}</span>
            <span class="simple-button-extra-text-padding"></span>
        </ng-container>
        <span class="simple-button-padding"></span>
    </span>
</button>
