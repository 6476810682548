export class Counts {
    offerCountsByStatus: { [status: string]: number };
    newWebshopOffers: number;
    complaintCountsByStatus: { [status: string]: number };
    paymentPackageCountsByStatus: { [status: string]: number };
    unprintedPaymentPackages: number;

    constructor() {
        this.offerCountsByStatus = {};
        this.complaintCountsByStatus = {};
        this.paymentPackageCountsByStatus = {};
    }
}
