import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WebshopRecommendedConfiguration} from '../../webshop-recommended-configuration';

@Component({
    selector: 'app-webshop-recommended-configuration-size-form',
    templateUrl: './webshop-recommended-configuration-size-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopRecommendedConfigurationSizeFormComponent implements OnChanges {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    addFilling = false;

    ngOnChanges(changes: SimpleChanges): void {
        const itemChange = changes['item'];
        if (itemChange != undefined && itemChange.currentValue != undefined) {
            this.addFilling = (itemChange.currentValue as WebshopRecommendedConfiguration)
                .productConfiguration.fillingMullionPosition != undefined;
        }
    }

    handleAddFillingChange(addFilling: boolean): void {
        this.addFilling = addFilling;
        if (!addFilling) {
            this.item.productConfiguration.fillingMullionPosition = undefined;
        }
    }

    get minFillingMullionPosition(): number {
        return this.item.productConfiguration.windowHeight != undefined
            ? Math.ceil(this.item.productConfiguration.windowHeight * 0.25)
            : 1;
    }

    get maxFillingMullionPosition(): number {
        return this.item.productConfiguration.windowHeight != undefined
            ? Math.floor(this.item.productConfiguration.windowHeight * 0.75)
            : 9999;
    }
}
