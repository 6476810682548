import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {forkJoin, Observable, of} from "rxjs";
import {finalize, map, mergeMap, tap} from "rxjs/operators";
import {WindowSystemType} from "../../../../window-designer/catalog-data/window-system-interface";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {MultilanguageField} from "../../../supportedLanguages";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, EntranceModelField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {ListOfIds} from "../../ListOfIds";
import {EntranceModelFieldUsage} from "../catalog-field-usage";
import {EntranceGlazingPackageService} from "../entrance-glazing-package/entrance-glazing-package.service";
import {ItemForCatalogLinking} from "../single-system-checkbox-crud/item-for-catalog-linking";
import {WindowSystemDefinitionService} from "../window-system-definition/window-system-definition.service";
import {EntranceModel} from "./entrance-model";
import {EntranceModelService} from "./entrance-model.service";

@Component({
    selector: 'app-entrance-model',
    templateUrl: './entrance-model.component.html',
    providers: [EntranceModelService, TranslatedSelectItemService, WindowSystemDefinitionService, EntranceGlazingPackageService]
})
export class EntranceModelComponent extends CrudCommonComponent<EntranceModel, EntranceModelService> implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };

    validateDataStep: WizardStepValidator;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    selectedWindowSystems: number[];
    entranceSystems: ItemForCatalogLinking[];
    availableGlazingPackages: SelectItem[];

    file2: File;

    editPermits: FieldLimitation[] = [];
    fieldUsage: EntranceModelFieldUsage;
    CatalogTab = CatalogTab;
    EntranceModelField = EntranceModelField;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private entranceModelService: EntranceModelService,
        private windowSystemDefinitionService: WindowSystemDefinitionService,
        private entranceGlazingPackageService: EntranceGlazingPackageService,
        private editCatalogPermitsService: EditCatalogPermitsService
    ) {
        super(injector, changeDetector, true, EntranceModelService, 'ENTRANCE_MODEL', 'EntranceModel');
        this.validateDataStep = () => this.validateForm();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.fieldUsage = new EntranceModelFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        const filters = {active: {value: 'true'}};
        forkJoin({
            entranceSystems: this.windowSystemDefinitionService.getSystemsForCatalogLinking(),
            glazingPackages: this.entranceGlazingPackageService.getItems(null, null, filters, null, null)
        }).subscribe({
            next: data => {
                this.entranceSystems = data.entranceSystems.filter(ws => ws.systemType === WindowSystemType.ENTRANCE.type);
                this.availableGlazingPackages = data.glazingPackages.data.map(gp =>
                    new SelectItemImpl(gp.name[this.translate.currentLang], gp.id));
            }, error: error => this.errors.handle(error)
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.ENTRANCE_MODELS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): EntranceModel {
        return new EntranceModel();
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.resetFiles();
        this.prepareDataForModel(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');

        this.validationErrors[`name[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.entranceModelDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]);

        this.validationErrors[`shortcut[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.entranceModelDto.shortcut[${this.translate.currentLang}]`)
                .withSizeValidator(0, 100)
                .validate(this.item.shortcut[this.translate.currentLang]);

        this.validationErrors['symbol'] =
            MultiValidator.of('error.entranceModelDto.symbol')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 50)
                .validate(this.item.symbol);

        this.validationErrors['sortIndex'] = MultiValidator.of('error.entranceModelDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        this.validationErrors['uw'] = MultiValidator.of('error.entranceModelDto.uw')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(0, 99)
            .validate(this.item.uw);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm().subscribe(validateOk => {
            if (validateOk) {
                if (this.isSaveInProgress()) {
                    return;
                }
                this.setSaveInProgress(true);
                let observable: Observable<number>;
                if (this.copyMode) {
                    observable = this.itemService.copy(this.selectedItem.id, this.item, this.file, this.file2);
                } else {
                    observable = this.itemService.saveEntranceModel(this.item, this.file, this.file2);
                }

                observable = observable.pipe(mergeMap(modelId => {
                    const ids = new ListOfIds();
                    ids.ids = this.selectedWindowSystems;
                    return this.entranceModelService.editLinks(modelId, ids);
                }));

                observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
                    complete: () => {
                        this.showSuccessMessage();
                        this.hideDetails();
                    },
                    error: err => {
                        this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                        this.changeDetector.markForCheck();
                    }
                });
            }
        });
    }

    prepareDataForModel(modelId: number) {
        forkJoin({
            model: (modelId != undefined ? this.entranceModelService.getItem(modelId) : of(new EntranceModel())),
            image: (modelId != undefined ? this.entranceModelService.getImage(modelId) : of<File>(undefined)),
            image2: (modelId != undefined ? this.entranceModelService.getImage2(modelId) : of<File>(undefined)),
            linkedSystems: (modelId != undefined ? this.entranceModelService.getLinkedSystems(modelId) : of<number[]>([]))
        }).subscribe({
            next: data => {
                this.newItem = modelId == undefined;
                this.item = data.model;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    this.item.shortcut = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.selectedWindowSystems = data.linkedSystems;
                this.file = data.image;
                this.file2 = data.image2;
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    showDialogToAdd() {
        this.resetFiles();
        this.validationErrors = {};
        this.prepareDataForModel(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.resetFiles();
            this.prepareDataForModel(this.selectedItem.id);
        }
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newItem = false;
        this.resetFiles();
        this.reloadDatatable();
    }

    resetFiles(): void {
        this.file = null;
        this.file2 = null;
    }

    onFile2Change(newFile: File): void {
        this.file2 = newFile;
        if (!newFile) {
            this.file2 = null;
            this.file2 = new File([], null);
        }
        this.changeDetector.markForCheck();
    }
}
