<div class="table-header">
    <div class="title">
        {{ 'TRANSLATIONS.UI_TRANSLATIONS.UI_TRANSLATIONS' | translate }}
    </div>

    <div class="table-header-buttons">
        <div class="table-header-buttons-container">
            <!-- SHOW / HIDE FILTERS -->
            <app-simple-button buttonId="toggle-filter-button"
                               label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                               icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
            <app-simple-button buttonId="download-all-button" label="{{ 'GENERAL.CSV_EXPORT' | translate }}"
                               icon="cloud_download" type="action"
                               (onClick)="handleDownloadAllClick()"></app-simple-button>
			<app-simple-button buttonId="import-csv-button"
							   label="{{ 'TRANSLATIONS.UI_TRANSLATIONS.CSV_IMPORT' | translate }}"
							   icon="publish" type="action" align="right"
							   (onClick)="csvInput.click()"></app-simple-button>
			<app-simple-button buttonId="auto-translate-button"
							   label="{{ 'TRANSLATIONS.UI_TRANSLATIONS.AUTO_TRANSLATE' | translate }}"
							   icon="auto_fix_high" type="main-action"
							   (onClick)="handleAutoTranslateUi()"></app-simple-button>
		</div>
    </div>
</div>
<div class="datatable-container">
    <p-table #table [value]="translations" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
             (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400" [totalRecords]="totalRecords"
             selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" [rowTrackBy]="rowTrackById" dataKey="key"
             [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [(selection)]="selectedItem">
        <ng-template pTemplate="header">
            <tr>
                <th [ngClass]="columnHeaderClasses('key')">
                    <span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.KEY' | translate }}</span>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="key"
                           (input)="table.filter(getInputEventValue($event), 'key', 'contains')">
                </th>
                <th [ngClass]="columnHeaderClasses('target')">
                    <span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.TARGET' | translate }}</span>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <p-multiSelect [options]="availableTargets | async"
                                       defaultLabel="{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.TARGET' | translate }}"
                                       appendTo="body" [displaySelectedLabel]="false" [ngModel]="selectedTargets"
                                       (ngModelChange)="handleTargetFilterChange($event)"></p-multiSelect>
                    </div>
                </th>
                <ng-container *ngFor="let lang of supportedLanguages">
                    <th [ngClass]="columnHeaderClasses('text.' + lang.code)">
                        <span>{{ lang.name | translate }}</span>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="{{ 'text.' + lang.code }}"
                               (input)="handleTextFilterChange(getInputEventValue($event), lang.code)">
                    </th>
                </ng-container>
                <th pSortableColumn="lastModifiedManually" [ngClass]="columnHeaderClasses('lastModifiedManually')">
                    <span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.LAST_MODIFIED' | translate }}</span>
                    <p-sortIcon field="lastModifiedManually"></p-sortIcon>
                    <div app-table-date-range-filter="lastModifiedManually"
                         [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                </th>
                <th pSortableColumn="lastModifiedAuto" [ngClass]="columnHeaderClasses('lastModifiedAuto')">
                    <span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.LAST_MODIFIED_AUTO' | translate }}</span>
                    <p-sortIcon field="lastModifiedAuto"></p-sortIcon>
                    <div app-table-date-range-filter="lastModifiedAuto"
                         [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-translation let-rowIndex="rowIndex">
            <tr [attr.tabindex]="rowIndex"
                (dblclick)="doOnRowSelect({data: translation, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                [pSelectableRow]="translation" [pSelectableRowIndex]="rowIndex"
                [app-table-keyboard-navigation]="translation"
                (app-table-keyboard-navigation-enter)="doOnRowSelect({data: translation, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                class="datatable-data">
                <td>{{ translation.key }}</td>
                <td>{{ ('TRANSLATION_TARGET.' + translation.target) | translate }}</td>
                <ng-container *ngFor="let lang of supportedLanguages">
                    <td class="ui-translation-value-column">{{ translation.text[lang.code] }}</td>
                </ng-container>
                <td>{{ translation.lastModifiedManually | momentCurrentLang | amDateFormat: 'L LT' }}</td>
                <td>{{ translation.lastModifiedAuto | momentCurrentLang | amDateFormat: 'L LT' }}</td>
            </tr>
        </ng-template>
    </p-table>
</div>
<ng-template #paginatorPageInfoTemplate>
    <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
</ng-template>
<p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
             styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
             [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
             [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>

<div class="dialog-window" *ngIf="editedItem != undefined">
    <p-dialog #dialog [visible]="editedItem != undefined" (visibleChange)="editedItem = undefined" [resizable]="false"
              [modal]="true" [focusOnShow]="false" [closable]="true" [closeOnEscape]="true">
        <p-header>
            <div class="p-dialog-title">
                {{ editedItem.key }} - {{ ('TRANSLATION_TARGET.' + editedItem.target) | translate }}
            </div>
        </p-header>
        <div class="scrolled-dialog scrolled-dialog-900">
            <div class="new-form-600">
                <div *ngFor="let lang of supportedLanguages" class="new-form-row">
                    <app-text-area [(ngModel)]="editedItem.text[lang.code]" [disabled]="!permissions.canEditTranslation()"
                                   [inputId]="'text_' + lang.code"
                                   label="{{ lang.name | translate }}"></app-text-area>
                </div>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button *ngIf="permissions.canEditTranslation()" label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                                   (onClick)="submit()"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="dialog.close($event)"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>

<input #csvInput type="file" style="display: none;" accept="text/csv" (change)="handleImportFileSelected($event)">
<p-dialog #importDialog [(visible)]="showImportChangesDialog" [modal]="true" [closeOnEscape]="true"
		  contentStyleClass="import-dialog">
	<p-header>
		<div class="p-dialog-title">
			{{ 'TRANSLATIONS.UI_TRANSLATIONS.CSV_IMPORT' | translate }}
		</div>
	</p-header>
	<p-table #changesTable [value]="importChanges">
		<ng-template pTemplate="header">
			<tr>
				<th style="vertical-align: middle">
					<app-tristate-checkbox [(ngModel)]="allSelectedImportRowsState"
										   (ngModelChange)="selectAllImportRowsChange()"></app-tristate-checkbox>
				</th>
				<th><span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.KEY' | translate }}</span></th>
				<th><span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.TARGET' | translate }}</span></th>
				<ng-container *ngFor="let lang of supportedLanguages">
					<th><span>{{ lang.name | translate }}</span></th>
				</ng-container>
			</tr>
		</ng-template>
		<ng-template pTemplate="body" let-translation let-rowIndex="rowIndex">
			<tr [attr.tabindex]="rowIndex"
				(dblclick)="doOnRowSelect({data: translation, originalEvent: {origin: getTableAdapter(changesTable), returnTabIndex: rowIndex}})"
				[pSelectableRow]="translation" [pSelectableRowIndex]="rowIndex"
				[app-table-keyboard-navigation]="translation"
				(app-table-keyboard-navigation-enter)="doOnRowSelect({data: translation, originalEvent: {origin: getTableAdapter(changesTable), returnTabIndex: rowIndex}})"
				[ngClass]="{ 'datatable-data': true, 'ui-translation-import-invalid': !translation.keyValid }">
				<td style="vertical-align: middle">
					<span class="flex-row">
						<app-checkbox *ngIf="translation.keyValid" [ngModel]="isSelectedImportRow(translation)"
									  (ngModelChange)="selectImportRow(translation)"></app-checkbox>
					</span>
				</td>
				<td>{{ translation.key }}</td>
				<td>{{ ('TRANSLATION_TARGET.' + translation.target) | translate }}</td>
				<ng-container *ngFor="let lang of supportedLanguages">
					<td [ngClass]="{ 'ui-translation-value-column': true, 'ui-translation-import-changed': isLanguageChangedOnImport(translation, lang.code) }">{{ translation.newTexts[lang.code] }}</td>
				</ng-container>
			</tr>
		</ng-template>
	</p-table>
	<p-footer>
		<div class="p-dialog-buttonpane">
			<app-simple-button *ngIf="permissions.canEditTranslation()" label="{{ 'GENERAL.SAVE' | translate }}"
							   type="main-action" [size]="40"
							   (onClick)="handleImportFileConfirmed($event)"></app-simple-button>
			<app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
							   (onClick)="importDialog.close($event)"></app-simple-button>
		</div>
	</p-footer>
</p-dialog>
