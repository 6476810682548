<p-dialog [resizable]="false" [(visible)]="visible" (onHide)="closeDialog()"
          class="dialog-window" [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">{{ dialogHeader | translate }}</div>
    </p-header>
    <div *ngIf="!skipSelect" class="select-element">
        <div class="new-form-600">
            <div class="new-form-row">
                <app-select
                    label="{{ selectLabel | translate }}"
                    [options]="sortedSelectItems" [checkAvailability]="true"
                    [noLongerAvailable]="selectedSelectValue | isNotAvailable:sortedSelectItems:true"
                    [ngModel]="selectedSelectValue"
                    (ngModelChange)="onSelectChange($event)">
                    <ng-template pTemplate="validationMessage">
                        <ng-container
                            *ngTemplateOutlet="unavailableTemplate; context: { id: selectedSelectValue, items: sortedSelectItems, canBeEmpty: true }"></ng-container>
                    </ng-template>
                </app-select>
            </div>
        </div>
    </div>
    <div class="tiles-container scrolled-dialog scrolled-dialog-90vh">
        <div class="tiles-grid new-form-field">
            <ng-container *ngFor="let tile of availableTiles">
                <ng-container *ngTemplateOutlet="tileTemplate; context: { tile: tile }"></ng-container>
            </ng-container>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="dialog-save" label="{{'GENERAL.OK' | translate }}" type="main-action"
                               [disabled]="selectedTileValue | isTileNotAvailable:availableTiles"
                               [size]="40" (onClick)="submit()"></app-simple-button>
            <app-simple-button buttonId="dialog-cancel" label="{{'GENERAL.CANCEL' | translate }}" type="cancel"
                               [size]="40" (onClick)="closeDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<ng-template #unavailableTemplate let-id="id" let-items="items" let-canBeEmpty="canBeEmpty">
    <div *ngIf="id | isNotAvailable: items: canBeEmpty" class="new-form-field-error-message">
        {{ 'ERROR.DESIGNER.NO_LONGER_AVAILABLE' | translate }}
    </div>
</ng-template>

<ng-template #tileTemplate let-tile="tile">
    <div class="new-form-field tile-element"
         [ngClass]="{selected: tile.tileValue === selectedTileValue, 'disabled-tile': !tile.available}"
         (click)="onTileSelect(tile.tileValue)"
         (dblclick)="onTileSelect(tile.tileValue); submit();">
        <ng-container *ngIf="tile.image as image">
            <img [src]="image | appSafeValue: 'url'">
            <div class="open-full-image" (click)="openFullImage(tile.tileValue)"><span class="material-icons">search</span></div>
        </ng-container>
        <ng-container *ngIf="!tile.image">
            <div class="image-placeholder" [ngStyle]="{'backgroundColor': tile.hex}"></div>
            <div class="open-full-image" (click)="openFullImage(tile.tileValue, tile.hex)"><span class="material-icons">search</span></div>
        </ng-container>
        <div>{{tile.tileName}}</div>
    </div>
</ng-template>
