import {HttpClient} from '@angular/common/http';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CrudService} from '../crud-common/crud.service';
import {Listing} from '../crud-common/crudItemList';
import {DataServiceHelper} from '../dataServiceHelper';
import {BaseAddonProfitMargin} from './baseAddonProfitMargin';

export abstract class BaseAddonProfitMarginService<T extends BaseAddonProfitMargin> implements CrudService<T> {

    private readonly apiUrl;

    protected constructor(protected http: HttpClient, private dataServiceHelper: DataServiceHelper, apiUrl: string) {
        this.apiUrl = apiUrl;
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number, dateFormat?): Observable<Listing<T>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<T>>(this.apiUrl, {params: params});
    }

    getItem(itemId: number): Observable<T> {
        return undefined;
    }

    addItem(item: T): Observable<number> {
        return undefined;
    }

    editItem(itemId: number, item: T): Observable<number> {
        return undefined;
    }

    getAllItems(clientId: number, clientGroupId: number,
                subsystemId: number, subsystemGroupId: number) {
        let filters: { [filterProperty: string]: FilterMetadata } = {};
        filters['clientId'] = {};
        filters['clientId'].value = clientId;
        filters['clientGroupId'] = {};
        filters['clientGroupId'].value = clientGroupId;
        filters['subsystemId'] = {};
        filters['subsystemId'].value = subsystemId;
        filters['subsystemGroupId'] = {};
        filters['subsystemGroupId'].value = subsystemGroupId;
        return this.getItems(null, null, filters, null, null);
    }

    save(dataList: BaseAddonProfitMargin[]) {
        let body = JSON.stringify(dataList);
        let headers = {};
        headers['Content-Type'] = 'application/json';
        let options = {headers: headers};
        return this.http.post(this.apiUrl, body, options);
    }

    abstract overrideGroupMemberMargins(entityId: number, profitMargins: T[]): Observable<void>;
}
