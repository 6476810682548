<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.MATERIALS.LIST' | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_MATERIAL.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.ADDON_MATERIAL.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-table #table [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true" [lazyLoadOnInit]="false" id="materialsTable"
             (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400" [totalRecords]="totalRecords"
             selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" [rowTrackBy]="rowTrackById" dataKey="id"
             [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [(selection)]="selectedItem">
        <ng-template pTemplate="header">
            <tr>
                <th>
                    {{ 'OFFER.FORM.ACTIONS' | translate }}
                </th>
                <th *ngIf="fieldUsage.show(MaterialField.NAME)" [ngClass]="columnHeaderClasses('name')" [pSortableColumn]="'name.' + translate.currentLang">
                    <span>{{ 'ADDON_MATERIAL.FORM.NAME' | translate }}</span>
                    <p-sortIcon [field]="'name.' + translate.currentLang"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="name"
                           (input)="table.filter(getInputEventValue($event), 'name', 'contains')">
                </th>
                <th pSortableColumn="sortIndex" [ngClass]="columnHeaderClasses('sortIndex')">
                    <span>{{ 'ADDONS.FORM.SORT_INDEX' | translate }}</span>
                    <p-sortIcon field="sortIndex"></p-sortIcon>
                </th>
                <th *ngIf="fieldUsage.show(MaterialField.SHORTCUT)" [ngClass]="columnHeaderClasses('shortcut')" [pSortableColumn]="'shortcut.' + translate.currentLang">
                    <span>{{ 'ADDON_MATERIAL.FORM.SHORTCUT' | translate }}</span>
                    <p-sortIcon [field]="'shortcut.' + translate.currentLang"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="shortcut"
                           (input)="table.filter(getInputEventValue($event), 'shortcut', 'contains')">
                </th>
                <th *ngIf="fieldUsage.show(MaterialField.SYMBOL)" [ngClass]="columnHeaderClasses('symbol')" pSortableColumn="symbol">
                    <span>{{ 'ADDON_MATERIAL.FORM.SYMBOL' | translate }}</span>
                    <p-sortIcon field="symbol"></p-sortIcon>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="symbol"
                           (input)="table.filter(getInputEventValue($event), 'symbol', 'contains')">
                </th>
                <th *ngIf="fieldUsage.show(MaterialField.ACTIVE)" [ngClass]="columnHeaderClasses('active')" pSortableColumn="active">
                    <span>{{ 'ADDON_MATERIAL.FORM.STATUS' | translate }}</span>
                    <p-sortIcon field="active"></p-sortIcon>
                    <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                         (click)="handleInputFilterClick($event)">
                        <app-select inputId="activeFilter" [options]="filterActiveItems | async"
                                    [ngModel]="filterActiveState" (ngModelChange)="handleFilterActiveChange($event)">
                            <ng-template pTemplate="label"></ng-template>
                        </app-select>
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-rowIndex="rowIndex">
            <tr [attr.tabindex]="rowIndex"
                (dblclick)="doOnRowSelect({data: item, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                [pSelectableRow]="item" [pSelectableRowIndex]="rowIndex"
                [app-table-keyboard-navigation]="item"
                (app-table-keyboard-navigation-enter)="doOnRowSelect({data: item, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                class="datatable-data">
                <td>
                    <div class="table-row-action-buttons">
                        <app-simple-button [buttonId]="'editColorLinks_' + item.id" icon="palette" type="main-action" [size]="36"
                                           [pTooltip]="'ADDON_MATERIAL.ACTIONS.TOOLTIPS.EDIT_COLOR_LINKS' | translate" [inlineLabel]="false"
                                           (onClick)="editColorLinks(item)"></app-simple-button>
                    </div>
                </td>
                <td *ngIf="fieldUsage.show(MaterialField.NAME)">{{ item.name[translate.currentLang] }}</td>
                <td>{{ item.sortIndex }}</td>
                <td *ngIf="fieldUsage.show(MaterialField.SHORTCUT)">{{ item.shortcut[translate.currentLang] }}</td>
                <td *ngIf="fieldUsage.show(MaterialField.SYMBOL)">{{ item.symbol }}</td>
                <td *ngIf="fieldUsage.show(MaterialField.ACTIVE)">{{ (item.active ? 'ADDON_MATERIAL.FORM.ACTIVE' : 'ADDON_MATERIAL.FORM.INACTIVE') | translate }}</td>
            </tr>
        </ng-template>
    </p-table>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="rowsPerPageOptions"></p-paginator>
</div>

<app-wizard *ngIf="newItem" header="{{ 'ADDON_MATERIAL.ADD-MATERIAL-TITLE' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'ADDON_MATERIAL.STEPS.DATA' | translate }}" [id]="STEPS.DATA.id"
                     [validate]="STEPS.DATA.validate">
        <app-material-form [editPermits]="editPermits" [item]="item" [file]="file"
                           (fileChange)="onFileChange($event)" [validationErrors]="validationErrors"></app-material-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'ADDON_MATERIAL.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS.id"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="configSystems" [linkableEntity]="LinkableEntity.CONFIG_ADDONS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.CONFIG-SYSTEMS"
                                [(ngModel)]="selectedConfigSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'ADDON_MATERIAL.COPY-MATERIAL-TITLE' : 'ADDON_MATERIAL.EDIT-MATERIAL-TITLE') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">
    <app-wizard-step label="{{ 'ADDON_MATERIAL.STEPS.DATA' | translate }}" [id]="STEPS.DATA.id"
                     [validate]="STEPS.DATA.validate">
        <app-material-form [editPermits]="editPermits" [item]="item" [file]="file"
                           (fileChange)="onFileChange($event)" [validationErrors]="validationErrors"></app-material-form>
    </app-wizard-step>
    <app-wizard-step label="{{ 'ADDON_MATERIAL.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS.id"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="configSystems" [linkableEntity]="LinkableEntity.CONFIG_ADDONS"
                                [showToggleAllButton]="true" toggleAllLabel="LINK-SELECTIONS.SELECT-ALL.CONFIG-SYSTEMS"
                                [(ngModel)]="selectedConfigSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>

<app-material-color-images-form *ngIf="showColorLinks"
                                [material]="selectedMaterial" [colors]="colors"
                                (onClose)="showColorLinks = false; selectedMaterial = null">
</app-material-color-images-form>
