import {ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import * as _ from 'underscore';
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {Listing} from "../../../../../../common/crud-common/crudItemList";
import {SelectItemImpl} from '../../../../../../common/service/select.item.impl';
import {SelectItemExtended} from "../../../../../../form-inputs/inputs/select/select.component";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {BulkPredefGlazingPackageChangeDialogData} from "../position-list-dialogs";
import {
    GraspGlazingCategoryService
} from "../../../../../window-system/grasp-glazing-categories/grasp-glazing-category.service";
import {
    GraspDistanceFrameCategoryService
} from "../../../../../window-system/grasp-distance-frame-category/grasp-distance-frame-category.service";
import {
    GraspGlazingPackageService
} from "../../../../../window-system/grasp-glazing-package/grasp-glazing-package.service";
import {SelectItem} from "primeng/api/selectitem";
import {GraspGlazingCategory} from "../../../../../window-system/grasp-glazing-categories/grasp-glazing-category";
import {
    GraspDistanceFrameCategory
} from "../../../../../window-system/grasp-distance-frame-category/grasp-distance-frame-category";
import {GraspGlazingPackage} from "../../../../../window-system/grasp-glazing-package/grasp-glazing-package";
import {BulkChangeFrontendWarning} from "../BulkChangeFrontendWarning";
import {ValidationErrorsHelper} from "../../../../../../common/ValidationErrorsHelper";
import {Position} from "../position";
import {PositionType} from "../../../../AbstractPosition";
import {DrawingData} from "../../../../../../../window-designer/drawing-data/drawing-data";

@Component({
    selector: 'app-bulk-predef-glazing-package-change',
    templateUrl: './bulk-predef-glazing-package-change.component.html',
    styleUrls: ['../../../../../shared-styles.css'],
    providers: [GraspGlazingCategoryService, GraspDistanceFrameCategoryService, GraspGlazingPackageService, TranslateService]
})
export class BulkPredefGlazingPackageChangeComponent implements OnInit {

    private static readonly LOAD_DATA_ID = 'BulkPredefGlazingPackageChangeComponent loadData';

    @Input()
    dialogData: BulkPredefGlazingPackageChangeDialogData;

    @Output()
    onSubmit = new EventEmitter();

    @Output()
    onClose = new EventEmitter();

    allPackages: GraspGlazingPackage[];
    packagesForAllSystems: GraspGlazingPackage[];

    quantities: SelectItem[];
    categories: SelectItemExtended[];
    frameCategories: SelectItemExtended[];
    glazingPackages: SelectItemExtended[];

    selectedQuantity: number;
    selectedCategory: number;
    selectedFrameCategory: number;
    selectedGlazingPackage: GraspGlazingPackage;

    private readonly dialogHideHelper = new OnceFlag();
    dataReady = false;
    validationErrors: { [field: string]: string } = {};
    updatedDatas: { offerPosition: Position, mappedData: DrawingData }[] = [];

    constructor(private graspGlazingCategoryService: GraspGlazingCategoryService,
                private graspDistanceFrameCategoryService: GraspDistanceFrameCategoryService,
                private graspGlazingPackageService: GraspGlazingPackageService,
                private translate: TranslateService,
                private blockUiController: BlockUiController,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.loadData();
        let copyOfferPosition = (offerPosition: Position) => {
            let position = new Position(offerPosition.name, PositionType.SYSTEM, null, null, offerPosition.quantity,
                null, null, null, null, null, null, null, null,
                null, null, null,  offerPosition.offerId, null);
            position.id = offerPosition.id;
            position.printOrder = offerPosition.printOrder;
            return {offerPosition: position, mappedData: JSON.parse(offerPosition.data)};
        };
        this.updatedDatas =
            this.dialogData.selectedOfferPositions.filter(p => p.type === PositionType.SYSTEM).map(copyOfferPosition);
    }

    private loadData(): void {
        this.blockUiController.block(BulkPredefGlazingPackageChangeComponent.LOAD_DATA_ID);
        let extractData = <T>(listingDto: Listing<T>) => listingDto.data;
        const selectedSystemIds = [...new Set(this.dialogData.selectedOfferPositions.map(pos => pos.windowSystemId))];
        forkJoin({
            graspGlazingCategories: this.graspGlazingCategoryService.getActiveItems().pipe(map(extractData)),
            graspDistanceFrameCategories: this.graspDistanceFrameCategoryService.getActiveItems(),
            graspGlazingPackagesForAllSystems: this.graspGlazingPackageService.getActiveItemsForSystems(selectedSystemIds, true).pipe(map(extractData)),
            graspGlazingPackages: this.graspGlazingPackageService.getActiveItemsForSystems(selectedSystemIds, false).pipe(map(extractData)),
        }).subscribe(catalogData => {
            this.categories = catalogData.graspGlazingCategories.map(item => this.formatGraspGlazingOption(item));
            this.frameCategories = catalogData.graspDistanceFrameCategories.map(item => this.formatGraspGlazingOption(item));
            this.allPackages = catalogData.graspGlazingPackages;
            this.packagesForAllSystems = catalogData.graspGlazingPackagesForAllSystems;
            this.quantities = _.uniq(catalogData.graspGlazingPackages.map(p => p.glazing.glazingGlassQuantity)).sort((a, b) => a - b)
                .map((item: number) => this.formatGlazingPackageQuantity(item));
            this.dataReady = true;
            this.changeDetector.markForCheck();
            this.blockUiController.unblock(BulkPredefGlazingPackageChangeComponent.LOAD_DATA_ID);
        });
    }

    private formatGraspGlazingOption(glazingItem: GraspGlazingCategory | GraspDistanceFrameCategory | GraspGlazingPackage,
                                     boldPackages?: GraspGlazingPackage[]): SelectItemExtended {
        return {
            label: glazingItem.name[this.translate.currentLang],
            value: glazingItem.id,
            available: glazingItem.active,
            bold: boldPackages ? (boldPackages.findIndex(bp => bp.id === glazingItem.id) >= 0) : false
        };
    }

    private formatGlazingPackageQuantity(quantity: number): SelectItem {
        return {
            label: quantity.toString(),
            value: quantity,
            available: true
        };
    }

    public setSelectedQuantity(quantity) {
        if (quantity) {
            this.selectedQuantity = quantity;
            this.setGlazingPackageOptions();
            delete this.validationErrors['quantity'];
        }
    }

    public setSelectedCategory(itemId) {
        if (itemId) {
            this.selectedCategory = itemId;
            this.setGlazingPackageOptions();
            delete this.validationErrors['category'];
        }
    }

    public setSelectedFrameCategory(itemId) {
        if (itemId) {
            this.selectedFrameCategory = itemId;
            this.setGlazingPackageOptions();
            delete this.validationErrors['frameCategory'];
        }
    }

    public setSelectedGlazingPackage(itemId) {
        if (itemId) {
            this.selectedGlazingPackage = this.allPackages.find(gp => gp.id === itemId);
            delete this.validationErrors['glazingPackage'];
        }
    }

    private setGlazingPackageOptions() {
        if (this.selectedQuantity && this.selectedCategory && this.selectedFrameCategory) {
            const filterCallback = gp => gp.glazingCategoryId === this.selectedCategory
                && gp.frameCategoryId === this.selectedFrameCategory && gp.glazing.glazingGlassQuantity === this.selectedQuantity;
            const allPackages = this.allPackages.filter(filterCallback);
            const boldPackages = this.packagesForAllSystems.filter(filterCallback);
            const glazingPackages = allPackages.map(gp => this.formatGraspGlazingOption(gp, boldPackages));
            glazingPackages.sort((gp1, gp2) => gp1.bold && !gp2.bold ? -1 : 1);
            if (glazingPackages.some(gp => !gp.bold)) {
                const firstNonBoldIndex = glazingPackages.findIndex(gp => !gp.bold);
                const someHeaderItem = new SelectItemImpl(this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_SOME'), null, false);
                glazingPackages.splice(firstNonBoldIndex, 0, someHeaderItem);
            }
            if (glazingPackages.some(gp => gp.bold)) {
                glazingPackages.unshift(new SelectItemImpl(this.translate.instant('OFFER.POSITIONS.DIALOGS.BULK_CHANGE.AVAILABLE_IN_ALL'), null, false));
            }
            this.glazingPackages = glazingPackages;
            this.changeDetector.markForCheck();
        }
    }

    private updateOffers(): {success: boolean, warnings: BulkChangeFrontendWarning[]} {
        if (!this.validateForm()) {
            return {success: false, warnings: []};
        }

        let warnings = [];
        for (let updateData of this.updatedDatas) {
            for (let window of updateData.mappedData.windows) {
                for (let subwindow of window.subWindows) {
                    for (let area of subwindow.areasSpecification) {
                        area.glazingCategoryId = this.selectedCategory;
                        area.glazingFrameCategoryId = this.selectedFrameCategory;
                        area.glazingPackageId = this.selectedGlazingPackage.id;
                        area.glazing = this.selectedGlazingPackage.glazing;
                    }
                }
            }
            updateData.offerPosition.data = JSON.stringify(updateData.mappedData);
        }
        this.dialogData.modifiedPositions = this.updatedDatas.map(updateData => updateData.offerPosition);
        return {success: true, warnings: warnings};
    }

    public submitDialog(): void {
        let result = this.updateOffers();
        if (result.success) {
            this.dialogHideHelper.call(() => this.onSubmit.emit(result.warnings));
        }
    }

    public resetDialog(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    protected validateForm(): boolean {
        this.clearValidationErrors();

        if (this.selectedQuantity == undefined) {
            this.validationErrors['quantity'] = 'error.offerPosition.bulkChange.glazing_package.quantity_not_empty';
        }

        if (this.selectedCategory == undefined) {
            this.validationErrors['category'] =
                'error.offerPosition.bulkChange.glazing_package.category_not_empty';
        }

        if (this.selectedFrameCategory == undefined) {
            this.validationErrors['frameCategory'] = 'error.offerPosition.bulkChange.glazing_package.frame_category_not_empty';
        }

        if (this.selectedGlazingPackage == undefined) {
            this.validationErrors['glazingPackage'] = 'error.offerPosition.bulkChange.glazing_package.package_not_empty';
        }

        return !ValidationErrorsHelper.validationErrorsPresent(this.validationErrors);
    }

    private clearValidationErrors(): void {
        this.validationErrors = {};
    }
}
