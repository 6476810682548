import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {Subscription} from 'rxjs';
import {Permissions} from "../../auth/permission.service";
import {GrowlMessageController} from '../../common/growl-message/growl-message-controller';
import {SavedFilter} from "../../common/saved-filter/saved-filter";
import {SavedFilterViewId} from "../../common/saved-filter/saved-filter-view-id";
import {SavedFilterComponent} from "../../common/saved-filter/saved-filter.component";
import {SecondLevelMenu} from "../../common/second-level-menu/SecondLevelMenu";
import {SavedShownColumns} from '../../common/service/data.table.column.builder';
import {NavigationLink} from "../../NavigationLink";
import {SidenavController} from '../../sidenav-controller';
import {ComplaintListComponent} from "./complaint/complaint-list/complaint-list.component";
import {DeliveryListsComponent} from './delivery-list/delivery-lists.component';
import {HasSavedFilter} from './has-saved-filter';
import {OffersComponent} from "./offers/offers.component";
import {OrdersComponent} from "./orders/orders.component";
import {PaymentPackageComponent} from './payment-package/payment-package.component';
import {ProductionOrdersComponent} from "./production-orders/production-orders.component";
import {ShippingSimulationListComponent} from './shipping/shipping-simulation/shipping-simulation-list/shipping-simulation-list.component';

type OfferMenuOption = 'offer' | 'order' | 'production-order' | 'payment-package' | 'delivery-lists' | 'shipping-simulation'
    | 'complaint' | 'offer-search';

@Component({
    selector: 'app-window-system',
    templateUrl: './main-offers.component.html',
    styleUrls: ['../../second-level-menu.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainOffersComponent implements OnInit, OnDestroy, SecondLevelMenu<OfferMenuOption> {

    showMenu = true;
    showMenuSubscription: Subscription;
    sideNavItems: NavigationLink<OfferMenuOption>[] = [];
    visibileSideNavItems: NavigationLink<OfferMenuOption>[] = [];
    activeSubComponent: OfferMenuOption;
    counter: number;
    readonly currentTableFilters: { [component: string]: string } = {};
    readonly originalTableFilters: { [component: string]: string } = {};
    readonly currentTableVisibleColumns: { [component: string]: string } = {};
    readonly originalTableVisibleColumns: { [component: string]: string } = {};

    @ViewChild(OffersComponent) offersComponent: OffersComponent;
    @ViewChild(OrdersComponent) ordersComponent: OrdersComponent;
    @ViewChild(ProductionOrdersComponent) productionOrdersComponent: ProductionOrdersComponent;
    @ViewChild(PaymentPackageComponent) paymentPackageComponent: PaymentPackageComponent;
    @ViewChild(DeliveryListsComponent) deliveryListsComponent: DeliveryListsComponent;
    @ViewChild(ShippingSimulationListComponent) shippingSimulationListComponent: ShippingSimulationListComponent;
    @ViewChild(ComplaintListComponent) complaintListComponent: ComplaintListComponent;
    @ViewChild(SavedFilterComponent) savedFilterComponent: SavedFilterComponent;

    constructor(public permissions: Permissions,
                private sidenavController: SidenavController,
                private growlMessageController: GrowlMessageController,
                private changeDetector: ChangeDetectorRef,
                private translateService: TranslateService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngOnInit() {
        this.prepareSideNavItems();
        this.showMenuSubscription = this.sidenavController.visibilityChange.subscribe(visible => {
            this.showMenu = visible;
            this.changeDetector.markForCheck();
        });
        this.route.paramMap.subscribe(params => {
            const component = (params.get('component') as OfferMenuOption) || 'offer';
            this.switchToSubComponent(component);
        });
    }

    ngOnDestroy(): void {
        this.showMenuSubscription.unsubscribe();
    }

    setActiveSubComponentByCssClass(cssClass: OfferMenuOption): void {
        if (this.activeSubComponent === cssClass) {
            return;
        }
        let navLinkIndex = this.sideNavItems.findIndex(item => item.cssClass === cssClass);
        if (navLinkIndex >= 0) {
            this.counter = undefined;
            this.activeSubComponent = cssClass;
            this.changeDetector.markForCheck();
        }
    }

    prepareSideNavItems() {
        this.sideNavItems = [];

        this.sideNavItems.push(new NavigationLink('', 'offer', "", {roles: []}, 'NAVIGATION.ITEMS.OFFERS.OFFERS'));
        this.sideNavItems.push(new NavigationLink('', 'order', "", {roles: ['ROLE_OPERATOR', 'ROLE_OPIEKUN', 'ROLE_KOORDYNATOR', 'ROLE_SPRZEDAWCA', 'ROLE_HANDLOWIEC']}, 'NAVIGATION.ITEMS.OFFERS.ORDERS'));
        this.sideNavItems.push(new NavigationLink('', 'payment-package', '', {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.OFFERS.PAYMENT_PACKAGES'));
        this.sideNavItems.push(new NavigationLink('', 'production-order', "", {roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']}, 'NAVIGATION.ITEMS.OFFERS.PRODUCTION_ORDERS'));
        this.sideNavItems.push(new NavigationLink('', 'delivery-lists', '', {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.OFFERS.DELIVERY_LISTS'));
        this.sideNavItems.push(new NavigationLink('', 'shipping-simulation', '', {roles: []}, 'NAVIGATION.ITEMS.SHIPPING_SIMULATION_LIST'));
        this.sideNavItems.push(new NavigationLink('', 'complaint', '', {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']}, 'NAVIGATION.ITEMS.COMPLAINT_LIST'));
        this.sideNavItems.push(new NavigationLink('', 'offer-search', '', {roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']}, '', undefined, true));
        this.sideNavItems = this.sideNavItems.filter(item => {
            return this.isPermitted(item.roles);
        });
        this.visibileSideNavItems = this.sideNavItems.filter(item => {
            return !item.hidden;
        });
    }

    navigateToSubComponent(item: OfferMenuOption, extras?: NavigationExtras): void {
        this.router.navigate(['/features/offer', {component: item}], extras);
    }

    switchToSubComponent(item: OfferMenuOption): void {
        this.setActiveSubComponentByCssClass(item);

        if (this.savedFilterComponent != null) {
            this.savedFilterComponent.loadFiltersForView(this.getSavedFilterViewId());
        }
    }

    isActiveSubComponent(cssClass: OfferMenuOption): boolean {
        return this.activeSubComponent === cssClass;
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    updateCounter(counter: number): void {
        this.counter = counter;
    }

    updateCurrentTableFilters(event: LazyLoadEvent): void {
        let data = {
            sortOrder: event.sortOrder,
            sortField: event.sortField,
            filters: event.filters
        };

        this.currentTableFilters[this.getSavedFilterViewId()] = JSON.stringify(data);
    }

    updateOriginalTableFilters(event: LazyLoadEvent): void {
        let data = {
            sortOrder: event.sortOrder,
            sortField: event.sortField,
            filters: event.filters
        };

        this.originalTableFilters[this.getSavedFilterViewId()] = JSON.stringify(data);

        this.unselectFilterIfCurrentTableFiltersOrVisibleColumnsHaveBeenChanged();
    }

    unselectFilterIfCurrentTableFiltersOrVisibleColumnsHaveBeenChanged() {
        if (this.savedFilterComponent && this.savedFilterComponent.selectedFilter &&
            (this.currentTableFilters[this.getSavedFilterViewId()] !== this.savedFilterComponent.selectedFilter.filter ||
                this.currentTableVisibleColumns[this.getSavedFilterViewId()] !== this.savedFilterComponent.selectedFilter.visibleColumns)) {
            this.savedFilterComponent.selectedFilterModified();
        }
    }

    updateTableVisibleColumns(columns: SavedShownColumns): void {
        this.originalTableVisibleColumns[this.getSavedFilterViewId()] = JSON.stringify(columns);
        this.currentTableVisibleColumns[this.getSavedFilterViewId()] = JSON.stringify(columns);
        this.unselectFilterIfCurrentTableFiltersOrVisibleColumnsHaveBeenChanged();
    }

    tableFilterSelected(filter: SavedFilter): void {
        this.currentTableVisibleColumns[this.getSavedFilterViewId()] = filter.visibleColumns;
        const filters = filter.filter != undefined ? JSON.parse(filter.filter) : undefined;
        const columns = filter.visibleColumns != undefined ? JSON.parse(filter.visibleColumns) : undefined;
        this.getActiveComponent().applySavedFilterAndVisibleColumns(filters, columns);
    }

    tableFilterUnselected(viewId: string): void {
        this.currentTableFilters[viewId] = this.originalTableFilters[viewId];
        this.currentTableVisibleColumns[viewId] = this.originalTableVisibleColumns[viewId];
        if (this.getSavedFilterViewId() === viewId) {
            const filter = this.originalTableFilters[viewId] != undefined ? JSON.parse(this.originalTableFilters[viewId]) : undefined;
            const columns = this.originalTableVisibleColumns[viewId] != undefined
                ? JSON.parse(this.originalTableVisibleColumns[viewId])
                : undefined;
            this.getActiveComponent().applySavedFilterAndVisibleColumns(filter, columns);
        }
    }

    private getActiveComponent(): HasSavedFilter {
        switch (this.activeSubComponent) {
            case 'offer':
                return this.offersComponent;
            case 'order':
                return this.ordersComponent;
            case 'production-order':
                return this.productionOrdersComponent;
            case 'payment-package':
                return this.paymentPackageComponent;
            case 'delivery-lists':
                return this.deliveryListsComponent;
            case 'shipping-simulation':
                return this.shippingSimulationListComponent;
            case 'complaint':
                return this.complaintListComponent;
            default:
                return null;
        }
    }

    tableFilterCreateSuccess(): void {
        this.growlMessageController.info('SAVED_FILTER.CREATE_DIALOG.SUCCESS');
    }

    tableFilterCreateError(): void {
        this.growlMessageController.error('SAVED_FILTER.CREATE_DIALOG.ERROR');
    }

    tableFilterDeleteSuccess(): void {
        this.growlMessageController.info('SAVED_FILTER.DELETE_DIALOG.SUCCESS');
    }

    tableFilterDeleteError(): void {
        this.growlMessageController.error('SAVED_FILTER.DELETE_DIALOG.SUCCESS');
    }

    getSavedFilterViewId(): string {
        switch (this.activeSubComponent) {
            case 'offer':
                return SavedFilterViewId.OFFERS;
            case 'order':
                return SavedFilterViewId.ORDERS;
            case 'production-order':
                return SavedFilterViewId.PRODUCTION_ORDERS;
            case 'payment-package':
                return SavedFilterViewId.PAYMENT_PACKAGES;
            case 'delivery-lists':
                return SavedFilterViewId.DELIVERY_LISTS;
            case 'shipping-simulation':
                return SavedFilterViewId.SHIPPING_SIMULATIONS;
            case 'complaint':
                return SavedFilterViewId.COMPLAINTS;
            default:
                return null;
        }
    }

    handleOfferSearch(searchedValue: string): void {
        if (!!searchedValue) {
            this.navigateToSubComponent('offer-search', {queryParams: {searchedValue: searchedValue}});
        }
    }
}
