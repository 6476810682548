import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ListOfIds} from '../../ListOfIds';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {DistanceFrame, DistanceFramePosition} from './distanceFrame';
import {DistanceFrameListing} from './distanceFrame-list';

@Injectable()
export class DistanceFrameService extends SingleSystemCheckboxCrudService<DistanceFrame> {

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<DistanceFrameListing>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<DistanceFrameListing>>('distanceframes', {params: params});
    }

    addItem(frame: DistanceFrame): Observable<number> {
        return this.http.post('distanceframe', frame, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId('distanceframe', response)));
    }

    editItem(frameId: number, frame: DistanceFrame): Observable<number> {
        return this.http.put<void>(`distanceframe/${frameId}`, frame).pipe(map(() => frameId));
    }

    saveFrame(frame: DistanceFrame): Observable<number> {
        if (frame.id) {
            return this.editItem(frame.id, frame);
        }
        return this.addItem(frame);
    }

    getItem(frameId: number): Observable<DistanceFrame> {
        return this.http.get<DistanceFrame>(`distanceframe/${frameId}`);
    }

    copyFrame(id: number, frame: DistanceFrame): Observable<number> {
        return this.http.post(`distanceframe/${id}/copy`, frame, {observe: 'response'}).pipe(
            map(response => this.dataServiceHelper.getNewItemId('distanceframe', response)));
    }

    getAllActiveDistanceFrames(): Observable<Listing<DistanceFrameListing>> {
        let filters = {active: {value: 'true', matchMode: ''}};
        return this.getItems(0, null, filters, 'sortIndex', 1);
    }

    editLinks(apiUrl: string, newItemId: number, systemIds: ListOfIds): Observable<void> {
        return this.http.put<void>(`distanceframe/link/${newItemId}`, systemIds.ids);
    }

    getDistanceFramesForWindowSystem(windowSystemId: number, selectedFrameIds: number[] = null, readOnly = false): Observable<DistanceFrame[]> {
        const params = selectedFrameIds ? {selectedFrameIds: selectedFrameIds.toString()} : {};
        params['readOnly'] = readOnly;
        return this.http.get<DistanceFrame[]>(`distanceframe/forWindowSystem/${windowSystemId}`, {params: params});
    }

    validate(frame: DistanceFrame): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('distanceframe/validate', frame));
    }

    setDistanceFramePositions(distanceFrameId: number, positions: DistanceFramePosition[]) {
        return this.http.put<void>(`distanceframe/${distanceFrameId}/positions`, positions);
    }

    getDistanceFrameIdsUsedInWindowSystemDefaultGlazingPackage(): Observable<{ id: number, windowSystemIds: number[] }[]> {
        return this.http.get<{ id: number, windowSystemIds: number[] }[]>('distanceframe/listUsedInDefaultGlazingPackages');
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('distanceframes/names', {params: params});
    }
}
