import * as _ from "underscore";
import {Glazing} from "../../../../../window-designer/catalog-data/glazing";
import {AreaSpecification} from "../../../../../window-designer/drawing-data/AreaSpecification";
import {FillingType} from "../../../../../window-designer/drawing-data/FillingType";
import {GlazingHelper} from '../../../../../window-designer/glazing-helper';
import {WindowCommonData} from "../../../../../window-designer/window-common-data";
import {DistanceFrame} from "../../../window-system/distance-frame/distanceFrame";
import {GlassWithPosition} from "../../../window-system/glass/glassWithPositions";
import {GlazingBead} from "../../../window-system/glazing-bead/glazing-bead";
import {DecorativeFillingWithColors} from '../DecorativeFillingWithColors';
import {OtherFillingWithColors} from "../OtherFillingWithColors";
import {WindowDesignerComponent} from "../window-designer/window-designer.component";

export enum GlazingBeadMatchingMode {
    AREA, COMMON, BULK_GLASS_CHANGE
}

export class GlazingBeadUtils {

    public static filterGlazingBeadsMatchingWidth(availableGlazingBeads: GlazingBead[],
                                                  data: AreaSpecification | WindowCommonData | Glazing,
                                                  mode: GlazingBeadMatchingMode,
                                                  glasses: GlassWithPosition[],
                                                  distanceFrames: DistanceFrame[],
                                                  decorativeFillings?: DecorativeFillingWithColors[],
                                                  fillings?: OtherFillingWithColors[]): GlazingBead[] {
        let filteredGlazingBeads = [];
        let glazingWidths = [];
        switch (mode) {
            case GlazingBeadMatchingMode.AREA:
                glazingWidths =
                    this.getGlazingWidthForArea(data as AreaSpecification, glasses, distanceFrames, decorativeFillings,
                        fillings);
                break;
            case GlazingBeadMatchingMode.COMMON:
                glazingWidths =
                    this.getTotalGlazingWidthForSidebarMode(data as WindowCommonData, glasses, distanceFrames,
                        decorativeFillings, fillings);
                break;
            case GlazingBeadMatchingMode.BULK_GLASS_CHANGE:
                glazingWidths = this.getTotalGlazingWidthForBulkGlassChange(data as Glazing, glasses, distanceFrames);
                break;
            default:
                throw new Error("Unsupported mode: " + mode);

        }

        if (glazingWidths.length > 0) {
            let minWidth = Math.min(...glazingWidths);
            let maxWidth = Math.max(...glazingWidths);
            if (availableGlazingBeads) {
                filteredGlazingBeads = availableGlazingBeads.filter(glazingBead => {
                    let fromToRange = GlazingBead.parseAcceptableFillingWidth(glazingBead);
                    return fromToRange.from <= minWidth && fromToRange.to >= maxWidth;
                });
            }
        }
        return filteredGlazingBeads;
    }

    private static getGlazingWidthForArea(area: AreaSpecification,
                                          glasses: GlassWithPosition[],
                                          distanceFrames: DistanceFrame[],
                                          decorativeFillings: DecorativeFillingWithColors[],
                                          fillings: OtherFillingWithColors[]) {
        let widths = [];
        switch (area.filling.type) {
            case FillingType.GLASS:
                let width = 0;
                let glassIds = [];
                for (let i = 1; i < area.glazing.glazingGlassQuantity + 1; i++) {
                    let nthGlass = GlazingHelper.getNthGlass(area.glazing, i);
                    if (nthGlass) {
                        glassIds.push(nthGlass);
                    }
                }

                let frameIds = [];
                for (let i = 1; i < area.glazing.glazingGlassQuantity; i++) {
                    let nthFrame = GlazingHelper.getNthFrame(area.glazing, i);
                    if (nthFrame) {
                        frameIds.push(nthFrame);
                    }
                }

                let glassOccurrences = _.countBy(glassIds, _.identity);
                if (glasses) {
                    for (let glass of glasses) {
                        if (glassIds.indexOf(glass.id) !== -1) {
                            width += glass.thickness * glassOccurrences[glass.id];
                        }
                    }
                }

                let frameOccurrences = _.countBy(frameIds, _.identity);
                if (distanceFrames) {
                    for (let frame of distanceFrames) {
                        if (frameIds.indexOf(frame.id) !== -1) {
                            width += frame.thickness * frameOccurrences[frame.id];
                        }
                    }
                }
                widths.push(width);
                break;
            case FillingType.DECORATIVE_FILLING:
                if (decorativeFillings) {
                    let usedFilling = this.find(decorativeFillings, area.filling.decorativeFillingId);
                    if (usedFilling) {
                        widths.push(usedFilling.thickness);
                    }
                }
                break;
            case FillingType.NO_FILLING:
                widths.push(area.filling.width);
                break;
            case FillingType.FILLING:
                let usedFilling = this.find(fillings, area.filling.fillingId);
                if (usedFilling) {
                    widths.push(usedFilling.thickness);
                }
                break;
        }
        return widths;
    }

    private static getTotalGlazingWidthForSidebarMode(commonData: WindowCommonData, glasses: GlassWithPosition[],
                                                      distanceFrames: DistanceFrame[],
                                                      decorativeFillings: DecorativeFillingWithColors[],
                                                      fillings: OtherFillingWithColors[]) {
        let widths = [];
        switch (commonData.fillingType) {
            case FillingType.GLASS:
                let width = 0;
                let glassIds = [];
                for (let i = 1; i < commonData.glazingGlassQuantity + 1; i++) {
                    let nthGlass = GlazingHelper.getNthGlass(commonData, i);
                    if (nthGlass) {
                        glassIds.push(nthGlass);
                    }
                }

                let frameIds = [];
                for (let i = 1; i < commonData.glazingGlassQuantity; i++) {
                    let nthFrame = GlazingHelper.getNthFrame(commonData, i);
                    if (nthFrame) {
                        frameIds.push(nthFrame);
                    }
                }

                let glassOccurrences = _.countBy(glassIds, _.identity);
                if (glasses) {
                    for (let glass of glasses) {
                        if (glassIds.indexOf(glass.id) !== -1) {
                            width += glass.thickness * glassOccurrences[glass.id];
                        }
                    }
                }

                let frameOccurrences = _.countBy(frameIds, _.identity);
                if (distanceFrames) {
                    for (let frame of distanceFrames) {
                        if (frameIds.indexOf(frame.id) !== -1) {
                            width += frame.thickness * frameOccurrences[frame.id];
                        }
                    }
                }
                widths.push(width);
                break;
            case FillingType.NO_FILLING:
                widths.push(commonData.fillingWidth);
                break;
            case FillingType.DECORATIVE_FILLING:
                if (decorativeFillings) {
                    let usedFilling = this.find(decorativeFillings, commonData.decorativeFillingId);
                    if (usedFilling) {
                        widths.push(usedFilling.thickness);
                    }
                }
                break;
            case FillingType.FILLING:
                let usedFilling = this.find(fillings, commonData.fillingId);
                if (usedFilling) {
                    widths.push(usedFilling.thickness);
                }
                break;
        }
        return widths;
    }

    private static getTotalGlazingWidthForBulkGlassChange(glazing: Glazing,
                                                          glasses: GlassWithPosition[],
                                                          distanceFrames: DistanceFrame[]): number[] {
        let glassIds = [];
        let frameIds = [];
        for (let i = 1; i <= WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX; i++) {
            if (glazing['glass' + i + 'id'] != undefined) {
                glassIds.push(glazing['glass' + i + 'id']);
            }
            if (glazing['frame' + i + 'id'] != undefined && i < WindowDesignerComponent.GLAZING_GLASS_NUMBER_MAX) {
                frameIds.push(glazing['frame' + i + 'id']);
            }
        }

        let width = 0;
        for (let glassId of glassIds) {
            for (let glass of glasses) {
                if (glass.id === glassId) {
                    width += glass.thickness;
                }
            }
        }
        for (let frameId of frameIds) {
            for (let frame of distanceFrames) {
                if (frame.id === frameId) {
                    width += frame.thickness;
                }
            }
        }
        return [width];
    }

    private static find(array: any[], id: number): any {
        return array.find(el => el.id === id);
    }

    public static resetIfNeeded(area: AreaSpecification, filteredGlazingBeads: GlazingBead[]): boolean {
        let firstAvailableId = filteredGlazingBeads.length > 0 ? filteredGlazingBeads[0].id : null;
        if (area.glazingBead.id == null) {
            area.glazingBead.id = firstAvailableId;
            return true;
        } else {
            let isPreviouslySelectedInvalid = filteredGlazingBeads.find(glazingBead => glazingBead.id === area.glazingBead.id) == null;
            if (isPreviouslySelectedInvalid) {
                area.glazingBead.id = firstAvailableId;
                return true;
            }
        }
        return false;
    }

    public static resetIfNeededSidebarMode(commonData: WindowCommonData, filteredGlazingBeads: GlazingBead[]): void {
        let firstAvailableId = filteredGlazingBeads.length > 0 ? filteredGlazingBeads[0].id : null;
        if (commonData.glazingBeadId == null) {
            commonData.glazingBeadId = firstAvailableId;
        } else {
            let isPreviouslySelectedInvalid = filteredGlazingBeads.find(glazingBead => glazingBead.id === commonData.glazingBeadId) == null;
            if (isPreviouslySelectedInvalid) {
                commonData.glazingBeadId = firstAvailableId;
            }
        }
    }
}
