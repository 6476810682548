import {Component, EventEmitter, Input, Output} from "@angular/core";

@Component({
    selector: 'app-sidebar-hider',
    templateUrl: './sidebar-hider.component.html',
    styleUrls: ['./sidebar-hider.component.css']
})
export class SidebarHiderComponent {

    @Input() showSidebar: boolean;
    @Input() sidebarOnlyMode: boolean;

    @Output() onClick = new EventEmitter();
}
