import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataServiceHelper} from '../../../../common/dataServiceHelper';
import {ListOfIds} from '../../../ListOfIds';
import {Supplier} from '../../../supplier/supplier';
import {AbstractPositionList} from '../../AbstractPosition';
import {WindowEditorPositionData} from '../../window-editor/window-editor-offer-interfaces';
import {GlobalConfigAddonAddResult} from './position-list/bulk-change-confirmation/globalConfigAddonAddResult';
import {BulkChange} from './position-list/BulkWindowSystemChange';
import {FinalizeBulkChange} from './position-list/FinalizeBulkChange';
import {Position, PositionSortGroup} from './position-list/position';
import {UpsellingPropositionResults} from './position-list/UpsellingPropositionResults';
import {AddonProperties} from './window-properties/addon-properties';
import {AssemblyProperties} from './window-properties/assembly-properties';
import {GateProperties} from './window-properties/gate-properties';
import {TransportProperties} from './window-properties/transport-properties';
import {WindowProperties} from './window-properties/window-properties';
import {ConfigProperties} from "./window-properties/config-properties";

export interface RemovePositionsResult {
    affectedSuppliers: number[];
    affectedSortGroups: Set<PositionSortGroup>;
}

@Injectable()
export class PositionService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    private static isNumeric(n: any): boolean {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<AbstractPositionList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder, {
            'quantity': PositionService.isNumeric,
            'profitMargin': PositionService.isNumeric,
            'rabate': PositionService.isNumeric,
            'buyPrice.vatPercent': PositionService.isNumeric,
            'sellPrice.vatPercent': PositionService.isNumeric,
            'dealerDiscount': PositionService.isNumeric,
            'tempDealerDiscount': PositionService.isNumeric,
            'basePrice': PositionService.isNumeric
        });
        return this.http.get<AbstractPositionList>('offerposition', {params: params});
    }

    getItem(itemId: number): Observable<Position> {
        return this.http.get<Position>(`offerposition/${itemId}`);
    }

    getChildOfferPositions(positionId: number): Observable<WindowEditorPositionData[]> {
        return this.http.get<WindowEditorPositionData[]>(`offerposition/childOfferPositions/${positionId}`);
    }

    getOfferPositionForWindowEditor(itemId: number): Observable<WindowEditorPositionData> {
        return this.http.get<WindowEditorPositionData>(`offerposition/${itemId}/forWindowEditor`);
    }

    saveItem(offerPosition: Position, images?: {
        thumbnail?: string,
        technical?: string,
        thumbnailRender?: string,
        conjunction?: string,
        regular?: string
    }): Observable<number> {
        let formData = new FormData();
        formData.append('offerPositionDto', new Blob([JSON.stringify(offerPosition)], {type: 'application/json'}));
        if (images != undefined) {
            formData.append('positionImagesDto', new Blob([JSON.stringify(images)], {type: 'application/json'}));
        }
        if (offerPosition.id) {
            return this.http.put<void>(`offerposition/${offerPosition.id}`, formData)
                .pipe(map(() => offerPosition.id));
        }
        return this.http.post<void>(`offerposition/${offerPosition.offerId}`, formData, {observe: 'response'})
            .pipe(map(response => this.dataServiceHelper.getNewItemId('offerposition', response)));
    }

    addConfigAddonsGlobally(configResults: GlobalConfigAddonAddResult[], offerId: number, position: Position,
                            isUpselling: boolean): Observable<void> {
        let formData = new FormData();
        formData.append('configResults', new Blob([JSON.stringify(configResults)], {
            type: 'application/json'
        }));
        formData.append('configPosition', new Blob([JSON.stringify(position)], {
            type: 'application/json'
        }));
        formData.append('isUpselling', new Blob([JSON.stringify(isUpselling)], {
            type: 'application/json'
        }));
        return this.http.post<void>(`offerposition/addConfigAddonsGlobally/${offerId}`, formData);
    }

    getPreview(id: number): Observable<string> {
        return this.http.get(`offerposition/${id}/preview`, {responseType: 'text'});
    }

    removeItems(offerId: number, offerPositionIds: number[], deleteChildren = false): Observable<RemovePositionsResult> {
        return this.http.request('DELETE', `offerposition/${offerId}`, {
            body: offerPositionIds,
            observe: 'response',
            params: {
                deleteChildren: '' + deleteChildren
            }
        }).pipe(
            map(response => {
                return {
                    affectedSuppliers: response.headers.get('AffectedSuppliers').split(',').map(id => +id),
                    affectedSortGroups: new Set<PositionSortGroup>(response.headers.get('AffectedSortGroups').split(',')
                        .map(sortGroup => PositionSortGroup[sortGroup]))
                };
            }));
    }

    copyItem(offerPositionId: number): Observable<number[]> {
        return this.http.post<void>(`offerposition/${offerPositionId}/copy`, undefined, {observe: 'response'}).pipe(
            map(response => response.headers.get('AffectedSuppliers').split(',').map(id => +id)));
    }

    changePrice(offerPositionId: number, price: number, priceIsNet: boolean, priceIsBuy: boolean): Observable<void> {
        return this.http.put<void>(`offerposition/${offerPositionId}/changeprice`, {
            price: price,
            priceIsNet: priceIsNet,
            priceIsBuy: priceIsBuy
        });
    }

    recalculatePriceWithRabate(offerId: number, offerPositionIds: number[], rabate: number): Observable<void> {
        return this.http.put<void>(`offerposition/changeRabate/${offerId}`, {
            rabate: rabate,
            offerPositionIds: offerPositionIds
        });
    }

    recalculatePriceWithRabateForOffer(id: number, rabate: number): Observable<void> {
        return this.http.put<void>(`offers/${id}/changeRabate`, {
            rabate: rabate
        });
    }

    recalculatePriceWithMargin(offerId: number, offerPositionIds: number[], margin: number): Observable<void> {
        return this.http.put<void>(`offerposition/changeMargin/${offerId}`, {
            margin: margin,
            offerPositionIds: offerPositionIds
        });
    }

    getPropertiesForMultipleWindows(offerPositionIds: number[]): Observable<WindowProperties[]> {
        return this.http.post<WindowProperties[]>('offerposition/windowProperties', offerPositionIds);
    }

    getPropertiesForMultipleGates(offerPositionIds: number[]): Observable<GateProperties[]> {
        return this.http.post<GateProperties[]>('offerposition/gateProperties', offerPositionIds);
    }

    getPropertiesForMultipleAddons(offerPositionIds: number[]): Observable<AddonProperties[]> {
        return this.http.post<AddonProperties[]>('offerposition/addonProperties', offerPositionIds);
    }

    getPropertiesForMultipleAssemblies(offerPositionIds: number[]): Observable<AssemblyProperties[]> {
        return this.http.post<AssemblyProperties[]>('offerposition/assemblyProperties', offerPositionIds);
    }

    getPropertiesForMultipleTransports(offerPositionIds: number[]): Observable<TransportProperties[]> {
        return this.http.post<TransportProperties[]>('offerposition/transportProperties', offerPositionIds);
    }

    getPropertiesForMultipleConfigs(offerPositionIds: number[]): Observable<ConfigProperties[]> {
        return this.http.post<ConfigProperties[]>('offerposition/configProperties', offerPositionIds);
    }

    beginBulkChange(offerPositions: Position[], bulkChangeType: string): Observable<BulkChange> {
        const headers = this.dataServiceHelper.prepareHeaders({bulkChangeType});
        return this.http.post<object>('offerposition/beginBulkChange', offerPositions, {headers}).pipe(
            map(response => BulkChange.fromJSON(response)));
    }

    addPreviewToBulkChange(bulkChangeId: number, offerPositionId: number, svgs: {
        thumbnail: string,
        technical: string,
        thumbnailRender: string,
        conjunction: string,
        regular: string
    }): Observable<void> {
        return this.http.post<void>(`offerposition/addPreviewToBulkChange/${bulkChangeId}/${offerPositionId}`, svgs);
    }

    finalizeBulkChange(bulkChangeId: number, excludedPositionIds: number[], offerId: number): Observable<void> {
        let body = new FinalizeBulkChange(bulkChangeId, excludedPositionIds);
        return this.http.post<void>(`offerposition/finalizeBulkChange/accept/${offerId}`, body);
    }

    rejectBulkChange(bulkChangeId: number): Observable<void> {
        return this.http.post<void>(`offerposition/finalizeBulkChange/reject/${bulkChangeId}`, undefined);
    }

    moveUp(offerPositionId: number) {
        return this.http.put<void>(`offerposition/${offerPositionId}/moveOfferPositionUp`, undefined);
    }

    moveDown(offerPositionId: number) {
        return this.http.put<void>(`offerposition/${offerPositionId}/moveOfferPositionDown`, undefined);
    }

    getPrintOrderRange(offerPositionId: number) {
        return this.http.get<{ from: number, to: number }>(`offerposition/${offerPositionId}/printOrderRange`);
    }

    setPrintOrder(offerPositionId: number, printOrder: number) {
        return this.http.put<void>(`offerposition/${offerPositionId}/setOfferPositionPrintOrder`, printOrder);
    }

    getSuppliersForOffer(offerId: number): Observable<Supplier[]> {
        return this.http.get<Supplier[]>(`offerposition/suppliersForOffer/${offerId}`);
    }

    getSuppliersForComplaint(complaintId: number): Observable<Supplier[]> {
        return this.http.get<Supplier[]>(`offerposition/suppliersForComplaint/${complaintId}`);
    }

    getPositionSortGroups(offerId: number): Observable<{ [sortGroup in PositionSortGroup]: boolean }> {
        return this.http.get<any>(`offerposition/sortGroups/${offerId}`);
    }

    updatePricings(offerId: number, selectedPositionsIds: ListOfIds): Observable<void> {
        return this.http.put<void>(`offerposition/${offerId}/updatePositionPricings`, selectedPositionsIds);
    }

    updateAllPricings(offerId: number): Observable<void> {
        return this.http.put<void>(`offerposition/${offerId}/updatePricings`, undefined);
    }

    updateSellVat(offerId: number, vatPercentage: number): Observable<void> {
        return this.http.put<void>(`offerposition/${offerId}/updateSellVat`, {
            vat: vatPercentage
        });
    }

    setOtherInfo(offerPositionId: number, otherInfo: string) {
        return this.http.put<void>(`offerposition/${offerPositionId}/otherInfo`, otherInfo);
    }

    getAvailableUpsellings(offerId: number): Observable<UpsellingPropositionResults[]> {
        return this.http.get<UpsellingPropositionResults[]>(`offerposition/getAvailableUpsellings/${offerId}`);
    }

    applyUpselling(offerId: number, propositionId: number, systemIds: number[]): Observable<number[]> {
        return this.http.post<number[]>(`offerposition/applyUpselling/${offerId}/${propositionId}`, new ListOfIds(systemIds));
    }

    updateOffersValidityDate(offerId: number): Observable<void> {
        return this.http.put<void>(`offers/${offerId}/updateValidityDates`, undefined);
    }

    disableValidation(offerPositionId: number): Observable<void> {
        return this.http.put<void>(`offerposition/${offerPositionId}/disableValidation`, undefined);
    }
}
