import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WindowSystemWebShopInfo} from './window-system-web-shop-info';

@Injectable()
export class WindowSystemWebShopInfoService {

    private static readonly API_URL = 'windowSystemWebShopInfo';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getWindowSystemWebShopInfo(windowSystemId: number): Observable<WindowSystemWebShopInfo> {
        return this.http.get<object>(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}`).pipe(
            map(WindowSystemWebShopInfo.fromJSON));
    }

    getImage(windowSystemId: number): Observable<string> {
        return this.http.get(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}/image`, {
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        });
    }

    getImageAsFile(windowSystemId: number): Observable<File> {
        return this.http.get(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getLargeImage(windowSystemId: number): Observable<string> {
        return this.http.get(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}/largeImage`, {
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        });
    }

    getLargeImageAsFile(windowSystemId: number): Observable<File> {
        return this.http.get(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}/largeImage`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getLargeImageMobileAsFile(windowSystemId: number): Observable<File> {
        return this.http.get(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}/largeImageMobile`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getSliderImage(windowSystemId: number, sliderImageId: number): Observable<string> {
        return this.http.get(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}/sliderImage/${sliderImageId}`, {
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        });
    }

    getSliderImageAsFile(windowSystemId: number, sliderImageId: number): Observable<File> {
        return this.http.get(`${WindowSystemWebShopInfoService.API_URL}/${windowSystemId}/sliderImage/${sliderImageId}`, {
            observe: 'response',
            responseType: 'text',
            headers: {
                Accept: this.dataServiceHelper.getFileAcceptHeader()
            }
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    validateWebShopInfo(windowSystemWebShopInfo: WindowSystemWebShopInfo): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${WindowSystemWebShopInfoService.API_URL}/validateWebShopInfo`, windowSystemWebShopInfo));
    }

    setWebShopInfoImage(windowSystemId: number, copiedWindowSystemId: number, image: File, largeImage: File, largeImageMobile: File): Observable<void> {
        let formData = new FormData();
        if (image != undefined) {
            formData.append('image', image);
        }
        if (largeImage != undefined) {
            formData.append('largeImage', largeImage);
        }
        if (largeImageMobile != undefined) {
            formData.append('largeImageMobile', largeImage);
        }
        return this.http.post<void>(
            `${WindowSystemWebShopInfoService.API_URL}/setImageForWindowSystem/${windowSystemId}/${copiedWindowSystemId}`, formData);
    }

    addSliderImageForWindowSystem(windowSystemId: number, sliderImage: File): Observable<void> {
        let formData = new FormData();
        formData.append('sliderImage', sliderImage);
        return this.http.post<void>(`${WindowSystemWebShopInfoService.API_URL}/addSliderImageForWindowSystem/${windowSystemId}`, formData);
    }

    removeSliderImageFromWindowSystemWebShopInfo(windowSystemId: number, sliderImageFileId: number): Observable<void> {
        return this.http.delete<void>(`${WindowSystemWebShopInfoService.API_URL}/removeSliderImageFromWindowSystem/${windowSystemId}/${sliderImageFileId}`);
    }
}
