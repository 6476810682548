<div class="flex-row-container">
    <div *ngIf="showMenu" class="second-level-menu">
        <div class="title">
            <div class="text">{{ 'NAVIGATION.ITEMS.CATALOG_CREATOR.LIST' | translate }}</div>
        </div>

        <div class="navigation-element" id="nav_catalog_options"
             [class.active]="isActiveSubComponent('optionList')" (click)="navigateToSubComponent('optionList')">
            <div class="name">{{ 'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_OPTION.LIST' | translate }}</div>
        </div>

        <div class="menu-separator"></div>

        <div class="title">
            <div class="text">{{ 'NAVIGATION.ITEMS.CATALOG_CREATOR.CATALOG_PROPERTY.LIST' | translate }}</div>
        </div>

        <div *ngFor="let target of categoryTargets" class="navigation-element" id="{{ 'nav_catalog_category_target_' + target }}"
             [class.active]="isActiveSubComponent(target)" (click)="navigateToSubComponent(target)">
            <div class="name">{{ getTargetName(target) | translate }}</div>
        </div>
    </div>

    <div class="content" [ngClass]="{'second-level-menu-hidden': !showMenu}">
        <ng-container *ngFor="let target of categoryTargets">
            <app-catalog-property *ngIf="isActiveSubComponent(target)" [target]="target"></app-catalog-property>
        </ng-container>
        <app-catalog-option *ngIf="isActiveSubComponent('optionList')"></app-catalog-option>
    </div>
</div>
