<div class="new-form-60vw">
    <div class="new-form-row">
        <h3>{{ "SUPPLIER-DETAILS.FORM.FOR_PROD" | translate }}</h3>
    </div>

    <div class="new-form-row">
        <div>
            <h3>{{ 'SUPPLIER-DETAILS.FORM.HEADER_COMMENT' | translate }}</h3>
            <p-editor #supplierHeaderComment styleClass="supplier-annotation-editor printable-editor"
                      [(ngModel)]="supplier.headerComment"></p-editor>
        </div>
    </div>

    <div class="new-form-row">
        <div>
            <h3>{{ 'SUPPLIER-DETAILS.FORM.FOOTER_COMMENT' | translate }}</h3>
            <p-editor #supplierFooterComment styleClass="supplier-annotation-editor printable-editor"
                      [(ngModel)]="supplier.footerComment"></p-editor>
        </div>
    </div>

    <br><br>

    <div class="new-form-row">
        <h3>{{ "SUPPLIER-DETAILS.FORM.FOR_OFFER" | translate }}</h3>
    </div>
    <div class="new-form-row" *ngFor="let lang of supportedLanguages">
        <div>
            <h3>{{ 'SUPPLIER-DETAILS.FORM.OFFER_COMMENT' | translate: {lang: lang.code} }}</h3>
            <p-editor #offerComment [id]="lang.code"
                      styleClass="supplier-annotation-editor printable-editor"
                      [(ngModel)]="supplier.offerComment[lang.code]"></p-editor>
        </div>
    </div>
</div>
