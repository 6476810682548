import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {UserGroup} from './user-group';
import {UserGroupList} from './user-group-list';

@Injectable()
export class UserGroupService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getUserGroups(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                  sortColumn: string, sortOrder: number): Observable<UserGroupList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<UserGroupList>('usergroups', {params: params});
    }

    add(userGroup: UserGroup): Observable<void> {
        return this.http.post<void>('usergroup', userGroup);
    }

    edit(userGroupId: number, userGroup: UserGroup): Observable<void> {
        return this.http.put<void>(`usergroup/${userGroupId}`, userGroup);
    }

    delete(userGroup: UserGroup): Observable<void> {
        return this.http.delete<void>(`usergroup/${userGroup.id}`);
    }
}
