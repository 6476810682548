import {MultilanguageFieldInterface} from '../../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CrudItem, JsonDeserializable} from '../../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../../supportedLanguages';
import {WindowEditorField} from '../../../offer/window-editor/window-editor-field';

export enum WindowDesignerCatalogDependentOptionAction {
    SHOW = 'SHOW',
    HIDE = 'HIDE'
}

export interface PositionDesignerCatalogDependentOption<FieldEnum extends string> extends CrudItem {
    id: number;
    requiredInputId: FieldEnum;
    requiredInputValue: string;
    dependentInputId: FieldEnum;
    dependentInputValue: string;
    whenMatched: WindowDesignerCatalogDependentOptionAction;
}

@JsonDeserializable
export class WindowDesignerCatalogDependentOption implements PositionDesignerCatalogDependentOption<WindowEditorField> {
    id: number;
    requiredInputId: WindowEditorField;
    requiredInputValue: string;
    requiredInputValueName?: MultilanguageFieldInterface;
    dependentInputId: WindowEditorField;
    dependentInputValue: string;
    dependentInputValueName?: MultilanguageFieldInterface;
    whenMatched: WindowDesignerCatalogDependentOptionAction;

    static fromJSON(jsonObject: object): WindowDesignerCatalogDependentOption {
        const option = new WindowDesignerCatalogDependentOption();
        option.id = jsonObject['id'];
        if (jsonObject['requiredInputId'] != undefined) {
            option.requiredInputId = WindowEditorField[jsonObject['requiredInputId'] as string];
        }
        option.requiredInputValue = jsonObject['requiredInputValue'];
        if (jsonObject['requiredInputValueName'] != undefined) {
            option.requiredInputValueName = MultilanguageField.fromJSON(jsonObject['requiredInputValueName']);
        }
        if (jsonObject['dependentInputId'] != undefined) {
            option.dependentInputId = WindowEditorField[jsonObject['dependentInputId'] as string];
        }
        option.dependentInputValue = jsonObject['dependentInputValue'];
        if (jsonObject['dependentInputValueName'] != undefined) {
            option.dependentInputValueName = MultilanguageField.fromJSON(jsonObject['dependentInputValueName']);
        }
        if (jsonObject['whenMatched'] != undefined) {
            option.whenMatched = WindowDesignerCatalogDependentOptionAction[jsonObject['whenMatched'] as string];
        }
        return option;
    }
}
