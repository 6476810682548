<p-dialog [resizable]="false" [visible]="true" [modal]="true" (onHide)="close()" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.UPDATE_VALIDITY_DATES.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <div>
            <p>{{'OFFER.POSITIONS.DIALOGS.UPDATE_VALIDITY_DATES.MESSAGE' | translate}}</p>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="updateValidityDatesSave" label="{{ 'GENERAL.YES' | translate }}" type="main-action"
                               [size]="40" (onClick)="submit()"></app-simple-button>
            <app-simple-button [size]="40" buttonId="updateValidityDatesCancel" label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
