import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {IsAnotherUserLoggedService} from '../features/login/isAnotherUserLogged';
import {IsLoggedService} from '../features/login/islogged.service';
import {StorageKey, StorageService} from './storage.service';

// helper class to break cyclic dependencies between AuthService and AuthAwareHttp
@Injectable()
export class LogoutHelperService {

    constructor(private isLoggedService: IsLoggedService,
                private isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private storageService: StorageService,
                private router: Router) {
    }

    logout(navigateToLogin = true) {
        this.isLoggedService.changeState(false);
        this.isAnotherUserLoggedService.changeState(false);
        this.storageService.remove(StorageKey.JWT);
        this.storageService.remove(StorageKey.MAIN_JWT);
        this.storageService.remove(StorageKey.CURRENT_USER_AUTH_DATA);
        this.storageService.remove(StorageKey.MAIN_USER_AUTH_DATA);
        if (navigateToLogin) {
            this.router.navigate(['/login'], {queryParams: {form: 'login'}});
        }
    }
}
