<div [attr.id]="dialogId" class="dialog-window">
    <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true" (onHide)="cancel()"
              [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title confirm-action-header">
                {{ header | translate }}
            </div>
        </p-header>
        <div *ngIf="question != undefined" class="preserveWhitespace">
            <div class="confirm-action-question" [innerHTML]="question | translate">
            </div>
        </div>
        <div *ngFor="let question of questions" class="preserveWhitespace">
            <div class="confirm-action-question" [innerHTML]="question | translate">
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button buttonId="confirm"
                                   [label]="confirmButtonLabel ? confirmButtonLabel : ('GENERAL.OK' | translate)"
                                   type="main-action" [size]="40" (onClick)="confirm()"></app-simple-button>
                <app-simple-button buttonId="confirm"
                                   [label]="discardButtonLabel ? discardButtonLabel : ('GENERAL.CANCEL' | translate)"
                                   type="cancel" [size]="40" (onClick)="cancel()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
