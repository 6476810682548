import {JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class SubsystemPartnerWebshopInfo {
    phoneNumber: string;
    phoneNumber2: string;
    webshopCompanyName: string;
    companyWebsiteUrl: string;
    email: string;
    street: string;
    city: string;
    country: string;
    zip: string;
    cardTitle: string;
    logo: File;
    subsystemId: string;

    constructor() {
        this.phoneNumber = undefined;
        this.phoneNumber2 = undefined;
        this.webshopCompanyName = undefined;
        this.companyWebsiteUrl = undefined;
        this.email = undefined;
        this.street = undefined;
        this.city = undefined;
        this.country = undefined;
        this.zip = undefined;
        this.cardTitle = undefined;
        this.logo = undefined;
        this.subsystemId = undefined;
    }

    static fromJSON(jsonObject: any): SubsystemPartnerWebshopInfo {
        const subsystemWebshopInfo = new SubsystemPartnerWebshopInfo();
        subsystemWebshopInfo.phoneNumber = jsonObject.phoneNumber;
        subsystemWebshopInfo.phoneNumber2 = jsonObject.phoneNumber2;
        subsystemWebshopInfo.webshopCompanyName = jsonObject.webshopCompanyName;
        subsystemWebshopInfo.companyWebsiteUrl = jsonObject.companyWebsiteUrl;
        subsystemWebshopInfo.email = jsonObject.email;
        subsystemWebshopInfo.street = jsonObject.street;
        subsystemWebshopInfo.city = jsonObject.city;
        subsystemWebshopInfo.country = jsonObject.country;
        subsystemWebshopInfo.zip = jsonObject.zip;
        subsystemWebshopInfo.cardTitle = jsonObject.cardTitle;
        subsystemWebshopInfo.subsystemId = jsonObject.subsystemId;
        return subsystemWebshopInfo;
    }
}
