import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {SelectInvalidValueCorrectedEvent} from "../../form-inputs/inputs/select/select.component";

@Component({
    selector: 'app-predefined-glazing-selection',
    templateUrl: './predefined-glazing-selection.component.html',
    styleUrls: ['../../features/shared-styles.css']
})
export class PredefinedGlazingSelectionComponent {

    @Input() readOnlyMode: boolean;
    @Input() validationErrors: { [field: string]: string } = {};

    @Input() quantityOptions: SelectItem[];
    @Input() categoryOptions: SelectItem[];
    @Input() frameCategoryOptions: SelectItem[];
    @Input() glazingPackageOptions: SelectItem[];

    @Input() selectedQuantity: number;
    @Input() glazingCategoryId: number;
    @Input() glazingFrameCategoryId: number;
    @Input() glazingPackageId: number;

    @Input() quantityNewOptions: boolean;
    @Input() categoryNewOptions: boolean;
    @Input() frameCategoryNewOptions: boolean;
    @Input() glazingPackageNewOptions: boolean;

    @Input() quantityHasValidationErrors: boolean;
    @Input() categoryHasValidationErrors: boolean;
    @Input() frameCategoryHasValidationErrors: boolean;
    @Input() glazingPackageHasValidationErrors: boolean;

    @Output() readonly quantityChanged = new EventEmitter<number>();
    @Output() readonly categoryChanged = new EventEmitter<number>();
    @Output() readonly frameCategoryChanged = new EventEmitter<number>();
    @Output() readonly glazingPackageChanged = new EventEmitter<number>();

    @Output() readonly quantityClicked = new EventEmitter<MouseEvent>();
    @Output() readonly categoryClicked = new EventEmitter<MouseEvent>();
    @Output() readonly frameCategoryClicked = new EventEmitter<MouseEvent>();
    @Output() readonly glazingPackageClicked = new EventEmitter<MouseEvent>();

    @Output() readonly selectFocus = new EventEmitter<FocusEvent>();

    @Output() readonly beginInvalidValueCorrection = new EventEmitter<SelectInvalidValueCorrectedEvent>();
    @Output() readonly endInvalidValueCorrection = new EventEmitter<void>();

    public onSelectFocus(event: FocusEvent): void {
        this.selectFocus.emit(event);
    }
}
