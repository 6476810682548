import {CustomTranslationsInterface} from "../../../../window-designer/catalog-data/custom-translations-interface";
import {MultilanguageField} from "../../../supportedLanguages";

export class CustomTranslations implements CustomTranslationsInterface {
    masterQuarter: MultilanguageField;

    constructor() {
        this.masterQuarter = new MultilanguageField();
    }
}
