import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {OfferStatus} from "../../../common/enums/OfferStatus";

@JsonDeserializable
export class PaymentPackage {

    id: number;
    name: string;
    transportValue: number;
    netValue: number;
    grossValue: number;
    creationDate: Date;
    completionDate: Date;
    subsystemId: number;
    subsystemName: string;
    ownerId: number;
    ownerFirstName: string;
    ownerLastName: string;
    clientManagerFirstName: string;
    clientManagerLastName: string;
    subClientManagerFirstName: string;
    subClientManagerLastName: string;
    orders: number[];
    empty: boolean;
    status: OfferStatus;
    paymentPackageCorrection: number;
    fullyPrinted: boolean;

    constructor() {
        this.id = undefined;
        this.name = undefined;
        this.transportValue = undefined;
        this.netValue = undefined;
        this.grossValue = undefined;
        this.creationDate = undefined;
        this.completionDate = undefined;
        this.subsystemId = undefined;
        this.subsystemName = undefined;
        this.ownerId = undefined;
        this.ownerFirstName = undefined;
        this.ownerLastName = undefined;
        this.clientManagerFirstName = undefined;
        this.clientManagerLastName = undefined;
        this.subClientManagerFirstName = undefined;
        this.subClientManagerLastName = undefined;
        this.orders = [];
        this.empty = true;
        this.paymentPackageCorrection = undefined;
        this.fullyPrinted = true;
    }

    static fromJSON(jsonObject: any): PaymentPackage {
        const paymentPackage = new PaymentPackage();
        paymentPackage.id = jsonObject.id;
        paymentPackage.name = jsonObject.name;
        paymentPackage.transportValue = jsonObject.transportValue;
        paymentPackage.netValue = jsonObject.netValue;
        paymentPackage.grossValue = jsonObject.grossValue;
        paymentPackage.creationDate = new Date(jsonObject.creationDate);
        paymentPackage.completionDate = new Date(jsonObject.completionDate);
        paymentPackage.subsystemId = jsonObject.subsystemId;
        paymentPackage.subsystemName = jsonObject.subsystemName;
        paymentPackage.ownerId = jsonObject.ownerId;
        paymentPackage.ownerFirstName = jsonObject.ownerFirstName;
        paymentPackage.ownerLastName = jsonObject.ownerLastName;
        paymentPackage.clientManagerFirstName = jsonObject.clientManagerFirstName;
        paymentPackage.clientManagerLastName = jsonObject.clientManagerLastName;
        paymentPackage.subClientManagerFirstName = jsonObject.subClientManagerFirstName;
        paymentPackage.subClientManagerLastName = jsonObject.subClientManagerLastName;
        paymentPackage.orders = [].concat(jsonObject.orders || []);
        paymentPackage.empty = jsonObject.empty;
        paymentPackage.status = jsonObject.status;
        paymentPackage.paymentPackageCorrection = jsonObject.paymentPackageCorrection;
        paymentPackage.fullyPrinted = jsonObject.fullyPrinted;

        return paymentPackage;
    }
}
