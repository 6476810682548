import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {SupportedLanguage, SupportedLanguages} from '../../../supportedLanguages';
import {DocumentTemplateType} from '../../offer/print-dialog/document-template-type.enum';
import {DocumentType} from '../../offer/print-dialog/DocumentType';
import {DocumentTemplate} from './document-template';
import {DocumentTemplateService} from './document-template.service';

@Component({
    selector: 'app-document-template',
    templateUrl: './document-template.component.html',
    providers: [DocumentTemplateService, DataServiceHelper, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTemplateComponent extends CrudCommonComponent<DocumentTemplate, DocumentTemplateService> implements OnInit {

    supportedLanguages = SupportedLanguages.languages;
    documentTypeFilter: Observable<SelectItem[]>;
    documentTypeOptions: Observable<SelectItem[]>;
    documentTemplateTypeOptions: Observable<SelectItem[]>;
    languageOptions: Observable<SelectItem[]>;
    @ViewChild(DataTable) dataTable: DataTable;
    validateDataStep: WizardStepValidator;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, DocumentTemplateService, 'DOCUMENT_TEMPLATE', 'DocumentTemplate');
        this.item = new DocumentTemplate();
        this.validateDataStep = () => this.validateForm();
    }

    ngOnInit(): void {
        let allowedDocumentTypes = [
            DocumentType.PRODUCTION_ORDER,
            DocumentType.ORDER,
            DocumentType.ORDER_WITH_FIXED_EXCHANGE_RATE,
            DocumentType.ORDER_CONFIRMATION,
            DocumentType.DISTRIBUTION_OFFER,
            DocumentType.OFFER,
            DocumentType.GLAMOUR_OFFER,
            DocumentType.GLAMOUR_UPSELLING,
            DocumentType.GLAMOUR_ROT,
            DocumentType.GLAMOUR_ENTRANCE_DOOR_POSITION,
            DocumentType.GLAMOUR_GATE_POSITION,
            DocumentType.GLAMOUR_WINDOW_POSITION,
            DocumentType.GLAMOUR_TERRACE_WINDOW_POSITION,
            DocumentType.GLAMOUR_ADDON_POSITIONS,
            DocumentType.GLAMOUR_CONFIGURABLE_ADDON_POSITIONS,
            DocumentType.GLAMOUR_ROOF_WINDOW_POSITION,
            DocumentType.GLAMOUR_WINDOW_BOOKLET,
            DocumentType.GLAMOUR_CONFIGURABLE_ADDON_BOOKLET,
            DocumentType.GLAMOUR_ROOF_WINDOW_BOOKLET,
            DocumentType.GLAMOUR_OFFER_WEBSHOP,
            DocumentType.RETAIL_OFFER,
            DocumentType.GLAMOUR_DEFAULT_ANNOTATIONS
        ];
        this.documentTypeFilter = this.translatedSelectItemService.buildSortedDropdown(allowedDocumentTypes, "SETTINGS.SECTION.DOCUMENT_TEMPLATES.DOCUMENT_TYPES.", '');
        this.documentTypeOptions = this.documentTypeFilter.pipe(map(types => types.slice(1)));

        this.documentTemplateTypeOptions = this.translatedSelectItemService
            .buildSortedDropdown(DocumentTemplateType, "SETTINGS.SECTION.DOCUMENT_TEMPLATES.DOCUMENT_TEMPLATE_TYPES.", '')
            .pipe(map(types => types.slice(1)));

        this.languageOptions = this.translatedSelectItemService
            .buildSortedDropdown(this.supportedLanguages.map(lang => lang.code), "", '')
            .pipe(map(types => types.slice(1)));

        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    getNewItem(): DocumentTemplate {
        return new DocumentTemplate();
    }

    getDatatable(): DataTable {
        return this.dataTable;
    }

    onRowSelect(event): void {
        this.validationErrors = {};
        this.item = new DocumentTemplate();
        forkJoin({
            item: this.itemService.getItem(event.data.id),
            file: this.itemService.getFileForItem(event.data.id)
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.file = data.file;
                this.setDisplayDialog(true);
            },
            error: error => {
                console.error(error);
                if (error.status === 500) {
                    this.setErrors(error);
                } else {
                    this.growlMessageController.error(error);
                }
            },
            complete: () => {
                console.debug('getDocumentTemplate` completed!');
            }
        });
        this.keepSelectedItemIndex(event);
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        if (this.item.documentType === DocumentType.PRODUCTION_ORDER) {
            this.item.languages = this.supportedLanguages.map(lang => lang.code);
        }
        this.itemService.saveItem(this.item, this.file).subscribe(this.genericCleanupAndReloadSuccessObserver());
    }

    showSuccessMessage(): void {
        this.growlMessageController.info('SETTINGS.SECTION.DOCUMENT_TEMPLATES.TEMPLATE_SAVED');
    }

    contains(template: DocumentTemplate, language: SupportedLanguage): boolean {
        return template.languages.includes(language.code)
    }

    validateForm(): Observable<boolean> {
        let errors = {};
        if (this.validationErrorsPresent(errors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, errors);
            this.changeDetector.markForCheck();
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }
}
