<div class="new-form-600">
    <div class="new-form-row">
        <app-input-text inputId="roleNameInput" label="{{ 'ROLE.FORM.NAME' | translate }}"
                        [(validationMessageKey)]="validationErrors['roleName']" [disabled]="!canEdit" [maxlength]="80"
                        [required]="true" [(ngModel)]="role.roleName"></app-input-text>
    </div>
    <div *ngFor="let privilege of privileges" class="new-form-row">
        <app-checkbox [inputId]="'privilege_' + privilege.id"
                      [label]="('PRIVILEGE.' + privilege.privilegeName) | translate" [disabled]="!canEdit"
                      [ngModel]="isPrivilegeSelected(privilege.id)"
                      (ngModelChange)="setPrivilegeSelected(privilege.id, $event)"></app-checkbox>
    </div>
</div>
