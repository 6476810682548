import {ChangeDetectionStrategy, Component, Input, OnInit} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {Permissions} from "../../../auth/permission.service";
import {Country} from '../../../common/enums/country';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {Supplier} from "../supplier";

@Component({
    selector: 'app-supplier-address-form',
    templateUrl: './supplier-address-form.component.html',
    styleUrls: ['../create-supplier/create-supplier.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierAddressFormComponent implements OnInit {

    @Input()
    validationErrors: any;

    @Input()
    supplier: Supplier;

    countries: Observable<SelectItem[]>;

    constructor(private permissions: Permissions,
                private translatedSelectItemService: TranslatedSelectItemService) {
    }

    ngOnInit(): void {
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
    }

    isNotPermitted(requiredPermission): boolean {
        return !this.permissions.isPermitted(requiredPermission);
    }
}
