import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ColorInterface} from '../../../../window-designer/catalog-data/color-interface';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {GatePanelType} from '../gate-panel-type/gate-panel-type';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {Color} from './color';
import {ColorList} from './color-list';

@Injectable()
export class ColorService extends SingleSystemCheckboxCrudService<Color> {

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<ColorList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<ColorList>('colors', {params: params});
    }

    addItem(item: Color): Observable<number> {
        return this.saveItem(item, undefined, undefined);
    }

    editItem(itemId: number, item: Color): Observable<number> {
        return this.saveItem(item, undefined, undefined);
    }

    getColorsByIdList(listOfIds: number[], active: boolean): Observable<ColorList> {
        const headers = this.dataServiceHelper.prepareHeaders({listOfIds, active});
        return this.http.get<ColorList>('colors/byIds', {headers});
    }

    getAllActiveColors(windowSystemIds?: number[], requireAllWindowSystems?: boolean): Observable<Color[]> {
        const params = {};
        if (windowSystemIds != undefined) {
            params['windowSystemId'] = windowSystemIds.join();
        }
        if (requireAllWindowSystems != undefined) {
            params['requireAllSystems'] = `${requireAllWindowSystems}`;
        }
        return this.http.get<Color[]>('colors/allActiveColors', {
            params: params
        });
    }

    getAllActiveGrillColors(): Observable<ColorList> {
        return this.http.get<ColorList>('colors/allActiveGrillColors');
    }

    saveItem(item: Color, file: File, webshopTexture: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('colorDto', new Blob([body], {
            type: 'application/json'
        }));

        if (file != undefined) {
            formData.append('file', file);
        }
        if (webshopTexture != undefined) {
            formData.append('webshopTexture', webshopTexture);
        }
        return this.http.post('colors', formData, {observe: 'response'}).pipe(
            map(response => item.id != undefined ? item.id : this.dataServiceHelper.getNewItemId('colors', response)));
    }

    getItem(colorId: number): Observable<Color> {
        return this.http.get<Color>(`colors/${colorId}`);
    }

    getImageForItemBySymbol(symbol: string): Observable<string> {
        return this.http.get('colors/image', {
            params: {symbol: symbol},
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImage(colorId: number): Observable<string> {
        return this.http.get(`colors/${colorId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageAsFile(colorId: number): Observable<File> {
        return this.http.get(`colors/${colorId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getWebshopTexture(colorId: number): Observable<File> {
        return this.http.get(`colors/${colorId}/webshopTexture`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    copyItem(id: number, item: Color, file, webshopTexture): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('colorDto', new Blob([body], {
            type: 'application/json'
        }));

        if (file != undefined) {
            formData.append('file', file);
        }
        if (webshopTexture != undefined) {
            formData.append('webshopTexture', webshopTexture);
        }

        return this.http.post(`colors/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id != undefined ? item.id : this.dataServiceHelper.getNewItemId('colors', response)));
    }

    validate(color: Color) {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('colors/validate', color));
    }

    editGateSystemLinks(colorId: number, gateSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`colors/linkGates/${colorId}`, gateSystemIds);
    }

    getLinkedGateSystems(colorId: number): Observable<number[]> {
        return this.http.get<number[]>(`colors/linkedGates/${colorId}`);
    }

    editConfigSystemLinks(colorId: number, configSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`colors/linkConfigs/${colorId}`, configSystemIds);
    }

    getLinkedConfigSystems(colorId: number): Observable<number[]> {
        return this.http.get<number[]>(`colors/linkedConfigs/${colorId}`);
    }

    getColorsForGateSystem(gateSystemId: number, selectedIds: number[] = null): Observable<Color[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        return this.http.get<Color[]>(`colors/forGateSystem/${gateSystemId}`, {params: params});
    }

    getColorsForConfigSystem(configSystemId: number, selectedIds: number[] = null, readOnly = false): Observable<Color[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        params['readOnly'] = `${readOnly}`;
        return this.http.get<Color[]>(`colors/forConfigSystem/${configSystemId}`, {params: params});
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('colors/names', {params: params});
    }
}
