import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DataTable} from 'primeng/datatable';
import {concat, forkJoin, Observable, of} from 'rxjs';
import {map, reduce, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {MultiValidator} from "../../../shared/validator/input-validator";
import {WebshopGlazingPackage} from '../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package';
import {WebshopGlazingPackageService} from '../../window-system/glazing-package/webshop-glazing-package/webshop-glazing-package.service';
import {WebshopChargeService} from "../../window-system/webshop-charge/webshop-charge-service";
import {WebshopCharge} from "../../window-system/webshop-charge/WebshopCharge";
import {WindowSystemName} from '../../window-system/window-system-definition/window-system-definition';
import {WindowSystemDefinitionService} from '../../window-system/window-system-definition/window-system-definition.service';
import {ConfigSystemUpsellingSettings} from '../configurable-addon-upselling-settings/config-system-upselling-settings';
import {ConfigSystemUpsellingSettingsService} from '../configurable-addon-upselling-settings/config-system-upselling-settings.service';
import {UpsellingProposition} from "./upselling-proposition";
import {UpsellingPropositionService} from "./upselling-proposition-service";

@Component({
    selector: 'app-upselling-proposition',
    templateUrl: './upselling-proposition.component.html',
    providers: [UpsellingPropositionService, WebshopChargeService, WebshopGlazingPackageService, WindowSystemDefinitionService,
        TranslatedSelectItemService, ConfigSystemUpsellingSettingsService]
})
export class UpsellingPropositionComponent extends CrudCommonComponent<UpsellingProposition, UpsellingPropositionService>
    implements OnInit {

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => this.validateData()
        },
        CHARGES: {
            id: 'CHARGES',
            validate: () => this.validateCharges()
        },
        GLAZINGS: {
            id: 'GLAZINGS',
            validate: () => this.validateGlazings()
        },
        CONFIG_ADDONS: {
            id: 'CONFIG_ADDONS',
            validate: () => this.validateConfigAddons()
        }
    };

    @ViewChild(DataTable, {static: true})
    dataTable: DataTable;

    private readonly webshopChargeService: WebshopChargeService;
    private readonly webshopGlazingPackageService: WebshopGlazingPackageService;
    private readonly configurableAddonUpsellingSettingsService: ConfigSystemUpsellingSettingsService;
    private readonly windowSystemService: WindowSystemDefinitionService;
    charges: WebshopCharge[] = [];
    glazings: WebshopGlazingPackage[] = [];
    configurableAddonUpsellingSettings: ConfigSystemUpsellingSettings[] = [];
    configurableAddonLinkedWindowSystems: Map<number, number[]>;
    systemNames: WindowSystemName[] = [];

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, UpsellingPropositionService, 'ADMIN_PANEL.UPSELLING_PROPOSITION',
            'UpsellingProposition');
        this.webshopChargeService = injector.get(WebshopChargeService);
        this.webshopGlazingPackageService = injector.get(WebshopGlazingPackageService);
        this.configurableAddonUpsellingSettingsService = injector.get(ConfigSystemUpsellingSettingsService);
        this.windowSystemService = injector.get(WindowSystemDefinitionService);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown('ADMIN_PANEL.UPSELLING_PROPOSITION.FORM');
        forkJoin({
            charges: this.webshopChargeService.getAllActiveWindowUpsellings(),
            glazings: this.webshopGlazingPackageService.getAllActiveGlazingUpsellings(),
            configAddons: this.configurableAddonUpsellingSettingsService.getAllActiveUpsellingSettingsWithWindowSystems(),
            windowSystems: this.windowSystemService.allNotRoofSystemNames()
        }).subscribe({
            next: data => {
                this.charges = data.charges.data;
                this.glazings = data.glazings.data;
                this.configurableAddonUpsellingSettings = data.configAddons.upsellingSettings;
                this.configurableAddonLinkedWindowSystems = data.configAddons.linkedSystems;
                this.systemNames = data.windowSystems;
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    getNewItem(): UpsellingProposition {
        return new UpsellingProposition();
    }

    loadEditedItem(event: { data: UpsellingProposition }): Observable<any> {
        return this.itemService.getItem(event.data.id);
    }

    protected validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrors(this.validationErrors);
        return concat(this.validateData(), this.validateCharges()).pipe(reduce((acc, value) => acc && value));
    }

    private validateData(): Observable<boolean> {
        let errors = {};
        if (!this.item.name[this.userLang]) {
            errors[`name[${this.userLang}]`] = `error.upsellingSettingsDto.name[${this.userLang}].not_empty`;
        }
        if (!this.newItem) {
            errors['sortIndex'] = MultiValidator.of('error.upsellingPropositionDto.sortIndex')
                .withNotNullValidator()
                .withIntegerValidator()
                .withRangeValidator(1, 99999)
                .validate(this.item.sortIndex);
        }
        if (this.validationErrorsPresent(errors)) {
            this.validationErrors = Object.assign({}, errors);
            return of(false);
        }
        return this.itemService.validateData(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    private validateCharges(): Observable<boolean> {
        if (this.validationErrors['webshopChargeIds'] != undefined) {
            return of(false);
        }
        return this.itemService.validateCharges(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    private validateGlazings(): Observable<boolean> {
        if (this.validationErrors['webshopGlazingIds'] != undefined) {
            return of(false);
        }
        return this.itemService.validateGlazings(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    private validateConfigAddons(): Observable<boolean> {
        if (this.validationErrors['configurableAddonUpsellingSettingsIds'] != undefined) {
            return of(false);
        }
        return this.itemService.validateConfigAddons(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    onRowSelect(event) {
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    getItemWithImage(id: number) {
        forkJoin({
            item: this.itemService.getItem(id),
            image: this.itemService.getImageAsFile(id)
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.file = data.image;
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }
}
