import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from "@angular/core";
import {finalize} from 'rxjs/operators';
import {OnceFlag} from '../../../../../../shared/once-flag';
import {PositionService, RemovePositionsResult} from "../../position.service";
import {Position} from "../position";

@Component({
    selector: 'app-delete-position-dialog',
    templateUrl: './delete-position-dialog.component.html',
    styleUrls: ['../../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeletePositionDialogComponent {

    @Input()
    offerId: number;

    @Input()
    areParentsSelected: boolean;

    @Input()
    itemsToRemove: Position[];

    @Output()
    onSuccess: EventEmitter<RemovePositionsResult> = new EventEmitter();

    @Output()
    onError: EventEmitter<any> = new EventEmitter();

    @Output()
    onCancel: EventEmitter<any> = new EventEmitter();

    deleteInProgress = false;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private positionService: PositionService,
                private changeDetector: ChangeDetectorRef) {
    }

    removeConfirmed() {
        this.remove(false);
    }

    removeWithChildrenConfirmed() {
        this.remove(true);
    }

    private remove(deleteChildren = false) {
        if (this.itemsToRemove && this.itemsToRemove.length > 0) {
            this.deleteInProgress = true;
            this.positionService.removeItems(this.offerId, this.itemsToRemove.map(item => item.id), deleteChildren)
                .pipe(finalize(() => {
                    this.deleteInProgress = false;
                    this.changeDetector.markForCheck();
                }))
                .subscribe({
                    next: (affectedTables: RemovePositionsResult): void => {
                        this.dialogHideHelper.call(() => this.onSuccess.emit(affectedTables));
                    },
                    error: (error): void => {
                        this.dialogHideHelper.call(() => this.onError.emit(error));
                    }
                });
        }
    }

    cancel() {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }
}
