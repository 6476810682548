import * as _ from 'underscore';
import {Glazing} from './glazing';

export class GlazingPackageForAreaRange {
    id: number;
    glazing: Glazing;
    glazingBeadId: number;
    bottomAreaLimit: number;
    topAreaLimit: number;
    glassCount: number;
    tempId: number;

    constructor() {
        this.id = undefined;
        this.glazing = new Glazing();
        this.bottomAreaLimit = undefined;
        this.topAreaLimit = undefined;
        this.glassCount = undefined;
        this.glazingBeadId = undefined;
        this.tempId = Math.floor(Math.random() * -1000000);
    }

    static copy(obj: GlazingPackageForAreaRange): GlazingPackageForAreaRange {
        let copy: GlazingPackageForAreaRange = new GlazingPackageForAreaRange();

        copy.id = obj.id;
        copy.glazing = _.clone(obj.glazing);
        copy.bottomAreaLimit = obj.bottomAreaLimit;
        copy.topAreaLimit = obj.topAreaLimit;
        copy.glassCount = obj.glassCount;
        copy.glazingBeadId = obj.glazingBeadId;

        return copy;
    }
}
