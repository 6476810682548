import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import {FilterMetadata} from 'primeng/api';
import {DateRangeFilter} from '../date-range-filter';
import {TranslatedSelectItemService} from '../service/translated-select-item.service';
import {AbstractDateRangeFilterComponent} from './abstract-date-range-filter.component';

@Component({
    selector: 'app-form-date-range-filter',
    templateUrl: './form-date-range-filter.component.html',
    styleUrls: ['./form-date-range-filter.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormDateRangeFilterComponent extends AbstractDateRangeFilterComponent implements OnChanges {

    filter: FilterMetadata | FilterMetadata[] = {};

    @Input()
    value: DateRangeFilter;

    @Output()
    readonly valueChange = new EventEmitter<DateRangeFilter>();

    constructor(translatedSelectItemService: TranslatedSelectItemService,
                changeDetector: ChangeDetectorRef) {
        super(translatedSelectItemService, changeDetector);
    }

    ngOnChanges(changes: SimpleChanges): void {
        const valueChange = changes['value'];
        if (valueChange != undefined) {
            this.filter = {value: valueChange.currentValue};
        }
    }

    get hostElement(): ElementRef {
        return undefined;
    }

    doFilter(filter: DateRangeFilter): void {
        this.filter = {value: filter};
        this.valueChange.emit(filter);
    }
}
