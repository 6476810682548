import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';
import {combineLatest, of, timer} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {AuthService} from '../../../auth/auth.service';

@Component({
    selector: 'app-send-reset-password-mail-form',
    templateUrl: './send-reset-password-mail-form.component.html',
    styleUrls: ['./../login.component.css']
})
export class SendResetPasswordMailFormComponent implements OnInit, OnDestroy {

    @Input()
    login: string;

    @Output()
    readonly returnClick = new EventEmitter<void>();

    @Output()
    readonly successMessageChange = new EventEmitter<string>();

    @Output()
    readonly errorMessageChange = new EventEmitter<string>();

    sendingMailInProgress = false;

    private readonly enterHotkey: Hotkey;

    constructor(private readonly authService: AuthService,
                private readonly hotkeysService: HotkeysService) {
        this.enterHotkey = new Hotkey('enter', (): boolean => {
            this.handleSendResetPasswordMailClick();
            return false;
        }, ['INPUT']);
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.enterHotkey);
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.enterHotkey);
    }

    handleSendResetPasswordMailClick() {
        this.successMessageChange.emit(undefined);
        this.errorMessageChange.emit(undefined);
        this.sendingMailInProgress = true;
        combineLatest([this.authService.sendPasswordResetMail(this.login).pipe(
            tap({
                next: () => {
                    this.successMessageChange.emit('LOGIN.FORM.PASSWORD_RESET_MAIL_SENT');
                    this.sendingMailInProgress = false;
                },
                error: () => {
                    this.errorMessageChange.emit('error.login.passwordResetMailSendingFailed');
                    this.sendingMailInProgress = false;
                }
            }),
            catchError(() => of(undefined))),
            timer(60000)]
        ).pipe(
            finalize(() => this.sendingMailInProgress = false)
        ).subscribe();
    }
}
