<div class="dialog-window">
    <p-dialog [visible]="true" [resizable]="false" (onHide)="hide()" [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="p-dialog-title">{{ "SUPPLIER-DETAILS.CHANGE-SUPPLIER-TITLE" | translate }}</div>
        </p-header>
        <div class="new-form-400">
            <div class="new-form">
                <app-select inputId="subSupplier"
                            label="{{ 'SUPPLIER-DETAILS.FORM.NEW-SUPPLIER-FOR-PRODUCTS' | translate }}"
                            [(validationMessageKey)]="validationErrors['selectedSubstituteSupplier']"
                            [options]="substituteSuppliers" [(ngModel)]="selectedSubstituteSupplier"></app-select>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                                   [disabled]="isNotPermitted({roles:['ROLE_CREATE_SUPPLIER', 'ROLE_EDIT_SUPPLIER']})"
                                   (onClick)="moveProductsToSupplier()"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="hide()"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
