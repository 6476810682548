import {Injectable, OnDestroy} from "@angular/core";
import {Router} from "@angular/router";
import {Subscription} from 'rxjs';
import * as _ from 'underscore';
import {OfferStatus} from "../common/enums/OfferStatus";
import {IsAnotherUserLoggedService} from "../features/login/isAnotherUserLogged";
import {IsLoggedService} from "../features/login/islogged.service";
import {ApplicationPrivilege} from './application-privilege';
import {CurrentUserService} from './current-user.service';
import {JwtDto} from './jwt-dto';

@Injectable()
export class Permissions implements OnDestroy {

    private roles: JwtDto;
    private subscription: Subscription;
    private subscription1: Subscription;

    constructor(private isLoggedService: IsLoggedService,
                private isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private router: Router,
                private currentUserService: CurrentUserService) {
        this.initSubscriptions();
    }

    ngOnDestroy(): void {
        if (this.subscription != undefined) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
        if (this.subscription1 != undefined) {
            this.subscription1.unsubscribe();
            this.subscription1 = undefined;
        }
    }

    private initSubscriptions(): void {
        this.subscription = this.isLoggedService.item$.subscribe(() => this.reloadUserRoles());
        this.subscription1 = this.isAnotherUserLoggedService.item$.subscribe(() => this.reloadUserRoles());
    }

    private reloadUserRoles() {
        this.roles = this.currentUserService.currentUser;
    }

    isPermitted(componentRoles: { roles: ApplicationPrivilege[] }): boolean {
        if (this.roles && componentRoles) {
            const tokenRoles = this.roles.userRoles;
            const rolesToCheck = componentRoles.roles;
            return rolesToCheck.length === 0 || _.intersection(tokenRoles, rolesToCheck).length !== 0;
        }
        return false;
    }

    public userCanEditOffer(offerStatus: OfferStatus): boolean {
        switch (offerStatus) {
            case OfferStatus.WEBSHOP_DRAFT:
                if (this.isWebshop()) {
                    return true;
                }
                break;
            case OfferStatus.COPY_DRAFT:
                return true;
            case OfferStatus.NEW_OFFER:
            case OfferStatus.PARTNER_NEW_OFFER:
            case OfferStatus.TO_REVISE:
                if (this.isOperator() || this.isHandlowiec() || this.isSprzedawca()) {
                    return true;
                }
                break;
            case OfferStatus.TO_SEND:
            case OfferStatus.REVIEWED:
            case OfferStatus.VERIFICATION_REJECTED:
                if (this.isOperator()) {
                    return true;
                }
                break;
            case OfferStatus.VERIFIED:
            case OfferStatus.TO_VERIFY:
            case OfferStatus.CORRECTION:
            case OfferStatus.TO_REVIEW:
                if (this.isKoordynator() || this.isOpiekun()) {
                    return true;
                }
                break;
        }

        return false;
    }

    public redirectToNoPermissionsErrorPage(showLoginButton: boolean): void {
        this.router.navigate(['/no-privileges-error', {
            previousUrl: this.router.url, showLoginButton: showLoginButton
        }], {
            skipLocationChange: true
        });
    }

    isKoordynator(): boolean {
        return this.roles.userRoles.includes('ROLE_KOORDYNATOR');
    }

    isOpiekun(): boolean {
        return this.roles.userRoles.includes('ROLE_OPIEKUN');
    }

    isOperator(): boolean {
        return this.roles.userRoles.includes('ROLE_OPERATOR');
    }

    isHandlowiec(): boolean {
        return this.roles.userRoles.includes('ROLE_HANDLOWIEC');
    }

    isWebshop(): boolean {
        return this.roles.userRoles.includes('ROLE_WEBSHOP');
    }

    isSprzedawca(): boolean {
        return this.roles.userRoles.includes('ROLE_SPRZEDAWCA');
    }

    canCreateClient(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_CLIENT');
    }

    canDeleteClient(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_CLIENT');
    }

    canReadClient(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_CLIENT');
    }

    canEditClient(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_CLIENT');
    }

    canCreateClientgroup(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_CLIENTGROUP');
    }

    canDeleteClientgroup(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_CLIENTGROUP');
    }

    canReadClientgroup(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_CLIENTGROUP');
    }

    canEditClientgroup(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_CLIENTGROUP');
    }

    canCreateUser(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_USER');
    }

    canDeleteUser(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_USER');
    }

    canReadUser(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_USER');
    }

    canEditUser(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_USER');
    }

    canEditUserSelf(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_USER_SELF');
    }

    canEditUserWebshop(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_USER_WEBSHOP');
    }

    canEditUserActiveField(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_USER_ACTIVE_FIELD');
    }

    canEditUserRoles(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_USER_ROLES');
    }

    canEditRole(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_ROLE');
    }

    canReadRole(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_ROLE');
    }

    canCreateUsergroup(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_USERGROUP');
    }

    canEditUsergroup(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_USERGROUP');
    }

    canDeleteUsergroup(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_USERGROUP');
    }

    canReadUsergroup(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_USERGROUP');
    }

    canCreateSubsystem(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_SUBSYSTEM');
    }

    canEditSubsystem(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_SUBSYSTEM');
    }

    canDeleteSubsystem(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_SUBSYSTEM');
    }

    canReadSubsystem(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_SUBSYSTEM');
    }

    canCreatePricelist(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_PRICELIST');
    }

    canEditPricelist(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_PRICELIST');
    }

    canDeletePricelist(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_PRICELIST');
    }

    canReadPricelist(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_PRICELIST');
    }

    canReadDealerDiscount(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_DEALER_DISCOUNT');
    }

    canEditDealerDiscount(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_DEALER_DISCOUNT');
    }

    canEditRotSettings(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_ROT_SETTINGS');
    }

    canCreateSupplier(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_SUPPLIER');
    }

    canEditSupplier(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_SUPPLIER');
    }

    canDeleteSupplier(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_SUPPLIER');
    }

    canReadSupplier(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_SUPPLIER');
    }

    canCreateDistanceFrame(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_DISTANCE_FRAME');
    }

    canEditDistanceFrame(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_DISTANCE_FRAME');
    }

    canReadDistanceFrame(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_DISTANCE_FRAME');
    }

    canCreateCatalogItem(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_CATALOG_ITEM');
    }

    canEditCatalogItem(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_CATALOG_ITEM');
    }

    canDeleteCatalogItem(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_CATALOG_ITEM');
    }

    canReadCatalogItem(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_CATALOG_ITEM');
    }

    canCreateOffer(): boolean {
        return this.roles.userRoles.includes('ROLE_CREATE_OFFER');
    }

    canEditOffer(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_OFFER');
    }

    canDeleteOffer(): boolean {
        return this.roles.userRoles.includes('ROLE_DELETE_OFFER');
    }

    canReadOffer(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_OFFER');
    }

    canCopyOffer(): boolean {
        return this.roles.userRoles.includes('ROLE_COPY_OFFER');
    }

    canCopyOfferToPartner(): boolean {
        return this.roles.userRoles.includes('ROLE_COPY_OFFER_TO_PARTNER');
    }

    canLoginDuringMaintenance(): boolean {
        return this.roles.userRoles.includes('ROLE_LOGIN_DURING_MAINTENANCE');
    }

    canReadWebshopDetails(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_WEBSHOP_DETAILS');
    }

    canViewTranslationEditUi(): boolean {
        return this.roles.userRoles.includes('ROLE_VIEW_TRANSLATION_EDIT_UI');
    }

    canEditTranslation(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_TRANSLATION');
    }

    isAuthTokenNeverExpiring(): boolean {
        return this.roles.userRoles.includes('ROLE_TOKEN_NEVER_EXPIRES');
    }

    canDisableOfferValidation(): boolean {
        return this.roles.userRoles.includes('ROLE_DISABLE_OFFER_VALIDATION');
    }

    canReadNews(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_NEWS');
    }

    canEditNews(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_NEWS');
    }

    canEditMotlawaConfiguration(): boolean {
        return this.roles.userRoles.includes('ROLE_CAN_EDIT_MOTLAWA_CONFIGURATION');
    }

    canViewDetailedPricing(): boolean {
        return this.roles.userRoles.includes('ROLE_READ_DETAILED_PRICING');
    }

    canEditSubsystemSalesTarget(): boolean {
        return this.roles.userRoles.includes('ROLE_EDIT_SUBSYSTEM_SALES_TARGET');
    }
}
