<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(GateSystemField.NAME)" [disabled]="fieldUsage.disabled(GateSystemField.NAME)"
                    label="{{ 'GATE_SYSTEM.FORM.NAME' | translate }}"
                    validationKeyPrefix="name" [validationErrors]="validationErrors" [maxlength]="100"
                    inputId="name"
                    [required]="false" [field]="item.name"></app-translation-fields>

    <app-translation-fields *ngIf="fieldUsage.show(GateSystemField.SHORTCUT)" [disabled]="fieldUsage.disabled(GateSystemField.SHORTCUT)"
                    label="{{ 'GATE_SYSTEM.FORM.SHORTCUT' | translate }}"
                    validationKeyPrefix="shortcut" [validationErrors]="validationErrors" [maxlength]="100"
                    inputId="shortcut"
                    [required]="false" [field]="item.shortcut"></app-translation-fields>

    <div *ngIf="fieldUsage.show(GateSystemField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'GATE_SYSTEM.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(GateSystemField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="50"
                        [required]="true" [(ngModel)]="item.symbol">
        </app-input-text>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GateSystemField.PCN)">
        <div class="new-form-field">
            <label for="pcn">{{ 'GATE_SYSTEM.FORM.PCN' | translate }}</label>
            <div class="new-form-field-input-container">
                <p-inputMask [ngModel]="item.pcn" id="pcn" inputId="pcn" ngDefaultControl
                             [disabled]="fieldUsage.disabled(GateSystemField.PCN)"
                             (ngModelChange)="item.pcn = $event; validationErrors['pcn'] = undefined"
                             mask="PCN 99 99 99 99" placeholder="PCN 99 99 99 99"></p-inputMask>
                <span *ngIf="validationErrors['pcn'] != undefined" class="new-form-field-error-icon material-icons">error</span>
                <div *ngIf="validationErrors['pcn'] != undefined" class="new-form-field-error-message">
                    {{ validationErrors['pcn'] | translate }}
                </div>
            </div>
        </div>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GateSystemField.SUPPLIER)">
        <app-select inputId="supplier" label="{{ 'GATE_SYSTEM.FORM.SUPPLIER' | translate }}"
                    [disabled]="fieldUsage.disabled(GateSystemField.SUPPLIER)"
                    [(validationMessageKey)]="validationErrors['supplier']" [options]="suppliers" [required]="true"
                    [optionKey]="getSupplierKey" [(ngModel)]="item.supplier"></app-select>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GateSystemField.DIMENSION_STEP)">
        <app-input-number inputId="dimensionStep" label="{{ 'GATE_SYSTEM.FORM.DIMENSION_STEP' | translate }}"
                          [disabled]="fieldUsage.disabled(GateSystemField.DIMENSION_STEP)"
                          [(validationMessageKey)]="validationErrors['dimensionStep']" [required]="true"
                          [(ngModel)]="item.dimensionStep" [min]="1"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GateSystemField.UNIT_WEIGHT)">
        <app-input-number inputId="unitWeight" label="{{ 'GATE_SYSTEM.FORM.UNIT_WEIGHT' | translate }}"
                          [disabled]="fieldUsage.disabled(GateSystemField.UNIT_WEIGHT)"
                          [(validationMessageKey)]="validationErrors['unitWeight']" [required]="true"
                          [(ngModel)]="item.unitWeight" [min]="0" [max]="9999"></app-input-number>
    </div>

    <div *ngIf="fieldUsage.show(GateSystemField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'GATE_SYSTEM.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(GateSystemField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(GateSystemField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'GATE_SYSTEM.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(GateSystemField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="9999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(GateSystemField.IMAGE_SECTION)">
        <p-accordion>
            <p-accordionTab header="{{ 'GATE_SYSTEM.FORM.IMAGE_SECTION' | translate }}" [selected]="false"
                            [ngClass]="{'accordion-header-with-errors': validationErrors['image']}">
                <app-file-upload inputId="image" [ngModel]="file"
                                 (ngModelChange)="onFileChange($event)"
                                 [disabled]="fieldUsage.disabled(GateSystemField.IMAGE_SECTION)"
                                 [maxSize]="500000" [maxWidth]="2000" [maxHeight]="2000"
                                 [addButtonLabel]="'GATE_SYSTEM.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'GATE_SYSTEM.FORM.CHANGE_FILE_UPLOAD'"
                                 [deleteButtonLabel]="'GATE_SYSTEM.FORM.DELETE_FILE_UPLOAD'"
                                 [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>
</div>
