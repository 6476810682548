import {MultilanguageField} from '../../../../../../supportedLanguages';
import {ConfigAddonApplication} from "../../../../../../../window-designer/enums/ConfigAddonApplication";

export class ConfigurableAddonDefinitionSettings {
    definitionId: number;
    supplierId: number;
    active: boolean;
    hiddenOnOffer: string[];
    hiddenOnOrder: string[];
    messagesHiddenOnOffer: string[];
    messagesHiddenOnOrder: string[];
    applicableTo: ConfigAddonApplication[];
    sortIndex: number;
    description: MultilanguageField;
    canBeAddedToAreaWithOuterGrill: boolean;
    canBeAddedToAreaWithoutFilling: boolean;
    canBeAddedToAreaWithOtherFilling: boolean;
    canBeAddedToAreaWithDecorativeFilling: boolean;
}
