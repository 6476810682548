import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {MultilanguageFieldInterface} from '../../../window-designer/catalog-data/multilanguage-field-interface';
import {Listing, mapListingToJson} from '../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {SupportedLanguages} from '../../supportedLanguages';
import {TranslationCatalog} from './translation-catalog';
import {TranslationItem} from './translation-item';
import {TranslationItemList} from './translation-item-list';
import {TranslationTarget} from './ui-translations/translation-target.enum';
import {UiTranslation} from './ui-translations/ui-translation';

export interface TranslationImportStatus {
    key: string;
    target: TranslationTarget;
    changedLanguages: string[];
    newTexts: MultilanguageFieldInterface;
    keyValid: boolean;
}

@Injectable()
export class TranslationsService {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getTranslations(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata }, sortColumn: string,
                    sortOrder: number, entityName: string, entityField: string): Observable<TranslationItemList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        params['entityName'] = entityName;
        params['entityField'] = entityField;
        params['multilangFilter'] = JSON.stringify(this.createMultilangFilter(filters));

        return this.http.get<TranslationItemList>('translations', {params: params});
    }

    private createMultilangFilter(filters: any): any {
        let multilangFilter = {};

        SupportedLanguages.languages.forEach(languageCode => {
            if (filters[languageCode.code] != null && filters[languageCode.code].value) {
                multilangFilter[languageCode.code] = filters[languageCode.code].value;
            }
        });

        return multilangFilter;
    }

    getCatalog(): Observable<TranslationCatalog> {
        return this.http.get<TranslationCatalog>('translations/catalog');
    }

    save(entityName: string, entityField: string, translationItem: TranslationItem) {
        return this.http.put<void>(`translations/${entityName}/${entityField}`, translationItem);
    }

    getTranslation(incomingEntityName: string, incomingEntityField: string, incomingEntityId: number): Observable<TranslationItem> {
        return this.http.get<TranslationItem>(`translations/${incomingEntityName}/${incomingEntityField}/${incomingEntityId}`);
    }

    getUiTranslations(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                      sortColumn: string, sortOrder: number): Observable<Listing<UiTranslation>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>('translations/ui', {params: params}).pipe(mapListingToJson(UiTranslation));
    }

    saveUiTranslations(translations: Pick<UiTranslation, 'key' | 'target' | 'text'>): Observable<void> {
        return this.http.post<void>('translations/ui', translations);
    }

    downloadAllUiTranslations(): Observable<Blob> {
        return this.http.get('translations/ui/all', {responseType: 'blob'});
    }

    checkImportedTranslations(csv: string): Observable<TranslationImportStatus[]> {
        return this.http.post<TranslationImportStatus[]>('translations/ui/checkImportedTranslations', csv, {
            headers: {
                'Content-Type': 'text/csv'
            }
        });
    }

    autoTranslateUi(): Observable<void> {
        return this.http.get<void>('translations/autoTranslateUi');
    }
}
