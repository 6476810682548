import {ChangeDetectorRef, Directive, Injector, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {saveAs} from 'file-saver';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {Table} from 'primeng/table';
import {Observable, Subscription} from 'rxjs';
import {finalize} from "rxjs/operators";
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DatatableHelper, DatatableInterface, TableToDatatableInterfaceAdapter} from "../../../common/DatatableHelper";
import {DateRangeFilter} from "../../../common/date-range-filter";
import {UserRoleTypes} from "../../../common/enums/UserRoleTypes";
import {DataTableColumnBuilder} from "../../../common/service/data.table.column.builder";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {SubsystemService} from "../../subsystem/subsystem.service";
import {UserActivityCustomFilter, UserWithActivityData} from './user-activity-data';
import {UserActivityMailReportSettings} from "./user-activity-mail-report-settings";
import {UserActivityReportMailSettingsComponent} from './user-activity-report-mail-settings/user-activity-report-mail-settings.component';
import {UserActivityService} from "./user-activity.service";

@Directive()
export abstract class AbstractUserActivityComponent extends ComponentWithUserConfigAndPaginator
    implements OnInit, OnDestroy {

    readonly MAX_ROWS_WITHOUT_PAGINATION = 2000;

    @ViewChild('dt', {static: true})
    dataTable: Table;

    @ViewChild('reportMailParamsWizardDialog', {static: true})
    reportMailParamsWizardDialog: Dialog;

    @ViewChild('reportMailSettings', {static: true})
    reportMailSettings: UserActivityReportMailSettingsComponent;

    users: UserWithActivityData[];
    selectedUser: UserWithActivityData;
    filterRoles: Observable<SelectItem[]>;
    selectedRoles: string[] = [];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    userLang: string;
    langTranslateSubscription: Subscription;
    lastLoadEvent: LazyLoadEvent;
    selectedSubsystems: string[] = [];
    subsystems: SelectItem[] = [];
    showReportWizard = false;
    criteriaTreeFilter: UserActivityCustomFilter;
    showReportMailWizard = false;
    timeUnits: Observable<SelectItem[]>;
    mailReportSettings: UserActivityMailReportSettings;
    mailCriteriaTreeFilter: UserActivityCustomFilter;

    public readonly permissions: Permissions;
    private readonly userActivityService: UserActivityService;
    private readonly translate: TranslateService;
    private readonly subsystemService: SubsystemService;
    private readonly translatedSelectItemService: TranslatedSelectItemService;
    private readonly errors: CommonErrorHandler;

    protected constructor(public activityType: string,
                          public activityTypeLabel: string,
                          public tableHeader: string,
                          public paginated: boolean,
                          viewName: string,
                          injector: Injector,
                          changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, viewName, false);
        this.permissions = injector.get(Permissions);
        this.userActivityService = injector.get(UserActivityService);
        this.translate = injector.get(TranslateService);
        this.subsystemService = injector.get(SubsystemService);
        this.translatedSelectItemService = injector.get(TranslatedSelectItemService);
        this.errors = injector.get(CommonErrorHandler);
        this.userLang = this.translate.currentLang;
        this.langTranslateSubscription = this.translate.onLangChange.subscribe(event => {
            this.userLang = event.lang;
            this.changeDetector.markForCheck();
        });

        this.initDefaultSortOrder();
    }

    ngOnInit(): void {
        this.initShownColumns();
        this.initSubsystems();
        this.filterRoles = this.translatedSelectItemService.buildSortedDropdown(UserRoleTypes, 'ROLE.', undefined);
        this.timeUnits = this.translatedSelectItemService.buildUnsortedDropdown(['DAYS', 'WEEKS', 'MONTHS'],
            'USER_ACTIVITY_REPORT.FORM.REPORT_FREQUENCY_UNIT.', undefined);
    }

    ngOnDestroy() {
        this.langTranslateSubscription.unsubscribe();
        super.ngOnDestroy();
    }

    private initSubsystems(): void {
        this.subsystemService.getSelectionItems().subscribe({
            next: subsystems => this.subsystems = subsystems,
            error: error => this.errors.handle(error)
        });
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    rowTrackById(index: number, item: UserWithActivityData) {
        return item.id;
    }

    loadUserActivitiesLazy(event: LazyLoadEvent) {
        this.lastLoadEvent = event;
        if (this.paginated) {
            super.loadItemsLazy(event); // save chosenRowsPerPage
        } else {
            this.updateShownColumnsSortOrder(event);
        }

        return this.userActivityService.getUsers(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.users = data.data;
                    this.totalRecords = data.totalRecords;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedUser = this.users[0];
                    DatatableHelper.focusOnRowIfNotEditingFilters(event);
                    this.changeDetector.markForCheck();
                },
                error: error => this.errors.handle(error)
            });
    }

    handleRoleFilterChange(roleNames: string[]): void {
        this.selectedRoles = roleNames;
        this.getDatatable().filter(roleNames.join(';'), 'roleName', 'in');
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.getDatatable().filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    getRoleTranslationKey(name: string): string {
        if (name) {
            return 'ROLE.' + name;
        } else {
            return '';
        }
    }

    onRowSelect(event) {
        this.keepSelectedItemIndex(event);
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.dataTable);
    }

    showDialogToAdd() {
    }

    submit(): void {
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = this.activityType;
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_DESCENDING;
    }

    getSubsystemsName(id: number): string {
        let subsystem = (this.subsystems || []).find(s => s.value === `${id}`);
        return subsystem == null ? '' : subsystem.label;
    }

    exportCSV(): void {
        const event: LazyLoadEvent = this.dataTable.createLazyLoadMetadata();
        this.userActivityService.getReportFile(event.first, event.rows, event.filters, event.sortField, event.sortOrder).subscribe(report => {
            saveAs(report, report.name, true);
        });
    }

    handleCustomFilterChange(): void {
        this.getDatatable().setFilterValue(this.criteriaTreeFilter.criteriaTreeDateRange, 'criteriaTreeDateRange', 'dateRange');
        this.getDatatable().setFilterValue(this.criteriaTreeFilter.criteriaTree, 'criteriaTree', 'contains');
        this.dataTable._filter();
    }

    openMailConfiguration(): void {
        const blockId = 'AbstractUserActivityComponent.mailSettings';
        this.blockUiController.block(blockId)
        this.showReportMailWizard = true;
        this.changeDetector.markForCheck();
        this.userActivityService.getMailReportConfiguration().pipe(
            finalize(() => this.blockUiController.unblock(blockId))
        ).subscribe({
            next: mailSettings => {
                this.mailReportSettings = mailSettings;
                this.mailCriteriaTreeFilter = {
                    criteriaTreeDateRange: new DateRangeFilter(undefined, undefined, mailSettings.reportDateRange),
                    criteriaTree: mailSettings.reportCriteriaTree
                };
                this.changeDetector.markForCheck();
            },
            error: err => this.errors.handle(err)
        });
    }

    handleReportConfigurationSave(event: MouseEvent): void {
        this.reportMailSettings.validateForm();
        if (this.reportMailSettings.validationErrorsPresent()) {
            return;
        }
        this.reportMailSettings.save();
        this.mailReportSettings.reportDateRange = this.mailCriteriaTreeFilter.criteriaTreeDateRange.kind;
        this.mailReportSettings.reportCriteriaTree = this.mailCriteriaTreeFilter.criteriaTree;
        this.userActivityService.updateMailReportConfiguration(this.mailReportSettings).subscribe({
            next: () => {
                this.reportMailParamsWizardDialog.close(event);
                this.changeDetector.markForCheck();
            },
            error: err => this.errors.handle(err)
        });
    }
}
