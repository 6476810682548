import {Component, EventEmitter, Input, Output} from '@angular/core';
import {UserConsent} from '../../features/admin-panel/user-consents/userConsentsData';

@Component({
    selector: 'app-user-consents-dialog',
    templateUrl: './user-consents-dialog.component.html',
    styleUrls: ['./user-consents-dialog.component.css']
})
export class UserConsentsDialogComponent {

    @Input() userConsents: UserConsent[];
    @Input() dialogId: string;

    @Output() onCancel = new EventEmitter();

    cancel() {
        this.onCancel.emit();
    }
}
