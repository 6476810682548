import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {BaseAddonProfitMarginService} from '../../../common/addon-profit-margin/base-addon-profit-margin.service';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {SubsystemAddonProfitMargin} from './subsystemAddonProfitMargin';

@Injectable()
export class SubsystemAddonProfitMarginService extends BaseAddonProfitMarginService<SubsystemAddonProfitMargin> {

    private static readonly API_URL = 'subsystemAddonsProfitMargins';

    constructor(http: HttpClient, dataServiceHelper: DataServiceHelper) {
        super(http, dataServiceHelper, SubsystemAddonProfitMarginService.API_URL);
    }

    overrideGroupMemberMargins(entityId: number, profitMargins: SubsystemAddonProfitMargin[]): Observable<void> {
        return undefined;
    }
}
