import {ColorInterface} from '../../../../window-designer/catalog-data/color-interface';
import {DecorativeFillingInterface} from '../../../../window-designer/catalog-data/decorative-filling-interface';
import {DecorativeFillingType} from "../../../../window-designer/catalog-data/decorative-filling-type";
import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class DecorativeFilling implements CrudItem, DecorativeFillingInterface {
    id: number;
    active: boolean;
    thickness: number;
    symbol: string;
    names: MultilanguageField;
    shortcut: MultilanguageField;
    insideColors: ColorInterface[];
    outsideColors: ColorInterface[];
    coreColor: ColorInterface;
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;
    glazingWidths: string;
    glazingSurface: number;
    minimalWidth: number;
    maximumWidth: number;
    minimalHeight: number;
    maximumHeight: number;
    type: DecorativeFillingType;
    sortIndex: number;
    unitWeight: number;

    constructor() {
        this.id = undefined;
        this.active = true;
        this.thickness = undefined;
        this.glazingWidths = '';
        this.glazingSurface = undefined;
        this.names = new MultilanguageField();
        this.shortcut = new MultilanguageField();
        this.insideColors = [];
        this.outsideColors = [];
        this.pcv = "UNCHECKED";
        this.aluminium = "UNCHECKED";
        this.wood = "UNCHECKED";
        this.aluminiumWoodMix = "UNCHECKED";
        this.type = DecorativeFillingType.TTEMP;
        this.minimalWidth = undefined;
        this.maximumWidth = undefined;
        this.minimalHeight = undefined;
        this.maximumHeight = undefined;
        this.sortIndex = 1;
        this.unitWeight = undefined;
    }
}
