<div class="list-content">
    <div class="table-header">
        <div class="title">{{'BUSINESSTYPE.TITLE' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column *ngIf="fieldUsage.show(BusinessTypeField.WINDOW_TYPE_CODE)"
                  field="type" header="{{ 'BUSINESSTYPE.FORM.WINDOWTYPECODE' | translate }}" [sortable]="false"
                  [filter]="showFilters" filterMatchMode="equals" [filterValues]="filterTypes | async">
            <ng-template pTemplate="body" let-businessType="rowData">
                {{ nameParser(businessType.type)}}
            </ng-template>
        </p-column>
        <p-column *ngIf="fieldUsage.show(BusinessTypeField.NAME)"
                  field="name" header="{{ 'BUSINESSTYPE.FORM.NAME' | translate }}" [sortable]="true" [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-businessType="rowData">
                {{businessType.names[userLang]}}
            </ng-template>
        </p-column>
        <p-column [sortable]="true" field="sortIndex" header="{{ 'BUSINESSTYPE.FORM.SORT_INDEX' | translate }}"></p-column>
        <p-column *ngIf="fieldUsage.show(BusinessTypeField.ACTIVE)" [sortable]="false" field="active" header="{{ 'BUSINESSTYPE.FORM.STATUS' | translate }}"
                  [filter]="showFilters"
                  [filterValues]="filterActive | selectItemTranslate" [defaultFilterValue]="defaultActiveFilter">
            <ng-template pTemplate="body" let-businessType="rowData">
                {{(businessType.active ? 'BUSINESSTYPE.FORM.ACTIVE' : 'BUSINESSTYPE.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<p-dialog header="{{ 'BUSINESSTYPE.UNLINK.TITLE' | translate }}" *ngIf="displayYesNoDialog" [visible]="true"
          [resizable]="false" [modal]="true" (onHide)="continueWithUnlink(false)" [focusOnShow]="false">
    <p>{{ 'BUSINESSTYPE.UNLINK.MESSAGE' | translate }}</p>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" icon="close" type="action"
                               (onClick)="continueWithUnlink(false)"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.YES' | translate }}" icon="check" type="action"
                               (onClick)="continueWithUnlink(true)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<app-wizard-dialog *ngIf="displayDialog && isPermitted({roles:['ROLE_KOORDYNATOR']})"
                   header="{{ 'BUSINESSTYPE.FORM.EDIT-TITLE' | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'BUSINESSTYPE.STEPS.DATA' | translate }}" [id]="STEPS.DATA" [validate]="validateDataStep">
        <app-business-type-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors"></app-business-type-form>
    </app-wizard-step>

    <app-wizard-step label="{{ 'BUSINESSTYPE.STEPS.SYSTEMS' | translate }}" [id]="STEPS.SYSTEMS"
                     *ngIf="fieldUsage.show(CatalogTab.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogTab.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems" [ngModel]="selectedWindowSystems"
                                (ngModelChange)="handleSelectedWindowSystemsChange($event)"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
