<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.PRINTOUT_DATA.GENERAL' | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-600">

            <div class="new-form-row">
                <h3>{{ 'PRINTOUT_DATA.COMMON_DATA' | translate }}</h3>
            </div>

            <div class="new-form-row">
                <app-select inputId="defaultCurrency" disabled [ngModel]="subsystem.defaultCurrency"
                            label="{{ translationKeys.subsystem.general | translate }} - {{ 'SUBSYSTEM-DETAILS.FORM.DEFAULT_CURRENCY' | translate }}"
                            [options]="currencyOptions | async"></app-select>
            </div>

            <div class="new-form-row">
                <h3>{{ 'OFFER.POSITIONS.DIALOGS.PRINT.GLAMOUR_TAB_LABEL' | translate }}</h3>
            </div>

            <ng-container *ngIf="isPermitted({roles:['ROLE_SPRZEDAWCA', 'ROLE_OPERATOR']})">
                <div class="new-form-row">
                    <div class="blue-text-button">
                        <app-simple-button buttonId="download-default-glamour-annotations"
                                           label="{{ 'PRINTOUT_DATA.DOWNLOAD_DEFAULT_GLAMOUR_ANNOTATIONS' | translate }}"
                                           (onClick)="downloadDefaultGlamourAnnotations()"></app-simple-button>
                    </div>
                </div>

                <div class="new-form-row">
                    <app-file-upload inputId="glamourAnnotations" [ngModel]="glamourAnnotations"
                                     label="{{ 'PRINTOUT_DATA.SET_OWN_GLAMOUR_ANNOTATIONS' | translate }}"
                                     addButtonLabel="PRINTOUT_DATA.SELECT_FILE_UPLOAD"
                                     changeButtonLabel="PRINTOUT_DATA.CHANGE_FILE_UPLOAD"
                                     deleteButtonLabel="PRINTOUT_DATA.DELETE_FILE_UPLOAD"
                                     [maxSize]="7000000" [image]="false" accept=".doc,.docx"
                                     (ngModelChange)="changeFile($event, printoutFileType.GLAMOUR_ANNOTATIONS)"
                                     [(validationMessageKey)]="validationErrors['glamourAnnotations']"></app-file-upload>
                </div>
            </ng-container>

            <div class="new-form-row">
                <app-file-upload inputId="logoGlamour" [ngModel]="logoGlamourFile"
                                 [disabled]="!isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})"
                                 label="{{ 'PRINTOUT_DATA.LOGO_GLAMOUR' | translate }}"
                                 (ngModelChange)="changeFile($event, printoutFileType.LOGO_GLAMOUR)"
                                 [maxSize]="1000000" [maxWidth]="3000" [maxHeight]="3000"
                                 [widthToHeightRatio]="2.7"
                                 addButtonLabel="PRINTOUT_DATA.SELECT_FILE_UPLOAD"
                                 changeButtonLabel="PRINTOUT_DATA.CHANGE_FILE_UPLOAD"
                                 deleteButtonLabel="PRINTOUT_DATA.DELETE_FILE_UPLOAD"
                                 [(validationMessageKey)]="validationErrors['logoGlamour']"></app-file-upload>
            </div>

            <div class="new-form-row">
                <app-file-upload inputId="glamourTitlePageImage" [ngModel]="glamourTitlePageImage"
                                 [disabled]="!isPermitted({roles:['ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']})"
                                 label="{{ 'PRINTOUT_DATA.GLAMOUR_TITLE_PAGE_IMAGE' | translate }}"
                                 (ngModelChange)="changeFile($event, printoutFileType.GLAMOUR_TITLE_PAGE)"
                                 [maxSize]="1000000" [maxWidth]="3000" [maxHeight]="3000"
                                 [widthToHeightRatio]="1"
                                 addButtonLabel="PRINTOUT_DATA.SELECT_FILE_UPLOAD"
                                 changeButtonLabel="PRINTOUT_DATA.CHANGE_FILE_UPLOAD"
                                 deleteButtonLabel="PRINTOUT_DATA.DELETE_FILE_UPLOAD"
                                 [(validationMessageKey)]="validationErrors['glamourTitlePageImage']"
                ></app-file-upload>
            </div>

            <ng-container *ngIf="subsystem?.webShopEnabled">
                <div class="new-form-row">
                    <h3>{{ 'SETTINGS.SECTION.PRINTOUT_DATA.WEBSHOP_PRINT' | translate }}</h3>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="subsystemWebshopInfo.street" disabled
                                    [ngModel]="subsystemWebshopInfo.street"
                                    label="{{ translationKeys.subsystemWebshopInfo.general | translate }} - {{ translationKeys.subsystemWebshopInfo.address | translate }} - {{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.STREET' | translate }}"
                    ></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="subsystemWebshopInfo.city" disabled [ngModel]="subsystemWebshopInfo.city"
                                    label="{{ translationKeys.subsystemWebshopInfo.general | translate }} - {{ translationKeys.subsystemWebshopInfo.address | translate }} - {{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.CITY' | translate }}"
                    ></app-input-text>
                </div>

                <div class="new-form-row">
                    <app-input-text inputId="subsystemWebshopInfo.zip" disabled [ngModel]="subsystemWebshopInfo.zip"
                                    label="{{ translationKeys.subsystemWebshopInfo.general | translate }} - {{ translationKeys.subsystemWebshopInfo.address | translate }} - {{ 'SUBSYSTEM_WEBSHOP_INFO_SETTINGS.FORM.ZIP' | translate }}"
                    ></app-input-text>
                </div>

            </ng-container>
        </div>
        <div class="new-form-600">
            <div class="new-form-row">
                <h3>{{ 'OFFER.POSITIONS.DIALOGS.PRINT.REGULAR_TAB_LABEL' | translate }}</h3>
            </div>

            <ng-container *ngIf="isPermitted({roles:['ROLE_SPRZEDAWCA', 'ROLE_OPERATOR']})">
                <div class="new-form-row">
                    <div class="blue-text-button">
                        <app-simple-button buttonId="download-default-confirmation-template"
                                           label="{{ 'PRINTOUT_DATA.DOWNLOAD_DEFAULT_TEMPLATE' | translate }}"
                                           (onClick)="downloadDefaultTemplate()"></app-simple-button>
                    </div>
                </div>

                <div class="new-form-row">
                    <app-file-upload inputId="template" [ngModel]="template"
                                     label="{{ 'PRINTOUT_DATA.SET_OWN_DEFAULT_TEMPLATE' | translate }}"
                                     addButtonLabel="PRINTOUT_DATA.SELECT_FILE_UPLOAD"
                                     changeButtonLabel="PRINTOUT_DATA.CHANGE_FILE_UPLOAD"
                                     deleteButtonLabel="PRINTOUT_DATA.DELETE_FILE_UPLOAD"
                                     [maxSize]="7000000" [image]="false" accept=".doc,.docx"
                                     (ngModelChange)="changeFile($event, printoutFileType.TEMPLATE)"
                                     [(validationMessageKey)]="validationErrors['template']"></app-file-upload>
                </div>

                <div class="new-form-row">
                    <div class="blue-text-button">
                        <app-simple-button buttonId="download-default-template"
                                           label="{{ 'PRINTOUT_DATA.DOWNLOAD_DEFAULT_CONFIRMATION_TEMPLATE' | translate }}"
                                           (onClick)="downloadDefaultConfirmationTemplate()"></app-simple-button>
                    </div>
                </div>

                <div class="new-form-row">
                    <app-file-upload inputId="template" [ngModel]="confirmationTemplate"
                                     label="{{ 'PRINTOUT_DATA.SET_OWN_DEFAULT_CONFIRMATION_TEMPLATE' | translate }}"
                                     addButtonLabel="PRINTOUT_DATA.SELECT_FILE_UPLOAD"
                                     changeButtonLabel="PRINTOUT_DATA.CHANGE_FILE_UPLOAD"
                                     deleteButtonLabel="PRINTOUT_DATA.DELETE_FILE_UPLOAD"
                                     [maxSize]="7000000" [image]="false" accept=".doc,.docx"
                                     (ngModelChange)="changeFile($event, printoutFileType.CONFIRMATION_TEMPLATE)"
                                     [(validationMessageKey)]="validationErrors['confirmationTemplate']"></app-file-upload>
                </div>
            </ng-container>

            <div class="new-form-row">
                <app-input-text inputId="name" disabled [ngModel]="isSeller ? seller.name : subsystem.name"
                                label="{{ translationKeys[isSeller ? 'seller' : 'subsystem'].general | translate }} - {{ 'SUBSYSTEM-DETAILS.FORM.NAME' | translate }}"
                                pTooltip="${SELLER_NAME}"
                                tooltipPosition="top"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="phoneNumber" disabled
                                [ngModel]="isSeller ? seller.phoneNumber : subsystem.phoneNumber"
                                label="{{ translationKeys[isSeller ? 'seller' : 'subsystem'].general | translate }} - {{ 'SUBSYSTEM-DETAILS.FORM.PHONE_NUMBER' | translate }}"
                                pTooltip="${SELLER_PHONE}"
                                tooltipPosition="top"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="correspondenceAddress.street" disabled
                                [ngModel]="correspondenceAddress?.address.street"
                                label="{{ translationKeys[isSeller ? 'seller' : 'subsystem'].general | translate }} - {{ translationKeys[isSeller ? 'seller' : 'subsystem'].address | translate }} - {{ 'SUBSYSTEM-DETAILS.FORM.STREET' | translate }}"
                                pTooltip="${SELLER_STREET}"
                                tooltipPosition="top"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-text inputId="correspondenceAddress.city" disabled
                                [ngModel]="correspondenceAddress?.address.city"
                                label="{{ translationKeys[isSeller ? 'seller' : 'subsystem'].general | translate }} - {{ translationKeys[isSeller ? 'seller' : 'subsystem'].address | translate }} - {{ 'SUBSYSTEM-DETAILS.FORM.CITY' | translate }}"
                                pTooltip="${SELLER_CITY}"
                                tooltipPosition="top"></app-input-text>
            </div>

            <div class="new-form-row">
                <app-input-zip inputId="correspondenceAddress.zip" disabled
                               [ngModel]="correspondenceAddress?.address.zip"
                               label="{{ translationKeys[isSeller ? 'seller' : 'subsystem'].general | translate }} - {{ translationKeys[isSeller ? 'seller' : 'subsystem'].address | translate }} - {{ 'SUBSYSTEM-DETAILS.FORM.ZIP' | translate }}"
                               pTooltip="${SELLER_ZIP}"
                               tooltipPosition="top"
                               [country]="correspondenceAddress?.address.country"></app-input-zip>
            </div>

            <div class="new-form-row">
                <app-file-upload inputId="image" disabled [ngModel]="signature"
                                 label="{{ translationKeys.user | translate }} - {{ 'USER-DETAILS.FORM.SIGNATURE' | translate }}"
                                 pTooltip="${USER_SIGNATURE}"
                                 tooltipPosition="top"
                                 [changeButtonLabel]="'USER-DETAILS.FORM.SELECT_FILE_UPLOAD'"></app-file-upload>
            </div>
        </div>
    </div>

    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" icon="check" type="main-action"
                               [size]="40" (onClick)="saveChanges()"></app-simple-button>
        </div>
    </div>

</div>
