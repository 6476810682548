import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";
import {Glazing} from "../../../../window-designer/catalog-data/glazing";

export class GraspGlazingPackage implements CrudItem {
    id: number;
    name: MultilanguageField;
    symbol: string;
    glazingCategoryId: number;
    frameCategoryId: number;
    glazingBeadId: number;
    glazing: Glazing;
    active = true;
    sortIndex: number;

    static fromJSON(jsonObject: any): GraspGlazingPackage {
        const glazingPackage = new GraspGlazingPackage();
        glazingPackage.id = jsonObject.id;
        glazingPackage.name = MultilanguageField.fromJSON(jsonObject.name);
        glazingPackage.symbol = jsonObject.symbol;
        glazingPackage.glazingCategoryId = jsonObject.glazingCategoryId;
        glazingPackage.frameCategoryId = jsonObject.frameCategoryId;
        glazingPackage.glazingBeadId = jsonObject.glazingBeadId;
        glazingPackage.glazing = jsonObject.glazing;
        glazingPackage.active = jsonObject.active;
        glazingPackage.sortIndex = jsonObject.sortIndex;
        return glazingPackage;
    }
}
