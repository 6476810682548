import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {CatalogProperty} from './catalog-property';

@Injectable()
export class CatalogPropertyService implements CrudService<CatalogProperty> {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<CatalogProperty>> {

        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<CatalogProperty>>('catalogProperty', {params: params});
    }

    getItem(itemId: number): Observable<CatalogProperty> {
        return this.http.get<CatalogProperty>(`catalogProperty/${itemId}`);
    }

    addItem(item: CatalogProperty): Observable<number> {
        // handled by saveCatalogProperty
        return this.saveCatalogProperty(item);
    }

    editItem(itemId: number, item: CatalogProperty): Observable<number> {
        // handled by saveCatalogProperty
        return this.saveCatalogProperty(item);
    }

    saveCatalogProperty(item: CatalogProperty): Observable<number> {
        return this.http.post('catalogProperty', item, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('catalogProperty', response)));
    }

    copy(id: number, item: CatalogProperty): Observable<number> {
        return this.http.post(`catalogProperty/${id}/copy`, item, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('catalogProperty', response)));
    }

    validate(property: CatalogProperty): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('catalogProperty/validate', property));
    }
}
