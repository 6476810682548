import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {RequiresExitConfirmation} from "./requires-exit-confirmation";

@Injectable()
export class RequiresExitConfirmationGuard  {

    canDeactivate(
        component: RequiresExitConfirmation,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (component.onExitAttempt) {
            return component.onExitAttempt();
        }
        return true;
    }
}
