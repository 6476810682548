import * as _ from 'underscore';
import {DrawingData} from "./drawing-data/drawing-data";
import {SubWindowData} from "./drawing-data/SubWindowData";
import {SubWindowShape} from "./drawing-data/SubWindowShape";
import {AreaUtils} from "./utils/AreaUtils";
import {ErrorNames} from "./utils/ErrorNames";
import {OperationResult} from "./utils/OperationResult";

export class DrawingDataChangeHelper {
    public static processRequiredChanges(oldData: DrawingData, newData: DrawingData): OperationResult {
        AreaUtils.setSubwindowShapes(newData);
        return this.removeVentilationFromArcSubwindows(oldData, newData);
    }

    private static removeVentilationFromArcSubwindows(oldData: DrawingData, newData: DrawingData): OperationResult {
        let removed = false;
        let oldSubwindows: SubWindowData[] = _.flatten(oldData.windows.map(w => w.subWindows));
        newData.windows.forEach(w => w.subWindows.forEach(newSubwindow => {
            let oldSubwindow = oldSubwindows.find(s => s.generatedId === newSubwindow.generatedId);
            if (this.subwindowChangedToArc(oldSubwindow, newSubwindow)) {
                if (this.subwindowContainsVentilation(newSubwindow)) {
                    removed = true;
                }
                newSubwindow.ventilator.addonId = undefined;
                newSubwindow.drip.addonId = undefined;
                newSubwindow.coupler.addonId = undefined;
            }
        }));
        let result = new OperationResult();
        if (removed) {
            result.addGrowls([ErrorNames.VENTILATION_ADDONS_AUTOMATICALLY_REMOVED]);
        }
        return result;
    }

    private static subwindowChangedToArc(oldSubwindow: SubWindowData, newSubwindow: SubWindowData): boolean {
        return oldSubwindow.shape !== SubWindowShape.ARC && newSubwindow.shape === SubWindowShape.ARC;
    }

    private static subwindowContainsVentilation(subwindow: SubWindowData): boolean {
        return subwindow.ventilator.addonId != null || subwindow.drip.addonId != null || subwindow.coupler.addonId != null;
    }
}
