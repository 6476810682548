import {SubWindowTypeCode} from "./subwindow-type-code";

export class SubwindowAttributes {
    type: SubWindowTypeCode;
    isLeading: boolean;

    constructor() {
        this.type = undefined;
        this.isLeading = false;
    }
}
