import * as _ from 'underscore';
import {AreaSpecification} from './drawing-data/AreaSpecification';
import {CompositionType} from "./drawing-data/CompositionType";
import {CutData} from './drawing-data/CutData';
import {Mullion} from './drawing-data/Mullion';
import {SubWindowData} from "./drawing-data/SubWindowData";
import {UfElementType} from './drawing-data/UfElementType';
import {DrawingUtil, MinMaxXY} from "./drawing-util";
import {ProfilesCompositionDistances, ProfilesCompositionType} from "./profiles-composition-distances";
import {GrillHelper} from './utils/grill-helper';
import {MullionHelper} from "./utils/MullionHelper";
import {MullionUtils} from "./utils/MullionUtils";
import {WindowCalculator} from './window-calculator';

export abstract class UfCalculator {

    static calculateUfData(subwindow: SubWindowData, totalBoundingBox: MinMaxXY, profileCompositionDistances: ProfilesCompositionDistances,
                           cuts: CutData[], skipValidation: boolean): UfDataElement[] {
        // glazing bead box points
        let glazingBeadPoints = WindowCalculator.getTotalGlazingBeadsPoints(subwindow, cuts, totalBoundingBox, profileCompositionDistances,
            skipValidation, false);
        let beadBox = DrawingUtil.calculateMinMaxFromPolygon(glazingBeadPoints, false);

        // determine areas of subwindow side
        let {left, right, top, bottom, groupedData} = this.getGroupedAreas(subwindow, beadBox, totalBoundingBox,
            profileCompositionDistances);

        // calculate mullion lengths
        let mullionLengths: { [id: number]: number } = {};
        subwindow.areasSpecification.forEach(area => {
            let points = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions, glazingBeadPoints,
                profileCompositionDistances);
            let areaBox = DrawingUtil.calculateMinMaxFromPolygon(points, false);

            let getMullionOnSide = (side: Side) => this.findMullion(side, area, areaBox, subwindow);
            let vertical = (areaBox.maxY - areaBox.minY) / 1000;
            let horizontal = (areaBox.maxX - areaBox.minX) / 1000;

            let leftElementType = areaBox.minX === beadBox.minX ? left.elementType : UfElementType.MULLION;
            let areaLeftSide = {
                elementType: leftElementType,
                length: vertical,
                id: leftElementType === UfElementType.MULLION ? getMullionOnSide(Side.LEFT).id : null
            };

            let rightElementType = areaBox.maxX === beadBox.maxX ? right.elementType : UfElementType.MULLION;
            let areaRightSide = {
                elementType: rightElementType,
                length: vertical,
                id: rightElementType === UfElementType.MULLION ? getMullionOnSide(Side.RIGHT).id : null
            };

            let topElementType = areaBox.minY === beadBox.minY ? top.elementType : UfElementType.MULLION;
            let areaTopSide = {
                elementType: topElementType,
                length: horizontal,
                id: topElementType === UfElementType.MULLION ? getMullionOnSide(Side.TOP).id : null
            };

            let bottomElementType = areaBox.maxY === beadBox.maxY ? bottom.elementType : UfElementType.MULLION;
            let areaBottomSide = {
                elementType: bottomElementType,
                length: horizontal,
                id: bottomElementType === UfElementType.MULLION ? getMullionOnSide(Side.BOTTOM).id : null
            };

            [areaLeftSide, areaRightSide, areaTopSide, areaBottomSide].forEach(side => {
                if (side.elementType === UfElementType.MULLION) {
                    mullionLengths[side.id] = mullionLengths.hasOwnProperty(side.id) ? mullionLengths[side.id] + side.length : side.length;
                } else {
                    groupedData[side.elementType].length = groupedData[side.elementType].length + side.length;
                }
            });
        });

        // calculate areas of mullions
        let mullionAreas: { [id: number]: number } = {};
        let sorted = [...subwindow.mullions].sort(this.horizontalMullionsLast);
        for (let i = 0; i < sorted.length; i++) {
            let mullion = sorted[i];
            let points = MullionUtils.getMullionPoints(mullion, glazingBeadPoints, subwindow, profileCompositionDistances, true);
            let mullionArea = this.getAreaFromPoints(points);
            for (let j = i + 1; j < sorted.length; j++) {
                let otherPoints = MullionUtils.getMullionPoints(sorted[j], glazingBeadPoints, subwindow, profileCompositionDistances, true);
                if (DrawingUtil.doPolygonsOverlap(points, otherPoints)) {
                    let overlapPoints = DrawingUtil.getPolygonsOverlapPoints(points, otherPoints);
                    mullionArea -= this.getAreaFromPoints(overlapPoints);
                }
            }
            mullionAreas[mullion.id] = mullionAreas.hasOwnProperty(mullion.id) ? mullionAreas[mullion.id] + mullionArea : mullionArea;
        }

        let dataArr = _.pairs(groupedData).map(pair => {
            return {
                id: undefined as number,
                elementType: pair[0],
                ...pair[1]
            };
        });

        let mullionsData = Object.keys(mullionLengths).map(id => {
            const idNumber = +id;
            return {
                id: idNumber,
                length: mullionLengths[idNumber],
                area: mullionAreas[idNumber],
                elementType: UfElementType.MULLION
            };
        });

        return [...dataArr, ...mullionsData]
            .filter(item => (item.length > 0 && item.area > 0))
            .map(item => new UfDataElement(item.id, item.elementType, +item.length.toFixed(5), +item.area.toFixed(5)));
    }

    private static horizontalMullionsLast(m1: Mullion): number {
        return GrillHelper.isHorizontal(m1) ? 1 : -1;
    }

    private static getAreaFromPoints(points: number[]): number {
        let boxPoints = DrawingUtil.calculateMinMaxFromPolygon(points, false);
        return ((boxPoints.maxX - boxPoints.minX) / 1000) * ((boxPoints.maxY - boxPoints.minY) / 1000);
    }

    private static getGroupedAreas(subwindow: SubWindowData, beadBox: MinMaxXY, totalBoundingBox: MinMaxXY,
                                   profileCompositionDistances: ProfilesCompositionDistances) {
        let framePoints = subwindow.points;
        let subwindowBox = DrawingUtil.calculateMinMaxFromPolygon(framePoints, false);

        let isWinged = !WindowCalculator.isSubWindowF(subwindow);
        let getUfElementType = this.getUfElementType(isWinged);

        let profiles = subwindow.profilesComposition;
        let left = {
            area: ((beadBox.minX - subwindowBox.minX) / 1000) * ((beadBox.maxY - beadBox.minY) / 1000),
            elementType: getUfElementType(profiles.left, subwindowBox.minX === totalBoundingBox.minX)
        };
        let right = {
            area: ((subwindowBox.maxX - beadBox.maxX) / 1000) * ((beadBox.maxY - beadBox.minY) / 1000),
            elementType: getUfElementType(profiles.right, subwindowBox.maxX === totalBoundingBox.maxX)
        };
        let top = {
            area: ((subwindowBox.maxX - subwindowBox.minX) / 1000) * ((beadBox.minY - subwindowBox.minY) / 1000),
            elementType: getUfElementType(profiles.top, subwindowBox.minY === totalBoundingBox.minY)
        };

        let useDoorstep = profileCompositionDistances.get(ProfilesCompositionType.THRESHOLD) &&
            !WindowCalculator.isSubWindowFixed(subwindow);
        let bottom = {
            area: ((subwindowBox.maxX - subwindowBox.minX) / 1000) * ((subwindowBox.maxY - beadBox.maxY) / 1000),
            elementType: getUfElementType(profiles.bottom, subwindowBox.maxY === totalBoundingBox.maxY, useDoorstep)
        };

        // TODO: ceownik?

        let results = [left, right, top, bottom];

        // group areas results by UfElementType
        let groupedData: { [type in UfElementType]?: { area: number, length: number } } = {};
        Object.keys(UfElementType).forEach((type: keyof typeof UfElementType) => {
            groupedData[type] = {
                area: results.filter(item => item.elementType.toString() === type)
                    .map(result => result.area)
                    .reduce((total, area) => total + area, 0),
                length: 0
            };
        });
        return {left, right, top, bottom, groupedData};
    }

    private static getUfElementType(isWinged: boolean) {
        return (compType: CompositionType, isOutside: boolean, useDoorstep = false): UfElementType => {
            if (useDoorstep) {
                return UfElementType.DOORSTEP;
            }
            if (compType === CompositionType.MOVABLE_POST) {
                return UfElementType.WING_MOVABLE_POST;
            }
            if (compType === CompositionType.SLIDING_CONTACT || compType === CompositionType.SLIDING_OVERLAP) {
                return UfElementType.FRAME_WING;
            }
            if (isWinged && isOutside) {
                return UfElementType.FRAME_WING;
            }
            if (isWinged && !isOutside) {
                return UfElementType.WING_MULLION;
            }
            if (!isWinged && isOutside) {
                return UfElementType.FRAME;
            }
            if (!isWinged && !isOutside) {
                return UfElementType.CONSTR_MULLION;
            }
        };
    }

    private static findMullion(side: Side, area: AreaSpecification, areaBox: MinMaxXY, subwindow: SubWindowData) {
        return area.definingMullions.map(defMullion => MullionHelper.findMullionById(defMullion.id, subwindow)).find(mullion => {
                let segment = GrillHelper.getExpectedLineSegment(mullion);
                let segmentBox = DrawingUtil.calculateMinMaxFromPolygon(segment.points, false);
                switch (side) {
                    case Side.LEFT:
                        return segmentBox.maxX < areaBox.minX;
                    case Side.RIGHT:
                        return segmentBox.minX > areaBox.maxX;
                    case Side.TOP:
                        return segmentBox.maxY < areaBox.minY;
                    case Side.BOTTOM:
                        return segmentBox.minY > areaBox.maxY;
                }
            }
        );
    }
}

enum Side {
    TOP = "TOP",
    BOTTOM = "BOTTOM",
    LEFT = "LEFT",
    RIGHT = "RIGHT"
}

export class UfDataElement {
    id: number;
    elementType: UfElementType;
    length: number;
    area: number;

    constructor(id: number, elementType: UfElementType, length: number, area: number) {
        this.id = id;
        this.elementType = elementType;
        this.length = length;
        this.area = area;
    }
}
