import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Palette} from "../../palette";
import {ValidationErrors} from "../../../../../common/validation-errors";

@Component({
    selector: 'app-palette-form',
    templateUrl: './palette-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaletteFormComponent {

    @Input()
    palette: Palette;

    @Input()
    validationErrors: ValidationErrors = {};

    constructor() {
    }
}
