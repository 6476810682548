<div class="new-form-600">
    <ng-container *ngIf="glazings != undefined">
        <div class="new-form-row">
            <app-radio-button inputId="glazingGlassQuantity"
                              label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.GLAZING_GLASS_QUANTITY' | translate }}"
                              [(validationMessageKey)]="validationErrors['productConfiguration.glazingGlassQuantity']"
                              [options]="glazingGlassQuantityOptions" [required]="true"
                              [ngModel]="item.productConfiguration.glazingGlassQuantity"
                              (ngModelChange)="handleSelectedGlazingGlassQuantityChange($event)"></app-radio-button>
        </div>
        <div class="new-form-row">
            <app-checkbox inputId="useWarmDistanceFrames"
                          label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.USE_WARM_DISTANCE_FRAMES' | translate }}"
                          [(validationMessageKey)]="validationErrors['productConfiguration.useWarmDistanceFrames']"
                          [ngModel]="item.productConfiguration.useWarmDistanceFrames"
                          (ngModelChange)="handleUseWarmDistanceFramesChange($event)"></app-checkbox>
        </div>
        <div class="new-form-row">
            <app-select inputId="glazingPackageId"
                        label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.GLAZING_PACKAGE' | translate }}"
                        [(validationMessageKey)]="validationErrors['productConfiguration.glazingPackageId']"
                        [options]="glazingPackageOptions" [required]="true"
                        [(ngModel)]="item.productConfiguration.glazingPackageId"></app-select>
        </div>
    </ng-container>
</div>
