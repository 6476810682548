<p-dialog #dialog *ngIf="loaded" [(visible)]="visible" [modal]="true" (onHide)="closeDialog.emit()" class="detailed-pricing-dialog">
    <p-header>
        <div class="p-dialog-title">
            {{ ('OFFER.DETAILED_PRICING.HEADER' | translate:{offerNumber: offer.offerNumber}) }}
            <div class="info-button-container"><span (click)="helpOpened = true" class="tooltip-info-icon"></span></div>
            <app-simple-button class="expand-button" label="{{ (visibleAll ? 'OFFER.DETAILED_PRICING.CLOSE_ALL' :
                                   'OFFER.DETAILED_PRICING.EXPAND_ALL') | translate }}" type="main-action" [size]="32"
                               (onClick)="toggleAll()"></app-simple-button>
            <p-multiSelect class="expand-button" defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}"
                           id="detailedPricingShownColumns" [displaySelectedLabel]="false"
                           [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                           (ngModelChange)="showColumns($event)"></p-multiSelect>
            <app-simple-button class="expand-button" label="{{ 'OFFER.DETAILED_PRICING.SIMULATION_PARAMETERS' | translate }}"
                               type="secondary-action" [size]="32"
                               (onClick)="openSimulationParametersDialog()"></app-simple-button>
            <app-simple-button class="expand-button" label="{{ 'OFFER.DETAILED_PRICING.SIMULATE' | translate }}"
                               type="secondary-action" [size]="32" [disabled]="pricingComponentsOverride == undefined"
                               (onClick)="simulateWithDifferentDiscountsAndMargins()"></app-simple-button>
        </div>
    </p-header>
    <div class="full-view-dialog">
        <div class="list-content detailed-pricing-table">
            <div class="buttons-section">
                <div class="tax-values-switch detailed-pricing-button">
                    <span>{{'OFFER.DETAILED_PRICING.NETTO' | translate}}</span>
                    <!-- disabled, bo kwota brutto wyściowa z Venski nie pokrywa się z kwotą brutto wejściową podsystemu (bo Venska ma vat sprzedazy 0% a podsystem ma vat 25%) -->
                    <p-inputSwitch [ngModel]="grossValues" (ngModelChange)="showGrossPrices($event)" [disabled]="true"></p-inputSwitch>
                    <span>{{'OFFER.DETAILED_PRICING.BRUTTO' | translate}}</span>
                </div>
                <div class="switch-buttons-separator"></div>
                <div class="tax-values-switch detailed-pricing-button">
                    <span>{{ 'OFFER.DETAILED_PRICING.0_PRICES_LABEL' | translate }}</span>
                    <span>{{ 'OFFER.DETAILED_PRICING.0_PRICES_HIDE' | translate }}</span>
                    <p-inputSwitch [ngModel]="zeroPricesVisible" (ngModelChange)="show0Prices($event)"></p-inputSwitch>
                    <span>{{ 'OFFER.DETAILED_PRICING.0_PRICES_SHOW' | translate }}</span>
                </div>
            </div>

            <p-treeTable selectionMode="single" [value]="detailedPricingTreeData" [(selection)]="selected"
                         (onNodeExpand)="toggleNode($event.node)" (onNodeCollapse)="toggleNode($event.node)"
                         [scrollable]="true" [scrollHeight]="'calc(100vh - 430px)'">
                <ng-template pTemplate="header">
                    <tr>
                        <th *ngIf="columnVisible('PRINT_ORDER')" class="header-cell header-cell-top narrow-column stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'PRINT_ORDER'"></th>
                        <th *ngIf="columnVisible('SYSTEM')" class="header-cell header-cell-top stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'SYSTEM'"></th>
                        <th class="header-cell header-cell-top name-column stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'POSITION_NAME'"></th>

                        <th *ngIf="columnVisible('VENSKA')" colspan="4" class="additional-header cell-separator">{{ 'OFFER.DETAILED_PRICING.VENSKA' | translate }}</th>
                        <th *ngIf="columnVisible('SUBSYSTEM')" colspan="4" class="additional-header cell-separator">{{ 'OFFER.DETAILED_PRICING.SUBSYSTEM' | translate }}</th>
                        <th *ngIf="columnVisible('CLIENT')" colspan="4" class="additional-header cell-separator">{{ 'OFFER.DETAILED_PRICING.CLIENT' | translate }}</th>
                        <th *ngIf="columnVisible('SELLER')" colspan="4" class="additional-header cell-separator">{{ 'OFFER.DETAILED_PRICING.SELLER' | translate }}</th>
                        <th *ngIf="columnVisible('SUMMARY')" colspan="4" class="additional-header cell-separator">{{ 'OFFER.DETAILED_PRICING.SUMMARY' | translate }}</th>
                    </tr>
                    <tr>
                        <th *ngIf="columnVisible('PRINT_ORDER')" class="header-cell narrow-column stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'PRINT_ORDER'">{{ 'OFFER.DETAILED_PRICING.PRINT_ORDER' | translate }}</th>
                        <th *ngIf="columnVisible('SYSTEM')" class="header-cell stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'SYSTEM'">
                                {{ 'OFFER.DETAILED_PRICING.SYSTEM' | translate }}
                        </th>
                        <th class="header-cell name-column stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'POSITION_NAME'">{{ 'OFFER.DETAILED_PRICING.POSITION_NAME' | translate }}</th>
                        <ng-container *ngFor="let type of costTypes">
                            <ng-container *ngIf="columnVisible(type)">
                                <th class="header-cell cell-separator">{{ 'OFFER.DETAILED_PRICING.PRICE_BEFORE' | translate }}</th>
                                <th class="header-cell">{{ 'OFFER.DETAILED_PRICING.MODIFICATION_VALUE' | translate }}</th>
                                <th class="header-cell">{{ 'OFFER.DETAILED_PRICING.MODIFICATION_SOURCE' | translate }}</th>
                                <th class="header-cell">
                                    <div>{{ getPriceLabel(type) | translate }}</div>
                                    <div>{{ 'OFFER.DETAILED_PRICING.PRICE_AFTER' | translate }}</div>
                                </th>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="columnVisible('SUMMARY')">
                            <th class="header-cell cell-separator">{{ 'OFFER.DETAILED_PRICING.QUANTITY' | translate }}</th>
                            <th *ngIf="canViewVenskaPrices" class="header-cell">{{ 'OFFER.DETAILED_PRICING.VENSKA_BUY_VALUE' | translate }}</th>
                            <th *ngIf="canViewSubsystemPrices" class="header-cell">{{ 'OFFER.DETAILED_PRICING.BUY_VALUE' | translate }}</th>
                            <th *ngIf="canViewClientPrices" class="header-cell">{{ 'OFFER.DETAILED_PRICING.SELL_VALUE' | translate }}</th>
                            <th class="header-cell">{{ 'OFFER.DETAILED_PRICING.RETAIL_SELL_VALUE' | translate }}</th>
                        </ng-container>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
                    <tr *ngIf="zeroPricesVisible | showZeroPrices:rowData" class="p-treetable-row" [class]="rowData.rowLevel">
                        <td *ngIf="columnVisible('PRINT_ORDER')" class="narrow-column centered-column stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'PRINT_ORDER'">{{ rowData.printOrder }}</td>
                        <td *ngIf="columnVisible('SYSTEM')" class="centered-column stick-it"
                            [ngClass]="selectedColumns | stickyColumnClass:'SYSTEM'">
                            {{ rowData.system ? (rowData.system | multilanguageTranslate) : '' }}
                        </td>
                        <td class="name-column stick-it" [ngClass]="selectedColumns | stickyColumnClass:'POSITION_NAME'">
                            <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                            {{ rowData.name | detailedPricingPositionName:rowData.hasKeyLabel | async }}
                        </td>
                        <ng-container *ngFor="let modification of rowData.modifications; let i = index">
                            <ng-container *ngIf="columnVisible(modification.costType)">
                                <td class="cell-separator">
                                    <div *ngIf="modification.priceBefore" class="cost-color {{ modification.costType }}">
                                        {{ (grossValues ? modification.priceBefore?.grossValue : modification.priceBefore?.netValue) | price:currency }}
                                    </div>
                                </td>
                                <td class="centered-column">{{ modification | modificationValue }}</td>
                                <td>{{ modification.source != null ? (('OFFER.DETAILED_PRICING.SOURCE.' + modification.source) | translate) : '' }}</td>
                                <td>
                                    <div *ngIf="modification.priceAfter" class="cost-color {{ modification.costType }}" [class.bold]="modification.bolded">
                                        {{ (grossValues ? modification.priceAfter?.grossValue : modification.priceAfter?.netValue) | price:currency }}
                                    </div>
                                </td>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="columnVisible('SUMMARY')">
                            <td class="centered-column cell-separator">{{ rowData.quantity }}</td>
                            <ng-container *ngFor="let value of rowData.summaryValues">
                                <td>
                                    <div *ngIf="value.value && value.value.netValue != undefined" class="cost-color bold {{ value.costType }}">
                                        {{ (grossValues ? value.value.grossValue : value.value.netValue) | price:currency }}
                                    </div>
                                </td>
                            </ng-container>
                        </ng-container>
                    </tr>
                </ng-template>
            </p-treeTable>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="dialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<app-help-dialog *ngIf="helpOpened" (onHide)="helpOpened = false"></app-help-dialog>

<app-detailed-pricing-simulation-parameters-dialog *ngIf="pricingComponentsOverride != undefined && pricingComponentsOverride.dialogVisible"
                                                   [supplierNames]="pricingComponentsOverride.suppliers"
                                                   [windowSystems]="pricingComponentsOverride.windowSystems"
                                                   [gateSystems]="pricingComponentsOverride.gateSystems"
                                                   [configSystems]="pricingComponentsOverride.configSystems"
                                                   [hasBulkAddons]="pricingComponentsOverride.hasBulkAddons"
                                                   [overrides]="pricingComponentsOverride.overrides"
                                                   (onHide)="pricingComponentsOverride.dialogVisible = false"
></app-detailed-pricing-simulation-parameters-dialog>
