<div class="table-header">
    <div class="title">
        {{ 'TRANSLATIONS.UI_TRANSLATIONS.UI_TRANSLATIONS' | translate }}
    </div>

    <div class="table-header-buttons">
        <div class="table-header-buttons-container">
            <!-- SHOW / HIDE FILTERS -->
            <app-simple-button buttonId="toggle-filter-button"
                               label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                               icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
            <app-simple-button buttonId="add" label="{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.ADD_TITLE' | translate }}"
                               icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                               (onClick)="doShowDialogToAdd()"></app-simple-button>
        </div>
    </div>
</div>
<div class="datatable-container">
    <p-table #table [value]="translations" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
             [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
             (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400" [totalRecords]="totalRecords"
             selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" [rowTrackBy]="rowTrackById" dataKey="key"
             [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
             [(selection)]="selectedItem">
        <ng-template pTemplate="header">
            <tr>
                <th [ngClass]="columnHeaderClasses('key')">
                    <span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.KEY' | translate }}</span>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="key"
                           (input)="table.filter(getInputEventValue($event), 'key', 'contains')">
                </th>
                <th [ngClass]="columnHeaderClasses('text.' + webshopLanguage)">
                    <span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.TEXT' | translate }}</span>
                    <input class="p-column-filter p-inputtext p-component"
                           [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                           (click)="handleInputFilterClick($event)" name="{{ 'text.' + webshopLanguage }}"
                           (input)="handleTextFilterChange(getInputEventValue($event), webshopLanguage)">
                </th>
                <th pSortableColumn="lastModified" [ngClass]="columnHeaderClasses('lastModified')">
                    <span>{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.LAST_MODIFIED' | translate }}</span>
                    <p-sortIcon field="lastModified"></p-sortIcon>
                    <div app-table-date-range-filter="lastModified"
                         [ngClass]="{ 'p-column-filter-invisible': !showFilters }"></div>
                </th>
                <th></th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-translation let-rowIndex="rowIndex">
            <tr [attr.tabindex]="rowIndex"
                (dblclick)="doOnRowSelect({data: translation, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                [pSelectableRow]="translation" [pSelectableRowIndex]="rowIndex"
                [app-table-keyboard-navigation]="translation"
                (app-table-keyboard-navigation-enter)="doOnRowSelect({data: translation, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                class="datatable-data">
                <td>{{ translation.key }}</td>
                <td class="ui-translation-value-column">{{ translation.text[webshopLanguage] }}</td>
                <td>{{ translation.lastModified | momentCurrentLang | amDateFormat: 'L LT' }}</td>
                <td>
                    <app-simple-button icon="cancel" type="delete" [size]="36" [inlineLabel]="false"
                                       label="{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.DELETE' | translate }}"
                                       (onClick)="deleteTranslation(translation)"></app-simple-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<ng-template #paginatorPageInfoTemplate>
    <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
</ng-template>
<p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
             styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
             [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
             [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>

<div class="dialog-window" *ngIf="editedItem != undefined">
    <p-dialog #dialog [visible]="editedItem != undefined" (visibleChange)="cancel()" [resizable]="false" [modal]="true"
              [focusOnShow]="false" [closable]="true" [closeOnEscape]="true">
        <p-header>
            <div *ngIf="newItem" class="p-dialog-title">
                {{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.ADD_TITLE' | translate }}
            </div>
            <div *ngIf="!newItem" class="p-dialog-title">
                {{ editedItem.key }} - {{ ('TRANSLATION_TARGET.' + editedItem.target) | translate }}
            </div>
        </p-header>
        <div class="new-form-600">
            <div class="new-form-row">
                <app-select *ngIf="newItem" inputId="key"
                            label="{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.KEY' | translate }}" [options]="availableKeys"
                            [(ngModel)]="editedItem.key" [(validationMessageKey)]="validationErrors['key']"
                            [required]="true"></app-select>
                <app-input-text *ngIf="!newItem" inputId="key"
                                label="{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.KEY' | translate }}" [disabled]="true"
                                [ngModel]="editedItem.key"></app-input-text>
            </div>
            <div class="new-form-row">
                <app-text-area [inputId]="'text_' + webshopLanguage"
                               label="{{ 'TRANSLATIONS.UI_TRANSLATIONS.FORM.TEXT' | translate }}"
                               [(ngModel)]="editedItem.text[webshopLanguage]"></app-text-area>
            </div>
        </div>
        <p-footer>
            <div class="p-dialog-buttonpane">
                <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                                   (onClick)="submit()"></app-simple-button>
                <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                   (onClick)="dialog.close($event)"></app-simple-button>
            </div>
        </p-footer>
    </p-dialog>
</div>
