<div class="new-form-60vw">
    <div class="new-form-row" *ngFor="let property of properties">
        <app-select [inputId]="'input_' + property.id"
                    [disabled]="disabled"
                    [label]="property.name[translate.currentLang]"
                    [(ngModel)]="value[property.id]"
                    [allowSelectingNone]="true"
                    [options]="optionsPerProperty[property.id]"
                    [optionFormatter]="selectItemFormatters.catalogOptionFormatter">
        </app-select>
    </div>
</div>
