import {Injectable, OnDestroy} from '@angular/core';
import {OperatorFunction, Subject} from 'rxjs';
import {mergeMap, take} from 'rxjs/operators';
import {ConfigurableAddonType} from '../../features/offer/offers/position/position-list/ConfigurableAddonModel/ConfigurableAddonType';
import {ProfitMarginExistance} from '../../features/offer/window-editor/drawing-tool/ProfitMarginExistance';

export interface MissingWindowSystemProfitMargin {
    target: 'WINDOW_SYSTEM';
    windowSystemId: number;
}

export interface MissingConfigurableAddonProfitMargin {
    target: 'CONFIGURABLE_ADDON';
    category: ConfigurableAddonType;
}

export interface MissingAddonProfitMargin {
    target: 'ADDON';
    category: 'ADDON';
}

export interface MissingGateProfitMargin {
    target: 'GATE_SYSTEM';
    gateSystemId: number;
}

export interface MissingConfigProfitMargin {
    target: 'CONFIG_SYSTEM';
    configSystemId: number;
}

export type MissingProfitMarginIdentifier =
    MissingWindowSystemProfitMargin
    | MissingConfigurableAddonProfitMargin
    | MissingAddonProfitMargin
    | MissingGateProfitMargin
    | MissingConfigProfitMargin;

export interface MissingProfitMarginParams {
    subsystemId: number;
    clientId: number;
    sellerClientId: number;
    identifier: MissingProfitMarginIdentifier;
}

@Injectable()
export class MissingProfitMarginHandlerService implements OnDestroy {

    private readonly requests = new Subject<{ status: ProfitMarginExistance, params: MissingProfitMarginParams }>();
    private readonly results = new Subject<boolean>();

    public readonly missingProfitMarginEvents = this.requests.asObservable();

    ngOnDestroy(): void {
        this.requests.complete();
        this.results.complete();
    }

    public handleProfitMarginExistenceResult(params: MissingProfitMarginParams): OperatorFunction<ProfitMarginExistance, boolean> {
        return mergeMap((profitMarginExistence: ProfitMarginExistance) => {
            setTimeout(() => this.requests.next({status: profitMarginExistence, params: params}));
            // return observable that waits for missingProfitMarginHandled() to be called
            return this.results.asObservable().pipe(take(1));
        });
    }

    public missingProfitMarginHandled(result: boolean): void {
        this.results.next(result);
    }
}
