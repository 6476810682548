import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {forkJoin, Observable, of} from "rxjs";
import {finalize, map, tap} from "rxjs/operators";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {MultilanguageField} from "../../../supportedLanguages";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, GateSystemField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {CatalogConfiguration} from "../../catalog-creator/catalog-configuration";
import {CatalogConfigurationService} from "../../catalog-creator/catalog-configuration.service";
import {CatalogPropertyTarget} from "../../catalog-creator/CatalogPropertyTarget";
import {SupplierService} from "../../supplier/supplier.service";
import {GateSystemFieldUsage} from "../catalog-field-usage";
import {GateSystem} from "./gate-system";
import {GateSystemService} from "./gate-system.service";

@Component({
    selector: 'app-gate-system',
    templateUrl: './gate-system.component.html',
    providers: [GateSystemService, TranslatedSelectItemService, SupplierService, CatalogConfigurationService]
})
export class GateSystemComponent extends CrudCommonComponent<GateSystem, GateSystemService> implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
        DESCRIPTION: 'DESCRIPTION'
    };

    validateDataStep: WizardStepValidator;
    existingSuppliers: SelectItem[] = [];
    catalogConfigurations: CatalogConfiguration;
    catalogConfigurationService: CatalogConfigurationService;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    editPermits: FieldLimitation[] = [];
    fieldUsage: GateSystemFieldUsage;
    GateSystemField = GateSystemField;
    CatalogTab = CatalogTab;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private gateSystemService: GateSystemService,
        private supplierService: SupplierService,
        private editCatalogPermitsService: EditCatalogPermitsService
    ) {
        super(injector, changeDetector, true, GateSystemService, 'GATE_SYSTEM', 'GateSystem');
        this.validateDataStep = () => this.validateForm();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.catalogConfigurationService = injector.get(CatalogConfigurationService);
        this.fieldUsage = new GateSystemFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        forkJoin({
            suppliers: this.supplierService.getSupplierNames(),
            catalogConfiguration: this.getCatalogConfiguration(CatalogPropertyTarget.GATES)
        }).subscribe({
            next: data => {
                this.existingSuppliers = data.suppliers.map(supplier => new SelectItemImpl(supplier.companyName, supplier));
                this.catalogConfigurations = data.catalogConfiguration;
            },
            error: error => this.errors.handle(error)
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GATE_SYSTEMS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): GateSystem {
        return new GateSystem();
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.resetFile();
        this.prepareDataForModel(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');

        if (this.item.pcn === "") {
            this.item.pcn = null;
        }
        this.validationErrors[`name[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.gateSystemDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]);

        this.validationErrors[`shortcut[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.gateSystemDto.shortcut[${this.translate.currentLang}]`)
                .withSizeValidator(0, 100)
                .validate(this.item.shortcut[this.translate.currentLang]);

        this.validationErrors['symbol'] =
            MultiValidator.of('error.gateSystemDto.symbol')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 50)
                .validate(this.item.symbol);

        this.validationErrors['unitWeight'] = MultiValidator.of('error.gateSystemDto.unitWeight')
            .withNotNullValidator()
            .withDecimalValidator()
            .withRangeValidator(0, 9999)
            .validate(this.item.unitWeight);

        this.validationErrors['sortIndex'] = MultiValidator.of('error.gateSystemDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validateForm().subscribe(validateOk => {
            if (validateOk) {
                if (this.isSaveInProgress()) {
                    return;
                }
                this.setSaveInProgress(true);
                let observable: Observable<number>;
                if (this.copyMode) {
                    observable = this.itemService.copy(this.selectedItem.id, this.item, this.file);
                } else {
                    observable = this.itemService.saveGateSystem(this.item, this.file);
                }

                observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
                    complete: () => {
                        this.showSuccessMessage();
                        this.hideDetails();
                    },
                    error: err => {
                        this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                        this.changeDetector.markForCheck();
                    }
                });
            }
        });
    }

    prepareDataForModel(gateId: number) {
        forkJoin({
            gate: (gateId != undefined ? this.gateSystemService.getItem(gateId) : of(new GateSystem())),
            image: (gateId != undefined ? this.gateSystemService.getImageAsFile(gateId) : of<File>(undefined)),
        }).subscribe({
            next: data => {
                this.newItem = gateId == undefined;
                this.item = data.gate;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    this.item.shortcut = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.file = data.image;
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    showDialogToAdd() {
        this.resetFile();
        this.validationErrors = {};
        this.prepareDataForModel(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.resetFile();
            this.prepareDataForModel(this.selectedItem.id);
        }
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newItem = false;
        this.resetFile();
        this.reloadDatatable();
    }

    getCatalogConfiguration(target: CatalogPropertyTarget): Observable<CatalogConfiguration> {
        return this.catalogConfigurations == undefined ?
            this.catalogConfigurationService.getForTarget(target, true) : of(this.catalogConfigurations);
    }
}
