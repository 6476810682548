import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, OnInit, Output, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable, of} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {StorageService} from '../../../auth/storage.service';
import {ButtonWithMenuElementSelectedEvent} from '../../../common/button-with-menu/button-with-menu-event';
import {MenuElement, MenuElementBuilder} from '../../../common/button-with-menu/MenuElement';
import {MenuType} from '../../../common/button-with-menu/MenuType';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ExchangeService} from "../../../common/exchange.service";
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {SavedFilterService} from '../../../common/saved-filter/saved-filter.service';
import {CrudComponent} from '../../../common/service/crud.component';
import {DataTableColumnBuilder, SavedShownColumns} from '../../../common/service/data.table.column.builder';
import {ExportComponent} from '../../../common/service/export.component';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {TranslatedSelectItemBuilder} from '../../../common/service/translated.select.item.builder';
import {AccessData} from '../../AccessData';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {HasSavedFilter} from '../has-saved-filter';
import {PrintableItem} from '../print-dialog/print-dialog.component';
import {PrintableSection} from '../print-dialog/printable-section.enum';
import {StatusTransition} from '../status-transition-dialog/StatusTransition';
import {StatusTransitionHelper} from '../status-transition-dialog/StatusTransitionHelper';
import {DeliveryList} from './delivery-list';
import {DeliveryListStatusTransitionService} from './delivery-list-status-transition.service';
import {DeliveryListService} from './delivery-list.service';
import {DeliveryListStatus} from './DeliveryListStatus';

@Component({
    selector: 'app-delivery-lists',
    templateUrl: './delivery-lists.component.html',
    styleUrls: ['../../shared-styles.css', './delivery-list-status-colors.component.css'],
    providers: [DeliveryListService, SubsystemService, ExportComponent, DeliveryListStatusTransitionService,
        ExchangeService /*for print dialog*/],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryListsComponent extends CrudComponent implements OnInit, HasSavedFilter {

    readonly TABLE_ID = 'deliveryListTable';

    private ignoreStoredFilter: boolean;

    menuType = MenuType;

    @ViewChild(DataTable, {static: true})
    deliveryListTable: DataTable;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();

    deliveryLists: DeliveryList[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    selectedDeliveryList: DeliveryList;

    subsystems: SelectItem[];
    selectedSubsystems: string[] = [];
    availableStatuses: TranslatedSelectItem[] = [];
    selectedStatuses: DeliveryListStatus[] = [];
    private currentActionDeliveryList: DeliveryList;

    private deliveryListService: DeliveryListService;
    private subsystemService: SubsystemService;
    private permissions: Permissions;

    private lastLoadEvent: LazyLoadEvent;
    canEdit = false;

    possibleTransitions: StatusTransition[] = [];

    printDialogVisible = false;
    PrintableSection = PrintableSection;
    printableItems: PrintableItem[] = [];

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private router: Router,
                private route: ActivatedRoute,
                private errors: CommonErrorHandler,
                private growls: GrowlMessageController,
                private storage: StorageService,
                private deliveryListStatusTransitionService: DeliveryListStatusTransitionService) {
        super(injector, changeDetector, 'DeliveryList', false);

        this.deliveryListService = injector.get(DeliveryListService);
        this.subsystemService = injector.get(SubsystemService);
        this.permissions = injector.get(Permissions);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.canEdit = this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
        this.initAvailableStatutes();
        this.createTable();
        this.resetDefaultFilterSelection();
        this.subsystemService.getSelectionItems().subscribe({
            next: data => {
                this.subsystems = data;
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    private initAvailableStatutes(): void {
        if (this.permissions.isPermitted({roles: ['ROLE_OPERATOR']})) {
            this.availableStatuses = TranslatedSelectItemBuilder.create()
                .add('DELIVERY_LIST.STATUS.APPROVED', DeliveryListStatus.APPROVED)
                .build();
        }
        if (this.permissions.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']})) {
            this.availableStatuses = TranslatedSelectItemBuilder.create()
                .add('DELIVERY_LIST.STATUS.DRAFT', DeliveryListStatus.DRAFT)
                .add('DELIVERY_LIST.STATUS.APPROVED', DeliveryListStatus.APPROVED)
                .build();
        }
    }

    submit(): void {
    }

    getExportData(): Observable<object[]> {
        return of([]);
    }

    showDialogToAdd(): void {
        if (!this.canEdit) {
            return;
        }
        this.router.navigate([AccessData.path.deliveryListWizard()]);
    }

    onRowSelect(event: { originalEvent: any, data: DeliveryList }): void {
        this.goToDeliveryList(event.data.id);
    }

    goToDeliveryList(id: number): void {
        this.router.navigate([AccessData.path.deliveryList(id.toString())]);
    }

    getDatatable(): DataTable {
        return this.deliveryListTable;
    }

    isShowingOnlyOwn(): boolean {
        const filter = this.deliveryListTable.filters['onlyOwn'];
        return filter != undefined && ('' + filter.value) === 'true';
    }

    toggleShowOnlyOwn(): void {
        const currentOnlyOwn = this.isShowingOnlyOwn();
        this.deliveryListTable.filter(!currentOnlyOwn, 'onlyOwn', 'equals');
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalFilters = true): void {
        super.loadItemsLazy(event);
        this.storeLazyLoadEvent(event, this.storage);
        this.onDataLazyLoad.emit(event);
        if (updateOriginalFilters) {
            this.onUpdateOriginalFilters.emit(event);
        }
        this.deliveryListService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.deliveryLists = data.data;
                    this.totalRecords = data.totalRecords;
                    this.totalRecordsChange.emit(this.totalRecords);
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedDeliveryList = this.restoreSelectionAfterLoad(this.selectedDeliveryList, this.deliveryLists, event);
                },
                error: error => {
                    this.errors.handle(error);
                },
                complete: () => {
                    this.lastLoadEvent = event;
                    this.changeDetector.markForCheck();
                }
            });
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.getDatatable().filters = {};
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        this.lastLoadEvent.filters = filter.filters;
        this.lastLoadEvent.sortOrder = filter.sortOrder;
        this.lastLoadEvent.sortField = filter.sortField;
        this.applyMultiSelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedStatuses, filter.filters['status']);
        this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        SavedFilterService.applyMultiSelectFilters(this.selectedSubsystems, filter.filters['subsystemIds']);
        this.getDatatable().setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
    }

    private resetDefaultFilterSelection() {
        if (!this.ignoreStoredFilter) {
            this.lastLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
            if (this.lastLoadEvent != undefined) {
                this.applySavedFilter(this.lastLoadEvent);
            }
        }
        this.restoreStatusesSelection();
    }

    private restoreStatusesSelection(): void {
        if (this.selectedStatuses.length > 0) {
            const statusColumn = this.getColumns().find(column => column.field === 'status');
            if (statusColumn != undefined) {
                statusColumn.defaultFilterValue = {
                    label: '',
                    value: this.selectedStatuses.join(';')
                };
            }
            this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        }
    }

    handleSubsystemFilterChange(subsystems: string[]): void {
        this.selectedSubsystems = subsystems;
        this.getDatatable().filter(subsystems.join(';'), 'subsystemIds', 'in');
    }

    handleStatusFilterChange(statuses: DeliveryListStatus[]): void {
        this.selectedStatuses = statuses;
        this.getDatatable().filter(statuses.join(';'), 'status', 'in');
    }

    private createTable(): void {
        let builder = DataTableColumnBuilder.create()
            .add('name', 'DELIVERY_LIST.LIST.NAME', true)
            .add('creationDate', 'DELIVERY_LIST.LIST.CREATION_DATE', true).setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING)
            .add('dispatchDate', 'DELIVERY_LIST.LIST.DISPATCH_DATE', true)
            .add('subsystemName', 'DELIVERY_LIST.LIST.SUBSYSTEM', true)
            .add('subsystemClientManager', 'DELIVERY_LIST.LIST.SUBSYSTEM_CLIENT_MANAGER', true)
            .add('subsystemSubClientManager', 'DELIVERY_LIST.LIST.SUBSYSTEM_SUB_CLIENT_MANAGER', true)
            .add('ownerName', 'DELIVERY_LIST.LIST.OWNER', true)
            .add('status', 'DELIVERY_LIST.LIST.STATUS', true).setFilterValues(this.availableStatuses);
        this.init(builder.build());
    }

    getRowActionsBuilder(deliveryList: DeliveryList): () => MenuElement[] {
        return () => {
            return this.buildRowActions(deliveryList);
        };
    }

    private buildRowActions(deliveryList: DeliveryList): MenuElement[] {
        let rowActions: MenuElement[] = [];
        let deliveryListSuffix: string = this.getDeliveryListSuffix(deliveryList);

        this.possibleTransitions = this.deliveryListStatusTransitionService.createPossibleTransitions(deliveryList,
            (actionName): void => {
                this.onChangeStatus(deliveryList, actionName);
            },
            (actionName, error): void => {
                this.onChangeStatus(deliveryList, actionName, error);
            });

        this.addActionButton('SHOW_ORDERS', rowActions, deliveryListSuffix);
        this.addActionButton('PRINT', rowActions, deliveryListSuffix);
        if (this.possibleTransitions.length > 0) {
            this.addActionButton('CHANGE_STATUS', rowActions, deliveryListSuffix, this.possibleTransitions[0].item.label);
        }
        this.addActionButton('DELETE', rowActions, deliveryListSuffix);
        return rowActions;
    }

    private getDeliveryListSuffix(deliveryList: DeliveryList) {
        return '___' + deliveryList.id;
    }

    private addActionButton(action: string, rowActions: MenuElement[], id: string, translation?: string): void {
        if (this.showAction(action)) {
            let translationKey = translation || 'DELIVERY_LIST.ACTIONS.TOOLTIPS.' + action;
            let builder = new MenuElementBuilder().setTranslationKey(translationKey).setIdentifier(action + id);
            rowActions.push(builder.build());
        }
    }

    public showAction(action: string): boolean {
        switch (action) {
            case 'SHOW_ORDERS':
            case 'PRINT':
            case 'CHANGE_STATUS':
                return true;
            case 'DELETE':
                return this.canEdit;
            default:
                return false;
        }
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent) {
        let data = event.identifier.split('___');
        let action = data[0];

        let deliveryListId = +data[1];
        let found = this.deliveryLists.find(deliveryList => deliveryList.id === deliveryListId);

        if (found) {
            this.actionOnClick(action, found);
        } else {
            console.error("Action called for non existing positionId " + deliveryListId);
        }
    }

    protected actionOnClick(action: string, deliveryList: DeliveryList): void {
        console.info("DeliveryListsComponent: clicked action '" + action + "' for delivery list: " + deliveryList.id);
        this.currentActionDeliveryList = deliveryList;
        switch (action) {
            case 'SHOW_ORDERS':
                this.router.navigate(['/features/offer', {component: 'order', ignoreStoredFilter: true}],
                    {queryParams: {deliveryListName: "\"" + this.currentActionDeliveryList.name + "\""}});
                break;
            case 'PRINT':
                this.openPrintDialog();
                break;
            case 'CHANGE_STATUS':
                this.changeStatus();
                break;
            case 'DELETE':
                this.deliveryListService.delete(deliveryList.id).subscribe({
                    next: () => {
                        this.loadItemsLazy(this.lastLoadEvent, false);
                        this.growls.info('DELIVERY_LIST.DELETE_SUCCESS');
                    },
                    error: error => {
                        this.errors.handle(error);
                    }
                });
                break;
            default:
                console.error("DeliveryListsComponent: action '" + action + "' is unknown.");
                break;
        }
    }

    changeStatus(): void {
        if (this.possibleTransitions.length === 1) {
            this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
            this.possibleTransitions[0].item.command();
        }
    }

    private onChangeStatus(deliveryList: DeliveryList, actionName: string, error?: HttpErrorResponse): void {
        this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
        if (error != undefined) {
            this.errors.handle(error);
        } else {
            this.growls.info('DELIVERY_LIST.CHANGE_STATUS_SUCCESS');
            deliveryList.status = DeliveryListStatus[actionName];
        }
        this.changeDetector.markForCheck();
    }

    public openPrintDialog() {
        this.printableItems = [new PrintableItem(this.currentActionDeliveryList.id, this.currentActionDeliveryList.name)];
        this.printDialogVisible = true;
        this.changeDetector.markForCheck();
    }
}
