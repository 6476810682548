import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Inject,
    Input,
    OnChanges,
    Optional,
    Output,
    Renderer2,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectItem} from 'primeng/api/selectitem';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';

export const SELECT_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectComponent),
    multi: true
};

class SelectOption {
    constructor(public label: string,
                public value: any,
                public nativeValue: string,
                public disabled: boolean,
                public available: boolean,
                public bold: boolean,
                public center: boolean) {
    }
}

export interface SelectItemExtended extends SelectItem {
    label: string;
    bold?: boolean;
    center?: boolean;
    singlePositionItem?: boolean;
}

export interface SelectInvalidValueCorrectedEvent {
    reason: SelectInvalidValueCorrectionReason;
    invalidValue: any;
}

export enum SelectInvalidValueCorrectionReason {
    EXTERNAL_WRITE_INVALID,
    OPTIONS_CHANGED
}

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    providers: [SELECT_VALUE_ACCESSOR]
})
export class SelectComponent extends AbstractInputComponent implements OnChanges {

    readonly NO_SELECTION_MARKER = "__clear_value_when_selected__";

    @Input()
    options: any[];

    @Input()
    required: boolean;

    @Input()
    translateLabels: boolean;

    @Input()
    optionFormatter: (option: any) => SelectItem;

    /**
     * Function returning option identity
     * Useful when option is some object with 'id' field and we want to compare objects by their id, not reference
     */
    @Input()
    optionKey: (option: any) => any;

    @Input()
    allowSelectingNone = false;

    @Input()
    noneOptionPosition: 'first' | 'last' = 'first';

    @Input()
    noLongerAvailable = false;

    @Input()
    checkAvailability = false;

    @Input()
    fakeSelect = false; // set true if select is handled via dialog (like terrace handle layout)

    @Output()
    readonly onClick = new EventEmitter<MouseEvent>();

    @Output()
    readonly beginInvalidValueCorrection = new EventEmitter<SelectInvalidValueCorrectedEvent>();

    @Output()
    readonly endInvalidValueCorrection = new EventEmitter<void>();

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    @ViewChild('select', {static: true})
    selectElement: ElementRef;

    formattedOptions: SelectOption[];
    optionTracker: (index, option: SelectItem) => any;
    private readonly defaultFormatter: (option: any) => SelectItem;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
        this.options = [];
        this.optionFormatter = option => option;
        this.optionKey = value => value;
        this.formattedOptions = [];
        this.optionTracker = (index, option) => this.optionKey(option.value);
        this.defaultFormatter = option => option;
    }

    protected getContainer(): ElementRef {
        return this.containerElement;
    }

    writeValue(obj: any): void {
        let selectedOptionIndex = this.formattedOptions.findIndex(option => {
            return (option.value === this.NO_SELECTION_MARKER && obj === this.NO_SELECTION_MARKER)
                || this.areValuesEqual(option.value, obj);
        });
        if (selectedOptionIndex !== -1) {
            super.writeValue(obj);
            this.renderer.setProperty(this.selectElement.nativeElement, 'value', this.formattedOptions[selectedOptionIndex].nativeValue);
        } else {
            let valueFix = undefined;
            if (!this.allowSelectingNone) {
                // special case - select the placeholder
                selectedOptionIndex = 0;
                if (obj == undefined) { // special case: allow undefined even if allowSelectingNone is false if it was the initial state
                    this.renderer.setProperty(this.selectElement.nativeElement, 'value', this.NO_SELECTION_MARKER);
                } else if (this.formattedOptions.length > 0) {
                    valueFix = this.formattedOptions[0].value;
                    this.renderer.setProperty(this.selectElement.nativeElement, 'value', this.formattedOptions[0].nativeValue);
                }
            }
            this.beginInvalidValueCorrection.emit({reason: SelectInvalidValueCorrectionReason.EXTERNAL_WRITE_INVALID, invalidValue: obj});
            this.value = valueFix;
            this.renderer.setProperty(this.selectElement.nativeElement, 'selectedIndex', selectedOptionIndex);
            this.endInvalidValueCorrection.emit();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        super.ngOnChanges(changes);
        if ('options' in changes || 'optionFormatter' in changes) {
            let optionsValue = this.options;
            let optionFormatterValue = this.optionFormatter;
            let options = changes['options'];
            if (options != undefined) {
                optionsValue = options.currentValue != undefined ? options.currentValue : [];
            }
            let optionFormatter = changes['optionFormatter'];
            if (optionFormatter != undefined) {
                optionFormatterValue = optionFormatter.currentValue != undefined ? optionFormatter.currentValue : this.defaultFormatter;
            }
            this.buildOptions(optionsValue, optionFormatterValue);
        }
        if ('allowSelectingNone' in changes) {
            this.buildOptions(this.options, this.optionFormatter);
        }
    }

    private buildOptions(options: any[], optionFormatter: (option: any) => SelectItem): void {
        this.formattedOptions = options.map((option, index) => this.buildOption(optionFormatter(option), index));
        let noneOption = new SelectOption(this.placeholder, undefined, this.NO_SELECTION_MARKER, false, true, false, false);
        if (this.allowSelectingNone) {
            switch (this.noneOptionPosition) {
                case 'first':
                    this.formattedOptions.unshift(noneOption);
                    break;
                case 'last':
                    this.formattedOptions.push(noneOption);
                    break;
                default:
                    throw new Error('Unsupported noneOptionPosition: ' + this.noneOptionPosition);
            }
        }
        // defer check to next change detection cycle
        // if someone changes both selected value and options at the same time
        // and new selected value is valid for new options but not for old options
        // ngOnChanges happens first, erasing selection, then writeValue is never called with new value
        setTimeout(() => {
            if (this.formattedOptions.length === 0) {
                if (this.value != undefined) {
                    this.beginInvalidValueCorrection.emit({
                        reason: SelectInvalidValueCorrectionReason.OPTIONS_CHANGED,
                        invalidValue: this.value
                    });
                    this.value = undefined;
                    this.endInvalidValueCorrection.emit();
                }
            } else if (this.value != undefined // special case: allow undefined even if allowSelectingNone is false if it was the initial state
                && this.formattedOptions.find(option => this.optionKey(option.value) === this.optionKey(this.value)) == undefined) {
                const newValue = this.allowSelectingNone ? undefined : this.formattedOptions[0].value;
                if (!this.areValuesEqual(this.value, newValue)) {
                    this.beginInvalidValueCorrection.emit({
                        reason: SelectInvalidValueCorrectionReason.OPTIONS_CHANGED,
                        invalidValue: this.value
                    });
                    this.value = newValue;
                    this.endInvalidValueCorrection.emit();
                }
            }
        });
    }

    private buildOption(option: SelectItem, index: number): SelectOption {
        return new SelectOption(option.label, option.value,
            `${index}: ${Object.prototype.toString.call(option)}`, option.disabled, option.available, (option as SelectItemExtended).bold,
            (option as SelectItemExtended).center);
    }

    handleChange(event: Event): void {
        this.value = this.formattedOptions.find(option => option.nativeValue === (event.target as HTMLSelectElement).value).value;
    }

    handleClick(event: MouseEvent): void {
        this.onClick.emit(event);
    }

    handleClickIfFakeSelect(event: MouseEvent): void {
        if (!this.disabled) {
            this.onClick.emit(event);
        }
    }

    private areValuesEqual(left: any, right: any): boolean {
        // normal equality check
        if (this.optionKey(left) === this.optionKey(right)) {
            return true;
        }
        // null/undefined special case
        if (this.optionKey(left) == undefined && this.optionKey(right) == undefined) {
            return true;
        }
        return false;
    }
}
