<p-dialog header="{{ 'OFFER.ACTIONS.DIALOGS.MESSAGES.TITLE.MESSAGES' | translate }}"
          [(visible)]="dataInitialized" (onHide)="close()" [resizable]="false" [modal]="true" [focusOnShow]="false">
    <div class="scrolled-dialog scrolled-dialog-900">
        <ng-container *ngIf="displayedDialog === dialogType.POSITION_MESSAGES">
            <ng-container *ngTemplateOutlet="messagesList; context: { messages: messages }"></ng-container>
        </ng-container>

        <ng-container *ngIf="displayedDialog === dialogType.OFFER_MESSAGES">
            <p-tabView>
                <p-tabPanel header="{{'OFFER.ACTIONS.DIALOGS.OFFER' | translate}}">
                    <ng-container *ngTemplateOutlet="messagesList; context: { messages: messages }"></ng-container>
                </p-tabPanel>
                <p-tabPanel header="{{'OFFER.ACTIONS.DIALOGS.OFFER_POSITIONS' | translate}}">
                    <p-tabMenu [model]="messagesTabMenuItems"
                                   [activeItem]="activePositionMessagesItem"></p-tabMenu>
                    <ng-container *ngTemplateOutlet="messagesList; context: { messages: positionMessages && positionMessages[activePositionMessageItemIndex].messages }"></ng-container>
                </p-tabPanel>
            </p-tabView>
        </ng-container>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.OK' | translate }}" type="main-action" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<ng-template #messagesList let-messages="messages">
    <p-dataTable [value]="messages"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" [seqNum]="3"
                 [rowStyleClass]="lookupRowStyleClass">
        <p-column field="message"
                  header="{{'OFFER.POSITIONS.DIALOGS.MESSAGES.MESSAGE' | translate}}">
            <ng-template let-msg="rowData" pTemplate="body">
                <div [innerHTML]="(msg.fieldName ? (getFieldName(msg) | translate) + ' - ' : ' ') +
                            (msg.messageCode | translate:msg.messageCodeParams)">
                </div>
            </ng-template>
        </p-column>
    </p-dataTable>
</ng-template>
