<p-dialog *ngIf="dataReady" [modal]="true" [resizable]="false" (onHide)="resetDialog()" [visible]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_COLOR_CHANGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600">
        <ng-container *ngIf="!isTerrace else terraceColors">
            <div class="new-form-row">
                <app-select inputId="coreColor" label="{{ 'OFFER.TABS.SECTION.COLOR.CORE_COLOR' | translate }}"
                            [(validationMessageKey)]="validationErrors['coreColor']" [options]="coreColors"
                            [optionFormatter]="formatColorOption" [ngModel]="chosenCoreColor"
                            (ngModelChange)="onCoreColorChange($event)"></app-select>
            </div>
            <div class="new-form-row">
                <app-select inputId="externalColor" label="{{ 'OFFER.TABS.SECTION.COLOR.EXTERNAL_COLOR' | translate }}"
                            [(validationMessageKey)]="validationErrors['externalColor']" [options]="externalColors"
                            [optionFormatter]="formatColorOption" [allowSelectingNone]="true"
                            [(ngModel)]="chosenExternalColor"></app-select>
            </div>
            <div class="new-form-row">
                <app-select inputId="internalColor" label="{{ 'OFFER.TABS.SECTION.COLOR.INTERNAL_COLOR' | translate }}"
                            [(validationMessageKey)]="validationErrors['internalColor']" [options]="internalColors"
                            [optionFormatter]="formatColorOption" [allowSelectingNone]="true"
                            [(ngModel)]="chosenInternalColor"></app-select>
            </div>
        </ng-container>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<ng-template #terraceColors>
    <div class="new-form-row">
        <app-select inputId="terraceColor" label="{{ 'OFFER.TABS.SECTION.COLOR.TERRACE_COLOR' | translate }}"
                    [(validationMessageKey)]="validationErrors['terraceColor']" [options]="allColors"
                    [optionFormatter]="formatColorOption" [(ngModel)]="chosenTerraceColor"></app-select>
    </div>
</ng-template>
