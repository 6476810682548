<div>
    <!-- block UI -->
    <div *ngIf="visibleState == 'visible'" class="upselling-block-ui" (click)="hide()"></div>

    <!-- sidebar contents -->
    <div class="upselling-sidebar" [@sidebarVisibilityState]="visibleState" (@sidebarVisibilityState.done)="sidebarAnimationFinished()">
        <div *ngIf="visible" class="container">
            <div class="move-scroll-to-child">
                <div class="upselling-sidebar-header">
                    <div>{{ 'UPSELLING-SIDEBAR.TITLE' | translate }}</div>
                </div>
                <div class="cost-income-switch">
                    <span>{{'UPSELLING_SIDEBAR.VALUE_DIFF_MODE' | translate}}</span>
                    <p-inputSwitch [(ngModel)]="showNetIncome"></p-inputSwitch>
                    <span>{{'UPSELLING_SIDEBAR.NET_INCOME_MODE' | translate}}</span>
                </div>
                <div class="move-scroll-to-child upselling-table-container">
                    <div class="tabs-header">{{ 'UPSELLING-SIDEBAR.TABS_HEADER' | translate }}</div>
                    <p-tabView class="move-scroll-to-child" [activeIndex]="selectedPropositionTab"
                               (activeIndexChange)="handlePropositionTabChange($event)">
                        <p-tabPanel *ngFor="let upsellingResult of upsellingResults; let i = index"
                                    header="{{upsellingResult.tabName[translateService.currentLang] | translate}}"
                                    [selected]="i === selectedPropositionTab">
                            <div class="upselling-table">
                                <div class="upselling-table__header"></div>
                                <div class="upselling-table__header"></div>
                                <div class="upselling-table__header"></div>
                                <div class="upselling-table__header">{{ 'UPSELLING-SIDEBAR.APPLY_ON_OFFER' | translate }}</div>
                                <ng-container *ngFor="let upsellingResult of upsellingResult.propositions;">

                                    <div class="upselling-image">
                                        <img *ngIf="getImage(upsellingResult.id) as image; else missingImage"
                                             [src]="image | appSafeValue: 'url'">
                                        <ng-template #missingImage><div class="image-placeholder"></div></ng-template>
                                    </div>

                                    <div class="upselling-description">
                                        <div class="upselling-description__name">{{ upsellingResult.name[translateService.currentLang] }}</div>
                                        <div *ngIf="upsellingResult.info[translateService.currentLang]"
                                             class="upselling-description__info">{{ upsellingResult.info[translateService.currentLang] }}</div>
                                        <div *ngIf="!showNetIncome" class="upselling-description__price">
                                            {{ formatPriceInSelectedCurrency(upsellingResult.price.netValue) }} / {{ formatPriceInSelectedCurrency(upsellingResult.price.grossValue) }}</div>
                                        <div *ngIf="showNetIncome" class="upselling-description__net-income">
                                            {{ formatPriceInSelectedCurrency(upsellingResult.netIncome) }}</div>
                                    </div>
                                    <div class="upselling-checkbox">
                                        <div [ngClass]="{selected: isSelectedItem(upsellingResult.id)}"
                                             (click)="selectItem(upsellingResult.id)">{{ 'UPSELLING-SIDEBAR.PROPOSE_ON_PRINT' | translate }}</div>
                                    </div>
                                    <div>
                                        <app-simple-button [fixedWidth]="'100%'" [size]="36"
                                                           [label]="'UPSELLING-SIDEBAR.APPLY' | translate"
                                                           [buttonId]="'applyUpselling_' + upsellingResult.name[translateService.currentLang]" type="main-action"
                                                           (onClick)="executeApplyUpselling(upsellingResult)"></app-simple-button>
                                    </div>
                                </ng-container>
                            </div>
                        </p-tabPanel>
                    </p-tabView>
                </div>
            </div>
        </div>
    </div>
</div>
