import {CechaGrupa} from "../../../../../../../window-designer/enums/CechaGrupa";
import {CechaType} from "../../../../../../../window-designer/enums/CechaType";
import {ConfigAddonApplication} from '../../../../../../../window-designer/enums/ConfigAddonApplication';
import {Opcja} from "./Opcja";

export class Cecha {
    type: CechaType;
    grupa: CechaGrupa;
    nameCode: string;
    symbol: string;
    hidden: boolean;
    obowiazkowa: boolean;
    disabled: boolean;
    hasAutoOption: boolean;
    defaultValue: string;
    wartoscDomyslna: string;
    jednostkaMiary: string;
    dependsOn: string;
    visibilityDependsOn: string;
    visibleIf: string;
    dependentValueIn: string;
    dependentValueNotIn: string;
    opcje: Opcja[] = [];
    minValue: number;
    maxValue: number;
    step: number;

    // runtime only - not in json
    isVisibleByApplication?: (application: ConfigAddonApplication) => boolean;
    isVisibleByMasterValue?: (masterValue: string) => boolean;
}
