<p-dialog [visible]="visible" [resizable]="false" [modal]="true" [style]="{'width.px': 1200}"
          [focusOnShow]="false" (onHide)="emitCloseDialog()">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.ADD_ADDON_BULK' | translate }}
        </div>
    </p-header>
    <div>
        <div class="bulk-addon-dialog-container">
            <div class="new-form-field-error-message" *ngIf="availableCategories.length === 0">
                {{'OFFER.DRAWING.ADDONS.NO_ADDONS_FOR_SELECTION'| translate}}
            </div>

            <div *ngIf="availableCategories.length !== 0" style="min-height: 60vh" class="flex-row-container">
                <div class="second-level-menu compact">
                    <app-checkbox *ngIf="!ownAddons" inputId="showAllSuppliersCheckbox"
                                  label="{{ 'OFFER.POSITIONS.SHOW_ADDONS_FOR_ALL_SUPPLIERS' | translate }}"
                                  [ngModel]="showAllSuppliers"
                                  (ngModelChange)="handleShowAllSuppliersChange($event)"></app-checkbox>

                    <app-search [component]="'add-bulk-addon-search'" (searched)="handleSearch($event)"></app-search>

                    <div *ngFor="let tabPanel of tabPanelsData" class="navigation-element compact" (click)="handleChange(tabPanel)"
                         [class.active]="currentTabId == tabPanel.index">

                        <ng-container *vLet="((('ADDONS.CATEGORIES.' + tabPanel.name) | translate) + getActiveSuffix(tabPanel.index)) as text">
                            <div class="name" [pTooltip]="text" [tooltipDisabled]="text.length <= 17">
                                {{ text }}
                            </div>
                            <div *ngIf="searchedNameCategoryCounts != undefined">
                                {{ searchedNameCategoryCounts.get(tabPanel.name) }}
                            </div>
                        </ng-container>
                    </div>
                </div>

                <div class="list-content">
                    <ng-container *ngFor="let tabPanel of tabPanelsData">
                        <div [class.hidden]="currentTabId !== tabPanel.index">
                            <app-addon-position *ngIf="panelContentVisible[tabPanel.index]"
                                                [offer]="offer"
                                                [suppliers]="!showAllSuppliers ? suppliers : undefined"
                                                [bulkAddonsOnly]="true"
                                                [selectedCategory]="tabPanel.name" [tabViewAddedMode]="false"
                                                [tabIndex]="tabPanel.index"
                                                (tableDataLoaded)="tableDataLoaded()"
                                                [includeGeneralAddons]="!ownAddons"
                                                [includeSubsystemAddons]="ownAddons"
                                                [subsystemDefaultCurrency]="subsystemDefaultCurrency"></app-addon-position>
                        </div>
                    </ng-container>
                    <div [class.hidden]="currentTabId != tabPanelsData.length">
                        <app-addon-position *ngIf="panelContentVisible[tabPanelsData.length]"
                                            [offer]="offer"
                                            [suppliers]="!showAllSuppliers ? suppliers : undefined"
                                            [bulkAddonsOnly]="true"
                                            [tabViewAddedMode]="false"
                                            [tabIndex]="tabPanelsData.length"
                                            (tableDataLoaded)="tableDataLoaded()"
                                            [includeGeneralAddons]="!ownAddons"
                                            [includeSubsystemAddons]="ownAddons"
                                            [subsystemDefaultCurrency]="subsystemDefaultCurrency"
                                            [searchedName]="searchedName"
                                            (searchedNameCategoryResultsChange)="handleSearchedNameCategoryResults($event)"></app-addon-position>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <div class="table-footer">
                <div class="dialog-footer-error-message" *ngIf="validationErrors['coreColor']">
                    {{validationErrors['coreColor'] | translate}}
                </div>
                <div class="dialog-footer-error-message" *ngIf="validationErrors['coverColor']">
                    {{validationErrors['coverColor'] | translate}}
                </div>
                <div class="dialog-footer-error-message" *ngIf="validationErrors['insideColor']">
                    {{validationErrors['insideColor'] | translate}}
                </div>
                <div class="dialog-footer-error-message" *ngIf="validationErrors['outsideColor']">
                    {{validationErrors['outsideColor'] | translate}}
                </div>
                <div class="dialog-footer-error-message" *ngIf="validationErrors['quantity']">
                    {{validationErrors['quantity'] | translate}}
                </div>
            </div>
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               [disabled]="nothingSelected() || saveInProgress" buttonId="saveBtn"
                               (onClick)="addSelected()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="emitCloseDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<app-missing-profit-margin-handler></app-missing-profit-margin-handler>
