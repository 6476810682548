import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {SupportedLanguages} from "../../../../supportedLanguages";
import {RailSystemField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {RailSystemFieldUsage} from "../../catalog-field-usage";
import {RailSystem} from "../rail-system";

@Component({
    selector: 'app-rail-system-form',
    templateUrl: './rail-system-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RailSystemFormComponent {

    supportedLanguages = SupportedLanguages.languages;

    @Input() item: RailSystem;
    @Input() validationErrors: any;
    @Input() file: File;
    @Input() designerFile: File;
    @Input() editPermits: FieldLimitation[] = [];
    @Output() readonly fileChange: EventEmitter<File> = new EventEmitter<File>();
    @Output() readonly designerFileChange: EventEmitter<File> = new EventEmitter<File>();
    @Input() additionalIcon: File;
    @Output() readonly additionalIconChange = new EventEmitter<File>();

    fieldUsage: RailSystemFieldUsage;
    RailSystemField = RailSystemField;

    constructor() {
        this.fieldUsage = new RailSystemFieldUsage(this);
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    onDesignerFileChange(file: File): void {
        this.designerFileChange.emit(file);
        this.designerFile = file;
    }
}
