export enum GatePanelStampingType {
    ALUTECH_L = 'ALUTECH_L',
    ALUTECH_M = 'ALUTECH_M',
    ALUTECH_S = 'ALUTECH_S',
    ALUTECH_RECTS = 'ALUTECH_RECTS',
    BRAMTECH_MULTILINE = 'BRAMTECH_MULTILINE',
    BRAMTECH_ONELINE = 'BRAMTECH_ONELINE',
    BRAMTECH_EXTRA = 'BRAMTECH_EXTRA',
    BRAMTECH_QUADRO = 'BRAMTECH_QUADRO',
    BRAMTECH_MULTI_INDUSTRIAL = 'BRAMTECH_MULTI_INDUSTRIAL',
    BRAMTECH_MICRO_INDUSTRIAL = 'BRAMTECH_MICRO_INDUSTRIAL'
}
