import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MessageService} from 'primeng/api';
import {Message} from 'primeng/api/message';
import {Toast} from 'primeng/toast';
import {GrowlMessage} from "./growl-message";
import {GrowlMessageController} from "./growl-message-controller";

@Component({
    selector: 'app-growl-message',
    templateUrl: './growl-message.component.html',
    styleUrls: ['../../features/shared-styles.css'],
    providers: [MessageService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GrowlMessageComponent implements OnInit {

    @ViewChild(Toast)
    toast: Toast;

    constructor(private growlMessageController: GrowlMessageController,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef,
                private toastMessageService: MessageService) {}

    ngOnInit(): void {
        this.growlMessageController.newGrowl.subscribe(message => {
            if (message.severity === 'clear_stickies') {
                if (this.toast.messages != undefined) {
                    // HACK: abuse mouseleave event of toast items to clear sticky status without clearing all toast messages
                    this.toast.messages.forEach(m => m.sticky = false);
                    document.querySelectorAll('.p-toast-message').forEach(value => {
                        value.dispatchEvent(new MouseEvent('mouseleave'));
                    });
                    this.toastMessageService.addAll([]);
                }
            } else {
                this.showMessage(message);
            }
            this.changeDetector.markForCheck();
        });
    }

    showMessage(growlMessage: GrowlMessage): void {
        this.translate.get([growlMessage.detail, growlMessage.summary], growlMessage.params).subscribe((res: any) => {
            let message: Message = {
                severity: growlMessage.severity,
                summary: res[growlMessage.summary],
                detail: res[growlMessage.detail],
                life: 6000,
                sticky: growlMessage.sticky,
                closable: true
            };
            this.pushMessage(message);
        });
    }

    private pushMessage(message: Message): void {
        this.toastMessageService.add(message);
        this.changeDetector.markForCheck();
    }
}
