import {Pipe, PipeTransform} from '@angular/core';
import {ExchangeService} from '../exchange.service';

@Pipe({
    name: 'price'
})
export class PricePipe implements PipeTransform {

    transform(value: number | string, currency: string): string {
        if (typeof value === 'number') {
            value = value.toFixed(2);
        }
        return ExchangeService.formatPriceInCurrency(value, currency);
    }
}
