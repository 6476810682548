import {FilterMetadata} from "primeng/api/filtermetadata";

export class FiltersHelper {

    private static readonly SUBSYSTEM_IDS = 'subsystemIds';
    private static readonly SUBSYSTEM = 'subsystem';

    public static filterBySubsystemIds(filters: {[s: string]: FilterMetadata}, selectedIds: number[]): void {
        if (selectedIds.length > 0) {
            filters[this.SUBSYSTEM_IDS] = {value: selectedIds, matchMode: undefined};
        } else {
            delete filters[this.SUBSYSTEM_IDS];
        }
    }

    public static filterBySubsystemNames(filters: {[s: string]: FilterMetadata}, selectedSubsystems: string[]): void {
        if (selectedSubsystems.length > 0) {
            const subsystems = selectedSubsystems.join(';');
            filters[this.SUBSYSTEM] = {value: subsystems, matchMode: 'in'};
        } else {
            delete filters[this.SUBSYSTEM];
        }
    }
}
