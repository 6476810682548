import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import * as _ from 'underscore';
import {WindowTypeCodeParser} from '../../../../window-designer/utils/WindowTypeCodeParser';
import {WindowTypeCode} from '../../../../window-designer/window-types/window-type-code';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {BusinessTypeField, CatalogTab} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {BusinessTypeFieldUsage} from "../catalog-field-usage";
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {BusinessTypeService} from './business-type.service';
import {BusinessType} from './BusinessType';
import {BusinessTypeWithMaterials} from './BusinessTypeWithMaterials';

@Component({
    selector: 'app-business-type',
    templateUrl: './business-type.component.html',
    styleUrls: ['./business-type.component.css', '../../shared-styles.css'],
    providers: [DataServiceHelper, BusinessTypeService, WindowSystemDefinitionService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BusinessTypeComponent extends SingleSystemCheckboxCrudComponent<BusinessType, BusinessTypeService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };

    item: BusinessTypeWithMaterials;
    filterTypes: Observable<SelectItem[]>;

    nameParser: (typeCode: WindowTypeCode) => string;
    editedItemOriginalWindowSystems: number[];
    selectedWindowSystemsAfterRemoval: number[];
    displayYesNoDialog = false;

    validateDataStep: WizardStepValidator;

    @ViewChild('dt', {static: true}) datatable;

    editPermits: FieldLimitation[] = [];
    fieldUsage: BusinessTypeFieldUsage;
    CatalogTab = CatalogTab;
    BusinessTypeField = BusinessTypeField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, false, BusinessTypeService, 'BUSINESSTYPE', 'BusinessType');
        this.item = new BusinessTypeWithMaterials();
        this.nameParser = WindowTypeCodeParser.getTypeName;
        this.initDefaultSortOrder();
        this.validateDataStep = () => this.validateForm();
        this.fieldUsage = new BusinessTypeFieldUsage(this);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        this.getWindowSystems();
        this.initShownColumns();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterTypes = this.translatedSelectItemService.buildSortedDropdown(WindowTypeCode, WindowTypeCodeParser.getTypeName, '');
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.BUSINESS_TYPES).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'businessTypes';
    }

    onRowSelect(event) {
        this.item = this.cloneBusinessType(event.data);

        this.getLinkedWindowSystems(this.item.id).subscribe({
            next: data => {
                this.selectedWindowSystems = data;
                this.editedItemOriginalWindowSystems = [...this.selectedWindowSystems];
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });

        this.keepSelectedItemIndex(event);
    }

    cloneBusinessType(businessType: BusinessTypeWithMaterials): BusinessTypeWithMaterials {
        let newBusinessType = _.clone(businessType) as BusinessTypeWithMaterials;
        newBusinessType.names = (_.clone(businessType.names) as MultilanguageField);
        return newBusinessType;
    }

    continueWithUnlink(continueProcess) {
        if (!this.displayYesNoDialog) {
            return;
        }
        if (continueProcess && this.selectedWindowSystemsAfterRemoval) {
            this.selectedWindowSystems = this.selectedWindowSystemsAfterRemoval;
            this.selectedWindowSystemsAfterRemoval = undefined;
        } else {
            // make new reference for change detection
            this.selectedWindowSystems = [...this.selectedWindowSystems];
        }
        this.displayYesNoDialog = false;
        this.changeDetector.markForCheck();
    }

    handleSelectedWindowSystemsChange(selectedWindowSystems: number[]) {
        if (!this.selectedWindowSystems || this.selectedWindowSystems.length < selectedWindowSystems.length) {
            // added selection - no magic, allow
            this.selectedWindowSystems = selectedWindowSystems;
        } else {
            // removed selection
            const removedItems = _.difference(this.selectedWindowSystems, selectedWindowSystems);
            if (removedItems.some(removedItem => this.editedItemOriginalWindowSystems.indexOf(removedItem) !== -1)) {
                this.selectedWindowSystemsAfterRemoval = selectedWindowSystems;
                this.displayYesNoDialog = true;
            } else {
                this.selectedWindowSystems = selectedWindowSystems;
            }
        }
    }

    submit() {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        this.itemService.edit(this.item, this.prepareSystemIdsForRequest())
            .subscribe(this.genericCleanupAndReloadSuccessObserver());
    }

    getNewItem(): BusinessType {
        return new BusinessType();
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }

    validateForm(): Observable<boolean> {
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }
}
