import {Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, SimpleChanges, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {Address} from '../../../common/address';
import {Country} from '../../../common/enums/country';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ZipCodeValidator} from '../../../common/zip-code-validator';
import {AbstractInputComponent, FormHandler} from '../../../form-inputs/inputs/abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../../../form-inputs/inputs/form-handler-token';
import {TabularAddress} from '../tabularAddress';

@Component({
    selector: 'app-subsystem-address-list-form',
    templateUrl: './subsystem-address-list-form.component.html',
    styleUrls: ['./subsystem-address-list-form.component.css'],
    providers: [TranslatedSelectItemService]
})
export class SubsystemAddressListFormComponent implements OnInit, OnChanges {

    @Input()
    addresses: TabularAddress[];

    @Input()
    usedAddresses: TabularAddress[];

    @Output()
    readonly addressesChange = new EventEmitter<TabularAddress[]>();

    @Input()
    selectedAddress: TabularAddress;

    @Output()
    readonly selectedAddressChange = new EventEmitter<TabularAddress>();

    @Input()
    canEdit: boolean;

    @Input()
    selectable: boolean;

    @Input()
    validationErrors: ValidationErrors = {};

    countries: Observable<SelectItem[]>;

    private editingAddressIndex = -1;
    editedAddress: TabularAddress;
    editedAddressValidationErrors: ValidationErrors = {};

    dialogTabValidationError: string;

    @ViewChild('dialogTabValidationMarker', {static: true})
    dialogTabValidationMarker: AbstractInputComponent;

    constructor(private translatedSelectItemService: TranslatedSelectItemService,
                @Inject(FORM_HANDLER) @Optional() private form: FormHandler) {
    }

    ngOnInit(): void {
        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('validationErrors' in changes) {
            const validationErrors = changes['validationErrors'].currentValue;
            this.dialogTabValidationError = this.hasAddressErrors(validationErrors) ? 'fake_error' : undefined;
        }
    }

    private hasAddressErrors(validationErrors: ValidationErrors): boolean {
        return Object.keys(validationErrors)
            .some(key => key.startsWith('addresses[') && validationErrors[key] != undefined);
    }

    reset(): void {
        this.editingAddressIndex = -1;
        this.editedAddress = undefined;
        this.editedAddressValidationErrors = {};
    }

    createNewAddress(): void {
        this.editingAddressIndex = -1;
        this.editedAddress = new TabularAddress();
        this.editedAddress.address = new Address();
        this.editedAddress.tempId = Math.floor(Math.random() * -1000000);
        this.editedAddressValidationErrors = {};
    }

    handleEdit(address: TabularAddress): void {
        this.editingAddressIndex = this.addresses.indexOf(address);
        this.editedAddress = {
            id: address.id,
            address: {...address.address},
            removable: address.removable
        };
        this.validateEditedAddress();
    }

    handleDelete(address: TabularAddress): void {
        const newAddresses = [...this.addresses];
        newAddresses.splice(newAddresses.indexOf(address), 1);
        this.addressesChange.emit(newAddresses);
    }

    handleSelect(address: TabularAddress): void {
        if (this.selectable) {
            this.selectedAddressChange.emit(address);
        }
    }

    handleSaveAddress(): void {
        if (!this.validateEditedAddress()) {
            return;
        }
        // save
        const newAddresses = [...this.addresses];
        if (this.editingAddressIndex >= 0) {
            newAddresses.splice(this.editingAddressIndex, 1, this.editedAddress);
            // kind of a hack for dialog tab error marker
            if (this.validationErrors != undefined) {
                Object.keys(this.validationErrors)
                    .filter(key => key.startsWith(`addresses[${this.editingAddressIndex}]`))
                    .forEach(key => this.validationErrors[key] = undefined);
                if (this.form && !this.hasAddressErrors(this.validationErrors)) {
                    this.form.onInputChange(undefined, this.dialogTabValidationMarker);
                }
            }
        } else {
            newAddresses.push(this.editedAddress);
        }
        this.addressesChange.emit(newAddresses);
        const selectedAddressIndex = this.addresses.indexOf(this.selectedAddress);
        if (selectedAddressIndex >= 0 && this.editingAddressIndex === selectedAddressIndex) {
            this.selectedAddressChange.emit(newAddresses[selectedAddressIndex]);
        }
        this.editingAddressIndex = -1;
        this.editedAddress = undefined;
    }

    validateEditedAddress(): boolean {
        const validationErrors: ValidationErrors = {};
        if (!this.editedAddress.address.street) {
            validationErrors['street'] = 'error.subsystemDto.correspondenceAddress.street.not_null';
        }
        if (!this.editedAddress.address.city) {
            validationErrors['city'] = 'error.subsystemDto.correspondenceAddress.city.not_null';
        }
        if (!this.editedAddress.address.country) {
            validationErrors['country'] = 'error.subsystemDto.correspondenceAddress.country.not_null';
        }
        if (!this.editedAddress.address.zip) {
            validationErrors['zip'] = 'error.subsystemDto.correspondenceAddress.zip.not_null';
        } else if (!new ZipCodeValidator().validate(this.editedAddress.address.country, this.editedAddress.address.zip)) {
            validationErrors['zip'] = 'error.subsystemDto.correspondenceAddress.zip.pattern_not_matched';
        }
        this.editedAddressValidationErrors = validationErrors;
        return !ValidationErrorsHelper.validationErrorsPresent(this.editedAddressValidationErrors);
    }
}
