import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';
import {TranslationTarget} from './translation-target.enum';

@JsonDeserializable
export class UiTranslation {
    key: string;
    target: TranslationTarget;
    text: MultilanguageFieldInterface;
    lastModifiedManually: Date;
    lastModifiedAuto: Date;

    static fromJSON(jsonObject: any): UiTranslation {
        const uiTranslation = new UiTranslation();
        uiTranslation.key = jsonObject.key;
        uiTranslation.target = TranslationTarget[jsonObject.target as string];
        uiTranslation.text = MultilanguageField.fromJSON(jsonObject.text);
        uiTranslation.lastModifiedManually = jsonObject.lastModifiedManually ? new Date(jsonObject.lastModifiedManually) : undefined;
        uiTranslation.lastModifiedAuto = jsonObject.lastModifiedAuto ? new Date(jsonObject.lastModifiedAuto) : undefined;
        return uiTranslation;
    }
}
