import {AreaSpecification} from '../drawing-data/AreaSpecification';
import {GrillGrid} from '../drawing-data/GrillGrid';
import {GrillSegment} from '../drawing-data/GrillSegment';
import {GrillType} from '../drawing-data/GrillType';
import {LineGrillSegment} from '../drawing-data/LineGrillSegment';
import {SubWindowData} from '../drawing-data/SubWindowData';
import {DrawingUtil} from '../drawing-util';
import {PendingGrillData} from '../pending-grill-data';
import {ProfilesCompositionDistances} from '../profiles-composition-distances';
import {WindowCalculator} from '../window-calculator';
import {AreaUtils} from './AreaUtils';
import {ErrorNames} from './ErrorNames';
import {GrillHelper} from './grill-helper';
import {GrillTypes} from "../enums/GrillTypes";
import {PositionReferenceType} from "../drawing-data/PositionReferenceType";

export class GrillPositionValidator {

    static readonly MINIMAL_GRILL_DISTANCE = 80;

    public static isFittingGridPossible(data: PendingGrillData): boolean {
        let grill = data.grill as GrillGrid;
        if (grill.type === GrillType.GRID_STANDARD || grill.type === GrillType.GRID_RHOMBUS) {
            let distance = grill.width + this.MINIMAL_GRILL_DISTANCE;
            if (data.area.width < (distance * grill.columns)) {
                return false;
            }
            if (data.area.height < (distance * grill.rows)) {
                return false;
            }
        }
        return true;
    }

    private static validateSpacingBetweenGrills(subwindow: SubWindowData, area: AreaSpecification, totalGlazingBead: number[],
                                                profileCompositionDistances: ProfilesCompositionDistances): void {
        type GrillSegmentPositionInfo = { pos: number, width: number, min: number, max: number };
        let posWithWidth = (pos: number, width: number, points: number[]) => {
            return {
                pos: points[pos],
                width: width,
                min: Math.min(DrawingUtil.getPoint(points, pos + 1), DrawingUtil.getPoint(points, pos + 3)),
                max: Math.max(DrawingUtil.getPoint(points, pos + 1), DrawingUtil.getPoint(points, pos + 3))
            };
        };
        let sortedAreaSegments = (func: (gs: LineGrillSegment) => boolean, pos: number) => {
            let segments: GrillSegmentPositionInfo[] = [];
            area.grills.forEach(g => {
                let filteredSegments = g.drawingSegments.filter((s): s is LineGrillSegment => (GrillSegment.isLine(s) && func(s)));
                filteredSegments.forEach(fs => segments.push(posWithWidth(pos, (g.width / 2), fs.points)));
            });
            return segments.sort((a, b) => a.pos - b.pos);
        };
        let distanceError = (onFrame: boolean) => {
            let err = new Error("GrillHelper.validateSpacingBetweenGrills: Szpros znajduje się za blisko " +
                (onFrame ? "ramy" : "innego szprosa"));
            err.name = onFrame ? ErrorNames.GRILL_TO_CLOSE_TO_FRAME : ErrorNames.GRILL_TO_CLOSE_TO_ANOTHER_GRILL;
            throw err;
        };
        let glazingBead = WindowCalculator.getGlazingBeadPoints(subwindow, area.definingMullions, totalGlazingBead,
            profileCompositionDistances);
        let bbox = DrawingUtil.calculateMinMaxFromPolygon(glazingBead);
        let horizontalSegments = sortedAreaSegments(GrillHelper.isSegmentHorizontal, 1);
        let verticalSegments = sortedAreaSegments(GrillHelper.isSegmentVertical, 0);

        let validate = (segments: GrillSegmentPositionInfo[], minFrame: { pos: number, width: number },
                        maxFrame: { pos: number, width: number }) => {
            for (let i = 0; i < segments.length; i++) {
                let currentSegment = segments[i];
                let significantSegments = segments.filter(
                    s => !((currentSegment.max <= s.min) || (currentSegment.min >= s.max)));
                let sorted = [minFrame, ...significantSegments, maxFrame].sort((a, b) => a.pos - b.pos);
                let index = sorted.indexOf(currentSegment);
                let prevTooClose = (sorted[index].pos - sorted[index - 1].pos) < this.MINIMAL_GRILL_DISTANCE +
                    sorted[index].width + sorted[index - 1].width;
                let nextTooClose = (sorted[index + 1].pos - sorted[index].pos) < this.MINIMAL_GRILL_DISTANCE +
                    sorted[index].width + sorted[index + 1].width;
                if (prevTooClose || nextTooClose) {
                    let onFrame = (prevTooClose && index === 1) || (nextTooClose && index === sorted.length - 2);
                    distanceError(onFrame);
                }
            }
        };

        validate(horizontalSegments, {pos: bbox.minY, width: 0}, {pos: bbox.maxY, width: 0});
        validate(verticalSegments, {pos: bbox.minX, width: 0}, {pos: bbox.maxX, width: 0});
    }

    private static validateGrillsNotPositionedAlongsideFrame(area: AreaSpecification, totalGlazingBead: number[]): void {
        for (let grill of area.grills) {
            for (let segment of grill.drawingSegments) {
                if (GrillSegment.isLine(segment)) {
                    let sideA = DrawingUtil.getParallelLine(segment.points, grill.width / 2);
                    let sideB = DrawingUtil.getParallelLine(segment.points, -grill.width / 2);
                    if (DrawingUtil.polygonLineIntersectionCount(totalGlazingBead, sideA, true) === 0 ||
                        DrawingUtil.polygonLineIntersectionCount(totalGlazingBead, sideB, true) === 0) {
                        let err = new Error(
                            "GrillHelper.validateGrillsNotPositionedAlongsideFrame: szpros nie może leżeć wzdłóż ramy");
                        err.name = ErrorNames.GRILL_ALONGSIDE_FRAME;
                        throw err;
                    }
                } else {
                    console.warn("Unsupported grill segment type - validateGrillsNotCollidingWithFrame");
                }
            }
        }
    }

    public static validateGrills(subwindow: SubWindowData, totalGlazingBead: number[],
                                 profileCompositionDistances: ProfilesCompositionDistances) {
        for (let i = 0; i < subwindow.areasSpecification.length; i++) {
            if (subwindow.areasSpecification.some(as => (GrillHelper.areaHasGrills(as) && !AreaUtils.canHaveGrills(as)))) {
                let err = new Error("GrillHelper.validateGrills: szprosy zawarte w obszarze o złym typie wypełnienia");
                err.name = ErrorNames.AREA_WITHOUT_GLASS_CONTAINS_GRILLS;
                throw err;
            }
            let area = subwindow.areasSpecification[i];
            this.validateGrillsConstructionDependencies(area);
            this.validateGrillsNotPositionedAlongsideFrame(area, totalGlazingBead);
            this.validateSpacingBetweenGrills(subwindow, area, totalGlazingBead, profileCompositionDistances);
        }
    }

    private static validateGrillsConstructionDependencies(area: AreaSpecification): void {
        if (area.grills.filter(g => g.constructionType !== GrillTypes.INNER).some(
            g => g.positions.filter(p => p.type === PositionReferenceType.GRILL)
                .some(p => !GrillHelper.canDependOn(g, GrillHelper.findGrillBySegmentId(area.grills, p.id))))) {
            let err = new Error(
                "GrillHelper.validateGrills: Szpros naklejany nie może opierać się o szprosy wewnątrzszybowe");
            err.name = ErrorNames.OUTER_GRILL_CANNOT_DEPEND_ON_INNER_GRILLS;
            throw err;
        }
    }
}
