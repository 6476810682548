import {CompositionDistances} from "../composition-distances";

export enum ProfileType {
    FRAME = 'FRAME',
    DECORATIVE_MULLION = 'DECORATIVE_MULLION',
    CONSTRUCTIONAL_MULLION = 'CONSTRUCTIONAL_MULLION',
    MOVABLE_POST = 'MOVABLE_POST',
    THRESHOLD = 'THRESHOLD',
    CHANNEL_SECTION = 'CHANNEL_SECTION'
}

export interface ProfileInterface {
    id: number;
    symbol: string;
    type: ProfileType;
    compositionDistances: CompositionDistances;
}

export interface ProfileListingInterface {
    data: ProfileInterface[];
    totalRecords: number;
}
