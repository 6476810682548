import {RandomIdGenerator} from "../../../../../window-designer/utils/RandomIdGenerator";

export class ConjunctionData {
    root: ConjunctionPositionData;
}

export class ConjunctionPositionData {
    id: number;
    width: number;
    height: number;
    dependees: ConjunctionDependee[] = [];
    generatedId: string = RandomIdGenerator.generate();

    constructor(id: number, width: number, height: number, dependees: ConjunctionDependee[] = []) {
        this.id = id;
        this.width = width;
        this.height = height;
        this.dependees = dependees;
    }

}

export class ConjunctionDependee {
    side: SideEnum;
    coupler: ConjunctionCoupler;
    children: ConjunctionPositionChild[] = [];

    constructor(side?: SideEnum, coupler?: ConjunctionCoupler, children: ConjunctionPositionChild[] = []) {
        this.side = side;
        this.coupler = coupler;
        this.children = children;
    }
}

export class ConjunctionCoupler {
    id: number;
    width: number;
    label: string;

    constructor(id: number, width: number) {
        this.id = id;
        this.width = width;
    }
}

export class ConjunctionPositionChild {
    corner: SideEnum;
    data: ConjunctionPositionData;

    constructor(corner: SideEnum, data?: ConjunctionPositionData) {
        this.corner = corner;
        this.data = data;
    }
}

export enum SideEnum {
    TOP = 'TOP',
    RIGHT = 'RIGHT',
    BOTTOM = 'BOTTOM',
    LEFT = 'LEFT',
}
