import {Component, EmbeddedViewRef, EventEmitter, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {TranslateService} from '@ngx-translate/core';
import {Chart, ChartConfiguration, ChartDataset, ChartOptions, TooltipItem, TooltipModel} from 'chart.js';
import Rainbow from 'rainbowvis.js';
import {forkJoin, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {ApplicationFeatures} from "../../../application-info/application-features";
import {Permissions} from '../../../auth/permission.service';
import {UserUiConfigService} from '../../../auth/uiconfig/userUiConfig.service';
import {ButtonWithMenuElementSelectedEvent} from '../../../common/button-with-menu/button-with-menu-event';
import {MenuElement, MenuElementBuilder, MenuElementType} from '../../../common/button-with-menu/MenuElement';
import {MenuType} from '../../../common/button-with-menu/MenuType';
import {FoldCorner} from '../../../common/card-fold/card-fold.component';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DateRangeFilter, DateRangeKind} from '../../../common/date-range-filter';
import {ExchangeService} from '../../../common/exchange.service';
import {formatNumber} from '../../../common/service/custom-decimal-format.pipe';
import {Currencies} from '../../../currencies';
import {Client} from '../../client/client';
import {ClientService} from '../../client/client.service';
import {PositionType} from '../../offer/AbstractPosition';
import {ConfigurableAddonsService} from '../../offer/offers/position/config-addons.service';
import {ReportDateResolution, ReportMode} from '../../reports/report-definitions';
import {ReportsService} from '../../reports/reports.service';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {SubsystemSelectionItem} from '../../subsystem/SubsystemSelectionItem';
import {SupplierService} from '../../supplier/supplier.service';
import {AddonsService} from '../../window-system/addons/addons.service';
import {GateSystemService} from '../../window-system/gate-system/gate-system.service';
import {WindowSystemDefinitionService} from '../../window-system/window-system-definition/window-system-definition.service';
import {QuickMenuButton} from '../home.component';
import {DashboardReportSettings} from './dashboard-report-settings-form/dashboard-report-settings';
import {ProductionOrderConversions} from './production-order-conversions';
import {ProductionOrderSalesTargetStatistics} from './production-order-sales-target-statistics';
import {ProductionOrderStatistics} from './production-order-statistics';

interface DialogState {
    exists: boolean;  // bind to *ngIf
    visible: boolean; // bind to [(visible)] (for animation and esc key support)
}

interface TooltipContext {
    chartTitle?: string;
    currentYearLabel?: string;
    currentYearValue?: string;
    previousYearLabel?: string;
    previousYearValue?: string;
    top?: number;
    left?: number;
    titleFontColor?: string;
}

interface IncomeOrRevenueDataPoint {
    x: number;
    y: number;
    xDate: Date;
    yCurrency: Currencies;
}

@Component({
    selector: 'app-dashboard-report',
    templateUrl: './dashboard-report.component.html',
    styleUrls: ['./dashboard-report.component.css'],
    providers: [
        SubsystemService,
        ClientService,
        SupplierService,
        WindowSystemDefinitionService,
        ConfigurableAddonsService,
        AddonsService,
        GateSystemService
    ]
})
export class DashboardReportComponent implements OnInit {

    private static readonly VIEW_NAME = 'DashboardReportComponent';
    private static readonly SAVED_CONFIG = {
        DATE_RANGE: 'dateRange',
        SUBSYSTEMS: 'subsystems'
    };

    readonly GREEN_COLOR = '#82B442';
    readonly RED_COLOR = '#BA2937';
    readonly GREY_COLOR = '#C0C0C0';

    @Output()
    displayDialog = new EventEmitter<QuickMenuButton>();

    @ViewChild('chartTooltip', {read: ViewContainerRef})
    tooltipContainer: ViewContainerRef;

    @ViewChild('chartTooltipTemplate', {read: TemplateRef})
    tooltipTemplate: TemplateRef<TooltipContext>;

    private tooltip: EmbeddedViewRef<TooltipContext>;

    currentYear: number;
    dateResolution: ReportDateResolution = 'day';
    dateRange: DateRangeKind;
    selectableDateRanges: MenuElement[];
    chartModes = [ReportMode.income, ReportMode.revenue];
    subsystemCurrency: Currencies;

    currencyTick: { value: number; label: string };
    reportCharts = new Map<ReportMode, {
        chart: ChartConfiguration<'line', IncomeOrRevenueDataPoint[]>,
        difference: number,
        total: number
    }>();
    conversionReportData: { offers: number, orders: number };

    menuType = MenuType;
    FoldCorner = FoldCorner;
    ready = false;

    salesTarget: ProductionOrderSalesTargetStatistics;
    salesTargetBackground = '#FF0000';
    showSetSalesTargetDialog = false;
    newSalesTarget: number;

    reportSettings: DashboardReportSettings = {
        subsystemIds: []
    };
    tempReportSettings: DashboardReportSettings = {
        subsystemIds: []
    };
    settingsDialogState: DialogState = {
        exists: false,
        visible: false
    };

    constructor(private readonly reportsService: ReportsService,
                private readonly subsystemService: SubsystemService,
                private readonly clientService: ClientService,
                private readonly supplierService: SupplierService,
                private readonly windowSystemService: WindowSystemDefinitionService,
                private readonly configurableAddonService: ConfigurableAddonsService,
                private readonly addonService: AddonsService,
                private readonly gateSystemService: GateSystemService,
                private readonly translate: TranslateService,
                private readonly userUiConfigService: UserUiConfigService,
                public readonly permissions: Permissions,
                private readonly route: ActivatedRoute) {

        let dateRanges = [
            DateRangeKind.PAST_7_DAYS,
            DateRangeKind.PAST_30_DAYS,
            DateRangeKind.PAST_YEAR,
        ];
        this.selectableDateRanges = [];
        dateRanges.forEach(range => {
            this.selectableDateRanges.push(new MenuElementBuilder()
                .setType(MenuElementType.REGULAR)
                .setIdentifier(range)
                .setTranslationKey('GENERAL.DATE_RANGE_KIND.' + range)
                .translateButtonText()
                .build());
        });
    }

    getReport(mode: ReportMode): { chart: ChartConfiguration<'line', IncomeOrRevenueDataPoint[]>; difference: number; total: number } {
        return this.reportCharts.get(mode);
    }

    getColor(difference: number): string {
        return difference >= 0 ? this.GREEN_COLOR : this.RED_COLOR;
    }

    ngOnInit(): void {
        this.currentYear = new Date().getFullYear();
        this.reportSettings.subsystemIds = this.userUiConfigService.getConfigForTheView(DashboardReportComponent.VIEW_NAME,
            DashboardReportComponent.SAVED_CONFIG.SUBSYSTEMS) || [];
        this.setDateRange(this.userUiConfigService.getConfigForTheView(DashboardReportComponent.VIEW_NAME,
            DashboardReportComponent.SAVED_CONFIG.DATE_RANGE) || DateRangeKind.PAST_7_DAYS, false);
    }

    private formatDateTicks(dateString: string | number): string {
        if (typeof dateString !== 'string') {
            dateString = new Date(dateString).toISOString();
        }
        return dateString.substring(5, 10);
    }

    reportOptions(mode: ReportMode, titleColor: string): ChartOptions<'line'> {
        return {
            scales: {
                curryear: {
                    axis: 'x',
                    type: 'linear',
                    display: true,
                    grid: {
                        display: false
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 4,
                        color: '#B2B5B8',
                        font: {
                            size: 8
                        },
                        maxRotation: 0,
                        callback: (tickValue: string) => this.formatDateTicks(tickValue)
                    }
                },
                prevyear: {
                    axis: 'x',
                    type: 'linear',
                    display: false,
                    grid: {
                        display: false
                    },
                    ticks: {
                        autoSkip: true,
                        maxTicksLimit: 4,
                        color: '#B2B5B8',
                        font: {
                            size: 8
                        },
                        maxRotation: 0,
                        callback: (tickValue: string) => this.formatDateTicks(tickValue)
                    },
                },
                y: {
                    display: true,
                    type: 'linear',
                    grid: {
                        display: false
                    },
                    ticks: {
                        color: '#B2B5B8',
                        font: {
                            size: 8
                        },
                        callback: (tickValue: number) => this.currencyTick != undefined && tickValue === this.currencyTick.value
                            ? this.currencyTick.label
                            : this.compactNumberFormat(tickValue),
                    }
                }
            },
            elements: {
                line: {
                    borderWidth: 2,
                    cubicInterpolationMode: 'monotone'
                },
                point: {
                    radius: 0
                }
            },
            plugins: {
                legend: {
                    display: false
                },
                tooltip: {
                    enabled: false,
                    mode: 'index',
                    external: (args: { chart: Chart; tooltip: TooltipModel<'line'> }) => {
                        this.fillChartTooltip(args.chart, args.tooltip);
                    },
                    callbacks: {
                        title: () => this.translate.instant('REPORTS.MODE.' + mode),
                        label: (tooltipItem: TooltipItem<'line'>) => {
                            const dataPoint = tooltipItem.raw as IncomeOrRevenueDataPoint;
                            return this.formatCurrency(tooltipItem.parsed.y, dataPoint.yCurrency);
                        },
                        beforeLabel(tooltipItem: TooltipItem<'line'>) {
                            const dataPoint = tooltipItem.raw as IncomeOrRevenueDataPoint;
                            return dataPoint.xDate.toISOString().substring(0, 10);
                        }
                    },
                    intersect: false,
                    backgroundColor: '#fff',
                    titleFont: {
                        size: 16
                    },
                    titleColor: titleColor,
                    bodyFont: {
                        size: 12
                    },
                    bodyColor: '#3c454e',
                    displayColors: false
                }
            }
        };
    }

    loadReport(): void {
        let filters = {dateRange: {value: new DateRangeFilter(undefined, undefined, this.dateRange)}};
        let conversionFilters = {dateRange: {value: new DateRangeFilter(undefined, undefined, DateRangeKind.CURRENT_YEAR)}};
        if (!!this.reportSettings.subsystemIds) {
            filters['subsystemId'] = {value: this.reportSettings.subsystemIds.join()};
            conversionFilters['subsystemId'] = {value: this.reportSettings.subsystemIds.join()};
        }
        forkJoin({
            income: this.reportsService.getProductionOrderReportData(ReportMode.income, this.dateResolution, filters, []),
            revenue: this.reportsService.getProductionOrderReportData(ReportMode.revenue, this.dateResolution, filters, []),
            conversion: this.reportsService.getProductionOrderConversionData(this.dateResolution, conversionFilters, []),
            previousIncome: this.reportsService.getProductionOrderReportData(ReportMode.income, this.dateResolution, filters, [], true),
            previousRevenue: this.reportsService.getProductionOrderReportData(ReportMode.revenue, this.dateResolution, filters, [], true),
            salesTarget: this.permissions.isOperator() || this.permissions.isHandlowiec()
                ? this.reportsService.getSalesTargetData()
                : of(undefined)
        }).pipe(mergeMap(data => {
            const subsystemIds = new Set<number>();
            const clientIds = new Set<number>();
            const supplierIds = new Set<number>();
            const windowSystemIds = new Set<number>();
            const configurableAddonIds = new Set<number>();
            const addonIds = new Set<number>();
            const gateSystemIds = new Set<number>();
            for (const dataset of [...data.income, ...data.revenue, ...data.conversion]) {
                const key = JSON.parse(dataset.label);
                if (key == null) {
                    continue;
                }
                if (key.subsystemId != undefined) {
                    subsystemIds.add(key.subsystemId);
                }
                if (key.clientId != undefined) {
                    clientIds.add(key.clientId);
                }
                if (key.supplierId != undefined) {
                    supplierIds.add(key.supplierId);
                }
                if (key.primaryCatalogEntityId != undefined) {
                    switch (key.type) {
                        case PositionType.SYSTEM:
                        case PositionType.STANDALONE_GLAZING_PACKAGE:
                        case PositionType.ROOF_SYSTEM:
                        case PositionType.ENTRANCE_DOOR_SYSTEM:
                            windowSystemIds.add(key.primaryCatalogEntityId);
                            break;
                        case PositionType.CONFIGURABLE_ADDON:
                            configurableAddonIds.add(key.primaryCatalogEntityId);
                            break;
                        case PositionType.BULK_ADDON:
                            addonIds.add(key.primaryCatalogEntityId);
                            break;
                        case PositionType.GATE_SYSTEM:
                            gateSystemIds.add(key.primaryCatalogEntityId);
                            break;
                        default:
                            break;
                    }
                }
            }
            this.salesTarget = data.salesTarget;
            this.setSalesTargetBackground();
            if (this.enableSalesTargets && !this.hasCurrentYearSalesTarget && this.permissions.isOperator()) {
                this.showSetSalesTargetDialog = true;
                this.newSalesTarget = this.salesTarget.previousYearTarget;
            }
            return forkJoin({
                dataDtos: of({
                    income: data.income,
                    revenue: data.revenue,
                    conversion: data.conversion,
                    previousIncome: data.previousIncome,
                    previousRevenue: data.previousRevenue
                }),
                subsystemNames: subsystemIds.size > 0 ? this.subsystemService.getNames() : of(undefined as Listing<SubsystemSelectionItem>),
                clientNames: clientIds.size > 0 ? this.clientService.getClients(undefined, clientIds.size, {
                    id: {value: Array.from(clientIds).join(',')}
                }, undefined, undefined) : of(undefined as Listing<Client>),
                supplierNames: supplierIds.size > 0 ? this.supplierService.getSupplierNames() : of(undefined as SupplierInterface[]),
                windowSystemNames: windowSystemIds.size > 0 ? this.windowSystemService.getItemNames(undefined) :
                    of(undefined as CatalogItemName[]),
                configurableAddonNames: configurableAddonIds.size > 0 ? this.configurableAddonService.getItemNames(undefined) :
                    of(undefined as CatalogItemName[]),
                addonNames: addonIds.size > 0 ? this.addonService.getItemNames(undefined) : of(undefined as CatalogItemName[]),
                gateSystemNames: gateSystemIds.size > 0 ? this.gateSystemService.getItemNames(undefined) :
                    of(undefined as CatalogItemName[])
            });
        })).subscribe(data => {
            this.reportCharts = new Map([
                [ReportMode.income, this.prepareReport(data.dataDtos.income, data.dataDtos.previousIncome, ReportMode.income)],
                [ReportMode.revenue, this.prepareReport(data.dataDtos.revenue, data.dataDtos.previousRevenue, ReportMode.revenue)]
            ]);
            this.prepareConversionReport(data.dataDtos.conversion);
            this.ready = true;
        });
    }

    prepareReport(dtos: ProductionOrderStatistics[], historicDtos: ProductionOrderStatistics[], mode: ReportMode): {
        chart: ChartConfiguration<'line', IncomeOrRevenueDataPoint[]>,
        difference: number,
        total: number
    } {
        let mapped = this.mapPoints(dtos[0], historicDtos[0]);
        let actualDataSet: ChartDataset<'line', IncomeOrRevenueDataPoint[]> = {
            xAxisID: 'curryear',
            data: mapped.points,
            fill: false,
            borderColor: this.getColor(mapped.difference)
        };
        let labels = actualDataSet.data.map(p => p.xDate.toISOString().substring(0, 10));
        const historicDataSet: ChartDataset<'line', IncomeOrRevenueDataPoint[]> = {
            xAxisID: 'prevyear',
            data: mapped.historicPoints,
            fill: false,
            borderColor: this.GREY_COLOR
        };
        return {
            chart: {
                type: 'line',
                options: this.reportOptions(mode, this.getColor(mapped.difference)),
                data: {labels: labels, datasets: [actualDataSet, historicDataSet]}
            },
            difference: mapped.difference,
            total: mapped.total
        };
    }

    prepareConversionReport(dtos: ProductionOrderConversions[]): void {
        this.mapConversionPoints(dtos[0]);
    }

    mapPoints(source: ProductionOrderStatistics, historicSource: ProductionOrderStatistics): {
        points: IncomeOrRevenueDataPoint[],
        historicPoints: IncomeOrRevenueDataPoint[],
        difference: number,
        total: number
    } {
        this.subsystemCurrency = source.currency;
        let mappedPoints: IncomeOrRevenueDataPoint[] = new Array<IncomeOrRevenueDataPoint>(source.data.length);
        let totalSumPLN = 0;
        let totalSumCurrency = 0;
        let totalSum = 0;
        for (let i = 0; i < source.data.length; i++) {
            totalSumPLN += +source.data[i].valuePLN;
            totalSumCurrency += +source.data[i].valueCurrency;
            source.data[i].valuePLN = totalSumPLN;
            source.data[i].valueCurrency = totalSumCurrency;
            totalSum = source.currency === Currencies.PLN ? totalSumPLN : totalSumCurrency;
            mappedPoints[i] = {
                xDate: new Date(source.data[i].date),
                x: new Date(source.data[i].date).getTime(),
                y: totalSum,
                yCurrency: source.currency
            };
        }
        let historicSum = 0;
        let historicSumInCurrency = 0;
        let historicTotalSum = 0;
        let mappedHistoricPoints: IncomeOrRevenueDataPoint[] = new Array<IncomeOrRevenueDataPoint>(source.data.length);
        historicSource.data.forEach((p, i) => {
            historicSum += +p.valuePLN;
            if (!isNaN(p.valueCurrency)) {
                historicSumInCurrency += +p.valueCurrency;
            }
            historicTotalSum = source.currency === Currencies.PLN ? historicSum : historicSumInCurrency;
            mappedHistoricPoints[i] = {
                xDate: new Date(p.date),
                x: new Date(p.date).getTime(),
                y: historicTotalSum,
                yCurrency: source.currency
            };
        });
        let difference = totalSum - (source.currency === Currencies.PLN ? historicSum : historicSumInCurrency);
        return {
            points: mappedPoints,
            historicPoints: mappedHistoricPoints,
            difference: difference,
            total: totalSum
        };
    }

    mapConversionPoints(source: ProductionOrderConversions): number[] {
        let totalOffersSum = 0;
        let totalProductionOrdersSum = 0;
        source.data.forEach(p => {
            totalOffersSum += p.totalOffers;
            totalProductionOrdersSum += p.totalProductionOrders;
        });
        this.conversionReportData = {
            offers: totalOffersSum, orders: totalProductionOrdersSum
        };
        return [totalProductionOrdersSum, totalOffersSum];
    }

    compactNumberFormat(value: number): string {
        if (Math.abs(value) < 1000) {
            return '' + Math.round(value);
        }
        return this.translate.instant('REPORTS.THOUSANDS_SHORTHAND', {
            amount: formatNumber(value / 1000, this.translate.currentLang, '1.0-0g')
        });
    }

    formatCurrency(value: number, currency = Currencies.PLN): string {
        return ExchangeService.formatPriceInCurrency(this.compactNumberFormat(value), currency);
    }

    handleDateRangeChange(elem: ButtonWithMenuElementSelectedEvent) {
        this.setDateRange(DateRangeKind[elem.identifier]);
    }

    setDateRange(dateRange: DateRangeKind, saveUiConfig = true): void {
        if (this.dateRange === dateRange) {
            return;
        }
        this.dateRange = dateRange;
        this.loadReport();
        if (saveUiConfig) {
            this.userUiConfigService.saveConfigForTheView(DashboardReportComponent.VIEW_NAME,
                DashboardReportComponent.SAVED_CONFIG.DATE_RANGE, dateRange);
        }
    }

    showExplanationDialog(reportType: string): void {
        this.displayDialog.emit({
            id: reportType,
            label: `DASHBOARD.REPORTS.EXPLANATION.${reportType}.HEADER`,
            info: `DASHBOARD.REPORTS.EXPLANATION.${reportType}.INFO`,
            display: () => true,
            action: () => {
            }
        });
    }

    showSettingsDialog(): void {
        this.tempReportSettings = {
            subsystemIds: [...this.reportSettings.subsystemIds]
        };
        this.settingsDialogState = {
            exists: true,
            visible: true
        };
    }

    handleSettingsDialogClosed(): void {
        this.settingsDialogState.exists = false;
    }

    handleSettingsDialogSaved(): void {
        this.reportSettings = {
            subsystemIds: [...this.tempReportSettings.subsystemIds]
        };
        this.loadReport();
        this.userUiConfigService.saveConfigForTheView(DashboardReportComponent.VIEW_NAME,
            DashboardReportComponent.SAVED_CONFIG.SUBSYSTEMS, this.reportSettings.subsystemIds);
    }

    fillChartTooltip(chartInstance: Chart, tooltipModel: TooltipModel<'line'>) {
        if (tooltipModel.opacity <= 0) {
            this.tooltipContainer.clear();
            this.tooltip = undefined;
            return;
        }
        if (!this.tooltip) {
            this.tooltip = this.tooltipContainer.createEmbeddedView(this.tooltipTemplate, {});
        }

        this.tooltip.context.chartTitle = tooltipModel.title[0];
        if (tooltipModel.body.length > 0) {
            this.tooltip.context.currentYearLabel = tooltipModel.body[0].before[0];
            this.tooltip.context.currentYearValue = tooltipModel.body[0].lines[0];
        }
        if (tooltipModel.body.length > 1) {
            this.tooltip.context.previousYearLabel = tooltipModel.body[1].before[0];
            this.tooltip.context.previousYearValue = tooltipModel.body[1].lines[0];
        }

        const position = chartInstance.canvas.getBoundingClientRect();
        this.tooltip.context.left = position.left + window.scrollX + tooltipModel.caretX;
        this.tooltip.context.top = position.top + window.scrollY + tooltipModel.caretY;
        this.tooltip.context.titleFontColor = typeof chartInstance.config.options.plugins.tooltip.titleColor === 'string' ?
            chartInstance.config.options.plugins.tooltip.titleColor : 'black';

        this.tooltip.markForCheck();
    }

    get enableSalesTargets(): boolean {
        return (this.route.snapshot.data['features'] as ApplicationFeatures).enableSalesTargets;
    }

    private setSalesTargetBackground(): void {
        if (!this.hasCurrentYearSalesTarget) {
            return;
        }
        const salesTargetProgress = this.scaleSalesTargetProgress(this.salesTargetProgress);
        this.salesTargetBackground = '#' + new Rainbow()
            .setSpectrum('#FF0000', '#F0F000', '#72CC58')
            .setNumberRange(0, 100)
            .colorAt(salesTargetProgress * 100);
    }

    get salesTargetProgress(): number {
        return Math.max(0, Math.min(this.salesTarget.currentYear.valuePLN / this.salesTarget.currentYearTarget, 1));
    }

    private scaleSalesTargetProgress(progress: number): number {
        if (progress < 0.35) {
            return progress / 35 * 50;
        }
        return 0.5 + (progress - 0.35) / 65 * 50;
    }

    get hasPreviousYearSalesTarget(): boolean {
        return this.salesTarget != undefined && this.salesTarget.previousYearTarget != undefined;
    }

    get hasCurrentYearSalesTarget(): boolean {
        return this.salesTarget != undefined && this.salesTarget.currentYearTarget != undefined;
    }

    handleSalesTargetDialogSaved(): void {
        this.subsystemService.getSubsystemForCurrentUser().pipe(
            mergeMap(subsystem => {
                return this.subsystemService.saveSalesTargets([subsystem.id], this.newSalesTarget);
            })
        ).subscribe({
            complete: () => {
                this.salesTarget.currentYearTarget = this.newSalesTarget;
                this.reportsService.clearSalesTargetCache();
                this.setSalesTargetBackground();
            }
        });
    }
}
