import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ColorList} from '../color/color-list';
import {SingleSystemCheckboxCrudService} from '../single-system-checkbox-crud/single-system-checkbox-crud.service';
import {DecorativeFilling} from './decorativeFilling';
import {DecorativeFillingList} from './decorativeFilling-list';

@Injectable()
export class DecorativeFillingsService extends SingleSystemCheckboxCrudService<DecorativeFilling> {

    constructor(http: HttpClient, private dataServiceHelper: DataServiceHelper) {
        super(http);
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string,
             sortOrder: number): Observable<DecorativeFillingList> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<DecorativeFillingList>('decorative-fillings', {params: params});
    }

    getItem(itemId: number): Observable<DecorativeFilling> {
        return this.http.get<DecorativeFilling>(`decorative-filling/${itemId}`);
    }

    getImage(itemId: number): Observable<string> {
        return this.http.get(`decorative-filling/${itemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`decorative-filling/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    getColors(): Observable<ColorList> {
        return this.http.get<ColorList>('decorative-filling/colors');
    }

    saveItem(item: DecorativeFilling, file): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('decorativeFillingDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }

        return this.http.post<void>('decorative-filling', formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('decorative-filling', response)));
    }

    addItem(item: any) {
        console.error('decorative-filling.sevice.ts: method addItem not implemented');
        return undefined;
    }

    editItem(itemId: number, item: any) {
        console.error('decorative-filling.sevice.ts: method editItem not implemented');
        return undefined;
    }

    copy(id: number, item: DecorativeFilling, file): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('decorativeFillingDto', new Blob([body], {type: 'application/json'}));

        if (file) {
            formData.append('file', file);
        }

        return this.http.post<void>(`decorative-filling/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId('decorative-filling', response)));
    }

    validate(item: DecorativeFilling): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('decorative-filling/validate', item));
    }

    getDecorativeFillings(ids: number[]): Observable<DecorativeFilling[]> {
        return this.http.get<DecorativeFilling[]>('decorative-filling/bulk', {params: {decorativeFillingIds: ids.map(id => '' + id)}});
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('decorative-fillings/names', {params: params});
    }
}
