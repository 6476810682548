import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {forkJoin} from "rxjs";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";
import {AvailableProducts} from "../../../common/enums/AvailableProductType";
import {GrowlMessageController} from "../../../common/growl-message/growl-message-controller";
import {ValidationErrors} from "../../../common/validation-errors";
import {MultilanguageField, SupportedLanguages} from "../../../supportedLanguages";
import {MarkProductsService} from "./mark-products.service";
import {MarkedProduct} from "./marked-product";

@Component({
    selector: 'app-mark-products',
    templateUrl: './mark-products.component.html',
    styleUrls: ['../../settings/settings.component.css', './mark-products.component.css'],
    providers: [MarkProductsService]
})
export class MarkProductsComponent implements OnInit {

    products = Object.keys(AvailableProducts);
    readonly supportedLanguages = SupportedLanguages.languages;

    sampleLabel = `<span style="color: red">Nowość!</span>`;

    markedProduct: MarkedProduct;
    productsToMark: Map<string, MultilanguageField> = new Map<string, MultilanguageField>();
    markedProducts: MarkedProduct[];
    activeTab = 0;
    validationErrorsByProduct: Map<string, ValidationErrors> = new Map<string, ValidationErrors>();

    constructor(
        private markProductsService: MarkProductsService,
        private changeDetector: ChangeDetectorRef,
        private growls: GrowlMessageController,
        private errors: CommonErrorHandler,
        private translate: TranslateService
    ) { }

    ngOnInit() {
        for (let product of this.products) {
            this.productsToMark.set(product, new MultilanguageField());
            this.validationErrorsByProduct.set(product, {});
        }
        this.markProductsService.getItems().subscribe((markedProducts: MarkedProduct[]) => {
            this.markedProducts = markedProducts;
            markedProducts.forEach(marked => {
                this.productsToMark.set(marked.productType, MultilanguageField.fromJSON(marked.markLabels));
            });
            this.changeDetector.markForCheck();
        });
    }

    save() {
        this.markedProduct = new MarkedProduct(this.getActiveProduct(), this.productsToMark.get(this.getActiveProduct()));
        forkJoin({
            save: this.markProductsService.save(this.markedProduct),
            growlMessage: this.translate.get('MARK_PRODUCTS.' + this.markedProduct.productType)
        }).subscribe({
            next: data => {
                let updatedIndex = this.markedProducts.findIndex(product => product.productType === this.markedProduct.productType);
                this.markedProducts.splice(updatedIndex, 1);
                this.markedProducts.push(this.markedProduct);
                this.growls.info('MARK_PRODUCTS.SAVED',
                    {productType: data.growlMessage});
            },
            error: err => {
                this.validationErrorsByProduct.set(this.getActiveProduct(), Object.assign({},
                    this.validationErrorsByProduct.get(this.getActiveProduct()), this.errors.handle(err)));
                this.changeDetector.markForCheck();
            }
        });
    }

    cancel() {
        const markedProduct = this.markedProducts.find(product => product.productType === this.getActiveProduct());
        if (markedProduct) {
            this.productsToMark.set(markedProduct.productType, MultilanguageField.fromJSON(markedProduct.markLabels));
        } else {
            this.productsToMark.set(this.getActiveProduct(), new MultilanguageField());
        }
    }

    getActiveProduct(): AvailableProducts {
        return this.products[this.activeTab] as AvailableProducts;
    }

    formatValidationMessage(value: string, langCode): string {
        return value != undefined ? value.replace(`[${langCode}]`, '') : undefined;
    }

    handleValidationMessageChange(value: string, key: string): void {
        this.validationErrorsByProduct.get(this.getActiveProduct())[key] = value;
        this.changeDetector.markForCheck();
    }

}
