<div class="list-content settings">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.ROT_SETTINGS.GENERAL' | translate }}</div>
    </div>
    <div class="settings-form-wrapper">
        <div class="new-form-600">
                <div class="new-form-row">
                    <h3>{{ 'SETTINGS.SECTION.ROT_SETTINGS.GENERAL' | translate }}</h3>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="rotPercentValue" [(ngModel)]="rotSettings.rotPercentValue"
                                      label="{{ 'ROT_SETTINGS.FORM.ROT_PERCENT_VALUE' | translate }}"
                                      [required]="true" [min]="0" [max]="100"
                                      [(validationMessageKey)]="validationErrors['rotPercentValue']"></app-input-number>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="defaultGrossLimit" [(ngModel)]="rotSettings.defaultGrossLimit"
                                      label="{{ 'ROT_SETTINGS.FORM.DEFAULT_GROSS_LIMIT' | translate }}"
                                      [required]="true" [min]="0"
                                      [(validationMessageKey)]="validationErrors['defaultGrossLimit']"></app-input-number>
                </div>

                <div class="new-form-row">
                    <app-input-number inputId="absoluteGrossLimit" [(ngModel)]="rotSettings.absoluteGrossLimit"
                                      label="{{ 'ROT_SETTINGS.FORM.ABSOLUTE_GROSS_LIMIT' | translate }}"
                                      [required]="true" [min]="0"
                                      [(validationMessageKey)]="validationErrors['absoluteGrossLimit']"></app-input-number>
                </div>

                <div class="new-form-row">
                    <app-select inputId="rotCalculationCurrency" [(ngModel)]="rotSettings.rotCalculationCurrency"
                                label="{{ 'ROT_SETTINGS.FORM.ROT_CALCULATION_CURRENCY' | translate }}"
                                [options]="currencyOptions | async" [required]="true"
                                [(validationMessageKey)]="validationErrors['rotCalculationCurrency']">
                    </app-select>
                </div>
            </div>
    </div>

    <div class="settings-footer">
        <div class="settings-footer-right">
            <app-simple-button buttonId="saveBtn" label="{{ 'GENERAL.SAVE' | translate }}" icon="check"
                               type="main-action" [size]="40" (onClick)="saveChanges()"></app-simple-button>
        </div>
    </div>
</div>

