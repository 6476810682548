import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SupplierInterface} from '../../../window-designer/catalog-data/supplier-interface';
import {Listing} from '../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {Supplier, SupplierMotlawaIntegrationInfo} from './supplier';

@Injectable()
export class SupplierService {

    private static readonly API_URL = 'supplier';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getSuppliers(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                 sortColumn: string, sortOrder: number): Observable<Listing<Supplier>> {
        const params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>('suppliers', {params: params}).pipe(
            map(response => Listing.fromJSON(Supplier, response)));
    }

    getSupplier(supplierId: number): Observable<Supplier> {
        return this.http.get<object>(`${SupplierService.API_URL}/${supplierId}`).pipe(
            map(response => Supplier.fromJSON(response)));
    }

    saveSupplier(supplier: Supplier, motlawaIntegrationInfo: SupplierMotlawaIntegrationInfo | undefined): Observable<number> {
        const formData = new FormData();
        formData.append('supplierDto',
            new Blob([JSON.stringify(supplier)], {type: 'application/json'}));
        if (motlawaIntegrationInfo != undefined) {
            formData.append('supplierMotlawaIntegrationInfoDto',
                new Blob([JSON.stringify(motlawaIntegrationInfo)], {type: 'application/json'}));
        }
        if (supplier.id) {
            return this.http.put(`${SupplierService.API_URL}/${supplier.id}`, formData).pipe(
                map(() => supplier.id));
        } else {
            return this.http.post(SupplierService.API_URL, formData, {observe: 'response'}).pipe(
                map(response => this.dataServiceHelper.getNewItemId(SupplierService.API_URL, response)));
        }
    }

    hasAnyProduct(id: number): Observable<boolean> {
        return this.http.get<boolean>(`${SupplierService.API_URL}/${id}/hasProducts`);
    }

    getAllOwnSuppliers(): Observable<Listing<Supplier>> {
        return this.getSuppliers(0, 0, {active: {matchMode: undefined, value: true}}, undefined, 1);
    }

    moveProductsBetweenSuppliers(fromSupplier: number, toSupplier: number): Observable<boolean> {
        return this.http.post<boolean>(`${SupplierService.API_URL}/${fromSupplier}/moveTo/${toSupplier}`, undefined);
    }

    validateGeneralDetails(supplier: Supplier): Observable<void> {
        return this.http.post<void>(`${SupplierService.API_URL}/validateGeneralDetails`, supplier);
    }

    validateAddress(supplier: Supplier): Observable<void> {
        return this.http.post<void>(`${SupplierService.API_URL}/validateAddress`, supplier);
    }

    getSupplierMotlawaIntegrationInfo(supplierId: number): Observable<SupplierMotlawaIntegrationInfo> {
        return this.http.get<object>(`${SupplierService.API_URL}/${supplierId}/motlawaIntegrationInfo`).pipe(
            map(response => SupplierMotlawaIntegrationInfo.fromJSON(response)));
    }

    getSupplierNames(): Observable<SupplierInterface[]> {
        return this.http.get<SupplierInterface[]>(`${SupplierService.API_URL}/names`);
    }

    getSuppliersForSimpleProfitMargins(): Observable<SupplierInterface[]> {
        return this.http.get<SupplierInterface[]>(`${SupplierService.API_URL}/forSimpleProfitMargins`);
    }
}
