import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, of} from 'rxjs';
import {map, mergeMap} from "rxjs/operators";
import * as _ from 'underscore';
import {DrawingData} from "../../../../../../../window-designer/drawing-data/drawing-data";
import {FillingType} from '../../../../../../../window-designer/drawing-data/FillingType';
import {StaticDataHelper} from '../../../../../../../window-designer/static-data-helper';
import {SubwindowTypes} from "../../../../../../../window-designer/subwindow-types";
import {AddonDefaultQuantityCalculator} from "../../../../../../../window-designer/utils/addons-default-quantity-calculator/AddonDefaultQuantityCalculator";
import {GlassUpsellingValidationUtil} from '../../../../../../../window-designer/utils/glass-upselling-validation-util';
import {WindowSystemDefaultsUtil} from "../../../../../../../window-designer/utils/WindowSystemDefaultsUtil";
import {BlockUiController} from '../../../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {AddonsService} from "../../../../../window-system/addons/addons.service";
import {DistanceFrameService} from '../../../../../window-system/distance-frame/distance-frame.service';
import {DistanceFrame} from '../../../../../window-system/distance-frame/distanceFrame';
import {GlassService} from '../../../../../window-system/glass/glass.service';
import {GlassWithPosition} from '../../../../../window-system/glass/glassWithPositions';
import {GlazingBead} from '../../../../../window-system/glazing-bead/glazing-bead';
import {GlazingPackage} from '../../../../../window-system/glazing-package/glazing-package';
import {GlazingPackageService} from '../../../../../window-system/glazing-package/glazing-package.service';
import {SubwindowTypeService} from "../../../../../window-system/subwindow-type/subwindow-type.service";
import {WindowSystemDefinition} from "../../../../../window-system/window-system-definition/window-system-definition";
import {WindowSystemDefinitionService} from "../../../../../window-system/window-system-definition/window-system-definition.service";
import {PositionType} from '../../../../AbstractPosition';
import {ConfigurableAddonUtils} from "../../../../window-editor/drawing-tool/ConfigurableAddonUtils";
import {SystemDefaultsService} from "../../../../window-editor/system-defaults.service";
import {Position} from "../position";
import {BulkWindowSystemChangeDialogData} from "../position-list-dialogs";

@Component({
    selector: 'app-bulk-window-system-change',
    templateUrl: './bulk-window-system-change.component.html',
    providers: [GlassService, DistanceFrameService, GlazingPackageService, WindowSystemDefinitionService, SystemDefaultsService,
        SubwindowTypeService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkWindowSystemChangeComponent implements OnInit {

    private static readonly SUBMIT_BULK_WINDOWS_SYSTEM_CHANGE_DIALOG_ID = 'submitBulkWindowsSystemChangeDialog';

    @Input()
    dialogData: BulkWindowSystemChangeDialogData;
    @Output()
    onSubmit = new EventEmitter();
    @Output()
    onClose = new EventEmitter();

    windowSystems: WindowSystemDefinition[];
    windowSystemsOptionFormatter: (windowSystem: WindowSystemDefinition) => SelectItem;

    selectedWindowSystem: WindowSystemDefinition;
    validationErrors: { [field: string]: string } = {};
    loadingData = true;

    private readonly dialogHideHelper = new OnceFlag();

    constructor(protected windowSystemService: WindowSystemDefinitionService,
                protected windowSystemDefaultsService: SystemDefaultsService,
                protected subwindowTypeService: SubwindowTypeService,
                protected addonsService: AddonsService,
                protected glassService: GlassService,
                protected distanceFrameService: DistanceFrameService,
                protected glazingPackageService: GlazingPackageService,
                protected translate: TranslateService,
                protected changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private blockUiController: BlockUiController) {
    }

    ngOnInit() {
        const windowSystemId = this.dialogData.selectedOfferPositions[0].windowSystemId;
        this.windowSystems = [];
        this.windowSystemService.getActiveSystemsMatchingType(windowSystemId).subscribe({
            next: data => {
                this.windowSystems = data.data;
                if (this.windowSystems.length > 0) {
                    this.selectedWindowSystem = this.windowSystems[0];
                }
                this.loadingData = false;
                this.changeDetector.markForCheck();
            },
            error: (error) => {
                this.errors.handle(error);
                this.onClose.emit();
            }
        });
        this.windowSystemsOptionFormatter = (windowSystem: WindowSystemDefinition) => {
            return {
                label: windowSystem.names[this.translate.currentLang],
                value: windowSystem
            };
        };
    }

    submitDialog(): void {
        this.blockUiController.block(BulkWindowSystemChangeComponent.SUBMIT_BULK_WINDOWS_SYSTEM_CHANGE_DIALOG_ID);

        let updatedDatas: { offerPosition: Position, mappedData: DrawingData }[];
        let copyOfferPosition = (offerPosition: Position) => {
            let position = new Position(offerPosition.name, PositionType.SYSTEM, null, null, offerPosition.quantity,
                null, null, null, null, null, null, null, null,
                null, null, null, offerPosition.offerId, null);
            position.id = offerPosition.id;
            position.printOrder = offerPosition.printOrder;
            return {offerPosition: position, mappedData: JSON.parse(offerPosition.data)};
        };
        updatedDatas = this.dialogData.selectedOfferPositions.filter(p => p.type === PositionType.SYSTEM).map(copyOfferPosition);

        if (!this.validateForm()) {
            this.blockUiController.unblock(BulkWindowSystemChangeComponent.SUBMIT_BULK_WINDOWS_SYSTEM_CHANGE_DIALOG_ID);
            return;
        }

        this.windowSystemDefaultsService.getDefaultsForWindow(this.selectedWindowSystem.id, this.dialogData.offerId, false)
            .pipe(mergeMap(defaults => {
                if (defaults.value != undefined && defaults.value.terraceGlazingPackageId != undefined) {
                    return forkJoin({
                        defaults: of(defaults),
                        glazingPackage: this.glazingPackageService.getItem(defaults.value.terraceGlazingPackageId),
                        glasses: this.glassService.getGlassesForWindowSystem(this.selectedWindowSystem.id),
                        frames: this.distanceFrameService.getDistanceFramesForWindowSystem(this.selectedWindowSystem.id),
                        glazingBeads: this.windowSystemService.getSystemsGlazingBeads(this.selectedWindowSystem.id)
                            .pipe(map(data => data.data))
                    });
                }
                return of({
                    defaults: defaults,
                    glazingPackage: undefined as GlazingPackage,
                    glasses: undefined as GlassWithPosition[],
                    frames: undefined as DistanceFrame[],
                    glazingBeads: undefined as GlazingBead[]
                });
            }), mergeMap(data => {
                if (data.defaults.value != undefined) {
                    for (let updateData of updatedDatas) {
                        WindowSystemDefaultsUtil.applyDefaults(data.defaults.value, updateData.mappedData, this.selectedWindowSystem);

                        if (data.glazingPackage != undefined) {
                            const staticData = new StaticDataHelper();
                            staticData.glasses = data.glasses;
                            staticData.frames = data.frames;
                            for (const w of updateData.mappedData.windows) {
                                for (const sw of w.subWindows) {
                                    for (const a of sw.areasSpecification) {
                                        a.filling.type = FillingType.GLASS;
                                    }
                                }
                            }
                            GlassUpsellingValidationUtil.applyGlazingBasedOnArea(data.glazingPackage.glazingPackageForAreaRangeList,
                                staticData, updateData.mappedData,
                                data.glazingBeads.map(gb => ({
                                    id: gb.id,
                                    active: gb.active,
                                    acceptableFillingWidth: GlazingBead.parseAcceptableFillingWidth(gb)
                                })),
                                undefined, false);
                        }
                    }
                    let addonIds = _.unique(
                        _.flatten(updatedDatas.map(ud => ud.mappedData.addons.map(addon => addon.addonId))));
                    if (addonIds.length > 0) {
                        return forkJoin({
                            subwindowTypes: this.subwindowTypeService.getAll(),
                            addons: this.addonsService.getItemsByIds(addonIds, false),
                            glasses: data.glasses != undefined
                                ? of(data.glasses)
                                : this.glassService.getGlassesForWindowSystem(this.selectedWindowSystem.id),
                            decorativeFillings: this.windowSystemService.getSystemsDecorativeFillings(this.selectedWindowSystem.id)
                        });
                    }
                    return of(undefined);
                }
                return of(undefined);
            }))
            .subscribe({
                next: data => {
                    if (data) {
                        let subwindowTypes = new SubwindowTypes(data.subwindowTypes);
                        let staticData = new StaticDataHelper();
                        staticData.glasses = data.glasses;
                        staticData.decorativeFillings = data.decorativeFillings.data;
                        updatedDatas.forEach(
                            ud => AddonDefaultQuantityCalculator.recalculateAllOnGlobalChange(ud.mappedData,
                                subwindowTypes, data.addons, staticData));
                    }
                },
                error: (error) => {
                    this.blockUiController.unblock(BulkWindowSystemChangeComponent.SUBMIT_BULK_WINDOWS_SYSTEM_CHANGE_DIALOG_ID);
                    this.errors.handle(error);
                    this.onClose.emit();
                },
                complete: () => {
                    for (let updateData of updatedDatas) {
                        updateData.mappedData.windowSystemId = this.selectedWindowSystem.id;
                        this.deleteGrills(updateData.mappedData);
                        ConfigurableAddonUtils.removeAllAddonIds(updateData.mappedData);
                        updateData.offerPosition.data = JSON.stringify(updateData.mappedData);
                    }
                    this.dialogData.modifiedPositions = updatedDatas.map(updateData => updateData.offerPosition);
                    this.dialogHideHelper.call(() => this.onSubmit.emit());
                    this.blockUiController.unblock(BulkWindowSystemChangeComponent.SUBMIT_BULK_WINDOWS_SYSTEM_CHANGE_DIALOG_ID);
                }
            });
    }

    resetDialog() {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    private validateForm() {
        this.validationErrors = {};
        if (this.selectedWindowSystem == undefined) {
            this.validationErrors['windowSystem'] = 'error.offerPosition.bulkChange.windowSystem.not_empty';
        }

        return Object.keys(this.validationErrors).length === 0;
    }

    private deleteGrills(drawingData: DrawingData): void {
        for (let window of drawingData.windows) {
            for (let subWindow of window.subWindows) {
                for (let area of subWindow.areasSpecification) {
                    area.grills.length = 0;
                    area.fields.length = 0;
                }
            }
        }
    }
}
