import {CrudItem} from "../../app/common/crud-common/crudItem";
import {WindowDimensionsType} from "../../app/features/window-system/window-dimensions/window-dimensions-type";

export class WindowDimensions implements CrudItem {
    id: number;
    symbol: string;
    width: number;
    height: number;
    surface: number;
    sortIndex: number;
    active: boolean;
    type: WindowDimensionsType;

    constructor() {
        this.id = undefined;
        this.symbol = undefined;
        this.width = undefined;
        this.height = undefined;
        this.surface = undefined;
        this.sortIndex = 1;
        this.active = true;
    }
}

export class WindowDimensionsUtils {
    public static readonly OVERRIDE_HARDCODED_ID = -1;

    public static isOverriden(dimensionsId: number): boolean {
        return dimensionsId === WindowDimensionsUtils.OVERRIDE_HARDCODED_ID;
    }
}
