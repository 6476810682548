import {HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {from, Observable, of, OperatorFunction} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';

export interface FileState {
    file: File;

    // needs saving on backend
    needSave: boolean;

    // has no db id, cannot be deleted
    isNew?: boolean;
}

@Injectable()
export class DataServiceHelper {

    prepareSearchParams(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                        sortColumn: string, sortOrder: number,
                        validatedFilters?: { [filterProperty: string]: (value: any) => boolean }): { [param: string]: string | string[] } {
        let params: { [param: string]: string | string[] } = {};
        if (offset != null) {
            params['offset'] = "" + offset;
        }
        if (pageSize != null) {
            params['limit'] = "" + pageSize;
        }
        for (let property in filters) {
            if (!Object.prototype.hasOwnProperty.call(filters, property)) {
                continue;
            }
            if (this.shouldSetParam(property, filters, validatedFilters)) {
                if (Array.isArray(filters[property].value)) {
                    filters[property].value.forEach(value => {
                        const vals = params[property] as string[] || [];
                        vals.push(value);
                        params[property] = vals;
                    });
                } else if (typeof filters[property].value === 'object') {
                    params[property] = JSON.stringify(filters[property].value);
                } else {
                    params[property] = filters[property].value;
                }
            } else {
                console.debug('skipping property filters[' + property + ']=', filters[property]);
            }
        }
        if (sortColumn) {
            params['sortBy'] = sortColumn;
        }
        if (sortOrder) {
            params['ascending'] = sortOrder === 1 ? "true" : "false";
        }
        return params;
    }

    private shouldSetParam(property: string, filters: { [filterProperty: string]: FilterMetadata },
                           validatedFilters?: { [filterProperty: string]: (value: any) => boolean }): boolean {
        if (filters[property] == undefined || filters[property].value == undefined || filters[property].value === "") {
            return false;
        }
        if (validatedFilters == undefined || validatedFilters[property] == undefined) {
            return true;
        }
        return validatedFilters[property](filters[property].value);
    }

    prepareHeaders(params: any): { [header: string]: string | string[]; } {
        let headers = {};
        Object.keys(params).forEach(key => {
            headers[key] = "" + params[key];
        });
        return headers;
    }

    getNewItemId(baseApiUrl: string, response: HttpResponse<any>): number {
        const location = response.headers.get('Location');
        return location != undefined ? +location.match(new RegExp(baseApiUrl + '/([^/]+)'))[1] : undefined;
    }

    mapToNewItemId(baseApiUrl: string): OperatorFunction<HttpResponse<void>, number> {
        return map((response: HttpResponse<void>) => this.getNewItemId(baseApiUrl, response));
    }

    mapToExistingItemId(itemId: number): OperatorFunction<any, number> {
        return map(() => itemId);
    }

    mapToItemId(baseApiUrl: string, itemId: number): OperatorFunction<HttpResponse<void>, number> {
        return itemId != undefined ? this.mapToExistingItemId(itemId) : this.mapToNewItemId(baseApiUrl);
    }

    getFileAcceptHeader(): string {
        return 'application/octet-stream, application/json;q=0.1';
    }

    mapToFile(): OperatorFunction<HttpResponse<string>, File> {
        return mergeMap(response => {
            if (!response.body) {
                return of<File>(undefined);
            }
            let fileName = this.decodeUnicode(response.headers.get('Content-Disposition'));
            return this.getArrayBufferFromDataUri(response.body).pipe(
                map(body => new File([body], fileName, {type: response.headers.get('Content-Type')}))
            );
        });
    }

    decodeUnicode(contentDisposition: string): string {
        let str = contentDisposition.split("filename*=UTF-8''")[1];
        return decodeURIComponent(str);
    }

    getArrayBufferFromDataUri(dataUri: string): Observable<ArrayBuffer> {
        return from(fetch(dataUri)).pipe(
            mergeMap(dataUriResponse => from(dataUriResponse.arrayBuffer()))
        );
    }

    isFileSaveNeeded(file: FileState | undefined): boolean {
        return file != undefined && file.needSave;
    }
}
