import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Table} from 'primeng/table';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {ColorInterface} from '../../../../window-designer/catalog-data/color-interface';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../common/crud-common/common-input-length-limits';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {FileState} from '../../../common/dataServiceHelper';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ImagesValidator} from '../../../form-inputs/inputs/file-upload/images-validator';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, GatePanelTypeField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {GatePanelTypeFieldUsage} from "../catalog-field-usage";
import {ColorService} from '../color/color.service';
import {GateSystemService} from '../gate-system/gate-system.service';
import {LinkableEntities} from '../link-selection/link-selection.component';
import {ItemForCatalogLinking} from '../single-system-checkbox-crud/item-for-catalog-linking';
import {GatePanelType} from './gate-panel-type';
import {GatePanelTypeService} from './gate-panel-type.service';

@Component({
    selector: 'app-gate-panel-type',
    templateUrl: './gate-panel-type.component.html',
    styleUrls: ['../../shared-styles.css'],
    providers: [GatePanelTypeService, TranslatedSelectItemService, GateSystemService, ColorService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GatePanelTypeComponent extends CrudCommonComponent<GatePanelType, GatePanelTypeService> implements OnInit, AfterViewInit {

    readonly LinkableEntity = LinkableEntities;

    readonly STEPS = {
        DATA: {
            id: 'DATA',
            validate: () => this.validateDataStep()
        },
        SYSTEMS: {
            id: 'SYSTEMS'
        }
    };

    @ViewChild('table')
    table: Table;

    private readonly gateSystemService: GateSystemService;
    private readonly colorService: ColorService;

    readonly filterActiveItems: Observable<SelectItem[]>;
    filterActiveState = true;

    lowResolutionPreviewImage: FileState = {file: undefined, needSave: false};
    highResolutionPreviewImage: FileState = {file: undefined, needSave: false};
    additionalIcon: FileState = {file: undefined, needSave: false};

    gateColors: ColorInterface[];
    gateSystems: ItemForCatalogLinking[] = [];
    selectedGateSystems: number[] = [];

    editPermits: FieldLimitation[] = [];
    fieldUsage: GatePanelTypeFieldUsage;
    CatalogField = CatalogTab;
    GatePanelTypeField = GatePanelTypeField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, GatePanelTypeService, 'GATE_PANEL_TYPE', 'GatePanelType');
        this.gateSystemService = injector.get(GateSystemService);
        this.colorService = injector.get(ColorService);
        this.filterActiveItems = this.translatedSelectItemService.buildUnsortedDropdown([true, false], active => {
            return active ? 'GENERAL.ONLY_ACTIVE_F' : 'GENERAL.ONLY_INACTIVE_F';
        }, '');
        this.fieldUsage = new GatePanelTypeFieldUsage(this);
    }

    ngOnInit(): void {
        super.ngOnInit();
        forkJoin({
            gateColors: this.colorService.getItems(undefined, undefined, {material: {value: 'GATE_COATING'}}, 'sortIndex', 1),
            gateSystems: this.gateSystemService.getGatesForCatalogLinking(),
            editPermits: this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GATE_PANEL_TYPES)
        }).subscribe(data => {
            this.gateColors = data.gateColors.data;
            this.gateSystems = data.gateSystems;
            this.editPermits = data.editPermits.fieldsLimitations;
            this.changeDetector.markForCheck();
        });
    }

    ngAfterViewInit(): void {
        super.ngAfterViewInit();
        if (this.table != undefined) {
            this.table.filter(true, 'active', 'equals');
        }
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    getNewItem(): GatePanelType {
        return new GatePanelType();
    }

    loadEditedItem(event: { data: GatePanelType }): Observable<GatePanelType> {
        return forkJoin({
            item: this.itemService.getItem(event.data.id),
            lowResolutionPreviewImage: this.itemService.getLowResolutionPreviewImageAsFile(event.data.id),
            highResolutionPreviewImage: this.itemService.getHighResolutionPreviewImageAsFile(event.data.id),
            additionalIcon: this.itemService.getAdditionalIconAsFile(event.data.id),
            linkedGateSystems: this.itemService.getLinkedSystems(event.data.id)
        }).pipe(tap(data => {
            if (this.copyMode) {
                data.item.id = undefined;
            }
            this.lowResolutionPreviewImage = {file: data.lowResolutionPreviewImage, needSave: false};
            this.highResolutionPreviewImage = {file: data.highResolutionPreviewImage, needSave: false};
            this.additionalIcon = {file: data.additionalIcon, needSave: false};
            this.selectedGateSystems = data.linkedGateSystems;
        }), map(data => data.item));
    }

    showDialogToCopy() {
        this.loadEditedItem({data: this.selectedItem}).subscribe({
            next: item => {
                this.item = item;
                this.setDisplayDialog(true);
            },
            error: error => {
                this.setErrors(error);
            }
        });
    }

    submit() {
        this.validateFormImpl().subscribe(validationOk => {
            if (!validationOk) {
                return;
            }
            let requestItem = this.prepareItemForRequest();

            if (this.isSaveInProgress()) {
                return;
            } else {
                this.setSaveInProgress(true);
            }

            const saveObservable: Observable<number> = (() => {
                if (this.copyMode) {
                    return this.itemService.copyItem(this.selectedItem.id, requestItem, this.lowResolutionPreviewImage,
                        this.highResolutionPreviewImage, this.additionalIcon);
                }
                if (this.itemId == undefined) {
                    return this.itemService.addItem(requestItem, this.lowResolutionPreviewImage, this.highResolutionPreviewImage,
                        this.additionalIcon);
                }
                return this.itemService.editItem(this.itemId, requestItem, this.lowResolutionPreviewImage, this.highResolutionPreviewImage,
                    this.additionalIcon);
            })();

            saveObservable
                .pipe(mergeMap(itemId => this.itemService.editLinks(itemId, this.selectedGateSystems).pipe(map(() => itemId))))
                .subscribe(this.genericCleanupAndReloadSuccessObserver());
        });
    }

    handleFilterActiveChange(active: boolean): void {
        if (this.filterActiveState === active) {
            return;
        }
        this.filterActiveState = active;
        this.table.filter(active, 'active', 'equals');
    }

    private validateDataStep(): Observable<boolean> {
        const validationErrors = {};

        validationErrors[`name[${this.translate.currentLang}]`] = MultiValidator.of(`error.gatePanelTypeDto.name[${this.translate.currentLang}]`)
            .withNotNullValidator()
            .withNotBlankValidator()
            .withSizeValidator(0, COMMON_INPUT_LENGTH_LIMITS.NAME_MAX_LENGTH)
            .validate(this.item.name[this.translate.currentLang]);
        validationErrors[`shortcut[${this.translate.currentLang}]`] = MultiValidator.of(`error.gatePanelTypeDto.shortcut[${this.translate.currentLang}]`)
            .withSizeValidator(0, COMMON_INPUT_LENGTH_LIMITS.SHORTCUT_MAX_LENGTH)
            .validate(this.item.shortcut[this.translate.currentLang]);
        validationErrors[`symbol`] = MultiValidator.of(`error.gatePanelTypeDto.symbol`)
            .withNotNullValidator()
            .withNotBlankValidator()
            .withSizeValidator(0, 50)
            .validate(this.item.symbol);
        validationErrors['height'] = MultiValidator.of('error.gatePanelTypeDto.height')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 1000)
            .validate(this.item.height);
        validationErrors[`active`] = MultiValidator.of(`error.gatePanelTypeDto.active`)
            .withNotNullValidator()
            .validate(this.item.active);
        validationErrors['sortIndex'] = MultiValidator.of('error.gatePanelTypeDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);
        validationErrors[`stampingType`] = MultiValidator.of(`error.gatePanelTypeDto.stampingType`)
            .withNotNullValidator()
            .validate(this.item.stampingType);

        return forkJoin({
            lowResolutionPreviewImage: this.lowResolutionPreviewImage.needSave
                ? ImagesValidator.validationErrors(this.lowResolutionPreviewImage.file, 150000, 400, 400)
                : of<string>(undefined),
            highResolutionPreviewImage: this.highResolutionPreviewImage.needSave
                ? ImagesValidator.validationErrors(this.highResolutionPreviewImage.file, 5242880, 1500, 1000)
                : of<string>(undefined),
            additionalIcon: this.additionalIcon.needSave
                ? ImagesValidator.validationErrors(this.additionalIcon.file, 10000, 100, 100)
                : of<string>(undefined)
        }).pipe(
            mergeMap(imageErrors => {
                Object.assign(validationErrors, imageErrors);
                if (this.validationErrorsPresent(validationErrors)) {
                    return of(validationErrors);
                }
                return this.itemService.validate(this.item);
            }),
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors))
        );
    }

    handleLowResolutionPreviewImageChange(newFile: File) {
        this.lowResolutionPreviewImage.file = newFile;
        this.lowResolutionPreviewImage.needSave = true;
        if (!newFile) {
            this.lowResolutionPreviewImage.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleHighResolutionPreviewImageChange(newFile: File) {
        this.highResolutionPreviewImage.file = newFile;
        this.highResolutionPreviewImage.needSave = true;
        if (!newFile) {
            this.highResolutionPreviewImage.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleAdditionalIconChange(newFile: File) {
        this.additionalIcon.file = newFile;
        this.additionalIcon.needSave = true;
        if (!newFile) {
            this.additionalIcon.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    protected resetFile() {
        this.lowResolutionPreviewImage = {file: undefined, needSave: false};
        this.highResolutionPreviewImage = {file: undefined, needSave: false};
        this.additionalIcon = {file: undefined, needSave: false};
        this.selectedGateSystems = [];
    }
}
