import {WindowSystemMaterialLinks} from "../single-system-checkbox-crud/window-system-material-links";
import {WindowDimensions} from "../../../../window-designer/entities/window-dimensions";

export class WindowDimensionsWithMaterials extends WindowDimensions implements WindowSystemMaterialLinks {
    pcv: string;
    aluminium: string;
    wood: string;
    aluminiumWoodMix: string;

    constructor() {
        super();
    }
}
