import {HttpErrorResponse} from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild
} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from "primeng/datatable";
import {Permissions} from "../../../../../auth/permission.service";
import {StorageService} from "../../../../../auth/storage.service";
import {ComponentWithUserConfigAndPaginator} from "../../../../../common/crud-common/paginable.component";
import {ExchangeService} from "../../../../../common/exchange.service";
import {ValidationErrorsHelper} from "../../../../../common/ValidationErrorsHelper";
import {Currencies} from "../../../../../currencies";
import {TristateCheckboxState} from "../../../../../form-inputs/inputs/tristate-checkbox/tristate-checkbox.component";
import {FULLSCREEN_CONTROLLER, WizardFullscreenController} from '../../../../../form-inputs/wizard/wizard-fullscreen-controller';
import {ErrorResponse} from "../../../../errors/errorResponse";
import {AddressService} from '../../../../subsystem/address.service';
import {TabularAddress} from '../../../../subsystem/tabularAddress';
import {ShippingContainer} from "../../ShippingContainer";
import {ShippingSimulationModel} from "../shipping-simulation.model";
import {ShippingSimulationService} from "../shipping-simulation.service";
import {ShippingSimulationDetailsTableModel} from "./shipping-simulation-details-table.model";

declare const jQuery: any;

@Component({
    selector: 'app-shipping-simulation-details',
    templateUrl: './shipping-simulation-details.component.html',
    styleUrls: ['../../../../shared-styles.css', './shipping-simulation-details.component.css',
        '../../../../../common/offer-status-colors.css'],
    providers: [AddressService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ShippingSimulationDetailsComponent extends ComponentWithUserConfigAndPaginator implements OnInit, OnDestroy {

    static readonly USE_BIG_TRUCKS_OPTION = 'useBigTrucks';
    private readonly DRAWER_BORDERS_WIDTH = 10;
    private readonly DRAWER_MARGINS_WIDTH = 64;

    maxWidth: number;
    currentRackIndex = 0;
    currentFloorIndex = 0;
    viewInitialized = false;
    previousRoute: string;
    previousRouteParams: any;
    showSaveSimulationDialog = false;
    showDeleteSimulationDialog = false;
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    lastLazyLoadEvent: LazyLoadEvent;
    defaultCurrency: string = Currencies.EUR.toString();

    positions: ShippingSimulationDetailsTableModel[] = [];
    selectedItems: ShippingSimulationDetailsTableModel[] = [];
    selectedItem: ShippingSimulationDetailsTableModel;
    allSelectedState = TristateCheckboxState.UNCHECKED;

    validationErrors: any = {};
    newSimulationName: string;

    incomingSimulationId: number;
    shippingSimulation: ShippingSimulationModel;
    shippingCalculationInProgress: boolean;

    deliveryAddressSelectionDialogVisible: boolean;
    addresses: TabularAddress[];
    customDeliveryAddress: TabularAddress;
    useBigTrucks = false;

    @ViewChild('maxWidthDeterminant')
    maxWidthDeterminant: ElementRef;

    @ViewChild('dt')
    datatable: DataTable;

    constructor(@Inject(FULLSCREEN_CONTROLLER) private fullscreenControllers: WizardFullscreenController[],
                public shippingSimulationService: ShippingSimulationService,
                changeDetector: ChangeDetectorRef,
                public translateService: TranslateService,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private storageService: StorageService,
                injector: Injector,
                public permissions: Permissions,
                private addressService: AddressService) {
        super(injector, changeDetector, 'ShippingSimulationDetailsComponent', false);
    }

    ngOnInit(): void {
        this.fullscreenControllers.forEach(controller => controller.setFullscreen());
        this.activatedRoute.paramMap.subscribe(param => {
            this.previousRoute = param.get("previousRoute");
            this.previousRouteParams = param.keys
                .filter(key => key !== 'previousRoute' && key !== 'simulationId')
                .reduce((agg, key) => {
                    agg[key] = param.get(key);
                    return agg;
                }, {});
            this.incomingSimulationId = param.get("simulationId") == null ? null : +param.get("simulationId");
            this.initSimulation();
        });
        if (this.shippingSimulationService.checkCalculationFinishedInterval) {
            this.shippingCalculationInProgress = true;
            this.shippingSimulationService.checkCalculationFinishedInterval.subscribe({
                complete: () => {
                    this.shippingSimulationService.getSimulationById(this.shippingSimulation.id).subscribe(response => {
                        this.shippingSimulation = response;
                        this.shippingCalculationInProgress = false;
                        this.changeDetector.markForCheck();
                    });
                },
                error: () => {
                    this.shippingCalculationInProgress = false;
                    this.changeDetector.markForCheck();
                }
            });
        }
        this.useBigTrucks = this.getUserUiConfigForThisView(ShippingSimulationDetailsComponent.USE_BIG_TRUCKS_OPTION) || false;
    }

    ngOnDestroy(): void {
        this.fullscreenControllers.forEach(controller => controller.unsetFullscreen());
        super.ngOnDestroy();
    }

    private initSimulation(): void {
        if (this.incomingSimulationId == null) {
            this.shippingSimulationService.getDraftForCurrentUser().subscribe(response => {
                this.setSimulation(response);
                this.initViewData();
            });
        } else {
            this.shippingSimulationService.getSimulationById(this.incomingSimulationId).subscribe(response => {
                this.setSimulation(response);
                this.initViewData();
            });
        }
    }

    private initViewData(): void {
        this.initDrawerWidth();

        setTimeout(() => {
            this.viewInitialized = true;
            this.hideDataLoadingIndicator();
            this.changeDetector.markForCheck();
        }, 1);
    }

    private initPositions(): void {
        let loadedPositions = [];

        this.shippingSimulationService.getOffers().forEach(offer => {
            offer.positions.forEach(position => {
                let detailsRowModel = new ShippingSimulationDetailsTableModel();
                detailsRowModel.offer = offer;
                detailsRowModel.position = position;
                detailsRowModel.simulationPositionId = position.shippingPositionId;

                loadedPositions.push(detailsRowModel);
            });
        });

        this.positions = loadedPositions;
    }

    loadItemsLazy(event: LazyLoadEvent, onSuccess?) {
        super.loadItemsLazy(event);
        this.lastLazyLoadEvent = event;

        this.shippingSimulationService.getSimulationPositions(this.shippingSimulation.id,
            event.first, event.rows, event.sortField, event.sortOrder).subscribe(response => {

            this.totalRecords = response.totalRecords;
            this.fromRecord = Math.min(event.first + 1, this.totalRecords);
            this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
            this.initPositions();
            this.hideDataLoadingIndicator();
            this.changeDetector.markForCheck();
        });
    }

    private initDrawerWidth(): void {
        if (this.showDetails()) {
            this.maxWidth = jQuery(this.maxWidthDeterminant.nativeElement).width() - this.DRAWER_BORDERS_WIDTH - this.DRAWER_MARGINS_WIDTH;
        }
    }

    navigateBack(): void {
        if (this.previousRoute != undefined) {
            this.router.navigate([this.previousRoute, this.previousRouteParams]);
        }
    }

    save(): void {
        if (!this.shippingCalculationInProgress) {
            this.showSaveSimulationDialog = true;
            this.changeDetector.markForCheck();
        }
    }

    cancelSaveSimulationDialog(): void {
        if (!this.showSaveSimulationDialog) {
            return;
        }
        this.showSaveSimulationDialog = false;
        this.newSimulationName = null;
        this.changeDetector.markForCheck();
    }

    confirmSaveSimulation(): void {
        if (!this.newSimulationName) {
            this.validationErrors['name'] = 'error.shippingSimulationDto.name.not_empty';
        }

        if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
            this.shippingSimulationService.saveDraft(this.newSimulationName).subscribe({
                next: response => {
                    this.setSimulation(response);
                    this.growlMessageController.info('SHIPPING_SIMULATION.MESSAGE.SUCCESS.SIMULATION_SAVED');
                    this.showSaveSimulationDialog = false;
                    this.incomingSimulationId = this.shippingSimulation.id;
                    this.changeDetector.markForCheck();
                },
                error: (error: HttpErrorResponse) => {
                    this.handleSaveError(error);
                    this.changeDetector.markForCheck();
                }
            });
        }
    }

    private handleSaveError(error: HttpErrorResponse): void {
        let errorResponse = new ErrorResponse(error.error);
        if (errorResponse.is400()) {
            this.validationErrors = {};
            for (let property in errorResponse.invalidFields) {
                this.validationErrors[property] = errorResponse.invalidFields[property];
            }
        } else {
            this.showSaveSimulationDialog = false;
            this.growlMessageController.error('SHIPPING_SIMULATION.MESSAGE.ERROR.SIMULATION_SAVE');
        }
    }

    showDeliveryAddressSelectionDialog(): void {
        if (this.addresses == undefined) {
            this.addressService.getUsableAddresses(this.shippingSimulationService.shippingSimulation.subsystemId).subscribe(addresses => {
                this.addresses = addresses;
                if (this.shippingSimulationService.shippingSimulation.deliveryAddressId != undefined) {
                    this.customDeliveryAddress = this.addresses
                        .find(address => address.id === this.shippingSimulationService.shippingSimulation.deliveryAddressId);
                }
                this.deliveryAddressSelectionDialogVisible = true;
                this.changeDetector.markForCheck();
            });
            return;
        }

        this.deliveryAddressSelectionDialogVisible = true;
        this.changeDetector.markForCheck();
    }

    handleDeliveryAddressChanged(address: TabularAddress): void {
        this.shippingSimulationService.setDeliveryAddress(address).subscribe();
    }

    deleteSimulation(): void {
        this.showDeleteSimulationDialog = true;
        this.changeDetector.markForCheck();
    }

    cancelDeleteSimulation(): void {
        this.showDeleteSimulationDialog = false;
        this.changeDetector.markForCheck();
    }

    confirmDeleteSimulation(): void {
        this.shippingSimulationService.deleteSimulation(this.shippingSimulation.id).subscribe(response => {
            this.navigateBack();
        });
    }

    previousRack(): void {
        if (this.currentRackIndex > 0) {
            this.currentFloorIndex = 0;
            this.currentRackIndex--;
        }
    }

    nextRack(): void {
        if (this.currentRackIndex < this.shippingSimulation.calculationResult.containers.length - 1) {
            this.currentFloorIndex = 0;
            this.currentRackIndex++;
        }
    }

    previousFloor(): void {
        this.currentFloorIndex = Math.max(0, this.currentFloorIndex - 1);
    }

    nextFloor(): void {
        this.currentFloorIndex =
            Math.min(this.shippingSimulation.calculationResult.containers[this.currentRackIndex].floorPositions.length -
                1, this.currentFloorIndex + 1);
    }

    getScale(container: ShippingContainer): number {
        let height: number = this.maxWidthDeterminant.nativeElement.offsetHeight - this.DRAWER_BORDERS_WIDTH - this.DRAWER_MARGINS_WIDTH;
        let scaleX = this.maxWidth / container.size.x;
        let scaleZ = height / container.size.z;

        return Math.min(scaleX, scaleZ);
    }

    selectAllChange(): void {
        this.selectedItems = [];

        if (this.allSelectedState === TristateCheckboxState.CHECKED) {
            this.selectedItems.push(...this.positions);
        }

        this.changeDetector.markForCheck();
    }

    isSelectedItem(item: ShippingSimulationDetailsTableModel): boolean {
        return this.selectedItems.indexOf(item) > -1;
    }

    selectItem(item: ShippingSimulationDetailsTableModel): void {
        let index = this.selectedItems.indexOf(item);
        if (index > -1) {
            this.selectedItems.splice(index, 1);
        } else {
            this.selectedItems.push(item);
        }

        this.refreshAllSelectedFlag();
    }

    private refreshAllSelectedFlag(): void {
        if (this.selectedItems.length === 0) {
            this.allSelectedState = TristateCheckboxState.UNCHECKED;
        } else if (this.selectedItems.length === this.positions.length) {
            this.allSelectedState = TristateCheckboxState.CHECKED;
        } else {
            this.allSelectedState = TristateCheckboxState.CHECKED_PARTIALLY;
        }
    }

    recalculateTransport(experimental?: boolean): void {
        if (this.positions.length === 0 || this.shippingCalculationInProgress) {
            return;
        }

        this.shippingCalculationInProgress = true;
        this.currentRackIndex = 0;
        this.currentFloorIndex = 0;

        this.shippingSimulationService.calculateTransport(this.useBigTrucks, experimental).subscribe({
            next: () => {
                this.shippingSimulation.calculationInProgress = true;
                this.changeDetector.markForCheck();

                this.shippingSimulationService.createCheckCalculationFinishedInterval(this.shippingSimulation.id, this.componentDestroyed)
                    .subscribe({
                        complete: () => {
                            this.shippingSimulationService.getSimulationById(this.shippingSimulation.id).subscribe(response => {
                                this.setSimulation(response);
                                this.shippingCalculationInProgress = false;
                                this.changeDetector.markForCheck();
                            });
                        },
                        error: () => this.shippingCalculationInProgress = false
                    });
            },
            error: (error: HttpErrorResponse) => {
                this.shippingCalculationInProgress = false;
                this.pushCalculationErrorMessage(new ErrorResponse(error.error).message);
                this.changeDetector.markForCheck();
            }
        });
    }

    deletePosition(position: ShippingSimulationDetailsTableModel): void {
        this.deleteSimulationPositions([position.simulationPositionId]);
    }

    deleteSelectedPositions(): void {
        this.deleteSimulationPositions(this.selectedItems.map(position => position.simulationPositionId));
    }

    private deleteSimulationPositions(simulationPositionIds: number[]): void {
        this.shippingSimulationService.removeSimulationPositionsFromDraft(simulationPositionIds).subscribe(response => {
            this.selectedItems = [];
            this.selectedItem = null;
            this.initSimulation();
            this.loadItemsLazy(this.lastLazyLoadEvent);
        });
    }

    private pushCalculationErrorMessage(label: string): void {
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})
            || label !== 'error.shipping.noShippingPricetableForAddressException') {
            this.growlMessageController.error(label);
        } else {
            this.growlMessageController.error('error.shipping.generalSimulationFailure');
        }
    }

    showDialogToAdd(): void {
        // not supported
    }

    submit(): void {
        // not supported
    }

    onRowSelect(event: any): void {
        // not supported
    }

    getDatatable(): DataTable {
        // not supported
        return this.datatable;
    }

    showDetails() {
        return this.permissions.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']});
    }

    formatSimulationPrice(): string {
        return this.shippingSimulation.price != null
            ? ExchangeService.formatPriceInCurrency('' + this.shippingSimulation.price, this.defaultCurrency)
            : '...';
    }

    private setSimulation(response: ShippingSimulationModel): void {
        this.shippingSimulation = this.addFloorPositions(response);
    }

    private addFloorPositions(response: ShippingSimulationModel): ShippingSimulationModel {
        if (response.calculationResult != null) {
            response.calculationResult.containers.forEach(container =>
                container.floorPositions = ShippingSimulationDetailsComponent.getFloorPositions(container)
            );
        }
        return response;
    }

    private static getFloorPositions(container: ShippingContainer): number[] {
        return container.items.map(item => item.position.y)
            .filter((value, index, self) => self.indexOf(value) === index)
            .sort((a, b) => {
                return a - b;
            });
    }

    getTopItemHeight(): number {
        let items = this.shippingSimulation.calculationResult.containers[this.currentRackIndex].items;
        return Math.max.apply(Math, items.map(item => item.position.y + item.size.y));
    }

    handleUseBigTrucksChange(useBigTrucks: boolean): void {
        this.useBigTrucks = useBigTrucks;
        this.saveUserUiConfigForThisView(ShippingSimulationDetailsComponent.USE_BIG_TRUCKS_OPTION, useBigTrucks);
    }

    showCalculationErrorOutsideOfGrowls(errorCode: string): boolean {
        return errorCode === 'error.shipping.noTruckLargeEnoughException';
    }
}
