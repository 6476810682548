import {ChangeDetectionStrategy, Component, Input} from "@angular/core";
import {Permissions} from "../../../auth/permission.service";
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../common/crud-common/common-input-length-limits';
import {Supplier} from "../supplier";

@Component({
    selector: 'app-supplier-details-form',
    templateUrl: './supplier-details-form.component.html',
    styleUrls: ['../create-supplier/create-supplier.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SupplierDetailsFormComponent {

    @Input()
    validationErrors: any;

    @Input()
    supplier: Supplier;

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    constructor(public readonly permissions: Permissions) {
    }

    handleWindowSupplierChange(windowSupplier: boolean): void {
        this.supplier.windowSupplier = windowSupplier;
        if (!windowSupplier) {
            this.supplier.simulateShipping = false;
        }
    }
}
