import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, QueryList, ViewChildren} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin} from 'rxjs';
import {finalize, map, mergeMap, tap} from 'rxjs/operators';
import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepChangeEvent} from '../../../form-inputs/wizard/wizard-base';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {SimpleProfitMarginSource, validateSimpleProfitMargins} from '../../client/simple-profit-margin-source/simple-profit-margin-source';
import {ConfigurableAddonsService} from "../../offer/offers/position/config-addons.service";
import {HasSaveTable} from '../../subsystem/base-profit-margin.component';
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from '../../subsystem/profit-margin/profitMargin';
import {SupplierService} from '../../supplier/supplier.service';
import {ProductTypeGroup} from '../../window-system/window-system-definition/product-type-group';
import {ClientGroupService} from '../client-group.service';
import {ClientGroup} from '../ClientGroup';
import {ProfitMarginTarget} from "../../subsystem/profit-margin/profit-margin-target";

@Component({
    selector: 'app-create-client-group',
    templateUrl: './create-client-group.component.html',
    providers: [ClientGroupService, SupplierService, ConfigurableAddonsService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateClientGroupComponent implements OnInit {

    readonly WindowSystemTypeGroup = ProductTypeGroup;

    readonly profitGlobalMarginButtonId = 'profitGlobalMarginButtonId';
    readonly profitGlobalMarginInputId = 'profitGlobalMarginInputId';
    readonly roofProfitGlobalMarginButtonId = 'roofProfitGlobalMarginButtonId';
    readonly roofProfitGlobalMarginInputId = 'roofProfitGlobalMarginInputId';
    readonly entranceProfitGlobalMarginButtonId = 'entranceProfitGlobalMarginButtonId';
    readonly entranceProfitGlobalMarginInputId = 'entranceProfitGlobalMarginInputId';
    readonly clientGroupConfAddGlobalMarginButtonId = 'clientGroupConfAddGlobalMarginButtonId';
    readonly clientGroupConfAddGlobalMarginInputId = 'clientGroupConfAddGlobalMarginInputId';
    readonly gatesGlobalMarginButtonId = 'gatesGlobalMarginButtonId';
    readonly gatesGlobalMarginInputId = 'gatesGlobalMarginInputId';

    readonly STEPS = {
        FILL_DETAILS: 'fill-details',
        FILL_PROFIT_MARGINS_SYSTEMS: 'fill-profit-margin-systems',
        FILL_PROFIT_MARGINS_ROOF_SYSTEMS: 'fill-profit-margin-roof-systems',
        FILL_PROFIT_MARGINS_ENTRANCE_SYSTEMS: 'fill-profit-margin-entrance-systems',
        FILL_PROFIT_MARGINS_CONFIG_ADDONS: 'fill-profit-margin-config-addons',
        FILL_PROFIT_MARGINS_GATES: 'fill-profit-margin-gates'
    };

    possibleTargets = ProfitMarginTarget;

    @ViewChildren('childTable')
    childTables: QueryList<HasSaveTable>;

    clientGroup: ClientGroup = new ClientGroup();
    validationErrors = {};
    suppliers: SupplierInterface[];
    simpleProfitMarginSource?: SimpleProfitMarginSource = new SimpleProfitMarginSource();

    validateClientGroupDetailsStep: WizardStepValidator;

    saveInProgress: boolean;
    readonly route: ActivatedRoute;

    constructor(public translate: TranslateService,
                public clientGroupService: ClientGroupService,
                public router: Router,
                public permissions: Permissions,
                public changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private supplierService: SupplierService,
                private configurableAddonService: ConfigurableAddonsService,
                injector: Injector) {
        this.validateClientGroupDetailsStep = () => {
            const validationErrors = {};
            if (!this.clientGroup.name) {
                validationErrors['name'] = 'error.clientGroupDto.name.not_empty';
            }
            if (!!this.clientGroup.bulkAddonProfitMargin
                && !isValidProfitMarginValueString(normalizeProfitMarginValueString(this.clientGroup.bulkAddonProfitMargin))) {
                validationErrors['bulkAddonProfitMargin'] = 'PROFIT_MARGIN.ERROR.INVALID_VALUE';
            }
            validationErrors['defaultOfferDurationDays'] = MultiValidator.of('error.clientGroupDto.defaultOfferDurationDays')
                .withRangeValidator(1, 31).validate(this.clientGroup.defaultOfferDurationDays);
            if (this.simpleProfitMarginSource != undefined) {
                Object.assign(validationErrors, validateSimpleProfitMargins(this.simpleProfitMarginSource, this.suppliers));
            }
            if (ValidationErrorsHelper.validationErrorsPresent(validationErrors)) {
                this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
                return false;
            }
            return this.clientGroupService.validateDetails(this.clientGroup).pipe(
                tap(backendValidationErrors => {
                    this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                    this.changeDetector.markForCheck();
                }),
                map(backendValidationErrors => !ValidationErrorsHelper.validationErrorsPresent(backendValidationErrors)));
        };
        this.route = injector.get(ActivatedRoute);
    }

    ngOnInit(): void {
        this.supplierService.getSuppliersForSimpleProfitMargins().subscribe(suppliers => {
            this.suppliers = suppliers;
        });
    }

    onStepChange(event: WizardStepChangeEvent): void {
        this.changeDetector.markForCheck();
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    exitWizard(): void {
        this.router.navigate(['/features/client-groups']);
    }

    submit(): void {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        if (!!this.clientGroup.bulkAddonProfitMargin) {
            this.clientGroup.bulkAddonProfitMargin = normalizeProfitMarginValueString(this.clientGroup.bulkAddonProfitMargin);
        }
        this.clientGroupService.add(this.clientGroup).pipe(
            finalize(() => this.saveInProgress = false),
            mergeMap(clientGroupId => forkJoin([...this.childTables.map(table => table.saveTable(clientGroupId)),
                this.clientGroupService.saveSimpleProfitMarginSource(clientGroupId, this.simpleProfitMarginSource)
            ]))).subscribe({
            next: () => {
                this.exitWizard();
            },
            error: errorMessage => {
                this.validationErrors = this.errors.handle(errorMessage);
                this.changeDetector.markForCheck();
            }
        });
    }

    handleEnableSimpleProfitMarginSourceChange(enable: boolean): void {
        if (enable) {
            this.simpleProfitMarginSource = new SimpleProfitMarginSource();
        } else {
            this.simpleProfitMarginSource = undefined;
            Object.keys(this.validationErrors)
                .filter(key => key.startsWith('simpleProfitMarginSource'))
                .forEach(key => this.validationErrors[key] = undefined);
            this.changeDetector.markForCheck();
        }
    }
}
