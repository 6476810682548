import {GrillType} from "./GrillType";
import {GenericGrillGrid} from "./GenericGrillGrid";

export class GrillGrid extends GenericGrillGrid {
    type: GrillType.GRID_STANDARD;
    rows: number;
    columns: number;

    constructor(columns?: number, rows?: number) {
        super(GrillType.GRID_STANDARD);
        this.columns = columns || 2;
        this.rows = rows || 2;
    }
}
