import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {Listing, mapListingToJson} from "../../../common/crud-common/crudItemList";
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {AffectedElement, ErrorCategory, ErrorReport} from './errorReport';

@Injectable()
export class ErrorReportService {

    private static readonly API_URL = 'errorReporting';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    public getMessages(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
                       sortColumn: string, sortOrder: number): Observable<Listing<ErrorReport>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(ErrorReportService.API_URL, {params: params})
            .pipe(mapListingToJson(ErrorReport));
    }

    public deactivateMessage(messageId: number): Observable<void> {
        return this.http.put<void>(`${ErrorReportService.API_URL}/${messageId}/deactivate`, undefined);
    }

    public deactivateAllMessages(): Observable<void> {
        return this.http.put<void>(`${ErrorReportService.API_URL}/deactivateAll`, undefined);
    }

    public reportError(errorCategory: ErrorCategory, affectedElements?: AffectedElement[]): Observable<void> {
        let errorMessage = new ErrorReport();
        errorMessage.eventOccurred = new Date();
        errorMessage.affectedElements = affectedElements;
        errorMessage.errorCategory = errorCategory;
        return this.http.post<void>(`${ErrorReportService.API_URL}/configurationError`, errorMessage);
    }

    public getMessagesSimple(offset: number, pageSize: number, searchedValue: string,
                             sortColumn: string, sortOrder: number): Observable<Listing<ErrorReport>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, {
            searchedValue: {value: searchedValue}
        }, sortColumn, sortOrder);
        return this.http.get<Listing<object>>(`${ErrorReportService.API_URL}/globalSearch`, {params: params})
            .pipe(mapListingToJson(ErrorReport));
    }
}
