import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {mapItemArrayToJson} from '../../../../common/crud-common/crudItem';
import {WindowDesignerCatalogDependentOption} from './window-designer-catalog-dependent-option';

@Injectable()
export class WindowDesignerCatalogDependentOptionService {

    private static readonly API_URL = 'windowDesignerCatalogDependentOption';

    constructor(private readonly http: HttpClient) {
    }

    getItemsFromWindowSystem(windowSystemId: number): Observable<WindowDesignerCatalogDependentOption[]> {
        return this.http.get<object[]>(`${WindowDesignerCatalogDependentOptionService.API_URL}/allFromWindowSystem/${windowSystemId}`)
            .pipe(mapItemArrayToJson(WindowDesignerCatalogDependentOption));
    }

    getItemsFromSet(optionsSetId: number): Observable<WindowDesignerCatalogDependentOption[]> {
        return this.http.get<WindowDesignerCatalogDependentOption[]>(`${WindowDesignerCatalogDependentOptionService.API_URL}/allFromSet/${optionsSetId}`);
    }
}
