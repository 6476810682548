import {
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {Editor} from 'primeng/editor';
import {Permissions} from '../../../../auth/permission.service';
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {WizardDialogComponent} from '../../../../form-inputs/wizard/wizard-dialog.component';
import {Offer} from "../../offer";
import {OffersService} from "../../offer-service";
import {ProductionOrderService} from "../production-order-service";
import {ProductionOrder} from "../ProductionOrder";

@Component({
    selector: 'app-change-annotations',
    templateUrl: './change-annotations.component.html',
    styleUrls: ['./change-annotations.component.css', '../../../shared-styles.css'],
    providers: [ProductionOrderService, OffersService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeAnnotationsComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {

    readonly STEPS = {
        HEADER: 'headerComment',
        FOOTER: 'footerComment',
    };

    @Input() productionOrder: ProductionOrder;
    @Input() offer: Offer;
    footerComment: string;
    headerComment: string;
    @Output() onSave = new EventEmitter<void>();
    @Output() onCancel = new EventEmitter<void>();

    private enterHotkey: Hotkey;
    private saveInProgress = false;

    @ViewChild(WizardDialogComponent, {static: true})
    wizard: WizardDialogComponent;

    @ViewChild("footerCommentEditor") footerCommentEditor: Editor;

    private footerCommentRewritten = false;

    constructor(public productionOrderService: ProductionOrderService,
                public offerService: OffersService,
                public changeDetector: ChangeDetectorRef,
                public hotkeyService: HotkeysService,
                public permissions: Permissions,
                private errors: CommonErrorHandler) {
        this.enterHotkey = new Hotkey('enter', (): boolean => {
            this.submit();
            return false;
        }, ['INPUT']);
    }

    ngOnInit(): void {
        this.hotkeyService.add(this.enterHotkey);
    }

    ngAfterViewInit(): void {
        if (this.productionOrder) {
            this.footerComment = this.productionOrder.footerComment;
            this.headerComment = this.productionOrder.headerComment;
        }
    }

    ngAfterViewChecked(): void {
        // write value to show correct text formatting after selecting footer comment wizard step for the first time
        if (this.wizard.getCurrentStepId() === this.STEPS.FOOTER && !this.footerCommentRewritten) {
            this.footerCommentEditor.writeValue(this.footerComment);
            this.footerCommentRewritten = true;
        }
    }

    ngOnDestroy(): void {
        this.hotkeyService.remove(this.enterHotkey);
    }

    reloadDefaultAnnotationEnd() {
        if (this.productionOrder) {
            this.productionOrderService.getDefaultFooterComment(this.productionOrder.id).subscribe({
                next: data => {
                    this.footerComment = data;
                    this.changeDetector.markForCheck();
                },
                error: error => this.errors.handle(error)
            });
        }
    }

    reloadDefaultAnnotationEntry() {
        if (this.productionOrder) {
            this.productionOrderService.getDefaultHeaderComment(this.productionOrder.id).subscribe({
                next: data => {
                    this.headerComment = data;
                    this.changeDetector.markForCheck();
                },
                error: error => this.errors.handle(error)
            });
        }
    }

    submit() {
        if (this.saveInProgress) {
            return;
        }
        this.saveInProgress = true;
        let observable;
        if (this.productionOrder) {
            this.productionOrder.headerComment = this.headerComment;
            this.productionOrder.footerComment = this.footerComment;
            observable = this.productionOrderService.updateProductionOrderAnnotations(this.productionOrder);
        }
        observable.subscribe(() => {
                this.onSave.emit();
                this.saveInProgress = false;
            },
            error => {
                this.errors.handle(error);
                this.saveInProgress = false;
            },
            () => {
                console.info('ChangeAnnotationsComponent submit done');
            });
    }

    getHeader(): string {
        let base = 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_ANNOTATIONS.';
        let isVenskaRole = this.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']});
        let isNotOrder = this.productionOrder == null;
        return (isVenskaRole && isNotOrder) ? base + 'TITLE_VENSKA' : base + 'TITLE';
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    reloadDefaultValue() {
        switch (this.wizard.getCurrentStepId()) {
            case this.STEPS.HEADER:
                this.reloadDefaultAnnotationEntry();
                break;
            case this.STEPS.FOOTER:
                this.reloadDefaultAnnotationEnd();
                break;
        }
    }
}
