import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {TermsOfUse} from '../../features/settings/terms-of-use/termsOfUse';
import {OnceFlag} from '../../shared/once-flag';

@Component({
    selector: 'app-terms-of-use-dialog',
    templateUrl: './terms-of-use-dialog.component.html',
    styleUrls: ['./terms-of-use-dialog.component.css']
})
export class TermsOfUseDialogComponent implements OnChanges {

    @Input()
    termsOfUse: TermsOfUse;
    @Input()
    dialogId: string;

    sanitizedContent: SafeHtml;
    consent1 = false;
    consent2 = false;
    consent3 = false;
    consent4 = false;
    validationErrors: { [field: string]: string } = {};

    @Output()
    onConfirmed = new EventEmitter<void>();
    @Output()
    onCancel = new EventEmitter<void>();

    private readonly dialogHideHelper = new OnceFlag();

    constructor(public domSanitizer: DomSanitizer,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('termsOfUse' in changes) {
            const termsOfUse = changes['termsOfUse'];
            if (termsOfUse.currentValue) {
                this.sanitizedContent = this.domSanitizer.bypassSecurityTrustHtml(termsOfUse.currentValue.content);
            } else {
                this.sanitizedContent = undefined;
            }
        }
    }

    cancel() {
        this.dialogHideHelper.call(() => this.onCancel.emit());
    }

    confirm() {
        if (this.validateForm()) {
            this.dialogHideHelper.call(() => this.onConfirmed.emit());
        }
    }

    private validateForm(): boolean {
        let errors = {};
        if (this.termsOfUse.consentCheckbox1 && !this.consent1) {
            errors['consent1'] = 'error.termsOfUse.consent1.not_selected';
        }
        if (this.termsOfUse.consentCheckbox2 && !this.consent2) {
            errors['consent2'] = 'error.termsOfUse.consent2.not_selected';
        }
        if (this.termsOfUse.consentCheckbox3 && !this.consent3) {
            errors['consent3'] = 'error.termsOfUse.consent3.not_selected';
        }
        if (this.termsOfUse.consentCheckbox4 && !this.consent4) {
            errors['consent4'] = 'error.termsOfUse.consent4.not_selected';
        }

        if (this.validationErrorsPresent(errors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, errors);
            this.changeDetector.markForCheck();
            return false;
        }
        return true;
    }

    private validationErrorsPresent(validationErrors?: { [field: string]: string }): boolean {
        if (!validationErrors) {
            validationErrors = this.validationErrors;
        }
        return Object.keys(validationErrors).filter(key => validationErrors[key] != undefined).length > 0;
    }
}
