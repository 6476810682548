import {Injectable} from '@angular/core';
import {JwtHelperService} from "./jwt-helper.service";

export enum StorageKey {
    MAIN_JWT = 'mainJwt',
    MAIN_USER_AUTH_DATA = 'mainUserAuthData',
    INTERFACE_LANGUAGE = 'interfaceLanguage',

    JWT = 'jwt',
    CURRENT_USER_AUTH_DATA = 'currentUserAuthData',
    CURRENT_USER_INTERFACE_LANGUAGE = 'currentUserInterfaceLanguage',

    UI_CONFIG = 'userUiConfig',
    DISPLAY_SIDE_MENU_LABELS = 'displaySideMenuLabels',
    PROD_ORDER = 'prodOrder',
    LAST_VISITED_OFFER_ID = 'lastVisitedOfferId',
    LAST_VISITED_PROD_ORDER_ID = 'lastVisitedProdOrderId',
    LAST_SELECTED_PROD_ORDER_CURRENCY = 'lastSelectedProdOrderCurrency',
    LAST_SELECTED_OFFER_CURRENCY = 'lastSelectedOfferCorrency',
    LAST_WINDOW_EDITOR_ADDON_CATEGORY = 'lastWindowEditorAddonCategory'
}

@Injectable()
export class StorageService {

    set(key: string | StorageKey, value: string): void {
        localStorage.setItem(this.formatKey(key), value);
    }

    get(key: string | StorageKey): string {
        return localStorage.getItem(this.formatKey(key));
    }

    remove(key: string | StorageKey): void {
        localStorage.removeItem(this.formatKey(key));
    }

    private formatKey(key: string | StorageKey): string {
        switch (key) {
            case StorageKey.DISPLAY_SIDE_MENU_LABELS:
            case StorageKey.PROD_ORDER:
            case StorageKey.LAST_VISITED_OFFER_ID:
            case StorageKey.LAST_SELECTED_OFFER_CURRENCY:
            case StorageKey.LAST_WINDOW_EDITOR_ADDON_CATEGORY:
                return `${this.getUserId()}_${key}`;
            default:
                if (key.toString().endsWith('_filters')) {
                    return `${this.getUserId()}_${key}`;
                }
                break;
        }
        return `${key}`;
    }

    private getUserId() {
        return JwtHelperService.decodeToken(localStorage.getItem(StorageKey.JWT))?.userid;
    }
}
