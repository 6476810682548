import {MultilanguageField} from "../../../supportedLanguages";
import {PricetableSelectionMode} from "./pricetable-selection-mode";

export class Material2ConfigSystem {
    materialId: number;
    materialName: MultilanguageField;
    configSystemId: number;
    configSystemName: MultilanguageField;
    windowSystemPricetableSelectionMode = PricetableSelectionMode.CONFIG;
    priceTableId: number;
    priceTableItemsPresent: boolean;
}
