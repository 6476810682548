import {ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {MotlawaIntegrationInfo} from "../../../common/MotlawaIntegrationInfo";
import {ConfigSystemService} from "./config-system.service";
import {CrudCommonComponent} from "../../../common/crud-common/crud.component";
import {DatatableInterface} from "../../../common/DatatableHelper";
import {WizardStepValidator} from "../../../form-inputs/wizard/wizard-step.component";
import {finalize, map, mergeMap, tap} from "rxjs/operators";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";
import {forkJoin, Observable, of} from "rxjs";
import {MultiValidator} from "../../../shared/validator/input-validator";
import {TranslatedSelectItemService} from "../../../common/service/translated-select-item.service";
import {MultilanguageField, SupportedLanguages} from "../../../supportedLanguages";
import {ConfigSystem} from "./config-system";
import {SelectItem} from "primeng/api/selectitem";
import {SelectItemImpl} from "../../../common/service/select.item.impl";
import {SupplierService} from "../../supplier/supplier.service";
import {CatalogConfiguration} from "../../catalog-creator/catalog-configuration";
import {CatalogConfigurationService} from "../../catalog-creator/catalog-configuration.service";
import {CatalogPropertyTarget} from "../../catalog-creator/CatalogPropertyTarget";
import {ConfigTypeDescriptorService} from "../config-type-descriptor/config-type-descriptor.service";
import {ConfigDescriptorLevel} from "../../../common/enums/ConfigDescriptorLevel.enum";
import {FileState} from "../../../common/dataServiceHelper";
import {MultilanguageFieldInterface} from "../../../../window-designer/catalog-data/multilanguage-field-interface";
import {SingleSystemCheckboxCrudComponent} from "../single-system-checkbox-crud/single-system-checkbox-crud.component";
import {ProductTypeGroup} from "../window-system-definition/product-type-group";
import {WindowSystemDefinitionService} from "../window-system-definition/window-system-definition.service";
import {MessageSeverity} from "../../offer/offers/message";
import * as _ from "underscore";
import {AddonCategoryGroupService} from "../addon-category-group/addon-category-group.service";
import {AddonCategoryGroup} from "../addon-category-group/addon-category-group";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";

@Component({
    selector: 'app-config-system',
    templateUrl: './config-system.component.html',
    providers: [ConfigSystemService, TranslatedSelectItemService, SupplierService, CatalogConfigurationService,
        WindowSystemDefinitionService, AddonCategoryGroupService]
})
export class ConfigSystemComponent extends SingleSystemCheckboxCrudComponent<ConfigSystem, ConfigSystemService> implements OnInit {

    readonly STEPS = {
        DATA: 'DATA',
        DIMENSIONS: 'DIMENSIONS',
        DESCRIPTION: 'DESCRIPTION',
        SYSTEMS: 'SYSTEMS',
        GLAMOUR_PRINT_INFO: 'GLAMOUR_PRINT_INFO',
        MOTLAWA: 'MOTLAWA'
    };

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT];

    validateDataStep: WizardStepValidator;
    validateDimensionsStep: WizardStepValidator;
    existingSuppliers: SelectItem[] = [];
    availableCategories: SelectItem[] = [];
    filterCategories: SelectItem[] = [];
    availableFunctions: SelectItem[] = [];
    filterFunctions: SelectItem[] = [];
    catalogConfigurations: CatalogConfiguration;
    catalogConfigurationService: CatalogConfigurationService;

    horizontalPrintImageFile: FileState;
    verticalPrintImageFile: FileState;
    glamourDescription = new MultilanguageField();

    designerImage: File;
    imageLarge: File;

    messageLevelsOffer: { [severity: string]: boolean } = {};
    messageLevelsOrder: { [severity: string]: boolean } = {};
    messagesHiddenOnOffer: MessageSeverity[];
    messagesHiddenOnOrder: MessageSeverity[];
    printableMessageSeverityLevels: MessageSeverity[] = [MessageSeverity.WARNING, MessageSeverity.INFO, MessageSeverity.CODE];
    motlawaInfo: MotlawaIntegrationInfo;
    canUploadToAmazon = false;
    amazonFiles: SelectItem[] = [];
    uploadedFile: File;

    labelKeysMap: Map<string, MultilanguageField> = new Map<string, MultilanguageField>();
    readonly supportedLanguages = SupportedLanguages.languages;
    addonCategoryGroups: AddonCategoryGroup[] = [];

    editPermits: FieldLimitation[] = [];

    @ViewChild('dt')
    dataTable: DatatableInterface;

    constructor(
        injector: Injector,
        changeDetector: ChangeDetectorRef,
        private supplierService: SupplierService,
        private configTypeDescriptorService: ConfigTypeDescriptorService,
        private addonCategoryGroupService: AddonCategoryGroupService,
        private editCatalogPermitsService: EditCatalogPermitsService
    ) {
        super(injector, changeDetector, true, ConfigSystemService, 'CONFIG_SYSTEM', 'ConfigSystem');
        this.validateDataStep = () => this.validateForm();
        this.validateDimensionsStep = () => this.validateDimensionForm();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.catalogConfigurationService = injector.get(CatalogConfigurationService);
    }

    ngOnInit() {
        super.ngOnInit();
        forkJoin({
            addonCategoryGroups: this.addonCategoryGroupService.getGroupsForConfigSystemDefinition(),
            suppliers: this.supplierService.getSupplierNames(),
            configTypeDescriptors: this.configTypeDescriptorService.getItems(null, null, {}, null, null),
            catalogConfiguration: this.getCatalogConfiguration(CatalogPropertyTarget.CONFIG_ADDONS)
        }).subscribe({
            next: data => {
                this.addonCategoryGroups = data.addonCategoryGroups;
                this.existingSuppliers = data.suppliers.map(supplier => new SelectItemImpl(supplier.companyName, supplier));
                this.availableCategories = data.configTypeDescriptors.data.filter(descriptor =>
                    descriptor.descriptorLevel === ConfigDescriptorLevel.CATEGORY)
                    .map(category => new SelectItemImpl(category.name[this.userLang], category));
                this.filterCategories = this.availableCategories.map(item => new SelectItemImpl(item.label, item.value.id));
                this.filterCategories.unshift(new SelectItemImpl('', ''));
                this.availableFunctions = data.configTypeDescriptors.data.filter(descriptor =>
                    descriptor.descriptorLevel === ConfigDescriptorLevel.FUNCTION)
                    .map(functionDescriptor => new SelectItemImpl(functionDescriptor.name[this.userLang], functionDescriptor));
                this.filterFunctions = this.availableFunctions.map(item => new SelectItemImpl(item.label, item.value.id));
                this.filterFunctions.unshift(new SelectItemImpl('', ''));
                this.catalogConfigurations = data.catalogConfiguration;
            },
            error: error => this.errors.handle(error)
        });
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.CONFIG_SYSTEMS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): ConfigSystem {
        this.selectedWindowSystems = [];
        return new ConfigSystem();
    }

    onRowSelect(event: any): void {
        this.validationErrors = {};
        this.resetFile();
        this.resetGlamourHorizontalPrintImageFile();
        this.resetGlamourVerticalPrintImageFile();
        this.prepareDataForItem(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image', 'designerImage', 'imageLarge');

        this.validationErrors[`name[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.configSystemDto.name[${this.translate.currentLang}]`)
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 100)
                .validate(this.item.name[this.translate.currentLang]);

        this.validationErrors[`shortcut[${this.translate.currentLang}]`] =
            MultiValidator.of(`error.configSystemDto.shortcut[${this.translate.currentLang}]`)
                .withSizeValidator(0, 100)
                .validate(this.item.shortcut[this.translate.currentLang]);

        this.validationErrors['symbol'] =
            MultiValidator.of('error.configSystemDto.symbol')
                .withNotNullValidator()
                .withNotBlankValidator()
                .withSizeValidator(0, 50)
                .validate(this.item.symbol);

        this.validationErrors['sortIndex'] = MultiValidator.of('error.configSystemDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        if (!this.item.category) {
            this.validationErrors['category'] = 'error.configSystemDto.category.not_empty';
        }

        if (!this.item.function) {
            this.validationErrors['function'] = 'error.configSystemDto.function.not_empty';
        }

        if (!this.item.supplier) {
            this.validationErrors['supplier'] = 'error.configSystemDto.supplier.not_empty';
        }

        this.validationErrors['unitWeight'] = MultiValidator.of('error.configSystemDto.unitWeight')
            .withNotNullValidator()
            .withNotBlankValidator()
            .validate(this.item.unitWeight);

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    validateDimensionForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrors(this.validationErrors);

        for (let i = 1; i <= 6; i++) {
            if (this.item[`hasDimension${i}`]) {
                const id = this.item.id != undefined ? this.item.id : 'id';
                this.validationErrors[`name_bulk_dim_${i}[${this.translate.currentLang}]`] =
                    MultiValidator.of(`error.configSystemDto.name_bulk_dim_${i}[${this.translate.currentLang}]`)
                        .withNotNullValidator()
                        .withNotBlankValidator()
                        .withSizeValidator(0, 100)
                        .validate(this.labelKeysMap.get(`CONFIG_DIMENSION.${id}.NAME.DIM_${i}`)[this.translate.currentLang]);

                this.validationErrors[`print_name_bulk_dim_${i}[${this.translate.currentLang}]`] =
                    MultiValidator.of(`error.configSystemDto.print_name_bulk_dim_${i}[${this.translate.currentLang}]`)
                        .withNotNullValidator()
                        .withNotBlankValidator()
                        .withSizeValidator(0, 100)
                        .validate(this.labelKeysMap.get(`CONFIG_DIMENSION.${id}.PRINT_NAME.DIM_${i}`)[this.translate.currentLang]);
            }
        }
        for (let i = 1; i <= 3; i++) {
            if (this.item[`hasWidth${i}`]) {
                const id = this.item.id != undefined ? this.item.id : 'id';
                this.validationErrors[`name_window_width_${i}[${this.translate.currentLang}]`] =
                    MultiValidator.of(`error.configSystemDto.name_window_width_${i}[${this.translate.currentLang}]`)
                        .withNotNullValidator()
                        .withNotBlankValidator()
                        .withSizeValidator(0, 100)
                        .validate(this.labelKeysMap.get(`CONFIG_DIMENSION.${id}.NAME.WIDTH_${i}`)[this.translate.currentLang]);

                this.validationErrors[`print_name_window_width_${i}[${this.translate.currentLang}]`] =
                    MultiValidator.of(`error.configSystemDto.print_name_window_width_${i}[${this.translate.currentLang}]`)
                        .withNotNullValidator()
                        .withNotBlankValidator()
                        .withSizeValidator(0, 100)
                        .validate(this.labelKeysMap.get(`CONFIG_DIMENSION.${id}.PRINT_NAME.WIDTH_${i}`)[this.translate.currentLang]);
            }

            if (this.item[`hasHeight${i}`]) {
                const id = this.item.id != undefined ? this.item.id : 'id';
                this.validationErrors[`name_window_height_${i}[${this.translate.currentLang}]`] =
                    MultiValidator.of(`error.configSystemDto.name_window_height_${i}[${this.translate.currentLang}]`)
                        .withNotNullValidator()
                        .withNotBlankValidator()
                        .withSizeValidator(0, 100)
                        .validate(this.labelKeysMap.get(`CONFIG_DIMENSION.${id}.NAME.HEIGHT_${i}`)[this.translate.currentLang]);

                this.validationErrors[`print_name_window_height_${i}[${this.translate.currentLang}]`] =
                    MultiValidator.of(`error.configSystemDto.print_name_window_height_${i}[${this.translate.currentLang}]`)
                        .withNotNullValidator()
                        .withNotBlankValidator()
                        .withSizeValidator(0, 100)
                        .validate(this.labelKeysMap.get(`CONFIG_DIMENSION.${id}.PRINT_NAME.HEIGHT_${i}`)[this.translate.currentLang]);
            }
        }

        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return of(true);
    }

    submit() {
        this.validateForm().subscribe(validateOk => {
            if (validateOk) {
                if (this.isSaveInProgress()) {
                    return;
                }
                this.setSaveInProgress(true);
                this.item.messagesHiddenOnOffer = this.getListOfUncheckedMessageSymbols(this.messageLevelsOffer);
                this.item.messagesHiddenOnOrder = this.getListOfUncheckedMessageSymbols(this.messageLevelsOrder);
                let observable: Observable<number>;
                if (this.copyMode) {
                    observable = this.itemService.copy(this.selectedItem.id, this.item, this.designerImage, this.imageLarge,
                        this.labelKeysMap, this.motlawaInfo, this.uploadedFile);
                    this.horizontalPrintImageFile.needSave = true;
                    this.verticalPrintImageFile.needSave = true;
                } else {
                    observable = this.itemService.saveConfigSystem(this.item, this.designerImage, this.imageLarge,
                        this.labelKeysMap, this.motlawaInfo, this.uploadedFile);
                }

                observable.pipe(mergeMap(updatedItemId => {
                    return this.itemService.editLinks(this.getApiUrl(), updatedItemId, this.prepareSystemIdsForRequest())
                        .pipe(map(() => updatedItemId));
                }), mergeMap(updatedItemId => {
                    return this.itemService.updateAddonGlamourPrintInfo(updatedItemId, this.horizontalPrintImageFile,
                        this.verticalPrintImageFile, this.glamourDescription).pipe(map(() => updatedItemId));
                }), finalize(() => this.setSaveInProgress(false))).subscribe({
                    complete: () => {
                        this.showSuccessMessage();
                        this.hideDetails();
                    },
                    error: err => {
                        this.validationErrors = Object.assign({}, this.validationErrors, this.errors.handle(err));
                        this.changeDetector.markForCheck();
                    }
                });
            }
        });
    }

    prepareDataForItem(configSystemId: number) {
        forkJoin({
            config: (configSystemId != undefined ? this.itemService.getItem(configSystemId) : of(new ConfigSystem())),
            designerImage: (configSystemId != undefined ? this.itemService.getDesignerImageAsFile(configSystemId) : of<File>(undefined)),
            imageLarge: (configSystemId != undefined ? this.itemService.getImageLargeAsFile(configSystemId) : of<File>(undefined)),
            horizontalPrintImage: (configSystemId != undefined ? this.itemService.getGlamourHorizontalPrintImageAsFile(configSystemId) : of<File>(undefined)),
            verticalPrintImage: (configSystemId != undefined ? this.itemService.getGlamourVerticalPrintImageAsFile(configSystemId) : of<File>(undefined)),
            glamourDescription: (configSystemId != undefined ? this.itemService.getGlamourDescription(configSystemId) : of<MultilanguageField>(new MultilanguageField())),
            translations: (configSystemId != undefined ? this.itemService.getLabelTranslations(configSystemId) : of<Map<string, MultilanguageField>>(new Map<string, MultilanguageField>())),
            linkedSystems: (configSystemId != undefined ? this.getLinkedWindowSystems(configSystemId) : of<number[]>([])),
            motlawaInfo: (configSystemId != undefined && this.permissions.isKoordynator()
                ? this.itemService.getMotlawaInfo(configSystemId)
                : of(new MotlawaIntegrationInfo())),
            canUploadToAmazon: this.itemService.canUploadToAmazonS3(),
            amazonFiles: this.itemService.getAmazonS3Files()
        }).subscribe({
            next: data => {
                this.newItem = configSystemId == undefined;
                this.item = data.config;
                if (this.newItem) {
                    this.item.name = new MultilanguageField();
                    this.item.shortcut = new MultilanguageField();
                    if (this.isPermitted({roles: ['ROLE_KOORDYNATOR']})) {
                        this.item.sortIndex = 1;
                    }
                }
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.horizontalPrintImageFile = {file: data.horizontalPrintImage, needSave: false};
                this.verticalPrintImageFile = {file: data.verticalPrintImage, needSave: false};
                this.glamourDescription = data.glamourDescription;
                this.designerImage = data.designerImage;
                this.imageLarge = data.imageLarge;
                this.prepareTranslationLabelKeys(data.translations, configSystemId);
                this.selectedWindowSystems = data.linkedSystems;
                this.motlawaInfo = data.motlawaInfo;
                this.messagesHiddenOnOffer = this.item.messagesHiddenOnOffer;
                this.messagesHiddenOnOrder = this.item.messagesHiddenOnOrder;
                this.printableMessageSeverityLevels.forEach(messageSeverity => this.setMessageLevelInItem(messageSeverity));
                this.canUploadToAmazon = data.canUploadToAmazon;
                this.amazonFiles = data.amazonFiles.map(file => ({label: file, value: file}));
                if (!this.canUploadToAmazon) {
                    if (this.amazonFiles.findIndex(af => af.value === this.item.amazonS3FileName) < 0) {
                        this.item.amazonS3FileName = undefined;
                    }
                }
                // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                this.focusOnElementWithId(this.getFirstInputId());
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
            }
        });
    }

    showDialogToAdd() {
        this.resetFile();
        this.validationErrors = {};
        this.prepareDataForItem(null);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.resetFile();
            this.prepareDataForItem(this.selectedItem.id);
        }
    }

    hideDetails() {
        this.copyMode = false;
        this.setDisplayDialog(false);
        this.newItem = false;
        this.resetFile();
        this.reloadDatatable();
    }

    getCatalogConfiguration(target: CatalogPropertyTarget): Observable<CatalogConfiguration> {
        return this.catalogConfigurations == undefined ?
            this.catalogConfigurationService.getForTarget(target, true) : of(this.catalogConfigurations);
    }

    resetFile() {
        this.imageLarge = null;
        this.designerImage = null;
    }

    onDesignerImageChange(newFile: File): void {
        this.designerImage = newFile;
        if (!newFile) {
            this.designerImage = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    onImageLargeChange(newFile: File): void {
        this.imageLarge = newFile;
        if (!newFile) {
            this.imageLarge = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleGlamourHorizontalPrintImageFileChange(newFile: File): void {
        this.horizontalPrintImageFile.file = newFile;
        this.horizontalPrintImageFile.needSave = true;
        if (!newFile) {
            this.horizontalPrintImageFile.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleGlamourVerticalPrintImageFileChange(newFile: File): void {
        this.verticalPrintImageFile.file = newFile;
        this.verticalPrintImageFile.needSave = true;
        if (!newFile) {
            this.verticalPrintImageFile.file = new File([], null);
        }
        this.changeDetector.markForCheck();
    }

    handleGlamourDescriptionChange(data: {text: string, lang: keyof MultilanguageFieldInterface}): void {
        this.glamourDescription[data.lang] = data.text;
        this.changeDetector.markForCheck();
    }

    resetGlamourHorizontalPrintImageFile(): void {
        this.horizontalPrintImageFile = {file: undefined, needSave: false};
    }

    resetGlamourVerticalPrintImageFile(): void {
        this.verticalPrintImageFile = {file: undefined, needSave: false};
    }

    setMessageLevelInItem(messageSeverity: MessageSeverity): void {
        this.messageLevelsOffer[messageSeverity] = !_.contains(this.messagesHiddenOnOffer, messageSeverity);
        this.messageLevelsOrder[messageSeverity] = !_.contains(this.messagesHiddenOnOrder, messageSeverity);
    }

    prepareTranslationLabelKeys(labels: Map<string, MultilanguageField>, configSystemId: number) {
        this.labelKeysMap.clear();
        // 'id' is a placeholder for new item
        const baseKey = 'CONFIG_DIMENSION.' + (configSystemId != undefined ? configSystemId : 'id');
        const newKey = this.copyMode ? 'CONFIG_DIMENSION.id' : baseKey;
        for (let i = 1; i <= 6; i++) {
            this.labelKeysMap.set(newKey + '.NAME.DIM_' + i,
                labels.get(baseKey + '.NAME.DIM_' + i) || new MultilanguageField());
            this.labelKeysMap.set(newKey + '.PRINT_NAME.DIM_' + i,
                labels.get(baseKey + '.PRINT_NAME.DIM_' + i) || new MultilanguageField());
            this.labelKeysMap.set(newKey + '.TOOLTIP.DIM_' + i,
                labels.get(baseKey + '.TOOLTIP.DIM_' + i) || new MultilanguageField());
        }
        for (let i = 1; i <= 3; i++) {
            this.labelKeysMap.set(newKey + '.NAME.WIDTH_' + i,
                labels.get(baseKey + '.NAME.WIDTH_' + i) || new MultilanguageField());
            this.labelKeysMap.set(newKey + '.PRINT_NAME.WIDTH_' + i,
                labels.get(baseKey + '.PRINT_NAME.WIDTH_' + i) || new MultilanguageField());
            this.labelKeysMap.set(newKey + '.TOOLTIP.WIDTH_' + i,
                labels.get(baseKey + '.TOOLTIP.WIDTH_' + i) || new MultilanguageField());
        }
        for (let i = 1; i <= 3; i++) {
            this.labelKeysMap.set(newKey + '.NAME.HEIGHT_' + i,
                labels.get(baseKey + '.NAME.HEIGHT_' + i) || new MultilanguageField());
            this.labelKeysMap.set(newKey + '.PRINT_NAME.HEIGHT_' + i,
                labels.get(baseKey + '.PRINT_NAME.HEIGHT_' + i) || new MultilanguageField());
            this.labelKeysMap.set(newKey + '.TOOLTIP.HEIGHT_' + i,
                labels.get(baseKey + '.TOOLTIP.HEIGHT_' + i) || new MultilanguageField());
        }

        const baseKey2 = 'CONFIG_LABEL.' + (configSystemId != undefined ? configSystemId : 'id');
        const newKey2 = this.copyMode ? 'CONFIG_LABEL.id' : baseKey2;
        this.labelKeysMap.set(newKey2 + '.NAME.MATERIAL', labels.get(baseKey2 + '.NAME.MATERIAL') || new MultilanguageField());
        this.labelKeysMap.set(newKey2 + '.PRINT_NAME.MATERIAL', labels.get(baseKey2 + '.PRINT_NAME.MATERIAL') || new MultilanguageField());
        this.labelKeysMap.set(newKey2 + '.NAME.COLOR', labels.get(baseKey2 + '.NAME.COLOR') || new MultilanguageField());
        this.labelKeysMap.set(newKey2 + '.PRINT_NAME.COLOR', labels.get(baseKey2 + '.PRINT_NAME.COLOR') || new MultilanguageField());
    }

    protected getApiUrl(): string {
        return "configSystem";
    }

    private getListOfUncheckedMessageSymbols(item: { [key: string]: boolean }): MessageSeverity[] {
        return Object.keys(item).filter(symbol => !item[symbol]).map(item => MessageSeverity[item]) || [];
    }
}
