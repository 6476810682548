import {ConfigurableAddon} from "../../../../../../../window-designer/entities/ConfigurableAddon";
import {ErrorResponse} from "../../../../../errors/errorResponse";
import {MessageSeverity, PositionMessage} from '../../../../offers/message';
import {Pricing} from "../Pricing";
import {PricingResult} from "../PricingResult";
import {ConfigAddonErrorMessages} from "./ConfigAddonErrorMessage";

export class ConfigAddonPricingResult {
    public addon: ConfigurableAddon;
    public pricing: Pricing;
    public errorMessages: ConfigAddonErrorMessages;
    public infoMessages: PositionMessage[] = [];
    public warningMessages: PositionMessage[] = [];
    public containsError: boolean;

    constructor(addon: ConfigurableAddon, pricingResult: PricingResult) {
        this.addon = addon;
        this.pricing = pricingResult.pricing;
        this.containsError = pricingResult.containsError;
        this.errorMessages = new ConfigAddonErrorMessages(pricingResult.errorCodes,
            ErrorResponse.mapFailedFields(pricingResult.failedFields));

        if (!pricingResult.containsError && pricingResult.pricing.products && pricingResult.pricing.products.length) {
            let messages = (pricingResult.pricing.products[0].messages || [])
                .filter(msg => msg.fieldName == undefined); // skip messages that have fieldName set, they are duplicated in failedFields
            this.infoMessages = messages.filter(msg => msg.severity === MessageSeverity.INFO);
            this.warningMessages =
                messages.filter(msg => msg.severity === MessageSeverity.WARNING);
        }
    }
}
