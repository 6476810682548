export enum PrintAction {
    DOWNLOAD_DOCX = 'DOWNLOAD_DOCX',
    DOWNLOAD_XLSX = 'DOWNLOAD_XLSX',
    DOWNLOAD_PDF = 'DOWNLOAD_PDF',
    PRINT = 'PRINT',
    IDLE = 'IDLE',
    DOWNLOAD_JSON = 'DOWNLOAD_JSON',
    DOWNLOAD_XML = 'DOWNLOAD_XML',
    COPY_CONTENT_TO_CLIPBOARD = 'COPY_CONTENT_TO_CLIPBOARD'
}
