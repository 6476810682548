import {AddonFor} from "./AddonFor";

export enum AddonCategoryEnum {
    GLAZIERY = 'GLAZIERY',
    PROFILE_PCV = 'PROFILE_PCV',
    PROFILE_WOOD = 'PROFILE_WOOD',
    PROFILE_ALUMINIUM = 'PROFILE_ALUMINIUM',
    PROFILE_ALUMINIUM_WOOD_MIX = 'PROFILE_ALUMINIUM_WOOD_MIX',
    VENTILATION = 'VENTILATION',
    VENTILATORS = 'VENTILATORS',
    OTHER_PCV_ADDONS = 'OTHER_PCV_ADDONS',
    PATTERNS = 'PATTERNS',
    SERVICES = 'SERVICES',
    HANDLE = 'HANDLE',
    TERRACE_HANDLE = 'TERRACE_HANDLE',
    COVER = 'COVER',
    MILLING = 'MILLING',
    MILLING_NORWEGIAN = 'MILLING_NORWEGIAN',
    UNDER_WINDOW_BEAD = 'UNDER_WINDOW_BEAD',
    ENHANCEMENTS = 'ENHANCEMENTS',
    UNDERWINDOW_PROFILE = 'UNDERWINDOW_PROFILE',
    CONNECTORS_EXTENDERS_PCV = 'CONNECTORS_EXTENDERS_PCV',
    CONNECTORS_EXTENDERS_WOOD = 'CONNECTORS_EXTENDERS_WOOD',
    CONNECTORS_EXTENDERS_ALUMINIUM = 'CONNECTORS_EXTENDERS_ALUMINIUM',
    CONNECTORS_EXTENDERS_ALUMINIUM_WOOD_MIX = 'CONNECTORS_EXTENDERS_ALUMINIUM_WOOD_MIX',
    WINDOWSILLS = 'WINDOWSILLS',
    SCANDINAVIAN_SOLUTIONS = 'SCANDINAVIAN_SOLUTIONS',
    DRIP = 'DRIP',
    COUPLER = 'COUPLER',
    FITTING_BRAKE = 'FITTING_BRAKE',
    FITTING_SLIDING = 'FITTING_SLIDING',
    FITTING_TYPE = 'FITTING_TYPE',
    FITTING_ESPAGNOLETTE_TYPE = 'FITTING_ESPAGNOLETTE_TYPE',
    FITTING_VERANDA = 'FITTING_VERANDA',
    FITTING_INSERTION = 'FITTING_INSERTION',
    FITTING_MAIN_INSERTION = 'FITTING_MAIN_INSERTION',
    FITTING_ADDITIONAL_INSERTION = 'FITTING_ADDITIONAL_INSERTION',
    FITTING_LOCK = 'FITTING_LOCK',
    FITTING_LOCK_TERRACE = 'FITTING_LOCK_TERRACE',
    FITTING_AUTOMATIC_DRIVE = 'FITTING_AUTOMATIC_DRIVE',
    OTHER = 'OTHER',
    ANCHOR = 'ANCHOR',
    FLASHING = 'FLASHING',
    FLASHING_AKP = 'FLASHING_AKP',
    ENTRANCE_DOOR_FITTING = 'ENTRANCE_DOOR_FITTING',
    WELD_TYPE = 'WELD_TYPE',
    GATE_SIDEBAR = 'GATE_SIDEBAR',
    GATE_NOT_SIDEBAR = 'GATE_NOT_SIDEBAR',
    CONFIG_ADDON_SIDEBAR = 'CONFIG_ADDON_SIDEBAR',
    SMART_LOCK = 'SMART_LOCK'
}

export class AddonCategoryEnumGroup {

    static readonly Gate: AddonCategoryTarget = {
        target: 'Gate',
        addonCategories: [
            AddonCategoryEnum.GATE_SIDEBAR,
            AddonCategoryEnum.GATE_NOT_SIDEBAR
        ],
        addonFor: [AddonFor.GATE]
    };

    static readonly Config: AddonCategoryTarget = {
        target: 'Config',
        addonCategories: [AddonCategoryEnum.CONFIG_ADDON_SIDEBAR],
        addonFor: [AddonFor.CONFIG_ADDON]
    };

    static readonly Window: AddonCategoryTarget = {
        target: 'Window',
        addonCategories: Object.keys(AddonCategoryEnum)
            .filter(ace => AddonCategoryEnumGroup.Gate.addonCategories.indexOf(AddonCategoryEnum[ace]) < 0)
            .filter(ace => AddonCategoryEnumGroup.Config.addonCategories.indexOf(AddonCategoryEnum[ace]) < 0)
            .map(ace => ace as AddonCategoryEnum),
        addonFor: Object.keys(AddonFor)
            .filter(ace => AddonCategoryEnumGroup.Gate.addonFor.indexOf(AddonFor[ace]) < 0)
            .filter(ace => AddonCategoryEnumGroup.Config.addonFor.indexOf(AddonFor[ace]) < 0)
            .map(ace => ace as AddonFor),
    };
}

export type AddonTarget = 'Gate' | 'Config' | 'Window';

export interface AddonCategoryTarget {
    target: AddonTarget;
    addonCategories: AddonCategoryEnum[];
    addonFor: AddonFor[];
}
