import {Component, Input} from '@angular/core';
import {GraspGlazingCategoryField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GraspGlazingCategoryFieldUsage} from "../../catalog-field-usage";
import {GraspGlazingCategory} from "../grasp-glazing-category";

@Component({
  selector: 'app-grasp-glazing-categories-form',
  templateUrl: './grasp-glazing-categories-form.component.html',
})
export class GraspGlazingCategoriesFormComponent {

    @Input()
    item: GraspGlazingCategory;

    @Input()
    validationErrors;

    @Input()
    editPermits: FieldLimitation[] = [];

    fieldUsage: GraspGlazingCategoryFieldUsage;
    GraspGlazingCategoryField = GraspGlazingCategoryField;

    constructor() {
        this.fieldUsage = new GraspGlazingCategoryFieldUsage(this);
    }
}
