import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {CommonErrorHandler} from "../../../../../../common/CommonErrorHandler";
import {ExchangeService} from "../../../../../../common/exchange.service";
import {OnceFlag} from '../../../../../../shared/once-flag';
import {OfferExhangeRates} from '../../../../offer';
import {OffersService} from "../../../../offer-service";
import {UpdateExchangeRateDialogData} from "../position-list-dialogs";
import {Currencies} from "../../../../../../currencies";

@Component({
    selector: 'app-update-exchange-rate',
    templateUrl: './update-exchange-rate.component.html',
    providers: [OffersService, ExchangeService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateExchangeRateComponent {

    @Input()
    dialogData: UpdateExchangeRateDialogData;

    @Output()
    protected onClose = new EventEmitter();

    @Output()
    navigateToSubsystemSettings = new EventEmitter<void>();

    @Output()
    protected onSuccess = new EventEmitter<OfferExhangeRates>();

    private readonly dialogHideHelper = new OnceFlag();
    readonly currencies = Currencies;

    constructor(private offerService: OffersService,
                private errors: CommonErrorHandler) {
    }

    public submit(): void {
        this.offerService.updateExchangeRate(this.dialogData.offerId).subscribe({
            next: newExchangeRates => {
                this.emitOnSuccess(newExchangeRates);
                this.close();
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    public close(): void {
        this.dialogHideHelper.call(() => this.onClose.emit());
    }

    private emitOnSuccess(newExchangeRates: OfferExhangeRates): void {
        this.dialogHideHelper.call(() => this.onSuccess.emit(newExchangeRates));
    }
}
