export class SubwindowPolygons {
    framePoints: number[];
    frameOpeningPoints: number[];
    wingPoints: number[];
    pluginPoints: number[];
    glassPoints: number[];

    constructor(framePoints: number[],
                frameOpeningPoints: number[],
                wingPoints: number[],
                pluginPoints: number[],
                glassPoints: number[]) {
        this.framePoints = framePoints;
        this.frameOpeningPoints = frameOpeningPoints;
        this.wingPoints = wingPoints;
        this.pluginPoints = pluginPoints;
        this.glassPoints = glassPoints;
    }
}
