<div class="dialog-window">
    <p-dialog [visible]="true" [resizable]="false" (onHide)="cancel()" [modal]="true" [focusOnShow]="false">
        <p-header>
            <div class="dialog-double-header-container">
                <div *ngIf="isNew" class="p-dialog-title">
                    {{ "PRICE_TABLES.FORM.ADD-TITLE" | translate }}
                </div>
                <div *ngIf="!isNew" class="p-dialog-title">
                    {{ "PRICE_TABLES.FORM.EDIT-TITLE" | translate }}
                </div>
                <div class="dialog-update-dates-container">
                    <span>{{ 'PRICE_TABLES.LAST_MODIFICATION_DATE' | translate }}:</span>
                    <span>{{primaryPricetable?.lastModifiedDate | momentCurrentLang | amDateFormat: 'L LT'}}</span>
                    <ng-container *ngIf="primaryPricetable?.lastNotificationDate">
                        <span>{{ 'PRICE_TABLES.LAST_NOTIFICATION_DATE' | translate }}:</span>
                        <span>{{primaryPricetable?.lastNotificationDate | momentCurrentLang | amDateFormat: 'L LT'}}</span>
                    </ng-container>
                </div>
            </div>
        </p-header>

        <div class="scrolled-dialog scrolled-dialog-900">
            <div class="price-table-padding">
                <ng-container *ngIf="priceTablesToEdit.length < 1 && target == targets.WINDOWS">
                    <ng-container *vLet="getTableInfoForWindows() as windowInfo">
                        <ng-container *ngTemplateOutlet="labelRow; context: {
                            label: 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate,
                            value: windowInfo.windowSystemName | multilanguageTranslate,
                            label2: 'PRICE_TABLES.FORM.TYPE' | translate,
                            value2: typeNameParser(windowInfo.typeName)
                         }">
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="priceTablesToEdit.length < 1 && target == targets.CONFIGS && !isNew">
                    <ng-container *vLet="getTableInfoForConfigs() as configInfo">
                        <ng-container *ngTemplateOutlet="labelRow; context: {
                            label: 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate,
                            value: configInfo.configSystemName | multilanguageTranslate,
                            label2: 'PRICE_TABLES.FORM.MATERIAL_NAME' | translate,
                            value2: configInfo.materialName | multilanguageTranslate
                         }">
                        </ng-container>
                    </ng-container>
                </ng-container>

                <ng-container *ngIf="target == targets.CONFIGS && isNew">
                    <div class="new-form-600">
                        <div class="new-form-row">
                            <app-select inputId="system" label="{{ 'PRICE_TABLES.FORM.SYSTEM_NAME' | translate }}"
                                        [options]="systems"
                                        [optionFormatter]="systemsOptionFormatter"
                                        [ngModel]="selectedSystem" (ngModelChange)="onSystemChange($event)"
                                        [required]="true"></app-select>
                        </div>
                        <div class="new-form-row">
                            <app-select inputId="system" label="{{ 'PRICE_TABLES.FORM.MATERIAL_NAME' | translate }}"
                                        [options]="materials"
                                        [optionFormatter]="materialsOptionFormatter"
                                        [ngModel]="selectedMaterial" (ngModelChange)="onMaterialChange($event)"
                                        [required]="true"></app-select>
                        </div>
                    </div>
                </ng-container>

                <ng-template #pricetableTemplate let-priceTable>
                    <app-window-pricingtable [priceTable]="priceTable"
                                             [selectedPricelistIsFuture]="selectedPricelistIsFuture"
                                             [canDownloadExternalPriceTable]="canDownloadExternalPriceTable"
                                             [tableInfo]="tableInfo"
                                             [isNew]="isNew"></app-window-pricingtable>
                </ng-template>
                <ng-container *ngIf="!canHaveTabs">
                    <ng-container
                        *ngTemplateOutlet="pricetableTemplate; context: { $implicit: priceTables[0] }"></ng-container>
                </ng-container>
                <ng-container *ngIf="canHaveTabs">
                    <app-simple-button *ngIf="canAddNewTab" buttonId="addChildPricetableButton"
                                       label="{{ 'PRICE_TABLES.FORM.NEW_CHILD_TABLE' | translate }}"
                                       type="action" (onClick)="addNewTab()"></app-simple-button>
                    <p-tabView [(activeIndex)]="selectedTab">
                        <p-tabPanel *ngFor="let priceTable of priceTables; trackBy: pricetableTabTrackIndex"
                                    [header]="getTabHeader(priceTable) | translate">
                            <ng-container
                                *ngTemplateOutlet="pricetableTemplate; context: { $implicit: priceTable }"></ng-container>
                        </p-tabPanel>
                    </p-tabView>
                </ng-container>
            </div>
        </div>

        <p-footer>
            <div class="p-dialog-buttonpane">
                <ng-container *ngIf="selectedPricetableComponent">
                    <app-pricing-message *ngIf="selectedPricetableComponent.error"
                                         [message]="selectedPricetableComponent.error"></app-pricing-message>
                    <div *ngIf="permissions.canEditPricelist()">
                        <app-simple-button label="{{ 'PRICE_TABLES.FORM.NEW_COLUMN' | translate }}" icon="create"
                                           type="action"
                                           (onClick)="selectedPricetableComponent.sheetTable.addColumn()"></app-simple-button>
                        <app-simple-button label="{{ 'PRICE_TABLES.FORM.NEW_ROW' | translate }}" icon="create"
                                           type="action"
                                           (onClick)="selectedPricetableComponent.sheetTable.addRow()"></app-simple-button>
                        <app-simple-button label="{{ 'PRICE_TABLES.FORM.DELETE_COLUMN' | translate }}" icon="create"
                                           type="action"
                                           [disabled]="!selectedPricetableComponent.sheetTable.canDeleteSelectedColumns() || !selectedPricetableComponent.activeCellValid"
                                           (onClick)="selectedPricetableComponent.sheetTable.removeColumn()"></app-simple-button>
                        <app-simple-button label="{{ 'PRICE_TABLES.FORM.DELETE_ROW' | translate }}" icon="create"
                                           type="action"
                                           [disabled]="!selectedPricetableComponent.sheetTable.canDeleteSelectedRows() || !selectedPricetableComponent.activeCellValid"
                                           (onClick)="selectedPricetableComponent.sheetTable.removeRows()"></app-simple-button>
                    </div>
                </ng-container>
                <ng-container *ngIf="selectedPricetable">
                    <div class="new-form-row">
                        <app-checkbox inputId="pricingtable-calculate-price-by-area-checkbox"
                                      label="{{ 'PRICE_TABLES.FORM.CALCULATE_PRICE_BY_AREA' | translate }}"
                                      [disabled]="!permissions.canEditPricelist()"
                                      [(ngModel)]="selectedPricetable.calculatePriceByArea"></app-checkbox>
                    </div>
                    <div class="new-form-row">
                        <app-checkbox inputId="pricingtable-use-interpolation-checkbox"
                                      label="{{ 'PRICE_TABLES.FORM.USE_INTERPOLATION' | translate }}"
                                      [disabled]="!permissions.canEditPricelist()"
                                      [(ngModel)]="selectedPricetable.useInterpolation"></app-checkbox>
                    </div>
                </ng-container>
                <ng-container *ngIf="primaryPricetable">
                    <div class="new-form-row">
                        <app-checkbox inputId="pricingtable-mark-outdated-checkbox"
                                      label="{{ 'PRICE_TABLES.FORM.MARK_OUTDATED' | translate }}"
                                      [disabled]="!permissions.canEditPricelist()"
                                      [(ngModel)]="markOutdatedPricesWhenActivated"></app-checkbox>
                    </div>
                    <div *ngIf="canDownloadExternalPriceTable" style="height: 240px">
                        <app-input-text label="{{ 'PRICE_TABLES.FORM.EXTERNAL_SYMBOL' | translate }}"
                                        [(ngModel)]="primaryPricetable.externalPricetableSymbol"></app-input-text>
                        <app-input-number inputId="externalPriceTableSizeAdjustmentInput"
                                          label="{{ 'PRICE_TABLES.FORM.EXTERNAL_PRICE_TABLE_SIZE_ADJUSTMENT' | translate }}"
                                          [(ngModel)]="primaryPricetable.externalPriceTableSizeAdjustment"></app-input-number>
                        <app-simple-button label="{{ 'PRICE_TABLES.FORM.DOWNLOAD_EXTERNAL_PRICE_TABLE' | translate }}"
                                           type="secondary-action" [size]="40"
                                           (onClick)="downloadExternalPriceTable.emit({ code: primaryPricetable.externalPricetableSymbol, future: false })"
                                           [disabled]="!primaryPricetable.externalPricetableSymbol"></app-simple-button>
                        <app-simple-button
                            label="{{ 'PRICE_TABLES.FORM.DOWNLOAD_EXTERNAL_FUTURE_PRICE_TABLE' | translate }}"
                            type="secondary-action" [size]="40"
                            (onClick)="downloadExternalPriceTable.emit({ code: primaryPricetable.externalPricetableSymbol, future: true })"
                            [disabled]="!primaryPricetable.externalPricetableSymbol"></app-simple-button>
                    </div>
                </ng-container>
                <div>
                    <app-simple-button *ngIf="permissions.canEditPricelist()" label="{{ 'GENERAL.SAVE' | translate }}"
                                       type="main-action" [size]="40" (onClick)="onSubmit()"></app-simple-button>
                    <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                                       (onClick)="cancel()"></app-simple-button>
                </div>
            </div>
        </p-footer>
    </p-dialog>
</div>

<ng-template #labelRow let-label="label" let-value="value" let-label2="label2" let-value2="value2">
    <div class="pricing-table-header-info">
        <label>{{ label }}</label>
        <label>{{ value }}</label>
        <label>{{ label2 }}</label>
        <label>{{ value2 }}</label>
    </div>
</ng-template>
