import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Glazing} from '../../../window-designer/catalog-data/glazing';
import {GlazingPackageForAreaRange} from '../../../window-designer/catalog-data/glazing-package-for-area-range';
import {GlazingBeadMatchingMode, GlazingBeadUtils} from '../../features/offer/window-editor/drawing-tool/GlazingBeadUtils';
import {DistanceFrame} from '../../features/window-system/distance-frame/distanceFrame';
import {GlassWithPosition} from '../../features/window-system/glass/glassWithPositions';
import {GlazingBead} from '../../features/window-system/glazing-bead/glazing-bead';
import {GlazingPackagesValidationErrors} from '../glazing-packages/glazing-packages-form.component';
import {SelectItemImpl} from '../service/select.item.impl';

@Component({
    selector: 'app-glazing-packages-for-area-range-form',
    templateUrl: './glazing-packages-for-area-range-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlazingPackagesForAreaRangeFormComponent implements OnChanges {

    @Input()
    glazingWidths: string;

    @Input()
    validationErrors: GlazingPackagesValidationErrors;

    @Input()
    glasses: GlassWithPosition[];

    @Input()
    frames: DistanceFrame[];

    @Input()
    glassCount: number;

    @Input()
    packagesForAreas: GlazingPackageForAreaRange[] = [];

    @Input()
    availableGlazingBeads: GlazingBead[] = [];

    @Input()
    readOnlyMode: boolean;

    @Input()
    addMode = false;

    packagesForAreasToShow: GlazingPackageForAreaRange[] = [];

    constructor(private changeDetector: ChangeDetectorRef,
                public translate: TranslateService) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('packagesForAreas' in changes) {
            this.toShow();
        }
    }

    toShow(): void {
        this.packagesForAreasToShow = (this.packagesForAreas || []).filter(p => p.glassCount === this.glassCount);
        this.changeDetector.markForCheck();
    }

    add(): void {
        let packageForAreaRange = new GlazingPackageForAreaRange();
        packageForAreaRange.glassCount = this.glassCount;
        packageForAreaRange.glazing = new Glazing();
        this.packagesForAreas.push(packageForAreaRange);
        this.toShow();
        this.changeDetector.markForCheck();
    }

    remove(packageForAreaRange: GlazingPackageForAreaRange): void {
        let index = this.packagesForAreas.indexOf(packageForAreaRange);
        if (index > -1) {
            this.packagesForAreas.splice(index, 1);
        }
        this.toShow();
        this.changeDetector.markForCheck();
    }

    getAvailableGlazingBeads(item: GlazingPackageForAreaRange): SelectItemImpl[] {
        let filteredGlazingBeadList = GlazingBeadUtils.filterGlazingBeadsMatchingWidth(this.availableGlazingBeads,
            item.glazing, GlazingBeadMatchingMode.BULK_GLASS_CHANGE, this.glasses, this.frames) || [];
        if (item.glazingBeadId == null) {
            if (filteredGlazingBeadList.length > 0) {
                item.glazingBeadId = filteredGlazingBeadList[0].id;
                this.validationErrors['selectedGlazingBeads'] = undefined;
            } else {
                item.glazingBeadId = undefined;
            }
        } else {
            let selectedIsAvailable = filteredGlazingBeadList.some(gb => gb.id === item.glazingBeadId);
            item.glazingBeadId = selectedIsAvailable ? item.glazingBeadId : undefined;
        }
        this.changeDetector.markForCheck();
        return filteredGlazingBeadList.map(glazingBead =>
            new SelectItemImpl(glazingBead.names[this.translate.currentLang], glazingBead.id));
    }

    updateGlazingBead(value, item: GlazingPackageForAreaRange): void {
        item.glazingBeadId = value;
        this.changeDetector.markForCheck();
    }

}
