import {CrudItem} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

export class Seal implements CrudItem {
    id: number;
    names: MultilanguageField;
    shortcut: MultilanguageField;
    symbol: string;
    innerSeal: boolean;
    outerSeal: boolean;
    active: boolean;
    sortIndex: number;

    constructor() {
        this.id = undefined;
        this.names = new MultilanguageField();
        this.active = true;
        this.shortcut = new MultilanguageField();
        this.sortIndex = 1;
    }
}
