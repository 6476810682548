import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {MaterialType} from '../../../common/enums/MaterialType';
import {DataTableColumn} from '../../../common/service/data.table.column';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {AbstractSellDealerDiscountComponent} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component';
import {SubsystemWindowSystemSellDealerDiscount} from './subsystem-window-system-sell-dealer-discount';
import {SubsystemWindowSystemSellDealerDiscountService} from './subsystem-window-system-sell-dealer-discount.service';

@Component({
    selector: 'app-subsystem-window-system-sell-dealer-discount',
    templateUrl: '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component.html',
    styleUrls: ['../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.component.css'],
    providers: [TranslatedSelectItemService, SubsystemWindowSystemSellDealerDiscountService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemWindowSystemSellDealerDiscountComponent
    extends AbstractSellDealerDiscountComponent<SubsystemWindowSystemSellDealerDiscount, SubsystemWindowSystemSellDealerDiscountService>
    implements OnInit {

    filterMaterial: Observable<SelectItem[]>;

    constructor(private translate: TranslateService,
                private translatedSelectItemService: TranslatedSelectItemService,
                service: SubsystemWindowSystemSellDealerDiscountService,
                errorHandler: CommonErrorHandler,
                changeDetector: ChangeDetectorRef) {
        super(service, errorHandler, changeDetector);
    }

    ngOnInit(): void {
        this.filterMaterial = this.translatedSelectItemService.buildUnsortedDropdown(MaterialType, 'MATERIAL.',
            '');
        super.ngOnInit();
    }

    getExtraColumns(): DataTableColumn[] {
        return DataTableColumnBuilder.create()
            .add('windowSystemName.' + this.translate.currentLang, 'SELL_DEALER_DISCOUNT.FORM.WINDOW_SYSTEM_NAME')
            .makeFilterable()
            .setFilterMatchMode('contains')
            .add('windowSystemMaterial', 'SELL_DEALER_DISCOUNT.FORM.WINDOW_SYSTEM_MATERIAL')
            .makeFilterable()
            .setFilterMatchMode('equals')
            .setFilterValues(<any>this.filterMaterial)
            .build();
    }

    formatExtraColumn(column: DataTableColumn, item: SubsystemWindowSystemSellDealerDiscount): Observable<any> {
        switch (column.field) {
            case 'windowSystemMaterial':
                return this.translate.get('MATERIAL.' + item.windowSystemMaterial);
            default:
                break;
        }
        return super.formatExtraColumn(column, item);
    }

    isGroupValueVisible(): boolean {
        return true;
    }
}
