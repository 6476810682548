export class PolygonPoint {
    x: number;
    y: number;
    isArc: boolean;

    constructor(x: number, y: number, isArc: boolean) {
        this.x = x;
        this.y = y;
        this.isArc = isArc;
    }
}

export class PolygonPointUtil {
    public static toNumbersArray(array: PolygonPoint[]): number[] {
        return [].concat.apply([], array.map(p => [p.x, p.y]));
    }

    public static toPolygonPoints(array: number[]): PolygonPoint[] {
        let result = [];
        for (let j = 0; j < array.length; j += 2) {
            result.push(new PolygonPoint(array[j], array[j + 1], false));
        }
        return result;
    }
}
