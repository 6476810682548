import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {TranslateService} from "@ngx-translate/core";
import {SupplierInterface} from "../../../../../window-designer/catalog-data/supplier-interface";
import {GateSystem} from "../../../window-system/gate-system/gate-system";
import {GateData} from '../../gate-editor/gate-data';
import {MenuItem} from "primeng/api/menuitem";
import * as _ from "underscore";
import {AddProductDialogDataProviderComponent} from "./add-product-dialog-data-provider.component";

@Component({
    selector: 'app-add-gate-dialog',
    templateUrl: './add-product-dialog-data-provider.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddGateDialogComponent extends AddProductDialogDataProviderComponent<GateSystem> implements OnInit {

    @Input() data: GateData;
    @Input() suppliers: SupplierInterface[];

    tableHeader = 'OFFER.TABS.SECTION.GATE.GATE';
    dropdownSelectLabel = 'WINDOW-SYSTEM-DEFINITION.FORM.ROOF_SYSTEM_FUNCTION';
    submitButtonLabel = 'OFFER.DRAWING.GATE_ADD';

    constructor(translate: TranslateService,
                domSanitizer: DomSanitizer,
                changeDetector: ChangeDetectorRef) {
        super(translate, domSanitizer, changeDetector);
    }

    ngOnInit() {
        this.initTabs(this.suppliers, this.items);
        let selected: GateSystem;
        if (this.data && this.data.gateSystemId) {
            selected = this.items.find(item => item.id === this.data.gateSystemId);
        } else {
            selected = _.chain(this.items)
                .sortBy(item => item.sortIndex)
                .first()
                .value();
        }
        this.handleTabChange(selected.supplier);
        this.handleDropdownChange(null);
        this.handleValueChange(selected);
        this.changeDetector.markForCheck();
    }

    private initTabs(suppliers: SupplierInterface[], gateSystems: GateSystem[]): void {
        this.tabItems = _.chain(suppliers)
            .sortBy(supplier => Math.min(...gateSystems.filter(gs => gs.supplier.id === supplier.id).map(gs => gs.sortIndex)))
            .map(descriptor => this.prepareTabMenuItem(descriptor))
            .value();
    }

    private prepareTabMenuItem(supplier: SupplierInterface): MenuItem {
        return {
            label: supplier.name[this.translate.currentLang],
            icon: 'fa fa-windows',
            command: () => this.handleTabChange(supplier),
            disabled: false,
            tabindex: this.getEntityTabIndex(supplier)
        };
    }

    private getEntityTabIndex(supplier: SupplierInterface): string {
        return "" + supplier.id;
    }

    handleTabChange(supplier: SupplierInterface): void {
        this.activeTabItem = this.tabItems.find(tab => tab.tabindex === this.getEntityTabIndex(supplier));
        this.initDropdown();
        this.handleDropdownChange(null);
    }

    public initDropdown(): void {
    }

    handleDropdownChange(dropdownValue: number): void {
        let filteredItems = _.chain(this.items || [])
            .filter(item => this.getEntityTabIndex(item.supplier) === this.activeTabItem.tabindex)
            .sortBy(item => item.sortIndex)
            .value();
        this.tableOptions = filteredItems;
        for (let item of filteredItems) {
            this.tableOptionNames[item.id] = item.name[this.translate.currentLang];
        }
        let selectedItem = _.chain(filteredItems).first().value();
        this.handleValueChange(selectedItem);
    }

    handleValueChange(item: GateSystem): void {
        this.selectedTableOption = item;
        this.selectedValueImage = null;
        if (this.selectedTableOption != undefined) {
            this.largeImageId = this.selectedTableOption.id;
            this.selectedValueDescription = this.prepareDescription(item.description);
            if (item.image) {
                this.selectedValueImage = this.domSanitizer.bypassSecurityTrustUrl(item.image);
            }
        }
    }
}
