import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {RoofGlazingPackage} from './roof-glazing-package';

@Injectable()
export class RoofGlazingPackageService implements CrudService<RoofGlazingPackage> {

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string,
             sortOrder: number): Observable<Listing<RoofGlazingPackage>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<RoofGlazingPackage>>('roofGlazingPackages', {params: params});
    }

    getItem(itemId: number): Observable<RoofGlazingPackage> {
        return this.http.get<RoofGlazingPackage>(`roofGlazingPackages/${itemId}`);
    }

    getImage(itemId: number): Observable<File> {
        return this.http.get(`roofGlazingPackages/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    saveItem(item: RoofGlazingPackage, file): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('roofGlazingPackageDto', new Blob([body], {
            type: 'application/json'
        }));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post('roofGlazingPackages', formData, {observe: 'response'}).pipe(
            map(response => item.id != undefined ? item.id : this.dataServiceHelper.getNewItemId('roofGlazingPackages', response)));
    }

    addItem(item: RoofGlazingPackage): Observable<number> {
        return this.saveItem(item, undefined);
    }

    editItem(itemId: number, item: RoofGlazingPackage): Observable<number> {
        return this.saveItem(item, undefined);
    }

    copy(id: number, item: RoofGlazingPackage, file: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('roofGlazingPackageDto', new Blob([body], {
            type: 'application/json'
        }));

        if (file) {
            formData.append('file', file);
        }
        return this.http.post(`roofGlazingPackages/${id}/copy`, formData, {observe: 'response'}).pipe(
            map(response => item.id != undefined ? item.id : this.dataServiceHelper.getNewItemId('roofGlazingPackages', response)));
    }

    validate(item: RoofGlazingPackage): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post('roofGlazingPackages/validate', item));
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>('roofGlazingPackages/names', {params: params});
    }
}
