import {Directive, ElementRef, Inject, Input, NgZone, OnDestroy, OnInit, PLATFORM_ID, Renderer2, ViewContainerRef} from '@angular/core';
import {PrimeNGConfig} from 'primeng/api';
import {Tooltip as PrimeTooltip} from 'primeng/tooltip';
import {Subscription} from 'rxjs';
import {AbstractInputComponent} from '../abstract-input/abstract-input.component';

@Directive({
    selector: '[appInputTooltip]'
})
export class InputTooltipDirective implements OnInit, OnDestroy {

    @Input('appInputTooltip')
    text: string;

    // tslint:disable-next-line:no-input-rename
    @Input('appInputTooltipComponentHost')
    input: AbstractInputComponent;

    private readonly tooltip: PrimeTooltip;
    private readonly subscriptions: Subscription[] = [];

    constructor(@Inject(PLATFORM_ID) platformId: any,
                el: ElementRef,
                zone: NgZone,
                primengConfig: PrimeNGConfig,
                renderer: Renderer2,
                viewContainer: ViewContainerRef) {
        this.tooltip = new PrimeTooltip(platformId, el, zone, primengConfig, renderer, viewContainer);
    }

    ngOnInit(): void {
        if (this.input == undefined || !(this.input instanceof AbstractInputComponent)) {
            throw Error('appInputTooltip can only be used on input components');
        }
        this.subscriptions.push(this.input.onFocus.subscribe(() => {
            this.tooltip.content = this.text;
            this.tooltip.setOption({tooltipLabel: this.text});
            this.tooltip.show();
            // hack primeng tooltip style a bit
            (this.tooltip.tooltipText as HTMLDivElement).style.wordWrap = 'break-word';
        }));
        this.subscriptions.push(this.input.onBlur.subscribe(() => {
            this.tooltip.hide();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(sub => sub.unsubscribe());
        this.tooltip.ngOnDestroy();
    }
}
