import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {finalize, map, mergeMap} from 'rxjs/operators';
import {Permissions} from '../../auth/permission.service';
import {StorageService} from '../../auth/storage.service';
import {CommonErrorHandler} from '../../common/CommonErrorHandler';
import {CrudCommonComponent} from '../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../common/dataServiceHelper';
import {CrudComponent} from '../../common/service/crud.component';
import {DataTableColumnBuilder} from '../../common/service/data.table.column.builder';
import {ExportComponent} from '../../common/service/export.component';
import {TranslatedSelectItemService} from '../../common/service/translated-select-item.service';
import {Currencies} from '../../currencies';
import {UserActivityCustomFilter} from "../admin-panel/user-activity/user-activity-data";
import {SubsystemGroupService} from '../subsystem-group/subsystem-group.service';
import {User} from '../user/user';
import {Subsystem} from './subsystem';
import {SubsystemService} from './subsystem.service';
import {TabularAddress} from './tabularAddress';
import {AccessData} from '../AccessData';

@Component({
    selector: 'app-subsystem',
    templateUrl: './subsystem.component.html',
    styleUrls: ['../shared-styles.css'],
    providers: [SubsystemService, SubsystemGroupService, DataServiceHelper, ExportComponent, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemComponent extends CrudComponent implements OnInit, OnDestroy {

    private ignoreStoredFilter: boolean;

    items: Subsystem[];
    hasNonGroupProfitMargins: { [subsystemId: number]: boolean } = {};
    editedItem: Subsystem;
    editedAddresses: TabularAddress[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    displayDialog = false;
    filterMine = true;
    filterSubsystemGroupId: number;
    selectedSubsystem: Subsystem;
    disabledFields: boolean;
    filterActive: SelectItem[];
    defaultActiveFilter: SelectItem;
    @ViewChild('dt', {static: true})
    datatable: DataTable;
    filterCurrency: Observable<SelectItem[]>;
    showReportWizard = false;
    criteriaTreeFilter: UserActivityCustomFilter;

    subsystemGroups: SelectItem[];
    clientManagers: User[];
    subClientManagers: User[];

    constructor(public permissions: Permissions,
                private router: Router,
                private route: ActivatedRoute,
                private subsystemService: SubsystemService,
                private subsystemGroupService: SubsystemGroupService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private errors: CommonErrorHandler,
                private storageService: StorageService,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'SubsystemComponent', false);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    ngOnInit() {
        super.ngOnInit();
        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.filterCurrency = this.translatedSelectItemService.buildSortedDropdown(Currencies, '', '');
        if (this.route.snapshot.queryParamMap.has('subsystemGroupId')) {
            this.filterMine = false;
            this.filterSubsystemGroupId = +this.route.snapshot.queryParamMap.get('subsystemGroupId');
        }
        this.createTable();
        this.resetDefaultFilterSelection();
        this.subsystemGroupService.getItems(undefined, undefined, {}, 'id', 1).subscribe(subsystemGroups => {
            this.subsystemGroups =
                subsystemGroups.data.map(subsystemGroup => ({label: subsystemGroup.name, value: subsystemGroup.id}))
                    .sort((a, b) => a.label.localeCompare(b.label));
        });
    }

    ngOnDestroy() {
        this.hotkeysService.remove(this.enterHotkey);
        super.ngOnDestroy();
    }

    getItemWithImage(itemId: number) {
        forkJoin({
            subsystem: this.subsystemService.getItem(itemId),
            addresses: this.subsystemService.getAddresses(itemId),
            clientManagers: this.subsystemService.getClientManagers()
        }).subscribe({
            next: data => {
                this.editedItem = data.subsystem;
                this.editedAddresses = data.addresses;
                this.clientManagers = data.clientManagers.data;
                this.subClientManagers = this.editedItem.clientManager ? this.clientManagers.filter(manager =>
                    manager.id !== this.editedItem.clientManager.id) : this.clientManagers;
                this.setDisplayDialog(true);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        this.applyExtraHeaderFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const currentUserFilter = filter.filters['mine'];
        if (currentUserFilter != undefined) {
            this.filterMine = '' + currentUserFilter.value === 'true';
        }
        if (filter.filters['subsystemGroupId'] != undefined) {
            filter.filters['subsystemGroupId'] = undefined;
        }
    }

    private resetDefaultFilterSelection() {
        if (!this.ignoreStoredFilter) {
            const event = this.retrieveStoredLazyLoadEvent(this.storageService);
            if (event != undefined) {
                this.applySavedFilter(event);
            }
        }
    }

    private applyMineFilter(event: LazyLoadEvent) {
        event.filters['mine'] = {value: this.filterMine};
    }

    private applySubsystemGroupFilter(event: LazyLoadEvent): void {
        if (this.filterSubsystemGroupId != undefined) {
            event.filters['subsystemGroupId'] = {value: this.filterSubsystemGroupId};
        } else {
            event.filters['subsystemGroupId'] = undefined;
        }
    }

    loadItemsLazy(event: LazyLoadEvent) {
        super.loadItemsLazy(event);
        this.applyMineFilter(event);
        this.applySubsystemGroupFilter(event);
        this.storeLazyLoadEvent(event, this.storageService);
        return this.subsystemService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder).pipe(
            finalize(() => this.hideDataLoadingIndicator()),
            mergeMap(data => forkJoin({
                data: of(data),
                hasNonGroupProfitMargins: this.subsystemService.hasNonGroupProfitMargins(data.data.map(s => s.id))
            }))
        ).subscribe({
            next: data => {
                this.totalRecords = data.data.totalRecords;
                this.items = data.data.data;
                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                this.selectedSubsystem = this.restoreSelectionAfterLoad(this.selectedSubsystem, this.items, event);
                this.hasNonGroupProfitMargins = data.hasNonGroupProfitMargins;
            },
            error: error => {
                console.error('SubsystemComponent `getPage` error:', error);
                this.errors.handle(error);
            },
            complete: () => {
                console.info('SubsystemComponent `getPage` completed!');
                this.changeDetector.markForCheck();
            }
        });
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.prepareDataForSubsystem(event.data.id);
        this.disabledFields = !this.permissions.canEditSubsystem();
        this.keepSelectedItemIndex(event);
    }

    showDialogToAdd() {
        this.router.navigate(['/features/create-subsystem']);
    }

    cancel() {
        this.setDisplayDialog(false);
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedSubsystem);
    }

    private createTable() {
        let shownColumns = this.getShownColumns();
        super.init(DataTableColumnBuilder.createWithShownColumnsArray(shownColumns)
            .add('id', 'SUBSYSTEM-DETAILS.FORM.ID', true)
            .add('name', 'SUBSYSTEM-DETAILS.FORM.NAME', true)
            .add('symbol', 'SUBSYSTEM-DETAILS.FORM.SYMBOL', true)
            .add('identificator', 'SUBSYSTEM-DETAILS.FORM.IDENTIFICATOR', true)
            .add('phoneNumber', 'SUBSYSTEM-DETAILS.FORM.PHONE_NUMBER', false)
            .add('email', 'SUBSYSTEM-DETAILS.FORM.EMAIL', false)
            .add('defaultCurrency', 'SUBSYSTEM-DETAILS.FORM.DEFAULT_CURRENCY', true).makeFilterable().makeSortable()
            .add('clientManager.login', 'SUBSYSTEM-DETAILS.FORM.CLIENT_MANAGER_LOGIN', true)
            .add('subClientManager.login', 'SUBSYSTEM-DETAILS.FORM.SUB_CLIENT_MANAGER_LOGIN', true)
            .add('subsystemGroupName', 'SUBSYSTEM-DETAILS.FORM.SUBSYSTEM_GROUP', true)
            .add('subsystemExpirationDate', 'SUBSYSTEM-DETAILS.FORM.EXPIRATION_DATE', true).makeFilterable().makeSortable()
            .add('active', 'SUBSYSTEM-DETAILS.FORM.STATUS', true).setFilterValues(this.filterActive)
            .setDefaultFilterValue(this.defaultActiveFilter)
            .build());
    }

    prepareDataForSubsystem(subsystemId: number) {
        this.getItemWithImage(subsystemId);
    }

    handleSave() {
        this.cleanUpAndReload();
        this.showSuccessMessage();
    }

    submit(): void {
    }

    showSuccessMessage() {
        this.growlMessageController.info('SUBSYSTEM-DETAILS.SUBSYSTEM_UPDATED');
    }

    cleanUpAndReload() {
        this.reloadDatatable();
        this.setDisplayDialog(false);
    }

    getExportData(): Observable<object[]> {
        const event = this.getDatatable().createLazyLoadMetadata();
        return this.subsystemService.getItems(0, null, event.filters, event.sortField, event.sortOrder).pipe(
            map(list => list.data));
    }

    private setDisplayDialog(display: boolean): void {
        this.displayDialog = display;
        this.changeDetector.markForCheck();
    }

    get displayShowAllButton(): boolean {
        return this.filterMine || this.filterSubsystemGroupId != undefined;
    }

    showAllSubsystems(): void {
        this.filterMine = false;
        this.filterSubsystemGroupId = undefined;
        this.router.navigate(['.'], {relativeTo: this.route, replaceUrl: true});
        this.reloadDatatable();
    }

    showMySubsystems(): void {
        this.filterMine = true;
        this.reloadDatatable();
    }

    goToNotes(subsystemId: number) {
        this.router.navigate([AccessData.path.subsystemNotes(subsystemId.toString())]);
    }

    handleCustomFilterChange(): void {
        this.getDatatable().setFilterValue(this.criteriaTreeFilter.criteriaTreeDateRange, 'criteriaTreeDateRange', 'dateRange');
        this.getDatatable().setFilterValue(this.criteriaTreeFilter.criteriaTree, 'criteriaTree', 'contains');
        this.datatable._filter();
    }
}
