import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {AbstractSellDealerDiscountService} from '../../price-lists/abstract-sell-dealer-discount/abstract-sell-dealer-discount.service';
import {SubsystemGroupConfigurableAddonSellDealerDiscount} from './subsystem-group-configurable-addon-sell-dealer-discount';

@Injectable()
export class SubsystemGroupConfigurableAddonSellDealerDiscountService
    extends AbstractSellDealerDiscountService<SubsystemGroupConfigurableAddonSellDealerDiscount> {

    constructor(http: HttpClient) {
        super(http, SubsystemGroupConfigurableAddonSellDealerDiscount);
    }

    getApiBaseUrl(): string {
        return 'sellDealerDiscount/subsystemGroup/configurableAddon';
    }

    addSearchParams(params: { [param: string]: string | string[] }, id: number, groupId: number): void {
        if (id != undefined) {
            params['subsystemGroupId'] = '' + id;
        }
    }
}
