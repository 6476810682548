import * as _ from 'underscore';
import {EnumUtils} from '../../shared/enum-utils';
import {CompositionType} from "../drawing-data/CompositionType";
import {ProfilesComposition} from "../drawing-data/ProfilesComposition";
import {WindowNeighbourCheck} from "../window-neighbour-check";
import {SubwindowAttributes} from "../window-types/subwindow-attributes";
import {SubWindowDataTypeUtil} from "../window-types/subwindow-data-type-util";
import {SubWindowTypeCode} from "../window-types/subwindow-type-code";
import {WindowAttributes} from "../window-types/window-attributes";
import {WindowTypeCode} from "../window-types/window-type-code";
import {ErrorNames} from "./ErrorNames";
import {OpeningDirectionUtils} from "../window-types/opening-direction-utils";

export class WindowTypeCodeParser {

    private static parseOptions(options: string, attributes: WindowAttributes | SubwindowAttributes,
                                func: ((option: string,
                                        attributes: WindowAttributes | SubwindowAttributes) => void)): void {
        if (options && options.length > 0) {
            let i = options.length;
            while (i--) {
                func(options.charAt(i), attributes);
            }
        }
    }

    private static parseWindowOption(option: string, attributes: WindowAttributes): void {
        switch (option) {
            case 'V':
                attributes.vertical = true;
                return;
            case 'O':
                attributes.eliptical = true;
                return;
            case 'S':
                attributes.movablePost = true;
                return;
            default:
                console.warn("Option " + option + " is not supported!");
                return;
        }
    }

    private static parseSubwindowOption(option: string, subwindowAttributes: SubwindowAttributes): void {
        switch (option) {
            case 'W':
                subwindowAttributes.isLeading = true;
                return;
            default:
                console.warn("Option " + option + " is not supported!");
                return;
        }
    }

    private static parseCompositions(typeCodeA: SubWindowTypeCode, typeCodeB: SubWindowTypeCode,
                                     attributes: WindowAttributes, index: number): void {
        let slidingTypeCodes = SubWindowDataTypeUtil.getSlidingTypes();
        let previous = attributes.profilesComposition[index];
        let next = attributes.profilesComposition[index + 1];
        let set = (comp: ProfilesComposition, value: CompositionType, forNext: boolean, forVertical: boolean) => {
            comp.left = forNext && !forVertical ? value : comp.left;
            comp.right = !forNext && !forVertical ? value : comp.right;
            comp.top = forNext && forVertical ? value : comp.top;
            comp.bottom = !forNext && forVertical ? value : comp.bottom;
        };
        // both sliding, but away from each other only
        if (_.difference([typeCodeA, typeCodeB], slidingTypeCodes).length === 0 &&
            OpeningDirectionUtils.areSubwindowsOpeningInOppositeDirections(typeCodeA, typeCodeB)) {
            set(previous, CompositionType.SLIDING_CONTACT, false, attributes.vertical);
            set(next, CompositionType.SLIDING_CONTACT, true, attributes.vertical);
            return;
        }
        // both sliding the same direction, or only one sliding
        if (_.difference([typeCodeA, typeCodeB], slidingTypeCodes).length < 2) {
            set(previous, CompositionType.SLIDING_OVERLAP, false, attributes.vertical);
            set(next, CompositionType.SLIDING_OVERLAP, true, attributes.vertical);
            return;
        }
        // opening between
        if (attributes.movablePost && WindowNeighbourCheck.isLeftOpening(typeCodeA) &&
            WindowNeighbourCheck.isRightOpening(typeCodeB)) {
            set(previous, CompositionType.MOVABLE_POST, false, attributes.vertical);
            set(next, CompositionType.MOVABLE_POST, true, attributes.vertical);
        }
    }

    private static parseSubwindow(typeCodeString: string, attributes: WindowAttributes, index: number): void {
        let typeCodeWithOptions = typeCodeString.split('_');
        attributes.subwindows[index].type = EnumUtils.fromString(SubWindowTypeCode, typeCodeWithOptions[0]);
        this.parseOptions(typeCodeWithOptions[1], attributes.subwindows[index], this.parseSubwindowOption);
    }

    public static parseTypeCode(typeCode: WindowTypeCode): WindowAttributes {
        if (typeCode) {
            let typeCodeString = WindowTypeCode[typeCode];
            if (!typeCodeString) {
                throw new Error("Nieobsługiwany typ handlowy: " + typeCodeString);
            }
            let subwindowsWithOptions = typeCodeString.split('$');
            let subwindows = subwindowsWithOptions[0].split('__');
            if (subwindows.length < 1) {
                throw new Error("Cannot parse business type code with no subwindows.");
            }
            const attributes = new WindowAttributes(subwindows.length);
            attributes.typeCode = typeCode;
            this.parseOptions(subwindowsWithOptions[1], attributes, this.parseWindowOption);
            subwindows.forEach((sw, index) => this.parseSubwindow(sw, attributes, index));
            for (let i = 0; i < subwindows.length - 1; i++) {
                this.parseCompositions(attributes.subwindows[i].type, attributes.subwindows[i + 1].type, attributes, i);
            }
            return attributes;
        }
        return new WindowAttributes(0);
    }

    public static getTypeName(typeCode: WindowTypeCode): string {
        let attributes = WindowTypeCodeParser.parseTypeCode(typeCode);
        let name = attributes.eliptical ? "O." : "";
        for (let i = 0; i < attributes.subwindows.length; i++) {
            name += attributes.subwindows[i].type;
            name += attributes.subwindows[i].isLeading ? ".W" : "";
            if (i < attributes.subwindows.length - 1) {
                name += attributes.vertical ? "/" : "_";
            }
        }
        name += attributes.movablePost ? ".SR" : "";
        return name;
    }

    public static buildTypeCodeFromAttributes(attributes: WindowAttributes): WindowTypeCode {
        let newTypeString = "";
        for (let i = 0; i < attributes.subwindows.length; i++) {
            newTypeString += SubWindowTypeCode[attributes.subwindows[i].type];
            if (attributes.subwindows[i].isLeading) {
                newTypeString += "_W";
            }
            if (i <  attributes.subwindows.length - 1) {
                newTypeString += "__";
            }
        }
        if (attributes.movablePost || attributes.vertical || attributes.eliptical) {
            newTypeString += "$";
            if (attributes.movablePost) {
                newTypeString += "S";
            }
            if (attributes.vertical) {
                newTypeString += "V";
            }
            if (attributes.eliptical) {
                newTypeString += "O";
            }
        }
        let newType = EnumUtils.fromString(WindowTypeCode, newTypeString);
        if (newType == undefined) {
            let noMirrorTypeError = new Error(
                "WindowTypeCodeParser.changeTypeCodeForNewAttributes: No mirror business type found for: " +
                newType);
            noMirrorTypeError.name = ErrorNames.NO_MIRROR_TYPE_FOR_BUSINNSS_TYPE;
            throw noMirrorTypeError;
        }
        attributes.typeCode = newType;
        return newType;
    }
}
