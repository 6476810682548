<div class="container-with-toolbar">
    <ng-template #toolbar>
        <app-toolbar-button buttonId="addPosition" *ngIf="canEdit"
                            icon="add" label="{{ 'OFFER.POSITIONS.ADD_POSITION' | translate}}"
                            [menuElements]="addPositionMenuElements"
                            (menuElementSelected)="addPositionMenuElementSelected($event)"></app-toolbar-button>

        <div class="separator"></div>

        <app-toolbar-button buttonId="printButton" *ngIf="printButtonVisible()"
                            icon="print" label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HEADER' | translate }}"
                            (onClick)="showPrintDialog()"></app-toolbar-button>

        <app-toolbar-button buttonId="showAnnotations" *ngIf="(canEditProdOrder) && isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                            icon="subject" label="{{ 'OFFER.POSITIONS.ANNOTATIONS' | translate}}"
                            (onClick)="showAnnotations()" [hotkey]="annotationsHotkey"></app-toolbar-button>

        <div class="separator"></div>

        <app-toolbar-button buttonId="showSummary" *ngIf="isShowSummaryEnabled()"
                            icon="beenhere" label="{{ 'OFFER.POSITIONS.SUMMARY' | translate}}"
                            (onClick)="showSummary()" [hotkey]="showSummaryHotkey"></app-toolbar-button>

        <app-toolbar-button icon="monetization_on"
                            *ngIf="canEdit && isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']}) && !offer.pricingOutdated"
                            label="{{ (offer.rabate ? offer.rabate + '% ' : '') + ('OFFER.POSITIONS.DIALOGS.RABATE_MARGINS.RABATE' | translate) }}"
                            (onClick)="openRabateDialog(true)" ></app-toolbar-button>

        <app-toolbar-button buttonId="globalChangePricing" *ngIf="canEdit"
                            icon="edit" label="{{ 'OFFER.POSITIONS.GLOBAL_CHANGE_PRICING' | translate}}"
                            [disabled]="disableGlobalChangePircingButton"
                            [menuElements]="globalChangePricingItems"
                            (menuElementSelected)="globalChangeMenuElementSelected($event)"></app-toolbar-button>

        <app-toolbar-button *ngIf="isUpdatePricingEnabled()"
                            icon="report_problem" label="{{ 'OFFER.POSITIONS.DIALOGS.UPDATE_PRICINGS.HEADER' | translate}}"
                            (onClick)="openUpdatePricingsDialog(true)" [hotkey]="globalPricingsUpdateHotkey"></app-toolbar-button>

        <div class="separator"></div>

        <app-toolbar-button buttonId="globalChange" *ngIf="canEdit"
                            icon="public" label="{{ 'OFFER.POSITIONS.GLOBAL_CHANGE' | translate}}"
                            [menuElements]="globalChangeItems" [disabled]="disableGlobalChangeButton"
                            (menuElementSelected)="globalChangeMenuElementSelected($event)"></app-toolbar-button>

        <app-toolbar-button buttonId="removePositions" *ngIf="isDeletePositionButtonEnabled()"
                            icon="delete" label="{{ 'OFFER.POSITIONS.REMOVE_POSITION' | translate}}"
                            (onClick)="removeSelectedPositions()" [hotkey]="deleteHotkey"></app-toolbar-button>

        <app-toolbar-button *ngIf="comparePositionsButtonVisible()"
                            icon="swap_horiz" label="{{ 'OFFER.POSITIONS.COMPARE' | translate}}"
                            (onClick)="comparePositions()"></app-toolbar-button>

        <app-toolbar-button buttonId="shipping" *ngIf="showShippingButton"
                            icon="local_shipping" label="{{ 'OFFER.POSITIONS.SHIPPING' | translate}}"
                            (onClick)="addToShippingSimulation(false)" [hotkey]="shippingHotkey"></app-toolbar-button>

        <app-toolbar-button buttonId="complaint" *ngIf="createComplaintButtonVisible()"
                            icon="description" label="{{ 'OFFER.POSITIONS.CREATE_COMPLAINT' | translate}}"
                            (onClick)="createComplaint()"></app-toolbar-button>

        <app-toolbar-button icon="description" *ngIf="!isComplaint && hasCorrection()"
                           label="{{ 'OFFER.POSITIONS.SHOW_CORRECTION' | translate }}"
                           (onClick)="navigateToCorrection()"></app-toolbar-button>

        <app-toolbar-button icon="monetization_on" *ngIf="isProductionOrder && productionOrder?.correctionId != undefined"
                            label="{{ 'OFFER.POSITIONS.SHOW_CORRECTION' | translate }}"
                            (onClick)="navigateToProdOrderCorrection()"></app-toolbar-button>

        <app-toolbar-button icon="monetization_on" *ngIf="canEditProdOrder"
                            label="{{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_DEALER_DISCOUNT' | translate }}"
                            (onClick)="setShowChangeDealerDiscountDialog(true)" ></app-toolbar-button>

        <app-toolbar-button icon="monetization_on" *ngIf="canEditProdOrder && suppliers[0]?.windowSupplier"
                            label="{{ 'OFFER.PRODUCTION_ORDERS.ACTIONS.CHANGE_SYSTEM_DEALER_DISCOUNT' | translate }}"
                            (onClick)="setShowChangeSystemDealerDiscountDialog()" ></app-toolbar-button>

        <div class="spacer"></div>

        <app-toolbar-button buttonId="conjunction" *ngIf="!isProductionOrder && !isComplaint"
                            icon="apartment" label="{{ 'OFFER.POSITIONS.CONJUNCTION.HEADER' | translate}}"
                            (onClick)="openConjunctionsDialog()" [hotkey]="conjunctionHotkey"></app-toolbar-button>

        <app-toolbar-button buttonId="editOffer" *ngIf="isEditOfferEnabled()"
                            icon="settings" label="{{ 'OFFER.POSITIONS.EDIT_OFFER' | translate}}"
                            (onClick)="editOffer()" [hotkey]="editHotkey"></app-toolbar-button>

        <ng-container *ngIf="!isProductionOrder">
            <app-toolbar-button buttonId="restoreVersionButton" *ngIf="restoreItems.length === 0" icon="restore"
                                label="{{ 'OFFER.POSITIONS.ARCHIVED_VERSIONS.HEADER' | translate}}"
                                additionalTooltip="{{ 'OFFER.POSITIONS.ARCHIVED_VERSIONS.NO_ARCHIVED_VERSIONS' | translate }}"
                                [disabled]="true"></app-toolbar-button>

            <app-toolbar-button buttonId="restoreVersionButton" *ngIf="restoreItems.length === 1" icon="restore"
                                label="{{ restoreItems[0].translationKey | translate}}"
                                (onClick)="restoreMenuElementSelected(restoreItems[0])"></app-toolbar-button>

            <app-toolbar-button buttonId="restoreVersionButton" *ngIf="restoreItems.length > 1" icon="restore"
                                label="{{ 'OFFER.POSITIONS.ARCHIVED_VERSIONS.HEADER' | translate}}"
                                [menuElements]="restoreItems"
                                (menuElementSelected)="restoreMenuElementSelected($event)"></app-toolbar-button>
        </ng-container>

        <app-toolbar-button buttonId="goBack" icon="exit_to_app" label="{{ 'GENERAL.GO_BACK' | translate}}"
                            (onClick)="navigateBack()"></app-toolbar-button>

        <app-toolbar-button *ngIf="canEdit" buttonId="archive_offer"
                            (onClick)="archiveOffer()"
                            [disabled]="!hasDifferencesFromLastArchivedVersion" icon="archive"
                            label="{{ 'OFFER.POSITIONS.ARCHIVE_VERSION' | translate }}"></app-toolbar-button>

    </ng-template>
    <app-shipping-simulation-sidebar #shippingSidebar [currentRoute]="'/features/offer/' + offer?.id + '/position'"
                                     (onHide)="shippingSidebarHidden()"
                                     (onCalculationStart)="shippingCalculationStarted()"
                                     (onCalculationFinish)="shippingCalculationFinished()"></app-shipping-simulation-sidebar>
    <app-shipping-simulation-float-button #shippingFloatButton
                                          [calculationInProgress]="shippingCalculationInProgress"
                                          (onClick)="showShippingSidebar()"></app-shipping-simulation-float-button>
    <app-upselling-sidebar #upsellingSidebar
                           [upsellingResults]="upsellingResults"
                           [formatPrice]="formatOfferPriceFunction"
                           [offerId]="id"
                           (applyUpselling)="applyUpselling($event)"></app-upselling-sidebar>
    <app-upselling-float-button #upsellingFloatButton
                                [bottomPosition]="shippingFloatButton.visible ? 132 : 32"
                                [visible]="canEdit && upsellingResults && upsellingResults.length > 0"
                                (onClick)="showUpsellingSidebar()"></app-upselling-float-button>

    <div *ngIf="isProductionOrder" class="list-content">
        <div *ngIf="ready" class="position-list-header" style="min-height: 88px">
            <div id="left">
                <div class="table-header major-element">
                    <div class="title">
                        <span>{{ productionOrder?.orderNumber }}</span>
                    </div>
                </div>
            </div>

            <div class="mid">
                <div class="centered-table-cell">
                <span class="table-header-buttons">
                    <app-simple-button buttonId="toggle-filter-button"
                                       label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                       icon="filter_list" type="filter"
                                       (onClick)="showHideFilters()"></app-simple-button>

                    <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}"
                                   id="positionListShownColumns" [displaySelectedLabel]="false"
                                   [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                                   (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>
                    <button-with-menu buttonId="currencySelectButton" [buttonLabel]="selectedCurrency"
                                      rightButtonIcon="keyboard_arrow_down" buttonType="action" [buttonSize]="32"
                                      [menuType]="menuType.BOTTOM_RIGHT" [menuElements]="availableCurrencies"
                                      (menuElementSelected)="handleCurrencyMenuElementSelected($event)"></button-with-menu>
                </span>
                </div>
            </div>
        </div>
        <div class="datatable-container">
            <p-dataTable #productionOrderPositionTable [value]="tableData.positions" [rows]="tableData.rowsPerPage"
                         [rowsPerPageOptions]="limitedRowsPerPageOptions"
                         [paginator]="true" selectionMode="single"
                         (selectionChange)="clickedRowChange($event)" (onRowKeyDown)="handleRowKeyDown($event)"
                         [(selection)]="tableData.selectedPosition" [lazy]="true"
                         [totalRecords]="tableData.totalRecords" [responsive]="true"
                         (onLazyLoad)="loadProdOrderTableItems($event)"
                         (onRowDblclick)="doOnRowSelect($event)"
                         [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
                <p-column [style]="{'vertical-align':'middle'}">
                    <ng-template pTemplate="header">
                        <app-tristate-checkbox [(ngModel)]="tableData.allSelectedState" (ngModelChange)="selectAllChange()"></app-tristate-checkbox>
                    </ng-template>
                    <ng-template let-position="rowData" pTemplate="body">
                <span class="flex-row">
                    <app-checkbox [ngModel]="isSelectedItem(position)"
                                  [disabled]="isPositionAlwaysReadOnly(position)"
                                  (ngModelChange)="selectItem(position)"></app-checkbox>
                    <i *ngIf="position.pricingOutdated" class="material-icons" title="{{'GENERAL.POSITION_PRICING_OUTDATED'|translate}}">report_problem</i>
                    <i *ngIf="positionHasTemporaryDealerDiscount(position)" class="material-icons" title="{{'OFFER.PRODUCTION_ORDERS.LIST.HAS_TEMP_DEALER_DISCOUNT'|translate}}">report_problem</i>
                </span>
                    </ng-template>
                </p-column>
                <p-column field="printOrder" sortable="true"
                          header="{{'OFFER.POSITIONS.FORM.PRINT_ORDER' | translate }}">
                </p-column>

                <ng-container *ngFor="let column of columns">
                    <p-column *ngIf="column.field == 'dimensions'
                          || column.field == 'venskaBuyPrice.vatPercent' || column.field == 'buyPrice.vatPercent'
                          || column.field == 'rabate'" [field]="column.field"
                              [header]="column.header | translate"
                              [sortable]="column.sortable" [filter]="column.filter" filterMatchMode="contains">
                    </p-column>
                    <p-column *ngIf="column.field == 'realDimensions'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="false" [filter]="false">
                    </p-column>
                    <p-column *ngIf="column.field == 'name'" [header]="column.header | translate" [sortable]="column.sortable"
                              [filter]="column.filter" filterMatchMode="contains" [field]="column.field">
                        <ng-template let-position="rowData" pTemplate="body">
                            <div>{{ position.name[userLang] | translate}}</div>
                        </ng-template>
                    </p-column>

                    <p-column *ngIf="column.field == 'quantity'" [header]="column.header | translate"
                              [sortable]="column.sortable"
                              [filter]="column.filter" filterMatchMode="contains" [field]="column.field"
                              filterType="number"
                              [filterNumericMinValue]="0" [filterAllowDecimals]="true" [filterNumericStep]="1">
                    </p-column>

                    <p-column *ngIf="column.field == 'unit'" header="{{'OFFER.POSITIONS.FORM.UNIT' | translate }}">
                        <ng-template let-position="rowData" pTemplate="body">
                            <div>{{getQuantityTypeForPosition(position) | translate}}</div>
                        </ng-template>
                    </p-column>

                    <p-column *ngIf="column.field == 'buyPrice.netValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.buyPrice.netValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.buyPrice.netValue)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'buyValue.netValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.buyValue.netValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.buyValue.netValue)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'buyPrice.grossValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.buyPrice.grossValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.buyPrice.grossValue)}} </span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'buyValue.grossValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.buyValue.grossValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.buyValue.grossValue)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'venskaBuyPrice.grossValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.venskaBuyPrice.grossValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.venskaBuyPrice.grossValue)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'venskaBuyPrice.netValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.venskaBuyPrice.netValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.venskaBuyPrice.netValue)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'venskaBuyValue.grossValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.venskaBuyValue.grossValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.venskaBuyValue.grossValue)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'venskaBuyValue.netValue'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.venskaBuyValue.netValue)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.venskaBuyValue.netValue)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'netVenskaIncome'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.netVenskaIncome)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.netVenskaIncome)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'grossVenskaIncome'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="true" [filter]="column.filter"
                              filterMatchMode="contains">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{formatPriceInSelectedCurrency(position.grossVenskaIncome)}}</span>
                            <span class="secondary-price" *ngIf="selectedCurrency != productionOrder.offerCurrency">{{formatPriceInOfferCurrency(position.grossVenskaIncome)}}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'dealerDiscount'"
                              [header]="column.header | translate"
                              [sortable]="column.sortable"
                              [filter]="column.filter" filterMatchMode="contains" [field]="column.field"
                              filterType="number"
                              [filterNumericMinValue]="0.0" [filterAllowDecimals]="true" [filterNumericStep]="0.1">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{ position.calculatedDealerDiscount != undefined ? (position.calculatedDealerDiscount + ' ('+ position.dealerDiscount + ')') : undefined }}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'tempDealerDiscount'"
                              [header]="column.header | translate"
                              [sortable]="column.sortable"
                              [filter]="column.filter" filterMatchMode="contains" [field]="column.field"
                              filterType="number"
                              [filterNumericMinValue]="0.0" [filterAllowDecimals]="true" [filterNumericStep]="0.1">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{ position.calculatedTempDealerDiscount != undefined ? (position.calculatedTempDealerDiscount + ' ('+ position.tempDealerDiscount + ')') : undefined }}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'tempSystemDealerDiscount'"
                              [header]="column.header | translate"
                              [sortable]="column.sortable"
                              [filter]="column.filter" filterMatchMode="contains" [field]="column.field"
                              filterType="number"
                              [filterNumericMinValue]="0.0" [filterAllowDecimals]="true" [filterNumericStep]="0.1">
                        <ng-template let-position="rowData" pTemplate="body">
                            <span>{{ position.calculatedTempSystemDealerDiscount != undefined ? (position.calculatedTempSystemDealerDiscount + ' ('+ position.tempSystemDealerDiscount + ')') : undefined }}</span>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="column.field == 'basePrice'" [field]="column.field"
                              [header]="column.header | translate" [sortable]="column.sortable"
                              [filter]="column.filter" filterType="contains">
                    </p-column>
                </ng-container>
                <ng-template pTemplate="paginatorLeft">
                    <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: tableData.fromRecord, to: tableData.toRecord, total: tableData.totalRecords} }}</span>
                </ng-template>
            </p-dataTable>
        </div>

    </div>
    <div *ngIf="!isProductionOrder" class="list-content">
        <div class="cover-behind-tabview-nav"></div>
        <div *ngIf="ready" class="position-list-header" style="min-height: 88px">
            <div class="position-list-header-row">
                <div id="left">
                    <div class="positions-primary-table-header major-element">
                        <div class="title">
                            <span class="offerNumberHeader">{{getHeaderNumber()}}</span>
                            <span *ngIf="!isComplaint && offer != undefined && offer.archiveVersionNumber">
                                ({{'OFFER.POSITIONS.VERSION' | translate}} {{offer.archiveVersionNumber}} <span class="modifiedLabel">{{(hasDifferencesFromLastArchivedVersion ? 'OFFER.POSITIONS.VERSION_HAS_MODIFICATIONS' : 'OFFER.POSITIONS.VERSION_UNMODIFIED') | translate}}</span>)
                            </span>
                            <span *ngIf="offer.alternateOfferNumber" [pTooltip]='offer.alternateOfferNumber' class='alternativeNumberWrapper'><span class='alternativeNumberHeader'>{{offer.alternateOfferNumber}}</span></span>
                        </div>
                    </div>
                </div>

                <div class="right">
                    <div class="positions-primary-table-header major-element">
                        <app-simple-button *ngIf="possibleTransitions.length > 0" buttonId="offerStatusTransitionButton"
                                           label="{{ (possibleTransitions.length === 1 ? possibleTransitions[0].item.label : 'OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP') | translate }}"
                                           icon="{{ possibleTransitions.length === 1 ? 'info' : '' }}" type="confirm" [size]="40" align="left"
                                           (onClick)="statusTransitionButtonClick()"
                                           (onIconClick)="showTransitionInfo($event)"></app-simple-button>
                    </div>
                </div>
            </div>

            <div class="position-list-second-header-row">
                <div class="mid">
                    <div class="centered-table-cell">
                        <span class="table-header-buttons">
                            <p-multiSelect defaultLabel="{{ 'GENERAL.CHOOSE_COLUMNS' | translate }}"
                                           id="positionListShownColumns" [displaySelectedLabel]="false"
                                           [options]="columnOptions | selectItemTranslate" [ngModel]="selectedColumns"
                                           (ngModelChange)="onDisplayedColumnsChange($event)"></p-multiSelect>
                            <button-with-menu buttonId="currencySelectButton" [buttonLabel]="selectedCurrency"
                                              rightButtonIcon="keyboard_arrow_down" buttonType="action" [buttonSize]="32"
                                              [menuType]="menuType.BOTTOM_RIGHT" [menuElements]="availableCurrencies"
                                              (menuElementSelected)="handleCurrencyMenuElementSelected($event)">
                            </button-with-menu>
                        </span>
                    </div>
                </div>

                <div class="right" *ngIf="exchangeRateWarningStatus != null">
                    <div class="major-element">
                        <app-simple-button buttonId="exchangeRateWarningBtn"
                                           label="{{ (exchangeRateWarningStatus == 'HIGH' ? 'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.RATE_TOO_HIGH' : 'OFFER.POSITIONS.DIALOGS.UPDATE_EXCHANGE_RATE.RATE_TOO_LOW') | translate:{diff: exchangeRatePercentDifference} }}"
                                           [type]="exchangeRateWarningStatus == 'HIGH' ? 'confirm' : 'delete'"
                                           [size]="40" [disabled]="!isPermittedToUpdateExchangeRate()"
                                           (onClick)="openUpdateExchangeRateDialog()"></app-simple-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="position-list-add-position-hint-region" *ngIf="showAddPositionHint()"
             (load)="addAddPositionHintBlurEventListener()">
            <div id="addPositionHintRegionDiv" #addPositionHintRegionDiv (click)="onAddPositionHintClick($event)"
                 (blur)="onAddPositionHintBlur()"
                 tabindex="-1" style="outline: 0" class="position-list-add-position-hint-button"
                 (load)="addAddPositionHintBlurEventListener()">
                <button-with-menu [menuType]="menuType.BOTTOM_RIGHT" [disabled]="false"
                                  (load)="addAddPositionHintBlurEventListener()">
                    <div id="settingsButton" class="navigation-tile" (load)="addAddPositionHintBlurEventListener()">
                        <i class="material-icons" (load)="addAddPositionHintBlurEventListener()">add</i>
                    </div>
                </button-with-menu>
                <button-with-menu #addPositionHintButton [menuType]="menuType.BOTTOM_RIGHT"
                                  [disabled]="true"
                                  buttonLabel="{{ 'OFFER.POSITIONS.ADD_POSITION' | translate}}"
                                  [menuElements]="addPositionMenuElements"
                                  (menuElementSelected)="addPositionMenuElementSelected($event)">
                </button-with-menu>
            </div>
        </div>


        <p-accordion [multiple]="true" class="position-list-accordion">
         <p-accordionTab *ngFor="let supplier of suppliers; trackBy: supplierTrackBy; let seqNum = index;" [selected]="true">
             <p-header>
                 <div class="accordion-tab-header">
                     <span class="left-side">
                         {{ getSupplierPositionTableHeader(supplier) }}
                     </span>
                     <app-simple-button [buttonId]="'toggle-filter-button-supplier-' + supplier.id"
                                        label="{{ (filterEnabledForSupplier(supplier.id) ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                        icon="filter_list" type="filter"
                                        (onClick)="showTableFilters($event, supplier.id)"></app-simple-button>
                     <span style="width: 8px"></span>
                     <span>
                         <i class="material-icons tab-closed">keyboard_arrow_down</i>
                         <i class="material-icons tab-opened">keyboard_arrow_right</i>
                     </span>
                 </div>
             </p-header>
             <div class="datatable-container">
                 <app-position-list-table #positionTable
                                          [tableData]="tableDataBySupplier[supplier.id]"
                                          [supplier]="supplier"
                                          [seqNum]="seqNum + 1"
                                          [columns]="columns"
                                          [selectedCurrency]="selectedCurrency"
                                          [userLang]="userLang"
                                          [offer]="offer"
                                          [canEdit]="isPositionsEditEnabled()"
                                          [isComplaint]="isComplaint"
                                          (onRowDblclick)="doOnRowSelect($event)"
                                          (selectionChange)="clickedRowChange($event)"
                                          (selectedRowCountChange)="selectedRowCountChange()"
                                          (onLazyLoad)="loadItemsForSupplier($event, supplier.id, false)"
                                          (onActionClicked)="actionOnClick($event.action, $event.position)">
                 </app-position-list-table>
             </div>
         </p-accordionTab>
         <p-accordionTab *ngIf="ownAddonsData != undefined"
                         [selected]="true">
             <p-header>
                 <div class="accordion-tab-header">
                     <span class="left-side">
                         {{ 'OFFER.POSITIONS.OWN_ADDONS' | translate}}
                     </span>
                     <app-simple-button buttonId="toggle-filter-button-own-addons"
                                        label="{{ (filterEnabledForOwnAddons() ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                        icon="filter_list" type="filter"
                                        (onClick)="showOwnAddonsFilters($event)"></app-simple-button>
                     <span style="width: 8px"></span>
                     <span>
                         <i class="material-icons tab-closed">keyboard_arrow_down</i>
                         <i class="material-icons tab-opened">keyboard_arrow_right</i>
                     </span>
                 </div>
             </p-header>
             <div class="datatable-container">
                 <app-position-list-table #ownAddonsPositionTable
                                          [tableData]="ownAddonsData"
                                          [supplier]="undefined"
                                          [seqNum]="suppliers.length + 1"
                                          [columns]="columns"
                                          [selectedCurrency]="selectedCurrency"
                                          [userLang]="userLang"
                                          [offer]="offer"
                                          [canEdit]="canEdit"
                                          (onRowDblclick)="doOnRowSelect($event)"
                                          (selectionChange)="clickedRowChange($event)"
                                          (selectedRowCountChange)="selectedRowCountChange()"
                                          (onLazyLoad)="loadOwnAddons($event, false)"
                                          (onActionClicked)="actionOnClick($event.action, $event.position)">
                 </app-position-list-table>
             </div>
         </p-accordionTab>
         <p-accordionTab *ngIf="assembliesData != undefined"
                         [selected]="true">
             <p-header>
                 <div class="accordion-tab-header">
                     <span class="left-side">
                         {{ 'OFFER.POSITIONS.ASSEMBLIES' | translate}}
                     </span>
                     <app-simple-button *ngIf="(isRotEnabled && canEdit) || rotData != null" buttonId="toggle-rot-button"
                                        label="{{ (rotData == null ? 'ROT_DATA.ADD_ROT' : (canEdit ? 'ROT_DATA.CHANGE_ROT' : 'ROT_DATA.LOOKUP_ROT')) | translate }}"
                                        icon="{{ (rotData == null ? 'add' : (canEdit ? 'edit' : 'search')) }}"
                                        type="filter" (onClick)="showRotDialog($event)"></app-simple-button>
                     <span style="width: 8px"></span>
                     <app-simple-button buttonId="toggle-filter-button-assemblies"
                                        label="{{ (filterEnabledForAssemblies() ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                        icon="filter_list" type="filter"
                                        (onClick)="showAssembliesFilters($event)"></app-simple-button>
                     <span style="width: 8px"></span>
                     <span>
                         <i class="material-icons tab-closed">keyboard_arrow_down</i>
                         <i class="material-icons tab-opened">keyboard_arrow_right</i>
                     </span>
                 </div>
             </p-header>
             <div class="datatable-container">
                 <app-position-list-table #assembliesPositionTable
                                          [tableData]="assembliesData"
                                          [supplier]="undefined"
                                          [seqNum]="suppliers.length + 2"
                                          [columns]="columns"
                                          [selectedCurrency]="selectedCurrency"
                                          [userLang]="userLang"
                                          [offer]="offer"
                                          [canEdit]="canEdit"
                                          (onRowDblclick)="doOnRowSelect($event)"
                                          (selectionChange)="clickedRowChange($event)"
                                          (selectedRowCountChange)="selectedRowCountChange()"
                                          (onLazyLoad)="loadAssemblies($event, false)"
                                          (onActionClicked)="actionOnClick($event.action, $event.position)">
                 </app-position-list-table>
             </div>
         </p-accordionTab>
         <p-accordionTab *ngIf="transportsData != undefined"
                         [selected]="true">
             <p-header>
                 <div class="accordion-tab-header">
                     <span class="left-side">
                         {{ 'OFFER.POSITIONS.TRANSPORTS' | translate}}
                     </span>
                     <app-simple-button buttonId="toggle-filter-button-transport"
                                        label="{{ (filterEnabledForTransports() ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                        icon="filter_list" type="filter"
                                        (onClick)="showTransportsFilters($event)"></app-simple-button>
                     <span style="width: 8px"></span>
                     <span>
                         <i class="material-icons tab-closed">keyboard_arrow_down</i>
                         <i class="material-icons tab-opened">keyboard_arrow_right</i>
                     </span>
                 </div>
             </p-header>
             <div class="datatable-container">
                 <app-position-list-table #transportsPositionTable [tableData]="transportsData" [supplier]="undefined"
                                          [seqNum]="suppliers.length + 3" [columns]="columns"
                                          [selectedCurrency]="selectedCurrency" [userLang]="userLang" [offer]="offer"
                                          [canEdit]="canEdit" (onRowDblclick)="doOnRowSelect($event)"
                                          (selectionChange)="clickedRowChange($event)"
                                          (selectedRowCountChange)="selectedRowCountChange()"
                                          (onLazyLoad)="loadTransports($event, false)"
                                          (onActionClicked)="actionOnClick($event.action, $event.position)"></app-position-list-table>
             </div>
         </p-accordionTab>
     </p-accordion>
    </div>
</div>
<div class="dialog-window" *ngIf="displayedDialog != undefined">
    <!-- ROT DATA -->
    <app-rot-data-dialog *ngIf="isDialogVisible('ROT_DATA')"
                         [displayedDialogData]="dialogDataSelector.getData(displayedDialogData, 'ROT_DATA')"
                         (onSubmit)="saveRotData($event)" (onCancel)="resetDialog()"
                         [validationErrors]="validationErrors"></app-rot-data-dialog>
    <!-- PRICE CHANGE -->
    <app-price-change *ngIf="isDialogVisible('PRICE_CHANGE')"
                      [displayedDialogData]="dialogDataSelector.getData(displayedDialogData, 'PRICE_CHANGE')"
                      (onSubmit)="priceChangeSuccessful($event)" (onCancel)="resetDialog()"
                      (onError)="genericDialogError($event.actionMessage, $event.error, currentActionPosition)"></app-price-change>

    <!-- COMMENTS DIALOG -->
    <app-comment-dialog *ngIf="isDialogVisible('COMMENTS')"
                        [offerId]="isComplaint ? null : id"
                        [offerPositionId]="currentActionPosition.id"
                        [complaintPosition]="getSelectedComplaintPosition()"
                        [mode]="commentDialogMode"
                        (onCloseDialog)="resetCommentDialog($event)"
                        (onSaveCommentSuccess)="onSaveCommentSuccess()"></app-comment-dialog>

    <app-config-editor *ngIf="isDialogVisible('NEW_CONFIG_ADDON') || isDialogVisible('EDIT_CONFIG_ADDON') || isDialogVisible('GLOBAL_ADD_CONFIGURABLE_ADDON')"
                       [applicableTo]="getApplicableTo()"
                       [offer]="offer" [productionOrder]="productionOrder"
                       [currentActionPosition]="currentActionPosition"
                       [displayedDialogData]="dialogDataSelector.getData(displayedDialogData, 'ADD_CONFIGURABLE_ADDON')"
                       [saveAddonOnFormSubmit]="!isDialogVisible('GLOBAL_ADD_CONFIGURABLE_ADDON')"
                       [dialogType]="displayedDialog"
                       (onClose)="onConfigurableAddonDialogSuccess($event)"
    ></app-config-editor>

    <!-- ADD / EDIT CONFIGURABLE ADDON DIALOG -->
    <app-configurable-addon-dialog *ngIf="isDialogVisible('EDIT_CONFIGURABLE_ADDON') || isDialogVisible('ADD_CONFIGURABLE_ADDON') || isDialogVisible('ADD_OTHER_SYSTEM_ADDON')"
                                   [dialogType]="displayedDialog"
                                   [offer]="offer"
                                   [selectedExchangeRate]="exchangeService.getCurrentRatio(selectedCurrency)"
                                   [selectedCurrency]="selectedCurrency"
                                   [currentActionPosition]="currentActionPosition"
                                   [displayedDialogData]="dialogDataSelector.getData(displayedDialogData, 'ADD_CONFIGURABLE_ADDON')"
                                   [showDimensionSection]="showConfigAddonDimensions()"
                                   [showPriceSection]="isDialogVisible('ADD_CONFIGURABLE_ADDON') || isDialogVisible('ADD_OTHER_SYSTEM_ADDON') || isDialogVisible('EDIT_CONFIGURABLE_ADDON')"
                                   [saveAddonOnFormSubmit]="!isDialogVisible('GLOBAL_ADD_CONFIGURABLE_ADDON')"
                                   [readOnlyMode]="!hasOfferLock || positionDisabled"
                                   (onSuccess)="onConfigurableAddonDialogSuccess($event)"
                                   (onClose)="resetDialog()">
    </app-configurable-addon-dialog>

    <app-delete-position-dialog *ngIf="isDialogVisible('REMOVE_POSITION')"
                                [offerId]="id"
                                [itemsToRemove]="itemsToRemove"
                                [areParentsSelected]="areParentsSelected"
                                (onSuccess)="handleDeletePositionSuccess($event)"
                                (onError)="handleDeletePositionError($event)"
                                (onCancel)="resetDialog()"></app-delete-position-dialog>

    <app-delete-complaint-position-dialog *ngIf="isDialogVisible('REMOVE_COMPLAINT_POSITION')"
                                [itemsToRemove]="complaintPositionsToRemove"
                                (onSuccess)="handleDeletePositionSuccess($event)"
                                (onError)="handleDeletePositionError($event)"
                                (onCancel)="resetDialog()"></app-delete-complaint-position-dialog>

    <app-create-complaint-confirmation-dialog *ngIf="isDialogVisible('CREATE_COMPLAINT_CONFIRMATION')"
                                [itemsToDisplay]="relatedComplaints"
                                (onConfirm)="proceedToComplaintCreator()"
                                (navigateToComplaint)="navigateToComplaint($event)"
                                (onCancel)="resetDialog()"></app-create-complaint-confirmation-dialog>

    <app-offer-summary-dialog *ngIf="isDialogVisible('SHOW_SUMMARY') && !isProductionOrder"
                              [offer]="offer"
                              [selectedCurrency]="selectedCurrency"
                              (onClose)="resetDialog()"></app-offer-summary-dialog>

    <app-production-order-summary-dialog *ngIf="isDialogVisible('SHOW_SUMMARY') && isProductionOrder"
                                         [productionOrder]="productionOrder"
                                         [selectedCurrency]="selectedCurrency"
                                         (onClose)="resetDialog()"></app-production-order-summary-dialog>

    <app-messages-dialog *ngIf="isDialogVisible('POSITION_MESSAGES')"
                                  [displayedDialog]="displayedDialog" [entityId]="currentActionPosition.id"
                                  (onClose)="resetDialog()"></app-messages-dialog>

    <app-rabate #rabateDialog
            *ngIf="isDialogVisible('RABATE_MARKUP')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'RABATE_MARKUP')"
            (onSubmit)="rabateDialogSubmitted($event)"
            (onClose)="resetDialog()"></app-rabate>

    <edit-bulk-addon-position *ngIf="isDialogVisible('EDIT_BULK_ADDON')"
                              [dialogData]="dialogDataSelector.getData(displayedDialogData, 'EDIT_BULK_ADDON')"
                              [offer]="offer"
                              [addon]="addon"
                              [imagePreview]="imagePreview"
                              [canEditOffer]="canEdit"
                              [subsystemDefaultCurrency]="defaultCurrency"
                              (onHide)="resetDialog()"
                              (onError)="genericDialogError('EDIT_BULK_ADDON', $event, currentActionPosition)"
                              (onSubmit)="submitEditBulkAddon()">
    </edit-bulk-addon-position>

    <app-open-offer-charge *ngIf="isDialogVisible('OFFER_CHARGE')"
                              [dialogData]="dialogDataSelector.getData(displayedDialogData, 'OFFER_CHARGE')"
                              [readOnlyMode]="true"
                              (onClose)="resetDialog()">
    </app-open-offer-charge>

    <add-bulk-addon-position *ngIf="isDialogVisible('ADD_BULK_ADDON') || isDialogVisible('ADD_OWN_BULK_ADDON')"
                             [offer]="offer" [suppliers]="suppliers" [seqNum]="suppliers.length + 4"
                             [allPositions]="getAllNotOwnPositions()"
                             [ownAddons]="isDialogVisible('ADD_OWN_BULK_ADDON')" (onClose)="resetDialog()"
                             (onSave)="addBulkAddonsSaved()"></add-bulk-addon-position>

    <app-show-description *ngIf="isDialogVisible('SHOW_DESCRIPTION')" [visible]="isDialogVisible('SHOW_DESCRIPTION')"
                          [position]="currentActionPosition"
                          [readOnlyMode]="!hasOfferLock"
                          (onClose)="showDescriptionClosed($event)"></app-show-description>

    <app-bulk-window-system-change *ngIf="isDialogVisible('BULK_WINDOW_SYSTEM_CHANGE')"
                                   [dialogData]="dialogDataSelector.getData(displayedDialogData, 'BULK_WINDOW_SYSTEM_CHANGE')"
                                   (onSubmit)="submitDialog()"
                                   (onClose)="resetDialog()"></app-bulk-window-system-change>

    <app-bulk-change-confirmation *ngIf="isDialogVisible('BULK_CHANGE_CONFIRMATION')"
                                  [dialogData]="dialogDataSelector.getData(displayedDialogData, 'BULK_CHANGE_CONFIRMATION')"
                                  (onSubmit)="submitDialog()"></app-bulk-change-confirmation>

    <app-global-config-addons-add-confirmation *ngIf="isDialogVisible('GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION')"
                                               [dialogData]="dialogDataSelector.getData(displayedDialogData, 'GLOBAL_CONFIG_ADDONS_ADD_CONFIRMATION')"
                                               (onSubmit)="submitDialog($event)"></app-global-config-addons-add-confirmation>

    <app-compare-positions-dialog *ngIf="isDialogVisible('COMPARE_POSITIONS')"
                                  [properties]="positionsPropertiesForComparison"
                                  [positionType]="positionTypeForComparison"
                                  (onClose)="resetDialog()"></app-compare-positions-dialog>

    <app-offer-archived-versions-dialog *ngIf="isDialogVisible('OFFER_ARCHIVED_VERSIONS')"
                                        [dialogData]="dialogDataSelector.getData(displayedDialogData, 'OFFER_ARCHIVED_VERSIONS')"
                                        [canRestore]="canEdit"
                                        (restoreArchivedVersion)="askToRestoreArchivedOfferVersion($event)"
                                        (printArchivedVersion)="showPrintDialog($event)"
                                        (onClose)="resetDialog()"></app-offer-archived-versions-dialog>

    <!--Glass change dialog-->
    <app-bulk-glass-change
            *ngIf="isDialogVisible('BULK_GLASS_CHANGE')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'BULK_GLASS_CHANGE')"
            (onSubmit)="submitDialog()"
            (onClose)="resetDialog()"></app-bulk-glass-change>

    <!--Color change dialog-->
    <app-bulk-color-change
            *ngIf="isDialogVisible('BULK_COLOR_CHANGE')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'BULK_COLOR_CHANGE')"
            (onSubmit)="submitDialog($event)"
            (onClose)="resetDialog()"></app-bulk-color-change>

    <!-- General change dialog -->
    <app-bulk-general-change
            *ngIf="isDialogVisible('BULK_GENERAL_CHANGE')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'BULK_GENERAL_CHANGE')"
            (onSubmit)="submitDialog($event)"
            (onClose)="resetDialog()"></app-bulk-general-change>

    <!-- Fittings change dialog -->
    <app-bulk-fitting-change
            *ngIf="isDialogVisible('BULK_FITTINGS_CHANGE')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'BULK_FITTINGS_CHANGE')"
            (onSubmit)="submitDialog($event)"
            (onClose)="resetDialog()"></app-bulk-fitting-change>

    <!--Predefined glazing package change dialog-->
    <app-bulk-predef-glazing-package-change
        *ngIf="isDialogVisible('BULK_PREDEF_GLAZING_PACKAGE_CHANGE')"
        [dialogData]="dialogDataSelector.getData(displayedDialogData, 'BULK_PREDEF_GLAZING_PACKAGE_CHANGE')"
        (onSubmit)="submitDialog()"
        (onClose)="resetDialog()"></app-bulk-predef-glazing-package-change>

    <app-update-exchange-rate
            *ngIf="isDialogVisible('UPDATE_EXCHANGE_RATE')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'UPDATE_EXCHANGE_RATE')"
            (onClose)="resetDialog()" (navigateToSubsystemSettings)="navigateToSubsystemSettings()"
            (onSuccess)="onUpdateExchangeRateSuccess('UPDATE_EXCHANGE_RATE', $event)"></app-update-exchange-rate>

    <app-update-pricings
            *ngIf="isDialogVisible('UPDATE_PRICINGS')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'UPDATE_PRICINGS')"
            (onClose)="resetDialog()"
            (onComplete)="onUpdatePricingSuccess('UPDATE_PRICINGS', $event)"></app-update-pricings>

    <app-update-validity-dates
            *ngIf="isDialogVisible('UPDATE_VALIDITY_DATES')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'UPDATE_VALIDITY_DATES')"
            (onClose)="resetDialog()"
            (onSuccess)="onUpdateValidityDatesSuccess('UPDATE_VALIDITY_DATES')"></app-update-validity-dates>

    <app-update-vat
            *ngIf="isDialogVisible('UPDATE_VAT')" [dialogData]="dialogDataSelector.getData(displayedDialogData, 'UPDATE_VAT')"
            (onComplete)="onUpdatePricingSuccess('UPDATE_VAT', $event)" (onClose)="resetDialog()"></app-update-vat>

    <app-add-assembly
            *ngIf="isDialogVisible('ADD_ASSEMBLY')"
            [offerId]="id"
            (onSubmit)="submitAddAssemblyDialog()"
            (onClose)="resetDialog()">
    </app-add-assembly>

    <app-edit-assembly
            *ngIf="isDialogVisible('EDIT_ASSEMBLY')"
            [dialogData]="dialogDataSelector.getData(displayedDialogData, 'EDIT_ASSEMBLY_OR_TRANSPORT')"
            [readOnlyMode]="!hasOfferLock"
            (onSubmit)="submitEditAssemblyDialog()"
            (onClose)="resetDialog()">
    </app-edit-assembly>

    <app-add-transport *ngIf="isDialogVisible('ADD_TRANSPORT')" [offerId]="id" (onSubmit)="submitAddTransportDialog()"
                       (onClose)="resetDialog()"></app-add-transport>

    <app-edit-transport *ngIf="isDialogVisible('EDIT_TRANSPORT')"
                        [dialogData]="dialogDataSelector.getData(displayedDialogData, 'EDIT_ASSEMBLY_OR_TRANSPORT')"
                        [readOnlyMode]="!hasOfferLock" (onSubmit)="submitEditTransportDialog()"
                        (onClose)="resetDialog()"></app-edit-transport>

    <app-change-annotations *ngIf="isDialogVisible('CHANGE_ANNOTATIONS') && isProductionOrder" [productionOrder]="productionOrder"
                            (onSave)="handleChangeAnnotationsSave()"
                            (onCancel)="resetDialog()"></app-change-annotations>

    <app-wizard-dialog *ngIf="isDialogVisible('EDIT_COMPLAINT_POSITION')"
                       header="{{ 'OFFER.COMPLAINT.FORM.EDIT' | translate }}"
                       (onCancel)="closeEditComplaintPositionDialog()" (onComplete)="submitDialog()"
                       dialogStyleClass="" contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

        <app-wizard-step label="{{ 'OFFER.COMPLAINT.CREATE.DETAILS' | translate }}" [id]="'details'" [validate]="validateComplaintPositionDataStep">
            <div class="new-form-600">
                <app-complaint-position-form [validationErrors]="validationErrors"
                                             [position]="getDialogComplaintPosition()"
                                             (onShowPhotoInstruction)="showPhotoInstructionDialog()"
                                             (onShowMovieInstruction)="showMovieInstructionDialog()"
                                             (onMovieFileChange)="handleMovieFileChange($event)"
                                             (onPhotoFileChange)="handlePhotoFileChange($event)"></app-complaint-position-form>
            </div>
        </app-wizard-step>
    </app-wizard-dialog>

    <app-info-dialog *ngIf="isDialogVisible('COMPLAINT_MOVIE_INSTRUCTIONS')"
                     header="{{ 'OFFER.COMPLAINT.FORM.MOVIE_GUIDE.HEADER' | translate }}"
                     text="{{ 'OFFER.COMPLAINT.FORM.MOVIE_GUIDE.TEXT' | translate }}"
                     (onHide)="closeComplaintPositionInstructionDialog()"></app-info-dialog>

    <app-info-dialog *ngIf="isDialogVisible('COMPLAINT_PHOTO_INSTRUCTIONS')"
                     header="{{ 'OFFER.COMPLAINT.FORM.PHOTO_GUIDE.HEADER' | translate }}"
                     text="{{ 'OFFER.COMPLAINT.FORM.PHOTO_GUIDE.TEXT' | translate }}"
                     (onHide)="closeComplaintPositionInstructionDialog()"></app-info-dialog>

    <app-correction-order-generation-dialog *ngIf="isDialogVisible('CORRECTION_ORDER_GENERATION')"
                                            (onSuccess)="onChangeStatusSuccess('CONFIRM_CORRECTION')"
                                            (onClose)="resetDialog()"
                                            [offerId]="id"></app-correction-order-generation-dialog>

    <app-change-dealer-discount *ngIf="isDialogVisible('CHANGE_DEALER_DISCOUNT')"
                                [temporaryDiscount]="productionOrder.temporaryDiscount"
                                (onSave)="handleChangeDiscountSave()" (onCancel)="setShowChangeDealerDiscountDialog(false)"
                                (onError)="handleChangeDiscountError($event)"></app-change-dealer-discount>

    <app-change-system-dealer-discount *ngIf="isDialogVisible('CHANGE_SYSTEM_DEALER_DISCOUNT')"
                                       [productionOrderId]="productionOrderId"
                                       (onClose)="handleChangeSystemDealerDiscountDialogClose($event)"></app-change-system-dealer-discount>

    <app-set-print-order-dialog *ngIf="isDialogVisible('SET_PRINT_ORDER')"
                                [dialogData]="dialogDataSelector.getData(displayedDialogData, 'SET_PRINT_ORDER')"
                                (onPrintOrderChangeSuccess)="handlePrintOrderChange($event)"
                                (onClose)="resetDialog()"></app-set-print-order-dialog>

    <app-conjunctions *ngIf="isDialogVisible('CONJUNCTIONS')"
                      [dialogData]="dialogDataSelector.getData(displayedDialogData, 'CONJUNCTIONS')"
                      (onPrint)="printConjunctions($event)"
                      (onClose)="resetDialog()"></app-conjunctions>
</div>

<app-confirm-action-dialog *ngIf="archivedIdToRestore" (onCancel)="hideConfirmRestoreDialog()"
                           (onConfirmed)="restoreArchivedOfferVersion()"
                           [question]="'OFFER.POSITIONS.ARCHIVED_VERSIONS.CONFIRMATION_DIALOG.QUESTION' | translate"
                           [header]="'OFFER.POSITIONS.ARCHIVED_VERSIONS.CONFIRMATION_DIALOG.HEADER' | translate"></app-confirm-action-dialog>

<app-offer-status-transition-dialog *ngIf="transitionsDialogVisible"
                                    [possibleTransitions]="possibleTransitions"
                                    [infoLabelPrefix]="isComplaint ? 'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS_TRANSITION_INFO.CONTENT.' : 'OFFER.ACTIONS.DIALOGS.STATUS_TRANSITION_INFO.CONTENT.'"
                                    (onHide)="hideTransitionsDialog()"
                                    (onChangeStatus)="changeStatus($event)">
</app-offer-status-transition-dialog>

<app-print-dialog *ngIf="printDialogVisible" [printableSection]="getPrintableSection()" [selectedItems]="printableItems"
                  [selectedPositionsIds]="selectedPositionsIds"
                  [selectedConjunctionsIds]="selectedConjunctionsIds"
                  (onCloseDialog)="onPrintDialogClose()"></app-print-dialog>

<app-info-dialog *ngIf="statusTransitionInfoDialogVisible"
                 header="{{ (isComplaint ? 'OFFER.ACTIONS.DIALOGS.STATUS_TRANSITION_INFO.HEADER' : 'OFFER.COMPLAINT.ACTIONS.DIALOG.STATUS_TRANSITION_INFO.HEADER') | translate }}"
                 text="{{ getStatusTransitionInfoLabel() }}"
                 (onHide)="hideStatusTransitionDialog()"></app-info-dialog>

<app-confirm-action-dialog *ngIf="isDialogVisible('DISABLE_VALIDATION')"
                           [header]="'OFFER.POSITIONS.DIALOGS.DISABLE_VALIDATION.HEADER' | translate"
                           [question]="'OFFER.POSITIONS.DIALOGS.DISABLE_VALIDATION.QUESTION' | translate"
                           (onConfirmed)="submitDialog()"
                           (onCancel)="resetDialog()"></app-confirm-action-dialog>

<app-add-standalone-glazing-package
		*ngIf="isDialogVisible('ADD_STANDALONE_GLAZING_PACKAGE')" [offerId]="offer.id" [position]="positionToEdit"
		[canEditOffer]="canEdit" (closed)="resetDialog()"
		(saved)="reloadAllTables()"></app-add-standalone-glazing-package>

<app-add-standalone-glazing-packages-globally
    *ngIf="isDialogVisible('GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE')"
    [dialogData]="dialogDataSelector.getData(displayedDialogData, 'GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE')"
    (closed)="resetDialog()" (saved)="reloadAllTables()"></app-add-standalone-glazing-packages-globally>

<app-info-dialog (onHide)="$event ? openGlobalAddStandaloneGlazingPackagesDialog() : resetDialog()" [hasCancelButton]="true"
                 (close)="resetDialog()"
                 *ngIf="isDialogVisible('GLOBAL_ADD_STANDALONE_GLAZING_PACKAGE_INFO')"
                 header="{{ 'OFFER.POSITIONS.DIALOGS.BULK_ADD_STANDALONE_GLAZING_PACKAGES.HEADER' | translate }}"
                 text="{{ 'OFFER.POSITIONS.DIALOGS.BULK_ADD_STANDALONE_GLAZING_PACKAGES.BODY' | translate }}"></app-info-dialog>

<app-confirm-action-dialog *ngIf="isDialogVisible('CONFIRM_PARTIAL_SIMULATION')"
                           [header]="'OFFER.ACTIONS.DIALOGS.SIMULATION_IMPOSSBLE_FOR_SOME_POSITIONS.HEADER' | translate"
                           [question]="'OFFER.ACTIONS.DIALOGS.SIMULATION_IMPOSSBLE_FOR_SOME_POSITIONS.QUESTION' | translate"
                           [confirmButtonLabel]="'GENERAL.YES' | translate"
                           [discardButtonLabel]="'GENERAL.NO' | translate"
                           (onConfirmed)="submitDialog()"
                           (onCancel)="resetDialog()"></app-confirm-action-dialog>

<!-- For redrawing previews in position list -->
<div [hidden]="true">
    <window-designer #drawer *ngIf="loadDrawer" [redrawPreviewMode]="true" [visibilitySettings]="settings"></window-designer>
</div>
