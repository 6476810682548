<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">
            {{ 'GRASP_DISTANCE_FRAME_CATEGORY.TITLE' | translate }}
        </div>

        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <!-- SHOW / HIDE FILTERS -->
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add"
                                   label="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <div class="datatable-container">
        <p-table #table [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="false" [first]="chosenPageNumber" [metaKeySelection]="true"
                 (onPage)="chosenPageNumber = $event.first" [lazy]="true" [filterDelay]="400"
                 [totalRecords]="totalRecords"
                 selectionMode="single" (onLazyLoad)="loadItemsLazy($event)" [rowTrackBy]="rowTrackById" dataKey="id"
                 [responsive]="true" [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [(selection)]="selectedItem">
            <ng-template pTemplate="header">
                <tr>
                    <th *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.NAME)"
                        [pSortableColumn]="'name.' + translate.currentLang" [ngClass]="columnHeaderClasses('name')">
                        <span>{{ 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.NAME' | translate }}</span>
                        <input class="p-column-filter p-inputtext p-component"
                               [ngClass]="{ 'p-column-filter-invisible': !showFilters }"
                               (click)="handleInputFilterClick($event)" name="name"
                               (input)="table.filter(getInputEventValue($event), 'name', 'contains')">
                    </th>
                    <th *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.SORT_INDEX)" pSortableColumn="sortIndex"
                        [ngClass]="columnHeaderClasses('sortIndex')">
                        <span>{{ 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.SORT_INDEX' | translate }}</span>
                    </th>
                    <th *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.ACTIVE)"
                        [ngClass]="columnHeaderClasses('active')">
                        <span>{{ 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.ACTIVE' | translate }}</span>
                        <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select"
                             (click)="handleInputFilterClick($event)">
                            <app-select inputId="activeFilter" [options]="filterActiveItems | async"
                                        [ngModel]="filterActiveState"
                                        (ngModelChange)="handleFilterActiveChange($event)">
                                <ng-template pTemplate="label"></ng-template>
                            </app-select>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-frameCategory let-rowIndex="rowIndex">
                <tr [attr.tabindex]="rowIndex"
                    (dblclick)="doOnRowSelect({data: frameCategory, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    [pSelectableRow]="frameCategory" [pSelectableRowIndex]="rowIndex"
                    [app-table-keyboard-navigation]="frameCategory"
                    (app-table-keyboard-navigation-enter)="doOnRowSelect({data: frameCategory, originalEvent: {origin: getDatatable(), returnTabIndex: rowIndex}})"
                    class="datatable-data">
                    <td *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.NAME)">{{ frameCategory.name | multilanguageTranslate }}</td>
                    <td *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.SORT_INDEX)">{{ frameCategory.sortIndex }}</td>
                    <td *ngIf="fieldUsage.show(GraspDistanceFrameCategoryField.ACTIVE)">{{ (frameCategory.active ? 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.ACTIVE' : 'GRASP_DISTANCE_FRAME_CATEGORY.FORM.INACTIVE') | translate }}</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <ng-template #paginatorPageInfoTemplate>
        <span>{{ 'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to: toRecord, total: totalRecords} }}</span>
    </ng-template>
    <p-paginator [pageLinkSize]="5" (onPageChange)="table.onPageChange($event)"
                 styleClass="p-paginator-bottom" [templateLeft]="paginatorPageInfoTemplate"
                 [totalRecords]="totalRecords" [first]="chosenPageNumber" [rows]="chosenRowsPerPage"
                 [rowsPerPageOptions]="limitedRowsPerPageOptions"></p-paginator>
</div>

<app-wizard *ngIf="newItem" header="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-grasp-distance-frame-category-form [item]="item"
                                                [validationErrors]="validationErrors"
                                                [editPermits]="editPermits"></app-grasp-distance-frame-category-form>
    </app-wizard-step>
</app-wizard>
<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.EDIT' | translate }}" (onCancel)="cancel()"
                   (onComplete)="submit()">
    <app-wizard-step [id]="STEPS.DATA.id" label="{{ 'GRASP_DISTANCE_FRAME_CATEGORY.STEPS.DATA' | translate }}"
                     [validate]="STEPS.DATA.validate">
        <app-grasp-distance-frame-category-form [item]="item"
                                                [validationErrors]="validationErrors"
                                                [editPermits]="editPermits"></app-grasp-distance-frame-category-form>
    </app-wizard-step>
</app-wizard-dialog>
