import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output
} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {Observable} from "rxjs";
import {ConfigSliderElement} from "../config-slider-element";
import {ConfigSystemService} from "../../config-system/config-system.service";
import {map} from "rxjs/operators";
import {SelectItemImpl} from "../../../../common/service/select.item.impl";
import {TranslateService} from "@ngx-translate/core";

@Component({
    selector: 'app-config-slider-element-form',
    templateUrl: './config-slider-element-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigSliderElementFormComponent {

    @Input() item: ConfigSliderElement;
    @Input() configSystems: SelectItem[];
    @Input() validationErrors: any;
    @Input() image: File;
    @Output() readonly imageLargeChange: EventEmitter<File> = new EventEmitter<File>();

}
