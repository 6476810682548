import {JsonDeserializable} from "../../../../../common/crud-common/crudItem";

@JsonDeserializable
export class OrderDeliveryData {

    offerNumber: string;
    alternateOfferNumber: string;
    productionOrderId: number;
    orderNumber: string;
    companyName: string;
    lastStatusChange: Date = new Date();
    merchantFirstName: string;
    merchantLastName: string;
    clientName: string;
    clientIdentifier: string;
    paymentPackageName: string;
    clientManagerFirstName: string;
    clientManagerLastName: string;
    paletteId: number;

    constructor() {
        this.offerNumber = undefined;
        this.alternateOfferNumber = undefined;
        this.productionOrderId = undefined;
        this.orderNumber = undefined;
        this.companyName = undefined;
        this.lastStatusChange = new Date();
        this.merchantFirstName = undefined;
        this.merchantLastName = undefined;
        this.clientName = undefined;
        this.clientIdentifier = undefined;
        this.paymentPackageName = undefined;
        this.clientManagerFirstName = undefined;
        this.clientManagerLastName = undefined;
        this.paletteId = undefined;
    }

    static fromJSON(jsonObject: any): OrderDeliveryData {
        const entity = new OrderDeliveryData();
        entity.offerNumber = jsonObject.offerNumber;
        entity.productionOrderId = jsonObject.productionOrderId;
        entity.alternateOfferNumber = jsonObject.alternateOfferNumber;
        entity.orderNumber = jsonObject.orderNumber;
        entity.companyName = jsonObject.companyName;
        entity.lastStatusChange = jsonObject.lastStatusChange;
        entity.merchantFirstName = jsonObject.merchantFirstName;
        entity.merchantLastName = jsonObject.merchantLastName;
        entity.clientName = jsonObject.clientName;
        entity.clientIdentifier = jsonObject.clientIdentifier;
        entity.paymentPackageName = jsonObject.paymentPackageName;
        entity.clientManagerFirstName = jsonObject.clientManagerFirstName;
        entity.clientManagerLastName = jsonObject.clientManagerLastName;
        entity.paletteId = jsonObject.paletteId;
        return entity;
    }
}
