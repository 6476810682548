import {AddonInterface} from '../catalog-data/addon-interface';
import {PositionListAddon} from '../catalog-data/position-list-addon';
import {WindowAddon} from '../drawing-data/WindowAddon';
import {WindowAddonData} from '../drawing-data/WindowAddonData';
import {GrowlMessageController} from "../../app/common/growl-message/growl-message-controller";

export class WindowAddonUtils {

    public static addWindowAddonToData(windowAddon: WindowAddon, positionListAddon: PositionListAddon, data: WindowAddonData,
                                       addedWindowAddons: PositionListAddon[]): void {
        if (data.addons.indexOf(windowAddon) < 0) {
            data.addons.push(windowAddon);
        }
        addedWindowAddons.push(positionListAddon);
    }

    public static editWindowAddonInData(windowAddon: WindowAddon, data: WindowAddonData): boolean {
        let addonToEdit = data.addons.find(addon => addon.addonId === windowAddon.addonId);
        if (addonToEdit.quantity !== windowAddon.quantity || addonToEdit.description !== windowAddon.description ||
            addonToEdit.lockRecalculateQuantity !== windowAddon.lockRecalculateQuantity) {
            addonToEdit.quantity = windowAddon.quantity;
            addonToEdit.description = windowAddon.description;
            addonToEdit.lockRecalculateQuantity = windowAddon.lockRecalculateQuantity;
            return true;
        }
        return false;
    }

    public static removeWindowAddonToData(addonId: number, data: WindowAddonData, addedWindowAddons: AddonInterface[]): void {
        let index = data.addons.findIndex(addon => addon.addonId === addonId);
        let indexOfAddedWindowAddons = addedWindowAddons.findIndex(addon => addon.id === addonId);
        if (index >= 0) {
            data.addons.splice(index, 1);
        }
        if (indexOfAddedWindowAddons >= 0) {
            addedWindowAddons.splice(indexOfAddedWindowAddons, 1);
        }
    }

    public static wrapAddedAddons(addonList: AddonInterface[], data: WindowAddonData, currentLang: string,
                                  growls?: GrowlMessageController): PositionListAddon[] {
        if (addonList == undefined) {
            return [];
        }

        // remove inactive addons from data
        const addonsCount = data.addons.length;
        addonList.filter(addon => !addon.active)
            .map(addon => data.addons.findIndex(wAddon => wAddon.addonId === addon.id))
            .filter(wAddonIndex => wAddonIndex >= 0)
            .forEach(wAddonIndex => data.addons.splice(wAddonIndex, 1));

        if (growls && data.addons.length < addonsCount) {
            growls.warning('OFFER_POSITION.INACTIVE_ADDON_REMOVED');
        }

        return data.addons
            .map(addon => ({addon: addonList.find(catalogAddon => catalogAddon.id === addon.addonId), windowAddon: addon}))
            .filter(({addon}) => addon != undefined)
            .map(({addon, windowAddon}) => {
                if (windowAddon.quantity == null) {
                    windowAddon.quantity = addon.defaultQuantity;
                }

                if (windowAddon.quantityType == null) {
                    windowAddon.quantityType = addon.quantityType;
                }

                let positionListAddon = new PositionListAddon(addon, currentLang, windowAddon.description, windowAddon.quantity);
                positionListAddon.lockRecalculateQuantity = windowAddon.lockRecalculateQuantity;
                positionListAddon.defaultAddon = windowAddon.defaultAddon;
                return positionListAddon;
            });
    }
}
