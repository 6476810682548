import {DealerDiscount} from "../dealer-discount";
import {MultilanguageField} from "../../../../supportedLanguages";

export class DealerDiscountPerSystem extends DealerDiscount {
    windowSystemId: number;
    windowSystemName: MultilanguageField;
    supplierId: number;
    supplierCompanyName: string;

    constructor() {
        super();
        this.windowSystemId = undefined;
        this.windowSystemName = new MultilanguageField();
        this.supplierId = undefined;
        this.supplierCompanyName = undefined;
    }

    public static fromJSON(jsonObject: any): DealerDiscountPerSystem {
        let dealerDiscount = new DealerDiscountPerSystem();
        dealerDiscount.id = jsonObject.id;
        dealerDiscount.active = jsonObject.active;
        dealerDiscount.cascade = jsonObject.cascade;
        dealerDiscount.windowSystemId = jsonObject.windowSystemId;
        dealerDiscount.windowSystemName = MultilanguageField.fromJSON(jsonObject.windowSystemName);
        dealerDiscount.supplierId = jsonObject.supplierId;
        dealerDiscount.supplierCompanyName = jsonObject.supplierCompanyName;
        dealerDiscount.discountValue = jsonObject.discountValue;
        dealerDiscount.calculatedDiscountValue = jsonObject.calculatedDiscountValue;
        dealerDiscount.validFrom = new Date(jsonObject.validFrom);
        dealerDiscount.isCurrent = jsonObject.isCurrent;
        return dealerDiscount;
    }
}

