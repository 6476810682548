<div class="list-content webshop-glazing-help">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.GENERAL' | translate }}</div>
    </div>
    <p-dataTable [paginator]="true" [rows]="20" [totalRecords]="allHelp.length" [rowsPerPageOptions]="[20]"
                 [responsive]="true" selectionMode="single" [(selection)]="help"
                 (onRowDblclick)="loadHelp($event.data.id)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 dataKey="language" [value]="allHelp">
        <p-column field="language" header="{{ 'SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.FORM.LANGUAGE' | translate }}">
            <ng-template let-rowData="rowData" pTemplate="body">
                {{ (rowData.language) | translate }}
            </ng-template>
        </p-column>
    </p-dataTable>
</div>
<app-wizard-dialog *ngIf="displayDialog" header="{{ 'SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.EDIT' | translate }}"
                   (onCancel)="displayDialog = false" (onComplete)="save()">
    <app-wizard-step>
        <div class="settings-form-wrapper webshop-glazing-help-form">
            <div class="new-form-600">
                <div class="new-form-row">
                    <div>
                        <h3>{{ help.language | translate }}</h3>
                        <p-editor styleClass="webshop-glazing-help-editor printable-editor"
                                  [ngModel]="help.description" (ngModelChange)="handleDescriptionChange($event)"></p-editor>
                        <div *ngIf="validationErrors['description']" class="new-form-field-error-message">
                            {{ validationErrors['description'] | translate }}
                        </div>
                    </div>
                </div>
                <div class="new-form-row">
                    <app-file-upload inputId="image" [ngModel]="imageFile"
                                     (ngModelChange)="handleImageFileChange($event)"
                                     label="{{ 'SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.FORM.IMAGE' | translate }}"
                                     [maxSize]="10485760" [maxWidth]="3000" [maxHeight]="3000"
                                     [addButtonLabel]="'SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.FORM.SELECT_IMAGE_UPLOAD'"
                                     [changeButtonLabel]="'SETTINGS.SECTION.WEBSHOP_GLAZING_HELP.FORM.CHANGE_IMAGE_UPLOAD'"
                                     [(validationMessageKey)]="validationErrors['image']"></app-file-upload>
                </div>
            </div>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
