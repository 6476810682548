<div class="system-defaults-container">
    <div class="new-form-400">
        <div class="new-form-row">
            <app-select inputId="SYSTEM_id" label="{{ 'OFFER.TABS.SECTION.MAIN.GATE_SYSTEM' | translate }}"
                        [options]="systems" [optionFormatter]="systemOptionFormatter"
                        [optionKey]="systemKey" [ngModel]="selectedSystem"
                        (ngModelChange)="handleSystemChange($event)"></app-select>
        </div>

        <ng-container *ngTemplateOutlet="config"></ng-container>

    </div>
</div>

<!-- REPLACE DEFAULTS DIALOG -->
<p-dialog #globalConfirmDialog id="system-defaults-replace-dialog" [(visible)]="dialogs.confirmReplacing"
          [resizable]="false" [modal]="true" [focusOnShow]="false"
          header="{{ 'OFFER.TABS.SECTION.DEFAULTS.SET' | translate }}" (onHide)="dialogs.confirmReplacing = false">

    <div class="new-form-400">
        <p>{{ 'OFFER.TABS.SECTION.DEFAULTS.CONFIRM.BODY' | translate }}</p>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="system-defaults-replace-confirm-button"
                               label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="saveDefaults()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="globalConfirmDialog.close($event)"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>

<!-- OVERWRITE DEFAULTS DIALOG -->
<app-confirm-overwriting-window-system-defaults-dialog [visible]="dialogs.confirmSubsystemGroup"
                                                       [selections]="subsystemGroups"
                                                       [title]="'OFFER.TABS.SECTION.DEFAULTS.SUBSYSTEM_GROUPS'"
                                                       body="OFFER.TABS.SECTION.DEFAULTS.OVERWRITE.BODY_SUBSYSTEM_GROUPS"
                                                       (onConfirm)="saveDefaults()"
                                                       (onHide)="dialogs.confirmSubsystemGroup = false"></app-confirm-overwriting-window-system-defaults-dialog>

<app-confirm-overwriting-window-system-defaults-dialog [visible]="dialogs.confirmSubsystem" [selections]="subsystems"
                                                       [title]="'OFFER.TABS.SECTION.DEFAULTS.SUBSYSTEMS'"
                                                       body="OFFER.TABS.SECTION.DEFAULTS.OVERWRITE.BODY_SUBSYSTEM"
                                                       (onConfirm)="saveDefaults()"
                                                       (onHide)="dialogs.confirmSubsystem = false"></app-confirm-overwriting-window-system-defaults-dialog>


<ng-template #config>
    <app-config-sidebar *ngIf="state != undefined" #sidebar
                        [data]="state.value" [fields]="fields"
                        [selectedSystem]="selectedSystem" [validationErrors]="{}" [readOnlyMode]="false"
                        [colorMaterialLinks]="colorMaterialLinks" [systemLinksLoaded]="systemLinksLoaded"
                        [addonCategoryGroups]="addonCategoryGroups"
                        [defaultsLevels]="defaultsLevels" [(defaultsLevel)]="defaultsLevel"
                        [(defaultsOverrideLowerLevel)]="defaultsOverrideLowerLevel"
                        (afterFieldChange)="handleSidebarFieldChange($event)"
                        (defaultsSavedClick)="handleDefaultsSavedClick()"></app-config-sidebar>
</ng-template>
