import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Dialog} from 'primeng/dialog';
import {forkJoin, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, mergeMap, tap} from 'rxjs/operators';
import {ErrorNames} from '../../../window-designer/utils/ErrorNames';
import {CurrentUserService} from '../../auth/current-user.service';
import {Permissions} from '../../auth/permission.service';
import {ClientGroupService} from '../../features/client-group/client-group.service';
import {ClientGroup} from '../../features/client-group/ClientGroup';
import {Client} from '../../features/client/client';
import {ClientService} from '../../features/client/client.service';
import {SimpleProfitMarginSource} from '../../features/client/simple-profit-margin-source/simple-profit-margin-source';
import {ConfigurableAddonsService} from '../../features/offer/offers/position/config-addons.service';
import {ProfitMarginExistance} from '../../features/offer/window-editor/drawing-tool/ProfitMarginExistance';
import {
    SubsystemGroupAddonProfitMarginService
} from '../../features/subsystem-group/subsystem-group-addon-profit-margin/subsystem-group-addon-profit-margin.service';
import {SubsystemGroupService} from '../../features/subsystem-group/subsystem-group.service';
import {ProfitMarginService} from '../../features/subsystem/profit-margin/profit-margin.service';
import {
    isValidProfitMarginValueString,
    normalizeProfitMarginValueString,
    ProfitMargin
} from '../../features/subsystem/profit-margin/profitMargin';
import {ProfitMarginApi} from '../../features/subsystem/profit-margin/profitMarginApi';
import {Subsystem} from '../../features/subsystem/subsystem';
import {SubsystemService} from '../../features/subsystem/subsystem.service';
import {GateSystem} from '../../features/window-system/gate-system/gate-system';
import {GateSystemService} from '../../features/window-system/gate-system/gate-system.service';
import {WindowSystemDefinition} from '../../features/window-system/window-system-definition/window-system-definition';
import {WindowSystemDefinitionService} from '../../features/window-system/window-system-definition/window-system-definition.service';
import {MultilanguageField} from '../../supportedLanguages';
import {CommonErrorHandler} from '../CommonErrorHandler';
import {GrowlMessageController} from '../growl-message/growl-message-controller';
import {ValidationErrors} from '../validation-errors';
import {MissingProfitMarginHandlerService, MissingProfitMarginParams} from './missing-profit-margin-handler.service';
import {ProfitMarginTarget} from "../../features/subsystem/profit-margin/profit-margin-target";
import {ConfigSystemService} from "../../features/window-system/config-system/config-system.service";
import {ConfigSystem} from "../../features/window-system/config-system/config-system";

@Component({
    selector: 'app-missing-profit-margin-handler',
    templateUrl: './missing-profit-margin-handler.component.html',
    styleUrls: ['./missing-profit-margin-handler.component.css'],
    // component that hosts <app-missing-profit-margin-handler> is supposed to be the provider for MissingProfitMarginHandlerService
    providers: [SubsystemService, SubsystemGroupService, ClientService, ClientGroupService,
        WindowSystemDefinitionService, ConfigurableAddonsService, GateSystemService, ConfigSystemService,
        ProfitMarginService, SubsystemGroupAddonProfitMarginService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MissingProfitMarginHandlerComponent implements OnInit, OnDestroy {

    @ViewChild('dialog', {static: true})
    dialog: Dialog;

    showDialog = false;
    private profitMarginStatus: ProfitMarginExistance;
    private profitMarginParams: MissingProfitMarginParams;
    private supplierId: number;
    subsystem: Subsystem;
    client: Client;
    private clientGroup: ClientGroup;
    clientGroupSimpleProfitMargins: SimpleProfitMarginSource;
    sellerClient: Client;
    sellerClientGroupSimpleProfitMargins: SimpleProfitMarginSource;
    profitMarginTargetName: MultilanguageField;
    profitMarginTargetSupplierName: MultilanguageField;
    profitMarginValue: string;
    validationErrors: ValidationErrors = {};
    private saveAction: () => Observable<any>;

    canEdit = {
        subsystem: false,
        client: false,
        sellerClient: false
    };

    private dialogResult: boolean;

    private subscription: Subscription;

    constructor(private readonly service: MissingProfitMarginHandlerService,
                private readonly subsystemService: SubsystemService,
                private readonly subsystemGroupService: SubsystemGroupService,
                private readonly clientService: ClientService,
                private readonly clientGroupService: ClientGroupService,
                private readonly windowSystemService: WindowSystemDefinitionService,
                private readonly configurableAddonsService: ConfigurableAddonsService,
                private readonly configSystemService: ConfigSystemService,
                private readonly gateSystemService: GateSystemService,
                private readonly profitMarginService: ProfitMarginService,
                private readonly subsystemGroupAddonProfitMarginService: SubsystemGroupAddonProfitMarginService,
                private readonly growls: GrowlMessageController,
                private readonly errors: CommonErrorHandler,
                private readonly permissions: Permissions,
                private readonly currentUserService: CurrentUserService,
                private readonly translate: TranslateService,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.subscription = this.service.missingProfitMarginEvents.subscribe(event => {
            if (event.status.dealerMarkup
                && event.status.subsystemMarkup
                && event.status.clientMarkup
                && event.status.sellerClientMarkup) {
                // nothing to do here, all data is present
                this.service.missingProfitMarginHandled(true);
                return;
            }

            // show dialog with appropriate input to fill
            this.profitMarginStatus = event.status;
            this.profitMarginParams = event.params;
            this.getDataSources(event.status, event.params).subscribe(() => {
                this.processMissingProfitMargins(event.status);
                this.changeDetector.markForCheck();
            });
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
        this.subscription = undefined;
    }

    private getDataSources(status: ProfitMarginExistance, params: MissingProfitMarginParams): Observable<any[]> {
        const observables: Observable<any>[] = [];
        if (!status.subsystemMarkup) {
            observables.push(this.subsystemService.getItem(params.subsystemId).pipe(
                tap(subsystem => this.subsystem = subsystem)));
        }
        if (!status.clientMarkup) {
            observables.push(this.clientService.getClient(params.clientId).pipe(
                tap(client => this.client = client),
                mergeMap(client => this.clientGroupService.getItem(client.groupId, true).pipe(
                    catchError(() => of<ClientGroup>(undefined)),
                    tap(clientGroup => this.clientGroup = clientGroup))),
                mergeMap(clientGroup => clientGroup != undefined
                    ? this.clientGroupService.getSimpleProfitMarginSource(clientGroup.id)
                    : of<SimpleProfitMarginSource>(undefined)),
                tap(clientGroupSimpleProfitMargins => this.clientGroupSimpleProfitMargins = clientGroupSimpleProfitMargins)));
        }
        if (!status.sellerClientMarkup) {
            observables.push(this.clientService.getClient(params.sellerClientId).pipe(
                tap(client => this.sellerClient = client),
                mergeMap(client => this.clientGroupService.getSimpleProfitMarginSource(client.groupId)),
                tap(clientGroupUsesSimpleProfitMargins => {
                    this.sellerClientGroupSimpleProfitMargins = clientGroupUsesSimpleProfitMargins;
                })));
        }
        switch (params.identifier.target) {
            case 'WINDOW_SYSTEM':
                observables.push(this.windowSystemService.getSystem(params.identifier.windowSystemId).pipe(
                    tap((windowSystem: WindowSystemDefinition) => {
                        this.profitMarginTargetName = windowSystem.names;
                        this.profitMarginTargetSupplierName = windowSystem.supplier.name;
                        this.supplierId = windowSystem.supplier.id;
                    })
                ));
                break;
            case 'CONFIG_SYSTEM':
                observables.push(this.configSystemService.getItem(params.identifier.configSystemId).pipe(
                    tap((system: ConfigSystem) => {
                        this.profitMarginTargetName = system.name;
                        this.profitMarginTargetSupplierName = system.supplier.name;
                        this.supplierId = system.supplier.id;
                    })
                ));
                break;
            case 'ADDON':
                observables.push(this.translate.get('MISSING_PROFIT_MARGIN_HANDLER.TARGET.ADDON').pipe(
                    tap(text => this.profitMarginTargetName = this.profitMarginTargetSupplierName = new MultilanguageField(text))
                ));
                break;
            case 'GATE_SYSTEM':
                observables.push(this.gateSystemService.getItem(params.identifier.gateSystemId).pipe(
                    tap((gateSystem: GateSystem) => {
                        this.profitMarginTargetName = gateSystem.name;
                        this.profitMarginTargetSupplierName = gateSystem.supplier.name;
                        this.supplierId = gateSystem.supplier.id;
                    })
                ));
                break;
        }
        return forkJoin(observables);
    }

    private processMissingProfitMargins(status: ProfitMarginExistance): void {
        this.canEdit = {
            subsystem: false,
            client: false,
            sellerClient: false
        };
        if (!status.subsystemMarkup) {
            if ((this.permissions.isKoordynator() || this.permissions.isOpiekun())
                && (this.permissions.canCreateSubsystem() || this.permissions.canEditSubsystem())) {
                this.canEdit.subsystem = true;
                this.saveAction = () => this.saveSubsystemProfitMargin(this.subsystem)
                    .pipe(tap(() => this.profitMarginStatus.subsystemMarkup = true));
                this.setShowDialog();
            }
        }

        if (!status.clientMarkup) {
            if (this.permissions.isOperator()
                || (this.permissions.isHandlowiec() && this.clientGroup != undefined
                    && this.clientGroup.ownerId === this.currentUserService.currentUserId)
                || (this.permissions.isSprzedawca() && this.clientGroup != undefined)) {
                this.canEdit.client = true;
                this.saveAction = () => this.saveClientProfitMargin(this.client, this.clientGroupSimpleProfitMargins)
                    .pipe(tap(() => {
                        this.profitMarginStatus.clientMarkupIsZeroWarning = normalizeProfitMarginValueString(this.profitMarginValue) === '0';
                        this.profitMarginStatus.clientMarkup = true;
                    }));
                this.setShowDialog();
            }
        }
        if (!status.sellerClientMarkup) {
            if (this.permissions.isSprzedawca()) {
                this.canEdit.sellerClient = true;
                this.saveAction = () => this.saveClientProfitMargin(this.sellerClient, this.sellerClientGroupSimpleProfitMargins)
                    .pipe(tap(() => {
                        this.profitMarginStatus.clientMarkupIsZeroWarning = normalizeProfitMarginValueString(this.profitMarginValue) === '0';
                        this.profitMarginStatus.sellerClientMarkup = true;
                    }));
                this.setShowDialog();
            }
        }
        if (!this.showDialog) {
            this.displayGrowls(status);
            this.service.missingProfitMarginHandled(false);
        }
    }

    private displayGrowls(status: ProfitMarginExistance): void {
        if (this.canEdit.subsystem) {
            if (!status.dealerMarkup) {
                this.growls.blocker(ErrorNames.DEALER_MARKUP_NOT_FOUND);
            }
            if (!status.subsystemMarkup) {
                this.growls.blocker(ErrorNames.SUBSYSTEM_MARKUP_NOT_FOUND);
            }
        } else {
            if (!status.dealerMarkup || !status.subsystemMarkup) {
                this.growls.blocker(ErrorNames.MARKUP_NOT_FOUND);
            }
        }
        if (!status.clientMarkup) {
            if (this.canEdit.client) {
                this.growls.blocker(ErrorNames.CLIENT_MARKUP_NOT_FOUND);
            } else {
                this.growls.blocker(ErrorNames.CLIENT_MARKUP_NOT_FOUND_FOR_SELLER);
            }
        }
        if (!status.sellerClientMarkup) {
            if (this.canEdit.sellerClient) {
                this.growls.blocker(ErrorNames.SELLER_CLIENT_MARKUP_NOT_FOUND);
            }
        }
        if (status.clientMarkupIsZeroWarning) {
            this.growls.warning(ErrorNames.CLIENT_MARKUP_IS_0);
        }
    }

    private setShowDialog() {
        this.showDialog = true;
        this.dialogResult = undefined;
    }

    emitDialogResult(): void {
        if (this.dialogResult != undefined) {
            this.displayGrowls(this.profitMarginStatus);
            this.service.missingProfitMarginHandled(this.dialogResult);
        }
    }

    get targetKey() {
        if (this.profitMarginParams.identifier.target === 'ADDON') {
            return '';
        }
        if ((this.canEdit.client && this.clientGroupSimpleProfitMargins != undefined)
            || (this.canEdit.sellerClient && this.sellerClientGroupSimpleProfitMargins != undefined)) {
            return 'MISSING_PROFIT_MARGIN_HANDLER.TARGET.SUPPLIER';
        }
        return `MISSING_PROFIT_MARGIN_HANDLER.TARGET.${this.profitMarginParams.identifier.target}`;
    }

    handleProfitMarginSaveClick(event: MouseEvent) {
        this.saveAction().subscribe({
            complete: () => {
                this.dialogResult = true;
                this.dialog.close(event);
            },
            error: err => {
                this.validationErrors = this.errors.handle(err);
            }
        });
    }

    handleProfitMarginCancelClick(event: MouseEvent): void {
        this.dialogResult = false;
        this.dialog.close(event);
        this.changeDetector.markForCheck();
    }

    private saveSubsystemProfitMargin(subsystem: Subsystem): Observable<any> {
        const valueString = normalizeProfitMarginValueString(this.profitMarginValue);
        if (!isValidProfitMarginValueString(valueString)) {
            return throwError(() => new HttpErrorResponse({
                error: {code: 400, invalidFields: {profitMarginValue: 'PROFIT_MARGIN.ERROR.INVALID_VALUE'}}
            }));
        }
        switch (this.profitMarginParams.identifier.target) {
            case 'WINDOW_SYSTEM': {
                const profitMargin = new ProfitMargin();
                profitMargin.systemId = this.profitMarginParams.identifier.windowSystemId;
                profitMargin.subsystemGroupId = subsystem.subsystemGroupId;
                profitMargin.valueString = valueString;
                this.profitMarginService.setApi(ProfitMarginApi.SUBSYSTEM_GROUP_ALL, ProfitMarginApi.SUBSYSTEM_GROUP);
                return this.profitMarginService.save([profitMargin], ProfitMarginTarget.SYSTEM);
            }
            case 'CONFIG_SYSTEM': {
                const configProfitMargin = new ProfitMargin();
                configProfitMargin.systemId = this.profitMarginParams.identifier.configSystemId;
                configProfitMargin.subsystemGroupId = subsystem.subsystemGroupId;
                configProfitMargin.valueString = valueString;
                this.profitMarginService.setApi(ProfitMarginApi.SUBSYSTEM_GROUP_ALL, ProfitMarginApi.SUBSYSTEM_GROUP);
                return this.profitMarginService.save([configProfitMargin], ProfitMarginTarget.CONFIG);
            }
            case 'ADDON':
                return this.subsystemGroupService.editBulkAddonProfitMargin(subsystem.subsystemGroupId, valueString);
            case 'GATE_SYSTEM': {
                const gateProfitMargin = new ProfitMargin();
                gateProfitMargin.systemId = this.profitMarginParams.identifier.gateSystemId;
                gateProfitMargin.subsystemGroupId = subsystem.subsystemGroupId;
                gateProfitMargin.valueString = valueString;
                this.profitMarginService.setApi(ProfitMarginApi.SUBSYSTEM_GROUP_ALL, ProfitMarginApi.SUBSYSTEM_GROUP);
                return this.profitMarginService.save([gateProfitMargin], ProfitMarginTarget.GATE);
            }
            default:
                break;
        }
        return throwError(() => new HttpErrorResponse({ error: { code: 500, invalidFields: {} } }));
    }

    private saveClientProfitMargin(client: Client, groupSimpleProfitMargins: SimpleProfitMarginSource): Observable<any> {
        const valueString = normalizeProfitMarginValueString(this.profitMarginValue);
        if (!isValidProfitMarginValueString(valueString)) {
            return throwError(() => new HttpErrorResponse({
                error: {code: 400, invalidFields: {profitMarginValue: 'PROFIT_MARGIN.ERROR.INVALID_VALUE'}}
            }));
        }
        if (groupSimpleProfitMargins != undefined && this.profitMarginParams.identifier.target !== 'ADDON') {
            groupSimpleProfitMargins.supplierProfitMargins[this.supplierId] = valueString;
            return this.clientGroupService.saveSimpleProfitMarginSource(client.groupId,
                groupSimpleProfitMargins);
        }
        switch (this.profitMarginParams.identifier.target) {
            case 'WINDOW_SYSTEM': {
                const profitMargin = new ProfitMargin();
                profitMargin.systemId = this.profitMarginParams.identifier.windowSystemId;
                profitMargin.clientGroupId = client.groupId;
                profitMargin.valueString = valueString;
                this.profitMarginService.setApi(ProfitMarginApi.CLIENT_GROUP_ALL, ProfitMarginApi.CLIENT_GROUP);
                return this.profitMarginService.save([profitMargin], ProfitMarginTarget.SYSTEM);
            }
            case 'CONFIG_SYSTEM': {
                const configProfitMargin = new ProfitMargin();
                configProfitMargin.systemId = this.profitMarginParams.identifier.configSystemId;
                configProfitMargin.clientGroupId = client.groupId;
                configProfitMargin.valueString = valueString;
                this.profitMarginService.setApi(ProfitMarginApi.CLIENT_GROUP_ALL, ProfitMarginApi.CLIENT_GROUP);
                return this.profitMarginService.save([configProfitMargin], ProfitMarginTarget.CONFIG);
            }
            case 'ADDON':
                return this.clientGroupService.editBulkAddonProfitMargin(client.groupId, valueString);
            case 'GATE_SYSTEM': {
                const gateProfitMargin = new ProfitMargin();
                gateProfitMargin.systemId = this.profitMarginParams.identifier.gateSystemId;
                gateProfitMargin.clientGroupId = client.groupId;
                gateProfitMargin.valueString = valueString;
                this.profitMarginService.setApi(ProfitMarginApi.CLIENT_GROUP_ALL, ProfitMarginApi.CLIENT_GROUP);
                return this.profitMarginService.save([gateProfitMargin], ProfitMarginTarget.GATE);
            }
            default:
                break;
        }
        return throwError(() => new HttpErrorResponse({ error: { code: 500, invalidFields: {} } }));
    }
}
