import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef} from '@angular/core';
import {Column, FilterMetadata, FilterService} from 'primeng/api';
import {DataTable} from 'primeng/datatable';
import {DatatableHelper} from '../DatatableHelper';
import {TranslatedSelectItemService} from '../service/translated-select-item.service';
import {AbstractDateRangeFilterComponent} from './abstract-date-range-filter.component';
import {DateRangeFilter} from '../date-range-filter';

@Component({
    selector: '[app-date-range-filter]',
    templateUrl: './date-range-filter.component.html',
    styleUrls: ['./date-range-filter.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFilterComponent extends AbstractDateRangeFilterComponent {

    constructor(translatedSelectItemService: TranslatedSelectItemService,
                changeDetector: ChangeDetectorRef,
                private readonly datatable: DataTable,
                filterService: FilterService,
                private column: Column) {
        super(translatedSelectItemService, changeDetector);

        // patch datatable with dateRange filtering capability
        DatatableHelper.initializeDateRangeFiltering(filterService);
    }

    get field() {
        return this.column.field;
    }

    get hostElement(): ElementRef {
        return this.datatable.el;
    }

    get filter(): FilterMetadata | FilterMetadata[] {
        return this.datatable.filters[this.field];
    }

    doFilter(filter: DateRangeFilter): void {
        this.datatable.filter(filter, this.field, 'dateRange');
    }
}
