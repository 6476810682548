<div class="new-form-600">
    <div class="new-form-row">
        <app-checkbox inputId="canUseShape" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.SHAPE' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseShape"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseCut" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.CUT' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseCut"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseTrim" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.TRIM' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseTrim"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseAddMullion" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.ADD_MULLION' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseAddMullion"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseMirror" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.MIRROR' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseMirror"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseAlignment" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.ALIGNMENT' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseAlignment"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseAddLineGrill" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.ADD_LINE_GRILL' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseAddLineGrill"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseAddGridGrill" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.ADD_GRID_GRILL' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseAddGridGrill"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseExtendHorizontalGrill" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.EXTEND_HORIZONTAL_GRILLS' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseExtendHorizontalGrills"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseAddHandle" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.ADD_HANDLE' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseAddHandle"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseAddConfigAddon" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.ADD_CONFIG_ADDON' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseAddConfigAddon"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="canUseAddStandaloneGlazingPackage"
                      label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DRAWING_TOOLS.ADD_STANDALONE_GLAZING_PACKAGE' | translate }}"
                      [disabled]="disabled"
                      [(ngModel)]="windowSystemDrawingToolsEnabler.canUseAddStandaloneGlazingPackage"></app-checkbox>
    </div>
</div>
