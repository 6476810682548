import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Hotkey, HotkeysService} from 'angular2-hotkeys';

@Component({
    selector: 'app-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./../login.component.css']
})
export class LoginFormComponent implements OnInit, OnDestroy {

    @Input()
    login: string;

    @Output()
    readonly loginChange = new EventEmitter<string>();

    @Input()
    password: string;

    @Output()
    readonly passwordChange = new EventEmitter<string>();

    @Output()
    readonly submit = new EventEmitter<void>();

    @Output()
    readonly resetPassword = new EventEmitter<void>();

    private readonly enterHotkey: Hotkey;

    constructor(private readonly hotkeysService: HotkeysService) {
        this.enterHotkey = new Hotkey('enter', (): boolean => {
            this.submit.emit();
            return false;
        }, ['INPUT']);
    }

    ngOnInit(): void {
        this.hotkeysService.add(this.enterHotkey);
    }

    ngOnDestroy(): void {
        this.hotkeysService.remove(this.enterHotkey);
    }

    handleResetPasswordClick(): boolean {
        this.resetPassword.emit();
        return false;
    }
}
