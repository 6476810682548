import {Injectable} from '@angular/core';
import {CrudService} from "../../../common/crud-common/crud.service";
import {ConfigTypeDescriptor} from "./config-type-descriptor";
import {HttpClient} from "@angular/common/http";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {Observable} from "rxjs";
import {FilterMetadata} from "primeng/api/filtermetadata";
import {Listing} from "../../../common/crud-common/crudItemList";
import {map} from "rxjs/operators";
import {ValidationErrors} from "../../../common/validation-errors";
import {ValidationErrorsHelper} from "../../../common/ValidationErrorsHelper";

@Injectable({
    providedIn: 'root'
})
export class ConfigTypeDescriptorService implements CrudService<ConfigTypeDescriptor> {

    private static readonly API_URL = 'configTypeDescriptor';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    addItem(item: ConfigTypeDescriptor): Observable<number> {
        return this.saveDescriptor(item);
    }

    editItem(itemId: number, item: ConfigTypeDescriptor): Observable<number> {
        return this.saveDescriptor(item, itemId);
    }

    getItem(itemId: number): Observable<ConfigTypeDescriptor> {
        return this.http.get<ConfigTypeDescriptor>(`${ConfigTypeDescriptorService.API_URL}/${itemId}`);
    }

    getItems(offset: number, pageSize: number, filters: { [p: string]: FilterMetadata }, sortColumn: string,
             sortOrder: number): Observable<Listing<ConfigTypeDescriptor>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<ConfigTypeDescriptor>>(ConfigTypeDescriptorService.API_URL, {params: params});
    }

    saveDescriptor(item: ConfigTypeDescriptor, itemId?: number): Observable<number> {
        const url = itemId ? `${ConfigTypeDescriptorService.API_URL}/${itemId}` : ConfigTypeDescriptorService.API_URL;

        return this.http.post(url, item, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(ConfigTypeDescriptorService.API_URL, response)));
    }

    copy(id: number, item: ConfigTypeDescriptor) {
        return this.http.post(`${ConfigTypeDescriptorService.API_URL}/${id}/copy`, item, {observe: 'response'}).pipe(
            map(response => item.id || this.dataServiceHelper.getNewItemId(ConfigTypeDescriptorService.API_URL, response)));
    }

    validate(item: ConfigTypeDescriptor): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${ConfigTypeDescriptorService.API_URL}/validate`, item));
    }
}
