import {Injectable} from "@angular/core";
import {MissingTranslationHandler, MissingTranslationHandlerParams} from "@ngx-translate/core";
import {map} from "rxjs/operators";

@Injectable()
export class CustomMissingTranslationHandler implements MissingTranslationHandler {

    private static readonly DROOLS_KEY_PREFIX = 'drools.';

    handle(params: MissingTranslationHandlerParams) {
        if (params.key.startsWith(CustomMissingTranslationHandler.DROOLS_KEY_PREFIX)) {
            return params.translateService.reloadLang(params.translateService.currentLang).pipe(
                map(result => {
                    const translation = params.translateService.parser.getValue(result, params.key);
                    if (translation != undefined) {
                        return params.translateService.parser.interpolate(translation, params.interpolateParams);
                    }
                    return params.key;
                })
            );
        }
        return params.key;
    }
}
