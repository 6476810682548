import {HttpErrorResponse} from '@angular/common/http';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Injector,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {finalize, map, tap} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {StorageService} from '../../../auth/storage.service';
import {ButtonWithMenuElementSelectedEvent} from '../../../common/button-with-menu/button-with-menu-event';
import {MenuElement, MenuElementBuilder} from '../../../common/button-with-menu/MenuElement';
import {MenuType} from '../../../common/button-with-menu/MenuType';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {OfferStatus} from '../../../common/enums/OfferStatus';
import {ResponseError} from '../../../common/error.handler';
import {ExchangeService} from '../../../common/exchange.service';
import {SavedFilterService} from '../../../common/saved-filter/saved-filter.service';
import {CrudComponent} from '../../../common/service/crud.component';
import {DataTableColumnBuilder, SavedShownColumns} from '../../../common/service/data.table.column.builder';
import {ExportComponent} from '../../../common/service/export.component';
import {SelectItemImpl} from '../../../common/service/select.item.impl';
import {TranslatedSelectItem} from '../../../common/service/translated.select.item';
import {TranslatedSelectItemBuilder} from '../../../common/service/translated.select.item.builder';
import {Currencies} from '../../../currencies';
import {WizardStepChangeEvent} from '../../../form-inputs/wizard/wizard-base';
import {ErrorResponse} from '../../errors/errorResponse';
import {Subsystem} from '../../subsystem/subsystem';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {HasSavedFilter} from '../has-saved-filter';
import {OffersService} from '../offer-service';
import {StatusTransitionDialogService} from '../status-transition-dialog/status-transition-dialog.service';
import {StatusTransition} from '../status-transition-dialog/StatusTransition';
import {StatusTransitionHelper} from '../status-transition-dialog/StatusTransitionHelper';
import {MotlawaIntegrationDialogsData} from './motlawa-integration-dialogs-data';
import {MotlawaIntegrationService} from './motlawa-integration.service';
import {PaymentPackage} from './payment-package';
import {PaymentPackageFormComponent} from './payment-package-form/payment-package-form.component';
import {PaymentPackagePicklistComponent} from './payment-package-picklist/payment-package-picklist.component';
import {PaymentPackageService} from './payment-package.service';

@Component({
    selector: 'app-payment-package',
    templateUrl: './payment-package.component.html',
    styleUrls: ['./payment-package.component.css', '../../shared-styles.css', '../../../common/offer-status-colors.css'],
    providers: [PaymentPackageService, SubsystemService, ExportComponent, DataServiceHelper, StatusTransitionDialogService,
        PaymentPackageService, OffersService, ExchangeService, MotlawaIntegrationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentPackageComponent extends CrudComponent implements HasSavedFilter, OnInit, OnDestroy {

    public static readonly MOTLAWA_CHECK_ADDONS_BLOCK_ID = 'motlawaCheckAddons';
    public static readonly MOTLAWA_GENERATE_DATA_BLOCK_ID = 'motlawaGenerateData';
    public static readonly PACKAGE_DELETE_BLOCK_ID = 'deletingPaymentPackage';

    readonly TABLE_ID = 'paymentPackageTable';
    readonly IDENTIFIER_CHANGE_STATUS = 'change-status_';
    readonly IDENTIFIER_DELETE = 'delete_';
    readonly IDENTIFIER_SHOW_ORDERS = 'show-orders_';
    readonly IDENTIFIER_SHOW_PRODUCTION_ORDERS = 'show-production-orders_';
    readonly IDENTIFIER_GENERATE_MOTLAWA_DATA = 'generate-motlawa-data_';

    readonly STEPS = {
        SUBSYSTEM: {
            id: 'SUBSYSTEM',
            validator: () => this.validateSubsystem()
        },
        DETAILS: {
            id: 'DATA',
            validator: () => this.validateForm()
        },
        ORDERS: {
            id: 'ORDERS'
        }
    };

    menuType = MenuType;

    @ViewChild('dt')
    paymentPackagesTable: DataTable;

    @Output() totalRecordsChange = new EventEmitter<number>();
    @Output() onDataLazyLoad = new EventEmitter<LazyLoadEvent>();
    @Output() onUpdateOriginalFilters = new EventEmitter<LazyLoadEvent>();
    @Output() onVisibleColumnsChange = new EventEmitter<SavedShownColumns>();

    @ViewChild('createDetails')
    createDetails: PaymentPackageFormComponent;

    @ViewChild('selectOrders')
    selectOrders: PaymentPackagePicklistComponent;

    paymentPackages: PaymentPackage[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    selectedPaymentPackage: PaymentPackage;
    editedPaymentPackage: PaymentPackage;
    ignoreStoredFilter = false;
    filterByCurrentUser: boolean;
    filterUnprintedPaymentPackages: boolean;

    newItem = false;
    subsystems: SelectItem[];
    selectedSubsystems: Subsystem[] = [];

    displayEditingWizard = false;

    possibleTransitions: StatusTransition[] = [];
    visibleDialog: 'STATUS_CHANGE' | 'MOTLAWA_ADDONS_DISTRIBUTION' | 'MOTLAWA_DATA';

    availableStatuses: TranslatedSelectItem[] = [];
    selectedStatuses: OfferStatus[] = [];

    private paymentPackageService: PaymentPackageService;
    private subsystemService: SubsystemService;
    private exchangeService: ExchangeService;
    permissions: Permissions;
    private router: Router;
    private route: ActivatedRoute;
    private errors: CommonErrorHandler;

    private lastLoadEvent: LazyLoadEvent;

    private componentInitialized = new Subject<void>();

    private motlawaIntegrationService: MotlawaIntegrationService;
    motlawaIntegrationDialogsData: MotlawaIntegrationDialogsData;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private statusTransitionDialogService: StatusTransitionDialogService,
                public storage: StorageService) {
        super(injector, changeDetector, 'PaymentPackage', false);
        this.paymentPackageService = injector.get(PaymentPackageService);
        this.motlawaIntegrationService = injector.get(MotlawaIntegrationService);
        this.subsystemService = injector.get(SubsystemService);
        this.exchangeService = injector.get(ExchangeService);
        this.permissions = injector.get(Permissions);
        this.router = injector.get(Router);
        this.route = injector.get(ActivatedRoute);
        this.errors = injector.get(CommonErrorHandler);
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.ignoreStoredFilter = this.route.snapshot.paramMap.has('ignoreStoredFilter');

        this.availableStatuses = TranslatedSelectItemBuilder.create()
            .add('OFFER.STATUS.WAITING_FOR_PAYMENT', OfferStatus.WAITING_FOR_PAYMENT)
            .add('OFFER.STATUS.PAID', OfferStatus.PAID)
            .add('OFFER.STATUS.SUSPENDED', OfferStatus.SUSPENDED)
            .build();

        this.subsystemService.getNames().subscribe(
            data => {
                this.subsystems = data.data.map(subsystem => new SelectItemImpl(subsystem.name, subsystem.id));
                this.createTable();
                this.resetDefaultFilterSelection();
                this.initFilterByCurrentUser();
                this.initFilterUnprintedPaymentPackages();
                if (this.route.snapshot.paramMap.get('mode') === 'new') {
                    this.showDialogToAdd();
                }
                this.componentInitialized.complete();
                this.changeDetector.markForCheck();
            });
    }

    resetDefaultFilterSelection() {
        let forcedStatuses = this.route.snapshot.paramMap.get('statusFilter');
        let selectedStatuses: OfferStatus[];
        if (forcedStatuses) {
            selectedStatuses = forcedStatuses.split(',').map(status => OfferStatus[status]);
        }
        if (!this.ignoreStoredFilter) {
            this.lastLoadEvent = this.retrieveStoredLazyLoadEvent(this.storage);
            if (this.lastLoadEvent != undefined) {
                this.applySavedFilter(this.lastLoadEvent);
            }
        } else {
            this.lastLoadEvent = {};
        }
        this.restoreStatusesSelection(selectedStatuses);
    }

    private restoreStatusesSelection(selectedStatuses: OfferStatus[]): void {
        if (selectedStatuses != undefined) {
            this.selectedStatuses = selectedStatuses;
            if (!this.columnByField['status']) {
                this.onDisplayedColumnsChange([...this.selectedColumns, 'status']);
            }
        }
        if (this.selectedStatuses.length > 0) {
            const statusColumn = this.getColumns().find(column => column.field === 'status');
            if (statusColumn != undefined) {
                statusColumn.defaultFilterValue = {
                    label: '',
                    value: this.selectedStatuses.join(';')
                };
            }
            this.getDatatable().setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');
        }
    }

    getExportData(): Observable<object[]> {
        return of([]);
    }

    isPermittedToAdd(): boolean {
        return this.permissions.isKoordynator() || this.permissions.isOpiekun();
    }

    showDialogToAdd(): void {
        if (!this.isPermittedToAdd()) {
            return;
        }
        this.editedPaymentPackage = new PaymentPackage();
        this.editedPaymentPackage.completionDate = new Date();
        this.newItem = true;
        this.changeDetector.markForCheck();
    }

    handleWizardStepChange(event: WizardStepChangeEvent): void {
        if (event.isBack()) {
            this.validationErrors['orders'] = undefined;
        } else if (event.oldId === this.STEPS.SUBSYSTEM.id) {
            this.editedPaymentPackage.orders = [];
            this.createDetails.loadSubsystem();
            this.selectOrders.loadOrders();
        }
    }

    submit(): void {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        let observable: Observable<number>;
        if (this.newItem) {
            observable = this.paymentPackageService.addItem(this.editedPaymentPackage);
        } else {
            observable = this.paymentPackageService.editItem(this.editedPaymentPackage.id, this.editedPaymentPackage).pipe(
                tap(() => {
                    this.growlMessageController.info('PAYMENT_PACKAGE.UPDATED');
                }));
        }
        observable.pipe(finalize(() => this.setSaveInProgress(false))).subscribe({
            next: newId => {
                this.newItem = false;
                this.displayEditingWizard = false;
                this.selectedPaymentPackage = new PaymentPackage();
                this.selectedPaymentPackage.id = newId;
                this.loadItemsLazy(this.lastLoadEvent);
            },
            error: (error: HttpErrorResponse) => {
                const errorResponse = new ErrorResponse(error.error);
                if (errorResponse.is400()) {
                    this.validationErrors = Object.assign({}, errorResponse.invalidFields);
                    this.changeDetector.markForCheck();
                    return;
                }
                throw new ResponseError(error);
            }
        });
    }

    cancel(): void {
        this.newItem = false;
        this.displayEditingWizard = false;
    }

    onRowSelect(event: { originalEvent: any, data: PaymentPackage }): void {
        if (this.isEditEnabled(event.data)) {
            this.showEditDialog(event.data.id);
        }
    }

    showEditDialog(paymentPackageId: number): void {
        if (this.subsystems == undefined) {
            return;
        }
        this.paymentPackageService.getItem(paymentPackageId).subscribe(paymentPackage => {
            this.editedPaymentPackage = paymentPackage;
            this.displayEditingWizard = true;
            this.changeDetector.markForCheck();
        });
    }

    getDatatable(): DataTable {
        return this.paymentPackagesTable;
    }

    getFirstInputId(): string {
        return 'transportValue';
    }

    loadItemsLazy(event: LazyLoadEvent, updateOriginalTableFilters = true): void {
        this.componentInitialized.subscribe({
            complete: () => {
                this.loadItemsLazySubscription(event, updateOriginalTableFilters);
            }
        });
    }

    private loadItemsLazySubscription(event: LazyLoadEvent, updateOriginalTableFilters: boolean): void {
        super.loadItemsLazy(event);
        this.filterCurrentUser(event);
        this.filterByUnprintedPaymentPackages(event);
        this.filterStatuses(event);
        this.filterSubsystems(event);
        this.filterExchangeRate(event);
        this.lastLoadEvent = event;
        this.storeLazyLoadEvent(event, this.storage);
        this.onDataLazyLoad.emit(this.lastLoadEvent);
        if (updateOriginalTableFilters) {
            this.onUpdateOriginalFilters.emit(this.lastLoadEvent);
        }
        this.paymentPackageService.getItems(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    this.paymentPackages = data.data;
                    this.totalRecords = data.totalRecords;
                    this.totalRecordsChange.emit(this.totalRecords);
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    this.selectedPaymentPackage = this.restoreSelectionAfterLoad(this.selectedPaymentPackage, this.paymentPackages, event);
                },
                error: () => {
                    console.error('PaymentPackageComponent - Failed to retrieve payment package list - check filters and try again.');
                },
                complete: () => {
                    this.changeDetector.markForCheck();
                }
            });
    }

    removeFiltersNotSetOnTableColumns(): void {
        this.filterByCurrentUser = false;
        this.filterUnprintedPaymentPackages = undefined;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    enableFilterByCurrentUser(): void {
        this.filterByCurrentUser = true;
        this.loadItemsLazy(this.lastLoadEvent);
    }

    private initFilterByCurrentUser(): void {
        if (this.filterByCurrentUser == undefined) {
            this.filterByCurrentUser = true;
        }
        if (this.route.snapshot.paramMap.has('forCurrentUser')) {
            this.filterByCurrentUser = this.route.snapshot.paramMap.get('forCurrentUser') === 'true';
        }
    }

    private initFilterUnprintedPaymentPackages() {
        if (this.route.snapshot.paramMap.has('unprintedPaymentPackages')) {
            this.filterUnprintedPaymentPackages = this.route.snapshot.paramMap.get('unprintedPaymentPackages') === 'true';
        }
    }

    private filterCurrentUser(event: LazyLoadEvent): void {
        event.filters['forCurrentUser'] = {value: this.filterByCurrentUser, matchMode: undefined};
    }

    private filterByUnprintedPaymentPackages(event: LazyLoadEvent): void {
        if (this.filterUnprintedPaymentPackages != undefined) {
            event.filters['unprintedPaymentPackages'] = {value: this.filterUnprintedPaymentPackages, matchMode: undefined};
        } else {
            event.filters['unprintedPaymentPackages'] = undefined;
        }
    }

    private filterSubsystems(event: LazyLoadEvent): void {
        if (this.selectedSubsystems.length > 0) {
            event.filters['subsystemIds'] = {
                value: this.selectedSubsystems.join(';'),
                matchMode: undefined
            };
        } else {
            event.filters['subsystemIds'] = undefined;
        }
    }

    private filterStatuses(event: LazyLoadEvent): void {
        let allStatuses = '';
        if (this.selectedStatuses.length > 0) {
            this.selectedStatuses.forEach(item => allStatuses += (item + ';'));
        } else {
            this.availableStatuses.forEach(item => allStatuses += (item.value + ';'));
        }
        event.filters['status'] = {value: allStatuses};
    }

    private filterExchangeRate(event: LazyLoadEvent): void {
        this.exchangeService.applyRateToRangeFilters(['transportNetValue', 'netValue', 'grossValue'], event, Currencies.PLN);
    }

    private createTable(): void {
        let builder = DataTableColumnBuilder.create()
            .add('name', 'PAYMENT_PACKAGE.LIST.NAME', true)
            .add('netValue', 'PAYMENT_PACKAGE.LIST.NET_VALUE', true)
            .add('grossValue', 'PAYMENT_PACKAGE.LIST.GROSS_VALUE', true)
            .add('creationDate', 'PAYMENT_PACKAGE.LIST.CREATION_DATE', true)
                .setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING)
            .add('completionDate', 'PAYMENT_PACKAGE.LIST.COMPLETION_DATE', true)
                .setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING)
            .add('status', 'PAYMENT_PACKAGE.LIST.STATUS', true)
            .add('ownerName', 'PAYMENT_PACKAGE.LIST.OWNER', true)
            .add('subsystemName', 'PAYMENT_PACKAGE.LIST.SUBSYSTEM', true)
            .add('clientManagerName', 'PAYMENT_PACKAGE.LIST.CLIENT_MANAGER', true)
            .add('subClientManagerName', 'PAYMENT_PACKAGE.LIST.SUB_CLIENT_MANAGER', false)
            .add('transportValue', 'PAYMENT_PACKAGE.LIST.TRANSPORT_VALUE', true)
            .add('paymentPackageCorrection', 'PAYMENT_PACKAGE.LIST.PAYMENT_PACKAGE_CORRECTION', true);
        this.init(builder.build());
        this.saveShownColumns();
    }

    saveShownColumns() {
        const shownColumns = super.saveShownColumns();
        this.onVisibleColumnsChange.emit(shownColumns);
        return shownColumns;
    }

    validateSubsystem(): Observable<boolean> {
        const validationErrors = {};
        if (this.editedPaymentPackage.subsystemId == undefined) {
            validationErrors['subsystemId'] = 'error.paymentPackageDto.subsystemId.not_null';
        }
        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.paymentPackageService.validateSubsystemData(this.editedPaymentPackage).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    validateForm(): Observable<boolean> {
        const validationErrors = {};
        if (this.editedPaymentPackage.name == undefined) {
            validationErrors['name'] = 'error.paymentPackageDto.name.not_null';
        } else if (this.editedPaymentPackage.name.length === 0 || this.editedPaymentPackage.name.length > 100) {
            validationErrors['name'] = 'error.paymentPackageDto.name.not_in_range';
        }

        if (this.editedPaymentPackage.transportValue == undefined) {
            validationErrors['transportValue'] = 'error.paymentPackageDto.transportValue.not_null';
        } else if (this.editedPaymentPackage.transportValue < 0) {
            validationErrors['transportValue'] = 'error.paymentPackageDto.transportValue.below_min';
        } else if (this.editedPaymentPackage.transportValue > 99999) {
            validationErrors['transportValue'] = 'error.paymentPackageDto.transportValue.over_max';
        }

        if (this.editedPaymentPackage.paymentPackageCorrection < -999999) {
            validationErrors['paymentPackageCorrection'] = 'error.paymentPackageDto.paymentPackageCorrection.below_min';
        } else if (this.editedPaymentPackage.paymentPackageCorrection > 999999) {
            validationErrors['paymentPackageCorrection'] = 'error.paymentPackageDto.paymentPackageCorrection.over_max';
        }

        if (this.validationErrorsPresent(validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return of(false);
        }
        return this.paymentPackageService.validateGeneralData(this.editedPaymentPackage).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    showOrders(paymentPackage: PaymentPackage): void {
        this.router.navigate(['/features/offer', {
            component: 'order',
            ignoreStoredFilter: true
        }], {queryParams: {paymentPackageName: `"${paymentPackage.name}"`}});
    }

    showProductionOrders(paymentPackage: PaymentPackage): void {
        this.router.navigate(['/features/offer', {
            component: 'production-order',
            ignoreStoredFilter: true
        }], {queryParams: {paymentPackageId: paymentPackage.id}});
    }

    deletePaymentPackage(paymentPackageId: number): void {
        this.blockUiController.block(PaymentPackageComponent.PACKAGE_DELETE_BLOCK_ID);
        this.paymentPackageService.deletePackage(paymentPackageId).subscribe({
            next: () => {
                this.blockUiController.unblock(PaymentPackageComponent.PACKAGE_DELETE_BLOCK_ID);
                this.growlMessageController.info('PAYMENT_PACKAGE.DELETED');
                this.loadItemsLazy(this.lastLoadEvent);
            },
            error: error => {
                this.blockUiController.unblock(PaymentPackageComponent.PACKAGE_DELETE_BLOCK_ID);
                this.errors.handle(error);
            }
        });
    }

    checkForBulkAddons(paymentPackageId: number): void {
        this.blockUiController.block(PaymentPackageComponent.MOTLAWA_CHECK_ADDONS_BLOCK_ID);
        forkJoin({
            addons: this.motlawaIntegrationService.checkForBulkAddons(paymentPackageId),
            offerCharges: this.motlawaIntegrationService.checkForOfferCharges(paymentPackageId)
        }).subscribe(
            data => {
                let addonsData = data.addons;
                let offerCharges = data.offerCharges;
                this.motlawaIntegrationDialogsData = new MotlawaIntegrationDialogsData(addonsData.data, offerCharges.data, paymentPackageId);
                if (addonsData.totalRecords > 0 || offerCharges.totalRecords > 0) {
                    this.visibleDialog = 'MOTLAWA_ADDONS_DISTRIBUTION';
                    this.changeDetector.markForCheck();
                } else {
                    this.generateMotlawaData(paymentPackageId);
                }
                this.blockUiController.unblock(PaymentPackageComponent.MOTLAWA_CHECK_ADDONS_BLOCK_ID);
            });
    }

    generateMotlawaData(paymentPackageId: number): void {
        this.blockUiController.block(PaymentPackageComponent.MOTLAWA_GENERATE_DATA_BLOCK_ID);
        this.motlawaIntegrationService.generateMotlawaData(paymentPackageId, this.motlawaIntegrationDialogsData.addonsSelections.selections, this.motlawaIntegrationDialogsData.offerChargesSelections.selections)
            .pipe(finalize(() => this.blockUiController.unblock(PaymentPackageComponent.MOTLAWA_GENERATE_DATA_BLOCK_ID))).subscribe({
            next: results => {
                this.motlawaIntegrationDialogsData.results = results;
                this.visibleDialog = 'MOTLAWA_DATA';
                this.changeDetector.markForCheck();
            },
            error: error => {
                this.errors.handle(error, true);
            }
        });
    }

    changeStatusAction(paymentPackage: PaymentPackage): void {
        this.possibleTransitions = this.createPossibleTransitions(paymentPackage);

        if (this.possibleTransitions.length === 1) {
            this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
            this.possibleTransitions[0].item.command();
        } else if (this.possibleTransitions.length > 1) {
            this.visibleDialog = 'STATUS_CHANGE';
        }
    }

    private createPossibleTransitions(paymentPackage: PaymentPackage): StatusTransition[] {
        return this.statusTransitionDialogService.createPaymentPackagePossibleTransitions(paymentPackage,
            (): void => {
                this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                this.growlMessageController.info('PAYMENT_PACKAGE.UPDATED');
                this.loadItemsLazy(this.lastLoadEvent);
            },
            (): void => {
                this.blockUiController.unblock(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                this.growlMessageController.error('PAYMENT_PACKAGE.UPDATE_FAILED');
                this.loadItemsLazy(this.lastLoadEvent);
            });
    }

    getChangeStatusActionTooltip(paymentPackage: PaymentPackage): string {
        let possibleTransitions = this.createPossibleTransitions(paymentPackage);

        if (possibleTransitions.length > 1) {
            return 'OFFER.ACTIONS.TOOLTIPS.SELECT_NEXT_STEP';
        } else if (possibleTransitions.length === 1) {
            return possibleTransitions[0].item.label;
        } else {
            return '';
        }
    }

    hideDialog(): void {
        this.visibleDialog = undefined;
    }

    changeStatus(selectedTransition: StatusTransition): void {
        if (selectedTransition) {
            if (selectedTransition.action === 'CHANGE_STATUS') {
                this.blockUiController.block(StatusTransitionHelper.TRANSITION_CHANGE_BLOCK_ID);
                selectedTransition.item.command();
                this.hideDialog();
            } else {
                console.error("Unsupported status transition.", selectedTransition.action);
            }
        }
    }

    isChangeStatusEnabled(paymentPackage: PaymentPackage): boolean {
        return this.createPossibleTransitions(paymentPackage).length > 0;
    }

    buildRowActions(paymentPackage: PaymentPackage): MenuElement[] {
        let rowActions: MenuElement[] = [];

        if (this.isChangeStatusEnabled(paymentPackage) && this.isEditEnabled(paymentPackage)) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey(this.getChangeStatusActionTooltip(paymentPackage))
                .setIdentifier(this.IDENTIFIER_CHANGE_STATUS + paymentPackage.id)
                .build());
        }
        rowActions.push(new MenuElementBuilder()
            .setTranslationKey("PAYMENT_PACKAGE.SHOW_ORDERS")
            .setIdentifier(this.IDENTIFIER_SHOW_ORDERS + paymentPackage.id)
            .build());

        if (this.permissions.isKoordynator() || this.permissions.isOpiekun()) {
            if (paymentPackage.status === 'PAID') {
                rowActions.push(new MenuElementBuilder()
                    .setTranslationKey("PAYMENT_PACKAGE.SHOW_PRODUCTION_ORDERS")
                    .setIdentifier(this.IDENTIFIER_SHOW_PRODUCTION_ORDERS + paymentPackage.id)
                    .build());
            }
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey("PAYMENT_PACKAGE.GENERATE_MOTLAWA_DATA")
                .setIdentifier(this.IDENTIFIER_GENERATE_MOTLAWA_DATA + paymentPackage.id)
                .build());
        }
        if (paymentPackage.empty && this.isEditEnabled(paymentPackage)) {
            rowActions.push(new MenuElementBuilder()
                .setTranslationKey("PAYMENT_PACKAGE.DELETE")
                .setIdentifier(this.IDENTIFIER_DELETE + paymentPackage.id)
                .build());
        }
        return rowActions;
    }

    handleRowAction(event: ButtonWithMenuElementSelectedEvent): void {
        if (event.identifier.startsWith(this.IDENTIFIER_CHANGE_STATUS)) {
            let ppId = event.identifier.substring(this.IDENTIFIER_CHANGE_STATUS.length);
            let found = this.paymentPackages.find(paymentPackage => paymentPackage.id === +ppId);
            if (found) {
                this.changeStatusAction(found);
            } else {
                console.error("CHANGE_STATUS Action called for non existing paymentPackageId " + ppId);
            }
        } else if (event.identifier.startsWith(this.IDENTIFIER_SHOW_ORDERS)) {
            let ppId = event.identifier.substring(this.IDENTIFIER_SHOW_ORDERS.length);
            let found = this.paymentPackages.find(paymentPackage => paymentPackage.id === +ppId);
            if (found) {
                this.showOrders(found);
            } else {
                console.error("SHOW_ORDERS Action called for non existing paymentPackageId " + ppId);
            }
        } else if (event.identifier.startsWith(this.IDENTIFIER_SHOW_PRODUCTION_ORDERS)) {
            let ppId = event.identifier.substring(this.IDENTIFIER_SHOW_PRODUCTION_ORDERS.length);
            let found = this.paymentPackages.find(paymentPackage => paymentPackage.id === +ppId);
            if (found) {
                this.showProductionOrders(found);
            } else {
                console.error("SHOW_PRODUCTION_ORDERS Action called for non existing paymentPackageId " + ppId);
            }
        } else if (event.identifier.startsWith(this.IDENTIFIER_GENERATE_MOTLAWA_DATA)) {
            let ppId = event.identifier.substring(this.IDENTIFIER_GENERATE_MOTLAWA_DATA.length);
            let found = this.paymentPackages.find(paymentPackage => paymentPackage.id === +ppId);
            if (found) {
                this.checkForBulkAddons(found.id);
            } else {
                console.error("GENERATE_MOTLAWA_DATA Action called for non existing paymentPackageId " + ppId);
            }
        } else if (event.identifier.startsWith(this.IDENTIFIER_DELETE)) {
            let ppId = event.identifier.substring(this.IDENTIFIER_DELETE.length);
            let found = this.paymentPackages.find(paymentPackage => paymentPackage.id === +ppId);
            if (found) {
                this.deletePaymentPackage(found.id);
            } else {
                console.error("DELETE Action called for non existing paymentPackageId " + ppId);
            }
        } else {
            console.error("Action called for non existing paymentPackageId " + event.identifier);
        }
    }

    isEditEnabled(paymentPackage: PaymentPackage): boolean {
        return this.permissions.isKoordynator() || this.permissions.isOpiekun();
    }

    isPaid(paymentPackage: PaymentPackage): boolean {
        return paymentPackage.status === OfferStatus.PAID;
    }

    applySavedFilterAndVisibleColumns(filter: LazyLoadEvent, visibleColumns: SavedShownColumns): void {
        this.updateVisibleColumns(visibleColumns);
        this.getDatatable().filters = {};
        this.applySavedFilter(filter);
        this.loadItemsLazy(this.lastLoadEvent, false);
    }

    applySavedFilter(filter: LazyLoadEvent): void {
        // delete legacy filter
        delete filter.filters.subsystemName;

        this.lastLoadEvent.filters = filter.filters;
        this.lastLoadEvent.sortOrder = filter.sortOrder;
        this.lastLoadEvent.sortField = filter.sortField;
        this.applyExtraHeaderFilters(filter);
        this.applyMultiSelectFilters(filter);
        this.applyLazyLoadEventData(filter);
        this.resetTableFilterInputs(this.getDatatable());
    }

    applyExtraHeaderFilters(filter: LazyLoadEvent): void {
        const currentUserFilter = filter.filters['forCurrentUser'];
        if (currentUserFilter != undefined) {
            this.filterByCurrentUser = '' + currentUserFilter.value === 'true';
        }
    }

    applyMultiSelectFilters(filter: LazyLoadEvent): void {
        SavedFilterService.applyMultiSelectFilters(this.selectedStatuses, filter.filters['status']);
        this.paymentPackagesTable.setFilterValue(this.selectedStatuses.join(';'), 'status', 'in');

        this.applySubsystemMultiselectFilter(filter.filters['subsystemIds']);
        this.paymentPackagesTable.setFilterValue(this.selectedSubsystems.join(';'), 'subsystemIds', 'in');
    }

    private applySubsystemMultiselectFilter(filter: any) {
        this.selectedSubsystems.splice(0, this.selectedSubsystems.length);
        if (filter != null) {
            filter.value.split(';').forEach(filterValue => {
                if (filterValue) {
                    const subsystem = this.subsystems.find(selectItem => '' + selectItem.value === filterValue);
                    if (subsystem != undefined) {
                        this.selectedSubsystems.push(subsystem.value);
                    }
                }
            });
        }
    }
}
