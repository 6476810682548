import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {WebshopAreaBarsCode} from '../../../../../../window-designer/webshop-area-bars/WebshopAreaBarsCode';
import {TranslatedSelectItemService} from '../../../../../common/service/translated-select-item.service';
import {WindowSystemWebshopSettings} from '../window-system-webshop-settings';

@Component({
    selector: 'app-window-system-webshop-area-bars-form',
    templateUrl: './window-system-webshop-area-bars-form.component.html',
    styleUrls: ['./window-system-webshop-area-bars-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [TranslatedSelectItemService]
})
export class WindowSystemWebshopAreaBarsFormComponent implements OnInit {

    @Input()
    windowSystemWebshopSettings: WindowSystemWebshopSettings;

    webshopAreaBarCodes: Observable<SelectItem[]>;

    constructor(private translatedSelectItemService: TranslatedSelectItemService) {
    }

    ngOnInit() {
        const codes = Object.keys(WebshopAreaBarsCode).filter(code => code !== WebshopAreaBarsCode.NONE);
        this.webshopAreaBarCodes = this.translatedSelectItemService.buildUnsortedDropdown(codes, '', undefined);
    }

    getIcon(webshopAreaBarsCode: WebshopAreaBarsCode): string {
        return `assets/images/area-bars-icons/area-bars-${webshopAreaBarsCode}.svg`;
    }

    isSelected(webshopAreaBarsCode: WebshopAreaBarsCode): boolean {
        return this.windowSystemWebshopSettings.availableAreaBars.indexOf(webshopAreaBarsCode) >= 0;
    }

    changeSelection(webshopAreaBarsCode: WebshopAreaBarsCode, selected: boolean): void {
        const index = this.windowSystemWebshopSettings.availableAreaBars.indexOf(webshopAreaBarsCode);
        if (selected) {
            if (index >= 0) {
                return;
            }
            this.windowSystemWebshopSettings.availableAreaBars.push(webshopAreaBarsCode);
        } else {
            if (index < 0) {
                return;
            }
            this.windowSystemWebshopSettings.availableAreaBars.splice(index, 1);
        }
    }
}
