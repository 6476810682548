import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {Table, TablePageEvent} from "primeng/table";
import {DatatableInterface, TableToDatatableInterfaceAdapter} from 'src/app/common/DatatableHelper';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ComponentWithUserConfigAndPaginator, KeepSelectedItemEventParams} from "../../../common/crud-common/paginable.component";
import {SubsystemService} from '../subsystem.service';
import {SubsystemUserActivityReport} from "./subsystem-user-activity-report";

@Component({
    selector: 'app-subsystem-user-activity-report',
    templateUrl: './subsystem-user-activity-report.component.html',
    styleUrls: ['./subsystem-user-activity-report.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemUserActivityReportComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    @Input()
    subsystemId: number;

    @ViewChild('table', { static: true })
    table: Table;

    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;

    summary: SubsystemUserActivityReport;
    usersReport: SubsystemUserActivityReport[];

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private readonly subystemService: SubsystemService,
                private readonly errors: CommonErrorHandler,) {
        super(injector, changeDetector, 'SubsystemUserActivityReportComponent', false);
    }

    ngOnInit(): void {
        this.showHideFilters(true);
        this.subystemService.getUserActivityReport(this.subsystemId).subscribe({
            next: report => {
                this.summary = new SubsystemUserActivityReport();
                this.usersReport = report;
                for (let row of report) {
                    if (this.summary.lastUserLoginDate == undefined
                        || (row.lastUserLoginDate != undefined && row.lastUserLoginDate > this.summary.lastUserLoginDate)) {
                        this.summary.lastUserLoginDate = row.lastUserLoginDate;
                    }
                    if (this.summary.lastOfferCreationDate == undefined
                        || (row.lastOfferCreationDate != undefined && row.lastOfferCreationDate > this.summary.lastOfferCreationDate)) {
                        this.summary.lastOfferCreationDate = row.lastOfferCreationDate;
                    }
                    if (this.summary.lastOfferModificationDate == undefined
                        || (row.lastOfferModificationDate != undefined && row.lastOfferModificationDate > this.summary.lastOfferModificationDate)) {
                        this.summary.lastOfferModificationDate = row.lastOfferModificationDate;
                    }
                    if (this.summary.lastOrderCreationDate == undefined
                        || (row.lastOrderCreationDate != undefined && row.lastOrderCreationDate > this.summary.lastOrderCreationDate)) {
                        this.summary.lastOrderCreationDate = row.lastOrderCreationDate;
                    }
                }
                this.totalRecords = report.length;
                this.handlePageChange({ first: 0, rows: this.chosenRowsPerPage });
                this.hideDataLoadingIndicator();
                this.changeDetector.markForCheck();
            },
            error: error => this.errors.handle(error)
        })
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    columnHeaderClasses(field: string): string {
        return super.columnHeaderClasses(field) + ' column-header column-value';
    }

    showDialogToAdd(): void {
    }

    submit(): void {
    }

    onRowSelect(event: { data: any; } & KeepSelectedItemEventParams): void {
    }

    handlePageChange(event: TablePageEvent): void {
        this.chosenPageNumber = event.first;
        this.fromRecord = this.chosenPageNumber * this.chosenRowsPerPage + 1;
        this.toRecord = Math.min(this.chosenPageNumber * this.chosenRowsPerPage + event.rows, this.totalRecords);
    }
}
