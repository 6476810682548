import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {JwtDto} from '../../auth/jwt-dto';
import {JwtHelperService} from '../../auth/jwt-helper.service';
import {LogoutHelperService} from "../../auth/logout-helper.service";
import {StorageKey, StorageService} from '../../auth/storage.service';
import {SupportedLanguages} from '../../supportedLanguages';
import {KnownApplicationResources} from '../admin-panel/application-resource/application-resource';
import {ApplicationResourceService} from '../admin-panel/application-resource/application-resource.service';
import {ErrorResponse} from '../errors/errorResponse';
import {TermsOfUseService} from '../settings/terms-of-use/terms-of-use.service';
import {TermsOfUse} from '../settings/terms-of-use/termsOfUse';
import {IsAnotherUserLoggedService} from './isAnotherUserLogged';
import {IsLoggedService} from './islogged.service';

interface LoginCredentials {
    login: string;
    password: string;
}

type LoginFormType = 'login' | 'resetPasswordMail' | 'resetPassword';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [AuthService, TermsOfUseService]
})
export class LoginComponent implements OnInit, OnDestroy {

    private static readonly AUTOLOGIN_TOKEN_PARAM = 'token';
    private static readonly RESET_PASSWORD_TOKEN_PARAM = 'resetPasswordToken';
    private static readonly FORM_PARAM = 'form';

    displayedForm: LoginFormType;
    readonly user: LoginCredentials = {login: null, password: null};
    successMessage: string;
    errorMessage: string;
    displayTermsOfUseDialog = false;
    termsOfUse: TermsOfUse;
    private encodedLoginToken: string;
    resetPasswordToken: string;

    private paramMapSubscrption: Subscription;

    constructor(private readonly translate: TranslateService,
                private readonly router: Router,
                private readonly route: ActivatedRoute,
                private readonly storageService: StorageService,
                private readonly auth: AuthService,
                private readonly isLoggedService: IsLoggedService,
                private readonly termsOfUseService: TermsOfUseService,
                private readonly isAnotherUserLoggedService: IsAnotherUserLoggedService,
                private readonly logoutService: LogoutHelperService) {
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParamMap.has(LoginComponent.AUTOLOGIN_TOKEN_PARAM)) {
            this.auth.getUserData(this.route.snapshot.queryParamMap.get(LoginComponent.AUTOLOGIN_TOKEN_PARAM)).subscribe({
                next: tokenObject => this.handleLoginSuccess(tokenObject),
                error: () => {
                    this.logoutService.logout();
                    this.initLoginForm();
                }
            });
            return;
        }

        this.initLoginForm();
    }

    ngOnDestroy(): void {
        if (this.paramMapSubscrption != undefined) {
            this.paramMapSubscrption.unsubscribe();
        }
    }

    private initLoginForm(): void {
        this.paramMapSubscrption = this.route.queryParamMap.subscribe(paramMap => {
            if (paramMap.has(LoginComponent.FORM_PARAM)) {
                this.displayedForm = paramMap.get(LoginComponent.FORM_PARAM) as LoginFormType;
                switch (this.displayedForm) {
                    case 'login':
                        if (!!this.storageService.get(StorageKey.JWT)) {
                            this.router.navigate(['/features']);
                        }
                        break;
                    case 'resetPassword':
                        this.resetPasswordToken = paramMap.get(LoginComponent.RESET_PASSWORD_TOKEN_PARAM);
                        break;
                }
            }
        });

        let initialView = this.route.snapshot.queryParamMap.get(LoginComponent.FORM_PARAM) as LoginFormType;
        if (!initialView) {
            initialView = this.route.snapshot.queryParamMap.has(LoginComponent.RESET_PASSWORD_TOKEN_PARAM)
                ? 'resetPassword'
                : 'login';
            this.router.navigate(['./'], {
                relativeTo: this.route,
                queryParams: {[LoginComponent.FORM_PARAM]: initialView},
                queryParamsHandling: 'merge',
                replaceUrl: true
            });
        }

        if (initialView !== 'login' || !this.storageService.get(StorageKey.JWT)) {
            const browserLang = this.detectLanguage();
            this.translate.setDefaultLang(browserLang);
            this.translate.use(browserLang);
        }
    }

    submit(): void {
        this.auth.login(this.user).subscribe({
            next: response => {
                this.handleLoginSuccess(response);
            },
            error: (error: HttpErrorResponse) => {
                let errorResponse = new ErrorResponse(error.error);
                this.errorMessage = errorResponse.message;
            }
        });
    }

    termsOfUseConfirmed(): void {
        this.termsOfUseService.confirmTermsOfUse(this.encodedLoginToken).subscribe(() => {
            this.displayTermsOfUseDialog = false;
            this.submit();
        });
    }

    termsOfUseDeclined(): void {
        this.termsOfUseService.declineTermsOfUse(this.encodedLoginToken).subscribe(() => {
            this.displayTermsOfUseDialog = false;
            this.errorMessage = 'error.login.declinedTerms';
        });
    }

    private handleLoginSuccess(tokenObject: JwtDto): void {
        this.encodedLoginToken = tokenObject.token;
        if (tokenObject.termsOfUseAccepted) {
            this.completeLoginSuccess(tokenObject);
        } else {
            forkJoin({
                termsOfUse: this.termsOfUseService.getTermsOfUse(this.encodedLoginToken),
                translations: this.setInterfaceLanguage(tokenObject)
            }).subscribe(data => {
                this.termsOfUse = data.termsOfUse;
                this.displayTermsOfUseDialog = true;
            });
        }
    }

    private completeLoginSuccess(tokenObject: JwtDto): void {
        this.storageService.set(StorageKey.JWT, this.encodedLoginToken);
        this.storageService.set(StorageKey.CURRENT_USER_AUTH_DATA, JSON.stringify({...tokenObject, token: undefined}));
        this.setInterfaceLanguage(tokenObject);

        this.storageService.remove(StorageKey.MAIN_JWT);
        this.storageService.remove(StorageKey.MAIN_USER_AUTH_DATA);

        let displaySideMenuLabels = tokenObject.displaySideMenuLabels;
        if (displaySideMenuLabels == undefined) {
            displaySideMenuLabels = true;
        }
        this.storageService.set(StorageKey.DISPLAY_SIDE_MENU_LABELS, '' + displaySideMenuLabels);

        this.isLoggedService.changeState(true);
        this.router.navigate(['/features', {showNews: true}]);

        this.isAnotherUserLoggedService.changeState(false); // reset the state if we were forced to logout by expired token
    }

    private setInterfaceLanguage(tokenObject: JwtDto): Observable<any> {
        let interfaceLanguage = tokenObject.userInterfaceLanguage;
        if (interfaceLanguage == undefined) {
            interfaceLanguage = 'en';
        }
        this.storageService.set(StorageKey.INTERFACE_LANGUAGE, interfaceLanguage);
        this.translate.setDefaultLang(interfaceLanguage);
        return this.translate.use(interfaceLanguage);
    }

    get backgroundImageUrl(): string {
        return ApplicationResourceService.getItemUrlByName(KnownApplicationResources.LOGIN_BACKGROUND);
    }

    private detectLanguage(): string {
        const lang = this.translate.getBrowserLang();
        if (SupportedLanguages.languages.findIndex(sl => sl.code === lang) >= 0) {
            return lang;
        }
        return 'en';
    }

    handleResetPasswordClick() {
        this.router.navigate(['./'], {
            relativeTo: this.route,
            queryParams: {[LoginComponent.FORM_PARAM]: 'resetPasswordMail'},
            queryParamsHandling: 'merge'
        });
        this.errorMessage = undefined;
    }

    handlePasswordSuccessfullyChanged(newPassword: string): void {
        const jwsClaims = JwtHelperService.decodeToken(this.resetPasswordToken);
        this.user.login = jwsClaims.sub;
        this.user.password = newPassword;

        this.submit();
    }

    handleReturnClick() {
        this.router.navigate(['/login'], {queryParams: {form: 'login'}});
        this.errorMessage = undefined;
        this.successMessage = undefined;
    }
}
