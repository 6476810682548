<div class="new-form-60vw">
    <div class="new-form-row">
        <app-select inputId="category" label="{{ 'SUBSYSTEM_NOTES.FORM.CATEGORY' | translate }}"
                    [options]="selectCategories" [ngModel]="note?.categoryId" (ngModelChange)="changeCategory($event)" [required]="true"
                    [(validationMessageKey)]="validationErrors['category']"></app-select>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="date" label="{{ 'SUBSYSTEM_NOTES.FORM.DATE' | translate }}"
                      [utc]="true" [(validationMessageKey)]="validationErrors['date']"
                      [ngModel]="note?.date" (ngModelChange)="changeDate($event)" [required]="true"></app-calendar>
    </div>
    <div class="new-form-row" [style.height]="'400px'">
        <div class="new-form-field" *ngIf="ready">
            <div class="new-form-field-label-container">
                <div><label>{{'SUBSYSTEM_NOTES.FORM.NOTE' | translate}}</label></div>
            </div>
            <div class="new-form-field-input-container">
                <p-editor [ngModel]="note.note" (ngModelChange)="changeNote($event)"
                          [style]="{'height':'350px', 'border': '1px solid #E8E8E8', 'border-radius': '2px'}">
                </p-editor>
                <div *ngIf="validationErrors['note'] != undefined" class="new-form-field-error-message">
                    {{ validationErrors['note'] | translate }}
                </div>
            </div>
        </div>
    </div>
</div>
