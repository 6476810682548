<div *ngIf="!canEdit" class="settings-form-wrapper">
    <div *ngIf="isVisibleForUsersWithRoleOtherThanKoordynator()" class="full-width-container">
        <div class="table-header">
            <div class="title">{{ news.title[currentLang]}}</div>
        </div>
        <p-editor *ngIf="initializeEditor" [readonly]="true" [ngModel]="news.content[currentLang]">
            <p-header></p-header>
        </p-editor>
    </div>
    <div *ngIf="!isVisibleForUsersWithRoleOtherThanKoordynator()" class="full-width-container">
        <div class="table-header">
            <div class="title">{{ 'NEWS.NO_NEWS_MESSAGE' | translate }}</div>
        </div>
    </div>
</div>

<div *ngIf="canEdit">
    <div class="settings-form-wrapper new-form-60vw" *ngIf="news">
        <div class="new-form-row" *ngFor="let role of roles">
            <app-checkbox [inputId]="'role_' + role.roleName" [label]="('ROLE.' + role.roleName) | translate"
                          [(validationMessageKey)]="validationErrors['visibleFor']" [ngModel]="isRoleSelected(role)"
                          (ngModelChange)="handleRoleSelectionChange(role, $event)"></app-checkbox>
        </div>
        <p-tabView class="full-width-container">
            <p-tabPanel *ngFor="let lang of supportedLanguages" header="{{lang.name | translate}}"
                        [headerStyleClass]="validationErrors['title[' + lang.code + ']' ] != undefined || validationErrors['content[' + lang.code + ']' ] != undefined ? 'errors-on-tab': ''">
                <div class="new-form-row">
                    <app-input-text [inputId]="'title_' + lang.code"
                                    label="{{ 'NEWS.TITLE' | translate }}"
                                    [(validationMessageKey)]="validationErrors['title[' + lang.code + ']' ]" [maxlength]="50"
                                    [(ngModel)]="news.title[lang.code]" ></app-input-text>
                </div>
                <div class="new-form-row">
                    <div class="new-form-field">
                        <div class="new-form-field-label-container">
                            <div><label>{{'NEWS.CONTENT' | translate}}</label></div>
                            <div *ngIf="validationErrors['content[' + lang.code + ']' ] != undefined" class="new-form-field-label-error-spacer">
                            </div>
                        </div>
                        <div class="new-form-field-input-container">
                            <p-editor *ngIf="initializeEditor" [ngModel]="news.content[lang.code]" (ngModelChange)="editContent($event, lang.code)"
                                      [style]="{'height':'350px', 'border': '1px solid #E8E8E8', 'border-radius': '2px'}">
                            </p-editor>
                            <div *ngIf="validationErrors['content[' + lang.code + ']' ] != undefined" class="new-form-field-error-message">
                                {{ validationErrors['content[' + lang.code + ']' ] | translate }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="new-form-row">
                    <div class="preview-button-container">
                        <app-simple-button label="{{ 'GENERAL.PREVIEW' | translate }}" icon="pageview" type="action" [size]="40"
                                           (onClick)="openPreview(lang.code)"></app-simple-button>
                    </div>
                </div>
            </p-tabPanel>
        </p-tabView>

        <div class="new-form-row">
            <app-calendar inputId="validFrom" label="{{ 'NEWS.VALID_FROM' | translate }}" [showTime]="true"
                          [(validationMessageKey)]="validationErrors['validFrom']"
                          [(ngModel)]="news.validFrom"></app-calendar>
        </div>

        <div class="new-form-row">
            <app-calendar inputId="validTo" label="{{ 'NEWS.VALID_TO' | translate }}" [showTime]="true"
                          [(validationMessageKey)]="validationErrors['validTo']"
                          [(ngModel)]="news.validTo"></app-calendar>
        </div>

        <div class="new-form-row">
            <app-checkbox [(ngModel)]="news.showOnDashboard"
                          inputId="showOnDashboard"
                          label="{{ 'NEWS.SHOW_ON_DASHBOARD' | translate }}"></app-checkbox>
        </div>

        <div class="new-form-row">
            <app-file-upload (ngModelChange)="handleImageFileChange($event)"
                             [addButtonLabel]="'NEWS.SELECT_FILE_UPLOAD'"
                             [changeButtonLabel]="'NEWS.CHANGE_FILE_UPLOAD'"
                             [deleteButtonLabel]="'NEWS.DELETE_FILE_UPLOAD'"
                             [ngModel]="file"
                             inputId="image"
                             label="{{ 'NEWS.PREVIEW_IMAGE' | translate }}"></app-file-upload>
        </div>
    </div>
</div>

<div class="settings">
    <div class="settings-footer" *ngIf="news">
        <div class="settings-footer-right">
            <app-simple-button *ngIf="!canEdit && isVisibleForUsersWithRoleOtherThanKoordynator()" label="{{ 'GENERAL.PREVIEW' | translate }}" icon="pageview" type="action" [size]="40"
                               (onClick)="openPreview(currentLang)"></app-simple-button>
            <app-simple-button *ngIf="canEdit" label="{{'GENERAL.SAVE' | translate }}" icon="check" type="action"
                               [size]="40" (onClick)="saveNews(false)"></app-simple-button>
            <app-simple-button *ngIf="canEdit" label="{{'NEWS.PUBLISH' | translate }}" icon="check" type="main-action"
                               [size]="40" (onClick)="saveNews(true)"></app-simple-button>
            <app-simple-button *ngIf="canEdit && news.id != undefined && (news | newsColourClass) === 'published-active'" label="{{'NEWS.FINISH' | translate }}" type="filter"
                               [size]="40" (onClick)="finishNews()"></app-simple-button>
        </div>
    </div>
</div>

<app-news-dialog *ngIf="showPreview" [newsTitle]="getCurrentNewsTitle()" [newsContent]="getCurrentNewsContent()" dialogId="newsDialog"
                         (onConfirmed)="closePreview()" (onRemindNewsLater)="closePreview()"></app-news-dialog>

