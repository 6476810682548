import {Directive, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Table} from 'primeng/table';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../DatatableHelper';

@Directive({
    selector: '[app-table-keyboard-navigation]'
})
export class TableKeyboardNavigationDirective {

    // tslint:disable-next-line:no-input-rename
    @Input('app-table-keyboard-navigation')
    rowData: any;

    @Output('app-table-keyboard-navigation-enter')
    onEnterPressed = new EventEmitter<KeyboardEvent>();

    private helper: DatatableInterface;

    constructor(private table: Table) {
        this.helper = TableToDatatableInterfaceAdapter.create(table);
    }

    @HostListener('keydown', ['$event'])
    rowKeyDown(event: KeyboardEvent) {
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            const value = this.table.filteredValue || this.table.value;
            let index = value.indexOf(this.rowData);
            if (value.length - 1 > index) {
                this.table.handleRowClick({
                    originalEvent: event,
                    rowData: value[index + 1],
                    rowIndex: index + 1
                });
                this.helper.focusOnRowWithTabIndex(index + 1);
            }
        } else if (event.key === 'ArrowUp') {
            event.preventDefault();
            const value = this.table.filteredValue || this.table.value;
            let index = value.indexOf(this.rowData);
            if (index > 0) {
                this.table.handleRowClick({
                    originalEvent: event,
                    rowData: value[index - 1],
                    rowIndex: index - 1
                });
                this.helper.focusOnRowWithTabIndex(index - 1);
            }
        } else if (event.key === 'Enter') {
            this.onEnterPressed.emit(event);
            (<HTMLElement>event.target).blur();
        } else if (event.key === 'ArrowRight') {
            const currentPage = Math.floor(this.table.first / this.table.rows);
            const lastPage = Math.ceil(this.table.totalRecords / this.table.rows) || 1;
            if (currentPage !== lastPage) {
                event.preventDefault();
                this.table.onPageChange({
                    first: this.table.first + this.table.rows,
                    rows: this.table.rows
                });
            }
        } else if (event.key === 'ArrowLeft') {
            if (Math.floor(this.table.first / this.table.rows) !== 0) {
                event.preventDefault();
                this.table.onPageChange({
                    first: this.table.first - this.table.rows,
                    rows: this.table.rows
                });
            }
        }
    }
}
