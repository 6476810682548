import {Glazing} from "./catalog-data/glazing";
import {FillingType} from "./drawing-data/FillingType";

export class WindowCommonData extends Glazing {
    fillingType: FillingType;
    fillingWidth: number;
    externalColorId: number;
    internalColorId: number;
    coreColorId: number;
    decorativeFillingId: number;
    fillingId: number;
    glazingBeadId: number;
    grillId: number;
    grillColorId: number;
    mullionId: number;
    glazingPackageId: number;
    glazingCategoryId: number;
    glazingFrameCategoryId: number;

    constructor() {
        super();
        this.clear();
    }

    clear(): void {
        this.fillingType = undefined;
        this.fillingWidth = undefined;
        this.glazingGlassQuantity = undefined;
        this.glass1id = undefined;
        this.glass2id = undefined;
        this.glass3id = undefined;
        this.glass4id = undefined;
        this.externalColorId = undefined;
        this.internalColorId = undefined;
        this.coreColorId = undefined;
        this.decorativeFillingId = undefined;
        this.fillingId = undefined;
        this.glazingBeadId = undefined;
        this.frame1id = undefined;
        this.frame2id = undefined;
        this.frame3id = undefined;
        this.grillId = undefined;
        this.grillColorId = undefined;
        this.mullionId = undefined;
        this.glazingPackageId = undefined;
        this.glazingCategoryId = undefined;
        this.glazingFrameCategoryId = undefined;
    }

    copyFrom(data: WindowCommonData): void {
        this.fillingType = data.fillingType;
        this.fillingWidth = data.fillingWidth;
        this.glazingGlassQuantity = data.glazingGlassQuantity;
        this.glass1id = data.glass1id;
        this.glass2id = data.glass2id;
        this.glass3id = data.glass3id;
        this.glass4id = data.glass4id;
        this.externalColorId = data.externalColorId;
        this.internalColorId = data.internalColorId;
        this.decorativeFillingId = data.decorativeFillingId;
        this.fillingId = data.fillingId;
        this.glazingBeadId = data.glazingBeadId;
        this.frame1id = data.frame1id;
        this.frame2id = data.frame2id;
        this.frame3id = data.frame3id;
        this.grillId = data.grillId;
        this.grillColorId = data.grillColorId;
        this.mullionId = data.mullionId;
        this.coreColorId = data.coreColorId;
        this.glazingPackageId = data.glazingPackageId;
        this.glazingCategoryId = data.glazingCategoryId;
        this.glazingFrameCategoryId = data.glazingFrameCategoryId;
    }
}
