import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {Permissions} from '../../../../auth/permission.service';
import {Subsystem} from '../../../subsystem/subsystem';
import {SubsystemService} from '../../../subsystem/subsystem.service';
import {DashboardReportSettings} from './dashboard-report-settings';

@Component({
    selector: 'app-dashboard-report-settings-form',
    templateUrl: './dashboard-report-settings-form.component.html',
    styleUrls: ['./dashboard-report-settings-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardReportSettingsFormComponent implements OnInit {

    @Input()
    settings: DashboardReportSettings;

    availableSubsystems: Subsystem[] = [];
    selectedSubsystems: Subsystem[] = [];

    constructor(public readonly permissions: Permissions,
                private readonly subsystemService: SubsystemService,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        if (this.permissions.isKoordynator()) {
            this.subsystemService.getItems(null, null, {active: {value: true}}, 'id', 1).subscribe(selectItems => {
                this.availableSubsystems = [];
                this.selectedSubsystems = [];
                const selectedIds = new Set(this.settings.subsystemIds);
                for (let subsystem of selectItems.data) {
                    if (selectedIds.has(subsystem.id)) {
                        this.selectedSubsystems.push(subsystem);
                    } else {
                        this.availableSubsystems.push(subsystem);
                    }
                }
                this.changeDetector.markForCheck();
            });
        }
    }

    handleMoveToSource(moved: Subsystem[]): void {
        for (let movedSubsystem of moved) {
            let selectedIndex = this.settings.subsystemIds.findIndex(subsystemId => subsystemId === movedSubsystem.id);
            if (selectedIndex !== -1) {
                this.settings.subsystemIds.splice(selectedIndex, 1);
            }
        }
    }

    handleMoveToTarget(moved: Subsystem[]): void {
        for (let movedSubsystem of moved) {
            this.settings.subsystemIds.push(movedSubsystem.id);
        }
    }
}
