<div class="flex-row-container">
    <div *ngIf="showMenu" class="second-level-menu">
        <div class="title">
            <div class="text">{{ 'NAVIGATION.ITEMS.OFFER' | translate }}</div>
        </div>

        <app-search [component]="'offer-search'" (searched)="handleOfferSearch($event)"></app-search>

        <div *ngFor="let item of visibileSideNavItems" class="navigation-element"
             [class.active]="isActiveSubComponent(item.cssClass)" (click)="navigateToSubComponent(item.cssClass)" id="{{ item.cssClass }}">
            <div class="name">{{item.name | translate}}</div>
            <div *ngIf="isActiveSubComponent(item.cssClass) && counter != undefined" class="counter">{{counter}}</div>
        </div>

        <div *ngIf="getSavedFilterViewId() != null" class="menu-separator"></div>

        <app-saved-filter *ngIf="getSavedFilterViewId() != null"
                          [viewId]="getSavedFilterViewId()"
                          [currentTableFilters]="currentTableFilters"
                          [currentTableVisibleColumns]="currentTableVisibleColumns"
                          (onFilterSelected)="tableFilterSelected($event)"
                          (onFilterUnselected)="tableFilterUnselected($event)"
                          (onCreateSuccess)="tableFilterCreateSuccess()"
                          (onCreateError)="tableFilterCreateError()"
                          (onDeleteSuccess)="tableFilterDeleteSuccess()"
                          (onDeleteError)="tableFilterDeleteError()"></app-saved-filter>
    </div>
    <div class="content" [ngClass]="{'second-level-menu-hidden': !showMenu}">
        <app-offers *ngIf="isActiveSubComponent('offer')"
                    (totalRecordsChange)="updateCounter($event)"
                    (onDataLazyLoad)="updateCurrentTableFilters($event)"
                    (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"
                    (onVisibleColumnsChange)="updateTableVisibleColumns($event)"></app-offers>

        <app-orders
                *ngIf="isActiveSubComponent('order') && isPermitted({roles:['ROLE_OPERATOR','ROLE_OPIEKUN','ROLE_KOORDYNATOR','ROLE_HANDLOWIEC', 'ROLE_SPRZEDAWCA']})"
                (totalRecordsChange)="updateCounter($event)"
                (onDataLazyLoad)="updateCurrentTableFilters($event)"
                (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"
                (onVisibleColumnsChange)="updateTableVisibleColumns($event)"></app-orders>

        <app-production-orders
                *ngIf="isActiveSubComponent('production-order') && isPermitted({roles:['ROLE_OPIEKUN','ROLE_KOORDYNATOR']})"
                (totalRecordsChange)="updateCounter($event)"
                (onDataLazyLoad)="updateCurrentTableFilters($event)"
                (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"
                (onVisibleColumnsChange)="updateTableVisibleColumns($event)"></app-production-orders>

        <app-payment-package *ngIf="isActiveSubComponent('payment-package')"
                             (totalRecordsChange)="updateCounter($event)"
                             (onDataLazyLoad)="updateCurrentTableFilters($event)"
                             (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"
                             (onVisibleColumnsChange)="updateTableVisibleColumns($event)"></app-payment-package>

        <app-delivery-lists *ngIf="isActiveSubComponent('delivery-lists')"
                            (totalRecordsChange)="updateCounter($event)"
                            (onDataLazyLoad)="updateCurrentTableFilters($event)"
                            (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"></app-delivery-lists>

        <app-shipping-simulation-list *ngIf="isActiveSubComponent('shipping-simulation')"
                                      (totalRecordsChange)="updateCounter($event)"
                                      (onDataLazyLoad)="updateCurrentTableFilters($event)"
                                      (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"></app-shipping-simulation-list>

        <app-complaint-list *ngIf="isActiveSubComponent('complaint')"
                            (totalRecordsChange)="updateCounter($event)"
                            (onDataLazyLoad)="updateCurrentTableFilters($event)"
                            (onUpdateOriginalFilters)="updateOriginalTableFilters($event)"
                            (onVisibleColumnsChange)="updateTableVisibleColumns($event)"></app-complaint-list>

        <app-offer-search-results *ngIf="isActiveSubComponent('offer-search')"></app-offer-search-results>
    </div>
</div>
