import {MultilanguageFieldInterface} from '../../../window-designer/catalog-data/multilanguage-field-interface';
import {SupplierInterface} from '../../../window-designer/catalog-data/supplier-interface';
import {Address} from "../../common/address";
import {Contact} from "../../common/contact";
import {JsonDeserializable} from '../../common/crud-common/crudItem';
import {MotlawaIntegrationInfo} from "../../common/MotlawaIntegrationInfo";
import {MultilanguageField} from "../../supportedLanguages";

@JsonDeserializable
export class Supplier implements SupplierInterface {
    id: number = undefined;
    companyName: string = undefined;
    name: MultilanguageFieldInterface = new MultilanguageField();
    description: string = undefined;
    symbol: string = undefined;
    contact: Contact = new Contact();
    address: Address = new Address();
    active = true;
    windowSupplier = false;
    printRabate = true;
    simulateShipping = false;
    headerComment: string = undefined;
    footerComment: string = undefined;
    offerComment: MultilanguageField = new MultilanguageField();
    topMullionDimensions = false;
    alternativeProductionOrders = false;
    allowSimpleProfitMargins = false;
    applyDealerDiscountToBulkAddons = false;

    static fromJSON(jsonObject: any): Supplier {
        const supplier = new Supplier();
        supplier.id = jsonObject.id;
        supplier.companyName = jsonObject.companyName;
        if (jsonObject.name != undefined) {
            supplier.name = MultilanguageField.fromJSON(jsonObject.name);
        }
        supplier.description = jsonObject.description;
        supplier.symbol = jsonObject.symbol;
        if (supplier.contact != undefined) {
            supplier.contact = Contact.fromJSON(jsonObject.contact);
        }
        if (jsonObject.address != undefined) {
            supplier.address = Address.fromJSON(jsonObject.address);
        }
        supplier.active = jsonObject.active;
        supplier.windowSupplier = jsonObject.windowSupplier;
        supplier.simulateShipping = jsonObject.simulateShipping;
        supplier.printRabate = jsonObject.printRabate;
        supplier.headerComment = jsonObject.headerComment;
        supplier.footerComment = jsonObject.footerComment;
        supplier.offerComment = jsonObject.offerComment || new MultilanguageField();
        supplier.topMullionDimensions = jsonObject.topMullionDimensions;
        supplier.alternativeProductionOrders = jsonObject.alternativeProductionOrders;
        supplier.allowSimpleProfitMargins = jsonObject.allowSimpleProfitMargins;
        supplier.applyDealerDiscountToBulkAddons = jsonObject.applyDealerDiscountToBulkAddons;
        return supplier;
    }
}

@JsonDeserializable
export class SupplierMotlawaIntegrationInfo {

    motlawaWindowsPcvInfo: MotlawaIntegrationInfo;
    motlawaWindowsAluInfo: MotlawaIntegrationInfo;
    motlawaWindowsWoodInfo: MotlawaIntegrationInfo;
    motlawaWindowsMixInfo: MotlawaIntegrationInfo;
    motlawaRoofWindowsInfo: MotlawaIntegrationInfo;
    motlawaEntranceDoorsInfo: MotlawaIntegrationInfo;
    motlawaAddonsInfo: MotlawaIntegrationInfo;
    motlawaGatesInfo: MotlawaIntegrationInfo;
    motlawaOfferChargesInfo: MotlawaIntegrationInfo;

    constructor() {
        this.motlawaWindowsPcvInfo = new MotlawaIntegrationInfo();
        this.motlawaWindowsAluInfo = new MotlawaIntegrationInfo();
        this.motlawaWindowsWoodInfo = new MotlawaIntegrationInfo();
        this.motlawaWindowsMixInfo = new MotlawaIntegrationInfo();
        this.motlawaRoofWindowsInfo = new MotlawaIntegrationInfo();
        this.motlawaEntranceDoorsInfo = new MotlawaIntegrationInfo();
        this.motlawaAddonsInfo = new MotlawaIntegrationInfo();
        this.motlawaGatesInfo = new MotlawaIntegrationInfo();
        this.motlawaOfferChargesInfo = new MotlawaIntegrationInfo();
    }

    static fromJSON(jsonObject: any): SupplierMotlawaIntegrationInfo {
        const supplier = new SupplierMotlawaIntegrationInfo();
        if (jsonObject.motlawaWindowsPcvInfo != undefined) {
            supplier.motlawaWindowsPcvInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaWindowsPcvInfo);
        }
        if (jsonObject.motlawaWindowsAluInfo != undefined) {
            supplier.motlawaWindowsAluInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaWindowsAluInfo);
        }
        if (jsonObject.motlawaWindowsWoodInfo != undefined) {
            supplier.motlawaWindowsWoodInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaWindowsWoodInfo);
        }
        if (jsonObject.motlawaWindowsMixInfo != undefined) {
            supplier.motlawaWindowsMixInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaWindowsMixInfo);
        }
        if (jsonObject.motlawaRoofWindowsInfo != undefined) {
            supplier.motlawaRoofWindowsInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaRoofWindowsInfo);
        }
        if (jsonObject.motlawaEntranceDoorsInfo != undefined) {
            supplier.motlawaEntranceDoorsInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaEntranceDoorsInfo);
        }
        if (jsonObject.motlawaAddonsInfo != undefined) {
            supplier.motlawaAddonsInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaAddonsInfo);
        }
        if (jsonObject.motlawaGatesInfo != undefined) {
            supplier.motlawaGatesInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaGatesInfo);
        }
        if (jsonObject.motlawaOfferChargesInfo != undefined) {
            supplier.motlawaOfferChargesInfo = MotlawaIntegrationInfo.fromJSON(jsonObject.motlawaOfferChargesInfo);
        }
        return supplier;
    }
}
