<p-dialog [resizable]="false" [modal]="true" (onHide)="close()" [visible]="true" [focusOnShow]="false">

    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.CONJUNCTION.HEADER' | translate }}
        </div>
    </p-header>

    <div>
        <div class="scrolled-dialog">
            <p-dataTable [value]="dialogData.conjunctions" id="conjunctionsListTable">
                <p-column field="select" [style]="{'width': '44px'}">
                    <ng-template pTemplate="header">
                        <app-tristate-checkbox [(ngModel)]="allSelectedState"
                                               (ngModelChange)="selectAllValidChange()"></app-tristate-checkbox>
                    </ng-template>

                    <ng-template let-item="rowData" pTemplate="body">
                        <span class="flex-row">
                        <app-checkbox [ngModel]="isSelected(item)" [disabled]="item.containsSignificantChanges"
                                      (ngModelChange)="handleSelection(item)"></app-checkbox>
                        <i *ngIf="item.containsSignificantChanges"  class="material-icons warning-severity-color"
                           title="{{ 'OFFER.POSITIONS.CONJUNCTION.SIGNIFICANT_CHANGES_WARNING' | translate }}">report_problem</i>
                        </span>
                    </ng-template>
                </p-column>
                <p-column header="{{'OFFER.POSITIONS.CONJUNCTION.ACTIONS' | translate}}">
                    <ng-template let-item="rowData" pTemplate="body">
                        <span>
                            <app-simple-button [buttonId]="'edit_' + item.id"
                                               label="{{ 'OFFER.POSITIONS.CONJUNCTION.EDIT' | translate }}"
                                               icon="edit" type="main-action" (onClick)="edit(item.id)"
                                               [inlineLabel]="false"></app-simple-button>
                            <app-simple-button [buttonId]="'delete_' + item.id"
                                               label="{{ 'OFFER.POSITIONS.CONJUNCTION.DELETE' | translate }}"
                                               icon="delete" type="delete" (onClick)="initDelete(item.id)"
                                               [inlineLabel]="false"></app-simple-button>
                        </span>
                    </ng-template>
                </p-column>
                <p-column field="supplierName.{{userLang}}"
                          header="{{'OFFER.POSITIONS.CONJUNCTION.SUPPLIER' | translate}}"></p-column>
                <p-column field="positionsOrderNumbers"
                          header="{{'OFFER.POSITIONS.CONJUNCTION.POSITIONS' | translate}}"></p-column>
            </p-dataTable>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'OFFER.POSITIONS.CONJUNCTION.CREATE_NEW' | translate }}" type="main-action"
                               [size]="40" (onClick)="createNew()"></app-simple-button>
            <app-simple-button label="{{ 'OFFER.POSITIONS.CONJUNCTION.PRINT' | translate }}" type="action"
                               icon="print" [disabled]="selected.length < 1"
                               [size]="40" (onClick)="print()"></app-simple-button>
            <app-simple-button label="{{ 'OFFER.ACTIONS.DIALOGS.BUTTON_CLOSE' | translate }}" type="cancel" [size]="40"
                               (onClick)="close()"></app-simple-button>
        </div>
    </p-footer>

</p-dialog>

<app-confirm-action-dialog *ngIf="toBeDeletedId" (onCancel)="toBeDeletedId = undefined"
                           (onConfirmed)="delete(toBeDeletedId)"
                           [question]="'OFFER.POSITIONS.CONJUNCTION.DELETE_QUESTION' | translate"
                           [header]="'OFFER.POSITIONS.CONJUNCTION.DELETE' | translate"></app-confirm-action-dialog>
