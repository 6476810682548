import {Routes} from "@angular/router";
import {featuresRoutes} from "./features/features.routes";
import {loginRoutes} from "./features/login/login.routes";
import {errorRoutes} from "./features/errors/error.routes";
import {applicationInfoRoutes} from "./application-info/application-info.routes";

export const routes: Routes = [
    ...featuresRoutes,
    ...loginRoutes,
    ...applicationInfoRoutes,
    ...errorRoutes,
];
