import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {WebshopGlazingPackagePerSubsystem} from './webshop-glazing-package-per-subsystem';

@Injectable()
export class WebshopGlazingPackagePerSubsystemService {

    private static readonly API_URL = 'webshopGlazingPackagesPerSubsystem';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<WebshopGlazingPackagePerSubsystem>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<WebshopGlazingPackagePerSubsystem>>(WebshopGlazingPackagePerSubsystemService.API_URL,
            {params: params});
    }

    editItem(item: WebshopGlazingPackagePerSubsystem): Observable<number> {
        return this.http.post<void>(WebshopGlazingPackagePerSubsystemService.API_URL, item).pipe(map(() => item.id));
    }
}
