import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Accordion} from 'primeng/accordion';
import {SelectItem} from 'primeng/api/selectitem';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {AddonInterface} from '../../../../../../window-designer/catalog-data/addon-interface';
import {ColorInterface} from '../../../../../../window-designer/catalog-data/color-interface';
import {Glazing} from "../../../../../../window-designer/catalog-data/glazing";
import {AreaSpecification} from '../../../../../../window-designer/drawing-data/AreaSpecification';
import {DrawingData} from "../../../../../../window-designer/drawing-data/drawing-data";
import {Filling} from "../../../../../../window-designer/drawing-data/Filling";
import {FillingType} from '../../../../../../window-designer/drawing-data/FillingType';
import {Grill} from '../../../../../../window-designer/drawing-data/Grill';
import {GrillGrid} from '../../../../../../window-designer/drawing-data/GrillGrid';
import {GrillGridWithRhombusOnIntersections} from '../../../../../../window-designer/drawing-data/GrillGridWithRhombusOnIntersections';
import {GrillType} from '../../../../../../window-designer/drawing-data/GrillType';
import {Mullion} from '../../../../../../window-designer/drawing-data/Mullion';
import {SubWindowData} from '../../../../../../window-designer/drawing-data/SubWindowData';
import {AddonFor} from "../../../../../../window-designer/enums/AddonFor";
import {GrillTypes} from "../../../../../../window-designer/enums/GrillTypes";
import {ProfilesCompositionDistances} from '../../../../../../window-designer/profiles-composition-distances';
import {GrillHelper} from "../../../../../../window-designer/utils/grill-helper";
import {MullionUtils} from "../../../../../../window-designer/utils/MullionUtils";
import {SidebarIdGenerator} from "../../../../../../window-designer/utils/SidebarIdGenerator";
import {WindowCalculator} from "../../../../../../window-designer/window-calculator";
import {ColorType} from '../../../../../ColorType';
import {GrowlMessageController} from "../../../../../common/growl-message/growl-message-controller";
import {ValidationErrors} from '../../../../../common/validation-errors';
import {Color} from "../../../../window-system/color/color";
import {DistanceFrame} from "../../../../window-system/distance-frame/distanceFrame";
import {GlassChangeEvent} from "../../../../window-system/glass/glassChangeEvent";
import {GlassWithPosition} from "../../../../window-system/glass/glassWithPositions";
import {GlazingBead} from "../../../../window-system/glazing-bead/glazing-bead";
import {GraspGlazingPackage} from "../../../../window-system/grasp-glazing-package/grasp-glazing-package";
import {Grill as GrillDto} from "../../../../window-system/grill/grill";
import {Profile} from "../../../../window-system/profile/profile";
import {WindowSystemDefinition} from "../../../../window-system/window-system-definition/window-system-definition";
import {VeneerComponent} from '../../../veneer/veneer.component';
import {VeneerEvent} from "../../../veneer/VeneerEvent";
import {DecorativeFillingWithColors} from '../../DecorativeFillingWithColors';
import {SidebarSection, SubWindowFieldUsage} from "../../fieldUsage";
import {OtherFillingWithColors} from "../../OtherFillingWithColors";
import {SidebarHelper} from "../../sidebar-helper";
import {WindowComponentPreviewData} from '../../window-component-preview-dialog/window-component-preview-dialog.component';
import {WindowEditorField} from '../../window-editor-field';
import {WindowEditorFieldContentProvider} from '../../window-editor-field-content-provider';
import {WindowEditorWindowSystemInterface} from '../../window-editor-window-system-interface';
import {FillingColorType} from "../FillingColorType";
import {SelectItemFormatters} from "../select-item-formatters";
import {SidebarFieldImageService} from '../sidebar-field-image.service';

export class SubwindowPropertyChangeEvent {
    tabNumber: number;
    areaNumber: number;
}

export class FillingWidthChangeEvent extends SubwindowPropertyChangeEvent {
    fillingWidth: number;
}

@Component({
    selector: 'app-sidebar-subwindow',
    templateUrl: './subwindow.component.html',
    styleUrls: ['../sidebar.component.css', '../../window-editor.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubwindowComponent implements OnChanges, OnDestroy {

    @ViewChildren(Accordion) allAccordions: QueryList<Accordion>;
    @ViewChild('swAcc', {static: true}) swAcc: Accordion;
    @ViewChildren(VeneerComponent) allVeneers: QueryList<VeneerComponent>;

    @Input() windowData: SubWindowData;
    @Input() tabIndex: number;
    @Input() subwindowName: string;
    @Input() readOnlyMode: boolean;
    @Input() drawingData: DrawingData;
    @Input() sidebarOnlyMode: boolean;
    @Input() profileCompositionDistances: ProfilesCompositionDistances;
    @Input() filteredGlazingBeads: { [areaId: string]: GlazingBead[] };
    @Input() fillingTypes: string[];
    @Input() fillingTypesGlassOnly: string[];
    @Input() fillingTypesWithoutDecorativeFilling: string[];
    @Input() allFillings: OtherFillingWithColors[];
    @Input() systemDecorativeFillings: DecorativeFillingWithColors[];
    @Input() grillColors: { [grillId: number]: Color[] } = {};
    @Input() grillRalColors: { [grillId: number]: Color[] } = {};
    @Input() grillNcsColors: { [grillId: number]: Color[] } = {};
    @Input() glazingGlassQuantities: number[];
    @Input() frames: DistanceFrame[];
    @Input() grills: GrillDto[];
    @Input() angledGrills: GrillDto[];
    @Input() mullions: Profile[];
    @Input() glasses: GlassWithPosition[];
    @Input() availableGlassCounts: number[];
    @Input() windowSystemsForVeneer: WindowEditorWindowSystemInterface[];
    @Input() windowSystem: WindowSystemDefinition;
    @Input() availableVentilators: AddonInterface[];
    @Input() availableDrips: AddonInterface[];
    @Input() availableCouplers: AddonInterface[];
    @Input() systemGlazingWidthItems: SelectItem[] = [];
    @Input() availableGlassCountsForDecor: number[];
    @Input() allGlazingPackages: GraspGlazingPackage[];

    @Output() openBusinessTypeChangeDialog = new EventEmitter();
    @Output() onFillingTypeChange = new EventEmitter<{ fillingType: FillingType, where: SubwindowPropertyChangeEvent}>();
    @Output() onFillingWidthChange = new EventEmitter<FillingWidthChangeEvent>();
    @Output() onGrillChange = new EventEmitter<{ grill: Grill, newGrill: Grill }>();
    @Output() onMullionChange = new EventEmitter<{ mullion: Mullion, newId: number }>();
    @Output() veneerEvent = new EventEmitter<VeneerEvent>();
    @Output() onVentilatorChange = new EventEmitter();
    @Output() onDripChange = new EventEmitter();
    @Output() onCouplerChange = new EventEmitter();
    @Output() onFrameChange = new EventEmitter<GlassChangeEvent>();
    @Output() onGlassQuantityChange = new EventEmitter<GlassChangeEvent>();
    @Output() onSingleGlassChange = new EventEmitter<GlassChangeEvent>();
    @Output() onGlobalFillingChange = new EventEmitter<number>();
    @Output() redrawDesigner = new EventEmitter();
    @Output() onGeneralSubWindowValueChange = new EventEmitter();
    @Output() onBeforeGeneralSubWindowValueChange = new EventEmitter();
    @Output() onShowImage = new EventEmitter<WindowComponentPreviewData>();
    @Output() onGlazingQuantityChange = new EventEmitter<GlassChangeEvent>();
    @Output() onGlazingCategoryChange = new EventEmitter<GlassChangeEvent>();
    @Output() onGlazingFrameCategoryChange = new EventEmitter<GlassChangeEvent>();
    @Output() onGlazingPackageChange = new EventEmitter<GlassChangeEvent>();
    @Output() fieldChanged = new EventEmitter<WindowEditorField>();

    SidebarIdGenerator = SidebarIdGenerator;

    validateAll = false;
    glazingValidationErrors: ValidationErrors = {};
    Field = WindowEditorField;
    SidebarSection = SidebarSection;
    fieldUsage: SubWindowFieldUsage;

    FillingColorType = FillingColorType;
    selectItemFormatters: SelectItemFormatters;

    selectingRalColorHeader: string;
    selectingRalGrill: Grill;
    selectingRalColors: Color[];
    private colorBeforeRalSelection: Color;

    availableGlazingPackages: Map<string, Observable<SelectItem[]>> = new Map<string, Observable<SelectItem[]>>();

    private readonly refreshFilteredAddonLists = new BehaviorSubject<void>(undefined);

    private subscription: Subscription;

    visibleFields: WindowEditorField[] = [];

    constructor(public translate: TranslateService,
                public cd: ChangeDetectorRef,
                public imageService: SidebarFieldImageService,
                private growls: GrowlMessageController,
                public windowEditorFieldContentProvider: WindowEditorFieldContentProvider) {
        translate.onLangChange.subscribe(() => this.cd.markForCheck());
        this.fieldUsage = new SubWindowFieldUsage(this);
        this.selectItemFormatters = new SelectItemFormatters(translate);
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    get filteredVentilators(): Observable<SelectItem[]> {
        return combineLatest([
            this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.VENTILATOR),
            this.refreshFilteredAddonLists]
        ).pipe(map(selectItems => this.filterWingAddons(this.availableVentilators, selectItems[0])));
    }

    get filteredDrips(): Observable<SelectItem[]> {
        return combineLatest([
            this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.DRIP),
            this.refreshFilteredAddonLists
        ]).pipe(map(selectItems => this.filterWingAddons(this.availableDrips, selectItems[0])));
    }

    get filteredCouplers(): Observable<SelectItem[]> {
        return combineLatest([
            this.windowEditorFieldContentProvider.getItemsStream(WindowEditorField.COUPLER),
            this.refreshFilteredAddonLists
        ]).pipe(map(selectItems => this.filterWingAddons(this.availableCouplers, selectItems[0])));
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('availableVentilators' in changes || 'availableDrips' in changes || 'availableCouplers' in changes) {
            this.refreshFilteredAddonLists.next();
        }
    }

    private filterWingAddons(addons: AddonInterface[], selectItems: SelectItem[]): SelectItem[] {
        if (!WindowCalculator.isSubWindowFixed(this.windowData)) {
            return selectItems;
        }
        return selectItems.filter(selectItem => {
            const addon = addons.find(a => a.id === selectItem.value);
            return addon != undefined && AddonFor[addon.addonFor] !== AddonFor.WING;
        });
    }

    onTypeChange(): void {
        this.refreshFilteredAddonLists.next();
    }

    private emitOnFillingTypeChange(fillingType: FillingType, areaNumber: number) {
        this.onFillingTypeChange.emit({fillingType: fillingType, where: {tabNumber: this.tabIndex, areaNumber: areaNumber}});
    }

    private emitOnFillingNameChange(areaNumber: number) {
        this.emitOnFillingChange(areaNumber);
        this.cd.markForCheck();
        this.redrawDesigner.emit();
    }

    emitOnGrillChange(grill: Grill, newId: number, colorChange = false) {
        let newGrill = JSON.parse(JSON.stringify(grill));
        newGrill.id = colorChange ? newGrill.id : newId;
        newGrill.colorId = colorChange ? newId : newGrill.colorId;
        this.onGrillChange.emit({grill: grill, newGrill: newGrill});
        this.onGlobalFillingChange.emit();
        this.fieldChanged.emit(WindowEditorField.GRILL);
    }

    emitOnMullionChange(mullion: Mullion, newMullionId: number) {
        this.onMullionChange.emit({mullion: mullion, newId: newMullionId});
        this.fieldChanged.emit(WindowEditorField.MULLION);
    }

    private emitOnFillingChange(areaNumber?: number) {
        this.onGlobalFillingChange.emit(areaNumber);
        this.markForCheck();
    }

    filterUsableMullions(mullion: Mullion, subwindowData: SubWindowData) {
        return MullionUtils.filterUsableMullions(this.mullions, mullion, subwindowData);
    }

    onGlassQnChange(area: AreaSpecification, newValue: number): void {
        const event = new GlassChangeEvent(null, newValue, area, this.windowData.generatedId);
        this.onGlassQuantityChange.emit(event);
        this.emitOnFillingChange();
        this.onGeneralSubWindowValueChange.emit();
    }

    onGlassChange(area: AreaSpecification, event: GlassChangeEvent) {
        event.area = area;
        event.subwindowId = this.windowData.generatedId;
        this.onSingleGlassChange.emit(event);
        this.emitOnFillingChange();
        this.onGeneralSubWindowValueChange.emit();
    }

    emitFrameChanged(area: AreaSpecification) {
        let event = new GlassChangeEvent(null, null, area, this.windowData.generatedId);
        this.onFrameChange.emit(event);
        this.emitOnFillingChange();
        this.onGeneralSubWindowValueChange.emit();
    }

    emitOnSubwindowAddonChange(newId: string, addonType: WindowEditorField): void {
        if (!this.fieldUsage.show(addonType)) {
            console.warn('Changing subwindow ' + WindowEditorField[addonType] + ' addon is diabled for arc shapes.');
            return;
        }
        if (newId === "undefined") {
            newId = null;
        }
        this.onBeforeGeneralSubWindowValueChange.emit();
        switch (addonType) {
            case WindowEditorField.VENTILATOR:
                this.onVentilatorChange.emit(newId);
                break;
            case WindowEditorField.DRIP:
                this.onDripChange.emit(newId);
                break;
            case WindowEditorField.COUPLER:
                this.onCouplerChange.emit(newId);
                break;
            default:
                console.error('emitOnSubwindowAddonChange - Unsupported addon type: ' + WindowEditorField[addonType]);
                break;
        }
    }

    getSubWindowDimensions(subWindow: SubWindowData): number[] {
        let width = subWindow.points[2] - subWindow.points[0];
        let height = subWindow.points[5] - subWindow.points[3];
        return [width, height];
    }

    getColorsForFilling(area: AreaSpecification, colorType: FillingColorType): ColorInterface[] {
        if (area.filling.type === FillingType.FILLING) {
            let filling = this.allFillings.find(f => f.id === area.filling.fillingId);
            if (filling != undefined) {
                switch (colorType) {
                    case FillingColorType.INTERNAL:
                        return filling.internalColors;
                    case FillingColorType.EXTERNAL:
                        return filling.externalColors;
                    case FillingColorType.CORE:
                        return [filling.coreColor];
                }
            }
        } else if (area.filling.type === FillingType.DECORATIVE_FILLING) {
            let filling = this.systemDecorativeFillings.find(f => f.id === area.filling.decorativeFillingId);
            if (filling != undefined) {
                switch (colorType) {
                    case FillingColorType.INTERNAL:
                        return filling.internalColors;
                    case FillingColorType.EXTERNAL:
                        return filling.externalColors;
                    case FillingColorType.CORE:
                        return [filling.coreColor];
                }
            }
        }
        return [];
    }

    /**
     * For a given grill object returns array of strings representing it's type as the first element and dimensions
     * (COLUMNSxROWS) as the second.
     * @param {Grill} grill - instance of Grill based object
     */
    getGrillType(grill: Grill): string[] {
        let size = "";
        switch (grill.type) {
            case GrillType.GRID_STANDARD:
                size += (grill as GrillGrid).columns + "x" + (grill as GrillGrid).rows;
                break;
            case GrillType.GRID_RHOMBUS:
                size += (grill as GrillGridWithRhombusOnIntersections).columns + "x"
                    + (grill as GrillGridWithRhombusOnIntersections).rows;
                size += " ";
                size += (grill as GrillGridWithRhombusOnIntersections).rhombusWidth + "x"
                    + (grill as GrillGridWithRhombusOnIntersections).rhombusHeight;
                break;
        }
        return [GrillType[grill.type], size];
    }

    getAllowedGrills(grill: Grill, area: AreaSpecification): GrillDto[] {
        let allowedGrills;
        if (GrillHelper.isDrawnAtAngle(grill)) {
            allowedGrills = this.angledGrills;
        } else {
            allowedGrills = this.grills;
        }

        let grillIds = [...area.grills.map(g => g.id)];
        let areaInnerGrills = grillIds.map(id => this.grills.find(g => g.id === id)).filter(g => g.type === GrillTypes.INNER);
        let areaNotCombinedGrills = areaInnerGrills.filter(g => g.type === GrillTypes.INNER && g.canNotBeCombinedWithOtherGrills);
        let selectedGrill = this.grills.find(g => g.id === grill.id);

        if (areaInnerGrills.length > 1 || selectedGrill.type !== GrillTypes.INNER && areaInnerGrills.length > 0) {
            allowedGrills = allowedGrills.filter(g => {
                if (areaNotCombinedGrills.length > 0) {
                    return g.type !== GrillTypes.INNER || areaInnerGrills.find(areaInnerGrill => grill.id === areaInnerGrill.id);
                } else {
                    return !(g.type === GrillTypes.INNER && g.canNotBeCombinedWithOtherGrills);
                }
            });
        }

        return allowedGrills;
    }

    getMuntinTypeBasedNumber(areaNumber: number, grillNumber: number): number {
        let lineGrill = 0;
        let gridGrill = 0;
        let crossGrill = 0;
        let angledGrill = 0;
        let diamondGrill = 0;
        let unknownGrill = 0;
        let area = this.windowData.areasSpecification[areaNumber];

        for (let i = 0; i < area.grills.length; i++) {
            switch (area.grills[i].type) {
                case GrillType.LINE_GRILL:
                    lineGrill++;
                    if (grillNumber === i) {
                        return lineGrill;
                    }
                    break;
                case GrillType.GRID_STANDARD:
                    gridGrill++;
                    if (grillNumber === i) {
                        return gridGrill;
                    }
                    break;
                case GrillType.GRID_CROSS_ANGLED:
                    angledGrill++;
                    if (grillNumber === i) {
                        return angledGrill;
                    }
                    break;
                case GrillType.GRID_CROSS_SIMPLE:
                    crossGrill++;
                    if (grillNumber === i) {
                        return crossGrill;
                    }
                    break;
                case GrillType.GRID_RHOMBUS:
                    diamondGrill++;
                    if (grillNumber === i) {
                        return diamondGrill;
                    }
                    break;
                default:
                    unknownGrill++;
                    if (grillNumber === i) {
                        return unknownGrill;
                    }
                    break;
            }
        }
    }

    getGrillColors(grill: Grill): Color[] {
        return this.grillColors[grill.id] = this.grillColors[grill.id] || [];
    }

    veneerChangeEvent(event: VeneerEvent): void {
        this.veneerEvent.emit(event);
    }

    getSectionErrorClass(section: SidebarSection, area?: AreaSpecification): { [cssClass: string]: boolean } {
        return {
            'accordion-header-with-errors': this.fieldUsage.sectionHasErrors(section, area)
        };
    }

    onSelectFocus(event: FocusEvent): void {
        SidebarHelper.onFocusOpenAccsTabs(event, this.allAccordions);
    }

    markForCheck(): void {
        this.cd.markForCheck();
    }

    setValidateAll(value: boolean): void {
        this.validateAll = value;
    }

    emitOnGeneralSubWindowValueChange(value: any, field: WindowEditorField, area: AreaSpecification, areaNumber: number): void {
        let oldValue;
        switch (field) {
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS:
                oldValue = area.filling.type;
                break;
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
                oldValue = area.filling.fillingId;
                break;
            case WindowEditorField.DECORATIVE_FILLING:
                oldValue = area.filling.decorativeFillingId;
                break;
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
                oldValue = area.filling.externalColorId;
                break;
            case WindowEditorField.FILLING_INTERNAL_COLOR:
                oldValue = area.filling.internalColorId;
                break;
            case WindowEditorField.FILLING_WIDTH:
                oldValue = area.filling.width;
                break;
            case WindowEditorField.GLAZING_BEAD:
                oldValue = area.glazingBead.id;
                break;
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
                oldValue = area.glazing.glazingGlassQuantity;
                break;
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
                oldValue = area.glazingCategoryId;
                break;
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
                oldValue = area.glazingFrameCategoryId;
                break;
            case WindowEditorField.GLAZING_PACKAGE:
                oldValue = area.glazingPackageId;
                break;
        }

        if (value === oldValue || (value == undefined && oldValue == undefined)) {
            return;
        }

        this.onBeforeGeneralSubWindowValueChange.emit();

        switch (field) {
            case WindowEditorField.FILLING_TYPE_W_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_MUNTINS:
            case WindowEditorField.FILLING_TYPE_WO_DECORATIVE_FILLINGS:
                area.filling.type = value;
                this.emitOnFillingTypeChange(value, areaNumber);
                break;
            case WindowEditorField.FILLING_NAME_EXTERNAL:
            case WindowEditorField.FILLING_NAME_INTERNAL:
                area.filling.fillingId = value;
                this.updateFillingColors(area);
                this.emitOnFillingNameChange(areaNumber);
                break;
            case WindowEditorField.DECORATIVE_FILLING:
                area.filling.decorativeFillingId = value;
                this.updateFillingColors(area);
                this.emitOnFillingNameChange(areaNumber);
                break;
            case WindowEditorField.FILLING_EXTERNAL_COLOR:
                area.filling.externalColorId = value;
                this.emitOnFillingChange();
                break;
            case WindowEditorField.FILLING_INTERNAL_COLOR:
                area.filling.internalColorId = value;
                this.emitOnFillingChange();
                break;
            case WindowEditorField.FILLING_WIDTH:
                area.filling.width = value;
                this.onFillingWidthChange.emit({tabNumber: this.tabIndex, areaNumber: areaNumber, fillingWidth: value});
                this.emitOnFillingChange();
                break;
            case WindowEditorField.GLAZING_BEAD:
                area.glazingBead.id = value;
                this.emitOnFillingChange(areaNumber);
                break;
            case WindowEditorField.GLAZING_PACKAGE_QUANTITY:
                area.glazing.glazingGlassQuantity = value;
                this.onGlazingQuantityChange.emit(new GlassChangeEvent(null, value, area, this.windowData.generatedId));
                this.emitOnFillingChange();
                break;
            case WindowEditorField.GLAZING_PACKAGE_CATEGORY:
                area.glazingCategoryId = value;
                this.onGlazingCategoryChange.emit(new GlassChangeEvent(null, value, area, this.windowData.generatedId));
                this.emitOnFillingChange();
                break;
            case WindowEditorField.GLAZING_PACKAGE_FRAME_CATEGORY:
                area.glazingFrameCategoryId = value;
                this.onGlazingFrameCategoryChange.emit(new GlassChangeEvent(null, value, area, this.windowData.generatedId));
                this.emitOnFillingChange();
                break;
            case WindowEditorField.GLAZING_PACKAGE:
                area.glazingPackageId = value;
                let glazingPackage = this.allGlazingPackages.find(gp => gp.id === value);
                if (glazingPackage) {
                    area.glazing = glazingPackage.glazing;
                    area.glazingBead.id = glazingPackage.glazingBeadId;
                } else {
                    const glazingGlassQuantity = area.glazing.glazingGlassQuantity;
                    area.glazing = new Glazing();
                    area.glazing.glazingGlassQuantity = glazingGlassQuantity;
                }
                this.onGlazingPackageChange.emit(new GlassChangeEvent(null, value, area, this.windowData.generatedId));
                this.emitOnFillingChange();
                break;
        }

        if (WindowEditorField.GLASS_SELECTOR !== field) {
            this.onGeneralSubWindowValueChange.emit();
            this.fieldChanged.emit(field);
        }
    }

    updateFillingColors(area: AreaSpecification): void {
        if (!this.fieldUsage.show(WindowEditorField.FILLING_INTERNAL_COLOR, area) ||
            !this.getColorsForFilling(area, FillingColorType.INTERNAL)
                .find(color => color.id === area.filling.internalColorId)) {
            area.filling.internalColorId = undefined;
        }
        if (!this.fieldUsage.show(WindowEditorField.FILLING_EXTERNAL_COLOR, area) ||
            !this.getColorsForFilling(area, FillingColorType.EXTERNAL)
                .find(color => color.id === area.filling.externalColorId)) {
            area.filling.externalColorId = undefined;
        }
    }

    handleColorSelected(color: Color, field: WindowEditorField, area: AreaSpecification, areaNumber: number): void {
        this.emitOnGeneralSubWindowValueChange(color != undefined ? color.id : undefined, field, area, areaNumber);
    }

    handleGrillColorSelected(grill: Grill, color: Color): void {
        if (color != undefined) {
            if (typeof color.id !== 'number') {
                this.selectingRalColorHeader = 'OFFER.TABS.SECTION.COLOR.' + color.type;
                this.selectingRalGrill = grill;
                switch (color.type) {
                    case ColorType.RAL_PALETTE_CUSTOM:
                        this.selectingRalColors = this.grillRalColors[grill.id];
                        break;
                    case ColorType.NCS_PALETTE_CUSTOM:
                        this.selectingRalColors = this.grillNcsColors[grill.id];
                        break;
                }
                this.colorBeforeRalSelection = this.grillColors[grill.id].find(c => c.id === grill.colorId);
            }
            this.emitOnGrillChange(grill, color.id, true);
        } else {
            this.emitOnGrillChange(grill, undefined, true);
        }
    }

    handleGrillRalColorSelected(color: Color): void {
        if (this.grillColors[this.selectingRalGrill.id].find(c => c.id === color.id) == undefined) {
            this.grillColors[this.selectingRalGrill.id] = [...this.grillColors[this.selectingRalGrill.id], color];
        }
        this.handleGrillColorSelected(this.selectingRalGrill, color);
        this.selectingRalColorHeader = undefined;
        this.selectingRalGrill = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    handleGrillRalColorSelectionCancel(): void {
        this.handleGrillColorSelected(this.selectingRalGrill, this.colorBeforeRalSelection);
        this.selectingRalColorHeader = undefined;
        this.selectingRalGrill = undefined;
        this.selectingRalColors = undefined;
        this.colorBeforeRalSelection = undefined;
    }

    businessTypeChangeClicked() {
        if (!this.windowSystem.active) {
            this.growls.error("OFFER.DRAWING.WINDOW_SYSTEM_NOT_AVAILABLE");
            return;
        }
        this.openBusinessTypeChangeDialog.emit();
    }

    applyFillingExternalToInternalColor(externalColorId, area, areaNumber): void {
        if (externalColorId != null) {
            if (this.getColorsForFilling(area, FillingColorType.INTERNAL).filter(color =>
                color.id === externalColorId).length > 0) {

                this.emitOnGeneralSubWindowValueChange(externalColorId, WindowEditorField.FILLING_INTERNAL_COLOR, area, areaNumber);
            }
        }
    }

    decorativeFillingFlip(filling: Filling): void {
        filling.flipDecorativeFilling = !filling.flipDecorativeFilling;
        this.redrawDesigner.emit();
    }

    handleShowImage(imageSource: Observable<string>, header: string): void {
        this.onShowImage.emit(new WindowComponentPreviewData(imageSource, header));
    }

    getWindowSystem(): WindowSystemDefinition {
        return this.windowSystem != undefined ? this.windowSystem : new WindowSystemDefinition();
    }

    areasTrackBy = (index: number, area: AreaSpecification) => {
        return area.generatedId;
    }

    mullionsTrackBy = (index: number, mullion: Mullion) => {
        return mullion.generatedId;
    }

    getGlazingWidths(area: AreaSpecification): string {
        let windowSystem = this.getWindowSystem();
        if (area.filling.type === FillingType.DECORATIVE_FILLING) {
            return area.filling.decorativeFillingId == null ? '' : this.systemDecorativeFillings.find(
                filling => filling.id === area.filling.decorativeFillingId).glazingWidths;
        }
        return windowSystem != null ? windowSystem.glazingWidths : '';
    }

    isDecorativeFillingType(fillingType: FillingType): boolean {
        return fillingType === FillingType.DECORATIVE_FILLING;
    }

    setAvailableGlazingPackages() {
        this.windowData.areasSpecification.forEach(area => {
            this.availableGlazingPackages.set(area.generatedId,
                this.windowEditorFieldContentProvider.getItemsStream(this.Field.GLAZING_PACKAGE));
        });
    }

    validVeneers(): boolean {
        return this.allVeneers.map(v => v.tempVeneer).filter(tv => tv != null).filter(tv => tv.active).every(tv => tv.valid);
    }
}
