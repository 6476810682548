import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {Observable} from 'rxjs';
import {DateRangeFilter, DateRangeKind} from '../../../../common/date-range-filter';
import {TranslatedSelectItemService} from '../../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../../common/validation-errors';
import {
    UserActivityCustomFilter,
    UserActivityReportCriteriaComparisonOperator,
    UserActivityReportCriteriaLogicOperator,
    UserActivityReportCriteriaTree,
    UserActivityReportCriteriaVariable
} from '../user-activity-data';

@Component({
    selector: 'app-user-activity-report-wizard',
    templateUrl: './user-activity-report-wizard.component.html',
    styleUrls: ['./user-activity-report-wizard.component.css'],
    providers: [TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActivityReportWizardComponent implements OnInit, OnChanges {

    @Input()
    filter: UserActivityCustomFilter;

    @Output()
    readonly filterChange = new EventEmitter<UserActivityCustomFilter>();

    @Output()
    readonly saved = new EventEmitter<MouseEvent>();

    dateRange: DateRangeFilter;
    criteriaTree: UserActivityReportCriteriaTree;
    validationErrors: ValidationErrors = {};

    dateRangeKinds: Observable<SelectItem[]>;
    variables: Observable<SelectItem[]>;
    operators: Observable<SelectItem[]>;
    childLogicOperators: Observable<SelectItem[]>;

    constructor(private readonly translatedSelectItemService: TranslatedSelectItemService,
                private readonly changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        this.dateRangeKinds = this.translatedSelectItemService.buildUnsortedDropdown([
                DateRangeKind.INPUT_RANGE,
                DateRangeKind.PAST_7_DAYS,
                DateRangeKind.PAST_30_DAYS,
                DateRangeKind.PAST_YEAR],
            'GENERAL.DATE_RANGE_KIND.', undefined);
        this.variables = this.translatedSelectItemService.buildUnsortedDropdown(UserActivityReportCriteriaVariable,
            'USER_ACTIVITY_REPORT_CRITERIA_VARIABLE.', undefined);
        this.operators = this.translatedSelectItemService.buildUnsortedDropdown(UserActivityReportCriteriaComparisonOperator,
            'USER_ACTIVITY_REPORT_CRITERIA_COMPARISON_OPERATOR.', undefined);
        this.childLogicOperators = this.translatedSelectItemService.buildUnsortedDropdown(UserActivityReportCriteriaLogicOperator,
            'USER_ACTIVITY_REPORT_CRITERIA_LOGIC_OPERATOR.', undefined);
        this.criteriaTree = this.createEmptyCriteriaTree();
    }

    ngOnChanges(changes: SimpleChanges): void {
        const filterChange = changes['filter'];
        if (filterChange != undefined) {
            this.initCriteriaTreeFrom(filterChange.currentValue as UserActivityCustomFilter);
        }
    }

    private createEmptyCriteriaTree(): UserActivityReportCriteriaTree {
        const criteriaTree: UserActivityReportCriteriaTree = {
            logic: UserActivityReportCriteriaLogicOperator.AND,
            children: [{
                logic: undefined,
                children: undefined,
                criteria: {
                    variable: undefined,
                    operator: undefined,
                    value: undefined
                }
            }],
            criteria: undefined
        };
        this.initParentReferences(criteriaTree, undefined);
        return criteriaTree;
    }

    private initParentReferences(node: UserActivityReportCriteriaTree, parent: UserActivityReportCriteriaTree): void {
        node.parent = parent;
        if (node.children != undefined) {
            for (let childNode of node.children) {
                this.initParentReferences(childNode, node);
            }
        }
    }

    private initCriteriaTreeFrom(filter: UserActivityCustomFilter): void {
        if (filter != undefined) {
            this.dateRange = filter.criteriaTreeDateRange;
            this.criteriaTree = JSON.parse(this.base64UrlDecode(filter.criteriaTree));
            this.initParentReferences(this.criteriaTree, undefined);
        } else {
            this.dateRange = undefined;
            this.criteriaTree = this.createEmptyCriteriaTree();
        }
    }

    handleDeleteNode(node: UserActivityReportCriteriaTree): void {
        const copy = [...node.parent.children];
        copy.splice(node.parent.children.indexOf(node), 1);
        node.parent.children = copy;
        this.changeDetector.markForCheck();
    }

    handleAddCriteriaNode(node: UserActivityReportCriteriaTree): void {
        node.children.push({
            parent: node,
            logic: undefined,
            children: undefined,
            criteria: {
                variable: undefined,
                operator: undefined,
                value: undefined
            }
        })
        this.changeDetector.markForCheck();
    }

    handleAddGroupNode(node: UserActivityReportCriteriaTree): void {
        node.children.push({
            parent: node,
            logic: UserActivityReportCriteriaLogicOperator.AND,
            children: [],
            criteria: undefined
        })
        this.changeDetector.markForCheck();
    }

    reset(): void {
        this.initCriteriaTreeFrom(this.filter);
        this.changeDetector.markForCheck();
    }

    save(event: MouseEvent): void {
        const filter: UserActivityCustomFilter = {
            criteriaTreeDateRange: this.dateRange,
            criteriaTree: this.base64UrlEncode(JSON.stringify(this.criteriaTree, function (key: string, value: any) {
                return key === 'parent' ? undefined : value;
            })),
        }
        this.filterChange.emit(filter);
        this.saved.emit(event);
        this.changeDetector.markForCheck();
    }

    private base64UrlEncode(input: string): string {
        return btoa(input)
            .replace(/\//g, '_')
            .replace(/\+/g, '-');
    }

    private base64UrlDecode(input: string): string {
        return atob(input
            .replace(/_/g, '/')
            .replace(/-/g, '+')
        );
    }
}
