import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Inject,
    Input,
    OnDestroy,
    Optional,
    Renderer2,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {saveAs} from 'file-saver';
import {ImageCroppedEvent, ImageCropperComponent} from 'ngx-image-cropper';
import {OutputFormat} from 'ngx-image-cropper/lib/interfaces/cropper-options.interface';
import {from, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {AbstractInputComponent, FormHandler} from '../abstract-input/abstract-input.component';
import {FORM_HANDLER} from '../form-handler-token';
import {FileValidator} from './file-validator';
import {ImagesValidator} from './images-validator';

export const FILE_UPLOAD_VALUE_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FileUploadComponent),
    multi: true
};

@Component({
    selector: 'app-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.css'],
    providers: [FILE_UPLOAD_VALUE_ACCESSOR]
})
export class FileUploadComponent extends AbstractInputComponent implements OnDestroy {

    readonly DEFAULT_IMAGE_MAX_SIZE = 50000;
    readonly DEFAULT_MAX_WIDTH = 400;
    readonly DEFAULT_MAX_HEIGHT = 400;
    readonly DEFAULT_MAX_FILE_SIZE = 15000000;

    @Input()
    required: boolean;

    @Input()
    addButtonLabel: string;

    @Input()
    changeButtonLabel: string;

    @Input()
    deleteButtonLabel: string;

    @Input()
    cropButtonLabel = 'GENERAL.CROP';

    @Input()
    maxSize: number;

    @Input()
    maxWidth: number;

    @Input()
    maxHeight: number;

    @Input()
    image = true;

    @Input()
    enableImageCropper = false;

    @Input()
    widthToHeightRatio: number | undefined;

    @Input()
    accept = '.jpg, .jpeg, .png';

    @Input()
    validationFileType: string;

    @ViewChild('container', {static: true})
    containerElement: ElementRef;

    @ViewChild('defaultDownloadButton', {read: TemplateRef, static: true})
    defaultDownloadButton: TemplateRef<any>;

    @ViewChild('imageCropper')
    imageCropper: ImageCropperComponent;

    @Input()
    crop: boolean;

    downloadButtonTemplate: TemplateRef<any>;

    src: SafeResourceUrl | string;

    private _imageFile: File;
    cropperFormat: OutputFormat;
    croppedImage = '';

    private objectUrl?: string;

    constructor(renderer: Renderer2,
                changeDetector: ChangeDetectorRef,
                private sanitizer: DomSanitizer,
                @Inject(FORM_HANDLER) @Optional() form: FormHandler) {
        super(renderer, changeDetector, form);
    }

    ngOnDestroy(): void {
        if (this.objectUrl != undefined) {
            URL.revokeObjectURL(this.objectUrl);
            this.objectUrl = undefined;
        }
        super.ngOnDestroy();
    }

    protected getContainer(): ElementRef<HTMLDivElement> {
        return this.containerElement;
    }

    protected resetTemplatesToDefault(): void {
        this.downloadButtonTemplate = this.defaultDownloadButton;
    }

    protected registerTemplate(type: string, template: TemplateRef<any>): void {
        if (type === 'download') {
            this.downloadButtonTemplate = template;
        }
    }

    writeValue(obj: any): void {
        super.writeValue(obj);
        this.updateSrc();
        this.imageFile = obj;
    }

    openFileBrowser(): void {
        const element = this.getHiddenInput();
        element.value = '';
        element.click();
    }

    showDownloadButton(): boolean {
        return this.value instanceof File && this.value.size > 0;
    }

    downloadFile(): boolean {
        saveAs(this.value, this.value.name, true);
        return false;
    }

    onFileChangeEvent(event: Event): void {
        this.onFileChange((event.target as HTMLInputElement).files[0]);
    }

    deleteFile(): void {
        this.value = undefined;
        this.updateSrc();
    }

    showDeleteButton(): boolean {
        return !!this.deleteButtonLabel && (!!this.src || (this.value != null && this.value.size !== 0));
    }

    formatSize(size: number): string {
        if (size == undefined) {
            size = this.image ? this.DEFAULT_IMAGE_MAX_SIZE : this.DEFAULT_MAX_FILE_SIZE;
        }
        if (size < 1024) {
            return size + 'B';
        }
        const rangedRound = (value: number): number => {
            if (value < 10) {
                // round to two decimal places max
                return Math.floor(value * 100) / 100;
            }
            if (value < 100) {
                // round to one decimal place max
                return Math.floor(value * 10) / 10;
            }
            return Math.floor(value);
        };
        if (size < 1024 * 1024) {
            return rangedRound(size / 1024) + 'KB';
        }
        if (size < 1024 * 1024 * 1024) {
            return rangedRound(size / (1024 * 1024)) + 'MB';
        }
        return rangedRound(size / (1024 * 1024 * 1024)) + 'GB';
    }

    formatWidthToHeightRatio(ratio: number): string {
        return `${ratio}\xA0:\xA01`;
    }

    private getHiddenInput(): HTMLInputElement {
        return this.getContainer().nativeElement.querySelector<HTMLInputElement>(`input#${this.inputId}`);
    }

    private updateSrc(): void {
        if (this.objectUrl != undefined) {
            URL.revokeObjectURL(this.objectUrl);
            this.objectUrl = undefined;
        }
        if (this.image && this.value instanceof File && this.value.size > 0) {
            this.objectUrl = URL.createObjectURL(this.value);
            this.src = this.sanitizer.bypassSecurityTrustUrl(this.objectUrl);
        } else {
            this.src = undefined;
        }
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.croppedImage = event.base64;
    }

    dataURLtoFile(dataUri: string, fileName: string): Observable<File> {
        const mime = /^data:([^;]+)?(;[^,]+)?,/.exec(dataUri);
        return from(fetch(dataUri)).pipe(
            mergeMap(dataUriResponse => from(dataUriResponse.arrayBuffer())),
            map(body => new File([body], fileName, {type: mime.length > 1 ? mime[1] : undefined}))
        );
    }

    cropFile(): void {
        let fileName = (this.value as File).name;
        this.dataURLtoFile(this.croppedImage, fileName)
            .subscribe(file => this.onFileChange(file));
    }

    onFileChange(file: File): void {
        this.value = file;
        if (this.image) {
            this.imageFile = this.value;
            ImagesValidator.validationErrors(this.value,
                (this.maxSize || this.DEFAULT_IMAGE_MAX_SIZE),
                (this.maxWidth || this.DEFAULT_MAX_WIDTH),
                (this.maxHeight || this.DEFAULT_MAX_HEIGHT),
                this.widthToHeightRatio,
                this.validationFileType == undefined ? undefined : [this.validationFileType])
                .subscribe(error => this.validationMessageKeyChange.emit(error));
        } else {
            let error = FileValidator.validationErrors(this.value, this.validationFileType, (this.maxSize || this.DEFAULT_MAX_FILE_SIZE));
            if (error) {
                this.validationMessageKeyChange.emit(error);
            }
        }
        this.updateSrc();
    }

    get imageFile() {
        return this._imageFile;
    }

    set imageFile(value: File) {
        this._imageFile = value;
        if (value != undefined) {
            // we should maintain the format specified by the user however png format almost doubles the original image size
            // so force to jpeg here
            // this.cropperFormat = 'jpeg';

            // desired behavior
            this.cropperFormat = value.type.includes('png') ? 'png' : 'jpeg';
        } else {
            this.cropperFormat = undefined;
        }
    }

    get showImageCropper() {
        return this.enableImageCropper || this.widthToHeightRatio != undefined;
    }
}
