export interface InchToMmCompositeInputValue {
    full: number;
    sixteenths: number;
}

export class UnitConverter {

    private static readonly mmToInchRatio = 25.4;

    static millimitersToInches(mm: number): { exact: number } & InchToMmCompositeInputValue {
        const exact = mm / this.mmToInchRatio;
        const full = Math.floor(exact);
        const sixteenths = Math.round((exact - full) * 16);
        return {exact, full, sixteenths};
    }

    static inchesToMillimeters(value: number | InchToMmCompositeInputValue): number {
        if (typeof value !== 'number') {
            value = value.full + value.sixteenths / 16;
        }
        return value * this.mmToInchRatio;
    }
}
