import {Component, Input} from '@angular/core';

export enum FoldCorner {
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}

@Component({
    selector: 'app-card-fold',
    templateUrl: './card-fold.component.html',
    styleUrls: ['./card-fold.component.css']
})
export class CardFoldComponent {

    @Input() corner: FoldCorner;
    @Input() foldColor: string;
    @Input() backgroundColor = 'white';

}
