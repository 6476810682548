<div class="new-form-600">
    <div class="new-form-row">
        <app-select inputId="productType"
                    label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.PRODUCT_TYPE' | translate }}"
                    [options]="categories | async"
                    [(validationMessageKey)]="validationErrors['productConfiguration.productType']"
                    [(ngModel)]="item.productConfiguration.productType" [disabled]="item.id != undefined"></app-select>
    </div>
    <div class="new-form-row">
        <app-calendar inputId="validFrom" label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.VALID_FROM' | translate }}"
                      [maxDate]="item.validTo" [(validationMessageKey)]="validationErrors['validFrom']"
                      [(ngModel)]="item.validFrom"></app-calendar>
    </div>
    <div class="new-form-row" *ngIf="!item.indefinite">
        <app-calendar inputId="validTo" label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.VALID_TO' | translate }}"
                      [minDate]="item.validFrom" [(validationMessageKey)]="validationErrors['validTo']"
                      [(ngModel)]="item.validTo"></app-calendar>
    </div>
    <div class="new-form-row">
        <app-text-area inputId="'description"
                       label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.DESCRIPTION' | translate }}"
                       [(validationMessageKey)]="validationErrors['description']" [maxlength]="1024"
                       [(ngModel)]="item.description"></app-text-area>
    </div>
    <div *ngIf="item.id != undefined" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.SORT_INDEX' | translate }}"
                          [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1"
                          [step]="1" [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="showOnMainPage"
                      label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.SHOW_ON_MAIN_PAGE' | translate }}"
                      [(validationMessageKey)]="validationErrors['showOnMainPage']"
                      [(ngModel)]="item.showOnMainPage"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.ACTIVE' | translate }}"
                      [(validationMessageKey)]="validationErrors['active']" [(ngModel)]="item.active"></app-checkbox>
    </div>
    <div class="new-form-row">
        <app-checkbox inputId="indefinite" label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.INDEFINITE' | translate }}"
                      [(validationMessageKey)]="validationErrors['indefinite']" [(ngModel)]="item.indefinite"
                      (ngModelChange)="setIndefinite($event)"></app-checkbox>
    </div>
</div>
