<!--first group of properties are common from AbstractInputComponent-->
<!--second group is from dedicated related classes: CheckboxComponent or SelectComponent  -->
<!--third group is just Model change-->
<app-checkbox *ngIf="isCheckbox"
              [inputId]="inputId"
              [label]="options[0].label"
              [placeholder]="placeholder"
              [validationMessageKey]="validationMessageKey"
              [hasValidationError]="hasValidationError"
              [disabled]="disabled"
              [modelOptions]="modelOptions"
              (validationMessageKeyChange)="validationMessageKeyChange.emit($event)"
              (onFocus)="onFocus.emit($event)"
              (onBlur)="onBlur.emit($event)"

              (onClick)="onClick.emit($event)"

              [ngModel]="value"
              (ngModelChange)="handleCheckboxChange($event)">
    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
        <ng-container *ngTemplateOutlet="labelTemplate; context: {label: label, inputId: inputId, itemId: options[0].value, disabled: false}"></ng-container>
    </ng-template>
    <ng-template pTemplate="validationMessage">
        <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
    </ng-template>
</app-checkbox>

<app-select *ngIf="!isCheckbox"
            [inputId]="inputId"
            [label]="label"
            [placeholder]="placeholder"
            [validationMessageKey]="validationMessageKey"
            [hasValidationError]="hasValidationError"
            [disabled]="disabled"
            [modelOptions]="modelOptions"
            (validationMessageKeyChange)="validationMessageKeyChange.emit($event)"
            (onFocus)="onFocus.emit($event)"
            (onBlur)="onBlur.emit($event)"

            (onClick)="onClick.emit($event)"
            [options]="options"
            [required]="required"
            [translateLabels]="translateLabels"
            [optionFormatter]="optionFormatter"
            [optionKey]="optionKey"
            [allowSelectingNone]="allowSelectingNone"
            [noneOptionPosition]="noneOptionPosition"
            [noLongerAvailable]="noLongerAvailable"
            [checkAvailability]="checkAvailability"
            [fakeSelect]="fakeSelect"
            (beginInvalidValueCorrection)="beginInvalidValueCorrection.emit($event)"
            (endInvalidValueCorrection)="endInvalidValueCorrection.emit()"

            [ngModel]="value"
            (ngModelChange)="valueChange.emit($event)">
    <ng-template pTemplate="label" let-label="label" let-inputId="inputId">
        <ng-container *ngTemplateOutlet="labelTemplate; context: {label: label, inputId: inputId, itemId: value, disabled: value == undefined}"></ng-container>
    </ng-template>
    <ng-template pTemplate="validationMessage">
        <ng-container *ngTemplateOutlet="validationMessageTemplate"></ng-container>
    </ng-template>
</app-select>
