import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MotlawaBulkAddonsDistributionPossibility} from "../../../common/enums/motlawa-bulk-addons-distribution-possibility.enum";

@JsonDeserializable
export class MotlawaBulkAddonsDistributionPossibilities {

    supplierId: number;
    supplierName: string;
    offerId: number;
    offerNumber: string;
    possibilities: MotlawaBulkAddonsDistributionPossibility[];

    static fromJSON(jsonObject: any): MotlawaBulkAddonsDistributionPossibilities {
        const dto = new MotlawaBulkAddonsDistributionPossibilities();
        dto.supplierId = jsonObject.supplierId;
        dto.supplierName = jsonObject.supplierName;
        dto.offerId = jsonObject.offerId;
        dto.offerNumber = jsonObject.offerNumber;
        dto.possibilities = jsonObject.possibilities;
        return dto;
    }
}
