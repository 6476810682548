import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';

@JsonDeserializable
export class Assembly implements CrudItem {
    id: number;
    name: string;
    assemblyUnit: AssemblyUnit;
    value: number;
    currency: string;
    active = true;
    sortIndex = 1;

    static fromJSON(jsonObject: object): Assembly {
        // no special handling needed
        return Object.assign({}, jsonObject as Assembly);
    }
}

export enum AssemblyUnit {
    PIECE = 'PIECE',
    AREA = 'AREA',
    PERIMETER = 'PERIMETER',
    METER = 'METER',
    ONE = 'ONE'
}
