<p-dialog #dialog [resizable]="false" [visible]="true" (onHide)="closeDialog()" [modal]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.PRINT.HEADER' | translate }}
        </div>
    </p-header>

    <div *ngIf="dialogReady" class="scrolled-dialog scrolled-dialog-900">
        <p-tabView (onChange)="tabChanged($event)" id="printTabView">
            <ng-container *ngFor="let tab of availableTabs">
                <p-tabPanel header="{{('OFFER.POSITIONS.DIALOGS.PRINT.' + tab + '_TAB_LABEL') | translate}}"
                            [headerStyleClass]="availableTabs.length < 2 ? 'hidden-tab-headers' : ''"
                            [selected]="selectedTab === tab">
                    <ng-container
                            *ngTemplateOutlet="panelContents; context: {configs: printConfigsPerTab.get(tab), tab: tab, selectedTemplate: selectedTemplatePerTab.get(tab)}"></ng-container>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="closeDialog()" buttonId="cancelButton"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
<ng-template #panelContents let-configs="configs" let-tab="tab" let-selectedTemplate="selectedTemplate">

    <div style="display: flex">
        <div class="new-form-600">
            <div *ngIf="templateTypeItemsPerTab.get(tab).length > 1" class="new-form-row">
                <app-select inputId="{{ 'documentTemplate' }}"
                            label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.TEMPLATE.LABEL' | translate }}"
                            [options]="templateTypeItemsPerTab.get(tab)" [ngModel]="selectedTemplate"
                            [translateLabels]="true" (ngModelChange)="templateChanged($event, tab, configs)"></app-select>
            </div>

            <div class="new-form-row" *ngIf="tab !== printDialogTab.GLAMOUR && tab !== printDialogTab.EXPORT">
                <app-radio-button inputId="showPrices"
                                  label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_PRICES' | translate }}"
                                  [disabled]="documentTemplateType.FULL === selectedTemplate"
                                  [options]="priceDetailLevels" [translateLabels]="true"
                                  [horizontal]="false"
                                  [(ngModel)]="configs.configsPerTemplateType.get(selectedTemplate).printPricesDetailLevel"></app-radio-button>
            </div>

            <div class="new-form-row">
                <p-dataTable [value]="printableDocumentsPerTabAndTemplate.get(tab).get(selectedTemplate)">
                    <p-column header="{{'OFFER.POSITIONS.DIALOGS.PRINT.ACTIONS.COLUMN_HEADER' | translate}}"
                              styleClass="various-icon-libraries">
                        <ng-template let-item="rowData" pTemplate="body">
                        <span *ngFor="let action of item.actions" [id]="item.labelKey">
                            <app-simple-button [buttonId]="item.documentType + '_' + action"
                                               label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.ACTIONS.' + action | translate }}"
                                               type="action"
                                               (onClick)="print(item.documentType, action, item.documentTemplate)"
                                               [icon]="getIcon(action)"
                                               [iconAwesome]="getIconAwesome(action)"
                                               [inlineLabel]="false"></app-simple-button>
                        </span>
                        </ng-template>
                    </p-column>
                    <p-column field="documentType"
                              header="{{'OFFER.POSITIONS.DIALOGS.PRINT.DOCUMENT_TYPE_COLUMN_HEADER' | translate}}">
                        <ng-template let-item="rowData" pTemplate="body">
                            <span *ngIf="printableSection !== printableSectionEnum.HISTORICAL_ORDERS else historicalOrdersRow">
                                {{ item.labelKey | translate}}
                            </span>
                            <ng-template #historicalOrdersRow>
                                <span *vLet="selectedItems[0].printHistoryEntry as entry">
                                    {{ item.labelKey | translate: {currency: entry.currency, exchangeRate: entry.exchangeRate} }}
                                </span>
                            </ng-template>
                        </ng-template>
                    </p-column>
                    <p-column *ngIf="tab !== printDialogTab.EXPORT"
                              field="printLanguage"
                              header="{{'OFFER.POSITIONS.DIALOGS.PRINT.PRINT_LANGUAGE_HEADER' | translate}}">
                        <ng-template let-item="rowData" pTemplate="body">
                            <div class="language language-container">
                                <div class="flag"
                                     (click)="openLanguageSelector(item.documentType, item.documentTemplate)"
                                     [id]="selectedLanguages.get(item.documentTemplate ? (item.documentType + '_' + item.documentTemplate) : item.documentType)?.code"></div>
                                <div *ngIf="showLanguageSelector && item.documentType === typeToChangeLang && item.documentTemplate === templateToChangeLang" class="language-selector-container">
                                    <app-languages-list [showActive]="false"
                                                        [selectable]="true"
                                                        [height]="60"
                                                        (languageSelected)="languageChange($event, item.documentType, item.documentTemplate)"></app-languages-list>
                                </div>
                            </div>
                        </ng-template>
                    </p-column>
                </p-dataTable>
            </div>
        </div>
        <div>
            <ng-container
                *ngTemplateOutlet="panelCheckboxesConfiguration; context: {config: configs.configsPerTemplateType.get(selectedTemplate), tab: tab, selectedTemplate: selectedTemplate}"></ng-container>
        </div>
    </div>
</ng-template>

<ng-template #panelCheckboxesConfiguration let-config="config" let-tab="tab" let-selectedTemplate="selectedTemplate">
    <ng-container *vLet="documentTemplateType.FULL === selectedTemplate as disabled">
        <ng-container *ngIf="tab === printDialogTab.GLAMOUR">
            <div class="new-form-row">
                <app-checkbox inputId="showLeaflets"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.SHOW_LEAFLETS' | translate }}"
                              [(ngModel)]="config.showLeaflets"
                              [disabled]="!printSubsystemConfig.enableLeaflets || disabled"></app-checkbox>
            </div>
            <div class="new-form-row">
                <app-checkbox inputId="hidePrices"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_ALL_PRICES' | translate }}"
                              [disabled]="disabled"
                              (change)="hidePricesCheckboxClicked()"
                              [ngModel]="config.printPricesDetailLevel === printPricesDetailLevel.HIDE_ALL"></app-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="tab !== printDialogTab.GLAMOUR && tab !== printDialogTab.EXPORT">
            <div class="new-form-row">
                <app-checkbox inputId="showQuantities"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.SHOW_QUANTITIES' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.printQuantitiesDetails"></app-checkbox>
            </div>
            <div class="new-form-row">
                <app-checkbox inputId="printOnlyGrossPrices"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.SHORT_PRICES_FOR_CLIENT' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.printOnlyGrossPrices"></app-checkbox>
            </div>
            <div class="new-form-row">
                <app-checkbox inputId="roundPrices"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.ROUND_PRICES' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.roundPrices"></app-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="tab !== printDialogTab.EXPORT">
            <div class="new-form-row">
                <app-checkbox [inputId]="createCheckboxId('warrantyInfo', tab)"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.WARRANTY_INFO' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.warrantyInfo"></app-checkbox>
            </div>
            <div class="new-form-row">
                <app-checkbox [inputId]="createCheckboxId('hideDimensions', tab)"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_DIMENSIONS' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.hideDimensions"></app-checkbox>
            </div>
            <div class="new-form-row">
                <app-checkbox [inputId]="createCheckboxId('hideOpenings', tab)"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_OPENINGS' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.hideOpenings"></app-checkbox>
            </div>
            <div class="new-form-row">
                <app-checkbox [inputId]="createCheckboxId('showWeight', tab)"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.SHOW_WEIGHT' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.showWeight"></app-checkbox>
            </div>
            <div class="new-form-row">
                <app-checkbox [inputId]="createCheckboxId('showUpselling', tab)"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.SHOW_UPSELLING' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.showUpselling"></app-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="tab !== printDialogTab.EXPORT && (permissions.isHandlowiec() || permissions.isOperator() || permissions.isSprzedawca())">
            <div class="new-form-row">
                <app-checkbox [inputId]="createCheckboxId('showRot', tab)"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.SHOW_ROT' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.showRot"></app-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="tab !== printDialogTab.GLAMOUR && tab !== printDialogTab.EXPORT">
            <div class="new-form-row">
                <app-checkbox inputId="showGrillsLayout"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.SHOW_GRILLS_LAYOUT' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.showGrillsLayout"></app-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="!permissions.isKoordynator() && !permissions.isOpiekun() && tab !== printDialogTab.EXPORT">
            <div class="new-form-row">
                <app-checkbox inputId="hideAssemblyAndTransportDisclaimer"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_ASSEMBLY_AND_TRANSPORT_DISCLAIMER' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.hideAssemblyAndTransportDisclaimer"></app-checkbox>
            </div>
        </ng-container>
        <ng-container *ngIf="tab === printDialogTab.EXPORT">
            <div class="new-form-row" *ngIf="permissions.isKoordynator() || permissions.isOpiekun()">
                <app-calendar inputId="offerLinkExpiration"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.OFFER_LINK_EXPIRATION_DATE' | translate }}"
                              [utc]="true" [showTime]="true" [(ngModel)]="offerLinkExpiration"></app-calendar>
            </div>
        </ng-container>
		<ng-container *ngIf="tab === printDialogTab.REGULAR && printableSection === printableSectionEnum.PRODUCTION_ORDERS">
			<div class="new-form-row">
				<app-checkbox inputId="hideDealerDiscount"
							  label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_DEALER_DISCOUNT' | translate }}"
							  [disabled]="disabled"
							  [(ngModel)]="config.hideDealerDiscount"></app-checkbox>
			</div>
		</ng-container>
        <ng-container *ngIf="tab !== printDialogTab.GLAMOUR && tab !== printDialogTab.EXPORT">
            <div class="new-form-row">
                <app-checkbox inputId="hideWindowSystemHeaders"
                              label="{{ 'OFFER.POSITIONS.DIALOGS.PRINT.HIDE_WINDOW_SYSTEM_HEADERS' | translate }}"
                              [disabled]="disabled"
                              [(ngModel)]="config.hideWindowSystemHeaders"></app-checkbox>
            </div>
        </ng-container>
    </ng-container>
</ng-template>
