import { Pipe, PipeTransform } from '@angular/core';

export enum GlassSelectionFieldType {
    GLASS, FRAME
}

@Pipe({
  name: 'glassSelectionFieldLabel'
})
export class GlassSelectionFieldLabelPipe implements PipeTransform {

  transform(fieldType: GlassSelectionFieldType, quantity: number, glassNumber = null): string {
      switch (fieldType) {
          case GlassSelectionFieldType.FRAME:
              if (quantity === 2) {
                  return 'OFFER.TABS.SECTION.FILLING.FRAME';
              }
              return 'OFFER.TABS.SECTION.FILLING.FRAME_NUMBERED';
          case GlassSelectionFieldType.GLASS:
              if (glassNumber === 0) {
                  return 'OFFER.TABS.SECTION.FILLING.GLASS_INTERNAL';
              } else if (glassNumber === quantity - 1) {
                  return 'OFFER.TABS.SECTION.FILLING.GLASS_EXTERNAL';
              } else if (quantity === 3) {
                  return 'OFFER.TABS.SECTION.FILLING.GLASS_MIDDLE';
              }
              return 'OFFER.TABS.SECTION.FILLING.GLASS_MIDDLE_NUMBERED';
      }
  }

}
