import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {CatalogItemName} from '../../../common/crud-common/catalog-item-name';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing, mapListingToJson} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper, FileState} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {Material} from './material';
import {mapItemToJson} from "../../../common/crud-common/crudItem";
import {CatalogItemNameAndActive} from "../../../common/crud-common/catalog-item-name-and-active";
import {MaterialColorImages} from "./material-color-images/material-color-images";
import {CatalogItemExtended} from "../entrance-model/entrance-model-basic";
import {map} from "rxjs/operators";

@Injectable()
export class MaterialService implements CrudService<Material> {

    private static readonly API_URL = 'materials';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<Material>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<Material>>(MaterialService.API_URL, {params: params})
            .pipe(mapListingToJson(Material));
    }

    getItem(itemId: number): Observable<Material> {
        return this.http.get<Material>(`${MaterialService.API_URL}/${itemId}`)
            .pipe(mapItemToJson(Material));
    }

    getImageForItem(itemId: number): Observable<string> {
        return this.http.get(`${MaterialService.API_URL}/${itemId}/image`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }

    getImageAsFile(itemId: number): Observable<File> {
        return this.http.get(`${MaterialService.API_URL}/${itemId}/image`, {
            observe: 'response',
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        }).pipe(this.dataServiceHelper.mapToFile());
    }

    addItem(item: Material, file: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('materialDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file) {
            formData.append('file', file);
        }
        return this.http.post<void>(MaterialService.API_URL, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(MaterialService.API_URL));
    }

    editItem(itemId: number, item: Material, file: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('materialDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file) {
            formData.append('file', file);
        }
        return this.http.put<void>(`${MaterialService.API_URL}/${itemId}`, formData)
            .pipe(this.dataServiceHelper.mapToExistingItemId(itemId));
    }

    copyItem(id: number, item: Material, file: File): Observable<number> {
        let body = JSON.stringify(item);
        let formData = new FormData();
        formData.append('materialDto', new Blob([body], {
            type: 'application/json'
        }));
        if (file) {
            formData.append('file', file);
        }
        return this.http.post<void>(`${MaterialService.API_URL}/${id}/copy`, formData, {observe: 'response'})
            .pipe(this.dataServiceHelper.mapToNewItemId(MaterialService.API_URL));
    }

    validate(item: Material): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(this.http.post(`${MaterialService.API_URL}/validate`, item));
    }

    editLinks(id: number, configSystemIds: number[]): Observable<void> {
        return this.http.put<void>(`${MaterialService.API_URL}/link/${id}`, configSystemIds);
    }

    getLinkedSystems(id: number): Observable<number[]> {
        return this.http.get<number[]>(`${MaterialService.API_URL}/linked/${id}`);
    }

    getConfigsWithMaterialLinks(pricelistId: number): Observable<CatalogItemExtended[]> {
        return this.http.get<CatalogItemExtended[]>(`${MaterialService.API_URL}/linked/exclude-pricelist/${pricelistId}`);
    }

    getItemNames(active = true): Observable<CatalogItemName[]> {
        const params = {};
        if (active != undefined) {
            params['active'] = `${active}`;
        }
        return this.http.get<CatalogItemName[]>(`${MaterialService.API_URL}/names`, {params: params});
    }

    getColors(): Observable<CatalogItemNameAndActive[]> {
        return this.http.get<CatalogItemNameAndActive[]>(`${MaterialService.API_URL}/colors`);
    }

    getColorLinksForMaterials(materialIds: number[]): Observable<MaterialColorImages[]> {
        const formData = new FormData();
        formData.append('materialIds', new Blob([JSON.stringify(materialIds)], {type: 'application/json'}));
        return this.http.post<MaterialColorImages[]>(`${MaterialService.API_URL}/colorLinks`, formData)
            .pipe(map(response => response.map(item => MaterialColorImages.fromJSON(item))));
    }

    getColorLinksForConfigSystem(configSystemId: number, selectedMaterialIds: number[] = null): Observable<MaterialColorImages[]> {
        const params = selectedMaterialIds ? {selectedIds: selectedMaterialIds.toString()} : {};
        return this.http.get<MaterialColorImages[]>(`${MaterialService.API_URL}/configSystem/${configSystemId}/colorLinks`, {params: params})
            .pipe(map(response => response.map(a => MaterialColorImages.fromJSON(a))));
    }

    getColorLinkForMaterial(materialId: number, colorId: number): Observable<MaterialColorImages> {
        return this.http.get<MaterialColorImages>(`${MaterialService.API_URL}/${materialId}/color/${colorId}/link`)
            .pipe(map(response => MaterialColorImages.fromJSON(response)));
    }

    linkColorWithMaterial(link: MaterialColorImages, image: FileState): Observable<string> {
        const formData = new FormData();
        formData.append('linkDto', new Blob([JSON.stringify(link)], {type: 'application/json'}));
        if (this.dataServiceHelper.isFileSaveNeeded(image)) {
            formData.append('image', image.file);
        }
        return this.http.post(`${MaterialService.API_URL}/linkColor`, formData, {responseType: 'text'});
    }

    unlinkColorFromMaterial(materialId: number, colorId: number): Observable<void> {
        return this.http.delete<void>(`${MaterialService.API_URL}/${materialId}/unlinkColor/${colorId}`);
    }

    getMaterialsForConfigSystem(configSystemId: number, selectedIds: number[] = null, readOnly = false): Observable<Material[]> {
        const params = selectedIds ? {selectedIds: selectedIds.toString()} : {};
        params['readOnly'] = `${readOnly}`;
        return this.http.get<Material[]>(`${MaterialService.API_URL}/forConfigSystem/${configSystemId}`, {params: params});
    }

    getMaterialColorImageLargeAsString(materialId: number, colorId: number): Observable<string> {
        return this.http.get(`${MaterialService.API_URL}/${materialId}/imageLarge/${colorId}`, {
            responseType: 'text',
            headers: {Accept: this.dataServiceHelper.getFileAcceptHeader()}
        });
    }
}
