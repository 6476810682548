import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input, OnInit, Output} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {saveAs} from "file-saver";
import {finalize} from "rxjs/operators";
import {BlockUiController} from "../../../../block-ui/block-ui-controller";
import {CommonErrorHandler} from "../../../../common/CommonErrorHandler";
import {GrowlMessageController} from "../../../../common/growl-message/growl-message-controller";
import {OnceFlag} from "../../../../shared/once-flag";
import {MotlawaIntegrationDialogsData} from "../motlawa-integration-dialogs-data";
import {MotlawaIntegrationService} from "../motlawa-integration.service";
import {MotlawaRowDiffType} from "./MotlawaRowDiffType";

@Component({
    selector: 'app-motlawa-data',
    templateUrl: './motlawa-data-dialog.component.html',
    styleUrls: ['./motlawa-data-dialog.component.css'],
    providers: [MotlawaIntegrationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MotlawaDataDialogComponent implements OnInit {

    public static readonly MOTLAWA_SEND_DATA_BLOCK_ID = 'motlawaSendData';
    public static readonly MOTLAWA_SEND_DATA_SUCCESS_MESSAGE = 'MOTLAWA_INTEGRATION_DIALOG.SUCCESS';
    public static readonly MODIFIED_DATA_INDICATOR = '\uFEFF';

    @Input() dialogData: MotlawaIntegrationDialogsData;

    @Output() onHide = new EventEmitter<void>();

    private readonly dialogHideHelper = new OnceFlag();

    public translate: TranslateService;
    private blockUiController: BlockUiController;
    private motlawaIntegrationService: MotlawaIntegrationService;
    private growls: GrowlMessageController;
    private errors: CommonErrorHandler;

    records: string[][];
    diffRecords: string[][];
    showDiff = false;
    showIds = false;
    hasDiffs = false;
    rowDiffs = new Map<number, MotlawaRowDiffType>();
    cellDiffs = new Map<number, number[]>();

    constructor(injector: Injector,
                private changeDetector: ChangeDetectorRef) {
        this.translate = injector.get(TranslateService);
        this.blockUiController = injector.get(BlockUiController);
        this.motlawaIntegrationService = injector.get(MotlawaIntegrationService);
        this.growls = injector.get(GrowlMessageController);
        this.errors = injector.get(CommonErrorHandler);
    }

    ngOnInit(): void {
        this.records = this.splitCsv(this.dialogData.results.csvData);
        this.diffRecords = this.splitCsv(this.dialogData.results.comparedCsvData, true);
    }

    splitCsv(csv: string, markDiffs = false): string[][] {
        if (csv == undefined) {
            return [];
        }
        const rows = csv.split(/\r\n|\n/).filter(String)
            .map(row => row.split(this.dialogData.results.csvDelimiter).map(record => this.stripQuotes(record)));
        if (markDiffs) {
            for (let i = 0; i < rows.length; i++) {
                const diff = rows[i].pop();
                if (diff === MotlawaRowDiffType.ADDED || diff === MotlawaRowDiffType.DELETED) {
                    this.rowDiffs.set(i, diff);
                    this.hasDiffs = true;
                }
                let modifiedCellIndexes = [];
                for (let j = 0; j < rows[i].length; j++) {
                    if (this.cellWasModified(rows[i][j])) {
                        modifiedCellIndexes.push(j);
                        rows[i][j] = rows[i][j].slice(0, -1);
                        this.hasDiffs = true;
                    }
                }
                this.cellDiffs.set(i, modifiedCellIndexes);
            }
        }
        return rows;
    }

    cellWasModified(value: string): boolean {
        return value && value.slice(-1) === MotlawaDataDialogComponent.MODIFIED_DATA_INDICATOR;
    }

    hide(): void {
        this.dialogHideHelper.call(() => this.onHide.emit());
    }

    download(): void {
        // explicit BOM for excel
        const BOM = "\uFEFF";
        saveAs(new Blob([BOM, this.dialogData.results.csvData]),
            `dane_motlawa__paczka_platnosci_${this.dialogData.paymentPackageId}.csv`, {autoBom: false});
    }

    stripQuotes(value: string): string {
        return value.substring(1, value.length - 1).replace('""', '"');
    }

    send(): void {
        this.blockUiController.block(MotlawaDataDialogComponent.MOTLAWA_SEND_DATA_BLOCK_ID);
        this.motlawaIntegrationService.sendMotlawaData(this.dialogData.paymentPackageId, this.dialogData.addonsSelections.selections, this.dialogData.offerChargesSelections.selections)
            .pipe(finalize(() => this.blockUiController.unblock(MotlawaDataDialogComponent.MOTLAWA_SEND_DATA_BLOCK_ID)))
            .subscribe({
                next: () => {
                    this.growls.info(MotlawaDataDialogComponent.MOTLAWA_SEND_DATA_SUCCESS_MESSAGE);
                    this.dialogData.results.successfulSendingDate = new Date();
                    this.changeDetector.markForCheck();
                },
                error: error => {
                    this.errors.handle(error, true);
                }
            });
    }
}
