import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class SubsystemNoteCategory implements CrudItem {
    id: number;
    name: MultilanguageField;
    active: boolean;
    sortIndex: number;

    static fromJSON(jsonObject: any): SubsystemNoteCategory {
        const category = new SubsystemNoteCategory();
        category.id = jsonObject.id;
        category.name = MultilanguageField.fromJSON(jsonObject.name);
        category.active = jsonObject.active;
        category.sortIndex = jsonObject.sortIndex;
        return category;
    }
}
