<div class="new-form-60vw">
    <div class="new-form-row">
        <app-input-text [(ngModel)]="item.symbol" [(validationMessageKey)]="validationErrors['symbol']"
                        [maxlength]="50"
                        inputId="symbol" label="{{ 'CONFIG_SLIDER_ELEMENT.FORM.SYMBOL' | translate }}">
        </app-input-text>
    </div>

    <app-translation-fields [field]="item.label" [maxlength]="100"
                            [validationErrors]="validationErrors" inputId="label"
                            label="{{ 'CONFIG_SLIDER_ELEMENT.FORM.LABEL' | translate }}" validationKeyPrefix="label"></app-translation-fields>

    <div class="new-form-row">
        <app-select [(ngModel)]="item.configSystemId" [(validationMessageKey)]="validationErrors['configSystemId']"
                    [options]="configSystems"
                    [required]="true" inputId="configSystemId"
                    label="{{ 'CONFIG_SLIDER_ELEMENT.FORM.CONFIG_SYSTEM' | translate }}"></app-select>
    </div>

    <div class="new-form-row">
        <app-checkbox [(ngModel)]="item.active" inputId="active"
                      label="{{ 'CONFIG_SLIDER_ELEMENT.FORM.ACTIVE' | translate }}"></app-checkbox>
    </div>

    <div *ngIf="item.id != null" class="new-form-row">
        <app-input-number [(ngModel)]="item.sortIndex"
                          [(validationMessageKey)]="validationErrors['sortIndex']"
                          [allowOnlyIntegers]="true"
                          [max]="9999" [min]="1" [required]="true" [step]="1"
                          inputId="sortIndex" label="{{ 'CONFIG_SLIDER_ELEMENT.FORM.SORT_INDEX' | translate }}"></app-input-number>
    </div>

    <div class="new-form-row">
        <p-accordion>
            <p-accordionTab [ngClass]="{'accordion-header-with-errors': validationErrors['imageLarge']}" [selected]="false"
                            header="{{ 'CONFIG_SLIDER_ELEMENT.FORM.IMAGE_LARGE_SECTION' | translate }}">
                <app-file-upload (ngModelChange)="imageLargeChange.emit($event)" [(validationMessageKey)]="validationErrors['imageLarge']"
                                 [addButtonLabel]="'CONFIG_SLIDER_ELEMENT.FORM.SELECT_FILE_UPLOAD'"
                                 [changeButtonLabel]="'CONFIG_SLIDER_ELEMENT.FORM.CHANGE_FILE_UPLOAD'" [deleteButtonLabel]="'CONFIG_SLIDER_ELEMENT.FORM.DELETE_FILE_UPLOAD'"
                                 [maxHeight]="8000" [maxSize]="12000000" [maxWidth]="8000" [ngModel]="image"
                                 inputId="imageLarge"></app-file-upload>
            </p-accordionTab>
        </p-accordion>
    </div>

</div>
