import {MultilanguageField} from '../../../../../supportedLanguages';
import {PriceWithTax} from '../../../offer';
import {MessageSeverity, PositionMessage} from '../../../offers/message';
import {Charge} from './Charge';

export class Product {

    static readonly GENERAL_ADDONS_GENERATED_ID = 'GENERAL_ADDONS_ID';

    generatedId: string;
    positionName: MultilanguageField;
    dimension: string;
    venskaBuyPrice: PriceWithTax;
    buyPrice: PriceWithTax;
    sellPrice: PriceWithTax;
    retailSellPrice: PriceWithTax;
    components: Charge[];
    messages: PositionMessage[];

    public static contains(product: Product, severity: MessageSeverity) {
        for (let message of product.messages) {
            if (message.severity === severity) {
                return true;
            }
        }
        return false;
    }
}
