import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {ValidationErrors} from '../../../../common/validation-errors';
import {MaterialField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {MaterialFieldUsage} from "../../catalog-field-usage";
import {Material} from '../material';

@Component({
    selector: 'app-material-form',
    templateUrl: './material-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaterialFormComponent {

    readonly lengthLimit = COMMON_INPUT_LENGTH_LIMITS;

    @Input()
    item: Material;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    editPermits: FieldLimitation[] = [];

    @Input()
    file: File;

    @Output()
    readonly fileChange = new EventEmitter<File>();

    fieldUsage: MaterialFieldUsage;
    MaterialField = MaterialField;

    constructor() {
        this.fieldUsage = new MaterialFieldUsage(this);
    }

    onFileChange(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }
}
