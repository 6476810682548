import {ProductTypeGroup} from '../../app/features/window-system/window-system-definition/product-type-group';
import {CompositionDistances} from '../composition-distances';
import {GlazingPackageInterface} from './glazing-package-interface';
import {MultilanguageFieldInterface} from './multilanguage-field-interface';

export enum WindowSystemTypeEnum {
    WINDOW = 'WINDOW',
    WINDOW_PREDEFINED_GLAZING = 'WINDOW_PREDEFINED_GLAZING',
    DOORS = 'DOORS',
    DOORS_PREDEFINED_GLAZING = 'DOORS_PREDEFINED_GLAZING',
    HS = 'HS',
    HS_PREDEFINED_GLAZING = 'HS_PREDEFINED_GLAZING',
    SLIDING = 'SLIDING',
    SLIDING_PREDEFINED_GLAZING = 'SLIDING_PREDEFINED_GLAZING',
    ROTATING = 'ROTATING',
    TILT_SLIDING = 'TILT_SLIDING',
    TILT_SLIDING_PREDEFINED_GLAZING = 'TILT_SLIDING_PREDEFINED_GLAZING',
    PENDULUM = 'PENDULUM',
    ROOF = 'ROOF',
    TERRACE = 'TERRACE',
    ENTRANCE = 'ENTRANCE',
}

export enum OpeningOption {
    INSIDE = 'INSIDE',
    OUTSIDE = 'OUTSIDE',
    NONE = 'NONE'
}

export enum AutoAlignOption {
    TO_FRAMES = 'TO_FRAMES',
    TO_GLASSES = 'TO_GLASSES',
}

export enum DoorSide {
    RIGHT = 'RIGHT',
    LEFT = 'LEFT'
}

export interface WindowSystemInterface {
    id: number;
    names: MultilanguageFieldInterface;
    material: string;
    defaultWidth: number;
    defaultHeight: number;
    doors: boolean;
    hsSystem: boolean;
    systemType?: WindowSystemTypeEnum;
    wingWidth: CompositionDistances;
    glazingBeadWidth: CompositionDistances;
    wingImpositionWidth: CompositionDistances;
    glazingPackageSeatingDepth: CompositionDistances;
    wingsImpositionWidth: number;
    wingsContactWidth: number;
    glazingPackage: GlazingPackageInterface;
    glazingPackageId: number;
    decorativeGlazingPackage?: GlazingPackageInterface;
    decorativeGlazingPackageId?: number;
    autoAlign: AutoAlignOption;
    allowMullionCrossing: boolean;
    doorstepAllowedInFF: boolean;
    allowFestAtHinges: boolean;
}

export interface WindowSystemTypeData {
    type: WindowSystemTypeEnum;
    doors: boolean;
    additionalProperties: boolean;
    group: ProductTypeGroup;
    predefinedGlazing: boolean;
}

export class WindowSystemType {

    static readonly WINDOW: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.WINDOW,
        doors: false,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: false
    };
    static readonly WINDOW_PREDEFINED_GLAZING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.WINDOW_PREDEFINED_GLAZING,
        doors: false,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: true
    };
    static readonly DOORS: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.DOORS,
        doors: true,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: false
    };
    static readonly DOORS_PREDEFINED_GLAZING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.DOORS_PREDEFINED_GLAZING,
        doors: true,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: true
    };
    static readonly HS: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.HS,
        doors: true,
        additionalProperties: true,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: false
    };
    static readonly HS_PREDEFINED_GLAZING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.HS_PREDEFINED_GLAZING,
        doors: true,
        additionalProperties: true,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: true
    };
    static readonly SLIDING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.SLIDING,
        doors: true,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: false
    };
    static readonly SLIDING_PREDEFINED_GLAZING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.SLIDING_PREDEFINED_GLAZING,
        doors: true,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: true
    };
    static readonly ROTATING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.ROTATING,
        doors: false,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: false
    };
    static readonly TILT_SLIDING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.TILT_SLIDING,
        doors: false,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: false
    };
    static readonly TILT_SLIDING_PREDEFINED_GLAZING: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.TILT_SLIDING_PREDEFINED_GLAZING,
        doors: false,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: true
    };
    static readonly PENDULUM: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.PENDULUM,
        doors: true,
        additionalProperties: false,
        group: ProductTypeGroup.DEFAULT,
        predefinedGlazing: false
    };
    static readonly ROOF: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.ROOF,
        doors: false,
        additionalProperties: false,
        group: ProductTypeGroup.ROOF,
        predefinedGlazing: false
    };
    static readonly TERRACE: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.TERRACE,
        doors: true,
        additionalProperties: true,
        group: ProductTypeGroup.TERRACE,
        predefinedGlazing: false
    };
    static readonly ENTRANCE: WindowSystemTypeData = {
        type: WindowSystemTypeEnum.ENTRANCE,
        doors: true,
        additionalProperties: false,
        group: ProductTypeGroup.ENTRANCE,
        predefinedGlazing: false
    };

    static isPredefinedGlazing(ws: WindowSystemTypeData): boolean {
        return ws.predefinedGlazing;
    }

    static getWindowAndTerraceSystemsTypes(): WindowSystemTypeData[] {
        return [
            ...this.getWindowSystemTypes(),
            WindowSystemType.TERRACE
        ];
    }

    static getWindowSystemTypes(): WindowSystemTypeData[] {
        return [
            WindowSystemType.WINDOW,
            WindowSystemType.WINDOW_PREDEFINED_GLAZING,
            WindowSystemType.DOORS,
            WindowSystemType.DOORS_PREDEFINED_GLAZING,
            WindowSystemType.HS,
            WindowSystemType.HS_PREDEFINED_GLAZING,
            WindowSystemType.SLIDING,
            WindowSystemType.SLIDING_PREDEFINED_GLAZING,
            WindowSystemType.ROTATING,
            WindowSystemType.TILT_SLIDING,
            WindowSystemType.TILT_SLIDING_PREDEFINED_GLAZING,
            WindowSystemType.PENDULUM,
        ];
    }

    static getAllTypes(): WindowSystemTypeData[] {
        return [
            ...this.getWindowSystemTypes(),
            WindowSystemType.TERRACE,
            WindowSystemType.ROOF,
            WindowSystemType.ENTRANCE
        ];
    }

    static getByName(systemType: WindowSystemTypeEnum): WindowSystemTypeData {
        return this.getAllTypes().find(type => type.type === systemType);
    }

    static getByGroup(windowSystemTypeGroup: ProductTypeGroup): WindowSystemTypeData[] {
        return this.getAllTypes().filter(type => type.group === windowSystemTypeGroup);
    }
}
