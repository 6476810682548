import {ChangeDetectorRef, Component, Injector, ViewChild} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DatatableInterface} from '../../../common/DatatableHelper';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ImagesValidator} from '../../../form-inputs/inputs/file-upload/images-validator';
import {callWizardStepValidators, WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {MultilanguageField} from '../../../supportedLanguages';
import {CatalogItemTag} from './catalog-item-tag';
import {CatalogItemTagsService} from './catalog-item-tags.service';

@Component({
    selector: 'app-catalog-item-tags',
    templateUrl: './catalog-item-tags.component.html',
    styleUrls: ['./catalog-item-tags.component.css'],
    providers: [CatalogItemTagsService, TranslatedSelectItemService]
})
export class CatalogItemTagsComponent extends CrudCommonComponent<CatalogItemTag, CatalogItemTagsService> {

    readonly STEPS = {
        DATA: 'DATA'
    };

    validateDataStep: WizardStepValidator;

    @ViewChild('dt')
    dataTable: DatatableInterface;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, false, CatalogItemTagsService, 'CATALOG_ITEM_TAG', 'CatalogItemTag');
        this.validateDataStep = () => this.validateData().pipe(map(validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            return !ValidationErrorsHelper.validationErrorsPresent(validationErrors);
        }));
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
    }

    getDatatable(): DatatableInterface {
        return this.dataTable;
    }

    getNewItem(): CatalogItemTag {
        const catalogItemTag = new CatalogItemTag();
        catalogItemTag.tagText = new MultilanguageField();
        catalogItemTag.active = true;
        catalogItemTag.sortIndex = 1;
        return catalogItemTag;
    }

    protected validateForm(): Observable<boolean> {
        return callWizardStepValidators([this.validateDataStep])
            .pipe(map(results => results.every(result => result)));
    }

    onRowSelect(event: any): void {
        this.resetFile();
        this.validationErrors = {};
        this.itemId = event.data.id;
        this.item = new CatalogItemTag();
        this.newItem = false;
        this.selectedItem = event.data;
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    private getItemWithImage(catalogItemTagId: number): void {
        forkJoin({
            item: this.itemService.getItem(catalogItemTagId),
            image: this.itemService.getImage(catalogItemTagId)
        }).subscribe(data => {
            this.item = data.item;
            this.file = data.image;
            this.setDisplayDialog(true);
        });
    }

    prepareItemForRequest(): CatalogItemTag {
        if (this.item.color != undefined && this.item.color.trim() === "") {
            this.item.color = undefined;
        }
        return this.item;
    }

    private validateData(): Observable<ValidationErrors> {
        const validationErrors: ValidationErrors = {};
        if (!this.item.tagText[this.translate.currentLang] || !this.item.tagText[this.translate.currentLang].trim()) {
            validationErrors[`tagText[${this.translate.currentLang}]`] =
                `error.catalogItemTagDto.tagText[${this.translate.currentLang}].not_empty`;
        }
        if (this.file == undefined) {
            validationErrors['icon'] = 'error.catalogItemTagDto.icon.not_null';
        }
        if (this.item.color == undefined ||
            !/^#[0-9A-Fa-f]{6}$/.test(this.item.color)) {
            validationErrors['color'] = 'error.catalogItemTagDto.color.pattern_not_matched';
        }
        if (this.item.active == undefined) {
            validationErrors['active'] = 'error.catalogItemTagDto.active.not_null';
        }
        validationErrors['sortIndex'] = MultiValidator.of('error.catalogItemTagDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);
        return of<ValidationErrors>(validationErrors)
            .pipe(mergeMap(errors => {
                if (!validationErrors['icon']) {
                    return ImagesValidator.validationErrors(this.file,
                        50000,
                        400,
                        400,
                        undefined,
                        ['image/svg+xml'])
                        .pipe(map(iconError => {
                            validationErrors['icon'] = iconError;
                            return validationErrors;
                        }));
                }
                return of(errors);
            }));
    }
}
