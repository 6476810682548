<div class="new-form-60vw">
    <app-translation-fields *ngIf="fieldUsage.show(DistanceFrameField.NAME)"
                            inputId="names" label="{{ 'DISTANCE-FRAME.FORM.NAME' | translate }}"
                            validationKeyPrefix="names" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(DistanceFrameField.NAME)"
                            [maxlength]="lengthLimit.NAME_MAX_LENGTH" [required]="true"
                            [field]="item.names"></app-translation-fields>
    <app-translation-fields *ngIf="fieldUsage.show(DistanceFrameField.SHORTCUT)"
                            inputId="shortcut" label="{{ 'DISTANCE-FRAME.FORM.SHORTCUT' | translate }}"
                            validationKeyPrefix="shortcut" [validationErrors]="validationErrors"
                            [disabled]="fieldUsage.disabled(DistanceFrameField.SHORTCUT)"
                            [maxlength]="lengthLimit.SHORTCUT_MAX_LENGTH"
                            [field]="item.shortcut"></app-translation-fields>
    <div *ngIf="fieldUsage.show(DistanceFrameField.SYMBOL)" class="new-form-row">
        <app-input-text inputId="symbol" label="{{ 'DISTANCE-FRAME.FORM.SYMBOL' | translate }}"
                        [disabled]="fieldUsage.disabled(DistanceFrameField.SYMBOL)"
                        [(validationMessageKey)]="validationErrors['symbol']" [maxlength]="100" [required]="true"
                        [(ngModel)]="item.symbol"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DistanceFrameField.THICKNESS)">
        <app-input-number inputId="thickness" label="{{ 'DISTANCE-FRAME.FORM.THICKNESS' | translate }}"
                          [disabled]="fieldUsage.disabled(DistanceFrameField.THICKNESS)"
                          [allowOnlyIntegers]="true" [(validationMessageKey)]="validationErrors['thickness']" [min]="1"
                          [max]="999" [required]="true" [ngModel]="item.thickness"
                          (ngModelChange)="handleThicknessChange($event)"></app-input-number>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DistanceFrameField.GROUP)">
        <app-input-text inputId="frameGroup" label="{{ 'DISTANCE-FRAME.FORM.GROUP' | translate }}"
                        [disabled]="fieldUsage.disabled(DistanceFrameField.GROUP)"
                        [(validationMessageKey)]="validationErrors['frameGroup']" [maxlength]="100"
                        [required]="true" [(ngModel)]="item.frameGroup"></app-input-text>
    </div>
    <div class="new-form-row" *ngIf="fieldUsage.show(DistanceFrameField.TYPE)">
        <app-select inputId="type" label="{{ 'DISTANCE-FRAME.FORM.TYPE' | translate }}"
                    [disabled]="fieldUsage.disabled(DistanceFrameField.TYPE)"
                    [(validationMessageKey)]="validationErrors['type']" [options]="types" [required]="true"
                    [(ngModel)]="item.type"></app-select>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(DistanceFrameField.WARM_ALTERNATIVE)">
        <app-select inputId="warmAlternative" label="{{ 'DISTANCE-FRAME.FORM.WARM_ALTERNATIVE' | translate }}"
                    [disabled]="fieldUsage.disabled(DistanceFrameField.WARM_ALTERNATIVE)"
                    [(validationMessageKey)]="validationErrors['warmAlternativeDistanceFrameId']"
                    [options]="warmFramesByThickness.get(item.thickness)"
                    [optionFormatter]="formatDistanceFrameSelectItem" [allowSelectingNone]="true"
                    [(ngModel)]="item.warmAlternativeDistanceFrameId"></app-select>
    </div>

    <!-- SORT INDEX -->
    <div *ngIf="fieldUsage.show(DistanceFrameField.SORT_INDEX)" class="new-form-row">
        <app-input-number inputId="sortIndex"
                          label="{{ 'DISTANCE-FRAME.FORM.SORT_INDEX' | translate }}"
                          [disabled]="fieldUsage.disabled(DistanceFrameField.SORT_INDEX)"
                          [allowOnlyIntegers]="true"
                          [(validationMessageKey)]="validationErrors['sortIndex']" [min]="1" [max]="999" [step]="1"
                          [(ngModel)]="item.sortIndex" [required]="true"></app-input-number>
    </div>

    <div class="new-form-row" *ngIf="fieldUsage.show(DistanceFrameField.WARM_FRAME)">
        <app-checkbox inputId="warmFrame" label="{{ 'DISTANCE-FRAME.FORM.WARM_FRAME' | translate }}"
                      [disabled]="fieldUsage.disabled(DistanceFrameField.WARM_FRAME)"
                      [(ngModel)]="item.warmFrame"></app-checkbox>
    </div>

    <div *ngIf="fieldUsage.show(DistanceFrameField.ACTIVE)" class="new-form-row">
        <app-checkbox inputId="active" label="{{ 'DISTANCE-FRAME.FORM.ACTIVE' | translate }}"
                      [disabled]="fieldUsage.disabled(DistanceFrameField.ACTIVE)"
                      [(ngModel)]="item.active"></app-checkbox>
    </div>
</div>
