import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import * as moment from 'moment';
import {SelectItem} from 'primeng/api/selectitem';
import {LocaleSettings} from "primeng/calendar";
import {Observable, Subscription} from 'rxjs';
import {CurrentUserService} from '../../../auth/current-user.service';
import {Permissions} from "../../../auth/permission.service";
import {UserRoleTypes} from '../../../common/enums/UserRoleTypes';
import {ListboxSelectionItem} from "../../../common/listbox-selection/listbox-selection.component";
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {CalendarLocaleProvider} from "../../../form-inputs/inputs/calendar/calendar-locale-provider";
import {SupportedLanguage, SupportedLanguages} from "../../../supportedLanguages";
import {ClientService} from '../../client/client.service';
import {User} from "../user";
import {UserService} from '../user.service';

@Component({
    selector: 'app-user-details-form',
    templateUrl: './user-details-form.component.html',
    styleUrls: ['./user-details-form.component.css'],
    providers: [ClientService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailsFormComponent implements OnInit, OnChanges, OnDestroy {

    @Input() isNew: boolean;
    @Input() user: User;
    @Input() validationErrors: any;
    @Input() roles: SelectItem[] = [];
    @Input() additionalRoles: SelectItem[] = [];
    @Input() subsystems: SelectItem[] = [];
    @Input() file: File;
    @Output() readonly fileChange = new EventEmitter<File>();

    clients: SelectItem[] = [];
    selectedClient: ListboxSelectionItem;

    supportedLanguages: Observable<SelectItem[]>;

    isVenskaRole = false;

    private langChangeSubscription: Subscription;

    calendarLocale: LocaleSettings;

    minAccountExpirationDate: Date;

    webshopApiToken: string;

    selectedRoles: number[];

    constructor(public permissions: Permissions,
                private sanitizer: DomSanitizer,
                private currentUserService: CurrentUserService,
                private translate: TranslateService,
                private userService: UserService,
                private clientService: ClientService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private calendarLocaleProvider: CalendarLocaleProvider,
                private changeDetector: ChangeDetectorRef) {
        this.supportedLanguages = this.translatedSelectItemService
            .buildUnsortedDropdown(SupportedLanguages.languages, lang => lang.name, undefined);
        this.calendarLocale = this.calendarLocaleProvider.getPrimengCalendarSettings(this.translate.currentLang);
        this.minAccountExpirationDate = moment().subtract(1, 'day').toDate();
    }

    ngOnInit(): void {
        this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.calendarLocale = this.calendarLocaleProvider.getPrimengCalendarSettings(event.lang);
            if (this.user.accountExpirationDate != undefined) {
                this.user.accountExpirationDate = new Date(this.user.accountExpirationDate.getTime());
            }
        });

        this.loadClients();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('user' in changes || 'additionalRoles' in changes) {
            this.selectedRoles = this.user.roleIds.filter(roleId => this.additionalRoles.find(role => role.value === roleId) != undefined);
            this.roleChange();
        }
    }

    loadClients() {
        let mapClient = client => ({
            label: client.name + ' ' + client.groupName,
            value: new ListboxSelectionItem(client.id, [client.name, client.groupName])
        });

        if (!this.isNew && this.user.roleName === UserRoleTypes.SPRZEDAWCA) {
            this.clientService.getClient(this.user.clientId).subscribe(client => {
                this.clients = [mapClient(client)];
                this.changeDetector.markForCheck();
            });
            return;
        }
        if (this.user.subsystemId && this.user.roleName === UserRoleTypes.SPRZEDAWCA) {
            this.clientService.getNotWebshopClientsForSubsystem(this.user.subsystemId)
                .subscribe(clients => {
                    this.clients = clients.sort((a, b) => a.name.localeCompare(b.name)).map(mapClient);

                    const foundClient = this.clients.find(c => c.value.id === this.user.clientId);
                    this.selectedClient = foundClient !== undefined ? foundClient.value : clients.length > 0 ?
                        this.clients[0].value : undefined;
                    this.user.clientId = this.selectedClient !== undefined ? this.selectedClient.id : undefined;
                    this.changeDetector.markForCheck();
                });
        } else {
            this.clients = [];
            this.selectedClient = undefined;
            this.user.clientId = undefined;
        }
    }

    ngOnDestroy(): void {
        if (this.langChangeSubscription != undefined) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    editedUserIsMyself(): boolean {
        return this.user.id === this.currentUserService.currentUserId;
    }

    canEditUser(): boolean {
        return (this.isPermitted({roles: ['ROLE_CREATE_USER']}) && this.user.id == null)
            || (this.isPermitted({roles: ['ROLE_EDIT_USER']}) && this.user.id != null)
            || (this.isPermitted({roles: ['ROLE_EDIT_USER_WEBSHOP']}) && this.user.roleName === 'WEBSHOP');
    }

    canEditUserExpirationDate(): boolean {
        return (this.isPermitted({roles: ['ROLE_CREATE_USER']}) && this.user.id == null)
            || (this.isPermitted({roles: ['ROLE_EDIT_USER']}) && this.user.id != null);
    }

    fileChanged(file: File): void {
        this.fileChange.emit(file);
        this.file = file;
    }

    roleChange(): void {
        this.isVenskaRole =
            this.user.roleName != undefined && (this.user.roleName === 'KOORDYNATOR' || this.user.roleName === 'OPIEKUN');

        if (this.isVenskaRole) {
            this.user.subsystemId = undefined;
        } else {
            this.user.motlawaLogin = undefined;
        }

        if (this.user.id == undefined) {
            this.user.enableOfferExport = this.user.roleName === 'KOORDYNATOR';
        }

        this.loadClients();
    }

    subsystemChange(subsystemId: string): void {
        this.user.subsystemId = subsystemId != undefined ? Number.parseInt(subsystemId, 10) : undefined;
        this.loadClients();
    }

    clientChange(clientId: number): void {
        if (clientId != undefined) {
            this.user.clientId = clientId;
        }
    }

    handleAccountExpirationDateChange(date: Date): void {
        this.user.accountExpirationDate = moment(date).endOf('day').toDate();
        this.validationErrors['accountExpirationDate'] = undefined;
    }

    isExistingWebshopUser(): boolean {
        return this.user.id != undefined && this.user.roleName === 'WEBSHOP';
    }

    generateWebshopToken(): void {
        this.userService.generateWebshopToken(this.user.id).subscribe(token => {
            this.webshopApiToken = token;
            this.changeDetector.markForCheck();
        });
    }

    isChangeRoleDisabled(): boolean {
        return this.user.id != null && (!this.isPermitted({roles: ['ROLE_KOORDYNATOR']}) || this.roles.length < 2);
    }

    languageOptionFormatter = (lang: SelectItem): SelectItem => ({label: lang.label, value: (lang.value as SupportedLanguage).code});

    handleRolesChange(roles: any) {
        this.user.roleIds = roles;
    }

    handleClientSelection(selectedItem: ListboxSelectionItem) {
        const foundClient = this.clients.find(client => client.value.id === selectedItem.id).value;
        this.clientChange(foundClient.id);
    }
}
