import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {DataTable} from 'primeng/datatable';
import {forkJoin, Observable, of} from 'rxjs';
import {map, mergeMap, tap} from 'rxjs/operators';
import {CrudCommonComponent} from '../../../common/crud-common/crud.component';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {DataTableColumnBuilder} from '../../../common/service/data.table.column.builder';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {WizardStepValidator} from '../../../form-inputs/wizard/wizard-step.component';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {CatalogElement} from "../../admin-panel/edit-catalog-permits/catalog-element.enum";
import {CatalogTab, GlazingBeadField} from '../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {EditCatalogPermitsService} from "../../admin-panel/edit-catalog-permits/edit-catalog-permits.service";
import {FieldLimitation} from "../../admin-panel/edit-catalog-permits/field-limitation";
import {GlazingBeadFieldUsage} from "../catalog-field-usage";
import {SingleSystemCheckboxCrudComponent} from '../single-system-checkbox-crud/single-system-checkbox-crud.component';
import {WindowSystemDefinitionService} from '../window-system-definition/window-system-definition.service';
import {ProductTypeGroup} from '../window-system-definition/product-type-group';
import {GlazingBead} from './glazing-bead';
import {GlazingBeadService} from './glazing-bead.service';

@Component({
    selector: 'app-glazing-bead',
    templateUrl: './glazing-bead.component.html',
    styleUrls: ['./glazing-bead.component.css', '../../shared-styles.css'],
    providers: [GlazingBeadService, DataServiceHelper, WindowSystemDefinitionService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GlazingBeadComponent extends SingleSystemCheckboxCrudComponent<GlazingBead, GlazingBeadService> implements OnInit {

    readonly windowSystemTypeGroups = [ProductTypeGroup.DEFAULT, ProductTypeGroup.TERRACE];

    filterType: SelectItem[];
    item: GlazingBead;
    @ViewChild('dt') datatable;
    validateDataStep: WizardStepValidator;

    readonly STEPS = {
        DATA: 'DATA',
        SYSTEMS: 'SYSTEMS'
    };

    editPermits: FieldLimitation[] = [];
    fieldUsage: GlazingBeadFieldUsage;
    CatalogTab = CatalogTab;
    GlazingBeadField = GlazingBeadField;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private editCatalogPermitsService: EditCatalogPermitsService) {
        super(injector, changeDetector, true, GlazingBeadService, 'GLAZING_BEAD', 'GlazingBead');
        this.validateDataStep = () => this.validateForm();
        this.initDefaultSortOrder();
        this.fieldUsage = new GlazingBeadFieldUsage(this);
    }

    ngOnInit() {
        super.ngOnInit();
        this.filterActive = CrudCommonComponent.buildActiveDropdown();
        this.defaultActiveFilter = this.filterActive[1];
        this.item = this.getNewItem();
        this.editCatalogPermitsService.getPermitsByCatalogElement(CatalogElement.GLAZING_BEADS).subscribe(permits => {
            this.editPermits = permits.fieldsLimitations;
        });
    }

    protected getApiUrl(): string {
        return 'glazing-bead';
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.item = new GlazingBead();
        this.getItemWithImage(event.data.id);
        this.keepSelectedItemIndex(event);
    }

    showDialogToCopy() {
        if (this.selectedItem) {
            this.validationErrors = {};
            this.item = new GlazingBead();
            this.getItemWithImage(this.selectedItem.id);
        }
    }

    getNewItem(): GlazingBead {
        this.selectedWindowSystems = [];
        return new GlazingBead();
    }

    getItemWithImage(glazingBeadId: number) {
        forkJoin({
            item: this.itemService.getItem(glazingBeadId),
            file: this.itemService.getImageAsFile(glazingBeadId),
            linkedSystems: this.getLinkedWindowSystems(glazingBeadId)
        }).subscribe({
            next: data => {
                this.item = data.item;
                if (this.copyMode) {
                    this.item.id = undefined;
                }
                this.file = data.file;
                if (!this.copyMode) {
                    // manualy focus on first row, because filling all data from backend makes primeng lose focus somehow..
                    this.focusOnElementWithId(this.getFirstInputId());
                } else {
                    this.item.id = undefined;
                }
                this.selectedWindowSystems = data.linkedSystems;
            },
            error: error => {
                this.setErrors(error);
            },
            complete: () => {
                this.setDisplayDialog(true);
                console.debug('getItemWithImage` completed!');
            }
        });
    }

    validateForm(): Observable<boolean> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors, 'image');
        if (!this.item.names[this.userLang] || this.item.names[this.userLang].trim() === '') {
            this.validationErrors[`names[${this.userLang}]`] = `error.glazingBeadDto.names[${this.userLang}].not_empty`;
        }
        this.validationErrors['thickness'] = MultiValidator.of('error.glazingBeadDto.thickness')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(0, 99999, false, true).validate(this.item.thickness);
        if (this.item.symbol == null || this.item.symbol === '') {
            this.validationErrors['symbol'] = 'error.glazingBeadDto.symbol.not_empty';
        }

        this.validationErrors['sortIndex'] = MultiValidator.of('error.colorDto.sortIndex')
            .withNotNullValidator()
            .withIntegerValidator()
            .withRangeValidator(1, 99999999999)
            .validate(this.item.sortIndex);

        this.validationErrors['acceptableFillingWidth'] = MultiValidator.of('error.glazingBeadDto.acceptableFillingWidth')
            .withNotNullValidator()
            .withSingleWidthValidator(0, 1000, true, false).validate(this.item.acceptableFillingWidth);
        if (this.validationErrorsPresent(this.validationErrors)) {
            this.validationErrors = Object.assign({}, this.validationErrors);
            return of(false);
        }
        return this.itemService.validate(this.item).pipe(
            tap(backendValidationErrors => {
                this.validationErrors = Object.assign({}, this.validationErrors, backendValidationErrors);
                this.changeDetector.markForCheck();
            }),
            map(backendValidationErrors => !this.validationErrorsPresent(backendValidationErrors)));
    }

    submit() {
        this.validationErrors = {};
        if (this.validateForm()) {
            if (this.isSaveInProgress()) {
                return;
            }
            this.setSaveInProgress(true);
            let observable: Observable<number>;
            if (this.copyMode) {
                observable = this.itemService.copy(this.selectedItem.id, this.item, this.file).pipe(mergeMap(this.editLinksAfterSave()));
            } else {
                observable = this.itemService.saveItem(this.item, this.file).pipe(mergeMap(this.editLinksAfterSave()));
            }
            observable.subscribe(this.genericCleanupAndReloadSuccessObserver());
        }
    }

    private initDefaultSortOrder(): void {
        this.defaultSortColumn = 'sortIndex';
        this.defaultSortOrder = DataTableColumnBuilder.ORDER_ASCENDING;
    }
}
