<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'NAVIGATION.ITEMS.PRICE-LISTS.SHIPPING_PRICE_TABLES.MENU' | translate }}</div>
    </div>
    <p-dataTable #dt [value]="shippingPriceTableInfoList"
                 [totalRecords]="totalRecords" (onLazyLoad)="loadData()"
                 [responsive]="true" selectionMode="single"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">

        <p-column field="country"
                  [header]="'SHIPPING_PRICE_TABLES.FORM.COUNTRY' | translate" >
            <ng-template pTemplate="body" let-table="rowData">
                {{ ('COUNTRIES.' + table.country) | translate }}
            </ng-template>
        </p-column>

        <p-column field="priceTableId" header="{{ 'SHIPPING_PRICE_TABLES.FORM.TABLE' | translate }}">
            <ng-template let-table="rowData" pTemplate="body">
                <app-simple-button *ngIf="table.hasPricetable" icon="check" type="confirm" [size]="36"
                                   (onClick)="editPricetable(table)"></app-simple-button>
                <app-simple-button *ngIf="!table.hasPricetable" icon="create" type="delete" [size]="36"
                                   (onClick)="editPricetable(table)"></app-simple-button>
            </ng-template>
        </p-column>
    </p-dataTable>
    <app-shipping-pricingtable *ngIf="displayDialog"
                               [priceTable]="priceTable"
                               [validationErrors]="validationErrors"
                               [tableInfo]="selectedTableInfo"
                               (close)="closeDialog()"
                               (submit)="submitShippingTable($event)">
    </app-shipping-pricingtable>
</div>
