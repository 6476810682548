<div class="list-content">
    <div class="table-header">
        <div class="title">{{ 'ADMIN_PANEL.USER_CONSENTS' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="users" [rowsPerPageOptions]="rowsPerPageOptions"
                 [rows]="chosenRowsPerPage"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords"
                 selectionMode="single" [(selection)]="selectedUser"
                 (onRowDblclick)="doOnRowSelect($event)"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 (onLazyLoad)="loadUserConsentsLazy($event)" [responsive]="true"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">
        <p-column header="{{ 'USER-DETAILS.FORM.ACTIONS_HEADER' | translate}}">
            <ng-template let-user="rowData" pTemplate="body">
                <div class="table-row-action-buttons min-width80">
                    <app-simple-button label="{{ 'TERMS_OF_USE.DIALOG.HEADER' | translate }}"
                                       [inlineLabel]="false" icon="search" type="main-action"
                                       (onClick)="getUserConsents(user.id)"></app-simple-button>
                </div>
            </ng-template>
        </p-column>
        <p-column field="login" header="{{ 'USER-DETAILS.FORM.LOGIN' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
        </p-column>
        <p-column field="firstName" header="{{ 'USER-DETAILS.FORM.FIRST_NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
        </p-column>
        <p-column field="lastName" header="{{ 'USER-DETAILS.FORM.LAST_NAME' | translate }}"
                  [sortable]="true" [filter]="showFilters" filterMatchMode="contains">
        </p-column>
        <p-column field="roleName" [header]="'USER-DETAILS.FORM.ROLE' | translate"
                  [sortable]="false" [filter]="showFilters"
                  [filterValues]="filterRoles | async">
            <ng-template let-user="rowData" pTemplate="body">
                {{ getRoleTranslationKey(user.roleName) | translate }}
            </ng-template>
        </p-column>
        <p-column field="subsystemIds" [header]="'USER-DETAILS.FORM.SUBSYSTEM' | translate" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains">

            <ng-template let-user="rowData" pTemplate="body">
                {{ getSubsystemsName(user.subsystemId) }}
            </ng-template>
            <ng-template pTemplate="filter">
                <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                    <p-multiSelect [options]="subsystems" (onChange)="loadUserConsentsLazy(lastLoadEvent)"
                                   defaultLabel="{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}" appendTo="body"
                                   [displaySelectedLabel]="false" [(ngModel)]="selectedSubsystemIds"></p-multiSelect>
                </div>
            </ng-template>
        </p-column>
        <p-column field="lastAcceptedTermsOfUseVersion"
                  [header]="'USER-DETAILS.FORM.LAST_ACCEPTED_TERMS_OF_USE_VERSION' | translate"></p-column>
        <p-column field="lastConsentTime" [header]="'USER-DETAILS.FORM.LAST_CONSENT_TIME' | translate">
            <ng-template let-user="rowData" pTemplate="body">
                {{ user.lastConsentTime | momentCurrentLang | amDateFormat: 'L LT' }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-user-consents-dialog *ngIf="showDialog" (onCancel)="showDialog = false" [userConsents]="userConsents"
                          dialogId="userConsentsDialog"></app-user-consents-dialog>
