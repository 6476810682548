import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {DataTable} from 'primeng/datatable';
import {BaseAddonProfitMarginComponent} from '../../../common/addon-profit-margin/base-addon-profit-margin.component';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {SubsystemGroupAddonProfitMargin} from './subsystem-group-addon-profit-margin';
import {SubsystemGroupAddonProfitMarginService} from './subsystem-group-addon-profit-margin.service';

@Component({
    selector: 'app-subsystem-group-addon-profit-margin',
    templateUrl: '../../../common/addon-profit-margin/base-addon-profit-margin.component.html',
    styleUrls: ['../../../common/addon-profit-margin/base-addon-profit-margin.component.css'],
    providers: [SubsystemGroupAddonProfitMarginService, TranslatedSelectItemService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubsystemGroupAddonProfitMarginComponent
    extends BaseAddonProfitMarginComponent<SubsystemGroupAddonProfitMargin, SubsystemGroupAddonProfitMarginService> implements OnInit {

    @ViewChild('dt') dt: DataTable;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, SubsystemGroupAddonProfitMarginService);
    }

    getDatatable(): DataTable {
        return this.dt;
    }

    fillElementId(rowData): void {
        this.editedItem.subsystemGroupId = rowData.subsystemGroupId;
        if (!this.editedItem.subsystemGroupId && this.subsystemGroupId) {
            this.editedItem.subsystemGroupId = this.subsystemGroupId;
        }
    }

    getNewItem(): SubsystemGroupAddonProfitMargin {
        return new SubsystemGroupAddonProfitMargin();
    }
}
