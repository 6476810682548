export class HandleParams {
    public static readonly Width = 20;
    public static readonly Length = 100;
    public static readonly FoundationWidth = 30;
    public static readonly FoundationLength = 66;
    public static readonly Proportion = 0.1;
    public static readonly FoundationProportion = 0.5;
    public static readonly Attributes = {
        stroke: '#010101',
        fill: '#FFFFFF'
    };
}
