import {Grill} from "./Grill";
import {GrillType} from "./GrillType";

export class GenericGrillGrid extends Grill {
    winglessMode: boolean;

    constructor(type: GrillType) {
        super(type);
    }
}
