import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {DocumentTemplateType} from '../../offer/print-dialog/document-template-type.enum';
import {DocumentType} from '../../offer/print-dialog/DocumentType';

@JsonDeserializable
export class DocumentTemplate implements CrudItem {
    id: number;
    name: string;
    documentType: DocumentType;
    documentTemplateTypes: DocumentTemplateType[];
    languages: (keyof MultilanguageFieldInterface)[];
    active: boolean;
    suppliersIds: number[];

    constructor() {
        this.id = undefined;
        this.name = undefined;
        this.documentType = undefined;
        this.documentTemplateTypes = [];
        this.languages = [];
        this.active = true;
        this.suppliersIds = [];
    }

    static fromJSON(jsonObject: any): DocumentTemplate {
        let template = new DocumentTemplate();
        template.id = jsonObject.id;
        template.name = jsonObject.name;
        template.documentType = jsonObject.documentType;
        template.documentTemplateTypes = jsonObject.documentTemplateTypes;
        template.languages = jsonObject.languages;
        template.active = jsonObject.active;
        template.suppliersIds = jsonObject.suppliersIds;

        return template;
    }
}
