import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SelectItem} from 'primeng/api/selectitem';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Permissions} from '../../../auth/permission.service';
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {Country} from '../../../common/enums/country';
import {ExchangeService} from '../../../common/exchange.service';
import {GrowlMessageController} from '../../../common/growl-message/growl-message-controller';
import {TranslatedSelectItemService} from '../../../common/service/translated-select-item.service';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {Currencies} from '../../../currencies';
import {ConfigSystemUpsellingSettingsService} from '../../admin-panel/configurable-addon-upselling-settings/config-system-upselling-settings.service';
import {IsLoggedService} from '../../login/islogged.service';
import {AddressService} from '../../subsystem/address.service';
import {Subsystem} from '../../subsystem/subsystem';
import {SubsystemValidator} from '../../subsystem/subsystem-validator';
import {SubsystemService} from '../../subsystem/subsystem.service';
import {TabularAddress} from '../../subsystem/tabularAddress';
import {User} from '../../user/user';
import {UserService} from '../../user/user.service';

@Component({
    selector: 'app-subsystem-settings',
    templateUrl: './subsystem-settings.component.html',
    styleUrls: ['../../shared-styles.css', '../settings.component.css'],
    providers: [SubsystemService, DataServiceHelper, UserService, TranslatedSelectItemService, ExchangeService, AddressService,
        ConfigSystemUpsellingSettingsService]
})
export class SubsystemSettingsComponent implements OnInit, OnDestroy {

    countries: Observable<SelectItem[]>;
    item: Subsystem;
    addresses: TabularAddress[];
    errorMessage: string;
    user: User;
    currencyOptions: Observable<SelectItem[]>;
    validationErrors = {};
    private loggedInSubscription: Subscription;

    private saveInProgress = false;
    private subsystemValidator: SubsystemValidator;

    currentExchangeRateForDefaultCurrency: number;
    correspondenceAddress: TabularAddress;
    deliveryAddress: TabularAddress;

    addressSelectionDialogType: 'none' | 'correspondence' | 'delivery' | 'modify' = 'none';
    displayEnableUpsellingCheckbox = false;

    constructor(private permissions: Permissions,
                private subsystemService: SubsystemService,
                private userService: UserService,
                private translatedSelectItemService: TranslatedSelectItemService,
                private growlMessageController: GrowlMessageController,
                private isLoggedService: IsLoggedService,
                private changeDetector: ChangeDetectorRef,
                private errors: CommonErrorHandler,
                private exchangeService: ExchangeService,
                private upsellingSettingsService: ConfigSystemUpsellingSettingsService,
                private addressService: AddressService) {
        this.item = new Subsystem();
        this.currencyOptions = this.translatedSelectItemService.buildUnsortedDropdown(Currencies, '', undefined);
        this.subsystemValidator = new SubsystemValidator(this.subsystemService);
    }

    ngOnInit() {
        this.loggedInSubscription = this.isLoggedService.item$.subscribe(
            () => {
                this.getCurrentUser();
            });

        this.countries = this.translatedSelectItemService.buildSortedDropdown(Country, 'COUNTRIES.', undefined);
        if (!this.isPermitted({roles: ['ROLE_SPRZEDAWCA', 'ROLE_HANDLOWIEC']})) {
            this.upsellingSettingsService.getItems(undefined, undefined, {}, 'id', 1)
                .subscribe(settings => this.displayEnableUpsellingCheckbox = settings.totalRecords > 0);
        }
    }

    ngOnDestroy() {
        this.loggedInSubscription.unsubscribe();
    }

    getCurrentUser() {
        this.userService.getUserProfile().subscribe({
            next: data => {
                this.user = data;
                this.getItemWithImage();
            },
            error: error => {
                this.errors.handle(error);
            },
            complete: () => {
                console.debug('CurrentProfile` completed!');
            }
        });
    }

    getItemWithImage() {
        forkJoin({
            item: this.subsystemService.getItem(this.user.subsystemId),
            addresses: this.subsystemService.getAddresses(this.user.subsystemId),
            exchangeRates: this.exchangeService.initializeExchangeRates(),
        }).subscribe({
            next: data => {
                this.item = data.item;
                this.addresses = data.addresses;
                this.exchangeService.storeExchangeRates(data.exchangeRates);
                if (this.item.defaultCurrency) {
                    this.currentExchangeRateForDefaultCurrency = this.exchangeService.getCurrentRatio(this.item.defaultCurrency);
                }
                this.correspondenceAddress = this.addresses.find(address => address.id === this.item.primaryCorrespondenceAddressId);
                this.deliveryAddress = this.addresses.find(address => address.id === this.item.primaryDeliveryAddressId);
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }

    isPermitted(requiredPermission) {
        return this.permissions.isPermitted(requiredPermission);
    }

    validateForm(): Observable<ValidationErrors> {
        ValidationErrorsHelper.clearAllErrorsExcluding(this.validationErrors);
        return this.subsystemValidator.validateAddress(this.item, this.addresses, this.correspondenceAddress, this.deliveryAddress)
            .pipe(tap(validationErrors => {
                if (!this.item.defaultCurrency) {
                    validationErrors['defaultCurrency'] = 'error.subsystemDto.defaultCurrency.not_empty';
                }
                if (this.item.manualExchangeRate && this.item.manualExchangeRate <= 0) {
                    validationErrors['manualExchangeRate'] = 'error.subsystemDto.manualExchangeRate.below_min';
                }
                return validationErrors;
            }));
    }

    saveChanges() {
        this.validateForm().subscribe(validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
                if (this.isSaveInProgress()) {
                    return;
                }

                this.setSaveInProgress(true);
                this.subsystemService.save(this.item, this.addresses, this.correspondenceAddress, this.deliveryAddress)
                    .subscribe({
                        next: () => {
                            this.showSuccessMessage();
                            this.changeDetector.markForCheck();
                            this.setSaveInProgress(false);
                        },
                        error: err => {
                            this.validationErrors = this.errors.handle(err);
                            this.changeDetector.markForCheck();
                            this.setSaveInProgress(false);
                        }
                    });
            }
        });
    }

    showSuccessMessage() {
        this.growlMessageController.info('SUBSYSTEM-DETAILS.SUBSYSTEM_UPDATED');
    }

    public setSaveInProgress(saveInProgress: boolean): void {
        this.saveInProgress = saveInProgress;
    }

    public isSaveInProgress(): boolean {
        return this.saveInProgress;
    }

    public getFullManagerName(): string {
        return this.item.clientManager.firstName + ' ' + this.item.clientManager.lastName;
    }

    onDefaultCurrencyChange(currency: Currencies) {
        this.item.defaultCurrency = currency;
        this.currentExchangeRateForDefaultCurrency = this.exchangeService.getCurrentRatio(currency);
        this.item.manualExchangeRate = undefined;
    }

    handleAddressesChange(addresses: TabularAddress[]): void {
        this.addresses = addresses;
        if (this.item.primaryCorrespondenceAddressId != undefined) {
            this.correspondenceAddress = addresses.find(address => address.id === this.item.primaryCorrespondenceAddressId);
            if (this.correspondenceAddress == undefined) {
                this.item.primaryCorrespondenceAddressId = undefined;
            }
        }
        if (this.item.primaryDeliveryAddressId != undefined) {
            this.deliveryAddress = addresses.find(address => address.id === this.item.primaryDeliveryAddressId);
            if (this.deliveryAddress == undefined) {
                this.item.primaryDeliveryAddressId = undefined;
            }
        }
    }

    handleAddressSelected(address: TabularAddress): void {
        switch (this.addressSelectionDialogType) {
            case 'correspondence':
                delete this.validationErrors['correspondenceAddress'];
                this.correspondenceAddress = address;
                this.item.primaryCorrespondenceAddressId = address != undefined ? address.id : undefined;
                break;
            case 'delivery':
                delete this.validationErrors['deliveryAddress'];
                this.deliveryAddress = address;
                this.item.primaryDeliveryAddressId = address != undefined ? address.id : undefined;
                break;
        }
        if (this.addressSelectionDialogType === 'modify') {
            this.saveAddresses();
        } else {
            this.saveAddressChanges();
        }
    }

    handleAddressSelectionVisibilityChange(visible: boolean): void {
        if (!visible) {
            this.addressSelectionDialogType = 'none';
        }
    }

    saveAddressChanges() {
        this.validateForm().subscribe(validationErrors => {
            this.validationErrors = Object.assign({}, this.validationErrors, validationErrors);
            if (!ValidationErrorsHelper.validationErrorsPresent(this.validationErrors)) {
                if (this.isSaveInProgress()) {
                    return;
                }

                this.setSaveInProgress(true);
                this.saveAddresses();
            }
        });
    }

    private saveAddresses(): void {
        this.subsystemService.saveAddresses(this.addresses, this.correspondenceAddress, this.deliveryAddress)
            .subscribe({
                next: () => {
                    this.showSuccessMessage();
                    this.changeDetector.markForCheck();
                    this.setSaveInProgress(false);
                },
                error: err => {
                    this.validationErrors = this.errors.handle(err);
                    this.changeDetector.markForCheck();
                    this.setSaveInProgress(false);
                }
            });
    }

    showAddressSelectionDialog(addressSelectionDialogType: 'none' | 'correspondence' | 'delivery' | 'modify'): void {
        this.addressService.getUsableAddresses(this.item.id).subscribe(addresses => {
            this.addressSelectionDialogType = addressSelectionDialogType;
            this.addresses = addresses;

            switch (this.addressSelectionDialogType) {
                case 'correspondence':
                    this.correspondenceAddress = this.addresses.find(address => address.id === this.item.primaryCorrespondenceAddressId);
                    break;
                case 'delivery':
                    this.deliveryAddress = this.addresses.find(address => address.id === this.item.primaryDeliveryAddressId);
                    break;
            }
            this.changeDetector.markForCheck();
        });
    }
}
