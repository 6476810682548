import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output} from '@angular/core';
import {EMPTY, from, Observable} from 'rxjs';
import {catchError, finalize, mergeAll} from 'rxjs/operators';
import {Permissions} from '../../../../auth/permission.service';
import {BlockUiController} from '../../../../block-ui/block-ui-controller';
import {CommonErrorHandler} from '../../../../common/CommonErrorHandler';
import {OfferStatus} from '../../../../common/enums/OfferStatus';
import {OnceFlag} from '../../../../shared/once-flag';
import {Offer} from '../../offer';
import {OffersService} from '../../offer-service';

@Component({
    selector: 'app-bulk-status-change-dialog',
    templateUrl: './bulk-status-change-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkStatusChangeDialogComponent {

    readonly BLOCK_UI_NAME = 'BulkStatusChange';

    @Input()
    selectedItems: Offer[];

    @Output()
    readonly onHide = new EventEmitter<boolean>();

    readonly dialogHideHelper = new OnceFlag();

    constructor(private offerService: OffersService,
                private permissions: Permissions,
                private errors: CommonErrorHandler,
                public blockUiController: BlockUiController,
                private changeDetector: ChangeDetectorRef) {
    }

    get allSelectedOffersHaveSameStatus(): boolean {
        return this.targetStatus != undefined;
    }

    get allSelectedOffersHaveValidPricing(): boolean {
        return this.selectedItems.every(offer => !offer.pricingOutdated);
    }

    get targetStatus(): OfferStatus {
        const statuses = new Set<OfferStatus>();
        this.selectedItems.forEach(offer => statuses.add(this.getTargetStatusForOffer(offer)));
        return statuses.size === 1 ? Array.from(statuses.keys())[0] : undefined;
    }

    private getTargetStatusForOffer(offer: Offer): OfferStatus {
        switch (offer.status) {
            case OfferStatus.NEW_OFFER:
            case OfferStatus.PARTNER_NEW_OFFER:
            case OfferStatus.TO_REVISE:
                return OfferStatus.TO_SEND;
            case OfferStatus.TO_SEND:
            case OfferStatus.REVIEWED:
            case OfferStatus.VERIFICATION_REJECTED:
                return OfferStatus.TO_VERIFY;
            case OfferStatus.VERIFICATION_APPROVED:
            case OfferStatus.TO_VERIFY:
                return OfferStatus.VERIFIED;
            case OfferStatus.TO_REVIEW:
                return OfferStatus.VERIFICATION_FOR_APPROVAL;
            case OfferStatus.VERIFICATION_FOR_APPROVAL:
                break; // two transitions, don't handle
            case OfferStatus.VERIFIED:
            case OfferStatus.WAITING_FOR_PAYMENT:
            case OfferStatus.SUSPENDED:
            case OfferStatus.PAID:
            case OfferStatus.CORRECTION:
            case OfferStatus.CORRECTED:
            case OfferStatus.PARTIALLY_DISPATCHED:
            case OfferStatus.DISPATCHED:
                break; // order status, don't handle
            case OfferStatus.SENT_TO_PARTNER:
                break; // no transition possible
            default:
                break;
        }
        return undefined;
    }

    get isAllowedBulkTransitionStatus(): boolean {
        switch (this.targetStatus) {
            case OfferStatus.TO_SEND:
                return this.permissions.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR', 'ROLE_SPRZEDAWCA']});
            case OfferStatus.TO_VERIFY:
                return this.permissions.isPermitted({roles: ['ROLE_OPERATOR']});
            case OfferStatus.VERIFIED:
            case OfferStatus.VERIFICATION_FOR_APPROVAL:
                return this.permissions.isPermitted({roles: ['ROLE_OPIEKUN', 'ROLE_KOORDYNATOR']});
            default:
                break;
        }
        return false;
    }

    get submitLabel() {
        switch (this.targetStatus) {
            case OfferStatus.TO_SEND:
                return 'OFFER.ACTIONS.TOOLTIPS.ACCEPT_NEW_OFFER';
            case OfferStatus.TO_VERIFY:
                return 'OFFER.ACTIONS.DIALOGS.BUTTON_SEND_TO_VERIFY';
            case OfferStatus.VERIFIED:
                return 'OFFER.ACTIONS.TOOLTIPS.ACCEPT_VERIFIED_OFFER';
            case OfferStatus.VERIFICATION_FOR_APPROVAL:
                return 'OFFER.ACTIONS.TOOLTIPS.SEND_BACK_TO_OPERATOR';
            default:
                break;
        }
        return '';
    }

    hide(refreshList: boolean): void {
        this.dialogHideHelper.call(() => this.onHide.emit(refreshList));
    }

    submit(): void {
        this.blockUiController.block(this.BLOCK_UI_NAME);
        this.changeDetector.markForCheck();
        from(this.selectedItems.map(offer => this.getTransitionAction(offer)
            .pipe(catchError(error => {
                this.errors.handle(error);
                return EMPTY;
            }))))
            .pipe(
                mergeAll(5), // limit to 5 max concurrent requests instead of forkJoin all of them
                finalize(() => {
                    this.blockUiController.unblock(this.BLOCK_UI_NAME);
                    this.changeDetector.markForCheck();
                }))
            .subscribe({complete: () => this.hide(true)});
    }

    private getTransitionAction(offer: Offer): Observable<void> {
        switch (offer.status) {
            case OfferStatus.NEW_OFFER:
            case OfferStatus.PARTNER_NEW_OFFER:
            case OfferStatus.TO_REVISE:
                return this.offerService.acceptNewOffer(offer.id);
            case OfferStatus.TO_SEND:
            case OfferStatus.REVIEWED:
            case OfferStatus.VERIFICATION_REJECTED:
                return this.offerService.sendTheOfferToVerify(offer.id);
            case OfferStatus.VERIFICATION_APPROVED:
            case OfferStatus.TO_VERIFY:
                return this.offerService.acceptOffer(offer.id);
            case OfferStatus.TO_REVIEW:
                return this.offerService.sendTheOfferBackToOperator(offer.id);
            default:
                throw new Error(`Unsupported offer status ${offer.status}`);
        }
    }
}
