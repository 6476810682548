<div class="list-content">
    <div class="table-header">
        <div class="title">{{'ADMIN_PANEL.ERROR_BROWSER.TITLE' | translate}}
        </div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button label="{{ 'ADMIN_PANEL.ERROR_BROWSER.DISMISS_ALL_ERRORS' | translate }}"
                                   icon="import_export" type="action"
                                   (onClick)="dismissAllMessages()"></app-simple-button>
                <app-search class="message-search" [component]="'error-search'" (searched)="handleMessagesSearch($event)"></app-search>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="messages" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [seqNum]="1" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 [responsive]="true"
                 (onRowDblclick)="doOnRowSelect($event)" (onLazyLoad)="loadItemsLazy($event)"
                 emptyMessage="{{'ADMIN_PANEL.ERROR_BROWSER.NO_DATA_DETAILS' | translate}}" id="usersTable"
                 [expandableRows]="true" class="fixed-table"
                 sortField="eventOccurred" [sortOrder]="-1"
                 [rowStyleClass]="lookupRowStyleClass">
        <p-column [style]="{'width':'60px'}">
            <ng-template let-message="rowData" pTemplate="body">
                    <i class="material-icons" *ngIf="message.stacktrace && !dt.isRowExpanded(message)"
                       (click)="dt.toggleRow(message,$event)">expand_more</i>
                    <i class="material-icons" *ngIf="message.stacktrace && dt.isRowExpanded(message)"
                       (click)="dt.toggleRow(message,$event)">expand_less</i>
            </ng-template>
        </p-column>
        <p-column field="severity" [style]="{'width':'110px'}"
                  header="{{'ADMIN_PANEL.ERROR_BROWSER.SEVERITY' | translate}}" [filter]="showFilters"
                  [filterValues]="filterServerity | async">
            <ng-template let-message="rowData" pTemplate="body">
                    {{('ADMIN_PANEL.ERROR_BROWSER.SEVERITY_FILTER.'+message.severity)| translate}}
            </ng-template>
        </p-column>
        <p-column field="version" [style]="{'width':'190px'}"
                  header="{{ 'ADMIN_PANEL.ERROR_BROWSER.VERSION' | translate }}" [filter]="showFilters">
            <ng-template let-message="rowData" pTemplate="body">
                <ng-container *ngIf="message.backendVersion != undefined">
                    <div class="message-summary">Backend</div>
                    <div class="message-details">{{ message.backendVersion }} {{ message.backendCommit }}</div>
                </ng-container>
                <ng-container *ngIf="message.frontendVersion != undefined">
                    <div class="message-summary">Frontend</div>
                    <div class="message-details">{{ message.frontendVersion }} {{ message.frontendCommit }}</div>
                </ng-container>
            </ng-template>
        </p-column>
        <p-column field="errorCategory" [style]="{'width':'190px'}"
                  header="{{'ADMIN_PANEL.ERROR_BROWSER.ERROR_CATEGORY.TITLE' | translate}}" [filter]="showFilters"
                  [filterValues]="filterCategory | async">
            <ng-template let-message="rowData" pTemplate="body">
                <div class="message-content">{{ getErrorCategoryTranslationKey(message.errorCategory) | translate }}</div>
            </ng-template>
        </p-column>
        <p-column field="eventOccurred" [style]="{'width':'255px'}"
                  header="{{ 'ADMIN_PANEL.ERROR_BROWSER.OCCURRED' | translate }}"
                  [sortable]="true" [filter]="showFilters" styleClass="datatable-date-range-column">
            <ng-template pTemplate="filter">
                <div app-date-range-filter [class.p-column-filter-invisible]="!showFilters"></div>
            </ng-template>
            <ng-template let-message="rowData" pTemplate="body">
                {{ message.eventOccurred | momentCurrentLang | amDateFormat: 'L LTS' }}
            </ng-template>
        </p-column>
        <p-column field="userLogin" [style]="{'width':'120px'}"
                  header="{{ 'ADMIN_PANEL.ERROR_BROWSER.USER' | translate }}" [filter]="showFilters" filterField="login">
            <ng-template let-message="rowData" pTemplate="body">
                <div class="message-details">{{ message.userLogin }}</div>
            </ng-template>
        </p-column>
        <p-column field="subsystem" [style]="{'width':'150px'}"
                  header="{{ 'ADMIN_PANEL.ERROR_BROWSER.SUBSYSTEM' | translate }}" [filter]="showFilters">
            <ng-template pTemplate="filter">
                <div [class.p-column-filter-invisible]="!showFilters" class="app-filter-multi-select">
                    <p-multiSelect [options]="availableSubsystems"
                                   defaultLabel="{{ 'USER-DETAILS.FORM.SUBSYSTEM' | translate }}" appendTo="body"
                                   [displaySelectedLabel]="false" [ngModel]="selectedSubsystems"
                                   (ngModelChange)="handleSubsystemFilterChange($event)"></p-multiSelect>
                </div>
            </ng-template>
            <ng-template let-message="rowData" pTemplate="body">
                <div class="message-details">{{ message.subsystem }}</div>
            </ng-template>
        </p-column>
        <p-column field="details" header="{{'ADMIN_PANEL.ERROR_BROWSER.DETAILS' | translate}}">
            <ng-template let-message="rowData" pTemplate="body">
                <div *ngIf="message.summary">
                    <div class="message-summary">{{ message.summary | translate}}</div>
                </div>
                <div *ngIf="message.details">
                    <div class="message-summary">{{ 'ADMIN_PANEL.ERROR_BROWSER.DETAILS' | translate }}</div>
                    <div class="message-content">{{ message.details }}</div>
                </div>
                <div *ngIf="message.errorCode">
                    <div class="message-summary">{{ 'ADMIN_PANEL.ERROR_BROWSER.ERROR_CODE' | translate }}</div>
                    <div class="message-content">{{ message.errorCode }}</div>
                </div>
                <div *ngIf="message.requestUrl">
                    <div class="message-summary">{{ 'ADMIN_PANEL.ERROR_BROWSER.REQUEST_URL' | translate }}</div>
                    <div class="message-content">{{ message.requestUrl }}</div>
                </div>
                <div *ngIf="message.offerNumber">
                    <div class="message-summary">{{ 'ADMIN_PANEL.ERROR_BROWSER.OFFER_NUMBER' | translate }}</div>
                    <div class="message-content">{{ message.offerNumber }}</div>
                </div>
                <div *ngIf="message.errorContent">
                    <div class="message-summary">{{ 'ADMIN_PANEL.ERROR_BROWSER.ERROR_MESSAGE' | translate }}</div>
                    <div class="message-content">{{ message.errorContent }}</div>
                </div>
                <div *ngIf="message.affectedElements">
                    <span class="message-summary">{{ 'ADMIN_PANEL.ERROR_BROWSER.AFFECTED_ELEMENTS' | translate }}</span>
                    <div class="message-content">
                        <div *ngFor="let affectedElement of message.affectedElements; let i = index">
                            <div *ngIf="affectedElement.translationKey" class="affected-element">
                                {{ (affectedElement.translationKey | translate) +
                                addCommaIfNeeded(i, message.affectedElements.length)}}
                            </div>
                            <div *ngIf="affectedElement.translatedKeys" class="affected-element">
                                <ng-container *ngIf="affectedElement.showAllLanguages">
                                    PL: {{ affectedElement.translatedKeys['pl'] || '---' }}<br/>
                                    EN: {{ affectedElement.translatedKeys['en'] || '---' }}<br/>
                                    NO: {{ affectedElement.translatedKeys['no'] || '---' }}<br/>
                                    SE: {{ affectedElement.translatedKeys['se'] || '---' }}<br/>
                                    DA: {{ affectedElement.translatedKeys['da'] || '---' }}
                                    {{ addCommaIfNeeded(i, message.affectedElements.length) }}<br/>
                                </ng-container>
                                <ng-container *ngIf="!affectedElement.showAllLanguages">
                                    {{ (affectedElement.translatedKeys | multilanguageTranslate) +
                                    addCommaIfNeeded(i, message.affectedElements.length) }}
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-column>
        <p-column field="actions" [style]="{'width':'120px'}"
                  header="{{'ADMIN_PANEL.ERROR_BROWSER.ACTIONS' | translate}}">
            <ng-template let-message="rowData" pTemplate="body">
                    <i class="material-icons" title="{{'ADMIN_PANEL.ERROR_BROWSER.REMOVE' | translate}}"
                       (click)="dismissMessage(message)">delete</i>
            </ng-template>
        </p-column>
        <ng-template let-message pTemplate="rowexpansion">
            <div>
                <pre class="message-details" [innerText]="message.stacktrace"></pre>
            </div>
        </ng-template>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>
