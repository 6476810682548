<div class="new-form-600">
    <ng-container *ngIf="allColorOptions != undefined">
        <div class="new-form-row">
            <app-radio-button inputId="selectedColorSide"
                              label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.COLOR_SIDE' | translate }}"
                              [options]="translatedColorSideOptions | async" [required]="true" [ngModel]="selectedColorSide"
                              (ngModelChange)="handleColorSideChange($event)"></app-radio-button>
        </div>
        <div *ngIf="colorOptions.length > 0" class="new-form-row">
            <app-select inputId="externalColorId"
                        label="{{ 'WEBSHOP_RECOMMENDED_CONFIGURATION.FORM.COLOR' | translate }}"
                        [(validationMessageKey)]="validationErrors['productConfiguration.externalColorId']"
                        [options]="colorOptions" [required]="true" [ngModel]="item.productConfiguration.externalColorId"
                        (ngModelChange)="handleColorChange($event)"></app-select>
        </div>
    </ng-container>
</div>
