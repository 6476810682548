import {JsonDeserializable} from '../../../common/crud-common/crudItem';
import {MultilanguageField} from '../../../supportedLanguages';

@JsonDeserializable
export class WebshopSale {
    id: number;
    windowSystemId: number;
    windowSystemName: MultilanguageField;
    rabate: number;
    validFrom: Date = new Date();
    validTo: Date = new Date();

    constructor() {
        this.windowSystemName = new MultilanguageField();
    }

    static fromJSON(jsonObject: any): WebshopSale {
        const webshopSale = new WebshopSale();
        webshopSale.id = jsonObject.id;
        webshopSale.rabate = jsonObject.rabate;
        webshopSale.windowSystemId = jsonObject.windowSystemId;
        if (jsonObject.windowSystemName != undefined) {
            webshopSale.windowSystemName = MultilanguageField.fromJSON(jsonObject.windowSystemName);
        }
        webshopSale.validFrom = new Date(jsonObject.validFrom);
        webshopSale.validTo = new Date(jsonObject.validTo);
        return webshopSale;
    }
}
