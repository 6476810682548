import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {DataTable} from 'primeng/datatable';
import {finalize} from "rxjs/operators";
import * as _ from 'underscore';
import {Permissions} from "../../../auth/permission.service";
import {CommonErrorHandler} from '../../../common/CommonErrorHandler';
import {ComponentWithUserConfigAndPaginator} from "../../../common/crud-common/paginable.component";
import {DataServiceHelper} from "../../../common/dataServiceHelper";
import {User} from '../../user/user';
import {UserService} from '../../user/user.service';
import {UserGroup} from "../user-group";
import {UserGroupService} from "../user-group.service";

@Component({
    selector: 'app-list-user-groups',
    templateUrl: './list-user-groups.component.html',
    styleUrls: ['./list-user-groups.component.css', '../../shared-styles.css'],
    providers: [UserService, UserGroupService, DataServiceHelper],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListUserGroupsComponent extends ComponentWithUserConfigAndPaginator implements OnInit {

    userGroups: UserGroup[];
    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    displayDialog: boolean;
    selectedUserGroup: UserGroup;
    newUserGroup: boolean;
    userGroup: UserGroup = new UserGroup();
    usersList: (User & { selected?: boolean })[];
    displayYesNoDialog = false;
    lastEvent;
    @ViewChild('dt') datatable;

    constructor(public translate: TranslateService,
                public userService: UserService,
                public userGroupService: UserGroupService,
                public route: ActivatedRoute,
                public router: Router,
                public permissions: Permissions,
                private errors: CommonErrorHandler,
                injector: Injector,
                changeDetector: ChangeDetectorRef) {
        super(injector, changeDetector, 'ListUserGroupsComponent', false);
    }

    getDatatable(): DataTable {
        return this.datatable;
    }

    showDialogToAdd() {
        this.validationErrors = {};
        this.newUserGroup = true;
        this.userGroup = new UserGroup();
        this.getUsersList();
    }

    button_delete() {
        this.userGroup = this.selectedUserGroup;
        this.showYesNoDialog(true);
    }

    confirmed_delete() {
        this.delete(this.userGroup);
    }

    cancel() {
        this.newUserGroup = false;
        this.setDisplayDialog(false);
        this.userGroup = new UserGroup();
        this.restoreSelectionAndResetHotkeysAfterCancel(this.selectedUserGroup);
    }

    onRowSelect(event) {
        this.validationErrors = {};
        this.newUserGroup = false;
        this.userGroup = this.cloneUserGroup(event.data);
        this.getUsersList();
        this.keepSelectedItemIndex(event);
    }

    cloneUserGroup(ug: UserGroup): UserGroup {
        let userGroup = _.clone(ug) as UserGroup;
        userGroup.linkedUsers = [...ug.linkedUsers];
        return userGroup;
    }

    loadUserGroupsLazy(event: LazyLoadEvent): void {
        super.loadItemsLazy(event);
        this.lastEvent = event;
        this.userGroupService.getUserGroups(event.first, event.rows, event.filters, event.sortField, event.sortOrder)
            .pipe(finalize(() => this.hideDataLoadingIndicator()))
            .subscribe({
                next: data => {
                    console.info('UserGroupListComponent `getPage` success:');
                    this.totalRecords = data.totalRecords;
                    this.userGroups = data.data;
                    this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                    this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                    for (let i = 0; i < this.userGroups.length; i++) {
                        this.userGroups[i].linkedUsers.sort();
                    }
                    this.selectedUserGroup = this.restoreSelectionAfterLoad(this.selectedUserGroup, this.userGroups, event);
                },
                error: error => {
                    console.error('UserGroupListComponent `getPage` error:', error);
                    this.errors.handle(error);
                },
                complete: () => {
                    console.info('UserGroupListComponent `getPage` completed!');
                    this.changeDetector.markForCheck();
                }
            });
    }

    getUsersList(): void {
        this.userService.getUsers(undefined, undefined, {}, undefined, undefined).subscribe({
            next: data => {
                this.usersList = data.data;
                if (!this.newUserGroup) {
                    let userLogins = [];
                    for (let i = 0; i < this.userGroup.linkedUsers.length; i++) {
                        userLogins.push(this.userGroup.linkedUsers[i].login);
                    }
                    for (let i = 0; i < this.usersList.length; i++) {
                        if (userLogins.indexOf(this.usersList[i].login) !== -1) {
                            this.usersList[i].selected = true;
                        }
                    }
                }
                console.log("getUsersList: " + this.usersList.length);
            },
            error: error => {
                console.error('List `getList` error:', error);
                this.errors.handle(error);
            },
            complete: () => {
                console.info('List `getList` completed!');
                this.setDisplayDialog(true);
            }
        });
    }

    submit(): void {
        if (this.newUserGroup) {
            this.add();
        } else {
            this.edit();
        }
    }

    add(): void {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        this.userGroup.linkedUsers = this.usersList.filter(user => user.selected);
        this.userGroupService.add(this.userGroup)
            .pipe(finalize(() => this.setSaveInProgress(false)))
            .subscribe({
                next: () => {
                    this.cleanUpAndReload();
                    this.showSuccessMessage();
                },
                error: errorMessage => {
                    this.handleErrorResponse(errorMessage);
                }
            });
    }

    edit(): void {
        if (this.isSaveInProgress()) {
            return;
        }
        this.setSaveInProgress(true);
        this.userGroup.linkedUsers = this.usersList.filter(user => user.selected);
        this.userGroupService.edit(this.userGroup.id, this.userGroup)
            .pipe(finalize(() => this.setSaveInProgress(false)))
            .subscribe({
                next: () => {
                    this.cleanUpAndReload();
                    this.showSuccessMessage();
                },
                error: errorMessage => {
                    this.handleErrorResponse(errorMessage);
                }
            });
    }

    delete(userGroup: UserGroup): void {
        this.userGroupService.delete(userGroup)
            .subscribe({
                next: () => {
                    this.cleanUpAndReload();
                    this.growlMessageController.info('USER-GROUP.GROUP_REMOVED');
                },
                error: errorMessage => {
                    this.handleErrorResponse(errorMessage);
                }
            });
    }

    private handleErrorResponse(error: HttpErrorResponse | Error): void {
        this.validationErrors = this.errors.handle(error);
        this.changeDetector.markForCheck();
    }

    showSuccessMessage() {
        if (this.newUserGroup) {
            this.growlMessageController.info('USER-GROUP.GROUP_CREATED');
        } else {
            this.growlMessageController.info('USER-GROUP.GROUP_EDITED');
        }
    }

    showYesNoDialog(show: boolean): void {
        if (this.displayYesNoDialog !== show) {
            this.displayYesNoDialog = show;
            this.changeDetector.markForCheck();
        }
    }

    cleanUpAndReload() {
        this.loadUserGroupsLazy(this.lastEvent);
        this.newUserGroup = false;
        this.showYesNoDialog(false);
        this.setDisplayDialog(false);
    }

    private setDisplayDialog(display: boolean): void {
        if (this.displayDialog !== display) {
            this.displayDialog = display;
            this.changeDetector.markForCheck();
        }
    }
}
