<div class="new-form-600">
    <div class="new-form-row">
        <app-select inputId="graspGlazingPackage1select"
                    label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.ONE_GLASS_GLAZING' | translate }}"
                    [(validationMessageKey)]="validationErrors['graspGlazingPackageId1']"
                    [disabled]="readOnly" [options]="graspGlazingPackages1" [allowSelectingNone]="true"
                    [(ngModel)]="item.graspGlazingPackageId1"></app-select>
    </div>
    <div class="new-form-row">
        <app-select inputId="graspGlazingPackage2select"
                    label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.TWO_GLASS_GLAZING' | translate }}"
                    [(validationMessageKey)]="validationErrors['graspGlazingPackageId2']"
                    [disabled]="readOnly" [options]="graspGlazingPackages2" [allowSelectingNone]="true"
                    [(ngModel)]="item.graspGlazingPackageId2"></app-select>
    </div>
    <div class="new-form-row">
        <app-select inputId="graspGlazingPackage3select"
                    label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.THREE_GLASS_GLAZING' | translate }}"
                    [(validationMessageKey)]="validationErrors['graspGlazingPackageId3']"
                    [disabled]="readOnly" [options]="graspGlazingPackages3" [allowSelectingNone]="true"
                    [(ngModel)]="item.graspGlazingPackageId3"></app-select>
    </div>
    <div class="new-form-row">
        <app-select inputId="graspGlazingPackage4select"
                    label="{{ 'WEBSHOP_GLAZING_PACKAGE.FORM.FOUR_GLASS_GLAZING' | translate }}"
                    [(validationMessageKey)]="validationErrors['graspGlazingPackageId4']"
                    [disabled]="readOnly" [options]="graspGlazingPackages4" [allowSelectingNone]="true"
                    [(ngModel)]="item.graspGlazingPackageId4"></app-select>
    </div>
</div>
