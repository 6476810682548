import {MultilanguageField} from "../../../supportedLanguages";

export class Glass {
    id: number;
    name: MultilanguageField;
    shortcut: MultilanguageField;
    thickness: number;
    constructionThickness: number;
    laminated: boolean;
    active: boolean;
    esg: boolean;
    tmp: boolean;
    ornament: boolean;
    symbol: string;
    linkedSystemIds: number[];
    sortIndex: number;
    unitWeight: number;

    constructor() {
        this.id = undefined;
        this.name = new MultilanguageField();
        this.thickness = undefined;
        this.constructionThickness = undefined;
        this.active = true;
        this.esg = undefined;
        this.tmp = undefined;
        this.symbol = undefined;
        this.ornament = undefined;
        this.shortcut = new MultilanguageField();
        this.linkedSystemIds = [];
        this.sortIndex = 1;
        this.unitWeight = undefined;
    }
}
