import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {FilterMetadata} from 'primeng/api/filtermetadata';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CrudService} from '../../../common/crud-common/crud.service';
import {Listing} from '../../../common/crud-common/crudItemList';
import {CatalogChargeProfitMargin} from './catalog-charge-profit-margin';

@Injectable()
export class CatalogChargeProfitMarginService implements CrudService<CatalogChargeProfitMargin> {

    constructor(private http: HttpClient) {
    }

    getItems(offset: number, pageSize: number, filters: { [filterProperty: string]: FilterMetadata },
             sortColumn: string, sortOrder: number): Observable<Listing<CatalogChargeProfitMargin>> {
        return undefined;
    }

    getItem(itemId: number) {
        return undefined;
    }

    addItem(item: any) {
        return undefined;
    }

    editItem(itemId: number, item: any) {
        return undefined;
    }

    saveProfitMargins(entityId: number, entityType: string, profitMargins: CatalogChargeProfitMargin[]): Observable<void> {
        return this.http.post<void>(`catalogChargeProfitMargin/${entityType}/${entityId}`, profitMargins);
    }

    getProfitMargins(subsystemId: number, subsystemGroupId: number, entityType: string): Observable<CatalogChargeProfitMargin[]> {
        let params: { [param: string]: string; } = {};
        if (subsystemId != null) {
            params['subsystemId'] = '' + subsystemId;
        }
        if (subsystemGroupId != null) {
            params['subsystemGroupId'] = '' + subsystemGroupId;
        }
        return this.http.get<any[]>(`catalogChargeProfitMargin/${entityType}`, {params: params}).pipe(
            map(response => response.map(CatalogChargeProfitMargin.fromJSON)));
    }

    overrideGroupMembersMargins(entityId: number, profitMargins: CatalogChargeProfitMargin[]): Observable<void> {
        return this.http.post<void>(`catalogChargeProfitMargin/subsystemGroup/${entityId}/overrideGroupMembers`, profitMargins);
    }
}
