<div class="container-with-toolbar">
    <div id="toolbar">
        <div class="separator"></div>
        <app-toolbar-button buttonId="recalculateSimulation"
                            label="{{ 'SHIPPING_SIMULATION.SIDEBAR.RECALCULATE' | translate }}" icon="autorenew"
                            [disabled]="positions.length == 0 || shippingCalculationInProgress"
                            (onClick)="recalculateTransport()"></app-toolbar-button>

        <app-toolbar-button buttonId="recalculateSimulation2"
                            *ngIf="showDetails()"
                            label="{{ 'SHIPPING_SIMULATION.SIDEBAR.RECALCULATE' | translate }} (piętrowanie)"
                            icon="3d_rotation"
                            [disabled]="positions.length == 0 || shippingCalculationInProgress || !showDetails()"
                            (onClick)="recalculateTransport(true)"></app-toolbar-button>

        <div class="separator"></div>

        <app-toolbar-button *ngIf="shippingSimulation" buttonId="changeAddress"
                            label="{{ 'SHIPPING_SIMULATION.ACTIONS.TOOLTIPS.CHANGE_ADDRESS' | translate }}"
                            icon="bookmarks" [disabled]="shippingSimulation.calculationInProgress"
                            (onClick)="showDeliveryAddressSelectionDialog()"></app-toolbar-button>

        <app-toolbar-button *ngIf="shippingSimulation && !shippingSimulation.draft" buttonId="deleteSimulation"
                            label="{{ 'SHIPPING_SIMULATION.DETAILS.DELETE' | translate }}" icon="delete"
                            [disabled]="shippingSimulation.calculationInProgress"
                            (onClick)="deleteSimulation()"></app-toolbar-button>

        <app-toolbar-button *ngIf="selectedItems.length > 0" buttonId="removePositions"
                            label="{{ 'SHIPPING_SIMULATION.DETAILS.REMOVE_POSITIONS' | translate }}" icon="delete"
                            [disabled]="shippingSimulation.calculationInProgress"
                            (onClick)="deleteSelectedPositions()"></app-toolbar-button>

        <div class="spacer"></div>

        <app-toolbar-button *ngIf="shippingSimulation && shippingSimulation.draft" buttonId="saveSimulation"
                            label="{{ 'SHIPPING_SIMULATION.DETAILS.SAVE' | translate }}" icon="save"
                            (onClick)="save()"></app-toolbar-button>

        <app-toolbar-button buttonId="goBack"
                            icon="exit_to_app" label="{{ 'GENERAL.GO_BACK' | translate}}"
                            (onClick)="navigateBack()"></app-toolbar-button>
    </div>
    <div class="list-content">
        <div class="simulation-details-header" *ngIf="viewInitialized">
            <div class="simulation-details-header-main">
                <div class="table-header major-element">
                    <div class="title">
                        <span>{{ 'SHIPPING_SIMULATION.DETAILS.HEADER' | translate }}</span>
                        <span>{{ shippingSimulation.name }}</span>
                    </div>
                </div>
            </div>

            <div *ngIf="permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})"
                 class="simulation-details-header-truck-selection">
                <app-checkbox inputId="useBigTrucks"
                              label="{{ 'SHIPPING_SIMULATION.SIDEBAR.USE_BIG_TRUCKS' | translate }}"
                              [ngModel]="useBigTrucks"
                              (ngModelChange)="handleUseBigTrucksChange($event)"></app-checkbox>
            </div>

            <div class="simulation-details-header-secondary" *ngIf="viewInitialized">
                <ng-container *ngIf="!shippingSimulation.calculationInProgress">
                    <div class="header-detail-container">
                        <div class="header-detail-value">{{ formatSimulationPrice() }}</div>
                        <div class="header-detail-label">{{ 'SHIPPING_SIMULATION.DETAILS.ESTIMATED_PRICE' | translate
                            }}
                        </div>
                    </div>

                    <div class="header-detail-container">
                        <div class="header-detail-value">{{ shippingSimulation.slots != null ? shippingSimulation.slots
                            : '...' }}
                        </div>
                        <div class="header-detail-label">{{ 'SHIPPING_SIMULATION.DETAILS.ESTIMATED_QUANTITY' | translate
                            }}
                        </div>
                    </div>

                    <div *ngIf="showDetails()" class="header-detail-container">
                        <div class="header-detail-value">
                            {{ shippingSimulation.calculationResult ?
                            shippingSimulation.calculationResult.containers[currentRackIndex]?.rack.name[translateService.currentLang]
                            : '...' }}
                        </div>
                        <div class="header-detail-label">{{ 'SHIPPING_SIMULATION.DETAILS.RACK_TYPE' | translate }}</div>
                    </div>

                    <div *ngIf="showDetails()" class="header-detail-container">
                        <div class="header-detail-value">
                            {{ shippingSimulation.calculationResult ?
                            (('SHIPPING_VEHICLE_TYPE.' + shippingSimulation.calculationResult.containers[currentRackIndex]?.vehicleType) | translate) : '...' }}
                        </div>
                        <div class="header-detail-label">{{ 'SHIPPING_SIMULATION.DETAILS.VEHICLE_TYPE' | translate }}
                        </div>
                    </div>

                    <div *ngIf="showCalculationErrorOutsideOfGrowls(shippingSimulation.calculationFailedCode)"
                         class="shipping-details-header-customs-note">
                        {{ shippingSimulation.calculationFailedCode | translate }}
                    </div>
                    <div class="shipping-details-header-customs-note">{{ 'SHIPPING_SIMULATION.DETAILS.CUSTOMS_NOTE' |
                        translate }}
                    </div>
                    <div class="shipping-details-estimated-transport-cost-note">{{ 'SHIPPING_SIMULATION.DETAILS.ESTIMATED_TRANSPORT_COST_NOTE' |
                        translate }}
                    </div>
                </ng-container>

                <div *ngIf="shippingSimulation.calculationInProgress" class="details-header-status-status">
                    {{ 'SHIPPING_SIMULATION.DETAILS.RECALCULATE_IN_PROGRESS' | translate }}
                </div>
            </div>
        </div>

        <div *ngIf="showDetails()" class="drawer-container">
            <div #maxWidthDeterminant class="drawer-width-determinant">
                <div *ngIf="viewInitialized && shippingSimulation.calculationResult && !shippingCalculationInProgress"
                     class="drawer-container-content">
                    <div *ngFor="let container of shippingSimulation.calculationResult.containers; let i = index"
                         [ngStyle]="{'display': currentRackIndex === i ? 'block' : 'none' }">

                        <div class="new-form-row">
                            <app-shipping-container-drawer #containerDrawer [container]="container"
                                                           [floorIndex]="currentFloorIndex"
                                                           [scale]="getScale(container)"></app-shipping-container-drawer>
                        </div>
                    </div>
                </div>
            </div>

            <div class="drawer-footer"
                 *ngIf="viewInitialized && shippingSimulation.calculationResult && !shippingCalculationInProgress">
                <div class="dialog-footer-left">
                    <app-simple-button buttonId="previousRack" icon="chevron_left" type="action" [size]="28"
                                       (onClick)="previousRack()"></app-simple-button>

                    {{ 'SHIPPING_SIMULATION.DETAILS.RACK' | translate }} {{ currentRackIndex + 1 }} / {{
                    shippingSimulation.calculationResult.containers.length }}

                    <app-simple-button buttonId="nextRack" icon="chevron_right" type="action" [size]="28"
                                       (onClick)="nextRack()"></app-simple-button>
                    <ng-container
                            *ngIf="shippingSimulation.calculationResult.containers[currentRackIndex]?.floorPositions.length > 1">
                        <app-simple-button buttonId="previousFloor" icon="keyboard_arrow_down" type="action" [size]="28"
                                           (onClick)="previousFloor()"></app-simple-button>

                        {{ 'SHIPPING_SIMULATION.DETAILS.FLOOR' | translate }} {{ currentFloorIndex + 1 }} / {{
                        shippingSimulation.calculationResult.containers[currentRackIndex]?.floorPositions.length }}

                        <app-simple-button buttonId="nextFloor" icon="keyboard_arrow_up" type="action" [size]="28"
                                           (onClick)="nextFloor()"></app-simple-button>


                        ({{
                        shippingSimulation.calculationResult.containers[currentRackIndex]?.floorPositions[currentFloorIndex]
                        }} -
                        {{
                        currentFloorIndex <
                        shippingSimulation.calculationResult.containers[currentRackIndex]?.floorPositions.length-1 ?
                        shippingSimulation.calculationResult.containers[currentRackIndex]?.floorPositions[currentFloorIndex+1]
                        : (getTopItemHeight() + " / " +
                        shippingSimulation.calculationResult.containers[currentRackIndex]?.rack.usableHeight)}})
                    </ng-container>
                </div>
            </div>
        </div>

        <div class="datatable-container" *ngIf="viewInitialized">
            <p-dataTable #dt [value]="positions" id="positionsTable"
                         [rows]="chosenRowsPerPage" [rowsPerPageOptions]="limitedRowsPerPageOptions"
                         [paginator]="true" [seqNum]="1" [(first)]="chosenPageNumber"
                         selectionMode="single" [lazy]="true" [totalRecords]="totalRecords"
                         [responsive]="true" (onLazyLoad)="loadItemsLazy($event)"
                         [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate"
                         [(selection)]="selectedItem">

                <p-column [style]="{'vertical-align':'middle'}">
                    <ng-template pTemplate="header">
                        <app-tristate-checkbox [(ngModel)]="allSelectedState"
                                               (ngModelChange)="selectAllChange()"></app-tristate-checkbox>
                    </ng-template>

                    <ng-template let-position="rowData" pTemplate="body">
                      <span class="flex-row">
                          <app-checkbox [ngModel]="isSelectedItem(position)"
                                        (ngModelChange)="selectItem(position)"></app-checkbox>
                      </span>
                    </ng-template>
                </p-column>

                <!-- ACTIONS -->
                <p-column header="{{ 'SHIPPING_SIMULATION.DETAILS.TABLE.ACTIONS' | translate }}" [sortable]="false">
                    <ng-template let-position="rowData" pTemplate="body">
                        <div class="table-row-action-buttons">
                            <app-simple-button buttonId="add" icon="delete" type="main-action" [size]="36"
                                               label="{{'SHIPPING_SIMULATION.DETAILS.ACTIONS.TOOLTIPS.DELETE_POSITION' | translate}}" [inlineLabel]="false"
                                               [disabled]="shippingSimulation.calculationInProgress"
                                               (onClick)="deletePosition(position)"></app-simple-button>
                        </div>
                    </ng-template>
                </p-column>

                <p-column field="position.name" sortField="offerPosition.name" [sortable]="true"
                          header="{{ 'SHIPPING_SIMULATION.DETAILS.TABLE.POSITION' | translate }}">

                    <ng-template let-position="rowData" pTemplate="body">
                        {{ position.position.name[translateService.currentLang] }}
                    </ng-template>
                </p-column>

                <!-- POSITION DIMENSIONS -->
                <p-column field="position.dimensions" sortField="offerPosition.dimensions" [sortable]="true"
                          header="{{ 'SHIPPING_SIMULATION.DETAILS.TABLE.DIMENSIONS' | translate }}">

                    <ng-template let-position="rowData" pTemplate="body">
                        {{ position.position.dimensions }}
                    </ng-template>
                </p-column>

                <p-column field="offer.offerNumber" [sortable]="true"
                          header="{{ 'SHIPPING_SIMULATION.DETAILS.TABLE.OFFER_NUMBER' | translate }}">

                    <ng-template let-item="rowData" pTemplate="body">
                        <app-offer-number [offerNumber]="item.offer.offerNumber"
                                          [isOffer]="!item.offer.isOrder"></app-offer-number>
                    </ng-template>
                </p-column>

                <p-column field="position.quantity" sortField="offerPosition.quantity" [sortable]="true"
                          header="{{ 'SHIPPING_SIMULATION.DETAILS.TABLE.QUANTITY' | translate }}"></p-column>

                <p-column field="offer.status" header="{{ 'SHIPPING_SIMULATION.DETAILS.TABLE.STATUS' | translate }}">
                    <ng-template let-position="rowData" pTemplate="body">
                        <div [ngClass]="'status-color ' + position.offer.status">
                            {{ ('OFFER.STATUS.' + position.offer.status) | translate }}
                        </div>
                    </ng-template>
                </p-column>

                <p-column field="offer.clientName" sortField="offer.client.name" [sortable]="true"
                          header="{{ 'SHIPPING_SIMULATION.DETAILS.TABLE.CLIENT' | translate }}"></p-column>

                <ng-template pTemplate="paginatorLeft">
                    <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
                </ng-template>
            </p-dataTable>
        </div>

        <div *ngIf="showSaveSimulationDialog" id="saveSimulationDialog" class="dialog-window">
            <p-dialog [resizable]="false" [closable]="true" [closeOnEscape]="true" [visible]="true"
                      (onHide)="cancelSaveSimulationDialog()" [modal]="true" [focusOnShow]="false">
                <p-header>
                    <div class="p-dialog-title">
                        {{ 'SHIPPING_SIMULATION.DETAILS.SAVE_DIALOG.HEADER' | translate }}
                    </div>
                </p-header>

                <div>
                    <div class="new-form-400 scrolled-dialog">
                        <app-input-text inputId="simulationName"
                                        label="{{ 'SHIPPING_SIMULATION.DETAILS.SAVE_DIALOG.NAME' | translate }}"
                                        [required]="true" [(validationMessageKey)]="validationErrors['name']"
                                        [maxlength]="30" [(ngModel)]="newSimulationName"></app-input-text>
                    </div>
                </div>

                <p-footer>
                    <div class="p-dialog-buttonpane">
                        <app-simple-button buttonId="confirm"
                                           [label]="'GENERAL.SAVE' | translate"
                                           type="main-action" [size]="40"
                                           (onClick)="confirmSaveSimulation()"></app-simple-button>

                        <app-simple-button buttonId="cancel"
                                           [label]="'GENERAL.CANCEL' | translate"
                                           type="cancel" [size]="40"
                                           (onClick)="cancelSaveSimulationDialog()"></app-simple-button>
                    </div>
                </p-footer>
            </p-dialog>
        </div>

        <app-confirm-action-dialog *ngIf="showDeleteSimulationDialog" (onCancel)="cancelDeleteSimulation()"
                                   (onConfirmed)="confirmDeleteSimulation()"
                                   [question]="'GENERAL.DELETE_CONFIRMATION' | translate"
                                   [header]="'GENERAL.DIALOG.DELETING' | translate"></app-confirm-action-dialog>

        <app-subsystem-address-selection-dialog *ngIf="deliveryAddressSelectionDialogVisible"
                                                [addresses]="addresses"
                                                [(visible)]="deliveryAddressSelectionDialogVisible"
                                                [selectedAddress]="customDeliveryAddress"
                                                (selectedAddressChange)="handleDeliveryAddressChanged($event)"
                                                [canEdit]="false"
                                                [selectable]="true"></app-subsystem-address-selection-dialog>
    </div>
</div>
