import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import * as _ from "underscore";
import {ConfigSystem} from "../../config-system";
import {MultilanguageField} from "../../../../../supportedLanguages";

@Component({
    selector: 'app-config-system-dimensions-settings',
    templateUrl: './config-system-dimensions-settings.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigSystemDimensionsSettingsComponent implements OnInit {

    @Input() item: ConfigSystem;
    @Input() dimensionsType: 'window' | 'bulk';
    @Input() validationErrors: any;
    @Input() dimensionLabelKeysMap: Map<string, MultilanguageField> = new Map<string, MultilanguageField>();

    dimensionsRange: number[] = [];

    ngOnInit(): void {
        this.dimensionsRange = _.range(1, 7);
    }

    getLabelKey(field: 'name' | 'print_name' | 'tooltip', num: number) {
        const idKeyPart = 'CONFIG_DIMENSION.' + (this.item.id != undefined ? this.item.id : 'id');
        const numKeyPart = this.dimensionsType === 'bulk'
            ? ('DIM_' + num) : num <= 3 ? ('WIDTH_' + num) : ('HEIGHT_' + (num - 3));
        switch (field) {
            case "name":
                return idKeyPart + '.NAME.' + numKeyPart;
            case "print_name":
                return idKeyPart + '.PRINT_NAME.' + numKeyPart;
            case "tooltip":
                return idKeyPart + '.TOOLTIP.' + numKeyPart;
        }
    }
}
