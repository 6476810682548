<div *ngIf="!displayModelEditor" class="list-content">
    <div class="table-header">
        <div class="title">{{'NAVIGATION.ITEMS.WINDOW-SYSTEM.MODEL.LIST' | translate}}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button  buttonId="add"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.MODEL.ADD' | translate }}" icon="add"
                                   type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>

    <p-dataTable #dt [value]="windowSystemModels" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true"
                 [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)"
                 [responsive]="true" [(selection)]="selectedWindowSystemModel"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate">

        <p-column field="windowSystem.name" header="{{ 'WINDOW_SYSTEM_MODEL.FORM.WINDOW_SYSTEM_NAME' | translate }}"
                  [sortable]="true"
                  [filter]="showFilters"
                  filterMatchMode="contains">
            <ng-template pTemplate="body" let-item="rowData">
                {{item.windowSystemName[translate.currentLang]}}
            </ng-template>
        </p-column>

        <p-column field="sortIndex" [style]="{'max-width': '200px'}" header="{{ 'WINDOW_SYSTEM_MODEL.FORM.SORT_INDEX' | translate }}" [sortable]="true">
        </p-column>
        <p-column [style]="{'width': '100px'}">
            <ng-template let-item="rowData" pTemplate="body" >
                <app-simple-button icon="edit" type="main-action" [size]="36" [inlineLabel]="false"
                                   label="{{'WINDOW_SYSTEM_MODEL.FORM.EDIT_SORT_INDEX' | translate }}"
                                   (onClick)="displaySortIndexDialog(item)"></app-simple-button>
            </ng-template>
        </p-column>

        <p-column field="active" header="{{ 'WINDOW_SYSTEM_MODEL.FORM.STATUS' | translate }}" [sortable]="false"
                  [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body" >
                {{(item.active ? 'WINDOW_SYSTEM_MODEL.FORM.ACTIVE' : 'WINDOW_SYSTEM_MODEL.FORM.INACTIVE') | translate}}
            </ng-template>
        </p-column>
        <p-column [style]="{'width': '100px'}">
            <ng-template let-item="rowData" pTemplate="body">
                <app-simple-button *ngIf="item.active" icon="cancel" type="delete" [size]="36" [inlineLabel]="false"
                                   label="{{'WINDOW_SYSTEM_MODEL.FORM.DEACTIVATE' | translate }}"
                                   (onClick)="changeActiveStatus(item, false)"></app-simple-button>
                <app-simple-button *ngIf="!item.active" icon="check" type="confirm" [size]="36" [inlineLabel]="false"
                                   label="{{'WINDOW_SYSTEM_MODEL.FORM.ACTIVATE' | translate }}"
                                   (onClick)="changeActiveStatus(item, true)"></app-simple-button>

            </ng-template>
        </p-column>

        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>
    </p-dataTable>
</div>

<app-window-editor *ngIf="displayModelEditor" [sidebarOnlyMode]="true" [modelMode]="true"
                   [editedWindowSystemId]="editedWindowSystemId"
                   (onModelSave)="setDisplayModelEditor(false)"
                   (onModelCancel)="setDisplayModelEditor(false)"></app-window-editor>

<p-dialog id="sort-index-change-dialog" [(visible)]="displayChangeSortIndexDialog"
          [resizable]="false" [modal]="true" [focusOnShow]="false"
          header="{{ 'WINDOW_SYSTEM_MODEL.EDIT_SORT_INDEX_DIALOG_HEADER' | translate }}"
          (onHide)="closeSortIndexDialog()">

    <div class="new-form-400">
        <div class="new-form-row">
            <app-input-number inputId="sortIndex"
                                  label="{{ 'WINDOW_SYSTEM_MODEL.FORM.SORT_INDEX' | translate }}"
                                  [(validationMessageKey)]="validationErrors['sortIndex']"
                                  [(ngModel)]="editedSortIndex"></app-input-number>
        </div>
    </div>

    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button buttonId="window-system-defaults-replace-confirm-button"
                               label="{{ 'GENERAL.YES' | translate }}" type="main-action" [size]="40"
                               (onClick)="changeSortIndex()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.NO' | translate }}" type="cancel" [size]="40"
                               (onClick)="closeSortIndexDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
