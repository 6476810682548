import {UserRoleTypes} from '../../common/enums/UserRoleTypes';
import {ValidationService} from "../../common/service/validation.service";
import {ValidationErrorsHelper} from "../../common/ValidationErrorsHelper";
import {MultiValidator} from '../../shared/validator/input-validator';
import {User} from "./user";

export class UserDetailsValidator {

    constructor(public validationService: ValidationService) {
    }

    validateForm(user: User, file: File, validationErrors: any): boolean {
        ValidationErrorsHelper.clearAllErrorsExcluding(validationErrors, 'image');

        this.validationService.validateNotEmpty(user, ["login", "firstName", "lastName", "email"],
            validationErrors, "userDto");

        if (!user.id) {
            if (!user.password) {
                validationErrors['password'] = 'error.userDto.password.not_empty';
            }
        }
        if (user.email && user.email.indexOf('@') === -1) {
            validationErrors['email'] = 'error.userDto.email.not_well_formatted';
        }
        if (!user.interfaceLanguage) {
            validationErrors['interfaceLanguage'] = 'error.userDto.interfaceLanguage.not_empty';
        }
        if (!user.roleName) {
            validationErrors['roleName'] = 'error.userDto.roleName.null';
        } else if (user.roleName !== UserRoleTypes.KOORDYNATOR && user.roleName !== UserRoleTypes.OPIEKUN) {
            if (user.subsystemId == undefined) {
                validationErrors['subsystemId'] = 'error.userDto.subsystemId.cannot_be_empty_for_role';
            }
        }
        if (user.roleName === UserRoleTypes.SPRZEDAWCA && !user.clientId && !user.id) {
            validationErrors['clientId'] = 'error.userDto.clientId.null';
        }
        if (!user.id && !file) {
            validationErrors['image'] = 'error.userDto.image.not_empty';
        }
        if (user.password !== user.repeatedPassword) {
            validationErrors['repeatedPassword'] = 'USER-DETAILS.FORM.PASSWORD_DONT_MATCH';
        }
        validationErrors['motlawaLogin'] = MultiValidator.of('error.userDto.motlawaLogin')
            .withSizeValidator(0, 38)
            .validate(user.motlawaLogin);

        return !ValidationErrorsHelper.validationErrorsPresent(validationErrors);
    }
}
