import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {CatalogOption} from '../../catalog-option/catalog-option';
import {CatalogOptionService} from '../../catalog-option/catalog-option.service';
import {CatalogPropertyTarget} from "../../CatalogPropertyTarget";
import {TranslatedSelectItem} from "../../../../common/service/translated.select.item";
import {ConfigAddonApplication} from "../../../../../window-designer/enums/ConfigAddonApplication";
import {TranslatedSelectItemImpl} from "../../../../common/service/translated.select.item.impl";
import {CatalogProperty} from "../catalog-property";

@Component({
    selector: 'app-catalog-property-form',
    templateUrl: './catalog-property-form.component.html',
    styleUrls: ['./catalog-property-form.component.css'],
    providers: [CatalogOptionService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CatalogPropertyFormComponent implements OnInit {

    @Input() item: CatalogProperty;
    @Input() validationErrors: any;
    @Input() target: CatalogPropertyTarget;

    availableOptions: CatalogOption[];
    selectedOptions: CatalogOption[];

    availableApplicableTo: TranslatedSelectItem[] = [];

    constructor(public translateService: TranslateService,
                public optionService: CatalogOptionService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.loadOptions();
        this.initAvailableApplicableToItems();
    }

    handleMoveToSource(moved: CatalogOption[]): void {
        for (let movedOption of moved) {
            let selectedIndex = this.item.options.findIndex(orderId => orderId === movedOption.id);
            if (selectedIndex !== -1) {
                this.item.options.splice(selectedIndex, 1);
            }
        }
    }

    handleMoveToTarget(moved: CatalogOption[]): void {
        for (let movedOption of moved) {
            this.item.options.push(movedOption.id);
        }
    }

    handleTargetReorder(): void {
        this.item.options = [];
        for (let option of this.selectedOptions) {
            this.item.options.push(option.id);
        }
    }

    loadOptions(): void {
        this.availableOptions = [];
        this.selectedOptions = [];

        this.optionService.getItems(null, 0, {target: {value: this.target}}, "sortIndex", undefined)
            .subscribe(
                offers => {
                    this.availableOptions = offers.data;
                    this.selectedOptions = [];
                    for (let selectedOptionId of this.item.options) {
                        let selectedIndex = this.availableOptions.findIndex(option => option.id === selectedOptionId);
                        if (selectedIndex !== -1) {
                            this.selectedOptions.push(...this.availableOptions.splice(selectedIndex, 1));
                        }
                    }
                    this.changeDetector.markForCheck();
                }
            );
    }

    showVisibilityCheckboxes(): boolean {
        return this.target === CatalogPropertyTarget.ROOF_SYSTEMS || this.target === CatalogPropertyTarget.GATES;
    }

    get showBusinessTypeRelatedCheckboxes(): boolean {
        return this.target === CatalogPropertyTarget.WINDOW_SYSTEMS;
    }

    hasApplication() {
        return this.item.target === CatalogPropertyTarget.CONFIG_ADDONS;
    }

    private initAvailableApplicableToItems(): void {
        this.availableApplicableTo = Object.keys(ConfigAddonApplication).filter(element => element !== ConfigAddonApplication.WINDOW)
            .map(element => new TranslatedSelectItemImpl('OFFER.POSITIONS.ADDON_CONFIG.APPLICABLE.' + element, element));
    }
}
