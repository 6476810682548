<p-dialog [visible]="true" [resizable]="false" [modal]="true" (onHide)="resetDialog()" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'ROT_DATA.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-400">
        <div class="new-form-row">
            <div class="input-with-action-container">
                <app-input-number inputId="rotPercentValue" [ngModel]="rotData.rotPercentValue"
                                  label="{{ 'ROT_DATA.ROT_PERCENT_VALUE' | translate }}"
                                  disabled></app-input-number>
            </div>
            <div class="new-form-field flex-row input-action-button-container">
                <app-simple-button buttonId="recalculatePrice" [disabled]="!displayedDialogData.canEdit"
                                   [label]="'ROT_DATA.PERCENT_VALUE.REFRESH' | translate"
                                   icon="cached" [inlineLabel]="false" type="filter" [size]="40" align="right"
                                   (onClick)="loadCurrentPercentValue()"></app-simple-button>

            </div>
        </div>
        <div class="new-form-row">
            <app-input-number inputId="grossLimit" [(ngModel)]="rotData.grossLimit"
                              label="{{ 'ROT_DATA.GROSS_LIMIT' | translate: {currency: rotSettings.rotCalculationCurrency} }}"
                              [required]="true" [min]="0" [max]="rotSettings.absoluteGrossLimit"
                              [disabled]="!displayedDialogData.canEdit"
                              [(validationMessageKey)]="validationErrors['grossLimit']"></app-input-number>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <ng-container>
                <app-simple-button buttonId="save" label="{{ 'GENERAL.SAVE' | translate }}"
                                   [disabled]="!displayedDialogData.canEdit"
                                   type="main-action" [size]="40" (onClick)="submitRot()"></app-simple-button>
                <app-simple-button buttonId="cancel" label="{{ 'GENERAL.CANCEL' | translate }}"
                                   icon="cancel" [disabled]="!displayedDialogData.canEdit"
                                   type="delete" [size]="40" (onClick)="submitCancelRot()"></app-simple-button>
            </ng-container>
            <app-simple-button label="{{ 'GENERAL.EXIT' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
