<div class="list-content" *ngIf="!newItem" [hidden]="!newItem && displayDialog">
    <div class="table-header">
        <div class="title">{{ 'SETTINGS.SECTION.WEBSHOP_CUSTOM_PAGE.GENERAL' | translate }}</div>
        <div class="table-header-buttons">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'SETTINGS.SECTION.WEBSHOP_CUSTOM_PAGE.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable [paginator]="true" [rows]="chosenRowsPerPage" [totalRecords]="totalRecords"
                 [rowsPerPageOptions]="rowsPerPageOptions" [responsive]="true" selectionMode="single"
                 [(selection)]="selectedItem" (onRowDblclick)="doOnRowSelect($event)" [lazy]="true"
                 (onLazyLoad)="loadItemsLazy($event)" emptyMessage="{{ 'GENERAL.NO_RECORDS_FOUND' | translate }}"
                 sortField="{{ defaultSortColumn }}" [sortOrder]="defaultSortOrder" dataKey="id" [value]="itemList">
        <p-column field="name" header="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.NAME' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>
        <p-column field="path" header="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.PATH' | translate }}" [sortable]="true"
                  [filter]="showFilters" filterMatchMode="contains"></p-column>
        <p-column field="type" header="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.TYPE' | translate }}" [sortable]="false"
                  [filter]="showFilters" [filterValues]="pageTypeFilter | async">
            <ng-template pTemplate="body" let-webshopCustomPage="rowData">
                {{ 'WEBSHOP_CUSTOM_PAGE.TYPE.' + webshopCustomPage.type | translate }}
            </ng-template>
        </p-column>
        <p-column field="linkLocation" header="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.LINK_LOCATION' | translate }}"
                  [sortable]="false" [filter]="showFilters" [filterValues]="linkLocationFilter | async">
            <ng-template pTemplate="body" let-webshopCustomPage="rowData">
                {{ 'WEBSHOP_CUSTOM_PAGE.LINK_LOCATION.' + webshopCustomPage.linkLocation | translate }}
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>
                {{ 'GENERAL.TOTAL_RECORDS' | translate: {from: fromRecord, to: toRecord, total: totalRecords} }}
            </span>
        </ng-template>
    </p-dataTable>
</div>
<app-wizard *ngIf="displayDialog" header="{{ 'SETTINGS.SECTION.WEBSHOP_CUSTOM_PAGE.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">
    <app-wizard-step label="{{ 'WEBSHOP_CUSTOM_PAGE.STEPS.DATA' | translate }}">
        <div class="new-form-60vw">
            <div class="new-form-row">
                <app-input-text inputId="name" label="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.NAME' | translate }}"
                                [(validationMessageKey)]="validationErrors['name']" [maxlength]="64" [required]="true"
                                [(ngModel)]="item.name"></app-input-text>
            </div>
            <div class="new-form-row">
                <app-input-text inputId="path" label="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.PATH' | translate }}"
                                [(validationMessageKey)]="validationErrors['path']" [maxlength]="64" [required]="true"
                                [(ngModel)]="item.path"></app-input-text>
            </div>
            <div class="new-form-row">
                <app-select inputId="type" label="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.TYPE' | translate }}"
                            [(validationMessageKey)]="validationErrors['type']" [options]="pageTypeOptions | async"
                            [required]="true" [(ngModel)]="item.type"></app-select>
            </div>
            <div class="new-form-row">
                <app-checkbox inputId="linkLocation" label="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.LINK_LOCATION' | translate }}"
                              [(validationMessageKey)]="validationErrors['linkLocation']" [required]="true"
                              [ngModel]="isShownInHeader()"
                              (ngModelChange)="handleShownInHeaderChange($event)"></app-checkbox>
            </div>
            <div class="new-form-row content-editor">
                <p-editor *ngIf="isText()" [(ngModel)]="item.content"></p-editor>
                <app-text-area *ngIf="isHtml()" inputId="content"
                               label="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.CONTENT' | translate }}"
                               [(validationMessageKey)]="validationErrors['content']" [maxlength]="7000000"
                               [required]="true" [(ngModel)]="item.content"></app-text-area>
                <app-input-text *ngIf="isExternal()" inputId="src"
                                label="{{ 'WEBSHOP_CUSTOM_PAGE.FORM.SRC' | translate }}"
                                [(validationMessageKey)]="validationErrors['src']" [maxlength]="1024" [required]="true"
                                [(ngModel)]="item.src"></app-input-text>
            </div>
            <div *ngIf="isText() && validationErrors['content' ] != undefined" class="new-form-field-error-message">
                {{ validationErrors['content' ] | translate }}
            </div>
        </div>
    </app-wizard-step>
</app-wizard>
