import {DealerDiscount} from "../dealer-discount";

export class DealerDiscountPerSupplier extends DealerDiscount {
    supplierId: number;
    supplierCompanyName: string;

    constructor() {
        super();
        this.supplierId = undefined;
        this.supplierCompanyName = undefined;
    }

    public static fromJSON(jsonObject: any): DealerDiscountPerSupplier {
        let dealerDiscount = new DealerDiscountPerSupplier();
        dealerDiscount.id = jsonObject.id;
        dealerDiscount.active = jsonObject.active;
        dealerDiscount.cascade = jsonObject.cascade;
        dealerDiscount.supplierId = jsonObject.supplierId;
        dealerDiscount.supplierCompanyName = jsonObject.supplierCompanyName;
        dealerDiscount.discountValue = jsonObject.discountValue;
        dealerDiscount.calculatedDiscountValue = jsonObject.calculatedDiscountValue;
        dealerDiscount.validFrom = new Date(jsonObject.validFrom);
        dealerDiscount.isCurrent = jsonObject.isCurrent;
        return dealerDiscount;
    }
}
