
export class DefiningMullion {
    id: number;
    side: 'top' | 'bottom';

    constructor(id: number, side: 'top' | 'bottom') {
        this.id = id;
        this.side = side;
    }
}
