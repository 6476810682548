import {MultilanguageFieldInterface} from '../../../../window-designer/catalog-data/multilanguage-field-interface';
import {CrudItem, JsonDeserializable} from "../../../common/crud-common/crudItem";
import {MultilanguageField} from "../../../supportedLanguages";

@JsonDeserializable
export class EntranceGlazingPackage implements CrudItem {
    id: number = undefined;
    name: MultilanguageFieldInterface = new MultilanguageField();
    shortcut: MultilanguageFieldInterface = new MultilanguageField();
    symbol: string = undefined;
    glassesQuantity = 1;
    active = true;
    sortIndex = 1;

    static fromJSON(jsonObject: any): EntranceGlazingPackage {
        const entranceGlazingPackage = new EntranceGlazingPackage();
        entranceGlazingPackage.id = jsonObject['id'];
        if (jsonObject['name'] != undefined) {
            entranceGlazingPackage.name = MultilanguageField.fromJSON(jsonObject['name']);
        }
        if (jsonObject['shortcut'] != undefined) {
            entranceGlazingPackage.shortcut = MultilanguageField.fromJSON(jsonObject['shortcut']);
        }
        entranceGlazingPackage.symbol = jsonObject['symbol'];
        entranceGlazingPackage.glassesQuantity = jsonObject['glassesQuantity'];
        entranceGlazingPackage.active = jsonObject['active'];
        entranceGlazingPackage.sortIndex = jsonObject['sortIndex'];
        return entranceGlazingPackage;
    }
}
