import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    TemplateRef
} from '@angular/core';
import {Router} from "@angular/router";
import {Permissions} from "../../auth/permission.service";
import {ButtonWithMenuElementSelectedEvent} from "./button-with-menu-event";
import {MenuElement, MenuElementType} from "./MenuElement";
import {MenuType} from "./MenuType";

@Component({
    selector: 'button-popup-menu',
    templateUrl: './button-popup-menu.component.html',
    styleUrls: ['./button-with-menu.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonPopupMenuComponent implements OnInit, OnChanges {

    menuElementType = MenuElementType;

    @Input() menuType: MenuType;
    @Input() menuElements: MenuElement[] = [];
    @Input() alignmentPopupPosition: number[] = [0, 0];
    @Input() menuId;
    @Input() menuVisible;
    @Input() menuElementTemplate: TemplateRef<any>;

    @Output() menuElementSelected = new EventEmitter<ButtonWithMenuElementSelectedEvent>();

    materialPopupClasses = "material-popup ";

    private permissions: Permissions;
    private router: Router;

    MenuTypeChoices = MenuType;

    constructor(permissions: Permissions,
                router: Router) {

        this.permissions = permissions;
        this.router = router;
    }

    ngOnInit(): void {
        this.assignCssClassesToMenu();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['menuType']) {
            this.assignCssClassesToMenu();
        }
    }

    onPositionSelected(identifier: string, event?): void {
        this.menuElementSelected.emit(new ButtonWithMenuElementSelectedEvent(identifier, event));
    }

    private assignCssClassesToMenu(): void {
        this.materialPopupClasses = "material-popup ";
        switch (this.menuType) {
            case MenuType.TOP_LEFT:
                this.materialPopupClasses += "top-left";
                break;
            case MenuType.TOP_RIGHT:
                this.materialPopupClasses += "top-right";
                break;
            case MenuType.BOTTOM_LEFT:
                this.materialPopupClasses += "bottom-left";
                break;
            case MenuType.BOTTOM_RIGHT:
                this.materialPopupClasses += "bottom-right";
                break;
            case MenuType.SIDEBAR_ICON_BUTTON:
            case MenuType.ICON_LINE:
                this.materialPopupClasses += "sidebar";
                break;
            default:
                this.materialPopupClasses += "top-right";
                break;
        }
    }

    getMenuLeftPosition(): number {
        switch (this.menuType) {
            case MenuType.TOP_LEFT:
            case MenuType.BOTTOM_LEFT:
            case MenuType.TOP_RIGHT:
            case MenuType.BOTTOM_RIGHT:
            case MenuType.ICON_LINE:
                return this.alignmentPopupPosition[0];
            case MenuType.SIDEBAR_ICON_BUTTON:
                return 0;
            default:
                return 0;
        }
    }

    isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    onNavigateTo(url: string): void {
        this.router.navigate([url]);
    }

    get getTopStyle(): any {
        if (this.menuType !== MenuType.ICON_LINE) {
            return this.alignmentPopupPosition[1];
        }
    }
}
