import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {SelectItem} from "primeng/api/selectitem";
import {forkJoin, of} from 'rxjs';
import {catchError, map} from "rxjs/operators";
import {Permissions} from "../../../auth/permission.service";
import {SimpleButtonType} from "../../../form-inputs/simple-button/simple-button.component";
import {MotlawaIntegrationService} from '../../offer/payment-package/motlawa-integration.service';
import {ApplicationStatusService} from './application-status.service';
import {GrowlMessageController} from "../../../common/growl-message/growl-message-controller";
import {CommonErrorHandler} from "../../../common/CommonErrorHandler";

enum DbConnectionStatus {
    CHECKING,
    FAILED,
    SUCCESSFUL
}

@Component({
    selector: 'app-application-status',
    templateUrl: './application-status.component.html',
    styleUrls: ['./application-status.component.css'],
    providers: [ApplicationStatusService, MotlawaIntegrationService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationStatusComponent implements OnInit {

    maintenanceActive = false;
    motlawaDbConnectionId: string;
    motlawaConnectionIds: SelectItem[] = [];
    readonly motlawaConnectionStatus = new Map<string, DbConnectionStatus>();

    constructor(private applicationStatusService: ApplicationStatusService,
                private motlawaIntegrationService: MotlawaIntegrationService,
                public permissions: Permissions,
                private growlMessageController: GrowlMessageController,
                private errors: CommonErrorHandler,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
        forkJoin({
            maintenanceActive: this.applicationStatusService.isMaintenanceActive(),
            motlawaDbConnectionId: this.permissions.canEditMotlawaConfiguration()
                ? this.applicationStatusService.getMotlawaDbConnectionId()
                : of<string>(undefined),
            motlawaConnectionIds: this.permissions.canEditMotlawaConfiguration() ?
                this.motlawaIntegrationService.getDataSourceIds()
                : of<string[]>([])
        }).subscribe(data => {
            this.maintenanceActive = data.maintenanceActive;
            this.motlawaDbConnectionId = data.motlawaDbConnectionId;
            this.motlawaConnectionIds = data.motlawaConnectionIds.map(id => ({label: id, value: id}));
            this.changeDetector.markForCheck();
        });
    }

    toggleMaintenanceStatus(): void {
        this.applicationStatusService.setMaintenanceStatus(!this.maintenanceActive)
            .subscribe(() => {
                this.maintenanceActive = !this.maintenanceActive;
                this.changeDetector.markForCheck();
            });
    }

    handleTestConnectionClick(event: MouseEvent, id: string): void {
        event.stopPropagation();
        this.motlawaConnectionStatus.set(id, DbConnectionStatus.CHECKING);
        this.motlawaIntegrationService.testDataSource(id).pipe(
            map(() => DbConnectionStatus.SUCCESSFUL),
            catchError(() => of(DbConnectionStatus.FAILED)),
        ).subscribe(success => {
            this.motlawaConnectionStatus.set(id, success);
            this.changeDetector.markForCheck();
        });
    }

    getConnectionStatusButtonType(id: string): SimpleButtonType {
        const dbConnectionStatus = this.motlawaConnectionStatus.get(id);
        if (dbConnectionStatus == undefined) {
            return 'action';
        }
        switch (dbConnectionStatus) {
            case DbConnectionStatus.CHECKING:
                return 'action';
            case DbConnectionStatus.FAILED:
                return 'delete';
            case DbConnectionStatus.SUCCESSFUL:
                return 'confirm';
            default:
                break;
        }
    }

    isConnectionStatusCheckButtonDisabled(id: string): boolean {
        return this.motlawaConnectionStatus.get(id) === DbConnectionStatus.CHECKING;
    }

    saveMotlawaDbConnection() {
        this.applicationStatusService.setMotlawaDbConnectionId(this.motlawaDbConnectionId).subscribe({
            next: () => {
                this.growlMessageController.info('APPLICATION_STATUS.MOTLAWA_CONNECTION_SAVED');
            },
            error: error => {
                this.errors.handle(error);
            }
        });
    }
}
