import {ProfileInterface} from './catalog-data/profile-interface';
import {WindowSystemInterface} from './catalog-data/window-system-interface';
import {CompositionType} from "./drawing-data/CompositionType";
import {DrawingData} from "./drawing-data/drawing-data";
import {ProfileCompositionUtils} from "./utils/profile-composition-utils";
import {CompositionDistances, CompositionDistancesUtils} from "./composition-distances";
import {OpeningDirection} from "./window-types/opening-direction";
import {SubwindowSide} from "./enums/subwindow-side";

class AdditionalProfilesConstraints {
    allowMullionCrossing = true;
}

export enum ProfilesCompositionType {
    // possible width overrides
    FRAME = 'FRAME',
    GLAZING_BEAD = 'GLAZING_BEAD',
    WING = 'WING',
    WING_OVERLAP = 'WING_OVERLAP',
    GLAZING_PACKAGE_SEATING_DEPTH = 'GLAZING_PACKAGE_SEATING_DEPTH',

    // only default width
    MULLION = 'MULLION',
    MOVABLE_POST = 'MOVABLE_POST',
    SLIDING_OVERLAP = 'SLIDING_OVERLAP',
    SLIDING_CONTACT = 'SLIDING_CONTACT',
    THRESHOLD = 'THRESHOLD',
    CHANNEL_SECTION = 'CHANNEL_SECTION',
}

type OverridableCompositionTypes =
    ProfilesCompositionType.FRAME
    | ProfilesCompositionType.GLAZING_BEAD
    | ProfilesCompositionType.WING
    | ProfilesCompositionType.WING_OVERLAP
    | ProfilesCompositionType.GLAZING_PACKAGE_SEATING_DEPTH;

export interface ProfileCompositionIds {
    doorstepId: number;
    frameProfileId: number;
    channelSectionId: number;
    constructionalMullionId: number;
    movablePostId: number;
}

export class ProfilesCompositionDistances {

    actual: Map<ProfilesCompositionType, CompositionDistances> = new Map<ProfilesCompositionType, CompositionDistances>();
    fallback: Map<ProfilesCompositionType, number> = new Map<ProfilesCompositionType, number>();
    additional = new AdditionalProfilesConstraints();

    constructor() {
        this.fallback.set(ProfilesCompositionType.FRAME, 66);
        this.fallback.set(ProfilesCompositionType.MULLION, 84);
        this.fallback.set(ProfilesCompositionType.GLAZING_BEAD, 20);
        this.fallback.set(ProfilesCompositionType.WING, 78);
        this.fallback.set(ProfilesCompositionType.WING_OVERLAP, 28);
        this.fallback.set(ProfilesCompositionType.MOVABLE_POST, 64);
        this.fallback.set(ProfilesCompositionType.SLIDING_OVERLAP, 46);
        this.fallback.set(ProfilesCompositionType.SLIDING_CONTACT, 14);
        this.fallback.set(ProfilesCompositionType.GLAZING_PACKAGE_SEATING_DEPTH, 0);
        this.fallback.set(ProfilesCompositionType.THRESHOLD, undefined);
        this.fallback.set(ProfilesCompositionType.CHANNEL_SECTION, undefined);
    }

    public copyFallbackToActual(): void {
        this.fallback.forEach((val, key) => this.set(key, new CompositionDistances(val)));
    }

    get(type: Exclude<ProfilesCompositionType, OverridableCompositionTypes>): number;
    get(type: OverridableCompositionTypes, side: SubwindowSide, opening: OpeningDirection): number;
    get(type: ProfilesCompositionType, side?: SubwindowSide, opening?: OpeningDirection): number {
        if (this.actual.get(type) != undefined) {
            return side == undefined ? this.actual.get(type).defaultWidth :
                CompositionDistancesUtils.get(this.actual.get(type), side, opening);
        }
        if (type !== ProfilesCompositionType.THRESHOLD && type !== ProfilesCompositionType.CHANNEL_SECTION) {
            console.warn('Required distance definition missing for type: ' + type + '. Using fallback instead.');
        }
        return this.fallback.get(type);
    }

    getDefault(type: ProfilesCompositionType): number {
        return this.getFull(type) == undefined ? this.fallback.get(type) : this.getFull(type).defaultWidth;
    }

    getFull(type: ProfilesCompositionType): CompositionDistances {
        return this.actual.get(type);
    }

    set(type: ProfilesCompositionType, compositionDistances: CompositionDistances): void {
        this.actual.set(type, compositionDistances);
    }

    validate(data: DrawingData): boolean {
        return this.validateForErrors(data).length === 0;
    }

    validateForErrors(data: DrawingData): string[] {
        let validationErrors: string[] = [];

        let windows = data.windows;
        if (windows.length === 0) {
            return validationErrors;
        }

        let requiredValidated = (
            this.actual.get(ProfilesCompositionType.FRAME) != undefined &&
            this.actual.get(ProfilesCompositionType.GLAZING_BEAD) != undefined &&
            this.actual.get(ProfilesCompositionType.WING) != undefined &&
            this.actual.get(ProfilesCompositionType.WING_OVERLAP) != undefined &&
            this.actual.get(ProfilesCompositionType.GLAZING_PACKAGE_SEATING_DEPTH) != undefined
        );
        let checkForComposition = (comp: CompositionType) => windows.some(window =>
            window.subWindows.some(
                sw => sw.profilesComposition.left === comp || sw.profilesComposition.right === comp ||
                    sw.profilesComposition.top === comp || sw.profilesComposition.bottom === comp));
        let movablePostValidated = (checkForComposition(CompositionType.MOVABLE_POST) ?
            this.actual.get(ProfilesCompositionType.MOVABLE_POST) != undefined : true);
        let hsoValidated = (checkForComposition(CompositionType.SLIDING_OVERLAP) ?
            this.actual.get(ProfilesCompositionType.SLIDING_OVERLAP) != undefined : true);
        let hscValidated = (checkForComposition(CompositionType.SLIDING_CONTACT) ?
            this.actual.get(ProfilesCompositionType.SLIDING_CONTACT) != undefined : true);
        let mullionValidated = (ProfileCompositionUtils.constructionalMullionPresent(data) ?
            this.actual.get(ProfilesCompositionType.MULLION) != undefined : true);
        let thresholdValidated = data.specification.doorstepId == null ||
            this.actual.get(ProfilesCompositionType.THRESHOLD) != undefined;
        let channelSectionValidated = data.specification.channelSectionId == null ||
            this.actual.get(ProfilesCompositionType.CHANNEL_SECTION) != undefined;
        if (!requiredValidated) {
            validationErrors.push('ERRORS.REQUIRED_PROFILES_DISTANCE_DEFINITION_MISSING');
            console.warn("Required profiles distance definitions missing.");
        }
        if (!movablePostValidated) {
            validationErrors.push('ERRORS.MOVABLE_POST_WIDTH_DEFINITION_MISSING');
            console.warn("Movable post is present but it's width definition is missing.");
        }
        if (!mullionValidated) {
            validationErrors.push('ERRORS.MULLION_WIDTH_DEFINITION_MISSING');
            console.warn("Constructional mullion is present but it's width definition is missing.");
        }
        if (!hsoValidated) {
            validationErrors.push('ERRORS.HS_SYSTEM_WIDTH_DEFINITION_MISSING');
            console.warn("HS system present but it's wing overlap width definition is missing.");
        }
        if (!hscValidated) {
            validationErrors.push('ERRORS.HS_SYSTEM_CONTACT_WIDTH_DEFINITION_MISSING');
            console.warn("HS system present but it's wing contact width definition is missing.");
        }
        if (!thresholdValidated) {
            validationErrors.push('ERRORS.THRESHOLD_DEFINITION_MISSING');
            console.warn("Door threshold present but it's width definition is missing.");
        }
        if (!channelSectionValidated) {
            validationErrors.push('ERRORS.CHANNEL_SECTION_DEFINITION_MISSING');
            console.warn("Channel section present but it's width definition is missing.");
        }

        return validationErrors;
    }

    prepareSystem(selectedWindowSystem: WindowSystemInterface): void {
        this.set(ProfilesCompositionType.GLAZING_BEAD, selectedWindowSystem.glazingBeadWidth);
        this.set(ProfilesCompositionType.GLAZING_PACKAGE_SEATING_DEPTH, selectedWindowSystem.glazingPackageSeatingDepth);
        this.set(ProfilesCompositionType.WING, selectedWindowSystem.wingWidth);
        this.set(ProfilesCompositionType.WING_OVERLAP, selectedWindowSystem.wingImpositionWidth);
        if (selectedWindowSystem.hsSystem) {
            this.set(ProfilesCompositionType.SLIDING_OVERLAP, new CompositionDistances(selectedWindowSystem.wingsImpositionWidth));
            this.set(ProfilesCompositionType.SLIDING_CONTACT, new CompositionDistances(selectedWindowSystem.wingsContactWidth));
        } else {
            this.set(ProfilesCompositionType.SLIDING_OVERLAP, undefined);
            this.set(ProfilesCompositionType.SLIDING_CONTACT, undefined);
        }
        this.additional.allowMullionCrossing = selectedWindowSystem.allowMullionCrossing;
    }

    getCompositionDistances(profiles: ProfileInterface[], id: number): CompositionDistances {
        return (profiles.find(p => p.id === id) || {compositionDistances: undefined}).compositionDistances;
    }

    prepareProfileDistances(profiles: ProfileInterface[], specification: ProfileCompositionIds): void {
        this.set(ProfilesCompositionType.FRAME,
            this.getCompositionDistances(profiles, specification.frameProfileId));
        this.set(ProfilesCompositionType.MULLION,
            this.getCompositionDistances(profiles, specification.constructionalMullionId));
        this.set(ProfilesCompositionType.MOVABLE_POST,
            this.getCompositionDistances(profiles, specification.movablePostId));
        this.set(ProfilesCompositionType.THRESHOLD,
            this.getCompositionDistances(profiles, specification.doorstepId));
        this.set(ProfilesCompositionType.CHANNEL_SECTION,
            this.getCompositionDistances(profiles, specification.channelSectionId));
    }
}
