import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    TrackByFunction,
    ViewChildren
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {WindowTypeCodeParser} from '../../../../../window-designer/utils/WindowTypeCodeParser';
import {WindowTypeCode} from '../../../../../window-designer/window-types/window-type-code';
import {Permissions} from '../../../../auth/permission.service';
import {PriceTable} from '../priceTable';
import {getPricetableSelectionModeParamEnum} from '../pricetable-selection-mode';
import {Type2WindowSystem} from '../type2windowSystem';
import {WindowPricingtableComponent} from './window-pricingtable.component';
import {PricelistTarget} from "../../pricelist";
import {Material2ConfigSystem} from "../material2ConfigSystem";
import {CatalogItemExtended} from "../../../window-system/entrance-model/entrance-model-basic";
import {CatalogItemName} from "../../../../common/crud-common/catalog-item-name";

@Component({
    selector: 'app-window-pricingtables',
    templateUrl: './window-pricingtables.component.html',
    styleUrls: ['./window-pricingtable.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowPricingtablesComponent implements OnInit {

    @Input() target: PricelistTarget;
    @Input() systemsWithLinks: CatalogItemExtended[];
    @Input() allMaterials: CatalogItemName[];
    @Input() tableInfo: Type2WindowSystem | Material2ConfigSystem;
    @Input() priceTablesToEdit: (Type2WindowSystem | Material2ConfigSystem)[] = [];
    @Input() priceTables: PriceTable[];
    @Input() selectedPricelistIsFuture: boolean;
    @Input() canDownloadExternalPriceTable: boolean;
    @Output() readonly addChildPricetable = new EventEmitter<PriceTable>();
    @Output() readonly submit = new EventEmitter<{priceTables: PriceTable[], material2system?: Material2ConfigSystem}>();
    @Output() readonly downloadExternalPriceTable = new EventEmitter<{ code: string, future: boolean }>();
    @Output() readonly close = new EventEmitter<void>();

    @ViewChildren(WindowPricingtableComponent)
    childPricetables: QueryList<WindowPricingtableComponent>;

    isNew = false;
    typeNameParser: (name: string) => string;
    selectedTab = 0;

    targets = PricelistTarget;
    systems: CatalogItemExtended[];
    selectedSystem: CatalogItemExtended;
    materials: CatalogItemName[];
    selectedMaterial: CatalogItemName;
    userLang: string;

    constructor(public readonly permissions: Permissions,
                private readonly translate: TranslateService,
                private readonly changeDetector: ChangeDetectorRef) {
        this.userLang = this.translate.currentLang;
        this.typeNameParser = (name: string) => WindowTypeCodeParser.getTypeName(WindowTypeCode[name]);
    }

    ngOnInit(): void {
        this.isNew = !this.tableInfo.priceTableItemsPresent && this.priceTablesToEdit.length < 2;
        if (this.isNew && this.systemsWithLinks != null) {
            this.systems = this.systemsWithLinks;
            this.onSystemChange(this.systems[0]);
        }
    }

    pricetableTabTrackIndex: TrackByFunction<PriceTable> = (index, item) => {
        return item.selectionAdditionalParam2;
    }

    get primaryPricetable(): PriceTable {
        return this.priceTables[0];
    }

    get selectedPricetable(): PriceTable {
        return this.priceTables[this.selectedTab];
    }

    get selectedPricetableComponent(): WindowPricingtableComponent {
        return this.childPricetables != undefined ? this.childPricetables.toArray()[this.selectedTab] : undefined;
    }

    get additionalParamEnumType() {
        return getPricetableSelectionModeParamEnum(this.tableInfo['windowSystemPricetableSelectionMode']);
    }

    get canHaveTabs(): boolean {
        return this.additionalParamEnumType != undefined;
    }

    get canAddNewTab(): boolean {
        const enumType = this.additionalParamEnumType;
        return Object.values(enumType).filter(v => typeof v === 'number').length > this.priceTables.length;
    }

    addNewTab(): void {
        let i = 0;
        for (; i < this.priceTables.length; ++i) {
            if (this.priceTables[i].selectionAdditionalParam2 > i) {
                break;
            }
        }
        const newPriceTable = new PriceTable();
        newPriceTable.items = [];
        newPriceTable.selectionAdditionalParam2 = i;
        newPriceTable.markOutdatedPricesWhenActivated = this.markOutdatedPricesWhenActivated;
        this.addChildPricetable.emit(newPriceTable);
    }

    getTabHeader(priceTable: PriceTable): string {
        const enumType = this.additionalParamEnumType;
        return `PRICE_TABLES.FORM.TABS.${this.tableInfo.windowSystemPricetableSelectionMode}.${enumType[priceTable.selectionAdditionalParam2]}`;
    }

    get markOutdatedPricesWhenActivated(): boolean {
        return this.priceTables.every(priceTable => priceTable.markOutdatedPricesWhenActivated);
    }

    set markOutdatedPricesWhenActivated(value: boolean) {
        this.priceTables.forEach(priceTable => priceTable.markOutdatedPricesWhenActivated = value);
    }

    onSubmit() {
        let event: any = {priceTables: this.childPricetables.map(cp => cp.onSubmit())};
        if (this.isNew && this.target === PricelistTarget.CONFIGS) {
            event.material2system = this.tableInfo;
        }
        this.submit.emit(event);
    }

    cancel() {
        this.close.emit();
    }

    systemsOptionFormatter = (config: CatalogItemExtended) => {
        return {
            label: config.name[this.translate.currentLang],
            value: config
        };
    }

    materialsOptionFormatter = (material: CatalogItemName) => {
        return {
            label: material.name[this.translate.currentLang],
            value: material
        };
    }

    onSystemChange(system: CatalogItemExtended) {
        this.selectedSystem = system;
        (this.tableInfo as Material2ConfigSystem).configSystemId = system.id;
        this.materials = this.selectedSystem.links.map(materialId => this.allMaterials.find(material => material.id === materialId));
        this.onMaterialChange(this.materials[0]);
    }

    onMaterialChange(material: CatalogItemName) {
        this.selectedMaterial = material;
        (this.tableInfo as Material2ConfigSystem).materialId = material.id;
        this.changeDetector.markForCheck();
    }

    getTableInfoForWindows(): Type2WindowSystem {
        return this.tableInfo as Type2WindowSystem;
    }

    getTableInfoForConfigs(): Material2ConfigSystem {
        return this.tableInfo as Material2ConfigSystem;
    }
}
