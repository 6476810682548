import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Listing} from '../../../common/crud-common/crudItemList';
import {DataServiceHelper} from '../../../common/dataServiceHelper';
import {ValidationErrors} from '../../../common/validation-errors';
import {ValidationErrorsHelper} from '../../../common/ValidationErrorsHelper';
import {ListOfIds} from '../../ListOfIds';
import {PaletteAdditionalElement} from './palette-additional-element';

@Injectable()
export class PaletteAdditionalElementService {

    private static readonly API_URL_ELEMENT = 'paletteAdditionalElement';
    private static readonly API_URL_ELEMENTS = 'paletteAdditionalElements';

    constructor(private http: HttpClient, private dataServiceHelper: DataServiceHelper) {
    }

    getItems(offset: number, pageSize: number, filters: any, sortColumn: string,
             sortOrder: number): Observable<Listing<PaletteAdditionalElement>> {
        let params = this.dataServiceHelper.prepareSearchParams(offset, pageSize, filters, sortColumn, sortOrder);
        return this.http.get<Listing<PaletteAdditionalElement>>(PaletteAdditionalElementService.API_URL_ELEMENTS, {params: params});
    }

    save(paletteAdditionalElement: PaletteAdditionalElement): Observable<void> {
        return this.http.post<void>(PaletteAdditionalElementService.API_URL_ELEMENT, paletteAdditionalElement);
    }

    delete(ids: ListOfIds): Observable<void> {
        return this.http.request<void>('DELETE', PaletteAdditionalElementService.API_URL_ELEMENTS, {body: ids});
    }

    validate(paletteAdditionalElement: PaletteAdditionalElement): Observable<ValidationErrors> {
        return ValidationErrorsHelper.mapBackendValidationErrors(
            this.http.post<void>(`${PaletteAdditionalElementService.API_URL_ELEMENT}/validate`, paletteAdditionalElement));
    }
}
