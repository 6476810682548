export enum TerraceHandleLayout {
    // wszedzie gdzie sie da
    EVERYWHERE = 'EVERYWHERE',

    // wszedzie (tylko od srodka)
    EVERYWHERE_ONLY_INSIDE = 'EVERYWHERE_ONLY_INSIDE',

    // tylko skrajne od wewnatrz
    EDGES_ONLY_INSIDE = 'EDGES_ONLY_INSIDE',

    // tylko środkowe (akordeon rozwijany na boki)
    CENTER = 'CENTER',

    // nigdzie
    NOWHERE = 'NOWHERE',
}
