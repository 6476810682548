import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {OfferMessagesContainer, PositionMessage} from './message';

@Injectable()
export class MessageService {

    private static SERVICE_PATH = 'messages';

    constructor(private http: HttpClient) {
    }

    getMessagesForPosition(positionId: number): Observable<PositionMessage[]> {
        return this.http.get<PositionMessage[]>(`${MessageService.SERVICE_PATH}/position/${positionId}`);
    }

    getMessagesForOffer(offerId: number): Observable<OfferMessagesContainer> {
        return this.http.get<OfferMessagesContainer>(`${MessageService.SERVICE_PATH}/offer/${offerId}`);
    }
}
