import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {LazyLoadEvent} from 'primeng/api/lazyloadevent';
import {Table} from 'primeng/table';
import {forkJoin, Observable, of} from 'rxjs';
import {mergeMap} from 'rxjs/operators';
import {CurrentUserService} from '../../../../auth/current-user.service';
import {Permissions} from '../../../../auth/permission.service';
import {MenuType} from '../../../../common/button-with-menu/MenuType';
import {DatatableInterface, TableToDatatableInterfaceAdapter} from '../../../../common/DatatableHelper';
import {ExchangeService} from '../../../../common/exchange.service';
import {OfferStatusProvider} from '../../../../common/offerStatusProvider';
import {CrudComponent} from '../../../../common/service/crud.component';
import {DataTableColumnBuilder} from '../../../../common/service/data.table.column.builder';
import {ExportComponent} from '../../../../common/service/export.component';
import {Currencies} from '../../../../currencies';
import {ListOfIds} from '../../../ListOfIds';
import {Offer, OrderDeliveryListNames, OrderProductionOrderNumbers} from '../../offer';
import {OffersService} from '../../offer-service';

@Component({
    selector: 'app-offer-search-results',
    templateUrl: './offer-search-results.component.html',
    styleUrls: ['../../../../common/offer-status-colors.css'],
    providers: [OffersService, ExchangeService, ExportComponent],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OfferSearchResultsComponent extends CrudComponent implements OnInit {

    private readonly ACQUIRE_OFFERLOCK_ID = 'acquireOfferLock';
    private readonly GLOBAL_SEARCH_LOCK_ID = 'globalSearchLock';

    private searchedValue: string;

    MenuType = MenuType;

    totalRecords = 0;
    fromRecord = 0;
    toRecord = 0;
    offers: Offer[];
    selectedItem: Offer;
    selectedCurrency = Currencies.PLN;

    @ViewChild('dt', {static: true})
    table: Table;

    constructor(injector: Injector,
                changeDetector: ChangeDetectorRef,
                private offersService: OffersService,
                private exchangeService: ExchangeService,
                private translateService: TranslateService,
                private router: Router,
                private activatedRoute: ActivatedRoute,
                public permissions: Permissions,
                private currentUserService: CurrentUserService) {
        super(injector, changeDetector, 'OfferSearchResultsComponent', false);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.createTable();
        this.langTranslateSubscription.push(this.activatedRoute.queryParamMap.subscribe(queryParams => {
            if (this.activatedRoute.snapshot.paramMap.get('component') === 'offer-search') {
                this.searchedValue = queryParams.get('searchedValue');
                this.table.filterGlobal(this.searchedValue, 'contains');
            }
        }));
        this.exchangeService.initializeExchangeRates().subscribe(exchangeRates => {
            this.exchangeService.storeExchangeRates(exchangeRates);
            this.blockUiController.unblock(this.viewName + 'Data');
        });
    }

    getExportData(): Observable<object[]> {
        throw new Error("Method not implemented.");
    }

    showDialogToAdd(): void {
        throw new Error("Method not implemented.");
    }

    submit(): void {
        throw new Error("Method not implemented.");
    }

    onRowSelect(event: any): void {
        throw new Error("Method not implemented.");
    }

    getDatatable(): DatatableInterface {
        return TableToDatatableInterfaceAdapter.create(this.table);
    }

    private createTable() {
        let builder = DataTableColumnBuilder.create()
            .add('offerNumber', 'OFFER.FORM.OFFER_NUMBER', true)
            .add('alternateOfferNumber', 'OFFER.FORM.ALT_OFFER_NUMBER', true);
        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR']})) {
            builder.add('productionOrderNumber', 'OFFER.FORM.PRODUCTION_ORDER_NUMBER', true)
                .add('deliveryListName', 'OFFER.FORM.DELIVERY_LISTS_NAMES', true)
                .add('paletteName', 'OFFER.FORM.PALETTE_NAMES', true);
        }
        builder.add('paymentPackageName', 'OFFER.FORM.RELATED_PAYMENT_PACKAGE_NAME', true)
            .add('createdDate', 'OFFER.FORM.CREATED_DATE', true)
            .setDefaultSortOrder(DataTableColumnBuilder.ORDER_DESCENDING)
            .add('lastStatusChange', 'OFFER.FORM.LAST_STATUS_CHANGE', true)
            .add('status', 'OFFER.FORM.STATUS', true)
            .add('lastModifiedDate', 'OFFER.FORM.LAST_MODIFIED_DATE', true)
            .add('validFrom', 'OFFER.FORM.VALID_FROM', true)
            .add('validTo', 'OFFER.FORM.VALID_TO', true);

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('subsystemName', 'OFFER.FORM.SUBSYSTEM', true);
        }

        builder.add('clientManagerName', 'OFFER.FORM.CLIENT_MANAGER', true)
            .add('clientGroupName', 'OFFER.FORM.CLIENT_GROUP', true)
            .add('merchantGroupName', 'OFFER.FORM.MERCHANT_GROUP', true)
            .add('clientName', 'OFFER.FORM.CLIENT_NAME', true)
            .add('clientEmail', 'OFFER.FORM.CLIENT_EMAIL', true)
            .add('clientIdentifier', 'OFFER.FORM.CLIENT_IDENTIFIER', true)
            .add('vatSell', 'OFFER.FORM.VAT_SELL', true)
            .add('sellNetVal', 'OFFER.FORM.SELL_NET_VAL', true)
            .add('sellGrossVal', 'OFFER.FORM.SELL_GROSS_VAL', false);

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN', 'ROLE_OPERATOR', 'ROLE_HANDLOWIEC']})) {
            builder.add('vatBuy', 'OFFER.FORM.VAT_BUY', true)
                .add('buyNetVal', 'OFFER.FORM.BUY_NET_VAL', true)
                .add('buyGrossVal', 'OFFER.FORM.BUY_GROSS_VAL', true);
        }

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('buyVenskaNetVal', 'OFFER.FORM.BUY_VENSKA_NET_VAL', true)
                .add('buyVenskaGrossVal', 'OFFER.FORM.BUY_VENSKA_GROSS_VAL', true);
        }

        builder.add('ownAddonsNetCost', 'OFFER.FORM.OWN_ADDONS_NET_COST', true)
            .add('ownAddonsGrossCost', 'OFFER.FORM.OWN_ADDONS_GROSS_COST', true);
        if (this.permissions.isPermitted({roles: ['ROLE_HANDLOWIEC', 'ROLE_OPERATOR']})) {
            builder.add('netIncome', 'OFFER.FORM.NET_INCOME', true)
                .add('grossIncome', 'OFFER.FORM.GROSS_INCOME', true);
        }

        if (this.permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']})) {
            builder.add('netRetailIncome', 'OFFER.FORM.NET_RETAIL_INCOME', true)
                .add('grossRetailIncome', 'OFFER.FORM.GROSS_RETAIL_INCOME', true)
                .add('vatRetailSell', 'OFFER.FORM.VAT_RETAIL_SELL', true)
                .add('retailSellNetVal', 'OFFER.FORM.RETAIL_SELL_NET_VAL', true)
                .add('retailSellGrossVal', 'OFFER.FORM.RETAIL_SELL_GROSS_VAL', true)
                .add('sellerClientGroupName', 'OFFER.FORM.SELLER_CLIENT_GROUP', true)
                .add('sellerClientName', 'OFFER.FORM.SELLER_CLIENT_NAME', true)
                .add('sellerClientEmail', 'OFFER.FORM.SELLER_CLIENT_EMAIL', true)
                .add('sellerClientIdentifier', 'OFFER.FORM.SELLER_CLIENT_IDENTIFIER', true);
        }

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_OPIEKUN']})) {
            builder.add('netVenskaIncome', 'OFFER.FORM.NET_INCOME', true)
                .add('grossVenskaIncome', 'OFFER.FORM.GROSS_INCOME', true);
        }

        builder.add('merchantName', 'OFFER.FORM.MERCHANT_NAME', true);

        builder.add('deliveryAddressStreet', 'OFFER.FORM.ADDRESS.STREET', true)
            .add('deliveryAddressCity', 'OFFER.FORM.ADDRESS.CITY', true)
            .add('deliveryAddressZip', 'OFFER.FORM.ADDRESS.ZIP', true)
            .add('deliveryAddressCountry', 'OFFER.FORM.ADDRESS.COUNTRY', true);

        if (this.permissions.isPermitted({roles: ['ROLE_KOORDYNATOR', 'ROLE_READ_WEBSHOP_DETAILS']})) {
            builder.add('sourceOfferNumber', 'OFFER.FORM.SOURCE_OFFER_NUMBER', true);
        }

        super.init(builder.build());
    }

    handleLazyLoad(event: LazyLoadEvent): void {
        this.blockUiController.block(this.GLOBAL_SEARCH_LOCK_ID);
        this.offersService.getOffersSimple(this.searchedValue, event.first, event.rows, event.sortField, event.sortOrder)
            .pipe(mergeMap(result => {
                const offerIds = result.data.map(offer => offer.id);
                const canSeeProductionOrderNumber =
                    this.columnOptions.findIndex(col => col.value === 'productionOrderNumber') >= 0;
                const canSeeDeliveryListName = this.columnOptions.findIndex(col => col.value === 'deliveryListName') >= 0 && !this.permissions.isPermitted({roles: ['ROLE_SPRZEDAWCA']});
                return forkJoin({
                    result: of(result),
                    productionOrderNumbers: canSeeProductionOrderNumber ? this.offersService.getProductionOrderNumbersForOrderList(offerIds) : of<OrderProductionOrderNumbers[]>([]),
                    deliveryLists: canSeeDeliveryListName ? this.offersService.getDeliveryListsForOrderList(new ListOfIds(offerIds)) : of<OrderDeliveryListNames[]>([])
                });
            }))
            .subscribe(result => {
                this.offers = result.result.data;
                this.totalRecords = result.result.totalRecords;
                this.fromRecord = Math.min(event.first + 1, this.totalRecords);
                this.toRecord = Math.min(event.first + event.rows, this.totalRecords);
                this.selectedItem = this.offers[0];
                this.offers.forEach(offer => {
                    let relatedProductionOrders = result.productionOrderNumbers.find(orderNumbers => orderNumbers.offerId === offer.id);
                    offer.productionOrders = relatedProductionOrders ? relatedProductionOrders.productionOrderNumbers : [];

                    let relatedDeliveryLists = result.deliveryLists.find(deliveryListName => deliveryListName.offerId === offer.id);
                    offer.palettes = relatedDeliveryLists ? relatedDeliveryLists.paletteNames : [];
                    offer.deliveryLists = relatedDeliveryLists ? relatedDeliveryLists.deliveryListNames : [];
                });
                this.blockUiController.unblock(this.GLOBAL_SEARCH_LOCK_ID);
                this.changeDetector.markForCheck();
            });
    }

    rowTrackById(index: number, item: Offer): number {
        return item.id;
    }

    getPriceInDefaultCurrency(priceInPln: number, priceInOfferCurrency: number, exchangeRate: number,
                              subsystemManualExchangeRate?: number): string {
        if (priceInOfferCurrency != undefined) {
            return priceInOfferCurrency.toFixed(2);
        }
        return ExchangeService.getPriceInDefaultCurrency(priceInPln, exchangeRate, subsystemManualExchangeRate);
    }

    getPriceInSelectedCurrency(priceInPln: number, priceInOfferCurrency: number,
                               offer: Offer, applySubsystemManualExchangeRate = false): string {
        if (this.selectedCurrency === offer.currency) {
            if (priceInOfferCurrency != undefined) {
                return priceInOfferCurrency.toFixed(2);
            }
            if (applySubsystemManualExchangeRate && offer.subsystemManualExchangeRate) {
                return ExchangeService.getPriceInDefaultCurrency(priceInPln, offer.exchangeRate, offer.subsystemManualExchangeRate);
            }
            return ExchangeService.getPriceInDefaultCurrency(priceInPln, offer.exchangeRate);
        }
        if (priceInPln == undefined) {
            const plnPriceToOfferCurrencyRatio = (applySubsystemManualExchangeRate && offer.subsystemManualExchangeRate)
                ? offer.subsystemManualExchangeRate
                : offer.exchangeRate;
            return this.exchangeService.getPriceInCurrency(priceInOfferCurrency, offer.currency, this.selectedCurrency,
                plnPriceToOfferCurrencyRatio);
        }
        return this.exchangeService.getPLNPriceInCurrency(priceInPln, this.selectedCurrency);
    }

    changeSemicolonsToLineBreaks(groups: string): string {
        return groups.replace(/;/g, "\n");
    }

    containsSemicolon(groups: string): boolean {
        return groups.indexOf(";") > 0;
    }

    redirectToPositionList(offer: Offer): void {
        if (offer.offerLockUserLogin == null) {
            this.blockUiController.block(this.ACQUIRE_OFFERLOCK_ID);
            this.offersService.blockOffer(offer.id).subscribe(
                hasLock => {
                    this.blockUiController.unblock(this.ACQUIRE_OFFERLOCK_ID);
                    this.navigateToPositionList(offer.id, hasLock);
                });
        } else {
            this.navigateToPositionList(offer.id, offer.offerLockUserLogin === this.currentUserService.currentUserName);
        }
    }

    private navigateToPositionList(offerId: number, hasLock: boolean): void {
        this.router.navigate(['/features/offer', offerId, 'position', {hasLock: hasLock, fromSearch: this.searchedValue}]);
    }

    isOrder(offer: Offer): boolean {
        return OfferStatusProvider.isOrderStatus(offer.status);
    }

    showInMainView(offer: Offer): void {
        this.router.navigate(['/features/offer', {
            component: this.isOrder(offer) ? 'order' : 'offer',
            ignoreStoredFilter: 'true'
        }], {
            queryParams: {
                offerNumber: offer.offerNumber
            }
        });
    }
}
