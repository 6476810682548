<div class="sheet-container" (copy)="onCopy($event)" (paste)="onPaste($event)" (mouseleave)="finishSelection()">
    <div class="sheet-row">
        <div class="sheet-cell header-cell header-column-cell"></div>
        <div class="sheet-cell header-cell"
             [class.selected-cell]="selectedHeaderColumns | cellSelected:null:num"
             (mousedown)="startSelection(null, num)"
             (mouseenter)="continueSelection(null, num)"
             (mouseup)="finishSelection()"
             *ngFor="let num of columns">{{firstColumnHeaderLabel ? (num === 0 ? columnLabel : num) : columnLabel + num }}</div>
    </div>
    <div *ngFor="let row of data; index as i" class="sheet-row">
        <div class="sheet-cell header-cell"
             [class.selected-cell]="selectedHeaderRows | cellSelected:i:null"
             (mousedown)="startSelection(i, null)"
             (mouseenter)="continueSelection(i, null)"
             (mouseup)="finishSelection()"
        >{{ rowLabel + (includeDescriptionRow ? i : i + 1) }}</div>
        <input *ngFor="let num of columns; index as j"
               [ngClass]="getStyleClassesForCell(i, j)"
               [(ngModel)]="row[num]"
               [readOnly]="readOnly || readonlyCell"
               (ngModelChange)="clearErrors(); validateCell(i, j, $event)"
               (dblclick)="readonlyCell = false"
               (blur)="readonlyCell = true"
               (mousedown)="startSelection(i,j)"
               (mouseenter)="continueSelection(i,j)"
               (mouseup)="finishSelection()"
               (keydown.control.a)="detectSelectAll($event)"
               (keydown.delete)="deleteValue()"/>
    </div>
</div>
