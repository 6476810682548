import {MotlawaBulkAddonsDistributionPossibility} from "../../../common/enums/motlawa-bulk-addons-distribution-possibility.enum";

export class MotlawaBulkAddonsDistributionSelection {
    supplierId: number;
    offerId: number;
    selection: MotlawaBulkAddonsDistributionPossibility;

    constructor(supplierId: number, offerId: number, selection: MotlawaBulkAddonsDistributionPossibility) {
        this.supplierId = supplierId;
        this.offerId = offerId;
        this.selection = selection;
    }
}
