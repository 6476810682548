<div class="new-form-600">
    <div class="new-form-row">
      <app-input-number inputId="maxFrameSize"
                        label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MAX_FRAME_SIZE' | translate }}"
                        [disabled]="disabled"
                        [(validationMessageKey)]="validationErrors['maxFrameSize']" [min]="1" [max]="999999"
                        [required]="true" [(ngModel)]="windowSystem.maxFrameSize"></app-input-number>
    </div>
    <div class="new-form-row">
      <app-input-number inputId="minAngleF" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MIN_ANGLE_F' | translate }}"
                        [disabled]="disabled"
                        [(validationMessageKey)]="validationErrors['minAngleF']" [min]="0" [max]="180"
                        [required]="true" [(ngModel)]="windowSystem.minAngleF"></app-input-number>
    </div>
    <div class="new-form-row">
      <app-input-number inputId="minAngleFW"
                        label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MIN_ANGLE_FW' | translate }}"
                        [disabled]="disabled"
                        [(validationMessageKey)]="validationErrors['minAngleFW']" [min]="0" [max]="180"
                        [required]="true" [(ngModel)]="windowSystem.minAngleFW"></app-input-number>
    </div>
    <div class="new-form-row">
      <app-input-number inputId="minAngleR" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MIN_ANGLE_R' | translate }}"
                        [disabled]="disabled"
                        [(validationMessageKey)]="validationErrors['minAngleR']" [min]="0" [max]="90"
                        [required]="true" [(ngModel)]="windowSystem.minAngleR"></app-input-number>
    </div>
    <div class="new-form-row">
      <app-input-number inputId="minAngleU" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.MIN_ANGLE_U' | translate }}"
                        [disabled]="disabled"
                        [(validationMessageKey)]="validationErrors['minAngleU']" [min]="0" [max]="90"
                        [required]="true" [(ngModel)]="windowSystem.minAngleU"></app-input-number>
    </div>
    <div class="new-form-row">
      <app-input-text inputId="glazingWidths_text"
                      label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.GLAZING_WIDTHS' | translate }}"
                      [disabled]="disabled"
                      [(validationMessageKey)]="validationErrors['glazingWidths']" [required]="true"
                      [(ngModel)]="windowSystem.glazingWidths"></app-input-text>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="defaultWidth" label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DEFAULT_WIDTH' | translate }}"
                          [disabled]="disabled"
                          [(validationMessageKey)]="validationErrors['defaultWidth']" [min]="1" [max]="15000"
                          [required]="true" [(ngModel)]="windowSystem.defaultWidth"></app-input-number>
    </div>
    <div class="new-form-row">
        <app-input-number inputId="defaultHeight"
                          label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.DEFAULT_HEIGHT' | translate }}"
                          [disabled]="disabled"
                          [(validationMessageKey)]="validationErrors['defaultHeight']" [min]="1" [max]="10000"
                          [required]="true" [(ngModel)]="windowSystem.defaultHeight"></app-input-number>
    </div>

    <div *ngIf="windowSystem.systemType !== 'TERRACE' && !isPredefinedGlazing" class="new-form-row">
        <app-select inputId="systemGlazingPackage"
                    label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYSTEM_GLAZING_PACKAGE' | translate }}"
                    [disabled]="disabled"
                    [(validationMessageKey)]="validationErrors['glazingPackageId']" [options]="glazingPackages"
                    [allowSelectingNone]="true" [(ngModel)]="windowSystem.glazingPackageId"></app-select>
    </div>

    <div *ngIf="windowSystem.systemType !== 'TERRACE'" class="new-form-row">
        <app-select inputId="systemDecorativeGlazingPackage"
                    label="{{ 'WINDOW-SYSTEM-DEFINITION.FORM.SYSTEM_DECORATIVE_GLAZING_PACKAGE' | translate }}"
                    [disabled]="disabled"
                    [(validationMessageKey)]="validationErrors['decorativeGlazingPackageId']"
                    [options]="decorativeGlazingPackages" [allowSelectingNone]="true"
                    [(ngModel)]="windowSystem.decorativeGlazingPackageId"></app-select>
    </div>

</div>
