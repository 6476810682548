// tslint:disable:max-line-length
import {DrawingUtil, MinMaxXY} from '../window-designer/drawing-util';
import {TextPainter} from '../window-designer/painters/TextPainter';
import {GateImagePaintParams} from './gate-image-paint-params';
import {GatePanelPrintPainter} from './gate-panel-print-painter';
import {GatePrintAttributes, GatePrintConstants} from './gate-print-constants';
import {TranslateService} from "@ngx-translate/core";

export class GatePrintPainter {

    // tslint:disable:no-invalid-template-strings
    static readonly LINTEL_HEIGHT_LABEL_PLACEHOLDER = '${LINTEL_HEIGHT_LABEL}';
    static readonly HOLE_HEIGHT_LABEL_PLACEHOLDER = '${HOLE_HEIGHT_LABEL}';
    static readonly CLEARANCE_HEIGHT_LABEL_PLACEHOLDER = '${CLEARANCE_HEIGHT_LABEL}';
    static readonly HOLE_WIDTH_LABEL_PLACEHOLDER = '${HOLE_WIDTH_LABEL}';
    static readonly CLEARANCE_WIDTH_LABEL_PLACEHOLDER = '${CLEARANCE_WIDTH_LABEL}';

    // tslint:enable:no-invalid-template-strings

    private readonly panelPainter: GatePanelPrintPainter;
    private boundingBox: MinMaxXY;

    protected constructor(private readonly svg: Snap.Paper,
                          private readonly translations: TranslateService) {
        this.panelPainter = new GatePanelPrintPainter(svg);
    }

    paint(params: GateImagePaintParams, localizedOnly: boolean): void {
        this.svg.clear();
        if (!params.width || !params.height) {
            return;
        }

        let panelsBox = new MinMaxXY(
            GatePrintConstants.HORIZONTAL_WALL_THICKNESS + GatePrintConstants.PERSPECTIVE_SPACING,
            GatePrintConstants.HORIZONTAL_WALL_THICKNESS + GatePrintConstants.PERSPECTIVE_SPACING + params.width,
            GatePrintConstants.LINTEL_HEIGHT + GatePrintConstants.PERSPECTIVE_SPACING,
            GatePrintConstants.LINTEL_HEIGHT + GatePrintConstants.PERSPECTIVE_SPACING + params.height,
        );

        this.setupViewBox(params);
        this.panelPainter.paint(params, panelsBox);
        this.paintBuilding(params);
        this.paintDimensions(params, panelsBox, localizedOnly);
    }

    private setupViewBox(params: GateImagePaintParams): void {
        let box = new MinMaxXY(
            -GatePrintConstants.SVG_PADDING_LEFT,
            params.width + (GatePrintConstants.HORIZONTAL_WALL_THICKNESS + GatePrintConstants.PERSPECTIVE_SPACING) * 2 + GatePrintConstants.SVG_PADDING_RIGHT,
            -GatePrintConstants.SVG_PADDING_TOP,
            params.height + GatePrintConstants.LINTEL_HEIGHT + GatePrintConstants.SVG_PADDING_BOTTOM + GatePrintConstants.PERSPECTIVE_SPACING * 2
        );
        this.boundingBox = box;
        this.svg.attr({
            viewBox: [
                box.minX,
                box.minY,
                box.maxX - box.minX,
                box.maxY - box.minY
            ].join(' ')
        });
    }

    private paintBuilding(params: GateImagePaintParams): void {
        const totalWidth = params.width + GatePrintConstants.HORIZONTAL_WALL_THICKNESS * 2 + GatePrintConstants.PERSPECTIVE_SPACING * 2;
        const totalHeight = params.height + GatePrintConstants.LINTEL_HEIGHT + GatePrintConstants.PERSPECTIVE_SPACING * 2;
        this.paintPerspectiveWallSegments(params);
        const bricksGroup = this.svg.g();
        bricksGroup.attr({
            fill: GatePrintAttributes.wallColor,
            transform: 'translate(0,1280) scale(0.1,-0.1)'
        });

        for (const path of GatePrintAttributes.brickPaths) {
            bricksGroup.add(this.svg.path(path));
        }

        const pattern = bricksGroup
            .toPattern(0, 0, 344, 426.666666)
            .attr({
                viewBox: '0 0 1032 1280'
            })
            .toDefs();

        let wallPoints = [
            0, 0,
            totalWidth, 0,
            totalWidth, totalHeight,
            totalWidth - GatePrintConstants.HORIZONTAL_WALL_THICKNESS, totalHeight,
            totalWidth - GatePrintConstants.HORIZONTAL_WALL_THICKNESS, GatePrintConstants.LINTEL_HEIGHT,
            GatePrintConstants.HORIZONTAL_WALL_THICKNESS, GatePrintConstants.LINTEL_HEIGHT,
            GatePrintConstants.HORIZONTAL_WALL_THICKNESS, totalHeight,
            0, totalHeight
        ];

        const wall = this.svg.path(DrawingUtil.pathStringFromPolygonPoints(wallPoints, true));
        wall.addClass('gate-building-wall');
        wall.attr({
            fill: pattern,
            ...GatePrintAttributes.wallBorder
        });
    }

    private paintPerspectiveWallSegments(params: GateImagePaintParams): void {
        let outerBox = new MinMaxXY(
            GatePrintConstants.HORIZONTAL_WALL_THICKNESS, GatePrintConstants.HORIZONTAL_WALL_THICKNESS + params.width + GatePrintConstants.PERSPECTIVE_SPACING * 2,
            GatePrintConstants.LINTEL_HEIGHT, GatePrintConstants.LINTEL_HEIGHT + params.height + GatePrintConstants.PERSPECTIVE_SPACING * 2
        );
        let innerBox = DrawingUtil.shrinkBoxBy(outerBox, GatePrintConstants.PERSPECTIVE_SPACING);

        let topPoints = [
            outerBox.minX, outerBox.minY,
            outerBox.maxX, outerBox.minY,
            innerBox.maxX, innerBox.minY,
            innerBox.minX, innerBox.minY
        ];
        let leftPoints = [
            outerBox.minX, outerBox.minY,
            innerBox.minX, innerBox.minY,
            innerBox.minX, innerBox.maxY,
            outerBox.minX, outerBox.maxY
        ];
        let rightPoints = [
            outerBox.maxX, outerBox.minY,
            outerBox.maxX, outerBox.maxY,
            innerBox.maxX, innerBox.maxY,
            innerBox.maxX, innerBox.minY
        ];

        let topSegment = this.svg.path(DrawingUtil.pathStringFromPolygonPoints(topPoints, true));
        let leftSegment = this.svg.path(DrawingUtil.pathStringFromPolygonPoints(leftPoints, true));
        let rightSegment = this.svg.path(DrawingUtil.pathStringFromPolygonPoints(rightPoints, true));

        let g = this.svg.group(...[topSegment, leftSegment, rightSegment]);
        g.attr(GatePrintAttributes.perspective);
    }

    private paintDimensions(params: GateImagePaintParams, panelsBox: MinMaxXY, localizedOnly: boolean): void {
        this.paintDimensionsGroup(params, panelsBox, true);
        if (!localizedOnly) {
            this.paintDimensionsGroup(params, panelsBox, false);
        }
    }

    private paintDimensionsGroup(params: GateImagePaintParams, panelsBox: MinMaxXY, localizedLabels: boolean): void {
        const fontSize = Math.max((this.boundingBox.maxX - this.boundingBox.minX), (this.boundingBox.maxY - this.boundingBox.minY)) / 48;

        const verticalGroup = this.svg.g();
        let verticalText = `${params.height} ${localizedLabels ? this.translations.instant('GATE_DESIGNER.DRAWING.HOLE_HEIGHT_LABEL') : GatePrintPainter.HOLE_HEIGHT_LABEL_PLACEHOLDER}`;
        verticalGroup.addClass('gate-dimensions-vertical');
        verticalGroup.add(
            // TODO: unknown value calculation
            // this.svg.line(-GatePrintPainter.RULERS_SPACING,
            //     GatePrintConstants.LINTEL_HEIGHT,
            //     -GatePrintPainter.RULERS_SPACING,
            //     params.height + GatePrintConstants.LINTEL_HEIGHT),
            this.svg.line(-GatePrintConstants.RULERS_SPACING /*removed until the other line is enabled  * 2*/,
                panelsBox.minY,
                -GatePrintConstants.RULERS_SPACING /*removed until the other line is enabled  * 2*/,
                panelsBox.maxY),
            TextPainter.simpleText(this.svg, verticalText,
                [-GatePrintConstants.RULERS_SPACING /*removed until the other line is enabled  * 2*/ - fontSize / 2,
                 (panelsBox.minX + panelsBox.maxY) / 2],
                fontSize, false, true).attr({'text-anchor': 'middle'}),
            this.svg.line(-GatePrintConstants.RULERS_SPACING /*removed until the other line is enabled  * 2*/ - 20,
                panelsBox.minY,
                0,
                panelsBox.minY),
            this.svg.line(-GatePrintConstants.RULERS_SPACING /*removed until the other line is enabled  * 2*/ - 20,
                panelsBox.maxY,
                0,
                panelsBox.maxY)
        );
        verticalGroup.attr(GatePrintAttributes.dimensionsLines);

        let horizontalRulerYPosition = GatePrintConstants.LINTEL_HEIGHT + params.height + GatePrintConstants.RULERS_SPACING + GatePrintConstants.PERSPECTIVE_SPACING;
        const horizontalGroup = this.svg.g();
        let horizontalText = `${params.width} ${localizedLabels ? this.translations.instant('GATE_DESIGNER.DRAWING.HOLE_WIDTH_LABEL') : GatePrintPainter.HOLE_WIDTH_LABEL_PLACEHOLDER}`;
        horizontalGroup.addClass('gate-dimensions');
        horizontalGroup.addClass('gate-dimensions-horizontal');
        horizontalGroup.add(
            // TODO: unknown value calculation
            // this.svg.line(GatePrintPainter.HORIZONTAL_WALL_THICKNESS,
            //     GatePrintConstants.LINTEL_HEIGHT + params.height + GatePrintPainter.RULERS_SPACING,
            //     GatePrintPainter.HORIZONTAL_WALL_THICKNESS + params.width,
            //     GatePrintConstants.LINTEL_HEIGHT + params.height + GatePrintPainter.RULERS_SPACING),
            this.svg.line(panelsBox.minX,
                horizontalRulerYPosition,
                panelsBox.maxX,
                horizontalRulerYPosition),
            TextPainter.simpleText(this.svg, horizontalText,
                [(panelsBox.minX + panelsBox.maxX) / 2,
                 horizontalRulerYPosition - fontSize / 2],
                fontSize, false, false).attr({'text-anchor': 'middle'}),
            this.svg.line(panelsBox.minX,
                horizontalRulerYPosition - GatePrintConstants.RULERS_SPACING,
                panelsBox.minX,
                horizontalRulerYPosition + 20),
            this.svg.line(panelsBox.maxX,
                horizontalRulerYPosition - GatePrintConstants.RULERS_SPACING,
                panelsBox.maxX,
                horizontalRulerYPosition + 20)
        );
        horizontalGroup.attr(GatePrintAttributes.dimensionsLines);

        const background = this.svg.filter(`<feFlood flood-color="${GatePrintAttributes.lintelHeightTextBackground}" result="background"/><feComposite in="SourceGraphic" in2="background" operator="over"/>`);
        background.attr({
            x: '-2%',
            width: '104%',
            filterUnits: 'objectBoundingBox'
        });

        const lintelHeightGroup = this.svg.g();
        lintelHeightGroup.addClass('gate-dimensions');
        lintelHeightGroup.addClass('gate-dimensions-lintel');
        lintelHeightGroup.attr({
            strokeWidth: 2
        });
        let lintelText = `${localizedLabels ? this.translations.instant('GATE_DESIGNER.DRAWING.LINTEL_HEIGHT_LABEL') : GatePrintPainter.LINTEL_HEIGHT_LABEL_PLACEHOLDER} ${params.lintelHeight}`;
        const lintelHeightTextElement = TextPainter.simpleText(this.svg, lintelText,
            [0, 0],
            fontSize, false, false);
        lintelHeightTextElement.attr({
            'dominant-baseline': 'central',
            'dy': '0.8em',
            'filter': background
        });

        const dimensionGroup = this.svg.g();
        dimensionGroup.addClass(localizedLabels ? 'localized' : 'translation-keys');
        dimensionGroup.add(verticalGroup);
        dimensionGroup.add(horizontalGroup);

        // below structure is to remove slight text-box "out of the gate" position
        let svg = this.svg.svg(0, 0, undefined, undefined, undefined, undefined, undefined, undefined) as Snap.Paper;
        const dimensionGroup2 = svg.g();
        dimensionGroup2.addClass(localizedLabels ? 'localized' : 'translation-keys');
        dimensionGroup2.add(lintelHeightGroup);
        lintelHeightGroup.add(
            lintelHeightTextElement
        );
    }
}
