import {HttpErrorResponse} from '@angular/common/http';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from "@angular/core";
import {SelectItem} from 'primeng/api/selectitem';
import {Permissions} from "../../../auth/permission.service";
import {ResponseError} from "../../../common/error.handler";
import {OnceFlag} from '../../../shared/once-flag';
import {ErrorResponse} from "../../errors/errorResponse";
import {User} from "../user";
import {UserService} from "../user.service";

@Component({
    selector: 'app-substitute-user-dialog',
    templateUrl: './substitute-user-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubstituteUserDialogComponent {

    @Input() substituteUsers: SelectItem[];
    @Input() user: User;
    @Output() onHide = new EventEmitter<void>();
    @Output() onSuccess = new EventEmitter<void>();
    @Output() onMoveClientsError = new EventEmitter<void>();
    @Output() onSaveUserError = new EventEmitter<void>();
    selectedSubstituteUser: User;
    validationErrors: any = {};

    private readonly dialogHideHelper = new OnceFlag();

    constructor(private permissions: Permissions,
                private userService: UserService) {
    }

    hide(): void {
        this.emitOnHide();
    }

    private emitOnHide(): void {
        this.dialogHideHelper.call(() => this.onHide.emit());
    }

    private isPermitted(requiredPermission): boolean {
        return this.permissions.isPermitted(requiredPermission);
    }

    isNotPermitted(requiredPermission): boolean {
        return !this.isPermitted(requiredPermission);
    }

    moveClientsToUser(): void {
        if (this.selectedSubstituteUser) {
            this.userService.moveClientsBetweenUsers(this.user.id, this.selectedSubstituteUser.id).subscribe({
                next: data => {
                    if (data) {
                        this.saveUser();
                    } else {
                        this.emitOnMoveClientError();
                    }
                },
                error: () => {
                    this.emitOnMoveClientError();
                }
            });
        } else {
            this.validationErrors['selectedSubstituteUser'] = 'error.userDto.selectedSubstituteUser.not_empty';
        }
    }

    private emitOnSuccess(): void {
        this.dialogHideHelper.call(() => this.onSuccess.emit());
    }

    private saveUser() {
        this.userService.saveUser(this.user, null).subscribe({
            next: () => {
                this.emitOnSuccess();
            },
            error: (errorMessage: HttpErrorResponse) => {
                let errorBody = new ErrorResponse(errorMessage.error);

                if (errorBody.is400()) {
                    this.emitOnSaveUserError();
                } else {
                    throw new ResponseError(errorMessage);
                }
            }
        });
    }

    private emitOnMoveClientError(): void {
        this.onMoveClientsError.emit();
    }

    private emitOnSaveUserError(): void {
        this.onSaveUserError.emit();
    }
}
