import {Injectable} from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {JwtHelperService} from './jwt-helper.service';
import {LogoutHelperService} from './logout-helper.service';

@Injectable()
export class AuthGuard  {

    constructor(private logoutHelper: LogoutHelperService,
                private jwtHelper: JwtHelperService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.jwtHelper.isTokenExpired()) {
            return true;
        }

        this.logoutHelper.logout();
        return false;
    }
}
