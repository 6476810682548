<div *ngIf="!newItem" class="list-content">
    <div class="table-header">
        <div class="title">{{ ('NAVIGATION.ITEMS.WINDOW-SYSTEM.' + type + '_DIMENSIONS.LIST') | translate}}</div>
        <div class="table-header-buttons" *ngIf="isPermitted({roles:['ROLE_KOORDYNATOR']})">
            <div class="table-header-buttons-container">
                <app-simple-button buttonId="toggle-filter-button"
                                   label="{{ (showFilters ? 'GENERAL.FILTER_HIDE' : 'GENERAL.FILTER') | translate }}"
                                   icon="filter_list" type="filter" (onClick)="showHideFilters()"></app-simple-button>
                <app-simple-button buttonId="copy"
                                   label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_DIMENSIONS.COPY' | translate }}"
                                   icon="content_copy" type="action" [disabled]="!selectedItem" [hotkey]="copyHotkey"
                                   (onClick)="doShowDialogToCopy()"></app-simple-button>
                <app-simple-button buttonId="add" label="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_DIMENSIONS.ADD' | translate }}"
                                   icon="add" type="main-action" [size]="40" align="right" [hotkey]="newElementHotkey"
                                   (onClick)="doShowDialogToAdd()"></app-simple-button>
            </div>
        </div>
    </div>
    <p-dataTable #dt [value]="itemList" [rows]="chosenRowsPerPage" [rowsPerPageOptions]="rowsPerPageOptions"
                 [paginator]="true" [lazy]="true" [totalRecords]="totalRecords" selectionMode="single"
                 (onRowDblclick)="doOnRowSelect($event)"
                 (onLazyLoad)="loadItemsLazy($event)" [responsive]="true"
                 [(selection)]="selectedItem"
                 [sortField]="defaultSortColumn" [sortOrder]="defaultSortOrder"
                 [emptyMessage]="'GENERAL.NO_RECORDS_FOUND'|translate" id="dimensionsTable">

        <p-headerColumnGroup>
            <p-row>
                <p-column [rowspan]="2" [sortable]="true" field="sortIndex" header="{{'WINDOW_DIMENSIONS.FORM.SORT_INDEX'|translate}}"></p-column>
                <p-column *ngIf="fieldUsage.show(DimensionsField.SYMBOL)"
                          header="{{'WINDOW_DIMENSIONS.FORM.SYMBOL'|translate}}" [rowspan]="2" [sortable]="true"
                          [filter]="showFilters" filterMatchMode="contains" field="symbol"></p-column>
                <p-column *ngIf="fieldUsage.show(DimensionsField.WIDTH)"
                          header="{{'WINDOW_DIMENSIONS.FORM.WIDTH'|translate}}" [rowspan]="2" field="width"
                          [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                          filterType="number" [filterNumericMinValue]="1" [filterNumericMaxValue]="99999"></p-column>
                <p-column *ngIf="fieldUsage.show(DimensionsField.HEIGHT)"
                          header="{{'WINDOW_DIMENSIONS.FORM.HEIGHT'|translate}}" [rowspan]="2" field="height"
                          [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                          filterType="number" [filterNumericMinValue]="1" [filterNumericMaxValue]="99999"></p-column>
                <p-column *ngIf="fieldUsage.show(DimensionsField.SURFACE)"
                          header="{{'WINDOW_DIMENSIONS.FORM.SURFACE'|translate}}" [rowspan]="2" field="surface"
                          [sortable]="true" [filter]="showFilters" filterMatchMode="contains"
                          filterType="number" [filterAllowDecimals]="true"></p-column>
                <p-column *ngIf="fieldUsage.show(DimensionsField.ACTIVE)" [rowspan]="2" [sortable]="false" field="active" header="{{'WINDOW_DIMENSIONS.FORM.STATUS'|translate}}"
                          [filter]="showFilters" [filterValues]="filterActive | selectItemTranslate"
                          [defaultFilterValue]="defaultActiveFilter"></p-column>
            </p-row>
        </p-headerColumnGroup>

        <p-column [sortable]="true" field="sortIndex"></p-column>

        <p-column *ngIf="fieldUsage.show(DimensionsField.SYMBOL)" [filter]="showFilters" [sortable]="true" field="symbol" filterMatchMode="contains">
            <ng-template let-item="rowData" pTemplate="body">
                <span class="table-field">{{item.symbol}}</span>
            </ng-template>
        </p-column>

        <p-column *ngIf="fieldUsage.show(DimensionsField.WIDTH)" [filter]="showFilters" [sortable]="true" field="width" filterMatchMode="contains"
                  filterType="number" [filterNumericMinValue]="1">
            {{item.width}}
        </p-column>

        <p-column *ngIf="fieldUsage.show(DimensionsField.HEIGHT)" [filter]="showFilters" [sortable]="true" field="height" filterMatchMode="contains"
                  filterType="number" [filterNumericMinValue]="1">
            {{item.height}}
        </p-column>

        <p-column *ngIf="fieldUsage.show(DimensionsField.SURFACE)" [filter]="showFilters" [sortable]="true" field="surface" filterMatchMode="contains"
                  filterType="number" [filterAllowDecimals]="true">
            {{item.surface}}
        </p-column>

        <p-column *ngIf="fieldUsage.show(DimensionsField.ACTIVE)" [filterValues]="filterActive | selectItemTranslate" [filter]="showFilters" field="active"
                  [defaultFilterValue]="defaultActiveFilter">
            <ng-template let-item="rowData" pTemplate="body">
                <p *ngIf="item.active">{{ 'WINDOW_DIMENSIONS.FORM.ACTIVE' | translate }}</p>
                <p *ngIf="!item.active">{{ 'WINDOW_DIMENSIONS.FORM.INACTIVE' | translate }}</p>
            </ng-template>
        </p-column>
        <ng-template pTemplate="paginatorLeft">
            <span>{{'GENERAL.TOTAL_RECORDS' | translate:{from: fromRecord, to:toRecord, total:totalRecords} }}</span>
        </ng-template>

    </p-dataTable>
</div>

<app-wizard *ngIf="newItem" header="{{ 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_DIMENSIONS.ADD' | translate }}"
            (onCancel)="cancel()" (onComplete)="submit()">

    <app-wizard-step label="{{ 'WINDOW_DIMENSIONS.STEPS.DATA' | translate }}" [id]="STEPS.DATA.id" [validate]="STEPS.DATA.validate">
        <app-window-dimensions-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" (onSizeChange)="onSizeChange()"></app-window-dimensions-form>
    </app-wizard-step>

    <app-wizard-step label="{{ 'WINDOW_DIMENSIONS.STEPS.SYSTEMS' | translate }}"
                     *ngIf="fieldUsage.show(CatalogField.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogField.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [ngModel]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>

</app-wizard>

<app-wizard-dialog *ngIf="!newItem && displayDialog"
                   header="{{ (copyMode ? 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_DIMENSIONS.COPY' : 'NAVIGATION.ITEMS.WINDOW-SYSTEM.WINDOW_DIMENSIONS.EDIT') | translate }}"
                   (onCancel)="cancel()" (onComplete)="submit()" dialogStyleClass=""
                   contentStyleClass="height-60vw-no-padding scrolled-dialog-60vw">

    <app-wizard-step label="{{ 'WINDOW_DIMENSIONS.STEPS.DATA' | translate }}" [id]="STEPS.DATA.id" [validate]="STEPS.DATA.validate">
        <app-window-dimensions-form [editPermits]="editPermits" [item]="item" [validationErrors]="validationErrors" (onSizeChange)="onSizeChange()"></app-window-dimensions-form>
    </app-wizard-step>

    <app-wizard-step label="{{ 'WINDOW_DIMENSIONS.STEPS.SYSTEMS' | translate }}"
                     *ngIf="fieldUsage.show(CatalogField.LINKED_SYSTEMS)">
        <div class="new-form-60vw">
            <app-link-selection [disabled]="fieldUsage.disabled(CatalogField.LINKED_SYSTEMS)"
                                [linkableItems]="windowSystems"
                                [showToggleAllButton]="true"
                                [windowSystemTypeGroups]="windowSystemTypeGroups"
                                [(ngModel)]="selectedWindowSystems"></app-link-selection>
        </div>
    </app-wizard-step>
</app-wizard-dialog>
