import {DecorativeFillingType} from "../catalog-data/decorative-filling-type";
import {FillingType} from "./FillingType";

export class Filling {
    type: FillingType;
    width: number;
    fillingId: number;
    decorativeFillingId: number;
    flipDecorativeFilling: boolean;
    externalColorId: number;
    internalColorId: number;
    coreColorId: number;

    constructor() {
        this.type = FillingType.GLASS;
        this.width = undefined;
        this.fillingId = undefined;
        this.decorativeFillingId = undefined;
        this.flipDecorativeFilling = false;
        this.externalColorId = undefined;
        this.internalColorId = undefined;
        this.coreColorId = undefined;
    }
}
