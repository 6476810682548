export enum Currencies {
    PLN = 'PLN',
    EUR = 'EUR',
    SEK = 'SEK',
    NOK = 'NOK',
    GBP = 'GBP',
    USD = 'USD',
    DKK = 'DKK',
    HUF = 'HUF',
    CAD = 'CAD'
}

export const CURRENCIES_WITH_PREFIX_FORMAT = [Currencies.CAD];
