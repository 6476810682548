import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SelectItem} from 'primeng/api/selectitem';
import {WindowTypeCode} from '../../../../../../window-designer/window-types/window-type-code';
import {ValidationErrors} from '../../../../../common/validation-errors';
import {WebshopRecommendedConfiguration} from '../../webshop-recommended-configuration';
import {WebshopRecommendedConfigurationService} from '../../webshop-recommended-configuration.service';

@Component({
    selector: 'app-webshop-recommended-configuration-business-type-form',
    templateUrl: './webshop-recommended-configuration-business-type-form.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WebshopRecommendedConfigurationBusinessTypeFormComponent implements OnChanges {

    @Input()
    item: WebshopRecommendedConfiguration;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    active: boolean;

    businessTypes: SelectItem[];

    private readonly businessTypesById = new Map<number, WindowTypeCode>();

    constructor(private service: WebshopRecommendedConfigurationService,
                private translate: TranslateService,
                private changeDetector: ChangeDetectorRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        const activeChange = changes['active'];
        if (activeChange != undefined && activeChange.currentValue) {
            this.businessTypesById.clear();
            this.service.getBusinessTypes(this.item.productConfiguration).subscribe(businessTypes => {
                this.businessTypes = [];
                for (const businesType of businessTypes) {
                    this.businessTypes.push({
                        label: businesType.businessType.names[this.translate.currentLang],
                        value: businesType.businessType.businessTypeId
                    });
                    this.businessTypesById.set(businesType.businessType.businessTypeId, businesType.businessType.type);
                }
                this.changeDetector.markForCheck();
            });
        }
    }

    handleBusinessTypeChange(businessTypeId: number): void {
        this.item.businessTypeId = businessTypeId;
        this.item.productConfiguration.windowTypeCode = this.businessTypesById.get(businessTypeId);
    }
}
