import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild
} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {Hotkey, HotkeysService} from "angular2-hotkeys";
import {SelectItem} from 'primeng/api/selectitem';
import {Dialog} from 'primeng/dialog';
import {OnceFlag} from "../../../shared/once-flag";
import {MenuItem} from "primeng/api/menuitem";
import {CatalogProperty} from "../../catalog-creator/catalog-property/catalog-property";
import {CatalogOption} from "../../catalog-creator/catalog-option/catalog-option";
import {SafeUrl} from "@angular/platform-browser";
import {Observable} from "rxjs";
import {OpenImageInNewBrowserTabController} from "../../../common/open-image-in-new-browser-tab-controller.service";
import {ConfigSliderElementService} from "../../window-system/config-slider-element/config-slider-element.service";
import {BlockUiController} from "../../../block-ui/block-ui-controller";
import {finalize} from "rxjs/operators";
import {ConfigSliderElement} from "../../window-system/config-slider-element/config-slider-element";

@Component({
    selector: 'app-add-product-dialog',
    templateUrl: './add-product-dialog.component.html',
    styleUrls: ['./add-product-dialog.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddProductDialogComponent implements OnDestroy {

    @Input() tabItems: MenuItem[] = [];
    @Input() activeTabItem: MenuItem;

    @Input() dialogHeader = 'OFFER.DRAWING.SYSTEM_ADD_PRODUCT';
    @Input() displayDialog: boolean;
    @Input() dropdownSelectLabel: string;
    @Input() dropdownSelectOptions: SelectItem[];
    @Input() selectedDropdownSelectOption: any;

    @Input() showDropdown: boolean;
    @Input() submitButtonDisabled: boolean;
    @Input() submitButtonLabel: string;
    @Input() tableHeader: string;
    @Input() tableOptions: any[] = [];
    @Input() tableOptionNames: string[] = [];
    @Input() tableOptionIcons: string[] = [];
    @Input() tableOptionDescriptions: string[] = [];
    @Input() tableTrackBy: (index: number, item: any) => any;
    @Input() warnings: string[] = [];
    @Input() selectedValueDescription: {property: CatalogProperty, option: CatalogOption}[];
    @Input() selectedValueImage: SafeUrl;
    @Input() tableSelection: any;
    @Input() largeImageGetter: (id: number) => Observable<string>;
    @Input() largeImageId: number;
    @Input() hasSlider: boolean;

    @Output() dialogClosed = new EventEmitter<boolean>();
    @Output() onDropdownSelectionChanged = new EventEmitter<any>();
    @Output() onTableSelectionChanged = new EventEmitter<any>();
    @Output() onSubmit = new EventEmitter<any>();
    @Output() openStorageFile: EventEmitter<number> = new EventEmitter<number>();

    @ViewChild('dialog', {static: true}) dialog: Dialog;
    private escHotkey: Hotkey;
    readonly dialogHideHelper = new OnceFlag();
    showSlider: boolean;
    sliderHeader: string;
    elements: ConfigSliderElement[] = [];

    constructor(public translate: TranslateService,
                private hotkeysService: HotkeysService,
                private openImageInNewBrowserTabController: OpenImageInNewBrowserTabController,
                private changeDetector: ChangeDetectorRef,
                private configSliderElementService: ConfigSliderElementService,
                private blockUiController: BlockUiController) {
        this.initHotKeys();
    }

    private initHotKeys() {
        this.escHotkey = new Hotkey('esc', (): boolean => {
            this.closeWindow(true);
            return false;
        }, ['INPUT', 'SELECT']);
    }

    ngOnDestroy() {
        this.deregisterHotkeys();
    }

    deregisterHotkeys() {
        this.hotkeysService.remove(this.escHotkey);
    }

    registerHotkeys() {
        this.hotkeysService.add(this.escHotkey);
    }

    afterShow() {
        this.dialog.center();
        this.registerHotkeys();
        this.dialogHideHelper.reset();
        this.changeDetector.markForCheck();
    }

    submit(event?) {
        if (event != null && event.data != null) {
            this.tableSelection = event.data;
        }
        this.dialogClosed.emit(false);
    }

    closeWindow(cancel = false) {
        this.dialogHideHelper.call(() => {
            this.deregisterHotkeys();
            this.dialogClosed.emit(cancel);
        });
    }

    handleImageClick() {
        let item = this.tableSelection;
        let name = item['name'] || item['names'] || {};
        let label = name[this.translate.currentLang];
        if (this.hasSlider) {
            this.blockUiController.block("loading slides");
            this.configSliderElementService.getElementsForSystem(this.largeImageId)
                .pipe(finalize(() => this.blockUiController.unblock("loading slides")))
                .subscribe(a => {
                    this.elements = a;
                    this.showSlider = true;
                    this.sliderHeader = label;
                    this.changeDetector.markForCheck();
                });
        } else {
            this.openImageInNewBrowserTabController
                .openInNewBrowserTab(label, () => this.largeImageGetter(this.largeImageId), item['image'], this.selectedValueImage);
        }
    }
}
