import {SupplierInterface} from '../../../../window-designer/catalog-data/supplier-interface';
import {CrudItem, JsonDeserializable} from '../../../common/crud-common/crudItem';
import {ValidationErrors} from '../../../common/validation-errors';
import {MultiValidator} from '../../../shared/validator/input-validator';
import {isValidProfitMarginValueString, normalizeProfitMarginValueString} from '../../subsystem/profit-margin/profitMargin';

@JsonDeserializable
export class SimpleProfitMarginSource implements CrudItem {
    id: number;
    clientGroupId?: number;
    clientId?: number;
    supplierProfitMargins: { [supplierId: number]: string } = {};

    static fromJSON(jsonObject: any): SimpleProfitMarginSource {
        const simpleProfitMarginSource = new SimpleProfitMarginSource();
        simpleProfitMarginSource.id = jsonObject['id'];
        simpleProfitMarginSource.clientGroupId = jsonObject['clientGroupId'];
        simpleProfitMarginSource.clientId = jsonObject['clientId'];
        if (jsonObject['supplierProfitMargins'] != undefined) {
            simpleProfitMarginSource.supplierProfitMargins = Object.assign({}, jsonObject['supplierProfitMargins']);
        }
        return simpleProfitMarginSource;
    }
}

export function validateSimpleProfitMargins(input: SimpleProfitMarginSource, visibleSuppliers: SupplierInterface[],
                                            fieldsAreRequired = true,
                                            validationPrefix = 'simpleProfitMarginSource.'): ValidationErrors {
    const validationErrors: ValidationErrors = {};
    for (let supplier of visibleSuppliers) {
        input.supplierProfitMargins[supplier.id] = normalizeProfitMarginValueString(input.supplierProfitMargins[supplier.id]);
        const validator = MultiValidator.of('error.simpleProfitMarginSourceDto.supplierProfitMargins');
        if (fieldsAreRequired) {
            validator
                .withNotNullValidator()
                .withNotBlankValidator();
        }
        validator
            .withSizeValidator(0, 250)
            .withCustomValidator(value => {
                if (typeof value !== 'string') {
                    return undefined;
                }
                return isValidProfitMarginValueString(value) ? undefined : 'pattern_not_matched';
            });

        validationErrors[validationPrefix + 'supplierProfitMargins[' + supplier.id + ']'] =
            validator.validate(input.supplierProfitMargins[supplier.id]);
    }
    return validationErrors;
}
