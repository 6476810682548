import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, of} from 'rxjs';
import * as _ from 'underscore';
import {AddonInterface} from '../../../../../../window-designer/catalog-data/addon-interface';
import {WindowSystemInterface} from '../../../../../../window-designer/catalog-data/window-system-interface';
import {DrawingData} from '../../../../../../window-designer/drawing-data/drawing-data';
import {ProfilesCompositionDistances} from '../../../../../../window-designer/profiles-composition-distances';
import {Permissions} from '../../../../../auth/permission.service';
import {BankerRounding} from '../../../../../common/BankerRounding';
import {CommonErrorHandler} from '../../../../../common/CommonErrorHandler';
import {CrudItem} from '../../../../../common/crud-common/crudItem';
import {ExchangeService} from "../../../../../common/exchange.service";
import {Currencies} from '../../../../../currencies';
import {MultilanguageField} from '../../../../../supportedLanguages';
import {Color} from '../../../../window-system/color/color';
import {DistanceFrame} from '../../../../window-system/distance-frame/distanceFrame';
import {Glass} from '../../../../window-system/glass/glass';
import {GlazingBead} from '../../../../window-system/glazing-bead/glazing-bead';
import {Grill} from '../../../../window-system/grill/grill';
import {Profile} from '../../../../window-system/profile/profile';
import {Seal} from '../../../../window-system/seal/Seal';
import {ProductTypeGroup} from '../../../../window-system/window-system-definition/product-type-group';
import {GateData} from '../../../gate-editor/gate-data';
import {PriceWithTax} from '../../../offer';
import {MessageSeverity} from '../../../offers/message';
import {DecorativeFillingWithColors} from '../../DecorativeFillingWithColors';
import {ConfigAddonValidator} from '../../drawing-tool/ConfigAddonValidator';
import {ConfigurableAddonUtils} from '../../drawing-tool/ConfigurableAddonUtils';
import {OtherFillingWithColors} from '../../OtherFillingWithColors';
import {EntranceDoorData} from '../../roof-window-editor/entrance-door-data';
import {RoofWindowData} from '../../roof-window-editor/RoofWindowData';
import {WindowEditorOfferData, WindowEditorProductionOrderData} from '../../window-editor-offer-interfaces';
import {Charge, ChargeType} from './Charge';
import {ConfigurableAddonPositionModel} from './config-addon-pricing/ConfigurableAddonPositionModel';
import {GroupedProductCharge} from './grouped-product-charge';
import {Pricing} from './Pricing';
import {PricingService} from './pricing.service';
import {CatalogItemType} from './PricingItem';
import {PricingResult} from './PricingResult';
import {PricingWithModel} from './PricingWithModel';
import {Product} from './Product';
import {ConfigurableAddon} from "../../../../../../window-designer/entities/ConfigurableAddon";
import {ConfigSystem} from "../../../../window-system/config-system/config-system";

interface Prices {
    venskaBuyPrice: PriceWithTax;
    buyPrice: PriceWithTax;
    sellPrice: PriceWithTax;
    retailSellPrice: PriceWithTax;
}

@Component({
    selector: 'app-pricing',
    templateUrl: './pricing.component.html',
    styleUrls: ['./pricing.component.css', '../../../../shared-styles.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [PricingService]
})
export class PricingComponent implements OnChanges {

    @Input()
    offer: WindowEditorOfferData;

    @Input()
    productionOrder: WindowEditorProductionOrderData;

    @Input()
    pricing: Pricing = new Pricing();

    @Output()
    refreshValidationPricingStatuses = new EventEmitter<Pricing>();

    @Output()
    setErrorToPricingStatus = new EventEmitter<void>();

    @Input()
    drawingData: DrawingData;

    @Input()
    windowSystem: WindowSystemInterface;

    @Input()
    colors: Color[];

    @Input()
    internalSeals: Seal[];

    @Input()
    externalSeals: Seal[];

    @Input()
    fillings: OtherFillingWithColors[];

    @Input()
    profiles: Profile[];

    @Input()
    glasses: Glass[];

    @Input()
    distanceFrames: DistanceFrame[];

    @Input()
    grills: Grill[];

    @Input()
    addons: AddonInterface[];

    @Input()
    glazingBeads: GlazingBead[];

    @Input()
    decorativeFillings: DecorativeFillingWithColors[];

    @Input()
    readOnlyMode: boolean;

    @Input()
    windowSystemTypeGroup: ProductTypeGroup;

    @Input()
    isGate = false;

    @Input()
    isConfig = false;

    validationErrorPresent = false;
    pricingErrorPresent = false;
    errorOccured = false;
    chargesPerProduct: Map<number, GroupedProductCharge[]>;
    private chargesMap: Map<ChargeType, ComponentInfo>;
    configAddonPricingsAndModels: PricingWithModel[] = [];
    missingPricingForConfigAddon = false;

    constructor(private pricingService: PricingService,
                public translate: TranslateService,
                private cd: ChangeDetectorRef,
                public permissions: Permissions,
                private errors: CommonErrorHandler) {
        translate.onLangChange.subscribe(() => this.cd.markForCheck());
        this.chargesPerProduct =
            !this.chargesPerProduct ? new Map<number, GroupedProductCharge[]>() : this.chargesPerProduct;
    }

    refresh(data: DrawingData, offerPositionId: number, configAddonModels: ConfigurableAddonPositionModel[],
            profileCompositionDistances: ProfilesCompositionDistances, skipValidation: boolean,
            configAddonDefinitions: ConfigSystem[], grills: Grill[],
            validationDisabled: boolean): void {
        let validationErrors = profileCompositionDistances.validateForErrors(data);
        validationErrors = validationErrors.concat(ConfigAddonValidator.validateConfigAddonsForErrors(data, configAddonModels,
            configAddonDefinitions, grills));
        this.validationErrorPresent = validationErrors.length > 0;
        if (!this.validationErrorPresent) {
            ConfigurableAddonUtils.resizeAll(data, configAddonModels, profileCompositionDistances, skipValidation);
            ConfigurableAddonUtils.changeGlazingBeads(data, configAddonModels);
            this.getPricing(data, offerPositionId, configAddonModels, validationDisabled, profileCompositionDistances);
        }
        this.cd.markForCheck();
    }

    getPricing(data: DrawingData | RoofWindowData | EntranceDoorData | GateData | ConfigurableAddon, offerPositionId: number,
        configAddonModels: ConfigurableAddonPositionModel[] = [], validationDisabled = false,
        profileCompositionDistances: ProfilesCompositionDistances = null): void {
        configAddonModels = configAddonModels.filter(model => model.configurableAddon);
        let configAddons = configAddonModels.map(model => model.configurableAddon);
        let configValidationDisabled = configAddonModels.map(model => model.position.validationDisabled);
        let oldPricing = this.pricing;
        this.pricing = null;
        this.errorOccured = false;
        let pricingObservable: Observable<Pricing>;
        let configAddonObservable: Observable<PricingResult[]>;
        if (this.productionOrder) {
            pricingObservable = this.pricingService.getSaveProdOrderPricing(offerPositionId);
            configAddonObservable = of([]);
        } else {
            if (this.windowSystemTypeGroup === ProductTypeGroup.ROOF) {
                pricingObservable = this.pricingService.evaluateRoofWindow(this.readOnlyMode, data as RoofWindowData, this.offer.id,
                    offerPositionId, false, validationDisabled);
                configAddonObservable = of([]);
            } else if (this.windowSystemTypeGroup === ProductTypeGroup.ENTRANCE) {
                pricingObservable = this.pricingService.evaluateEntranceDoor(this.readOnlyMode, data as EntranceDoorData,
                    this.offer.id, offerPositionId, false, validationDisabled);
                configAddonObservable = of([]);
            } else if (this.isGate) {
                pricingObservable = this.pricingService.evaluateGate(this.readOnlyMode, data as GateData,
                    this.offer.id, offerPositionId, false, validationDisabled);
                configAddonObservable = of([]);
            } else if (this.isConfig) {
                pricingObservable = this.pricingService.evaluateConfig(this.readOnlyMode, data as ConfigurableAddon,
                    this.offer.id, offerPositionId, false, validationDisabled);
                configAddonObservable = of([]);
            } else {
                pricingObservable = this.pricingService.evaluate(this.readOnlyMode, data as DrawingData, profileCompositionDistances,
                    this.offer.id, offerPositionId, this.windowSystem, false, false,
                    _.object(configAddonModels.map(ca => ca.position.id ? ca.position.id : ca.position.assignedId),
                        configAddonModels.map(ca => ca.configurableAddon.definitionId)), validationDisabled);
                configAddonObservable =
                    configAddons.length > 0 ?
                        this.pricingService.evaluateAddons(this.offer.id, configAddons, configValidationDisabled) :
                        of([]);
            }
        }
        forkJoin({
            mainPricing: pricingObservable,
            configAddons: configAddonObservable
        }).subscribe({
            next: pricingResults => {
                console.info('PricingService `evaluate` success:');
                let pricing = pricingResults.mainPricing;
                this.pricingErrorPresent = Pricing.containsForPricing(pricing, MessageSeverity.ERROR) || Pricing.containsForPricing(pricing, MessageSeverity.BLOCKER);
                this.validationErrorPresent = Pricing.containsForValidation(pricing, MessageSeverity.ERROR) || Pricing.containsForValidation(pricing, MessageSeverity.BLOCKER);
                this.pricing = pricing;
                this.ensureGeneralChargesProductExists();
                this.configAddonPricingsAndModels =
                    this.pricingService.joinPricingWithModels(pricingResults.configAddons, configAddonModels);
                this.missingPricingForConfigAddon = this.configAddonPricingsAndModels.some(item => item.pricingResult.containsError);
                this.refreshValidationPricingStatuses.emit(pricing);
                this.groupProfileCharges();
                this.setChargeComponentsMap();
                if (this.missingPricingForConfigAddon) {
                    this.setErrorToPricingStatus.emit();
                }
                this.cd.markForCheck();
            },
            error: error => {
                this.errors.handle(error);
                this.errorOccured = true;
                this.pricing = oldPricing;
                this.setErrorToPricingStatus.emit();
                this.cd.markForCheck();
            }
        });
    }

    private groupProfileCharges(): void {
        const generalCharges = this.getGeneralChargesProduct();
        let profileCharges = generalCharges.components.filter(
            comp => comp.type === ChargeType.PROFILE_CHARGE);
        let profileChargesGrouped = _.groupBy(profileCharges, item => item.name.pl);
        let summedProfileCharges = _.values(profileChargesGrouped).map(group => {
            return group.reduce((total, item) => {
                if (!total) {
                    return item;
                }
                let venskaBuyPrice = null;
                if (total.venskaBuyPrice != undefined) {
                    venskaBuyPrice = PriceWithTax.add(total.venskaBuyPrice, item.venskaBuyPrice);
                }
                return {
                    ...total,
                    buyPrice: PriceWithTax.add(total.buyPrice, item.buyPrice),
                    sellPrice: PriceWithTax.add(total.sellPrice, item.sellPrice),
                    venskaBuyPrice
                };
            }, null);
        });

        generalCharges.components =
            [...generalCharges.components.filter(comp => comp.type !== ChargeType.PROFILE_CHARGE),
                ...summedProfileCharges];
    }

    ngOnChanges(changes: SimpleChanges): void {
        const pricingChange = changes['pricing'];
        if (pricingChange != undefined) {
            if (pricingChange.currentValue != undefined) {
                this.pricingErrorPresent = Pricing.containsForPricing(pricingChange.currentValue, MessageSeverity.ERROR) || Pricing.containsForPricing(pricingChange.currentValue, MessageSeverity.BLOCKER);
                this.validationErrorPresent = Pricing.containsForValidation(pricingChange.currentValue, MessageSeverity.ERROR) || Pricing.containsForValidation(pricingChange.currentValue, MessageSeverity.BLOCKER);
                this.ensureGeneralChargesProductExists();
            } else {
                this.validationErrorPresent = false;
                this.pricingErrorPresent = false;
            }
        }
    }

    private setChargeComponentsMap(): void {
        this.buildChargesMap();
        this.findAndRemoveCommonAddonChargesInSubwindows();
        const products: Product[] = this.pricing ? this.pricing.products : [];
        this.chargesPerProduct = new Map<number, GroupedProductCharge[]>();

        products.forEach((product, index) => {
            const flatComponents: Charge[] = this.getFlatComponents(product.components);
            this.chargesPerProduct.set(index, this.groupAndCountComponents(flatComponents, product));
        });
    }

    private buildChargesMap(): void {
        this.chargesMap = new Map<ChargeType, ComponentInfo>();
        this.pricing.products.forEach(product => {
            product.components.forEach(comp => {
                this.getLeafComponents(comp, product);
            });
        });
    }

    private findAndRemoveCommonAddonChargesInSubwindows(): void {
        if (this.chargesMap.get(ChargeType.SUBWINDOW_CHARGE) != null &&
            this.chargesMap.get(ChargeType.SUBWINDOW_CHARGE).occurenceNumber > 1) {
            Array.from(this.chargesMap.entries()).forEach(mapEntry => {
                if (mapEntry[1].match && this.isChargeTypeDedicatedForAddons(mapEntry[0], false)) {
                    this.pricing.products.forEach(product => {
                        this.lookForLeafToRemove(product, mapEntry[0], this.getGeneralChargesProduct(), true);
                    });
                }
            });
        }
    }

    private getLeafComponents(component: Charge, parentComponent: Product | Charge): void {
        this.addToTreeMap(component, parentComponent);
        component.components.forEach(comp => {
            this.getLeafComponents(comp, component);
        });
    }

    private lookForLeafToRemove(component: Product | Charge, chargeTypeToRemove: ChargeType, generalComponent: Product,
                                mustBePresentOnAllSubwindows: boolean): void {
        if (component.components.length > 0) {
            let leafsToRemove = component.components.filter(comp => comp.type === chargeTypeToRemove);
            if (leafsToRemove.length > 0 &&
                (!mustBePresentOnAllSubwindows || this.isAddonOnEachSubWindow(chargeTypeToRemove))) {
                leafsToRemove.forEach(leafToRemove =>
                    this.removeLeafAndUpdatePricingStructure(leafToRemove, component, chargeTypeToRemove,
                        generalComponent));
            }
            component.components.forEach(comp => {
                this.lookForLeafToRemove(comp, chargeTypeToRemove, generalComponent, mustBePresentOnAllSubwindows);
            });
        }
    }

    private removeLeafAndUpdatePricingStructure(leafToRemove: Charge, component: Product | Charge,
                                                chargeTypeToRemove: ChargeType,
                                                generalComponent: Product): void {
        if (leafToRemove.components.length === 0) {
            if (generalComponent.components.some(postion => postion.type === chargeTypeToRemove)) {
                let componentToUpdate = generalComponent.components.find(
                    postion => postion.type === chargeTypeToRemove);
                this.updateExistingComponentPricing(componentToUpdate, leafToRemove);
            } else {
                generalComponent.components.push(leafToRemove);
            }
        } else {
            console.error('error - attempt to remove a component that is not a leaf');
        }

        this.removeLeafFromStructure(component, leafToRemove);
    }

    private removeLeafFromStructure(component: Product | Charge, leafToRemove: Charge): void {
        let index = component.components.indexOf(leafToRemove, 0);
        if (index > -1) {
            component.components.splice(index, 1);
        }
    }

    private updateExistingComponentPricing(componentToUpdate: Product | Charge, leafToRemove: Charge): void {
        componentToUpdate.venskaBuyPrice =
            this.sumPrices(componentToUpdate.venskaBuyPrice, leafToRemove.venskaBuyPrice);
        componentToUpdate.buyPrice = this.sumPrices(componentToUpdate.buyPrice, leafToRemove.buyPrice);
        componentToUpdate.sellPrice = this.sumPrices(componentToUpdate.sellPrice, leafToRemove.sellPrice);
    }

    private isAddonOnEachSubWindow(chargeTypeToRemove: ChargeType): boolean {
        return _.uniq(this.chargesMap.get(chargeTypeToRemove).parents).length ===
            this.chargesMap.get(ChargeType.SUBWINDOW_CHARGE).occurenceNumber;
    }

    private sumPrices(firstPrice: PriceWithTax, secondPrice: PriceWithTax): PriceWithTax {
        if (firstPrice == null || secondPrice == null) {
            return null;
        }
        return new PriceWithTax(BankerRounding.evenRound(firstPrice.netValue += secondPrice.netValue, 2),
            firstPrice.vatPercent);
    }

    private addToTreeMap(component: Charge, parentComponent: Product | Charge): void {
        if (this.chargesMap.has(component.type)) {
            let value = this.chargesMap.get(component.type);
            if ((value.componentName == undefined || component.name == undefined) ||
                (value.componentName[this.translate.currentLang] !== component.name[this.translate.currentLang])) {
                value.match = false;
            }
            value.occurenceNumber += 1;
            value.quantity += component.quantity;
            value.parents.push(parentComponent);
        } else {
            this.chargesMap.set(component.type,
                new ComponentInfo(true, 1, component.name, [parentComponent], component.quantity));
        }
    }

    private getFlatComponents(components: Charge[] = []): Charge[] {
        let flattenedComponents: Charge[] = [];
        components.forEach(component => {
            if (component.components && component.components.length > 0) {
                flattenedComponents = flattenedComponents.concat(this.getFlatComponents(component.components));
            } else {
                flattenedComponents = flattenedComponents.concat(component);
            }
        });
        return flattenedComponents;
    }

    private groupAndCountComponents(components: Charge[], product: Product): GroupedProductCharge[] {
        let groupedComponents: GroupedProductCharge[] = [];
        components.filter(component => this.isChargeVisible(component)).forEach(component => {
            let groupedCharge = groupedComponents.find(groupedComponent =>
                groupedComponent.isChargeEqual(component, this.translate));
            if (groupedCharge != undefined) {
                ++groupedCharge.count;
            } else {
                groupedComponents.push(new GroupedProductCharge(component, 1));
            }
        });

        return groupedComponents;
    }

    protected isChargeTypeDedicatedForAddons(chargeType: ChargeType, includeSidebar = true): boolean {
        let result = chargeType === ChargeType.DRIP_ADDON_CHARGE || chargeType === ChargeType.HANDLE_ADDON_CHARGE
            || chargeType === ChargeType.VENTILATOR_ADDON_CHARGE || chargeType === ChargeType.COUPLER_ADDON_CHARGE
            || chargeType === ChargeType.COVER_ADDON_CHARGE;
        return includeSidebar ? result || chargeType === ChargeType.SIDEBAR_ADDON_CHARGE : result;
    }

    shouldShowPricingDetails(product: Product): boolean {
        if (product.messages != null) {
            if (product.messages.length === 0) {
                return true;
            }
            let validationMessages = product.messages.filter(msg => msg.validationMessage);
            return validationMessages.length === product.messages.length;
        }
        return true;
    }

    formatPriceInOfferCurrency(prices: Prices, chargeCount = 1): string {
        if (prices.venskaBuyPrice != undefined) {
            // venska always PLN
            return this.formatPrice(prices.venskaBuyPrice.netValue, prices.buyPrice.netValue, Currencies.PLN, chargeCount);
        }
        if (prices.buyPrice != undefined) {
            return this.formatPrice(prices.buyPrice.netValue, prices.sellPrice.netValue, this.offer.currency, chargeCount);
        }
        if (this.permissions.isSprzedawca()) {
            return this.formatPrice(prices.sellPrice.netValue, prices.retailSellPrice.netValue, this.offer.currency, chargeCount);
        }
        return this.formatPrice(prices.sellPrice.netValue, prices.sellPrice.grossValue, this.offer.currency, chargeCount);
    }

    private formatPrice(val1: number, val2: number, currency: Currencies, valuesMultiplier: number) {
        return ExchangeService.formatPriceInCurrency(this.getValueInCurrency(val1 * valuesMultiplier, currency) + ' / '
            + this.getValueInCurrency(val2 * valuesMultiplier, currency, true), currency);
    }

    private getValueInCurrency(value: number, currency: Currencies, applySubsystemManualExchangeRate = false): string {
        if (currency !== Currencies[Currencies.PLN]) {
            if (applySubsystemManualExchangeRate && this.offer.subsystemManualExchangeRate != undefined) {
                return (value / this.offer.subsystemManualExchangeRate).toFixed(2);
            }
            return (value / this.offer.exchangeRate).toFixed(2);
        }
        return value.toFixed(2);
    }

    private isChargeVisible(charge: Charge): boolean {
        return (charge.venskaBuyPrice == undefined || charge.venskaBuyPrice.netValue !== 0)
            && (charge.buyPrice == undefined || charge.buyPrice.netValue !== 0)
            && charge.sellPrice.netValue !== 0
            && charge.quantity !== 0;
    }

    formatChargeQuantityFull(component: GroupedProductCharge): string {
        if (component.count === 1 && component.charge.quantity !== 1) {
            return this.formatChargeQuantity(component.charge.quantity);
        }
        if (component.count !== 1 || component.charge.quantity !== 1) {
            return component.count + ' ' + this.formatChargeQuantity(component.charge.quantity);
        }
    }

    formatChargeQuantity(quantity: number): string {
        return `x ${(Math.round(quantity * 1000) / 1000)}`;
    }

    chargeTypeHasNameInPricing(type: ChargeType): boolean {
        switch (type) {
            case ChargeType.WINDOW_PRICING:
            case ChargeType.ROOF_WINDOW_PRICING:
            case ChargeType.CONFIG_ADDON_CHARGE:
            case ChargeType.BULK_CONFIG_ADDON_CHARGE:
                return false;
            default:
                break;
        }
        return true;
    }

    getChargeName(charge: Charge): string {
        return charge.name != undefined && charge.name[this.translate.currentLang] != undefined
            ? charge.name[this.translate.currentLang]
            : this.getChargeNameFallback(charge);
    }

    private ensureGeneralChargesProductExists(): void {
        let generalChargesProduct = this.getGeneralChargesProduct();
        if (generalChargesProduct != undefined) {
            return;
        }
        generalChargesProduct = new Product();
        generalChargesProduct.generatedId = Product.GENERAL_ADDONS_GENERATED_ID;
        generalChargesProduct.positionName = new MultilanguageField();
        generalChargesProduct.dimension = undefined;
        if (this.pricing.venskaBuyPrice != undefined) {
            generalChargesProduct.venskaBuyPrice = new PriceWithTax(0, this.pricing.venskaBuyPrice.vatPercent);
        }
        if (this.pricing.buyPrice != undefined) {
            generalChargesProduct.buyPrice = new PriceWithTax(0, this.pricing.buyPrice.vatPercent);
        }
        if (this.pricing.sellPrice != undefined) {
            generalChargesProduct.sellPrice = new PriceWithTax(0, this.pricing.sellPrice.vatPercent);
        }
        if (this.pricing.retailSellPrice != undefined) {
            generalChargesProduct.retailSellPrice = new PriceWithTax(0, this.pricing.retailSellPrice.vatPercent);
        }
        generalChargesProduct.components = [];
        generalChargesProduct.messages = [];
        this.pricing.products.unshift(generalChargesProduct);
    }

    private getGeneralChargesProduct(): Product {
        return this.pricing.products.find(product => product.generatedId === Product.GENERAL_ADDONS_GENERATED_ID);
    }

    private getChargeNameFallback(charge: Charge): string {
        switch (charge.type) {
            case ChargeType.INSIDE_COLOR_CHARGE:
                return this.getChargeCatalogName(this.colors, this.drawingData.specification.colorIdInternal, 'names',
                    charge.type);
            case ChargeType.OUTSIDE_COLOR_CHARGE:
                return this.getChargeCatalogName(this.colors, this.drawingData.specification.colorIdExternal, 'names',
                    charge.type);
            case ChargeType.CORE_COLOR_CHARGE:
                return this.getChargeCatalogName(this.colors, this.drawingData.specification.colorIdCore, 'names',
                    charge.type);
            case ChargeType.INNER_SEAL_CHARGE:
                return this.getChargeCatalogName(this.internalSeals, this.drawingData.specification.sealInternalId,
                    'names', charge.type);
            case ChargeType.OUTER_SEAL_CHARGE:
                return this.getChargeCatalogName(this.externalSeals, this.drawingData.specification.sealExternalId,
                    'names', charge.type);
            case ChargeType.FILLING_CHARGE:
                return this.getChargeCatalogName(this.fillings,
                    Charge.findRelatedItemId(charge, CatalogItemType.OTHER_FILLING), 'name', charge.type);
            case ChargeType.MULLION_CHARGE:
                return this.getChargeCatalogName(this.profiles,
                    Charge.findRelatedItemId(charge, CatalogItemType.PROFILE_MULLION), 'names', charge.type);
            case ChargeType.GLASS_CHARGE:
                return this.getChargeCatalogName(this.glasses, Charge.findRelatedItemId(charge, CatalogItemType.GLASS),
                    'name', charge.type);
            case ChargeType.DISTANCE_FRAME_CHARGE:
                return this.getChargeCatalogName(this.distanceFrames,
                    Charge.findRelatedItemId(charge, CatalogItemType.DISTANCE_FRAME), 'names', charge.type);
            case ChargeType.MUNTIN_CHARGE:
                return this.getChargeCatalogName(this.grills, Charge.findRelatedItemId(charge, CatalogItemType.GRILL),
                    'name', charge.type);
            case ChargeType.BULK_ADDON_CHARGE:
            case ChargeType.WINDOW_ADDON_CHARGE:
            case ChargeType.WING_ADDON_CHARGE:
            case ChargeType.QUARTER_ADDON_CHARGE:
            case ChargeType.GLAZIERY_ADDON_CHARGE:
            case ChargeType.AREA_ADDON_CHARGE:
            case ChargeType.SIDEBAR_ADDON_CHARGE:
            case ChargeType.FITTING_CHARGE:
            case ChargeType.VENTILATOR_ADDON_CHARGE:
            case ChargeType.DRIP_ADDON_CHARGE:
            case ChargeType.HANDLE_ADDON_CHARGE:
            case ChargeType.COUPLER_ADDON_CHARGE:
            case ChargeType.COVER_ADDON_CHARGE:
                return this.getChargeCatalogName(this.addons, Charge.findRelatedItemId(charge, CatalogItemType.ADDON),
                    'name', charge.type);
            case ChargeType.PROFILE_CHARGE:
                return this.getChargeCatalogName(this.profiles,
                    Charge.findRelatedItemId(charge, CatalogItemType.PROFILE), 'names', charge.type);
            case ChargeType.GLAZING_BEAD_CHARGE:
                return this.getChargeCatalogName(this.glazingBeads,
                    Charge.findRelatedItemId(charge, CatalogItemType.GLAZING_BEAD), 'names', charge.type);
            case ChargeType.DECORATIVE_FILLING_CHARGE:
                return this.getChargeCatalogName(this.decorativeFillings,
                    Charge.findRelatedItemId(charge, CatalogItemType.DECORATIVE_FILLING), 'names', charge.type);
            default:
                break;
        }
        return `OFFER.TABS.PRICING.COMPONENT.TYPE.${charge.type}`;
    }

    private getChargeCatalogName<T extends CrudItem, K extends keyof T>(items: T[],
                                                                        id: number,
                                                                        nameKey: K,
                                                                        chargeType: ChargeType): string {
        const found = items.find(item => item.id === id);
        return found != undefined ? found[nameKey][this.translate.currentLang] :
            `OFFER.TABS.PRICING.COMPONENT.TYPE.${chargeType}`;
    }
}

export class ComponentInfo {
    constructor(public match?: boolean,
                public occurenceNumber?: number,
                public componentName?: MultilanguageField,
                public parents = [],
                public quantity?: number) {
    }
}
