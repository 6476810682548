<p-dialog *ngIf="dataReady" [modal]="true" (onHide)="resetDialog()" [resizable]="false" [visible]="true" [focusOnShow]="false">
    <p-header>
        <div class="p-dialog-title">
            {{ 'OFFER.POSITIONS.DIALOGS.BULK_GENERAL_CHANGE.HEADER' | translate }}
        </div>
    </p-header>
    <div class="new-form-600 scrolled-dialog">
        <!--Profile-->
        <div class="new-form-row">
            <app-select inputId="profile" label="{{ 'OFFER.TABS.SECTION.MAIN.PROFILE' | translate }}"
                        [(validationMessageKey)]="validationErrors['profile']" [options]="profiles"
                        [(ngModel)]="chosenProfile"></app-select>
        </div>
        <!-- Weld type -->
        <div class="new-form-row" *ngIf="availableWeldTypes.length > 0">
            <app-select inputId="weldType" label="{{ 'OFFER.TABS.SECTION.MAIN.WELD_TYPE' | translate }}"
                        [(validationMessageKey)]="validationErrors['weldType']" [options]="availableWeldTypes"
                        [(ngModel)]="chosenWeldType"></app-select>
        </div>
        <!-- Under Window Bead -->
        <div class="new-form-row" *ngIf="availableUnderWindowBeads.length > 0">
            <app-select inputId="milling" label="{{ 'OFFER.TABS.SECTION.UNDER_WINDOW_BEAD' | translate }}"
                        [(validationMessageKey)]="validationErrors['underWindowBead']" [options]="availableUnderWindowBeads"
                        [(ngModel)]="chosenUnderWindowBead"></app-select>
        </div>
        <!-- Frame Reinforcement -->
        <div class="new-form-row">
            <app-select inputId="enhancement" label="{{ 'OFFER.TABS.SECTION.MAIN.ENHANCEMENT' | translate }}"
                        [(validationMessageKey)]="validationErrors['enhancement']" [options]="enhancements"
                        [(ngModel)]="chosenEnhancement"></app-select>
        </div>
        <!-- Covers -->
        <div class="new-form-row" *ngIf="covers.length > 0">
            <app-select inputId="cover" label="{{ 'OFFER.TABS.SECTION.MAIN.COVERS' | translate }}"
                        [(validationMessageKey)]="validationErrors['cover']" [options]="covers"
                        [(ngModel)]="chosenCover"></app-select>
        </div>
        <!-- Millings -->
        <div class="new-form-row" *ngIf="availableMillings.length > 0">
            <app-select inputId="milling" label="{{ 'OFFER.TABS.SECTION.MILLING' | translate }}"
                        [(validationMessageKey)]="validationErrors['milling']" [options]="availableMillings"
                        [(ngModel)]="chosenMilling"></app-select>
        </div>
        <div class="new-form-row" *ngIf="availableMillingsNorwegian.length > 0">
            <app-select inputId="milling" label="{{ 'OFFER.TABS.SECTION.MAIN.MILLING_NORWEGIAN' | translate }}"
                        [(validationMessageKey)]="validationErrors['millingNorwegian']" [options]="availableMillingsNorwegian"
                        [(ngModel)]="chosenMillingNorwegian"></app-select>
        </div>
        <!-- Handle -->
        <div class="new-form-row" *ngIf="availableHandles.length > 0">
            <app-select inputId="handle" label="{{ 'OFFER.TABS.SECTION.MAIN.HANDLES' | translate }}"
                        [(validationMessageKey)]="validationErrors['handle']" [options]="availableHandles"
                        [(ngModel)]="chosenHandle"></app-select>
        </div>
        <!-- Terrace handle -->
        <ng-container *ngIf="availableTerraceHandles?.length > 0">
            <div class="new-form-row">
                <app-select inputId="terraceHandle" label="{{ 'OFFER.TABS.SECTION.MAIN.TERRACE_HANDLES' | translate }}"
                            [(validationMessageKey)]="validationErrors['terraceHandle']" [options]="availableTerraceHandles"
                            [(ngModel)]="chosenTerraceHandle"></app-select>
            </div>
            <div class="new-form-row">
                <app-select inputId="terraceHandleLayout"
                            label="{{ 'OFFER.TABS.SECTION.MAIN.TERRACE_HANDLE_LAYOUT' | translate }}"
                            [(validationMessageKey)]="validationErrors['terraceHandleLayout']"
                            [options]="availableTerraceHandleLayouts" [translateLabels]="true"
                            [(ngModel)]="chosenTerraceHandleLayout"></app-select>
            </div>
        </ng-container>
        <!-- Doorsteps -->
        <div class="new-form-row" *ngIf="availableDoorsteps.length > 0">
            <app-select inputId="doorstep" label="{{ 'OFFER.TABS.SECTION.MAIN.DOORSTEPS' | translate }}"
                        [(validationMessageKey)]="validationErrors['doorstep']" [options]="availableDoorsteps"
                        [(ngModel)]="chosenDoorsteps"></app-select>
        </div>
        <!-- Channel sections -->
        <div class="new-form-row" *ngIf="availableChannelSections.length > 0">
            <app-select inputId="channelSection" label="{{ 'OFFER.TABS.SECTION.MAIN.CHANNEL_SECTIONS' | translate }}"
                        [(validationMessageKey)]="validationErrors['channelSection']" [options]="availableChannelSections"
                        [(ngModel)]="chosenChannelSection"></app-select>
        </div>
        <!-- Constructional mullions -->
        <div class="new-form-row" *ngIf="availableConstructionalMullions.length > 0">
            <app-select inputId="constructionalMullion" label="{{ 'OFFER.TABS.SECTION.MAIN.CONSTRUCTIONAL_MULLION' | translate }}"
                        [(validationMessageKey)]="validationErrors['constructionalMullion']" [options]="availableConstructionalMullions"
                        [(ngModel)]="chosenConstructionalMullion"></app-select>
        </div>
        <!-- Movable posts -->
        <div class="new-form-row" *ngIf="availableMovablePosts.length > 0">
            <app-select inputId="movablePost" label="{{ 'OFFER.TABS.SECTION.MAIN.MOVABLE_POST' | translate }}"
                        [(validationMessageKey)]="validationErrors['movablePost']" [options]="availableMovablePosts"
                        [(ngModel)]="chosenMovablePost"></app-select>
        </div>
        <!-- Under window Profile -->
        <div class="new-form-row" *ngIf="underWindowProfiles.length > 0">
            <app-select inputId="underwindowProfile"
                        label="{{ 'OFFER.TABS.SECTION.MAIN.UNDER_WINDOW_PROFILE' | translate }}"
                        [(validationMessageKey)]="validationErrors['underWindowProfile']"
                        [options]="underWindowProfiles" [(ngModel)]="underWindowProfile"></app-select>
        </div>
        <!-- DoorOpening -->
        <div class="new-form-row">
            <app-select inputId="opening" label="{{ 'OFFER.OPENING.SECTION_TITLE' | translate }}"
                        [(validationMessageKey)]="validationErrors['opening']" [options]="doorOpening"
                        [translateLabels]="true" [(ngModel)]="chosenOpening"></app-select>
        </div>
        <!-- external seal -->
        <div class="new-form-row" *ngIf="externalSeals.length > 0">
            <app-select inputId="externalSeal" label="{{ 'OFFER.TABS.SECTION.MAIN.SEAL_EXTERNAL_COLOR' | translate }}"
                        [(validationMessageKey)]="validationErrors['externalSeal']" [options]="externalSeals"
                        [(ngModel)]="chosenExternalSeal"></app-select>
        </div>
        <!-- internal seal -->
        <div class="new-form-row" *ngIf="internalSeals.length > 0">
            <app-select inputId="internalSeal" label="{{ 'OFFER.TABS.SECTION.MAIN.SEAL_INTERNAL_COLOR' | translate }}"
                        [(validationMessageKey)]="validationErrors['internalSeal']" [options]="internalSeals"
                        [(ngModel)]="chosenInternalSeal"></app-select>
        </div>
    </div>
    <p-footer>
        <div class="p-dialog-buttonpane">
            <app-simple-button label="{{ 'GENERAL.SAVE' | translate }}" type="main-action" [size]="40"
                               [disabled]="submitInProgress" (onClick)="submitDialog()"></app-simple-button>
            <app-simple-button label="{{ 'GENERAL.CANCEL' | translate }}" type="cancel" [size]="40"
                               (onClick)="resetDialog()"></app-simple-button>
        </div>
    </p-footer>
</p-dialog>
