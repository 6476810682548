import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {SizeUnitsConverter} from "../../../../window-designer/utils/SizeUnitsConverter";
import {PositionEditorFieldContentProvider} from '../window-editor/position-editor-field-content-provider';
import {ConfigSystemService} from "../../window-system/config-system/config-system.service";
import {ConfigSystem} from "../../window-system/config-system/config-system";
import {ConfigData} from "./config-data";
import {ConfigEditorField} from "./config-editor-field";
import {AddonCategoryGroup} from "../../window-system/addon-category-group/addon-category-group";
import {ConfigSystemDefaults} from "../../settings/config-system-defaults/config-system-defaults";
import * as _ from "underscore";
import {AddonCategoryGroupService} from "../../window-system/addon-category-group/addon-category-group.service";
import {ColorService} from "../../window-system/color/color.service";
import {MaterialService} from "../../window-system/material/material.service";
import {Color} from "../../window-system/color/color";
import {Material} from "../../window-system/material/material";
import {ConfigAddonApplication} from "../../../../window-designer/enums/ConfigAddonApplication";
import {ConfigDesignerCatalogDependentOption} from "../../window-system/config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option";
import {ConfigDesignerCatalogDependentOptionService} from "../../window-system/config-designer-catalog-dependent-option/data-form/config-designer-catalog-dependent-option.service";
import {MultilanguageFieldInterface} from "../../../../window-designer/catalog-data/multilanguage-field-interface";
import {TranslateService} from "@ngx-translate/core";

export interface CatalogLoadResult {
    colors: Color[];
    materials: Material[];
    addonCategoryGroups: AddonCategoryGroup[];
    fieldDependencies: ConfigDesignerCatalogDependentOption[];
}

export type CatalogLoadObservables = { [K in keyof CatalogLoadResult]: Observable<CatalogLoadResult[K]>; };

type ConfigDataKey = 'materialId' | 'colorId';

@Injectable()
export class ConfigEditorFieldContentProvider extends PositionEditorFieldContentProvider {

    constructor(private readonly configSystemService: ConfigSystemService,
                private readonly addonCategoryGroupService: AddonCategoryGroupService,
                private readonly colorService: ColorService,
                private readonly materialService: MaterialService,
                private readonly dependentOptionService: ConfigDesignerCatalogDependentOptionService,
                private readonly translate: TranslateService) {
        super(Object.keys(ConfigEditorField));
    }

    loadConfigSystems(applicableTo?: ConfigAddonApplication[], systemIds?: number[], selectedSystemId?: number): Observable<ConfigSystem[]> {
        let filters: any = {
            withImages: {value: true},
            active: {value: true},
        };
        if (applicableTo) {
            filters.applicableTo = {value: applicableTo};
        }
        if (systemIds) {
            filters.systemIds = {value: systemIds};
        }
        if (selectedSystemId) {
            filters.selectedSystemId = {value: selectedSystemId};
        }
        return this.configSystemService.getItems(undefined, undefined, filters, undefined, undefined)
            .pipe(map(listing => listing.data));
    }

    getCatalogDataSource(configSystemId: number, data: ConfigSystemDefaults, readOnly: boolean): CatalogLoadObservables {
        if (configSystemId == undefined) {
            return {
                addonCategoryGroups: of<AddonCategoryGroup[]>([]),
                colors: of<Color[]>([]),
                materials: of<Material[]>([]),
                fieldDependencies: of<ConfigDesignerCatalogDependentOption[]>([]),
            };
        }
        return {
            addonCategoryGroups: this.addonCategoryGroupService.getGroupsForConfigDeep(configSystemId, this.extractSelectedAddons(data), true),
            colors: this.colorService.getColorsForConfigSystem(configSystemId, this.extractSelectedId(data, 'colorId'), readOnly),
            materials: this.materialService.getMaterialsForConfigSystem(configSystemId, this.extractSelectedId(data, 'materialId'), readOnly),
            fieldDependencies: this.dependentOptionService.getItems(undefined, undefined, {configSystemId: {value: configSystemId}, activeSet: {value: 'true'}},
                undefined, undefined).pipe(map(listing => listing.data)),
        };
    }

    initCatalog(catalogData: CatalogLoadResult): void {
        this.storeMaterials(catalogData.materials);
        this.storeColors(catalogData.colors);
        this.storeAddons(catalogData.addonCategoryGroups);
        this.storeFieldDependencies(catalogData.fieldDependencies);
    }

    storeAddons(addonCategoryGroups: AddonCategoryGroup[]): void {
        // clear addon items
        for (let key of this.items.keys()) {
            const isPredefinedField = Object.keys(ConfigEditorField).includes(key);
            if (isPredefinedField) {
                continue;
            }
            this.setItems(key, [], []);
        }

        const addonCategorySymbols = _.chain(addonCategoryGroups)
            .map(group => group.categories)
            .flatten()
            .map(category => category.symbol)
            .value();

        this.initializeProvider(addonCategorySymbols);

        for (let addonCategoryGroup of addonCategoryGroups) {
            for (let category of addonCategoryGroup.categories) {
                this.setItems(category.symbol, category.addons.map(addon => this.mapSelectItemWithName(addon)), []);
            }
        }
    }

    storeColors(colors: Color[]): void {
        this.setItems(ConfigEditorField.COLOR, colors.map(color => this.mapSelectItemWithNames(color)), []);
    }

    storeMaterials(materials: Material[]): void {
        this.setItems(ConfigEditorField.MATERIAL, materials.map(material => this.mapSelectItemWithName(material)), []);
    }

    private mapSelectItemWithNames(item: { id: number, names: MultilanguageFieldInterface, active: boolean }) {
        return {
            label: item.names[this.translate.currentLang],
            value: item.id,
            available: item.active
        };
    }

    private mapSelectItemWithName(item: { id: number, name: MultilanguageFieldInterface, active: boolean, singlePositionAddon?: boolean, additionalIcon?: string, hex?: string }) {
        return ({
            label: item.name[this.translate.currentLang],
            value: item.id,
            available: item.active,
            singlePositionItem: item.singlePositionAddon,
            icon: item.additionalIcon,
            title: item.hex
        });
    }

    private extractSelectedId(data: ConfigSystemDefaults, ...key: ConfigDataKey[]): number[] {
        if (data == undefined) {
            return [];
        }
        return key.map(k => data[k]).filter(v => v != undefined);
    }

    private extractSelectedAddons(data: ConfigSystemDefaults): number[] {
        if (data == undefined) {
            return [];
        }
        return _.values(data.sidebarAddons);
    }

    protected requiredValueIsCorrect(currentValue: string, requiredField: ConfigEditorField, requiredValue: string): boolean {
        switch (requiredField) {
            case ConfigEditorField.DIM_1_GREATER_THAN:
            case ConfigEditorField.DIM_2_GREATER_THAN:
            case ConfigEditorField.DIM_3_GREATER_THAN:
            case ConfigEditorField.DIM_4_GREATER_THAN:
            case ConfigEditorField.DIM_5_GREATER_THAN:
            case ConfigEditorField.DIM_6_GREATER_THAN:
            case ConfigEditorField.AREA_GREATER_THAN:
                return +currentValue > +requiredValue;
            case ConfigEditorField.DIM_1_LESS_THAN:
            case ConfigEditorField.DIM_2_LESS_THAN:
            case ConfigEditorField.DIM_3_LESS_THAN:
            case ConfigEditorField.DIM_4_LESS_THAN:
            case ConfigEditorField.DIM_5_LESS_THAN:
            case ConfigEditorField.DIM_6_LESS_THAN:
            case ConfigEditorField.AREA_LESS_THAN:
                return +currentValue < +requiredValue;
            default:
                break;
        }
        return super.requiredValueIsCorrect(currentValue, requiredField, requiredValue);
    }

    storeSelectedValues(data: Partial<ConfigData>): void {
        this.notifyFieldChanged(ConfigEditorField.SYSTEM, data.configSystemId);
        this.notifyFieldChanged(ConfigEditorField.COLOR, data.colorId);
        this.notifyFieldChanged(ConfigEditorField.MATERIAL, data.materialId);
        this.notifyFieldChanged(ConfigEditorField.DIM_1, data.wym1);
        this.notifyFieldChanged(ConfigEditorField.DIM_2, data.wym2);
        this.notifyFieldChanged(ConfigEditorField.DIM_3, data.wym3);
        this.notifyFieldChanged(ConfigEditorField.DIM_4, data.wym4);
        this.notifyFieldChanged(ConfigEditorField.DIM_5, data.wym5);
        this.notifyFieldChanged(ConfigEditorField.DIM_6, data.wym6);
        this.notifyFieldChanged(ConfigEditorField.DIM_1_LESS_THAN, data.wym1);
        this.notifyFieldChanged(ConfigEditorField.DIM_2_LESS_THAN, data.wym2);
        this.notifyFieldChanged(ConfigEditorField.DIM_3_LESS_THAN, data.wym3);
        this.notifyFieldChanged(ConfigEditorField.DIM_4_LESS_THAN, data.wym4);
        this.notifyFieldChanged(ConfigEditorField.DIM_5_LESS_THAN, data.wym5);
        this.notifyFieldChanged(ConfigEditorField.DIM_6_LESS_THAN, data.wym6);
        this.notifyFieldChanged(ConfigEditorField.DIM_1_GREATER_THAN, data.wym1);
        this.notifyFieldChanged(ConfigEditorField.DIM_2_GREATER_THAN, data.wym2);
        this.notifyFieldChanged(ConfigEditorField.DIM_3_GREATER_THAN, data.wym3);
        this.notifyFieldChanged(ConfigEditorField.DIM_4_GREATER_THAN, data.wym4);
        this.notifyFieldChanged(ConfigEditorField.DIM_5_GREATER_THAN, data.wym5);
        this.notifyFieldChanged(ConfigEditorField.DIM_6_GREATER_THAN, data.wym6);
        this.notifyFieldChanged(ConfigEditorField.AREA_GREATER_THAN, SizeUnitsConverter.mmSidesToMeterSquared(data.wym1, data.wym2));
        this.notifyFieldChanged(ConfigEditorField.AREA_LESS_THAN, SizeUnitsConverter.mmSidesToMeterSquared(data.wym1, data.wym2));
        Object.entries(data.sidebarAddons || {}).forEach(entry => {
            this.notifyFieldChanged(entry[0], entry[1]);
        });
    }
}
