import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GatePanelStampingType} from '../../../../../gate-painter/gate-panel-stamping-type';
import {ColorInterface} from '../../../../../window-designer/catalog-data/color-interface';
import {COMMON_INPUT_LENGTH_LIMITS} from '../../../../common/crud-common/common-input-length-limits';
import {ValidationErrors} from '../../../../common/validation-errors';
import {SupportedLanguages} from "../../../../supportedLanguages";
import {GatePanelTypeField} from '../../../admin-panel/edit-catalog-permits/catalog-field.enum';
import {FieldLimitation} from "../../../admin-panel/edit-catalog-permits/field-limitation";
import {GatePanelTypeFieldUsage} from "../../catalog-field-usage";
import {GatePanelType} from '../gate-panel-type';

@Component({
    selector: 'app-gate-panel-type-form',
    templateUrl: './gate-panel-type-form.component.html',
    styleUrls: ['./gate-panel-type-form.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class GatePanelTypeFormComponent {

    readonly LIMITS = COMMON_INPUT_LENGTH_LIMITS;

    supportedLanguages = SupportedLanguages.languages;

    @Input()
    item: GatePanelType;

    @Input()
    validationErrors: ValidationErrors;

    @Input()
    lowResolutionPreviewImage: File;

    @Output()
    readonly lowResolutionPreviewImageChange = new EventEmitter<File>();

    @Input()
    highResolutionPreviewImage: File;

    @Output()
    readonly highResolutionPreviewImageChange = new EventEmitter<File>();

    @Input()
    gateColors: ColorInterface[] = [];

    @Input()
    additionalIcon: File;

    @Input() editPermits: FieldLimitation[] = [];

    @Output()
    readonly additionalIconChange = new EventEmitter<File>();

    readonly availableStampingTypes: {
        type: string,
        image: string
    }[];

    fieldUsage: GatePanelTypeFieldUsage;
    GatePanelTypeField = GatePanelTypeField;

    constructor(private translate: TranslateService) {
        this.availableStampingTypes = Object.keys(GatePanelStampingType).map(type => ({
            type: type,
            image:  `assets/images/gate-designer/panels/${type}.svg`
        }));
        this.fieldUsage = new GatePanelTypeFieldUsage(this);
    }

    colorFormatter = (color: ColorInterface) => ({label: color.names[this.translate.currentLang], value: color.id});

    handleStampingTypeChange(stampingType: GatePanelStampingType): void {
        this.item.stampingType = stampingType;
        this.validationErrors['stampingType'] = undefined;
    }
}
