import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import {interval, Subscription} from 'rxjs';

@Component({
    selector: 'app-countdown',
    templateUrl: './countdown.component.html',
    styleUrls: ['./countdown.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CountdownComponent implements OnInit, OnChanges, OnDestroy {

    @Input()
    showExpiresSoonTime: Date;

    @Input()
    endTime: Date;

    @Output()
    isShortRemaining: EventEmitter<boolean> = new EventEmitter<boolean>();

    days: number;
    hours: number;
    minutes: number;
    seconds: number;

    showWarningColor = true;

    private timerSubscription: Subscription;

    constructor(private changeDetector: ChangeDetectorRef) {
    }

    ngOnInit(): void {
    }

    ngOnDestroy(): void {
        this.unsubscribeTimer();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if ('endTime' in changes) {
            this.unsubscribeTimer();
            if (changes['endTime'].currentValue != undefined) {
                this.fillTimeFields(changes['endTime'].currentValue);
                this.showWarningColor = this.showExpiresSoonTime <= new Date();
                this.isShortRemaining.emit(this.showWarningColor);
                this.timerSubscription = interval(1000).subscribe(() => {
                    this.fillTimeFields(this.endTime);
                    const now = new Date();
                    this.showWarningColor = this.showExpiresSoonTime <= now;
                    this.isShortRemaining.emit(this.showWarningColor);
                    if (this.endTime <= now) {
                        this.unsubscribeTimer();
                    }
                    this.changeDetector.markForCheck();
                });
            } else {
                this.days = 0;
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
                this.isShortRemaining.emit(true);
            }
        }
    }

    private unsubscribeTimer() {
        if (this.timerSubscription != undefined) {
            this.timerSubscription.unsubscribe();
            this.timerSubscription = undefined;
        }
    }

    private fillTimeFields(endTime: Date) {
        const duration = moment.duration(moment(endTime).diff(moment()));
        this.days = Math.max(Math.floor(duration.asDays()), 0);
        this.hours = Math.max(duration.hours(), 0);
        this.minutes = Math.max(duration.minutes(), 0);
        this.seconds = Math.max(duration.seconds(), 0);
    }
}
